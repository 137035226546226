import React from 'react'

const CircleIcon = ({ size, bg, className, children }) => {
    return (
        <div style={{
            height: size,
            width: size,
            backgroundColor: bg
        }} className={`overflow-hidden ${size ? `h-[${size}px] w-[${size}px]` : 'h-[4.375rem] w-[4.375rem]'} rounded-full flex items-center justify-center ${bg ? `bg-[${bg}]` : 'bg-[#E4EAF0]'} ${className}`}>
            {children}
        </div>
    )
}

export default CircleIcon