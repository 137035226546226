import React from "react";
import { CSVLink } from "react-csv";
import Svgs from "../../../../../Assets/svgs";
import LoginBtn from "../../../Elements/LoginBtn";
import Popup from "../../../Elements/Popup";
import LocationSelection from "new-components/location";
import moment from "moment";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import NoPayrollFound from "../Elements/NoPayrollFound";
import IconButton from "Components/TenantApp/Elements/IconButton";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import DateRangePicker from "Components/TenantApp/Elements/DateRangePicker";
import useUpsellDownsell from "./useUpsellDownsell";
import Pagination from "new-components/Pagination";
import EmployeeUpsellDownSell from "./EmployeeUpSellDownSell";
import StoreUpsellDownSell from "./StoreUpSellDownSell";

const UpsellDownsell = () => {
  const {
    loader,
    up_down_sell,
    currentPage, loadingPermission,
    pages,
    translation,
    selectedLanguage,
    employeePaginationData,
    Currency,
    employeeLoader, employeeRedux,
    setToggleDateRange, toggleDateRange,
    startDate, endDate,
    setStartDate, setEndDate,
    tabList,
    active_tab,
    setSearchParams,
    searchParams,
    setEmployeeSearch, employeeSearch,
    selectedEmployee, setSelectedEmployee, employee, payrollData,
    exportLoader, handleExportClick, setCurrentPage,
    setEmployeePage, employeePage, setImportPayroll, ImportPayroll, PageSize
  } = useUpsellDownsell()


  const tabsList = {
    employee: <EmployeeUpsellDownSell/>,
    store: <StoreUpsellDownSell/>
  };

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "POS Analytics" : translation[7]?.value ? translation[7]?.value : "POS Analytics"}
          second={selectedLanguage?.name == "English" ? "Upsell and Downsell Report" : translation[1690]?.value ? translation[1690]?.value : "Upsell and Downsell Report"}
          url={'/dashboard/pos-analytics/'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Upsell and Downsell Report" : translation[1690]?.value ? translation[1690]?.value : "Upsell and Downsell Report"}
            </h2>
            <LocationSelection />
          </div>

        </div>
        <div className={"border-b mt-[2rem]"}>
          <div className="flex items-center gap-6">
            {tabList?.length > 0 &&
              tabList.map((tab, index) => {
                return (
                  <div className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${active_tab == tab.value && "font-semibold border-primary"}`}
                    onClick={() => {
                      setSearchParams({ ...searchParams, active_tab: tab.value });
                    }}
                    key={index}
                  >
                    <h2 className="text-primary capitalize">{tab.label}</h2>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="mt-[3rem] mb-[3rem]">{active_tab && tabsList[active_tab]}</div>
      </div>
    </>
  );
};

export default UpsellDownsell;
