import React from 'react'

const DetailPopupDetails = ({ title, desc, titleClass, descClass }) => {
    return (
        <div>
            <h1 className={`${titleClass} text-[#555555] font-bold`}>{title}</h1>
            <p className={`${descClass} text-[#101928]`}>{desc}</p>
        </div>
    )
}

export default DetailPopupDetails