
import axios from "axios";
import { BASE_URL, create_retail_target, get_retail_target, update_retail_target, delete_retail_target, copy_retail_target, get_retail_target_report_url } from "../api_variables";
import { toast } from 'react-toastify';
import { RETAIL_TARGET } from "Redux/TenantRedux/ActionTypes/retailTargetTypes";
import { apiErrorMessage } from "Constants/Data/Errors";

export const getRetailTarget = (selected_location, currentPage, searchText, monthFilter, searchYear, setLoader) => dispatch => {
    setLoader && setLoader(true)
    const location = selected_location ? `?location_id=${selected_location}` : ''
    const current_Page = currentPage ? `&page=${currentPage}` : '';
    const search = searchText ? `&search_text=${searchText}` : ''
    const month = monthFilter ? `&month=${monthFilter}` : ''
    const year = searchYear ? `&year=${searchYear}` : ''
    let s_code;
    fetch(`${BASE_URL}${get_retail_target}${location}${current_Page}${search}${month}${year}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let retailTarget = result;
                dispatch({
                    type: RETAIL_TARGET.GET_RETAIL_TARGET,
                    payload: retailTarget
                })
                setLoader && setLoader(false)
            }
            else {
                toast.error(apiErrorMessage, { toastId: "toast" })
                setLoader && setLoader(false)
            }
        }).catch((err) => {
            toast.error(apiErrorMessage, { toastId: "toast" })
            setLoader && setLoader(false)
        })
};


export const getRetailTargetWithNoPagination = (selected_location, searchText, monthFilter, searchYear, setNoPaginationLoader) => async (dispatch) => {
    setNoPaginationLoader && setNoPaginationLoader(true)
    const location = selected_location ? `&location_id=${selected_location}` : ''
    const search = searchText ? `&search_text=${searchText}` : ''
    const month = monthFilter ? `&month=${monthFilter}` : ''
    const year = searchYear ? `&year=${searchYear}` : ''
    try {
        const result = await axios.get(`${BASE_URL}${get_retail_target}?no_pagination=true${location}${search}${month}${year}`);
        if (dispatch) {
            let retailTarget = result;
            dispatch({
                type: RETAIL_TARGET?.GET_RETAIL_TARGET_WITHOUT_PAGINATION,
                payload: retailTarget
            })
            setNoPaginationLoader && setNoPaginationLoader(false)
        }
        setNoPaginationLoader && setNoPaginationLoader(false)
        return result;
    } catch (error) {
        setNoPaginationLoader && setNoPaginationLoader(false)
        return error;
    }
};




export const createRetailTarget = (data, access_token, success, fail) => async dispatch => {
    let form_data = new FormData();

    for (let key in data) {
        if (data[key]) {
            form_data.append(key, data[key])
        }
    }

    try {
        const result = await axios.post(`${BASE_URL}${create_retail_target}`, form_data,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );

        if (result?.data?.status_code == 201) {
            dispatch({
                type: RETAIL_TARGET?.CREATE_RETAIL_TARGET,
                payload: result?.data?.response?.retailtarget
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()

    }
};

export const createCopyRetailTarget = (data, access_token, success, fail) => async dispatch => {
    let form_data = new FormData();

    for (let key in data) {
        if (data[key]) {
            form_data.append(key, data[key])
        }
    }

    try {
        const result = await axios.post(`${BASE_URL}${copy_retail_target}`, form_data,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );

        if (result?.data?.status_code == 200) {
            // dispatch({
            //     type: RETAIL_TARGET?.GET_RETAIL_TARGET,
            //     payload: result?.data?.response?.retailtarget
            // })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()

    }
};


export const updateRetailTarget = (data, access_token, success, fail) => async dispatch => {

    let form_data = new FormData();
    for (let key in data) {
        if (data[key]) {
            form_data.append(key, data[key])
        }
    }

    try {
        const result = await axios.put(`${BASE_URL}${update_retail_target}`, form_data,
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });

        if (result?.data?.status_code === 200) {
            dispatch({
                type: RETAIL_TARGET.UPDATE_RETAIL_TARGET,
                payload: result?.data?.response?.retailtarget
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
};


export const deleteRetailTarget = (id, access_token, success, fail) => dispatch => {

    fetch(`${BASE_URL}${delete_retail_target}`, {
        method: "DELETE",
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Token ${access_token}`
        },
        body: JSON.stringify(
            { id: id }
        )
    }).then((result) => {
        return result.json()
    }).then((response) => {
        if (response.status_code === 200) {
            dispatch({
                type: RETAIL_TARGET?.DELETE_RETAIL_TARGET,
                payload: id
            });
        }
        success && success(response?.response?.message);
    }).catch((err) => {
        fail && fail()
    })

}


// Retail Target Report KPI
export const get_retail_target_kpi = (selectedd_location, currentPage, month, year, filterBrand, loader, success, fail) => async (dispatch) => {
    const location = selectedd_location ? `?location_id=${selectedd_location}` : ''
    const page = currentPage ? `&page=${currentPage}` : ''
    const monthFilter = month ? `&month=${month}` : ''
    const yearFilter = year ? `&year=${year}` : ''
    const brand = filterBrand ? `&brand_id=${filterBrand}` : ''
    try {
        loader && loader()
        const response = await axios.get(`${BASE_URL}${get_retail_target_report_url}${location}${page}${monthFilter}${yearFilter}${brand}`);
        if (response.status === 200) {
            success && success()
            dispatch({
                type: RETAIL_TARGET?.GET_RETAIL_TARGET_REPORT,
                payload: response?.data
            });
        } else {
            toast.error(apiErrorMessage, { toastId: "toast" });
        }
    } catch (error) {
        fail && fail()
        toast.error(apiErrorMessage, { toastId: "toast" });
        return error;
    }
};

// no pagination
export const get_retail_target_kpi_without_pagination = (selectedd_location, month, year, filterBrand, loader, success, fail) => async (dispatch) => {
    const location = selectedd_location ? `?location_id=${selectedd_location}` : ''
    const monthFilter = month ? `&month=${month}` : ''
    const yearFilter = year ? `&year=${year}` : ''
    const brand = filterBrand ? `&brand_id=${filterBrand}` : ''
    try {
        loader && loader()
        const response = await axios.get(`${BASE_URL}${get_retail_target_report_url}${location}&no_pagination=true${monthFilter}${yearFilter}${brand}`);
        if (response.status === 200) {
            success && success()
            dispatch({
                type: RETAIL_TARGET?.GET_RETAIL_TARGET_REPORT_WITHOUT_PAGINATION,
                payload: response?.data
            });
        } else {
            toast.error(apiErrorMessage, { toastId: "toast" });
        }
    } catch (error) {
        fail && fail()
        toast.error(apiErrorMessage, { toastId: "toast" });
        return error;
    }
};