import React, { useState, useEffect } from "react";
import Svgs from "../../../../../Assets/svgs";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import { MONTHS } from "../../../../../Constants/Data/Months";
import LoginInput from "../../../Elements/LoginInput";
import Popup from "../../../Elements/Popup";
import CircleIcon from "../../../Elements/CircleIcon";
import DateInput from "../../../Elements/DateInput";
import { useDispatch, useSelector } from "react-redux";
import { getSaleOrderServices } from "../../../../../Adapters/Api/saleOrders";
import { getLocations } from "../../../../../Adapters/Api";
import useSetDataInLocalStorage from "../../../../../Hooks/useSetDataInLocalStorage";
import { getSaleServices } from "../../../../../Adapters/Api/saleServices";
import Moment from "react-moment";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import PreloaderFull from "../../../Elements/PreloaderFull";
import NotFound from "../../../../Utility/NotFound";

const StaffService = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  const saleOrderServicesList = state?.saleOrder?.saleOrderServices;
  const saleOrderServices_updated = state?.saleOrder?.saleOrderServices_updated;
  const serviceList = state?.saleService?.saleServices;
  const locationList = state.locations.locations;
  const [ShowDetails, setShowDetails] = useState(0);
  const [Filter, setFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedStates, setSelectedState] = useState({
    employeeText: "",
    locationText: "",
    serviceText: "",
    selectedFrom: "",
    selectedTo: "",
  });
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const { employeeText, locationText, serviceText, selectedFrom, selectedTo } =
    selectedStates;

  const [filterState, setFilterState] = useState({
    employee_name: "",
    service: "",
    location: "",
    from: "",
    to: "",
  });
  const { employee_name, service, location, from, to } = filterState;

  //toggle filter modal
  const toggleModal = () => {
    setFilter(!Filter);
    setSelectedState({
      employeeText: "",
      locationText: "",
      serviceText: "",
      selectedFrom: "",
      selectedTo: "",
    });
  };

  //all filter handle here
  const service_filter = () => {
    const fromDate = Date.parse(from);
    const toDate = Date.parse(to);

    const service_filter_data = saleOrderServicesList?.filter((item) => {
      let createAt = Date.parse(item?.created_at.split("T")[0]);

      if (
        searchText ||
        employee_name ||
        service ||
        location ||
        (fromDate && toDate)
      ) {
        let return_value = false;

        //check search text
        if (searchText) {
          if (
            item.member?.full_name
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            return_value = true;
          } else {
            return false;
          }
        }

        //check filter employee text
        if (employee_name) {
          if (
            item.member?.full_name
              .toLowerCase()
              .includes(employee_name.toLowerCase())
          ) {
            return_value = true;
          } else {
            return false;
          }
        }

        //check filter from to date
        if (fromDate && toDate) {
          if (createAt >= fromDate && createAt <= toDate) {
            return_value = true;
          } else {
            return false;
          }
        }

        //check filter product text
        if (service) {
          if (
            item?.service?.name?.toLowerCase().includes(service.toLowerCase())
          ) {
            return_value = true;
          } else {
            return false;
          }
        }

        //check filter location text
        if (location) {
          if (
            item?.location?.address_name
              ?.toLowerCase()
              .includes(location.toLowerCase())
          ) {
            return_value = true;
          } else {
            return false;
          }
        }

        return return_value;
      }
      return true;
    });

    return service_filter_data;
  };

  //apply filter here
  const applyFilter = () => {
    setFilterState((prev) => ({
      ...prev,
      employee_name: employeeText,
      location: locationText,
      service: serviceText,
      from: selectedFrom,
      to: selectedTo,
    }));

    setFilter(false);
  };

  //filter state handle change
  const filterHandleChange = (e) => {
    const { name, value } = e.target;
    setSelectedState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (saleOrderServicesList.length === 0) {
      dispatch(getSaleOrderServices());
    }
    if (locationList?.length === 0) {
      dispatch(getLocations(businessData?.id));
    }
    if (serviceList.length === 0) {
      dispatch(getSaleServices());
    }
  }, []);

  // service Data exports
  let serviceHeader = [
    { label: "Service Name", key: "service_name" },
    { label: "Date", key: "date" },
    { label: "Net Sale", key: "net_sale" },
    { label: "Payment Method", key: "payment_method" },
    { label: "Tip", key: "tip" },
    { label: "Tax", key: "tax" },
    { label: "Total Sale", key: "total_sale" },
  ];

  const exportSaleSerivces = {
    filename: "saleServices.csv",
    headers: serviceHeader,
    data:
      saleOrderServicesList?.length > 0
        ? saleOrderServicesList?.map((item) => {
          return {
            service_name: item?.service?.name ? item?.service?.name : "----",
            date: item?.created_at?.split("T")[0]
              ? item?.created_at?.split("T")[0]
              : "----",
            net_sale: item?.total_price ? item?.total_price : 0,
            payment_method: item?.payment_type ? item?.payment_type : "----",
            tip: item?.tip ? item?.tip : 0,
            tax: item?.gst ? item?.gst : 0,
            total_sale: item?.total_price
              ? item?.total_price + item?.tip + item?.gst
              : 0 + item?.tip + item?.gst,
          };
        })
        : [],
  };

  return !saleOrderServices_updated ? (
    <PreloaderFull />
  ) : (
    <>
      <div className="border rounded-lg">
        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">Staff Service Sales</h2>
            </div>
            <div className="mb-00">
              <LoginInput
                name="searchText"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                title=""
                placeholder="Search"
                padding="py-1"
                leftIcon={<Svgs.Search />}
              />
            </div>
          </div>
          <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
            <LoginBtn
              onClick={toggleModal}
              borderBtn
            >
              <div className="flex items-center gap-2 text-[#101928]">
                <Svgs.Filters />
                {/* <p>Filters</p> */}
              </div>
            </LoginBtn>
            {!employee ? (
              <CSVLink
                {...exportSaleSerivces}
                target="_blank"
                onClick={() => {
                  setTimeout(() => {
                    toast.success("File downloaded successfully", { toastId: "toast" });
                  }, 300);
                }}
              >
                <LoginBtn
                  borderBtn

                >
                  <div className="flex items-center gap-2 text-[#101928]">
                    <Svgs.Export bg="#101928" />
                  </div>
                </LoginBtn>
              </CSVLink>
            ) : (
              ""
            )}

            {/* <LoginBtn className='rounded-lg border border-primary text-sm' >
                            <div className='flex items-center gap-2'>
                                <Svgs.Import fill='#fff' />
                                <p className='font-semibold'>Import List</p>
                            </div>
                        </LoginBtn> */}
          </div>
        </div>
        <div className="overflow-x-auto">
          <div className="inline-grid xl:grid grid-cols-[repeat(8,minmax(135px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
            <p>Employee Name</p>
            <p>Service Name</p>
            <p>Date</p>
            <p>Net Sale </p>
            <p>Payment Method </p>
            <p>Tip</p>
            <p>Tax</p>
            <p>Total Sale</p>
            {/* <p>Actions</p> */}
          </div>
          <div
            className={`odd-bg text-[#3A3A3A] transition-all ${ShowDetails
              ? "border-l-[4px] border-[#5679FFB2]"
              : "border-l-[0px]"
              }`}
          >
            {saleOrderServicesList?.length > 0 ? (
              service_filter()?.map((srvc, i) => {
                return (
                  <>
                    <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(8,minmax(135px,1fr))]">
                      <div
                        className={`before-left-arrow cursor-pointer ${ShowDetails == i + 1
                          ? "before-left-arrow-open"
                          : "before-left-arrow-close"
                          }`}
                        onClick={() => {
                          setShowDetails(ShowDetails != i + 1 ? i + 1 : 0);
                        }}
                      >
                        <div className="h-full flex items-center">
                          <p className="text-sm">{srvc?.member?.full_name}</p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">{srvc?.service?.name}</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          <Moment format="dddd, D MMM YYYY">
                            {srvc?.created_at}
                          </Moment>
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          {srvc?.total_price}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          {srvc?.payment_type}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">{srvc?.tip}</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">{srvc?.gst}</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          {srvc?.total_price + srvc?.tip + srvc?.gst}
                        </p>
                      </div>
                      {/* <div className='flex items-center gap-2'>
                                        <div className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center'>
                                            <Svgs.Delete fill='#7D7D7D' />
                                        </div>
                                    </div> */}
                    </div>
                    {ShowDetails == i + 1 ? (
                      <div className="scale-in-center">
                        <div className="bg-[#FAF9FF] px-6 py-3 inline-grid xl:grid grid-cols-[repeat(4,minmax(180px,1fr))]">
                          <div className="flex items-center gap-12">
                            <div className="flex items-center gap-3 col-span-1">
                              <img
                                src={`${srvc?.image
                                  ? srvc?.image
                                  : `${process.env.PUBLIC_URL}/images/profile_img.svg`
                                  }`}
                                className="h-[4.375rem] w-[4.375rem] object-cover rounded-lg"
                              />{" "}
                              <div className="flex flex-col gap-2 flex-1">
                                <p className="text-black font-semibold">
                                  {srvc?.member?.full_name}
                                </p>
                                <h2 className="text-[#949494] text-sm">
                                  1233356{" "}
                                </h2>
                              </div>
                            </div>
                            <div className="h-full w-[1px] bg-[#3F3F441A] mr-3"></div>
                          </div>
                          <div className="grid grid-cols-7 col-span-3 gap-5">
                            <div className="col-span-2">
                              <div className="flex flex-col gap-4">
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Service Name{" "}
                                  </h2>
                                  <p className="text-sm">
                                    {srvc?.service?.name}.
                                  </p>
                                </div>
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Date
                                  </h2>
                                  <p className="text-sm">
                                    {srvc?.created_at?.split("T")[0]}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-span-2">
                              <div className="flex flex-col gap-4">
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Location
                                  </h2>
                                  <p className="text-sm">
                                    {srvc?.location?.address_name}
                                  </p>
                                </div>
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Tip
                                  </h2>
                                  <p className="text-sm">{srvc?.tip}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-span-2">
                              <div className="flex flex-col gap-4">
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Net Sale
                                  </h2>
                                  <p className="text-sm">
                                    Lorem ipsum dolor sit.
                                  </p>
                                </div>
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Total Sale
                                  </h2>
                                  <p className="text-sm">{srvc?.total_price}</p>
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <div className="flex flex-col gap-4">
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Tax
                                  </h2>
                                  <p className="text-sm">{srvc?.gst}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })
            ) : (
              <NotFound />
            )}
          </div>
        </div>
      </div>
      {Filter && (
        <Popup heading="Filter" open={Filter} close={toggleModal}>
          <div className="flex flex-col gap-5">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
              <CircleIcon>
                <Svgs.FilterPopup />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  Search By Filters
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  Apply filters to get desired results
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5">
              <Dropwdown
                title="Employee"
                placeholder="Select Employee"
                name={"employeeText"}
                value={employeeText}
                onChange={filterHandleChange}
                options={saleOrderServicesList?.map((srvc) => {
                  return {
                    label: srvc?.member?.full_name,
                    value: srvc?.member?.full_name,
                  };
                })}
              />
              <Dropwdown
                title="Location"
                placeholder="Select Location"
                name={"locationText"}
                value={locationText}
                onChange={filterHandleChange}
                options={locationList?.map((lction) => {
                  return {
                    label: lction?.address_name,
                    value: lction?.address_name,
                  };
                })}
              />

              <Dropwdown
                title="Service"
                placeholder="Select Service"
                name={"serviceText"}
                value={serviceText}
                onChange={filterHandleChange}
                options={serviceList?.map((srvc) => {
                  return {
                    label: srvc?.name,
                    value: srvc?.name,
                  };
                })}
              />
              <div></div>
              <DateInput
                title="From"
                name={"selectedFrom"}
                value={selectedFrom}
                onChange={filterHandleChange}
              />
              <DateInput
                title="To"
                name={"selectedTo"}
                value={selectedTo}
                onChange={filterHandleChange}
              />
            </div>
            <div className="flex items-center justify-start">
              <LoginBtn text="Apply Filter" onClick={applyFilter} />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default StaffService;
