import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState, useRef } from "react";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { deleteWeekendApi, getWeekend } from "Adapters/Api/weekend";
import { toast } from "react-toastify";
import WeekendProfileStack from "Components/TenantApp/Elements/WeekendProfileStack";
import moment from "moment";
import Svgs from "Assets/svgs";

const useWeekendManagement = () => {
    const calendarRef = useRef()
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const selected_location = getDataFromLocalStorage('selected_location')
    const access_token = getDataFromLocalStorage('access_token')


    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const weekendData = state?.employee?.weekend

    const [events, setEvents] = useState([]);
    const [createWeekend, setCreateWeekend] = useState(false)
    const [selectedDate, setSelectedDate] = useState('')
    const [isEdit, setIsEdit] = useState(false)
    const [weekendDetail, setWeekendDetail] = useState({})
    const [deleteIds, setDeleteIds] = useState([])
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [deleteWeekend, setDeleteWeekend] = useState(false)
    const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
    const [updatedDate, setUpdatedDate] = useState(moment().format('YYYY-MM-DD'));
    const [goToDate, setGoToDate] = useState(false);
    const [currentMonth, setCurrentMonth] = useState('')
    const [currentYear, setCurrentYear] = useState('')

    const [loader, setLoader] = useState(true)

    useEffect(() => {
        const transformedData = [];

        weekendData?.forEach((event) => {
            // Check if an object with the same date already exists in the transformedData array
            const existingObject = transformedData?.find((obj) => obj.date === event.date);

            if (existingObject) {
                // If an object with the same date exists, add the employee to its employees array
                existingObject?.employees?.push(event.employee);
                existingObject?.schedules?.push(event.id);
            } else {
                // If no object with the same date exists, create a new object
                transformedData?.push({
                    title: event?.title,
                    date: event?.date,
                    employees: [event?.employee],
                    schedules: [event?.id],
                    display: 'background',
                    backgroundColor: '#EDF1FF'
                });
            }
        });
        setEvents(transformedData)
    }, [weekendData, weekendData?.length])


    useEffect(() => {
        if (selected_location && currentMonth && currentYear) {
            getWeekendData(selected_location)
        }
    }, [selected_location, currentYear, currentMonth])

    const getWeekendData = (selected_location) => {
        let payload = { location_id: selected_location, is_weekend: true, month: currentMonth, year: currentYear }
        dispatch(getWeekend(payload))
    }

    const handleEventDrop = (info) => {
        const updatedEvents = events.map((event) =>
            event.id === info.event.id
                ? { ...event, start: info.event.start, end: info.event.end }
                : event
        );

        setEvents(updatedEvents);
    };

    const handleDateClick = (info) => {
        const filteredEvent = events?.find(itm => itm?.date == info?.dateStr)
        if (filteredEvent) {
            const selectedDate = info.dateStr
            const employees = filteredEvent?.employees?.map(itm => itm?.id)
            setWeekendDetail({ date: selectedDate, employees: employees, is_weekend: true })
            setIsEdit(true)
            setCreateWeekend(!createWeekend)
        } else {
            setSelectedDate(info?.dateStr)
            setCreateWeekend(!createWeekend)
        }
    }

    const eventContent = (eventInfo) => {
        // show content on event
        return (
            <div style={{ height: '100%', padding: '5px', borderRadius: '4px' }} className="relative flex py-4">
                {/* <div className='absolute cursor-pointer top-4 right-4'
                    onClick={(e) => handleDelete(eventInfo.event, e)}>
                    <Svgs.CirlceClose size='1.2rem' fill='#ED0F1C' />
                </div> */}

                <div className="flex items-end">
                    <WeekendProfileStack data={eventInfo?.event?._def?.extendedProps?.employees} />
                </div>
            </div>
        );
    };

    const handleEventMouseEnter = (info) => {
        // Show event details on hover
        // For demonstration purposes, we'll log the event details to the console
        console.log('Event details:', info.event.id);
        // You can use a state variable or any other method to display the details in your UI
        // Set the content and position for the tooltip
    };

    const handleEventMouseLeave = () => {
        // Hide event details on mouse leave
        // For demonstration purposes, we'll log a message to the console
        console.log('Mouse left event');
        // You can update your UI to hide the event details
    };

    const handleEventClick = (info) => {
        // Handle event click logic here
        // Access additional data from the event
        const selectedDate = info.event.startStr
        const employees = info.event.extendedProps.employees?.map(itm => itm?.id)
        setWeekendDetail({ date: selectedDate, employees: employees, is_weekend: true })
        setIsEdit(true)
        setCreateWeekend(true)
    };

    const handleDelete = (info, e) => {
        // Prevent eventClick from being triggered
        setCreateWeekend(false)
        e.stopPropagation();
        // handle delete here
        const scheduleIds = info.extendedProps.schedules
        setDeleteIds(scheduleIds)
        setDeleteWeekend(!deleteWeekend)
    }

    const handleCloseDeletePopup = () => {
        setDeleteIds([])
        setDeleteLoader(false)
        setDeleteWeekend(!deleteWeekend)
    }

    const handleDeleteWeekend = async () => {
        setDeleteLoader(true)
        let payload = {
            ids: deleteIds,
            is_weekend: true
        }

        const res = await deleteWeekendApi(payload, setDeleteLoader, access_token);
        if (res?.data?.status == 200) {
            getWeekendData(selected_location)
            handleCloseDeletePopup()
            toast.success(res?.data?.response?.message, { toastId: "toast" })
        }
    };

    const successCallBack = () => {
        getWeekendData(selected_location)
    }

    const handleGoToDate = () => {
        setGoToDate(true);
    };

    const GoToDateFunc = () => {
        setCurrentDate(moment(updatedDate).format('YYYY-MM-DD'))
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.gotoDate(updatedDate);
            calendarApi.changeView('timeGridDay')
            setGoToDate(false)
        }
    }

    const handleDatesSet = (info) => {
        const month = info.start
        const year = info.view.currentStart.getFullYear();
        const formatedMonth = +moment(month).format('MM') + 1
        setCurrentMonth(formatedMonth == 13 ? 1 : formatedMonth);
        setCurrentYear(year)
    };

    const todayClickFunc = () => {
        setCurrentDate(moment().format('YYYY-MM-DD'))
        setUpdatedDate(moment().format('YYYY-MM-DD'))
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.gotoDate(moment().format('YYYY-MM-DD'));
            setGoToDate(false)
        }
    }

    return {
        translation, selectedLanguage, handleEventDrop, handleEventMouseLeave, handleEventMouseEnter,
        handleDateClick, events, createWeekend, setCreateWeekend, selectedDate, handleEventClick,
        isEdit, setIsEdit, weekendDetail, handleDelete, eventContent, deleteWeekend, deleteLoader,
        handleDeleteWeekend, handleCloseDeletePopup, successCallBack, currentDate, updatedDate,
        setUpdatedDate, handleGoToDate, GoToDateFunc, goToDate, setGoToDate, calendarRef, todayClickFunc, handleDatesSet
    }
}

export default useWeekendManagement