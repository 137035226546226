import axios from "axios";
import { BASE_URL, create_deal, delete_single_deal, get_all_deals, get_deal_categories, get_deal_channels, get_deal_locations, get_deal_restrictions, get_deal_settings, get_deal_types, get_deal_validity, get_single_deal, update_deal } from "../../api_variables";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { CRMTYPES } from "Redux/TenantRedux/ActionTypes/CRMTypes";
import { toast } from "react-toastify";
// import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";
// import { buildSpecificNameQueryString } from "Constants/Functions/buildSpecificNameQueryString";

// get all deals
export const getAllDeals = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const query = buildQueryString(payload)
        const result = await axios.get(`${BASE_URL}${get_all_deals}${query}`, header)
        if (result?.status == 200) {
            if (result?.data?.response?.statusCode == 200) {
                // toast.success(result?.data?.response?.message, { toastId: "toast" })
                dispatch({
                    type: CRMTYPES.GET_DEALS,
                    payload: result?.data?.response?.data
                })
                success && success()
            }
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}


// delete single deal
export const deleteDeal = (id, access_token, success, fail) => async (dispatch) => {
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const result = await axios.delete(`${BASE_URL}${delete_single_deal}${id}`, header)
        if (result?.status == 200) {
            toast.success(result?.data?.response?.message, { toastId: 'toast' })
            success && success()
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}

// get deal detailData
export const getDealById = (id, access_token, success, fail) => async (dispatch) => {
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const result = await axios.get(`${BASE_URL}${get_single_deal}/${id}/`, header)
        if (result?.status == 200) {
            success && success(result)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}

// get deal detailData
export const getDealRestrictions = (dealId, access_token, success, fail) => async (dispatch) => {
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const result = await axios.get(`${BASE_URL}${get_deal_restrictions}${dealId}/restrictions/`, header);
        if (result?.status == 200) {
            success && success(result)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}


// get deal types
export const getDealsTypes = (access_token, success, fail) => async (dispatch) => {
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const result = await axios.get(`${BASE_URL}${get_deal_types}`, header)
        if (result?.status == 200) {
            success && success(result)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}

// get deal channels
export const getDealsChannels = (access_token, success, fail) => async (dispatch) => {
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const result = await axios.get(`${BASE_URL}${get_deal_channels}`, header)
        if (result?.status == 200) {
            success && success(result)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}

// get deal categories
export const getDealsCategories = (access_token, success, fail) => async (dispatch) => {
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const result = await axios.get(`${BASE_URL}${get_deal_categories}`, header)
        if (result?.status == 200) {
            dispatch({
                type: "GET_DEAL_CATEGORIES",
                payload: result?.data?.data
            })
            success && success(result)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}

// get deal validity options
export const getDealsValidityOptions = (access_token, success, fail) => async (dispatch) => {
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const result = await axios.get(`${BASE_URL}${get_deal_validity}`, header)
        if (result?.status == 200) {
            success && success(result)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}

// get deal location options
export const getDealsLocationOptions = (access_token, success, fail) => async (dispatch) => {
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const result = await axios.get(`${BASE_URL}${get_deal_locations}`, header)
        if (result?.status == 200) {
            if (result?.data?.response?.statusCode == 302) {
                success && success(result)
            }
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}

// get deal settings
export const getDealsSetting = (dealId, slug, access_token, success, fail) => async (dispatch) => {
    if (dealId && slug) {
        try {
            const header = {
                headers: { Authorization: `Token ${access_token}` },
            }
            const result = await axios.get(`${BASE_URL}${get_deal_settings}${dealId}/${slug}`, header)
            if (result?.status == 200) {
                if (result?.data?.response?.statusCode == 200) {
                    success && success(result)
                }
                if (result?.data?.response?.statusCode == 404) {
                    success && success(result)
                }
            } else {
                fail && fail()
            }
        } catch (error) {
            fail && fail()
        }
    }
}

// get deal item types 
export const getDealsItemTypeOptions = (type, access_token, success, fail) => async (dispatch) => {
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const result = await axios.get(`${BASE_URL}/${type}`, header)
        if (result?.status == 200) {
            success && success(result)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}

// get deal item types pagination
export const getDealsItemTypeOptionsWithPagination = (type, payload, access_token, success, fail) => async (dispatch) => {
    // const queryString = buildSpecificNameQueryString(brands, 'brand', '&')
    const query = buildQueryString(payload)
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const result = await axios.get(`${BASE_URL}${type}${query}`, header)
        if (result?.status == 200) {
            success && success(result)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}


// post deal settings
export const postDealSettings = (dealId, slug, body, access_token, success, fail) => async (dispatch) => {
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const result = await axios.post(`${BASE_URL}${get_deal_settings}${dealId}/${slug}`, body, header);
        if (result?.status == 200) {
            if (result?.data?.response?.statusCode == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success(result?.data?.response?.data)
            }
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}

// create deal first step
export const createDeal = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.post(`${BASE_URL}${create_deal}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 201) {
            const data = result?.data?.deal
            toast.success(result?.data?.message, { toastId: "toast" })
            success && success(data)
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}

// update deal first step
export const updateDeal = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.put(`${BASE_URL}${update_deal}${payload?.dealId}/`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.deal
            toast.success(result?.data?.message, { toastId: "toast" })
            success && success(data)
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
}

// update deal restrictions
export const updateDealRestriction = (dealId, payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.put(`${BASE_URL}${update_deal}${dealId}/restrictions/`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.restrictions
            toast.success(result?.data?.message, { toastId: "toast" })
            success && success(data)
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.message)
        return error;
    }
}

// update deal images
export const updateDealImages = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.put(`${BASE_URL}${create_deal}/${payload?.dealId}/images`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.response?.data
            if (result?.data?.response?.statusCode == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success(data)
            }
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }
}

// update deal terms and conditions
export const updateDealTncs = (id, payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.put(`${BASE_URL}${create_deal}/${id}/tnc`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.response?.data
            if (result?.data?.response?.statusCode == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success(data)
            }
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }
}

// update deal status
export const updateDealsStatus = (dealIdFromLocal, payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.patch(`${BASE_URL}${create_deal}/${dealIdFromLocal}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.response?.data
            if (result?.data?.response?.statusCode == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success(data)
            }
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }
}

// upload single image
export const uploadSingleImage = (payload, access_token, success, fail) => async (dispatch) => {
    const formData = new FormData();
    formData.append('file', payload?.file);
    try {
        const result = await axios.post(`${BASE_URL}/files/upload-image?fileName=${payload?.fileName}&extension=${payload?.fileFormat}`, formData, {
            headers: {
                Authorization: `Token ${access_token}`,
                'content-type': 'multipart/form-data'
            },
        });
        if (result?.status == 200) {
            const data = result?.data?.response?.data
            if (result?.data?.response?.statusCode == 200) {
                success && success(data)
            }
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }
}