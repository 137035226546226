import { useSelector } from 'react-redux';

function useClientLandingScreenUrl() {
    const state = useSelector((state) => state);
    const tenant_data = state?.client_appointments?.common_tenant_data;
    const urls = `/profile/${tenant_data?.address_name}/${tenant_data?.address}/${tenant_data?.business_location}?business=${tenant_data?.business}&hash=${tenant_data?.hash}`
    
    return {
        urls,
        tanant_hash : tenant_data?.hash,
        tanant_business : tenant_data?.business,
    }
}

export default useClientLandingScreenUrl