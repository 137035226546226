/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { MONTHS } from "Constants/Data/Months";
import { getServiceGroup, getServiceGroupPaginationDropdown } from "Adapters/Api/saleServices";
import { get_service_target_kpi, get_service_target_kpi_without_pagination } from "Adapters/Api/serviceTarget";
import { useLocation } from "new-components/location/helper";
import { toast } from "react-toastify";

let timeOutId = undefined
let serviceGroupTimeOutId = undefined
export const useReports = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locationDetail } = useLocation();

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const { selected_location } = state?.locations;
  const reportStatus = state?.serviceTarget?.serviceTargetReports_updated;
  const reports = state?.serviceTarget?.serviceTargetReports;
  const allReports = state?.serviceTarget?.allServiceTargetReports;
  const pages = state?.serviceTarget?.serviceTargetReportsPages;
  const allCount = state?.serviceTarget?.serviceTargetReportsAllCount;
  const currencyOfSelectedLocation = locationDetail?.currency?.code


  let date = new Date();
  let today_year = date.getFullYear();
  let month = date.getMonth();
  const PageSize = 10

  const [filterServiceGroup, setFilterServiceGroup] = useState("");
  const [filterYear, setFilterYear] = useState(today_year);
  const [filterMonth, setFilterMonth] = useState(month);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [noPaginationLoader, setNoPaginationLoader] = useState(true)
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [exportLoader, setExportLoader] = useState(false);
  let selectedd_location = locationDetail?.id

  const [serviceGroupSearchText, setServiceGroupSearchText] = useState("");
  const [serviceGroupCurrentPage, setServiceGroupCurrentPage] = useState(1)
  const [serviceGroupLoader, setServiceGroupLoader] = useState(false)
  const serviceGroupPaginationData = state?.saleService?.servicesGroupDropdownPagination;
  const serviceGroups = state?.saleService.servicesGroupDropdown;


  useEffect(() => {
    if (serviceGroupSearchText === null) {
      // dispatch(getServiceGroup());
      dispatch(getServiceGroupPaginationDropdown(serviceGroupCurrentPage, "", setServiceGroupLoader))
    } else {
      if (serviceGroupTimeOutId) {
        clearTimeout(serviceGroupTimeOutId)
      }
      serviceGroupTimeOutId = setTimeout(() => {
        dispatch(getServiceGroupPaginationDropdown(serviceGroupCurrentPage, serviceGroupSearchText, setServiceGroupLoader))
      }, 500);
    }
  }, [serviceGroupSearchText, serviceGroupCurrentPage])

  useEffect(() => {
    let loader = () => {
      setLoading(true)
    }
    let success = () => {
      setLoading(false);
    }
    let fail = () => {
      setLoading(false);
    }
    if (selectedd_location) {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(get_service_target_kpi(selectedd_location, currentPage, filterMonth + 1, filterYear, filterServiceGroup, loader, success, fail));
      }, 500);
    }
  }, [filterMonth, filterYear, currentPage, selectedd_location, filterServiceGroup])

  useEffect(() => {
    setCurrentPage(1)
    setLoading(true)
    setNoPaginationLoader(true)
  }, [filterMonth, filterYear, selectedd_location, filterServiceGroup])

  // no pagination
  useEffect(() => {
    let loader = () => {
      setNoPaginationLoader(true)
    }
    let success = () => {
      setNoPaginationLoader(false);
    }
    let fail = () => {
      setNoPaginationLoader(false);
    }
    if (selectedd_location) {
      dispatch(get_service_target_kpi_without_pagination(selectedd_location, filterMonth + 1, filterYear, filterServiceGroup, loader, success, fail));
    }
  }, [selectedd_location, filterMonth + 1, filterYear, filterServiceGroup])

  // useMemo(() => {
  //   const val = reports?.map((element) => {
  //     const data = element?.location?.filter(
  //       ({ location }) => location.id === selectedd_location
  //     );
  //     if (data?.length > 0 && data !== undefined) return { ...element };
  //   });
  //   const reportsArr = val.filter((element) => element !== undefined);
  //   setDataList(reportsArr);
  // }, [reports, selected_location, filterMonth, filterYear])

  useMemo(() => {
    setDataList(reports);
  }, [reports, reports?.length, selected_location, filterMonth, filterYear])

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'filterServiceGroup':
        setFilterServiceGroup(value);
        break;
      case 'filterYear':
        setFilterYear(value);
        break;
      case 'filterMonth':
        setFilterMonth(value);
        break;
      default:
        break;
    }
  }

  const yearDropdown = useMemo(() => {
    if (today_year) {
      let years = [];
      for (let i = today_year; i <= 2040; i++) {
        years.push({ label: i.toString(), value: i.toString() })
      }
      return years;
    }
  }, [today_year])

  const monthDropdown = useMemo(() => {
    return MONTHS?.map((month, i) => {
      return {
        label: month,
        value: i,
      };
    })
  }, [])

  const serviceGroupDropdown = useMemo(() => {
    return [{ label: selectedLanguage?.name == "English" ? "All Service Group" : translation[1240]?.value ? translation[1240]?.value : "All Service Group", value: '' },
    ...serviceGroups?.map((obj) => {
      return {
        label: obj?.name,
        value: obj?.id
      }
    })]
  }, [serviceGroups])

  const getTotalService = (service) => {
    // filter data by month and calculate total price
    let data = [];
    let calculateTotalSale = '';
    service?.map(obj => {
      let containSale = obj?.sale?.length > 0;
      if (containSale) {
        let filterTotalSaleByMonthAndYear = obj?.sale?.filter(obj => parseInt(obj?.location?.id === selectedd_location && moment(obj?.created_at)?.format("M")) === filterMonth + 1 && parseInt(moment(obj?.created_at)?.format("Y")) === filterYear)?.reduce((accumulator, value) => accumulator + parseFloat(value?.total_price), 0)
        filterTotalSaleByMonthAndYear = Math.round(filterTotalSaleByMonthAndYear)
        data.push(filterTotalSaleByMonthAndYear)
      }
    })
    calculateTotalSale = data.reduce((accumulator, value) => accumulator + value, 0);
    if (calculateTotalSale) {
      return calculateTotalSale;
    }
  }

  const reportsFilter = () => {
    // const staffTargetfilterData = dataList?.filter((item) => {
    //   if (filterServiceGroup) {
    //     let return_value = false;
    //     if (filterServiceGroup) {
    //       if (
    //         item?.id
    //           ?.toLowerCase()
    //           .includes(filterServiceGroup?.toLowerCase())
    //       ) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }
    //     return return_value;
    //   }
    //   return true;
    // });
    const staffTargetfilterData = dataList
    return staffTargetfilterData;
  };
  //export csv
  let headers = [
    { label: selectedLanguage?.name == "English" ? 'Service Group' : translation[262]?.value ? translation[262]?.value : "Service Group", key: "name" },
    { label: selectedLanguage?.name == "English" ? 'Total Service Target' : translation[371]?.value ? translation[371]?.value : "Total Service Target", key: "service_target" },
    { label: selectedLanguage?.name == "English" ? 'Total Service Sales' : translation[372]?.value ? translation[372]?.value : "Total Service Sales", key: "service_sale_price" },
    { label: selectedLanguage?.name == "English" ? 'Year' : translation[376]?.value ? translation[376]?.value : "Year", key: "year" },
    { label: selectedLanguage?.name == "English" ? 'Month' : translation[377]?.value ? translation[377]?.value : "Month", key: "month" },
  ];

  const csvReport = {
    filename: "Service Target Report.csv",
    headers: headers,
    data: allReports?.map((item) => {

      return {
        ...item,
        name: item?.name ? item?.name : "--------",
        service_target: item?.service_target ? item?.service_target : "--------",
        service_sale_price: getTotalService(item?.service) ? getTotalService(item?.service) : "--------",
        year: filterYear ? filterYear : "--------",
        month: MONTHS[filterMonth] ? MONTHS[filterMonth] : "--------",
      };
    }),
  };

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allReports?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  return {
    reportStatus, filterMonth, filterYear, filterServiceGroup, yearDropdown, monthDropdown, csvReport, loading,
    serviceGroupDropdown, getTotalService, reportsFilter, navigate, handleChange, translation, selectedLanguage,
    currentPage, setCurrentPage, PageSize, allCount, pages, allReports, handleExportClick, exportLoader, currencyOfSelectedLocation,
    serviceGroupSearchText, setServiceGroupSearchText, serviceGroupCurrentPage, setServiceGroupCurrentPage, serviceGroupLoader, serviceGroupPaginationData,

  }
};