import { ATTENDANCE } from "../../ActionTypes/attendanceTypes";


const initialState = {
    attendance: [],
    attendance_updated: false,
    pages: 0,
    count: 0,
    allAttendance: []
};


const AttendanceReducers = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {

        case ATTENDANCE.GET_ATTENDANCE:
            return {
                ...state,
                attendance_updated: true,
                attendance: payload,
            };

        case ATTENDANCE.GET_ATTENDANCE_WITHOUT_PAGINATION:
            return {
                ...state,
                attendance_updated: true,
                allAttendance: payload,
            };
        case ATTENDANCE.GET_ATTENDANCE_PAGES:
            return {
                ...state,
                pages: payload,
            };

        case ATTENDANCE.GET_ATTENDANCE_COUNT:
            return {
                ...state,
                count: payload,
            };

        case ATTENDANCE.DELETE_ATTENDANCE:
            let result = state?.attendance?.filter((item) => item.id !== payload);
            return {
                ...state,
                attendance: [...result],
            };


        // case ATTENDANCE.ADD_ATTENDANCE:
        // return {
        //     ...state,
        //     attendance: [...state.attendance, payload]
        // };

        // case ATTENDANCE.UPDATE_ATTENDANCE:

        //     let updateList = state?.attendance?.map((item) => {
        //         if (item.id === payload.id) {
        //             return payload
        //         } else {
        //             return item;
        //         }
        //     })
        //     return {
        //         ...state,
        //         attendance: [...updateList],
        //     };

        default:
            return state;
    }
};
export default AttendanceReducers;
