import React, { useState, useEffect } from "react";

import Input from "new-components/input";
import AddProductBtn from "../AddHeaderBtns/AddProductBtn";
import AddCategoryBtn from "../AddHeaderBtns/AddCategoryBtn";
import DeleteConfirmation from "new-components/delete-popup";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
import NotFound from "Components/TenantApp/Dashboard/Sales/Elements/NotFound";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";

import { usePurchaseOrder } from "./helper";

import Svgs from "Assets/svgs";

import { toast } from 'react-toastify'
import UpdatePurchaseStatus from "./update-purchase-status";
import LocationSelection from "new-components/location";
import { useUpdatePurchaseStatus } from "./update-purchase-status/helper";
import Pagination from "new-components/Pagination";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import IconButton from "Components/TenantApp/Elements/IconButton";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import NoPurchaseOrderDataFound from "Components/Utility/NoPurchaseOrderDataFound";
import TableShimmer_v2 from "Components/Utility/Shimmers/TableShimmer_v2";
import Shimmer from "Components/Utility/Shimmers/Shimmer";

const PurchaseOrders = () => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const { searchText, OrderStatus, ShowDetails, isDelete, dataList, deleteClose, updateStatus, updateData, setUpdateStatus,
    update_status, navigate, setDeleteId, setSearchText, setDeleteClose, setOrderStatus, setShowDetails, handleDeleteOrder,
    currentPage, setCurrentPage, purchasePages, allCount, allPurchases, loader, exportLoader, setExportLoader
  } = usePurchaseOrder();
  const PageSize = 10

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);


  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  let headers = [
    { label: selectedLanguage?.name == "English" ? "Order Id" : translation[1020]?.value ? translation[1020]?.value : "Order Id", key: 'Order_id' },
    { label: selectedLanguage?.name == "English" ? "Vendor" : translation[962]?.value ? translation[962]?.value : "Vendor", key: 'vendor' },
    { label: selectedLanguage?.name == "English" ? "Delivery Location" : translation[1021]?.value ? translation[1021]?.value : "Delivery Location", key: 'delivery_location' },
    { label: selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products", key: 'products' },
    { label: selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status", key: 'status' },
    { label: selectedLanguage?.name == "English" ? "Quantity" : translation[365]?.value ? translation[365]?.value : "Quantity", key: 'quantity' },
  ]

  const csvReport = {
    filename: "Purchase Order Report.csv",
    headers: headers,
    data: allPurchases?.map(itm => {
      return {
        ...itm,
        Order_id: itm.id.slice(0, 6),
        vendor: itm?.vendor_name ? itm?.vendor_name : '--------',
        delivery_location: itm?.to_location_name,
        // products: itm?.products?.product?.name ? itm?.products?.product?.name : '--------',
        products: itm?.products?.map((ele) => (ele?.product?.name ? ele?.product?.name : '--------')),
        quantity: itm?.products?.map((ele) => (ele?.quantity)),
        status: itm?.products?.map((ele) => (ele?.status))
      }
    })
  }

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allPurchases?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <p className="text-[#7B7B7B] text-sm">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/");
            }}
          >
            {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          </span>{" "}
          &gt;{" "}
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/inventory-operations");
            }}
          >
            {selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations"}
          </span>{" "}
          &gt; <span className="text-primary font-semibold">
            {selectedLanguage?.name == "English" ? "Purchases" : translation[1018]?.value ? translation[1018]?.value : "Purchases"}
          </span>
        </p>
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Purchases" : translation[1018]?.value ? translation[1018]?.value : "Purchases"}
            </h2>
            <LocationSelection />
          </div>
          <div className="flex items-center sm:gap-4 gap-2">
            {loadingPermission ?
              <Shimmer className={'w-32 lg:w-36'}>
                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
              </Shimmer>
              : !employee && <>
                <LoginBtn
                  onClick={() => {
                    navigate("/dashboard/account/inventory-operations/order-stock");
                  }}
                >
                  <div className="flex items-center gap-2 text-[#fff]">
                    <Svgs.Plus color="#fff" />
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "Order Stock" : translation[1019]?.value ? translation[1019]?.value : "Order Stock"}
                    </p>
                  </div>
                </LoginBtn>
                {/* <AddProductBtn />
                <AddCategoryBtn fill="true" /> */}
              </>
            }
            {loadingPermission ?
              <Shimmer className={'w-32 lg:w-36'}>
                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
              </Shimmer>
              : employee && employeePermissions?.inventory_purchase_order?.includes("create") ? (<>
                <LoginBtn
                  onClick={() => {
                    navigate("/dashboard/account/inventory-operations/order-stock");
                  }}
                >
                  <div className="flex items-center gap-2 text-[#fff]">
                    <Svgs.Plus color="#fff" />
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "Order Stock" : translation[1019]?.value ? translation[1019]?.value : "Order Stock"}
                    </p>
                  </div>
                </LoginBtn>
                {/* <AddProductBtn />
                <AddCategoryBtn fill="true" /> */}
              </>
              ) : (
                ""
              )}
          </div>
        </div>

        <div className="border rounded-lg">
          <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 ">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl ">
                  {selectedLanguage?.name == "English" ? "Purchase Management" : translation[1017]?.value ? translation[1017]?.value : "Purchase Management"}
                </h2>

                {loader ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">{allCount} {" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Purchases" : translation[1018]?.value ? translation[1018]?.value : "Purchases"
                        : selectedLanguage?.name == "English" ? "Purchase" : translation[1317]?.value ? translation[1317]?.value : "Purchase"
                      }
                    </p>
                  </div>
                }
              </div>
              <div className="mb-00">
                {loadingPermission ?
                  <Shimmer className={'w-44 lg:w-48'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                  </Shimmer>
                  :
                  <LoginInput
                    placeholder={selectedLanguage?.name == "English" ? "Search order id" : translation[1276]?.value ? translation[1276]?.value : "Search order id"}
                    padding="py-1"
                    value={searchText}
                    title=""
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    leftIcon={<Svgs.Search />}
                  />
                }
              </div>
            </div>
            <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
              {loadingPermission ?
                <Shimmer className={'w-12 lg:w-16'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                : exportLoader ? (
                  <div className="h-[4rem] flex items-center justify-center">
                    <PreloaderSm />
                  </div>
                ) :
                  !employee ? (
                    allPurchases?.length > 0 ?
                      <CSVLink {...csvReport} target="_blank" onClick={handleExportClick}>
                        <IconButton filled>
                          <Svgs.Export />
                        </IconButton>
                      </CSVLink> :
                      <LoginBtn
                        className="rounded-lg border-2 border-primary"
                        bg="bg-white"
                        animation={false}
                        onClick={handleExportClick}
                      >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                          <Svgs.Export bg="#FFFFFF" />
                        </div>
                      </LoginBtn>
                  ) : (
                    ""
                  )}

            </div>
          </div>
          {/* old search filter
          ?.filter((itm, index) => {
              if (itm?.id?.toLowerCase().includes(searchText.toLowerCase())) {
                return true;
              } else {
                return false;
              }
            }) */}
          {/* {dataList &&
            dataList?.length > 0 ? ( */}
          <>
            {loader ?
              <TableShimmer_v2 cols={4} rows={12} rowHeight={'h-12'} />
              :
              <div className="overflow-x-auto">
                <div className="inline-grid xl:grid grid-cols-[repeat(4,minmax(155px,1fr))] px-12 py-3 bg-[#FAF9FF] text-sm font-semibold min-w-full w-fit">
                  <p>{selectedLanguage?.name == "English" ? "Order Id" : translation[1020]?.value ? translation[1020]?.value : "Order Id"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Vendor" : translation[962]?.value ? translation[962]?.value : "Vendor"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}</p>
                  {/* <p>Status</p> */}
                  {/* <p>Update Status</p> */}
                  <p className="text-center">{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
                </div>
                {dataList?.length > 0 ? (dataList?.map((item, i) => {
                  return (
                    <>
                      {/* <div
                        className={`odd-bg lg:w-full w-full text-[#3A3A3A] transition-all  ${ShowDetails == item.id
                          ? "border-l-[4px] border-[#5679FFB2]"
                          : "border-l-[0px]"
                          }`}
                      > */}
                      <div className={`px-12 py-3 inline-grid xl:grid grid-cols-[repeat(4,minmax(155px,1fr))] min-w-full w-fit odd-bg text-[#3A3A3A] transition-all order-l-[0px]`} key={i}>
                        <div
                          className={`before-left-arrow cursor-pointer ${ShowDetails == item.id
                            ? "before-left-arrow-open"
                            : "before-left-arrow-close"
                            }`}
                          onClick={() => {
                            setShowDetails(ShowDetails != item.id ? item.id : undefined);
                          }}
                        >
                          <div className="h-full flex items-center">
                            <p className="text-sm">{item.id?.slice(0, 6)}</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">{item.vendor_name}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">{item.to_location_name}</p>
                        </div>

                        {/* <div className="flex items-center">
                              <StatusBtn
                                status={item.status ? "Active" : "InActive"}
                              />
                            </div> */}

                        <div className="flex items-center gap-2 cursor-pointer justify-center">
                          {/* <div
                                onClick={() => {
                                  navigate(
                                    `/dashboard/account/inventory-operations/order-stock/${item.id}`
                                  );
                                }}
                                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center cursor-pointer"
                              >
                                <span>
                                  <Svgs.Pen />
                                </span>
                              </div> */}
                          {!employee ? (
                            <div
                              onClick={() => {
                                setDeleteClose(true);
                                setDeleteId(item.id);
                              }}
                              className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center cursor-pointer"
                            >
                              <span>
                                <Svgs.Delete />{" "}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {item.id === ShowDetails &&
                        <div className="overflow-x-scroll">
                          {item?.products?.map((ele, index) => (
                            <div className="scale-in-center min-w-fit" key={index}>
                              <div className="bg-[#FAF9FF] px-6 py-3 !w-full">
                                <div className="inline-grid xl:grid grid-cols-[repeat(7,minmax(155px,1fr))] gap-y-5 gap-x-3 min-w-full w-fit">
                                  <div className="flex flex-col gap-1">
                                    <p className="text-sm text-[#969BA0]">
                                      {selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}
                                    </p>
                                    <p className="text-black font-semibold">
                                      {ele?.product?.name || "--"}
                                    </p>
                                  </div>
                                  <div className="flex flex-col gap-1">
                                    <p className="text-sm text-[#969BA0]">
                                      {selectedLanguage?.name == "English" ? "Vendor" : translation[962]?.value ? translation[962]?.value : "Vendor"}
                                    </p>
                                    <p className="text-black font-semibold">
                                      {item.vendor_name || "--"}
                                    </p>
                                  </div>
                                  <div className="flex flex-col gap-1">
                                    <p className="text-sm text-[#969BA0]">
                                      {selectedLanguage?.name == "English" ? "Delivery Location" : translation[1021]?.value ? translation[1021]?.value : "Delivery Location"}
                                    </p>
                                    <p className="text-black font-semibold word-break line-clamp-1">
                                      {item.to_location_name || "--"}
                                    </p>
                                  </div>
                                  {!employee || (employee &&
                                    employeePermissions?.inventory_purchase_order?.includes(
                                      "edit"
                                    )) ? (
                                    <div className="flex flex-col gap-1">
                                      <p className="text-sm text-[#969BA0]">
                                        {selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                                      </p>
                                      <p className="text-black font-semibold word-break line-clamp-1">
                                        <LoginBtn
                                          className={ele?.is_finished ? 'bg-[#05D672] text-white' : "theme-btn"}
                                          customThemeBtn={'custom-theme-btn'}
                                          text={ele?.is_finished ? selectedLanguage?.name == "English" ? "Closed" : translation[1048]?.value ? translation[1048]?.value : "Closed"
                                            : selectedLanguage?.name == "English" ? "Update Status" : translation[1048]?.value ? translation[1048]?.value : "Update Status"}
                                          onClick={() => {
                                            update_status(item.id, ele.id);
                                          }}
                                          disabled={ele?.is_finished}
                                        />
                                      </p>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="flex flex-col gap-1">
                                    <p className="text-sm text-[#969BA0]">
                                      {selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                                    </p>
                                    <p className="text-black font-semibold word-break">
                                      {/* <div className={`border ${'border-[#05D672] text-[#05D672] bg-[#d2fdf4]'} ${'rounded-full'} 'px-5 py-2 text-xs'} flex items-center w-fit gap-2`}>
                                              <Svgs.Bullet fill={'#05D672'} />
                                              <p className='capitalize'>{ele.status}</p>
                                          </div> */}
                                      <StatusBtn
                                        text={ele?.status}
                                        status="Active"
                                      />
                                    </p>
                                  </div>
                                  <div className="flex items-end justify-between">
                                    <div className="flex flex-col gap-1">
                                      <p className="text-sm text-[#969BA0]">
                                        {selectedLanguage?.name == "English" ? "Ordered" : translation[1022]?.value ? translation[1022]?.value : "Ordered"}
                                      </p>
                                      <p className="text-black font-semibold">
                                        {ele?.quantity || "--"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex items-end justify-between">
                                    <div className="flex flex-col gap-1">
                                      <p className="text-sm text-[#969BA0]">
                                        {selectedLanguage?.name == "English" ? "Recieved" : translation[1023]?.value ? translation[1023]?.value : "Recieved"}
                                      </p>
                                      <p className="text-black font-semibold">
                                        {ele?.rec_quantity || "--"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      }
                    </>
                  );
                })) : (
                  <NoPurchaseOrderDataFound />
                )}
              </div>
            }

            {(dataList?.length > 0 && !loader) &&
              <Pagination
                currentPage={currentPage}
                totalCount={Math.ceil(purchasePages)}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
              />
            }
          </>
          {/* ) : (
            <NotFound />
          )} */}
        </div>
      </div>

      {updateStatus && (
        <UpdatePurchaseStatus
          handleClose={() => setUpdateStatus(false)}
          dataList={dataList}
          translation={translation}
          selectedLanguage={selectedLanguage}
          updateData={updateData}
        />
      )
      }

      {
        deleteClose && (
          <DeleteConfirmation
            title={selectedLanguage?.name == "English" ? "Order Stock" : translation[1019]?.value ? translation[1019]?.value : "Order Stock"}
            handleClose={() => setDeleteClose(false)}
            handleDelete={handleDeleteOrder}
            isLoading={isDelete}
          />
        )
      }
    </>
  );
};

export default PurchaseOrders;
