import React from 'react'

const Holiday = () => {
    return (
        <div className={`schedule-grid-item bg-[#8238E5]/10 flex justify-center items-center flex-col gap-1`}>
            <p className='font-medium text-[#8238E5]'>Holiday</p>
        </div>
    )
}

export default Holiday