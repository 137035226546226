import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import useObjectKeys from "Hooks/useObjectKeys";
import { add_coupons_discount, get_client_discount_promotion, update_coupons_discount } from "Adapters/Api/clientDiscountPromotion";
import { getServiceGroup } from "Adapters/Api/saleServices";
import { discountErrors, navigateToDiscountPromotion } from "../../helper";
import { useRef } from "react";
import { getsProductsDropdownList } from "Adapters/Api/Products";
import { getClientDropDownList } from "Adapters/Api/clients";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import { getBrandDropdownlist, get_business_brands } from "Adapters/Api/Brand";
import { getserviceDropdownList } from "Adapters/Api/services";
import { generateUniqueCode } from "Constants/Functions/generateRandomCodes";

// initial state 
const initialState = {
    buyOneGetOne: {
        type: '',
        selectedType: ''
    },
    fixedAmount: {
        spendAmount: '',
        discount: ''
    },
    specificServiceGroupBrandDiscount: [{
        service_group: '',
        discount: '',
        brand: '',
        brand_discount: '',
        is_deleted: 'False',
    }],
    brandRestriction: '',
    brandRestrictions: [],
    serviceRestriction: '',
    serviceRestrictions: [],
    coupon_name: '',
    coupon_description: '',
    couponType: 'Specific Product And Services',
    couponTypeValue: 1,
    coupon_code: '',
    client: 'All',
    clients: [],
    client_type: 'Walk_in',
    usageLimit: '',
    userLimit: '',
    status: true,
    dateRestriction: { startDate: '', endDate: '' },
    storeRestriction: '',
    storeRestrictions: [],
    dayRestrictions: [
        { day: 'Monday', value: '', is_deleted: 'False' },
        { day: 'Tuesday', value: '', is_deleted: 'False' },
        { day: 'Wednesday', value: '', is_deleted: 'False' },
        { day: 'Thursday', value: '', is_deleted: 'False' },
        { day: 'Friday', value: '', is_deleted: 'False' },
        { day: 'Saturday', value: '', is_deleted: 'False' },
        { day: 'Sunday', value: '', is_deleted: 'False' },
    ]
}
export const useAddSpecificDiscount = () => {
    const formRef = useRef()
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const { objectKeyConvertToArray } = useObjectKeys();

    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");

    const [isEdit, setIsEdit] = useState(false);
    const [toggleStore, setToggleStore] = useState(false);
    const [toggleDay, setToggleDay] = useState(false);
    const [loading, setLoading] = useState(false);
    const [discount, setDiscount] = useState(initialState);
    const [formErrors, setFormErrors] = useState()
    const [showAddClientPopup, setShowAddClientPopup] = useState(false)
    const [serviceToggle, setServiceToggle] = useState(false)
    const [brandToggle, setBrandToggle] = useState(false)

    const state = useSelector((state) => state);
    const clientRedux = state?.client?.clients
    const businessId = state?.business?.business?.id;
    const locations = state?.locations?.locations;
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const serviceGroupRedux = state?.saleService?.servicesgroup;
    const servicesRedux = state?.service?.services;
    const brandRedux = state?.brand?.brands;
    const productRedux = state?.product?.products;
    const reduxServices = state?.service?.services;
    const reduxProducts = state.product?.products;

    const categoryDiscountDropdown = [{ value: 'All', label: 'All' }, { value: 'Service', label: 'Service' }, { value: 'Retail', label: 'Retail' }, { value: 'Voucher', label: 'Voucher' }]
    const choices = [{ label: 'Service', value: 'Service' }, { label: 'Product', value: 'Product' }]
    const clientTypeOptions = [
        { value: 'Walk_in', label: selectedLanguage?.name == "English" ? "Walk-in" : translation[383]?.value ? translation[383]?.value : "Walk-in" },
        { value: 'In_Saloon', label: selectedLanguage?.name == "English" ? "In-Saloon" : translation[384]?.value ? translation[384]?.value : "In-Saloon" },
    ]

    const {
        storeRestrictions,
        buyOneGetOne,
        fixedAmount,
        couponType
    } = discount;

    const getClients = () => {
        dispatch(getClientDropDownList())
    }

    useEffect(() => {
        dispatch(getServiceGroup());
        // dispatch(get_business_brands());
        dispatch(getBrandDropdownlist());
        dispatch(getserviceDropdownList());
        get_products();
    }, [])

    useEffect(() => {
        // get param for edit 
        if (params.id === undefined) {
            setIsEdit(false);
        }
        if (state?.clientDiscountPromotion?.discountPromotions?.length === 0 && params.id) {
            dispatch(get_client_discount_promotion())
            setIsEdit(true);
        } if (params.id) {
            setIsEdit(true);
        }
    }, [params.id, state?.clientDiscountPromotion?.discountPromotions?.length]);

    useEffect(() => {
        // useEffect for get edit data and store in discount object
        let editFlatDiscount = state?.clientDiscountPromotion?.discountPromotions?.find((obj) => obj.id === params.id);

        if (editFlatDiscount) {
            let { id, name, short_description, start_date, end_date, usage_limit, user_limit, requested_status, code, coupon_type_value, coupon_type,
                amount_spent, discounted_percentage, locations, coupon_blockdays, excluded_products, excluded_services, clients, buy_one_get_one_product,
                buy_one_get_one_service, buy_one_type, servicegroup_discount, client_type } = editFlatDiscount;

            let dayRestrictions = initialState?.dayRestrictions?.map((item) => {
                let obj = coupon_blockdays.find(obj => obj.day == item.day);
                if (obj?.day === item.day) {
                    return {
                        id: obj?.id,
                        day: obj?.day,
                        value: obj?.day,
                        is_deleted: obj?.is_deleted
                    }
                } else {
                    return { ...item }
                }
            })
            coupon_blockdays?.length > 0 && setToggleDay(true);
            locations?.length > 0 && setToggleStore(true);
            excluded_services?.length > 0 && setServiceToggle(true)
            excluded_products?.length > 0 && setBrandToggle(true)

            let data = {
                id: id,
                coupon_name: name,
                coupon_description: short_description,
                usageLimit: usage_limit,
                userLimit: user_limit,
                status: requested_status,
                dateRestriction: { startDate: start_date, endDate: end_date },
                couponType: coupon_type,
                couponTypeValue: +coupon_type_value,
                coupon_code: code,
                dayRestrictions: dayRestrictions,
                storeRestrictions: locations,
                client_type: client_type,
                clients: clients,
                client: clients?.length === 0 ? 'All' : ''
            }
            if (editFlatDiscount && start_date && end_date) {
                if (+coupon_type_value === 1) {
                    data = {
                        ...data,
                        brandRestrictions: excluded_products,
                        serviceRestrictions: excluded_services,
                        specificServiceGroupBrandDiscount: servicegroup_discount?.map((obj) => {
                            return {
                                ...obj,
                                service_group: obj?.service_group[0]?.service_group,
                                discount: obj?.service_group[0]?.service_group_discount,
                                brand: obj?.brand[0]?.brand,
                                brand_discount: obj?.brand[0]?.brand_discount,
                                is_deleted: obj?.is_deleted ? obj?.is_deleted : 'False'
                            }
                        }),
                    }
                } else if (+coupon_type_value === 2) {
                    data = {
                        ...data,
                        buyOneGetOne: {
                            type: buy_one_type,
                            selectedType: buy_one_get_one_product?.length > 0 ? buy_one_get_one_product[0] : buy_one_get_one_service[0]
                        }
                    }
                } else {
                    data = {
                        ...data,
                        fixedAmount: {
                            spendAmount: amount_spent,
                            discount: discounted_percentage
                        }
                    }
                }
                setDiscount(data)
            }
        }
    }, [isEdit, state?.clientDiscountPromotion?.discountPromotions?.length])

    // get api for product
    const get_products = async () => {
        const response = await getsProductsDropdownList();
        if (response.status === 200) {
            let productList = response?.data?.response?.products;
            dispatch(getProducts(productList));
        }
    };

    // dropdown option of client
    const clientDropdown = useMemo(() => {
        return clientRedux?.map((clnt, i) => {
            if (clnt) {
                return {
                    label: clnt.full_name,
                    value: clnt.id,
                    otherData: {
                        image: clnt?.image,
                        DataOne: clnt.email,
                        showImage: true,
                        index: i
                    },
                    email: clnt.email,
                    mobile_number: clnt.mobile_number
                }
            }
        })
    }, [clientRedux])

    // general on change function 
    const handleChange = (e) => {
        const { value, name } = e.target;
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
        if (name === 'startDate' || name === 'endDate') {
            if (name === 'startDate') {
                setDiscount((prev) => ({
                    ...prev,
                    dateRestriction: { ...prev.dateRestriction, endDate: "" }
                }));
            }
            setDiscount((prev) => ({
                ...prev,
                dateRestriction: { ...prev?.dateRestriction, [name]: value }
            }));
        }

        else {
            setDiscount((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    }

    // on change function for specific discount
    const addServiceGroupDiscount = (e, i) => {
        const { name, value } = e.target;
        if (value) {
            setErrors((prev) => ({ ...prev, [`${name}${i}`]: "" }))
        }
        if (name == "service_group") {
            setDiscount((prev) => ({
                ...prev,
                serviceRestrictions: []
            }));
        }

        setDiscount((prev) => ({
            ...prev,
            specificServiceGroupBrandDiscount: [
                ...prev?.specificServiceGroupBrandDiscount.map((obj, index) => {
                    if (i === index) {
                        return {
                            ...obj,
                            [name]: value,
                            is_deleted: 'False'
                        }
                    }
                    else {
                        return obj
                    }
                })
            ],
        }));
    }

    // remove selected values from specific discount
    const filterServiceGroupDiscountField = (id, index) => {
        if (id) {  // edit 
            var filter = discount?.specificServiceGroupBrandDiscount?.map((obj) => {
                if (obj === discount?.specificServiceGroupBrandDiscount[index]) {
                    return {
                        ...obj,
                        is_deleted: 'True'
                    }
                }
                else {
                    return { ...obj }
                }
            })
            setDiscount((prev) => ({
                ...prev,
                specificServiceGroupBrandDiscount: filter,
            }))
        }
        else { // create no need of isDelete here 
            let filter = discount.specificServiceGroupBrandDiscount.filter((obj) => obj !== discount.specificServiceGroupBrandDiscount[index])
            let serviceRestrictionsData = filter?.filter(itm => serviceExcludedDropdown?.find(item => item == itm?.value))
            let brandRestrictionsData = filter?.filter(itm => productExcludedDropdown?.find(item => item == itm?.value))

            setDiscount((prev) => ({
                ...prev,
                specificServiceGroupBrandDiscount: filter,
                brandRestrictions: brandRestrictionsData,
                serviceRestrictions: serviceRestrictionsData
            }))
        }
    }

    // function to generate random coupon code
    const generateCouponCode = () => {
        // function for random code with specific length
        const uniqueCode = generateUniqueCode(10, 10)
        setDiscount((prev) => ({
            ...prev,
            coupon_code: uniqueCode
        }))
        setErrors((prev) => ({
            ...prev,
            coupon_code: "",
        }));
    }

    // on change function for coupon type
    const handleChangeCouponType = (type, typeValue) => {
        if (type !== couponType) {
            setDiscount((prev) => ({
                ...prev,
                couponType: type,
                couponTypeValue: typeValue,
                buyOneGetOne: {
                    type: '',
                    selectedType: ''
                },
                fixedAmount: {
                    spendAmount: '',
                    discount: ''
                },
                specificServiceGroupBrandDiscount: [{
                    service_group: '',
                    discount: '',
                    brand: '',
                    brand_discount: '',
                    is_deleted: 'False',
                }]
            }))
            setErrors((prev) => ({
                ...prev,
                type: "",
                selectedType: "",
                spendAmount: "",
                discount: ""
            }));
            setFormErrors([])
        }
    }

    // add function for store restriction
    const addStoreRestriction = () => {
        setDiscount((prev) => ({
            ...prev,
            storeRestrictions: [...prev.storeRestrictions, discount.storeRestriction],
            storeRestriction: ''
        }))
        setErrors((prev) => ({
            ...prev,
            storeRestriction: "",
        }));
    }

    // add brand restriction for specific discount
    const addBrandRestriction = () => {
        setDiscount((prev) => ({
            ...prev,
            brandRestrictions: [...prev.brandRestrictions, discount.brandRestriction],
            brandRestriction: ''
        }))
    }

    // add service restriction for specific discount
    const addServiceRestriction = () => {
        setDiscount((prev) => ({
            ...prev,
            serviceRestrictions: [...prev.serviceRestrictions, discount.serviceRestriction],
            serviceRestriction: ''
        }))
    }

    // filter store restrictions
    const filterStoreRestriction = (index) => {
        var filter = storeRestrictions?.filter(obj => obj !== storeRestrictions[index])
        setDiscount((prev) => ({
            ...prev,
            storeRestrictions: filter,
        }))
    }

    // on change function for day restrictions
    const handleDayChecklist = (e) => {
        setDiscount((prev) => ({
            ...prev,
            dayRestrictions: discount?.dayRestrictions?.map(obj => {
                // handle checklist withoutd ids 
                // check function
                if (obj.day === e.target.name && obj.value === '' && obj.is_deleted === 'False') {
                    return {
                        ...obj,
                        value: e.target.name
                    }
                }
                // UN Check block
                if (obj.value === e.target.value) {
                    return {
                        ...obj,
                        value: ''
                    }
                }

                // handling update scenarios for checklist
                // un check block for checked
                if (obj.id && obj.value === e.target.value && obj.is_deleted === 'False') {
                    return {
                        ...obj,
                        is_deleted: 'True',
                        value: ''
                    }
                }

                // checked block and isDeleted 
                if (obj.day === e.target.name && obj.value === '' && obj.is_deleted && obj.id) {
                    return {
                        ...obj,
                        value: e.target.name,
                        is_deleted: 'False'
                    }
                }
                else {
                    return obj
                }
            })
        }))
    }

    // on change function for client
    const addClients = (e) => {
        const { value } = e.target;
        if (value == 'All') {
            setDiscount((prev) => ({
                ...prev,
                clients: [],
                client: 'All'
            }))
        } else {
            setDiscount((prev) => ({
                ...prev,
                clients: [...prev.clients, value],
                client: ''
            }))
        }
    }

    // remove selected clients 
    const filterClient = (id) => {
        let filter = discount.clients.filter((obj) => obj !== id)
        if (filter?.length > 0) {
            setDiscount((prev) => ({
                ...prev,
                clients: filter,
                client: ''
            }))
        } else {
            setDiscount((prev) => ({
                ...prev,
                clients: [],
                client: 'All'
            }))
        }
    }

    // function to get client name
    const getClientName = (id, data) => {
        return data.find((obj) => obj.value === id)?.label;
    }

    // on change function for Buy One Get One discount
    const handleBuyOneGetOne = (e) => {
        const { name, value } = e.target;
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
        if (name === "type") {
            setDiscount((prev) => ({
                ...prev,
                buyOneGetOne: { type: value, selectedType: "" },
            }))
        } else {
            setDiscount((prev) => ({
                ...prev,
                buyOneGetOne: { ...buyOneGetOne, [name]: value },
            }))
        }


    }

    // on change function for fixed amount discount
    const handleFixedAmountDiscount = (e) => {
        const { name, value } = e.target;
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));

        setDiscount((prev) => ({
            ...prev,
            fixedAmount: { ...fixedAmount, [name]: value },
        }))

    }

    // location dropdown
    const locationDropdown = useMemo(() => {
        return locations?.map((obj) => {
            if (obj) {
                return {
                    label: obj?.address_name,
                    value: obj?.id
                }
            }
        })
    }, [locations])

    // product dropdown
    const productDropdown = useMemo(() => {
        return reduxProducts?.map((obj) => {
            if (obj) {
                return {
                    label: obj?.name,
                    value: obj?.id
                }
            }
        })
    }, [reduxProducts, discount?.buyOneGetOne?.type])

    // service dropdown
    const serviceDropdown = useMemo(() => {
        return reduxServices?.map((obj) => {
            if (obj) {
                return {
                    label: obj?.name,
                    value: obj?.id
                }
            }
        })
    }, [reduxServices, discount?.buyOneGetOne?.type])

    // service group discount
    const serviceGroupDropdown = useMemo(() => {
        return serviceGroupRedux?.map((obj) => {
            if (obj) {
                return {
                    label: obj?.name,
                    value: obj?.id
                }
            }
        })
    }, [serviceGroupRedux])

    // brand dropdown
    const brandDropdown = useMemo(() => {
        return brandRedux?.map((obj) => {
            return {
                label: obj.name,
                value: obj.id
            }
        })
    }, [brandRedux])

    // service excluded dropdown
    const serviceExcludedDropdown = useMemo(() => {
        let newArr = []
        discount?.specificServiceGroupBrandDiscount
            ?.map(itm => {
                newArr = [...newArr, ...servicesRedux?.filter(item => item?.service_group?.find(tm => tm.id === itm?.service_group))]
            })

        return newArr?.map((obj) => {
            if (obj)
                return {
                    label: obj.name,
                    value: obj.id
                }
        })
    }, [discount.specificServiceGroupBrandDiscount])

    // product excluded dropdown
    const productExcludedDropdown = useMemo(() => {
        let newArr = []
        discount?.specificServiceGroupBrandDiscount
            ?.map(itm =>
                newArr = [...newArr, ...productRedux?.filter(item => item?.brand?.id === itm?.brand)]
            )

        return newArr?.map((obj) => {
            return {
                label: obj.name,
                value: obj.id
            }
        })
    }, [discount.specificServiceGroupBrandDiscount])


    // success function
    let success = () => {
        setLoading(false);
        setDiscount(initialState);
        navigateToDiscountPromotion(navigate)
        setToggleDay(false);
        setToggleStore(false);
    }
    // fail function
    let fail = () => {
        setLoading(false);
    }

    // on submit function for create and edit
    const onSubmit = () => {
        let submit = false
        let payload = {
            business: businessId,
            coupon_name: discount.coupon_name,
            coupon_description: discount.coupon_description,
            couponType: discount.couponType,
            couponTypeValue: discount.couponTypeValue,
            coupon_code: discount.coupon_code,
            startDate: discount.dateRestriction.startDate,
            endDate: discount.dateRestriction.endDate,
            usageLimit: discount.usageLimit,
            userLimit: discount.userLimit,
            storeRestrictions: discount.storeRestrictions,
            status: discount.status,
            client_type: discount.client_type
        }
        if (discount.clients?.length > 0 && discount.client_type === 'In_Saloon') {
            payload = { ...payload, clients: discount.clients }
        }

        let requiredFields = {
            coupon_name: discount.coupon_name,
            coupon_description: discount.coupon_description,
            startDate: discount.dateRestriction.startDate,
            endDate: discount.dateRestriction.endDate,
            usageLimit: discount.usageLimit,
            userLimit: discount.userLimit,
            coupon_code: discount.coupon_code,
            storeRestriction: discount?.storeRestrictions?.length > 0 ? true : false
        }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);

        if (objectKeyConvertToArray(result)?.length === 0) {
            if (discount?.couponTypeValue === 1) {
                const errors = [];
                // Required Values For First Type Of Coupon
                discount.specificServiceGroupBrandDiscount?.forEach((data, index) => {
                    const fieldErrors = {};
                    if (!data.service_group) {
                        fieldErrors.service_group = 'This field is required';
                    }
                    if (!data.discount) {
                        fieldErrors.discount = 'This field is required';
                    }
                    if (!data.brand) {
                        fieldErrors.brand = 'This field is required';
                    }
                    if (!data.brand_discount) {
                        fieldErrors.brand_discount = 'This field is required';
                    }
                    errors[index] = fieldErrors;
                });
                // Update formErrors state with the new errors
                setFormErrors(errors);
                // If there are errors, return early and do not proceed with form submission
                if (errors?.some((error) => Object.keys(error)?.length > 0)) {
                    formRef.current?.scrollIntoView({ behavior: "smooth" });
                    return;
                } else {
                    // Specify the keys you want to exclude
                    const keysToDelete = ['fixedAmount', 'buyOneGetOne'];
                    // Use destructuring and the rest operator to create a new object without the specified keys
                    const filteredPayload = Object.fromEntries(
                        Object.entries(payload).filter(([key]) => !keysToDelete.includes(key))
                    );
                    payload = {
                        ...filteredPayload,
                        specificServiceGroupBrandDiscount: discount.specificServiceGroupBrandDiscount,
                        excludedServices: discount.serviceRestrictions,
                        excludedProducts: discount.brandRestrictions
                    }
                    submit = true
                }
            } else if (discount?.couponTypeValue === 2) {
                // Required Values For Second Type Of Coupon
                const secondStepRequiredFields = {
                    type: buyOneGetOne.type,
                    selectedType: buyOneGetOne.selectedType
                }
                setErrors(validation(secondStepRequiredFields));
                let result = validation(secondStepRequiredFields);
                if (objectKeyConvertToArray(result)?.length === 0) {
                    // Specify the keys you want to exclude
                    const keysToDelete = ['specificServiceGroupBrandDiscount', 'serviceRestrictions', 'brandRestrictions', 'fixedAmount'];

                    // Use destructuring and the rest operator to create a new object without the specified keys
                    const filteredPayload = Object.fromEntries(
                        Object.entries(payload).filter(([key]) => !keysToDelete.includes(key))
                    );
                    payload = { ...filteredPayload, type: buyOneGetOne.type, selectedType: buyOneGetOne.selectedType }
                    submit = true
                } else {
                    submit = false
                }
            } else {
                // Required Values For third Type Of Coupon
                const thirdStepRequiredFields = {
                    spendAmount: fixedAmount.spendAmount,
                    discount: fixedAmount.discount
                }
                setErrors(validation(thirdStepRequiredFields));
                let result = validation(thirdStepRequiredFields);
                if (objectKeyConvertToArray(result)?.length === 0) {
                    // Specify the keys you want to exclude
                    const keysToDelete = ['specificServiceGroupBrandDiscount', 'serviceRestrictions', 'brandRestrictions', 'buyOneGetOne'];

                    // Use destructuring and the rest operator to create a new object without the specified keys
                    const filteredPayload = Object.fromEntries(
                        Object.entries(payload).filter(([key]) => !keysToDelete.includes(key))
                    );
                    payload = { ...filteredPayload, amount_spent: fixedAmount.spendAmount, discounted_percentage: fixedAmount.discount }
                    submit = true
                } else {
                    submit = false
                }
            }

            if (submit) {
                setLoading(true);
                if (isEdit) {
                    if (payload) {
                        payload.id = discount.id;
                        payload.dayRestrictions = discount?.dayRestrictions?.filter(obj => obj.value || obj.is_deleted === "True");
                        dispatch(update_coupons_discount(payload, access_token, success, fail))
                    }
                } else {
                    if (payload) {
                        payload.dayRestrictions = discount?.dayRestrictions?.filter(obj => obj.value)?.map(obj => { return { day: obj.value } });
                        dispatch(add_coupons_discount(payload, access_token, success, fail))
                    }
                }
            }
        } else {
            formRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }

    return {
        navigate, setErrors, validation, onBlurHandler, getClientName, handleBuyOneGetOne, productDropdown, serviceDropdown,
        handleChange, setDiscount, filterServiceGroupDiscountField, addServiceGroupDiscount, handleDayChecklist,
        addStoreRestriction, filterStoreRestriction, setToggleDay, setToggleStore, onSubmit, setLoading,
        errors, discount, categoryDiscountDropdown, toggleDay, toggleStore, loading, locationDropdown,
        isEdit, serviceGroupDropdown, brandDropdown, translation, selectedLanguage, productExcludedDropdown,
        formErrors, setFormErrors, formRef, showAddClientPopup, setShowAddClientPopup, serviceExcludedDropdown,
        addClients, clientDropdown, addBrandRestriction, addServiceRestriction, serviceToggle, setServiceToggle,
        brandToggle, setBrandToggle, filterClient, choices, handleFixedAmountDiscount, generateCouponCode,
        handleChangeCouponType, clientTypeOptions, getClients
    };
};