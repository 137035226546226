import React, { useEffect } from "react";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Svgs from "Assets/svgs";
import FloatInput from "Components/TenantApp/Elements/FloatInput";

function AddProductComission({
  currencyOnSelectedLocation,
  product_comission,
  submitButtonClick,
  onBlurHandler,
  errors,
  translation,
  selectedLanguage,
  setCommissions,
  commissions,
  setErrors,
  onUpdate,
  i
}) {
  let productCommissionLength = commissions?.product_comission.length;
  const handleChange = (e) => {
    const { name, value } = e.target;
    onUpdate({ name: name, value: value })
    setErrors((prev) => ({
      ...prev,
      name: "",
    }));
  };
  const removeSelectedField = () => {
    if (i == 0) { // when length is zero
      setCommissions((prev) => ({
        ...prev,
        product_comission: []
      }))
    }
    if (product_comission?.id) {  // edit 
      onUpdate({ name: 'isDeleted', value: 'True' }) //for handling isDelete
    }
    else { // create no need of isDelete here 
      let filter = commissions.product_comission.filter((obj) => obj !== commissions.product_comission[i])
      setCommissions((prev) => ({
        ...prev,
        product_comission: filter
      }))
    }
  }
  useEffect(() => {
    if (i == 0) {
      onUpdate({ name: 'from_value', value: '0' })
    }
    if (i == productCommissionLength - 1) {
      onUpdate({ name: 'to_value', value: '∞' })
    }
  }, [i, productCommissionLength])

  return (
    <div className="grid md:grid-cols-4 md:gap-4 gap-6 relative border p-3 py-6 whitespace-nowrap rounded-md">
      <div className="absolute top-0 right-0 ... bg-primary px-[3px] py-[3px]  rounded cursor-pointer -translate-y-1/2 translate-x-1/2" onClick={removeSelectedField}><Svgs.Close /></div>
      <FloatInput
        title={"From"}
        rightIcon={currencyOnSelectedLocation?.currency?.code}
        placeholder="Enter from"
        name="from_value"
        value={product_comission?.from_value}
        onChange={handleChange}
        // onBlur={() =>
        //   onBlurHandler({ from_value: product_comission?.from_value })
        // }
        // error={errors?.from_value}
        disabled={i == 0 ? true : false}
        error={(submitButtonClick && !product_comission?.from_value) ? true : false}
      />
      <LoginInput
        type={i === productCommissionLength - 1 ? "text" : "number"}
        title={"To"}
        rightIcon={currencyOnSelectedLocation?.currency?.code}
        placeholder="Enter To"
        name="to_value"
        value={product_comission?.to_value}
        onChange={handleChange}
        disabled={i === productCommissionLength - 1 ? true : false}
        error={(submitButtonClick && !product_comission?.to_value) ? true
          : product_comission?.to_value && (product_comission?.to_value <= product_comission?.from_value)
            ? selectedLanguage?.name == "English" ? "Value should be greater than from value." : translation[1331]?.value
              ? translation[1331]?.value : "Value should be greater than from value." : false}
      // onBlur={() =>
      //   onBlurHandler({ to_value: product_comission?.to_value })
      // }
      // error={errors?.to_value}
      />
      <FloatInput
        title={"Commission"}
        error={(submitButtonClick && !product_comission?.commission) && true}
        rightIcon={commissions.product_comission_symbol}
        placeholder="Enter"
        name="commission"
        value={product_comission?.commission}
        onChange={handleChange}
      // onBlur={() =>
      //   onBlurHandler({
      //     service_commission_percentage:
      //       product_comission?.commission_percentage,
      //   })
      // }
      // error={errors?.commission_percentage}
      />
      <div className="grid grid-cols-2 gap-1 self-end">
        <LoginBtn
          className="!w-full justify-center"
          borderBtn={commissions.product_comission_symbol === "%" && true}
          onClick={(e) => {
            setCommissions((prev) => ({
              ...prev,
              product_comission_symbol: currencyOnSelectedLocation?.currency?.code,
            }));
          }}
        >
          {currencyOnSelectedLocation?.currency?.code}
        </LoginBtn>

        <LoginBtn
          className="!w-full justify-center"
          borderBtn={commissions.product_comission_symbol !== "%" && true}
          onClick={(e) => {
            setCommissions((prev) => ({
              ...prev,
              product_comission_symbol: '%',
            }));
          }}
        >
          %
        </LoginBtn>
      </div>
    </div>
  );
}

export default AddProductComission;