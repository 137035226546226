import { useState } from 'react'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { addPaymentMethods, deletePaymentMethod, updatePaymentMethods } from '../../../../../../Adapters/Api/financialSettings';
import useSetDataInLocalStorage from '../../../../../../Hooks/useSetDataInLocalStorage';
import useValidations from './../../../../../../Hooks/useValidations';
import { addPaymentMethod, removePaymentMethod, updatePaymentMethod } from '../../../../../../Redux/TenantRedux/Actions/financialSettingsActions';
import { apiErrorMessage } from 'Constants/Data/Errors';
import useObjectKeys from 'Hooks/useObjectKeys';

function useFinancialSettings() {
  const dispatch = useDispatch();
  const FinancialSettingsRedux = useSelector((state) => state?.financialSettings?.paymentMethod);
  const [Payment, setPayment] = useState(false)
  const [isSelected, setIsSelected] = useState(true)
  // const [method_type, setMethodType] = useState();
  const [method_type, setMethodType] = useState({ name: "", status: true });
  const { errors, setErrors, validation } = useValidations()
  const { objectKeyConvertToArray } = useObjectKeys()
  const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  const access_token = getDataFromLocalStorage("access_token");
  const [isEdit, setIsEdit] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState({ cash: "", mastercard: "", visa: "", paypal: "", google_pay: "", apple_pay: "" });

  //toggle payment modal
  const paymentModalToggle = (reset = true) => {
    setMethodType({ name: "", status: true });
    setPayment(!Payment);
  }

  // payment method on change handler 
  const paymentMethodHandler = (e) => {
    if (e?.target) {
      const { name, value } = e?.target;
      setMethodType((prev) => ({ ...prev, [name]: value }))
      setErrors((prev) => ({ ...prev, [name]: "" }))
    } else {
      setMethodType((prev) => ({ ...prev, location: e.id }));
      setErrors((prev) => ({ ...prev, location: "" }))
    }

    // const result = FinancialSettingsRedux.find((item) => item.method_type === paymentMethod);
    // if (result) {
    //   toast.error("Payment Method Already Selected!");
    // } else {
    //   if (paymentMethod) {
    //     if (!method_type.includes(paymentMethod)) {
    //       setIsSelected(false);
    //       setMethodType([...method_type, paymentMethod]);
    //       setErrors((prev) => ({ ...prev, payment_method: "" }))
    //     } else {
    //       setMethodType(method_type.filter((item) => item != paymentMethod));
    //     }

    //   }
    // }

  }


  // on add new payment methods
  const onAddPaymentMethod = async () => {
    const { name } = method_type
    setErrors(validation({ name }));
    let result = validation({ name });
    if (objectKeyConvertToArray(result)?.length === 0) {
      if (method_type.length === 0) {
        setErrors((prev) => ({ ...prev, payment_method: "Please select a payment methods" }))
      } else {
        if (isEdit) {
          const payment_id = getDataFromLocalStorage("payment_id");
          const response = await updatePaymentMethods(method_type, businessData.id, payment_id, access_token);
          if (response.status === 200) {
            let updatePaymentMethodResponse = response?.data?.response?.payment_method;
            dispatch(updatePaymentMethod(updatePaymentMethodResponse));
            paymentModalToggle();
            let successMessage = response?.data?.response?.message;
            toast.success(successMessage, { toastId: "toast" });
          }
        } else {
          addPaymentMethods(method_type.name, businessData.id, access_token, method_type.status).
            then((response) => {
              if (response.status === 201) {
                let addedPaymentMethod = response?.data?.response?.payment_method
                // console.log("addedPaymentMethod", addedPaymentMethod);
                dispatch(addPaymentMethod(addedPaymentMethod));
              }
            }).catch((error) => {
              toast.error(apiErrorMessage, { toastId: "toast" })
            })
          paymentModalToggle();
          toast.success("Payment method added!", { toastId: "toast" });
        }
      }
    }
  }

  // delete payment method 
  const paymentMethodDeleteHandler = async (id) => {
    let response = await deletePaymentMethod(id, access_token);
    if (response.status_code === 200) {
      let successMessage = response?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      dispatch(removePaymentMethod(id));
    }
  }

  // edit payment methods 
  const paymentMethodEditHandler = (id) => {
    storeDataInLocalStorage("payment_id", id)
    paymentModalToggle(false);
    setIsEdit(true);
    const selectedMethod = FinancialSettingsRedux.find((item) => item.id === id);
    if (selectedMethod) {
      // setMethodType(selectedMethod.method_type);
      setMethodType({ name: selectedMethod?.method_type, status: selectedMethod?.is_active });
    }

  }





  return {
    setIsEdit,
    errors,
    method_type,
    paymentMethod,
    setPaymentMethod,
    paymentMethodHandler,
    onAddPaymentMethod,
    paymentModalToggle,
    Payment,
    paymentMethodDeleteHandler,
    paymentMethodEditHandler,
    isSelected,
    isEdit
  }
}

export default useFinancialSettings
