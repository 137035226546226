import React from 'react'

const ProgressBar = ({progressWidth,heading,value, xl, sm, animate}) => {
  return (
    animate ?
    <div className="bg-white animate-pulse transition-all">
        <div className="flex justify-between mb-1">
            <span className={`${xl?"text-base":"text-sm"} text-base font-medium text-[#949494] rounded-full bg-gray-200 h-5 w-full`}>{heading}</span>
            <span className={`${xl?"text-sm xl:text-base":"text-sm"} font-normal text-[#353535] rounded-full bg-gray-200 h-4 w-0`}>{value}</span>
        </div>
        <div className={`${xl?"h-2 xl:h-2.5":"h-2"} w-full bg-gray-200 rounded-full`}>
        </div>
    </div>
    
    :
    <div className="progress_bar">
        <div className="flex justify-between mb-1">
            <span className={`${xl?"text-base":"text-sm"} text-base font-medium text-[#949494]`}>{heading}</span>
            <span className={`${xl?"text-sm xl:text-base":"text-sm"} font-normal text-[#353535]`}>{value}</span>
        </div>
        <div className={`${xl?"h-2 xl:h-2.5":"h-2"} w-full bg-[#D9D9D9] rounded-full`}>
          <div className="bg-[#5272EF] h-2 xl:h-2.5 rounded-full" style={{ width: `${progressWidth}%` }}></div>
        </div>
    </div>
  )
}

export default ProgressBar