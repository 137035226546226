import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeDropDownListPagination } from "Adapters/Api/employee";
import useValidations from "Hooks/useValidations";
import { handleSubmitEmployeeSlot } from "Adapters/Api/employee/working-schedule";
import useObjectKeys from "Hooks/useObjectKeys";
import { toast } from "react-toastify";
import { useLocation } from "new-components/location/helper";
import { apiErrorMessage } from "Constants/Data/Errors";

let employeeTimeOutId = undefined

let initialState = {
    employee: "",
    date: "",
    day: "",
    start_time: "",
    end_time: "",
}
const useAddLieuDay = ({ close, successCallback }) => {
    const state = useSelector((state) => state);
    const { locationDetail } = useLocation();
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const employeeRedux = state?.employee?.employeesDropdown;
    const employeePaginationData = state?.employee?.employeeDropdownPaginationData

    const business_id = state?.business?.business?.id
    const { objectKeyConvertToArray } = useObjectKeys()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");
    const businessData = getDataFromLocalStorage("business_data");

    const dispatch = useDispatch();
    const { access_token } = state?.user.user;

    const [lieuDayData, setLieuDayData] = useState(initialState)
    const { errors, setErrors, validation } = useValidations()
    const [isLoading, setIsLoading] = useState(false);

    const [employeeSearch, setEmployeeSearch] = useState("");
    const [employeePage, setEmployeePage] = useState(1)
    const [employeeLoader, setEmployeeLoader] = useState(false)
    const [limit_start_time, setLimit_start_time] = useState([])

    let minStartDateLimit = useMemo(() => {
        return moment().format('YYYY-MM-DD')
    }, []);

    const onChangeHandler = (e) => {
        const { name, value } = e?.target
        if (name == "date") {
            let date = moment(value).format('YYYY-MM-DD')
            let day = moment(value).format('dddd')
            setLieuDayData((prev) => ({ ...prev, 'date': date }))
            setLieuDayData((prev) => ({ ...prev, 'day': day }))
            setErrors((prev) => ({ ...prev, [name]: "" }))
        }
        setLieuDayData((prev) => ({ ...prev, [name]: value }))
        setErrors((prev) => ({ ...prev, [name]: "" }))
    }
    const handleClose = () => {
        close(false);
    };

    const handleSubmit = async (data) => {
        const requiredFields = { ...lieuDayData }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setIsLoading(true)
            let payload = {
                ...lieuDayData,
                business: businessData?.id ? businessData?.id : business_id,
                location: selected_location,
                is_leo_day: true,
            };
            const res = await handleSubmitEmployeeSlot(payload, access_token, handleClose, setIsLoading);

            if (res.status === 201) {
                toast.success(res?.data?.response?.message, { toastId: "lieu day" })
                successCallback && successCallback()
            }else{
                toast.error(apiErrorMessage, { toastId: "lieu day" })
            }

        }

    };

    useEffect(() => {
        if (selected_location) {
            if (employeeSearch === null) {
                dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
            } else {
                if (employeeTimeOutId) {
                    clearTimeout(employeeTimeOutId)
                }
                employeeTimeOutId = setTimeout(() => {
                    dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
                }, 500);
            }
        }
    }, [employeeSearch, selected_location, employeePage])


    const timeSlot = (strt, clse) => {
        if (strt != null && clse != null) {
            var startTime = moment(strt, "HH:mm");
            var endTime = moment(clse, "HH:mm");
            var timeStops = [];
            while (startTime <= endTime) {
                timeStops.push(new moment(startTime).format("HH:mm:ss"));
                startTime.add(30, "minutes");
            }
            if (timeStops?.length > 0) {
                setLimit_start_time(
                    timeStops.map((ele) => ({
                        value: ele,
                        label: moment(ele, ["HH:mm"]).format("h:mm A"),
                    }))
                );
            } else {
                setLimit_start_time([])
            }
        }
    };

    let limit_end_time = useMemo(() => {
        let endTime = limit_start_time?.map((obj) => {
            if (moment(obj.value, "hh:mm").valueOf() <= moment(lieuDayData?.start_time, "hh:mm").valueOf()) {
            } else {
                return obj;
            }
        }).filter((obj) => obj !== undefined);
        return endTime;
    }, [limit_start_time]);

    useEffect(() => {
        if (lieuDayData?.day) {
            const res = locationDetail?.opening_hours?.find((dy) => dy?.day === lieuDayData?.day.split(",")[0].toLowerCase());
            timeSlot(res?.start_time, res?.close_time);
        }
    }, [locationDetail, lieuDayData]);

    return {
        translation, selectedLanguage,
        isLoading,
        employeeRedux,
        employeePaginationData,
        employeeSearch, setEmployeeSearch,
        employeePage, setEmployeePage,
        employeeLoader,
        minStartDateLimit,
        handleClose,
        handleSubmit,
        limit_start_time,
        onChangeHandler, limit_end_time,
        lieuDayData, errors, setErrors,
    };
}
export default useAddLieuDay

