import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { addLanguage, get_language_translation } from "../../../../../../Adapters/Api";
import useObjectKeys from "../../../../../../Hooks/useObjectKeys";
import useSetDataInLocalStorage from "../../../../../../Hooks/useSetDataInLocalStorage";
import useValidations from "../../../../../../Hooks/useValidations";
import { getLanguagesTranslation, select_language, selected_language, updateLanguagesArray } from "../../../../../../Redux/TenantRedux/Actions/languageActions";
import { useNavigate } from "react-router-dom";

function useMulitLanguage() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const { objectKeyConvertToArray } = useObjectKeys();
  const businessData = getDataFromLocalStorage("business_data");
  const access_token = getDataFromLocalStorage("access_token");

  const { errors, setErrors, validation, onBlurHandler } = useValidations();
  const [languageList, setLanguageList] = useState();
  const [multiLanguages, setMultiLanguages] = useState({
    language: "",
    is_default: false,
    // is_default: {
    //     id:"",
    //     is_default:false
    // },
  });

  const [languages, setlanguages] = useState({
    select_language: "",
  });

  const onChangeHandler = (e, fieldName = "") => {
    setMultiLanguages((prev) => ({ ...prev, language: e.target.value }));
    setlanguages((prev) => ({ ...prev, select_language: e.target.value }));
    setErrors((prev) => ({ ...prev, language: "" }));
  };

  // Fetch Translations
  const getTranslation = async (name) => {
    const response = await get_language_translation(name)
    if (response.status === 200) {
      const data = response?.data?.response?.data;
      dispatch(getLanguagesTranslation(data))
    }
  }

  // added a business language when user hit save changes
  const onSubmitHandler = async () => {
    setErrors(validation(multiLanguages.language));
    const result = validation(multiLanguages.language);

    if (objectKeyConvertToArray(result)?.length === 0) {
      const response = await addLanguage(
        { ...multiLanguages, business: businessData.id },
        access_token
      );

      if (response.status === 200) {
        let addedLanguage = response?.data?.response?.language;
        let successMessage = response?.data?.response?.message;
        localStorage.setItem("selected_language", JSON.stringify(addedLanguage?.language))

        getTranslation(addedLanguage?.language?.name)
        dispatch(updateLanguagesArray(addedLanguage));
        dispatch(selected_language(addedLanguage?.language))
        setMultiLanguages({
          language: "",
          is_default: false,
        });
        toast.success(successMessage, { toastId: "toast" });
        navigate("/dashboard/account/setup");
      }
    }
  };

  return {
    errors,
    onBlurHandler,
    multiLanguages,
    setLanguageList,
    languages,
    onChangeHandler,
    onSubmitHandler,
    languageList,
    setlanguages,
    getTranslation,
  };
}

export default useMulitLanguage;
