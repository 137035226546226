import { useSelector } from "react-redux"
import Svgs from "../../Assets/svgs"

const NoClientFound = ({ className, onClick, showAddText }) => {
    const state = useSelector((state) => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>
            <div className={`flex items-center justify-center my-5 ${className}`}>
                <div className="flex flex-col gap-1 justify-center text-center">
                    <div className="w-fit mx-auto">
                        <Svgs.NoClientFound />
                    </div>
                    <h1 className="text-primary font-semibold text-lg normal-case">
                        {selectedLanguage?.name == "English" ? "No client found" : translation[1279]?.value ? translation[1279]?.value : "No client found"}
                    </h1>
                    {showAddText &&
                        <p className="text-sm text-[#555555]">
                            {selectedLanguage?.name == "English" ? "Please click the" : translation[1270]?.value ? translation[1270]?.value : "Please click the"}
                            {" "}
                            <span
                                onClick={() => {
                                    onClick && onClick()
                                }}
                                className="underline text-[#5679FF] cursor-pointer"
                            >
                                {selectedLanguage?.name == "English" ? "New Client" : translation[462]?.value ? translation[462]?.value : "New Client"}
                            </span>{" "}
                            {selectedLanguage?.name == "English" ? "button to add one." : translation[1271]?.value ? translation[1271]?.value : "button to add one."}
                        </p>
                    }
                </div>
            </div>
        </>
    )
}

NoClientFound.defaultProps = {
    className: '',
    showAddText: true
}

export default NoClientFound