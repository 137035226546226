
import VerificationMethodCard from '../../TenantApp/Elements/VerificationMethodCard';



const  VerificationMethod = (props) => {
 

  

    return (
        <>
            <h1 className="text-3xl font-light text-[#7B7B7B] lg:text-left text-center">
                Verification <span className="font-semibold text-black">Method</span>
            </h1>
            <p className="text-[#7B7B7B] lg:text-left text-center text-sm">
                Select Verification Method.
            </p>
            <VerificationMethodCard
                onClick={() => { props.onChange({type : 'Email'}) }}
                icon={<svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.33333 0C3.91885 0 2.56229 0.568927 1.5621 1.58162C0.561903 2.59432 0 3.96783 0 5.4V5.9427L16 14.6664L32 5.9454V5.4C32 3.96783 31.4381 2.59432 30.4379 1.58162C29.4377 0.568927 28.0812 0 26.6667 0H5.33333ZM32 9.0099L16.632 17.388C16.4378 17.4939 16.2206 17.5493 16 17.5493C15.7794 17.5493 15.5622 17.4939 15.368 17.388L0 9.0099V21.6C0 23.0322 0.561903 24.4057 1.5621 25.4184C2.56229 26.4311 3.91885 27 5.33333 27H26.6667C28.0812 27 29.4377 26.4311 30.4379 25.4184C31.4381 24.4057 32 23.0322 32 21.6V9.0099Z" fill="white" />
                </svg>}
                title='Email Verification'
                desc={<>Get your verification code on <span className='text-black font-semibold'>an*******23@gmail.com</span></>}
            />
            <VerificationMethodCard
                onClick={() => { props.onChange({type : 'Mobile'}) }}
                icon={<svg width="25" height="39" viewBox="0 0 25 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.2222 0H2.77778C2.04107 0 1.33453 0.293494 0.813593 0.815917C0.292658 1.33834 0 2.0469 0 2.78571V36.2143C0 36.9531 0.292658 37.6617 0.813593 38.1841C1.33453 38.7065 2.04107 39 2.77778 39H22.2222C22.9589 39 23.6655 38.7065 24.1864 38.1841C24.7073 37.6617 25 36.9531 25 36.2143V2.78571C25 2.0469 24.7073 1.33834 24.1864 0.815917C23.6655 0.293494 22.9589 0 22.2222 0V0ZM13.8889 36.2143H11.1111V33.4286H13.8889V36.2143ZM2.77778 30.6429V2.78571H22.2222V30.6429H2.77778Z" fill="white" />
                </svg>}
                title='Phone Verification'
                desc={<>Get your verification code on <span className='text-black font-semibold'>0316*******57</span></>}
            />
{/* 
            <div className="flex lg:justify-start justify-center">
                <LoginBtn
                    disabled={active != false ? false : true}
                    className={active != false ? "shake-bottom" : "opacity-40"}
                    text="Next"
                    // onClick={() => {
                    //     props.setSection(1)
                    //     props.verification(selected);
                        // navigate("/auth/login/forgot");
                    // }}
                >
                    <svg
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 8H17M10 1L17 8L10 15"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </LoginBtn>
            </div> */}
</>






  
    )
}

export default VerificationMethod