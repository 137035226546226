import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Svgs from "Assets/svgs";
import { discountTypes } from "Redux/TenantRedux/Reducers/clientDiscountPromotionReducer";
import moment from "moment";

const DiscountCard = ({
  data,
  deleteDiscountPromotion,
  deleteModal,
  setDeleteModal,
  i,
  editDiscount,
  translation, selectedLanguage
}) => {
  let TYPE_COLOR = {
    [discountTypes.directOrFlat]: 1,
    [discountTypes.specificGroup]: 2,
    [discountTypes.purchase]: 3,
    [discountTypes.retailPromotion]: 4,
    [discountTypes.bundleDiscount]: 4,
    [discountTypes.freeService]: 5,
    [discountTypes.fixedPrice]: 6,
    [discountTypes.specificBrandServiceGroup]: 7,
    [discountTypes.spendSomeAmount]: 8,
    [discountTypes.userRestricted]: 9,
    [discountTypes.complimentaryVoucher]: 10,
    [discountTypes.packages]: 7,
    [discountTypes.coupons]: 8,
  };
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user, employee, employeePermissions]);

  return (
    <div
      key={i}
      className={`rounded-lg px-4 py-6 discount-gradient-${TYPE_COLOR[data?.type]
        } flex flex-col gap-4`}
    >
      <div className="flex items-center justify-between gap-3 flex-wrap">
        <div className="bg-white rounded-full px-4 py-2 text-center text-sm font-semibold">
          {data?.heading}
        </div>
        <div className="flex items-center gap-4 text-white text-sm">
          <div className="flex items-center gap-2">
            <p>{selectedLanguage?.name == "English" ? "Start:" : translation[640]?.value ? translation[640]?.value : "Start:"}</p>
            <p className="font-semibold text-base">{moment(data?.start_date).format('DD-MM-YYYY')}</p>
          </div>
          <div className="flex items-center gap-2">
            <p>{selectedLanguage?.name == "English" ? "End:" : translation[642]?.value ? translation[642]?.value : "End:"}</p>
            <p className="font-semibold text-base">{moment(data?.end_date).format('DD-MM-YYYY')}</p>
          </div>
        </div>
        <h2 className="text-white text-sm font-medium w-full ">{data?.promotion_name}</h2>
      </div>
      <div className="flex items-center gap-4 justify-between flex-wrap">
        {TYPE_COLOR[data?.type] && (
          <div className="flex items-center gap-5 text-white flex-wrap">
            {data?.multiple_discounts?.length > 0 ? (
              data?.multiple_discounts?.map((ele, index) => {
                return (
                  data.type == "Spend_Some_Amount" ?
                    <div key={index} className="flex items-center gap-2">
                      <p>{selectedLanguage?.name == "English" ? "Spend" : translation[644]?.value ? translation[644]?.value : "Spend"}
                        <span className="font-semibold text-xl">{ele?.amount}</span> &
                        {selectedLanguage?.name == "English" ? "Get" : translation[299]?.value ? translation[299]?.value : "Get"} <span className="font-semibold text-xl">{ele?.type}</span>
                        {selectedLanguage?.name == "English" ? "Free" : translation[649]?.value ? translation[649]?.value : "Free"}</p>
                    </div>
                    :
                    <div key={index} className="flex items-center gap-2">
                      <p className="font-semibold text-2xl whitespace-nowrap">
                        {ele?.percentage && `${ele.percentage} %`} {ele?.amount}
                      </p>
                      <p className="text-sm">{selectedLanguage?.name == "English" ? "off on" : translation[312]?.value ? translation[312]?.value : "off on"} {ele.type}</p>
                    </div>
                );
              })
            ) : (
              <div className="text-white">
                {data?.title && (
                  <h1 className="font-bold text-3xl">{data?.title}</h1>
                )}
                {data?.sub_title && (
                  <h1 className="font-semibold text-sm">{data?.sub_title}</h1>
                )}
              </div>
            )}
          </div>
        )}
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-2">
            {!employee ||
              (employee &&
                employeePermissions?.client_discount?.includes("edit")) ? (
              <div
                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                onClick={() => {
                  editDiscount(data);
                }}
              >
                <Svgs.Edit fill="#FFFFFF" />
              </div>
            ) : (
              ""
            )}
            {!employee ? (
              <div
                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center cursor-pointer"
                onClick={() => {
                  setDeleteModal(!deleteModal);
                  deleteDiscountPromotion(data?.id, data?.type);
                }}
              >
                <Svgs.Delete fill="#FFFFFF" />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountCard;
