
import axios from "axios";
import { BASE_URL, get_commission_reports_by_staff, get_commission_reports_by_commission_details, get_employee_commission } from "../api_variables";
import { EMPLOYEES } from "Redux/TenantRedux/ActionTypes/employeeTypes";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";



export const getSaleEmployeeCommission = (id, fail) => async dispatch => {
    try {
        const result = await axios.get(`${BASE_URL}${get_employee_commission}?id=${id}`);
        if (result?.data?.status === 200) {
            dispatch({
                type: EMPLOYEES.EMPLOYEE_COMMISSIONS,
                payload: result?.data?.response?.commission
            })
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
};



export const getCommissionReportsByStaff = (start, end, selected_location, setLoading) => async dispatch => {
    const location = selected_location ? `?location_id=${selected_location}` : ""
    setLoading && setLoading(true)
    const apiUrl = (start && end) ? `${get_commission_reports_by_staff}${location}&range_start=${start}&range_end=${end}`
        : `${get_commission_reports_by_staff}${location}`
    try {
        const result = await axios.get(`${BASE_URL}${apiUrl}`);
        if (result?.data?.status === 200) {
            dispatch({
                type: EMPLOYEES.GET_COMMISSION_BY_STAFF,
                payload: result?.data?.response?.staff_report
            })
            setTimeout(() => {
                setLoading && setLoading(false)
            }, 1000);
        } else {
            setLoading && setLoading(false)
        }
    } catch (error) {
        setLoading && setLoading(false)
        toast.error(apiErrorMessage, { toastId: "toast" })
    }
};




export const getCommissionReportsByCommissionDetails = (selected_location, page, type, selectEmployee, start, end, setLoading, success, fail) => async dispatch => {
    setLoading && setLoading(true)
    const location = selected_location ? `?location_id=${selected_location}` : ''
    const currentPage = page ? `&page=${page}` : ''
    const startDate = start ? `&range_start=${start}` : ''
    const endDate = end ? `&range_end=${end}` : ''
    const item_type = type ? `&order_type=${type}` : ''
    const employee = selectEmployee ? `&employee_id=${selectEmployee}` : ''

    const apiUrl = `${get_commission_reports_by_commission_details}${location}${currentPage}${item_type}${employee}${startDate}${endDate}`

    try {
        const result = await axios.get(`${BASE_URL}${apiUrl}`);
        if (result?.status == 200) {
            dispatch({
                type: EMPLOYEES.GET_COMMISSION_BY_DETAIL,
                payload: result?.data
            })
            setLoading && setLoading(false)
            success && success()
        } else {
            setLoading && setLoading(false)
            fail && fail()
        }
    } catch (error) {
        setLoading && setLoading(false)
        fail && fail()
    }
};

export const getCommissionReportsByCommissionDetailsWithoutPagination = (selected_location, type, selectEmployee, start, end, setLoading, success, fail) => async dispatch => {
    setLoading && setLoading(true)
    const location = selected_location ? `?location_id=${selected_location}` : ''
    const startDate = start ? `&range_start=${start}` : ''
    const endDate = end ? `&range_end=${end}` : ''
    const item_type = type ? `&order_type=${type}` : ''
    const employee = selectEmployee ? `&employee_id=${selectEmployee}` : ''
    const apiUrl = `${get_commission_reports_by_commission_details}${location}&no_pagination=true${item_type}${employee}${startDate}${endDate}`

    try {
        const result = await axios.get(`${BASE_URL}${apiUrl}`);
        if (result?.status == 200) {
            dispatch({
                type: EMPLOYEES.GET_COMMISSION_BY_DETAIL_WITHOUT_PAGINATION,
                payload: result?.data
            })
            setLoading && setLoading(false)
            success && success()
        } else {
            setLoading && setLoading(false)
            fail && fail()
        }
    } catch (error) {
        setLoading && setLoading(false)
        fail && fail()
    }
};


