import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { deleteMissedOpportunity, getMissedOpportunity } from "Adapters/Api/apointments";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";

let timeOutId = undefined;
function useMissedOpportunity() {
    const dispatch = useDispatch()

    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const isPermissionUpdated = state?.user?.isPermissionUpdated;

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");
    const selectedLocationCurrency = getDataFromLocalStorage("selectedLocationCurrency");

    const [searchText, setSearchText] = useState("")
    const [data, setData] = useState([])
    const [toggleDateRange, setToggleDateRange] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [loader, setLoader] = useState(true)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [pages, setPages] = useState(0)
    const [count, setCount] = useState(0)
    const [dropdownStatus, setDropdownStatus] = useState("")
    const [addPopup, setAddPopup] = useState(false)
    const [ShowDetails, setShowDetails] = useState(-1);
    const [delPopup, setDelPopup] = useState(false)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [loadingPermission, setLoadingPermission] = useState(true)
    const [showNotes, setShowNotes] = useState(false)
    const [getNote, setGetNote] = useState('')

    const PageSize = 10

    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])

    useEffect(() => {
        let data = {}
        if (startDate && endDate) {
            data = { page: currentPage, location_id: selected_location, start_date: startDate, end_date: endDate }
        } else {
            data = { page: currentPage, location_id: selected_location }
        }
        let success = (data) => {
            setLoader(false)
            setData(data?.response?.missed_opportunities?.results)
            setCount(data?.response?.missed_opportunities?.count)
            setPages(data?.response?.missed_opportunities?.pages)
        }
        let fail = () => {
            setLoader(false)
        }
        if (selected_location) {
            if (searchText === null) {
                setLoader(true)
                // Initial load with empty search_text
                getMissedOpportunity(data, success, fail)
            } else {
                data = { ...data, search_text: searchText }
                if (timeOutId) {
                    clearTimeout(timeOutId)
                }
                timeOutId = setTimeout(() => {
                    setLoader(true)
                    getMissedOpportunity(data, success, fail)
                }, 500);
            }
        }
    }, [searchText, selected_location, currentPage, startDate, endDate, dropdownStatus])

    useEffect(() => {
        setLoader(true)
    }, [selected_location, currentPage])

    const deleteOpportunity = () => {
        setDeleteLoader(true)
        dispatch(
            deleteMissedOpportunity(
                deleteId,
                (result) => {
                    setDeleteLoader(false);
                    setDeleteId('')
                    setDelPopup(!delPopup)
                    toast.success(result?.response?.message, { toastId: "toast" })
                    getAPi()
                },

                () => {
                    setDeleteLoader(false);
                    // toast.error(apiErrorMessage, { toastId: "toast" });
                }
            )
        );
    }

    const getAPi = () => {
        setLoader(true)
        let success = (data) => {
            setLoader(false)
            setData(data?.response?.missed_opportunities?.results)
            setCount(data?.response?.missed_opportunities?.count)
            setPages(data?.response?.missed_opportunities?.pages)
        }
        let fail = () => {
            setLoader(false)
        }
        setCurrentPage(1)
        setStartDate()
        setDropdownStatus("")
        setEndDate()
        setSearchText("")
        dispatch(getMissedOpportunity({ page: 1, location_id: selected_location }, success, fail))
    }

    return {
        searchText, setSearchText, toggleDateRange, setToggleDateRange, currentPage, setCurrentPage, loader, PageSize, data, setData,
        translation, selectedLanguage, startDate, setStartDate, endDate, setEndDate, pages, setDropdownStatus, dropdownStatus,
        selectedLocationCurrency, count, addPopup, setAddPopup, ShowDetails, setShowDetails, delPopup, setDelPopup, deleteLoader,
        setDeleteLoader, deleteOpportunity, deleteId, setDeleteId, getAPi, loadingPermission,showNotes ,setShowNotes,getNote, setGetNote
    };
}

export default useMissedOpportunity;
