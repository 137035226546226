import React from 'react'
import useTrainerComments from './helper'
import Popup from 'Components/TenantApp/Elements/Popup'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import Textarea from 'new-components/text-area'

const CommnetPopup = ({ open, close, data, onSuccessCall }) => {
    const {
        translation, selectedLanguage,
        trainingData, Data, errors, loading, onChangeHandler, onSubmitHandler
    } = useTrainerComments(data, onSuccessCall, close)
    return (
        <>
            <Popup
                open={open}
                onclose={close}
                heading={"Add Comments & Recomendations"}
            >
                <div className='flex flex-col gap-5'>
                    <div className='flex flex-col gap-3'>
                        <div>
                            <Textarea
                                title={selectedLanguage?.name == "English" ? "Add Recomendation" : translation[1570]?.value ? translation[1570]?.value : "Add Recomendation"}
                                rows={5}
                                limit={150}
                                placeholder={selectedLanguage?.name == "English" ? "Add text ..." : translation[1571]?.value ? translation[1571]?.value : "Add text ..."}
                                required={true}
                                name={'teacher_recommendation'}
                                value={trainingData?.teacher_recommendation}
                                onChange={onChangeHandler}
                                onEnterSubmit={onChangeHandler}
                                errorMessage={errors?.teacher_recommendation}
                            />
                        </div>
                        <div>
                            <Textarea
                                title={selectedLanguage?.name == "English" ? "Add Comment" : translation[1570]?.value ? translation[1570]?.value : "Add Comment"}
                                rows={5}
                                limit={150}
                                placeholder={selectedLanguage?.name == "English" ? "Add text ..." : translation[1571]?.value ? translation[1571]?.value : "Add text ..."}
                                required={true}
                                name={'teacher_comment'}
                                value={trainingData?.teacher_comment}
                                onChange={onChangeHandler}
                                onEnterSubmit={onChangeHandler}
                                errorMessage={errors?.teacher_comment}
                            />
                        </div>
                    </div>
                    <div className='flex justify-end items-center'>
                        <LoginBtn
                            className={"!px-6"}
                            onClick={() => {
                                onSubmitHandler('complete')
                            }}
                            loading={loading}
                            disabled={loading}
                            text={selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"} />
                    </div>
                </div>

            </Popup>
        </>
    )
}

export default CommnetPopup