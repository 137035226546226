import React from "react";
import Svgs from "Assets/svgs";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import useCampaign from "./helper";
import AddCompaign from "./addCompaign";
import AddFilter from "./addFilter";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import DetailCampaign from "./DetailCampaign";
import DeleteConfirmation from "new-components/delete-popup";
import NotFound from "Components/Utility/NotFound";
import Pagination from "new-components/Pagination";


const Campaigns = () => {

  const {
    campaigns,
    loading,
    CreateCompanign, setCreateCompanign,
    ViewDetails, setViewDetails,
    FilterPopup, setFilterPopup, setApiCall,
    searchCampaign, setSearchCampaign,
    selectedLanguage, translation, deleteLoading, deleteCampaign,
    deletePopup, setDeletePopup, getCampaignDetail, currentPage, setCurrentPage,
    campaignDetails, isEdit, setIsEdit, campaignPagination
  } = useCampaign()

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <Breadcrumb first={selectedLanguage?.name == "English" ? "CRM" : translation[1354]?.value ? translation[1354]?.value : "CRM"} url={"/dashboard/crm/"} second={selectedLanguage?.name == "English" ? "Campaigns" : translation[1357]?.value ? translation[1357]?.value : "Campaigns"} />
        <div className="flex items-center gap-3 justify-between">
          <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Campaigns" : translation[1357]?.value ? translation[1357]?.value : "Campaigns"}
            </h2>
          </div>
          <LoginBtn
            onClick={() => {
              setCreateCompanign(!CreateCompanign);
            }}
            text={
              <div className="flex items-center gap-3">
                <div>
                  <Svgs.Plus />
                </div>
                <div>
                  <p>{selectedLanguage?.name == "English" ? "Create Campaigns" : translation[1363]?.value ? translation[1363]?.value : "Create Campaigns"}</p>
                </div>
              </div>
            }
          />
        </div>
        <div className="">
          <div className="border rounded-lg">
            <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
              <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                <div className="flex items-center lg:gap-4 gap-3">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold text-xl">{selectedLanguage?.name == "English" ? "Campaigns" : translation[1357]?.value ? translation[1357]?.value : "Campaigns"}</h2>
                    <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                      {loading
                        ? <div className="h-4 w-12" />
                        : <>
                          <p className="text-primary">
                            {campaignPagination?.allCount ? campaignPagination?.allCount : 0} {' '}
                            {selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"}
                          </p>
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div className="mb-0">
                  <LoginInput
                    title=""
                    name={"search_text"}
                    value={searchCampaign}
                    onChange={(e) => setSearchCampaign(e.target.value)}
                    placeholder={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                    padding="py-1"
                    leftIcon={<Svgs.Search />}
                  />
                </div>
              </div>
              <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
                {/* <LoginBtn
                  borderBtn
                  onClick={() => {
                    setFilterPopup(!FilterPopup);
                  }}
                >
                  <div className="flex items-center gap-2 text-primary">
                    <Svgs.Filters />
                    <p>{selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}</p>
                  </div>
                </LoginBtn> */}
                {/* <IconButton>
                  <Svgs.Import />
                </IconButton>
                <IconButton filled>
                  <Svgs.Export />
                </IconButton> */}
              </div>
            </div>

            {loading ?
              <TableShimer cols={5} />
              :
              <div className="overflow-x-auto">
                <div className="inline-grid xl:grid grid-cols-[repeat(4,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                  <p>{selectedLanguage?.name == "English" ? "Campaign Name" : translation[1364]?.value ? translation[1364]?.value : "Campaign Name"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Assign Segment" : translation[1365]?.value ? translation[1365]?.value : "Assign Segment"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Campaign Type" : translation[1374]?.value ? translation[1374]?.value : "Campaign Type"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Action" : translation[1366]?.value ? translation[1366]?.value : "Action"}</p>
                </div>
                <div
                  className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
                >
                  {campaigns?.length > 0
                    ? campaigns?.map((itm, i) => {
                      return (
                        <div key={i} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(4,minmax(115px,1fr))] min-w-full w-fit">
                          <div
                            onClick={() => getCampaignDetail(itm, 'detail')}
                            className="cursor-pointer h-full flex items-center gap-3">
                            <p className="text-sm text-primary">{itm?.title}</p>
                          </div>

                          <div className="flex items-center">
                            <p className="text-sm">{itm?.segment_data?.name}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm capitalize">
                              {itm?.campaign_type == "both" ? "Email/App Notification" : itm?.campaign_type}
                            </p>
                          </div>
                          <div className="flex items-center gap-3">
                            <div
                              className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center"
                              onClick={() => getCampaignDetail(itm, 'edit')}
                            >
                              <Svgs.Edit fill="#7D7D7D" />
                            </div>
                            <div
                              className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center"
                              onClick={() => getCampaignDetail(itm, 'delete')}
                            >
                              <Svgs.Delete fill="#7D7D7D" />
                            </div>
                          </div>
                        </div>
                      );
                    })
                    : <NotFound />
                  }
                  {/* -------------- */}
                </div>
              </div>
            }
          </div>

          {(campaigns?.length > 0 && !loading) &&
            <Pagination
              currentPage={currentPage}
              totalCount={campaignPagination?.pages}
              pageSize={10}
              onPageChange={page => setCurrentPage(page)}
            />
          }
        </div>
        {CreateCompanign && (
          <AddCompaign
            CreateCompanign={CreateCompanign}
            setCreateCompanign={setCreateCompanign}
            isEdit={isEdit}
            setApiCall={setApiCall}
            setIsEdit={setIsEdit}
            data={campaignDetails}
          />
        )
        }
        {FilterPopup && (
          <AddFilter setFilterPopup={setFilterPopup} FilterPopup={FilterPopup} />
        )}

        {ViewDetails &&
          <DetailCampaign
            data={campaignDetails}
            ViewDetails={ViewDetails}
            setViewDetails={setViewDetails}
            selectedLanguage
            translation
          />
        }

        {deletePopup &&
          <DeleteConfirmation
            title='Campaigns'
            isLoading={deleteLoading}
            handleClose={() => {
              setDeletePopup(false);
            }}
            handleDelete={() => deleteCampaign(campaignDetails?.id)}
            heading={"Are you sure you want to delete?"}
          />}

      </div>
    </>
  );
};

export default Campaigns;
