import React from 'react'
import Popup from "Components/TenantApp/Elements/Popup";
import moment from 'moment';
// import useDetailCompaignPopup from './helper';

const DetailCampaign = ({ data, ViewDetails, selectedLanguage, translation, setViewDetails }) => {

    return (
        <Popup
            size={"md"}
            heading={selectedLanguage?.name == "English" ? "Campaign Details" : translation[1389]?.value ? translation[1389]?.value : "Campaign Details"}
            open={ViewDetails}
            close={setViewDetails}
        >
            <div className="space-y-5">
                <div className="cursor-pointer h-full flex items-center gap-3">
                    <h5 className="text-[18px] font-semibold text-primary capitalize">{data?.title}</h5>
                </div>
                <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-1">
                        <h5 className=" text-[16px] font-medium">{selectedLanguage?.name == "English" ? "Segment" : translation[1389]?.value ? translation[1389]?.value : "Segment"}</h5>
                        <p className="text-[14px] font-normal">{data?.segment_data?.name}</p>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h5 className=" text-[16px] font-medium">{selectedLanguage?.name == "English" ? "Date & Time" : translation[1390]?.value ? translation[1390]?.value : "Date & Time"}</h5>
                        <div className="flex gap-2">
                            <p className="text-[14px] font-normal">{moment(data?.start_date).format('DD-MM-YYYY')}</p>
                            <p className="text-[14px] font-normal">{moment(data?.end_date).format('DD-MM-YYYY')}</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 capitalize">
                      <h5 className=" text-[16px] font-medium">{selectedLanguage?.name == "English" ? "Campaign Type" : translation[1391]?.value ? translation[1391]?.value : "Campaign Type"}</h5>
                        {/* {data?.campaign_type} */}
                        <p className="text-[14px] font-normal">{data?.campaign_type == "both" ? "Email/App Notification" : data?.campaign_type}</p>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h5 className=" text-[16px] font-medium">{selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description"}</h5>
                        <p className="text-[14px] font-normal">{data?.content}</p>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default DetailCampaign