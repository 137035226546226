import { BUSINESS_SETTING } from "../../ActionTypes/businessSettingTypes";
import { BUSINESS, LOCATION } from "../../ActionTypes/locationTypes";

const businessLocation = {
  locations: [],
  location_update: false,
  business_types: [],
  business_types_updated: false,
  business: {},
  selected_location: "",
  image_preview: null,
  current_location_data: null,
  profile_completion : {}
};





const BusinessSettingsReducers = (state = businessLocation, action) => {
  const { type, payload } = action;

  switch (type) {
    case BUSINESS_SETTING.UPDATE_BUSINESS_PROFILE_COMPLETION_PROGRESS:
      return {
        ...state,
        profile_completion : payload
      }
    case BUSINESS_SETTING.GET_BUSINESS_TYPES:
      return {
        ...state,
        business_types: payload,
        business_types_updated: true,
      };

    case LOCATION.ADD_LOCATION:
      return {
        ...state,
        locations: payload,
        location_update: true,
      };

    case LOCATION.SELECTED_LOCATION:
      return {
        ...state,
        selected_location: payload,
        location_update: true,
      };

    case LOCATION.CREATE_LOCATION:
      return {
        ...state,
        locations: [payload, ...state.locations],
      };


    case LOCATION.IMAGE_PREVIEW:
      return {
        ...state,
        image_preview: payload,
      };


    case LOCATION.STORE_CURRENT_LOCATION_DATA:
      return {
        ...state,
        current_location_data: payload,
      };


    case LOCATION.UPDATE_LOCATION:
      let updateList = state.locations.map((item) => {
        if (item.id === payload.id) {
          return payload;
        } else {
          return item;
        }
      });

      return {
        ...state,
        locations: [...updateList],
      };


    case LOCATION.DELETE_LOCATION:
      let result = state?.locations?.filter((item) => item.id !== payload);
      return {
        ...state,
        locations: [...result],
      };

    case BUSINESS.ADD_BUSINESS:
      return {
        ...state,
        business: payload,
      };

    case BUSINESS.UPDATE_BUSINESS:
      return {
        ...state,
        business: payload,
      };

    default:
      return state;
  }
};

export default BusinessSettingsReducers;
