import { CATEGORIES } from "../../ActionTypes/categoryTypes";

const initialState = {
  categories: [],
  categoriesDropdown: [],
  categoriesPagination: {},
  categoriesDropdownWithoutSearch: [],
  pages: 0,
  allCount: 0,
  all_categories: [],
  categories_updated: false,

};


const CategoryReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case CATEGORIES.GET_CATEGORIES:
      return {
        ...state,
        categories: payload?.response?.categories,
        pages: payload?.pages,
        allCount: payload?.count,
        categories_updated: true
      };

    case CATEGORIES.GET_CATEGORIES_DROPDOWNLIST:
      return {
        ...state,
        categories: payload,
        categories_updated: true
      };

    case CATEGORIES.GET_CATEGORIES_DROPDOWNLIST_PAGINATION:
      const uniqueCategoryWithSearch = Array.from(
        new Set([...state?.categoriesDropdown, ...payload?.response?.categories].map(category => category.id))).map(id => {
          return [...state?.categoriesDropdown, ...payload?.response?.categories].find(category => category.id === id);
        });

      const uniqueCategoryWithoutSearch = Array.from(
        new Set([...state?.categoriesDropdownWithoutSearch, ...payload?.response?.categories]?.map(category => category.id))).map(id => {
          return [...state?.categoriesDropdownWithoutSearch, ...payload?.response?.categories]?.find(category => category.id === id);
        });

      return {
        ...state,
        categories_updated: true,
        categoriesDropdownWithoutSearch: +payload?.current_page == 1 ? payload?.response?.categories
          : [...state?.categoriesDropdownWithoutSearch, ...payload?.response?.categories],
        categoriesDropdown: payload?.is_searched
          ? (Math.ceil(+payload?.pages) > 1 && +payload?.current_page > 1)
            ? uniqueCategoryWithSearch
            : payload?.response?.categories
          : payload?.current_page == 1
            ? payload?.response?.categories
            : uniqueCategoryWithoutSearch,
        // : [...state?.categoriesDropdown, ...payload?.response?.categories],


        categoriesPagination: {
          total: payload?.count,
          total_pages: Math.ceil(+payload?.pages),
          currentPage: +payload?.current_page,
          isSearched: payload?.is_searched
        }
      }


    case CATEGORIES.GET_CATEGORIES_WITH_NO_PAGINATION:
      return {
        ...state,
        all_categories: payload,
        categories_updated: true
      };


    case CATEGORIES.ADD_CATEGORY:
      return {
        ...state,
        categories: [payload, ...state.categories]
      };


    case CATEGORIES.DELETE_CATEGORY:
      let result = state?.categories?.filter((item) => item.id !== payload);
      return {
        ...state,
        categories: [...result],
      };


    case CATEGORIES.UPDATE_CATEGORY:
      let updateList = state.categories.map((item) => {
        if (item.id === payload.id) {
          return payload
        } else {
          return item;
        }
      })

      return {
        ...state,
        categories: [...updateList],
      };

    default:
      return state;
  }
};
export default CategoryReducer;
