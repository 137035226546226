import React from 'react'
import Svgs from 'Assets/svgs'
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import RadioBtn from 'Components/TenantApp/Elements/RadioBtn';
import Textarea from 'Components/TenantApp/Elements/Textarea';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import ToggleWithText from 'Components/TenantApp/Elements/ToggleWithText';
import useCreateDealHelper from './helper'
import SelectedOption from 'Components/TenantApp/Elements/SelectedOption';
import ImageUploader from 'Components/TenantApp/Elements/ImageUploader';
import ThumnailCard from 'Components/TenantApp/Elements/ThumnailCard';
import FloatInput from 'Components/TenantApp/Elements/FloatInput';
import moment from 'moment';
import FormSteps from 'Components/TenantApp/Elements/FormSteps';
import DealTypeThreeSetting from './DealTypeThreeSetting';
import DealTypeOneSetting from './DealTypeOneSetting';
import DealTypeTwoSetting from './DealTypeTwoSetting';
import DealTypeFourSetting from './DealTypeFourSetting';
import DealTypeFiveSetting from './DealTypeFiveSetting';
import DealTypeSevenSetting from './DealTypeSevenSetting';
import DealTypeEightSetting from './DealTypeEightSetting';
import DealTypeSixSetting from './DealTypeSixSetting';
import PreloaderFull from 'Components/TenantApp/Elements/PreloaderFull';
import Popup from 'Components/TenantApp/Elements/Popup';
import ReactDateTimePicker from 'Components/TenantApp/Elements/ReactDateTimePicker';
import AddPopup from '../../Elements/AddPopup';

const Index = () => {
  const {
    navigate, step, handleChange, data, errors, dealTypeOptions, dealCategoryOptions, setStep, handleChangeForRadio,
    dealBrandOptions, handleChangeForDropdown, removeSelectedItem, allOption, dealChannelOptions, allChannels,
    handleChangeForDate, firstStepSubmit, formRef, dealValidityOption, btnLoading, allProducts, allServices, restrictionData,
    handleRestrictionChange, blockDate, dayRestrictions, toggleDay, setToggleDay, toggleStore, setToggleStore, toggleblockdate,
    setToggleblockdate, termAndCondition, handleChangeTermsAndConditions, addTncs, tncsLoader, submitTermsAndCondition, tncs,
    handleImageChange, allImages, handleDeleteImage, handleThumbnailClick, storeRestriction, dealLocationOption, allLocations,
    addRestrictionData, toggleProduct, setToggleProduct, toggleService, setToggleService, restrictionLoader, imageLoader,
    submitDealImages, submitDealRestrictions, settingData, updateDealSettingData, settingsLoader, dealTypeSlug,
    optionsForDealTypeOne, allServicesGroup, allServicesCategory, allProductsBrand, submitSettingClick, anyOrAllOption,
    AllTypeOfDiscounts, allMergedOptions, setSettingData, setErrors, validityScheduleOptions, pageLoader, statusLoader,
    updateDealStatus, completeDealData, editDeal, dealIdFromLocal, verificationPopupForStepOne, setVerificationPopupForStepOne,
    excludedServicePopup, setExcludedServicePopup, excludedServiceType, setExcludedServiceType,
    handleChangeForRestrictionDropdownAdd, stepToNavigate, setStepToNavigate
  } = useCreateDealHelper()

  const { title, typeId, categoryId, code, audience, codeGeneration, usageLimitType, usagePerCustomer,
    brand, redeemableChannelIds, startDate, endDate, minimumSpend, description, validityPeriodTypeId,
    validPeriod, customerType
  } = data

  const { excludedProductIds, excludedServiceIds, excludedDates, excludedWeekDays, excludedLocationIds
  } = restrictionData

  const { discountAmount, applicalbleCategoryIds, serviceDiscount, productDiscount, voucherDiscount,
    requiredItemsSettings, applicableItemsSettings, applicableItems
  } = settingData

  return pageLoader ? <PreloaderFull /> : (
    <>
      <div className='p-10'>
        <div className="flex items-center gap-3 mb-10" onClick={() => {
          if (step != 1) {
            setStep(step - 1)
          } else {
            navigate('/dashboard/crm/deals')
          }
        }}>
          <span>
            <Svgs.Back />
          </span>
          <h2 className="text-primary font-bold lg:text-3xl text-xl">
            {editDeal ? "Edit Deal" : "Create Deal"}
          </h2>
        </div>

        <FormSteps
          currentStep={step}
          disabledStep={4}
          active={step}
          setStep={setStep}
          customStepValidation={true}
          setStepToNavigate={setStepToNavigate}
          setVerificationPopupForStepOne={setVerificationPopupForStepOne}
          allowNavigation={(brand?.length > 0 && dealIdFromLocal) ? true : false}
          steps={[
            "Basic Settings",
            "Restrictions",
            "Settings",
            "Preview & Activation"
          ]}
          stepsLength="4"
        />

        {step === 1 &&
          <div className="flex flex-col gap-6 text-left border border-solid border-[#3f3f4438] p-10 rounded-xl" ref={formRef}>
            <div className="flex items-center gap-4">
              <CircleIcon>
                <Svgs.NewService />
              </CircleIcon>
              <div className="flex flex-col gap-1">
                <h1 className="text-primary text-lg font-bold">
                  Create New Deal
                </h1>
                <p className="text-sm text-[#7B7B7B]">
                  Fill in the following information to add customers
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-8">
              <div className="grid md:grid-cols-2 gap-8">
                <LoginInput
                  required={true}
                  title={'Name'}
                  placeholder={'Enter Segment Name'}
                  // charactersMaxLength={25}
                  name="title"
                  value={title}
                  onChange={handleChange}
                  error={errors?.title}
                />
                <div>
                  <Dropwdown
                    required={true}
                    name="typeId"
                    value={typeId}
                    onChange={handleChange}
                    title={"Type"}
                    placeholder={"Select Type"}
                    options={dealTypeOptions?.map((itm) => {
                      return {
                        label: itm.name,
                        value: itm.typeId,
                      };
                    })}
                    error={errors?.typeId}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 gap-8">
                <div className="flex flex-col gap-2 md:order-1 order-2">
                  <Dropwdown
                    required={true}
                    custom_padding='pt-[2px]'
                    title={'Category'}
                    placeholder={"Select Category"}
                    name="categoryId"
                    value={categoryId}
                    onChange={handleChange}
                    options={dealCategoryOptions?.map((itm) => {
                      return {
                        label: itm.name,
                        value: itm.id,
                      };
                    })}
                    error={errors?.categoryId}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-3">
                  <h3 className=" text-lg font-semibold">
                    Audience
                  </h3>
                  <div className="flex items-center gap-10">
                    <div className="flex items-center gap-1">
                      <RadioBtn
                        text={"Public"}
                        name="audience"
                        id={'Public_audience'}
                        checked={audience === 'public'}
                        value={audience}
                        onClick={() => { handleChangeForRadio('audience', 'public') }}
                      />

                    </div>
                    <div className="flex items-center gap-1">
                      <RadioBtn
                        text={"Pre Set Client"}
                        name="audience"
                        id={'LimitedUsers_audience'}
                        checked={audience === 'pre-set-users'}
                        value={audience}
                        onClick={() => { handleChangeForRadio('audience', 'pre-set-users') }}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-3">
                  <h3 className=" text-lg font-semibold">
                    Code Strategy
                  </h3>
                  <div className="flex items-center gap-10">
                    <div className="flex items-center gap-1">
                      <RadioBtn
                        text={"Unique"}
                        name="codeGeneration"
                        id={'Unique_Code'}
                        value={codeGeneration}
                        checked={codeGeneration == 'unique'}
                        onClick={() => { handleChangeForRadio('codeGeneration', 'unique') }}
                      />
                    </div>
                    <div className="flex items-center gap-1">
                      <RadioBtn
                        text={"Generic"}
                        name="codeGeneration"
                        id={'generic'}
                        value={codeGeneration}
                        checked={codeGeneration == 'generic'}
                        onClick={() => { handleChangeForRadio('codeGeneration', 'generic') }}
                      />

                    </div>
                  </div>
                </div>
                <div className="space-y-3">
                  <h3 className=" text-lg font-semibold">
                    Client Type
                  </h3>
                  <div className="flex items-center gap-7">
                    <RadioBtn
                      text={"Any"}
                      name="customerType"
                      id={'anyCustomerType'}
                      value={customerType}
                      checked={customerType == 'any'}
                      onClick={() => { handleChangeForRadio('customerType', 'any') }}
                    />
                    <RadioBtn
                      text={"Lead"}
                      name="customerType"
                      id={'leadCustomerType'}
                      value={customerType}
                      checked={customerType == 'lead'}
                      onClick={() => { handleChangeForRadio('customerType', 'lead') }}
                    />
                    <RadioBtn
                      text={"New"}
                      name="customerType"
                      id={'newCustomerType'}
                      value={customerType}
                      checked={customerType == 'new'}
                      onClick={() => { handleChangeForRadio('customerType', 'new') }}
                    />
                    <RadioBtn
                      text={"Regular"}
                      name="customerType"
                      id={'regularCustomerType'}
                      value={customerType}
                      checked={customerType == 'regular'}
                      onClick={() => { handleChangeForRadio('customerType', 'regular') }}
                    />
                    <RadioBtn
                      text={"Lapsed"}
                      name="customerType"
                      id={'lapsedCustomerType'}
                      value={customerType}
                      checked={customerType == 'lapsed'}
                      onClick={() => { handleChangeForRadio('customerType', 'lapsed') }}
                    />
                  </div>
                </div>
                <div className="space-y-3">
                  <h3 className=" text-lg font-semibold">
                    Usage Limit
                  </h3>
                  <div className="flex items-center gap-10">
                    <RadioBtn
                      text={"Unlimited"}
                      name="Usage_Limit"
                      id={'Limited_Usage'}
                      value={usageLimitType}
                      checked={usageLimitType == 'unlimited'}
                      onClick={() => { handleChangeForRadio('usageLimitType', 'unlimited') }}
                    />
                    <RadioBtn
                      text={"Limited"}
                      name="Usage_Limit"
                      id={'Unlimited_Usage'}
                      value={usageLimitType}
                      checked={usageLimitType == 'limited'}
                      onClick={() => { handleChangeForRadio('usageLimitType', 'limited') }}
                    />
                  </div>
                </div>
              </div>

              {usageLimitType == 'limited' &&
                <div className="grid md:grid-cols-2 gap-8">
                  <LoginInput
                    required={true}
                    type={'number'}
                    title={"Limit Per User"}
                    placeholder='Enter limit per user'
                    name="usagePerCustomer"
                    onlyNumericAllowed={true}
                    value={usagePerCustomer}
                    onChange={handleChange}
                    error={errors?.usagePerCustomer}
                  />
                </div>
              }

              <div className="grid md:grid-cols-2 gap-8 py-5 pb-0">
                <div className="space-y-5">
                  <div>
                    <Dropwdown
                      required={true}
                      name="brand"
                      title={"Brand"}
                      placeholder={"Select Brand"}
                      onChange={(e) => { handleChangeForDropdown(e, 'brand') }}
                      disabled={dealBrandOptions?.length === 0}
                      options={[allOption, ...dealBrandOptions?.map((itm) => ({
                        label: itm.name,
                        value: itm.id,
                      }))]}
                      error={errors?.brand && brand?.length == 0}
                    />
                  </div>
                  {brand?.length > 0 && (
                    <div className="flex items-center flex-wrap gap-2">
                      {brand &&
                        brand?.map((item, i) => {
                          const filtered = allProductsBrand?.find((itm) => itm?.id == item);
                          return (
                            <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                              <p className="text-primary text-sm font-bold">{`${filtered?.name}`}</p>
                              <span
                                onClick={() =>
                                  removeSelectedItem(item, "brand")
                                }
                              >
                                <Svgs.CirlceClose fill="#5679FF" />
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
                <div className="space-y-5">
                  <div>
                    <Dropwdown
                      required={true}
                      name="redeemableChannelIds"
                      title={"Redeemable Channel"}
                      placeholder={"Select Channel"}
                      onChange={(e) => { handleChangeForDropdown(e, 'channel') }}
                      disabled={dealChannelOptions?.length === 0}
                      options={[allOption, ...dealChannelOptions?.map((itm) => ({
                        label: itm.name,
                        value: itm.channelId,
                      }))]}
                      error={errors?.redeemableChannelIds && redeemableChannelIds?.length == 0}
                    />
                  </div>
                  {redeemableChannelIds?.length > 0 && (
                    <div className="flex items-center flex-wrap gap-2">
                      {redeemableChannelIds &&
                        redeemableChannelIds?.map((item, i) => {
                          const filtered = allChannels?.find((itm) => itm?.channelId == item);
                          return (
                            <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                              <p className="text-primary text-sm font-bold">{`${filtered?.name}`}</p>
                              <span
                                onClick={() =>
                                  removeSelectedItem(item, "channel")
                                }
                              >
                                <Svgs.CirlceClose fill="#5679FF" />
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>

              <div className="grid md:grid-cols-2 gap-8 py-5 pb-0">
                <FloatInput
                  required={true}
                  title={'Minimum Spend'}
                  placeholder={'Enter Minimum Spend Amount'}
                  name="minimumSpend"
                  value={minimumSpend}
                  onChange={handleChange}
                  error={errors?.minimumSpend}
                />
                <LoginInput
                  required={true}
                  title={'Deal Code'}
                  placeholder={'Enter Deal Code'}
                  name="code"
                  capatalizeInput={true}
                  charactersMaxLength={12}
                  value={code}
                  onChange={handleChange}
                  error={errors?.code}
                />
              </div>


              {/* <div className=" space-y-3">
              <h3 className="text-lg font-bold">Redeemable Channel</h3>
              <div className='grid md:grid-cols-2 gap-8'>
                <DropdownWithCheckbox
                  required={true}
                  name=""
                  title={""}
                  placeholder={"Select Channel"}
                  options={[
                    { label: "All", value: 'All' },
                    { label: "Online", value: 'Online' },
                    { label: "Mobile", value: 'Mobile' },
                    { label: "POS", value: 'POS' },
                  ]}
                />
              </div>
            </div> */}
              <div className="">
                <h3 className="text-lg font-bold">
                  Date Restrictions
                </h3>
                <div className="grid md:grid-cols-2 gap-8 py-5 pb-0">
                  <ReactDateTimePicker
                    required={true}
                    name={"startDate"}
                    value={startDate}
                    onChange={handleChangeForDate}
                    title={"Start Date"}
                    error={errors?.startDate}
                  />
                  <ReactDateTimePicker
                    required={false}
                    name={"endDate"}
                    value={endDate}
                    min={startDate}
                    onChange={handleChangeForDate}
                    title={"End Date"}
                  />
                  {/* <DateTimePicker
                    required={true}
                    name={"startDate"}
                    value={startDate}
                    onChange={handleChangeForDate}
                    title={"Start Date"}
                    error={errors?.startDate}
                  />
                  <DateTimePicker
                    required={false}
                    name={"endDate"}
                    value={endDate}
                    min={minEndDateLimit}
                    onChange={handleChangeForDate}
                    title={"End Date"}
                  /> */}
                </div>
                <div className="grid md:grid-cols-2 gap-8 py-5">
                  <Dropwdown
                    required={true}
                    name="validityPeriodTypeId"
                    value={validityPeriodTypeId}
                    title={"Deal Validity"}
                    placeholder={"Select Deal Validity"}
                    error={errors?.validityPeriodTypeId && !validityPeriodTypeId}
                    options={dealValidityOption?.map((itm) => ({
                      label: itm.name,
                      value: itm.id,
                    }))}
                    onChange={(e) => { handleChangeForDropdown(e, 'validity') }}
                  />
                  {(validityPeriodTypeId && validityPeriodTypeId !== 'from-start-to-end-date') &&
                    <LoginInput
                      required={true}
                      title={'Validity Period'}
                      placeholder={'Enter Validity Period'}
                      name="validPeriod"
                      value={validPeriod}
                      type={'number'}
                      onlyNumericAllowed={true}
                      onChange={handleChange}
                      error={errors?.validPeriod}
                    />
                  }
                </div>
                <Textarea
                  required={true}
                  name="description"
                  value={description}
                  onChange={handleChange}
                  rows='5'
                  title={"Description"}
                  placeholder={"Enter description"}
                  error={errors?.description}
                />
              </div>

            </div>
          </div>
        }

        {step === 2 &&
          <div className="flex flex-col gap-8 text-left border border-solid border-[#3f3f4438] p-10 rounded-xl" ref={formRef}>
            {(excludedServiceType == 'serviceRestriction' && excludedServicePopup) &&
              <AddPopup
                completeDataLength={allServices?.length}
                firstStepData={data}
                open={excludedServicePopup}
                close={() => {
                  setExcludedServicePopup(false)
                  setExcludedServiceType()
                }}
                heading={"Select Services"}
                placeholder={"Search Service"}
                apiType={"/deal/services"}
                data={excludedServiceIds?.map(itm => itm)}
                onSubmitHandler={(e, data) => { handleChangeForRestrictionDropdownAdd(e, data, 'service', 'excludedServiceIds') }}
              />
            }
            {(excludedServiceType == 'productRestriction' && excludedServicePopup) &&
              <AddPopup
                completeDataLength={allProducts?.length}
                firstStepData={data}
                open={excludedServicePopup}
                close={() => {
                  setExcludedServicePopup(false)
                  setExcludedServiceType()
                }}
                heading={"Select Products"}
                placeholder={"Search Product"}
                apiType={"/deal/products"}
                data={excludedProductIds?.map(itm => itm)}
                onSubmitHandler={(e, data) => { handleChangeForRestrictionDropdownAdd(e, data, 'product', 'excludedProductIds') }}
              />
            }
            <div className="flex flex-col gap-4">
              <ToggleWithText
                textClass={"!text-lg"}
                title={"Product Restrictions"}
                value={toggleProduct}
                onChange={() => setToggleProduct(!toggleProduct)}
                checked={toggleProduct}
              />
              {toggleProduct && (
                <>
                  <div className="flex items-end gap-3">
                    <LoginBtn
                      text={'Select Product'}
                      // className={'!w-full justify-center'}
                      onClick={() => {
                        setExcludedServicePopup(true)
                        setExcludedServiceType('productRestriction')
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-2 flex-wrap">
                    {excludedProductIds?.map((obj, index) => {
                      const filtered = allProducts?.find((itm) => itm?.id == obj);
                      return (
                        <>
                          <SelectedOption
                            // textColor={"text-[#5679FF]"}
                            rounded={"full"}
                            name={filtered?.name}
                            onClick={() => removeSelectedItem(obj, 'product')}
                          />
                        </>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col gap-4">
              <ToggleWithText
                textClass={"!text-lg"}
                title={"Service Restrictions"}
                value={toggleService}
                onChange={() => setToggleService(!toggleService)}
                checked={toggleService}
              />
              {toggleService && (
                <>
                  <div className="flex items-end gap-3">
                    <LoginBtn
                      text={'Select Service'}
                      // className={'!w-full justify-center'}
                      onClick={() => {
                        setExcludedServicePopup(true)
                        setExcludedServiceType('serviceRestriction')
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-2 flex-wrap">
                    {excludedServiceIds?.map((obj, index) => {
                      const filtered = allServices?.find((itm) => itm?.id == obj);
                      return (
                        <>
                          <SelectedOption
                            // textColor={"text-[#5679FF]"}
                            rounded={"full"}
                            name={filtered?.name}
                            onClick={() => removeSelectedItem(obj, 'service')}
                          />
                        </>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col gap-4">
              <ToggleWithText
                textClass={'!text-lg'}
                title={"Add Block Date"}
                value={toggleblockdate}
                onChange={() => setToggleblockdate(!toggleblockdate)}
                checked={toggleblockdate}
              />
              {toggleblockdate &&
                <>
                  <div className="flex items-end gap-3">
                    <LoginInput
                      name="blockDate"
                      title={"Add Date"}
                      type="date"
                      parentClass="flex-1"
                      required={false}
                      value={blockDate}
                      min={moment(data?.startDate).format('YYYY-MM-DD')}
                      max={data?.endDate && moment(data?.endDate).format('YYYY-MM-DD')}
                      onChange={(e) => { handleRestrictionChange(e, 'blockDate') }}
                      error={errors?.blockDate}
                    // disabled={
                    //   !data?.startDate ||
                    //   !data?.endDate
                    // }
                    />
                    <LoginBtn
                      disabled={!blockDate}
                      className={blockDate && "shake-bottom"}
                      onClick={() => { addRestrictionData('blockDate') }}
                      text={
                        <div className="flex items-center gap-2">
                          <Svgs.Plus />
                          <p>
                            Add
                          </p>
                        </div>
                      }
                    />
                  </div>
                  <div className="flex items-center gap-2 flex-wrap">
                    {excludedDates?.map((obj, index) => (
                      <>
                        <SelectedOption
                          // textColor={"text-[#5679FF]"}
                          rounded={"full"}
                          name={obj}
                          onClick={() => removeSelectedItem(obj, 'blockDate')}
                        />
                      </>
                    ))}
                  </div>
                </>
              }
            </div>
            <div className="flex flex-col gap-4">
              <ToggleWithText
                textClass={"!text-lg"}
                title={"Store Restrictions"}
                value={toggleStore}
                onChange={() => setToggleStore(!toggleStore)}
                checked={toggleStore}
              />
              {toggleStore && (
                <>
                  <div className="flex items-end gap-3">
                    <Dropwdown
                      // required={true}
                      name="storeRestriction"
                      placeholder={"Store"}
                      title={"Select stores where this promotions will not be available"}
                      parentClass="flex-1"
                      value={storeRestriction}
                      onChange={(e) => { handleRestrictionChange(e, 'storeRestriction') }}
                      options={dealLocationOption?.map((itm) => ({
                        label: itm.address_name,
                        value: itm.id,
                      }))}
                    />
                    <LoginBtn
                      className={storeRestriction && "shake-bottom"}
                      text={
                        <div className="flex items-center gap-2">
                          {" "}
                          <Svgs.Plus />
                          <p>
                            Add
                          </p>
                        </div>
                      }
                      onClick={() => { addRestrictionData('storeRestriction') }}
                      disabled={!storeRestriction}
                    />
                  </div>
                  <div className="flex items-center gap-2 flex-wrap">
                    {excludedLocationIds?.map((obj, index) => {
                      const filtered = allLocations?.find((itm) => itm?.id == obj);
                      return (
                        <>
                          <SelectedOption
                            // textColor={"text-[#5679FF]"}
                            rounded={"full"}
                            name={filtered?.address_name}
                            onClick={() => removeSelectedItem(obj, 'storeRestriction')}
                          />
                        </>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col gap-4">
              <ToggleWithText
                textClass={"!text-lg"}
                title={"Day Restrictions"}
                value={toggleDay}
                onChange={() => setToggleDay(!toggleDay)}
                checked={toggleDay}
              />
              {toggleDay && (
                <div className="flex flex-wrap gap-8">
                  {dayRestrictions?.map((item) => {
                    return (
                      <CheckBox
                        name={item}
                        label={item}
                        value={item}
                        onChange={(e) => { handleRestrictionChange(e, 'dayRestriction') }}
                        checked={excludedWeekDays?.includes(item)}
                      />
                    );
                  })}
                </div>
              )}
            </div>
            <div className="flex items-center justify-end">
              <LoginBtn
                successBtn
                onClick={submitDealRestrictions}
                loading={restrictionLoader}
                disabled={restrictionLoader}
                text={
                  <div className="flex items-center gap-3 px-7">
                    <p>
                      Save
                    </p>
                  </div>
                }
              />
            </div>
            <div className='flex flex-col gap-4'>
              <p className={`text-lg font-semibold cursor-pointer`}>Upload Images</p>
              <div className='flex flex-wrap gap-5'>
                {/* Uploader 1 */}
                <ImageUploader
                  title={"Mobile"}
                  name={"mobile"}
                  imgSize={"375x200"}
                  accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"}
                  mb_file_size={'5'}
                  imgWidth='375'
                  imgHeight='200'
                  id={'upload_img_mobile'}
                  onChange={(e) => { handleImageChange(e, 'mobile') }}
                />
                {/* Uploader 2 */}
                <ImageUploader
                  title={"Website"}
                  imgSize={"600x150"}
                  name={"website"}
                  accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"}
                  mb_file_size={'5'}
                  imgWidth='600'
                  imgHeight='150'
                  id={'upload_img_website'}
                  onChange={(e) => { handleImageChange(e, 'website') }}
                />

              </div>
              <div className="flex flex-wrap gap-5">
                {allImages?.map((itm) => {
                  return (
                    <ThumnailCard
                      url={itm?.url}
                      title={itm?.fileName}
                      onDelete={() => { handleDeleteImage(itm?.fileUrlId) }}
                      onThumbnailClick={() => { handleThumbnailClick(itm?.fileUrlId) }}
                      isThumbnail={itm?.isThumbnail}
                    />
                  )
                })}
              </div>
              <div className="flex items-center justify-end">
                <span className='text-sm text-red-600'>{(allImages?.length == 0 && errors?.allImages) &&
                  'Add at least one image'}</span>
                <LoginBtn
                  successBtn
                  onClick={submitDealImages}
                  loading={imageLoader}
                  disabled={imageLoader}
                  text={
                    <div className="flex items-center gap-3 px-7">
                      <p>
                        Save
                      </p>
                    </div>
                  }
                />
              </div>
              <div>
                <Textarea
                  title={"Terms & Conditions"}
                  rows={5}
                  limit={150}
                  placeholder={"Enter details"}
                  required={true}
                  name={'termAndCondition'}
                  value={termAndCondition}
                  onChange={handleChangeTermsAndConditions}
                  errorMessage={errors?.termAndCondition}
                />
                <div className="flex items-center gap-2 flex-wrap">
                  {tncs?.map((obj, index) => (
                    <>
                      <SelectedOption
                        // textColor={"text-[#5679FF]"}
                        rounded={"full"}
                        name={obj}
                        onClick={() => removeSelectedItem(index, 'termAndCondition')}
                      />
                    </>
                  ))}
                </div>
              </div>
              <div className="flex items-center justify-between flex-wrap gap-5">
                <LoginBtn
                  className={termAndCondition && "shake-bottom"}
                  onClick={addTncs}
                  disabled={!termAndCondition}
                  text={
                    <div className="flex items-center gap-2">
                      <Svgs.Plus />
                      <p>
                        Add
                      </p>
                    </div>
                  }
                />
                <LoginBtn
                  successBtn
                  onClick={submitTermsAndCondition}
                  loading={tncsLoader}
                  disabled={tncsLoader}
                  text={
                    <div className="flex items-center gap-3 px-7">
                      <p>
                        Save
                      </p>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        }

        {step === 3 &&
          <div className="flex flex-col gap-6 text-left border border-solid border-[#3f3f4438] p-10 rounded-xl" ref={formRef}>
            <p className={`text-lg font-semibold cursor-pointer`}>Deal Settings</p>
            <div className="flex flex-col gap-8">
              {dealTypeSlug == "fixed-amount-discount-deal" &&
                <DealTypeOneSetting
                  discountAmount={discountAmount}
                  applicalbleCategoryIds={applicalbleCategoryIds}
                  setSettingData={setSettingData}
                  settingData={settingData}
                  dealTypeSlug={dealTypeSlug}
                  setErrors={setErrors}
                  submitSettingClick={submitSettingClick}
                  optionsForDealTypeOne={optionsForDealTypeOne}
                  allOption={allOption}
                />
              }
              {dealTypeSlug == "percentage-discount-deal" &&
                <DealTypeTwoSetting
                  firstStepData={data}
                  serviceDiscount={serviceDiscount}
                  productDiscount={productDiscount}
                  voucherDiscount={voucherDiscount}
                  errors={errors}
                  setSettingData={setSettingData}
                  settingData={settingData}
                  allProducts={allProducts}
                  allServices={allServices}
                  allProductsBrand={allProductsBrand}
                  allServicesCategory={allServicesCategory}
                  allServicesGroup={allServicesGroup}
                  submitSettingClick={submitSettingClick}
                  allMergedOptions={allMergedOptions}
                  setErrors={setErrors}
                />
              }
              {dealTypeSlug == "buy-some-get-some-free-or-discount" &&
                <>
                  <DealTypeThreeSetting
                    firstStepData={data}
                    requiredItemsSettings={requiredItemsSettings}
                    applicableItemsSettings={applicableItemsSettings}
                    setSettingData={setSettingData}
                    settingData={settingData}
                    allProducts={allProducts}
                    allServices={allServices}
                    allProductsBrand={allProductsBrand}
                    allServicesCategory={allServicesCategory}
                    allServicesGroup={allServicesGroup}
                    submitSettingClick={submitSettingClick}
                    allMergedOptions={allMergedOptions}
                    errors={errors}
                    setErrors={setErrors}
                    anyOrAllOption={anyOrAllOption}
                    AllTypeOfDiscounts={AllTypeOfDiscounts}
                  />
                </>
              }
              {dealTypeSlug == "get-free-on-purchasing-specific-items" &&
                <>
                  <DealTypeFourSetting
                    firstStepData={data}
                    requiredItemsSettings={requiredItemsSettings}
                    applicableItemsSettings={applicableItemsSettings}
                    setSettingData={setSettingData}
                    settingData={settingData}
                    allProducts={allProducts}
                    allServices={allServices}
                    allProductsBrand={allProductsBrand}
                    allServicesCategory={allServicesCategory}
                    allServicesGroup={allServicesGroup}
                    submitSettingClick={submitSettingClick}
                    allMergedOptions={allMergedOptions}
                    errors={errors}
                    setErrors={setErrors}
                    anyOrAllOption={anyOrAllOption}
                    AllTypeOfDiscounts={AllTypeOfDiscounts}
                    validityScheduleOptions={validityScheduleOptions}
                  />
                </>
              }
              {dealTypeSlug == "fixed-price-items-deal" &&
                <DealTypeFiveSetting
                  firstStepData={data}
                  applicableItems={applicableItems}
                  errors={errors}
                  setSettingData={setSettingData}
                  settingData={settingData}
                  allProducts={allProducts}
                  allServices={allServices}
                  allProductsBrand={allProductsBrand}
                  allServicesCategory={allServicesCategory}
                  allServicesGroup={allServicesGroup}
                  submitSettingClick={submitSettingClick}
                  allMergedOptions={allMergedOptions}
                  setErrors={setErrors}
                />
              }
              {dealTypeSlug == "spend-some-amount-get-some-reward" &&
                <>
                  <DealTypeSixSetting
                    firstStepData={data}
                    requiredItemsSettings={requiredItemsSettings}
                    applicableItemsSettings={applicableItemsSettings}
                    setSettingData={setSettingData}
                    settingData={settingData}
                    allProducts={allProducts}
                    allServices={allServices}
                    allProductsBrand={allProductsBrand}
                    allServicesCategory={allServicesCategory}
                    allServicesGroup={allServicesGroup}
                    submitSettingClick={submitSettingClick}
                    allMergedOptions={allMergedOptions}
                    errors={errors}
                    setErrors={setErrors}
                    anyOrAllOption={anyOrAllOption}
                    AllTypeOfDiscounts={AllTypeOfDiscounts}
                    allOption={allOption}
                    optionsForDealTypeOne={optionsForDealTypeOne}
                    dealTypeSlug={dealTypeSlug}
                  />
                </>
              }
              {dealTypeSlug == "complimentary-voucher" &&
                <DealTypeSevenSetting
                  firstStepData={data}
                  applicableItems={applicableItems}
                  errors={errors}
                  setSettingData={setSettingData}
                  settingData={settingData}
                  allProducts={allProducts}
                  allServices={allServices}
                  allProductsBrand={allProductsBrand}
                  allServicesCategory={allServicesCategory}
                  allServicesGroup={allServicesGroup}
                  submitSettingClick={submitSettingClick}
                  allMergedOptions={allMergedOptions}
                  setErrors={setErrors}
                />
              }
              {dealTypeSlug == "package" &&
                <DealTypeEightSetting
                  firstStepData={data}
                  applicableItems={applicableItems}
                  errors={errors}
                  setSettingData={setSettingData}
                  settingData={settingData}
                  allProducts={allProducts}
                  allServices={allServices}
                  allProductsBrand={allProductsBrand}
                  allServicesCategory={allServicesCategory}
                  allServicesGroup={allServicesGroup}
                  submitSettingClick={submitSettingClick}
                  allMergedOptions={allMergedOptions}
                  setErrors={setErrors}
                />
              }
              <div className="flex items-center justify-end">
                <LoginBtn
                  successBtn
                  onClick={updateDealSettingData}
                  loading={settingsLoader}
                  disabled={settingsLoader}
                  text={
                    <div className="flex items-center gap-3 px-7">
                      <p>
                        Save
                      </p>
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        }
        {step === 4 &&
          <div className="flex flex-col gap-6 text-left border border-solid border-[#3f3f4438] p-10 rounded-xl" ref={formRef}>
            <div className='flex flex-col gap-4'>
              <p className={`text-lg font-semibold`}>Deal Preview</p>
              <p className={`font-semibold`}>Title: <span className='text-sm font-normal'>{completeDealData?.title}</span></p>
              <p className={`font-semibold`}>Type: <span className='text-sm font-normal'>{completeDealData?.type?.name}</span></p>
              <p className={`font-semibold`}>Category: <span className='text-sm font-normal'>{completeDealData?.category?.name}</span></p>
              <p className={`font-semibold`}>Description: <span className='text-sm font-normal'>{completeDealData?.description}</span></p>
            </div>
          </div>
        }

        <div className="flex items-center justify-end  mt-10">
          {step != 4 ?
            <LoginBtn
              onClick={() => {
                if (step == 1) {
                  firstStepSubmit()
                } else {
                  setStep(step + 1)
                }
              }}
              loading={btnLoading}
              disabled={btnLoading}
              text={
                <div className="flex items-center gap-3 px-7">
                  <p>
                    Next
                  </p>
                </div>
              }
            /> :
            <LoginBtn
              onClick={updateDealStatus}
              loading={statusLoader}
              disabled={statusLoader}
              text={
                <div className="flex items-center gap-3 px-7">
                  <p>
                    Activate & Launch Deal
                  </p>
                </div>
              }
            />
          }
        </div>
      </div>
      {
        verificationPopupForStepOne &&
        <Popup
          heading={"Confirm to Switch Step"}
          close={setVerificationPopupForStepOne}
          open={verificationPopupForStepOne}
        >
          <div>
            <p>You have to submit first step before moving to other step.</p>
            <div className='flex items-center justify-end gap-3 py-5'>
              <LoginBtn
                text={'Discard'}

                onClick={() => {
                  setVerificationPopupForStepOne(false)
                }}
              />
              <LoginBtn
                text={'Submit'}
                successBtn
                onClick={() => {
                  firstStepSubmit(stepToNavigate)
                }}
              />
            </div>
          </div>
        </Popup>
      }
    </>
  )
}

export default Index