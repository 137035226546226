import { SERVICES } from "../../ActionTypes/ServicesTypes/index";

const initialState = {
    services: [],
    servicesDropdown: [],
    servicesDropdownWithoutSearch: [],
    servicesPagination: {},

};

const ServiceReducer = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {

        case SERVICES.GET_SERVICES:
            return {
                ...state,
                services: payload,
            };
        case SERVICES.GET_SERVICES_DROPDOWN_PAGINATION:
            const uniqueBrandWithSearch = Array.from(
                new Set([...state?.servicesDropdown, ...payload?.response?.services].map(service => service.id))).map(id => {
                    return [...state?.servicesDropdown, ...payload?.response?.services].find(service => service.id === id);
                });

            const uniqueBrandWithoutSearch = Array.from(
                new Set([...state?.servicesDropdownWithoutSearch, ...payload?.response?.services]?.map(service => service.id))).map(id => {
                    return [...state?.servicesDropdownWithoutSearch, ...payload?.response?.services]?.find(service => service.id === id);
                });
            return {
                ...state,
                // productDropdown: payload?.is_searched
                //     ? (Math.ceil(+payload?.pages) > 1 && +payload?.current_page > 1)
                //         ? [...state?.productDropdown, ...payload?.response?.products]
                //         : payload?.response?.products
                //     : [...state?.productDropdownWithoutSearch, ...payload?.response?.products],

                // productDropdownWithoutSearch: payload?.current_page == 1 ? payload?.response?.products
                //     : [...state?.productDropdownWithoutSearch, ...payload?.response?.products],

                servicesDropdown: payload?.is_searched
                    ? (Math.ceil(+payload?.pages) > 1 && +payload?.current_page > 1)
                        ? uniqueBrandWithSearch
                        : payload?.response?.services
                    : uniqueBrandWithoutSearch,

                // : action.payload?.current_page == 1
                //     ? payload?.response?.services
                //     : [...state?.servicesDropdown, ...payload?.response?.services],

                // servicesDropdown: payload?.is_searched ? payload?.response?.services
                //     : [...state?.servicesDropdownWithoutSearch, ...payload?.response?.services],

                servicesDropdownWithoutSearch: payload?.current_page == 1
                    ? payload?.response?.services
                    : [...state?.servicesDropdownWithoutSearch, ...payload?.response?.services],

                servicesPagination: {
                    total: payload?.count,
                    total_pages: Math.ceil(+payload?.pages),
                    currentPage: +payload?.current_page,
                    isSearched: payload?.is_searched
                }
            }
        default:
            return state;
    }
};
export default ServiceReducer;