import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import MobileVerifyInput from "Components/TenantApp/Elements/MobileVerifyInput";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import useMobileVerifyForm from "./useMobileVerifyForm";
import useObjectKeys from "Hooks/useObjectKeys";
import useValidations from "Hooks/useValidations";
import { useCommonHooks } from "Hooks/useCommonHooks";
import {
  resendVerificationCodeToPhoneNumberOrEmail,
  verificationOfPhoneNumberOrEmail,
} from "Adapters/Api";
import VerifyNumber from "Assets/svgs/Login/VerifyNumber";
import { apiErrorMessage } from "Constants/Data/Errors";
import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";

const MobileVerify = ({
  setStep,
  topHeading,
  secondHeading,
  code_for,
  sent_to,
  calledin,
  checkAccountType,
  ...props
}) => {
  const inp_1 = useRef();
  const inp_2 = useRef();
  const inp_3 = useRef();
  const inp_4 = useRef();
  let hiddenMobileNumberDigit;
  const { hiddenMobileNumberDigits } = useCommonHooks();

  const [loading, setLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [test, setTest] = useState(false);
  const [count, setCount] = useState(isFinished ? 0 : 120);

  const { verificationCode, setVerificationCode, onChangeHandler } =
    useMobileVerifyForm();
  const { removeWhiteSpacesAndSpecialCharactors } = useValidations();
  const { objectKeyConvertToArray } = useObjectKeys();

  const { num1, num2, num3, num4 } = verificationCode;
  const state = useSelector((state) => state.userData?.personalInfo);
  const passwordData = useSelector((state) => state.passwordData);


  //hide some digits of mobile number here
  if (state?.account_email || state?.email) {
    hiddenMobileNumberDigit = hiddenMobileNumberDigits(state?.account_email ? state?.account_email : state?.email);
  } else if (passwordData?.email) {
    hiddenMobileNumberDigit = hiddenMobileNumberDigits(passwordData?.email);
  }

  // resend verification code to mobile number or email
  const resendVerificationCodeToMobileOrEmail = async () => {
    setLoading(true);
    let code;
    let sendObj;

    code = `${num1}${num2}${num3}${num4}`;
    sendObj = {
      code_for: code_for,
      ignore_activity: true,
    };
    if (code_for == "Email") {
      sendObj["email"] = sent_to;
    } else if (code_for == "Mobile") {
      sendObj["mobile_number"] = sent_to;
    }

    const response = await resendVerificationCodeToPhoneNumberOrEmail(sendObj);
    setLoading(false);

    if (response?.status === 200) {
      let successMessage = response?.data?.response?.message;
      setIsFinished(false);
      setLoading(false);
      setVerificationCode((prev) => ({
        num1: "",
        num2: "",
        num3: "",
        num4: "",
      }));
      setCount(120);
      setTest(!test)
      toast.success(successMessage, { toastId: "toast" });
    } else {
      toast.error(apiErrorMessage, { toastId: "toast" });
    }
  };

  useEffect(() => {
    let num_inps = document.querySelectorAll(".numberAddInp");
    num_inps.forEach((inp, i) => {
      inp.addEventListener("input", () => {
        const inputValue = removeWhiteSpacesAndSpecialCharactors(inp.value);
        if (inputValue && inputValue != "") {
          if (i != num_inps.length - 1) {
            num_inps[i + 1].select();
          }
        }
      });
    });
  }, [test]);

  //if user enter verification correct or fill all fields navigate to next secreen
  const verifiyingPhoneOrEmail = async () => {
    if (objectKeyConvertToArray(verificationCode)?.length === 4) {
      let code;
      let data;
      if (num1 && num2 && num3 && num4) {
        setLoading(true)
        if (code_for) {
          code = `${num1}${num2}${num3}${num4}`;
          data = {
            code: code,
            code_for: code_for,
          };
          if (code_for == "Email") {
            data["email"] = sent_to;
          } else if (code_for == "Mobile") {
            data["mobile_number"] = sent_to;
          }
        } else {
          return;
        }
        const response = await verificationOfPhoneNumberOrEmail(data);
        if (response && response?.status === 200) {

          try {
            props.onVerifyOTP(response);
            setLoading(false)
          } catch {
            // toast.success("OTP Verified");
          }
        } else {
          toast.error("Please enter a valid OTP", { toastId: "toast" });
          setLoading(false)
          setTest(!test)
          if (inp_1 && inp_1.current) {
            inp_1.current.focus();
          }
          setVerificationCode((prev) => ({
            num1: "",
            num2: "",
            num3: "",
            num4: "",
          }));
        }

        // }
      }
    }
  };

  //customer email verifications
  const verifiyingBusinessClientEmail = async () => {
    if (objectKeyConvertToArray(verificationCode)?.length === 4) {
      let code;
      let data;
      if (num1 && num2 && num3 && num4) {
        setLoading(true)
        if (code_for) {
          code = `${num1}${num2}${num3}${num4}`;
          data = {
            code: code,
            code_for: code_for,
          };
          if (code_for == "Email") {
            data["email"] = sent_to;
          } else if (code_for == "Mobile") {
            data["mobile_number"] = sent_to;
          }
        } else {
          return;
        }
        // const response = await emailVerificationOfBusinessClient(data);
        const response = await verificationOfPhoneNumberOrEmail(data);

        if (response && response?.status === 200) {
          try {
            props.onVerifyOTP(response);
            setLoading(false)
          } catch {
            // toast.success("OTP Verified");
          }
        } else {
          toast.error("Please enter a valid OTP", { toastId: "toast" });
          setLoading(false)
          if (inp_1 && inp_1.current) {
            inp_1.current.focus();
          }
          // setIsFinished(true);
          // setCount(0);
          setTest(!test)
          setVerificationCode((prev) => ({
            num1: "",
            num2: "",
            num3: "",
            num4: "",
          }));
        }

        // }
      }
    }
  };

  //if user enter verification correct or fill all fields navigate to next secreen
  useEffect(() => {
    if (checkAccountType === "business") {
      verifiyingPhoneOrEmail();
    } else {
      verifiyingBusinessClientEmail();
    }
  }, [verificationCode]);

  //decrement count in very second
  const countDown = () => {
    if (count !== 0) {
      setCount(count - 1);
    }
  };

  // handle counter function here
  useEffect(() => {
    if (!isFinished) {
      setTimeout(() => {
        countDown();
      }, 1000);
    }
  }, [count, isFinished]);

  useEffect(() => {
    setStep(2);
  }, []);

  return (
    (loading ?
      <>
        {/* this div is only to render the same OTP screen on loading state no functional use for this div */}
        <div className="slide-in-right bg-white sm:bg-[linear-gradient(to_bottom_left,rgba(166,77,255,1),rgba(54,67,120,1),rgba(54,67,120,1),rgba(87,120,255,1))!important] fixed top-0 left-0 z-[100] h-[100vh] w-[100vw] flex sm:justify-center items-center">
          <div className="flex flex-col gap-5 items-center lg:text-left text-center bg-white p-3 sm:p-10 rounded-3xl w-full sm:w-auto">
            <VerifyNumber />

            {topHeading ? (
              <h1 className="text-3xl font-light text-[#7B7B7B] lg:text-left text-center">
                {code_for}{" "}
                <span className="font-semibold text-[#374168]">{topHeading}</span>
              </h1>
            ) : (
              <h3 className="font-semibold text-2xl">
                Verify Your{" "}
                {code_for?.toLowerCase() == "email" ? "Email" : "Mobile Number"}
              </h3>
            )}
            {secondHeading ? (
              <p className="text-[#7B7B7B] lg:text-left text-center text-sm">
                {secondHeading}
                <br />{" "}
                <span className="font-semibold text-primary">
                  {code_for == "Email" ? (
                    `${hiddenMobileNumberDigits(sent_to)}`
                  ) : (
                    <p className="font-semibold">{hiddenMobileNumberDigit}</p>
                  )}
                </span>
              </p>
            ) : (
              <div className="text-sm">
                <p className="text-[#7B7B7B]">
                  Please verify your email by entering the 4-digit code sent to{" "}
                </p>
                <p className="font-semibold text-left md:text-center"><span className="text-[#7B7B7B] text-sm">sent to</span> {hiddenMobileNumberDigit}</p>
              </div>
            )}

            <div className="flex items-center gap-10 md:gap-8 mt-0 lg:mt-6">
              <MobileVerifyInput
                name={"num1"}
                value={num1}
                inp_ref={inp_1}
              />
              <MobileVerifyInput
                name={"num2"}
                value={num2}
                inp_ref={inp_2}
              />
              <MobileVerifyInput
                name={"num3"}
                value={num3}
                inp_ref={inp_3}
              />
              <MobileVerifyInput
                name={"num4"}
                value={num4}
                inp_ref={inp_4}
              />
            </div>
          </div>
        </div>
        <PreloaderFull />
      </>
      :
      <div className="slide-in-right bg-white sm:bg-[linear-gradient(to_bottom_left,rgba(166,77,255,1),rgba(54,67,120,1),rgba(54,67,120,1),rgba(87,120,255,1))!important] fixed top-0 left-0 z-[100] h-[100vh] w-[100vw] flex sm:justify-center items-center">
        <div className="flex flex-col gap-5 items-center lg:text-left text-center bg-white p-3 sm:p-10 rounded-3xl w-full sm:w-auto">
          <VerifyNumber />

          {topHeading ? (
            <h1 className="text-3xl font-light text-[#7B7B7B] lg:text-left text-center">
              {code_for}{" "}
              <span className="font-semibold text-[#374168]">{topHeading}</span>
            </h1>
          ) : (
            <h3 className="font-semibold text-2xl">
              Verify Your{" "}
              {code_for?.toLowerCase() == "email" ? "Email" : "Mobile Number"}
            </h3>
          )}
          {secondHeading ? (
            <p className="text-[#7B7B7B] lg:text-left text-center text-sm">
              {secondHeading}
              <br />{" "}
              <span className="font-semibold text-primary">
                {code_for == "Email" ? (
                  `${hiddenMobileNumberDigits(sent_to)}`
                ) : (
                  <p className="font-semibold">{hiddenMobileNumberDigit}</p>
                )}
              </span>
            </p>
          ) : (
            <div className="text-sm">
              <p className="text-[#7B7B7B]">
                Please verify your email by entering the 4-digit code sent to{" "}
              </p>
              <p className="font-semibold text-left md:text-center"><span className="text-[#7B7B7B] text-sm">sent to</span> {hiddenMobileNumberDigit}</p>
            </div>
          )}

          <div className="flex items-center gap-10 md:gap-8 mt-0 lg:mt-6">
            <MobileVerifyInput
              // disabled={num1 && num2 && num3 && num4}
              name={"num1"}
              value={num1}
              inp_ref={inp_1}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            />
            <MobileVerifyInput
              // disabled={num1 && num2 && num3 && num4}
              name={"num2"}
              value={num2}
              inp_ref={inp_2}
              onBackSpace={() => {
                if (num2 == "" && inp_1 && inp_1.current) {
                  inp_1.current.focus();
                }
              }}
              onChange={(e) => {
                if (e.target.value == "") {
                  if (inp_1 && inp_1.current) {
                    inp_1.current.focus();
                  }
                }
                onChangeHandler(e);
              }}
            />
            <MobileVerifyInput
              // disabled={num1 && num2 && num3 && num4}
              name={"num3"}
              value={num3}
              inp_ref={inp_3}
              onBackSpace={() => {
                if (num3 == "" && inp_2 && inp_2.current) {
                  inp_2.current.focus();
                }
              }}
              onChange={(e) => {
                if (e.target.value == "") {
                  if (inp_2 && inp_2.current) {
                    inp_2.current.focus();
                  }
                }
                onChangeHandler(e);
              }}
            />
            <MobileVerifyInput
              // disabled={num1 && num2 && num3 && num4}
              name={"num4"}
              value={num4}
              inp_ref={inp_4}
              onBackSpace={() => {
                if (num4 == "" && inp_3 && inp_3.current) {
                  inp_3.current.focus();
                }
              }}
              onChange={(e) => {
                if (e.target.value == "") {
                  if (inp_3 && inp_3.current) {
                    inp_3.current.focus();
                  }
                }
                onChangeHandler(e);
              }}
            />
          </div>
          <div>
            <p className="text-[#7B7B7B] text-sm">
              {count > 0 && !isFinished ? (
                <span className="font-semibold text-black"> {count}s </span>
              ) : (
                <div className="flex items-center gap-2 justify-center">
                  <p className="text-[#7B7B7B] text-sm">Didn’t receive the code?</p>
                  <LoginBtn
                    disabled={(num1 && num2 && num3 && num4)
                      || loading}
                    text="Click to Resend"
                    loading={loading}
                    onClick={resendVerificationCodeToMobileOrEmail}
                    customThemeBtn
                    className={"bg-[transparent!important] border-[none!important] custom-btn shadow-[0!important] text-[#5679FF!important]"}
                  />
                </div>
              )}
            </p>
          </div>
        </div>
      </div>
    )
  );
};

MobileVerify.defaultProps = {
  onVerifyOTP: () => { },
  code_for: "Email",
  setStep: () => { },
};

export default MobileVerify;
