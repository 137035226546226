import React from "react";
import { useDispatch } from "react-redux";

import LoginCardBlock from "../../../TenantApp/Elements/LoginCardBlock";
import firebase, { GoogleAuthprovider } from "../../../../firebaseConf";
import LoginSocial from "Components/TenantApp/Elements/LoginSocial";
import Svgs from "Assets/svgs";

const LoginWithGoogle = ({ onVerify, login }) => {
  const dispatch = useDispatch();

  const handleGoogleLogin = () => {
    dispatch({ type: "LOADER", payload: true });
    firebase
      .auth()
      .signInWithPopup(GoogleAuthprovider)
      .then(function (result) {
        let info = result.additionalUserInfo;
        let user = info.profile;

        let user_data = {
          first_name: user.given_name,
          last_name: user.family_name,
          email: user.email,
          social_platform: "Google",
          social_id: user.id,
          image: user.picture,
          is_email_verified: user.verified_email,
          username: user.name,
          is_new_user: info.isNewUser,
        };
        dispatch({ type: "LOADER", payload: false });
        onVerify(user_data);
      })
      .catch(function (error) {
        dispatch({ type: "LOADER", payload: false });
      });
  };

  return (
    <>
      {/*  GoogleLogin implemented just commented  due to absence of clientId */}
      <div className="relative">
        <LoginSocial
          login={login}
          width={login}
          onClick={() => {
            handleGoogleLogin();
          }}
          // heading={login ? <span className="whitespace-nowrap">Google Login &nbsp;&nbsp;&nbsp;</span> : <span>Signup with Google&nbsp;&nbsp;&nbsp;&nbsp;</span>}
          desc={false}
          className={"bg-[#F9F9F9!important] p-1 border border-[#E8E8E8] h-[auto!important] w-[auto!important]"}
        >
          <Svgs.Google />
        </LoginSocial>
      </div>
    </>
  );
};

LoginWithGoogle.defaultProps = {
  onVerify: () => { },
};

export default LoginWithGoogle;
