import React from 'react'
import LabelAvatar from './LabelAvatar'

const ProfileStack = ({ data, type ,textFontSize}) => {
    return (
        <div className='flex items-center relative'>
            {
                data?.slice(0, 3).map((item, i) => {
                    return (
                        <div className={`sm:h-[34px] h-[24px] w-[24px] sm:w-[34px] rounded-full overflow-hidden border-2 border-[white] relative`}
                            style={{
                                left: `${i == 0 ? '' : -(0.4 * i) + 'rem'}`
                            }}>
                            {type == "refund" ?
                                item?.employee_data?.image
                                    ? <img src={item?.employee_data?.image} className="w-full h-full object-cover" alt="img" />
                                    : <LabelAvatar index={i} iconLabel={item?.employee_data?.full_name} fontSize={"!text-sm"} />
                                : item?.image
                                    ? <img src={item.image} className="w-full h-full object-cover" alt="img" />
                                    : <LabelAvatar index={i} iconLabel={item?.full_name} fontSize={textFontSize ? textFontSize : "!text-xl"} />

                            }
                        </div>)
                })
            }
            {
                data?.length > 3 &&
                <p className='relative text-sm text-[#5679FF] bg-[rgba(86,121,255,0.2)] rounded-full sm:h-[34px] h-[24px] w-[24px] sm:w-[34px] flex justify-center items-center'
                    style={{ left: '-0.6rem' }}>
                    +{data?.length - 3}
                </p>
            }
        </div>
    )
}
export default ProfileStack