import { useSelector } from "react-redux"
import Svgs from "../../Assets/svgs"
import { useNavigate } from "react-router-dom"

const NoEmployeeFound = ({ className }) => {
    const navigate = useNavigate()
    const state = useSelector((state) => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>
            <div className={`flex items-center justify-center my-5 ${className}`}>
                <div className="flex flex-col gap-1 justify-center text-center">
                    <div className="w-fit mx-auto">
                        <Svgs.NoEmployeeFound />
                    </div>
                    <h1 className="text-primary font-semibold text-lg normal-case">
                        {selectedLanguage?.name == "English" ? "No employees found" : translation[1258]?.value ? translation[1258]?.value : "No employees found"}
                    </h1>
                    <p className="text-sm text-[#555555]">
                        {selectedLanguage?.name == "English" ? "Please click the" : translation[1270]?.value ? translation[1270]?.value : "Please click the"}
                        {" "}
                        <span
                            onClick={() => {
                                navigate('/dashboard/employee/employees/add-employee')
                            }}
                            className="underline text-[#5679FF] cursor-pointer"
                        >
                            {selectedLanguage?.name == "English" ? "New Employee" : translation[424]?.value ? translation[424]?.value : "New Employee"}
                        </span>{" "}
                        {selectedLanguage?.name == "English" ? "button to add one." : translation[1271]?.value ? translation[1271]?.value : "button to add one."}
                    </p>
                </div>
            </div>
        </>
    )
}

NoEmployeeFound.defaultProps = {
    className: ''
}

export default NoEmployeeFound