import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

function useProfile() {
    const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
    const location = window?.location;
    const path = location?.pathname;
    const search_params = location?.search;
    let next = `${path}${search_params}`
    const token = getDataFromLocalStorage("client_token")
    const hash = getDataFromLocalStorage("hash")
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const business = searchParams.get("business")
    const [showSignin, setShowSignin] = useState(false)
    // const hash = searchParams.get("hash")
    const [visitedLocation, setVisitedLocation] = useState();
    const [Selected, setSelected] = useState();
    const navigate = useNavigate();
    const state = useSelector((state) => state);
    const groupList = state?.utility?.saleService?.servicesgroup;
    const selectedProfileService = state?.utility?.saleService?.profileSelectedService;
    const locationList = state?.utility?.locations.locations;
    const [selectedService, setSelectedService] = useState();
    let loc_services = [];

    //return all service against the selected location on dashboard or calender
    const serviceOfSelectedLocation = useCallback(() => {
        if (params?.id) {
            storeDataInLocalStorage("business_location", params?.id);
        }
        next && storeDataInLocalStorage("next", next);

        let result = groupList?.filter((grp) => grp?.status === true).map((itm) => {
            let grp_services = itm?.services?.filter((srv) => {
                let loc_service = srv?.location?.find((loc) => loc == params?.id);
                if (loc_service) {
                    return true;
                } else {
                    return false;
                }
            });
            if (grp_services?.length > 0) {
                return { ...itm, services: grp_services };
            } else {
                return undefined;
            }
        });

        result = result?.filter((itm) => itm);

        return result;
    }, [params?.id, visitedLocation])



    useEffect(() => {
        if (!params?.id) {
            navigate(-1)
        }
        const matchLocation = locationList?.find((itm) => itm?.id === params?.id);
        setVisitedLocation({ ...matchLocation, business, business_address: params?.id, hash, token, next });
        setSelectedService(selectedProfileService?.id)
        setSelected(selectedProfileService?.group);
        next = getDataFromLocalStorage("next", next)
    }, [locationList]);


    //filter all service that belong to current locations
    serviceOfSelectedLocation()?.map((itm) => {
        const result = itm?.services?.filter((dat) => {
            const res = dat?.priceservice?.filter((dt) => dt?.currency == visitedLocation?.currency?.id);
            loc_services.push({ ...dat, priceservice: res, group_id: itm?.id })
            if (res) {
                return true
            } else {
                return false
            }
        })
    })



    return {
        visitedLocation,
        locationList,
        serviceOfSelectedLocation,
        selectedService, setSelectedService,
        selectedProfileService, token,
        Selected, setSelected, next, loc_services, showSignin
    }
}

export default useProfile
