import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom';

import Svgs from 'Assets/svgs'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import SelectedOption from 'Components/TenantApp/Elements/SelectedOption';
import ToggleWithText from 'Components/TenantApp/Elements/ToggleWithText';
import { useAddDiscount } from './helper';
import CategoryDiscount from './CategoryDiscount';
import ToggleRestrictions from './ToggleRestrictions';
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb';
import moment from 'moment';

const AddDiscount = () => {
    const {
        errors, categoryDiscountDropdown, toggleDay, toggleStore, discount, loading, locationDropdown, isEdit, dateRangeCheck, validateBlockDate,
        handleChange, onBlurHandler, navigate, setDiscount, removeCategoryDiscountField, setErrors, addStoreRestriction, onSubmit,
        addBlockDates, setToggleDay, setToggleStore, setToggleBlockDate, toggleblockdate, translation, selectedLanguage, formErrors,
        setFormErrors, formRef,
        addServiceRestriction,
        addRetailRestriction,
        addVoucherRestriction,

        vouchersDropdown,
        retailDropdown,
        serviceDropdown,

        serviceToggle, setServiceToggle,
        retailToggle, setRetailToggle,
        voucherToggle, setVoucherToggle,

    } = useAddDiscount();
    const {
        categoryDiscount,
        dateRestriction,
        blockDate,
        blockDates,
        storeRestriction,
        promotion_name,
        storeRestrictions,
        dayRestrictions,
        serviceRestriction,
        voucherRestriction,
        retailRestriction,

        serviceRestrictions,
        retailRestrictions,
        voucherRestrictions,

    } = discount;

    var minEndDateLimit = useMemo(() => {
        return moment(dateRestriction.startDate).add(1, 'day').format('YYYY-MM-DD')
    }, [dateRestriction.startDate]);

    return (
        <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right' >

            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
                url={'/dashboard/clients/'}
                second={selectedLanguage?.name == "English" ? "Discount & Promotions" : translation[638]?.value ? translation[638]?.value : "Discount & Promotions"}
                secondurl={'/dashboard/clients/discounts-promotions'}
                third={selectedLanguage?.name == "English" ? "Create Flat Discount" : translation[880]?.value ? translation[880]?.value : "Create Flat Discount"}
            />

            <div className='flex items-center sm:gap-4 gap-2 my-[1rem]'>

                <div className='flex items-center gap-4 justify-between w-full'>
                    <div className='flex items-center gap-3'>
                        <Svgs.Back link='/dashboard/clients/discounts-promotions' />
                        <h2 className='text-primary font-bold lg:text-3xl text-xl cursor-pointer'>
                            {selectedLanguage?.name == "English" ? "Create Direct or Flat Discount" : translation[881]?.value ? translation[881]?.value : "Create Direct or Flat Discount"}
                        </h2>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 md:w-[40vw] ' ref={formRef}>

                <div className="border rounded-lg p-3 flex flex-col gap-3 relative">
                    <LoginInput
                        name="promotion_name"
                        className='!w-fit'
                        value={promotion_name}
                        title={selectedLanguage?.name == "English" ? "Promotion Name" : translation[883]?.value ? translation[883]?.value : "Promotion Name"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Promotion Name" : translation[1732]?.value ? translation[1732]?.value : "Enter Promotion Name"}
                        type='text'
                        // charactersMaxLength={25}
                        onChange={handleChange}
                        error={errors.promotion_name}
                    />
                    <CategoryDiscount
                        categoryDiscount={categoryDiscount}
                        removeCategoryDiscountField={removeCategoryDiscountField}
                        categoryDiscountDropdown={categoryDiscountDropdown}
                        setDiscount={setDiscount}
                        onBlurHandler={onBlurHandler}
                        errors={errors}
                        setErrors={setErrors}
                        selectedLanguage={selectedLanguage}
                        translation={translation}
                        formErrors={formErrors}
                        setFormErrors={setFormErrors}
                        formRef={formRef}
                    />
                </div>
                <div className='border rounded-lg p-3 flex flex-col gap-7'>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>
                            {selectedLanguage?.name == "English" ? "Date Restrictions" : translation[899]?.value ? translation[899]?.value : "Date Restrictions"}
                        </h1>
                        <div className='grid grid-cols-2 gap-3'>
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                name='startDate'
                                value={dateRestriction.startDate}
                                type='date'
                                onChange={handleChange}
                                onBlur={() => onBlurHandler({ startDate: dateRestriction.startDate })}
                                error={errors.startDate}
                            />
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                                name='endDate'
                                value={dateRestriction.endDate}
                                type='date'
                                onChange={handleChange}
                                min={minEndDateLimit}
                                onBlur={() => onBlurHandler({ endDate: dateRestriction.endDate })}
                                error={errors.endDate}
                            />
                        </div>
                    </div>

                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>
                            {selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
                        </h1>
                        <div className='flex items-end gap-3'>
                            <Dropwdown
                                required={true}
                                name='storeRestriction'
                                placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                                title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                                parentClass='flex-1'
                                options={locationDropdown?.filter(obj => obj.value !== discount?.storeRestrictions?.find(location => location === obj.value))}
                                value={storeRestriction}
                                onChange={handleChange}
                                error={errors?.storeRestriction}
                            />
                            <LoginBtn
                                className={storeRestriction && "shake-bottom"}
                                text={<div className='flex items-center gap-2' > <Svgs.Plus /><p>
                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                </p></div>}
                                onClick={addStoreRestriction}
                                disabled={!storeRestriction}
                            />
                        </div>
                    </div>
                    <div className='flex items-center gap-2 flex-wrap'>
                        {
                            storeRestrictions?.map((obj, index) => (
                                <SelectedOption
                                    rounded={'full'}
                                    name={locationDropdown?.find(location => location?.value === obj)?.label}
                                    onClick={() => {
                                        var filter = storeRestrictions?.filter(obj => obj !== storeRestrictions[index])
                                        setDiscount((prev) => ({
                                            ...prev,
                                            storeRestrictions: filter,
                                        }))
                                    }}
                                />
                            ))
                        }
                    </div>
                    {/* <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={'!text-lg'}
                            title={selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
                            value={toggleStore}
                            onChange={() => setToggleStore(!toggleStore)}
                            checked={toggleStore}
                        />
                        {toggleStore &&
                            <>
                                <div className='flex items-end gap-3'>
                                    <Dropwdown
                                        name='storeRestriction'
                                        placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                                        title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                                        parentClass='flex-1'
                                        options={locationDropdown?.filter(obj => obj.value !== discount?.storeRestrictions?.find(location => location === obj.value))}
                                        value={storeRestriction}
                                        onChange={handleChange}
                                    />
                                    <LoginBtn
                                        className={storeRestriction && "shake-bottom"}
                                        text={<div className='flex items-center gap-2' > <Svgs.Plus /><p>
                                            {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                        </p></div>}
                                        onClick={addStoreRestriction}
                                        disabled={!storeRestriction}
                                    />
                                </div>
                                <div className='flex items-center gap-2 flex-wrap'>
                                    {
                                        storeRestrictions?.map((obj, index) => (
                                            <SelectedOption
                                                rounded={'full'}
                                                name={locationDropdown?.find(location => location?.value === obj)?.label}
                                                onClick={() => {
                                                    var filter = storeRestrictions?.filter(obj => obj !== storeRestrictions[index])
                                                    setDiscount((prev) => ({
                                                        ...prev,
                                                        storeRestrictions: filter,
                                                    }))
                                                }}
                                            />
                                        ))
                                    }
                                </div>
                            </>
                        }


                    </div> */}

                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={'!text-lg'}
                            title={selectedLanguage?.name == "English" ? "Add Block Date" : translation[902]?.value ? translation[902]?.value : "Add Block Date"}
                            value={toggleblockdate}
                            onChange={() => setToggleBlockDate(!toggleblockdate)}
                            checked={toggleblockdate}
                        />
                        {toggleblockdate &&
                            <>
                                <div className='flex items-end gap-3'>
                                    <LoginInput
                                        name='blockDate'
                                        title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                        type='date'
                                        parentClass='flex-1'
                                        required={false}
                                        min={dateRestriction.startDate}
                                        max={dateRestriction.endDate}
                                        value={blockDate}
                                        onChange={handleChange}
                                        // onBlur={()=>onBlurHandler({blockDate: blockDate})}
                                        error={errors.blockDate}
                                        disabled={
                                            !discount.dateRestriction.startDate ||
                                            !discount.dateRestriction.endDate
                                        }
                                    />
                                    <LoginBtn
                                        text={<div className='flex items-center gap-2'
                                        >
                                            <Svgs.Plus />
                                            <p>
                                                {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                            </p>
                                        </div>
                                        }
                                        className={blockDate && "shake-bottom"}
                                        onClick={addBlockDates}
                                        disabled={
                                            !dateRangeCheck ? true : false ||
                                                !discount.dateRestriction.startDate ||
                                                !discount.dateRestriction.endDate ||
                                                !blockDate ||
                                                validateBlockDate ? true : false
                                        }
                                    />
                                </div>
                                <div className='flex items-center gap-2 flex-wrap'>
                                    {
                                        blockDates?.map((obj, index) => (
                                            <>
                                                {
                                                    obj?.is_deleted === 'False' &&
                                                    <SelectedOption rounded={'full'} name={obj?.date} onClick={() => {
                                                        if (obj.id) {
                                                            var filter = blockDates?.map((obj) => {
                                                                if (obj === blockDates[index]) {
                                                                    return {
                                                                        ...obj,
                                                                        is_deleted: 'True'
                                                                    }
                                                                }
                                                                else {
                                                                    return { ...obj }
                                                                }
                                                            })
                                                            setDiscount((prev) => ({
                                                                ...prev,
                                                                blockDates: filter,
                                                            }))
                                                        }
                                                        else {
                                                            var filter = blockDates?.filter((obj) => obj != blockDates[index])
                                                            setDiscount((prev) => ({
                                                                ...prev,
                                                                blockDates: filter,
                                                            }))
                                                        }
                                                    }} />
                                                }

                                            </>
                                        ))
                                    }
                                </div>
                            </>
                        }
                    </div>
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={'!text-lg'}
                            title={selectedLanguage?.name == "English" ? "Day Restrictions" : translation[906]?.value ? translation[906]?.value : "Day Restrictions"}
                            value={toggleDay}
                            onChange={() => setToggleDay(!toggleDay)}
                            checked={toggleDay}
                        />
                        {
                            toggleDay &&
                            <div className='grid grid-cols-2 gap-4'>
                                {
                                    dayRestrictions.map(ele => {
                                        return <CheckBox
                                            name={ele.day}
                                            label={ele.day}
                                            value={ele.value}
                                            onChange={(e) => setDiscount((prev) => ({
                                                ...prev,
                                                dayRestrictions: discount?.dayRestrictions?.map(obj => {
                                                    // handle checklist withoutd ids 
                                                    // check function
                                                    if (obj.day === e.target.name && obj.value === '' && obj.is_deleted === 'False') {
                                                        return {
                                                            ...obj,
                                                            value: e.target.name
                                                        }
                                                    }
                                                    // UN Check block
                                                    if (obj.value === e.target.value && !obj.id) {
                                                        return {
                                                            ...obj,
                                                            value: ''
                                                        }
                                                    }

                                                    // handling update scenarios for checklist
                                                    // un check block for checked
                                                    if (obj.id && obj.value === e.target.value && obj.is_deleted === 'False') {
                                                        return {
                                                            ...obj,
                                                            is_deleted: 'True',
                                                            value: ''
                                                        }
                                                    }

                                                    // checked block and isDeleted 
                                                    if (obj.day === e.target.name && obj.value === '' && obj.is_deleted && obj.id) {
                                                        return {
                                                            ...obj,
                                                            value: e.target.name,
                                                            is_deleted: 'False'
                                                        }
                                                    }
                                                    else {
                                                        return obj
                                                    }
                                                })
                                            }))}
                                            checked={ele.value === ele.day ? true : false}
                                        />
                                    })
                                }
                            </div>
                        }

                    </div>
                    <div className='flex flex-col gap-7'>
                        {categoryDiscount?.map((obj, index) => {
                            if (obj?.category != "All")
                                return (
                                    <>
                                        {/* {obj?.category == "Voucher" &&
                                            <>
                                                <ToggleWithText
                                                    textClass={'!text-lg'}
                                                    title={selectedLanguage?.name == "English" ? "Excluded Vouchers" : translation[907]?.value ? translation[907]?.value : "Excluded Vouchers"}
                                                    value={voucherToggle}
                                                    onChange={() => setVoucherToggle(!voucherToggle)}
                                                    checked={voucherToggle}
                                                />
                                                <ToggleRestrictions
                                                    setDiscount={setDiscount}
                                                    handleChange={handleChange}
                                                    addRestrictions={addVoucherRestriction}
                                                    toggleRestrictions={voucherToggle}
                                                    restrictions={voucherRestrictions}
                                                    restriction={voucherRestriction}
                                                    type={"voucherRestriction"}
                                                    title={selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"}
                                                    translation={translation}
                                                    selectedLanguage={selectedLanguage}
                                                    dropwdown={vouchersDropdown.filter(itm => itm.value !== discount?.voucherRestrictions?.find(ele => ele?.id === itm.value))}
                                                />
                                            </>
                                        } */}

                                        {obj?.category == "Retail" &&
                                            <>
                                                <ToggleWithText
                                                    textClass={'!text-lg'}
                                                    title={selectedLanguage?.name == "English" ? "Excluded Retails" : translation[908]?.value ? translation[908]?.value : "Excluded Retails"}
                                                    value={retailToggle}
                                                    onChange={() => setRetailToggle(!retailToggle)}
                                                    checked={retailToggle}
                                                />
                                                <ToggleRestrictions
                                                    setDiscount={setDiscount}
                                                    handleChange={handleChange}
                                                    addRestrictions={addRetailRestriction}
                                                    toggleRestrictions={retailToggle}
                                                    restrictions={retailRestrictions}
                                                    restriction={retailRestriction}
                                                    type={"retailRestriction"}
                                                    translation={translation}
                                                    selectedLanguage={selectedLanguage}
                                                    title={selectedLanguage?.name == "English" ? "Retail" : translation[604]?.value ? translation[604]?.value : "Retail"}
                                                    dropwdown={retailDropdown.filter(itm => itm.value !== discount?.retailRestrictions?.find(ele => ele?.id === itm.value))}

                                                // dropwdown={retailDropdown}
                                                />
                                            </>
                                        }
                                        {obj?.category == "Service" &&
                                            <>
                                                <ToggleWithText
                                                    textClass={'!text-lg'}
                                                    title={selectedLanguage?.name == "English" ? "Excluded Services" : translation[909]?.value ? translation[909]?.value : "Excluded Services"}
                                                    value={serviceToggle}
                                                    onChange={() => setServiceToggle(!serviceToggle)}
                                                    checked={serviceToggle}
                                                />
                                                <ToggleRestrictions
                                                    setDiscount={setDiscount}
                                                    handleChange={handleChange}
                                                    addRestrictions={addServiceRestriction}
                                                    toggleRestrictions={serviceToggle}
                                                    restrictions={serviceRestrictions}
                                                    restriction={serviceRestriction}
                                                    translation={translation}
                                                    selectedLanguage={selectedLanguage}
                                                    type={"serviceRestriction"}
                                                    title={selectedLanguage?.name == "English" ? "services that you want to exclude from this offer" : translation[911]?.value ? translation[911]?.value : "services that you want to exclude from this offer"}
                                                    // dropwdown={serviceDropdown}
                                                    dropwdown={serviceDropdown.filter(itm => itm.value !== discount?.serviceRestrictions?.find(ele => ele?.id === itm.value))}

                                                />
                                            </>
                                        }
                                    </>
                                )
                            else
                                return (
                                    <>

                                        <ToggleWithText
                                            textClass={'!text-lg'}
                                            title={selectedLanguage?.name == "English" ? "Excluded Services" : translation[909]?.value ? translation[909]?.value : "Excluded Services"}
                                            value={serviceToggle}
                                            onChange={() => setServiceToggle(!serviceToggle)}
                                            checked={serviceToggle}
                                        />
                                        <ToggleRestrictions
                                            setDiscount={setDiscount}
                                            handleChange={handleChange}
                                            addRestrictions={addServiceRestriction}
                                            toggleRestrictions={serviceToggle}
                                            restrictions={serviceRestrictions}
                                            restriction={serviceRestriction}
                                            translation={translation}
                                            selectedLanguage={selectedLanguage}
                                            type={"serviceRestriction"}
                                            title={selectedLanguage?.name == "English" ? "services that you want to exclude from this offer" : translation[911]?.value ? translation[911]?.value : "services that you want to exclude from this offer"}
                                            dropwdown={serviceDropdown.filter(itm => itm.value !== discount?.serviceRestrictions?.find(ele => ele?.id === itm.value))}
                                        />


                                        {/* <ToggleWithText
                                            textClass={'!text-lg'}
                                            title={selectedLanguage?.name == "English" ? "Excluded Vouchers" : translation[907]?.value ? translation[907]?.value : "Excluded Vouchers"}
                                            value={voucherToggle}
                                            onChange={() => setVoucherToggle(!voucherToggle)}
                                            checked={voucherToggle}
                                        />
                                        <ToggleRestrictions
                                            setDiscount={setDiscount}
                                            handleChange={handleChange}
                                            addRestrictions={addVoucherRestriction}
                                            toggleRestrictions={voucherToggle}
                                            restrictions={voucherRestrictions}
                                            restriction={voucherRestriction}
                                            translation={translation}
                                            selectedLanguage={selectedLanguage}
                                            type={"voucherRestriction"}
                                            title={selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"}
                                            dropwdown={vouchersDropdown.filter(itm => itm.value !== discount?.voucherRestrictions?.find(ele => ele?.id === itm.value))}
                                        /> */}


                                        <ToggleWithText
                                            textClass={'!text-lg'}
                                            title={selectedLanguage?.name == "English" ? "Excluded Retails" : translation[908]?.value ? translation[908]?.value : "Excluded Retails"}
                                            value={retailToggle}
                                            onChange={() => setRetailToggle(!retailToggle)}
                                            checked={retailToggle}
                                        />
                                        <ToggleRestrictions
                                            setDiscount={setDiscount}
                                            handleChange={handleChange}
                                            addRestrictions={addRetailRestriction}
                                            toggleRestrictions={retailToggle}
                                            restrictions={retailRestrictions}
                                            restriction={retailRestriction}
                                            translation={translation}
                                            selectedLanguage={selectedLanguage}
                                            type={"retailRestriction"}
                                            title={selectedLanguage?.name == "English" ? "Retail" : translation[604]?.value ? translation[604]?.value : "Retail"}
                                            dropwdown={retailDropdown.filter(itm => itm.value !== discount?.retailRestrictions?.find(ele => ele?.id === itm.value))}
                                        />

                                    </>
                                )
                        })}

                    </div>
                </div>
                <LoginBtn
                    text={isEdit ?
                        selectedLanguage?.name == "English" ? "Update" : translation[845]?.value ? translation[845]?.value : "Update"
                        :
                        selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
                    loading={loading}
                    disabled={loading}
                    // disabled={
                    //     loading ||
                    //     discount.categoryDiscount.length === 0 ||
                    //     discount?.categoryDiscount?.find(
                    //         (obj) =>
                    //             !obj?.category ||
                    //             !obj?.discount
                    //     ) ||
                    //     !discount.promotion_name ||
                    //     !discount.dateRestriction.endDate ||
                    //     !discount.dateRestriction.startDate
                    //     || errors.endDate
                    //     // discount.blockDates.length === 0
                    // }
                    onClick={onSubmit}
                />
            </div>
        </div>
    )
}

export default AddDiscount