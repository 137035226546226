import { GIFT_CARDS } from "Redux/TenantRedux/ActionTypes/giftCards/giftCards";

const initialState = {
  giftCards: [],
  giftCards_updated: false,
  page_count: 0,
  allCount: 0
};

const GiftCardReducer = (state = initialState, action) => {

  const { type, payload } = action;
  switch (type) {

    case GIFT_CARDS.GET_GIFT_CARDS:
      return {
        ...state,
        giftCards: payload?.response?.results,
        giftCards_updated: true,
        page_count: payload?.pages,
        allCount: payload?.count
      };

    default:
      return state;
  }
};

export default GiftCardReducer;