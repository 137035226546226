import React from 'react'

const ColorPalette = (props) => {
    return (
        <div className='flex items-center gap-2'>
            {
                props.colors.map(color => {
                    return <div className='h-[2.5rem] w-[2.5rem] rounded-full' style={{ backgroundColor: color }}></div>
                })
            }
        </div>
    )
}

ColorPalette.defaultProps = {
    colors: ['red', 'green', 'blue']
}

export default ColorPalette