import { CONSUMPTION } from "../../ActionTypes/consumptionTypes";

const inventoryConsumption = {
  consumptions: [],
  allConsumptions: [],
  consumptions_updated: false,
  pages: 0,
  allCount: 0
};

const ConsumptionReducer = (state = inventoryConsumption, action) => {
  const { type, payload } = action;

  switch (type) {
    case CONSUMPTION.GET_PRODUCT_CONSUMPTIONS:
      return {
        ...state,
        consumptions: payload?.response?.product_consumptions,
        pages: payload?.pages,
        allCount: payload?.count,
        consumptions_updated: true
      };

    case CONSUMPTION.GET_PRODUCT_CONSUMPTIONS_WITH_NO_PAGINATION:
      return {
        ...state,
        allConsumptions: payload,
      };

    case CONSUMPTION.ADD_PRODUCT_CONSUMPTION:
      return {
        ...state,
        consumptions: [payload, ...state.consumptions],
      };

    case CONSUMPTION.UPDATE_PRODUCT_CONSUMPTION:
      let updateList = state?.consumptions?.map((item) => {
        if (item.id == payload.id) {
          return payload;
        } else {
          return item;
        }
      });
      return {
        ...state,
        consumptions: updateList,
      };
    case CONSUMPTION.DELETE_PRODUCT_CONSUMPTION:
      let filterArrayAfterDeletion = state?.consumptions?.filter(
        (consumption) => {
          if (consumption?.id !== payload) {
            return consumption;
          }
        }
      );
      return {
        ...state,
        consumptions: filterArrayAfterDeletion,
      };
    default:
      return state;
  }
};

export default ConsumptionReducer;
