import React, { useState } from 'react'
import Svgs from '../../../../../Assets/svgs';
import Dropwdown from '../../../Elements/Dropwdown'
import LoginBtn from '../../../Elements/LoginBtn';
import LoginInput from '../../../Elements/LoginInput'
import StatusBtn from '../../../Elements/StatusBtn';
import Popup from '../../../Elements/Popup';
import CircleIcon from '../../../Elements/CircleIcon';
import useValidations from '../../../../../Hooks/useValidations';
import useObjectKeys from '../../../../../Hooks/useObjectKeys';
import useSetDataInLocalStorage from './../../../../../Hooks/useSetDataInLocalStorage';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAsset, deleteAsset, getAllAssests, getEmployeeList, updateAsset } from '../../../../../Adapters/Api/employee';
import { toast } from 'react-toastify';
import NotFound from '../../Sales/Elements/NotFound';
import { CSVLink } from 'react-csv';
import DateInput from '../../../Elements/DateInput';
import PreloaderFull from './../../../Elements/PreloaderFull';

const StaffAssetes = () => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state)
    const employeeList = state?.employee?.employees;
    const AssestList = state?.employee?.assets;
    const assets_updated = state?.employee?.assets_updated;

    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys();
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()

    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");

    const [ShowDetails, setShowDetails] = useState(0);
    const [loading, setLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [Assets, setAssets] = useState(false);
    const [assetData, setAssetData] = useState({
        name: "",
        staff_id: "",
        given_date: "",
        return_date: "",
        document: "",
        is_active: true
    });

    const { name, staff_id, given_date, return_date, is_active, document } = assetData;
    const [Filter, setFilter] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [employeeText, setemployeeText] = useState("");
    const [isActive, setIsActive] = useState(true);
    const [selectedFrom, setFrom] = useState("")
    const [selectedTo, setTo] = useState("")
    const [image, setImage] = useState(undefined)
    const [filterState, setFilterState] = useState({
        employee_name: "",
        status: "",
        from: "",
        to: ""
    })
    const { employee_name, status, from, to } = filterState;

    // on change handler 
    const assetHandleChange = (e) => {
        const { name, value } = e.target;
        if (name === "document") {
            setImage(URL.createObjectURL(e.target.files[0]));
            setAssetData((prev) => ({
                ...prev, document: e.target.files[0]
            }))
            setErrors((prev) => ({
                ...prev, document: ""
            }))
        } else {
            setAssetData((prev) => ({ ...prev, [name]: value }))
            setErrors((prev) => ({ ...prev, [name]: "" }))
        }
    }

    //removed selected file
    const removedImage = () => {
        setImage(undefined);
        setAssetData((prev) => ({
            ...prev, document: ""
        }))
    }


    //add asset and update assets
    const assetSubmitHandler = () => {

        const requiredFields = { name, staff_id, given_date }
        setErrors(validation(requiredFields))
        const result = validation(requiredFields)

        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoading(true)

            if (isEdit) {
                const form_data = new FormData()
                for (let key in assetData) {
                    if (assetData[key]) {
                        if (key === "staff_id") {
                            form_data.append("staff_id ", assetData[key])
                        } else {
                            if (key == "document" && typeof assetData["document"] === "string") {
                                continue;
                            } else {
                                form_data.append(key, assetData[key])
                            }
                        }
                    }
                }

                dispatch(updateAsset(
                    form_data,
                    () => {
                        toast.success("Assets updated successfully!", { toastId: "toast" })
                        setAssetData({ is_active: true })
                        toggleModal();
                        setLoading(false)
                    }),
                    () => {
                        toast.error("Assets did not updated!", { toastId: "toast" })
                        setLoading(false)
                    }
                )
            } else {

                const form_data = new FormData()
                for (let key in assetData) {
                    if (assetData[key]) {
                        if (key === "staff_id") {
                            form_data.append("employee", assetData[key])
                        } else {
                            form_data.append(key, assetData[key])
                        }
                    }
                }
                form_data.append("business", businessData.id)

                dispatch(createAsset(
                    form_data,
                    access_token,
                    () => {
                        toast.success("Assets created successfully!")
                        setAssetData({ is_active: true })
                        toggleModal();
                        setLoading(false)
                    }),
                    () => {
                        toast.error("Assets did not created!")
                        setLoading(false)
                    }
                )

            }

        }
    }

    const toggleModal = () => {
        setAssets(!Assets)
    }

    //delete asset
    const deleteStaffAsset = (id) => {


        if (!deleteId && !isDelete) {
            setIsDelete(true)
            setDeleteId(id)

        } else {
            dispatch(deleteAsset(
                deleteId,
                access_token,
                () => {
                    toast.success("Asset deleted successfully!")
                    setIsDelete(false)
                    setDeleteId()
                },
                () => {
                    toast.success("Asset did not deleted!")
                }
            ))

        }
    }


    useEffect(() => {
        if (AssestList?.length === 0) {

            dispatch(getAllAssests(access_token))
        }

        if (state?.employee?.employees?.length === 0) {
            dispatch(getEmployeeList())
        }
    }, [])



    let employeeAssetHeader = [
        { label: 'Asset Name', key: 'name' },
        { label: 'Staff Name', key: 'staff_id' },
        { label: 'Document', key: 'document' },
        { label: 'Given Date', key: 'given_date' },
        { label: 'Returned Date', key: 'return_date' },
        { label: 'Status', key: 'status' },
    ]


    const exportAssetData = {
        filename: "assetData.csv",
        headers: employeeAssetHeader,
        data: AssestList?.length > 0 ? AssestList?.map((item) => {
            return {
                name: item?.name ? item?.name : "----",
                staff_id: item?.employee?.full_name ? item?.employee?.full_name : "----",
                document: item?.document[0]?.document ? item?.document[0]?.document : "----",
                given_date: item?.given_date ? item?.given_date : "----",
                return_date: item?.return_date ? item?.return_date : "----",
                status: item?.is_active ? "Active" : "Inactive"

            }
        }) : []
    }



    //all filter handle here
    const employee_assets_filter = () => {
        const fromDate = Date.parse(from);
        const toDate = Date.parse(to);

        const asset_data_filter = AssestList?.filter((item) => {
            let createAt = Date.parse(item?.created_at.split("T")[0]);

            if ((searchText || employee_name || status) || (fromDate && toDate)) {

                let return_value = false;

                //check search text
                if (searchText) {
                    if (item.name.toLowerCase().includes(searchText.toLowerCase()) || (item?.employee?.full_name?.toLowerCase()?.includes(searchText?.toLowerCase()))) {
                        return_value = true
                    } else {
                        return false
                    }
                }

                //check dropdown text
                if (employee_name) {
                    if (item?.employee?.full_name?.toLowerCase()?.includes(employee_name?.toLowerCase())) {
                        return_value = true
                    } else {
                        return false
                    }
                }

                //check records in from and to date
                if (fromDate && toDate) {
                    if ((createAt >= fromDate) && (createAt <= toDate)) {
                        return_value = true
                    } else {
                        return false
                    }
                }

                // check ative and inactive records
                if (status && status != '') {

                    if ((item?.is_active) === (status == "active" ? true : false)) {
                        return_value = true
                    } else {
                        return false
                    }
                }

                return return_value;
            }
            return true;
        })

        return asset_data_filter;
    }


    //apply filter here 
    const applyDateFilter = () => {
        setFilterState((prev) => ({
            ...prev,
            employee_name: employeeText,
            status: isActive ? "active" : "inactive",
            from: selectedFrom,
            to: selectedTo,
        }))

        setemployeeText();
        setIsActive(true);
        setFrom();
        setTo();

        setFilter(false)
    }


    const editStaffAsset = (id) => {
        setIsEdit(true)
        setAssets(true)
        const seletectedAssest = AssestList.find((item) => item?.id == id);
        setAssetData((prev) => ({
            ...prev,
            ...seletectedAssest,
            id: id,
            staff_id: seletectedAssest?.employee?.id,
            document: seletectedAssest?.document[0]?.document,
        }))
        setImage(seletectedAssest?.document[0]?.document);
    }


    return (
        (!assets_updated) ? <PreloaderFull /> : <>
            <div className='border rounded-lg'>
                <div className='px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3'>
                    <div className='flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap'>
                        <div className='flex items-center sm:gap-4 gap-2 '>
                            <h2 className='font-semibold text-xl'>Staff Data</h2>
                        </div>
                        <div className='mb-00'>

                            <LoginInput
                                name="searchText"
                                value={searchText}
                                onChange={(e) => { setSearchText(e.target.value) }}
                                title=''
                                placeholder='Search'
                                padding='py-1'
                                leftIcon={<Svgs.Search />}

                            />
                        </div>
                    </div>
                    <div className='flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full'>
                        <LoginBtn onClick={() => { setFilter(!Filter) }} borderBtn >
                            <div className='flex items-center gap-2 text-[#101928]'>
                                <Svgs.Filters />
                                {/* <p>Filters</p> */}
                            </div>
                        </LoginBtn>
                        {/* <LoginBtn className='rounded-lg border border-primary text-sm' >
                            <div className='flex items-center gap-2'>
                                <Svgs.Import fill='#fff' />
                                <p className='font-semibold'>Import List</p>
                            </div>
                        </LoginBtn> */}
                        <CSVLink {...exportAssetData} target="_blank" onClick={() => {
                            setTimeout(() => { toast.success("File downloaded successfully", { toastId: "toast" }) }, 300)
                        }}>
                            <LoginBtn borderBtn >
                                <div className='flex items-center gap-2 text-[#101928]'>
                                    <Svgs.Export bg='#101928' />
                                </div>
                            </LoginBtn>
                        </CSVLink>
                        <LoginBtn onClick={() => {
                            setIsEdit(false)
                            toggleModal()
                        }}>
                            <p>Add Asset</p>
                        </LoginBtn>
                    </div>
                </div>
                <div className='overflow-x-auto'>
                    <div className='inline-grid xl:grid grid-cols-[repeat(7,minmax(155px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold'>
                        <p>Asset Name</p>
                        <p>Staff Name</p>
                        <p>Document</p>
                        <p>Given Date </p>
                        <p>Returned Date</p>
                        <p>Status</p>
                        <p>Actions</p>
                    </div>

                    {AssestList?.length > 0 ?
                        employee_assets_filter()?.map((asset, i) => {
                            return (
                                <div className={`odd-bg text-[#3A3A3A] transition-all ${ShowDetails ? 'border-l-[4px] border-[#5679FFB2]' : 'border-l-[0px]'}`}>
                                    <>
                                        <div className='px-12 py-3 inline-grid xl:grid grid-cols-[repeat(7,minmax(155px,1fr))]'>
                                            <div className={`before-left-arrow cursor-pointer ${ShowDetails == i + 1 ? 'before-left-arrow-open' : 'before-left-arrow-close'}`} onClick={() => { setShowDetails(ShowDetails != i + 1 ? i + 1 : 0) }}>
                                                <div className='h-full flex items-center'>
                                                    <p className='text-sm'>{asset?.name}</p>
                                                </div>
                                            </div>
                                            <div className='flex items-center'>
                                                <p className='text-sm'>{asset?.employee?.full_name}</p>
                                            </div>
                                            <div className='flex items-center'>
                                                {/* <p className='text-sm line-clamp-1 pr-3'>Document</p> */}
                                                <p className='text-sm line-clamp-1 pr-3'>{asset?.document[0]?.document}</p>
                                            </div>
                                            <div className='flex items-center'>
                                                <p className='text-sm line-clamp-1 pr-3'>{asset?.given_date}</p>
                                            </div>
                                            <div className='flex items-center'>
                                                <p className='text-sm line-clamp-1 pr-3'>{asset?.return_date}</p>
                                            </div>
                                            <div className='flex items-center'>
                                                <div className='w-fit'>
                                                    <StatusBtn status={asset?.is_active ? 'active' : "inActive"} />
                                                </div>
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <div className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center'>
                                                    <span onClick={() => { editStaffAsset(asset?.id); console.log("assest ::", asset?.id) }}>    <Svgs.Edit fill='#7D7D7D' /> </span>
                                                </div>
                                                <div className='h-[2.5rem] w-[2.5rem] border rounded-full cursor-pointer flex items-center justify-center'>
                                                    <span onClick={() => deleteStaffAsset(asset?.id)}>  <Svgs.Delete fill='#7D7D7D' /></span>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            ShowDetails == i + 1 ? <div className='scale-in-center'>
                                                <div className='bg-[#FAF9FF] px-6 py-3'>
                                                    <div className='grid grid-cols-8 col-span-3 gap-5'>
                                                        <div className='col-span-2'>
                                                            <div className='flex flex-col gap-4'>
                                                                <div>
                                                                    <h2 className='text-[#949494] text-sm'>Aseet Name </h2>
                                                                    <p className='text-sm'>{asset?.name}</p>
                                                                </div>
                                                                <div>
                                                                    <h2 className='text-[#949494] text-sm'>Returned Date</h2>
                                                                    <p className='text-sm'>{asset?.return_date}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-span-2'>
                                                            <div className='flex flex-col gap-4'>
                                                                <div>
                                                                    <h2 className='text-[#949494] text-sm'>Staff Name</h2>
                                                                    <p className='text-sm'>{asset?.employee?.full_name}</p>
                                                                </div>
                                                                <div className='flex flex-col gap-1'>
                                                                    {/* <h2 className='text-[#949494] text-sm'>Status</h2>
                                                                    <div className='w-fit'>
                                                                        <StatusBtn size='sm' status='active' />
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-span-2'>
                                                            <div className='flex flex-col gap-4'>
                                                                <div>
                                                                    <h2 className='text-[#949494] text-sm'>Document</h2>
                                                                    <p className='text-sm line-clamp-1 pr-3'>{asset?.document[0]?.document}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-span-2'>
                                                            <div className='flex flex-col gap-4'>
                                                                <div>
                                                                    <h2 className='text-[#949494] text-sm'>Given Date</h2>
                                                                    <p className='text-sm'>{asset?.given_date}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''
                                        }
                                    </>
                                </div>
                            )
                        })
                        :
                        <NotFound />
                    }

                </div>
            </div>
            {
                Assets && <Popup open={Assets} close={() => {
                    setAssetData({ is_active: true })
                    setImage()
                    toggleModal();
                }} heading={`${isEdit ? "Update Asset" : 'Add Asset'}`}>
                    <div className='flex flex-col gap-3'>
                        <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]'>
                            <CircleIcon>
                                <Svgs.File />
                            </CircleIcon>
                            <div className='flex flex-col gap-1 lg:text-left text-center'>
                                <h2 className='text-primary font-semibold text-xl'>Asset Details</h2>
                                <p className='text-[#7B7B7B] text-sm'>Add assets with respect to relevant staff.</p>
                            </div>
                        </div>
                        <LoginInput
                            required={true}
                            name={"name"}
                            value={name}
                            onChange={assetHandleChange}
                            error={errors?.name}
                            onBlur={() => onBlurHandler({ name: name })}
                            title='Asset Name'
                            placeholder='Enter Asset Name'

                        />
                        <Dropwdown
                            required={true}
                            name={"staff_id"}
                            value={staff_id}
                            onChange={assetHandleChange}
                            error={errors?.staff_id}
                            onBlur={() => onBlurHandler({ staff_id: staff_id })}
                            title='Staff Name'
                            placeholder='Select Staff'
                            options={employeeList?.map((emp) => {
                                return {
                                    label: emp?.full_name,
                                    value: emp?.id
                                }
                            })}

                        />
                        <div className='grid lg:grid-cols-2 gap-3'>
                            <DateInput
                                required={true}
                                name={"given_date"}
                                value={given_date}
                                onChange={assetHandleChange}
                                error={errors?.given_date}
                                onBlur={() => onBlurHandler({ given_date: given_date })}
                                title='Given Date'
                                placeholder='Enter Given Date'

                            />
                            <DateInput
                                required={false}
                                name={"return_date"}
                                value={return_date}
                                onChange={assetHandleChange}
                                title='Returned Date'
                                placeholder='Enter Returned Date'


                            />
                        </div>
                        <Dropwdown
                            required={true}
                            name={"is_active"}
                            value={is_active}
                            onChange={assetHandleChange}
                            options={
                                [
                                    { label: "Active", value: true },
                                    { label: "Inactive", value: false },
                                ]
                            }
                            error={errors?.is_active}
                            title='Status'
                            placeholder='Enter Status'

                        />

                        <div className='flex flex-col gap-4 border-[#A1A1A1] border-dashed border p-4 rounded-lg text-center text-sm'>
                            <div className='h-[8rem]'>
                                <Svgs.UploadList />
                            </div>
                            {/* <p className='text-[#595959]'>Drop your .CSV file</p>
                            <p className='text-[#595959]'>Or</p> */}
                            <LoginBtn size='sm' className='mx-auto'>
                                <label htmlFor='csvFileforproduct' className='cursor-pointer'>
                                    <div className='flex items-center gap-2 text-[#ffffff]'>
                                        Upload Document
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    name='document'
                                    value={""}
                                    onChange={assetHandleChange}
                                    id="csvFileforproduct"
                                    accept='.png,.PNG,.JPG,.jpg,jpeg,.JPEG'
                                    hidden />
                            </LoginBtn>
                        </div>
                        {image && <div className='flex items-center gap-3 flex-wrap'>
                            <div className='h-[5.2rem] w-[5.2rem] relative rounded-lg'>
                                <img src={image} className="h-full w-full object-cover rounded-lg" />
                                <div className='absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 bg-white cursor-pointer rounded-full'>
                                    <span onClick={removedImage}> <Svgs.CirlceClose fill='red' size='1.4rem' /> </span>
                                </div>
                            </div>
                        </div>}
                        <div className='flex items-center justify-start mt-[2rem]'>
                            <LoginBtn
                                loading={loading}
                                disabled={!name || !staff_id || !given_date || !document}
                                onClick={assetSubmitHandler}
                                size='sm'
                                text={`${isEdit ? "Update Asset" : 'Add Asset'}`}
                            />
                        </div>
                    </div>
                </Popup>
            }

            {
                isDelete &&
                <Popup heading='Are you sure you want to delete this staff asset?' onclose={() => { setIsDelete(false); setDeleteId() }}>
                    <div className=''>
                        <p>Are you sure you want to delete this staff asset? You won't be able to see this again.</p>
                        <div className='flex items-center justify-start mt-[2rem]'>
                            <LoginBtn
                                deleteBtn
                                text='Yes! Delete'
                                onClick={() => {
                                    deleteStaffAsset()
                                }}
                            />
                        </div>
                    </div>
                </Popup>
            }

            {
                Filter && <Popup heading='Filter' open={Filter} close={() => {
                    setemployeeText();
                    setIsActive(true);
                    setFrom();
                    setTo();
                    setFilter()
                }} >
                    <div className='flex flex-col gap-5'>
                        <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
                            <CircleIcon>
                                <Svgs.FilterPopup />
                            </CircleIcon>
                            <div className="flex flex-col gap-1 lg:text-left text-center">
                                <h2 className="text-primary font-semibold text-xl">
                                    Search By Filters
                                </h2>
                                <p className="text-[#7B7B7B] text-sm">
                                    Apply filters to get desired results
                                </p>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-5'>
                            <Dropwdown
                                title='Employee'
                                placeholder='Select Employee'
                                name={"employeeText"}
                                value={employeeText}
                                onChange={(e) => setemployeeText(e.target.value)}
                                options={employeeList?.map((item) => {
                                    return {
                                        label: item?.full_name,
                                        value: item?.full_name,
                                    }
                                })}
                            />

                            <Dropwdown
                                title='Status'
                                placeholder='Select Status'
                                name={"status"}
                                value={isActive}
                                onChange={(e) => setIsActive(e.target.value)}
                                options={[
                                    { value: true, label: 'Active' },
                                    { value: false, label: 'InActive' },
                                ]}
                            />

                            <DateInput
                                title='From'
                                name="from"
                                value={selectedFrom}
                                onChange={(e) => setFrom(e.target.value)}
                            />
                            <DateInput
                                title='To'
                                name="to"
                                value={selectedTo}
                                onChange={(e) => setTo(e.target.value)}
                            />
                        </div>
                        <div className='flex items-center justify-start'>
                            <LoginBtn text='Apply Filter' onClick={applyDateFilter} />
                        </div>
                    </div>
                </Popup>
            }
        </>
    )
}

export default StaffAssetes