import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { createClientAppointment } from 'Adapters/Api/apointments';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { apiErrorMessage } from 'Constants/Data/Errors';

function useProfileAppointment() {
    const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
    const token = getDataFromLocalStorage("access_token")
    const client_id = getDataFromLocalStorage("client_id")
    const business_location = getDataFromLocalStorage("business_location")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const state = useSelector((state) => state);
    const groupList = state?.utility?.saleService?.servicesgroup;
    const selectedProfileService = state?.utility?.saleService?.profileSelectedService;
    const profileSelectedLocation = state?.utility?.saleService?.profileSelectedLocation;
    const tax = state?.financialSettings?.businessTaxs;

    const [activeGroup, setActiveGroup] = useState();
    const [isServiceExist, setIsServiceExist] = useState(false);
    const [activeGroupServices, setActiveGroupServices] = useState();
    const [addedService, setAddedServices] = useState([]);
    const [location, setLocation] = useState([]);
    const [loading, setLoading] = useState(false);


    //set initial selected group, location, service here
    useEffect(() => {
        if (!selectedProfileService) {
            // navigate(-1)

        } else {

            const { location, group_id, id, service_name, employees, service, price, duration, selectedService } = selectedProfileService;
            setActiveGroup(group_id);
            setLocation(location)
            const result = profileSelectedLocation?.find(({ id }) => id === group_id);
            setActiveGroupServices(result);

            if (!selectedService) {
                setAddedServices([{ id, employees, duration, service, price, service_name, group_id }]);
            } else {
                setAddedServices(selectedService)
            }
        }
    }, [selectedProfileService]);


    useEffect(() => {
        let arr = [];
        activeGroupServices?.services.map((itm, i) => {
            const result = itm?.priceservice?.filter((dt) => dt?.currency == location?.currency?.id)
            if (result?.length > 0) {
                arr.push(...result)
            }
        })
        setIsServiceExist(arr?.length > 0 ? true : false)
    }, [activeGroupServices]);


    // calculate of selected service in the right side of the screen
    const totalPrice = (itms) => {
        let total = 0;
        itms?.forEach((itm) => {
            if (itm?.price) {
                total += itm?.price
            }
        });
        return total;
    }


    const onAppointmentBook = (data) => {
        let client_email = getDataFromLocalStorage('client_email')
        if (client_email) {
            data['client_email'] = client_email
        }
        data['client_token'] = getDataFromLocalStorage('client_token')
        setLoading(true)
        dispatch(createClientAppointment(
            data,
            (result) => {
                storeDataInLocalStorage('client_id', result.client_id)
                toast.success("Appointment created successfully", { toastId: "toast" });
                navigate("/clients-appointments")
            },
            () => {
                setLoading(false)
                toast.error(apiErrorMessage, { toastId: "toast" });
            }
        )
        )
    }


    return {
        selectedProfileService,
        profileSelectedLocation,
        activeGroup, setActiveGroup,
        activeGroupServices, setActiveGroupServices,
        addedService, setAddedServices,
        totalPrice, location, token, onAppointmentBook,
        client_id, loading, tax, isServiceExist, business_location
    }
}

export default useProfileAppointment
