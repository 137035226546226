import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

function useAppointmentsHelper() {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const active_tab = searchParams.get("active_tab");

    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    const [backButtonClick, setBackButtonClick] = useState(false)

    const tabList = [
        {
            value: "appointments",
            label: selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"
        },
        {
            value: "reversal-requests",
            label: selectedLanguage?.name == "English" ? "Reversal Requests" : translation[1561]?.value ? translation[1561]?.value : "Reversal Requests"
        },
    ];

    useEffect(() => {
        if (!backButtonClick && !(active_tab && !tabList?.includes(active_tab))) {
            setSearchParams({ ...searchParams, active_tab: tabList[0]?.value });
        }
    }, [active_tab, backButtonClick]);

    useEffect(() => {
        // custom browser back button functionality implemented
        if (active_tab == null) {
            setBackButtonClick(true)
            navigate('/dashboard/sales');
        };
    }, [active_tab]);

    return {
        selectedLanguage, translation, tabList, setSearchParams, searchParams, active_tab
    };
}

export default useAppointmentsHelper