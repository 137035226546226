import { MEMBER } from "../../ActionTypes/Membership/member";

const initialState = {
  memberships: [],
  membership_updated: false,
  page_count: 0,
  allCount: 0

};

const MembershipReducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {

    case MEMBER.GET_MEMBER:
      return {
        ...state,
        memberships: payload,
        membership_updated: true,
      };

    case MEMBER.GET_MEMBER_COUNT:
      return {
        ...state,
        page_count: payload?.pages,
        allCount: payload?.count
      };

    default:
      return state;
  }
};

export default MembershipReducer;