import { AddGiftCards } from "Adapters/Api/vouchers"
import { generateUniqueCode } from "Constants/Functions/generateRandomCodes"
import useObjectKeys from "Hooks/useObjectKeys"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import useValidations from "Hooks/useValidations"
import { useLocation } from "new-components/location/helper"
import { useState } from "react"
import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

let initialState = {
    price: '',
    title: "",
    valid_till: "",
    description: "",
    retail_price: '',
    term_condition: "",
    currency_gift_card_price: [],
}

const useCustomGiftCard = ({ handleSelection, setOpenCart, close }) => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const { locations } = useSelector(
        (state) => state
    );
    const { selected_location } = locations;
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const { locationDetail } = useLocation();
    const LocationCurrency = locationDetail?.currency;

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const locationList = state.locations.locations;
    const currencyList = state?.utility?.currency?.currency_list;
    const businessCurrency = state.business.business;

    const [btn_loading, setBtnLoading] = useState(false)
    const [codeToggle, setCodeToggle] = useState(false)
    const [giftCardCode, setGiftCardCode] = useState()

    const { setErrors, validation, setEditErrors, editErrors, errors, onBlurHandler, validateMoblieNumber } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys();

    const generateCouponCode = () => {
        const uniqueCode = generateUniqueCode(3, 3)
        return uniqueCode;
    }

    const [gift_card_input, setgift_card_input] = useState(initialState)

    const handlegift_card_input = (e) => {
        const { name, value } = e.target;
        let trimmedValue = value;
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));

        if (name === "description") {
            trimmedValue = value.slice(0, 150); // Trim the value to a maximum of 150 characters
            if (value.length > 150) {
                setErrors((prev) => ({
                    ...prev,
                    // description: "You can not add more than 150 characters",
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    description: "",
                }));
            }
        }

        if (name === "term_condition") {
            trimmedValue = value.slice(0, 150); // Trim the value to a maximum of 150 characters
            if (value.length > 150) {
                setErrors((prev) => ({
                    ...prev,
                    // term_condition: "You can not add more than 150 characters",
                }));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    term_condition: "",
                }));
            }
        }

        setgift_card_input({
            ...gift_card_input,
            [name]: trimmedValue,
        });

    }

    const giftCardsubmithandler = () => {
        const { title, valid_till, price, retail_price, description, term_condition } = gift_card_input;
        const uniqueCode = generateCouponCode()
        // errors['code'] = ''
        // gift_card_input['business'] = state?.business?.business?.id
        let requiredFields = {}
        if (codeToggle) {
            requiredFields = { title, description, price, retail_price, valid_till, term_condition, giftCardCode }
        } else {
            // gift_card_input['code'] = uniqueCode
            requiredFields = { title, description, price, retail_price, valid_till, term_condition }
        }

        const payload = {
            is_custom_card: true,
            code: codeToggle ? giftCardCode : uniqueCode,
            title: gift_card_input.title,
            business: state?.business?.business?.id,
            valid_till: gift_card_input.valid_till,
            description: gift_card_input.description,
            term_condition: gift_card_input.term_condition,
            currency_gift_card_price: [{
                currencies: LocationCurrency?.id,
                price: gift_card_input?.price,
                retail_price: gift_card_input?.retail_price
            }]
        }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);

        if ((objectKeyConvertToArray(result)?.length === 0)) {
            const success = (data) => {
                setBtnLoading(false)
                handleSelection(data?.id, data, true, giftCardCode)
                setgift_card_input(initialState)
                setGiftCardCode()
                setOpenCart(true)
                close()
            }
            const fail = () => {
                setBtnLoading(false)
            }
            setBtnLoading(true)
            dispatch(AddGiftCards(access_token, payload, success, fail))
        }
    }
    return {
        gift_card_input,
        selectedLanguage,
        translation,
        errors,
        onBlurHandler,
        giftCardsubmithandler,
        btn_loading,
        currencyList,
        LocationCurrency,
        handlegift_card_input,
        setErrors,
        codeToggle, setCodeToggle,
        giftCardCode, setGiftCardCode
    }
}

export default useCustomGiftCard