import React from 'react'

const GoogleDirection = ({ address, text }) => {
    const onClickFn = () => {
        // window.open(`https://www.google.com/maps/dir/lahore/${address}`, '_blank');
        if (!navigator.geolocation) {
            window.open(`https://www.google.com/maps?q=${address}`, '_blank');
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                window.open(`https://www.google.com/maps/dir/${position.coords.latitude},${position.coords.longitude}/${address}`, '_blank');

            }, () => {
                window.open(`https://www.google.com/maps?q=${address}`, '_blank');
            });
        }
    }
    return (
        <p className='cursor-pointer text-sm w-fit text-[#5679FF] underline' onClick={onClickFn}>{text}</p>
    )
}

export default GoogleDirection