import axios from "axios";
import { tenantDetail } from "../../../Hooks/useTenant";
import { createRefundTimePeriod, deleteRefundTimePeriod, getBusinessTaxSetting, getBusinessTaxs, getRefundTimePeriod, updateRefundTimePeriod } from "../../../Redux/TenantRedux/Actions/financialSettingsActions";
import { create_refund_time_url, delete_refund_time_url, get_refund_time_url, update_refund_time_url } from "../api_variables";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";

const { is_tenant, tenant } = tenantDetail();

const tenant_base_url = `${process.env.REACT_APP_SERVER_BASEURL_START}${tenant}.${process.env.REACT_APP_SERVER_BASEURL_END}`;
const tenant_base_url_without_tenant = `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}`;
const nstyle_base_url = `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}`;

let BASE_URL = is_tenant ? tenant_base_url : nstyle_base_url;



export const getPaymentMethods = async (business_id, from_financial_account_setting) => {
    const getProp = from_financial_account_setting ? "&get_all=true" : ""
    try {
        const result = await axios.get(`${BASE_URL}/business/get_business_payment_methods/?business=${business_id}${getProp}`);
        return result;
    } catch (error) {
        return error;
    }
}



export const addPaymentMethods = async (payment_method_data, business_id, access_token, status) => {
    try {
        const active = status ? true : false;
        const result = await axios.post(`${BASE_URL}/business/add_payment_method/`, { method_type: payment_method_data, business: business_id, is_active: active },
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });
        return result;
    } catch (error) {
        return error;
    }
}



export const deletePaymentMethod = async (payment_id, access_token) => {

    try {

        let result = await fetch(`${BASE_URL}/business/delete_business_payment_methods/`, {
            method: "DELETE",
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Token ${access_token}`
            },
            body: JSON.stringify(
                { id: payment_id }
            )
        })
        result = result.json();
        return result;

    } catch (error) {
        return error;
    }

}




export const updatePaymentMethods = async (updated_payment_method_data, business_id, payment_id, access_token) => {
    try {
        const result = await axios.put(`${BASE_URL}/business/update_payment_method/`, { method_type: updated_payment_method_data?.name, is_active: updated_payment_method_data?.status, business: business_id, id: payment_id },
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });
        return result;
    } catch (error) {
        return error;
    }
}




export const getBusinessTax = (business_id, setTaxLoader) => async (dispatch) => {
    try {
        const result = await axios.get(`${BASE_URL}/business/get_business_taxes/?business=${business_id}`);
        if (result.status === 200) {
            let businessTaxs = result?.data?.response?.tax;
            dispatch(getBusinessTaxs(businessTaxs));
            setTaxLoader(false)
        }
        return result;
    } catch (error) {
        return error;
    }
}



export const addBusinessTax = async (tax_data, access_token) => {
    try {
        const result = await axios.post(`${BASE_URL}/business/add_business_tax/`, tax_data,
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });
        return result;
    } catch (error) {
        return error;
    }
}







export const updateBusinessTax = async (updated_taxs, access_token) => {
    try {
        const result = await axios.put(`${BASE_URL}/business/update_business_tax/`, updated_taxs,
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });
        return result;
    } catch (error) {
        return error;
    }
}

export const updateBusinessTaxSetting = async (taxSetting, tax_id, business_id, access_token, setTaxSettingLoader) => {
    setTaxSettingLoader(true)
    try {
        const result = await axios.put(`${BASE_URL}/business/tax_setting/`,
            {
                tax_setting: taxSetting,
                business_id: business_id,
                business_tax_id: tax_id
            },
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });
        if (result.status === 200) {
            setTaxSettingLoader(false)
        }
        return result;
    } catch (error) {
        setTaxSettingLoader(false)
        return error;
    }
}

export const getTaxSettingBusiness = (business_id, access_token) => async (dispatch) => {
    try {
        const result = await axios.get(`${BASE_URL}/business/tax_setting/?business_id=${business_id}`,
            {
                headers: { 'Authorization': `Token ${access_token}` },
            }
        )
        if (result.status === 200) {
            let businessTaxSetting = result?.data?.response?.data?.data;
            dispatch(getBusinessTaxSetting(businessTaxSetting));
        }
        return result;
    } catch (error) {
        return error;
    }
};


export const deleteBusinessTax = async (tax, access_token) => {
    try {
        let result = await fetch(`${BASE_URL}/business/delete_business_tax/`, {
            method: "DELETE",
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Token ${access_token}`
            },
            body: JSON.stringify(
                { tax }
            )
        })
        result = result.json();
        return result;
    } catch (error) {
        return error;
    }
}


// create_refund_time_url
// update_refund_time_url
// delete_refund_time_url
// get_refund_time_url
export const create_refund_time_period = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.post(`${BASE_URL}${create_refund_time_url}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.response?.data
            if (result?.data?.status_code == 200) {
                dispatch(createRefundTimePeriod(data));
                toast.success(result?.data?.response?.message, { toastId: "add_refund_time_period" })
                success && success()
                return;
            }
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }

}
export const get_refund_time_period = (access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.get(`${BASE_URL}${get_refund_time_url}`, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.response?.data;
            dispatch(getRefundTimePeriod(data));
            success && success()
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

export const delete_refund_time_period = (access_token, data, success, fail) => async (dispatch) => {
    try {
        const result = await axios.delete(`${BASE_URL}${delete_refund_time_url}${data}`, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            toast.success(result?.data?.response?.message, { toastId: "delete_refund_tp" })
            dispatch(deleteRefundTimePeriod(data));
            success && success()
            return
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
        return error;
    }
}

export const update_refund_time_period = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.put(`${BASE_URL}${update_refund_time_url}${payload?.id}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.response?.data
            if (result?.data?.status_code == 200) {
                dispatch(updateRefundTimePeriod(data));
                toast.success(result?.data?.response?.message, { toastId: "update_refund_tp" })
                success && success()
            }
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "update_refund_tp" })
        fail && fail()
        return error;
    }
};