import { useEffect, useState } from "react";

const useDealTypeTwoSetting = ({ setErrors, setSettingData, settingData, allProducts, allServices, allProductsBrand, allServicesCategory, allServicesGroup, serviceDiscount, productDiscount }) => {
    const [screenLoader, setScreenLoader] = useState(true)
    const [activeTab, setActiveTab] = useState('Service')
    const [serviceType, setServiceType] = useState()
    const [popup, setPopup] = useState(false)
    const [allMergedArrays, setAllMergedArrays] = useState([])
    const [searchService, setSearchService] = useState("")
    const [searchProduct, setSearchProduct] = useState("")
    const [selectedServicesArray, setSelectedServicesArray] = useState([])
    const [selectedProductsArray, setSelectedProductsArray] = useState([])

    const tabList = [
        {
            value: "Service",
            label: "Service"
        },
        {
            value: "Product",
            label: "Product"
        },
        {
            value: "Voucher",
            label: "Voucher"
        },
    ];

    useEffect(() => {
        setTimeout(() => {
            setScreenLoader && setScreenLoader(false)
        }, 1000);
    }, [])
    console.log(settingData, "set")
    useEffect(() => {
        const applicableServicesSet = new Set(serviceDiscount?.applicableServices?.map(service => service?.itemId));
        const filtered = allMergedArrays?.filter((itm) => applicableServicesSet.has(itm?.id));
        setSelectedServicesArray(filtered)
    }, [allMergedArrays, serviceDiscount])

    useEffect(() => {
        const applicableProductsSet = new Set(productDiscount?.applicableProducts?.map(product => product?.itemId));
        const filtered = allMergedArrays?.filter((itm) => applicableProductsSet.has(itm?.id));
        setSelectedProductsArray(filtered)
    }, [allMergedArrays, productDiscount])

    useEffect(() => {
        const srv = allServices || []
        const srvgrp = allServicesGroup || []
        const srvcat = allServicesCategory || []
        const prd = allProducts || []
        const prdbrd = allProductsBrand || []
        setAllMergedArrays([...srv, ...srvgrp, ...srvcat, ...prd, ...prdbrd])
    }, [allServices, allProductsBrand, allProducts, allServicesCategory, allServicesGroup])

    // handle change for service discount radio
    const handleChangeForServiceDiscountRadio = (name, value) => {
        // service
        if (name == 'service') {
            if (value == 'None') {
                setSettingData((prev) => ({
                    ...prev,
                    serviceDiscount: {
                        applicableOn: value
                    }
                }))
            } else {
                setSettingData((prev) => ({
                    ...prev,
                    serviceDiscount: {
                        ...prev?.serviceDiscount,
                        applicableOn: value
                    }
                }))
            }
        }
        // product
        if (name == 'product') {
            if (value == 'None') {
                setSettingData((prev) => ({
                    ...prev,
                    productDiscount: {
                        applicableOn: value
                    }
                }))
            } else {
                setSettingData((prev) => ({
                    ...prev,
                    productDiscount: {
                        ...prev?.productDiscount,
                        applicableOn: value
                    }
                }))
            }
        }
        // voucher
        if (name == 'voucher') {
            if (value == 'None') {
                setSettingData((prev) => ({
                    ...prev,
                    voucherDiscount: {
                        applicableOn: value
                    }
                }))
            } else {
                setSettingData((prev) => ({
                    ...prev,
                    voucherDiscount: {
                        ...prev?.voucherDiscount,
                        applicableOn: value
                    }
                }))
            }
        }
    }

    // handle change for service discount radio
    const handleChangeForDealType2 = (e, type) => {
        const { name, value } = e.target
        // service
        if (type == 'service') {
            setSettingData((prev) => ({
                ...prev,
                serviceDiscount: {
                    ...prev?.serviceDiscount,
                    applicableOnAllDiscount: value
                }
            }))
        }
        // product
        if (type == 'product') {
            setSettingData((prev) => ({
                ...prev,
                productDiscount: {
                    ...prev?.productDiscount,
                    applicableOnAllDiscount: value
                }
            }))
        }
        // voucher
        if (type == 'voucher') {
            setSettingData((prev) => ({
                ...prev,
                voucherDiscount: {
                    ...prev?.voucherDiscount,
                    applicableOnAllDiscount: value
                }
            }))
        }
    }

    // handle change for deal type 2 dropdown
    const handleChangeForType2DealDropdown = (e, data, type) => {
        // for customService DealType2
        if (type == 'customService') {
            setSettingData((prev) => {
                const mapingList = data || []
                const updatedApplicableItems = mapingList?.map((itm) => {
                    const filteredItm = prev?.serviceDiscount?.applicableServices?.find(item => item?.itemId == itm)
                    return {
                        itemId: itm,
                        discount: filteredItm?.discount ? filteredItm?.discount : ""
                    };
                });
                return {
                    ...prev,
                    serviceDiscount: {
                        ...prev?.serviceDiscount,
                        applicableServices: updatedApplicableItems,
                    },
                };
            });
        }

        if (type == 'customProduct') {
            setSettingData((prev) => {
                const mapingList = data || [];
                const updatedApplicableItems = mapingList?.map((itm) => {
                    const filteredItm = prev?.productDiscount?.applicableProducts?.find(item => item?.itemId == itm)
                    return {
                        itemId: itm,
                        discount: filteredItm?.discount ? filteredItm?.discount : ""
                    };
                });
                return {
                    ...prev,
                    productDiscount: {
                        ...prev?.productDiscount,
                        applicableProducts: updatedApplicableItems,
                    },
                };
            });
        }
        setPopup(false)
        setServiceType()
    }

    // handle discount change of applicableServices and applicableProducts for deal type 2
    const handleDiscountChange = (e, id, type) => {
        const { name, value } = e.target;
        if (type == "customService") {
            setSettingData((prev) => {
                const existingDiscounts = prev?.serviceDiscount?.applicableServices || [];
                const updatedApplicableItems = existingDiscounts?.map((existingItem) => {
                    if (existingItem?.itemId == id) {
                        // Update the discount for the matching item
                        return {
                            ...existingItem,
                            discount: value,
                        };
                    }
                    // No match, return the existing item as is
                    return existingItem;
                });

                return {
                    ...prev,
                    serviceDiscount: {
                        ...prev?.serviceDiscount,
                        applicableServices: updatedApplicableItems,
                    },
                };
            });
        }
        if (type == 'customProduct') {
            setSettingData((prev) => {
                const existingDiscounts = prev?.productDiscount?.applicableProducts || [];
                const updatedApplicableItems = existingDiscounts?.map((existingItem) => {
                    if (existingItem?.itemId == id) {
                        // Update the discount for the matching item
                        return {
                            ...existingItem,
                            discount: value,
                        };
                    }
                    // No match, return the existing item as is
                    return existingItem;
                });

                return {
                    ...prev,
                    productDiscount: {
                        ...prev?.productDiscount,
                        applicableProducts: updatedApplicableItems,
                    },
                };
            });
        }
    };

    // removed selected Item from array
    const removeSelectedItem = (value, type) => {
        // for deal type 2 service
        if (type == "customService") {
            const updatedApplicableItems = settingData?.serviceDiscount?.applicableServices?.filter((itm) => itm?.itemId != value)
            setSettingData((prev) => ({
                ...prev,
                serviceDiscount: {
                    ...prev?.serviceDiscount,
                    applicableServices: updatedApplicableItems
                }
            }))
        }
        // for deal type 2 product
        if (type == 'customProduct') {
            const updatedApplicableItems = settingData?.productDiscount?.applicableProducts?.filter((itm) => itm?.itemId != value)
            setSettingData((prev) => ({
                ...prev,
                productDiscount: {
                    ...prev?.productDiscount,
                    applicableProducts: updatedApplicableItems
                }
            }))
        }
    }


    return {
        handleChangeForServiceDiscountRadio, handleChangeForDealType2, handleChangeForType2DealDropdown, popup, setPopup,
        screenLoader, serviceType, setServiceType, handleDiscountChange, removeSelectedItem, tabList, activeTab, setActiveTab,
        allMergedArrays, searchService, setSearchService, searchProduct, setSearchProduct,
        selectedServicesArray, selectedProductsArray
    }
};

export default useDealTypeTwoSetting