import React from 'react'
import Svgs from '../../../Assets/svgs'

const TypeStatusBtn = (props) => {
    return (
        <div className={`border ${props?.is_leo_day && "border-[#FF9843] bg-[#FF9843]/10"} ${props?.is_holiday && "border-[#5679FF] bg-[#5679FF]/10"} ${props?.is_working_schedule && "border-[#FFCB13] bg-[#FFCB13]/10"} ${props?.is_weekend && "border-[#1FD7FF] bg-[#1FD7FF]/10"}  ${props?.is_leave && "border-[#FF1F1F] bg-[#FF1F1F]/10"} ${props?.is_vacation && "border-[#05D672] bg-[#05D672]/10"}  py-[9px] px-[12px] rounded-[13px] flex items-center w-fit gap-2`}>
            <Svgs.Bullet fill={props?.fill?props?.fill:'#000'} />
            {props?.status &&
                <p className={`capitalize text-sm ${props?.is_leo_day && "text-[#FF9843]"} ${props?.is_holiday && "text-[#5679FF]"} ${props?.is_working_schedule && "text-[#FFCB13]"} ${props?.is_weekend && "text-[#1FD7FF] "} ${props?.is_leave && "text-[#FF1F1F]"}  ${props?.is_vacation && "text-[#05D672]"}`}>{props?.status}</p>
            }
        </div>
    )
}

TypeStatusBtn.defaultProps = {
    status: '',
    fill: '',
    color: ''
}

export default TypeStatusBtn


