// import { get_all_segments } from "Adapters/Api/segments"
import { delete_Holiday, get_all_holidays } from "Adapters/Api/holidays"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"


const initialFilter = {
  segment_type: "",
  is_active: "",
}

let holidayTimeout = undefined
const useHoliday = ({ setCreateHoliday }) => {
  const dispatch = useDispatch()
  const state = useSelector(state => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const { getDataFromLocalStorage } = useSetDataInLocalStorage()
  const access_token = getDataFromLocalStorage('access_token')

  const [ViewDetails, setViewDetails] = useState(false);
  const [isEdit, setIsEdit] = useState(false)

  const [loading, setLoading] = useState(true)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [FilterPopup, setFilterPopup] = useState(false)
  const [deletePopup, setDeletePopup] = useState(false);
  const [holidayDetails, setHolidayDetails] = useState({})
  const [currentPage, setCurrentPage] = useState(1);
  const [searchHoliday, setSearchHoliday] = useState('')
  const [filterData, setFilterData] = useState(initialFilter)

  const holidays = state?.holidays?.holidays;
  const holidayPagination = state?.holidays?.holidayPagination;

  const current_location = getDataFromLocalStorage("selected_location");
  let selectedLocation = state?.locations?.selected_location || current_location


  const getHolidayDetail = (data, type) => {
    setHolidayDetails(data)
    if (type == "detail") {
      setViewDetails(true);
    }
    if (type == "edit") {
      setIsEdit(true)
      setCreateHoliday(true)
    }
    if (type == "delete") {
      setDeletePopup(true);
    }
  }

  const getHolidays = ({ apply, selectedLocation }) => {
    setLoading(true)
    const payload = {
      page: currentPage,
      location: selectedLocation
    };
    if (searchHoliday) {
      payload.search_text = searchHoliday
    }
    if (apply) {
      payload.segment_type = filterData?.segment_type;
      payload.is_active = filterData?.is_active;
    }
    const success = () => { setLoading(false) }
    const fail = () => { setLoading(false) }
    dispatch(get_all_holidays(access_token, payload, success, fail))
  }


  const deleteHoliday = (id) => {
    setDeleteLoading(true)
    const success = () => {
      getHolidays({ selectedLocation })
      setDeletePopup(false)
      setDeleteLoading(false)
    }
    const fail = () => {
      setDeletePopup(false)
      setDeleteLoading(false)
    }
    dispatch(delete_Holiday(access_token, id, success, fail))
  }

  const successCallBack = () => {
    setLoading(true)
    setCurrentPage(1)
    getHolidays({ selectedLocation })
  }

  useEffect(() => {
    if (searchHoliday === null) {
      getHolidays({ selectedLocation })
    } else {
      if (holidayTimeout) {
        clearTimeout(holidayTimeout)
      }
      holidayTimeout = setTimeout(() => {
        getHolidays({ selectedLocation })
      }, 500);
    }
  }, [searchHoliday, selectedLocation, currentPage])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchHoliday, selectedLocation])

  useEffect(() => {
    setLoading(true)
  }, [currentPage, selectedLocation])

  return {
    loading, translation, selectedLanguage, holidayDetails, holidayPagination,
    holidays, deleteHoliday, successCallBack,
    ViewDetails, setViewDetails,
    FilterPopup, setFilterPopup,
    searchHoliday, setSearchHoliday,
    deleteLoading, getHolidayDetail,
    deletePopup, setDeletePopup,
    currentPage, setCurrentPage,
    isEdit, setIsEdit,
    selectedLocation,

  }
}

export default useHoliday