/* eslint-disable array-callback-return */
import { getLocations } from "Adapters/Api";
import { createDeal, getDealById, getDealRestrictions, getDealsChannels, getDealsItemTypeOptions, getDealsSetting, getDealsTypes, getDealsValidityOptions, postDealSettings, updateDeal, updateDealImages, updateDealRestriction, updateDealTncs, updateDealsStatus, uploadSingleImage } from "Adapters/Api/CRM/Deals";
import useObjectKeys from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const initialState = {
    title: "",
    typeId: "",
    categoryId: "",
    status: 'Active',
    audience: "public",
    codeGeneration: "unique",
    usageLimitType: 'unlimited',
    usagePerCustomer: null,
    customerType: 'any',
    brand: [],
    redeemableChannelIds: [],
    startDate: null,
    endDate: null,
    minimumSpendOn: "all",
    minimumSpend: "",
    description: "",
    validPeriod: "",
    validityPeriodTypeId: "",
    code: ""
}
const restrictionInitialState = {
    excludedProductIds: [],
    excludedServiceIds: [],
    excludedWeekDays: [],
    excludedDates: [],
    excludedLocationIds: [],
}
const allOption = {
    label: "All",
    value: "all",
};
const dayRestrictions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const settingInitialState = {
    settingsId: null
}
const optionsForDealTypeOne = [
    { label: 'Product', value: 'product' },
    { label: 'Service', value: 'service' },
    { label: 'Voucher', value: 'voucher' }
]
const anyOrAllOption = [
    { label: 'Any', value: 'Any' },
    { label: 'All', value: 'All' }
]
const AllTypeOfDiscounts = [
    { label: 'Discount', value: false },
    { label: 'Free', value: true }
]

const validityScheduleOptions = [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Year', value: 'year' },
]


const useCreateDealHelper = () => {
    const formRef = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { errors, setErrors, validation } = useValidations()
    const { objectKeyConvertToArray } = useObjectKeys()

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage('access_token')
    const dealIdFromLocal = getDataFromLocalStorage('dealId')
    const editDeal = getDataFromLocalStorage('editDeal')
    const businessData = getDataFromLocalStorage("business_data");

    // states
    const [toggleblockdate, setToggleblockdate] = useState(false)
    const [blockDate, setBlockDate] = useState('')
    const [toggleStore, setToggleStore] = useState(false)
    const [toggleDay, setToggleDay] = useState(false)
    const [toggleProduct, setToggleProduct] = useState(false)
    const [toggleService, setToggleService] = useState(false)
    const [dealTypeOptions, setDealTypeOptions] = useState([])
    const [allTypes, setAllTypes] = useState([])
    const [dealCategoryOptions, setDealCategoryOptions] = useState([])
    const [dealBrandOptions, setDealBrandOptions] = useState([])
    const [dealChannelOptions, setDealChannelOptions] = useState([])
    const [allChannels, setAllChannels] = useState([])
    const [dealValidityOption, setDealValidityOption] = useState([])
    const [allProducts, setAllProducts] = useState([])
    const [productRestriction, setProductRestriction] = useState('')
    const [allServices, setAllServices] = useState([])
    const [allServicesGroup, setAllServicesGroup] = useState([])
    const [allServicesCategory, setAllServicesCategory] = useState([])
    const [allProductsBrand, setAllProductBrands] = useState([])
    const [serviceRestriction, setServiceRestriction] = useState('')
    const [dealLocationOption, setDealLocationOption] = useState([])
    const [allLocations, setAllLocations] = useState([])
    const [storeRestriction, setStoreRestriction] = useState("")
    const [dataAfterFirstStepSubmit, setDataAfterFirstStepSubmit] = useState({})
    const [step, setStep] = useState(1)
    const [data, setData] = useState(initialState)
    const [restrictionData, setRestrictionData] = useState(restrictionInitialState)
    const [tncs, setTncs] = useState([])
    const [termAndCondition, setTermAndCondition] = useState('')
    const [allImages, setAllImages] = useState([])
    const [allMergedOptions, setAllMergedOptions] = useState([])
    const [settingData, setSettingData] = useState(settingInitialState)
    const [dealTypeSlug, setDealTypeSlug] = useState("")
    const [completeDealData, setCompleteDealData] = useState({})
    const [btnLoading, setBtnLoading] = useState(false)
    const [restrictionLoader, setRestrictionLoader] = useState(false)
    const [imageLoader, setImageLoader] = useState(false)
    const [tncsLoader, setTncsLoader] = useState(false)
    const [statusLoader, setStatusLoader] = useState(false)
    const [settingsLoader, setSettingsLoader] = useState(false)
    const [submitSettingClick, setSubmitSettingClick] = useState(false)
    const [dealStatus, setDealStatus] = useState('Active')
    const [pageLoader, setPageLoader] = useState(true)
    const [verificationPopupForStepOne, setVerificationPopupForStepOne] = useState(false)
    const [stepToNavigate, setStepToNavigate] = useState(false)
    const [excludedServicePopup, setExcludedServicePopup] = useState(false)
    const [excludedServiceType, setExcludedServiceType] = useState(false)

    useEffect(() => {
        // get apis on every refresh
        getTypesForDeals()
        getChannelsForDeals()
        getValidityOptionsForDeals()
        getLocationOptionsForDeal()
        getItemsFromTypeForDeal('product/get_products_main_page/?no_pagination=true')
        getItemsFromTypeForDeal('product/get_brands/?no_pagination=true')
        getItemsFromTypeForDeal('sale/get_servicegroup_main_page/?no_pagination=true')
        getItemsFromTypeForDeal('deal/get_deal_category/')
        getItemsFromTypeForDeal('sale/get_service_main_page/?no_pagination=true')
    }, [])

    useEffect(() => {
        if (step == 3) {
            getSettingsForDeal()
        }
    }, [step])

    useEffect(() => {
        setAllMergedOptions([...allProducts, ...allServices, ...allProductsBrand, ...allServicesCategory, ...allServicesGroup])
    }, [allProducts, allServices, allProductsBrand, allServicesCategory, allServicesGroup])

    useEffect(() => {
        // filtering brand options
        let filtered = allProductsBrand;
        filtered = allProductsBrand?.filter((brand) =>
            !data.brand?.includes(brand?.id) // Assuming that brand has an id property
        );
        setDealBrandOptions([...filtered]); // Creating a new array before setting state
    }, [data?.brand?.length, dealBrandOptions?.length, allProductsBrand?.length]);

    useEffect(() => {
        // filtering channel options
        let filtered = allChannels;
        filtered = allChannels?.filter((channel) =>
            !data.redeemableChannelIds?.includes(channel?.channelId) // Assuming that channel has an id property
        );
        setDealChannelOptions([...filtered]); // Creating a new array before setting state
    }, [data?.redeemableChannelIds?.length, dealChannelOptions?.length, allChannels?.length]);

    useEffect(() => {
        // filtering location options
        let filtered = allLocations;
        filtered = allLocations?.filter((location) =>
            !restrictionData?.excludedLocationIds?.includes(location?.id) // Assuming that brand has an id property
        );
        setDealLocationOption([...filtered]); // Creating a new array before setting state
    }, [restrictionData?.excludedLocationIds?.length, dealLocationOption?.length, allLocations?.length]);

    useEffect(() => {
        const filtered = allTypes?.find((itm) => itm?.typeId == data?.typeId)
        if (filtered?.settingsPageUrlSlug) {
            setDealTypeSlug(filtered?.settingsPageUrlSlug)
        }
    }, [allTypes?.length, dataAfterFirstStepSubmit, data?.typeId])

    useEffect(() => {
        // delete all settings data on change of typeId
        if (data?.typeId) {
            setSettingData({
                settingsId: settingData?.settingsId ? settingData?.settingsId : null
            })
        }
    }, [data?.typeId])

    useEffect(() => {
        // store deal id after creating or on edit
        if (dataAfterFirstStepSubmit?.id) {
            storeDataInLocalStorage("dealId", dataAfterFirstStepSubmit?.id)
        }
    }, [dataAfterFirstStepSubmit?.id])

    useEffect(() => {
        if (dealIdFromLocal) {
            getDealDetailData()
            getDealDetailRestrictionData()
        } else {
            setPageLoader(false)
        }
    }, [dealIdFromLocal])

    useEffect(() => {
        if (dealTypeSlug === "buy-some-get-some-free-or-discount") {
            if (settingData?.requiredItemsSettings?.anyOrAll == undefined) {
                setSettingData({
                    ...settingData,
                    requiredItemsSettings: {
                        ...settingData?.requiredItemsSettings,
                        "anyOrAll": "Any",
                    }
                })
            }
            if (settingData?.applicableItemsSettings?.anyOrAll == undefined) {
                setSettingData({
                    ...settingData,
                    applicableItemsSettings: {
                        ...settingData?.applicableItemsSettings,
                        "anyOrAll": "Any",
                        isFree: false
                    }
                })
            }
        }
        if (dealTypeSlug === "get-free-on-purchasing-specific-items") {
            if (settingData?.requiredItemsSettings?.timePeriodType == undefined) {
                setSettingData({
                    ...settingData,
                    requiredItemsSettings: {
                        ...settingData?.requiredItemsSettings,
                        "timePeriodType": "day",
                    }
                })
            }
            if (settingData?.applicableItemsSettings?.isFree == undefined) {
                setSettingData({
                    ...settingData,
                    applicableItemsSettings: {
                        ...settingData?.applicableItemsSettings,
                        isFree: false
                    }
                })
            }
        }
        if (dealTypeSlug === "spend-some-amount-get-some-reward") {
            if (settingData?.applicableItemsSettings?.isFree == undefined) {
                setSettingData({
                    ...settingData,
                    applicableItemsSettings: {
                        ...settingData?.applicableItemsSettings,
                        isFree: false
                    }
                })
            }
        }
    }, [dealTypeSlug, settingData?.applicableItemsSettings, settingData?.requiredItemsSettings])

    var minEndDateLimit = useMemo(() => {
        return moment(data?.startDate).add(1, 'days').format('YYYY-MM-DDTHH:mm')
    }, [data?.startDate]);

    // format date time
    const formatDateTime = (inputDateTime) => {
        const formattedDateTime = moment(inputDateTime).format('YYYY-MM-DD HH:mm:ss');
        return formattedDateTime;
    };

    // data handle change function
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value
        }))
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
    }

    // restrictions handle change function
    const handleRestrictionChange = (e, type) => {
        const { name, value } = e.target;
        if (type == 'blockDate') {
            if (name === 'blockDate') {
                let validateBlock = restrictionData?.excludedDates?.find(obj => obj === value)
                if (validateBlock) {
                    setErrors((prev) => ({
                        ...prev,
                        blockDate: "Date already selected",
                    }));
                }
                if (!validateBlock) {
                    setErrors((prev) => ({
                        ...prev,
                        blockDate: "",
                    }));
                }
                setBlockDate(value)
            }

        }
        if (type == 'dayRestriction') {
            let filteredItm = []
            const existing = restrictionData?.excludedWeekDays || []
            const matchedItm = restrictionData?.excludedWeekDays?.find((itm) => itm == value)
            if (matchedItm) {
                filteredItm = restrictionData?.excludedWeekDays?.filter((itm) => itm != value)
            } else {
                filteredItm = [...existing, value]
            }
            setRestrictionData((prev) => ({
                ...prev,
                excludedWeekDays: [...filteredItm]
            }))
        }
        if (type == 'storeRestriction') {
            setStoreRestriction(value)
        }
        if (type == 'product') {
            setProductRestriction(value)
        }
        if (type == 'service') {
            setServiceRestriction(value)
        }
    }

    // onClick for add blockDate
    const addRestrictionData = (type) => {
        if (type == 'blockDate') {
            if (!errors?.blockDate) {
                const existing = restrictionData?.excludedDates || []
                setRestrictionData((prev) => ({
                    ...prev,
                    excludedDates: [...existing, blockDate]
                }))
                setBlockDate('')
            }
        }
        if (type == 'storeRestriction') {
            const existing = restrictionData?.excludedLocationIds || []
            setRestrictionData((prev) => ({
                ...prev,
                excludedLocationIds: [...existing, storeRestriction]
            }))
            setStoreRestriction('')
        }
        if (type == 'product') {
            if (productRestriction == "all") {
                setRestrictionData((prev) => ({
                    ...prev,
                    excludedProductIds: allProducts?.map((itm) => itm?.id)
                }))
            } else {
                const exist = data?.excludedProductIds?.find(itm => itm == productRestriction)
                const existing = restrictionData?.excludedProductIds || []
                setRestrictionData((prev) => ({
                    ...prev,
                    excludedProductIds: [...existing, productRestriction]
                }))
            }
            setProductRestriction('')
        }
        if (type == 'service') {
            if (serviceRestriction == "all") {
                setRestrictionData((prev) => ({
                    ...prev,
                    excludedServiceIds: allServices?.map((itm) => itm?.id)
                }))
            } else {
                const exist = data?.excludedServiceIds?.find(itm => itm == serviceRestriction)
                const existing = restrictionData?.excludedServiceIds || []
                setRestrictionData((prev) => ({
                    ...prev,
                    excludedServiceIds: [...existing, serviceRestriction]
                }))
            }
            setServiceRestriction('')
        }
    }

    // onClick for add terms and conditions
    const addTncs = () => {
        setTncs([...tncs, termAndCondition])
        setTermAndCondition('')
    }

    // handle change for terms and conditions
    const handleChangeTermsAndConditions = (e) => {
        const { name, value } = e.target;
        setTermAndCondition(value)
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
    }

    // handle change for radio buttons
    const handleChangeForRadio = (name, value) => {
        setData((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    // handle change for start date and end date selection
    const handleChangeForDate = (e) => {
        const { name, value } = e.target;
        if (name == 'startDate') {
            setData((prev) => ({
                ...prev,
                [name]: formatDateTime(value),
                endDate: null
            }))
        } else {
            setData((prev) => ({
                ...prev,
                [name]: formatDateTime(value)
            }))
        }
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
    }

    // handle change for dropdown add
    const handleChangeForDropdown = (e, type) => {
        const { name, value } = e.target;
        // for brand
        if (type == 'brand') {
            if (value == "all") {
                setData((prev) => ({
                    ...prev,
                    [name]: allProductsBrand?.map((itm) => itm?.id)
                }))
            } else {
                const exist = data?.brand?.find(itm => itm == value)
                if (!exist) {
                    setData((prev) => ({
                        ...prev,
                        [name]: [...prev?.brand, value]
                    }))
                }
            }
        }
        // for channel
        if (type == 'channel') {
            if (value == "all") {
                setData((prev) => ({
                    ...prev,
                    [name]: allChannels?.map((itm) => itm?.channelId)
                }))
            } else {
                const exist = data?.redeemableChannelIds?.find(itm => itm == value)
                if (!exist) {
                    setData((prev) => ({
                        ...prev,
                        [name]: [...prev?.redeemableChannelIds, value]
                    }))
                }
            }
        }
        // for validity
        if (type == 'validity') {
            const matchingValidity = dealValidityOption?.find((itm) => itm?.id == value)
            setData((prev) => ({
                ...prev,
                [name]: matchingValidity?.id,
            }))
        }
        // for product
        if (type == 'product') {
            if (value == "all") {
                setRestrictionData((prev) => ({
                    ...prev,
                    [name]: allProducts?.map((itm) => itm?.id)
                }))
            } else {
                const exist = data?.excludedProductIds?.find(itm => itm == value)
                if (!exist) {
                    setRestrictionData((prev) => ({
                        ...prev,
                        [name]: [...prev?.excludedProductIds, value]
                    }))
                }
            }
        }
        // for service
        if (type == 'service') {
            if (value == "all") {
                setRestrictionData((prev) => ({
                    ...prev,
                    [name]: allServices?.map((itm) => itm?.id)
                }))
            } else {
                const exist = data?.excludedServiceIds?.find(itm => itm == value)
                if (!exist) {
                    setRestrictionData((prev) => ({
                        ...prev,
                        [name]: [...prev?.excludedServiceIds, value]
                    }))
                }
            }
        }
        // for applicalbleCategoryIds
        if (type == 'applicalbleCategoryIds') {
            if (value == "all") {
                setSettingData((prev) => ({
                    ...prev,
                    [name]: optionsForDealTypeOne?.map((itm) => itm?.value)
                }))
            } else {
                const exist = settingData?.applicalbleCategoryIds?.find(itm => itm == value)
                if (!exist) {
                    setSettingData((prev) => ({
                        ...prev,
                        [name]: [...prev?.applicalbleCategoryIds, value]
                    }))
                }
            }
        }
    }

    // handle change for restriction  dropdown add
    const handleChangeForRestrictionDropdownAdd = (e, data, type, name) => {
        // const { name, value } = e.target;
        // for product
        if (type == 'product') {
            setRestrictionData((prev) => ({
                ...prev,
                [name]: data?.map(itm => itm)
            }))
        }
        // for service
        if (type == 'service') {
            setRestrictionData((prev) => ({
                ...prev,
                [name]: data?.map(itm => itm)
            }))
        }
        setExcludedServicePopup(false)
        setExcludedServiceType()
    }


    // removed selected Item from array
    const removeSelectedItem = (value, type) => {
        // for brand
        if (type == 'brand') {
            const filteredBrands = data.brand?.filter((itm) => itm != value)
            setData((prev) => ({
                ...prev,
                brand: [...filteredBrands]
            }))
        }
        // for channel
        if (type == 'channel') {
            const filteredBrands = data.redeemableChannelIds?.filter((itm) => itm != value)
            setData((prev) => ({
                ...prev,
                redeemableChannelIds: [...filteredBrands]
            }))
        }
        // for product
        if (type == 'product') {
            const filteredProducts = restrictionData?.excludedProductIds?.filter((itm) => itm != value)
            setRestrictionData((prev) => ({
                ...prev,
                excludedProductIds: [...filteredProducts]
            }))
        }
        // for service
        if (type == 'service') {
            const filteredServices = restrictionData?.excludedServiceIds?.filter((itm) => itm != value)
            setRestrictionData((prev) => ({
                ...prev,
                excludedServiceIds: [...filteredServices]
            }))
        }
        // for block date
        if (type == 'blockDate') {
            const filteredBlockDates = restrictionData?.excludedDates?.filter((itm) => itm != value)
            setRestrictionData((prev) => ({
                ...prev,
                excludedDates: [...filteredBlockDates]
            }))
        }
        // for terms and conditions
        if (type == 'termAndCondition') {
            const filteredTncs = tncs?.filter((itm, ind) => ind != value)
            setTncs([...filteredTncs])
        }
        // for store restrictions
        if (type == 'storeRestriction') {
            const filteredLocationIds = restrictionData?.excludedLocationIds?.filter((itm) => itm != value)
            setRestrictionData((prev) => ({
                ...prev,
                excludedLocationIds: [...filteredLocationIds]
            }))
        }
    }

    // Get Deal Types
    const getTypesForDeals = () => {
        if (access_token) {
            let success = (result) => {
                setAllTypes(result?.data?.data)
                setDealTypeOptions(result?.data?.data)
            }
            let fail = () => {
            }
            dispatch(getDealsTypes(access_token, success, fail))
        }
    }

    // Get Deal Channels
    const getChannelsForDeals = () => {
        if (access_token) {
            let success = (result) => {
                setAllChannels(result?.data?.data)
                setDealChannelOptions(result?.data?.data)
            }
            let fail = () => {
            }
            dispatch(getDealsChannels(access_token, success, fail))
        }
    }

    // Get Deal Validity Options
    const getValidityOptionsForDeals = () => {
        if (access_token) {
            let success = (result) => {
                setDealValidityOption(result?.data?.data)
            }
            let fail = () => {
            }
            dispatch(getDealsValidityOptions(access_token, success, fail))
        }
    }

    // Get Deal Settings
    const getItemsFromTypeForDeal = (type) => {
        if (access_token) {
            let success = (result) => {
                if (type == 'sale/get_service_main_page/?no_pagination=true') {
                    setAllServices(result?.data?.response?.service)
                }
                if (type == 'product/get_products_main_page/?no_pagination=true') {
                    setAllProducts(result?.data?.response?.products)
                }
                if (type == 'sale/get_servicegroup_main_page/?no_pagination=true') {
                    setAllServicesGroup(result?.data?.response?.sales)
                }
                if (type == 'deal/get_deal_category/') {
                    setAllServicesCategory(result?.data?.data)
                    setDealCategoryOptions(result?.data?.data)
                }
                if (type == 'product/get_brands/?no_pagination=true') {
                    setAllProductBrands(result?.data?.response?.brands)
                    setDealBrandOptions(result?.data?.response?.brands)
                }
            }
            let fail = () => {
            }
            dispatch(getDealsItemTypeOptions(type, access_token, success, fail))
        }
    }

    // Get Deal Location Options
    const getLocationOptionsForDeal = () => {
        if (access_token) {
            let success = (result) => {
                setAllLocations(result)
                setDealLocationOption(result)
            }
            let fail = () => {
            }
            dispatch(getLocations(businessData?.id, success, fail));
        }
    }

    // deal type 2 setting format 
    const setSecondTypeDealGetData = (data) => {
        if (data?.serviceDiscount?.applicableOn == 'Custom' || data?.productDiscount?.applicableOn == 'Custom') {
            setSettingData({
                ...data,
                productDiscount: {
                    applicableOn: data?.productDiscount?.applicableOn,
                    applicableProducts: data?.productDiscount?.applicableProducts?.map((itm) => ({
                        discount: itm?.discount,
                        itemId: itm?.item?.id
                    })),
                },
                serviceDiscount: {
                    applicableOn: data?.serviceDiscount?.applicableOn,
                    applicableServices: data?.serviceDiscount?.applicableServices?.map((itm) => ({
                        discount: itm?.discount,
                        itemId: itm?.item?.id
                    }))
                }
            });
        } else {
            setSettingData(data)
        }
    }

    // deal type 3 setting format
    const setThirdTypeDealGetData = (data) => {
        setSettingData({
            ...data,
            applicableItemsSettings: {
                ...data?.applicableItemsSettings,
                itemIds: data?.applicableItemsSettings?.items?.map(itm => itm?.id),
            },
            requiredItemsSettings: {
                ...data?.requiredItemsSettings,
                itemIds: data?.requiredItemsSettings?.items?.map(itm => itm?.id),
            }
        });
    }

    // deal type 4 setting format
    const setFourthTypeDealGetData = (data) => {
        setSettingData({
            ...data,
            applicableItems: data?.applicableItems?.map((itm) => ({
                qty: itm?.qty,
                itemId: itm?.item?.id
            }))
        });
    }

    // deal type 5 setting format
    const setFifthTypeDealGetData = (data) => {
        setSettingData({
            ...data,
            applicableItems: data?.applicableItems?.map((itm) => ({
                qty: itm?.qty,
                itemId: itm?.item?.id
            }))
        });
    }

    // deal type 6 setting format
    const setSixthTypeDealGetData = (data) => {
        setSettingData({
            ...data,
            applicableItemsSettings: {
                ...data?.applicableItemsSettings,
                itemIds: data?.applicableItemsSettings?.items?.map(itm => itm?.id),
            },
            requiredItemsSettings: {
                ...data?.requiredItemsSettings,
                timePeriodType: data?.requiredItemsSettings?.timePeriodType?.periodId,
                itemIds: data?.requiredItemsSettings?.items?.map(itm => itm?.id),
            }
        });
    }

    // deal type 7 setting format
    const setSeventhTypeDealGetData = (data) => {
        setSettingData({
            ...data,
            applicableItemsSettings: {
                ...data?.applicableItemsSettings,
                itemIds: data?.applicableItemsSettings?.items?.map(itm => itm?.id),
            },
            // spendTargetCategoryIds: data?.spendTargetCategoryIds?.map(item => item.charAt(0).toUpperCase() + item.slice(1))
        });
    }

    // deal type 8 setting format
    const setEigthTypeDealGetData = (data) => {
        setSettingData({
            ...data,
            applicableItems: data?.applicableItems?.map((itm) => ({
                fixedPrice: itm?.fixedPrice,
                itemId: itm?.item?.id
            }))
        });
    }

    // Get Deal Settings
    const getSettingsForDeal = () => {
        const filtered = allTypes?.find((itm) => itm?.typeId == data?.typeId)
        if (access_token) {
            let success = (result) => {
                if (result?.data?.response?.data?.settingsId) {
                    if (dealTypeSlug == "fixed-amount-discount-deal") {
                        setSettingData(result?.data?.response?.data)
                    }
                    if (dealTypeSlug === "percentage-discount-deal") {
                        setSecondTypeDealGetData(result?.data?.response?.data)
                    }
                    if (dealTypeSlug === "buy-some-get-some-free-or-discount") {
                        setThirdTypeDealGetData(result?.data?.response?.data)
                    }
                    if (dealTypeSlug === "get-free-on-purchasing-specific-items") {
                        setSixthTypeDealGetData(result?.data?.response?.data)
                    }
                    if (dealTypeSlug === "fixed-price-items-deal") {
                        setEigthTypeDealGetData(result?.data?.response?.data)
                    }
                    if (dealTypeSlug === "spend-some-amount-get-some-reward") {
                        setSeventhTypeDealGetData(result?.data?.response?.data)
                    }
                    if (dealTypeSlug === "complimentary-voucher") {
                        setFourthTypeDealGetData(result?.data?.response?.data)
                    }
                    if (dealTypeSlug === "package") {
                        setFifthTypeDealGetData(result?.data?.response?.data)
                    }
                } else {
                    setSettingData({
                        settingsId: null,
                        ...result?.data?.response?.data
                    })
                }
            }
            let fail = () => {
            }
            dispatch(getDealsSetting(dealIdFromLocal, filtered?.settingsPageUrlSlug, access_token, success, fail))
        }
    }

    // handle image change
    const handleImageChange = (e, type) => {
        let imageData = {}
        const file = e.target.files[0]
        const fileType = e.target.files[0]?.type;
        const fileFormat = fileType?.split("/")[1];
        const url = URL.createObjectURL(file)
        const fileName = e.target.files[0]?.name

        imageData = {
            fileFormat: fileFormat,
            url: url,
            fileName: fileName,
            file: file,
        }
        let success = (result) => {
            imageData = {}
            setAllImages([...allImages, { ...result, screenSize: type, isThumbnail: false }])
        }
        let fail = () => {
        }
        dispatch(uploadSingleImage(imageData, access_token, success, fail))
    }

    // handle thumbnail click
    const handleThumbnailClick = (id) => {
        const updatedImages = allImages?.map((image) => {
            // Check if the current image's fileUrlId matches the provided id
            if (image?.fileUrlId == id) {
                // If it matches, set isThumbnail to true
                return { ...image, isThumbnail: true };
            } else {
                // If it doesn't match, set isThumbnail to false
                return { ...image, isThumbnail: false };
            }
        });
        setAllImages(updatedImages)
    }

    // handle delete image
    const handleDeleteImage = (id) => {
        const filteredImages = allImages?.filter(itm => itm?.fileUrlId != id)
        setAllImages(filteredImages)
    }

    // Get Deal By Id
    const getDealDetailRestrictionData = (dealId) => {
        if (access_token) {
            let success = (result) => {
                console.log(result, "result")
                const dealData = result?.data
                const resData = {
                    excludedDates: dealData?.block_dates,
                    excludedProductIds: dealData?.excluded_products,
                    excludedServiceIds: dealData?.excluded_services,
                    excludedLocationIds: dealData?.excluded_locations,
                    excludedWeekDays: dealData?.excludedWeekDays
                }
                if (resData?.excludedProductIds?.length > 0) {
                    setToggleProduct(true)
                }
                if (resData?.excludedServiceIds?.length > 0) {
                    setToggleService(true)
                }
                if (resData?.excludedLocationIds?.length > 0) {
                    setToggleStore(true)
                }
                if (resData?.excludedWeekDays?.length > 0) {
                    setToggleDay(true)
                }
                if (resData?.excludedDates?.length > 0) {
                    setToggleblockdate(true)
                }
                setRestrictionData(resData)
            }
            let fail = () => {
            }
            const DealID = dealIdFromLocal ? dealIdFromLocal : dealId
            dispatch(getDealRestrictions(DealID, access_token, success, fail))
        }
    }

    // Get Deal By Id
    const getDealDetailData = (dealId) => {
        if (access_token) {
            let success = (result) => {
                setPageLoader(false)
                const dealData = result?.data
                setCompleteDealData(result?.data)
                setData({
                    title: dealData?.title,
                    typeId: dealData?.typeId,
                    categoryId: dealData?.category,
                    status: dealData?.status ? dealData?.status : "Draft",
                    audience: dealData?.audience,
                    codeGeneration: dealData?.codeGeneration,
                    usageLimitType: dealData?.usageLimitType,
                    usagePerCustomer: dealData?.usagePerCustomer,
                    brand: dealData?.brand,
                    redeemableChannelIds: dealData?.redeemableChannels,
                    startDate: dealData?.startDate,
                    endDate: dealData?.endDate,
                    minimumSpendOn: dealData?.minimumSpendOn,
                    minimumSpend: dealData?.minimumSpend,
                    customerType: dealData?.customerType,
                    description: dealData?.description,
                    validPeriod: dealData?.validPeriod,
                    validityPeriodTypeId: dealData?.validityPeriodType,
                    code: dealData?.code
                })
                setAllImages(dealData?.images?.map((itm) => ({
                    deleted: itm?.imageFileData?.deleted,
                    extension: itm?.imageFileData?.extension,
                    fileName: itm?.imageFileData?.fileName,
                    fileUrlId: itm?.imageFileData?.fileUrlId,
                    url: itm?.imageFileData?.url,
                    isThumbnail: itm?.isThumbnail,
                    screenSize: itm?.screenSize?.screenSizeId,

                })))
                setTncs(dealData?.tncs)
            }
            let fail = () => {
                setPageLoader(false)
            }
            setPageLoader(true)
            const DealID = dealIdFromLocal ? dealIdFromLocal : dealId
            dispatch(getDealById(DealID, access_token, success, fail))
        }
    }

    // first step submit function
    const firstStepSubmit = (stepToNavigate) => {
        let success = (result) => {
            getDealDetailData(result?.dealId)
            getDealDetailRestrictionData(result?.dealId)
            setDataAfterFirstStepSubmit(result)
            if (stepToNavigate) {
                setStep(stepToNavigate)
            } else {
                setStep(2)
            }
            setBtnLoading(false)
            setVerificationPopupForStepOne(false)
        }
        let fail = (err) => {
            if (err?.response?.status == 400) {
                toast.error(err?.response?.data?.message, { toastId: "toast" })
            }
            setBtnLoading(false)
        }
        let requiredFields = {
            title: data?.title,
            typeId: data?.typeId,
            categoryId: data?.categoryId,
            brand: data?.brand?.length > 0 ? true : "",
            redeemableChannelIds: data?.redeemableChannelIds?.length > 0 ? true : "",
            startDate: data?.startDate,
            minimumSpend: data?.minimumSpend,
            description: data?.description,
            validityPeriodTypeId: data?.validityPeriodTypeId,
            code: data?.code
        }
        let payload = {
            title: data?.title,
            typeId: data?.typeId,
            category: data?.categoryId,
            audience: data?.audience,
            codeGeneration: data?.codeGeneration,
            usageLimitType: data?.usageLimitType,
            usagePerCustomer: data?.usagePerCustomer ? +data?.usagePerCustomer : null,
            brand: data?.brand,
            redeemableChannels: data?.redeemableChannelIds,
            startDate: data?.startDate,
            endDate: data?.endDate,
            minimumSpendOn: "all",
            minimumSpend: +data?.minimumSpend,
            description: data?.description,
            validPeriod: data?.validityPeriodTypeId !== 'from-start-to-end-date' ? +data?.validPeriod : null,
            validityPeriodType: data?.validityPeriodTypeId,
            code: data?.code,
            customerType: data?.customerType
        }
        if (data?.usageLimitType == 'limited') {
            requiredFields = { ...requiredFields, usagePerCustomer: data?.usagePerCustomer }
        }
        if (data?.validityPeriodTypeId && data?.validityPeriodTypeId !== 'from-start-to-end-date') {
            requiredFields = { ...requiredFields, validPeriod: data?.validPeriod }
        }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setBtnLoading(true)
            if (dealIdFromLocal) {
                payload = { ...payload, status: data?.status, dealId: dealIdFromLocal }
                dispatch(updateDeal(payload, access_token, success, fail))
            } else {
                dispatch(createDeal(payload, access_token, success, fail))
            }
        } else {
            setVerificationPopupForStepOne(false)
            formRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }


    // second step submit function
    const submitDealRestrictions = () => {
        let success = (result) => {
            setRestrictionLoader(false)
        }
        let fail = () => {
            setRestrictionLoader(false)
        }
        let payload = {
            restrictions: {
                excluded_products: restrictionData?.excludedProductIds || [],
                excluded_services: restrictionData?.excludedServiceIds || [],
                block_dates: restrictionData?.excludedDates || [],
                excluded_locations: restrictionData?.excludedLocationIds || [],
                excludedWeekDays: restrictionData?.excludedWeekDays || []
            },
            dealId: dealIdFromLocal
        }
        // setErrors(validation(requiredFields));
        // let result = validation(requiredFields);
        if (dealIdFromLocal) {
            setRestrictionLoader(true)
            dispatch(updateDealRestriction(dealIdFromLocal, payload, access_token, success, fail))
        }
    }

    // Deal images submit function
    const submitDealImages = () => {
        let success = (result) => {
            setImageLoader(false)
        }
        let fail = () => {
            setImageLoader(false)
        }
        let requiredFields = { allImages: allImages?.length > 0 ? true : "" }
        let payload = {
            dealImagesList: allImages,
            dealId: dealIdFromLocal
        }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0 && dealIdFromLocal) {
            setImageLoader(true)
            dispatch(updateDealImages(payload, access_token, success, fail))
        }
    }

    // Terms and condition submit function
    const submitTermsAndCondition = () => {
        let success = (result) => {
            setTncsLoader(false)
        }
        let fail = () => {
            setTncsLoader(false)
        }
        let requiredFields = { termAndCondition: tncs?.length > 0 ? true : "" }
        let payload = {
            tncs: tncs,
            dealId: dealIdFromLocal
        }

        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0 && dealIdFromLocal) {
            setTncsLoader(true)
            dispatch(updateDealTncs(dealIdFromLocal, payload, access_token, success, fail))
        }
    }

    // post deal settings
    const updateDealSettingData = () => {
        setSubmitSettingClick(true)
        let success = (result) => {
            // setSettingData(...result)
            setSettingsLoader(false)
            setSubmitSettingClick(false)
        }
        let fail = () => {
            setSettingsLoader(false)
        }

        const filtered = allTypes?.find((itm) => itm?.typeId == data?.typeId)
        if (access_token) {
            let payload = {
                settingsId: settingData?.settingsId
            }
            if (dealTypeSlug == "fixed-amount-discount-deal") {
                dealTypeOneSubmit(payload, filtered, success, fail)
            }
            if (dealTypeSlug === "percentage-discount-deal") {
                dealTypeTwoSubmit(payload, filtered, success, fail)
            }
            if (dealTypeSlug === "buy-some-get-some-free-or-discount") {
                dealTypeThreeSubmit(payload, filtered, success, fail)
            }
            if (dealTypeSlug === "get-free-on-purchasing-specific-items") {
                dealTypeFourSubmit(payload, filtered, success, fail)
            }
            if (dealTypeSlug === "fixed-price-items-deal") {
                dealTypeFiveSubmit(payload, filtered, success, fail)
            }
            if (dealTypeSlug === "spend-some-amount-get-some-reward") {
                dealTypeSixSubmit(payload, filtered, success, fail)
            }
            if (dealTypeSlug === "complimentary-voucher") {
                dealTypeSevenSubmit(payload, filtered, success, fail)
            }
            if (dealTypeSlug === "package") {
                dealTypeEightSubmit(payload, filtered, success, fail)
            }
        }
    }

    // deal type one submit function
    const dealTypeOneSubmit = (payload, filtered, success, fail) => {
        let typeAllowSubmit = false
        payload = { ...payload, discountAmount: settingData?.discountAmount, applicalbleCategoryIds: settingData?.applicalbleCategoryIds }
        if (settingData?.discountAmount && settingData?.applicalbleCategoryIds) {
            typeAllowSubmit = true
        }
        if (typeAllowSubmit) {
            setSettingsLoader(true)
            dispatch(postDealSettings(dealIdFromLocal, filtered?.settingsPageUrlSlug, payload, access_token, success, fail))
        }
    }

    // deal type two submit function 
    const dealTypeTwoSubmit = (payload, filtered, success, fail) => {
        let typeAllowSubmit = false
        let productSubmit = false
        let voucherSubmit = false
        let serviceSubmit = false
        payload = {
            ...payload,
            serviceDiscount: {
                ...(settingData?.serviceDiscount || {}),
                applicableOn: settingData?.serviceDiscount?.applicableOn || 'None',
            },
            productDiscount: {
                ...(settingData?.productDiscount || {}),
                applicableOn: settingData?.productDiscount?.applicableOn || 'None',
            },
            voucherDiscount: {
                ...(settingData?.voucherDiscount || {}),
                applicableOn: settingData?.voucherDiscount?.applicableOn || 'None',
            },
        };
        if (settingData?.serviceDiscount?.applicableOn && settingData?.serviceDiscount?.applicableOn != 'None') {
            if (settingData?.serviceDiscount?.applicableOn == 'All' && settingData?.serviceDiscount?.applicableOnAllDiscount) {
                serviceSubmit = true
            } else if (settingData?.serviceDiscount?.applicableOn == 'Custom') {
                delete payload.serviceDiscount.applicableOnAllDiscount
                const allServicesHaveDiscount = settingData?.serviceDiscount?.applicableServices?.every(service => service.discount);
                if (allServicesHaveDiscount) {
                    serviceSubmit = true
                }
            }
        } else {
            serviceSubmit = true
        }
        if (settingData?.productDiscount?.applicableOn && settingData?.productDiscount?.applicableOn != 'None') {
            if (settingData?.productDiscount?.applicableOn == 'All' && settingData?.productDiscount?.applicableOnAllDiscount) {
                productSubmit = true
            } else if (settingData?.productDiscount?.applicableOn == 'Custom') {
                delete payload.productDiscount.applicableOnAllDiscount
                const allProductHaveDiscount = settingData?.productDiscount?.applicableProducts?.every(product => product.discount);
                if (allProductHaveDiscount) {
                    productSubmit = true
                }
            }
        } else {
            productSubmit = true
        }
        if (settingData?.voucherDiscount?.applicableOn && settingData?.voucherDiscount?.applicableOn != 'None') {
            if (settingData?.voucherDiscount?.applicableOn == 'All' && settingData?.voucherDiscount?.applicableOnAllDiscount) {
                voucherSubmit = true
            }
        } else {
            voucherSubmit = true
        }

        if (productSubmit && voucherSubmit && serviceSubmit) {
            typeAllowSubmit = true
        }
        if (typeAllowSubmit) {
            setSettingsLoader(true)
            dispatch(postDealSettings(dealIdFromLocal, filtered?.settingsPageUrlSlug, payload, access_token, success, fail))
        }
    }

    // deal type three submit function 
    const dealTypeThreeSubmit = (payload, filtered, success, fail) => {
        let requiredItemLength = false
        let applicableItemLength = false
        payload = {
            ...payload,
            requiredItemsSettings: settingData?.requiredItemsSettings,
            applicableItemsSettings: settingData?.applicableItemsSettings
        }
        let requiredFields = {}
        if (settingData?.requiredItemsSettings?.isFree == false) {
            requiredFields = { ...requiredFields, discount: settingData?.requiredItemsSettings?.isFree }
        }

        if (((settingData?.applicableItemsSettings?.anyOrAll == 'Any' && settingData?.applicableItemsSettings?.isFree == false) ||
            (settingData?.applicableItemsSettings?.anyOrAll == 'Any' && settingData?.applicableItemsSettings?.isFree == true))) {
            requiredFields = { ...requiredFields, applicableqty: settingData?.applicableItemsSettings?.qty }
        }

        if (settingData?.requiredItemsSettings?.anyOrAll == 'Any') {
            requiredFields = { ...requiredFields, qty: settingData?.requiredItemsSettings?.qty }
        }

        if (settingData?.requiredItemsSettings?.itemIds?.length > 0) {
            requiredItemLength = true
        }
        if (settingData?.applicableItemsSettings?.itemIds?.length > 0) {
            applicableItemLength = true
        }

        if (requiredItemLength && applicableItemLength) {
            setErrors(validation(requiredFields));
            let result = validation(requiredFields);
            if (objectKeyConvertToArray(result)?.length === 0) {
                setSettingsLoader(true)
                dispatch(postDealSettings(dealIdFromLocal, filtered?.settingsPageUrlSlug, payload, access_token, success, fail))
            }
        }
    }

    // deal type Four submit function 
    const dealTypeFourSubmit = (payload, filtered, success, fail) => {
        let requiredItemLength = false
        let applicableItemLength = false
        payload = {
            ...payload,
            requiredItemsSettings: settingData?.requiredItemsSettings,
            applicableItemsSettings: settingData?.applicableItemsSettings
        }
        let requiredFields = {
            qty: settingData?.requiredItemsSettings?.qty,
            timePeriod: settingData?.requiredItemsSettings?.timePeriod,
            applicableQty: settingData?.applicableItemsSettings?.qty
        }

        if (settingData?.applicableItemsSettings?.isFree == false) {
            requiredFields = { ...requiredFields, discount: settingData?.applicableItemsSettings?.discount }
        }

        if (settingData?.requiredItemsSettings?.itemIds?.length > 0) {
            requiredItemLength = true
        }
        if (settingData?.applicableItemsSettings?.itemIds?.length > 0) {
            applicableItemLength = true
        }

        if (requiredItemLength && applicableItemLength) {
            setErrors(validation(requiredFields));
            let result = validation(requiredFields);
            if (objectKeyConvertToArray(result)?.length === 0) {
                setSettingsLoader(true)
                dispatch(postDealSettings(dealIdFromLocal, filtered?.settingsPageUrlSlug, payload, access_token, success, fail))
            }
        }
    }

    // deal type five submit function 
    const dealTypeFiveSubmit = (payload, filtered, success, fail) => {
        let typeAllowSubmit = false
        payload = {
            ...payload,
        };

        const allItmsHaveDiscount = settingData?.applicableItems?.every(itm => itm?.fixedPrice);
        if (allItmsHaveDiscount && settingData?.applicableItems?.length > 0) {
            typeAllowSubmit = true
        }
        payload = { ...payload, ...settingData }
        if (typeAllowSubmit) {
            setSettingsLoader(true)
            dispatch(postDealSettings(dealIdFromLocal, filtered?.settingsPageUrlSlug, payload, access_token, success, fail))
        }
    }

    // deal type Four submit function 
    const dealTypeSixSubmit = (payload, filtered, success, fail) => {
        let applicableItemLength = false
        payload = {
            ...payload,
            applicableItemsSettings: settingData?.applicableItemsSettings,
            spendAmount: settingData?.spendAmount,
            spendTargetCategoryIds: settingData?.spendTargetCategoryIds
        }
        let requiredFields = {
            applicableQty: settingData?.applicableItemsSettings?.qty,
            spendAmount: settingData?.spendAmount
        }

        if (settingData?.applicableItemsSettings?.isFree == false) {
            requiredFields = { ...requiredFields, discount: settingData?.applicableItemsSettings?.discount }
        }

        if (settingData?.applicableItemsSettings?.itemIds?.length > 0) {
            applicableItemLength = true
        }

        if (applicableItemLength && settingData?.spendTargetCategoryIds?.length > 0) {
            setErrors(validation(requiredFields));
            let result = validation(requiredFields);
            if (objectKeyConvertToArray(result)?.length === 0) {
                setSettingsLoader(true)
                dispatch(postDealSettings(dealIdFromLocal, filtered?.settingsPageUrlSlug, payload, access_token, success, fail))
            }
        }
    }

    // deal type seven submit function 
    const dealTypeSevenSubmit = (payload, filtered, success, fail) => {
        let typeAllowSubmit = false
        payload = {
            ...payload,
        };

        const allItmsHaveDiscount = settingData?.applicableItems?.every(itm => itm?.qty);
        if (allItmsHaveDiscount && settingData?.applicableItems?.length > 0) {
            typeAllowSubmit = true
        }
        payload = { ...payload, ...settingData }
        if (typeAllowSubmit) {
            setSettingsLoader(true)
            dispatch(postDealSettings(dealIdFromLocal, filtered?.settingsPageUrlSlug, payload, access_token, success, fail))
        }
    }

    // deal type eight submit function 
    const dealTypeEightSubmit = (payload, filtered, success, fail) => {
        let typeAllowSubmit = false
        payload = {
            ...payload,
        };

        const allItmsHaveDiscount = settingData?.applicableItems?.every(itm => itm?.qty);
        if (allItmsHaveDiscount && settingData?.applicableItems?.length > 0) {
            typeAllowSubmit = true
        }
        payload = { ...payload, ...settingData }
        let requiredFields = { packagePrice: settingData?.packagePrice }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            if (typeAllowSubmit) {
                setSettingsLoader(true)
                dispatch(postDealSettings(dealIdFromLocal, filtered?.settingsPageUrlSlug, payload, access_token, success, fail))
            }
        }
    }

    // update deal status function
    const updateDealStatus = () => {
        let success = (result) => {
            navigate("/dashboard/crm/deals")
            setStatusLoader(false)
        }
        let fail = () => {
            setStatusLoader(false)
        }
        let payload = {
            status: dealStatus,
            dealId: dealIdFromLocal
        }

        if (dealIdFromLocal) {
            setStatusLoader(true)
            dispatch(updateDealsStatus(dealIdFromLocal, payload, access_token, success, fail))
        }
    }

    return {
        navigate, step, setStep, handleChange, data, errors, dealTypeOptions, dealCategoryOptions,
        handleChangeForRadio, dealBrandOptions, handleChangeForDropdown, removeSelectedItem,
        allOption, dealChannelOptions, allChannels, handleChangeForDate, btnLoading, firstStepSubmit,
        formRef, dealValidityOption, allProducts, allServices,
        restrictionData, handleRestrictionChange, dayRestrictions, toggleDay, setToggleDay,
        toggleStore, setToggleStore, blockDate, setBlockDate, toggleblockdate, setToggleblockdate,
        dataAfterFirstStepSubmit, termAndCondition, handleChangeTermsAndConditions, addTncs, tncsLoader,
        submitTermsAndCondition, tncs, handleImageChange, allImages, handleDeleteImage, storeRestriction,
        handleThumbnailClick, dealLocationOption, allLocations, addRestrictionData, productRestriction,
        toggleProduct, setToggleProduct, toggleService, setToggleService, serviceRestriction,
        restrictionLoader, imageLoader, submitDealImages, submitDealRestrictions, settingData,
        updateDealSettingData, settingsLoader, dealTypeSlug, optionsForDealTypeOne, allServicesGroup,
        allServicesCategory, allProductsBrand, submitSettingClick, anyOrAllOption, AllTypeOfDiscounts,
        allMergedOptions, setSettingData, setErrors, validityScheduleOptions, dealStatus, setDealStatus,
        statusLoader, updateDealStatus, completeDealData, pageLoader, editDeal, dealIdFromLocal,
        minEndDateLimit, verificationPopupForStepOne, setVerificationPopupForStepOne,
        excludedServicePopup, setExcludedServicePopup, excludedServiceType, setExcludedServiceType,
        handleChangeForRestrictionDropdownAdd, stepToNavigate, setStepToNavigate
    }
};

export default useCreateDealHelper