import Svgs from "Assets/svgs";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import React from "react";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import useDeals from "./helper";
import Pagination from "new-components/Pagination";
import DeleteConfirmation from "new-components/delete-popup";
import Popup from "Components/TenantApp/Elements/Popup";
import NotFound from "Components/Utility/NotFound";
import Filter from "./Filter";
import TableShimmer_v2 from "Components/Utility/Shimmers/TableShimmer_v2";

const Deals = () => {
    const { navigate, loader, currentPage, setCurrentPage, allDeals, dealsData, PageSize, deleteLoader,
        deleteDealFunct, deleteId, setDeleteId, updateDealStatus, statusLoader, editDeal, setEditDeal, clearFilter,
        editDealFunct, keyWord, setKeyWord, setFilterPopup, FilterPopup, filterData, onFilterChange, onSubmitFilter
    } = useDeals();

    return (
        <>
            <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
                <Breadcrumb first={"CRM"} url={"/dashboard/crm/"} second={"Deals"} />

                <div className="flex items-center gap-3 justify-between">
                    <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">
                        <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
                            Deals
                        </h2>
                    </div>
                    <LoginBtn onClick={() =>
                        navigate('/dashboard/crm/deals/createDeals')
                    } text={<div className="flex items-center gap-3">
                        <div>
                            <Svgs.Plus />
                        </div>
                        <div>
                            <p>
                                Create Deals
                            </p>
                        </div>
                    </div>
                    }
                    />
                </div>

                <div className="">
                    <div className="border rounded-lg">
                        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
                            <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                                <div className="flex items-center lg:gap-4 gap-3">
                                    <div className="flex items-center sm:gap-4 gap-2 ">
                                        <h2 className="font-semibold text-xl">
                                            Deals
                                        </h2>
                                        <div
                                            className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                            {loader ? (
                                                <div className="h-4 w-12" />
                                            ) : (
                                                <p className="text-primary">
                                                    {" "}
                                                    {dealsData?.totalRecords}{" "}
                                                    {dealsData?.totalRecords > 1 ? 'Records' : 'Record'}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-00">
                                    <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
                                        <LoginBtn borderBtn onClick={() => {
                                            setFilterPopup(!FilterPopup);
                                        }}>
                                            <div className="flex items-center gap-2 text-primary">
                                                <Svgs.Filters />
                                                <p>
                                                    Filters
                                                </p>
                                            </div>
                                        </LoginBtn>
                                        <LoginInput
                                            title=""
                                            name={"search_text"}
                                            placeholder={"Search"}
                                            padding="py-1"
                                            leftIcon={<Svgs.Search />}
                                            value={keyWord}
                                            onChange={(e) => { setKeyWord(e.target.value) }}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
                                <IconButton filled>
                                    <Svgs.Import fill={"white"} />
                                </IconButton>
                                <IconButton filled>
                                    <Svgs.Export />
                                </IconButton>
                            </div> */}
                        </div>

                        {loader ? (
                            <TableShimmer_v2 cols={5} />
                        ) : (
                            <div className="overflow-x-auto">
                                <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                                    <p> Name</p>
                                    <p> Type</p>
                                    <p> Category</p>
                                    <p> Client Type</p>
                                    <p> Status</p>
                                    <p> Action</p>
                                </div>
                                <div className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}>
                                    {allDeals?.length > 0 ? allDeals?.map((item, index) =>
                                        <>
                                            <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] min-w-full w-fit">
                                                <div className="cursor-pointer h-full flex items-center gap-3">
                                                    <div
                                                        className="cursor-pointer h-full flex items-center gap-3">
                                                        <div>
                                                            {/* <div className="h-[2rem] w-[2rem] "><img className="w-full h-full rounded-full" src={'https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=1379'} alt="img" /></div> */}
                                                        </div>
                                                        <p className="text-sm text-primary">{item?.title}</p>
                                                    </div>
                                                </div>
                                                <div className="flex items-center capitalize">
                                                    <p className="text-sm">{item?.deal_type}</p>
                                                </div>
                                                <div className="flex items-center capitalize">
                                                    <p className="text-sm">{item?.category}</p>
                                                </div>
                                                <div className="flex items-center capitalize">
                                                    <p className="text-sm">{item?.customerType}</p>
                                                </div>
                                                <div className="flex items-center">
                                                    <StatusBtn
                                                        status={item?.status ? item?.status : 'Draft'}
                                                        text={item?.status ? item?.status : 'Draft'}
                                                    />
                                                </div>
                                                <div className="flex items-center gap-3">
                                                    <div onClick={() => { editDealFunct(item?.id, item?.status) }}
                                                        className="h-[2rem] w-[2rem] p-2 border rounded-full flex items-center  cursor-pointer justify-center">
                                                        <Svgs.Edit fill="#7D7D7D" />
                                                    </div>
                                                    <div onClick={() => { setDeleteId(item?.id) }}
                                                        className="h-[2rem] w-[2rem] p-2 border rounded-full flex items-center  cursor-pointer justify-center">
                                                        <Svgs.Delete fill="#7D7D7D" />
                                                    </div>
                                                    <LoginBtn
                                                        deleteBtn
                                                        text={"Deactivate"}
                                                        onClick={() => {
                                                            updateDealStatus(item?.id, 'Inactive')
                                                        }}
                                                        disabled={item?.status != "Active" || (statusLoader?.id == item?.id && statusLoader?.statusLoader)}
                                                        loading={(statusLoader?.id == item?.id && statusLoader?.statusLoader)}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : <NotFound />}
                                </div>

                                {(!loader && allDeals?.length > 0) &&
                                    <Pagination
                                        currentPage={currentPage + 1}
                                        totalCount={Math.ceil(dealsData?.totalPageCount)}
                                        pageSize={PageSize}
                                        onPageChange={page => setCurrentPage(page - 1)}
                                    />
                                }
                            </div>
                        )}


                    </div>
                </div>
            </div>

            {deleteId &&
                <DeleteConfirmation
                    title='Delete Deal'
                    isLoading={deleteLoader}
                    handleClose={() => {
                        setDeleteId('');
                    }}
                    handleDelete={deleteDealFunct}
                    heading={"Are you sure you want to delete?"}
                />}

            {editDeal && (
                <Popup
                    size="md"
                    heading="Edit Deal"
                    open={editDeal}
                    close={setEditDeal}
                >
                    <div className="flex flex-col gap-1">
                        <h1 className="text-primary text-sm normal-case">Are you sure you want to edit deal.The status of deal become Draft.</h1>
                        <div className="flex justify-end">
                            <LoginBtn
                                disabled={(statusLoader?.id == editDeal?.id && statusLoader?.editLoader)}
                                loading={(statusLoader?.id == editDeal?.id && statusLoader?.editLoader)}
                                text="Sure! Go To Edit"
                                onClick={() => {
                                    updateDealStatus(editDeal, 'Draft')
                                }}
                            />
                        </div>
                    </div>
                </Popup>
            )
            }


            {FilterPopup &&
                <Filter
                    onFilterChange={onFilterChange}
                    filterData={filterData}
                    onSubmit={onSubmitFilter}
                    clearFilter={clearFilter}
                    setFilterPopup={setFilterPopup}
                    FilterPopup={FilterPopup}
                />
            }
        </>
    );
};

export default Deals;