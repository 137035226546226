import Svgs from "../../../../../Assets/svgs";
import React, { useEffect, useState } from "react";
import useSetDataInLocalStorage from "../../../../../Hooks/useSetDataInLocalStorage";
import LoginInput from "../../../Elements/LoginInput";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddRewardCard = ({
  data,
  onDelete,
  is_edit,
  index,
  onUpdate,
  onEditClick,
  ...props
}) => {
  const state = useSelector((state) => state);
  const [rewardData, setrewardData] = useState({});
  const [rewardError, setrewardError] = useState({});
  const [editId, seteditId] = useState();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  let business = businessData?.id;
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    setrewardData({
      ...data,
      service: data?.service?.id,
      product: data?.product?.id,
    });
  }, [editId]);

  const handlerewardData = (e) => {
    const { name, value } = e.target;
    setrewardData({
      ...rewardData,
      [name]: value,
    });
  };

  return (
    <>
      <div className="flex gap-6">
        <div className="relative">
          {index != 0 && (
            <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
          )}
          <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
            <div className="h-[1.8rem] w-[1.8rem] rounded-full  border border-primary flex items-center justify-center bg-white">
              <Svgs.Appointment_Bullet />
            </div>
          </div>
          <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-1/2 left-1/2 -translate-x-1/2"></div>
        </div>
        {editId == rewardData?.id ? (
          <>
            <div className="mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4">
              <LoginInput
                name={"name"}
                required={true}
                title="Reward Name"
                onEnterSubmit={() => {
                  onUpdate(rewardData);
                }}
                placeholder="Reward Name"
                value={rewardData?.name}
                error={rewardError?.name}
                onChange={(e) => {
                  handlerewardData(e);
                }}
                onBlur={() => {
                  if (!rewardData?.name) {
                    setrewardError({
                      ...rewardError,
                      name: true,
                    });
                  } else {
                    setrewardError({
                      ...rewardError,
                      name: false,
                    });
                  }
                }}
              />

              <Dropwdown
                required
                title="Choose Reward Type"
                placeholder="Reward Type"
                name="reward_type"
                value={rewardData?.reward_type}
                error={rewardError.reward_type}
                options={[
                  { label: "Service", value: "Service" },
                  { label: "Product", value: "Product" },
                ]}
                onChange={(e) => {
                  handlerewardData(e);
                }}
                onBlur={() => {
                  if (!rewardData?.reward_type) {
                    setrewardError({
                      ...rewardError,
                      reward_type: true,
                    });
                  } else {
                    setrewardError({
                      ...rewardError,
                      reward_type: false,
                    });
                  }
                }}
              />
              <div className=" ">
                {rewardData?.reward_type == "Service" ? (
                  <>
                    <Dropwdown
                      title="Services"
                      required={true}
                      placeholder="Select Service"
                      name="service"
                      value={rewardData?.service}
                      error={rewardError?.service}
                      onChange={(e) => {
                        handlerewardData(e);
                      }}
                      options={state?.service?.services?.map((itm) => {
                        return {
                          label: itm.name,
                          value: itm.id,
                        };
                      })}
                      onBlur={() => {
                        if (!rewardData?.service) {
                          setrewardError({
                            ...rewardError,
                            service: true,
                          });
                        } else {
                          setrewardError({
                            ...rewardError,
                            service: false,
                          });
                        }
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Dropwdown
                      title="Select Product"
                      required={true}
                      placeholder="Select Product"
                      name="product"
                      value={rewardData?.product}
                      error={rewardError?.product}
                      options={state?.product?.products?.map((itm) => {
                        return {
                          label: itm.name,
                          value: itm.id,
                        };
                      })}
                      onChange={(e) => {
                        handlerewardData(e);
                      }}
                      onBlur={() => {
                        if (!rewardData?.product) {
                          setrewardError({
                            ...rewardError,
                            product: true,
                          });
                        } else {
                          setrewardError({
                            ...rewardError,
                            product: false,
                          });
                        }
                      }}
                    />
                  </>
                )}
              </div>
              <div className="grid lg:grid-cols-2 gap-4 ">
                <LoginInput
                  required={true}
                  name="reward_value"
                  type="number"
                  onEnterSubmit={() => {
                    onUpdate(rewardData);
                  }}
                  title="Reward Value"
                  value={rewardData?.reward_value}
                  error={rewardError?.reward_value}
                  placeholder={`Enter Value`}
                  onChange={(e) => {
                    handlerewardData(e);
                  }}
                  onBlur={() => {
                    if (!rewardData?.reward_value) {
                      setrewardError({
                        ...rewardError,
                        reward_value: true,
                      });
                    } else {
                      setrewardError({
                        ...rewardError,
                        reward_value: false,
                      });
                    }
                  }}
                />
                <LoginInput
                  required={true}
                  name="reward_point"
                  type="number"
                  title="Reward Point"
                  onEnterSubmit={() => {
                    onUpdate(rewardData);
                  }}
                  value={rewardData?.reward_point}
                  error={rewardError?.reward_point}
                  placeholder={`Enter Points`}
                  onChange={(e) => {
                    handlerewardData(e);
                  }}
                  onBlur={() => {
                    if (!rewardData?.reward_point) {
                      setrewardError({
                        ...rewardError,
                        reward_point: true,
                      });
                    } else {
                      setrewardError({
                        ...rewardError,
                        reward_point: false,
                      });
                    }
                  }}
                />
              </div>
              <div className="grid lg:grid-cols-2 gap-4">
                {/* <p className='text-sm'>Discount Percentage <span class="text-red-600"> *</span></p> */}
                <LoginInput
                  required
                  title="Total Points"
                  type="number"
                  placeholder="Enter total points"
                  name="total_points"
                  onEnterSubmit={() => {
                    onUpdate(rewardData);
                  }}
                  value={rewardData?.total_points}
                  error={rewardError?.total_points}
                  onChange={(e) => {
                    handlerewardData(e);
                  }}
                  onBlur={() => {
                    if (!rewardData?.total_points) {
                      setrewardError({
                        ...rewardError,
                        total_points: true,
                      });
                    } else {
                      setrewardError({
                        ...rewardError,
                        total_points: false,
                      });
                    }
                  }}
                />
                <LoginInput
                  placeholder="Ex 50%"
                  title="Discount Percentage "
                  name="discount"
                  value={rewardData?.discount}
                  onEnterSubmit={() => {
                    onUpdate(rewardData);
                  }}
                  error={rewardError?.discount}
                  onChange={(e) => {
                    handlerewardData(e);
                  }}
                  onBlur={() => {
                    if (!rewardData?.discount) {
                      setrewardError({
                        ...rewardError,
                        discount: true,
                      });
                    } else {
                      setrewardError({
                        ...rewardError,
                        discount: false,
                      });
                    }
                  }}
                />
              </div>
              <LoginBtn
                text="Submit"
                onClick={() => {
                  onUpdate(rewardData);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="mt-[2rem] rounded-md w-full flex flex-col gap-4">
              <div className="reward-gradient-1 rounded-lg px-6 py-4">
                <div className="flex gap-2 flex-col">
                  <div className="flex items-center justify-between gap-4">
                    <h1 className="text-white font-bold text-2xl">
                      {data?.name}
                    </h1>
                    <div className="flex items-center gap-3">
                      {!employee ||
                        (employee &&
                          employeePermissions?.client_reward?.includes(
                            "edit"
                          )) ? (
                        <div
                          className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                          onClick={() => {
                            onEditClick(data?.id);
                            seteditId(data?.id);
                          }}
                        >
                          <Svgs.Edit fill="#fff" />
                        </div>
                      ) : (
                        ""
                      )}
                      {!employee ? (
                        <div
                          className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                          onClick={() => {
                            onDelete(data?.id);
                            // setDelete(true)
                          }}
                        >
                          <Svgs.Delete fill="#FFF" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="text-white text-lg">
                    <p className="">
                      <span className="text-2xl font-semibold">
                        {data?.discount}%
                      </span>
                      <span> off on </span>
                      {data?.product
                        ? data?.product?.name
                        : data?.service?.name}
                    </p>

                    <p className="text-sm text-right">
                      Redeem for {data?.total_points} Pts
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

AddRewardCard.defaultProps = {
  data: {},
  index: undefined,
  onEditClick: () => { },
  onDelete: () => { },
  onUpdate: () => { },
};

export default AddRewardCard;

// import { useDispatch } from 'react-redux'
// import { toast } from 'react-toastify'
// import { deleteReward } from '../../../../../Adapters/Api/clients'
// import Svgs from '../../../../../Assets/svgs'
// import useSetDataInLocalStorage from '../../../../../Hooks/useSetDataInLocalStorage'
// import { removeReward } from '../../../../../Redux/TenantRedux/Actions/clientActions'
// import Dropwdown from '../../../Elements/Dropwdown'
// import InputSection from '../../../Elements/InputSection'
// import LoginBtn from '../../../Elements/LoginBtn'
// import Popup from '../../../Elements/Popup'
// import LoginInput from './../../../Elements/LoginInput';

// // const AddRewardCard = ({ onUpdate, data, RewardData, setRewards, setErrors, errors, onBlurHandler, domeOptions, rewardaTypesOptions, serviceOptions, productOptions }) => {
// const AddRewardCard = ({ RewardData, data, edit, reduxService, productRedux, setrewardId, deleteModelHandler, rewardEditHandler, rewardsHandleChange, errors, onBlurHandler, loading, rewardaTypesOptions, serviceOptions, productOptions, onUpdateHandler }) => {

//     const { name, reward_value, reward_point, total_points, discount, reward_type, service, product } = RewardData;
//     return (
//         <>
//             <div className='flex gap-6'>
//                 <div className='relative'>
//                     {/* <div className='absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2'></div> */}
//                     <div className='flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 '>
//                         <div className='h-[1.8rem] w-[1.8rem] rounded-full  border border-primary flex items-center justify-center bg-white'>
//                             <Svgs.Appointment_Bullet />
//                         </div>
//                     </div>
//                     <div className='absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-1/2 left-1/2 -translate-x-1/2'></div>
//                 </div>
//                 {
//                     edit ?

//                         <div className='mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4'>
//                             <LoginInput
//                                 required={true}
//                                 name={"name"}
//                                 value={name}
//                                 onChange={rewardsHandleChange}
//                                 onBlur={() => onBlurHandler({ name: name })}
//                                 error={errors?.name}
//                                 title='Reward Name'
//                                 placeholder='Reward Name'
//                             />

//                             <Dropwdown
//                                 required={true}
//                                 name={"reward_type"}
//                                 value={reward_type}
//                                 onChange={rewardsHandleChange}
//                                 onBlur={() => onBlurHandler({ reward_type: reward_type })}
//                                 error={errors?.reward_type}
//                                 options={rewardaTypesOptions}
//                                 title='Choose Reward Type'
//                                 placeholder='Reward Type'
//                             />

//                             {reward_type == "Service" ?
//                                 <Dropwdown
//                                     required={true}
//                                     name={"service"}
//                                     value={service}
//                                     onBlur={() => onBlurHandler({ service: service })}
//                                     error={errors?.service}
//                                     options={reduxService && reduxService?.map((service) => {
//                                         return {
//                                             label: service.name,
//                                             value: service.id,
//                                         }
//                                     })}
//                                     onChange={rewardsHandleChange}
//                                     title='Select Service'
//                                     placeholder='Select Service'
//                                 /> : <Dropwdown
//                                     required={true}
//                                     name={"product"}
//                                     value={product}
//                                     onBlur={() => onBlurHandler({ product: product })}
//                                     error={errors?.product}
//                                     options={productRedux && productRedux?.map((product) => {
//                                         return {
//                                             label: product.name,
//                                             value: product.id,
//                                         }
//                                     })}
//                                     onChange={rewardsHandleChange}
//                                     title='Select product'
//                                     placeholder='Select product'
//                                 />
//                             }
//                             <div className='flex flex-col gap-2'>
//                                 <p className='text-sm'>Reward Value / points <span class="text-red-600"> *</span></p>
//                                 <InputSection
//                                     required={true}
//                                     name={"reward_value"}
//                                     value={reward_value}
//                                     onChange={rewardsHandleChange}
//                                     onBlur={() => onBlurHandler({ reward_value: reward_value })}
//                                     error={errors?.reward_value}
//                                     placeholder='10'
//                                     leftText={<p className='px-2'>Value</p>}

//                                 />

//                                 <InputSection
//                                     required={true}
//                                     name={"reward_point"}
//                                     value={reward_point}
//                                     onChange={rewardsHandleChange}
//                                     onBlur={() => onBlurHandler({ reward_point: reward_point })}
//                                     error={errors?.reward_point}
//                                     placeholder='10'
//                                     leftText={<p className='px-2'>Points</p>
//                                     } />
//                             </div>

//                             <div className='grid lg:grid-cols-2 gap-4'>
//                                 <LoginInput
//                                     required={true}
//                                     name={"total_points"}
//                                     value={total_points}
//                                     onChange={rewardsHandleChange}
//                                     onBlur={() => onBlurHandler({ total_points: total_points })}
//                                     error={errors?.total_points}
//                                     title='Total Reward points '
//                                     placeholder='Select Total Reward points '
//                                 />

//                                 <div className='flex flex-col gap-2'>
//                                     <p className='text-sm'>Discount Percentage<span class="text-red-600"> *</span></p>
//                                     <InputSection
//                                         required={true}
//                                         name={"discount"}
//                                         value={discount}
//                                         onChange={rewardsHandleChange}
//                                         onBlur={() => onBlurHandler({ discount: discount })}
//                                         error={errors?.discount}
//                                         placeholder='Ex 50%'
//                                         leftText={<p className='px-2'>%</p>}
//                                     />
//                                 </div>
//                                 <LoginBtn
//                                     loading={loading}
//                                     onClick={onUpdateHandler}
//                                     text='Update Reward'
//                                 />

//                             </div>
//                         </div>
//                         :
//                         <div className='mt-[2rem] rounded-md w-full flex flex-col gap-4'>
//                             <div className='reward-gradient-1 rounded-lg px-6 py-4'>
//                                 <div className="flex gap-2 flex-col">
//                                     <div className='flex items-center justify-between gap-4'>
//                                         <h1 className='text-white font-bold text-2xl'>{data?.name}</h1>
//                                         <div className='flex items-center gap-3'>
//                                             <div
//                                                 onClick={() => {
//                                                     rewardEditHandler(data?.id)
//                                                 }}
//                                                 className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center'>
//                                                 <Svgs.Edit fill='#fff' />
//                                             </div>
//                                             <div
//                                                 onClick={() => {
//                                                     setrewardId(data?.id)
//                                                     deleteModelHandler()
//                                                 }
//                                                 }
//                                                 className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center'>
//                                                 <Svgs.Delete fill='#fff' />
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className='text-white text-lg'>
//                                         <p className=''><span className='text-2xl font-semibold'>{data?.discount}%</span> off on {' '}
//                                         {data?.product ? data?.product?.name : data?.service?.name}</p>
//                                         <p className='text-sm text-right'>Redeem for {data?.total_points} Pts</p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                 }
//             </div>
//         </>
//     )
// }

// AddRewardCard.defaultProps = {
//     data: {},
//     index: undefined,
//     onUpdate: () => { },
//     onDelete: () => { },
// }

// export default AddRewardCard
