import React from 'react'

const AppointmentDetailShimmer = ( {onlyView} ) => {
    return (
        <>
            <div className="rounded-md appointment-shadow p-4 animate-pulse">
                <div className="flex items-center justify-between md:gap-0 gap-3 flex-wrap">
                    <div className="flex flex-col gap-2 w-3/5">
                        <div className="py-5 w-3/5 rounded-lg bg-gray-200"></div>
                        <div className="py-3 w-4/5 rounded-lg my-1 bg-gray-200"></div>
                        <div className="py-3 w-2/5 rounded-lg bg-gray-200"></div>
                    </div>
                    <div className="flex justify-end w-2/5">
                        <div className="py-5 w-4/5 rounded-lg my-2 bg-gray-200"></div>
                    </div>
                </div>
                <hr className="my-[1.4rem]" />
                <div className="flex items-end justify-between gap-5">
                    <div className="grid grid-cols-2 w-full gap-6">
                        <div className="flex flex-col gap-1">
                            <div className="py-3 w-32 rounded-lg my-0.5 bg-gray-200"></div>
                            <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className="py-3 w-32 rounded-lg my-0.5 bg-gray-200"></div>
                            <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className="py-3 w-32 rounded-lg my-0.5 bg-gray-200"></div>
                            <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className="py-3 w-32 rounded-lg my-0.5 bg-gray-200"></div>
                            <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                        </div>
                        {/* <div className="flex flex-col gap-1">
                            <div className="py-3 w-32 rounded-lg my-0.5 bg-gray-200"></div>
                            <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                        </div>
                        <div className="flex flex-col gap-1">
                            <div className="py-3 w-32 rounded-lg my-0.5 bg-gray-200"></div>
                            <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                        </div> */}
                    </div>
                    {/* <div className="flex items-center justify-between flex-wrap gap-3">
                        <div className="flex items-center gap-6">
                            <div className="flex flex-col gap-1">
                                <div className="py-3 w-32 rounded-lg my-0.5 bg-gray-200"></div>
                                <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                            </div>
                            <div className="flex flex-col gap-1">
                                <div className="py-3 w-32 rounded-lg my-0.5 bg-gray-200"></div>
                                <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                            </div>
                        </div>
                    </div> */}
                    <div className="flex items-center justify-end gap-3 w-1/2">
                        <div className="py-5 w-20 rounded-lg my-2 bg-gray-200"></div>
                        <div className="py-5 w-28 rounded-lg my-2 bg-gray-200"></div>
                        {/* <div className="py-5 w-36 rounded-lg my-2 bg-gray-200"></div> */}
                    </div>
                </div>
            </div>

            {onlyView ? '' : <div className="rounded-md appointment-shadow p-4 animate-pulse">
                <div className="flex items-start gap-4">
                    <div className="h-16 w-12 rounded-lg my-2 bg-gray-200"></div>
                    <div className="flex flex-col gap-1 w-4/5">
                        <div className="py-3 w-2/6 rounded-lg my-2 bg-gray-300"></div>
                        <div className="py-3 w-1/2 rounded-lg bg-gray-200"></div>
                    </div>
                </div>
            </div>}

           {onlyView ? '' : <div className="py-4 w-1/4 rounded-lg bg-gray-200"></div>}
        </>
    )
}

export default AppointmentDetailShimmer