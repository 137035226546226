import React from 'react'

export const LogoSvg = (props) => {

    return (
        <>
            <svg width="4rem" height="4rem" viewBox="0 0 68 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_51_4)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M38.9616 0C44.1843 2.38607 45.9252 5.74041 45.4378 9.26764C44.6718 12.5874 41.3641 15.5268 38.7875 17.325C33.3907 21.0943 25.5914 24.3794 18.9411 22.3046C13.7532 20.6793 14.9022 14.8006 14.7977 15.423C14.3103 17.9128 14.8674 20.6793 16.9217 22.3738C18.767 23.8953 21.9355 24.3103 24.2683 24.3103C26.3574 24.3103 28.4813 23.9299 30.5356 23.4112C29.2473 24.0682 27.9242 24.6907 26.5663 25.2094C21.065 27.2842 16.469 26.4197 11.7686 27.5955C3.13364 29.7395 0 37.8314 0 37.8314C0 37.8314 3.86482 29.6703 19.6027 30.0853C19.7071 30.0853 20.0553 30.1198 20.1946 30.1198C14.0317 34.2004 10.6196 40.0445 16.5038 52.9777C15.2852 34.235 33.2166 32.7826 42.9309 27.4917C57.7286 19.469 58.7732 3.73472 38.9616 0Z" className={`${props.fill ? props.fill : 'fill-primary'}`} />
                    <path fillRule="evenodd" clipRule="evenodd" d="M59.3999 40.8399C62.0809 42.4306 64.1004 42.8456 68 42.6727L66.6421 41.9465C64.7967 41.1511 64.4834 40.9436 62.8121 39.7679C57.3108 35.6873 57.8679 27.0767 57.4501 20.9214C57.1716 17.4287 56.7537 14.1781 55.6047 11.3079C55.2914 10.5471 54.2468 9.02557 54.2468 9.02557C55.9181 14.6622 55.7788 17.7745 52.4711 24.5523C48.85 31.9872 55.6047 36.0677 50.5909 36.7593C47.1439 37.2435 48.084 37.8314 48.2581 39.6296C48.3625 40.8399 47.5965 40.909 46.2386 41.0474C46.4475 43.3297 46.5868 43.0185 44.6022 43.3297C42.5131 43.6409 44.4629 48.4476 40.2151 47.514C37.5689 46.9261 36.0021 44.3671 32.3113 44.4363C23.2586 53.3235 37.9171 59.1331 43.3487 72.1701C44.254 66.6717 37.5689 58.9256 45.9253 53.6348C43.488 60.2397 43.1398 68.2624 49.4419 73.0346C48.9544 71.3055 48.0144 69.6111 47.8054 67.4671C47.4921 64.4931 47.7358 61.0696 48.6063 58.3032C52.5407 45.3354 47.318 66.3259 61.106 68.6428C58.2161 65.5651 56.5796 63.8361 56.893 58.4069C57.4153 49.4159 57.2412 48.6551 55.6744 44.7821C56.0574 45.7158 59.574 47.7215 61.8024 51.802C64.3789 56.5396 63.1255 58.234 62.638 62.937C69.8802 48.4131 59.1214 46.6494 59.3999 40.8399Z" className={`${props.fill ? props.fill : 'fill-primary'}`} />
                    <path fillRule="evenodd" clipRule="evenodd" d="M26.4967 5.04878C26.6011 4.94504 28.2376 6.74324 29.7348 9.61344C29.7348 9.57886 29.7348 9.54428 29.7348 9.54428C30.7445 3.00852 22.6667 0.691607 20.3339 0.311218C23.9201 3.18142 24.2335 12.5182 29.3866 10.9275C28.2376 8.57602 27.0538 6.01704 26.4967 5.04878Z" className={`${props.fill ? props.fill : 'fill-primary'}`} />
                    <path fillRule="evenodd" clipRule="evenodd" d="M34.2612 7.53862C33.3907 8.43772 32.7292 9.09475 31.7194 11.4808C31.7194 11.4462 31.7194 11.4462 31.7194 11.4117C30.6749 5.98248 38.2304 3.94222 39.5883 3.80389C37.1859 6.29371 34.9923 15.0426 32.1024 12.5874C32.5551 10.3397 33.6692 8.50688 34.2612 7.53862Z" className={`${props.fill ? props.fill : 'fill-primary'}`} />
                    <path fillRule="evenodd" clipRule="evenodd" d="M24.3727 14.2818C25.7307 13.694 26.7752 13.2444 29.9088 13.1407C29.874 13.1407 29.874 13.1061 29.8392 13.1061C24.1986 9.61344 18.6974 17.1175 17.9662 18.5699C21.7962 16.9791 33.2862 17.6016 30.9882 14.0398C28.2376 13.5902 25.6958 14.0398 24.3727 14.2818Z" className={`${props.fill ? props.fill : 'fill-primary'}`} />
                    <path className={`${props.fill ? props.fill : 'fill-primary'}`} fillRule="evenodd" clipRule="evenodd" d="M2.64618 24.6561C2.681 24.5524 4.63082 24.3795 5.46646 23.7224C6.54582 23.1 5.29236 21.9588 3.20327 23.9645C2.99436 24.172 2.82027 24.414 2.64618 24.6561Z" />
                    <path className={`${props.fill ? props.fill : 'fill-primary'}`} fillRule="evenodd" clipRule="evenodd" d="M4.17816 19.3307C6.26726 19.0886 7.20735 17.982 9.05271 17.8091C11.2114 17.6016 11.6989 21.1634 6.71989 20.1606C5.60571 19.9531 4.66562 19.5036 4.17816 19.3307Z" />
                </g>
                <defs>
                    <clipPath id="clip0_51_4">
                        <rect width="68" height="73" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
