import Svgs from "../../../../../Assets/svgs"


const RewardsCheckoutCard = (data) => {
    return (
        <>
            <h2 className='text-lg font-bold'>Rewards</h2>
            <div className='border border-[#EDEDED] rounded-lg p-4 flex flex-col gap-4 text-sm bg-[#FCFDFE]'>
                <div className='bg-[#FFE5A3] py-4 px-6 rounded-xl flex items-center gap-4'>
                    <Svgs.Trophy />
                    <div className='flex flex-col gap-1'>
                        <h2 className=''><span className='text-[#B84E13] text-2xl font-bold'>2,2500</span>  Reward Points</h2>
                        <p className='text-[#494949]'>Lorem Ipsum is the dummy text</p>
                    </div>
                </div>
                <h2 className='text-lg font-semibold'>My Rewards:</h2>
                <div className='grid lg:grid-cols-2 gap-6'>
                    <div className='flex flex-col gap-3'>
                        <div className='rounded-lg p-5 reward-gradient-1 text-white text-center'>
                            <h2 className='text-lg font-semibold'>15% off on Hair Style</h2>
                        </div>
                        <div className='flex items-center justify-between text-[#5679FF]'>
                            <div className='flex items-center gap-2'>
                                <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.7931 6.69147H3.60219V4.37441C3.60219 3.17593 4.40118 2.13725 5.51976 1.89755C7.03783 1.65786 8.39611 2.77644 8.39611 4.29451C8.39611 4.7739 8.7956 5.0935 9.19509 5.0935C9.59458 5.0935 9.99408 4.7739 9.99408 4.29451C9.99408 1.97745 7.99661 0.059888 5.59966 0.299584C3.52229 0.539279 2.00422 2.37695 2.00422 4.45431V6.69147H1.20524C0.805743 6.69147 0.40625 7.01106 0.40625 7.49045V15.4803C0.40625 15.8798 0.805743 16.2793 1.20524 16.2793H10.7931C11.2725 16.2793 11.592 15.8798 11.592 15.4803V7.49045C11.592 7.01106 11.2725 6.69147 10.7931 6.69147ZM6.79813 12.0447V13.0834C6.79813 13.5627 6.47854 13.8823 5.99915 13.8823C5.51976 13.8823 5.20016 13.5627 5.20016 13.0834V12.0447C4.72077 11.7251 4.40118 11.2457 4.40118 10.6864C4.40118 9.64772 5.35996 8.92863 6.39864 9.16832C6.95793 9.32812 7.43732 9.80751 7.59712 10.3668C7.75692 11.0859 7.35742 11.7251 6.79813 12.0447Z" fill="#5679FF" />
                                </svg>
                                <p className='font-semibold'>Reward Unlocked</p>
                            </div>
                            <button className='text-primary border-2 border-primary px-3 py-[0.39rem] text-sm rounded-md'>Redeem for 200</button>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <div className='rounded-lg p-5 reward-gradient-2 text-white text-center'>
                            <h2 className='text-lg font-semibold'>20% off on Hair Style</h2>
                        </div>
                        <div className='flex items-center gap-3 text-[#5679FF]'>
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6667 5.7207H10.6667V4.38736C10.6667 1.81414 8.57325 -0.279297 6 -0.279297C3.42675 -0.279297 1.33334 1.81414 1.33334 4.38736V5.7207H0.333344C0.149094 5.7207 0 5.8698 0 6.05405V14.3874C0 15.1227 0.597969 15.7207 1.33334 15.7207H10.6667C11.402 15.7207 12 15.1227 12 14.3874V6.05405C12 5.8698 11.8509 5.7207 11.6667 5.7207ZM6.99806 12.6839C7.00847 12.778 6.97822 12.8724 6.91506 12.943C6.85191 13.0137 6.76141 13.054 6.66669 13.054H5.33334C5.23863 13.054 5.14812 13.0137 5.08497 12.943C5.02181 12.8724 4.99153 12.778 5.00197 12.6839L5.21225 10.793C4.87078 10.5446 4.66669 10.1517 4.66669 9.7207C4.66669 8.98536 5.26466 8.38736 6.00003 8.38736C6.73541 8.38736 7.33337 8.98533 7.33337 9.7207C7.33337 10.1517 7.12928 10.5446 6.78781 10.793L6.99806 12.6839ZM8.66666 5.7207H3.33334V4.38736C3.33334 2.91698 4.52962 1.7207 6 1.7207C7.47038 1.7207 8.66666 2.91698 8.66666 4.38736V5.7207Z" fill="#7B7B7B" />
                            </svg>
                            <div className='flex flex-col gap-1 flex-1'>
                                <p className='text-xs'>Reward Locked</p>
                                <div className="w-full bg-[#D9D9D9] h-1 rounded-md overflow-hidden">
                                    <div className="bg-primary h-1 w-[45%]"></div>
                                </div>
                                <div className='flex items-center justify-between gap-4 text-xs text-[#7B7B7B]'>
                                    <p>1200/5000</p>
                                    <p>3800 pts remaining</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <div className='rounded-lg p-5 reward-gradient-3 text-white text-center'>
                            <h2 className='text-lg font-semibold'>20% off on Hair Style</h2>
                        </div>
                        <div className='flex items-center gap-3 text-[#5679FF]'>
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6667 5.7207H10.6667V4.38736C10.6667 1.81414 8.57325 -0.279297 6 -0.279297C3.42675 -0.279297 1.33334 1.81414 1.33334 4.38736V5.7207H0.333344C0.149094 5.7207 0 5.8698 0 6.05405V14.3874C0 15.1227 0.597969 15.7207 1.33334 15.7207H10.6667C11.402 15.7207 12 15.1227 12 14.3874V6.05405C12 5.8698 11.8509 5.7207 11.6667 5.7207ZM6.99806 12.6839C7.00847 12.778 6.97822 12.8724 6.91506 12.943C6.85191 13.0137 6.76141 13.054 6.66669 13.054H5.33334C5.23863 13.054 5.14812 13.0137 5.08497 12.943C5.02181 12.8724 4.99153 12.778 5.00197 12.6839L5.21225 10.793C4.87078 10.5446 4.66669 10.1517 4.66669 9.7207C4.66669 8.98536 5.26466 8.38736 6.00003 8.38736C6.73541 8.38736 7.33337 8.98533 7.33337 9.7207C7.33337 10.1517 7.12928 10.5446 6.78781 10.793L6.99806 12.6839ZM8.66666 5.7207H3.33334V4.38736C3.33334 2.91698 4.52962 1.7207 6 1.7207C7.47038 1.7207 8.66666 2.91698 8.66666 4.38736V5.7207Z" fill="#7B7B7B" />
                            </svg>
                            <div className='flex flex-col gap-1 flex-1'>
                                <p className='text-xs'>Reward Locked</p>
                                <div className="w-full bg-[#D9D9D9] h-1 rounded-md overflow-hidden">
                                    <div className="bg-primary h-1 w-[45%]"></div>
                                </div>
                                <div className='flex items-center justify-between gap-4 text-xs text-[#7B7B7B]'>
                                    <p>1200/5000</p>
                                    <p>3800 pts remaining</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RewardsCheckoutCard