import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import React from 'react'
import PlacesAutocomplete, {
    geocodeByAddress,
} from "react-places-autocomplete";

const LocationStep = ({ handleChange, formData, setFormData, currencyList, errors }) => {

    const handleAddressSuggetion = async (value) => {
        const result = await geocodeByAddress(value);
        setFormData((prev) => ({
            ...prev,
            location: {
                ...prev.location,
                business_address: value
            },
        }));
    };

    const handleLocationChange = (e, fieldName = "") => {
        if (e?.target) {
            const { name, value } = e?.target;
            setFormData((prev) => ({
                ...prev,
                location: {
                    ...prev.location,
                    [name]: value,
                },
            }));
        }
    };

    return (
        <div className='my-[2rem] flex flex-col gap-4'>
            <h1 className='font-semibold text-xl'>Add Location</h1>
            <div>
                <LoginInput
                    required={true}
                    value={formData?.location?.name}
                    name={'name'}
                    onChange={(e) => { handleChange(e, 'location') }}
                    title={'Location Name'}
                    // charactersMaxLength={25}
                    placeholder={'Enter Location Name'}
                    // onBlur={() => onBlurHandler({ name: formData?.location?.name })}
                    error={(!formData?.location?.name && errors?.name) && errors?.name}
                />
            </div>
            <div>
                <PlacesAutocomplete
                    value={formData?.location?.business_address}
                    onChange={(value) => { handleLocationChange({ target: { name: 'business_address', value: value } }) }}
                    onSelect={handleAddressSuggetion}
                    error={(!formData?.location?.business_address && errors?.address_name) && errors?.address_name}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                    }) => (
                        <div>
                            <LoginInput
                                {...getInputProps()}
                                required={true}
                                title="Address"
                                placeholder="Enter Business Address"
                                error={(!formData?.location?.business_address && errors?.address_name) && errors?.address_name}
                            />

                            {suggestions.length > 0 && (
                                <div className="autocomplete-dropdown-container border rounded-md mt-2">
                                    {loading && (
                                        <div className="rounded-md p-2 text-xs text-[#555555]">
                                            Loading...
                                        </div>
                                    )}
                                    {suggestions.map((suggestion) => {
                                        const className =
                                            "rounded-md p-2 text-xs hover:!bg-gray-100 text-[#555555]";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
            <div>
                <Dropwdown
                    required={true}
                    error={(errors?.currency && !formData?.location?.currency) && errors?.currency}
                    options={currencyList?.map((itm) => {
                        return {
                            label: itm?.code,
                            value: itm?.id,
                        };
                    })}
                    name={"currency"}
                    value={formData?.location?.currency}
                    onChange={(e) => { handleChange(e, 'location') }}
                    title={'Currency'}
                    placeholder={'Select Currency'} />
            </div>
            {/* <div>
                <LoginInput
                    required={true}
                    value={formData?.location?.email}
                    name={'email'}
                    onChange={(e) => { handleChange(e, 'location') }}
                    title={'Email Address'}
                    placeholder={'Enter Email Address'}
                    disabled={formData?.location?.email ? true : false}
                />
            </div> */}
        </div>
    )
}

export default LocationStep