import React, { useState } from 'react'
import TableShimer from 'Components/Utility/Shimmers/TableShimer'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import Textarea from 'Components/TenantApp/Elements/Textarea';
import Popup from 'Components/TenantApp/Elements/Popup';
import NotFound from 'Components/TenantApp/Dashboard/Sales/Elements/NotFound';
import Pagination from 'new-components/Pagination';
import moment from 'moment';
import FeedbackPopup from '../Popups/FeedbackPopup';

const Student = ({ translation, selectedLanguage, data, pagination, loading, currentPage, setCurrentPage, onSuccessCall }) => {

  const [trainingData, setTrainingData] = useState()
  const [feedbackPopup, setFeedbackPopup] = useState()

  return (
    <div className="border rounded-lg">
      <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b sm:flex-row flex-col gap-3">
        <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2 ">
            <h2 className="font-semibold text-xl">
              {selectedLanguage?.name == "English" ? "Employee Training Requests" : translation[1706]?.value ? translation[1706]?.value : "Employee Training Requests"}
            </h2>
            <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
              <p className="text-primary">
                {pagination?.count ? pagination?.count : 0}
                {" "}
                {pagination?.count && pagination?.count > 1
                  ? selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                  : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                }
              </p>
            </div>
          </div>
        </div>

      </div>
      {loading ?
        <TableShimer cols={5} />
        :
        <div className="overflow-x-auto">
          <div className="inline-grid xl:grid grid-cols-[repeat(16,minmax(155px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
            <p>
              {selectedLanguage?.name == "English" ? "Sr. No" : translation[1679]?.value ? translation[1679]?.value : "Sr. No"}
            </p>
            <p>
              {selectedLanguage?.name == "English" ? "Requester" : translation[1687]?.value ? translation[1687]?.value : "Requester"}
            </p>
            <p>
              {selectedLanguage?.name == "English" ? "Trainer" : translation[1321]?.value ? translation[1321]?.value : "Trainer"}
            </p>
            <p>
              {selectedLanguage?.name == "English" ? "Training Location" : translation[1321]?.value ? translation[1321]?.value : "Training Location"}
            </p>
            <p>
              {selectedLanguage?.name == "English" ? "Requested Date" : translation[417]?.value ? translation[417]?.value : "Requested Date"}
            </p>
            <p>
              {selectedLanguage?.name == "English" ? "Accepted Date" : translation[417]?.value ? translation[417]?.value : "Accepted Date"}
            </p>
            <p>
              {selectedLanguage?.name == "English" ? "Desired Skill" : translation[417]?.value ? translation[417]?.value : "Desired Skill"}
            </p>
            <p className="col-span-2">
              {selectedLanguage?.name == "English" ? "Requester Comments" : translation[1685]?.value ? translation[1685]?.value : "Requester Comments"}
            </p>
            <p className="col-span-2">
              {selectedLanguage?.name == "English" ? "Trainer Comments" : translation[1685]?.value ? translation[1685]?.value : "Trainer Comments"}
            </p>
            <p className="col-span-2">
              {selectedLanguage?.name == "English" ? "Trainer Recomendations" : translation[1685]?.value ? translation[1685]?.value : " Trainer Recomendations"}
            </p>
            <p className="col-span-2">
              {selectedLanguage?.name == "English" ? "Manager Comments" : translation[1685]?.value ? translation[1685]?.value : "Manager Comments"}
            </p>
            <p className="text-center">{selectedLanguage?.name == "English" ? "Status" : translation[1366]?.value ? translation[1366]?.value : "Status"}</p>
          </div>
          {data?.length > 0 ? data?.map((itm, i) => {
            return (
              <div className={`odd-bg text-[#3A3A3A] transition-all min-w-full w-fit`}>
                <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(16,minmax(155px,1fr))] min-w-full w-fit">
                  <div className="h-full flex items-center">
                    <p className="text-sm">
                      {itm?.id ? itm?.id?.substring(0, 6)?.toUpperCase() : '---------'}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-sm">
                      {itm?.student_name ? itm?.student_name : '---------'}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-sm">
                      {itm?.teacher_name ? itm?.teacher_name : '---------'}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-sm">
                      {itm?.location_name ? itm?.location_name : '---------'}
                    </p>
                  </div>

                  <div className="flex items-center">
                    <p className="text-sm line-clamp-1 pr-3">
                      {itm?.date_requested ? moment(itm?.date_requested).format(' DD MMM yyyy ') : '---------'}
                    </p>
                  </div>

                  <div className="flex items-center">
                    <p className="text-sm line-clamp-1 pr-3">
                      {itm?.date_offered ? moment(itm?.date_offered).format(' DD MMM yyyy ') : '---------'}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-sm line-clamp-1 pr-3">
                      {itm?.skill_name ? itm?.skill_name : '---------'}
                    </p>
                  </div>

                  <div className="flex items-center col-span-2">
                    <p className="text-sm line-clamp-2 pr-3">
                      {itm?.teacher_comment ? itm?.teacher_comment : '---------'}
                    </p>
                  </div>
                  <div className="flex items-center col-span-2">
                    <p className="text-sm line-clamp-2 pr-3">
                      {itm?.student_comment ? itm?.student_comment : '---------'}
                    </p>
                  </div>
                  <div className="flex items-center col-span-2">
                    <p className="text-sm line-clamp-2 pr-3">
                      {itm?.teacher_recommendation ? itm?.teacher_recommendation : '---------'}
                    </p>
                  </div>
                  <div className="flex items-center col-span-2">
                    <p className="text-sm line-clamp-2 pr-3">
                      {itm?.manager_feedback ? itm?.manager_feedback : '---------'}
                    </p>
                  </div>
                  <div className="flex items-center justify-center gap-3">
                    {itm?.is_completed
                      ? <LoginBtn
                        className={'!capitalize'}
                        onClick={() => {
                          setTrainingData(itm)
                          setFeedbackPopup(true)
                        }}
                        disabled={itm?.manager_feedback}
                        text={selectedLanguage?.name == "English" ? "Feedback" : translation[1492]?.value ? translation[1492]?.value : "Feedback"}
                      />
                      : <LoginBtn
                        className={'!capitalize'}
                        newSuccessBtnFocus={true}
                        text={selectedLanguage?.name == "English" ? "Pending" : translation[1492]?.value ? translation[1492]?.value : "Pending"}
                      />
                    }
                  </div>
                </div>
              </div>
            )
          })
            :
            <NotFound />
          }
        </div>
      }

      {data?.length > 0 ?
        <Pagination
          currentPage={currentPage}
          totalCount={pagination?.total_pages}
          pageSize={10}
          onPageChange={page => setCurrentPage(page)}
        /> : ""}
      {feedbackPopup &&
        <FeedbackPopup
          open={feedbackPopup}
          close={setFeedbackPopup}
          data={trainingData}
          onSuccessCall={onSuccessCall}
        />
      }
    </div>
  )
}

export default Student