import React from "react";

const LoginCardBlock = ({
  login,
  onClick,
  active,
  width,
  heading,
  desc,
  children,
  
}) => {
  return (
    <div
      onClick={onClick && onClick}
      className={`${active
        ? "border-primary bg-[#F2F7F7]"
        : "shadow-login-card border-transparent"
        } ${login && '!py-2.5 !px-2.5 rounded-md border !gap-3'} hover:border-primary hover:bg-[#F2F7F7] border-2 cursor-pointer transition-all ease-in rounded-2xl p-5 flex items-center lg:justify-start justify-center lg:mx-0 md:mx-auto mx-0 gap-5 ${width ? width : "md:w-[75%] w-full"
        }`}
    >
      {children ? (
        children
      ) : (
        <p className="italic text-xs w-[10ch]">"Give icon as child prop"</p>
      )}
      <div>
        <h2>{heading}</h2>
        {desc && <p className="text-[#7B7B7B]">{desc}</p>}
      </div>
    </div>
  );
};

LoginCardBlock.defaultProps = {
  heading: 'Give "Heading" as a prop',
};

export default LoginCardBlock;
