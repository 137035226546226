import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DateCounter from "./CalendarElements/DateCounter";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import AddBlockTime, { BlockTimePopup } from "./Elements/AddBlockTime";
import { getLocations } from "Adapters/Api";
import { getCalendarEmployeeDropDownList } from "Adapters/Api/employee";
import {
  APPOINTMENT_TIME,
  CALENDAR_OPENING_TIME,
} from "Constants/Data/OpeningHours";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getCalendarMainAppointments, UpdateBlockTime } from "Adapters/Api/apointments";
import Svgs from "Assets/svgs";
import { apiErrorMessage } from "Constants/Data/Errors";
import { BASE_URL } from "Adapters/Api/api_variables";
import NoEmployeeDataOnCalendar from "Components/Utility/NoEmployeeDataOnCalendar";
import CalenderShimmer from "Components/Utility/Shimmers/CalenderShimmer";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import PaidUnpaidAppointment from "./PaidUnpaidAppointment/PaidUnpaidAppointment";
import MissedOpprtunity from "./MissedOpportunity";

import AppointmentDetailPopup from "./AppointmentDetailPopup";
import CalendarDNDHelper from "./CalendarDND.js";
import moment from "moment";
import CalendarRef from "./CalendarRef";

let closeDraging;
const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
const hasMouse = typeof window.matchMedia === 'function' && window.matchMedia('(pointer:fine)').matches;
const currentTime = moment();

const getClientStatusClass = (appointment) => {
  if (appointment?.status == 'Started') return 'yellow-bg-card';
  if (appointment?.status == 'Finished') return 'red-bg-card';
  if (appointment?.status == 'Void') return 'void-bg-card';
  if (appointment?.status == 'Booked') return 'green-bg-card';
  return 'gray-bg-card';
};
// fuction to change color of Walkin appointment card
const getWalkinStatusClass = (appointment) => {
  if (appointment?.status == 'Started') return 'yellow-bg-card';
  if (appointment?.status == 'Finished') return 'red-bg-card';
  if (appointment?.status == 'Void') return 'void-bg-card';
  if (appointment?.status == 'Booked') return 'brown-bg-card';
  return 'gray-bg-card';
};


const CalendarMain = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const { getDataFromLocalStorage, storeDataInLocalStorage } =
    useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  const selected_location = getDataFromLocalStorage("selected_location");
  const state = useSelector((state) => state);
  const location_currency = state?.utility?.currency?.location_currency;
  const reduxlocationsList = state?.locations?.locations;
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const FinalData = state.appointment.appointments;
  let employeeRedux = state?.employee?.employees
  // const employeeUpdate = state?.employee?.employee_updated
  // const businessCurrency = state?.business?.business;

  const [selectedLocation, setLocationSelection] = useState("");
  const [btn_loading, seBtnLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [getEmployeeLoader, setGetEmployeeLoader] = useState(true)
  const [appointmentLoader, setAppointmentLoader] = useState(true)
  const [updateAppointmentLoader, setUpdateAppointmentLoader] = useState(false)
  const [updateAppointmentData, setUpdateAppointmentData] = useState({})
  const [StartTime, setStartTime] = useState(CALENDAR_OPENING_TIME);
  const [ActiveDragStartTime, setActiveDragStartTime] = useState(null);
  const [SelectedDate, setSelectedDate] = useState(
    moment().format('YYYY-MM-DD')
  );
  const [AllEmployee, setAllEmployee] = useState([]);
  const [block_time_edit, setBlockTimeEdit] = useState(undefined);
  const [SelectedEmployee, setSelectedEmployee] = useState("All");
  const [currentDate, setCurrentDate] = useState()
  const [appointmentDetail, setAppointmentDetail] = useState(false)
  const [calendarTabMenu, setCalendarTabMenu] = useState(false)
  const [appGroupId, setAppGroupId] = useState('')
  const [paidUnpaidAppointmentpopup, setPaidUnpaidAppointmentPopup] = useState(false)
  const [missedOpprtunityPopup, setMissedOpprtunityPopup] = useState(false)
  const [BlockTime, setBlockTime] = useState(false);
  const [calendarHeight, setCalendarHeight] = useState(0);
  // Create a reference to the calendar container element
  const calendarContainerRef = useRef(null);
  // State to hold the calculated width of each column
  const [columnWidth, setColumnWidth] = useState(0);

  useEffect(() => {
    // Function to update the widths of the calendar columns
    const updateWidths = () => {
      if (calendarContainerRef.current) {
        // Get the total width of the calendar container
        const totalWidth = calendarContainerRef.current.scrollWidth;
        // Calculate the width of each column based on the number of employees
        const columnWidth = AllEmployee && AllEmployee.length ? totalWidth / AllEmployee.length : totalWidth;
        setColumnWidth(columnWidth);
      }
    };

    updateWidths();

    const resizeObserver = new ResizeObserver(() => {
      updateWidths();
    });

    if (calendarContainerRef.current) {
      resizeObserver.observe(calendarContainerRef.current);
    }

    return () => {
      if (calendarContainerRef.current) {
        resizeObserver.unobserve(calendarContainerRef.current);
      }
    };
  }, [FinalData, AllEmployee]);



  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  // set the height of Calendar based on the screen height
  useEffect(() => {
    const updateCalendarHeight = () => {
      const screenHeight = window.innerHeight;
      if (screenHeight >= 500 && screenHeight < 580) {
        setCalendarHeight('72vh');
      } else if (screenHeight >= 580 && screenHeight < 660) {
        setCalendarHeight('74vh');
      } else if (screenHeight >= 660 && screenHeight < 750) {
        setCalendarHeight('76vh');
      } else if (screenHeight >= 750) {
        setCalendarHeight('78vh');
      }
    };

    // Call the function initially and add event listener for window resize
    updateCalendarHeight();
    window.addEventListener('resize', updateCalendarHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateCalendarHeight);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("cancelled_appointment")) {
      localStorage.removeItem("cancelled_appointment")
    }
  }, [localStorage.getItem("cancelled_appointment")])

  // get business locations
  const getBusinessLocation = (business_id) => {
    dispatch(getLocations(business_id));
  };

  useEffect(() => {
    // dispatch(getEmployeeList('', '', '', setGetEmployeeLoader));
    if (selected_location) {
      dispatch(getCalendarEmployeeDropDownList(selected_location, setGetEmployeeLoader));
    }
  }, [selected_location])

  useEffect(() => {
    if (businessData?.id) {
      getBusinessLocation(businessData?.id);
    }

  }, [businessData?.id]);

  useEffect(() => {
    if (SelectedEmployee === "All") {
      setAllEmployee(employeeRedux);
    } else {
      setAllEmployee(employeeRedux?.filter(itm => itm?.id == SelectedEmployee))
    }
  }, [employeeRedux, employeeRedux?.length, SelectedEmployee]);

  useEffect(() => {
    if (!getEmployeeLoader && !appointmentLoader) {
      setTimeout(() => {
        // scroll into view when page is loaded start
        StartTime.map((time, index) => {

          let date = moment();
          let CurrentDate = {
            seconds: "00",
            // To round off the minutes into multiples of 5
            minutes: Math.ceil(date.minutes() / 5) * 5 == 60 ? 0 : Math.ceil(date.minutes() / 5) * 5,
            hours: date.hours(),
          };

          // Format the new Date Obj
          let date2 = moment(`${time.label}`, "hh:mm A");
          let BlockDate = {
            seconds: "00",
            // To round off the minutes into multiples of 5
            minutes: Math.ceil(date2.minutes() / 5) * 5 == 60 ? 0 : Math.ceil(date2.minutes() / 5) * 5,
            hours: date2.hours(),
          };

          if (
            BlockDate.hours == CurrentDate.hours &&
            BlockDate.minutes == CurrentDate.minutes
          ) {

            // Get the left time with queryselector
            if (document.querySelector(`.block-${index == 0 || index < 10 ? index : index - 8}`)) {
              document.querySelector(`.block-${index == 0 || index < 10 ? index : index - 8}`).scrollIntoView({ behavior: "smooth" });
            }
          }
        });
        // scroll into view when page is loaded end
      }, 300);
    }
  }, [getEmployeeLoader, appointmentLoader])


  const updateBlockTimeHandler = (blocked_data, success, fail) => {
    seBtnLoading(true)
    dispatch(
      UpdateBlockTime(
        { ...blocked_data, business: state?.business?.business?.id },
        () => {
          seBtnLoading(false)
          toast.success("Block time updated!", { toastId: "toast" });
          success && success();
          setBlockTimeEdit(undefined);
        },
        () => {
          seBtnLoading(false)
          fail && fail();
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };

  useEffect(() => {
    if (selected_location) {
      setLocationSelection(selected_location);
    } else {
      if (reduxlocationsList?.length > 0) {
        setLocationSelection(reduxlocationsList[0]?.id);
      }
    }
  }, [reduxlocationsList?.length]);

  useEffect(() => {
    let lctin = reduxlocationsList.find((itm) => itm?.id === selected_location);
    if (lctin != undefined || lctin != null) {
      if (Object.keys(lctin).includes("currency") && lctin?.currency?.code) {
        setSelectedCurrency(lctin?.currency?.code);
        dispatch({
          type: "LOCATION_CURRENCY",
          payload: lctin?.currency?.code,
        });
      }
    }
  }, [selected_location, reduxlocationsList?.length]);


  //  Think Again Before Changing This Function 
  const { CalendarDND } = CalendarDNDHelper();
  CalendarDND(setUpdateAppointmentLoader, setActiveDragStartTime, setUpdateAppointmentData, hasMouse, isTouchDevice, closeDraging, BASE_URL, SelectedDate, SelectedEmployee, selectedLocation, FinalData, getEmployeeLoader, appointmentLoader, appointmentDetail, setAppointmentDetail, setAppGroupId , columnWidth);

  //  Think Again Before Changing This Function 


  // update dom with latest 
  useEffect(() => {
    let drop = document.querySelectorAll('.drop');
    drop.forEach(ele => {
      ele.classList.add('hidden')
    })


    // get calendar appointments
    getCalendarAppointments()
  }, [SelectedDate, SelectedEmployee, selectedLocation]) // block_time_edit ------ remove from dependency array because api hit when block time popup open on edit 

  useEffect(() => {
    // if (!updateAppointmentLoader) {
    let filteredDiv = document.getElementsByClassName('dropped_div')
    for (let i = 0; i < filteredDiv?.length; i++) {
      let div = filteredDiv[i];
      if (div) {
        // div.classList.add('hidden');
        div.remove()
      }
      // }
    }
  }, [updateAppointmentLoader])


  const handleParamUpdate = (newParam) => {
    if (newParam) {
      getCalendarAppointments()
    }
  };

  const getCalendarAppointments = () => {
    // var dateeee = new Date();
    var dateeee = moment();;
    setCurrentDate(dateeee)
    // console.log(SelectedDate,'SelectedDateSelectedDate');
    if (SelectedDate && selectedLocation) {
      let employeeData
      if (SelectedEmployee === "All") {
        employeeData = "All";
      } else {
        employeeData = [{ id: SelectedEmployee }]
      }
      // if (!state.appointment.appointments_updated) {
      dispatch(getCalendarMainAppointments(SelectedDate ? SelectedDate : dateeee.toLocaleDateString(), selectedLocation, employeeData, setAppointmentLoader));
      // }
    }
  }


  return (
    <>
      {block_time_edit && (
        <BlockTimePopup
          btn_loading={btn_loading}
          onClose={() => {
            setBlockTimeEdit(undefined);
          }}
          onSubmit={updateBlockTimeHandler}
          edit_mode={true}
          EditData={block_time_edit}
        />
      )}
      <div className=" min-h-full bounce-in-right px-2">
        <div className="flex flex-col gap-1">
          <div className=" pt-1 flex items-center justify-between md:gap-5 gap-7 flex-wrap">

            <div className={`sm:w-full w-fit px-10 sm:px-0 py-40 sm:py-0 bg-white drop-shadow sm:drop-shadow-none sm:bg-transparent h-screen sm:!h-min ${calendarTabMenu ? "right-0" : "-right-96"} transition-all sm:right-0 sm:pl-0  sm:relative fixed top-0 sm:top-0 z-[999999] sm:z-0`}>
              <div className="absolute sm:hidden block top-12 right-5" onClick={() => setCalendarTabMenu(!calendarTabMenu)}>
                <Svgs.Cross className='w-8 h-8 m-auto rounded-full border p-1 border-[#5679FF] cursor-pointer' fill='#5679FF' />
              </div>
              <div className="flex sm:items-center sm:justify-end items-end sm:flex-row flex-col-reverse gap-4 sm:w-auto w-full">
                <div className="relative z-[412] sm:w-auto w-full">
                  {AllEmployee && !getEmployeeLoader && !appointmentLoader ?
                    <>
                      {
                        (!state.user.user.employee || (state.user.user.employee && state.user?.permissions?.system_root_access?.includes('view'))) &&

                        <Dropwdown
                          className={'w-full sm:w-[10rem] lg:w-[14rem]'}
                          showTitle={false}
                          name={"selectedLocation"}
                          value={selectedLocation}
                          onChange={(e) => {
                            setLocationSelection(e.target.value);
                            storeDataInLocalStorage(
                              "selected_location",
                              e.target.value
                            );
                            if (selected_location != e.target.value) {
                              storeDataInLocalStorage('locationChange', true)
                            }
                          }}
                          options={reduxlocationsList?.map((adrs) => {
                            return {
                              label: adrs?.address_name,
                              value: adrs?.id,
                            };
                          })}
                          title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                          placeholder={selectedLanguage?.name == "English" ? "Filter by Location" : translation[50]?.value ? translation[50]?.value : "Filter by Location"}
                        />
                      }
                    </>
                    :
                    <Shimmer className={'w-full sm:w-[10rem] lg:w-[14rem]'}>
                      <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                    </Shimmer>
                  }
                </div>
                {!employee ||
                  (employee &&
                    employeePermissions?.calender_block_time?.includes(
                      "create"
                    )) ? (
                  <LoginBtn
                    onClick={() => {
                      setBlockTime(!BlockTime);
                      setCalendarTabMenu(!calendarTabMenu)
                    }}
                    borderBtn
                    className={"!w-full sm:!w-auto"}
                  // bg="bg-[#fff]"

                  >
                    <div className="flex items-center justify-center gap-2 w-full">
                      <Svgs.Block />
                      <p className="">
                        {selectedLanguage?.name == "English" ? "Add Block Time" : translation[107]?.value ? translation[107]?.value : "Add Block Time"}
                      </p>
                    </div>
                  </LoginBtn>
                ) : (
                  ""
                )}


                <LoginBtn
                  onClick={() => {
                    setPaidUnpaidAppointmentPopup(!paidUnpaidAppointmentpopup);
                    setCalendarTabMenu(!calendarTabMenu)
                  }}
                  borderBtn
                >
                  <div className="flex items-center gap-2">
                    <p className="">
                      {selectedLanguage?.name == "English" ? "Paid/Unpaid Appointments" : translation[1346]?.value ? translation[1346]?.value : "Paid/Unpaid Appointments"}
                    </p>
                  </div>
                </LoginBtn>


                <LoginBtn
                  onClick={() => {
                    setMissedOpprtunityPopup(!missedOpprtunityPopup);
                    setCalendarTabMenu(!calendarTabMenu)
                  }}
                  className={"!w-full sm:!w-auto"}
                  borderBtn
                >
                  <div className="flex items-center justify-center gap-2 w-full">
                    <p className="">
                      {selectedLanguage?.name == "English" ? "Missed Opportunity" : translation[1347]?.value ? translation[1347]?.value : "Missed Opportunity"}
                    </p>
                  </div>
                </LoginBtn>

                {!employee ||
                  (employee &&
                    employeePermissions?.calender_appointment?.includes(
                      "create"
                    )) ? (
                  <LoginBtn

                    onClick={() => {
                      navigate("/dashboard/calendar/add-appointment");
                    }}
                    className={"!w-full sm:!w-auto"}
                  >
                    <div className="flex items-center justify-center sm:justify-start gap-2 w-full">
                      <Svgs.Plus />
                      <p className="font-semibold">
                        {selectedLanguage?.name == "English" ? "New Appointment" : translation[18]?.value ? translation[18]?.value : "New Appointment"}
                      </p>
                    </div>
                  </LoginBtn>
                ) : (
                  ""
                )}
              </div>

            </div>
          </div>
          <div className="border border-[#3F3F441A] rounded-lg">
            <div className="px-4 py-1 relative z-[12]">
              <div className="flex items-center justify-between flex-wrap gap-3">
                <div className="flex items-center gap-3 flex-wrap">
                  <div>
                    <Dropwdown
                      showTitle={false}
                      placeholder="All Employees"
                      value={SelectedEmployee}
                      options={[
                        {
                          value: "All",
                          label: selectedLanguage?.name == "English" ? "All Employee" : translation[122]?.value ? translation[122]?.value : "All Employee"
                        },
                        ...employeeRedux?.map((info) => {
                          return { value: info.id, label: info.full_name };
                        }),
                      ]}
                      onChange={(e) => {
                        setSelectedEmployee(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-center gap-2 justify-between flex-1">
                  &nbsp;
                  <div className="flex items-center gap-2">
                    <DateCounter
                      bigCalendar={true}
                      SelectedDate={SelectedDate}
                      setSelectedDate={setSelectedDate}
                      selectedLanguage={selectedLanguage}
                      translation={translation}
                    />
                    <div className="sm:hidden block" onClick={() => setCalendarTabMenu(!calendarTabMenu)}>
                      <Svgs.RightSideBarMenu className='cursor-pointer' />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* CalendarRef Component
            This component is responsible for calculating the calendar's width
            and then assigning the appropriate width to the columns based on the calendar width. */}
            <CalendarRef AllEmployee={AllEmployee} calendarContainerRef={calendarContainerRef} />
            <div className={`overflow-auto calendar_parent relative w-full border-t`} id="main__calendar__parent" style={{ height: calendarHeight }}>
              {!getEmployeeLoader && !appointmentLoader ?
                AllEmployee?.length > 0 ? (
                  <>
                    <div className={`flex min-w-full`} style={{ width: `${AllEmployee?.length > 7 ? `${((AllEmployee.length - 7) * 36).toFixed(0)}%` : "100%"}` }}>
                      <div className="sticky left-0 z-50 bg-white">
                        <div className="h-[2.8rem] w-[7rem] flex items-center justify-center border-r border-b top-0 bg-white sticky z-[2]">
                          <h1 className="font-semibold text-sm">
                            {selectedLanguage?.name == "English" ? "Duration" : translation[112]?.value ? translation[112]?.value : "Duration"}
                          </h1>
                        </div>
                        {StartTime.map((time, index) => {

                          const activeStartTime = moment(ActiveDragStartTime, 'HH:mm:ss');
                          const fiveMinutesBefore = activeStartTime.clone().subtract(5, 'minutes').format('HH:mm:ss');
                          const tenMinutesBefore = activeStartTime.clone().subtract(10, 'minutes').format('HH:mm:ss');

                          const isTimeWithinThreshold = (
                            ActiveDragStartTime === time?.value ||
                            fiveMinutesBefore === time?.value ||
                            tenMinutesBefore === time?.value
                          );

                          const timeStyles = isTimeWithinThreshold ? '!font-semibold !text-sm !text-[#5679ff]' : '';

                          return (
                            <div
                              className={`time_block block-${index} h-[0.7rem] w-[7rem] ${(index) % 3 == 0 && index != 0 && 'border-t'} border-r flex items-center justify-center`}
                              key={index}
                              time={time.value}
                            >
                              <h1
                                className={`text-[#5C5C5C] hidden ${index % 3 == 0 &&
                                  "translate-y-[0.8rem] !block text-[0.6rem] font-semibold"
                                  } ${timeStyles}`}
                              >
                                {time.label}
                              </h1>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex-1 relative box-border">
                        {/* Calendar Structure Starts here */}
                        <div
                          className="grid absolute h-full w-full "
                          style={{
                            gridTemplateColumns: `repeat(${AllEmployee?.length},minmax(15rem,2fr))`,
                          }}
                        >
                          {
                            <>
                              {AllEmployee?.map((obj, i) => {
                                let data;
                                if (SelectedEmployee === "All") {
                                  data = obj;
                                } else {
                                  data = obj.employee ? obj.employee : obj;
                                }
                                return (
                                  <div
                                    className={`border-r grid`}
                                    style={{
                                      gridTemplateRows: `repeat(${StartTime?.length},0.7rem)`,
                                    }}
                                  >
                                    {/* Render The Employee Name and Image */}
                                    <div className="row-span-4 flex items-center justify-center sticky top-0 w-full bg-white z-[7] border-b ">
                                      <div className="flex items-center gap-2">
                                        <div className="h-[1.6rem] w-[1.6rem] rounded-full overflow-hidden mx-auto border">
                                          {data.image ?
                                            <img
                                              src={data.image}
                                              className="w-full h-full object-cover"
                                            />
                                            :
                                            <LabelAvatar iconLabel={data?.full_name} fontSize={'text-lg'} index={i} />

                                          }
                                        </div>
                                        <h2 className="font-semibold text-sm text-center capitalize line-clamp-1">
                                          {data.full_name}
                                        </h2>
                                      </div>
                                    </div>

                                    {/* Render all the time divs for the border in calendar */}

                                    {/* index % 3 is wriiten because 3 represent the number of divs to ignore to add border. */}
                                    {StartTime.map((time, index) => {
                                      return (
                                        <div
                                          className={`h-[0.7rem] flex items-center justify-center border-t ${index % 3 != 0 && "border-transparent"
                                            } ${index == 0 && "border-transparent"
                                            }`}
                                          key={index}
                                        >
                                          {/* <h1 className="text-xs text-[#5C5C5C]"></h1> */}
                                        </div>
                                      );
                                    })}

                                  </div>
                                );
                              })}
                            </>
                          }
                        </div>
                        {/* Calendar Structure Ends here */}


                        {/* Appointment cards will render here */}
                        <div
                          className="grid absolute h-full w-full asd"
                          style={{
                            gridTemplateColumns: `repeat(${AllEmployee?.length},minmax(15rem,2fr))`,
                          }}
                        >
                          {/* FinalData is set on the regard of the selected employee */}
                          {FinalData?.map((data, index) => {

                            return (
                              <div
                                employee_id={data.employee.id}
                                className={`employee__column gid ${SelectedEmployee != "All" ? "ONLY_EMPLOYEE" : ""
                                  } border-r grid Drag_Drop_Column w-full`}
                                style={{
                                  gridTemplateRows: `repeat(${StartTime.length},0.7rem) `,
                                }}
                                key={index}
                              >
                                <div className="row-span-4 border-b flex items-center justify-center"></div>
                                {data?.appointments?.length > 0 && StartTime?.map((time, i) => {

                                  return data?.appointments?.map(
                                    (appointment_arr) => {
                                      return appointment_arr?.map(
                                        (appointment, Loop_Id, ind) => {


                                          // Calculate if the appointment time is before 01:30 AM
                                          const isBeforeAppointmentTime = moment(appointment.appointment_time, "HH:mm:ss").isBefore(moment("01:30:00", "HH:mm:ss"));

                                          // Calculate if the appointment time is after 10:30 PM
                                          const isAfterAppointmentTime = moment(appointment.appointment_time, "HH:mm:ss").isAfter(moment("22:30:00", "HH:mm:ss"));

                                          // Check if this is the last item in the list and there are more than one item
                                          const isLastIndex = index === (FinalData.length - 1) && FinalData.length > 1;

                                          // Check if this is the second to last item in the list and there are more than two items
                                          const isSecondToLastIndex = index === (FinalData.length - 2) && FinalData.length > 2;

                                          // Check if there is only one item in the list
                                          const isSingleItem = FinalData.length === 1;

                                          // Concatenate CSS classes based on conditions to style the tooltip
                                          const toolTipStyles = `absolute w-full min-w-[18rem] max-w-[20rem] min-h-fit z-50 bg-white rounded-md shadow-md left-[102%] hidden group-hover/item:block tool__tip__card ${isBeforeAppointmentTime ? 'left-[102%]' : '-top-10'
                                            } ${isLastIndex ? '!left-[-20rem] z-[999]' : ''
                                            } ${isSecondToLastIndex ? '!left-[98%] z-[999]' : ''
                                            } ${isSingleItem ? '!left-1/2 z-[999] !top-[-12rem] -translate-x-1/2' : ''
                                            } ${isAfterAppointmentTime ? 'left-[0] !top-[-12rem]' : ''
                                            }`;




                                          // Check if the current iteration is equal to the appointment time and date
                                          // {/* new Date(appointment.appointment_date).toLocaleDateString("en-CA") == SelectedDate && */ }
                                          if (
                                            moment(appointment.appointment_date).format('YYYY-MM-DD') == moment(SelectedDate).format('YYYY-MM-DD') && appointment.appointment_time == time.value
                                          ) {
                                            // Loop_Id = Loop_Id + 1
                                            let id = i + 1;
                                            let step = 6;

                                            // Calculate the width and margin of the Appointment Card.
                                            {/* let width = (1 / appointment_arr.length) * 100 == 100 ? (1 / appointment_arr.length) * 100 == 100 : (1 / appointment_arr.length) * 100 - 0.8; */}
                                            let margin = (1 / appointment_arr.length) * 100 * Loop_Id;
                                            APPOINTMENT_TIME.filter(
                                              (ite, i) => {
                                                if (
                                                  ite.label == appointment.duration.toLowerCase().replace(' 0min', '')
                                                ) {
                                                  // 9 is only used to set the height of the card. It was set only by hit and trial!

                                                  // Actually step is the height of the Card.
                                                  step = i + 7;
                                                }
                                              }
                                            );

                                            const appointmentDate = moment(appointment.appointment_date).format('YYYY-MM-DD');
                                            const appointmentDateTime = moment(`${appointment.appointment_date} ${appointment.appointment_time}`).format('YYYY-MM-DD HH:mm:ss');

                                            const timeDifferenceInMinutes = Math.floor((appointmentDateTime - currentTime) / (1000 * 60));

                                            // Check if the time difference is around half an hour (e.g., less than or equal to 30 minutes)
                                            const isTimeAroundHalfHour = Math.abs(timeDifferenceInMinutes) <= 30;

                                            return (
                                              <div
                                                className={`z-[5] appointment__card ${!(appointment.is_unavailable) && !(appointment.is_blocked && !(appointment.is_unavailable)) && appointment.appointment_status != 'Done' && `${appointment?.status == 'Booked' ? 'AppointmentCard_Drag' : ''}`} border-t px-1 p-[0.125rem] ${appointment.is_blocked && 'is_blocked'} ${!employee || (employee && employeePermissions?.calender_appointment?.includes("edit")) ? 'clickable_div' : ''} 
                                                  ${appointment.appointment_status == 'Done' ? "appointment_done_Card no_edit" : appointment.client_types == "IN HOUSE" ? `${isTimeAroundHalfHour && (appointment?.status == 'Booked') ? 'blinking-card' : ''} ${getClientStatusClass(appointment)}` : appointment.is_blocked ? (appointment.is_unavailable) ? 'unavailable-bg-card' : "gray-bg-card" : ` ${getWalkinStatusClass(appointment)} ${isTimeAroundHalfHour && (appointment?.status == 'Booked') ? 'blinking-card' : ''}`} cursor-pointer`}

                                                onClick={(e) => {
                                                  if (!employee || (employee && employeePermissions?.calender_appointment?.includes("edit"))) {
                                                    appointment.is_blocked && (
                                                      setBlockTimeEdit({
                                                        ...appointment,
                                                        member: data.employee.id,
                                                      })
                                                    )
                                                  }

                                                  // this is for touch deve
                                                  if (isTouchDevice && !hasMouse) {
                                                    setAppGroupId({
                                                      appGroupId: appointment?.appointment_id,
                                                      serviceId: appointment?.id,
                                                      appointment_group_id: appointment?.appointment_group_id
                                                    })
                                                    !appointment.is_blocked && (
                                                      setAppointmentDetail(true)
                                                    )
                                                  }
                                                }}
                                                style={{
                                                  gridArea: `${id + 4
                                                    } / 1 / ${id + step} / 2`,
                                                  width: (columnWidth / (appointment_arr.length > 0 ? appointment_arr.length : 1)) - 1,
                                                  // width: (columnWidth * (width == true ? 100 : width)  / 100 ) + 'px',
                                                  // marginLeft: `${margin}%`,
                                                  marginLeft: `${columnWidth * margin / 100}px`,
                                                }}
                                                key={appointment?.id}
                                              >
                                                <div id appointment_time={appointment.appointment_time} employee_id={data.employee.id} parent_id={appointment?.appointment_id} appointment_id={appointment?.id} appointment_group_id={appointment?.appointment_group_id} className="children_id flex flex-col h-full justify-between relative group/item">
                                                  {(!updateAppointmentLoader && !appointment?.is_blocked) && <div className={toolTipStyles}>
                                                    <div className="w-full bg-[#5679ff] text-white rounded-t-md px-3 py-1 text-sm flex items-center justify-between"><span className="">Appointment</span><span className="">{appointment?.status}</span></div>
                                                    <div className="px-3 py-1 z-10 relative">
                                                      <div className="flex items-center gap-3 pt-3">
                                                        <div className="h-10 w-10 rounded-full overflow-hidden border flex items-center justify-center ">
                                                          {!appointment?.client_name ?
                                                            <Svgs.Profile2 width={20} height={20} />
                                                            :
                                                            <LabelAvatar iconLabel={appointment?.client_name} fontSize={'text-lg'} />

                                                          }
                                                        </div>
                                                        <p className="truncate">{appointment?.client_name ? appointment?.client_name : 'Walk In'}</p>
                                                      </div>
                                                      <div className="flex items-center gap-x-3 gap-y-1 w-full justify-between py-3">
                                                        <p className="text-sm truncate">
                                                          <span className="text-[#5679ff]">By:</span> {data.employee.full_name}
                                                        </p>
                                                        <div className="flex items-center gap-2 self-end">
                                                          <p className="text-sm">{appointment?.duration}</p>
                                                          <p className="text-sm">|</p>
                                                          <p className="text-sm">{+(appointment?.price)?.toFixed(2)} {location_currency}</p>
                                                        </div>
                                                      </div>
                                                      <div className="flex items-center gap-2">
                                                        <svg
                                                          width="10"
                                                          height="10"
                                                          viewBox="0 0 12 12"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M5.72468 0.192383C2.70163 0.192383 0.242188 2.65183 0.242188 5.67487C0.242188 8.69792 2.70163 11.1574 5.72468 11.1574C8.74772 11.1574 11.2072 8.69792 11.2072 5.67487C11.2072 2.65183 8.74772 0.192383 5.72468 0.192383ZM8.87711 6.22312H5.17643V2.38538H6.27293V5.12663H8.87711V6.22312Z"
                                                            className="fill-[#5679ff]"
                                                          />
                                                        </svg>
                                                        <p className="duration-time text-[0.65rem] font-semibold whitespace-nowrap">
                                                          {moment(appointment?.appointment_date).format('ddd, MMMM Do')} | {moment(updateAppointmentData?.response?.Appointment?.appointment_time ? updateAppointmentData?.response?.Appointment?.appointment_time : appointment?.appointment_time, "HH:mm:ss").format('h:mm A')} - {moment(appointment?.end_time, "HH:mm:ss").format('h:mm A')}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    {/* <div className={`absolute bottom-0 w-4 h-4 bg-white rotate-45 left-1/2 -translate-x-1/2 translate-y-1/2 ${(moment(appointment.appointment_time, "HH:mm:ss").isBefore(moment("01:30:00", "HH:mm:ss")) ? 'hidden' : 'block')} ${(index === (FinalData.length - 1)) ? 'hidden' : 'block'}`}></div> */}
                                                  </div>}
                                                  <div className="flex items-center justify-between gap-3 overflow-hidden">
                                                    {!appointment.is_blocked && (
                                                      <>
                                                        <h3 className="font-semibold text-xs truncate">
                                                          {
                                                            appointment?.client_name ? appointment?.client_name : ''
                                                          }
                                                        </h3>
                                                        <div className="flex items-center gap-2">
                                                          {/* {appointment?.client_info?.tag == 'Least Visitor' ? <Svgs.LeastVisitor className={''} fill={`${appointment?.appointment_status == 'Done' ? '#808080' : '#000'}`} /> : appointment?.client_info?.tag == 'Most Visitor' ? <Svgs.WalkingPerson className={''} fill={`${appointment?.appointment_status == 'Done' ? '#808080' : '#000'}`} /> : ''} */}
                                                          {appointment?.is_favourite && <Svgs.Heart className={''} fill={`${appointment?.appointment_status == 'Done' ? '#808080' : '#D60000'}`} />}
                                                          {/* {appointment?.client_info?.client_type == 'Most Spender' && <Svgs.SpentMoney className={''} fill={`${appointment?.appointment_status == 'Done' ? '#808080' : '#095307'}`} />} */}
                                                          <div className="openCardDiv" onClick={(e) => {
                                                            setAppGroupId({
                                                              appGroupId: appointment?.appointment_id,
                                                              serviceId: appointment?.id,
                                                              appointment_group_id: appointment?.appointment_group_id
                                                            })
                                                            !appointment.is_blocked && (
                                                              setAppointmentDetail(true)
                                                            )
                                                          }}>
                                                            <Svgs.OpenCardIcon className={'w-3'} fill='black' />
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}

                                                    {appointment.is_blocked &&
                                                      <>
                                                        <div className="flex items-center gap-1">
                                                          <svg
                                                            width="12"
                                                            height="12"
                                                            viewBox="0 0 12 12"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <path
                                                              d="M5.72468 0.192383C2.70163 0.192383 0.242188 2.65183 0.242188 5.67487C0.242188 8.69792 2.70163 11.1574 5.72468 11.1574C8.74772 11.1574 11.2072 8.69792 11.2072 5.67487C11.2072 2.65183 8.74772 0.192383 5.72468 0.192383ZM8.87711 6.22312H5.17643V2.38538H6.27293V5.12663H8.87711V6.22312Z"
                                                              className="duration-fill"
                                                            />
                                                          </svg>
                                                          <p className="duration-time text-[0.65rem] font-semibold whitespace-nowrap">
                                                            {appointment.duration}
                                                          </p>
                                                        </div>
                                                        {
                                                          (appointment.is_unavailable) ? <h3 className="font-semibold text-xs is_unavailable_typeee">
                                                            {/* Unavailable */}
                                                          </h3> : <h3 className="font-semibold text-xs ">
                                                            Blocked Time
                                                          </h3>
                                                        }
                                                      </>
                                                    }
                                                    
                                                  </div>
                                                  <div className="flex gap-1 items-end justify-between overflow-hidden">
                                                    {!appointment.is_blocked ? (
                                                      <>
                                                        <span className="capitalize text-[0.6rem] font-semibold truncate text-center">
                                                          {
                                                            appointment?.service?.name
                                                          }
                                                        </span>
                                                        <div className="flex items-center gap-1">
                                                          <svg
                                                            width="10"
                                                            height="10"
                                                            viewBox="0 0 12 12"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <path
                                                              d="M5.72468 0.192383C2.70163 0.192383 0.242188 2.65183 0.242188 5.67487C0.242188 8.69792 2.70163 11.1574 5.72468 11.1574C8.74772 11.1574 11.2072 8.69792 11.2072 5.67487C11.2072 2.65183 8.74772 0.192383 5.72468 0.192383ZM8.87711 6.22312H5.17643V2.38538H6.27293V5.12663H8.87711V6.22312Z"
                                                              className="duration-fill"
                                                            />
                                                          </svg>
                                                          <p className="duration-time text-[0.65rem] font-semibold whitespace-nowrap">
                                                            {appointment.duration}
                                                          </p>
                                                        </div>
                                                        <h3 className="font-semibold text-xs whitespace-nowrap">
                                                          {+(appointment?.price)?.toFixed(2)}{" "}
                                                          {location_currency}
                                                        </h3>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}

                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        });
                                    }
                                  );
                                })}
                              </div>
                            );
                          })}
                        </div>
                        {/* Appointment cards will render here */}
                      </div>
                    </div>
                  </>
                ) : (
                  <NoEmployeeDataOnCalendar />
                )
                :
                <div className="flex h-full w-full">
                  <CalenderShimmer />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {appointmentDetail && (
        <AppointmentDetailPopup
          open={appointmentDetail}
          onClose={(input_param) => {
            if (closeDraging) {
              closeDraging()
            }
            setAppointmentDetail(input_param)
          }
          }
          appointment_group_id={appGroupId.appointment_group_id}
          appGroupId={appGroupId.appGroupId}
          serviceId={appGroupId.serviceId}
          onUpdateParam={handleParamUpdate}
          view={true} />
      )
      }

      {!employee ||
        (employee &&
          employeePermissions?.calender_block_time?.includes(
            "create"
          )) ? (
        <AddBlockTime SelectedDate={SelectedDate} dateeee={currentDate} onUpdateParam={handleParamUpdate} BlockTime={BlockTime} setBlockTime={setBlockTime} />
      ) : (
        ""
      )}

      {!employee ||
      {/* (employee &&
                  employeePermissions?.calender_block_time?.includes(
                    "create"
                  )) */}
        ? (
          <PaidUnpaidAppointment paidUnpaidAppointmentpopup={paidUnpaidAppointmentpopup} setPaidUnpaidAppointmentPopup={setPaidUnpaidAppointmentPopup} />
        ) : (
          ""
        )}

      {!employee ||
      {/* (employee &&
                    employeePermissions?.calender_block_time?.includes(
                    "create"
                    )) */}
        ? (
          <MissedOpprtunity missedOpprtunityPopup={missedOpprtunityPopup} setMissedOpprtunityPopup={setMissedOpprtunityPopup} />
        ) : (
          ""
        )}
    </>
  );
};

export default CalendarMain;