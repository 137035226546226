import React, { useState } from "react";
import { useSelector } from "react-redux";
import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
import LocationSelection from "new-components/location";
import StockFilter from "./stock-filters";
import { useStocks } from "./helper";
import { useEffect } from "react";
import { useLocation } from "new-components/location/helper";
import NoPurchaseOrderDataFound from "Components/Utility/NoPurchaseOrderDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import Pagination from "new-components/Pagination";
// import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
// import Svgs from "Assets/svgs";
// import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
// import AddCategoryBtn from "../AddHeaderBtns/AddCategoryBtn";
// import AddProductBtn from "../AddHeaderBtns/AddProductBtn";

const StockListingCard = ({ data, currency, selected_location }) => {
  const state = useSelector((state) => state);
  const locations = state?.locations?.locations;
  const [ShowDetails, setShowDetails] = useState(0);
  const [price, setPrice] = useState({ amount: '', currency: '' })
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const { locationDetail } = useLocation();

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    if (locationDetail) {
      let price = data.currency_retail_price.find((obj) => obj?.currency == locationDetail?.currency?.id)
      setPrice({ amount: price?.retail_price, currency: locationDetail?.currency?.code })
    }
  }, [locationDetail])


  return (
    <div
      className={`odd-bg min-w-fit w-full text-[#3A3A3A] transition-all ${ShowDetails ? "border-l-[4px] border-[#5679FFB2]" : "border-l-[0px]"
        }`}
    >
      <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(9,minmax(155px,1fr))]">
        <div
        // className={`before-left-arrow cursor-pointer ${ShowDetails == 1
        //   ? "before-left-arrow-open"
        //   : "before-left-arrow-close"
        //   }`}
        // onClick={() => {
        //   setShowDetails(ShowDetails != 1 ? 1 : 0);
        // }}
        >
          <div className="h-full flex items-center">
            <p className="text-sm line-clamp-2 pr-3">{data?.name}</p>
          </div>
        </div>
        <div className="flex items-center">
          <p className="text-sm line-clamp-2 pr-3">
            {data?.stock[0]?.available_quantity || "0"}
          </p>
        </div>
        <div className="flex items-center">
          <p className="text-sm">
            {" "}
            {data?.stock[0]?.sold_quantity || "0"}
          </p>
        </div>
        <div className="flex items-center">
          <p className="text-sm line-clamp-1 pr-3">{price?.amount || "0"}  {price?.currency}</p>
        </div>
        <div className="flex items-center">
          <p className="text-sm line-clamp-1 pr-3">
            {data?.total_consumption}
            {/* {console.log(
              data?.consumed.filter(
                ({ location }) => location?.id === selected_location
              )[0]
            )} */}
            {/* {data?.consumed.filter(
              ({ location }) => location?.id === selected_location
            )[0]?.quantity || "0"} */}
            {/* {data?.consumed?.reduce((accumulator, currentItem) => {
              if (currentItem.location?.id === selected_location) {
                return accumulator + currentItem.quantity;
              }
              return accumulator;
            }, 0)} */}
          </p>
        </div>
        <div className="flex items-center">
          <StatusBtn
            status={`${data?.stock[0]?.status ? "active" : "inActive"}`}
            profile_status={
              data?.stock[0]?.status
                ? selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                : selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
            }
            size="md"
          />
        </div>
        <div className="flex items-center">
          {/* {data?.stocktransfer?.reduce((accumulator, { quantity }) => {
            return accumulator + quantity;
          }, 0) || "--"} */}
          {data?.total_transfer}
        </div>
        <div className="flex items-center">
          {
            data?.stock[0]?.status_text
          }
        </div>
        <div className="flex items-center">
          <p className="text-sm">
            {
              data?.stock[0]?.turnover
            }
          </p>
        </div>
      </div>
      {ShowDetails ? (
        <div className="scale-in-center">
          <div className="bg-[#FAF9FF] px-6 py-3">
            <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(155px,1fr))] gap-y-5 gap-x-3">
              <div className="flex flex-col gap-1">
                <p className="text-sm text-[#969BA0]">
                  {selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}
                </p>
                <p className="text-black font-semibold">{data?.name}</p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-sm text-[#969BA0]">
                  {selectedLanguage?.name == "English" ? "Available Stock" : translation[1030]?.value ? translation[1030]?.value : "Available Stock"}
                </p>
                <p className="text-black font-semibold">
                  {data?.stock.filter(
                    ({ location }) => location?.id === selected_location
                  )[0]?.available_quantity || "0"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-sm text-[#969BA0]">
                  {selectedLanguage?.name == "English" ? "Sold Stock" : translation[1110]?.value ? translation[1110]?.value : "Sold Stock"}
                </p>
                <p className="text-black font-semibold">
                  {" "}
                  {data?.stock.filter(
                    ({ location }) => location?.id === selected_location
                  )[0]?.sold_quantity || "0"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-sm text-[#969BA0]">
                  {selectedLanguage?.name == "English" ? "Price" : translation[200]?.value ? translation[200]?.value : "Price"}
                </p>
                <p className="text-black font-semibold">
                  {price?.amount || "0"}  {price?.currency}
                </p>
              </div>
              {/* <div className="flex flex-col gap-1">
                <p className="text-sm text-[#969BA0]">Usage</p>
                <p className="text-black font-semibold">
                  {Math.round((data?.consumed?.reduce((accumulator, { quantity }) => {
                    return accumulator + quantity;
                  }, 0) * (data?.stock.filter(
                    ({ location }) => location?.id === selected_location
                  )[0]?.available_quantity)) / 100)} %

                </p>
              </div> */}
              {/* {data?.consumed.filter(
                    ({ location }) => location?.id === selected_location
                  )[0]?.quantity || "0"} */}
              <div className="flex items-start justify-between">
                <div className="flex flex-col items-start gap-1">
                  <p className="text-sm text-[#969BA0] text-center">
                    {selectedLanguage?.name == "English" ? "Availability Status" : translation[1116]?.value ? translation[1116]?.value : "Availability Status"}

                  </p>
                  <StatusBtn
                    status={`${data?.stock?.filter(({ location }) => location?.id === selected_location)[0]?.status
                      ? "active" : "inActive"}`
                    }
                    profile_status={
                      data?.stock?.filter(({ location }) => location?.id === selected_location)[0]?.status
                        ? selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                        : selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                    }
                    size="md"
                  />
                </div>
              </div>
              <div className="flex items-start justify-between">
                <div className="flex flex-col gap-1">
                  <p className="text-sm text-[#969BA0]">
                    {selectedLanguage?.name == "English" ? "Sale Status" : translation[1113]?.value ? translation[1113]?.value : "Sale Status"}
                  </p>
                  <p className="text-black font-semibold">

                    {
                      data?.stock?.filter(
                        ({ location }) => location?.id === selected_location
                      )[0]?.status_text
                    }
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-sm text-[#969BA0]">
                  {selectedLanguage?.name == "English" ? "Turnover" : translation[1114]?.value ? translation[1114]?.value : "Turnover"}
                </p>
                <p className="text-black font-semibold">
                  {
                    data?.stock?.filter(
                      ({ location }) => location?.id === selected_location
                    )[0]?.turnover
                  }
                </p>
              </div>
              <div className="flex flex-col gap-1"></div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const Stocks = () => {
  const {
    state,
    filter_data,
    businessCurrency,
    selected_location,
    dataList,
    navigate,
    filter_stock,
    setFilterData,
    setStockDelPopup,
    loader,
    setSearchText,
    stockData,
    allStockData,
    setCurrentPage,
    pages,
    allCount,
    currentPage,
    PageSize
  } = useStocks();

  const stateData = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const translation = stateData?.languages?.language_translation
  const selectedLanguage = stateData?.languages?.selected_language


  useEffect(() => {
    setEmployee(stateData?.user?.user?.employee);
    setEmployeePermissions(stateData?.user?.permissions);
  }, [stateData?.user]);

  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
      <p className="text-[#7B7B7B] text-sm">
        <span
          className="cursor-pointer"
          onClick={() => {
            navigate("/dashboard/account/");
          }}
        >
          {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
        </span>{" "}
        &gt;{" "}
        <span
          className="cursor-pointer"
          onClick={() => {
            navigate("/dashboard/account/inventory-operations");
          }}
        >
          {selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations"}
        </span>{" "}
        &gt; <span className="text-primary font-semibold">
          {selectedLanguage?.name == "English" ? "Stock Reports" : translation[879]?.value ? translation[879]?.value : "Stock Reports"}</span>
      </p>
      <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
        <div className="flex items-center sm:gap-4 gap-2">

          <h2 className="text-primary font-bold lg:text-3xl text-xl">
            {selectedLanguage?.name == "English" ? "Stock Reports" : translation[879]?.value ? translation[879]?.value : "Stock Reports"}
          </h2>
          <LocationSelection />
        </div>
        {/* {!employee ? (
          <div className="flex items-center sm:gap-4 gap-2">
            <AddProductBtn />
            <AddCategoryBtn />
          </div>
        ) : (
          ""
        )} */}

      </div>
      <div className="border rounded-lg">
        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 ">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">
                {state.product.stock_filters
                  ? selectedLanguage?.name == "English" ? "Filtered Stock" : translation[1117]?.value ? translation[1117]?.value : "Filtered Stock"
                  : selectedLanguage?.name == "English" ? "Stock Availability" : translation[1108]?.value ? translation[1108]?.value : "Stock Availability"
                }
              </h2>
              {state.product?.stocks_updated && !loader ?
                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                  <p className="text-primary">
                    {allCount}
                    {" "}
                    {allCount && allCount > 1 ?
                      selectedLanguage?.name == "English" ? "Stocks" : translation[1107]?.value ? translation[1107]?.value : "Stocks"
                      : selectedLanguage?.name == "English" ? "Stock" : translation[1319]?.value ? translation[1319]?.value : "Stock"
                    }
                  </p>
                </div>
                :
                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                  <p className="rounded-md h-5 w-12 mx-1"> </p>
                </div>
              }
            </div>
            <StockFilter
              filter_data={filter_data}
              setFilterData={setFilterData}
              setSearchText={setSearchText}
              selected_location={selected_location}
              allStockData={allStockData}
            />
          </div>
        </div>
        {state.product?.stocks_updated && !loader ? (
          <div className="overflow-x-auto">
            <div className="inline-grid xl:grid grid-cols-[repeat(9,minmax(155px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
              <p>{selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}</p>
              <p>{selectedLanguage?.name == "English" ? "Current Stock" : translation[998]?.value ? translation[998]?.value : "Current Stock"}</p>
              <p>{selectedLanguage?.name == "English" ? "Sold Stock" : translation[1110]?.value ? translation[1110]?.value : "Sold Stock"}</p>
              <p>{selectedLanguage?.name == "English" ? "Price" : translation[200]?.value ? translation[200]?.value : "Price"}</p>
              <p>{selectedLanguage?.name == "English" ? "Consumed" : translation[1000]?.value ? translation[1000]?.value : "Consumed"}</p>
              <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
              <p>{selectedLanguage?.name == "English" ? "Transfer" : translation[1111]?.value ? translation[1111]?.value : "Transfer"}</p>
              <p>{selectedLanguage?.name == "English" ? "Sale Status" : translation[1113]?.value ? translation[1113]?.value : "Sale Status"}</p>
              <p>{selectedLanguage?.name == "English" ? "Turnover" : translation[1114]?.value ? translation[1114]?.value : "Turnover"}</p>
            </div>

            {stockData?.length > 0 ? (
              stockData?.map((stock, index) => {
                return (
                  <StockListingCard
                    currency={businessCurrency?.currency?.code}
                    data={stock}
                    selected_location={selected_location}
                    key={index}
                    onDeleteClick={() => {
                      setStockDelPopup(stock.stock.id);
                    }}
                  />
                );
              })
            ) : (
              <>
                <NoPurchaseOrderDataFound />
              </>
            )
            }

            {(stockData?.length > 0 && !loader) &&
              <Pagination
                currentPage={currentPage}
                totalCount={Math.ceil(pages)}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
              />
            }
          </div>
        ) : (
            <TableShimer cols={9} rows={12} className={"inline-grid xl:grid grid-cols-[repeat(9,minmax(185px,1fr))] text-sm font-semibold"} />
        )}
      </div>


    </div>
  );
};

export default Stocks;
