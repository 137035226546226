import React, { useEffect } from 'react'
import Svgs from '../../../Assets/svgs'

const FormSteps = (props) => {
    const dottedLines = props.stepsLength ? (+props.stepsLength - 1) : (+props.steps?.length - 1)
    useEffect(() => {
        if (props.scrollNone) {
            return
        } else {
            if (document.querySelector('.topscroll')) {
                document.querySelector('.topscroll').scrollIntoView();
            }
        }
    }, [props.active])

    return (
        <div className={`flex items-center xl:gap-3 gap-1 justify-center topscroll ${props.className}`}>
            {
                props.steps.map((step, i) => {
                    return <>
                        <div key={i} onClick={
                            () => {
                                if (props.customStepValidation && props?.currentStep == 1) {
                                    props.setVerificationPopupForStepOne && props.setVerificationPopupForStepOne(true)
                                    props.setStepToNavigate && props.setStepToNavigate(i + 1)
                                } else {
                                    if (props.allowNavigation) {
                                        props?.setStep(i + 1)
                                    } else {
                                        if (props?.currentStep != props?.disabledStep)
                                            props?.currentStep > i + 1 && props?.setStep(i + 1);
                                    }
                                }
                            }
                        }
                            className={`${props.className} lg:flex-none flex items-center lg:flex-row text-center flex-col gap-1.5 xl:gap-3`}>
                            <div
                                className={`h-[2.1875rem] w-[2.1875rem] rounded-full border flex items-center justify-center font-semibold ${props.active == (i + 1) ? 'text-primary border-primary' : props.active > (i + 1) ? 'border-[#05D672] bg-[#05D672]' : 'text-[#A1A1A1] border-[#A1A1A1]'}`}
                            >
                                {
                                    props.active > (i + 1) ? <div className='mt-[2px] cursor-pointer'><Svgs.Check /></div> : i + 1
                                }
                            </div>
                            <h2 className={`font-semibold whitespace-nowrap text-sm ${props.active == (i + 1) ? 'text-primary cursor-pointer' : props.active > (i + 1) ? 'text-[#05D672] cursor-pointer' : 'text-[#A1A1A1]'}`}
                            // onClick={
                            //     () => {
                            // if (props && props.currentStep != props.steps.length) {
                            //         props?.currentStep > i + 1 && props?.setStep(i + 1);
                            // }
                            //     }
                            // }
                            >
                                {step}
                            </h2>
                        </div>
                        {
                            (i != +dottedLines) && <div><div className='h-[1px] w-[2.813rem] border-t border-dashed border-[#A1A1A1]'></div></div>
                        }
                    </>
                })
            }
        </div>
    )
}

export default FormSteps