import { SALE_ORDER } from "../../ActionTypes/salleOrderTypes";

const initialState = {
    saleOrderProducts: [],
    saleOrderProducts_updated: false,
    saleOrderServices: [],
    saleOrderServices_updated: false,
    saleOrderVouchers: [],
    saleOrderMemberships: [],
    allSaleorders: [],
    allSaleordersWithNoPagination: [],
    singleInvoiceDetail: {},
    totalInvoiceBill: 0,
    pages: 0,
    invoice_titles: {}
};

const SaleOrderReducer = (state = initialState, action) => {

    const { type, payload } = action;
    switch (type) {

        case SALE_ORDER.GET_SALE_ORDER_PRODUCTS:
            return {
                ...state,
                saleOrderProducts: payload,
                saleOrderProducts_updated: true,
            };


        case SALE_ORDER.UPDATE_SALE_ORDER_PRODUCTS:
            return {
                ...state,
                saleOrderProducts: [...payload, ...state?.saleOrderProducts],
                saleOrderProducts_updated: true,
            };




        case SALE_ORDER.GET_SALE_ORDER_SERVICES:
            return {
                ...state,
                saleOrderServices: payload,
                saleOrderServices_updated: true
            };

        case SALE_ORDER.UPDATE_SALE_ORDER_SERVICES:
            return {
                ...state,
                saleOrderServices: [...payload, ...state.saleOrderServices],
                saleOrderServices_updated: true
            };

        case SALE_ORDER.GET_SALE_ORDER_VOUCHERS:
            return {
                ...state,
                saleOrderVouchers: payload,
            };

        case SALE_ORDER.GET_ALL_SALE_ORDER:
            return {
                ...state,
                allSaleorders: payload.data,
                pages: payload.pages
            };

        case SALE_ORDER.GET_INVOICE_TITLES:
            return {
                ...state,
                invoice_titles: payload.data,
            };

        case SALE_ORDER.GET_RECENT_SALES:
            return {
                ...state,
                recentSaleorders: payload.data,
            };

        case SALE_ORDER.GET_ALL_SALE_ORDER_NO_PAGINATION:
            return {
                ...state,
                allSaleordersWithNoPagination: payload.data,
            };

        case SALE_ORDER.GET_SINGLE_INVOICE_DETAIL:
            return {
                ...state,
                singleInvoiceDetail: payload.data,
            };


        case SALE_ORDER.GET_SALE_ORDER_MEMBERSHIPS:
            return {
                ...state,
                saleOrderMemberships: payload,
            };


        case SALE_ORDER.CALCULATE_INVOICE_BILL:
            return {
                ...state,
                totalInvoiceBill: payload,
            };

        case SALE_ORDER.COUNT:
            return {
                ...state,
                count: payload,
            };



        default:
            return state;
    }
};

export default SaleOrderReducer;