import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import React from 'react'

const InstallmentMemberShipShimmer = () => {
    return (
        <>
            <div className="flex flex-col gap-5 pt-3 animate-pulse">
                <div className="space-y-2">
                    <div className="w-1/3 h-8 bg-gray-200 rounded-lg" />
                    <div className="flex flex-wrap gap-2">
                        {[1, 2, 3]?.map((itm, ind) => (
                            <div className=" h-10 bg-gray-200 rounded-lg w-36 " />
                        ))}
                    </div>
                </div>
                <div className="bg-[#d5d5d6] rounded-xl">
                    <div className="flex items-center justify-between p-5 border-b border-[#bdbdbd]">
                        <div className="flex flex-col w-1/2 gap-1">
                            <div className="w-2/3 h-8 bg-gray-200 rounded-lg" />
                            <div className="w-2/3 h-8 bg-gray-200 rounded-lg" />
                        </div>
                        <div className="flex flex-col w-1/2 gap-1 items-end">
                            <div className="w-2/3 h-8 bg-gray-200 rounded-lg" />
                            <div className="w-2/3 h-8 bg-gray-200 rounded-lg" />
                        </div>
                    </div>
                    <div className="grid grid-cols-3 items-center">
                        <div className="flex flex-col items-center gap-1 py-5 border-r border-[#bdbdbd]">
                            <div className="w-2/3 h-6 bg-gray-200 rounded-lg" />
                            <div className="w-2/3 h-6 bg-gray-200 rounded-lg" />
                        </div>
                        <div className="flex flex-col items-center gap-1 py-5 border-r border-[#bdbdbd]">
                            <div className="w-2/3 h-6 bg-gray-200 rounded-lg" />
                            <div className="w-2/3 h-6 bg-gray-200 rounded-lg" />
                        </div>
                        <div className="flex flex-col items-center gap-1">
                            <div className="w-2/3 h-6 bg-gray-200 rounded-lg" />
                            <div className="w-2/3 h-6 bg-gray-200 rounded-lg" />
                        </div>
                    </div>
                </div>

                <div className="flex items-center gap-5">
                    <div className=" h-10 bg-gray-200 rounded-lg w-1/2 " />
                </div>

                <div className="flex flex-col gap-5">
                    <div className="w-1/4 h-9 bg-gray-200 rounded-lg" />
                    <div className="overflow-x-auto">
                        <div className="min-w-[500px] text-[#101928] rounded-xl border border-[#A1A1A1]">
                            <div className="grid grid-cols-5 rounded-t-xl items-center py-2 bg-[#F3F6FC]">
                                <div className="w-11/12 mx-auto h-6 bg-gray-200 rounded-lg" />
                                <div className="w-11/12 mx-auto h-6 bg-gray-200 rounded-lg" />
                                <div className="w-11/12 mx-auto h-6 bg-gray-200 rounded-lg" />
                                <div className="w-11/12 mx-auto h-6 bg-gray-200 rounded-lg" />
                                <div className="w-11/12 mx-auto h-6 bg-gray-200 rounded-lg" />
                            </div>
                            {[1, 2, 3, 4]?.map(i => {
                                return (
                                    <div className="grid grid-cols-5 items-center py-2 odd:bg-[#F3F6FC]">
                                        <div className="w-11/12 h-8 bg-gray-200 rounded-lg mx-auto" />
                                        <div className="w-11/12 h-8 bg-gray-200 rounded-lg mx-auto" />
                                        <div className="w-11/12 h-8 bg-gray-200 rounded-lg mx-auto" />
                                        <div className="w-11/12 h-8 bg-gray-200 rounded-lg mx-auto" />
                                        <div className="w-[4.5rem] h-10 bg-gray-200 rounded-full mx-4" />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-end w-full pt-2">
                    <div className=" h-10 bg-gray-200 rounded-lg w-28 " />
                </div>
            </div>
        </>
    )
}

export default InstallmentMemberShipShimmer