import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createReward,
  deleteReward,
  getrewards,
  updateRewards,
} from "../../../../Adapters/Api/clients";
import { getsProducts } from "../../../../Adapters/Api/Products";
import { getservice } from "../../../../Adapters/Api/services";
import Svgs from "../../../../Assets/svgs";
import { getProducts } from "../../../../Redux/TenantRedux/Actions/product";
import CircleIcon from "../../Elements/CircleIcon";
import Dropwdown from "../../Elements/Dropwdown";
import I_Info_Icon from "../../Elements/I_Info_Icon";
import LoginBtn from "../../Elements/LoginBtn";
import LoginInput from "../../Elements/LoginInput";
import Popup from "../../Elements/Popup";
import AddRewardCard from "./Elements/AddRewardCard";
import PreloaderFull from "./../../Elements/PreloaderFull";
import { apiErrorMessage } from "Constants/Data/Errors";

const ReardsSection = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const productRedux = state?.product?.products;
  const serviceRedux = state?.service?.services;
  const client_rewards_updated = state.client?.client_rewards_updated;
  const [is_delete, setDelete] = useState(undefined);
  const [rewardinput, setrewardinput] = useState({});
  const [btn_loading, setBtnLoading] = useState(false);
  const [edit_id, setEditId] = useState(undefined);
  const [rewardError, setrewardError] = useState({});
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const handlerewardinput = (e) => {
    const { name, value } = e.target;
    setrewardinput({
      ...rewardinput,
      [name]: value,
    });
    setrewardError({
      ...rewardError,
      [name]: false,
    });
  };

  const rewardsubmithandler = () => {
    if (rewardinput.reward_type === "Service") {
      delete rewardinput.product;
    } else if (rewardinput.reward_type === "Product") {
      delete rewardinput.service;
    }
    rewardinput["business"] = state?.business?.business?.id;
    rewardinput["discount_service"] = rewardinput.service;

    if (
      (Object.keys(rewardinput).includes("product") &&
        rewardinput["product"]) ||
      (Object.keys(rewardinput).includes("service") && rewardinput["service"])
    ) {
      setBtnLoading(true);
      dispatch(
        createReward(
          rewardinput,
          () => {
            setrewardinput({});
            setrewardError({});
            setBtnLoading(false);
            toast.success("Rewards created Successfully");
          },
          () => {
            setBtnLoading(false);
            toast.error("Rewards did not created");
          }
        )
      );
    } else {
      if (rewardinput.reward_type === "Service") {
        setrewardError({
          ...rewardError,
          service: true,
        });
      } else {
        setrewardError({
          ...rewardError,
          product: true,
        });
      }
    }
  };

  const fetchProductslist = async () => {
    const response = await getsProducts();

    if (response.status === 200) {
      let productList = response?.data?.response?.products;
      dispatch(getProducts(productList));
    }
  };

  const onPromotionDeleteHandler = (id) => {
    dispatch(
      deleteReward(
        { id: id },
        () => {
          toast.success("Rewards Deleted successfully");
          dispatch(getrewards());
        },
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };

  useEffect(() => {
    if (state?.client?.client_rewards?.length === 0) {
      dispatch(getrewards());
    }

    if (productRedux?.length === 0) {
      dispatch(getservice());
    }

    if (serviceRedux?.length === 0) {
      fetchProductslist();
    }
  }, []);

  const onRewardEditClickHnadler = (id) => {
    setEditId(id);
  };

  const onRewardUpdateHnadler = (edited_data) => {
    dispatch(
      updateRewards(
        edited_data,
        () => {
          toast.success("Rewards Updated successfully");
          setEditId();
          dispatch(getrewards());
        },
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return !client_rewards_updated ? (
    <PreloaderFull />
  ) : (
    <>
      <div className="col-span-4 slide-in-bottom">
        <div className="flex flex-col gap-5 ">
          <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
            <CircleIcon>
              <Svgs.Rewards />
            </CircleIcon>
            <div className="flex flex-col gap-1 lg:text-left text-center">
              <h2 className="text-primary font-semibold text-xl">
                Add Rewards
              </h2>
              <p className="text-[#7B7B7B] text-sm">
                Set Rewards criterias for your clients on
                products/services/membership
              </p>
            </div>
          </div>
          <div className="lg:w-[82%] w-full col-span-1">
            <div className="">
              <>
                {state.client.client_rewards.length > 0 ? (
                  state.client.client_rewards.map((rew, index) => {
                    return (
                      <AddRewardCard
                        key={index}
                        index={index}
                        data={rew}
                        is_edit={edit_id == rew.id ? true : false}
                        onUpdate={(edited_data) => {
                          onRewardUpdateHnadler(edited_data);
                        }}
                        onDelete={(id) => {
                          setDelete(id);
                        }}
                        onEditClick={(id) => {
                          onRewardEditClickHnadler(id);
                        }}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
              </>
              {!employee ||
                (employee &&
                  employeePermissions?.client_reward?.includes("create")) ? (
                <div className="flex gap-6">
                  <div className="relative">
                    <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                      <div className="h-[1.8rem] w-[1.8rem] rounded-full border border-[#C9C7C7] bg-white flex items-center justify-center"></div>
                    </div>
                    <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
                  </div>
                  <div className="mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4">
                    <LoginInput
                      name={"name"}
                      required={true}
                      title="Reward Name"
                      placeholder="Enter reward name"
                      onEnterSubmit={rewardsubmithandler}
                      value={rewardinput?.name}
                      error={rewardError?.name}
                      onChange={(e) => {
                        handlerewardinput(e);
                      }}
                      onBlur={() => {
                        if (!rewardinput.name) {
                          setrewardError({
                            ...rewardError,
                            name: true,
                          });
                        } else {
                          setrewardError({
                            ...rewardError,
                            name: false,
                          });
                        }
                      }}
                    />

                    <Dropwdown
                      required
                      title="Choose Reward Type"
                      placeholder="Select where you want to implement this reward"
                      name="reward_type"
                      value={rewardinput?.reward_type}
                      error={rewardError.reward_type}
                      options={[
                        { label: "Service", value: "Service" },
                        { label: "Product", value: "Product" },
                      ]}
                      onChange={(e) => {
                        handlerewardinput(e);
                      }}
                      onBlur={() => {
                        if (!rewardinput.reward_type) {
                          setrewardError({
                            ...rewardError,
                            reward_type: true,
                          });
                        } else {
                          setrewardError({
                            ...rewardError,
                            reward_type: false,
                          });
                        }
                      }}
                    />
                    <div className=" ">
                      {rewardinput.reward_type == "Service" ? (
                        <>
                          <Dropwdown
                            title="Services"
                            required={true}
                            placeholder="Select Service"
                            name="service"
                            value={rewardinput?.service}
                            error={rewardError?.service}
                            onChange={(e) => {
                              handlerewardinput(e);
                            }}
                            options={serviceRedux.map((itm) => {
                              return {
                                label: itm.name,
                                value: itm.id,
                              };
                            })}
                            onBlur={() => {
                              if (!rewardinput.service) {
                                setrewardError({
                                  ...rewardError,
                                  service: true,
                                });
                              } else {
                                setrewardError({
                                  ...rewardError,
                                  service: false,
                                });
                              }
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Dropwdown
                            title="Select Product"
                            required={true}
                            placeholder="Select Product"
                            name="product"
                            value={rewardinput?.product}
                            error={rewardError?.product}
                            options={productRedux.map((itm) => {
                              return {
                                label: itm.name,
                                value: itm.id,
                              };
                            })}
                            onChange={(e) => {
                              handlerewardinput(e);
                            }}
                            onBlur={() => {
                              if (!rewardinput.product) {
                                setrewardError({
                                  ...rewardError,
                                  product: true,
                                });
                              } else {
                                setrewardError({
                                  ...rewardError,
                                  product: false,
                                });
                              }
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className="flex flex-col gap-4 ">
                      <LoginInput
                        required={true}
                        name="reward_value"
                        type="number"
                        title="Reward Value"
                        value={rewardinput?.reward_value}
                        error={rewardError?.reward_value}
                        onEnterSubmit={rewardsubmithandler}
                        placeholder={`How much services to take to get reward`}
                        onChange={(e) => {
                          handlerewardinput(e);
                        }}
                        onBlur={() => {
                          if (!rewardinput.reward_value) {
                            setrewardError({
                              ...rewardError,
                              reward_value: true,
                            });
                          } else {
                            setrewardError({
                              ...rewardError,
                              reward_value: false,
                            });
                          }
                        }}
                      />
                      <LoginInput
                        required={true}
                        name="reward_point"
                        type="number"
                        title="Reward Point"
                        value={rewardinput?.reward_point}
                        error={rewardError?.reward_point}
                        onEnterSubmit={rewardsubmithandler}
                        placeholder={`How many points client will get on specific value`}
                        onChange={(e) => {
                          handlerewardinput(e);
                        }}
                        onBlur={() => {
                          if (!rewardinput.reward_point) {
                            setrewardError({
                              ...rewardError,
                              reward_point: true,
                            });
                          } else {
                            setrewardError({
                              ...rewardError,
                              reward_point: false,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="grid lg:grid-cols-2 gap-4">
                      {/* <p className='text-sm'>Discount Percentage <span class="text-red-600"> *</span></p> */}
                      <LoginInput
                        required
                        title={
                          <I_Info_Icon
                            text="Total Reward points"
                            info="The total points that the client needs to avail this offer"
                          />
                        }
                        type="number"
                        placeholder="Enter reward points to get the reward "
                        name="total_points"
                        value={rewardinput?.total_points}
                        error={rewardError?.total_points}
                        onEnterSubmit={rewardsubmithandler}
                        onChange={(e) => {
                          handlerewardinput(e);
                        }}
                        onBlur={() => {
                          if (!rewardinput.total_points) {
                            setrewardError({
                              ...rewardError,
                              total_points: true,
                            });
                          } else {
                            setrewardError({
                              ...rewardError,
                              total_points: false,
                            });
                          }
                        }}
                      />
                      <LoginInput
                        placeholder="Ex 50%"
                        title="Discount Percentage "
                        name="discount"
                        value={rewardinput?.discount}
                        error={rewardError?.discount}
                        onEnterSubmit={rewardsubmithandler}
                        onChange={(e) => {
                          handlerewardinput(e);
                        }}
                        onBlur={() => {
                          if (!rewardinput.discount) {
                            setrewardError({
                              ...rewardError,
                              discount: true,
                            });
                          } else {
                            setrewardError({
                              ...rewardError,
                              discount: false,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {!employee ||
            (employee &&
              employeePermissions?.client_reward?.includes("create")) ? (
            <LoginBtn
              text="Add Reward"
              disabled={
                (!rewardinput.name ||
                  !rewardinput?.reward_type ||
                  !rewardinput?.service ||
                  !rewardinput?.reward_value ||
                  !rewardinput?.reward_point ||
                  !rewardinput?.total_points ||
                  !rewardinput?.discount) &&
                (!rewardinput.name ||
                  !rewardinput?.reward_type ||
                  !rewardinput?.reward_value ||
                  !rewardinput?.reward_point ||
                  !rewardinput?.total_points ||
                  !rewardinput?.discount ||
                  !rewardinput?.product)
              }
              loading={btn_loading}
              onClick={() => {
                rewardsubmithandler();
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {is_delete && (
        <Popup
          heading="Are you sure want to delete this Promotion?"
          onclose={() => {
            setDelete(false);
          }}
        >
          <div>
            <p>
              Are you sure want to delete this Promotion? You won't be able to
              see this again.
            </p>
            <div className="flex justify-end mt-4">
              <LoginBtn
                deleteBtn
                text="Yes! Delete"
                onClick={() => {
                  onPromotionDeleteHandler(is_delete);
                  setTimeout(() => {
                    setDelete(false);
                  }, 500);
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default ReardsSection;

// import Svgs from '../../../../Assets/svgs'
// import CircleIcon from '../../Elements/CircleIcon'
// import Dropwdown from '../../Elements/Dropwdown'
// import LoginBtn from '../../Elements/LoginBtn'
// import AddRewardCard from './Elements/AddRewardCard'
// import useSetDataInLocalStorage from './../../../../Hooks/useSetDataInLocalStorage';
// import useValidations from './../../../../Hooks/useValidations';
// import useObjectKeys from './../../../../Hooks/useObjectKeys';
// import { useDispatch, useSelector } from 'react-redux';
// import LoginInput from '../../Elements/LoginInput'
// import { getservice } from '../../../../Adapters/Api/services'
// import { getsProducts } from './../../../../Adapters/Api/Products/index';
// import { getProducts } from '../../../../Redux/TenantRedux/Actions/product'
// import InputSection from '../../Elements/InputSection'
// import { createReward, deleteReward, getrewards, updateReward } from '../../../../Adapters/Api/clients'
// import { toast } from 'react-toastify'
// import { addReward, removeReward, editReward } from '../../../../Redux/TenantRedux/Actions/clientActions'
// import NotFound from './../../../Utility/NotFound';
// import Popup from '../../Elements/Popup'

// const RewardSection = () => {
//     const reduxService = useSelector((state) => state?.service?.services);
//     const productRedux = useSelector((state) => state?.product?.products);
//     const reduxRewards = useSelector((state) => state?.client?.client_rewards);

//     let isDisabled = true;
//     const [loading, setLoading] = useState(false)

//     let serviceOptions;
//     if (reduxService && reduxService.length >= 1) {
//         serviceOptions = reduxService && reduxService?.map((service) => {
//             return {
//                 label: service.name,
//                 value: service.id,
//             }
//         });
//     }

//     let productOptions;
//     if (productRedux && productRedux.length >= 1) {
//         productOptions = productRedux && productRedux?.map((product) => {
//             return {
//                 label: product.name,
//                 value: product.id,
//             }
//         });
//     }

//     const domeOptions = [
//         { label: "one", value: "one" },
//         { label: "two", value: "two" },
//     ]

//     const rewardaTypesOptions = [
//         { label: "Service", value: "Service" },
//         { label: "Product", value: "Product" },
//     ]

//     const { getDataFromLocalStorage } = useSetDataInLocalStorage();
//     const { setErrors, validation, errors, onBlurHandler } = useValidations();
//     const { objectKeyConvertToArray } = useObjectKeys();
//     const dispatch = useDispatch();
//     const access_token = getDataFromLocalStorage("access_token");
//     const businessData = getDataFromLocalStorage("business_data");
//     const [rewardModal, setRewardModal] = useState(false)
//     const [isEdit, setIsEdit] = useState(false);

//     const [inputField, setInputField] = useState({})
//     const [RewardData, setRewards] = useState({});
//     const [deleteModal, setDeleteModal] = useState(false);
//     const [rewardId, setrewardId] = useState();
//     const [selectedId, setSelectedId] = useState();

//     const { name, reward_value, reward_point, total_points, discount, reward_type, service, product } = RewardData;
//     if ((name && reward_type && reward_value && reward_point && total_points && discount) && (service || product)) {
//         isDisabled = false
//     }

//     // on change handle for main two fields
//     const inputFieldsHandleChange = (e) => {
//         const { name, value } = e.target;
//         setInputField((prev) => ({ ...prev, [name]: value }))
//         setErrors((prev) => ({ ...prev, [name]: "" }))
//         if (name === "reward_type" && inputField.name && value) {
//             addNewRewards({ ...inputField, [name]: value })
//         }
//     }

//     //generate new card for add rewards here
//     const addNewRewards = (data) => {
//         setRewards([...RewardData, { ...data }])
//         setInputField({})
//     }

//     //reward full form handle change method
//     const rewardsHandleChange = (e) => {

//         const { name, value } = e.target;
//         setRewards((prev) => ({ ...prev, [name]: value }))
//         setErrors((prev) => ({ ...prev, [name]: "" }))

//     }

//     //rewards submit here
//     const onRewardsSubmit = () => {
//         setLoading(true)
//         createReward({ ...RewardData, business: businessData?.id }, access_token).
//             then((result) => {
//                 if (result.status_code == 201) {
//                     toast.success(result?.response?.message)
//                     dispatch(addReward(result?.response?.rewards))
//                     setRewards({ name: "", reward_value: "", reward_point: "", total_points: "", discount: "", reward_type: "", service: "", product: "" })
//                     setLoading(false);
//                 }
//             }).catch((error) => {
//                 setLoading(false)
//             })
//     }

//     // get product list
//     const fetchProductslist = async () => {
//         const response = await getsProducts();
//         if (response.status === 200) {
//             let productList = response?.data?.response?.products;
//             dispatch(getProducts(productList))
//         }
//     }

//     useEffect(() => {

//         if (productRedux?.length === 0) {
//             fetchProductslist()
//         }

//         if (reduxService.length === 0) {
//             dispatch(getservice())
//         }

//         if (reduxRewards.length === 0) {
//             dispatch(getrewards())
//         }
//     }, [])

//     const deleteModelHandler = () => {
//         setDeleteModal(!deleteModal);
//     }

//     // *******************handle delete modal *********************
//     const rewardModalHandler = () => {
//         setRewardModal(!rewardModal)
//     }

//     //delete rewards handle method
//     const deleteRewardHandler = () => {
//         rewardId && deleteReward(rewardId, access_token).then((result) => {
//             if (result.status_code === 200) {
//                 toast.success(result?.response?.message);
//                 dispatch(removeReward(rewardId));
//             }
//         }).catch((error) => {
//             toast.success("Somethings went wrong!")
//         })

//     }

//     const rewardEditHandler = (id) => {

//         const selectedReward = reduxRewards && reduxRewards.find((item) => item.id === id);
//         if (selectedReward) {
//             setRewards({
//                 name: selectedReward?.name,
//                 reward_type: selectedReward?.reward_type,
//                 product: selectedReward?.product,
//                 service: selectedReward?.service,
//                 reward_value: selectedReward?.reward_value,
//                 reward_point: selectedReward?.reward_point,
//                 discount: selectedReward?.discount,
//                 total_points: selectedReward?.total_points,

//             })
//         }
//         setSelectedId(id)
//     }

//     const onUpdateHandler = () => {
//         updateReward({ ...RewardData, id: selectedId }, access_token).
//             then((result => {
//                 if (result.status_code == 200) {
//                     toast.success(result?.response?.message)
//                     dispatch(editReward(result?.response?.reward))
//                     setSelectedId(false)
//                 }
//             })).
//             catch((error) => {
//                 toast.error("something went wrongs!")
//             })
//     }

//     return (
//         <>
//             <div className='col-span-4 slide-in-bottom'>
//                 <div className='flex flex-col gap-5 '>
//                     <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
//                         <CircleIcon>
//                             <Svgs.Promotions />
//                         </CircleIcon>
//                         <div className='flex flex-col gap-1 lg:text-left text-center'>
//                             <h2 className='text-primary font-semibold text-xl'>Add Rewards</h2>
//                             <p className='text-[#7B7B7B] text-sm'>Set reward criterias for your clients on products/services/membership</p>
//                         </div>
//                     </div>
//                     <div className='lg:w-[75%] w-full col-span-1'>
//                         <div className=''>
//                             <>
//                                 {
//                                     reduxRewards && reduxRewards.length > 0 ?

//                                         reduxRewards?.map((reward) => {
//                                             return (
//                                                 <AddRewardCard
//                                                     loading={loading}
//                                                     productRedux={productRedux}
//                                                     reduxService={reduxService}
//                                                     RewardData={RewardData}
//                                                     onUpdateHandler={onUpdateHandler}
//                                                     edit={selectedId == reward.id ? true : false}
//                                                     rewardsHandleChange={rewardsHandleChange}
//                                                     rewardEditHandler={rewardEditHandler}
//                                                     rewardaTypesOptions={rewardaTypesOptions}
//                                                     errors={errors}
//                                                     onBlurHandler={onBlurHandler}
//                                                     data={reward}
//                                                     setrewardId={setrewardId}
//                                                     deleteModelHandler={deleteModelHandler}
//                                                     rewardModalHandler={rewardModalHandler}
//                                                     key={reward.id}

//                                                 />
//                                             )
//                                         })
//                                         : <NotFound />
//                                 }
//                             </>

//                         </div>

//                         <div className='flex gap-6'>
//                             <div className='relative'>
//                                 <div className='flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 '>
//                                     <div className='h-[1.8rem] w-[1.8rem] rounded-full border border-[#C9C7C7] bg-white flex items-center justify-center'>
//                                     </div>
//                                 </div>
//                                 <div className='absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2'></div>
//                             </div>
//                             {!rewardModal &&
//                                 <div className='mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4'>
//                                     <LoginInput
//                                         required={true}
//                                         name={"name"}
//                                         value={name}
//                                         onChange={rewardsHandleChange}
//                                         // onBlur={() => onBlurHandler({ name: name })}
//                                         // error={errors?.name}
//                                         title='Reward Name'
//                                         placeholder='Reward Name'
//                                     />

//                                     <Dropwdown
//                                         required={true}
//                                         name={"reward_type"}
//                                         value={reward_type}
//                                         onChange={rewardsHandleChange}
//                                         onBlur={() => onBlurHandler({ reward_type: reward_type })}
//                                         error={errors?.reward_type}
//                                         options={rewardaTypesOptions}
//                                         title='Choose Reward Type'
//                                         placeholder='Reward Type'
//                                     />

//                                     {reward_type === "Service" ?
//                                         <Dropwdown
//                                             required={true}
//                                             name={"service"}
//                                             value={service}
//                                             onBlur={() => onBlurHandler({ service: service })}
//                                             error={errors?.service}
//                                             options={serviceOptions}
//                                             onChange={rewardsHandleChange}
//                                             title='Select Service'
//                                             placeholder='Select Service'
//                                         /> : <Dropwdown
//                                             required={true}
//                                             name={"product"}
//                                             value={product}
//                                             onBlur={() => onBlurHandler({ product: product })}
//                                             error={errors?.product}
//                                             options={productOptions}
//                                             onChange={rewardsHandleChange}
//                                             title='Select product'
//                                             placeholder='Select product'
//                                         />
//                                     }
//                                     <div className='flex flex-col gap-2'>
//                                         <p className='text-sm'>Reward Value / points <span class="text-red-600"> *</span></p>
//                                         <InputSection
//                                             required={true}
//                                             name={"reward_value"}
//                                             value={reward_value}
//                                             onChange={rewardsHandleChange}
//                                             onBlur={() => onBlurHandler({ reward_value: reward_value })}
//                                             error={errors?.reward_value}
//                                             placeholder='10'
//                                             leftText={<p className='px-2'>Value</p>}

//                                         />

//                                         <InputSection
//                                             required={true}
//                                             name={"reward_point"}
//                                             value={reward_point}
//                                             onChange={rewardsHandleChange}
//                                             onBlur={() => onBlurHandler({ reward_point: reward_point })}
//                                             error={errors?.reward_point}
//                                             placeholder='10'
//                                             leftText={<p className='px-2'>Points</p>
//                                             } />
//                                     </div>

//                                     <div className='grid lg:grid-cols-2 gap-4'>
//                                         <LoginInput
//                                             required={true}
//                                             name={"total_points"}
//                                             value={total_points}
//                                             onChange={rewardsHandleChange}
//                                             onBlur={() => onBlurHandler({ total_points: total_points })}
//                                             error={errors?.total_points}
//                                             title='Total Reward points '
//                                             placeholder='Select Total Reward points '
//                                         />

//                                         <div className='flex flex-col gap-2'>
//                                             <p className='text-sm'>Discount Percentage<span class="text-red-600"> *</span></p>
//                                             <InputSection
//                                                 required={true}
//                                                 name={"discount"}
//                                                 value={discount}
//                                                 onChange={rewardsHandleChange}
//                                                 onBlur={() => onBlurHandler({ discount: discount })}
//                                                 error={errors?.discount}
//                                                 placeholder='Ex 50%'
//                                                 leftText={<p className='px-2'>%</p>}
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                             }

//                             {/* <div className='mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4'>
//                                 <LoginInput
//                                     required={true}
//                                     name={"name"}
//                                     value={inputField?.name}
//                                     onChange={inputFieldsHandleChange}
//                                     onBlur={() => onBlurHandler({ name: inputField?.name })}
//                                     error={errors?.name}
//                                     title='Reward Name'
//                                     placeholder='Reward Name'
//                                 />

//                                 <Dropwdown
//                                     required={true}
//                                     name={"reward_type"}
//                                     value={inputField?.reward_type}
//                                     onChange={inputFieldsHandleChange}
//                                     onBlur={() => onBlurHandler({ reward_type: inputField?.reward_type })}
//                                     error={errors?.reward_type}
//                                     title='Choose Reward Type'
//                                     placeholder='Reward Type'
//                                     options={rewardaTypesOptions}
//                                     disabled={!inputField?.name?.trim() ? true : false}
//                                 />
//                             </div> */}
//                         </div>
//                     </div>
//                     <LoginBtn
//                         loading={loading}
//                         onClick={onRewardsSubmit} text='Add Rewards'
//                         disabled={isDisabled}

//                     />
//                 </div>

//             </div>

//             {
//                 deleteModal &&
//                 <Popup
//                     heading="Are you sure want to delete this Reward?"
//                     onclose={deleteModelHandler}
//                 >
//                     <div>
//                         <p>Are you sure want to delete this Reward? You won't be able to see this again.</p>
//                         <div className='flex justify-end mt-4'>
//                             <LoginBtn
//                                 bg='bg-[#CE0000]'
//                                 text='Yes! Delete'
//                                 onClick={async () => {
//                                     await deleteRewardHandler();
//                                     deleteModelHandler()
//                                 }}
//                             />
//                         </div>
//                     </div>
//                 </Popup>
//             }

//         </>
//     )
// }

// export default RewardSection
