import React from "react";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import Svgs from "Assets/svgs";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import FloatInput from "Components/TenantApp/Elements/FloatInput";
import { useEffect, useState } from "react";
// import LoginInput from "Components/TenantApp/Elements/LoginInput";

function CategoryDiscount({ formRef, setFormErrors, formErrors, translation, selectedLanguage, categoryDiscount, removeCategoryDiscountField, categoryDiscountDropdown, setDiscount, onBlurHandler, errors, setErrors }) {
  const [dropdownCategory, setDropDownCategory] = useState(categoryDiscountDropdown)
  const [showAddBtn, setShowAddBtn] = useState(true)
  const onUpdate = (e, i) => {
    const { name, value } = e.target;
    if (value) {
      setErrors((prev) => ({ ...prev, [`${name}${i}`]: "" }))
    }

    if (formErrors?.lengh > 0) {
      const updatedFormErrors = [...formErrors];
      updatedFormErrors[i] = {
        ...updatedFormErrors[i],
        [name]: '',
      };
      setFormErrors(updatedFormErrors);
    }

    setDiscount((prev) => ({
      ...prev,
      categoryDiscount: [
        ...prev?.categoryDiscount.map((obj, index) => {
          if (i === index) {
            return {
              ...obj,
              [name]: value,
              is_deleted: 'False'
            }
          }
          else {
            return obj
          }
        })
      ],
    }));
  }

  useEffect(() => {
    if (categoryDiscount?.length == 2 && categoryDiscount?.find(itm => itm?.category == 'Service' || itm?.category == 'Retail')) {
      const categoryToExclude = categoryDiscount.map(item => item?.category);
      let newArr = []
      newArr = [
        ...categoryDiscountDropdown
          ?.filter(itm => itm?.label !== "All")
          ?.filter(itm => !categoryToExclude.includes(itm?.label))
      ]
      setDropDownCategory(newArr)
    } else {
      setDropDownCategory(categoryDiscountDropdown)
    }

    if (categoryDiscount?.find(itm => itm?.category === 'All') || categoryDiscount?.length == 2) {
      setShowAddBtn(false)
    } else {
      setShowAddBtn(true)
    }
  }, [categoryDiscount?.length, categoryDiscount])

  return (
    <>
      <div className="flex flex-col gap-3 relative">
        {categoryDiscount?.map((obj, index) => obj.is_deleted === 'False' && (
          <div className="relative border rounded p-2 pb-5 mt-2.5" >
            {
              index > 0 &&
              <div
                className="absolute cursor-pointer top-0 right-0 translate-x-1/2 -translate-y-1/2"
                onClick={() => {
                  removeCategoryDiscountField(obj?.id, index)
                }}
              >
                <Svgs.CirlceClose size="20" fill="red" />
              </div>
            }

            <div className={`flex relative items-center gap-3`}>
              <Dropwdown
                name={`category`}
                placeholder={selectedLanguage?.name == "English" ? "Select Category" : translation[889]?.value ? translation[889]?.value : "Select Category"}
                title={selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}
                options={
                  !obj.category ? dropdownCategory.filter(obj => {
                    return obj && !categoryDiscount?.filter(element => element.is_deleted === 'False')?.find((element) => element.category === obj.value)
                  })
                    :
                    [{ label: obj.category && obj.category, value: obj.category && obj.category },
                    ...dropdownCategory.filter((obj, index) => {
                      return obj && !categoryDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.category === obj.value)
                    })
                    ]
                }
                value={obj?.category}
                onChange={(e) => onUpdate(e, index)}
                onBlur={() => onBlurHandler({ [`category${index}`]: obj?.category })}
                error={formErrors?.length > 0 ? formErrors[index]?.category : ""}
              // error={errors[`category${index}` || formErrors[index]?.category]}
              />
              <FloatInput
                name="discount"
                title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[895]?.value ? translation[895]?.value : "Discount Percentage"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Discount Percentage" : translation[1733]?.value ? translation[1733]?.value : "Enter Discount Percentage"}
                value={obj?.discount}
                onChange={(e) => e.target.value <= 100 && onUpdate(e, index)}
                onBlur={() => onBlurHandler({ [`discount${index}`]: obj?.discount })}
                error={formErrors?.length > 0 ? formErrors[index]?.discount : ""}
                isPercentage={true}
              // error={errors[`discount${index}` || formErrors[index]?.discount]}
              />
            </div>
          </div>
        ))}
        {showAddBtn ?
          <LoginBtn
            borderBtn
            onClick={() => {
              const errors = [];
              categoryDiscount?.forEach((data, index) => {
                const fieldErrors = {};
                if (!data.category) {
                  fieldErrors.category = 'This field is required';
                }
                if (!data.discount) {
                  fieldErrors.discount = 'This field is required';
                }
                errors[index] = fieldErrors;
              });
              // Update formErrors state with the new errors
              setFormErrors(errors);
              // If there are errors, return early and do not proceed with form submission
              if (errors?.some((error) => Object.keys(error)?.length > 0)) {
                formRef.current?.scrollIntoView({ behavior: "smooth" });
                return;
              } else {
                setDiscount((prev) => ({
                  ...prev,
                  categoryDiscount: [
                    ...prev?.categoryDiscount,
                    { category: "", discount: "", is_deleted: "False" },
                  ],
                }));
              }
            }
            }
          // disabled={categoryDiscount?.find(
          //   (obj) =>
          //     !obj?.category ||
          //     !obj?.discount)
          // }
          >
            <Svgs.Plus color="#1C8AFE" />
            <span className="text-primary">
              {selectedLanguage?.name == "English" ? "Add New" : translation[898]?.value ? translation[898]?.value : "Add New"}
            </span>
          </LoginBtn>
          : ''
        }
      </div>
    </>
  );
}

export default CategoryDiscount;
