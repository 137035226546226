import { useEffect, useState } from "react";

const useDealTypeSixSetting = ({ setErrors, setSettingData, settingData, allMergedOptions, optionsForDealTypeOne, dealTypeSlug }) => {
    const [screenLoader, setScreenLoader] = useState(true)
    const [dealOptionForTypeOne, setDealOptionForTypeOne] = useState(optionsForDealTypeOne)
    const [popup, setPopup] = useState(false)
    const [serviceType, setServiceType] = useState('')
    const [searchApplicable, setSearchApplicable] = useState('')
    const [selectedApplicableItemsArray, setSelectedApplicableItemsArray] = useState([])

    useEffect(() => {
        setTimeout(() => {
            setScreenLoader && setScreenLoader(false)
        }, 1000);
    }, [])


    useEffect(() => {
        const applicableServicesSet = new Set(settingData?.applicableItemsSettings?.itemIds?.map(service => service));
        const filtered = allMergedOptions?.filter((itm) => applicableServicesSet.has(itm?.id));
        setSelectedApplicableItemsArray(filtered)
    }, [allMergedOptions, settingData?.applicableItemsSettings?.itemIds])

    useEffect(() => {
        // filtering deal type one options
        if (dealTypeSlug == "spend-some-amount-get-some-reward") {
            let filtered = optionsForDealTypeOne;
            filtered = optionsForDealTypeOne?.filter((type) =>
                !settingData?.spendTargetCategoryIds?.includes(type?.value) // Assuming that brand has an id property
            );
            setDealOptionForTypeOne([...filtered]); // Creating a new array before setting state
        }
    }, [dealTypeSlug, settingData?.spendTargetCategoryIds?.length, dealOptionForTypeOne?.length]);

    // handle change for deal type 3 
    const handleChangeForDealType3 = (e, type, name) => {
        const { value } = e.target
        if (type == 'applicableItemsSettings') {
            setSettingData({
                ...settingData,
                applicableItemsSettings: {
                    ...settingData?.applicableItemsSettings,
                    [name]: value,
                }
            })
        }
    }

    // data handle change function
    const handleSettingChange = (e) => {
        const { name, value } = e.target;
        setSettingData((prev) => ({
            ...prev,
            [name]: value
        }))
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
    }

    // handle change for deal type 3 add data
    const handleChangeForDealType3AddData = (e, data, type, name) => {
        // for deal type 3 applicable discount dropdown
        if (type == 'applicableItemsSettings') {
            setSettingData({
                ...settingData,
                applicableItemsSettings: {
                    ...settingData?.applicableItemsSettings,
                    [name]: [...data]
                }
            })
        }
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
        setPopup(false)
        setServiceType('')
    }

    // handle change for deal type 3 dropdown
    const handleChangeForDealType3Dropdown = (e, type, name) => {
        const { value } = e.target
        // for deal type 6 applicable discount dropdown
        if (type == 'applicableItemsSettings') {
            if (name == 'itemIds') {
                const existingItms = settingData?.applicableItemsSettings?.itemIds || []
                setSettingData({
                    ...settingData,
                    applicableItemsSettings: {
                        ...settingData?.applicableItemsSettings,
                        [name]: [...existingItms, value]
                    }
                })
            } else {
                if (name == 'isFree') {
                    if (value == true) {
                        let prevData = { ...settingData?.applicableItemsSettings }
                        delete prevData?.discount
                        setSettingData({
                            ...settingData,
                            applicableItemsSettings: {
                                ...prevData,
                                [name]: value
                            }
                        })
                    } else {
                        setSettingData({
                            ...settingData,
                            applicableItemsSettings: {
                                ...settingData?.applicableItemsSettings,
                                [name]: value
                            }
                        })
                    }
                }
            }
        }
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
    }

    console.log()

    // handle change for dropdown add
    const handleChangeForDropdown = (e, type) => {
        const { name, value } = e.target;
        // for spendTargetCategoryIds
        if (type == 'spendTargetCategoryIds') {
            if (value == "all") {
                setSettingData((prev) => ({
                    ...prev,
                    spendTargetCategoryIds: optionsForDealTypeOne?.map((itm) => itm?.value)
                }))
            } else {
                const prevIds = settingData?.spendTargetCategoryIds || []
                const exist = settingData?.spendTargetCategoryIds?.find(itm => itm == value)
                if (!exist) {
                    setSettingData((prev) => ({
                        ...prev,
                        spendTargetCategoryIds: [...prevIds, value]
                    }))
                }
            }
        }
    }

    // removed selected Item from array
    const removeSelectedItem = (value, type) => {
        // for spendTargetCategoryIds
        if (type == 'spendTargetCategoryIds') {
            const filteredIds = settingData?.spendTargetCategoryIds?.filter((itm) => itm != value)
            setSettingData((prev) => ({
                ...prev,
                spendTargetCategoryIds: [...filteredIds]
            }))
        }
        // for deal type 6
        if (type == 'applicableItemsSettings') {
            const updatedApplicableItems = settingData?.applicableItemsSettings?.itemIds?.filter((itm) => itm != value)
            setSettingData((prev) => ({
                ...prev,
                applicableItemsSettings: {
                    ...prev?.applicableItemsSettings,
                    itemIds: updatedApplicableItems
                }
            }))
        }
    }

    return {
        handleChangeForDealType3Dropdown, handleChangeForDealType3, removeSelectedItem, screenLoader, handleSettingChange,
        handleChangeForDropdown, dealOptionForTypeOne, popup, setPopup, serviceType, setServiceType, searchApplicable,
        setSearchApplicable, selectedApplicableItemsArray, setSelectedApplicableItemsArray, handleChangeForDealType3AddData
    }
};

export default useDealTypeSixSetting