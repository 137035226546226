import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import PhoneInput from 'Components/TenantApp/Elements/PhoneInput'
import React from 'react'

const ClientsStep = ({ isClientEmailValid, handleChange, formData, errors, btn_loading, setErrors }) => {
  return (
    <div className='my-[2rem] flex flex-col gap-4'>
      <h1 className='font-semibold text-xl'>Add your first client</h1>
      <div>
        <LoginInput
          required={false}
          specialCharacterNotAllowed={true}
          value={formData?.client?.name}
          name={'name'}
          // charactersMaxLength={25}
          onChange={(e) => { handleChange(e, 'client') }}
          title={'Client Name'}
          placeholder={'Client Name'}
          error={(errors?.name && !formData?.client?.name && !btn_loading) && errors?.name}
        />
      </div>
      <div>
        <LoginInput
          required={false}
          value={formData?.client?.email}
          name={'email'}
          onChange={(e) => {
            handleChange(e, 'client')
            setErrors((prev) => ({
              ...prev,
              client_email: ""
            }))
          }}
          title={'Email'}
          placeholder={'Enter Email'}
          error={!isClientEmailValid ? 'Email is invalid' : errors?.client_email ? "Email already exist" : false}
        />
      </div>
      <div>
        <PhoneInput
          required={false}
          value={formData?.client?.phone_number}
          name={'phone_number'}
          onChange={(e) => { handleChange(e, 'client') }}
          title={'Phone Number'}
          edit={formData?.client?.phone_number ? true : false}
          placeholder={'Enter Phone Number'}
          error={(errors?.phone_number && !formData?.client?.phone_number?.split("-")[1] && !btn_loading) && errors?.phone_number}
        />
      </div>
    </div>
  )
}

export default ClientsStep