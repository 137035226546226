import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getEmployeeDropDownList, getEmployeeDropDownListPagination } from "Adapters/Api/employee";
import { addStaffTarget, editStaffTarget } from "Adapters/Api/staffTarget";
import { add_StaffTarget, update_StaffTarget } from "Redux/TenantRedux/Actions/staffTarget";
import { MONTHS } from "Constants/Data/Months";
import { useLocation } from "new-components/location/helper";
import { useStaffTarget } from "../helper";

let employeeTimeOutId = undefined;

export const useAddStaffTarget = ({ handleClose, isUpdate, updateData }) => {
  const {
    fetchStaffTargetListNoPagination,
    fetchStaffTargetList,
    selected_location,
    staffTargetfilter,
    navigate,
    setCurrentPage, searchText, searchMonth, searchYear,
  } = useStaffTarget();

  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const { id } = getDataFromLocalStorage("business_data");

  const dispatch = useDispatch();
  const { locationDetail } = useLocation()
  const state = useSelector((state) => state);
  const employeeListRedux = state?.employee?.employeesDropdown;
  const businessId = state?.business?.business?.id;
  const currency = state?.business?.business?.currency?.code;
  const [isLoading, setIsLoading] = useState(false);
  const [YearValue, setYearValue] = useState(undefined);
  const [MonthValue, setMonthValue] = useState(undefined);
  const [serviceTargetValue, setServiceTargetValue] = useState(undefined);
  const [retailTargetValue, setRetailTargetValue] = useState(undefined);
  const [staff, setStaff] = useState(undefined);
  const [data, setData] = useState({
    current_year: '',
    current_month: '',
    current_date: '',
    selected_month: '',
  })
  const [staffTarget, setStaffTarget] = useState({
    staff: "",
    month: "",
    serviceTarget: "",
    retailTarget: ""
  })
  const {
    handleSubmit,
    getValues,
    register,
    setValue,
    trigger,
    watch,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(vaildationSchema),
    mode: "onBlur"
  });

  const [employeeSearch, setEmployeeSearch] = useState(null);
  const [employeePage, setEmployeePage] = useState(1)
  const [employeeLoader, setEmployeeLoader] = useState(false)
  const employeePaginationData = state?.employee?.employeeDropdownPaginationData


  useEffect(() => {
    let date = new Date();
    let today_year = date.getFullYear();
    let today_month = date.getMonth();
    let today_date = date.getDate();
    let today_day = date.getDay();

    setData({
      ...data,
      current_year: today_year,
      current_month: today_month,
      current_date: today_date,
      selected_month: MONTHS[today_month],
    });
  }, [])


  useEffect(() => {
    if (selected_location) {
      if (employeeSearch === null) {
        // Initial load with empty search_text
        dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
      } else {
        if (employeeTimeOutId) {
          clearTimeout(employeeTimeOutId)
        }
        employeeTimeOutId = setTimeout(() => {
          dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
        }, 500);
      }
    }
  }, [employeeSearch, selected_location, employeePage])

  useEffect(() => {
    const { employee, month, service_target, retail_target, year } = updateData;
    if (isUpdate) {
      setYearValue(year?.slice(0, 4))
      setStaff(employee?.id)
      setMonthValue(month)
      setServiceTargetValue(service_target)
      setRetailTargetValue(retail_target)
      reset({
        staff: employee?.id,
        month: month,
        year: year?.slice(0, 4),
        serviceTarget: service_target,
        retailTarget: retail_target,
      });
    }
  }, [updateData]);
  const { year } = updateData;

  const yearDropdown = useMemo(() => {
    if (data.current_year) {
      let years = [];
      for (let i = data.current_year; i <= 2040; i++) {
        years.push({ label: i.toString(), value: i.toString() })
      }
      return years;
    }
  }, [data.current_year])

  const monthDropdown = useMemo(() => {
    let selected_year = watch('year');
    if (selected_year === undefined || selected_year === null) {
      let months = MONTHS?.map((month, i) => {
        return {
          label: month,
          value: month,
          disabled: true
        };
      })
      return months;
    }
    if (selected_year !== null || selected_year !== undefined || selected_year !== '') {
      let months = MONTHS?.map((month, i) => {
        return {
          label: month,
          value: month,
          disabled:
            parseInt(selected_year) < parseInt(data.current_year) ? true
              :
              parseInt(selected_year) === parseInt(data.current_year) ?
                data.current_month > i ? true
                  : false
                : false
        };
      })
      return months;
    }

  }, [watch('year')])

  const onSubmitUpdateHandler = async (data) => {

    const { month, retailTarget, serviceTarget, staff, year } = data;
    setIsLoading(true)
    const body = {
      id: updateData.id,
      employee: staff,
      month: month,
      year: year,
      service_target: serviceTarget,
      retail_target: retailTarget
    }
    const response = await editStaffTarget(body, access_token)
    if (response.status === 200) {
      let staffTarget = response?.data?.response?.stafftarget;
      let successMessage = response?.data?.response?.message;
      dispatch(update_StaffTarget(staffTarget));
      handleClose()
      setCurrentPage(1)
      fetchStaffTargetListNoPagination(selected_location, access_token)
      fetchStaffTargetList(selected_location, 1, searchText, searchMonth, searchYear)
      setIsLoading(false);
      toast.success(successMessage, { toastId: "toast" });
    } else {
      setIsLoading(false);
      toast.error("Error in Update staff target", { toastId: "toast" });
    }
  }

  const onSubmit = async (data) => {
    const { month, retailTarget, serviceTarget, staff, year } = data;
    if (isUpdate) {
      onSubmitUpdateHandler(data)
    } else {
      setIsLoading(true)
      const body = {
        business: businessId,
        employee: staff,
        month: month,
        year: year,
        service_target: serviceTarget,
        retail_target: retailTarget
      }
      const response = await addStaffTarget(body, access_token)
      if (response.status === 201) {
        let staffTarget = response?.data?.response?.stafftarget;
        let successMessage = response?.data?.response?.message;
        dispatch(add_StaffTarget(staffTarget));
        handleClose()
        setCurrentPage(1)
        fetchStaffTargetListNoPagination(selected_location, access_token)
        fetchStaffTargetList(selected_location, 1, searchText, searchMonth, searchYear)
        setIsLoading(false);
        toast.success(successMessage, { toastId: "toast" });
      } else {
        setIsLoading(false);
        toast.error("Error in create staff target", { toastId: "toast" });
      }
    }
  }

  const staffDropdownList = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    // const list = employeeListRedux.filter(obj => {
    //   if (!obj.id) {
    //     return false
    //   }

    //   let locations = obj?.location?.filter(loc => loc.id == state?.locations?.selected_location)
    //   if (locations?.length > 0) {
    //     return true
    //   }
    // }).map((obj) => {
    //   return {
    //     value: obj.id,
    //     label: obj.full_name,
    //   };
    // });
    const list = employeeListRedux.map((empl, i) => {
      return {
        label: empl.full_name,
        value: empl.id,
        otherData: {
          image: empl?.image,
          DataOne: empl.email,
          showImage: true,
          index: i
        },
        email: empl.email,
        mobile_number: empl.mobile_number
      };
    });
    return list;
  }, [employeeListRedux?.length]);

  return {
    handleSubmit,
    register,
    onSubmit,
    errors,
    control,
    isValid,
    isLoading,
    staffTarget,
    staffDropdownList,
    monthDropdown,
    yearDropdown,
    currency, locationDetail,
    YearValue, setYearValue,
    MonthValue, setMonthValue,
    staff, setStaff,
    staffTargetfilter,
    navigate, dispatch,
    serviceTargetValue, setServiceTargetValue,
    retailTargetValue, setRetailTargetValue,
    employeeSearch, setEmployeeSearch, employeePage, setEmployeePage, employeeLoader, setEmployeeLoader, employeePaginationData
  }
}

export const vaildationSchema = yup.object().shape({
  staff: yup.string().required("This field is required").nullable(),
  month: yup.string().required("This field is required").nullable(),
  year: yup.string().required("This field is required").nullable(),
  serviceTarget: yup.string().required("This field is required"),
  retailTarget: yup.string().required("This field is required"),
});