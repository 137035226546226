import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GET_MY_APPOINTMENTS } from "Redux/TenantRedux/ActionTypes/Appointment/Appointment";
import { toast } from "react-toastify";
import moment from "moment";

function CalendarDNDHelper() {
    const dispatch = useDispatch();
    // =========== Think Again Before Changing This Function ==========



    function CalendarDND(setUpdateAppointmentLoader,setActiveDragStartTime, setUpdateAppointmentData, hasMouse, isTouchDevice, closeDraging, BASE_URL, SelectedDate, SelectedEmployee, selectedLocation, FinalData, getEmployeeLoader, appointmentLoader, appointmentDetail, setAppointmentDetail, setAppGroupId, columnWidth) {
        useEffect(() => {
            setTimeout(() => {
                // get the updated data from api
                let employeeData
                if (SelectedEmployee === "All") {
                    employeeData = "All";
                } else {
                    employeeData = [{ id: SelectedEmployee }]
                }
                var dateeee = new Date();

                let block_fn = () => { };
                if (!getEmployeeLoader && !appointmentLoader) {
                    // console.log("DND Code Rendered");
                    let AppointmentCard_Drag = document.querySelectorAll('.AppointmentCard_Drag');
                    let Drag_Drop_Column = document.querySelectorAll('.Drag_Drop_Column');
                    let dropped_div = false;
                    let drop_column = false;
                    let api_data = false
                    const main_drag_drop_fn = () => {
                        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
                        //  remove all bgs
                        const remove_all_bgs = () => {
                            Drag_Drop_Column.forEach(all => all.classList.remove('bg-gray-50'));
                        }


                        if (AppointmentCard_Drag.length > 0) {
                            AppointmentCard_Drag.forEach(myDiv => {

                                const parentElement = myDiv.parentNode; // Get the parent node 

                                // Set initial position

                                //  check whether user wants to click or drag
                                let mouseDownFn = () => {
                                    let children_id = myDiv.querySelector('.children_id')
                                    let dragging = false;
                                    if (!isTouchDevice && hasMouse) {
                                        // For Mouse Only
                                        // if user wants to move mouse after click
                                        myDiv.onmousemove = () => {
                                            dragging = true
                                            // myDiv.onmousedown = dragMouseDown;
                                            // Function to start the drag
                                            function dragMouseDown(e) {
                                                e = e || window.event;
                                                e.preventDefault();
                                                // Get the mouse cursor position at startup
                                                pos3 = e.clientX;
                                                pos4 = e.clientY;
                                                // Call a function whenever the cursor moves
                                                document.onmousemove = elementDrag;
                                                document.onmouseup = closeDragElement;
                                            }
                                            dragMouseDown();

                                            // Function to move the div
                                            function elementDrag(e) {
                                                setUpdateAppointmentLoader(true)
                                                e = e || window.event;
                                                e.preventDefault();
                                                children_id.classList.remove('group/item')
                                                // Set the element's new styling
                                                myDiv.style.width = `${document.querySelector('.Drag_Drop_Column').clientWidth}px`;
                                                myDiv.style.height = `${(myDiv.offsetHeight)}px`;

                                                var shadowBox
                                                if (!localStorage.getItem('appointmentShadowBox')) {
                                                    shadowBox = document.createElement('div');
                                                }

                                                // show a shadowBox while DND
                                                if (shadowBox) {
                                                    localStorage.setItem('appointmentShadowBox', shadowBox)
                                                    shadowBox.style.width = myDiv.style.width ? myDiv.style.width : '100%';
                                                    shadowBox.style.height = myDiv.style.height;
                                                    shadowBox.innerHTML = myDiv.innerHTML;
                                                    shadowBox.style.backgroundColor = '#E8E8E8';
                                                    shadowBox.style.opacity = 0.5;

                                                    shadowBox.classList.add('appointment__shadowBox');
                                                    shadowBox.style.top = `${(myDiv.offsetTop - pos2)}px`;
                                                    shadowBox.style.left = `${(myDiv.offsetLeft - pos1)}px`;
                                                    shadowBox.style.position = 'absolute';
                                                    shadowBox.style.margin = '0';
                                                    const clonedShadowBox = shadowBox.cloneNode(true);
                                                    parentElement.insertBefore(clonedShadowBox, myDiv.nextSibling); // Insert a clone of shadowBox after myDiv
                                                }



                                                // Calculate the new cursor position
                                                pos1 = pos3 - e.clientX;
                                                pos2 = pos4 - e.clientY;
                                                pos3 = e.clientX;
                                                pos4 = e.clientY;

                                                myDiv.style.top = `${(myDiv.offsetTop - pos2)}px`;
                                                myDiv.style.left = `${(myDiv.offsetLeft - pos1)}px`;

                                                myDiv.style.position = 'absolute';
                                                myDiv.style.margin = '0';


                                                function getOverlapArea(elem1, elem2) {
                                                    let rect1 = elem1.getBoundingClientRect();
                                                    let rect2 = elem2.getBoundingClientRect();

                                                    let xOverlap = Math.max(0, Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left));
                                                    let yOverlap = Math.max(0, Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top));

                                                    return xOverlap * yOverlap;
                                                }

                                                let maxOverlapArea = 0;
                                                let maxOverlapColumn = null;

                                                if (SelectedEmployee == "All") {
                                                    Drag_Drop_Column.forEach(ele => {
                                                        let overlapArea = getOverlapArea(myDiv, ele);

                                                        if (overlapArea > maxOverlapArea) {
                                                            maxOverlapArea = overlapArea;
                                                            maxOverlapColumn = ele;
                                                        }
                                                    });

                                                    if (maxOverlapColumn !== null) {
                                                        remove_all_bgs();
                                                        // maxOverlapColumn.classList.add('bg-gray-50');
                                                        drop_column = maxOverlapColumn;
                                                    }
                                                } else {
                                                    let singleCol = document.querySelector(`.Drag_Drop_Column[employee_id="${SelectedEmployee}"]`)
                                                    if (singleCol) {
                                                        // singleCol.classList.add('bg-gray-50');
                                                        drop_column = singleCol
                                                    }
                                                }

                                                if (drop_column) {
                                                    let time_block = document.querySelectorAll('.time_block');
                                                    time_block.forEach(block => {
                                                        const rect1 = block.getBoundingClientRect();
                                                        const rect2 = myDiv.getBoundingClientRect();

                                                        let num1 = Math.floor(rect1.y);
                                                        let num2 = Math.floor(rect2.y);
                                                        let maxDifference = 5;

                                                        // calculate time block on which div is dropped
                                                        if (Math.abs(num1 - num2) <= maxDifference) {
                                                            const blockStrings = [...(block.classList)].filter(str => /^block-/.test(str))[0]?.split('-')[1];
                                                            let rowDiff = +(myDiv.style.gridRowEnd - myDiv.style.gridRowStart);
                                                            let rowStart = +blockStrings + 5; // space to leave from the top of the column to render appointment
                                                            let rowEnd = rowStart + rowDiff;
                                                            let gridArea = `${rowStart} / 1 / ${rowEnd} / 2`;
                                                            setActiveDragStartTime(block.getAttribute('time'))
                                                            // add grid area for the new position of the dropped div
                                                            myDiv.style.gridArea = gridArea;

                                                            // let duration_time = myDiv.querySelector('.duration_time');
                                                            // duration_time.innerText = new Date('1970-01-01T' + block.getAttribute('time') + 'Z')
                                                            //     .toLocaleTimeString('en-US',
                                                            //         { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
                                                            //     );

                                                            // check employee id on which appointment is assigned
                                                            let dropped_employee_id = drop_column.getAttribute('employee_id');

                                                            // final data to update dropped appointment
                                                            api_data = {
                                                                "employee_id": dropped_employee_id,
                                                                "start_time": block.getAttribute('time'),
                                                                "id": myDiv.children[0].getAttribute('appointment_id'),
                                                                'get_calendar_selected_date': moment(SelectedDate ? SelectedDate : dateeee.toLocaleDateString()).format('YYYY-MM-DD'),
                                                                'get_calendar_employee': employeeData,
                                                                'get_calendar_location_id': selectedLocation
                                                            };
                                                        }
                                                    })
                                                }
                                            }


                                            // Function to end the drag
                                            function closeDragElement() {
                                                setActiveDragStartTime(null)
                                                children_id.classList.add('group/item')
                                                // Remove shadowBox from localStorage 
                                                localStorage.removeItem('appointmentShadowBox')
                                                const elementsToRemove = document.querySelectorAll('.appointment__shadowBox');
                                                for (const element of elementsToRemove) {
                                                    element.parentNode.removeChild(element);
                                                }

                                                remove_all_bgs();
                                                // Stop moving when mouse button is released
                                                document.onmouseup = null;
                                                // document.onmousedown = null;
                                                document.onmousemove = null;
                                                Drag_Drop_Column.forEach(ele => {
                                                    ele.onmouseover = null
                                                });

                                                if (drop_column) {

                                                    // Set the styles to the new div generated 
                                                    dropped_div = document.createElement('div');
                                                    dropped_div.innerHTML = myDiv.innerHTML;
                                                    dropped_div.classList = myDiv.classList;
                                                    dropped_div.style.cssText = myDiv.style.cssText;
                                                    dropped_div.style.top = 'initial';
                                                    dropped_div.style.left = 'initial';
                                                    dropped_div.style.position = 'static'
                                                    dropped_div.classList.add('drop');
                                                    dropped_div.classList.add('dropped_div');



                                                    drop_column.appendChild(dropped_div);
                                                    myDiv.classList.add('drop');
                                                    myDiv.classList.add('hidden');


                                                    AppointmentCard_Drag = document.querySelectorAll('.AppointmentCard_Drag');
                                                    Drag_Drop_Column = document.querySelectorAll('.Drag_Drop_Column');

                                                    // Hit api here, the dragging div is dropped to the new location -------->
                                                    const data = api_data;
                                                    // console.log(data,'data ------');
                                                    if (api_data && (myDiv.children[0].getAttribute('appointment_time') != api_data?.start_time || myDiv.children[0].getAttribute('employee_id') != api_data?.employee_id)) {
                                                        let access_token = localStorage.getItem("access_token");
                                                        if (access_token) {
                                                            access_token = access_token?.replaceAll('"', "");
                                                        }
                                                        setUpdateAppointmentLoader(true)
                                                        fetch(BASE_URL + '/appointment/update_appointment/', {
                                                            method: 'PUT', // or 'PUT'
                                                            headers: {
                                                                'Authorization': `Token ${access_token}`,
                                                                'Content-Type': 'application/json',
                                                            },
                                                            body: JSON.stringify(data),
                                                        })
                                                            .then((response) => response.json())
                                                            .then((data) => {
                                                                setUpdateAppointmentLoader(false)
                                                                if (data.status) {
                                                                    setUpdateAppointmentData(data)
                                                                    dispatch({
                                                                        type: GET_MY_APPOINTMENTS,
                                                                        payload: data.response.appointments,
                                                                    });
                                                                    toast.success('Appointment update successfully', { toastId: "toast" })
                                                                    const divs = document.querySelectorAll('.AppointmentCard_Drag:not(.hidden)');
                                                                    // Check for overlap between each pair of divs
                                                                    for (let i = 0; i < divs.length; i++) {
                                                                        for (let j = i + 1; j < divs.length; j++) {
                                                                            // Get the bounding rectangles of the two divs
                                                                            const rect1 = divs[i].getBoundingClientRect();
                                                                            const rect2 = divs[j].getBoundingClientRect();
                                                                            // Check for overlap
                                                                            if (!(rect1.right < rect2.left ||
                                                                                rect1.left > rect2.right ||
                                                                                rect1.bottom < rect2.top ||
                                                                                rect1.top > rect2.bottom)) {
                                                                                // Add class to overlapping divs
                                                                                divs[i].classList.add('overlap_');
                                                                                divs[j].classList.add('overlap_');
                                                                            }
                                                                        }
                                                                    }

                                                                    let overlap_s = document.querySelectorAll('.overlap_:not(.hidden)');
                                                                    if (overlap_s.length > 0) {
                                                                        overlap_s.forEach((ele, index) => {
                                                                            // let width = (1 / overlap_s.length) * 100 == 100 ? (1 / overlap_s.length) * 100 == 100 : (1 / overlap_s.length) * 100 - 0.8;
                                                                            let width = (columnWidth / (overlap_s.length > 0 ? overlap_s.length : 1)) - 1;
                                                                            let margin = (1 / overlap_s.length) * 100 * index;

                                                                            // ele.style.width = width + '%';
                                                                            ele.style.width = width ;
                                                                            // ele.style.marginLeft = margin + '%';
                                                                            ele.style.marginLeft = `${columnWidth * margin / 100}px`;
                                                                            overlap_s.forEach(ele => {
                                                                                ele.classList.remove('overlap_')
                                                                            })
                                                                        })
                                                                    }

                                                                }
                                                            })
                                                            .catch((error) => {
                                                                setUpdateAppointmentLoader(false)
                                                                // console.error('Error:', error);
                                                            });
                                                    }


                                                    // Recall the funtion for dropped element
                                                    dropped_div = false;
                                                    drop_column = false;
                                                    // api_data = false
                                                    main_drag_drop_fn();
                                                } else {
                                                    dragging = false
                                                }
                                            }
                                            closeDraging = closeDragElement

                                        }

                                        // Check if the mouse even was the click event or the dragging event
                                        myDiv.onmouseup = () => {
                                            if (dragging == false) {
                                                if (myDiv.classList.contains('clickable_div')) {
                                                    if (myDiv.classList.contains('is_blocked')) {
                                                        block_fn()
                                                    } else {
                                                        let children_id = myDiv.querySelector('.children_id')
                                                        if (children_id) {
                                                            // Assuming 'openCardDiv' is the class of the element you want to attach the click event to
                                                            const openCardDivElement = children_id.querySelector('.openCardDiv');
                                                            if (openCardDivElement) {
                                                                openCardDivElement.addEventListener('click', (e) => {
                                                                    e.stopPropagation(); // Prevent the click event from bubbling up
                                                                    setAppGroupId({
                                                                        appGroupId: children_id.getAttribute('parent_id'),
                                                                        serviceId: children_id.getAttribute('appointment_id'),
                                                                        appointment_group_id: children_id.getAttribute('appointment_group_id')
                                                                    })
                                                                    setAppointmentDetail(true);
                                                                });
                                                            }
                                                            if (isTouchDevice && !hasMouse) {
                                                                setAppGroupId({
                                                                    appGroupId: children_id.getAttribute('parent_id'),
                                                                    serviceId: children_id.getAttribute('appointment_id'),
                                                                    appointment_group_id: children_id.getAttribute('appointment_group_id')
                                                                })
                                                                setAppointmentDetail(true);
                                                            }
                                                            document.ontouchend = null;
                                                            document.ontouchmove = null;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        // For Touch Only
                                        // For Mobile
                                        myDiv.ontouchmove = null
                                        myDiv.addEventListener('touchmove', () => {
                                            dragging = true
                                            // Function to start the drag
                                            function dragMouseDown(e) {
                                                e = e || window.event;
                                                e.preventDefault();
                                                // Get the mouse cursor position at startup

                                                // Call a function whenever the cursor moves
                                                document.ontouchmove = null;
                                                myDiv.addEventListener('touchmove', elementDrag, { passive: false });
                                                document.ontouchend = null;
                                                myDiv.addEventListener('touchend', closeDragElement, { passive: false });
                                            }
                                            dragMouseDown();


                                            // Function to move the div
                                            function elementDrag(e) {
                                                setUpdateAppointmentLoader(true)
                                                const main__calendar__parent = document.getElementById('main__calendar__parent')
                                                if (isTouchDevice && !hasMouse) {
                                                    main__calendar__parent.classList.add('overflow-hidden')
                                                    main__calendar__parent.classList.remove('overflow-auto')
                                                }
                                                e = e || window.event;
                                                e.preventDefault();


                                                // Set the element's new styling
                                                myDiv.style.width = `${document.querySelector('.Drag_Drop_Column').clientWidth}px`;
                                                myDiv.style.height = `${(myDiv.offsetHeight)}px`;


                                                var shadowBox
                                                if (!localStorage.getItem('appointmentShadowBox')) {
                                                    shadowBox = document.createElement('div');
                                                }

                                                // show a shadowBox while DND
                                                if (shadowBox) {
                                                    localStorage.setItem('appointmentShadowBox', shadowBox)
                                                    shadowBox.style.width = myDiv.style.width;
                                                    shadowBox.style.height = myDiv.style.height;
                                                    shadowBox.innerHTML = myDiv.innerHTML;
                                                    shadowBox.style.backgroundColor = '#E8E8E8';
                                                    shadowBox.style.opacity = 0.5;

                                                    shadowBox.classList.add('appointment__shadowBox');
                                                    shadowBox.style.top = `${(myDiv.offsetTop - pos2)}px`;
                                                    shadowBox.style.left = `${(myDiv.offsetLeft - pos1)}px`;
                                                    shadowBox.style.position = 'absolute';
                                                    shadowBox.style.margin = '0';
                                                    const clonedShadowBox = shadowBox.cloneNode(true);
                                                    parentElement.insertBefore(clonedShadowBox, myDiv.nextSibling); // Insert a clone of shadowBox after myDiv
                                                }


                                                const touchX = e.touches[0].clientX;
                                                const touchY = e.touches[0].clientY;

                                                // Update the element's position
                                                myDiv.style.left = (touchX - (myDiv.offsetWidth / 2)) + 'px';
                                                myDiv.style.top = (touchY - (myDiv.offsetHeight / 2)) + 'px';


                                                myDiv.style.position = 'fixed';
                                                myDiv.style.margin = '0';


                                                function getOverlapArea(elem1, elem2) {
                                                    let rect1 = elem1.getBoundingClientRect();
                                                    let rect2 = elem2.getBoundingClientRect();

                                                    let xOverlap = Math.max(0, Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left));
                                                    let yOverlap = Math.max(0, Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top));

                                                    return xOverlap * yOverlap;
                                                }

                                                let maxOverlapArea = 0;
                                                let maxOverlapColumn = null;

                                                if (SelectedEmployee == "All") {
                                                    Drag_Drop_Column.forEach(ele => {
                                                        let overlapArea = getOverlapArea(myDiv, ele);

                                                        if (overlapArea > maxOverlapArea) {
                                                            maxOverlapArea = overlapArea;
                                                            maxOverlapColumn = ele;
                                                        }
                                                    });

                                                    if (maxOverlapColumn !== null) {
                                                        remove_all_bgs();
                                                        // maxOverlapColumn.classList.add('bg-gray-50');
                                                        drop_column = maxOverlapColumn;
                                                    }
                                                } else {
                                                    let singleCol = document.querySelector(`.Drag_Drop_Column[employee_id="${SelectedEmployee}"]`)
                                                    if (singleCol) {
                                                        // singleCol.classList.add('bg-gray-50');
                                                        drop_column = singleCol
                                                    }
                                                }

                                                if (drop_column) {
                                                    let time_block = document.querySelectorAll('.time_block');
                                                    time_block.forEach(block => {
                                                        const rect1 = block.getBoundingClientRect();
                                                        const rect2 = myDiv.getBoundingClientRect();

                                                        let num1 = Math.floor(rect1.y);
                                                        let num2 = Math.floor(rect2.y);
                                                        let maxDifference = 5;

                                                        // calculate time block on which div is dropped
                                                        if (Math.abs(num1 - num2) <= maxDifference) {
                                                            const blockStrings = [...(block.classList)].filter(str => /^block-/.test(str))[0]?.split('-')[1];
                                                            let rowDiff = +(myDiv.style.gridRowEnd - myDiv.style.gridRowStart);
                                                            let rowStart = +blockStrings + 5; // space to leave from the top of the column to render appointment
                                                            let rowEnd = rowStart + rowDiff;
                                                            let gridArea = `${rowStart} / 1 / ${rowEnd} / 2`;

                                                            // add grid area for the new position of the dropped div
                                                            myDiv.style.gridArea = gridArea;

                                                            // let duration_time = myDiv.querySelector('.duration_time');
                                                            // duration_time.innerText = new Date('1970-01-01T' + block.getAttribute('time') + 'Z')
                                                            //     .toLocaleTimeString('en-US',
                                                            //         { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
                                                            //     );

                                                            // check employee id on which appointment is assigned
                                                            let dropped_employee_id = drop_column.getAttribute('employee_id');


                                                            // final data to update dropped appointment
                                                            api_data = {
                                                                "employee_id": dropped_employee_id,
                                                                "start_time": block.getAttribute('time'),
                                                                "id": myDiv.children[0].getAttribute('appointment_id'),
                                                                'get_calendar_selected_date': moment(SelectedDate ? SelectedDate : dateeee.toLocaleDateString()).format('YYYY-MM-DD'),
                                                                'get_calendar_employee': employeeData,
                                                                'get_calendar_location_id': selectedLocation
                                                            };
                                                        }
                                                    })
                                                }
                                            }


                                            // Function to end the drag
                                            function closeDragElement() {
                                                // console.log('closeDragElement in touch');
                                                const main__calendar__parent = document.getElementById('main__calendar__parent')
                                                if (isTouchDevice && !hasMouse) {
                                                    main__calendar__parent.classList.remove('overflow-hidden')
                                                    main__calendar__parent.classList.add('overflow-auto')
                                                }

                                                // Remove shadowBox from localStorage 
                                                localStorage.removeItem('appointmentShadowBox')
                                                const elementsToRemove = document.querySelectorAll('.appointment__shadowBox');
                                                for (const element of elementsToRemove) {
                                                    element.parentNode.removeChild(element);
                                                }


                                                remove_all_bgs();
                                                // Stop moving when mouse button is released
                                                document.ontouchend = null;
                                                document.ontouchmove = null;
                                                Drag_Drop_Column.forEach(ele => {
                                                    ele.touchenter = null
                                                });

                                                if (drop_column) {

                                                    // Set the styles to the new div generated 
                                                    dropped_div = document.createElement('div');
                                                    dropped_div.innerHTML = myDiv.innerHTML;
                                                    dropped_div.classList = myDiv.classList;
                                                    dropped_div.style.cssText = myDiv.style.cssText;
                                                    dropped_div.style.top = 'initial';
                                                    dropped_div.style.left = 'initial';
                                                    dropped_div.style.position = 'static'
                                                    dropped_div.classList.add('drop');
                                                    dropped_div.classList.add('dropped_div');



                                                    drop_column.appendChild(dropped_div);
                                                    myDiv.classList.add('drop');
                                                    myDiv.classList.add('hidden');


                                                    AppointmentCard_Drag = document.querySelectorAll('.AppointmentCard_Drag');
                                                    Drag_Drop_Column = document.querySelectorAll('.Drag_Drop_Column');

                                                    // Hit api here, the dragging div is dropped to the new location -------->
                                                    const data = api_data;
                                                    if (api_data && (myDiv.children[0].getAttribute('appointment_time') != api_data?.start_time || myDiv.children[0].getAttribute('employee_id') != api_data?.employee_id)) {
                                                        let access_token = localStorage.getItem("access_token");
                                                        if (access_token) {
                                                            access_token = access_token?.replaceAll('"', "");
                                                        }
                                                        setUpdateAppointmentLoader(true)
                                                        fetch(BASE_URL + '/appointment/update_appointment/', {
                                                            method: 'PUT', // or 'PUT'
                                                            headers: {
                                                                'Authorization': `Token ${access_token}`,
                                                                'Content-Type': 'application/json',
                                                            },
                                                            body: JSON.stringify(data),
                                                        })
                                                            .then((response) => response.json())
                                                            .then((data) => {
                                                                setUpdateAppointmentLoader(false)
                                                                if (data.status) {
                                                                    const divs = document.querySelectorAll('.AppointmentCard_Drag:not(.hidden)');
                                                                    dispatch({
                                                                        type: GET_MY_APPOINTMENTS,
                                                                        payload: data.response.appointments,
                                                                    });
                                                                    toast.success('Appointment update successfully', { toastId: "toast" })
                                                                    // Check for overlap between each pair of divs
                                                                    for (let i = 0; i < divs.length; i++) {
                                                                        for (let j = i + 1; j < divs.length; j++) {
                                                                            // Get the bounding rectangles of the two divs
                                                                            const rect1 = divs[i].getBoundingClientRect();
                                                                            const rect2 = divs[j].getBoundingClientRect();
                                                                            // Check for overlap
                                                                            if (!(rect1.right < rect2.left ||
                                                                                rect1.left > rect2.right ||
                                                                                rect1.bottom < rect2.top ||
                                                                                rect1.top > rect2.bottom)) {
                                                                                // Add class to overlapping divs
                                                                                divs[i].classList.add('overlap_');
                                                                                divs[j].classList.add('overlap_');
                                                                            }
                                                                        }
                                                                    }

                                                                    let overlap_s = document.querySelectorAll('.overlap_:not(.hidden)');
                                                                    if (overlap_s.length > 0) {
                                                                        overlap_s.forEach((ele, index) => {
                                                                            // let width = (1 / overlap_s.length) * 100 == 100 ? (1 / overlap_s.length) * 100 == 100 : (1 / overlap_s.length) * 100 - 0.8;
                                                                            let width = (columnWidth / (overlap_s.length > 0 ? overlap_s.length : 1)) - 1;
                                                                            let margin = (1 / overlap_s.length) * 100 * index;

                                                                            // ele.style.width = width + '%';
                                                                            ele.style.width = width;
                                                                            // ele.style.marginLeft = margin + '%';
                                                                            ele.style.marginLeft = `${columnWidth * margin / 100}px`;
                                                                            overlap_s.forEach(ele => {
                                                                                ele.classList.remove('overlap_')
                                                                            })
                                                                        })
                                                                    }

                                                                }
                                                            })
                                                            .catch((error) => {
                                                                setUpdateAppointmentLoader(false)
                                                                // console.error('Error:', error);
                                                            });
                                                    }


                                                    // Recall the funtion for dropped element
                                                    dropped_div = false;
                                                    drop_column = false;
                                                    // api_data = false
                                                    main_drag_drop_fn();
                                                } else {
                                                    dragging = false
                                                }
                                            }

                                        }, { passive: false });

                                        // For Mobile
                                        myDiv.ontouchend = () => {
                                            if (dragging == false) {
                                                if (myDiv.classList.contains('clickable_div')) {
                                                    if (myDiv.classList.contains('is_blocked')) {
                                                        block_fn()
                                                    } else {
                                                        let children_id = myDiv.querySelector('.children_id')
                                                        if (children_id) {
                                                            // setAppointmentDetail(!appointmentDetail)
                                                            document.ontouchend = null;
                                                            document.ontouchmove = null;
                                                            // setAppGroupId({
                                                            //     appGroupId: children_id.getAttribute('appointment_id'),
                                                            //     serviceId: children_id.getAttribute('parent_id')
                                                            //   })
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }


                                };


                                myDiv.onmousedown = null;
                                myDiv.ontouchstart = null;
                                myDiv.addEventListener('touchstart', mouseDownFn, { passive: false });
                                myDiv.addEventListener('mousedown', mouseDownFn, { passive: false });
                                myDiv.addEventListener('mouseup', () => {
                                    if (closeDraging) {
                                        closeDraging()
                                    }
                                    myDiv.onmousedown = null;
                                    myDiv.onmousemove = null;
                                }, { passive: false });


                                // For mobile devices
                                // if (window.innerWidth < 991) {
                                //   myDiv.ontouchstart = () => {
                                //     if (myDiv.classList.contains('clickable_div')) {
                                //       if (myDiv.classList.contains('is_blocked')) {
                                //         block_fn()
                                //       } else {
                                //         let children_id = myDiv.querySelector('.children_id')
                                //         if (children_id) {
                                //           navigate(`/dashboard/calendar/appointment-detail/${children_id.getAttribute('appointment_id')}/?parent_id=${children_id.getAttribute('parent_id')}`)
                                //         }
                                //       }
                                //     }
                                //   }
                                // }


                            });
                        }
                    }
                    main_drag_drop_fn();
                }
            }, 1500);
        }, [SelectedDate, SelectedEmployee, selectedLocation, FinalData, getEmployeeLoader, appointmentLoader, appointmentDetail])
    }



    return {
        CalendarDND
    }

}

export default CalendarDNDHelper