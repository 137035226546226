import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deletePayroll } from "../../../../../Adapters/Api/payroll";
import useObjectKeys from "../../../../../Hooks/useObjectKeys";
import useValidations from "../../../../../Hooks/useValidations";
import { removePayroll } from "../../../../../Redux/TenantRedux/Actions/payrollActions";
import useSetDataInLocalStorage from "./../../../../../Hooks/useSetDataInLocalStorage";

function usePayroll() {
  // const payrollRedux = useSelector((state) => state?.payroll?.payrolls);
  const dispatch = useDispatch();

  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const { setErrors, validation, errors, onBlurHandler } = useValidations();
  const { objectKeyConvertToArray } = useObjectKeys();
  const [RangePopup, setRangePopup] = useState("");
  const [isDelete, setDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteId, setDeleteId] = useState();
  const [selectedDateRange, setSelectedDataRange] = useState({
    from: "",
    to: "",
  });

  // **************************** date range on change handler *************************
  const dateRangeHandleChange = (e) => {
    if (e?.target) {
      const { name, value } = e.target;
      setSelectedDataRange((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  // *******************  selected Range submit handler ************************************
  const onDateRangeSubmit = () => {
    setErrors(validation(selectedDateRange));
    let result = validation(selectedDateRange);
    if (objectKeyConvertToArray(result)?.length === 0) {
      setRangePopup("");
      setSelectedDataRange({
        from: "",
        to: "",
      });
    }
  };

  // **************************** delete Payroll *********************************
  const payrollDeleteHandler = async (id) => {
    if (deleteId && isDelete) {
      let response = await deletePayroll(deleteId, access_token);
      if (response.status_code === 200) {
        toast.success(response?.response?.message, { toastId: "toast" });
        dispatch(removePayroll(deleteId));
        setDelete(false);
        setDeleteId();
      }
    } else {
      setDelete(true);
      setDeleteId(id);
    }
  };

  return {
    payrollDeleteHandler,
    dateRangeHandleChange,
    selectedDateRange,
    RangePopup,
    setRangePopup,
    errors,
    onBlurHandler,
    onDateRangeSubmit,
    isDelete,
    setDelete,
    currentPage, setCurrentPage
  };
}

export default usePayroll;
