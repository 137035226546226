import React from 'react'

function CheckBox({ parentClass, disabled,label_text_transform, mainLabelClass, label_Class, name, onChange, value, checked, error, label, colorLabel1, colorLabel2, className, id, onClickLabel1, onClickLabel2 }) {
    var unique_id = Math.floor(Math.random() * 100) * Date.now();
    return (
        <div className={`flex items-center sm:gap-4 gap-2 ${parentClass ? parentClass : ""}`}>
            <input disabled={disabled} id={`${id ? id : unique_id}`} type="checkbox" name={name} onChange={onChange} value={value} checked={checked} className={`${className ? className : "accent-[#5679FF] h-[1.3rem] w-[1.3rem]"} ${disabled && "cursor-not-allowed"} `} />
            <label htmlFor={`${id ? id : unique_id}`} className={`${mainLabelClass}`} >
                <p className={`text-sm font-semibold cursor-pointer ${label_Class ? label_Class : ' text-[#7B7B7B]'} ${label_text_transform ? label_text_transform : 'normal-case'}`}>
                    {label}
                    <span className='text-primary' onClick={onClickLabel1 && onClickLabel1}>{colorLabel1}  </span>  {colorLabel2 && <span> <span>&</span> <span className='text-primary' onClick={onClickLabel2 && onClickLabel2}>{colorLabel2}</span></span>}
                </p>
            </label>
            {error && <span className='text-[#eb3b3b] text-sm'>{error}</span>}
            {/* 
            
            <input type="checkbox" name="terms_condition" onChange={handleChange} value={terms_condition} checked={terms_condition} id='1' className='accent-[#5679FF] h-[1.3rem] w-[1.3rem]' />
            <label htmlFor="1">
                <p className='text-sm font-semibold cursor-pointer'>I agree to all <span className='text-primary'>Terms </span>&<span className='text-primary'>Conditions</span></p>
            </label> 
            
            */}
            {/* {error && <span className='text-[#eb3b3b]'>{error}</span>} */}
        </div>
    )
}

export default CheckBox
