import axios from "axios";
import { getAttendance, getAttendanceWithoutPagination } from "../../../Redux/TenantRedux/Actions/attendanceActions";
import { BASE_URL, get_attendance_url, update_attendance_url, delete_attendance_url, import_attendance_url } from "../api_variables";
import { ATTENDANCE } from "Redux/TenantRedux/ActionTypes/attendanceTypes";



export const getAttendanceData = (currentPage, selected_location, startDate, endDate, search_text, employee_id, setLoader, leoday) => async (dispatch) => {
  setLoader && setLoader(true)
  let start_date = startDate ? `&start_date=${startDate}` : ""
  let end_date = endDate ? `&end_date=${endDate}` : ""
  const search = search_text ? `&search_text=${search_text}` : ''
  const employee = employee_id ? `&employee_id=${employee_id}` : ''
  const isLeoday = leoday ? `&leo_day=${leoday}` : ''

  try {
    const response = await axios.get(`${BASE_URL}${get_attendance_url}?page=${currentPage}&location=${selected_location}${start_date}${end_date}${search}${employee}${isLeoday}`);
    if (response.status === 200) {
      let attendance = response?.data?.response?.attendance;
      let pages = response?.data?.response?.pages
      let count = response?.data?.response?.count
      dispatch(getAttendance(attendance));
      dispatch({
        type: ATTENDANCE.GET_ATTENDANCE_PAGES,
        payload: pages,
      })
      dispatch({
        type: ATTENDANCE.GET_ATTENDANCE_COUNT,
        payload: count,
      })
      setLoader && setLoader(false)
    }
    setLoader && setLoader(false)
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getAttendanceDataWithNoPagination = (selected_location, startDate, endDate, search_text, employee_id, setLoader,leoday) => async (dispatch) => {
  setLoader && setLoader(true)
  let start_date = startDate ? `&start_date=${startDate}` : ""
  let end_date = endDate ? `&end_date=${endDate}` : ""
  const search = search_text ? `&search_text=${search_text}` : ''
  const employee = employee_id ? `&employee_id=${employee_id}` : ''
  const isLeoday = leoday ? `&leo_day=${leoday}` : ''
  
  try {
    const response = await axios.get(`${BASE_URL}${get_attendance_url}?no_pagination=true&location=${selected_location}${start_date}${end_date}${search}${employee}${isLeoday}`);
    if (response.status === 200) {
      let attendance = response?.data?.response?.attendance;
      dispatch(getAttendanceWithoutPagination(attendance));
      setLoader && setLoader(false)
    }
    setLoader && setLoader(false)
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getsAttendanceImport = async (form) => {
  let token = localStorage.getItem('access_token')
  if (token) {
    token = token.replaceAll('"', '')
  }

  fetch(`${BASE_URL}${import_attendance_url}`,
    {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`
      },
      body: form
    }).then((response => {
      return (
        response.json()
      )
    })).then((result) => {
    })

};

export const deleteAttendance = async (attendance_id, access_token) => {
  try {
    let result = await fetch(`${BASE_URL}${delete_attendance_url}`, {
      method: "DELETE",
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Token ${access_token}`
      },
      body: JSON.stringify(
        { attendence_id: attendance_id }
      )
    })
    result = result.json();
    return result;
  } catch (error) {
    return error;
  }

}


// export const updateAttendance = async (data_data, access_token) => {
//     try {
//         const result = await axios.put(`${BASE_URL}${update_attendance_url}`, { ...data_data },
//             {
//                 headers: { 'Authorization': `Token ${access_token}` },
//             });
//         return result;
//     } catch (error) {
//         return error;
//     }
// }


