/* eslint-disable react/jsx-pascal-case */
import Svgs from 'Assets/svgs'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import InputSection from 'Components/TenantApp/Elements/InputSection';
import I_Info_Icon from 'Components/TenantApp/Elements/I_Info_Icon';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import SelectedOption from 'Components/TenantApp/Elements/SelectedOption';
import ToggleWithText from 'Components/TenantApp/Elements/ToggleWithText';
import { DURATIONS } from 'Constants/Data/OpeningHours';
import { useAddDiscount } from './helper';
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb';
import { useMemo } from 'react';
import moment from 'moment';

const AddDiscount = () => {
    const {
        errors,
        toggleDay,
        toggleStore,
        discount,
        serviceDropdown,
        loading,
        locationDropdown,
        isEdit,
        dateRangeCheck,
        validateBlockDate,
        removePackage,
        newPackage,
        handlePackage,
        getServiceName,
        addService,
        filterService,
        handleChange,
        onBlurHandler,
        navigate,
        addStoreRestriction,
        filterStoreRestriction,
        onSubmit,
        addBlockDates,
        filterBlockDate,
        setToggleDay,
        setToggleStore,
        handleDayChecklist,
        toggleblockdate, setToggleBlockDate
    } = useAddDiscount();
    const {
        dateRestriction,
        blockDate,
        blockDates,
        storeRestriction,
        promotion_name,
        storeRestrictions,
        dayRestrictions,
        packages
    } = discount;

    var minEndDateLimit = useMemo(() => {
        return moment(dateRestriction.startDate).add(1, 'day').format('YYYY-MM-DD')
    }, [dateRestriction.startDate]);

    return (
        <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right' >


            <Breadcrumb
                first={'Clients'}
                url={'/dashboard/clients/'}
                second={'Discount & Promotions'}
                secondurl={'/dashboard/clients/discounts-promotions'}
                third='Packages'
            />

            <div className='flex items-center sm:gap-4 gap-2 my-[1rem]'>
                
                <div className='flex items-center gap-4 justify-between w-full'>
                    <div className='flex items-center gap-3'>
                        <Svgs.Back link='/dashboard/clients/discounts-promotions' />
                        <h2 className='text-primary font-bold lg:text-3xl text-xl cursor-pointer'>Packages</h2>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 md:w-[40vw]'>

                <div className='border rounded-lg p-3 flex flex-col gap-5'>
                    <h1 className='text-sm'>Create packages for selected services with services duration for a specific time period.</h1>
                    {packages?.map((obj, index) => obj?.is_deleted === 'False' && (
                        <div className="relative border rounded p-2">
                            {
                                index > 0 &&
                                <div
                                    className="absolute cursor-pointer top-0 right-0 translate-x-1/2 -translate-y-1/2"
                                    onClick={() => removePackage(obj?.id, index)}
                                >
                                    <Svgs.CirlceClose size="20" fill="red" />
                                </div>
                            }
                            <div className='grid grid-cols-2 gap-4'>
                                <div>
                                    <Dropwdown
                                        name="service"
                                        title={'Select Service'}
                                        placeholder='Select Service'
                                        options={serviceDropdown.filter(item => item.value !== obj?.service?.find((service) => service === item.value))}
                                        onChange={(e) => addService(e, index)}
                                    />
                                    {(serviceDropdown.filter(item => item.value !== obj?.service?.find((service) => service === item.value)))?.length == 0 && (
                                        <div
                                            className="flex items-center gap-2 cursor-pointer pt-2"
                                            onClick={() => {
                                                navigate('/dashboard/account/service-management')
                                            }}
                                        >
                                            <p className="text-sm">No services found. Click <span className="underline">here</span> to add.</p>
                                        </div>
                                    )}
                                </div>

                                <Dropwdown
                                    name="service_duration"
                                    title={'Select Duration'}
                                    placeholder='20 Mins '
                                    options={DURATIONS}
                                    value={obj?.service_duration}
                                    onChange={(e) => handlePackage(e, index)}
                                />
                                <div className='flex items-center gap-2 flex-wrap col-span-full' >
                                    {obj?.service?.map(service => (
                                        <SelectedOption
                                            rounded={'full'}
                                            name={getServiceName(service, serviceDropdown)}
                                            onClick={() => filterService(service, index)}
                                        />
                                    ))}
                                </div>
                                <div className='flex flex-col gap-1 mt-1'>
                                    <p className='text-sm'>
                                        <I_Info_Icon
                                            name="amount"
                                            text={'Total Amount'}
                                            info='The price of the complete package for all selected services.'
                                        />
                                    </p>
                                    <InputSection
                                        name="total_amount"
                                        leftText={'AED'}
                                        placeholder='5'
                                        type={'number'}
                                        value={obj?.total_amount}
                                        onChange={(e) => handlePackage(e, index)}
                                    />
                                </div>
                                <LoginInput
                                    type='number'
                                    name="package_duration"
                                    title={<I_Info_Icon text={'Package Duration'} info='The validity of the package: for how many days the client need to completely utilize this package.' />}
                                    placeholder='3 Month'
                                    value={obj?.package_duration}
                                    onChange={(e) => handlePackage(e, index)}
                                />
                            </div>
                        </div>
                    ))}

                    <p className="text-primary cursor-pointer flex items-center gap-2" onClick={newPackage}>
                        <Svgs.Plus color="#1C8AFE" />
                        Add New
                    </p>
                </div>

                <div className='border rounded-lg p-3 flex flex-col gap-7'>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>Date Restrictions</h1>
                        <div className='grid grid-cols-2 gap-3'>
                            <LoginInput
                                title={"Start Date"}
                                name="startDate"
                                value={dateRestriction.startDate}
                                type="date"
                                onChange={handleChange}
                                onBlur={() =>
                                    onBlurHandler({ startDate: dateRestriction.startDate })
                                }
                                error={errors.startDate}
                            />
                            <LoginInput
                                title={"End Date"}
                                name="endDate"
                                value={dateRestriction.endDate}
                                type="date"
                                onChange={handleChange}
                                min={minEndDateLimit}
                                onBlur={() =>
                                    onBlurHandler({ endDate: dateRestriction.endDate })
                                }
                                error={errors.endDate}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={'!text-lg'}
                            title='Add Block Date'
                            value={toggleblockdate}
                            onChange={() => setToggleBlockDate(!toggleblockdate)}
                            checked={toggleblockdate}
                        />
                        {toggleblockdate &&
                            <>
                                <div className='flex items-end gap-3'>
                                    <LoginInput
                                        name="blockDate"
                                        title={"Start Date"}
                                        type="date"
                                        parentClass="flex-1"
                                        required={false}
                                        min={dateRestriction.startDate}
                                        max={dateRestriction.endDate}
                                        value={blockDate}
                                        onChange={handleChange}
                                        error={errors.blockDate}
                                        disabled={
                                            !discount.dateRestriction.startDate ||
                                            !discount.dateRestriction.endDate
                                        }
                                    />
                                    <LoginBtn
                                        text={
                                            <div className="flex items-center gap-2">
                                                <Svgs.Plus />
                                                <p>Add</p>
                                            </div>
                                        }
                                        onClick={addBlockDates}
                                        disabled={
                                            !dateRangeCheck ? true : false ||
                                                !discount.dateRestriction.startDate ||
                                                !discount.dateRestriction.endDate ||
                                                !blockDate ||
                                                validateBlockDate ? true : false
                                        }
                                    />
                                </div>
                                <div className='flex items-center gap-2 flex-wrap'>
                                    {blockDates?.map((obj, index) => (
                                        <>
                                            {obj?.is_deleted === "False" && (
                                                <SelectedOption
                                                    rounded={"full"}
                                                    name={obj?.date}
                                                    onClick={() => filterBlockDate(obj?.id, index)}
                                                />
                                            )}
                                        </>
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={"!text-lg"}
                            title="Store Restrictions"
                            value={toggleStore}
                            onChange={() => setToggleStore(!toggleStore)}
                            checked={toggleStore}
                        />
                        {toggleStore && (
                            <>
                                <div className="flex items-end gap-3">
                                    <Dropwdown
                                        name="storeRestriction"
                                        placeholder={"Store"}
                                        title={"select stores where this promotions will be available"}
                                        parentClass="flex-1"
                                        options={locationDropdown?.filter(
                                            (obj) =>
                                                obj.value !==
                                                discount?.storeRestrictions?.find(
                                                    (location) => location === obj.value
                                                )
                                        )}
                                        value={storeRestriction}
                                        onChange={handleChange}
                                    />
                                    <LoginBtn
                                        text={
                                            <div className="flex items-center gap-2">
                                                {" "}
                                                <Svgs.Plus />
                                                <p>Add</p>
                                            </div>
                                        }
                                        onClick={addStoreRestriction}
                                        disabled={!storeRestriction}
                                    />
                                </div>
                                <div className="flex items-center gap-2 flex-wrap">
                                    {storeRestrictions?.map((obj, index) => (
                                        <SelectedOption
                                            rounded={"full"}
                                            name={
                                                locationDropdown?.find(
                                                    (location) => location?.value === obj
                                                )?.label
                                            }
                                            onClick={() => filterStoreRestriction(index)}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={"!text-lg"}
                            title="Day Restrictions"
                            value={toggleDay}
                            onChange={() => setToggleDay(!toggleDay)}
                            checked={toggleDay}
                        />
                        {toggleDay && (
                            <div className="grid grid-cols-2 gap-4">
                                {dayRestrictions.map((item) => {
                                    return (
                                        <CheckBox
                                            name={item.day}
                                            label={item.day}
                                            value={item.value}
                                            onChange={handleDayChecklist}
                                            checked={item.value === item.day ? true : false}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <LoginBtn
                    text={isEdit ? "Update" : "Save"}
                    loading={loading}
                    disabled={
                        loading ||
                        // discount.users.length === 0 ||
                        !discount.dateRestriction.endDate ||
                        !discount.dateRestriction.startDate
                        || errors.endDate
                        // || discount.blockDates.length === 0
                    }
                    onClick={onSubmit}
                />
            </div>
        </div>
    )
}

export default AddDiscount