import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getClientInstallmentMemberships, getQuickSalesMembership, paySingleInstallmentPrice } from "../../../../../../Adapters/Api/vouchers";
import { useLocation } from "new-components/location/helper";
import Svgs from "../../../../../../Assets/svgs";
import LoginBtn from "../../../../Elements/LoginBtn";
import LoginInput from "../../../../Elements/LoginInput";
import MembershipCard from "../../Elements/MembershipCard.jsx";
import AddToCart from "../../Elements/AddToCart";
import Popup from "./../../../../../../new-components/popup/index";
import CircleIcon from "./../../../../Elements/CircleIcon";
import DetailPopupDetails from "./../../Elements/DetailPopupDetails";
import { useService } from "../Services/helper";
import Pagination from "new-components/Pagination";
import NoMembershipFound from "../../Elements/NoMembershipFound";
import ServiceCard from "../../Elements/ServiceCard";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import QuickSalesTableHead from "Components/Utility/Shimmers/QuickSalesTableHead";
import Shimmer_array from "Constants/Data/Shimmer_array";
import RadioBtn from "Components/TenantApp/Elements/RadioBtn";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import { getClientDropDownListPagination } from "Adapters/Api/clients";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { toast } from "react-toastify";
import useValidations from "Hooks/useValidations";
import moment from "moment";
import InstallmentMemberShipShimmer from "Components/Utility/Shimmers/InstallmentMemberShipShimmer";

let clientTimeOutId = undefined;
let timeOutId = undefined
const Membership = ({ isLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage()
  const selectedLocationCurrency = getDataFromLocalStorage("selectedLocationCurrency")
  const selectedLocationCurrencyId = getDataFromLocalStorage("selectedLocationCurrencyId")
  const cartFromLocalStorage = getDataFromLocalStorage('cart_items')

  const { setErrors, errors } = useValidations()

  const state = useSelector((state) => state);
  const { locations } = useSelector((state) => state);
  const { selected_location } = locations;
  const membership_updated = state.membership?.membership_updated;
  const cartItems = state.cartItem.cart_items;
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const filterMembership = state.membership?.memberships
  const membershipCount = state?.membership?.allCount
  const pages = state?.membership?.page_count
  const clientPaginationData = state?.client?.clientPaginationData;
  const clientDropdownRedux = state?.client?.clientsRedux;

  const PageSize = 10

  const { removedFromCart } = useService();
  const { locationDetail } = useLocation();
  const LocationCurrency = locationDetail?.currency?.id;

  const [selected_ids, setSelectIds] = useState([]);
  const [search_text, setSearchText] = useState("");
  const [ProductDetailPopup, setProductDetailPopup] = useState(false);
  const [membersip_details, setMembershipDetails] = useState({});
  const [pageLoading, setPageLoading] = useState(true)
  const [lastPage, setLastPage] = useState()
  const [addMemberShipInsallment, setAddMemberShipInsallment] = useState(undefined)
  const [memberShipInstallment, setMemberShipInstallment] = useState(false)
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [OpenCart, setOpenCart] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingPermission, setLoadingPermission] = useState(true)
  const [InstallmentPopup, setInstallmentPopup] = useState(false)
  const [selectedClient, setSelectedClient] = useState("")
  const [clientSearchText, setClientSearchText] = useState("");
  const [clientCurrentPage, setClientCurrentPage] = useState(1)
  const [clientLoader, setClientLoader] = useState(false)
  const [installmentInputData, setInstallmentInputData] = useState({
    price: "",
    total_installment_months: 2
  })
  const [clientInstallmentData, setClientInstallmentData] = useState([])
  const [singleInstallmentRecord, setSingleInstallmentRecord] = useState(undefined)
  const [newPaymentData, setNewPaymentData] = useState({
    membership: "",
    paid_installment: ""
  })
  const [installmentLoader, setInstallmentLoader] = useState(false)
  const [payInstallmentLoader, setPayInstallmentLoader] = useState(false)

  useEffect(() => {
    if (clientSearchText === null) {
      getClients(clientCurrentPage, "")
    } else {
      if (clientTimeOutId) {
        clearTimeout(clientTimeOutId)
      }
      clientTimeOutId = setTimeout(() => {
        getClients(clientCurrentPage, clientSearchText)
      }, 500);
    }
  }, [clientSearchText, clientCurrentPage])

  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    const quickSales = true
    if (search_text === null) {
      // Initial load with empty search_text 
      dispatch(getQuickSalesMembership(selected_location, currentPage, "", setPageLoading, quickSales))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(getQuickSalesMembership(selected_location, currentPage, search_text, setPageLoading, quickSales))
      }, 500);
    }
  }, [currentPage, search_text, selected_location])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  useEffect(() => {
    setPageLoading(true)
    setCurrentPage(1)
  }, [selected_location])

  useEffect(() => {
    const result = cartItems?.map((itm) => itm.id);
    setSelectIds(result);
  }, [cartItems.length != selected_ids.length]);

  useEffect(() => {
    setInstallmentLoader(true)
    setClientInstallmentData([])
    setSingleInstallmentRecord(undefined)
    // get client installment memberships
    let payload = {
      location_id: selected_location,
      client_id: selectedClient,
      installment: 'True'
    }
    let success = (data) => {
      setClientInstallmentData(data)
      if (data?.length > 0) {
        setSingleInstallmentRecord(data[0])
        setNewPaymentData({
          membership: data[0]?.id,
          name: data[0]?.name,
          paid_installment: ""
        })
      }
    }
    let fail = () => {
    }
    dispatch(getClientInstallmentMemberships(payload, setInstallmentLoader, success, fail))
  }, [selectedClient])

  // pay single installment
  const paySingleInstallment = () => {
    let data = { is_installment: true, name: newPaymentData?.name, quantity: 1, membership: newPaymentData?.membership, installment_price: ((+newPaymentData?.paid_installment)?.toFixed(2)), selection_type: "INSTALLMENT_MEMBERSHIP" }
    dispatch({
      type: "ADD_BULK_ITEMS_TO_CART",
      payload: {
        'services': [data],
        installment_client: { id: selectedClient, name: clientDropdownRedux?.find(itm => itm?.id == selectedClient)?.full_name }
      },
    });
    setInstallmentPopup(false)
    setSelectedClient("")
    setClientInstallmentData([])
    setSingleInstallmentRecord(undefined)
    // // previous integrations
    // let success = () => {
    //   setInstallmentPopup(false)
    //   setSelectedClient("")
    //   setClientInstallmentData([])
    //   setSingleInstallmentRecord(undefined)
    // }
    // let fail = () => {
    // }
    // if (newPaymentData?.paid_installment) {
    //   if (!errors?.installmentPrice) {
    //     dispatch(paySingleInstallmentPrice(newPaymentData, setPayInstallmentLoader, success, fail))
    //   }
    // } else {
    //   setErrors((prev) => ({
    //     ...prev,
    //     installmentPrice: true
    //   }))
    // }
  }

  // handle change of installment price
  const handleInstallmentPriceChange = (e) => {
    const { value } = e.target
    if (value != "") {
      if (+value === 0) {
        setErrors((prev) => ({
          ...prev,
          installmentPrice: `Price cannot be 0`
        }))
      } else {
        console.log(singleInstallmentRecord, "singleInstallmentRecord")
        if (value <= singleInstallmentRecord?.payable_amount) {
          if (singleInstallmentRecord?.installment_price < singleInstallmentRecord?.payable_amount) {
            if (value < singleInstallmentRecord?.installment_price || value > singleInstallmentRecord?.payable_amount) {
              if (value < singleInstallmentRecord?.installment_price) {
                setErrors((prev) => ({
                  ...prev,
                  installmentPrice: `Price cannot be lesser than ${singleInstallmentRecord?.installment_price} ${selectedLocationCurrency}`
                }))
              } else {
                setErrors((prev) => ({
                  ...prev,
                  installmentPrice: `Price cannot be greater than ${singleInstallmentRecord?.payable_amount} ${selectedLocationCurrency}`
                }))
              }
            } else {
              setErrors((prev) => ({
                ...prev,
                installmentPrice: ""
              }))
            }
          } else {
            setErrors((prev) => ({
              ...prev,
              installmentPrice: ""
            }))
          }
        } else {
          setErrors((prev) => ({
            ...prev,
            installmentPrice: `Price cannot be greater than ${singleInstallmentRecord?.payable_amount} ${selectedLocationCurrency}`
          }))
        }
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        installmentPrice: ""
      }))
    }
    setNewPaymentData((prev) => ({
      ...prev,
      paid_installment: value
    }))
  }

  const member_details_settings = (id) => {
    setProductDetailPopup(true);
    const selected_membership = filterMembership?.find(
      (itm) => itm?.id === id
    );
    setMembershipDetails(selected_membership);
  };

  const getClients = (clientCurrentPage, clientSearchText) => {
    dispatch(getClientDropDownListPagination(clientCurrentPage, clientSearchText, setClientLoader));
  }

  // add to cart function (make cart item)
  const handleSelection = (id, price, installmentPrice, totalInstallmentMonths) => {
    if (selected_ids.includes(id)) {
      selected_ids?.splice(selected_ids.indexOf(id), 1);
    } else {
      selected_ids?.push(id);
    }

    const result = state?.membership?.memberships?.find((itm) => itm.id === id);
    let data = { ...result, quantity: 1, price, selection_type: "MEMBERSHIP" }
    if (totalInstallmentMonths) {
      data = { ...data, installment_price: installmentPrice, installment_months: totalInstallmentMonths }
    }
    dispatch({
      type: "ADD_TO_CART",
      payload: data,
    });
    let updatedCartForLocal = []
    if (cartFromLocalStorage?.length > 0 && cartFromLocalStorage != undefined) {
      updatedCartForLocal = [...cartFromLocalStorage]
    }
    updatedCartForLocal = [...updatedCartForLocal, data]
    storeDataInLocalStorage('cart_items', updatedCartForLocal)
  };

  // handle add to cart
  const handleAddToCart = (membership) => {
    let memberShipData = addMemberShipInsallment ? addMemberShipInsallment : membership
    let membershipTotalPrice = memberShipData?.currency_membership?.find((itm) => itm?.currency === LocationCurrency)?.price
    if (cartItems?.find(itm => itm?.id == memberShipData?.id)) {
      toast.error("You can't add this membership again", { toastId: 'toast' })
    } else {
      if (!memberShipInstallment) {
        setOpenCart(true)
        handleSelection(memberShipData?.id, membershipTotalPrice)
        setAddMemberShipInsallment(undefined)
        setMemberShipInstallment(false)
        setInstallmentInputData({
          price: "",
          total_installment_months: 2
        })
      } else {
        if (installmentInputData?.price && installmentInputData?.total_installment_months) {
          if (+membershipTotalPrice >= installmentInputData?.price) {
            setOpenCart(true)
            handleSelection(addMemberShipInsallment?.id, membershipTotalPrice, installmentInputData?.price, installmentInputData?.total_installment_months)
            setAddMemberShipInsallment(undefined)
            setMemberShipInstallment(false)
            setInstallmentInputData({
              price: "",
              total_installment_months: 2
            })
          }
        } else {
          setErrors((prev) => ({
            ...prev,
            installmentInput: true
          }))
        }
      }
    }
  }

  return (
    <>
      <div className="border rounded-md">
        <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership"}
              </h2>

              {!membership_updated || pageLoading ?
                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                  <p className="rounded-md h-5 w-12 mx-1"> </p>
                </div>
                :
                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                  <p className="text-primary">
                    {membershipCount ? membershipCount : 0} {" "}
                    {membershipCount && membershipCount > 1 ?
                      selectedLanguage?.name == "English" ? "Memberships" : translation[195]?.value ? translation[195]?.value : "Memberships"
                      : selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership"
                    }
                  </p>
                </div>
              }
            </div>
            <div className="mb-00">
              {loadingPermission ?
                <Shimmer className={'w-40 lg:w-44'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                :
                <LoginInput
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Search Membership" : translation[1268]?.value ? translation[1268]?.value : "Search Membership"}
                  padding="py-1"
                  leftIcon={<Svgs.Search />}
                  value={search_text}
                  onChange={(e) => { setSearchText(e.target.value); }}
                />
              }
            </div>
          </div>
          {loadingPermission ?
            <QuickSalesTableHead />
            :
            <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">

              {!employee ? (
                <LoginBtn
                  text={selectedLanguage?.name == "English" ? "Pay Installment" : translation[1688]?.value ? translation[1688]?.value : "Pay Installment"}
                  onClick={() => {
                    setInstallmentPopup(true)
                  }}
                />
              ) : (
                ""
              )}
              {!employee ? (
                <LoginBtn
                  text={selectedLanguage?.name == "English" ? "Add Membership" : translation[301]?.value ? translation[301]?.value : "Add Membership"}
                  onClick={() => {
                    navigate("/dashboard/clients/sharing-settings/membership");
                  }}
                />
              ) : (
                ""
              )}
              {!employee ||
                (employee &&
                  employeePermissions?.sales_apply_offer?.includes("create")) ||
                employeePermissions?.sales_apply_offer?.includes("edit") ? (
                <AddToCart OpenCart={OpenCart} setOpenCart={setOpenCart} />
              ) : (
                ""
              )}
            </div>
          }
        </div>
        <div className="px-6 py-3">

          {!membership_updated || pageLoading ?
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 py-5">
              {Shimmer_array()?.map(itm => <ServiceCard animate />)}
            </div>
            :
            filterMembership?.length > 0 ? (
              <>
                <div className="flex items-center justify-between">
                  <h1 className="text-[#7B7B7B] text-sm font-semibold normal-case">
                    {selectedLanguage?.name == "English" ? "You can select multiple memberships" : translation[302]?.value ? translation[302]?.value : "You can select multiple memberships"} <span className='text-red-500'>*</span>
                  </h1>
                  {membershipCount > 10 &&
                    <div className="flex items-center justify-between gap-4">
                      <LoginBtn
                        onClick={() => {
                          setCurrentPage(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                        text="Back" />
                      <LoginBtn
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                        disabled={currentPage === lastPage}
                        text="Next" />
                    </div>
                  }
                </div>
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 py-5">
                  {filterMembership?.map((membership, index) => {
                    return (
                      <MembershipCard
                        setOpenCart={setOpenCart}
                        data={membership}
                        index={index}
                        removedFromCart={removedFromCart}
                        view_details={() =>
                          member_details_settings(membership?.id)
                        }
                        onClick={() => {
                          if (membership?.is_installment) {
                            setAddMemberShipInsallment(membership)
                          } else {
                            handleAddToCart(membership)
                          }
                        }
                        }
                      />
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center">
                <NoMembershipFound />
              </div>
            )}
        </div>

        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          totalCount={Math.ceil(pages)}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
          setLastPage={setLastPage}
        />
      </div>
      {ProductDetailPopup && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Membership Details" : translation[308]?.value ? translation[308]?.value : "Membership Details"}
          handleClose={() => setProductDetailPopup(false)}
        >
          <div className="grid md:grid-cols-5 gap-4">
            <div className="flex justify-center">
              <CircleIcon className="!h-[6rem] !w-[6rem]">
                <Svgs.MembershipIcon />
              </CircleIcon>
            </div>
            <div className="flex flex-col gap-6 md:col-span-4">
              <div className="grid md:grid-cols-2 gap-2">
                <DetailPopupDetails
                  title={selectedLanguage?.name == "English" ? "Membership Name" : translation[205]?.value ? translation[205]?.value : "Membership Name"}
                  desc={membersip_details?.name}
                />
                <DetailPopupDetails
                  title={selectedLanguage?.name == "English" ? "Membership Type" : translation[208]?.value ? translation[208]?.value : "Membership Type"}
                  desc={membersip_details?.discount}
                />
                {membersip_details?.currency_membership?.filter((itm) =>
                  itm?.currency === LocationCurrency).map((obj) => obj?.price) &&
                  <DetailPopupDetails
                    title={selectedLanguage?.name == "English" ? "Membership Price" : translation[207]?.value ? translation[207]?.value : "Membership Price"}
                    desc={
                      <div>
                        {membersip_details?.currency_membership?.filter((itm) =>
                          itm?.currency === LocationCurrency).map((obj) => obj?.price)} {membersip_details?.currency_membership?.find((itm) => itm?.currency === LocationCurrency) ? locationDetail?.currency?.code : "AED"}
                        {/* {membersip_details?.price} */}
                      </div>
                    }
                  />
                }
                <DetailPopupDetails
                  title={selectedLanguage?.name == "English" ? "Validity" : translation[307]?.value ? translation[307]?.value : "Validity"}
                  desc={membersip_details?.valid_for}
                />
                <div className="col-span-2">
                  <DetailPopupDetails
                    title={selectedLanguage?.name == "English" ? "Membership Details" : translation[308]?.value ? translation[308]?.value : "Membership Details"}
                    desc={<>
                      {membersip_details?.discount === "Free" ?
                        <p className="flex flex-col gap-1">
                          {selectedLanguage?.name == "English" ? "Get free services" : translation[310]?.value ? translation[310]?.value : "Get free services"}:
                          {''}{membersip_details?.services?.map((itm, i) => {
                            return ((i != 0 ? ',' : '') + itm?.service_name)
                          })}
                        </p> :
                        membersip_details?.services?.map((itm, i) => <p>
                          {selectedLanguage?.name == "English" ? "Get" : translation[299]?.value ? translation[299]?.value : "Get"} :
                          {itm?.percentage ? itm?.percentage : ""}
                          %
                          {selectedLanguage?.name == "English" ? "off on service" : translation[311]?.value ? translation[311]?.value : "off on service"} :
                          {itm?.service_name}
                        </p>)}

                      <p>
                        {membersip_details?.products?.map((itm, i) => <p>
                          {selectedLanguage?.name == "English" ? "Get" : translation[299]?.value ? translation[299]?.value : "Get"}
                          {itm?.percentage ? itm?.percentage : ""}  %
                          {selectedLanguage?.name == "English" ? "off on" : translation[311]?.value ? translation[311]?.value : "off on"} :
                          {itm?.product_name}
                        </p>)}
                      </p>
                    </>}
                  />
                </div>
              </div>
            </div>
          </div>
        </Popup>
      )}
      {
        addMemberShipInsallment && (
          <Popup
            heading={selectedLanguage?.name == "English" ? "Add Membership" : translation[301]?.value ? translation[301]?.value : "Add Membership"}
            handleClose={() => {
              setAddMemberShipInsallment(undefined)
              setMemberShipInstallment(false)
              setInstallmentInputData({
                price: "",
                total_installment_months: 2
              })
              setErrors((prev) => ({
                ...prev,
                installmentInput: ""
              }))
            }}
          >
            <div className="flex items-center justify-between py-3">
              <div className="flex flex-col gap-1">
                <p className="text-[#7A7A7A] text-sm">Membership Name</p>
                <h5 className="font-semibold text-base md:text-xl">{addMemberShipInsallment?.name}</h5>
              </div>
              <div className="flex flex-col gap-1 text-right">
                <p className="text-[#7A7A7A] text-sm">Membership Price</p>
                <h5 className="font-semibold text-base md:text-xl">{addMemberShipInsallment?.currency_membership?.find((itm) =>
                  itm?.currency === LocationCurrency)?.price} {selectedLocationCurrency}
                </h5>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <RadioBtn id={"full_pay"} text={"Full Pay"} name={"full_pay"} checked={!memberShipInstallment} onClick={() => setMemberShipInstallment(false)} />
              {addMemberShipInsallment?.is_installment &&
                <RadioBtn
                  id={"installment"}
                  text={"Installment"}
                  name={"installment"}
                  checked={memberShipInstallment}
                  onClick={() => {
                    let price = addMemberShipInsallment?.currency_membership?.find((itm) => itm?.currency === LocationCurrency)?.price
                    setInstallmentInputData((prev) => ({
                      ...prev,
                      price: +price / 2,
                    }))
                    setMemberShipInstallment(true)
                  }} />
              }
            </div>
            {!memberShipInstallment ?
              <div className="grid sm:grid-cols-2 items-center gap-5 py-5">
                <LoginInput
                  value={addMemberShipInsallment?.currency_membership?.find((itm) =>
                    itm?.currency === LocationCurrency)?.price}
                  disabled={true}
                  name={"total_payment"}
                  placeholder={"300"}
                  title={"Total Payment"}
                />
              </div> :
              <div className="grid sm:grid-cols-2 items-center gap-5 py-5">
                <LoginInput
                  name={"installmentInput"}
                  placeholder={"300"}
                  title={"Total Payment"}
                  // (+installmentInputData?.price)?.toFixed(2)
                  value={installmentInputData?.price ? installmentInputData?.price : ""}
                  onChange={(e) => {
                    const { value } = e.target
                    let membershipTotalPrice = addMemberShipInsallment?.currency_membership?.find((itm) => itm?.currency === LocationCurrency)?.price
                    if (+value === 0 && value !== "") {
                      setErrors((prev) => ({
                        ...prev,
                        installmentInput: `Value cannot be zero`
                      }))
                    } else {
                      if (+value > +membershipTotalPrice) {
                        setErrors((prev) => ({
                          ...prev,
                          installmentInput: `You can't add more then ${membershipTotalPrice} ${selectedLocationCurrency}`
                        }))
                      } else {
                        setErrors((prev) => ({
                          ...prev,
                          installmentInput: ''
                        }))
                      }
                    }
                    setInstallmentInputData((prev) => ({
                      ...prev,
                      price: value,
                    }))
                  }}
                  error={errors?.installmentInput}
                />
                <Dropwdown
                  placeholder={"2"}
                  title={"How many Installment (month)"}
                  onChange={(e) => {
                    const { value } = e.target
                    let price = addMemberShipInsallment?.currency_membership?.find((itm) => itm?.currency === LocationCurrency)?.price
                    price = +price / +value
                    setInstallmentInputData({
                      // price: (+price)?.toFixed(2),
                      price: price,
                      total_installment_months: value
                    })
                  }}
                  value={installmentInputData?.total_installment_months}
                  name={"months"} options={[
                    { label: "2", value: 2 },
                    { label: "3", value: 3 },
                    { label: "4", value: 4 },
                    { label: "5", value: 5 },
                    { label: "6", value: 6 },
                    { label: "7", value: 7 },
                    { label: "8", value: 8 },
                    { label: "9", value: 9 },
                    { label: "10", value: 10 },
                    { label: "11", value: 11 },
                    { label: "12", value: 12 },
                  ]} />
              </div>
            }
            <div className="flex items-center justify-end w-full pt-2">
              <LoginBtn
                text={"Add to Cart"}
                onClick={() => {
                  handleAddToCart()
                }}
              />
            </div>
          </Popup>
        )
      }
      {
        InstallmentPopup && (
          <Popup
            heading={selectedLanguage?.name == "English" ? "Pay Installment" : translation[1688]?.value ? translation[1688]?.value : "Pay Installment"}
            handleClose={() => {
              setSelectedClient("")
              setInstallmentPopup(false)
              setErrors((prev) => ({
                ...prev,
                installmentPrice: ""
              }))
            }}
          >
            <div className="flex flex-col gap-5 pt-3">
              <div className="">
                <PaginationDropdown
                  name={'client'}
                  value={selectedClient}
                  required={true}
                  title={selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client"}
                  placeholder={selectedLanguage?.name == "English" ? "Select Client" : translation[225]?.value ? translation[225]?.value : "Select Client"}
                  onChange={(e) => { setSelectedClient(e.target.value) }}
                  setPage={setClientCurrentPage}
                  page={clientCurrentPage}
                  setDropdownSearch={setClientSearchText}
                  dropdownSearch={clientSearchText}
                  lastPage={clientPaginationData?.pages}
                  currentPage={clientPaginationData?.currentPage}
                  loading={clientLoader}

                  options={clientDropdownRedux?.map((clnt, i) => {
                    return {
                      label: clnt.full_name,
                      value: clnt.id,
                      otherData: {
                        image: clnt?.image,
                        DataOne: clnt.email,
                        showImage: true,
                        index: i
                      },
                      email: clnt.email,
                      mobile_number: clnt.mobile_number
                    };
                  })}
                />
              </div>
              {installmentLoader ?
                <InstallmentMemberShipShimmer />
                : clientInstallmentData?.length > 0 &&
                <>
                  <div className="space-y-2">
                    <h1 className="text-xl font-semibold">{selectedLanguage?.name == "English" ? "Select Membership" : translation[1710]?.value ? translation[1710]?.value : "Select Membership"}</h1>
                    <div className="flex flex-wrap gap-2">
                      {clientInstallmentData?.map((itm, ind) => (
                        <div
                          key={ind}
                          className={`${singleInstallmentRecord?.id == itm?.id ? "bg-primary text-white" : " bg-white text-black border border-[#A1A1A1]"} rounded-lg px-3 py-2 cursor-pointer flex flwx-wrap space-x-2`}
                          onClick={() => {
                            setSingleInstallmentRecord(itm)
                            setNewPaymentData({
                              membership: itm?.id,
                              name: itm?.name,
                              paid_installment: ""
                            })
                          }}>{`Membership ${ind + 1}`}</div>
                      ))}
                    </div>
                  </div>

                  <div className="bg-[#E0EBFD] rounded-xl">
                    <div className="flex items-center justify-between p-5 border-b border-[#ACBCD7]">
                      <div className="flex flex-col gap-1">
                        <p className="text-[#7A7A7A] text-sm">{selectedLanguage?.name == "English" ? "Membership Name" : translation[205]?.value ? translation[205]?.value : "Membership Name"}</p>
                        <h5 className="font-semibold text-base md:text-xl">{singleInstallmentRecord?.name}</h5>
                      </div>
                      <div className="flex flex-col gap-1 text-right">
                        <p className="text-[#7A7A7A] text-sm">{selectedLanguage?.name == "English" ? "Membership Price" : translation[207]?.value ? translation[207]?.value : "Membership Price"}</p>
                        <h5 className="font-semibold text-base md:text-xl">{singleInstallmentRecord?.total_membership_price?.find(itm => itm?.currency == selectedLocationCurrencyId)?.price} {selectedLocationCurrency}</h5>
                      </div>
                    </div>
                    <div className="grid grid-cols-3 items-center">
                      <div className="flex flex-col items-center gap-1 py-5 border-r border-[#ACBCD7]">
                        <p className="text-[#7A7A7A] text-sm">{selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}</p>
                        <h5 className="font-semibold text-sm">{singleInstallmentRecord?.created_at ? moment(singleInstallmentRecord?.created_at).format('D MMMM YYYY') : "----"}</h5>
                      </div>
                      <div className="flex flex-col items-center gap-1 py-5 border-r border-[#ACBCD7]">
                        <p className="text-[#7A7A7A] text-sm">{selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}</p>
                        <h5 className="font-semibold text-sm">{singleInstallmentRecord?.next_installment_date ? moment(singleInstallmentRecord?.next_installment_date).format('D MMMM YYYY') : "----"}</h5>
                      </div>
                      <div className="flex flex-col items-center gap-1">
                        <p className="text-[#7A7A7A] text-sm">{selectedLanguage?.name == "English" ? "Remaining Amount" : translation[1677]?.value ? translation[1677]?.value : "Remaining Amount"}</p>
                        <h5 className="font-semibold text-sm">{+(singleInstallmentRecord?.payable_amount)?.toFixed(2)} {selectedLocationCurrency}</h5>
                      </div>
                    </div>
                  </div>

                  <div className="grid sm:grid-cols-2 items-center gap-5">
                    <LoginInput
                      name={"total_payment"}
                      placeholder={"300"}
                      value={newPaymentData?.paid_installment}
                      onChange={handleInstallmentPriceChange}
                      error={errors?.installmentPrice}
                      title={selectedLanguage?.name == "English" ? "Total Payment" : translation[1678]?.value ? translation[1678]?.value : "Total Payment"} />
                  </div>

                  {singleInstallmentRecord?.installment_data?.length > 0 &&
                    <div className="flex flex-col gap-5">
                      <h5 className="text-[#101928] text-base md:text-xl font-semibold flex items-end gap-2"><span>{selectedLanguage?.name == "English" ? "History" : translation[1682]?.value ? translation[1682]?.value : "History"}</span> <span className="rotate-180"><Svgs.Chevron_down /></span></h5>
                      <div className="overflow-x-auto">
                        <div className="min-w-[500px] text-[#101928] rounded-xl border border-[#A1A1A1]">
                          <div className="grid grid-cols-3 rounded-t-xl items-center py-2 bg-[#F3F6FC]">
                            <p className="text-sm font-semibold px-4">{selectedLanguage?.name == "English" ? "Sr. No" : translation[1679]?.value ? translation[1679]?.value : "Sr. No"}</p>
                            <p className="text-sm font-semibold px-4">{selectedLanguage?.name == "English" ? "Amount Paid" : translation[1711]?.value ? translation[1711]?.value : "Amount Paid"}</p>
                            <p className="text-sm font-semibold px-4">{selectedLanguage?.name == "English" ? "Paid Date" : translation[1680]?.value ? translation[1680]?.value : "Paid Date"}</p>
                            {/* <p className="text-sm font-semibold px-4">{selectedLanguage?.name == "English" ? "Qty Month" : translation[1681]?.value ? translation[1681]?.value : "Qty Month"}</p>
                          <p className="text-sm font-semibold px-4">{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p> */}
                          </div>
                          {singleInstallmentRecord?.installment_data?.map((itm, ind) => (
                            <div className="grid grid-cols-3 items-center py-2 odd:bg-[#F3F6FC]">
                              <p className="text-sm px-4"># {ind + 1}</p>
                              <p className="text-sm px-4">{+(itm?.paid_installment)?.toFixed(2)} {selectedLocationCurrency}</p>
                              <p className="text-sm px-4">{itm?.created_at ? moment(itm?.created_at).format('D MMMM YYYY') : "----"}</p>
                              {/* <p className="text-sm px-4">1 month</p>
                          <div className="text-sm px-4 ">
                            <LoginBtn className={"newSuccessBtnFocus !px-6 !rounded-full !shadow-none"} text={"Paid"} />
                          </div> */}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  }

                  <div className="flex items-center justify-end w-full pt-2">
                    <LoginBtn
                      text={"Pay Now"}
                      loading={payInstallmentLoader}
                      disabled={payInstallmentLoader}
                      onClick={() => {
                        paySingleInstallment()
                      }}
                    />
                  </div>
                </>
              }
            </div>
          </Popup>
        )
      }
    </>
  )
}

export default Membership;
