import { createStore, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';

import NstyleRootReducer from "./NstyleRootReducer";



const middleware = [thunk];

const store = createStore(NstyleRootReducer, compose(applyMiddleware(...middleware)));

export default store;
