import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllSaleOders } from 'Adapters/Api/saleOrders';
import { SALE_ORDER } from 'Redux/TenantRedux/ActionTypes/salleOrderTypes';

function useSaleOrderService() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const selected_location = state?.locations?.selected_location;
    const allSaleorders = state?.saleOrder?.allSaleorders;
  const location_currency = state?.utility?.currency?.location_currency;

    const [servicelist, setServicelist] = useState([]);

    useEffect(() => {
        if (allSaleorders?.length === 0) {
            dispatch(getAllSaleOders());
        } else {
            const saleOrderOnSelectedLocation = allSaleorders?.filter(
                ({ location }) => location?.id === selected_location
            );
            let srvc = saleOrderOnSelectedLocation?.filter((itm) => itm?.service?.length > 0)
            setServicelist(srvc);
        }

    }, [selected_location]);
// }, [selected_location, allSaleorders]);

    // calculate to bill of selecte service
    const calculate_totals = (item, isInvoice = false) => {
        let total_bill = 0;

        if (typeof item?.service == "object") {
            if (item?.service?.length > 0) {
                item?.service?.forEach((itm) => {
                    // itm?.service?.priceservice?.forEach((tm) => {
                    //     total_bill += (tm?.price) * (itm?.quantity ? itm?.quantity : 1);
                    // });
                    total_bill += itm?.service?.priceservice[0]?.price * (itm?.quantity ? itm?.quantity : 1)
                });
            }
        }

        if (isInvoice) {
            dispatch({
                type: SALE_ORDER.CALCULATE_INVOICE_BILL,
                payload: total_bill
            })
        }

        return total_bill

    };

    return {
        servicelist,
        calculate_totals,
        location_currency,
    }
}

export default useSaleOrderService
