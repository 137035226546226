/* eslint-disable array-callback-return */
import { STAFF_TARGET } from "Redux/TenantRedux/ActionTypes/staffTargetTypes";

const initialState = {
  staffTargets: [],
  allStaffTargetReports: [],
  staffTargetReportsPages: 0,
  staffTargetReportsAllCount: 0,
  staffTargets_updated: false,
  // staff target report kpi
  staffTargetReports: [],
  staffTargetReports_updated: false,
  pages: 0,
  allCount: 0
};

const StaffTargetReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case STAFF_TARGET.GET_STAFF_TARGET_REPORT:
      return {
        ...state,
        staffTargetReports: payload?.response?.staff_report,
        staffTargetReports_updated: true,
        staffTargetReportsPages: payload?.pages,
        staffTargetReportsAllCount: payload?.count
      };

    case STAFF_TARGET.GET_STAFF_TARGET_REPORT_WITHOUT_PAGINATION:
      return {
        ...state,
        allStaffTargetReports: payload?.response?.staff_report,
        staffTargetReports_updated: true,
      };

    case STAFF_TARGET.GET_STAFFTARGET:
      return {
        ...state,
        staffTargets: payload?.response?.stafftarget,
        staffTargets_updated: true,
        pages: payload?.pages,
        allCount: payload?.count
      };

    case STAFF_TARGET.GET_STAFFTARGET_WITHOUT_PAGINATION:
      return {
        ...state,
        allStaffTargets: payload?.response?.stafftarget,
        staffTargets_updated: true,
      };

    case STAFF_TARGET.ADD_STAFFTARGET:
      return {
        ...state,
        staffTargets: [payload, ...state.staffTargets],
      };

    case STAFF_TARGET.UPDATE_STAFFTARGET:
      let updateList = state?.staffTargets?.map((item) => {
        if (item.id === payload.id) {
          return payload;
        } else {
          return item;
        }
      });
      return {
        ...state,
        staffTargets: updateList,
      };
    case STAFF_TARGET.DELETE_STAFFTARGET:
      let filterArrayAfterDeletion = state?.staffTargets?.filter((staffTarget) => {
        if (staffTarget?.id !== payload) {
          return staffTarget;
        }
      });
      return {
        ...state,
        staffTargets: filterArrayAfterDeletion,
      };
    default:
      return state;
  }
};

export default StaffTargetReducer;
