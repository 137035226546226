import React from 'react'

const Absences = () => {
    return (
        <div className={`schedule-grid-item bg-[#F95C5C]/10 flex justify-center items-center flex-col gap-1`}>
            <p className='font-medium text-[#F95C5C]'>Absent</p>
        </div>
    )
}

export default Absences