import React, { useEffect, useMemo, useState } from "react";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Popup from "Components/TenantApp/Elements/Popup";
import { useVacation } from "../helper";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import Textarea from "Components/TenantApp/Elements/Textarea";
import { toast } from "react-toastify";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";

function AddVacation({ handleClose, editData, editMode }) {
  const {
    vacationHandleChange,
    vacation,
    setVacation,
    employeeDropdownList,
    onBlurHandler,
    errors,
    onSubmitHandler,
    loading,
    onSubmitEditHandler,
    isEdit,
    setIsEdit,
    date,
    employeeListRedux,
    setEmployeePage,
    employeePage,
    setEmployeeSearch,
    employeeSearch,
    employeePaginationData,
    employeeRedux,
    employeeLoader
    // disbledValidation,
    // vacationList
  } = useVacation();
  const { staff, from, to, note, vacation_type } = vacation;

  const state = useSelector((state) => state)
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  useMemo(() => {
    if (editData && editMode) {
      setVacation({
        ...vacation,
        staff: editData?.employee?.id,
        from: editData?.from_date,
        to: editData?.to_date,
        description: editData?.note,
        schedule_id: editData?.id,
      });
      setIsEdit(true);
    }
  }, [editMode]);

  var minToDateLimit = useMemo(() => {
    return moment(from).add(1, 'day').format('YYYY-MM-DD')
  }, [from]);


  const [selectedRemainingVacation, setSelectedRemainingVacation] = useState("")
  const [remainingVacation, setRemainingVacation] = useState([])

  useEffect(() => {
    if (staff) {
      const filteredStaff = employeeListRedux?.find(itm => itm?.id == staff)
      const leaveDataForSelectedStaff = filteredStaff?.leave_data
      setRemainingVacation([
        {
          name: "casual",
          display_name: "casual",
          value: leaveDataForSelectedStaff?.casual_leave,
        },
        {
          name: "medical",
          display_name: "medical",
          value: leaveDataForSelectedStaff?.medical_leave,
        },
        {
          name: "annual",
          display_name: "annual",
          value: leaveDataForSelectedStaff?.annual_leave,
        },
        {
          name: "leo_day",
          display_name: "lieu",
          value: leaveDataForSelectedStaff?.leo_leave,
        },
      ])
    }
  }, [staff])

  return (
    <>
      <Popup
        heading={`${isEdit && editData
          ? "Update Vacation" :
          selectedLanguage?.name == "English" ? "Add Vacation" : translation[628]?.value ? translation[628]?.value : "Add Vacation"
          }`}
        onclose={handleClose}
        size="md"
      >
        <div className="flex flex-col gap-4">
          <PaginationDropdown
            error={errors?.staff}
            setPage={setEmployeePage}
            page={employeePage}
            setDropdownSearch={setEmployeeSearch}
            dropdownSearch={employeeSearch}
            lastPage={employeePaginationData?.employeePages}
            currentPage={employeePaginationData?.employeeCurrentPage}
            title={selectedLanguage?.name == "English" ? "Staff" : translation[335]?.value ? translation[335]?.value : "Staff"}
            placeholder={selectedLanguage?.name == "English" ? "Select Staff" : translation[334]?.value ? translation[334]?.value : "Select Staff"}
            name={"staff"}
            value={staff}
            onChange={(e) => {
              vacationHandleChange(e);
            }}
            loading={employeeLoader}
            // customHeight={'h-[1.25rem]'}
            custom_min_width={'sm:min-w-[15rem] min-w-[20rem]'}
            options={[
              ...employeeRedux?.map((empl, i) => {
                return {
                  label: empl.full_name,
                  value: empl.id,
                  otherData: {
                    image: empl?.image,
                    DataOne: empl.email,
                    showImage: true,
                    index: i
                  },
                  email: empl.email,
                  mobile_number: empl.mobile_number
                };
              })
            ]}
          />
          {/* <Dropwdown
            name="staff"
            required={true}
            value={staff}
            options={employeeDropdownList}
            title={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
            placeholder={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
            onChange={(e) => {
              vacationHandleChange(e);
            }}
            error={errors?.staff}
          /> */}
          {remainingVacation?.length > 0 &&
            <div>
              <div className="pb-3 flex gap-1">
                <p className="text-sm ">{selectedLanguage?.name == "English" ? "Remaining Vacations" : translation[1547]?.value ? translation[1547]?.value : "Remaining Vacations"}</p>
                <span className="text-red-500">*</span>
              </div>
              <div className="flex gap-3 items-center text-sm text-primary">
                {remainingVacation?.map((item, i) => {
                  return (
                    <div onClick={() => {
                      if (item?.value > 0) {
                        setVacation({
                          ...vacation,
                          vacation_type: item?.name
                        })
                        setSelectedRemainingVacation(item?.name)
                      } else {
                        toast.error(`You don't have any ${item.display_name} leave`, { toastId: 'toast' })
                      }
                    }} className={`min-w-[5.5rem] max-w-[5.5rem] rounded-xl flex flex-col justify-center items-center gap-2 bg-[#F2F5FF] py-3 px-2 cursor-pointer ${selectedRemainingVacation == item?.name ? 'border border-primary' : ''}`}>
                      <p className="font-medium capitalize"> {item.display_name}</p>
                      <p className="font-bold text-lg">{item.value}</p>
                    </div>
                  )
                })}
              </div>
            </div>
          }
          {(errors?.vacation_type && !vacation_type) &&
            <span className="text-sm text-red-500">Select vacation type</span>}

          <div className="grid md:grid-cols-2 gap-4">
            <LoginInput
              min={moment(date).format("YYYY-MM-DD")}
              max={"2050-01-01"}
              name="from"
              value={from}
              title={selectedLanguage?.name == "English" ? "From Date" : translation[635]?.value ? translation[635]?.value : "From Date"}
              placeholder="Enter Value"
              type="date"
              required={true}
              onBlur={() => onBlurHandler({ from: from })}
              onChange={(e) => {
                vacationHandleChange(e);
              }}
              error={errors?.from}
            />
            <LoginInput
              min={minToDateLimit}
              max={"2050-01-01"}
              name="to"
              value={to}
              title={selectedLanguage?.name == "English" ? "To Date" : translation[636]?.value ? translation[636]?.value : "To Date"}
              placeholder="Enter Value"
              type="date"
              onBlur={() => onBlurHandler({ to: to })}
              required={false}
              onChange={(e) => {
                vacationHandleChange(e);
              }}
              error={errors?.to}
            />
          </div>
          <Textarea
            name="note"
            value={note}
            title={selectedLanguage?.name == "English" ? "Note" : translation[609]?.value ? translation[609]?.value : "Note"}
            placeholder={selectedLanguage?.name == "English" ? "Enter notes" : translation[1606]?.value ? translation[1606]?.value : "Enter notes"}
            onChange={(e) => {
              vacationHandleChange(e);
            }}
            error={errors?.note}
            limit={'150'}
            rows='5'
          />

          <LoginBtn
            loading={loading}
            disabled={loading}
            // disabled={disbledValidation}

            text={`${editData && isEdit ?
              selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
              : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}`}
            onClick={() => {
              if (editData && isEdit) {
                onSubmitEditHandler(handleClose, editData);
              } else {
                onSubmitHandler(handleClose);
              }
            }}
          />
        </div>
      </Popup>
    </>
  );
}

export default AddVacation;
