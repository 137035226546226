import React from 'react'
import Shimmer from './Shimmer'

const QuickSalesTableHead = () => {
    return (
        <>
            <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">
                <Shimmer className={'w-16 lg:w-20'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                <Shimmer className={'w-16 lg:w-20'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                <Shimmer className={'w-16 lg:w-20'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
            </div>
        </>
    )
}

export default QuickSalesTableHead