import DateInput from 'Components/TenantApp/Elements/DateInput'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import Popup from 'Components/TenantApp/Elements/Popup'
import Textarea from 'Components/TenantApp/Elements/Textarea'
import UploadFile from 'Components/TenantApp/Elements/UploadFile'
import React from 'react'
import useExpensePayment from './helper'

const CreatePaymentPopup = ({ data, open, close, onSuccessAPICall, editData, setEditData, isEdit, setIsEdit }) => {
    const {
        SubmitExpense, onChangeHandler, translation, selectedLanguage, errors, expensePaymentData,
        location_currency, loading, onClose
    } = useExpensePayment(close, data, onSuccessAPICall, editData, isEdit, setEditData, setIsEdit)

    return (
        <>
            <Popup
                heading={
                    isEdit
                        ? selectedLanguage?.name == "English" ? "Update Payment" : translation[1779]?.value ? translation[1779]?.value : "Update Payment"
                        : selectedLanguage?.name == "English" ? "Add Payment" : translation[1772]?.value ? translation[1772]?.value : "Add Payment"
                }
                open={open}
                close={onClose}
            >
                <div className="flex flex-col gap-8">
                    <div className="grid gap-4 grid-cols-2">
                        <div className="flex flex-col">
                            <h1>
                                <b>
                                    {selectedLanguage?.name == "English" ? "Reference No" : translation[1773]?.value ? translation[1773]?.value : "Reference No"}
                                </b>
                            </h1>
                            <p>{data?.reference_number}</p>
                            <p>
                                <b>{selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}</b>: { }
                                {data?.location_name}
                            </p>
                        </div>
                        <div className="flex flex-col">
                            <h1>
                                <b>{selectedLanguage?.name == "English" ? "Total Amount" : translation[183]?.value ? translation[183]?.value : "Total Amount"}</b>
                            </h1>
                            <p>{location_currency}{ } {data?.total_amount}</p>
                            <p >
                                <b>{selectedLanguage?.name == "English" ? "Payment Note" : translation[1752]?.value ? translation[1752]?.value : "Payment Note"}</b>: { }
                                {data?.cheque_details?.payment_note || data?.card_details?.payment_note || data?.online_payment_details?.payment_note || data?.payment_note}
                            </p>
                        </div>
                    </div>

                    <div className="grid gap-4 grid-cols-2">
                        <Dropwdown
                            required={true}
                            disabled={true}
                            title={selectedLanguage?.name == "English" ? "Payment Method" : translation[742]?.value ? translation[742]?.value : "Payment Method"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Payment Method" : translation[736]?.value ? translation[736]?.value : "Select Payment Method"}
                            options={[
                                { label: data?.payment_method, value: data?.payment_method }
                            ]}
                            value={data?.payment_method}
                        // onChange={(e) => setPaym(e?.target?.value)}
                        />
                        <DateInput
                            required={true}
                            title={selectedLanguage?.name == "English" ? "Date" : translation[450]?.value ? translation[450]?.value : "Date"}
                            custom_padding={" "}
                            name={'date'}
                            onChange={onChangeHandler}
                            value={expensePaymentData?.date}
                            error={errors?.date}
                        />
                        <FloatInput
                            required={true}
                            name={'amount'}
                            onChange={onChangeHandler}
                            value={expensePaymentData?.amount}
                            error={errors?.amount}
                            title={selectedLanguage?.name == "English" ? "Amount" : translation[793]?.value ? translation[793]?.value : "Amount"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Amount" : translation[794]?.value ? translation[794]?.value : "Enter Amount"}
                        />
                        <Textarea
                            parent='col-span-2'
                            required={true}
                            title={selectedLanguage?.name == "English" ? "Payment Note" : translation[1752]?.value ? translation[1752]?.value : "Payment Note"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Payment Note" : translation[1753]?.value ? translation[1753]?.value : "Enter Payment Note"}
                            name='payment_note'
                            onChange={onChangeHandler}
                            value={expensePaymentData?.payment_note}
                            error={errors?.payment_note}
                        />
                        <UploadFile
                            accept={".PNG,.JPG,.JPEG,.pdf,.docx,.doc"}
                            onChange={onChangeHandler}
                            name="file"
                            id={'expense_file'}
                            mb_file_size={10}
                            file_type={expensePaymentData?.file_type}
                            value={expensePaymentData?.file}
                            error={errors?.file}
                            title={selectedLanguage?.name == "English" ? "Document" : translation[1776]?.value ? translation[1776]?.value : "Document"}
                        />
                    </div>
                </div>
                <div>
                    <LoginBtn
                        className={'capitalize mt-4'}
                        text={isEdit
                            ? selectedLanguage?.name == "English" ? "Update Payment" : translation[1779]?.value ? translation[1779]?.value : "Update Payment"
                            : selectedLanguage?.name == "English" ? "Add Payment" : translation[1772]?.value ? translation[1772]?.value : "Add Payment"
                        }
                        onClick={SubmitExpense}
                        loading={loading}
                        disabled={loading}
                    />
                </div>

            </Popup>
        </>
    )
}

export default CreatePaymentPopup