import { useState } from 'react'
import ReviewConfirm from './ProfileAppointmentSteps/ReviewConfirm'
import SelectServices from './ProfileAppointmentSteps/SelectServices'
import SelectTime from './ProfileAppointmentSteps/SelectTime'
import SelectStaff from './ProfileAppointmentSteps/SelectStaff'

const ProfileAppointment = () => {
    const [Step, setStep] = useState(1);

    const obj = {
        1: <SelectServices setStep={setStep} step={Step} />,
        2: <ReviewConfirm setStep={setStep} step={Step} />,
        3: <SelectStaff setStep={setStep} step={Step} />,
        4: <SelectTime setStep={setStep} step={Step} />,
    }
    return (
        obj[Step]
    )
}

export default ProfileAppointment