import Svgs from "Assets/svgs";
import { toast } from "react-toastify";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAddress from "Hooks/useAddress";
import useValidations from "Hooks/useValidations";
import useMarketPlace from "./helper";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import useObjectKeys from "Hooks/useObjectKeys";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import PhoneInput from "Components/TenantApp/Elements/PhoneInput";
import ToggleWithText from "Components/TenantApp/Elements/ToggleWithText";
import FormSteps from "Components/TenantApp/Elements/FormSteps";
import Popup from "Components/TenantApp/Elements/Popup";
import { useSelector, useDispatch } from "react-redux";
import { WEEKDAYS } from "Constants/Data/Months";
import { OPENING_TIME } from "Constants/Data/OpeningHours";
import { apiErrorMessage } from "Constants/Data/Errors";

// import NotFound from "Components/Utility/NotFound";
import {
  createBusinessLocation,
  getAllLanguages,
  // deleteBusinessLocation,
  // getBusinessById,
  // getBusinessTypes,
  // getLocations,
  // updateBusiness,
  updateLocations,
} from "Adapters/Api";
import {
  createLocations,
  // deleteLocations,
  // editBusiness,
  // fetchBusiness,
  updateLocation,
} from "Redux/TenantRedux/Actions/locationActions";
import { useRef } from "react";
import { getLanguages } from "Redux/TenantRedux/Actions/multiLanguageInvoicesActions";
import { getMultiLanguageInvoice } from "Adapters/Api/multiLanguageInvoice";
import NoOnlineProfileFound from "Components/Utility/NoOnlineProfileFound";
import OnlineProfilesShimmer from "Components/Utility/Shimmers/OnlineProfilesShimmer";
import Textarea from "Components/TenantApp/Elements/Textarea";

const MarketPlace = () => {
  // const state = useSelector((state) => state);
  const dispatch = useDispatch();

  const reduxstate = useSelector((state) => state);
  const locationList = useSelector((state) => state.locations.locations);
  const currency_list = reduxstate?.utility?.currency?.currency_list;
  const reduxInvoices = reduxstate?.multilanguageInvoices?.invoices;

  const { storeDataInLocalStorage, getDataFromLocalStorage } =
    useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");

  const formRefLocation = useRef()

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [countryId, setCountryId] = useState();
  const [stateId, setStateId] = useState();
  const [Location, setLocation] = useState(false);
  const [location2, setLocation2] = useState({});
  const [step, setstep] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addres, setAddress] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonClick, setButtonClick] = useState(false)

  const [businessLocations, setBusinessLocations] = useState({
    country_name: "",
    state_name: "",
    city_name: "",
  });
  //location form state
  const [locationData, setLocationData] = useState({
    address_name: "",
    address: "",
    postal_code: "",
    country: "",
    state: "",
    city: "",
    email: "",
    mobile_number: "",
    banking: "Enable",
    start_time: "",
    close_time: "",
    currency: "",
    primary_translation_id: reduxInvoices?.length > 0 ? reduxInvoices?.find(itm => itm?.language_data?.name == "English")?.id : "",
    secondary_translation_id: ""
  });

  const {
    address_name,
    address,
    country,
    state,
    city,
    postal_code,
    email,
    banking,
    mobile_number,
    currency,
    primary_translation_id,
    secondary_translation_id,
    privacy_policy
  } = locationData;
  const { objectKeyConvertToArray } = useObjectKeys();
  // location form handler
  const handleLocationChange = (e, fieldName = "") => {
    if (e?.target) {
      const { name, value } = e?.target;
      let trimmedValue = value

      if (name === "country") {
        setCountryId(trimmedValue)
        setLocationData((prev) => ({
          ...prev,
          city: '',
        }));
        // setCityList();
      }
      if (name === "privacy_policy") {
        trimmedValue = value?.slice(0, 500); // Trim the value to a maximum of 150 characters

      }
      setLocationData((prev) => ({
        ...prev,
        [name]: trimmedValue,
      }));

      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
      name === "state" && setStateId(trimmedValue);
    }
  };

  const getLanguageList = async () => {
    const response = await getAllLanguages();
    if (response.status === 200) {
      let languageList = response?.data?.response?.languages;
      dispatch(getLanguages(languageList))
      // let laguageOptionList = setOptionForDropdown(languageList);
      // setAllLanguages(laguageOptionList);
    }
  };

  useEffect(() => {
    getLanguageList()
  }, [])

  const onfirststepsubmit = () => {
    const requiredFields = {
      currency,
      address_name,
      address,
      email,
      mobile_number,
      country,
      primary_translation_id
    };
    setErrors(validation(requiredFields));
    const result = validation(requiredFields);
    if (objectKeyConvertToArray(result)?.length === 0) {
      setstep(2);
    } else {
      formRefLocation.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onLocationSubmit = async () => {
    if (!isButtonDisabled) {
      const requiredFields = {
        currency,
        address_name,
        address,
        email,
        mobile_number,
        country,
        primary_translation_id
      };
      setErrors(validation(requiredFields));
      const result = validation(requiredFields);
      if (objectKeyConvertToArray(result)?.length === 0) {
        const location_id = getDataFromLocalStorage("location_id");
        if (isEdit) {
          setLoading(true);
          let form_data = new FormData();
          for (let key in locationData) {
            if (locationData[key]) {
              form_data.append(key, locationData[key]);
            }
          }

          form_data.append("open_day", JSON.stringify(location2));
          form_data.append("business", businessData.id);

          const response = await updateLocations(form_data, access_token);
          if (response?.status === 200) {
            let location = response?.data?.response?.location;
            let successMessage = response?.data?.response?.message;
            setLocationData({
              address_name: "",
              address: "",
              postal_code: "",
              country: "",
              state: "",
              city: "",
              email: "",
              mobile_number: "",
              start_time: "",
              close_time: "",
              banking: "Enable",
            });
            setLocation2({});
            setStateList();
            // setCityList();
            setCountryId();
            setStateId();
            dispatch(updateLocation(location));
            setLocation(!Location);
            toast.success(successMessage, { toastId: "toast" });
            setLoading(false);
            setstep(1);
          } else {
            setLoading(false);
            toast.error(apiErrorMessage, { toastId: "toast" });
          }
        } else {
          setLoading(true);

          let form_data = new FormData();
          for (let key in locationData) {
            if (locationData[key]) {
              form_data.append(key, locationData[key]);
            }
          }
          form_data.append("open_day", JSON.stringify(location2));
          form_data.append("business", businessData.id);

          // const response = await createBusinessLocation({ ...locationData, business: businessData?.id }, access_token);
          const response = await createBusinessLocation(form_data, access_token);
          if (response?.status === 201) {
            let locations = response?.data?.response?.locations;
            let successMessage = response?.data?.response?.message;
            toast.success(successMessage, { toastId: "toast" });
            dispatch(createLocations(locations));
            if (locationList?.length === 0) {
              storeDataInLocalStorage("selected_location", locations?.id);
              const selected_location = getDataFromLocalStorage('selected_location')
              if (selected_location != locations?.id) {
                storeDataInLocalStorage('locationChange', true)
              }
            }
            setLoading(false);

            setLocationData({
              address_name: "",
              address: "",
              postal_code: "",
              country: "",
              state: "",
              city: "",
              email: "",
              mobile_number: "",
              start_time: "",
              close_time: "",
              banking: "Enable",
              primary_translation_id: reduxInvoices?.length > 0 ? reduxInvoices?.find(itm => itm?.language_data?.code == "eng")?.id : "",
              secondary_translation_id: ""
            });
            setLocation2({});
            setStateList();
            // setCityList();
            setCountryId();
            setStateId();
            setLocation(!Location);
            // getBusinessLocation(businessData?.id);
            setstep(1);
          } else {
            setLoading(false);

            toast.error(apiErrorMessage, { toastId: "toast" });
          }
        }
      }
    } else {
      formRefLocation.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleAddressSuggetion = async (value) => {
    const result = await geocodeByAddress(value);
    setAddress(value);
    setLocationData((prev) => ({ ...prev, address: value }));
    setErrors((prev) => ({ ...prev, address: "" }));
  };


  const { countriesList, stateList, setStateList } =
    useAddress(countryId);
  const { setErrors, validation, errors, onBlurHandler, validateWebsiteUrl } =
    useValidations();


  const businessData = getDataFromLocalStorage("business_data");
  const sub_domain = getDataFromLocalStorage("domain-name");
  const hash = getDataFromLocalStorage("hash");
  const token = getDataFromLocalStorage("access_token");
  let urls = window?.location?.host;

  const route = useNavigate();
  const { locationsList, loader } = useMarketPlace();

  const translation = reduxstate?.languages?.language_translation
  const selectedLanguage = reduxstate?.languages?.selected_language

  useEffect(() => {
    setEmployee(reduxstate?.user?.user?.employee);
    setEmployeePermissions(reduxstate?.user?.permissions);
  }, [reduxstate?.user]);

  useEffect(() => {
    if (locationData?.primary_translation_id == "" && reduxInvoices?.length > 0) {
      setLocationData((prev) => ({ ...prev, primary_translation_id: reduxInvoices?.length > 0 ? reduxInvoices?.find(itm => itm?.language_data?.code == "eng")?.id : "" }));
    }
  }, [reduxInvoices, reduxInvoices?.length, locationData?.primary_translation_id])

  // const getInovices = () => {
  //   setLoading(true)
  //   const success = () => { setLoading(false) }
  //   const fail = () => { setLoading(false) }
  //   dispatch(getMultiLanguageInvoice(access_token, success, fail))
  // }

  // useEffect(() => {
  // getInovices()
  // }, [])

  useEffect(() => {
    let isDisabled = false;
    if (step == 2 && (Object.keys(location2)?.length == 0
      || Object.keys(location2)?.find(key => (!location2[key]?.end_time || !location2[key]?.start_time)))) {
      isDisabled = true;
    }
    setIsButtonDisabled(isDisabled);
  }, [location2, step]);

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full flex flex-col gap-[2rem]">
        {loader ?
          <OnlineProfilesShimmer />
          :
          <>
            <div className="flex items-start justify-between gap-4">
              <div className="flex items-center gap-2">

                <h1 className="font-semibold text-2xl">
                  {selectedLanguage?.name == "English" ? "Online Profile" : translation[6]?.value ? translation[6]?.value : "Online Profile"}
                </h1>
              </div>
              {!employee && (
                <LoginBtn
                  onClick={() => {
                    setStateList();
                    // setCityList();
                    setCountryId();
                    // setStateId();
                    setIsEdit(false);
                    setLocation(!Location);
                  }}
                  text={
                    <div className="flex items-center gap-2">
                      <Svgs.Plus />
                      <p>
                        {selectedLanguage?.name == "English" ? "Add New Location" : translation[38]?.value ? translation[38]?.value : "Add New Location"}

                      </p>
                    </div>}
                />
              )}
            </div>
            {locationsList?.length > 0 ? (
              locationsList?.map((itm) => {
                return (
                  <div className="flex md:items-end items-start justify-between gap-4 border-b pb-4 md:flex-row flex-col">
                    <div className="flex items-center gap-4">
                      {itm?.images ? (
                        <img
                          src={itm?.images}
                          className="h-[7rem] w-[10rem] object-cover rounded-lg"
                        />
                      ) : (
                        <div className="h-[7rem] w-[10rem] object-cover rounded-lg flex items-center border justify-center bg-gray-100">
                          <Svgs.Home fill={"#101928"} />
                        </div>
                      )}

                      <div className="flex flex-col gap-2">
                        <h1 className="font-semibold text-xl capitalize line-clamp-1">
                          {itm?.address_name}
                        </h1>
                        <p className="text-[#7B7B7B] text-sm capitalize line-clamp-1">
                          {itm?.address}
                        </p>
                        <StatusBtn
                          profile_status={
                            itm?.is_publish ?
                              selectedLanguage?.name == "English" ? "Online" : translation[43]?.value ? translation[43]?.value : "Online"
                              :
                              selectedLanguage?.name == "English" ? "Offline" : translation[42]?.value ? translation[42]?.value : "Offline"
                          }
                          status={`${itm?.is_publish ? "Online" : "Offline"}`}
                          selectedLanguage={selectedLanguage}
                          translation={translation}
                        />
                      </div>
                    </div>
                    <div className="flex items-center gap-3">
                      {!employee || (employee &&
                        employeePermissions?.profile_list?.includes("edit")) ? (
                        <LoginBtn
                          disabled={itm?.is_publish ? false : true}
                          text={selectedLanguage?.name == "English" ? "Edit" : translation[39]?.value ? translation[39]?.value : "Edit"}
                          borderBtn
                          onClick={() => {
                            route(`/dashboard/marketplace-profile/admin-booking/?id=${itm?.id}&edit=true`);
                          }}
                        />
                      ) : (
                        ""
                      )}

                      {!employee ? (
                        <LoginBtn
                          text={`${itm?.is_publish ?
                            selectedLanguage?.name == "English" ? "Preview Store" : translation[41]?.value ? translation[41]?.value : "Preview Store"
                            :
                            selectedLanguage?.name == "English" ? "List Your Profile" : translation[40]?.value ? translation[40]?.value : "List Your Profile"
                            }`}
                          onClick={() => {
                            if (itm?.is_publish) {
                              window.open(
                                `http://${window?.location?.host
                                  .split(".")
                                  .slice(1)
                                  .join(".")}/profile/${itm?.address_name
                                    ?.replaceAll(",", "")
                                    .replaceAll(" ", "-")}/${itm?.address
                                      ?.replaceAll(",", "")
                                      .replaceAll(" ", "-")}/${itm?.id}?business=${businessData.id
                                }&domain=${sub_domain}&hash=${hash}&token=${token}`,
                                "_blank",
                                "noreferrer"
                              );
                            } else {
                              route(`/dashboard/marketplace-profile/admin-booking/?id=${itm?.id}`);
                            }
                          }}
                        />
                      ) : (employee &&
                        employeePermissions?.profile_list?.includes("edit")) ||
                        employeePermissions?.profile_list?.includes("view") ||
                        employeePermissions?.profile_list?.includes("create") ? (
                        <LoginBtn
                          text={`${itm?.is_publish ?
                            selectedLanguage?.name == "English" ? "Preview Store" : translation[41]?.value ? translation[41]?.value : "Preview Store"
                            :
                            selectedLanguage?.name == "English" ? "List Your Profile" : translation[40]?.value ? translation[40]?.value : "List Your Profile"
                            }`}
                          onClick={() => {
                            if (itm?.is_publish) {
                              window.open(
                                `http://${window?.location?.host
                                  .split(".")
                                  .slice(1)
                                  .join(".")}/profile/${itm?.address_name
                                    ?.replaceAll(",", "")
                                    .replaceAll(" ", "-")}/${itm?.address
                                      ?.replaceAll(",", "")
                                      .replaceAll(" ", "-")}/${itm?.id}?business=${businessData.id
                                }&domain=${sub_domain}&hash=${hash}&token=${token}`,
                                "_blank",
                                "noreferrer"
                              );
                            } else {
                              route(`/dashboard/marketplace-profile/admin-booking/?id=${itm?.id}`);
                            }
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <NoOnlineProfileFound />
            )}
          </>
        }
      </div>
      <Popup
        size="lg"
        heading={`${isEdit ?
          selectedLanguage?.name == "English" ? "Edit Location" : translation[88]?.value ? translation[88]?.value : "Edit Location"
          :
          selectedLanguage?.name == "English" ? "Add Location" : translation[44]?.value ? translation[44]?.value : "Add Location"
          } `}
        close={() => {
          setLocation(!Location);
          setBusinessLocations({
            country_name: "",
            state_name: "",
            city_name: "",
          });
          setstep(1);
          setLocation2({});
          setLocationData({
            address_name: "",
            address: "",
            postal_code: "",
            country: "",
            state: "",
            city: "",
            email: "",
            mobile_number: "",
            banking: "Enable",
          });
          setErrors({});
        }}
        open={Location}
      >
        <div className="pb-[1.5rem]">
          <FormSteps
            currentStep={step}
            setStep={setstep}
            active={step}
            steps={[
              selectedLanguage?.name == "English" ? "Location Details" : translation[46]?.value ? translation[46]?.value : "Location Details",
              selectedLanguage?.name == "English" ? "Time Schedule" : translation[47]?.value ? translation[47]?.value : "Time Schedule"
            ]}
            stepsLength="2"
          />
        </div>

        {step === 1 && (
          <div className="flex flex-col gap-6" ref={formRefLocation}>
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
              <CircleIcon>
                <Svgs.Location />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Add your name location" : translation[45]?.value ? translation[45]?.value : "Add your name location"}
                </p>
              </div>
            </div>
            <LoginInput
              required={true}
              name="address_name"
              value={address_name}
              // charactersMaxLength={25}
              onChange={handleLocationChange}
              error={errors.address_name}
              onBlur={() => onBlurHandler({ address_name: address_name })}
              title={selectedLanguage?.name == "English" ? "Location Name" : translation[48]?.value ? translation[48]?.value : "Location Name"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Location Name" : translation[50]?.value ? translation[50]?.value : "Enter Location Name"}
            />

            <PlacesAutocomplete
              value={addres}
              onChange={setAddress}
              onSelect={handleAddressSuggetion}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <LoginInput
                    {...getInputProps()}
                    required={true}
                    // value={address}
                    error={errors.address}
                    onBlur={() => onBlurHandler({ address: address })}
                    title={selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter Business Address" : translation[51]?.value ? translation[51]?.value : "Enter Business Address"}
                  />

                  {suggestions.length > 0 && (
                    <div className="autocomplete-dropdown-container border rounded-md mt-2">
                      {loading && (
                        <div className="rounded-md p-2 text-xs text-[#555555]">
                          Loading...
                        </div>
                      )}
                      {suggestions.map((suggestion) => {
                        const className =
                          "rounded-md p-2 text-xs hover:!bg-gray-100 text-[#555555]";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
              <Dropwdown
                required={true}
                title={selectedLanguage?.name == "English" ? "Country" : translation[52]?.value ? translation[52]?.value : "Country"}
                placeholder={selectedLanguage?.name == "English" ? "Select Country" : translation[53]?.value ? translation[53]?.value : "Select Country"}
                name="country"
                value={country}
                onChange={(e) => handleLocationChange(e, "country")}
                onBlur={() => onBlurHandler({ country: country })}
                error={errors.country}
                options={countriesList}
                edit={isEdit ? true : false}
              />
              <Dropwdown
                required={false}
                title={selectedLanguage?.name == "English" ? "State/Province" : translation[54]?.value ? translation[54]?.value : "State/Province"}
                placeholder={selectedLanguage?.name == "English" ? "Select State" : translation[55]?.value ? translation[55]?.value : "Select State"}

                name="state"
                value={state}
                onChange={(e) => handleLocationChange(e, "state")}
                options={stateList}
                edit={isEdit ? true : false}
              />
              {/* <Dropwdown
                required={false}
                title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Postal Code" : translation[57]?.value ? translation[57]?.value : "Select City"}
                name="city"
                value={city}
                onChange={handleLocationChange}
                // onBlur={() => onBlurHandler({ city: city })}
                // error={errors.city}
                options={cityList}
                edit={isEdit ? true : false}
              /> */}
              <LoginInput
                required={false}
                name={"city"}
                value={city}
                disabled={!state || !country}
                onChange={handleLocationChange}
                title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                placeholder={selectedLanguage?.name == "English" ? "Enter City Name" : translation[57]?.value ? translation[57]?.value : "Enter City Name"}
              />

              <LoginInput
                required={false}
                title={selectedLanguage?.name == "English" ? "Postal Code" : translation[58]?.value ? translation[58]?.value : "Postal Code"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Postal Code" : translation[59]?.value ? translation[59]?.value : "Enter Postal Code"}
                name="postal_code"
                value={postal_code}
                onChange={handleLocationChange}
              />

              <LoginInput
                required={true}
                title={selectedLanguage?.name == "English" ? "Email Address" : translation[60]?.value ? translation[60]?.value : "Email Address"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Email Address" : translation[61]?.value ? translation[61]?.value : "Enter Email Address"}
                name="email"
                value={email}
                onChange={handleLocationChange}
                error={errors.email}
                onBlur={() => onBlurHandler({ email: email })}
                disabled={isEdit ? true : false}
              />

              <PhoneInput
                required={true}
                title={selectedLanguage?.name == "English" ? "Phone Number" : translation[62]?.value ? translation[62]?.value : "Phone Number"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Phone Number" : translation[63]?.value ? translation[63]?.value : "Enter Phone Number"}
                name="mobile_number"
                value={mobile_number}
                onChange={handleLocationChange}
                error={errors.mobile_number}
                onBlur={() => onBlurHandler({ mobile_number: mobile_number })}
                edit={isEdit ? true : false}
              />

              <Dropwdown
                required={false}
                title={selectedLanguage?.name == "English" ? "Booking" : translation[64]?.value ? translation[64]?.value : "Booking"}
                placeholder={selectedLanguage?.name == "English" ? "Select Booking" : translation[65]?.value ? translation[65]?.value : "Select Booking"}
                name="banking"
                value={banking}
                onChange={handleLocationChange}
                options={[
                  { value: "Enable", label: selectedLanguage?.name == "English" ? "Enable" : translation[65]?.value ? translation[65]?.value : "Enable" },
                  { value: "Disable", label: selectedLanguage?.name == "English" ? "Disable" : translation[66]?.value ? translation[66]?.value : "Disable" },
                ]}
              />
              <Dropwdown
                required={true}
                title={selectedLanguage?.name == "English" ? "Currency" : translation[67]?.value ? translation[67]?.value : "Currency"}
                placeholder={selectedLanguage?.name == "English" ? "Select Currency" : translation[68]?.value ? translation[68]?.value : "Select Currency"}
                name={"currency"}
                value={currency}
                onChange={handleLocationChange}
                onBlur={() => onBlurHandler({ currency: currency })}
                error={errors.currency}
                options={currency_list?.map((crncy) => {
                  return {
                    label: crncy?.code,
                    value: crncy?.id,
                  };
                })}
              />

              <Dropwdown
                required={true}
                title={selectedLanguage?.name == "English" ? "Primary Language" : translation[1290]?.value ? translation[1290]?.value : "Primary Language"}
                placeholder={selectedLanguage?.name == "English" ? "Select Primary Language" : translation[1291]?.value ? translation[1291]?.value : "Select Primary Language"}
                name="primary_translation_id"
                value={primary_translation_id}
                onChange={handleLocationChange}
                error={errors.primary_translation_id}
                onBlur={() => onBlurHandler({ primary_translation_id: primary_translation_id })}
                options={reduxInvoices?.filter(itm => itm?.status == "active")
                  ?.filter(itm => itm?.language_data?.id != secondary_translation_id)?.map((lang) => {
                    return {
                      label: lang?.language_data?.name,
                      value: lang?.id,
                    };
                  })}
              />

              <Dropwdown
                title={selectedLanguage?.name == "English" ? "Secondary Language" : translation[1289]?.value ? translation[1289]?.value : "Secondary Language"}
                placeholder={selectedLanguage?.name == "English" ? "Select Secondary Language" : translation[1288]?.value ? translation[1288]?.value : "Select Secondary Language"}
                name="secondary_translation_id"
                value={secondary_translation_id}
                onChange={handleLocationChange}
                error={errors.secondary_translation_id}
                onBlur={() => onBlurHandler({ secondary_translation_id: secondary_translation_id })}
                options={[
                  {
                    value: "",
                    label: selectedLanguage?.name == "English" ? "Select Secondary Language" : translation[1288]?.value ? translation[1288]?.value : "Select Secondary Language"
                  },
                  ...reduxInvoices?.filter(itm => itm?.status == "active")?.filter(itm => itm?.id != primary_translation_id)?.map((lang) => {
                    return { value: lang?.id, label: lang?.language_data?.name };
                  }),
                ]}
              />
            </div>
            <div>
              <Textarea
                error={errors.privacy_policy}
                title={selectedLanguage?.name == "English" ? "Privacy Policy" : translation[92]?.value ? translation[92]?.value : "Privacy Policy"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Privacy Policy" : translation[93]?.value ? translation[93]?.value : "Enter Privacy Policy"}
                rows='6'
                limit='500'
                name='privacy_policy'
                onChange={handleLocationChange}
                disabled={loading}
                value={privacy_policy}
                required={false}
              />
            </div>
            <div className="flex items-center justify-start mt-[2rem]">
              <LoginBtn
                // disabled={
                //   !currency ||
                //   !address_name ||
                //   !address ||
                //   !email ||
                //   !mobile_number ||
                //   !country
                // }
                onClick={() => {
                  // setstep(2)
                  onfirststepsubmit();
                  // onLocationSubmit
                }}

              >
                <div className="flex items-center gap-2">
                  <div className="">
                    {selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
                  </div>
                  <Svgs.NextArrow />
                </div>
              </LoginBtn>
            </div>
          </div>
        )}
        {step == 2 && (
          <div className="flex flex-col gap-24 md:gap-16 lg:gap-4" ref={formRefLocation}>
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[3rem]">
              <CircleIcon>
                <Svgs.Location />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Opening Hours" : translation[70]?.value ? translation[70]?.value : "Opening Hours"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Set the opening and closing hours of your business" : translation[71]?.value ? translation[71]?.value : "Set the opening and closing hours of your business"}
                </p>
              </div>
            </div>
            {WEEKDAYS.map((days, index) => {
              days = days.toLowerCase();
              return (
                <>
                  <div className="md:grid flex grid-cols-5 gap-4 lg:flex-row flex-col pb-[1.5rem] h-[3.75rem]">
                    <div className="col-span-1 flex items-center">
                      <div className="w-full">
                        <ToggleWithText
                          checked={
                            location2[days]?.start_time &&
                            location2[days]?.end_time
                          }
                          title={<p className="capitalize">{days}</p>}
                          value={location2[days]}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setLocation2({
                                ...location2,
                                [days]: {
                                  is_closed: false,
                                  start_time:
                                    days === "monday"
                                      ? ""
                                      : location2["monday"]?.start_time,
                                  end_time:
                                    days === "monday"
                                      ? ""
                                      : location2["monday"]?.end_time,
                                },
                              });
                            } else {
                              delete location2[days];
                              setLocation2({
                                ...location2,
                                // [days]: null
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    {location2[days] && (
                      <div className="col-span-4 flex items-center w-full sm:gap-4 gap-2 lg:flex-row flex-col">
                        <Dropwdown
                          className="w-full"
                          title={false}
                          showTitle={false}
                          placeholder={selectedLanguage?.name == "English" ? "Start Time" : translation[73]?.value ? translation[73]?.value : "Start Time"}
                          options={OPENING_TIME}
                          // options={OPENING_TIME.filter((itm, itm_index) => {
                          //   if (!location2[days]?.end_time) {
                          //     return true;
                          //   } else {
                          //     let index_start = OPENING_TIME.find(
                          //       (op_itm) =>
                          //         op_itm.value == location2[days]?.end_time
                          //     );
                          //     index_start = OPENING_TIME.indexOf(index_start);

                          //     if (index_start >= 0 && itm_index < index_start) {
                          //       return true;
                          //     }

                          //     return false;
                          //   }
                          // })}
                          value={location2[days]?.start_time}
                          onChange={(e) => {
                            setLocation2({
                              ...location2,
                              [days]: {
                                ...location2[days],
                                start_time: e.target.value,
                              },
                            });
                          }}
                        // value={location2.sunday.start_time}
                        />
                        <Dropwdown
                          className="w-full"
                          title={false}
                          showTitle={false}
                          placeholder={selectedLanguage?.name == "English" ? "End time" : translation[74]?.value ? translation[74]?.value : "End time"}
                          options={OPENING_TIME.filter((itm, itm_index) => {
                            if (!location2[days]?.start_time) {
                              return true;
                            } else {
                              let index_start = OPENING_TIME.find(
                                (op_itm) =>
                                  op_itm.value == location2[days]?.start_time
                              );
                              index_start = OPENING_TIME.indexOf(index_start);
                              if (index_start >= 0 && itm_index > index_start) {
                                return true;
                              }
                              return false;
                            }
                          })}
                          value={location2[days]?.end_time}
                          onChange={(e) => {
                            setLocation2({
                              ...location2,
                              [days]: {
                                ...location2[days],
                                end_time: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              );
            })}
            <div className=" text-red-500">
              {(isButtonDisabled && buttonClick) && "Add at least one day of business working hours"}
            </div>
            <div className="flex items-center justify-start md:mt-[2rem]">
              <LoginBtn
                loading={loading}
                text={`${isEdit ?
                  selectedLanguage?.name == "English" ? "Edit Location" : translation[88]?.value ? translation[88]?.value : "Edit Location"
                  :
                  selectedLanguage?.name == "English" ? "Add Location" : translation[44]?.value ? translation[44]?.value : "Add Location"
                  }`}
                onClick={() => {
                  // setstep(2),
                  setButtonClick(true)
                  onLocationSubmit();
                }}
                disabled={loading}

              >
                {/* <Svgs.NextArrow /> */}
              </LoginBtn>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
};

export default MarketPlace;
