import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  delete_brand,
  get_business_brands,
  get_business_brands_with_no_pagination,
} from "../../../../../Adapters/Api/Brand";
import Svgs from "../../../../../Assets/svgs";
import NotFound from "../../../../Utility/NotFound";
import AddBrandPopup from "../../../Brand/AddBrandPopup";
import LoginBtn from "../../../Elements/LoginBtn";
import LoginInput from "../../../Elements/LoginInput";
import Popup from "../../../Elements/Popup";
import StatusBtn from "../../../Elements/StatusBtn";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import useSetDataInLocalStorage from "../../../../../Hooks/useSetDataInLocalStorage";
import IconButton from "Components/TenantApp/Elements/IconButton";
import Pagination from "new-components/Pagination";
import {
  getsBrandsImport,
  getsProducts,
  // getsProductsImport,
} from "../../../../../Adapters/Api/Products";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import TableShimmer_v2 from "Components/Utility/Shimmers/TableShimmer_v2";
import NoBrandFound from "Components/Utility/NoBrandFound";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
// import PreloaderFull from "./../../../Elements/PreloaderFull";
// import CircleIcon from "../../../Elements/CircleIcon";
// import Dropwdown from "../../../Elements/Dropwdown";
// import FormSteps from "../../../Elements/FormSteps";
// import Textarea from "../../../Elements/Textarea";
// import ToggleWithText from "../../../Elements/ToggleWithText";
// import UploadFile from "../../../Elements/UploadFile";
// import AddCategoryBtn from "./AddHeaderBtns/AddCategoryBtn";
// import AddProductBtn from "./AddHeaderBtns/AddProductBtn";
// import { getProducts } from "../../../../../Redux/TenantRedux/Actions/product";

const BrandlistCard = ({ ShowDetails, setShowDetails, data, onEditClick, onDeleteClick, hideDeleteIcon }) => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <>
      {/* <div className={``}> */}
        <div className={`px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(155px,1fr))] gap-3 min-w-full w-fit odd-bg text-[#3A3A3A] transition-all ${ShowDetails == data.id ? "border-l-[4px] border-[#5679FFB2]" : "border-l-[0px]"}`}>
          <div className="flex items-center"
          // className={`before-left-arrow cursor-pointer ${ShowDetails == data.id
          //   ? "before-left-arrow-open"
          //   : "before-left-arrow-close"
          //   }`}
          // onClick={() => {
          //   setShowDetails(
          //     data.id == ShowDetails ? undefined : data.id
          //   );
          // }}
          >
            <div className="flex items-center gap-3">
              <img
                src={data?.image != null ? data?.image : `${process.env.PUBLIC_URL}/images/brand-dummy-image.png`}
                className="h-[3rem] w-[3rem] object-cover rounded-full"
              />
              <p className="text-sm">
                {data?.name?.slice(0, 40)}
                {data?.name?.length > 40 && "..."}
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <p className="text-sm truncate">
              {data?.website ? data?.website?.slice(0, 20) : "-------"}
              {data?.website?.length > 20 && "..."}
            </p>
          </div>
          <div className="flex items-center">
            <StatusBtn
              profile_status={
                data?.is_active ?
                  selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                  :
                  selectedLanguage?.name == "English" ? "In Active" : translation[1303]?.value ? translation[1303]?.value : "In Active"
              }
              status={data?.is_active ? "active" : "inActive"}
            />
          </div>
          <div className="flex items-center">
            <p className="text-sm line-clamp-3 pr-2">
              {data?.description ? data?.description : "-------"}
            </p>
          </div>
          {!employee ? (
            <div className="flex items-center gap-2">
              <div
                onClick={() => { onEditClick(data); }}
                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
              >
                <Svgs.Pen />
              </div>
              {!hideDeleteIcon &&
                <div
                  className="h-[2.5rem] cursor-pointer w-[2.5rem] border rounded-full flex items-center justify-center"
                  onClick={onDeleteClick}
                >
                  <Svgs.Delete />
                </div>
              }
            </div>
          ) : (
            ""
          )}

        </div>
        {ShowDetails == data.id ? (
          <div className="scale-in-center">
            <div className="bg-[#FAF9FF] px-6 py-3 inline-grid xl:grid grid-cols-[repeat(4,minmax(180px,1fr))]">
              <div className="flex items-center gap-12">
                <div className="flex items-center gap-3 col-span-1">
                  <img
                    src={data?.image != null ? data?.image : `${process.env.PUBLIC_URL}/images/brand-dummy-image.png`}
                    className="h-[3rem] w-[3rem] object-cover rounded-lg"
                  />
                  <div className="flex flex-col gap-2 flex-1">
                    <p className="text-black font-semibold ">
                      {data?.name}
                    </p>
                    {/* <div className="w-fit">
                      <StatusBtn
                        profile_status={
                          data?.is_active ?
                            selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                            :
                            selectedLanguage?.name == "English" ? "In Active" : translation[1303]?.value ? translation[1303]?.value : "In Active"
                        }
                        status={data?.is_active ? "active" : "inActive"}
                        size="sm"
                      />
                    </div> */}
                  </div>
                </div>
                <div className="h-full w-[1px] bg-[#3F3F441A] mr-3"></div>
              </div>
              <div className="grid grid-cols-7 col-span-3 gap-5">
                <div className="col-span-2 break-words">
                  <h2 className="text-[#949494] text-sm">{selectedLanguage?.name == "English" ? "Website" : translation[917]?.value ? translation[917]?.value : "Website"}</h2>
                  <p className="text-sm">
                    {data?.website ? data?.website : "-------"}
                  </p>
                </div>
                <div className="col-span-4 break-words">
                  <h2 className="text-[#949494] text-sm">{selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description"}</h2>
                  <p className="text-sm line-clamp-3">
                    {data?.description ? data?.description : "-------"}
                  </p>
                </div>
                {/* <div className='col-span-1'> */}
                {/* <div className='flex-col flex gap-4'>
                                            {/* <div
                                                className='h-[2.5rem] cursor-pointer w-[2.5rem] border border-[#00AC6E] rounded-full flex items-center justify-center'
                                                onClick={() => {
                                                    onEditClick(data)
                                                }}
                                            >
                                                <Svgs.Pen fill='#00AC6E' />
                                            </div> */}
                {/* <div
                                                className='h-[2.5rem] cursor-pointer w-[2.5rem] border border-[#FF3030] rounded-full flex items-center justify-center'
                                                onClick={onDeleteClick}
                                            >
                                                <Svgs.Delete fill='#FF3030' />
                                            </div> */}
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      {/* </div> */}
    </>
  );
};

let timeOutId = undefined;

const Brands = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const brand_state = state.brand;
  const brands_updated = state.brand.brands_updated;

  const [AddBrand, setAddBrand] = useState(false);
  const [edit_brand, setEditBrand] = useState(false);
  const [delete_popup, setdelete_popup] = useState(undefined);
  const [loading_del_btn, setLoadingDelBtn] = useState(false);
  const [search_text, setSearchText] = useState("");
  const [ImportProducts, setImportProducts] = useState(false);
  const [Product, setProduct] = useState(false);
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  const [employee, setEmployee] = useState();
  const [loader, setLoader] = useState(true)
  const [noPaginationLoader, setNoPaginationLoader] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [ShowDetails, setShowDetails] = useState(0);


  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  const handle_edit_brand = (data) => {
    setEditBrand(data);
  };

  const handle_delete_brand = () => {
    setLoadingDelBtn(true);
    dispatch(
      delete_brand(
        { brand_id: delete_popup },
        () => {
          setCurrentPage(1)
          dispatch(get_business_brands_with_no_pagination(search_text, setNoPaginationLoader))
          dispatch(get_business_brands(1, search_text, setLoader))
          setLoadingDelBtn(false);
          setdelete_popup(false);
        },
        () => {
          setLoadingDelBtn(false);
        }
      )
    );
  };

  // useEffect(() => {
  //   if (!brand_state.brands_updated) {
  //     dispatch(get_business_brands());
  //   }
  // }, [brand_state.brands]);
  useEffect(() => {
    if (search_text === null) {
      // Initial load with empty search_text
      dispatch(get_business_brands(currentPage, "", setLoader))
      dispatch(get_business_brands_with_no_pagination("", setNoPaginationLoader))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(get_business_brands(currentPage, search_text, setLoader))
        dispatch(get_business_brands_with_no_pagination(search_text, setNoPaginationLoader))
      }, 500);
    }
  }, [currentPage, search_text])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  let productHeader = [
    { label: selectedLanguage?.name == "English" ? "Product Name" : translation[296]?.value ? translation[296]?.value : "Product Name", key: "name" },
    { label: selectedLanguage?.name == "English" ? "Website" : translation[917]?.value ? translation[917]?.value : "Website", key: "website" },
    { label: selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status", key: "status" },
    { label: selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description", key: "description" },
    { label: selectedLanguage?.name == "English" ? "Image" : translation[928]?.value ? translation[928]?.value : "Image", key: "image" },
  ];

  const productReportsExport = {
    filename: "Product Brands List.csv",
    headers: productHeader,
    data: brand_state?.all_brands?.map((item) => {
      return {
        ...item,
        name: item?.name ? item?.name : "----",
        website: item?.website ? item?.website : "----",
        status: item?.is_active ?
          selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
          : selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive",
        image: item?.image ? item?.image : "----",
        description: item?.description ? item?.description : "----",
      };
    }),
  };
  const fetchBrandlist = async () => {
    const response = await getsProducts();

    if (response.status === 200) {
      let productList = brand_state.brands;
      dispatch(get_business_brands(1, search_text, setLoader));
      // get_business_brands
    }
  };

  // useEffect(() => {
  //   if (brand_state.brands?.length === 0) {
  //     fetchBrandlist();
  //   }
  // }, []);
  const submit = (file) => {
    const businessData = getDataFromLocalStorage("business_data");
    let business = businessData.id;
    business = business.replaceAll('"', "");

    let form_data = new FormData();
    form_data.append("file", file);

    form_data.append("business", businessData.id);
    getsBrandsImport(form_data, () => {
      fetchBrandlist();
    });
  };

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (brand_state?.all_brands?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  const PageSize = 10
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <p className="text-[#7B7B7B] text-sm">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/");
            }}
          >
            {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          </span>{" "}
          &gt;{" "}
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/inventory-operations");
            }}
          >
            {selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations"}
          </span>{" "}
          &gt; <span className="text-primary font-semibold">
            {selectedLanguage?.name == "English" ? "Brands" : translation[836]?.value ? translation[836]?.value : "Brands"}
          </span>
        </p>
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Brands" : translation[836]?.value ? translation[836]?.value : "Brands"}
            </h2>
          </div>
          {loadingPermission ?
            <Shimmer className={'w-32 lg:w-36'}>
              <div className="h-8 w-full  bg-gray-200 rounded-lg" />
            </Shimmer> :
            !employee ? (
              <div className="flex items-center sm:gap-4 gap-2">
                <LoginBtn
                  onClick={() => {
                    setAddBrand(!AddBrand);
                  }}
                >
                  <div className="flex items-center gap-2 text-[#fff]">
                    <Svgs.Plus color="#fff" />
                    <p className="">
                      {selectedLanguage?.name == "English" ? "Add Brand" : translation[918]?.value ? translation[918]?.value : "Add Brand"}
                    </p>
                  </div>
                </LoginBtn>

                {/* <AddProductBtn />
              <AddCategoryBtn fill="true" /> */}
              </div>
            ) : (
              ""
            )}

        </div>

        <div className="border rounded-lg">
          <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row  gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 ">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Brands" : translation[836]?.value ? translation[836]?.value : "Brands"}
                </h2>
                {(!brands_updated || loader) ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">
                      {brand_state?.allCount} {" "}
                      {brand_state?.allCount && brand_state?.allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Brands" : translation[836]?.value ? translation[836]?.value : "Brands"
                        : selectedLanguage?.name == "English" ? "Brand" : translation[292]?.value ? translation[292]?.value : "Brand"
                      }
                    </p>
                  </div>
                }
              </div>
              <div className="mb-00">
                {loadingPermission ?
                  <Shimmer className={'w-44 lg:w-48'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                  </Shimmer>
                  :
                  <LoginInput
                    title=""
                    placeholder={selectedLanguage?.name == "English" ? "Search Brand" : translation[1275]?.value ? translation[1275]?.value : "Search Brand"}
                    padding="py-1"
                    value={search_text}
                    leftIcon={<Svgs.Search />}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                }
              </div>
            </div>
            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-12 !lg:16'} classOn2={'!w-12 !lg:16'} />
              : exportLoader ? (
                <div className="h-[4rem] flex items-center justify-center">
                  <PreloaderSm />
                </div>
              ) :
                !employee ? (
                  brand_state?.all_brands?.length > 0 ?
                    <div className="flex items-center  justify-end gap-3 w-fit h-[2.3rem]">
                      <input
                        type="file"
                        accept=".csv"
                        id="csvFileforproduct"
                        onChange={(e) => {
                          submit(e.target.files[0]);
                          setImportProducts(false);
                        }}
                        hidden
                        value={""}
                      />
                      <IconButton onClick={() => {
                        setImportProducts(true);
                      }}>
                        <Svgs.Import />
                      </IconButton>

                      <CSVLink
                        {...productReportsExport}
                        target="_blank"
                        onClick={handleExportClick}
                      >
                        <IconButton filled>
                          <Svgs.Export />
                        </IconButton>
                      </CSVLink>
                    </div> :
                    <LoginBtn
                      className="rounded-lg border-2 border-primary"
                      bg="bg-white"
                      animation={false}
                      onClick={handleExportClick}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.Export bg="#FFFFFF" />
                      </div>
                    </LoginBtn>
                ) : (
                  ""
                )}

          </div>
          {(!brands_updated || loader) ?
            <TableShimmer_v2 cols={4} rowHeight={'h-14'} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(155px,1fr))] gap-3 px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                <p>{selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name"}</p>
                <p>{selectedLanguage?.name == "English" ? "Website" : translation[917]?.value ? translation[917]?.value : "Website"}</p>
                <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
                <p>{selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description"}</p>
                {!employee ? (
                  <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
                ) : (
                  ""
                )}

              </div>
              {/* .filter((itm) => {
                    if (
                      itm.name.toLowerCase().includes(search_text.toLowerCase()) //||
                      // itm.description.toLowerCase().includes(search_text.toLowerCase()) ||
                      // itm.website.toLowerCase().includes(search_text.toLowerCase())
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }) */}
              {brand_state.brands_updated ? (
                brand_state?.brands?.length > 0 ? (
                  brand_state?.brands?.map((brand, index) => {
                    return (
                      <BrandlistCard
                        data={brand}
                        key={index}
                        onEditClick={handle_edit_brand}
                        onDeleteClick={() => setdelete_popup(brand.id)}
                        hideDeleteIcon={brand_state?.brands?.length == 1 ? true : false}
                        ShowDetails={ShowDetails}
                        setShowDetails={setShowDetails}
                      />
                    );
                  })
                ) : (
                  <NoBrandFound />
                )
              ) : (
                <>
                  <p className="text-center py-3">
                    {selectedLanguage?.name == "English" ? "loading" : translation[160]?.value ? translation[160]?.value : "loading"} ...
                  </p>
                </>
              )}
            </div>
          }

          {(brand_state?.brands?.length > 0 && !loader) &&
            <Pagination
              currentPage={currentPage}
              totalCount={Math.ceil(brand_state?.pages)}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          }
        </div>
      </div>
      {(edit_brand || AddBrand) && (
        <AddBrandPopup
          onclose={() => {
            setAddBrand(false);
            setEditBrand(false);
          }}
          setNoPaginationLoader={setNoPaginationLoader}
          edit={edit_brand ? true : false}
          edit_data={edit_brand}
          setCurrentPage={setCurrentPage}
          search_text={search_text}
        />
      )}


      {delete_popup && delete_popup != undefined && (
        <Popup
          heading={
            selectedLanguage?.name == "English"
              ? "Are you sure to delete this Brand?"
              : translation[926]?.value
                ? translation[926]?.value
                : "Are you sure to delete this Brand?"
          }
          open={delete_popup}
          onclose={() => {
            setdelete_popup(undefined);
          }}
        >
          <div>
            <p>
              {selectedLanguage?.name == "English"
                ? "You won't be able to see this brand again."
                : translation[927]?.value
                  ? translation[927]?.value
                  : "You won't be able to see this brand again."
              }
            </p>
            <div className="flex justify-end">
              <LoginBtn
                deleteBtn
                loading={loading_del_btn}
                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                onClick={() => {
                  handle_delete_brand();
                }}
              />
            </div>
          </div>
        </Popup>
      )}

      <Popup
        heading={selectedLanguage?.name == "English" ? "Import Brands" : translation[924]?.value ? translation[924]?.value : "Import Brands"}
        close={setImportProducts}
        open={ImportProducts}
      >
        <div className="flex flex-col gap-4 border-[#A1A1A1] border-dashed border-2 p-4 rounded-lg text-center">
          <div className="h-[15rem]">
            <Svgs.UploadList />
          </div>
          <p className="text-[#595959]">
            {selectedLanguage?.name == "English" ? "Add your .CSV file to Brands" : translation[1323]?.value ? translation[1323]?.value : "Add your .CSV file to Brands"}
          </p>
          {/* <p className="text-[#595959]">
            {selectedLanguage?.name == "English" ? "Or" : translation[885]?.value ? translation[885]?.value : "Or"}
          </p> */}
          <LoginBtn size="sm" className="mx-auto">
            <label htmlFor="csvFileforproduct" className="cursor-pointer">
              <div className="flex items-center gap-2 text-[#ffffff]">
                {selectedLanguage?.name == "English" ? "Upload New List" : translation[886]?.value ? translation[886]?.value : "Upload New List"}
              </div>
            </label>
          </LoginBtn>
        </div>
      </Popup>
    </>
  );
};

export default Brands;
