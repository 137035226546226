import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPOSCustomer } from 'Adapters/Api/posAnalytics/index'
import moment from "moment";
import { MonthData } from "Constants/Data/Months";

const useCustomerAnalytics = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const reduxlocationsList = state?.locations?.locations;
  const selected_location = state?.locations?.selected_location;

  const [FilterDetails, setFilterDetails] = useState({
    customer_type: false,
    spending_range: false,
    feedback_type: false,
    promotion_usage: false,
    customer_footfall_comparison: false,
  })
  const [toggleDateRange, setToggleDateRange] = useState(false)
  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")
  const [Filter, setFilter] = useState(false);

  const [dateRange, setDateRange] = useState({
    start_date: '',
    end_date: ''
  })

  const [yearsFilter, setYearsFilter] = useState(false)

  // const [selected_location, setSelected_location] = useState(state?.locations.selected_location)
  const currentYear = moment().year();




  const [filterData, setFilterData] = useState({
    type: 'year',
    year: currentYear,
    month: 1,
  })
  const [customerData, setCustomerData] = useState({})
  const [loader, setLoader] = useState(false);
  const { getDataFromLocalStorage } = useSetDataInLocalStorage()


  const getPOSCustomerData = (clear) => {
    setLoader(true)
    let isRangeFilter = dateRange?.start_date && dateRange?.end_date

    const payload = {
      location_id: selected_location,
      year: (isRangeFilter && !clear) ? '' : filterData?.type != 'today' ? filterData?.year : '',
      month: (isRangeFilter && !clear) ? '' : (filterData?.type != 'today' && filterData?.type == 'month') ? filterData?.month : '',
      today: (isRangeFilter && !clear) ? '' : filterData?.type == 'today' ? moment().format('YYYY-MM-DD') : '',
      start_date: clear ? '' : dateRange?.start_date,
      end_date: clear ? '' : dateRange?.end_date
    };

    // const payload = {
    //   location_id: selected_location,
    //   year: filterData?.type != 'today' ? filterData?.year : '',
    //   month: (filterData?.type != 'today' && filterData?.type == 'month') ? filterData?.month : '',
    //   today: filterData?.type == 'today' ? moment().format('YYYY-MM-DD') : ''
    // };

    const success = (result) => {
      setCustomerData(result?.data)
      setLoader(false)
    };

    const fail = (result) => {
      console.log('fail', result);
    };

    getPOSCustomer(payload, success, fail);
  };

  useEffect(() => {
    getPOSCustomerData();
  }, [selected_location, filterData]);

  const onChangeHandler = (e) => {
    const { name, value } = e.target
    setFilterData({
      ...filterData,
      [name]: [value]
    })
  }

  const MonthsDropdown = useMemo(() => {
    if (filterData?.year < currentYear) {
      return MonthData
    } else {
      const currentMonth = new Date().getMonth() + 1;
      return MonthData.filter(month => parseInt(month.value) <= currentMonth);
    }
  }, [filterData?.year])

  const location_currency = useMemo(() => {
    const foundLocation = reduxlocationsList.find(location => location?.id === selected_location);
    return foundLocation?.currency?.code ? foundLocation?.currency?.code : ''
  }, [reduxlocationsList, selected_location]);


  const onDateRangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' || name === 'end_date') {
      if (name === 'start_date') {
        setDateRange((prev) => ({
          ...prev,
          start_date: value,
          end_date: ''
        }));
      }
      if (name === 'end_date') {
        setDateRange((prev) => ({
          ...prev,
          end_date: value
        }));
      }
    }
  }
  var minEndDateLimit = useMemo(() => {
    return moment(dateRange.start_date).add(1, 'day').format('YYYY-MM-DD')
  }, [dateRange.start_date]);

  const resetFilters = () => {
    getPOSCustomerData(1)
    setDateRange({
      start_date: '',
      end_date: ''
    })
    setFilter(false)
  }

  const ApplyFilter = () => {
    if (dateRange?.start_date && dateRange?.end_date) {
      setFilter(false)
      getPOSCustomerData()
    }
  }


  return {
    translation, selectedLanguage, navigate, FilterDetails, setFilterDetails, toggleDateRange, setToggleDateRange, start_date, setStartDate, end_date, setEndDate,
    customerData, loader, onChangeHandler, yearsFilter, selected_location, reduxlocationsList,
    MonthsDropdown, location_currency, filterData, onDateRangeHandler, minEndDateLimit, dateRange, resetFilters, ApplyFilter, Filter, setFilter
  }
}

export default useCustomerAnalytics