import React from "react";
import Svgs from "../../../../../Assets/svgs";
import ByStaff from "./ByStaff";
import CommissionDetailed from "./CommissionDetailed";
import useCommissionReports from "./helper";
import LocationSelection from "new-components/location";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import SelectedInvoiceModal from "../../Sales/sale-history/SelectedInvoiceModal";
import GroupInvoiceModal from "../../Sales/sale-history/GroupInvoiceModal";

const CommissionReports = () => {
    const { locationStaffCommissionReports, locationDetailCommissionReports, ImportPayroll, setImportPayroll, Section, setSection,
        startDate, setStartDate, endDate, setEndDate, selectMember, setMember, selectEmployee, setSelectEmployee, locationDetail,
        employeeList, totals, commissionReportData, loading, startdt, setStartDt, closeDate, setcloseDate, itemType,
        setItemType, commissionReportDetail, isPopup, setIsPopup, selectedItem, setSeletedItem, total_tax, invoiceLabels,
        translation, selectedLanguage, pagesCommissionDetailed, allCountCommissionDetailed, commissionDetailCurrentPage,
        setCommissionDetailCurrentPage, detailedLoading, handleCommissionReportByDetailExportClick, handleCommissionReportByStaffExportClick,
        allCommissionReportByDetail, invoice_titles, selectedInvice, detailLoading, setInvoicePopup, calculate_totals, getInvoiceDetailData,
        InvoicePopup, employeeSearch, setEmployeeSearch, employeePage, setEmployeePage, employeeLoader, setEmployeeLoader, employeePaginationData

    } = useCommissionReports()

    return <>
        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right relative">


            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
                url={'/dashboard/reports/'}
                second={selectedLanguage?.name == "English" ? "Commission Reports" : translation[314]?.value ? translation[314]?.value : "Commission Reports"}
            />
            <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

                <h2 className="text-primary font-bold lg:text-3xl text-xl">
                    {selectedLanguage?.name == "English" ? "Commission Reports" : translation[314]?.value ? translation[314]?.value : "Commission Reports"}
                </h2>
                <LocationSelection />
            </div>
            <div className='border-b mt-[2rem]'>
                <div className='flex items-center gap-6'>
                    <div
                        className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${Section == 'ByStaff' && 'font-semibold border-primary'}`}
                        onClick={() => { setSection('ByStaff'); setStartDt(); setcloseDate() }}
                    >
                        <h2 className='text-[#101928]'>{selectedLanguage?.name == "English" ? "By Staff" : translation[329]?.value ? translation[329]?.value : "By Staff"}</h2>
                    </div>
                    <div
                        className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${Section == 'CommissionDetailed' && 'font-semibold border-primary'}`}
                        onClick={() => { setSection('CommissionDetailed'); setStartDate(); setEndDate() }}
                    >
                        <h2 className='text-[#101928]'>{selectedLanguage?.name == "English" ? "Commission Detailed" : translation[331]?.value ? translation[331]?.value : "Commission Detailed"}</h2>
                    </div>
                </div>
            </div>
            <div className='mt-[2rem]'>
                {
                    Section === 'CommissionDetailed' &&
                    <CommissionDetailed
                        pages={pagesCommissionDetailed}
                        setCurrentPage={setCommissionDetailCurrentPage}
                        allCount={allCountCommissionDetailed}
                        currentPage={commissionDetailCurrentPage}
                        PageSize={10}
                        currency={locationDetail?.currency?.code}
                        commissionDetailList={locationDetailCommissionReports}
                        setSection={setSection}
                        start={startdt}
                        setStartDt={setStartDt}
                        end={closeDate}
                        setcloseDate={setcloseDate}
                        selectEmployee={selectEmployee}
                        setSelectEmployee={setSelectEmployee}
                        locationEmployee={employeeList}
                        loading={detailedLoading}
                        itemType={itemType}
                        setItemType={setItemType}
                        commissionReportDetail={commissionReportDetail}
                        isPopup={isPopup}
                        setIsPopup={setIsPopup}
                        selectedItem={selectedItem}
                        setSeletedItem={setSeletedItem}
                        translation={translation}
                        selectedLanguage={selectedLanguage}
                        handleExportClick={handleCommissionReportByDetailExportClick}
                        allCommissionReportByDetail={allCommissionReportByDetail}
                        employeeSearch={employeeSearch}
                        setEmployeeSearch={setEmployeeSearch}
                        employeePage={employeePage}
                        setEmployeePage={setEmployeePage}
                        employeeLoader={employeeLoader}
                        employeePaginationData={employeePaginationData}
                        getInvoiceDetailData={getInvoiceDetailData}
                    />
                }

                {
                    Section === 'ByStaff' &&
                    <ByStaff
                        totals={totals}
                        currency={locationDetail?.currency?.code}
                        staffCommissionList={locationStaffCommissionReports}
                        setSection={setSection}
                        start={startDate}
                        setStartDate={setStartDate}
                        end={endDate}
                        setEndDate={setEndDate}
                        setMember={setMember}
                        selectMember={selectMember}
                        locationEmployee={employeeList}
                        commissionReportData={commissionReportData}
                        loading={loading}
                        translation={translation}
                        selectedLanguage={selectedLanguage}
                        handleExportClick={handleCommissionReportByStaffExportClick}
                        employeeSearch={employeeSearch}
                        setEmployeeSearch={setEmployeeSearch}
                        employeePage={employeePage}
                        setEmployeePage={setEmployeePage}
                        employeeLoader={employeeLoader}
                        employeePaginationData={employeePaginationData}
                    />
                }
            </div>
        </div>


        {InvoicePopup ?
            selectedInvice?.checkout_type == "Group Appointment" ?
                <GroupInvoiceModal
                    open={InvoicePopup}
                    close={() => {
                        setInvoicePopup(false)
                    }}
                    invoice_titles={invoice_titles}
                    selectedInvice={selectedInvice}
                    selectedLanguage={selectedLanguage}
                    translation={translation}
                    calculate_totals={calculate_totals}
                    loading={detailLoading}
                /> :
                <SelectedInvoiceModal
                    open={InvoicePopup}
                    close={() => {
                        setInvoicePopup(false)
                    }}
                    invoice_titles={invoice_titles}
                    selectedInvice={selectedInvice}
                    selectedLanguage={selectedLanguage}
                    translation={translation}
                    calculate_totals={calculate_totals}
                    loading={detailLoading}
                />
            : ""}
        {/* {

            <InvoicePopup
                currency={locationDetail?.currency?.code}
                total_tax={total_tax}
                isPopup={isPopup}
                invoiceLabels={invoiceLabels}
                setIsPopup={setIsPopup}
                selectedItem={selectedItem}
                invoice_titles={invoice_titles}
            />
        } */}
    </>
};

export default CommissionReports;
