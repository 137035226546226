import { useState, useEffect } from "react";
import {
  getAllCityOfSelectedState,
  getAllCountry,
  getAllStateOfSelectedCountry,
} from "Adapters/Api";
import useSetDataInLocalStorage from "./useSetDataInLocalStorage";

function useAddress(country_id, state_id) {
  let country_name = country_id;
  let state_name = state_id;

  const { storeDataInLocalStorage, getDataFromLocalStorage } =
    useSetDataInLocalStorage();
  const [countriesList, setCountriesList] = useState();
  const [stateList, setStateList] = useState();
  const [cityList, setCityList] = useState();

  // ******************** set dropdown list here like [{id:"13213",label:"labelName", value :" valueName"}] ********************
  const setOptionForDropdown = (DataList) => {
    let optionList =
      DataList &&
      DataList?.map((item) => {
        return { label: item.name, value: item.unique_id };
      });
    return optionList;
  };

  //************************************  getting countries  *******************************
  const getCountryList = async () => {
    const response = await getAllCountry();
    if (response && response.status === 200) {
      const countries = response?.data?.response?.data;
      storeDataInLocalStorage("countriesList", countries);
      const countriesList = setOptionForDropdown(countries);
      setCountriesList(countriesList);
    }
  };

  //**********************************  getting states on the basis of country ******************************
  const getStateOfSelectedCountry = async () => {
    if (country_name) {
      const response = await getAllStateOfSelectedCountry(country_name);
      const states = response?.data?.response?.data;
      storeDataInLocalStorage("states", states);
      const statesList = setOptionForDropdown(states);
      setStateList(statesList);
    }
  };

  // *********************************** getting cities on the basis of State  *******************************
  const getCitiesOfSelectedState = async () => {
    if (state_name) {
      const response = await getAllCityOfSelectedState(state_name);
      const cities = response?.data?.response?.data;
      storeDataInLocalStorage("cities", cities);
      const cityList = setOptionForDropdown(cities);
      setCityList(cityList);
    }
  };

  // ****************************** get country on first rendrings *********************
  useEffect(() => {
    getCountryList();
  }, []);

  // *********************** get states when country change ************************
  useEffect(() => {
    if (country_name) {
      getStateOfSelectedCountry();
    }
  }, [country_name]);

  // *********************** get cities when state change ************************
  useEffect(() => {
    if (state_name) {
      getCitiesOfSelectedState();
    }
  }, [state_name]);

  return {
    countriesList,
    stateList,
    cityList,
    setCountriesList,
    setStateList,
    setCityList,
  };
}

export default useAddress;
