import axios from "axios";
import { BASE_URL, get_sale_order_product, get_sale_order_service, get_sale_order_voucher, get_sale_order_membership, get_all_sale_order, get_single_invoice, get_all_sale_order_updated_url, get_single_invoice_updated_url } from "../api_variables";
import { toast } from 'react-toastify';
import { SALE_ORDER } from './../../../Redux/TenantRedux/ActionTypes/salleOrderTypes/index';



export const getSaleOrderProduct = () => dispatch => {

    let s_code;
    fetch(`${BASE_URL}${get_sale_order_product}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let productList = result?.response?.orders
                dispatch({
                    type: SALE_ORDER.GET_SALE_ORDER_PRODUCTS,
                    payload: productList
                })
            }
            else {
                toast.error("Error in get product", { toastId: "toast" })
            }
        })
};


export const getSaleOrderServices = () => dispatch => {

    let s_code;
    fetch(`${BASE_URL}${get_sale_order_service}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let serviceList = result?.response?.orders;
                dispatch({
                    type: SALE_ORDER.GET_SALE_ORDER_SERVICES,
                    payload: serviceList
                })
            }
            else {
                toast.error("Error in get services", { toastId: "toast" })
            }
        })
};



export const getSaleOrderVouchers = () => dispatch => {

    let s_code;
    fetch(`${BASE_URL}${get_sale_order_voucher}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let voucherList = result?.response?.orders;
                dispatch({
                    type: SALE_ORDER.GET_SALE_ORDER_VOUCHERS,
                    payload: voucherList
                })
            }
            else {
                toast.error("Error in get vouchers", { toastId: "toast" })
            }
        })
};



export const getSaleOrderMembership = () => dispatch => {

    let s_code;
    fetch(`${BASE_URL}${get_sale_order_membership}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let membershipList = result?.response?.orders;
                dispatch({
                    type: SALE_ORDER.GET_SALE_ORDER_MEMBERSHIPS,
                    payload: membershipList
                })
            }
            else {
                toast.error("Error in get memberships", { toastId: "toast" })
            }
        })
};


export const getrecentSaleOrders = (location, setLoading) => dispatch => {
    let s_code;
    const product_location = location ? `?location=${location}` : "";

    const apiUrl = `${get_all_sale_order}${product_location}&recent_five_sales=true`

    setLoading && setLoading(true)
    fetch(`${BASE_URL}${apiUrl}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let allSaleOrderList = result?.response?.sales;
                setLoading && setLoading(false)
                dispatch({
                    type: SALE_ORDER.GET_RECENT_SALES,
                    payload: {
                        data: allSaleOrderList,
                    },
                })
            }
            else {
                setLoading && setLoading(false)
                toast.error("Error in get sale order", { toastId: "toast" })
            }
        })
};

export const getSingleInvoiceDetail = (access_token, invoiceId, location, setLoading, success, fail) => dispatch => {
    let s_code;
    const product_location = location ? `?location=${location}` : "";
    const invoice = invoiceId ? `&checkout_id=${invoiceId}` : ''
    const apiUrl = `${get_single_invoice_updated_url}${product_location}${invoice}`

    setLoading && setLoading(true)
    fetch(`${BASE_URL}${apiUrl}`,
        {
            headers: {
                'Authorization': `Token ${access_token}`,
                'Content-Type': 'application/json',
                // Add any other headers as needed
            }
        })
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let singleInvoice = result?.response?.sales;
                let titles = result?.response?.invoice_translation
                dispatch({
                    type: SALE_ORDER.GET_INVOICE_TITLES,
                    payload: {
                        data: titles
                    }
                })
                success && success(singleInvoice)
                setLoading && setLoading(false)
            }
            else {
                setLoading && setLoading(false)
                toast.error("Error in get all sale order", { toastId: "toast" })
                fail && fail()
            }
        })
};

export const getAllSaleOders = (access_token, payload) => dispatch => {
    let s_code;
    const encodedSearchText = encodeURIComponent(payload?.search_text);
    const product_location = payload?.location ? `?location=${payload?.location}` : "";
    const current_Page = payload?.currentPage ? `&page=${payload?.currentPage}` : '';
    const client = payload?.clientt ? `&client=${payload?.clientt}` : '';
    const search = payload?.search_text ? `&search_text=${encodedSearchText?.replace(/%23/g, '')}` : ''
    const services = payload?.servicess ? `&service=${payload?.servicess}` : ''
    const rangeStart = payload?.startDate ? `&range_start=${payload?.startDate}` : ''
    const rangeEnd = payload?.endDate ? `&range_end=${payload?.endDate}` : ''
    const isRecentTrue = payload?.is_quick_sale ? `&is_quick_sale=${payload?.is_quick_sale}` : ''

    const apiUrl = `${get_all_sale_order_updated_url}${product_location}${current_Page}${rangeStart}${rangeEnd}${client}${search}${services}${isRecentTrue}`
    // const apiUrl = `${get_all_sale_order}${product_location}${current_Page}${rangeStart}${rangeEnd}${client}${search}${services}`

    payload?.setLoading && payload?.setLoading(true)
    fetch(`${BASE_URL}${apiUrl}`,
        {
            headers: {
                'Authorization': `Token ${access_token}`,
                'Content-Type': 'application/json',
                // Add any other headers as needed
            }
        })
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let allSaleOrderList = result?.response?.sales;
                let pages = result?.total_pages
                payload?.setLoading && payload?.setLoading(false)
                if (payload?.is_quick_sale) {
                    dispatch({
                        type: SALE_ORDER.GET_RECENT_SALES,
                        payload: {
                            data: allSaleOrderList,
                        },
                    })
                } else {
                    dispatch({
                        type: SALE_ORDER.GET_ALL_SALE_ORDER,
                        payload: {
                            data: allSaleOrderList,
                            pages: pages
                        },
                    })
                }
            }
            else {
                payload?.setLoading && payload?.setLoading(false)
                toast.error("Error in get all sale order", { toastId: "toast" })
            }
        })
};



// export const getAllSaleOrdersNoPagination = (location, startDate, endDate, clientt, search_text, servicess, setNoPaginationLoader) => dispatch => {
export const getAllSaleOrdersNoPagination = (access_token, payload, setNoPaginationLoader) => dispatch => {
    setNoPaginationLoader && setNoPaginationLoader(true)
    let s_code;
    const encodedSearchText = encodeURIComponent(payload?.search_text);
    const product_location = payload?.location ? `?location=${payload?.location}` : "";
    const client = payload?.clientt ? `&client=${payload?.clientt}` : '';
    const search = payload?.search_text ? `&search_text=${encodedSearchText?.replace(/%23/g, '')}` : ''
    const services = payload?.servicess ? `&service=${payload?.servicess}` : ''
    const rangeStart = payload?.startDate ? `&range_start=${payload?.startDate}` : ''
    const rangeEnd = payload?.endDate ? `&range_end=${payload?.endDate}` : ''

    const apiUrl = `${get_all_sale_order_updated_url}${product_location}&no_pagination=true${rangeStart}${rangeEnd}${client}${search}${services}`
    // const apiUrl = `${get_all_sale_order}${product_location}&no_pagination=true${rangeStart}${rangeEnd}${client}${search}${services}`

    fetch(`${BASE_URL}${apiUrl}`, {
        headers: {
            'Authorization': `Token ${access_token}`,
            'Content-Type': 'application/json',
            // Add any other headers as needed
        }
    }).then(response => {
        s_code = response.status
        if (response.ok) {
            return response.json()
        }
    }).then(result => {
        if (s_code == 200) {
            let allSaleOrderList = result?.response?.sales;
            dispatch({
                type: SALE_ORDER.GET_ALL_SALE_ORDER_NO_PAGINATION,
                payload: {
                    data: allSaleOrderList,
                },
            })
            setNoPaginationLoader && setNoPaginationLoader(false)
        }
        else {
            setNoPaginationLoader && setNoPaginationLoader(false)
            // toast.error("Error in get all sale order",{ toastId: "toast" })
        }
    })
};

