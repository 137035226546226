import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LoginCardBlock from "./LoginCardBlock";

import Svgs from "Assets/svgs";

const ChooseSection = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/auth/signup`);
    // navigate(`/auth/signup?account_type=business`);
  }, []);

  return (
    <div className="flex flex-col gap-5 justify-center">
      <h1 className="text-3xl font-light text-[#7B7B7B] lg:text-left text-center ">
        Choose Your
        <span className="font-semibold text-black">Account Type</span>
      </h1>
      <p className="text-[#7B7B7B] lg:text-left text-center text-sm">
        Choose how you want to signup with NStyle
      </p>
      <LoginCardBlock
        onClick={() => {
          // navigate(`/auth/signup/?account_type=business&signup_type=email&actives=personal_information`);
          navigate(`/auth/signup`);

          // navigate(`/auth/signup?account_type=business`);
        }}
        heading="For Business"
        desc="Manage and grow your business."
      >
        <Svgs.Business_info />
      </LoginCardBlock>

      {/* <LoginCardBlock
        onClick={() => {
          navigate(`/auth/signup/?account_type=everyone&signup_type=email&actives=personal_information`);
          // navigate(`/auth/signup?account_type=business`);
        }}
        heading="For Everyone"
        desc="Manage and grow your business."
      >
        <Svgs.Business_info />
      </LoginCardBlock> */}
    </div>
  );
};

export default ChooseSection;
