import Svgs from 'Assets/svgs'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import SelectedOption from 'Components/TenantApp/Elements/SelectedOption'
import React, { useEffect } from 'react'
import { useState } from 'react'

const ToggleRestrictions = (props) => {
    const [check, setCheck] = useState(true)
    const { discount, addRestrictions, handleChange, title, setDiscount, toggleRestrictions, restrictions, restriction, type, dropwdown, translation, selectedLanguage } = props

    const removeDuplicate = [];
    dropwdown.forEach((item) => {
        if (!removeDuplicate.find((element) => element.value === item.value)) {
            removeDuplicate.push(item);
        }
    });


    // useEffect(() => {
    //     if (check) {
    //         if (restrictions?.length > 0) {
    //             let newArrayData = []
    //             const filteredArray = restrictions?.filter(obj =>
    //                 removeDuplicate?.find(location => location?.value === obj)
    //             );
    //             // Store the filtered data in the state
    //             newArrayData = filteredArray;
    //             if (type === "serviceRestriction")
    //                 setDiscount((prev) => ({
    //                     ...prev,
    //                     serviceRestrictions: newArrayData,
    //                 }))
    //             if (type === "brandRestriction")
    //                 setDiscount((prev) => ({
    //                     ...prev,
    //                     brandRestrictions: newArrayData,
    //                 }))
    //         }
    //     }
    // }, [discount?.groupDiscount, removeDuplicate, restrictions, type, check])

    return (
        <>
            {
                toggleRestrictions &&
                <>
                    <div className='flex items-end gap-3'>
                        <Dropwdown
                            name={`${type}`}
                            placeholder={`${selectedLanguage?.name == "English" ? "Select" : translation[913]?.value ? translation[913]?.value : "Select"} ${title} `}
                            title={`${title}`}
                            parentClass='flex-1 normal-case'
                            // options={dropwdown}
                            options={
                                restrictions?.length > 0
                                    ? removeDuplicate?.filter((obj, index) => {
                                        return !restrictions?.find(itm => itm == obj.value) && obj
                                    })
                                    :
                                    removeDuplicate
                            }
                            value={restriction}
                            onChange={handleChange}
                        />
                        <LoginBtn
                            text={<div className='flex items-center gap-2' > <Svgs.Plus /><p>{selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}</p></div>}
                            onClick={addRestrictions}
                            disabled={!restriction}
                            className={restriction && "shake-bottom"}
                        />



                    </div>
                    <div className='flex items-center gap-2 flex-wrap'>
                        {
                            restrictions?.map((obj, index) => (
                                <SelectedOption
                                    rounded={'full'}
                                    name={removeDuplicate?.find(location => location?.value === obj)?.label}
                                    onClick={() => {
                                        // setCheck(false)
                                        var filter = restrictions?.filter(obj => obj !== restrictions[index])
                                        if (type === "retailRestriction")
                                            setDiscount((prev) => ({
                                                ...prev,
                                                retailRestrictions: filter,
                                            }))
                                        if (type === "voucherRestriction")
                                            setDiscount((prev) => ({
                                                ...prev,
                                                voucherRestrictions: filter,
                                            }))
                                        if (type === "serviceRestriction")
                                            setDiscount((prev) => ({
                                                ...prev,
                                                serviceRestrictions: filter,
                                            }))
                                        if (type === "brandRestriction")
                                            setDiscount((prev) => ({
                                                ...prev,
                                                brandRestrictions: filter,
                                            }))
                                    }}
                                />
                            ))
                        }
                    </div>
                </>
            }
        </>
    )
}

export default ToggleRestrictions