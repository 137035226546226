import React, { useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { publicRoutes } from "./helper";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getBusinessLocationByDomain, getBusinessTaxOutOfTenant, getClientDetail, getCommonTenantDetail, getVisitedLocationBusinessTax } from "Adapters/Api/businessLocationInNstyleApp";
import { getBusinessTax } from "Adapters/Api/financialSettings";
import { CLIENT_APPOINTMENT } from "Redux/NstyleRedux/ActionTypes/ClientAppointmentTypes";
import { apiErrorMessage } from "Constants/Data/Errors";
import { useState } from "react";

const RouteList = () => {
  const dispatch = useDispatch();
  const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
  const [searchParams, setSearchParams] = useSearchParams();
  const state = useSelector((state) => state)
  let params = useParams();

  const locationTax = state?.financialSettings?.businessTaxs;

  let business = searchParams.get("business");
  const domain = searchParams.get("domain");
  let hash = searchParams.get("hash");
  let client_id = getDataFromLocalStorage("client_id");



  useEffect(() => {
    business && storeDataInLocalStorage("business", business);
    hash && storeDataInLocalStorage("hash", hash);
    domain && storeDataInLocalStorage("hash_name", domain);

    if (hash) {
      dispatch(getBusinessLocationByDomain(hash));
    } else {
      hash = getDataFromLocalStorage("hash");
      hash && dispatch(getBusinessLocationByDomain(hash));
    }

    if (params?.id) {
      dispatch(getVisitedLocationBusinessTax(params?.id, hash))
    } else {
      const business_location = getDataFromLocalStorage("business_location");
      dispatch(getVisitedLocationBusinessTax(business_location, hash))
    }
  }, [hash]);


  useEffect(() => {
    if (!params?.id) {
      params["id"] = getDataFromLocalStorage("business_location");
    }

    if (locationTax?.length > 0) {
      const res = locationTax?.find((itm) => itm?.location?.id === params?.id);
      dispatch({
        type: CLIENT_APPOINTMENT.BUSINESS_TAX,
        payload: res
      })

    }
  }, [locationTax])

  // get client details here
  useEffect(() => {
    let client_email = getDataFromLocalStorage("client_email");
    if ((client_id && hash) || (client_email) || (state?.userData?.clientInfo?.email)) {
      dispatch(
        getClientDetail(
          hash,
          client_id,
          client_email || state?.userData?.clientInfo?.email,
          () => {
            toast.error(apiErrorMessage, { toastId: "toast" })
          }
        )
      )
    }
  }, [client_id, hash, state?.userData?.loggedIn, state?.userData?.clientInfo?.email]);

  // get common tenant data 
  useEffect(() => {
    dispatch(getCommonTenantDetail())
  }, [])


  useEffect(() => {
    let client_token = getDataFromLocalStorage("client_token");
    if (client_token) {
      dispatch({
        type: 'BUSINESS_CLIENT_LOGGEDIN'
      })
    }

  }, [])

  return (
    <Routes>
      {publicRoutes.map(({ path, element }, index) => (
        <Route path={path} element={element} key={index} />
      ))}
      <Route path="*" element={<Navigate to={"/auth/account-type"} />} />
      {/* <Route path="*" element={<Navigate to={"/auth/login"} />} /> */}
    </Routes>
  );
};

export default RouteList;
