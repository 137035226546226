import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import React from 'react'
import useDealTypeFourSetting from './helper'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import Svgs from 'Assets/svgs'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'
import PreloaderFull from 'Components/TenantApp/Elements/PreloaderFull'
import NotFound from 'Components/Utility/NotFound'
import AddPopup from '../../../Elements/AddPopup'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'

const DealTypeFourSetting = (props) => {
    const { requiredItemsSettings, setSettingData, settingData, allProducts, allServices,
        allProductsBrand, allServicesCategory, allServicesGroup, submitSettingClick, errors,
        allMergedOptions, applicableItemsSettings, setErrors, anyOrAllOption, AllTypeOfDiscounts,
        validityScheduleOptions, firstStepData } = props

    const {
        handleChangeForDealType3Dropdown, handleChangeForDealType3, filteredRPBForDealType3,
        filteredRSForDealType3, filteredRSGForDealType3, filteredRSCForDealType3,
        filteredRPForDealType3, removeSelectedItem, filteredASForDealType3,
        filteredASGForDealType3, filteredASCForDealType3, filteredAPBForDealType3,
        filteredAPForDealType3, screenLoader, popup, setPopup, serviceType,
        setServiceType, handleChangeForDealType3AddData, searchApplicable, setSearchApplicable, selectedApplicableItemsArray,
        setSelectedApplicableItemsArray, selectedRequiredItemsArray, setSelectedRequiredItemsArray, searchRequired, setSearchRequired
    } = useDealTypeFourSetting({
        setSettingData, settingData, allProducts, allServices, allProductsBrand, allServicesCategory, allServicesGroup, setErrors, allMergedOptions
    })

    return screenLoader ? <PreloaderFull /> : (
        <>
            <div className="space-y-3">
                <h3 className=" text-lg font-semibold">
                    Required Items
                </h3>
                <div className="grid md:grid-cols-3 gap-4">
                    <LoginInput
                        required={true}
                        title={'Enter Quantity'}
                        placeholder={'Enter Quantity'}
                        name="qty"
                        value={requiredItemsSettings?.qty}
                        type={'number'}
                        onlyNumericAllowed={true}
                        onChange={(e) => { handleChangeForDealType3(e, 'requiredItemsSettings', 'qty') }}
                        error={(submitSettingClick && !requiredItemsSettings?.qty) || errors?.qty}
                    />

                    <Dropwdown
                        required={true}
                        name="timePeriodType"
                        value={requiredItemsSettings?.timePeriodType}
                        title={"Discount Validity Type"}
                        placeholder={"Select Discount Validity Type"}
                        options={validityScheduleOptions}
                        onChange={(e) => { handleChangeForDealType3Dropdown(e, 'requiredItemsSettings', 'timePeriodType') }}
                    />
                    <LoginInput
                        required={true}
                        title={'Enter Time Period'}
                        placeholder={'Enter Time Period'}
                        name="timePeriod"
                        value={requiredItemsSettings?.timePeriod}
                        type={'number'}
                        onlyNumericAllowed={true}
                        onChange={(e) => { handleChangeForDealType3(e, 'requiredItemsSettings', 'timePeriod') }}
                        error={(submitSettingClick && !requiredItemsSettings?.timePeriod) || errors?.timePeriod}
                    />
                </div>
                <div className="flex flex-wrap sm:flex-nowrap gap-3">
                    <LoginBtn
                        text={'Select Service'}
                        // className={'!w-full justify-center'}
                        onClick={() => {
                            setPopup(true)
                            setServiceType('requiredService')
                        }}
                    />
                    <LoginBtn
                        text={'Select Service Group'}
                        // className={'!w-full justify-center'}
                        onClick={() => {
                            setPopup(true)
                            setServiceType('requiredServiceGroup')
                        }}
                    />
                    <LoginBtn
                        text={'Select Service Category'}
                        // className={'!w-full justify-center'}
                        onClick={() => {
                            setPopup(true)
                            setServiceType('requiredServiceCategory')
                        }}
                    />
                    <LoginBtn
                        text={'Select Product'}
                        // className={'!w-full justify-center'}
                        onClick={() => {
                            setPopup(true)
                            setServiceType('requiredProduct')
                        }}
                    />
                    <LoginBtn
                        text={'Select Product Brand'}
                        // className={'!w-full justify-center'}
                        onClick={() => {
                            setPopup(true)
                            setServiceType('requiredProductBrand')
                        }}
                    />
                </div>
                {(serviceType == 'requiredService' && popup) &&
                    <AddPopup
                        completeDataLength={allServices?.length}
                        firstStepData={firstStepData}
                        open={popup}
                        close={() => {
                            setPopup(false)
                            setServiceType()
                        }}
                        heading={"Select Services"}
                        placeholder={"Search Service"}
                        apiType={"single-service"}
                        data={settingData?.requiredItemsSettings?.itemIds?.map(itm => itm)}
                        onSubmitHandler={(e, data) => { handleChangeForDealType3AddData(e, data, 'requiredItemsSettings', 'itemIds') }}
                    />
                }
                {(serviceType == 'requiredServiceGroup' && popup) &&
                    <AddPopup
                        firstStepData={firstStepData}
                        open={popup}
                        close={() => {
                            setPopup(false)
                            setServiceType()
                        }}
                        heading={"Select Service Groups"}
                        placeholder={"Search Service Groups"}
                        apiType={"service-group"}
                        completeDataLength={allServicesGroup?.length}
                        data={settingData?.requiredItemsSettings?.itemIds?.map(itm => itm)}
                        onSubmitHandler={(e, data) => { handleChangeForDealType3AddData(e, data, 'requiredItemsSettings', 'itemIds') }}
                    />
                }
                {(serviceType == 'requiredServiceCategory' && popup) &&
                    <AddPopup
                        firstStepData={firstStepData}
                        open={popup}
                        close={() => {
                            setPopup(false)
                            setServiceType()
                        }}
                        heading={"Select Service Categories"}
                        placeholder={"Search Service Categories"}
                        apiType={"service-category"}
                        completeDataLength={allServicesCategory?.length}
                        data={settingData?.requiredItemsSettings?.itemIds?.map(itm => itm)}
                        onSubmitHandler={(e, data) => { handleChangeForDealType3AddData(e, data, 'requiredItemsSettings', 'itemIds') }}
                    />
                }
                {(serviceType == 'requiredProduct' && popup) &&
                    <AddPopup
                        firstStepData={firstStepData}
                        open={popup}
                        close={() => {
                            setPopup(false)
                            setServiceType()
                        }}
                        heading={"Select Products"}
                        placeholder={"Search Product"}
                        apiType={"single-product"}
                        completeDataLength={allProducts?.length}
                        data={settingData?.requiredItemsSettings?.itemIds?.map(itm => itm)}
                        onSubmitHandler={(e, data) => { handleChangeForDealType3AddData(e, data, 'requiredItemsSettings', 'itemIds') }}
                    />
                }
                {(serviceType == 'requiredProductBrand' && popup) &&
                    <AddPopup
                        firstStepData={firstStepData}
                        open={popup}
                        close={() => {
                            setPopup(false)
                            setServiceType()
                        }}
                        heading={"Select Products Brands"}
                        placeholder={"Search Product Brand"}
                        apiType={"product-brand"}
                        completeDataLength={allProductsBrand?.length}
                        data={settingData?.requiredItemsSettings?.itemIds?.map(itm => itm)}
                        onSubmitHandler={(e, data) => { handleChangeForDealType3AddData(e, data, 'requiredItemsSettings', 'itemIds') }}
                    />
                }
                {settingData?.requiredItemsSettings?.itemIds?.length > 0 &&
                    <div className="">
                        <div className="border rounded-lg">
                            <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
                                <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                                    <div className="flex items-center lg:gap-4 gap-3">
                                        <div className="flex items-center sm:gap-4 gap-2 ">
                                            <h2 className="font-semibold text-xl">Selected Items</h2>
                                            <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                                <p className="text-primary">{settingData?.requiredItemsSettings?.itemIds?.length} {settingData?.requiredItemsSettings?.itemIds?.length > 1 ? 'Records' : 'Record'} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-00">
                                        <LoginInput
                                            title=""
                                            value={searchRequired}
                                            onChange={(e) => setSearchRequired(e.target.value)}
                                            name={"search_text"}
                                            placeholder={"Search"}
                                            padding="py-1"
                                            leftIcon={<Svgs.Search />}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="overflow-x-auto">
                                <div className="inline-grid xl:grid grid-cols-[repeat(2,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                                    <p>Name</p>
                                    <p>Action</p>
                                </div>
                                <div className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}>
                                    {selectedRequiredItemsArray?.filter((itm) =>
                                        searchRequired ? itm?.name?.toLowerCase()?.includes(searchRequired?.toLowerCase()) : true
                                    )?.length > 0 ? selectedRequiredItemsArray?.filter((itm) =>
                                        searchRequired ? itm?.name?.toLowerCase()?.includes(searchRequired?.toLowerCase()) : true
                                    )?.map((item, i) => {
                                        const filtered = allMergedOptions?.find((itm) => itm?.id == item?.id);
                                        return (
                                            <div key={i} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(2,minmax(115px,1fr))] min-w-full w-fit">

                                                <div className="flex items-center gap-4">
                                                    <div>
                                                        #{i + 1}
                                                    </div>
                                                    {filtered?.name}
                                                </div>
                                                <div className="flex items-center gap-3">
                                                    <div
                                                        onClick={() =>
                                                            removeSelectedItem(filtered?.id, "requiredItemsSettings")
                                                        }
                                                        className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center">
                                                        <Svgs.Delete fill="#7D7D7D" />
                                                    </div>
                                                </div>
                                            </div>

                                        );
                                    })
                                        : <NotFound />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(submitSettingClick && (!settingData?.requiredItemsSettings?.itemIds || settingData?.requiredItemsSettings?.itemIds?.length == 0)) &&
                    <span className='text-red-600 float-right text-sm'>Add at least one from above options</span>
                }
            </div>
            <div className="space-y-3">
                <h3 className=" text-lg font-semibold">
                    Applicable Items
                </h3>
                <div className="grid md:grid-cols-3 gap-2">
                    <Dropwdown
                        required={true}
                        name="isFree"
                        value={applicableItemsSettings?.isFree}
                        title={"Discount Type"}
                        placeholder={"Select Discount Type"}
                        options={AllTypeOfDiscounts}
                        onChange={(e) => { handleChangeForDealType3Dropdown(e, 'applicableItemsSettings', 'isFree') }}
                    />
                    <LoginInput
                        type={'number'}
                        required={true}
                        title={'Quantity'}
                        placeholder={'Enter Quantity'}
                        name="qty"
                        value={applicableItemsSettings?.qty}
                        onlyNumericAllowed={true}
                        onChange={(e) => { handleChangeForDealType3(e, 'applicableItemsSettings', 'qty') }}
                        error={submitSettingClick && !applicableItemsSettings?.qty}
                    />

                    {(applicableItemsSettings?.isFree == false) &&
                        <FloatInput
                            required={true}
                            title={'Discount Percentage'}
                            placeholder={'Enter Discount Percentage'}
                            name="discount"
                            value={applicableItemsSettings?.discount}
                            onChange={(e) => { handleChangeForDealType3(e, 'applicableItemsSettings', 'discount') }}
                            error={submitSettingClick && !applicableItemsSettings?.discount}
                        />
                    }
                </div>
                <div className="flex flex-wrap sm:flex-nowrap gap-3">
                    <LoginBtn
                        text={'Select Service'}
                        // className={'!w-full justify-center'}
                        onClick={() => {
                            setPopup(true)
                            setServiceType('applicableService')
                        }}
                    />
                    <LoginBtn
                        text={'Select Service Group'}
                        // className={'!w-full justify-center'}
                        onClick={() => {
                            setPopup(true)
                            setServiceType('applicableServiceGroup')
                        }}
                    />
                    <LoginBtn
                        text={'Select Service Category'}
                        // className={'!w-full justify-center'}
                        onClick={() => {
                            setPopup(true)
                            setServiceType('applicableServiceCategory')
                        }}
                    />
                    <LoginBtn
                        text={'Select Product'}
                        // className={'!w-full justify-center'}
                        onClick={() => {
                            setPopup(true)
                            setServiceType('applicableProduct')
                        }}
                    />
                    <LoginBtn
                        text={'Select Product Brand'}
                        // className={'!w-full justify-center'}
                        onClick={() => {
                            setPopup(true)
                            setServiceType('applicableProductBrand')
                        }}
                    />
                </div>
                {(serviceType == 'applicableService' && popup) &&
                    <AddPopup
                        completeDataLength={allServices?.length}
                        firstStepData={firstStepData}
                        open={popup}
                        close={() => {
                            setPopup(false)
                            setServiceType()
                        }}
                        heading={"Select Services"}
                        placeholder={"Search Service"}
                        apiType={"single-service"}
                        data={settingData?.applicableItemsSettings?.itemIds?.map(itm => itm)}
                        onSubmitHandler={(e, data) => { handleChangeForDealType3AddData(e, data, 'applicableItemsSettings', 'itemIds') }}
                    />
                }
                {(serviceType == 'applicableServiceGroup' && popup) &&
                    <AddPopup
                        firstStepData={firstStepData}
                        open={popup}
                        close={() => {
                            setPopup(false)
                            setServiceType()
                        }}
                        heading={"Select Service Groups"}
                        placeholder={"Search Service Groups"}
                        apiType={"service-group"}
                        completeDataLength={allServicesGroup?.length}
                        data={settingData?.applicableItemsSettings?.itemIds?.map(itm => itm)}
                        onSubmitHandler={(e, data) => { handleChangeForDealType3AddData(e, data, 'applicableItemsSettings', 'itemIds') }}
                    />
                }
                {(serviceType == 'applicableServiceCategory' && popup) &&
                    <AddPopup
                        firstStepData={firstStepData}
                        open={popup}
                        close={() => {
                            setPopup(false)
                            setServiceType()
                        }}
                        heading={"Select Service Categories"}
                        placeholder={"Search Service Categories"}
                        apiType={"service-category"}
                        completeDataLength={allServicesCategory?.length}
                        data={settingData?.applicableItemsSettings?.itemIds?.map(itm => itm)}
                        onSubmitHandler={(e, data) => { handleChangeForDealType3AddData(e, data, 'applicableItemsSettings', 'itemIds') }}
                    />
                }
                {(serviceType == 'applicableProduct' && popup) &&
                    <AddPopup
                        firstStepData={firstStepData}
                        open={popup}
                        close={() => {
                            setPopup(false)
                            setServiceType()
                        }}
                        heading={"Select Products"}
                        placeholder={"Search Product"}
                        apiType={"single-product"}
                        completeDataLength={allProducts?.length}
                        data={settingData?.applicableItemsSettings?.itemIds?.map(itm => itm)}
                        onSubmitHandler={(e, data) => { handleChangeForDealType3AddData(e, data, 'applicableItemsSettings', 'itemIds') }}
                    />
                }
                {(serviceType == 'applicableProductBrand' && popup) &&
                    <AddPopup
                        firstStepData={firstStepData}
                        open={popup}
                        close={() => {
                            setPopup(false)
                            setServiceType()
                        }}
                        heading={"Select Products Brands"}
                        placeholder={"Search Product Brand"}
                        apiType={"product-brand"}
                        completeDataLength={allProductsBrand?.length}
                        data={settingData?.applicableItemsSettings?.itemIds?.map(itm => itm)}
                        onSubmitHandler={(e, data) => { handleChangeForDealType3AddData(e, data, 'applicableItemsSettings', 'itemIds') }}
                    />
                }
                {settingData?.applicableItemsSettings?.itemIds?.length > 0 &&
                    <div className="">
                        <div className="border rounded-lg">
                            <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
                                <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                                    <div className="flex items-center lg:gap-4 gap-3">
                                        <div className="flex items-center sm:gap-4 gap-2 ">
                                            <h2 className="font-semibold text-xl">Selected Items</h2>
                                            <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                                <p className="text-primary">{settingData?.applicableItemsSettings?.itemIds?.length} {settingData?.applicableItemsSettings?.itemIds?.length > 1 ? 'Records' : 'Record'} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-00">
                                        <LoginInput
                                            title=""
                                            value={searchApplicable}
                                            onChange={(e) => setSearchApplicable(e.target.value)}
                                            name={"search_text"}
                                            placeholder={"Search"}
                                            padding="py-1"
                                            leftIcon={<Svgs.Search />}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="overflow-x-auto">
                                <div className="inline-grid xl:grid grid-cols-[repeat(2,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                                    <p>Name</p>
                                    <p>Action</p>
                                </div>
                                <div className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}>
                                    {selectedApplicableItemsArray?.filter((itm) =>
                                        searchApplicable ? itm?.name?.toLowerCase()?.includes(searchApplicable?.toLowerCase()) : true
                                    )?.length > 0 ? selectedApplicableItemsArray?.filter((itm) =>
                                        searchApplicable ? itm?.name?.toLowerCase()?.includes(searchApplicable?.toLowerCase()) : true
                                    )?.map((item, i) => {
                                        const filtered = allMergedOptions?.find((itm) => itm?.id == item?.id);
                                        return (
                                            <div key={i} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(2,minmax(115px,1fr))] min-w-full w-fit">

                                                <div className="flex items-center gap-4">
                                                    <div>
                                                        #{i + 1}
                                                    </div>
                                                    {filtered?.name}
                                                </div>
                                                <div className="flex items-center gap-3">
                                                    <div
                                                        onClick={() =>
                                                            removeSelectedItem(filtered?.id, "applicableItemsSettings")
                                                        }
                                                        className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center">
                                                        <Svgs.Delete fill="#7D7D7D" />
                                                    </div>
                                                </div>
                                            </div>

                                        );
                                    })
                                        : <NotFound />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {(submitSettingClick && (!settingData?.applicableItemsSettings?.itemIds || settingData?.applicableItemsSettings?.itemIds?.length == 0)) &&
                    <span className='text-red-600 float-right text-sm'>Add at least one from above options</span>
                }
            </div>
        </>
    )
}

export default DealTypeFourSetting