import { useEffect, useState } from "react";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import LocationSelection from "new-components/location";
import { useDispatch, useSelector } from "react-redux";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import Popup from "Components/TenantApp/Elements/Popup";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import Textarea from "Components/TenantApp/Elements/Textarea";
import useValidations from "Hooks/useValidations";
import useObjectKeys from "Hooks/useObjectKeys";
import { getEmployeeDropDownList, getEmployeeDropDownListPagination } from "Adapters/Api/employee";
import { createEmployeeComment, getEmployeeComment } from "Adapters/Api/apointments";
import moment from "moment";
import Pagination from "new-components/Pagination";
import NoPayrollFound from "../Elements/NoPayrollFound";
import NoCommentFound from "../Elements/NoCommentFound";
let employeeTimeOutId = undefined

const EmployeeComments = () => {
  const { setErrors, validation, errors, onBlurHandler } = useValidations();
  const { objectKeyConvertToArray } = useObjectKeys()
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const employeeRedux = state?.employee?.employeesDropdown
  const selected_location = state?.locations?.selected_location;
  const employeePaginationData = state?.employee?.employeeDropdownPaginationData
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [employeeCommentPopup, setEmployeeCommentPopup] = useState(false);
  const [employeePage, setEmployeePage] = useState(1)
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [employeeLoader, setEmployeeLoader] = useState(true)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [EmployeeCommentsData, setEmployeeCommentsData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    if (selected_location) {
      if (employeeSearch === null) {
        // Initial load with empty search_text
        dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
      } else {
        if (employeeTimeOutId) {
          clearTimeout(employeeTimeOutId)
        }
        employeeTimeOutId = setTimeout(() => {
          dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
        }, 500);
      }
    }
  }, [employeeSearch, selected_location, employeePage])


  const PageSize = 10

  // Create Comment API Create 

  let initialState = {
    selectedEmployee: "",
    comment: ""
  }
  const [employeeComment, setEmployeeComment] = useState(initialState)

  const onChangeHandler = (e) => {
    if (e?.target) {
      const { name, value } = e.target;
      if (name === 'comment' || name === "selectedEmployee") {
        setEmployeeComment((prev) => ({ ...prev, [name]: value }));
      }
      setErrors((prev) => ({ ...prev, [name]: null }));
    }
  };



  const onSubmitHandler = () => {
    const { comment, selectedEmployee } = employeeComment;
    let requiredFields = {}
    requiredFields = { comment, selectedEmployee }

    setErrors(validation(requiredFields));
    let result = validation(requiredFields);

    if (objectKeyConvertToArray(result)?.length === 0) {
      setSubmitLoader(true)
      const success = (result) => {
        setEmployeeCommentPopup(false)
        setEmployeePage(1);
        getComments()
        setEmployeeComment(initialState);
        setSubmitLoader(false)

      }
      const fail = (result) => {
        setSubmitLoader(false)
        setEmployeeComment(initialState);
        console.log('fail', result);
      }
      dispatch(createEmployeeComment({ comment: comment, employee_id: selectedEmployee, location_id: selected_location }, success, fail))
    }
  }



  const getComments = () => {
    setLoading(true)
    const payload = { location_id: selected_location, page: currentPage };

    const success = (result) => {
      setLoading(false)
      setEmployeeCommentsData(result?.response)
    };

    const fail = (result) => {
      setLoading(false)
      console.log('fail', result);
    };

    getEmployeeComment(payload, success, fail);
  };

  useEffect(() => {
    getComments()
  }, [currentPage, selected_location])

  useEffect(() => {
    setCurrentPage(1)
  }, [selected_location])

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Employee Management" : translation[375]?.value ? translation[375]?.value : "Employee Management"}
          second={selectedLanguage?.name == "English" ? "Employee Comments" : translation[1683]?.value ? translation[1683]?.value : "Employee Comments"}
          url={'/dashboard/employee/'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Employee Comments" : translation[1683]?.value ? translation[1683]?.value : "Employee Comments"}
            </h2>
            <LocationSelection />
          </div>
          <div className="flex items-center sm:justify-center justify-end gap-3 w-fit">
            <LoginBtn className={'!capitalize'} text={selectedLanguage?.name == "English" ? "Add Comments" : translation[1685]?.value ? translation[1685]?.value : "Add Comments"} onClick={() => {
              setEmployeeCommentPopup(true)
              if (selected_location) {
                dispatch(getEmployeeDropDownList(selected_location))
              }
            }} />
          </div>
        </div>

        <div className="border rounded-lg">
          <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b sm:flex-row flex-col gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Comments Details" : translation[1684]?.value ? translation[1684]?.value : "Comments Details"}
                </h2>
                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                  <p className="text-primary">
                    {EmployeeCommentsData?.count} {" "}
                    {selectedLanguage?.name == "English" ? "Comments" : translation[1685]?.value ? translation[1685]?.value : "Comments"}
                  </p>
                </div>
              </div>
            </div>


          </div>
          {loading ?
            <TableShimer cols={5} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                <p>
                  {selectedLanguage?.name == "English" ? "Sr. No" : translation[1679]?.value ? translation[1679]?.value : "Sr. No"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Commented By" : translation[1687]?.value ? translation[1687]?.value : "Commented By"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Employee" : translation[1321]?.value ? translation[1321]?.value : "Employee"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}
                </p>
                <p className="col-span-2">
                  {selectedLanguage?.name == "English" ? "Comments" : translation[1685]?.value ? translation[1685]?.value : "Comments"}
                </p>
              </div>
              {EmployeeCommentsData?.data?.length > 0 ? (EmployeeCommentsData?.data?.map((data, i) => {
                return (
                  <div className={`odd-bg text-[#3A3A3A] transition-all min-w-full w-fit`}>
                    <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))] min-w-full w-fit">
                      <div className="h-full flex items-center">
                        {/* <p className="text-sm">{data?.user?.id?.substring(0, 6)?.toUpperCase()}</p> */}
                        <p className="text-sm">{data?.id?.substring(0, 6)?.toUpperCase()}</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {data?.user?.first_name}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {data?.employee?.full_name}
                        </p>
                      </div>

                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          {moment(data?.created_at).format(' DD MMM yyyy ')}
                        </p>
                      </div>

                      <div className="flex items-center col-span-2">
                        <p className="text-sm line-clamp-2 pr-3">
                          {data?.comment}
                        </p>
                      </div>
                    </div>
                  </div>
                )

              })) : (
                <>
                  <div className="max-w-full">
                    <NoCommentFound />
                  </div>
                </>
              )
              }
            </div>
          }

          {(EmployeeCommentsData?.data?.length > 0 && !loading) &&
            <Pagination
              currentPage={currentPage}
              totalCount={Math.ceil(EmployeeCommentsData?.total_pages)}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          }

        </div>
      </div>
      {employeeCommentPopup && (
        <Popup
          heading="Add Comment"
          onclose={() => {
            setEmployeeCommentPopup(false);
            setEmployeeComment(initialState);
            setErrors("");
          }}
        >
          <div className="space-y-6">
            <div className="space-y-[0.625rem]">
              <p className="text-[#101928] text-sm">Select Employee</p>
              <PaginationDropdown
                title=""
                setPage={setEmployeePage}
                page={employeePage}
                setDropdownSearch={setEmployeeSearch}
                dropdownSearch={employeeSearch}
                lastPage={employeePaginationData?.employeePages}
                currentPage={employeePaginationData?.employeeCurrentPage}
                showTitle={true}
                placeholder={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                name={"selectedEmployee"}
                value={employeeComment?.selectedEmployee}
                onChange={onChangeHandler}
                loading={employeeLoader}
                error={errors?.selectedEmployee}
                custom_min_width={'sm:min-w-[15rem] min-w-[20rem]'}
                options={[

                  ...employeeRedux?.map((empl, i) => {
                    return {
                      label: empl.full_name,
                      value: empl.id,
                      otherData: {
                        image: empl?.image,
                        DataOne: empl.email,
                        showImage: true,
                        index: i
                      },
                      email: empl.email,
                      mobile_number: empl.mobile_number
                    };
                  })
                ]}
              />
            </div>

            <Textarea
              title={selectedLanguage?.name == "English" ? "Add Comment" : translation[1570]?.value ? translation[1570]?.value : "Add Comment"}
              rows={5}
              limit={150}
              placeholder={selectedLanguage?.name == "English" ? "Add text ..." : translation[1571]?.value ? translation[1571]?.value : "Add text ..."}
              required={true}
              name={'comment'}
              value={employeeComment?.comment}
              onChange={onChangeHandler}
              onEnterSubmit={onChangeHandler}
              errorMessage={errors?.comment}
              onBlur={() => onBlurHandler({ name: "comment" })}
            />

            <LoginBtn
              className={'!capitalize'}
              text={selectedLanguage?.name == "English" ? "Add Comment" : translation[1570]?.value ? translation[1570]?.value : "Add Comment"}
              onClick={onSubmitHandler}
              loading={submitLoader}
              disabled={submitLoader}
            />
          </div>
        </Popup>
      )}
    </>
  );
};

export default EmployeeComments;
