import { getLocations } from "Adapters/Api";
import { getEmployeeDropDownList, getEmployeeDropDownListPagination } from "Adapters/Api/employee";
import { createEmployeeTraining, getEmployeesSkill } from "Adapters/Api/employee/employeeTraining";
import useObjectKeys from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

let employeeTimeOutId = undefined

const initialState = {
    teacher: '',
    student: '',
    skill: '',
    location: '',
    date_requested: '',
    student_comment: ''
}
const useAddTraining = (onSuccessCall, close) => {
    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys()
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [loading, setLoading] = useState(false)
    const [employeeLoader, setEmployeeLoader] = useState(true)
    const [trainingData, setTrainingData] = useState(initialState)
    const businessData = getDataFromLocalStorage("business_data");
    const access_token = getDataFromLocalStorage("access_token");


    const selected_location = state?.locations?.selected_location;
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const employeeRedux = state?.employee?.employees
    const employeeSkills = state?.employee?.employeeSkills
    const LocationsList = state?.locations?.locations;

    useEffect(() => {
        dispatch(getEmployeeDropDownList(selected_location, setEmployeeLoader))
    }, [selected_location])

    const getBusinessLocation = (business_id) => {
        dispatch(getLocations(business_id));
    };
    useEffect(() => {
        getBusinessLocation(businessData?.id);
        dispatch(getEmployeesSkill(access_token))
    }, []);

    const onChangeHandler = (e) => {
        if (e?.target) {
            const { name, value } = e.target;
            setTrainingData((prev) => ({ ...prev, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: "" }))
        }
    }

    const onSubmitHandler = () => {
        const { teacher, student, skill, location, date_requested, student_comment } = trainingData;
        let requiredFields = { teacher, student, skill, location, date_requested, student_comment }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoading(true)
            const payload = trainingData
            const success = () => {
                onSuccessCall && onSuccessCall()
                setTrainingData(initialState)
                close && close(false)
                setLoading(false)
            }
            const fail = () => {
                setLoading(false)
            }
            dispatch(createEmployeeTraining(access_token, payload, success, fail))
        }
    }


    return {
        translation,
        selectedLanguage,
        employeeRedux,
        employeeLoader, onChangeHandler,
        LocationsList, errors,
        trainingData, onSubmitHandler,
        employeeSkills, loading
    }

}

export default useAddTraining