import React, { useEffect, useState } from "react";

import ConatinerWithHeader from "Components/NstyleApp/Wrappers/ConatinerWithHeader";
import ProfileInfo from "../Components/ProfileInfo";
import ServiceTable from "../Components/ServiceTable";
import useProfile from "./helper";
import CreateLinkPopup from "Components/TenantApp/Dashboard/CreateLinkPopup";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";

const OnlineProfile = () => {
  const { visitedLocation, locationList, token, next, showSignin } = useProfile();
  const [isPopup, setIsPopup] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();

  const hash = getDataFromLocalStorage("hash")
  const hash_name = getDataFromLocalStorage("hash_name")
  const business = getDataFromLocalStorage("business")


  return (
    <>
      <ConatinerWithHeader signin={token} showSignin={showSignin} next={next}>
        <div className="flex flex-col gap-6">
          <ProfileInfo />
          <ServiceTable />
          {/* {locationList?.filter(
            (itm) =>
              itm?.id != visitedLocation?.id && itm?.is_publish === true
          )
            ?.map((itm) => itm.address_name != "" ? */}
            <div className="flex flex-col gap-3">
              <h1 className="font-semibold text-xl">Additional locations</h1>
              <div className="flex flex-col gap-3 max-h-[30rem] overflow-auto border rounded-md p-3">
                {locationList
                  ?.filter((itm) => itm?.id != visitedLocation?.id && itm?.is_publish === true)
                  ?.filter((itm) =>itm.address_name != "")
                  ?.map((itm) => {
                    return (
                      <div className="border rounded-md p-3 flex items-center gap-3"
                        onClick={() => {
                          // setSelectedLocation(itm)
                          setIsPopup(!isPopup)
                          window.open(`http://${window?.location?.host}/profile/${itm?.address_name?.replaceAll(",", "").replaceAll(" ", "-")}/${itm?.address?.replaceAll(",", "").replaceAll(" ", "-")}/${itm?.id}?business=${business}&domain=${hash_name}&hash=${hash}&token=${"kjlkjlkjlkj"}`, '_blank', 'noreferrer');
                        }}
                      >
                        <div>
                          <img
                            src={`${itm?.images
                              ? itm?.images
                              : `${process.env.PUBLIC_URL}/images/location_img.png`
                              }`}
                            className="h-[5rem] w-[5rem] rounded-md border object-contain"
                          />
                        </div>
                        <div className="flex flex-col gap-3">
                          <h1 className="font-bold text-xl">{itm?.address_name}</h1>
                          <p className="text-xs text-[#101928]/60">
                            {itm?.address ? itm?.address : "----"}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
              {/* : "")} */}
        </div>
      </ConatinerWithHeader>
      {/* {
        isPopup &&
        <CreateLinkPopup
          selected_location={selectedLocation}
          isPopup={isPopup}
          setIsPopup={setIsPopup}
        />
      } */}
    </>
  );
};

export default OnlineProfile;
