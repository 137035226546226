import { useState } from 'react'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { addBusinessTax, deleteBusinessTax, getTaxSettingBusiness, updateBusinessTax, updateBusinessTaxSetting } from '../../../../../../Adapters/Api/financialSettings';
import useObjectKeys from '../../../../../../Hooks/useObjectKeys';
import useSetDataInLocalStorage from '../../../../../../Hooks/useSetDataInLocalStorage';
import useValidations from '../../../../../../Hooks/useValidations';
import { addingBusinessTaxs, updatingBusinessTax, removeBusinessTax } from '../../../../../../Redux/TenantRedux/Actions/financialSettingsActions';
import { getBusinessTax } from './../../../../../../Adapters/Api/financialSettings/index';
import { useEffect } from 'react';

function useTaxSettings() {
    const dispatch = useDispatch()
    const reduxBusinessTax = useSelector((state) => state?.financialSettings?.businessTaxs);
    const reduxBusinessTaxSetting = useSelector((state) => state?.financialSettings?.businessTaxSetting);
    const reduxlocationsList = useSelector((state) => state?.locations?.locations);
    const { errors, setErrors, validation, trimEmptySpace, onBlurHandler } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys();
    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
    const businessData = getDataFromLocalStorage("business_data");
    const update_item_id = getDataFromLocalStorage("update_item_id");
    const location_id = getDataFromLocalStorage("location_id");
    const access_token = getDataFromLocalStorage("access_token");


    const [tax, setTax] = useState(false);
    const [OpenSettings, setOpenSettings] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [taxSetting, setTaxSetting] = useState()
    const [taxSettingLoader, setTaxSettingLoader] = useState(false)
    const [addTax, setAddTax] = useState({
        name: "",
        tax_rate: "",
    })

    // group tax states 
    const [TaxGroup, setTaxGroup] = useState(false)
    const [groupTax, setGroupTax] = useState({
        name: "",
        tax1_id: "",
        tax2_id: ""
    })


    // location tax states 
    const [apply, setApply] = useState(false);
    // const [locationvalue, setLocationvalue] = useState();
    const [locationTax, setLocationTax] = useState({
        location: "",
        tax_rate: "",
    });

    // const taxGroupSetting = localStorage.getItem('taxTypeForGroup')
    useEffect(() => {
        if (!reduxBusinessTaxSetting?.tax_setting) {
            localStorage.setItem('taxTypeForGroup', 'Combined')
            setTaxSetting('Combined')
        } else {
            localStorage.setItem('taxTypeForGroup', reduxBusinessTaxSetting?.tax_setting)
            setTaxSetting(reduxBusinessTaxSetting?.tax_setting)
        }
    }, [reduxBusinessTaxSetting?.tax_setting])

    // *************************** Group modal toggler *****************************

    const groupModalToggler = () => {
        setTaxGroup(!TaxGroup)
        setGroupTax({
            name: "",
            tax1_id: "",
            tax2_id: ""
        })
        setErrors({});

    }


    // *************************** group handle change *****************************

    const groupTaxHandler = (e, taxName = "") => {

        if (e?.target) {
            const { name, value } = e?.target;
            setGroupTax((prev) => ({ ...prev, [name]: value }))
            setErrors((prev) => ({ ...prev, [name]: "" }));
        }

    }




    // *************************** Location modal toggler *****************************

    const applyModalToggle = () => {
        setApply(!apply)
        // setLocationvalue("");
        setLocationTax({
            location: "",
            tax_rate: "",
        })
        setErrors({});

    }


    // *************************** Location handle change *****************************

    const applyTaxHandleChange = (e) => {
        if (e?.target) {
            const { name, value } = e?.target;
            setLocationTax((prev) => ({ ...prev, [name]: value }))
            setErrors((prev) => ({ ...prev, [name]: "" }))
        } else {
            setLocationTax((prev) => ({ ...prev, location: e.id }));
            // setLocationvalue(e);
            setErrors((prev) => ({ ...prev, location: "" }))

        }

    }

    //TOGGLE TAX MODAL
    const taxModalToggler = () => {
        setTax(!tax)
        setAddTax((prev) => ({ ...prev, name: "", tax_rate: "" }));
        setErrors({});
    }

    //HANDLE ADD TAX INPUT FIELD
    const addTaxHandleChange = (e) => {

        const { name, value } = e.target;

        setAddTax((prev) => ({ ...prev, [name]: value }));
        setErrors((prev) => ({ ...prev, [name]: "" }))

    }

    // TAX SETTING SUBMIT
    const onTaxSettingSubmit = async () => {
        const response = await updateBusinessTaxSetting(taxSetting, reduxBusinessTaxSetting?.id, businessData?.id, access_token, setTaxSettingLoader);

        if (response.status === 200) {
            dispatch(getTaxSettingBusiness(businessData.id, access_token));
            let successMessage = response?.data?.response?.message;
            toast.success(successMessage, { toastId: "toast" });
            setOpenSettings(!OpenSettings)
        }
    }

    // ADD NEW TAX SUBMISSION
    const onAddTaxSubmit = async (taxType) => {

        let result;

        if (taxType === "location") {
            setErrors(validation(locationTax));
            result = validation(locationTax);
        } else if (taxType === "Individual") {

            setErrors(validation(addTax));
            result = validation(addTax);
        } else if (taxType === "group") {

            setErrors(validation(groupTax));
            result = validation(groupTax);
        }


        if (objectKeyConvertToArray(result)?.length === 0) {

            if (isUpdate) {


                let formatDataForBackend;
                if (taxType === "Individual") {
                    formatDataForBackend = {
                        ...addTax,
                        tax_rate: Number(addTax.tax_rate),
                        tax_id: update_item_id,
                        tax_type: "Individual",
                        business: businessData.id,

                    }
                } else if (taxType === "group") {

                    formatDataForBackend = {
                        name: groupTax.name,
                        tax_ids: [groupTax.tax1_id, groupTax.tax2_id],
                        tax_type: "Group",
                        tax_id: update_item_id,
                        business: businessData.id,
                    }

                } else if (taxType === "location") {

                    formatDataForBackend = {
                        location: locationTax.location,
                        tax_id: update_item_id,
                        tax_type: "Location",
                        business: businessData.id,
                        parent_tax: locationTax?.tax_rate,
                    }
                }

                const response = await updateBusinessTax(formatDataForBackend, access_token);

                if (response.status === 200) {
                    let businessTax = response?.data?.response?.tax;
                    let successMessage = response?.data?.response?.message;
                    dispatch(updatingBusinessTax(businessTax));
                    dispatch(getBusinessTax(businessData.id))
                    toast.success(successMessage, { toastId: "toast" });
                    taxType === "Individual" && taxModalToggler()
                    taxType === "location" && applyModalToggle()
                    taxType === "group" && groupModalToggler()
                }

            } else {

                let formatDataForBackend;
                if (taxType === "location") {
                    formatDataForBackend = {
                        // ...locationTax,
                        // tax_rate: Number(locationTax.tax_rate),
                        location: locationTax.location,
                        tax_id: locationTax.tax_rate,
                        tax_type: "Location",
                        business: businessData.id,
                        // name: locationvalue.value
                    }

                } else if (taxType === "Individual") {
                    formatDataForBackend = {
                        ...addTax,
                        tax_type: "Individual",
                        business: businessData.id,

                    }

                } else if (taxType === "group") {
                    formatDataForBackend = {
                        name: groupTax.name,
                        tax_ids: JSON.stringify([groupTax.tax1_id, groupTax.tax2_id]),
                        tax_type: "Group",
                        business: businessData.id,

                    }

                }

                const response = await addBusinessTax(formatDataForBackend, access_token);

                if (response.status === 201) {
                    let businessTax = response?.data?.response?.tax;
                    let successMessage = response?.data?.response?.message;
                    dispatch(addingBusinessTaxs(businessTax));
                    toast.success(successMessage, { toastId: "toast" });
                    taxType === "Individual" && taxModalToggler();
                    taxType === "location" && applyModalToggle();
                    taxType === "group" && groupModalToggler();
                }

            }
        }

    }

    // delete business tax 
    const businessTaxDeleteHandler = async (id, type) => {
        if (type === "Tax") {
            let is_exits = false
            reduxBusinessTax.forEach(tax => {
                if (tax.tax_type == 'Group') {
                    if (tax.parent_tax.find(tax_itm => tax_itm.id == id)) {
                        is_exits = true
                        return
                    }
                }
            })
            if (is_exits) {
                toast.error("This tax is in a group and can't be deleted!", { toastId: "toast" })
                return
            }
        }
        const taxOnLocation = reduxBusinessTax
            ?.filter((item) => item.tax_type === "Location")
            ?.filter(itm => itm?.parent_tax?.find(itmm => itmm.id === id))

        let response = await deleteBusinessTax(id, access_token);
        if (taxOnLocation) {
            taxOnLocation.map(async item => {
                let response = await deleteBusinessTax(item.id, access_token)
                if (response.status_code === 200) {
                    let successMessage = response?.response?.message;
                    toast.success(successMessage, { toastId: "toast" });
                    dispatch(removeBusinessTax(item.id));
                }
            })
        }

        if (response.status_code === 200) {
            let successMessage = response?.response?.message;
            toast.success(successMessage, { toastId: "toast" });
            dispatch(removeBusinessTax(id));
        }

    }

    // edit business tax 
    const businessTaxEditHandler = (id, taxType) => {
        setIsUpdate(true);
        storeDataInLocalStorage("update_item_id", id);
        if (taxType == "Individual") {

            taxModalToggler();
            const selectedTax = reduxBusinessTax.find((item) => item.id === id);
            setAddTax((prev) => ({ ...prev, name: selectedTax.name, tax_rate: selectedTax.tax_rate }));

        } if (taxType == "Group") {

            groupModalToggler();
            const selectedTax = reduxBusinessTax.find((item) => item.id === id);

            const [tax1, tax2] = selectedTax.parent_tax;

            setGroupTax((prev) => ({ ...prev, name: selectedTax?.name, tax1_id: tax1?.id, tax2_id: tax2?.id ? tax2?.id : tax1?.id }));
        } else if (taxType == "location") {

            applyModalToggle();
            const selectedTax = reduxBusinessTax.find((item) => item.id === id);
            const selectedLocation = reduxlocationsList && reduxlocationsList.filter((item) => item.id === selectedTax.location?.id)
            const [address] = selectedLocation;
            const [tax] = selectedTax.parent_tax;
            setAddTax((prev) => ({ ...prev, name: selectedTax.name, tax_rate: selectedTax.tax_rate }));
            setLocationTax((prev) => ({ ...prev, tax_rate: tax?.id, location: selectedTax?.location?.id }))
        }

    }


    return {
        errors,
        addTax,
        addTaxHandleChange,
        onAddTaxSubmit,
        taxModalToggler,
        onBlurHandler,
        tax,
        businessTaxDeleteHandler,
        businessTaxEditHandler,
        // locationvalue,
        locationTax,
        setLocationTax,
        applyTaxHandleChange,
        // onApplyTaxSubmit,
        applyModalToggle,
        apply,
        isUpdate,
        setIsUpdate,
        groupModalToggler,
        groupTax,
        TaxGroup,
        groupTaxHandler,
        taxSetting, setTaxSetting,
        onTaxSettingSubmit,
        OpenSettings, setOpenSettings,
        reduxBusinessTaxSetting, taxSettingLoader
    }
}

export default useTaxSettings
