import { getsProducts } from "Adapters/Api/Products"
import { getSaleServices } from "Adapters/Api/saleServices"
import Svgs from "Assets/svgs"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { getProducts } from "Redux/TenantRedux/Actions/product"





const BuyRetailGetFreeServiceCard = ({ selected, data, onSelect, onRemoveOffer }) => {
    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    const product = data?.promotion?.filter((retail, index) => retail?.promotion_type == "Product")
    const brand = data?.promotion?.filter((retail, index) => retail?.promotion_type == "Brand")

    return (
        <>
            {data?.promotion?.length > 0 &&
                <div
                    className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? "border-black" : " border-[#B8C6F5]"
                        }  border   rounded-md p-2 mt-1`}
                >
                    <div className="flex items-center justify-between mb-3">
                        <p className="text-[#A1A1A1] font-bold p-2">Buy Retail & Get Service Free</p>
                        {onRemoveOffer && selected &&
                            <button
                                className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                                onClick={onRemoveOffer}
                            >
                                <Svgs.Delete fill="#767A87" />
                            </button>
                        }
                    </div>

                    <div className="px-2 text-black font-semibold mb-1 w-full">{data?.promotion_name}</div>

                    <div className="">
                        {
                            product.length > 0 && (
                                <>
                                    <div className="flex items-center justify-between my-1 p-2">
                                        <p className="text-[#2a2a2a] font-bold">
                                            {selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}
                                        </p>
                                        <span className="text-[#2a2a2a] font-bold">
                                            {selectedLanguage?.name == "English" ? "Free Service" : translation[1062]?.value ? translation[1062]?.value : "Free Service"}
                                        </span>
                                    </div>
                                    {
                                        product?.map((retail, index) => {
                                            if (retail?.promotion_type == "Product")
                                                return (
                                                    <>
                                                        <div
                                                            key={index}
                                                            className={`${selected ? "" : "border"} border-[#B8C6F5] cursor-pointer flex mb-2 items-center w-full justify-between p-2 rounded-md`}
                                                            onClick={() =>
                                                                onSelect &&
                                                                onSelect(
                                                                    {
                                                                        ...data,
                                                                        promotion: [retail]
                                                                    },
                                                                    retail?.promotion_type
                                                                )
                                                            }
                                                        >
                                                            <div className="flex items-center">
                                                                <p className="text-[#343434] font-medium line-clamp-1 ">{retail?.product?.name}</p>
                                                                <span className="whitespace-nowrap ml-1 text-sm text-gray-600">(AED {retail?.product?.location_based_retail_price})</span>
                                                            </div>
                                                            <p>{retail?.service?.name}</p>
                                                        </div>
                                                    </>
                                                )
                                        })
                                    }
                                </>
                            )
                        }
                        {brand.length > 0 && (
                            <>
                                <div className="flex items-center justify-between my-1 p-2">
                                    <p className="text-[#2a2a2a] font-bold">
                                        {selectedLanguage?.name == "English" ? "Brands" : translation[836]?.value ? translation[836]?.value : "Brands"}
                                    </p>
                                    <span className="text-[#2a2a2a] font-bold">
                                        {selectedLanguage?.name == "English" ? "Free Service" : translation[1062]?.value ? translation[1062]?.value : "Free Service"}
                                    </span>
                                </div>

                                {brand?.map((retail, index) => {
                                    return (
                                        <>
                                            <div
                                                key={index}
                                                className={`${selected ? "" : "border"} border-[#B8C6F5] cursor-pointer flex mb-2 items-center w-full justify-between p-2 rounded-md`}
                                                onClick={() => onSelect &&
                                                    onSelect(
                                                        {
                                                            ...data,
                                                            promotion: [retail]
                                                        },
                                                        retail?.promotion_type
                                                    )}

                                            >
                                                <div className="flex items-center">
                                                    <p className="text-[#343434] font-medium line-clamp-1 ">{retail?.brand?.name}</p>
                                                    {/* <span className="whitespace-nowrap ml-1 text-sm text-gray-600">(AED {retail?.brand?.location_based_retail_price})</span> */}
                                                </div>
                                                <p>{retail?.service?.name}</p>
                                            </div>
                                        </>
                                    )
                                })
                                }
                            </>
                        )}
                    </div>
                </div>
            }
        </>
    )
}

BuyRetailGetFreeServiceCard.defaultProps = {
    data: {},
    selected: false,
    onSelect: () => { }
}

const BuyRetailGetFreeService = ({ setNotFound, retailPromotion, setProductsData, MODULE_TYPE, servicesRedux, onSelect }) => {

    const state = useSelector(state => state)
    const [retail_data, setRetailData] = useState([])

    const dispatch = useDispatch()

    // const handle_select_service = (data) =>{
    //     let services = data?.spend_service?.map(service =>{
    //         return {
    //             id : service?.service,
    //             name : service?.service_name,
    //             selection_type: "SERVICE",
    //             quantity: 1,
    //             price : service?.spend_amount ,
    //             discount_price : undefined,
    //             client_can_book : service?.client_can_book,
    //             slot_availible_for_online : service?.discount_service_slot_availible_for_online,
    //         }
    //     })

    //     dispatch({
    //         type: "ADD_BULK_ITEMS_TO_CART",
    //         payload: {'services' : services}
    //     });
    //     toast.success("Items added to cart");
    //     onSelect(
    //         [], 
    //         false,
    //     )
    // }

    const handle_select = (data, type) => {
        let new_items = []

        if (type === "Product")
            data?.promotion?.forEach(promotion => {
                if (promotion.promotion_type === "Product") {
                    new_items?.push({
                        ...promotion.service,
                        selection_type: "SERVICE",
                        quantity: 1,
                        price: 0,
                        discount_price: undefined,
                    })
                    new_items?.push({
                        ...promotion.product,
                        selection_type: "PRODUCT",
                        quantity: 1,
                        price: promotion?.product?.location_based_retail_price,
                        discount_price: undefined,
                    })
                }
            })

        if (type === "Brand") {
            data?.promotion?.forEach(promotion => {
                if (promotion.promotion_type === "Brand") {
                    new_items?.push({
                        ...promotion.service,
                        selection_type: "SERVICE",
                        quantity: 1,
                        price: 0,
                        discount_price: undefined,
                        buy_retail_redemption: true
                    })
                }
            })

            handle_select_product(data)
        }

        dispatch({
            type: "ADD_BULK_ITEMS_TO_CART",
            payload: {
                'services': new_items,
                is_promotion: true,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
            }
        });
        toast.success("Items added to cart", { toastId: "toast" });
        onSelect(
            [],
            false,
        )
    }

    const handle_select_product = (data) => {
        let services_disc = data?.promotion?.find(itm => itm)
        // console.log("services_disc", services_disc);
        if (!services_disc) {
            // console.log('Discount Service Not Found')
            return
        }

        // let selected_currency_id = state.locations.locations.find(loc => loc.id == state.locations.selected_location)?.currency?.id


        setProductsData(prev => {
            let this_brand_products = prev.filter(obj => obj.brand.id == services_disc?.brand?.id)

            // this_brand_products.forEach(this_prod => {
            //     console.log("New Product", this_prod);
            //     // let result = this_prod?.currency_retail_price?.find((itm) => itm?.currency === selected_currency_id)
            //     this_prod.discount = services_disc?.brand_discount
            //     // this_prod.discount_price = (result?.retail_price * (100 - services_disc.brand_discount)) / 100
            // })
            // console.log("prev_prods", this_brand_products);
            return this_brand_products
        })

        // dispatch({
        //     type: "ADD_BULK_ITEMS_TO_CART",
        //     payload: {
        //         'services': [],
        //         is_promotion: true,
        //     }
        // });

    }

    const get_products = async () => {
        const response = await getsProducts();

        if (response.status === 200) {
            let productList = response?.data?.response?.products;
            dispatch(getProducts(productList));
        }
    };

    useEffect(() => {
        if (!state.product.products_updated) {
            get_products()
            return
        }

        if (!state.saleService?.saleServices_updated) {
            dispatch(getSaleServices());
            return
        }
        let retails = [
            ...retailPromotion
        ]


        let selected_location = state.locations.selected_location
        selected_location = state.locations.locations?.find(itm => itm.id == selected_location)
        let selected_currency_id = undefined

        if (selected_location && selected_location.currency) {
            selected_currency_id = selected_location.currency.id
        }

        retails.forEach(itm => {
            itm.promotion?.forEach(retail => {
                let this_service = servicesRedux?.find(srv => srv.id == retail.service)
                if (this_service) {
                    retail.service = this_service
                }

                let this_product = state.product.products?.find(prod => prod.id == retail.product)
                if (this_product) {
                    let retail_price = this_product?.currency_retail_price?.find(rt_price => rt_price.currency == selected_currency_id)
                    retail.product = this_product
                    if (retail_price && retail_price?.retail_price) {
                        retail.product.location_based_retail_price = retail_price?.retail_price
                    }
                    else {
                        retail.product.location_based_retail_price = 0
                    }
                }
            })
        })

        setRetailData(retails)

    }, [state.product.products_updated, state.saleService?.saleServices_updated])


    return (
        <>
            {
                retail_data?.map((retail, index) => {
                    return (
                        <>
                            <BuyRetailGetFreeServiceCard
                                data={retail}
                                key={index}
                                onSelect={handle_select}
                            />
                        </>
                    )
                })
            }
        </>
    )
}

BuyRetailGetFreeService.defaultProps = {
    spend_some_amount: []
}


export default BuyRetailGetFreeService