import React from 'react'
import Svgs from 'Assets/svgs'
import LabelAvatar from '../Elements/LabelAvatar'

const ProductListCard = ({ data, onClick, index, name, sale, animate, currency, image, ...props }) => {
    return (
        // Shimmer
        animate
            ? <div className='px-3 pb-3 animate-pulse'>
                <div className=' border-b pb-3'>
                    <div className="flex items-center gap-3 px-3">
                        <div className="rounded-xl h-[4rem] w-[4rem] bg-[#F6F6F6]" />
                        <div className='flex flex-col gap-1 flex-1'>
                            <div className="h-6 w-full rounded-md bg-gray-100"></div>
                            <div className="h-6 w-full rounded-md bg-gray-100"></div>
                        </div>
                    </div>
                </div>
            </div>
            : <div className='px-3 pb-3 '> 
                <div className=' border-b pb-3'>
                    <div className="flex items-center cursor-pointer gap-3 px-3" onClick={() => onClick && onClick(data?.id)} >
                        <div
                            className="rounded-xl border h-[4rem] w-[4rem] flex justify-center items-center bg-center bg-cover bg-no-repeat bg-[#F6F6F6]"
                            style={{
                                backgroundImage: `url('${image ? image : ''}')`
                            }}
                        >
                            {!image &&
                                <LabelAvatar iconLabel={data?.full_name} index={index} className={'!rounded-xl'} />
                                // <Svgs.Dummy />
                            }

                        </div>
                        <div className='flex flex-col gap-1 flex-1'>
                            <p className="font-semibold text-[#3F3F44]">{name}</p>
                            {sale ?
                                <p className='text-[#3F3F44] text-xs'>{parseFloat(sale?.toFixed(2))} {" "} {currency ? currency : ""} sale</p>
                                : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ProductListCard