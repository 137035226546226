// eslint-disable-next-line react-hooks/exhaustive-deps
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { getLocations } from "Adapters/Api";
import { getsProducts, getsProductsDropdownListPagination, getsProductsForConsumption } from "Adapters/Api/Products";
import {
  handleStockTransfer,
} from "Adapters/Api/stocks";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import { apiErrorMessage } from "Constants/Data/Errors";
import useObjectKeys from "Hooks/useObjectKeys";
import useValidations from "Hooks/useValidations";
import { useStockTransfer } from "../helper";

let productTimeOutId = undefined;
export const useAddTransferStocks = ({
  handleClose,
  updateStockData,
}) => {
  const dispatch = useDispatch();
  const { handleGetStockTransferListWithNoPagination, setCurrentPage, search_text, handleGetStockTransferList } = useStockTransfer();
  const { setErrors, validation, errors, onBlurHandler } = useValidations();
  const { objectKeyConvertToArray } = useObjectKeys();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const selected_location = getDataFromLocalStorage("selected_location");
  const { id } = getDataFromLocalStorage("business_data");
  const state = useSelector((state) => state);
  const { locations } = state?.locations;
  const products = state?.product?.productDropdown;

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  const [fromAddress, setFromAddress] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [quantityMax, setQuantityMax] = useState();
  const [toProductsList, setToProductsList] = useState([]);
  const [toAddress, setToAddress] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [dropdownData, setDropdownData] = useState("");

  const [productSearchText, setProductSearchText] = useState("");
  const [productCurrentPage, setProductCurrentPage] = useState(1)
  const [productLoader, setProductLoader] = useState(false)
  const productPaginationData = state?.product?.productPagination;

  const loading = () => {
    setIsLoading(true);
  }

  const success = () => {
    setIsLoading(false);
    handleClose();
  }
  const fail = () => {
    setIsLoading(false);
    toast.error(apiErrorMessage, { toastId: "toast" });
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    let data = {
      from_location: dropdownData?.from_location,
      to_location: dropdownData?.to_location,
      note: dropdownData?.note,
      product: dropdownData?.product,
      quantity: dropdownData?.quantity
    }
    // validation for max quantity check
    const product = dropdownData?.product;
    var toLocationProduct = toProductsList?.find(
      (obj) => obj.id === product
    );
    const maxQuantity = toLocationProduct?.current_stock

    setErrors(validation(data));
    let result = validation(data);
    if (objectKeyConvertToArray(result)?.length === 0) {
      if (maxQuantity < dropdownData?.quantity) {
        setErrors({ ...errors, quantity: `${selectedLanguage?.name == "English" ? "The current stock of the product is" : translation[1197]?.value ? translation[1197]?.value : "The current stock of the product is"} ${maxQuantity}` })
      } else {
        if (dropdownData?.quantity >= 1) {
          loading();
          const res = await handleStockTransfer(
            dropdownData,
            access_token,
            success,
            fail
          );
          if (res.status === 201) {
            updateStockData();
            // fetchProductslist();
            setCurrentPage(1)
            handleGetStockTransferList(1, search_text);
            handleGetStockTransferListWithNoPagination(search_text)
            toast.success("Item transfer successfully");
          }
        }
        else {
          setErrors(prev => ({
            ...prev,
            quantity: `${selectedLanguage?.name == "English" ? "The quantity should be greater than 0" :
              translation[1081]?.value ? translation[1081]?.value : "The quantity should be greater than 0"
              }`
          }));
          // toast.error("The quantity should be greater than 0")
        }
      }
    }
  };

  useEffect(() => {
    const num = parseInt(dropdownData?.quantity, 10);
    if (num == '0') {
      setErrors(prev => ({
        ...prev,
        quantity: `${selectedLanguage?.name == "English" ? "The quantity should be greater than 0" :
          translation[1081]?.value ? translation[1081]?.value : "The quantity should be greater than 0"
          }`
      }));
    } else {
      setErrors(prev => ({
        ...prev,
        quantity: ''
      }));
    }
  }, [dropdownData?.quantity]);

  const handleDropdownChange = (e, fieldName = "") => {
    const { name, value } = e.target;
    setDropdownData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  // const getProductsList = async () => {
  //   const response = await getsProducts();
  //   response.status === 200 &&
  //     dispatch(getProducts(response?.data?.response?.products));
  // };
  // fetch Product Lists 
  // const fetchProductslist = async () => {
  //   const response = await getsProductsForConsumption(dropdownData?.from_location);
  //   if (response.status === 200) {
  //     let productList = response?.data?.response?.products;
  //     dispatch(getProducts(productList));
  //   }
  // };

  // useEffect(() => {
  //   if (dropdownData?.from_location) {
  //     fetchProductslist();
  //   }
  // }, [dropdownData?.from_location])
  useEffect(() => {
    if (dropdownData?.from_location) {
      if (productSearchText === null) {
        // Initial load with empty search_text
        dispatch(getsProductsDropdownListPagination(productCurrentPage, dropdownData?.from_location, "", setProductLoader))
      } else {
        if (productTimeOutId) {
          clearTimeout(productTimeOutId)
        }
        productTimeOutId = setTimeout(() => {
          dispatch(getsProductsDropdownListPagination(productCurrentPage, dropdownData?.from_location, productSearchText, setProductLoader))
        }, 500);
      }
    }
  }, [productSearchText, dropdownData?.from_location, productCurrentPage])


  useEffect(() => {
    if (products.length > 0 && update === false) {
      setUpdate(true);
    }
  }, [productsList?.length])

  useEffect(() => {
    // get the location list from server or redux-store depend on condition.
    id && locations?.length === 0
      ? dispatch(getLocations(id))
      : setFromAddress(
        locations?.map(({ address_name, id }) => ({
          label: address_name,
          value: id,
        }))
      );
  }, [locations]);

  useEffect(() => {
    if (dropdownData?.from_location) {
      const filteredData = locations?.filter(
        ({ id }) => id !== dropdownData?.from_location
      );

      setToAddress(filteredData?.map(({ address_name, id }) => ({
        label: address_name,
        value: id,
      })));
    } else {
      // Reset the 'setToAddress' array if no option is selected
      setToAddress([]);
    }
  }, [dropdownData?.from_location, locations]);

  useEffect(() => {
    const product_toLocation = [];
    products?.map((product) => {
      product?.location_quantities?.map((obj) => {
        product_toLocation.push({
          ...obj,
          name: product.name,
          id: product.id
        });
      });
    });
    setToProductsList(product_toLocation);
    setProductsList(
      product_toLocation?.map(({ name, id }) => ({
        label: name,
        value: id
      }))
    );
  }, [dropdownData?.from_location, products?.length, selected_location]);

  useEffect(() => {
    const product = dropdownData?.product;
    var toLocationProduct = toProductsList?.find(
      (obj) => obj.id === product
    );
    setQuantityMax(toLocationProduct?.current_stock)
    if (quantityMax < dropdownData?.quantity) {
      setErrors({ ...errors, quantity: `${selectedLanguage?.name == "English" ? "The current stock of the product is" : translation[1197]?.value ? translation[1197]?.value : "The current stock of the product is"} ${quantityMax}` })
    }
  }, [dropdownData?.product, dropdownData?.quantity, toProductsList.length])

  // useEffect(() => {
  //   // get the product list from server or redux-store depend on condition.
  //   getProductsList();
  // }, []);

  // const fetchProductslist = async () => {
  //   const response = await getsProducts();

  //   if (response.status === 200) {
  //     let productList = response?.data?.response?.products;
  //     dispatch(getProducts(productList));
  //   }
  // };

  return {
    handleDropdownChange,
    onSubmit,
    isLoading,
    toAddress,
    fromAddress,
    productsList,
    setToAddress,
    setProductsList,
    dropdownData, setErrors, validation, errors, onBlurHandler,
    productSearchText, setProductSearchText, productCurrentPage, setProductCurrentPage, productLoader, productPaginationData,
  };
};
