import React, { useEffect } from "react";
import Svgs from "Assets/svgs";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import CheckBox from "Components/TenantApp/Elements/Checkbox/CheckBox";
import useOrderStock from "../../useOrderStock";
import { useState } from "react";
import NoPurchaseOrderDataFound from "Components/Utility/NoPurchaseOrderDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
// import NotFound from "Components/Utility/NotFound";
// import Pagination from "new-components/Pagination";

function Step2({
  step,
  orderStock,
  orderStockHandleChange,
  handleStep,
  pages,
  page_loading,
  currentPage, setCurrentPage,
  translation,
  selectedLanguage, productLengthError, setProductLengthError,
  errors, productListRedux, productList, searchText, setSearchText, selected_product_list
}) {
  const {
    order_stock_fiter,
    searchValue,
    setSearchValue
  } = useOrderStock();
  // let pages = 2
  // let PageSize = 10
  const [filteredProducts, setFilteredProducts] = useState([])

  useEffect(() => {
    // ?.filter((item) => {
    //   if (item?.product?.is_active) {
    //     return true
    //   }
    //   else {
    //     return false
    //   }
    // })
    let filteredProducts
    if (searchText) {
      filteredProducts =
        productList?.filter((item) => {
          if (
            item?.name
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            item?.id
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            item?.stocks?.length
              ?.toString()
              .includes(searchText.toLowerCase()) ||
            item?.location_quantities?.length
              ?.toString()
              ?.includes(searchText.toString())

          ) {
            return true;
          } else {
            return false;
          }
        })
    } else {
      filteredProducts = productList
    }
    setFilteredProducts(filteredProducts)
  }, [searchText, productList, productList?.length])
  console.log(filteredProducts, "filteredProducts")

  return (
    <>
      {/* <div className="mt-[1rem] border flex items-center justify-between gap-4 border-[#BAD3FC] bg-[#ECF3FF] px-4 py-3 rounded-lg">
        <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
          <CircleIcon bg="#5679FF">
            <p className="text-xl text-white">JT</p>
          </CircleIcon>
          <div className="flex flex-col gap-1 lg:text-left text-center">
            <h2 className="text-primary font-semibold text-xl">
              Junaid Tahir
            </h2>
            <p className="text-[#7B7B7B] text-sm">
              Bahadurabad Chowrangi, Bahadurabad Bahadur Yar Jang CHS.
            </p>
          </div>
        </div>
        <div>
          <LoginBtn
            className="border border-primary bg-transparent text-primary"
            text="View Details"
          />
        </div>
      </div> */}
      <div className="border rounded-lg mt-[1rem]">
        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
              </h2>
              <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                <p className="text-primary">
                  {filteredProducts?.length} {" "}
                  {selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
                </p>
              </div>
            </div>
            <div className="mb-00">
              <LoginInput
                title=""
                placeholder={selectedLanguage?.name == "English" ? "Search Product" : translation[1265]?.value ? translation[1265]?.value : "Search Product"}
                padding="py-1"
                leftIcon={<Svgs.Search />}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
          </div>
          {/* <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
            <LoginBtn
              padding="p-3"
              className="rounded-lg border border-primary text-sm"
              bg="bg-primary"
              
            >
              <div className="flex items-center gap-2">
                <Svgs.Filters fill="#fff" />
              </div>
            </LoginBtn>
          </div> */}
        </div>
        {page_loading
          ?
          <TableShimer cols={6} />
          :
          <div className="overflow-x-auto">
            <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(180px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
              <p>{selectedLanguage?.name == "English" ? "Select Product" : translation[603]?.value ? translation[603]?.value : "Select Product"}</p>
              <p>{selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}</p>
              <p>{selectedLanguage?.name == "English" ? "Available Stock" : translation[1030]?.value ? translation[1030]?.value : "Available Stock"}</p>
              <p>{selectedLanguage?.name == "English" ? "Min Quantity" : translation[1031]?.value ? translation[1031]?.value : "Min Quantity"}</p>
              <p>{selectedLanguage?.name == "English" ? "Order Quantity" : translation[1032]?.value ? translation[1032]?.value : "Order Quantity"}</p>
              <p>{selectedLanguage?.name == "English" ? "Size" : translation[1035]?.value ? translation[1035]?.value : "Size"}</p>
            </div>
            {productList &&
              filteredProducts?.length > 0 &&
              productList ? (
              filteredProducts?.map((item, index) => {
                const prodId = item?.id?.split("-")[0]
                return (
                  <div
                    key={index}
                    className={`odd-bg text-[#3A3A3A] transition-all`}
                  >
                    <div
                      key={item?.id}
                      className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(180px,1fr))]"
                    >
                      <div className="flex items-center gap-3">
                        <CheckBox
                          className={"h-8 w-8"}
                          name="selected_product_list"
                          value={item?.id}
                          onChange={(e) => {
                            orderStockHandleChange(e);
                          }}
                          label={`${prodId}`}
                          id={item?.id}
                          checked={selected_product_list?.find((i) => i.productId === item?.productId && i.isDeleted === false ? true : false) ? true : false}
                        />
                      </div>
                      <div className="flex items-center gap-3">
                        <p className="text-sm">{item?.name}</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {item?.current_stock}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {item?.low_stock}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">{item?.reorder_quantity}</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">{item?.product?.product_size} g</p>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <NoPurchaseOrderDataFound />
              </>
            )}
          </div>
        }
        {/* <Pagination
          currentPage={currentPage}
          totalCount={Math.ceil(pages)}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        /> */}

      </div>
      {filteredProducts?.length > 0 &&
        <div>
          <div className="flex items-center justify-end mb-2">
            {productLengthError && <div className="text-red-500">{productLengthError}</div>}
          </div>
          <div className="flex items-center justify-end">
            <LoginBtn
              onClick={(e) => {
                handleStep(e, step);
              }}
              text={
                <div className="flex items-center gap-2">
                  <p>
                    {selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
                  </p>
                  <Svgs.NextArrow />
                </div>
              }
            // disabled={selected_product_list?.filter((obj) => obj.isDeleted === false).length === 0}
            />
          </div>
        </div>
      }
    </>
  );
}

export default Step2;
