import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import React from 'react'
import useDealTypeEightSetting from './helper'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import Svgs from 'Assets/svgs'
import PreloaderFull from 'Components/TenantApp/Elements/PreloaderFull'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'
import NotFound from 'Components/Utility/NotFound'
import AddPopup from '../../../Elements/AddPopup'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'

const DealTypeEightSetting = (props) => {
    const { errors, allServices, allServicesCategory, allServicesGroup, allProductsBrand, allProducts,
        setErrors, setSettingData, settingData, applicableItems, allMergedOptions, submitSettingClick, firstStepData
    } = props

    const {
        screenLoader, handleChange, handleChangeForType5DealDropdown, handleDiscountChange, removeSelectedItem, popup, setPopup,
        serviceType, setServiceType, searchApplicable, setSearchApplicable, selectedApplicableItemsArray
    } = useDealTypeEightSetting({
        setErrors, setSettingData, settingData, allMergedOptions
    })

    return screenLoader ? <PreloaderFull /> : (
        <>
            <FloatInput
                required={true}
                title={'Package Price'}
                placeholder={'Enter Package Price'}
                name="packagePrice"
                value={settingData?.packagePrice}
                onChange={handleChange}
                error={errors?.packagePrice}
            />
            <div className="flex flex-wrap sm:flex-nowrap gap-3">
                <LoginBtn
                    text={'Select Service'}
                    // className={'!w-full justify-center'}
                    onClick={() => {
                        setPopup(true)
                        setServiceType('Service')
                    }}
                />
                <LoginBtn
                    text={'Select Service Group'}
                    // className={'!w-full justify-center'}
                    onClick={() => {
                        setPopup(true)
                        setServiceType('ServiceGroup')
                    }}
                />
                <LoginBtn
                    text={'Select Service Category'}
                    // className={'!w-full justify-center'}
                    onClick={() => {
                        setPopup(true)
                        setServiceType('ServiceCategory')
                    }}
                />
                <LoginBtn
                    text={'Select Product'}
                    // className={'!w-full justify-center'}
                    onClick={() => {
                        setPopup(true)
                        setServiceType('Product')
                    }}
                />
                <LoginBtn
                    text={'Select Product Brand'}
                    // className={'!w-full justify-center'}
                    onClick={() => {
                        setPopup(true)
                        setServiceType('ProductBrand')
                    }}
                />
            </div>
            {(serviceType == 'Service' && popup) &&
                <AddPopup
                    completeDataLength={allServices?.length}
                    firstStepData={firstStepData}
                    open={popup}
                    close={() => {
                        setPopup(false)
                        setServiceType()
                    }}
                    heading={"Select Services"}
                    placeholder={"Search Service"}
                    apiType={"single-service"}
                    data={applicableItems?.map(itm => itm?.itemId)}
                    onSubmitHandler={(e, data) => { handleChangeForType5DealDropdown(e, data) }}
                />
            }
            {(serviceType == 'ServiceGroup' && popup) &&
                <AddPopup
                    firstStepData={firstStepData}
                    open={popup}
                    close={() => {
                        setPopup(false)
                        setServiceType()
                    }}
                    heading={"Select Service Groups"}
                    placeholder={"Search Service Groups"}
                    apiType={"service-group"}
                    completeDataLength={allServicesGroup?.length}
                    data={applicableItems?.map(itm => itm?.itemId)}
                    onSubmitHandler={(e, data) => { handleChangeForType5DealDropdown(e, data) }}
                />
            }
            {(serviceType == 'ServiceCategory' && popup) &&
                <AddPopup
                    firstStepData={firstStepData}
                    open={popup}
                    close={() => {
                        setPopup(false)
                        setServiceType()
                    }}
                    heading={"Select Service Categories"}
                    placeholder={"Search Service Categories"}
                    apiType={"service-category"}
                    completeDataLength={allServicesCategory?.length}
                    data={applicableItems?.map(itm => itm?.itemId)}
                    onSubmitHandler={(e, data) => { handleChangeForType5DealDropdown(e, data) }}
                />
            }
            {(serviceType == 'Product' && popup) &&
                <AddPopup
                    firstStepData={firstStepData}
                    open={popup}
                    close={() => {
                        setPopup(false)
                        setServiceType()
                    }}
                    heading={"Select Products"}
                    placeholder={"Search Product"}
                    apiType={"single-product"}
                    completeDataLength={allProducts?.length}
                    data={applicableItems?.map(itm => itm?.itemId)}
                    onSubmitHandler={(e, data) => { handleChangeForType5DealDropdown(e, data) }}
                />
            }
            {(serviceType == 'ProductBrand' && popup) &&
                <AddPopup
                    firstStepData={firstStepData}
                    open={popup}
                    close={() => {
                        setPopup(false)
                        setServiceType()
                    }}
                    heading={"Select Products Brands"}
                    placeholder={"Search Product Brand"}
                    apiType={"product-brand"}
                    completeDataLength={allProductsBrand?.length}
                    data={applicableItems?.map(itm => itm?.itemId)}
                    onSubmitHandler={(e, data) => { handleChangeForType5DealDropdown(e, data) }}
                />
            }
            {applicableItems?.length > 0 &&
                <div className="">
                    <div className="border rounded-lg">
                        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
                            <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                                <div className="flex items-center lg:gap-4 gap-3">
                                    <div className="flex items-center sm:gap-4 gap-2 ">
                                        <h2 className="font-semibold text-xl">Selected Items</h2>
                                        <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                            <p className="text-primary">{applicableItems?.length} {applicableItems?.length > 1 ? 'Records' : 'Record'} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-00">
                                    <LoginInput
                                        title=""
                                        value={searchApplicable}
                                        onChange={(e) => setSearchApplicable(e.target.value)}
                                        name={"search_text"}
                                        placeholder={"Search"}
                                        padding="py-1"
                                        leftIcon={<Svgs.Search />}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="overflow-x-auto">
                            <div className="inline-grid xl:grid grid-cols-[repeat(3,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                                <p>Name</p>
                                <p>Quantity</p>
                                <p>Action</p>
                            </div>
                            <div className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}>
                                {selectedApplicableItemsArray?.filter((itm) =>
                                    searchApplicable ? itm?.name?.toLowerCase()?.includes(searchApplicable?.toLowerCase()) : true
                                )?.length > 0 ? selectedApplicableItemsArray?.filter((itm) =>
                                    searchApplicable ? itm?.name?.toLowerCase()?.includes(searchApplicable?.toLowerCase()) : true
                                )?.map((item, i) => {
                                    const filtered = applicableItems?.find((itm) => itm?.itemId == item?.id);
                                    return (
                                        <div key={i} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(3,minmax(115px,1fr))] min-w-full w-fit">

                                            <div className="flex items-center gap-4">
                                                <div>
                                                    #{i + 1}
                                                </div>
                                                {item?.name}
                                            </div>

                                            <div className="flex items-center capitalize">
                                                <LoginInput
                                                    required={true}
                                                    title={'Quantity'}
                                                    placeholder={'Enter Quantity'}
                                                    name="qty"
                                                    value={filtered?.qty}
                                                    type={'number'}
                                                    onlyNumericAllowed={true}
                                                    onChange={(e) => { handleDiscountChange(e, filtered?.itemId) }}
                                                    error={(submitSettingClick && !filtered?.qty) ? true : false}
                                                />
                                            </div>
                                            <div className="flex items-center gap-3">
                                                <div
                                                    onClick={() =>
                                                        removeSelectedItem(filtered?.itemId)
                                                    }
                                                    className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center">
                                                    <Svgs.Delete fill="#7D7D7D" />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                                    : <NotFound />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default DealTypeEightSetting