import React from 'react'
import useRefundsSetting from './helper';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Svgs from 'Assets/svgs';
import Shimmer from 'Components/Utility/Shimmers/Shimmer';
import CardTileShimmer from 'Components/Utility/Shimmers/CardTileShimmer';
import NotFound from 'Components/Utility/NotFound';
import AddRefundPeriod from './AddRefundTimePeriodPopup';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import CardTile from 'Components/TenantApp/Dashboard/CardTile';
import DeleteConfirmation from 'new-components/delete-popup';

const RefundSetting = ({ employee, employeePermissions, loadingPermission }) => {
    const {
        selectedLanguage, translation,
        isEdit, setIsEdit, locationListFiltered,
        refundPopup, setRefundPopup, deleteLoading,
        deletePopup, setDeletePopup, refundDetails,
        fetchRefund, refundTimePeriodRedux,
        getRefundTimeDetail,
        deleteRefundTimePeriod
    } = useRefundsSetting()
    return (
        <>

            <div className="flex items-center justify-between lg:gap-0 gap-4 lg:flex-row flex-col my-6">
                <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
                    <CircleIcon>
                        <Svgs.Location_percent />
                    </CircleIcon>
                    <div className="flex flex-col gap-1 lg:text-left text-center">
                        <h2 className="text-primary font-semibold text-xl">
                            {selectedLanguage?.name == "English" ? "Store Refund Period" : translation[1164]?.value ? translation[1164]?.value : "Store Refund Period"}
                        </h2>
                        <p className="text-[#7B7B7B] text-sm">
                            {selectedLanguage?.name == "English" ? "Define how many days customers have for returns at each store for hassle-free refunds" : translation[1165]?.value ? translation[1165]?.value : "Define how many days customers have for returns at each store for hassle-free refunds"}
                        </p>
                    </div>
                </div>
                {loadingPermission ?
                    <Shimmer className={'w-20 lg:w-24'}>
                        <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                    </Shimmer> :
                    <div className="flex items-center sm:gap-4 gap-2">
                        {!employee ? (
                            <LoginBtn
                                onClick={() => {
                                    setIsEdit(false);
                                    setRefundPopup(!refundPopup)
                                }}
                            >
                                {selectedLanguage?.name == "English" ? "Set Refund Days" : translation[1483]?.value ? translation[1483]?.value : "Set Refund Days"}
                            </LoginBtn>
                        ) : employee &&
                            employeePermissions?.account_finance_setting?.includes(
                                "edit"
                            ) ? (
                            ""
                        ) : (
                            ""
                        )}
                    </div>
                }
            </div>
            {fetchRefund ?
                <>
                    <CardTileShimmer tax />
                    <CardTileShimmer tax />
                </>
                : refundTimePeriodRedux?.length > 0 ? (
                    <>
                        <div className="flex flex-col gap-3 mt-[2rem]">
                            {refundTimePeriodRedux?.map((item) => {
                                return (
                                    !employee ? (
                                        <CardTile
                                            leftIcon={<Svgs.Home size={'1.5rem'} />}
                                            onEdit={() => getRefundTimeDetail(item, "edit")}
                                            onDelete={() => getRefundTimeDetail(item, "delete")}
                                            // onDelete={() =>
                                            //     setDelPopup({
                                            //         heading: selectedLanguage?.name == "English" ? "Tax Location" : translation[752]?.value ? translation[752]?.value : "Tax Location",
                                            //         callback: businessTaxDeleteHandler,
                                            //         data: item.id,
                                            //     })
                                            // }
                                            title={item?.location_name}
                                            icon={item?.allowed_refund}
                                            desc={`${selectedLanguage?.name == "English" ? "Refund Days" : translation[1484]?.value ? translation[1484]?.value : "Refund Days"}: ${item?.number_of_days}`}
                                        />
                                    ) : employee && employeePermissions?.account_finance_setting?.includes("edit") ? (
                                        <CardTile
                                            leftIcon={<Svgs.Home size={'1.5rem'} />}
                                            onEdit={() => getRefundTimeDetail(item, "edit")}
                                            title={item?.location_name}
                                            icon={item?.allowed_refund}
                                            desc={`Valid for ${item?.number_of_days} days`}
                                        />
                                    ) : employee && employeePermissions?.account_root_access?.includes("view") ?
                                        (
                                            <CardTile
                                                leftIcon={<Svgs.Home size={'1.5rem'} />}
                                                title={item?.location_name}
                                                icon={item?.allowed_refund}
                                                desc={`Valid for ${item?.number_of_days} days`}
                                            />
                                        ) : (""))
                            })}
                        </div>
                    </>
                ) : (
                    <>
                        <NotFound className="" />
                    </>
                )}

            {refundPopup &&
                <AddRefundPeriod
                    details={refundDetails}
                    open={refundPopup}
                    close={setRefundPopup}
                    isEdit={isEdit}
                    locationList={locationListFiltered}
                    setIsEdit={setIsEdit}
                />
            }

            {deletePopup &&
                <DeleteConfirmation
                    title='Refunds Location Time Period'
                    isLoading={deleteLoading}
                    handleClose={() => {
                        setDeletePopup(false);
                    }}
                    handleDelete={() => deleteRefundTimePeriod(refundDetails?.id)}
                    heading={"Are you sure you want to delete?"}
                />}
        </>
    )
}

export default RefundSetting