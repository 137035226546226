import { getFinanceAnalyticsData } from "Adapters/Api/posAnalytics";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useFinanceAnalytics = () => {
  const navigate = useNavigate();

  const { getDataFromLocalStorage } = useSetDataInLocalStorage()
  const selectedLocationCurrency = getDataFromLocalStorage('selectedLocationCurrency')

  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const reduxlocationsList = state?.locations?.locations;
  const selected_location = state?.locations?.selected_location;

  const [year, setYear] = useState('2024');
  const [loader, setLoader] = useState(true)
  const [financeData, setFinanceData] = useState({})

  const getFinanceData = () => {
    setLoader(true)
    const payload = {
      location_id: selected_location,
      year: year
    };

    const success = (result) => {
      const data = result?.response
      const salesMonthChartData = Object.values(data?.total_sales_month_wise).map(value => value === null ? 0 : value);
      const salesPreviousYearMonthChartData = Object.values(data?.total_sales_previous_month_wise).map(value => value === null ? 0 : value);
      const expenseMonthChartData = Object.values(data?.total_expenses_month_wise).map(value => value === null ? 0 : value);
      const paymentMethodAnalytics = Object.entries(data?.sales_by_payment_methods
      ).map(([name, value]) => ({
        name: name,
        value: (+value)?.toFixed(2)
      }));
      setFinanceData({
        total_sales: data?.total_sales ? (+data?.total_sales)?.toFixed(2) : 0,
        total_expenses: data?.total_expenses ? (+data?.total_expenses)?.toFixed(2) : 0,
        saleExpenseChart: {
          salesMonthChartData: salesMonthChartData,
          expenseMonthChartData: expenseMonthChartData
        },
        saleCurrentPreviousYearChart: {
          salesMonthChartData: salesMonthChartData,
          salesPreviousYearMonthChartData: salesPreviousYearMonthChartData
        },
        paymentMethodAnalytics: paymentMethodAnalytics,
        totalSalesYearWise: {
          current_year_sales: data?.total_sales_year_wise?.current_year_sales ? data?.total_sales_year_wise?.current_year_sales : 0,
          previous_year_sales: data?.total_sales_year_wise?.previous_year_sales ? data?.total_sales_year_wise?.previous_year_sales : 0
        }
      })
      setLoader(false)
    };
    const fail = () => {
      setLoader(false)
    }
    getFinanceAnalyticsData(payload, success, fail);
  };

  useEffect(() => {
    if (selected_location) {
      getFinanceData();
    }
  }, [selected_location, year]);


  return {
    translation, selectedLanguage, navigate, reduxlocationsList, year, setYear, loader, financeData, selectedLocationCurrency
  }
}

export default useFinanceAnalytics