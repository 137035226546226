import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getAllSaleOders, getSingleInvoiceDetail, getrecentSaleOrders } from "Adapters/Api/saleOrders";
import { getEmployeeListSalesCart } from "Adapters/Api/employee";
// import { useMemo } from "react";
// import useSaleModule from "../helper";

export const useQuickSale = () => {
  // const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector(state => state)
  const employee_updated = state?.employee?.employee_updated
  const invoice_titles = state?.saleOrder?.invoice_titles;
  const recentSaleorders = state?.saleOrder?.recentSaleorders;
  const location_currency = state?.utility?.currency?.location_currency;
  const reduxEmployees = state.employee?.employeesSalesCart;
  const { locations, saleService, utility } = useSelector((state) => state);

  const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
  const selected_location = getDataFromLocalStorage("selected_location");
  const access_token = getDataFromLocalStorage("access_token");
  const checkoutId = getDataFromLocalStorage("checkoutId");
  const locationChange = getDataFromLocalStorage("locationChange");
  const resetCart = getDataFromLocalStorage("resetCart");

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [searchParams, setSearchParams] = useSearchParams();
  const [locationName, setLocationName] = useState("");
  const [employeePopup, setEmployeePopup] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedInvice, setSelectedInvice] = useState();
  const [InvoicePopup, setInvoicePopup] = useState(false);
  const [loader, setLoader] = useState(true)
  const [backButtonClick, setBackButtonClick] = useState(false)
  const [detailLoading, setDetailLoading] = useState(true)

  // const employeeRedux = allEmployees?.filter(emp => emp?.is_active == true)?.filter((emp) =>
  //   emp?.location?.find((loc) => loc?.id === selected_location) ? true : false
  // );

  const active_tab = searchParams.get("active_tab");

  useEffect(() => {
    const val = locations?.locations?.filter(
      ({ id }) => selected_location === id
    );
    setLocationName(val[0]?.address_name);
  }, [locations, locationName]);

  useEffect(() => {
    if (selected_location) {
      dispatch(getEmployeeListSalesCart(selected_location, setLoader))
    }
  }, [selected_location])

  useEffect(() => {
    if (reduxEmployees?.length === 0) {
      setEmployeePopup(true)
    } else {
      setEmployeePopup(false)
    }
  }, [selected_location, reduxEmployees, reduxEmployees?.length])

  const tabList = [
    {
      value: "products",
      label: selectedLanguage?.name == "English" ? "products" : translation[233]?.value ? translation[233]?.value : "products"
    },
    {
      value: "services",
      label: selectedLanguage?.name == "English" ? "services" : translation[180]?.value ? translation[180]?.value : "services"
    },
    {
      value: "vouchers",
      label: selectedLanguage?.name == "English" ? "vouchers" : translation[196]?.value ? translation[196]?.value : "vouchers"
    },
    {
      value: "memberships",
      label: selectedLanguage?.name == "English" ? "memberships" : translation[195]?.value ? translation[195]?.value : "memberships"
    },
    {
      value: "gift_card",
      label: selectedLanguage?.name == "English" ? "Gift Card" : translation[1540]?.value ? translation[1540]?.value : "Gift Card"
    },
  ];


  useEffect(() => {
    // custom browser back button functionality implemented
    const handlePopstate = () => {
      setBackButtonClick(true)
      navigate('/dashboard/sales');
    };
    window.addEventListener('popstate', handlePopstate);
    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);


  useEffect(() => {
    if (!backButtonClick && !(active_tab && !tabList.includes(active_tab))) {
      setSearchParams({ ...searchParams, active_tab: tabList[0]?.value });
    }
  }, [active_tab, backButtonClick]);

  useEffect(() => {
    let payload = {
      setLoading: setLoading,
      location: selected_location,
      is_quick_sale: true
    }
    dispatch(getAllSaleOders(access_token, payload));
  }, [selected_location])

  // useEffect(() => {
  //   if (locationChange || resetCart) {
  //     dispatch({
  //       type: "RESET_CART",
  //       payload: []
  //     })
  //     localStorage.removeItem('locationChange')
  //     localStorage.removeItem('resetCart')
  //   }
  // }, [locationChange, resetCart])

  useEffect(() => {
    if (checkoutId) {
      getInvoiceDetailData(checkoutId)
      localStorage.removeItem('checkoutId')
    }
  }, [checkoutId])

  const getSaleHistory = () => {
    let payload = {
      setLoading: setLoading,
      location: selected_location,
      is_quick_sale: true
    }
    dispatch(getAllSaleOders(access_token, payload));
  }

  // const invoiceLabels = useMemo(() => {
  //   return (
  //     recentSaleorders?.find(itm => itm?.location == selected_location)
  //   )
  // }, [recentSaleorders, recentSaleorders?.length, selected_location])

  // const calculateTotalAmount = (item) => {
  //   let total_amount = 0;

  //   if (typeof item?.membership == "object") {
  //     if (item?.membership?.length > 0) {
  //       item?.membership?.forEach((itm) => {
  //         total_amount += itm?.membership_price * (itm?.quantity ? itm?.quantity : 1);
  //       });
  //     }
  //   }

  //   if (typeof item?.voucher == "object") {
  //     if (item?.voucher?.length > 0) {
  //       item?.voucher?.forEach((itm) => {
  //         total_amount += (itm?.voucher_price) * (itm?.quantity ? itm?.quantity : 1);
  //       });
  //     }
  //   }

  //   if (typeof item?.service == "object") {
  //     if (item?.service?.length > 0) {
  //       item?.service?.forEach((itm) => {
  //         total_amount += (itm?.price * itm?.quantity)
  //         // itm?.service?.priceservice?.forEach((tm) => {
  //         //   total_amount += (tm?.price) * (itm?.quantity ? itm?.quantity : 1);
  //         // });
  //       });
  //     }
  //   }

  //   if (typeof item?.product == "object") {
  //     if (item?.product?.length > 0) {
  //       item?.product?.forEach((itm) => {
  //         total_amount += (itm?.price) * (itm?.quantity ? itm?.quantity : 1);
  //       });
  //     }
  //   }

  //   if (item?.order_type === "Appointment") {
  //     total_amount += item?.total_price;
  //   }

  //   return total_amount;
  // }

  const getInvoiceDetailData = (id) => {
    setInvoicePopup(!InvoicePopup);
    dispatch(getSingleInvoiceDetail(
      access_token, id, selected_location, setDetailLoading,
      (result) => {
        setSelectedInvice(result);
      },
      () => { }
    ));
  }

  // const toggleInviceModal = (id = "", type = "") => {
  //   if (id && type == "history") {
  //     const result = state?.saleOrder?.recentSaleorders?.find(
  //       (item) => item?.id == id
  //     );
  //     // calculate_totals(result);
  //     setSelectedInvice(result);
  //   }

  //   setInvoicePopup(!InvoicePopup);
  // };

  const calculate_totals = (item) => {
    let total_bill = 0;
    if (typeof item?.membership == "object") {
      if (item?.membership?.length > 0) {
        item?.membership?.forEach((itm) => {
          total_bill += itm?.membership_price * (itm?.quantity ? itm?.quantity : 1);
        });
      }
    }

    if (typeof item?.voucher == "object") {
      if (item?.voucher?.length > 0) {
        item?.voucher?.forEach((itm) => {
          total_bill += (itm?.voucher_price) * (itm?.quantity ? itm?.quantity : 1);
        });
      }
    }

    if (typeof item?.service == "object") {
      if (item?.service?.length > 0) {
        item?.service?.forEach((single_service) => {
          // let this_price = single_service?.service?.priceservice?.find(price => price.currency_name == location_currency)
          // if (this_price) {
          // total_bill += (this_price?.price) * (single_service?.quantity ? single_service?.quantity : 1);
          // }
          total_bill += (single_service?.price) * (single_service?.quantity ? single_service?.quantity : 1);
        });
      }
    }


    if (typeof item?.product == "object") {
      if (item?.product?.length > 0) {
        item?.product?.forEach((itm) => {
          total_bill += (itm?.price) * (itm?.quantity ? itm?.quantity : 1);
        });
      }
    }

    // if (item?.tip?.length > 0) {
    //   const selected_invoice_tip = (item?.tip?.length > 0 ? item?.tip?.reduce(function (total, obj) {
    //     return total + (obj.tip !== null && obj?.tip);
    //   }, 0) : 0)

    //   if (selected_invoice_tip) {
    //     total_bill += selected_invoice_tip
    //   }
    // }


    if (item?.order_type === "Appointment") {
      total_bill += item?.total_price;
    }
    let total = total_bill?.toFixed(2)
    return total;
  };

  return {
    navigate, setLocationName, setSearchParams, locations, active_tab, tabList, searchParams, locationName, employeePopup,
    setEmployeePopup, employee_updated, loading, recentSaleorders, selectedInvice, setSelectedInvice, setInvoicePopup,
    InvoicePopup, location_currency, reduxEmployees, loader, invoice_titles, translation, selectedLanguage,
    calculate_totals, detailLoading, getInvoiceDetailData, selected_location, setLoader, dispatch, getSaleHistory,
    storeDataInLocalStorage
  };

};
