import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Popup from "../../../../Elements/Popup";
import LoginBtn from "../../../../Elements/LoginBtn";
import { ProductPopup } from "../AddHeaderBtns/AddProductBtn";
import { deleteProduct, getAllProductsWithNoPagination, getsProducts, getsProductsDetail } from "Adapters/Api/Products";
import Svgs from "Assets/svgs";
import { useLocation } from "new-components/location/helper";
import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
// import { apiErrorMessage } from "Constants/Data/Errors";
// import { PRODUCT } from "Redux/TenantRedux/ActionTypes/productTypes";
// import { getProducts } from "Redux/TenantRedux/Actions/product";

const ProductCard = ({
  item,
  ShowDetails,
  setShowDetails,
  currency,
  selected_location,
  translation,
  selectedLanguage,
  search_text,
  setCurrentPage,
  fetchProductslist,
  hideDeleteIcon,
  setNoPaginationLoader
}) => {

  // console.log(item)

  const [edit_popup, setEditPopup] = useState(false);
  const [delete_popup, setDelPopup] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [detailLoading, setDetailLoading] = useState(true)
  const [singleItemDetail, setSingleItemDetail] = useState({})

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { locationDetail } = useLocation();

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const getSingleProductItemDetail = (id) => {
    setEditPopup(!edit_popup);
    dispatch(getsProductsDetail(
      id, setDetailLoading,
      (result) => {
        setSingleItemDetail(result);
        setDetailLoading(false)
      },
      () => { }
    ));
  }

  return (
    <>
      {edit_popup && (
        <ProductPopup
          edit_mode={true}
          edit_data={singleItemDetail}
          loader={detailLoading}
          onClose={() => {
            setEditPopup(false);
            setSingleItemDetail({})
          }}
        />
      )}
      {delete_popup && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this Product?" : translation[1003]?.value ? translation[1003]?.value : "Are you sure you want to delete this Product?"}
          onclose={() => {
            setDelPopup(false);
          }}
        >
          <div className="">
            <p>
              {selectedLanguage?.name == "English"
                ? "Are you sure you want to delete this product? You won't be able to see this again."
                : translation[1004]?.value
                  ? translation[1004]?.value
                  : "Are you sure you want to delete this product? You won't be able to see this again."}

            </p>
            <div className="flex items-center justify-end mt-[2rem]">
              <LoginBtn
                loading={deleting}
                disabled={deleting}
                deleteBtn
                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                onClick={() => {
                  setDeleting(true);
                  dispatch(
                    deleteProduct(
                      item.id,
                      (result) => {
                        if (result?.status_code === 200) {
                          if (!isToastVisible) {
                            toast.success(selectedLanguage?.name == "English" ? "Product deleted successfully" : translation[1005]?.value ? translation[1005]?.value : "Product deleted successfully", {
                              onClose: () => setIsToastVisible(false),
                            });
                            setIsToastVisible(true);
                          }

                          // toast.success(selectedLanguage?.name == "English" ? "Product deleted successfully" : translation[1005]?.value ? translation[1005]?.value : "Product deleted successfully")
                          setTimeout(() => {
                            setDeleting(false);
                            setDelPopup(false);
                          }, 200);
                          setCurrentPage(1)
                          fetchProductslist(1, selected_location, search_text)
                          dispatch(getAllProductsWithNoPagination(search_text, selected_location, setNoPaginationLoader))
                        }
                      },
                      () => {
                        setDeleting(false);
                        // toast.error(apiErrorMessage,{ toastId: "toast" });
                      }
                    )
                  );
                }}
              />
            </div>
          </div>
        </Popup>
      )}
      <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(7,minmax(155px,1fr))]">
        <div
          className={`before-left-arrow cursor-pointer ${ShowDetails == item.id
            ? "before-left-arrow-open"
            : "before-left-arrow-close"
            }`}
          onClick={() => {
            setShowDetails(ShowDetails != item.id ? item.id : undefined);
          }}
        >
          <div className="flex items-center gap-3">
            <div
              className="flex items-center justify-center h-[3rem] w-[3rem] object-cover rounded-full bg-gray-200 bg-center bg-cover bg-no-repeat"
              style={{
                backgroundImage: `url('${item.cover_image ? item.cover_image : ""
                  }')`,
              }}
            >
              {!item.cover_image && (
                <span>
                  <Svgs.Products />
                </span>
              )}
            </div>
            <p className="text-sm flex-1 line-clamp-2 pr-3">{item?.name}</p>
          </div>
        </div>
        {
          <div className="flex items-center">
            <p className="text-sm">
              {item?.location_quantities?.filter(
                ({ location }) => location === selected_location
              )[0]?.available_quantity || "0"}
            </p>
          </div>
        }

        {
          <div className="flex items-center">
            <p className="text-sm">
              {item?.stocktransfer || "----"}
            </p>
          </div>
        }

        <div className="flex items-center">
          <p className="text-sm">
            {item?.category?.name ? item?.category?.name : "-------"}
          </p>
        </div>
        <div className="flex items-center">
          <p className="text-sm">
            {/* {item?.consumed?.find(
              ({ location }) => location?.id === selected_location
            )?.quantity || "0"} */}
            {item?.consumed || "----"}
          </p>
        </div>
        <div className="flex items-center">
          <p className="text-sm">
            <StatusBtn
              profile_status={
                item?.is_active ?
                  selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                  :
                  selectedLanguage?.name == "English" ? "In-Active" : translation[460]?.value ? translation[460]?.value : "In-Active"
              }
              status={item?.is_active ? "active" : "inActive"}
            />
          </p>
        </div>
        {!employee ? (
          <div className="flex items-center gap-2">
            <div
              className="h-[2.5rem] w-[2.5rem] cursor-pointer border rounded-full flex items-center justify-center"
              onClick={() => {
                // setEditPopup(true);
                getSingleProductItemDetail(item?.id)
              }}
            >
              <span>
                <Svgs.Pen />
              </span>
            </div>
            {!hideDeleteIcon &&
              <div
                onClick={() => {
                  setDelPopup(item.id);
                }}
                className="h-[2.5rem] w-[2.5rem] cursor-pointer border rounded-full flex items-center justify-center"
              >
                <span>
                  {" "}
                  <Svgs.Delete />{" "}
                </span>
              </div>
            }
          </div>
        ) : (
          ""
        )}

      </div>
      {ShowDetails == item.id ? (
        <div className="scale-in-center">
          <div className="bg-[#FAF9FF] px-6 py-3 inline-grid xl:grid grid-cols-[repeat(4,minmax(180px,1fr))]">
            <div className="flex items-center gap-3 col-span-1">
              <div
                className="flex items-center justify-center h-[3rem] w-[3rem] object-cover rounded-lg bg-gray-200 bg-center bg-cover bg-no-repeat"
                style={{
                  backgroundImage: `url('${item.cover_image ? item.cover_image : ""
                    }')`,
                }}
              >
                {!item.cover_image && <Svgs.Products />}
              </div>
              <div className="flex flex-col gap-1 flex-1">
                <p className="text-black font-semibold">{item?.name}</p>
                {/* <p className="text-sm text-[#969BA0]">{item?.category?.name}</p>
                <p className="text-black font-semibold">
                  {currency} {item?.full_price}
                </p> */}
              </div>
            </div>
            <div className="grid grid-cols-3 col-span-3 gap-y-5 gap-x-3">
              <div className="flex flex-col gap-1">
                <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Barcode ID" : translation[934]?.value ? translation[934]?.value : "Barcode ID"}</p>
                <p className="text-black font-semibold">
                  {(item?.barcode_id && item?.barcode_id != 'null') ? item?.barcode_id : "-------"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? 'Brand Name' : translation[428]?.value ? translation[428]?.value : "Brand Name"}</p>
                <p className="text-black font-semibold">
                  {item?.brand?.name ? item?.brand?.name : "-------"}
                </p>
              </div>
              <div className="flex items-end justify-between">
                <div className="flex flex-col gap-1">
                  <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Vendor" : translation[962]?.value ? translation[962]?.value : "Vendor"}</p>
                  <p className="text-black font-semibold">
                    {item?.vendor?.vendor_name
                      ? item?.vendor?.vendor_name
                      : "-------"}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Transferred" : translation[999]?.value ? translation[999]?.value : "Transferred"}</p>
                  <p className="text-black font-semibold">
                    {item?.stocktransfer || "----"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Current Stock" : translation[998]?.value ? translation[998]?.value : "Current Stock"}</p>
                <p className="text-black font-semibold">
                  {item?.location_quantities?.find(
                    ({ location }) => location === selected_location
                  )?.available_quantity || "0"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Consumed" : translation[1000]?.value ? translation[1000]?.value : "Consumed"}</p>
                <p className="text-black font-semibold">
                  {/* {item?.consumed?.find(
                    ({ location }) => location?.id === selected_location
                  )?.quantity || "0"} */}
                  {item?.consumed || "----"}
                </p>
              </div>

              <div className="flex items-start justify-between">
                <div className="flex flex-col gap-1">
                  <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Price" : translation[200]?.value ? translation[200]?.value : "Price"}</p>
                  <p className="text-black font-semibold">
                    {currency} {item?.currency_retail_price?.filter(cur => cur?.currency === locationDetail?.currency?.id)?.map(prce => prce?.retail_price || "0")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ProductCard;
