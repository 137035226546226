import React, { useEffect, useState } from 'react'
import Svgs from '../../../../Assets/svgs'
import CheckboxCard from '../../Elements/CheckboxCard'
import Dropwdown from '../../Elements/Dropwdown'
import LoginBtn from '../../Elements/LoginBtn'
import { useNavigate } from 'react-router-dom'
import SupportCard from '../Clients/Elements/SupportCard'
import { useDispatch, useSelector } from 'react-redux'
import useSetDataInLocalStorage from '../../../../Hooks/useSetDataInLocalStorage'
import PromotionCheckoutCard from './Checkout/PromotionChackoutCard'
import RewardsCheckoutCard from './Checkout/RewardsCheckoutCard'
import MemberShipCheckoutCard from './Checkout/MemberShipCheckoutCard'
import SubscriptionCheckoutCard from './Checkout/SubscriptionCheckoutCard'
import BookingDetailCard from './Elements/BookingDetailsCard'
import VoucherCheckoutPopup from './Checkout/VouchersCheckoutCard'

const Discounts = () => {
    const state = useSelector(state => state)
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const businessData = getDataFromLocalStorage("business_data");

    const [appointmentData, setAppointmentData] = useState('PROMOTIONS')

    const AppointmentDatahandler = (e) => {
        const { name, value } = e.target
        setAppointmentData({
            ...appointmentData,
            [name]: value
        })
    }


    return (
        <>
            <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
                <div className='flex flex-col gap-4'>
                    <h2 className="text-primary font-bold lg:text-3xl text-xl">Checkout</h2>
                    <div className='grid lg:grid-cols-5 mt-[3rem] gap-[3rem]'>
                        <div className='flex flex-col gap-5 lg:col-span-3'>
                            <h2 className="text-primary font-bold text-xl">Avail Discounts</h2>

                            <div className='border rounded-lg p-4 flex flex-col gap-4 text-sm'>
                                <h2 className='font-semibold'>Click to view your discounts</h2>
                                <div className='grid xl:grid-cols-3 md:grid-cols-2 gap-2'>
                                    <CheckboxCard
                                        checked={appointmentData == 'PROMOTIONS'}
                                        onClick={() => {
                                            setAppointmentData('PROMOTIONS')
                                        }}
                                        id={'PROMOTIONS'}
                                        title={
                                            <div className='flex items-center gap-2'>
                                                <Svgs.PromotionsBadge />
                                                <p className='font-semibold text-sm'>Promotions</p>
                                            </div>
                                        }
                                    />
                                    <CheckboxCard
                                        checked={appointmentData == 'REWARDS'}
                                        onClick={() => {
                                            setAppointmentData('REWARDS')
                                        }}
                                        id={'REWARDS'}
                                        title={
                                            <div className='flex items-center gap-2'>
                                                <Svgs.RewardBadge />
                                                <p className='font-semibold text-sm'>Rewards</p>
                                            </div>
                                        } />
                                    <CheckboxCard
                                        checked={appointmentData == 'VOUCHERS'}
                                        onClick={() => {
                                            setAppointmentData('VOUCHERS')
                                        }}
                                        id={'VOUCHERS'}
                                        title={
                                            <div className='flex items-center gap-2'>
                                                <Svgs.VoucherBadge />
                                                <p className='font-semibold text-sm'>Vouchers</p>
                                            </div>
                                        } />
                                    <CheckboxCard
                                        checked={appointmentData == 'MEMBERSHIPS'}
                                        onClick={() => {
                                            setAppointmentData('MEMBERSHIPS')
                                        }}
                                        id={'MEMBERSHIPS'}
                                        title={
                                            <div className='flex items-center gap-2'>
                                                <Svgs.MembershipsBadge />
                                                <p className='font-semibold text-sm'>Memberships</p>
                                            </div>
                                        } />
                                    <CheckboxCard
                                        checked={appointmentData == 'SUBSCRIPTIONS'}
                                        onClick={() => {
                                            setAppointmentData('SUBSCRIPTIONS')
                                        }}
                                        id={'SUBSCRIPTIONS'}
                                        title={
                                            <div className='flex items-center gap-2'>
                                                <Svgs.SubscriptionsBadge />
                                                <p className='font-semibold text-sm'>Subscriptions</p>
                                            </div>
                                        } />
                                </div>
                            </div>

                            {
                                appointmentData == 'PROMOTIONS' &&
                                <>
                                    <PromotionCheckoutCard />

                                </>
                            }
                            {
                                appointmentData == 'REWARDS' &&
                                <>
                                    <RewardsCheckoutCard />
                                </>
                            }
                            {
                                appointmentData == 'MEMBERSHIPS' &&
                                <>
                                    <MemberShipCheckoutCard />
                                </>
                            }
                            {
                                appointmentData == 'SUBSCRIPTIONS' &&
                                <>
                                    <SubscriptionCheckoutCard />
                                </>
                            }
                            <LoginBtn
                                text='Next'
                                disabled={(!appointmentData || Object.keys(appointmentData).length == 0 || !appointmentData?.client) ? true : false}
                                className="w-fit"
                            />
                        </div>
                        <div className='lg:col-span-2 flex flex-col gap-6'>
                            <BookingDetailCard />
                            <SupportCard />
                        </div>
                    </div>
                </div>
            </div>
            {
                appointmentData == 'VOUCHERS' &&
                <VoucherCheckoutPopup
                    onClose={() => {
                        setAppointmentData({ ...appointmentData, discount_type: 'VOUCHERS' })
                    }}
                />
            }
        </>
    )
}

export default Discounts