import axios from "axios";

import {
  BASE_URL,
  add_stock_transfer_stock,
  delete_stock_transfer_url,
  get_stock_transfer_list_url,
  update_stock_transfer_url,
} from "../api_variables";

export const handleStockTransfer = async (
  data,
  access_token,
  success,
  fail
) => {
  try {
    const result = await axios.post(
      `${BASE_URL}${add_stock_transfer_stock}`,
      data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    success && success()
    return result;
  } catch (error) {
    fail && fail()
    return error;
  }
};

export const fetchStockTransferList = async (currentPage, search_text, access_token) => {
  const current_Page = currentPage ? `?page=${currentPage}` : '';
  const search = search_text ? `&search_text=${search_text}` : ''
  try {
    const result = await axios.get(
      `${BASE_URL}${get_stock_transfer_list_url}${current_Page}${search}`,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const fetchStockTransferListWithNoPagination = async (search_text, access_token) => {
  const search = search_text ? `&search_text=${search_text}` : ''
  try {
    const result = await axios.get(`${BASE_URL}${get_stock_transfer_list_url}?no_pagination=true${search}`,
      {
        headers: { Authorization: `Token ${access_token}` },
      });
    return result;
  } catch (error) {
    return error;
  }
};

export const deleteStockTransferRecode = async ({ id, access_token }) => {
  try {
    const result = await axios.delete(
      `${BASE_URL}${delete_stock_transfer_url}`,
      {
        headers: { Authorization: `Token ${access_token}` },
        data: { id: id },
      }
    );

    return result;
  } catch (error) {
    return error;
  }
};

export const handleUpdateStockTransfer = async (
  data,
  access_token,
  success,
  fail
) => {
  try {
    const result = await axios.put(
      `${BASE_URL}${update_stock_transfer_url}`,
      data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    success && success();
    return result;
  } catch (error) {
    fail && fail();
    return error;
  }
};
