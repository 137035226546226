import React, { useEffect, useState } from 'react'
import Svgs from '../../../../../../Assets/svgs';
import CircleIcon from '../../../../Elements/CircleIcon';
import Dropwdown from '../../../../Elements/Dropwdown';
import LoginBtn from '../../../../Elements/LoginBtn';
import LoginInput from '../../../../Elements/LoginInput';
import Popup from '../../../../Elements/Popup';
import useCategory from '../Category/useCategory';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories, getAllCategoriesWithNoPagination } from 'Adapters/Api/categories';

export const CategoryPopup = ({ edit_mode, edit_data, onClose, onSubmit }) => {
    const { AddCategory,
        categoryData, categoryModalToggler,
        categoryHandleChange, onCategorySumbit,
        onBlurHandler, errors,
        categoryDeletehandler, categoryEdithandler,
        setIsEdit, loading, setCategory
    } = useCategory();
    const { name, is_active } = categoryData;

    const state = useSelector((state) => state);

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    useEffect(() => {
        if (edit_mode && edit_data) {
            setCategory({
                name: edit_data.name,
                is_active: edit_data.is_active,
                id: edit_data.id
            })
            setIsEdit(true)
        }
    }, [edit_mode])

    return (
        <>
            <Popup
                heading={edit_mode
                    ? selectedLanguage?.name == "English" ? "Edit Category" : translation[894]?.value ? translation[894]?.value : "Edit Category"
                    : selectedLanguage?.name == "English" ? "Add Category" : translation[887]?.value ? translation[887]?.value : "Add Category"
                }
                onclose={() => { onClose && onClose() }}>
                <div className="flex flex-col gap-4">
                    <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
                        <CircleIcon>
                            <Svgs.Inventory_Category_add />
                        </CircleIcon>
                        <div className="flex flex-col gap-1 lg:text-left text-center">
                            <h2 className="text-primary font-semibold text-xl">
                                {selectedLanguage?.name == "English" ? "Category Details" : translation[888]?.value ? translation[888]?.value : "Category Details"}
                            </h2>
                            <p className="text-[#7B7B7B] text-sm">
                                {selectedLanguage?.name == "English" ? "Add categories related to your business" : translation[890]?.value ? translation[890]?.value : "Add categories related to your business"}
                                <br className="hidden md:block" />
                                {selectedLanguage?.name == "English" ? "and products." : translation[891]?.value ? translation[891]?.value : "and products."}
                            </p>
                        </div>
                    </div>

                    <LoginInput
                        name={"name"}
                        value={name}
                        onChange={(e) => categoryHandleChange(e)}
                        onBlur={() => onBlurHandler({ name: name })}
                        error={errors.name}
                        // charactersMaxLength={25}
                        title={selectedLanguage?.name == "English" ? "Category Name" : translation[892]?.value ? translation[892]?.value : "Category Name"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter category name" : translation[893]?.value ? translation[893]?.value : "Enter category name"}
                        onEnterSubmit={
                            () => {
                                onCategorySumbit(() => { onClose && onClose() })
                            }}
                    />
                    <Dropwdown
                        required={true}
                        name={"is_active"}
                        value={is_active}
                        onChange={(e) => categoryHandleChange(e, "is_active")}
                        // onBlur={() => onBlurHandler({ is_active: is_active.value })}
                        error={errors.is_active}
                        title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[483]?.value : "Select Status"}
                        options={[
                            {
                                value: true,
                                label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                            },
                            {
                                value: false,
                                label: selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                            },
                        ]}
                    />

                    <div className="flex items-center justify-start mt-[2rem]">
                        <LoginBtn
                            loading={loading}
                            disabled={loading}
                            text={edit_mode
                                ? selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                                : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
                            }
                            onClick={() => {
                                onCategorySumbit(() => {
                                    onClose && onClose()
                                    onSubmit && onSubmit()
                                })
                            }}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

const AddCategoryBtn = (props) => {
    const [cat_popup, setCatPopup] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setCatPopup(props.open)
    }, [props.open])

    const state = useSelector((state) => state);

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>
            {!props.open &&
                <LoginBtn
                    onClick={() => {
                        setCatPopup(true);
                    }}
                    className={`rounded-lg ${props.border ? 'border-2 border-primary' : ''}`}
                    bg={props.fill ? 'bg-primary' : 'bg-white'}

                // border={}
                >
                    <div className={`flex items-center gap-2 ${props.fill ? 'text-white' : 'text-[#101928]'}`}>
                        {
                            props.icon &&
                            <Svgs.Plus color={props.fill ? '#fff' : '#101928'} />
                        }
                        {
                            props.showText &&
                            <p className="font-semibold">
                                {selectedLanguage?.name == "English" ? "Add Category" : translation[887]?.value ? translation[887]?.value : "Add Category"}
                            </p>
                        }
                    </div>
                </LoginBtn>
            }
            {
                cat_popup &&
                <CategoryPopup
                    onClose={() => {
                        setCatPopup(false)
                    }}
                    onSubmit={() => {
                        props.setCurrentPage(1)
                        dispatch(getAllCategories(1, props.search_text))
                        dispatch(getAllCategoriesWithNoPagination())
                    }}
                />
            }
        </>
    )
}




AddCategoryBtn.defaultProps = {
    fill: true,
    icon: true,
    border: true,
    open: false,
    showText: true
}

export default AddCategoryBtn