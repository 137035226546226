import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import React, { useState } from "react";
import LocationSelection from "new-components/location";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Svgs from "Assets/svgs";
import useHolidayLeaveManagment from "./helper";
import Holiday from "./Holiday";
import Leave from "./Leave";

const HolidayLeaveManagement = () => {
    const { translation, selectedLanguage, setCreateHoliday, CreateHoliday, CreateLeave, setCreateLeave } = useHolidayLeaveManagment()
    const [active_tab, setActiveTab] = useState("holiday")
    const tabList = [
        {
            value: "holiday",
            label: selectedLanguage?.name == "English" ? "holiday" : translation[1473]?.value ? translation[1473]?.value : "holiday"
        },
        {
            value: "leave",
            label: selectedLanguage?.name == "English" ? "leave" : translation[1487]?.value ? translation[1487]?.value : "leave"
        },

    ];
    const tabsList = {
        holiday: <Holiday CreateHoliday={CreateHoliday} setCreateHoliday={setCreateHoliday} />,
        leave: <Leave CreateLeave={CreateLeave} setCreateLeave={setCreateLeave} />,
    };
 
    return <>

        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
            <Breadcrumb first={selectedLanguage?.name == "English" ? "HRM" : translation[1469]?.value ? translation[1469]?.value : "HRM"} url={"/dashboard/hrm/"} second={selectedLanguage?.name == "English" ? "Holiday & Leave Management" : translation[1486]?.value ? translation[1486]?.value : "Holiday & Leave Management"} />
            <div className="flex items-center gap-3 justify-between xs:flex-row flex-col">
                <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">
                    <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
                        {selectedLanguage?.name == "English" ? "Holiday & Leave Management" : translation[1486]?.value ? translation[1486]?.value : "Holiday & Leave Management"}
                    </h2>
                    <LocationSelection />
                </div>
                <div className="self-end xs:self-center">
                    {
                        active_tab && active_tab == "holiday" ? (

                            <LoginBtn
                                onClick={() => {
                                    setCreateHoliday(!CreateHoliday);
                                }}
                                text={
                                    <div className="flex items-center gap-3">
                                        <div>
                                            <Svgs.Plus />
                                        </div>
                                        <div>
                                            <p>{selectedLanguage?.name == "English" ? "Add Holiday" : translation[1474]?.value ? translation[1474]?.value : "Add Holiday"}</p>
                                        </div>
                                    </div>
                                }
                            />
                        ) : ''
                    }
                </div>
            </div>
            <div className={"border-b mt-[1rem]"}>
                <div className="flex items-center gap-6">
                    {tabList?.length > 0 &&
                        tabList.map((tab, index) => {
                            return (
                                <div className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${active_tab == tab.value && "font-semibold border-primary"}`}
                                    onClick={() => {
                                        setActiveTab(tab.value)
                                    }}
                                    key={index}
                                >
                                    <h2 className="text-primary capitalize">{tab.label}</h2>
                                </div>
                            );
                        })}
                </div>
            </div>

            <div className="mt-[3rem] mb-[3rem]">{active_tab && tabsList[active_tab]}</div>
        </div>
    </>;
};

export default HolidayLeaveManagement;
