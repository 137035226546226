
import { CLIENT } from './../../ActionTypes/clientActionTypes/index';

const initialState = {
    clients: [],
    allClients: [],
    pages: 0,
    allCount: 0,
    checkout_client: {},
    clients_updated: false,
    client_sub: [],
    client_rewards: [],
    client_rewards_updated: false,
    client_promo: [],
    client_promo_updated: false,
    client_id: "",
    loyalty_points: [],
    loyaltu_points_updated: false,
    loyalty_points_customer_log: [],
    loyalty_points_detailed_log: [],
    logs_fetched: false,
    clientsRedux: [],
    clientReduxNoPagination: [],
    clientPaginationData: {},
    clientsReduxWithoutSearch: [],
    cashback: [],
    cashback_updated: false,
};


const ClientReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case CLIENT.SET_SPECIFIC_CLIENTS_FOR_CHECKOUT:
            return {
                ...state,
                checkout_client: {
                    'clients': payload.clients,
                    'client_discount': payload.client_discount
                }
            }
        case CLIENT.DELETE_PROMOTION:
            return {
                ...state,
                client_promo: [
                    ...state.client_promo.filter(itm => itm.id != payload.id)
                ]
            }
        case CLIENT.CREATE_PROMOTION:
            return {
                ...state,
                client_promo: [
                    ...state.client_promo,
                    payload
                ]
            }

        case CLIENT.GET_CLIENT:
            return {
                ...state,
                clients: payload?.response?.client,
                clients_updated: true,
                pages: payload?.response?.pages,
                allCount: payload?.response?.count
            };
        case CLIENT.GET_CLIENT_DROPDOWN:
            return {
                ...state,
                clients: payload?.response?.clients,
                clients_updated: true,
                pages: payload?.response?.pages,
                allCount: payload?.response?.count
            };

        case CLIENT.GET_CLIENT_DROPDOWN_WITHOUT_PAGINATION:

            let paginationData = payload?.isPaginated
                ? {
                    pages: Math.ceil(+payload?.pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page,
                    isSearched: payload?.is_searched,
                    is_filtered: true
                } : {
                    ...state?.clientPaginationData
                }
            return {
                ...state,
                clientReduxNoPagination: payload?.response?.clients,
                clientPaginationData: paginationData
            }

        case CLIENT.GET_CLIENT_DROPDOWN_PAGINATION:
            const uniqueClientsWithSearch = Array.from(
                new Set([...state?.clientsRedux, ...payload?.response?.clients].map(client => client.id))).map(id => {
                    return [...state?.clientsRedux, ...payload?.response?.clients].find(client => client.id === id);
                });

            const uniqueClientsWithoutSearch = Array.from(
                new Set([...state?.clientsReduxWithoutSearch, ...payload?.response?.clients]?.map(client => client.id))).map(id => {
                    return [...state?.clientsReduxWithoutSearch, ...payload?.response?.clients]?.find(client => client.id === id);
                });

            return {
                ...state,
                clients_updated: true,

                // clientsRedux: payload?.is_searched && payload?.current_page == 1 ? payload?.response?.clients
                //     : [...state?.clientsReduxWithoutSearch, ...payload?.response?.clients],

                clientsRedux: payload?.is_searched || payload?.isFiltered || payload?.is_filtered
                    ? (Math.ceil(+payload?.pages) > 1 && +payload?.current_page > 1)
                        ? uniqueClientsWithSearch
                        : payload?.response?.clients
                    : uniqueClientsWithoutSearch,

                // : payload?.current_page == 1
                //     ? payload?.response?.clients
                //     : [...state?.clientsRedux, ...payload?.response?.clients],

                clientsReduxWithoutSearch: payload?.current_page == 1 ? payload?.response?.clients
                    : uniqueClientsWithoutSearch,

                clientPaginationData: {
                    pages: Math.ceil(+payload?.pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page,
                    isSearched: payload?.is_searched,
                    is_filtered: payload?.is_filtered
                }
            };

        case CLIENT.GET_CLIENT_WITHOUT_PAGINATION:
            return {
                ...state,
                allClients: payload?.response?.client,
                clients_updated: true,
            };
        case CLIENT.CLIENT_PROMO:
            return {
                ...state,
                client_promo: payload,
                client_promo_updated: true

            };


        case CLIENT.ADD_CLIENT:
            return {
                ...state,
                clients: [payload, ...state.clients],
            };


        case CLIENT.DELETE_CLIENT:
            let result = state?.clients?.filter((item) => item.id !== payload);
            return {
                ...state,
                clients: [...result],
            };


        case CLIENT.UPDATE_CLIENT:
            let updateList = state.clients.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })

            return {
                ...state,
                clients: [...updateList],
            };


        case CLIENT.CLIENT_ID:
            return {
                ...state,
                client_id: payload,
            };
        case CLIENT.CLIENT_SUB:
            return {
                ...state,
                client_sub: payload,
            };
        case CLIENT.CLIENT_PROMO:
            return {
                ...state,
                client_promo: payload,
            };

        // rewards sections start
        case CLIENT.GET_CLIENT_REWARDS:
            return {
                ...state,
                client_rewards: payload,
                client_rewards_updated: true,
            };


        case CLIENT.ADD_CLIENT_REWARDS:
            return {
                ...state,
                client_rewards: [...state.client_rewards, payload],
            };


        case CLIENT.UPDATE_CLIENT_REWARDS:
            let updateRewardList = state.client_rewards.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })

            return {
                ...state,
                client_rewards: [...updateRewardList],
            };


        case CLIENT.DELETE_CLIENT_REWARDS:
            let res = state?.client_rewards?.filter((item) => item.id !== payload);
            return {
                ...state,
                client_rewards: [...res],
            };
        // rewards sections end

        case CLIENT.CREATE_LOAYALTY_POINT:
            return {
                ...state,
                loyalty_points: [payload, ...state?.loyalty_points]
            }


        case CLIENT.GET_LOAYALTY_POINTS:
            return {
                ...state,
                loyalty_points: payload,
                loyaltu_points_updated: true,
            }


        case CLIENT.UPDATE_LOAYALTY_POINT:
            return {
                ...state,
                loyalty_points: state?.loyalty_points?.map((item) => {
                    if (item.id === payload.id) {
                        return payload
                    } else {
                        return item;
                    }
                })
            };


        case CLIENT.DELETE_LOAYALTY_POINT:
            return {
                ...state,
                loyalty_points: state?.loyalty_points?.filter((item) => item.id !== payload)
            };



        case CLIENT.GET_LOAYALTY_POINTS_CUSTOMER_LOG:
            return {
                ...state,
                loyalty_points_customer_log: payload,
                logs_fetched: true,
            }

        case CLIENT.GET_LOAYALTY_POINTS_DETAILED_LOG:
            return {
                ...state,
                loyalty_points_detailed_log: payload,
                logs_fetched: true,
            }

        // Client Cashback starts
        case CLIENT.CREATE_CASHBACK:
            return {
                ...state,
                cashback: [payload, ...state?.cashback]
            }


        case CLIENT.GET_CASHBACK:
            return {
                ...state,
                cashback: payload,
                cashback_updated: true,
            }


        case CLIENT.UPDATE_CASHBACK:
            return {
                ...state,
                cashback: state?.cashback?.map((item) => {
                    if (item.id === payload.id) {
                        return payload
                    } else {
                        return item;
                    }
                })
            };

        case CLIENT.DELETE_CASHBACK:
            return {
                ...state,
                cashback: state?.cashback?.filter((item) => item.id !== payload)
            };
        // Client Cashback ends

        default:
            return state;
    }
};
export default ClientReducer;
