import React from "react";
import Svgs from "Assets/svgs";
import { useSelector } from "react-redux";

const NotFound = (props) => {
  const state = useSelector((state) => state)
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  return (
    <div className="flex items-center justify-center h-[22rem]">
      <div className="flex flex-col gap-3 justify-center text-center">
        <div className="w-fit mx-auto">
          <Svgs.ServiceNotFound />
        </div>
        <h1 className="text-primary font-semibold text-lg normal-case">
          {selectedLanguage?.name == "English" ? "No record found!" : translation[27]?.value ? translation[27]?.value : "No record found!"}
        </h1>
      </div>
    </div>
  );
};

export default NotFound;
