import { useSelector } from "react-redux"
import Svgs from "../../Assets/svgs"
import { useNavigate } from "react-router-dom"

const EmployeeInsightNotFound = ({ className }) => {
    const navigate = useNavigate()
    const state = useSelector((state) => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>
            <div className={`flex items-center justify-center my-5 ${className}`}>
                <div className="flex flex-col gap-3 justify-center text-center">
                    <div className="w-fit mx-auto">
                        <Svgs.EmployeeInsightNotFound />
                    </div>
                    <h1 className="text-primary font-semibold text-lg normal-case">
                        {selectedLanguage?.name == "English" ? "No employee insight available!" : translation[1253]?.value ? translation[1253]?.value : "No employee insight available!"}
                    </h1>
                    <p className="text-sm text-[#555555] mb-2">
                        {selectedLanguage?.name == "English" ? "No employee insights available. Optimize team productivity, take action!" : translation[1304]?.value ? translation[1304]?.value : "No employee insights available. Optimize team productivity, take action!"}
                    </p>
                </div>
            </div>
        </>
    )
}

EmployeeInsightNotFound.defaultProps = {
    className: ''
}

export default EmployeeInsightNotFound