import React from 'react'
import useAdminBooking from './../helper';

function useOpeningHours() {
    const { locationInfo, setLocationInfo } = useAdminBooking();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLocationInfo((prev) => ({
            ...prev,
            [name]: value
        }))
    }


    return {
        setLocationInfo,
        locationInfo,
        handleChange,
    }
}

export default useOpeningHours
