import { UpdateServiceReversalStatus, getAllReversalRequest } from "Adapters/Api/apointments";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


let timeOutId = undefined
function useReversalRequestHelper() {
    const dispatch = useDispatch();

    const state = useSelector((state) => state);
    const { all_reversal } = state.appointment;
    const count = state?.appointment?.reversalCount ? state?.appointment?.reversalCount : 0
    const allCount = state?.appointment?.reversalAllCount ? state?.appointment?.reversalAllCount : 0
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const isPermissionUpdated = state?.user?.isPermissionUpdated;

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");

    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);
    const [Search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1)
    const [loader, setLoader] = useState(true)
    const [toggleDateRange, setToggleDateRange] = useState(false)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [ShowDetails, setShowDetails] = useState(-1);
    const [dropdownStatus, setDropdownStatus] = useState('')
    const [loadingPermission, setLoadingPermission] = useState(true)
    const [acceptPopup, setAcceptPopup] = useState(false)
    const [reversalSubmitClick, setReversalSubmitClick] = useState(false)
    const [statusLoader, setStatusLoader] = useState({
        id: "",
        delete_loader: false,
        accept_loader: false
    })
    const [acceptPopupData, setAcceptPopupData] = useState({
        id: "",
        appointment_id: "",
        service_id: "",
        appointment_status: "",
        reversal_status: "accepted"
    })

    const PageSize = 10

    // let StatusOptions = [
    //     { label: selectedLanguage?.name == "English" ? 'All Appointments' : translation[1342]?.value ? translation[1342]?.value : "All Appointments", value: "" },
    //     { label: selectedLanguage?.name == "English" ? 'Booked' : translation[1429]?.value ? translation[1429]?.value : "Booked", value: "Booked" },
    //     { label: selectedLanguage?.name == "English" ? 'Started' : translation[469]?.value ? translation[469]?.value : "Started", value: "Started" },
    //     { label: selectedLanguage?.name == "English" ? 'Finished' : translation[1430]?.value ? translation[1430]?.value : "Finished", value: "Finished" },
    //     { label: selectedLanguage?.name == "English" ? 'Done' : translation[1431]?.value ? translation[1431]?.value : "Done", value: "Done" },
    //     { label: selectedLanguage?.name == "English" ? 'Cancelled' : translation[172]?.value ? translation[172]?.value : "Cancelled", value: "Cancelled" },
    //     // { label: selectedLanguage?.name == "English" ? 'Void' : translation[1339]?.value ? translation[1339]?.value : "Void", value: "Void" }
    // ];

    let AcceptStatusOptions = [
        { label: selectedLanguage?.name == "English" ? 'Booked' : translation[1429]?.value ? translation[1429]?.value : "Booked", value: "Booked" },
        { label: selectedLanguage?.name == "English" ? 'Void' : translation[1339]?.value ? translation[1339]?.value : "Void", value: "Void" },
    ];

    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])

    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);

    // useEffect(() => {
    //     if (client_fullName) {
    //         setSearch(client_fullName)
    //         const timeout = setTimeout(() => {
    //             localStorage.removeItem("client_search");
    //             return () => clearTimeout(timeout);
    //         }, 500);
    //     }
    // }, [client_fullName])

    useEffect(() => {
        setLoader(true)
        setCurrentPage(1)
    }, [dropdownStatus, selected_location])

    useEffect(() => {
        if (startDate && endDate) {
            setCurrentPage(1)
        }
    }, [startDate, endDate])

    useEffect(() => {
        setCurrentPage(1)
    }, [Search])

    useEffect(() => {
        let data = {}
        if (startDate && endDate) {
            data = { page: currentPage, location_id: selected_location, start_date: startDate, end_date: endDate, appointment_status: dropdownStatus }
        } else {
            data = { page: currentPage, location_id: selected_location, appointment_status: dropdownStatus }
        }
        if (selected_location) {
            if (Search === null) {
                setLoader(true)
                // Initial load with empty search_text
                getDataFromApi(data)
            } else {
                data = { ...data, search_text: Search }
                if (timeOutId) {
                    clearTimeout(timeOutId)
                }
                timeOutId = setTimeout(() => {
                    setLoader(true)
                    getDataFromApi(data)
                }, 500);
            }
        }
    }, [Search, selected_location, currentPage, startDate, endDate, dropdownStatus])

    const getDataFromApi = (data) => {
        dispatch(getAllReversalRequest(data, setLoader));
        // dispatch(getAllAppointmentsWithNoPagination(data, setNoPaginationLoader));
    }

    // let headers = [
    //     { label: "Booked_by", key: "booked_by" },
    //     { label: "Service", key: "service" },
    //     { label: "Booking_id", key: "booking_id" },
    //     { label: "Client", key: "client" },
    //     { label: "Booking_status", key: "booking_status" },
    //     { label: "Appointment type", key: "appointment_type" },
    //     { label: "Member", key: "member" },
    // ];

    // const csvReport = {
    //     filename: "Appointment History Report.csv",
    //     headers: headers,
    //     data: all_appointments_no_pagination?.map((itm) => {
    //         return {
    //             ...itm,
    //             booked_by: itm?.booked_by ? itm?.booked_by : "--------",
    //             booking_id: itm?.booking_id ? itm?.booking_id : "--------",
    //             client: itm?.client ? itm?.client : "--------",
    //             booking_status: itm?.appointment_status ? itm?.appointment_status : "--------",
    //             appointment_type: itm?.appointment_type ? itm?.appointment_type : "--------",
    //             member: itm?.member ? itm?.member : "--------",
    //             service: itm?.service ? itm?.service : "--------",
    //         };
    //     }),
    // };

    // const handleExportClick = () => {
    //     if (!isToastVisible) {
    //         if (all_appointments_no_pagination?.length > 0) {
    //             // setExportLoader(true)
    //             setTimeout(() => {
    //                 toast.success("File downloaded successfully", {
    //                     toastId: "toast",
    //                     onClose: () => setIsToastVisible(false)
    //                 });
    //                 setExportLoader(false)
    //             }, 300);
    //         } else {
    //             toast.error("No data to export", {
    //                 toastId: "toast",
    //                 onClose: () => setIsToastVisible(false),
    //             });
    //         }
    //         setIsToastVisible(true);
    //     }
    // };


    const updateAppointmentReversalStatus = (id, srvId, appointmentId) => {
        let submit = false
        setReversalSubmitClick(true)
        let payload = {}
        if (acceptPopup) {
            if (acceptPopupData?.appointment_status) {
                submit = true
            }
            payload = {
                request_status: acceptPopupData?.appointment_status,
                appointment_id: acceptPopupData?.appointment_id,
                service_id: acceptPopupData?.service_id,
                status: "accepted"
            }
            setStatusLoader(() => ({
                id: id,
                accept_loader: true,
                delete_loader: false,
            }));
        } else {
            submit = true
            payload = {
                appointment_id: appointmentId,
                service_id: srvId,
                status: "rejected"
            }
            setStatusLoader(() => ({
                id: id,
                delete_loader: true,
                accept_loader: false,
            }));
        }
        if (submit) {
            UpdateServiceReversalStatus(
                payload,
                (response) => {
                    let data = {}
                    if (startDate && endDate) {
                        data = { page: currentPage, location_id: selected_location, start_date: startDate, end_date: endDate, appointment_status: dropdownStatus }
                    } else {
                        data = { page: currentPage, location_id: selected_location, appointment_status: dropdownStatus }
                    }
                    setAcceptPopup(false)
                    getDataFromApi(data)
                    setReversalSubmitClick(false)
                    setStatusLoader(() => ({
                        id: id,
                        delete_loader: false,
                        accept_loader: false
                    }));
                },
                (result) => {
                    setReversalSubmitClick(false)
                    setStatusLoader(() => ({
                        id: id,
                        delete_loader: false,
                        accept_loader: false
                    }));
                })
        }
    }

    return {
        selectedLanguage, translation, loader, allCount, toggleDateRange, setToggleDateRange, startDate,
        setStartDate, endDate, setEndDate, setSearch, Search, all_reversal, currentPage, count,
        PageSize, setCurrentPage, ShowDetails, setShowDetails, acceptPopup, setAcceptPopup,
        loadingPermission, setAcceptPopupData, updateAppointmentReversalStatus, acceptPopupData, AcceptStatusOptions,
        statusLoader, reversalSubmitClick, employee, employeePermissions
    };
}

export default useReversalRequestHelper