import React from 'react'
import Svgs from '../../../Assets/svgs'

const SelectedOption = ({ name, img, rounded, onClick, showDelete,textColor }) => {
    return (
        <div
            className={`${textColor && textColor} flex items-center cursor-pointer gap-2 py-2 w-fit border ${rounded == 'full' && 'rounded-full px-4'} ${rounded == 'md' && 'rounded-md px-2'} border-primary bg-[#F8FBFB] ${img && '!px-1 !py-1'}`}>

            {
                img && <div className='h-[1.8rem] w-[1.8rem] rounded-md overflow-hidden'>
                    <img src={img} className="h-full w-full object-cover" />
                </div>
            }
            <p className={`${textColor ? textColor :  "text-primary"}  text-xs font-bold `}>{name}</p>
            {
                showDelete ? <span onClick={onClick}><Svgs.CirlceClose /></span> : ''
            }

        </div>
    )
}

SelectedOption.defaultProps = {
    showDelete: true,
    rounded: 'md',
}

export default SelectedOption