import React, { useState } from 'react';
import Svgs from 'Assets/svgs';
import { error_message } from 'Constants/Variables';
import { useEffect } from 'react';

const FloatInput = ({
    onEnterSubmit,
    rightIcon,
    leftIcon,
    title,
    required,
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    error,
    setFormErrors,
    setErrors,
    disabled,
    parentClass,
    inputPadding,
    min,
    max,
    isPercentage,
    isCapitalized,
    index,
    errorClass,
    charactersMaxLength }) => {
    const [sanitizedValue, setSanitizedValue] = useState('');
    const parsedPlaceholder = isCapitalized ? placeholder : placeholder && placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();

    useEffect(() => {
        let inputValue = value ? (typeof value == 'string') ? value?.replace(/[^0-9.]/g, '') : value : ''
        // // Allow only one dot in the value
        // const parts = inputValue ? inputValue?.split('.') : '';
        // if (parts) {
        //     const firstPart = parts.shift(); // Get the first part (before the first dot)
        //     inputValue = firstPart + (parts.length > 0 ? '.' + parts.join('') : '');
        // }
        setSanitizedValue(inputValue)
    }, [value])

    const handleInputChange = (e) => {
        // Allow only digits, one dot, and ignore other characters
        let inputValue = e.target.value ? e.target.value?.replace(/[^0-9.]/g, '') : '';
        e.target.value = inputValue

        // validation for maximum characters length
        if (charactersMaxLength) {
            inputValue = e.target.value?.slice(0, charactersMaxLength)
            e.target.value = inputValue
        }

        // Allow only one dot in the value
        const parts = e.target.value?.split('.');
        const firstPart = parts.shift(); // Get the first part (before the first dot)
        inputValue = firstPart + (parts.length > 0 ? '.' + parts.join('') : '');
        e.target.value = inputValue

        // validation on percenatage 
        if (isPercentage) {
            if (+inputValue > 100) {
                inputValue = 100
                e.target.value = 100
                // const errors = [];
                // const fieldErrors = { discount: "Percentage should not be greater than 100" }
                // errors[index] = fieldErrors;
                // setFormErrors && setFormErrors(errors)

                // setErrors && setErrors((prev) => ({
                //     ...prev,
                //     [e.target.name]: "Percentage should not be greater than 100"
                // }));
            } else {
                e.target.value = inputValue
                // const errors = [];
                // const fieldErrors = { discount: "" }
                // errors[index] = fieldErrors;
                // setFormErrors && setFormErrors(errors)

                // setErrors && setErrors((prev) => ({
                //     ...prev,
                //     [e.target.name]: "" // Remove the error message
                // }));
            }
        }

        setSanitizedValue(e.target.value)

        // Call the external onChange handler (if provided)
        if (typeof onChange === "function") {
            onChange(e);
        }
    };

    const HandleKeyPress = (e) => {
        if (e.key.toLowerCase() == "enter") {
            onEnterSubmit();
        }

        // regular expression pattern for only numeric characters
        const onlyNumericCharsAllowed = /[a-zA-Z=!@#$%^&*()_+{}+×÷%≤≥≈≠∞€£¥""¢''/`"'\[\]:|;<>,?~\\-]/g;
        // Check your condition here to disallow special characters
        if (onlyNumericCharsAllowed.test(e.key) && e.key != "Backspace") {
            // If input doesn't match, prevent the default behavior (e.g., typing the invalid character)
            e.preventDefault();
        }
    };

    return (
        <div className={`${parentClass} flex flex-col gap-2 relative `}>
            {title != "" && (
                <div className="text-sm flex items-center gap-1">
                    <div>{title}</div> {required && title && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            )}
            {rightIcon ? (
                <div className="w-full relative">
                    <input
                        onKeyDown={HandleKeyPress}
                        min={min}
                        max={max}
                        name={name}
                        disabled={disabled}
                        maxLength={charactersMaxLength}
                        type="text"
                        value={sanitizedValue}
                        onChange={handleInputChange}
                        placeholder={parsedPlaceholder}
                        className={`${error && "!border-l-[0.375rem] !border-[#eb3b3b]"
                            } ${inputPadding} input w-full lg:pr-8 pr-7 `}
                    />
                    <div className="absolute top-1/2 -translate-y-1/2 right-3 text-sm input-right-icon">
                        {rightIcon}
                    </div>
                </div>
            ) : leftIcon ? (
                <div className="w-full relative">
                    <div className="absolute top-1/2 -translate-y-1/2 left-3">
                        {leftIcon}
                    </div>
                    <input
                        onKeyDown={HandleKeyPress}
                        min={min}
                        max={max}
                        name={name}
                        disabled={disabled}
                        maxLength={charactersMaxLength}
                        type="text"
                        value={sanitizedValue}
                        onChange={handleInputChange}
                        placeholder={parsedPlaceholder}
                        className={`${error && "!border-l-[0.375rem] !border-[#eb3b3b]"
                            } ${inputPadding} input w-full lg:pl-8 !pl-7`}
                    />
                </div>
            ) : (
                <input
                    onKeyDown={HandleKeyPress}
                    min={min}
                    max={max}
                    name={name}
                    disabled={disabled}
                    maxLength={charactersMaxLength}
                    type="text"
                    value={sanitizedValue}
                    onChange={handleInputChange}
                    placeholder={parsedPlaceholder}
                    className={`${error && "!border-l-[0.375rem] !border-[#eb3b3b]"
                        } ${inputPadding}  input `}
                />
            )}
            {error && typeof error == "string" ? (
                <p className={`text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end ${errorClass}`}>
                    <Svgs.I fill="#eb3b3b" />
                    {error}
                </p>
            ) : typeof error == "boolean" && error == true ? (
                <p className="text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                    <Svgs.I fill="#eb3b3b" /> {error_message}
                </p>
            ) : (
                ""
            )}
        </div>
    );
};

export default FloatInput;