import { useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import useValidations from 'Hooks/useValidations';
import useObjectKeys from 'Hooks/useObjectKeys';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { useEffect } from 'react';
import { createHoliday, updateHoliday } from 'Adapters/Api/holidays';
import { useMemo } from 'react';

let initialState = {
    name: '',
    note: '',
    start_date: '',
    end_date: null,
}

const useHolidayPopup = ({ setCreateHoliday, data, selectedLocation, setIsEdit, isEdit, successCallBack }) => {
    const { objectKeyConvertToArray } = useObjectKeys();
    const dispatch = useDispatch()
    const state = useSelector((state) => state);
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const business = getDataFromLocalStorage('business_data')

    const [holidayForm, setHolidayForm] = useState(initialState)
    // Date Range
    const [indexValue, setIndexValue] = useState()
    const [toggleDateRange, setToggleDateRange] = useState(false)
    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const [submitLoader, setSubmitLoader] = useState(false)

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    let minStartDateLimit = useMemo(() => {
        return moment().format('YYYY-MM-DD')
    }, []);

    let maxStartDateLimit = useMemo(() => {
        if (holidayForm?.end_date)
            return moment(holidayForm?.end_date).format('YYYY-MM-DD')
    }, [holidayForm?.end_date]);

    var minToDateLimit = useMemo(() => {
        return moment(holidayForm?.start_date).add(1, 'day').format('YYYY-MM-DD')
    }, [holidayForm?.start_date]);


    useEffect(() => {
        if (data && isEdit) {
            setHolidayForm({
                id: data?.id,
                name: data?.name,
                note: data?.note,
                start_date: data?.start_date ? moment(data?.start_date).format('YYYY-MM-DD') : null,
                end_date: data?.end_date ? moment(data?.end_date).format('YYYY-MM-DD') : null,
            })
        }
    }, [data, isEdit])

    const onChangeHandler = (e) => {
        if (e?.target) {
            const { name, value } = e.target;
            setHolidayForm((prev) => ({ ...prev, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: "" }))
        }
    }

    const onSubmitHandler = () => {
        const { name, note, start_date } = holidayForm

        let requiredFields = { name, start_date }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);

        if (objectKeyConvertToArray(result)?.length === 0) {
            setSubmitLoader(true)
            const success = () => {
                successCallBack && successCallBack()
                setHolidayForm(initialState)
                setCreateHoliday(false)
                setIsEdit(false)
                setSubmitLoader(false)
            }
            const fail = () => {
                setSubmitLoader(false)
            }
            if (isEdit) {
                dispatch(updateHoliday({ ...holidayForm, location: selectedLocation, business: business?.id }, access_token, success, fail))
            } else {
                dispatch(createHoliday({ ...holidayForm, location: selectedLocation, business: business?.id }, access_token, success, fail))
            }
        }
    }

    return {
        moment, submitLoader,
        indexValue, setIndexValue,
        setErrors, maxStartDateLimit, minStartDateLimit,
        holidayForm, onChangeHandler,
        toggleDateRange, setToggleDateRange, onBlurHandler,
        translation, selectedLanguage, errors,
        onSubmitHandler, minToDateLimit
    }
}

export default useHolidayPopup