/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { get_client_discount_promotion } from 'Adapters/Api/clientDiscountPromotion';

import { getSaleServices, getServiceGroup, getServiceGroupCalendar } from "Adapters/Api/saleServices";
import { get_business_brands } from "Adapters/Api/Brand";
// import { getProducts } from "Redux/TenantRedux/Actions/product";
// import { getsProducts } from "Adapters/Api/Products";
// import { getClient } from "Adapters/Api/clients";
import { useMemo } from 'react';
import { getCalendarEmployeeDropDownList } from 'Adapters/Api/employee';


const initialState = {
  current_month: 0,
  selected_month: "",
  appointment_date: "",
  appointments: [],
  date: "",
  client_type: "IN HOUSE",
  avail_offer: []
}
export const useGroupAppointment = ({ allSelectedClients, selectedId }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const [appointmentData, setAppointmentData] = useState(initialState);
  const [combinedAppointment, setCombinedAppointment] = useState([])


  const [availOfferPopup, setAvailOfferPopup] = useState(false);
  const [groupLoader, setGroupLoader] = useState(true);
  const discountPromotionData = state?.clientDiscountPromotion?.discountPromotions_updated;
  const discountPromotions = state?.clientDiscountPromotion?.discountPromotions;
  const { selected_location } = state?.locations;

  const serviceGroupRedux = state?.saleService?.servicesgroup;
  const servicesRedux = state?.saleService?.saleServices;
  const clientRedux = useSelector((state) => state?.client?.clients);

  useEffect(() => {
    !discountPromotionData && dispatch(get_client_discount_promotion())
  }, [discountPromotions?.length, !discountPromotionData])

  useEffect(() => {
    if (selected_location) {
      dispatch(getServiceGroupCalendar(selected_location, setGroupLoader));
    }
  }, [selected_location])

  useEffect(() => {
    if (selected_location) {
      dispatch(getCalendarEmployeeDropDownList(selected_location));
    }
  }, [selected_location])

  const addCombinedData = (success) => {
    const exist = combinedAppointment?.find(itm => itm?.client == selectedId)
    if (exist) {
      const prevData = combinedAppointment?.filter(itm => itm?.client != selectedId)
      setCombinedAppointment([...prevData, { ...appointmentData, client: selectedId, client_type: "IN HOUSE", }])
      success && success([...prevData, { ...appointmentData, client: selectedId, client_type: "IN HOUSE", }])
    } else {
      const prevData = combinedAppointment || []
      setCombinedAppointment([...prevData, { ...appointmentData, client: selectedId, client_type: "IN HOUSE", }])
      success && success([...prevData, { ...appointmentData, client: selectedId, client_type: "IN HOUSE", }])
    }
  }

  const addOffer = (data) => {
    setAppointmentData(data);
    setAvailOfferPopup(false);
  }

  const filterOffer = (data) => {
    setAppointmentData(data);
  }

  return {
    availOfferPopup,
    discountPromotionData,
    discountPromotions,
    appointmentData,
    selected_location,
    serviceGroupRedux,
    servicesRedux,
    clientRedux,
    combinedAppointment,
    setCombinedAppointment,
    initialState,
    setAppointmentData,
    setAvailOfferPopup,
    addOffer,
    filterOffer,
    setGroupLoader,
    addCombinedData
  }
}