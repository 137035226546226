
import { CLIENT_DISCOUNT_PROMOTION } from 'Redux/TenantRedux/ActionTypes/clientDiscountActionTypes';

const initialState = {
    discountPromotions: [],
    directFlat: [],
    specificCategoryGroup: [],
    purchase: [],
    specificBrandServiceGroup: [],
    spendSomeAmount: [],
    fixedPrice: [],
    freeService: [],
    bundleDiscount: [],
    retailPromotion: [],
    userRestricted: [],
    complimentaryVoucher: [],
    packages: [],
    coupons: [],
    discountPromotions_updated: false,
    avail_offers: {},
    avail_offers_updated_: false
};
// getting these types from backend
export const discountTypes = {
    directOrFlat: "Direct Or Flat",  //#1
    specificGroup: "Specific Group Discount",  //#2
    purchase: "Purchase Discount",  //#3
    specificBrandServiceGroup: "Specific Brand Discount",  //#4
    spendSomeAmount: "Spend_Some_Amount",  //#5
    fixedPrice: "Fixed_Price_Service",  //#6,
    freeService: "Mentioned_Number_Service", //#7
    bundleDiscount: "Bundle_Fixed_Service",   //#8
    retailPromotion: "Retail_and_Get_Service",    //#9
    userRestricted: "User_Restricted_discount", // #10
    complimentaryVoucher: "Complimentary_Discount", //#11
    packages: "Packages_Discount", //#12
    coupons: "Coupons_Discount", //#13
    Mentioned_Number_Service: "Mentioned_Number_Service",
}

const getDiscountGroup = (data, type) => data.filter(obj => obj.type === type)
const addDiscount = (payload, data) => {
    return [payload, ...data]
}
const update = (data, payload) => {
    return data.map((item) => {
        if (item.id === payload.id) {
            return payload
        } else {
            return item;
        }
    })
}
const deleteDiscount = (data, id) => {
    return data?.filter((item) => item.id !== id)
}
const ClientDiscountPromotionReducer = (state = initialState, action) => {
    const { type, payload } = action;
    let {
        discountPromotions,
        directFlat,
        specificCategoryGroup,
        purchase,
        specificBrandServiceGroup,
        spendSomeAmount,
        fixedPrice,
        freeService,
        bundleDiscount,
        retailPromotion,
        userRestricted,
        complimentaryVoucher,
        packages,
        coupons
    } = state;

    switch (type) {
        case CLIENT_DISCOUNT_PROMOTION.GET_DISCOUNT_PROMOTION:
            return {
                ...state,
                discountPromotions: payload,
                directFlat: getDiscountGroup(payload, discountTypes.directOrFlat), // Not changing
                specificCategoryGroup: getDiscountGroup(payload, discountTypes.specificGroup),
                purchase: getDiscountGroup(payload, discountTypes.purchase),
                get_free_item_for_other_purchase: getDiscountGroup(payload, discountTypes.Mentioned_Number_Service),
                specificBrandServiceGroup: getDiscountGroup(payload, discountTypes.specificBrandServiceGroup),
                spendSomeAmount: getDiscountGroup(payload, discountTypes.spendSomeAmount),
                fixedPrice: getDiscountGroup(payload, discountTypes.fixedPrice),
                freeService: getDiscountGroup(payload, discountTypes.freeService),

                bundleDiscount: getDiscountGroup(payload, discountTypes.bundleDiscount),

                retailPromotion: getDiscountGroup(payload, discountTypes.retailPromotion),
                userRestricted: getDiscountGroup(payload, discountTypes.userRestricted),
                complimentaryVoucher: getDiscountGroup(payload, discountTypes.complimentaryVoucher),
                packages: getDiscountGroup(payload, discountTypes.packages),
                coupons: getDiscountGroup(payload, discountTypes.coupons),
                discountPromotions_updated: true,
            };

        case CLIENT_DISCOUNT_PROMOTION.GET_AVAIL_OFFERS:
            return {
                ...state,
                avail_offers: payload,
                avail_offers_updated_: true,
            };

        // Discount on Direct or Flat (Module 1)
        case CLIENT_DISCOUNT_PROMOTION.ADD_DISCOUNT:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                directFlat: addDiscount(payload, directFlat)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_BUNDLE_FIXED_AMOUNT:
            return {
                ...state,
                avail_offers: {
                    ...state.avail_offers,
                    bundleDiscount: payload
                },
            };
        case CLIENT_DISCOUNT_PROMOTION.UPDATE_FIXED_PRICE_SERVICES:
            return {
                ...state,
                avail_offers: {
                    ...state.avail_offers,
                    fixedPrice: payload
                },
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_DISCOUNT:
            return {
                ...state,
                discountPromotions: deleteDiscount(state?.discountPromotions, payload),
                directFlat: deleteDiscount(directFlat, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_DISCOUNT:
            return {
                ...state,
                discountPromotions: update(state.discountPromotions, payload),
                directFlat: update(directFlat, payload)
            };


        // Discount on Specific Category Group (Module 2)
        case CLIENT_DISCOUNT_PROMOTION.ADD_SPECIFIC_CATEGORYGROUP_DISCOUNT:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                specificCategoryGroup: addDiscount(payload, specificCategoryGroup)
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_SPECIFIC_CATEGORYGROUP_DISCOUNT:
            return {
                ...state,
                discountPromotions: deleteDiscount(state?.discountPromotions, payload),
                specificCategoryGroup: deleteDiscount(specificCategoryGroup, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_SPECIFIC_CATEGORYGROUP_DISCOUNT:
            return {
                ...state,
                discountPromotions: update(state.discountPromotions, payload),
                specificCategoryGroup: update(specificCategoryGroup, payload)
            };


        // Discount on Purchase (Module 3)
        case CLIENT_DISCOUNT_PROMOTION.ADD_PURCHASE_DISCOUNT:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                purchase: addDiscount(payload, purchase)
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_PURCHASE_DISCOUNT:
            return {
                ...state,
                discountPromotions: deleteDiscount(state?.discountPromotions, payload),
                purchase: deleteDiscount(purchase, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_PURCHASE_DISCOUNT:
            return {
                ...state,
                discountPromotions: update(state.discountPromotions, payload),
                purchase: update(purchase, payload)
            };


        // Discount on Specific Brand and Service Group (Module 4)
        case CLIENT_DISCOUNT_PROMOTION.ADD_SPECIFIC_BRAND_SERVICE_GROUP_DISCOUNT:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                specificBrandServiceGroup: addDiscount(payload, specificBrandServiceGroup)
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_SPECIFIC_BRAND_SERVICE_GROUP_DISCOUNT:
            return {
                ...state,
                discountPromotions: deleteDiscount(state?.discountPromotions, payload),
                specificBrandServiceGroup: deleteDiscount(specificBrandServiceGroup, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_SPECIFIC_BRAND_SERVICE_GROUP_DISCOUNT:
            return {
                ...state,
                discountPromotions: update(state.discountPromotions, payload),
                specificBrandServiceGroup: update(specificBrandServiceGroup, payload)
            };

        // Discount on get a free item if you spend some amount Module 5
        case CLIENT_DISCOUNT_PROMOTION.ADD_SPEND_SOME_AMOUNT_DISCOUNT:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                spendSomeAmount: addDiscount(payload, spendSomeAmount)
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_SPEND_SOME_AMOUNT_DISCOUNT:
            return {
                ...state,
                discountPromotions: deleteDiscount(state?.discountPromotions, payload),
                spendSomeAmount: deleteDiscount(spendSomeAmount, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_SPEND_SOME_AMOUNT_DISCOUNT:
            return {
                ...state,
                discountPromotions: update(state.discountPromotions, payload),
                spendSomeAmount: update(spendSomeAmount, payload)
            };

        //Discount on Get an Item for a fixed price or in the group
        case CLIENT_DISCOUNT_PROMOTION.ADD_FIXED_PRICE_SERVICE_DISCOUNT:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                fixedPrice: addDiscount(payload, fixedPrice)
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_FIXED_PRICE_SERVICE_DISCOUNT:
            return {
                ...state,
                discountPromotions: deleteDiscount(state?.discountPromotions, payload),
                fixedPrice: deleteDiscount(fixedPrice, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_FIXED_PRICE_SERVICE_DISCOUNT:
            return {
                ...state,
                discountPromotions: update(state.discountPromotions, payload),
                fixedPrice: update(fixedPrice, payload)
            };

        // Discount on Get free Item for other purchase
        case CLIENT_DISCOUNT_PROMOTION.ADD_FREE_SERVICE_DISCOUNT:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                freeService: addDiscount(payload, freeService)
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_FREE_SERVICE_DISCOUNT:
            return {
                ...state,
                discountPromotions: deleteDiscount(discountPromotions, payload),
                freeService: deleteDiscount(freeService, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_FREE_SERVICE_DISCOUNT:
            return {
                ...state,
                discountPromotions: update(discountPromotions, payload),
                freeService: update(freeService, payload)
            };

        // Discount on Bundle Discount
        case CLIENT_DISCOUNT_PROMOTION.ADD_BUNDLE_DISCOUNT:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                bundleDiscount: addDiscount(payload, bundleDiscount)
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_BUNDLE_DISCOUNT:
            return {
                ...state,
                discountPromotions: deleteDiscount(discountPromotions, payload),
                bundleDiscount: deleteDiscount(bundleDiscount, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_BUNDLE_DISCOUNT:
            return {
                ...state,
                discountPromotions: update(discountPromotions, payload),
                bundleDiscount: update(bundleDiscount, payload)
            };

        // Discount on Buy retail and get specific services
        case CLIENT_DISCOUNT_PROMOTION.ADD_RETAIL_PROMOTION:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                retailPromotion: addDiscount(payload, retailPromotion)
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_RETAIL_PROMOTION:
            return {
                ...state,
                discountPromotions: deleteDiscount(discountPromotions, payload),
                retailPromotion: deleteDiscount(retailPromotion, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_RETAIL_PROMOTION:
            return {
                ...state,
                discountPromotions: update(discountPromotions, payload),
                retailPromotion: update(retailPromotion, payload)
            };

        // Discount on User Restricted
        case CLIENT_DISCOUNT_PROMOTION.ADD_USER_RESTRICTED_DISCOUNT:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                userRestricted: addDiscount(payload, userRestricted)
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_USER_RESTRICTED_DISCOUNT:
            return {
                ...state,
                discountPromotions: deleteDiscount(discountPromotions, payload),
                userRestricted: deleteDiscount(userRestricted, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_USER_RESTRICTED_DISCOUNT:
            return {
                ...state,
                discountPromotions: update(discountPromotions, payload),
                userRestricted: update(userRestricted, payload)
            };

        // Discount on complimentary Voucher
        case CLIENT_DISCOUNT_PROMOTION.ADD_COMPLIMENTARY_DISCOUNT:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                complimentaryVoucher: addDiscount(payload, complimentaryVoucher)
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_COMPLIMENTARY_DISCOUNT:
            return {
                ...state,
                discountPromotions: deleteDiscount(discountPromotions, payload),
                complimentaryVoucher: deleteDiscount(complimentaryVoucher, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_COMPLIMENTARY_DISCOUNT:
            return {
                ...state,
                discountPromotions: update(discountPromotions, payload),
                complimentaryVoucher: update(complimentaryVoucher, payload)
            };
        // Discount on Packages
        case CLIENT_DISCOUNT_PROMOTION.ADD_PACKAGE_DISCOUNT:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                packages: addDiscount(payload, packages)
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_PACKAGE_DISCOUNT:
            return {
                ...state,
                discountPromotions: deleteDiscount(discountPromotions, payload),
                packages: deleteDiscount(packages, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_PACKAGE_DISCOUNT:
            return {
                ...state,
                discountPromotions: update(discountPromotions, payload),
                packages: update(packages, payload)
            };

        // Discount on Coupons
        case CLIENT_DISCOUNT_PROMOTION.ADD_COUPONS_DISCOUNT:
            return {
                ...state,
                discountPromotions: addDiscount(payload, discountPromotions),
                coupons: addDiscount(payload, coupons)
            };

        case CLIENT_DISCOUNT_PROMOTION.DELETE_COUPONS_DISCOUNT:
            return {
                ...state,
                discountPromotions: deleteDiscount(discountPromotions, payload),
                coupons: deleteDiscount(coupons, payload)
            };

        case CLIENT_DISCOUNT_PROMOTION.UPDATE_COUPONS_DISCOUNT:
            return {
                ...state,
                discountPromotions: update(discountPromotions, payload),
                coupons: update(coupons, payload)
            };
        default:
            return state;
    }
};
export default ClientDiscountPromotionReducer;
