/* eslint-disable array-callback-return */
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { add_package_discount, get_client_discount_promotion, update_package_discount } from "Adapters/Api/clientDiscountPromotion";
import { getSaleServices, getServiceGroup } from "Adapters/Api/saleServices";
import { get_business_brands } from "Adapters/Api/Brand";
import { discountErrors, navigateToDiscountPromotion } from "../../helper";
import { getsProducts } from "Adapters/Api/Products";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import { getClient } from "Adapters/Api/clients";
import { getLocations } from "Adapters/Api";

const initialState = {
  packages: [{ service: [], service_duration: '', total_amount: '', package_duration: '', is_deleted: 'False' }],
  service: [],
  promotion_name: '',
  dateRestriction: { startDate: '', endDate: '' },
  blockDate: '',
  blockDates: [],
  storeRestriction: '',
  storeRestrictions: [],
  dayRestrictions: [
    { day: 'Monday', value: '', is_deleted: 'False' },
    { day: 'Tuesday', value: '', is_deleted: 'False' },
    { day: 'Wednesday', value: '', is_deleted: 'False' },
    { day: 'Thursday', value: '', is_deleted: 'False' },
    { day: 'Friday', value: '', is_deleted: 'False' },
    { day: 'Saturday', value: '', is_deleted: 'False' },
    { day: 'Sunday', value: '', is_deleted: 'False' },
  ]
}
export const CORPORATE_CHOICE = {
  All_Service: 'All Service',
  Retail_Product: 'Retail Product'
}

export const TYPE_CHOICES = [
  { label: 'Next 1 visit', value: 'Next 1 visit' },
  { label: 'Next 2 visit', value: 'Next 2 visit' },
  { label: 'Next 3 visit', value: 'Next 3 visit' },
  { label: 'Next 4 visit', value: 'Next 4 visit' },
  { label: 'Next 5 visit', value: 'Next 5 visit' },
  { label: 'Next 6 visit', value: 'Next 6 visit' },
  { label: 'Next 7 visit', value: 'Next 7 visit' },
  { label: 'Next 8 visit', value: 'Next 8 visit' },
  { label: 'Next 9 visit', value: 'Next 9 visit' },
  { label: 'Next 10 visit', value: 'Next 10 visit' },
]

export const useAddDiscount = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { setErrors, errors, onBlurHandler } = useValidations();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const dispatch = useDispatch();
  const businessId = state?.business?.business?.id;
  const [isEdit, setIsEdit] = useState(false);
  const [toggleStore, setToggleStore] = useState(false);
  const [toggleblockdate, setToggleBlockDate] = useState(false);
  const [toggleDay, setToggleDay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(initialState);
  const locations = state?.locations?.locations;
  const params = useParams();
  const clientRedux = useSelector((state) => state?.client?.clients);
  const servicesRedux = state?.saleService?.saleServices;

  const {
    blockDates,
    blockDate,
    storeRestrictions,
  } = discount;

  const get_products = async () => {
    if (!state.product.products_updated) {
      const response = await getsProducts();

      if (response.status === 200) {
        let productList = response?.data?.response?.products;
        dispatch(getProducts(productList));
      }
    }
  };
  useEffect(() => {
    get_products();
    dispatch(getSaleServices());
    dispatch(getClient())
    dispatch(getLocations(businessId))
  }, [])

  useEffect(() => {
    dispatch(getServiceGroup());
    if (!state.brand.brands_updated) {
      dispatch(get_business_brands());
    }
  }, [state?.brand?.brands?.length])

  useEffect(() => {
    if (params.id === undefined) {
      setIsEdit(false);
    }
    if (state?.clientDiscountPromotion?.discountPromotions?.length === 0 && params.id) {
      dispatch(get_client_discount_promotion())
      setIsEdit(true);
    }
    if (params.id) {
      setIsEdit(true);
    }
  }, [params.id, state?.clientDiscountPromotion?.discountPromotions?.length]);

  useEffect(() => {
    let editData = state?.clientDiscountPromotion?.discountPromotions?.find((obj) => obj.id === params.id);
    if (editData) {
      let { id, date_restrictions, block_date, day_restrictions, service_duration } = editData;
      let { start_date, end_date, business_address } = date_restrictions;
      let dayRestrictions = initialState?.dayRestrictions?.map((item) => {
        let obj = day_restrictions.find(obj => obj.day === item.day);
        if (obj?.day === item.day) {
          return {
            id: obj?.id,
            day: obj?.day,
            value: obj?.day,
            is_deleted: obj?.is_deleted
          }
        } else {
          return { ...item }
        }
      })

      day_restrictions?.length > 0 && setToggleDay(true);
      business_address?.length > 0 && setToggleStore(true);

      if (editData && dayRestrictions) {
        setDiscount({
          ...discount,
          id: id,
          packages: service_duration,
          dateRestriction: { startDate: start_date, endDate: end_date },
          blockDates: block_date,
          storeRestrictions: business_address,
          dayRestrictions: dayRestrictions
        })
      }

    }
  }, [isEdit, state?.clientDiscountPromotion?.discountPromotions?.length])

  const addNewVoucher = () => {
    setDiscount((prev) => ({
      ...prev,
      voucher: [
        ...prev?.voucher,
        initialState?.voucher[0],
      ],
    }));
  }

  const handleVoucher = (e, i) => {
    const { value, name } = e.target;
    setDiscount((prev) => ({
      ...prev,
      voucher: [
        ...prev?.voucher.map((obj, index) => {
          if (i === index) {
            return {
              ...obj,
              [name]: value,
              is_deleted: 'False'
            }
          }
          else {
            return obj
          }
        })
      ],
    }));
  }
  const filterVoucher = (id, index) => {
    if (id) {  // edit 
      var filter = discount?.voucher?.map((obj) => {
        if (obj === discount?.voucher[index]) {
          return {
            ...obj,
            is_deleted: 'True'
          }
        }
        else {
          return { ...obj }
        }
      })
      setDiscount((prev) => ({
        ...prev,
        voucher: filter,
      }))
    }
    else { // create no need of isDelete here 
      let filter = discount.voucher.filter((obj) => obj !== discount.voucher[index])
      setDiscount((prev) => ({
        ...prev,
        voucher: filter
      }))
    }
  }
  const serviceDropdown = useMemo(() => {
    return servicesRedux?.map((obj) => {
      if (obj) {
        return {
          label: obj?.name,
          value: obj?.id
        }
      }
    })
  }, [servicesRedux])
  const getServiceName = (id, data) => {
    return data.find((obj) => obj.value === id)?.label;
  }

  let dateRangeCheck = moment(blockDate).valueOf() >= moment(discount.dateRestriction.startDate).valueOf() && moment(blockDate).valueOf() <= moment(discount.dateRestriction.endDate).valueOf()
  let validateBlockDate = discount?.blockDates?.find(obj => obj.date === blockDate)
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === 'startDate' || name === 'endDate') {
      setDiscount((prev) => ({
        ...prev,
        dateRestriction: { ...prev?.dateRestriction, [name]: value }
      }));
    }
    if (name === 'blockDate') {
      let validateBlock = discount?.blockDates?.find(obj => obj.date === value)

      let dateRangeCheck = moment(value).valueOf() >= moment(discount.dateRestriction.startDate).valueOf() && moment(value).valueOf() <= moment(discount.dateRestriction.endDate).valueOf()
      if (!dateRangeCheck) {
        setErrors((prev) => ({
          ...prev,
          [name]: discountErrors.dateRange,
        }));
      }

      if (dateRangeCheck) {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
      if (validateBlock) {
        setErrors((prev) => ({
          ...prev,
          [name]: discountErrors.dateAlreadySelected,
        }));
      }
      setDiscount((prev) => ({
        ...prev,
        [name]: value
      }));
    }
    else {
      setDiscount((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  }
  const addBlockDates = () => {
    setDiscount((prev) => ({
      ...prev,
      blockDates: [...prev.blockDates, { date: discount.blockDate, is_deleted: 'False' }],
      blockDate: ''
    }))
  }

  const filterBlockDate = (id, index) => {
    if (id) {
      var filter = blockDates?.map((obj) => {
        if (obj === blockDates[index]) {
          return {
            ...obj,
            is_deleted: 'True'
          }
        }
        else {
          return { ...obj }
        }
      })
      setDiscount((prev) => ({
        ...prev,
        blockDates: filter,
      }))
    }
    else {
      var filter = blockDates?.filter((obj) => obj != blockDates[index])
      setDiscount((prev) => ({
        ...prev,
        blockDates: filter,
      }))
    }
  }
  const addService = (e, index) => {
    const { value } = e.target;
    const packages = discount?.packages?.map((packag, i) => {
      if (i === index) {
        const service = [...packag.service, value]
        return { ...packag, service }
      }
      return packag
    })
    setDiscount(
      {
        ...discount,
        packages
      }
    )
  }
  const handlePackage = (e, i) => {
    const { value, name } = e.target;
    setDiscount((prev) => ({
      ...prev,
      packages: [
        ...prev?.packages?.map((itm, ind) => {
          if (i === ind) {
            return {
              ...itm,
              [name]: value,
            };
          } else {
            return itm
          }
        }),
      ],
    }));
  }
  const newPackage = () => {
    setDiscount((prev) => ({
      ...prev,
      packages: [
        ...prev?.packages,
        initialState.packages[0]
      ],
    }));
  }
  const filterService = (value, index) => {
    const packages = discount.packages.map((packag, i) => {
      if (i === index) {
        const service = packag.service.filter((item) => item !== value)
        return { ...packag, service }
      }
      return packag
    })
    setDiscount(
      {
        ...discount,
        packages
      }
    )
  }
  const removePackage = (id, index) => {
    if (id) {  // edit 
      var filter = discount?.packages?.map((obj) => {
        if (obj === discount?.packages[index]) {
          return {
            ...obj,
            is_deleted: 'True'
          }
        }
        else {
          return { ...obj }
        }
      })
      setDiscount((prev) => ({
        ...prev,
        packages: filter,
      }))
    }
    else { // create no need of isDelete here 
      let filter = discount.packages.filter((obj) => obj !== discount.packages[index])
      setDiscount((prev) => ({
        ...prev,
        packages: filter
      }))
    }
  }
  const addStoreRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      storeRestrictions: [...prev.storeRestrictions, discount.storeRestriction],
      storeRestriction: ''
    }))
  }
  const filterStoreRestriction = (index) => {
    var filter = storeRestrictions?.filter(obj => obj !== storeRestrictions[index])
    setDiscount((prev) => ({
      ...prev,
      storeRestrictions: filter,
    }))
  }
  const handleDayChecklist = (e) => {
    setDiscount((prev) => ({
      ...prev,
      dayRestrictions: discount?.dayRestrictions?.map(obj => {
        // handle checklist withoutd ids 
        // check function
        if (obj.day === e.target.name && obj.value === '' && obj.is_deleted === 'False') {
          return {
            ...obj,
            value: e.target.name
          }
        }
        // UN Check block
        if (obj.value === e.target.value && !obj.id) {
          return {
            ...obj,
            value: ''
          }
        }

        // handling update scenarios for checklist
        // un check block for checked
        if (obj.id && obj.value === e.target.value && obj.is_deleted === 'False') {
          return {
            ...obj,
            is_deleted: 'True',
            value: ''
          }
        }

        // checked block and isDeleted 
        if (obj.day === e.target.name && obj.value === '' && obj.is_deleted && obj.id) {
          return {
            ...obj,
            value: e.target.name,
            is_deleted: 'False'
          }
        }
        else {
          return obj
        }
      })
    }))
  }

  const locationDropdown = useMemo(() => {
    return locations?.map((obj) => {
      if (obj) {
        return {
          label: obj?.address_name,
          value: obj?.id
        }
      }
    })
  }, [locations])

  const clientDropdown = useMemo(() => {
    return clientRedux?.map((obj) => {
      if (obj) {
        return {
          label: obj?.full_name,
          value: obj?.id
        }
      }
    })
  }, [clientRedux])

  let success = () => {
    setLoading(false);
    setDiscount(initialState);
    navigateToDiscountPromotion(navigate)
    setToggleDay(false);
    setToggleStore(false);
    setToggleBlockDate(false)
  }
  let fail = () => {
    setLoading(false);
  }

  const onSubmit = () => {
    setLoading(true);
    let payload = {
      business: businessId,
      packages: discount.packages,
      start_date: discount.dateRestriction.startDate,
      end_date: discount.dateRestriction.endDate,
      blockdate: discount?.blockDates,
      promotion_name: discount.promotion_name,
      location: discount.storeRestrictions
    }
    if (isEdit) {
      if (payload) {
        payload.id = discount.id;
        payload.dayrestrictions = discount?.dayRestrictions?.filter(obj => obj.value || obj.is_deleted === "True");
        dispatch(update_package_discount(payload, access_token, success, fail))
      }
    } else {
      if (payload) {
        payload.dayrestrictions = discount?.dayRestrictions?.filter(obj => obj.value)?.map(obj => { return { day: obj.value } });
        dispatch(add_package_discount(payload, access_token, success, fail))
      }
    }
  }

  return {
    errors,
    toggleDay,
    toggleStore,
    discount,
    clientDropdown,
    loading,
    locationDropdown,
    isEdit,
    dateRangeCheck,
    validateBlockDate,
    serviceDropdown,
    newPackage,
    handlePackage,
    handleVoucher,
    filterVoucher,
    addNewVoucher,
    addService,
    filterService,
    getServiceName,
    handleChange,
    onBlurHandler,
    navigate,
    addStoreRestriction,
    filterStoreRestriction,
    onSubmit,
    addBlockDates,
    filterBlockDate,
    setToggleDay,
    setToggleStore,
    handleDayChecklist,
    removePackage,
    toggleblockdate, setToggleBlockDate
  }
};