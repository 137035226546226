import Svgs from 'Assets/svgs'
import { error_message } from 'Constants/Variables'
import React from 'react'

const DateInput = ({ required, title, name, placeholder, value, min, max, onChange, onBlur, error, custom_padding }) => {
    return (
        <div className='flex flex-col gap-2'>
            {title ? (
                <div className="text-sm flex items-center gap-1">
                    <div>{title}</div> {required && title && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            ) : ""}
            {/* {
                title ? <p className='text-sm'>{title}</p> : ''
            } */}
            <div className="w-full relative">
                <input
                    id="inputId"
                    type="date"
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={placeholder ? placeholder : "mm/dd/yyyy"}
                    min={min}
                    max={max}
                    onClick={(e) => {
                        if (typeof e.target.showPicker === 'function') {
                            e.target.showPicker();
                        }
                    }}
                    className={`${error && "!border-l-[0.375rem] !border-[#eb3b3b]"
                        } input w-full ${custom_padding ? custom_padding : 'lg:pr-8 pr-7'}`}
                />
                {error && typeof error == "string" ? (
                    <p className="text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                        <Svgs.I fill="#eb3b3b" />
                        {error}
                    </p>
                ) : typeof error == "boolean" && error == true ? (
                    <p className="text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                        <Svgs.I fill="#eb3b3b" /> {error_message}
                    </p>
                ) : (
                    ""
                )}
            </div>
        </div>
    )
}


export default DateInput