import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

const DurationBtn = ({ start, end, handleDeleteSlot, handleEditSlot, week, bottom, employee, employeePermissions }) => {
  let start_time = start ? new Date(`12/12/2222 ${start}`) : "------";
  let end_time = end ? new Date(`12/12/2222 ${end}`) : "------";

  if (start_time !== "------") {
    start_time = start_time.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // This will display AM/PM indicator
    }).replace(/\b([ap])m\b/i, function (match, p1) {
      return p1.toUpperCase() + 'M'; // Capitalize 'A' or 'P' and add 'M'
    });
  }

  if (end_time !== "------") {
    end_time = end_time.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // This will display AM/PM indicator
    }).replace(/\b([ap])m\b/i, function (match, p1) {
      return p1.toUpperCase() + 'M'; // Capitalize 'A' or 'P' and add 'M'
    });
  }

  const state = useSelector((state) => state);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language



  return (
    <button className="transition-all relative border border-[#BAD3FC] text-[#3A3A3A] hover:bg-[#BAD3FC] bg-[#ECF3FF] px-3 py-2 flex items-center gap-2 rounded-lg hover-parent">
      <p className="whitespace-nowrap">
        {start_time} - {end_time}
      </p>
      {!employee ||
        (employee &&
          employeePermissions?.employee_work_schedule?.includes(
            "edit"
          )) ?
        <div className={`hidden hover-child absolute ${bottom ? 'bottom-full' : 'top-full'} left-0 right-0 bg-white border p-[0.35rem]  text-left shadow-md rounded-md`}>
          <div className="hover:bg-[#ddd] rounded p-1" onClick={handleEditSlot}>
            {selectedLanguage?.name == "English" ? "Edit this day" : translation[624]?.value ? translation[624]?.value : "Edit this day"} {' '}
            {/* {week} */}
            {moment(week).format('ddd, MMM D, YYYY')}
          </div>
          <div
            className="hover:bg-[#ddd] rounded p-1 text-[#E04663]"
            onClick={handleDeleteSlot}
          >
            {selectedLanguage?.name == "English" ? "Delete this shift" : translation[625]?.value ? translation[625]?.value : "Delete this shift"}
          </div>
        </div>
        : ""}
    </button>
  );
};
export default DurationBtn;
