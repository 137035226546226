import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import LocationSelection from "new-components/location";
import AddStaffTarget from "./AddStaffTarget";
import NotFound from "Components/Utility/NotFound";
import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
import { useStaffTarget } from "./helper";
import Svgs from "Assets/svgs";
import StaffTargetItem from "./StaffTargetItem";
import { MONTHS } from "Constants/Data/Months";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import CopyStaffTarget from "./CopyStaffTarget";
import DeleteConfirmation from "new-components/delete-popup";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import IconButton from "Components/TenantApp/Elements/IconButton";
import Pagination from "new-components/Pagination";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import NoReportDataFound from "Components/Utility/NoReportDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";

const StaffTarget = () => {
  const navigate = useNavigate();
  const [CopyStaff_Target, setCopyStaff_Target] = useState(false);
  const { staffTargetList, dataList, searchText, searchMonth, staffTarget_updated, openStaffTarget, deleteLoading, updateData,
    isUpdate, openDeletePopup, deleteId, currency, yearDropdown, csvReport, searchYear, currencyOfSelectedLocation, setSearchYear,
    handleDelete, setSearchText, setOpenStaffTarget, setIsUpdate, handleUpdateStaffId, setSearchMonth, staffTargetfilter,
    handleDeleteStaffId, setOpenDeletePopup, currentPage, setCurrentPage, PageSize, allStaffTargetList, pages, allCount, loader,
    handleExportClick, exportLoader
  } = useStaffTarget();

  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          second={selectedLanguage?.name == "English" ? "Target Control" : translation[569]?.value ? translation[569]?.value : "Target Control"}
          third={selectedLanguage?.name == "English" ? "Staff Target" : translation[648]?.value ? translation[648]?.value : "Staff Target"}
          url={'/dashboard/account/'}
          secondurl={'/dashboard/account/target-control'}
        />
        <div className="my-[1rem] flex sm:items-center justify-between gap-4 sm:flex-row flex-col">
          <div className="flex items-center sm:gap-4 gap-2">

            <div className="flex items-center gap-4">
              <h2 className="text-primary font-bold lg:text-3xl text-xl">
                {selectedLanguage?.name == "English" ? "Staff Target" : translation[648]?.value ? translation[648]?.value : "Staff Target"}
              </h2>
              <LocationSelection />
            </div>
          </div>
          {loadingPermission ?
            <EmployeesHeadShimmer classOn1={'!w-32 !lg:w-44 !sm:w-40'} classOn2={'!w-32 !lg:w-44 !sm:w-40'} />
            :
            <div className="flex items-center sm:gap-4 gap-2 sm:w-fit w-full justify-end">
              {!employee ? (
                <LoginBtn
                  onClick={() => {
                    setCopyStaff_Target(!CopyStaff_Target);
                  }}

                >
                  <div className="flex items-center gap-2">
                    <Svgs.Copy />
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "Copy Staff Target" : translation[846]?.value ? translation[846]?.value : "Copy Staff Target"}
                    </p>
                  </div>
                </LoginBtn>
              ) : (
                ""
              )}

              {!employee ||
                (employee &&
                  employeePermissions?.target_control_staff?.includes("create")) ? (
                <LoginBtn
                  onClick={() => {
                    setOpenStaffTarget(!openStaffTarget);
                    setIsUpdate(false);
                  }}

                >
                  <div className="flex items-center gap-2">
                    <Svgs.Plus />
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "Add Staff Target" : translation[841]?.value ? translation[841]?.value : "Add Staff Target"}

                    </p>
                  </div>
                </LoginBtn>
              ) : (
                ""
              )}
            </div>
          }
        </div>

        <div className="border rounded-lg">
          <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-1 lg:gap-1 gap-2">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Staff Target" : translation[648]?.value ? translation[648]?.value : "Staff Target"}
                </h2>

                {loader || !staffTarget_updated ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">
                      {allCount ? allCount : 0}{" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                      }
                    </p>
                  </div>
                }
              </div>
              <div className="mb-00">
                {loadingPermission ?
                  <Shimmer className={'w-44 lg:w-48'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                  </Shimmer> :
                  <LoginInput
                    name="searchText"
                    title=""
                    value={searchText}
                    placeholder={selectedLanguage?.name == "English" ? "Search staff" : translation[423]?.value ? translation[423]?.value : "Search staff"}
                    padding="py-1"
                    leftIcon={<Svgs.Search />}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                }
              </div>
            </div>
            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-32 !lg:w-44 !sm:w-40'} classOn2={'!w-32 !lg:w-44 !sm:w-40'} classOn3={'!w-12 !lg:16'} has3Itm />
              :
              <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">

                <Dropwdown
                  placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                  title=""
                  name="searchYear"
                  value={searchYear}
                  options={yearDropdown}
                  onChange={(e) => {
                    setSearchYear(e.target.value);
                  }}
                />

                <Dropwdown
                  placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                  title=""
                  name="searchMonth"
                  value={searchMonth}
                  options={[
                    { label: selectedLanguage?.name == "English" ? "All Month" : translation[821]?.value ? translation[821]?.value : "All Month", value: "" },
                    ...MONTHS?.map((month, i) => {
                      return {
                        label: month,
                        value: month,
                      };
                    }),
                  ]}
                  onChange={(e) => {
                    setSearchMonth(e.target.value);
                  }}
                />

                {exportLoader ? (
                  <div className="h-[4rem] flex items-center justify-center">
                    <PreloaderSm />
                  </div>
                ) : !employee ? (
                  allStaffTargetList?.length > 0 ?
                    <CSVLink {...csvReport} target="_blank" onClick={handleExportClick}>
                      <IconButton filled>
                        <Svgs.Export />
                      </IconButton>
                    </CSVLink>
                    :
                    <LoginBtn
                      className="rounded-lg border-2 border-primary"
                      bg="bg-white"
                      animation={false}
                      onClick={handleExportClick}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.Export bg="#FFFFFF" />
                      </div>
                    </LoginBtn>) : (
                  ""
                )}
              </div>
            }
          </div>
          {loader || !staffTarget_updated ?
            <TableShimer cols={5} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid gap-3 grid-cols-[repeat(5,minmax(145px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                {/* <p>{selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id"}</p> */}
                <p>{selectedLanguage?.name == "English" ? "Staff" : translation[335]?.value ? translation[335]?.value : "Staff"}</p>
                <p>{selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}</p>
                <p>{selectedLanguage?.name == "English" ? "Retail" : translation[604]?.value ? translation[604]?.value : "Retail"}</p>
                <p>{selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}</p>
                <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
              </div>
              {(dataList?.length > 0 && staffTargetfilter()?.length > 0) ? (
                staffTargetfilter()?.map((item, index) => {
                  return (
                    <StaffTargetItem
                      item={item}
                      index={index}
                      handleDelete={handleDelete}
                      deleteId={deleteId}
                      deleteLoading={deleteLoading}
                      handleUpdateStaffId={handleUpdateStaffId}
                      handleDeleteStaffId={handleDeleteStaffId}
                      openDeletePopup={openDeletePopup}
                      setOpenDeletePopup={setOpenDeletePopup}
                      currency={currencyOfSelectedLocation}
                    />
                  );
                })
              ) : (
                <>
                  <NoReportDataFound />
                </>
              )}

              {(dataList?.length > 0 && staffTargetfilter()?.length > 0 && !loader) &&
                <Pagination
                  currentPage={currentPage}
                  totalCount={Math.ceil(pages)}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
              }
            </div>
          }
        </div>
      </div>

      {openStaffTarget && (
        <AddStaffTarget
          selectedLanguage={selectedLanguage}
          translation={translation}
          handleClose={() => {
            setOpenStaffTarget(false);
          }}
          updateData={updateData}
          isUpdate={isUpdate}
          allStaffTargetList={allStaffTargetList}
        />
      )}
      {openDeletePopup && (
        <DeleteConfirmation
          title={selectedLanguage?.name == "English" ? "Staff Target" : translation[648]?.value ? translation[648]?.value : "Staff Target"}
          isLoading={deleteLoading}
          handleClose={() => {
            setOpenDeletePopup(false);
          }}
          handleDelete={() => handleDelete(deleteId)}
        />
      )}

      {CopyStaff_Target && (
        <CopyStaffTarget
          selectedLanguage={selectedLanguage}
          translation={translation}
          handleClose={() => {
            setCopyStaff_Target(false);
          }}
        />

        // <Popup
        //   heading={`Copy Staff Target`}
        //   handleClose={setCopyStaff_Target}
        //   size="md"
        //   open={CopyStaff_Target}
        // >
        //   <div className="flex flex-col gap-4">
        //     <div className="grid md:grid-cols-2 gap-4">
        //       <Dropwdown title={"From Month"} placeholder="Select Month" />
        //       <Dropwdown title={"To Month"} placeholder="Select Month" />
        //     </div>
        //     <LoginBtn text={`Save`} className="!px-6" />
        //   </div>
        // </Popup>
      )}
    </>
  );
};

export default StaffTarget;
