import ClientSection from 'Components/TenantApp/Dashboard/Calendar/AddAppointment/ClientSection'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import React from 'react'
import { DateCard } from '../DateSlider'
import ClientSectionShimmer from './ClientSectionShimmer'

const AddAppointmentShimmer = ({ notView, isEdit }) => {
    return (
        <>
            <div className="flex flex-col gap-5 lg:col-span-3 pb-5 animate-pulse">
                <div className="flex flex-col gap-3">
                    {/* <div className="h-10 w-3/5 bg-gray-200 rounded-lg my-3" /> */}
                    <div className="w-full flex flex-row items-center justify-end gap-2">
                        <div className="h-10 w-1/5 bg-gray-200 rounded-lg" />
                        <div className="h-10 w-1/5 bg-gray-200 rounded-lg" />
                    </div>
                    <div className="flex items-center gap-3 overflow-auto py-3 scroll-smooth" >
                        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9,]?.map(itm =>
                            <DateCard animate />
                        )}
                    </div>
                </div>
                <div className="flex flex-col gap-4">
                    {!notView && <div className="flex items-center gap-4 justify-end flex-wrap mt-2">
                        <div className="h-10 w-1/5 bg-gray-200 rounded-lg" />
                    </div>}
                    <div>

                        <div className="flex gap-6">
                            <div className="relative">

                            </div>
                            <div className="w-full">
                                {/* {console.log(viewOnly,'viewOnly')} */}
                                {isEdit &&
                                    <div className=" border px-4 py-3 rounded-md w-full flex flex-col gap-4">
                                        <div className="grid lg:grid-cols-2 gap-4">
                                            <div className="h-12 my-3 bg-gray-200 rounded-lg" />
                                            <div className="h-12 my-3 bg-gray-200 rounded-lg" />
                                            <div className="h-12 my-3 bg-gray-200 rounded-lg" />
                                            <div className="h-12 my-3 bg-gray-200 rounded-lg" />
                                        </div>
                                    </div>
                                }
                                <div className="mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4">
                                    <div className="grid lg:grid-cols-2 gap-4">
                                        <div className="h-12 my-3 bg-gray-200 rounded-lg" />
                                        <div className="h-12 my-3 bg-gray-200 rounded-lg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center gap-2 cursor-pointer ml-6"></div>

                <div className="flex justify-end">
                    <div className="h-10 w-1/5 bg-gray-200 rounded-lg" />
                </div>
            </div>
        </>
    )
}

export default AddAppointmentShimmer