import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Svgs from "Assets/svgs";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LocationSelection from "new-components/location";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Popup from "Components/TenantApp/Elements/Popup";
import useRetailTarget from "./helper";
import Selection from "new-components/dropdown";
import { MONTHS } from "./../../../../../../Constants/Data/Months";
import Input from "new-components/input";
import NotFound from "./../../../../../Utility/NotFound";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import CopyRetailTarget from "./CopyRetailTarget";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import IconButton from "Components/TenantApp/Elements/IconButton";
import Pagination from "new-components/Pagination";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import NoReportDataFound from "Components/Utility/NoReportDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import { getBrandDropdownlistPagination } from "Adapters/Api/Brand";

let brandTimeOutId = undefined;
const RetailTarget = () => {
  const { handleSubmit, control, register, reset, errors, isValid, is_delete, setDelete, is_Update, setUpdate, deleteId,
    setDeleteId, searchText, setSearchText, monthFilter, setMonthFilter, onSubmitRetailsTarget, onEditHandler, onUpdateHandler,
    onDeleteHandler, retailTargetList, retail_updated, yearDropdown, locationList, brandlist, AddRetailTarget,
    setAddRetailTarget, retail_target_fiter, loader, retailTargetData, currency, yearFilter, setYearFilter,
    currencyOfSelectedLocation, locationDetail, getValues, dataList, add_serv_target_loading,
    setServ_targ_Loading, pages, allCount, setCurrentPage, currentPage
  } = useRetailTarget();
  const dispatch = useDispatch()

  const state = useSelector((state) => state);
  const navigate = useNavigate();

  const [openCopyRetailTarget, setOpenCopyRetailTarget] = useState(false);
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [YearValue, setYearValue] = useState(true);
  const [BrandValue, setBrandValue] = useState(true);
  const [AddressValue, setAddressValue] = useState(true);
  const [AddMonthValue, setMonthValue] = useState(undefined);
  const [availed_values, setAvailedValues] = useState({})
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [brandTargetValue, setBrandTargetValue] = useState(undefined);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [brandSearchText, setBrandSearchText] = useState("");
  const [brandCurrentPage, setBrandCurrentPage] = useState(1)
  const [brandLoader, setBrandLoader] = useState(false)
  const brandListDropdown = state?.brand?.brandsDropdown;
  const brandPaginationData = state?.brand?.brandsPagination;


  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);


  useEffect(() => {
    if (brandSearchText === null) {
      dispatch(getBrandDropdownlistPagination(brandCurrentPage, "", setBrandLoader))
    } else {
      if (brandTimeOutId) {
        clearTimeout(brandTimeOutId)
      }
      brandTimeOutId = setTimeout(() => {
        dispatch(getBrandDropdownlistPagination(brandCurrentPage, brandSearchText, setBrandLoader))
      }, 500);
    }
  }, [brandSearchText, brandCurrentPage])

  useEffect(() => {
    let values = {}
    dataList.forEach(target => {
      let loc_data = values[target?.location?.id]
      if (!loc_data) {
        loc_data = {}
      }
      values[target?.location?.id] = { ...loc_data }

      let brand_id = target?.brand?.id
      let this_brand = values[target?.location?.id][brand_id]
      if (!this_brand) {
        this_brand = {}
      }
      values[target?.location?.id][brand_id] = {
        ...this_brand
      }

      let year = target.year?.slice(0, 4)
      let this_year = values[target?.location?.id][brand_id][year]
      if (!this_year) {
        this_year = []
      }

      let month = target.month
      values[target?.location?.id][brand_id][year] = [
        ...this_year,
        month
      ]
    })

    setAvailedValues(values)
  }, [dataList])
  // retail_target_fiter()?.forEach((str) => {
  //   Month_value = [
  //     ...Month_value,
  //     str?.month
  //   ]
  //   Year_value = [
  //     ...Year_value,
  //     str?.year?.slice(0, 4)
  //   ]
  //   Brand_value = [
  //     ...Brand_value,
  //     str?.brand?.id
  //   ]
  //   Address_value = [
  //     ...Address_value,
  //     str?.location?.id
  //   ]
  // })

  function shouldDisableMonth(month) {
    const selectedYear = parseInt(YearValue); // Convert to number
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    const monthIndex = MONTHS.indexOf(month);
    return monthIndex !== -1 && selectedYear === currentYear && monthIndex < currentMonth;
  }

  let filteredOptions = [...MONTHS.filter(dt => (availed_values[AddressValue] ? availed_values[AddressValue][BrandValue] ? availed_values[AddressValue][BrandValue][YearValue] ? (is_Update && AddMonthValue == dt) ? true : !availed_values[AddressValue][BrandValue][YearValue].includes(dt) ? true : false : true : true : true)).map(month => ({
    label: month,
    value: month,
    disabled: shouldDisableMonth(month),
  }))];


  const handleExportClick = () => {
    if (!isToastVisible) {
      if (dataList?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  const PageSize = 10
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          second={selectedLanguage?.name == "English" ? "Target Control" : translation[569]?.value ? translation[569]?.value : "Target Control"}
          third={selectedLanguage?.name == "English" ? "Retail Target" : translation[819]?.value ? translation[819]?.value : "Retail Target"}

          url={'/dashboard/account/'}
          secondurl={'/dashboard/account/target-control'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <div className="flex items-center gap-4">
              <h2 className="text-primary font-bold lg:text-3xl text-xl">
                {selectedLanguage?.name == "English" ? "Retail Target" : translation[819]?.value ? translation[819]?.value : "Retail Target"}
              </h2>
              <LocationSelection />
            </div>
          </div>
          {loadingPermission ?
            <EmployeesHeadShimmer classOn1={'!w-32 !lg:w-44 !sm:w-40'} classOn2={'!w-32 !lg:w-44 !sm:w-40'} />
            :
            <div className="flex items-center sm:gap-4 gap-2">
              {!employee ? (
                <LoginBtn
                  onClick={() => {
                    setOpenCopyRetailTarget(!openCopyRetailTarget);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <Svgs.Copy />
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "Copy Retail Target" : translation[824]?.value ? translation[824]?.value : "Copy Retail Target"}
                    </p>
                  </div>
                </LoginBtn>
              ) : (
                ""
              )}

              {!employee ||
                (employee &&
                  employeePermissions?.target_control_retail?.includes(
                    "create"
                  )) ? (
                <LoginBtn
                  onClick={() => {
                    setAddressValue(state.locations.selected_location)
                    setAddRetailTarget(!AddRetailTarget);
                    setUpdate(false);
                    reset({
                      'location': state.locations.selected_location
                    })
                  }}

                >
                  <div className="flex items-center gap-2">
                    <Svgs.Plus />
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "Add Retail Target" : translation[829]?.value ? translation[829]?.value : "Add Retail Target"}
                    </p>
                  </div>
                </LoginBtn>
              ) : (
                ""
              )}
            </div>
          }
        </div>

        <div className="border rounded-lg">
          <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-1 lg:gap-1 gap-2">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Retail Target" : translation[819]?.value ? translation[819]?.value : "Retail Target"}
                </h2>
                {loader ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-[#101928]">
                      {allCount ? allCount : 0}{" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                      }
                      {/* {selectedLanguage?.name == "English" ? "Store" : translation[822]?.value ? translation[822]?.value : "Store"} */}
                    </p>
                  </div>
                }
              </div>
              <div className="mb-00">
                {loadingPermission ?
                  <Shimmer className={'w-44 lg:w-48'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                  </Shimmer>
                  :
                  <LoginInput
                    name="searchText"
                    value={searchText}
                    title=""
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    placeholder={selectedLanguage?.name == "English" ? "Search brand" : translation[1275]?.value ? translation[1275]?.value : "Search brand"}
                    padding="py-1"
                    leftIcon={<Svgs.Search />}
                  />
                }
              </div>
            </div>
            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-32 !lg:w-44 !sm:w-40'} classOn2={'!w-32 !lg:w-44 !sm:w-40'} classOn3={'!w-12 !lg:16'} has3Itm />
              :
              <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">

                <Dropwdown
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                  showTitle={false}
                  value={yearFilter}
                  onChange={(e) => {
                    setYearFilter(e.target.value);
                  }}
                  options={
                    yearDropdown && [
                      { label: selectedLanguage?.name == "English" ? "All Year" : translation[823]?.value ? translation[823]?.value : "All Year", value: "" },
                      ...yearDropdown,
                    ]
                  }
                />

                <Dropwdown
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                  showTitle={false}
                  value={monthFilter}
                  onChange={(e) => {
                    setMonthFilter(e.target.value);
                  }}
                  options={[
                    { label: selectedLanguage?.name == "English" ? "All Month" : translation[821]?.value ? translation[821]?.value : "All Month", value: "" },
                    ...MONTHS.map((mnth, i) => {
                      return {
                        label: mnth,
                        value: mnth,
                      };
                    }),
                  ]}
                />
                {exportLoader ? (
                  <div className="h-[4rem] flex items-center justify-center">
                    <PreloaderSm />
                  </div>
                ) : !employee ? (
                  dataList?.length > 0 ?
                    <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
                      <CSVLink
                        {...retailTargetData}
                        target="_blank"
                        onClick={handleExportClick}
                      >
                        <IconButton filled>
                          <Svgs.Export />
                        </IconButton>
                      </CSVLink>
                    </div> :
                    <LoginBtn
                      className="rounded-lg border-2 border-primary"
                      bg="bg-white"
                      animation={false}
                      onClick={handleExportClick}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.Export bg="#FFFFFF" />
                      </div>
                    </LoginBtn>
                ) : (
                  ""
                )}
              </div>
            }
          </div>
          {loader ?
            <TableShimer cols={5} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid gap-3 grid-cols-[repeat(4,minmax(145px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                {/* <p>{selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id"}</p> */}
                <p>{selectedLanguage?.name == "English" ? "Brand" : translation[836]?.value ? translation[836]?.value : "Brand"}</p>
                <p>{selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}</p>
                <p>{selectedLanguage?.name == "English" ? "Target" : translation[837]?.value ? translation[837]?.value : "Target"}</p>
                <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>

              </div>
              {retail_target_fiter()?.length > 0 ? (
                retail_target_fiter()?.map((itm) => {
                  return (
                    <>
                      <div className={`odd-bg text-[#3A3A3A] transition-all px-12 py-3 inline-grid xl:grid gap-3 grid-cols-[repeat(4,minmax(145px,1fr))] min-w-full w-fit`}>
                        {/* <div className="flex items-center cursor-pointer">
                          <p className="text-sm">
                            {itm?.id?.toString()?.split("-")[0]}
                          </p>
                        </div> */}
                        <div className="flex items-center">
                          <p className="text-sm">{itm?.brand?.name}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {itm?.month}
                            {itm?.year && `, ${itm?.year?.slice(0, 4)}`}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {itm?.brand_target} {currencyOfSelectedLocation}
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          {!employee ||
                            (employee &&
                              employeePermissions?.target_control_retail?.includes(
                                "edit"
                              )) ? (
                            <div
                              className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                              onClick={() => {
                                onEditHandler(itm);
                                setAddressValue(itm?.location?.id)
                                setBrandTargetValue(itm?.brand_target)
                                setBrandValue(itm?.brand?.id)
                                setYearValue(itm?.year?.slice(0, 4))
                                setMonthValue(itm?.month)
                              }}
                            >
                              <Svgs.Pen />
                            </div>
                          ) : (
                            ""
                          )}
                          {!employee ? (
                            <div
                              className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center"
                              onClick={() => {
                                setDelete(true);
                                setDeleteId(itm?.id);
                              }}
                            >
                              <Svgs.Delete />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <NoReportDataFound />
              )}

              {(retail_target_fiter()?.length > 0 && !loader) &&
                <Pagination
                  currentPage={currentPage}
                  totalCount={Math.ceil(pages)}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
              }
            </div>
          }

        </div>
      </div>

      {AddRetailTarget && (
        <Popup
          heading={`${is_Update ?
            selectedLanguage?.name == "English" ? "Update Retail Target" : translation[834]?.value ? translation[834]?.value : "Update Retail Target"
            : selectedLanguage?.name == "English" ? "Add Retail Target" : translation[829]?.value ? translation[829]?.value : "Add Retail Target"
            }`}
          close={() => {
            setAddRetailTarget(false);
            reset({
              business: "",
              location: "",
              brand: "",
              month: "",
              brand_target: "",
            });
          }}
          open={AddRetailTarget}
        >
          <form id='retail-target-add-or-update-form'
            onSubmit={handleSubmit(
              is_Update ? onUpdateHandler : onSubmitRetailsTarget
            )}
          >
            <div className="flex flex-col gap-4">
              <div className="grid md:grid-cols-2 gap-4">
                <Selection
                  isDisabled={true}
                  label={<>
                    {selectedLanguage?.name == "English" ? "Store" : translation[822]?.value ? translation[822]?.value : "Store"}{" "}
                    <span className="text-red-600">*</span>
                  </>}
                  placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[380]?.value ? translation[380]?.value : "Select Stores"}
                  name={"location"}
                  control={control}
                  setVALUEEEE={setAddressValue}
                  VALUEEEE={AddressValue}
                  value={AddressValue}
                  errorMessage={errors?.location?.message}
                  options={locationList?.map((itm) => {
                    return {
                      label: itm?.address_name,
                      value: itm?.id,
                    };
                  })}
                >
                </Selection>
                <div>
                  <PaginationDropdown
                    name={"brand"}
                    title={selectedLanguage?.name == "English" ? "Product Brand" : translation[1313]?.value ? translation[1313]?.value : "Product Brand"}
                    placeholder={selectedLanguage?.name == "English" ? "Select Product Brand" : translation[1314]?.value ? translation[1314]?.value : "Select Product Brand"}
                    required
                    options={brandListDropdown && brandListDropdown?.map((itm) => {
                      return {
                        label: itm?.name,
                        value: itm?.id,
                      }
                    })}
                    value={BrandValue}
                    control={control}
                    onChange={(e) => setBrandValue(e.target.value)}
                    setPage={setBrandCurrentPage}
                    setDropdownSearch={setBrandSearchText}
                    page={brandCurrentPage}
                    dropdownSearch={brandSearchText}
                    lastPage={brandPaginationData?.total_pages}
                    currentPage={brandPaginationData?.currentPage}
                    loading={brandLoader}
                  />
                  {/* <Selection
                    setVALUEEEE={setBrandValue}
                    // control={control}
                    errorMessage={errors?.brand?.message}
                  /> */}
                  {brandListDropdown?.length == 0 && (
                    <div
                      className="flex items-center gap-2 cursor-pointer pt-2"
                      onClick={() => {
                        navigate('/dashboard/account/inventory-operations/brands')
                      }}
                    >
                      <p className="text-sm  mt-6">
                        {selectedLanguage?.name == "English" ? "No brand found. Click" : translation[838]?.value ? translation[838]?.value : "No brand found. Click"}
                        <span className="underline mx-px text-[#5679FF]">
                          {selectedLanguage?.name == "English" ? "here" : translation[502]?.value ? translation[502]?.value : "here"}
                        </span>
                        {selectedLanguage?.name == "English" ? " to add." : translation[140]?.value ? translation[140]?.value : " to add."}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="grid md:grid-cols-2 gap-4">
                <Selection
                  label={<>
                    {selectedLanguage?.name == "English" ? "Year" : translation[376]?.value ? translation[376]?.value : "Year"}{" "}
                    <span className="text-red-600">*</span>
                  </>}
                  placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                  name={"year"}
                  control={control}
                  setVALUEEEE={setYearValue}
                  VALUEEEE={YearValue}
                  onChange={e => { }}
                  errorMessage={errors?.year?.message}
                  options={
                    yearDropdown && [
                      ...yearDropdown,
                    ]
                  }
                />
                <Selection
                  label={<>
                    {selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}{" "}
                    <span className="text-red-600">*</span>
                  </>}
                  placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                  name={"month"}
                  control={control}
                  errorMessage={errors?.month?.message}
                  VALUEEEE={AddMonthValue}
                  setVALUEEEE={setMonthValue}
                  // options={[
                  //   ...MONTHS.filter(dt => (availed_values[AddressValue] ? availed_values[AddressValue][BrandValue] ? availed_values[AddressValue][BrandValue][YearValue] ? (is_Update && AddMonthValue == dt) ? true : !availed_values[AddressValue][BrandValue][YearValue].includes(dt) ? true : false : true : true : true)).map((mnth, i) => {
                  //     return {
                  //       label: mnth,
                  //       value: mnth,
                  //     };
                  //   }),
                  // ]}
                  options={filteredOptions}
                />
                <Input
                  title={<>
                    {selectedLanguage?.name == "English" ? "Brand Target" : translation[832]?.value ? translation[832]?.value : "Brand Target"}{" "}
                    <span className="text-red-600">*</span>
                  </>}
                  placeholder={selectedLanguage?.name == "English" ? "Enter Brand Target" : translation[833]?.value ? translation[833]?.value : "Enter Brand Target"}
                  rightIcon={locationDetail?.currency?.code}
                  type={"number"}
                  name={"brand_target"}
                  value={brandTargetValue}
                  setValue={setBrandTargetValue}
                  register={register}
                  errorMessage={errors?.brand_target?.message}
                  onlyNumericAllowed={true}
                />
              </div>
              <LoginBtn
                disabled={add_serv_target_loading}
                loading={add_serv_target_loading}
                text={`${is_Update ?
                  selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                  : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
                  }`}
                className="!px-6"
              />
            </div>
          </form>
        </Popup>
      )}

      {openCopyRetailTarget && (
        <CopyRetailTarget
          selectedLanguage={selectedLanguage}
          translation={translation}
          onClose={() => setOpenCopyRetailTarget(false)} />
      )}


      {is_delete && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Are you sure want to delete?" : translation[839]?.value ? translation[839]?.value : "Are you sure want to delete?"}
          onclose={() => {
            setDelete(false);
          }}
        >
          <div>
            <p>
              {selectedLanguage?.name == "English"
                ? "Are you sure you want to delete this Retail targets? You won't be able to see this again."
                : translation[840]?.value
                  ? translation[840]?.value
                  : "Are you sure you want to delete this Retail targets? You won't be able to see this again."}
            </p>
            <div className="flex justify-end mt-4">
              <LoginBtn
                deleteBtn
                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                onClick={() => {
                  onDeleteHandler();
                  setTimeout(() => {
                    setDelete(false);
                  }, 500);
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default RetailTarget;