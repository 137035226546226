import React, { useState, useEffect } from "react";

import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Popup from "Components/TenantApp/Elements/Popup";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import useConsumptions from "./useConsumptions";
import { useNavigate } from "react-router-dom";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
// import { getLocations } from "Adapters/Api";

const ConsumptionPopup = ({ editMode, editData, handleClose, translation, selectedLanguage }) => {
  const {
    errors,
    consumption,
    isEdit,
    loading,
    filterObjectBasedOnProductLocation,
    setConsumption,
    consumptionHandleChange,
    onConsumptionSubmit,
    onBlurHandler,
    setIsEdit,
    consumptionEditHandler,
    selected_location,
    dispatch,
    productList,
    productSearchText, setProductSearchText, productCurrentPage, setProductCurrentPage, productLoader, productPaginationData,
  } = useConsumptions();

  // fetch Product Lists 
  // const fetchProductslist = async () => {
  // (productCurrentPage, selected_location, productSearchText)
  // const response = await getsProductsForConsumption(selected_location);
  // if (response.status === 200) {
  //   let productList = response?.data?.response?.products;
  //   dispatch(getProducts(productList));
  // }
  // };

  // useEffect(() => {
  //   if (selected_location) {
  //     fetchProductslist();
  //   }
  // }, [selected_location])



  const { product, location, quantity } = consumption;
  const [productOptions, setProductOptions] = useState([])
  // console.log("🚀 ~ file: ConsumptionPopup.jsx:49 ~ ConsumptionPopup ~ productOptions:", productOptions)

  // useEffect(() => {
  //   setProductOptions(productList)
  // }, [productList, productList?.length])
  // const businessLocations = state?.business?.locations;
  // const businessId = state?.business?.business?.id;


  // Dispatching Locations into Reducer on base of businessId
  // useEffect(() => {
  //   if (businessLocations?.length == 0) {
  //     dispatch(getLocations(state.business.business.id));
  //   }
  // }, [businessId]);

  // Create Location list and Passing Locations List data in Location dropdown
  // const locationList = useMemo(() => {
  //   var locations = [];
  //   businessLocations?.map((item, index) => {
  //     locations.push({ label: item.address_name, value: item.id });
  //   });
  //   return locations;
  // }, [businessLocations?.length == 0]);
  const navigate = useNavigate()
  useEffect(() => {
    if (editData && editMode) {
      setConsumption({
        product: editData?.product?.id,
        location: editData?.location?.id,
        quantity: editData?.quantity,
      });
      setIsEdit(true);
    }
  }, [editMode]);

  // let disbledValidation =
  //   Object.values(consumption)?.every((x) => x !== "") === false && Object?.keys(filterObjectBasedOnProductLocation)?.length > 0 || errors.quantity !== ''
  //     ? true
  //     : false;
  return (
    <>
      <Popup
        heading={`${isEdit && editData?.id ?
          selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
          : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
          } `}
        onclose={handleClose}
      >
        <div className="flex flex-col gap-3">
          <div className="grid md:grid-cols-2 gap-3">
            <div>
              <PaginationDropdown
                title={selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}
                placeholder={selectedLanguage?.name == "English" ? "Select Product" : translation[1010]?.value ? translation[1010]?.value : "Select Product"}
                required={true}
                name="product"
                value={product}
                onBlur={() => onBlurHandler({ product: product })}
                options={productList}
                onChange={(e) => {
                  consumptionHandleChange(e);
                }}
                error={errors?.product}

                setPage={setProductCurrentPage}
                setDropdownSearch={setProductSearchText}
                page={productCurrentPage}
                dropdownSearch={productSearchText}
                lastPage={productPaginationData?.total_pages}
                currentPage={productPaginationData?.currentPage}
                loading={productLoader}
              />
              {productList?.length == 0 && (
                <div
                  className="flex items-center gap-2 cursor-pointer pt-2"
                  onClick={() => {
                    // settogglecat(true);
                    navigate('/dashboard/account/inventory-operations/products')
                  }}
                >
                  <p className="text-sm">
                    {selectedLanguage?.name == "English" ? "No product found. Click " : translation[1011]?.value ? translation[1011]?.value : "No product found. Click "}
                    <span className="underline mx-px text-[#5679FF]">
                      {selectedLanguage?.name == "English" ? "here " : translation[502]?.value ? translation[502]?.value : "here "}
                    </span>
                    {selectedLanguage?.name == "English" ? "to add." : translation[140]?.value ? translation[140]?.value : "to add."}
                  </p>
                </div>
              )}
            </div>
            <LoginInput
              title={selectedLanguage?.name == "English" ? "Add Quantity" : translation[951]?.value ? translation[951]?.value : "Add Quantity"}
              placeholder={selectedLanguage?.name == "English" ? "Enter quantity" : translation[956]?.value ? translation[956]?.value : "Enter quantity"}
              name={"quantity"}
              type="number"
              value={quantity}
              disabled={!product}
              onBlur={() => onBlurHandler({ quantity: quantity })}
              onChange={(e) => consumptionHandleChange(e)}
              error={errors?.quantity}
            />
            {filterObjectBasedOnProductLocation.current_stock != undefined && (
              <>
                {selectedLanguage?.name == "English" ? "The available quantity of the product is "
                  : translation[1249]?.value ? translation[1249]?.value : "The available quantity of the product is "
                }
                {filterObjectBasedOnProductLocation.current_stock}
              </>
            )
            }
          </div>
          {/* <Dropwdown
            title="Select Location"
            required={true}
            placeholder="Select Location"
            name="location"
            value={location}
            onBlur={() => onBlurHandler({ location: location })}
            options={locationList}
            onChange={(e) => {
              consumptionHandleChange(e);
            }}
            error={errors?.location}
          /> */}
          <div className="flex items-center gap-3 justify-end mt-2">
            <LoginBtn
              loading={loading}
              disabled={loading}
              text={`${editData && isEdit ? "Update" : selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}`}
              onClick={() => {
                if (isEdit && editData) {
                  consumptionEditHandler(editData, handleClose);
                } else {
                  onConsumptionSubmit(handleClose);
                }
              }}
            // disabled={disbledValidation ||
            //   !product}
            />
          </div>
        </div>
      </Popup>
    </>
  );
};
export default ConsumptionPopup;
