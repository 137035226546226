import Svgs from 'Assets/svgs'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import CheckboxCard from 'Components/TenantApp/Elements/CheckboxCard';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import InputSection from 'Components/TenantApp/Elements/InputSection';
import InputWithIcon from 'Components/TenantApp/Elements/InputWithIcon';
import I_Info_Icon from 'Components/TenantApp/Elements/I_Info_Icon';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import RadioBtn from 'Components/TenantApp/Elements/RadioBtn';
import SelectedOption from 'Components/TenantApp/Elements/SelectedOption';
import ToggleWithText from 'Components/TenantApp/Elements/ToggleWithText';
import { WEEKDAYS } from 'Constants/Data/Months';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb';

const CreateSpendDiscount = () => {
    const navigate = useNavigate();

    return (
        <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right' >
            
            <Breadcrumb 
                first={'Clients'} 
                url={'/dashboard/clients/'} 
                second={'Discount & Promotions'} 
                secondurl={'/dashboard/clients/discounts-promotions'} 
                third='Spend and Get Discount' 
            />
            <div className='flex items-center sm:gap-4 gap-2 my-[1rem]'>
                
                <div className='flex items-center gap-4 justify-between w-full'>
                    <div className='flex items-center gap-3'>
                        <Svgs.Back link='/dashboard/clients/discounts-promotions' />
                        <h2 className='text-primary font-bold lg:text-3xl text-xl cursor-pointer'>Spend and Get Discount</h2>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 md:w-[40vw]'>
                <div className='border rounded-lg p-3 flex flex-col gap-3'>
                    <p className='text-sm'>Spend entered amount on a service and get discount on specific product/service.</p>
                    <div className='flex items-center gap-3'>
                        <div className='flex flex-col gap-1'>
                            <p className='text-sm'>Spend Amount</p>
                            <InputSection placeholder={'300'} leftText='AED' />
                        </div>
                        <Dropwdown title={'Spend Amount on'} placeholder={'Percentage'} />
                    </div>
                    <div className='grid lg:grid-cols-3 gap-3'>
                        <Dropwdown title={'Select Category'} placeholder={'All'} />
                        <Dropwdown title={'Select Product'} placeholder={'Product'} />
                        <Dropwdown title={'Discount Value'} type='number' placeholder={'20%'} />
                    </div>
                </div>

                <div className='border rounded-lg p-3 flex flex-col gap-7'>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>Date Restrictions</h1>
                        <div className='grid grid-cols-2 gap-3'>
                            <LoginInput title={'Start Date'} type='date' />
                            <LoginInput title={'End Date'} type='date' />
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>Add Block Date</h1>
                        <div className='flex items-end gap-3'>
                            <LoginInput title={'Add Date'} type='date' parentClass='flex-1' />
                            <LoginBtn text={<div className='flex items-center gap-2'>
                                <Svgs.Plus />
                                <p>Add</p>
                            </div>} />
                        </div>
                        <div className='flex items-center gap-2 flex-wrap'>
                            <SelectedOption rounded={'full'} name='12/16/2022' />
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText textClass={'!text-lg'} title='Store Restrictions' />
                        <div className='flex items-end gap-3'>
                            <Dropwdown placeholder={'Store'} title={'Select Store'} parentClass='flex-1' />
                            <LoginBtn text={<div className='flex items-center gap-2'>
                                <Svgs.Plus />
                                <p>Add</p>
                            </div>} />
                        </div>
                        <div className='flex items-center gap-2 flex-wrap'>
                            <SelectedOption rounded={'full'} name='Bahadurabad Chowrangi' />
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText textClass={'!text-lg'} title='Day Restrictions' />
                        <div className='grid grid-cols-2 gap-4'>
                            {
                                WEEKDAYS.map(ele => {
                                    return <CheckBox label={ele} />
                                })
                            }
                        </div>
                    </div>
                </div>
                <LoginBtn text={'Save'} />
            </div>
        </div>
    )
}

export default CreateSpendDiscount