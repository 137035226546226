import moment from "moment";

export const generateUniqueCode = (codeLength, timeStampLength) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

    // Generate a random 6-character code with only alphabets
    const randomCode = Array.from({ length: codeLength }, (_, index) => {
        const charIndex = moment().milliseconds() % characters.length;
        return characters.charAt((charIndex + index) % characters.length);
    }).join('');

    // Get the current timestamp as a string (including milliseconds) and limit it to timeStampLength
    const timestamp = moment().format('YYYYMMDDHHmmssSSS').slice(-timeStampLength);

    // Combine letters and numbers in the desired format
    let formattedCode = '';
    for (let i = 0; i < randomCode.length || i < timestamp.length; i++) {
        if (i < randomCode.length) {
            formattedCode += randomCode[i];
        }
        if (i < timestamp.length) {
            formattedCode += timestamp[i];
        }
    }

    return formattedCode;
};
