import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyChktGYACm_gF3E1dEhpUdxDb-S_pf_3uU",
    authDomain: "nstyle-361807.firebaseapp.com",
    projectId: "nstyle-361807",
    // storageBucket: "nstyle-361807.appspot.com",
    // messagingSenderId: "203132762644",
    appId: "1:203132762644:web:d52546404ac522762dc501",
    // measurementId: "G-PS7GHPQL3H"
};
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app();
}
// export const db = firebase.firestore()
export const GoogleAuthprovider = new firebase.auth.GoogleAuthProvider();

export default firebase