import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getClientGroup, getClientGroupWithNoPagination } from "../../../../../Adapters/Api/clientGroup";
import Svgs from "../../../../../Assets/svgs";
import CircleIcon from "../../../Elements/CircleIcon";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import LoginInput from "../../../Elements/LoginInput";
import Popup from "../../../Elements/Popup";
import useClientGroup from "./useClientGroup";
import NotFound from "./../../../../Utility/NotFound";
import { getClient, getClientDropDownList } from "../../../../../Adapters/Api/clients";
import ClientGroupCard from "./ClientGroupCard";
import PreloaderFull from "../../../Elements/PreloaderFull";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import IconButton from "Components/TenantApp/Elements/IconButton";
import Pagination from "new-components/Pagination";
import NoClientGroupFound from "Components/Utility/NoClientGroupFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";

let timeOutId = undefined;
const ClientGroups = () => {
  const state = useSelector((state) => state);
  const clientRedux = state?.client?.clients;
  const clientGroupRedux = state?.clientGroup?.clientGroups;
  const pages = state?.clientGroup?.pages;
  const allCount = state?.clientGroup?.allCount
  const allClientGroupRedux = state?.clientGroup?.allClientGroups;
  const clientGroups_updated = state?.clientGroup?.clientGroups_updated;
  const PageSize = 10
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [exportLoader, setExportLoader] = useState(false);

  const { clientGroup, setIsEdit, loading, clientOptionList, isEdit, clientGroupHandleChange, onClientGroupSubmit, errors, onBlurHandler,
    clientModalHandler, ShowDetails, setShowDetails, AddClient, clientGroupEditHandler, clientGroupDeleteHandler, setClientGroup,
    translation, selectedLanguage, search_text, setSearchText, currentPage, setCurrentPage, formRef, loader, setLoader, isToastVisible,
    setIsToastVisible, setNoPaginationLoader, noPaginationLoader
  } = useClientGroup();
  const { name, email, client } = clientGroup;

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    if (search_text === null) {
      // Initial load with empty search_text
      dispatch(getClientGroup(currentPage, "", setLoader))
      dispatch(getClientGroupWithNoPagination("", setNoPaginationLoader))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(getClientGroup(currentPage, search_text, setLoader))
        dispatch(getClientGroupWithNoPagination(search_text, setNoPaginationLoader))
      }, 500);
    }
  }, [currentPage, search_text])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  useEffect(() => {
    dispatch(getClientDropDownList())
  }, [])

  const clientGroupHeader = [
    { label: selectedLanguage?.name == "English" ? 'Group Name' : translation[546]?.value ? translation[546]?.value : "Group Name", key: "group_name" },
    { label: selectedLanguage?.name == "English" ? 'Total No Of Clients' : translation[548]?.value ? translation[548]?.value : "Total No Of Clients", key: "total_client" },
    { label: selectedLanguage?.name == "English" ? 'Group Email' : translation[549]?.value ? translation[549]?.value : "Group Email", key: "email" },
    { label: selectedLanguage?.name == "English" ? 'Create at' : translation[561]?.value ? translation[561]?.value : "Create at", key: "created_at" },
  ];

  const csvReport = {
    filename: "Client Groups Record.csv",
    headers: clientGroupHeader,
    data: allClientGroupRedux?.length > 0
      ? allClientGroupRedux?.map((item) => {
        return {
          group_name: item?.name ? item?.name : "----",
          total_client: item?.client.length > 0 ? item?.client.length : 0,
          email: item?.email ? item?.email : "----",
          created_at: item?.created_at
            ? item?.created_at?.split("T")[0]
            : "----",
        };
      })
      : [],
  };

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allClientGroupRedux?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  // const onImportClientGroup = (file) => {
  //   console.log("hellow");
  // };

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <p className="text-[#7B7B7B] text-sm">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/clients/");
            }}
          >
            {selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
          </span>{" "}
          &gt; <span className="text-primary font-semibold">{selectedLanguage?.name == "English" ? "Client Groups" : translation[440]?.value ? translation[440]?.value : "Client Groups"}</span>
        </p>
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl whitespace-nowrap">
              {selectedLanguage?.name == "English" ? "Client Groups" : translation[440]?.value ? translation[440]?.value : "Client Groups"}
            </h2>
          </div>
          <div className="flex items-center sm:gap-4 gap-2">
            {/* <LoginBtn className='rounded-lg border-2 border-primary' bg='bg-white' >
                            <div className='flex items-center gap-2 text-[#101928]' onClick={() => { navigate('/dashboard/clients/client-profiles/add-client') }}>
                                <Svgs.Plus color='#101928' />
                                <p className='font-semibold'>Add Client</p>
                            </div>
                </LoginBtn> 
            */}
            {loadingPermission ?
              <Shimmer className={'w-40 lg:w-44'}>
                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
              </Shimmer>
              : !employee || (employee && employeePermissions?.client_groups?.includes("create")) ? (
                <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
                  <LoginBtn
                    onClick={() => {
                      clientModalHandler();
                      setIsEdit(false);
                    }}
                    text={
                      <div className="flex items-center gap-2">
                        <Svgs.Plus />
                        <p className="">{selectedLanguage?.name == "English" ? "Add Group" : translation[542]?.value ? translation[542]?.value : "Add Group"}</p>
                      </div>
                    }

                  ></LoginBtn>
                </div>
              ) : (
                ""
              )}
          </div>
        </div>
        <div className="border rounded-lg">
          <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl whitespace-nowrap">{selectedLanguage?.name == "English" ? "Client Groups" : translation[440]?.value ? translation[440]?.value : "Client Groups"}</h2>
                {(!clientGroups_updated || loader) ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">
                      {allCount ? allCount : 0}{" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Groups" : translation[543]?.value ? translation[543]?.value : "Groups"
                        : selectedLanguage?.name == "English" ? "Group" : translation[1322]?.value ? translation[1322]?.value : "Group"
                      }
                    </p>
                  </div>
                }
              </div>
            </div>

            {loadingPermission ?
              <EmployeesHeadShimmer classOn2={'!w-12 lg:!16'} classOn1={'!w-32 lg:!w-44 sm:!w-40'} />
              :
              <div className="flex items-center gap-3 justify-between w-fit">
                <div className="mb-00">
                  <LoginInput
                    title=""
                    placeholder={selectedLanguage?.name == "English" ? "Search Client Group" : translation[1675]?.value ? translation[1675]?.value : "Search Client Group"}
                    padding="py-1"
                    value={search_text}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    leftIcon={<Svgs.Search />}
                  />
                </div>
                {exportLoader ? (
                  <div className="h-[4rem] flex items-center justify-center">
                    <PreloaderSm />
                  </div>
                ) :
                  !employee ? (
                    allClientGroupRedux?.length > 0 ?
                      <div className="flex items-center gap-3">
                        {/* <label
                    onClick={() => {
                      let inp = document.querySelector(".inppp");
                      inp.click();
                    }}
                    htmlFor="2312"
                    className="cursor-pointer"
                  >
                    <LoginBtn
                      className="rounded-lg border border-primary text-sm"
                      
                      onClick={() => {
                        return false;
                      }}
                    >
                      <div className="flex items-center gap-2">
                        <Svgs.Import fill="#fff" />
                      </div>
                    </LoginBtn>
                  </label>
                  <input
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                      onImportClientGroup(e.target.files[0]);
                    }}
                    id="2312"
                    hidden
                    className="inppp"
                  /> */}

                        <CSVLink {...csvReport} target="_blank" onClick={handleExportClick}>
                          <IconButton filled>
                            <Svgs.Export />
                          </IconButton>
                        </CSVLink>
                      </div> :
                      <LoginBtn
                        className="rounded-lg border-2 border-primary"
                        bg="bg-white"
                        animation={false}
                        onClick={handleExportClick}
                      >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                          <Svgs.Export bg="#FFFFFF" />
                        </div>
                      </LoginBtn>
                  ) : (
                    ""
                  )}
              </div>
            }
          </div>
          {(!clientGroups_updated || loader) ?
            <TableShimer cols={5} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(11.875rem,1fr))] ml-30px px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                <p className="text-cente">{selectedLanguage?.name == "English" ? "Group Name" : translation[546]?.value ? translation[546]?.value : "Group Name"}</p>
                <p className="text-center">{selectedLanguage?.name == "English" ? "Total No of Clients" : translation[548]?.value ? translation[548]?.value : "Total No of Clients"}</p>
                <p className="text-center">{selectedLanguage?.name == "English" ? "Group Email" : translation[549]?.value ? translation[549]?.value : "Group Email"}</p>
                <p className="text-center">{selectedLanguage?.name == "English" ? "Group Created On" : translation[550]?.value ? translation[550]?.value : "Group Created On"}</p>
                <p className="text-center">{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
              </div>
              {clientGroupRedux &&
                clientGroupRedux?.length > 0 ? (
                clientGroupRedux?.map((item) => {
                  return (
                    <div className={`odd-bg text-[#3A3A3A] transition-all `}>
                      <ClientGroupCard
                        item={item}
                        key={item.id}
                        setShowDetails={setShowDetails}
                        ShowDetails={ShowDetails}
                        clientGroupEditHandler={clientGroupEditHandler}
                        clientGroupDeleteHandler={clientGroupDeleteHandler}
                      />
                    </div>
                  );
                })
              ) : (
                <>
                  <NoClientGroupFound />
                </>
              )}
            </div>
          }

          {(clientGroupRedux?.length > 0 && !loader) &&
            <Pagination
              currentPage={currentPage}
              totalCount={Math.ceil(pages)}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          }
        </div>
      </div>
      {
        AddClient && (
          <>
            <Popup
              heading={`${isEdit ?
                selectedLanguage?.name == "English" ? 'Update Client Group' : translation[553]?.value ? translation[553]?.value : "Update Client Group" :
                selectedLanguage?.name == "English" ? 'Add Client Group' : translation[552]?.value ? translation[552]?.value : "Add Client Group"}`}
              close={clientModalHandler}
              open={AddClient}
            >
              <div className="flex flex-col gap-4" ref={formRef}>
                <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[0.5rem]">
                  <CircleIcon>
                    <Svgs.Staff_Group />
                  </CircleIcon>
                  <div className="flex flex-col gap-1 lg:text-left text-center">
                    <h2 className="text-primary font-semibold text-xl">
                      {selectedLanguage?.name == "English" ? "Client Group" : translation[554]?.value ? translation[554]?.value : "Client Group"}
                    </h2>
                    <p className="text-[#7B7B7B] text-sm">
                      {selectedLanguage?.name == "English" ? "Provide information about the client groups" : translation[555]?.value ? translation[555]?.value : "Provide information about the client groups"}{" "}
                      <br className="hidden md:block" />
                    </p>
                  </div>
                </div>
                <LoginInput
                  required={true}
                  name="name"
                  value={name}
                  onChange={clientGroupHandleChange}
                  error={errors?.name}
                  // charactersMaxLength={25}
                  onBlur={() => onBlurHandler({ name: name })}
                  onEnterSubmit={onClientGroupSubmit}
                  title={selectedLanguage?.name == "English" ? "Client Group Name" : translation[556]?.value ? translation[556]?.value : "Client Group Name"}
                  placeholder={selectedLanguage?.name == "English" ? "Enter Client Group Name" : translation[557]?.value ? translation[557]?.value : "Enter Client Group Name"}
                  errorClass={"!static"}
                />
                <LoginInput
                  required={false}
                  name="email"
                  value={email}
                  onChange={clientGroupHandleChange}
                  onEnterSubmit={onClientGroupSubmit}
                  error={errors?.email}
                  title={selectedLanguage?.name == "English" ? "Client Group Email" : translation[558]?.value ? translation[558]?.value : "Client Group Email"}
                  placeholder={selectedLanguage?.name == "English" ? "Enter Client Group Email" : translation[559]?.value ? translation[559]?.value : "Enter Client Group Email"}
                  errorClass={"!static"}
                />
                <Dropwdown
                  errorClass={"!static"}
                  required={true}
                  name="client"
                  value={client}
                  onChange={clientGroupHandleChange}
                  // onBlur={() =>
                  //   onBlurHandler({ client: client.length > 0 ? client[0] : "" })
                  // }
                  error={errors?.client}
                  title={selectedLanguage?.name == "English" ? "Add Client" : translation[486]?.value ? translation[486]?.value : "Add Client"}
                  placeholder={selectedLanguage?.name == "English" ? "Select Client" : translation[225]?.value ? translation[225]?.value : "Select Client"}
                  options={clientOptionList?.filter((itm) => {
                    if (!client.includes(itm.value)) {
                      return itm;
                    }
                    return false;
                  })}
                />
                {client.length > 0 && (
                  <div className="flex items-center gap-3 flex-wrap">
                    {client &&
                      client?.map((item) => {
                        let selected_client = clientRedux?.find(
                          (empl) => empl?.id == item
                        );
                        return (
                          <div className="px-1 py-1 rounded-lg items-center gap-3 flex justify-between border-[2px] border-primary w-max text-primary cursor-pointer">
                            <div
                              className="w-8 h-8 flex items-center bg-center bg-cover bg-no-repeat justify-center rounded-sm "
                              style={{
                                backgroundImage: `url('${selected_client?.image
                                  ? selected_client?.image
                                  : ""
                                  }')`,
                              }}
                            >
                              {!selected_client?.image && <Svgs.Employee />}
                            </div>
                            <div>{selected_client?.full_name}</div>

                            <Svgs.Remove
                              onClick={() => {
                                setClientGroup({
                                  ...clientGroup,
                                  client: [...client.filter((id) => id != item)],
                                });
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                )}

                <div className="flex items-center justify-end">
                  <LoginBtn
                    loading={loading}
                    onClick={onClientGroupSubmit}
                    disabled={loading}
                    // || !name
                    // // || !email
                    // || !client}
                    text={`${isEdit ?
                      selectedLanguage?.name == "English" ? 'Update Group' : translation[560]?.value ? translation[560]?.value : "Update Group" :
                      selectedLanguage?.name == "English" ? 'Add Group' : translation[542]?.value ? translation[542]?.value : "Add Group"}`}
                  ></LoginBtn>
                </div>
              </div>
            </Popup>
          </>
        )
      }
    </>
  );
};

export default ClientGroups;
