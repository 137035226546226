import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const AccountSelectionCard = (props) => {
    const navigate = useNavigate();
    const state = useSelector((state) => state)

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    const progressPercentage = (+(props?.progressData?.completed_modules / props?.progressData?.total_modules) * 100)?.toFixed(0)

    return (
        <div onClick={() => {
            navigate(`/dashboard/account/${props.link}`)
        }} className={`bg-[#fff] shadow-login-card border-transparent border-2 cursor-pointer transition-all ease-in rounded-2xl p-5 flex items-center gap-5 lg:w-[75%] w-full`}>
            {
                props?.showProgress && props?.progressData && progressPercentage < 100 && <div className="absolute top-2 right-4 flex flex-col gap-2">
                    <span className={`font-semibold`}>
                        {progressPercentage} %
                        {" "}{selectedLanguage?.name == "English" ? "setup completed" : translation[653]?.value ? translation[653]?.value : "setup completed"}
                    </span>
                    <div class="w-[18.75rem] bg-white rounded-full h-1.5 border relative border-[#68D087]">
                        <div class="bg-[#68D087] rounded-full absolute inset-0" style={{ width: Math.round((+(props?.progressData?.completed_modules / props?.progressData?.total_modules) * 100)?.toFixed(0)) + '%' }}></div>
                    </div>
                </div>
            }
            {props.icon}
            <div className=' w-full flex items-center sm:gap-4 gap-2'>
                <div className='flex-1'>
                    <h2 className='font-semibold text-black'>{props.heading}</h2>
                    <p className="text-[#7B7B7B] text-sm">{props.paragraph}</p>
                </div>
                <svg width="0.875rem" height="1.5rem" viewBox="0 0 19 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 31L16.5 16.5L2 2" stroke="#101928" strokeWidth="3.625" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>
    )
}

export default AccountSelectionCard