import React, { useState } from "react";
import { toast } from "react-toastify";

import LoginCardBlock from "./LoginCardBlock";
import LoginWithGoogle from "Components/NstyleApp/SocialsLogin/LoginWithGoogle";
import LoginWithFacebook from "Components//NstyleApp/SocialsLogin/LoginWithFacebook";

import { userLogin } from "Adapters/Api";

const ChooseSectionLogin = ({ className, section }) => {
  const SocialLogin_Handler = (user) => {
    userLogin(
      {
        email: user.email,
        social_account: true,
        social_platform: user.social_platform,
      },
      (result) => {
        let data = result?.data?.response?.data;
        let hostName = process.env.REACT_APP_SERVER_APPLICATION_DOMAIN
        if (typeof window !== 'undefined') {
          hostName = window.location.host;
        }
        const hostArr = hostName.split(".");
        hostArr.unshift(`${data.domain ? data.domain : null}`);
        const domain = hostArr.join(".");
        window.location = `http://${domain}/authentication/login/auto_login/?user_login_id=${data.id}&access_token=${data.access_token}&next_path=/dashboard&redirection_source=nstyle_app&redirection_purpose=account_and_mobile_otp_verification${data?.selected_location ? `&selected_location=${data?.selected_location}` : ''}`;

        toast.success("Authenticated successful", { toastId: "toast" });
      },
      (err) => {
        toast.error("Unable to Login, Something went wrong", { toastId: "toast" });
      }
    );
  };

  return (
    <div
      className={`flex flex-1 flex-col gap-5 justify-center ${className} py-[3rem]`}
    >
      <h1 className="text-3xl font-light text-[#7B7B7B] lg:text-left text-center">
        We are <span className="font-semibold text-black">NStyle</span>
      </h1>
      <p className="text-[#7B7B7B] lg:text-left text-center text-sm">
        Welcome to NStyle, Please sign in your account.
      </p>
      <LoginCardBlock
        active={true}
        onClick={() => section(2)}
        heading="Sign In with Email"
        desc={false}
      >
        <svg
          width="33"
          height="23"
          viewBox="0 0 33 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31.4669 0.257568H1.0859C0.771701 0.257568 0.51709 0.512241 0.51709 0.826381V21.5266C0.51709 21.8407 0.771701 22.0954 1.0859 22.0954H25.0387C25.3529 22.0954 25.6076 21.8407 25.6076 21.5266C25.6076 21.2124 25.3529 20.9578 25.0387 20.9578H2.60175L12.9295 11.9323L15.9021 14.53C16.0093 14.6237 16.1429 14.6706 16.2764 14.6706C16.41 14.6706 16.5436 14.6238 16.6508 14.53L19.6236 11.932L29.9516 20.9577H27.2003C26.8861 20.9577 26.6315 21.2124 26.6315 21.5265C26.6315 21.8407 26.8861 22.0953 27.2003 22.0953H31.4669C31.7811 22.0953 32.0357 21.8407 32.0357 21.5265V0.826381C32.0357 0.51218 31.7811 0.257568 31.4669 0.257568ZM29.6788 2.38895C29.472 2.15238 29.1127 2.12818 28.8762 2.33496L16.2764 13.3462L9.13552 7.1056C8.89894 6.89894 8.5398 6.92307 8.3329 7.15958C8.12618 7.39616 8.15038 7.75548 8.38689 7.9622L12.0651 11.1767L1.65471 20.2745V2.07894L6.61391 6.4128C6.72182 6.50705 6.85522 6.55334 6.98807 6.55334C7.14652 6.55334 7.30418 6.48754 7.41658 6.35882C7.6233 6.12224 7.59911 5.76292 7.3626 5.5562L2.60126 1.39519H30.8981V20.2741L20.4879 11.1766L29.6248 3.19163C29.8613 2.98485 29.8855 2.62553 29.6788 2.38895Z"
            fill="#101928"
          />
        </svg>
      </LoginCardBlock>
      {/* <LoginWithGoogle onVerify={SocialLogin_Handler} />
      <LoginWithFacebook
        onVerify={(user) => {
          SocialLogin_Handler({ ...user, social_platform: "Facebook" });
        }}
      /> */}
    </div>
  );
};

export default ChooseSectionLogin;
