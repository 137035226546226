import { ABSENCE } from "Redux/TenantRedux/ActionTypes/absenceTypes";

const employeeAbsences = {
    absences: [],
    allAbsences: [],
    absence_updated: false,
    pages: 0,
    allCount: 0
};

const AbsenceReducer = (state = employeeAbsences, action) => {
    const { type, payload } = action;

    switch (type) {
        case ABSENCE.GET_ABSENCES:
            return {
                ...state,
                absences: payload,
                absence_updated: true,
            };

        case ABSENCE.GET_ABSENCES_WITHOUT_PAGINATION:
            return {
                ...state,
                allAbsences: payload,
                absence_updated: true,
            };

        case ABSENCE.ADD_ABSENCE:
            return {
                ...state,
                absences: [payload, ...state.absences],
            };
        case ABSENCE.GET_ABSENCE_PAGES:
            return {
                ...state,
                pages: payload?.pages,
                allCount: payload?.count
            };

        case ABSENCE.UPDATE_ABSENCE:
            let updateList = state?.absences?.map((item) => {
                if (item.id === payload.id) {
                    return payload;
                } else {
                    return item;
                }
            });
            return {
                ...state,
                absences: updateList,
            };
        case ABSENCE.DELETE_ABSENCE:
            let filterArrayAfterDeletion = state?.absences?.filter((absence) => {
                if (absence?.id !== payload) {
                    return absence;
                }
            });
            return {
                ...state,
                absences: filterArrayAfterDeletion,
            };
        default:
            return state;
    }
};

export default AbsenceReducer;
