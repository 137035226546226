import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckBox from "Components/TenantApp/Elements/Checkbox/CheckBox";
import { SALE_SERVICES } from "Redux/TenantRedux/ActionTypes/saleServiceType";
import TotalCount from "Components/TenantApp/Elements/TotalCount";
import useProfile from "../Profile/helper";
import Svgs from "Assets/svgs";
import { toast } from "react-toastify";

const ServiceTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    visitedLocation,
    serviceOfSelectedLocation,
    selectedService,
    setSelectedService,
    selectedProfileService,
    token,
    Selected,
    setSelected,
    next,
  } = useProfile();

  return (
    <>
      <div className="flex flex-col gap-4">
        <h1 className="font-semibold text-2xl">Services</h1>
        <div className="border rounded-lg grid grid-cols-4">
          <div className="p-3 flex flex-col gap-3 border-r col-span-1 max-h-[19rem] overflow-auto">
            {serviceOfSelectedLocation()?.map((itm, i) => {
              return (
                <>
                  <div
                    onClick={() => {
                      setSelected(itm);
                    }}
                    className={`rounded-md transition-all ${Selected?.id == itm?.id || (!Selected && i === 0)
                      ? "bg-[#F2F5FF]"
                      : "bg-transparent"
                      } hover:bg-[#f5f5f5] flex items-center justify-between gap-3 p-3 cursor-pointer`}
                  >
                    <h1 className="font-semibold">{itm?.name}</h1>
                    <div>
                      <TotalCount
                        bg="#FFFFFF"
                        textColor={"#70757E"}
                        count={itm?.services?.length}
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="p-4 flex flex-col gap-3 col-span-3 max-h-[19rem] overflow-auto">
            {(Selected
              ? Selected
              : serviceOfSelectedLocation()?.length > 0 && serviceOfSelectedLocation()[0]
            )?.services?.length > 0 ?

              (Selected
                ? Selected
                : serviceOfSelectedLocation()[0]
              )?.services?.map((itm, i, arr) => {
                return (
                  <>
                    {itm?.priceservice?.filter((dt) => dt?.currency == visitedLocation?.currency?.id).map((item) => {
                      return (
                        <>
                          <div className="flex items-center gap-1">
                            <div>
                              <CheckBox
                                disabled={visitedLocation?.banking != "Enable"}
                                onChange={(e) => {
                                  if (token) {
                                    dispatch({
                                      type: SALE_SERVICES.PROFILE_SELECTED_LOCATION,
                                      payload: serviceOfSelectedLocation(),
                                    });

                                    dispatch({
                                      type: SALE_SERVICES.PROFILE_SELECTED_SERIVCE,
                                      payload: {
                                        ...item,
                                        employees: itm?.employees,
                                        service_name: itm?.name,
                                        group_id: Selected ? Selected?.id : serviceOfSelectedLocation()[0]?.id,
                                        group: Selected ? Selected : serviceOfSelectedLocation()[0],
                                        location: visitedLocation,
                                        business_address: visitedLocation?.business_address,
                                        business: visitedLocation?.business,
                                        hash: visitedLocation?.hash,
                                        token: visitedLocation?.token,
                                        next: visitedLocation?.next,
                                      },
                                    });

                                    setSelectedService(item);
                                    navigate("/profile-appointment");
                                  } else {
                                    setTimeout(() => {
                                      toast.error("Please login to continue", { toastId: "toast" });
                                    }, 300);
                                    // navigate(`/auth/account-type/?next=${next}`);
                                    //   navigate(
                                    //     `/auth/signup?account_type=everyone&signup_type=email&actives=personal_information&next=${next}`
                                    //   );
                                  }
                                }}
                                checked={item?.id === selectedProfileService?.id}
                              />
                            </div>

                            <div className="flex-1 flex items-center gap-5 justify-between">
                              <div className="flex items-center gap-2">
                                <div>
                                  <div className="h-[3.5rem] w-[3.5rem] rounded-full overflow-hidden flex items-center justify-center border bg-[#BAD3FC]">
                                    <Svgs.NewService />
                                  </div>
                                </div>
                                <div>
                                  <h1 className="font-semibold">{itm?.name}</h1>
                                  <p className="text-xs text-[#101928]/60">
                                    {item?.duration?.replaceAll("_", " ")}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <h1 className="font-semibold text-lg">
                                  {item?.price} {visitedLocation?.currency?.code}
                                </h1>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      )
                    })}
                  </>
                );
              }) :
              <div className="flex flex-col items-center gap-5 justify-center">
                <h3>No Service Available</h3>
              </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceTable;
