import React from "react";

const CheckboxCard = ({
  title,
  value,
  id,
  checked,
  onClick,
  name,
  error,
  padding,
  disabled,
  onChange,
}) => {
  return (
    <>
      <div
        onClick={disabled ? () => {} : onClick}
        className={`${
          disabled && "opacity-[0.3] cursor-not-allowed"
        }  cursor-pointer border rounded-lg  ${
          error && " border-l-[7px] border-[#eb3b3b]"
        } ${
          checked ? "border-l-[7px]  border-primary bg-[#F8FBFB]" : ""
        } px-6 py-3 ${
          padding ? padding : ""
        } flex items-center justify-between gap-5`}
      >
        <p className="font-semibold text-sm">{title}</p>
        <div className="rounded-checkbox">
          <input
            type="checkbox"
            name={name}
            value={value}
            id={`checkbox-${id}`}
            onChange={onChange}
            checked={checked ? checked : false}
            disabled={disabled}
          />
          <label htmlFor={`checkbox-${id}`}></label>
        </div>
      </div>
    </>
  );
};

CheckboxCard.defaultProps = {
  onChange: () => {},
};

export default CheckboxCard;
