/* eslint-disable react/jsx-pascal-case */
import Svgs from "Assets/svgs";
import CheckBox from "Components/TenantApp/Elements/Checkbox/CheckBox";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import I_Info_Icon from "Components/TenantApp/Elements/I_Info_Icon";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import SelectedOption from "Components/TenantApp/Elements/SelectedOption";
import ToggleWithText from "Components/TenantApp/Elements/ToggleWithText";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ToggleRestrictions from "../../CreateDirectFlatDiscount/AddDiscount/ToggleRestrictions";
import { useAddSpecificDiscount } from "./helper";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import { useMemo } from "react";
import FloatInput from "Components/TenantApp/Elements/FloatInput";

const AddDiscount = () => {
  const { errors, toggleDay, toggleStore, discount, loading, locationDropdown, isEdit, serviceGroupDropdown, brandDropdown, dateRangeCheck, toggleService, setToggleService,
    validateBlockDate, handleChange, onBlurHandler, navigate, setDiscount, filterServiceGroupDiscountField, addServiceGroupDiscount, setErrors,
    addStoreRestriction, filterStoreRestriction, onSubmit, addBlockDates, filterBlockDate, setToggleDay, setToggleStore, addBrandRestriction,
    addServiceRestriction, handleDayChecklist, translation, selectedLanguage, formErrors, setFormErrors, serviceToggle, setServiceToggle,
    brandToggle, setBrandToggle, productExcludedDropdown, serviceExcludedDropdown, toggleblockdate, setToggleBlockDate, formRef
  } = useAddSpecificDiscount();
  const {
    groupDiscount,
    dateRestriction,
    blockDate,
    blockDates,
    promotion_name,

    brandRestriction,
    brandRestrictions,

    serviceRestriction,
    serviceRestrictions,

    storeRestriction,
    storeRestrictions,
    dayRestrictions,
  } = discount;

  var minEndDateLimit = useMemo(() => {
    return moment(dateRestriction.startDate).add(1, 'day').format('YYYY-MM-DD')
  }, [dateRestriction.startDate]);

  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">


      <Breadcrumb
        first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
        url={'/dashboard/clients/'}
        second={selectedLanguage?.name == "English" ? "Discount & Promotions" : translation[638]?.value ? translation[638]?.value : "Discount & Promotions"}
        secondurl={'/dashboard/clients/discounts-promotions'}
        third={selectedLanguage?.name == "English" ? " Discount on Specific groups of category" : translation[915]?.value ? translation[915]?.value : " Discount on Specific groups of category"}
      />
      <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

        <div className="flex items-center gap-4 justify-between w-full">
          <div className="flex items-center gap-3">
            <Svgs.Back link="/dashboard/clients/discounts-promotions" />
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Discount on Specific groups of category" : translation[915]?.value ? translation[915]?.value : "Discount on Specific groups of category"}
            </h2>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:w-[40vw]" ref={formRef}>
        <div className="border rounded-lg p-3 flex flex-col gap-3">
          <LoginInput
            name="promotion_name"
            className='!w-fit'
            value={promotion_name}
            title={selectedLanguage?.name == "English" ? "Promotion Name" : translation[883]?.value ? translation[883]?.value : "Promotion Name"}
            placeholder={selectedLanguage?.name == "English" ? "Enter Promotion Name" : translation[1732]?.value ? translation[1732]?.value : "Enter Promotion Name"}
            type='text'
            // charactersMaxLength={25}
            onChange={handleChange}
            error={errors.promotion_name}
          />
          {groupDiscount?.map(
            (obj, index) =>
              obj.is_deleted === "False" && (
                <AddServiceGroupDiscountPercentage
                  obj={obj}
                  index={index}
                  removeServiceGroupDiscountField={filterServiceGroupDiscountField}
                  groupDiscount={groupDiscount}
                  serviceGroupDropdown={serviceGroupDropdown}
                  brandDropdown={brandDropdown}
                  handleServiceGroupDiscount={addServiceGroupDiscount}
                  onBlurHandler={onBlurHandler}
                  errors={errors}
                  translation={translation}
                  selectedLanguage={selectedLanguage}
                  formErrors={formErrors}
                  setFormErrors={setFormErrors}
                />
              )
          )}
          <LoginBtn
            borderBtn
            onClick={() => {
              const errors = [];
              discount.groupDiscount?.forEach((data, index) => {
                const fieldErrors = {};
                if (!data.service_group) {
                  fieldErrors.service_group = 'This field is required';
                }
                if (!data.discount) {
                  fieldErrors.discount = 'This field is required';
                }
                if (!data.brand) {
                  fieldErrors.brand = 'This field is required';
                }
                if (!data.brand_discount) {
                  fieldErrors.brand_discount = 'This field is required';
                }
                errors[index] = fieldErrors;
              });
              // Update formErrors state with the new errors
              setFormErrors(errors);
              // If there are errors, return early and do not proceed with form submission
              if (errors?.some((error) => Object.keys(error)?.length > 0)) {
                formRef.current?.scrollIntoView({ behavior: "smooth" });
                return;
              } else {
                setDiscount((prev) => ({
                  ...prev,
                  groupDiscount: [
                    ...prev?.groupDiscount,
                    {
                      service_group: "", discount: "", brand: '', brand_discount: '', is_deleted: "False"
                    },
                  ],
                }));
              }
            }}
          // disabled={groupDiscount?.find(
          //   (obj) => !obj?.service_group || !obj?.discount || !obj?.brand || !obj?.brand_discount
          // )}
          >
            <Svgs.Plus color="#1C8AFE" /> {selectedLanguage?.name == "English" ? "Add New" : translation[898]?.value ? translation[898]?.value : "Add New"}
          </LoginBtn>
        </div>

        <div className="border rounded-lg p-3 flex flex-col gap-7">
          <div className="flex flex-col gap-4">
            <h1 className="font-semibold text-lg">Date Restrictions</h1>
            <div className="grid grid-cols-2 gap-3">
              <LoginInput
                title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                name="startDate"
                value={dateRestriction.startDate}
                type="date"
                onChange={handleChange}
                onBlur={() =>
                  onBlurHandler({ startDate: dateRestriction.startDate })
                }
                error={errors.startDate}
              />
              <LoginInput
                title={selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                name="endDate"
                value={dateRestriction.endDate}
                type="date"
                onChange={handleChange}
                min={minEndDateLimit}
                onBlur={() =>
                  onBlurHandler({ endDate: dateRestriction.endDate })
                }
                error={errors.endDate}
              />
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <h1 className='font-semibold text-lg'>
              {selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
            </h1>
            <div className="flex items-end gap-3">
              <Dropwdown
                required={true}
                name="storeRestriction"
                placeholder={"Store"}
                title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                parentClass="flex-1"
                options={locationDropdown?.filter(
                  (obj) =>
                    obj.value !==
                    discount?.storeRestrictions?.find(
                      (location) => location === obj.value
                    )
                )}
                value={storeRestriction}
                onChange={handleChange}
                error={errors?.storeRestriction}
              />
              <LoginBtn
                className={storeRestriction && "shake-bottom"}
                text={
                  <div className="flex items-center gap-2">
                    {" "}
                    <Svgs.Plus />
                    <p>
                      {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                    </p>
                  </div>
                }
                onClick={addStoreRestriction}
                disabled={!storeRestriction}
              />
            </div>
          </div>
          <div className="flex items-center gap-2 flex-wrap">
            {storeRestrictions?.map((obj, index) => (
              <SelectedOption
                rounded={"full"}
                name={
                  locationDropdown?.find(
                    (location) => location?.value === obj
                  )?.label
                }
                onClick={() => filterStoreRestriction(index)}
              />
            ))}
          </div>
          {/* <div className="flex flex-col gap-4">
            <ToggleWithText
              textClass={"!text-lg"}
              title={selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
              value={toggleStore}
              onChange={() => setToggleStore(!toggleStore)}
              checked={toggleStore}
            />
            {toggleStore && (
              <>
                <div className="flex items-end gap-3">
                  <Dropwdown
                    name="storeRestriction"
                    placeholder={"Store"}
                    title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                    parentClass="flex-1"
                    options={locationDropdown?.filter(
                      (obj) =>
                        obj.value !==
                        discount?.storeRestrictions?.find(
                          (location) => location === obj.value
                        )
                    )}
                    value={storeRestriction}
                    onChange={handleChange}
                  />
                  <LoginBtn
                    className={storeRestriction && "shake-bottom"}
                    text={
                      <div className="flex items-center gap-2">
                        {" "}
                        <Svgs.Plus />
                        <p>
                          {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                        </p>
                      </div>
                    }
                    onClick={addStoreRestriction}
                    disabled={!storeRestriction}
                  />
                </div>
                <div className="flex items-center gap-2 flex-wrap">
                  {console.log(storeRestrictions, storeRestriction, 'storeRestriction', 'storeRestrictions')}
                  {storeRestrictions?.map((obj, index) => (
                    <SelectedOption
                      rounded={"full"}
                      name={
                        locationDropdown?.find(
                          (location) => location?.value === obj
                        )?.label
                      }
                      onClick={() => filterStoreRestriction(index)}
                    />
                  ))}
                </div>
              </>
            )}
          </div> */}
          <div className="flex flex-col gap-4">
            <ToggleWithText
              textClass={'!text-lg'}
              title={selectedLanguage?.name == "English" ? "Add Block Date" : translation[902]?.value ? translation[902]?.value : "Add Block Date"}
              value={toggleblockdate}
              onChange={() => setToggleBlockDate(!toggleblockdate)}
              checked={toggleblockdate}
            />
            {toggleblockdate &&
              <>
                <div className="flex items-end gap-3">
                  <LoginInput
                    name="blockDate"
                    title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                    type="date"
                    parentClass="flex-1"
                    required={false}
                    value={blockDate}
                    min={dateRestriction.startDate}
                    max={dateRestriction.endDate}
                    onChange={handleChange}
                    error={errors.blockDate}
                    disabled={
                      !discount.dateRestriction.startDate ||
                      !discount.dateRestriction.endDate
                    }
                  />
                  <LoginBtn
                    className={blockDate && "shake-bottom"}
                    text={
                      <div className="flex items-center gap-2">
                        <Svgs.Plus />
                        <p>
                          {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                        </p>
                      </div>
                    }
                    onClick={addBlockDates}
                    disabled={
                      !dateRangeCheck ? true : false ||
                        !discount.dateRestriction.startDate ||
                        !discount.dateRestriction.endDate ||
                        !blockDate ||
                        validateBlockDate ? true : false
                    }
                  />
                </div>
                <div className="flex items-center gap-2 flex-wrap">
                  {blockDates?.map((obj, index) => (
                    <>
                      {obj?.is_deleted === "False" && (
                        <SelectedOption
                          rounded={"full"}
                          name={obj?.date}
                          onClick={() => filterBlockDate(obj?.id, index)}
                        />
                      )}
                    </>
                  ))}
                </div>
              </>
            }
          </div>

          <div className="flex flex-col gap-4">
            <ToggleWithText
              textClass={"!text-lg"}
              title={selectedLanguage?.name == "English" ? "Day Restrictions" : translation[906]?.value ? translation[906]?.value : "Day Restrictions"}
              value={toggleDay}
              onChange={() => setToggleDay(!toggleDay)}
              checked={toggleDay}
            />
            {toggleDay && (
              <div className="grid grid-cols-2 gap-4">
                {console.log(dayRestrictions, 'dayRestrictions')}
                {dayRestrictions.map((item) => {
                  return (
                    <CheckBox
                      name={item.day}
                      label={item.day}
                      value={item.value}
                      onChange={handleDayChecklist}
                      checked={item.value === item.day ? true : false}
                    />
                  );
                })}
              </div>
            )}
          </div>

          <div className="flex flex-col gap-4">
            <ToggleWithText
              textClass={'!text-lg'}
              title={selectedLanguage?.name == "English" ? "Excluded Services" : translation[909]?.value ? translation[909]?.value : "Excluded Services"}
              value={serviceToggle}
              onChange={() => setServiceToggle(!serviceToggle)}
              checked={serviceToggle}
            />

            <ToggleRestrictions
              setDiscount={setDiscount}
              handleChange={handleChange}
              addRestrictions={addServiceRestriction}
              toggleRestrictions={serviceToggle}
              restrictions={serviceRestrictions}
              restriction={serviceRestriction}
              translation={translation}
              selectedLanguage={selectedLanguage}
              type={"serviceRestriction"}
              discount={discount}
              title={selectedLanguage?.name == "English" ? "services that you want to exclude from this offer" : translation[911]?.value ? translation[911]?.value : "services that you want to exclude from this offer"}
              // dropwdown={serviceExcludedDropdown}
              dropwdown={serviceExcludedDropdown.filter(itm => itm.value !== serviceRestrictions?.find(ele => ele?.id === itm.value))}

            />
          </div>
          <div className="flex flex-col gap-4">
            <ToggleWithText
              textClass={'!text-lg'}
              title={selectedLanguage?.name == "English" ? "Excluded Products" : translation[989]?.value ? translation[989]?.value : "Excluded Products"}
              value={brandToggle}
              onChange={() => setBrandToggle(!brandToggle)}
              checked={brandToggle}
            />
            <ToggleRestrictions
              setDiscount={setDiscount}
              handleChange={handleChange}
              addRestrictions={addBrandRestriction}
              toggleRestrictions={brandToggle}
              restrictions={brandRestrictions}
              restriction={brandRestriction}
              translation={translation}
              selectedLanguage={selectedLanguage}
              type={"brandRestriction"}
              title={selectedLanguage?.name == "English" ? "products that you want to exclude from this offer" : translation[990]?.value ? translation[990]?.value : "products that you want to exclude from this offer"}
              // dropwdown={productExcludedDropdown}
              dropwdown={productExcludedDropdown.filter(itm => itm.value !== brandRestrictions?.find(ele => ele?.id === itm.value))}

            />
          </div>
        </div>
        <LoginBtn
          text={isEdit ?
            selectedLanguage?.name == "English" ? "Update" : translation[845]?.value ? translation[845]?.value : "Update"
            :
            selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
          loading={loading}
          disabled={loading}
          // disabled={
          //   loading ||
          //   discount.groupDiscount.length === 0 ||
          //   discount?.groupDiscount?.find(
          //     (obj) => !obj?.service_group || !obj?.discount || !obj?.brand || !obj?.brand_discount
          //   ) ||
          //   !discount.dateRestriction.endDate ||
          //   !discount.promotion_name ||
          //   !discount.dateRestriction.startDate
          //   || errors.endDate
          //   // || discount.blockDates.length === 0
          // }
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default AddDiscount;

const AddServiceGroupDiscountPercentage = ({
  translation, selectedLanguage,
  obj,
  index,
  removeServiceGroupDiscountField,
  serviceGroupDropdown,
  brandDropdown,
  handleServiceGroupDiscount,
  groupDiscount,
  onBlurHandler,
  errors,
  formErrors
}) => {


  const getServiceGroupName = (id) => {
    return serviceGroupDropdown.find((obj) => obj.value === id)?.label;
  }
  const getBrandName = (id) => {
    return brandDropdown.find((obj) => obj.value === id)?.label;
  }

  return (
    <>
      <div className="items-start gap-3 border p-2 rounded-md relative flex-col">

        {index > 0 &&
          <div
            className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 cursor-pointer"
            onClick={() => removeServiceGroupDiscountField(obj?.id, index)}
          >
            <Svgs.CirlceClose fill={"red"} size={"20"} />
          </div>
        }

        <div className='grid lg:grid-cols-2 gap-3'>
          <div>
            <Dropwdown
              required={true}
              name='brand'
              title={selectedLanguage?.name == "English" ? "Brand" : translation[836]?.value ? translation[836]?.value : "Brand"}
              placeholder={selectedLanguage?.name == "English" ? "Select Brand" : translation[984]?.value ? translation[984]?.value : "Select Brand"}
              options={
                // brandDropDown
                !obj.brand ? brandDropdown.filter(obj => {
                  return obj && !groupDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.brand === obj.value)
                })
                  :
                  [{ label: getBrandName(obj?.brand), value: getBrandName(obj?.brand) },
                  ...brandDropdown.filter(obj => {
                    return obj && !groupDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.brand === obj.value)
                  })
                  ]
              }
              value={getBrandName(obj?.brand)}
              onChange={(e) => handleServiceGroupDiscount(e, index)}
              error={formErrors?.length > 0 ? formErrors[index]?.brand : ""}
            />
          </div>
          <FloatInput
            name="brand_discount"
            title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[895]?.value ? translation[895]?.value : "Discount Percentage"}
            placeholder={selectedLanguage?.name == "English" ? "Enter Discount Percentage" : translation[1733]?.value ? translation[1733]?.value : "Enter Discount Percentage"}
            required={true}
            value={obj?.brand_discount}
            onChange={(e) => {
              e.target.value <= 100 &&
                handleServiceGroupDiscount(e, index)
            }}
            isPercentage={true}
            // onBlur={() => onBlurHandler({ [`brand_discount${index}`]: obj?.brand_discount })}
            // error={errors[`brand_discount${index}`]}
            error={formErrors?.length > 0 ? formErrors[index]?.brand_discount : ""}
          />
          <Dropwdown
            required={true}
            name="service_group"
            title={selectedLanguage?.name == "English" ? "Service group" : translation[262]?.value ? translation[262]?.value : "Service group"}
            placeholder={selectedLanguage?.name == "English" ? "Select Service group" : translation[916]?.value ? translation[916]?.value : "Select Service group"}
            // options={serviceGroupDropdown}
            options={
              // serviceGroupDropdown
              !obj.service_group ? serviceGroupDropdown.filter(obj => {
                return obj && !groupDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.service_group === obj.value)
              })
                :
                [{ label: getServiceGroupName(obj?.service_group), value: getServiceGroupName(obj?.service_group) },
                ...serviceGroupDropdown.filter(obj => {
                  return obj && !groupDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.service_group === obj.value)
                })
                ]
            }
            value={getServiceGroupName(obj?.service_group)}
            onChange={(e) => handleServiceGroupDiscount(e, index)}
            // onBlur={() => onBlurHandler({ [`service_group${index}`]: obj?.service_group })}
            // error={errors[`service_group${index}`]}
            error={formErrors?.length > 0 ? formErrors[index]?.service_group : ""}
          />
          <FloatInput
            required={true}
            name="discount"
            title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[895]?.value ? translation[895]?.value : "Discount Percentage"}
            placeholder={selectedLanguage?.name == "English" ? "Enter Discount Percentage" : translation[1733]?.value ? translation[1733]?.value : "Enter Discount Percentage"}
            value={obj?.discount}
            isPercentage={true}
            onChange={(e) => {
              e.target.value <= 100 &&
                handleServiceGroupDiscount(e, index)
            }}
            // onBlur={() => onBlurHandler({ [`discount${index}`]: obj?.discount })}
            // error={errors[`discount${index}`]}
            error={formErrors?.length > 0 ? formErrors[index]?.discount : ""}
          />
        </div>
        {/* <div className="flex">
          <div className="flex-1">
            <Dropwdown
              name="service_group"
              title={
                <I_Info_Icon
                  text="Select Service Group"
                  info="Customers can get mentioned discount on all services of selected service group."
                />
              }
              placeholder="Service"
              value={getServiceGroupName(obj?.service_group)}
              options={
                // serviceGroupDropdown
                !obj.service_group ? serviceGroupDropdown.filter(obj => {
                  return obj && !groupDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.service_group === obj.value)
                })
                  :
                  [{ label: getServiceGroupName(obj?.service_group), value: getServiceGroupName(obj?.service_group) },
                  ...serviceGroupDropdown.filter(obj => {
                    return obj && !groupDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.service_group === obj.value)
                  })
                  ]
              }
              onChange={(e) => handleServiceGroupDiscount(e, index)}
              onBlur={() => onBlurHandler({ [`service_group${index}`]: obj?.service_group })}
              error={errors[`service_group${index}`]}
            />
            {// serviceGroupDropdown
              (!obj.service_group ? serviceGroupDropdown.filter(obj => {
                return obj && !groupDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.service_group === obj.value)
              })
                :
                [{ label: getServiceGroupName(obj?.service_group), value: getServiceGroupName(obj?.service_group) },
                ...serviceGroupDropdown.filter(obj => {
                  return obj && !groupDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.service_group === obj.value)
                })
                ])?.length == 0 && (
                <div
                  className="flex items-center gap-2 cursor-pointer pt-4"
                  onClick={() => {
                    //humayun
                    navigate('/dashboard/account/setup/service-management')
                  }}
                >
                  <p className="text-sm">No service group found. Click <span className="underline">here</span> to add.</p>
                </div>
              )}
          </div>

          <LoginInput
            name="discount"
            title={"Discount Percentage"}
            type="number"
            placeholder={"Percentage"}
            value={obj?.discount}
            onChange={(e) =>
              e.target.value <= 100 && handleServiceGroupDiscount(e, index)
            }
            onBlur={() => onBlurHandler({ [`discount${index}`]: obj?.discount })}
            error={errors[`discount${index}`]}
          />
        </div> */}
      </div>
    </>
  );
};
