import React, { useState, useEffect } from "react";
import Svgs from "../../../../../Assets/svgs";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import LoginInput from "../../../Elements/LoginInput";
import Popup from "../../../Elements/Popup";
import CircleIcon from "../../../Elements/CircleIcon";
import DateInput from "../../../Elements/DateInput";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getSaleOrderProduct } from "../../../../../Adapters/Api/saleOrders";
import Moment from "react-moment";
import { getLocations } from "../../../../../Adapters/Api";
import useSetDataInLocalStorage from "../../../../../Hooks/useSetDataInLocalStorage";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import PreloaderFull from "./../../../Elements/PreloaderFull";

const StaffProdcut = () => {
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const saleProductList = state?.saleOrder?.saleOrderProducts;
  const saleOrderProducts_updated = state?.saleOrder?.saleOrderProducts_updated;

  const locationList = state.locations.locations;
  const [ShowDetails, setShowDetails] = useState(0);
  const [Filter, setFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedStates, setSelectedState] = useState({
    employeeText: "",
    locationText: "",
    productText: "",
    incomeType: "",
    selectedFrom: "",
    selectedTo: "",
  });
  const {
    employeeText,
    locationText,
    productText,
    incomeType,
    selectedFrom,
    selectedTo,
  } = selectedStates;
  const [filterState, setFilterState] = useState({
    employee_name: "",
    income_type: "",
    product: "",
    location: "",
    from: "",
    to: "",
  });
  const { employee_name, income_type, product, location, from, to } =
    filterState;
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    if (saleProductList.length === 0) {
      dispatch(getSaleOrderProduct());
    }
    if (locationList?.length === 0) {
      dispatch(getLocations(businessData?.id));
    }
  }, []);

  //toggle filter modal
  const toggleModal = () => {
    setFilter(!Filter);
    setSelectedState({
      employeeText: "",
      locationText: "",
      productText: "",
      incomeType: "",
      selectedFrom: "",
      selectedTo: "",
    });
  };

  //all filter handle here
  const product_filter = () => {
    const fromDate = Date.parse(from);
    const toDate = Date.parse(to);

    const product_filter = saleProductList?.filter((item) => {
      let createAt = Date.parse(item?.created_at.split("T")[0]);

      if (
        searchText ||
        employee_name ||
        product ||
        location ||
        (fromDate && toDate)
      ) {
        let return_value = false;

        //check search text
        if (searchText) {
          if (
            item.member?.full_name
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            return_value = true;
          } else {
            return false;
          }
        }

        //check filter employee text
        if (employee_name) {
          if (
            item.member?.full_name
              .toLowerCase()
              .includes(employee_name.toLowerCase())
          ) {
            return_value = true;
          } else {
            return false;
          }
        }

        //check filter from to date
        if (fromDate && toDate) {
          if (createAt >= fromDate && createAt <= toDate) {
            return_value = true;
          } else {
            return false;
          }
        }

        //check filter product text
        if (product) {
          if (
            item?.product_name?.toLowerCase().includes(product.toLowerCase())
          ) {
            return_value = true;
          } else {
            return false;
          }
        }

        //check filter location text
        if (location) {
          if (
            item?.location?.address_name
              ?.toLowerCase()
              .includes(location.toLowerCase())
          ) {
            return_value = true;
          } else {
            return false;
          }
        }

        return return_value;
      }
      return true;
    });

    return product_filter;
  };

  //apply filter here
  const applyFilter = () => {
    setFilterState((prev) => ({
      ...prev,
      employee_name: employeeText,
      location: locationText,
      product: productText,
      income_type: incomeType,
      from: selectedFrom,
      to: selectedTo,
    }));

    setFilter(false);
  };

  const filterHandleChange = (e) => {
    const { name, value } = e.target;
    setSelectedState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  let productHeader = [
    { label: "Employee Name", key: "full_name" },
    { label: "Item Sold", key: "item_sold" },
    { label: "Date", key: "date" },
    { label: "Net Sale", key: "net_sale" },
    { label: "Tip", key: "tip" },
    { label: "Location", key: "location" },
    { label: "Tax", key: "tax" },
    { label: "Total Sale", key: "total_sale" },
  ];

  const exportProductSale = {
    filename: "saleProduct.csv",
    headers: productHeader,
    data:
      saleProductList?.length > 0
        ? saleProductList?.map((item) => {
          return {
            full_name: item?.member?.full_name
              ? item?.member?.full_name
              : "----",
            item_sold: item?.sold_quantity ? item?.sold_quantity : 0,
            date: item?.created_at ? item?.created_at?.split("T")[0] : "----",
            net_sale: item?.total_price ? item?.total_price : 0,
            tip: item?.tip ? item?.tip : 0,
            location: item?.location?.address_name
              ? item?.location?.address_name
              : "----",
            tax: item?.gst ? item?.gst : 0,
            total_sale: item?.total_price
              ? item?.total_price + item?.tip + item?.gst
              : 0 + item?.tip + item?.gst,
          };
        })
        : [],
  };

  return !saleOrderProducts_updated ? (
    <PreloaderFull />
  ) : (
    <>
      <div className="border rounded-lg">
        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">Sales Details</h2>
            </div>

            <div className="mb-00">
              <LoginInput
                name="searchText"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                title=""
                placeholder="Search"
                padding="py-1"
                leftIcon={<Svgs.Search />}
              />
            </div>
          </div>
          <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
            <LoginBtn
              onClick={toggleModal}
              padding="px-3 py-2"
              borderBtn

            >
              <div className="flex items-center gap-2 text-[#101928]">
                <Svgs.Filters />
                {/* <p>Filters</p> */}
              </div>
            </LoginBtn>
            {!employee ? (
              <CSVLink
                {...exportProductSale}
                target="_blank"
                onClick={() => {
                  setTimeout(() => {
                    toast.success("File downloaded successfully", { toastId: "toast" });
                  }, 300);
                }}
              >
                <LoginBtn
                  borderBtn

                >
                  <div className="flex items-center gap-2 text-[#101928]">
                    <Svgs.Export bg="#101928" />
                  </div>
                </LoginBtn>
              </CSVLink>
            ) : (
              ""
            )}

            {/* <LoginBtn className='rounded-lg border border-primary text-sm' >
                            <div className='flex items-center gap-2'>
                                <Svgs.Import fill='#fff' />
                                <p className='font-semibold'>Import List</p>
                            </div>
                        </LoginBtn> */}
          </div>
        </div>
        <div className="overflow-x-auto">
          <div className="inline-grid xl:grid grid-cols-[repeat(8,minmax(150px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
            <p>Employee Name</p>
            <p>Item Sold</p>
            <p>Date</p>
            <p>Net Sale </p>
            <p>Tip </p>
            <p>Location</p>
            <p>Tax</p>
            <p>Total Sale</p>
            {/* <p>Actions</p> */}
          </div>
          <div
            className={`odd-bg text-[#3A3A3A] transition-all ${ShowDetails
              ? "border-l-[4px] border-[#5679FFB2]"
              : "border-l-[0px]"
              }`}
          >
            {saleProductList.length > 0 &&
              product_filter()?.map((prdct, i) => {
                return (
                  <>
                    <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(8,minmax(150px,1fr))]">
                      <div
                        className={`before-left-arrow cursor-pointer ${ShowDetails == i + 1
                          ? "before-left-arrow-open"
                          : "before-left-arrow-close"
                          }`}
                        onClick={() => {
                          setShowDetails(ShowDetails != i + 1 ? i + 1 : 0);
                        }}
                      >
                        <div className="h-full flex items-center">
                          <p className="text-sm">{prdct?.member?.full_name}</p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">{prdct?.sold_quantity}</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          <Moment format="dddd, D MMMM YYYY">
                            {prdct?.created_at}
                          </Moment>
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          {prdct?.total_price}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          {prdct?.tip}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          {prdct?.location?.address_name}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          {prdct?.gst}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          {prdct?.total_price + prdct?.tip + prdct?.gst}
                        </p>
                      </div>
                      {/* <div className='flex items-center gap-2'>
                                        <div className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center'>
                                            <Svgs.Delete fill='#7D7D7D' />
                                        </div>
                                    </div> */}
                    </div>
                    {ShowDetails == i + 1 ? (
                      <div className="scale-in-center">
                        <div className="bg-[#FAF9FF] px-6 py-3 inline-grid xl:grid grid-cols-[repeat(4,minmax(180px,1fr))]">
                          <div className="flex items-center gap-12">
                            <div className="flex items-center gap-3 col-span-1">
                              <img
                                src={`${prdct?.member?.image
                                  ? prdct?.member?.image
                                  : `${process.env.PUBLIC_URL}/images/profile_img.svg`
                                  }`}
                                className="h-[4.375rem] w-[4.375rem] object-cover rounded-lg"
                              />
                              <div className="flex flex-col gap-2 flex-1">
                                <p className="text-black font-semibold">
                                  Employee Name
                                </p>
                                <h2 className="text-[#949494] text-sm">
                                  {prdct?.member?.full_name}{" "}
                                </h2>
                              </div>
                            </div>
                            <div className="h-full w-[1px] bg-[#3F3F441A] mr-3"></div>
                          </div>
                          <div className="grid grid-cols-7 col-span-3 gap-5">
                            <div className="col-span-2">
                              <div className="flex flex-col gap-4">
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Item Sold{" "}
                                  </h2>
                                  <p className="text-sm">
                                    {prdct?.sold_quantity}
                                  </p>
                                </div>
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Date
                                  </h2>
                                  <p className="text-sm">
                                    {prdct?.created_at?.split("T")[0]}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-span-2">
                              <div className="flex flex-col gap-4">
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Location
                                  </h2>
                                  <p className="text-sm">
                                    {prdct?.location?.address_name}
                                  </p>
                                </div>
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Tip
                                  </h2>
                                  <p className="text-sm">{prdct?.tip}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-span-2">
                              <div className="flex flex-col gap-4">
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Net Sale
                                  </h2>
                                  <p className="text-sm">
                                    {prdct?.total_price}
                                  </p>
                                </div>
                                <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Total Sale
                                  </h2>
                                  <p className="text-sm">
                                    {prdct?.total_price +
                                      prdct?.tip +
                                      prdct?.gst}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <div className=''>
                                                        <div className='flex flex-col gap-4'>
                                                            <div>
                                                                <h2 className='text-[#949494] text-sm'>Tax</h2>
                                                                <p className='text-sm'>Lorem ipsum dolor sit.</p>
                                                            </div>
                                                        </div>
                                                    </div> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
          </div>
        </div>
      </div>
      {Filter && (
        <Popup heading="Filter" open={Filter} close={toggleModal}>
          <div className="flex flex-col gap-5">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
              <CircleIcon>
                <Svgs.FilterPopup />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  Search By Filters
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  Apply filters to get desired results
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-5">
              <Dropwdown
                title="Employee"
                placeholder="Select Employee"
                name={"employeeText"}
                value={employeeText}
                onChange={filterHandleChange}
                options={saleProductList?.map((prdct) => {
                  return {
                    label: prdct?.member?.full_name,
                    value: prdct?.member?.full_name,
                  };
                })}
              />
              <Dropwdown
                title="Location"
                placeholder="Select Location"
                name={"locationText"}
                value={locationText}
                onChange={filterHandleChange}
                options={locationList?.map((lction) => {
                  return {
                    label: lction?.address_name,
                    value: lction?.address_name,
                  };
                })}
              />

              <Dropwdown
                title="Product"
                placeholder="Select Product"
                name={"productText"}
                value={productText}
                onChange={filterHandleChange}
                options={saleProductList?.map((prdct) => {
                  return {
                    label: prdct?.product_name,
                    value: prdct?.product_name,
                  };
                })}
              />

              <Dropwdown
                title="Income Type"
                placeholder="Select Income Type"
                name={"incomeType"}
                value={incomeType}
                onChange={filterHandleChange}
                options={[
                  { label: "Hourly Rate", value: "Hourly_Rate" },
                  { label: "Daily Income", value: "Daily_Income" },
                  { label: "Monthly Salary", value: "Monthly_Salary" },
                ]}
              />

              <DateInput
                title="From"
                name={"selectedFrom"}
                value={selectedFrom}
                onChange={filterHandleChange}
              />
              <DateInput
                title="To"
                name={"selectedTo"}
                value={selectedTo}
                onChange={filterHandleChange}
              />
            </div>
            <div className="flex items-center justify-start">
              <LoginBtn text="Apply" onClick={applyFilter} />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default StaffProdcut;
