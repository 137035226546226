import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createStaffGroup, getStaffGroup, deleteStaffGroup, updateStaffGroup, getStaffGroupWithNoPagination } from '../../../../../Adapters/Api/staffGroup';
import useObjectKeys from '../../../../../Hooks/useObjectKeys';
import useSetDataInLocalStorage from '../../../../../Hooks/useSetDataInLocalStorage';
import useValidations from '../../../../../Hooks/useValidations';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getEmployeeDropDownList } from 'Adapters/Api/employee';

function useStaffGroups() {
    const employeeRedux = useSelector((state) => state?.employee?.employees);
    const staffGroupRedux = useSelector((state) => state?.staffGroup?.staffGroups);

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const selected_location = getDataFromLocalStorage("selected_location");
    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");
    const staff_group_id = getDataFromLocalStorage("staff_group_id");

    const [step, setStep] = useState(1);
    const [isEdit, setIsEdit] = useState(false);
    const [Finished, setFinished] = useState(false);
    const [staffModal, setStaffModal] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [search_text, setSearchText] = useState("");
    const [loader, setLoader] = useState(true)
    const [noPaginationLoader, setNoPaginationLoader] = useState(true)
    const [loading, setLoading] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [isEditValue, setIsEditValue] = useState(false)
    const [showAddStaffPopup, setShowAddStaffPopup] = useState(false)

    const [staffGroup, setStaffGroup] = useState({
        name: "",
        employees: [],
        is_active: true,
        access_reports: false,
        access_sales: false,
        access_inventory: false,
        access_expenses: false,
        access_products: false,
    });

    //**************************** set employee dropdown list in Staff group *********************************
    let employeeOptions;
    if (employeeRedux && employeeRedux?.length >= 1) {
        employeeOptions = employeeRedux && employeeRedux?.map((item) => {
            return ({ label: item.full_name, value: item.id });
        });
    }


    // *******************  staff Group modal handler ************************************
    const staffGroupModalToggler = () => {
        setStaffModal(!staffModal)
        setStep(1)
        setStaffGroup({
            name: "", employees: [], is_active: true, access_reports: false, access_sales: false, access_inventory: false, access_expenses: false, access_products: false,
        })
        setErrors({})
        setIsEdit(false);
    }


    // **************************** staff group on change handler *************************
    const staffGroupHandleChange = (e) => {

        if (e?.target) {
            const { name, value } = e.target;
            if (value != 'no_value') {
                if (name === "employees" && value != 'no_value') {

                    if (!staffGroup?.employees?.includes(value)) {
                        setStaffGroup((prev) => ({ ...prev, [name]: [...prev.employees, value] }));
                        setErrors((prev) => ({ ...prev, [name]: "" }))
                        return;
                    }
                } else {
                    setStaffGroup((prev) => ({ ...prev, [name]: value }));
                    setErrors((prev) => ({ ...prev, [name]: "" }))
                }

            }
        }
    }

    const filter_employess = () => {
        let group_emply_ids = [];
        staffGroupRedux.forEach((group) => {
            group.employees.forEach((empl) => {
                group_emply_ids.push(empl.id);
            });
        });

        let filtered_empls = employeeRedux?.filter((employee) => !group_emply_ids.includes(employee.id));
        return filtered_empls;
    };

    // **************************** employee on change handler *********************************
    const employeePermissionHandleChange = (e) => {
        const { name, value } = e.target;
        let new_returned = staffGroup[name] ? staffGroup[name] : []
        if (!new_returned.includes(value)) {
            new_returned.push(value)
        }
        else {
            let itm_index = new_returned.indexOf(value)
            new_returned.splice(itm_index, 1)
        }
        setStaffGroup({ ...staffGroup, [name]: new_returned })
    }


    // **************************** staff group on submit *********************************
    const onStaffGroupSubmit = async () => {

        const { name, employees } = staffGroup
        let requiredFields = { name }

        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0 && employees.length !== 0) {
            if (step === 1) {
                setStep(2);
            } else if (step === 2) {

                if (isEdit && isEditValue) {
                    setLoading(true)
                    updateStaffGroup({ ...staffGroup, staff_id: staff_group_id }, access_token).then((response) => {
                        // dispatch(editStaffGroup(response?.data?.response.StaffGroupUpdate));
                        setLoading(false)
                        // staffGroupModalToggler();
                        // setStaffModal(false)
                        setCurrentPage(1)
                        dispatch(getStaffGroup(1, search_text, setLoader))
                        dispatch(getStaffGroupWithNoPagination(search_text, setNoPaginationLoader))
                        if (!isToastVisible) {
                            toast.success(response?.data?.response?.message, {
                                toastId: "toast",
                                onClose: () => setIsToastVisible(false),
                            });
                            setIsToastVisible(true);
                        }
                        setStep(3);
                    }).catch((error) => {
                        setLoading(false)
                    })
                    return;

                } else {
                    setLoading(true)

                    createStaffGroup({ ...staffGroup, business: businessData.id }, access_token).then((response) => {
                        // dispatch(addStaffGroup(response.data.response?.StaffGroup));
                        setLoading(false)
                        // staffGroupModalToggler();
                        // setSelectedEmployee([])
                        // setFinished(true);
                        setCurrentPage(1)
                        dispatch(getStaffGroup(1, search_text, setLoader))
                        dispatch(getStaffGroupWithNoPagination(search_text, setNoPaginationLoader))
                        if (!isToastVisible) {
                            setTimeout(() => {
                                toast.success(response?.data?.response?.message, {
                                    toastId: "toast",
                                    onClose: () => setIsToastVisible(false),
                                });
                                setIsToastVisible(true);
                            }, 1000)
                        }
                        setStep(3);
                    }).catch((error) => {
                        setLoading(false)
                    })
                }

            }
        } else {
            if (employees.length == 0) {
                setErrors((prev) => ({ ...prev, employees: "This field is required" }))
            }
        }


    }

    const staffGroupEdit = (id) => {
        let filteredStaff = staffGroupRedux?.find(itm => itm?.id == id)
        setStaffGroup(filteredStaff)
        // setStaffGroup((prev) => ({ ...prev, name: filteredStaff?.name, employees: filteredStaff?.employees, is_active: filteredStaff?.is_active }))
    }

    // **************************** Eidt Staff group *********************************
    const staffGroupEditHandler = async (id) => {
        // const { name, employees } = staffGroup
        // let requiredFields = { name }

        // setErrors(validation(requiredFields));
        // let result = validation(requiredFields);
        // if (objectKeyConvertToArray(result)?.length === 0 && employees.length !== 0) {
        storeDataInLocalStorage("staff_group_id", id)
        staffGroupModalToggler();
        setIsEdit(true);
        const selectedStaffGroup = staffGroupRedux.find((item) => item.id === id);
        if (selectedStaffGroup) {
            setStaffGroup({
                name: selectedStaffGroup?.name,
                employees: selectedStaffGroup?.employees?.map((item) => item.id),
                is_active: selectedStaffGroup?.is_active,
                access_reports: selectedStaffGroup?.staff_permission?.access_reports,
                access_sales: selectedStaffGroup?.staff_permission?.access_sales,
                access_inventory: selectedStaffGroup?.staff_permission?.access_inventory,
                access_expenses: selectedStaffGroup?.staff_permission?.access_expenses,
                access_products: selectedStaffGroup?.staff_permission?.access_products,
                ...selectedStaffGroup.staff_permission
            })
        }
        // }
        // else {
        //     if (employees.length == 0) {
        //         setErrors((prev) => ({ ...prev, employees: "This field is required" }))
        //     }
        // }
    }


    // **************************** delete Staff group *********************************
    const staffGroupDeleteHandler = async (id) => {
        setDeleteLoader(true)
        let response = await deleteStaffGroup(id, access_token);
        if (response.status_code === 200) {
            if (!isToastVisible) {
                toast.success(response?.response?.message, {
                    toastId: "toast",
                    onClose: () => setIsToastVisible(false),
                });
                setIsToastVisible(true);
            }
            // dispatch(removeStaffGroup(id));
            setCurrentPage(1)
            dispatch(getStaffGroup(1, search_text, setLoader))
            dispatch(getStaffGroupWithNoPagination(search_text, setNoPaginationLoader))
            setDeleteLoader(false)
        } else {
            setDeleteLoader(false)
        }
    }


    useEffect(() => {
        dispatch(getEmployeeDropDownList())
    }, [])


    return {
        staffGroup, setStaffGroup, staffModal, staffGroupHandleChange, employeePermissionHandleChange, onStaffGroupSubmit,
        errors, onBlurHandler, staffGroupModalToggler, step, setStep, staffGroupEditHandler, staffGroupDeleteHandler, isEdit,
        setIsEdit, employeeOptions, loading, Finished, setFinished, currentPage, setCurrentPage, loader, setLoader, search_text,
        setSearchText, isToastVisible, setIsToastVisible, filter_employess, employeeRedux, selected_location, navigate, setStaffModal,
        noPaginationLoader, setNoPaginationLoader, deleteLoader, isEditValue, setIsEditValue, showAddStaffPopup, setShowAddStaffPopup,
        dispatch,
        // selectedEmployee,
    }


}

export default useStaffGroups
