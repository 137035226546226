import { BASE_URL, get_currency_url } from "../api_variables";
import { toast } from 'react-toastify';
import { apiErrorMessage } from "Constants/Data/Errors";





export const getCurrencyList = () => dispatch => {

    let s_code;
    fetch(`${BASE_URL}${get_currency_url}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let currencyList = result?.response?.currency;
                dispatch({
                    type: "GET_CURRENCY",
                    payload: currencyList
                })
            }
            else {
                toast.error(apiErrorMessage, { toastId: "toast" })
            }
        }).catch((err) => {
            toast.error(apiErrorMessage, { toastId: "toast" })
        })
};
