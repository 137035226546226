import Svgs from 'Assets/svgs';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import InputWithIcon from '../Elements/InputWithIcon';
import LoginBtn from '../Elements/LoginBtn';
import Popup from '../Elements/Popup';

function CreateLinkPopup({ selected_location, isPopup, setIsPopup }) {

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const hash = getDataFromLocalStorage("hash")
    const hash_name = getDataFromLocalStorage("hash_name")
    const business = getDataFromLocalStorage("business")

    return (
        <Popup
            size="sm"
            heading={`Select Locations`}
            close={() => setIsPopup(false)}
            open={isPopup}
        >
            <div className="flex flex-col gap-4 items-center text-center">
                <div className="h-[6rem] w-[6rem] rounded-full flex items-center justify-center bg-green-gradient">
                    <Svgs.Check size='2rem' />
                </div>
                <h1 className="font-semibold">Your Link is Ready!</h1>
                <p className="text-[#555555]">You have created a link to your Sale listedd on N Style</p>
                <CopyToClipboard
                    // text={`http://${window?.location?.host}/profile/${selected_location?.address_name?.replaceAll(",", "").replaceAll(" ", "-")}/${selected_location?.address?.replaceAll(",", "").replaceAll(" ", "-")}/${selected_location?.id}?business=${business}&domain=${hash_name}&hash=${hash}`}
                    text={`http://${window?.location?.host}/profile/${selected_location?.address_name?.replaceAll(",", "").replaceAll(" ", "-")}/${selected_location?.address?.replaceAll(",", "").replaceAll(" ", "-")}/${selected_location?.id}?business=${business}&domain=${hash_name}`}
                    onCopy={() => {
                        // setCopy(true)
                        toast.success("copied to clipboard!", { toastId: "toast" })
                    }}>
                    <div className='flex flex-col gap-2  w-full relative capitalize'>
                        <InputWithIcon disabled={true} fill='#555555' title="" value={`http://${window?.location?.host}/profile/${selected_location?.address_name?.replaceAll(",", "").replaceAll(" ", "-")}/${selected_location?.address?.replaceAll(",", "").replaceAll(" ", "-")}/${selected_location?.id}?business=${business}`} icon={<Svgs.Copy />} />
                    </div>
                </CopyToClipboard>
                <div className="flex items-center justify-center gap-3">
                    <LoginBtn
                        borderBtn
                        text={'See Your Profile'}
                        onClick={() => {
                            // window.open(`http://${window?.location?.host}/profile/${selected_location?.address_name?.replaceAll(",", "").replaceAll(" ", "-")}/${selected_location?.address?.replaceAll(",", "").replaceAll(" ", "-")}/${selected_location?.id}?business=${business}&domain=${hash_name}&hash=${hash}&token=${"kjlkjlkjlkj"}`, '_blank', 'noreferrer');
                            window.open(`http://${window?.location?.host}/profile/${selected_location?.address_name?.replaceAll(",", "").replaceAll(" ", "-")}/${selected_location?.address?.replaceAll(",", "").replaceAll(" ", "-")}/${selected_location?.id}?business=${business}&domain=${hash_name}&token=${"kjlkjlkjlkj"}`, '_blank', 'noreferrer');
                        }}
                    />
                    <LoginBtn
                        onClick={() => {
                            setIsPopup(false)
                        }}
                        text={'Done!'} />
                </div>
            </div>
        </Popup>
    )
}

export default CreateLinkPopup