

// export const DESIGNATIONS = [
//     {
//         label: 'Accounts Manager',
//         value: 'Accounts Manager'
//     },
//     {
//         label: 'Recruitment Manager',
//         value: 'Recruitment Manager'
//     },
//     {
//         label: 'Technology Manager',
//         value: 'Technology Manager'
//     },
//     {
//         label: 'Store Manager',
//         value: 'Store Manager'
//     },
//     {
//         label: 'Regional Managers',
//         value: 'Regional Managers'
//     },
//     {
//         label: 'Functional Managers',
//         value: 'Functional Managers'
//     },
//     {
//         label: 'Departmental Managers',
//         value: 'Departmental Managers'
//     },
//     {
//         label: 'General Managers',
//         value: 'General Managers'
//     }
// ]

export const DESIGNATIONS = [
    {
        label: 'Area Manager',
        value: 'Area Manager'
    },
    {
        label: 'Store Manager',
        value: 'Store Manager'
    },
    {
        label: 'Store Supervisor',
        value: 'Store Supervisor'
    },
    {
        label: 'Cashier',
        value: 'Cashier'
    },
    {
        label: 'Hair Stylists',
        value: 'Hair Stylists'
    },
    {
        label: 'Barbers',
        value: 'Barbers'
    },
    {
        label: 'Technicians',
        value: 'Technicians'
    },
    {
        label: 'Assistants',
        value: 'Assistants'
    },
]