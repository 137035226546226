import axios from "axios";
import { BASE_URL, get_appointment_analytics } from "../api_variables";
import { buildQueryString } from "Constants/Functions/buildQueryString";

export const get_appointment_analytics_reports = (data, success, fail) => async dispatch => {
    const query = buildQueryString(data)
    try {
        // const current_Page = data?.currentPage ? `&page=${data?.currentPage}` : '';
        // const search = data?.search_text ? `&search_text=${data?.search_text}` : ''
        // const startDate = data?.startDate ? `&start_date=${data?.startDate}` : ''
        // const endDate = data?.endDate ? `&end_date=${data?.endDate}` : ''
        // const apiUrl = `${get_appointment_analytics}${startDate}${endDate}${current_Page}${search}`
        const apiUrl = `${get_appointment_analytics}${query}`
        const result = await axios.get(`${BASE_URL}${apiUrl}`)
        if (result?.status === 200) {
            success && success(result?.data)
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
}