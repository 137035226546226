import { PURCHASE_ORDER } from "Redux/TenantRedux/ActionTypes/purchaseOrderTypes";

export const addOrderStock = (data) => {
  return {
    type: PURCHASE_ORDER.ADD_ORDER_STOCK,
    payload: data,
  };
};

export const getOrderStock = (data) => {
  return {
    type: PURCHASE_ORDER.GET_ORDER_STOCK,
    payload: data,
  };
};

export const getPurchasesWithNoPagination = (data) => {
  return {
    type: PURCHASE_ORDER.ALL_PURCHASE_ORDER,
    payload: data,
  };
};

export const getPurchasesPages = (data) => {
  return {
    type: PURCHASE_ORDER.GET_PURCHASES_PAGES,
    payload: data,
  };
}

export const update_orderStock = (data) => {
  return {
    type: PURCHASE_ORDER.UPDATE_ORDER_STOCK,
    payload: data,
  };
};
export const update_orderStock_status = (id, data) => {
  const payload = {
    orderId: id,
    data: data
  }
  return {
    type: PURCHASE_ORDER.UPDATE_ORDER_STOCK_STATUS,
    payload: payload,
  };
};

export const delete_orderStock = (id) => {
  return {
    type: PURCHASE_ORDER.DELETE_ORDER_STOCK,
    payload: id,
  };
};