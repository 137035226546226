import React from 'react'
import useTrainerComments from './helper'
import moment from 'moment'
import Popup from 'Components/TenantApp/Elements/Popup'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'

const AcceptRequestPopup = ({ open, close, data, onSuccessCall }) => {
    const {
        translation, selectedLanguage,
        trainingData, Data, errors, loading, onChangeHandler, onSubmitHandler
    } = useTrainerComments(data, onSuccessCall, close)
    return (
        <>
            <Popup
                open={open}
                onclose={close}
                heading={"Accept Request"}
            >
                <div className='flex flex-col gap-5'>
                    <LoginInput
                        errorClass={"!static"}
                        parentClass={"w-full"}
                        title={selectedLanguage?.name == "English" ? "Select Date" : translation[111]?.value ? translation[111]?.value : "Select Date"}
                        type='date'
                        name='date_offered'
                        value={trainingData?.date_offered}
                        min={moment().format("YYYY-MM-DD")}
                        max={"2050-01-01"}
                        onChange={onChangeHandler}
                        error={errors.date_offered}
                    />
                    <div className='flex justify-end items-center'>
                        <LoginBtn
                            onClick={() => {
                                onSubmitHandler('accept')
                            }}
                            loading={loading}
                            disabled={loading}
                            className={"!px-6"}
                            text={selectedLanguage?.name == "English" ? "Accept" : translation[1492]?.value ? translation[1492]?.value : "Accept"}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default AcceptRequestPopup