import React, { useState, useEffect } from 'react'
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { cancelClientAppointments, getClientAppointments } from 'Adapters/Api/businessLocationInNstyleApp';
import { apiErrorMessage } from 'Constants/Data/Errors';

function useClientAppointment() {
  const dispatch = useDispatch();
  const [ManageAppintment, setManageAppintment] = useState(false);
  const [isReshedule, setIsReshedule] = useState(false);
  const [AddNotes, setAddNotes] = useState(false);
  const [notes_inp, setNotesInp] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [pastAppTimes, setPastAppTimes] = useState();
  const [past, setPast] = useState();
  const [upcoming, setUpcoming] = useState();
  const [upcomingAppTimes, setUpcomingAppTimes] = useState();
  const state = useSelector((state) => state)
  const locationList = state?.utility?.locations.locations;
  const appointment_list = state?.client_appointments?.client_appointments;
  const tax = state?.financialSettings?.businessTaxs;

  const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
  const client_id = getDataFromLocalStorage("client_id");
  const hash = getDataFromLocalStorage("hash");
  const business_location = getDataFromLocalStorage("business_location")
  const [businessLocation, setBusinessLocation] = useState();


  const [pastGroup, setPastGroup] = useState();
  const [upcomingGroup, setUpcomingGroup] = useState();
  const [selectedAppointmentGroup, setSelectedAppointmentGroup] = useState();


  //get all client appointment
  useEffect(() => {
    if (client_id) {
      dispatch(getClientAppointments(
        client_id,
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" })
        })
      )
    }
  }, [])

  //set business locations
  useEffect(() => {
    const matchLocation = locationList?.find((itm) => itm?.id == business_location);
    setBusinessLocation(matchLocation)
  }, [business_location, locationList])


  //cancell appointment handle here
  const onCancelAppointments = (id, notes_inp) => {
    dispatch(
      cancelClientAppointments(
        notes_inp, id, hash,
        (message) => {
          toast.success(message, { toastId: "toast" });
          setManageAppintment(!ManageAppintment)
        },
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };



  // set appointmetn time here
  const timeFormateSettings = (list) => {
    let start = list?.length > 0 && moment(list[0]?.appointment_time, "HH:mm");
    let arr = [];

    list?.forEach((ele, i) => {
      const drtion_arr = ele?.duration?.split(" ");
      if (drtion_arr?.length > 1) {
        const hrs =
          drtion_arr[0]?.toString()?.includes("h") &&
          drtion_arr[0]?.split("h")[0] * 60;
        const mns = drtion_arr[1]?.toString()?.split("min")[0] * 1;
        const total = hrs + mns;
        arr.push(new moment(start).format("HH:mm:ss"));
        start.add(total, "minutes");
      } else {
        const mns = drtion_arr[0]?.toString()?.split("min")[0] * 1;
        arr.push(new moment(start).format("HH:mm:ss"));
        start.add(mns, "minutes");
      }
    });

    return arr;
  };

  //get past and upcoming appointment time and store in states
  useEffect(() => {
    const pastList = timeFormateSettings(past);
    const upcomingList = timeFormateSettings(upcoming);
    setPastAppTimes(pastList);
    setUpcomingAppTimes(upcomingList);
  }, [selectedAppointment]);


  // set default selected appointment for right section
  useEffect(() => {
    if (upcoming?.length > 0) {
      setSelectedAppointment(upcoming[0]);
    } else {
      if (past?.length > 0) {
        setSelectedAppointment(past[0]);
      }
    }



    if (upcomingGroup?.length > 0) {
      setSelectedAppointmentGroup(upcomingGroup[0]);
    } else {
      if (pastGroup?.length > 0) {
        setSelectedAppointmentGroup(pastGroup[0]);
      }
    }



  }, [upcoming, past, pastGroup, upcomingGroup]);


  //set past and upcoming appointment arrays here
  useEffect(() => {
    let past = [];
    let upcoming = [];
    let past_group = [];
    let upcoming_group = [];
    appointment_list?.forEach((itm) => {
      itm?.appointment_service?.forEach((dt) => {
        if (dt?.appointment_status === "Appointment Booked") {
          upcoming.push({ ...dt, app_id: itm?.id });
        } else {
          past.push({ ...dt, app_id: itm?.id });
        }
      });

      if (itm?.appointment_service[0]?.appointment_status === "Appointment Booked") {
        upcoming_group.push(itm);
      } else {
        past_group.push(itm);
      }

    });
    setPast(past);
    setUpcoming(upcoming);
    setUpcomingGroup(upcoming_group)
    setPastGroup(past_group)

  }, [appointment_list]);




  return {
    ManageAppintment,
    setManageAppintment,
    selectedAppointment,
    setSelectedAppointment,
    pastAppTimes,
    setPastAppTimes,
    // past,
    // setPast,
    // upcoming,
    // setUpcoming,
    // upcomingAppTimes,
    setUpcomingAppTimes,
    appointment_list,
    businessLocation,
    onCancelAppointments,
    hash,
    isReshedule,
    setIsReshedule,
    tax,
    pastGroup,
    upcomingGroup,
    selectedAppointmentGroup,
    setSelectedAppointmentGroup,
    AddNotes,
    setAddNotes,
    notes_inp,
    setNotesInp
  }
}

export default useClientAppointment