import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { error_message } from 'Constants/Variables'
import Svgs from 'Assets/svgs';
import moment from 'moment';

const ReactDateTimePicker = ({ required, title, name, placeholder, value, min, max, onChange, onBlur, error }) => {
    const [selectDate, setSelectDate] = useState(null);
    const handleDateChange = (value) => {
        if (value != null) {
            setSelectDate(value)

            let updatedDate = moment(value).format('YYYY-MM-DD')

            let event = {
                target: {
                    name: name,
                    value: updatedDate
                }
            }
            onChange && onChange(event)
        }

    }

    useEffect(() => {
        if (value) {
            setSelectDate(new Date(value))
        } else {
            setSelectDate(null)
        }
    }, [value])
    return (
        <div className='flex flex-col gap-2 relative'>
            {title ? (
                <div className="text-sm flex items-center gap-1">
                    <div>{title}</div> {required && title && <span className="leading-[1] text-red-600"> *</span>}
                </div>
            ) : ""}
            <div className="date-picker-container">
                <DatePicker
                    // readOnly
                    selected={selectDate}
                    name={name}
                    value={selectDate}
                    onChange={handleDateChange}
                    // onBlur={onBlur}
                    maxDate={new Date(max)}
                    minDate={new Date(min)}
                    showTimeSelect
                    icon="fa fa-calendar"
                    onInputError={error}
                    className={`custom-datepicker input ${error && 'input__error__style'}`}
                    placeholderText={placeholder ? placeholder : "mm/dd/yyyy --/-- --"}
                    dateFormat='MM/dd/yyyy h:mm aa'
                />
                {error && typeof error == "string" ? (
                    <p className="text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                        <Svgs.I fill="#eb3b3b" />
                        {error}
                    </p>
                ) : typeof error == "boolean" && error == true ? (
                    <p className="text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                        <Svgs.I fill="#eb3b3b" /> {error_message}
                    </p>
                ) : (
                    ""
                )}
            </div>
        </div>
    )
}

export default ReactDateTimePicker
