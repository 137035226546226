import React from "react";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import Svgs from "Assets/svgs";
import AddCashBackCard from "./AddCashBackCard";
import useCashBack from "./helper";
import Popup from "./../../../../../new-components/popup/index";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import LoyalityShimmer from "Components/Utility/Shimmers/LoyalityShimmer";
import FloatInput from "Components/TenantApp/Elements/FloatInput";
import Textarea from "Components/TenantApp/Elements/Textarea";
import CheckBox from "Components/TenantApp/Elements/Checkbox/CheckBox";

function CashBack() {
  const {
    allCashBackData, onUpdateCashback, cashBackData, errors, handleChange, onSubmitCashBack, is_delete, setDelete, setDeleteId, onDeleteCashBack,
    currency, addLoader, updateLoader, translation, selectedLanguage, setEditErrors, editErrors, editId, seteditId, getLoader, applicableData,
    loadingPermission, employee, employeePermissions, removeItem, allowMembershipChangeHandler, reduxlocationsList
  } = useCashBack();

  const {
    name, status, validTill, applicableFor, termsAndCondition, allowMembership, allowedLocations, redeemableLocations, amount_spend, percentage,
    redeemptionAmount
  } = cashBackData

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <div className="lg:w-[82%] slide-in-bottom">

          <Breadcrumb
            first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
            url={'/dashboard/clients/'}
            second={selectedLanguage?.name == "English" ? "Cashback" : translation[1699]?.value ? translation[1699]?.value : "Cashback"}
          />
          <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Cashback" : translation[1699]?.value ? translation[1699]?.value : "Cashback"}
            </h2>
          </div>

          <div className="flex flex-col gap-5 ">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
              <CircleIcon>
                <Svgs.Promotions />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Add Cashback" : translation[1700]?.value ? translation[1700]?.value : "Add Cashback"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Set loyalty criterias for your client on product/service/both" : translation[1089]?.value ? translation[1089]?.value : "Set loyalty criterias for your client on product/service/both"}
                </p>
              </div>
            </div>
            <div className="lg:w-[82%] w-full col-span-1">
              <div className="">
                <>
                  {getLoader ?
                    <LoyalityShimmer />
                    :
                    allCashBackData?.length > 0 &&
                    allCashBackData?.map((data, index) => {
                      return (
                        <AddCashBackCard
                          index={index}
                          data={data}
                          cashBackData={cashBackData}
                          applicableData={applicableData}
                          errors={editErrors}
                          setErrors={setEditErrors}
                          handleChange={handleChange}
                          updateCashBack={(data) => onUpdateCashback(data)}
                          onDelete={(id) => {
                            setDelete(true);
                            setDeleteId(id);
                          }}
                          currency={currency}
                          updateLoader={updateLoader}
                          translation={translation}
                          selectedLanguage={selectedLanguage}
                          editId={editId}
                          seteditId={seteditId}
                          loadingPermission={loadingPermission}
                          reduxlocationsList={reduxlocationsList}
                        />
                      );
                    })}
                </>
                {!loadingPermission && !employee || (employee && employeePermissions?.cash_back?.includes("create")) ? (
                  <div className="flex gap-6">
                    <div className="relative">
                      <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                        <div className="h-[1.8rem] w-[1.8rem] rounded-full border border-[#C9C7C7] bg-white flex items-center justify-center"></div>
                      </div>
                      {
                        allCashBackData.length > 0 &&
                        <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
                      }
                    </div>
                    <div className="mt-[2rem] border px-4 py-3 rounded-md w-xl flex flex-col gap-4 flex-1">
                      <LoginInput
                        required={true}
                        name={"name"}
                        // charactersMaxLength={25}
                        value={name}
                        onChange={handleChange}
                        error={errors?.name}
                        title={selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Name" : translation[1090]?.value ? translation[1090]?.value : "Enter Name"}
                      />

                      <Dropwdown
                        required={true}
                        name="allowedLocations"
                        onChange={handleChange}
                        error={errors?.location}
                        options={reduxlocationsList?.filter((adrs) => {
                          if (!(allowedLocations?.includes(adrs?.id))) {
                            return adrs
                          }
                        }).map((adrs) => {
                          return {
                            label: adrs?.address_name,
                            value: adrs?.id,
                          };
                        })}
                        title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Location" : translation[20]?.value ? translation[20]?.value : "Select Location"}
                      />
                      {allowedLocations?.length > 0 && (
                        <div className="flex items-center flex-wrap gap-2">
                          {allowedLocations &&
                            allowedLocations?.map((data, i) => {
                              const matchingAddressName = reduxlocationsList?.find(item => item?.id === data)?.address_name
                              return (
                                <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                  <p className="text-primary text-sm font-bold">{`${matchingAddressName}`}</p>
                                  <span
                                    className="cursor-pointer"
                                    onClick={() =>
                                      removeItem('allowedLocations', data)
                                    }
                                  >
                                    <Svgs.CirlceClose fill="#5679FF" />
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                      )}

                      <Dropwdown
                        required={true}
                        name="redeemableLocations"
                        onChange={handleChange}
                        error={errors?.location}
                        options={reduxlocationsList?.filter((adrs) => {
                          if (!(redeemableLocations?.includes(adrs?.id))) {
                            return adrs
                          }
                        }).map((adrs) => {
                          return {
                            label: adrs?.address_name,
                            value: adrs?.id,
                          };
                        })}
                        title={selectedLanguage?.name == "English" ? "Redeemable Location" : translation[19]?.value ? translation[19]?.value : "Redeemable Location"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Redeemable Location" : translation[20]?.value ? translation[20]?.value : "Select Redeemable Location"}
                      />
                      {redeemableLocations?.length > 0 && (
                        <div className="flex items-center flex-wrap gap-2">
                          {redeemableLocations &&
                            redeemableLocations?.map((data, i) => {
                              const matchingAddressName = reduxlocationsList?.find(item => item?.id === data)?.address_name
                              return (
                                <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                  <p className="text-primary text-sm font-bold">{`${matchingAddressName}`}</p>
                                  <span
                                    className="cursor-pointer"
                                    onClick={() =>
                                      removeItem('redeemableLocations', data)
                                    }
                                  >
                                    <Svgs.CirlceClose fill="#5679FF" />
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                      )}

                      <div className="grid md:grid-cols-2 gap-3">
                        <FloatInput
                          required={true}
                          name="amount_spend"
                          value={amount_spend}
                          onChange={handleChange}
                          error={errors?.amount_spend}
                          title={"Minimum Spend"}
                          placeholder={selectedLanguage?.name == "English" ? "Enter Spend Amount" : translation[1095]?.value ? translation[1095]?.value : "Enter Spend Amount"}
                        />

                        <FloatInput
                          required={true}
                          name="percentage"
                          value={percentage}
                          onChange={handleChange}
                          error={errors?.percentage}
                          title={selectedLanguage?.name == "English" ? "Percentage" : translation[199]?.value ? translation[199]?.value : "Percentage"}
                          placeholder={selectedLanguage?.name == "English" ? "Enter Percentage" : translation[1701]?.value ? translation[1701]?.value : "Enter Percentage"}
                        />
                      </div>

                      <FloatInput
                        required={true}
                        name="redeemptionAmount"
                        value={redeemptionAmount}
                        onChange={handleChange}
                        error={errors?.redeemptionAmount}
                        title={selectedLanguage?.name == "English" ? "Redemption amount" : translation[1702]?.value ? translation[1702]?.value : "Redemption amount"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Redemption Amount" : translation[1095]?.value ? translation[1095]?.value : "Enter Redemption Amount"}
                      />

                      <Dropwdown
                        required={true}
                        name={"applicableFor"}
                        value={applicableFor}
                        onChange={handleChange}
                        error={errors?.applicableFor}
                        options={applicableData}
                        placeholder={selectedLanguage?.name == "English" ? "Select Category" : translation[889]?.value ? translation[889]?.value : "Select Category"}
                        title={selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}
                      />
                      <Dropwdown
                        required={true}
                        name={"status"}
                        value={status}
                        onChange={handleChange}
                        error={errors?.status}
                        title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[521]?.value : "Select Status"}
                        options={[
                          {
                            value: true,
                            label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                          },
                          {
                            value: false,
                            label: selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                          },
                        ]}
                      />

                      <div className="grid md:grid-cols-2 gap-3">
                        <Dropwdown
                          required={true}
                          name={"validTill"}
                          value={validTill}
                          onChange={handleChange}
                          error={errors?.validTill}
                          options={[
                            { label: '7 Days', value: '7 Days' },
                            { label: '14 Days', value: '14 Days' },
                            { label: '1 Month', value: '1 Months' },
                            { label: '2 Months', value: '2 Months' },
                            { label: '3 Months', value: '3 Months' },
                            { label: '4 Months', value: '4 Months' },
                            { label: '6 Months', value: '6 Months' },
                            { label: '8 Months', value: '8 Months' },
                            { label: '1 Years', value: '1 Years' },
                            { label: '18 Months', value: '18 Months' },
                            { label: '2 Years', value: '2 Years' },
                            { label: '5 Years', value: '5 Years' },
                          ]}
                          title={selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}
                          placeholder={selectedLanguage?.name == "English" ? "Select validity" : translation[1173]?.value ? translation[1173]?.value : "Select validity"}
                        />

                      </div>

                      <div className="space-y-5">
                        <Textarea
                          required={true}
                          name={"termsAndCondition"}
                          value={termsAndCondition}
                          onChange={handleChange}
                          error={errors?.termsAndCondition}
                          title={selectedLanguage?.name == "English" ? "Terms & conditions" : translation[1174]?.value ? translation[1174]?.value : "Terms & conditions"}
                          placeholder={selectedLanguage?.name == "English" ? "Enter Terms & conditions" : translation[1175]?.value ? translation[1175]?.value : "Enter Terms & conditions"}
                        />
                        <CheckBox
                          name="allowMembership"
                          onChange={allowMembershipChangeHandler}
                          value={allowMembership}
                          checked={allowMembership}
                          label_Class={"!normal-case"}
                          label={"Allow it with membership"}
                        />
                      </div>

                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {!loadingPermission && (!employee || (employee && employeePermissions?.cash_back?.includes("create"))) ? (
              <LoginBtn
                disabled={addLoader}
                className="ml-6"
                text={selectedLanguage?.name == "English" ? "Add Cashback" : translation[1700]?.value ? translation[1700]?.value : "Add Cashback"}
                loading={addLoader}
                onClick={() => onSubmitCashBack()}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {is_delete && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this CashBack?" : translation[1743]?.value ? translation[1743]?.value : "Are you sure you want to delete this CashBack?"}
          handleClose={() => {
            setDelete(false);
          }}
        >
          <div>
            <p>
              {selectedLanguage?.name == "English" ? "Are you sure you want to delete this CashBack? You won't be able to see this again." : translation[1744]?.value ? translation[1744]?.value : "Are you sure you want to delete this CashBack? You won't be able to see this again."}
            </p>
            <div className="flex justify-end mt-4">
              <LoginBtn
                deleteBtn
                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                onClick={() => {
                  onDeleteCashBack();
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
}

export default CashBack;
