/* eslint-disable react/jsx-pascal-case */
import Svgs from 'Assets/svgs'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import InputSection from 'Components/TenantApp/Elements/InputSection';
import I_Info_Icon from 'Components/TenantApp/Elements/I_Info_Icon';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import SelectedOption from 'Components/TenantApp/Elements/SelectedOption';
import ToggleWithText from 'Components/TenantApp/Elements/ToggleWithText';
import moment from 'moment/moment';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAddDiscount } from './helper';
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb';
import FloatInput from 'Components/TenantApp/Elements/FloatInput';

const AddDiscount = () => {
    const { errors, toggleDay, toggleStore, discount, loading, locationDropdown, isEdit, serviceDropdown, addSpendAmountOnSelectedService,
        filterSpendAmountOnSelectedService, handleChange, onBlurHandler, navigate, addFreeServices, addStoreRestriction,
        filterStoreRestriction, onSubmit, addBlockDates, filterBlockDate, setToggleDay, setToggleStore, handleDayChecklist,
        dateRangeCheck, validateBlockDate, toggleblockdate, setToggleBlockDate, translation, selectedLanguage, formRef, formErrors, setFormErrors
    } = useAddDiscount();

    const {
        spendService,
        dateRestriction,
        blockDate,
        promotion_name,
        blockDates,
        storeRestriction,
        storeRestrictions,
        dayRestrictions,
    } = discount;

    var minEndDateLimit = useMemo(() => {
        return moment(dateRestriction.startDate).add(1, 'day').format('YYYY-MM-DD')
    }, [dateRestriction.startDate]);

    return (
        <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right' >

            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
                url={'/dashboard/clients/'}
                second={selectedLanguage?.name == "English" ? "Discount & Promotions" : translation[638]?.value ? translation[638]?.value : "Discount & Promotions"}
                secondurl={'/dashboard/clients/discounts-promotions'}
                third={selectedLanguage?.name == "English" ? "Get a free item if you spend some amount" : translation[1046]?.value ? translation[1046]?.value : "Get a free item if you spend some amount"}
            />
            <div className='flex items-center sm:gap-4 gap-2 my-[1rem]'>

                <div className='flex items-center gap-4 justify-between w-full'>
                    <div className='flex items-center gap-3'>
                        <Svgs.Back link='/dashboard/clients/discounts-promotions' />
                        <h2 className='text-primary font-bold lg:text-3xl text-xl cursor-pointer'>{selectedLanguage?.name == "English" ? "Get a free item if you spend some amount" : translation[1046]?.value ? translation[1046]?.value : "Get a free item if you spend some amount"}</h2>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 md:w-[40vw]' ref={formRef}>
                <div className='border rounded-lg p-3 flex flex-col gap-5'>
                    <h1 className='text-sm'>
                        {selectedLanguage?.name == "English" ? "Get selected services on spending the mentioned amount." : translation[1047]?.value ? translation[1047]?.value : "Get selected services on spending the mentioned amount."}
                    </h1>
                    <LoginInput
                        name="promotion_name"
                        className='!w-fit'
                        title={selectedLanguage?.name == "English" ? "Promotion Name" : translation[883]?.value ? translation[883]?.value : "Promotion Name"}
                        value={promotion_name}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Promotion Name" : translation[1732]?.value ? translation[1732]?.value : "Enter Promotion Name"}
                        type='text'
                        // charactersMaxLength={25}
                        onChange={handleChange}
                        error={errors?.promotion_name}
                    />
                    {spendService?.map((obj, index) => (
                        obj.is_deleted === "False" && (
                            <>
                                <AddSpendAmountOnSelectedService
                                    obj={obj}
                                    index={index}
                                    serviceDropdown={serviceDropdown}
                                    handleChange={addSpendAmountOnSelectedService}
                                    removeSpendService={filterSpendAmountOnSelectedService}
                                    spendService={spendService}
                                    onBlurHandler={onBlurHandler}
                                    errors={errors}
                                    formErrors={formErrors}
                                />
                            </>
                        )
                    ))}

                    <LoginBtn
                        text={
                            <div className='flex items-center gap-2'>
                                <Svgs.Plus />
                                <p>
                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                </p>
                            </div>
                        }
                        onClick={() => {
                            const errors = [];
                            discount.spendService?.forEach((data, index) => {
                                const fieldErrors = {};
                                if (!data.spend_amount) {
                                    fieldErrors.spend_amount = 'This field is required';
                                }
                                if (!data.service) {
                                    fieldErrors.service = 'This field is required';
                                }
                                errors[index] = fieldErrors;
                            });
                            // Update formErrors state with the new errors
                            setFormErrors(errors);
                            // If there are errors, return early and do not proceed with form submission
                            if (errors?.some((error) => Object.keys(error)?.length > 0)) {
                                formRef.current?.scrollIntoView({ behavior: "smooth" });
                                return;
                            } else {
                                addFreeServices()
                            }
                        }}
                    // disabled={spendService?.find(
                    //     (obj) => !obj?.spend_amount || !obj?.service
                    // )}
                    />
                </div>

                <div className='border rounded-lg p-3 flex flex-col gap-7'>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>
                            {selectedLanguage?.name == "English" ? "Date Restrictions" : translation[899]?.value ? translation[899]?.value : "Date Restrictions"}
                        </h1>
                        <div className='grid grid-cols-2 gap-3'>
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                name="startDate"
                                value={dateRestriction.startDate}
                                type="date"
                                onChange={handleChange}
                                onBlur={() =>
                                    onBlurHandler({ startDate: dateRestriction.startDate })
                                }
                                error={errors.startDate}
                            />
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                                name="endDate"
                                value={dateRestriction.endDate}
                                type="date"
                                onChange={handleChange}
                                min={minEndDateLimit}
                                onBlur={() =>
                                    onBlurHandler({ endDate: dateRestriction.endDate })
                                }
                                error={errors.endDate}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={'!text-lg'}
                            title={selectedLanguage?.name == "English" ? "Add Block Date" : translation[902]?.value ? translation[902]?.value : "Add Block Date"}
                            value={toggleblockdate}
                            onChange={() => setToggleBlockDate(!toggleblockdate)}
                            checked={toggleblockdate}
                        />
                        {toggleblockdate &&
                            <>
                                <div className='flex items-end gap-3'>
                                    <LoginInput
                                        name="blockDate"
                                        title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                        type="date"
                                        parentClass="flex-1"
                                        required={false}
                                        min={dateRestriction.startDate}
                                        max={dateRestriction.endDate}
                                        value={blockDate}
                                        onChange={handleChange}
                                        // onBlur={() => onBlurHandler({ blockDate: blockDate })}
                                        error={errors.blockDate}
                                        disabled={
                                            !discount.dateRestriction.startDate ||
                                            !discount.dateRestriction.endDate
                                        }
                                    />
                                    <LoginBtn
                                        className={(blockDate) && "shake-bottom"}
                                        text={
                                            <div className="flex items-center gap-2">
                                                <Svgs.Plus />
                                                <p>
                                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                                </p>
                                            </div>
                                        }
                                        onClick={addBlockDates}
                                        disabled={
                                            !dateRangeCheck ? true : false ||
                                                !discount.dateRestriction.startDate ||
                                                !discount.dateRestriction.endDate ||
                                                !blockDate ||
                                                validateBlockDate ? true : false
                                        }
                                    />
                                </div>
                                <div className='flex items-center gap-2 flex-wrap'>
                                    {blockDates?.map((obj, index) => (
                                        <>
                                            {obj?.is_deleted === "False" && (
                                                <SelectedOption
                                                    rounded={"full"}
                                                    name={obj?.date}
                                                    onClick={() => filterBlockDate(obj?.id, index)}
                                                />
                                            )}
                                        </>
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={"!text-lg"}
                            title={selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
                            value={toggleStore}
                            onChange={() => setToggleStore(!toggleStore)}
                            checked={toggleStore}
                        />
                        {toggleStore && (
                            <>
                                <div className="flex items-end gap-3">
                                    <Dropwdown
                                        name="storeRestriction"
                                        placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                                        title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                                        parentClass="flex-1"
                                        options={locationDropdown?.filter(
                                            (obj) =>
                                                obj.value !==
                                                discount?.storeRestrictions?.find(
                                                    (location) => location === obj.value
                                                )
                                        )}
                                        value={storeRestriction}
                                        onChange={handleChange}
                                    />
                                    <LoginBtn
                                        className={(storeRestriction) && "shake-bottom"}
                                        text={
                                            <div className="flex items-center gap-2">
                                                {" "}
                                                <Svgs.Plus />
                                                <p>
                                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                                </p>
                                            </div>
                                        }
                                        onClick={addStoreRestriction}
                                        disabled={!storeRestriction}
                                    />
                                </div>
                                <div className="flex items-center gap-2 flex-wrap">
                                    {storeRestrictions?.map((obj, index) => (
                                        <SelectedOption
                                            rounded={"full"}
                                            name={
                                                locationDropdown?.find(
                                                    (location) => location?.value === obj
                                                )?.label
                                            }
                                            onClick={() => filterStoreRestriction(index)}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={"!text-lg"}
                            title={selectedLanguage?.name == "English" ? "Day Restrictions" : translation[906]?.value ? translation[906]?.value : "Day Restrictions"}
                            value={toggleDay}
                            onChange={() => setToggleDay(!toggleDay)}
                            checked={toggleDay}
                        />
                        {toggleDay && (
                            <div className="grid grid-cols-2 gap-4">
                                {dayRestrictions.map((item) => {
                                    return (
                                        <CheckBox
                                            name={item.day}
                                            label={item.day}
                                            value={item.value}
                                            onChange={handleDayChecklist}
                                            checked={item.value === item.day ? true : false}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <LoginBtn
                    text={isEdit ?
                        selectedLanguage?.name == "English" ? "Update" : translation[845]?.value ? translation[845]?.value : "Update"
                        :
                        selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
                    loading={loading}
                    disabled={loading}
                    // disabled={
                    //     // !discount.spendAmount ||
                    //     // discount.freeServices.length === 0 ||
                    //     // discount?.serviceGroupDiscount?.find(
                    //     //   (obj) => !obj?.service_group || !obj?.discount
                    //     // ) ||
                    //     !discount.promotion_name ||
                    //     !discount.dateRestriction.endDate ||
                    //     !discount.dateRestriction.startDate
                    //     || errors.endDate
                    //     // || discount.blockDates.length === 0
                    // }
                    onClick={onSubmit}
                />
            </div>
        </div>
    )
}

export default AddDiscount;

const AddSpendAmountOnSelectedService = (
    {
        obj,
        handleChange,
        removeSpendService,
        serviceDropdown,
        index,
        spendService,
        errors,
        onBlurHandler,
        formErrors
    }
) => {

    const [selected_services_ids, setIds] = useState([])
    const getServiceName = (id) => {
        return serviceDropdown.find((obj) => obj.value === id)?.label;
    }

    useEffect(() => {
        let ids = spendService?.map(itm => itm.service).filter(itm => itm)
        setIds(ids)
    }, [spendService])
    const navigate = useNavigate();
    return (
        <>
            <div className="grid md:grid-cols-2 gap-4 border p-2 rounded-md relative">
                {index > 0 &&
                    <div
                        className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 cursor-pointer"
                        onClick={() => removeSpendService(obj?.id, index)}
                    >
                        <Svgs.CirlceClose fill={"red"} size={"20"} />
                    </div>
                }
                <div className='flex flex-col gap-2 whitespace-nowrap capitalize relative'>
                    <p className='text-sm flex items-center gap-1'>Spend Amount <span class="text-red-600"> *</span></p>
                    <FloatInput
                        name={`spend_amount`}
                        value={obj?.spend_amount}
                        className=''
                        // leftText={'AED'}
                        // title={'Spend Amount '}
                        title={''}
                        placeholder='Enter Amount'
                        required={true}
                        onChange={(e) => handleChange(e, index)}
                        // onBlur={() => onBlurHandler({ [`spend_amount${index}`]: obj?.spend_amount })}
                        // error={errors[`spend_amount${index}`]}
                        error={formErrors?.length > 0 ? formErrors[index]?.spend_amount : ""}
                    />
                </div>
                <div>
                    <Dropwdown
                        required={true}
                        name="service"
                        title={<I_Info_Icon text={'Select Free Service'} info='The client will get selected services as free on spending the mentioned amount.' />}
                        placeholder='Select Service'
                        value={obj?.service}
                        // options={
                        // !obj.service ? serviceDropdown.filter(obj => { 
                        //     return obj && !spendService.filter(element => element.is_deleted === 'False')?.find((element)=> element.service === obj.value)
                        //   }) 
                        //   :
                        //   [{label:getServiceName(obj?.service),value:getServiceName(obj?.service)},
                        //     ...spendService.filter(obj => { 
                        //       return obj && !spendService.filter(element => element.is_deleted === 'False')?.find((element)=> element.service === obj.value)
                        //     })                
                        //   ]
                        // } 
                        options={
                            serviceDropdown.filter(service_obj => {
                                if (obj?.service == service_obj.value || !selected_services_ids.includes(service_obj.value)) {
                                    return true
                                }
                            })
                        }
                        onChange={(e) => handleChange(e, index)}
                        // onBlur={() => onBlurHandler({ [`service${index}`]: obj?.service })}
                        // error={errors[`service${index}`]}
                        error={formErrors?.length > 0 ? formErrors[index]?.service : ""}
                    />
                    {(serviceDropdown.filter(service_obj => {
                        if (obj?.service == service_obj.value || !selected_services_ids?.includes(service_obj.value)) {
                            return true
                        }
                    }))?.length == 0 && (
                            <div
                                className="flex items-center gap-2 cursor-pointer pt-2"
                                onClick={() => {
                                    //humayunnnn
                                    navigate('/dashboard/account/service-management')
                                }}
                            >
                                <p className="text-sm">No services found. Click <span className="underline">here</span> to add.</p>
                            </div>
                        )}
                </div>
            </div>
        </>
    )
}