import React, { useEffect, useState } from "react";
import Svgs from "Assets/svgs";
import { useSelector } from "react-redux";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import I_Info_Icon from "./../../../Elements/I_Info_Icon";
import LoginBtn from "./../../../Elements/LoginBtn";

function AddLoyaltyCard({ editId, setErrors, errors, seteditId, data, index, updateLoyalty, onDelete, currency, updateLoyalityLoader, translation, selectedLanguage, }) {
  const [loyaltyData, setloyaltyData] = useState({});

  const state = useSelector((state) => state);
  const reduxlocationsList = state?.locations?.locations;

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    const newData = { ...data, location: data?.location?.id }
    setloyaltyData(newData)
  }, [editId]);

  //loyalty handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == 'amount_spend' || name == 'number_points' || name == 'earn_points' || name == 'total_earn_from_points') {
      setloyaltyData((prev) => ({
        ...prev,
        [name]: value
      }))
      let error = ''
      if (value == 0 && value !== "") {
        error = selectedLanguage?.name == "English" ? "Value cannot be 0." : translation[1721]?.value ? translation[1721]?.value : "Value cannot be 0."
      } else {
        error = ''
      }
      setErrors((prev) => ({
        ...prev,
        [name]: error
      }))
    }
    if (name != 'amount_spend' && name != 'number_points' && name != 'earn_points' && name != 'total_earn_from_points') {
      setloyaltyData((prev) => ({
        ...prev,
        [name]: value
      }))
      setErrors((prev) => ({
        ...prev,
        [name]: ""
      }))
    }
  };

  return (
    <>
      <div className="flex gap-6">
        <div className="relative">
          {index != 0 && (
            <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
          )}
          <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
            <div className="h-[1.8rem] w-[1.8rem] rounded-full  border border-primary flex items-center justify-center bg-white">
              <Svgs.Appointment_Bullet />
            </div>
          </div>
          <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-1/2 left-1/2 -translate-x-1/2"></div>
        </div>
        {editId === loyaltyData?.id ? (
          <>
            <div className="mt-[2rem] border px-4 py-5 rounded-md w-xl flex flex-col gap-4 flex-1">
              <LoginInput
                required={true}
                name={"name"}
                // charactersMaxLength={25}
                value={loyaltyData?.name}
                onChange={handleChange}
                error={errors?.name}
                title={selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Name" : translation[1090]?.value ? translation[1090]?.value : "Enter Name"}
              />

              {/* <Dropwdown
                name="loyaltytype"
                value={loyaltyData?.loyaltytype}
                onChange={handleChange}
                placeholder="Choose Loyalty Type"
                options={[
                  { label: "Service", value: "Service" },
                  { label: "Retail", value: "Retail" },
                  { label: "Both", value: "Both" },
                ]}
                title={
                  <I_Info_Icon
                    text="Choose Loyalty Type *"
                    info="Choose where you want to implement the loyalty points"
                  />
                }
              /> */}

              <Dropwdown
                required={true}
                name="location"
                value={loyaltyData?.location?.id || loyaltyData?.location}
                onChange={handleChange}
                options={reduxlocationsList?.map((adrs) => {
                  return {
                    label: adrs?.address_name,
                    value: adrs?.id,
                  };
                })}
                error={errors?.location}
                title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                placeholder={selectedLanguage?.name == "English" ? "Select Location" : translation[20]?.value ? translation[20]?.value : "Select Location"}
              />

              <Dropwdown
                required={true}
                name={"status"}
                value={loyaltyData?.status}
                onChange={handleChange}
                error={errors?.status}
                title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[521]?.value : "Select Status"}
                options={[
                  {
                    value: true,
                    label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                  },
                  {
                    value: false,
                    label: selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                  },
                ]}
              />
              <div className="grid lg:grid-cols-2 gap-4">
                <LoginInput
                  type={"number"}
                  required={true}
                  name="amount_spend"
                  value={loyaltyData?.amount_spend}
                  error={errors?.amount_spend}
                  onChange={handleChange}
                  title={
                    <I_Info_Icon
                      text={selectedLanguage?.name == "English" ? "Amount To Spend" : translation[1091]?.value ? translation[1091]?.value : "Amount To Spend"}
                      info={selectedLanguage?.name == "English" ? "Total amount that a client will spend on purchases to avail this offer " : translation[1092]?.value ? translation[1092]?.value : "Total amount that a client will spend on purchases to avail this offer "}
                    />
                  }
                  placeholder={selectedLanguage?.name == "English" ? "Enter Spend Amount" : translation[1095]?.value ? translation[1095]?.value : "Enter Spend Amount"}
                />

                <LoginInput
                  required={true}
                  name="number_points"
                  type="number"
                  value={loyaltyData?.number_points}
                  onChange={handleChange}
                  error={errors?.number_points}
                  title={
                    <I_Info_Icon
                      text={selectedLanguage?.name == "English" ? "Total Number Of Points" : translation[1093]?.value ? translation[1093]?.value : "Total Number Of Points"}
                      info={selectedLanguage?.name == "English" ? "Number of point the client will get on spending the mentioned amount" : translation[1094]?.value ? translation[1094]?.value : "Number of point the client will get on spending the mentioned amount"}
                    />
                  }
                  placeholder={selectedLanguage?.name == "English" ? "Enter No Of Points" : translation[1097]?.value ? translation[1097]?.value : "Enter No Of Points"}
                />
              </div>
              <div className="text-sm flex items-center gap-2 flex-wrap">
                <span>{selectedLanguage?.name == "English" ? "For every" : translation[1096]?.value ? translation[1096]?.value : "For every"}</span>
                <div>
                  <LoginInput
                    parentClass="w-[10rem]"
                    required={true}
                    placeholder={selectedLanguage?.name == "English" ? "Enter Total Points" : translation[1098]?.value ? translation[1098]?.value : "Enter Total Points"}
                    type="number"
                    title=""
                    name="earn_points"
                    error={errors?.earn_points}
                    value={loyaltyData?.earn_points}
                    onChange={handleChange}
                  />
                </div>
                <span>{selectedLanguage?.name == "English" ? "points, a client will get" : translation[1099]?.value ? translation[1099]?.value : "points, a client will get"}</span>
                <div>
                  <LoginInput
                    parentClass="w-[10rem]"
                    required={true}
                    name="total_earn_from_points"
                    value={loyaltyData?.total_earn_from_points}
                    onChange={handleChange}
                    error={errors?.total_earn_from_points}
                    title=""
                    type="number"
                    placeholder={selectedLanguage?.name == "English" ? "Enter Total Amount" : translation[1100]?.value ? translation[1100]?.value : "Enter Total Amount"}
                  />
                </div>
                <span>{selectedLanguage?.name == "English" ? "amount" : translation[793]?.value ? translation[793]?.value : "amount"}</span>
              </div>
              <div className="flex justify-end items-center">
                <LoginBtn
                  // disabled={
                  //   !loyaltyData?.name ||
                  //   !loyaltyData?.amount_spend ||
                  //   !loyaltyData?.number_points ||
                  //   !loyaltyData?.earn_points ||
                  //   // !loyaltyData?.loyaltytype ||
                  //   !loyaltyData?.location ||
                  //   !loyaltyData?.total_earn_from_points
                  //   || updateLoyalityLoader
                  // }
                  className="ml-6 mt-2"
                  text={selectedLanguage?.name == "English" ? "Update Loyalty" : translation[1088]?.value ? translation[1088]?.value : "Update Loyalty"}
                  loading={updateLoyalityLoader}
                  disabled={updateLoyalityLoader}
                  onClick={() => {
                    updateLoyalty(loyaltyData);
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mt-[2rem] rounded-md w-full flex flex-col gap-4">
              <div className="loyalty-gradient flex">
                <div className="px-3 md:px-8 py-4 bg-[#5679FF] flex items-center justify-center flex-col text-white">
                  <button className="bg-white text-[#5679FF] rounded-full px-6 py-2 font-bold">
                    {selectedLanguage?.name == "English" ? "Offer" : translation[1102]?.value ? translation[1102]?.value : "Offer"}
                  </button>
                  <p className="w-[70%] md:w-fit mx-auto text-center">{data?.name}</p>
                </div>
                <div className="flex gap-2 flex-col px-6 py-8 flex-1">
                  <div className="flex items-center justify-between gap-4">
                    <div className="flex items-end ">
                      {/* <h1 className="text-[#22283D] font-bold text-2xl">
                        {data?.name}
                      </h1> */}
                      {
                        data?.location &&
                        <div className="flex gap-2 mx-2 items-center text-[#22283D] font-bold text-lg">
                          {/* <Svgs.Location fill='#fff' /> */}
                          <Svgs.MAP_PIN stroke='#fff' />
                          <p>{data?.location?.address_name}</p>
                        </div>
                      }
                    </div>
                    <div className="flex items-center gap-3">
                      {!employee ||
                        (employee &&
                          employeePermissions?.client_loyality?.includes(
                            "edit"
                          )) ? (
                        <div
                          className="h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                          onClick={() => {
                            // onEditClick(data?.id)
                            seteditId(data?.id);
                          }}
                        >
                          <Svgs.Edit fill="#767A87" />
                        </div>
                      ) : (
                        ""
                      )}
                      {!employee ? (
                        <div
                          className="h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                          onClick={() => {
                            onDelete(data?.id);
                          }}
                        >
                          <Svgs.Delete fill="#767A87" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="text-[#22283D] text-lg">
                    <p className="">
                      {/* <span className="text-lg">
                        Spent {data?.amount_spend} {currency} On{" "}
                        {data?.loyaltytype} to get {data?.number_points} points
                      </span> */}
                      <span className="text-lg">
                        {selectedLanguage?.name == "English" ? "Spent" : translation[1103]?.value ? translation[1103]?.value : "Spent"} {data?.amount_spend} {" "}
                        {selectedLanguage?.name == "English" ? "to get" : translation[1105]?.value ? translation[1105]?.value : "to get"} {data?.number_points} {selectedLanguage?.name == "English" ? "points" : translation[1104]?.value ? translation[1104]?.value : "points"}
                      </span>
                    </p>
                    {/* <p className="text-2xl font-bold">
                      {" "}
                      Get {data?.total_earn_from_points} {currency} of on{" "}
                      {data?.earn_points} points
                    </p> */}
                    <p className="text-2xl font-bold">
                      {" "}
                      {selectedLanguage?.name == "English" ? "Get" : translation[1106]?.value ? translation[1106]?.value : "Get"} {data?.total_earn_from_points} {selectedLanguage?.name == "English" ? "amount for every" : translation[1109]?.value ? translation[1109]?.value : "amount for every"}{" "}
                      {data?.earn_points} {selectedLanguage?.name == "English" ? "points" : translation[1104]?.value ? translation[1104]?.value : "points"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddLoyaltyCard;
