import React from 'react'
// import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import Popup from 'new-components/popup'
import Selection from 'new-components/dropdown';
import useCopyStore from './helper';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';

function CopyStoreTarget({ onClose }) {
    const {
        control, reset, isValid, errors, onApplyFilter, handleSubmit, monthDropdown, toMonth
    } = useCopyStore();
    return (
        <Popup
            heading={`Copy Service Target`}
            handleClose={onClose}
            size='md'
        >
            <form onSubmit={handleSubmit(onApplyFilter)} >
                <div className='flex flex-col gap-4'>
                    <div className='grid md:grid-cols-2 gap-4'>
                        <Selection
                            label={'From Month'}
                            name={"from_month"}
                            placeholder='Select Month'
                            control={control}
                            options={monthDropdown}
                            errorMessage={errors?.from_month?.message}
                        />
                        <Selection
                            label={'To Month'}
                            name={"to_month"}
                            placeholder='Select Month'
                            control={control}
                            options={toMonth}
                            errorMessage={errors?.to_month?.message}
                        />
                    </div>
                    <LoginBtn
                        disabled={!isValid}
                        text={`Save`}
                    />
                </div>
            </form>
        </Popup>
    )
}

export default CopyStoreTarget
