import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import AccountSetupCard from "../AccountSetupCard";

import Svgs from "Assets/svgs";

const DashboardEmployee = () => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  const [employee_cards, setEmployeeCards] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    let filtered_data = employeeModuleArr.filter((itm) => {
      if (employee) {
        if (employeePermissions[itm.permission_value]?.includes("view")
          || employeePermissions[itm.permission_value]?.includes("create")
          || employeePermissions[itm.permission_value]?.includes("edit")
          || employeePermissions[itm.pos_value]?.includes("view")
          || employeePermissions[itm.root_permission_value]?.includes("view")) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    setEmployeeCards(filtered_data);
  }, [employeePermissions]);

  // return (
  //   <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
  //     <div className="flex items-center gap-5 lg:w-fit w-full lg:px-0 lg:pb-[2rem] lg:py-0 p-3 pb-8">

  //       <h2 className="font-semibold text-2xl">Employee Management</h2>
  //     </div>
  //     <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem] xl:pr-[4rem] pr-0">
  //       {employee_cards.map(({ link, icon, title, description }, index) => (
  //         <AccountSetupCard
  //           link={link}
  //           icon={icon}
  //           title={title}
  //           description={description}
  //           key={index}
  //         />
  //       ))}
  //     </div>
  //   </div>
  // );
  // };

  const employeeModuleArr = [
    {
      link: "/dashboard/employee/employees",
      icon: <Svgs.Dashboard_Employee />,
      title: selectedLanguage?.name == "English" ? "Employees" : translation[399]?.value ? translation[399]?.value : "Employees",
      description: selectedLanguage?.name == "English" ? "Manage employee settings such as your employee name and information." : translation[400]?.value ? translation[400]?.value : "Manage employee settings such as your employee name and information.",
      permission_value: "employee_new",
      pos_value: "pos_root_access",
      root_permission_value: "employee_root_access"
    },
    {
      link: "/dashboard/employee/payroll",
      icon: <Svgs.Dashboard_Payroll />,
      title: selectedLanguage?.name == "English" ? "Payroll" : translation[402]?.value ? translation[402]?.value : "Payroll",
      description: selectedLanguage?.name == "English" ? "Manage employees payroll settings." : translation[403]?.value ? translation[403]?.value : "Manage employees payroll settings.",
      permission_value: "employee_payroll",
      pos_value: "pos_root_access",
      root_permission_value: "employee_root_access"
    },
    {
      link: "/dashboard/employee/attendance_leoday",
      icon: <Svgs.Dashboard_Attendance />,
      title: selectedLanguage?.name == "English" ? "Attendance" : translation[404]?.value ? translation[404]?.value : "Attendance",
      description: selectedLanguage?.name == "English" ? "Manage employees attendance settings." : translation[405]?.value ? translation[405]?.value : "Manage employees attendance settings.",
      permission_value: "employee_attendance",
      pos_value: "pos_root_access",
      root_permission_value: "employee_root_access"
    },
    {
      link: "/dashboard/employee/staff-groups",
      icon: <Svgs.Dashboard_Staff />,
      title: selectedLanguage?.name == "English" ? "Staff Groups" : translation[406]?.value ? translation[406]?.value : "Staff Groups",
      description: selectedLanguage?.name == "English" ? "Manage staff groups settings." : translation[407]?.value ? translation[407]?.value : "Manage staff groups settings.",
      permission_value: "employee_staff_group",
      pos_value: "pos_root_access",
      root_permission_value: "employee_root_access"
    },
    {
      link: "/dashboard/employee/reports",
      icon: <Svgs.Dashboard_Reports />,
      title: selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports",
      description: selectedLanguage?.name == "English" ? "Manage employees reports settings." : translation[408]?.value ? translation[408]?.value : "Manage employees reports settings.",
      permission_value: "employee_reports",
      pos_value: "pos_root_access",
      root_permission_value: "employee_root_access"
    },
    {
      link: "/dashboard/employee/commission-scheme",
      icon: <Svgs.Dashboard_Scheme />,
      title: selectedLanguage?.name == "English" ? "Commission Scheme" : translation[409]?.value ? translation[409]?.value : "Commission Scheme",
      description: selectedLanguage?.name == "English" ? "Manage commission scheme settings." : translation[410]?.value ? translation[410]?.value : "Manage commission scheme settings.",
      permission_value: "employee_commission",
      pos_value: "pos_root_access",
      root_permission_value: "employee_root_access"
    },
    {
      link: "/dashboard/employee/working-schedule",
      icon: <Svgs.Dashboard_Schedule />,
      title: selectedLanguage?.name == "English" ? "Working Schedule" : translation[412]?.value ? translation[412]?.value : "Working Schedule",
      description: selectedLanguage?.name == "English" ? "Manage working schedule settings." : translation[413]?.value ? translation[413]?.value : "Manage working schedule settings.",
      permission_value: "employee_work_schedule",
      pos_value: "pos_root_access",
      root_permission_value: "employee_root_access"
    },
    // {
    //   link: "/dashboard/employee/history-log",
    //   icon: <Svgs.Dashboard_History />,
    //   title: "History Log",
    //   description: "Manage history log settings.",
    // },
    {
      link: "/dashboard/employee/vacation&absence",
      icon: <Svgs.Dashboard_Vacation />,
      title: selectedLanguage?.name == "English" ? "Vacation & Absence" : translation[414]?.value ? translation[414]?.value : "Vacation & Absence",
      description: selectedLanguage?.name == "English" ? "Manage vacations for your staff from their working schedule" : translation[415]?.value ? translation[415]?.value : "Manage vacations for your staff from their working schedule",
      permission_value: "employee_vacation",
      pos_value: "pos_root_access",
      root_permission_value: "employee_root_access"
    },
    // {
    //   link: "/dashboard/employee/upsell&downsell-report",
    //   icon: <Svgs.Dashboard_Vacation />,
    //   title: selectedLanguage?.name == "English" ? "Upsell and Downsell Report" : translation[1690]?.value ? translation[1690]?.value : "Upsell and Downsell Report",
    //   description: selectedLanguage?.name == "English" ? "Manage vacations for your staff from their working schedule" : translation[415]?.value ? translation[415]?.value : "Manage vacations for your staff from their working schedule",
    //   permission_value: "employee_upsell&downsell-report",
    //   pos_value: "pos_root_access",
    //   root_permission_value: "employee_root_access"
    // },
    {
      link: "/dashboard/employee/employee-comments",
      icon: <Svgs.EmployeeCommands />,
      title: selectedLanguage?.name == "English" ? "Employee Comments" : translation[1683]?.value ? translation[1683]?.value : "Employee Comments",
      description: selectedLanguage?.name == "English" ? "Management truly listens to employee feedback and implements changes." : translation[1689]?.value ? translation[1689]?.value : "Management truly listens to employee feedback and implements changes.",
      permission_value: "employee_comments",
      pos_value: "pos_root_access",
      root_permission_value: "employee_root_access"
    },
    {
      link: "/dashboard/employee/employee-training-requests",
      icon: <Svgs.Dashboard_Staff />,
      title: selectedLanguage?.name == "English" ? "Employee Training Requests" : translation[1706]?.value ? translation[1706]?.value : "Employee Training Requests",
      description: selectedLanguage?.name == "English" ? "Management truly listens to employee feedback and implements changes." : translation[1689]?.value ? translation[1689]?.value : "Management truly listens to employee feedback and implements changes.",
      permission_value: "employee_training_requests",
      pos_value: "pos_root_access",
      root_permission_value: "employee_root_access"
    },
  ];


  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
      <div className="flex items-center gap-5 lg:w-fit w-full lg:px-0 lg:pb-[2rem] lg:py-0 p-3 pb-8">
        <h2 className="font-semibold text-2xl">
          {selectedLanguage?.name == "English" ? "Employee Management" : translation[375]?.value ? translation[375]?.value : "Employee Management"}
        </h2>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem] xl:pr-[4rem] pr-0">
        {employee_cards.map(({ link, icon, title, description }, index) => (
          <AccountSetupCard
            link={link}
            icon={icon}
            title={title}
            description={description}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default DashboardEmployee;


