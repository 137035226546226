import { PAYROLL } from "../../ActionTypes/payrollTypes";


const initialState = {
    payrolls: [],
    allPayrolls: [],
    payroll_updated: false,
    pages: 0,
    upsell_downsell: [],
    upsell_downsell_pages: [],
    upsell_downsell_store: [],
    // upsell_downsell_store_pages: []
};


const PayrollReducers = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {

        case PAYROLL.GET_PAYROLL:
            return {
                ...state,
                payroll_updated: true,
                payrolls: payload,
            };

        case PAYROLL.GET_PAYROLL_WITHOUT_PAGINATION:
            return {
                ...state,
                payroll_updated: true,
                allPayrolls: payload,
            };
        case PAYROLL.GET_PAYROLL_PAGES:
            return {
                ...state,
                pages: payload,
            };


        case PAYROLL.GET_UPSEL_DOWNSELL:
            return {
                ...state,
                upsell_downsell: payload?.data,
                upsell_downsell_pages: payload?.total_pages
            };

        case PAYROLL.GET_UPSEL_DOWNSELL_STORE:
            return {
                ...state,
                upsell_downsell_store: payload,
                // upsell_downsell_store_pages: payload?.total_pages
            };


        case PAYROLL.DELETE_PAYROLL:
            let result = state?.payrolls?.filter((item) => item.id !== payload);
            return {
                ...state,
                payrolls: [...result],
            };



        default:
            return state;
    }
};
export default PayrollReducers;
