import React, { useEffect, useState } from 'react'
import { UpdateAppointmentServiceStatus, getAppointmentLog, getAppointmentServices, requestAppointmentServiceReversal } from 'Adapters/Api/apointments';
import { apiErrorMessage } from 'Constants/Data/Errors';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { GET_APPOINTMENT_DETAIL } from 'Redux/TenantRedux/ActionTypes/Appointment/Appointment';
import moment from 'moment';
import { log } from 'util';
import useValidations from 'Hooks/useValidations';
import useObjectKeys from 'Hooks/useObjectKeys';

function useAppointmentServiceDetails(app_group_id) {
    const state = useSelector(state => state)
    const appointmentServicesDetail = state?.appointment?.appointmentDetail
    const dispatch = useDispatch()
    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys()

    const [taxValuesForApi, setTaxValuesForApi] = useState({})
    const [appointmentServices, setAppointmentService] = useState([])
    const [tipsApplied, setTipsApplied] = useState([])
    const [appliedTipTotal, setAppliedTipTotal] = useState(0)
    const [appointmentLog, setAppointmentLog] = useState([])
    const [fetchLogs, setFetchLogs] = useState(false)
    const [loader, setLoader] = useState(true)
    const [voidPopup, setVoidPopup] = useState(false)
    const [clientDetails, setClientDetails] = useState({
        client_id: '',
        isOpen: false
    })
    const [requestReversalPopup, setRequestReversalPopup] = useState(false)
    const [callCalendarApi, setCallCalendarApi] = useState(false);
    const [reversalLoader, setReversalLoader] = useState(false)
    const [requestReversalNote, setRequestReversalNote] = useState({
        description: "",
        appointment_id: "",
        appointment_service: "",
        existingStatus: ""
    })
    const [statusLoader, setStatusLoader] = useState({
        Void: false,
        Started: false,
        Finished: false,
    });
    const [subTotal, setSubTotal] = useState(0)

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");
    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");
    const selectedLocationCurrency = getDataFromLocalStorage("selectedLocationCurrency")

    useEffect(() => {
        getAppointmentServicesApi()
    }, [app_group_id]);

    const getAppointmentServicesApi = () => {
        dispatch(getAppointmentServices(
            app_group_id,
            (response) => {
                setAppointmentService(response?.appointment?.appointmnet_service?.filter(itm => itm?.status != "Void"));
                let tips = []
                let total = 0
                dispatch({
                    type: GET_APPOINTMENT_DETAIL,
                    payload: { ...response?.appointment, total_sales: response?.total_sales },
                });
                response?.appointment?.appointment_tips?.forEach(empl_tip => {
                    if (empl_tip?.tip) {
                        total += empl_tip.tip
                    }
                    tips.push({
                        ...empl_tip,
                        employee: empl_tip?.member_name,
                        hide_delete_icon: true
                    })
                })
                setTipsApplied([...tips]);
                setAppliedTipTotal(total)
                setLoader(false)
            },
            () => {
                toast.error(apiErrorMessage, { toastId: "toast" })
            },

        ));
    }

    const handleAppointmentLog = (appointment_id) => {
        setFetchLogs(true)
        dispatch(getAppointmentLog(
            selected_location,
            appointment_id,
            access_token,
            (response) => {
                setFetchLogs(false)
                setAppointmentLog(response?.appointment_logs)
            },
            (result) => {
                setFetchLogs(false)
                toast.error(apiErrorMessage, { toastId: "toast" })
            }))
    }

    const updateAppointmentServiceStatus = (srvId, appointmentId, status, voidReason) => {
        setCallCalendarApi(true)
        setStatusLoader(() => ({
            id: srvId,
            [status]: true,
        }));
        setAppointmentService((prevAppointmentServices) => {
            const updatedServices = [...prevAppointmentServices];
            const serviceToUpdate = updatedServices?.find(srv => srv?.id === srvId);

            if (serviceToUpdate) {
                if (status === "Started") {
                    serviceToUpdate.service_start_time = moment();
                }
                if (status === "Finished") {
                    serviceToUpdate.service_end_time = moment();
                }
            }

            return updatedServices;
        });
        UpdateAppointmentServiceStatus(
            {
                appointment_service_id: srvId, appointment_id: appointmentId, status: status, cancel_reason: voidReason?.void_note, tax_name: taxValuesForApi?.tax_name, tax_name1: taxValuesForApi?.tax_name1,
                gst: taxValuesForApi?.gst, gst1: taxValuesForApi?.gst1, gst_price: taxValuesForApi?.gst_price, gst_price1: taxValuesForApi?.gst_price1
            },
            (response) => {
                getAppointmentServicesApi()
                setStatusLoader(() => ({
                    id: srvId,
                    [status]: false,
                }));
                setAppointmentService((prevAppointmentServices) => {
                    const updatedServices = [...prevAppointmentServices];
                    const serviceToUpdate = updatedServices?.find(srv => srv?.id === srvId);

                    if (serviceToUpdate) {
                        serviceToUpdate.status = status;
                    }

                    return updatedServices;
                });
                if (status === "Void") {
                    setVoidPopup(!voidPopup)
                }
            },
            (result) => {
                setStatusLoader(() => ({
                    id: srvId,
                    [status]: false,
                }));
                toast.error(apiErrorMessage, { toastId: "toast" })
            })
    }

    const handleReversalRequest = () => {
        let requiredField = { reversalNote: requestReversalNote?.description }
        setErrors(validation(requiredField));
        const result = validation(requiredField);
        const filteredService = appointmentServicesDetail?.appointmnet_service?.find(itm => itm?.id == requestReversalNote?.appointment_service)
        if (objectKeyConvertToArray(result)?.length === 0) {
            setReversalLoader(true)
            // Get the current URL pathname
            const pathname = window.location.href;
            // Find the index of "/dashboard" in the pathname
            const dashboardIndex = pathname.indexOf('/dashboard');
            // Extract the part of the pathname before "/dashboard"
            const desiredPath = dashboardIndex !== -1 ? pathname.substring(0, dashboardIndex) : pathname;

            dispatch(requestAppointmentServiceReversal(
                {
                    ...requestReversalNote,
                    business: businessData?.id,
                    appointment_date: filteredService?.appointment_date,
                    service_name: filteredService?.srv_name,
                    service_id: filteredService?.id,
                    location_id: filteredService?.location?.id,
                    client_type: appointmentServicesDetail?.client_type,
                    client_name: appointmentServicesDetail?.client_name,
                    client_phone: appointmentServicesDetail?.client_phone,
                    email: state?.user?.user?.email,
                    url: `${desiredPath}/dashboard/sales/appointments?active_tab=reversal-requests`,
                    generated_by: state?.user?.user?.username,
                    generated_by_id: state?.user?.user?.id
                },
                (response) => {
                    getAppointmentServicesApi()
                    toast.success(response?.response?.message, { toastId: "toast" })
                    setRequestReversalPopup(false)
                    setReversalLoader(false)
                    setRequestReversalNote({
                        description: "",
                        appointment_id: "",
                        appointment_service: ""
                    })
                },
                (result) => {
                    setReversalLoader(false)
                    toast.error(apiErrorMessage, { toastId: "toast" })
                }))
        }
    }

    return {
        appointmentServices, appointmentLog, tipsApplied, fetchLogs, handleAppointmentLog, appliedTipTotal, updateAppointmentServiceStatus,
        statusLoader, voidPopup, setVoidPopup, clientDetails, setClientDetails, setAppointmentService, loader, callCalendarApi, setCallCalendarApi, setTaxValuesForApi,
        requestReversalPopup, setRequestReversalPopup, requestReversalNote, setRequestReversalNote, handleReversalRequest,
        reversalLoader, setErrors, validation, errors, onBlurHandler, objectKeyConvertToArray, selectedLocationCurrency, subTotal, setSubTotal
    }
}

export default useAppointmentServiceDetails