import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProductRefundInvoice from './ProductReports';
import LocationSelection from "new-components/location";
import ServiceRefundInvoice from './ServiceReports';

const RefundReports = () => {
    const navigate = useNavigate()
    const [invoiceFor, setInvoiceFor] = useState('product')
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>
            <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
                <p className="text-[#7B7B7B] text-sm">
                    <span
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/dashboard/account/");
                        }}
                    >
                        {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
                    </span>{" "}
                    &gt;{" "}
                    <span
                        className="cursor-pointer"
                        onClick={() => {
                            navigate("/dashboard/account/inventory-operations");
                        }}
                    >
                        {selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations"}
                    </span>{" "}
                    &gt; <span className="text-primary font-semibold">
                        {selectedLanguage?.name == "English" ? "Refund Reports" : translation[1662]?.value ? translation[1662]?.value : "Refund Reports"}
                    </span>
                </p>
                <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
                    <div className="flex items-center sm:gap-4 gap-2">

                        <h2 className="text-primary font-bold lg:text-3xl text-xl">
                            {selectedLanguage?.name == "English" ? "Refund Reports" : translation[1662]?.value ? translation[1662]?.value : "Refund Reports"}
                        </h2>
                        <LocationSelection />
                    </div>
                </div>

                <div className="border-b mt-[3rem]">
                    <div className="flex items-center gap-6">
                        <div
                            className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${invoiceFor == "product" && "font-semibold border-primary"}`}
                            onClick={() => {
                                setInvoiceFor("product");
                                // setSearch('');
                                // setGroupSearch('')
                            }}
                        >
                            <h2 className="text-primary">
                                {selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}
                            </h2>
                        </div>
                        <div className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${invoiceFor == "service" && "font-semibold border-primary"}`}
                            onClick={() => {
                                setInvoiceFor("service");
                                // setSearch('');
                                // setGroupSearch('')
                            }}
                        >
                            <h2 className="text-[#595A5A]">
                                {selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"}
                            </h2>
                        </div>
                    </div>
                </div>

                {invoiceFor == 'product'
                    ? <ProductRefundInvoice />
                    : invoiceFor == 'service'
                    && <ServiceRefundInvoice />
                }
            </div>
        </>
    )
}

export default RefundReports