import { NOTIFICATION } from './../../ActionTypes/notificationsTypes/index';

const notificationsData= {
 notifications: {},
}

const NotificationReducer = (state = notificationsData, action) => {
    
  const { type, payload } = action;

  switch (type) {

    case NOTIFICATION.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: payload,
      };

    default:
      return state;
  }

};

export default NotificationReducer;


