import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DateRangePicker = ({ start, end, setStartDate, setEndDate, addCustomDataOnSelection, setToggleDateRange }) => {
    const [dateSelected, setDateSelected] = useState(false)
    const [clickCount, setClickCount] = useState(0);
    const [selectionRange, setSelectionRange] = useState({
        startDate: start ? new Date(start) : new Date(),
        endDate: end ? new Date(end) : new Date(),
        key: 'selection',
    });

    const handleSelect = (ranges) => {
        setClickCount(clickCount + 1);
        if (ranges.selection.startDate && ranges.selection.endDate) {
            setSelectionRange(ranges.selection);
            setDateSelected(true)
        } else if (ranges.selection.startDate) {
            // User has only selected the start date, not the end date
            setSelectionRange({
                startDate: ranges.selection.startDate,
                endDate: null,
                key: 'selection',
            });

        } else {
            // User has cleared both start and end dates
            setSelectionRange({
                startDate: null,
                endDate: null,
                key: 'selection',
            });
        }
    };


    useEffect(() => {
        if (clickCount == 2) {
            if (selectionRange.startDate && selectionRange.endDate && dateSelected && selectionRange?.endDate != "Invalid Date") {
                const from = selectionRange.startDate ? moment(selectionRange.startDate).format("YYYY-MM-DD") : '';
                const to = selectionRange.endDate ? moment(selectionRange.endDate).format("YYYY-MM-DD") : '';
                setStartDate(from);
                setEndDate(to);
                addCustomDataOnSelection && addCustomDataOnSelection()
                setToggleDateRange(false)
            }
        }
    }, [selectionRange, clickCount]);

    return (
        <div className="date-range-picker-container shadow-sm overflow-hidden border rounded-md mb-2">
            <DateRange
                editableDateInputs={true}
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
                ranges={[selectionRange]}
            />
        </div>
    );
}

export default DateRangePicker;
