import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getLocations } from "Adapters/Api/index";
import { getClient } from "Adapters/Api/clients/index";
import { getLocationTax, getSaleServices } from "Adapters/Api/saleServices";
import { getEmployeeList } from "Adapters/Api/employee/index";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import moment from 'moment';
import { get_discount_promotions_reports, get_discount_promotions_reports_invoicedetail, get_discount_promotions_reports_without_pagination } from "Adapters/Api/PromotionDiscountReports";
import useCalculateTaxes from "Hooks/useCalculateTaxes";
import { toast } from "react-toastify";

// import { getAllSaleOders } from "Adapters/Api/saleOrders";
// import { count } from "Adapters/Api/saleOrders"
// import { BASE_URL, get_promotions_and_discounts_reports } from "Adapters/Api/api_variables";
// import axios from "axios";

let timeOutId = undefined;
export const useDiscountPromotionsReports = () => {
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const businessData = getDataFromLocalStorage("business_data");
    const current_location = getDataFromLocalStorage("selected_location");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [loading, setLoading] = useState(true);
    const [noPaginationLoader, setNoPaginationLoader] = useState(true);
    const [commissionAndReportsWithNoPagination, setCommissionAndReportsWithNoPagination] = useState([])
    const [exportLoader, setExportLoader] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [detailLoading, setDetailLoading] = useState(true)
    const [search_text, setSearchText] = useState("");
    const [selectedInvice, setSelectedInvice] = useState();
    const [commissionAndReports, setCommissionAndReports] = useState([])
    const [promotionData, setPromotionData] = useState([])
    const [selectedInvoiceTip, setSelectedInvoiceTip] = useState(0);
    const [pages, setPages] = useState(0)
    const [InvoicePopup, setInvoicePopup] = useState(false);


    const state = useSelector((state) => state);
    const reduxlocationsList = state?.locations?.locations;
    const total_tax = state?.saleService?.total_tax;
    const { selected_location } = state?.locations;
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const invoice_titles = state?.saleOrder?.invoice_titles;
    const clientRedux = state?.client?.clients;
    const reduxEmployees = state.employee?.employees;
    const reduxServices = state?.saleService?.saleServices;
    const location_currency = state?.utility?.currency?.location_currency;
    const locationTax = state?.saleService?.location_tax
    // const businessCurrency = state.business.business;
    // const allSaleorders = state?.saleOrder?.allSaleorders;
    // const pages = state?.saleOrder?.pages;

    const isPermissionUpdated = state?.user?.isPermissionUpdated;
    const [loadingPermission, setLoadingPermission] = useState(true)
    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])

    const { calculateTaxes } = useCalculateTaxes(locationTax);

    // const invoiceLabels = useMemo(() => {
    //     return (
    //         commissionAndReports?.find(itm => itm?.location == current_location)
    //     )
    // }, [commissionAndReports?.length || current_location])

    useEffect(() => {
        if (locationTax?.id) {
            calculateTaxes(locationTax)
        }
    }, [locationTax?.id])

    const onChange = (e) => {
        setSearchText(e.target.value);
        // searchFilter(e.target.value);
    };

    // const searchFilter = (id) => {
    //     if (id) {
    //         console.log("FIlter", id);
    //         const data = promotionData.filter((itm) => itm.id.includes(id))
    //         setCommissionAndReports(data)
    //     } else {
    //         setCommissionAndReports(promotionData)
    //     }
    // }

    const calculate_totals = (item) => {
        let total_bill = 0;
        if (typeof item?.membership == "object") {
            if (item?.membership?.length > 0) {
                item?.membership?.forEach((itm) => {
                    total_bill += itm?.membership_price * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        if (typeof item?.voucher == "object") {
            if (item?.voucher?.length > 0) {
                item?.voucher?.forEach((itm) => {
                    total_bill += (itm?.voucher_price) * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        if (typeof item?.service == "object") {
            if (item?.service?.length > 0) {
                item?.service?.forEach((single_service) => {
                    // let this_price = single_service?.service?.priceservice?.find(price => price.currency_name == location_currency)
                    // if (this_price) {
                    // total_bill += (this_price?.price) * (single_service?.quantity ? single_service?.quantity : 1);
                    // }
                    total_bill += (single_service?.price) * (single_service?.quantity ? single_service?.quantity : 1);
                });
            }
        }


        if (typeof item?.product == "object") {
            if (item?.product?.length > 0) {
                item?.product?.forEach((itm) => {
                    total_bill += (itm?.price) * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        // if (item?.tip?.length > 0) {
        //   const selected_invoice_tip = (item?.tip?.length > 0 ? item?.tip?.reduce(function (total, obj) {
        //     return total + (obj.tip !== null && obj?.tip);
        //   }, 0) : 0)

        //   if (selected_invoice_tip) {
        //     total_bill += selected_invoice_tip
        //   }
        // }


        if (item?.order_type === "Appointment") {
            total_bill += item?.total_price;
        }
        let total = total_bill?.toFixed(2)
        return total;
    };


    // const toggleInviceModal = (id = "", type = "") => {
    //     if (id && type == "history") {
    //         const result = commissionAndReports?.find(
    //             (item) => item?.id == id
    //         );
    //         calculate_totals(result);
    //         setSelectedInvice(result);
    //     }

    //     setInvoicePopup(!InvoicePopup);
    // };

    const getInvoiceDetailData = (id) => {
        setInvoicePopup(!InvoicePopup);
        dispatch(get_discount_promotions_reports_invoicedetail(
            id, selected_location, setDetailLoading,
            (result) => {
                console.log("🚀 ~ getInvoiceDetailData ~ result:", result)
                setSelectedInvice(result?.checkout);
            },
            () => { }
        ));
    }

    // const calculateTotalAmount = (item) => {
    //     let total_amount = 0;

    //     if (typeof item?.membership == "object") {
    //         if (item?.membership?.length > 0) {
    //             item?.membership?.forEach((itm) => {
    //                 total_amount += itm?.membership_price * (itm?.quantity ? itm?.quantity : 1);
    //             });
    //         }
    //     }

    //     if (typeof item?.voucher == "object") {
    //         if (item?.voucher?.length > 0) {
    //             item?.voucher?.forEach((itm) => {
    //                 total_amount += (itm?.voucher_price) * (itm?.quantity ? itm?.quantity : 1);
    //             });
    //         }
    //     }

    //     if (typeof item?.service == "object") {
    //         if (item?.service?.length > 0) {
    //             item?.service?.forEach((itm) => {
    //                 total_amount += (itm?.price * itm?.quantity)
    //                 // itm?.service?.priceservice?.forEach((tm) => {
    //                 //   total_amount += (tm?.price) * (itm?.quantity ? itm?.quantity : 1);
    //                 // });
    //             });
    //         }
    //     }

    //     if (typeof item?.product == "object") {
    //         if (item?.product?.length > 0) {
    //             item?.product?.forEach((itm) => {
    //                 total_amount += (itm?.product_price) * (itm?.quantity ? itm?.quantity : 1);
    //             });
    //         }
    //     }

    //     if (item?.order_type === "Appointment") {
    //         total_amount += item?.total_price;
    //     }

    //     return total_amount;
    // }


    let promotionDiscountReport = [
        { label: `${selectedLanguage?.name == "English" ? 'Invoice' : translation[411]?.value ? translation[411]?.value : 'Invoice'} #`, key: 'invoice' },
        { label: selectedLanguage?.name == "English" ? 'Discount Name' : translation[416]?.value ? translation[416]?.value : 'Discount Name', key: 'discount_name' },
        { label: selectedLanguage?.name == "English" ? 'Date' : translation[417]?.value ? translation[417]?.value : 'Date', key: 'date' },
        { label: selectedLanguage?.name == "English" ? 'Original Price' : translation[418]?.value ? translation[418]?.value : 'Original Price', key: 'original_price' },
        { label: selectedLanguage?.name == "English" ? 'Discounted Price' : translation[419]?.value ? translation[419]?.value : 'Discounted Price', key: 'discounted_price' },
        { label: selectedLanguage?.name == "English" ? 'Location' : translation[19]?.value ? translation[19]?.value : 'Location', key: 'location' },
    ]

    const exportPromotionDiscountReport = {
        filename: "Discound And Promotion Report.csv",
        headers: promotionDiscountReport,
        data: commissionAndReportsWithNoPagination?.length > 0 ? commissionAndReportsWithNoPagination?.map((item) => {
            // let total_amount = calculateTotalAmount(item)
            let date = moment(item?.created_at).format('DD-MM-YYYY')
            return {
                invoice: item?.id ? item?.id : "----",
                discount_name: item?.promotion?.promotion_name ? item?.promotion?.promotion_name : "----",
                date: date ? date : "----",
                original_price: item?.original_price ? (+item?.original_price)?.toFixed(2) : "----",
                discounted_price: item?.discounted_price ? (+item?.discounted_price)?.toFixed(2) : 0,
                location: item?.location?.address_name ? item?.location?.address_name : "----",
                client: (typeof item?.client == "string") ? item?.client : item?.client?.full_name ? item?.client?.full_name : "----",
            }
        }) : []
    }

    const handleExportClick = () => {
        if (!isToastVisible) {
            if (commissionAndReportsWithNoPagination?.length > 0) {
                // setExportLoader(true)
                setTimeout(() => {
                    toast.success("File downloaded successfully", {
                        toastId: "toast",
                        onClose: () => setIsToastVisible(false)
                    });
                    setExportLoader(false)
                }, 300);
            } else {
                toast.error("No data to export", {
                    onClose: () => setIsToastVisible(false),
                });
            }
            setIsToastVisible(true);
        }
    };

    const getData = (search_text) => {
        setLoading(true)
        dispatch(get_discount_promotions_reports(
            {
                location_id: selected_location, startDate: startDate, endDate: endDate, currentPage: currentPage, search_text: search_text
            },
            (result) => {
                setCommissionAndReports(result?.response?.sales)
                setPromotionData(result?.response?.sales)
                setPages(result?.pages)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))

    }
    // no pagination
    const getDataWithNoPagination = (search_text) => {
        setNoPaginationLoader(true)
        dispatch(get_discount_promotions_reports_without_pagination(
            {
                location_id: selected_location, startDate: startDate, endDate: endDate, search_text: search_text
            },
            (result) => {
                setCommissionAndReportsWithNoPagination(result?.response?.sales)
                setNoPaginationLoader(false)
            },
            (err) => {
                setNoPaginationLoader(false)
            }
        ))

    }

    //get business location and stored it in redux
    const getBusinessLocation = (business_id) => {
        dispatch(getLocations(business_id));
    };
    const get_employees = () => {
        dispatch(getEmployeeList());
    };

    useEffect(() => {

        if (reduxlocationsList?.length === 0) {
            getBusinessLocation(businessData?.id);
        }

        if (clientRedux?.length === 0) {
            dispatch(getClient());
        }

        if (reduxEmployees?.length === 0) {
            get_employees();
        }

        if (reduxServices.length === 0) {
            dispatch(getSaleServices());
        }

        if (selected_location) {
            dispatch(getLocationTax(selected_location))
        }
    }, [])

    useEffect(() => {
        if (selected_location) {
            if (search_text === null) {
                // Initial load with empty search_text
                getData("")
                getDataWithNoPagination("")
            } else {
                if (timeOutId) {
                    clearTimeout(timeOutId)
                }
                timeOutId = setTimeout(() => {
                    getData(search_text)
                    getDataWithNoPagination(search_text)
                }, 500);
            }
        }
    }, [currentPage, search_text, selected_location, startDate, endDate])

    useEffect(() => {
        setCurrentPage(1)
        setLoading(true)
        setNoPaginationLoader(true)
    }, [selected_location, startDate, endDate])

    useEffect(() => {
        setCurrentPage(1)
    }, [search_text])

    useEffect(() => {
        if (selectedInvice && selectedInvice?.tip) {
            const selected_invoice_tip = (selectedInvice?.tip?.length > 0 ? selectedInvice?.tip?.reduce(function (total, obj) {
                return total + (obj.tip !== null && obj?.tip);
            }, 0) : 0)
            setSelectedInvoiceTip(selected_invoice_tip)
        }
    }, [selectedInvice]);


    return {
        navigate, InvoicePopup, selectedInvice, search_text, onChange, setInvoicePopup, loading,
        location_currency, exportPromotionDiscountReport, commissionAndReports, startDate, detailLoading,
        setStartDate, endDate, setEndDate, getInvoiceDetailData, total_tax, calculate_totals, reduxEmployees,
        selectedInvoiceTip, pages, currentPage, setCurrentPage, translation, selectedLanguage,
        commissionAndReportsWithNoPagination, handleExportClick, exportLoader, invoice_titles, setSelectedInvice, loadingPermission
    }

}
