import DateInput from 'Components/TenantApp/Elements/DateInput';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import FloatInput from 'Components/TenantApp/Elements/FloatInput';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Popup from 'Components/TenantApp/Elements/Popup';
import Textarea from 'Components/TenantApp/Elements/Textarea';
import React from 'react'
import useAddExpense from './helper';
import UploadFile from 'Components/TenantApp/Elements/UploadFile';
import moment from 'moment';

const AddExpensePopup = ({ open, isEdit, setIsEdit, editData, setEditData, onSuccessAPICall, close }) => {
    const { translation,
        selectedLanguage,
        PaymentMethodOptions,
        onChangeHandler,
        expenseData,
        errors, SubmitExpense,
        expenseCategory, loading, CardTypeOptions
    } = useAddExpense(close, onSuccessAPICall, isEdit, editData)

    return (
        <>
            <Popup
                open={open}
                close={() => {
                    setIsEdit(false)
                    setEditData({})
                    close && close()
                }}
                heading={
                    isEdit
                        ? selectedLanguage?.name == "English" ? "Update Expense" : translation[1750]?.value ? translation[1750]?.value : "Update Expense"
                        : selectedLanguage?.name == "English" ? "Add Expense" : translation[1750]?.value ? translation[1750]?.value : "Add Expense"
                }
            >
                <div className="flex flex-col gap-4">

                    <div className="grid lg:grid-cols-2 gap-5">
                        <Dropwdown
                            required={true}
                            name={'expense_category'}
                            value={expenseData?.expense_category}
                            error={errors?.expense_category}
                            onChange={onChangeHandler}
                            options={expenseCategory?.map(itm => {
                                return {
                                    label: itm?.name,
                                    value: itm?.id
                                }
                            })}
                            title={selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Category" : translation[889]?.value ? translation[889]?.value : "Select Category"}
                        />
                        <DateInput
                            name={'date'}
                            required={true}
                            value={expenseData?.date}
                            error={errors?.date}
                            onChange={onChangeHandler}
                            title={selectedLanguage?.name == "English" ? "Date" : translation[450]?.value ? translation[450]?.value : "Date"}
                            custom_padding={" "}
                            max={moment().format('YYYY-MM-DD')}
                        />
                        <FloatInput
                            name={'total_amount'}
                            value={expenseData?.total_amount}
                            error={errors?.total_amount}
                            onChange={onChangeHandler}
                            required={true}
                            title={selectedLanguage?.name == "English" ? "Amount" : translation[793]?.value ? translation[793]?.value : "Amount"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Amount" : translation[794]?.value ? translation[794]?.value : "Enter Amount"}
                        />
                        <Dropwdown
                            required={true}
                            title={selectedLanguage?.name == "English" ? "Payment Method" : translation[742]?.value ? translation[742]?.value : "Payment Method"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Payment Method" : translation[736]?.value ? translation[736]?.value : "Select Payment Method"}
                            options={PaymentMethodOptions}
                            name={'payment_method'}
                            value={expenseData?.payment_method}
                            error={errors?.payment_method}
                            onChange={onChangeHandler}
                        />
                        {expenseData?.payment_method == 'Card'
                            ? <>
                                <LoginInput
                                    name={'card_number'}
                                    onChange={onChangeHandler}
                                    value={expenseData?.card_number}
                                    error={errors?.card_number}
                                    title={selectedLanguage?.name == "English" ? "Card Number" : translation[518]?.value ? translation[518]?.value : "Card Number"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Card Number" : translation[519]?.value ? translation[519]?.value : "Enter Card Number"}
                                />
                                <LoginInput
                                    name={'card_holder_name'}
                                    onChange={onChangeHandler}
                                    value={expenseData?.card_holder_name}
                                    error={errors?.card_holder_name}
                                    title={selectedLanguage?.name == "English" ? "Card Holder Name" : translation[1759]?.value ? translation[1759]?.value : "Card Holder Name"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Card Holder Name" : translation[1760]?.value ? translation[1760]?.value : "Enter Card Holder Name"}
                                />
                                <Dropwdown
                                    name={'card_type'}
                                    onChange={onChangeHandler}
                                    value={expenseData?.card_type}
                                    error={errors?.card_type}
                                    title={selectedLanguage?.name == "English" ? "Card Type" : translation[1761]?.value ? translation[1761]?.value : "Card Type"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Card Type" : translation[1762]?.value ? translation[1762]?.value : "Select Card Type"}
                                    options={CardTypeOptions}
                                />
                                <LoginInput
                                    name={'month'}
                                    onChange={onChangeHandler}
                                    charactersMaxLength={2}
                                    type={'number'}
                                    value={expenseData?.month}
                                    error={errors?.month}
                                    title={selectedLanguage?.name == "English" ? 'Month' : translation[377]?.value ? translation[377]?.value : "Month"}
                                    placeholder={selectedLanguage?.name == "English" ? 'Month' : translation[377]?.value ? translation[377]?.value : "Month"}
                                />
                                <LoginInput
                                    type={'number'}
                                    name={'year'}
                                    charactersMaxLength={4}
                                    onChange={onChangeHandler}
                                    value={expenseData?.year}
                                    error={errors?.year}
                                    title={selectedLanguage?.name == "English" ? 'Year' : translation[376]?.value ? translation[376]?.value : "Year"}
                                    placeholder={selectedLanguage?.name == "English" ? 'Year' : translation[376]?.value ? translation[376]?.value : "Year"}
                                />
                                {/* <LoginInput
                                    name={'cvv'}
                                    onChange={onChangeHandler}
                                    value={expenseData?.cvv}
                                    error={errors?.cvv}
                                    required={false}
                                    title={selectedLanguage?.name == "English" ? "Security Code" : translation[1763]?.value ? translation[1763]?.value : "Security Code"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Security Code" : translation[1764]?.value ? translation[1764]?.value : "Enter Security Code"}
                                /> */}

                            </>
                            : expenseData?.payment_method == 'Cheque'
                                ? <LoginInput
                                    name={'cheque_number'}
                                    onChange={onChangeHandler}
                                    value={expenseData?.cheque_number}
                                    error={errors?.cheque_number}
                                    title={selectedLanguage?.name == "English" ? "Cheque No" : translation[1765]?.value ? translation[1765]?.value : "Cheque No"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Cheque No" : translation[1766]?.value ? translation[1766]?.value : "Enter Cheque No"}
                                />
                                : expenseData?.payment_method == 'Online' || expenseData?.payment_method == 'Visa' || expenseData?.payment_method == 'Bank Transfer'
                                    ? <LoginInput
                                        name={'transaction_number'}
                                        value={expenseData?.transaction_number}
                                        error={errors?.transaction_number}
                                        onChange={onChangeHandler}
                                        title={selectedLanguage?.name == "English" ? "Transaction No" : translation[1767]?.value ? translation[1767]?.value : "Transaction No"}
                                        placeholder={selectedLanguage?.name == "English" ? "Enter Transaction No" : translation[1768]?.value ? translation[1768]?.value : "Enter Transaction No"}
                                    />
                                    : ''
                        }
                        <Textarea
                            value={expenseData?.expense_note}
                            error={errors?.expense_note}
                            name={'expense_note'}
                            onChange={onChangeHandler}
                            parent='col-span-2'
                            required={true}
                            title={selectedLanguage?.name == "English" ? "Expense Note" : translation[1746]?.value ? translation[1746]?.value : "Expense Note"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Expense Note" : translation[1751]?.value ? translation[1751]?.value : "Enter Expense Note"}
                        />

                        <Textarea
                            value={expenseData?.payment_note}
                            name='payment_note'
                            error={errors?.payment_note}
                            onChange={onChangeHandler}
                            parent='col-span-2'
                            required={true}
                            title={selectedLanguage?.name == "English" ? "Payment Note" : translation[1752]?.value ? translation[1752]?.value : "Payment Note"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Payment Note" : translation[1753]?.value ? translation[1753]?.value : "Enter Payment Note"}
                        />
                    </div>
                    <UploadFile
                        accept={".PNG,.JPG,.JPEG,.pdf,.docx,.doc"}
                        onChange={onChangeHandler}
                        name="file"
                        id={'expense_file'}
                        mb_file_size={10}
                        file_type={expenseData?.file_type}
                        // edit={isEdit ? true : false}
                        value={expenseData?.file}
                        title={selectedLanguage?.name == "English" ? "Document" : translation[1776]?.value ? translation[1776]?.value : "Document"}
                        error={errors?.file}
                    />
                    <div>
                        <LoginBtn
                            className={'capitalize'}
                            text={selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                            onClick={SubmitExpense}
                            loading={loading}
                            disabled={loading}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default AddExpensePopup