import { EXPENSE_ACTIONS } from "Redux/TenantRedux/ActionTypes/expense"

export const getExpenseCategoriesNoPagination = (data) => {
    return {
        type: EXPENSE_ACTIONS.GET_EXPENSE_CATEGORIES_NO_PAGINATION,
        payload: data,
    }
}
export const getExpenseCategories = (data) => {
    return {
        type: EXPENSE_ACTIONS.GET_EXPENSE_CATEGORIES,
        payload: data,
    }
}
export const getExpenses = (data) => {
    return {
        type: EXPENSE_ACTIONS.GET_EXPENSE,
        payload: data,
    }
}
export const getExpensePayments = (data) => {
    return {
        type: EXPENSE_ACTIONS.GET_EXPENSE_PAYMENT,
        payload: data,
    }
}
export const getExpenseAnalyticsAction = (data) => {
    return {
        type: EXPENSE_ACTIONS.GET_EXPENSE_ANALYTICS,
        payload: data,
    }
}