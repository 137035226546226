import { CLIENT_DISCOUNT_PROMOTION } from "Redux/TenantRedux/ActionTypes/clientDiscountActionTypes"


export const getDiscountPromotion = (data) => {
    return {
        type: CLIENT_DISCOUNT_PROMOTION.GET_DISCOUNT_PROMOTION,
        payload: data
    }
}

export const getAvailOffers = (data) => {
    return {
        type: CLIENT_DISCOUNT_PROMOTION.GET_AVAIL_OFFERS,
        payload: data
    }
}

export const addDiscountPromotion = (data,type) => {
    return {
        type: type,
        payload: data
    }
}

export const updateDiscountPromotion = (data,type) => {
    return {
        type: type,
        payload: data
    }
}

export const deleteDiscountPromotion = (data,type) => {
    return {
        type: type,
        payload: data
    }
}