import { VOUCHER } from "../../ActionTypes/Vouchers/voucher";

const initialState = {
  voucher: [],
  voucher_updated: false,
  page_count: 0,
  allCount: 0,
};

const VoucherReducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {

    case VOUCHER.GET_VOUCHER:
      return {
        ...state,
        voucher: payload,
        voucher_updated: true,
      };

    case VOUCHER.GET_VOUCHER_COUNT:
      return {
        ...state,
        page_count: payload?.pages,
        allCount: payload?.count
      };

    default:
      return state;
  }
};

export default VoucherReducer;