import { GET_USER_DETAILS } from "../../ActionTypes/UserActionTypes";

import { GET_USER_PERMISSIONS } from "../../ActionTypes/UserActionTypes";

export const get_user_details = (data) => (dispatch) => {
  dispatch({
    type: GET_USER_DETAILS,
    payload: {
      data: data,
    },
  });
};

export const get_user_permissions = (data) => (dispatch) => {
  dispatch({
    type: GET_USER_PERMISSIONS,
    payload: {
      data: data,
    },
  });
};
