import React from 'react'

const AppointmentLogShimmers = () => {
    return (
        <>
            <div className="flex flex-col gap-3 my-3 animate-pulse">
                <div className="border rounded-lg">
                    <div className="px-5 py-3 bg-[#ECF3FF]">
                        <div className="grid grid-cols-4 gap-3">
                            <div className="flex flex-col gap-1 justify-center">
                                <div className="h-7 w-2/5 bg-gray-200 rounded-lg" />
                                <div className="h-7 w-3/5 bg-gray-200 rounded-lg" />
                            </div>
                            <div className="flex flex-col gap-1 justify-center">
                                <div className="h-7 w-3/5 bg-gray-200 rounded-lg" />
                                <div className="h-7 w-2/5 bg-gray-200 rounded-lg" />
                            </div>
                            <div className="flex flex-col gap-1 justify-center">
                                <div className="h-7 w-2/5 bg-gray-200 rounded-lg" />
                                <div className="h-7 w-4/5 bg-gray-200 rounded-lg" />
                            </div>
                            <div className="flex flex-col gap-1 justify-center">
                                <div className="h-10 w-4/5 bg-gray-200 rounded-lg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppointmentLogShimmers