import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import React from 'react'
import useDealTypeOneSetting from './helper'
import Svgs from 'Assets/svgs'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'
import PreloaderFull from 'Components/TenantApp/Elements/PreloaderFull'

const DealTypeOneSetting = (props) => {
    const { discountAmount, setSettingData, settingData, dealTypeSlug, setErrors,
        submitSettingClick, allOption, applicalbleCategoryIds
    } = props
    const {
        handleSettingChange, screenLoader, handleChangeForDropdown, dealOptionForTypeOne, removeSelectedItem,
        optionsForDealTypeOne
    } = useDealTypeOneSetting({
        setSettingData, settingData, dealTypeSlug, setErrors
    })

    return screenLoader ? <PreloaderFull /> : (
        <>
            <div className="grid md:grid-cols-2 gap-8">
                <FloatInput
                    required={true}
                    title={'Discount Amount'}
                    placeholder={'Enter Discount Amount'}
                    name="discountAmount"
                    value={discountAmount}
                    onChange={handleSettingChange}
                    error={submitSettingClick && !discountAmount}
                />
                <div>
                    <div className="space-y-5">
                        <Dropwdown
                            required={true}
                            name="applicalbleCategoryIds"
                            title={"Applicable For"}
                            placeholder={"Applicable For"}
                            onChange={(e) => { handleChangeForDropdown(e, 'applicalbleCategoryIds') }}
                            disabled={dealOptionForTypeOne?.length === 0}
                            options={[allOption, ...dealOptionForTypeOne]}
                            error={submitSettingClick && (applicalbleCategoryIds?.length == 0 || applicalbleCategoryIds == undefined)}
                        />
                        {applicalbleCategoryIds?.length > 0 && (
                            <div className="flex items-center flex-wrap gap-2">
                                {applicalbleCategoryIds &&
                                    applicalbleCategoryIds?.map((item, i) => {
                                        const filtered = optionsForDealTypeOne?.find((itm) => itm?.label == item);
                                        return (
                                            <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                                <p className="text-primary text-sm font-bold">{`${filtered?.label}`}</p>
                                                <span
                                                    onClick={() =>
                                                        removeSelectedItem(item, "applicalbleCategoryIds")
                                                    }
                                                >
                                                    <Svgs.CirlceClose fill="#5679FF" />
                                                </span>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DealTypeOneSetting