import React from 'react'
import TopSection from './Elements/TopSection'
import Svgs from 'Assets/svgs'
import useHelpCenter from './helper'
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm'
import { useEffect } from 'react'

const Help = () => {

    const { topics, loader, sliceString, getHelpCenterData, helpData } = useHelpCenter()

    useEffect(() => {
        const path = window?.location?.pathname.split('/').join("")
        if (path == 'help') {
            getHelpCenterData()
        }
    }, [])

    return (
        <div className=''>
            <TopSection />
            <div className=''>
                <div className='container mx-auto w-[85%] my-[3rem]'>
                    {loader ?
                        <div className="my-3 mx-auto w-fit"><PreloaderSm /></div>
                        :
                        <div className='flex flex-col gap-8'>
                            <div className='flex flex-col gap-4'>
                                <h1 className='font-semibold text-xl'>Recent Topics</h1>
                                <div className='grid grid-cols-2 sm:grid-cols-3 md:flex items-center flex-wrap gap-4'>
                                    {helpData.map(item => {
                                        return (
                                            item?.is_recent == true
                                            &&
                                            <div
                                                onClick={() => { window.open(`/help-details?type=${item?.content?.toLowerCase()}&&id=${item?.id}`, "_blank") }}
                                                className='cursor-pointer w-full h-[10rem] md:w-[10rem] p-3 border-[1.4px] flex items-center justify-center flex-col gap-4 hover:border-[#5679FF] transition-all'
                                            >
                                                {item?.image &&
                                                    <div >
                                                        <img src={item?.image} alt={item?.content} srcset={item?.image} className="w-20" />
                                                    </div>
                                                }
                                                <p className='text-sm mt-4'>
                                                    {item?.content}
                                                </p>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            <div className='flex flex-col gap-4'>
                                <h1 className='font-semibold text-xl'>Recommended for you</h1>
                                <div className='grid md:grid-cols-3 gap-5'>
                                    {helpData.map(item => {
                                        return (
                                            item?.is_recent == false &&
                                            <div
                                                onClick={() => { window.open(`/help-details?type=${item?.content?.toLowerCase()}&&id=${item?.id}`, "_blank") }}
                                                className='py-[1.2rem] border-b pr-8 transition-all hover:pr-3 hover:bg-gray-50 cursor-pointer'
                                            >
                                                <div className='px-3 flex items-center justify-between gap-1'>
                                                    <div className='flex flex-col gap-3'>
                                                        <h1 className='text-[#101928] text-lg font-semibold'>{item?.content}</h1>
                                                        <p className='text-[#666666] text-sm'>{sliceString(item?.description)} ...</p>
                                                    </div>
                                                    <div className=''>
                                                        <Svgs.Right />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Help