import { addExpenseCategory, updateExpenseCategory } from 'Adapters/Api/expense';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Popup from 'Components/TenantApp/Elements/Popup';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import useValidations from 'Hooks/useValidations';
import React, { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux';

const AddExpenseCategoryPopup = ({ open, close, selectedLanguage, translation, onSuccessAPICall, setIsEdit, setEditData, isEdit,editData }) => {
    const { setErrors, validation, errors } = useValidations();

    const dispatch = useDispatch();

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");

    const [loading, setLoading] = useState(false)
    const [category, setCategory] = useState('')

    useEffect(() => {
        if (isEdit && editData?.id) {
            setCategory(editData?.name)
        }
    }, [editData, isEdit])

    const handleAddCategory = () => {
        const success = () => {
            onSuccessAPICall()
            setCategory('')
            setEditData && setEditData()
            setIsEdit && setIsEdit(false)
            close && close(false)
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        setErrors(validation({ category }));
        let result = validation({ category });
        if (!result?.category) {
            setLoading(true)
            isEdit
                ? dispatch(updateExpenseCategory(access_token, { name: category, business: businessData?.id,id:editData?.id }, success, fail))
                : dispatch(addExpenseCategory(access_token, { name: category, business: businessData?.id }, success, fail))
        }
    }
    return (
        <>
            <Popup
                open={open}
                close={() => {
                    setIsEdit(false)
                    setEditData()
                    close && close()
                }}
                heading={
                    isEdit
                        ? selectedLanguage?.name == "English" ? "Update Category" : translation[896]?.value ? translation[896]?.value : "Update Category"
                        : selectedLanguage?.name == "English" ? "Add Category" : translation[1714]?.value ? translation[1714]?.value : "Add Category"
                }
            >
                <div className="flex flex-col gap-4">
                    <LoginInput
                        required={true}
                        name={'title'}
                        value={category}
                        title={selectedLanguage?.name == "English" ? "Category Name" : translation[892]?.value ? translation[892]?.value : "Category Name"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter category name" : translation[893]?.value ? translation[893]?.value : "Enter category name"}
                        onChange={(e) => {
                            setErrors((prev) => ({ ...prev, category: "" }))
                            setCategory(e?.target?.value)
                        }}
                        error={errors?.category}
                    />
                    <LoginBtn
                        className={'capitalize'}
                        text={selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                        onClick={handleAddCategory}
                        loading={loading}
                        disabled={loading}
                    />
                </div>
            </Popup>
        </>
    )
}

export default AddExpenseCategoryPopup