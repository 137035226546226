import React from 'react'

const VerificationMethodCard = (props) => {
    return (
        <div onClick={props.onClick} className={`shadow rounded-xl p-3 flex justify-between items-center cursor-pointer ${props.checked ? 'bg-[#EEF5FB]' : 'focus:bg-[#EEF5FB]'}`}>
            <div className='flex items-center gap-3'>
                <div className='rounded-xl bg-primary p-5 h-[4.375rem] w-[4.375rem] flex items-center justify-center'>
                    {props.icon}
                </div>
                <div className='h-fit flex flex-col gap-1'>
                    <h2 className='font-semibold text-black'>{props.title}</h2>
                    <p className='text-[#7B7B7B] text-xs'>{props.desc}</p>
                </div>
            </div>
            <div className="rounded-checkbox mr-3">
                <input type="checkbox" id={`checkbox-1`} checked={props.checked} />
                <label htmlFor={`checkbox-1`} style={{ transform: 'scale(0.5)' }}></label>
            </div>
        </div>
    )
}
VerificationMethodCard.defaultProps = {
    checked: false
}
export default VerificationMethodCard