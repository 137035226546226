import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const Counter = ({ key, data, value }) => {
    const dispatch = useDispatch()
    const [Count, setCount] = useState(value ? value : 1);

    const handleProductIncrement = () => {
        if (Count < data?.current_stock) {
            setCount(parseInt(Count) + 1);
            dispatch({
                type: "ITEM_QUANTITY_INCREMENT",
                payload: {
                    id: data?.id,
                    quantity: Count
                },
            });
        }
    }

    const handleProductDecrement = () => {
        if (Count > 1) {
            setCount(Count > 0 ? parseInt(Count) - 1 : 0);
            dispatch({
                type: "ITEM_QUANTITY_DECREMENT",
                payload: {
                    id: data?.id,
                    quantity: Count
                },
            });
        }
    }

    const addItemFunction = () => {
        setCount(parseInt(Count) + 1);
        dispatch({
            type: "ITEM_QUANTITY_INCREMENT",
            payload: {
                id: data?.id,
                quantity: Count
            },
        });
    }

    const handleIncrement = () => {
        if (data?.selection_type == "VOUCHER") {
            if (+data?.voucher_count + +Count < data?.sales) {
                addItemFunction()
            } else {
                toast.error("The number of sales are completed for this voucher", { toastId: "toast" });
            }
        }
        else {
            addItemFunction()
        }
    }

    const handleDecrement = () => {
        if (Count > 1) {
            setCount(Count > 0 ? parseInt(Count) - 1 : 0);
            dispatch({
                type: "ITEM_QUANTITY_DECREMENT",
                payload: {
                    id: data?.id,
                    quantity: Count
                },
            });
        }
    }

    return (
        <div key={key} class="flex items-center border border-[#CDCDCD] justify-between rounded-md px-2 py-2.5">
            <span
                class="cursor-pointer"
                onClick={() => {
                    if (data?.selection_type === "PRODUCT") {
                        handleProductDecrement()
                    }
                    else {
                        handleDecrement()
                    }
                }}>
                <svg width="9" height="2" viewBox="0 0 9 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M0 1.92H8.14264V0H0V1.92Z" fill="black"></path></svg>
            </span>
            <div class="w-[4ch] outline-none border-none text-center text-sm">{Count}</div>
            <span
                class="cursor-pointer"
                onClick={() => {
                    if (data?.selection_type === "PRODUCT") {
                        handleProductIncrement()
                    }
                    else {
                        handleIncrement()
                    }
                }}
            >
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.4" d="M0 4.928H2.70884V7.792H4.80496V4.928H7.49767V2.896H4.80496V0H2.70884V2.896H0V4.928Z" fill="black"></path></svg>
            </span>
        </div>
    )
}

export default Counter