import React from 'react'
import ClientCardShimmer from './ClientCardShimmer'

const ClientSectionShimmer = () => {
    return (
        <div className='lg:col-span-2 flex flex-col gap-6 lg:mt-0 mt-4'>

            <div className='shadow-appoint rounded-md'>
                <div className='p-4'>
                    <div className='flex items-center justify-between gap-3 w-full' >
                        <div className="h-8 w-1/3 my-3 bg-gray-200 rounded-lg" />
                        <div className="h-8 w-1/3 my-3 bg-gray-200 rounded-lg" />
                    </div>
                    <div className='mt-4'>
                        <div className="h-12 my-3 bg-gray-200 rounded-lg" />
                    </div>
                </div>
                {[0, 1, 2, 3, 4]?.map(itm =>
                    <ClientCardShimmer />
                )}
            </div>
        </div>
    )
}

export default ClientSectionShimmer