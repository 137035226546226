import React from "react";
import { LogoSvg } from "../../../Assets/svgs/Logo/LogoSvg";
import SocialMediaPlatform from "../../../Components/NstyleApp/SocialMediaPlatforms";
import Sweepings from "../../../Components/NstyleApp/Sweepings";
import { useNavigate } from "react-router-dom";
import UserVerificationOTP from "../../../Components/NstyleApp/UserOTPVerification";
import { toast } from "react-toastify";

const ForgetScreen = (props) => {
  const navigate = useNavigate();

  return (
    <div className="min-h-[100vh] grid grid-cols-1 lg:grid-cols-2">
      <div className="flex flex-col lg:pt-[3rem] lg:px-[4rem] px-8 py-5">
        <div className="flex items-center justify-between">
          <LogoSvg />
          <p className="text-[#7B7B7B] text-sm lg:text-left text-right">
            Don't have an account?&nbsp;
            <span
              className="text-primary cursor-pointer font-semibold transition-all hover:underline"
              onClick={() => {
                navigate("/auth/account-type");
                // navigate("/auth/signup");
              }}
            >
              Signup Now
            </span>
          </p>
        </div>
        <UserVerificationOTP
          onVerify={() => {
            toast.success("Account Verfied Successfully", { toastId: "toast" });
            navigate('/auth/login')
          }}
        />
      </div>
      <div className="hidden lg:block" style={{
        backgroundImage: 'linear-gradient(to bottom left, rgba(166,77,255,1), rgba(54,67,120,1), rgba(54,67,120,1), rgba(87,120,255,1))'
      }}>
        <div className="px-8 py-5 lg:px-[4rem] lg:pt-[3rem] flex flex-col h-full">
          <div className="flex justify-end text-white"><span className="cursor-pointer" onClick={() => {
                navigate('/help')
            }}>Help?</span></div>
          <Sweepings />
        </div>
      </div>
    </div>
  );
};

export default ForgetScreen;
