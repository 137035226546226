import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Popup from 'Components/TenantApp/Elements/Popup';
import React, { useEffect, useState } from 'react'
import NotFound from 'Components/Utility/NotFound';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { useDispatch } from 'react-redux';
import { getDealsItemTypeOptionsWithPagination } from 'Adapters/Api/CRM/Deals';
import InfiniteScroll from 'react-infinite-scroll-component';
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm';
import ClientSectionShimmer from 'Components/Utility/Shimmers/ClientSectionShimmer';

let timeOutId = undefined
const AddPopup = (props) => {
    const { completeDataLength, firstStepData, data, heading, open, close, placeholder, onSubmitHandler, apiType } = props

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage('access_token')
    const dispatch = useDispatch()

    const [selectedDataArray, setSelectedDataArray] = useState(data)
    const [searchText, setSearchText] = useState("")
    const [selectedLength, setSelectedLength] = useState(0)
    const [arrayData, setArrayData] = useState([])
    const [searchArrayData, setSearchArrayData] = useState([])
    const [page, setPage] = useState(1)
    const [paginationData, setPaginationData] = useState({})
    const [secondLoader, setSecondLoader] = useState(false)
    const [loader, setLoader] = useState(true)
    const [isSelectedAll, setIsSelectAll] = useState(false)
    const [completeList, setCompleteList] = useState([])

    useEffect(() => {
        // filtering data for length
        const filteredData = arrayData?.length > 0 ? arrayData?.filter(itm => selectedDataArray?.includes(itm?.id)) : []
        setSelectedLength(filteredData?.length)
    }, [arrayData, selectedDataArray])

    useEffect(() => {
        // setting data for inside state
        setSelectedDataArray(data)
    }, [data])

    // useEffect(() => {
    //     // removing duplicate
    //     if (searchText) {
    //         if (arrayData?.length > 0) {
    //             const removeduplicate = Array.from(
    //                 new Set(arrayData?.map(client => client.id)))?.map(id => {
    //                     return arrayData?.find(client => client.id === id);
    //                 })
    //             setArrayData(removeduplicate)
    //         }
    //     }
    // }, [arrayData])


    useEffect(() => {
        let payload = {
            page: page
        }

        if (searchText === null) {
            // Initial load with empty search_text
            getItemsFromTypeForDeal(apiType, payload)
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            payload = {
                ...payload,
                search_text: searchText
            }
            timeOutId = setTimeout(() => {
                getItemsFromTypeForDeal(apiType, payload)
            }, 700);
        }
    }, [searchText, page, apiType])

    // useEffect(() => {
    //     if (completeDataLength == arrayData?.length) {
    //         setIsSelectAll(true)
    //     }
    // }, [completeDataLength, arrayData])

    useEffect(() => {
        setPage(1)
    }, [searchText])

    // get data
    const getItemsFromTypeForDeal = (type, payload) => {
        if (access_token) {
            let success = (result) => {
                setSecondLoader(false)
                if (isSelectedAll || payload.isSelectedAll) {
                    // if (searchText) {
                    //     setSearchArrayData([...result?.data?.response?.data?.list])
                    //     setArrayData([...result?.data?.response?.data?.list])
                    //     const mapingArr = result?.data?.response?.data?.list?.map(itm => itm?.id)
                    //     setSelectedDataArray(mapingArr)
                    //     setIsSelectAll(false)
                    // } else {
                    const mapingArr = result?.data?.response?.data?.list?.map(itm => itm?.id)
                    setSelectedDataArray(mapingArr)
                    setCompleteList([...result?.data?.response?.data?.list])
                    setArrayData([...result?.data?.response?.data?.list])
                    // }
                } else {
                    if (searchText) {
                        setSearchArrayData([...result?.data?.response?.data?.list])
                        setArrayData([...result?.data?.response?.data?.list])
                    } else {
                        if (completeList?.length == 0) {
                            setArrayData([...arrayData, ...result?.data?.response?.data?.list])
                        } else {
                            setArrayData(completeList)
                        }
                    }
                }
                setPaginationData({
                    currentPage: result?.data?.response?.data?.page,
                    pages: result?.data?.response?.data?.totalPageCount
                })
                setLoader(false)
            }
            let fail = () => {
                setSecondLoader(false)
                setLoader(false)
            }
            if (isSelectedAll || payload.isSelectedAll) {
                delete payload.page
                delete payload.search_text
            }
            setLoader(true)
            dispatch(getDealsItemTypeOptionsWithPagination(type, payload, access_token, success, fail))
        }
    }

    // handle on change function
    const handleChange = (e, id, type) => {
        if (type != "all") {
            if (!e.target.checked) {
                const filtered = selectedDataArray?.filter(itm => itm != id)
                setSelectedDataArray(filtered)
            } else {
                const exist = selectedDataArray || []
                setSelectedDataArray([...exist, id])
            }
        } else {
            if (!e.target.checked) {
                setIsSelectAll(false)
                setSelectedDataArray([])
            } else {
                setIsSelectAll(true)
                if (!searchText) {
                    let payload = { isSelectedAll: true }
                    getItemsFromTypeForDeal(apiType, payload)
                }
            }
        }
    }

    return (
        <>
            {open && (
                <Popup
                    size={"lg"}
                    heading={heading}
                    open={open}
                    close={close}
                >
                    {loader && !secondLoader ?
                        <ClientSectionShimmer />
                        :
                        <div className="flex flex-col relative gap-4">
                            <>
                                <LoginInput
                                    title={''}
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value)
                                    }}

                                    placeholder={placeholder}
                                />
                                <div className="flex items-center gap-3 justify-between">
                                    <CheckBox
                                        label={"Select All"}
                                        onChange={(e) => {
                                            setSearchText("")
                                            handleChange(e, "", 'all')
                                        }}
                                        disabled={arrayData?.length == 0}
                                        checked={arrayData?.length == selectedDataArray?.length && isSelectedAll}
                                    />
                                    <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                        <p className="text-primary">
                                            {selectedLength} {' '}
                                            Selected
                                        </p>
                                    </div>
                                </div>
                                <hr className="border" />
                                <div className='max-h-[20rem] overflow-y-auto' id="scrollableDiv">
                                    <InfiniteScroll
                                        dataLength={arrayData?.length ? arrayData?.length : 10} //This is important field to render the next data
                                        next={() => {
                                            paginationData?.currentPage < paginationData?.pages ? setSecondLoader(true) : setSecondLoader(false)
                                            setPage(+paginationData?.currentPage + 1)
                                        }}
                                        hasMore={paginationData && paginationData?.currentPage < paginationData?.pages}
                                        scrollableTarget="scrollableDiv"
                                        loader={
                                            secondLoader ?
                                                <div className="h-[4rem] flex items-center justify-center">
                                                    <PreloaderSm />
                                                </div>
                                                : ""
                                        }
                                    >
                                        <div>
                                            {arrayData?.length > 0 ?
                                                arrayData?.map((item, index) => {
                                                    return (
                                                        <CheckBox
                                                            parentClass="mb-7"
                                                            mainLabelClass={"!w-full"}
                                                            label={item?.name}
                                                            name={'employee'}
                                                            value={item?.id}
                                                            id={item?.id}
                                                            onChange={(e) => { handleChange(e, item?.id) }}
                                                            checked={selectedDataArray?.find(itm => item?.id == itm) ? true : false}
                                                        />
                                                    )
                                                }) : <NotFound />
                                            }
                                        </div>
                                    </InfiniteScroll>
                                </div>
                                {/* <div className="flex flex-col items-start gap-4 justify-start min-h-[17rem] max-h-[17rem] overflow-y-auto">
                                <div className='max-h-[30rem] overflow-y-auto w-full' id="scrollableDiv">
                                    {arrayData?.length > 0
                                        ? arrayData?.map((item, index) => {
                                            return (
                                                <CheckBox
                                                    mainLabelClass={"!w-full"}
                                                    label={item?.name}
                                                    name={'employee'}
                                                    value={item?.id}
                                                    id={item?.id}
                                                    onChange={(e) => { handleChange(e, item?.id) }}
                                                    checked={selectedDataArray?.find(itm => item?.id == itm) ? true : false}
                                                />
                                            )
                                        })
                                        : <NotFound />
                                    }
                                </div>
                            </div> */}
                                <hr className="border" />
                                <div className='flex justify-end items-end'>
                                    <LoginBtn
                                        disabled={selectedDataArray?.length == 0}
                                        onClick={(e) => {
                                            onSubmitHandler(e, selectedDataArray)
                                        }}
                                        text={"Save"}
                                    />
                                </div>
                            </>
                        </div>
                    }
                </Popup>
            )}
        </>
    )
}

export default AddPopup