export const THEME_COLORS = [
    {
        label: "System Default",
        primary: "#5679ff",
        secondary: "#101928"
    },
    {
        label: "Royal Blue Peach",
        primary: "#2F3C7E",
        secondary: "#EEA47FFF"
    },
    // {
    //     label: "Coral Yellow",
    //     primary: "#F96167",
    //     secondary: "#FCE77D"
    // },
    // {
    //     label: "Charcoal Yellow",
    //     primary: "#101820FF",
    //     secondary: "#FEE715FF"
    // },
    // {
    //     label: "Lime Green Electric Blue",
    //     primary: "#CCF381",
    //     secondary: "#4831D4"
    // },
    // {
    //     label: "Lavender Teal",
    //     primary: "#E2D1F9",
    //     secondary: "#317773"
    // },
    // {
    //     label: "Cherry Red Off-White",
    //     primary: "#990011FF",
    //     secondary: "#FCF6F5FF"
    // },
    {
        label: "Sage Forest Green",
        primary: "#CBD18F",
        secondary: "#3A6B35"
    },
]

export const THEME_MENUS = (translation, selectedLanguage) => {
    return [
        {
            id: 1,
            name: "Horizontal View"
            // name: selectedLanguage?.name == "English" ? "Horizontal View" : translation[760]?.value ? translation[760]?.value : "Horizontal View"
            ,
        },
        {
            id: 2,
            name: "Vertical View"
            // name: selectedLanguage?.name == "English" ? "Vertical View" : translation[761]?.value ? translation[761]?.value : "Vertical View"
            ,
        },
    ]
}