import React from 'react'

const ClientCardShimmer = () => {
    return (
        <div className='p-4 flex items-center gap-4 border-b'>
            <div>
                <div className='h-[3.5rem] w-[3.5rem] rounded-full bg-gray-200'>
                </div>
            </div>
            <div className='w-full flex flex-col gap-2'>
                <div className="h-6 w-2/3 bg-gray-200 rounded-lg" />
                    <div className="h-6 w-2/3 bg-gray-200 rounded-lg" />
            </div>
        </div>
    )
}

export default ClientCardShimmer