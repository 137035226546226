import React, { useState } from 'react'
import BusinessInfo from './businessInfo/index';
import BusinessPhotos from './businessPhotos';
import useAdminBooking from './helper';
import LocationInfo from './locationInfo';
import OpeningHours from './openingHours';

const AdminBooking = () => {
    useAdminBooking()
    const [StepCount, setStepCount] = useState(1);
    let data = {
        1: <BusinessInfo count={`${StepCount}/4`} StepCount={StepCount} setStepCount={setStepCount} />,
        2: <LocationInfo count={`${StepCount}/4`} StepCount={StepCount} setStepCount={setStepCount} />,
        3: <OpeningHours count={`${StepCount}/4`} StepCount={StepCount} setStepCount={setStepCount} />,
        4: <BusinessPhotos count={`${StepCount}/4`} StepCount={StepCount} setStepCount={setStepCount} />,
    }
    return (
        data[StepCount]
    )
}

export default AdminBooking