import Svgs from 'Assets/svgs';
import Header from 'Components/NstyleApp/Wrappers/Header';
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm';
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import useHelpCenter from '../helper';

const Outlet = () => {
    const { Type, topicDetail, loader_detailed, Active, setActive, redirectToHelp } = useHelpCenter()

    const data = [
        {
            'title': 'Sales Techniques',
            'description': <>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate fugiat quasi doloremque fuga consequuntur ducimus iure distinctio delectus quaerat perferendis commodi, mollitia beatae, excepturi aspernatur voluptates iste amet obcaecati? Numquam.</p>
            </>
        },
        {
            'title': 'Object Handling',
            'description': <>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate fugiat quasi doloremque fuga consequuntur ducimus iure distinctio delectus quaerat perferendis commodi, mollitia beatae, excepturi aspernatur voluptates iste amet obcaecati? Numquam.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate fugiat quasi doloremque fuga consequuntur ducimus iure distinctio delectus quaerat perferendis commodi, mollitia beatae, excepturi aspernatur voluptates iste amet obcaecati? Numquam.</p>
            </>
        },
        {
            'title': 'Call to action',
            'description': <>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate fugiat quasi doloremque fuga consequuntur ducimus iure distinctio delectus quaerat perferendis commodi, mollitia beatae, excepturi aspernatur voluptates iste amet obcaecati? Numquam.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate fugiat quasi doloremque fuga consequuntur ducimus iure distinctio delectus quaerat perferendis commodi, mollitia beatae, excepturi aspernatur voluptates iste amet obcaecati? Numquam.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate fugiat quasi doloremque fuga consequuntur ducimus iure distinctio delectus quaerat perferendis commodi, mollitia beatae, excepturi aspernatur voluptates iste amet obcaecati? Numquam.</p>
            </>
        },
        {
            'title': 'Sales Techniques',
            'description': <>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate fugiat quasi doloremque fuga consequuntur ducimus iure distinctio delectus quaerat perferendis commodi, mollitia beatae, excepturi aspernatur voluptates iste amet obcaecati? Numquam.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate fugiat quasi doloremque fuga consequuntur ducimus iure distinctio delectus quaerat perferendis commodi, mollitia beatae, excepturi aspernatur voluptates iste amet obcaecati? Numquam.</p>
            </>
        },
        {
            'title': 'Call to action',
            'description': <>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate fugiat quasi doloremque fuga consequuntur ducimus iure distinctio delectus quaerat perferendis commodi, mollitia beatae, excepturi aspernatur voluptates iste amet obcaecati? Numquam.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate fugiat quasi doloremque fuga consequuntur ducimus iure distinctio delectus quaerat perferendis commodi, mollitia beatae, excepturi aspernatur voluptates iste amet obcaecati? Numquam.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate fugiat quasi doloremque fuga consequuntur ducimus iure distinctio delectus quaerat perferendis commodi, mollitia beatae, excepturi aspernatur voluptates iste amet obcaecati? Numquam.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate fugiat quasi doloremque fuga consequuntur ducimus iure distinctio delectus quaerat perferendis commodi, mollitia beatae, excepturi aspernatur voluptates iste amet obcaecati? Numquam.</p>
            </>
        }
    ]

    return (
        <div className='min-h-screen h-screen flex flex-col'>
            <div className=''>
                <div className='bg-[#EFF5FD]'>
                    <Header noshadow />
                </div>
                <div className='border-b'>
                    <div className='container mx-auto py-4 w-[80%]'>
                        <div className='flex items-center gap-3 text-sm'>
                            <p className='text-[#808080] cursor-pointer' onClick={redirectToHelp}>Help</p>
                            <svg width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M-0.00168786 8.4718C-0.00168785 8.2798 0.0713123 8.08681 0.219312 7.94081L3.17231 4.99981L0.219313 2.06081C-0.0746874 1.76881 -0.0746877 1.29381 0.217312 0.999805C0.509312 0.706805 0.983313 0.704805 1.27731 0.997805L4.76431 4.46881C5.04731 4.74981 5.04731 5.24981 4.76431 5.53181L1.27731 9.00281C0.983312 9.29481 0.509312 9.2938 0.217312 9.0008C0.071312 8.8548 -0.00168787 8.6628 -0.00168786 8.4718" fill="#1E1E1E" />
                            </svg>
                            <p className='font-semibold capitalize'>{Type}</p>
                        </div>
                    </div>
                </div>
            </div>
            {loader_detailed ?
                <div className="my-5 mx-auto w-fit"><PreloaderSm /></div>
                :
                <div className='container mx-auto w-[80%] flex-1'>
                    <div className='grid lg:grid-cols-4 h-full'>
                        <div className='border-r'>
                            <div className='p-3 flex flex-col gap-3 !pr-0'>
                                <div className='mb-4 cursor-pointer' onClick={redirectToHelp}>
                                    <svg width="1.1875rem" height="0.9375rem" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.4375 7.49989C18.4375 7.07953 18.1241 6.73213 17.7174 6.67715L17.6043 6.66957L2.95781 6.67L8.24934 1.41871C8.57541 1.09515 8.57655 0.569418 8.25187 0.24446C7.95671 -0.0509558 7.4939 -0.0787477 7.1672 0.161737L7.07358 0.241932L0.352579 6.91106L0.336107 6.92855C0.31904 6.94648 0.302769 6.96516 0.287348 6.98455L0.352579 6.91106C0.320198 6.94319 0.291021 6.97731 0.265053 7.01303C0.249353 7.03547 0.234119 7.05877 0.220064 7.08284C0.188313 7.1364 0.163708 7.19245 0.145557 7.25008C0.139442 7.27039 0.133759 7.29113 0.128878 7.31219C0.124907 7.32826 0.121643 7.34459 0.118874 7.36099C0.116106 7.37876 0.113691 7.39654 0.111851 7.4145C0.109766 7.43251 0.108486 7.45093 0.107819 7.46936C0.107681 7.47958 0.107498 7.48971 0.107498 7.49989L0.107746 7.52746C0.108435 7.54791 0.109879 7.56834 0.112074 7.5887L0.107498 7.49989C0.107498 7.5473 0.111486 7.59379 0.119146 7.63903C0.122568 7.65964 0.126904 7.68035 0.132038 7.70089C0.136154 7.71722 0.140678 7.73325 0.145668 7.74907C0.151556 7.76787 0.158287 7.78681 0.165728 7.80554C0.173452 7.82488 0.181808 7.84377 0.190834 7.86229C0.197672 7.87645 0.205326 7.89106 0.213446 7.90548C0.228762 7.93254 0.244974 7.95792 0.26248 7.98232C0.265232 7.98614 0.26832 7.99035 0.27145 7.99454C0.299238 8.0316 0.328041 8.0643 0.359215 8.09457L7.07353 14.758C7.39958 15.0816 7.92712 15.0805 8.25182 14.7556C8.54701 14.4602 8.57294 13.9989 8.33025 13.6743L8.24939 13.5813L2.95883 8.331L17.6043 8.33021C18.0645 8.33021 18.4375 7.95846 18.4375 7.49989Z" fill="#101928" />
                                    </svg>
                                </div>
                                {
                                    topicDetail.map((item, i) => {
                                        return <div onClick={() => {
                                            setActive(i)
                                        }} className={`${i == Active ? "bg-[#EFF5FD] text-[#5679FF]" : "hover:bg-gray-50 text-gray-900"} cursor-pointer py-3 px-6 font-semibold`}>
                                            {item?.content}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className='col-span-3'>
                            <div className='px-[3rem] py-[2rem]'>
                                <h1 className='font-semibold text-2xl mb-[1rem]'>{topicDetail[Active]?.content}</h1>
                                <div className='text-sm'>
                                    {
                                        topicDetail[Active]?.description
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Outlet