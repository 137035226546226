import { STORE_TARGET } from "Redux/TenantRedux/ActionTypes/storeTargetTypes";

const initialState = {
    store_targets: [],
    all_store_targets: [],
    pages: 0,
    allCount: 0,
    store_targets_report_pages: 0,
    store_targets_report_allCount: 0,
    all_store_targets_report: [],
    store_target_updated: false,
    store_targets_report: [],
    store_target_reports_updated: false
};


const StoreTargetReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {

        case STORE_TARGET.GET_STORE_TARGET:
            return {
                ...state,
                store_targets: payload?.response?.storetarget,
                store_target_updated: true,
                pages: payload?.pages,
                allCount: payload?.count
            };

        case STORE_TARGET.GET_STORE_TARGET_WITHOUT_PAGINATION:
            return {
                ...state,
                all_store_targets: payload?.response?.storetarget,
                store_target_updated: true
            };

        case STORE_TARGET.GET_STORE_TARGET_REPORT:
            return {
                ...state,
                store_targets_report: payload?.response?.address,
                store_targets_report_pages: payload?.pages,
                store_targets_report_allCount: payload?.count,
                store_target_reports_updated: true
            };

        case STORE_TARGET.GET_STORE_TARGET_REPORT_WITH_NO_PAGINATION:
            return {
                ...state,
                all_store_targets_report: payload?.response?.address,
                store_target_reports_updated: true
            };

        case STORE_TARGET.CREATE_STORE_TARGET:
            return {
                ...state,
                store_targets: [payload, ...state.store_targets]
            };


        case STORE_TARGET.DELETE_STORE_TARGET:
            let result = state?.store_targets?.filter((item) => item.id !== payload);
            return {
                ...state,
                store_targets: [...result],
            };


        case STORE_TARGET.UPDATE_STORE_TARGET:

            let updateList = state?.store_targets?.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })

            return {
                ...state,
                store_targets: [...updateList],
            };

        default:
            return state;
    }
};
export default StoreTargetReducer;
