import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getLocations } from '../../../../../Adapters/Api'
import { createorderstock, deleteOrderstock, getorderStock, getsProducts, updateorderStock } from '../../../../../Adapters/Api/Products'
import { getVendorsList } from '../../../../../Adapters/Api/vendor'
import Svgs from '../../../../../Assets/svgs'
import useSetDataInLocalStorage from '../../../../../Hooks/useSetDataInLocalStorage'
import { addLocations } from '../../../../../Redux/TenantRedux/Actions/locationActions'
import { getProducts, get_orderStocks } from '../../../../../Redux/TenantRedux/Actions/product'
import CheckBox from '../../../Elements/Checkbox/CheckBox'
import CircleIcon from '../../../Elements/CircleIcon'
import Dropwdown from '../../../Elements/Dropwdown'
import LoginBtn from '../../../Elements/LoginBtn'
import LoginInput from '../../../Elements/LoginInput'
import Popup from '../../../Elements/Popup'
import StatusBtn from '../../../Elements/StatusBtn'
import VerificationMethodCard from '../../../Elements/VerificationMethodCard'
import AddCategoryBtn from './AddHeaderBtns/AddCategoryBtn'
import AddProductBtn from './AddHeaderBtns/AddProductBtn'
import NotFound from './../../../../Utility/NotFound';
import PreloaderFull from '../../../Elements/PreloaderFull'
import { apiErrorMessage } from 'Constants/Data/Errors'

const Purchase = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [ShowDetails, setShowDetails] = useState(0)
    const [Filters, setFilters] = useState(false)
    const [OpenStock, SetOpenStock] = useState(false)
    const [stockData, setstockData] = useState({})
    const [stockError, setstockError] = useState({})
    const [search_text, setSearchText] = useState("")
    const vendorsRedux = useSelector((state) => state?.vendors?.vendors);
    const locationList = useSelector((state) => state.locations.locations);
    const productRedux = useSelector((state) => state?.product?.products);
    const orderstockRedux = useSelector((state) => state?.product?.order_stock);
    const order_stock_updated = useSelector((state) => state?.product?.order_stock_updated);
    const [is_edit, setEdit] = useState(undefined);
    const state = useSelector((state) => state);


    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);

    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);

    const getBusinessLocation = (business_id) => {
        dispatch(getLocations(business_id))
    }
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const businessData = getDataFromLocalStorage("business_data");

    const handleStockData = (e) => {
        const { name, value } = e.target
        setstockData({
            ...stockData,
            [name]: value
        })

    }

    const onorderStockDeleteHandler = (id) => {
        dispatch(
            deleteOrderstock(
                { id: id },
                () => {
                    toast.success('Order Stock Deleted successfully', { toastId: "toast" })
                    fetchstockorderlist()
                },
                () => {
                    toast.error(apiErrorMessage, { toastId: "toast" })

                }
            )
        )
    }

    const onstockorderUpdateHnadler = (edited_data, cancel) => {
        dispatch(
            updateorderStock(
                edited_data,
                cancel,
                () => {
                    toast.success('Order Stock Updated successfully', { toastId: "toast" })
                    SetOpenStock(!OpenStock)
                    fetchstockorderlist()

                },
                () => {
                    toast.error(apiErrorMessage, { toastId: "toast" })

                }
            )
        )
    }

    const fetchstockorderlist = async () => {
        const response = await getorderStock();

        if (response.status === 200) {
            let stockList = response?.data?.response?.stocks;
            dispatch(get_orderStocks(stockList))
        }
    }

    const onstocksubmithandler = () => {
        dispatch(
            createorderstock(
                stockData,
                () => {
                    setstockData({})
                    setstockError({})
                    SetOpenStock(!OpenStock)
                    fetchstockorderlist()
                    toast.success("Stock order created Successfully", { toastId: "toast" })

                },
                () => {
                    toast.error("Stock oreder did not created", { toastId: "toast" })
                }
            )
        )

    }

    useEffect(() => {
        if (locationList?.length === 0) {
            getBusinessLocation(businessData.id);
        }
    }, []);


    const fetchProductslist = async () => {
        const response = await getsProducts();

        if (response.status === 200) {
            let productList = response?.data?.response?.products;
            dispatch(getProducts(productList))
        }
    }

    useEffect(() => {
        if (vendorsRedux?.length === 0) {
            dispatch(getVendorsList())
        }
        if (productRedux?.length === 0) {
            fetchProductslist()
        }
        fetchstockorderlist()

    }, [])

    let parchaseObject = [
        { id: 1, product: "product_name", vendor: "vender_name", location: "location_name", quantity: 20 },
        { id: 2, product: "p1", vendor: "v1", location: "l1", quantity: 30 },
        { id: 3, product: "p2", vendor: "v2", location: "l2", quantity: 40 },
    ]


    return (
        (!order_stock_updated) ? <PreloaderFull /> : <>
            <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
                <p className='text-[#7B7B7B] text-sm'><span className='cursor-pointer' onClick={() => { navigate('/dashboard/account/') }}>My Account</span> {' '} &gt; {' '} <span className='cursor-pointer' onClick={() => { navigate('/dashboard/account/inventory-operations') }}>Inventory Operations</span> {' '} &gt; {' '} <span className='text-primary font-semibold'>Purchase</span></p>
                <div className='my-[1rem] flex items-center justify-between gap-4 flex-wrap'>
                    <div className='flex items-center sm:gap-4 gap-2'>

                        <h2 className='text-primary font-bold lg:text-3xl text-xl'>Purchase</h2>
                    </div>
                    {!employee ? (
                        <div className='flex items-center sm:gap-4 gap-2'>
                            <AddProductBtn />
                            <AddCategoryBtn />
                        </div>
                    ) : (
                        ""
                    )}

                </div>

                <div className='border rounded-lg'>
                    <div className='px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3'>
                        <div className='flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap'>
                            <div className='flex items-center sm:gap-4 gap-2 '>
                                <h2 className='font-semibold text-xl'>Stock Availability</h2>
                                <div className='p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap'>
                                    <p className='text-[#101928]'>{orderstockRedux?.length} Stock Availability</p>
                                </div>
                            </div>
                            <div className='mb-00'>
                                <LoginInput title=''
                                    name={"search_text"}
                                    value={search_text}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    placeholder='Search'
                                    padding='py-1'
                                    leftIcon={<Svgs.Search />} />
                            </div>
                        </div>
                        <div className='flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full'>
                            <LoginBtn borderBtn onClick={() => { setFilters(!Filters) }}>
                                <div className='flex items-center gap-2 text-[#101928]'>
                                    <Svgs.Filters />
                                    <p>Filters</p>
                                </div>
                            </LoginBtn>
                            <LoginBtn
                                onClick={() => {
                                    SetOpenStock(!OpenStock)
                                }} >
                                <div className='flex items-center gap-2'>
                                    <Svgs.Stock fill='white' />
                                    <p>Order Stock</p>
                                </div>
                            </LoginBtn>
                        </div>
                    </div>
                    <div className='overflow-x-auto'>
                        <div className='inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold'>
                            <p>Product</p>
                            <p>Vendor</p>
                            <p>Location </p>
                            <p>Quantity</p>
                            <p>Status</p>
                            <p>Actions</p>
                        </div>
                        {

                            orderstockRedux && orderstockRedux.length > 0 ?
                                orderstockRedux && orderstockRedux?.filter((data) => {
                                    if (
                                        (data?.products[0]?.product?.name?.toLowerCase()?.includes(search_text?.toLowerCase())) || (data?.vendor_name?.toLowerCase()?.includes(search_text?.toLowerCase()))
                                    ) {
                                        return true
                                    }
                                    else {
                                        return false
                                    }
                                }
                                ).map((data) => {
                                    return (
                                        <>
                                            <div className={`odd-bg text-[#3A3A3A] transition-all ${ShowDetails == data?.id ? 'border-l-[4px] border-[#5679FFB2]' : 'border-l-[0px]'}`}>
                                                <div className='px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))]'>
                                                    <div className={`before-left-arrow cursor-pointer ${ShowDetails == data?.id ? 'before-left-arrow-open' : 'before-left-arrow-close'}`} onClick={() => { setShowDetails(data.id) }}>
                                                        <div className='h-full flex items-center'>
                                                            <p className='text-sm'>{data.products[0]?.product.name}</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center'>
                                                        {/* <p className='text-sm'>vendor</p> */}
                                                        <p className='text-sm'>{data?.vendor_name}</p>
                                                    </div>
                                                    <div className='flex items-center'>
                                                        <p className='text-sm'>{data.location}</p>
                                                        <p className='text-sm'>locations</p>
                                                    </div>
                                                    <div className='flex items-center'>
                                                        <p className='text-sm line-clamp-1 pr-3'>{data.products[0]?.quantity}</p>
                                                        <p className='text-sm line-clamp-1 pr-3'>20</p>
                                                    </div>
                                                    <div className='flex items-center'>
                                                        {data.status == 'Placed' &&

                                                            <div className={`border border-[#05D672] text-[#05D672] bg-[#d2fdf4] rounded-full  px-2 py-1 text-xs flex items-center gap-2`}>
                                                                <Svgs.Bullet fill={'#05D672'} />
                                                                <p className='capitalize'>Placed</p>
                                                            </div>
                                                        }
                                                        {data.status == 'Received' &&
                                                            <div className={`border border-[#05D672] text-[#05D672] bg-[#d2fdf4] rounded-full  px-2 py-1 text-xs flex items-center gap-2`}>
                                                                <Svgs.Bullet fill={'#ff595a'} />
                                                                <p className='capitalize'>Received</p>
                                                            </div>
                                                        }
                                                        {data.status == 'Cancelled' &&
                                                            <div className={`border border-[#ff4343] text-[#ff595a] bg-[#ffeef4] rounded-full  px-2 py-1 text-xs flex items-center gap-2`}>
                                                                <Svgs.Bullet fill={'#ff595a'} />
                                                                <p className='capitalize'>Cancelled</p>
                                                            </div>
                                                        }
                                                        {data.status == 'Delivered' &&
                                                            <div className={`border border-[#a6bafe] text-primary bg-[#fbfcff] rounded-full  px-2 py-1 text-xs flex items-center gap-2`}>
                                                                <Svgs.Bullet fill={'#5679ff'} />
                                                                <p className='capitalize'>Delivered</p>
                                                            </div>
                                                        }
                                                        {data.status == 'Partially_Received' &&
                                                            <div className={`border border-[#9134fb] text-[#b64efc] bg-[#eadafc] rounded-full  px-2 py-1 text-xs flex items-center gap-2`}>
                                                                <Svgs.Bullet fill={'#b64efc'} />
                                                                <p className='capitalize'>Partially Recieved</p>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='flex items-center gap-2'>
                                                        {
                                                            data.status != 'Cancelled' &&

                                                            <div
                                                                onClick={() => {
                                                                    setEdit(data?.id)
                                                                    setstockData(
                                                                        {
                                                                            ...data,
                                                                            products: [
                                                                                ...data.products.map(pr_ => {
                                                                                    return {
                                                                                        ...pr_,
                                                                                        id: pr_?.product?.id,
                                                                                        quantity: pr_.quantity

                                                                                    }
                                                                                })
                                                                            ],
                                                                            rec_quantity: data?.rec_quantity,
                                                                        }

                                                                    )
                                                                    SetOpenStock(!OpenStock)
                                                                }}
                                                                className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center'>
                                                                <Svgs.Pen />
                                                            </div>
                                                        }
                                                        <div
                                                            onClick={() => {

                                                                onorderStockDeleteHandler(data.id)
                                                            }}
                                                            className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center'>
                                                            <Svgs.Delete fill='#ff4343' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                (data.id == ShowDetails) ? <div className='scale-in-center'>
                                                    <div className='bg-[#FAF9FF] px-6 py-3'>
                                                        <div className='inline-grid xl:grid grid-cols-[repeat(4,minmax(155px,1fr))] gap-y-5'>
                                                            <div className='flex flex-col gap-1'>
                                                                <p className="text-sm text-[#969BA0]">Product</p>
                                                                <p className='text-black font-semibold'>{data.products[0]?.product.name}</p>
                                                            </div>
                                                            <div className='flex flex-col gap-1'>
                                                                <p className="text-sm text-[#969BA0]">Vendor</p>
                                                                <p className='text-black font-semibold'>{data.vendor}</p>
                                                            </div>
                                                            <div className='flex flex-col gap-1'>
                                                                <p className="text-sm text-[#969BA0]">Delivery Location</p>
                                                                <p className='text-black font-semibold'>{data.location}</p>
                                                            </div>
                                                            <div className='flex items-end justify-between'>
                                                                <div className='flex flex-col gap-1'>
                                                                    <p className="text-sm text-[#969BA0]">Quantity</p>
                                                                    <p className='text-black font-semibold'>{data.products[0]?.quantity}</p>
                                                                </div>

                                                            </div>
                                                            <div className='flex items-start justify-between'>
                                                                <div className='flex flex-col items-start gap-1'>
                                                                    <p className="text-sm text-[#969BA0] text-center">Status</p>
                                                                    {data.status == 'Placed' &&

                                                                        <div className={`border border-[#05D672] text-[#05D672] bg-[#d2fdf4] rounded-full  px-2 py-1 text-xs flex items-center gap-2`}>
                                                                            <Svgs.Bullet fill={'#05D672'} />
                                                                            <p className='capitalize'>Placed</p>
                                                                        </div>
                                                                    }
                                                                    {data.status == 'Received' &&
                                                                        <div className={`border border-[#05D672] text-[#05D672] bg-[#d2fdf4] rounded-full  px-2 py-1 text-xs flex items-center gap-2`}>
                                                                            <Svgs.Bullet fill={'#ff595a'} />
                                                                            <p className='capitalize'>Received</p>
                                                                        </div>
                                                                    }
                                                                    {data.status == 'Cancelled' &&
                                                                        <div className={`border border-[#ff4343] text-[#ff595a] bg-[#ffeef4] rounded-full  px-2 py-1 text-xs flex items-center gap-2`}>
                                                                            <Svgs.Bullet fill={'#ff595a'} />
                                                                            <p className='capitalize'>Cancelled</p>
                                                                        </div>
                                                                    }
                                                                    {data.status == 'Delivered' &&
                                                                        <div className={`border border-[#a6bafe] text-primary bg-[#fbfcff] rounded-full  px-2 py-1 text-xs flex items-center gap-2`}>
                                                                            <Svgs.Bullet fill={'#5679ff'} />
                                                                            <p className='capitalize'>Delivered</p>
                                                                        </div>
                                                                    }
                                                                    {data.status == 'Partially_Received' &&
                                                                        <div className={`border border-[#9134fb] text-[#b64efc] bg-[#eadafc] rounded-full  px-2 py-1 text-xs flex items-center gap-2`}>
                                                                            <Svgs.Bullet fill={'#b64efc'} />
                                                                            <p className='capitalize'>Partially Recieved</p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='flex flex-col gap-1'></div>
                                                            <div className='flex flex-col gap-1'></div>
                                                            {/* <div className='flex items-start justify-end'>
                                                                <div className='h-[2.5rem] w-[2.5rem] border border-[#FF3030] rounded-full flex items-center justify-center'>
                                                                    <Svgs.Delete fill='#FF3030' />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div> : ''
                                            }
                                        </>
                                    )
                                })

                                : <NotFound />
                        }
                    </div>
                </div>
            </div>
            {
                Filters && <Popup heading='Filters' close={setFilters} open={Filters}>
                    <div className='flex flex-col gap-6'>
                        <Dropwdown title='Vendors' placeholder='Select Vendors' />
                        <Dropwdown title='Brand' placeholder='Select Brand' />
                        <Dropwdown title='Category' placeholder='Select Category' />
                        <LoginInput title='Vendors Name' placeholder='Enter Vendors Name' />

                        <div className='flex flex-col gap-3'>
                            <h2 className='font-semibold'>Stock</h2>
                            <CheckBox label='Low Stock' />
                            <CheckBox label='High Stock' />
                        </div>

                        <div className='flex flex-col gap-3'>
                            <h2 className='font-semibold'>Top Selling Items</h2>
                            <CheckBox label='Top Sellable Items' />
                        </div>

                        <div className='flex flex-col gap-2'>
                            <h2 className='font-semibold'>Top Selling Items</h2>
                            <div className='flex items-center gap-5'>
                                <div className='flex-1'>
                                    <div className='border rounded-lg p-3'>
                                        <input type="date" placeholder='asudhas' className='outline-none w-full text-[#7B7B7B]' />
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <div className='border rounded-lg p-3'>
                                        <input type="date" placeholder='asudhas' className='outline-none w-full text-[#7B7B7B]' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center justify-start mt-[2rem]'>
                            <LoginBtn text='Apply Filters' />
                        </div>
                    </div>
                </Popup>
            }
            {
                OpenStock && <Popup heading={`${is_edit ? 'Update Order stock' : 'Order Stock'}`} close={() => {
                    SetOpenStock()
                    setEdit(undefined)
                    setstockData({})
                    setstockError({})
                }} open={OpenStock}>
                    <div className='flex flex-col gap-6'>
                        <div className='flex items-center gap-4'>
                            <CircleIcon>
                                <Svgs.Inventory_Brands_2 />
                            </CircleIcon>
                            <div className='flex-1 flex items-center justify-between'>
                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-primary text-lg font-semibold'>Order Stock</h1>
                                    <p className='text-sm text-[#7B7B7B]'>Lorem Ipsum is simply dummy text of the printing and <br className='hidden lg:block' /> typesetting industry.</p>
                                </div>
                                <div>
                                    <LoginBtn text='Download PDF' />
                                </div>
                            </div>
                        </div>
                        <Dropwdown title='Vendors'
                            placeholder='Select Vendors'
                            options={vendorsRedux && vendorsRedux?.filter(itm => itm.is_active).map((item) => {
                                return ({ label: item.vendor_name, value: item.id });
                            })}
                            value={stockData?.vendor}
                            name='vendor'
                            disabled={is_edit}
                            error={stockError?.vendor}
                            onChange={(e) => {
                                handleStockData(e)
                            }}

                            onBlur={() => {
                                if (!stockData.vendor) {
                                    setstockError(
                                        {
                                            ...stockError,
                                            vendor: true
                                        }
                                    )
                                }

                                else {
                                    setstockError(
                                        {
                                            ...stockError,
                                            vendor: false
                                        }
                                    )
                                }

                            }}


                        />

                        <div className='flex flex-col gap-2'>
                            <h1 className='text-primary text-sm'>Delivery Location *</h1>
                            {
                                locationList.length > 0 &&
                                locationList.map((loc) => {
                                    {
                                        if (!is_edit || loc.id == stockData.location)

                                            return (

                                                <VerificationMethodCard
                                                    onClick={() => {
                                                        if (!is_edit) {

                                                            setstockData(
                                                                {
                                                                    ...stockData,
                                                                    location: loc.id
                                                                }
                                                            )
                                                        }
                                                        // console.log(stockData.location)
                                                        // console.log(loc.id,'id')

                                                    }}
                                                    checked={
                                                        stockData.location == loc.id ?
                                                            true
                                                            :
                                                            false


                                                    }
                                                    icon={<Svgs.Home />}
                                                    title={loc.address_name}
                                                    desc={loc.address} />
                                            )
                                    }
                                })
                            }
                        </div>

                        {
                            stockData?.products?.map((stock_product, index) => {
                                return (
                                    <>

                                        <div className='grid lg:grid-cols-2 gap-4'>
                                            <Dropwdown title='Product'
                                                placeholder='Select Product'
                                                disabled={is_edit}
                                                options={productRedux.map((item) => {
                                                    return ({ label: item.name, value: item.id });
                                                })}
                                                value={stock_product?.id}
                                                name='id'
                                                onChange={(e) => {
                                                    setstockData({
                                                        ...stockData,
                                                        products: [
                                                            ...stockData?.products?.map((st_pr, p_index) => {
                                                                if (index == p_index) {
                                                                    return {
                                                                        ...stock_product,
                                                                        id: e.target.value
                                                                    }
                                                                }
                                                                return st_pr
                                                            })
                                                        ]
                                                    })
                                                }}




                                            />
                                            <LoginInput title='Quantity'
                                                placeholder='Select Quantity'
                                                value={stock_product?.quantity}
                                                disabled={is_edit}
                                                name='quantity'
                                                type='number'
                                                onChange={(e) => {
                                                    setstockData({
                                                        ...stockData,
                                                        products: [
                                                            ...stockData?.products?.map((st_pr, p_index) => {
                                                                if (index == p_index) {
                                                                    return {
                                                                        ...stock_product,
                                                                        quantity: e.target.value
                                                                    }
                                                                }
                                                                return st_pr
                                                            })
                                                        ]
                                                    })
                                                }}


                                            />
                                        </div>
                                    </>
                                )

                            })
                        }
                        {!is_edit &&

                            <div
                                className='border-2 border-[#E1E1E1] border-dashed py-4 text-[#E1E1E1] text-sm font-semibold text-center rounded-lg cursor-pointer'
                                onClick={() => {
                                    let new_list = stockData.products ? stockData.products : []
                                    new_list.push({})
                                    // console.log(stockData.products)
                                    // console.log(new_list)
                                    setstockData({
                                        ...stockData,
                                        products: new_list
                                    })
                                    // console.log('ok')
                                }}
                            >
                                Add More Product
                            </div>

                        }
                        <Dropwdown title='Staus'
                            placeholder='Select Staus'
                            options={[
                                { value: 'Placed', label: 'Placed' },
                                { value: 'Delivered', label: 'Delivered' },
                                { value: 'Partially_Received', label: 'Partially Received' },
                                { value: 'Received', label: 'Received' },
                            ]}
                            value={stockData?.status}
                            name='status'
                            error={stockError?.status}
                            onChange={(e) => {
                                handleStockData(e)
                            }}

                            onBlur={() => {
                                if (!stockData.status) {
                                    setstockError(
                                        {
                                            ...stockError,
                                            status: true
                                        }
                                    )
                                }

                                else {
                                    setstockError(
                                        {
                                            ...stockError,
                                            status: false
                                        }
                                    )
                                }

                            }}
                        />
                        {
                            stockData.status == 'Partially_Received' &&

                            <LoginInput title='Received Quantity' placeholder='Select Received Quantity'
                                value={stockData?.rec_quantity}
                                name='rec_quantity'
                                error={stockError?.rec_quantity}
                                onChange={(e) => {
                                    handleStockData(e)
                                }}

                                onBlur={() => {
                                    if (!stockData.rec_quantity) {
                                        setstockError(
                                            {
                                                ...stockError,
                                                rec_quantity: true
                                            }
                                        )
                                    }

                                    else {
                                        setstockError(
                                            {
                                                ...stockError,
                                                rec_quantity: false
                                            }
                                        )
                                    }

                                }}
                            />

                        }
                        <div className={`flex items-center gap-4 ${(stockData.status == 'Placed' && is_edit) ? 'justify-between' : 'justify-end'} mt-[3rem]1`}>
                            {
                                (stockData.status == 'Placed' && is_edit) &&
                                <LoginBtn text='Cancel Order' deleteBtn
                                    onClick={
                                        () => {
                                            let cancel = true
                                            onstockorderUpdateHnadler(stockData, cancel)

                                        }
                                    }
                                />
                            }

                            <LoginBtn text={`${is_edit ? 'Update Order' : 'Create Order'}`}
                                onClick={
                                    () => {
                                        is_edit ?
                                            onstockorderUpdateHnadler(stockData)
                                            :
                                            onstocksubmithandler()
                                    }
                                }
                            />
                        </div>

                    </div>
                </Popup>
            }

        </>
    )
}

export default Purchase