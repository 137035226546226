import React from 'react'
import Shimmer from './Shimmer'

const BookingDetailCardShimmer = () => {
    return <Shimmer className={'px-2 py-5 cursor-pointer transition-all rounded-lg bg-white text-primary'} >
        <div className=' py-3 w-1/3 rounded-xl my-2 bg-gray-200'></div>
        <hr />
        <div className="py-3 w-1/5 rounded-xl my-2 bg-gray-200"></div>
        <div className='flex justify-between'>
            <div className="py-3 w-1/4 rounded-xl my-2 bg-gray-200"></div>
            <div className="py-3 w-1/4 rounded-xl my-2 bg-gray-200"></div>
        </div>
        <hr />
        <div className="py-3 w-1/5 rounded-xl my-2 bg-gray-200"></div>
        <div className='flex justify-between'>
            <div className="py-3 w-1/4 rounded-xl my-2 bg-gray-200"></div>
            <div className="py-3 w-1/4 rounded-xl my-2 bg-gray-200"></div>
        </div>
    </Shimmer>
}

export default BookingDetailCardShimmer