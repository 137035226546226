import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import {
    addAbsence,
    deleteAbsence,
    editAbsence,
    fetchAbsenceList,
    fetchAbsenceListWithNoPagination,
} from "Adapters/Api/absence";
import useValidations from "Hooks/useValidations";
import { getEmployeeDropDownListPagination, getWorkingShedule } from "Adapters/Api/employee";
import useObjectKeys from "Hooks/useObjectKeys";
import {
    // add_Absence,
    // delete_Absence,
    // get_Absences,
    update_Absence,
} from "Redux/TenantRedux/Actions/absence";
import { ABSENCE } from "Redux/TenantRedux/ActionTypes/absenceTypes";

let timeOutId = undefined
let employeeTimeOutId = undefined

export const useAbsence = () => {
    const date = new Date();
    const navigate = useNavigate();
    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");
    const selected_location = getDataFromLocalStorage("selected_location");
    const [searchAbsenceText, setSearchAbsenceText] = useState("");
    const [absenceLoader, setAbsenceLoader] = useState(true)
    const [absenceNoPaginationLoader, setAbsenceNoPaginationLoader] = useState(true)
    const dispatch = useDispatch();
    const { objectKeyConvertToArray } = useObjectKeys();
    const [absence, setAbsence] = useState({
        staff: "",
        from: "",
        to: "",
        description: "",
    });
    const [filterAbsenceStaff, setFilterAbsenceStaff] = useState("");
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currentAbsencePage, setCurrentAbsencePage] = useState(1);
    const [deleteAbsenceLoading, setDeleteLoading] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [editPopup, setEditPopup] = useState(false);
    const [openAbsence, setOpenAbsence] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const stateAbsence = useSelector((state) => state);
    // const employeeAbsenceListRedux = stateAbsence?.employee?.employees
    const businessId = stateAbsence?.business?.business?.id;
    const absenceList = stateAbsence?.absence?.absences?.data;
    const allAbsenceList = stateAbsence?.absence?.allAbsences?.data;
    const absencePages = stateAbsence?.absence?.pages;
    const absenceAllCount = stateAbsence?.absence?.allCount;

    const [employeeSearch, setEmployeeSearch] = useState("");
    const [employeePage, setEmployeePage] = useState(1)
    const [employeeLoader, setEmployeeLoader] = useState(false)

    const employeeAbsenceListRedux = stateAbsence?.employee?.employeesDropdown;
    const employeePaginationData = stateAbsence?.employee?.employeeDropdownPaginationData
    const employeeRedux = stateAbsence?.employee?.employeesDropdown

    let { staff, from, to } = absence;

    let validateAbsence = { staff, from };
    let disbledValidation =
        Object.values(validateAbsence).every((x) => x !== "") === false
            ? true
            : false;

    // useEffect(() => {
    //     handleGetAbsenceList();
    // }, [absenceList?.length === 0, currentAbsencePage, filterAbsenceStaff]);

    useEffect(() => {
        if (selected_location) {
            if (searchAbsenceText === null) {
                // Initial load with empty searchAbsenceText
                handleGetAbsenceList(currentAbsencePage, selected_location, filterAbsenceStaff, '', access_token)
                handleGetAbsenceListWithoutPagination(selected_location, filterAbsenceStaff, '', access_token)
            } else {
                if (timeOutId) {
                    clearTimeout(timeOutId)
                }
                timeOutId = setTimeout(() => {
                    handleGetAbsenceList(currentAbsencePage, selected_location, filterAbsenceStaff, searchAbsenceText, access_token)
                    handleGetAbsenceListWithoutPagination(selected_location, filterAbsenceStaff, searchAbsenceText, access_token)
                }, 500);
            }
        }
    }, [currentAbsencePage, selected_location, searchAbsenceText, filterAbsenceStaff])

    useEffect(() => {
        setAbsenceLoader(true)
        setAbsenceNoPaginationLoader(true)
        setCurrentAbsencePage(1)
    }, [selected_location, filterAbsenceStaff])

    useEffect(() => {
        setCurrentAbsencePage(1)
    }, [searchAbsenceText])

    // useEffect(() => {
    //     dispatch(getEmployeeList());
    // }, []);

    const employeeAbsenceDropdownList = useMemo(() => {
        // eslint-disable-next-line array-callback-return
        const list = employeeAbsenceListRedux?.map((obj) => {
            if (obj.id) {
                return {
                    value: obj.id,
                    label: obj.full_name,
                };
            }
        });
        return list;
    }, [employeeAbsenceListRedux?.length > 0]);

    const handleGetAbsenceList = async (currentAbsencePage, selected_location, filterAbsenceStaff, searchAbsenceText, access_token) => {
        const response = await fetchAbsenceList(currentAbsencePage, selected_location, filterAbsenceStaff, searchAbsenceText, access_token, setAbsenceLoader);
        if (response.status === 200) {
            let absenceList = response?.data?.response?.absences;
            let absencePages = response?.data?.response;
            // dispatch(get_Absences(absenceList));
            dispatch({
                type: ABSENCE.GET_ABSENCES,
                payload: {
                    data: absenceList
                },
            })
            dispatch({
                type: ABSENCE.GET_ABSENCE_PAGES,
                payload: absencePages,
            })
            setAbsenceLoader && setAbsenceLoader(false)
        }
    };

    const handleGetAbsenceListWithoutPagination = async (selected_location, filterAbsenceStaff, searchAbsenceText, access_token) => {
        const response = await fetchAbsenceListWithNoPagination(selected_location, filterAbsenceStaff, searchAbsenceText, access_token, setAbsenceNoPaginationLoader);
        if (response.status === 200) {
            let absenceList = response?.data?.response?.absences;
            // dispatch(get_Absences(absenceList));
            dispatch({
                type: ABSENCE.GET_ABSENCES_WITHOUT_PAGINATION,
                payload: {
                    data: absenceList
                },
            })
            setAbsenceNoPaginationLoader && setAbsenceNoPaginationLoader(false)
        }
    };

    const absenceHandleChange = (e) => {
        const { name, value } = e.target;

        if (name == "description") {
            setAbsence((prev) => ({ ...prev, description: value }));
            setErrors((prev) => ({ ...prev, description: "" }));
            // if (value && value.length <= 150) {

            //     setAbsence((prev) => ({ ...prev, description: value }));
            //     setErrors((prev) => ({ ...prev, description: "" }));
            // } else {
            //     if (value && value.length > 150) {
            //         setErrors((prev) => ({
            //             ...prev,
            //             description: "You can not add more than 150 characters",
            //         }));
            //     }
            // }
        }

        if (name === "filterAbsenceStaff") {
            setFilterAbsenceStaff(value);
        }

        if (name != "description" && name != "filterAbsenceStaff") {
            setAbsence((prev) => ({ ...prev, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: "" }));
        }
    };

    const absenceDeleteHandler = async (deleteId, handleClose) => {
        setDeleteLoading(true);
        let response = await deleteAbsence(deleteId, access_token);

        if (response.status === 200) {
            let successMessage = response?.data?.response?.message;
            toast.success("Absence delete successfully!", { toastId: "toast" });
            // dispatch(delete_Absence(deleteId));
            setCurrentAbsencePage(1)
            handleGetAbsenceList(1, selected_location, filterAbsenceStaff, searchAbsenceText, access_token);
            handleGetAbsenceListWithoutPagination(selected_location, filterAbsenceStaff, searchAbsenceText, access_token)
            setDeleteLoading(false);
            handleClose();
        }
    };

    const onSubmitEditHandler = async (handleClose, editData) => {
        const { staff, from, to, description } = absence;
        let absenceData = { staff, from };
        setErrors(validation(absenceData));
        let errorResponse = validation(absenceData);

        if (objectKeyConvertToArray(errorResponse)?.length === 0) {
            const body = {
                schedule_id: editData?.id,
                employee: staff,
                from_date: from,
                to_date: to,
                note: description,
                business: businessId,
                is_leave: true,
            };
            setLoading(true);
            const response = await editAbsence(body, access_token);
            if (response.status === 200) {
                let absenceEditResponse = response?.data?.response?.schedule;
                let successMessage = response?.data?.response?.message; // need to update the response from backend
                setLoading(false);
                dispatch(update_Absence(absenceEditResponse));
                // toast.success('Absence Updated Successfully');
                toast.success(successMessage, { toastId: "toast" })
                setAbsence({
                    ...absence,
                    staff: "",
                    from: "",
                    to: "",
                    description: "",
                });
                setCurrentAbsencePage(1)
                handleGetAbsenceList(1, selected_location, filterAbsenceStaff, searchAbsenceText, access_token);
                handleGetAbsenceListWithoutPagination(selected_location, filterAbsenceStaff, searchAbsenceText, access_token)
                handleClose();
            } else {
                setLoading(false);
                toast.error("Error in Update absence", { toastId: "toast" });
            }
        }
    };

    const onSubmitHandler = async (handleClose) => {
        const { staff, from, to, description } = absence;
        let absenceData = { staff, from };
        setErrors(validation(absenceData));
        let errorResponse = validation(absenceData);

        if (objectKeyConvertToArray(errorResponse)?.length === 0) {
            const body = {
                employee: staff,
                from_date: from,
                to_date: to,
                note: description,
                business: businessId,
                is_leave: true,
            };
            setLoading(true);

            const response = await addAbsence(body, access_token);
            if (response.status === 200) {
                let absenceResponse = response?.data?.response?.schedule;
                // dispatch(add_Absence(absenceResponse));
                let payload = {
                    location_id: selected_location
                }
                dispatch(getWorkingShedule(payload));
                let successMessage = response?.data?.response?.message;
                handleGetAbsenceList(1, selected_location, filterAbsenceStaff, searchAbsenceText, access_token);
                setLoading(false);
                toast.success(successMessage, { toastId: "toast" });
                setAbsence({
                    ...absence,
                    staff: "",
                    from: "",
                    to: "",
                    description: "",
                });
                setCurrentAbsencePage(1)
                handleGetAbsenceList(1, selected_location, filterAbsenceStaff, searchAbsenceText, access_token);
                handleGetAbsenceListWithoutPagination(selected_location, filterAbsenceStaff, searchAbsenceText, access_token)
                handleClose();
            } else {
                setLoading(false);
                toast.error("Error in create absence", { toastId: "toast" });
            }
        }
    };

    useEffect(() => {
        if (selected_location) {
            if (employeeSearch === null) {
                // Initial load with empty search_text
                dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
            } else {
                if (employeeTimeOutId) {
                    clearTimeout(employeeTimeOutId)
                }
                employeeTimeOutId = setTimeout(() => {
                    dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
                }, 500);
            }
        }
    }, [employeeSearch, selected_location, employeePage])

    return {
        absenceHandleChange, handleGetAbsenceList, setAbsence, setSearchAbsenceText, setFilterAbsenceStaff, setOpenAbsence,
        setDeleteId, setIsEdit, setDeleteLoading, setDeletePopup, setEditPopup, navigate, onBlurHandler, absenceDeleteHandler,
        onSubmitHandler, onSubmitEditHandler, employeeAbsenceListRedux, disbledValidation, absenceList, absence, searchAbsenceText,
        isEdit, openAbsence, deletePopup, editPopup, deleteId, employeeAbsenceDropdownList, errors, loading, deleteAbsenceLoading,
        filterAbsenceStaff, stateAbsence, date, currentAbsencePage, setCurrentAbsencePage, absencePages, absenceAllCount,
        allAbsenceList, absenceLoader, employeeSearch, setEmployeeSearch, employeePage,
        setEmployeePage, employeeLoader, setEmployeeLoader, employeePaginationData, employeeRedux
    };
};
