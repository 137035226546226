import { deleteDeal, getAllDeals, getDealsCategories, updateDealsStatus } from "Adapters/Api/CRM/Deals"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const initialFilter = {
    category: "",
    status: "",
}

let timeOutId = undefined
const useDeals = () => {
    const PageSize = 10
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const dealsData = state?.crm?.deals
    const allDeals = dealsData?.list
    console.log(dealsData, "allDeals")

    const [currentPage, setCurrentPage] = useState(1)
    const [loader, setLoader] = useState(true)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [keyWord, setKeyWord] = useState('')
    const [FilterPopup, setFilterPopup] = useState(false)
    const [filterData, setFilterData] = useState(initialFilter)
    const [statusLoader, setStatusLoader] = useState({
        id: "",
        editLoader: false,
        statusLoader: false
    })
    const [editDeal, setEditDeal] = useState('')

    useEffect(() => {
        getCategoriesForDeals()
    }, [])

    useEffect(() => {
        let payload = {
            page: 1
        }
        let success = () => {
            setLoader(false)
        }
        let fail = () => {
            setLoader(false)
        }
        if (keyWord === null) {
            // Initial load with empty search_text
            setLoader(true)
            dispatch(getAllDeals(payload, access_token, success, fail))
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                payload = { ...payload, search_text: keyWord }
                setLoader(true)
                dispatch(getAllDeals(payload, access_token, success, fail))
            }, 500);
        }
    }, [currentPage, keyWord])

    useEffect(() => {
        setCurrentPage(1)
    }, [keyWord])

    useEffect(() => {
        if (localStorage.getItem('dealId')) {
            localStorage.removeItem('dealId')
            localStorage.removeItem('editDeal')
        }
    }, [localStorage.getItem('dealId')])

    // Get Deal Categories
    const getCategoriesForDeals = () => {
        if (access_token) {
            let success = (result) => {
            }
            let fail = () => {
            }
            dispatch(getDealsCategories(access_token, success, fail))
        }
    }

    // On Filter Change
    const onFilterChange = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({ ...prev, [name]: value }));
    }

    // clear filter
    const clearFilter = () => {
        if (filterData?.status || filterData?.category) {
            getApi()
        }
        setFilterData(initialFilter)
        setFilterPopup(false)
    }

    // on submit filter
    const onSubmitFilter = () => {
        setCurrentPage(1)
        if (access_token) {
            let payload = {
                page: 1
            }
            if (filterData?.category) {
                payload = { ...payload, category: filterData?.category }
            }

            if (filterData?.status) {
                payload = { ...payload, status: filterData?.status }
            }

            let success = () => {
                setLoader(false)
            }
            let fail = () => {
                setLoader(false)
            }
            setLoader(true)
            dispatch(getAllDeals(payload, access_token, success, fail))
        }
        setFilterPopup(false)
    }

    // delete deal function
    const deleteDealFunct = () => {
        let success = () => {
            setDeleteId('')
            setDeleteLoader(false)
            getApi()
        }
        let fail = () => {
            setDeleteLoader(false)
        }
        setDeleteLoader(true)
        dispatch(deleteDeal(deleteId, access_token, success, fail))
    }

    // get api after delete and update
    const getApi = () => {
        setCurrentPage(1)
        if (access_token) {
            let payload = {
                page: 1
            }
            let success = () => {
                setLoader(false)
            }
            let fail = () => {
                setLoader(false)
            }
            setLoader(true)
            dispatch(getAllDeals(payload, access_token, success, fail))
        }
    }

    // handle edit funct
    const editDealFunct = (id, status) => {
        if (status == 'Draft') {
            storeDataInLocalStorage('editDeal', true)
            storeDataInLocalStorage('dealId', id)
            navigate("/dashboard/crm/deals/createDeals")
        } else {
            setEditDeal(id)
        }
    }

    // update deal status function
    const updateDealStatus = (id, value) => {
        let success = (result) => {
            if (value == 'Draft') {
                storeDataInLocalStorage('editDeal', true)
                storeDataInLocalStorage('dealId', id)
                navigate("/dashboard/crm/deals/createDeals")
            } else {
                getApi()
            }
            setStatusLoader(() => ({
                id: id,
                editLoader: false,
                statusLoader: false
            }));
        }
        let fail = () => {
            setStatusLoader(() => ({
                id: id,
                editLoader: false,
                statusLoader: false
            }));
        }
        let payload = {
            status: value,
            dealId: id
        }

        if (id) {
            if (value == 'Draft') {
                setStatusLoader(() => ({
                    id: id,
                    editLoader: true,
                    statusLoader: false
                }));
            } else {
                setStatusLoader(() => ({
                    id: id,
                    editLoader: false,
                    statusLoader: true
                }));
            }
            dispatch(updateDealsStatus(id, payload, access_token, success, fail))
        }
    }


    return {
        navigate, loader, currentPage, setCurrentPage, allDeals, dealsData, PageSize, deleteDealFunct,
        deleteLoader, deleteId, setDeleteId, updateDealStatus, statusLoader, editDeal, setEditDeal, clearFilter,
        editDealFunct, keyWord, setKeyWord, setFilterPopup, FilterPopup, filterData, onFilterChange, onSubmitFilter
    }
}

export default useDeals