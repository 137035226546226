import { getClient, getClientPackage } from "Adapters/Api/clients"
import { getsProducts } from "Adapters/Api/Products"
import Svgs from "Assets/svgs"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { getProducts } from "Redux/TenantRedux/Actions/product"
import { CLIENT } from "Redux/TenantRedux/ActionTypes/clientActionTypes"





export const PackagesPromotionsCard = ({ selected, data, onSelect, onRemoveOffer }) => {

    // useEffect(()=>{
    //     console.log(data)
    // } , [])

    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    return (
        <>
            <div
                className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? "border-black" : " border-[#B8C6F5]"
                    }  border   rounded-md p-4 mt-1 cursor-pointer`}

            >
                <div className="mb-3 flex justify-between items-center gap-1">
                    <p className="text-[#A1A1A1] font-bold">
                        {selectedLanguage?.name == "English" ? "Package" : translation[1219]?.value ? translation[1219]?.value : "Package"}
                    </p>
                    {onRemoveOffer && selected &&
                        <button
                            className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                            onClick={onRemoveOffer}
                        >
                            <Svgs.Delete fill="#767A87" />
                        </button>
                    }
                </div>
                <div className="text-black font-semibold mb-1 w-full">{data?.promotion_name}</div>

                <div className="">
                    {
                        data?.service_duration?.map((service_offer, index) => {
                            return (
                                <div key={index} className={`mb-2 bg-gray-200 px-2 py-1 ${!selected ? 'hover:bg-gray-300' : ''}  rounded-md`} onClick={() => onSelect({ ...service_offer, type: data.type, package: data })}>
                                    <p className="text-[#2a2a2a]">
                                        {selectedLanguage?.name == "English" ? "Avail" : translation[1194]?.value ? translation[1194]?.value : "Avail"}
                                        {service_offer?.services?.map((service, inner_index) => <>
                                            {inner_index > 0 && ', '}
                                            <span key={inner_index} className="text-black font-medium">
                                                "{service?.name}"</span></>)
                                        }
                                        {selectedLanguage?.name == "English" ? "Services in package AED" : translation[1220]?.value ? translation[1220]?.value : "Services in package AED"}
                                        {service_offer?.total_amount}
                                        {selectedLanguage?.name == "English" ? "within" : translation[1221]?.value ? translation[1221]?.value : "within"}
                                        {service_offer?.package_duration}
                                        {selectedLanguage?.name == "English" ? "Months" : translation[1222]?.value ? translation[1222]?.value : "Months"}

                                    </p>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </>
    )
}

// Avail "Ok", "Mask", "Hair Color" services in package AED within 3 Months

PackagesPromotionsCard.defaultProps = {
    data: {},
    selected: false,
    onSelect: () => { }
}

const PackagesPromotions = ({ setNotFound, packages, MODULE_TYPE, servicesRedux, onSelect, setSelServGroups, setServiceData }) => {

    const state = useSelector(state => state)
    const [packages_data, setPackageData] = useState([])

    const dispatch = useDispatch()

    const handle_select_appointment = (data) => {
        let today_date = new Date()
        today_date = `${today_date.getFullYear()}-${today_date.getMonth() + 1}-${today_date.getDate()}`

        let selected_client = state?.appointment?.selected_client
        if (Object.keys(selected_client).length == 0) {
            toast.info('Please select Client first')
            onSelect(
                [],
                false,
            )
            return
        }

        const handle_success = (result) => {
            let services_ids = []
            let total_price = 0

            if (result?.response?.is_expired) {
                toast.info('The package is expired')
                onSelect(
                    [],
                    false,
                )
                return
            }

            if (result.status_code == 404) {
                total_price = data?.total_amount
                services_ids = data?.service
            }
            else {
                if (result?.response?.Service) {
                    services_ids = result.response.Service
                }
                else {
                    services_ids = []
                }
            }

            if (services_ids.length == 0) {
                toast.info('This client has completely availed this package')
                onSelect(
                    [],
                    false,
                )
                return
            }


            setSelServGroups((prev) => {
                let new_service_grp = []

                if (services_ids.length > 0) {
                    prev.forEach(srv_grp => {
                        let return_services = srv_grp?.services?.filter(srvice => services_ids.includes(srvice.id))
                        if (return_services.length > 0) {
                            return_services.forEach(service => {
                                if (service.priceservice.length > 0) {
                                    service.priceservice = [{ ...service.priceservice[0], price: (data?.total_amount / data?.service?.length), duration: data?.service_duration }]
                                }
                            })
                            new_service_grp.push({
                                ...srv_grp,
                                services: return_services
                            })
                        }
                    })
                }
                return new_service_grp
            })
            onSelect(
                [],
                false,
                {
                    extra_price: total_price,
                    free_services_quantity: services_ids.length,
                    selected_promotion_type: data?.type,
                    selected_promotion_id: data?.id,
                    is_promotion_availed: true,
                    selected_promotion: data?.package,
                }
            )
        }


        const handle_fail = () => {

        }

        dispatch(
            getClientPackage(
                { client: selected_client.id, package: data?.package?.id, package_service: data?.id },
                handle_success,
                handle_fail
            )
        )




    }

    const handle_select_services = (data) => {
        let services = []


        let extra_price = 0
        data?.service_duration?.forEach(srv_dur => {
            extra_price += srv_dur.total_amount
            srv_dur.services?.forEach(service => {
                let appointment = {
                    ...service,
                    discount: service?.discount_percentage,
                    discount_price: undefined,
                    quantity: 1
                }

                services.push(appointment)
            })
        })
        dispatch({
            type: "ADD_BULK_ITEMS_TO_CART",
            payload: {
                'services': services,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
                is_promotion: true,
            }
        });
        toast.success('Items added to cart', { toastId: "toast" })
        onSelect(
            [],
            false,
        )

    }


    const handle_select = (data) => {
        // dispatch({
        //     type: CLIENT.SET_SPECIFIC_CLIENTS_FOR_CHECKOUT,
        //     payload: {'clients' : data.clients, 'client_discount' : data?.discount_percentage}
        // });

        let funcs = {
            'APPOINTMENT': () => handle_select_appointment(data),
            'SERVICE': () => handle_select_services(data),
        }
        funcs[MODULE_TYPE] && funcs[MODULE_TYPE]()

        // onSelect(
        //     [], 
        //     false,
        // )


        // dispatch({
        //     type: "ADD_BULK_ITEMS_TO_CART",
        //     payload: {'services' : new_items}
        // });
        // onSelect(
        //     [], 
        //     false,
        // )
    }

    useEffect(() => {
        if (!state.client.clients_updated) {
            dispatch(
                getClient()
            )
        }
    }, [])

    useEffect(() => {
        let packages_data_ = [
            ...packages
        ]


        // rest_discounts.forEach(itm =>{

        //     let clients = state.client?.clients?.filter(clnt => itm?.client?.includes(clnt.id))
        //     itm.clients = clients
        // })

        // let new_data = rest_discounts.filter(itm =>{
        //     if ((MODULE_TYPE == 'APPOINTMENT' || MODULE_TYPE == 'SERVICE') && itm.corporate_type == 'All Service'){
        //         return true
        //     }
        //     else if (MODULE_TYPE == 'PRODUCT' && itm.corporate_type == 'Retail Product'){
        //         return true
        //     }
        // })

        packages_data_.forEach(pckg => {

            pckg?.service_duration?.forEach(service_offer => {
                let new_services = []

                service_offer?.service?.forEach(service_id => {
                    let this_service = servicesRedux?.find(itm => itm.id == service_id)
                    if (this_service) {
                        new_services.push(this_service)
                    }
                })
                service_offer.services = new_services
            })
        })

        setPackageData(packages_data_)

    }, [servicesRedux])

    return (
        <>
            {
                packages_data?.map((retail, index) => {
                    return (
                        <>
                            <PackagesPromotionsCard
                                data={retail}
                                key={index}
                                onSelect={handle_select}
                            />
                        </>
                    )
                })
            }
        </>
    )
}

PackagesPromotions.defaultProps = {
    spend_some_amount: []
}


export default PackagesPromotions

