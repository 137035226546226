import React from 'react'
import Svgs from '../../../../Assets/svgs'
import Dropwdown from '../../Elements/Dropwdown'
import { APPOINTMENT_TIME, CALENDAR_OPENING_TIME, OPENING_TIME } from '../../../../Constants/Data/OpeningHours'
import Popup from '../../Elements/Popup'
import AddService from '../Sales/quick-sales/Services/addService'
import { useService } from '../Sales/quick-sales/Services/helper'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import useAppointmentErrorHandling from '../../../../Hooks/useAppointmentErrorHandling'
import AddServiceGroup from '../Sales/quick-sales/Services/AddServiceGroup'
import { getServiceGroup } from 'Adapters/Api/saleServices'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox'

const AppointmentsDetails = ({ selectedIndex, handleNewServices, translation, selectedLanguage, setServiceId, setSelectedIndex, index, setdropdown, search, setSearch, locationDetail, onRemove, appointmentData, Filtered_employees, selecteMember, action, clientRedux, onUpdate, isVailible, SelectServices, hr_and_mints, hr, min, FilterDataGroup, employeeRedux, setSelectServices, checkEmployeetiming }) => {
    // check for only services api call when on calendar
    let allowGroupsApiCall = true
    const {
        durationOptions,
        serviceModal,
        isEdit,
        active,
        errors,
        loading,
        serviceData,
        ServiceType,
        setService,
        setAddService,
        onSubmitService,
        serviceHandleChange,
        onBlurHandler,
        setIsEdit,
        removedSelectedEmployee,
        setErrors,
        setServiceType,
        setIsDelete,
        formRef,
        ServiceGroup,
        setServiceGroup,
        dispatch
    } = useService(allowGroupsApiCall);
    const {
        service_future_error,
        booking_error
    } = useAppointmentErrorHandling({ ...appointmentData, selectedIndex });


    //handle change
    const handleChange = (e, book = "", online_slot = "", id, additional_data = {}) => {
        let { name, value } = e.target
        if (name === "service") {
            onUpdate({ name: name, value: value, indexValue: selectedIndex }, {}, book, online_slot, id, additional_data)
            setdropdown("False")
        } else if (name === "is_favourite") {
            onUpdate({ name: name, value: e.target.checked, indexValue: selectedIndex })
        } else {
            onUpdate({ name: name, value: value, indexValue: selectedIndex })
            setdropdown("False")
        }

    }

    return (
        <>

            <div className='flex gap-6'>
                <div className='mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4 relative'>
                    {(action != "edit" && action != "reschedul") &&
                        <div
                            className='absolute cursor-pointer top-0 right-0 translate-x-1/2 -translate-y-1/2'
                            onClick={() => onRemove(index)}
                        >
                            <Svgs.CirlceClose size='1.75rem' fill='#ED0F1C' />
                        </div>
                    }
                    <div className='grid lg:grid-cols-2 gap-4'>
                        <Dropwdown
                            name={"appointment_time"}
                            value={appointmentData && appointmentData?.appointment_time}
                            onChange={(e) => {
                                checkEmployeetiming({ ...appointmentData, appointment_time: e.target.value, index })
                                handleChange(e);
                                setSelectedIndex(index);
                            }}
                            required
                            options={CALENDAR_OPENING_TIME}
                            title={selectedLanguage?.name == "English" ? "Time" : translation[1728]?.value ? translation[1728]?.value : "Time"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Time" : translation[130]?.value ? translation[130]?.value : "Select Time"}
                            handleFocus={() => setSelectedIndex(index)}
                        />
                        <div>
                            <div>
                                <p className='mb-2 text-sm' >
                                    {selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"}
                                    <span className='text-red-500'>*</span></p>
                                <div className={`inputdesign ${action != "edit" && "cursor-not-allowed bg-gray-100"}`} onClick={() => {
                                    if (action === "edit") {
                                        setSelectServices(!SelectServices);
                                        setSelectedIndex(index);
                                    }
                                }} >
                                    <p className='placeholder !text-black overflow-hidden overflow-x-auto'>{appointmentData?.srv_name} ({appointmentData?.srv_duration ? appointmentData.srv_duration : appointmentData?.duration}, {locationDetail?.currency?.code} {appointmentData?.price} {appointmentData?.discount_price}) </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(appointmentData && appointmentData?.message) && <div className='flex items-center gap-1'>
                        <Svgs.I />
                        <p className='text-xs text-[#555555]'>
                            {appointmentData?.message}</p>
                    </div>}
                    {/* {(!isVailible && selecteMember?.full_name) && <div className='flex items-center gap-1'>
                        <Svgs.I />
                        <p className='text-xs text-[#555555]'>
                            {selecteMember?.full_name} isn’t available between {hr_and_mints.join(":")} and {hr >= 10 ? hr : "0" + hr} :{min >= 10 ? min : "0" + min},
                            but your team member can still book appointments for them.</p>
                    </div>} */}
                    <div className='grid lg:grid-cols-2 gap-4'>
                        <Dropwdown
                            disabled={action != "edit"}
                            name={"duration"}
                            value={appointmentData?.duration}
                            onChange={(e) => {
                                checkEmployeetiming({ ...appointmentData, duration: e.target.value, index })
                                handleChange(e);
                                setSelectedIndex(index);
                            }}
                            // options={DURATIONS}
                            options={APPOINTMENT_TIME}
                            title={selectedLanguage?.name == "English" ? "Duration" : translation[112]?.value ? translation[112]?.value : "Duration"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Duration" : translation[115]?.value ? translation[115]?.value : "Select Duration"}
                            handleFocus={() => setSelectedIndex(index)}
                        />
                        {(action === "edit") ?
                            <Dropwdown
                                name={"member"}
                                required
                                value={appointmentData?.member}
                                options={appointmentData?.employee_list?.map((itm) => {
                                    return {
                                        label: itm?.employee_name,
                                        value: itm?.employee
                                    }
                                })}
                                onChange={(e) => {
                                    checkEmployeetiming({ ...appointmentData, member: e.target.value, index })
                                    handleChange(e);
                                }}
                                title={selectedLanguage?.name == "English" ? "Team Member" : translation[116]?.value ? translation[116]?.value : "Team Member"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Team Member" : translation[117]?.value ? translation[117]?.value : "Select Team Member"}
                                handleFocus={() => setSelectedIndex(index)}
                            /> : <Dropwdown
                                disabled={true}
                                name={"member"}
                                required
                                value={appointmentData?.member}
                                options={employeeRedux.map(empl => {
                                    return {
                                        label: `${empl.full_name}`,
                                        value: empl.id,
                                    }
                                })}
                                onChange={handleChange}
                                title={selectedLanguage?.name == "English" ? "Team Member" : translation[116]?.value ? translation[116]?.value : "Team Member"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Team Member" : translation[117]?.value ? translation[117]?.value : "Select Team Member"}
                                handleFocus={() => setSelectedIndex(index)}
                            />
                        }
                    </div>
                    <div>
                        <CheckBox
                            name="is_favourite"
                            onChange={(e) => {
                                handleChange(e)
                                setSelectedIndex(index);
                            }}
                            disabled={action === "reschedul"}
                            label_Class={'!normal-case text-[#7B7B7B]'}
                            value={appointmentData.is_favourite}
                            checked={appointmentData.is_favourite}
                            label={selectedLanguage?.name == "English" ? "Request staff for this appointment" : translation[1555]?.value ? translation[1555]?.value : "Request staff for this appointment"}
                        />
                    </div>
                    {
                        action != "edit" && <p className='text-sm text-[#2f2f2f] flex items-center gap-1'>
                            <Svgs.I />
                            <span>
                                {selectedLanguage?.name == "English" ? "You can only change the time of the appointment while rescheduling." : translation[224]?.value ? translation[224]?.value : "You can only change the time of the appointment while rescheduling."}
                            </span>
                        </p>
                    }
                    {
                        (action && action === "edit") &&
                        <div>
                            {
                                appointmentData?.employee_list?.filter((fltr) => (fltr?.location[0]?.id === locationDetail?.id) ? true : false)?.length === 0 &&
                                <p className='text-xs text-[#eb3b3b]'>
                                    {selectedLanguage?.name == "English" ? "No staff available for this service" : translation[116]?.value ? translation[116]?.value : "No staff available for this service"}.
                                </p>
                            }
                            {service_future_error &&
                                <div className='flex items-center gap-1'>
                                    <Svgs.I />
                                    <p className='text-sm text-[#2f2f2f]'>
                                        {service_future_error}</p>
                                </div>}
                            {booking_error &&
                                <div className='flex items-center gap-1'>
                                    <Svgs.I />
                                    <p className='text-sm text-[#2f2f2f]'>
                                        {booking_error}</p>
                                </div>
                            }

                        </div>}
                </div>
            </div>

            {
                <Popup
                    no
                    size="md"
                    heading="Select Services"
                    open={SelectServices}
                    close={() => setSelectServices(false)}
                >
                    <div className="flex flex-col gap-4 max-h-[40vh]">
                        <LoginInput
                            title=""
                            placeholder="Search"
                            padding="py-1"
                            leftIcon={<Svgs.Search />}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        {FilterDataGroup()?.length > 0 ? (
                            <>
                                {
                                    FilterDataGroup()?.map((srv) => {
                                        return (
                                            <div className='flex flex-col gap-2'>
                                                <h1 className='font-semibold text-[#101928]'>{srv?.name}</h1>
                                                {srv?.services?.map((itm) => {
                                                    // const filteredServices = itm?.priceservice?.filter(itm => itm?.currency_name?.toLowerCase() == location_currency?.toLowerCase()) 
                                                    return (
                                                        <>
                                                            {
                                                                itm?.priceservice?.map((dt) => {
                                                                    return (
                                                                        <>
                                                                            <div
                                                                                className='flex items-center justify-between gap-3 border-b py-2 px-1 hover:bg-gray-50 rounded-md cursor-pointer'
                                                                                onClick={(e) => {
                                                                                    if (typeof handleNewServices === "function") {
                                                                                        handleNewServices(itm, dt)
                                                                                    } else {
                                                                                        handleChange(
                                                                                            {
                                                                                                ...e,
                                                                                                target: {
                                                                                                    ...e.target,
                                                                                                    name: "service",
                                                                                                    value: itm.id
                                                                                                }
                                                                                            },
                                                                                            itm?.client_can_book,
                                                                                            itm?.slot_availible_for_online,
                                                                                            appointmentData?.id,
                                                                                            {
                                                                                                srv_name: itm?.name,
                                                                                                price: dt.price,
                                                                                                srv_duration: dt?.duration.toLowerCase().replaceAll("_", " ")
                                                                                            }

                                                                                        )
                                                                                        setServiceId(itm?.id)
                                                                                    }

                                                                                    setSelectServices(!SelectServices)
                                                                                }
                                                                                }

                                                                            >
                                                                                <div className=''>
                                                                                    <h1 className='text-[#101928]'>{itm?.name}</h1>
                                                                                    <p className='text-xs text-[#949494]'>{dt?.duration.replaceAll("_", " ")}</p>
                                                                                </div>
                                                                                <p className='text-primary text-sm font-semibold'>{locationDetail?.currency?.code} {dt?.price?.toFixed(2)}</p>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </>
                                                    )
                                                })}
                                            </div>

                                        )
                                    })}
                            </>
                        ) : (
                            <div className="flex flex-col gap-4 items-center text-center">
                                <p className="text-sm text-[#000]/40">
                                    You have not added any services yet.
                                    <br className="hidden md:block" />
                                    Click on the button below to add new services
                                </p>
                                <div>
                                    <AddService
                                        formRef={formRef}
                                        setServiceGroup={setServiceGroup}
                                        setSelectServices={setSelectServices}
                                        ServiceType={ServiceType}
                                        setServiceType={setServiceType}
                                        serviceData={serviceData}
                                        isEdit={isEdit}
                                        setService={setService}
                                        serviceHandleChange={serviceHandleChange}
                                        onSubmitService={onSubmitService}
                                        active={active}
                                        onBlurHandler={onBlurHandler}
                                        errors={errors}
                                        removedSelectedEmployee={removedSelectedEmployee}
                                        serviceModal={serviceModal}
                                        setAddService={setAddService}
                                        durationOptions={durationOptions}
                                        setIsEdit={setIsEdit}
                                        setErrors={setErrors}
                                        setIsDelete={setIsDelete}
                                        loading={loading}
                                    // icon={<Svgs.Plus />}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </Popup>
            }

            {
                ServiceGroup && (
                    <AddServiceGroup
                        onClose={() => {
                            setServiceGroup(false);
                        }}
                        open={ServiceGroup}
                        update={false}
                        addSuccess={() => {
                            dispatch(getServiceGroup())
                        }}
                    />
                )
            }
        </>
    )
}

export default AppointmentsDetails