import React from 'react'

const TeamSizeS = () => {
    return (
        <svg width="103" height="93" viewBox="0 0 103 93" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="72.5625" cy="62.3203" r="30.4375" fill="#E4EAF0" />
            <g clipPath="url(#clip0_174_1162)">
                <path d="M41.4284 25.8465C41.2403 25.2808 40.7119 24.9003 40.1158 24.9003C39.5196 24.9003 38.9912 25.2806 38.8031 25.8465C37.7642 28.9659 34.7016 29.0489 34.5825 29.0502C33.819 29.0502 33.1992 29.67 33.1992 30.4335V35.9667C33.1992 40.5869 36.3573 47.0332 41.4991 47.0332C46.6422 47.0332 49.7989 40.587 49.7989 35.9667V30.4335C49.7989 29.67 49.1806 29.0502 48.4156 29.0502C48.356 29.0502 42.4673 28.9632 41.4284 25.8465ZM47.0321 35.9667C47.0321 39.7266 44.5642 44.2666 41.4989 44.2666C38.4336 44.2666 35.9657 39.7266 35.9657 35.9667V31.648C37.3212 31.3408 38.9923 30.569 40.2206 28.9976C42.1587 30.9356 45.2489 31.5415 47.032 31.731V35.9667H47.0321Z" fill="#101928" />
                <path d="M6.79221 29.0448C6.83366 29.0489 6.87654 29.0504 6.91814 29.0504C7.62637 29.0504 8.22956 28.5109 8.29459 27.7916L9.6779 12.5793C9.98635 9.287 14.4116 5.53415 17.9833 5.53415H20.7499C24.3907 5.53415 28.8147 9.27186 29.0539 12.5572L29.2669 15.3238C29.3263 16.0861 29.989 16.6601 30.7525 16.5965C31.5148 16.5384 32.0847 15.8729 32.0252 15.1108L31.8135 12.3498C31.4427 7.27995 25.3189 2.7677 20.7499 2.7677H17.9832C12.9479 2.7677 7.36638 7.58983 6.92229 12.3264L5.53898 27.5427C5.4698 28.3032 6.0301 28.9756 6.79221 29.0448Z" fill="#101928" />
                <path d="M52.2489 16.5965C52.9917 16.6503 53.6751 16.0846 53.7331 15.3238L53.9433 12.5793C54.2518 9.287 58.6756 5.53415 62.2486 5.53415H65.0152C68.656 5.53415 73.0799 9.27186 73.3206 12.5752L74.7039 27.7916C74.7704 28.5109 75.372 29.0504 76.0804 29.0504C76.1218 29.0504 76.1647 29.0489 76.2076 29.0448C76.9684 28.9756 77.5286 28.3034 77.4596 27.5426L76.0777 12.3498C75.7084 7.27995 69.5845 2.7677 65.0154 2.7677H62.2488C57.2135 2.7677 51.632 7.58983 51.1865 12.3444L50.9748 15.111C50.9167 15.8729 51.4866 16.5383 52.2489 16.5965Z" fill="#101928" />
                <path d="M27.6703 44.1601C27.6108 44.9223 28.1821 45.5877 28.9429 45.6457C29.6858 45.7052 30.3706 45.1339 30.4286 44.373L31.8105 26.4122C32.1189 23.1199 36.5427 19.367 40.1159 19.367H42.8825C46.5233 19.367 50.9472 23.1047 51.1865 26.3901L52.5698 44.373C52.6251 45.0979 53.231 45.6499 53.9475 45.6499C53.9821 45.6499 54.0194 45.6484 54.0554 45.6457C54.8163 45.5877 55.3861 44.9222 55.3281 44.1601L53.9448 26.1827C53.5755 21.1128 47.4517 16.6006 42.8825 16.6006H40.1159C35.0806 16.6006 29.4991 21.4227 29.0536 26.1773L27.6703 44.1601Z" fill="#101928" />
                <path d="M63.56 12.0136C63.3719 11.4479 62.8449 11.0674 62.2486 11.0674C61.6524 11.0674 61.124 11.4477 60.9371 12.0136C59.8969 15.133 56.8357 15.2159 56.7153 15.2173C55.9504 15.2173 55.332 15.8371 55.332 16.6006V22.1338C55.332 26.754 58.4887 33.2003 63.6319 33.2003C68.775 33.2003 71.9317 26.7542 71.9317 22.1338V16.6006C71.9317 15.8371 71.3134 15.2173 70.5484 15.2173C70.4889 15.2173 64.6002 15.1301 63.56 12.0136ZM69.1653 22.1338C69.1653 25.8937 66.6974 30.4337 63.632 30.4337C60.5667 30.4337 58.0988 25.8937 58.0988 22.1338V17.8151C59.4531 17.5079 61.1268 16.7361 62.3538 15.1647C64.2918 17.1027 67.382 17.7086 69.1651 17.8982V22.1338H69.1653Z" fill="#101928" />
                <path d="M29.0493 60.8662C28.2858 60.8662 27.666 61.486 27.666 62.2495V78.849C27.666 79.6126 28.2858 80.2323 29.0493 80.2323C29.8129 80.2323 30.4326 79.6126 30.4326 78.849V62.2495C30.4326 61.4858 29.8129 60.8662 29.0493 60.8662Z" fill="#101928" />
                <path d="M48.4561 47.0498C47.687 47.0539 47.0355 47.6543 47.0314 48.429C47.0299 48.7111 46.9346 55.3331 41.4981 55.3331C36.0631 55.3331 35.9664 48.7125 35.9649 48.4345C35.9649 47.671 35.3466 47.0527 34.5843 47.0512C29.5698 47.1066 25.4766 48.4718 22.7585 51.1846C20.57 53.3689 19.3652 56.3153 19.3652 59.483V76.0827C19.3652 76.8462 19.985 77.466 20.7485 77.466C21.5121 77.466 22.1318 76.8462 22.1318 76.0827V59.483C22.1318 57.0553 23.0489 54.8032 24.7116 53.1433C26.6745 51.1831 29.6224 50.0558 33.2909 49.8524C33.6866 53.0023 35.4461 57.2738 40.1147 57.9862V65.0161C40.1147 65.7796 40.7344 66.3994 41.498 66.3994C42.2615 66.3994 42.8813 65.7796 42.8813 65.0161V57.9862C47.5499 57.2738 49.3096 53.0021 49.7051 49.851C53.3736 50.0557 56.3214 51.1831 58.2843 53.1433C59.9485 54.8046 60.8641 57.0553 60.8641 59.483V76.0827C60.8641 76.8462 61.4824 77.466 62.2474 77.466C63.0124 77.466 63.6307 76.8462 63.6307 76.0827V59.483C63.6307 56.3153 62.426 53.3687 60.2389 51.186C57.528 48.4787 53.4555 47.0498 48.4561 47.0498Z" fill="#101928" />
                <path d="M76.0806 47.0332C75.3156 47.0332 74.6973 47.653 74.6973 48.4165V65.016C74.6973 65.7796 75.3156 66.3993 76.0806 66.3993C76.8456 66.3993 77.4639 65.7796 77.4639 65.016V48.4165C77.4639 47.653 76.8456 47.0332 76.0806 47.0332Z" fill="#101928" />
                <path d="M82.6236 37.6102C80.7355 35.6045 77.1153 33.2169 70.5861 33.2169C69.8155 33.2335 69.1682 33.8228 69.164 34.5961C69.1626 34.8782 69.0673 41.5002 63.6308 41.5002C62.0082 41.5002 60.7687 40.9662 59.8434 39.8679C59.3495 39.2841 58.4766 39.2094 57.8929 39.7019C57.3106 40.1958 57.2358 41.0672 57.727 41.651C58.8847 43.0246 60.4327 43.867 62.2477 44.152V48.4167C62.2477 49.1803 62.866 49.8 63.631 49.8C64.3959 49.8 65.0143 49.1803 65.0143 48.4167V44.1535C69.6829 43.4411 71.4411 39.1694 71.838 36.0183C75.6352 36.23 78.6494 37.4252 80.6081 39.507C81.1338 40.0632 82.0094 40.0866 82.5642 39.5652C83.1216 39.0419 83.1466 38.1662 82.6236 37.6102Z" fill="#101928" />
                <path d="M6.91651 47.0332C6.15297 47.0332 5.5332 47.653 5.5332 48.4165V65.016C5.5332 65.7796 6.15297 66.3993 6.91651 66.3993C7.68005 66.3993 8.29982 65.7796 8.29982 65.016V48.4165C8.29982 47.653 7.68005 47.0332 6.91651 47.0332Z" fill="#101928" />
                <path d="M19.2955 12.0136C19.1059 11.4479 18.579 11.0674 17.9828 11.0674C17.3866 11.0674 16.8595 11.4477 16.6701 12.0136C15.6313 15.133 12.5686 15.2159 12.4497 15.2173C11.6862 15.2173 11.0664 15.8371 11.0664 16.6006V22.1338C11.0664 26.754 14.2245 33.2003 19.3662 33.2003C24.508 33.2003 27.6661 26.7542 27.6661 22.1338V16.6006C27.6661 15.8371 27.0463 15.2173 26.2828 15.2173C26.2232 15.2173 20.3343 15.1301 19.2955 12.0136ZM24.8993 22.1338C24.8993 25.8937 22.4314 30.4337 19.3661 30.4337C16.3008 30.4337 13.8329 25.8937 13.8329 22.1338V17.8151C15.1884 17.5079 16.8595 16.7361 18.0878 15.1647C20.0259 17.1027 23.1148 17.7086 24.8992 17.8982V22.1338H24.8993Z" fill="#101928" />
                <path d="M20.7489 48.4165V44.156C22.5375 43.8834 24.0356 43.077 25.2058 41.7283C25.7052 41.1501 25.6429 40.2772 25.0662 39.7764C24.488 39.2757 23.6137 39.3393 23.1143 39.916C22.1902 40.9826 20.9645 41.4998 19.3656 41.4998C13.9305 41.4998 13.8338 34.8793 13.8323 34.6013C13.8309 33.8212 13.1268 33.2195 12.4034 33.218C5.88111 33.218 2.26392 35.6041 0.375618 37.6099C-0.147226 38.1661 -0.120925 39.0417 0.433801 39.5645C0.989961 40.0861 1.86557 40.0625 2.38985 39.5063C4.35003 37.4245 7.3642 36.2293 11.1587 36.0176C11.5543 39.1687 13.3138 43.4404 17.9824 44.1528V48.4161C17.9824 49.1796 18.6022 49.7994 19.3657 49.7994C20.1291 49.7998 20.7489 49.1801 20.7489 48.4165Z" fill="#101928" />
                <path d="M53.9478 60.8662C53.1828 60.8662 52.5645 61.486 52.5645 62.2495V78.849C52.5645 79.6126 53.1828 80.2323 53.9478 80.2323C54.7127 80.2323 55.3311 79.6126 55.3311 78.849V62.2495C55.3311 61.4858 54.7127 60.8662 53.9478 60.8662Z" fill="#101928" />
            </g>
            <defs>
                <clipPath id="clip0_174_1162">
                    <rect width="83" height="83" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default TeamSizeS