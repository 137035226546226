import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import SelectLocation from "Components/TenantApp/Elements/SelectLocation";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Popup from "../../../Elements/Popup";
import Membership from "./Membership";
import Products from "./Products";
import Services from "./Services";
import Vouchers from "./Vouchers";

import { saleOrderTabs, useSaleOrder } from "./helper";

import Svgs from "Assets/svgs";
import LocationSelection from "new-components/location";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import IconButton from "Components/TenantApp/Elements/IconButton";

const SaleOrders = () => {
  const {
    navigate,
    setIsTabName,
    setFilterData,
    setFilterPopup,
    setLocationName,
    setInvoicePopup,
    toggleInviceModal,
    filterDataHandler,
    filterPopDataHandler,
    client,
    business,
    employee,
    isTabName,
    locations,
    paymenTime,
    productList,
    serviceList,
    voucherList,
    FilterPopup,
    paymentDate,
    filter_data,
    InvoicePopup,
    locationName,
    memberShipList,
    selectedInvice,
    businessCurrency,
    filter_pop_data,
    total_tax,
    totalInvoiceBill,
    location_currency,
    lengths,
    setLengths,
  } = useSaleOrder();
  const state = useSelector((state) => state);

  const [Employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">


        <Breadcrumb
          first={'Sales'}
          url={'/dashboard/sales/'}
          second='Sales Orders'
        />

        <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

          <div className="flex items-center gap-4">
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              Sale Orders
            </h2>
            {/* <SelectLocation
              locationList={locations?.locations}
              locationName={locationName}
              setLocationName={setLocationName}
            /> */}
            <LocationSelection />
          </div>
        </div>

        <div className="border-b mt-[3rem]">
          <div className="flex items-center gap-6">
            {saleOrderTabs.map(({ title }, index) => (
              <div
                className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${isTabName === title && "font-semibold border-primary"
                  }`}
                onClick={() => {
                  setIsTabName(title);
                }}
                key={index}
              >
                <h2 className="text-[#595A5A]">{title}</h2>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-[3rem]">
          <div className="border rounded-lg">
            <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
              <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                <div className="flex items-center lg:gap-4 gap-3">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold text-xl">{isTabName}</h2>
                    <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                      <p className="text-primary">
                        {isTabName === "Products" && lengths}
                        {isTabName === "Services" && lengths}
                        {isTabName === "Membership" && lengths}
                        {isTabName === "Vouchers" && lengths}
                        {" " + isTabName}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-00">
                  <LoginInput
                    title=""
                    name={"text"}
                    placeholder="Search"
                    padding="py-1"
                    leftIcon={<Svgs.Search />}
                    value={filter_data?.text}
                    onChange={filterDataHandler}
                  />
                </div>
              </div>
              <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
                <LoginBtn
                  borderBtn
                  
                  onClick={() => {
                    setFilterPopup(!FilterPopup);
                  }}
                >
                  <div className="flex items-center gap-2 text-primary font-semibold">
                    <Svgs.Filters />
                    <p>Filters</p>
                  </div>
                </LoginBtn>
                {!employee ? (
                  <IconButton filled>
                    <Svgs.Export />
                  </IconButton>
                ) : (
                  ""
                )}
              </div>
            </div>
            {isTabName === "Products" && (
              <Products
                filter_data={filter_data}
                openInvoice={setInvoicePopup}
                toggleInviceModal={toggleInviceModal}
                setLengths={setLengths}
              />
            )}
            {isTabName === "Services" && (
              <Services
                filter_data={filter_data}
                openInvoice={setInvoicePopup}
                toggleInviceModal={toggleInviceModal}
                setLengths={setLengths}
              />
            )}
            {isTabName === "Membership" && (
              <Membership
                filter_data={filter_data}
                openInvoice={setInvoicePopup}
                toggleInviceModal={toggleInviceModal}
                setLengths={setLengths}
              />
            )}
            {isTabName === "Vouchers" && (
              <Vouchers
                openInvoice={setInvoicePopup}
                toggleInviceModal={toggleInviceModal}
                filter_data={filter_data}
                setLengths={setLengths}
              />
            )}
          </div>
        </div>
      </div>

      {FilterPopup && (
        <Popup heading="Filter" open={FilterPopup} close={setFilterPopup}>
          <div className="flex flex-col gap-5">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
              <CircleIcon>
                <Svgs.FilterPopup />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  Search By Filters
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Dolorem, nobis.s <br className="hidden md:block" /> Lorem.
                </p>
              </div>
            </div>
            <div className="grid xs:grid-cols-2 gap-5">
              <div>
                <Dropwdown
                  title="Client"
                  placeholder="Select Client"
                  name={"client"}
                  value={filter_pop_data?.client}
                  onChange={filterPopDataHandler}
                  options={client.clients.map((client) => {
                    return {
                      label: client.full_name,
                      value: client.id,
                    };
                  })}
                />
              </div>
              <div>
                <Dropwdown
                  title="Location"
                  placeholder="Select Location"
                  name={"location"}
                  value={filter_pop_data?.location}
                  onChange={filterPopDataHandler}
                  options={business.locations.map((loc) => {
                    return {
                      label: loc.address_name,
                      value: loc.id,
                    };
                  })}
                />
              </div>
              <div>
                <Dropwdown
                  title="Staff"
                  placeholder="Select Staff"
                  name={"member"}
                  value={filter_pop_data?.member}
                  onChange={filterPopDataHandler}
                  options={employee.employees.map((staff) => {
                    return {
                      label: staff.full_name,
                      value: staff.id,
                    };
                  })}
                />
              </div>
              {/* <div>
                                <Dropwdown
                                    title='Service'
                                    placeholder='Select Service'
                                />
                            </div> */}
            </div>
            <LoginBtn
              text="Search"
              onClick={() => {
                setFilterData({
                  ...filter_data,
                  ...filter_pop_data,
                });
                setFilterPopup(false);
              }}
            />
          </div>
        </Popup>
      )}

      {InvoicePopup && (
        <div
          className={`fixed inset-0 h-[100vh] w-[100vw] z-[4444] flex justify-center items-center`}
        >
          <div className="bg-[#101928]/30 absolute inset-0 z-[1]"></div>
          <div
            className={`relative overflow-hidden rounded-2xl lg:w-[26vw] w-[90vw] min-w-[30rem] z-[2] slide-in-elliptic-top-fwd`}
          >
            <div>
              <div className="relative ring-offset-black top-0 left-1/2 -translate-x-1/2 translate-y-1/2 border-2 border-white bg-white rounded-full w-fit">
                <Svgs.CircleCheck size="5rem" />
              </div>
            </div>
            <div className="bg-white rounded-t-2xl shadow-2xl">
              <div className="sticky top-0 z-[22]">
                <div
                  className="absolute top-0 right-0 bg-primary px-[1.6rem] py-[1rem] rounded-bl-2xl rounded-tr-2xl cursor-pointer"
                  onClick={() => toggleInviceModal()}
                >
                  <Svgs.Close />
                </div>
                <h1 className={`p-[1rem] font-semibold text-xl text-left`}></h1>
              </div>
              <div className="p-[1.2rem] overflow-y-auto overflow-x-hidden scroll-hidden max-h-[75vh] text-center flex flex-col gap-5">
                <h1 className="font-bold text-xl">
                  Invoice # {selectedInvice?.id}
                </h1>
                <div className="max-w-[80%] w-fit bg-[#F9F9F9] text-[#7B7B7B] border rounded-md mx-auto p-2 px-4 flex items-center gap-2 text-sm text-center">
                  <div>
                    <p>{paymentDate}</p>
                  </div>
                  <div className="h-[1.5rem] w-[1px] border-l"></div>
                  <div>
                    <p>
                      Billed to{" "}
                      <span className="font-bold">
                        {selectedInvice?.product[0]?.client?.full_name
                          ? selectedInvice?.product[0]?.client?.full_name
                          : "Walk In"}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="text-left flex flex-col gap-1">
                  <div className="flex items-center justify-between text-[#7B7B7B] text-sm">
                    <p>Item</p>
                    <p>Amount</p>
                  </div>
                  {isTabName === "Products"
                    ? selectedInvice?.product?.map((itm) => {
                      return (
                        <div className="flex items-center justify-between text-primary text-sm font-semibold">
                          <p>{itm?.product_name}</p>
                          <p>
                            {itm?.quantity ? itm?.quantity : 1} X{" "}
                            {itm?.product_price} {location_currency}
                          </p>
                        </div>
                      );
                    })
                    : isTabName === "Services" &&
                    typeof selectedInvice?.service == "object" &&
                    selectedInvice?.service?.length > 0 &&
                    selectedInvice?.service?.map((srv) => {
                      return (
                        <div className="flex items-center justify-between text-primary text-sm font-semibold">
                          <p>
                            {srv?.service?.name ? srv?.service?.name : ""}
                          </p>
                          <p>
                            <span className="text-[#7B7B7B]">
                              {srv?.quantity ? srv?.quantity : 1} x
                            </span>
                            {srv?.service?.priceservice[0]?.price}{" "}
                            {location_currency}
                          </p>
                        </div>
                      );
                    })}
                </div>

                <div className="text-left flex flex-col gap-1">
                  <div className="flex items-center justify-between text-primary text-sm font-bold">
                    <p>Subtotal</p>
                    <p>
                      {totalInvoiceBill} {location_currency}
                    </p>
                  </div>
                  {/* <div className="flex items-center justify-between text-[#7B7B7B] text-sm">
                    <p> Taxes({total_tax ? total_tax : 0}%) </p>
                    <p>
                      {Math.round(
                        (total_tax * 50 / 100
                        ))}
                      {" "} {location_currency}
                    </p>
                  </div> */}
                </div>
                <div className="flex items-center justify-between text-primary text-sm font-bold">
                  <p>Total</p>
                  <p className="text-sm">
                    {/* {selectedInvice?.total_price + Math.round((total_tax * selectedInvice?.total_price) / 100)}{" "} {" "} {location_currency} */}
                    {totalInvoiceBill} {location_currency}
                  </p>
                </div>
                <hr />
                <div className="text-left flex flex-col gap-1">
                  <div className="flex items-center justify-between text-primary text-sm font-bold">
                    <p>Payment Method</p>
                  </div>
                  <div className="flex items-center justify-between text-primary text-sm">
                    <p className="text-[#7B7B7B]">
                      {selectedInvice?.payment_type
                        ? selectedInvice?.payment_type
                        : "----"}{" "}
                      &nbsp;Payment on {paymentDate ? paymentDate : "----"} at&nbsp;{" "} {paymenTime ? paymenTime : "----"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SaleOrders;
