import React, { useState, useEffect } from "react";
import moment from 'moment'
import LoginBtn from "../../../Elements/LoginBtn";
import ProgressBar from "../../../Elements/ProgressBar";
import usePOSAnalytics from "./helper";
// import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import Popup from "Components/TenantApp/Elements/Popup";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import DateRangePicker from "Components/TenantApp/Elements/DateRangePicker";
import Svgs from "Assets/svgs";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import SalesAnalyticsCards from "Components/TenantApp/Elements/SalesAnalyticsCards";
import AccordionDropdown from "Components/TenantApp/Elements/AccordionDropdown";
import RadioBtn from "Components/TenantApp/Elements/RadioBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import LineChatCurve from "Components/TenantApp/Elements/Charts/LineChatCurve";
import LineChart from "Components/TenantApp/Elements/Charts/LineChart";
import Donet from "Components/TenantApp/Elements/Charts/Donet";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import LocationSelection from "new-components/location";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import Pagination from "new-components/Pagination";
import { YearsDropdown } from "Constants/Data/Months";

const SalesAnalytics = () => {

    const { translation, selectedLanguage,
        FilterDetails, setFilterDetails,
        salesData, loader, onChangeHandler,
        tableLoader, productAnalytics, pagination,
        Filter, setFilter, setCurrent_page, current_page,
        selected_location, setSelected_location, reduxlocationsList,
        filterData, MonthsDropdown, location_currency,
        onDateRangeHandler, minEndDateLimit, dateRange,
        resetFilters, ApplyFilter
    } = usePOSAnalytics();

    const products = [
        {
            name: "Silk & Shine",
            value: 1508,
        },
        {
            name: "Cosmic Care",
            value: 888,
        },
        {
            name: "Shinewave",
            value: 854,
        },
        {
            name: "LightDew",
            value: 790,
        },
        {
            name: "Nail Care",
            value: 641,
        },
    ]

    return (
        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

            <div className="my-[1rem] flex items-end justify-between gap-4 flex-wrap">
                <div className="flex flex-col items-start gap-2 md:gap-4">
                    <Breadcrumb
                        first={selectedLanguage?.name == "English" ? "POS Analytics" : translation[7]?.value ? translation[7]?.value : "POS Analytics"}
                        second={selectedLanguage?.name == "English" ? "Sales Analytics" : translation[1586]?.value ? translation[1586]?.value : "Sales Analytics"}
                        url={'/dashboard/pos-analytics'}
                    />
                    <div className="flex items-center justify-between gap-4 flex-wrap">
                        <div className="flex items-center sm:gap-4 gap-2">

                            <h2 className="text-primary font-bold lg:text-3xl text-xl">
                                {selectedLanguage?.name == "English" ? "Sales Analytics" : translation[1586]?.value ? translation[1586]?.value : "Sales Analytics"}
                            </h2>
                            <LocationSelection />
                            {/* <Dropwdown
                                className={'w-[10rem] lg:w-[14rem]'}
                                showTitle={false}
                                name={"selected_location"}
                                value={selected_location}
                                onChange={(e) => { setSelected_location(e.target.value) }}
                                options={[
                                    { label: selectedLanguage?.name == "English" ? "All Locations" : translation[687]?.value ? translation[687]?.value : "All Locations", value: "" },
                                    ...reduxlocationsList?.map((adrs) => {
                                        return {
                                            label: adrs?.address_name,
                                            value: adrs?.id,
                                        };
                                    })]}
                                title={''}
                                placeholder={selectedLanguage?.name == "English" ? "All Locations" : translation[687]?.value ? translation[687]?.value : "All Locations"}
                            /> */}
                        </div>

                    </div>
                </div>
                <div className="flex items-center sm:gap-4 gap-2">
                    <Dropwdown
                        className={'w-[7rem]'}
                        name="type"
                        title=""
                        showTitle={false}
                        placeholder={selectedLanguage?.name == "English" ? "Select" : translation[1587]?.value ? translation[1587]?.value : "Select"}
                        options={[
                            { value: 'today', label: 'Today' },
                            { value: 'month', label: 'Monthly' },
                            { value: 'year', label: 'Yearly' },
                        ]}
                        // onBlur={() => onBlurHandler({ country_name: country_name.value })}
                        value={filterData?.type}
                        onChange={onChangeHandler}
                    // error={errors.country_name}
                    />
                    {filterData?.type != 'today'
                        ? <>
                            <Dropwdown
                                placeholder={selectedLanguage?.name == "English" ? "Select" : translation[1587]?.value ? translation[1587]?.value : "Select"}
                                className={'w-[7rem]'}
                                name="year"
                                title=""
                                showTitle={false}
                                options={YearsDropdown}
                                onChange={onChangeHandler}
                                value={filterData?.year}
                            />
                            {filterData?.type == 'month' &&
                                <Dropwdown
                                    placeholder={selectedLanguage?.name == "English" ? "Select" : translation[1587]?.value ? translation[1587]?.value : "Select"}
                                    className={'w-[7rem]'}
                                    name="month"
                                    title=""
                                    showTitle={false}
                                    options={MonthsDropdown}
                                    onChange={onChangeHandler}
                                    value={filterData?.month}
                                />
                            }
                        </>
                        : ''}
                    {/* <LoginBtn
                        className="rounded-lg border-2 border-primary"
                        bg="bg-white"
                        animation={false}
                        onClick={() => setFilter(!Filter)}
                    >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                            <Svgs.DateFilter fill={"#fff"} />
                        </div>
                    </LoginBtn> */}
                </div>
            </div>

            <div className="border-t py-5 md:py-10 flex flex-col gap-5 md:gap-10">
                <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-5 md:gap-10">
                    {loader
                        ? [1, 2, 3, 4, 5]?.map(itm => { return <SalesAnalyticsCards animate /> })
                        : <>
                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={(salesData?.sales_cards?.avg_sale)?.toFixed(2)}
                                subvalueType={"positive"}
                                subvalue={"+11.01"}
                                currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Average Sales" : translation[1590]?.value ? translation[1590]?.value : "Average Sales"}
                                graph={<Svgs.NewGraphUp />}
                            />
                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={(salesData?.sales_cards?.appointment_average)?.toFixed(2)}
                                subvalueType={"positive"}
                                subvalue={"+15.01"}
                                currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"}
                                graph={<Svgs.NewGraphUp />}
                            />
                            {/* <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={salesData?.sales_cards?.total_returning_customer}
                                subvalueType={"positive"}
                                subvalue={"+6.01"}
                                text={selectedLanguage?.name == "English" ? "Returning client" : translation[1592]?.value ? translation[1592]?.value : "Returning client"}
                                graph={<Svgs.NewGraphUp />}
                            /> */}
                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={salesData?.sales_cards?.total_refund_amount?.toFixed(2)}
                                subvalueType={"positive"}
                                subvalue={"+6.01"}
                                currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Refund Amount" : translation[1727]?.value ? translation[1727]?.value : "Refund Amount"}
                                graph={<Svgs.NewGraphUp />}
                            />
                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={salesData?.sales_cards?.total_client_sale?.toFixed(2)}
                                subvalueType={"positive"}
                                subvalue={"+6.01"}
                                currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Total Client Sales" : translation[1740]?.value ? translation[1740]?.value : "Total Client Sales"}
                                graph={<Svgs.NewGraphUp />}
                            />
                            <SalesAnalyticsCards
                                icon={<Svgs.DollarSign />}
                                value={salesData?.sales_cards?.total_welkin_sale?.toFixed(2)}
                                subvalueType={"positive"}
                                subvalue={"+6.01"}
                                currency={location_currency}
                                text={selectedLanguage?.name == "English" ? "Total Walk In Sales" : translation[1741]?.value ? translation[1741]?.value : "Total Walk In Sales"}
                                graph={<Svgs.NewGraphUp />}
                            />
                        </>
                    }
                </div>
                <div className="grid sm:grid-cols-2 xl:grid-cols-6 gap-5 md:gap-10">
                    <div className="flex flex-col gap-5 sm:gap-0 xl:gap-10 h-auto xl:col-span-2 py-3 xl:py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex flex-col gap-1">
                            <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Total Sales" : translation[146]?.value ? translation[146]?.value : "Total Sales"}</h4>
                            <h5 className="text-[#484848] font-bold text-2xl">{(salesData?.sales_progress?.total_sale)?.toFixed(2)} {location_currency}</h5>
                        </div>
                        <div className="flex flex-col gap-3 xl:gap-5 py-3 xl:py-5">
                            {loader
                                ? [0, 1, 2, 3, 4]?.map(itm => { return <ProgressBar xl={true} animate /> })
                                : <>
                                    <ProgressBar
                                        xl={true}
                                        progressWidth={(salesData?.sales_progress?.service?.service_total_sale !== 0 || salesData?.sales_progress?.service?.total_service_target !== 0) ? ((salesData?.sales_progress?.service?.service_total_sale * 100) / salesData?.sales_progress?.service?.total_service_target > 100) ? 100 : ((salesData?.sales_progress?.service?.service_total_sale * 100) / salesData?.sales_progress?.service?.total_service_target) : 0}
                                        heading={"Services"}
                                        value={`${salesData?.sales_progress?.service?.service_total_sale?.toFixed(2)} ${location_currency}`}
                                    />
                                    <ProgressBar
                                        xl={true}
                                        progressWidth={(salesData?.sales_progress?.product?.total_retail_target !== 0 || salesData?.sales_progress?.product?.product_total_sale !== 0) ? ((salesData?.sales_progress?.product?.product_total_sale * 100) / salesData?.sales_progress?.product?.total_retail_target > 100) ? 100 : (salesData?.sales_progress?.product?.product_total_sale * 100) / salesData?.sales_progress?.product?.total_retail_target : 0}
                                        heading={"Products"}
                                        value={`${salesData?.sales_progress?.product?.product_total_sale?.toFixed(2)} ${location_currency}`}
                                    />
                                    <ProgressBar
                                        xl={true}
                                        progressWidth={`${(salesData?.sales_progress?.vouchers_total_sale) > 0 ? '100' : '0'}`}
                                        heading={"Vouchers"}
                                        value={`${salesData?.sales_progress?.vouchers_total_sale?.toFixed(2)} ${location_currency}`}
                                    />
                                    <ProgressBar
                                        xl={true}
                                        progressWidth={`${(salesData?.sales_progress?.membership_total_sale) > 0 ? '100' : '0'}`}
                                        heading={"Membership"}
                                        value={`${salesData?.sales_progress?.membership_total_sale?.toFixed(2)} ${location_currency}`}
                                    />
                                    <ProgressBar
                                        xl={true}
                                        progressWidth={`${(salesData?.sales_progress?.gift_card_total_sale) > 0 ? '100' : '0'}`}
                                        heading={"Gift Card"}
                                        value={`${salesData?.sales_progress?.gift_card_total_sale?.toFixed(2)} ${location_currency}`}
                                    />
                                </>
                                // (salesData?.sales_progress?.product?.product_total_sale*100)/salesData?.sales_progress?.product?.total_retail_target
                            }
                        </div>
                    </div>
                    <div className="flex flex-col gap-5 xl:gap-10 justify-between h-auto xl:col-span-4 py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex justify-between items-center">
                            <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Total Sales" : translation[146]?.value ? translation[146]?.value : "Total Sales"}</h4>
                            <div className="flex items-center gap-4">
                                <div className="flex items-center gap-1">
                                    <div className="h-2 w-2 rounded-full bg-[#587AFE]"></div>
                                    <p className="text-sm text-[#1C1C1C]">Current Year</p>
                                </div>
                                <div className="flex items-center gap-1">
                                    <div className="h-2 w-2 rounded-full bg-[#556E94]"></div>
                                    <p className="text-sm text-[#1C1C1C]">Previous Year</p>
                                </div>
                            </div>
                        </div>
                        <div className="h-auto w-full overflow-hidden">
                            <LineChatCurve fill={true} chartData1={salesData?.total_sale_chart?.current_year_sales} chartData2={salesData?.total_sale_chart?.previous_year_sales} chartData1Color={'#5679FF'} chartData2Color={'#556E94'} />
                        </div>
                    </div>
                </div>
                <div className="grid sm:grid-cols-2 gap-5 md:gap-10">
                    <div className="flex flex-col gap-10 h-auto 3xl:h-[25rem] py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-1">
                                <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Sales channel" : translation[1588]?.value ? translation[1588]?.value : "Sales channel "} POS</h4>
                                <h5 className="text-[#484848] font-bold text-2xl">{(salesData?.sales_channel_pos?.total_pos_sale)?.toFixed(2)} {location_currency}</h5>
                            </div>
                            {/* <div className="flex items-center gap-1 text-[#00C287]">
                                <div className="border border-[#00C287] rounded-full p-1"><Svgs.UpArrow size={"10"} fill={"#00C287"} /></div>
                                <p className="text-sm font-semibold">6.2%</p>
                            </div> */}
                        </div>
                        <div className="h-auto w-full overflow-hidden">
                            <LineChart chartData={salesData?.sales_channel_pos?.current_year_pos_sale} />
                        </div>
                    </div>
                    <div className="min-h-[20rem] py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5 flex flex-col justify-between gap-2">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-1">
                                <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"}</h4>
                                {/* <h5 className="text-[#484848] font-bold text-2xl">82%</h5> */}
                            </div>
                            {/* <div className="flex items-center gap-1 text-[#BC2323]">
                                <div className="border border-[#BC2323] rounded-full p-1 rotate-180"><Svgs.UpArrow size={"10"} fill={"#BC2323"} /></div>
                                <p className="text-sm font-semibold">6.2%</p>
                            </div> */}
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <div className=" xl:h-[170px] xl:w-[170px] lg:h-[130px] lg:w-[130px] overflow-hidden">
                                <Donet appointment_progress={salesData?.appointment_progress}
                                    chartData={[salesData?.appointment_progress?.finished_appointment > 0 ? salesData?.appointment_progress?.finished_appointment : (salesData?.appointment_progress?.cancel_appointment > 0 ? 0 : 1), salesData?.appointment_progress?.cancel_appointment > 0 ? (salesData?.appointment_progress?.cancel_appointment) : (salesData?.appointment_progress?.finished_appointment > 0 ? 0 : 1)]}
                                    chartDataColors={['#3856C9', '#AFCDEE']}
                                    circleCutout={'80%'} />
                            </div>
                        </div>
                        <div className="flex items-center justify-between w-full">
                            <div className="flex items-center gap-1 text-xl font-medium"><span className="w-8 h-4 rounded-full bg-[#3856C9]"></span><span>{(salesData?.appointment_progress?.finished_appointment != 0 && salesData?.appointment_progress?.total_appointment != 0) ? (((salesData?.appointment_progress?.finished_appointment * 100) / salesData?.appointment_progress?.total_appointment)?.toFixed(2)) : 0}%</span><span>{selectedLanguage?.name == "English" ? "Completed" : translation[322]?.value ? translation[322]?.value : "Completed"}</span></div>
                            <div className="flex items-center gap-1 text-xl font-medium"><span className="w-8 h-4 rounded-full bg-[#AFCDEE]"></span><span>{(salesData?.appointment_progress?.cancel_appointment != 0 && salesData?.appointment_progress?.total_appointment != 0) ? (((salesData?.appointment_progress?.cancel_appointment * 100) / salesData?.appointment_progress?.total_appointment)?.toFixed(2)) : 0}%</span><span>{selectedLanguage?.name == "English" ? "Cancelled" : translation[1589]?.value ? translation[1589]?.value : "Cancelled"}</span></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="border rounded-lg">
                <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b gap-5">

                    <div className="flex items-center sm:gap-4 gap-2 ">
                        <h2 className="font-semibold text-xl">{selectedLanguage?.name == "English" ? "Search Result" : translation[1143]?.value ? translation[1143]?.value : "Search Result"}</h2>
                    </div>

                    <div className="flex items-center gap-5">
                        <div className='relative flex items-center gap-3 w-full z-[1000]'>
                            <div className={`border text-sm rounded-md font-semibold flex items-center w-full`}>
                                <div className='p-2 relative gap-4 flex items-center whitespace-nowrap w-full'>
                                    <p
                                        onClick={() => { setToggleDateRange(!toggleDateRange) }}
                                        className='cursor-pointer xl:text-xs w-full'>
                                        {start_date && end_date ?
                                            `${start_date
                                                ? moment(start_date).format("DD-MM-YYYY") : "--/--/----"} - ${end_date ? moment(end_date).format("DD-MM-YYYY")
                                                    : "--/--/----"
                                            }`
                                            : '--/--/---- - --/--/----'}
                                    </p>
                                    <span className='cursor-pointer ' onClick={() => {
                                        setStartDate()
                                        setEndDate()
                                    }}>
                                        {selectedLanguage?.name == "English" ? "Clear" : translation[340]?.value ? translation[340]?.value : "Clear"}
                                    </span>

                                    {(toggleDateRange) && <>
                                        <div className='absolute inset-0 top-0 left-0 w-[95%] mb-4' onClick={() => { setToggleDateRange(false) }}></div>
                                        <div className={`absolute top-0 right-0 mb-4`}>
                                            <DateRangePicker
                                                start={start_date}
                                                end={end_date}
                                                setStartDate={setStartDate}
                                                setEndDate={setEndDate}
                                                setToggleDateRange={setToggleDateRange}
                                            />
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                        <LoginBtn>
                            <Svgs.Import fill="#fff" width="1.5rem" height="1.5rem" />
                        </LoginBtn>

                    </div>
                </div>

                <div className="overflow-x-auto">
                    <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] px-12 py-3 bg-[#F3F6FF] text-sm font-semibold min-w-full w-fit">
                        <p>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</p>
                        <p>{selectedLanguage?.name == "English" ? "Channel" : translation[1582]?.value ? translation[1582]?.value : "Channel"} </p>
                        <p>{selectedLanguage?.name == "English" ? "Product" : translation[1316]?.value ? translation[1316]?.value : "Product"}</p>
                        <p>{selectedLanguage?.name == "English" ? "Quantity" : translation[365]?.value ? translation[365]?.value : "Quantity"}</p>
                        <p>{selectedLanguage?.name == "English" ? "Promotion" : translation[1583]?.value ? translation[1583]?.value : "Promotion"}</p>
                        <p>{selectedLanguage?.name == "English" ? "Tax Type" : translation[1584]?.value ? translation[1584]?.value : "Tax Type"}</p>
                    </div>
                    <div className={` text-[#3A3A3A] transition-all border-l-[0px]`} >
                        {tableLoader
                            ? <TableShimer cols={6} />
                            : productAnalytics?.length > 0
                                ? productAnalytics?.map((prdct, i) => {
                                    return (
                                        <div key={i} className="even:bg-[#FBFCFF] px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] min-w-full w-fit">
                                            <div>
                                                {prdct.created_at ? moment(prdct.created_at).format('YYYY-MM-DD') : '--------'}
                                            </div>
                                            <div>
                                                {prdct.channel ? prdct.channel : '--------'}
                                            </div>

                                            <div>
                                                {prdct.product_name ? prdct.product_name : '--------'}
                                            </div>
                                            <div>
                                                {prdct.quantity ? prdct.quantity : '--------'}
                                            </div>

                                            <div>
                                                {prdct.promotion ? prdct.promotion : '--------'}
                                            </div>
                                            <div>
                                                {prdct.tax_type ? prdct.tax_type : '--------'}
                                            </div>

                                        </div>
                                    );
                                })
                                : ''
                        }
                    </div>
                </div>
                {(productAnalytics?.length > 0 && !tableLoader)
                    ? <Pagination
                        currentPage={current_page}
                        totalCount={Math.ceil(pagination?.total_pages)}
                        pageSize={10}
                        onPageChange={page => setCurrent_page(page)}
                    />
                    : ''
                }
            </div> */}

            <Popup
                // size={"lg"}
                heading={selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}
                open={Filter}
                close={setFilter}
                customPadding={"!p-0"}
            >
                <div className="flex flex-col w-full">
                    {/* <div className="grid lg:grid-cols-2 transition-all duration-300 max-h-[35rem] xl:max-h-[27rem] xl:min-h-[27rem] overflow-y-auto">
                        <div className="">

                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Channel" : translation[233]?.value ? translation[233]?.value : "Channel"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, channel: !FilterDetails?.channel })}
                                    checked={FilterDetails?.channel}
                                />
                                {FilterDetails?.channel &&
                                    <div className="transition-all py-6 px-6">
                                        <RadioBtn
                                            text={"POS"}
                                            name="pos"
                                            id={'pos'}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, product: !FilterDetails?.product })}
                                    checked={FilterDetails?.product}
                                />
                                {FilterDetails?.product &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6">
                                        <div className="flex flex-col gap-2">
                                            <p className="text-[#000] text-base font-medium">Search</p>
                                            <LoginInput
                                                title=''
                                                placeholder={"Search products here..."}
                                                padding='py-1'
                                                leftIcon={<Svgs.Search />}
                                            // value={search}
                                            // onChange={searchfunction}
                                            />
                                        </div>
                                        <hr className="border-[rgba(86,121,255,.2)]" />
                                        {
                                            products?.map((product) => {
                                                return (
                                                    <div className="flex items-center justify-between">
                                                        <RadioBtn
                                                            text={product?.name}
                                                            name={product?.name}
                                                            id={product?.name}
                                                        // checked={audience === 'public'}
                                                        // value={audience}
                                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                                        />
                                                        <div className="h-6 w-16 bg-[#DDE4FF] rounded-2xl text-[#5679FF] text-sm flex justify-center items-center">{product?.value}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Service" : translation[361]?.value ? translation[361]?.value : "Service"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, service: !FilterDetails?.service })}
                                    checked={FilterDetails?.service}
                                />
                                {FilterDetails?.service &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6">
                                        <div className="flex flex-col gap-2">
                                            <p className="text-[#000] text-base font-medium">Search</p>
                                            <LoginInput
                                                title=''
                                                placeholder={"Search services here..."}
                                                padding='py-1'
                                                leftIcon={<Svgs.Search />}
                                            // value={search}
                                            // onChange={searchfunction}
                                            />
                                        </div>
                                        <hr className="border-[rgba(86,121,255,.2)]" />
                                        {
                                            products?.map((product) => {
                                                return (
                                                    <div className="flex items-center">
                                                        <RadioBtn
                                                            text={product?.name}
                                                            name={product?.name}
                                                            id={product?.name}
                                                        // checked={audience === 'public'}
                                                        // value={audience}
                                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Promotion" : translation[1583]?.value ? translation[1583]?.value : "Promotion"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, promotion: !FilterDetails?.promotion })}
                                    checked={FilterDetails?.promotion}
                                />
                                {FilterDetails?.promotion &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6">
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"}
                                            name={"voucher"}
                                            id={"voucher"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership"}
                                            name={"membership"}
                                            id={"membership"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Coupon" : translation[1566]?.value ? translation[1566]?.value : "Coupon"}
                                            name={"coupon"}
                                            id={"coupon"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Loyalty Points" : translation[447]?.value ? translation[447]?.value : "Loyalty Points"}
                                            name={"loyaltyPoints"}
                                            id={"loyaltyPoints"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />

                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Appointment Status" : translation[1585]?.value ? translation[1585]?.value : "Appointment Status"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, appointment_status: !FilterDetails?.appointment_status })}
                                    checked={FilterDetails?.appointment_status}
                                />
                                {FilterDetails?.appointment_status &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6">
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Started" : translation[469]?.value ? translation[469]?.value : "Started"}
                                            name={"started"}
                                            id={"started"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Completed" : translation[322]?.value ? translation[322]?.value : "Completed"}
                                            name={"completed"}
                                            id={"completed"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Void" : translation[1339]?.value ? translation[1339]?.value : "Void"}
                                            name={"void"}
                                            id={"void"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Canceled" : translation[1589]?.value ? translation[1589]?.value : "Canceled"}
                                            name={"canceled"}
                                            id={"canceled"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Tax Type" : translation[1584]?.value ? translation[1584]?.value : "Tax Type"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, tax_type: !FilterDetails?.tax_type })}
                                    checked={FilterDetails?.tax_type}
                                />
                                {FilterDetails?.tax_type &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6">
                                        <RadioBtn
                                            text={"GST"}
                                            name={"GST"}
                                            id={"GST"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={"VAT"}
                                            name={"VAT"}
                                            id={"VAT"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={"GST+VAT"}
                                            name={"GST+VAT"}
                                            id={"GST+VAT"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />

                                    </div>
                                }
                            </div>
                        </div>
                        <div className="lg:border-l lg:border-[#E5E7EB]">
                            <div className="px-6 flex flex-col gap-3 pt-5 lg:pt-3">

                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Channel" : translation[233]?.value ? translation[233]?.value : "Channel"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">POS </h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">LightDew</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Service" : translation[361]?.value ? translation[361]?.value : "Service"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">Hair Cutting</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Promotion" : translation[1583]?.value ? translation[1583]?.value : "Promotion"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">Voucher</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Appointment Status" : translation[1585]?.value ? translation[1585]?.value : "Appointment Status"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">Completed</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Tax Type" : translation[1584]?.value ? translation[1584]?.value : "Tax Type"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">GST</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="flex flex-col gap-2 py-4 px-6">
                        <div className='flex flex-col gap-4'>
                            <div className='grid grid-cols-2 gap-3'>
                                <LoginInput
                                    title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                    name="start_date"
                                    value={dateRange?.start_date}
                                    type="date"
                                    onChange={onDateRangeHandler}
                                />
                                <LoginInput
                                    title={selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                                    name="end_date"
                                    value={dateRange?.end_date}
                                    type="date"
                                    onChange={onDateRangeHandler}
                                    min={minEndDateLimit}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end items-end gap-2 py-4 px-6">
                        <LoginBtn onClick={resetFilters} className="!px-6" borderBtn text={selectedLanguage?.name == "English" ? "RESET" : translation[1141]?.value ? translation[1141]?.value : "RESET"} />
                        <LoginBtn onClick={ApplyFilter} text={selectedLanguage?.name == "English" ? "APPLY FILTER" : translation[595]?.value ? translation[595]?.value : "APPLY FILTER"} />
                    </div>
                </div>

            </Popup>
        </div>
    )
}

export default SalesAnalytics