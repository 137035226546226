import axios from "axios";
import { get_client_group_url, add_client_group_url, delete_client_group_url, update_client_group_url, BASE_URL } from "../api_variables";
import { toast } from 'react-toastify';
import { getClientGroups, getClientGroupsWithoutPagination } from "../../../Redux/TenantRedux/Actions/clientGroupActions";


export const getClientGroup = (currentPage, search_text, setLoader) => async (dispatch) => {
    setLoader && setLoader(true)
    const page = currentPage ? `?page=${currentPage}` : ''
    const search = search_text ? `&search_text=${search_text}` : ''
    try {
        const response = await axios.get(`${BASE_URL}${get_client_group_url}${page}${search}`);
        if (response.status === 200) {
            dispatch(getClientGroups(response?.data));
            setLoader && setLoader(false)
        } else {
            setLoader && setLoader(false)
            toast.error("Error in get client group", { toastId: "toast" })
        }
    } catch (error) {
        return error;
    }
};


export const getClientGroupWithNoPagination = (search_text, setLoader) => async (dispatch) => {
    setLoader && setLoader(true)
    const search = search_text ? `&search_text=${search_text}` : ''
    try {
        const response = await axios.get(`${BASE_URL}${get_client_group_url}?no_pagination=true${search}`);
        if (response.status === 200) {
            dispatch(getClientGroupsWithoutPagination(response?.data));
            setLoader && setLoader(false)
        } else {
            // toast.error("Error in get client group", { toastId: "toast" })
            setLoader && setLoader(false)
        }
    } catch (error) {
        return error;
    }
};

export const createClientGroup = async (client_group_data, access_token) => {
    try {
        const result = await axios.post(`${BASE_URL}${add_client_group_url}`, client_group_data,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );
        return result;
    } catch (error) {
        return error;
    }

};


export const updateClientGroup = async (client_group_data, access_token) => {
    let result = await fetch(`${BASE_URL}${update_client_group_url}`, {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Token ${access_token}`
        },
        body: JSON.stringify(client_group_data),

    })
    result = result.json();
    return result;
}


export const deleteClientGroup = async (client_group_id, access_token) => {
    try {
        let result = await fetch(`${BASE_URL}${delete_client_group_url}`, {
            method: "DELETE",
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Token ${access_token}`
            },
            body: JSON.stringify(
                { client_group_id: client_group_id }
            )
        })
        result = result.json();
        return result;

    } catch (error) {
        return error;
    }

}


