
import axios from "axios";
import { BASE_URL, create_cashback, update_cashback, get_cashback, delete_cashback } from "../api_variables";
import { toast } from 'react-toastify';
import { CLIENT } from "Redux/TenantRedux/ActionTypes/clientActionTypes";
import { apiErrorMessage } from "Constants/Data/Errors";

export const getCashBack = (access_token, setLoader) => dispatch => {
    let s_code;
    setLoader && setLoader(true)
    fetch(`${BASE_URL}${get_cashback}`,
        {
            headers: {
                Authorization: `Token ${access_token}`,
            },
        })
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                setLoader && setLoader(false)
                let data = result?.data;
                dispatch({
                    type: CLIENT.GET_CASHBACK,
                    payload: data
                })
            }
            else {
                toast.error(apiErrorMessage, { toastId: "toast" })
                setLoader && setLoader(false)
            }
        })
};



export const createCashBack = (data, access_token, success, fail) => async dispatch => {
    try {
        const result = await axios.post(`${BASE_URL}${create_cashback}`, data,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );
        if (result?.data?.status_code === 200) {
            // dispatch({
            //     type: CLIENT.CREATE_CASHBACK,
            //     payload: result?.data?.data
            // })
            success && success(result?.data?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()

    }
};



export const updateCashBack = (data, access_token, success, fail) => async dispatch => {
    try {
        const result = await axios.put(`${BASE_URL}${update_cashback}`, { ...data },
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });
        console.log(result, "result")
        if (result?.data?.status_code === 200) {
            // dispatch({
            //     type: CLIENT.UPDATE_CASHBACK,
            //     payload: result?.data?.response?.voucher
            // })
            success && success(result?.data?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
};


export const deleteCashBack = (id, access_token, success, fail) => dispatch => {
    fetch(`${BASE_URL}${delete_cashback}`, {
        method: "DELETE",
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Token ${access_token}`
        },
        body: JSON.stringify(
            { id: id }
        )
    }).then((result) => {
        return result.json()
    }).then((response) => {
        console.log(response, "response")
        if (response?.status_code === 200) {
            // dispatch({
            //     type: CLIENT?.DELETE_CASHBACK,
            //     payload: id
            // });
        }
        success && success(response?.message);
    }).catch((err) => {
        fail && fail()
    })

}

