import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Sweepings from "Components/NstyleApp/Sweepings";
import LoginSection from "Components/TenantApp/Elements/LoginSection";
import SocialMediaPlatform from "Components/NstyleApp/SocialMediaPlatforms";
import ChooseSectionLogin from "Components/TenantApp/Elements/ChooseSectionLogin";

import { LogoSvg } from "Assets/svgs/Logo/LogoSvg";
import useClientUrlFromCreatedLink from "Hooks/useClientUrlFromCreatedLink";

const LoginScreen = () => {
  const state = useSelector((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [section, setSection] = useState(2);
  const { nexts, hash, token, domain } = useClientUrlFromCreatedLink();

  // const nexts = searchParams.get("next");
  // const hash = searchParams.get("hash");
  // const token = searchParams.get("token");
  // const domain = searchParams.get("domain");
  const account_type = searchParams.get("account_type");

  useEffect(() => {
    if (!account_type) {
      navigate(`/auth/account-type`);
    }
  }, [])

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span className="${className} paggination"></span>`;
    },
  };



  return (
    <div className="min-h-[100vh] grid grid-cols-1 lg:grid-cols-2">
      <div className="flex flex-col lg:pt-[3rem] lg:px-[4rem] px-8 py-5">
        <div className="flex items-center justify-between">
          <LogoSvg />
          <p className="text-[#7B7B7B] text-sm lg:text-left text-right">
            Don't have an account?&nbsp;
            <span
              className="text-primary cursor-pointer font-semibold transition-all hover:underline"
              onClick={() => {
                if (account_type === "everyone") {
                  if (nexts && hash) {
                    navigate(`/auth/signup/?account_type=everyone&next=${nexts}&hash=${hash}&token=${token}&domain=${domain}`);
                  } else {
                    navigate(`/auth/signup/?account_type=everyone`);
                  }
                } else {
                  navigate("/auth/signup/?account_type=business");
                }

                // if (nexts) {
                //   navigate(
                //     `/auth/account-type/?next=${nexts}&hash=${hash}&token=${token}&domain=${domain}`
                //   );
                // } else {
                //   navigate("/auth/account-type");
                // }
              }}
            >
              Signup Now
            </span>
          </p>
        </div>
        {section === 1 ? (
          <ChooseSectionLogin
            section={setSection}
            className="slide-in-bottom"
          />
        ) : (
          <LoginSection className="slide-in-bottom" />
        )}
      </div>
      <div className="hidden lg:block" style={{
        backgroundImage: 'linear-gradient(to bottom left, rgba(166,77,255,1), rgba(54,67,120,1), rgba(54,67,120,1), rgba(87,120,255,1))'
      }}>
        <div className="px-8 py-5 lg:px-[4rem] lg:pt-[3rem] flex flex-col h-full">
          <div className="flex justify-end text-white"><span className="cursor-pointer" onClick={() => {
            navigate('/help')
          }}>Help?</span></div>
          <Sweepings />
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
