import { get_discount_promotions } from "Adapters/Api/clientDiscountPromotion";
import Popup from "Components/TenantApp/Elements/Popup";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DiscountPromotion from "./index";
import PromotionCardShimmer from "Components/Utility/Shimmers/PromotionCardShimmer";

const DiscountPopup = ({
  selectCallback,
  availOfferPopup,
  setAvailOfferPopup,
  appointmentData,
  selected,
  serviceGroupRedux,
  brandRedux,
  productRedux,
  servicesRedux,
  clientRedux,
  SetData,
  mainData,
  setSelServGroups,
  select_service_serviceGroups,
  MODULE_TYPE,
  setServiceData,
  setProductsData,
}) => {

  const state = useSelector(state => state)
  const dispatch = useDispatch()

  const [promotions_data, setPromotionsData] = useState({})
  const [promotions_updated, setPromotionsUpdated] = useState(false)
  const [not_found, setNotFound] = useState(false)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  useEffect(() => {
    let date = appointmentData?.appointment_date
    let day = appointmentData?.selected_day

    if (!date) {
      let new_date = new Date()
      let today_year = new_date.getFullYear()
      let today_month = new_date.getMonth()
      let today_date = new_date.getDate()
      date = `${today_year}-${today_month + 1}-${today_date}`
    }

    if (!day) {
      day = ''
    }

    dispatch(
      get_discount_promotions(
        {
          selected_date: date,
          selected_day: day
        },
        (result) => {
          let MODULES = {
            'SERVICE': ['directFlat', 'specificCategoryGroup', 'get_free_item_for_other_purchase', 'bundleDiscount', 'fixedPrice', 'specificBrandServiceGroup', 'purchase', 'spendSomeAmount', 'retailPromotion', 'userRestricted'],
            'APPOINTMENT': ['directFlat', 'specificCategoryGroup', 'get_free_item_for_other_purchase', 'bundleDiscount', 'fixedPrice', 'specificBrandServiceGroup', 'purchase', 'spendSomeAmount', 'userRestricted', 'complimentaryVoucher', 'packages',],
            'PRODUCT': ['directFlat', 'specificCategoryGroup', 'specificBrandServiceGroup', 'purchase', 'retailPromotion', 'userRestricted',],
          }
          let any_1_exist = false

          if (result) {
            let allowed_offers = MODULES[MODULE_TYPE]
            allowed_offers?.forEach(offr => {
              if (result[offr]?.length > 0) {
                any_1_exist = true
              }
            })
          }
          else {
            any_1_exist = false
          }
          if (any_1_exist) {
            setPromotionsUpdated(true)
            setPromotionsData(result)
          }
          else {
            setPromotionsUpdated(true)
            setNotFound(true)
          }
        },
        () => {
          setPromotionsUpdated(true)
          setNotFound(true)
        }
      )
    )
  }, [])

  // console.log(state.clientDiscountPromotion.avail_offers)
  // console.log(promotions_data)

  return (
    <>
      <Popup
        no
        size="md"
        heading={selectedLanguage?.name == "English" ? "Available Offer" : translation[1723]?.value ? translation[1723]?.value : "Available Offer"}

        open={availOfferPopup}
        close={setAvailOfferPopup}
      >
        {
          promotions_updated ?
            <>
              <DiscountPromotion
                promotions={promotions_data}
                setServiceData={setServiceData}
                MODULE_TYPE={MODULE_TYPE}
                SetData={(all_data, extra_data) => {
                  SetData(all_data, extra_data)
                  setAvailOfferPopup(false)
                }}
                selectedLanguage={selectedLanguage}
                translation={translation}
                setProductsData={setProductsData}
                setAvailOfferPopup={setAvailOfferPopup}
                setSelServGroups={setSelServGroups}
                select_service_serviceGroups={select_service_serviceGroups}
                mainData={mainData}
                selected={selected}
                serviceGroupRedux={serviceGroupRedux}
                brandRedux={brandRedux}
                productRedux={productRedux}
                servicesRedux={servicesRedux}
                clientRedux={clientRedux}
                appointmentData={appointmentData}
                isDelete={false}
                setNotFound={setNotFound}
              />

            </>
            :
            <>
              <PromotionCardShimmer />
              <PromotionCardShimmer />
            </>
        }
      </Popup>
    </>
  );
};


DiscountPopup.defaultProps = {
  SetData: () => { },
  MODULE_TYPE: 'APPOINTMENT'
}
export default DiscountPopup;
