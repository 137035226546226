

import { BASE_URL, get_user_business_profile_completion_progress } from "../api_variables"
import { BUSINESS_SETTING } from "Redux/TenantRedux/ActionTypes/businessSettingTypes"


export const getProfileCompletion = (data, success, fail) => dispatch =>{
    fetch(
        `${BASE_URL}${get_user_business_profile_completion_progress}?business_id=${data.business_id}`
    )
    .then(response =>{
        return response.json()
    })
    .then(result =>{
        success && success(result.response.data)
        dispatch({
            type : BUSINESS_SETTING.UPDATE_BUSINESS_PROFILE_COMPLETION_PROGRESS,
            payload : result.response.data
        })
    })
    .catch(err =>{
        fail && fail()
    })
}