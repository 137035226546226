import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { discountTypes } from "Redux/TenantRedux/Reducers/clientDiscountPromotionReducer";
import DirectFlat from "./DirectFlat";
import { FreeItemForOtherPurchase } from "./FreeItemForOtherPurchase";
import { SpecificGroup } from "./SpecificGroup";
import BundleFixedPriceSection from "./BundleFixedPrice";
import FixedPriceService from "./FixedPriceService";
import DiscountOnBrandNServiceGroup from "./DiscountOnBrandNServiceGroup";
import PurchaseAndDiscount from "./PurchaseNDiscount";
import FreeItemSpendAmount from "./FreeItemSpendAmount";
import BuyRetailGetFreeService from "./BuyRetailAndGetFreeService";
import UserRestrictedDiscount from "./UserRestrictedDiscount";
import ComplimentaryVoucher from "./ComplimentaryVoucher";
import PackagesPromotions from "./Packages";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";



const InnerSection = ({ children, selectedLanguage, translation, }) => {
  const [not_found, setNotFound] = useState(false)

  useEffect(() => {
    if (children) {
      let is_all = children.some(itm => itm)
      if (!is_all) {
        setNotFound(true)
      }
      else {
        setNotFound(false)
      }
    }
    else {
      setNotFound(true)
    }
  }, [children])
  return (
    <>
      {
        not_found ?
          <p>
            {selectedLanguage?.name == "English" ? "You have not added any offer yet, go to" : translation[138]?.value ? translation[138]?.value : "You have not added any offer yet, go to"}
            {" "}
            <Link to='/dashboard/clients/discounts-promotions' >
              <span className="cursor-pointer text-[#3e5bd0]">
                {selectedLanguage?.name == "English" ? "promotions & discounts" : translation[139]?.value ? translation[139]?.value : "promotions & discounts"}
              </span>
            </Link>
            {" "}
            {selectedLanguage?.name == "English" ? "to add" : translation[140]?.value ? translation[140]?.value : "to add"}
          </p>
          :
          children
      }
    </>
  )

}

const DiscountPromotion = ({
  isDelete,
  selected,
  servicesRedux,
  appointmentData,
  promotions,
  mainData,
  SetData,
  selectCallback,
  setSelServGroups,
  select_service_serviceGroups,
  MODULE_TYPE,
  setServiceData,
  setAvailOfferPopup,
  setProductsData,
  selectedLanguage,
  translation,
  setNotFound
}) => {

  const [promotionsData, setPromotionsData] = useState({})
  const [haveDirectOfFlat, setHavDirectOrFlat] = useState(true)

  const handle_services_selected_data = (req_data, is_replaceable, extra_data) => {
    toast.success(selectedLanguage?.name == "English" ? "Selected discount or promotion has been applied successfully" : translation[1724]?.value ? translation[1724]?.value : "Selected discount or promotion has been applied successfully", { toastId: 'toast' })
    setAvailOfferPopup(false)
  }


  const handle_product_selected_data = (req_data, is_replaceable, extra_data) => {
    toast.success(selectedLanguage?.name == "English" ? "Selected discount or promotion has been applied successfully" : translation[1724]?.value ? translation[1724]?.value : "Selected discount or promotion has been applied successfully", { toastId: 'toast' })
    setAvailOfferPopup(false)
  }

  const handle_appointment_selected_data = (req_data, is_replaceable, extra_data) => {
    let new_data = {
      ...mainData,
      appointments: []
    }
    if (!is_replaceable) {
      new_data['appointments'] = [...mainData['appointments']]
    }

    let new_apps = [...new_data.appointments, ...req_data]
    toast.success(selectedLanguage?.name == "English" ? "Selected discount or promotion has been applied successfully" : translation[1724]?.value ? translation[1724]?.value : "Selected discount or promotion has been applied successfully", { toastId: 'toast' })
    new_data.appointments = new_apps
    SetData(new_data, extra_data)
  }


  const handle_selected_data = (req_data, is_replaceable, extra_data) => {
    let funcs = {
      'APPOINTMENT': () => handle_appointment_selected_data(req_data, is_replaceable, extra_data),
      'SERVICE': () => handle_services_selected_data(req_data, is_replaceable, extra_data),
      'PRODUCT': () => handle_product_selected_data(req_data, is_replaceable, extra_data),
    }
    funcs[MODULE_TYPE] && funcs[MODULE_TYPE]()
  }

  useEffect(() => {
    setPromotionsData(promotions)
  }, [promotions])
  // Fixed_Price_Service

  return (
    <InnerSection selectedLanguage={selectedLanguage} translation={translation} >
      {
        promotionsData?.directFlat && promotionsData?.directFlat?.length > 0 && haveDirectOfFlat &&
        // No Cost Price =>> Done 
        <DirectFlat // 1 Done with Selection Type & Working
          // is_promotion : true,
          setServiceData={setServiceData}
          mainData={mainData}
          onSelect={handle_selected_data}
          discount_data={promotionsData?.directFlat}
          isDelete={isDelete}
          selected={selected}
          setSelServGroups={setSelServGroups}
          select_service_serviceGroups={select_service_serviceGroups}
          MODULE_TYPE={MODULE_TYPE}
          setProductsData={setProductsData}
          setNotFound={setNotFound}
          haveDirectOfFlat={haveDirectOfFlat}
          setHavDirectOrFlat={setHavDirectOrFlat}
        />
      }
      {
        promotionsData?.specificCategoryGroup && promotionsData?.specificCategoryGroup?.length > 0 &&
        // No Cost Price =>> Done 
        <SpecificGroup // 2 Done with Selection Type & Working
          // is_promotion : true,
          specific_group={promotionsData?.specificCategoryGroup}
          onSelect={handle_selected_data}
          servicesRedux={servicesRedux}
          appointmentData={appointmentData}
          setSelServGroups={setSelServGroups}
          select_service_serviceGroups={select_service_serviceGroups}
          MODULE_TYPE={MODULE_TYPE}
          setServiceData={setServiceData}
          setProductsData={setProductsData}
          setNotFound={setNotFound}
          x1={selected}

        />
      }
      {
        (MODULE_TYPE == 'SERVICE' || MODULE_TYPE == 'APPOINTMENT') && promotionsData?.get_free_item_for_other_purchase && promotionsData?.get_free_item_for_other_purchase?.length > 0 &&
        // No Cost Price =>> Done 
        <FreeItemForOtherPurchase // 3 DONE with Selection Type & Working
          // is_promotion
          get_free_item_for_other_purchase={promotionsData?.get_free_item_for_other_purchase}
          servicesRedux={servicesRedux}
          onSelect={handle_selected_data}
          setSelServGroups={setSelServGroups}
          select_service_serviceGroups={select_service_serviceGroups}
          MODULE_TYPE={MODULE_TYPE}
          setServiceData={setServiceData}
          setNotFound={setNotFound}
        />
      }
      {
        (MODULE_TYPE == 'SERVICE' || MODULE_TYPE == 'APPOINTMENT') && promotionsData?.bundleDiscount && promotionsData?.bundleDiscount?.length > 0 &&
        // No Cost Price =>> Done 
        <BundleFixedPriceSection // 4 Done with Selection Type & Working
          // is_promotion : true,
          bundle_fixed_price={promotionsData?.bundleDiscount}
          servicesRedux={servicesRedux}
          onSelect={handle_selected_data}
          MODULE_TYPE={MODULE_TYPE}
          setServiceData={setServiceData}
          setNotFound={setNotFound}
          setPromotionsData={setPromotionsData}

        />
      }
      {
        (MODULE_TYPE == 'SERVICE' || MODULE_TYPE == 'APPOINTMENT') && promotionsData?.fixedPrice && promotionsData?.fixedPrice?.length > 0 &&
        // No Cost Price =>> Done 
        <FixedPriceService // 5 Done with Selection type & Working
          // is_promotion : true,
          fixed_price_services={promotionsData?.fixedPrice}
          servicesRedux={servicesRedux}
          onSelect={handle_selected_data}
          MODULE_TYPE={MODULE_TYPE}
          setServiceData={setServiceData}
          setNotFound={setNotFound}
          setPromotionsData={setPromotionsData}

        />
      }
      {
        promotionsData?.specificBrandServiceGroup && promotionsData?.specificBrandServiceGroup?.length > 0 &&
        // No Cost Price =>> Done 
        <DiscountOnBrandNServiceGroup // 6 Done with selection type & Working
          // is_promotion : true,
          specificBrandServiceGroup={promotionsData?.specificBrandServiceGroup}
          servicesRedux={servicesRedux}
          onSelect={handle_selected_data}
          setSelServGroups={setSelServGroups}
          MODULE_TYPE={MODULE_TYPE}
          setServiceData_state={setServiceData}
          setProductsData={setProductsData}
          setNotFound={setNotFound}
        />
      }
      {
        promotionsData?.purchase && promotionsData?.purchase.filter(itm => itm.select_type?.toLowerCase() == (MODULE_TYPE == 'APPOINTMENT' ? 'service' : MODULE_TYPE?.toLowerCase()))?.length > 0 &&
        // No Cost Price =>> Done 
        <PurchaseAndDiscount // 7 Done with selection type & Working
          // is_promotion : true,
          purchases_discount={promotionsData?.purchase.filter(itm => itm.select_type?.toLowerCase() == (MODULE_TYPE == 'APPOINTMENT' ? 'service' : MODULE_TYPE?.toLowerCase()))}
          servicesRedux={servicesRedux}
          onSelect={handle_selected_data}
          MODULE_TYPE={MODULE_TYPE}
          setServiceData={setServiceData}
          setNotFound={setNotFound}
        />
      }
      {
        (MODULE_TYPE == 'APPOINTMENT') && promotionsData?.spendSomeAmount && promotionsData?.spendSomeAmount.length > 0 &&
        // No Cost Price =>> Done 
        <FreeItemSpendAmount // 8 Done with selection type & Working
          // is_promotion : true,
          spend_some_amount={promotionsData?.spendSomeAmount}
          servicesRedux={servicesRedux}
          onSelect={handle_selected_data}
          MODULE_TYPE={MODULE_TYPE}
          setServiceData={setServiceData}
          setNotFound={setNotFound}
        />
      }
      {
        (MODULE_TYPE == 'SERVICE' || MODULE_TYPE == 'PRODUCT') && promotionsData?.retailPromotion && promotionsData?.retailPromotion.length > 0 &&
        // No Cost Price =>> Done 
        <BuyRetailGetFreeService // 8
          // is_promotion : true,
          retailPromotion={promotionsData?.retailPromotion}
          servicesRedux={servicesRedux}
          setProductsData={setProductsData}
          onSelect={handle_selected_data}
          MODULE_TYPE={MODULE_TYPE}
          setServiceData={setServiceData}
          setNotFound={setNotFound}
        />
      }
      {
        promotionsData?.userRestricted && promotionsData?.userRestricted.length > 0 &&
        // No Cost Price =>> Done 
        <UserRestrictedDiscount // 8 Done with Selection Type & Working
          // is_promotion : true,
          userRestricted={promotionsData?.userRestricted}
          servicesRedux={servicesRedux}
          onSelect={handle_selected_data}
          MODULE_TYPE={MODULE_TYPE}
          setServiceData={setServiceData}
          setSelServGroups={setSelServGroups}
          setProductsData={setProductsData}
          setNotFound={setNotFound}
        />
      }
      {
        (MODULE_TYPE == 'APPOINTMENT') && promotionsData?.complimentaryVoucher && promotionsData?.complimentaryVoucher.length > 0 &&
        // MODULE_TYPE == 'SERVICE' || 
        // No Cost Price =>> Done 
        <ComplimentaryVoucher // 8 Done with Selection type & Working
          // is_promotion : true,
          complimentaryVoucher={promotionsData?.complimentaryVoucher}
          servicesRedux={servicesRedux}
          onSelect={handle_selected_data}
          MODULE_TYPE={MODULE_TYPE}
          setServiceData={setServiceData}
          setSelServGroups={setSelServGroups}
          setNotFound={setNotFound}
        />
      }
      {
        (MODULE_TYPE == 'APPOINTMENT') && promotionsData?.packages && promotionsData?.packages.length > 0 &&
        // No Cost Price =>> Done 
        <PackagesPromotions // 8 Done with Selection Type & Working
          // is_promotion : true,
          packages={promotionsData?.packages}
          servicesRedux={servicesRedux}
          onSelect={handle_selected_data}
          MODULE_TYPE={MODULE_TYPE}
          setServiceData={setServiceData}
          setSelServGroups={setSelServGroups}
          setNotFound={setNotFound}
        />
      }
    </InnerSection>
  )
}

DiscountPromotion.defaultProps = {
  data: {},
  setSelServGroups: () => { }
}

const mapStateToProps = state => {
  return {
    // promotions: state?.clientDiscountPromotion
    // promotions: state?.clientDiscountPromotion?.avail_offers
  }
}

const mapDispatchToProps = {

}


export default connect(mapStateToProps, mapDispatchToProps)(DiscountPromotion);

