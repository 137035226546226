import { useEffect, useState } from "react";
import * as yup from "yup";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation } from "new-components/location/helper";
import { getWorkingShedule } from "Adapters/Api/employee";
import {
  handleSubmitEmployeeSlot,
  handleUpdateEmployeeSlot,
} from "Adapters/Api/employee/working-schedule";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";

const useEmployeeSlot = ({
  employeeData,
  updateSlotData,
  isUpdate,
  setEmployeeSlotPopup,
  setIsUpdate,
  isLeoDay,
  successCallBack
}) => {
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const selected_location = getDataFromLocalStorage("selected_location");
  const dispatch = useDispatch();
  const { locationDetail } = useLocation();
  const { business, user } = useSelector((state) => state);
  const { access_token } = user.user;
  const { id } = business?.business;

  const [timeSlotArr, setTimeSlotArr] = useState([]);
  const [scheduleId, setScheduleId] = useState("");
  const [shiftPopup, setShiftPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(productAddSchema),
    mode: "onBlur",
    defaultValues: {
      schedule: [{ start_time: "", end_time: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "schedule",
  });



  const handleAddTimeSlot = () => {
    append({ start_time: "", end_time: "" });
  };

  const timeSlot = (strt, clse) => {
    if (strt != null && clse != null) {
      var startTime = moment(strt, "HH:mm");
      var endTime = moment(clse, "HH:mm");

      var timeStops = [];

      while (startTime <= endTime) {
        timeStops.push(new moment(startTime).format("HH:mm:ss"));
        startTime.add(30, "minutes");
      }
      setTimeSlotArr(
        timeStops.map((ele) => ({
          value: ele,
          label: moment(ele, ["HH:mm"]).format("h:mm A"),
        }))
      );
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const format1 = "YYYY-MM-DD HH:mm:ss"
    const format2 = "YYYY-MM-DD"

    let payload = {
      business: id,
      employee: employeeData.id,
      created_from_dashboard: employeeData?.is_created,
      id_to_maintain: employeeData?.id_to_maintain,
      type_of_vacation: employeeData?.type_of_vacation,
      type: employeeData?.type,
      start_time: data.schedule[0].start_time,
      end_time: data.schedule[0].end_time,
      day: employeeData.dayName,
      date: moment(employeeData?.week).format(format2)
    };

    if (employeeData?.is_working_schedule) {
      payload = { ...payload, is_working_schedule: employeeData?.is_working_schedule }
    }

    if (isLeoDay) {
      payload = { ...payload, is_leo_day: isLeoDay }
    }

    if (data.schedule.length > 1) {
      payload.start_time_shift = data.schedule[1].start_time;
      payload.end_time_shift = data.schedule[1].end_time;
    }
    console.log(payload);

    if (isUpdate) {
      handleUpdate(payload);
    } else {
      const res = await handleSubmitEmployeeSlot(
        payload,
        access_token,
        handleClose,
        setIsLoading
      );

      res.status === 201 && successCallBack();
    }
  };

  const handleUpdate = async (payload) => {

    payload.schedule_id = scheduleId
    const res = await handleUpdateEmployeeSlot(
      payload,
      access_token,
      handleClose,
      setIsLoading
    );

    res.status === 200 && successCallBack()
  };

  const handleClose = () => {
    setEmployeeSlotPopup(false);
    setIsUpdate(false);
    reset({});
  };

  useEffect(() => {
    if (isUpdate) {
      const { start_time, start_time_shift, end_time, end_time_shift, id } =
        updateSlotData;
      setScheduleId(id);
      if (start_time_shift && end_time_shift) {
        reset({
          schedule: [
            {
              start_time: start_time,
              end_time: end_time,
            },
            {
              start_time: start_time_shift,
              end_time: end_time_shift,
            },
          ],
        });
      } else {
        reset({
          schedule: [
            {
              start_time: start_time,
              end_time: end_time,
            },
          ],
        });
      }
    }
  }, []);

  useEffect(() => {
    const res = locationDetail?.opening_hours?.find((dy) => dy?.day === employeeData?.day.split(",")[0].toLowerCase());
    timeSlot(res?.start_time, res?.close_time);
  }, [locationDetail]);



  return {
    errors,
    fields,
    control,
    isLoading,
    shiftPopup,
    timeSlotArr,
    watch,
    remove,
    onSubmit,
    handleClose,
    handleSubmit,
    setShiftPopup,
    handleAddTimeSlot,
  };
};

export default useEmployeeSlot;

export const productAddSchema = yup.object().shape({
  schedule: yup.array().of(
    yup.object().shape({
      end_time: yup.string().required("field is required"),
      start_time: yup.string().required("field is required"),
    })
  ),
});
