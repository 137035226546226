import { createSegment, getCRMClient, getCRMClientNoPagination, updateSegment } from 'Adapters/Api/segments'
import useObjectKeys from 'Hooks/useObjectKeys'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import useValidations from 'Hooks/useValidations'
import moment from 'moment'
import { useMemo } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

let initialState = {
    name: "",
    segment_type: "",
    is_active: true,
    description: "",
    client: [],
}

let clientInitialFilters = {
    gender: '',
    number_visit: 0,
    min_spend_amount: 0,
    max_spend_amount: 0,
    start_date: "",
    end_date: "",
}

let timeOutId = undefined

const useSegmentPopup = ({ setCreateS, data, setIsEdit, setApiCall, isEdit }) => {
    const currentDate = moment().format('YYYY-MM-DD');

    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const state = useSelector(state => state);
    const dispatch = useDispatch()
    const bussiness = getDataFromLocalStorage('business_data')
    const access_token = getDataFromLocalStorage('access_token')

    const { objectKeyConvertToArray } = useObjectKeys();

    const clientReduxData = state?.client?.clientsRedux;
    const clientPaginationData = state?.client?.clientPaginationData;
    const clientReduxNoPagination = state?.client?.clientReduxNoPagination;

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    const [segmentationData, setSegmentationData] = useState(initialState)
    const [submitLoader, setSubmitLoader] = useState(false)
    const [filterPopup, setFilterPopup] = useState(false)
    const { setErrors, validation, errors, onBlurHandler } = useValidations();

    const [clientFilters, setClientFilters] = useState(clientInitialFilters)
    const [clientLoader, setClientLoader] = useState(true)
    const [clientSecondLoader, setClientSecondLoader] = useState(true)
    const [clientSearchText, setClientSearchText] = useState("");
    const [clientCurrentPage, setClientCurrentPage] = useState(1)
    const [isSelectAll, setIsSelectAll] = useState(false)
    const [selected, setSelected] = useState(false)

    const clientRedux = useMemo(() => {
        if (isEdit) {
            const dataToFilter = isSelectAll ? clientReduxNoPagination : clientReduxData
            const filteredClient = dataToFilter.filter(item => !data?.client?.includes(item.id));

            return clientPaginationData?.is_filtered
                ? dataToFilter
                : [
                    ...data?.client_data,
                    ...filteredClient
                ]
        } else {
            return (
                clientPaginationData?.is_filtered
                    ? clientReduxData
                    : isSelectAll
                        ? clientReduxNoPagination
                        : clientReduxData
            )
        }
    }, [clientReduxNoPagination, clientPaginationData, clientReduxData, isSelectAll])

    var minEndDateLimit = useMemo(() => {
        return moment(clientFilters.start_date).add(1, 'day').format('YYYY-MM-DD')
    }, [clientFilters.start_date]);

    var maxEndDateLimit = useMemo(() => {
        return moment(currentDate).add(1, 'day').format('YYYY-MM-DD')
    }, [clientFilters.start_date]);

    useEffect(() => {
        if (data && isEdit) {
            if (clientPaginationData?.allCount == data?.client?.length) {
                setIsSelectAll(true)
            }
            setSegmentationData(data)
        }
    }, [data, isEdit])

    useEffect(() => {
        if (isSelectAll) {
            const clientIds = clientRedux.map((itm) => itm?.id)
            setSegmentationData((prev) => ({ ...prev, client: [...clientIds] }));
            setErrors((prev) => ({ ...prev, client: "" }))
        }
    }, [clientRedux])

    useEffect(() => {
        setClientCurrentPage(1)
    }, [clientSearchText])

    const getClients = (page, clearFilter) => {
        const page_numb = page ? page : clientCurrentPage
        if (clearFilter) {
            setClientFilters(clientInitialFilters)
        }

        const payload = clearFilter
            ? {
                search_text: clientSearchText,
            }
            : {
                ...clientFilters,
                search_text: clientSearchText,
            }

        let query;
        for (let dt in payload) {
            if (payload[dt]) {
                query += `${dt}=${payload[dt]}&`
            }
        }
        const page_num = clearFilter ? page_numb : query || isSelectAll ? null : page_numb
        if (query || page_numb == 1)
            setClientLoader(true)

        setFilterPopup(false)
        // if (query || isSelectAll) {
        //     setNoPagination(true)
        //     get_ClientsNoPagination(payload)
        // } else {
        // setNoPagination(false)
        dispatch(getCRMClient({ ...payload, page: page_num }, setClientLoader));
        // }
    }

    // const get_ClientsNoPagination = (payload) => {
    //     let isPaginated = payload ? true : false
    // }
    useEffect(() => {
        dispatch(getCRMClientNoPagination({}, setClientLoader));
    }, [])

    useEffect(() => {
        if (clientSearchText === null || clientSearchText.length == 0) {
            if (!isEdit) {
                // setIsSelectAll(false)
                // setSegmentationData((prev) => ({ ...prev, client: [] }));
            }
            getClients(clientCurrentPage, "")
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getClients(clientCurrentPage, clientSearchText)
            }, 500);
        }
    }, [clientSearchText, clientCurrentPage])

    useEffect(() => {
        if (segmentationData?.client?.length > 0) {
            if (segmentationData?.client.length == clientPaginationData?.allCount) {
                setIsSelectAll(true)
            } else {
                setIsSelectAll(false)
            }
        }
    }, [clientPaginationData, segmentationData, isSelectAll])


    const onChangeHandler = (e, all) => {
        if (e?.target) {
            const { name, value } = e.target;
            if (all) {
                const clientIds = clientRedux.map((itm) => itm?.id)
                if (segmentationData?.client.length == clientPaginationData?.allCount) {
                    setSegmentationData((prev) => ({ ...prev, client: [] }));
                    setErrors((prev) => ({ ...prev, client: "" }))
                } else {
                    setSegmentationData((prev) => ({ ...prev, client: [...clientIds] }));
                    setErrors((prev) => ({ ...prev, client: "" }))
                }
            } else {
                if (name == 'client') {
                    if (!segmentationData?.client?.includes(value)) {
                        setSegmentationData((prev) => ({ ...prev, [name]: [...prev.client, value] }));
                        setErrors((prev) => ({ ...prev, [name]: "" }))
                        return;
                    } else {
                        setSegmentationData({
                            ...segmentationData,
                            client: [
                                ...segmentationData.client.filter(client_id => client_id != value)
                            ]
                        })
                        return;
                    }
                }
            }
            setSegmentationData((prev) => ({ ...prev, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: "" }))
        }
    }

    const onSubmitHandler = () => {
        const { name, segment_type, description, client, } = segmentationData;
        let requiredFields = {}
        if (client?.length == 0) {
            requiredFields = { ...segmentationData, client: client?.length > 0 ? client : "" }
        } else {
            requiredFields = { name, segment_type, description }
        }

        setErrors(validation(requiredFields));
        let result = validation(requiredFields);

        if (objectKeyConvertToArray(result)?.length === 0) {
            setSubmitLoader(true)
            const success = () => {
                setApiCall(name)
                setSegmentationData(initialState)
                setCreateS(false)
                setIsEdit(false)
                setSubmitLoader(false)
            }
            const fail = () => {
                setSubmitLoader(false)
            }
            if (isEdit) {
                dispatch(updateSegment({ ...segmentationData, business: bussiness?.id }, access_token, success, fail))
            } else {
                dispatch(createSegment({ ...segmentationData, business: bussiness?.id }, access_token, success, fail))
            }
        }
    }

    const onClientFilterChange = (e) => {
        const { name, value } = e.target;

        if (name === 'start_date' || name === 'end_date') {
            if (name === 'start_date') {
                setClientFilters((prev) => ({
                    ...prev,
                    start_date: value,
                    end_date: currentDate
                }));
            }
            if (name === 'end_date') {
                setClientFilters((prev) => ({
                    ...prev,
                    end_date: value
                }));
            }
        }
        setClientFilters((prev) => ({ ...prev, [name]: value }));
    }


    return {
        filterPopup, setFilterPopup,
        translation, selectedLanguage,
        errors, onBlurHandler,
        segmentationData, onChangeHandler, onSubmitHandler,
        clientPaginationData, clientRedux, clientLoader,
        clientSearchText, setClientSearchText, setClientCurrentPage,
        getClients, minEndDateLimit, maxEndDateLimit, isSelectAll, setIsSelectAll,
        clientSecondLoader, setClientSecondLoader,
        submitLoader, setSegmentationData,
        onClientFilterChange, clientFilters,
    }
}

export default useSegmentPopup