/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect } from "react";


import Selection from "new-components/dropdown";
import Popup from "new-components/popup";
import { useCopyStaffTarget } from "./helper";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";

function CopyStaffTarget({ handleClose, selectedLanguage, translation, }) {
  const { control, handleSubmit, onSubmit, monthDropdown, errors, isLoading, toMonth, isValid, yearDropdown, watch } = useCopyStaffTarget({
    handleClose,
  });
  return (
    <>
      <Popup
        heading={`Copy Staff Target`}
        size="md"
        handleClose={handleClose}
      >
        <form method="POST" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4">
            <div className="grid md:grid-cols-2 gap-4">
              <Selection
                label={selectedLanguage?.name == "English" ? "From Year" : translation[825]?.value ? translation[825]?.value : "From Year"}
                placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                name="from_year"
                control={control}
                options={yearDropdown}
                errorMessage={errors?.from_year?.message}
              />
              <Selection
                label={selectedLanguage?.name == "English" ? "From Month" : translation[826]?.value ? translation[826]?.value : "From Month"}
                placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                name="fromMonth"
                control={control}
                options={monthDropdown}
                errorMessage={errors?.fromMonth?.message}
              />
              <Selection
                label={selectedLanguage?.name == "English" ? "To Year" : translation[827]?.value ? translation[827]?.value : "To Year"}
                placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                name="to_year"
                control={control}
                isDisabled={!watch('from_year')}
                options={yearDropdown}
                errorMessage={errors?.to_year?.message}
              />

              <Selection
                label={selectedLanguage?.name == "English" ? "To Month" : translation[828]?.value ? translation[828]?.value : "To Month"}
                placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                name="toMonth"
                control={control}
                isDisabled={!watch('fromMonth')}
                options={toMonth}
                errorMessage={errors?.toMonth?.message}
              />
            </div>
            <LoginBtn
              loading={isLoading}
              disabled={isLoading}
              // disabled={!isValid
              //   || isLoading}
              text={selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
            />
          </div>
        </form>
      </Popup>
    </>
  );
}

export default CopyStaffTarget;
