import CircleIcon from 'Components/TenantApp/Elements/CircleIcon'
import React from 'react'

const FormShimmerFullScreen = () => {
    return (
        <>
            <div className="flex flex-col gap-6 animate-pulse">
                <div className='flex items-center xl:gap-3 gap-1 justify-center topscroll'>
                    {[0, 1, 2].map((step, i) => {
                        return <>
                            <div key={i} className='flex items-center lg:flex-row text-center flex-col gap-1.5 xl:gap-3'>
                                <div className={`h-[2.1875rem] w-[2.1875rem] rounded-full border-2 flex items-center justify-center font-semibold border-gray-200`}> </div>
                                <div className="w-32 h-6 bg-gray-200 rounded-lg"></div>
                            </div>
                            {i < 2 &&
                                <div className='h-[1px] w-[2.813rem] border-t-2 border-dashed border-gray-300'></div>
                            }
                        </>
                    })
                    }
                </div>
                <div className="flex flex-col gap-5 animate-pulse">
                    <div className="flex items-center gap-4 lg:flex-row flex-col">
                        <CircleIcon />
                        <div className="flex w-full flex-col gap-1 lg:text-left text-center">
                            <div className="w-2/3 h-6 bg-gray-200 rounded-lg" />
                            <div className="w-1/3 h-6 bg-gray-200 rounded-lg" />
                        </div>
                    </div>
                    <div className="flex items-center lg:flex-row flex-col gap-5">
                        <div className="relative w-fit">
                            <div className="h-[11rem] w-[10rem] bg-[#E4EAF0] rounded-lg flex items-center justify-center overflow-hidden">
                            </div>
                        </div>
                        <div className="grid lg:grid-cols-2 gap-5 w-full">
                            <div className="h-12 bg-gray-200 rounded-lg" />
                            <div className="h-12 bg-gray-200 rounded-lg" />
                            <div className="h-12 bg-gray-200 rounded-lg" />
                            <div className="h-12 bg-gray-200 rounded-lg" />
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-2 gap-5">
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                    </div>
                    <div className="flex justify-start w-full">
                        <div className="w-1/4 h-12 bg-gray-200 rounded-lg" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormShimmerFullScreen