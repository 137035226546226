
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Svgs from "../../../../../Assets/svgs";
import { ProductPopup } from "../../MyAccount/Inventory/AddHeaderBtns/AddProductBtn";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
// import { useSaleProduct } from "../quick-sales/Products/helper";

const ProductCard = ({
  setOpenCart, OpenCart,
  getProductList,
  item,
  product,
  view_details,
  onClick,
  currency,
  locationDetail,
  cartItems,
  translation,
  selectedLanguage,
  productsData,
  animate
}) => {
  const [edit_popup, setEditPopup] = useState(false);
  const [product_price, setProductPrice] = useState(undefined)
  const [product_stock, setProductStock] = useState(undefined)
  const [cart_product, setCartProd] = useState(undefined)

  useEffect(() => {
    if (locationDetail) {

      let result = product?.currency_retail_price?.find((itm) => itm?.currency === locationDetail?.currency?.id)
      if (result) {
        setProductPrice(result)
      }
      else {
        // console.log('result not found')
      }

      const product_current_stck = product?.location_quantities?.length > 0 ? product?.location_quantities[0] : undefined
      if (product_current_stck) {
        setProductStock(product_current_stck)
      }
    }

  }, [locationDetail, product, product?.current_stock, product?.length, productsData])

  useEffect(() => {
    const cart_prod = cartItems?.find((dt) => (dt?.id === product?.id))
    if (cart_prod) {
      setCartProd(cart_prod)
    }
    else {
      setCartProd(undefined)
    }
  }, [cartItems])

  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);


  return (
    <>
      {edit_popup && (
        <ProductPopup
          edit_mode={true}
          edit_data={item}
          onClose={() => {
            setEditPopup(false);
          }}
          loader={false}
        />
      )}


      {animate ?
        <div className={`border justify-between relative animate-pulse`}>
          <div className="flex items-center gap-4 p-4">
            <div className="!w-[7em] h-[5rem] rounded-lg bg-gray-200"></div>

            <div className="w-full">
              <div className="rounded-sm h-5 w-4/5 bg-gray-200"></div>
              <div className="rounded-sm h-5 w-4/5 mt-2 bg-gray-200"></div>
            </div>
          </div>

          <div className="border-t-2 border-[#CFCFDC] border-dashed px-4 py-2.5 flex items-center justify-between">
            <div className="rounded-lg h-8 w-1/5 mt-2 bg-gray-200"></div>
            <div className="rounded-lg h-10 w-2/5 mt-2 bg-gray-200"></div>
          </div>
        </div>
        :

        <div
          className={`border justify-between relative rounded-[0.625rem]`}
        >
          <div className="flex items-start gap-4 p-4">

            <div className="absolute top-2 right-2">
              <div className="cursor-pointer" onClick={() => view_details(product_price?.retail_price ? product_price?.retail_price : 0)}>
                <Svgs.INFO />
              </div>
            </div>

            <div>
              <div className="h-[6rem] w-[6rem] overflow-hidden">
                <img
                  className="h-full w-full object-contain border rounded-[0.625rem]"
                  src={product?.cover_image}
                  alt="img"
                />
              </div>
            </div>

            <div className="">
              <p className="text-[#727985] text-xs pb-1">Quantity: {product_stock?.current_stock}</p>
              <h1 className="font-semibold ">{product?.name}</h1>
              <div className="">
                <p className="text-[#5679ff] text-xs font-semibold">
                  {(product_price && product_price?.retail_price) ? <>{product_price?.retail_price ? product_price?.retail_price?.toFixed(2) : 0} {currency}</> : <></>}
                </p>
                {(product?.discount_price || product?.discount_price == 0) && (
                  <p className="text-[#7B7B7B] text-xs font-semibold">
                    {selectedLanguage?.name == "English" ? "Discount Price" : translation[245]?.value ? translation[245]?.value : "Discount Price"} : {product?.discount_price?.toFixed(2)} {currency}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="border-t-2 border-[#CFCFDC] border-dashed px-4 py-2.5 flex items-center justify-between">

            {product_stock?.current_stock == 0 &&
              <div className="bg-[#FFE5E5] border border-[#F8C3C3] text-[#E32C2C] px-2.5 rounded-md py-1 text-center text-[0.6rem] w-fit">
                <p className="leading-[1]">Out of Stock</p>
              </div>
            }
            {(product_stock?.current_stock <= product_stock?.low_stock && product_stock?.current_stock != 0)
              &&
              <div className="bg-[#FFE5E5] border border-[#F8C3C3] text-[#E32C2C] px-2.5 rounded-md py-1 text-center text-[0.6rem] w-fit">
                <p className="leading-[1]">Low Stock</p>
              </div>
            }

            {/* //    <div className="bg-[#FFF5E5] border border-[#F3D29D] text-[#E58F03] px-2.5 rounded-md py-1 text-center text-[0.6rem] w-fit">
              //    <p className="leading-[1]">Limited Stock</p>
              //  </div>

              //   <div className="bg-[#E5FFF0] border border-[#ABF3D1] text-[#21BF72] px-2.5 rounded-md py-1 text-center text-[0.6rem] w-fit">
              //   <p className="leading-[1]">High stock</p>
              // </div>

              //    <div className="bg-[#FFE5E5] border border-[#F8C3C3] text-[#E32C2C] px-2.5 rounded-md py-1 text-center text-[0.6rem] w-fit">
              //    <p className="leading-[1]">Expiry</p>
              //  </div> */}


            {product_stock?.current_stock != 0 &&
              (!employee || ((employee && employeePermissions?.sales_apply_offer?.includes("create"))) || employeePermissions?.sales_apply_offer?.includes("edit")) ? (
              <LoginBtn
                className={'ml-auto !py-2'}
                onClick={() => {
                  const price = product_price?.retail_price ? product_price?.retail_price : 0
                  if (((product_stock?.current_stock > 0) && (cart_product?.quantity < product_stock.current_stock)) || ((product_stock?.current_stock > 0) && (cart_product == undefined))) {
                    if ((product?.discount_price || product?.discount_price == 0) && product?.discount_price !== undefined) {
                      onClick(product_stock?.current_stock, price, product?.discount_price);
                    } else {
                      onClick(product_stock?.current_stock, price)
                    }
                    // onClick(product_stock?.current_stock, price);
                    setOpenCart(true)
                  } else {
                    toast.error(selectedLanguage?.name == "English" ? "Selected product stock is not available!" : translation[250]?.value ? translation[250]?.value : "Selected product stock is not available!", { toastId: "toast" })
                  }
                }
                }
                text={'Add to Cart'}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      }
      {/* {
                ProductDetailPopup && <Popup heading='Product Details' close={() => setProductDetailPopup(false)}>
                    <div className='grid md:grid-cols-5 gap-4'>
                        <div className='flex justify-center'>
                            <CircleIcon className='!h-[6rem] !w-[6rem]'>
                                <Svgs.NewService />
                            </CircleIcon>
                        </div>
                        <div className='flex flex-col gap-6 md:col-span-4'>
                            <div className='grid md:grid-cols-3 gap-2'>
                                <DetailPopupDetails title={'Product Name'} desc={'Hmm'} />
                                <DetailPopupDetails title={'Brand'} desc={'Hmm HMMM'} />
                                <DetailPopupDetails title={'Category'} desc={'Hmm HMMM'} />
                            </div>
                            <DetailPopupDetails title={'Location'} desc={'Mehran Block, Allama Iqbal Town, Lahore'} />
                            <DetailPopupDetails title='Employee' desc={<div className='flex items-center gap-4 flex-wrap mt-2'>
                                <SelectedOption showDelete={false} img='https://source.unsplash.com/random' name='Waqar Zaka' />
                            </div>} />
                            <DetailPopupDetails title={'Retail Price'} desc={'$100 - $200'} descClass='text-xl font-semibold !text-[#5679FF]' />
                            <DetailPopupDetails title={'Consumption Notes'} desc={'Mehran Block, Allama Iqbal Town, Lahore Mehran Block, Allama Iqbal Town, Lahore Mehran Block, Allama Iqbal Town, LahoreMehran Block, Allama Iqbal Town, Lahore'} />
                            <DetailPopupDetails title={'Description'} desc={'Mehran Block, Allama Iqbal Town, Lahore Mehran Block, Allama Iqbal Town, Lahore Mehran Block, Allama Iqbal Town, LahoreMehran Block, Allama Iqbal Town, Lahore'} />
                        </div>
                    </div>
                </Popup>
            } */}
    </>
  );
};

export default ProductCard;
