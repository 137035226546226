import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useObjectKeys from "Hooks/useObjectKeys";
import useValidations from "Hooks/useValidations";
import { addProducts, getAllProductsWithNoPagination, getsProducts, getsProductsMainPage, updateProducts } from "Adapters/Api/Products";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { addProduct, getProducts, updateProduct } from "Redux/TenantRedux/Actions/product";
import { useLocation } from "new-components/location/helper";
import { apiErrorMessage } from "Constants/Data/Errors";
import { useRef } from "react";
import { PRODUCT } from "Redux/TenantRedux/ActionTypes/productTypes";
// import { getMultiLanguageInvoice } from "Adapters/Api/multiLanguageInvoice";

function useProducts() {

  const [products, setProducts] = useState({
    vendor: "",
    category: "",
    brand: "",
    name: "",
    cost_price: "",
    full_price: "",
    // size: "",
    invoices: [],
    currency_retail_price: [],
    // sell_price: "",
    short_description: "",
    description: "",
    barcode_id: "",
    sku: "",
    product_images: "",
    is_active: true,
    alert_when_stock_becomes_lowest: true,
    location_quantities: [],
  });

  const formRef = useRef(null);
  const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();

  const { locationDetail } = useLocation()
  const { setErrors, validation, errors, onBlurHandler } = useValidations();
  const { objectKeyConvertToArray } = useObjectKeys();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const [loader, setLoader] = useState(true)
  const { locations, product } = useSelector((state) => state);
  const { selected_location } = locations;
  const [currentPage, setCurrentPage] = useState(1);
  const [search_text, setSearchText] = useState("");

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  const currencyList = state?.utility?.currency?.currency_list;
  const productRedux = state?.product?.products;
  const locationList = state.locations.locations;
  const globle_currency = state?.business?.business?.currency?.code
  const location_currency = state?.utility?.currency?.location_currency;

  const allUinqueCurrencyList = useMemo(() => {
    let allCurrency = [];
    locationList?.filter((item) => item?.currency?.id).filter((itm) => {
      if (!allCurrency.find((dt) => dt?.currency?.id === itm?.currency?.id)) {
        allCurrency.push(itm)
        return true
      }
    })
    return allCurrency?.filter((itm) => !products?.currency_retail_price.find(({ currency }) => currency === itm?.currency?.id))
  }, [products?.currency_retail_price, locationList])

  const allCurrenciesData = useMemo(() => {
    let allCurrency = [];
    locationList?.filter((item) => item?.currency?.id).filter((itm) => {
      if (!allCurrency.find((dt) => dt?.currency?.id === itm?.currency?.id)) {
        allCurrency.push(itm)
        return true
      }
    })
    return allCurrency
  }, [locationList])



  const reduxInvoices = state?.multilanguageInvoices?.invoices
  const vendorsRedux = useSelector((state) => state?.vendors?.vendors);
  const brand_state = useSelector((state) => state?.brand);
  const categoryRedux = useSelector((state) => state?.category?.categories);
  const reduxlocationsList = useSelector((state) => state?.locations?.locations);

  const [invoiceLanguage, setInvoiceLanguage] = useState()
  const [product_name, setProduct_name] = useState()


  const access_token = getDataFromLocalStorage("access_token");
  const businessData = getDataFromLocalStorage("business_data");

  const [loading, setLoading] = useState(false);
  const [isEdit, setProductIsEdit] = useState(false);
  const [step, setStep] = useState(1);
  const [AddProduct, setAddProduct] = useState(false);
  const [AddCategory, setAddCategory] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [retailPrice, setRetailPrice] = useState();
  const [secondStepError, setSecondStepError] = useState()
  const [noPaginationLoader, setNoPaginationLoader] = useState(true)

  // *******************  Category modal handler *******************
  const categoryModalToggler = () => {
    setAddCategory(!AddCategory);
  };

  // *******************  Product modal handler *******************
  const productModalToggler = () => {
    setAddProduct(!AddProduct);
    setProducts({
      vendor: "",
      category: "",
      brand: "",
      name: "",
      product_images: "",
      location: [],
      product_type: "",
      cost_price: "",
      full_price: "",
      // sell_price: "",
      currency_retail_price: [],
      short_description: "",
      description: "",
      barcode_id: "",
      sku: "",
      consumable_quantity: "",
      unit: "",
      amount: "",
      is_active: "",
      product_size: "",
      product_unit: "",
      sellable_quantity: "",
      available_quantity: "",
      // size: "",
      location_quantities: [],
      alert_when_stock_becomes_lowest: true,
    });

    setErrors({});
  };

  // *******************  Product handle change *******************
  const productHandleChange = (e, fieldName = "") => {
    const { name, value } = e.target;
    if (e.target.name && e.target.name == "product_images") {
      let fileList = [];
      for (let i = 0; i <= e.target.files.length; i++) {
        fileList.push(e.target.files[i]);
      }
      setProducts((prev) => ({ ...prev, product_images: fileList }));
      return;
    }

    if (name === "location") {
      if (!products?.location?.includes(value)) {
        setProducts((prev) => ({
          ...prev,
          [name]: [...prev?.location, value],
        }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
        return;
      }
    }

    if (name == "short_description") {
      setProducts((prev) => ({ ...prev, short_description: value }));
      setErrors((prev) => ({ ...prev, short_description: "" }));
    }

    if (name == "description") {
      setProducts((prev) => ({ ...prev, description: value }));
      setErrors((prev) => ({ ...prev, description: "" }));
    }

    if (name != "description" && name != "short_description" && name != "product_images" && name != 'location') {
      setProducts((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };


  // useEffect(()=>{
  //   console.log(product.products,'Products')
  //   let filter =  product?.products?.map((obj) =>{
  //     obj?.location_quantities?.map((i)=>{ 
  //       console.log(i,'i')
  //       if(i?.location?.address_name == 'Thokar Niaz Baig'){
  //         return obj;
  //       } 
  //     })       
  //   })
  //   if(filter){
  //     console.log(filter,'filter')
  //   }
  // },[product])

  const fetchProductslist = async (currentPage, selected_location, search_text) => {
    setLoader(true)
    const response = await getsProductsMainPage(currentPage, selected_location, search_text);
    if (response.status === 200) {
      let productList = response?.data?.response?.products;
      let pages = response?.data?.response?.pages
      let count = response?.data?.response?.count

      dispatch({
        type: PRODUCT.GET_PRODUCT_PAGES,
        payload: pages
      })
      dispatch({
        type: PRODUCT.GET_ALL_COUNT,
        payload: count
      })
      dispatch(getProducts(productList));
      setLoader(false)
    } else {
      setLoader(false)
    }
  };



  // **************************** remove selected items *********************************
  const removedItem = (value, type) => {
    if (type === "location") {
      if (products?.location?.includes(value)) {
        setProducts((prev) => ({
          ...prev,
          location: [...products?.location?.filter((srvc) => srvc != value)],
        }));
      }
    }

    if (type === "invoices") {
      if (products?.invoices?.find(item => item?.invoiceLanguage === value)) {
        setProducts((prev) => ({
          ...prev,
          invoices: products?.invoices?.filter((srvc) => srvc?.invoiceLanguage != value),
        }));
      }
    }

    if ((type === "retail") && !isEdit) {
      if (products?.currency_retail_price?.find((itm) => itm?.currency == value)) {
        setProducts((prev) => ({
          ...prev,
          currency_retail_price: [...prev?.currency_retail_price?.filter((itm) => itm?.currency != value)],
        }));
      }
    } else {
      if (products?.currency_retail_price?.find((itm) => itm?.currency == value)) {
        setProducts((prev) => ({
          ...prev,
          currency_retail_price: [...prev?.currency_retail_price?.filter((itm) => itm?.currency != value)],
        }));
      }
      // if (products?.currency_retail_price?.find((itm) => itm?.currency == value)) {
      //   setProducts((prev) => ({
      //     ...prev,
      //     currency_retail_price: [...prev?.currency_retail_price?.map((itm) => {
      //       if (itm?.currency == value) {
      //         return {
      //           ...itm,
      //           is_deleted: true
      //         }
      //       }
      //       return itm
      //     })],
      //   }));
      // }
    }

  };

  useEffect(() => {
    if (allUinqueCurrencyList?.length == 0) {
      setErrors((prev) => ({
        ...prev,
        retailPrice: ""
      }))
    }
  }, [products?.currency_retail_price])

  useEffect(() => {
    if (products?.invoices?.length != 0) {
      setErrors((prev) => ({
        ...prev,
        product_name: ""
      }))
    }
  }, [products?.invoices])

  const onSubmitOfAddEditProduct = async (step, formatDataOfAddProduct) => {
    let location_quantity_list = products?.location_quantities?.filter(
      (itm) => itm?.current_stock || itm?.low_stock || itm?.reorder_quantity
    );
    if (isEdit) {
      // setLoader(true)
      setLoading(true);
      formatDataOfAddProduct = {
        ...products,
        location_quantities: location_quantity_list,
        product: products.id,
        business: businessData.id,
        cost_price: products?.cost_price ? products?.cost_price : null
      };
      let form_data = new FormData();
      for (let key in formatDataOfAddProduct) {
        if (key == "product_images") {
          if (typeof formatDataOfAddProduct["product_images"] != "string") {
            for (let img of formatDataOfAddProduct["product_images"]) {
              if (img) {
                form_data.append("product_images", img);
              }
            }
          }
        } else {
          if (
            typeof formatDataOfAddProduct[key] == "object" &&
            key != "product_images"
          ) {
            form_data.append(
              key,
              JSON.stringify(formatDataOfAddProduct[key])
            );
          } else {
            if (formatDataOfAddProduct[key]) {
              form_data.append(key, formatDataOfAddProduct[key]);
            }
          }
        }
      }

      //update api called here
      const response = await updateProducts(form_data, access_token);

      if (response.status === 200) {
        // let updatedProduct = response?.data?.response?.product;
        let successMessage = response?.data?.response?.message;
        setLoading(false);
        toast.success(successMessage, { toastId: "toast" });
        setCurrentPage(1)
        fetchProductslist(1, selected_location, search_text)
        dispatch(getAllProductsWithNoPagination(search_text, selected_location, setNoPaginationLoader))
        // dispatch(updateProduct(updatedProduct));
        productModalToggler();
        setStep(3);
      } else {
        setLoading(false);
        toast.error(apiErrorMessage, { toastId: "toast" });
      }
    } else {
      // setLoading(true);
      formatDataOfAddProduct = {
        ...products,
        location_quantities: location_quantity_list,
        business: businessData.id,
        tax_rate: 12,
        amount: products?.amount
          ? Number(products?.amount)
          : products?.amount,
        unit: products?.unit ? Number(products?.unit) : products?.unit,
        cost_price: products?.cost_price ? products?.cost_price : null
      };

      let form_data = new FormData();
      for (let key in formatDataOfAddProduct) {
        if (key == "product_images") {
          for (let img of formatDataOfAddProduct["product_images"]) {
            if (img) {
              form_data.append("product_images", img);
            }
          }
        } else {
          if (
            typeof formatDataOfAddProduct[key] == "object" &&
            key != "product_images"
          ) {
            form_data.append(
              key,
              JSON.stringify(formatDataOfAddProduct[key])
            );
          } else {
            if (
              formatDataOfAddProduct[key] ||
              typeof formatDataOfAddProduct[key] == "boolean"
            ) {
              form_data.append(key, formatDataOfAddProduct[key]);
            }
          }
        }
      }
      const response = await dispatch(addProducts(form_data, access_token));
      if (response.status === 201) {
        setStep(3);
        setCurrentPage(1)
        fetchProductslist(1, selected_location, search_text)
        dispatch(getAllProductsWithNoPagination(search_text, selected_location, setNoPaginationLoader))
        let product = response?.data?.response?.product;
        let successMessage = response?.data?.response?.message;
        // dispatch(addProduct(product));
        setLoading(false);
        productModalToggler();
        toast.success(successMessage, { toastId: "toast" });
        setProducts({
          vendor: "",
          category: "",
          brand: "",
          name: "",
          product_images: "",
          product_type: "",
          cost_price: "",
          full_price: "",
          location: [],
          // sell_price: "",
          currency_retail_price: [],
          short_description: "",
          product_unit: "",
          // product_size,
          description: "",
          barcode_id: "",
          sku: "",
          available_quantity: "",
          consumable_quantity: "",
          unit: "",
          amount: "",
          // size: "",
          is_active: "",
          alert_when_stock_becomes_lowest: true,
        });
      } else {
        setLoading(false);
        toast.error(apiErrorMessage, { toastId: "toast" });
      }
    }
  }

  // *******************  Product submissions *******************
  const onProdcutSubmit = async (step) => {
    const {
      vendor,
      category,
      brand,
      name,
      // size,
      invoices,
      cost_price,
      full_price,
      currency_retail_price,
      // sell_price,
      sku,
      product_images,
      product_size,
    } = products;

    let firstStepDataFormat = {
      name,
      sku,
      brand,
      category,
      // cost_price,
      // size,
      // invoices: invoices?.length > 0 ? invoices : "",
      // retailPrice: currency_retail_price?.length > 0 ? currency_retail_price[0] : "",
      // product_images: product_images ? product_images[0]?.name : '',
      // full_price,
      // sell_price,
    };

    setErrors(validation(firstStepDataFormat));
    let result = validation(firstStepDataFormat);
    if (objectKeyConvertToArray(result)?.length === 0 && !errors?.product_name) {
      let formatDataOfAddProduct;
      if (step == '1') {
        if (allUinqueCurrencyList?.length > 0) {
          setErrors((prev) => ({
            ...prev,
            retailPrice: selectedLanguage?.name == "English" ? "Please add all currencies and their price" : translation[1196]?.value ? translation[1196]?.value : "Please add all currencies and their price"
          }))
          formRef.current?.scrollIntoView({ behavior: "smooth" });
        } else if (!product_images) {
          setErrors((prev) => ({
            ...prev,
            product_images: selectedLanguage?.name == "English" ? "This field is required" : translation[287]?.value ? translation[287]?.value : "This field is required"
          }))
        } else {
          setStep(2)
        }
      } else if (step == '2') {
        firstStepDataFormat = {}
        let conditionForSubmit = false
        let all_errors = [];
        (products?.location_quantities)?.forEach((item, i) => {
          let err_obj = {}
          if (item.current_stock && item.low_stock && item.reorder_quantity && products?.location_quantities?.every(
            (itm) =>
              (itm?.low_stock !== 0 && itm?.low_stock !== '') &&
              (itm?.reorder_quantity !== 0 && itm?.reorder_quantity !== '') &&
              (itm?.current_stock !== 0 && itm?.current_stock !== '')
          )) {
            conditionForSubmit = true
          } else {
            err_obj.current_stock = item.current_stock !== '0' ? !(item.current_stock) : '0'
            err_obj.low_stock = item.low_stock !== '0' ? !(item.low_stock) : '0'
            err_obj.reorder_quantity = item.reorder_quantity !== '0' ? !(item.reorder_quantity) : '0'
            err_obj.id = item.id
            all_errors.push(err_obj)
            formRef.current?.scrollIntoView({ behavior: "smooth" });
          }
        });
        setSecondStepError(all_errors)
        if (conditionForSubmit) {
          onSubmitOfAddEditProduct(step, formatDataOfAddProduct)
        }
      }
    } else {
      if (step == 1) {
        if (allUinqueCurrencyList?.length >= currency_retail_price?.length) {
          setErrors((prev) => ({
            ...prev,
            retailPrice: selectedLanguage?.name == "English" ? "Please add all currencies and their price" : translation[1196]?.value ? translation[1196]?.value : "Please add all currencies and their price"
          }))
          formRef.current?.scrollIntoView({ behavior: "smooth" });
        }
        // else if (invoices?.length == 0) {
        //   setErrors((prev) => ({
        //     ...prev,
        //     product_name: selectedLanguage?.name == "English" ? "Please add languages and their product names according to them" : translation[1199]?.value ? translation[1199]?.value : "Please add languages and their product names according to them"
        //   }))
        //   formRef.current?.scrollIntoView({ behavior: "smooth" });
        // }
        else {
          setErrors((prev) => ({
            ...prev,
            retailPrice: "",
            product_name: "",
            invoices: ""
          }));
        }
        if (objectKeyConvertToArray(result)?.length === 0) {
          setStep(2)
        }
        formRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  const productEditHandler = (id) => {
    setProductIsEdit(true);
    storeDataInLocalStorage("product_id", id);
    productModalToggler();
    const selectedProduct = productRedux.find((item) => item.id === id);
    if (selectedProduct) {
      setProducts({
        vendor: selectedProduct?.vendor?.id,
        category: selectedProduct?.category?.id,
        brand: selectedProduct?.brand?.id,
        name: selectedProduct?.name,
        product_images: selectedProduct?.media[0]?.image,
        cost_price: selectedProduct?.cost_price,
        full_price: selectedProduct?.full_price,
        currency_retail_price: selectedProduct?.currency_retail_price ? selectedProduct?.currency_retail_price : [],
        // sell_price: selectedProduct?.sell_price,
        short_description: selectedProduct?.short_description,
        description: selectedProduct?.description,
        barcode_id: selectedProduct?.barcode_id
          ? selectedProduct?.barcode_id
          : "",
        sku: selectedProduct?.sku,
        // size: selectedProduct?.size,
        location_quantities: selectedProduct?.location_quantities
          ? selectedProduct?.location_quantities
          : [],
        is_active: selectedProduct?.is_active,
        alert_when_stock_becomes_lowest:
          selectedProduct?.stocks[0]?.alert_when_stock_becomes_lowest,
      });
    }
  };

  //set currency and retail price object here
  const addNewItem = () => {
    setProducts((prev) => ({
      ...prev,
      currency_retail_price: [
        ...prev.currency_retail_price,
        { currency: selectedCurrency, retail_price: retailPrice }
      ]
    }))
    setSelectedCurrency();
    setRetailPrice()
  }

  const addNewInvoiceLanguage = () => {
    setProducts((prev) => ({
      ...prev,
      invoices: [
        ...prev.invoices,
        { invoiceLanguage: invoiceLanguage, product_name: product_name }
      ]
    }))
    setInvoiceLanguage();
    setProduct_name()
  }

  //default select the currency of selectedLocation
  const defaulSelectedLocationCurrency = useMemo(() => {
    const res = allUinqueCurrencyList?.find((itm) => itm?.currency?.id === locationDetail?.currency?.id);
    setSelectedCurrency(res?.currency?.id);
  }, [locationDetail])

  // const defaulSelectedInvoice = useMemo(() => {
  //   if (reduxInvoices?.length > 0 && !invoiceLanguage) {
  //     setInvoiceLanguage(reduxInvoices[0]?.language_data?.id);
  //   }
  // }, [reduxInvoices])


  // const getInovices = () => {
  //   const success = () => { }
  //   const fail = () => { }
  //   dispatch(getMultiLanguageInvoice(access_token, success, fail))
  // }
  // useEffect(() => {
  //   if (reduxInvoices?.length == 0) {
  //     getInovices()
  //   }
  // }, [reduxInvoices?.length])

  return {
    setProducts, productHandleChange, categoryModalToggler,
    productModalToggler, onProdcutSubmit, onBlurHandler,
    productEditHandler, setProductIsEdit, addNewItem,
    setSelectedCurrency, removedItem, setRetailPrice, setStep,
    addNewInvoiceLanguage,
    invoiceLanguage, setInvoiceLanguage,
    product_name, setProduct_name,
    products, AddProduct, AddCategory,
    errors, step, isEdit, loading, globle_currency, selectedCurrency,
    retailPrice, allUinqueCurrencyList, currencyList, formRef, fetchProductslist,
    currentPage, setCurrentPage, search_text, setSearchText, loader, setLoader, location_currency, secondStepError, setSecondStepError,
    vendorsRedux, setErrors,
    brand_state,
    categoryRedux,
    reduxlocationsList, noPaginationLoader, setNoPaginationLoader, allCurrenciesData
  };
}

export default useProducts;
