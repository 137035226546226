import React, { useEffect, useState } from 'react';
import useProfileAppointment from './helper';

const RightBar = ({ services, location }) => {
    const {
        totalPrice,
        tax
    } = useProfileAppointment();
    let total_tax = 0;
    let total_price = totalPrice(services);

    let arr = [];
    if (tax?.length > 0) {
        tax?.forEach((itm) => {
            if (itm?.tax?.tax_type == 'Individual'){
                arr?.push(itm.tax)
            }
            else{
                itm?.tax?.parent_tax && arr?.push(...itm?.tax?.parent_tax)
            }
        })
    }

    return (
        <div className='col-span-2'>
            <div className='px-8 pt-4 pb-8 border-b'>
                <div className='flex items-center gap-4'>
                    <div>
                        <img src={`${location?.images ? location?.images : `${process.env.PUBLIC_URL}/images/location_img.png`}`} className='h-[6rem] w-[6rem] rounded-md object-cover border' />
                    </div>
                    <div className='flex flex-col gap-1 flex-1'>
                        <h1 className='font-semibold'>{location?.address_name}</h1>
                        <p className='text-[#101928]/60 text-sm'>{location?.address}</p>
                    </div>
                </div>
            </div>
            <div className='h-[50vh] flex flex-col '>
                <div className='flex-1 overflow-auto'>
                    <h1 className='font-semibold text-lg p-4'>Selected Services</h1>
                    {
                        services?.length > 0 ?
                            services?.map((itm) => {
                                return (
                                    (itm?.price && <div className='flex flex-col'>
                                        <div className='border-t p-4'>
                                            <div className='flex items-center justify-between gap-4'>
                                                <div className='flex flex-col gap-1'>
                                                    <h1 className='font-semibold'>{itm?.service_name}</h1>
                                                    <p className='text-sm text-[#101928]/60'>{itm?.duration?.replaceAll("_", " ")}</p>
                                                </div>
                                                <div>
                                                    <p className='font-semibold'>{itm?.price} {location?.currency?.code}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {itm?.emp_detail?.full_name && <div className='bg-[#E0EBFD] flex items-center gap-2 p-2 m-3 rounded-lg px-6'>
                                            <div>
                                                <img
                                                    src={`${itm?.emp_detail?.image ? itm?.emp_detail?.image : `${process.env.PUBLIC_URL}/images/profile_img.svg`}`}
                                                    alt="" className='h-[2rem] w-[2rem] object-cover rounded-full' />
                                            </div>
                                            <div>
                                                <h1 className='font-semibold'>{itm?.emp_detail?.full_name}</h1>
                                            </div>
                                        </div>}
                                    </div>)
                                )
                            }) :
                            <div className='bg-[#F4F4F4] rounded-md m-4 py-3 text-center'>
                                <p className='text-sm text-[#101928]/60'>No services selected yet</p>
                            </div>
                    }
                </div>

                <div className='border-t p-4 flex flex-col  font-semibold text-sm'>
                    <div>
                        {arr?.length > 0 && arr?.map((tx) => {
                            let services_total_p = totalPrice(services)

                            if (tx?.tax_type == "Group") {
                                // total_tax = (Math?.round(tx?.parent_tax[0]?.tax_rate * totalPrice(services) / 100) + (Math?.round(tx?.parent_tax[1]?.tax_rate * totalPrice(services) / 100)))
                                let taxOne = ((tx?.parent_tax[0]?.tax_rate * services_total_p) / 100);
                                let firstTotal = (taxOne + services_total_p)
                                let taxTwo = (((tx?.parent_tax[1]?.tax_rate) * firstTotal) / 100)
                                // let taxTwo = (((tx?.parent_tax[1]?.tax_rate) * services_total_p) / 100)
                                total_price = taxTwo + firstTotal;
                            } else {
                                // total_tax = Math?.round(tx?.tax_rate * totalPrice(services) / 100);
                                total_tax = tx?.tax_rate * services_total_p / 100
                                total_price = total_tax + services_total_p
                            }
                            return (
                                tx?.tax_type == "Group" ?
                                    <div className="flex items-center justify-between gap-3 py-2">
                                        <div className="">
                                            <p className="text-primary">Tax</p>
                                        </div>
                                        <p className="text-[#5679FF]">
                                            {tx?.parent_tax[0]?.tax_rate}%, {tx?.parent_tax[1]?.tax_rate}%
                                            {/* {(Math?.round(tx?.parent_tax[0]?.tax_rate * totalPrice(services) / 100) + (Math?.round(tx?.parent_tax[1]?.tax_rate * totalPrice(services) / 100)))} {location?.currency?.code} */}
                                        </p>
                                    </div>
                                    :
                                    <div className="flex items-center justify-between gap-3 py-2">
                                        <div className="">
                                            <h1 className="text-primary">Tax </h1>
                                        </div>
                                        <p className="text-[#5679FF] ">
                                            {tx?.tax_rate}%
                                            {/* {Math?.round((tx?.tax_rate) * totalPrice(services) / 100)} {location?.currency?.code} */}
                                        </p>
                                    </div>
                            )
                        })
                        }
                    </div>
                    <div className="flex items-center justify-between gap-3 py-2">
                        <p>Total</p>
                        {/* <p className='text-[#5679FF] '>{total_price ? Math.round(total_price) : 0} {location?.currency?.code}</p> */}
                        <p className='text-[#5679FF] '>{total_price ? total_price : 0} {location?.currency?.code}</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default RightBar