import { OPENING_TIME } from '../Data/OpeningHours'

export const get_afterward_time_range = (req_date, all) => {
    if (!req_date) {
        return []
    }

    let date = new Date();
    let this_year = date.getFullYear()
    let this_month = date.getMonth() + 1
    let this_day = date.getDate()
    const [app_year, month, day] = req_date.split('-')

    if ((+app_year > this_year) || (+month > this_month) || (+day > this_day)) {
        return OPENING_TIME
    }

    let this_hour = date.getHours()
    let this_minute = date.getMinutes()

    if (all) {
        return OPENING_TIME;
    } else {
        let current_time_range = OPENING_TIME?.filter((tm, index) => {
            let [hour, minutes, seconds] = tm?.value.split(':')
            if ((+hour >= this_hour)) {
                if (+hour > this_hour) {
                    return true
                }
                if (+minutes >= this_minute) {
                    return true
                }
            }
        })
        return current_time_range
    }
}