import axios from "axios";

import {
    add_absence_url,
    BASE_URL,
    delete_absence_url,
    get_absence_list_url,
    update_absence_url,
} from "../api_variables";

export const fetchAbsenceList = async (currentAbsencePage, location_id, filterAbsenceStaff, searchAbsenceText, access_token, setAbsenceLoader) => {
    setAbsenceLoader && setAbsenceLoader(true)
    const location = location_id ? `?location_id=${location_id}` : ''
    const employee = filterAbsenceStaff ? `&employee_id=${filterAbsenceStaff}` : ""
    const search = searchAbsenceText ? `&search_text=${searchAbsenceText}` : ''
    const page = currentAbsencePage ? `&page=${currentAbsencePage}` : ''
    try {
        const result = await axios.get(
            `${BASE_URL}${get_absence_list_url}${location}${page}${employee}${search}`,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );
        return result;
    } catch (error) {
        setAbsenceLoader && setAbsenceLoader(false)
        return error;
    }
};

export const fetchAbsenceListWithNoPagination = async (location_id, filterAbsenceStaff, searchAbsenceText, access_token, setAbsenceNoPagiantionLoader) => {
    setAbsenceNoPagiantionLoader && setAbsenceNoPagiantionLoader(true)
    const location = location_id ? `?location_id=${location_id}` : ''
    const employee = filterAbsenceStaff ? `&employee_id=${filterAbsenceStaff}` : ""
    const search = searchAbsenceText ? `&search_text=${searchAbsenceText}` : ''
    try {
        const result = await axios.get(
            `${BASE_URL}${get_absence_list_url}${location}&no_pagination=true${employee}${search}`,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );
        return result;
    } catch (error) {
        setAbsenceNoPagiantionLoader && setAbsenceNoPagiantionLoader(false)
        return error;
    }
};

export const deleteAbsence = async (id, access_token) => {
    try {
        let data = {
            id: id,
        };
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
            data,
        };
        const result = await axios.delete(
            `${BASE_URL}${delete_absence_url}`,
            headers
        );
        return result;
    } catch (error) {
        return error;
    }
};

export const addAbsence = async (absence_data, access_token) => {
    try {
        const result = await axios.post(
            `${BASE_URL}${add_absence_url}`,
            absence_data,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );
        return result;
    } catch (error) {
        return error;
    }
};

export const editAbsence = async (absence_data, access_token) => {

    try {
        let form_data = new FormData();

        for (let ky in absence_data) {
            if (absence_data[ky]) {
                form_data.append(ky, absence_data[ky]);
            }
        }
        const result = await axios.put(
            `${BASE_URL}${update_absence_url}`,
            form_data,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );
        return result;
    } catch (error) {
        return error;
    }
};