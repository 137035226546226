import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Sweepings from "Components/NstyleApp/Sweepings";
import LoginSection from "Components/TenantApp/Elements/LoginSection";
import SocialMediaPlatform from "Components/NstyleApp/SocialMediaPlatforms";
import ChooseSectionLogin from "Components/TenantApp/Elements/ChooseSectionLogin";

import { LogoSvg } from "Assets/svgs/Logo/LogoSvg";
import LoginCardBlock from "Components/TenantApp/Elements/LoginCardBlock";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import useClientUrlFromCreatedLink from "Hooks/useClientUrlFromCreatedLink";

const ChooseAccountType = () => {

    const [section, setSection] = useState();
    const { nexts, hash, token, domain } = useClientUrlFromCreatedLink();

    // const [account_type, setAccountType] = useState();

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const isLogout = searchParams.get("logout");
    if (isLogout == 1) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('loginData')
        localStorage.clear();
    }


    // const hash = searchParams.get("hash");
    // const token = searchParams.get("token");
    // const domain = searchParams.get("domain");



    return (
        <div className="min-h-[100vh] grid grid-cols-1 lg:grid-cols-2">
            <div className="flex flex-col lg:pt-[3rem] lg:px-[4rem] px-8 py-5">
                <div className="flex items-center justify-between">
                    <LogoSvg />
                    {/* <p className="text-[#7B7B7B] text-sm lg:text-left text-right">
                        Already have an account?&nbsp;
                        <span
                            className={`text-[#5679FF] cursor-pointer transition-all hover:underline`}
                            onClick={() => {
                                    if(window?.location?.search?.replace("?","")){
                                        navigate(`/auth/login/?account_type=everyone&next=${nexts}&hash=${hash}&token=${token}&domain=${domain}`);
                                    } else { 
                                        navigate(`/auth/login/?account_type=business&next=${nexts}&hash=${hash}&token=${token}&domain=${domain}`);
                                    }
                            }}
                        >
                            Login Now
                        </span>
                    </p> */}
                </div>
                <div
                    className={`flex flex-1 flex-col gap-5 justify-center md:py-[3rem] py-[1.75rem]`}
                >
                    <h1 className="text-3xl font-light text-[#7B7B7B] lg:text-left text-center">
                        We are <span className="font-semibold text-black">NStyle</span>
                    </h1>
                    <p className="text-[#7B7B7B] lg:text-left text-center text-sm">
                        Welcome to NStyle, Please select your account type.
                    </p>
                    <LoginCardBlock
                        // active={section === 1}
                        heading="Account For Business"
                        desc={false}
                        onClick={() => {
                            // setAccountType("business")
                            // setSection(1)
                            if (nexts && hash) {
                                navigate(`/auth/signup/?account_type=business&${window?.location?.search?.replace("?", "")}`)
                            } else {
                                navigate(`/auth/signup/?account_type=business`)

                            }

                        }}
                    >
                        <svg
                            width="33"
                            height="23"
                            viewBox="0 0 33 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M31.4669 0.257568H1.0859C0.771701 0.257568 0.51709 0.512241 0.51709 0.826381V21.5266C0.51709 21.8407 0.771701 22.0954 1.0859 22.0954H25.0387C25.3529 22.0954 25.6076 21.8407 25.6076 21.5266C25.6076 21.2124 25.3529 20.9578 25.0387 20.9578H2.60175L12.9295 11.9323L15.9021 14.53C16.0093 14.6237 16.1429 14.6706 16.2764 14.6706C16.41 14.6706 16.5436 14.6238 16.6508 14.53L19.6236 11.932L29.9516 20.9577H27.2003C26.8861 20.9577 26.6315 21.2124 26.6315 21.5265C26.6315 21.8407 26.8861 22.0953 27.2003 22.0953H31.4669C31.7811 22.0953 32.0357 21.8407 32.0357 21.5265V0.826381C32.0357 0.51218 31.7811 0.257568 31.4669 0.257568ZM29.6788 2.38895C29.472 2.15238 29.1127 2.12818 28.8762 2.33496L16.2764 13.3462L9.13552 7.1056C8.89894 6.89894 8.5398 6.92307 8.3329 7.15958C8.12618 7.39616 8.15038 7.75548 8.38689 7.9622L12.0651 11.1767L1.65471 20.2745V2.07894L6.61391 6.4128C6.72182 6.50705 6.85522 6.55334 6.98807 6.55334C7.14652 6.55334 7.30418 6.48754 7.41658 6.35882C7.6233 6.12224 7.59911 5.76292 7.3626 5.5562L2.60126 1.39519H30.8981V20.2741L20.4879 11.1766L29.6248 3.19163C29.8613 2.98485 29.8855 2.62553 29.6788 2.38895Z"
                                fill="#101928"
                            />
                        </svg>
                    </LoginCardBlock>
                    {/* <LoginCardBlock
                        // active={account_type === 2}
                        heading="Account For EveryOne"
                        desc={false}
                        onClick={() => {
                            // setAccountType("everyone")
                            // setSection(2);
                            // navigate(`/auth/signup/?account_type=everyone&${window?.location?.search?.replace("?","")}`)
                            if (nexts && hash) {
                                navigate(`/auth/signup/?account_type=everyone&${window?.location?.search?.replace("?", "")}`)
                            } else {
                                navigate(`/auth/signup/?account_type=everyone`)

                            }
                        }}
                    >
                        <svg
                            width="33"
                            height="23"
                            viewBox="0 0 33 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M31.4669 0.257568H1.0859C0.771701 0.257568 0.51709 0.512241 0.51709 0.826381V21.5266C0.51709 21.8407 0.771701 22.0954 1.0859 22.0954H25.0387C25.3529 22.0954 25.6076 21.8407 25.6076 21.5266C25.6076 21.2124 25.3529 20.9578 25.0387 20.9578H2.60175L12.9295 11.9323L15.9021 14.53C16.0093 14.6237 16.1429 14.6706 16.2764 14.6706C16.41 14.6706 16.5436 14.6238 16.6508 14.53L19.6236 11.932L29.9516 20.9577H27.2003C26.8861 20.9577 26.6315 21.2124 26.6315 21.5265C26.6315 21.8407 26.8861 22.0953 27.2003 22.0953H31.4669C31.7811 22.0953 32.0357 21.8407 32.0357 21.5265V0.826381C32.0357 0.51218 31.7811 0.257568 31.4669 0.257568ZM29.6788 2.38895C29.472 2.15238 29.1127 2.12818 28.8762 2.33496L16.2764 13.3462L9.13552 7.1056C8.89894 6.89894 8.5398 6.92307 8.3329 7.15958C8.12618 7.39616 8.15038 7.75548 8.38689 7.9622L12.0651 11.1767L1.65471 20.2745V2.07894L6.61391 6.4128C6.72182 6.50705 6.85522 6.55334 6.98807 6.55334C7.14652 6.55334 7.30418 6.48754 7.41658 6.35882C7.6233 6.12224 7.59911 5.76292 7.3626 5.5562L2.60126 1.39519H30.8981V20.2741L20.4879 11.1766L29.6248 3.19163C29.8613 2.98485 29.8855 2.62553 29.6788 2.38895Z"
                                fill="#101928"
                            />
                        </svg>
                    </LoginCardBlock> */}
                    {/* <LoginBtn
                        disabled={!account_type}
                        onClick={() => {
                            navigate(`/auth/signup/?account_type=${account_type}&${window?.location?.search?.replace("?","")}`)
                        }}
                        text='Next'
                    /> */}
                    {/* <div className="md:mt-[3rem] mt-[1rem]"> */}
                    {/* <div className="md:mt-[0.5rem] mt-[1rem]">
                        <h1 className="text-[#5679FF] cursor-pointer font-semibold w-fit" onClick={() => {
                            navigate('/help')
                        }}>Help?</h1>
                    </div> */}
                </div>
            </div>
            <div className="hidden lg:block" style={{
                backgroundImage: 'linear-gradient(to bottom left, rgba(166,77,255,1), rgba(54,67,120,1), rgba(54,67,120,1), rgba(87,120,255,1))'
            }}>
                <div className="px-8 py-5 lg:px-[4rem] lg:pt-[3rem] flex flex-col h-full">
                    <div className="flex justify-end text-white"><span className="cursor-pointer" onClick={() => {
                            navigate('/help')
                        }}>Help?</span></div>
                    <Sweepings />
                </div>
            </div>
        </div>
    );
};

export default ChooseAccountType;
