import React from 'react'
import Svgs from '../../../Assets/svgs'
import { error_message } from '../../../Constants/Variables'

const InputWithIcon = ({ title, type, onBlur, disabled, icon, placeholder, error, name, value, onChange, }) => {
    return (
        <>
            <div className='flex flex-col gap-2 w-full relative capitalize'>
                <p className='text-sm'>{title}</p>
                <div className='flex items-center gap-2 relative w-full'>
                    <label htmlFor={name} className='absolute left-3'>
                        {icon}
                    </label>
                    <input disabled={disabled} id={name} type={type} name={name} value={value} onChange={onChange} placeholder={placeholder} className={`border ${(value && error) && " border-l-[7px] border-[#eb3b3b]"} rounded-md text-sm outline-none px-4 py-3 pl-10 placeholder:text-[#A1A1A1] text-primary focus:border-primary ease-in transition-all focus:border-l-[7px] focus:pl-9 w-full`} />
                </div>
                {/* {(value && error) &&  <span className='text-[#eb3b3b] flex items-center gap-2'>{error}<Svgs.I /></span> } */}

                {(error) && (typeof error == "string") ? <p className='text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end'><Svgs.I fill='#eb3b3b' />{error}</p> : (typeof error == "boolean" && error == true) ? <p className='text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end'>{error_message} <Svgs.I fill='#eb3b3b' /></p> : ""}
                {/* {(value && error) &&  <span className='text-[#eb3b3b] flex items-center gap-2 text-xs'>{error}<Svgs.I /></span> } */}
            </div>
        </>
    )
}
InputWithIcon.defaultProps = {
    title: 'Enter "title" prop',
    placeholder: 'Enter "placeholder" prop',
    type: 'text',
    disabled: false
}
export default InputWithIcon