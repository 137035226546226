import React from "react";

import Selection from "new-components/dropdown";

import Input from "new-components/input";
import Popup from "new-components/popup";

import { status, useUpdatePurchaseStatus } from "./helper";
import Textarea from "new-components/text-area";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";

const UpdatePurchaseStatus = ({ handleClose, dataList, updateData, translation, selectedLanguage }) => {
  const { handleSubmit, register, onSubmit, control, fromAddress, errors, watch, data, isLoading, setReceivedValue,
    recievedError, receivedValue } =
    useUpdatePurchaseStatus({ dataList, updateData, handleClose });
  return (
    <Popup heading="Order Status" handleClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-3">
          <div className="grid md:grid-cols-2 gap-3">
            <Input
              name={"total_order"}
              register={register}
              title={selectedLanguage?.name == "English" ? "Total Order" : translation[1053]?.value ? translation[1053]?.value : "Total Order"}
              disabled={true}
              // placeholder={5}
              value={data?.orderStock?.quantity}
              errorMessage={errors?.total_order?.message}
            />
            <Input
              // placeholder={5}
              required={true}
              name={"received"}
              title={selectedLanguage?.name == "English" ? "Received" : translation[1023]?.value ? translation[1023]?.value : "Received"}
              register={register}
              type={"number"}
              onChange={(e) => {
                setReceivedValue(e.target.value);
              }}
              setReceivedValue={setReceivedValue}
              errorMessage={(errors?.received?.message && errors?.received?.message) || (recievedError && recievedError)}
            />
            <Selection
              name={"change_location"}
              label={selectedLanguage?.name == "English" ? "Change Location" : translation[1054]?.value ? translation[1054]?.value : "Change Location"}
              control={control}
              options={fromAddress}
              isDisabled={true}
              placeholder={selectedLanguage?.name == "English" ? "Add Location" : translation[44]?.value ? translation[44]?.value : "Add Location"}
              errorMessage={errors?.change_location?.message}
            />
            <Selection
              name={"status"}
              label={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
              placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[483]?.value : "Select Status"}
              control={control}
              options={status(translation, selectedLanguage)}
              errorMessage={errors?.status?.message}
            />
          </div>
          {/* <Textarea
            title={selectedLanguage?.name == "English" ? "Add Note" : translation[134]?.value ? translation[134]?.value : "Add Note"}
            name={"note"}
            register={register}
            errorMessage={errors?.note?.message}
          /> */}
          <div className="flex items-center gap-3 justify-end">
            <LoginBtn
              onClick={() => {
                handleClose()
              }}
              text={selectedLanguage?.name == "English" ? "Cancel" : translation[165]?.value ? translation[165]?.value : "Cancel"}
            />

            <LoginBtn
              text={selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
              // disabled={data?.orderStock?.is_finished || receivedValue == 0 || isLoading}
              disabled={data?.orderStock?.is_finished || isLoading}
              loading={isLoading}
            />
          </div>
        </div>
      </form>
    </Popup>
  );
};

export default UpdatePurchaseStatus;
