import { useEffect, useState } from "react";

const useDealTypeEightSetting = ({ setErrors, setSettingData, settingData, allMergedOptions }) => {
    const [screenLoader, setScreenLoader] = useState(true)
    const [popup, setPopup] = useState(false)
    const [serviceType, setServiceType] = useState('')
    const [searchApplicable, setSearchApplicable] = useState('')
    const [selectedApplicableItemsArray, setSelectedApplicableItemsArray] = useState([])

    useEffect(() => {
        setTimeout(() => {
            setScreenLoader && setScreenLoader(false)
        }, 1000);
    }, [])

    useEffect(() => {
        const applicableServicesSet = new Set(settingData?.applicableItems?.map(service => service?.itemId));
        const filtered = allMergedOptions?.filter((itm) => applicableServicesSet.has(itm?.id));
        setSelectedApplicableItemsArray(filtered)
    }, [allMergedOptions, settingData?.applicableItems])

    // handle change for deal type 2 dropdown
    const handleChangeForType5DealDropdown = (e, data) => {
        const { name, value } = e.target;
        setSettingData((prev) => {
            const mapingList = data || [];
            const updatedApplicableItems = mapingList?.map((itm) => {
                const filteredItm = prev?.applicableItems?.find(item => item?.itemId == itm)
                return {
                    itemId: itm,
                    qty: filteredItm?.qty ? filteredItm?.qty : ""
                };
            });
            return {
                ...prev,
                applicableItems: updatedApplicableItems,
            };
        });

        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
        setPopup(false)
        setServiceType()
    }


    // handle quantity change 
    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettingData((prev) => ({
            ...prev,
            [name]: value,
        }))
    }
    // handle quantity change 
    const handleDiscountChange = (e, id, type) => {
        const { name, value } = e.target;
        setSettingData((prev) => {
            const existingDiscounts = prev?.applicableItems || [];
            const updatedApplicableItems = existingDiscounts?.map((existingItem) => {
                if (existingItem?.itemId == id) {
                    // Update the quantity for the matching item
                    return {
                        ...existingItem,
                        qty: value,
                    };
                }
                // No match, return the existing item as is
                return existingItem;
            });

            return {
                ...prev,
                applicableItems: updatedApplicableItems,
            };
        });
    };

    // removed selected Item from array
    const removeSelectedItem = (value, type) => {
        const updatedApplicableItems = settingData?.applicableItems?.filter((itm) => itm?.itemId != value)
        setSettingData((prev) => ({
            ...prev,
            applicableItems: updatedApplicableItems
        }))
    }


    return {
        handleChangeForType5DealDropdown, handleChange, screenLoader, handleDiscountChange, removeSelectedItem, popup, setPopup,
        serviceType, setServiceType, searchApplicable, setSearchApplicable, selectedApplicableItemsArray,
        setSelectedApplicableItemsArray
    }
};

export default useDealTypeEightSetting