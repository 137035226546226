import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import useObjectKeys from "Hooks/useObjectKeys";
import { add_client_discount_promotion, get_client_discount_promotion, update_client_discount_promotion } from "Adapters/Api/clientDiscountPromotion";
import moment from "moment";
import { discountErrors, navigateToDiscountPromotion } from "../../helper";
import { getsProducts, getsProductsDropdownList } from "Adapters/Api/Products";
import { getSaleServices } from "Adapters/Api/saleServices";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import { getserviceDropdownList } from "Adapters/Api/services";

const initialState = {
  promotion_name: '',
  categoryDiscount: [{ category: '', discount: '', is_deleted: 'False' }],
  dateRestriction: { startDate: '', endDate: '' },
  blockDate: '',
  blockDates: [],

  serviceRestriction: '',
  serviceRestrictions: [],

  retailRestriction: '',
  retailRestrictions: [],

  // voucherRestriction: '',
  // voucherRestrictions: [],

  storeRestriction: '',
  storeRestrictions: [],

  dayRestrictions: [
    { day: 'Monday', value: '', is_deleted: 'False' },
    { day: 'Tuesday', value: '', is_deleted: 'False' },
    { day: 'Wednesday', value: '', is_deleted: 'False' },
    { day: 'Thursday', value: '', is_deleted: 'False' },
    { day: 'Friday', value: '', is_deleted: 'False' },
    { day: 'Saturday', value: '', is_deleted: 'False' },
    { day: 'Sunday', value: '', is_deleted: 'False' },
  ]
}
export const useAddDiscount = () => {
  const formRef = useRef()
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { setErrors, validation, errors, onBlurHandler } = useValidations();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const dispatch = useDispatch();
  const { objectKeyConvertToArray } = useObjectKeys();
  const businessId = state?.business?.business?.id;
  const [isEdit, setIsEdit] = useState(false);
  const [toggleStore, setToggleStore] = useState(false);
  const [toggleblockdate, setToggleBlockDate] = useState(false);
  const [toggleDay, setToggleDay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(initialState);
  const [formErrors, setFormErrors] = useState()

  const vouchersRedux = state?.voucher?.voucher
  const retailsRedux = state?.product?.products
  const servicesRedux = state?.service?.services
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  // console.log("servicesRedux", state?.saleService);

  const [serviceToggle, setServiceToggle] = useState(false)
  const [retailToggle, setRetailToggle] = useState(false)
  const [voucherToggle, setVoucherToggle] = useState(false)

  const categoryDiscountDropdown = [{ value: 'All', label: 'All' }, { value: 'Service', label: 'Service' }, { value: 'Retail', label: 'Retail' }]
  const locations = state?.locations?.locations;
  const params = useParams();
  const { blockDate } = discount;
  let dateRangeCheck = moment(blockDate).valueOf() >= moment(discount.dateRestriction.startDate).valueOf() && moment(blockDate).valueOf() <= moment(discount.dateRestriction.endDate).valueOf()
  let validateBlockDate = discount?.blockDates?.find(obj => obj.date === blockDate)

  useEffect(() => {
    if (params.flat_discount_id === undefined) {
      setIsEdit(false);
    }
    if (state?.clientDiscountPromotion?.discountPromotions?.length === 0 && params.flat_discount_id) {
      dispatch(get_client_discount_promotion())
      setIsEdit(true);
    }
    if (params.flat_discount_id) {
      setIsEdit(true);
    }
  }, [params.flat_discount_id, state?.clientDiscountPromotion?.discountPromotions?.length]);

  useEffect(() => {
    let editData = state?.clientDiscountPromotion?.discountPromotions?.find((obj) => obj.id === params.flat_discount_id);
    if (editData) {
      let { category_discount, id, date_restrictions, block_date, day_restrictions, promotion_name, excluded_products, excluded_services, excluded_vouchers } = editData;
      let { start_date, end_date, business_address } = date_restrictions;

      let dayRestrictions = initialState?.dayRestrictions?.map((item) => {
        let obj = day_restrictions.find(obj => obj.day == item.day);
        if (obj?.day === item.day) {
          return {
            id: obj?.id,
            day: obj?.day,
            value: obj?.day,
            is_deleted: obj?.is_deleted
          }
        } else {
          return { ...item }
        }
      })
      day_restrictions?.length > 0 && setToggleDay(true);
      business_address?.length > 0 && setToggleStore(true);
      block_date?.length > 0 && setToggleBlockDate(true);
      excluded_services?.length > 0 && setServiceToggle(true)
      excluded_products?.length > 0 && setRetailToggle(true)
      // excluded_vouchers?.length > 0 && setVoucherToggle(true)

      if (editData && date_restrictions) {
        setDiscount({
          ...discount,
          promotion_name: promotion_name,
          id: id,
          categoryDiscount: category_discount?.map((obj) => { return { ...obj, category: obj?.category_type, discount: obj?.discount } }),
          dateRestriction: { startDate: start_date, endDate: end_date },
          blockDates: block_date,
          storeRestrictions: business_address,

          serviceRestrictions: excluded_services.map(itm => itm.id),
          retailRestrictions: excluded_products.map(itm => itm.id),
          // voucherRestrictions: excluded_vouchers.map(itm => itm.id),

          dayRestrictions: dayRestrictions
        })
      }
    }
  }, [isEdit, state?.clientDiscountPromotion?.discountPromotions?.length])

  let start_date;
  let end_date;

  const validateDateRange = () => {
    if (moment(end_date).valueOf() <= moment(start_date).valueOf()) {
      return "End date must be greater than start date";
    }
    return null;
  }


  const handleChange = (e, index) => {
    const { value, name } = e.target;

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));


    if (name === 'startDate' || name === 'endDate') {

      if (name === 'startDate') {
        start_date = value
        setDiscount((prev) => ({
          ...prev,
          dateRestriction: { ...prev.dateRestriction, endDate: "" }
        }));
      }
      if (name === 'endDate')
        end_date = value

      setDiscount((prev) => ({
        ...prev,
        dateRestriction: { ...prev?.dateRestriction, [name]: value }
      }));


      // const error = validateDateRange();
      // setErrors((prev) => ({
      //   ...prev,
      //   [name]: error,
      // }));

    }

    if (name === 'dayRestrictions') {
      setDiscount((prev) => ({
        ...prev,
        dayRestrictions: discount?.dayRestrictions?.map(obj => {
          if (obj.day === name) {
            return {
              ...obj,
              value: value
            }
          } else {
            return obj
          }
        })
      }));
    }
    if (name === 'blockDate') {
      let validateBlock = discount?.blockDates?.find(obj => obj.date === value)

      let dateRangeCheck = moment(value).valueOf() >= moment(discount.dateRestriction.startDate).valueOf() && moment(value).valueOf() <= moment(discount.dateRestriction.endDate).valueOf()
      if (!dateRangeCheck) {
        setErrors((prev) => ({
          ...prev,
          [name]: discountErrors.dateRange,
        }));
      }

      if (dateRangeCheck) {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
      if (validateBlock) {
        setErrors((prev) => ({
          ...prev,
          [name]: discountErrors.dateAlreadySelected,
        }));
      }
      setDiscount((prev) => ({
        ...prev,
        [name]: value
      }));
    }
    else {
      setDiscount((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  }

  const removeCategoryDiscountField = (id, index) => {
    if (id) {  // edit 
      var filter = discount?.categoryDiscount?.map((obj) => {
        if (obj === discount?.categoryDiscount[index]) {
          return {
            ...obj,
            is_deleted: 'True'
          }
        }
        else {
          return { ...obj }
        }
      })
      setDiscount((prev) => ({
        ...prev,
        categoryDiscount: filter,
      }))
    }
    else { // create no need of isDelete here 
      let filter = discount.categoryDiscount.filter((obj) => obj !== discount.categoryDiscount[index])
      setDiscount((prev) => ({
        ...prev,
        categoryDiscount: filter
      }))
    }
  }

  const addBlockDates = () => {
    setDiscount((prev) => ({
      ...prev,
      blockDates: [...prev.blockDates, { date: discount.blockDate, is_deleted: 'False' }],
      blockDate: ''
    }))
  }

  const serviceDropdown = useMemo(() => {
    return servicesRedux?.map((obj) => {
      if (obj) {
        return {
          label: obj?.name,
          value: obj?.id
        }
      }
    })
  }, [servicesRedux])
  const vouchersDropdown = useMemo(() => {
    return vouchersRedux?.map((obj) => {
      if (obj) {
        return {
          label: obj?.name,
          value: obj?.id
        }
      }
    })
  }, [vouchersRedux])
  // console.log("vouchersRedux", vouchersDropdown);


  const retailDropdown = useMemo(() => {
    return retailsRedux?.map((obj) => {
      if (obj) {
        return {
          label: obj?.name,
          value: obj?.id
        }
      }
    })
  }, [retailsRedux])

  const addStoreRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      storeRestrictions: [...prev.storeRestrictions, discount.storeRestriction],
      storeRestriction: ''
    }))
    setErrors((prev) => ({
      ...prev,
      storeRestriction: "",
    }));
  }

  const addServiceRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      serviceRestrictions: [...prev.serviceRestrictions, discount.serviceRestriction],
      serviceRestriction: ''
    }))
  }
  const addRetailRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      retailRestrictions: [...prev.retailRestrictions, discount.retailRestriction],
      retailRestriction: ''
    }))
  }
  const addVoucherRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      voucherRestrictions: [...prev.voucherRestrictions, discount.voucherRestriction],
      voucherRestriction: ''
    }))
  }

  const locationDropdown = useMemo(() => {
    return locations?.map((obj) => {
      if (obj) {
        return {
          label: obj?.address_name,
          value: obj?.id
        }
      }
    })
  }, [locations])

  let success = () => {
    setLoading(false);
    setDiscount(initialState);
    navigateToDiscountPromotion(navigate)
    setToggleDay(false);
    setToggleStore(false);
    setToggleBlockDate(false)
  }
  let fail = () => {
    setLoading(false);
  }
  const onSubmit = () => {
    // let voucher = [];
    let retail = [];
    let services = [];

    // if (discount.categoryDiscount.find(itm => itm.category === 'Voucher'))
    //   voucher = discount.voucherRestrictions

    if (discount.categoryDiscount.find(itm => itm.category === 'Retail'))
      retail = discount.retailRestrictions

    if (discount.categoryDiscount.find(itm => itm.category === 'Service'))
      services = discount.serviceRestrictions

    if (discount.categoryDiscount.find(itm => itm.category === 'All')) {
      // voucher = discount.voucherRestrictions
      retail = discount.retailRestrictions
      services = discount.serviceRestrictions
    }

    const requiredFields = {
      promotion_name: discount.promotion_name,
      startDate: discount.dateRestriction.startDate,
      endDate: discount.dateRestriction.endDate,
      storeRestriction: discount?.storeRestrictions?.length > 0 ? true : false
    }
    setErrors(validation(requiredFields));
    let result = validation(requiredFields);

    if (objectKeyConvertToArray(result)?.length === 0) {
      const errors = [];
      discount.categoryDiscount?.forEach((data, index) => {
        const fieldErrors = {};
        if (!data.category) {
          fieldErrors.category = 'This field is required';
        }
        if (!data.discount) {
          fieldErrors.discount = 'This field is required';
        }
        errors[index] = fieldErrors;
      });
      // Update formErrors state with the new errors
      setFormErrors(errors);
      // If there are errors, return early and do not proceed with form submission
      if (errors?.some((error) => Object.keys(error)?.length > 0)) {
        formRef.current?.scrollIntoView({ behavior: "smooth" });
        return;
      } else {
        setLoading(true);
        let payload = {
          business: businessId,
          promotion_name: discount.promotion_name,
          start_date: discount.dateRestriction.startDate,
          end_date: discount.dateRestriction.endDate,
          categorydiscount: discount.categoryDiscount,
          blockdate: discount?.blockDates,
          location: discount.storeRestrictions,
          // voucher: voucher,
          product: retail,
          services: services,
        }

        if (isEdit) {
          if (payload) {
            payload.id = discount.id;
            payload.dayrestrictions = discount?.dayRestrictions?.filter(obj => obj.value || obj.is_deleted === "True")
            dispatch(update_client_discount_promotion(payload, access_token, success, fail, () => {
              setLoading(false);
            }))
          }
        } else {
          if (payload) {
            payload.dayrestrictions = discount?.dayRestrictions?.filter(obj => obj.value)?.map(obj => { return { day: obj.value } });
            dispatch(add_client_discount_promotion(payload, access_token, success, fail))
          }
        }
      }
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }

  const get_products = async () => {
    const response = await getsProductsDropdownList();
    if (response.status === 200) {
      let productList = response?.data?.response?.products;
      dispatch(getProducts(productList));
    }
  };

  useEffect(() => {
    dispatch(getserviceDropdownList());
    get_products();
  }, [])

  return {
    errors, categoryDiscountDropdown, toggleDay, toggleStore, discount, loading, locationDropdown, isEdit, dateRangeCheck, validateBlockDate,
    handleChange, onBlurHandler, navigate, setDiscount, removeCategoryDiscountField, setErrors, addStoreRestriction, onSubmit,
    addBlockDates, setToggleDay, setToggleStore, toggleblockdate, setToggleBlockDate, translation, selectedLanguage, formErrors,
    setFormErrors, formRef,
    addServiceRestriction,
    addRetailRestriction,
    addVoucherRestriction,

    serviceDropdown,
    vouchersDropdown,
    retailDropdown,

    serviceToggle, setServiceToggle,
    retailToggle, setRetailToggle,
    voucherToggle, setVoucherToggle,
  };
};
