import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import StatusBtn from "../../../../Elements/StatusBtn";
import NotFound from "../../Elements/NotFound";

import { getSaleOrderMembership } from "Adapters/Api/saleOrders";

import Svgs from "Assets/svgs";

const Membership = ({ filter_data, setLengths, ...props }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const location_currency = state?.utility?.currency?.location_currency;



  const filterProducts = () => {
    return state?.saleOrder?.saleOrderMemberships?.filter((itm) => {
      if (Object.keys(filter_data).length > 0) {
        let returned_value = false;
        if (filter_data?.text) {
          let value = filter_data?.text.toLowerCase();
          if (
            itm.client?.full_name?.toLowerCase().includes(value) ||
            itm.member?.full_name.toLowerCase().includes(value)
          ) {
            returned_value = true;
          } else {
            return false;
          }
        }
        if (filter_data?.client) {
          if (filter_data?.client == itm?.client?.id) {
            returned_value = true;
          } else {
            return false;
          }
        }
        if (filter_data?.location) {
          if (filter_data?.location == itm?.location?.id) {
            returned_value = true;
          } else {
            return false;
          }
        }
        if (filter_data?.member) {
          if (filter_data?.member == itm?.member?.id) {
            returned_value = true;
          } else {
            return false;
          }
        }
        return returned_value;
      }
      return true;
    });
  };

  useEffect(() => {
    if (state?.saleOrder?.saleOrderMemberships.length === 0) {
      dispatch(getSaleOrderMembership());
    }

    setLengths(filterProducts().length)
  }, [filterProducts().length]);

  return (
    <div className="overflow-x-auto">
      <div className="inline-grid xl:grid grid-cols-[repeat(7,minmax(140px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
        <p>Membership</p>
        <p>Client </p>
        <p>Employee</p>
        {/* <p>Start Date</p>
        <p>End Date</p> */}
        <p>Status</p>
        <p>Total Price</p>
      </div>
      <div className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}>
        {filterProducts().length > 0 ? (
          filterProducts().map((membr) => {
            let sale_start_date;
            let sale_end_date;
            if (membr?.start_date || membr?.end_date) {
              if (membr?.start_date) {
                const stateDate =
                  membr?.start_date && membr?.start_date?.split("-");
                const date_join = stateDate[2].split("").slice(0, 2).join("");
                stateDate.splice(2, 1, date_join);
                sale_start_date = stateDate.join("-");
              }
              if (membr?.end_date) {
                const endDate = membr?.end_date && membr?.end_date?.split("-");
                const date_join = endDate[2].split("").slice(0, 2).join("");
                endDate.splice(2, 1, date_join);
                sale_end_date = endDate.join("-");
              }
            }
            return (
              <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(7,minmax(140px,1fr))]">
                <div className="flex items-center">
                  <div className="flex items-center gap-4">
                    <div className="h-[3.3rem] w-[3.3rem] bg-primary rounded-lg flex items-center justify-center">
                      <Svgs.VoucherCard fill="#FFFFFF" size="34" />
                    </div>
                    <div>{membr?.membership ? membr?.membership : "----"}</div>
                  </div>
                </div>
                <div className="flex items-center">
                  <p className="text-sm">{membr?.client?.full_name ? membr?.client?.full_name : "----"}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-sm ">{membr?.member?.full_name ? membr?.member?.full_name : "----"}</p>
                </div>
                {/* <div className="flex items-center">
                  <p className="text-sm ">{sale_start_date}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-sm ">{sale_end_date}</p>
                </div> */}
                <div className="flex items-center">
                  <StatusBtn
                    rounded="rounded-md"
                    status="active"
                    size="sm"
                    text={membr?.status}
                  />
                </div>
                <div className="flex items-center">
                  <p className="text-sm ">{membr?.total_price ? membr?.total_price : 0} {location_currency}</p>
                </div>
              </div>
            );
          })
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};

export default Membership;
