import React, { useState, useEffect } from "react";

import AccountSetupCard from "../../AccountSetupCard";

import Svgs from "Assets/svgs";
import { useSelector } from "react-redux";

const DashboardInventory = () => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

      <div className="flex items-center gap-5 lg:w-fit w-full lg:px-0 lg:pb-[2rem] lg:py-0 p-3 pb-8">
        <Svgs.Back link="/dashboard/account" />
        <h2 className="font-semibold text-2xl">
          {selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations"}
        </h2>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem] xl:pr-[4rem] pr-0">
        {!employee ||
          (employee && employeePermissions?.pos_root_access?.includes("view")) ? (
          <AccountSetupCard
            link="/dashboard/account/inventory-operations/category"
            icon={<Svgs.Inventory_Category />}
            title={selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}
            description={selectedLanguage?.name == "English" ? "Add, edit, and delete product’s categories." : translation[865]?.value ? translation[865]?.value : "Add, edit, and delete product’s categories."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && employeePermissions?.pos_root_access?.includes("view")) ? (
          <AccountSetupCard
            link="/dashboard/account/inventory-operations/brands"
            icon={<Svgs.Inventory_Brands />}
            title={selectedLanguage?.name == "English" ? "Brands" : translation[836]?.value ? translation[836]?.value : "Brands"}
            description={selectedLanguage?.name == "English" ? "Add, edit, and delete product’s brands." : translation[866]?.value ? translation[866]?.value : "Add, edit, and delete product’s brands."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && employeePermissions?.pos_root_access?.includes("view")) ? (
          <AccountSetupCard
            link="/dashboard/account/inventory-operations/vendors"
            icon={<Svgs.Inventory_Vendors />}
            title={selectedLanguage?.name == "English" ? "Vendors" : translation[867]?.value ? translation[867]?.value : "Vendors"}
            description={selectedLanguage?.name == "English" ? "Manage your business’ vendors to order stock." : translation[868]?.value ? translation[868]?.value : "Manage your business’ vendors to order stock."}
          />
        ) : (
          ""
        )}



        {!employee ||
          (employee &&
            (employeePermissions?.product_root_access?.includes("view")
              || employeePermissions?.inventory_root_access?.includes("view")
              || (employee && employeePermissions?.pos_root_access?.includes("view")))) ? (
          <AccountSetupCard
            link="/dashboard/account/inventory-operations/products"
            icon={<Svgs.Inventory_Products />}
            title={selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
            description={selectedLanguage?.name == "English" ? "Add products and stock details." : translation[869]?.value ? translation[869]?.value : "Add products and stock details."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee &&
            (employeePermissions?.inventory_purchase_order?.includes("view")
              || employeePermissions?.inventory_purchase_order?.includes("create")
              || employeePermissions?.inventory_purchase_order?.includes("edit")
              || employeePermissions?.inventory_root_access?.includes("view")
              || (employee && employeePermissions?.pos_root_access?.includes("view")))) ? (
          <AccountSetupCard
            link="/dashboard/account/inventory-operations/purchases-orders"
            icon={<Svgs.Inventory_Products />}
            title={selectedLanguage?.name == "English" ? "Purchases Orders" : translation[870]?.value ? translation[870]?.value : "Purchases Orders"}
            description={selectedLanguage?.name == "English"
              ? "Order stock and filter purchases based on the available options." : translation[871]?.value
                ? translation[871]?.value : "Order stock and filter purchases based on the available options."
            }
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee &&
            (employeePermissions?.inventory_consumption?.includes("view")
              || employeePermissions?.inventory_consumption?.includes("create")
              || employeePermissions?.inventory_consumption?.includes("edit")
              || employeePermissions?.inventory_root_access?.includes("view")
              || (employee && employeePermissions?.pos_root_access?.includes("view")))) ? (
          <AccountSetupCard
            link="/dashboard/account/inventory-operations/consumptions"
            icon={<Svgs.Consumptions />}
            title={selectedLanguage?.name == "English" ? "Consumptions" : translation[872]?.value ? translation[872]?.value : "Consumptions"}
            description={selectedLanguage?.name == "English"
              ? "Add consumptions in stock based on selected items." : translation[873]?.value
                ? translation[873]?.value : "Add consumptions in stock based on selected items."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee &&
            (employeePermissions?.inventory_stock_transfer?.includes("view")
              || employeePermissions?.inventory_stock_transfer?.includes("create")
              || employeePermissions?.inventory_stock_transfer?.includes("edit")
              || employeePermissions?.inventory_root_access?.includes("view")
              || (employee && employeePermissions?.pos_root_access?.includes("view")))) ? (
          <AccountSetupCard
            link="/dashboard/account/inventory-operations/stocks-transfer"
            icon={<Svgs.Inventory_Stock />}
            title={selectedLanguage?.name == "English" ? "Stocks Transfer" : translation[874]?.value ? translation[874]?.value : "Stocks Transfer"}
            description={selectedLanguage?.name == "English" ? "Manage stocks availability, usage, export stock reports." : translation[875]?.value ? translation[875]?.value : "Manage stocks availability, usage, export stock reports."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee &&
            (employeePermissions?.inventory_stock_report?.includes("view")
              || employeePermissions?.inventory_root_access?.includes("view")
              || (employee && employeePermissions?.pos_root_access?.includes("view")))) ? (
          <AccountSetupCard
            link="/dashboard/account/inventory-operations/stocks"
            icon={<Svgs.Inventory_Stock />}
            title={selectedLanguage?.name == "English" ? "Stock Reports" : translation[879]?.value ? translation[879]?.value : "Stock Reports"}
            description={selectedLanguage?.name == "English" ? "Manage stocks availability, usage, export stock reports." : translation[875]?.value ? translation[875]?.value : "Manage stocks availability, usage, export stock reports."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee &&
            (employeePermissions?.inventory_report?.includes("view")
              || employeePermissions?.inventory_root_access?.includes("view")
              || (employee && employeePermissions?.pos_root_access?.includes("view")))) ? (
          <AccountSetupCard
            link="/dashboard/account/inventory-operations/reports"
            icon={<Svgs.Inventory_Reports />}
            title={selectedLanguage?.name == "English" ? "Inventory Reports" : translation[876]?.value ? translation[876]?.value : "Inventory Reports"}
            description={selectedLanguage?.name == "English" ? "Manage stock, products sales reports, apply filters, and export." : translation[877]?.value ? translation[877]?.value : "Manage stock, products sales reports, apply filters, and export."}

          />
        ) : (
          ""
        )}

        {/* {!employee ||
          (employee &&
            (employeePermissions?.inventory_goods_receipt?.includes("view")
              || employeePermissions?.inventory_goods_receipt?.includes("create")
              || employeePermissions?.inventory_goods_receipt?.includes("edit")
              || employeePermissions?.inventory_root_access?.includes("view")
              || (employee && employeePermissions?.pos_root_access?.includes("view")))) ? ( */}
        <AccountSetupCard
          link="/dashboard/account/inventory-operations/refund-reports"
          icon={<Svgs.Inventory_Reports />}
          // title="Good Receipt"
          title={selectedLanguage?.name == "English" ? "Refund Reports" : translation[1662]?.value ? translation[1662]?.value : "Refund Reports"}
          description={selectedLanguage?.name == "English" ? "Manage refund stock, refund products sales reports, apply filters, and export." : translation[1663]?.value ? translation[1663]?.value : "Manage refund stock, refund products sales reports, apply filters, and export."}

        />

        {/* ) : (
          ""
        )} */}
      </div>
    </div>
  );
};
export default DashboardInventory;
