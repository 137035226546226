import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Popup from 'Components/TenantApp/Elements/Popup';
import React from 'react'
import useHolidayPopup from './helper';
import Textarea from 'Components/TenantApp/Elements/Textarea';

const AddHoliday = ({ data, isEdit, selectedLocation, setIsEdit, CreateHoliday, setCreateHoliday, successCallBack }) => {

  const {
    moment, submitLoader, maxStartDateLimit, minStartDateLimit,
    indexValue, setIndexValue,
    setErrors, holidayForm, onChangeHandler, onBlurHandler,
    translation, selectedLanguage, errors,
    onSubmitHandler, minToDateLimit
  } = useHolidayPopup({ setCreateHoliday, data, selectedLocation, setIsEdit, isEdit, successCallBack })
  const { name, note, start_date, end_date } = holidayForm

  return (
    <Popup
      size={"md"}
      heading={selectedLanguage?.name == "English" ? "Add Holiday" : translation[1474]?.value ? translation[1474]?.value : "Add Holiday"}
      open={CreateHoliday}
      close={() => {
        setCreateHoliday(false)
        setIsEdit(false)
      }}
    >
      <div className="flex flex-col gap-5">

        <div>
          <div className="flex flex-col gap-3">
            <LoginInput
              title={selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Name" : translation[1090]?.value ? translation[1090]?.value : "Enter Name"}
              required={true}
              name={'name'}
              value={name}
              onChange={onChangeHandler}
              onEnterSubmit={onChangeHandler}
              error={errors?.name}
            />
            <div className='flex gap-4'>
              <LoginInput
                parentClass={"w-full"}
                title={selectedLanguage?.name == "English" ? "From Date" : translation[635]?.value ? translation[635]?.value : "From Date"}
                type='date'
                name='start_date'
                value={start_date}
                min={moment().format("YYYY-MM-DD")}
                max={"2050-01-01"}
                onChange={onChangeHandler}
                error={errors.start_date}
              />
              <LoginInput
                required={false}
                parentClass={"w-full"}
                title={selectedLanguage?.name == "English" ? "To Date" : translation[636]?.value ? translation[636]?.value : "To Date"}
                type='date'
                name='end_date'
                value={end_date}
                onChange={onChangeHandler}
                min={minToDateLimit}
                error={errors.end_date}
              />
            </div>
            <Textarea
              title={selectedLanguage?.name == "English" ? "Note" : translation[609]?.value ? translation[609]?.value : "Note"}
              rows={5}
              placeholder={selectedLanguage?.name == "English" ? "Enter Note" : translation[1477]?.value ? translation[1477]?.value : "Enter Note"}
              required={false}
              name={'note'}
              value={note}
              limit={150}
              onChange={onChangeHandler}
              onEnterSubmit={onChangeHandler}
              errorMessage={errors?.note}
            />
          </div>
        </div>


        <div className="flex justify-end">
          <LoginBtn
            loading={submitLoader}
            onClick={onSubmitHandler}
            disabled={submitLoader}
            text={
              isEdit ?
                selectedLanguage?.name == "English" ? "Update Holiday" : translation[1478]?.value ? translation[1478]?.value : "Update Holiday"
                :
                selectedLanguage?.name == "English" ? "Add Holiday" : translation[1474]?.value ? translation[1474]?.value : "Add Holiday"
            }
          />
        </div>
      </div>
    </Popup>
  )
}

export default AddHoliday