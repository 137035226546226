import axios from "axios";
import { EMPLOYEES } from "../../../Redux/TenantRedux/ActionTypes/employeeTypes";
import {
  get_employee_url,
  add_employee_url,
  get_single_employee,
  delete_employee_url,
  update_employee_url,
  BASE_URL,
  search_employee_url,
  get_employee_import,
  get_unique_employee_id,
  get_working_shedule,
  create_assets,
  get_assets,
  delete_asset,
  update_asset,
  check_email_employees,
  get_employee_With_page,
  get_employee_dashboard,
  get_employees_for_dropdown,
  get_employee_mainpage,
  delete_all__workingschedule,
} from "../api_variables";
import { getEmployees, getEmployeesDropdown, getEmployeesDropdownPaginationData, getEmployeesSalesCart, getEmployeesStaffData, getEmployeesWithoutPagination } from "./../../../Redux/TenantRedux/Actions/employeeActions/index";
import { buildQueryString } from "Constants/Functions/buildQueryString";

export const getEmployeeList = (currentPage, selected_location, search_text, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const location = selected_location ? `&location_id=${selected_location}` : ''
  const search = search_text ? `&search_text=${search_text}` : ''
  let url;
  if (currentPage) {
    url = `${BASE_URL}${get_employee_With_page}?page=${currentPage}${location}${search}`
  }
  else {
    url = `${BASE_URL}${get_employee_url}`
  }
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      let employeeslist = response?.data?.response?.employees;
      let employeePage = response?.data;
      dispatch(getEmployees(employeeslist));
      dispatch({
        type: EMPLOYEES.GET_EMPLOYEE_PAGES,
        payload: employeePage,
      })
      setLoader && setLoader(false)
    }
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getEmployeeListSalesCart = (selected_location, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const location = selected_location ? `?location_id=${selected_location}` : ''
  let url = `${BASE_URL}${get_employees_for_dropdown}${location}`

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      let employeeslist = response?.data?.response?.employees;
      dispatch(getEmployeesSalesCart(employeeslist));
      setLoader && setLoader(false)
    }
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getEmployeeListMainPage = (currentPage, selected_location, search_text, status, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const location = selected_location ? `?location_id=${selected_location}` : ""
  const search = search_text ? `&search_text=${search_text}` : ""
  const activeStatus = status ? `&is_active=${status}` : ""
  const page = currentPage ? `&page=${currentPage}` : ""

  let url = `${BASE_URL}${get_employee_mainpage}${location}${page}${search}${activeStatus}`

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      let employeeslist = response?.data?.response?.employees;
      let employeePage = response?.data;
      dispatch(getEmployees(employeeslist));
      dispatch({
        type: EMPLOYEES.GET_EMPLOYEE_PAGES,
        payload: employeePage,
      })
      setLoader && setLoader(false)
    }
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getEmployeeListWithNoPagination = (selected_location, search_text, status, setNoPaginationLoader) => async (dispatch) => {
  setNoPaginationLoader && setNoPaginationLoader(true)
  const search = search_text ? `&search_text=${search_text}` : ''
  const location = selected_location ? `&location_id=${selected_location}` : ''
  const activeStatus = status ? `&is_active=${status}` : ""
  let url = `${BASE_URL}${get_employee_mainpage}?no_pagination=true${location}${search}${activeStatus}`

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      let employeeslist = response?.data?.response?.employees;
      dispatch(getEmployeesWithoutPagination(employeeslist));
      setNoPaginationLoader && setNoPaginationLoader(false)
    }
    setNoPaginationLoader && setNoPaginationLoader(false)
  } catch (error) {
    setNoPaginationLoader && setNoPaginationLoader(false)
    return error;
  }
};

export const getCalendarEmployeeDropDownList = (selected_location, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const location = selected_location ? `?location_id=${selected_location}` : ''
  let url = `${BASE_URL}${get_employees_for_dropdown}${location}&is_calendar=true`
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      let employeeslist = response?.data?.response?.employees;
      dispatch(getEmployees(employeeslist));
      dispatch(getEmployeesDropdown(employeeslist));
      setLoader && setLoader(false)
    }
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getEmployeeDropDownList = (selected_location, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const location = selected_location ? `?location_id=${selected_location}` : ''
  let url = `${BASE_URL}${get_employees_for_dropdown}${location}`
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      let employeeslist = response?.data?.response?.employees;
      dispatch(getEmployees(employeeslist));
      dispatch(getEmployeesDropdown(employeeslist));
      setLoader && setLoader(false)
    }
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getEmployeeDropDownListPagination = (employeePage, selected_location, employeeSearch, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const page = employeePage ? `?page=${employeePage}` : ''
  const location = selected_location ? `&location_id=${selected_location}` : ''
  const search = employeeSearch ? `&search_text=${employeeSearch}` : ''
  let url = `${BASE_URL}${get_employees_for_dropdown}${page}${location}${search}`
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      // let employeeslist = response?.data;
      dispatch(getEmployeesDropdownPaginationData(response?.data));
      // delete this action after implementing new conditions
      // dispatch(getEmployeesDropdown(employeeslist));
      setLoader && setLoader(false)
    }
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getEmployeeDropDownListPaginationForWeekend = (payload, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const query = buildQueryString(payload)
  let url = `${BASE_URL}${get_employees_for_dropdown}${query}`
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      dispatch(getEmployeesDropdownPaginationData(response?.data));
      setLoader && setLoader(false)
    }
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getEmployeeListDasboard = (selected_location, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const location = selected_location ? `?location_id=${selected_location}` : ''
  let url = `${BASE_URL}${get_employee_dashboard}${location}`

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      let employeeslist = response?.data?.response?.Employee;
      dispatch(getEmployees(employeeslist));
      setLoader && setLoader(false)
    }
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getStaffData = (currentPage, searchText, employee, designation, income_type, selected_location, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const location = selected_location ? `&location_id=${selected_location}` : ''
  const search = searchText ? `&search_text=${searchText}` : ''
  const employeeId = employee ? `&employee_id=${employee}` : ''
  const employeeDesignation = designation ? `&designation=${designation}` : ''
  const incomeType = income_type ? `&income_type=${income_type}` : ''
  // const 
  let url = `${BASE_URL}${get_employee_With_page}?page=${currentPage}${location}${search}${employeeId}${employeeDesignation}${incomeType}`

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      let employeeslist = response?.data?.response?.employees;
      let employeePage = response?.data;
      dispatch(getEmployeesStaffData(employeeslist));
      dispatch({
        type: EMPLOYEES.GET_EMPLOYEE_PAGES,
        payload: employeePage,
      })
      setLoader && setLoader(false)
    }
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getStaffDataWithNoPagination = (searchText, employee, designation, income_type, selected_location, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const location = selected_location ? `&location_id=${selected_location}` : ''
  const search = searchText ? `&search_text=${searchText}` : ''
  const employeeId = employee ? `&employee_id=${employee}` : ''
  const employeeDesignation = designation ? `&designation=${designation}` : ''
  const incomeType = income_type ? `&income_type=${income_type}` : ''
  // const 
  let url = `${BASE_URL}${get_employee_With_page}?no_pagination=true${location}${search}${employeeId}${employeeDesignation}${incomeType}`

  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      let employeeslist = response?.data?.response?.employees;
      let employeePage = response?.data;
      dispatch(getEmployeesWithoutPagination(employeeslist));
      dispatch({
        type: EMPLOYEES.GET_EMPLOYEE_PAGES,
        payload: employeePage,
      })
      setLoader && setLoader(false)
    }
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getsEmployeesImport = async (data, success) => {
  // -------------------real token------------------------
  let token = localStorage.getItem("access_token");
  if (token) {
    token = token.replaceAll('"', "");
  }

  let form_data = new FormData();
  form_data.append("file", data.file);
  form_data.append("business", data.business);

  fetch(`${BASE_URL}${get_employee_import}`, {
    method: "POST",
    headers: {
      Authorization: `Token ${token}`,
    },
    body: form_data,
  })
    .then((response) => {
      if (response?.status == 200) {
        success();
        return response.json();
      }
    })
    .then((result) => { });
};

export const createEmployees = async (employee_data, access_token) => {
  try {
    const result = await axios.post(
      `${BASE_URL}${add_employee_url}`,
      employee_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const deleteEmployee = async (employee_id, access_token) => {
  try {
    let result = await fetch(`${BASE_URL}${delete_employee_url}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${access_token}`,
      },
      body: JSON.stringify({ employee: employee_id }),
    });
    result = result.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const updateEmployee = async (update_employee_data, access_token) => {
  try {
    const result = await axios.put(
      `${BASE_URL}${update_employee_url}`,
      update_employee_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const getSingleEmployee = async (employee_id) => {
  try {
    const result = await axios.get(
      `${BASE_URL}${get_single_employee}?employee_id=${employee_id}`
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const searchEmploye = (data, success, fail) => (dispatch) => {
  fetch(BASE_URL + search_employee_url + `?text=${data?.text}`)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      dispatch(getEmployees(result.response.Employees));
    })
    .catch((err) => { });
};

export const getUniqueEmployeeId = async (token) => {
  let result = await fetch(`${BASE_URL}${get_unique_employee_id}`, {
    headers: { Authorization: `Token ${token}` },
  });
  result = result.json();
  return result;
};

export const getWorkingShedule = (payload, setLoader) => (dispatch) => {
  const query = buildQueryString(payload)
  setLoader && setLoader(true)
  let s_code;
  fetch(`${BASE_URL}${get_working_shedule}${query}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        let data = result?.response?.employees;
        dispatch({
          type: EMPLOYEES.GET_WORKING_SCHEDULE,
          payload: data,
        });
        setLoader && setLoader(false)
      }
      setLoader && setLoader(false)
    })
    .catch((err) => {
      setLoader && setLoader(false)
      // console.log("err in get working schedule ::", err);
    });
};

export const deleteAllWorkingSchedules = () => (dispatch) => {
  let s_code;
  fetch(`${BASE_URL}${delete_all__workingschedule}`, {
    method: "DELETE"
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
    })
    .catch((err) => {
      // console.log("err in get working schedule ::", err);
    });
};

export const createAsset =
  (form_data, access_token, success, fail) => (dispatch) => {
    fetch(BASE_URL + create_assets, {
      method: "POST",
      body: form_data,
      headers: {
        Authorization: `Token ${access_token}`,
      },
    })
      .then((result) => {
        if (result.ok) {
          return result.json();
        }
      })
      .then((result) => {
        if (result?.status_code === 201) {
          dispatch({
            type: EMPLOYEES.ADD_ASSETS,
            payload: result?.response?.asset,
          });
          success && success();
        } else {
          fail && fail();
        }
      })
      .catch((err) => {
        fail && fail();
      });
  };

export const updateAsset = (data, success, fail) => (dispatch) => {
  let token = localStorage.getItem("access_token");
  if (token) {
    token = token.replaceAll('"', "");
  }

  fetch(BASE_URL + update_asset, {
    method: "PUT",
    body: data,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 200) {
        success && success();
        dispatch({
          type: EMPLOYEES.UPDATE_ASSETS,
          payload: result?.response?.asset,
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const getAllAssests = (access_token) => (dispatch) => {
  fetch(BASE_URL + get_assets, {
    headers: {
      Authorization: `Token ${access_token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      dispatch({
        type: EMPLOYEES.GET_ASSETS,
        payload: result?.response?.asset,
      });
    })
    .catch((error) => {
      console.log("error ::", error);
    });
};

export const deleteAsset = (id, token, success, fail) => (dispatch) => {
  fetch(BASE_URL + delete_asset, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify({ id }),
  })
    .then((result) => {
      if (result.ok) {
        return result.json();
      }
    })
    .then((response) => {
      dispatch({
        type: EMPLOYEES.DELETE_ASSETS,
        payload: id,
      });
      success && success();
    })
    .catch((err) => {
      fail && fail();
      console.log("err ::", err);
    });
};





export const checkEmployeeEmail = async (employee_email_mobileNumber, success, fail) => {
  try {
    const result = await axios.post(
      `${BASE_URL}${check_email_employees}`,
      employee_email_mobileNumber,
      // {
      //   headers: { Authorization: `Token ${access_token}` },
      // }
    );
    if (result.status === 200) {
      success && success(result?.data?.response);
    } else {
      fail && fail();
    }

    return result;
  } catch (error) {
    fail && fail();
    return error;
  }
};