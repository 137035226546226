import { FORGET_PASSWORD } from "../../ActionTypes/forgotPasswordTypes";

const userPasswordDetails = {
 email:"",
 mobile_number:"",
 verifications:""
}

const ForgetPasswordReducer = (state = userPasswordDetails, action) => {
    
  const { type, payload } = action;

  switch (type) {
    case FORGET_PASSWORD.EMAIL:
      return {
        ...state,
        email: payload,
      };

      case FORGET_PASSWORD.MOBILE_NUMBER:
      return {
        ...state,
        mobile_number: payload,
      };


      case FORGET_PASSWORD.VERIFICATIONS:
      return {
        ...state,
        verifications: payload,
      };

    default:
      return state;
  }

};

export default ForgetPasswordReducer;