import React, { useEffect, useRef, useState } from 'react'
import { BASE_URL, country_code_url } from '../../../Adapters/Api/api_variables';
import { DIAL_CODES } from '../../../Constants/Data/DialCodes';
import Dropwdown from './Dropwdown';
import Svgs from '../../../Assets/svgs'
import { error_message } from 'Constants/Variables';

const PhoneInput = ({ onEnterSubmit, title, placeholder, isCapitalized, name, value, onChange, edit, onBlur, onFocus, error, required }) => {
    const dial_code_ref = useRef()
    const [code, setCode] = useState('');
    const [number, setNumber] = useState('');
    const [focus, setFocus] = useState(false);

    const HandleKeyPress = (e) => {
        if (e.key.toLowerCase() == 'enter') {
            onEnterSubmit()
        }
    }
    const parsedPlaceholder = isCapitalized ? placeholder : placeholder.charAt(0).toUpperCase() + placeholder.slice(1).toLowerCase();

    useEffect(() => {
        // console.log(value, "value")
        if (value && edit) {
            const arrValue = value.split("-");
            if (arrValue[0] != code) {
                setCode(arrValue[0])
            }
            if (arrValue[1] != number) {
                setNumber(arrValue[1])
            }
        }
        else if (((value && value?.split('-')[1] == '') || !value) && edit) {
            get_user_location()
        }
        else {
            if (!edit) {
                if (value) {
                    const arrValue = value.split("-");
                    if (arrValue[0] != code) {
                        setCode(arrValue[0])
                    }
                    if (arrValue[1] != number) {
                        setNumber(arrValue[1])
                    }
                } else {
                    get_user_location()
                }
            }
        }

    }, [edit, value])

    useEffect(() => {
        if ((!code || !number) && required) {
            // seterror(true)
        }
        else {
        }
        onChange(
            {
                target: {
                    value: `${code && `${code}-`}${number ? number : ''}`,
                    name: name ? name : 'mobile_number'
                }
            }
        )

    }, [code, number])

    const get_user_location = () => {
        fetch(BASE_URL + country_code_url)
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
            })
            .then(result => {
                let my_country = DIAL_CODES.find(cd => cd.Code.toLowerCase() == result?.country_code?.toLowerCase())
                if (edit) {
                    if ((value && value?.split('-')[1] == '') || !value) {
                        if (my_country) {
                            setCode(my_country.MobileCode)
                        }
                        else {
                            setCode("+971")
                        }
                    } else {
                        if (my_country) {
                            setCode(my_country.MobileCode)
                        }
                        else {
                            setCode("+971")
                        }
                    }
                } else {
                    if (!edit) {
                        if (my_country) {
                            setCode(my_country.MobileCode)
                        }
                        else {
                            setCode("+971")
                        }
                    }
                }
            })
            .catch(err => {
                setCode("+971")
            })
    }

    return (
        <div className='flex flex-col gap-2 relative'>
            <p className='text-sm'>{title} {required && title && <span className='text-red-600'> *</span>}</p>
            <div className={`flex border ${(((error && typeof error == "string") || (typeof error == "boolean" && error == true)) && !focus) && "border-l-[7px] border-[#eb3b3b]"} rounded-[4px] ease-in transition-all ${focus ? 'border-primary border-l-[7px]' : ''}`}>
                <div className='w-[106px] remove-indicator-dropdown flex items-center ' id='remove-indicator-dropdown'>
                    <Dropwdown
                        custom_min_width={'min-w-[9ch]'}
                        title={''}
                        showTitle={false}
                        value={code}
                        placeholder='+00'
                        options={
                            [...DIAL_CODES.map((cd) => {
                                return {
                                    label: <div className='flex items-center gap-1'>
                                        <img src={`https://flagcdn.com/${cd?.Code?.toLowerCase()}.svg`} className='h-[1rem] w-[1.5rem] rounded-md object-cover' alt="South Africa" />
                                        {cd.MobileCode}
                                    </div>,
                                    value: cd.MobileCode,
                                }
                            })]
                        }
                        onChange={(e) => {
                            setCode(e.target.value)
                        }}
                        className='border-0 flex-1 h-full'
                        noBorder={true}
                    // error={error}
                    />
                </div>
                <input
                    onKeyDown={HandleKeyPress}
                    disabled={!code}
                    type="text"
                    placeholder={parsedPlaceholder}
                    name={name}
                    value={number}
                    onChange={(e) => {

                        if (e.target.value == '') {
                            if (dial_code_ref && dial_code_ref.current) {
                                dial_code_ref.current.focus()
                            }
                        }
                        setNumber((e.target.value).replace(/[^0-9]/g, ''))
                    }}
                    className="px-4 py-[0.5rem] border-l w-full rounded-tr-md rounded-br-md outline-none text-sm disabled:bg-[#f2f2f2] disabled:cursor-not-allowed"
                />
            </div>
            {error && typeof error == "string" ? (
                <p className="text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                    <Svgs.I fill="#eb3b3b" />
                    {error}
                </p>
            ) : typeof error == "boolean" && error == true ? (
                <p className="text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                    <Svgs.I fill="#eb3b3b" /> {error_message}
                </p>
            ) : (
                ""
            )}
            {/* {(error) && <p className='text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end'><Svgs.I fill='#eb3b3b' />{error}</p>} */}
            {/* {error && <span className='text-[#eb3b3b] flex items-center gap-2 text-xs'>{error}<Svgs.I /></span>} */}
        </div>
    )
}

PhoneInput.defaultProps = {
    title: 'Enter "title" prop',
    placeholder: 'Enter "placeholder" prop',
    edit: false,
    onEnterSubmit: () => { }
}

export default PhoneInput