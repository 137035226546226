import { EMPLOYEE_ALL_PERMISSIONS } from "../../../../../Constants/Data/PermissionValues";
import PermissionParent from "./PermissionParent";
import PermissionRow from "./PermissionRow";

const PermissionsComponent = ({ data, selectedLanguage, translation, onUpdate, ...props }) => {
  return (
    <>
      {EMPLOYEE_ALL_PERMISSIONS(selectedLanguage, translation).map((permission, index) => {
        return (
          <>
            <PermissionParent
              selectedLanguage={selectedLanguage}
              translation={translation}
              title={permission.title}
              key={index}
              options={permission.options}
            >
              {permission?.permissions?.map((permission_opt, p_index) => {
                return (
                  <>
                    <PermissionRow
                      onCheck={(value) => {
                        onUpdate({
                          target: { name: permission_opt.value, value: value },
                        });
                      }}
                      selected={data[permission_opt.value]}
                      title={permission_opt.name}
                      key={p_index}
                      options={permission.options}
                      disabled_array={permission_opt.disabled_options}
                    />
                  </>
                );
              })}
            </PermissionParent>
          </>
        );
      })}
    </>
  );
};

PermissionsComponent.defaultProps = {
  onUpdate: () => { },
  data: {},
};

export default PermissionsComponent;
