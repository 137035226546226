import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Popup from "new-components/popup";

import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import CheckboxCard from "Components/TenantApp/Elements/CheckboxCard";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { LOCATION } from "Redux/TenantRedux/ActionTypes/locationTypes";

import Svgs from "Assets/svgs";

const ChooseLocationModal = ({ setLocationPopup, locationList, conditionalOnClick }) => {
  const dispatch = useDispatch();
  const { getDataFromLocalStorage, storeDataInLocalStorage } =
    useSetDataInLocalStorage();

  const state = useSelector((state) => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [uniqueData, setUniqueData] = useState();
  let selected_location = getDataFromLocalStorage("selected_location");

  const handleChangeLocation = (id) => {
    storeDataInLocalStorage("selected_location", id);
    setLocationPopup(false);
    dispatch({
      type: LOCATION.SELECTED_LOCATION,
      payload: id,
    });
    if (selected_location != id) {
      conditionalOnClick && conditionalOnClick()
    }
  };

  useEffect(() => {
    let uniqueIds = [];
    const data = locationList.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.id);
      if (!isDuplicate) {
        uniqueIds.push(element.id);
        return true;
      }
      return false;
    });
    setUniqueData(data);
  }, []);

  return (
    <Popup
      heading={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
      handleClose={() => setLocationPopup(false)}
    >
      <div className="flex flex-col gap-6">
        <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
          <CircleIcon>
            <Svgs.Location />
          </CircleIcon>
          <div className="flex flex-col gap-1 lg:text-left text-center">
            <h2 className="text-primary font-semibold text-xl">
              {selectedLanguage?.name == "English" ? "Choose Location" : translation[614]?.value ? translation[614]?.value : "Choose Location"}
            </h2>
            <p className="text-[#7B7B7B] text-sm">
              {selectedLanguage?.name == "English" ? "Choose a location to view details related to that location." : translation[615]?.value ? translation[615]?.value : "Choose a location to view details related to that location."}
            </p>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 gap-[1rem]">
          {uniqueData?.length > 0 &&
            uniqueData?.map(({ address_name, address, id }, index) => {
              return (
                <CheckboxCard
                  key={index}
                  padding="pl-2"
                  checked={id === selected_location}
                  onClick={() => handleChangeLocation(id)}
                  title={
                    <div className="flex items-center gap-3">
                      <div>
                        <div className="rounded-lg bg-primary h-[4.375rem] w-[4.375rem] flex items-center justify-center  border overflow-hidden">
                          <Svgs.Home />
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <h1 className="font-semibold text-primary">
                          {address_name}
                        </h1>
                        <p className="text-sm text-[#7B7B7B] whitespace-normal">{address}</p>
                      </div>
                    </div>
                  }
                />
              );
            })}
        </div>
      </div>
    </Popup>
  );
};

export default ChooseLocationModal;
