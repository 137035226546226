import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { MONTHS } from "Constants/Data/Months";
import { copyStaffTarget } from "Adapters/Api/staffTarget";
import { get_StaffTarget } from "Redux/TenantRedux/Actions/staffTarget";
import { useStaffTarget } from "../helper";

export const useCopyStaffTarget = ({ handleClose }) => {
  const {
    fetchStaffTargetListNoPagination,
    fetchStaffTargetList,
    selected_location,
    staffTargetfilter,
    setCurrentPage, searchText, searchMonth, searchYear
  } = useStaffTarget();
  const dispatch = useDispatch();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const [isLoading, setIsLoading] = useState(false);
  const [toMonth, setToMonth] = useState([]);
  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors, isValid },
    getValues
  } = useForm({
    resolver: yupResolver(vaildationSchema),
    mode: "onBlur"
  });

  const monthDropdown = useMemo(() => {
    let months = MONTHS?.map((month, i) => {
      return {
        label: month,
        value: month,
      };
    })
    return months;
  }, [])

  const onSubmit = async (data) => {
    const { fromMonth, toMonth, from_year, to_year } = data;
    setIsLoading(true);
    const body = {
      from_year,
      to_year,
      from_month: fromMonth,
      to_month: toMonth
    }
    // Check if from_year and from_month exist in any object of the array
    const existsInArray = staffTargetfilter()?.some(item => {
      const date = new Date(item.year);
      const year = date.getFullYear();
      return year == data?.from_year && item.month?.toLowerCase() == data?.fromMonth?.toLowerCase();
    });

    if (!existsInArray) {
      setIsLoading(false);
      toast.error('No record found', { toastId: "toast" })
    } else {
      const response = await copyStaffTarget(body, access_token);
      if (response.status === 200) {
        let staffTarget = response?.data?.response?.stafftarget;
        let successMessage = response?.data?.response?.message;
        // dispatch(get_StaffTarget(staffTarget))
        handleClose();
        setIsLoading(false);
        toast.success(successMessage, { toastId: "toast" });
        setCurrentPage(1)
        fetchStaffTargetListNoPagination(selected_location, access_token)
        fetchStaffTargetList(selected_location, 1, searchText, searchMonth, searchYear)
      } else {
        setIsLoading(false);
        toast.error("Error in copy staff target", { toastId: "toast" });
      }
    }
  }

  useEffect(() => {
    let fromMonth = watch('fromMonth');
    if (fromMonth !== undefined || fromMonth !== null) {
      setToMonth(monthDropdown.filter((obj) => obj.value !== watch('fromMonth')))
    }
  }, [watch('fromMonth')])

  const yearDropdown = useMemo(() => {
    let date = new Date();
    let this_year = date?.getFullYear();
    if (this_year) {
      let years = [];
      for (let i = this_year; i <= 2040; i++) {
        years.push({ label: i.toString(), value: i.toString() })
      }
      return years;
    }
  }, [])

  return {
    errors,
    control,
    isValid,
    isLoading,
    monthDropdown,
    isLoading,
    yearDropdown,
    toMonth,
    yearDropdown,
    getValues,
    handleSubmit,
    register,
    onSubmit,
    watch,
  }
}

export const vaildationSchema = yup.object().shape({
  fromMonth: yup.string().required("This field is required").nullable(),
  toMonth: yup.string().required("This field is required").nullable(),
  from_year: yup.string().required("This field is required").nullable(),
  to_year: yup.string().required("This field is required").nullable(),
});