import React, { useEffect, useState } from "react";
import moment from 'moment';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProductListCard from "./ProductListCard";
import AppointmentCard from "./AppointmentCard";
import TotalReadingCards from "./TotalReadingCards";
import AppointmentTableRow from "./AppointmentTableRow";
import Dropwdown from "../Elements/Dropwdown";
import LoginBtn from "../Elements/LoginBtn";
import { MONTHS } from "Constants/Data/Months";
import { getsDashboardProducts, getsProductsDetail } from "Adapters/Api/Products";
import { BASE_URL } from "Adapters/Api/api_variables";
import { getEmployeeListDasboard } from "Adapters/Api/employee";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { get_dashboard_data_on_business_location } from "Adapters/Api/dashboard/index";
import {
  getRecentTenAppointments,
  getTodyasAppointments,
} from "Adapters/Api/apointments";
import { getProfileCompletion } from '../../../Adapters/Api/Business/index'
import Svgs from "Assets/svgs/index";
import "react-calendar/dist/Calendar.css";
import AskToUpdateData from "Components/Utility/AskToUpdateData";
import DashboardChart from "./DashboardChart";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import { graphScheleton } from "Constants/Data/Calendar";
import NoTodayAppointmentDataFound from "Components/Utility/NoTodayAppointmentDataFound";
import GroupAppointmentCard from "./GroupAppointmentCard";
import useEmployeeForms from "./Employee/AddEmployee/useEmployeeForms";
import { ProductPopup } from "./MyAccount/Inventory/AddHeaderBtns/AddProductBtn";

const DashboardMain = () => {
  const navigate = useNavigate();
  const { getDataFromLocalStorage, storeDataInLocalStorage } =
    useSetDataInLocalStorage();

  const dispatch = useDispatch();
  const state = useSelector((state) => {
    return state;
  });
  // const business = state.business?.business;
  // const location_currency = state?.utility?.currency?.location_currency;
  // const location_update = state?.locations?.location_update;
  // const employee_updated = state.employee?.employee_updated;
  // const products_updated = state.product?.products_updated;
  const reduxlocationsList = state?.locations?.locations;
  const { selected_location } = state?.locations;
  const reduxEmployees = state.employee?.employees;
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const reduxProducts = state?.product?.products;
  const products_updated = state?.product?.products_updated
  const dashboard_data = state?.dashborad?.dashboard_data;
  const recentAppointments = state.appointment.all_appointments
  const todayAppointment = state?.appointment?.get_todays_appointment;
  const today_appointment = state?.appointment?.today_appointment;


  const [selectedLocation, setLocationSelection] = useState("");
  const [revenueLoader, setRevenueLoader] = useState(true)
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [profileCompletion, setProfileCompletion] = useState({});
  const [OpenDashboardDropdown, setOpenDashboardDropdown] = useState(false);
  const [GraphData, setGraphData] = useState([]);
  const [groupTodayAppointments, setGroupTodayAppointments] = useState([]);
  const [clientGroupTodayAppointments, setClientGroupTodayAppointments] = useState([]);
  const [singleTodayAppointments, setSingleTodayAppointments] = useState([]);
  const [recentAppointmentLoader, setRecentAppointmentLoader] = useState(true)
  const [filter, setFilter] = useState({
    location: ""
  });
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const { duration } = filter;
  // const location_id = getDataFromLocalStorage("selected_location");
  const [edit_popup, setEditPopup] = useState(false);
  const [singleItemDetail, setSingleItemDetail] = useState({})
  const [detailLoading, setDetailLoading] = useState(true)

  const getSingleProductItemDetail = (id) => {
    setEditPopup(!edit_popup);
    dispatch(getsProductsDetail(
      id, setDetailLoading,
      (result) => {
        setSingleItemDetail(result);
        setDetailLoading(false)
      },
      () => { }
    ));
  }

  const getServiceCount = (location) => {
    setRevenueLoader(true)
    let api_url = `${BASE_URL}/appointment/service_appointment_count/?address=${location}`;
    fetch(api_url)
      .then((resp) => resp.json())
      .then((data) => {
        let arr = [];
        data?.response?.serializer_data.map((data) => {
          // if (data.count > 0 && arr.length < 9) {
          arr.push({
            key: data?.name,
            data: data?.total_count,
          });
          // }
        });
        setGraphData(arr);
        setRevenueLoader(false)
      });
  };

  const get_products = async (location) => {
    const response = await getsDashboardProducts(location);
    if (response.status === 200) {
      let productList = response?.data?.response?.products;
      dispatch(getProducts(productList));
    }
  };

  const get_employees = (location) => {
    dispatch(getEmployeeListDasboard(location));
  };

  // apply location filter
  const applyFilter = (selectedLocation) => {
    setFilter((prev) => ({
      location: selectedLocation,
    }));
    dispatch(
      get_dashboard_data_on_business_location(
        selectedLocation,
        duration,
        () => {
          if (!isToastVisible) {
            toast.success(selectedLanguage?.name == "English" ? "Changes applied successfully!" : translation[1297]?.value ? translation[1297]?.value : "Changes applied successfully!", {
              toastId: "toast",
              onClose: () => setIsToastVisible(false),
            });
            setIsToastVisible(true);
          }
        }
      )
    );
    getServiceCount(selectedLocation, duration);
    storeDataInLocalStorage("selected_location", selectedLocation);
    if (selected_location != selectedLocation) {
      storeDataInLocalStorage('locationChange', true)
    }
    setOpenDashboardDropdown(!OpenDashboardDropdown);
  };

  useEffect(() => {
    // organize the appointments into groups based on their common "appointment" values
    // Group appointments based on their "appointment" value
    if (today_appointment && todayAppointment?.length > 0) {
      // Separate grouped and single appointments
      const groupAppointments = [];
      const singleAppointments = [];
      const clientGroupAppointments = [];

      const groupedAppointments = todayAppointment?.reduce((groups, appointment) => {
        const appointmentKey = appointment.appointment;
        const GroupAppointmentKey = appointment.appointment_group_id;
        if (!groups[appointmentKey]) {
          groups[appointmentKey] = [];
        }
        if (GroupAppointmentKey === null) {
          groups[appointmentKey].push(appointment);
        }
        return groups;
      }, {});
      for (const key in groupedAppointments) {
        if (groupedAppointments[key].length > 1) {
          groupAppointments.push(groupedAppointments[key]);
        } else {
          singleAppointments.push(groupedAppointments[key][0]);
        }
      }

      // Manage Client Group Appointments 

      const clientGroupedAppointments = todayAppointment?.reduce((clientGroups, appointment) => {
        const appointmentKey = appointment.appointment;
        const GroupAppointmentKey = appointment.appointment_group_id;
        if (!clientGroups[GroupAppointmentKey]) {
          clientGroups[GroupAppointmentKey] = [];
        }
        if (GroupAppointmentKey !== null) {
          clientGroups[GroupAppointmentKey].push(appointment);
        }
        return clientGroups;
      }, {});
      for (const key in clientGroupedAppointments) {
        if (clientGroupedAppointments[key].length > 0) {
          clientGroupAppointments.push(clientGroupedAppointments[key]);
        }
      }

      for (let i = singleAppointments.length - 1; i >= 0; i--) {
        if (singleAppointments[i] === undefined) {
          singleAppointments.splice(i, 1);
        }
      }

      setClientGroupTodayAppointments(clientGroupAppointments)
      setGroupTodayAppointments(groupAppointments)
      setSingleTodayAppointments(singleAppointments)
    }

  }, [todayAppointment?.length, todayAppointment, today_appointment])

  useEffect(() => {
    if (selected_location) {
      if (!selectedLocation) {
        setLocationSelection(selected_location);
      }
      setRevenueLoader(true)
      dispatch(getRecentTenAppointments(selected_location, setRecentAppointmentLoader));
      get_products(selected_location);
      get_employees(selected_location);
      dispatch(getTodyasAppointments(selected_location));
      dispatch(get_dashboard_data_on_business_location(selected_location));
      getServiceCount(selected_location);
    }
  }, [selected_location])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    let lctin = reduxlocationsList.find(
      (itm) => itm?.id === selected_location
    );
    if (lctin != undefined || lctin != null) {
      if (Object.keys(lctin).includes("currency") && lctin?.currency?.code) {
        setSelectedCurrency(lctin?.currency?.code);
        dispatch({
          type: "LOCATION_CURRENCY",
          payload: lctin?.currency?.code,
        });
      }
    }
  }, [selected_location, reduxlocationsList?.length]);

  useEffect(() => {
    if (state?.business?.business?.id) {
      dispatch(
        getProfileCompletion({ business_id: state?.business?.business?.id })
      )
    }
  }, [state?.business?.business])

  useEffect(() => {
    if (state.business.profile_completion) {
      setProfileCompletion(state.business.profile_completion)
    }
  }, [state?.business?.profile_completion])

  useEffect(() => {
    if (!selected_location && !selected_location && reduxlocationsList?.length > 0) {
      const firstLoc = reduxlocationsList[0]?.id
      setLocationSelection(firstLoc)
      storeDataInLocalStorage("selected_location", firstLoc);
    }
  }, [selected_location, reduxlocationsList?.length])

  const { employeeEditHandler }= useEmployeeForms()

  return (
    <>
      <AskToUpdateData />
      <div className="md:grid flex flex-col md:grid-cols-3">
        <div className="md:col-span-2 md:order-1 order-2">
          {
            (profileCompletion && Math.round(profileCompletion.completion_percentage) < 100 && !employee) &&
            <>
              <div className="bg-[#EFFBF4] px-[1.2rem] py-1.5 border-b border-[#68D087] flex items-center justify-between">
                <div>
                  <h1 className="text-sm mb-2 normal-case">
                    {selectedLanguage?.name == "English" ? "You have completed" : translation[12]?.value ? translation[12]?.value : "You have completed"}
                    {" "}{Math.round(profileCompletion.completion_percentage)}

                    {selectedLanguage?.name == "English" ? " % of your account setup " : translation[13]?.value ? translation[13]?.value : " % of your account setup "}

                    <span className="text-[#5679FF] underline cursor-pointer"
                      onClick={() => {
                        navigate('/dashboard/account/setup')
                      }}>
                      {selectedLanguage?.name == "English" ? "Click here " : translation[14]?.value ? translation[14]?.value : "Click here "}

                    </span>
                    {selectedLanguage?.name == "English" ? "to complete it." : translation[15]?.value ? translation[15]?.value : "to complete it."}

                  </h1>
                  <div class="w-[18.75rem] bg-white rounded-full h-1.5 border relative border-[#68D087]">
                    <div class="bg-[#68D087] rounded-full absolute inset-0" style={{ width: Math.round(profileCompletion.completion_percentage) + '%' }}></div>
                  </div>
                </div>
              </div>

            </>
          }

          <div className="lg:px-[2rem] px-[1rem] md: pr-[2rem] py-[2rem]">
            <div className="flex justify-between items-center lg:col-span-4 col-span-6 mb-5 flex-col xs:flex-row gap-2">
              {state?.user?.user?.first_name ?
                <div className="self-start">
                  <p className="text-lg">
                    {selectedLanguage?.name == "English" ? "Hello" : translation[16]?.value ? translation[16]?.value : "Hello"}
                    ,
                    {" "}
                    <span className="font-semibold">
                      {state?.user?.user?.first_name} {state?.user?.user?.last_name}
                    </span>
                  </p>
                </div>
                :
                <Shimmer className={'w-1/3'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
              }
              <div className="flex items-stretch self-end gap-2 xs:w-1/2 lg:w-fit w-full ">
                {reduxlocationsList?.length == 0 ?
                  <>
                    <Shimmer className={'w-1/2'}>
                      <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                    </Shimmer>
                    <Shimmer className={'w-1/2'}>
                      <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                    </Shimmer>
                  </>
                  :
                  <>
                    {!employee ? (
                      <LoginBtn
                        onClick={() => {
                          navigate("/dashboard/calendar/add-appointment");
                        }}
                      >
                        <div className="flex items-center gap-3">
                          <Svgs.Calendar size="1.1rem" fill="#fff" />
                          <p>{selectedLanguage?.name == "English" ? "New Appointment" : translation[18]?.value ? translation[18]?.value : "New Appointment"}</p>
                        </div>
                      </LoginBtn>
                    ) : (
                      ""
                    )}
                    {
                      (!state.user.user.employee || (state.user.user.employee && state.user?.permissions?.system_root_access?.includes('view'))) &&
                      <Dropwdown
                        className={'w-[10rem] lg:w-[14rem]'}
                        showTitle={false}
                        name={"selectedLocation"}
                        value={selectedLocation}
                        onChange={(e) => {
                          if (e.target.value != selectedLocation) {
                            setLocationSelection(e.target.value);
                            storeDataInLocalStorage(
                              "selected_location",
                              e.target.value
                            );
                            applyFilter(e.target.value)
                          }
                        }}
                        options={reduxlocationsList?.map((adrs) => {
                          return {
                            label: adrs?.address_name,
                            value: adrs?.id,
                          };
                        })}
                        title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                        placeholder={selectedLanguage?.name == "English" ? "Filter by Location" : translation[50]?.value ? translation[50]?.value : "Filter by Location"}
                      />
                    }
                  </>
                }
              </div>
            </div>
            <div className="md:col-span-4 col-span-6 flex flex-col gap-[1.6rem]">
              <>
                {revenueLoader ?
                  <div className="flex items- gap-3 flex-wrap md:flex-row flex-col">
                    {[0, 1, 2].map(itm =>
                      <TotalReadingCards
                        animate
                      />
                    )}
                  </div>
                  :
                  <div className="flex items- gap-3 flex-wrap md:flex-row flex-col">
                    <TotalReadingCards
                      icon={<Svgs.DollarSign />}
                      value={`${dashboard_data?.revenue ? parseFloat(dashboard_data?.revenue?.toFixed(2)) : 0} ${selectedCurrency ? selectedCurrency : ""}`}
                      text={selectedLanguage?.name == "English" ? "Total Sales" : translation[146]?.value ? translation[146]?.value : "Total Sales"}
                      head="Total Revenue"
                      graph={<Svgs.GraphUp />}
                    />

                    <TotalReadingCards
                      icon={<Svgs.TotalClients />}
                      value={dashboard_data?.appointments_count ? dashboard_data?.appointments_count : 0}
                      text={selectedLanguage?.name == "English" ? "Total Footfalls" : translation[24]?.value ? translation[24]?.value : "Total Footfalls"}
                      head="Total Footfalls"
                      graph={<Svgs.GraphUp />}
                    />

                    <TotalReadingCards
                      icon={<Svgs.Calendar />}
                      value={`${dashboard_data?.client_count ? dashboard_data?.client_count : 0}`}
                      text={selectedLanguage?.name == "English" ? "Average sales per footfall" : translation[25]?.value ? translation[25]?.value : "Average sales per footfall"}
                      head="Average sales per footfall"
                      graph={<Svgs.GraphDown />}
                    />
                  </div>
                }
                {revenueLoader ?
                  <div className="flex flex-col gap-6 border rounded-xl p-6">
                    <Shimmer>
                      <div className="h-8 w-1/4 rounded-md bg-gray-100"></div>
                    </Shimmer>
                    <div className="rounded-xl lg:pb-0 pb-4 overflow-x-scroll">
                      <div className="min-w-[1000px] animate-pulse">
                        <DashboardChart GraphData={graphScheleton} animate />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="flex flex-col gap-6 border rounded-xl p-6">
                    <p className="font-semibold text-xl text-primary">
                      {selectedLanguage?.name == "English" ? "Top Services" : translation[1338]?.value ? translation[1338]?.value : "Top Services"}
                    </p>
                    <div className="rounded-xl lg:pb-0 pb-4 overflow-x-auto">
                      <div className={`${GraphData?.length > 3 ? ' min-w-[1000px]' : ''}`}>
                        {GraphData?.length > 0 ? (
                          <>
                            <DashboardChart GraphData={GraphData} />
                          </>
                        ) : (
                          <div className="rounded-2xl h-full w-full flex items-center justify-center text-center">
                            <div className="flex flex-col gap-3">
                              <div className="mx-auto">
                                <Svgs.NoRevenue fill="#fff" />
                              </div>
                              <h1 className="text-md normal-case">
                                {selectedLanguage?.name == "English" ? "No record found!" : translation[27]?.value ? translation[27]?.value : "No record found!"}
                              </h1>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                }
                <div className="flex items-center gap-5 sm:flex-row flex-col">
                  {!products_updated ?
                    <div className="md:flex-1 md:w-fit w-full border rounded-xl relative">
                      <div className="animate-pulse p-5">
                        <div className="h-8 w-1/4 rounded-md bg-gray-100"></div>
                      </div>
                      <div className="h-[15rem] overflow-auto scroll-hidden-lg">
                        {[0, 1, 2].map(itm =>
                          <ProductListCard
                            animate
                          />
                        )}
                      </div>
                    </div>
                    :
                    <>
                      <div className="md:flex-1 md:w-fit w-full border rounded-xl relative">
                        <div className="flex items-center justify-between text-[#3F3F44] p-5">
                          <h2 className="font-semibold text-lg">
                            {selectedLanguage?.name == "English" ? "Top Products" : translation[28]?.value ? translation[28]?.value : "Top Products"}
                          </h2>
                        </div>
                        <div className="h-[15rem] overflow-auto scroll-hidden-lg">
                          {reduxProducts?.length > 0 ? (
                            reduxProducts?.map((prod, index) => {
                              return (
                                <ProductListCard
                                  data={prod}
                                  name={prod?.name}
                                  index={index}
                                  image={
                                    prod?.cover_image
                                      ? prod?.cover_image
                                      : undefined
                                  }
                                  onClick={getSingleProductItemDetail}
                                />
                              );
                            })
                          ) : (
                            <>
                              <div className="flex items-center justify-center text-center">
                                <div className="flex flex-col gap-2">
                                  <div className="mx-auto">
                                    <Svgs.NoTopProductFound />
                                  </div>
                                  <h1 className="text-md normal-case">
                                    {selectedLanguage?.name == "English" ? "No record found!" : translation[27]?.value ? translation[27]?.value : "No record found!"}
                                  </h1>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  }

                  {!state.employee.employee_updated ?
                    <div className="md:flex-1 md:w-fit w-full border rounded-xl relative">
                      <div className="animate-pulse p-5">
                        <div className="h-8 w-3/4 rounded-md bg-gray-100"></div>
                      </div>
                      <div className="h-[15rem] overflow-auto scroll-hidden-lg">
                        {[0, 1, 2, 4, 5].map(itm =>
                          <ProductListCard
                            animate
                          />
                        )}
                      </div>
                    </div>
                    :
                    <>
                      <div className="md:flex-1 md:w-fit w-full border rounded-xl relative">
                        <div className="flex items-center justify-between text-[#3F3F44] p-5">
                          <h2 className="font-semibold text-lg">
                            {selectedLanguage?.name == "English" ? "Top Team Members" : translation[29]?.value ? translation[29]?.value : "Top Team Members"}
                          </h2>
                        </div>

                        <div className="h-[15rem] overflow-auto scroll-hidden-lg">
                          {reduxEmployees?.length > 0 ? (
                            reduxEmployees?.map((empl, index) => {
                              return (
                                <ProductListCard
                                  data={empl}
                                  sale={empl?.total_sale}
                                  index={index}
                                  currency={selectedCurrency ? selectedCurrency : ""}
                                  name={empl.full_name}
                                  onClick={employeeEditHandler}
                                  image={empl?.image ? empl.image : undefined}
                                />
                              );
                            })
                          ) : (
                            <>
                              <div className="flex items-center justify-center text-center">
                                <div className="flex flex-col gap-2">
                                  <div className="mx-auto">
                                    <Svgs.NoTopMemberFound />
                                  </div>
                                  <h1 className="text-md normal-case">
                                    {selectedLanguage?.name == "English" ? "No record found!" : translation[27]?.value ? translation[27]?.value : "No record found!"}
                                  </h1>
                                </div>
                              </div>
                            </>
                          )
                          }
                        </div>
                      </div>
                    </>
                  }
                </div>
                {recentAppointmentLoader ?
                  <div className="flex-11 animate-pulse border rounded-xl relative">
                    <div className="flex items-center text-[#3F3F44] pb-4 p-5">
                      <div className="h-9 w-1/4 rounded-md bg-gray-100"></div>
                    </div>
                    <div className="overflow-auto max-h-[30rem]">
                      <table className="table-auto md:table-fixed w-full">
                        <thead className="sticky top-0 bg-white">
                          <tr className="text-center mb-4 text-[#3F3F44B2]">
                            <th
                              scope="col"
                              className="text-sm font-medium px-6 py-2 bg-[#F6F6F6]  text-left"
                            >
                              <div className="h-5"></div>
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium px-6 py-2 bg-[#F6F6F6]"
                            >
                              <div className="h-5"></div>
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium px-6 py-2 bg-[#F6F6F6]"
                            >
                              <div className="h-5"></div>
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium px-6 py-2 bg-[#F6F6F6] "
                            >
                              <div className="h-5"></div>

                            </th>
                          </tr>
                        </thead>
                        <div className="leading-[0.5]">&nbsp;</div>
                        <tbody>
                          {[0, 1, 2, 3, 4].map((appointment, index) => {
                            return (
                              <AppointmentTableRow
                                animate
                              />
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  :
                  <div className="flex-11 border rounded-xl relative">
                    <div className="flex items-center justify-between text-[#3F3F44] pb-4 p-5">
                      <h2 className="font-semibold text-lg">
                        {selectedLanguage?.name == "English" ? "Recent Appointments" : translation[30]?.value ? translation[30]?.value : "Recent Appointments"}
                      </h2>
                    </div>
                    <div className="overflow-auto max-h-[30rem]">
                      <table className="table-auto md:table-fixed w-full">
                        <thead className="sticky top-0 bg-white">
                          <tr className="text-center mb-4 text-[#3F3F44B2]">
                            <th
                              scope="col"
                              className="text-sm font-medium px-6 py-2 bg-[#F6F6F6]  text-left"
                            >
                              {selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium px-6 py-2 bg-[#F6F6F6]"
                            >
                              {selectedLanguage?.name == "English" ? "Employee" : translation[32]?.value ? translation[32]?.value : "Employee"}
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium px-6 py-2 bg-[#F6F6F6]"
                            >
                              {selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client"}
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium px-6 py-2 bg-[#F6F6F6] "
                            >
                              {selectedLanguage?.name == "English" ? "Earnings" : translation[33]?.value ? translation[33]?.value : "Earnings"}

                            </th>
                          </tr>
                        </thead>
                        <div className="leading-[0.5]">&nbsp;</div>
                        {recentAppointments &&
                          recentAppointments?.length > 0 ? (
                          <tbody>
                            {recentAppointments?.map((appointment, index) => {
                              return (
                                <AppointmentTableRow
                                  key={index}
                                  data={appointment}
                                  service={appointment?.service}
                                  employee={appointment?.member}
                                  sale={appointment.client}
                                  img={appointment?.service_image?.image}
                                  earnings={`${appointment.price?.toFixed(2)} ${selectedCurrency ? selectedCurrency : ""}`}
                                />
                              );
                            })}
                          </tbody>
                        ) : (
                          <>
                            <tbody>
                              <tr>
                                <td colSpan="4" className="text-center">
                                  <div className="rounded-2xl h-[15rem] w-full flex items-center justify-center text-center">
                                    <div className="flex flex-col gap-2">
                                      <div className="mx-auto">
                                        <Svgs.NoRecentAppointments width="200" height="150" />
                                      </div>
                                      <h1 className="text-md normal-case">
                                        {selectedLanguage?.name == "English" ? "No record found!" : translation[27]?.value ? translation[27]?.value : "No record found!"}
                                      </h1>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </>
                        )
                        }
                      </table>
                    </div>
                  </div>
                }
              </>
            </div>
          </div>
        </div>
        <div className="border-l lg:px-[1.5rem] px-[1rem] py-[2rem] xl:pt-[1.5rem] pt-[1rem] md:order-2 order-1">
          <div className="sticky top-[2rem]">
            {!today_appointment ?
              <div className="animate-pulse md:col-span-2 flex flex-col gap-[1.6rem]">
                <h2 className="text-xl font-semibold">
                  <div className="h-9 w-3/4 rounded-md bg-gray-100"></div>
                </h2>
                <div className="flex flex-col gap-4">
                  {[0, 1, 2, 3, 4]?.map((app) => {
                    return (
                      <AppointmentCard
                        animate
                      />
                    );
                  })}
                </div>
              </div>
              :
              <div className="md:col-span-2 flex flex-col gap-[1.6rem] ">
                <h2 className="text-xl font-semibold">
                  {selectedLanguage?.name == "English" ? "Today's Appointments" : translation[35]?.value ? translation[35]?.value : "Today's Appointments"}
                </h2>
                <div className="flex flex-col gap-4 max-h-[78vh] overflow-auto pr-2">
                  {todayAppointment?.length > 0 ? (
                    <>
                      {singleTodayAppointments?.length > 0 ? singleTodayAppointments?.map((app) => {
                        let months = MONTHS[app?.appointment_date?.split("-")[1] - 1];
                        return (
                          <AppointmentCard
                            startEnd={`${moment(`${app?.appointment_date} ${app?.appointment_time}`, 'YYYY-MM-DD, hh:mm:ss').format('LT')} - ${app?.duration}`}
                            name={app?.member}
                            type={app?.service}
                            id={app?.id}
                            appointment_id={app?.appointment}
                            date={app?.appointment_date?.split("-")[2]}
                            month={months}
                            img={app?.service_image?.image}
                          />
                        );
                      }) : ""}

                      {groupTodayAppointments?.length > 0 ? groupTodayAppointments?.map((group, groupIndex) => (
                        <div key={groupIndex} className="group-appointment-wrapper border-[#5679FF] border-solid border-[1px] md:px-3 px-1.5 rounded-xl space-y-2 divide-y-[1px]">
                          {group.map((app) => {

                            let months = MONTHS[app.appointment_date?.split("-")[1] - 1];
                            return (
                              <GroupAppointmentCard
                                key={app.id}
                                startEnd={`${moment(`${app.appointment_date} ${app?.appointment_time}`, 'YYYY-MM-DD, hh:mm:ss').format('LT')} - ${app?.duration}`}
                                name={app.member}
                                type={app.service}
                                id={app?.id}
                                appointment_id={app?.appointment}
                                date={app.appointment_date.split("-")[2]}
                                month={months}
                                img={<Svgs.NewService />}
                              />
                            );
                          })}
                        </div>
                      )) : ""}

                      {clientGroupTodayAppointments?.length > 0 ? clientGroupTodayAppointments?.map((group, groupIndex) => (
                        <div key={groupIndex} className="group-appointment-wrapper border-[#5679FF] border-solid border-[1px] md:px-3 px-1.5 rounded-xl space-y-2 divide-y-[1px]">
                          {group.map((app) => {

                            let months = MONTHS[app.appointment_date?.split("-")[1] - 1];
                            return (
                              <GroupAppointmentCard
                                key={app.id}
                                startEnd={`${moment(`${app.appointment_date} ${app?.appointment_time}`, 'YYYY-MM-DD, hh:mm:ss').format('LT')} - ${app?.duration}`}
                                name={app.member}
                                type={app.service}
                                id={app?.id}
                                appointment_id={app?.appointment}
                                clientGroupAppointmentId={app?.appointment_group_id}
                                date={app.appointment_date.split("-")[2]}
                                month={months}
                                isClientGroupAppointment={true}
                                img={<Svgs.NewService />}
                              />
                            );
                          })}
                        </div>
                      )) : ""}
                    </>
                  ) : (
                    <NoTodayAppointmentDataFound />
                  )}
                </div>
              </div>
            }
          </div>
        </div>
      </div>


      {edit_popup && (
        <ProductPopup
          edit_mode={true}
          edit_data={singleItemDetail}
          loader={detailLoading}
          onClose={() => {
            setEditPopup(false);
            setSingleItemDetail({})
          }}
        />
      )}
      {/* <AccountSetupPopup /> */}
    </>
  );
};

export default DashboardMain;
