import React, { useState } from "react";

import Popup from "new-components/popup";

import Selection from "new-components/dropdown";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import FormSteps from "Components/TenantApp/Elements/FormSteps";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import PhoneInput from "Components/TenantApp/Elements/PhoneInput";
import ToggleWithText from "Components/TenantApp/Elements/ToggleWithText";

import { useAddTransferStocks } from "./helper";
// import Textarea from "new-components/text-area";
import { useNavigate } from "react-router-dom";
import useValidations from "Hooks/useValidations";
import useAddress from "Hooks/useAddress";
import useObjectKeys from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import {
  createBusinessLocation,
  getAllLanguages,
  updateLocations,
} from "Adapters/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  createLocations,
  updateLocation,
} from "Redux/TenantRedux/Actions/locationActions";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";
import Svgs from "Assets/svgs";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { WEEKDAYS } from "Constants/Data/Months";
import { OPENING_TIME } from "Constants/Data/OpeningHours";
import I_Info_Icon from "Components/TenantApp/Elements/I_Info_Icon";
import Textarea from "Components/TenantApp/Elements/Textarea";
import { useRef } from "react";
import { useEffect } from "react";
import { getLanguages } from "Redux/TenantRedux/Actions/multiLanguageInvoicesActions";
import { getMultiLanguageInvoice } from "Adapters/Api/multiLanguageInvoice";
import { getsProductsDropdownListPagination } from "Adapters/Api/Products";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";


const AddStocksTransfer = ({
  handleClose,
  updateStockData,
  updateData,
  isUpdate,
  stockTransferList,
  setStockTransferList,
}) => {
  const {
    onSubmit,
    errors,
    isLoading,
    toAddress,
    fromAddress,
    setProductsList,
    productsList,
    handleDropdownChange,
    dropdownData,
    productSearchText, setProductSearchText, productCurrentPage, setProductCurrentPage, productLoader, productPaginationData,
  } = useAddTransferStocks({
    updateStockData,
    handleClose,
    updateData,
    isUpdate,
    setStockTransferList,
    stockTransferList,
  });

  const navigate = useNavigate();
  // start of Add location popup
  const formRefLocation = useRef()
  const dispatch = useDispatch();
  const { storeDataInLocalStorage, getDataFromLocalStorage } =
    useSetDataInLocalStorage();
  const reduxstate = useSelector((state) => state);
  const locationList = useSelector((state) => state.locations.locations);
  const currency_list = reduxstate?.utility?.currency?.currency_list;
  const businessData = getDataFromLocalStorage("business_data");
  const access_token = getDataFromLocalStorage("access_token");

  const translation = reduxstate?.languages?.language_translation
  const selectedLanguage = reduxstate?.languages?.selected_language
  const reduxInvoices = reduxstate?.multilanguageInvoices?.invoices;

  const { setErrors, validation, onBlurHandler, validateWebsiteUrl } =
    useValidations();
  const { objectKeyConvertToArray } = useObjectKeys();
  const [businessLocations, setBusinessLocations] = useState({
    country_name: "",
    state_name: "",
    city_name: "",
  });
  //location form state
  const [locationData, setLocationData] = useState({
    address_name: "",
    address: "",
    postal_code: "",
    country: "",
    state: "",
    city: "",
    email: "",
    mobile_number: "",
    banking: "Enable",
    start_time: "",
    close_time: "",
    currency: "",
    primary_translation_id: reduxInvoices?.length > 0 ? reduxInvoices?.find(itm => itm?.language_data?.code == "eng")?.id : "",
    secondary_translation_id: ""
  });
  const [addres, setAddress] = useState("");
  const [countryId, setCountryId] = useState();
  const [stateId, setStateId] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [Location, setLocation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setstep] = useState(1);
  const [location2, setLocation2] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonClick, setButtonClick] = useState(false)
  const { countriesList, stateList, setStateList } =
    useAddress(countryId);

  const {
    address_name,
    address,
    country,
    state,
    city,
    postal_code,
    email,
    banking,
    mobile_number,
    currency,
    primary_translation_id,
    secondary_translation_id
  } = locationData;

  // location form handler
  const handleLocationChange = (e, fieldName = "") => {
    if (e?.target) {
      const { name, value } = e?.target;
      setLocationData((prev) => ({
        ...prev,
        [name]: value,
      }));

      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));

      name === "country" && setCountryId(e?.target?.value);
      name === "state" && setStateId(e?.target?.value);
    }
  };

  const onfirststepsubmit = () => {
    const requiredFields = {
      currency,
      address_name,
      address,
      email,
      mobile_number,
      country,
      primary_translation_id
    };
    setErrors(validation(requiredFields));
    const result = validation(requiredFields);
    if (objectKeyConvertToArray(result)?.length === 0) {
      setstep(2);
    } else {
      formRefLocation.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getLanguageList = async () => {
    const response = await getAllLanguages();
    if (response.status === 200) {
      let languageList = response?.data?.response?.languages;
      dispatch(getLanguages(languageList))
      // let laguageOptionList = setOptionForDropdown(languageList);
      // setAllLanguages(laguageOptionList);
    }
  };

  useEffect(() => {
    getLanguageList()
  }, [])

  const onLocationSubmit = async () => {
    if (!isButtonDisabled) {
      const requiredFields = {
        currency,
        address_name,
        address,
        email,
        mobile_number,
        country,
        primary_translation_id
      };
      setErrors(validation(requiredFields));
      const result = validation(requiredFields);
      if (objectKeyConvertToArray(result)?.length === 0) {
        const location_id = getDataFromLocalStorage("location_id");
        if (isEdit) {
          setLoading(true);
          let form_data = new FormData();
          for (let key in locationData) {
            if (locationData[key]) {
              form_data.append(key, locationData[key]);
            }
          }

          form_data.append("open_day", JSON.stringify(location2));
          form_data.append("business", businessData.id);

          const response = await updateLocations(form_data, access_token);
          if (response?.status === 200) {
            let location = response?.data?.response?.location;
            let successMessage = response?.data?.response?.message;
            setLocationData({
              address_name: "",
              address: "",
              postal_code: "",
              country: "",
              state: "",
              city: "",
              email: "",
              mobile_number: "",
              start_time: "",
              close_time: "",
              banking: "Enable",
              primary_translation_id: reduxInvoices?.length > 0 ? reduxInvoices?.find(itm => itm?.language_data?.code == "eng")?.id : "",
              secondary_translation_id: ""
            });
            setLocation2({});
            setStateList();
            // setCityList();
            setCountryId();
            setStateId();
            dispatch(updateLocation(location));
            setLocation(!Location);
            toast.success(successMessage, { toastId: "toast" });
            setLoading(false);
            setstep(1);
          } else {
            setLoading(false);
            toast.error(apiErrorMessage, { toastId: "toast" });
          }
        } else {
          setLoading(true);

          let form_data = new FormData();
          for (let key in locationData) {
            if (locationData[key]) {
              form_data.append(key, locationData[key]);
            }
          }
          form_data.append("open_day", JSON.stringify(location2));
          form_data.append("business", businessData.id);

          // const response = await createBusinessLocation({ ...locationData, business: businessData?.id }, access_token);
          const response = await createBusinessLocation(form_data, access_token);
          if (response?.status === 201) {
            let locations = response?.data?.response?.locations;
            let successMessage = response?.data?.response?.message;
            toast.success(successMessage, { toastId: "toast" });
            dispatch(createLocations(locations));
            if (locationList?.length === 0) {
              storeDataInLocalStorage("selected_location", locations?.id);
              const selected_location = getDataFromLocalStorage('selected_location')
              if (selected_location != locations?.id) {
                storeDataInLocalStorage('locationChange', true)
              }
            }
            setLoading(false);

            setLocationData({
              address_name: "",
              address: "",
              postal_code: "",
              country: "",
              state: "",
              city: "",
              email: "",
              mobile_number: "",
              start_time: "",
              close_time: "",
              banking: "Enable",
            });
            setLocation2({});
            setStateList();
            // setCityList();
            setCountryId();
            setStateId();
            setLocation(!Location);
            // getBusinessLocation(businessData?.id);
            setstep(1);
          } else {
            setLoading(false);

            toast.error(apiErrorMessage, { toastId: "toast" });
          }
        }
      }
    } else {
      formRefLocation.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (locationData?.primary_translation_id == "" && reduxInvoices?.length > 0) {
      setLocationData((prev) => ({ ...prev, primary_translation_id: reduxInvoices?.length > 0 ? reduxInvoices?.find(itm => itm?.language_data?.name == "English")?.id : "" }));
    }
  }, [reduxInvoices, reduxInvoices?.length, locationData?.primary_translation_id])

  // const getInovices = () => {
  //   setLoading(true)
  //   const success = () => { setLoading(false) }
  //   const fail = () => { setLoading(false) }
  //   dispatch(getMultiLanguageInvoice(access_token, success, fail))
  // }

  // useEffect(() => {
  //   getInovices()
  // }, [])

  useEffect(() => {
    let isDisabled = false;
    if (step == 2 && (Object.keys(location2)?.length == 0
      || Object.keys(location2)?.find(key => (!location2[key]?.end_time || !location2[key]?.start_time)))) {
      isDisabled = true;
    }
    setIsButtonDisabled(isDisabled);
  }, [location2, step]);

  const handleAddressSuggetion = async (value) => {
    const result = await geocodeByAddress(value);
    setAddress(value);
    setLocationData((prev) => ({ ...prev, address: value }));
    setErrors((prev) => ({ ...prev, address: "" }));
  };

  return (
    <>
      <Popup
        heading={selectedLanguage?.name == "English" ? "Transfer Stock" : translation[1073]?.value ? translation[1073]?.value : "Transfer Stock"}

        handleClose={handleClose}
        size={"md"}
      >
        <form onSubmit={onSubmit}>
          <div className="flex flex-col gap-3">
            <div className="grid md:grid-cols-1 gap-3">
              <Dropwdown
                required={true}
                title={selectedLanguage?.name == "English" ? "From" : translation[601]?.value ? translation[601]?.value : "From"}
                placeholder={selectedLanguage?.name == "English" ? "Select from location" : translation[1078]?.value ? translation[1078]?.value : "Select from location"}
                name={"from_location"}
                value={dropdownData?.from_location}
                options={fromAddress}
                error={errors?.from_location}
                onChange={handleDropdownChange}
              />
              <Dropwdown
                required={true}
                title={selectedLanguage?.name == "English" ? "To" : translation[602]?.value ? translation[602]?.value : "To"}
                placeholder={selectedLanguage?.name == "English" ? "Select To location" : translation[1079]?.value ? translation[1079]?.value : "Select To location"}
                name={"to_location"}
                value={dropdownData?.to_location}
                options={toAddress}
                error={errors?.to_location}
                onChange={handleDropdownChange}
              />
              {/* {(dropdownData?.from_location && toAddress?.length == 0) && (
                <div
                  className="flex items-center gap-2 cursor-pointer pt-2"
                  onClick={() => {
                    setStateList();
                    // setCityList();
                    setCountryId();
                    setStateId();
                    setIsEdit(false);
                    setLocation(true);
                  }}
                >
                  <p className="text-sm  mt-4">
                    {selectedLanguage?.name == "English"
                      ? "You have only one location added, add another location to transfer stock. Click"
                      : translation[1080]?.value
                        ? translation[1080]?.value
                        : "You have only one location added, add another location to transfer stock. Click"
                    }
                    <span className="underline mx-1">
                      {selectedLanguage?.name == "English" ? "here" : translation[502]?.value ? translation[502]?.value : "here"}
                    </span>
                    {selectedLanguage?.name == "English" ? "to add." : translation[140]?.value ? translation[140]?.value : "to add."}
                  </p>
                </div>
              )} */}
            </div>

            <div className="grid md:grid-cols-2 gap-3">
              <div>
                <PaginationDropdown
                  required={true}
                  name={"product"}
                  title={selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
                  placeholder={selectedLanguage?.name == "English" ? "Select Products" : translation[1025]?.value ? translation[1025]?.value : "Select Products"}
                  value={dropdownData?.product}
                  options={productsList}
                  error={errors?.product}
                  onChange={handleDropdownChange}

                  setPage={setProductCurrentPage}
                  setDropdownSearch={setProductSearchText}
                  page={productCurrentPage}
                  dropdownSearch={productSearchText}
                  lastPage={productPaginationData?.total_pages}
                  currentPage={productPaginationData?.currentPage}
                  loading={productLoader}
                />
                {(dropdownData?.from_location && productsList?.length == 0) && (
                  <div
                    className="flex items-center gap-2 cursor-pointer pt-2"
                    onClick={() => {
                      // settogglecat(true);
                      navigate('/dashboard/account/inventory-operations/products')
                    }}
                  >
                    <p className="text-sm mt-4">
                      {selectedLanguage?.name == "English" ? "No product found. Click" : translation[1011]?.value ? translation[1011]?.value : "No product found. Click"}
                      <span className="underline mx-px text-[#5679FF]">
                        {selectedLanguage?.name == "English" ? "here" : translation[502]?.value ? translation[502]?.value : "here"}
                      </span>
                      {selectedLanguage?.name == "English" ? "to add." : translation[140]?.value ? translation[140]?.value : "to add."}
                    </p>
                  </div>
                )}
              </div>
              <div>
                <LoginInput
                  type={"number"}
                  required={true}
                  name={"quantity"}
                  placeholder={selectedLanguage?.name == "English" ? "Add Quantity" : translation[951]?.value ? translation[951]?.value : "Add Quantity"}
                  onChange={handleDropdownChange}
                  value={dropdownData?.quantity}
                  title={
                    <I_Info_Icon
                      text={selectedLanguage?.name == "English" ? "Add Quantity" : translation[951]?.value ? translation[951]?.value : "Add Quantity"}
                      info={selectedLanguage?.name == "English" ? "The quantity should be greater than 0" :
                        translation[1081]?.value ? translation[1081]?.value : "The quantity should be greater than 0"
                      }
                    />
                  }
                  disabled={dropdownData?.product ? false : true}
                  error={errors?.quantity}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-1 gap-3">
              <Textarea
                type={"text"}
                required={true}
                name={"note"}
                onChange={handleDropdownChange}
                value={dropdownData?.note}
                placeholder={selectedLanguage?.name == "English" ? "Enter Note" : translation[1477]?.value ? translation[1477]?.value : "Enter Note"}
                title={`${selectedLanguage?.name == "English" ? "Add Note" : translation[134]?.value ? translation[134]?.value : "Add Note"}`}
                error={errors?.note}
              />
            </div>
            <div className="flex items-center gap-3 justify-end pt-3">
              <LoginBtn
                text={isUpdate ?
                  selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                  : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
                }
                className={"p-4"}
                loading={isLoading}
                disabled={isLoading}
              // disabled={!dropdownData?.from_location ||
              //   !dropdownData?.to_location ||
              //   !dropdownData?.product ||
              //   !dropdownData?.note ||
              //   !dropdownData?.quantity ||
              //   errors?.quantity ||
              //   errors?.note ||
              //   errors?.from_location ||
              //   errors?.to_location ||
              //   errors?.product ||
              //   isLoading}
              />
            </div>
          </div>
        </form>
      </Popup>

      {Location && <Popup
        size="lg"
        heading={isEdit ?
          selectedLanguage?.name == "English" ? "Edit Location" : translation[88]?.value ? translation[88]?.value : "Edit Location"
          :
          selectedLanguage?.name == "English" ? "Add Location" : translation[44]?.value ? translation[44]?.value : "Add Location"
        }
        handleClose={() => {
          setLocation(false);
          setBusinessLocations({
            country_name: "",
            state_name: "",
            city_name: "",
          });
          setstep(1);
          setLocation2({});
          setLocationData({
            address_name: "",
            address: "",
            postal_code: "",
            country: "",
            state: "",
            city: "",
            email: "",
            mobile_number: "",
            banking: "Enable",
          });
          setErrors({});
        }}
        open={Location}
      >
        <div className="pb-[1.5rem]">
          <FormSteps
            currentStep={step}
            setStep={setstep}
            active={step}
            steps={[
              selectedLanguage?.name == "English" ? "Location Details" : translation[46]?.value ? translation[46]?.value : "Location Details",
              selectedLanguage?.name == "English" ? "Time Schedule" : translation[47]?.value ? translation[47]?.value : "Time Schedule"]}
            stepsLength="2"
          />
        </div>

        {step === 1 && (
          <div className="flex flex-col gap-6" ref={formRefLocation}>
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
              <CircleIcon>
                <Svgs.Location />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Fill in all the details to add your business location" : translation[691]?.value ? translation[691]?.value : "Fill in all the details to add your business location"}
                </p>
              </div>
            </div>
            <LoginInput
              required={true}
              name="address_name"
              value={address_name}
              // charactersMaxLength={25}
              onChange={handleLocationChange}
              error={errors.address_name}
              onBlur={() => onBlurHandler({ address_name: address_name })}
              title={selectedLanguage?.name == "English" ? "Location Name" : translation[48]?.value ? translation[48]?.value : "Location Name"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Location Name" : translation[50]?.value ? translation[50]?.value : "Enter Location Name"}
            />

            <PlacesAutocomplete
              value={address}
              onChange={(value) => { handleLocationChange({ target: { name: 'address', value: value } }) }}
              onSelect={handleAddressSuggetion}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                  <LoginInput
                    {...getInputProps()}
                    required={true}
                    error={errors.address}
                    onBlur={() => onBlurHandler({ address: address })}
                    title={selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter Business Address" : translation[51]?.value ? translation[51]?.value : "Enter Business Address"}
                  />

                  {suggestions.length > 0 && (
                    <div className="autocomplete-dropdown-container border rounded-md mt-2">
                      {loading && (
                        <div className="rounded-md p-2 text-xs text-[#555555]">
                          Loading...
                        </div>
                      )}
                      {suggestions.map((suggestion) => {
                        const className =
                          "rounded-md p-2 text-xs hover:!bg-gray-100 text-[#555555]";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </PlacesAutocomplete>


            <div className="grid lg:grid-cols-2 grid-cols-1 gap-6">
              <Dropwdown
                required={true}
                title={selectedLanguage?.name == "English" ? "Country" : translation[52]?.value ? translation[52]?.value : "Country"}
                placeholder={selectedLanguage?.name == "English" ? "Select Country" : translation[53]?.value ? translation[53]?.value : "Select Country"}
                name="country"
                value={country}
                onChange={(e) => handleLocationChange(e, "country")}
                onBlur={() => onBlurHandler({ country: country })}
                error={errors.country}
                options={countriesList}
                edit={isEdit ? true : false}
              />
              <Dropwdown
                required={false}
                title={selectedLanguage?.name == "English" ? "State/Province" : translation[54]?.value ? translation[54]?.value : "State/Province"}
                placeholder={selectedLanguage?.name == "English" ? "Select State" : translation[55]?.value ? translation[55]?.value : "Select State"}
                name="state"
                value={state}
                onChange={(e) => handleLocationChange(e, "state")}
                options={stateList}
                edit={isEdit ? true : false}
              />
              {/* <Dropwdown
                required={false}
                title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Postal Code" : translation[57]?.value ? translation[57]?.value : "Select City"}
                name="city"
                value={city}
                onChange={handleLocationChange}
                // onBlur={() => onBlurHandler({ city: city })}
                // error={errors.city}
                options={cityList}
                edit={isEdit ? true : false}
              /> */}
              <LoginInput
                required={false}
                name={"city"}
                value={city}
                disabled={!state || !country}
                onChange={handleLocationChange}
                title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                placeholder={selectedLanguage?.name == "English" ? "Enter City Name" : translation[57]?.value ? translation[57]?.value : "Enter City Name"}
              />
              <LoginInput
                required={false}
                title={selectedLanguage?.name == "English" ? "Postal Code" : translation[58]?.value ? translation[58]?.value : "Postal Code"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Postal Code" : translation[59]?.value ? translation[59]?.value : "Enter Postal Code"}
                name="postal_code"
                // type="number"
                type="text"
                value={postal_code}
                onChange={handleLocationChange}
              />

              <LoginInput
                required={true}
                title={selectedLanguage?.name == "English" ? "Email Address" : translation[60]?.value ? translation[60]?.value : "Email Address"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Email Address" : translation[61]?.value ? translation[61]?.value : "Enter Email Address"}
                name="email"
                value={email}
                onChange={handleLocationChange}
                error={errors.email}
                onBlur={() => onBlurHandler({ email: email })}
                disabled={isEdit ? true : false}
              />

              <PhoneInput
                required={true}
                title={selectedLanguage?.name == "English" ? "Phone Number" : translation[62]?.value ? translation[62]?.value : "Phone Number"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Phone Number" : translation[63]?.value ? translation[63]?.value : "Enter Phone Number"}
                name="mobile_number"
                value={mobile_number}
                onChange={handleLocationChange}
                error={errors.mobile_number}
                onBlur={() => onBlurHandler({ mobile_number: mobile_number })}
                edit={isEdit ? true : false}
              />

              <Dropwdown
                required={false}
                title={selectedLanguage?.name == "English" ? "Booking" : translation[64]?.value ? translation[64]?.value : "Booking"}
                placeholder={selectedLanguage?.name == "English" ? "Select Booking" : translation[65]?.value ? translation[65]?.value : "Select Booking"}
                name="banking"
                value={banking}
                onChange={handleLocationChange}
                options={[
                  { value: "Enable", label: selectedLanguage?.name == "English" ? "Enable" : translation[65]?.value ? translation[65]?.value : "Enable" },
                  { value: "Disable", label: selectedLanguage?.name == "English" ? "Disable" : translation[66]?.value ? translation[66]?.value : "Disable" },
                ]}
              />
              <Dropwdown
                required={true}
                title={selectedLanguage?.name == "English" ? "Currency" : translation[67]?.value ? translation[67]?.value : "Currency"}
                placeholder={selectedLanguage?.name == "English" ? "Select Currency" : translation[68]?.value ? translation[68]?.value : "Select Currency"}
                name={"currency"}
                value={currency}
                onChange={handleLocationChange}
                onBlur={() => onBlurHandler({ currency: currency })}
                error={errors.currency}
                options={currency_list?.map((crncy) => {
                  return {
                    label: crncy?.code,
                    value: crncy?.id,
                  };
                })}
              />

              <Dropwdown
                required={true}
                title={selectedLanguage?.name == "English" ? "Primary Language" : translation[1290]?.value ? translation[1290]?.value : "Primary Language"}
                placeholder={selectedLanguage?.name == "English" ? "Select Primary Language" : translation[1291]?.value ? translation[1291]?.value : "Select Primary Language"}
                name="primary_translation_id"
                value={primary_translation_id}
                onChange={handleLocationChange}
                error={errors.primary_translation_id}
                onBlur={() => onBlurHandler({ primary_translation_id: primary_translation_id })}
                options={reduxInvoices?.filter(itm => itm?.status == "active")
                  ?.filter(itm => itm?.language_data?.id != secondary_translation_id)?.map((lang) => {
                    return {
                      label: lang?.language_data?.name,
                      value: lang?.id,
                    };
                  })}
              />

              <Dropwdown
                title={selectedLanguage?.name == "English" ? "Secondary Language" : translation[1289]?.value ? translation[1289]?.value : "Secondary Language"}
                placeholder={selectedLanguage?.name == "English" ? "Select Secondary Language" : translation[1288]?.value ? translation[1288]?.value : "Select Secondary Language"}
                name="secondary_translation_id"
                value={secondary_translation_id}
                onChange={handleLocationChange}
                error={errors.secondary_translation_id}
                onBlur={() => onBlurHandler({ secondary_translation_id: secondary_translation_id })}
                options={[
                  {
                    value: "",
                    label: selectedLanguage?.name == "English" ? "Select Secondary Language" : translation[1288]?.value ? translation[1288]?.value : "Select Secondary Language"
                  },
                  ...reduxInvoices?.filter(itm => itm?.status == "active")?.filter(itm => itm?.language_data?.id != primary_translation_id)?.map((lang) => {
                    return { value: lang?.id, label: lang?.language_data?.name };
                  }),
                ]}
              />
            </div>
            <div className="flex items-center justify-start mt-[2rem]">
              <LoginBtn
                // disabled={
                //   !currency ||
                //   !address_name ||
                //   !address ||
                //   !email ||
                //   !mobile_number ||
                //   !country
                // }
                text={selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
                onClick={() => {
                  // setstep(2)
                  onfirststepsubmit();
                  // onLocationSubmit
                }}

              >
                {/* <Svgs.NextArrow /> */}
              </LoginBtn>
            </div>
          </div>
        )}
        {step == 2 && (
          <div className="flex flex-col gap-6">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[3rem]">
              <CircleIcon>
                <Svgs.Location />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Opening Hours" : translation[70]?.value ? translation[70]?.value : "Opening Hours"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Set the opening and closing hours of your business" : translation[71]?.value ? translation[71]?.value : "Set the opening and closing hours of your business"}
                </p>
              </div>
            </div>
            {WEEKDAYS.map((days, index) => {
              // console.log("days",days);
              days = days.toLowerCase();
              return (
                <>
                  <div className="md:grid flex grid-cols-5 gap-4 lg:flex-row flex-col pb-[1.5rem] h-[3.75rem]">
                    <div className="col-span-1 flex items-center">
                      <div className="w-full">
                        <ToggleWithText
                          checked={
                            location2[days]?.start_time &&
                            location2[days]?.end_time
                          }
                          title={<p className="capitalize">{days}</p>}
                          value={location2[days]}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setLocation2({
                                ...location2,
                                [days]: {
                                  is_closed: false,
                                  start_time:
                                    days === "monday"
                                      ? ""
                                      : location2["monday"]?.start_time,
                                  end_time:
                                    days === "monday"
                                      ? ""
                                      : location2["monday"]?.end_time,
                                },
                              });
                            } else {
                              delete location2[days];
                              setLocation2({
                                ...location2,
                                // [days]: null
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                    {location2[days] && (
                      <div className="col-span-4 flex items-center w-full sm:gap-4 gap-2 lg:flex-row flex-col">
                        <Dropwdown
                          className="w-full"
                          title={false}
                          showTitle={false}
                          placeholder="Start time"
                          // options={OPENING_TIME}
                          options={OPENING_TIME.filter((itm, itm_index) => {
                            if (!location2[days]?.end_time) {
                              return true;
                            } else {
                              let index_start = OPENING_TIME.find(
                                (op_itm) =>
                                  op_itm.value == location2[days]?.end_time
                              );
                              index_start = OPENING_TIME.indexOf(index_start);
                              // console.log("index_start",index_start);

                              if (index_start >= 0 && itm_index < index_start) {
                                return true;
                              }

                              return false;
                            }
                          })}
                          value={location2[days]?.start_time}
                          onChange={(e) => {
                            setLocation2({
                              ...location2,
                              [days]: {
                                ...location2[days],
                                start_time: e.target.value,
                              },
                            });
                          }}
                        // value={location2.sunday.start_time}
                        />
                        <Dropwdown
                          className="w-full"
                          title={false}
                          showTitle={false}
                          placeholder="End time"
                          options={OPENING_TIME.filter((itm, itm_index) => {
                            if (!location2[days]?.start_time) {
                              return true;
                            } else {
                              let index_start = OPENING_TIME.find(
                                (op_itm) =>
                                  op_itm.value == location2[days]?.start_time
                              );
                              index_start = OPENING_TIME.indexOf(index_start);
                              if (index_start >= 0 && itm_index > index_start) {
                                return true;
                              }
                              return false;
                            }
                          })}
                          value={location2[days]?.end_time}
                          onChange={(e) => {
                            setLocation2({
                              ...location2,
                              [days]: {
                                ...location2[days],
                                end_time: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              );
            })}
            <div className="text-red-500">
              {(isButtonDisabled && buttonClick) && "Add at least one day of business working hours"}
            </div>
            <div className="flex items-center justify-start mt-[2rem]">
              <LoginBtn
                disabled={loading}
                loading={loading}
                text={`${isEdit ? selectedLanguage?.name == "English" ? "Edit Location" : translation[88]?.value ? translation[88]?.value : "Edit Location"
                  :
                  selectedLanguage?.name == "English" ? "Add Location" : translation[44]?.value ? translation[44]?.value : "Add Location"
                  }`}
                onClick={() => {
                  // setstep(2),
                  setButtonClick(true)
                  onLocationSubmit();
                }}
              // disabled={Object.keys(location2).length > 0 ? Object.keys(location2).find(key => (!location2[key]?.end_time || !location2[key]?.start_time)) ? true : false : false}

              >
                {/* <Svgs.NextArrow /> */}
              </LoginBtn>
            </div>
          </div>
        )}
      </Popup>}
    </>
  );
};

export default AddStocksTransfer;
