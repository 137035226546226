import React from 'react'

const TableShimmer_v2 = ({ cols, rows, rowHeight }) => {
    let array = []
    for (let j = 0; j < cols; j++) {
        array.push(j);
    }
    let row = []
    let rowLength = rows ? rows : 10
    for (let i = 0; i < rowLength; i++) {
        row.push(i);
    }



    return (
        <>
            <div className="overflow-x-auto py-4 animate-pulse">
                <div className={`px-12 py-3 inline-grid xl:grid gap-2 grid-cols-[repeat(${cols},minmax(145px,1fr))] min-w-full w-fit`}>
                    {array.map(itm => {
                        return (
                            <p className="bg-gray-200 rounded-md h-9"> </p>
                        )
                    })}
                </div>
                <div className={`px-12 inline-grid xl:grid gap-2 grid-cols-[repeat(${cols},minmax(145px,1fr))] min-w-full w-fit`}>
                    {row?.map((item, index) => {
                        return (
                            <>
                                {array.map(itm => {
                                    return (
                                        <p className={`bg-gray-200 rounded-md ${rowHeight ? rowHeight : "h-11"}`}> </p>
                                    )
                                })}
                            </>
                        )
                    })}
                </div>
            </div>
        </>

    )
}

export default TableShimmer_v2