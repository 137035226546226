import axios from "axios";

import {
  add_vacation_url,
  BASE_URL,
  delete_vacation_url,
  get_vacation_list_url,
  update_vacation_status_url,
  update_vacation_url,
} from "../api_variables";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";
import { toast } from "react-toastify";


export const update_vacation_status = (data, access_token, success, fail) => async (dispatch) => {
  const form_data = convertDataToFormData(data)
  try {
    const response = await axios.patch(`${BASE_URL}${update_vacation_status_url}`, form_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};
export const fetchVacationList = async (currentPage, location_id, filterStaff, searchText, access_token, setLoader) => {
  setLoader && setLoader(true)
  const location = location_id ? `?location_id=${location_id}` : ''
  const employee = filterStaff ? `&employee_id=${filterStaff}` : ""
  const search = searchText ? `&search_text=${searchText}` : ''
  const page = currentPage ? `&page=${currentPage}` : ''
  try {
    const result = await axios.get(
      `${BASE_URL}${get_vacation_list_url}${location}${page}${employee}${search}`,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const fetchVacationListWithNoPagination = async (location_id, filterStaff, searchText, access_token, setLoader) => {
  setLoader && setLoader(true)
  const employee = filterStaff ? `&employee_id=${filterStaff}` : ""
  const search = searchText ? `&search_text=${searchText}` : ''
  const location = location_id ? `?location_id=${location_id}` : ''
  try {
    const result = await axios.get(
      `${BASE_URL}${get_vacation_list_url}${location}&no_pagination=true${employee}${search}`,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const deleteVacation = async (id, access_token) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const result = await axios.delete(
      `${BASE_URL}${delete_vacation_url}`,
      headers
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const addVacation = async (vacation_data, access_token) => {
  try {
    const result = await axios.post(
      `${BASE_URL}${add_vacation_url}`,
      vacation_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const editVacation = async (vacation_data, access_token) => {

  try {
    let form_data = new FormData();

    for (let ky in vacation_data) {
      if (vacation_data[ky]) {
        form_data.append(ky, vacation_data[ky]);
      }
    }
    const result = await axios.put(
      `${BASE_URL}${update_vacation_url}`,
      form_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};