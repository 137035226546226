import { HOLIDAYS } from "Redux/TenantRedux/ActionTypes/holidayTypes";

const initialState = {
    holidays: [],
    holidayPagination: {}
};

const HolidayReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case HOLIDAYS.GET_HOLIDAYS:
            return {
                ...state,
                holidays: payload?.response?.data,
                holidayPagination: {
                    pages: Math.ceil(+payload?.total_pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page,
                    isSearched: payload?.is_searched
                }
            };

        case HOLIDAYS.ADD_HOLIDAYS:
            return {
                ...state,
                holidays: [payload, ...state.holidays],
                holidayPagination: {
                    ...state?.holidayPagination,
                    allCount: state.holidayPagination?.allCount + 1
                }
            };

        case HOLIDAYS.DELETE_HOLIDAYS:
            let result = state?.holidays?.filter((item) => item.id !== payload);
            return {
                ...state,
                holidays: [...result],
                holidayPagination: {
                    ...state?.holidayPagination,
                    allCount: state.holidayPagination?.allCount - 1
                }
            };

        case HOLIDAYS.UPDATE_HOLIDAYS:
            let updateList = state.holidays.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })
            return {
                ...state,
                holidays: [...updateList],
            };

        default:
            return state;
    }

}
export default HolidayReducer