import { CLIENT } from "../../ActionTypes/clientActionTypes";


export const getClients = (data) => {
    return {
        type: CLIENT.GET_CLIENT,
        payload: data
    }
}

export const getClientsDropdown = (data) => {
    return {
        type: CLIENT.GET_CLIENT_DROPDOWN,
        payload: data
    }
}

export const getClientsDropdownWithPagination = (data) => {
    return {
        type: CLIENT.GET_CLIENT_DROPDOWN_PAGINATION,
        payload: data
    }
}

export const getClientsDropdownWithoutPagination = (data) => {
    return {
        type: CLIENT.GET_CLIENT_DROPDOWN_WITHOUT_PAGINATION,
        payload: data
    }
}

export const getClientsWithoutPagination = (data) => {
    return {
        type: CLIENT.GET_CLIENT_WITHOUT_PAGINATION,
        payload: data
    }
}


export const addClient = (data) => {
    return {
        type: CLIENT.ADD_CLIENT,
        payload: data
    }
}
export const getpromo = (data) => {
    return {
        type: CLIENT.CLIENT_PROMO,
        payload: data
    }
}
export const get_rewards = (data) => {
    return {
        type: CLIENT.GET_CLIENT_REWARDS,
        payload: data
    }
}

export const addReward = (data) => {
    return {
        type: CLIENT.ADD_CLIENT_REWARDS,
        payload: data
    }
}



export const editReward = (data) => {
    return {
        type: CLIENT.UPDATE_CLIENT_REWARDS,
        payload: data
    }
}


export const removeReward = (data) => {
    return {
        type: CLIENT.DELETE_CLIENT_REWARDS,
        payload: data
    }
}


export const client_promo = (data) => {
    return {
        type: CLIENT.CLIENT_PROMO,
        payload: data
    }
}
export const getservices = (data) => {
    return {
        type: CLIENT.SERVICES,
        payload: data
    }
}

export const editClient = (data) => {
    return {
        type: CLIENT.UPDATE_CLIENT,
        payload: data
    }
}

export const getclientsub = (data) => {
    return {
        type: CLIENT.CLIENT_SUB,
        payload: data
    }
}

export const removeClient = (data) => {
    return {
        type: CLIENT.DELETE_CLIENT,
        payload: data
    }
}


export const storeClientId = (data) => {
    return {
        type: CLIENT.CLIENT_ID,
        payload: data
    }
}