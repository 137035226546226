import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Popup from "Components/TenantApp/Elements/Popup";

import useMulitLanguage from "./useMulitLanguage";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getAllBusinessLanguages, getAllLanguages } from "Adapters/Api";
import {
  addLanguages,
  deleteLanguage,
  selected_language,
} from "Redux/TenantRedux/Actions/languageActions";
import {
  api_base_url_tenant,
  delete_language_url,
} from "Adapters/Api/api_variables";

import Svgs from "Assets/svgs";

const MultiLanguage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  const all_languages = useSelector((state) => state.languages.languages);

  const state = useSelector((state) => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [submiting, setSubmiting] = useState(false);
  const [del_popup, setDelPopup] = useState(false);
  const {
    errors,
    onBlurHandler,
    onChangeHandler,
    onSubmitHandler,
    setlanguages,
    languageList,
    setLanguageList,
    getTranslation,
    languages,
    multiLanguages,
    finalLanguage
  } = useMulitLanguage();
  const { select_language, default_language } = languages;
  const { language } = multiLanguages;

  //set dropdown options
  const setOptionForDropdown = (DataList) => {
    let optionList =
      DataList &&
      DataList?.map((item) => {
        return { id: item.id, label: item.name, value: item.id };
      });
    return optionList;
  };

  //get all languages
  const getLanguageList = async () => {
    const business_languages = true
    const response = await getAllLanguages(business_languages);
    if (response.status === 200) {
      let languageList = response?.data?.response?.languages;
      let laguageOptionList = setOptionForDropdown(languageList);
      setLanguageList(laguageOptionList);
    }
  };

  const delet_language_handler = (id) => {
    let form_data = new FormData();
    form_data.append("id", id);
    fetch(api_base_url_tenant + delete_language_url, {
      method: "DELETE",
      headers: {
        Authorization: `Token ${getDataFromLocalStorage("access_token")}`,
      },
      body: form_data,
    }).then((response) => {
      if (response.ok) {
        dispatch(deleteLanguage({ id: id }));
        getTranslation("English")
        localStorage.setItem("selected_language", JSON.stringify({ "id": "e0f6d750-d107-4fb2-b9bd-ef22f63d2ba6", "name": "English", "code": "eng" }))
        dispatch(selected_language(JSON.stringify({ "id": "e0f6d750-d107-4fb2-b9bd-ef22f63d2ba6", "name": "English", "code": "eng" })))
      }
    });
  };

  // get business languages
  const getBusinessLanguagesList = async (business_id) => {
    const response = await getAllBusinessLanguages(business_id);

    if (response.status === 200) {
      const businessLanguages = response?.data?.response?.languages;
      dispatch(addLanguages(businessLanguages));
    }
  };

  useEffect(() => {
    if (businessData.id) {
      getBusinessLanguagesList(businessData.id);
    }
    getLanguageList();
  }, []);


  useEffect(() => {
    if (all_languages?.length > 0) {
      localStorage.setItem("selected_language", JSON.stringify(all_languages[0]?.language))
    }
    // languageList
  }, [all_languages])


  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <p className="text-[#7B7B7B] text-sm">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/");
            }}
          >
            {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          </span>{" "}
          &gt;{" "}
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/setup/");
            }}
          >
            {selectedLanguage?.name == "English" ? "Account Setup" : translation[645]?.value ? translation[645]?.value : "Account Setup"}
          </span>{" "}
          &gt; <span className="text-primary">
            {selectedLanguage?.name == "English" ? "Business Language" : translation[664]?.value ? translation[664]?.value : "Business Language"}
          </span>
        </p>
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">
            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Business Language" : translation[664]?.value ? translation[664]?.value : "Business Language"}
            </h2>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex items-center justify-between lg:gap-0 gap-4 lg:flex-row flex-col my-6">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
              <CircleIcon>
                <Svgs.Globe />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Language settings" : translation[763]?.value ? translation[763]?.value : "Language settings"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English"
                    ? "Choose the default language for appointment notification messages sent to your clients. Per-"
                    : translation[764]?.value
                      ? translation[764]?.value
                      : "Choose the default language for appointment notification messages sent to your clients. Per-"
                  }
                  <br className="md:block hidden" />

                  {selectedLanguage?.name == "English"
                    ? "client language preferences can also be set within the settings for each client."
                    : translation[765]?.value
                      ? translation[765]?.value
                      : "client language preferences can also be set within the settings for each client."
                  }
                </p>
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-2 gap-6">
            <Dropwdown
              required={true}
              name="language"
              value={language}
              options={languageList?.filter(
                (lang) =>
                  !all_languages.find(
                    (in_lang) => in_lang.language.id == lang.id
                  )
              )}
              onChange={onChangeHandler}
              onBlur={() => onBlurHandler({ language: language })}
              error={errors.language}
              placeholder={selectedLanguage?.name == "English" ? "Select language" : translation[766]?.value ? translation[766]?.value : "Select language"}
              title={selectedLanguage?.name == "English" ? "Select language" : translation[766]?.value ? translation[766]?.value : "Select language"}
            // isMulti={true}
            />
          </div>
        </div>

        <div className="flex items-center sm:gap-4 gap-2 flex-wrap mt-5">
          {/* {finalLanguage &&
            <div
              className="px-3 py-2 border-[1.2px] rounded-xl border-primary text-primary text-sm max-w-max flex gap-3 items-center justify-between"
            >
              <p>{finalLanguage?.language?.name}</p>
              <span
                className=" cursor-pointer"
                onClick={() => {
                  setDelPopup(finalLanguage?.id);
                }}
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 0C3.35826 0 0 3.35826 0 7.5C0 11.6417 3.35826 15 7.5 15C11.6417 15 15 11.6417 15 7.5C15 3.35826 11.6417 0 7.5 0ZM10.269 10.3493L9.16406 10.3443L7.5 8.36049L5.83761 10.3426L4.73103 10.3477C4.65737 10.3477 4.5971 10.2891 4.5971 10.2137C4.5971 10.1819 4.60882 10.1518 4.62891 10.1267L6.80692 7.53181L4.62891 4.93862C4.60868 4.91408 4.59745 4.88336 4.5971 4.85156C4.5971 4.7779 4.65737 4.71763 4.73103 4.71763L5.83761 4.72266L7.5 6.70647L9.16239 4.72433L10.2673 4.71931C10.341 4.71931 10.4012 4.7779 10.4012 4.85324C10.4012 4.88504 10.3895 4.91518 10.3694 4.94029L8.19475 7.53348L10.3711 10.1283C10.3912 10.1535 10.4029 10.1836 10.4029 10.2154C10.4029 10.2891 10.3426 10.3493 10.269 10.3493Z"
                    fill="#5679FF"
                  />
                </svg>
              </span>
            </div>
          } */}
          {all_languages &&
            all_languages.map((item) => {
              return (
                <div
                  key={item.id}
                  className="px-3 py-2 border-[1.2px] rounded-xl border-primary text-primary text-sm max-w-max flex gap-3 items-center justify-between"
                >
                  <p>{item.language.name}</p>
                  {/* <span
                    className=" cursor-pointer"
                    onClick={() => {
                      setDelPopup(item.id);
                    }}
                  >
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 0C3.35826 0 0 3.35826 0 7.5C0 11.6417 3.35826 15 7.5 15C11.6417 15 15 11.6417 15 7.5C15 3.35826 11.6417 0 7.5 0ZM10.269 10.3493L9.16406 10.3443L7.5 8.36049L5.83761 10.3426L4.73103 10.3477C4.65737 10.3477 4.5971 10.2891 4.5971 10.2137C4.5971 10.1819 4.60882 10.1518 4.62891 10.1267L6.80692 7.53181L4.62891 4.93862C4.60868 4.91408 4.59745 4.88336 4.5971 4.85156C4.5971 4.7779 4.65737 4.71763 4.73103 4.71763L5.83761 4.72266L7.5 6.70647L9.16239 4.72433L10.2673 4.71931C10.341 4.71931 10.4012 4.7779 10.4012 4.85324C10.4012 4.88504 10.3895 4.91518 10.3694 4.94029L8.19475 7.53348L10.3711 10.1283C10.3912 10.1535 10.4029 10.1836 10.4029 10.2154C10.4029 10.2891 10.3426 10.3493 10.269 10.3493Z"
                        fill="#5679FF"
                      />
                    </svg>
                  </span> */}
                </div>
              );
            })}
        </div>
        <div className="flex items-center justify-start mt-[2rem]">
          <LoginBtn
            onClick={async () => {
              setSubmiting(true);
              await onSubmitHandler();
              setlanguages({ select_language: undefined });
              setTimeout(() => {
                setSubmiting(false);
              }, 500);
            }}
            disabled={select_language ? false : true}

            loading={submiting}
          >
            {selectedLanguage?.name == "English" ? "Save Settings" : translation[759]?.value ? translation[759]?.value : "Save Settings"}
          </LoginBtn>
        </div>
      </div>

      {del_popup && (
        <Popup
          heading={
            selectedLanguage?.name == "English"
              ? "Are you sure you want to delete this Language?"
              : translation[767]?.value
                ? translation[767]?.value
                : "Are you sure you want to delete this Language?"
          }

          onclose={() => setDelPopup(false)}
        >
          <p>
            {
              selectedLanguage?.name == "English"
                ? "Are you sure you want to delete this Language"
                : translation[767]?.value
                  ? translation[767]?.value
                  : "Are you sure you swant to delete this Language"
            }

            {selectedLanguage?.name == "English"
              ? "You won't be able to see this again."
              : translation[745]?.value
                ? translation[745]?.value
                : "You won't be able to see this again."}

          </p>
          <div className="mt-[2rem] flex justify-end">
            <LoginBtn
              text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
              deleteBtn
              onClick={async () => {
                await delet_language_handler(del_popup);
                setDelPopup(false);
              }}

            ></LoginBtn>
          </div>
        </Popup >
      )}
    </>
  );
};

export default MultiLanguage;
