import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocations } from "Adapters/Api";
import { getEmployeeList } from "Adapters/Api/employee";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getServiceGroupPaginationDropdown } from "Adapters/Api/saleServices";

let serviceGroupTimeOutId = undefined;

export const useAddServices = ({
  setService,
  setAddService,
  setIsEdit,
  setServiceType,
  serviceData,
}) => {
  const dispatch = useDispatch();

  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  let selected_location = getDataFromLocalStorage("selected_location");
  const businessData = getDataFromLocalStorage("business_data");

  const state = useSelector(state => state)
  const { employee, saleService, locations } = useSelector((state) => state);
  const employeeList = employee?.employees;
  const employeeRedux = useMemo(() => { return employeeList.filter((emp) => emp?.is_active === true) }, [employeeList])
  const reduxlocationsList = locations?.locations;
  const locationList = state.locations.locations;
  const serviceGroupPaginationData = saleService?.servicesGroupDropdownPagination;
  const reduxgroup = saleService.servicesGroupDropdown;
  const reduxInvoices = state?.multilanguageInvoices?.invoices

  let allUinqueCurrencyList = [];
  locationList?.filter((item) => item?.currency?.id).filter((itm) => {
    if (!allUinqueCurrencyList?.find((dt) => dt?.currency?.id === itm?.currency?.id) && (itm?.id === selected_location || serviceData?.location?.includes(itm?.id))) {
      allUinqueCurrencyList?.push(itm)
      return true
    }
  })

  const [selecteDuration, setDuration] = useState();
  const [selectePrice, setPrice] = useState();
  const [service_name, setService_name] = useState()
  const [employeeOnSelectedLocation, setEmployeeOnSelectedLocation] = useState()
  const [serviceGroupSearchText, setServiceGroupSearchText] = useState("");
  const [serviceGroupCurrentPage, setServiceGroupCurrentPage] = useState(1)
  const [serviceGroupLoader, setServiceGroupLoader] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState(allUinqueCurrencyList[0]?.currency?.id);
  const [invoiceLanguages, setInvoiceLanguages] = useState(reduxInvoices[0]?.language)


  useEffect(() => {
    if (serviceGroupSearchText === null) {
      // dispatch(getServiceGroup());
      dispatch(getServiceGroupPaginationDropdown(serviceGroupCurrentPage, "", setServiceGroupLoader))
    } else {
      if (serviceGroupTimeOutId) {
        clearTimeout(serviceGroupTimeOutId)
      }
      serviceGroupTimeOutId = setTimeout(() => {
        dispatch(getServiceGroupPaginationDropdown(serviceGroupCurrentPage, serviceGroupSearchText, setServiceGroupLoader))
      }, 500);
    }
  }, [serviceGroupSearchText, serviceGroupCurrentPage])

  useEffect(() => {
    if (employeeRedux?.length > 0) {
      let filtered = employeeRedux?.filter(itm => itm?.location?.length > 0)
      let filteredEmployees = filtered?.filter((emp) => (emp?.location[0]?.id === selected_location) || (serviceData?.location?.includes(emp?.location[0]?.id)))
      setEmployeeOnSelectedLocation(filteredEmployees)
    }
  }, [employeeRedux])

  // //handle employee and currency selection and dropdown data on the basis of location dropdown
  // useEffect(() => {
  //   let prevEmp = employeeOnSelectedLocation || []
  //   setService((prev) => ({
  //     ...prev,
  //     priceservice: [
  //       ...prev?.priceservice?.filter((crrncy) => allUinqueCurrencyList?.find((dt) => dt?.currency?.id === crrncy?.currency))
  //     ],
  //     employee: [
  //       ...prevEmp?.filter((emp) => prev?.employee?.includes(emp?.id))?.map((itm) => itm?.id)]
  //   }));
  // }, [serviceData?.location, employeeRedux])

  //get business location and stored it in redux
  const getBusinessLocation = (business_id) => {
    dispatch(getLocations(business_id));
  };

  const addDurationPrice = (data) => {
    setService({
      ...serviceData,
      priceservice: [...serviceData?.priceservice, { ...data }],
    });
  };

  const addMultilanguageInvoices = (data) => {
    setService({
      ...serviceData,
      invoices: [...serviceData?.invoices, { ...data }],
    });
  };

  const openAddServicePopup = () => {
    setAddService(true);
    setIsEdit(false);
    setServiceType("Service");
  };

  useEffect(() => {
    if (businessData?.id) {
      if (reduxlocationsList?.length === 0) {
        getBusinessLocation(businessData?.id);
      }
    }
    dispatch(getEmployeeList());
  }, []);

  return {
    employeeOnSelectedLocation, reduxgroup, selectePrice, employeeRedux, selecteDuration, reduxlocationsList, openAddServicePopup,
    addDurationPrice, addMultilanguageInvoices, invoiceLanguages, setInvoiceLanguages, service_name, setService_name, setDuration,
    setPrice, selectedCurrency, setSelectedCurrency, allUinqueCurrencyList, serviceGroupSearchText, setServiceGroupSearchText,
    serviceGroupCurrentPage, setServiceGroupCurrentPage, serviceGroupLoader, serviceGroupPaginationData,
  };
};