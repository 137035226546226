export const LOCATION = {
    ADD_LOCATION: "ADD_LOCATION",
    CREATE_LOCATION: "CREATE_LOCATION",
    UPDATE_LOCATION: "UPDATE_LOCATION",
    DELETE_LOCATION: "DELETE_LOCATION",
    SELECTED_LOCATION: "SELECTED_LOCATION",
    IMAGE_PREVIEW: "IMAGE_PREVIEW",
    STORE_CURRENT_LOCATION_DATA: "STORE_CURRENT_LOCATION_DATA"
}



export const BUSINESS = {
    UPDATE_BUSINESS: "UPDATE_BUSINESS",
    ADD_BUSINESS: "ADD_BUSINESS",

}