import { EMPLOYEES } from "../../ActionTypes/employeeTypes";

// GET_COMMISSION_BY_STAFF: "GET_COMMISSION_BY_STAFF",
//   GET_COMMISSION_BY_DETAIL: "GET_COMMISSION_BY_DETAIL",
const initialState = {
  employees: [],
  employeesStaffData: [],
  employeePages: 0,
  employeeAllCount: 0,
  allEmployees: [],
  working_schedule: [],
  working_schedule_updated: false,
  employee_updated: false,
  assets: [],
  assets_updated: false,
  commissions: [],
  allCommissions: [],
  commissionPages: 0,
  commissionAllCount: 0,
  commissions_updated: false,
  employee_commsions: [],
  commission_by_staff: [],
  commission_by_staff_updated: false,
  commission_by_detail: [],
  all_commission_by_detail: [],
  pagesCommissionDetailed: 0,
  allCountCommissionDetailed: 0,
  commission_by_detail_updated: false,
  invoice_titles: {},
  employeesDropdown: [],
  employeeDropdownPaginationData: {},
  employeesPaginatedRedux: [],
  employeesPaginatedReduxWithoutSearch: [],
  employeesSalesCart: [],
  weekend: [],
  employeeSkills: [],
  employeeTraining: [],
  employeeTrainingPaginationData: {},
  weekend_updated: false,
};

const EmployeeReducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {

    case EMPLOYEES.GET_EMPLOYEES:
      return {
        ...state,
        employee_updated: true,
        employees: payload,
      };

    case EMPLOYEES.GET_EMPLOYEES_SALES_CART:
      return {
        ...state,
        employee_updated: true,
        employeesSalesCart: payload,
      };

    // case EMPLOYEES.GET_EMPLOYEES_DROPDOWN:
    //   return {
    //     ...state,
    //     employee_updated: true,
    //     employeesDropdown: payload?.is_searched ?
    //       payload?.response?.employees
    //       : payload?.current_page == 1 ? payload?.response?.employees
    //         : [...state?.employeesDropdown, ...payload?.response?.employees],
    //   };

    case EMPLOYEES.GET_EMPLOYEES_STAFF_DATA:
      return {
        ...state,
        employee_updated: true,
        employeesStaffData: payload,
      };

    case EMPLOYEES.GET_EMPLOYEES_WITHOUT_PAGINATION:
      return {
        ...state,
        employee_updated: true,
        allEmployees: payload,
      };

    case EMPLOYEES.GET_EMPLOYEE_PAGES:
      return {
        ...state,
        employeePages: payload?.response?.pages,
        employeeAllCount: payload?.response?.count
      };


    case EMPLOYEES.GET_EMPLOYEES_PAGINATION_DATA:
      const uniqueEmployeeWithSearch = Array.from(
        new Set([...state?.employeesDropdown, ...payload?.response?.employees].map(empl => empl.id))).map(id => {
          return [...state?.employeesDropdown, ...payload?.response?.employees].find(empl => empl.id === id);
        });

      const uniqueEmployeeWithoutSearch = Array.from(
        new Set([...state?.employeesPaginatedReduxWithoutSearch, ...payload?.response?.employees]?.map(empl => empl.id))).map(id => {
          return [...state?.employeesPaginatedReduxWithoutSearch, ...payload?.response?.employees]?.find(empl => empl.id === id);
        });

      return {
        ...state,
        employee_updated: true,
        employeesDropdown: payload?.is_searched
          ? (Math.ceil(+payload?.pages) > 1 && +payload?.current_page > 1)
            ? uniqueEmployeeWithSearch
            : payload?.response?.employees
          : payload?.current_page == 1
            ? payload?.response?.employees
            : uniqueEmployeeWithoutSearch,

        employeesPaginatedReduxWithoutSearch: payload?.current_page == 1 ? payload?.response?.employees
          : uniqueEmployeeWithoutSearch,

        employeeDropdownPaginationData: {
          employeePages: Math.ceil(+payload?.pages),
          employeeAllCount: payload?.count,
          employeeCurrentPage: +payload?.current_page
        }
      };


    case EMPLOYEES.ADD_EMPLOYEE:
      return {
        ...state,
        employee_updated: true,
        employees: [payload, ...state.employees],
      };


    case EMPLOYEES.DELETE_EMPLOYEE:
      let result = state?.employees?.filter((item) => item.id !== payload);
      return {
        ...state,
        employees: [...result],
      };


    case EMPLOYEES.UPDATE_EMPLOYEE:
      let updateList = state.employees.map((item) => {
        if (item.id === payload.id) {
          return payload
        } else {
          return item;
        }
      })

      return {
        ...state,
        employees: [...updateList],
      };


    case EMPLOYEES.GET_WORKING_SCHEDULE:
      return {
        ...state,
        working_schedule: payload,
        working_schedule_updated: true,
      };

    case EMPLOYEES.GET_WEEKEND:
      return {
        ...state,
        weekend: payload,
        weekend_updated: true,
      };


    // ASSETS 
    case EMPLOYEES.GET_ASSETS:
      return {
        ...state,
        assets: payload,
        assets_updated: true,
      };

    case EMPLOYEES.ADD_ASSETS:
      return {
        ...state,
        assets: [payload, ...state.assets],
      };


    case EMPLOYEES.UPDATE_ASSETS:
      return {
        ...state,
        assets: state?.assets?.map((item) => {
          if (item.id === payload.id) {
            return payload
          } else {
            return item;
          }
        }),
      };



    case EMPLOYEES.DELETE_ASSETS:
      return {
        ...state,
        assets: state?.assets?.filter((item) => item.id !== payload),
      };



    // commission start

    case EMPLOYEES.GET_COMMISSION:
      return {
        ...state,
        commissions: payload,
        commissions_updated: true,
      };

    case EMPLOYEES.GET_COMMISSION_WITHOUT_PAGINATION:
      return {
        ...state,
        allCommissions: payload,
        commissions_updated: true,
      };

    case EMPLOYEES.GET_COMMISSION_PAGES:
      return {
        ...state,
        commissionPages: payload?.pages,
        commissionAllCount: payload?.count
      };


    case EMPLOYEES.ADD_COMMISSION:
      return {
        ...state,
        commissions: [payload, ...state.commissions],
      };


    case EMPLOYEES.UPDATE_COMMISSION:
      return {
        ...state,
        commissions: state?.commissions?.map((item) => {
          if (item.id === payload.id) {
            return payload
          } else {
            return item;
          }
        }),
      };


    case EMPLOYEES.DELETE_COMMISSION:
      return {
        ...state,
        commissions: state?.commissions?.filter((item) => item.id !== payload),
      };

    // commission end


    // COMMISSION REPORTS START
    case EMPLOYEES.EMPLOYEE_COMMISSIONS:
      return {
        ...state,
        employee_commsions: payload,
      };

    case EMPLOYEES.GET_COMMISSION_BY_STAFF:
      return {
        ...state,
        commission_by_staff_updated: true,
        commission_by_staff: payload,
      };


    case EMPLOYEES.GET_COMMISSION_BY_DETAIL:
      return {
        ...state,
        commission_by_detail_updated: true,
        commission_by_detail: payload?.response?.sales,
        invoice_titles: payload?.response?.invoice_translations,
        pagesCommissionDetailed: payload?.pages,
        allCountCommissionDetailed: payload?.count
      };

    case EMPLOYEES.GET_COMMISSION_BY_DETAIL_WITHOUT_PAGINATION:
      return {
        ...state,
        all_commission_by_detail: payload?.response?.sales,
      };

    // COMMISSION REPORTS END


    // Employee Training 

    case EMPLOYEES.GET_EMPLOYEE_SKILL:
      return {
        ...state,
        employeeSkills: payload?.response?.data
      }

    case EMPLOYEES.GET_EMPLOYEE_TRAINING:
      return {
        ...state,
        employeeTraining: payload?.response?.data,
        employeeTrainingPaginationData: {
          count: payload?.response?.count,
          total_pages: payload?.response?.total_pages,
        }
      }
    default:
      return state;
  }
};
export default EmployeeReducer;
