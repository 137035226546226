import { RETAIL_TARGET } from './../../ActionTypes/retailTargetTypes/index';

const initialState = {
    retail_targets: [],
    all_retail_targets: [],
    pages: 0,
    allCount: 0,
    retail_target_updated: false,
    // retail target report kpi
    retailTargetReports: [],
    allRetailTargetReports: [],
    retailTargetReportsAllCount: 0,
    retailTargetReportsPages: 0,
    retailTargetReports_updated: false,
};


const RetailTargetReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {

        case RETAIL_TARGET.GET_RETAIL_TARGET:
            return {
                ...state,
                retail_targets: payload?.response?.retailtarget,
                retail_target_updated: true,
                pages: payload?.pages,
                allCount: payload?.count
            };

        case RETAIL_TARGET.GET_RETAIL_TARGET_WITHOUT_PAGINATION:
            return {
                ...state,
                all_retail_targets: payload?.data?.response?.retailtarget,
                retail_target_updated: true,
            };

        case RETAIL_TARGET.CREATE_RETAIL_TARGET:
            return {
                ...state,
                retail_targets: [payload, ...state.retail_targets]
            };


        case RETAIL_TARGET.DELETE_RETAIL_TARGET:
            let result = state?.retail_targets?.filter((item) => item.id !== payload);
            return {
                ...state,
                retail_targets: [...result],
            };


        case RETAIL_TARGET.UPDATE_RETAIL_TARGET:
            let updateList = state?.retail_targets?.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })

            return {
                ...state,
                retail_targets: [...updateList],
            };

        case RETAIL_TARGET.GET_RETAIL_TARGET_REPORT:
            return {
                ...state,
                retailTargetReports: payload?.response?.sale,
                retailTargetReportsPages: payload?.pages,
                retailTargetReportsAllCount: payload?.count,
                retailTargetReports_updated: true
            };

        case RETAIL_TARGET.GET_RETAIL_TARGET_REPORT_WITHOUT_PAGINATION:
            return {
                ...state,
                allRetailTargetReports: payload?.response?.sale,
                retailTargetReports_updated: true
            };

        default:
            return state;
    }
};
export default RetailTargetReducer;
