import React from 'react'
import Svgs from '../../../../../Assets/svgs'
import LoginBtn from '../../../Elements/LoginBtn'

const SupportCard = () => {
    return (
        <>
        {/* <div className='rounded-md appointment-shadow lg:mt-0 mt-[1rem]'>
            <div className='p-4 flex items-start gap-3 '>
                <Svgs.Support />
                <div className='flex flex-col gap-3'>
                    <h2 className='text-primary font-semibold'>We can help you</h2>
                    <p className='text-[#A1A1A1] text-sm'>Call us <span className='text-primary font-semibold'>+971 876557 9766</span> (or) chat with our
                        customer support team.</p>
                    <LoginBtn borderBtn text='Contact Us' />
                </div>
            </div>
        </div> */}
        </>
    )
}

export default SupportCard