import Svgs from 'Assets/svgs'
import NotFound from 'Components/TenantApp/Dashboard/Sales/Elements/NotFound'
import React from 'react'
import DeleteConfirmation from 'new-components/delete-popup'
import Pagination from 'new-components/Pagination'

const ExpenseTableCategory = ({ data, selectedLanguage, deletePopup, loader, setDeletePopup, translation, setIsEdit, setEditData, setAddCategoryPopup, deleteLoading, onDeleteClick, expensePagination, currentPage, setCurrentPage }) => {

    return (
        <>
            <div className="overflow-x-auto rounded-lg relative">
                <div className="inline-grid xl:grid border-b grid-cols-[repeat(3,minmax(140px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                    <p>{selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
                </div>
                {data?.length == 0
                    ? <NotFound />
                    : <div className={`odd-bg transition-all border-l-[0px]`}>
                        {data?.map(itm => {
                            return <div className="odd-bg inline-grid items-center xl:grid grid-cols-[repeat(3,minmax(140px,1fr))] px-12 py-3 text-sm min-w-full w-fit">
                                <p>{itm?.id?.split("-")[0]}</p>
                                <p>{itm?.name}</p>
                                <div className="flex items-center gap-3">
                                    <div
                                        className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center"
                                        onClick={() => {
                                            setIsEdit && setIsEdit(true)
                                                    setEditData && setEditData(itm)
                                                    setAddCategoryPopup && setAddCategoryPopup(true)
                                        }}
                                    >
                                        <Svgs.Edit fill="#7D7D7D" />
                                    </div>
                                    <div
                                        className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                                        onClick={() => {
                                            setDeletePopup(itm?.id)
                                        }}
                                    >
                                        <Svgs.Delete />
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                }
                {(data?.length > 0 && !loader) &&
                    <Pagination
                        currentPage={currentPage}
                        totalCount={Math.ceil(expensePagination?.total_pages)}
                        pageSize={10}
                        onPageChange={page => setCurrentPage(page)}
                    />
                }
            </div>

            {deletePopup &&
                <DeleteConfirmation
                    handleClose={() => setDeletePopup()}
                    heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this expense category?" : translation[1791]?.value ? translation[1791]?.value : "Are you sure you want to delete this expense?"}
                    description={`
                        ${selectedLanguage?.name == "English" ? "Are you sure you want to delete this expense category?" : translation[1791]?.value ? translation[1791]?.value : "Are you sure you want to delete this expense category?"}
                        ${selectedLanguage?.name == "English" ? "You won't be able to see this again." : translation[745]?.value ? translation[745]?.value : "You won't be able to see this again."}`}
                    isLoading={deleteLoading}
                    handleDelete={onDeleteClick}
                />
            }

        </>
    )
}

export default ExpenseTableCategory