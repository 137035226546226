/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "new-components/location/helper";

import { MONTHS } from "Constants/Data/Months";
import { getServiceGroup } from "Adapters/Api/saleServices";
import { get_retail_target_kpi, get_retail_target_kpi_without_pagination } from "Adapters/Api/retailsTarget";
import { getBrandDropdownlist, getBrandDropdownlistPagination } from "Adapters/Api/Brand";
import { toast } from "react-toastify";

let timeOutId = undefined;
let brandTimeOutId = undefined;

export const useReports = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locationDetail } = useLocation();

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const { selected_location } = state?.locations;
  const reportStatus = state?.retailTarget?.retailTargetReports_updated;
  const reports = state?.retailTarget?.retailTargetReports;
  const pages = state?.retailTarget?.retailTargetReportsPages;
  const allCount = state?.retailTarget?.retailTargetReportsAllCount;
  const allReports = state?.retailTarget?.allRetailTargetReports;
  const serviceGroups = state?.saleService?.servicesgroup;
  // const brandRedux = state?.brand?.brands;
  const currencyOfSelectedLocation = locationDetail?.currency?.code

  let date = new Date();
  let today_year = date.getFullYear();
  let month = date.getMonth();

  const [filterBrand, setFilterBrand] = useState("");
  const [filterYear, setFilterYear] = useState(today_year);
  const [filterMonth, setFilterMonth] = useState(month);
  const [loading, setLoading] = useState(true);
  const [noPaginationLoader, setNoPaginationLoader] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const PageSize = 10


  const [brandSearchText, setBrandSearchText] = useState("");
  const [brandCurrentPage, setBrandCurrentPage] = useState(1)
  const [brandLoader, setBrandLoader] = useState(false)
  const brandRedux = state?.brand?.brandsDropdown;
  const brandPaginationData = state?.brand?.brandsPagination;


  let selectedd_location = locationDetail?.id
  useEffect(() => {
    if (serviceGroups.length === 0 && selectedd_location) {
      dispatch(getServiceGroup());
    }
  }, [selectedd_location])

  //fetch
  useEffect(() => {
    let loader = () => {
      setLoading(true)
    }
    let success = () => {
      setLoading(false);
    }
    let fail = () => {
      setLoading(false);
    }
    if (selectedd_location) {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(get_retail_target_kpi(selectedd_location, currentPage, filterMonth + 1, filterYear, filterBrand, loader, success, fail));
      }, 500);
    }
  }, [filterMonth, filterYear, selectedd_location, currentPage, filterBrand])

  useEffect(() => {
    setCurrentPage(1)
    setLoading(true)
    setNoPaginationLoader(true)
  }, [filterMonth, filterYear, selectedd_location, filterBrand])

  useEffect(() => {
    let loader = () => {
      setNoPaginationLoader(true)
    }
    let success = () => {
      setNoPaginationLoader(false);
    }
    let fail = () => {
      setNoPaginationLoader(false);
    }
    if (selectedd_location) {
      dispatch(get_retail_target_kpi_without_pagination(selectedd_location, filterMonth + 1, filterYear, filterBrand, loader, success, fail));
    }
  }, [selectedd_location, filterMonth + 1, filterYear, filterBrand])

  // useEffect(() => {
  //   if (!state.brand.brands_updated) {
  //     dispatch(get_business_brands());
  //   }
  // }, [state?.brand?.brands?.length])

  // useEffect(() => {
  //   dispatch(getBrandDropdownlist());
  // }, [])

  useEffect(() => {
    if (brandSearchText === null) {
      dispatch(getBrandDropdownlistPagination(brandCurrentPage, "", setBrandLoader))
    } else {
      if (brandTimeOutId) {
        clearTimeout(brandTimeOutId)
      }
      brandTimeOutId = setTimeout(() => {
        dispatch(getBrandDropdownlistPagination(brandCurrentPage, brandSearchText, setBrandLoader))
      }, 500);
    }
  }, [brandSearchText, brandCurrentPage])


  useMemo(() => {
    // const val = reports?.map((element) => {
    //   const data = element?.brand_target?.filter(
    //     ({ location }) => location.id === selected_location
    //   );
    //   if (data?.length > 0 && data !== undefined) return { ...element };
    // });
    // const reportsArr = val.filter((element) => element !== undefined);
    // setDataList(reportsArr);
    setDataList(reports)
  }, [reports, reports?.length, selected_location, filterMonth, filterYear])

  const getTotalTarget = (brand) => {
    // filter data by month and calculate total price
    let brandTarget;
    brand?.map(obj => {
      if (obj.month === MONTHS[filterMonth] && parseInt(obj?.year?.split('-', 1)[0]) === filterYear * 1) {
        brandTarget = obj?.brand_target
      }
    })
    return brandTarget;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'filterBrand':
        setFilterBrand(value);
        break;
      case 'filterYear':
        setFilterYear(value);
        break;
      case 'filterMonth':
        setFilterMonth(value);
        break;
      default:
        break;
    }
  }

  const yearDropdown = useMemo(() => {
    if (today_year) {
      let years = [];
      for (let i = 2022; i <= 2040; i++) {
        years.push({ label: i.toString(), value: i.toString() })
      }
      return years;
    }
  }, [today_year])

  const monthDropdown = useMemo(() => {
    return MONTHS?.map((month, i) => {
      return {
        label: month,
        value: i,
      };
    })
  }, [])

  const brandDropdown = useMemo(() => {
    return [{ label: selectedLanguage?.name == "English" ? "All Brand" : translation[398]?.value ? translation[398]?.value : "All Brand", value: "" },
    ...brandRedux?.map((obj) => {
      return {
        label: obj.name,
        value: obj.id
      }
    })]
  }, [brandRedux])

  const reportsFilter = () => {
    // const staffTargetfilterData = dataList?.filter((item) => {
    //   if (filterBrand) {
    //     let return_value = false;
    //     if (filterBrand) {
    //       if (
    //         item?.id
    //           ?.toLowerCase()
    //           .includes(filterBrand?.toLowerCase())
    //       ) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }
    //     return return_value;
    //   }
    //   return true;
    // });
    const staffTargetfilterData = dataList
    return staffTargetfilterData;
  };

  //export csv
  let headers = [
    { label: selectedLanguage?.name == "English" ? 'Brand Name' : translation[428]?.value ? translation[428]?.value : "Brand Name", key: "brandName" },
    { label: selectedLanguage?.name == "English" ? 'Total Brand Target' : translation[396]?.value ? translation[396]?.value : "Total Brand Target", key: "brandTarget" },
    { label: selectedLanguage?.name == "English" ? 'Total Brand Sales' : translation[397]?.value ? translation[397]?.value : "Total Brand Sale", key: "brandSale" },
    { label: selectedLanguage?.name == "English" ? 'Year' : translation[376]?.value ? translation[376]?.value : "Year", key: "year" },
    { label: selectedLanguage?.name == "English" ? 'Month' : translation[377]?.value ? translation[377]?.value : "Month", key: "month" },
  ];

  const csvReport = {
    filename: "Retail Target Report.csv",
    headers: headers,
    data: allReports?.map(({ name, product_sale_price, brand_target }) => {

      return {
        brandName: name ? name : "--------",
        brandTarget: getTotalTarget(brand_target) ? getTotalTarget(brand_target) : 0,
        // brandTarget: product_sale_price ? product_sale_price : "--------",
        brandSale: product_sale_price ? product_sale_price : 0,
        // brandSale  : getTotalTarget(brand_target) ?  getTotalTarget(brand_target) : "--------",
        year: filterYear ? filterYear : "--------",
        month: MONTHS[filterMonth] ? MONTHS[filterMonth] : "--------",
      };
    }),
  };

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allReports?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  return {
    reportStatus, filterMonth, filterYear, filterBrand, yearDropdown, monthDropdown, csvReport, loading,
    brandDropdown, getTotalTarget, reportsFilter, navigate, handleChange, translation, selectedLanguage,
    pages, allCount, currentPage, setCurrentPage, PageSize, allReports, handleExportClick, exportLoader,
    brandSearchText, setBrandSearchText, brandCurrentPage, setBrandCurrentPage, brandLoader, brandPaginationData,
    currencyOfSelectedLocation,
  }
};