import Svgs from "Assets/svgs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CLIENT_DISCOUNT_PROMOTION } from "Redux/TenantRedux/ActionTypes/clientDiscountActionTypes";



export const FixedPriceServiceCard = ({ selected, data, onSelect, onRemoveOffer }) => {
    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    return (
        <>
            <div
                className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? "border-black" : " border-[#B8C6F5]"
                    }  border   rounded-md p-4 mt-1 cursor-pointer`}
                onClick={() => {
                    onSelect && onSelect(data)
                }}
            >
                <div className="flex items-center justify-between gap-1 mb-2">
                    <p className="text-[#A1A1A1] font-bold">
                        {selectedLanguage?.name == "English" ? "Fixed Price Service" : translation[1214]?.value ? translation[1214]?.value : "Fixed Price Service"}

                    </p>

                    {onRemoveOffer && selected &&
                        <button
                            className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                            onClick={onRemoveOffer}
                        >
                            <Svgs.Delete fill="#767A87" />
                        </button>
                    }

                </div>
                <div className="text-black font-semibold mb-1 w-full">{data?.promotion_name}</div>


                <div className="flex items-center justify-between gap-1 mb-3">
                    <span className="font-semibold text-black">AED {data?.spend_amount}</span>
                </div>
                <div className="flex flex-col gap-2 flex-1">
                    {data?.services?.map((service, index) => {
                        return (
                            <div key={index} className="flex items-center justify-between gap-2">
                                <p className="text-[#646464] text-sm">
                                    {selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}
                                </p>
                                <p className="text-[#494949] font-semibold">
                                    {service?.name}
                                </p>
                            </div>
                        );
                    })}
                </div>
                {/* Date duration */}
                <div className="flex items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                        <p className="text-[#A1A1A1] text-sm">
                            {selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                        </p>
                        <p className="text-[#101928] font-semibold">
                            {moment(data?.date_restrictions?.start_date).format('DD-MM-YYYY')}
                        </p>
                    </div>
                    <div className="flex items-center gap-2">
                        <p className="text-[#A1A1A1] text-sm">
                            {selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                        </p>
                        <p className="text-[#101928] font-semibold">
                            {moment(data?.date_restrictions?.end_date).format('DD-MM-YYYY')}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}



const FixedPriceService = ({ setPromotionsData, fixed_price_services, MODULE_TYPE, servicesRedux, onSelect }) => {

    const state = useSelector(state => state)
    const dispatch = useDispatch()


    const handle_select_data_appointment = (data) => {
        let today_date = new Date()
        today_date = `${today_date.getFullYear()}-${today_date.getMonth() + 1}-${today_date.getDate()}`
        console.log(data)

        let services = data.services

        let new_apps_data = []
        let extra_price = data?.spend_amount

        services.forEach(service => {
            let appointment = {
                appointment_date: today_date,
                client_can_book: service.client_can_book,
                duration: service?.duration,
                member: undefined,
                price: 0,
                service: service.id,
                slot_availible_for_online: service.slot_availible_for_online,
                srv_duration: service?.duration,
                srv_name: service?.name
            }
            new_apps_data.push(appointment)
        })

        console.log(new_apps_data)


        onSelect(
            new_apps_data,
            false,
            {
                extra_price: extra_price,
                free_services_quantity: services.length,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
                selected_promotion: data,
            })
    }

    const handle_select_service = (data) => {
        let services = data?.services?.map(service => {
            return {
                ...service,
                selection_type: "SERVICE",
                quantity: 1,
                // price: 0,
                price: data?.spend_amount / data?.services?.length,
                discount_price: undefined,
            }
        })

        dispatch({
            type: "ADD_BULK_ITEMS_TO_CART",
            payload: {
                'services': services,
                cart_total: data?.spend_amount,
                free_services_quantity: services?.length,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
                is_promotion: true,
            }
        });
        toast.success("Items added to cart", { toastId: "toast" });
        onSelect(
            [],
            false,
        )
    }

    const handle_select_data = (data) => {
        let funcs = {
            'APPOINTMENT': () => handle_select_data_appointment(data),
            'SERVICE': () => handle_select_service(data),
        }
        funcs[MODULE_TYPE] && funcs[MODULE_TYPE]()
    }

    useEffect(() => {
        if (fixed_price_services[0]?.services) {
            return
        }

        let bndl_fxd_prc = [
            ...fixed_price_services,
        ]

        let selected_currency_id = state.locations?.locations?.find(obj => obj.id == state.locations.selected_location)?.currency?.id
        let new_bndl_price = bndl_fxd_prc.map(bundle => {
            let services = []
            bundle?.service?.forEach(id => {
                let this_service = servicesRedux?.find(itm => itm.id == id)
                let this_price = this_service?.priceservice?.find(obj => obj.currency == selected_currency_id)
                if (this_service) {
                    services.push({
                        id: id,
                        name: this_service.name,
                        slot_availible_for_online: this_service.slot_availible_for_online,
                        client_can_book: this_service.client_can_book,
                        duration: this_price?.duration ? this_price?.duration?.toLowerCase() : '10min'
                    })
                }
            })
            return {
                ...bundle,
                services: services
            }
        })

        setPromotionsData((prev) => {
            return {
                ...prev,
                fixedPrice: new_bndl_price
            }
        })

        // dispatch({
        //     type: CLIENT_DISCOUNT_PROMOTION.UPDATE_FIXED_PRICE_SERVICES,
        //     payload: new_bndl_price
        // })
    }, [servicesRedux.length])

    return (
        <>
            {
                fixed_price_services?.map((fp_service, index) => {
                    return (
                        <>
                            <FixedPriceServiceCard
                                data={fp_service}
                                key={index}
                                onSelect={handle_select_data}
                            />
                        </>
                    )
                })
            }
        </>
    )
}


FixedPriceService.defaultProps = {
    fixed_price_services: [],
    servicesRedux: [],
    onSelect: () => { }
}


export default FixedPriceService;