/* eslint-disable no-const-assign */
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import {
  createService,
  deletegroupService,
  deletecalender,
  deleteSaleService,
  updateSaleService,
  updateGroupService,
  getServiceGroup,
  getSaleServices,
  getSingleServiceData,
  getSingleServiceGroupDetail,
  getServiceGroupCalendar,
} from "Adapters/Api/saleServices";
import useObjectKeys from "Hooks/useObjectKeys";
import useValidations from "Hooks/useValidations";
import { getAppointments } from "Adapters/Api/apointments";
import { getEmployeeList } from "Adapters/Api/employee/index";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useLocation } from "new-components/location/helper";
import { apiErrorMessage } from "Constants/Data/Errors";
import { discountTypes } from "Redux/TenantRedux/Reducers/clientDiscountPromotionReducer";
import { useRef } from "react";
import { getServiceForCalendar } from "Adapters/Api/services";
// import { getLanguages } from "Redux/TenantRedux/Actions/multiLanguageInvoicesActions";
// import { getAllLanguages } from "Adapters/Api";
// import { getMultiLanguageInvoice } from "Adapters/Api/multiLanguageInvoice";

export const useService = (allowGroupsApiCall) => {
  const formRef = useRef()
  const durationOptions = [
    { value: "30_Min", label: "30 Min" },
    { value: "60_Min", label: "60 Min" },
    { value: "90_Min", label: "90 Min" },
    { value: "120_Min", label: "120 Min" },
    { value: "150_Min", label: "150 Min" },
    { value: "180_Min", label: "180 Min" },
    { value: "210_Min", label: "210 Min" },
  ];
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const { setErrors, validation, errors, onBlurHandler, validateMoblieNumber } =
    useValidations();
  const { objectKeyConvertToArray } = useObjectKeys();
  const dispatch = useDispatch();
  const businessData = getDataFromLocalStorage("business_data");
  const { locationDetail } = useLocation();

  const state = useSelector((state) => state);
  const location_currency = state?.utility?.currency?.location_currency;

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  let services = state?.saleService?.saleServices;
  const [ServiceType, setServiceType] = useState("Service");
  const [availOfferPopup, setAvailOfferPopup] = useState(false);
  const discountPromotionData =
    state?.clientDiscountPromotion?.discountPromotions_updated;
  const discountPromotions = state?.clientDiscountPromotion?.discountPromotions;
  const locationList = state.locations.locations;
  const reduxInvoices = state?.multilanguageInvoices?.invoices

  const [GroupServiceType, setgroupServiceType] = useState("Services Group");
  const [serviceModal, setAddService] = useState(false);
  const [reduxServices, setReduxServices] = useState(services);
  const [loading, setLoading] = useState(false);
  const [fetchServiceLoading, setFetchServiceLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteGroupLoading, setDeleteGroupLoading] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [ServiceGroup, setServiceGroup] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [detailLoading, setDetailLoading] = useState(true)
  const [groupEditLoading, setGroupEditLoading] = useState(true)
  const [loader, setLoader] = useState(true)
  const [serviceData, setService] = useState({
    business: "",
    name: "",
    staffgroup_id: "",
    service_availible: "",
    description: "",
    employee: [],
    location: [],
    invoices: [],
    image: '',
    service: [],
    priceservice: [],
    initial_deposit: "",
    slot_availible_for_online: "Anytime_In_The_Future",
    client_can_book: "Anytime",
    enable_team_comissions: false,
    avail_service: {},
  });
  // const [ toggle,settogle ]=useState(true)

  const [values, setValues] = useState({
    id: "",
    name: "",
    service: [],
    status: true,
    allow_client_to_select_team_member: false,
  });
  const {
    name,
    service_availible,
    description,
    staffgroup_id,
    is_package,
    employee,
    location,
    image,
    initial_deposit,
    // invoices,
    // duration,
    // price,
    // service,
    // enable_team_comissions,
    // enable_vouchers,
    // service_type,
    // priceservice,
    // slot_availible_for_online,
    // client_can_book,
  } = serviceData;

  let allUinqueCurrencyList = [];
  // locationList?.filter((itm) => {
  //   if (!allUinqueCurrencyList.find((dt) => dt?.currency?.id === itm?.currency?.id)) {
  //     allUinqueCurrencyList.push(itm)
  //     return true
  //   }
  // })

  // const groupedLocations = {};
  // locationList?.forEach((itm) => {
  //   const currencyId = itm?.currency?.id;
  //   if (currencyId) {
  //     if (!groupedLocations[currencyId]) {
  //       groupedLocations[currencyId] = [];
  //     }
  //     groupedLocations[currencyId].push({
  //       id: itm.id,
  //       currency: itm.currency,
  //     });
  //   }
  // });
  // allUinqueCurrencyList = Object.values(groupedLocations).flat();

  useEffect(() => {
    setReduxServices(services);
  }, [services, services.length]);

  // ******************* on change handler *******************
  const serviceHandleChange = (e) => {
    const { name, value } = e.target;
    if (value != "no_value") {
      if (e.target.name && e.target.name == "image") {
        setService((prev) => ({ ...prev, 'image': e.target.files[0] }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
        return;
      }
      if (name === "employee" && value != "no_value") {
        if (!serviceData?.employee?.includes(value)) {
          setService((prev) => ({
            ...prev,
            [name]: [...prev.employee, value],
          }));
          setErrors((prev) => ({ ...prev, [name]: "" }));
          return;
        }
      }

      if (name === "service" && value != "no_value") {
        setService((prev) => ({ ...prev, service: [...prev.service, value] }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
        return;
      }

      if (name === "location" && value != "no_value") {
        setService((prev) => ({
          ...prev,
          location: [...prev.location, value],
        }));
        setErrors((prev) => ({ ...prev, [name]: "" }));
        return;
      }
    }

    if (name === "description") {
      setService((prev) => ({ ...prev, description: value }));
      setErrors((prev) => ({ ...prev, description: "" }));
    }

    if (
      name != "employee" &&
      name != "service" &&
      name != "location" &&
      name != "description"
    ) {
      setService((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const removedSelectedEmployee = (value, type) => {
    if (type === "employee") {
      setService({
        ...serviceData,
        employee: [
          ...serviceData.employee.filter((employee_id) => employee_id != value),
        ],
      });
    }

    if (type === "location") {
      const updatedLocation = [
        ...serviceData.location.filter((location_id) => location_id != value),
      ]
      const filteredCurrencyList = locationList?.filter((cur) => updatedLocation?.includes(cur?.id));
      // Filter priceservice array based on currency existence in filteredCurrencyList
      let filteredPriceservice = serviceData?.priceservice?.map(service => {
        // Check if currency exists in filteredCurrencyList
        const selected_currency = filteredCurrencyList?.find(
          (itm) => itm?.currency?.id === service?.currency
        );
        if (selected_currency) {
          return service;
        } else {
          return null
        }
      })?.filter(itm => itm != null);

      setService({
        ...serviceData,
        location: updatedLocation,
        priceservice: filteredPriceservice,
        invoices: []
      });
    }
    if (type === "service") {
      setService({
        ...serviceData,
        service: [
          ...serviceData.service.filter((service_id) => service_id != value),
        ],
      });
    }


    if ((type === "priceservice") && !isEdit) {
      if (serviceData?.priceservice?.find((itm) => itm?.currency == value)) {
        setService((prev) => ({
          ...prev,
          priceservice: [...prev?.priceservice?.filter((itm) => itm?.currency != value)],
        }));
      }
    } else {
      if (serviceData?.priceservice?.find((itm) => itm?.currency == value)) {
        setService((prev) => ({
          ...prev,
          priceservice: [...prev?.priceservice?.map((itm) => {
            if (itm?.currency === value) {
              return {
                ...itm,
                is_deleted: true
              }
            }
            return itm
          })],
        }));
      }
    }
  };

  const removedSelectedServices = (value, type) => {
    if (type === "serivicegroup") {
      setValues({
        ...values,
        service: [
          ...values.service.filter((service_id) => service_id != value),
        ],
      });
    }
  };

  const onSubmitService = () => {
    const selected_location = getDataFromLocalStorage("selected_location");
    let requiredFields;
    if (is_package) {
      requiredFields = { name, initial_deposit }
      if (employee?.length == 0) {
        requiredFields = { ...requiredFields, employee: employee?.length > 0 ? employee : "" }
      }
      if (location.length == 0) {
        requiredFields = { ...requiredFields, location: location.length > 0 ? location : "" }
      }
    } else {
      requiredFields = { name, staffgroup_id, description, service_availible }
      if (employee?.length == 0) {
        requiredFields = { ...requiredFields, employee: employee?.length > 0 ? employee : "" }
      }
      if (location.length == 0) {
        requiredFields = { ...requiredFields, location: location.length > 0 ? location : "" }
      }
    }
    setErrors(validation(requiredFields));
    const result = validation(requiredFields);

    if (objectKeyConvertToArray(result)?.length === 0) {
      if (!image) {
        setErrors((prev) => ({
          ...prev,
          image: selectedLanguage?.name == "English" ? "This field is required" : translation[287]?.value ? translation[287]?.value : "This field is required"
        }))
      } else {
        setLoading(true);
        if (isEdit) {
          dispatch(
            updateSaleService(
              serviceData,
              () => {
                localStorage.setItem('get_sale_service', true)
                setService({
                  name: "",
                  service_type: "",
                  priceservice: [],
                  service_availible: "",
                  description: "",
                  staffgroup_id: "",
                  price: "",
                  duration: "",
                  is_package: false,
                  employee: [],
                  invoices: [],
                  location: [],
                  initial_deposit: "",
                  slot_availible_for_online: "",
                  client_can_book: "",
                  enable_team_comissions: false,
                  enale_vouchers: false,
                });

                setErrors({});
                toast.success("Service updated successfully", { toastId: "toast" });
                dispatch(getEmployeeList());
                setIsEdit(false);
                setLoading(false);
                setAddService(false);
              },
              () => {
                setLoading(false);
                toast.error("Service did not update", { toastId: "toast" });
              }
            )
          );
          dispatch(getSaleServices());
        } else {
          dispatch(
            createService(
              { ...serviceData, business: businessData?.id },
              () => {
                localStorage.setItem('get_sale_service', true)
                setService({
                  name: "",
                  service_type: "",
                  service_availible: "",
                  priceservice: [],
                  description: "",
                  staffgroup_id: "",
                  price: "",
                  duration: "",
                  is_package: false,
                  invoices: [],
                  employee: [],
                  location: [],
                  service: [],
                  // controls_time_slot: "",
                  initial_deposit: "",
                  slot_availible_for_online: "",
                  client_can_book: "",
                  enable_team_comissions: false,
                  enale_vouchers: false,
                });
                setErrors({});
                setLoading(false);
                setAddService(false);
                if (allowGroupsApiCall) {
                  dispatch(getServiceForCalendar(selected_location, setLoader));
                  dispatch(getServiceGroupCalendar(selected_location));
                } else {
                  dispatch(getEmployeeList());
                  dispatch(getServiceGroup());
                }
              },
              () => {
                setLoading(false);

                toast.error("Service did not created", { toastId: "toast" });
              }
            )
          );
        }
      }
    } else {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onDeleteHandler = (id) => {
    if (!isDelete) {
      setIsDelete(true);
      setDeleteId(id);
    }

    if (isDelete && deleteId) {
      dispatch(
        deleteSaleService(
          { id: deleteId },
          () => {
            localStorage.setItem('get_sale_service', true)
            if (!isToastVisible) {
              toast.success("Sale service deleted successfully", {
                toastId: "toast",
                onClose: () => setIsToastVisible(false),
              });
              setIsToastVisible(true);
            }
            setIsDelete(false);
            setDeleteId();
          },
          () => {
            toast.error(apiErrorMessage, { toastId: "toast" });
          }
        )
      );
    }
  };

  const onDeleteGroup = (id) => {
    if (!isDelete) {
      setIsDelete(true);
      setDeleteId(id);
    }

    if (isDelete && deleteId) {
      setDeleteGroupLoading(true)
      dispatch(
        deletegroupService(
          { id: deleteId },
          () => {
            if (!isToastVisible) {
              toast.success("Sale service Group Deleted successfully", {
                toastId: "toast",
                onClose: () => setIsToastVisible(false),
              });
              setIsToastVisible(true);
            }
            localStorage.setItem('get_service_group', true)
            setIsDelete(false);
            setDeleteId();
            setDeleteGroupLoading(false)
          },
          () => {
            toast.error(apiErrorMessage, { toastId: "toast" });
            setDeleteGroupLoading(false)
          }
        )
      );
    }
  };

  const onEditHandler = (id) => {
    setAddService(true);
    setFetchServiceLoading(true)
    // console.log("inside edit");
    dispatch(getSingleServiceData(
      id, setDetailLoading,
      (result) => {
        setIsEdit(true);
        setFetchServiceLoading(false)
        const selectedService = result
        const {
          name,
          service_group,
          image,
          priceservice,
          service_availible,
          description,
          is_package,
          employees,
          invoices,
          location,
          initial_deposit,
          slot_availible_for_online,
          client_can_book,
          enable_team_comissions,
          // service_type,
          // staffgroup_id,
          // price,
          // duration,
          // parrent_service,
          // service,
          // controls_time_slot,
          // enable_vouchers,
        } = selectedService;
        if (is_package) {
          setServiceType("Package");
        } else {
          setServiceType("Service");
        }

        if (selectedService) {
          setService({
            id: id,
            name: name,
            image: image,
            staffgroup_id: service_group?.length > 0 ? service_group[0]?.id : "",
            priceservice: priceservice,
            service_availible: service_availible,
            description: description,
            employee: employees?.map(emp => emp?.employee),
            location: location?.map(lctn => lctn.id),
            invoices: invoices,
            initial_deposit: initial_deposit,
            slot_availible_for_online: slot_availible_for_online,
            client_can_book: client_can_book,
            enable_team_comissions,
            // service_type: service_type,
            // service_id: service_group?.id,
            // staffgroup_id:service_group?.id,
            // service: parrent_service.length > 0 ? parrent_service : [],
            // price: price,
            // duration: duration,
            // is_package: is_package,
            // // controls_time_slot: controls_time_slot,
            // enable_vouchers: enable_vouchers
          });
        }
      },
      () => { }
    ));

  };

  const onDeleteblock = (id) => {
    dispatch(
      deletecalender(
        { id },
        () => {
          dispatch(getAppointments());
          setTimeout(() => {
            toast.success("Block Time Deleted!", { toastId: "toast" });
          }, 1000);
        },
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };

  const onEditGroup = (id) => {
    setServiceGroup(!ServiceGroup)
    dispatch(getSingleServiceGroupDetail(
      id, setGroupEditLoading,
      (result) => {
        const selectedgroupService = result
        const { name, services, image, status, allow_client_to_select_team_member } =
          selectedgroupService;

        if (is_package) {
          setgroupServiceType("Services Group");
        } else {
          setgroupServiceType("Services Group");
        }
        if (selectedgroupService) {
          // Groupservice
          setValues({
            id: id,
            name: name,
            image: image,
            service: services.map((srv) => srv?.id),
            status: status,
            allow_client_to_select_team_member: allow_client_to_select_team_member,
          });
        }
      },
      () => { }
    ));

  };

  //remove item form cart
  const removedFromCart = (item) => {
    dispatch({
      type: "REMOVE_FROM_CART",
      payload: item,
    });
  };

  const addOffer = (obj) => {
    setService((prev) => ({
      ...prev,
      avail_service: obj,
    }));
    switch (obj.type) {
      case discountTypes.directOrFlat:
        let Service = obj.category_discount.find(
          (discount) => discount.category_type === "Service"
        );
        if (Service) {
          let discountServices = reduxServices.map((service) => {
            if (service) {
              return {
                ...service,
                priceservice: service.priceservice.map((priceService) => {
                  if (priceService) {
                    return {
                      ...priceService,
                      discount_price:
                        parseInt(priceService.price) -
                        (parseInt(priceService.price) * Service.discount) / 100,
                    };
                  }
                }),
              };
            }
          });
          if (discountServices) {
            setReduxServices(discountServices);
          }
        }
        break;
      case discountTypes.specificGroup:
        let discountServices = reduxServices.map((service) => {
          let specificGroupDiscount = obj.servicegroup_discount.find(
            (obj) =>
              obj.servicegroup ===
              service.service_group.find((sg) => obj.servicegroup === sg.id)?.id
          )?.discount;
          if (specificGroupDiscount) {
            return {
              ...service,
              priceservice: service.priceservice.map((priceService) => {
                if (priceService) {
                  return {
                    ...priceService,
                    discount_price:
                      parseInt(priceService.price) -
                      (parseInt(priceService.price) * specificGroupDiscount) /
                      100,
                  };
                }
              }),
            };
          } else {
            return { ...service };
          }
        });
        if (discountServices) {
          setReduxServices(discountServices);
        }
        break;
      default:
        break;
    }

    setAvailOfferPopup(false);
  };

  const filterOffer = (id) => {
    setService((prev) => ({
      ...prev,
      avail_service: {},
    }));
    setReduxServices(state?.saleService?.saleServices);
  };


  return {
    GroupServiceType, durationOptions, serviceModal, serviceData, ServiceType, isDelete,
    deleteGroupLoading, loading, errors, isEdit, values, removedSelectedServices, serviceHandleChange,
    setServiceType, setService, setAddService, onSubmitService, onBlurHandler, setErrors, setIsEdit,
    onDeleteHandler, onEditHandler, onEditGroup, setIsDelete, setValues, onDeleteGroup,
    onDeleteblock, removedFromCart, removedSelectedEmployee, setAvailOfferPopup, addOffer,
    filterOffer, reduxServices, location_currency, locationDetail, availOfferPopup,
    discountPromotionData, discountPromotions, ServiceGroup, setServiceGroup, setLoading,
    reduxInvoices, allUinqueCurrencyList, formRef, detailLoading, dispatch, fetchServiceLoading,
    groupEditLoading, locationList
  };
};
