import Svgs from 'Assets/svgs';
import Layout from 'Components/TenantApp/Dashboard/AdminBooking/Layout'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import Popup from 'Components/TenantApp/Elements/Popup';
import RadioBtn from 'Components/TenantApp/Elements/RadioBtn';
import Textarea from 'Components/TenantApp/Elements/Textarea';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import RightBar from './RightBar';
import useProfileAppointment from './helper';
import { useDispatch } from 'react-redux';
import { SALE_SERVICES } from 'Redux/TenantRedux/ActionTypes/saleServiceType';
import { useEffect } from 'react';

const ReviewConfirm = ({ setStep, step }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        selectedProfileService, addedService,
        totalPrice, location, token
    } = useProfileAppointment();


    const [value, setValue] = useState(false);
    const [SelectStaffPopup, setSelectStaffPopup] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [note, setNote] = useState();
    const [confirmStatus, setconfirmStatus] = useState(true);


    useEffect(() => {
        if (selectedProfileService?.is_empoyee_select) {
            setValue(selectedProfileService?.is_empoyee_select)
        }
    }, [])

    return (
        <>
            <Layout
                className='!p-0'
                parent_className='!p-0'
                count={`${step}/4`}
                backClick={() => {
                    setStep(step - 1)
                }}
                heading={'Review and Confirm'}
                NextClick={() => {
                    dispatch({
                        type: SALE_SERVICES.PROFILE_SELECTED_SERIVCE,
                        payload: {
                            ...selectedProfileService,
                            appointment_notes: note ? [note] : [],
                        }
                    })
                    setStep(step + 1);
                    dispatch({
                        type: SALE_SERVICES.PROFILE_SELECTED_SERIVCE,
                        payload: {
                            ...selectedProfileService,
                            is_empoyee_select: true
                        }
                    })

                    // setSelectStaffPopup(true)
                }}
            >
                <div className='md:grid flex flex-col grid-cols-7'>
                    <div className='col-span-5 border-r'>
                        <div className='px-8 pt-4 pb-8'>
                            <div className='flex flex-col gap-4'>
                                <div>
                                    <h1 className='font-semibold text-lg'>Salon</h1>
                                    <p className='text-sm text-[#101928]/60'>Include comments or requests obout your booking</p>
                                </div>
                                <Textarea
                                    title='Note'
                                    placeholder='Enter Note'
                                    name={"note"}
                                    value={note}
                                    onChange={(e) => {
                                        setNote(e?.target?.value)
                                    }}

                                />
                            </div>
                        </div>
                    </div>
                    <RightBar services={addedService} location={location} />
                </div>
            </Layout>
            {/* {
                SelectStaffPopup && <>
                    <Popup size={'md'} open={SelectStaffPopup} close={() => {
                        setSelectStaffPopup(false);
                        // navigate(-1)
                    }} heading='Select Staff'>
                        {
                            confirmStatus ? <div className='flex flex-col gap-4'>
                                <div className='font-semibold'>Do you want to select staff for your services?</div>
                                <div className='flex items-center gap-3'>
                                    <RadioBtn
                                        checked={selectedProfileService?.is_empoyee_select ? true : false}
                                        onClick={() => {
                                            setValue(true)
                                            setIsDisabled(false)
                                            dispatch({
                                                type: SALE_SERVICES.PROFILE_SELECTED_SERIVCE,
                                                payload: {
                                                    ...selectedProfileService,
                                                    is_empoyee_select: true
                                                }
                                            })
                                        }} text='Yes' />
                                    <RadioBtn
                                        checked={!selectedProfileService?.is_empoyee_select ? true : false}
                                        onClick={() => {
                                            setValue(false)
                                            setIsDisabled(false)
                                            dispatch({
                                                type: SALE_SERVICES.PROFILE_SELECTED_SERIVCE,
                                                payload: {
                                                    ...selectedProfileService,
                                                    is_empoyee_select: false
                                                }
                                            })
                                        }} text='No' />
                                </div>

                                <div className='mt-[1rem]'>
                                    <LoginBtn
                                        onClick={() => {
                                            if (value) {

                                                setStep(step + 1);
                                                dispatch({
                                                    type: SALE_SERVICES.PROFILE_SELECTED_SERIVCE,
                                                    payload: {
                                                        ...selectedProfileService,
                                                        is_empoyee_select: true
                                                    }
                                                })
                                                setSelectStaffPopup(false);

                                            } else {

                                                const res = selectedProfileService?.selectedService?.map((dt) => {
                                                    delete dt["member"]
                                                    delete dt["emp_detail"]
                                                    return {
                                                        ...dt
                                                    }
                                                })
                                                setStep(step + 2);
                                                dispatch({
                                                    type: SALE_SERVICES.PROFILE_SELECTED_SERIVCE,
                                                    payload: {
                                                        ...selectedProfileService,
                                                        is_empoyee_select: false,
                                                    }
                                                })
                                                setSelectStaffPopup(false);
                                                // setconfirmStatus(value)
                                            }
                                        }}
                                        text={'Done'}
                                        className='!px-5'

                                    />
                                </div>
                            </div> : <div className='flex flex-col gap-3 justify-center items-center'>
                                <CircleIcon bg='#5679FF' size='7rem'>
                                    <Svgs.Check size='1.5rem' />
                                </CircleIcon>
                                <h1 className='font-semibold text-lg'>Appointment Confirmed</h1>
                            </div>
                        }
                    </Popup>
                </>
            } */}
        </>
    )
}

export default ReviewConfirm