import { VACATION } from "Redux/TenantRedux/ActionTypes/vacationTypes";

const employeeVacations = {
  vacations: [],
  allVacations: [],
  vacation_updated: false,
  pages: 0,
  allCount: 0
};

const VacationReducer = (state = employeeVacations, action) => {
  const { type, payload } = action;

  switch (type) {
    case VACATION.GET_VACATIONS:
      return {
        ...state,
        vacations: payload,
        vacation_updated: true,
      };

    case VACATION.GET_VACATIONS_WITHOUT_PAGINATION:
      return {
        ...state,
        allVacations: payload,
        vacation_updated: true,
      };

    case VACATION.ADD_VACATION:
      return {
        ...state,
        vacations: [payload, ...state.vacations.data],
      };
    case VACATION.GET_VACATION_PAGES:
      return {
        ...state,
        pages: payload?.pages,
        allCount: payload?.count
      };

    case VACATION.UPDATE_VACATION:
      let updateList = state?.vacations?.map((item) => {
        if (item.id === payload.id) {
          return payload;
        } else {
          return item;
        }
      });
      return {
        ...state,
        vacations: updateList,
      };
    case VACATION.DELETE_VACATION:
      let filterArrayAfterDeletion = state?.vacations?.filter((vacation) => {
        if (vacation?.id !== payload) {
          return vacation;
        }
      });
      return {
        ...state,
        vacations: filterArrayAfterDeletion,
      };
    default:
      return state;
  }
};

export default VacationReducer;
