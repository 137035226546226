// import { get_all_segments } from "Adapters/Api/segments"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

const useUnsubcribe = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const [loading, setLoading] = useState(true)
    const [CreateGroup, setCreateGroup] = useState(false);
    const [ViewDetails, setViewDetails] = useState(false);
    const [FilterPopup, setFilterPopup] = useState(false);
    const segment = state?.segments?.segments;


    const getSegments = () => {
        const success = () => { }
        const fail = () => { }

        // dispatch(get_all_segments(success, fail))
    }

    useEffect(() => {
        getSegments()
    }, [])


// Dummy Data For Unsubscribe
const UnsubscribeData = [
    {
      name: "Ahmed Raza",
      type: "Dynamic",
      email: "ahmedraza75@gmail.com",
      is_active: true,
    },
    {
        name: "Ahmed Raza",
        type: "Dynamic",
        email: "ahmedraza75@gmail.com",
        is_active: false,
      },
      {
        name: "Ahmed Raza",
        type: "Dynamic",
        email: "ahmedraza75@gmail.com",
        is_active: true,
      },
  ];

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const tabList = [
        {
            value: `${selectedLanguage?.name == "English" ? "Unsubscribe" : translation[1362]?.value ? translation[1362]?.value : "Unsubscribe"}`,
            label: `${selectedLanguage?.name == "English" ? "Unsubscribe" : translation[1362]?.value ? translation[1362]?.value : "Unsubscribe"}`,
        },
    ];
    const active_tab = tabList[0].value;




    return {
        loading,
        tabList,
        active_tab,
        UnsubscribeData,
        CreateGroup, setCreateGroup,
        ViewDetails, setViewDetails,
        FilterPopup, setFilterPopup,
        translation, selectedLanguage,
    }
}

export default useUnsubcribe