import Svgs from 'Assets/svgs'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import SelectedOption from 'Components/TenantApp/Elements/SelectedOption';
import ToggleWithText from 'Components/TenantApp/Elements/ToggleWithText';
import { WEEKDAYS } from 'Constants/Data/Months';
import ToggleRestrictions from '../../CreateDirectFlatDiscount/AddDiscount/ToggleRestrictions';
import { useAddSpecificBrandDiscount } from './helper';
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb';
import { useMemo } from 'react';
import moment from 'moment';
import FloatInput from 'Components/TenantApp/Elements/FloatInput';

const AddDiscount = () => {
    const { serviceToggle, setServiceToggle, brandToggle, setBrandToggle, productExcludedDropdown, serviceExcludedDropdown, setDiscount,
        addBrandRestriction, addServiceRestriction, errors, toggleDay, toggleStore, discount, loading, locationDropdown, isEdit,
        serviceGroupDropdown, brandDropdown, dateRangeCheck, validateBlockDate, handleChange, onBlurHandler, navigate, handleSpecificDiscount,
        addStoreRestriction, filterStoreRestriction, onSubmit, addBlockDates, filterBlockDate, setToggleDay, setToggleStore, handleDayChecklist,
        toggleblockdate, setToggleBlockDate, translation, selectedLanguage,
    } = useAddSpecificBrandDiscount();
    const {
        specificDiscount,
        dateRestriction,
        blockDate,
        promotion_name,
        blockDates,
        storeRestriction,
        storeRestrictions,

        serviceRestrictions,
        serviceRestriction,

        brandRestrictions,
        brandRestriction,

        dayRestrictions,
    } = discount;

    var minEndDateLimit = useMemo(() => {
        return moment(dateRestriction.startDate).add(1, 'day').format('YYYY-MM-DD')
    }, [dateRestriction.startDate]);

    return (
        <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right' >

            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
                url={'/dashboard/clients/'}
                second={selectedLanguage?.name == "English" ? "Discount & Promotions" : translation[638]?.value ? translation[638]?.value : "Discount & Promotions"}
                secondurl={'/dashboard/clients/discounts-promotions'}
                third={selectedLanguage?.name == "English" ? " Get Discount on Specific Brand and Service Group" : translation[1001]?.value ? translation[1001]?.value : " Get Discount on Specific Brand and Service Group"}
            />
            <div className='flex items-center sm:gap-4 gap-2 my-[1rem]'>

                <div className='flex items-center gap-4 justify-between w-full'>
                    <div className='flex items-center gap-3'>
                        <Svgs.Back link='/dashboard/clients/discounts-promotions' />
                        <h2 className='text-primary font-bold lg:text-3xl text-xl cursor-pointer'>{selectedLanguage?.name == "English" ? "Get Discount on Specific Brand and Service Group" : translation[1001]?.value ? translation[1001]?.value : "Get Discount on Specific Brand and Service Group"}</h2>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 md:w-[40vw]'>
                <div className='border rounded-lg p-3 flex flex-col gap-3'>
                    <LoginInput
                        name="promotion_name"
                        className='!w-fit'
                        title={selectedLanguage?.name == "English" ? "Promotion Name" : translation[883]?.value ? translation[883]?.value : "Promotion Name"}
                        value={promotion_name}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Promotion Name" : translation[1732]?.value ? translation[1732]?.value : "Enter Promotion Name"}
                        type='text'
                        // charactersMaxLength={25}
                        onChange={handleChange}
                        error={errors.promotion_name}
                    />
                    <p className='text-sm'>{selectedLanguage?.name == "English" ? "Add discount for all products of a specific brand and all services of a specific service group." : translation[1002]?.value ? translation[1002]?.value : "Add discount for all products of a specific brand and all services of a specific service group."}</p>
                    <div className='grid lg:grid-cols-2 gap-3'>
                        <div>
                            <Dropwdown
                                required={true}
                                name='brand'
                                title={selectedLanguage?.name == "English" ? "Brand" : translation[836]?.value ? translation[836]?.value : "Brand"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Brand" : translation[984]?.value ? translation[984]?.value : "Select Brand"}
                                options={brandDropdown}
                                value={specificDiscount.brand}
                                onChange={handleSpecificDiscount}
                                onBlur={() => onBlurHandler({ brand: specificDiscount.brand })}
                                error={errors.brand}
                            />
                            {brandDropdown?.length == 0 && (
                                <div
                                    className="flex items-center gap-2 cursor-pointer pt-2"
                                    onClick={() => {
                                        //humayun
                                        navigate('/dashboard/account/inventory-operations/brands')
                                    }}
                                >
                                    <p className="text-sm mt-6">
                                        {selectedLanguage?.name == "English" ? "No brand found. Click" : translation[838]?.value ? translation[838]?.value : "No brand found. Click"}
                                        <span className="underline mx-px">
                                            {selectedLanguage?.name == "English" ? "here" : translation[502]?.value ? translation[502]?.value : "here"}
                                        </span>
                                        {selectedLanguage?.name == "English" ? " to add." : translation[140]?.value ? translation[140]?.value : " to add."}
                                    </p>
                                </div>
                            )}
                        </div>
                        <FloatInput
                            name="brandPercentage"
                            title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[895]?.value ? translation[895]?.value : "Discount Percentage"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Discount Percentage" : translation[1733]?.value ? translation[1733]?.value : "Enter Discount Percentage"}
                            required={true}
                            value={specificDiscount.brandPercentage}
                            isPercentage={true}
                            onChange={(e) => {
                                e.target.value <= 100 &&
                                    handleSpecificDiscount(e)
                            }}
                            onBlur={() =>
                                onBlurHandler({ brandPercentage: specificDiscount.brandPercentage })
                            }
                            error={errors.brandPercentage}
                        />
                        <Dropwdown
                            name="service"
                            required={true}
                            title={selectedLanguage?.name == "English" ? "Service group" : translation[262]?.value ? translation[262]?.value : "Service group"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Service group" : translation[916]?.value ? translation[916]?.value : "Select Service group"}
                            options={serviceGroupDropdown}
                            value={specificDiscount.service}
                            onChange={handleSpecificDiscount}
                            onBlur={() => onBlurHandler({ service: specificDiscount.service })}
                            error={errors.type}
                        />
                        <FloatInput
                            name='servicePercentage'
                            title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[895]?.value ? translation[895]?.value : "Discount Percentage"}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Discount Percentage" : translation[1733]?.value ? translation[1733]?.value : "Enter Discount Percentage"}
                            isPercentage={true}
                            required={true}
                            value={specificDiscount.servicePercentage}
                            onChange={(e) => {
                                e.target.value <= 100 &&
                                    handleSpecificDiscount(e)
                            }}
                            onBlur={() =>
                                onBlurHandler({ servicePercentage: specificDiscount.servicePercentage })
                            }
                            error={errors.servicePercentage}
                        />
                    </div>
                </div>

                <div className='border rounded-lg p-3 flex flex-col gap-7'>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>{selectedLanguage?.name == "English" ? "Date Restrictions" : translation[899]?.value ? translation[899]?.value : "Date Restrictions"}</h1>
                        <div className="grid grid-cols-2 gap-3">
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                name="startDate"
                                value={dateRestriction.startDate}
                                type="date"
                                onChange={handleChange}
                                onBlur={() =>
                                    onBlurHandler({ startDate: dateRestriction.startDate })
                                }
                                error={errors.startDate}
                            />
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                                name="endDate"
                                value={dateRestriction.endDate}
                                type="date"
                                onChange={handleChange}
                                min={minEndDateLimit}
                                onBlur={() =>
                                    onBlurHandler({ endDate: dateRestriction.endDate })
                                }
                                error={errors.endDate}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>
                            {selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
                        </h1>
                        <div className='flex items-end gap-3'>
                            <Dropwdown
                                required={true}
                                name='storeRestriction'
                                placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                                title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                                parentClass='flex-1'
                                options={locationDropdown?.filter(obj => obj.value !== discount?.storeRestrictions?.find(location => location === obj.value))}
                                value={storeRestriction}
                                onChange={handleChange}
                                error={errors?.storeRestriction}
                            />
                            <LoginBtn
                                className={storeRestriction && "shake-bottom"}
                                text={<div className='flex items-center gap-2' > <Svgs.Plus /><p>
                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                </p></div>}
                                onClick={addStoreRestriction}
                                disabled={!storeRestriction}
                            />
                        </div>
                    </div>
                    <div className='flex items-center gap-2 flex-wrap'>
                        {
                            storeRestrictions?.map((obj, index) => (
                                <SelectedOption
                                    rounded={'full'}
                                    name={locationDropdown?.find(location => location?.value === obj)?.label}
                                    onClick={() => {
                                        var filter = storeRestrictions?.filter(obj => obj !== storeRestrictions[index])
                                        setDiscount((prev) => ({
                                            ...prev,
                                            storeRestrictions: filter,
                                        }))
                                    }}
                                />
                            ))
                        }
                    </div>
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={'!text-lg'}
                            title={selectedLanguage?.name == "English" ? "Add Block Date" : translation[902]?.value ? translation[902]?.value : "Add Block Date"}
                            value={toggleblockdate}
                            onChange={() => setToggleBlockDate(!toggleblockdate)}
                            checked={toggleblockdate}
                        />
                        {toggleblockdate &&
                            <>
                                <div className="flex items-end gap-3">
                                    <LoginInput
                                        name="blockDate"
                                        title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                        type="date"
                                        parentClass="flex-1"
                                        required={false}
                                        value={blockDate}
                                        min={dateRestriction.startDate}
                                        max={dateRestriction.endDate}
                                        onChange={handleChange}
                                        error={errors.blockDate}
                                        disabled={
                                            !discount.dateRestriction.startDate ||
                                            !discount.dateRestriction.endDate
                                        }
                                    />
                                    <LoginBtn
                                        className={blockDate && "shake-bottom"}
                                        text={
                                            <div className="flex items-center gap-2">
                                                <Svgs.Plus />
                                                <p>
                                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                                </p>
                                            </div>
                                        }
                                        onClick={addBlockDates}
                                        disabled={
                                            !dateRangeCheck ? true : false ||
                                                !discount.dateRestriction.startDate ||
                                                !discount.dateRestriction.endDate ||
                                                !blockDate ||
                                                validateBlockDate ? true : false
                                        }
                                    />
                                </div>
                                <div className='flex items-center gap-2 flex-wrap'>
                                    {blockDates?.map((obj, index) => (
                                        <>
                                            {obj?.is_deleted === "False" && (
                                                <SelectedOption
                                                    rounded={"full"}
                                                    name={obj?.date}
                                                    onClick={() => filterBlockDate(obj?.id, index)}
                                                />
                                            )}
                                        </>
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                    {/* <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={"!text-lg"}
                            title={selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
                            value={toggleStore}
                            onChange={() => setToggleStore(!toggleStore)}
                            checked={toggleStore}
                        />
                        {toggleStore && (
                            <>
                                <div className="flex items-end gap-3">
                                    <Dropwdown
                                        name="storeRestriction"
                                        placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                                        title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                                        parentClass="flex-1"
                                        options={locationDropdown?.filter(
                                            (obj) =>
                                                obj.value !==
                                                discount?.storeRestrictions?.find(
                                                    (location) => location === obj.value
                                                )
                                        )}
                                        value={storeRestriction}
                                        onChange={handleChange}
                                    />
                                    <LoginBtn
                                        className={storeRestriction && "shake-bottom"}
                                        text={
                                            <div className="flex items-center gap-2">
                                                {" "}
                                                <Svgs.Plus />
                                                <p>
                                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                                </p>
                                            </div>
                                        }
                                        onClick={addStoreRestriction}
                                        disabled={!storeRestriction}
                                    />
                                </div>
                                <div className="flex items-center gap-2 flex-wrap">
                                    {storeRestrictions?.map((obj, index) => (
                                        <SelectedOption
                                            rounded={"full"}
                                            name={
                                                locationDropdown?.find(
                                                    (location) => location?.value === obj
                                                )?.label
                                            }
                                            onClick={() => filterStoreRestriction(index)}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div> */}
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={"!text-lg"}
                            title={selectedLanguage?.name == "English" ? "Day Restrictions" : translation[906]?.value ? translation[906]?.value : "Day Restrictions"}
                            value={toggleDay}
                            onChange={() => setToggleDay(!toggleDay)}
                            checked={toggleDay}
                        />
                        {toggleDay && (
                            <div className="grid grid-cols-2 gap-4">
                                {dayRestrictions.map((item) => {
                                    return (
                                        <CheckBox
                                            name={item.day}
                                            label={item.day}
                                            value={item.value}
                                            onChange={handleDayChecklist}
                                            checked={item.value === item.day ? true : false}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>

                    <div className="flex flex-col gap-4">
                        <ToggleWithText
                            textClass={'!text-lg'}
                            title={selectedLanguage?.name == "English" ? "Excluded Services" : translation[909]?.value ? translation[909]?.value : "Excluded Services"}
                            value={serviceToggle}
                            onChange={() => setServiceToggle(!serviceToggle)}
                            checked={serviceToggle}
                        />
                        <ToggleRestrictions
                            setDiscount={setDiscount}
                            handleChange={handleChange}
                            addRestrictions={addServiceRestriction}
                            toggleRestrictions={serviceToggle}
                            restrictions={serviceRestrictions}
                            restriction={serviceRestriction}
                            translation={translation}
                            selectedLanguage={selectedLanguage}
                            type={"serviceRestriction"}
                            title={selectedLanguage?.name == "English" ? "services that you want to exclude from this offer" : translation[911]?.value ? translation[911]?.value : "services that you want to exclude from this offer"}
                            // dropwdown={serviceExcludedDropdown}
                            dropwdown={serviceExcludedDropdown.filter(itm => itm.value !== serviceRestrictions?.find(ele => ele?.id === itm.value))}
                        />
                    </div>
                    <div className="flex flex-col gap-4">
                        <ToggleWithText
                            textClass={'!text-lg'}
                            title={selectedLanguage?.name == "English" ? "Excluded Products" : translation[989]?.value ? translation[989]?.value : "Excluded Products"}
                            value={brandToggle}
                            onChange={() => setBrandToggle(!brandToggle)}
                            checked={brandToggle}
                        />
                        <ToggleRestrictions
                            setDiscount={setDiscount}
                            handleChange={handleChange}
                            addRestrictions={addBrandRestriction}
                            toggleRestrictions={brandToggle}
                            restrictions={brandRestrictions}
                            restriction={brandRestriction}
                            translation={translation}
                            selectedLanguage={selectedLanguage}
                            type={"brandRestriction"}
                            title={selectedLanguage?.name == "English" ? "products that you want to exclude from this offer" : translation[990]?.value ? translation[990]?.value : "products that you want to exclude from this offer"}
                            // dropwdown={productExcludedDropdown}
                            dropwdown={productExcludedDropdown.filter(itm => itm.value !== brandRestrictions?.find(ele => ele?.id === itm.value))}
                        />
                    </div>

                </div>
                <LoginBtn
                    text={isEdit ?
                        selectedLanguage?.name == "English" ? "Update" : translation[845]?.value ? translation[845]?.value : "Update"
                        :
                        selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
                    loading={loading}
                    disabled={loading}
                    // disabled={
                    //     loading ||
                    //     // discount.serviceGroupDiscount.length === 0 ||
                    //     // discount?.serviceGroupDiscount?.find(
                    //     //   (obj) => !obj?.service_group || !obj?.discount
                    //     // ) ||
                    //     !discount.dateRestriction.endDate ||
                    //     !discount.promotion_name ||
                    //     !discount.dateRestriction.startDate
                    //     || errors.endDate
                    //     // || discount.blockDates.length === 0
                    // }
                    onClick={onSubmit}
                />
            </div>
        </div>
    )
}

export default AddDiscount