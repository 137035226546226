import React, { useEffect } from 'react'
import Svgs from '../../../Assets/svgs'

const FormStepsSignUp = (props) => {
    const dottedLines = props.stepsLength ? (+props.stepsLength - 1) : (+props.steps?.length - 1)
    useEffect(() => {
        if(document.querySelector('.topscroll')){
            document.querySelector('.topscroll').scrollIntoView();
        }
    }, [props.active])

    const stepIcons = [
        <Svgs.BriefCase width={'24px'} height={'24px'}/>
        ,
        <Svgs.Location2 width={'24px'} height={'24px'}/>
        ,
        <Svgs.Profile2 width={'24px'} height={'24px'}/>
    ]
    
    return (
        <div className={`flex items-center justify-between overflow-x-hidden w-full ${props.className}`}>
            {
                props.steps.map((step, i) => {
                    return <>
                        <div key={i} onClick={
                            () => {
                                if (props?.currentStep != props?.disabledStep)
                                    props?.currentStep > i + 1 && props?.setStep(i + 1);
                            }
                        }
                            className={`${props.className} flex flex-col items-center text-center gap-2 w-fit`}>
                            <div className='flex flex-col items-center text-center gap-2 w-fit'>
                                <div className={`h-[30px] w-[30px] p-2 md:p-3 md:h-[50px] md:w-[50px] rounded-full border flex items-center justify-center font-semibold ${props.active == (i + 1) ? 'bg-primary text-white border-primary' : props.active > (i + 1) ? 'border-[#49B769] bg-[#49B769]' : 'text-[#7A88A1] border-[#E1E4ED] bg-[#E1E4ED]'}`}>
                                    {
                                        props.active > (i + 1) ? <div className='mt-[2px] cursor-pointer'><Svgs.Check /></div> : stepIcons[i]
                                    }
                                </div>
                                <h2 className={` whitespace-nowrap  text-center text-sm ${props.active == (i + 1) ? 'text-primary cursor-pointer' : props.active > (i + 1) ? 'text-[#000] cursor-pointer' : 'text-[#A1A1A1]'}`}
                                    // onClick={
                                    //     () => {
                                    // if (props && props.currentStep != props.steps.length) {
                                    //         props?.currentStep > i + 1 && props?.setStep(i + 1);
                                    // }
                                    //     }
                                    // }
                                >
                                    {step}
                                </h2>
                            </div>
                        </div>
                            {
                                (i != +dottedLines) && <div className='h-[2px] w-[100%] pb-5 border-t border-[#E1E4ED]'></div>
                            }
                    </>
                })
            }
        </div>
    )
}

export default FormStepsSignUp;