import axios from "axios";

import {
  BASE_URL,
  get_consumptions_url,
  add_consumptions_url,
  update_product_consumptions,
  delete_product_consumptions,
} from "../api_variables";
import { getConsumptionWithNoPagination } from "Redux/TenantRedux/Actions/consumption";

export const getConsumptions = async (selected_location, currentPage, searchText, access_token, setGetLoader) => {
  setGetLoader && setGetLoader(true)
  const location = selected_location ? `?location_id=${selected_location}` : ''
  const current_Page = currentPage ? `&page=${currentPage}` : '';
  const search = searchText ? `&search_text=${searchText}` : ''
  try {
    const response = await axios.get(`${BASE_URL}${get_consumptions_url}${location}${current_Page}${search}`, {
      headers: {
        Authorization: `Token ${access_token}`,
      },
    });
    setGetLoader && setGetLoader(false)
    return response;
  } catch (error) {
    setGetLoader && setGetLoader(false)
    return error;
  }
};

export const getConsumptionsWithNoPagination = async (selected_location, searchText, access_token, setNoPaginationLoader) => {
  setNoPaginationLoader && setNoPaginationLoader(true)
  const location = selected_location ? `?location_id=${selected_location}` : ''
  const search = searchText ? `&search_text=${searchText}` : ''
  const noPagination = `&no_pagination=true`
  try {
    const response = await axios.get(`${BASE_URL}${get_consumptions_url}${location}${noPagination}${search}`, {
      headers: {
        Authorization: `Token ${access_token}`,
      },
    });
    setNoPaginationLoader && setNoPaginationLoader(false)
    return response;
  } catch (error) {
    setNoPaginationLoader && setNoPaginationLoader(false)
    return error;
  }
};

export const addConsumptions = async (consumption_data, access_token) => {
  try {
    const result = await axios.post(
      `${BASE_URL}${add_consumptions_url}`,
      consumption_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const editConsumption = async (consumption_data, access_token) => {
  try {
    const result = await axios.put(
      `${BASE_URL}${update_product_consumptions}`,
      consumption_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const deleteConsumption = async (id, access_token) => {
  try {
    let data = {
      consumption_id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const result = await axios.delete(
      `${BASE_URL}${delete_product_consumptions}`,
      headers
    );
    return result;
  } catch (error) {
    return error;
  }
};
