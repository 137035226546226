import Svgs from 'Assets/svgs'
import React from 'react'

const Weekend = ({onClick}) => {
    return (
        <div className={`schedule-grid-item bg-[#D56905]/10 flex justify-center items-center flex-col gap-1 hover-parent`}>
            <div onClick={onClick} className='h-[2rem] w-[2rem] border-2 border-[#5679FF] rounded-full items-center justify-center cursor-pointer hover-child hidden'>
                <Svgs.Plus color="#5679FF" />
            </div>
            <p className='font-medium text-[#D56905]'>Weekend</p>
        </div>
    )
}

export default Weekend