import React from 'react';
import LoginBtn from '../../LoginBtn';
import PasswordInput from '../../PasswordInput';
import useNewPasswordForm from './useNewPasswordForm';

const ForgetScreen3 = (props) => {
    const { newPassword, onBlurHandler, handleChange, onSubmitHandler, errors, loading, active, passwordlengthValidation } = useNewPasswordForm();
    const { password, confirmPassword } = newPassword;

    return (
        <div className={`flex flex-1 flex-col gap-5 justify-center md:py-[3rem] py-[1.75rem] lg:w-[75%] md:mx-0 mx-auto w-full lg:py-0 slide-in-bottom `}>
            <h1 className="text-3xl font-light text-[#7B7B7B] lg:text-left text-center">
                Reset <span className="font-semibold text-black">Password</span>
            </h1>
            <p className="text-[#7B7B7B] lg:text-left text-center text-sm">
                Enter your new password
            </p>
            <PasswordInput
                name="password"
                onBlur={() => onBlurHandler({ password: password })}
                onChange={handleChange} value={password}
                error={(passwordlengthValidation && password?.length < 6) ? "Password length must be greater or equal to 6 digits." : errors?.password}
                title='New Password'
                placeholder='Enter 6 digit password'
            />
            <PasswordInput
                name="confirmPassword"
                onBlur={() => onBlurHandler({ confirmPassword: confirmPassword })}
                onChange={handleChange} value={confirmPassword}
                error={(passwordlengthValidation && confirmPassword?.length < 6) ? "Confirm password length must be greater or equal to 6 digits." : errors?.confirmPassword}
                title='Confirm Password'
                placeholder='Confirm password'
            />
            <div className="flex lg:justify-start justify-center">
                <LoginBtn
                    loading={loading}
                    // disabled={(!active || password?.length < 6 || confirmPassword?.length < 6) ? true : false}
                    // className={active != false ? "" : "opacity-40"}
                    text="Confirm"
                    onClick={() => {
                        onSubmitHandler({ sent_to: props.sent_to, method_type: props.method_type });
                    }}
                >
                    <svg
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 8H17M10 1L17 8L10 15"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </LoginBtn>
            </div>
        </div>
    )
}

export default ForgetScreen3