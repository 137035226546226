import React from 'react'
import Svgs from '../../../Assets/svgs'


import TooltipLite from 'react-tooltip-lite';


const I_Info_Icon = ({ text, info }) => {
    return (
        <>
            <div className='flex items-center gap-1'>
                {text}
                <a data-tip={info} className='cursor-pointer'></a>
                <TooltipLite zIndex={'123213213'} content={info} tagName="div">
                    <Svgs.I className='cursor-pointer' />
                </TooltipLite>
            </div>
            {/* <ReactTooltip className='custom-tooltip-shadow' place="right" type="dark" effect="solid" textColor='#101928' backgroundColor='#FBFCFF' /> */}
            {/* <div className='flex items-center gap-2'>
                <p>{props.text}</p>
                <Svgs.I className='tooltip_opener cursor-pointer' />
                <div className='relative tooltip_'>
                    <div className={`custom-tooltip ${props.top && 'top'}`}>
                        <div className='custom-tooltip-arrow'></div>
                        <div className='overflow-hidden'>
                            <div className='w-[20rem] custom-tooltip-inner overflow-y-auto whitespace-pre-line max-h-[10rem] text-xs text-primary leading-[1.8]'>
                                <p>{props.info}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>

    )
}

I_Info_Icon.defaultProps = {
    text: 'Enter "Text" Prop',
    info: 'Enter "Info" Prop',
}

export default I_Info_Icon