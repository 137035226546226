import { delete_Segment, get_all_segments } from "Adapters/Api/segments"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

const initialFilter = {
    segment_type: "",
    is_active: "",
}
let segmentTimeout = undefined;

const useSegments = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const [loading, setLoading] = useState(true)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [CreateS, setCreateS] = useState(false);
    const [FilterSection, setFilterSection] = useState(false);
    const [ViewDetails, setViewDetails] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [isEdit, setIsEdit] = useState(false)
    const [segmentDetail, setSegmentDetail] = useState({})
    const [FilterPopup, setFilterPopup] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [searchSegment, setSearchSegment] = useState('')
    const [filterData, setFilterData] = useState(initialFilter)
    const [apiCall, setApiCall] = useState('')

    const segment = state?.segments?.segments;
    const segmentPagination = state?.segments?.segmentPagination;

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    const access_token = getDataFromLocalStorage('access_token')

    const getSegmentDetail = (data, type) => {
        setSegmentDetail(data)
        if (type == "detail") {
            setViewDetails(true);
        }
        if (type == "edit") {
            if (data?.segment_type == 'dynamic') {
                setIsEdit(true)
                setCreateS(true)
            } else {
                toast.info("You can not update the static segment", { toastId: 'edit' })
            }
        }
        if (type == "delete") {
            setDeletePopup(true);
        }
    }

    const getSegments = (apply) => {
        setLoading(true)
        const payload = {
            page: currentPage,
        };
        if (searchSegment) {
            payload.search_text = searchSegment
        }
        if (apply) {
            payload.segment_type = filterData?.segment_type;
            payload.is_active = filterData?.is_active;
        }

        const success = () => { setLoading(false) }
        const fail = () => { setLoading(false) }

        dispatch(get_all_segments(access_token, payload, success, fail))
    }

    const onFilterChange = (e) => {
        const { name, value } = e.target
        setFilterData((prev) => ({ ...prev, [name]: value }));
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [searchSegment])

    useEffect(() => {
        if (searchSegment === null) {
            getSegments()
        } else {
            if (segmentTimeout) {
                clearTimeout(segmentTimeout)
            }
            segmentTimeout = setTimeout(() => {
                getSegments()
            }, 500);
        }
    }, [searchSegment, currentPage, apiCall])

    const deleteSegment = (id) => {
        setDeleteLoading(true)
        const success = () => {
            setApiCall(id)
            setDeletePopup(false)
            setDeleteLoading(false)
        }
        const fail = () => {
            setDeletePopup(false)
            setDeleteLoading(false)
        }

        dispatch(delete_Segment(access_token, id, success, fail))
    }

    const clearFilter = () => {
        setCurrentPage(1)
        getSegments()
        setFilterData(initialFilter)
        setFilterPopup(false)
    }
    const onSubmit = () => {
        getSegments("filter")
        setFilterPopup(false)
    }

    return {
        loading, getSegmentDetail,
        CreateS, setCreateS,
        FilterPopup, setFilterPopup,
        FilterSection, setFilterSection,
        ViewDetails, setViewDetails,
        deleteSegment, setIsEdit,
        segmentPagination, setApiCall,
        deleteLoading, currentPage, setCurrentPage,
        segment, segmentDetail, deletePopup, isEdit, setDeletePopup,
        searchSegment, setSearchSegment, clearFilter,
        onFilterChange, selectedLanguage, translation, filterData, onSubmit,
    }
}

export default useSegments