import axios from "axios";
import { BASE_URL, get_client_available_loyalty_points, get_customers_loyalty_points_logs, get_customer_detailed_loyalty_points_logs, get_customer_detailed_loyalty_points_logs_detail } from "../api_variables";
import { SALE_ORDER } from "Redux/TenantRedux/ActionTypes/salleOrderTypes";
// import { toast } from 'react-toastify';
// import { CLIENT } from "Redux/TenantRedux/ActionTypes/clientActionTypes";
// import { apiErrorMessage } from "Constants/Data/Errors";

export const getCustomerLoyaltyPointsLogs = (data, success, fail) => async (dispatch) => {
    try {
        const client = data?.client_Id ? `&customer_id=${data.client_Id}` : ""
        const dateFilter = (data?.startDate && data?.endDate) ? `&start_date=${data?.startDate}&end_date=${data?.endDate}` : ""
        const page = data?.currentPage ? `&page=${data?.currentPage}` : ''

        const result = await axios.get(`${BASE_URL}${get_customers_loyalty_points_logs}?location_id=${data?.location_id}${client}${dateFilter}${page}`);
        if (result?.status === 200) {
            success && success(result?.data)
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};


export const getCustomerLoyaltyPointsLogsNoPagination = (data, success, fail) => async (dispatch) => {
    try {
        const client = data?.client_Id ? `&customer_id=${data.client_Id}` : ""
        const dateFilter = (data?.startDate && data?.endDate) ? `&start_date=${data?.startDate}&end_date=${data?.endDate}` : ""
        const result = await axios.get(`${BASE_URL}${get_customers_loyalty_points_logs}?location_id=${data?.location_id}${client}${dateFilter}&no_pagination=true`);
        if (result?.status === 200) {
            success && success(result?.data?.response)
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

export const getCustomerDetailedLoyaltyPointsLogs = (data, success, fail) => async (dispatch) => {
    try {
        const client = data?.client_Id ? `&customer_id=${data.client_Id}` : ""
        const dateFilter = (data?.startDate && data?.endDate) ? `&start_date=${data?.startDate}&end_date=${data?.endDate}` : ""
        const page = data?.currentPage ? `&page=${data?.currentPage}` : ''

        const result = await axios.get(`${BASE_URL}${get_customer_detailed_loyalty_points_logs}?location_id=${data?.location_id}${client}${dateFilter}${page}`);
        if (result?.status === 200) {
            success && success(result?.data?.response)
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

export const getCustomerDetailedLoyaltyPointsLogsNoPagination = (data, success, fail) => async (dispatch) => {
    try {
        const client = data?.client_Id ? `&customer_id=${data.client_Id}` : ""
        const dateFilter = (data?.startDate && data?.endDate) ? `&start_date=${data?.startDate}&end_date=${data?.endDate}` : ""
        const result = await axios.get(`${BASE_URL}${get_customer_detailed_loyalty_points_logs}?location_id=${data?.location_id}${client}${dateFilter}&no_pagination=true`);
        if (result?.status === 200) {
            success && success(result?.data?.response)
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

export const getCustomerDetailedLoyaltyPointsLogsInvoiceDetail = (id, location, setDetailloading, success, fail) => async (dispatch) => {
    setDetailloading && setDetailloading(true)
    try {
        const result = await axios.get(`${BASE_URL}${get_customer_detailed_loyalty_points_logs_detail}?location_id=${location}&loyalty_logs_id=${id}`);
        if (result?.status === 200) {
            setDetailloading && setDetailloading(false)
            dispatch({
                type: SALE_ORDER.GET_INVOICE_TITLES,
                payload: {
                    data: result?.data?.response?.invoice_translations
                }
            })
            success && success(result?.data?.response?.data[0]?.checkout_data[0])
        } else {
            setDetailloading && setDetailloading(false)
            fail && fail(result)
        }
        return result;
    } catch (error) {
        setDetailloading && setDetailloading(false)
        fail && fail(error)
        return error;
    }
};

// export const getClientLoyaltyPointsLogs = (data, success, fail) => async (dispatch) => {
//     try {
//         // const client = data?.client_Id ? `&customer_id=${data.client_Id}` : ""
//         const result = await axios.get(`${BASE_URL}${get_client_available_loyalty_points}?location_id=${data?.location_id}&client_id=${data.client_Id}`);
//         if (result?.status === 200) {
//             success && success(result?.data?.response)
//         } else {
//             fail && fail(result)
//         }
//         return result;
//     } catch (error) {
//         fail && fail(error)
//         return error;
//     }
// };
