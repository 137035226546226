import React, { useState, useEffect } from "react";
import Svgs from "../../../../Assets/svgs";
import AccountSetupCard from "../../Dashboard/AccountSetupCard";
import { useSelector } from "react-redux";

const DashboardClients = () => {
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
      <div className="flex items-center gap-5 lg:w-fit w-full lg:px-0 lg:pb-[2rem] lg:py-0 p-3 pb-8">

        <h2 className="font-semibold text-2xl">
          {selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
        </h2>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem] xl:pr-[4rem] pr-0">
        {!employee ||
          (employee && (employeePermissions?.client_profile?.includes("view")
            || employeePermissions?.client_profile?.includes("create")
            || employeePermissions?.client_profile?.includes("edit")
            || employeePermissions?.client_root_access?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view"))) ? (
          <AccountSetupCard
            link="/dashboard/clients/client-profiles"
            icon={<Svgs.Dashboard_Employee />}
            title={selectedLanguage?.name == "English" ? "Client Profiles" : translation[437]?.value ? translation[437]?.value : "Client Profiles"}
            description={selectedLanguage?.name == "English" ? "Manage clients list. Add edit and delete clients." : translation[439]?.value ? translation[439]?.value : "Manage clients list. Add edit and delete clients."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && (employeePermissions?.client_groups?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.client_groups?.includes("create")
            || employeePermissions?.client_groups?.includes("edit")
            || employeePermissions?.client_root_access?.includes("view"))) ? (
          <AccountSetupCard
            link="/dashboard/clients/client-group"
            icon={<Svgs.Client_Groups />}
            title={selectedLanguage?.name == "English" ? "Client Groups" : translation[440]?.value ? translation[440]?.value : "Client Groups"}
            description={selectedLanguage?.name == "English" ? "Manage client groups. Add edit and delete client groups." : translation[443]?.value ? translation[443]?.value : "Manage client groups. Add edit and delete client groups."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && (employeePermissions?.client_discount?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.client_discount?.includes("create")
            || employeePermissions?.client_discount?.includes("edit")
            || employeePermissions?.client_root_access?.includes("view"))) ? (
          <AccountSetupCard
            link="/dashboard/clients/discounts-promotions"
            icon={<Svgs.Client_Groups />}
            title={selectedLanguage?.name == "English" ? "Discounts & Promotions" : translation[445]?.value ? translation[445]?.value : "Discounts & Promotions"}
            description={selectedLanguage?.name == "English" ? "Manage discount and promotions. Set discount for your clients." : translation[446]?.value ? translation[446]?.value : "Manage discount and promotions. Set discount for your clients."}
          />
        ) : (
          ""
        )}

        {/* {!employee ||
          (employee && (employeePermissions?.client_reward?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.client_reward?.includes("create")
            || employeePermissions?.client_reward?.includes("edit")
            || employeePermissions?.client_root_access?.includes("view"))) ? (
          <AccountSetupCard
            link="/dashboard/clients/rewards-promotions"
            icon={<Svgs.Rewards_dashboard />}
            title="Rewards / Promotions"
            description="Manage rewards and promotions. Set reward criteria for your clients."
          />
        ) : (
          ""
        )} */}

        {/* <AccountSetupCard link='/dashboard/clients/subscription-program' icon={<Svgs.Client_Programs />}
                    title='Subscription Programs'
                    description='Manage subscription. Add edit and delete subscriptions.' /> */}
        {/* <AccountSetupCard link='/dashboard/clients/reports' icon={<Svgs.Dashboard_Reports
                />} title='Reports'
                    description='Manage rewards/promotions reports. Add edit and delete reports.' /> */}

        {!employee ||
          (employee && (employeePermissions?.client_loyality?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.client_loyality?.includes("create")
            || employeePermissions?.client_loyality?.includes("edit")
            || employeePermissions?.client_root_access?.includes("view"))) ? (
          <AccountSetupCard
            link="/dashboard/clients/loyalty-points"
            icon={<Svgs.Client_Sharing />}
            title={selectedLanguage?.name == "English" ? "Loyalty Points" : translation[447]?.value ? translation[447]?.value : "Loyalty Points"}
            description={selectedLanguage?.name == "English" ? "Manage loyalty points for clients." : translation[449]?.value ? translation[449]?.value : "Manage loyalty points for clients."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && (employeePermissions?.client_sharing?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.client_sharing?.includes("create")
            || employeePermissions?.client_sharing?.includes("edit")
            || employeePermissions?.client_root_access?.includes("view"))) ? (
          <AccountSetupCard
            link="/dashboard/clients/sharing-settings/membership"
            icon={<Svgs.SharingSettings />}
            title={selectedLanguage?.name == "English" ? "Sharing Settings" : translation[451]?.value ? translation[451]?.value : "Sharing Settings"}
            description={selectedLanguage?.name == "English" ? "Manage sharing settings. Voucher and membership for clients." : translation[453]?.value ? translation[453]?.value : "Manage sharing settings. Voucher and membership for clients."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && (employeePermissions?.client_sharing?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.client_sharing?.includes("create")
            || employeePermissions?.client_sharing?.includes("edit")
            || employeePermissions?.client_root_access?.includes("view"))) ? (
          <AccountSetupCard
            link="/dashboard/clients/loyalty-logs"
            icon={<Svgs.LoyaltyLogs />}
            title={selectedLanguage?.name == "English" ? "Loyalty Logs" : translation[455]?.value ? translation[455]?.value : "Loyalty Logs"}
            description={selectedLanguage?.name == "English" ? "View your all loyalty logs." : translation[456]?.value ? translation[456]?.value : "View your all loyalty logs."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && (employeePermissions?.client_sharing?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.client_sharing?.includes("create")
            || employeePermissions?.client_sharing?.includes("edit")
            || employeePermissions?.client_root_access?.includes("view"))) ? (
          <AccountSetupCard
            link="/dashboard/clients/cash-back"
            icon={<Svgs.CashBack />}
            title={selectedLanguage?.name == "English" ? "Cash back" : translation[455]?.value ? translation[455]?.value : "Cash back"}
            description={selectedLanguage?.name == "English" ? "View your all loyalty logs." : translation[456]?.value ? translation[456]?.value : "View your all loyalty logs."}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default DashboardClients;
