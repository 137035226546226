
import axios from "axios";
import { tenantDetail } from "../../../Hooks/useTenant";
import { VENDORS } from "../../../Redux/TenantRedux/ActionTypes/vendorsTypes";
import { get_vendors, add_vendor, delete_vendor, update_vendor, get_vendor_import_url, check_vendor_existance, get_vendors_dropdown } from './../api_variables';

const { is_tenant, tenant } = tenantDetail();

const tenant_base_url = `${process.env.REACT_APP_SERVER_BASEURL_START}${tenant}.${process.env.REACT_APP_SERVER_BASEURL_END}`;
const nstyle_base_url = `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}`;

let BASE_URL = is_tenant ? tenant_base_url : nstyle_base_url;

export const getVendorsList = (currentPage, search_text, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const current_Page = currentPage ? `?page=${currentPage}` : '';
  const search = search_text ? `&search_text=${search_text}` : ''
  try {
    const result = await axios.get(`${BASE_URL}${get_vendors}${current_Page}${search}`);
    if (dispatch) {
      dispatch(
        {
          type: VENDORS.GET_VENDORS,
          payload: result.data
        }
      )
      setLoader && setLoader(false)
    }
    return result;
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getVendorsDropdownList = (setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  try {
    const result = await axios.get(`${BASE_URL}${get_vendors_dropdown}`);
    if (dispatch) {
      dispatch(
        {
          type: VENDORS.GET_VENDORS_DROPDOWNLIST,
          payload: result.data
        }
      )
      setLoader && setLoader(false)
    }
    return result;
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getVendorsListWithNoPagination = (search_text, setNoPaginationLoader) => async (dispatch) => {
  setNoPaginationLoader && setNoPaginationLoader(true)
  const search = search_text ? `&search_text=${search_text}` : ''
  try {
    const result = await axios.get(`${BASE_URL}${get_vendors}?no_pagination=true${search}`);
    if (dispatch) {
      dispatch({
        type: VENDORS.GET_VENDORS_WITH_NO_PAGINATION,
        payload: result.data?.response?.vendors
      })
      setNoPaginationLoader && setNoPaginationLoader(false)
    }
    setNoPaginationLoader && setNoPaginationLoader(false)
    return result;
  } catch (error) {
    setNoPaginationLoader && setNoPaginationLoader(false)
    return error;
  }
};



export const addVendors = async (vendor_data, access_token) => {
  try {
    const result = await axios.post(`${BASE_URL}${add_vendor}`, vendor_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const checkVendorExistance = (vendorData, success, fail) => async dispatch => {
  try {
    const result = await axios.post(
      `${BASE_URL}${check_vendor_existance}`, vendorData
    );
    if (result?.data?.status_code == 200) {
      success && success(result)
    }
  } catch (error) {
    fail && fail(error?.response)
  }
};


export const deleteVendor = async (vendor_id, access_token) => {

  try {

    let result = await fetch(`${BASE_URL}${delete_vendor}`, {
      method: "DELETE",
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Token ${access_token}`
      },
      body: JSON.stringify(
        { vendor: vendor_id }
      )
    })
    result = result.json();
    return result;

  } catch (error) {
    return error;
  }

}

export const getVendorsImport = (form, success) => dispatch => {
  let token = localStorage.getItem('access_token')
  if (token) {
    token = token.replaceAll('"', '')
  }
  fetch(`${BASE_URL}${get_vendor_import_url}`,
    {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`
      },
      body: form
    }).then((response => {
      return (
        response.json()
      )
    })).then((result) => {
      dispatch({
        type: VENDORS.ADD_IMPORTED_VENDORS_TO_LIST,
        payload: result?.response?.vendors
      })
      success && success()
    })

};


export const updateVendor = async (update_vendor_data, access_token) => {
  try {
    const result = await axios.put(`${BASE_URL}${update_vendor}`, update_vendor_data,
      {
        headers: { 'Authorization': `Token ${access_token}` },
      });
    return result;
  } catch (error) {
    return error;
  }
}