import axios from "axios";
import { tenantDetail } from "../../../Hooks/useTenant";

const { is_tenant, tenant } = tenantDetail();

const tenant_base_url = `${process.env.REACT_APP_SERVER_BASEURL_START}${tenant}.${process.env.REACT_APP_SERVER_BASEURL_END}`;
const tenant_base_url_without_tenant = `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}`;
const nstyle_base_url = `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}`;

let BASE_URL = is_tenant ? tenant_base_url : nstyle_base_url;

export const getBusinessTheme = async (business_id) => {
    try {
        const result = await axios.get(`${BASE_URL}/business/get_business_theme/?business=${business_id}`);
        return result;
    } catch (error) {
        return error;
    }
}


export const updateThemeCustomizationColor = async (update_theme, access_token) => {
    try {
        const result = await axios.put(`${BASE_URL}/business/update_business_theme/`, update_theme,
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });
        return result;
    } catch (error) {
        return error;
    }
}
