

export const DIAL_CODES = [
    { Code: 'AF', MobileCode: '+93' },
    { Code: 'AL', MobileCode: '+355' },
    { Code: 'DZ', MobileCode: '+213' },
    { Code: 'AD', MobileCode: '+376' },
    { Code: 'AO', MobileCode: '+244' },
    { Code: 'AR', MobileCode: '+54' },
    { Code: 'AM', MobileCode: '+374' },
    { Code: 'AW', MobileCode: '+297' },
    { Code: 'AU', MobileCode: '+61' },
    { Code: 'AT', MobileCode: '+43' },
    { Code: 'AZ', MobileCode: '+994' },
    { Code: 'BH', MobileCode: '+973' },
    { Code: 'BD', MobileCode: '+880' },
    { Code: 'BY', MobileCode: '+375' },
    { Code: 'BE', MobileCode: '+32' },
    { Code: 'BZ', MobileCode: '+501' },
    { Code: 'BJ', MobileCode: '+229' },
    { Code: 'BT', MobileCode: '+975' },
    { Code: 'VE', MobileCode: '+58' },
    { Code: 'BO', MobileCode: '+591' },
    { Code: 'BQ', MobileCode: '+599' },
    { Code: 'BA', MobileCode: '+387' },
    { Code: 'BW', MobileCode: '+267' },
    { Code: 'BR', MobileCode: '+55' },
    { Code: 'IO', MobileCode: '+246' },
    { Code: 'BN', MobileCode: '+673' },
    { Code: 'BG', MobileCode: '+359' },
    { Code: 'BF', MobileCode: '+226' },
    { Code: 'BI', MobileCode: '+257' },
    { Code: 'CV', MobileCode: '+238' },
    { Code: 'KH', MobileCode: '+855' },
    { Code: 'CM', MobileCode: '+237' },
    { Code: 'CA', MobileCode: '+1' },
    { Code: 'CF', MobileCode: '+236' },
    { Code: 'TD', MobileCode: '+235' },
    { Code: 'CL', MobileCode: '+56' },
    { Code: 'CN', MobileCode: '+86' },
    { Code: 'CX', MobileCode: '+61' },
    { Code: 'CC', MobileCode: '+61' },
    { Code: 'CO', MobileCode: '+57' },
    { Code: 'KM', MobileCode: '+269' },
    { Code: 'CG', MobileCode: '+242' },
    { Code: 'CD', MobileCode: '+243' },
    { Code: 'CK', MobileCode: '+682' },
    { Code: 'CR', MobileCode: '+506' },
    { Code: 'CI', MobileCode: '+225' },
    { Code: 'HR', MobileCode: '+385' },
    { Code: 'CU', MobileCode: '+53' },
    { Code: 'CW', MobileCode: '+599' },
    { Code: 'CY', MobileCode: '+357' },
    { Code: 'CZ', MobileCode: '+420' },
    { Code: 'TL', MobileCode: '+670' },
    { Code: 'DK', MobileCode: '+45' },
    { Code: 'DJ', MobileCode: '+253' },
    { Code: 'EC', MobileCode: '+593' },
    { Code: 'EG', MobileCode: '+20' },
    { Code: 'SV', MobileCode: '+503' },
    { Code: 'GQ', MobileCode: '+240' },
    { Code: 'ER', MobileCode: '+291' },
    { Code: 'EE', MobileCode: '+372' },
    { Code: 'ET', MobileCode: '+251' },
    { Code: 'FK', MobileCode: '+500' },
    { Code: 'FO', MobileCode: '+298' },
    { Code: 'FJ', MobileCode: '+679' },
    { Code: 'FI', MobileCode: '+358' },
    { Code: 'FR', MobileCode: '+33' },
    { Code: 'GF', MobileCode: '+594' },
    { Code: 'PF', MobileCode: '+689' },
    { Code: 'GA', MobileCode: '+241' },
    { Code: 'GM', MobileCode: '+220' },
    { Code: 'GE', MobileCode: '+995' },
    { Code: 'DE', MobileCode: '+49' },
    { Code: 'GH', MobileCode: '+233' },
    { Code: 'GI', MobileCode: '+350' },
    { Code: 'GR', MobileCode: '+30' },
    { Code: 'GL', MobileCode: '+299' },
    { Code: 'GP', MobileCode: '+590' },
    { Code: 'GT', MobileCode: '+502' },
    { Code: 'GN', MobileCode: '+224' },
    { Code: 'GW', MobileCode: '+245' },
    { Code: 'GY', MobileCode: '+592' },
    { Code: 'HT', MobileCode: '+509' },
    { Code: 'HM', MobileCode: '+ ' },
    { Code: 'HN', MobileCode: '+504' },
    { Code: 'HK', MobileCode: '+852' },
    { Code: 'HU', MobileCode: '+36' },
    { Code: 'IS', MobileCode: '+354' },
    { Code: 'IN', MobileCode: '+91' },
    { Code: 'ID', MobileCode: '+62' },
    { Code: 'IR', MobileCode: '+98' },
    { Code: 'IQ', MobileCode: '+964' },
    { Code: 'IE', MobileCode: '+353' },
    { Code: 'IL', MobileCode: '+972' },
    { Code: 'IT', MobileCode: '+39' },
    { Code: 'SJ', MobileCode: '+47' },
    { Code: 'JP', MobileCode: '+81' },
    { Code: 'JO', MobileCode: '+962' },
    { Code: 'KZ', MobileCode: '+7' },
    { Code: 'KE', MobileCode: '+254' },
    { Code: 'KI', MobileCode: '+686' },
    { Code: 'KR', MobileCode: '+82' },
    { Code: 'KW', MobileCode: '+965' },
    { Code: 'KG', MobileCode: '+996' },
    { Code: 'LA', MobileCode: '+856' },
    { Code: 'LV', MobileCode: '+371' },
    { Code: 'LB', MobileCode: '+961' },
    { Code: 'LS', MobileCode: '+266' },
    { Code: 'LR', MobileCode: '+231' },
    { Code: 'LY', MobileCode: '+218' },
    { Code: 'LI', MobileCode: '+423' },
    { Code: 'LT', MobileCode: '+370' },
    { Code: 'LU', MobileCode: '+352' },
    { Code: 'MO', MobileCode: '+853' },
    { Code: 'MK', MobileCode: '+389' },
    { Code: 'MG', MobileCode: '+261' },
    { Code: 'MW', MobileCode: '+265' },
    { Code: 'MY', MobileCode: '+60' },
    { Code: 'MV', MobileCode: '+960' },
    { Code: 'ML', MobileCode: '+223' },
    { Code: 'MT', MobileCode: '+356' },
    { Code: 'MH', MobileCode: '+692' },
    { Code: 'MQ', MobileCode: '+596' },
    { Code: 'MR', MobileCode: '+222' },
    { Code: 'MU', MobileCode: '+230' },
    { Code: 'YT', MobileCode: '+262' },
    { Code: 'MX', MobileCode: '+52' },
    { Code: 'FM', MobileCode: '+691' },
    { Code: 'MD', MobileCode: '+373' },
    { Code: 'MC', MobileCode: '+377' },
    { Code: 'MN', MobileCode: '+976' },
    { Code: 'ME', MobileCode: '+382' },
    { Code: 'MA', MobileCode: '+212' },
    { Code: 'MZ', MobileCode: '+258' },
    { Code: 'MM', MobileCode: '+95' },
    { Code: 'NA', MobileCode: '+264' },
    { Code: 'NR', MobileCode: '+674' },
    { Code: 'NP', MobileCode: '+977' },
    { Code: 'NL', MobileCode: '+31' },
    { Code: 'NC', MobileCode: '+687' },
    { Code: 'NZ', MobileCode: '+64' },
    { Code: 'NI', MobileCode: '+505' },
    { Code: 'NE', MobileCode: '+227' },
    { Code: 'NG', MobileCode: '+234' },
    { Code: 'NU', MobileCode: '+683' },
    { Code: 'NF', MobileCode: '+672' },
    { Code: 'KP', MobileCode: '+850' },
    { Code: 'NO', MobileCode: '+47' },
    { Code: 'OM', MobileCode: '+968' },
    { Code: 'PK', MobileCode: '+92' },
    { Code: 'PW', MobileCode: '+680' },
    { Code: 'PS', MobileCode: '+970' },
    { Code: 'PA', MobileCode: '+507' },
    { Code: 'PG', MobileCode: '+675' },
    { Code: 'PY', MobileCode: '+595' },
    { Code: 'PE', MobileCode: '+51' },
    { Code: 'PH', MobileCode: '+63' },
    { Code: 'PN', MobileCode: '+870' },
    { Code: 'PL', MobileCode: '+48' },
    { Code: 'PT', MobileCode: '+351' },
    { Code: 'QA', MobileCode: '+974' },
    { Code: 'RE', MobileCode: '+262' },
    { Code: 'RO', MobileCode: '+40' },
    { Code: 'RU', MobileCode: '+7' },
    { Code: 'RW', MobileCode: '+250' },
    { Code: 'BL', MobileCode: '+590' },
    { Code: 'SH', MobileCode: '+290' },
    { Code: 'MF', MobileCode: '+590' },
    { Code: 'PM', MobileCode: '+508' },
    { Code: 'WS', MobileCode: '+685' },
    { Code: 'SM', MobileCode: '+378' },
    { Code: 'ST', MobileCode: '+239' },
    { Code: 'SA', MobileCode: '+966' },
    { Code: 'SN', MobileCode: '+221' },
    { Code: 'RS', MobileCode: '+381' },
    { Code: 'SC', MobileCode: '+248' },
    { Code: 'SL', MobileCode: '+232' },
    { Code: 'SG', MobileCode: '+65' },
    { Code: 'SX', MobileCode: '+599' },
    { Code: 'SK', MobileCode: '+421' },
    { Code: 'SI', MobileCode: '+386' },
    { Code: 'SB', MobileCode: '+677' },
    { Code: 'SO', MobileCode: '+252' },
    { Code: 'ZA', MobileCode: '+27' },
    { Code: 'SS', MobileCode: '+211' },
    { Code: 'ES', MobileCode: '+34' },
    { Code: 'LK', MobileCode: '+94' },
    { Code: 'SD', MobileCode: '+249' },
    { Code: 'SR', MobileCode: '+597' },
    { Code: 'SJ', MobileCode: '+47' },
    { Code: 'SZ', MobileCode: '+268' },
    { Code: 'SE', MobileCode: '+46' },
    { Code: 'CH', MobileCode: '+41' },
    { Code: 'SY', MobileCode: '+963' },
    { Code: 'TW', MobileCode: '+886' },
    { Code: 'TJ', MobileCode: '+992' },
    { Code: 'TZ', MobileCode: '+255' },
    { Code: 'TH', MobileCode: '+66' },
    { Code: 'TG', MobileCode: '+228' },
    { Code: 'TK', MobileCode: '+690' },
    { Code: 'TO', MobileCode: '+676' },
    { Code: 'TN', MobileCode: '+216' },
    { Code: 'TR', MobileCode: '+90' },
    { Code: 'TM', MobileCode: '+993' },
    { Code: 'TV', MobileCode: '+688' },
    { Code: 'UM', MobileCode: '+1' },
    { Code: 'UG', MobileCode: '+256' },
    { Code: 'UA', MobileCode: '+380' },
    { Code: 'AE', MobileCode: '+971' },
    { Code: 'GB', MobileCode: '+44' },
    { Code: 'US', MobileCode: '+1' },
    { Code: 'UY', MobileCode: '+598' },
    { Code: 'UZ', MobileCode: '+998' },
    { Code: 'VU', MobileCode: '+678' },
    { Code: 'VA', MobileCode: '+379' },
    { Code: 'VN', MobileCode: '+84' },
    { Code: 'WF', MobileCode: '+681' },
    { Code: 'YE', MobileCode: '+967' },
    { Code: 'ZM', MobileCode: '+260' },
    { Code: 'ZW', MobileCode: '+263' }
]