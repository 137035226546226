import React from 'react'
import Popup from 'new-components/popup'
import useCopyService from './helper';
import Selection from 'new-components/dropdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';

function CopyRetailTarget({ onClose, selectedLanguage, translation, }) {
    const {
        control, isValid, errors, toMonth, monthDropdown, isLoading, yearDropdown, onApplyFilter, handleSubmit, watch
    } = useCopyService({ onClose });
    return (
        <Popup
            heading={selectedLanguage?.name == "English" ? "Copy Retail Target" : translation[824]?.value ? translation[824]?.value : "Copy Retail Target"}
            handleClose={onClose}
            size='md'
        >
            <form onSubmit={handleSubmit(onApplyFilter)} >
                <div className='flex flex-col gap-4'>
                    <div className='grid md:grid-cols-2 gap-4'>
                        <Selection
                            label={selectedLanguage?.name == "English" ? "From Year" : translation[825]?.value ? translation[825]?.value : "From Year"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                            name="from_year"
                            control={control}
                            options={yearDropdown}
                            errorMessage={errors?.from_year?.message}
                        />

                        <Selection
                            label={selectedLanguage?.name == "English" ? "From Month" : translation[826]?.value ? translation[826]?.value : "From Month"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                            name={"from_month"}
                            options={monthDropdown}
                            control={control}
                            errorMessage={errors?.from_month?.message}
                        />

                        <Selection
                            label={selectedLanguage?.name == "English" ? "To Year" : translation[827]?.value ? translation[827]?.value : "To Year"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                            name="to_year"
                            control={control}
                            // options={yearDropdown.filter(obj => obj.value != watch('from_year'))}
                            options={yearDropdown}
                            errorMessage={errors?.to_year?.message}
                        />

                        <Selection
                            label={selectedLanguage?.name == "English" ? "To Month" : translation[828]?.value ? translation[828]?.value : "To Month"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                            name={"to_month"}
                            options={toMonth}
                            control={control}
                            errorMessage={errors?.to_month?.message}
                        />
                    </div>
                    <LoginBtn
                        text={selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
                        loading={isLoading}
                        disabled={isLoading}
                        className='!px-6'
                    />
                </div>
            </form>
        </Popup>
    )
}

export default CopyRetailTarget
