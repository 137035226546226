import React from "react";
import moment from "moment";

import Popup from "new-components/popup";

import Selection from "new-components/dropdown";

import useEmployeeBreakTimeSlot from "./helper";

import Svgs from "Assets/svgs";
import { useSelector } from "react-redux";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";

const AddBreakTimeSlot = ({
  employeeData,
  updateSlotData,
  isUpdate,
  setIsUpdate,
  isLeoDay,
  setEmployeeSlotPopup,
  successCallBack,
  breaktimeSlotPopup, setBreaktimeSlotPopup,
}) => {
  const {
    fields,
    errors,
    control,
    isLoading,
    timeSlotArr,
    watch,
    onSubmit,
    handleSubmit,
    handleAddTimeSlot,
  } = useEmployeeBreakTimeSlot({
    breaktimeSlotPopup, setBreaktimeSlotPopup,
    employeeData,
    updateSlotData,
    isUpdate,
    setIsUpdate,
    isLeoDay,
    successCallBack
  });

  const endTime = (index) => {
    let endTime = timeSlotArr
      .map((obj) => {
        if (
          moment(obj.value, "hh:mm").valueOf() <=
          moment(watch(`schedule[${index}].start_time`), "hh:mm").valueOf()
        ) {
        } else {
          return obj;
        }
      })
      .filter((obj) => obj !== undefined);
    return endTime;
  };

  const state = useSelector((state) => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  const startTime = (index) => {
    let startTime = timeSlotArr
      .map((obj) => {
        if (
          moment(obj.value, "hh:mm").valueOf() <=
          moment(watch(`schedule[${index}].end_time`), "hh:mm").valueOf()
        ) {
        } else {
          return obj;
        }
      })
      .filter((obj) => obj !== undefined);
    return startTime;
  };
  console.log("🚀 ~ watch(`schedule[${index - 1}].end_time`):", control)

  return (
    <Popup
      size="md"
      heading={"Break Time"}
      handleClose={() => setBreaktimeSlotPopup(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4">
          {fields &&
            fields?.map((ele, index) => {
              return (
                <div key={index} className="flex items-center gap-3">
                  <Selection
                    name={`schedule[${index}].start_time`}
                    placeholder={selectedLanguage?.name == "English" ? " Select In Time" : translation[621]?.value ? translation[621]?.value : " Select In Time"}
                    options={
                      watch(`schedule[${index - 1}].end_time`)
                        ? startTime(index - 1)
                        : timeSlotArr
                    }
                    control={control}
                    errorMessage={
                      errors?.schedule &&
                        errors?.schedule[index]?.start_time?.message ?
                        selectedLanguage?.name == "English"
                          ? "This field is required"
                          : translation[287]?.value
                            ? translation[287]?.value
                            : "This field is required"
                        : ""
                    }
                  />

                  <Selection
                    name={`schedule[${index}].end_time`}
                    placeholder={selectedLanguage?.name == "English" ? " Select Out Time" : translation[622]?.value ? translation[622]?.value : " Select Out Time"}
                    options={endTime(index)}
                    control={control}
                    errorMessage={
                      errors?.schedule &&
                        errors?.schedule[index]?.end_time?.message ?
                        selectedLanguage?.name == "English"
                          ? "This field is required"
                          : translation[287]?.value
                            ? translation[287]?.value
                            : "This field is required"
                        : ""
                    }
                  />
                </div>
              );
            })}


          <div className="flex justify-end pt-1">
            <LoginBtn
              className={"!px-6"}
              text={isUpdate
                ? selectedLanguage?.name == "English" ? "Update" : translation[845]?.value ? translation[845]?.value : "Update"
                : selectedLanguage?.name == "English" ? "Add" : translation[623]?.value ? translation[623]?.value : "Ad"
              }
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        </div>
      </form>
    </Popup>
  );
};

export default AddBreakTimeSlot;
