import { addNewSkill, getEmployeesTraining } from "Adapters/Api/employee/employeeTraining";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useTrainingRequest = () => {
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");

    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const employeeTraining = state?.employee?.employeeTraining
    const employeeTrainingPaginationData = state?.employee?.employeeTrainingPaginationData

    const [addTraingPoup, setAddTraingPoup] = useState(false)
    const [addSkillPopup, setAddSkillPopup] = useState(false)

    const [trainingLoading, setTrainingLoading] = useState(true)
    const [trainingPage, setTrainingPage] = useState(1)
    const [ReportToggle, setReportToggle] = useState("student");

    const getTrainingData = () => {
        const success = () => { setTrainingLoading(false) }
        const fail = () => { setTrainingLoading(false) }
        const payload = {
            page: trainingPage,
            user_role: ReportToggle
        }
        setTrainingLoading(true)
        dispatch(getEmployeesTraining(access_token, success, fail, payload))
    }

    useEffect(() => {
            getTrainingData()

    }, [trainingPage, ReportToggle])
    
    useEffect(() => {
        setTrainingPage(1)
    }, [ReportToggle])

    return {
        translation,
        selectedLanguage,
        addTraingPoup, setAddTraingPoup,
        addSkillPopup, setAddSkillPopup, trainingLoading, getTrainingData, trainingPage, setTrainingPage,
        employeeTraining, employeeTrainingPaginationData, ReportToggle, setReportToggle
    }
}

export default useTrainingRequest