export const SALE_ORDER = {
    GET_SALE_ORDER_PRODUCTS: "GET_SALE_ORDER_PRODUCTS",
    UPDATE_SALE_ORDER_PRODUCTS: "UPDATE_SALE_ORDER_PRODUCTS",
    GET_SALE_ORDER_SERVICES: "GET_SALE_ORDER_SERVICES",
    UPDATE_SALE_ORDER_SERVICES: "UPDATE_SALE_ORDER_SERVICES",
    GET_SALE_ORDER_VOUCHERS: "GET_SALE_ORDER_VOUCHERS",
    GET_SALE_ORDER_MEMBERSHIPS: "GET_SALE_ORDER_MEMBERSHIPS",
    GET_ALL_SALE_ORDER: "GET_ALL_SALE_ORDER",
    GET_INVOICE_TITLES: "GET_INVOICE_TITLES",
    GET_RECENT_SALES: 'GET_RECENT_SALES',
    GET_ALL_SALE_ORDER_NO_PAGINATION: "GET_ALL_SALE_ORDER_NO_PAGINATION",
    GET_SINGLE_INVOICE_DETAIL: 'GET_SINGLE_INVOICE_DETAIL',
    CALCULATE_INVOICE_BILL: "CALCULATE_INVOICE_BILL"
};