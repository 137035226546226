import Svgs from 'Assets/svgs';
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import FloatInput from 'Components/TenantApp/Elements/FloatInput';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import NoPurchaseOrderDataFound from 'Components/Utility/NoPurchaseOrderDataFound';
import React from 'react'

const RefundDetails = ({ selectedLanguage, translation, errors, loading, onSubmit, handleBackClick, handleChange, minEndDateLimit, productList, refundState }) => {

    return (
        <>
            <div className="overflow-x-auto relative">
                <div className="inline-grid xl:grid grid-cols-[repeat(7,minmax(160px,1fr))] py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit text-center">
                    <p>{selectedLanguage?.name == "English" ? "Select Item" : translation[1485]?.value ? translation[1485]?.value : "Select Product"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Product"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Refunded Quantity" : translation[1454]?.value ? translation[1454]?.value : "Refunded Quantity"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Quantity" : translation[365]?.value ? translation[365]?.value : "Quantity"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Refunded Price" : translation[1453]?.value ? translation[1453]?.value : "Refunded Price"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Total Price" : translation[1452]?.value ? translation[1452]?.value : "Total Price"}</p>
                    <p>{selectedLanguage?.name == "English" ? "In Stock" : translation[1451]?.value ? translation[1451]?.value : "In Stock"}</p>
                </div>
                {productList?.products_records?.length > 0 || productList?.services_records?.length > 0 || productList?.appointment_services?.length > 0 ?
                    <>
                        {productList?.products_records?.map((item, index) => {
                            const prodId = item?.id?.split("-")[0]
                            return (
                                <div
                                    key={index}
                                    className={`odd-bg text-[#3A3A3A] transition-all`}
                                >
                                    <div
                                        key={item?.id}
                                        // className={`py-3 inline-grid xl:grid grid-cols-[repeat(7,minmax(160px,1fr))] ${errors?.refunded_quantity?.length > 0 || errors?.refunded_amount?.length ? 'place-items-start' : 'place-items-center'}`}
                                        className={`py-3 inline-grid xl:grid grid-cols-[repeat(7,minmax(160px,1fr))]  place-items-center h-28`}
                                    >
                                        <div className="flex items-center gap-3">
                                            <CheckBox
                                                className={"h-6 w-6"}
                                                name="refunded_products"
                                                value={item?.product}
                                                onChange={(e) => {
                                                    handleChange(e, {
                                                        index: index,
                                                        type: 'product',
                                                        single: item,
                                                        product: {
                                                            product: item?.product,
                                                            in_stock: false
                                                        },
                                                    });
                                                }}
                                                label={`${prodId}`}
                                                id={item?.product}
                                                checked={refundState?.refunded_products?.find((i) => i?.product === item?.product ? true : false)}
                                            />
                                        </div>

                                        <div className="flex items-center gap-3">
                                            <p className="text-sm pr-1 text-center">{item?.product_names?.name}</p>
                                        </div>
                                        <div className="flex items-center h-fit">
                                            <LoginInput
                                                placeholder={selectedLanguage?.name == "English" ? "Quantity" : translation[365]?.value ? translation[365]?.value : "Quantity"}
                                                type={'number'}
                                                onlyNumericAllowed
                                                disabled={!refundState?.refunded_products?.find((i) => i.product == item?.product)}
                                                title={""}
                                                error={errors?.refunded_quantity?.length > 0 ? errors?.refunded_quantity?.find(itm => itm?.id == item?.product)?.text : ''}
                                                name='refunded_quantity'
                                                parentClass="max-w-[10rem] border-0"
                                                errorClass={'!static'}
                                                value={refundState?.refunded_products?.find((i) => i?.product === item?.product)?.refunded_quantity}
                                                onChange={(e) => {
                                                    handleChange(e, {
                                                        index: index,
                                                        single: item,
                                                        type: 'product',
                                                        product: {
                                                            product: item?.product,
                                                        },
                                                    });
                                                }}
                                            />
                                        </div>

                                        <div className="flex items-center">
                                            <p className="text-sm">
                                                {item?.quantity}
                                            </p>
                                        </div>
                                        {/* error={product?.quantity > product.reorder_quantity ?
                                        `${selectedLanguage?.name == "English" ? "Max quantity is" : translation[1198]?.value ? translation[1198]?.value : "Max quantity is"} ${product.reorder_quantity}` :
                                        (product?.quantity == 0 && product?.quantity != "") ? `${selectedLanguage?.name == "English" ? "Quantity should be greater than 0." : translation[1198]?.value ? translation[1198]?.value : "Quantity should be greater than 0."}` :
                                            ((filteredProductError && !product?.quantity && filteredProductError?.quantity == '') || (submitButtonClick && product?.quantity == "")) ? selectedLanguage?.name == "English"
                                                ? "This field is required"
                                                : translation[287]?.value
                                                    ? translation[287]?.value
                                                    : "This field is required"
                                                : ""} */}

                                        <div className="flex items-center">
                                            <FloatInput
                                                title={""}
                                                disabled={!refundState?.refunded_products?.find((i) => i.product == item?.product)}
                                                placeholder={selectedLanguage?.name == "English" ? "Price" : translation[1030]?.value ? translation[1030]?.value : "Price"}
                                                name='refunded_amount'
                                                // error={errors?.refunded_amount}
                                                error={errors?.refunded_amount?.length > 0 ? errors?.refunded_amount?.find(itm => itm?.id == item?.product)?.text : ''}
                                                parentClass="max-w-[10rem] border-0"
                                                onChange={(e) => {
                                                    handleChange(e, {
                                                        index: index,
                                                        single: item,
                                                        type: 'product',
                                                        product: {
                                                            product: item?.product,
                                                        }
                                                    });
                                                }}
                                                errorClass={'!static'}
                                                value={refundState?.refunded_products?.find((i) => i?.product === item?.product)?.refunded_amount}
                                            />
                                        </div>
                                        <div className="flex items-center">
                                            <p className="text-sm">
                                                {+item?.price * +item?.quantity}
                                            </p>
                                        </div>
                                        <CheckBox
                                            className={"h-6 w-6"}
                                            name="in_stock"
                                            value={refundState?.refunded_products?.find((i) => i.product === item?.product ? i?.in_stock : "")}
                                            onChange={(e) => {
                                                handleChange(e, {
                                                    index: index,
                                                    single: item,
                                                    type: 'product',
                                                    product: {
                                                        product: item?.product,
                                                    }
                                                });
                                            }}
                                            label={""}
                                            id={item?.product}
                                            disabled={!refundState?.refunded_products?.find((i) => i.product == item?.product)}
                                            checked={refundState?.refunded_products?.find((i) => i.product === item?.product)?.in_stock}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        {productList?.services_records?.map((item, index) => {
                            const prodId = item?.id?.split("-")[0]
                            return (
                                <div
                                    key={index}
                                    className={`odd-bg text-[#3A3A3A] transition-all`}
                                >
                                    <div
                                        key={item?.id}
                                        className="py-3 inline-grid xl:grid grid-cols-[repeat(7,minmax(160px,1fr))] place-items-center h-28"
                                    >
                                        <div className="flex items-center gap-3">
                                            <CheckBox
                                                className={"h-6 w-6"}
                                                name="refunded_services"
                                                value={item?.service}
                                                onChange={(e) => {
                                                    handleChange(e, {
                                                        index: index,
                                                        single: item,
                                                        type: 'service',
                                                        service: {
                                                            service: item?.service,
                                                        },
                                                    });
                                                }}
                                                label={`${prodId}`}
                                                id={item?.id}
                                                checked={refundState?.refunded_services?.find((i) => i?.service === item?.service ? true : false)}
                                            />
                                        </div>

                                        <div className="flex items-center gap-3">
                                            <p className="text-sm">{item?.service_names?.name}</p>
                                        </div>
                                        <div className="flex items-center">
                                            ------
                                        </div>

                                        <div className="flex items-center">
                                            ------
                                        </div>

                                        <div className="flex items-center">
                                            <FloatInput
                                                title={""}
                                                disabled={!refundState?.refunded_services?.find((i) => i?.service == item?.service)}
                                                placeholder={selectedLanguage?.name == "English" ? "Price" : translation[1030]?.value ? translation[1030]?.value : "Price"}
                                                name='refunded_amount'
                                                errorClass={'!static'}
                                                // error={errors?.refunded_amount}
                                                error={errors?.refunded_service_amount?.length > 0 ? errors?.refunded_service_amount?.find(itm => itm?.id == item?.service)?.text : ''}
                                                parentClass="max-w-[10rem] border-0"
                                                onChange={(e) => {
                                                    handleChange(e, {
                                                        index: index,
                                                        single: item,
                                                        type: 'service',
                                                        service: {
                                                            service: item?.service,
                                                        },
                                                    });
                                                }}
                                                value={refundState?.refunded_services?.find((i) => i?.service === item?.service)?.refunded_amount}
                                            />
                                        </div>
                                        <div className="flex items-center">
                                            <p className="text-sm">
                                                {+item?.price * +item?.quantity}
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            -------
                                        </div>

                                    </div>
                                </div>
                            );
                        })}
                        {productList?.appointment_services?.map((item, index) => {
                            const prodId = item?.id?.split("-")[0]
                            return (
                                <div
                                    key={index}
                                    className={`odd-bg text-[#3A3A3A] transition-all`}
                                >
                                    <div
                                        key={item?.id}
                                        className="py-3 inline-grid xl:grid grid-cols-[repeat(7,minmax(160px,1fr))] place-items-center"
                                    >
                                        <div className="flex items-center gap-3">
                                            <CheckBox
                                                className={"h-6 w-6"}
                                                name="refunded_services"
                                                value={item?.service}
                                                onChange={(e) => {
                                                    handleChange(e, {
                                                        index: index,
                                                        single: item,
                                                        type: 'service',
                                                        service: {
                                                            service: item?.service,
                                                            id: item?.id,
                                                            client: productList?.client
                                                        },
                                                    });
                                                }}
                                                label={`${prodId}`}
                                                id={item?.id}
                                                checked={refundState?.refunded_services?.find((i) => (i?.service === item?.service && i?.id === item?.id) ? true : false)}
                                            />
                                        </div>

                                        <div className="flex items-center gap-3">
                                            <p className="text-sm">{item?.service_names?.name}</p>
                                        </div>
                                        <div className="flex items-center">
                                            ------
                                        </div>

                                        <div className="flex items-center">
                                            ------
                                        </div>

                                        <div className="flex items-center">
                                            <FloatInput
                                                title={""}
                                                disabled={!refundState?.refunded_services?.find((i) => i?.service == item?.service && i?.id == item?.id)}
                                                placeholder={selectedLanguage?.name == "English" ? "Price" : translation[1030]?.value ? translation[1030]?.value : "Price"}
                                                name='refunded_amount'
                                                errorClass={'!static'}
                                                // error={errors?.refunded_amount}
                                                error={errors?.refunded_service_amount?.length > 0 ? errors?.refunded_service_amount?.find(itm => itm?.id == item?.service && item?.id == itm?.appServiceId)?.text : ''}
                                                parentClass="max-w-[10rem] border-0"
                                                onChange={(e) => {
                                                    handleChange(e, {
                                                        index: index,
                                                        single: item,
                                                        type: 'service',
                                                        service: {
                                                            service: item?.service,
                                                            id: item?.id,
                                                            client: productList?.client
                                                        },
                                                    });
                                                }}
                                                value={refundState?.refunded_services?.find((i) => i?.service === item?.service && i?.id === item?.id)?.refunded_amount}
                                            />
                                        </div>
                                        <div className="flex items-center">
                                            <p className="text-sm">
                                                {+item?.price}
                                            </p>
                                        </div>
                                        <div className="flex items-center">
                                            -------
                                        </div>

                                    </div>
                                </div>
                            );
                        })}
                    </>
                    : <>
                        <NoPurchaseOrderDataFound />
                    </>
                }
            </div>
            <hr />

            <div className="flex relative mt-1 h-4">
                {errors?.refunded_products || errors?.refunded_services ?
                    <p className="text-[#eb3b3b] flex items-center gap-1 text-xs w-full justify-start">
                        <Svgs.I fill="#eb3b3b" />
                        {errors?.refunded_products}
                    </p>
                    : ""
                }
            </div>


            <div className="flex justify-between mt-4 flex-col sm:flex-row items-start sm:items-end">
                <div className="flex gap-5 my-3  h-fit whitespace-nowrap">
                    <LoginInput
                        readOnly
                        type={'number'}
                        onlyNumericAllowed
                        title={selectedLanguage?.name == "English" ? "Total Refundable Price" : translation[1457]?.value ? translation[1457]?.value : "Total Refundable Price"}
                        placeholder={selectedLanguage?.name == "English" ? "Total Price" : translation[1452]?.value ? translation[1452]?.value : "Total Price"}
                        error={errors?.total_refund_amount}
                        name='total_refund_amount'
                        disabled={true}
                        parentClass="!w-11/12 border-0"
                        value={refundState?.total_refund_amount}
                    />
                    {refundState?.refund_type == "Credit Refund" &&
                        <LoginInput
                            title={selectedLanguage?.name == "English" ? "Coupon Expiry Date" : translation[1457]?.value ? translation[1457]?.value : "Coupon Expiry Date"}
                            type='date'
                            name='expiry_date'
                            value={refundState?.expiry_date}
                            onChange={handleChange}
                            min={minEndDateLimit}
                            error={errors?.expiry_date}
                        />
                    }
                </div>
                <div className="flex h-fit gap-2 my-3">
                    <LoginBtn
                        onClick={handleBackClick}
                        text={selectedLanguage?.name == "English" ? "Back" : translation[1420]?.value ? translation[1420]?.value : "Back"}
                    />
                    <LoginBtn
                        onClick={onSubmit}
                        loading={loading}
                        text={selectedLanguage?.name == "English" ? "Refund" : translation[1463]?.value ? translation[1463]?.value : "Refund"}
                    />
                </div>
            </div>
        </>
    )
}

export default RefundDetails