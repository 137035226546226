


import { BASE_URL, get_dashboard_data_on_the_base_of_address } from "../api_variables";
import { toast } from 'react-toastify';
import { apiErrorMessage } from "Constants/Data/Errors";





export const get_dashboard_data_on_business_location = (id, duration, success) => dispatch => {

    let s_code;
    let api_url;
    if (duration) {
        api_url = `${BASE_URL}${get_dashboard_data_on_the_base_of_address}?location=${id}&duration=${duration}`
    } else {
        api_url = `${BASE_URL}${get_dashboard_data_on_the_base_of_address}?location=${id}`
    }

    fetch(api_url)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let dashboardData = result?.response;
                dispatch({
                    type: "GET_BASHBOARD_DATA_ON_SELECTED_LOCATION",
                    payload: dashboardData
                })
                success && success();
            } else {
                toast.error("Error in get dashboard data!")
            }
        }).catch((err) => {
            toast.error(apiErrorMessage, { toastId: "toast" })
        })
};
