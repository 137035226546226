export const SIGNUP_USER = {
    PERSONAL_INFO: "PERSONAL_INFO",
    BUSINESS_DETAILS: "BUSINESS_DETAILS",
    BUSINESS_TYPE: "BUSINESS_TYPE",
    TEAM_SIZE: "TEAM_SIZE",
    SOFTWARE_USING: "SOFTWARE_USING",
    FIND_US: "FIND_US",
    OPENINIG_HOURS: "OPENINIG_HOURS"
}

export const CLIENT_SIGNUP =  { 
    CLIENT_INFO :"CLIENT_INFO"
}