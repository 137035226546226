import React,{useState, useEffect} from 'react';
import { Chart as ChartJS,LineElement,CategoryScale,PointElement, LinearScale } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(LineElement,CategoryScale,PointElement, LinearScale);

const LineChart = ({chartData}) => {
  
  const [LabelFontSize, setLabelFontSize] = useState(14);
    useEffect(() => {
          const handleResize = () => {
          if (window.innerWidth >= 770) {
    
              setLabelFontSize(14)
          } else {
        
              setLabelFontSize(10)
          }
          };
          // Initial setup
          handleResize();
          // Event listener for window resize
          window.addEventListener('resize', handleResize);
          return () => {
              window.removeEventListener('resize', handleResize);
          };
      }, []);

  const createLinearGradient = (color1, color2) => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0,0, 450);
    gradient.addColorStop(0, color1);
    gradient.addColorStop(1, color2);
    return gradient;
  };
  
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'April', 'May','Jun','July','Aug','Sep','Oct','Nov','Dec'],
    datasets: [
      {
        label: 'Sales',
        data: chartData?chartData:[0, 10, 20, 22, 34,49, 80,84,91, 95,99,98],
        borderColor: createLinearGradient('#5679FF', '#5679FF1A'),
        fill: false,
        pointBackgroundColor:["#244BDF"],
        pointRadius:6,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: ["#244BDF"],
        pointHoverBorderWidth: 0,
      },
    ],
  };
  
  const options = {
    plugins: {
      legend:false,
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: true,
        usePointStyle: true,
        bodyAlign: "center",
        yAlign: "bottom",
        xAlign: "center",
        footerAlign: "center",
        titleAlign: "center",
        displayColors: false,
        pointStyle: false,
        padding: 10,
        caretPadding: 10,
        bodyFont: {
          size: 15,
        },
        callbacks: {
          title: function () {
            return "";
          },
        },
      },
    },
    scales: {
      y: {
        grid:{
          display: true,
        },
        ticks: {
          font: {
            size: LabelFontSize, // Set the font size for the y-axis labels
            family: 'Arial', // Set the font family for the y-axis labels
          },
        },
      },
      x: {
        grid:{
          display: false,
        },
        ticks: {
          font: {
            size: LabelFontSize, // Set the font size for the y-axis labels
            family: 'Arial', // Set the font family for the y-axis labels
          },
        },
      },
    },
  };

  return (
    <Line data={data} options={options}/>
  );
};

export default LineChart;
