import React from "react";
import CheckBox from "../../../Elements/Checkbox/CheckBox";

const PermissionRow = ({
  onCheck,
  selected,
  options,
  disabled_array,
  ...props
}) => {
  return (
    <div className="px-5 py-3">
      <div
        className={`inline-grid xl:grid gap-y-5 gap-x-3 w-full`}
        style={{
          gridTemplateColumns: `repeat(${
            options.length + 2
          },minmax(8.125rem,1fr))`,
        }}
      >
        <div className="col-span-2">
          <p className="text-[#3A3A3A] text-sm font-semibold capitalize">
            {props.title}
          </p>
        </div>
        {options.map((opt, index) => {
          return (
            <div className="pl-2" key={index}>
              <CheckBox
                onChange={() => {
                  onCheck(opt);
                }}
                checked={selected?.includes(opt.toLowerCase()) ? true : false}
                disabled={disabled_array?.includes(opt) ? true : false}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

PermissionRow.defaultProps = {
  title: 'Use "title" prop!',
  basic: false,
  low: false,
  medium: false,
  high: false,
  owner: false,
  onCheck: () => {},
  options: [],
  selected: [],
};

export default PermissionRow;
