import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getServiceGroupPaginationDropdown, updateGroupService } from "Adapters/Api/saleServices";
import useValidations from "Hooks/useValidations";
import useObjectKeys from "Hooks/useObjectKeys";
import { PostServiceGroup } from "Adapters/Api/CreateServicesGroup";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";
import { SALE_SERVICES } from "Redux/TenantRedux/ActionTypes/saleServiceType";
import { getserviceDropdownList } from "Adapters/Api/services";

export const useAddServiceGroup = () => {
    const dispatch = useDispatch();
    const { setErrors, validation, errors } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys()

    const state = useSelector(state => state)
    const reduxServices = state?.saleService?.saleServices;
    const reduxgroup = state?.saleService?.servicesgroup;
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const servicesRedux = state?.service?.services

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const businessData = getDataFromLocalStorage("business_data");

    const [values, setValues] = useState({
        id: "",
        name: "",
        service: [],
        status: true,
        image: '',
        allow_client_to_select_team_member: false,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getserviceDropdownList())
    }, [])

    // useEffect(() => {
    //     dispatch(getServiceGroup());
    // }, [])

    const selected_services_in_group = reduxgroup?.map((itm) => itm?.services)?.flat();

    const not_selected_service = servicesRedux?.filter(
        (srv) => !selected_services_in_group?.find((itm) => itm?.id === srv?.id)
    );

    const removedSelectedServices = (value, type) => {
        if (type === "serivicegroup") {
            setValues({
                ...values,
                service: [
                    ...values.service.filter((service_id) => service_id != value),
                ],
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (value != "no_value") {
            if (e.target.name && e.target.name == "image") {
                setValues((prev) => ({ ...prev, 'image': e.target.files[0] }));
                setErrors((prev) => ({ ...prev, [name]: "" }));
                return;
            }
            if (name === "service" && value != "no_value") {
                if (!values?.service?.includes(value)) {
                    setValues((prev) => ({ ...prev, [name]: [...prev.service, value] }));
                    setErrors((prev) => ({ ...prev, [name]: "" }));
                    return;
                }
            } else {
                setValues((prev) => ({ ...prev, [name]: value }));
                setErrors((prev) => ({ ...prev, [name]: "" }));
            }
        }
    };

    const CreateGroup = (success, fail) => {
        setErrors(validation({ name: values?.name, }));
        let result = validation({ name: values?.name });
        if (objectKeyConvertToArray(result)?.length === 0) {
            if (!values?.image) {
                setErrors((prev) => ({
                    ...prev,
                    image: selectedLanguage?.name == "English" ? "This field is required" : translation[287]?.value ? translation[287]?.value : "This field is required"
                }))
            } else {
                setLoading(true);
                dispatch(
                    PostServiceGroup(
                        { ...values, business: businessData?.id },
                        (result, message) => {
                            dispatch({
                                type: SALE_SERVICES?.ADD_GROUP_SERVICES,
                                payload: result,
                            });
                            dispatch(getServiceGroupPaginationDropdown(1))
                            setLoading(false);
                            setValues({ name: "", service: [], status: true });
                            toast.success(message, { toastId: "toast" });
                            success && success()
                        },

                        () => {
                            setLoading(false);
                            toast.error(apiErrorMessage, { toastId: "toast" });
                        }
                    )
                );
            }
        }
    }

    const EditGroup = (success, fail) => {
        setErrors(validation({ name: values?.name }));
        let result = validation({ name: values?.name });
        if (objectKeyConvertToArray(result)?.length === 0) {
            if (!values?.image) {
                setErrors((prev) => ({
                    ...prev,
                    image: selectedLanguage?.name == "English" ? "This field is required" : translation[287]?.value ? translation[287]?.value : "This field is required"
                }))
            } else {
                setLoading(true);
                dispatch(
                    updateGroupService(
                        values,
                        (message) => {
                            setValues({ name: "", service: [], status: true });
                            toast.success(message, { toastId: "toast" });
                            setLoading(false);
                            dispatch(getServiceGroupPaginationDropdown(1))
                            success && success()
                        },
                        () => {
                            setLoading(false);
                            toast.error(apiErrorMessage, { toastId: "toast" });
                        }
                    )
                );
            }
        }
    };

    return {
        handleInputChange, setValues, errors, reduxServices, values, translation, selectedLanguage,
        not_selected_service, removedSelectedServices, loading, CreateGroup, EditGroup, servicesRedux
    };
};