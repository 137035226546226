import Svgs from 'Assets/svgs';
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput'; 
import Popup from 'Components/TenantApp/Elements/Popup';
import React,{useEffect} from 'react'
import useSegmentPopup from './helper';
import InfiniteScroll from 'react-infinite-scroll-component';
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm';
import AddAppointmentRightClient from 'Components/TenantApp/Dashboard/Calendar/Elements/AddAppointmentRightClient';
import NoClientFound from 'Components/Utility/NoClientFound';
import ClientCardShimmer from 'Components/Utility/Shimmers/ClientCardShimmer';
import Textarea from 'Components/TenantApp/Elements/Textarea';

const AddSegmentaion = ({ data, setApiCall, setIsEdit, isEdit, CreateS, setCreateS }) => {
  const {
    filterPopup, setFilterPopup,
    translation, selectedLanguage,
    errors, onBlurHandler, setSegmentationData,
    segmentationData, onChangeHandler, onSubmitHandler,
    clientPaginationData, clientRedux, clientLoader,
    clientSearchText, setClientSearchText, setClientCurrentPage,
    getClients, minEndDateLimit, maxEndDateLimit,
    clientSecondLoader, setClientSecondLoader,
    submitLoader,
    isSelectAll, setIsSelectAll,
    onClientFilterChange, clientFilters,
  } = useSegmentPopup({ setCreateS, data, setIsEdit, isEdit, setApiCall })
  const { name, segment_type, is_active, description, client } = segmentationData
  const { gender, number_visit, min_spend_amount, max_spend_amount, start_date, end_date } = clientFilters


  useEffect(() => {
    console.log('clientPaginationData',clientPaginationData);
  }, [clientPaginationData])
  

  
  return (
    <>
      {CreateS && (
        <Popup
          size={"lg"}
          heading={selectedLanguage?.name == "English" ? "Create segment" : translation[1403]?.value ? translation[1403]?.value : "Create segment"}
          open={CreateS}
          close={() => {
            setCreateS(false)
            setIsEdit(false)
          }}
        >
          <div className="grid lg:grid-cols-2 gap-3">
            <div className="flex flex-col gap-5 pr-4">
              <div className="flex items-center gap-3">
                <CircleIcon children={<Svgs.NewService />} />
                <div className="flex flex-col gap-1">
                  <div>
                    <h1 className="font-semibold text-xl">
                      {selectedLanguage?.name == "English" ? "Create New Segment" : translation[1406]?.value ? translation[1406]?.value : "Create New Segment"}
                    </h1>
                  </div>
                  <div>
                    <p className="text-sm text-[#7B7B7B]">
                      {selectedLanguage?.name == "English" ? "Fill in the following information to add customers" : translation[1407]?.value ? translation[1407]?.value : "Fill in the following information to add customers"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <LoginInput
                  title={selectedLanguage?.name == "English" ? "Segment Name" : translation[1404]?.value ? translation[1404]?.value : "Segment Name"}
                  placeholder={selectedLanguage?.name == "English" ? "Enter segment name" : translation[1408]?.value ? translation[1408]?.value : "Enter segment name"}
                  required={true}
                  name={'name'}
                  value={name}
                  specialCharacterNotAllowed={true}
                  onChange={onChangeHandler}
                  onEnterSubmit={onChangeHandler}
                  error={errors?.name}
                  onBlur={() => onBlurHandler({ name: name })}
                />
                <Dropwdown
                  title={selectedLanguage?.name == "English" ? "Segment Type" : translation[1409]?.value ? translation[1409]?.value : "Segment Type"}
                  placeholder={selectedLanguage?.name == "English" ? "Select Segment Type" : translation[1410]?.value ? translation[1410]?.value : "Select Segment Type"}
                  options={
                    [
                      {
                        label: `${selectedLanguage?.name == "English" ? "Dynamic" : translation[1411]?.value ? translation[1411]?.value : "Dynamic"}`,
                        value: "dynamic",
                      },
                      {
                        label: `${selectedLanguage?.name == "English" ? "Static" : translation[1412]?.value ? translation[1412]?.value : "Static"}`,
                        value: "static",
                      }]
                  }
                  required={true}
                  name={'segment_type'}
                  value={segment_type}
                  onChange={onChangeHandler}
                  onEnterSubmit={onChangeHandler}
                  error={errors?.segment_type}
                  onBlur={() => onBlurHandler({ name: segment_type })}
                />
              </div>

              <div className="space-y-2">
                <Dropwdown
                  required={true}
                  title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                  placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[483]?.value : "Select Status"}
                  options={[
                    {
                      value: true,
                      label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                    },
                    {
                      value: false,
                      label: selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                    },
                  ]}
                  value={is_active}
                  name='is_active'
                  onChange={onChangeHandler}
                // onBlur={blur_change}
                // error={error.includes('is_active') && !form_data.status}
                // error={errors?.segment_type}
                // disabled={loading}
                />

                <Textarea
                  title={selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description"}
                  rows={5}
                  limit={150}
                  placeholder={selectedLanguage?.name == "English" ? "Enter description" : translation[93]?.value ? translation[93]?.value : "Enter description"}
                  required={true}
                  name={'description'}
                  value={description}
                  onChange={onChangeHandler}
                  onEnterSubmit={onChangeHandler}
                  errorMessage={errors?.description}
                  onBlur={() => onBlurHandler({ name: description })}
                />
              </div>

            </div>
            <div className="flex flex-col relative gap-4 lg:pl-4 lg:border-l h-full">
              <div className="flex items-center justify-between gap-2 pt-6 lg:py-0">
                <h1 className="text-xl font-semibold">
                  {selectedLanguage?.name == "English" ? "Add Client" : translation[1413]?.value ? translation[1413]?.value : "Add Client"}
                </h1>
                {!filterPopup && <LoginBtn
                  onClick={() => {
                    setFilterPopup(!filterPopup);
                  }}
                  borderBtn
                >
                  <div className="flex items-center gap-2 text-primary font-semibold">
                    <Svgs.Filters />
                    <p>{selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}</p>
                  </div>
                </LoginBtn>
                }
              </div>
              {filterPopup ? (
                <>
                  <div className='flex flex-col gap-4 xl:pb-4'>
                    <Dropwdown
                      name={"gender"}
                      value={gender}
                      onChange={onClientFilterChange}
                      onBlur={() => onBlurHandler({ gender: gender })}
                      error={errors?.gender}
                      title={selectedLanguage?.name == "English" ? "Gender" : translation[479]?.value ? translation[479]?.value : "Gender"}
                      placeholder={selectedLanguage?.name == "English" ? "Select Gender" : translation[516]?.value ? translation[516]?.value : "Select Gender"}
                      options={[
                        {
                          label: selectedLanguage?.name == "English" ? "All" : translation[313]?.value ? translation[313]?.value : "All",
                          value: ''
                        },
                        {
                          label: selectedLanguage?.name == "English" ? "Male" : translation[86]?.value ? translation[86]?.value : "Male",
                          value: 'Male'
                        },
                        {
                          label: selectedLanguage?.name == "English" ? "Female" : translation[87]?.value ? translation[87]?.value : "Female",
                          value: 'Female'
                        }
                      ]}
                    />
                    <LoginInput
                      title={selectedLanguage?.name == "English" ? "Number of Visit/Leads" : translation[1418]?.value ? translation[1418]?.value : "Number of Visit/Leads"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Number" : translation[1419]?.value ? translation[1419]?.value : "Enter Number"}
                      name='number_visit'
                      value={number_visit}
                      type='number'
                      onChange={onClientFilterChange}
                      onlyNumericAllowed={true}
                      required={false}
                    // min={minEndDateLimit}
                    // onBlur={() => onBlurHandler({ endDate: dateRestriction.endDate })}
                    // error={errors.endDate}
                    />
                    <LoginInput
                      title={selectedLanguage?.name == "English" ? "Minimum Spend Amount" : translation[1459]?.value ? translation[1459]?.value : "Minimum Spend Amount"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Minimum Spend Amount" : translation[1460]?.value ? translation[1460]?.value : "Minimum Enter Spend Amount"}
                      name='min_spend_amount'
                      value={min_spend_amount}
                      type='number'
                      required={false}
                      onChange={onClientFilterChange}
                      onlyNumericAllowed={true}
                    // min={minEndDateLimit}
                    // onBlur={() => onBlurHandler({ endDate: dateRestriction.endDate })}
                    // error={errors.endDate}
                    />
                    <LoginInput
                      title={selectedLanguage?.name == "English" ? "Maximum Spend Amount" : translation[1461]?.value ? translation[1461]?.value : "Maximum Spend Amount"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Maximum Spend Amount" : translation[1462]?.value ? translation[1462]?.value : "Enter Maximum Spend Amount"}
                      name='max_spend_amount'
                      value={max_spend_amount}
                      type='number'
                      required={false}
                      onChange={onClientFilterChange}
                      onlyNumericAllowed={true}
                    // min={minEndDateLimit}
                    // onBlur={() => onBlurHandler({ endDate: dateRestriction.endDate })}
                    // error={errors.endDate}
                    />

                    <div className="flex flex-col gap-2">
                      <p className="text-sm normal-case">
                        {selectedLanguage?.name == "English" ? "Date Range" : translation[617]?.value ? translation[617]?.value : "Date Range"}
                      </p>
                      <div className='flex flex-col gap-4'>
                        <LoginInput
                          title={""}
                          type='date'
                          name='start_date'
                          value={start_date}
                          onChange={onClientFilterChange}
                        // onBlur={() => onBlurHandler({ startDate: dateRestriction.startDate })}
                        // error={errors.startDate}
                        />
                        <LoginInput
                          title={""}
                          type='date'
                          name='end_date'
                          value={end_date}
                          onChange={onClientFilterChange}
                          // value={dateRestriction.endDate}
                          // onChange={handleChange}
                          min={minEndDateLimit}
                          max={maxEndDateLimit}
                        // min={minEndDateLimit}
                        // onBlur={() => onBlurHandler({ endDate: dateRestriction.endDate })}
                        // error={errors.endDate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap justify-between">
                    <LoginBtn
                      text={selectedLanguage?.name == "English" ? "Clear filter" : translation[346]?.value ? translation[346]?.value : "Clear filter"}
                      onClick={() => {
                        setIsSelectAll(false)
                        setSegmentationData((prev) => ({ ...prev, client: [] }));
                        getClients(1, "clear")
                      }}
                    />

                    <div className="flex justify-end items-center gap-3">
                      <LoginBtn
                        onClick={() => {
                          setFilterPopup(!filterPopup);
                        }}
                        borderBtn
                        text={selectedLanguage?.name == "English" ? "Back" : translation[1420]?.value ? translation[1420]?.value : "Back"}
                      />
                      <LoginBtn
                        text={selectedLanguage?.name == "English" ? "Apply" : translation[1421]?.value ? translation[1421]?.value : "Apply"}
                        onClick={() => {
                          setIsSelectAll(false)
                          setSegmentationData((prev) => ({ ...prev, client: [] }));
                          getClients(1)
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <LoginInput
                    title={''}
                    value={clientSearchText}
                    onChange={(e) => {
                      setClientSearchText(e.target.value)
                    }}

                    placeholder={selectedLanguage?.name == "English" ? "Search Client Name" : translation[1414]?.value ? translation[1414]?.value : "Search Client Name"}
                  />
                  <div className="flex items-center gap-3 justify-between">
                    {clientLoader
                      ? <>
                        <div className='w-1/3 bg-gray-200 h-6 rounded-md animate-pulse' />
                        <div className="bg-[#E4EAF0]  rounded-2xl px-4 py-2 animate-pulse">
                          <div className="h-4 w-16"></div>
                        </div>
                      </>
                      : <>
                        <CheckBox
                          label={selectedLanguage?.name == "English" ? "Select All" : translation[1415]?.value ? translation[1415]?.value : "Select All"}
                          onChange={(e) => {
                            setIsSelectAll(!isSelectAll)
                            onChangeHandler(e, 'all')
                          }}
                          disabled={clientRedux?.length == 0}
                          checked={isSelectAll}
                        />
                        <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                          <p className="text-primary">
                            {client?.length ? client?.length : 0} {' '}
                            {selectedLanguage?.name == "English" ? "Selected" : translation[1434]?.value ? translation[1434]?.value : "Selected"}
                          </p>
                        </div>
                      </>
                    }
                  </div>
                  <hr className="border" />
                  <div className="flex flex-col items-start gap-4 justify-start min-h-[17rem] max-h-[17rem] overflow-y-auto">
                    <div className='max-h-[30rem] overflow-y-auto w-full' id="scrollableDiv">
                      {clientLoader
                        ? [0, 1, 2, 3, 4]?.map(itm =>
                          <ClientCardShimmer />
                        )
                        : <InfiniteScroll
                          dataLength={clientRedux?.length ? clientRedux?.length : 10} //This is important field to render the next data
                          next={() => {
                            clientPaginationData?.currentPage < clientPaginationData?.pages ? setClientSecondLoader(true) : setClientSecondLoader(false)
                            setClientCurrentPage(clientPaginationData?.currentPage + 1)
                          }}
                          hasMore={clientPaginationData && clientRedux?.length < clientPaginationData?.allCount && clientPaginationData?.currentPage > 0 && clientPaginationData?.currentPage < clientPaginationData?.pages}
                          scrollableTarget="scrollableDiv"
                          loader={clientSecondLoader
                            ? <div className="h-[4rem] flex items-center justify-center">
                              <PreloaderSm />
                            </div>
                            : ""
                          }
                        >
                          {clientRedux?.length > 0
                            ? clientRedux?.map((clint, index) => {
                              return (
                                <CheckBox
                                  mainLabelClass={"!w-full"}
                                  label={
                                    <AddAppointmentRightClient
                                      data={clint}
                                      index={index}
                                      noId
                                    />
                                  }
                                  name={'client'}
                                  value={clint?.id}
                                  id={clint?.id}
                                  onChange={onChangeHandler}
                                  checked={client?.find(itm => clint?.id == itm) ? true : false}
                                />
                              )
                            })
                            : <NoClientFound />
                          }
                        </InfiniteScroll>
                      }
                    </div>
                  </div>
                  {errors?.client && (
                    <span className={"text-[#eb3b3b] flex items-center gap-1 text-xs w-full justify-end"}>
                      <Svgs.I fill="#eb3b3b" />
                      {errors?.client}
                    </span>
                  )}
                  <hr className="border" />
                  <div className='flex justify-end items-end'>
                    <LoginBtn
                      loading={submitLoader}
                      onClick={onSubmitHandler}
                      disabled={submitLoader}
                      text={
                        isEdit ?
                          `${selectedLanguage?.name == "English" ? "Update Segment" : translation[1416]?.value ? translation[1416]?.value : "Update Segment"}`
                          :
                          `${selectedLanguage?.name == "English" ? "Add Segment" : translation[1417]?.value ? translation[1417]?.value : "Add Segment"}`
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Popup >
      )}
    </>
  )
}

export default AddSegmentaion