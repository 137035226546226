import React from 'react'
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Svgs from 'Assets/svgs';

export const ClientAppointmenrCard = ({ name, animate, price, service, created_at, sale_by, description, dt, dy, status, duration, favourite }) => {
    const dispatch = useDispatch();
    const state = useSelector((state) => {
        return state;
    });
    const location_currency = state?.utility?.currency?.location_currency;
    const navigate = useNavigate();

    var date = new Date();
    date?.setDate(date?.getDate() - 1);
    let from_Date = date?.toLocaleString()?.split(",")[0]
    const fromDate = Date?.parse(from_Date);
    let createAt = Date?.parse(created_at?.split("T")[0]);


    return (
        animate ? <div className='border-b animate-pulse'>
            <div className='flex items-center gap-3 pt-4 pb-3'>
                <div className='bg-gray-300 rounded-lg w-12 h-16 text-center p-1'>
                </div>
                <div className='flex flex-col flex-1'>
                    <p className="bg-gray-200 rounded-[4px] h-3.5 w-3/5 mx-1"></p>
                    <p className="bg-gray-300 rounded-[4px] h-5   w-4/5 mx-1 my-1"></p>
                    <p className="bg-gray-200 rounded-[4px] h-3.5 w-4/5 mx-1"></p>
                </div>
                <div className='flex items-center justify-between w-1/5 gap-4'>
                    <p className="bg-gray-200 rounded-[4px] h-5 w-full"></p>
                </div>
            </div>
        </div>
            :
            <div className='border-b'>
                <div className='flex items-center gap-3 pt-4 pb-3'>
                    <div>
                        <div className='bg-[#E0EBFD] rounded-lg text-center p-3'>
                            <h1 className='font-semibold text-lg'>{dy ? dy : 0}</h1>
                            <p className='text-[#A1A1A1] text-sm'>
                                {moment(dt).format("MMM")}
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-col flex-1'>
                        <p className='text-[#A1A1A1] text-xs'>
                            <p className='text-[#A1A1A1] text-xs'>
                                {moment(created_at).format('ddd HH:mm')}
                            </p>
                        </p>
                        <div className='flex items-center justify-between gap-4'>
                            {/* <h1 className='text-primary font-semibold'>{name}</h1> */}
                            <h1 className='text-primary font-semibold'>{service}</h1>
                            <h1 className='text-primary font-semibold'> {+(price)?.toFixed(2)} {location_currency}</h1>
                        </div>
                        <p className='text-[#A1A1A1] text-sm flex items-center gap-2'> <span>{duration.toString().toLowerCase()}</span> with
                            <span>
                                {/* {
                                    favourite ?
                                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.6159 0.0240253C2.6333 0.0703144 1.715 0.492189 1.03636 1.20879C0.447149 1.83106 0.0899997 2.62149 0.00933422 3.47988C-0.00411002 3.62871 -0.00294096 4.05059 0.0122569 4.19824C0.0952605 5.02442 0.425521 5.77266 0.974397 6.38086C1.01415 6.42422 2.26914 7.68574 3.76437 9.18457L6.48303 11.9092L9.22916 9.15527C12.1068 6.27012 12.0378 6.33984 12.2009 6.12481C12.962 5.12168 13.1742 3.78809 12.7638 2.58985C12.6089 2.1375 12.3693 1.71797 12.056 1.35059C11.9595 1.2375 11.7538 1.03125 11.6415 0.935158C10.7495 0.172267 9.57345 -0.13535 8.42368 0.0937519C7.73451 0.230861 7.10614 0.553713 6.58123 1.04063C6.52745 1.09043 6.48186 1.13086 6.47952 1.13086C6.47719 1.13086 6.44913 1.10508 6.41639 1.07344C5.97624 0.644533 5.3958 0.315236 4.79081 0.152932C4.5079 0.0767597 4.25655 0.0380878 3.95201 0.0234394C3.81347 0.0169941 3.7632 0.0169941 3.6159 0.0240253Z" fill="#FF3030" />
                                        </svg>  
                                        :
                                        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.4551 3.1067C13.2524 2.64552 12.96 2.2276 12.5944 1.87635C12.2285 1.52404 11.7972 1.24407 11.3238 1.05165C10.8328 0.851341 10.3063 0.748809 9.77475 0.75001C9.029 0.75001 8.30139 0.950611 7.66908 1.32952C7.51781 1.42017 7.37411 1.51972 7.23797 1.6282C7.10182 1.51972 6.95812 1.42017 6.80685 1.32952C6.17454 0.950611 5.44694 0.75001 4.70118 0.75001C4.16417 0.75001 3.6438 0.851054 3.15218 1.05165C2.67719 1.24483 2.2491 1.52269 1.88151 1.87635C1.51544 2.22721 1.223 2.64522 1.02079 3.1067C0.810526 3.58665 0.703125 4.09633 0.703125 4.62086C0.703125 5.11567 0.805988 5.63129 1.0102 6.15583C1.18114 6.59418 1.42619 7.04887 1.73932 7.50802C2.23548 8.23464 2.91771 8.99247 3.76482 9.7607C5.1686 11.0341 6.55877 11.9138 6.61776 11.9495L6.97627 12.1753C7.1351 12.2749 7.33932 12.2749 7.49815 12.1753L7.85666 11.9495C7.91565 11.9123 9.30431 11.0341 10.7096 9.7607C11.5567 8.99247 12.2389 8.23464 12.7351 7.50802C13.0482 7.04887 13.2948 6.59418 13.4642 6.15583C13.6684 5.63129 13.7713 5.11567 13.7713 4.62086C13.7728 4.09633 13.6654 3.58665 13.4551 3.1067ZM7.23797 11C7.23797 11 1.85277 7.61055 1.85277 4.62086C1.85277 3.1067 3.12797 1.87932 4.70118 1.87932C5.80696 1.87932 6.76601 2.48558 7.23797 3.37119C7.70993 2.48558 8.66898 1.87932 9.77475 1.87932C11.348 1.87932 12.6232 3.1067 12.6232 4.62086C12.6232 7.61055 7.23797 11 7.23797 11Z" fill="#FF3030" />
                                        </svg>
                                } */}
                                <Svgs.Employee />
                            </span>
                            <span>{sale_by}</span> </p>
                        <p className='text-[#A1A1A1] text-sm'>{description ? description : ""} </p>
                    </div>
                </div>
            </div>
    )
}