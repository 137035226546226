import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import CircleIcon from "Components/TenantApp/Elements/CircleIcon";

import Svgs from "Assets/svgs";

import LoginBtn from "Components/TenantApp/Elements/LoginBtn";

const ServiceCard = ({
  setOpenCart,
  OpenCart,
  service,
  onClick,
  currency,
  view_details,
  onEditHandler,
  onDeleteHandler,
  animate,
  locationDetail,
}) => {
  let result = service?.priceservice?.find(
    (itm) => itm?.currency === locationDetail?.currency?.id
  );

  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (<>

    {animate ?
      <div className={`border justify-between relative animate-pulse`}>
        <div className="flex items-center gap-4 p-4">
          <div className="!w-[7em] h-[5rem] rounded-lg bg-gray-200"></div>

          <div className="w-full">
            <div className="rounded-sm h-5 w-4/5 bg-gray-200"></div>
            <div className="rounded-sm h-5 w-4/5 mt-2 bg-gray-200"></div>
          </div>
        </div>

        <div className="border-t-2 border-[#CFCFDC] border-dashed px-4 py-2.5 flex items-center justify-end">
          <div className="rounded-lg h-8 w-1/5 mt-2 bg-gray-200"></div>
        </div>
      </div>
      :

      <div className={`border justify-between relative rounded-[0.625rem]`}>
        <div className="flex items-center gap-4 p-4">

          <div className="absolute top-2 right-2">
            <div className="cursor-pointer" onClick={() => view_details()}>
              <Svgs.INFO />
            </div>
          </div>

          <div>
            {service?.image
              ?
              <div className="h-[6rem] w-[6rem] overflow-hidden flex items-center justify-center bg-gray-50 border rounded-[0.625rem]">
                <img src={service?.image} />
              </div>
              :
              <div className="h-[6rem] w-[6rem] overflow-hidden flex items-center justify-center bg-gray-50 border rounded-[0.625rem]">
                <Svgs.NewService size='2.5rem' />
              </div>
            }
          </div>

          <div className="">
            <h1 className="font-semibold ">{service?.name}</h1>
            <div className="">
              <p className="text-[#5679ff] text-xs font-semibold">
                {result?.price ? result?.price?.toFixed(2) : 0} {currency}
              </p>
              {(result?.discount_price || result?.discount_price == 0) && (
                <p className="text-[#7D7D7D] font-semibold text-xs">
                  {selectedLanguage?.name == "English" ? "Discount Price" : translation[245]?.value ? translation[245]?.value : "Discount Price"} : {result?.discount_price?.toFixed(2)} {currency}
                </p>
              )}

            </div>
          </div>
        </div>

        <div className="border-t-2 border-[#CFCFDC] border-dashed px-4 py-2.5 flex items-center justify-between">
          {!employee ||
            (employee &&
              employeePermissions?.sales_apply_offer?.includes("create")) ||
            employeePermissions?.sales_apply_offer?.includes("edit") ? (
            <LoginBtn
              className={'ml-auto !py-2'}
              onClick={() => {
                setOpenCart(true)
                const price = result?.price ? result?.price : 0;
                const discount_price = result?.discount_price
                if ((result?.discount_price || result?.discount_price == 0) && result?.discount_price !== undefined) {
                  onClick(price, discount_price);
                } else {
                  onClick(price)
                }
              }}
              text={'Add to Cart'}
            />
          ) : (
            ""
          )}

        </div>
      </div>
    }


    {/* OLD CARD UI HERE */}
    {/* <div className="relative z-0">
          <div
            className={
              "bg-white z-0 rounded-xl shadow border lg:w-[15rem] relative w-[13.5rem]"
            }
          >
            {!employee ||
              (employee &&
                employeePermissions?.sales_apply_offer?.includes("create")) ||
              employeePermissions?.sales_apply_offer?.includes("edit") ? (
              <div className="absolute top-4 right-4">
                <LoginBtn
                  onClick={() => {
                    setOpenCart(true)
                    const price = result?.price ? result?.price : 0;
                    const discount_price = result?.discount_price
                    if ((result?.discount_price || result?.discount_price == 0) && result?.discount_price !== undefined) {
                      onClick(price, discount_price);
                    } else {
                      onClick(price)
                    }
                  }}
                  text={<Svgs.Plus color="#fff" />}
                />
              </div>
            ) : (
              ""
            )}

            <div className="flex flex-col gap-3 p-3 justify-center">
              <div className="flex justify-center">
                <div className="flex flex-col gap-2">
                  <div className="flex items-start justify-center">
                    <Svgs.Gift />
                  </div>
                  <h1 className="text-primary font-bold text-lg line-clamp-2 text-center">
                    {service?.name}
                  </h1>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-2">
                <p className="text-primary font-semibold text-base flex items-center justify-start">
                  {currency} {result?.price ? result?.price?.toFixed(2) : 0}
                </p>
                {(result?.discount_price || result?.discount_price == 0) && (
                  <p className="text-[#7D7D7D] font-semibold text-sm flex items-center justify-start">
                    {selectedLanguage?.name == "English" ? "Discount Price" : translation[245]?.value ? translation[245]?.value : "Discount Price"} : {currency} {result?.discount_price?.toFixed(2)}
                  </p>
                )}
              </div>
              <p
                className="text-sm underline text-[#949494] cursor-pointer text-center"
                onClick={() => view_details()}
              >
                {selectedLanguage?.name == "English" ? "View Details" : translation[244]?.value ? translation[244]?.value : "View Details"}
              </p>
            </div>
          </div>
        </div> */}
    {/* OLD CARD UI HERE */}


  </>
  );
};

export default ServiceCard;
