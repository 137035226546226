import Svgs from "Assets/svgs"
import moment from "moment"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"



export const PurchaseAndDiscountCard = ({ selected, data, onRemoveOffer, onSelect }) => {
    const state = useSelector(state => state)

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language



    return (
        <>

            <div
                onClick={() => {
                    onSelect && onSelect(data)
                }}
                className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? " border-black" : " border-[#B8C6F5]"} cursor-pointer border  rounded-md p-4 mt-1 `}>
                <div className="flex justify-between items-center mb-2">
                    <p className="text-[#A1A1A1] font-bold">
                        {selectedLanguage?.name == "English" ? "Purchase And Get Discount" : translation[1223]?.value ? translation[1223]?.value : "Purchase And Get Discount"}
                    </p>
                    {onRemoveOffer && selected &&
                        <button
                            className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                            onClick={onRemoveOffer}
                        >
                            <Svgs.Delete fill="#767A87" />
                        </button>
                    }
                </div>
                <div className="text-black font-semibold mb-1 w-full">{data?.promotion_name}</div>

                <div className="mb-2">
                    <p className="text-[#626262]">Purchase <span className="font-bold text-[#393939]">
                        " {data?.service_name} " </span>
                        {selectedLanguage?.name == "English" ? "Service & Get" : translation[1224]?.value ? translation[1224]?.value : "Service & Get"}
                        <span className="font-bold text-[#393939]">
                           {' '} {data?.discount_value}% </span> {' '}
                        {selectedLanguage?.name == "English" ? "Discount on" : translation[1225]?.value ? translation[1225]?.value : "Discount on"}
                        {' '} {data?.purchase} {' '}
                        {selectedLanguage?.name == "English" ? "Purchases of" : translation[1226]?.value ? translation[1226]?.value : "Purchases of"} {' '}
                        <span className="font-bold text-[#393939]">"{data?.discount_service_name}"</span>
                    </p>
                </div>
                {/* Date duration */}
                <div className="flex items-center justify-between w-full gap-2">
                    <div className="flex items-center gap-2">
                        <p className="text-[#A1A1A1] text-sm">
                            {selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                        </p>
                        <p className="text-[#101928] font-semibold">
                            {moment(data?.date_restrictions?.start_date).format('DD-MM-YYYY')}
                        </p>
                    </div>
                    <div className="flex items-center gap-2">
                        <p className="text-[#A1A1A1] text-sm">
                            {selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                        </p>
                        <p className="text-[#101928] font-semibold">
                            {moment(data?.date_restrictions?.end_date).format('DD-MM-YYYY')}
                        </p>
                    </div>
                </div>
            </div>
        </>

    )
}

PurchaseAndDiscountCard.defaultProps = {
    data: {},
    selected: false,
    onSelect: () => { }
}

const PurchaseAndDiscount = ({ setNotFound, purchases_discount, MODULE_TYPE, servicesRedux, onSelect }) => {

    const [purchasenDiscount_data, setDiscData] = useState([])
    const state = useSelector(state => state)

    const dispatch = useDispatch()

    const handle_on_select_appointment = (data) => {
        console.log(data)
        let today_date = new Date()
        today_date = `${today_date.getFullYear()}-${today_date.getMonth() + 1}-${today_date.getDate()}`

        let dsc_app_data = {
            appointment_date: today_date,
            client_can_book: data.discount_service_client_can_book,
            // duration : data?.duration,
            duration: "30min",
            member: undefined,
            price: data?.discount_service_price,
            service: data.discount_service,
            slot_availible_for_online: data.discount_service_slot_availible_for_online,
            srv_duration: "30min",
            srv_name: data?.discount_service_name,
            discount_percentage: data.discount_value,
            discount_price: (data.discount_service_price * (100 - data.discount_value)) / 100,
            image: data?.image
        }
        let new_apps = new Array(data?.purchase)
        if (new_apps) {
            new_apps.fill(dsc_app_data)
        }

        let appointment = {
            appointment_date: today_date,
            client_can_book: data.client_can_book,
            // duration : data?.duration,
            duration: "30min",
            member: undefined,
            price: data?.price,
            service: data.service,
            slot_availible_for_online: data.slot_availible_for_online,
            srv_duration: "30min",
            srv_name: data?.service_name,
            image: data?.image
        }
        new_apps.unshift(appointment)
        onSelect(
            new_apps,
            false,
            {
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
                selected_promotion: data,
            }
        )
    }


    const handle_select_service = (data) => {
        console.log(data)

        let discount_service = {
            id: MODULE_TYPE == 'SERVICE' ? data?.discount_service : data?.discount_product,
            name: data?.discount_service_name,
            selection_type: MODULE_TYPE,
            quantity: data?.purchase,
            price: data?.discount_service_price,
            discount_price: (data?.discount_service_price * (100 - data?.discount_value) / 100),
            client_can_book: data?.discount_service_client_can_book,
            slot_availible_for_online: data?.discount_service_slot_availible_for_online,
            image: data?.image
        }


        let service = {
            id: MODULE_TYPE == 'SERVICE' ? data?.service : data?.product.id,
            name: data?.service_name,
            selection_type: MODULE_TYPE,
            quantity: 1,
            price: data?.price,
            discount_price: undefined,
            client_can_book: data?.service_client_can_book,
            slot_availible_for_online: data?.service_slot_availible_for_online,
            image: data?.image
        }

        if (MODULE_TYPE == 'PRODUCT') {
            discount_service = {
                ...data.product,
                ...discount_service
            }
            service = {
                ...data.product,
                ...service
            }
        }

        dispatch({
            type: "ADD_BULK_ITEMS_TO_CART",
            payload: {
                'services': [discount_service, service],
                is_promotion: true,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
            }
        });
        toast.success("Items added to cart", { toastId: "toast" });
        onSelect(
            [],
            false,
        )
    }

    const handle_on_select = (data) => {
        let funcs = {
            'APPOINTMENT': () => handle_on_select_appointment(data),
            'SERVICE': () => handle_select_service(data),
            'PRODUCT': () => handle_select_service(data),
        }
        funcs[MODULE_TYPE] && funcs[MODULE_TYPE]()
    }

    useEffect(() => {
        let new_purchase = [
            ...purchases_discount
        ]

        let selected_location = state.locations.selected_location
        selected_location = state.locations.locations?.find(itm => itm.id == selected_location)
        let selected_currency_id = undefined

        if (selected_location && selected_location.currency) {
            selected_currency_id = selected_location.currency.id
        }

        new_purchase.forEach(purchase_itm => {
            // discount_product
            let service = undefined
            if (MODULE_TYPE == 'APPOINTMENT' || MODULE_TYPE == 'SERVICE') {
                service = servicesRedux?.find(itm => itm.id == purchase_itm.service)
                let service_price = service?.priceservice?.find((itm) => itm?.currency === selected_currency_id);
                if (service_price) {
                    service.price = service_price.price
                }
                else {
                    if (service && (service.price || service.price == 0)) {
                        service.price = 0
                    }
                }
            }
            else if (MODULE_TYPE == 'PRODUCT') {
                service = state.product?.products?.find(itm => itm.id == purchase_itm.product)
                if (service) {
                    let retail_price = service?.currency_retail_price?.find(rt_price => rt_price.currency == selected_currency_id)

                    service.price = retail_price?.retail_price ? retail_price?.retail_price : 0
                }
                purchase_itm.product = service ? service : {}
            }
            if (service) {
                purchase_itm.service_name = service.name
                purchase_itm.price = service.price
                purchase_itm.client_can_book = service.client_can_book
                purchase_itm.slot_availible_for_online = service.slot_availible_for_online
                purchase_itm.image = service.image
            }

            let d_service = undefined

            if (MODULE_TYPE == 'APPOINTMENT' || MODULE_TYPE == 'SERVICE') {
                d_service = servicesRedux?.find(itm => itm.id == purchase_itm.discount_service)
                let d_service_price = d_service?.priceservice?.find((itm) => itm?.currency === selected_currency_id);
                if (d_service_price) {
                    d_service.price = d_service_price.price
                }
                else {
                    if (d_service && (d_service.price || d_service.price == 0)) {
                        d_service.price = 0
                    }
                }
            }
            else if (MODULE_TYPE == 'PRODUCT') {
                d_service = state.product?.products?.find(itm => itm.id == purchase_itm.discount_product)
                if (d_service) {
                    let retail_price = d_service?.currency_retail_price?.find(rt_price => rt_price.currency == selected_currency_id)

                    d_service.price = retail_price?.retail_price ? retail_price?.retail_price : 0
                }
                // purchase_itm.product = d_service ? d_service : {}
            }

            if (d_service) {
                purchase_itm.discount_service_name = d_service.name
                purchase_itm.discount_service_price = d_service.price
                purchase_itm.discount_service_client_can_book = d_service.client_can_book
                purchase_itm.discount_service_slot_availible_for_online = d_service.slot_availible_for_online
                purchase_itm.image = d_service.image
            }

        })

        setDiscData(new_purchase)

    }, [purchases_discount])



    return (
        <>
            {
                purchasenDiscount_data?.map((brnd_grp, index) => {
                    return (
                        <>
                            <PurchaseAndDiscountCard
                                data={brnd_grp}
                                key={index}
                                onSelect={handle_on_select}
                            />
                        </>
                    )
                })
            }
        </>
    )
}

PurchaseAndDiscount.defaultProps = {
    purchases_discount: []
}


export default PurchaseAndDiscount