import Svgs from 'Assets/svgs'
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import Popup from 'Components/TenantApp/Elements/Popup'
import React, { useEffect } from 'react'
import useRefund from './helper'
import RefundDetails from './RefundDetails'

const RefundPopup = ({ open, close, setOpenRefundPopup, selectedInvice }) => {
    const {
        translation, selectedLanguage, submitLoader,
        refundState, errors, detailLoading,
        handleNextClick, onChangeHandler, handleBackClick, minEndDateLimit,
        showRefundTable, setShowRefundTable, productList, handleSubmitClick
    } = useRefund(setOpenRefundPopup, selectedInvice)
    const { refund_type } = refundState

    return (
        <>
            {open &&
                <Popup
                    heading={
                        !showRefundTable
                            ? selectedLanguage?.name == "English" ? "Refund Type" : translation[1506]?.value ? translation[1506]?.value : "Refund Type"
                            : refund_type
                                ? selectedLanguage?.name == "English" ? "Select Items" : translation[1485]?.value ? translation[1485]?.value : "Select Items"
                                : ""
                    }
                    open={open}
                    close={close}
                    size={!showRefundTable ? "md" : (refund_type == "Cash Refund" || refund_type == "Credit Refund") ? "xl" : ""}
                >
                    {!showRefundTable ?
                        <div className="flex flex-col gap-5">
                            {detailLoading
                                ? <div className="flex flex-col gap-5 animate-pulse">
                                    <div className="flex flex-1 items-center gap-4 lg:flex-row flex-col">
                                        <CircleIcon />
                                        <div className="flex flex-1 w-full flex-col gap-1 lg:text-left text-center">
                                            <div className="w-3/5 h-8 bg-gray-200 rounded-lg" />
                                            <div className="w-2/3 h-6 bg-gray-200 rounded-lg" />
                                            <div className="w-1/3 h-6 bg-gray-200 rounded-lg" />
                                        </div>
                                    </div>

                                    <div className="flex-1 h-12 bg-gray-200 rounded-lg" />
                                    <div className="grid lg:grid-cols-2 gap-5">
                                        <div className="h-12 bg-gray-200 rounded-lg" />
                                    </div>
                                    <div className="flex justify-end w-full">
                                        <div className="w-1/5 h-8 bg-gray-200 rounded-lg" />
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
                                        <CircleIcon>
                                            <Svgs.FilterPopup />
                                        </CircleIcon>
                                        <div className="flex flex-col flex-1 gap-1 lg:text-left text-center">
                                            <h2 className="text-primary font-semibold text-xl">
                                                {selectedLanguage?.name == "English" ? "Select Refund Type" : translation[1508]?.value ? translation[1508]?.value : "Select Refund Type"}
                                            </h2>
                                            <p className="text-[#7B7B7B] text-sm">
                                                {selectedLanguage?.name == "English" ? "Choose refund type in order to create a refund" : translation[1509]?.value ? translation[1509]?.value : "Choose refund type in order to create a refund"}
                                                {/* Choose filters by status and segmentation type to tailor your customer segmentation view. */}

                                            </p>
                                        </div>
                                    </div>
                                    <div className="grid xs:grid-cols-2 gap-5">
                                        <div>
                                            <Dropwdown
                                                title={selectedLanguage?.name == "English" ? "Refund Type" : translation[1506]?.value ? translation[1506]?.value : "Refund Type"}
                                                placeholder={selectedLanguage?.name == "English" ? "Select Refund Type" : translation[1508]?.value ? translation[1508]?.value : "Select Refund Type"}
                                                value={refund_type}
                                                onChange={onChangeHandler}
                                                options={
                                                    [
                                                        {
                                                            label: "Cash Refund",
                                                            value: "Cash Refund",
                                                        },
                                                        {
                                                            label: "Credit Refund",
                                                            value: "Credit Refund",
                                                        }]
                                                }
                                                error={errors?.refund_type}
                                                name={'refund_type'}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-end">
                                        <LoginBtn
                                            onClick={handleNextClick}
                                            text={selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
                                        />
                                    </div>
                                </>
                            }
                        </div>
                        :
                        <RefundDetails
                            errors={errors}
                            loading={submitLoader}
                            open={showRefundTable}
                            translation={translation}
                            productList={selectedInvice}
                            refundState={refundState}
                            close={setShowRefundTable}
                            onSubmit={handleSubmitClick}
                            handleChange={onChangeHandler}
                            handleBackClick={handleBackClick}
                            selectedLanguage={selectedLanguage}
                            minEndDateLimit={minEndDateLimit}
                        />
                    }
                </Popup>
            }
        </>
    )
}

export default RefundPopup