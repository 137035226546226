import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from "new-components/input";
import LocationSelection from "new-components/location";
import Popup from "Components/TenantApp/Elements/Popup";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import AddProductBtn from "../AddHeaderBtns/AddProductBtn";
import ProductCard from "./ProductCard";
import { getAllProductsWithNoPagination, getsProductsImport } from "Adapters/Api/Products";
import Svgs from "Assets/svgs";
import Pagination from "new-components/Pagination";
import { useLocation } from "new-components/location/helper";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoProductFound from "Components/Utility/NoProductFound";
import useProducts from "./useProducts";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import TableShimmer_v2 from "Components/Utility/Shimmers/TableShimmer_v2";
import Shimmer from "Components/Utility/Shimmers/Shimmer";

// import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
// import AddCategoryBtn from "../AddHeaderBtns/AddCategoryBtn";
// import NotFound from "Components/Utility/NotFound";
// import { getProducts } from "Redux/TenantRedux/Actions/product/index";
// import { PRODUCT } from "Redux/TenantRedux/ActionTypes/productTypes";
// import TableShimer from "Components/Utility/Shimmers/TableShimer";

let timeOutId = undefined
const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locationDetail } = useLocation();
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const productPages = state?.product?.product_pages
  const allCount = state?.product?.allCount
  const { product, locations, business } = useSelector((state) => state);
  const { products, products_updated, allProducts } = product;
  const businessCurrency = business?.business;
  const { selected_location } = locations;

  const { fetchProductslist, currentPage, setCurrentPage, search_text, setSearchText, loader, noPaginationLoader,
    setNoPaginationLoader, setLoader } = useProducts();

  // const [currentPage, setCurrentPage] = useState(1);
  // const [search_text, setSearchText] = useState("");
  const [dataList, setDataList] = useState([]);
  const [ShowDetails, setShowDetails] = useState(-1);
  const [ImportProducts, setImportProducts] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [allProductsDataNoPagination, setAllProductsDataNoPagination] = useState([])
  const [employee, setEmployee] = useState();
  const [exportLoader, setExportLoader] = useState(false);
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  // // get product list
  // const fetchProductslist = async (currentPage, search_text) => {
  //   const response = await getsProducts(currentPage, search_text);
  //   if (response.status === 200) {
  //     let productList = response?.data?.response?.products;
  //     let pages = response?.data?.response?.pages
  //     let count = response?.data?.response?.count
  //     // dispatch({
  //     //   type: PRODUCT.GET_PRODUCT,
  //     //   payload: {
  //     //     data: productList
  //     //   },
  //     // })
  //     dispatch({
  //       type: PRODUCT.GET_PRODUCT_PAGES,
  //       payload: pages
  //     })
  //     dispatch({
  //       type: PRODUCT.GET_ALL_COUNT,
  //       payload: count
  //     })
  //     dispatch(getProducts(productList));
  //   }
  // };

  useEffect(() => {
    if (search_text === null) {
      // Initial load with empty search_text 
      fetchProductslist(currentPage, selected_location, "")
      dispatch(getAllProductsWithNoPagination("", selected_location, setNoPaginationLoader))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        fetchProductslist(currentPage, selected_location, search_text)
        dispatch(getAllProductsWithNoPagination(search_text, selected_location, setNoPaginationLoader))
      }, 500);
    }
  }, [currentPage, search_text, selected_location, selected_location])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  useEffect(() => {
    setLoader(true)
  }, [currentPage])

  useEffect(() => {
    setLoader(true)
    setCurrentPage(1)
  }, [selected_location])

  useEffect(() => {
    if (localStorage.getItem('Get_Products_after_Update')) {
      setCurrentPage(1)
      setTimeout(() => {
        localStorage.removeItem('Get_Products_after_Update')
      }, 500);
    }
  }, [localStorage.getItem('Get_Products_after_Update')])

  useEffect(() => {
    // if (allProducts?.length === 0) {
    //   fetchProductslist();
    //   // setDataList([])
    // } else {
    const val = allProducts?.map((element) => {
      const data = element?.location_quantities?.filter(
        ({ location }) => location === selected_location
      );
      if (data?.length > 0 && data !== undefined) return { ...element };
    });
    const productArr = val?.filter((element) => element !== undefined);
    setAllProductsDataNoPagination(productArr);
    // }
  }, [selected_location, allProducts?.length]);

  useEffect(() => {
    // this useEffect is for no pagination data for all products
    // if (products?.data?.length === 0) {
    //   dispatch(getAllProductsWithNoPagination(search_text, setNoPaginationLoader))
    // } else {
    const val = products?.map((element) => {
      const data = element?.location_quantities?.filter(
        ({ location }) => location === selected_location
      );
      if (data?.length > 0 && data !== undefined) return { ...element };
    });
    const productArr = val?.filter((element) => element !== undefined);
    setDataList(productArr);
    // }
  }, [selected_location, state.product.products, products, products?.length]);

  let headers = [
    { label: selectedLanguage?.name == "English" ? "Product Name" : translation[296]?.value ? translation[296]?.value : "Product Name", key: "name" },
    { label: selectedLanguage?.name == "English" ? "Cost price" : translation[941]?.value ? translation[941]?.value : "Cost price", key: "cost_price" },
    // { label: "Full Price", key: "full_price" },
    // { label: "Sell Price", key: "sell_price" },

    // { label: 'Sellable Quantity', key: 'sellable_quantity' },
    // { label: "Product Quantity", key: "consumable_quantity" },
    { label: selectedLanguage?.name == "English" ? "Category Name" : translation[892]?.value ? translation[892]?.value : "Category Name", key: "category" },
    { label: selectedLanguage?.name == "English" ? 'Brand Name' : translation[428]?.value ? translation[428]?.value : "Brand Name", key: "brand" },
    { label: selectedLanguage?.name == "English" ? 'Product Type' : translation[1006]?.value ? translation[1006]?.value : "Product Type", key: "product_type" },
    { label: selectedLanguage?.name == "English" ? "Barcode ID" : translation[934]?.value ? translation[934]?.value : "Barcode ID", key: "barcode_id" },
    { label: selectedLanguage?.name == "English" ? "Vendor" : translation[962]?.value ? translation[962]?.value : "Vendor", key: "vendor" },
    { label: selectedLanguage?.name == "English" ? "Retail Price" : translation[294]?.value ? translation[294]?.value : "Retail Price", key: "retail_price" },
    { label: selectedLanguage?.name == "English" ? "SKU" : translation[932]?.value ? translation[932]?.value : "SKU", key: "sku" },
    // { label: "Cost Price", key: "cost_price" },
    { label: selectedLanguage?.name == "English" ? "Product Size" : translation[945]?.value ? translation[945]?.value : "Product Size", key: "product_size" },
  ];

  const csvReport = {
    filename: "Product Records Catalog.csv",
    headers: headers,
    data: allProducts?.map((itm) => {
      // let selected_currency = state?.location?.locations.find(loc => loc.id == state?.locations?.selected_location)?.currency
      // let retail_price = itm?.currency_retail_price?.find(itm => itm.currency == selected_currency?.id)
      return {
        ...itm,
        // consumable_quantity: itm?.stocks[0]?.consumable_quantity
        //   ? itm.stocks[0]?.consumable_quantity
        //   : itm.stocks[0]?.sellable_quantity,
        category: itm?.category?.name ? itm?.category?.name : " ",
        brand: itm?.brand?.name ? itm?.brand?.name : " ",
        product_type: itm?.product_type ? itm?.product_type : " ",
        barcode_id: itm?.barcode_id ? itm?.barcode_id : " ",
        vendor: itm?.vendor?.vendor_name ? itm?.vendor?.vendor_name : " ",
        // retail_price: retail_price ? `${selected_currency.code} ${retail_price?.retail_price}` : `${selected_currency?.code ? selected_currency.code : ''} 0`,
        retail_price: (itm?.currency_retail_price && itm?.currency_retail_price?.length > 0) ? itm?.currency_retail_price?.filter(obj => obj.retail_price).map(price => ` ${price?.currency_code ? price?.currency_code : ''} ${price.retail_price}`) : `0`,
        sku: itm?.sku ? itm?.sku : " ",
        cost_price: itm?.cost_price ? itm?.cost_price : " ",
        product_size: itm?.size ? itm?.size : " ",
      };
    }),
  };

  const handleExportClick = () => {
    if (allProducts?.length > 0) {
      // setExportLoader(true)
      setTimeout(() => {
        toast.success("File downloaded successfully", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false)
        });
        setExportLoader(false)
      }, 300);
    } else {
      toast.error("No data to export", {
        toastId: "toast",
        onClose: () => setIsToastVisible(false),
      });
    }
  };

  //-------------------------------IMPORT CODE------------------------------------
  const submit = (file) => {
    let form_data = new FormData();
    form_data.append("file", file);
    getsProductsImport(form_data, () => {
      fetchProductslist();
    });
  };
  const PageSize = 10


  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <p className="text-[#7B7B7B] text-sm">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/");
            }}
          >
            {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          </span>{" "}
          &gt;{" "}
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/inventory-operations");
            }}
          >
            {selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations"}
          </span>{" "}
          &gt; <span className="text-primary font-semibold">
            {selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
          </span>
        </p>
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
            </h2>
            <LocationSelection />
          </div>
          {loadingPermission ?
            <Shimmer className={'w-32 lg:w-36'}>
              <div className="h-8 w-full  bg-gray-200 rounded-lg" />
            </Shimmer>
            : !employee ? (
              <div className="flex items-center sm:gap-4 gap-2">
                <AddProductBtn fill={true} />
                {/* <AddCategoryBtn /> */}
              </div>
            ) : (
              ""
            )}
        </div>

        <div className="border rounded-lg">
          <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 ">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
                </h2>

                {(!products_updated || loader) ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">{allCount ? allCount : 0}{' '}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"
                        : selectedLanguage?.name == "English" ? "Product" : translation[1316]?.value ? translation[1316]?.value : "Product"
                      }
                    </p>
                  </div>
                }
              </div>
              <div className="mb-00">
                {loadingPermission ?
                  <Shimmer className={'w-44 lg:w-48'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                  </Shimmer>
                  :
                  <Input
                    placeholder={selectedLanguage?.name == "English" ? "Search Product" : translation[135]?.value ? translation[135]?.value : "Search Product"}
                    value={search_text}
                    padding="py-1"
                    leftIcon={<Svgs.Search />}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                }
              </div>
            </div>
            {loadingPermission ?
              <Shimmer className={'w-12 lg:w-16'}>
                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
              </Shimmer>
              : exportLoader ? (
                <div className="h-[4rem] flex items-center justify-center">
                  <PreloaderSm />
                </div>
              ) :
                !employee ? (
                  products?.length > 0 ?
                    <div className="flex items-center lg:justify-center justify-end gap-3 w-fit mb-00">
                      {/* <input
                  type="file"
                  accept=".csv"
                  id="csvFileforproduct"
                  onChange={(e) => {
                    submit(e.target.files[0]);
                    setImportProducts(false);
                  }}
                  hidden
                  value={""}
                />
                <LoginBtn
                  className="!px-3 !py-2 rounded-lg border border-primary text-sm"
                  bg="bg-white"
                  
                  onClick={() => {
                    setImportProducts(true);
                  }}
                >
                  <div className="flex items-center gap-2 text-primary">
                    <Svgs.Import />
                  </div>
                </LoginBtn> */}
                      <CSVLink
                        {...csvReport}
                        target="_blank"
                        onClick={handleExportClick}
                      >
                        <IconButton filled>
                          <Svgs.Export />
                        </IconButton>
                      </CSVLink>
                    </div> :
                    <LoginBtn
                      className="rounded-lg border-2 border-primary"
                      bg="bg-white"
                      animation={false}
                      onClick={handleExportClick}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.Export bg="#FFFFFF" />
                      </div>
                    </LoginBtn>
                ) : (
                  ""
                )}
          </div>
          {(!products_updated || loader) ?
            <TableShimmer_v2 cols={7} rows={10}/>
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(7,minmax(155px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                <p>{selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}</p>
                <p>{selectedLanguage?.name == "English" ? "Current Stock" : translation[998]?.value ? translation[998]?.value : "Current Stock"}</p>
                <p>{selectedLanguage?.name == "English" ? "Transferred" : translation[999]?.value ? translation[999]?.value : "Transferred"}</p>
                <p>{selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}</p>
                <p>{selectedLanguage?.name == "English" ? "Consumed" : translation[1000]?.value ? translation[1000]?.value : "Consumed"}</p>
                <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
                {!employee ? (
                  <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={`odd-bg text-[#3A3A3A] transition-all order-l-[0px]`}>
                {/* previous search filter
            ?.filter((itm) => {
                  if (
                    itm?.name
                      ?.toLowerCase()
                      .includes(search_text.toLowerCase()) ||
                    itm?.category?.name
                      .toLowerCase()
                      .includes(search_text.toLowerCase()) ||
                    itm?.brand?.name
                      .toLowerCase()
                      .includes(search_text.toLowerCase()) ||
                    itm?.product_type
                      .toLowerCase()
                      .startsWith(search_text.toLowerCase())
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }) */}
                {dataList &&
                  dataList?.length > 0 ? (
                  dataList?.map((item, i) => {
                    return (
                      <ProductCard
                        fetchProductslist={fetchProductslist}
                        setCurrentPage={setCurrentPage}
                        search_text={search_text}
                        currency={locationDetail?.currency?.code ? locationDetail?.currency?.code : "AED"}
                        item={item}
                        key={i}
                        translation={translation}
                        selectedLanguage={selectedLanguage}
                        ShowDetails={ShowDetails}
                        setShowDetails={setShowDetails}
                        selected_location={selected_location}
                        hideDeleteIcon={dataList?.length == 1 ? true : false}
                        setNoPaginationLoader={setNoPaginationLoader}
                      />
                    );
                  })
                ) : (
                  <NoProductFound />
                )}
              </div>

              {(dataList &&
                dataList?.length > 0 && !loader) &&
                <Pagination
                  currentPage={currentPage}
                  totalCount={Math.ceil(productPages)}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />}
            </div>
          }
        </div>
      </div>

      <Popup
        heading={`Import Product`}
        close={setImportProducts}
        open={ImportProducts}
      >
        <div className="flex flex-col gap-4 border-[#A1A1A1] border-dashed border-2 p-4 rounded-lg text-center">
          <div className="h-[15rem]">
            <Svgs.UploadList />
          </div>
          <p className="text-[#595959]">Drop your .CSV file to add prodcuts</p>
          <p className="text-[#595959]">Or</p>
          <LoginBtn size="sm" className="mx-auto">
            <label htmlFor="csvFileforproduct" className="cursor-pointer">
              <div className="flex items-center gap-2 text-[#ffffff]">
                Upload New List
              </div>
            </label>
          </LoginBtn>
        </div>
      </Popup>
    </>
  );
};

export default Products;
