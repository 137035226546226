import React, { useState, useEffect } from "react";

import AccountSelectionCard from "./AccountSelectionCard";

import Svgs from "Assets/svgs/index";
import { useDispatch, useSelector } from "react-redux";
import { getProfileCompletion } from "Adapters/Api/Business";
// import { LanguageConstructor } from "LanguageConstructor";
// import { DashboardAccount_Language } from "../Language/Dashboard/DashboardAccount_Language";

const DashboardAccount = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state);
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [profileCompletion, setProfileCompletion] = useState({})

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    if (state?.business?.business?.id) {
      dispatch(
        getProfileCompletion({ business_id: state?.business?.business?.id })
      )
    }
  }, [state?.business?.business])

  useEffect(() => {
    if (state.business.profile_completion) {
      setProfileCompletion(state.business.profile_completion)
    }
  }, [state?.business?.profile_completion])

  // useEffect(() => {
  //   setTHIS_LANGUAGE(LanguageConstructor(DashboardAccount_Language));
  // }, [])



  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
      <div className="h-full w-full flex lg:justify-center justify-between items-center flex-col gap-4">
        <div className="flex items-center gap-5 lg:w-fit w-full lg:p-0 p-3">
          <h2 className="font-semibold text-2xl">
            {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          </h2>
        </div>
        <div className="flex flex-col gap-4 w-full items-center">
          <p className="text-[#7B7B7B] text-center text-sm leading-[1.6] lg:block hidden ">
            {selectedLanguage?.name == "English" ? "Your account helps you do more by personalizing" : translation[641]?.value ? translation[641]?.value : "Your account helps you do more by personalizing"}
            {" "} <br />
            {selectedLanguage?.name == "English" ? "your NStyle experience." : translation[643]?.value ? translation[643]?.value : "your NStyle experience."}
          </p>
          {!employee ||
            (employee &&
              (employeePermissions?.account_root_access?.includes("view")
                || employeePermissions?.account_business_setting?.includes("edit")
                || employeePermissions?.account_finance_setting?.includes("edit")
                || employeePermissions?.account_service_management?.includes("edit")
                || (employeePermissions?.account_notification_setting?.includes("edit")
                  || employeePermissions?.pos_root_access?.includes("view"))))
            ? <AccountSelectionCard
              link="setup"
              heading={selectedLanguage?.name == "English" ? "Account Setup" : translation[645]?.value ? translation[645]?.value : "Account Setup"}
              paragraph={selectedLanguage?.name == "English" ? "Manage your business category, team size, and other business info." : translation[646]?.value ? translation[646]?.value : "Manage your business category, team size, and other business info."}
              icon={<Svgs.Account_Setup />}
            />
            : ''}

          {!employee ||
            (employee &&
              (employeePermissions?.inventory_root_access?.includes("view")
                || employeePermissions?.inventory_purchase_order?.includes("view")
                || employeePermissions?.inventory_purchase_order?.includes("edit")
                || employeePermissions?.inventory_purchase_order?.includes("create")
                || employeePermissions?.inventory_goods_receipt?.includes("view")
                || employeePermissions?.inventory_goods_receipt?.includes("edit")
                || employeePermissions?.inventory_goods_receipt?.includes("create")
                || employeePermissions?.inventory_consumption?.includes("view")
                || employeePermissions?.inventory_consumption?.includes("edit")
                || employeePermissions?.inventory_consumption?.includes("create")
                || employeePermissions?.inventory_stock_transfer?.includes("view")
                || employeePermissions?.inventory_stock_transfer?.includes("edit")
                || employeePermissions?.inventory_stock_transfer?.includes("create")
                || employeePermissions?.inventory_stock_report?.includes("view")
                || employeePermissions?.inventory_report?.includes("view")
                || employeePermissions?.pos_root_access?.includes("view"))) ? (

            <AccountSelectionCard
              link="inventory-operations"
              heading={selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations"}
              paragraph={selectedLanguage?.name == "English" ? "Manage vendors, products, stocks, purchases, product sales, and stock reports." : translation[647]?.value ? translation[647]?.value : "Manage vendors, products, stocks, purchases, product sales, and stock reports."}
              icon={<Svgs.Inventory_Operations />}
            />
          ) : ''}

          {!employee ||
            (employee && (employeePermissions?.pos_root_access?.includes("view")
              || employeePermissions?.target_control_staff?.includes("view")
              || employeePermissions?.target_control_staff?.includes("create")
              || employeePermissions?.target_control_staff?.includes("edit")
              || employeePermissions?.target_control_store?.includes("view")
              || employeePermissions?.target_control_store?.includes("create")
              || employeePermissions?.target_control_store?.includes("edit")
              || employeePermissions?.target_control_service?.includes("view")
              || employeePermissions?.target_control_service?.includes("create")
              || employeePermissions?.target_control_service?.includes("edit")
              || employeePermissions?.target_control_retail?.includes("view")
              || employeePermissions?.target_control_retail?.includes("create")
              || employeePermissions?.target_control_retail?.includes("edit")))
            ? <AccountSelectionCard
              link="target-control"
              heading={selectedLanguage?.name == "English" ? "Target Control" : translation[648]?.value ? translation[648]?.value : "Target Control"}
              paragraph={selectedLanguage?.name == "English" ? "Set the target for staff, store, service and retail." : translation[650]?.value ? translation[650]?.value : "Set the target for staff, store, service and retail."}
              icon={<Svgs.Target_Control />}
            />
            : ""}
          <AccountSelectionCard
            link="expenses"
            heading={selectedLanguage?.name == "English" ? "Expenses" : translation[1603]?.value ? translation[1603]?.value : "Expenses"}
            paragraph={selectedLanguage?.name == "English" ? "Set the target for staff, store, service and retail." : translation[650]?.value ? translation[650]?.value : "Set the target for staff, store, service and retail."}
            icon={<Svgs.ExpenseIcon />}
          />

          {(employee &&
            employeePermissions?.account_root_access?.includes("view") ||
            employeePermissions?.account_service_management?.includes("edit") ||
            employeePermissions?.pos_root_access?.includes("view")) ||
            !employee ? (
            <AccountSelectionCard
              link="service-management"
              icon={<Svgs.ServiceManagement width="3.7rem" height="3.7rem" />}
              heading={selectedLanguage?.name == "English" ? "Service Management" : translation[660]?.value ? translation[660]?.value : "Service Management"}
              paragraph={selectedLanguage?.name == "English" ? "Manage services, and service groups." : translation[661]?.value ? translation[661]?.value : "Manage services, and service groups."}
              showProgress={true}
              progressData={profileCompletion?.service_management}
            />
          ) : (
            ""
          )}
        </div>
        <div className="lg:hidden block"></div>
      </div>
    </div>
  );
};

export default DashboardAccount;
