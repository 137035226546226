import axios from "axios";
import {
  BASE_URL,
  get_purchase_order_list,
  create_orderstock,
  delete_purchase_order,
  update_orderstock,
  update_orderstock_status,
} from "../api_variables";
import { getPurchasesWithNoPagination } from "Redux/TenantRedux/Actions/purchaseOrderActions";

export const getPurchaseOrder = async (selected_location, currentPage, search_text, setLoader, access_token) => {
  setLoader && setLoader(true)
  const location = selected_location ? `?location_id=${selected_location}` : ""
  const current_Page = currentPage ? `&page=${currentPage}` : '';
  const search = search_text ? `&search_text=${search_text}` : ''
  try {
    const result = await axios.get(`${BASE_URL}${get_purchase_order_list}${location}${current_Page}${search}`, {
      headers: {
        Authorization: `Token ${access_token}`,
      },
    });
    setLoader && setLoader(false)
    return result;
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getPurchaseOrderWithNoPagination = (selected_location, search_text, setNoPaginationLoader) => async (dispatch) => {
  setNoPaginationLoader && setNoPaginationLoader(true)
  const location = selected_location ? `&location_id=${selected_location}` : ""
  const search = search_text ? `&search_text=${search_text}` : ''
  try {
    const result = await axios.get(`${BASE_URL}${get_purchase_order_list}?no_pagination=true${location}${search}`);
    if (dispatch) {
      const data = result.data.response.stocks?.filter(
        (ele) => ele?.to_location === selected_location
      );
      dispatch(getPurchasesWithNoPagination(data))
      setNoPaginationLoader && setNoPaginationLoader(false)
    }
    setNoPaginationLoader && setNoPaginationLoader(false)
    return result;
  } catch (error) {
    setNoPaginationLoader && setNoPaginationLoader(false)
    return error;
  }
};

export const deletePurchaseOrder = async (id, access_token) => {
  try {
    const result = await axios.delete(`${BASE_URL}${delete_purchase_order}`, {
      headers: {
        Authorization: `Token ${access_token}`,
      },
      data: { id: id },
    });
    return result;
  } catch (error) {
    return error;
  }
};

export const createOrderStock = async (orderStock_data, access_token) => {
  try {
    const result = await axios.post(
      `${BASE_URL}${create_orderstock}`,
      orderStock_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const updateOrderStock = async (orderStock_data, access_token) => {
  try {
    const result = await axios.put(
      `${BASE_URL}${update_orderstock}`,
      orderStock_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const updateOrderStockStatus = async (orderStock_data, access_token) => {
  try {
    const result = await axios.put(
      `${BASE_URL}${update_orderstock_status}`,
      orderStock_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};
