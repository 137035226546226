import { STAFF_GROUPS } from "../../ActionTypes/staffGroupsTypes";


export const getStaffGroups = (data) => {
    return {
        type: STAFF_GROUPS.GET_STAFF_GROUPS,
        payload: data
    }
}

export const getStaffGroupsWithoutPagination = (data) => {
    return {
        type: STAFF_GROUPS.GET_STAFF_GROUPS_WITHOUT_PAGINATION,
        payload: data
    }
}


export const addStaffGroup = (data) => {
    return {
        type: STAFF_GROUPS.ADD_STAFF_GROUP,
        payload: data
    }
}


export const editStaffGroup = (data) => {
    return {
        type: STAFF_GROUPS.UPDATE_STAFF_GROUP,
        payload: data
    }
}


export const removeStaffGroup = (data) => {
    return {
        type: STAFF_GROUPS.DELETE_STAFF_GROUP,
        payload: data
    }
}