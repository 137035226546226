import React from 'react'
import useTrainerComments from './helper'
import Popup from 'Components/TenantApp/Elements/Popup'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import Textarea from 'new-components/text-area'

const FeedbackPopup = ({ open, close, data, onSuccessCall }) => {
    const {
        translation, selectedLanguage,
        trainingData, errors, loading, onChangeHandler, onSubmitHandler
    } = useTrainerComments(data, onSuccessCall, close)
    return (
        <>
            <Popup
                open={open}
                onclose={close}
                heading={selectedLanguage?.name == "English" ? "Add Feedback" : translation[1715]?.value ? translation[1715]?.value : "Add Feedback"}
            >
                <div className='flex flex-col gap-5'>
                    <div>
                        <Textarea
                            title={selectedLanguage?.name == "English" ? "Add Feedback" : translation[1715]?.value ? translation[1715]?.value : "Add Feedback"}
                            rows={5}
                            limit={150}
                            placeholder={selectedLanguage?.name == "English" ? "Add text ..." : translation[1571]?.value ? translation[1571]?.value : "Add text ..."}
                            required={true}
                            name={'manager_feedback'}
                            value={trainingData?.manager_feedback}
                            onChange={onChangeHandler}
                            onEnterSubmit={onChangeHandler}
                            errorMessage={errors?.manager_feedback}
                        />
                    </div>
                    <div className='flex justify-end items-center'>
                        <LoginBtn
                            className={"!px-6"}
                            onClick={() => {
                                onSubmitHandler('feedback')
                            }}
                            loading={loading}
                            disabled={loading}
                            text={selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"} />
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default FeedbackPopup