import { PRODUCT } from "../../ActionTypes/productTypes";
import { CATEGORIES } from '../../ActionTypes/categoryTypes/index'
import { DELETE_BUSINESS_BRAND, UPDATE_BUSINESS_BRAND } from "../../ActionTypes/BrandTypes";
import { VENDORS } from "../../ActionTypes/vendorsTypes";
const inventoryProduct = {
  products: [],
  productDropdown: [],
  productPagination: {},
  productDropdownWithoutSearch: [],
  allProducts: [],
  product_pages: 0,
  allCount: 0,
  products_updated: false,
  product_stock: [],
  product_stock_pages: 0,
  product_stock_allCount: 0,
  products_stock_updated: false,
  stocks: [],
  allStocks: [],
  allCountStock: 0,
  pagesStock: 0,
  stocks_updated: false,
  filtered_stocks: [],
  order_stock: [],
  order_stock_updated: false,
  stock_filters: false,
  insights: {
    chart_products: {
      data: [],
      is_updated: false
    },
    products: {
      data: [],
      is_updated: false,
    }
  },
  order_stock_product: [],
  order_stock_product_updated: false
};

const ProductReducer = (state = inventoryProduct, action) => {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT.GET_INSIGHT_PRODUCTS_CHART:
      return {
        ...state,
        insights: {
          ...state.insights,
          chart_products: {
            data: payload.products,
            is_updated: true
          },
        }
      }

    case PRODUCT.GET_INSIGHT_PRODUCTS:
      return {
        ...state,
        insights: {
          ...state.insights,
          products: {
            data: payload.products,
            is_updated: true,
          }
        }
      }
    case VENDORS.DELETE_VENDOR:
      return {
        ...state,
        products: [
          ...state.products.map(prod => {
            if (prod?.vendor && prod?.vendor?.id == payload) {
              delete prod.vendor
              return {
                ...prod
              }
            }
            return prod
          })
        ]
      }
    case CATEGORIES.DELETE_CATEGORY:
      return {
        ...state,
        products: [
          ...state.products.map(prod => {
            if (prod?.category && prod?.category?.id == payload) {
              delete prod.category
              return {
                ...prod
              }
            }
            return prod
          })
        ]
      }
    case DELETE_BUSINESS_BRAND:
      return {
        ...state,
        products: [
          ...state.products.map(prod => {
            if (prod?.brand && prod?.brand?.id == payload.id) {
              delete prod.brand
              return {
                ...prod
              }
            }
            return prod
          })
        ]
      }

    case CATEGORIES.UPDATE_CATEGORY:
      return {
        ...state,
        products: [
          ...state.products.map((prod) => {
            if (prod.category && prod.category.id == action.payload.id) {
              return {
                ...prod,
                category: action.payload
              }
            }
            return prod
          })
        ]
      }

    case UPDATE_BUSINESS_BRAND:
      return {
        ...state,
        products: [
          ...state.products.map((prod) => {
            if (prod.brand && prod.brand.id == action.payload.id) {
              return {
                ...prod,
                brand: action.payload
              }
            }
            return prod
          })
        ]
      }

    case VENDORS.UPDATE_VENDOR:
      return {
        ...state,
        products: [
          ...state.products.map((prod) => {
            if (prod.vendor && prod.vendor.id == action.payload.id) {
              return {
                ...prod,
                vendor: action.payload
              }
            }
            return prod
          })
        ]
      }

    case PRODUCT.DELETE_PRODUCT_STOCKS:
      return {
        ...state,
        stocks: [
          ...state.stocks.filter(stock => stock.id != action.payload.id)
        ],
        stocks_updated: true
      }

    case PRODUCT.REMOVE_STOCK_FILTERS:
      return {
        ...state,
        stock_filters: false
      }
    case PRODUCT.GET_FILTER_PRODUCT_STOCKS:
      return {
        ...state,
        filtered_stocks: action.payload.data,
        stock_filters: true
      }
    case PRODUCT.CREATE_ORDER_STOCK:
      return {
        ...state,
        order_stock: action.payload.data,
      }
    case PRODUCT.GET_PRODUCT_STOCK_REPORT:
      return {
        ...state,
        product_stock: action.payload?.response?.product_stock_report,
        products_stock_updated: true,
        product_stock_pages: action.payload?.pages,
        product_stock_allCount: action.payload?.count
      }
    case PRODUCT.GET_REFUND_REPORT:
      console.log("🚀 ~ ProductReducer:", action.payload)
      return {
        ...state,
        refund: action.payload?.data,
        refund_current_page: action.payload?.current_page,
        refund_total_pages: action.payload?.total_pages,
        refund_allCount: action.payload?.count
      }

    case PRODUCT.GET_PRODUCT_STOCKS:
      return {
        ...state,
        stocks: action.payload.data,
        stocks_updated: true
      }

    case PRODUCT.GET_PRODUCT_STOCKS_PAGINATION_DATA:
      return {
        ...state,
        allCountStock: action.payload.data?.count,
        pagesStock: action.payload.data?.pages
      }

    case PRODUCT.GET_PRODUCT_STOCKS_NO_PAGINATION:
      return {
        ...state,
        allStocks: action.payload.data,
        stocks_updated: true
      }
    case PRODUCT.GET_PRODUCT:
      return {
        ...state,
        products: payload,
        products_updated: true,
      };
    case PRODUCT.GET_PRODUCT_WITH_NO_PAGINATION:
      return {
        ...state,
        allProducts: payload,
      };
    case PRODUCT.GET_PRODUCT_PAGES:
      return {
        ...state,
        product_pages: payload,
        products_updated: true,
      };
    case PRODUCT.GET_ALL_COUNT:
      return {
        ...state,
        allCount: payload,
        products_updated: true,
      };
    case PRODUCT.GET_PRODUCT_UPDATED:
      return {
        ...state,
        products_updated: false,
      };

    case PRODUCT.GET_ORDER_STOCK:
      return {
        ...state,
        order_stock: payload,
        order_stock_updated: true,

      };

    case PRODUCT.GET_PRODUCT_ORDER_STOCK:
      return {
        ...state,
        order_stock_product: payload,
        order_stock_product_updated: true,

      };

    case PRODUCT.GET_PRODUCT_DROPDOWN_PAGINATION:
      // Assuming payload?.response?.products is an array of objects
      const uniqueProductsWithSearch = Array.from(
        new Set([...state?.productDropdown, ...payload?.response?.products].map(product => product.id)))?.map(id => {
          return [...state?.productDropdown, ...payload?.response?.products].find(product => product.id === id);
        });

      const uniqueProductsWithoutSearch = Array.from(
        new Set([...state?.productDropdownWithoutSearch, ...payload?.response?.products]?.map(product => product.id))).map(id => {
          return [...state?.productDropdownWithoutSearch, ...payload?.response?.products]?.find(product => product.id === id);
        });

      return {
        ...state,
        productDropdown: payload?.is_searched
          ? (Math.ceil(+payload?.pages) > 1 && +payload?.current_page > 1)
            ? uniqueProductsWithSearch
            : payload?.response?.products
          : uniqueProductsWithoutSearch,

        productDropdownWithoutSearch: payload?.current_page == 1 ? payload?.response?.products
          : [...state?.productDropdownWithoutSearch, ...payload?.response?.products],

        productPagination: {
          total: payload?.count,
          total_pages: Math.ceil(+payload?.pages),
          currentPage: +payload?.current_page,
          isSearched: payload?.is_searched
        }
      }

    case PRODUCT.ADD_PRODUCT:
      return {
        ...state,
        products: [{ ...payload, location_quantities: payload.stocks }, ...state.products],
        products_updated: true,
        stocks: [
          ...state.stocks,
          {
            ...payload,
            stock: {
              ...payload.stocks[0],
              available_stock: payload.stocks[0].quantity,
              sale_status: "High",
              sold_stock: 0,
              status: true,
              status_text: "In Stock",
              turnover: "Highest",
              usage: 100,
              price: payload.full_price
            }
          }
        ]
      };

    case PRODUCT.DELETE_ORDER_STOCK:
      let results = state?.order_stock?.filter((item) => item.id !== payload);
      return {
        ...state,
        order_stock: [...results],
        // stocks: [
        //   ...state.stocks.filter(prod => prod.id != payload)
        // ]
      };

    case PRODUCT.GET_PRODUCT:
      let result = state?.products?.filter((item) => item.id !== payload);
      return {
        ...state,
        products: [...result],
        stocks: [
          ...state.stocks.filter(prod => prod.id != payload)
        ]
      };

    case PRODUCT.UPDATE_PRODUCT:
      let updateList = state?.products?.map((item) => {
        if (item.id === payload.id) {
          return payload
        } else {
          return item;
        }
      })

      return {
        ...state,
        products: [...updateList],
        stocks: state?.stocks?.map(prod => {

          if (prod.id == payload.id) {
            return {
              brand: payload.brand,
              category: payload.category,
              vendor: payload.vendor,
              cost_price: payload.cost_price,
              full_price: payload.full_price,
              sell_price: payload.sell_price,
              name: payload.name,
              id: payload.id,
              stock: {
                ...prod.stock,
                available_stock: payload.quantity,
                price: payload.full_price,
                quantity: payload.quantity,
              }
            }
          }
          return prod
        })
      };

    case PRODUCT.DELETE_PRODUCT:
      return {
        ...state,
        products: [
          ...state.products.filter(prdct => prdct.id != payload)
        ],
      }

    case PRODUCT.UPDATE_ORDER_STOCK:
      let updatEorderstock = state?.products?.order_stock?.map((item) => {
        if (item.id === payload.id) {
          return payload
        } else {
          return item;
        }
      })

    default:
      return state;
  }
};

export default ProductReducer;
