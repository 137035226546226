import { useState } from 'react'
import useValidations from './../../../../Hooks/useValidations';

export default function useMobileVerifyForm() {
    const { removeWhiteSpacesAndSpecialCharactors } = useValidations();

    // initials state for verification code fields 
    const [verificationCode, setVerificationCode] = useState({
        num1: "",
        num2: "",
        num3: "",
        num4: "",
    });

    // set verification code state here 
    const onChangeHandler = (e) => {
        e.target.value = removeWhiteSpacesAndSpecialCharactors(e.target.value)
        const { name, value } = e.target;

        if (value.length > 1) return false;
        setVerificationCode((prev) => ({
            ...prev,
            [name]: value
        }));

    }

    return {
        setVerificationCode,
        verificationCode,
        onChangeHandler,
    }
}
