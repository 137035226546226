/* eslint-disable array-callback-return */
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import useValidations from "Hooks/useValidations";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getEmployeeDropDownList, getEmployeeDropDownListPagination, getEmployeeList } from "Adapters/Api/employee";
import {
  createCommission,
  deleteCommission,
  getCommissions,
  getCommissionsWithNoPagination,
} from "Adapters/Api/commissions";
import { updateCommission } from "../../../../../Adapters/Api/commissions/index";
import { apiErrorMessage } from "Constants/Data/Errors";
import { useRef } from "react";
import useObjectKeys from "Hooks/useObjectKeys";

let timeOutId = undefined
let employeeTimeOutId = undefined
function useCommission() {
  const formRef = useRef()
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const business_data = getDataFromLocalStorage("business_data");
  const access_token = getDataFromLocalStorage("access_token");
  const { setErrors, errors, onBlurHandler, validation } = useValidations();
  const { objectKeyConvertToArray } = useObjectKeys()
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const [employeeSearch, setEmployeeSearch] = useState("");
  const [employeePage, setEmployeePage] = useState(1)
  const [employeeLoader, setEmployeeLoader] = useState(false)

  const employeeList = state?.employee?.employeesDropdown;
  const employeePaginationData = state?.employee?.employeeDropdownPaginationData

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  // const employeeList = state?.employee?.employees;
  const commissionList = state?.employee?.commissions;
  const allCommissionList = state?.employee?.allCommissions;
  const pages = state?.employee?.commissionPages;
  const allCount = state?.employee?.commissionAllCount
  const commissions_updated = state?.employee?.commissions_updated;
  const currency = state?.business?.business?.currency?.code;
  const selected_location = state?.locations?.selected_location;
  const currencyOnSelectedLocation = state?.locations?.locations?.find((obj) => obj?.id === selected_location);

  const [isLoading, setIsLoading] = useState(false);
  const [is_delete, setDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [AddCommission, setAddCommission] = useState(false);
  const [searchText, setSearchText] = useState();
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [loader, setLoader] = useState(true)
  const [noPaginationLoader, setNoPaginationLoader] = useState(true)
  const [submitButtonClick, setSubmitButtonClick] = useState(false)
  const [exportLoader, setExportLoader] = useState(false);
  const [commissions, setCommissions] = useState({
    employee: "",
    commission_cycle: "",
    service_comission: [],
    service_comission_symbol: '%',
    product_comission: [],
    product_comission_symbol: '%',
    voucher_comission: [],
    voucher_comission_symbol: '%',
  });



  //handle change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCommissions((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };
  useEffect(() => {
    if (commissions?.id) {
      setIsEdit(true)
    } else {
      setIsEdit(false);
    }
  }, [commissions?.id])

  // commission details  submit here
  const onSubmitCommission = () => {
    setSubmitButtonClick(true)
    const requiredFields = {
      employee: commissions.employee
    }
    setErrors(validation(requiredFields));
    let result = validation(requiredFields);
    if (objectKeyConvertToArray(result)?.length === 0) {
      let submit = false
      let serviceValid = true
      let productValid = true
      let voucherValid = true

      if (commissions?.service_comission?.length > 0 || commissions?.voucher_comission?.length > 0 || commissions?.product_comission?.length > 0) {
        submit = true
      }

      if (commissions?.service_comission?.length > 0) {
        if (commissions?.service_comission?.length > 0 && commissions?.service_comission?.every(
          (obj) => obj?.commission && obj?.to_value && obj?.from_value && (obj?.to_value > obj?.from_value)
        )) {
          serviceValid = true
        } else {
          serviceValid = false
        }
      }

      if (commissions?.voucher_comission?.length > 0) {
        if (commissions?.voucher_comission?.length > 0 && commissions?.voucher_comission?.every(
          (obj) => obj?.commission && obj?.to_value && obj?.from_value && (obj?.to_value > obj?.from_value)
        )) {
          voucherValid = true
        } else {
          voucherValid = false
        }
      }

      if (commissions?.product_comission?.length > 0) {
        if (commissions?.product_comission?.length > 0 && commissions?.product_comission?.every(
          (obj) => obj?.commission && obj?.to_value && obj?.from_value && (obj?.to_value > obj?.from_value)
        )) {
          productValid = true
        } else {
          productValid = false
        }
      }

      if (submit && serviceValid && voucherValid && productValid) {
        setIsLoading(true);
        let service_commission = commissions.service_comission.map((obj) => {
          // let symobol = commissions.service_comission_symbol !== '%'? 'symbol' : 'symbol';
          if (obj.isDeleted !== 'True' && obj !== null)
            return {
              ...obj,
              ['symbol']: commissions.service_comission_symbol
            }
        })

        let product_commission = commissions.product_comission.map((obj) => {
          // let symobol = commissions.product_comission_symbol !== '%'? 'symbol' : 'symbol';
          if (obj.isDeleted !== 'True' && obj !== null)
            return {
              ...obj,
              ['symbol']: commissions.product_comission_symbol
            }
        })

        let voucher_commission = commissions.voucher_comission.map((obj) => {
          // let symobol = commissions.voucher_comission_symbol !== '%'? 'symbol' : 'symbol';
          if (obj.isDeleted !== 'True' && obj !== null)
            return {
              ...obj,
              ['symbol']: commissions.voucher_comission_symbol
            }
        })
        let { employee, commission_cycle } = commissions;
        let payload = {
          service_comission: service_commission,
          product_comission: product_commission,
          voucher_comission: voucher_commission,
          employee: employee,
          commission_cycle: commission_cycle
        }
        payload &&
          dispatch(
            createCommission(
              { ...payload, business: business_data?.id },
              access_token,
              (message) => {
                setIsLoading(false)
                toast.success(message ? message : "Success", { toastId: "toast" });
                setCurrentPage(1)
                dispatch(getCommissions(selected_location, currentPage, searchText, setLoader))
                dispatch(getCommissionsWithNoPagination(selected_location, searchText, setNoPaginationLoader))
                setAddCommission(false);
                setSubmitButtonClick(false)
                setCommissions({
                  employee: "",
                  commission_cycle: "",
                  service_comission: [],
                  service_comission_symbol: '%',
                  product_comission: [],
                  product_comission_symbol: '%',
                  voucher_comission: [],
                  voucher_comission_symbol: '%',
                });
              },
              () => {
                setIsLoading(false);
                toast.error("Error", { toastId: "toast" });
              }
            )
          );
      } else {
        formRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  //update commission here
  const onUpdateCommission = () => {
    setSubmitButtonClick(true)
    const requiredFields = {
      employee: commissions.employee
    }
    setErrors(validation(requiredFields));
    let result = validation(requiredFields);

    if (objectKeyConvertToArray(result)?.length === 0) {
      let submit = false
      let serviceValid = true
      let productValid = true
      let voucherValid = true

      if (commissions?.service_comission?.length > 0 || commissions?.voucher_comission?.length > 0 || commissions?.product_comission?.length > 0) {
        submit = true
      }

      if (commissions?.service_comission?.length > 0) {
        if (commissions?.service_comission?.length > 0 && commissions?.service_comission?.every(
          (obj) => obj?.commission && obj?.to_value && obj?.from_value && (obj?.to_value > obj?.from_value)
        )) {
          serviceValid = true
        } else {
          serviceValid = false
        }
      }

      if (commissions?.voucher_comission?.length > 0) {
        if (commissions?.voucher_comission?.length > 0 && commissions?.voucher_comission?.every(
          (obj) => obj?.commission && obj?.to_value && obj?.from_value && (obj?.to_value > obj?.from_value)
        )) {
          voucherValid = true
        } else {
          voucherValid = false
        }
      }

      if (commissions?.product_comission?.length > 0) {
        if (commissions?.product_comission?.length > 0 && commissions?.product_comission?.every(
          (obj) => obj?.commission && obj?.to_value && obj?.from_value && (obj?.to_value > obj?.from_value)
        )) {
          productValid = true
        } else {
          productValid = false
        }
      }

      if (submit && serviceValid && voucherValid && productValid) {
        setIsLoading(true);
        let service_commission = commissions.service_comission.map((obj) => {
          if (obj)
            return {
              ...obj,
              ['symbol']: commissions.service_comission_symbol
            }
        })

        let product_commission = commissions.product_comission.map((obj) => {
          if (obj)
            return {
              ...obj,
              ['symbol']: commissions.product_comission_symbol
            }
        })

        let voucher_commission = commissions.voucher_comission.map((obj) => {
          if (obj)
            return {
              ...obj,
              ['symbol']: commissions.voucher_comission_symbol
            }
        })
        let { employee, commission_cycle } = commissions;
        let payload = {
          id: commissions?.id,
          service_comission: service_commission,
          product_comission: product_commission,
          voucher_comission: voucher_commission,
          employee: employee,
          commission_cycle: commission_cycle
        }
        dispatch(
          updateCommission(
            payload,
            access_token,
            (message) => {
              toast.success(message, { toastId: "toast" });
              setCurrentPage(1)
              dispatch(getCommissions(selected_location, currentPage, searchText, setLoader))
              dispatch(getCommissionsWithNoPagination(selected_location, searchText, setNoPaginationLoader))
              setAddCommission(false);
              setIsEdit(false);
              setIsLoading(false);
              setSubmitButtonClick(false)
              setCommissions({
                employee: "",
                commission_cycle: "",
                service_comission: [],
                service_comission_symbol: '%',
                product_comission: [],
                product_comission_symbol: '%',
                voucher_comission: [],
                voucher_comission_symbol: '%',
              });
            },
            () => {
              toast.error(apiErrorMessage, { toastId: "toast" });
            }
          )
        );
      } else {
        formRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  //set state of edit records
  const onEditStateManageHandler = (id) => {
    setIsEdit(true);
    const selected_commission = commissionList?.find((itm) => itm?.id === id);
    let service_comission = [];
    let retail_comission = [];
    let voucher_comission = [];
    selected_commission?.category_comission?.map((obj) => {

      if (obj.category_comission === 'Service') {

        service_comission.push({
          //  ...obj,
          id: obj?.id,
          commission: obj?.commission_percentage,
          symbol: obj?.symbol,
          from_value: obj?.from_value,
          to_value: obj?.to_value
        })
      }
      if (obj.category_comission === 'Retail') {
        retail_comission.push({
          //  ...obj,
          id: obj?.id,
          commission: obj?.commission_percentage,
          symbol: obj?.symbol,
          from_value: obj?.from_value,
          to_value: obj?.to_value
        })
      }
      if (obj.category_comission === 'Voucher') {
        voucher_comission.push({
          //  ...obj,
          id: obj?.id,
          commission: obj?.commission_percentage,
          symbol: obj?.symbol,
          from_value: obj?.from_value,
          to_value: obj?.to_value
        })
      }
    })

    if (service_comission && voucher_comission && retail_comission) {
      setCommissions({
        id: selected_commission?.id,
        employee: selected_commission?.employee?.id,
        commission_cycle: selected_commission?.commission_cycle,
        service_comission: service_comission,
        service_comission_symbol: service_comission[0]?.symbol ? service_comission[0]?.symbol : "%",
        product_comission: retail_comission,
        product_comission_symbol: retail_comission[0]?.symbol ? retail_comission[0]?.symbol : "%",
        voucher_comission: voucher_comission,
        voucher_comission_symbol: voucher_comission[0]?.symbol ? voucher_comission[0]?.symbol : "%"
      });
      setAddCommission(true);
    }
  };

  //delete handler
  const onDeleteCommission = () => {
    dispatch(
      deleteCommission(
        deleteId,
        access_token,
        (message) => {
          toast.success(message, { toastId: "toast" });
          setDelete(false);
          setCurrentPage(1)
          dispatch(getCommissions(selected_location, currentPage, searchText, setLoader))
          dispatch(getCommissionsWithNoPagination(selected_location, searchText, setNoPaginationLoader))
        },
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };

  //commission filter on the bases of employee id and employee name handle here
  const commission_filter = () => {
    // const filter_data = dataList?.filter((item) => {
    //   if (searchText) {
    //     let return_value = false;
    //     if (searchText) {
    //       if (
    //         item?.employee?.full_name
    //           ?.toLowerCase()
    //           .includes(searchText.toLowerCase()) ||
    //         item?.employee?.employee_id
    //           ?.toLowerCase()
    //           ?.includes(searchText?.toLowerCase())
    //       ) {
    //         return_value = true;
    //       } else {
    //         return false;
    //       }
    //     }
    //     return return_value;
    //   }
    //   return true;
    // });
    const filter_data = dataList
    return filter_data;
  };

  // commission xports
  // { label: "Commission Cycle", key: "commission_cycle" },
  let exportCommissionData = [
    {
      label: selectedLanguage?.name == "English" ? "ID" : translation[603]?.value ? translation[603]?.value : "ID",
      key: "id"
    },
    { label: selectedLanguage?.name == "English" ? "Staff Name" : translation[613]?.value ? translation[613]?.value : "Staff Name", key: "staff_id" },
    { label: selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service", key: "service" },
    { label: selectedLanguage?.name == "English" ? "Retail" : translation[604]?.value ? translation[604]?.value : "Retail", key: "retail" },
    { label: selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher", key: "voucher" },
  ];

  // set export table fields here
  const exportCommission = {
    filename: "Employee Commission Report.csv",
    headers: exportCommissionData,
    data:
      allCommissionList?.length > 0
        ? allCommissionList?.map((item) => {
          return {
            id: item?.employee?.employee_id
              ? item?.employee?.employee_id
              : "----",
            staff_id: item?.employee?.full_name
              ? item?.employee?.full_name
              : "----",
            // commission_cycle: item?.commission_cycle
            //   ? item?.commission_cycle
            //   : "----",
            service: item?.category_comission[0]?.commission_percentage
              ? `${item?.category_comission[0]?.commission_percentage} ${item?.category_comission.find((obj) => obj.category_comission === "Service")?.symbol}`
              : "----",
            retail: item?.category_comission[1]?.commission_percentage
              ? `${item?.category_comission[1]?.commission_percentage} ${item?.category_comission.find((obj) => obj.category_comission === "Retail")?.symbol}`
              : "----",
            voucher: item?.category_comission[2]?.commission_percentage
              ? `${item?.category_comission[2]?.commission_percentage} ${item?.category_comission.find((obj) => obj.category_comission === "Voucher")?.symbol}`
              : "----",
          };
        })
        : [],
  };

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allCommissionList?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", { toastId: "toast", onClose: () => setIsToastVisible(false) });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };


  useEffect(() => {
    if (selected_location) {
      dispatch(getEmployeeDropDownList(selected_location))
    }
  }, [selected_location])

  // useEffect(() => {
  //   if (employeeList?.length === 0) {
  //     dispatch(getEmployeeList());
  //   }

  //   // if (commissionList?.length === 0) {
  //   //   dispatch(getCommissions(currentPage));
  //   // } else {
  //   //   const val = commissionList?.map((element) => {
  //   //     const data = element?.employee?.location?.filter(
  //   //       ({ id }) => id === selected_location
  //   //     );
  //   //     if (data?.length > 0 && data !== undefined) return { ...element };
  //   //   });
  //   //   const commissionArr = val?.filter((element) => element !== undefined);
  //   //   setDataList(commissionArr);
  //   // }
  // }, [selected_location]);

  useEffect(() => {
    setDataList(commissionList);
  }, [commissionList, commissionList?.length])

  useEffect(() => {
    if (selected_location) {
      if (searchText === null) {
        // Initial load with empty searchText
        dispatch(getCommissions(selected_location, currentPage, "", setLoader))
        dispatch(getCommissionsWithNoPagination(selected_location, "", setNoPaginationLoader))
      } else {
        if (timeOutId) {
          clearTimeout(timeOutId)
        }
        timeOutId = setTimeout(() => {
          dispatch(getCommissions(selected_location, currentPage, searchText, setLoader))
          dispatch(getCommissionsWithNoPagination(selected_location, searchText, setNoPaginationLoader))
        }, 500);
      }
    }
  }, [currentPage, searchText, selected_location])


  useEffect(() => {
    setLoader(true)
    setNoPaginationLoader(true)
    setCurrentPage(1)
  }, [selected_location])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchText])

  const employeeDropdown = useMemo(() => {
    let employeesOnSelectedLocation = [];
    employeeList?.map((empl, i) => {
      employeesOnSelectedLocation.push({
        label: empl.full_name,
        value: empl.id,
        otherData: {
          image: empl?.image,
          DataOne: empl.email,
          showImage: true,
          index: i
        },
        email: empl.email,
        mobile_number: empl.mobile_number
      });
    });
    return employeesOnSelectedLocation;
  }, [selected_location, employeeList, employeeList?.length]);


  const filterEmployee = useMemo(() => {
    if (!isEdit) {
      const filterEmployeeIfCommissionCreadted = employeeDropdown?.filter((obj) => {
        return !dataList?.find(element => {
          return element?.employee?.id === obj?.value;
        });
      })
      return filterEmployeeIfCommissionCreadted;
    } else {
      return employeeDropdown;
    }
  }, [employeeDropdown?.length, dataList, employeeList, isEdit]);


  useEffect(() => {
    if (selected_location) {
      if (employeeSearch === null) {
        // Initial load with empty search_text
        dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
      } else {
        if (employeeTimeOutId) {
          clearTimeout(employeeTimeOutId)
        }
        employeeTimeOutId = setTimeout(() => {
          dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
        }, 500);
      }
    }
  }, [employeeSearch, selected_location, employeePage])


  return {
    errors, is_delete, deleteId, commissions, filterEmployee, exportCommission, currency, employeeDropdown,
    employeeList, commissionList, commissions_updated, isLoading, currencyOnSelectedLocation, AddCommission, searchText,
    isEdit, setIsEdit, setErrors, setDelete, setIsLoading, setDeleteId, setCommissions, handleChange, onSubmitCommission,
    onBlurHandler, onUpdateCommission, onDeleteCommission, onEditStateManageHandler, setSearchText, setAddCommission,
    commission_filter, pages, currentPage, setCurrentPage, dataList, allCount, allCommissionList, loader, handleExportClick,
    exportLoader, setExportLoader, submitButtonClick, setNoPaginationLoader, noPaginationLoader, setSubmitButtonClick,

    employeeSearch, setEmployeeSearch, employeePage, setEmployeePage, employeeLoader, setEmployeeLoader, employeePaginationData
  };
}

export default useCommission;
