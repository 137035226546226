import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";

import NotFound from "../../Elements/NotFound";
import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";

import { getSaleOrderServices } from "Adapters/Api/saleOrders";
import moment from "moment";
import useSaleOrderService from './helper';

const Services = ({ filter_data, toggleInviceModal, setLengths, ...props }) => {
  const { servicelist, calculate_totals, location_currency } = useSaleOrderService()
  const dispatch = useDispatch();
  const { saleOrder, locations, business } = useSelector((state) => state);
  const businessCurrency = business.business?.currency?.code;

  const { saleOrderServices, saleOrderServices_updated } = saleOrder;
  const { selected_location } = locations;

  const [dataList, setDataList] = useState([]);

  console.log("servicelist ::", servicelist)
  const filterProducts = () => {
    return servicelist?.filter((itm) => {
      if (Object.keys(filter_data).length > 0) {
        let returned_value = false;
        if (filter_data?.text) {
          let value = filter_data?.text.toLowerCase();
          if (
            itm.service[0]?.client?.full_name?.toLowerCase()?.includes(value) ||
            itm.service[0]?.member?.full_name?.toLowerCase()?.includes(value) ||
            itm?.service[0]?.service?.name?.toLowerCase()?.includes(value)
          ) {
            returned_value = true;
          } else {
            return false;
          }
        }
        if (filter_data?.client) {
          if (filter_data?.client == itm?.service[0]?.client?.id) {
            returned_value = true;
          } else {
            return false;
          }
        }
        if (filter_data?.location) {
          if (filter_data?.location == itm?.service[0]?.location?.id) {
            returned_value = true;
          } else {
            return false;
          }
        }
        if (filter_data?.member) {
          if (filter_data?.member == itm?.service[0]?.member?.id) {
            returned_value = true;
          } else {
            return false;
          }
        }
        return returned_value;
      }
      return true;
    });
  };

  useEffect(() => {
    if (saleOrderServices.length === 0) {
      dispatch(getSaleOrderServices());
    }
    setLengths(filterProducts().length)
  }, [filterProducts().length]);

  // useEffect(() => {
  //   saleOrderServices.length === 0 && dispatch(getSaleOrderServices());
  //   const val = saleOrderServices?.filter(
  //     (ele) => ele.location.id === selected_location
  //   );
  //   setDataList(val);
  // }, [selected_location, saleOrderServices]);

  return !saleOrderServices_updated ? (
    <PreloaderFull />
  ) : (
    <div className="overflow-x-auto">
      <div className="inline-grid xl:grid grid-cols-[repeat(10,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
        <p>Ref#</p>
        <p>Client </p>
        <p>Service</p>
        <p>Created By</p>
        {/* <p>Created on</p> */}
        <p>Scheduled On</p>
        <p>Duration</p>
        <p>Location</p>
        <p>Employee</p>
        <p>Price</p>
      </div>
      <div className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}>
        {filterProducts().length > 0 ? (
          filterProducts().map((srvc, index) => {
            let totalPrice = calculate_totals(srvc)
            return (
              <div
                className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(10,minmax(115px,1fr))]"
                key={index}
              >
                <div className={`before-left-arrow cursor-pointer`}>
                  <div className="h-full flex items-center">
                    <p
                      className="text-sm text-primary line-clamp-1"
                      onClick={() => {
                        toggleInviceModal(srvc?.id, "Services", srvc)
                        calculate_totals(srvc, true)
                      }}
                    >
                      # {srvc?.id}
                    </p>
                    {/* <p className='text-sm text-[#5679FF]' onClick={() => { props.openInvoice(true) }}>{srvc?.id}</p> */}
                  </div>
                </div>
                <div className="flex items-center">
                  <p className="text-sm line-clamp-1 pr-3">
                    {srvc?.service[0]?.client?.full_name ? srvc?.service[0]?.client?.full_name : "----"}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="text-sm">{srvc?.service[0]?.service?.name ? srvc?.service[0]?.service?.name : "----"}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-sm">{srvc?.service[0]?.user ? srvc?.service[0]?.user : "----"}</p>
                </div>
                <div className="flex items-center">
                  <p>
                    {/* <Moment fromNow>{srvc?.created_at}</Moment> */}
                    {
                      moment(srvc?.created_at).format('YYYY-MM-DD')
                    }
                  </p>
                </div>
                {/* <div className="flex items-center">
                  <p className="text-sm ">123</p>
                </div> */}
                <div className="flex items-center">
                  <p className="text-sm ">{srvc?.service[0]?.duration}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-sm line-clamp-1 ">
                    {srvc?.service[0]?.location?.address_name ? srvc?.service[0]?.location?.address_name : "----"}
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="text-sm ">{srvc?.service[0]?.member?.full_name ? srvc?.service[0]?.member?.full_name : "----"}</p>
                </div>
                <div className="flex items-center">
                  <p className="text-sm ">{totalPrice ? totalPrice : "----"}{location_currency}</p>
                </div>
              </div>
            );
          })
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
};

export default Services;
