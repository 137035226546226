import React from "react";
import Svgs from "Assets/svgs";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import { useNavigate } from "react-router-dom";

function Step1({
  step,
  orderStock,
  orderStockHandleChange,
  onBlurHandler,
  errors,
  translation,
  selectedLanguage, setGetProduct,
  handleStep, vendorsDropdownList, locationsDropdownList
}) {
  const { vendor, fromLocation, toLocation } = orderStock;
  const navigate = useNavigate();
  return (
    <>
      <div className="w-[60%] flex flex-col gap-4">
        <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem] mt-[1rem]">
          <CircleIcon>
            <Svgs.Profile />
          </CircleIcon>
          <div className="flex flex-col gap-1 lg:text-left text-center">
            <h2 className="text-primary font-semibold text-xl">
              {selectedLanguage?.name == "English" ? "Order Stock" : translation[1019]?.value ? translation[1019]?.value : "Order Stock"}
            </h2>
            <p className="text-[#7B7B7B] text-sm">
              {selectedLanguage?.name == "English"
                ? "Order product's stock from respective vendor to any business"
                : translation[1027]?.value
                  ? translation[1027]?.value
                  : "Order product's stock from respective vendor to any business"
              }
              <br className="hidden md:block" />
              {selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}.
            </p>
          </div>
        </div>

        <Dropwdown
          title={selectedLanguage?.name == "English" ? "Vendors" : translation[867]?.value ? translation[867]?.value : "Vendors"}
          placeholder={selectedLanguage?.name == "English" ? "Select Vendor" : translation[961]?.value ? translation[961]?.value : "Select Vendor"}
          required={true}
          name="vendor"
          value={vendor}
          onBlur={() => onBlurHandler({ vendor: vendor })}
          options={vendorsDropdownList}
          onChange={(e) => {
            orderStockHandleChange(e);
          }}
          error={errors?.vendor}
        />
        {vendorsDropdownList?.length == 0 && (
          <div
            className="flex items-center gap-2 cursor-pointer pt-2"
            onClick={() => {
              // settogglecat(true);
              navigate('/dashboard/account/inventory-operations/vendors')
            }}
          >
            <p className="text-sm">No vendors found. Click <span className="underline">here</span> to add.</p>
          </div>
        )}
        <div className="flex flex-col gap-3">
          <h1 className="font-semibold text-xl">
            {selectedLanguage?.name == "English" ? "Delivery Location" : translation[1021]?.value ? translation[1021]?.value : "Delivery Location"} *
          </h1>
          <div className="grid md:grid-cols-2 gap-4">
            {/* <Dropwdown
              title={"From"}
              placeholder="Select Location"
              required={true}
              name="fromLocation"
              value={fromLocation}
              onBlur={() => onBlurHandler({ fromLocation: fromLocation })}
              options={locationsDropdownList}
              onChange={(e) => {
                orderStockHandleChange(e);
              }}
              error={errors?.fromLocation}
            /> */}
            <Dropwdown
              title={selectedLanguage?.name == "English" ? "To" : translation[1064]?.value ? translation[1064]?.value : "To"}
              placeholder={selectedLanguage?.name == "English" ? "Select Location" : translation[20]?.value ? translation[20]?.value : "Select Location"}
              required={true}
              name="toLocation"
              value={toLocation}
              onBlur={() => onBlurHandler({ toLocation: toLocation })}
              options={locationsDropdownList.filter(
                (obj) => obj.value !== fromLocation
              )}
              onChange={(e) => {
                orderStockHandleChange(e);
              }}
              error={errors?.toLocation}
            />
          </div>
        </div>
        <LoginBtn
          onClick={(e) => {
            setGetProduct(true)
            handleStep(e, step);
          }}
          text={
            <div className="flex items-center gap-2">
              <p>
                {selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
              </p>
              <Svgs.NextArrow />
            </div>
          }
        // disabled={toLocation === "" || vendor === ""}
        />
      </div>
    </>
  );
}

export default Step1;
