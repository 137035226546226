
import axios from "axios";
import { getActionCategoriesDropdownList, getActionCategoriesDropdownListPagination, getCategories, getCategoriesWithNoPagination } from "../../../Redux/TenantRedux/Actions/categoryActions";
import { get_categories, add_categories, update_category, delete_category, BASE_URL, get_category_import_url, get_categories_dropdown } from '../api_variables';




export const getAllCategories = (currentPage, search_text, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const current_Page = currentPage ? `?page=${currentPage}` : '';
  const search = search_text ? `&search_text=${search_text}` : ''
  try {
    const result = await axios.get(`${BASE_URL}${get_categories}${current_Page}${search}`);
    if (dispatch) {
      dispatch(getCategories(result.data))
      setLoader && setLoader(false)
    }
    return result;
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getCategoriesDropdownList = (setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  try {
    const result = await axios.get(`${BASE_URL}${get_categories_dropdown}`);
    if (dispatch) {
      dispatch(getActionCategoriesDropdownList(result.data?.response?.categories))
      setLoader && setLoader(false)
    }
    return result;
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getCategoriesDropdownListPagination = (page, searchText, setLoader, setCatLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const pageNumb = page ? `?page=${page}` : ''
  const search = searchText ? `&search_text=${searchText}` : ''
  try {
    const result = await axios.get(`${BASE_URL}${get_categories_dropdown}${pageNumb}${search}`);
    if (dispatch) {
      dispatch(getActionCategoriesDropdownListPagination(result.data))
      setLoader && setLoader(false)
      setCatLoader && setCatLoader(false)
    }
    return result;
  } catch (error) {
    setCatLoader && setCatLoader(false)
    setLoader && setLoader(false)
    return error;
  }
}

export const getAllCategoriesWithNoPagination = (search_text, setNoPaginationLoader) => async (dispatch) => {
  setNoPaginationLoader && setNoPaginationLoader(true)
  const search = search_text ? `&search_text=${search_text}` : ''
  try {
    const result = await axios.get(`${BASE_URL}${get_categories}?no_pagination=true${search}`);
    if (dispatch) {
      console.log(result.data.response.categories, "result.data.response.categories")
      dispatch(getCategoriesWithNoPagination(result.data.response.categories))
      setNoPaginationLoader && setNoPaginationLoader(false)
    }
    return result;
  } catch (error) {
    setNoPaginationLoader && setNoPaginationLoader(false)
    return error;
  }
};


export const getCategoryImport = async (form, success) => {
  let token = localStorage.getItem('access_token')
  if (token) {
    token = token.replaceAll('"', '')
  }

  fetch(`${BASE_URL}${get_category_import_url}`,
    {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`
      },
      body: form
    }).then((response => {
      return (
        response.json()
      )
    })).then((result) => {
      success()
    })

};


export const addCategroy = async (category_data, access_token) => {

  try {
    const result = await axios.post(
      `${BASE_URL}${add_categories}`, category_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};




export const deleteCategory = async (category_id, access_token) => {

  try {

    let result = await fetch(`${BASE_URL}${delete_category}`, {
      method: "DELETE",
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Token ${access_token}`
      },
      body: JSON.stringify(
        { category: category_id }
      )
    })
    result = result.json();
    return result;

  } catch (error) {
    return error;
  }

}


export const updateCategory = async (update_category_data, access_token) => {
  try {
    const result = await axios.put(`${BASE_URL}${update_category}`, update_category_data,
      {
        headers: { 'Authorization': `Token ${access_token}` },
      });
    return result;
  } catch (error) {
    return error;
  }
}