import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { createComment, getComment } from 'Adapters/Api/apointments'
import AppointmentCommentShimmer from "Components/Utility/Shimmers/AppointmentCommentShimmer";
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import useValidations from 'Hooks/useValidations';
import useObjectKeys from 'Hooks/useObjectKeys';
import Textarea from 'Components/TenantApp/Elements/Textarea';
import InfiniteScroll from 'react-infinite-scroll-component';
import Popup from 'Components/TenantApp/Elements/Popup';
import LabelAvatar from 'Components/TenantApp/Elements/LabelAvatar';
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm';

const EmployeeCommentSystem = ({appointmentDataId , groupAppointment}) => {
    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys()

    const appointmentKey = groupAppointment ? "group_appointment" : "appointment_id";
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    const [AppointmentComments, setAppointmentComments] = useState([]);
    const [AppointmentCommentsData, setAppointmentCommentsData] = useState({});
    const [AppointmentCommentsPageCount, setAppointmentCommentsPageCount] = useState(1);
  
    // Create Comment API Create 
  
    let initialState = {
      employeeId: "",
      comment: ""
    }
    const [AppointmentComment, setAppointmentComment] = useState(initialState)
  
    const onChangeHandler = (e) => {
      // console.log("AppointmentComment", AppointmentComment);
      if (e?.target) {
        const { name, value } = e.target;
  
        if (name === 'comment') {
          if (!AppointmentComment?.comment?.includes(value)) {
            setAppointmentComment((prev) => ({ ...prev, [name]: value }));
          } else {
            // If you don't want to change employeeId, exclude it from the update
            setAppointmentComment((prev) => ({
              ...prev,
              [name]: value,
            }));
          }
        }
        setErrors((prev) => ({ ...prev, [name]: "" }));
      }
    };
  
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const dispatch = useDispatch()
    const access_token = getDataFromLocalStorage('access_token')
    const [submitLoader, setSubmitLoader] = useState(false)
    const [CommentsLoader, setCommentsLoader] = useState(false)
    const [addCommentPopup, setAddCommentPopup] = useState(false);
    const [newShimmer, setNewShimmer] = useState(false);
  
  
  
    const onSubmitHandler = () => {
  
      const { comment } = AppointmentComment;
      let requiredFields = {}
      requiredFields = { comment }
  
      setErrors(validation(requiredFields));
      let result = validation(requiredFields);
      // console.log('result',result);
  
      if (objectKeyConvertToArray(result)?.length === 0) {
        setSubmitLoader(true)
        const success = (result) => {
          setAppointmentCommentsPageCount(1);
          setAppointmentComments([]);
          getComments(1)
          setAppointmentComment(initialState);
          setSubmitLoader(false)
        }
        const fail = (result) => {
          setSubmitLoader(false)
          console.log('fail', result);
        }
  
        dispatch(createComment({ ...AppointmentComment, user_id: state?.user?.user?.id, [appointmentKey]: appointmentDataId, }, access_token, success, fail))
  
      }
    }
  
  
    const getComments = (count) => {
      const payload = { user_id: state?.user?.user?.id, [appointmentKey]: appointmentDataId, page: count ? count : AppointmentCommentsPageCount }; 
      setCommentsLoader(true)
  
      const success = (result) => {
  
        count ? setAppointmentComments([...result?.response?.data]) : setAppointmentComments([...AppointmentComments, ...result?.response?.data]);
        setAppointmentCommentsData({ ...result?.response })
        // dispatch({
        //   type: GET_COMMENTS,
        //   payload: AppointmentComments,
        // });
        setNewShimmer(false)
        setCommentsLoader(false)
      };
  
      const fail = (result) => {
        console.log('fail', result);
      };
  
      getComment(payload, success, fail);
    };
  
    useEffect(() => {
      if (AppointmentCommentsPageCount != 1) {
        getComments()
      }
  
    }, [AppointmentCommentsPageCount])
  
  return (
    <>
    <LoginBtn
        text={selectedLanguage?.name == "English" ? "ADD COMMENTs" : translation[1570]?.value ? translation[1570]?.value : "ADD COMMENT"}
        className={'bg-[#5679ff] text-white'}
        customThemeBtn={'custom-theme-btn'}
        onClick={() => {
        setNewShimmer(true)
        setAddCommentPopup(!addCommentPopup);
        getComments();
    }}
        />
        {addCommentPopup && 
      <Popup
        heading={"Add Comment"}
        close={() => {
          setAddCommentPopup();
          setAppointmentCommentsPageCount(1)
          setAppointmentComments([])
          setAppointmentComment(initialState)
          setErrors("");
        }}
        open={() => {
          addCommentPopup();
        }}
      >
       <div className="flex flex-col gap-3">
       {
          (AppointmentComment && AppointmentComments?.length > 0) ? (
            <div className="flex flex-col gap-2">
              <p className='text-sm'>{selectedLanguage?.name == "English" ? "Comment History" : translation[1572]?.value ? translation[1572]?.value : "Comment History"}</p>
              <div className="py-2 px-2 border border-[#A6A6A6] rounded-md min-h-[15rem] max-h-[15rem] overflow-y-auto w-full" id="scrollableDiv">
                <InfiniteScroll
                  dataLength={AppointmentComments?.length ? AppointmentComments?.length : 10} //This is important field to render the next data
                  next={() => {
                    AppointmentCommentsData?.current_page < AppointmentCommentsData?.total_pages ? setCommentsLoader(true) : setCommentsLoader(false) 
                    setAppointmentCommentsPageCount(AppointmentCommentsPageCount+1)
                  }}
                  hasMore={AppointmentCommentsData && AppointmentComments?.length < AppointmentCommentsData?.count && AppointmentCommentsData?.current_page > 0 && AppointmentCommentsData?.current_page < AppointmentCommentsData?.total_pages}
                  
                  scrollableTarget="scrollableDiv"
                  loader={CommentsLoader
                    ? (
                        <div className="h-[5rem] flex items-center justify-center">
                          <PreloaderSm />
                        </div>
                      )
                    : ""
                  }
                  >

                    {
                      (AppointmentComments && AppointmentComments.length>0) ? AppointmentComments?.map((obj) => {
                        return(
                          <div className="p-2 last:border-0 border-b-2 border-[#DFE6FF]">
                            <div className="flex gap-3 items-center">
                              <div className='h-[2.5rem] w-[2.5rem] rounded-full overflow-hidden'>
                                  {
                                  // data?.image ?
                                  //     <img
                                  //         src={data?.image}
                                  //         className="h-full w-full object-cover" />
                                  //     :
                                      <LabelAvatar fontSize={"text-xl"} iconLabel={obj?.user?.full_name} index={0} />
                                  }
                              </div>
                              <div>
                                  <h4 className="font-medium">{obj?.user?.first_name}</h4>
                                  <p className="text-[#7B7B7B] text-sm">{obj?.user?.email}</p>
                              </div>
                            </div>
                            <div className="py-3">
                                <p className="text-[#444] text-justify"><span className="text-[#5679FF]">“</span>{obj?.comment}<span className="text-[#5679FF]">”</span></p>
                            </div>
                        </div>
                        )
                      }) : ((AppointmentCommentsData?.data?.length>0 || newShimmer) ? (<AppointmentCommentShimmer />) : 
                      <div className="min-h-[13rem] max-h-[13rem] flex items-center justify-center">
                        <p>No Comments</p>
                      </div>)
                    }
                    
                  </InfiniteScroll>
              </div>
            </div>
          ) : 
            AppointmentCommentsData?.data?.length>0 || newShimmer ? (<AppointmentCommentShimmer parentClass={"!p-0"} headShimmer={true} itemLength={[0,1]} />): <></>
          
        }
          
          
          <Textarea
              title={selectedLanguage?.name == "English" ? "Add Comment" : translation[1570]?.value ? translation[1570]?.value : "Add Comment"}
              rows={5}
              limit={150}
              placeholder={selectedLanguage?.name == "English" ? "Enter Comment" : translation[1571]?.value ? translation[1571]?.value : "Enter Comment"}
              required={true}
              name={'comment'}
              value={AppointmentComment?.comment}
              onChange={onChangeHandler}
              onEnterSubmit={onChangeHandler}
              errorMessage={errors?.comment}
              onBlur={() => onBlurHandler({ name: "comment" })}
            />
       </div>

        <div className="py-2">
          <LoginBtn
            text={selectedLanguage?.name == "English" ? "ADD" : translation[97]?.value ? translation[97]?.value : "ADD"}
            className={'bg-[#5679ff] text-white !px-8'}
            customThemeBtn={'custom-theme-btn'} 
            onClick={onSubmitHandler}
            loading={submitLoader}
            disabled={submitLoader}
          />
        </div>

      </Popup>
      }
    </>
  )
}

export default EmployeeCommentSystem