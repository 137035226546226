import axios from "axios";
import { BASE_URL, get_promotions_and_discounts_reports, get_promotions_and_discounts_reports_detail } from "../api_variables";
import { SALE_ORDER } from "Redux/TenantRedux/ActionTypes/salleOrderTypes";

export const get_discount_promotions_reports = (data, success, fail) => async dispatch => {
    try {
        const product_location = `?location_id=${data.location_id}`;
        const current_Page = data?.currentPage ? `&page=${data?.currentPage}` : '';
        const search = data?.search_text ? `&search_text=${data?.search_text}` : ''
        const startDate = data?.startDate ? `&start_date=${data?.startDate}` : ''
        const endDate = data?.endDate ? `&end_date=${data?.endDate}` : ''
        const apiUrl = `${get_promotions_and_discounts_reports}${product_location}${startDate}${endDate}${current_Page}${search}`

        const result = await axios.get(`${BASE_URL}${apiUrl}`)
        if (result?.status === 200) {
            success && success(result?.data)
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
}

export const get_discount_promotions_reports_invoicedetail = (id, location_id, setLoading, success, fail) => async dispatch => {
    setLoading && setLoading(true)
    try {
        const product_location = location_id ? `?location_id=${location_id}` : "";
        const invoiceID = id ? `&id=${id}` : ""
        const apiUrl = `${get_promotions_and_discounts_reports_detail}${product_location}${invoiceID}`

        const result = await axios.get(`${BASE_URL}${apiUrl}`)
        if (result?.status === 200) {
            setLoading && setLoading(false)
            dispatch({
                type: SALE_ORDER.GET_INVOICE_TITLES,
                payload: {
                    data: result?.data?.response?.invoice_translations
                }
            })
            success && success(result?.data?.response?.sales[0])
        } else {
            setLoading && setLoading(false)
            fail && fail(result)
        }
        return result;
    } catch (error) {
        setLoading && setLoading(false)
        fail && fail(error)
        return error;
    }
}

export const get_discount_promotions_reports_without_pagination = (data, success, fail) => async dispatch => {
    try {
        const product_location = `?location_id=${data.location_id}`;
        const search = data?.search_text ? `&search_text=${data?.search_text}` : ''
        const startDate = data?.startDate ? `&start_date=${data?.startDate}` : ''
        const endDate = data?.endDate ? `&end_date=${data?.endDate}` : ''
        const apiUrl = `${get_promotions_and_discounts_reports}${product_location}&no_pagination=true${startDate}${endDate}${search}`

        const result = await axios.get(`${BASE_URL}${apiUrl}`)
        if (result?.status === 200) {
            success && success(result?.data)
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
}