import React, { useState, useEffect } from "react";
import Svgs from "../../../../../Assets/svgs";
import AccountSetupCard from "../../AccountSetupCard";
import { useSelector } from "react-redux";

const TargetDashboard = () => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

      <div className="flex items-center gap-5 lg:w-fit w-full lg:px-0 lg:pb-[2rem] lg:py-0 p-3 pb-8">
        <Svgs.Back link="/dashboard/account" />
        <h2 className="font-semibold text-2xl">
          {selectedLanguage?.name == "English" ? "Target Control" : translation[569]?.value ? translation[569]?.value : "Target Control"}
        </h2>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem] xl:pr-[4rem] pr-0">
        {!employee ||
          (employee && (employeePermissions?.target_control_staff?.includes("view")
            || employeePermissions?.target_control_staff?.includes("create")
            || employeePermissions?.target_control_staff?.includes("edit")
            || employeePermissions?.pos_root_access?.includes("view"))) ? (
          <AccountSetupCard
            link="/dashboard/account/target-control/staff-target"
            icon={<Svgs.Staff_target />}
            title={selectedLanguage?.name == "English" ? "Staff Target" : translation[648]?.value ? translation[648]?.value : "Staff Target"}
            description={selectedLanguage?.name == "English" ? "Manage staff target." : translation[816]?.value ? translation[816]?.value : "Manage staff target."}

          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && (employeePermissions?.target_control_store?.includes("view")
            || employeePermissions?.target_control_store?.includes("create")
            || employeePermissions?.target_control_store?.includes("edit")
            || employeePermissions?.pos_root_access?.includes("view"))) ? (
          <AccountSetupCard
            link="/dashboard/account/target-control/store-target"
            icon={<Svgs.Store_Target />}
            title={selectedLanguage?.name == "English" ? "Store Target" : translation[815]?.value ? translation[815]?.value : "Store Target"}
            description={selectedLanguage?.name == "English" ? "Manage store target." : translation[816]?.value ? translation[816]?.value : "Manage store target."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && (employeePermissions?.target_control_service?.includes("view")
            || employeePermissions?.target_control_service?.includes("create")
            || employeePermissions?.target_control_service?.includes("edit")
            || employeePermissions?.pos_root_access?.includes("view"))) ? (
          <AccountSetupCard
            link="/dashboard/account/target-control/service-target"
            icon={<Svgs.Service_Target />}
            title={selectedLanguage?.name == "English" ? "Service Target" : translation[817]?.value ? translation[817]?.value : "Service Target"}
            description={selectedLanguage?.name == "English" ? "Manage service target for service groups." : translation[818]?.value ? translation[818]?.value : "Manage service target for service groups."}
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && (employeePermissions?.target_control_retail?.includes("view")
            || employeePermissions?.target_control_retail?.includes("create")
            || employeePermissions?.target_control_retail?.includes("edit")
            || employeePermissions?.pos_root_access?.includes("view"))) ? (
          <AccountSetupCard
            link="/dashboard/account/target-control/retail-target"
            icon={<Svgs.Retail_Target />}
            title={selectedLanguage?.name == "English" ? "Retail Target" : translation[819]?.value ? translation[819]?.value : "Retail Target"}
            description={selectedLanguage?.name == "English" ? "Manage retail target for brands." : translation[820]?.value ? translation[820]?.value : "Manage retail target for brands."}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default TargetDashboard;
