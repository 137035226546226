import { CLIENT_APPOINTMENT } from "Redux/NstyleRedux/ActionTypes/ClientAppointmentTypes";

const initialState = {
  client_appointments: [],
  business_tax: null,
  client_detail: null,
  common_tenant_data:null,
};

const ClientAppointmentReducer = (state = initialState, action) => {
    const {type, payload} = action;

  switch (type) {
    case CLIENT_APPOINTMENT.GET_CLIENT_APPOITMENT:
      return {
        ...state,
        client_appointments: payload,
      };



      case CLIENT_APPOINTMENT.CANCEL_CLIENT_APPOITMENT:
        return {
          ...state,
          client_appointments: state?.client_appointments?.map((itm)=>{
            if(itm?.id === payload?.id) { 
              return payload
            } 
            return itm
          }),
        };


      case CLIENT_APPOINTMENT.RESCHEDULE_CLIENT_APPOITMENT:

        return {
          ...state,
          client_appointments: state?.client_appointments?.map((itm)=>{
            if(itm?.id === payload?.appointment_id) { 
              return {
                ...itm,
                appointment_service: payload?.appointments
                }
            } 
            return itm
          }),
        };

        
        case CLIENT_APPOINTMENT.BUSINESS_TAX:
          return {
            ...state,
            business_tax: payload,
          };


          case CLIENT_APPOINTMENT.CLIENT_DETAIL:
          return {
            ...state,
            client_detail: payload,
          };


          case  CLIENT_APPOINTMENT.COMMON_TENANT_DETAIL:
            return {
              ...state,
              common_tenant_data: payload,
            };
          
         
    default:
      return state;
  }

};

export default ClientAppointmentReducer;
