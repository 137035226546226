import React from "react";

import Popup from "Components/TenantApp/Elements/Popup";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import Svgs from "Assets/svgs";
import AddServiceComission from "./AddServiceComission";
import AddProductComission from "./AddProductComission";
import AddVoucherComission from "./AddVoucherComission";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";

function AddComission({
  commissions,
  setAddCommission,
  setCommissions,
  AddCommission,
  employee,
  handleChange,
  onBlurHandler,
  errors,
  filterEmployee,
  commission_cycle,
  service_comission,
  currencyOnSelectedLocation,
  setErrors,
  product_comission,
  voucher_comission,
  translation,
  selectedLanguage,
  onUpdateCommission,
  onSubmitCommission,
  isEdit,
  isLoading,
  submitButtonClick,
  formRef,
  setSubmitButtonClick,
  employeeSearch, setEmployeeSearch, employeePage, setEmployeePage, employeeLoader, employeePaginationData
}) {



  return (
    <>
      <Popup
        heading={`${Object.keys(commissions)?.includes("id")
          ? selectedLanguage?.name == "English" ? "Update Commission" : translation[611]?.value ? translation[611]?.value : "Update Commission"
          : selectedLanguage?.name == "English" ? "Add Commission" : translation[597]?.value ? translation[597]?.value : "Add Commission"

          }`}
        close={() => {
          setAddCommission(false);
          setSubmitButtonClick(false)
          setCommissions({
            employee: "",
            commission_cycle: "",
            service_comission: [],
            service_comission_symbol: "%",
            product_comission: [],
            product_comission_symbol: "%",
            voucher_comission: [],
            voucher_comission_symbol: "%",
          });
        }}
        open={AddCommission}
      >
        <div className="flex flex-col gap-4" ref={formRef}>
          <PaginationDropdown
            title={<><h1 className="font-semibold">{selectedLanguage?.name == "English" ? "Staff" : translation[335]?.value ? translation[335]?.value : "Staff"}</h1></>}
            placeholder={selectedLanguage?.name == "English" ? "Select Staff" : translation[334]?.value ? translation[334]?.value : "Select Staff"}
            name="employee"
            value={employee}
            onChange={handleChange}
            onBlur={() => onBlurHandler({ employee: employee })}
            error={!isEdit && errors?.employee}
            setPage={setEmployeePage}
            page={employeePage}
            setDropdownSearch={setEmployeeSearch}
            dropdownSearch={employeeSearch}
            lastPage={employeePaginationData?.employeePages}
            currentPage={employeePaginationData?.employeeCurrentPage}
            loading={employeeLoader}
            options={filterEmployee}
            // options={filterEmployee?.map((empl, i) => {
            //   return {
            //     label: empl.full_name,
            //     value: empl.id,
            //     otherData: {
            //       image: empl?.image,
            //       DataOne: empl.email,
            //       showImage: true,
            //       index: i
            //     },
            //     email: empl.email,
            //     mobile_number: empl.mobile_number
            //   };
            // })
            // }
            disabled={isEdit}
          />
          {/* <div className='grid md:grid-cols-2 gap-4'>
                            <LoginInput title={'From Value'} placeholder='Enter Value' />
                            <LoginInput title={'To Value'} placeholder='Enter Value' />
                        </div> */}
          <div className="grid md:grid-col gap-4">
            {/* <LoginInput title={'Percentage'} placeholder='Add Percentage' /> */}
            {/* <Dropwdown
              title={"Commission Cycle"}
              placeholder="Select Commission Cycle"
              name="commission_cycle"
              value={commission_cycle}
              onChange={handleChange}
              onBlur={() =>
                onBlurHandler({ commission_cycle: commission_cycle })
              }
              error={errors?.commission_cycle}
              options={[
                { label: "Every day", value: "Every day" },
                { label: "Every week", value: "Every week" },
                { label: "Every 2 week", value: "Every 2 week" },
                { label: "Every month", value: "Every month" },
                { label: "Every quarter", value: "Every quarter" },
                { label: "Every 6 months", value: "Every 6 months" },
                { label: "Every year", value: "Every year" },
              ]}
            /> */}
          </div>
          <div className="flex flex-col gap-4">
            <hr />
            <h1 className="font-semibold flex items-center gap-2">
              {selectedLanguage?.name == "English" ? "Service Commission" : translation[598]?.value ? translation[598]?.value : "Service Commission"}
            </h1>

            {service_comission?.map(
              (service_comission, i) =>
                service_comission?.isDeleted !== "True" && (
                  <>
                    <AddServiceComission
                      i={i}
                      commissions={commissions}
                      submitButtonClick={submitButtonClick}
                      currencyOnSelectedLocation={currencyOnSelectedLocation}
                      onBlurHandler={onBlurHandler}
                      service_comission={service_comission}
                      setCommissions={setCommissions}
                      errors={errors}
                      translation={translation}
                      selectedLanguage={selectedLanguage}

                      setErrors={setErrors}
                      onUpdate={({ name, value }) => {
                        setCommissions((prev) => ({
                          ...prev,
                          service_comission: [
                            ...prev?.service_comission?.map((itm, ind) => {
                              if (i == ind) {
                                return {
                                  ...itm,
                                  [name]: value,
                                };
                              }
                              let pushFrom = false;
                              let pushTo = false;
                              if (name === "from_value" && value !== "") {
                                pushFrom = true;
                              }
                              if (name === "to_value" && value !== "") {
                                pushTo = true;
                              }
                              if (
                                name === "from_value" &&
                                value !== "" &&
                                pushFrom
                              ) {
                                if (ind === i - 1) {
                                  return {
                                    ...itm,
                                    to_value: (value - 1).toString(),
                                  };
                                }
                              }
                              if (
                                name === "to_value" &&
                                value !== "" &&
                                value != "∞" &&
                                pushTo &&
                                ind === i + 1
                              ) {
                                if (ind === i + 1) {
                                  return {
                                    ...itm,
                                    from_value: (
                                      parseInt(value) + 1
                                    ).toString(),
                                  };
                                }
                              } else {
                                return itm;
                              }
                            }),
                          ],
                        }));
                      }}
                    />
                  </>
                )
            )}
            <h4
              className="cursor-pointer flex items-center gap-2 text-sm text-[#A1A1A1]"
              onClick={() => {
                setCommissions((prev) => ({
                  ...prev,
                  service_comission: [
                    ...prev?.service_comission,
                    {
                      from_value: "",
                      to_value: "",
                      commission: "",
                      isDeleted: "False",
                      symbol: "",
                    },
                  ],
                }));
              }}
            >
              {selectedLanguage?.name == "English" ? "Add new commission tier" : translation[612]?.value ? translation[612]?.value : "Add new commission tier"}
              <div>
                {" "}
                <Svgs.Plus color="#1C8AFE" />
              </div>
            </h4>

            <hr />
            <h1 className="font-semibold flex items-center gap-2">
              {selectedLanguage?.name == "English" ? "Product Commission" : translation[599]?.value ? translation[599]?.value : "Product Commission"}
            </h1>

            {product_comission?.map(
              (product_comission, i) =>
                product_comission?.isDeleted !== "True" && (
                  <>
                    <AddProductComission
                      i={i}
                      commissions={commissions}
                      currencyOnSelectedLocation={currencyOnSelectedLocation}
                      onBlurHandler={onBlurHandler}
                      product_comission={product_comission}
                      setCommissions={setCommissions}
                      errors={errors}
                      setErrors={setErrors}
                      translation={translation}
                      selectedLanguage={selectedLanguage}
                      submitButtonClick={submitButtonClick}
                      onUpdate={({ name, value }) => {
                        setCommissions((prev) => ({
                          ...prev,
                          product_comission: [
                            ...prev?.product_comission?.map((itm, ind) => {
                              if (i == ind) {
                                return {
                                  ...itm,
                                  [name]: value,
                                };
                              }
                              let pushFrom = false;
                              let pushTo = false;
                              if (name === "from_value" && value !== "") {
                                pushFrom = true;
                              }
                              if (name === "to_value" && value !== "") {
                                pushTo = true;
                              }
                              if (
                                name === "from_value" &&
                                value !== "" &&
                                pushFrom
                              ) {
                                if (ind === i - 1) {
                                  return {
                                    ...itm,
                                    to_value: (value - 1).toString(),
                                  };
                                }
                              }
                              if (
                                name === "to_value" &&
                                value !== "" &&
                                value != "∞" &&
                                pushTo &&
                                ind === i + 1
                              ) {
                                if (ind === i + 1) {
                                  return {
                                    ...itm,
                                    from_value: (
                                      parseInt(value) + 1
                                    ).toString(),
                                  };
                                }
                              } else {
                                return itm;
                              }
                            }),
                          ],
                        }));
                      }}
                    />
                  </>
                )
            )}

            <h4
              className="cursor-pointer flex items-center gap-2 text-sm text-[#A1A1A1]"
              onClick={() => {
                // setCommission(!Commission);
                setCommissions((prev) => ({
                  ...prev,
                  product_comission: [
                    ...prev?.product_comission,
                    {
                      from_value: "",
                      to_value: "",
                      commission: "",
                      isDeleted: "False",
                      symbol: "",
                    },
                  ],
                }));
              }}
            >
              {selectedLanguage?.name == "English" ? "Add new commission tier" : translation[612]?.value ? translation[612]?.value : "Add new commission tier"}
              <div>
                {" "}
                <Svgs.Plus color="#1C8AFE" />
              </div>
            </h4>

            <hr />
            <h1 className="font-semibold flex items-center gap-2">
              {selectedLanguage?.name == "English" ? "Voucher Commission" : translation[600]?.value ? translation[600]?.value : "Voucher Commission"}
            </h1>

            {voucher_comission?.map(
              (voucher_commission, i) =>
                voucher_commission?.isDeleted !== "True" && (
                  <>
                    <AddVoucherComission
                      i={i}
                      commissions={commissions}
                      currencyOnSelectedLocation={currencyOnSelectedLocation}
                      onBlurHandler={onBlurHandler}
                      voucher_commission={voucher_commission}
                      setCommissions={setCommissions}
                      errors={errors}
                      setErrors={setErrors}
                      submitButtonClick={submitButtonClick}
                      translation={translation}
                      selectedLanguage={selectedLanguage}

                      onUpdate={({ name, value }) => {
                        setCommissions((prev) => ({
                          ...prev,
                          voucher_comission: [
                            ...prev?.voucher_comission?.map((itm, ind) => {
                              if (i == ind) {
                                return {
                                  ...itm,
                                  [name]: value,
                                };
                              }
                              let pushFrom = false;
                              let pushTo = false;
                              if (name === "from_value" && value !== "") {
                                pushFrom = true;
                              }
                              if (name === "to_value" && value !== "") {
                                pushTo = true;
                              }
                              if (
                                name === "from_value" &&
                                value !== "" &&
                                pushFrom
                              ) {
                                if (ind === i - 1) {
                                  return {
                                    ...itm,
                                    to_value: (value - 1).toString(),
                                  };
                                }
                              }
                              if (
                                name === "to_value" &&
                                value !== "" &&
                                value != "∞" &&
                                pushTo &&
                                ind === i + 1
                              ) {
                                if (ind === i + 1) {
                                  return {
                                    ...itm,
                                    from_value: (
                                      parseInt(value) + 1
                                    ).toString(),
                                  };
                                }
                              } else {
                                return itm;
                              }
                            }),
                          ],
                        }));
                      }}
                    />
                  </>
                )
            )}

            <h4
              className="cursor-pointer flex items-center gap-2 text-sm text-[#A1A1A1]"
              onClick={() => {
                setCommissions((prev) => ({
                  ...prev,
                  voucher_comission: [
                    ...prev?.voucher_comission,
                    {
                      from_value: "",
                      to_value: "",
                      commission: "",
                      symbol: "",
                      isDeleted: "False",
                    },
                  ],
                }));
              }}
            >
              {selectedLanguage?.name == "English" ? "Add new commission tier" : translation[612]?.value ? translation[612]?.value : "Add new commission tier"}
              <div>
                {" "}
                <Svgs.Plus color="#1C8AFE" />
              </div>
            </h4>

            <hr />
            {(submitButtonClick && commissions?.service_comission?.length == 0 &&
              commissions?.product_comission?.length == 0 &&
              commissions?.voucher_comission?.length == 0) && <p className="text-red-500 text-sm">Add atleast one commission tier.</p>}
          </div>
          <LoginBtn
            loading={isLoading}
            onClick={() => {
              if (Object.keys(commissions)?.includes("id")) {
                onUpdateCommission();
              } else {
                onSubmitCommission();
              }
            }}
            disabled={isLoading}
            // disabled={
            //   !commissions?.employee ||
            //   !commissions?.commission_cycle &&
            //   !commissions?.service_comission?.length > 0 &&
            //   !commissions?.product_comission?.length > 0 &&
            //   !commissions?.voucher_comission?.length > 0 ||
            //   commissions?.service_comission?.find(
            //     (obj) =>
            //       !obj?.from_value ||
            //       !obj?.to_value ||
            //       (!obj?.commission && obj.isDeleted === "False")
            //   ) ||
            //   commissions?.product_comission?.find(
            //     (obj) =>
            //       !obj?.from_value ||
            //       !obj?.to_value ||
            //       (!obj?.commission && obj.isDeleted === "False")
            //   ) ||
            //   commissions?.voucher_comission?.find(
            //     (obj) =>
            //       !obj?.from_value ||
            //       !obj?.to_value ||
            //       (!obj?.commission && obj.isDeleted === "False")
            //   )
            // }
            text={`${Object.keys(commissions)?.includes("id")
              ? selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
              : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
              }`}
          />
        </div>
      </Popup>
    </>
  );
}

export default AddComission;
