import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { LOCATION } from "Redux/TenantRedux/ActionTypes/locationTypes";

export const useLocation = () => {
  const dispatch = useDispatch();
  const { locations } = useSelector((state) => state);
  const { getDataFromLocalStorage, storeDataInLocalStorage } =
    useSetDataInLocalStorage();
  let selected_location = getDataFromLocalStorage("selected_location");

  const [locationPopup, setLocationPopup] = useState(false);
  const [locationName, setLocationName] = useState('--');
  const [locationList, setLocationList] = useState([]);
  const [locationDetail, setLocationDetail] = useState();
  const [loading, setLoading] = useState(true)

  const handleNewLocation = (id, address_name) => {
    setLocationName(address_name);
    setLoading(false)
    setLocationPopup(false);
    storeDataInLocalStorage("selected_location", id);
    const selected_location = getDataFromLocalStorage('selected_location')
    if (selected_location != id) {
      storeDataInLocalStorage('locationChange', true)
    }
  };

  useEffect(() => {
    const val = locations?.locations?.find(({ id }) => selected_location === id);
    setLocationDetail(val)
    setLocationName(val?.address_name);
    setLocationList(locations?.locations);

    if (val != undefined || val != null) {
      if ((Object.keys(val).includes("currency")) && (val?.currency?.code)) {
        // setSelectedCurrency(val?.currency?.code)
        localStorage.setItem("selectedLocationCurrency", JSON.stringify(val?.currency?.code))
        localStorage.setItem("selectedLocationCurrencyId", JSON.stringify(val?.currency?.id))
        dispatch({
          type: "LOCATION_CURRENCY",
          payload: val?.currency?.code,
        })
      }
      setLoading(false)
    }
  }, [locations, selected_location]);

  // useEffect(() => {
  //   if (locationName && locationName != '--')
  // }, [locationName])


  useEffect(() => {
    dispatch({
      type: LOCATION.SELECTED_LOCATION,
      payload: selected_location,
    });
  }, []);

  return {
    locationPopup,
    locationName,
    locationList,
    selected_location,
    locationDetail,
    loading,
    handleNewLocation,
    setLocationPopup,
  };
};
