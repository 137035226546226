import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import Svgs from "../../../../../Assets/svgs";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import LoginInput from "../../../Elements/LoginInput";
import { useSelector, useDispatch } from "react-redux";
import { getEmployeeDropDownList, getEmployeeDropDownListPagination, getStaffData, getStaffDataWithNoPagination } from "../../../../../Adapters/Api/employee";
import Popup from "../../../Elements/Popup";
import CircleIcon from "../../../Elements/CircleIcon";
import Pagination from "new-components/Pagination";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoReportDataFound from "Components/Utility/NoReportDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import { useLocation } from "new-components/location/helper";
import { DESIGNATIONS } from "Constants/Data/Designation";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
// import NotFound from "./../../../../Utility/NotFound";
// import PreloaderFull from "./../../../Elements/PreloaderFull";
// import Shimmer from "Components/Utility/Shimmers/Shimmer";
// import { MONTHS } from "../../../../../Constants/Data/Months";

let timeOutId = undefined;
let employeeTimeOutId = undefined;
const StaffData = () => {
  const { selected_location } = useLocation();

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const employeeRedux = state?.employee?.employees;
  const employeeReduxStaffData = state?.employee?.employeesStaffData;
  const employeePages = state?.employee?.employeePages;
  const employeeAllCount = state?.employee?.employeeAllCount
  const allEmployeeRedux = state?.employee?.allEmployees
  const businessCurrency = state.business.business;
  const employee_updated = state.employee.employee_updated;
  const reduxlocationsList = state?.locations?.locations;

  const [ShowDetails, setShowDetails] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [Filter, setFilter] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [employeeText, setemployeeText] = useState("");
  const [designationText, setdesignationText] = useState("");
  const [serviceText, setServiceText] = useState("");
  const [incomeTypeText, setIncomeTypeText] = useState("");
  const [locationText, setLocationText] = useState("");
  const [exportLoader, setExportLoader] = useState(false);
  const [loader, setLoader] = useState(true)
  const [noPaginationLoader, setNoPaginationLoader] = useState(true)
  const [isToastVisible, setIsToastVisible] = useState(false);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  const [employeeSearch, setEmployeeSearch] = useState("");
  const [employeePage, setEmployeePage] = useState(1)
  const [employeeLoader, setEmployeeLoader] = useState(false)

  const employeeDropdownRedux = state?.employee?.employeesDropdown;
  const employeePaginationData = state?.employee?.employeeDropdownPaginationData

  const [filterState, setFilterState] = useState({
    employee: "",
    designation: "",
    service: "",
    income_type: "",
    location: "",
  });
  const [Employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const { employee, designation, service, income_type, location } = filterState;

  // console.log(searchText, employeeText, designationText, incomeTypeText, locationText, "filterState")

  // useEffect(() => {
  //   dispatch(getStaffData(currentPage, employee, designation, service, income_type, location));
  // }, [currentPage, employee, designation, service, income_type, location]);

  useEffect(() => {
    if (searchText === null) {
      // Initial load with empty search_text
      dispatch(getStaffData(currentPage, '', employee, designation, income_type, location, setLoader))
      dispatch(getStaffDataWithNoPagination('', employee, designation, income_type, location, setNoPaginationLoader))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(getStaffData(currentPage, searchText, employee, designation, income_type, location, setLoader))
        dispatch(getStaffDataWithNoPagination(searchText, employee, designation, income_type, location, setNoPaginationLoader))
      }, 500);
    }
  }, [currentPage, searchText, employee, designation, income_type, location])

  useEffect(() => {
    setNoPaginationLoader(true)
    setLoader(true)
    setCurrentPage(1)
  }, [searchText, employee, designation, income_type, location])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchText])

  useEffect(() => {
    if (employeeSearch === null) {
      // Initial load with empty search_text
      dispatch(getEmployeeDropDownListPagination(employeePage, locationText, "", setEmployeeLoader))
    } else {
      if (employeeTimeOutId) {
        clearTimeout(employeeTimeOutId)
      }
      employeeTimeOutId = setTimeout(() => {
        dispatch(getEmployeeDropDownListPagination(employeePage, locationText, employeeSearch, setEmployeeLoader))
      }, 500);
    }
  }, [employeeSearch, locationText, employeePage])


  //apply filter method
  const applyFilters = () => {
    setFilterState({
      employee: employeeText,
      service: serviceText,
      designation: designationText,
      income_type: incomeTypeText,
      location: locationText
    });
    setFilter(false);
  };

  //clear filter method
  const clearFilters = () => {
    setFilterState({
      employee: "",
      designation: "",
      service: "",
      income_type: "",
      location: "",
    });
    setLocationText("")
    setdesignationText("")
    setemployeeText("")
    setIncomeTypeText("")
    setFilter(false);
  };

  //all filter handle here like search, name, designation...etc
  const staff_data_filters = () => {
    // const filtered_staff = employeeReduxStaffData?.filter((item) => {
    //   if (searchText || employee || designation || service || income_type || location) {
    //     let return_value = false;

    //     if (searchText && searchText != "") {
    //       if (
    //         item?.full_name
    //           ?.toLocaleLowerCase()
    //           ?.includes(searchText?.toLocaleLowerCase())
    //       ) {
    //         return_value = true;
    //       } else {
    //         return false;
    //       }
    //     }

    //     if (employee && employee != "") {
    //       if (
    //         item?.full_name
    //           ?.toLocaleLowerCase()
    //           ?.includes(employee?.toLocaleLowerCase())
    //       ) {
    //         return_value = true;
    //       } else {
    //         return false;
    //       }
    //     }

    //     if (designation && designation != "") {
    //       if (
    //         item?.designation
    //           ?.toLocaleLowerCase()
    //           ?.includes(designation?.toLocaleLowerCase())
    //       ) {
    //         return_value = true;
    //       } else {
    //         return false;
    //       }
    //     }

    //     if (income_type && income_type != "") {
    //       if (
    //         item?.income_type
    //           ?.toLocaleLowerCase()
    //           ?.includes(income_type?.toLocaleLowerCase())
    //       ) {
    //         return_value = true;
    //       } else {
    //         return false;
    //       }
    //     }

    //     // this is not working now it missed from backend in response
    //     if (service && service != "") {
    //       if (
    //         item?.service
    //           ?.toLocaleLowerCase()
    //           ?.includes(service?.toLocaleLowerCase())
    //       ) {
    //         return_value = true;
    //       } else {
    //         return false;
    //       }
    //     }

    //     if (location && location != "") {
    //       if (
    //         item?.location[0]?.id
    //           ?.toLocaleLowerCase()
    //           ?.includes(location?.toLocaleLowerCase())
    //       ) {
    //         return_value = true;
    //       } else {
    //         return false;
    //       }
    //     }

    //     return return_value;
    //   }

    //   return true;
    // });
    // console.log("location For Staff:", location, filtered_staff)
    const filtered_staff = employeeReduxStaffData
    return filtered_staff;
  };

  // staff data exports
  let staffDataHeader = [
    {
      label: selectedLanguage?.name == "English" ? "Employee Name" : translation[420]?.value ? translation[420]?.value : "Employee Name",
      key: "full_name"
    },
    {
      label: selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address",
      key: "address"
    },
    {
      label: selectedLanguage?.name == "English" ? "Email" : translation[476]?.value ? translation[476]?.value : "Email",
      key: "email"
    },
    {
      label: selectedLanguage?.name == "English" ? "Designation" : translation[488]?.value ? translation[488]?.value : "Designation",
      key: "designation"
    },
    {
      label: selectedLanguage?.name == "English" ? "Income" : translation[593]?.value ? translation[593]?.value : "Income",
      key: "income"
    },
    // { label: "Salary", key: "salary" },
  ];

  // staff export data
  const exportStaffData = {
    filename: "Employee Details Report.csv",
    headers: staffDataHeader,
    data:
      allEmployeeRedux?.length > 0
        ? allEmployeeRedux?.map((item) => {
          return {
            full_name: item?.full_name ? item?.full_name : "----",
            address: item?.address ? item?.address : "----",
            email: item?.email ? item?.email : "----",
            designation: item?.designation ? item?.designation : "----",

            income: item?.salary
              ? `${item?.location?.map((cur) => cur?.currency)} ${item?.salary}/${item?.income_type === "Hourly_Rate"
                ? "hr"
                : item?.income_type === "Monthly_Salary"
                  ? "month"
                  : item?.income_type === "Daily_Income"
                    ? "day"
                    : ""
              }`
              : "----",
            // salary: item?.salary ? item?.salary : "----",
          };
        })
        : [],
  };

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allEmployeeRedux?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  const pageSize = 10

  return (
    <>
      <div className="border rounded-lg">
        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row  gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl whitespace-nowrap">
                {selectedLanguage?.name == "English" ? "Staff Data" : translation[592]?.value ? translation[592]?.value : "Staff Data"}
              </h2>
              {!employee_updated || loader ?
                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                  <p className="rounded-md h-5 w-12 mx-1"> </p>
                </div>
                :
                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                  <p className="text-primary">
                    {employeeAllCount ? employeeAllCount : 0}{" "}
                    {employeeAllCount && employeeAllCount > 1 ?
                      selectedLanguage?.name == "English" ? "Employees" : translation[399]?.value ? translation[399]?.value : "Employees"
                      : selectedLanguage?.name == "English" ? "Employee" : translation[1321]?.value ? translation[1321]?.value : "Employee"
                    }
                  </p>
                </div>
              }
            </div>
            <div className="flex gap-2 mb-00">
              {loadingPermission ?
                <Shimmer className={'w-40 lg:w-44'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                :
                <LoginInput
                  name="searchText"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Search Employee" : translation[423]?.value ? translation[423]?.value : "Search Employee"}
                  padding="py-1"
                  leftIcon={<Svgs.Search />}
                />
              }
            </div>
          </div>
          {loadingPermission ?
            <EmployeesHeadShimmer classOn1={'!w-12 lg:!16'} classOn2={'!w-12 lg:!16'} />
            :
            <div className="flex items-center  justify-end gap-3 w-fit h-[2.3rem]">
              {/* <LoginBtn
              onClick={() => {
                setFilter(!Filter);
                setemployeeText("");
                setdesignationText("");
                setServiceText("");
                setIncomeTypeText("");
              }}
              padding="px-3 py-2"
              className="rounded-lg border border-primary text-sm"
              bg="bg-white"
              
            >
              <div className="flex items-center gap-2 text-[#101928]">
                <Svgs.Filters />
              </div>
            </LoginBtn> */}
              {/* <LoginBtn padding='px-3 py-2' className='rounded-lg border border-primary text-sm' bg='bg-white' >
                            <div className='flex items-center gap-2 text-[#101928]'>
                                <Svgs.Import /> */}
              {/* <p>Filters</p> */}
              {/* </div>
                        </LoginBtn> */}
              <LoginBtn
                onClick={() => {
                  setFilter(!Filter);
                  // setemployeeText("");
                  // setdesignationText("");
                  // setServiceText("");
                  // setIncomeTypeText("");
                }}
                borderBtn

              >
                <div className="flex items-center gap-2 text-[#101928]">
                  <Svgs.Filters />
                </div>
              </LoginBtn>
              {exportLoader ? (
                <div className="h-[4rem] flex items-center justify-center">
                  <PreloaderSm />
                </div>
              ) :
                !Employee ? (
                  staff_data_filters()?.length > 0 ?
                    <CSVLink
                      {...exportStaffData}
                      target="_blank"
                      onClick={handleExportClick}
                    >
                      <IconButton filled>
                        <Svgs.Export />
                      </IconButton>
                    </CSVLink> :
                    <LoginBtn
                      className="rounded-lg border-2 border-primary"
                      bg="bg-white"
                      animation={false}
                      onClick={handleExportClick}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.Export bg="#FFFFFF" />
                      </div>
                    </LoginBtn>
                ) : (
                  ""
                )}

              {/* <LoginBtn className='rounded-lg border border-primary text-sm' >
                            <div className='flex items-center gap-2'>
                                <Svgs.Import fill='#fff' />
                                <p className='font-semibold'>Import List</p>
                            </div>
                        </LoginBtn> */}
            </div>
          }
        </div>
        {!employee_updated || loader ?
          <TableShimer cols={5} />
          :
          <div className="overflow-x-auto">
            <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(165px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
              <p>
                {selectedLanguage?.name == "English" ? "Employee Name" : translation[420]?.value ? translation[420]?.value : "Employee Name"}
              </p>
              {/* <p>
              {selectedLanguage?.name == "English" ? "Employee ID" : translation[448]?.value ? translation[448]?.value : "Employee ID"}
            </p> */}
              <p>
                {selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
              </p>
              <p>
                {selectedLanguage?.name == "English" ? "Email" : translation[476]?.value ? translation[476]?.value : "Email"}
              </p>
              <p>
                {selectedLanguage?.name == "English" ? "Designation" : translation[488]?.value ? translation[488]?.value : "Designation"}
              </p>
              <p>
                {selectedLanguage?.name == "English" ? "Income" : translation[593]?.value ? translation[593]?.value : "Income"}
              </p>
              {/* <p>Salary</p>
                        <p>Actions</p> */}
            </div>
            {employeeReduxStaffData && employeeReduxStaffData?.length > 0 && staff_data_filters()?.length > 0 ? (
              staff_data_filters()?.map((staff, i) => {
                return (
                  <div
                    className={`odd-bg text-[#3A3A3A] transition-all ${ShowDetails
                      ? "border-l-[4px] border-[#5679FFB2]"
                      : "border-l-[0px]"
                      }`}>
                    <>
                      <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(165px,1fr))] min-w-full w-fit">
                        <div
                          className={`before-left-arrow cursor-pointer ${ShowDetails == i + 1
                            ? "before-left-arrow-open"
                            : "before-left-arrow-close"
                            }`}
                          onClick={() => {
                            setShowDetails(ShowDetails != i + 1 ? i + 1 : 0);
                          }}
                        >
                          <div className="h-full flex items-center pr-2">
                            <p className="text-sm overflow-wrap-anywhere">
                              {staff?.full_name}
                            </p>
                          </div>
                        </div>
                        {/* <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          {staff?.employee_id}
                        </p>
                      </div> */}
                        <div className="flex items-center">
                          <p className="text-sm">{staff?.address}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">
                            {staff?.email}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">
                            {staff?.designation}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">
                            {staff?.location?.map((cur) =>
                              cur?.currency
                            )} {staff?.salary}/
                            {`${staff?.income_type === "Hourly_Rate"
                              ? "hr"
                              : staff?.income_type === "Monthly_Salary"
                                ? "month"
                                : staff?.income_type === "Daily_Income"
                                  ? "day"
                                  : ""
                              }`}
                          </p>
                        </div>
                        {/* <div className='flex items-center'>
                                                    <p className='text-sm line-clamp-1 pr-3'>{businessCurrency?.currency?.code} {staff?.salary}</p>
                                                </div> */}
                        {/* <div className='flex items-center gap-2'>
                                                    <div className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center'>
                                                        <Svgs.Edit fill='#7D7D7D' />
                                                    </div>
                                                    <div className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center'>
                                                        <Svgs.Delete fill='#7D7D7D' />
                                                    </div>
                                                </div> */}
                      </div>
                      {ShowDetails == i + 1 ? (
                        <div className="scale-in-center bg-[#FAF9FF]">
                          <div className="bg-[#FAF9FF] px-6 py-3 inline-grid xl:grid grid-cols-[repeat(4,minmax(180px,1fr))]">
                            <div className="flex items-center gap-12">
                              <div className="flex items-center gap-3 col-span-1">
                                {staff?.image ?
                                  <img
                                    src={staff?.image}
                                    className="h-[3rem] w-[3rem] object-cover rounded-lg"
                                  />
                                  :
                                  <LabelAvatar iconLabel={staff?.full_name} index={i} className={'!h-[3rem] !w-[3rem] !rounded-lg'} />
                                }
                                <div className="flex flex-col gap-2 flex-1">
                                  <p className="text-black font-semibold">
                                    {staff?.full_name}
                                  </p>
                                  {/* <h2 className="text-[#949494] text-sm">
                                  1233356{" "}
                                </h2> */}
                                </div>
                              </div>
                              <div className="h-full w-[1px] bg-[#3F3F441A] mr-3"></div>
                            </div>
                            <div className="grid grid-cols-7 col-span-3 gap-5">
                              <div className="col-span-2">
                                <div className="flex flex-col gap-4">
                                  <div>
                                    <h2 className="text-[#949494] text-sm">
                                      {selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}{" "}
                                    </h2>
                                    <p className="text-sm">{staff?.address}</p>
                                  </div>
                                  <div>
                                    <h2 className="text-[#949494] text-sm">
                                      {selectedLanguage?.name == "English" ? "Income" : translation[593]?.value ? translation[593]?.value : "Income"}
                                    </h2>
                                    <p className="text-sm">
                                      {
                                      staff?.income_type === "Hourly_Rate"
                                      ? "Hourly Rate"
                                      : staff?.income_type === "Monthly_Salary"
                                        ? "Monthly Salary"
                                        : staff?.income_type === "Daily_Income" 
                                        ? "Daily Income": "---" 
                                       }
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-span-3">
                                <div className="flex flex-col gap-4">
                                  <div>
                                    <h2 className="text-[#949494] text-sm">
                                      {selectedLanguage?.name == "English" ? "Email" : translation[476]?.value ? translation[476]?.value : "Email"}
                                    </h2>
                                    <p className="text-sm max-w-xs break-words">{staff?.email}</p>
                                  </div>
                                  <div>
                                    <h2 className="text-[#949494] text-sm">
                                      {selectedLanguage?.name == "English" ? "Salary" : translation[492]?.value ? translation[492]?.value : "Salary"}
                                    </h2>
                                    <p className="text-sm">
                                      {staff?.location?.map((cur) =>
                                        cur?.currency
                                      )}{" "}
                                      {staff?.salary}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-span-2">
                                <div className="flex flex-col gap-4">
                                  <div>
                                    <h2 className="text-[#949494] text-sm">
                                      {selectedLanguage?.name == "English" ? "Designation" : translation[488]?.value ? translation[488]?.value : "Designation"}
                                    </h2>
                                    <p className="text-sm">
                                      {staff?.designation}
                                    </p>
                                  </div>
                                  {/* <div>
                                  <h2 className="text-[#949494] text-sm">
                                    Services
                                  </h2>
                                  {staff?.employee_info?.services?.length > 0 &&
                                    staff?.employee_info?.services?.map(
                                      (srvc) => {
                                        return (
                                          <span className="text-sm">
                                            {" "}
                                            {srvc?.name}{" "}
                                          </span>
                                        );
                                      }
                                    )}
                                </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                );
              })
            ) : (
              <NoReportDataFound />
            )}
          </div>
        }

        {(staff_data_filters()?.length > 0 && !loader) &&
          <Pagination
            currentPage={currentPage}
            totalCount={Math.ceil(employeePages)}
            pageSize={pageSize}
            onPageChange={page => setCurrentPage(page)}
          />
        }
      </div>

      {Filter && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Filter" : translation[341]?.value ? translation[341]?.value : "Filter"}
          open={Filter} close={setFilter}
        >
          <div className="flex flex-col gap-5">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
              <CircleIcon>
                <Svgs.FilterPopup />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Search By Filters" : translation[343]?.value ? translation[343]?.value : "Search By Filters"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Apply filters to get desired results" : translation[594]?.value ? translation[594]?.value : "Apply filters to get desired results"}
                </p>
              </div>
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-5">
              <PaginationDropdown
                title={selectedLanguage?.name == "English" ? "Employee" : translation[32]?.value ? translation[32]?.value : "Employee"}
                placeholder={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                name={"employeeText"}
                value={employeeText}
                onChange={(e) => setemployeeText(e.target.value)}
                setPage={setEmployeePage}
                page={employeePage}
                setDropdownSearch={setEmployeeSearch}
                dropdownSearch={employeeSearch}
                lastPage={employeePaginationData?.employeePages}
                currentPage={employeePaginationData?.employeeCurrentPage}
                loading={employeeLoader}
                options={employeeDropdownRedux.map((empl, i) => {
                  return {
                    label: empl.full_name,
                    value: empl.id,
                    otherData: {
                      image: empl?.image,
                      DataOne: empl.email,
                      showImage: true,
                      index: i
                    },
                    email: empl.email,
                    mobile_number: empl.mobile_number
                  };
                })
                }
              />
              <Dropwdown
                title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                placeholder={selectedLanguage?.name == "English" ? "Select Location" : translation[20]?.value ? translation[20]?.value : "Select Location"}
                name={"locationText"}
                value={locationText}
                onChange={(e) => setLocationText(e.target.value)}
                options={reduxlocationsList?.map((adrs) => {
                  return {
                    label: adrs?.address_name,
                    value: adrs?.id,
                  };
                })}
              />

              <Dropwdown
                title={selectedLanguage?.name == "English" ? "Designation" : translation[488]?.value ? translation[488]?.value : "Designation"}
                placeholder={selectedLanguage?.name == "English" ? "Select Designation" : translation[540]?.value ? translation[540]?.value : "Select Designation"}
                name={"designationText"}
                value={designationText}
                onChange={(e) => setdesignationText(e.target.value)}
                // options={[...new Set(employeeRedux.map(itm => itm?.employee_info?.designation))]?.map((empl_designation) => {
                //   return {
                //     label: empl_designation,
                //     value: empl_designation,
                //   };
                // })}
                options={DESIGNATIONS}
              />
              {/* <Dropwdown
                title="Service"
                placeholder="Select Service"
                name={"serviceText"}
                value={serviceText}
                onChange={(e) => setServiceText(e.target.value)}
                options={[]}
              /> */}

              <Dropwdown
                title={selectedLanguage?.name == "English" ? "Income Type" : translation[432]?.value ? translation[432]?.value : "Income Type"}
                placeholder={selectedLanguage?.name == "English" ? "Select Income Type" : translation[491]?.value ? translation[491]?.value : "Select Income Type"} name={"incomeTypeText"}
                value={incomeTypeText}
                onChange={(e) => setIncomeTypeText(e.target.value)}
                options={[
                  {
                    value: "Hourly_Rate",
                    label: selectedLanguage?.name == "English" ? "Hourly Rate" : translation[498]?.value ? translation[498]?.value : "Hourly Rate"
                  },
                  {
                    value: "Daily_Income",
                    label: selectedLanguage?.name == "English" ? "Daily Income" : translation[499]?.value ? translation[499]?.value : "Daily Income"
                  },
                  {
                    value: "Monthly_Salary",
                    label: selectedLanguage?.name == "English" ? "Monthly Salary" : translation[500]?.value ? translation[500]?.value : "Monthly Salary"
                  },
                ]}
              />
            </div>
            <div className="flex items-center justify-start">
              <LoginBtn
                text={selectedLanguage?.name == "English" ? "Apply Filter" : translation[595]?.value ? translation[595]?.value : "Apply Filter"}
                onClick={applyFilters}
              />
              <div className="ml-3">
                <LoginBtn
                  text={selectedLanguage?.name == "English" ? "Clear" : translation[340]?.value ? translation[340]?.value : "Clear"}
                  onClick={clearFilters}
                />
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default StaffData;
