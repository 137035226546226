import { getEmployeeDropDownList } from 'Adapters/Api/employee'
import { create_refund_time_period, update_refund_time_period } from 'Adapters/Api/financialSettings'
import useObjectKeys from 'Hooks/useObjectKeys'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import useValidations from 'Hooks/useValidations'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const initialState = {
    location: "",
    number_of_days: null,
    allowed_refund: []
}

const useAddRefundTimePeriod = ({ close, isEdit, setIsEdit, details }) => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const { objectKeyConvertToArray } = useObjectKeys();
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()

    const bussiness = getDataFromLocalStorage('business_data')
    const access_token = getDataFromLocalStorage('access_token')
    const { setErrors, validation, errors, onBlurHandler } = useValidations();

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const employeeRedux = state?.employee?.employees;

    const [refundPeriod, setRefundPeriod] = useState(initialState)
    const [submitLoader, setSubmitLoader] = useState(false)
    const [fetchRefund, setFetchRefund] = useState(true)

    const onChangeHandler = (e) => {
        const { value, name } = e?.target
        if (value != 'no_value') {
            if (name === "allowed_refund" && value != 'no_value') {
                if (!refundPeriod?.allowed_refund?.find(itm => itm?.employee == value)) {

                    setRefundPeriod((prev) => ({ ...prev, [name]: [...prev.allowed_refund, { employee: value }] }));
                    setErrors((prev) => ({ ...prev, [name]: "" }))
                    return;
                }
            } else {
                setRefundPeriod((prev) => ({ ...prev, [name]: value }))
                setErrors((prev) => ({ ...prev, [name]: "" }))
            }

        }
    }


    const filteredEmployee = useMemo(() => {
        if (refundPeriod?.location) {
            return employeeRedux?.filter((emp) => !refundPeriod?.allowed_refund?.find(itm => itm?.employee == emp?.id)).map((empl, i) => {
                return {
                    label: empl.full_name,
                    value: empl.id,
                    otherData: {
                        image: empl?.image,
                        DataOne: empl.email,
                        showImage: true,
                        index: i
                    },
                    email: empl.email,
                    mobile_number: empl.mobile_number
                };
            })
        }
    }, [employeeRedux, refundPeriod, isEdit])

    useEffect(() => {
        if (isEdit) {
            setRefundPeriod({
                id: details?.id,
                number_of_days: details?.number_of_days,
                location: details?.location,
                allowed_refund: details?.allowed_refund
            })
        }
    }, [isEdit])


    const onSubmit = () => {
        let requiredFields = {}
        const { location, number_of_days, allowed_refund } = refundPeriod
        if (allowed_refund?.length == 0) {
            requiredFields = { location, number_of_days, allowed_refund }
        } else {
            requiredFields = { location, number_of_days }
        }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);

        if (objectKeyConvertToArray(result)?.length === 0) {
            setSubmitLoader(true)
            const success = () => {
                setRefundPeriod(initialState)
                setSubmitLoader(false)
                setIsEdit(false)
                close(false)
            }
            const fail = () => {
                setSubmitLoader(false)
            }
            if (isEdit) {
                dispatch(update_refund_time_period(access_token, { ...refundPeriod }, success, fail))
            } else {
                dispatch(create_refund_time_period(access_token, { ...refundPeriod }, success, fail))
            }
        }
    }

    useEffect(() => {
        if (refundPeriod?.location) {
            dispatch(getEmployeeDropDownList(refundPeriod?.location))
        }
    }, [refundPeriod?.location])

    return {
        selectedLanguage, translation,
        refundPeriod, submitLoader,
        onBlurHandler, onChangeHandler,
        errors, onSubmit, filteredEmployee,
        employeeRedux, setRefundPeriod
    }
}

export default useAddRefundTimePeriod