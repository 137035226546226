import React from 'react'
import AccountSetupCard from './AccountSetupCard'
import Svgs from 'Assets/svgs'
import { useSelector } from "react-redux";

const DashboarHRM = () => {
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <div>
            <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
                <div className='flex items-center gap-5 lg:w-fit w-full lg:px-0 lg:pb-[2rem] lg:py-0 p-3 pb-8'>
                    <h2 class="font-semibold text-2xl">{selectedLanguage?.name == "English" ? "HRM" : translation[1469]?.value ? translation[1469]?.value : "HRM"}</h2>
                </div>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem] xl:pr-[4rem] pr-0">
                    <AccountSetupCard
                        link="/dashboard/hrm/leave-management"
                        icon={<Svgs.Customer_Segmen />}
                        title={selectedLanguage?.name == "English" ? "Leave Management" : translation[1470]?.value ? translation[1470]?.value : "Leave Management"}
                        description={selectedLanguage?.name == "English" ? "Manage client through dynamic, static and multiple filters" : translation[1356]?.value ? translation[1356]?.value : "Manage client through dynamic, static and multiple filters"}

                    />
                    {/* <AccountSetupCard
                        link="/dashboard/hrm/performance-evaluation"
                        icon={<Svgs.Campaigns />}
                        title={selectedLanguage?.name == "English" ? "Performance Evaluation" : translation[1471]?.value ? translation[1471]?.value : "Performance Evaluation"}
                        description={selectedLanguage?.name == "English" ? "Manage payment methods, taxes, and apply tax on business locations" : translation[1358]?.value ? translation[1358]?.value : "Manage payment methods, taxes, and apply tax on business locations"}
                    /> */}
                    {/* <AccountSetupCard
                        link="/dashboard/hrm/staff-training"
                        icon={<Svgs.Coversion_tracker />}
                        title={selectedLanguage?.name == "English" ? "Staff Training" : translation[1472]?.value ? translation[1472]?.value : "Staff Training"}
                        description={selectedLanguage?.name == "English" ? "Manage booking settings for the appointment booking" : translation[1360]?.value ? translation[1360]?.value : "Manage booking settings for the appointment booking"}
                    /> */}
                    {/* <AccountSetupCard
                        link="/dashboard/hrm/holiday"
                        icon={<Svgs.Lead_Generation />}
                        title={selectedLanguage?.name == "English" ? "Holiday" : translation[1473]?.value ? translation[1473]?.value : "Holiday"}
                        description={selectedLanguage?.name == "English" ? "Manage theme color, menu, and calendar customizations" : translation[663]?.value ? translation[663]?.value : "Manage theme color, menu, and calendar customizations"}
                    /> */}

                    <AccountSetupCard
                        link="/dashboard/hrm/weekend-management"
                        icon={<Svgs.Lead_Generation />}
                        title={selectedLanguage?.name == "English" ? "Weekend Management" : translation[1497]?.value ? translation[1497]?.value : "Weekend Management"}
                        description={selectedLanguage?.name == "English" ? "Manage theme color, menu, and calendar customizations" : translation[663]?.value ? translation[663]?.value : "Manage theme color, menu, and calendar customizations"}
                    />

                </div>
            </div>
        </div>
    )
}

export default DashboarHRM