import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAttendanceData,
  getAttendanceDataWithNoPagination,
  getsAttendanceImport,
} from "../../../../../../Adapters/Api/attendance";
import { getEmployeeDropDownList, getEmployeeDropDownListPagination } from "../../../../../../Adapters/Api/employee";
import Svgs from "../../../../../../Assets/svgs";
import { MONTHS } from "../../../../../../Constants/Data/Months";
import useSetDataInLocalStorage from "../../../../../../Hooks/useSetDataInLocalStorage";
import Dropwdown from "../../../../Elements/Dropwdown";
import LoginBtn from "../../../../Elements/LoginBtn";
import LoginInput from "../../../../Elements/LoginInput";
import Popup from "../../../../Elements/Popup";
import PreloaderFull from "../../../../Elements/PreloaderFull";
import StatusBtn from "../../../../Elements/StatusBtn";
import useEmployeeForms from "../../AddEmployee/useEmployeeForms";
import useAttendance from "../useAttendance";

import { useLocation } from "new-components/location/helper";
import moment from "moment";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import Pagination from "new-components/Pagination";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoAttendanceRecordFound from "../../Elements/NoAttendanceRecordFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";


let timeOutId = undefined
let employeeTimeOutId = undefined

const LeoDay = ({ apiCallState, setApiCallState }) => {
  const { selected_location } = useLocation();
  const state = useSelector((state) => state);
  const attendanceList = state?.attendance?.attendance;
  const allAttendanceList = state?.attendance?.allAttendance;
  const attendanceRedux = attendanceList

  const pages = state?.attendance?.pages
  const count = state?.attendance?.count


  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const attendance_updated = state?.attendance?.attendance_updated;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const employeeRedux = state?.employee?.employees

  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");

  const [exportLoader, setExportLoader] = useState(false);
  const [ShowDetails, setShowDetails] = useState(0);
  const [ImportAttendance, setImportAttendance] = useState(false);
  const [filterAttendance, setFilterAttendance] = useState();
  const [DateRange, setDateRange] = useState();
  const [selectYear, setSelectYear] = useState("");
  const [search_text, setSearchText] = useState("");
  const [dropdown_text, setDropdownText] = useState("");
  const [loader, setLoader] = useState(true)
  const [noPaginationLoader, setNoPaginationLoader] = useState(true)
  const [isToastVisible, setIsToastVisible] = useState(false);
  const { setIsEdit } = useEmployeeForms();
  const {
    attendanceDeleteHandler,
    setDateRangePopup,
    selectedDateRange,
    isDelete,
    setDelete,
    currentPage, setCurrentPage,
    deleteId, setDeleteId,
    deleteLoading, isAddLieuDay
  } = useAttendance();
  const { from, to } = selectedDateRange;

  const [employeeSearch, setEmployeeSearch] = useState("");
  const [employeePage, setEmployeePage] = useState(1)
  const [employeeLoader, setEmployeeLoader] = useState(false)

  const employeeRedux = state?.employee?.employeesDropdown;
  const employeePaginationData = state?.employee?.employeeDropdownPaginationData

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    if (apiCallState) {
      setCurrentPage(1)
      getLieuDay()
      setApiCallState(false)
    }
  }, [apiCallState])

  useEffect(() => {
    if (DateRange == "Date") {
      setDateRangePopup("Date");
    } else if (DateRange != "") {
      setDateRangePopup("Month");
    }
  }, [DateRange]);

  const getLieuDay = () => {
    const date = new Date(`${DateRange} 1, ${selectYear}`);
    const monthNumber = date.getMonth() + 1;
    const formattedMonth = String(monthNumber).padStart(2, '0');
    const nextMonthDate = new Date(date);
    nextMonthDate.setMonth(monthNumber);
    // Subtract one day to get the last day of the current month
    nextMonthDate.setDate(0);
    const lastDay = nextMonthDate.getDate();

    const startDate = `${selectYear}-${formattedMonth}-01`
    const endDate = `${selectYear}-${formattedMonth}-${lastDay}`

    if (selected_location && startDate && endDate && selectYear) {
      if (search_text === null) {
        // Initial load with empty search_text
        dispatch(getAttendanceData(currentPage, selected_location, startDate, endDate, "", dropdown_text, setLoader, true))
        dispatch(getAttendanceDataWithNoPagination(selected_location, startDate, endDate, "", dropdown_text, setNoPaginationLoader, true))
      } else {
        if (timeOutId) {
          clearTimeout(timeOutId)
        }
        timeOutId = setTimeout(() => {
          dispatch(getAttendanceData(currentPage, selected_location, startDate, endDate, search_text, dropdown_text, setLoader, true))
          dispatch(getAttendanceDataWithNoPagination(selected_location, startDate, endDate, search_text, dropdown_text, setNoPaginationLoader, true))
        }, 500);
      }
    }
  }

  useEffect(() => {
    getLieuDay()
  }, [currentPage, selected_location, DateRange, selectYear, search_text, dropdown_text]);



  useEffect(() => {
    setLoader(true)
    setNoPaginationLoader(true)
    setCurrentPage(1)
  }, [selected_location, DateRange, selectYear, search_text, dropdown_text])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  useEffect(() => {
    if (selected_location) {
      if (employeeSearch === null) {
        // Initial load with empty search_text
        dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
      } else {
        if (employeeTimeOutId) {
          clearTimeout(employeeTimeOutId)
        }
        employeeTimeOutId = setTimeout(() => {
          dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
        }, 500);
      }
    }
  }, [employeeSearch, selected_location, employeePage])

  const submit = (file) => {
    let form_data = new FormData();
    form_data.append("file", file);
    form_data.append("business", businessData?.id);
    getsAttendanceImport(form_data);
  };

  // export start
  let attendanceHeader = [
    {
      label: selectedLanguage?.name == "English" ? "Employee ID" : translation[448]?.value ? translation[448]?.value : "Employee ID",
      key: "employee_id"
    },
    {
      label: selectedLanguage?.name == "English" ? "Employee Name" : translation[420]?.value ? translation[420]?.value : "Employee Name",
      key: "employee_name"
    },
    {
      label: selectedLanguage?.name == "English" ? "Date" : translation[450]?.value ? translation[450]?.value : "Date",
      key: "date"
    },
    {
      label: selectedLanguage?.name == "English" ? "In Time" : translation[450]?.value ? translation[450]?.value : "In Time",
      key: "in_time"
    },
    {
      label: selectedLanguage?.name == "English" ? "Out Time" : translation[451]?.value ? translation[451]?.value : "Out Time",
      key: "out_time"
    },
    {
      label: selectedLanguage?.name == "English" ? "Total Hours" : translation[457]?.value ? translation[457]?.value : "Total Hours",
      key: "total_hours"
    },
  ];

  const exportAssetData = {
    filename: "Employee Attendance Report.csv",
    headers: attendanceHeader,
    data:
      allAttendanceList?.length > 0
        ? allAttendanceList?.flatMap((attendance) =>
          attendance?.schedule?.map((item) => {
            return {
              employee_id: attendance?.employee_id ? attendance?.employee_id : "----",
              employee_name: attendance?.full_name ? attendance?.full_name : "----",
              date: item?.date ? moment(item.date).format("YYYY-MM-DD") : "----",
              in_time: item?.start_time ? item.start_time : "----",
              out_time: item?.end_time ? item.end_time : "----",
              total_hours: item?.total_hours ? item.total_hours : "----",
            };
          })
        )
        : [],
  };

  // export end


  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allAttendanceList?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", { toastId: "toast", onClose: () => setIsToastVisible(false) });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  // getLieuDayData(getLieuDay)

  //attendance all filter handle here like search, dropdown and month filter
  const filter_attendance = () => {
    let attendance_list = attendanceRedux
    return attendance_list;
  };

  // default states month, in filter date assing to state here
  useEffect(() => {
    const dt = new Date();
    const selectedDate = DateRange && selectYear
      ? moment(`${dt.getDate()}-${DateRange}-${dt.getFullYear()}`).format(
        "YYYY-M-D"
      )
      : "";
    const date = new Date(selectedDate ? selectedDate : new Date());
    const current_month = date.getMonth();
    setDateRange(DateRange ? DateRange : MONTHS[new Date().getMonth()]);
    setSelectYear(selectYear ? selectYear : date?.getFullYear());
    const result = filter_attendance()?.map((itm) => {
      const current_month_schedule = itm?.schedule?.filter((dt) => {
        if (moment(dt?.date).format("MMMM") == DateRange &&
          moment(dt?.date).format("YYYY") == selectYear) {
          return true;
        }
      });
      return {
        ...itm,
        schedule: current_month_schedule,
      };
    });

    setFilterAttendance(result);
  }, [attendanceRedux, search_text, dropdown_text, DateRange, selectYear, state.locations.selected_location]);

  const PageSize = 10
  return (
    <div className="border rounded-lg">
      <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b 2xl:flex-row flex-col gap-3">
        <div className="flex gap-3 items-center justify-between w-full">
        <div className="flex items-center justify-between flex-1 w-full md:gap-3 gap-1.5 ">
          <div className="flex items-center sm:gap-4 gap-1 ">
            <h2 className="font-semibold text-xl whitespace-nowrap">
              {selectedLanguage?.name == "English" ? "LIEU Day Details" : translation[424]?.value ? translation[424]?.value : "LIEU Day Details"}
            </h2>

            {!attendance_updated || loader ?
              <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                <p className="rounded-md h-5 w-12 mx-1"> </p>
              </div>
              :
              <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                <p className="text-[#101928]">
                  {count ? count : 0}{" "}
                  {count && count > 1 ?
                    selectedLanguage?.name == "English" ? "Employees" : translation[399]?.value ? translation[399]?.value : "Employees"
                    : selectedLanguage?.name == "English" ? "Employee" : translation[1321]?.value ? translation[1321]?.value : "Employee"
                  }
                </p>
              </div>
            }
          </div>
          
        </div>
         
          <div className="mb-00 min-w-[5rem]">
              {loadingPermission ?
                <Shimmer className={'w-40 lg:w-44'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                :
                <LoginInput
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Search Employee" : translation[423]?.value ? translation[423]?.value : "Search Employee"}
                  padding="py-1"
                  leftIcon={<Svgs.Search />}
                  value={search_text}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              }
            </div>
        </div>
         <div className="flex flex-col items-end sm:flex-row justify-end w-full sm:items-center gap-3">
          {loadingPermission ?
            <EmployeesHeadShimmer classOn1={'!w-32 lg:!w-44 sm:!w-40'} classOn2={'!w-32 lg:!w-44 sm:!w-40'} classOn3={'!w-32 lg:!w-44 sm:!w-40'} classOn4={'!w-12 lg:!16'} has4Itm />
            :
            <div className="flex items-center lg:justify-center justify-end gap-3 w-full sm:w-fit flex-wrap sm:flex-nowrap">
              {/* <input
                  type="file"
                  accept=".csv"
                  id="csvFileforproduct"
                  onChange={(e) => {
                    submit(e.target.files[0]);
                    setImportAttendance(false);
                  }}
                  hidden
                  value={""}
                /> */}



              {/* <Dropwdown
                  title=""
                
                  options={[
                    { label: selectedLanguage?.name == "English" ? "All Employee" : translation[241]?.value ? translation[241]?.value : "All Employee", value: "" },
                    ...employeeRedux
                      ?.map((obj) => {
                        return {
                          label: obj?.full_name,
                          value: obj?.id,
                        };
                      }),
                  ]}
                /> */}

            
              <PaginationDropdown
                title=""
                setPage={setEmployeePage}
                page={employeePage}
                setDropdownSearch={setEmployeeSearch}
                dropdownSearch={employeeSearch}
                lastPage={employeePaginationData?.employeePages}
                currentPage={employeePaginationData?.employeeCurrentPage}
                placeholder={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                value={dropdown_text}
                showTitle={false}
                onChange={(e) => setDropdownText(e.target.value)}
                // customHeight={'h-[1.25rem]'}
                custom_min_width={'lg:min-w-[16rem] sm:min-w-[20rem] min-w-full'}
                loading={employeeLoader}
                options={[
                  {
                    label: selectedLanguage?.name == "English" ? "All Employee" : translation[241]?.value ? translation[241]?.value : "All Employee", value: ""
                  },
                  ...employeeRedux?.map((empl, i) => {
                    return {
                      label: empl.full_name,
                      value: empl.id,
                      otherData: {
                        image: empl?.image,
                        DataOne: empl.email,
                        showImage: true,
                        index: i
                      },
                      email: empl.email,
                      mobile_number: empl.mobile_number
                    };
                  })
                ]}
              />
  <div className="flex items-center gap-3 w-full sm:w-fit">
              <div className="grid grid-cols-2 gap-3 items-center sm:flex w-full">
              <Dropwdown
                title=""
                placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                showTitle={false}
                value={DateRange}
                onChange={(e) => {
                  setDateRange(e.target.value);
                }}
                options={MONTHS.map((mnth, i) => {
                  return {
                    label: mnth,
                    value: mnth,
                  };
                })}
              />
              <Dropwdown
                title=""
                placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                showTitle={false}
                value={selectYear}
                onChange={(e) => {
                  setSelectYear(e.target.value);
                }}
                options={[
                  { label: 2022, value: 2022 },
                  { label: 2023, value: 2023 },
                  { label: 2024, value: 2024 },
                  { label: 2025, value: 2025 },
                ]}
              />
              </div>
              {exportLoader ? (
                <div className="h-[4rem] flex items-center justify-center">
                  <PreloaderSm />
                </div>
              ) :
                !employee ? (
                  filterAttendance?.length > 0 ?
                    <CSVLink
                      {...exportAssetData}
                      target="_blank"
                      onClick={handleExportClick}
                    >
                      <IconButton filled>
                        <Svgs.Export />
                      </IconButton>
                    </CSVLink> :
                    <LoginBtn
                      className="rounded-lg border-2 border-primary"
                      bg="bg-white"
                      animation={false}
                      onClick={handleExportClick}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.Export bg="#FFFFFF" />
                      </div>
                    </LoginBtn>
                ) : (
                  ""
                )}
                </div>
            </div>
          }
         </div>
      </div>

      {!attendance_updated || loader ?
        <TableShimer cols={5} />
        :
        <div className="overflow-x-auto">
          <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(180px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
            <p>{selectedLanguage?.name == "English" ? "Employee Name" : translation[420]?.value ? translation[420]?.value : "Employee Name"}</p>
            <p>{selectedLanguage?.name == "English" ? "Working Days" : translation[1528]?.value ? translation[1528]?.value : "Working Days"}</p>
            {/* <p>{selectedLanguage?.name == "English" ? "In Time" : translation[450]?.value ? translation[450]?.value : "In Time"}</p>
            <p>{selectedLanguage?.name == "English" ? "Out Time" : translation[451]?.value ? translation[451]?.value : "Out Time"}</p> */}
            <p>{selectedLanguage?.name == "English" ? "Salary" : translation[492]?.value ? translation[492]?.value : "Salary"}</p>
            <p>{selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}</p>
            <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
            {/* <p>Date</p> */}
          </div>
          <div
            className={`odd-bg text-[#3A3A3A] transition-all ${ShowDetails
              ? "border-l-[4px] border-[#5679FFB2]"
              : "border-l-[0px]"
              }`}
          >
            {filterAttendance && filterAttendance?.length > 0 ? (
              filterAttendance?.map((item, i) => {
                let startTime =
                  item?.in_time && item?.in_time.split(":").join(".");
                let endTime =
                  item?.out_time && item?.out_time.split(":").join(".");
                return (
                  <>
                    <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(180px,1fr))] min-w-full w-fit">
                      <div
                        className={`before-left-arrow cursor-pointer ${ShowDetails == i + 1
                          ? "before-left-arrow-open"
                          : "before-left-arrow-close"
                          }`}
                        onClick={() => {
                          setShowDetails(ShowDetails != i + 1 ? i + 1 : 0);
                        }}
                      >
                        <div className="h-full flex items-center">
                          <div className="flex items-center gap-3 col-span-1">
                            {
                              item?.image ?
                                <img
                                  src={item?.image}
                                  className="h-[3rem] !w-[3rem] object-cover rounded-lg"
                                />
                                :
                                <LabelAvatar iconLabel={item?.full_name} index={i} className="!h-[3rem] !w-[3rem] !rounded-lg"
                                />

                            }
                            <div className="flex flex-col gap-2 flex-1">
                              <p className="text-black font-semibold text-sm">
                                {item?.full_name
                                  ? item?.full_name
                                  : "----"}
                              </p>
                              <h2 className="text-[#949494] text-sm">
                                {item?.employee_id
                                  ? item?.employee_id
                                  : "-----"}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {item?.schedule?.length}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {item?.salary} /{" "}
                          {item?.income_type === "Daily_Income"
                            ? "daily"
                            : item?.income_type ===
                              "Hourly_Rate"
                              ? "Hr"
                              : item?.income_type ===
                                "Monthly_Salary"
                                ? "month"
                                : ""}
                        </p>
                      </div>
                      {/* <div className="flex items-center">
                        <p className="text-sm">
                          {item?.schedule[0]?.start_time
                            ? item?.schedule[0]?.start_time
                            : "----"}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {item?.schedule[0]?.end_time
                            ? item?.schedule[0]?.end_time
                            : "----"}
                        </p>
                      </div> */}
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          {item?.schedule?.length > 0 ?
                            <Moment format="MMMM">
                              {item?.schedule[0]?.date}
                            </Moment> : DateRange}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <StatusBtn
                          status={item?.is_active ? "Active" : "InActive"}
                          text={
                            item?.is_active
                              ? selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                              : selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                          }
                        />
                      </div>
                    </div>
                    {ShowDetails == i + 1
                      ? item?.schedule?.map((schl, ind) => {
                        return (
                          <>
                            <div className="bg-[#FAF9FF] px-6 py-3 grid grid-cols-4 col-span-3 gap-y-5 gap-x-3 w-full">
                              <div className="flex items-center gap-3">
                                <div className="flex justify-between items-center">
                                  <p className="font-semibold text-lg text-[#949494]">
                                    #{ind + 1}
                                  </p>
                                  <div className="h-full w-[5px] bg-[#1616a51a] mr-3"></div>
                                </div>
                                <div className="flex flex-col gap-1">
                                  <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Date" : translation[450]?.value ? translation[450]?.value : "Date"}</p>
                                  <p className="text-black font-semibold">
                                    {moment(schl?.date).format('DD-MM-YYYY')}
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm text-[#969BA0]"> {selectedLanguage?.name == "English" ? "In Time" : translation[450]?.value ? translation[450]?.value : "In Time"}</p>
                                <p className="text-black font-semibold">
                                  {schl?.start_time
                                    ? schl?.start_time
                                    : "----"}
                                </p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm text-[#969BA0]">  {selectedLanguage?.name == "English" ? "Out Time" : translation[451]?.value ? translation[451]?.value : "Out Time"}</p>
                                <p className="text-black font-semibold">
                                  {schl?.end_time
                                    ? schl?.end_time
                                    : "----"}
                                </p>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="text-sm text-[#969BA0]">{selectedLanguage?.name == "English" ? "Total Hours" : translation[457]?.value ? translation[457]?.value : "Total Hours"}</p>
                                <p className="text-black font-semibold">
                                  {schl?.total_hours
                                    ? schl?.total_hours
                                    : "----"}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })
                      : ""}
                  </>
                );
              })
            ) : (
              <>
                <NoAttendanceRecordFound />
              </>
            )}
          </div>
        </div>
      }
      {/* Pagination */}
      {(!loader && filterAttendance?.length > 0) &&
        <Pagination
          currentPage={currentPage}
          totalCount={Math.ceil(pages)}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      }

      {isDelete && (
        <Popup
          heading="Are you sure you want to delete this Attendance?"
          onclose={() => {
            setDelete(false);
            setDeleteId()
          }}
        >
          <div className="">
            <p>
              Are you sure you want to delete this Attendance? You won't be able to
              see this again.
            </p>
            <div className="flex items-center justify-start mt-[2rem]">
              <LoginBtn
                deleteBtn
                text="Yes! Delete"
                disabled={deleteLoading}
                loading={deleteLoading}
                onClick={() => attendanceDeleteHandler(deleteId)}
              />
            </div>
          </div>
        </Popup>
      )}
    </div>
  )
}

export default LeoDay