const PrivacyPolicy = () => {
  return (
    <>
      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">
          Privacy Policy for Employee App
        </h1>
        <p className="mb-4">
          This Privacy Policy outlines the information collection, usage, and
          handling procedures for the employee-only application, detailing the
          data that is gathered and how it is managed within the platform.
        </p>
        <div className="">
          <h2 className="text-2xl font-bold mb-2">1. Information Collection</h2>
          <p className="mb-4">
            <span className="font-semibold">Types of Data Collected:</span>
            <br />
            <span className="ml-4">
              a. Employee Information: When an admin registers an employee, the
              following data is collected:
              <ul className="list-disc">
                <li className=" ml-8">
                  <span className="">
                    Personal credentials: name, contact details, and login
                    credentials.
                  </span>
                </li>
              </ul>
            </span>
          </p>
        </div>
        <div className="">
          <h2 className="text-2xl font-bold mb-2">2. Data Usage</h2>
          <p className="mb-4">
            <span className="ml-4">
              a. Purpose of Collection: The collected information is used for
              the following purposes:
              <ul className="list-disc">
                <li className=" ml-8">
                  <span className="">
                    Viewing sales, achieved targets, commissions, tips, and
                    work-related statistics.
                  </span>
                </li>
                <li className=" ml-8">
                  <span className="">Managing appointments and updates.</span>
                </li>
                <li className=" ml-8">
                  <span className="">
                    Tracking attendance, income, working days, and leaves.
                  </span>
                </li>
              </ul>
            </span>
            <span className="">
              b. Secure Handling: All employee data is securely managed,
              accessible only within the app for authorized employees and
              administrators.
            </span>
          </p>
        </div>
        <div className="">
          <h2 className="text-2xl font-bold mb-2">
            3. Information Accessibility
          </h2>
          <p className="mb-4">
            <span className="">
              a. Employee Access: Employees have restricted access to their
              individual data, including sales, commissions, appointments,
              attendance, income details, and leave applications/status.
            </span>
          </p>
        </div>
        <div className="">
          <h2 className="text-2xl font-bold mb-2">
            4. Sharing of Employee Data
          </h2>
          <p className="mb-4">
            <span className="">
              a. Internal Usage: The data within the app is solely for
              employee-related functionalities and is not shared externally.
            </span>
          </p>
        </div>
        <div className="">
          <h2 className="text-2xl font-bold mb-2">
            5. Data Retention and Deletion
          </h2>
          <p className="mb-4">
            <span className="">
              a. Retention Period: Employee data is retained as required for
              operational and legal purposes.
            </span>
            <span className="">
              b. Deletion Procedures: Upon request or upon the conclusion of
              employment, data may be securely deleted or anonymized.
            </span>
          </p>
        </div>
        <div className="">
          <h2 className="text-2xl font-bold mb-2">
            6. User Rights and Contact Information
          </h2>
          <p className="mb-4">
            <span className="">
              a. Employee Rights: Employees have the right to access, update, or
              request the deletion of their information. Contact the app
              administrator for assistance.
            </span>
            <span className="">
              b. Inquiries: For any privacy-related concerns or queries,
              employees can reach out to the designated privacy contact.
            </span>
          </p>
        </div>
        <div className="">
          <h2 className="text-2xl font-bold mb-2">7. Compliance and Updates</h2>
          <p className="mb-4">
            <span className="">
              a. Applicability: This Privacy Policy is specifically for the
              internal employee app and does not extend to external services or
              platforms.
            </span>
            <span className="">
              b. Policy Updates: Any modifications to this policy will be made
              available within the app. Users will be notified of changes.
            </span>
            <span className="">
              Note: Employees are allowed only access to their own data within
              the app. The app is solely for the management and viewing of
              work-related information.
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
