import Svgs from 'Assets/svgs'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import SelectedOption from 'Components/TenantApp/Elements/SelectedOption';
import ToggleWithText from 'Components/TenantApp/Elements/ToggleWithText';
import { TYPE_CHOICES, useAddDiscount } from './helper';
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb';
import { useMemo } from 'react';
import moment from 'moment';
import FloatInput from 'Components/TenantApp/Elements/FloatInput';
const AddDiscount = () => {
    const { errors, toggleDay, toggleStore, discount, handleVoucher, filterVoucher, serviceDropdown, loading, locationDropdown, isEdit,
        dateRangeCheck, validateBlockDate, addNewVoucher, getServiceName, handleChange, onBlurHandler, navigate, addStoreRestriction,
        filterStoreRestriction, onSubmit, addBlockDates, filterBlockDate, setToggleDay, setToggleStore, handleDayChecklist,
        setToggleBlockDate, toggleblockdate, translation, selectedLanguage, formRef, formErrors, setFormErrors
    } = useAddDiscount();
    const {
        voucher,
        dateRestriction,
        blockDate,
        blockDates,
        promotion_name,
        storeRestriction,
        storeRestrictions,
        dayRestrictions,
    } = discount;

    var minEndDateLimit = useMemo(() => {
        return moment(dateRestriction.startDate).add(1, 'day').format('YYYY-MM-DD')
    }, [dateRestriction.startDate]);

    return (
        <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right' >
            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
                url={'/dashboard/clients/'}
                second={selectedLanguage?.name == "English" ? "Discount & Promotions" : translation[638]?.value ? translation[638]?.value : "Discount & Promotions"}
                secondurl={'/dashboard/clients/discounts-promotions'}
                third={selectedLanguage?.name == "English" ? "Complimentary vouchers" : translation[1082]?.value ? translation[1082]?.value : "Complimentary vouchers"}
            />


            <div className='flex items-center sm:gap-4 gap-2 my-[1rem]'>

                <div className='flex items-center gap-4 justify-between w-full'>
                    <div className='flex items-center gap-3'>
                        <Svgs.Back link='/dashboard/clients/discounts-promotions' />
                        <h2 className='text-primary font-bold lg:text-3xl text-xl cursor-pointer'>
                            {selectedLanguage?.name == "English" ? "Complimentary vouchers" : translation[1082]?.value ? translation[1082]?.value : "Complimentary vouchers"}
                        </h2>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 md:w-[40vw]' ref={formRef}>

                <div className='border rounded-lg p-3 flex flex-col gap-5'>
                    <h1 className='text-sm'>
                        {selectedLanguage?.name == "English" ? "Create Vouchers Discount for Free service for a specific duration." : translation[1083]?.value ? translation[1083]?.value : "Create Vouchers Discount for Free service for a specific duration."}
                    </h1>
                    <LoginInput
                        name="promotion_name"
                        className='!w-fit'
                        title={selectedLanguage?.name == "English" ? "Promotion Name" : translation[883]?.value ? translation[883]?.value : "Promotion Name"}
                        value={promotion_name}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Promotion Name" : translation[1732]?.value ? translation[1732]?.value : "Enter Promotion Name"}
                        type='text'
                        // charactersMaxLength={25}
                        onChange={handleChange}
                        error={errors.promotion_name}
                    />
                    <div className='grid'>
                        {voucher.map((obj, index) => obj.is_deleted === 'False' && (
                            <>
                                <AddVoucher
                                    handleVoucher={handleVoucher}
                                    filterVoucher={filterVoucher}
                                    obj={obj}
                                    index={index}
                                    serviceDropdown={serviceDropdown}
                                    getServiceName={getServiceName}
                                    voucher={voucher}
                                    selectedLanguage={selectedLanguage}
                                    translation={translation}
                                    formErrors={formErrors}
                                />
                            </>
                        ))}

                    </div>
                    <p className="text-primary cursor-pointer flex items-center gap-2"
                        onClick={() => {
                            const errors = [];
                            discount.voucher?.forEach((data, index) => {
                                const fieldErrors = {};
                                if (!data.discount_percentage) {
                                    fieldErrors.discount_percentage = 'This field is required';
                                }
                                if (!data.service) {
                                    fieldErrors.service = 'This field is required';
                                }
                                if (!data.discount_duration) {
                                    fieldErrors.discount_duration = 'This field is required';
                                }
                                errors[index] = fieldErrors;
                            });
                            // Update formErrors state with the new errors
                            setFormErrors(errors);
                            // If there are errors, return early and do not proceed with form submission
                            if (errors?.some((error) => Object.keys(error)?.length > 0)) {
                                formRef.current?.scrollIntoView({ behavior: "smooth" });
                                return;
                            } else { addNewVoucher() }
                        }}>
                        <Svgs.Plus color="#1C8AFE" />
                        {selectedLanguage?.name == "English" ? "Add New" : translation[898]?.value ? translation[898]?.value : "Add New"}
                    </p>
                </div>

                <div className='border rounded-lg p-3 flex flex-col gap-7'>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>
                            {selectedLanguage?.name == "English" ? "Date Restrictions" : translation[899]?.value ? translation[899]?.value : "Date Restrictions"}
                        </h1>
                        <div className='grid grid-cols-2 gap-3'>
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                name="startDate"
                                value={dateRestriction.startDate}
                                type="date"
                                onChange={handleChange}
                                onBlur={() =>
                                    onBlurHandler({ startDate: dateRestriction.startDate })
                                }
                                error={errors.startDate}
                            />
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                                name="endDate"
                                value={dateRestriction.endDate}
                                type="date"
                                onChange={handleChange}
                                min={minEndDateLimit}
                                onBlur={() =>
                                    onBlurHandler({ endDate: dateRestriction.endDate })
                                }
                                error={errors.endDate}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>
                            {selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
                        </h1>
                        <div className='flex items-end gap-3'>
                            <Dropwdown
                                required={true}
                                name='storeRestriction'
                                placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                                title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                                parentClass='flex-1'
                                options={locationDropdown?.filter(obj => obj.value !== discount?.storeRestrictions?.find(location => location === obj.value))}
                                value={storeRestriction}
                                onChange={handleChange}
                                error={errors?.storeRestriction}
                            />
                            <LoginBtn
                                className={storeRestriction && "shake-bottom"}
                                text={<div className='flex items-center gap-2' > <Svgs.Plus /><p>
                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                </p></div>}
                                onClick={addStoreRestriction}
                                disabled={!storeRestriction}
                            />
                        </div>
                    </div>
                    <div className='flex items-center gap-2 flex-wrap'>
                        {storeRestrictions?.map((obj, index) => (
                            <SelectedOption
                                rounded={"full"}
                                name={
                                    locationDropdown?.find(
                                        (location) => location?.value === obj
                                    )?.label
                                }
                                onClick={() => filterStoreRestriction(index)}
                            />
                        ))}
                    </div>
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={'!text-lg'}
                            title={selectedLanguage?.name == "English" ? "Add Block Date" : translation[902]?.value ? translation[902]?.value : "Add Block Date"}
                            value={toggleblockdate}
                            onChange={() => setToggleBlockDate(!toggleblockdate)}
                            checked={toggleblockdate}
                        />
                        {toggleblockdate &&
                            <>
                                <div className='flex items-end gap-3'>
                                    <LoginInput
                                        name="blockDate"
                                        title={selectedLanguage?.name == "English" ? "Select Date" : translation[111]?.value ? translation[111]?.value : "Select Date"}
                                        type="date"
                                        required={false}
                                        parentClass="flex-1"
                                        min={dateRestriction.startDate}
                                        max={dateRestriction.endDate}
                                        value={blockDate}
                                        onChange={handleChange}
                                        error={errors.blockDate}
                                        disabled={
                                            !discount.dateRestriction.startDate ||
                                            !discount.dateRestriction.endDate
                                        }
                                    />
                                    <LoginBtn
                                        className={(blockDate) && "shake-bottom"}
                                        text={
                                            <div className="flex items-center gap-2">
                                                <Svgs.Plus />
                                                <p>
                                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                                </p>
                                            </div>
                                        }
                                        onClick={addBlockDates}
                                        disabled={
                                            !dateRangeCheck ? true : false ||
                                                !discount.dateRestriction.startDate ||
                                                !discount.dateRestriction.endDate ||
                                                !blockDate ||
                                                validateBlockDate ? true : false
                                        }
                                    />
                                </div>
                                <div className='flex items-center gap-2 flex-wrap'>
                                    {blockDates?.map((obj, index) => (
                                        <>
                                            {obj?.is_deleted === "False" && (
                                                <SelectedOption
                                                    rounded={"full"}
                                                    name={obj?.date}
                                                    onClick={() => filterBlockDate(obj?.id, index)}
                                                />
                                            )}
                                        </>
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                    {/* <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={"!text-lg"}
                            title={selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
                            value={toggleStore}
                            onChange={() => setToggleStore(!toggleStore)}
                            checked={toggleStore}
                        />
                        {toggleStore && (
                            <>
                                <div className="flex items-end gap-3">
                                    <Dropwdown
                                        name="storeRestriction"
                                        placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                                        title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                                        parentClass="flex-1"
                                        options={locationDropdown?.filter(
                                            (obj) =>
                                                obj.value !==
                                                discount?.storeRestrictions?.find(
                                                    (location) => location === obj.value
                                                )
                                        )}
                                        value={storeRestriction}
                                        onChange={handleChange}
                                    />
                                    <LoginBtn
                                        className={(storeRestriction) && "shake-bottom"}
                                        text={
                                            <div className="flex items-center gap-2">
                                                {" "}
                                                <Svgs.Plus />
                                                <p>
                                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                                </p>
                                            </div>
                                        }
                                        onClick={addStoreRestriction}
                                        disabled={!storeRestriction}
                                    />
                                </div>
                                <div className="flex items-center gap-2 flex-wrap">
                                    {storeRestrictions?.map((obj, index) => (
                                        <SelectedOption
                                            rounded={"full"}
                                            name={
                                                locationDropdown?.find(
                                                    (location) => location?.value === obj
                                                )?.label
                                            }
                                            onClick={() => filterStoreRestriction(index)}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div> */}
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={"!text-lg"}
                            title={selectedLanguage?.name == "English" ? "Day Restrictions" : translation[906]?.value ? translation[906]?.value : "Day Restrictions"}
                            value={toggleDay}
                            onChange={() => setToggleDay(!toggleDay)}
                            checked={toggleDay}
                        />
                        {toggleDay && (
                            <div className="grid grid-cols-2 gap-4">
                                {dayRestrictions.map((item) => {
                                    return (
                                        <CheckBox
                                            name={item.day}
                                            label={item.day}
                                            value={item.value}
                                            onChange={handleDayChecklist}
                                            checked={item.value === item.day ? true : false}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <LoginBtn
                    text={isEdit ?
                        selectedLanguage?.name == "English" ? "Update" : translation[845]?.value ? translation[845]?.value : "Update"
                        :
                        selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
                    loading={loading}
                    disabled={loading}
                    // disabled={
                    //     loading ||
                    //     // discount.users.length === 0 ||
                    //     !discount.promotion_name ||
                    //     !discount.dateRestriction.endDate ||
                    //     !discount.dateRestriction.startDate
                    //     || errors.endDate
                    //     // || discount.blockDates.length === 0
                    // }
                    onClick={onSubmit}
                />
            </div>
        </div>
    )
}

export default AddDiscount

const AddVoucher = ({ formErrors, handleVoucher, translation, selectedLanguage, filterVoucher, obj, index, serviceDropdown, getServiceName, voucher }) => {
    return (
        <div className="flex sm:items-center sm:flex-row flex-col gap-4 border p-2 rounded-md relative flex-wrap">
            {index > 0 &&
                <div
                    className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 cursor-pointer"
                    onClick={() => filterVoucher(obj?.id, index)}
                >
                    <Svgs.CirlceClose fill={"red"} size={"20"} />
                </div>
            }
            <FloatInput
                name="discount_percentage"
                title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[895]?.value ? translation[895]?.value : "Discount Percentage"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Discount Percentage" : translation[1733]?.value ? translation[1733]?.value : "Enter Discount Percentage"}
                required={true}
                isPercentage={true}
                value={obj?.discount_percentage}
                onChange={(e) => handleVoucher(e, index)}
                error={formErrors?.length > 0 ? formErrors[index]?.discount_percentage : ""}
            />
            <Dropwdown
                required={true}
                name="service"
                title={selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}
                placeholder={selectedLanguage?.name == "English" ? "Select Service" : translation[1063]?.value ? translation[1063]?.value : "Select Service"}
                value={getServiceName(obj?.service, serviceDropdown)}
                options={
                    !obj.service ? serviceDropdown.filter(obj => {
                        return obj && !voucher.filter(element => element.is_deleted === 'False')?.find((element) => element.service === obj.value)
                    })
                        :
                        [{ label: getServiceName(obj?.service, serviceDropdown), value: getServiceName(obj?.service, serviceDropdown) },
                        ...serviceDropdown.filter(obj => {
                            return obj && !voucher.filter(element => element.is_deleted === 'False')?.find((element) => element.service === obj.value)
                        })
                        ]
                }
                error={formErrors?.length > 0 ? formErrors[index]?.service : ""}
                onChange={(e) => handleVoucher(e, index)}
            />
            <Dropwdown
                required={true}
                title={selectedLanguage?.name == "English" ? "Discount Duration" : translation[1085]?.value ? translation[1085]?.value : "Discount Duration"}
                placeholder={selectedLanguage?.name == "English" ? "Select Discount Duration" : translation[1718]?.value ? translation[1718]?.value : "Select Discount Duration"}
                value={obj?.discount_duration}
                name="discount_duration"
                error={formErrors?.length > 0 ? formErrors[index]?.discount_duration : ""}
                // options={!obj.discount_duration ? TYPE_CHOICES.filter(obj => { 
                //     return obj && !voucher.filter(element => element.is_deleted === 'False')?.find((element)=> element.discount_duration === obj.value)
                //   })
                //     :
                //     [{label:getServiceName(obj?.discount_duration,TYPE_CHOICES),value:getServiceName(obj?.discount_duration,TYPE_CHOICES)},
                //     ...TYPE_CHOICES.filter(obj => { 
                //     return obj && !voucher.filter(element => element.is_deleted === 'False')?.find((element)=> element.discount_duration === obj.value)
                //     })                
                // ]}
                options={TYPE_CHOICES}
                onChange={(e) => handleVoucher(e, index)}
            />
        </div>
    )
}   