import React from 'react'

const TotalReadingCards = (props) => {
    return (
        props?.animate ?
            <div className="bg-white p-6 rounded-xl flex-1 border animate-pulse hover:shadow-md flex flex-col justify-between gap-4 transition-all">
                <div className="flex justify-between items-center">
                    <div className="h-10 w-1/4 rounded-lg bg-gray-200"></div>
                    <div className="h-10 w-2/4 rounded-lg bg-gray-200"></div>

                </div>
                <div className="flex justify-between items-center">
                    <div className="h-6 w-2/5 rounded-lg bg-gray-200"></div>
                    <div className="h-6 w-1/4 rounded-lg bg-gray-200"></div>
                </div>
            </div>
            :
            <div className="bg-white p-6 rounded-xl flex-1 border hover:shadow-md flex flex-col justify-between gap-4 transition-all cursor-pointer h-[130px]">
                <div className="flex flex-col">
                    <p className="text-[#000] text-base font-semibold">{props?.text}</p>
                </div>
                <div className='flex justify-between items-center'>
                    <div><p className="text-[#007930] font-semibold text-2xl">{props?.value ? props?.value : 0} {props?.currency} </p></div>
                    {/* <div className="flex items-center gap-1">
                        <div><p className={`${props?.subvalueType=="positive"?"text-[#007930]":"text-[#BC2323]"} font-semibold text-sm`}>{props?.subvalue ? props?.subvalue : 0}%</p></div>
                        <div>{props.graph}</div>
                    </div>  */}
                </div>
            </div>
    )
}

export default TotalReadingCards