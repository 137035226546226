import React, { useState, useEffect } from 'react'
import Svgs from '../../../../../../Assets/svgs'
import CircleIcon from '../../../../Elements/CircleIcon'
import Dropwdown from '../../../../Elements/Dropwdown'
import LoginBtn from '../../../../Elements/LoginBtn'
import { useNavigate } from 'react-router-dom'
import useSetDataInLocalStorage from '../../../../../../Hooks/useSetDataInLocalStorage'
import { getBusinessTheme, updateThemeCustomizationColor } from '../../../../../../Adapters/Api/themeCustomization'
import { toast } from 'react-toastify';
import { THEME_COLORS, THEME_MENUS } from '../../../../../../Constants/Data/Themes'
import { useDispatch, useSelector } from 'react-redux'
import { change_color } from 'Redux/Utility/Actions/ThemeActions'

const ThemeCustomization = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const state = useSelector((state) => state)

    const [submiting, setSubmiting] = useState(false)
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const businessData = getDataFromLocalStorage("business_data");
    const access_token = getDataFromLocalStorage("access_token");
    const [themeCustomization, setThemeCustomization] = useState({
        business: "",
        theme: "",
        theme_name: THEME_COLORS?.length > 0 ? THEME_COLORS[0]?.label : '',
        primary_color: "Dummy Primary Color",
        secondary_color: "Dummy Secondary Color",
        menu_option: "Horizental View",
        calendar_option: "HorizontalView"
    });

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    const { theme_name, menu_option, calendar_option } = themeCustomization;

    const onChangeHandler = async (e) => {
        const { name, value, data = {} } = e.target;
        if (name === "theme_name") {
            setThemeCustomization((prev) => ({
                ...prev,
                primary_color: data?.primary,
                secondary_color: data?.secondary,
                [name]: data?.label,
            }));
            let format_data = {
                ...themeCustomization,
                primary_color: data?.primary,
                secondary_color: data?.secondary,
                theme_name: data?.label,
            }

            let form_data = new FormData();
            for (let dt in format_data) {
                if (format_data[dt]) {
                    form_data.append(dt, format_data[dt]);
                }
            }

            await updateThemeCustomizationColor(format_data, access_token);

        } else {
            setThemeCustomization((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    }

    // handle menu option and calander option  
    const onMenuChangeHanlder = (selectedOption, id, isCalander = false) => {

        if (!isCalander) {
            setThemeCustomization((prev) => ({ ...prev, menu_option: selectedOption }));
        } else {
            setThemeCustomization((prev) => ({ ...prev, calendar_option: selectedOption }));
        }
    }

    //on handle submit update the business theme
    const onSubmitHandler = async () => {
        let new_data = {}
        for (let dt in themeCustomization) {
            if (themeCustomization[dt]) {
                new_data[dt] = themeCustomization[dt]
            }
        }

        const response = await updateThemeCustomizationColor({ ...new_data }, access_token);

        if (response.status === 200) {
            let successMessage = response?.data?.response?.message;
            toast.success(successMessage, { toastId: "toast" });
            navigate("/dashboard/account/setup");
        }
        else {
            toast.error(response.response.data.response.message, { toastId: "toast" });
        }
    }

    // get business theme data
    const getBusinessThemeData = async (business_id) => {
        const response = await getBusinessTheme(business_id);

        if (response.status === 200) {
            const businessTheme = response?.data?.response?.theme;

            setThemeCustomization((prev) => ({
                ...prev,
                ...businessTheme,
                business: businessData.id,
                theme: businessTheme.id,
                primary_color: businessTheme?.primary_color,
                secondary_color: businessTheme?.secondary_color,
                // menu_option: businessTheme?.menu_option

                // primary_color: "#5679FF",
                // secondary_color: "#101928",
            }))
            dispatch(change_color({ label: businessTheme?.theme_name, primary: businessTheme?.primary_color, secondary: businessTheme?.secondary_color }))
        }
    }


    useEffect(() => {
        if (businessData.id) {
            getBusinessThemeData(businessData.id);
        }
    }, [])


    useEffect(() => {
        if (businessData.id) {
            getBusinessThemeData(businessData.id);
        }
    }, []);

    const [Font, setFont] = useState(localStorage.getItem('NstyleFont') ? localStorage.getItem('NstyleFont') : 'Inter')

    return (
        <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
            <p className='text-[#7B7B7B] text-sm'><span className='cursor-pointer' onClick={() => { navigate('/dashboard/account/') }}>
                {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
            </span> {' '} &gt; {' '}
                <span className='cursor-pointer' onClick={() => { navigate('/dashboard/account/setup/') }}>
                    {selectedLanguage?.name == "English" ? "Account Setup" : translation[645]?.value ? translation[645]?.value : "Account Setup"}
                </span> {' '} &gt; {' '}
                <span className='text-primary font-semibold'>
                    {selectedLanguage?.name == "English" ? "Theme Customization" : translation[662]?.value ? translation[662]?.value : "Theme Customization"}
                </span>
            </p>
            <div className='my-[1rem] flex items-center justify-between gap-4 flex-wrap'>
                <div className='flex items-center sm:gap-4 gap-2'>
                    <h2 className='text-primary font-bold lg:text-3xl text-xl'>
                        {selectedLanguage?.name == "English" ? "Theme Customization" : translation[662]?.value ? translation[662]?.value : "Theme Customization"}
                    </h2>
                </div>

            </div>
            <div className='flex flex-col gap-5'>
                <div className='flex items-center justify-between lg:gap-0 gap-4 lg:flex-row flex-col my-6'>
                    <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.ColorTheme />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>
                                {selectedLanguage?.name == "English" ? "Color Customization" : translation[753]?.value ? translation[753]?.value : "Color Customization"}
                            </h2>
                            <p className='text-[#7B7B7B] text-sm'>
                                {selectedLanguage?.name == "English"
                                    ? "You can change color scheme throughout the "
                                    : translation[754]?.value ? translation[754]?.value
                                        : "You can change color scheme throughout the "
                                }
                                <br className='lg:block hidden' />
                                {selectedLanguage?.name == "English" ? "application" : translation[755]?.value ? translation[755]?.value : "application"}
                                .</p>
                        </div>
                    </div>
                </div>
                <div className='lg:w-[55%] w-full'>
                    <div className='grid md:grid-cols-2 gap-5'>
                        <Dropwdown
                            value={theme_name ? theme_name : THEME_COLORS[0]?.label}
                            onChange={(e) => {

                                let selected = THEME_COLORS.filter(clr => clr.label == e.target.value);
                                onChangeHandler({
                                    ...e,
                                    target: {
                                        name: "theme_name",
                                        value: selected[0]?.label,
                                        data: selected[0],
                                    }


                                })
                                dispatch(change_color(selected[0]))
                            }}
                            options={
                                THEME_COLORS.map((clr) => {
                                    return {
                                        label: <div className='flex items-center gap-[0.3rem]'>
                                            <div
                                                className='h-[1rem] w-[1rem] rounded-full object-cover' style={{ backgroundColor: `${clr.primary}` }}></div>
                                            {clr.label}
                                        </div>,
                                        value: clr.label,
                                    }
                                })
                            }
                            name='theme_name'
                            placeholder='Select color'
                            title={selectedLanguage?.name == "English" ? "Color Customization" : translation[753]?.value ? translation[753]?.value : "Color Customization"}
                        />
                        <Dropwdown
                            placeholder='Select Font Family'
                            title={selectedLanguage?.name == "English" ? "Font Customization" : translation[753]?.value ? translation[753]?.value : "Font Customization"}

                            options={
                                [
                                    { value: 'Inter', label: 'Inter' },
                                    { value: 'Poppins', label: 'Poppins' },
                                    { value: 'Montserrat', label: 'Montserrat' },
                                    { value: 'Open Sans', label: 'Open Sans' },
                                    { value: 'Ubuntu', label: 'Ubuntu' },
                                ]
                            }
                            value={Font}
                            onChange={(e) => {
                                setFont(e.target.value);
                                dispatch({
                                    type: 'CHANGE_FONT',
                                    payload: e.target.value
                                })
                            }}
                        />
                    </div>
                </div>
                {/* <hr className='bg-[#3F3F441A] my-[1.5rem]' />

                <div className='flex items-center justify-between lg:gap-0 gap-4 lg:flex-row flex-col my-6'>
                    <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.CustomizeMenu />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>
                                {selectedLanguage?.name == "English" ? "Menu Customization" : translation[757]?.value ? translation[757]?.value : "Menu Customization"}
                            </h2>
                            <p className='text-[#7B7B7B] text-sm'>
                                {selectedLanguage?.name == "English" ? "Menu customization for your NStyle" : translation[758]?.value ? translation[758]?.value : "Menu customization for your NStyle"}
                                <br className='lg:block hidden' />
                                {selectedLanguage?.name == "English" ? "application" : translation[755]?.value ? translation[755]?.value : "application"}
                                .</p>
                        </div>
                    </div>
                </div>
                <div className='flex items-center gap-3 flex-wrap'>
                    {THEME_MENUS(translation, selectedLanguage) && THEME_MENUS(translation, selectedLanguage).map((item, index) => {
                        return (
                            <div className='flex flex-col gap-2 text-center' key={index}>
                                <div className={`h-[125px] w-[125px] rounded-xl border flex justify-center items-center cursor-pointer transition-all ${item.name == menu_option ? 'customize-active' : ''}`} onClick={() => onMenuChangeHanlder(item.name, item.id)}>
                                    {
                                        index == 0 ?
                                            <Svgs.Horizental_View />
                                            :
                                            <Svgs.Vertical_View />
                                    }
                                </div>
                                <p className='text-xs text-primary capitalize'>{item?.name}</p>
                            </div>
                        )
                    })}
                </div> */}


                {/* <hr className='bg-[#3F3F441A] my-[1.5rem]' />

                <div className='flex items-center justify-between lg:gap-0 gap-4 lg:flex-row flex-col my-6'>
                    <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.Calendar />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>Calendar Customization</h2>
                            <p className='text-[#7B7B7B] text-sm'>Menu calendar customization for your NStyle <br className='lg:block hidden' /> application.</p>
                        </div>
                    </div>
                </div>
                <div className='flex items-center gap-3 flex-wrap'>
                    <div className='flex flex-col gap-2 text-center'>
                        <div className={`h-[125px] w-[125px] rounded-xl border flex justify-center items-center cursor-pointer transition-all ${calendar_option === "HorizontalView" ? 'customize-active' : ''}`} onClick={() => onMenuChangeHanlder("HorizontalView", 1, true)}>
                            <Svgs.Horizental_View />
                        </div>
                        <p className='text-xs text-[#101928]'>Horizental View</p>
                    </div>
                    <div className='flex flex-col gap-2 text-center'>
                        <div className={`h-[125px] w-[125px] rounded-xl border flex justify-center items-center cursor-pointer transition-all ${calendar_option === "VerticalView" ? 'customize-active' : ''}`} onClick={() => onMenuChangeHanlder("VerticalView", 2, true)}>
                            <Svgs.Vertical_View />
                        </div>
                        <p className='text-xs text-[#101928]'>Vertical View</p>
                    </div>
                </div> */}

                <div className='flex items-center justify-start mt-[2rem]'>
                    <LoginBtn
                        onClick={async () => {
                            setSubmiting(true)
                            await onSubmitHandler()
                            setTimeout(() => {
                                setSubmiting(false)
                            }, 500);
                        }}
                        loading={submiting}
                    >
                        {selectedLanguage?.name == "English" ? "Save Settings" : translation[759]?.value ? translation[759]?.value : "Save Settings"}
                    </LoginBtn>
                </div>

            </div>
        </div>
    )
}

export default ThemeCustomization
