import { getAllLanguages } from 'Adapters/Api';
import { createMultiLanguageInvoice, deleteMultiLanguageInvoice, getMultiLanguageInvoice, updateMultiLanguageInvoice } from 'Adapters/Api/multiLanguageInvoice';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getLanguages } from 'Redux/TenantRedux/Actions/multiLanguageInvoicesActions';
import { toast } from "react-toastify";
import useValidations from 'Hooks/useValidations';
import useObjectKeys from 'Hooks/useObjectKeys';

const useInvoiceTranslation = () => {
    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys();
    const state = useSelector((state) => state);
    const dispatch = useDispatch()
    const [search_text, setSearchText] = useState("");
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()

    const reduxLocations = state?.locations?.locations;
    const reduxLanguages = state?.multilanguageInvoices?.languages;
    const reduxInvoices = state?.multilanguageInvoices?.invoices

    const access_token = getDataFromLocalStorage('access_token')

    const [loading, setLoading] = useState(false)

    const [openInvoice, setOpenInvoice] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false)
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [edit, setEdit] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [exportLoader, setExportLoader] = useState(false);

    const [selectedInvoice, setSelectedInvoice] = useState('')

    const isPermissionUpdated = state?.user?.isPermissionUpdated;
    const [loadingPermission, setLoadingPermission] = useState(true)
    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])

    const getLanguageList = async () => {
        const response = await getAllLanguages();
        if (response.status === 200) {
            let languageList = response?.data?.response?.languages;
            dispatch(getLanguages(languageList))
            // let laguageOptionList = setOptionForDropdown(languageList);
            // setAllLanguages(laguageOptionList);
        }
    };

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    const [invoiceData, setInvoiceData] = useState({
        invoice: '',
        language: '',
        items: '',
        id: '',
        amount: '',
        subtotal: '',
        tips: '',
        taxes: '',
        status: 'active',
        total: '',
        payment_method: '',
        change: '',

    })

    const onEditHandler = (id) => {
        const data = reduxInvoices?.find(itm => itm.id === id)
        setEdit(true)
        setOpenInvoice(true)

        setInvoiceData({
            invoice: data?.invoice,
            id: data?.id,
            language: data?.language_data?.id,
            items: data?.items,
            amount: data?.amount,
            subtotal: data?.subtotal,
            tips: data?.tips,
            taxes: data?.taxes,
            status: data?.status,
            total: data?.total,
            change: data?.change,
            payment_method: data?.payment_method,
        })
    }

    const onDeleteHandler = (id) => {
        setDeleteModal(true)
        setSelectedInvoice(id)
    }

    const deleteInvoices = () => {
        setSubmitLoader(true)
        const succes = () => { setSubmitLoader(false); setDeleteModal(false); getInovices(); setErrors({}) }
        const fail = () => { setSubmitLoader(false) }
        const payload = {
            id: selectedInvoice,
        }
        dispatch(deleteMultiLanguageInvoice(payload, access_token, succes, fail))
    }

    const onHandleSubmit = () => {
        let validationData = {
            language: invoiceData?.language,
            invoice: invoiceData?.invoice,
            items: invoiceData?.items,
            amount: invoiceData?.amount,
            subtotal: invoiceData?.subtotal,
            tips: invoiceData?.tips,
            taxes: invoiceData?.taxes,
            total: invoiceData?.total,
            payment_method: invoiceData?.payment_method,
            change: invoiceData?.change,
        }
        setErrors(validation(validationData));
        let result = validation(validationData);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setSubmitLoader(true)
            const succes = () => {
                // setEdit(false)
                setErrors({})
                setOpenInvoice(false)
                setSubmitLoader(false)
                setInvoiceData({
                    invoice: '',
                    language: '',
                    items: '',
                    amount: '',
                    subtotal: '',
                    tips: '',
                    taxes: '',
                    status: '',
                    total: '',
                    payment_method: '',
                })
                getInovices()
            }
            const fail = () => {
                setSubmitLoader(false)
            }

            var data = new FormData();
            data.append(`invoice`, invoiceData.invoice);
            data.append(`language`, invoiceData.language);
            data.append(`items`, invoiceData.items);
            data.append(`amount`, invoiceData.amount);
            data.append(`subtotal`, invoiceData.subtotal);
            data.append(`tips`, invoiceData.tips);
            data.append(`taxes`, invoiceData.taxes);
            data.append(`status`, invoiceData.status);
            data.append(`total`, invoiceData.total);
            data.append(`change`, invoiceData.change);
            data.append(`payment_method`, invoiceData.payment_method);
            edit && data.append(`id`, invoiceData.id)

            edit ?
                dispatch(updateMultiLanguageInvoice(data, access_token, succes, fail))
                :
                dispatch(createMultiLanguageInvoice(data, access_token, succes, fail))
        }
    }

    const getInovices = () => {
        setLoading(true)
        const success = () => { setLoading(false) }
        const fail = () => { setLoading(false) }
        dispatch(getMultiLanguageInvoice(access_token, success, fail))
    }


    // const LocationDropDown = useMemo(() => {
    //     const locationIDs = edit ? reduxInvoices?.map(itm => { if (itm?.location != invoiceData?.location) { return itm?.location } }) : reduxInvoices?.map(itm => { return itm?.location })
    //     const filterLocation = reduxLocations?.filter(item => item?.id != locationIDs?.find(itm => itm == item.id))
    //     return filterLocation
    // }, [openInvoice])

    const onChangeHandler = (e) => {
        setInvoiceData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
        setErrors((prev) => ({ ...prev, [e.target.name]: "" }));
    }

    useEffect(() => {
        if (reduxLanguages?.length == 0) {
            getLanguageList()
        }
    }, [openInvoice])

    //----------------------Exoprt code--------------------

    let headers = [
        { label: selectedLanguage?.name == "English" ? 'Language' : translation[805]?.value ? translation[805]?.value : "Language", key: "language" },
        { label: selectedLanguage?.name == "English" ? 'Status' : translation[454]?.value ? translation[454]?.value : "Status", key: "status" },
    ];

    const csvReport = {
        filename: "Invoice Translation List.csv",
        headers: headers,
        data: reduxInvoices?.map((itm) => {
            return {
                ...itm,
                language: itm?.language_data?.name ? itm?.language_data?.name : "--------",
                status: itm?.status ? itm?.status : "InActive",
            };
        }),
    };


    const handleExportClick = () => {
        if (!isToastVisible) {
            if (reduxInvoices?.length > 0) {
                // setExportLoader(true)
                setTimeout(() => {
                    toast.success("File downloaded successfully", {
                        toastId: "toast",
                        onClose: () => setIsToastVisible(false)
                    });
                    setExportLoader(false)
                }, 300);
            } else {
                toast.error("No data to export", {
                    onClose: () => setIsToastVisible(false),
                });
            }
            setIsToastVisible(true);
        }
    };


    return {
        // allLanguages,
        reduxLocations, reduxLanguages, reduxInvoices, submitLoader, edit, setEdit, openInvoice, setOpenInvoice, deleteModal,
        setDeleteModal, invoiceData, setInvoiceData, onChangeHandler, onEditHandler, onHandleSubmit, loading, translation,
        selectedLanguage, onDeleteHandler, deleteInvoices, getInovices, search_text, setSearchText, csvReport,
        toast, exportLoader, setExportLoader, handleExportClick, errors, setErrors, loadingPermission
    }
}

export default useInvoiceTranslation