import axios from "axios";
import { toast } from "react-toastify";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { PRODUCT } from "Redux/TenantRedux/ActionTypes/productTypes";
import {
  get_products_url,
  add_product_url,
  delete_product_url,
  update_product_url,
  BASE_URL,
  api_base_url_tenant,
  get_stocks_url,
  delete_stocks_url,
  filter_stocks_url,
  search_product_url,
  get_Export_Prod_List,
  get_import_url,
  create_order_stock_url,
  get_order_stock_url,
  update_order_stock_url,
  delete_order_stock_url,
  get_Brand_import_url,
  get_product_stock_report_url,
  get_products_with_page,
  get_filtered_insight_products_url,
  get_filtered_insight_chart_products_url,
  get_products_quick_sales,
  get_dashboard_products,
  get_products_for_dropdown,
  get_products_detail,
  get_products_main_page,
} from "../api_variables";
import { apiErrorMessage } from "Constants/Data/Errors";
import { getProducts, getProductsDropdownPagination, getProductsOrderStock, getProductsWithoutPagination, get_product_stock_report } from "Redux/TenantRedux/Actions/product";

export const getsDashboardProducts = async (location) => {
  const product_location = location ? `?location_id=${location}` : "";
  let url = `${BASE_URL}${get_dashboard_products}${product_location}`
  try {
    const result = await axios.get(url);
    return result;
  } catch (error) {
    return error;
  }
};

export const getsProducts = async (page, search_text) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }
  const current_Page = page ? `page=${page}` : '';
  const search = search_text ? `&search_text=${search_text}` : ''
  let url;
  if (page) {
    url = `${BASE_URL}${get_products_with_page}?${current_Page}${search}`
  } else {
    url = `${BASE_URL}${get_products_url}`
  }

  try {
    const result = await axios.get(url)
    // {
    //   headers: { Authorization: `Token ${access_token}` },
    // })
    return result;
  } catch (error) {
    return error;
  }
};

export const getsProductsMainPage = async (page, selected_location, search_text) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  const current_Page = page ? `?page=${page}` : '';
  const search = search_text ? `&search_text=${search_text}` : ''
  const location = selected_location ? `&location_id=${selected_location}` : ""

  let url = `${BASE_URL}${get_products_main_page}${current_Page}${search}${location}`
  try {
    const result = await axios.get(url)
    // {
    //   headers: { Authorization: `Token ${access_token}` },
    // })
    return result;
  } catch (error) {
    return error;
  }
};

export const getsProductsDetail = (id, setLoading, success, fail) => dispatch => {
  let s_code;
  const prodID = id ? `?product_id=${id}` : ''
  const apiUrl = `${get_products_detail}${prodID}`

  setLoading && setLoading(true)
  fetch(`${BASE_URL}${apiUrl}`)
    .then(response => {
      s_code = response.status
      if (response.ok) {
        return response.json()
      }
    })
    .then(result => {
      if (s_code == 200) {
        let singleProd = result?.response?.products[0];
        success && success(singleProd)
        setLoading && setLoading(false)
      }
      else {
        setLoading && setLoading(false)
        fail && fail()
      }
    })
};

export const getsProductsDropdownList = async (setLoading) => {
  setLoading && setLoading(true)
  let url = `${BASE_URL}${get_products_for_dropdown}`
  try {
    const result = await axios.get(url);
    setLoading && setLoading(false)
    return result;
  } catch (error) {
    setLoading && setLoading(false)
    return error;
  }
};

// export const getEmployeeDropDownListPagination = (employeePage, selected_location, employeeSearch, setLoader) => {

export const getsProductsDropdownListPagination = (page, location, searchText, setLoading) => async (dispatch) => {
  setLoading && setLoading(true)
  const pageNumb = page ? `&page=${page}` : ''
  const search = searchText ? `&search_text=${searchText}` : ''
  const locationId = location ? `?location_id=${location}` : ''
  let url = `${BASE_URL}${get_products_for_dropdown}${locationId}${pageNumb}${search}`
  try {
    const result = await axios.get(url);
    if (result.status === 200) {
      setLoading && setLoading(false)
      dispatch(getProductsDropdownPagination(result.data));
    }
    return result;
  } catch (error) {
    setLoading && setLoading(false)
    return error;
  }
};

export const getProductsForOrderStock = (location, setLoading) => async (dispatch) => {
  setLoading && setLoading(true)
  const locationId = location ? `?location_id=${location}` : ''
  let url = `${BASE_URL}${get_products_for_dropdown}${locationId}`
  try {
    const result = await axios.get(url);
    if (result.status === 200) {
      setLoading && setLoading(false)
      dispatch(getProductsOrderStock(result?.data?.response?.products));
    }
    return result;
  } catch (error) {
    setLoading && setLoading(false)
    return error;
  }
};

export const getsProductsForConsumption = async (location, setLoading) => {
  setLoading && setLoading(true)
  const locationId = location ? `?location_id=${location}` : ''
  let url = `${BASE_URL}${get_products_for_dropdown}${locationId}`
  try {
    const result = await axios.get(url);
    setLoading && setLoading(false)
    return result;
  } catch (error) {
    setLoading && setLoading(false)
    return error;
  }
};

export const getsProductsInMembership = async () => {
  let url = `${BASE_URL}${get_products_url}`
  try {
    const result = await axios.get(url);
    return result;
  } catch (error) {
    return error;
  }
};


export const getsProductsQuickSales = async (location, page, search_text, quickSales) => {
  const business_data = JSON.parse(localStorage.getItem("business_data"));
  const businessId = business_data ? `&business=${business_data.id}`:""
  const product_location = location ? `?location_id=${location}` : "";
  const current_Page = page ? `&page=${page}` : '';
  const search = search_text ? `&search_text=${search_text}` : ''
  const getProdFromQuickSales = quickSales ? `&quick_sales=true` : ''
  let url;
  if (page) {
    url = `${BASE_URL}${get_products_quick_sales}${product_location}${businessId}${current_Page}${search}${getProdFromQuickSales}`
  }
  try {
    const result = await axios.get(url);
    return result;
  } catch (error) {
    return error;
  }
};

export const getAllProductsWithNoPagination = (search_text, selected_location, setNoPaginationLoader) => async (dispatch) => {
  setNoPaginationLoader && setNoPaginationLoader(true)
  const search = search_text ? `&search_text=${search_text}` : ''
  const location = selected_location ? `&location_id=${selected_location}` : ""
  try {
    const result = await axios.get(`${BASE_URL}${get_products_url}?no_pagination=true${search}${location}`);
    if (dispatch) {
      let productList = result?.data?.response?.products;
      dispatch(getProductsWithoutPagination(productList))
      setNoPaginationLoader && setNoPaginationLoader(false)
    }
    setNoPaginationLoader && setNoPaginationLoader(false)
    return result;
  } catch (error) {
    setNoPaginationLoader && setNoPaginationLoader(false)
    return error;
  }
};

export const getorderStock = async () => {
  try {
    const result = await axios.get(`${BASE_URL}${get_order_stock_url}`);
    return result;
  } catch (error) {
    return error;
  }
};
export const getsProductsImport = async (form, success) => {
  let token = localStorage.getItem("access_token");
  if (token) {
    token = token.replaceAll('"', "");
  }

  fetch(`${BASE_URL}${get_import_url}`, {
    method: "POST",
    headers: {
      Authorization: `Token ${token}`,
    },
    body: form,
  })
    .then((response) => {
      if (response.status == 200) {
        success();
        toast.success("Products imported successfully", { toastId: "toast" });
        return response.json();
      } else {
        toast.error("Create category and brand first", { toastId: "toast" });
        return response.json();
      }
    })
    .then((result) => {
      success();
    });
};

export const getsBrandsImport = async (form, success) => {
  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }

  fetch(`${BASE_URL}${get_Brand_import_url}`, {
    method: "POST",
    headers: {
      Authorization: `Token ${token}`,
    },
    body: form,
  })
    .then((response) => {
      if (response.status == 200) {
        success();
        toast.success("Brands imported successfully", { toastId: "toast" });
        return response.json();
      } else {
        toast.error("Create category and brand first", { toastId: "toast" });
        return response.json();
      }
    })
    .then((result) => {
      success();
    });
};

export const getExoprtList = () => {
  fetch(`${BASE_URL}${get_Export_Prod_List}`)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => { })
    .catch((err) => { });
};
export const addProducts = (product_data, access_token) => async dispatch => {
  try {
    const result = await axios.post(
      `${BASE_URL}${add_product_url}`,
      product_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const createorderstock = (data, success, fail) => (dispatch) => {
  let token = localStorage.getItem("access_token");
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");

  if (token) {
    token = token.replaceAll('"', "");
  }
  let form_data = new FormData();

  form_data.append("products", JSON.stringify(data.products));
  form_data.append("vendor", data.vendor);
  form_data.append("status", data.status);
  form_data.append("location", data.location);
  form_data.append("rec_quantity", data.rec_quantity);
  form_data.append("business", businessData.id);

  fetch(`${BASE_URL}${create_order_stock_url}`, {
    method: "POST",
    headers: {
      Authorization: `Token ${token}`,
    },
    body: form_data,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result?.status_code === 201) {
        success && success();
        dispatch({
          type: PRODUCT.CREATE_ORDER_STOCK,
          payload: result.response.orderstock,
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const updateProducts = async (update_prodcut_data, access_token) => {
  try {
    const result = await axios.put(
      `${BASE_URL}${update_product_url}`,
      update_prodcut_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const deleteProduct = (product_id, success, fail) => (dispatch) => {
  const localStorageData = localStorage.getItem("access_token");
  const access_token = JSON.parse(localStorageData);
  let s_code;

  fetch(`${BASE_URL}${delete_product_url}`, {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      Authorization: `Token ${access_token}`,
    },
    body: JSON.stringify({ product: product_id }),
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: PRODUCT.DELETE_PRODUCT,
          payload: product_id,
        });
        success && success(result);
      } else {
        fail && fail();
      }
      return result;
    })
    .catch((err) => {
      fail && fail();
    });
};

export const get_product_stocks = (setLoader, data, success, fail) => (dispatch) => {
  let s_code;
  setLoader && setLoader(true)
  fetch(api_base_url_tenant + get_stocks_url)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: PRODUCT.GET_PRODUCT_STOCKS,
          payload: {
            data: result.response.stocks,
          },
        });
        setLoader && setLoader(false)
        success && success(result.response.stocks);
      } else {
        setLoader && setLoader(false)
        fail && fail(result.response.message);
      }
    })
    .catch(() => {
      setLoader && setLoader(false)
      fail && fail(apiErrorMessage);
    });
};

export const get_product_stocks_optmized = (currentPage, selected_location, search_text, setLoader, success, fail) => (dispatch) => {
  let s_code;
  setLoader && setLoader(true)
  const page = currentPage ? `&page=${currentPage}` : ""
  const location = selected_location ? `?location_id=${selected_location}` : ""
  const search = search_text ? `&search_text=${search_text}` : ""

  fetch(`${api_base_url_tenant}${get_stocks_url}${location}${page}${search}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: PRODUCT.GET_PRODUCT_STOCKS,
          payload: {
            data: result.response.stocks,
          },
        });
        dispatch({
          type: PRODUCT.GET_PRODUCT_STOCKS_PAGINATION_DATA,
          payload: {
            data: result,
          },
        });
        setLoader && setLoader(false)
        success && success(result.response.stocks);
      } else {
        setLoader && setLoader(false)
        fail && fail(result.response.message);
      }
    })
    .catch(() => {
      setLoader && setLoader(false)
      fail && fail(apiErrorMessage);
    });
};

export const get_product_stocks_nopagination = (selected_location, search_text, setLoader, success, fail) => (dispatch) => {
  let s_code;
  setLoader && setLoader(true)

  const location = selected_location ? `?location_id=${selected_location}` : ""
  fetch(`${api_base_url_tenant}${get_stocks_url}${location}&no_pagination=true`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: PRODUCT.GET_PRODUCT_STOCKS_NO_PAGINATION,
          payload: {
            data: result.response.stocks,
          },
        });
        setLoader && setLoader(false)
        success && success(result.response.stocks);
      } else {
        setLoader && setLoader(false)
        fail && fail(result.response.message);
      }
    })
    .catch(() => {
      setLoader && setLoader(false)
      fail && fail(apiErrorMessage);
    });
};

export const delete_stock = (data, success, fail) => (dispatch) => {
  let s_code;
  let access_token = localStorage.getItem("access_token");
  access_token = JSON.parse(access_token);
  if (!access_token) {
    alert(apiErrorMessage);
    return;
  }

  let form_data = new FormData();
  form_data.append("stock", data.id);
  fetch(api_base_url_tenant + delete_stocks_url, {
    headers: {
      Authorization: `Token ${access_token}`,
    },
    method: "DELETE",
    body: form_data,
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: PRODUCT.DELETE_PRODUCT_STOCKS,
          payload: {
            id: data.id,
          },
        });
        success && success();
      } else {
        fail && fail(result.response.message);
      }
    })
    .catch(() => {
      fail && fail(apiErrorMessage);
    });
};

export const filter_stock = (data, success, fail) => (dispatch) => {
  let s_code,
    query = "";
  for (let dt in data) {
    if (data[dt]) {
      query += `&${dt}=${data[dt]}`;
    }
  }

  fetch(api_base_url_tenant + filter_stocks_url + `?${query}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: PRODUCT.GET_FILTER_PRODUCT_STOCKS,
          payload: {
            data: result.response.products,
          },
        });
        success && success();
      } else {
        fail && fail(result.response.message);
      }
    })
    .catch(() => {
      fail && fail(apiErrorMessage);
    });
};

export const search_products = (data, success, fail) => (dispatch) => {
  let s_code,
    query = "";
  for (let dt in data) {
    if (data[dt]) {
      query += `&${(dt = data[dt])}`;
    }
  }

  fetch(api_base_url_tenant + search_product_url + `?${query}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: PRODUCT.GET_SEARCH_PRODUCTS,
          payload: {
            data: result.response.products,
          },
        });
        success && success();
      } else {
        fail && fail(result.response.message);
      }
    })
    .catch(() => {
      fail && fail(apiErrorMessage);
    });
};

export const updateorderStock = (data, cancel, success, fail) => (dispatch) => {
  let s_code;

  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }
  let form_data = new FormData();
  if (cancel) {
    form_data.append("status", "Cancelled");
  } else {
    form_data.append("status", data.status);
  }
  {
    data.status == "Partially_Received" &&
      form_data.append("rec_quantity", data.rec_quantity);
  }
  // form_data.append('business', businessData.id)
  form_data.append("order_id", data.id);

  fetch(BASE_URL + update_order_stock_url, {
    method: "PUT",
    body: form_data,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 200) {
        success && success();
        dispatch({
          type: PRODUCT.UPDATE_ORDER_STOCK,
          payload: {
            id: data.id,
          },
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const deleteOrderstock = (data, success, fail) => (dispatch) => {
  let s_code;

  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }

  let form_data = new FormData();
  form_data.append("id", data.id);

  fetch(BASE_URL + delete_order_stock_url, {
    method: "DELETE",
    body: form_data,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 200) {
        success && success();
        dispatch({
          type: PRODUCT.DELETE_ORDER_STOCK,
          payload: {
            id: data.id,
          },
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};


// Inventory Product Cycle Report
export const get_product_inventory_report = (loading, success, fail, data) => async (dispatch) => {

  let query = '?'

  for (let dt in data) {
    if (data[dt]) {
      query += `${dt}=${data[dt]}&`
    }
  }
  console.log("🚀 ~ constget_product_inventory_report= ~ query:", query)
  try {
    loading && loading()
    const response = await axios.get(`${BASE_URL}${get_product_stock_report_url}${query}`)
    if (response.status === 200) {
      success && success()
      let reportList = response?.data;
      dispatch(get_product_stock_report(reportList));
    }
  } catch (error) {
    console.log("🚀 ~ constget_product_inventory_report= ~ error:", error)
    fail && fail()
    toast.error(apiErrorMessage, { toastId: "toast" });
    return error;
  }
};

export const getFilteredInsightProductsUrl = (data, success, fail) => dispatch => {
  let s_code;

  let query = '?'
  for (let dt in data) {
    if (data[dt]) {
      query += `${dt}=${data[dt]}&`
    }
  }

  fetch(
    `${BASE_URL}${get_filtered_insight_products_url}${query}`
  )
    .then(response => {
      return response.json()
    })
    .then(result => {
      // console.log(result)
      dispatch({
        type: PRODUCT.GET_INSIGHT_PRODUCTS,
        payload: {
          products: result.response.products,
          chart_products: result.response.products
        }
      })
      success && success()
    })
    .catch(err => {
      fail && fail()
      console.log('error :: ', err)
    })
}

export const getChartProductsInsights = (data, success, fail) => dispatch => {
  let s_code;

  let query = '?'
  for (let dt in data) {
    if (data[dt]) {
      query += `${dt}=${data[dt]}&`
    }
  }

  fetch(
    `${BASE_URL}${get_filtered_insight_chart_products_url}${query}`
  )
    .then(response => {
      return response.json()
    })
    .then(result => {
      dispatch({
        type: PRODUCT.GET_INSIGHT_PRODUCTS_CHART,
        payload: {
          products: result.response.products,
        }
      })
      success && success()
    })
    .catch(err => {
      fail && fail()
      console.log('error :: ', err)
    })
}