import { apiErrorMessage } from 'Constants/Data/Errors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getAllLanguages } from '../../../../../Adapters/Api'

import { getSingleClient, getUniqueClientId } from '../../../../../Adapters/Api/clients'
import Svgs from '../../../../../Assets/svgs'
import CircleIcon from '../../../Elements/CircleIcon'
import DateInput from '../../../Elements/DateInput'
import Dropwdown from '../../../Elements/Dropwdown'
import I_Info_Icon from '../../../Elements/I_Info_Icon'
import LoginBtn from '../../../Elements/LoginBtn'
import LoginInput from '../../../Elements/LoginInput'
import PhoneInput from '../../../Elements/PhoneInput'
import Popup from '../../../Elements/Popup'
import RadioBtn from '../../../Elements/RadioBtn'
import Textarea from '../../../Elements/Textarea'
import UploadFile from '../../../Elements/UploadFile'
import useAddClient from './useAddClient'
import PreloaderFull from 'Components/TenantApp/Elements/PreloaderFull'
import FormShimmerFullScreen from 'Components/Utility/Shimmers/FormShimmerFullScreen'
import ImageUploader from 'Components/TenantApp/Elements/ImageUploader'
import ThumnailCard from 'Components/TenantApp/Elements/ThumnailCard'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox'

const AddClient = ({ onclose, edit, edit_data, multiple, onChange, mb_file_size }) => {


    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const clientId = searchParams?.get("client_id");
    const from_url = searchParams?.get("from");
    const [pageLoader, setPageLoader] = useState(false)
    const {
        clientsData, setClientsData,
        countriesList, stateList,
        clientHandleChange,
        checkClientExistenceBeforeSubmit, setIsEdit,
        errors, onBlurHandler, languageList, setLanguageList,
        finished, setFinished, isEdit, img, setAllImages,
        setCountryId, setStateId, loading, access_token, handleDeleteImage,
        translation, selectedLanguage, formRef, handleImageChange, allImages
    } = useAddClient();


    const { client_id, full_name, email, gender, address, country, state, city, is_active, mobile_number, dob, about_us, postal_code, language, marketing, customer_note, card_number, image, allow_loyalty } = clientsData;


    //get a single client 
    const setClientFormData = (id) => {
        setPageLoader(true)
        getSingleClient(id)
            .then((result) => {
                if (result?.status === 200) {
                    const client = result?.response?.client;
                    const { id, client_id, full_name, email, gender, address, country, customer_note, state, city, about_us, marketing, is_active, mobile_number, dob, postal_code, card_number, image, language, images, allow_loyalty } = client;
                    country && setCountryId(country?.unique_id)
                    // state && setStateId(state)
                    setClientsData({
                        id, client_id, full_name, email, gender, address, country: country?.unique_id, state: state?.unique_id, city: city?.name, marketing, about_us,
                        is_active, mobile_number, dob, postal_code, card_number, image, customer_note, allow_loyalty, language, allow_loyalty
                    })
                    setAllImages(images)
                    setPageLoader(false)
                }
            })
            .catch((error) => {
                setPageLoader(false)
                toast.error(apiErrorMessage, { toastId: "toast" })

            })
    }
    // const [services, setservices] = useState("");
    // const [details, setDetails] = useState("");



    useEffect(() => {
        if (clientId) {
            setIsEdit(true);
            setClientFormData(clientId);
        }
    }, [clientId]);


    const fetchClientUniqueId = () => {

        getUniqueClientId(access_token).then((result) => {

            const res = result?.response?.id;
            let idToString = res.toString();
            let idSrigToArray = idToString.split("-");
            let shortDomian = idSrigToArray[0].split("").filter((i) => {
                if (i != "," && i != "'" && i != "[" && i != "]" && i != " ") { return i }
            }).join("").toUpperCase()

            idSrigToArray.splice(0, 1, shortDomian)
            setClientsData((prev) => ({
                ...prev,
                client_id: idSrigToArray.join("-")
            }))

        }).catch((err) => {
            toast.error("Error in get employee id")
        })
    }



    //get all languages
    const getLanguageList = async () => {
        const response = await getAllLanguages();
        if (response.status === 200) {
            let languageList = response?.data?.response?.languages;
            setLanguageList(languageList)
        }
    }


    useEffect(() => {
        if (!clientId) {
            fetchClientUniqueId()
        }

        getLanguageList()

    }, [])



    return (
        <>
            <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] bounce-in-right topscroll' ref={formRef}>
                {(pageLoader && pageLoader == true)
                    ? <FormShimmerFullScreen />
                    :
                    <div className='flex flex-col gap-4'>
                        <div className='flex items-center gap-3'>{
                            from_url == "appointment" ?
                                <span onClick={() => {
                                    navigate(-1)
                                }}>
                                    <Svgs.Back />
                                </span> :
                                from_url == "quick_sales_checkout" ?
                                    <span onClick={() => {
                                        navigate(-1)
                                    }}>
                                        <Svgs.Back />
                                    </span>
                                    :
                                    <Svgs.Back link='/dashboard/clients/client-profiles/' />

                        }
                            <h2 className='text-primary font-bold lg:text-3xl text-xl'>{clientId ?
                                selectedLanguage?.name == "English" ? "Update Client" : translation[489]?.value ? translation[489]?.value : "Update Client" :
                                selectedLanguage?.name == "English" ? "Add Client" : translation[486]?.value ? translation[486]?.value : "Add Client"} </h2>
                        </div>
                        <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem] mt-[1rem]'>
                            <CircleIcon>
                                <Svgs.Profile />
                            </CircleIcon>
                            <div className='flex flex-col gap-1 lg:text-left text-center'>
                                <h2 className='text-primary font-semibold text-xl'>{selectedLanguage?.name == "English" ? "Client Information" : translation[490]?.value ? translation[490]?.value : "Client Information"}</h2>
                                <p className='text-[#7B7B7B] text-sm'>
                                    {selectedLanguage?.name == "English" ? "Provide all the information about the client for the" : translation[493]?.value ? translation[493]?.value : "Provide all the information about the client for the"} <br className='hidden md:block' />
                                    {selectedLanguage?.name == "English" ? "records." : translation[497]?.value ? translation[497]?.value : "records."}</p>
                            </div>
                        </div>
                        <div className='flex items-center gap-5 lg:flex-nowrap flex-wrap lg:justify-start justify-center'>
                            <div className='relative w-fit'>
                                <div className='h-[11rem] w-[10rem] bg-[#E4EAF0] rounded-lg flex items-center justify-center overflow-hidden'>
                                    {img ? <img src={img} /> : image ? <img src={image} /> : <>
                                        <p className='text-center text-primary text-sm font-semibold'>
                                            {selectedLanguage?.name == "English" ? "Upload Profile" : translation[470]?.value ? translation[470]?.value : "Upload Profile"} <br className='hidden md:block' /> {selectedLanguage?.name == "English" ? "Picture" : translation[471]?.value ? translation[471]?.value : "Picture"}</p>
                                    </>}
                                </div>
                                <label htmlFor="upload_img">
                                    <div className='cursor-pointer absolute h-[3.125rem] w-[3.125rem] rounded-full border top-full left-1/2 -translate-x-1/2 -translate-y-1/2 bg-primary flex items-center justify-center'>
                                        <Svgs.Camera fill='#fff' />
                                    </div>
                                </label>

                                <div className='hidden'>
                                    <UploadFile
                                        title='Brand Image'
                                        type="file"
                                        multiple={false}
                                        onChange={(e) => clientHandleChange(e, "image")}
                                        name='image'
                                        value={img ? img : image}
                                        edit={edit}
                                        id="upload_img"
                                        accept='.png,.PNG,.JPG,.jpg,jpeg,.JPEG'
                                        mb_file_size={10}
                                        required
                                    />
                                </div>

                                {/* <input type="file" name='image' onChange={(e) => clientHandleChange(e, "image")} id="upload_img" hidden accept='.png,.PNG,.JPG,.jpg,jpeg,.JPEG' /> */}
                            </div>

                            <div className='w-full flex flex-col gap-5'>
                                <LoginInput
                                    required={true}
                                    title={selectedLanguage?.name == "English" ? "Full Name" : translation[472]?.value ? translation[472]?.value : "Full Name"}
                                    name={"full_name"}
                                    onEnterSubmit={checkClientExistenceBeforeSubmit}
                                    value={full_name}
                                    // charactersMaxLength={25}
                                    specialCharacterNotAllowed={true}
                                    onChange={clientHandleChange}
                                    onBlur={() => onBlurHandler({ full_name: full_name })}
                                    error={errors?.full_name}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Your Name" : translation[509]?.value ? translation[509]?.value : "Enter Your Name"} />

                                <LoginInput
                                    required={false}
                                    name={"email"}
                                    value={email}
                                    onChange={clientHandleChange}
                                    // onBlur={() => onBlurHandler({ email: email })}
                                    error={errors?.email}
                                    title={selectedLanguage?.name == "English" ? "Email Address" : translation[60]?.value ? translation[60]?.value : "Email Address"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Your Email" : translation[510]?.value ? translation[510]?.value : "Enter Your Email"} />

                            </div>
                            <div className='w-full flex flex-col gap-5'>
                                <LoginInput
                                    required={true}
                                    name={"client_id"}
                                    value={client_id}
                                    onEnterSubmit={checkClientExistenceBeforeSubmit}
                                    onChange={clientHandleChange}
                                    onBlur={() => onBlurHandler({ client_id: client_id })}
                                    error={errors?.client_id}
                                    title={
                                        <I_Info_Icon text={selectedLanguage?.name == "English" ? "Unique Id" : translation[511]?.value ? translation[511]?.value : "Unique Id"} info={selectedLanguage?.name == "English" ? "Client ID can be a unique identifier or contact HR deparment for client ID" : translation[512]?.value ? translation[512]?.value : "Client ID can be a unique identifier or contact HR deparment for client ID"} />
                                    }
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Your Name Unique Id" : translation[513]?.value ? translation[513]?.value : "Enter Your Name Unique Id"}
                                    disabled={client_id ? true : false}
                                />

                                <PhoneInput
                                    required={true}
                                    name={"mobile_number"}
                                    value={mobile_number}
                                    onBlur={() => onBlurHandler({ mobile_number: mobile_number })}
                                    onChange={clientHandleChange}
                                    error={errors?.mobile_number}
                                    title={selectedLanguage?.name == "English" ? "Phone Number" : translation[62]?.value ? translation[62]?.value : "Phone Number"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter your phone number" : translation[514]?.value ? translation[514]?.value : "Enter your phone number"}
                                    onEnterSubmit={checkClientExistenceBeforeSubmit}
                                    edit={isEdit ? true : false}

                                />
                            </div>
                        </div>
                        <div className='mt-[2rem] grid grid-cols-2 gap-5'>
                            <DateInput
                                required={false}
                                name={"dob"}
                                value={dob}
                                onChange={clientHandleChange}
                                title={selectedLanguage?.name == "English" ? "Date of Birth" : translation[477]?.value ? translation[477]?.value : "Date of Birth"}
                            />
                            <Dropwdown
                                required={true}
                                name={"gender"}
                                value={gender}
                                onChange={clientHandleChange}
                                onBlur={() => onBlurHandler({ gender: gender })}
                                error={errors?.gender}
                                title={selectedLanguage?.name == "English" ? "Gender" : translation[479]?.value ? translation[479]?.value : "Gender"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Gender" : translation[516]?.value ? translation[516]?.value : "Select Gender"}
                                options={[
                                    { label: selectedLanguage?.name == "English" ? "Male" : translation[86]?.value ? translation[86]?.value : "Male", value: 'Male' },
                                    { label: selectedLanguage?.name == "English" ? "Female" : translation[87]?.value ? translation[87]?.value : "Female", value: 'Female' }
                                ]}
                            />

                            <Dropwdown
                                required={false}
                                name={"country"}
                                value={country}
                                onChange={clientHandleChange}
                                options={countriesList}
                                title={selectedLanguage?.name == "English" ? "Country" : translation[52]?.value ? translation[52]?.value : "Country"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Country" : translation[53]?.value ? translation[53]?.value : "Select Country"}
                                edit={isEdit ? true : false}

                            />
                            <Dropwdown
                                required={false}
                                title={selectedLanguage?.name == "English" ? "State" : translation[481]?.value ? translation[481]?.value : "State"}
                                placeholder={selectedLanguage?.name == "English" ? "Select State" : translation[55]?.value ? translation[55]?.value : "Select State"}
                                name={"state"}
                                value={state}
                                onChange={clientHandleChange}
                                options={stateList}
                                edit={isEdit ? true : false}
                            />

                            {/* <Dropwdown
                            required={false}
                            name={"city"}
                            value={city}
                            onChange={clientHandleChange}
                            options={cityList}
                            title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                            placeholder={selectedLanguage?.name == "English" ? "Select City" : translation[57]?.value ? translation[57]?.value : "Select City"}
                            edit={isEdit ? true : false}
                        /> */}

                            <LoginInput
                                required={false}
                                name={"city"}
                                value={city}
                                disabled={!state || !country}
                                onChange={clientHandleChange}
                                title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                                placeholder={selectedLanguage?.name == "English" ? "Enter City Name" : translation[57]?.value ? translation[57]?.value : "Enter City Name"}
                            />

                            <LoginInput
                                required={false}
                                type={"number"}
                                name={"postal_code"}
                                value={postal_code}
                                onChange={clientHandleChange}
                                title={selectedLanguage?.name == "English" ? "Postal Code" : translation[58]?.value ? translation[58]?.value : "Postal Code"}
                                onEnterSubmit={checkClientExistenceBeforeSubmit}
                                placeholder={selectedLanguage?.name == "English" ? "Enter postal code" : translation[59]?.value ? translation[59]?.value : "Enter postal code"} />

                            <LoginInput
                                required={false}
                                name={"address"}
                                value={address}
                                onChange={clientHandleChange}
                                // onBlur={() => onBlurHandler({ address: address })}
                                error={errors?.address}
                                title={selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
                                onEnterSubmit={checkClientExistenceBeforeSubmit}
                                placeholder={selectedLanguage?.name == "English" ? "Enter Address" : translation[482]?.value ? translation[482]?.value : "Enter Address"} />

                            {/* <LoginInput
                                required={false}
                                type={"number"}
                                title={selectedLanguage?.name == "English" ? "Card Number" : translation[518]?.value ? translation[518]?.value : "Card Number"}
                                name={"card_number"}
                                value={card_number}
                                onChange={clientHandleChange}
                                onEnterSubmit={checkClientExistenceBeforeSubmit}
                                placeholder={selectedLanguage?.name == "English" ? "Enter Card Number" : translation[519]?.value ? translation[519]?.value : "Enter Card Number"} /> */}

                            <Dropwdown
                                required={true}
                                name={"is_active"}
                                value={is_active}
                                onChange={clientHandleChange}
                                onBlur={() => onBlurHandler({})}
                                error={errors?.is_active}
                                title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[521]?.value : "Select Status"}
                                options={[
                                    {
                                        value: true,
                                        label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                                    },
                                    {
                                        value: false,
                                        label: selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                                    },
                                ]}
                            />

                            <Dropwdown
                                required={true}
                                name="language"
                                value={language}
                                onChange={clientHandleChange}
                                onBlur={() => onBlurHandler({ language: language })}
                                error={errors.language}
                                options={languageList?.map((lng) => {
                                    return { label: lng.name, value: lng.id }
                                })}
                                title={selectedLanguage?.name == "English" ? "Preferred Language" : translation[522]?.value ? translation[522]?.value : "Preferred Language"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Preferred Language" : translation[523]?.value ? translation[523]?.value : "Select Preferred Language"}
                            />



                            <Dropwdown
                                title={selectedLanguage?.name == "English" ? "How do you know about us?" : translation[526]?.value ? translation[526]?.value : "How do you know about us?"}
                                placeholder={selectedLanguage?.name == "English" ? "How did you find us?" : translation[527]?.value ? translation[527]?.value : "How did you find us?"}
                                name={"about_us"}
                                value={about_us}
                                onChange={clientHandleChange}
                                options={[
                                    { label: selectedLanguage?.name == "English" ? 'Facebook' : translation[528]?.value ? translation[528]?.value : 'Facebook', value: 'Facebook' },
                                    { label: selectedLanguage?.name == "English" ? 'Instagram' : translation[529]?.value ? translation[529]?.value : 'Instagram', value: 'Instagram' },
                                    { label: selectedLanguage?.name == "English" ? 'Twitter' : translation[531]?.value ? translation[531]?.value : 'Twitter', value: 'Twitter' },
                                    { label: selectedLanguage?.name == "English" ? 'Whatsapp' : translation[532]?.value ? translation[532]?.value : 'Whatsapp', value: 'Whatsapp' },
                                    { label: selectedLanguage?.name == "English" ? 'Community' : translation[533]?.value ? translation[533]?.value : 'Community', value: 'Community' },
                                    { label: selectedLanguage?.name == "English" ? 'Media_Ads' : translation[534]?.value ? translation[534]?.value : 'Media_Ads', value: 'Media Ads' },
                                    { label: selectedLanguage?.name == "English" ? 'Friends' : translation[535]?.value ? translation[535]?.value : 'Friends', value: 'Friends' },
                                ]} />
                            {/* <div className='flex flex-col gap-5 text-sm'>
                            <I_Info_Icon text={selectedLanguage?.name == "English" ? "Email Marketing" : translation[524]?.value ? translation[524]?.value : "Email Marketing"} info={selectedLanguage?.name == "English" ? "Subscribe/Un-subscribe from the marketing emails." : translation[525]?.value ? translation[525]?.value : "Subscribe/Un-subscribe from the marketing emails."} />
                            <div className='flex items-center gap-2'>
                                <RadioBtn
                                    id='1'
                                    checked={marketing === 'opt_in'}
                                    text='opt_in'
                                    value={marketing}
                                    name="marketing"
                                    onClick={() => setClientsData((prev) => ({ ...prev, marketing: 'opt_in' }))}
                                />
                                <RadioBtn
                                    id='2'
                                    checked={marketing === 'opt_out'}
                                    text='opt_out'
                                    value={marketing}
                                    name="marketing"
                                    onClick={() => setClientsData((prev) => ({ ...prev, marketing: 'opt_out' }))}
                                />
                            </div>
                        </div> */}
                        </div>
                        <Textarea
                            // required={true}
                            title={selectedLanguage?.name == "English" ? "Client Note" : translation[536]?.value ? translation[536]?.value : "Client Note"}
                            name={"customer_note"}
                            value={customer_note}
                            onChange={clientHandleChange}
                            onBlur={() => onBlurHandler({ customer_note: customer_note })}
                            error={errors.customer_note}
                        />
                        <ImageUploader
                            title={selectedLanguage?.name == "English" ? "Waiver/Disclaimer" : translation[1565]?.value ? translation[1565]?.value : "Waiver/Disclaimer"}
                            // imgSize={"600x150"}
                            // name={"website"}
                            accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"}
                            mb_file_size={'10'}
                            showImageSize={false}
                            multiple={false}
                            id={'upload_img_website'}
                            onChange={(e) => { handleImageChange(e, 'website') }}
                        />
                        <div className="flex flex-wrap gap-5">
                            {allImages?.map((itm) => {
                                return (
                                    <ThumnailCard
                                        url={itm?.image}
                                        title={itm?.fileName}
                                        showThumbnailSection={false}
                                        showImageSize={false}
                                        onDelete={() => { handleDeleteImage(itm?.id) }}
                                    />
                                )
                            })}
                        </div>
                        <CheckBox
                            name="allow_loyalty"
                            onChange={clientHandleChange}
                            value={allow_loyalty}
                            checked={allow_loyalty}
                            label_Class={"!normal-case"}
                            label={"Allow loyalty"}
                        />
                        <div className='flex items-center justify-start mt-[2rem]'>
                            <LoginBtn
                                loading={loading}
                                onClick={() => { checkClientExistenceBeforeSubmit(); }}
                                disabled={loading}
                                // || !language
                                // || !mobile_number
                                // || mobile_number?.split("-", 2)[1]?.length < 8
                                // || mobile_number?.split("-", 2)[1]?.length > 12
                                // || errors?.mobile_number
                                // || !full_name}
                                // value={details}
                                // onChange={(e)=>{setDetails(e.target.value)}}
                                text={`${clientId ?
                                    selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                                    : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}`}
                            />
                        </div>
                    </div>
                }
            </div>

            {
                finished && <Popup heading='Successful' open={finished}
                    onclose={() => {
                        if (from_url === "appointment") {
                            navigate(-1)
                        } else {
                            navigate('/dashboard/clients/client-profiles/')
                        }
                    }}
                    close={setFinished} >
                    <div className='my-[2rem] flex flex-col gap-5 items-center text-center'>
                        <div className='h-[150px] w-[150px]'>
                            <Svgs.Form_Complete_check />
                        </div>
                        <h2 className='font-semibold text-xl'>{selectedLanguage?.name == "English" ? "Client added successfully" : translation[537]?.value ? translation[537]?.value : "Client added successfully"}</h2>
                        <p className='text-[#7B7B7B] text-sm'>{selectedLanguage?.name == "English" ? "You have added the client successfully." : translation[538]?.value ? translation[538]?.value : "You have added the client successfully."}</p>
                        <LoginBtn
                            onClick={() => {
                                if (from_url === "appointment" || from_url === "quick_sales_checkout") {
                                    navigate(-1)
                                } else {
                                    navigate('/dashboard/clients/client-profiles/')
                                }
                            }}
                            borderBtn >
                            <p className='font-semibold'>{selectedLanguage?.name == "English" ? "View All Clients" : translation[539]?.value ? translation[539]?.value : "View All Clients"}</p>
                        </LoginBtn>
                    </div>
                </Popup>
            }
        </>
    )
}

export default AddClient