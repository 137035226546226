import React, { useState } from 'react'
import Svgs from '../../../../../Assets/svgs'
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';

const DateCounter = (props) => {
    const [Today, setToday] = useState(new Date().toLocaleDateString("en-CA"));
    const [ToggleCalendar, setToggleCalendar] = useState(false)
    return (
        <div className='flex items-center gap-3'>
            <div onClick={() => {

                var date = new Date(props.SelectedDate)
                date.setDate(date.getDate() - 1);
                const minDate = new Date(props?.minDate);
                if (props?.minDate) {
                    if (date >= minDate) {
                        props.setSelectedDate(date.toLocaleDateString("en-CA"));
                    }
                }
                else {
                    date = date.toLocaleDateString("en-CA");
                    props.setSelectedDate(date)
                }

            }} className='bg-[#F1F6FA] rounded-md h-[2.5rem] w-[2.5rem] flex items-center justify-center cursor-pointer'>
                <Svgs.LeftChevron />
            </div>
            <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                <button className='p-2' onClick={() => {
                    props.setSelectedDate(Today)
                }}>
                    <h2 className='text-[#101928]'>
                        {props?.selectedLanguage?.name == "English" ? "Today" : props?.translation[123]?.value ? props?.translation[123]?.value : "Today"}
                    </h2>
                </button>
                <div className='p-2 border-l border-[#A1A1A1] relative'>
                    {/* <input value={props.SelectedDate} type="date" className='text-primary whitespace-nowrap outline-none' onChange={(e) => {
                        props.setSelectedDate(e.target.value)
                    }} /> */}
                    <p onClick={() => { setToggleCalendar(!ToggleCalendar) }} className='cursor-pointer'>{moment(props.SelectedDate).format('DD-MM-YYYY')}</p>
                    {
                        ToggleCalendar && <>
                            <div className='fixed inset-0 z-1' onClick={() => { setToggleCalendar(false) }}></div>
                            <div className='absolute top-full right-0 z-[2]'>
                                {
                                    props?.bigCalendar ? <>
                                        <Calendar
                                            date={new Date(props.SelectedDate)} className='border border-primary rounded-lg'
                                            onChange={(e) => {
                                                props.setSelectedDate(e.toLocaleDateString("en-CA"));
                                                setToggleCalendar(false)
                                            }}
                                        />
                                    </> : <>
                                        <Calendar
                                            minDate={props?.minDate ? new Date(props?.minDate) : ""}
                                            maxDate={props?.maxDate ? new Date(props?.maxDate) : ""}
                                            date={new Date(props.SelectedDate)} className='border border-primary rounded-lg'
                                            onChange={(e) => {
                                                props.setSelectedDate(e.toLocaleDateString("en-CA"));
                                                setToggleCalendar(false)
                                            }}
                                        />
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
            <div onClick={() => {
                var date = new Date(props.SelectedDate)
                date.setDate(date.getDate() + 1);
                const maxDate = new Date(props?.maxDate);
                if (props?.maxDate) {
                    if (date <= maxDate) {
                        props.setSelectedDate(date.toLocaleDateString("en-CA"));
                    }
                } else {
                    date = date.toLocaleDateString("en-CA");
                    props.setSelectedDate(date)
                }

            }} className='bg-[#F1F6FA] rounded-md h-[2.5rem] w-[2.5rem] flex items-center justify-center cursor-pointer'>
                <Svgs.RightChevron />
            </div>
        </div>
    )
}

export default DateCounter