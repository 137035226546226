import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import LoginBtn from "../LoginBtn";
import LoginInput from "../LoginInput";
import PasswordInput from "../PasswordInput";
import useLoginSection from "./useLoginSection";
import InActivePopup from "./InActivePopup";
import useClientLandingScreenUrl from "Hooks/useClientLandingScreenUrl";
import useClientUrlFromCreatedLink from "Hooks/useClientUrlFromCreatedLink";
import { resendVerificationCodeToPhoneNumberOrEmail } from "Adapters/Api";
import LoginWithGoogle from "Components/NstyleApp/SocialsLogin/LoginWithGoogle";
import LoginWithFacebook from "Components/NstyleApp/SocialsLogin/LoginWithFacebook";
import { userLogin } from "Adapters/Api";
import { toast } from "react-toastify";
import ToggleWithText from "../ToggleWithText";

const LoginSection = ({ className }) => {
  const navigate = useNavigate();
  const {
    errors,
    active,
    loading,
    loginCredentials,
    handleChange,
    onBlurHandler,
    onSubmitHandler,
    businessClientLogin,
  } = useLoginSection();
  const [searchParams, setSearchParams] = useSearchParams();
  const { urls, tanant_hash, tanant_business } = useClientLandingScreenUrl();

  const { email, password } = loginCredentials;
  const [showforget, setShow] = useState(false);
  const [show_activate_popup, setShowPopup] = useState(false);
  const { nexts, hash, token, domain } = useClientUrlFromCreatedLink();

  const account_type = searchParams.get("account_type");
  // const nexts = searchParams.get("next");
  // const hash = searchParams.get("hash");
  // const token = searchParams.get("token");
  // const domain = searchParams.get("domain");
  const business = nexts?.split("business=")[1];

  const onOtpSubmitHandler = async () => {
    // setLoading(true);
    const response = await resendVerificationCodeToPhoneNumberOrEmail({
      email: email,
      code_for: 'Email',
      ignore_activity: false
    });

    if (response && response?.status === 200) {
      //set select verification method like mobile, email
      // props.onSendCode({ sent_to: email });
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      navigate(`/auth/login/activate?otp_sent=true&user_query=${email}&query_type=Email&account_type=${account_type}`);
    } else {
      toast.error(response?.response?.data?.response?.message, { toastId: "toast" });
    }
    // setLoading(false);

  };

  const SocialLogin_Handler = (user) => {
    userLogin(
      {
        email: user.email,
        social_account: true,
        social_platform: user.social_platform,
      },
      (result) => {
        let data = result?.data?.response?.data;
        let hostName = process.env.REACT_APP_SERVER_APPLICATION_DOMAIN
        if (typeof window !== 'undefined') {
          hostName = window.location.host;
        }
        const hostArr = hostName.split(".");
        hostArr.unshift(`${data.domain ? data.domain : null}`);
        const domain = hostArr.join(".");
        window.location = `http://${domain}/authentication/login/auto_login/?user_login_id=${data.id}&access_token=${data.access_token}&next_path=/dashboard&redirection_source=nstyle_app&redirection_purpose=account_and_mobile_otp_verification${data?.selected_location ? `&selected_location=${data?.selected_location}` : ''}`;

        toast.success("Authenticated successful", { toastId: "toast" });
      },
      (err) => {
        toast.error("Unable to Login, Something went wrong", { toastId: "toast" });
      }
    );
  }
  // const onOtpSubmitHandler = async () => {
  //   // setLoading(true);
  //   const response = await resendVerificationCodeToPhoneNumberOrEmail({
  //     email: email,
  //     code_for: 'Email',
  //     ignore_activity: false
  //   });

  //   if (response && response?.status === 200) {
  //     //set select verification method like mobile, email
  //     // props.onSendCode({ sent_to: email });
  //     let successMessage = response?.data?.response?.message;
  //     toast.success(successMessage,{ toastId: "toast" });
  //     navigate(`/auth/login/activate?otp_sent=true&user_query=${email}&query_type=Email`);
  //   } else {
  //     toast.error(response?.response?.data?.response?.message);
  //   }
  //   // setLoading(false);
  // };

  return (
    <>
      <div
        className={`flex flex-1 flex-col gap-5 justify-center md:py-[3rem] py-[1.75rem] lg:mx-0 mx-auto  ${className} w-full lg:py-0`}
      >
        <h1 className="text-[25px] md:text-[34px] font-semibold text-[rgb(55,65,104)]" style={{textTransform: 'none'}}>
           Welcome to Nstyle: <br /> Sign into your account
        </h1>
        
        <LoginInput
          required={true}
          type="email"
          name="email"
          onChange={handleChange}
          value={email}
          onBlur={() => onBlurHandler({ email: email })}
          title="Email Address"
          placeholder="Enter your email address"
          error={errors.email}
          onEnterSubmit={() => {
            if (account_type === "everyone") {
              if (nexts) {
                businessClientLogin(
                  hash,
                  business,
                  `${nexts}&hash=${hash}&token=${token}&domain=${domain}`
                );
              } else {
                businessClientLogin(tanant_hash, tanant_business, urls)
              }
            } else {
              onSubmitHandler({}, (response) => {
                if (
                  response.status_code == 4009 ||
                  response.status_code == 4010
                ) {
                  // navigate("/auth/login/activate");
                  onOtpSubmitHandler()
                } else if (response.status_code == 4013) {
                  setShow(true);
                }
              });
            }

          }}
        />

        <PasswordInput
          required={true}
          title="Password"
          name="password"
          onChange={handleChange}
          value={password}
          onBlur={() => onBlurHandler({ password: password })}
          placeholder="Enter 6 digit password"
          error={errors.password}
          onEnterSubmit={() => {
            if (account_type === "everyone") {
              if (nexts) {
                businessClientLogin(hash, business, `${nexts}&hash=${hash}&token=${token}&domain=${domain}`);
              } else {
                businessClientLogin(tanant_hash, tanant_business, urls)
              }
            } else {
              onSubmitHandler({}, (response) => {
                if (
                  response.status_code == 4009 ||
                  response.status_code == 4010
                ) {
                  // navigate("/auth/login/activate");
                  onOtpSubmitHandler()
                } else if (response.status_code == 4013) {
                  setShow(true);
                }
              });
            }

          }}
        />
        {/* {showforget && (
          <div className="text-right"> */}
            {/* <p
              className="text-primary text-sm cursor-pointer w-fit"
              onClick={() => {
                navigate("/auth/login/forgot");
              }}
            >
              Forgot Password?
            </p> */}
            {/* <p className='text-primary text-sm cursor-pointer' onClick={() => { navigate('/auth/login/activate') }}>Verify Your Accout?</p> */}
          {/* </div> */}
        {/* )} */}


        <div className="flex justify-between items-center">
          <ToggleWithText textClass={"text-[#808080] font-normal"} title2="Remember me"/>
              <div className="text-[rgb(56,121,255)] font-bold text-sm text-right cursor-pointer" onClick={() => {
                navigate("/auth/login/forgot");
              }}>Forgot Password</div>
        </div>

        <div className="flex justify-center w-full">
          <LoginBtn
            loading={loading}
            disabled={loading}
            // disabled={!active || loading}
            // className={active != false ? "" : "opacity-40"}
            text={<>Sign In&nbsp;</>}
            className={"w-[100%!important] flex justify-center"}
            onClick={() => {
              if (account_type === "everyone") {
                if (nexts) {
                  businessClientLogin(hash, business, `${nexts}&hash=${hash}&token=${token}&domain=${domain}`);
                } else {
                  businessClientLogin(tanant_hash, tanant_business, urls)
                }
              } else {
                onSubmitHandler({}, (response) => {
                  if (
                    response.status_code == 4009 ||
                    response.status_code == 4010
                  ) {
                    // Send Verification OTP using API here
                    onOtpSubmitHandler()
                    // navigate(`/auth/login/activate?otp_sent=true&user_query=${email}&query_type=Email`);
                  } else if (response.status_code == 4013) {
                    setShow(true);
                  }
                });
              }
            }}
          >
            
          </LoginBtn>
        </div>
          {/* <div className="flex flex-col items-center justify-center gap-3">
            <p className="text-sm text-[#808080]">or continue with</p>
            <div className="flex justify-center gap-3">
              <LoginWithFacebook
                login={true}
                onVerify={(user) => {
                  SocialLogin_Handler({ ...user, social_platform: "Facebook" });
                }}
              />
              <LoginWithGoogle login={true} onVerify={SocialLogin_Handler} />
            </div>
            <p className="block md:hidden text-[#5679FF] text-base font-medium pt-2" onClick={() => {
                            navigate('/help')
                        }}>Help?</p>
          </div> */}
      </div>

      {/* In-Active account popup message  */}
      {/* {show_activate_popup && (
        <InActivePopup navigate={navigate} setShowPopup={setShowPopup} />
      )} */}
    </>
  );
};

export default LoginSection
