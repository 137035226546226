import { combineReducers } from "redux";
import LoaderReducer from "./LoaderReducer";
import ThemeReducer from "./Theme/ThemeReducer";
import CurrencyReducer from "./Currency/CurrencyReducer";
import LocationReducer from "./Reducers/LocationReducer";
import ServiceReducer from "./Reducers/ServiceReducer";
import SaleServicesReducer from './../TenantRedux/Reducers/SaleServiceReducer/index';
import BusinessSettingsReducers from "Redux/TenantRedux/Reducers/businessSettingsReducers";

const UtilityRootReducer = combineReducers({
    theme: ThemeReducer,
    loader: LoaderReducer,
    currency: CurrencyReducer,
    locations: BusinessSettingsReducers,
    services: ServiceReducer,
    saleService: SaleServicesReducer,

});

export default UtilityRootReducer;
