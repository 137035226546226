import { getAllSaleOders } from 'Adapters/Api/saleOrders';
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { SALE_ORDER } from 'Redux/TenantRedux/ActionTypes/salleOrderTypes';

function useSaleOrderProduct() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const selected_location = state?.locations?.selected_location;
    const allSaleorders = state?.saleOrder?.allSaleorders;
    const location_currency = state?.utility?.currency?.location_currency;

    const [productList, setProductList] = useState([]);


    // filter data on location base
    useEffect(() => {
        if (allSaleorders?.length === 0) {
            dispatch(getAllSaleOders());
        } else {
            const saleOrderOnSelectedLocation = allSaleorders?.filter(
                ({ location }) => location?.id === selected_location
            );

            let prduct = saleOrderOnSelectedLocation?.filter((itm) => itm?.product?.length > 0)

            setProductList(prduct);
        }
    }, [selected_location, allSaleorders]);

    //calculate total bill of selecte product invice
    const calculate_totals = (item, isInvoice = false) => {
        let total_bill = 0;

        if (typeof item?.product == "object") {
            if (item?.product?.length > 0) {
                item?.product?.forEach((itm) => {
                    total_bill += (itm?.product_price) * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        if (isInvoice) {
            dispatch({
                type: SALE_ORDER.CALCULATE_INVOICE_BILL,
                payload: total_bill
            })
        }
        return total_bill;

    };


    return {
        productList,
        calculate_totals,
        location_currency
    }
}

export default useSaleOrderProduct