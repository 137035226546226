import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createPromotion,
  deletePromotion,
  getPromotions,
  updatePromotion,
} from "../../../../Adapters/Api/clients";
import { getsProducts } from "../../../../Adapters/Api/Products";
import { getservice } from "../../../../Adapters/Api/services";
import Svgs from "../../../../Assets/svgs";
import { getProducts } from "../../../../Redux/TenantRedux/Actions/product";
import CircleIcon from "../../Elements/CircleIcon";
import Dropwdown from "../../Elements/Dropwdown";
import LoginBtn from "../../Elements/LoginBtn";
import LoginInput from "../../Elements/LoginInput";
import AddPromotionCard from "./Elements/AddPromotionCard";
import Popup from "../../Elements/Popup";
import I_Info_Icon from "../../Elements/I_Info_Icon";
import PreloaderFull from "../../Elements/PreloaderFull";
import { apiErrorMessage } from "Constants/Data/Errors";

const PromotionSection = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const productRedux = state?.product?.products;
  const serviceRedux = state?.service?.services;
  const client_promo_updated = state.client?.client_promo_updated;
  const [is_delete, setDelete] = useState(undefined);
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const [promoData, setpromoData] = useState([]);

  const [promoinput, setPromoinput] = useState({});
  const [btn_loading, setBtnLoading] = useState(false);

  const [edit_id, setEditId] = useState(undefined);

  const [promoError, setpromoError] = useState({});

  const handlepromoinput = (e) => {
    const { name, value } = e.target;
    setPromoinput({
      ...promoinput,
      [name]: value,
    });
    setpromoError({
      ...promoError,
      [name]: false,
    });
  };

  const promotionsubmithandler = () => {
    if (promoinput.promotion_type === "Service") {
      delete promoinput.product;
    } else if (promoinput.promotion_type === "Product") {
      delete promoinput.service;
    }
    promoinput["business"] = state?.business?.business?.id;
    promoinput["discount_service"] = promoinput.service;

    if (
      (Object.keys(promoinput).includes("product") && promoinput["product"]) ||
      (Object.keys(promoinput).includes("service") && promoinput["service"])
    ) {
      setBtnLoading(true);
      dispatch(
        createPromotion(
          promoinput,
          () => {
            setPromoinput({});
            setpromoError({});
            setBtnLoading(false);
            toast.success("Promotion created Successfully");
          },
          () => {
            setBtnLoading(false);
            toast.error("Promotion did not created");
          }
        )
      );
    } else {
      if (promoinput.reward_type === "Service") {
        setpromoError({
          ...promoError,
          service: true,
        });
      } else {
        setpromoError({
          ...promoError,
          product: true,
        });
      }
    }
  };

  const fetchProductslist = async () => {
    const response = await getsProducts();

    if (response.status === 200) {
      let productList = response?.data?.response?.products;
      dispatch(getProducts(productList));
    }
  };

  const onPromotionDeleteHandler = (id) => {
    dispatch(
      deletePromotion(
        { id: id },
        () => {
          toast.success("Promotion Deleted successfully");
        },
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };

  useEffect(() => {
    dispatch(getservice());
    dispatch(getPromotions());
    fetchProductslist();
  }, []);

  const onPromotionEditClickHnadler = (id) => {
    setEditId(id);
  };

  const onPromotionUpdateHnadler = (edited_data) => {
    dispatch(
      updatePromotion(
        edited_data,
        () => {
          toast.success("Promotion Updated successfully");
          setEditId(false);
        },
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  return !client_promo_updated ? (
    <PreloaderFull />
  ) : (
    <>
      <div className="col-span-4 slide-in-bottom">
        <div className="flex flex-col gap-5 ">
          <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
            <CircleIcon>
              <Svgs.Promotions />
            </CircleIcon>
            <div className="flex flex-col gap-1 lg:text-left text-center">
              <h2 className="text-primary font-semibold text-xl">
                Add Promotions
              </h2>
              <p className="text-[#7B7B7B] text-sm">
                Set Promotion criterias for your clients on
                products/services/membership
              </p>
            </div>
          </div>
          <div className="lg:w-[82%] w-full col-span-1">
            <div className="">
              <>
                {state.client.client_promo.length > 0 ? (
                  state.client.client_promo.map((promotion, index) => {
                    return (
                      <AddPromotionCard
                        index={index}
                        data={promotion}
                        is_edit={edit_id == promotion.id ? true : false}
                        onUpdate={(edited_data) => {
                          onPromotionUpdateHnadler(edited_data);
                        }}
                        onDelete={(id) => {
                          setDelete(id);
                        }}
                        onEditClick={(id) => {
                          onPromotionEditClickHnadler(id);
                        }}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
              </>
              {!employee ||
                (employee &&
                  employeePermissions?.client_reward?.includes("create")) ? (
                <div className="flex gap-6">
                  <div className="relative">
                    <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                      <div className="h-[1.8rem] w-[1.8rem] rounded-full border border-[#C9C7C7] bg-white flex items-center justify-center"></div>
                    </div>
                    <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
                  </div>
                  <div className="mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4">
                    <LoginInput
                      name={"name"}
                      required={true}
                      title="Promotion Name"
                      placeholder="Promotion Name"
                      value={promoinput?.name}
                      error={promoError?.name}
                      onEnterSubmit={promotionsubmithandler}
                      onChange={(e) => {
                        handlepromoinput(e);
                      }}
                      onBlur={() => {
                        if (!promoinput.name) {
                          setpromoError({
                            ...promoError,
                            name: true,
                          });
                        } else {
                          setpromoError({
                            ...promoError,
                            name: false,
                          });
                        }
                      }}
                    />

                    <Dropwdown
                      required
                      title="Choose Promotion Type"
                      placeholder="Promotion Type"
                      name="promotion_type"
                      value={promoinput?.promotion_type}
                      error={promoError.promotion_type}
                      onEnterSubmit={promotionsubmithandler}
                      options={[
                        { label: "Service", value: "Service" },
                        { label: "Product", value: "Product" },
                      ]}
                      onChange={(e) => {
                        handlepromoinput(e);
                      }}
                      onBlur={() => {
                        if (!promoinput.promotion_type) {
                          setpromoError({
                            ...promoError,
                            promotion_type: true,
                          });
                        } else {
                          setpromoError({
                            ...promoError,
                            promotion_type: false,
                          });
                        }
                      }}
                    />
                    <div className="grid lg:grid-cols-2 gap-4">
                      {promoinput.promotion_type == "Service" ? (
                        <>
                          <Dropwdown
                            title="Services"
                            required={true}
                            placeholder="Select Service"
                            name="service"
                            value={promoinput?.service}
                            error={promoError?.service}
                            onEnterSubmit={promotionsubmithandler}
                            onChange={(e) => {
                              handlepromoinput(e);
                            }}
                            options={serviceRedux.map((itm) => {
                              return {
                                label: itm.name,
                                value: itm.id,
                              };
                            })}
                            onBlur={() => {
                              if (!promoinput.service) {
                                setpromoError({
                                  ...promoError,
                                  service: true,
                                });
                              } else {
                                setpromoError({
                                  ...promoError,
                                  service: false,
                                });
                              }
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Dropwdown
                            title="Select Product"
                            required={true}
                            placeholder="Select Product"
                            name="product"
                            value={promoinput?.product}
                            error={promoError?.product}
                            onEnterSubmit={promotionsubmithandler}
                            options={productRedux.map((itm) => {
                              return {
                                label: itm.name,
                                value: itm.id,
                              };
                            })}
                            onChange={(e) => {
                              handlepromoinput(e);
                            }}
                            onBlur={() => {
                              if (!promoinput.product) {
                                setpromoError({
                                  ...promoError,
                                  product: true,
                                });
                              } else {
                                setpromoError({
                                  ...promoError,
                                  product: false,
                                });
                              }
                            }}
                          />
                        </>
                      )}

                      <LoginInput
                        required={true}
                        name="no_of_purchases"
                        type="number"
                        title={
                          <I_Info_Icon
                            text="No. of Purchases"
                            info="Select amount of purchases need to be done by the client to get this offer."
                          />
                        }
                        value={promoinput?.no_of_purchases}
                        error={promoError?.no_of_purchases}
                        onEnterSubmit={promotionsubmithandler}
                        placeholder={`Enter no. of Purchases`}
                        onChange={(e) => {
                          handlepromoinput(e);
                        }}
                        onBlur={() => {
                          if (!promoinput.no_of_purchases) {
                            setpromoError({
                              ...promoError,
                              no_of_purchases: true,
                            });
                          } else {
                            setpromoError({
                              ...promoError,
                              no_of_purchases: false,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="grid lg:grid-cols-2 gap-4">
                      <div className="flex flex-col gap-2">
                        {/* <p className='text-sm'>Discount Percentage <span class="text-red-600"> *</span></p> */}
                        <LoginInput
                          placeholder="Ex 50%"
                          title={
                            <I_Info_Icon
                              text="Discount Percentage "
                              info="Add discount percentage that client will get on select service after completing the number of purchases"
                            />
                          }
                          name="discount"
                          value={promoinput?.discount}
                          error={promoError?.discount}
                          onEnterSubmit={promotionsubmithandler}
                          onChange={(e) => {
                            handlepromoinput(e);
                          }}
                          onBlur={() => {
                            if (!promoinput.discount) {
                              setpromoError({
                                ...promoError,
                                discount: true,
                              });
                            } else {
                              setpromoError({
                                ...promoError,
                                discount: false,
                              });
                            }
                          }}
                        />
                      </div>
                      {/* <LoginInput
                                            // placeholder='Ex 50%'
                                            type='date'
                                            title='Start Date'
                                            name={"start_time"}
                                            value={promoinput?.start_time}
                                            error={promoError?.start_time}
                                            onEnterSubmit={promotionsubmithandler}
                                            onChange={(e) => {
                                                handlepromoinput(e)
                                            }}
                                            onBlur={() => {
                                                if (!promoinput.start_time) {
                                                    setpromoError(
                                                        {
                                                            ...promoError,
                                                            start_time: true
                                                        }
                                                    )
                                                }

                                                else {
                                                    setpromoError(
                                                        {
                                                            ...promoError,
                                                            start_time: false
                                                        }
                                                    )
                                                }

                                            }}

                                        />
                                        <LoginInput
                                            placeholder='Ex 50%'
                                            type='date'
                                            title='End Date'
                                            name={"end_time"}
                                            value={promoinput?.end_time}
                                            error={promoError?.end_time}
                                            onEnterSubmit={promotionsubmithandler}
                                            onChange={(e) => {
                                                handlepromoinput(e)
                                            }}
                                            onBlur={() => {
                                                if (!promoinput.end_time) {
                                                    setpromoError(
                                                        {
                                                            ...promoError,
                                                            end_time: true
                                                        }
                                                    )
                                                } else {
                                                    setpromoError(
                                                        {
                                                            ...promoError,
                                                            end_time: false
                                                        }
                                                    )
                                                }

                                            }}
                                        /> */}
                      <Dropwdown
                        required
                        title={
                          <I_Info_Icon
                            text="Discount Duration"
                            info="For how many days, the discount is valid to avail by the client."
                          />
                        }
                        type="text"
                        options={[
                          { label: "14 Days", value: "14 Days" },
                          { label: "1 Months", value: "1 Months" },
                          { label: "2 Months", value: "2 months" },
                          { label: "3 Months", value: "3 Months" },
                          { label: "4 Months", value: "4 Months" },
                          { label: "6 Months", value: "6 Months" },
                          { label: "8 Months", value: "8 Months" },
                          { label: "1 Years", value: "1 Years" },
                          { label: "18 Months", value: "18 Months" },
                          { label: "2 Years", value: "2 Years" },
                          { label: "5 Years", value: "5 Years" },
                        ]}
                        placeholder="Select Discount Duration"
                        name="duration"
                        value={promoinput?.duration}
                        error={promoError?.duration}
                        onEnterSubmit={promotionsubmithandler}
                        onChange={(e) => {
                          handlepromoinput(e);
                        }}
                        onBlur={() => {
                          if (!promoinput.duration) {
                            setpromoError({
                              ...promoError,
                              duration: true,
                            });
                          } else {
                            setpromoError({
                              ...promoError,
                              duration: false,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {!employee ||
            (employee &&
              employeePermissions?.client_reward?.includes("create")) ? (
            <LoginBtn
              text="Add Promotions"
              disabled={
                !promoinput?.name ||
                !promoinput?.promotion_type ||
                !promoinput?.no_of_purchases ||
                !promoinput?.discount ||
                !promoinput?.duration
              }
              loading={btn_loading}
              onClick={() => {
                promotionsubmithandler();
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {is_delete && (
        <Popup
          heading="Are you sure you want to delete this Promotion?"
          onclose={() => {
            setDelete(false);
          }}
        >
          <div>
            <p>
              Are you sure you want to delete this Promotion? You won't be able to
              see this again.
            </p>
            <div className="flex justify-end mt-4">
              <LoginBtn
                deleteBtn
                text="Yes! Delete"
                onClick={() => {
                  onPromotionDeleteHandler(is_delete);
                  setTimeout(() => {
                    setDelete(false);
                  }, 500);
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default PromotionSection;
