import Svgs from 'Assets/svgs';
import { error_message } from 'Constants/Variables';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const Textarea = ({ parent, readOnly, disabled, required, errorMessage, limit, value, onChange, ...props }) => {
    const state = useSelector((state) => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    const parsedPlaceholder = props?.isCapitalized ? props?.placeholder : props?.placeholder && props?.placeholder.charAt(0).toUpperCase() + props?.placeholder.slice(1).toLowerCase();

    const [count, setCount] = useState(0)
    const [text, setText] = useState(value || ''); // Internal state for the text

    useEffect(() => {
        setCount(value ? value?.length : 0)
        setText(value || ''); // Update internal state when external value changes
    }, [value]);


    const handleChange = (event) => {
        const newText = event.target.value;

        // Check if the new text exceeds the limit or is empty
        if (newText?.length <= limit) {
            setText(newText);
            setCount(newText?.length)
            let updatedEvent;
            if (newText?.length === 0) {
                updatedEvent = { ...event, target: { ...event.target, value: '', name: props.name } };
            } else {
                updatedEvent = { ...event, target: { ...event.target, value: newText, name: props.name } };
            }
            onChange && onChange(updatedEvent); // Propagate the change to the parent component
        }
        else {
            // Trim the pasted text to fit within the limit
            const trimmedText = newText?.slice(0, limit);
            setText(trimmedText);
            setCount(trimmedText.length);

            const updatedEvent = { ...event, target: { ...event.target, value: trimmedText, name: props.name } };
            onChange && onChange(updatedEvent); // Propagate the change to the parent component
        }
    };

    return (
        <div className={`flex flex-col gap-2 relative ${parent}`}>
            <p className='text-sm'>{props.title} {required && props.title && <span className='text-red-500'> *</span>}{!required && props.title && !readOnly && <span className='text-[#A1A1A1] text-sm'>
                ({selectedLanguage?.name == "English" ? "Optional" : translation[637]?.value ? translation[637]?.value : "Optional"})</span>}</p>
            <div className='relative'>
                <textarea
                    disabled={disabled}
                    value={text}
                    readOnly={readOnly ? true : false}
                    onChange={handleChange}
                    name={props.name}
                    rows={props.rows ? props.rows : 10}
                    className={`w-full placeholder:text-[#A1A1A1] text-sm resize-none rounded-md border outline-none p-3
                      ${(props.error || errorMessage) && " border-l-[7px] border-[#eb3b3b]"} border-[#A1A1A1] `}
                    placeholder={parsedPlaceholder}

                />
                {
                    (props.counter && !readOnly) && <div className='absolute bottom-2 text-xs right-2 text-[#A1A1A1]'>{count}/{limit}</div>
                }
                {(props?.error) && (typeof props?.error == "string") ? <span className='text-[#eb3b3b] flex items-center gap-2 text-xs'>{props?.error}<Svgs.I /></span> : (typeof props?.error == "boolean" && props?.error == true) ? <span className='text-[#eb3b3b] flex items-center gap-2 text-xs'>{error_message} <Svgs.I /></span> : ""}
            </div>
            {errorMessage && (
                <span
                    className={
                        "text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[100%] w-full justify-end"
                    }
                >
                    <Svgs.I fill="#eb3b3b" />
                    {errorMessage}
                </span>
            )}
        </div>
    );
};

Textarea.defaultProps = {
    counter: true,
    limit: '150',
    rows: '5'
}

export default Textarea;
