import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Popup from "Components/TenantApp/Elements/Popup";
import { useState, useEffect } from "react";
import Svgs from "../../../../../../Assets/svgs";
import ConsumptionPopup from "./ConsumptionPopup";
import useConsumptions from "./useConsumptions";
import { useSelector } from "react-redux";

const ConsumptionItem = ({ item }) => {
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <>
      {editPopup && (
        <ConsumptionPopup
          editMode={true}
          translation={translation}
          selectedLanguage={selectedLanguage}
          editData={item}
          handleClose={() => {
            setEditPopup(false);
          }}
        />
      )}
      {deletePopup && (
        <DeleteConsumption
          translation={translation}
          selectedLanguage={selectedLanguage}
          deletePopup={deletePopup}
          setDeletePopup={setDeletePopup}
          deleteItem={item}
        />
      )}
      <div className={`px-12 py-3 inline-grid xl:grid grid-cols-[repeat(4,minmax(155px,1fr))] min-w-full w-fit odd-bg text-[#3A3A3A] transition-all`}>
        <div className="flex items-center">
          <p className="text-sm">{item?.product?.name}</p>
        </div>
        <div className="flex items-center">
          <p className="text-sm">{item?.location?.address_name}</p>
        </div>
        <div className="flex items-center">
          <p className="text-sm">{item?.quantity}</p>
        </div>
        {!employee ? (
          <div className="flex items-center gap-2">
            <div
              className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center  cursor-pointer"
              onClick={() => {
                setDeletePopup(true);
              }}
            >
              <Svgs.Delete fill="#7D7D7D" />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

    </>
  );
};

export default ConsumptionItem;

export const DeleteConsumption = ({ setDeletePopup, deleteItem, translation, selectedLanguage }) => {
  const { consumptionDeleteHandler, loading } = useConsumptions();
  const handleClose = () => {
    setDeletePopup(false);
  };

  return (
    <Popup
      heading={selectedLanguage?.name == "English"
        ? "Are you sure you want to delete this product consumption?"
        : translation[1013]?.value
          ? translation[1013]?.value
          : "Are you sure you want to delete this product consumption?"
      }
      onclose={handleClose}
    >
      <p>
        {selectedLanguage?.name == "English"
          ? "Are you sure you want to delete this product consumption? You won't be able to see this again."
          : translation[1014]?.value
            ? translation[1014]?.value
            : "Are you sure you want to delete this product consumption? You won't be able to see this again."
        }
      </p>
      <div className="flex justify-end mt-4">
        <LoginBtn
          loading={loading}
          text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
          deleteBtn
          onClick={() => consumptionDeleteHandler(deleteItem, handleClose)}
        />
      </div>
    </Popup>
  );
};
