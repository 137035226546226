import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import Popup from 'Components/TenantApp/Elements/Popup';
import React from 'react'
import { useAddMissedOpportunity } from './helper';
import Svgs from 'Assets/svgs';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import { useEffect } from 'react';
import AddEmployeePopup from 'Components/TenantApp/Dashboard/Employee/AddEmployee/AddEmployeePopup';
import DateInput from 'Components/TenantApp/Elements/DateInput';
import AddService from 'Components/TenantApp/Dashboard/Sales/quick-sales/Services/addService';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import AddServiceGroup from 'Components/TenantApp/Dashboard/Sales/quick-sales/Services/AddServiceGroup';
import { getServiceGroupCalendar } from 'Adapters/Api/saleServices';
import { useService } from 'Components/TenantApp/Dashboard/Sales/quick-sales/Services/helper';
import AddAppointmentCard from '../../Elements/AddAppointmentCard';
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown';
import AddClientPopup from 'Components/TenantApp/Dashboard/Clients/AddClient/AddClientPopup';
import { OPENING_TIME } from 'Constants/Data/OpeningHours';
import Textarea from 'Components/TenantApp/Elements/Textarea';
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import NotFound from 'Components/Utility/NotFound';
import ReactDatePicker from 'Components/TenantApp/Elements/ReactDatePicker';
import NoServiceFound from 'Components/Utility/NoServiceFound';

const AddMissedOpportunity = (props) => {
    const { update, onClose, open, isLoading, editValues, addSuccess, updateSuccess } = props
    const {
        handleInputChange, setValues, errorss, values, translation, selectedLanguage, showAddStaffPopup, setShowAddStaffPopup, loader,
        CreateMissedOpportunity, EditMissedOpportunity, handleServices, clientDropdownRedux, submitClick, inp_data, setInpData, select,
        setselect, addNewData, SelectServices, setSelectServices, dropdown, search, searchfunction, serviceEmployee, selected_location,
        select_service_serviceGroups, selectedLocationCurrency, dispatch, ServiceGroup, setServiceGroup, setGroupLoader, servicesRedux,
        possible_error, setPossibleError, appointmentServices, duration_options, FilterDataGroup, checkEmployeetiming, handleRemoveAppointments,
        setSelServGroups, minDateLimit, clientSearchText, setClientSearchText, clientCurrentPage, setClientCurrentPage, clientLoader,
        clientPaginationData, showAddClientPopup, setShowAddClientPopup, clientTypeOptions, dependencyOptions, getClients
    } = useAddMissedOpportunity()

    const { durationOptions, serviceModal, isEdit, active, errors, loading, serviceData, ServiceType, setService, setAddService,
        onSubmitService, serviceHandleChange, onBlurHandler, setIsEdit, removedSelectedEmployee, setErrors, setServiceType, setIsDelete,
        formRef
    } = useService();

    useEffect(() => {
        if (editValues && update) {
            setValues(editValues)
        }
    }, [editValues, update])




    return (
        <>
            <Popup
                heading={
                    !update ?
                        selectedLanguage?.name == "English" ? "Add Missed Opportunity" : translation[806]?.value ? translation[806]?.value : "Add Missed Opportunity"
                        : selectedLanguage?.name == "English" ? "Update Missed Opportunity" : translation[810]?.value ? translation[810]?.value : "Update Missed Opportunity"
                }
                size="lg"
                close={() => {
                    onClose()
                    setValues({ client_type: "", services: [], notes: "", opportunity_date: "" });
                }}
                open={open}
            // customPadding={'pb-[12rem]'}
            >
                {update && isLoading ?
                    <div className="flex flex-col gap-4 animate-pulse">
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="flex justify-start w-full">
                            <div className="w-1/3 h-14 bg-gray-200 rounded-lg" />
                        </div>
                    </div>
                    :
                    <>
                        <div className="flex flex-col gap-4">
                            <Dropwdown
                                name={'client_type'}
                                value={values?.client_type}
                                title={selectedLanguage?.name == "English" ? "Client Type" : translation[330]?.value ? translation[330]?.value : "Client Type"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Client Type" : translation[379]?.value ? translation[379]?.value : "Select Client Type"}
                                onChange={handleInputChange}
                                options={clientTypeOptions}
                            />
                            {values?.client_type === "In_Saloon" &&
                                <div>
                                    <PaginationDropdown
                                        name={'client'}
                                        value={values?.client}
                                        required={true}
                                        title={selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client"}
                                        placeholder={selectedLanguage?.name == "English" ? "Select Client" : translation[225]?.value ? translation[225]?.value : "Select Client"}
                                        onChange={handleInputChange}
                                        setPage={setClientCurrentPage}
                                        page={clientCurrentPage}
                                        setDropdownSearch={setClientSearchText}
                                        dropdownSearch={clientSearchText}
                                        lastPage={clientPaginationData?.pages}
                                        currentPage={clientPaginationData?.currentPage}
                                        loading={clientLoader}
                                        error={errorss?.client}
                                        options={clientDropdownRedux?.map((clnt, i) => {
                                            return {
                                                label: clnt.full_name,
                                                value: clnt.id,
                                                otherData: {
                                                    image: clnt?.image,
                                                    DataOne: clnt.email,
                                                    showImage: true,
                                                    index: i
                                                },
                                                email: clnt.email,
                                                mobile_number: clnt.mobile_number
                                            };
                                        })}
                                    />
                                    {clientDropdownRedux?.length == 0 &&
                                        <div
                                            className="flex items-center gap-2 cursor-pointer pt-2"
                                            onClick={() => {
                                                localStorage.setItem('add_client_from_sales', true)
                                                // navigate('/dashboard/clients/client-profiles/add-client/?from=quick_sales_checkout')
                                                setShowAddClientPopup(!showAddClientPopup)
                                            }}
                                        >
                                            <p className="text-sm mt-6">
                                                <span className="underline mx-px text-[#5679FF]">
                                                    {selectedLanguage?.name == "English" ? "Click here" : translation[502]?.value ? translation[502]?.value : "Click here"}
                                                </span>
                                                {selectedLanguage?.name == "English" ? " to add client." : translation[1284]?.value ? translation[1284]?.value : " to add client."}
                                            </p>
                                        </div>
                                    }
                                </div>
                            }

                            <Dropwdown
                                name={'dependency'}
                                value={values?.dependency}
                                title={selectedLanguage?.name == "English" ? "Dependency" : translation[330]?.value ? translation[330]?.value : "Dependency"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Dependency" : translation[379]?.value ? translation[379]?.value : "Select Dependency"}
                                onChange={handleInputChange}
                                options={dependencyOptions}
                            />

                            {/* <DateInput
                                required={true}
                                title={selectedLanguage?.name == "English" ? "Opportunity Date" : translation[477]?.value ? translation[477]?.value : "Opportunity Date"}
                                name={"opportunity_date"}
                                value={values?.opportunity_date}
                                max={minDateLimit}
                                onChange={handleInputChange}
                                error={errorss.opportunity_date}
                                custom_padding={""}
                            /> */}

                            {/* <DateInput
                                required={true}
                                title={selectedLanguage?.name == "English" ? "Opportunity Date" : translation[477]?.value ? translation[477]?.value : "Opportunity Date"}
                                name={"opportunity_date"}
                                value={values?.opportunity_date}
                                max={minDateLimit}
                                onChange={handleInputChange}
                                error={errorss.opportunity_date}
                            /> */}
                            <LoginInput
                                parentClass={"w-full"}
                                title={selectedLanguage?.name == "English" ? "Opportunity Date" : translation[477]?.value ? translation[477]?.value : "Opportunity Date"}
                                type='date'
                                name={"opportunity_date"}
                                value={values?.opportunity_date}
                                // min={moment().format("YYYY-MM-DD")}
                                max={minDateLimit}
                                onChange={handleInputChange}
                                error={errorss.opportunity_date}
                                required={true}
                            />

                            <Textarea
                                title={selectedLanguage?.name == "English" ? "Booking Note" : translation[1554]?.value ? translation[1554]?.value : "Booking Note"}
                                placeholder={selectedLanguage?.name == "English" ? "Enter Booking note" : translation[1729]?.value ? translation[1729]?.value : "Enter Booking note"}
                                name={"notes"}
                                limit={'150'}
                                value={values?.notes}
                                onChange={handleInputChange}
                                error={errors.notes}
                                rows='5'
                            />

                        </div>

                        {values &&
                            values?.services?.length > 0 &&
                            values?.services?.map((appointment, index) => {
                                return (
                                    <>
                                        <AddAppointmentCard
                                            // memberNotRequired={true}
                                            addMissedOpportunity={true}
                                            custom_padding={'pb-8'}
                                            location_currency={selectedLocationCurrency}
                                            is_last_card={values?.services?.length == (index + 1) ? true : false}
                                            select_service_serviceGroups={select_service_serviceGroups}
                                            time_range={OPENING_TIME}
                                            servicesRedux={servicesRedux}
                                            selectedLanguage={selectedLanguage}
                                            translation={translation}
                                            possible_error={possible_error}
                                            setPossibleError={setPossibleError}
                                            selectedLocation={selected_location}
                                            serviceEmployee={serviceEmployee}
                                            search={search}
                                            select={select}

                                            length={values.services?.length - 1}
                                            appointmentServices={appointmentServices}
                                            setselect={setselect}
                                            searchfunction={searchfunction}
                                            FilterDataGroup={FilterDataGroup}
                                            data={appointment}
                                            values={values}
                                            setAppointmentData={setValues}
                                            appointment_date={values?.appointment_date}
                                            index={index}
                                            key={index}
                                            submitButtonClick={submitClick}
                                            durationOption={duration_options[0]}
                                            checkEmployeetiming={(dt) => {
                                                checkEmployeetiming({ ...dt, index })
                                            }}
                                            onRemove={(id) => handleRemoveAppointments(id)}
                                            setSelServGroups={setSelServGroups}
                                            serviceGroupArray={select_service_serviceGroups}
                                            onUpdate={(
                                                { name, value },
                                                extraValues,
                                                book,
                                                online_slot,
                                                additional_data
                                            ) => {
                                                let ex_data = extraValues ? extraValues : {};
                                                setValues({
                                                    ...values,
                                                    services: [
                                                        ...values.services.map(
                                                            (appmnt, ap_i) => {
                                                                if (ap_i == index) {
                                                                    if (name === "service") {
                                                                        return {
                                                                            ...appmnt,
                                                                            client_can_book: book,
                                                                            slot_availible_for_online: online_slot,
                                                                            srv_name: additional_data?.srv_name,
                                                                            srv_duration: additional_data?.srv_duration,
                                                                            price: additional_data?.price,
                                                                            discount_price: additional_data?.discount_price,
                                                                            duration: additional_data?.srv_duration,
                                                                            [name]: value,
                                                                            ...ex_data,
                                                                        };
                                                                    } else {
                                                                        return {
                                                                            ...appmnt,
                                                                            [name]: value,
                                                                            ...ex_data,
                                                                        };
                                                                    }
                                                                }
                                                                return appmnt;
                                                            }
                                                        ),
                                                    ],
                                                });
                                            }}
                                        />
                                    </>
                                );
                            })}
                        <div className="flex gap-6">
                            <div className="relative">
                                <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                                    <div className="h-[1.8rem] w-[1.8rem] rounded-full border border-[#C9C7C7] bg-white flex items-center justify-center"></div>
                                </div>
                                {values &&
                                    values.services &&
                                    values.services.length > 0 && (
                                        <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
                                    )}
                            </div>
                            <div className="mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4">
                                <div className="grid lg:grid-cols-2 gap-4">
                                    <Dropwdown
                                        // inputClass={"!h-[25.6px] xl:!h-[35.6px]"}
                                        title={selectedLanguage?.name == "English" ? "Time" : translation[1728]?.value ? translation[1728]?.value : "Time"}
                                        placeholder={selectedLanguage?.name == "English" ? "Select Time" : translation[130]?.value ? translation[130]?.value : "Select Time"}
                                        required={true}
                                        name={"date_time"}
                                        value={inp_data?.date_time}
                                        onChange={(e) => {
                                            let sel_data = {
                                                ...inp_data,
                                                [e.target.name]: e.target.value,
                                            };
                                            setInpData(sel_data);
                                            if (e.target.value && inp_data.service) {
                                                addNewData(sel_data);
                                            }
                                        }}
                                        options={OPENING_TIME}
                                    />
                                    <div>
                                        <p className="mb-2 text-sm">
                                            {selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"}
                                            <span class="text-red-600"> *</span></p>


                                        {dropdown == "False" ? (
                                            <div
                                                className="cursor-pointer inputdesign overflow-hidden overflow-x-auto"
                                                onClick={() => {
                                                    setSelectServices(!SelectServices);
                                                }}
                                            >
                                                <p className={`${select ? "!text-black" : ""} placeholder`}>
                                                    {select ?
                                                        select :
                                                        selectedLanguage?.name == "English" ? "Select Services" : translation[89]?.value ? translation[89]?.value : "Select Services"
                                                    }
                                                </p>
                                            </div>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(submitClick && values?.services?.length === 0) &&
                            <p className={"flex items-center justify-end mt-2 gap-1 text-red-600 text-sm"}>
                                <Svgs.I fill="#eb3b3b" />
                                {selectedLanguage?.name == "English" ? "Select at least service" : translation[89]?.value ? translation[89]?.value : "Select at least service"}
                            </p>
                        }
                        <div className="flex items-center justify-start mt-5">
                            {!update || update == "false" ? (
                                <LoginBtn
                                    loading={loader}
                                    disabled={loader}
                                    onClick={(e) => {
                                        CreateMissedOpportunity(() => {
                                            onClose()
                                            addSuccess && addSuccess()
                                        }, () => {

                                        })
                                    }}
                                >
                                    <p className="font-semibold text-white">
                                        {selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                                    </p>
                                </LoginBtn>
                            ) : (
                                <LoginBtn
                                    loading={loader}
                                    disabled={loader}
                                    onClick={(e) => {
                                        EditMissedOpportunity(() => {
                                            updateSuccess && updateSuccess()
                                            onClose()
                                        }, () => {
                                        })
                                    }}
                                >
                                    <p className="font-semibold text-white">
                                        {" "}
                                        {selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}
                                    </p>
                                </LoginBtn>
                            )}
                        </div>
                    </>
                }
            </Popup>

            {showAddStaffPopup && (
                <AddEmployeePopup open={showAddStaffPopup} close={setShowAddStaffPopup} />
            )
            }

            {
                <Popup
                    no
                    size="md"
                    heading={selectedLanguage?.name == "English" ? "Select Services" : translation[89]?.value ? translation[89]?.value : "Select Services"}
                    open={SelectServices}
                    close={() => setSelectServices(false)}
                >
                    <div className="flex flex-col gap-4 max-h-[45vh] lg:max-h-[47vh] 2xl:max-h-[45vh]">
                        <LoginInput
                            title=""
                            placeholder={selectedLanguage?.name == "English" ? "Search Service" : translation[1266]?.value ? translation[1266]?.value : "Search Service"}
                            padding="py-1"
                            leftIcon={<Svgs.Search />}
                            value={search}
                            onChange={searchfunction}
                        />
                        {select_service_serviceGroups?.length > 0 ? (
                            <>
                                {select_service_serviceGroups?.map((srv) => {
                                    return (
                                        <div className="flex flex-col gap-2">
                                            <h1 className="font-semibold text-primary">
                                                {srv?.name ? srv?.name : ""}
                                            </h1>
                                            {srv?.services?.length > 0 && srv?.services?.map((itm) => {
                                                const filteredServices = itm?.priceservice?.filter(itm => itm?.currency_name?.toLowerCase() == selectedLocationCurrency?.toLowerCase())
                                                return (
                                                    <>
                                                        {filteredServices?.map((itm_srv) => {
                                                            return (
                                                                <div
                                                                    className="flex items-center justify-between gap-3 border-b py-2 px-1 hover:bg-gray-50 rounded-md cursor-pointer"
                                                                    onClick={() => {
                                                                        handleServices(itm?.id, {
                                                                            srv_name: itm?.name,
                                                                            price: itm_srv?.discount_price || itm_srv?.discount_price == 0 ? itm_srv?.discount_price : itm_srv.price,
                                                                            srv_duration: itm_srv?.duration
                                                                                .toLowerCase()
                                                                                .replaceAll("_", " "),
                                                                        });
                                                                        let sel_data = {
                                                                            ...inp_data,
                                                                            service: itm?.id,
                                                                            client_can_book: itm?.client_can_book,
                                                                            slot_availible_for_online: itm?.slot_availible_for_online,
                                                                            duration: itm_srv?.duration.toLowerCase().replaceAll("_", " "),
                                                                            srv_name: itm?.name,
                                                                            price: itm_srv.price,
                                                                            srv_duration: itm_srv?.duration
                                                                                .toLowerCase()
                                                                                .replaceAll("_", " "),
                                                                        };
                                                                        setInpData(sel_data);
                                                                        if (itm?.id && inp_data.date_time) {
                                                                            addNewData(sel_data);
                                                                        }
                                                                        setSelectServices(!SelectServices);
                                                                    }}
                                                                >
                                                                    <div className="">
                                                                        <h1 className="text-primary">
                                                                            {itm?.name}
                                                                        </h1>
                                                                        <p className="text-xs text-[#949494]">
                                                                            {itm_srv?.duration.replaceAll("_", " ")}
                                                                        </p>
                                                                    </div>
                                                                    <div className="d-flex flex-col text-end">
                                                                        <p className="text-primary text-sm font-semibold">
                                                                            {selectedLocationCurrency ? selectedLocationCurrency : ""}{" "}
                                                                            {itm_srv?.price?.toFixed(2)}
                                                                        </p>
                                                                        {(itm_srv?.discount_price || itm_srv?.discount_price == 0) &&
                                                                            <p className="text-xs text-[#949494]">
                                                                                {selectedLanguage?.name == "English" ? "Discount Price" : translation[245]?.value ? translation[245]?.value : "Discount Price"} :
                                                                                {selectedLocationCurrency ? selectedLocationCurrency : ""}{" "}
                                                                                {itm_srv?.discount_price?.toFixed(2)}
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <>
                                {/* <div className="flex flex-col gap-4 items-center text-center">
                                <p className="text-sm text-[#000]/40">
                                    {selectedLanguage?.name == "English" ? "You have not added any services yet." : translation[246]?.value ? translation[246]?.value : "You have not added any services yet."}
                                    <br className="hidden md:block" />
                                    {selectedLanguage?.name == "English" ? "Click on the button below to add new services." : translation[247]?.value ? translation[247]?.value : "Click on the button below to add new services."}

                                </p>
                                <div>
                                    <AddService
                                        formRef={formRef}
                                        setServiceGroup={setServiceGroup}
                                        setSelectServices={setSelectServices}
                                        ServiceType={ServiceType}
                                        setServiceType={setServiceType}
                                        serviceData={serviceData}
                                        isEdit={isEdit}
                                        setService={setService}
                                        serviceHandleChange={serviceHandleChange}
                                        onSubmitService={onSubmitService}
                                        active={active}
                                        onBlurHandler={onBlurHandler}
                                        errors={errors}
                                        removedSelectedEmployee={removedSelectedEmployee}
                                        serviceModal={serviceModal}
                                        setAddService={setAddService}
                                        durationOptions={durationOptions}
                                        setIsEdit={setIsEdit}
                                        setErrors={setErrors}
                                        setIsDelete={setIsDelete}
                                        loading={loading}

                                    />
                                </div>
                            </div> */}
                                <NoServiceFound />

                            </>
                        )}
                    </div>
                </Popup>
            }

            {
                ServiceGroup && (
                    <AddServiceGroup
                        onClose={() => {
                            setServiceGroup(false);
                        }}
                        open={ServiceGroup}
                        update={false}
                        addSuccess={() => {
                            dispatch(getServiceGroupCalendar(selected_location, setGroupLoader));
                        }}
                    />
                )
            }

            {showAddClientPopup &&
                <AddClientPopup open={showAddClientPopup} close={setShowAddClientPopup} getClients={getClients} />}
        </>
    )
}

export default AddMissedOpportunity