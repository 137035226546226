import React from "react";
import useFinanceAnalytics from "./helper";
import Svgs from "Assets/svgs";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import SalesAnalyticsCards from "Components/TenantApp/Elements/SalesAnalyticsCards";
import LineChatCurve from "Components/TenantApp/Elements/Charts/LineChatCurve";
import BarChart from "Components/TenantApp/Elements/Charts/BarChart";
import MultiDataDonet from "Components/TenantApp/Elements/Charts/MultiDataDonet";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import LocationSelection from "new-components/location";

const FinanceAnalytics = () => {
    const {
        translation, selectedLanguage, navigate, year, setYear, loader, financeData, selectedLocationCurrency
    } = useFinanceAnalytics();

    return (
        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "POS Analytics" : translation[7]?.value ? translation[7]?.value : "POS Analytics"}
                second={selectedLanguage?.name == "English" ? "Finance Analytics" : translation[1595]?.value ? translation[1595]?.value : "Finance Analytics"}
                url={'/dashboard/pos-analytics'}
            />
            <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
                <div className="flex items-center sm:gap-4 gap-2">
                    <span onClick={() => navigate(-1)}>
                        <Svgs.Back />
                    </span>
                    <h2 className="text-primary font-bold lg:text-3xl text-xl">
                        {selectedLanguage?.name == "English" ? "Finance Analytics" : translation[1595]?.value ? translation[1595]?.value : "Finance Analytics"}
                    </h2>

                    <LocationSelection />
                </div>
                <div className="flex items-center sm:gap-4 gap-2">
                    <Dropwdown
                        className={'w-[7rem]'}
                        name="year"
                        title=""
                        showTitle={false}
                        placeholder={"2024"}
                        value={year}
                        onChange={(e) => { setYear(e.target.value) }}
                        options={[
                            { value: '2022', label: '2022' },
                            { value: '2023', label: '2023' },
                            { value: '2024', label: '2024' },
                        ]}
                    />
                </div>
            </div>

            <div className="border-t py-5 md:py-10 flex flex-col gap-5 md:gap-10">

                <div className="grid sm:grid-cols-2 gap-5 md:gap-10">
                    <div className="grid sm:grid-cols-2 jus gap-5 xl:gap-10 h-auto py-5 sm:px-6 !pt-5">
                        <SalesAnalyticsCards
                            currency={selectedLocationCurrency}
                            value={financeData?.total_sales}
                            text={selectedLanguage?.name == "English" ? "Sales" : translation[3]?.value ? translation[3]?.value : "Sales"}
                        />
                        <SalesAnalyticsCards
                            value={financeData?.total_expenses}
                            currency={selectedLocationCurrency}
                            text={selectedLanguage?.name == "English" ? "Expenses" : translation[1603]?.value ? translation[1603]?.value : "Expenses"}
                        />
                    </div>
                    <div className="flex flex-col gap-5 xl:gap-10 justify-between h-auto py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex items-center">
                            <h4 className="font-semibold text-xl text-[#101928]">Sales & Expense Analytics</h4>
                        </div>
                        <div className="h-auto w-full overflow-hidden">
                            <BarChart chartData1={financeData?.saleExpenseChart?.salesMonthChartData} chartData2={financeData?.saleExpenseChart?.expenseMonthChartData} chartData1Color={'#5679FF'} chartData2Color={'#BBC9FF'} />
                        </div>
                    </div>
                </div>
                <div className="grid sm:grid-cols-2 gap-5 md:gap-10">
                    <div className="flex flex-col gap-10 h-auto 3xl:h-[25rem] py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex xl:flex-nowrap flex-wrap gap-2 xl:gap-5 items-center">
                            <div className="flex flex-col gap-1">
                                {/* <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Revenue" : translation[26]?.value ? translation[26]?.value : "Revenue"}</h4> */}
                            </div>
                            {/* <hr className="h-full w-[1px] bg-[#1C1C1C33]" /> */}
                            <div className="flex items-center gap-2 text-black">
                                <div className="h-2 w-2 rounded-full bg-[#5679FF]"></div>
                                <p className="text-sm flex gap-2 items-center"><span>Current Year</span><span className="font-semibold">{financeData?.totalSalesYearWise?.current_year_sales} {selectedLocationCurrency}</span></p>
                            </div>
                            <div className="flex items-center gap-2 text-black">
                                <div className="h-2 w-2 rounded-full bg-[#A8C5DA]"></div>
                                <p className="text-sm flex gap-2 items-center"><span>Previous Year</span><span className="font-semibold">{financeData?.totalSalesYearWise?.previous_year_sales} {selectedLocationCurrency}</span></p>
                            </div>
                        </div>
                        <div className="h-auto w-full overflow-hidden">
                            <LineChatCurve fill={false} chartData1={financeData?.saleCurrentPreviousYearChart?.salesMonthChartData} chartData2={financeData?.saleCurrentPreviousYearChart?.salesPreviousYearMonthChartData} chartData1Color={'#5679FF'} chartData2Color={'#556E94'} />
                        </div>
                    </div>
                    <div className="min-h-[20rem] py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5 flex flex-col justify-between gap-2">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-1">
                                <h4 className="font-semibold text-xl text-[#101928]">
                                    Payment Method Analytics
                                    {/* {selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments"} */}
                                </h4>
                            </div>
                        </div>
                        <div className="h-full w-full flex flex-col sm:flex-row items-center sm:justify-between gap-5 2xl:gap-10">

                            <div className="flex items-center w-[150px] h-[150px] xl:h-[200px] xl:w-[200px] overflow-hidden">
                                <MultiDataDonet data={financeData?.paymentMethodAnalytics} />
                            </div>

                            <div className="w-full md:w-auto flex flex-col gap-5">
                                {financeData?.paymentMethodAnalytics?.map((itm, index) => {
                                    return (
                                        <div className="flex items-center justify-between gap-5 whitespace-nowrap 2xl:gap-10 text-[#1C1C1C]" key={index}>
                                            <div className="flex gap-2 items-center"><div className="h-2 w-2 rounded-full bg-[#1C1C1C]"></div><p>{itm?.name}</p></div>
                                            <div>{itm?.value} %</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="grid sm:grid-cols-12 gap-5 md:gap-10">
                <div className="order-first sm:order-none sm:col-span-4 flex flex-col gap-5 xl:gap-10 h-auto py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                    <div className="flex flex-col gap-1">
                        <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Revenue by Location" : translation[1597]?.value ? translation[1597]?.value : "Revenue by Location"}</h4>
                    </div>
                    <div className="h-[150px] w-full">
                        <MapChart />
                    </div>
                    <div className="flex flex-col gap-3 md:gap-5">
                        <ProgressBar progressWidth={"75"} heading={"Silk Saloon"} value={"72K"} />
                        <ProgressBar progressWidth={"45"} heading={"New Saloon"} value={"72K"} />
                        <ProgressBar progressWidth={"65"} heading={"US Saloon"} value={"72K"} />
                        <ProgressBar progressWidth={"35"} heading={"Style Saloon"} value={"72K"} />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default FinanceAnalytics