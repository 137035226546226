/* eslint-disable array-callback-return */
// eslint-disable-next-line array-callback-return
import { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import useObjectKeys from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { getLocations } from "Adapters/Api";
import { getVendorsDropdownList } from "Adapters/Api/vendor";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import { getProductsForOrderStock, getsProducts, getsProductsDropdownListPagination } from "Adapters/Api/Products";
import {
  createOrderStock,
  getPurchaseOrder,
  // getPurchaseOrderWithNoPagination,
  updateOrderStock,
} from "Adapters/Api/purchasesOrder";
import {
  addOrderStock,
  getOrderStock,
  getPurchasesPages,
  update_orderStock,
} from "Redux/TenantRedux/Actions/purchaseOrderActions";
import { apiErrorMessage } from "Constants/Data/Errors";

let timeOutId = undefined
function useOrderStock() {
  const formRef = useRef()
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const { objectKeyConvertToArray } = useObjectKeys();
  const state = useSelector((state) => state);
  const { selected_location } = state?.locations;
  const [step, setStep] = useState(1);
  const [orderStock, setOrderStock] = useState({
    vendor: "",
    fromLocation: "",
    toLocation: selected_location ? selected_location : "",
    selected_product_list: [],
  });
  const access_token = getDataFromLocalStorage("access_token");
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [purchasesOrderList, setPurchasesOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)
  const [page_loading, setPage_loading] = useState(false)
  const [pages, setPages] = useState(1)
  const [searchText, setSearchText] = useState("");
  const [productLengthError, setProductLengthError] = useState("")
  const [lastStepError, setLastStepError] = useState()
  const [loader, setLoader] = useState(false)
  const [getProduct, setGetProduct] = useState(false)

  const { selected_product_list } = orderStock;
  const { setErrors, validation, errors, onBlurHandler } = useValidations();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const businessLocations = state?.business?.locations;
  const businessId = state?.business?.business?.id;
  const vendorsRedux = state?.vendors?.vendors;
  // const productListRedux = state?.product?.products;
  const productListRedux = state?.product?.order_stock_product;
  const productListRedux_updated = state?.product?.order_stock_product_updated
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const params = useParams();
  console.log(productListRedux, "productListRedux")

  const getPurchaseOrderList = async (selected_location, currentPage, searchText) => {
    const result = await getPurchaseOrder(selected_location, currentPage, searchText, setLoader, access_token);
    setPurchasesOrderList(result?.data?.response?.stocks);
    dispatch(getOrderStock(result?.data?.response?.stocks));
    dispatch(getPurchasesPages(result?.data))
  };

  // const fetchProductslist = async (toLocation) => {
  //   setPage_loading(true)
  //   const response = await getsProductsDropdownListPagination("", toLocation, "", setPage_loading);
  //   // setPages(response?.data?.response?.page_count)
  //   if (response.status === 200) {
  //     let productList = response?.data?.response?.products;
  //     // setPage_loading(false)
  //     dispatch(getProducts(productList));
  //   }
  // };

  useEffect(() => {
    if (searchText === null) {
      // Initial load with empty searchText
      dispatch(getPurchaseOrderList(selected_location, currentPage, ""))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(getPurchaseOrderList(selected_location, currentPage, searchText))
      }, 500);
    }
  }, [currentPage, searchText, selected_location])

  // useEffect(() => {
  //   dispatch(getPurchaseOrderWithNoPagination(selected_location))
  // }, [selected_location]);

  //Create Product list and Passing Product List data in product dropdown
  // useMemo(() => {
  //   // if (productListRedux?.length === 0) {
  //   fetchProductslist();
  //   // }
  // }, []);

  // useEffect(() => {
  //   if (orderStock?.toLocation) {
  //     console.log(orderStock?.toLocation, "orderStock?.toLocation")
  //     fetchProductslist(orderStock?.toLocation)
  //   }
  // }, [orderStock?.toLocation])

  useEffect(() => {
    if (selected_location && orderStock?.toLocation === "") {
      setOrderStock((prev) => ({
        ...prev,
        toLocation: selected_location
      }))
    }
  }, [selected_location, orderStock?.toLocation])

  useEffect(() => {
    if (getProduct) {
      if (orderStock?.toLocation) {
        dispatch(getProductsForOrderStock(orderStock?.toLocation, setPage_loading))
      }
    }
  }, [orderStock?.toLocation, getProduct])


  useEffect(() => {
    if (params.order_stock_id === undefined) {
      setIsEdit(false);
    } else {
      // fetchProductslist();
      setIsEdit(true);
    }
  }, [params.order_stock_id]);

  const productList = useMemo(() => {
    const newList = [];
    productListRedux?.map((obj) => {
      obj?.location_quantities?.map((lq) => {
        newList?.push({ productId: obj.id, id: obj.id, name: obj.name, ...lq, product: obj });
      })
    })
    // filter products on the basis of to location
    // const filterLocationProduct = newList?.filter((obj) => obj?.location?.id === orderStock?.toLocation)
    // return filterLocationProduct;
    return newList
  }, [orderStock?.toLocation, isEdit, selected_location, productListRedux])

  // Edit set data for update
  useEffect(() => {
    let editOrderStock = purchasesOrderList?.find((obj) => obj.id === params.order_stock_id);
    if (editOrderStock) {
      let { vendor, to_location, from_location, products, id } = editOrderStock;
      var selectedProducts = [];
      products?.map((i) => {
        productList?.map((j) => {
          if (i?.product?.id == j?.productId) {
            selectedProducts.push({ ...j, isDeleted: false, quantity: i.quantity, order_id: i.id })
          }
        })
      })
      setOrderStock({
        id: id,
        vendor: vendor,
        toLocation: to_location,
        selected_product_list: selectedProducts,
      });
    }

  }, [isEdit, purchasesOrderList?.length, productListRedux?.length, productList?.length]);

  useEffect(() => {
    dispatch(getVendorsDropdownList());
  }, []);

  const vendorsDropdownList = useMemo(() => {
    var vendors = [];
    vendorsRedux?.map((item, index) => {
      vendors.push({ label: item.vendor_name, value: item.id });
    });
    return vendors;
  }, [vendorsRedux?.length == 0]);

  // Dispatching Locations into Reducer on base of businessId
  useEffect(() => {
    if (businessLocations?.length === 0) {
      dispatch(getLocations(state.business.business.id));
    }
  }, [businessId]);

  // Create Location list and Passing Locations List data in Location dropdown
  const locationsDropdownList = useMemo(() => {
    var locations = [];
    businessLocations?.map((item, index) => {
      locations.push({ label: item.address_name, value: item.id });
    });
    return locations;
  }, [businessLocations?.length == 0]);

  const removeSelectedProduct = (id) => {
    const filterProducts = selected_product_list.map((product) => {
      if (product.id === id) {
        return { ...product, isDeleted: true };
      } else {
        return { ...product };
      }
    });
    var findOrder = filterProducts.find((obj) => obj.id === id);
    if (findOrder.isDeleted && findOrder.hasOwnProperty("order_id") === false) { // it will remove those selected order that doesnot contain order id
      let filterProduct = filterProducts.filter((obj) => obj.id !== id);        // remove order at state level
      setOrderStock((prev) => ({
        ...prev,
        selected_product_list: filterProduct,
      }));
    } else {
      setOrderStock((prev) => ({
        ...prev,
        selected_product_list: filterProducts,
      }));
    }
  };

  const addSelectedProduct = (value, name) => {
    var completeProductData = productList?.map((product) => {
      if (product.id === value) {
        return {
          ...product,
          quantity: product?.quantity ? product.quantity : "",
          isDeleted: product?.isDeleted,
        };
      }
    });
    var filterCompleteProduct = completeProductData.find(
      (obj) => obj?.id === value
    );
    if (selected_product_list?.find((item) => item.id === value && item.isDeleted === true)) {
      setOrderStock((prev) => ({
        ...prev,
        selected_product_list: selected_product_list?.map((product) => {
          if (product.id === value && product.isDeleted === true) {
            return { ...product, isDeleted: false };
          } else {
            return { ...product };
          }
        }),
      }));
    } else {
      setOrderStock((prev) => ({
        ...prev,
        [name]: [
          ...selected_product_list,
          { ...filterCompleteProduct, isDeleted: false },
        ],
      }));
    }
  };
  // handle onChange Values
  const orderStockHandleChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "quantity") {
      setOrderStock((prev) => ({
        ...prev,
        selected_product_list: selected_product_list?.map((item, i) => {
          if (index === i
            // && value <= item?.reorder_quantity
          ) {
            return { ...item, [e.target.name]: e.target.value };
          } else {
            return item;
          }
        }),
      }));
    }
    if (name === "selected_product_list") {
      if (
        selected_product_list?.find((item) => item.id === value && item.isDeleted === false)
      ) {
        removeSelectedProduct(value);
      } else {
        addSelectedProduct(value, name);
      }
      setProductLengthError("")
    } else {
      setOrderStock((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
      setProductLengthError("")
    }
  };
  const handleStep = (e, step) => {
    const { vendor,
      // fromLocation,
      toLocation } = orderStock;
    if (step === 1) {
      let step1Data = {
        vendor,
        //  fromLocation,
        toLocation
      };
      setErrors(validation(step1Data));
      let errorResponse = validation(step1Data);
      if (objectKeyConvertToArray(errorResponse)?.length === 0) {
        setStep(step + 1);
      }
    }
    if (step === 2) {
      let step2Data = { selected_product_list };
      if (step2Data.selected_product_list.length !== 0) {
        setStep(step + 1);
      } else {
        setProductLengthError("Select at least one product to create order stock.")
      }
    }
  };
  // handle edit request
  const orderEditHandler = async () => {
    setLoading(true);
    const { vendor, fromLocation, toLocation, id, selected_product_list } =
      orderStock;
    let payloadProducts = selected_product_list.map((j) => {
      if (j.order_id === undefined && j.isDeleted === true) {
        // it will not add any order that is deleted and doesnot contain order id
        // that means that order is generated and just delete at state level
      } else {
        return {
          id: j?.order_id,
          quantity: j?.quantity,
          product_id: j.productId,
          isDeleted: j.isDeleted ? "True" : "False",
          status: "Placed",
        };
      }
    });

    const body = {
      order_id: id,
      vendor: vendor,
      from_location: fromLocation,
      to_location: toLocation,
      business: businessId,
      products: payloadProducts,
      rec_quantity: '',
      status: "Placed",
    };
    const response = await updateOrderStock(body, access_token);
    if (response.status === 200) {
      setLoading(false);
      let updatedResponse = response?.data?.response?.stock;
      let successMessage = response?.data?.response?.message;
      dispatch(update_orderStock(updatedResponse));
      setLoading(false);
      toast.success(successMessage, { toastId: "toast" });
      setOrderStock({
        order_id: "",
        vendor: "",
        fromLocation: "",
        toLocation: "",
        selected_product_list: [],
      });
      navigate("/dashboard/account/inventory-operations/purchases-orders");
    } else {
      setLoading(false);
      toast.error(apiErrorMessage, { toastId: "toast" });
    }
  };

  // handle create request
  const handleOrder = async () => {
    let submit = false
    let all_errors = [];
    (selected_product_list)?.forEach((item, i) => {
      let err_obj = {}
      if (item?.quantity && selected_product_list?.every(
        (itm) =>
          itm?.quantity !== 0
      )) {
        submit = true
      } else {
        err_obj.quantity = item.quantity !== '' ? !(item.quantity) : ''
        err_obj.id = item.product?.id
        all_errors.push(err_obj)
        formRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    });
    setLastStepError(all_errors)
    if (submit) {
      onSubmitOfAddEditOrderStock()
    }
  };

  const onSubmitOfAddEditOrderStock = async () => {
    if (isEdit) {
      orderEditHandler();
    } else {
      const { vendor, fromLocation, toLocation, selected_product_list } = orderStock;
      setLoading(true);
      let payloadProducts = [];
      selected_product_list?.map((product) => {
        if (product?.id && product?.quantity) {
          payloadProducts.push({
            quantity: product.quantity,
            id: product.productId,
          });
        }
      });
      const body = {
        vendor: vendor,
        // from_location: fromLocation,
        to_location: toLocation,
        business: businessId,
        products: payloadProducts,
        rec_quantity: '',
        status: "Placed",
      };
      const response = await createOrderStock(body, access_token);
      if (response.status === 201) {
        let orderStock = response?.data?.response?.orderstock;
        let successMessage = response?.data?.response?.message;
        dispatch(addOrderStock(orderStock));
        setLoading(false);
        toast.success(successMessage, { toastId: "toast" });
        setOrderStock({
          vendor: "",
          fromLocation: "",
          toLocation: "",
        });
        navigate("/dashboard/account/inventory-operations/purchases-orders");
      } else {
        setLoading(false);
        toast.error("Error in create order stock", { toastId: "toast" });
      }
    }
  }

  return {
    step, vendorsDropdownList, locationsDropdownList,
    productListRedux, productList, orderStock, errors,
    searchText, openDeletePopup, deleteId, loading,
    state, deleting, isEdit, params, selected_location, productListRedux_updated,
    setStep, navigate, setOrderStock,
    orderStockHandleChange, onBlurHandler, setSearchText,
    removeSelectedProduct, setOpenDeletePopup, setDeleteId,
    setLoading, handleOrder, handleStep, setDeleting,
    page_loading, productLengthError, setProductLengthError, lastStepError,
    pages, formRef, setGetProduct,
    currentPage, setCurrentPage,
    translation,
    selectedLanguage,
  };
}
export default useOrderStock;
