import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";


import { CSVLink } from "react-csv";
import AddStocksTransfer from "./addTransferStocks";
import DeleteConfirmation from "new-components/delete-popup";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import NotFound from "Components/TenantApp/Dashboard/Sales/Elements/NotFound";
import { toast } from 'react-toastify'

import { useStockTransfer } from "./helper";

import Svgs, { Plus } from "Assets/svgs";
import LocationSelection from "new-components/location";
import Pagination from "new-components/Pagination";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoPurchaseOrderDataFound from "Components/Utility/NoPurchaseOrderDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";

const StocksTransfer = () => {
  const { handleGetStockTransferList, setOpenDeletePopup, handleDeleteStockId, handleUpdateStock, setOpenStocks, setSearchText,
    handleDelete, isDeleteLoading, setisUpdate, navigate, isUpdate, openStocks, updateData, search_text, openDeletePopup,
    stockTransferList, setStockTransferList, currentPage, setCurrentPage, stockTransferListWithNoPagination, pages, allCount,
    PageSize, loader, handleGetStockTransferListWithNoPagination, setNoPaginationLoader, noPaginationLoader
  } = useStockTransfer();
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [exportLoader, setExportLoader] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])


  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  let headers = [
    { label: selectedLanguage?.name == "English" ? "Product Name" : translation[296]?.value ? translation[296]?.value : "Product Name", key: 'name' },
    { label: selectedLanguage?.name == "English" ? "Product ID" : translation[1076]?.value ? translation[1076]?.value : "Product ID", key: 'id' },
    { label: selectedLanguage?.name == "English" ? "From Location" : translation[1015]?.value ? translation[1015]?.value : "From Location", key: 'from_location' },
    { label: selectedLanguage?.name == "English" ? "To Location" : translation[1077]?.value ? translation[1077]?.value : "To Location", key: 'to_location' },
    { label: selectedLanguage?.name == "English" ? "Quantity" : translation[365]?.value ? translation[365]?.value : "Quantity", key: 'quantity' },
  ]

  const csvReport = {
    filename: "Stock Transfer Record.csv",
    headers: headers,
    data: stockTransferListWithNoPagination.map(itm => {
      return {
        ...itm,
        name: itm?.product?.name ? itm?.product?.name : '--------',
        id: itm?.product?.id,
        from_location: itm?.from_location?.address_name,
        to_location: itm?.to_location?.address_name,
        quantity: itm?.quantity,
      }
    })
  }

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (stockTransferListWithNoPagination?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
      <p className="text-[#7B7B7B] text-sm">
        <span
          className="cursor-pointer"
          onClick={() => {
            navigate("/dashboard/account/");
          }}
        >
          {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
        </span>{" "}
        &gt;{" "}
        <span
          className="cursor-pointer"
          onClick={() => {
            navigate("/dashboard/account/inventory-operations");
          }}
        >
          {selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations"}
        </span>{" "}
        &gt; <span className="text-primary font-semibold">
          {selectedLanguage?.name == "English" ? "Transfer Stock" : translation[1073]?.value ? translation[1073]?.value : "Transfer Stock"}
        </span>
      </p>
      <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
        <div className="flex items-center sm:gap-4 gap-2">

          <h2 className="text-primary font-bold lg:text-3xl text-xl">

            {selectedLanguage?.name == "English" ? "Transfer Stock" : translation[1073]?.value ? translation[1073]?.value : "Transfer Stock"}
          </h2>
          {/* <LocationSelection /> */}
        </div>
        <div className="flex items-center sm:gap-4 gap-2">
          {loadingPermission ?
            <Shimmer className={'w-36 lg:w-40'}>
              <div className="h-8 w-full  bg-gray-200 rounded-lg" />
            </Shimmer>
            : !employee || (employee && employeePermissions?.inventory_stock_transfer?.includes("create")) ? (
              <LoginBtn
                text={<><div className="pr-2"><Plus color={"#fff"} /></div>{selectedLanguage?.name == "English" ? "Add Transfer Stock" : translation[1075]?.value ? translation[1075]?.value : "Add Transfer Stock"}</>}
                onClick={() => setOpenStocks(!openStocks)}
              />
            ) : (
              ""
            )}
        </div>
      </div>
      <div className="border rounded-lg">
        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row  gap-3">
          <div className="flex xs:items-center justify-between xs:flex-row flex-col flex-1 w-full md:gap-0 gap-4 ">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Stock Transferred Details" : translation[1074]?.value ? translation[1074]?.value : "Stock Transferred Details"}
              </h2>
              {loader ?
                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                  <p className="rounded-md h-5 w-12 mx-1"> </p>
                </div>
                :
                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                  <p className="text-primary">
                    {allCount} {" "}
                    {allCount && allCount > 1 ?
                      selectedLanguage?.name == "English" ? "Stock Items" : translation[1008]?.value ? translation[1008]?.value : "Stock Items"
                      : selectedLanguage?.name == "English" ? "Stock Item" : translation[1318]?.value ? translation[1318]?.value : "Stock Item"
                    }
                  </p>
                </div>
              }
            </div>
            <div className="mb-00 flex items-center gap-4">
            <div className="flex-1">
              {loadingPermission ?
                <Shimmer className={'w-44 lg:w-48'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                :
                <LoginInput
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Search product" : translation[1265]?.value ? translation[1265]?.value : "Search product"}
                  padding="py-1"
                  value={search_text}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  leftIcon={<Svgs.Search />}
                />
              }
            </div>

              <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
                {/* <LoginBtn
              padding="p-3"
              className="rounded-lg border border-primary text-sm"
              bg="bg-primary"
              
            >
              <div className="flex items-center gap-2">
                <Svgs.Filters fill="#fff" />
              </div>
            </LoginBtn> */}
                {loadingPermission ?
                  <Shimmer className={'w-12 lg:w-16'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                  </Shimmer>
                  : exportLoader ? (
                    <div className="h-[4rem] flex items-center justify-center">
                      <PreloaderSm />
                    </div>
                  ) : !employee ? (
                    stockTransferList?.length > 0 ?
                      <CSVLink {...csvReport} target="_blank" onClick={handleExportClick}>
                        <IconButton filled>
                          <Svgs.Export />
                        </IconButton>
                      </CSVLink> :
                      <LoginBtn
                        className="rounded-lg border-2 border-primary"
                        bg="bg-white"
                        animation={false}
                        onClick={handleExportClick}
                      >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                          <Svgs.Export bg="#FFFFFF" />
                        </div>
                      </LoginBtn>
                  ) : (
                    ""
                  )}
              </div>
            </div>
          </div>
        </div>

        {loader ?
          <TableShimer cols={6} rows={12} className={"inline-grid xl:grid grid-cols-[repeat(6,minmax(185px,1fr))] text-sm font-semibold"} />
          :
          <div className="overflow-x-auto">
            <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
              <p>{selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}</p>
              {/* <p>{selectedLanguage?.name == "English" ? "Product ID" : translation[1076]?.value ? translation[1076]?.value : "Product ID"}</p> */}
              <p>{selectedLanguage?.name == "English" ? "From Location" : translation[1015]?.value ? translation[1015]?.value : "From Location"}</p>
              <p>{selectedLanguage?.name == "English" ? "To Location" : translation[1077]?.value ? translation[1077]?.value : "To Location"}</p>
              <p>{selectedLanguage?.name == "English" ? "Quantity" : translation[365]?.value ? translation[365]?.value : "Quantity"}</p>
              <p>{selectedLanguage?.name == "English" ? "Date" : translation[551]?.value ? translation[551]?.value : "Date"}</p>
              <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
            </div>
            {stockTransferList && stockTransferList?.length > 0 ?
              stockTransferList?.map(
                (
                  { id, product, from_location, to_location, quantity, created_at },
                  index
                ) => {
                  return (
                    <div
                      className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))]"
                      key={index}
                    >
                      <div className="flex items-center">
                        <p className="text-sm">{product?.name}</p>
                      </div>
                      {/* <div className="flex items-center">
                          <p className="text-sm">{product?.id}</p>
                        </div> */}
                      <div className="flex items-center">
                        <p className="text-sm">
                          {from_location?.address_name}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">{to_location?.address_name}</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">{quantity}</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">{created_at}</p>
                      </div>
                      <div className="flex items-center gap-2">
                        {/* {!employee ||
                            (employee &&
                              employeePermissions?.inventory_stock_transfer?.includes(
                                "edit"
                              )) ? (
                            <div
                              className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center hover:cursor-pointer"
                              onClick={() => handleUpdateStock(id)}
                            >
                              <Svgs.Pen />
                            </div>
                          ) : (
                            ""
                          )} */}
                        {!employee ? (
                          <div
                            className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center hover:cursor-pointer"
                            onClick={() => {
                              if (id) {
                                handleDeleteStockId(id)
                              }
                            }}
                          >
                            <Svgs.Delete fill="#7D7D7D" />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                }
              ) : (
                <NoPurchaseOrderDataFound />
              )}

            {(stockTransferList?.length > 0 && !loader) &&
              <Pagination
                currentPage={currentPage}
                totalCount={Math.ceil(pages)}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
              />
            }
          </div>
        }
      </div>

      {openStocks && (
        <AddStocksTransfer
          stockTransferList={stockTransferList}
          setStockTransferList={setStockTransferList}
          handleClose={() => {
            setOpenStocks(false);
            setisUpdate(false);
          }}
          updateStockData={handleGetStockTransferList}
          updateData={updateData}
          isUpdate={isUpdate}
          setCurrentPage={setCurrentPage}
          search_text={search_text}
          handleGetStockTransferListWithNoPagination={handleGetStockTransferListWithNoPagination}
          setNoPaginationLoader={setNoPaginationLoader}
        />
      )}

      {openDeletePopup && (
        <DeleteConfirmation
          isLoading={isDeleteLoading}
          handleClose={() => setOpenDeletePopup(false)}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default StocksTransfer;
