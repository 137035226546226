import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Svgs from '../../../../../../Assets/svgs'
import CircleIcon from '../../../../Elements/CircleIcon'
import Dropwdown from '../../../../Elements/Dropwdown'
import LoginBtn from '../../../../Elements/LoginBtn'
import useBoookingSettings from './useBoookingSettings';
import CheckBox from './../../../../Elements/Checkbox/CheckBox';
import { getBookingsSettings } from './../../../../../../Adapters/Api/bookingSettings/index';
import useSetDataInLocalStorage from './../../../../../../Hooks/useSetDataInLocalStorage';


import { cancelOrRescheduleOptionList, clientCanBookOptionList, timeSlotIntervalOptionList, controllTimeSlotOptionList } from '../../../../../../Constants/Data/BookingSettingData';
import { useSelector } from 'react-redux';

const BookingSettings = () => {
    const state = useSelector((state) => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    const navigate = useNavigate();
    const [submiting, setSubmiting] = useState(false)
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const businessData = getDataFromLocalStorage("business_data");
    const { BookingSettings, isDisabled, setBookingSettings, onHandleChange, onDropDownHandleChange, onSaveBoookingSettingsChanges } = useBoookingSettings();
    const {
        cancel_or_reschedule,
        client_can_book,
        controls_time_slot,
        time_slots_interval,
        allow_client_to_select_team_member,
        send_to_client,
        send_to_specific_email_address,
        auto_confirmation,
        admin_confirmation,
        start_time,
        services,
        duration,
        choose_team_member,
        select_payment_type,
        initial_deposit,
    } = BookingSettings;

    //get booktingSettings from backend
    const getBookingsSettingsDetails = async (business_id) => {
        const response = await getBookingsSettings(business_id);

        if (response.status === 200) {
            const bookingSettings = response?.data?.response?.setting;
            setBookingSettings(bookingSettings)
        }
    }

    useEffect(() => {
        if (businessData.id) {
            getBookingsSettingsDetails(businessData.id)
        }
    }, [])

    const fieldLists = [
        { value: start_time, name: "start_time", label: "Start Time" },
        { value: services, name: "services", label: "Services" },
        { value: duration, name: "duration", label: "Duration" },
        { value: select_payment_type, name: "select_payment_type", label: "Select Payment Type" },
    ];

    return (
        <>
            <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right' >
                <p className='text-[#7B7B7B] text-sm'>
                    <span className='cursor-pointer' onClick={() => { navigate('/dashboard/account/') }}>
                        {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
                    </span> {' '} &gt; {' '}

                    <span className='cursor-pointer' onClick={() => { navigate('/dashboard/account/setup/') }}>
                        {selectedLanguage?.name == "English" ? "Account Setup" : translation[645]?.value ? translation[645]?.value : "Account Setup"}
                    </span> {' '} &gt; {' '}
                    <span className='text-primary font-semibold'>
                        {selectedLanguage?.name == "English" ? "Business Settings" : translation[654]?.value ? translation[654]?.value : "Business Settings"}
                    </span></p>
                <div className='my-[1rem] flex items-center justify-between gap-4 flex-wrap'>
                    <div className='flex items-center sm:gap-4 gap-2'>

                        <h2 className='text-primary font-bold lg:text-3xl text-xl'>
                            {selectedLanguage?.name == "English" ? "Business Settings" : translation[654]?.value ? translation[654]?.value : "Business Settings"}
                        </h2>
                    </div>
                </div>
                <div className='flex flex-col gap-5'>
                    {/* <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.Calendar_cancel />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>Online cancellation and rescheduling</h2>
                            <p className='text-[#7B7B7B] text-sm'>Set how far in advance clients can cancel or reschedule, after this timeframe clients must call  <br className='md:block hidden' /> to change their appointment.</p>
                        </div>
                    </div>
                    <div className='lg:w-[36%] w-full'>
                        <Dropwdown
                            name={"cancel_or_reschedule"}
                            value={cancel_or_reschedule}
                            onChange={onDropDownHandleChange}
                            title='Clients can cancel or reschedule online'
                            placeholder='Clients can cancel or reschedule online'
                            options={cancelOrRescheduleOptionList} />
                    </div>
                    <div className='bg-[#E4EAF0] rounded-lg px-5 py-4'>
                        <h1 className='font-semibold'>Terms & Conditions:</h1>
                        <ul className='text-[#696969] list-disc text-sm ml-4 my-3 flex flex-col gap-1'>
                            <li>An Intellectual Property clause will inform users that the contents.</li>
                            <li>A Termination clause will inform users that any accounts on your website and mobile app</li>
                            <li>A Governing Law clause will inform users which laws govern the agreement.</li>
                        </ul>
                    </div>
                    <hr className='bg-[#3F3F441A] my-[1.5rem]' />
                    <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.Calendar_Check />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>Online booking availability</h2>
                            <p className='text-[#7B7B7B] text-sm'>Set how far in advance clients can book online, and lead time for when clients can cancel or  <br className='hidden md:block' /> reschedule.</p>
                        </div>
                    </div>
                    <div className='grid lg:grid-cols-2 gap-3'>
                        <Dropwdown
                            name={"client_can_book"}
                            value={client_can_book}
                            onChange={onDropDownHandleChange}
                            title='Clients can book oppointments'
                            placeholder='Clients can book oppointments'
                            options={clientCanBookOptionList} />
                        <div>
                            <Dropwdown
                                name={"controls_time_slot"}
                                value={controls_time_slot}
                                onChange={onDropDownHandleChange}
                                title={''}
                                placeholder='Controll time slot'
                                options={controllTimeSlotOptionList}
                                required={false}
                            />
                            <div className='flex items-center gap-2 mt-[0.5rem]'>
                                <Svgs.I />
                                <p className='text-xs text-[#7B7B7B]'>Controls the time slots available for online booking</p>
                            </div>
                        </div>
                        <div>
                            <Dropwdown
                                name={"time_slots_interval"}
                                value={time_slots_interval}
                                onChange={onDropDownHandleChange}
                                title='Time slot interval'
                                placeholder='Time slot interval'
                                options={timeSlotIntervalOptionList} />
                            <div className='flex items-center gap-2 mt-[0.5rem]'>
                                <Svgs.I />
                                <p className='text-xs text-[#7B7B7B]'>Controls the time slots available for online booking</p>
                            </div>
                        </div>
                    </div>

                    <CheckBox
                        value={allow_client_to_select_team_member}
                        name={"allow_client_to_select_team_member"}
                        onChange={(e) => onHandleChange(e, "allow_client_to_select_team_member")}
                        checked={allow_client_to_select_team_member}
                        label={"Alow clients to select team members"}
                        className="accent-[#5679FF] h-[1.3rem] w-[1.3rem]" />

                    <hr className='bg-[#3F3F441A] my-[1.5rem]' />
                    <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.Activity_emails />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>Online booking activity emails</h2>
                            <p className='text-[#7B7B7B] text-sm'>Receive emails when clients use online booking to book, reschedule or cancel. These emails  <br className='hidden md:block' /> are sent in addition to regular team members notifications.</p>
                        </div>
                    </div>
                    <div className='mt-3 flex flex-col gap-3'>
                        <div className="flex items-center sm:gap-4 gap-2">
                            <CheckBox
                                value={send_to_client}
                                name={"send_to_client"}
                                onChange={(e) => onHandleChange(e, "send_to_client")}
                                checked={send_to_client}
                                label={"Send to Clients."}
                                className="accent-[#5679FF] h-[1.3rem] w-[1.3rem]"
                            />
                        </div>
                        <div className="flex items-center sm:gap-4 gap-2">
                            <CheckBox
                                value={send_to_specific_email_address}
                                name={"send_to_specific_email_address"}
                                onChange={(e) => onHandleChange(e, "send_to_specific_email_address")}
                                checked={send_to_specific_email_address}
                                label={"Send to specific email addreses"}
                                className="accent-[#5679FF] h-[1.3rem] w-[1.3rem]" />

                        </div>
                    </div>

                    <hr className='bg-[#3F3F441A] my-[1.5rem]' />

                    <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.Booking_Confirmation />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>Booking Confirmation</h2>
                            <p className='text-[#7B7B7B] text-sm'>Receive emails when clients use online booking to book, reschedule or cancel. These emails <br className='hidden lg:block' /> are sent in addition to regular team members notifications.</p>
                        </div>
                    </div>
                    <div className='mt-3 flex flex-col gap-3'>
                        <div className="flex items-center sm:gap-4 gap-2">
                            <CheckBox
                                value={auto_confirmation}
                                name={"auto_confirmation"}
                                onChange={(e) => onHandleChange(e, "auto_confirmation")}
                                checked={auto_confirmation}
                                label={"Auto Confirmation"}
                                className="accent-[#5679FF] h-[1.3rem] w-[1.3rem]" />

                        </div>
                        <div className="flex items-center sm:gap-4 gap-2">
                            <CheckBox
                                value={admin_confirmation}
                                name={"admin_confirmation"}
                                onChange={(e) => onHandleChange(e, "admin_confirmation")}
                                checked={admin_confirmation}
                                label={"Admin Confirmation"}
                                className="accent-[#5679FF] h-[1.3rem] w-[1.3rem]" />

                        </div>
                    </div>

                    <hr className='bg-[#3F3F441A] my-[1.5rem]' /> */}

                    <div className='flex items-center sm:gap-4 gap-2 lg:flex-row flex-col'>
                        <CircleIcon>
                            <Svgs.Form />
                        </CircleIcon>
                        <div className='flex flex-col gap-1 lg:text-left text-center'>
                            <h2 className='text-primary font-semibold text-xl'>Booking Form</h2>
                            <p className='text-[#7B7B7B] text-sm'>Manage fields to appear for clients while booking <br className='hidden lg:block' /> an appointment.</p>
                        </div>
                    </div>
                    <div className='mt-3 flex flex-col gap-3'>
                        {
                            fieldLists.map((field) => {
                                const { value, name, label } = field;
                                return (
                                    <div className="flex items-center sm:gap-4 gap-2">
                                        <CheckBox
                                            value={value}
                                            name={name}
                                            onChange={(e) => onHandleChange(e, name)}
                                            checked={value}
                                            label={label}
                                            className="accent-[#5679FF] h-[1.3rem] w-[1.3rem]" />

                                    </div>
                                )
                            })
                        }
                    </div>
                    {/* <div className="flex items-center sm:gap-4 gap-2">
                            <CheckBox
                                value={start_time}
                                name={"start_time"}
                                onChange={(e) => onHandleChange(e, "start_time")}
                                checked={start_time}
                                label={"Start Time"}
                                className="accent-[#5679FF] h-[1.3rem] w-[1.3rem]" />

                        </div>
                        <div className="flex items-center sm:gap-4 gap-2">
                            <CheckBox
                                value={services}
                                name={"services"}
                                onChange={(e) => onHandleChange(e, "services")}
                                checked={services}
                                label={"Services"}
                                className="accent-[#5679FF] h-[1.3rem] w-[1.3rem]" />

                        </div>
                        <div className="flex items-center sm:gap-4 gap-2">
                            <CheckBox
                                value={duration}
                                name={"duration"}
                                onChange={(e) => onHandleChange(e, "duration")}
                                checked={duration}
                                label={"Duration"}
                                className="accent-[#5679FF] h-[1.3rem] w-[1.3rem]" />


                        </div> */}
                    {/* <div className="flex items-center sm:gap-4 gap-2">
                            <CheckBox
                                value={choose_team_member}
                                name={"choose_team_member"}
                                onChange={(e) => onHandleChange(e, "choose_team_member")}
                                checked={choose_team_member}
                                label={"Choose Team Member"}
                                className="accent-[#5679FF] h-[1.3rem] w-[1.3rem]" />

                        </div> */}
                    {/* <div className="flex items-center sm:gap-4 gap-2">
                            <CheckBox
                                value={select_payment_type}
                                name={"select_payment_type"}
                                onChange={(e) => onHandleChange(e, "select_payment_type")}
                                checked={select_payment_type}
                                label={"Select Payment Type"}
                                className="accent-[#5679FF] h-[1.3rem] w-[1.3rem]" />

                        </div> */}
                    {/* <div className="flex items-center sm:gap-4 gap-2">
                            <CheckBox
                                value={initial_deposit}
                                name={"initial_deposit"}
                                onChange={(e) => onHandleChange(e, "initial_deposit")}
                                checked={initial_deposit}
                                label={"Initial Deposit"}
                                className="accent-[#5679FF] h-[1.3rem] w-[1.3rem]" />
                        </div> */}
                    {/* </div> */}

                    <div className='mt-[2rem]'>
                        <LoginBtn
                            disabled={isDisabled}
                            onClick={async () => {
                                setSubmiting(true)
                                await onSaveBoookingSettingsChanges()
                                setTimeout(() => {
                                    setSubmiting(false)
                                }, 500);
                            }}
                            loading={submiting}

                        >Save Changes</LoginBtn>
                    </div>

                </div>
            </div>
        </>
    )
}

export default BookingSettings