import React, { useState, useEffect, useRef, useMemo } from "react";
import Svgs from "Assets/svgs";
import ConatinerWithHeader from "Components/NstyleApp/Wrappers/ConatinerWithHeader";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Popup from "Components/TenantApp/Elements/Popup";
import TotalCount from "Components/TenantApp/Elements/TotalCount";
import { DateCard } from "Components/Utility/DateSlider";
import { MONTHS, MONTHS_DURATIONS, WEEKDAYS } from "Constants/Data/Months";
import moment from "moment";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import useClientAppointment from "./helper";
import NotFound from "Components/Utility/NotFound";
import { reschedulClientAppointments } from "Adapters/Api/businessLocationInNstyleApp";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import GoogleDirection from "Components/Utility/GoogleDirection";
import { apiErrorMessage } from "Constants/Data/Errors";
import Textarea from "Components/TenantApp/Elements/Textarea";

const MyAppointments = () => {

  const {
    businessLocation,
    ManageAppintment,
    setManageAppintment,
    selectedAppointment,
    setSelectedAppointment,
    // pastAppTimes,
    // past,
    // upcoming,
    // upcomingAppTimes,
    appointment_list,
    onCancelAppointments,
    isReshedule,
    setIsReshedule,
    hash,
    tax,
    pastGroup,
    upcomingGroup,
    selectedAppointmentGroup,
    setSelectedAppointmentGroup,
    AddNotes,
    setAddNotes,
    notes_inp,
    setNotesInp
  } = useClientAppointment();

  return (
    <ConatinerWithHeader>
      {isReshedule ? (
        <TimeSection
          selectedAppointmentGroup={selectedAppointmentGroup}
          selectedAppointment={selectedAppointment}
          location={businessLocation}
          app_list={appointment_list}
          hash={hash}
          setIsReshedule={setIsReshedule}
        />
      ) : (
        <div className="md:grid flex flex-col grid-cols-5 gap-6 border rounded-md px-4 py-6">
          <div className="col-span-2 flex flex-col gap-8">
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2">
                <h1 className="font-semibold text-xl">Upcoming appointments</h1>
                <TotalCount
                  count={upcomingGroup?.length > 0 ? upcomingGroup?.length : 0}
                />
              </div>
              <div className="flex flex-col gap-3 max-h-[14rem] overflow-auto pt-3 pr-3">
                {upcomingGroup?.length > 0 ? (
                  upcomingGroup?.map((itm, ind) => {
                    const app_time = moment(itm?.appointment_service[0]?.appointment_time, [
                      "hh:mm",
                    ]).format("hh:mm a");
                    const app_date = moment(
                      itm?.appointment_service[0]?.appointment_date
                    ).format("dddd,  DD MMM YYYY ");

                    return (
                      <div
                        className={`border rounded-md p-3 flex items-center gap-4 ${selectedAppointmentGroup?.id == itm?.id &&
                          "active-check"
                          }`}
                        onClick={() => {
                          setSelectedAppointment({
                            ...itm?.appointment_service[0],
                            app_id: itm?.appointment_service[0]?.id,
                            appointment_time: app_time,
                          });
                          setSelectedAppointmentGroup(itm)
                        }}
                      >
                        <div>
                          <div className="h-[3.5rem] w-[3.5rem] rounded-full overflow-hidden flex items-center justify-center border bg-[#BAD3FC]">
                            <Svgs.NewService />
                          </div>
                        </div>
                        <div className="">
                          <p className="text-[#101928]/40 text-sm">
                            {app_date} at {app_time}
                          </p>
                          <h1 className="text-primary font-semibold text-lg">
                            {itm?.appointment_service[0]?.business_address?.address}
                          </h1>
                          <p className="text-[#101928]/40 text-sm">
                            {itm?.appointment_service?.map((srv, i, arr) => {
                              return (
                                <span>{srv?.service?.name} {i < arr?.length - 1 ? ", " : ""}</span>
                              )
                            })}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <NotFound />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-2">
                <h1 className="font-semibold text-xl">Past appointments</h1>
                <TotalCount count={pastGroup?.length > 0 ? pastGroup?.length : 0} />
              </div>
              <div className="flex flex-col gap-3 max-h-[14rem] overflow-auto pt-3 pr-3">
                {pastGroup?.length > 0 ? (
                  pastGroup?.map((itm, ind) => {
                    const app_time = moment(itm?.appointment_service[0]?.appointment_time, [
                      "hh:mm",
                    ]).format("hh:mm a");
                    const app_date = moment(
                      itm?.appointment_service[0]?.appointment_date
                    ).format("dddd,  DD MMM YYYY ");

                    return (
                      <div
                        className={`border rounded-md p-3 flex items-center gap-4 ${selectedAppointmentGroup?.id == itm?.id &&
                          "active-check"
                          }`}
                        onClick={() => {
                          setSelectedAppointmentGroup(itm)
                        }}
                      >
                        <div>
                          <div className="h-[3.5rem] w-[3.5rem] rounded-full overflow-hidden flex items-center justify-center border bg-[#BAD3FC]">
                            <Svgs.NewService />
                          </div>
                        </div>
                        <div className="">
                          <p className="text-[#101928]/40 text-sm">
                            {app_date} at {app_time}
                          </p>
                          <h1 className="text-primary font-semibold text-lg">
                            {itm?.appointment_service[0]?.business_address?.address}
                          </h1>
                          <p className="text-[#101928]/40 text-sm">
                            {itm?.appointment_service?.map((srv, i, arr) => {
                              return (
                                <span>{srv?.service?.name} {i < arr?.length - 1 ? ", " : ""}</span>
                              )
                            })}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <NotFound />
                )}
              </div>
            </div>
          </div>
          <RightSection
            selectedAppointmentGroup={selectedAppointmentGroup}
            businessLocation={businessLocation}
            setManageAppintment={setManageAppintment}
            ManageAppintment={ManageAppintment}
            tax={tax}
          />
        </div>
      )}
      {ManageAppintment && (
        <Popup
          heading={"Manage Appointment"}
          size={"md"}
          open={ManageAppintment}
          close={setManageAppintment}
        >
          <div className="flex flex-col gap-4">
            <div
              className="flex items-center gap-3 cursor-pointer"
              onClick={() => {
                setIsReshedule(true);
                setManageAppintment(!ManageAppintment);
              }}
            >
              <Svgs.Calendar />
              <p className="text-sm text-[#3A3A3A]">Reschedule Appointment</p>
            </div>
            <div
              className="flex items-center gap-3 cursor-pointer"
              onClick={() => {
                setAddNotes(!AddNotes);
              }}
            >
              <Svgs.CirlceClose fill={"#3A3A3A"} size="1.6rem" />
              <p className="text-sm text-[#3A3A3A]">Cancel Appointment</p>
            </div>
          </div>
        </Popup>
      )}

      {AddNotes && (
        <Popup
          size="md"
          heading="Appointment Notes"
          open={AddNotes}
          close={setAddNotes}
        >
          <div className="flex flex-col gap-1">
            <h1 className="text-primary text-sm">Add Details</h1>
            <Textarea
              onChange={(e) => {
                setNotesInp(e.target.value);
              }}
              value={notes_inp}
            />
            <div className="flex justify-end">
              <LoginBtn
                disabled={!notes_inp}
                text="Add Note"
                onClick={() => {
                  onCancelAppointments(selectedAppointment?.app_id, notes_inp);
                  setAddNotes(false);
                }}
              />
            </div>
          </div>
        </Popup>
      )
      }
    </ConatinerWithHeader>
  );
};

export default MyAppointments;





export const RightSection = ({
  setManageAppintment,
  ManageAppintment,
  // selectedAppointment,
  businessLocation,
  tax,
  selectedAppointmentGroup
}) => {
  let total_tax = 0;
  let totalPrice = useMemo(() => {
    return selectedAppointmentGroup?.appointment_service?.reduce((acc, obj) => acc + parseInt(obj.price), 0);
  }, [selectedAppointmentGroup]);

  let tip_sum = useMemo(() => {
    return selectedAppointmentGroup?.appointment_tips?.reduce((acc, obj) => acc + obj.tip, 0);
  }, [selectedAppointmentGroup]);

  const app_date = moment(selectedAppointmentGroup?.appointment_service[0]?.appointment_date).format(
    "dddd,  DD MMM YYYY "
  );
  const app_time = moment(selectedAppointmentGroup?.appointment_service[0]?.appointment_time, [
    "hh:mm",
  ]).format("hh:mm a");

  let arr = [];

  if (tax?.length > 0) {
    tax?.forEach((itm) => {
      itm?.tax?.parent_tax && arr?.push(...itm?.tax?.parent_tax)
    })
  }

  console.log(tip_sum)

  return (
    selectedAppointmentGroup = null || selectedAppointmentGroup == undefined || !selectedAppointmentGroup ?
      <div className="col-span-3 flex flex-col gap-3">
        <div className="h-full p-5 rounded-lg shadow-lg items-center justify-center border flex flex-col gap-4">
          <NotFound />
        </div>
      </div>
      :
      <div className="col-span-3 flex flex-col gap-3">
        <div className="p-5 rounded-lg shadow-lg border flex flex-col gap-4 h-fit">
          <LoginBtn
            text={
              selectedAppointmentGroup?.appointment_service[0]?.appointment_status === "Done"
                ? "Done"
                : selectedAppointmentGroup?.appointment_service[0]?.appointment_status === "Cancel"
                  ? "Cancel"
                  : "Confirmed"
            }
          />
          <h1 className="font-semibold text-xl">
            {app_date} at {app_time}
          </h1>
          <div className="text-sm">
            <p className="text-[#5679FF]">
              <GoogleDirection text={selectedAppointmentGroup?.appointment_service[0]?.business_address?.address} address={selectedAppointmentGroup?.appointment_service[0]?.business_address?.address} />
            </p>
            <p className="text-[#101928]/50">
              {selectedAppointmentGroup?.appointment_service[0]?.business_address?.address_name}
            </p>
          </div>

          {

            selectedAppointmentGroup?.appointment_service[0]?.appointment_status != "Done" &&
            selectedAppointmentGroup?.appointment_service[0]?.appointment_status != "Cancel" && (
              <LoginBtn
                className={"!px-6"}
                borderBtn
                text={"Manage Appointment"}
                onClick={() => {
                  setManageAppintment(!ManageAppintment);
                }}
              />
            )}
          <div className="flex items-center justify-between gap-4">
            <h1 className="text-primary font-semibold text-lg">
              Appointment details
            </h1>
            <p className="text-sm text-[#101928]/50">
              Booking ref: {selectedAppointmentGroup?.id?.split("-")[0]}
            </p>
          </div>
          {selectedAppointmentGroup?.appointment_service?.map((itm) => {
            return (
              <>
                <div className="px-4 py-3 rounded-md flex items-center gap-3 w-fit border border-[#BAD3FC] bg-[#ECF3FF]">
                  <div>
                    <img
                      src={`${itm?.member?.image
                        ? itm?.member?.image
                        : `${process.env.PUBLIC_URL}/images/profile_img.svg`
                        }`}
                      className="h-[3.5rem] w-[3.5rem] object-cover rounded-full"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-primary font-semibold text-lg">
                      Appointment with {itm?.member?.full_name}
                    </h1>
                    <p className="text-[#101928]/40 text-sm">
                      {itm?.member?.designation}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between gap-3 py-2 border-b">
                  <div className="">
                    <h1 className="text-primary font-semibold text-lg">
                      {itm?.service?.name}
                    </h1>
                    <p className="text-[#101928]/40 text-sm">
                      {itm?.duration}
                    </p>
                  </div>
                  <p className="font-semibold"> {itm?.price} {businessLocation?.currency?.code}</p>
                </div>
              </>

            )
          })}
          {arr?.map((tx) => {
            if (tx?.tax_type == "Group") {
              let taxOne = (tx?.parent_tax[0]?.tax_rate * (totalPrice) / 100);
              let firstTotal = (taxOne + (totalPrice))
              let taxTwo = ((tx?.parent_tax[1]?.tax_rate) * firstTotal / 100)
              totalPrice = taxTwo + firstTotal;
            } else {
              total_tax = tx?.tax_rate * (totalPrice) / 100
              totalPrice = total_tax + (totalPrice)
            }

            return (
              tx?.tax_type == "Group" ?

                <div className="flex items-center justify-between gap-3 py-2">
                  <div className="">
                    <h1 className="text-primary font-semibold text-lg">Tax</h1>
                  </div>
                  <p className="text-[#5679FF]  font-semibold text-lg">
                    {tx?.parent_tax[0]?.tax_rate}%, {tx?.parent_tax[1]?.tax_rate}%
                  </p>
                </div>
                :
                <div className="flex items-center justify-between gap-3 py-2">
                  <div className="">
                    <h1 className="text-primary font-semibold text-lg">Tax ({tx?.tax_rate}%)</h1>
                  </div>
                  <p className="font-semibold text-[#5679FF] text-lg">
                    {tx?.tax_rate}%
                  </p>
                </div>
            )
          })}
          <div className={`${selectedAppointmentGroup?.appointment_tips?.length > 0 ? '' : 'hidden'}`}>
            {
              selectedAppointmentGroup?.appointment_tips?.length > 0 &&
              <div className="flex items-center justify-between gap-3 py-2">
                {
                  selectedAppointmentGroup?.appointment_tips?.map((app_tip, index) => {
                    return (
                      <>
                        <div className="mb-0" key={index}>
                          <h1 className="text-primary font-semibold text-lg">Tip for {app_tip.member_name}</h1>
                        </div>
                        <p className="text-[#5679FF] font-semibold text-lg">
                          {app_tip?.tip?.toFixed(2)} {businessLocation?.currency?.code}
                        </p>
                      </>
                    )
                  })
                }
              </div>
            }
          </div>
          <div className="flex items-center justify-between gap-3 py-2">
            <div className="">
              <h1 className="text-primary font-semibold text-lg">Total</h1>
            </div>
            <p className="text-[#5679FF] font-semibold text-lg">
              {(totalPrice) ? (tip_sum + totalPrice)?.toFixed(2) : 0} {businessLocation?.currency?.code}
              {/* {(totalPrice) ? Math.round(totalPrice) : 0} {businessLocation?.currency?.code} */}
            </p>
          </div>
        </div>
      </div>
  );
};





export const TimeSection = ({
  selectedAppointmentGroup,
  selectedAppointment,
  location,
  app_list,
  hash,
  setIsReshedule,
}) => {
  const sliderRef = useRef();
  const DateCardRef = useRef();
  const dispatch = useDispatch();
  const dt = new Date();
  let yearArr = [2022, 2023, 2024];
  const [Selected, setSelected] = useState();
  const [employeeTimeSlot, setEmployeeTimeSlot] = useState([]);
  const [test, settest] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [data, setData] = useState();

  const [appointmentData, setAppointmentData] = useState({
    current_month: 0,
    selected_month: "",
    appointment_date: "",
    current_year: "",
    selected_day: "",
    selected_year: "",
    date: "",
  });

  //  this fuction for to scroll auto till the current date
  const SliderRef_Hanlder = () => {
    let slider = sliderRef.current;
    let date_card = DateCardRef.current;
    let slider_left_ofset = slider.offsetLeft;
    let card_left_ofset = date_card.offsetLeft;

    let ofset_dif = card_left_ofset - slider_left_ofset;
    slider.scrollLeft = ofset_dif - 100;
  };


  // fuction for to scroll auto till the current date
  useEffect(() => {
    if (sliderRef && sliderRef.current && DateCardRef && DateCardRef.current) {
      setTimeout(() => {
        SliderRef_Hanlder();
      }, 200);
    }
  }, [sliderRef.current, DateCardRef.current, appointmentData?.selected_month]);



  //set appointment date here by defualt
  useEffect(() => {
    let dt = new Date();
    let date = new Date(
      appointmentData?.selected_year > dt.getFullYear()
        ? appointmentData?.selected_year
        : !appointmentData?.selected_year
          ? selectedAppointment?.appointment_date
          : new Date()
    );

    let today_year = date.getFullYear();
    let today_month = date.getMonth();
    let today_date = date.getDate();
    let today_day = date.getDay() - 1;

    setAppointmentData({
      ...appointmentData,
      current_year: today_year,
      current_month: today_month,
      current_date: today_date,
      selected_month: MONTHS[today_month],
      selected_day: WEEKDAYS[today_day],
      appointment_date: `${today_year}-${today_month + 1}-${today_date}`,
    });
    if (!test) {
      settest(today_year);
    }
  }, [appointmentData?.selected_year]);

  //set time formate with 15 mints gap of the employee start and close time
  const timeSlot = (strt, clse) => {
    if (strt != null && clse != null) {
      var startTime = moment(strt, "HH:mm");
      var endTime = moment(clse, "HH:mm");
      var timeStops = [];
      while (startTime <= endTime) {
        timeStops.push(new moment(startTime).format("HH:mm:ss"));
        startTime.add(30, "minutes");
      }
      setEmployeeTimeSlot(
        timeStops.map((ele) => ({
          value: ele,
          label: moment(ele, ["HH:mm:ss"]).format("h:mm A"),
        }))
      );
    } else {
      setEmployeeTimeSlot([]);
    }
  };

  // show time detail of selected date set here
  useEffect(() => {
    const app_day = moment(appointmentData?.appointment_date)
      .format("dddd")
      .toLocaleLowerCase();
    setSelectedDate(
      moment(appointmentData?.appointment_date).format("D MMM,YYYY")
    );

    const res = location?.opening_hours?.find((dy) => dy?.day === app_day);
    timeSlot(res?.start_time, res?.close_time);
    setSelected(res?.start_time);
  }, [appointmentData?.appointment_date]);

  // formate duration and data for backend
  useEffect(() => {
    let res = selectedAppointmentGroup
    let start = moment(Selected, "HH:mm");
    let arr = [];
    res?.appointment_service?.forEach((ele, i) => {
      const drtion_arr = ele?.duration?.split(" ");
      if (drtion_arr?.length > 1) {
        const hrs =
          drtion_arr[0]?.toString()?.includes("h") &&
          drtion_arr[0]?.split("h")[0] * 60;
        const mns = drtion_arr[1]?.toString()?.split("min")[0] * 1;
        const total = hrs + mns;
        arr.push(new moment(start).format("HH:mm:ss"));
        start.add(total, "minutes");
      } else {
        const mns = drtion_arr[0]?.toString()?.split("min")[0] * 1;

        arr.push(new moment(start).format("HH:mm:ss"));
        start.add(mns, "minutes");
      }
    });

    const format_data = res?.appointment_service?.map((itm, ind) => {
      return {
        date_time: arr[ind],
        id: itm?.id,
        date: appointmentData?.appointment_date,
        duration: itm?.duration,
      };
    });
    if (format_data?.length > 0) {
      setData(format_data);
    }
  }, [appointmentData?.appointment_date, Selected]);

  // on reshedule api hit here
  const onReschedule = () => {
    dispatch(
      reschedulClientAppointments(
        data,
        hash,
        (message) => {
          toast.success(message, { toastId: "toast" });
          setIsReshedule(false);
        },
        () => {
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };

  return (
    <div className="md:grid flex flex-col grid-cols-7">
      <div className="col-span-5 border-r">
        <div className="px-8 pt-4 pb-8">
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-between gap-3">
              <div>
                <p className="text-sm flex items-center gap-2">
                  Select Date{" "}
                  {appointmentData?.selected_month
                    ? `- ${appointmentData?.selected_month}`
                    : ""}{" "}
                  <select
                    className="outline-none border-0 rounded-md text-sm"
                    placeholder="year"
                    onChange={(e) => {
                      settest(e.target.value);
                      setAppointmentData({
                        ...appointmentData,
                        selected_year: e.target.value,
                      });
                    }}
                  >
                    {yearArr?.map((itm, ind) => {
                      let date = new Date();
                      let crnt_yr = date.getFullYear();

                      return (
                        <option
                          value={itm}
                          selected={
                            appointmentData.current_year &&
                              appointmentData.current_year === itm
                              ? true
                              : crnt_yr === itm
                          }
                          disabled={itm < crnt_yr ? true : false}
                          className={
                            itm < crnt_yr
                              ? "bg-[#c8c6c6] cursor-not-allowed"
                              : "cursor-pointer"
                          }
                        >
                          {itm}
                        </option>
                      );
                    })}
                  </select>
                </p>
              </div>
              <div>
                <Dropwdown
                  title=""
                  placeholder="Select Month"
                  showTitle={false}
                  value={appointmentData.selected_month}
                  name="selected_month"
                  onChange={(e) => {
                    setAppointmentData({
                      ...appointmentData,
                      selected_month: e.target.value,
                    });
                  }}
                  options={MONTHS.map((mnth, index) => {
                    const dt = new Date();
                    return {
                      label: mnth,
                      value: mnth,
                      disabled:
                        appointmentData.current_year > dt.getFullYear()
                          ? false
                          : index >= dt.getMonth()
                            ? false
                            : true,
                    };
                  })}
                />
              </div>
            </div>

            <div
              className="flex items-center gap-3 overflow-auto py-3 scroll-smooth"
              ref={sliderRef}
            >
              {appointmentData?.current_month >= 0 &&
                [
                  ...Array(
                    MONTHS_DURATIONS[
                    MONTHS.indexOf(appointmentData?.selected_month)
                    ]
                  ).keys(),
                ].map((day, index) => {
                  let is_selected =
                    appointmentData.appointment_date ==
                      `${appointmentData?.current_year}-${MONTHS.indexOf(appointmentData?.selected_month) + 1
                      }-${day + 1}`
                      ? true
                      : false;
                  let is_start =
                    MONTHS.indexOf(appointmentData?.selected_month) ==
                      appointmentData?.current_month
                      ? appointmentData?.current_date == day + 1
                      : index == 0;
                  return (
                    <DateCard
                      date_card_ref={is_selected ? DateCardRef : is_start ? DateCardRef : { current: undefined }}
                      year={appointmentData?.current_year}
                      month={appointmentData?.selected_month}
                      current_month={appointmentData?.current_month}
                      date={day + 1}
                      disabled={
                        test > dt.getFullYear()
                          ? false
                          : MONTHS.indexOf(appointmentData?.selected_month) ==
                            appointmentData?.current_month
                            ? dt.getDate() <= day + 1
                              ? false
                              : true
                            : false
                      }
                      selected={is_selected}
                      onSelected={(value) => {
                        setAppointmentData({
                          ...appointmentData,
                          appointment_date: value,
                        });
                      }}
                    />
                  );
                })}
            </div>
            <h1 className="font-semibold text-xl">{selectedDate}</h1>
            <div className="flex items-center gap-3 flex-wrap">
              {employeeTimeSlot?.length > 0 ? (
                employeeTimeSlot
                  ?.filter((itm) => {
                    if (appointmentData?.appointment_date === `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`) {
                      if (itm?.value >= moment(dt).format("HH:mm:ss")) {
                        return true
                      }
                    } else {
                      return true
                    }
                  }
                  )
                  .map((ele, i) => {
                    return (
                      <div
                        onClick={() => {
                          setSelected(ele?.value);
                        }}
                        className={`border rounded-md flex items-center justify-center w-[8rem] h-[3rem] text-sm text-[#101928] cursor-pointer hover:bg-gray-100 ${Selected == ele?.value && "!bg-blue-100 border-blue-500"
                          }`}
                      >
                        {ele?.label}
                      </div>
                    );
                  })
              ) : (
                <h1 className="font-semibold text-xl">OFF DAY</h1>
              )}
            </div>
          </div>
        </div>
        <LoginBtn
          onClick={() => {
            onReschedule();
          }}
        >
          Reschedule
        </LoginBtn>
      </div>
    </div>
  );
};
