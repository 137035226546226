import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import moment from 'moment/moment';
import React, { useState } from 'react'
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm';
import NotFound from 'Components/Utility/NotFound';
import Pagination from 'new-components/Pagination';
import NoLoyalityLogByCustomerFound from '../../Elements/NoLoyalityLogByCustomer';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown';

const ByCustomer = (props) => {
    const { setSection, start, client, translation, selectedLanguage, setStartDate, end, setEndDate, customer, setCustomer,
        loading, data, currentPage, setCurrentPage, PageSize, pages, allCount,
        clientSearchText, setClientSearchText, clientCurrentPage, setClientCurrentPage, clientLoader, clientPaginationData
    } = props
    const [toggleDateRange, setToggleDateRange] = useState(false)

    return (
        <>
            <div className="border rounded-lg">
                <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row  gap-3 flex-wrap">
                    <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 ">
                        <div
                            onClick={() => setSection("ByStaff")}
                            className="flex items-center sm:gap-4 gap-2 "
                        >
                            <h2 className="font-semibold text-xl whitespace-nowrap">
                                {selectedLanguage?.name == "English" ? "All Loyalty Logs" : translation[1156]?.value ? translation[1156]?.value : "All Loyalty Logs"}
                            </h2>
                            {loading ?
                                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                                </div>
                                :
                                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                    <p className="text-primary">
                                        {allCount ? allCount : 0}{" "}
                                        {allCount && allCount > 1 ?
                                            selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                            : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                        }
                                    </p>
                                </div>
                            }
                        </div>
                        {/* <div className="mb-00">
                            <Dropwdown
                                options={[
                                    { label: selectedLanguage?.name == "English" ? "All Customer" : translation[1154]?.value ? translation[1154]?.value : "All Customer", value: "" },
                                    ...client?.map((clnt) => {
                                        return {
                                            label: clnt?.full_name,
                                            value: clnt?.id,
                                        };
                                    }),
                                ]}
                            />

                        </div> */}
                    </div>
                    <div className="grid grid-cols-2 sm:flex items-center justify-end gap-3 w-full sm:w-fit">
                        {/* <DateRangePicker
                            value={[start, end]}
                            clearIcon={
                                <p
                                    onClick={() => {
                                        if (start && end) setStartDate();
                                        setEndDate();
                                    }}
                                >
                                    {" "}
                                    {selectedLanguage?.name == "English" ? "clear" : translation[340]?.value ? translation[340]?.value : "clear"}
                                    {" "}
                                </p>
                            }
                            onChange={(e) => {
                                if (e && e.length > 0) {
                                    const from = moment(e[0])?.format("YYYY-MM-DD");
                                    const to = moment(e[1])?.format("YYYY-MM-DD");
                                    if (from && to) {
                                        setStartDate(from);
                                        setEndDate(to);
                                    }
                                }
                            }}
                        /> */}
                        <PaginationDropdown
                            title=""
                            value={customer}
                            onChange={(e) => setCustomer(e.target.value)}
                            showTitle={false}
                            placeholder={selectedLanguage?.name == "English" ? "All Client" : translation[1154]?.value ? translation[1154]?.value : "All Client"}

                            setPage={setClientCurrentPage}
                            page={clientCurrentPage}
                            setDropdownSearch={setClientSearchText}
                            dropdownSearch={clientSearchText}
                            lastPage={clientPaginationData?.pages}
                            currentPage={clientPaginationData?.currentPage}
                            loading={clientLoader}
                            custom_min_width={'min-w-[15rem]'}
                            options={[
                                { label: selectedLanguage?.name == "English" ? "All Client" : translation[1154]?.value ? translation[1154]?.value : "All Client", value: "" },
                                ...client?.map((clnt, i) => {
                                    return {
                                        label: clnt.full_name,
                                        value: clnt.id,
                                        otherData: {
                                            image: clnt?.image,
                                            DataOne: clnt.email,
                                            showImage: true,
                                            index: i
                                        },
                                        email: clnt.email,
                                        mobile_number: clnt.mobile_number
                                    }
                                }),
                            ]}
                        />
                        <div className='flex items-center gap-3'>
                            <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                                <div className='p-[0.45rem] relative gap-4 flex items-center'>
                                    <p onClick={() => {
                                        setToggleDateRange(!toggleDateRange)
                                    }} className='cursor-pointer'>
                                        {`${start ? moment(start).format("DD-MM-YYYY") : "--/--/----"} - ${end ? moment(end).format("DD-MM-YYYY") : "--/--/----"}`}
                                    </p>
                                    <span className='cursor-pointer' onClick={() => {
                                        setStartDate()
                                        setEndDate()
                                    }}>Clear</span>

                                    {
                                        toggleDateRange && <>
                                            <div className='fixed inset-0 z-1 w-full' onClick={() => { setToggleDateRange(false) }}></div>
                                            <div className='absolute top-full right-0 z-[2'>
                                                <DateRangePicker
                                                    start={start}
                                                    end={end}
                                                    setStartDate={setStartDate}
                                                    setEndDate={setEndDate}
                                                    setToggleDateRange={setToggleDateRange}
                                                    
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* {!employee ? (
                            <CSVLink
                                {...commissionReportData}
                                target="_blank"
                                onClick={() => {
                                    setTimeout(() => {
                                        toast.success("File downloaded successfully", { toastId: "toast",
onClose: () => setIsToastVisible(false) });
                                    }, 300);
                                }}
                            >
                                <LoginBtn
                                    className="rounded-lg border-2 border-primary"
                                    bg="bg-white"
                                    
                                >
                                    <div className="flex items-center gap-2 text-[#101928]">
                                        <Svgs.Export bg="#101928" />
                                    </div>
                                </LoginBtn>
                            </CSVLink>
                        ) : (
                            ""
                        )} */}
                    </div>
                </div>
                {loading ?
                    <TableShimer cols={5} rows={11} colsHeight={9} />
                    :
                    <div className="overflow-x-auto">
                        <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(205px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                            {/* <p>
                            {selectedLanguage?.name == "English" ? "Customer ID" : translation[1158]?.value ? translation[1158]?.value : "Customer ID"}
                        </p> */}
                            <p>
                                {selectedLanguage?.name == "English" ? "Client Name" : translation[1159]?.value ? translation[1159]?.value : "Client Name"}
                            </p>
                            <p>
                                {selectedLanguage?.name == "English" ? "Loyalty Name" : translation[1160]?.value ? translation[1160]?.value : "Loyalty Name"}
                            </p>
                            {/* <p>Loyalty Type</p> */}
                            <p>
                                {selectedLanguage?.name == "English" ? "Points Earned" : translation[1161]?.value ? translation[1161]?.value : "Points Earned"}
                            </p>
                            <p>
                                {selectedLanguage?.name == "English" ? "Points Redeemed" : translation[1162]?.value ? translation[1162]?.value : "Points Redeemed"}
                            </p>
                            <p>
                                {selectedLanguage?.name == "English" ? "Balance" : translation[1163]?.value ? translation[1163]?.value : "Balance"}
                            </p>
                        </div>
                        {data?.length > 0 ? (
                            data?.map((itm) => {
                                return (
                                    <>
                                        <div className="text-[#3A3A3A] transition-all px-12 py-3 odd-bg inline-grid xl:grid grid-cols-[repeat(5,minmax(205px,1fr))] min-w-full w-fit">
                                            {/* <div className="flex items-center">
                                                <p className="text-sm">{itm?.customer?.customer_id}</p>
                                            </div> */}
                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {itm?.customer?.customer_name}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {itm?.loyalty?.loyalty_name}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {itm?.points_earned?.toFixed(2)}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {itm?.points_redeemed}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {parseFloat(itm?.balance.toFixed(2))}
                                                </p>
                                            </div>
                                            {/* <div className="flex items-center">
                                                <p className="text-sm">
                                                    {itm?.product_sale_price?.voucher_commission} {currency}
                                                </p>
                                            </div> */}
                                        </div>
                                    </>
                                );
                            })
                        ) : (
                            <NoLoyalityLogByCustomerFound />
                        )}

                        {(data?.length > 0 && !loading) &&
                            <Pagination
                                currentPage={currentPage}
                                totalCount={Math.ceil(pages)}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        }

                        {/* <div className="mt-[4rem] inline-grid xl:grid grid-cols-[repeat(8,minmax(205px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                        <p>Total</p>
                        <p>{totals?.total_services_sales}</p>
                        <p>{totals?.total_services_commissions}</p>
                        <p>{totals?.total_products_sales}</p>
                        <p>{totals?.total_products_commissions}</p>
                        <p>{totals?.total_vouchers_sales}</p>
                        <p>{totals?.total_vouchers_commissions}</p>
                        <p>{totals?.total_commission}</p>
                    </div> */}
                    </div>
                }
            </div >
        </>

    )
}

export default ByCustomer