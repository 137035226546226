import React from 'react'

const VerifyNumber = () => {
    return (
        <svg width="166" height="170" viewBox="0 0 166 170" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M152.073 167H26.6836V77.5425L89.3684 43.9727L152.073 77.5425V167Z" fill="#4561CC"/>
            <path d="M143.45 29.2607H32.2031V149.705H143.45V29.2607Z" fill="#EDF1FF"/>
            <path d="M82.4723 104.745L57 82.9929L63.8335 74.9039L80.4405 88.564L112.849 46.9999L121.496 53.154L82.4723 104.745Z" fill="rgb(86, 121, 255)"/>
            <path d="M152.073 99.9365V167H26.6836V99.9365C32.0683 102.511 37.3629 105.212 42.6876 107.846C48.0023 110.53 53.3069 113.214 58.5815 115.949C64.4566 118.963 70.3318 122.035 76.1869 125.124C76.247 125.091 76.317 125.057 76.3771 125.032C76.317 125.065 76.287 125.108 76.227 125.141C80.6108 127.446 85.0046 129.767 89.3885 132.08C93.7523 129.759 98.1461 127.429 102.53 125.141C102.47 125.108 102.44 125.065 102.38 125.032C102.44 125.065 102.51 125.099 102.57 125.108C108.425 122.027 114.28 118.963 120.175 115.932C125.45 113.214 130.735 110.513 136.049 107.846C141.394 105.212 146.689 102.511 152.073 99.9365Z" fill="rgb(86, 121, 255)"/>
            <g opacity="0.2">
                <path d="M152.072 99.9362C147.007 102.941 141.843 105.811 136.748 108.765C131.604 111.652 126.449 114.548 121.265 117.401C115.86 120.389 110.435 123.343 105.01 126.281C104.84 126.373 104.69 126.475 104.52 126.568C99.7158 129.159 94.9016 131.742 90.0774 134.333L89.3667 134.696L88.6661 134.333C83.8419 131.742 79.0477 129.159 74.2435 126.551C74.0734 126.475 73.9032 126.373 73.7331 126.281C68.2883 123.343 62.8635 120.389 57.4588 117.384C52.2943 114.539 47.1398 111.652 41.9953 108.757C36.8908 105.803 31.7363 102.933 26.6719 99.9277C32.0566 102.502 37.3512 105.203 42.6759 107.837C47.9905 110.521 53.2952 113.205 58.5698 115.94C64.4449 118.954 70.3201 122.026 76.1752 125.116C76.1752 125.116 76.1885 125.121 76.2152 125.133C80.5991 127.437 84.9929 129.758 89.3767 132.071C93.7406 129.75 98.1344 127.42 102.518 125.133C102.518 125.133 102.532 125.121 102.558 125.099C108.413 122.018 114.269 118.954 120.164 115.923C125.438 113.205 130.723 110.504 136.038 107.837C141.382 105.203 146.677 102.502 152.062 99.9277L152.072 99.9362Z" fill="#5679FF"/>
                <path d="M76.3737 125.031C76.3136 125.065 76.2836 125.107 76.2236 125.141C75.563 125.614 74.9024 126.095 74.2418 126.551C71.0991 128.787 67.8763 130.932 64.6034 133.016C60.6099 135.591 56.5263 138.047 52.4227 140.495C48.2991 142.892 44.1555 145.273 39.9218 147.56C35.6881 149.848 31.4143 152.085 26.9805 154.136C30.7838 151.325 34.7373 148.708 38.7308 146.151C42.7443 143.576 46.8278 141.12 50.9114 138.672C55.055 136.258 59.1987 133.877 63.4324 131.59C66.8053 129.767 70.2283 127.969 73.7314 126.289C74.5421 125.892 75.3528 125.512 76.1835 125.133C76.2436 125.099 76.3136 125.065 76.3737 125.04V125.031Z" fill="black"/>
                <path d="M151.772 154.136C147.348 152.085 143.055 149.848 138.831 147.56C134.597 145.273 130.453 142.892 126.31 140.495C122.206 138.047 118.123 135.591 114.129 133.016C110.876 130.94 107.654 128.804 104.521 126.568C103.84 126.095 103.18 125.614 102.529 125.141C102.469 125.107 102.439 125.065 102.379 125.031C102.439 125.065 102.509 125.099 102.569 125.107C103.4 125.504 104.211 125.884 105.021 126.281C108.524 127.96 111.927 129.758 115.32 131.581C119.554 133.869 123.698 136.249 127.821 138.663C131.925 141.111 136.008 143.568 140.002 146.142C144.015 148.7 147.969 151.317 151.772 154.127V154.136Z" fill="black"/>
             </g>
            <path d="M26.7334 28.9783C26.333 26.6486 26.243 24.3189 26.2229 21.9891C26.233 19.6594 26.323 17.3297 26.7134 15C27.1137 17.3297 27.2038 19.6594 27.2238 21.9891C27.2138 24.3189 27.1237 26.6486 26.7334 28.9783Z" fill="#4762CD"/>
            <path d="M19.6972 31.1982C17.4653 29.7886 15.4435 28.1932 13.4818 26.5641C11.5401 24.9097 9.64844 23.213 7.96697 21.3307C10.1989 22.7403 12.2207 24.3272 14.1824 25.9648C16.1241 27.6192 18.0158 29.3159 19.6872 31.1982H19.6972Z" fill="#4762CD"/>
            <path d="M16.5745 36.9634C13.8121 37.3011 11.0497 37.377 8.28725 37.3939C5.52484 37.3855 2.76242 37.3095 0 36.9803C2.76242 36.6427 5.52484 36.5667 8.28725 36.5498C11.0497 36.5583 13.8121 36.6342 16.5745 36.9634Z" fill="#4762CD"/>
            <path d="M19.2068 42.8974C17.5353 44.7798 15.6437 46.4849 13.712 48.1393C11.7503 49.7769 9.73852 51.3722 7.50657 52.7903C9.17803 50.9079 11.0597 49.2029 13.0014 47.5484C14.9631 45.9109 16.9749 44.3155 19.2068 42.8974Z" fill="#4762CD"/>
            <path d="M138.498 13.9783C138.108 11.6486 138.018 9.31886 138.008 6.98915C138.028 4.65943 138.118 2.32972 138.518 0C138.909 2.32972 138.999 4.65943 139.009 6.98915C138.989 9.31886 138.899 11.6486 138.498 13.9783Z" fill="#4762CD"/>
            <path d="M145.534 16.1982C147.206 14.3159 149.098 12.6192 151.039 10.9648C153.001 9.32725 155.023 7.74034 157.255 6.33069C155.583 8.21304 153.692 9.90967 151.75 11.5641C149.788 13.2017 147.766 14.7886 145.534 16.1982Z" fill="#4762CD"/>
            <path d="M148.647 21.9634C151.41 21.6342 154.172 21.5583 156.934 21.5498C159.697 21.5667 162.459 21.6427 165.222 21.9803C162.459 22.3095 159.697 22.3855 156.934 22.3939C154.172 22.377 151.41 22.3011 148.647 21.9634Z" fill="#4762CD"/>
            <path d="M146.025 27.8974C148.257 29.3071 150.269 30.9024 152.23 32.5484C154.172 34.2029 156.054 35.908 157.725 37.7903C155.493 36.3806 153.481 34.7853 151.52 33.1393C149.578 31.4849 147.696 29.7798 146.025 27.8974Z" fill="#4762CD"/>
        </svg>
    )
}

export default VerifyNumber