import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { getLocations } from "Adapters/Api";
import { getClient } from "Adapters/Api/clients";
import { getEmployeeList } from "Adapters/Api/employee";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useSaleModule from "../helper";

export const useSaleOrder = () => {
  let paymentDate;
  let paymenTime;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useSaleModule()
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const selected_location = getDataFromLocalStorage("selected_location");
  const businessData = getDataFromLocalStorage("business_data");
  const { locations, saleOrder, business, client, employee, saleService, utility } = useSelector(
    (state) => state
  );

  const businessCurrency = business.business;
  const productList = saleOrder?.saleOrderProducts;
  const serviceList = saleOrder?.saleOrderServices;
  const voucherList = saleOrder?.saleOrderVouchers;
  const memberShipList = saleOrder?.saleOrderMemberships;
  const totalInvoiceBill = saleOrder?.totalInvoiceBill;
  const total_tax = saleService?.total_tax;
  const location_currency = utility?.currency?.location_currency;

  const [filter_data, setFilterData] = useState({});
  const [locationName, setLocationName] = useState("");
  const [FilterPopup, setFilterPopup] = useState(false);
  const [isTabName, setIsTabName] = useState("Products");
  const [selectedInvice, setSelectedInvice] = useState();
  const [InvoicePopup, setInvoicePopup] = useState(false);
  const [filter_pop_data, setFilterPopData] = useState({});
  const [lengths, setLengths] = useState(0)

  const filterDataHandler = (e) => {
    let { name, value } = e.target;
    let new_dict = { ...filter_data, [name]: value };
    if (!new_dict[name]) {
      delete new_dict[name];
    }

    setFilterData({
      ...new_dict,
    });
  };

  useEffect(() => {
    const val = locations?.locations?.filter(
      ({ id }) => selected_location === id
    );
    setLocationName(val[0]?.address_name);
  }, [locations, locationName]);

  useEffect(() => {
    if (locations.locations.length === 0 && businessData?.id) {
      dispatch(getLocations(businessData?.id));
    }
  }, []);

  const filterPopDataHandler = (e) => {
    let { name, value } = e.target;
    let new_dict = { ...filter_pop_data, [name]: value };
    if (!new_dict[name]) {
      delete new_dict[name];
    }

    setFilterPopData({
      ...new_dict,
    });
  };

  useEffect(() => {
    setFilterData({});
    setFilterPopData({});
  }, [isTabName]);

  const toggleInviceModal = (id = "", type = "", item = {}) => {
    if (id && type == "Products") {
      const result = productList?.find((item) => item?.id == id);
      setSelectedInvice({
        ...item,
        id: id?.split("-")[0].split("").slice(0, 2).join(""),
      });
    }

    if (id && type == "Services") {
      const result = serviceList?.find((item) => item?.id == id);
      setSelectedInvice({
        ...item,
        id: id?.split("-")[0].split("").slice(0, 2).join(""),
      });
    }

    setInvoicePopup(!InvoicePopup);
  };

  useEffect(() => {
    if (FilterPopup) {
      if (!client.clients_updated) {
        dispatch(getClient());
      }
      if (business.locations == 0) {
        dispatch(getLocations(business.business.id));
      }
      if (!employee.employee_updated) {
        dispatch(getEmployeeList());
      }
    }
  }, [FilterPopup]);

  //set date and time of invice
  if (selectedInvice) {
    const dataArr = selectedInvice?.created_at?.split("-");
    const date_join = dataArr[2].split("").slice(0, 2).join("");
    dataArr.splice(2, 1, date_join);
    paymentDate = dataArr.join("-");
    paymenTime = selectedInvice?.created_at
      ?.split("T")[1]
      .split(":")
      .join()
      .split(".")[0]
      .replaceAll(",", ":");
  }

  return {
    navigate,
    setIsTabName,
    setFilterData,
    setFilterPopup,
    setLocationName,
    setInvoicePopup,
    toggleInviceModal,
    filterDataHandler,
    filterPopDataHandler,
    client,
    business,
    employee,
    isTabName,
    locations,
    paymenTime,
    productList,
    serviceList,
    voucherList,
    FilterPopup,
    paymentDate,
    filter_data,
    InvoicePopup,
    locationName,
    memberShipList,
    selectedInvice,
    businessCurrency,
    filter_pop_data,
    total_tax,
    totalInvoiceBill,
    location_currency,
    lengths,
    setLengths
  };
};

export const saleOrderTabs = [
  {
    title: "Products",
  },
  {
    title: "Services",
  },
  {
    title: "Membership",
  },
  {
    title: "Vouchers",
  },
];
