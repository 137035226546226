import React from 'react'

const OnlineProfilesShimmer = () => {
    return (
        <>
            <div className="flex items-start justify-between gap-4 animate-pulse">
                <div className="flex items-center gap-2 w-full">
                    <div className="h-8 w-1/5 object-cover rounded-lg bg-gray-200" />
                </div>
                <div className="h-8 w-2/6 object-cover rounded-lg bg-gray-200" />
            </div>

            {[0, 1, 2,3,4]?.map(itm => {
                return (
                    <div className="flex md:items-end w-full items-start animate-pulse justify-between gap-4 border-b pb-4 md:flex-row flex-col">
                        <div className="flex items-center gap-4 w-full">
                            <div className="h-[7rem] w-[10rem] object-cover rounded-lg bg-gray-200" />
                            <div className="flex flex-col gap-2 flex-1">
                                <div className="h-8 w-2/5 object-cover rounded-lg bg-gray-200" />
                                <div className="h-5 w-2/6 object-cover rounded-lg bg-gray-200" />
                                <div className='rounded-full bg-gray-200 items-center w-20 h-8 gap-2'></div>
                            </div>
                        </div>

                        <div className="flex items-end justify-end gap-3 flex-1 ">
                            <div className="flex items-end justify-end gap-3 w-full">
                                <div className='bg-gray-200 rounded-lg items-center w-16 h-10 gap-2'></div>
                                <div className='bg-gray-200 rounded-lg items-center w-32 h-10 gap-2'></div>
                            </div>
                        </div>
                    </div >
                )
            })}
        </>
    )
}

export default OnlineProfilesShimmer