import { get_appointment_analytics_reports } from "Adapters/Api/appointmentReports";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
let timeOutId = undefined;
export const useBookingReports = () => {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [loading, setLoading] = useState(true)
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [exportLoader, setExportLoader] = useState(false);
    const [appointmentReportsData, setAppointmentReportsData] = useState([])
    const [search_text, setSearchText] = useState("");
    const [pages, setPages] = useState(0)

    const selectedLanguage = state?.languages?.selected_language
    const translation = state?.languages?.language_translation




    const getData = (search_text) => {
        setLoading(true)
        dispatch(get_appointment_analytics_reports(
            {
                start_date: startDate, end_date: endDate, search_text: search_text
                // startDate: startDate, endDate: endDate, currentPage: currentPage, search_text: search_text
            },
            (result) => {
                setAppointmentReportsData(result?.response?.data)
                // setPages(result?.pages)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))

    }

    const onChange = (e) => {
        setSearchText(e.target.value);
        // searchFilter(e.target.value);
    };

    useEffect(() => {
        if (search_text === null) {
            // Initial load with empty search_text
            getData("")
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                getData(search_text)
            }, 500);
        }
    }, [currentPage, search_text, startDate, endDate])



    // useEffect(() => {
    //     setCurrentPage(1)
    //     setLoading(true)
    // }, [startDate, endDate])

    return {
        translation, selectedLanguage, onChange,appointmentReportsData,pages, setStartDate, setEndDate,search_text,setSearchText,exportLoader, loading, setCurrentPage,currentPage,startDate,endDate
    }

}