import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LoginInput from "Components/TenantApp/Elements/LoginInput";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LocationSelection from "new-components/location";
import ConsumptionPopup from "./ConsumptionPopup";
import ConsumptionItem from "./ConsumptionItem";
import useConsumptions from "./useConsumptions";
import { CSVLink } from "react-csv";
import { toast } from 'react-toastify'

import Svgs from "Assets/svgs";
import Pagination from "new-components/Pagination";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoConsumptionDataFound from "Components/Utility/NoConsumptionDataFound";
import TableShimmer_v2 from "Components/Utility/Shimmers/TableShimmer_v2";
import Shimmer from "Components/Utility/Shimmers/Shimmer";

export const AddConsumptionBtn = ({ translation, selectedLanguage }) => {
  const { modalOpen, setModalOpen } = useConsumptions();

  return (
    <div className="flex items-center sm:gap-4 gap-2">
      <Svgs.Plus color="#fff" />
      <LoginBtn
        onClick={() => {
          setModalOpen(!modalOpen);
        }}
      >
        <div className="flex items-center gap-2 text-[#fff]">
          <Svgs.Plus color="#fff" />
          <p className="font-semibold">
            {selectedLanguage?.name == "English" ? "Add Consumption" : translation[1009]?.value ? translation[1009]?.value : "Add Consumption"}
          </p>
        </div>
      </LoginBtn>
      {modalOpen && (
        <ConsumptionPopup
          handleClose={() => setModalOpen(false)}
          translation={translation}
          selectedLanguage={selectedLanguage}
        />
      )}
    </div>
  );
};

let timeOutId = undefined;
const Consumptions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const consumptionRedux = state?.consumption?.consumptions;
  const consumptionUpdated = state?.consumption?.consumptions_updated;
  const allConsumptions = state?.consumption?.allConsumptions
  const allCount = state?.consumption?.allCount
  const pages = state?.consumption?.pages
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [dataList, setDataList] = useState([]);
  const { selected_location } = state?.locations;
  const { access_token, getLoader, setGetLoader, loading, currentPage, setCurrentPage,
    searchText, setSearchText, noPaginationLoader, setNoPaginationLoader, fetchConsumptionListNoPagination,
    fetchConsumptionList } = useConsumptions();
  // const consumptionProductLength = consumptionRedux?.length;
  // const checkConsumptionArrayLength = consumptionProductLength === 0;

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])
  // useEffect(() => {
  //   if (checkConsumptionArrayLength) {
  //     fetchConsumptionList();
  //   }
  // }, [checkConsumptionArrayLength]);

  useEffect(() => {
    if (searchText === null) {
      // Initial load with empty searchText
      fetchConsumptionList(currentPage, "")
      fetchConsumptionListNoPagination("")
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        fetchConsumptionList(currentPage, searchText)
        fetchConsumptionListNoPagination(searchText)
      }, 500);
    }
  }, [currentPage, searchText, selected_location])

  // useEffect(() => {
  //   dispatch(getConsumptionsWithNoPagination(selected_location, searchText, access_token, setNoPaginationLoader))
  // }, [selected_location])

  useEffect(() => {
    setGetLoader(true)
    setNoPaginationLoader(true)
    setCurrentPage(1)
  }, [selected_location])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchText])

  useEffect(() => {
    // if (consumptionRedux?.length == 0) {
    //   fetchConsumptionList(currentPage, searchText);
    // } else {
    // const val = consumptionRedux?.filter(cons => cons?.location?.id == selected_location);
    setDataList(consumptionRedux);
    // }
  }, [consumptionRedux]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  let headers = [
    { label: selectedLanguage?.name == "English" ? "Product Name" : translation[296]?.value ? translation[296]?.value : "Product Name", key: 'name' },
    { label: selectedLanguage?.name == "English" ? "From Location" : translation[1015]?.value ? translation[1015]?.value : "From Location", key: 'location' },
    { label: selectedLanguage?.name == "English" ? "Consumption" : translation[1016]?.value ? translation[1016]?.value : "Consumption", key: 'quantity' },
  ]

  const csvReport = {
    filename: "Consumption Stock Report.csv",
    headers: headers,
    data: allConsumptions?.map(itm => {
      return {
        ...itm,
        name: itm?.product?.name ? itm?.product?.name : '--------',
        location: itm?.location?.address_name,
        quantity: itm?.quantity,
      }
    })
  }

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allConsumptions?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  const PageSize = 10
  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
      <p className="text-[#7B7B7B] text-sm">
        <span
          className="cursor-pointer"
          onClick={() => {
            navigate("/dashboard/account/");
          }}
        >
          {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
        </span>{" "}
        &gt;{" "}
        <span
          className="cursor-pointer"
          onClick={() => {
            navigate("/dashboard/account/inventory-operations");
          }}
        >
          {selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations"}
        </span>{" "}
        &gt; <span className="text-primary font-semibold">
          {selectedLanguage?.name == "English" ? "Consumptions" : translation[872]?.value ? translation[872]?.value : "Consumptions"}
        </span>
      </p>
      <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
        <div className="flex items-center sm:gap-4 gap-2">
          <h2 className="text-primary font-bold lg:text-3xl text-xl">
            {selectedLanguage?.name == "English" ? "Consumptions" : translation[872]?.value ? translation[872]?.value : "Consumptions"}
          </h2>
          <LocationSelection />
        </div>
        {loadingPermission ?
          <Shimmer className={'w-32 lg:w-36'}>
            <div className="h-8 w-full  bg-gray-200 rounded-lg" />
          </Shimmer>
          : !employee || (employee && employeePermissions?.inventory_consumption?.includes("create")) ? (
            <AddConsumptionBtn
              translation={translation}
              selectedLanguage={selectedLanguage}
            />
          ) : (
            ""
          )}
      </div>
      <div className="border rounded-lg">
        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Consumption Stock" : translation[1007]?.value ? translation[1007]?.value : "Consumption Stock"}
              </h2>
              {(getLoader || !consumptionUpdated) ?
                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                  <p className="rounded-md h-5 w-12 mx-1"> </p>
                </div>
                :
                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                  <p className="text-primary">{allCount} {" "}
                    {allCount && allCount > 1 ?
                      selectedLanguage?.name == "English" ? "Stock Items" : translation[1008]?.value ? translation[1008]?.value : "Stock Items"
                      : selectedLanguage?.name == "English" ? "Stock Item" : translation[1318]?.value ? translation[1318]?.value : "Stock Item"
                    }
                  </p>
                </div>
              }
            </div>
            <div className="mb-00">
              {loadingPermission ?
                <Shimmer className={'w-44 lg:w-48'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                :
                <LoginInput
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Search product or location" : translation[1273]?.value ? translation[1273]?.value : "Search product or location"}
                  padding="py-1"
                  value={searchText}
                  leftIcon={<Svgs.Search />}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              }
            </div>
          </div>
          <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
            {/* <LoginBtn
              padding="p-3"
              className="rounded-lg border border-primary text-sm"
              bg="bg-primary"
              
            >
              <div className="flex items-center gap-2">
                <Svgs.Filters fill="#fff" />
              </div>
            </LoginBtn> */}
            {loadingPermission ?
              <Shimmer className={'w-12 lg:w-16'}>
                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
              </Shimmer>
              : exportLoader ? (
                <div className="h-[4rem] flex items-center justify-center">
                  <PreloaderSm />
                </div>) :
                !employee ? (
                  dataList?.length > 0 ?
                    <CSVLink {...csvReport} target="_blank" onClick={handleExportClick}>
                      <IconButton filled>
                        <Svgs.Export />
                      </IconButton>
                    </CSVLink> :
                    <LoginBtn
                      className="rounded-lg border-2 border-primary"
                      bg="bg-white"
                      animation={false}
                      onClick={handleExportClick}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.Export bg="#FFFFFF" />
                      </div>
                    </LoginBtn>
                ) : (
                  ""
                )}
          </div>
        </div>
        {(getLoader || !consumptionUpdated) ?
          <TableShimmer_v2 cols={4} rows={12} rowHeight={'h-12'} />
          :
          <div className="overflow-x-auto">
            <div className="inline-grid xl:grid grid-cols-[repeat(4,minmax(155px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
              <p>{selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}</p>
              <p>{selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}</p>
              <p>{selectedLanguage?.name == "English" ? "Consumptions" : translation[872]?.value ? translation[872]?.value : "Consumptions"}</p>
              <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
            </div>
            {/* old filter
          ?.filter((item) => {
              if (
                item?.product?.name
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                item?.location?.address_name
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                item?.quantity?.toString()?.includes(searchText.toString())
              ) {
                return true;
              } else {
                return false;
              }
            }) */}
            {dataList &&
              dataList?.length > 0 &&
              dataList ? (
              dataList?.map((item, index) => {
                return <ConsumptionItem item={item} key={index} />;
              })
            ) : (
              <>
                <NoConsumptionDataFound />
              </>
            )}

            {(dataList?.length > 0 || !getLoader) &&
              <Pagination
                currentPage={currentPage}
                totalCount={Math.ceil(pages)}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
              />
            }
          </div>
        }
      </div>
    </div>
  );
};

export default Consumptions;
