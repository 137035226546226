import { LogoSvg } from "../../../Assets/svgs/Logo/LogoSvg";
import SocialMediaPlatform from "../SocialMediaPlatforms";
import Sweepings from "../Sweepings";
import ChooseSectionSignup from "../../TenantApp/Elements/ChooseSectionSignup";
import ChooseSection from "../../TenantApp/Elements/ChooseSection";
import { useNavigate, useSearchParams, Navigate } from "react-router-dom";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useClientUrlFromCreatedLink from "Hooks/useClientUrlFromCreatedLink";

export default function AccountTypesAndSignupTypes() {
  const navigater = useNavigate();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage()
  const { nexts, hash, token, domain } = useClientUrlFromCreatedLink();
  const [searchParams, setSearchParams] = useSearchParams();
  const account_type = searchParams.get("account_type");

  // const nexts = searchParams.get("next");
  // const hash = searchParams.get("hash");
  // const token = searchParams.get("token");
  // const domain = searchParams.get("domain");

  return (
    <div className="min-h-[100vh] grid grid-cols-1 lg:grid-cols-2 w-full">
      <div className="flex flex-col lg:pt-[3rem] lg:px-[4rem] px-8 py-5">
        <div className="flex items-center justify-between">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigater("/");
            }}
          >
            <LogoSvg />
          </div>
          <p className="text-[#7B7B7B] text-sm lg:text-left text-right">
            Already have an account?&nbsp;
            <span
              className="text-primary cursor-pointer transition-all hover:underline font-semibold"
              onClick={() => {
                if (account_type === "everyone") {
                  if (nexts && hash) {
                    navigater(`/auth/login/?account_type=everyone&next=${nexts}&hash=${hash}&token=${token}&domain=${domain}`);
                  } else {
                    navigater(`/auth/login/?account_type=everyone`);

                  }
                } else {
                  navigater("/auth/login/?account_type=business");
                }
              }}
            >
              Login Now
            </span>
          </p>
        </div>
        <div className="flex-1 flex flex-col justify-start py-[3rem] md:py-[1rem] slide-in-bottom">
          {account_type ? (
            account_type == "business" || account_type == "everyone" ? (
              <ChooseSectionSignup className="slide-in-bottom" />
            ) : (
              <Navigate to="/auth/signup/" />
            )
          ) : (
            <ChooseSection />
          )}
        </div>
      </div>
      <div className="hidden lg:block" style={{
        backgroundImage: 'linear-gradient(to bottom left, rgba(166,77,255,1), rgba(54,67,120,1), rgba(54,67,120,1), rgba(87,120,255,1))'
      }}>
        <div className="px-8 py-5 lg:px-[4rem] lg:pt-[3rem] flex flex-col h-full">
          <div className="flex justify-end text-white"><span className="cursor-pointer" onClick={() => {
                            navigater('/help')
                        }}>Help?</span></div>
          <Sweepings />
        </div>
      </div>
    </div>
  );
}
