import axios from "axios";
import { tenantDetail } from "../../../Hooks/useTenant";
import { update_business_additional_information } from "../api_variables";

const { is_tenant, tenant } = tenantDetail();

const tenant_base_url = `${process.env.REACT_APP_SERVER_BASEURL_START}${tenant}.${process.env.REACT_APP_SERVER_BASEURL_END}`;
const tenant_base_url_without_tenant = `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}`;
const nstyle_base_url = `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}`;

let BASE_URL = is_tenant ? tenant_base_url : nstyle_base_url;



export const getBookingsSettings = async (business_id) => {
    try {
        const result = await axios.get(`${BASE_URL}/business/get_business_booking_settings/?business=${business_id}`);
        return result;
    } catch (error) {
        return error;
    }
}


export const updateBookingsSettings = async (updated_bookingSettings, access_token) => {
    try {
        const result = await axios.put(`${BASE_URL}/business/update_business_booking_settings/`, updated_bookingSettings,
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });
        return result;
    } catch (error) {
        return error;
    }
}

export const SubmitBusinessAdditionalInfo = (data, success, fail) => dispatch => {
    let access_token = localStorage.getItem('access_token')
    let business = localStorage.getItem('business_data')
    if (access_token) {
        access_token = access_token?.replaceAll('"', '')
    }
    let form_data = new FormData()
    for (let dt in data) {
        if (typeof (data[dt]) == 'object') {
            form_data.append(dt, JSON.stringify(data[dt]))
        }
        else {
            if (data[dt]) {
                form_data.append(dt, data[dt])
            }
        }
    }
    fetch(
        BASE_URL + update_business_additional_information,
        {
            method: 'PUT',
            headers: {
                Authorization: `Token ${access_token}`
            },
            body: form_data
        }
    )
        .then(response => {
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (result.status_code == 200) {
                success && success()
            }
            else {
                fail && fail()
            }
        })
        .catch(err => {
            fail && fail()
        })
}
