import { checkRefundPermission, creatRefund } from 'Adapters/Api/refund';
import { getSingleInvoiceDetail } from 'Adapters/Api/saleOrders';
import useObjectKeys from 'Hooks/useObjectKeys';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import useValidations from 'Hooks/useValidations';
import moment from 'moment';
import { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const initialState = {
    refunded_products: [],
    refunded_services: [],
    refund_type: "",
    expiry_date: null,
    total_refund_amount: 0,
}
const useRefund = (setOpenRefundPopup, selectedInvice) => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state);
    const { objectKeyConvertToArray } = useObjectKeys();
    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage()
    const { setErrors, validation, errors } = useValidations();

    const current_location = getDataFromLocalStorage("selected_location");
    const access_token = getDataFromLocalStorage("access_token");
    const business_data = getDataFromLocalStorage("business_data");

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    let selectedLocation = state?.locations?.selected_location || current_location

    const [productList, setProductList] = useState({})
    const [detailLoading, setDetailLoading] = useState(false)
    const [refundState, setRefundState] = useState(initialState)
    const [submitLoader, setSubmitLoader] = useState(false)
    const [showRefundTable, setShowRefundTable] = useState(false)
    const [checkRefundLoading, setCheckRefundLoading] = useState('')

    var minEndDateLimit = useMemo(() => {
        return moment().format('YYYY-MM-DD')
    }, []);

    const onChangeHandler = (e, extra) => {
        const productData = extra?.type == "product" ? extra?.product : {}
        const serviceData = extra?.type == "service" ? extra?.service : {}

        if (e?.target) {
            const singleProduct = extra?.single
            const singleService = extra?.single
            const { name, value } = e.target;
            if (name == "refunded_products") {
                let newErrQty = errors?.refunded_quantity?.filter(itm => itm?.id != productData?.product)
                let newErrPrice = errors?.refunded_amount?.filter(itm => itm?.id != productData?.product)

                if (refundState?.refunded_products?.filter(val => val?.product == productData?.product)?.length == 0) {
                    if (singleProduct?.quantity == 1) {
                        setRefundState((prev) => ({
                            ...prev,
                            refunded_products: [
                                ...prev.refunded_products,
                                {
                                    ...productData,
                                    checkouts: productList?.invoice?.checkout,
                                    refunded_quantity: +singleProduct?.quantity
                                }
                            ]
                        }))
                        setErrors((prev) => ({ ...prev, refunded_services: "", refunded_products: "", refunded_amount: newErrPrice, refunded_quantity: newErrQty }))
                    } else {
                        setRefundState((prev) => ({ ...prev, refunded_products: [...prev.refunded_products, productData] }));
                        setErrors((prev) => ({ ...prev, refunded_services: "", refunded_products: "", refunded_amount: newErrPrice, refunded_quantity: newErrQty }))
                    }
                    return;
                } else {
                    setRefundState({
                        ...refundState,
                        refunded_products: [
                            ...refundState?.refunded_products?.filter(prod => prod?.product != productData?.product)
                        ]
                    })
                    setErrors((prev) => ({ ...prev, refunded_amount: newErrPrice, refunded_quantity: newErrQty }))
                }
            } else if (name == "refunded_amount") {
                if (+value <= (extra?.type == "service" ?
                 (+singleService?.price * (singleProduct?.quantity ? +singleProduct?.quantity : 1))
                  : (singleProduct?.price * singleProduct?.quantity))) {
                    setRefundState((prev) => {
                        let updatedRefundedProducts = prev?.refunded_products
                        let updatedRefundedService = prev?.refunded_services

                        extra?.type == "service"
                            ? prev?.refunded_services?.length > 0 && (
                                // updatedRefundedProducts = prev?.refunded_products,
                                updatedRefundedService = prev?.refunded_services?.map((val) => {
                                    if (val?.service === serviceData?.service) {
                                        return {
                                            ...val,
                                            checkouts: productList?.invoice?.checkout,
                                            refunded_amount: +value,
                                        };
                                    } else {
                                        return val;
                                    }
                                }))
                            : prev?.refunded_products?.length > 0 && (
                                // updatedRefundedService = prev?.refunded_services,
                                updatedRefundedProducts = prev?.refunded_products?.map((val) => {
                                    if (val?.product === productData?.product) {
                                        return {
                                            ...val,
                                            checkouts: productList?.invoice?.checkout,
                                            refunded_amount: +value,
                                        };
                                    } else {
                                        return val;
                                    }
                                }));

                        // Calculate the new sum of refunded_amounttttttttttt
                        const productSum = updatedRefundedProducts?.reduce((total, item) => {
                            return total + Number(item.refunded_amount);
                        }, 0);

                        const serviceSum = updatedRefundedService?.reduce((total, item) => {
                            return total + Number(item.refunded_amount);
                        }, 0);

                        let newProductSum = productSum ? productSum : 0
                        let newServiceSum = serviceSum ? serviceSum : 0
                        const newSum = newProductSum + newServiceSum
                        // Update the state with the new refunded_products and sum
                        return extra?.type == "service"
                            ? {
                                ...prev,
                                refunded_services: updatedRefundedService,
                                total_refund_amount: newSum, // Assuming you have a total_refunded_amount in your state
                            }
                            : {
                                ...prev,
                                refunded_products: updatedRefundedProducts,
                                total_refund_amount: newSum, // Assuming you have a total_refunded_amount in your state
                            }

                    });
                    let filterServiceError = errors?.refunded_service_amount && errors?.refunded_service_amount?.filter(itm => itm?.id != serviceData?.service)
                    let filterProductError = errors?.refunded_amount && errors?.refunded_amount?.filter(itm => itm?.id != productData?.product)
                    // Remove errors for the updated product
                    extra?.type == "service"
                        ? setErrors((prev) => ({ ...prev, refunded_service_amount: filterServiceError }))
                        : setErrors((prev) => ({ ...prev, refunded_amount: filterProductError }));
                    // setErrors((prev) => ({ ...prev, refunded_amount: newErr }));
                    return;

                } else {
                    extra?.type == "service"
                        ? setErrors((prev) => ({
                            ...prev,
                            refunded_service_amount: [
                                ...(prev?.refunded_service_amount || []), {
                                    text: `Refunded price not be greater then ${singleService?.price}`,
                                    id: serviceData?.service,
                                    appServiceId: serviceData?.id
                                }]
                        }))
                        : setErrors((prev) => ({
                            ...prev,
                            refunded_amount: [
                                ...(prev?.refunded_amount || []), {
                                    text: `Refunded price not be greater then ${singleProduct?.price * singleProduct?.quantity}`,
                                    id: productData?.product,
                                }],
                            // refunded_service_amount: [
                            //     ...(prev?.refunded_service_amount || []), {
                            //         text: `Refunded price not be greater then ${singleService?.price}`,
                            //         id: serviceData?.service
                            //     }]
                        }))

                    return
                }
            } else if (name == "refunded_quantity") {
                if (+value <= singleProduct?.quantity) {
                    setRefundState((prev) => ({
                        ...prev,
                        refunded_products: refundState?.refunded_products?.map(val => {
                            if (val?.product == productData?.product) {
                                return {
                                    ...val,
                                    refunded_quantity: +value
                                }
                            } else {
                                return val;
                            }
                        })
                    }))
                    let newErr = errors?.refunded_quantity?.filter(itm => itm?.id != productData?.product)
                    setErrors((prev) => ({ ...prev, refunded_quantity: newErr }))
                    return;
                } else {
                    setErrors((prev) => ({
                        ...prev,
                        refunded_quantity: [
                            ...(prev?.refunded_quantity || []), {
                                text: `Product refunded quantity not be greater ${singleProduct?.quantity}`,
                                id: productData?.product
                            }]
                    }))
                    return
                }
            } else if (name == "in_stock") {
                setRefundState((prev) => ({
                    ...prev,
                    refunded_products: refundState?.refunded_products?.map(val => {
                        if (val?.product == productData?.product) {
                            return {
                                ...val,
                                in_stock: !val?.in_stock
                            }
                        } else {
                            return val;
                        }
                    })
                }))
                return;
            } else if (name == "refunded_services") {
                let newErrPrice = errors?.refunded_amount && errors?.refunded_amount?.filter(itm => itm?.id != serviceData?.service)
                if (serviceData?.id) {
                    if (refundState?.refunded_services?.filter(val => val?.service == serviceData?.service && val?.id == serviceData?.id)?.length == 0) {
                        setRefundState((prev) => ({ ...prev, refunded_services: [...prev.refunded_services, serviceData] }));
                        setErrors((prev) => ({ ...prev, refunded_services: "", refunded_products: '', refunded_amount: newErrPrice }))
                        return;
                    } else {
                        let filteredData = refundState?.refunded_services?.filter(prod => prod?.service != serviceData?.service && prod?.id != serviceData?.id)
                        setRefundState({
                            ...refundState,
                            refunded_services: filteredData
                        })
                        setErrors((prev) => ({ ...prev, refunded_amount: newErrPrice, }))
                    }
                } else {
                    if (refundState?.refunded_services?.filter(val => val?.service == serviceData?.service)?.length == 0) {
                        setRefundState((prev) => ({ ...prev, refunded_services: [...prev.refunded_services, serviceData] }));
                        setErrors((prev) => ({ ...prev, refunded_services: "", refunded_products: '', refunded_amount: newErrPrice }))
                        return;
                    } else {
                        let filteredData = refundState?.refunded_services?.filter(prod => prod?.service != serviceData?.service)
                        setRefundState({
                            ...refundState,
                            refunded_services: filteredData
                        })
                        setErrors((prev) => ({ ...prev, refunded_amount: newErrPrice, }))
                    }
                }
            } else {
                setRefundState((prev) => ({ ...prev, [name]: value }));
                setErrors((prev) => ({ ...prev, [name]: "" }))
            }
        }
    }

    const handleNextClick = () => {
        const { refund_type } = refundState
        setRefundState({
            ...initialState,
            refund_type: refund_type
        })
        let requiredFields = { refund_type }
        setErrors(validation(requiredFields));
        const result = validation(requiredFields)
        if (objectKeyConvertToArray(result)?.length === 0) {
            setShowRefundTable(true)
        }
    }

    const handleBackClick = () => {
        const { refund_type } = refundState
        let requiredFields = { refund_type }
        setErrors(validation(requiredFields));
        const result = validation(requiredFields)
        if (objectKeyConvertToArray(result)?.length === 0) {
            setShowRefundTable(false)
        }
    }


    const handleSubmitClick = () => {
        const { refund_type, expiry_date, refunded_products, refunded_services } = refundState;

        let requiredFields = {}
        if (refunded_products?.length == 0 || refunded_services?.length == 0) {
            // if (refunded_services?.length == 0) {
            if (refund_type == "Credit Refund") {
                if (refunded_products?.length == 0 && refunded_services?.length == 0) {
                    requiredFields = { expiry_date, refunded_products }
                } else {
                    requiredFields = { expiry_date }
                }
            } else {
                if (refunded_products?.length == 0 && refunded_services?.length == 0) {
                    requiredFields = { refunded_products }
                }
                else {
                    requiredFields = {}
                }
            }
            // }
        } else {
            if (refund_type == "Credit Refund")
                requiredFields = { expiry_date }
            else
                requiredFields = {}
        }
        let result = {}

        // if (refunded_products?.length > 0 || refunded_services?.length > 0) {
        //     if (refunded_products?.length > 0) {
        //         setErrors(validation(requiredFields, "", { product: refunded_products }));
        //         result = validation(requiredFields, "", { product: refunded_products });
        //     } else {
        //         setErrors(validation(requiredFields, "", { service: refunded_services }));
        //         result = validation(requiredFields, "", { service: refunded_services });
        //     }
        // }

        let productsData = refunded_products?.map((itm) => ({
            ...itm,
            checkouts: selectedInvice?.invoice?.checkout
        }))

        // let servicesData = refunded_services?.map((itm) => ({
        //     ...itm,
        //     checkouts: selectedInvice?.invoice?.checkout
        // }))
        let servicesData = refunded_services?.map((itm) => {
            if (itm?.client) {
                return {
                    ...itm,
                    checkouts: selectedInvice?.invoice?.checkout
                };
            } else {
                // If itm doesn't have a client, delete the client property
                const { client, ...newObj } = itm;
                return {
                    ...newObj,
                    checkouts: selectedInvice?.invoice?.checkout
                };
            }
        });

        setErrors({
            ...errors,
            ...validation(
                requiredFields,
                "",
                {
                    product: refunded_products?.length > 0 ? refunded_products : [],
                    service: refunded_services?.length > 0 ? refunded_services : []
                })
        });
        result = validation(
            requiredFields,
            "",
            {
                product: refunded_products?.length > 0 ? refunded_products : [],
                service: refunded_services?.length > 0 ? refunded_services : []
            });
        // console.log(selectedInvice, refundState, "sss")
        if (objectKeyConvertToArray(result)?.length === 0 && (!errors?.refunded_amount || !errors?.refunded_service_amount || errors?.refunded_service_amount?.length == 0 || errors?.refunded_amount?.length == 0)) {
            let payload = {
                // ...refundState,
                business: business_data?.id,
                client: selectedInvice?.client,
                checkout: selectedInvice?.invoice?.checkout,
                location: selectedInvice?.invoice?.location,
                refund_invoice_id: selectedInvice?.invoice?.id,
                client_type: selectedInvice?.client_type,
                refund_type: refund_type,
                checkout_type: selectedInvice?.checkout_type,
                expiry_date: refundState?.expiry_date,
                refunded_products: productsData,
                refunded_services: servicesData,
                total_refund_amount: refundState?.total_refund_amount,
                // total_voucher_price: productList?.invoice?.voucher_total_price,
                // total_service_price: productList?.invoice?.service_total_price,
                // total_product_price: productList?.invoice?.product_total_price,
                // service_commission_type: productList?.invoice?.service_commission_type,
                // product_commission_type: productList?.invoice?.product_commission_type,
                // voucher_commission_type: productList?.invoice?.voucher_commission_type,
            }

            const success = (data) => {

                storeDataInLocalStorage("RefundDone", true)
                storeDataInLocalStorage("checkoutId", data?.invoice?.checkout)
                setRefundState(initialState)
                setOpenRefundPopup(false)
                // setIsEdit(false)
                setSubmitLoader(false)
            }
            const fail = () => {
                setSubmitLoader(false)
            }

            setSubmitLoader(true)
            dispatch(creatRefund(payload, access_token, success, fail))
        }
    }

    const checkRefundAvailablity = (invoice_id, checkout_id) => {
        setCheckRefundLoading(invoice_id)
        const success = () => {
            setOpenRefundPopup && setOpenRefundPopup(true)
            setDetailLoading(false)
            setCheckRefundLoading("")
            setProductList(selectedInvice);
            // dispatch(getSingleInvoiceDetail(
            //     access_token, checkout_id, selectedLocation, setDetailLoading,
            //     (result) => {
            //         console.log("🚀 ~ success ~ result:", result)
            //     },
            //     (err) => {
            //         setDetailLoading(false)
            //         console.log("🚀 ~ success ~ err:", err)
            //     }
            // ));
        }
        const fail = () => {
            setCheckRefundLoading("")
        }

        let payload = {
            invoice_id: invoice_id,
            location: selectedLocation,
            business: business_data?.id
        }
        dispatch(checkRefundPermission(payload, access_token, success, fail))
    }


    return {
        translation, selectedLanguage,
        refundState, errors, detailLoading,
        showRefundTable, setShowRefundTable,
        productList, minEndDateLimit, handleSubmitClick,
        onChangeHandler, handleNextClick, handleBackClick,
        submitLoader, setProductList,
        checkRefundLoading, checkRefundAvailablity
    }
}

export default useRefund