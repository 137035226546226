import React from 'react'
import Popup from "Components/TenantApp/Elements/Popup";
import moment from 'moment';
// import useDetailCompaignPopup from './helper';

const DetailHoliday = ({ data, ViewDetails, selectedLanguage, translation, setViewDetails }) => {

    return (
        <Popup
            size={"md"}
            heading={selectedLanguage?.name == "English" ? "Holiday Detail" : translation[1491]?.value ? translation[1491]?.value : "Holiday Detail"}
            open={ViewDetails}
            close={setViewDetails}
        >
            <div className="space-y-5">
                <div className="cursor-pointer h-full flex items-center gap-3">
                    <h5 className="text-[18px] font-semibold text-primary capitalize">{data?.title}</h5>
                </div>
                <div className="flex flex-col gap-10">
                    <div className="flex flex-col gap-1">
                        <h5 className=" text-[16px] font-medium">{selectedLanguage?.name == "English" ? "Holiday Name" : translation[1475]?.value ? translation[1475]?.value : "Holiday Name"}</h5>
                        <p className="text-[14px] font-normal">{data?.name}</p>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h5 className=" text-[16px] font-medium">
                            {selectedLanguage?.name == "English" ? "From Date" : translation[635]?.value ? translation[635]?.value : "From Date"}
                        </h5>
                        <div className="flex gap-2">
                            <p className="text-[14px] font-normal">{moment(data?.start_date).format('YYYY-MM-DD')}</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h5 className=" text-[16px] font-medium">
                            {selectedLanguage?.name == "English" ? "To Date" : translation[636]?.value ? translation[636]?.value : "To Date"}
                        </h5>
                        <div className="flex gap-2">
                            <p className="text-[14px] font-normal">{moment(data?.end_date).format('YYYY-MM-DD')}</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1">
                        <h5 className=" text-[16px] font-medium">
                            {selectedLanguage?.name == "English" ? "Note" : translation[536]?.value ? translation[536]?.value : "Note"}
                        </h5>
                        <p className="text-[14px] font-normal">{data?.note}</p>
                    </div>
                </div>
            </div>
        </Popup>
    )
}

export default DetailHoliday