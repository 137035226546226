import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useLocation } from "new-components/location/helper";

export const useAddStoreTarget = () => {

    const { locationDetail } = useLocation()
    const state = useSelector((state) => state);


    return {
        locationDetail
    }
}
