import { Provider } from "react-redux";

import NstyleStore from "Redux/NstyleRedux/store";
import TenantStore from "Redux/TenantRedux/store";

import { useTenant } from "Hooks/useTenant";

const StoreWrapper = ({ children }) => {
  const { is_tenant } = useTenant();
  return (
    <Provider store={is_tenant ? TenantStore : NstyleStore}>
      {children}
    </Provider>
  );
};

export default StoreWrapper;
