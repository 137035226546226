const { SEGMENTS } = require("Redux/TenantRedux/ActionTypes/segmentTypes");

const initialState = {
    segments: [],
    segmentPagination: {},

    segmentDropdown: [],
    segmentDropdownWithoutSearch: [],
    segmentDropdownPagination: {},

};

const SegmentReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SEGMENTS.GET_SEGMENTS:
            return {
                ...state,
                segments: payload?.response?.data,
                segmentPagination: {
                    pages: Math.ceil(+payload?.total_pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page,
                    isSearched: payload?.is_search
                }
            };

        case SEGMENTS.ADD_SEGMENTS:
            return {
                ...state,
                segments: [payload, ...state.segments],
                segmentPagination: {
                    ...state?.segmentPagination,
                    allCount: state.segmentPagination?.allCount + 1
                }
            };

        case SEGMENTS.DELETE_SEGMENTS:
            let result = state?.segments?.filter((item) => item.id !== payload);
            return {
                ...state,
                segments: [...result],
                segmentPagination: {
                    ...state?.segmentPagination,
                    allCount: state.segmentPagination?.allCount - 1
                }
            };

        case SEGMENTS.UPDATE_SEGMENTS:
            let updateList = state.segments.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })
            return {
                ...state,
                segments: [...updateList],
            };

        case SEGMENTS.GET_SEGMENTS_DROPDOWN_PAGINATION:
            const uniqueSegmentsWithSearch = Array.from(
                new Set([...state?.segmentDropdown, ...payload?.response?.data].map(client => client.id))).map(id => {
                    return [...state?.segmentDropdown, ...payload?.response?.data].find(client => client.id === id);
                });

            const uniqueSegmentsWithoutSearch = Array.from(
                new Set([...state?.segmentDropdownWithoutSearch, ...payload?.response?.data]?.map(client => client.id))).map(id => {
                    return [...state?.segmentDropdownWithoutSearch, ...payload?.response?.data]?.find(client => client.id === id);
                });

            return {
                ...state,
                segmentDropdown: payload?.is_search
                    ? (Math.ceil(+payload?.total_pages) > 1 && +payload?.current_page > 1)
                        ? uniqueSegmentsWithSearch
                        : payload?.response?.data
                    : uniqueSegmentsWithoutSearch,

                segmentDropdownWithoutSearch: payload?.current_page == 1 ? payload?.response?.data
                    : uniqueSegmentsWithoutSearch,

                segmentDropdownPagination: {
                    pages: Math.ceil(+payload?.total_pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page,
                    isSearched: payload?.is_search
                }
            };

        default:
            return state;
    }

}
export default SegmentReducer