import Svgs from 'Assets/svgs'
import Header from 'Components/NstyleApp/Wrappers/Header'
import React from 'react'
import useHelpCenter from '../helper'

const TopSection = () => {
    const { searchText, onSearchChangeHandler, getHelpCenterData } = useHelpCenter()

    return (
        <div className='bg-[#EFF5FD]'>
            <Header noshadow />
            <div className='container mx-auto mt-[2rem]'>
                <div className='flex items-center justify-center gap-12 flex-wrap-reverse'>
                    <div>
                        <Svgs.HelpHeader />
                    </div>
                    <div>
                        <div className='flex flex-col gap-2'>
                            <h1 className='text-[#101928] font-semibold text-[2rem]'>Hi, how can we help?</h1>
                            <div>
                                <div className='flex'>
                                    <div className='border rounded-l-md px-4 bg-white flex gap-2 py-2 w-[24rem]'>
                                        <div className='flex items-center'>
                                            <svg width="1.3125rem" height="1.3125rem" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="9.76981" cy="9.7659" r="8.98856" stroke="#A9B3C5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M16.0234 16.4844L19.5475 19.9992" stroke="#A9B3C5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                        <div className='w-full'>
                                            <input type="text" value={searchText} className='placeholder:text-[#000000]/40 outline-none border-none text-sm w-full' placeholder='Search' onChange={onSearchChangeHandler} />
                                        </div>
                                    </div>
                                    <div className='bg-[#5679FF] px-5 flex items-center text-white text-sm rounded-r-md cursor-pointer' onClick={getHelpCenterData}>Search</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopSection