import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const FontFamilyWrapper = ({ children }) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    let font = localStorage.getItem("NstyleFont");
    dispatch({
      type: "CHANGE_FONT",
      payload: font,
    });
  }, []);

  useEffect(() => {
    if (state.utility.theme?.font_family) {
      localStorage.setItem("NstyleFont", state.utility.theme?.font_family);
    }
  }, [state.utility.theme?.font_family]);

  // console.log(state?.utility.theme?.nstyle?.primary?.bg)

  useEffect(() => {
    let tag = document.querySelector('.style_head');
    let Bg_Primary = state?.utility?.theme?.nstyle?.primary?.bg.primary ? state?.utility?.theme?.nstyle?.primary?.bg.primary : '#5679ff';
    let Bg_Secondary = state?.utility?.theme?.nstyle?.primary?.bg.secondary ? state?.utility?.theme?.nstyle?.primary?.bg.secondary : '#101928';

    tag.innerHTML = `

      :root{
        --primary:${Bg_Primary} !important;
        --secondary:${Bg_Secondary} !important;
      }
        body,
        .text-primary{
          color:${Bg_Secondary} !important;
          --bg-primary:${Bg_Secondary} !important;
        }

        .fill-primary{
          fill:${Bg_Primary} !important;
        }

        .bg-primary{
          background-color:${Bg_Primary} !important;
        }

        .border-primary{
          border-color:${Bg_Primary} !important;
        }

        .border-primary:focus{
          border-color:${Bg_Primary} !important;
        }

        [fill="#101928"],[fill="#3F3F44"]{
          fill:${Bg_Secondary} !important;
        }

        [stroke="#101928"]{
          stroke:${Bg_Secondary} !important;
        }

        [stroke="#5679FF"]{
          stroke:${Bg_Primary} !important;
        }

        [fill="#5679FF"]{
          fill:${Bg_Secondary} !important;
        }

        ::-webkit-scrollbar-thumb {
          background: ${Bg_Secondary} !important;
        }
    `;

  }, [state?.utility.theme?.nstyle])


  return (
    <>
      <style>
        {`

          body{
              font-family:'${state.utility.theme?.font_family
            ? state.utility.theme?.font_family
            : "Inter"
          }', sans-serif !important;
          }

        `}
      </style>
      {children}
    </>
  );
};

export default FontFamilyWrapper;
