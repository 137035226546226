import React from "react";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Svgs from "Assets/svgs";
import Pagination from "new-components/Pagination";
import NoAppointmentRecordFound from "Components/Utility/NoAppointmentRecordFound";
import moment from "moment";
import useReversalRequestHelper from "./helper";
import DateRangePicker from "Components/TenantApp/Elements/DateRangePicker";
import TableShimmer_v2 from "Components/Utility/Shimmers/TableShimmer_v2";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import Popup from "../../../../Elements/Popup";

const ReversalRequest = () => {
    const { selectedLanguage, translation, loader, allCount, toggleDateRange, setToggleDateRange, startDate,
        setStartDate, endDate, setEndDate, setSearch, Search, all_reversal, currentPage, count, reversalSubmitClick,
        PageSize, setCurrentPage, ShowDetails, setShowDetails, acceptPopup, setAcceptPopup, statusLoader,
        loadingPermission, setAcceptPopupData, acceptPopupData, AcceptStatusOptions, updateAppointmentReversalStatus,
        employee, employeePermissions
    } = useReversalRequestHelper()

    return (
        <>
            <div className="">
                <div className="">
                    <div className="border rounded-md">
                        <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
                            <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-1 gap-2">
                                <div className="flex items-center sm:gap-4 gap-2 ">
                                    <h2 className="font-semibold sm:text-xl text-lg whitespace-nowrap">
                                        {selectedLanguage?.name == "English" ? "Reversal Requests" : translation[1561]?.value ? translation[1561]?.value : "Reversal Requests"}
                                    </h2>

                                    {loader ?
                                        <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                            <p className="rounded-md h-5 w-16 mx-1"> </p>
                                        </div>
                                        :
                                        <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                            <p className="text-primary">{allCount} Request</p>
                                        </div>
                                    }
                                </div>

                                {loadingPermission ?
                                    <EmployeesHeadShimmer classOn1={'!w-24 lg:!w-32 sm:!w-28'} classOn2={'!w-28 lg:!w-36 sm:!w-32'} classOn3={'w-20 lg:!w-24'} has3Itm />
                                    :
                                    <div className="mb-00 flex items-center gap-2">
                                        {/* <div className="mb-00">
                                            <Dropwdown
                                                title=""
                                                value={dropdownStatus}
                                                onChange={(e) => setDropdownStatus(e.target.value)}
                                                showTitle={false}
                                                placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[1433]?.value ? translation[1433]?.value : "Select Status"}
                                                options={StatusOptions}
                                            />
                                        </div> */}
                                        <LoginInput
                                            title=""
                                            placeholder={selectedLanguage?.name == "English" ? "Search Appointment" : translation[1269]?.value ? translation[1269]?.value : "Search Appointment"}
                                            padding="py-1"
                                            leftIcon={<Svgs.Search />}
                                            onChange={(e) => { setSearch(e.target.value) }}
                                            value={Search}
                                            name="firstName"
                                        />
                                    </div>
                                }
                            </div>
                            <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">
                                <div className='flex items-center gap-3'>
                                    <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                                        <div className='p-2 relative gap-4 flex items-center'>
                                            <p onClick={() => {
                                                setToggleDateRange(!toggleDateRange)
                                            }} className='cursor-pointer'>
                                                {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/----"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/----"}`}
                                            </p>
                                            <span className='cursor-pointer' onClick={() => {
                                                setStartDate()
                                                setEndDate()
                                            }}>Clear</span>

                                            {
                                                toggleDateRange && <>
                                                    <div className='fixed inset-0 z-1' onClick={() => { setToggleDateRange(false) }}></div>
                                                    <div className='absolute top-full right-0 z-[2]'>
                                                        <DateRangePicker
                                                            start={startDate}
                                                            end={endDate}
                                                            setStartDate={setStartDate}
                                                            setEndDate={setEndDate}
                                                            setToggleDateRange={setToggleDateRange}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="py-3">
                            {loader ?
                                <TableShimmer_v2 cols={7} rowHeight={'h-9'} />
                                :
                                <div className="overflow-x-auto">
                                    <div className={`inline-grid xl:grid ${!employee ||
                                        (employee &&
                                            employeePermissions?.calender_appointment?.includes("modify")) ? "grid-cols-[repeat(6,minmax(115px,1fr))]" : "grid-cols-[repeat(5,minmax(115px,1fr))]"} px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit`}>
                                        <p>{selectedLanguage?.name == "English" ? "Appointment ID" : translation[1344]?.value ? translation[1344]?.value : "Appointment ID"} #</p>
                                        <p>{selectedLanguage?.name == "English" ? "Appointment Date" : translation[1345]?.value ? translation[1345]?.value : "Appointment Date"}</p>
                                        <p>{selectedLanguage?.name == "English" ? "Client Type" : translation[330]?.value ? translation[330]?.value : "Client Type"}</p>
                                        <p>{selectedLanguage?.name == "English" ? "Client Name" : translation[474]?.value ? translation[474]?.value : "Client Name"}</p>
                                        <p>{selectedLanguage?.name == "English" ? "Generated By" : translation[1564]?.value ? translation[1564]?.value : "Generated By"}</p>
                                        {(!employee ||
                                            (employee &&
                                                employeePermissions?.calender_appointment?.includes("modify"))) && <p className="text-center">{selectedLanguage?.name == "English" ? "Action" : translation[1366]?.value ? translation[1366]?.value : "Action"}</p>}
                                    </div>
                                    <div
                                        className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
                                    >
                                        {all_reversal?.length > 0 ? (
                                            all_reversal?.map((item, index) => {
                                                return (
                                                    <>
                                                        <div key={index} className={`px-12 py-3 inline-grid xl:grid ${!employee ||
                                                            (employee &&
                                                                employeePermissions?.calender_appointment?.includes("modify")) ? "grid-cols-[repeat(6,minmax(115px,1fr))]" : "grid-cols-[repeat(5,minmax(115px,1fr))]"} min-w-full w-fit`}>
                                                            <div className={`before-left-arrow cursor-pointer`}>
                                                                <div className="h-full flex items-center">
                                                                    <p
                                                                    // className={`before-left-arrow cursor-pointer ${ShowDetails == item.id
                                                                    //     ? "before-left-arrow-open"
                                                                    //     : "before-left-arrow-close"
                                                                    //     }`}
                                                                    // onClick={() => {
                                                                    //     setShowDetails(ShowDetails != item.id ? item.id : undefined);
                                                                    // }}
                                                                    >
                                                                        #{item?.appointment_services ? item?.appointment_services?.id?.split('-')[0] : ''}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <p className="text-sm line-clamp-2">
                                                                    {item?.appointment_date ? moment(item?.appointment_date).format('DD-MM-YYYY') : ""}
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <p className="text-sm line-clamp-1 pr-3">
                                                                    {item?.client_type ? item?.client_type : "Walkin"}
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <p className="text-sm">
                                                                    {item?.client_name ? item?.client_name : "----"}
                                                                </p>
                                                            </div>
                                                            <div className="flex items-center">
                                                                <p className="text-sm pr-2">
                                                                    {item?.generated_by ? item?.generated_by : "----"}
                                                                </p>
                                                            </div>

                                                            {!employee ||
                                                                (employee &&
                                                                    employeePermissions?.calender_appointment?.includes("modify")) ? (
                                                                <div className="flex items-center justify-center gap-3">
                                                                    {item?.request_status == "pending" || item?.request_status == "accepted" ?
                                                                        <LoginBtn
                                                                            // successBtnFocus={obj?.vacation_status == "accepted" ? true : false}
                                                                            className={'!capitalize'}
                                                                            successBtn={item?.request_status == "pending" ? true : false}
                                                                            newSuccessBtnFocus={item?.request_status == "accepted" ? true : false}
                                                                            text={selectedLanguage?.name == "English" ? `${item?.request_status == "pending" ? 'Accept' : 'Accepted'}` : translation[1492]?.value ? translation[1492]?.value : `${item?.request_status == "pending" ? 'Accept' : 'Accepted'}`}
                                                                            onClick={() => {
                                                                                setAcceptPopup(true)
                                                                                setAcceptPopupData({
                                                                                    appointment_id: item?.appointment,
                                                                                    service_id: item?.appointment_services?.id
                                                                                })
                                                                            }}
                                                                            disabled={item?.request_status != "pending"}
                                                                        // loading={statusLoader && statusLoader?.accepted && statusLoader?.id == obj?.id}
                                                                        />
                                                                        : <></>
                                                                    }
                                                                    {item?.request_status == "pending" || item?.request_status == "rejected" ?
                                                                        <LoginBtn
                                                                            className={'!capitalize'}
                                                                            deleteBtn={item?.request_status == "pending" ? true : false}
                                                                            newDeleteBtnFocus={item?.request_status == "rejected" ? true : false}
                                                                            text={selectedLanguage?.name == "English" ? `${item?.request_status == "pending" ? 'Reject' : 'Rejected'}` : translation[1493]?.value ? translation[1493]?.value : `${item?.request_status == "pending" ? 'Reject' : 'Rejected'}`}
                                                                            onClick={() => {
                                                                                updateAppointmentReversalStatus(item?.id, item?.appointment_services?.id, item?.appointment)
                                                                            }}
                                                                            disabled={(statusLoader && statusLoader?.id == item?.id &&
                                                                                statusLoader?.delete_loader) || item?.request_status != "pending"}
                                                                            loading={statusLoader && statusLoader?.delete_loader && statusLoader?.id == item?.id}
                                                                        />
                                                                        :
                                                                        <></>
                                                                    }
                                                                </div>) : ""
                                                            }
                                                        </div>
                                                    </>
                                                );
                                            })
                                        ) : (
                                            <NoAppointmentRecordFound />
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                        {(all_reversal?.length > 0 && !loader) &&
                            <Pagination
                                currentPage={currentPage}
                                totalCount={Math.ceil(count)}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        }
                    </div>
                </div>
            </div>

            {acceptPopup && (
                <Popup
                    heading={selectedLanguage?.name == "English"
                        ? "Request Reversal"
                        : translation[1559]?.value
                            ? translation[1559]?.value
                            : "Request Reversal"}
                    onclose={() => {
                        setAcceptPopup({
                            appointment_id: "",
                            appointment_service: "",
                            appointment_status: "",
                            reversal_status: "accepted"
                        })
                        setAcceptPopup(false)
                    }}
                    open={acceptPopup}
                >
                    <div className="">
                        <div className="flex flex-col gap-4">
                            <div className="mb-00">
                                <Dropwdown
                                    required
                                    title={selectedLanguage?.name == "English" ? "Select Status" : translation[1433]?.value ? translation[1433]?.value : "Select Status"}
                                    value={acceptPopupData?.appointment_status}
                                    onChange={(e) => setAcceptPopupData((prev) => ({
                                        ...prev,
                                        appointment_status: e.target.value
                                    }))}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[1433]?.value ? translation[1433]?.value : "Select Status"}
                                    options={AcceptStatusOptions}
                                    error={reversalSubmitClick && !acceptPopupData?.appointment_status}
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-end mt-[2rem]">
                            <LoginBtn
                                text={selectedLanguage?.name == "English" ? "Apply" : translation[1421]?.value ? translation[1421]?.value : "Apply"}
                                onClick={() => {
                                    updateAppointmentReversalStatus()
                                }}
                                className={`!px-7 bg-primary !text-white`}
                                customThemeBtn={'custom-theme-btn-reschedule'}
                                loading={statusLoader && statusLoader?.accept_loader}
                                disabled={statusLoader && statusLoader?.accept_loader}
                            />
                        </div>
                    </div>
                </Popup>
            )}
        </>
    );
};

export default ReversalRequest;
