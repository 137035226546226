

export const CALENDAR_DATA = [
    {
        employee: {
            name: 'Huzaifa',
            id: '2384723n8y8nxyb32983yx983y',
            image: 'random url'
        },
        appointments: [
            {
                id: 'appointmen id',
                appointment_date: '17-09-2022', // 9:30 AM
                appointment_time: '09:30:00', // 9:30 AM, Start Time
                duration: '30 Mins',
                end_time: '10:00:00', // Will be calculate on =>>> end_time = (appointment_time + duration) 
                price: 223,
                currency: 'AED',
                client_type: 'InHouse',
                service: {
                    name: 'Hair Cut',
                    id: 'serviceidhere'
                },
                member: {
                    name: 'Member Name',
                    id: 'member id',
                },

            }
        ]
    }, {
        employee: {
            name: 'Humayun',
            id: '2384723n8y8nxyb32983yx12983y',
            image: 'random url'
        },
        appointments: [
            {
                id: 'appointmen id',
                appointment_date: '17-09-2022', // 9:30 AM
                appointment_time: '10:30:00', // 10:30 AM, Start Time
                duration: '30 Mins',
                end_time: '11:00:00', // Will be calculate on =>>> end_time = (appointment_time + duration) 
                price: 223,
                currency: 'AED',
                client_type: 'InHouse',
                service: {
                    name: 'Hair Cut',
                    id: 'serviceidhere'
                },
                member: {
                    name: 'Member Name',
                    id: 'member id',
                },

            }
        ]
    }, {
        employee: {
            name: 'Humayun',
            id: '2384723n8y8nxyb32983yx12983y',
            image: 'random url'
        },
        appointments: [
            {
                id: 'appointmen id',
                appointment_date: '17-09-2022', // 9:30 AM
                appointment_time: '10:30:00', // 10:30 AM, Start Time
                duration: '30 Mins',
                end_time: '11:00:00', // Will be calculate on =>>> end_time = (appointment_time + duration) 
                price: 223,
                currency: 'AED',
                client_type: 'InHouse',
                service: {
                    name: 'Hair Cut',
                    id: 'serviceidhere'
                },
                member: {
                    name: 'Member Name',
                    id: 'member id',
                },

            }
        ]
    }, {
        employee: {
            name: 'Humayun',
            id: '2384723n8y8nxyb32983yx12983y',
            image: 'random url'
        },
        appointments: [
            {
                id: 'appointmen id',
                appointment_date: '17-09-2022', // 9:30 AM
                appointment_time: '10:30:00', // 10:30 AM, Start Time
                duration: '1 Hour',
                end_time: '11:30:00', // Will be calculate on =>>> end_time = (appointment_time + duration) 
                price: 223,
                currency: 'AED',
                client_type: 'InHouse',
                service: {
                    name: 'Hair Cut',
                    id: 'serviceidhere'
                },
                member: {
                    name: 'Member Name',
                    id: 'member id',
                },

            }
        ]
    }

]

export const CALENDAR_DATA_UPDTAED = [
    {
        employee: {
            name: 'Huzaifa',
            id: '2384723n8y8nxyb32983yx983y',
            image: 'random url'
        },
        appointments: [
            [
                {
                    appointment_date: '17-09-2022', // 9:30 AM
                    appointment_time: '09:30:00', // 9:30 AM, Start Time
                    end_time: '10:00:00', // Will be calculate on =>>> end_time = (appointment_time + duration) 
                    service: {
                        name: 'Hair Cut',
                        id: 'serviceidhere'
                    },
                    member: {
                        name: 'Member Name',
                        id: 'member id',
                    },
                },
                {
                    appointment_date: '17-09-2022', // 9:30 AM
                    appointment_time: '09:30:00', // 9:30 AM, Start Time
                    end_time: '10:00:00', // Will be calculate on =>>> end_time = (appointment_time + duration) 
                    service: {
                        name: 'Hair Cut',
                        id: 'serviceidhere'
                    },
                    member: {
                        name: 'Member Name',
                        id: 'member id',
                    },
                },
            ],
            [
                {
                    appointment_date: '17-09-2022', // 9:30 AM
                    appointment_time: '10:30:00', // 9:30 AM, Start Time
                    end_time: '10:00:00', // Will be calculate on =>>> end_time = (appointment_time + duration) 
                    service: {
                        name: 'Hair Cut',
                        id: 'serviceidhere'
                    },
                    member: {
                        name: 'Member Name',
                        id: 'member id',
                    },
                }
            ]
        ]
    }

]

export const graphScheleton = [
    {
        key: '',
        data: 4
    },
    {
        key: '',
        data: 5
    },
    {
        key: '',
        data: 6
    },
    {
        key: '',
        data: 7
    },
    {
        key: '',
        data: 9
    },
    {
        key: '',
        data: 12
    },
]