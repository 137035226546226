import { BUSINESS_SETTING } from "../../../TenantRedux/ActionTypes/businessSettingTypes";
import { SIGNUP_USER , CLIENT_SIGNUP} from "../../ActionTypes/signupTypes";

const userData = {
  clientInfo:{},
  personalInfo: {},
  businessDetails: {},
  businessType: [],
  teamSize: "",
  softwareUsing: [],
  OpeningHours: [],
  findUs: "",
  business_types: [],
  loggedIn : false
}

const SignupReducer = (state = userData, action) => {

  const { type, payload } = action;


  switch (type) {
    case 'BUSINESS_CLIENT_LOGGEDIN':
      return {
        ...state,
        loggedIn : true
      }
    case BUSINESS_SETTING.GET_BUSINESS_TYPES:
      return {
        ...state,
        business_types: payload
      }
      
    case SIGNUP_USER.PERSONAL_INFO:
      return {
        ...state,
        personalInfo: payload,
      };

    case SIGNUP_USER.BUSINESS_DETAILS:
      return {
        ...state,
        businessDetails: payload,
      };

    case SIGNUP_USER.BUSINESS_TYPE:
      return {
        ...state,
        businessType: payload,
      };

    case SIGNUP_USER.TEAM_SIZE:
      return {
        ...state,
        teamSize: payload?.teamSize,
      };

    case SIGNUP_USER.SOFTWARE_USING:
      return {
        ...state,
        softwareUsing: payload,
      };

    case SIGNUP_USER.FIND_US:
      return {
        ...state,
        findUs: payload,
      };

    case SIGNUP_USER.OPENINIG_HOURS:
      return {
        ...state,
        OpeningHours: payload,
      };
      case CLIENT_SIGNUP.CLIENT_INFO:
        return {
          ...state,
          clientInfo: payload,
          loggedIn : true
        };
    default:
      return state;
  }

};

export default SignupReducer;