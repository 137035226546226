import axios from "axios";
import { apiErrorMessage } from "Constants/Data/Errors";
import { toast } from "react-toastify";
import { get_StaffTarget_report, get_StaffTarget_report_without_pagination } from "Redux/TenantRedux/Actions/staffTarget";

import {
  BASE_URL,
  add_staff_target_url,
  get_staff_target_url,
  delete_staff_target_url,
  update_staff_target_url,
  copy_staff_target_url,
  get_staff_target_report_url,
} from "../api_variables";

export const getStaffTarget = async (selected_location, currentPage, searchText, searchMonth, searchYear, access_token) => {
  const location = selected_location ? `?location_id=${selected_location}` : ''
  const current_Page = currentPage ? `&page=${currentPage}` : '';
  const search = searchText ? `&search_text=${searchText}` : ''
  const month = searchMonth ? `&month=${searchMonth}` : ''
  const year = searchYear ? `&year=${searchYear}` : ''

  try {
    const response = await axios.get(`${BASE_URL}${get_staff_target_url}${location}${current_Page}${search}${month}${year}`, {
      headers: {
        Authorization: `Token ${access_token}`,
      },
    });
    return response;
  } catch (error) {
    toast.error(apiErrorMessage, { toastId: "toast" });
    return error;
  }
};

export const getStaffTargetWithNoPagination = async (selected_location, access_token) => {
  const location = selected_location ? `&location_id=${selected_location}` : ''
  try {
    const response = await axios.get(`${BASE_URL}${get_staff_target_url}?no_pagination=true${location}`, {
      headers: {
        Authorization: `Token ${access_token}`,
      },
    });
    return response;
  } catch (error) {
    // toast.error(apiErrorMessage,{ toastId: "toast" });
    return error;
  }
};

export const copyStaffTarget = async (data, access_token) => {
  try {
    const result = await axios.post(
      `${BASE_URL}${copy_staff_target_url}`,
      data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const addStaffTarget = async (consumption_data, access_token) => {
  try {
    const result = await axios.post(
      `${BASE_URL}${add_staff_target_url}`,
      consumption_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const deleteStaffTarget = async (id, access_token) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const result = await axios.delete(
      `${BASE_URL}${delete_staff_target_url}`,
      headers
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const editStaffTarget = async (staffTarget_data, access_token) => {
  try {
    const result = await axios.put(
      `${BASE_URL}${update_staff_target_url}`,
      staffTarget_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export const get_staff_target_kpi = (selectedd_location, currentPage, month, year, filterStaff, loader, success, fail) => async (dispatch) => {
  const location = selectedd_location ? `?location_id=${selectedd_location}` : ''
  const page = currentPage ? `&page=${currentPage}` : ''
  const monthValue = month ? `&month=${month}` : ''
  const yearValue = year ? `&year=${year}` : ''
  const employee = filterStaff ? `&employee_id=${filterStaff}` : ''
  try {
    loader && loader()
    const response = await axios.get(`${BASE_URL}${get_staff_target_report_url}${location}${page}${monthValue}${yearValue}${employee}`);
    if (response.status === 200) {
      success && success()
      dispatch(get_StaffTarget_report(response?.data));
    } else {
      toast.error(apiErrorMessage, { toastId: "toast" });
    }
  } catch (error) {
    fail && fail()
    toast.error(apiErrorMessage, { toastId: "toast" });
    return error;
  }

};
// no pagination
export const get_staff_target_kpi_without_pagination = (selectedd_location, month, year, filterStaff, loader, success, fail) => async (dispatch) => {
  const monthValue = month ? `&month=${month}` : ''
  const yearValue = year ? `&year=${year}` : ''
  const location = selectedd_location ? `?location_id=${selectedd_location}` : ''
  const employee = filterStaff ? `&employee_id=${filterStaff}` : ''

  try {
    loader && loader()
    const response = await axios.get(`${BASE_URL}${get_staff_target_report_url}${location}&no_pagination=true${monthValue}${yearValue}${employee}`);
    if (response.status === 200) {
      success && success()
      dispatch(get_StaffTarget_report_without_pagination(response?.data));
    } else {
      toast.error(apiErrorMessage, { toastId: "toast" });
    }
  } catch (error) {
    fail && fail()
    toast.error(apiErrorMessage, { toastId: "toast" });
    return error;
  }
};