import { STAFF_GROUPS } from "../../ActionTypes/staffGroupsTypes";

const initialState = {
    staffGroups: [],
    allStaffGroups: [],
    pages: 0,
    allCount: 0,
    staffGroup_updated: false,
};


const StaffGroupReducers = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {

        case STAFF_GROUPS.GET_STAFF_GROUPS:
            return {
                ...state,
                staffGroups: payload?.response?.staff_group,
                pages: payload?.response?.pages,
                allCount: payload?.response?.count,
                staffGroup_updated: true,
            };

        case STAFF_GROUPS.GET_STAFF_GROUPS_WITHOUT_PAGINATION:
            return {
                ...state,
                allStaffGroups: payload,
                staffGroup_updated: true,
            };


        case STAFF_GROUPS.ADD_STAFF_GROUP:
            return {
                ...state,
                staffGroups: [payload, ...state.staffGroups]
            };


        case STAFF_GROUPS.DELETE_STAFF_GROUP:
            let result = state?.staffGroups?.filter((item) => item.id !== payload);
            return {
                ...state,
                staffGroups: [...result],
            };


        case STAFF_GROUPS.UPDATE_STAFF_GROUP:

            let updateList = state?.staffGroups?.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })
            return {
                ...state,
                staffGroups: [...updateList],
            };

        default:
            return state;
    }
};
export default StaffGroupReducers;
