import Svgs from "../../../../../Assets/svgs"
import LoginBtn from "../../../Elements/LoginBtn"
import LoginInput from "../../../Elements/LoginInput"
import Popup from "../../../Elements/Popup"



const VoucherCheckoutPopup = ({ onClose }) => {
    return (
        <>
            <Popup heading={`Voucher Payment`} onclose={onClose}>
                <div className='flex flex-col gap-4'>
                    <div className='text-sm flex items-center gap-2 px-4 py-2 bg-[#F4F7FA] rounded-md'>
                        <Svgs.I />
                        <p className='text-[#555555]'>Total amount to pay is <span className='tetx-black'>RS. 50</span> </p>
                    </div>
                    <LoginInput title='Voucher Code' placeholder='Enter Voucher Code ' required={true} leftIcon={<Svgs.Search />} />
                </div>
                <div className='flex items-center justify-start mt-[2rem]'>
                    <LoginBtn>Pay Now</LoginBtn>
                </div>
            </Popup>
        </>
    )
}

VoucherCheckoutPopup.defaultProps = {
    onClose: () => { }
}

export default VoucherCheckoutPopup