import React, { memo } from "react";
import ChooseLocationModal from "./choose-location-modal";
import { useLocation } from "./helper";
import Svgs from "Assets/svgs";
import { useSelector } from "react-redux";

const LocationSelection = ({ onlyShow, conditionalOnClick }) => {
  const state = useSelector(s => s)
  const { locationPopup, locationName, loading, locationList, setLocationPopup } = useLocation();

  return (
    <>
      <div
        className={`flex text-center items-center gap-1 ${locationName ? "cursor-pointer" : "cursor-default"} ${onlyShow && "border-2 p-2 rounded-md"}`}
        onClick={() => {
          if (!onlyShow && locationName) {
            setLocationPopup(true);
          }
        }}
      >
        {loading
          ? <>
            <div className="py-4 w-32 rounded-lg bg-gray-200 animate-pulse"></div>
          </>
          :
          (!state.user.user.employee || onlyShow || (state.user.user.employee && state.user?.permissions?.system_root_access?.includes('view'))) &&
          <>
            <Svgs.MAP_PIN />
            <h1 className="text-primary text-sm">{locationName}</h1>
            {!onlyShow &&
              <span> <Svgs.Edit size="12" fill="#7D7D7D" /></span>
            }
          </>
        }
      </div>

      {locationPopup && (
        <ChooseLocationModal
          setLocationPopup={setLocationPopup}
          locationList={locationList}
          conditionalOnClick={conditionalOnClick}
        />
      )}
    </>
  );
};

export default memo(LocationSelection);
