import Svgs from 'Assets/svgs';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import Popup from 'Components/TenantApp/Elements/Popup';
import React from 'react'
import { useSelector } from 'react-redux';

const Filter = ({ FilterPopup, setFilterPopup, onFilterChange, filterData, clearFilter, onSubmit }) => {
    const { category, status } = filterData

    const state = useSelector(state => state)
    const allCategories = state?.crm?.categories

    return (
        <Popup heading="Filter" open={FilterPopup} close={setFilterPopup}>
            <div className="flex flex-col gap-5">
                <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
                    <CircleIcon>
                        <Svgs.FilterPopup />
                    </CircleIcon>
                    <div className="flex flex-col gap-1 lg:text-left text-center">
                        <h2 className="text-primary font-semibold text-xl">
                            Search by filter
                        </h2>
                        <p className="text-[#7B7B7B] text-sm">
                            Choose filters by status and segmentation type to tailor your <br /> client segmentation view.
                        </p>
                    </div>
                </div>
                <div className="grid xs:grid-cols-2 gap-5">
                    <div>
                        <Dropwdown
                            title={"Category"}
                            placeholder={"Select Category"}
                            options={allCategories?.map((itm) => ({
                                label: itm?.name,
                                value: itm?.id
                            }))}
                            name={'category'}
                            value={category}
                            onChange={onFilterChange}
                        />
                    </div>
                    <div>
                        <Dropwdown
                            title={"Status"}
                            placeholder={"Select Status"}
                            options={[
                                {
                                    value: 'Draft',
                                    label: "Draft"
                                },
                                {
                                    value: 'Inactive',
                                    label: "Inactive"
                                },
                                {
                                    value: 'Active',
                                    label: "Active"
                                },
                            ]}
                            value={status}
                            name='status'
                            onChange={onFilterChange}
                        // onBlur={blur_change}
                        // error={error.includes('is_active') && !form_data.status}
                        // disabled={loading}
                        />
                    </div>
                </div>
                <div className='flex gap-3'>
                    <LoginBtn text="Search" onClick={onSubmit} />
                    <LoginBtn text="Clear Filter" onClick={clearFilter} />
                </div>
            </div>
        </Popup>
    )
};

export default Filter;