import axios from "axios";
import { toast } from "react-toastify";
import { SALE_SERVICES } from "Redux/TenantRedux/ActionTypes/saleServiceType";
import { BASE_URL, create_servicegroup } from "../api_variables";


export const PostServiceGroup = (data, success, fail) => dispatch => {
    let form_data = new FormData()
    for (let dt in data) {
        if (data[dt]) {
            if (typeof (data[dt]) == 'object' && dt != 'image') {
                form_data.append(dt, JSON.stringify(data[dt]))
            } else {
                form_data.append(dt, data[dt])
            }
        }
    }

    let access_token = localStorage.getItem('access_token')
    if (access_token) {
        access_token = access_token?.replaceAll('"', '')
    }


    let result = fetch(`${BASE_URL}${create_servicegroup}`, {
        method: 'POST',
        body: form_data,
        headers: {
            Authorization: `Token ${access_token}`,
        },

    }).then((result) => {
        if (result.ok) {
            return result.json();
        }
    }).then((res) => {
        if (res.status_code == 201) {
            success && success(res?.response?.service_group, res?.response?.message)
        } else {
            fail && fail();
        }
    }).catch((err) => {
        fail && fail();
    })

    return result;
};
