import React from 'react'
import Svgs from '../../../Assets/svgs'

const StatusBtn = (props) => {
    return (
        <div
            className={`border 
                ${props.status.toLowerCase() == 'active' || props.status.toLowerCase() == 'pending' || props.status.toLowerCase() == 'online'
                    ? 'border-[#05D672] text-[#05D672] bg-[#d2fdf4]'
                    : ''}
                ${props.status.toLowerCase() == 'inactive' || props.status.toLowerCase() == 'offline'
                    ? 'border-[#FF3030] text-[#FF3030] bg-[#FFF9F9]'
                    : ''}
                ${props.status.toLowerCase() == 'expire'
                    ? 'border-[#ffc107] text-[#ffc107] bg-[#FFF9F9]'
                    : ''}
                ${props.rounded ? props.rounded : 'rounded-full'}
                ${props.size == 'xs'
                    ? 'px-1 py-0.5 text-xs !gap-0.5'
                    : props.size == 'sm'
                        ? 'px-2 py-1 text-xs'
                        : props.size == 'md'
                            ? 'px-3 py-2 text-xs'
                            : 'px-3 py-2 text-xs'} 
                flex items-center w-fit gap-2`}
        >
            <Svgs.Bullet fill={props.status.toLowerCase() == 'active' || props.status.toLowerCase() == 'online' || props.status.toLowerCase() == 'pending'
                ? '#05D672'
                : props.status.toLowerCase() == 'expire'
                    ?'#ffc107'
                : '#FF3030'} />
            {props?.profile_status ?
                <p className='capitalize'>{props?.profile_status}</p>
                :
                <p className='capitalize'>{props.text ? props.text : props.status}</p>
            }
        </div>
    )
}

StatusBtn.defaultProps = {
    status: 'Active',
}

export default StatusBtn


