import React,{useEffect, useState} from 'react';
import { Chart as ChartJS,LineElement,CategoryScale,PointElement, LinearScale } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(LineElement,CategoryScale,PointElement, LinearScale);

const createLinearGradient = (color1, color2) => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0,450, 0);
    gradient.addColorStop(0, color1);
    gradient.addColorStop(1, color2);
    return gradient;
  };

const ColorLineChart = () => {


  const [LabelFontSize, setLabelFontSize] = useState(14);
    useEffect(() => {
          const handleResize = () => {
          if (window.innerWidth >= 770) {
    
              setLabelFontSize(14)
          } else {
        
              setLabelFontSize(10)
          }
          };
          // Initial setup
          handleResize();
          // Event listener for window resize
          window.addEventListener('resize', handleResize);
          return () => {
              window.removeEventListener('resize', handleResize);
          };
      }, []);
  
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
    datasets: [
      {
        label: '',
        data: [0, 30, 70, 22, 18,30, 40,10,60, 80,40,100],
        borderColor: createLinearGradient('#71CCFF80', '#0063F8'),
        pointRadius:0,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: ["#0063F8"],
        pointHoverBorderColor: ["#fff"],
        pointHoverBorderWidth: 2,
        fill: false,
        // backgroundColor: 'rgba(86, 121, 255, 0.9)',
      },
      {
        label: '',
        data: [0, 35, 10, 5, 60,55, 80,40,24, 50,22,100],
        borderColor: createLinearGradient('#1EFEB980', '#007930'),
        pointRadius:0,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: ["#007930"],
        pointHoverBorderColor: ["#fff"],
        pointHoverBorderWidth: 2,
        fill: false,
        // backgroundColor: '#333',
      },
    ],
  };
  
  
  const options = {
    
    plugins: {
      legend:false,
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: true,
        usePointStyle: true,
        bodyAlign: "center",
        yAlign: "bottom",
        xAlign: "center",
        footerAlign: "center",
        titleAlign: "center",
        displayColors: false,
        pointStyle: false,
        padding: 10,
        caretPadding: 10,
        bodyFont: {
          size: 15,
        },
        callbacks: {
          title: function () {
            return "";
          },
        },
      },
    },
    scales: {
      y: {
        grid:{
          display: true,
        },
        ticks: {
          font: {
            size: LabelFontSize, // Set the font size for the y-axis labels
            family: 'Arial', // Set the font family for the y-axis labels
          },
        },
      },
      x: {
        grid:{
          display: false,
        },
        ticks: {
          font: {
            size: LabelFontSize, // Set the font size for the y-axis labels
            family: 'Arial', // Set the font family for the y-axis labels
          },
        },
      },
    },
  };


  return (
    <Line data={data} options={options}/>
  );
};

export default ColorLineChart;