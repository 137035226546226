import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import FindUs from "Components/NstyleApp/Signup/FindUs";
import TeamSize from "Components/NstyleApp/Signup/TeamSize";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import BusinessType from "Components/NstyleApp/Signup/BusinessType";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getBusinessById, getBusinessTypes } from "Adapters/Api";
import { getCurrencyList } from "Adapters/Api/currency/index";
import { fetchBusiness } from "Redux/TenantRedux/Actions/locationActions/";
import { SubmitBusinessAdditionalInfo } from "Adapters/Api/bookingSettings";
import { apiErrorMessage } from "Constants/Data/Errors";
import AskToUpdateData from "Components/Utility/AskToUpdateData";
import Shimmer from "Components/Utility/Shimmers/Shimmer";

const AdditionalInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();

  const [businessData, setBusinessData] = useState({});
  const [currency, setCurrency] = useState("");
  const [loading, setLoading] = useState(false);

  const state = useSelector((state) => state);
  const currency_list = state?.utility?.currency?.currency_list;
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const handle_submit = () => {
    let business_data = getDataFromLocalStorage("business_data");
    businessData["business"] = business_data.id;
    if (currency) {
      businessData["currency"] = currency;
    } else {
      delete businessData["currency"];
    }

    setLoading(true);
    dispatch(
      SubmitBusinessAdditionalInfo(
        businessData,
        () => {
          setLoading(false);
          dispatch(getBusinessTypes());
          getBusiness();
          toast.success("Updated Successfully", { toastId: "toast" });
          navigate("/dashboard/account/setup");
        },
        () => {
          setLoading(false);
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };

  const handle_select_type = (id) => {
    // let selected_types = businessData.selected_types
    //   ? businessData.selected_types
    //   : [];
    // if (selected_types) {
    //   if (selected_types.includes(id)) {
    //     selected_types = selected_types.filter((itm) => itm != id);
    //   } else {
    //     selected_types.push(id);
    //   }
    // }

    setBusinessData({
      ...businessData,
      // selected_types: [...selected_types],
      selected_types: [id],
    });
  };

  const getBusiness = async () => {
    const access_token = getDataFromLocalStorage("access_token");
    const user_id = getDataFromLocalStorage("user_id");
    const isEmployee = getDataFromLocalStorage("isEmployee");

    const response = await getBusinessById(user_id, isEmployee, access_token);

    if (response.status === 200) {
      let business = response?.data?.response?.business;
      dispatch(fetchBusiness(business));
    }
  };
  useEffect(() => {
    if (Object?.keys(state?.business?.business)?.length === 0) {
      getBusiness();
    } else {
      setBusinessData({
        ...state.business?.business,
        selected_types: state.business?.business?.business_types,
        selected_softwares: state.business?.business?.software_used,
      });
      setCurrency(state.business?.business?.currency?.id);
    }
  }, [state.business?.business]);

  useEffect(() => {
    dispatch(getCurrencyList());
  }, []);

  return (
    <>
      <AskToUpdateData />
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.2rem] px-[1rem] h-full overscroll-auto">
        <div className="pb-6">
          <p className="text-[#7B7B7B] text-sm">
            <span
              className="cursor-pointer"
              onClick={() => {
                navigate("/dashboard/account/");
              }}
            >
              {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
            </span>{" "}
            &gt;{" "}
            <span
              className="cursor-pointer"
              onClick={() => {
                navigate("/dashboard/account/setup/");
              }}
            >
              {selectedLanguage?.name == "English" ? "Account Setup" : translation[645]?.value ? translation[645]?.value : "Account Setup"}
            </span>{" "}
            &gt;{" "}
            <span className="text-primary font-semibold">
              {selectedLanguage?.name == "English" ? "Business Info" : translation[656]?.value ? translation[656]?.value : "Business Info"}
            </span>
          </p>
        </div>
        <BusinessType
          onSelectType={handle_select_type}
          selected_types={
            businessData?.selected_types ? businessData?.selected_types : []
          }
        />
        <div className="grid lg:grid-cols-2 gap-5">
          <FindUs
            selected={businessData.how_find_us}
            onSelect={(value) => {
              setBusinessData({
                ...businessData,
                how_find_us: value,
              });
            }}
          />
          <TeamSize
            onSelect={(value) => {
              setBusinessData({
                ...businessData,
                team_size: value,
              });
            }}
            selected_size={businessData.team_size}
          />
          {/* <SoftwareUsing
                    onSelectType={handle_selected_software}
                    selected_types={businessData?.selected_softwares ? businessData?.selected_softwares : []}
                /> */}
        </div>
        {/* <div className="mb-5 flex flex-col gap-4 w-full lg:w-[46%]">
         
          <h2 className="font-semibold text-2xl">
            {selectedLanguage?.name == "English" ? "Select Currency" : translation[68]?.value ? translation[68]?.value : "Select Currency"}
          </h2>
          <div className="w-full lg:w-[75%]">
            <Dropwdown
              required={true}
              title=""
              name={"currency"}
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              // onBlur={() => onBlurHandler({ is_active: is_active })}
              // error={errors.is_active}
              placeholder={selectedLanguage?.name == "English" ? "Select Currency" : translation[68]?.value ? translation[68]?.value : "Select Currency"}
              options={currency_list?.map((crncy) => {
                return {
                  label: crncy?.code,
                  value: crncy?.id,
                };
              })}
            />
          </div>
        </div> */}

        {loadingPermission ?
          <Shimmer className={'w-44 lg:w-48 mb-5'}>
            <div className="h-8 w-full  bg-gray-200 rounded-lg" />
          </Shimmer> :
          !employee ? (
            <LoginBtn
              text={selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}
              disabled={
                Object.keys(businessData).length > 0
                  // && currency
                  ? false
                  :
                  // !currency ||
                  !businessData.team_size ||
                    !businessData.how_find_us ||
                    !businessData.selected_softwares ||
                    !businessData.selected_types?.length == 0
                    ? true
                    : false
              }
              loading={loading}
              onClick={() => {
                handle_submit();
              }}
            />
          ) : employee && employeePermissions?.account_business_setting?.includes("edit") ? (
            <LoginBtn
              className={'!mb-5'}
              text={selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}
              disabled={
                Object.keys(businessData).length > 0 && currency
                  ? false
                  : !currency ||
                    !businessData.team_size ||
                    !businessData.how_find_us ||
                    !businessData.selected_softwares ||
                    !businessData.selected_types?.length == 0
                    ? true
                    : false
              }
              loading={loading}
              onClick={() => {
                handle_submit();
              }}
            />
          ) : employee && employeePermissions?.inventory_root_access?.includes("view") ?
            (
              <LoginBtn
                className={'!mb-5'}
                text={selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}
                disabled={true}
              />
            ) : ("")}

      </div>
    </>
  );
};

export default AdditionalInformation;
