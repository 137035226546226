import axios from 'axios';
import { UTITLY_LOCATION } from 'Redux/Utility/ActionTypes/LocationTypes';
import {
    BASE_URL, get_business_location_by_domain, customer_login,
    check_employee_availability, assign_rendom_employee,
    create_business_client, verification_business_client,
    get_customer_appointment, cancel_client_appointment,
    employee_availability, update_appointment_client,
    get_locataion_tax_for_clients, get_selected_locataion_tax,
    get_client_auto_generate_id, get_client_details, get_common_tenant_detail, get_user_default_data, update_user_default_data, check_employee_existance, nstyle_BASE_URL
} from "../api_variables";
import { SALE_SERVICES } from './../../../Redux/TenantRedux/ActionTypes/saleServiceType/index';
import { LOCATION } from './../../../Redux/TenantRedux/ActionTypes/locationTypes/index';
import { CLIENT_APPOINTMENT } from 'Redux/NstyleRedux/ActionTypes/ClientAppointmentTypes';
import { getBusinessTaxs } from 'Redux/TenantRedux/Actions/financialSettingsActions';
import { toast } from 'react-toastify';
import { apiErrorMessage } from 'Constants/Data/Errors';

// get business by domain name
export const getBusinessLocationByDomain = (hash) => async dispatch => {
    try {
        const result = await axios.get(
            `${BASE_URL}${get_business_location_by_domain}?hash=${hash}`
        );
        if (result?.status === 200) {
            let locations = result?.data?.response?.locations;
            dispatch({
                type: LOCATION.ADD_LOCATION,
                payload: locations,
            })

            dispatch({
                type: SALE_SERVICES.GET_SALE_SERVICES,
                payload: result?.data?.response?.service
            })

            dispatch({
                type: SALE_SERVICES.GET_GROUP_SERVICES,
                payload: result?.data?.response?.service_group
            })

        }
        return result;
    } catch (error) {
        return error;
    }
};



export const chechEmployeeAvailability = (check_availability, hash, success, fail) => async dispatch => {

    try {
        const result = await axios.post(
            `${BASE_URL}${check_employee_availability}`, { check_availability, hash }
        );
        if (result?.data?.status_code === 200) {
            const Availability = result?.data?.response?.Availability;
            const message = result?.data?.response?.message
            success && success(message, Availability)
        } else {
            fail && fail(result?.data?.response?.message)
        }
    } catch (error) {
        fail && fail("Something went worng")
    }
};



export const getRemdomFreeEmployee = (data, success, fail) => async dispatch => {

    try {
        const result = await axios.post(
            `${BASE_URL}${assign_rendom_employee}`, data
        );
        if (result?.data?.status_code === 200) {
            success && success(result?.data?.response?.employee)
        } else {
            fail && fail()
        }
        return result;
    } catch (error) {
        fail && fail()
        return error;
    }
};


export const createBusinessClient = (data, success, fail) => async dispatch => {

    try {
        const result = await axios.post(
            `${BASE_URL}${create_business_client}`, data
        );

        if (result?.data?.status_code === 200) {
            success && success(result?.data?.response?.message)
        } else {
            fail && fail(result)
        }

        return result;
    } catch (error) {
        fail && fail(error)
        return error;
    }
};


// verified your mobile number or email
export const emailVerificationOfBusinessClient = async (verification_data) => {
    try {
        const result = await axios.post(
            `${BASE_URL}${verification_business_client}`,
            verification_data
        );
        return result;
    } catch (error) {
        return error;
    }
};

// customer login
export const customerLogin = async (data, success, fail) => {

    let query = '?'
    if (data.hash_id) {
        query += `tenant_id=${data.hash_id}`
    }

    try {
        const result = await axios.post(
            `${BASE_URL}${customer_login}${query}`,
            data.login_credentials
        );
        success && success(result);
        return result;
    } catch (error) {
        fail && fail(error);
        return error.response;
    }
};



export const getClientAppointments = (client_id, fail) => async dispatch => {

    try {
        const result = await axios.get(
            `${BASE_URL}${get_customer_appointment}?client_id=${client_id}`
        );

        if (result?.status === 200) {
            let appts = result?.data?.response?.appointment;
            dispatch({
                type: CLIENT_APPOINTMENT.GET_CLIENT_APPOITMENT,
                payload: appts
            })

        } else {
            fail && fail()
        }
        return result;
    } catch (error) {
        fail && fail()
        return error;
    }
};



export const cancelClientAppointments = (appointment_notes, appointment_id, hash, success, fail) => async dispatch => {
    try {
        const result = await axios.put(
            `${BASE_URL}${cancel_client_appointment}?appointment_id=${appointment_id}&hash=${hash}`, { appointment_notes }
        );

        if (result?.status === 200) {
            let appts = result?.data?.response?.appointment;
            dispatch({
                type: CLIENT_APPOINTMENT.CANCEL_CLIENT_APPOITMENT,
                payload: appts
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
        return result;
    } catch (error) {
        fail && fail()
        return error;
    }
};


export const reschedulClientAppointments = (appointment_service, hash, success, fail) => async dispatch => {

    try {
        const result = await axios.put(
            `${BASE_URL}${update_appointment_client}`, { appointment_service, hash }
        );

        if (result?.status === 200) {
            let appointments = result?.data?.response?.appointment;
            let appointment_id = result?.data?.response?.appointment_id;
            dispatch({
                type: CLIENT_APPOINTMENT.RESCHEDULE_CLIENT_APPOITMENT,
                payload: { appointments, appointment_id }
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
        return result;
    } catch (error) {
        fail && fail()
        return error;
    }
};


export const employeeAvailability = (data, success, fail) => async dispatch => {

    try {
        const result = await axios.post(
            `${BASE_URL}${employee_availability}`, data
        );

        if (result?.data?.status_code === 200) {
            const emp_detail = result?.data?.response?.employee
            success && success(result?.data?.response?.message, emp_detail)
        } else {
            fail && fail()
        }

        return result;
    } catch (error) {
        fail && fail()
        return error;
    }
};


export const getBusinessTaxOutOfTenant = (business, hash, success, fail) => async dispatch => {

    try {
        const result = await axios.get(
            `${BASE_URL}${get_locataion_tax_for_clients}?business=${business}&hash=${hash}`
        );
        if (result?.status === 200) {
            let tax = result?.data?.response?.tax;
            dispatch(getBusinessTaxs(tax));
        } else {
            fail && fail()
        }
        return result;
    } catch (error) {
        fail && fail()
        return error;
    }
};


export const getVisitedLocationBusinessTax = (location_id, hash, success, fail) => async dispatch => {

    try {
        const result = await axios.get(
            `${BASE_URL}${get_selected_locataion_tax}?location_id=${location_id}&hash=${hash}`
        );
        if (result?.status === 200) {
            let tax = result?.data?.response?.tax;
            dispatch(getBusinessTaxs(tax));
        } else {
            fail && fail()
        }
        return result;
    } catch (error) {
        fail && fail()
        return error;
    }
};


export const getClientAutoGenerateId = (hash, success, fail) => async dispatch => {
    try {
        const result = await axios.get(
            `${BASE_URL}${get_client_auto_generate_id}?hash=${hash}`
        );
        if (result?.status === 200) {
            let clientId = result?.data;
            success && success(clientId)
        } else {
            fail && fail()
        }
        return result;
    } catch (error) {
        fail && fail()
        return error;
    }
};

export const getClientDetail = (hash, client_id, client_email, fail) => async dispatch => {
    try {
        const result = await axios.get(
            `${BASE_URL}${get_client_details}?hash=${hash}&client_id=${client_id}&client_email=${client_email}`
        );
        if (result?.status === 200) {
            let client_detail = result?.data?.response?.client;
            dispatch({
                type: CLIENT_APPOINTMENT.CLIENT_DETAIL,
                payload: client_detail,
            })
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
};


export const getCommonTenantDetail = () => async dispatch => {
    try {
        const result = await axios.get(
            `${BASE_URL}${get_common_tenant_detail}`
        );
        if (result?.status === 200) {
            let tenant_detail = result?.data?.response;
            dispatch({
                type: CLIENT_APPOINTMENT.COMMON_TENANT_DETAIL,
                payload: tenant_detail,
            })
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
    }
};

export const checkEmployeeExistance = (email, success, fail) => async dispatch => {
    try {
        const result = await axios.get(
            `${nstyle_BASE_URL}${check_employee_existance}?email=${email}`
        );
        if (result?.data?.status == 200) {
            success && success(result)
        }
    } catch (error) {
        fail && fail(error?.response)
    }
};



export const getUserDefaultData = (data, success, fail) => {
    if (!data.business_id) {
        return null
    }
    fetch(
        `${BASE_URL}${get_user_default_data}?business_id=${data.business_id}`
    )
        .then(response => {
            return response.json()
        })
        .then(result => {
            success && success(result.response.data)
        })
        .catch(() => {
            fail && fail()
        })
}

export const UpdateUserBusinessDefaultData = (data, success, fail) => {
    let form_data = new FormData()

    for (let dt in data) {
        if (dt == 'service') {
            form_data.append(dt, JSON.stringify(data[dt]))
        }
        else if (dt == 'service_group') {
            form_data.append(dt, JSON.stringify(data[dt]))
        }
        else if (dt == 'location') {
            form_data.append(dt, JSON.stringify(data[dt]))
        }
        else if (dt == 'client') {
            form_data.append(dt, JSON.stringify(data[dt]))
        }
        else if (dt == 'employee') {
            form_data.append(dt, JSON.stringify(data[dt]))
        }
        else {
            form_data.append(dt, data[dt])
        }
    }
    fetch(
        `${BASE_URL}${update_user_default_data}`,
        {
            method: 'POST',
            body: form_data
        }
    )
        .then(response => {
            return response.json()
        })
        .then(result => {
            success && success(result)
        })
        .catch(() => {
            fail && fail()
        })
}