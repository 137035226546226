import { addNewSkill } from 'Adapters/Api/employee/employeeTraining'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Popup from 'Components/TenantApp/Elements/Popup';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import useValidations from 'Hooks/useValidations';
import React from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';

const AddSkillPopup = ({ open, close, selectedLanguage, translation }) => {
    const { setErrors, validation, errors } = useValidations();
    const dispatch = useDispatch();
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");

    const [loading, setLoading] = useState(false)
    const [skill, setSkill] = useState('')
    const handleAddSkill = () => {
        const success = () => {
            setSkill('')
            close && close(false)
            setLoading(false)
        }
        const fail = () => {
            setLoading(false)
        }
        setErrors(validation({ skill }));
        let result = validation({ skill });
        if (!result?.skill) {
            setLoading(true)
            dispatch(addNewSkill(access_token, { name: skill, business: businessData?.id }, success, fail))
        }
    }
    return (
        <>

            <Popup
                open={open}
                close={close}
                heading={selectedLanguage?.name == "English" ? "Add Skill" : translation[1714]?.value ? translation[1714]?.value : "Add Skill"}
            >
                <div className="flex flex-col gap-4">
                    <LoginInput
                        required={true}
                        name={'title'}
                        value={skill}
                        title={selectedLanguage?.name == "English" ? "Add Skill" : translation[1714]?.value ? translation[1714]?.value : "Add Skill"}
                        placeholder={selectedLanguage?.name == "English" ? "Add Skill" : translation[1714]?.value ? translation[1714]?.value : "Add Skill"}
                        onChange={(e) => {
                            setErrors((prev) => ({ ...prev, skill: "" }))
                            setSkill(e?.target?.value)
                        }}
                        error={errors?.skill}
                    />
                    <LoginBtn
                        className={'capitalize'}
                        text={selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                        onClick={handleAddSkill}
                        loading={loading}
                        disabled={loading}
                    />
                </div>
            </Popup></>
    )
}

export default AddSkillPopup