import { delete_refund_time_period, get_refund_time_period } from 'Adapters/Api/financialSettings'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import React from 'react'
import { useEffect } from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useRefundsSetting = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)


    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const bussiness = getDataFromLocalStorage('business_data')

    // const { setErrors, validation, errors, onBlurHandler } = useValidations();

    const locationList = state?.locations?.locations;
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const refundTimePeriodRedux = state?.financialSettings?.refundTimePeriod

    const [refundPopup, setRefundPopup] = useState(false)
    const [submitLoader, setSubmitLoader] = useState(false)
    const [fetchRefund, setFetchRefund] = useState(true)
    const [isEdit, setIsEdit] = useState(false)
    const [deletePopup, setDeletePopup] = useState(false)
    const [refundDetails, setRefundDetails] = useState({})
    const [deleteLoading, setDeleteLoading] = useState(false)

    const getRefundTimeDetail = (data, type) => {
        setRefundDetails(data)
        if (type == "edit") {
            setIsEdit(true)
            setRefundPopup(true)
        }
        if (type == "delete") {
            setDeletePopup(true);
        }
    }

    const deleteRefundTimePeriod = (id) => {
        setDeleteLoading(true)
        const success = () => {
            setDeletePopup(false)
            setDeleteLoading(false)
        }
        const fail = () => {
            setDeletePopup(false)
            setDeleteLoading(false)
        }

        dispatch(delete_refund_time_period(access_token, id, success, fail))
    }

    const locationListFiltered = useMemo(() => {
        return locationList?.filter((tx_loc) =>
            !refundTimePeriodRedux?.find((itm) => {
                if (!isEdit) {
                    if (itm?.location == tx_loc?.id) return true;
                    else return false
                }
            })
        )
    }, [locationList,isEdit, refundTimePeriodRedux]);

    const getRefundTimePeriod = () => {
        const success = () => { setFetchRefund(false) }
        const fail = () => { setFetchRefund(false) }
        dispatch(get_refund_time_period(access_token, success, fail))
    }

    useEffect(() => {
        getRefundTimePeriod()
    }, [])


    return {
        isEdit, setIsEdit,
        submitLoader, fetchRefund,
        deletePopup, setDeletePopup, deleteLoading,
        refundDetails, refundPopup, setRefundPopup,
        selectedLanguage, translation,
        deleteRefundTimePeriod, locationListFiltered,
        refundTimePeriodRedux, getRefundTimeDetail,
    }
}

export default useRefundsSetting