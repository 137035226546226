import React from 'react'

const SelectedInvoiceShimmer = () => {
    return (
        <>
            <div className="p-[1.2rem] overflow-y-auto overflow-x-hidden scroll-hidden max-h-[55vh] text-center flex flex-col gap-5 animate-pulse">
                <div className="flex cursor-pointer items-center justify-between rounded-md gap-2 bg-gray-200 p-2"><div className="flex gap-2 items-center"><div className="w-[4.5rem] h-[4.5rem] rounded-md bg-gray-300"></div><div className="space-y-1"><p className="font-bold leading-4 w-20 h-4 bg-gray-300 rounded-md"></p><p className=" text-sm w-20 h-4 bg-gray-300 rounded-md"></p></div></div><div className="flex flex-col gap-2"><p className="font-semibold text-sm w-20 h-4 bg-gray-300 rounded-md"></p><p className="font-semibold text-sm w-20 h-4 bg-gray-300 rounded-md"></p></div></div>
                <h1 className="font-bold text-xl normal-case items-center justify-center mx-auto">
                    <div className="py-3 w-24 rounded-lg my-1 bg-gray-300 mx-auto"></div>
                    <div className="py-3 w-40 rounded-lg my-1 bg-gray-200"></div>
                    <div className="py-3 my-4 w-28 rounded-lg bg-gray-300 mx-auto"></div>
                    <div className="py-3 w-24 rounded-lg my-2 bg-gray-200 mx-auto"></div>
                </h1>
                <div className="w-full h-10 bg-[#F9F9F9] text-[#7B7B7B] border rounded-md mx-auto p-2 px-4 flex justify-center items-center gap-2 text-sm text-center">
                </div>
                <div>
                    <div>
                        <div className="text-left flex flex-col gap-1">
                            <div className="flex items-center justify-between text-[#000] font-semibold text-sm">
                                <div className="py-3 w-20 rounded-lg my-0.5 bg-gray-300"></div>
                                <div className="py-3 w-20 rounded-lg my-0.5 bg-gray-300"></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-left flex flex-col gap-1">
                            <div className="flex items-center justify-between text-[#000] font-semibold text-sm">
                                <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                                <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-left flex flex-col gap-1">
                            <div className="flex items-center justify-between text-[#000] font-semibold text-sm">
                                <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                                <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-left flex flex-col gap-1 my-4">
                            <div className="flex items-center justify-between text-[#000] font-semibold text-sm">
                                <div className="py-3 w-20 rounded-lg my-0.5 bg-gray-300"></div>
                                <div className="py-3 w-20 rounded-lg my-0.5 bg-gray-300"></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-left flex flex-col gap-1 my-2">
                            <div className="flex items-center justify-between text-[#000] font-semibold text-sm">
                                <div className="py-3 w-20 rounded-lg my-0.5 bg-gray-300"></div>
                                <div className="py-3 w-20 rounded-lg my-0.5 bg-gray-300"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="flex items-center justify-between text-primary text-sm font-bold">
                    <div className="text-left flex flex-col gap-1">
                        <div className="flex items-center justify-between text-primary text-sm font-bold">
                            <div className="py-3 w-40 rounded-lg my-0.5 bg-gray-300"></div>
                        </div>
                        <div className="flex items-center justify-between text-primary text-sm text-[#7B7B7B]">
                            <div className="py-3 w-64 rounded-lg my-0.5 bg-gray-300"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SelectedInvoiceShimmer