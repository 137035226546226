
import axios from "axios";
import { getPayroll } from "../../../Redux/TenantRedux/Actions/payrollActions";
import { BASE_URL, create_loyalty_point, get_loyalty_points, update_loyalty_point, delete_loyalty_point } from "../api_variables";
import { toast } from 'react-toastify';
import { CLIENT } from "Redux/TenantRedux/ActionTypes/clientActionTypes";
import { apiErrorMessage } from "Constants/Data/Errors";

export const getLoyaltyPoints = (setLoadLoyality) => dispatch => {
    let s_code;
    setLoadLoyality(true)
    fetch(`${BASE_URL}${get_loyalty_points}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                setLoadLoyality(false)
                let all_points = result?.response?.loyalty;
                dispatch({
                    type: CLIENT.GET_LOAYALTY_POINTS,
                    payload: all_points
                })
            }
            else {
                toast.error(apiErrorMessage, { toastId: "toast" })
                setLoadLoyality(false)
            }
        })
};



export const createLoyaltyPoint = (data, access_token, success, fail) => async dispatch => {
    try {
        const result = await axios.post(`${BASE_URL}${create_loyalty_point}`, data,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );
        if (result?.data?.status_code === 201) {
            dispatch({
                type: CLIENT.CREATE_LOAYALTY_POINT,
                payload: result?.data?.response?.loyalty
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()

    }
};



export const updateLoyaltyPoint = (data, access_token, success, fail) => async dispatch => {
    try {
        const result = await axios.put(`${BASE_URL}${update_loyalty_point}`, { ...data },
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });
        if (result?.data?.status_code === 200) {
            dispatch({
                type: CLIENT.UPDATE_LOAYALTY_POINT,
                payload: result?.data?.response?.voucher
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
};


export const deleteLoyalty = (id, access_token, success, fail) => dispatch => {
    fetch(`${BASE_URL}${delete_loyalty_point}`, {
        method: "DELETE",
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Token ${access_token}`
        },
        body: JSON.stringify(
            { id: id }
        )
    }).then((result) => {
        return result.json()
    }).then((response) => {
        if (response.status_code === 200) {
            dispatch({
                type: CLIENT?.DELETE_LOAYALTY_POINT,
                payload: id
            });
        }
        success && success(response?.response?.message);
    }).catch((err) => {
        fail && fail()
    })

}

