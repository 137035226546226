import React from 'react'

const SideNav_Reports = () => {
    return (
        // <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 460.797 460.797" style="enableBackground:new 0 0 460.797 460.797;" xmlSpace="preserve">
            <g>
                <g>
                    <g>
                        <path fill='#101928' d="M142.475,340.364H63.296c-4.35,0-7.877,3.527-7.877,7.877V452.92c0,4.35,3.527,7.877,7.877,7.877h79.179
				c4.35,0,7.877-3.527,7.877-7.877V348.241C150.351,343.891,146.825,340.364,142.475,340.364z M134.598,445.043H71.173v-88.925
				h63.425V445.043z"/>
                        <path fill='#101928' d="M281.9,238.192h-79.18c-4.35,0-7.877,3.527-7.877,7.877V452.92c0,4.35,3.527,7.877,7.877,7.877h79.18
				c4.35,0,7.877-3.527,7.877-7.877V246.069C289.776,241.719,286.25,238.192,281.9,238.192z M274.023,445.043h-63.426V253.946
				h63.426V445.043z"/>
                        <path fill='#101928' d="M421.324,136.02h-79.179c-4.35,0-7.877,3.527-7.877,7.877V452.92c0,4.35,3.527,7.877,7.877,7.877h79.179
				c4.35,0,7.877-3.527,7.877-7.877V143.897C429.201,139.547,425.674,136.02,421.324,136.02z M413.447,445.043h-63.425V151.774
				h63.425V445.043z"/>
                        <polygon points="400.281,45.652 421.324,0 371.266,4.596 381.227,18.691 31.597,265.787 40.689,278.652 390.32,31.557 			" />
                    </g>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>

    )
}
const SideNav_Myaccount = () => {
    return (
        <svg width="1.25rem" height="1.25rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.98712 12.7852C6.7641 12.7852 4.01172 13.2725 4.01172 15.224C4.01172 17.1756 6.74664 17.6804 9.98712 17.6804C13.2101 17.6804 15.9617 17.1923 15.9617 15.2415C15.9617 13.2907 13.2276 12.7852 9.98712 12.7852Z" stroke="#101928" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.98643 10.0033C12.1015 10.0033 13.8158 8.28819 13.8158 6.17311C13.8158 4.05804 12.1015 2.34375 9.98643 2.34375C7.87135 2.34375 6.15627 4.05804 6.15627 6.17311C6.14913 8.28105 7.8523 9.99613 9.95945 10.0033H9.98643Z" stroke="#101928" strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

const SideNav_Logout = () => {
    return (
        // <?xml version="1.0" encoding="iso-8859-1"?>
        // <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 511.989 511.989" style="enableBackground:new 0 0 511.989 511.989;" xmlSpace="preserve">
                <g>
                    <g>
                        <g>
                            <path fill='#101928' d="M110.933,221.782c-4.71,0-8.533,3.823-8.533,8.533v51.2c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-51.2
				C119.467,225.605,115.644,221.782,110.933,221.782z"/>
                            <path fill='#101928' d="M111.855,2.304L31.172,34.586C8.448,43,0,54.418,0,76.715v358.477c0,22.298,8.448,33.715,30.959,42.061l81.058,32.427
				c4.011,1.519,8.038,2.287,11.981,2.287c17.152,0,29.602-14.336,29.602-34.091V34.049C153.6,9.78,134.246-6.126,111.855,2.304z
				 M136.533,477.876c0,10.18-5.035,17.024-12.535,17.024c-1.869,0-3.883-0.401-5.803-1.118L37.103,461.33
				c-16.102-5.965-20.036-11.102-20.036-26.138V76.715c0-15.036,3.934-20.164,20.241-26.206l80.725-32.29
				c2.082-0.785,4.087-1.186,5.956-1.186c7.501,0,12.544,6.835,12.544,17.016V477.876z"/>
                            <path fill='#101928' d="M178.133,51.115h120.533c14.114,0,25.6,11.486,25.6,25.6v128c0,4.71,3.814,8.533,8.533,8.533
				c4.719,0,8.533-3.823,8.533-8.533v-128c0-23.526-19.14-42.667-42.667-42.667H178.133c-4.71,0-8.533,3.823-8.533,8.533
				S173.423,51.115,178.133,51.115z"/>
                            <path fill='#101928' d="M332.8,298.582c-4.719,0-8.533,3.823-8.533,8.533v128c0,14.114-11.486,25.6-25.6,25.6H179.2
				c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h119.467c23.526,0,42.667-19.14,42.667-42.667v-128
				C341.333,302.405,337.519,298.582,332.8,298.582z"/>
                            <path fill='#101928' d="M511.343,252.655c-0.435-1.05-1.058-1.988-1.852-2.782l-85.325-85.333c-3.337-3.336-8.73-3.336-12.066,0
				c-3.337,3.337-3.337,8.73,0,12.066l70.767,70.775H196.267c-4.71,0-8.533,3.823-8.533,8.533c0,4.71,3.823,8.533,8.533,8.533
				h286.601L412.1,335.215c-3.337,3.337-3.337,8.73,0,12.066c1.664,1.664,3.849,2.5,6.033,2.5c2.185,0,4.369-0.836,6.033-2.5
				l85.325-85.325c0.794-0.794,1.417-1.732,1.852-2.782C512.205,257.093,512.205,254.738,511.343,252.655z"/>
                        </g>
                    </g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
            </svg>
        </>

    )
}
const SideNav_Marketing = () => {
    return (
        <svg width="1.25rem" height="1.25rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.5 12.6962C6.71071 12.2913 11.922 13.4561 17.1327 16.2127V3.01562C11.8494 6.01342 6.64163 7.1562 1.5 6.70183V12.6962ZM1.5 12.6962L3.29606 16.9839H7.27823L5.57328 12.6998M8.63539 6.29033V13.125M17.1327 8.24836C17.8872 8.24836 18.5 8.85936 18.5 9.61387C18.5 10.3684 17.8872 10.9794 17.1327 10.9794" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}
const SideNav_Sales = () => {
    return (
        <svg width="1.25rem" height="1.25rem" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.291 6.80078C12.8951 6.80078 13.3848 6.31109 13.3848 5.70703C13.3848 5.10297 12.8951 4.61328 12.291 4.61328C11.687 4.61328 11.1973 5.10297 11.1973 5.70703C11.1973 6.31109 11.687 6.80078 12.291 6.80078Z" stroke="#101928" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1.09375 10.3438L9.84375 1.59375H16.4062V8.15625L7.65625 16.9062L1.09375 10.3438Z" stroke="#101928" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}
const SideNav_Products = () => {
    return (
        <svg width="1.25rem" height="1.25rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.52919 13.8104C2.52919 13.8104 6.00215 11.1884 9.03377 12.7999C12.0654 14.4092 15.7412 15.6877 17.5088 13.3752M7.98095 7.63295H12.016C15.0416 7.63295 17.5156 10.0983 17.5156 13.111C17.5156 16.126 15.0416 18.5914 12.016 18.5914H7.98095C4.95844 18.5906 2.48438 16.1252 2.48438 13.111C2.48438 10.0983 4.95844 7.63295 7.98095 7.63295ZM8.08045 1.40625H12.566C13.3886 1.40625 14.0624 2.0754 14.0624 2.89518V4.68461C14.0624 5.5044 13.3886 6.17354 12.566 6.17354H8.08045C7.25779 6.17354 6.58401 5.5044 6.58401 4.68461V2.89518C6.58401 2.0754 7.25779 1.40625 8.08045 1.40625Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

const SideNav_Dashboard = () => {
    return (
        <svg width="1.25rem" height="1.25rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.3333 7.1013V3.31797C18.3333 2.14297 17.8 1.66797 16.475 1.66797H13.1083C11.7833 1.66797 11.25 2.14297 11.25 3.31797V7.09297C11.25 8.2763 11.7833 8.74297 13.1083 8.74297H16.475C17.8 8.7513 18.3333 8.2763 18.3333 7.1013Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.3333 16.475V13.1083C18.3333 11.7833 17.8 11.25 16.475 11.25H13.1083C11.7833 11.25 11.25 11.7833 11.25 13.1083V16.475C11.25 17.8 11.7833 18.3333 13.1083 18.3333H16.475C17.8 18.3333 18.3333 17.8 18.3333 16.475Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.74935 7.1013V3.31797C8.74935 2.14297 8.21602 1.66797 6.89102 1.66797H3.52435C2.19935 1.66797 1.66602 2.14297 1.66602 3.31797V7.09297C1.66602 8.2763 2.19935 8.74297 3.52435 8.74297H6.89102C8.21602 8.7513 8.74935 8.2763 8.74935 7.1013Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.74935 16.475V13.1083C8.74935 11.7833 8.21602 11.25 6.89102 11.25H3.52435C2.19935 11.25 1.66602 11.7833 1.66602 13.1083V16.475C1.66602 17.8 2.19935 18.3333 3.52435 18.3333H6.89102C8.21602 18.3333 8.74935 17.8 8.74935 16.475Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}


export default {
    SideNav_Reports,
    SideNav_Sales,
    SideNav_Products,
    SideNav_Marketing,
    SideNav_Dashboard,
    SideNav_Myaccount,
    SideNav_Logout
}
