import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import {
  addVacation,
  deleteVacation,
  editVacation,
  fetchVacationList,
  fetchVacationListWithNoPagination,
} from "Adapters/Api/vacations";
import useValidations from "Hooks/useValidations";
import { getEmployeeDropDownList, getEmployeeDropDownListPagination, getWorkingShedule } from "Adapters/Api/employee";
import useObjectKeys from "Hooks/useObjectKeys";
import {
  // add_Vacation,
  // delete_Vacation,
  // get_Vacations,
  update_Vacation,
} from "Redux/TenantRedux/Actions/vacation";
import { VACATION } from "Redux/TenantRedux/ActionTypes/vacationTypes";

let timeOutId = undefined
let employeeTimeOutId = undefined

export const useVacation = () => {
  const date = new Date();
  const navigate = useNavigate();
  const { setErrors, validation, errors, onBlurHandler } = useValidations();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const selected_location = getDataFromLocalStorage("selected_location");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true)
  const [noPaginantionLoader, setNoPaginationLoader] = useState(true)
  const dispatch = useDispatch();
  const { objectKeyConvertToArray } = useObjectKeys();
  const [vacation, setVacation] = useState({
    staff: "",
    from: "",
    to: "",
    note: "",
    vacation_type: ""
  });
  const [filterStaff, setFilterStaff] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [openVacation, setOpenVacation] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [employeePage, setEmployeePage] = useState(1)
  const [employeeLoader, setEmployeeLoader] = useState(false)

  const state = useSelector((state) => state);
  // const employeeListRedux = state?.employee?.employees
  const businessId = state?.business?.business?.id;
  const vacationList = state?.vacation?.vacations;
  const allVacationList = state?.vacation?.allVacations;
  const pages = state?.vacation?.pages;
  const allCount = state?.vacation?.allCount;
  const employeeListRedux = state?.employee?.employeesDropdown;
  const employeePaginationData = state?.employee?.employeeDropdownPaginationData
  const employeeRedux = state?.employee?.employeesDropdown

  let { staff, from, to, note } = vacation;

  let validateVacation = { staff, from };
  let disbledValidation =
    Object.values(validateVacation).every((x) => x !== "") === false
      ? true
      : false;

  // useEffect(() => {
  //   handleGetVacationList(currentPage, selected_location, access_token, filterStaff);
  // }, [vacationList?.length === 0, currentPage, filterStaff]);

  useEffect(() => {
    if (searchText === null) {
      // Initial load with empty searchText
      handleGetVacationList(currentPage, selected_location, filterStaff, '', access_token)
      handleGetVacationListWithoutPagination(selected_location, filterStaff, '', access_token)
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        handleGetVacationList(currentPage, selected_location, filterStaff, searchText, access_token)
        handleGetVacationListWithoutPagination(selected_location, filterStaff, searchText, access_token)
      }, 500);
    }
  }, [currentPage, selected_location, searchText, filterStaff])

  useEffect(() => {
    setCurrentPage(1)
    setLoader(true)
    setNoPaginationLoader(true)
  }, [selected_location, searchText, filterStaff])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchText])


  useEffect(() => {
    if (selected_location) {
      if (employeeSearch === null) {
        // Initial load with empty search_text
        dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
      } else {
        if (employeeTimeOutId) {
          clearTimeout(employeeTimeOutId)
        }
        employeeTimeOutId = setTimeout(() => {
          dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
        }, 500);
      }
    }
  }, [employeeSearch, selected_location, employeePage])

  const employeeDropdownList = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    const list = employeeListRedux?.map((obj) => {
      if (obj.id) {
        return {
          value: obj.id,
          label: obj.full_name,
        };
      }
    });
    return list;
  }, [employeeListRedux?.length > 0]);

  const handleGetVacationList = async (currentPage, selected_location, filterStaff, searchText, access_token) => {
    const response = await fetchVacationList(currentPage, selected_location, filterStaff, searchText, access_token, setLoader);
    if (response.status === 200) {
      let vacationList = response?.data?.response?.vacations;
      let pages = response?.data?.response;
      dispatch({
        type: VACATION.GET_VACATIONS,
        payload: vacationList,
      })
      dispatch({
        type: VACATION.GET_VACATION_PAGES,
        payload: pages,
      })
      setLoader && setLoader(false)
      // dispatch(get_Vacations(vacationList));
    }
  };

  const handleGetVacationListWithoutPagination = async (selected_location, filterStaff, searchText, access_token) => {
    const response = await fetchVacationListWithNoPagination(selected_location, filterStaff, searchText, access_token);
    if (response.status === 200) {
      let vacationList = response?.data?.response?.vacations;
      dispatch({
        type: VACATION.GET_VACATIONS_WITHOUT_PAGINATION,
        payload: vacationList,
      })
      setNoPaginationLoader && setNoPaginationLoader(false)
      // dispatch(get_Vacations(vacationList));
    }
  };

  const vacationHandleChange = (e) => {
    const { name, value } = e.target;

    if (name == "note") {
      setVacation((prev) => ({ ...prev, note: value }));
      setErrors((prev) => ({ ...prev, note: "" }));
      // if (value && value.length <= 150) {
      //   setVacation((prev) => ({ ...prev, note: value }));
      //   setErrors((prev) => ({ ...prev, note: "" }));
      // } else {
      //   if (value && value.length > 150) {
      //     setErrors((prev) => ({
      //       ...prev,
      //       note: "You can not add more than 150 characters",
      //     }));
      //   }
      // }
    }

    if (name === "filterStaff") {
      setFilterStaff(value);
    }

    if (name === "from") {
      setVacation((prev) => ({ ...prev, [name]: value, to: "" }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }

    if (name != "note" && name != "filterStaff" && name != "from") {
      setVacation((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const vacationDeleteHandler = async (deleteId, handleClose) => {
    setDeleteLoading(true);
    let response = await deleteVacation(deleteId, access_token);

    if (response.status === 200) {
      // let successMessage = response?.data?.response?.message;
      toast.success("Vacation deleted successfully!", { toastId: 'toast' });
      setCurrentPage(1)
      handleGetVacationList(1, selected_location, filterStaff, searchText, access_token);
      handleGetVacationListWithoutPagination(selected_location, filterStaff, searchText, access_token)
      // dispatch(delete_Vacation(deleteId));
      setDeleteLoading(false);
      handleClose();
    }
  };

  const onSubmitEditHandler = async (handleClose, editData) => {
    const { staff, from, to, description } = vacation;
    let vacationData = { staff, from };
    setErrors(validation(vacationData));
    let errorResponse = validation(vacationData);

    if (objectKeyConvertToArray(errorResponse)?.length === 0) {
      const body = {
        schedule_id: editData?.id,
        employee: staff,
        from_date: from,
        to_date: to,
        note: description,
        business: businessId,
        is_vacation: true,
      };
      setLoading(true);
      const response = await editVacation(body, access_token);
      // console.log(response);
      if (response.status === 200) {
        // console.log('block checking')
        let vacationEditResponse = response?.data?.response?.schedule;
        let successMessage = response?.data?.response?.message; // need to update the response from backend
        setLoading(false);
        dispatch(update_Vacation(vacationEditResponse));
        // toast.success('Vacation Updated Successfully');
        toast.success(successMessage, { toastId: "toast" })
        setVacation({
          ...vacation,
          staff: "",
          from: "",
          to: "",
          description: "",
        });
        setCurrentPage(1)
        handleGetVacationList(1, selected_location, filterStaff, searchText, access_token);
        handleGetVacationListWithoutPagination(selected_location, filterStaff, searchText, access_token)
        handleClose();
      } else {
        setLoading(false);
        toast.error("Error in update vacation", { toastId: "toast" });
      }
    }
  };

  const onSubmitHandler = async (handleClose) => {
    const { staff, from, to, note, vacation_type } = vacation;
    let vacationData = { staff, from, vacation_type };
    setErrors(validation(vacationData));
    let errorResponse = validation(vacationData);
    // console.log(vacation)
    if (objectKeyConvertToArray(errorResponse)?.length === 0) {
      const body = {
        employee: staff,
        from_date: from,
        to_date: to,
        note: note,
        business: businessId,
        is_vacation: true,
        vacation_type: vacation_type
      };
      setLoading(true);

      const response = await addVacation(body, access_token);
      if (response?.data?.status == 200) {
        // dispatch(add_Vacation(vacationResponse));
        let payload = {
          location_id: selected_location
        }
        dispatch(getWorkingShedule(payload));
        handleGetVacationList(1, selected_location, filterStaff, searchText, access_token);
        setLoading(false);
        toast.success(response?.data?.response?.message, { toastId: "toast" });
        setVacation({
          ...vacation,
          staff: "",
          from: "",
          to: "",
          description: "",
        });
        setCurrentPage(1)
        handleGetVacationList(1, selected_location, filterStaff, searchText, access_token);
        handleGetVacationListWithoutPagination(selected_location, filterStaff, searchText, access_token)
        handleClose();
      } else if (response?.data?.status == 400) {
        setLoading(false);
        toast.error(response?.data?.response?.message, { toastId: "toast" });
      } else {
        setLoading(false);
        toast.error("Error in create vacation", { toastId: "toast" });
      }
    }
  };

  return {
    vacationHandleChange, handleGetVacationList, setVacation, setSearchText, setFilterStaff, setOpenVacation, setDeleteId,
    setIsEdit, setDeleteLoading, setDeletePopup, setEditPopup, navigate, onBlurHandler, vacationDeleteHandler, onSubmitHandler,
    onSubmitEditHandler, employeeListRedux, disbledValidation, vacationList, vacation, searchText, isEdit, openVacation,
    deletePopup, editPopup, deleteId, employeeDropdownList, errors, loading, deleteLoading, filterStaff, state, date,
    currentPage, setCurrentPage, pages, allVacationList, allCount, loader, employeeSearch, setEmployeeSearch, employeePage,
    setEmployeePage, employeeLoader, setEmployeeLoader, employeePaginationData, employeeRedux
  };
};
