import React, { useEffect, useState } from "react";

import Popup from "Components/TenantApp/Elements/Popup";
import Input from "new-components/input";

import Selection from "new-components/dropdown";

import { useAddStaffTarget } from "./helper";
import { useStaffTarget } from "../helper";
import { MONTHS } from "Constants/Data/Months";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import AddEmployeePopup from "Components/TenantApp/Dashboard/Employee/AddEmployee/AddEmployeePopup";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import { getEmployeeList } from "Adapters/Api/employee";

function AddStaffTarget({
  updateStockData,
  handleClose,
  updateData,
  isUpdate,
  selectedLanguage,
  translation,
  allStaffTargetList
}) {
  const {
    handleSubmit,
    handleChange,
    register,
    onSubmit,
    errors,
    control,
    isValid,
    isLoading,
    staffDropdownList,
    currency,
    monthDropdown,
    yearDropdown,
    locationDetail,
    YearValue, setYearValue,
    MonthValue, setMonthValue,
    staff, setStaff,
    staffTargetfilter,
    serviceTargetValue, setServiceTargetValue,
    retailTargetValue, setRetailTargetValue,
    navigate, dispatch,
    employeeSearch, setEmployeeSearch, employeePage, setEmployeePage, employeeLoader, setEmployeeLoader, employeePaginationData
  } = useAddStaffTarget({
    handleClose,
    updateData,
    isUpdate,
  });
  const [showAddStaffPopup, setShowAddStaffPopup] = useState(false)

  let Month_value = []
  let Year_value = []
  let staff_value = []

  staffTargetfilter()?.forEach((str) => {
    Month_value = [
      ...Month_value,
      str?.month?.toLowerCase()
    ]
    Year_value = [
      ...Year_value,
      str?.year?.slice(0, 4)
    ]
    staff_value = [
      ...staff_value,
      str?.employee?.id
    ]
  })

  // Function to generate a list of months for a given year
  function generateMonthsList(year) {
    const months = [];
    allStaffTargetList.forEach(item => {
      const itemYear = item.year.split('-')[0];
      if (itemYear === year && item.month && item?.employee?.id == staff) {
        months.push(item.month);
      }
    });
    return months;
  }
  let monthsForSelectedYear = generateMonthsList(YearValue);

  // If isUpdate is true, remove MonthValue from monthsForSelectedYear
  if (isUpdate && MonthValue) {
    monthsForSelectedYear = monthsForSelectedYear?.filter(month => month !== MonthValue);
  }

  const filteredMonths = monthDropdown?.filter(dt => {
    // Check if the month is not in 'monthsForSelectedYear'
    return !monthsForSelectedYear.includes(dt?.label);
  });
  // const filteredOptions = monthDropdown?.filter(dt => staff_value?.includes(staff) ? (Year_value.includes(YearValue) ? !Month_value.includes(dt?.label?.toLowerCase()) : true) : true)
  // const filteredMonths = filteredOptions?.filter(monthOption => {
  //   const monthLabel = monthOption?.label?.toLowerCase();

  //   if (Year_value.includes(YearValue)) {
  //     return !Month_value.includes(monthLabel);
  //   }

  //   return true; // Allow all months if no year available
  // });
  return (
    <>
      <Popup
        heading={isUpdate
          ? selectedLanguage?.name == "English" ? "Update Staff Target" : translation[844]?.value ? translation[844]?.value : "Update Staff Target"
          : selectedLanguage?.name == "English" ? "Add Staff Target" : translation[841]?.value ? translation[841]?.value : "Add Staff Target"
        }
        onclose={handleClose}
        size='md'
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4">
            <div className="grid md:grid-cols-1 gap-4">
              <PaginationDropdown
                required
                title={selectedLanguage?.name == "English" ? "Staff" : translation[335]?.value ? translation[335]?.value : "Staff"}
                placeholder={selectedLanguage?.name == "English" ? "Select Staff" : translation[334]?.value ? translation[334]?.value : "Select Staff"}
                name={"staff"}
                value={staff}
                onChange={(e) => setStaff(e.target.value)}
                error={errors?.staff?.message}
                options={staffDropdownList}
                control={control}
                page={employeePage}
                setPage={setEmployeePage}
                setDropdownSearch={setEmployeeSearch}
                dropdownSearch={employeeSearch}
                lastPage={employeePaginationData?.employeePages}
                currentPage={employeePaginationData?.employeeCurrentPage}
                loading={employeeLoader}
              />

              {/* <Selection
                onChange={handleChange}
                options={}
                errorMessage={}
                isDisabled={staffDropdownList?.length == 0 ? true : false}
              /> */}
            </div>
            {staffDropdownList?.length == 0 &&
              <p>
                {
                  selectedLanguage?.name == "English"
                    ? "There is no staff available at this location, click"
                    : translation[847]?.value
                      ? translation[847]?.value
                      : "There is no staff available at this location, click"
                }
                <span className="cursor-pointer underline mx-1 text-[#5679FF]"
                  onClick={() => {
                    setShowAddStaffPopup(!showAddStaffPopup);
                    // navigate('/dashboard/employee/employees/add-employee')
                  }}
                >
                  {selectedLanguage?.name == "English" ? "here" : translation[502]?.value ? translation[502]?.value : "here"}
                </span>
                {selectedLanguage?.name == "English" ? "to add." : translation[140]?.value ? translation[140]?.value : "to add."}
              </p>
            }
            <div className="grid md:grid-cols-2 gap-2">
              <Selection
                name={"year"}
                control={control}
                label={<>
                  {selectedLanguage?.name == "English" ? "Year" : translation[376]?.value ? translation[376]?.value : "Year"}{" "}
                  <span className="text-red-600">*</span>
                </>}
                placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                setVALUEEEE={setYearValue}
                VALUEEEE={YearValue}
                // onBlur={handleChange}
                // isDisabled={!staff}
                options={yearDropdown}
                errorMessage={errors?.year?.message}
              />
              <Selection
                name={"month"}
                control={control}
                label={<>
                  {selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}{" "}
                  <span className="text-red-600">*</span>
                </>}
                placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                // onBlur={handleChange}
                // options={monthDropdown.filter(dt => staff_value?.includes(staff) ? (Year_value.includes(YearValue) ? !Month_value.includes(dt?.label?.toLowerCase()) : true) : true)}
                setVALUEEEE={setMonthValue}
                VALUEEEE={MonthValue}
                options={filteredMonths}
                errorMessage={errors?.month?.message}
              // isDisabled={!staff || !YearValue}
              />
              <Input
                type={"number"}
                rightIcon={<p className="text-sm">{locationDetail?.currency?.code}</p>}
                // required={true}
                name={"serviceTarget"}
                title={<>
                  {selectedLanguage?.name == "English" ? "Service Target" : translation[817]?.value ? translation[817]?.value : "Service Target"}{" "}
                  <span className="text-red-600">*</span>
                </>}
                placeholder={selectedLanguage?.name == "English" ? "Enter Service Target" : translation[842]?.value ? translation[842]?.value : "Enter Service Target"}
                currencyCode={currency}
                register={register}
                value={serviceTargetValue}
                setValue={setServiceTargetValue}
                errorMessage={errors?.serviceTarget?.message}
              />
              <Input
                type={"number"}
                rightIcon={<p className="text-sm">{locationDetail?.currency?.code}</p>}
                // required={true}
                name={"retailTarget"}
                title={<>
                  {selectedLanguage?.name == "English" ? "Retail Target" : translation[819]?.value ? translation[819]?.value : "Retail Target"}{" "}
                  <span className="text-red-600">*</span>
                </>}
                placeholder={selectedLanguage?.name == "English" ? "Enter Retail Target" : translation[843]?.value ? translation[843]?.value : "Enter Retail Target"}
                currencyCode={currency}
                register={register}
                value={retailTargetValue}
                setValue={setRetailTargetValue}
                errorMessage={errors?.retailTarget?.message}
              />
            </div>

            <LoginBtn
              text={isUpdate ?
                selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
              }
              loading={isLoading}
              disabled={isLoading}
            // disabled={!isValid
            //   || isLoading}
            />
          </div>
        </form>
      </Popup>

      {showAddStaffPopup && (
        <AddEmployeePopup
          open={showAddStaffPopup}
          close={setShowAddStaffPopup}
          success={() => {
            dispatch(getEmployeeList());
          }}
        />
      )}
    </>
  );
}

export default AddStaffTarget;