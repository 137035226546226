import Svgs from 'Assets/svgs'
import LabelAvatar from 'Components/TenantApp/Elements/LabelAvatar'
import React from 'react'

const GroupSelectedClients = ({animate,data, index, selectedClient, onCloseClick, isSelected, ...props }) => {
    return (
        
        animate ? (<>
        <div className='animate-pulse flex items-center justify-between pr-2 transition-all cursor-pointer hover:bg-[#ecf4ff] border-b'>
            <div className='p-2 flex items-center gap-4 '>
            <div>
                <div className='h-[2.5rem] w-[2.5rem] rounded-full bg-gray-300 animate-pulse'></div>
            </div>
            <div className='space-y-1'>
                <h1 className='h-[1.6rem] w-[10rem] rounded-md text-lg text-primary font-semibold bg-gray-300 animate-pulse'></h1>
                <p className='h-[1rem] w-[8rem] rounded-md text-xs text-[#A1A1A1] bg-gray-300 animate-pulse'></p>
            </div>
            </div>
        </div>
      </>) : (
            selectedClient ? (
                <div className={`flex items-center justify-between pr-2 transition-all cursor-pointer first:rounded-t-xl last:rounded-b-xl hover:bg-[#ecf4ff] ${selectedClient == data?.client ? 'bg-[#ecf4ff]' : ''} ${isSelected ? 'border border-solid border-[#5679FF]' : 'border-b'} `} onClick={props.onClick}>
                    <div className='p-2 flex items-center gap-4 '>
                        <div>
                            <div className='h-[2.5rem] w-[2.5rem] rounded-full overflow-hidden group/item group relative'>
                                {data?.client_image ?
                                    <div className="h-full w-full relative cursor-pointer">
                                        <div className="h-full w-full absolute transition-all"></div>
                                        <img
                                            src={data?.client_image}
                                            className="h-full w-full object-cover absolute " />
                                    </div>
                                    :
                                    <div className="h-full w-full relative cursor-pointer">
                                        <div className=" h-full w-full absolute transition-all"></div>
                                        <LabelAvatar fontSize={'text-lg'} iconLabel={data?.client_name} index={index} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className=''>
                            <h1 className='text-lg text-primary font-semibold'>{data?.client_name}</h1>
                            <>
                                <p className='text-xs text-[#A1A1A1]'>{data?.client_phone}</p>
                            </>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='flex items-center justify-between pr-2 transition-all cursor-pointer hover:bg-[#ecf4ff] border-b' onClick={props.onClick}>
                    <div className='p-2 flex items-center gap-4 '>
                        <div>
                            <div className='h-[2.5rem] w-[2.5rem] rounded-full overflow-hidden'>
                                {data?.client_image ?
                                    <img
                                        src={data?.client_image}
                                        className="h-full w-full object-cover" />
                                    :
                                    <LabelAvatar fontSize={'text-lg'} iconLabel={data?.client_name} index={index} />
                                }
                            </div>
                        </div>
                        <div className=''>
                            <h1 className='text-lg text-primary font-semibold'>{data?.client_name}</h1>
                            <>
                                <p className='text-xs text-[#A1A1A1]'>{data?.client_phone}</p>
                            </>
                        </div>
                    </div>
                </div>
            )
        )
    )
}

export default GroupSelectedClients