import Svgs from "Assets/svgs"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"





export const FreeItemSpendAmountCard = ({ selected, data, onRemoveOffer, onSelect }) => {
    const state = useSelector(state => state)

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>
            <div
                className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? "border-black" : " border-[#B8C6F5]"
                    }  border   rounded-md p-4 mt-1 cursor-pointer`}
                onClick={() => onSelect && onSelect(data)}
            >
                <div className="flex items-center justify-between mb-3">
                    <p className="text-[#A1A1A1] font-bold">
                        {selectedLanguage?.name == "English" ? "Spend Some amount and Get Free Item" : translation[1218]?.value ? translation[1218]?.value : "Spend Some amount and Get Free Item"}
                    </p>
                    {onRemoveOffer && selected &&
                        <button
                            className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                            onClick={onRemoveOffer}
                        >
                            <Svgs.Delete fill="#767A87" />
                        </button>
                    }
                </div>
                <div className="text-black font-semibold mb-1 w-full">{data?.promotion_name}</div>

                <div className="">
                    <div className="flex items-center justify-between">
                        <p className="text-[#2a2a2a] font-bold">
                            {selectedLanguage?.name == "English" ? "Free Service" : translation[1062]?.value ? translation[1062]?.value : "Free Service"}
                        </p>
                        <span className="text-[#2a2a2a] font-bold">
                            {selectedLanguage?.name == "English" ? "Spend Amount" : translation[1042]?.value ? translation[1042]?.value : "Spend Amount"}
                        </span>
                    </div>
                    {
                        data?.spend_service && data?.spend_service?.map((fr_serv, index) => {
                            return (
                                <>
                                    <div
                                        key={index}
                                        className="flex mb-1 items-center w-full justify-between p-2 rounded-md"

                                    >
                                        <p className="text-[#5b5b5b] line-clamp-1 ">{fr_serv?.service_name}</p>
                                        <span className="whitespace-nowrap font-medium">AED {fr_serv?.spend_amount}</span>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

FreeItemSpendAmountCard.defaultProps = {
    data: {},
    selected: false,
    onSelect: () => { }
}

const FreeItemSpendAmount = ({ setNotFound, spend_some_amount, MODULE_TYPE, servicesRedux, onSelect }) => {

    const [spend_data, setSpendData] = useState([])

    const dispatch = useDispatch()

    const handle_select_appointment = (data) => {
        let today_date = new Date()
        today_date = `${today_date.getFullYear()}-${today_date.getMonth() + 1}-${today_date.getDate()}`

        let apps = []
        let total = 0
        data?.spend_service?.forEach(service => {
            let appointment = {
                appointment_date: today_date,
                client_can_book: service.client_can_book,
                duration: "30min",
                member: undefined,
                price: 0,
                service: service.service,
                slot_availible_for_online: service.slot_availible_for_online,
                srv_duration: "30min",
                srv_name: service?.service_name
            }
            total = total + service.spend_amount
            apps.push(appointment)
        })
        onSelect(
            apps,
            false,
            {
                total_required_ammount: total,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
                selected_promotion: data,
            })
    }


    const handle_select_service = (data) => {
        let total_required_ammount = 0
        let services = data?.spend_service?.map(service => {
            total_required_ammount += service?.spend_amount
            return {
                id: service?.service,
                name: service?.service_name,
                selection_type: "SERVICE",
                quantity: 1,
                price: 0,
                discount_price: undefined,
                client_can_book: service?.client_can_book,
                slot_availible_for_online: service?.discount_service_slot_availible_for_online,
            }
        })

        dispatch({
            type: "ADD_BULK_ITEMS_TO_CART",
            payload: {
                'services': services,
                required_amount: total_required_ammount,
                is_promotion: true,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
            }
        });
        toast.success("Items added to cart", { toastId: "toast" });
        onSelect(
            [],
            false,
        )
    }

    const handle_select = (data) => {
        let funcs = {
            'APPOINTMENT': () => handle_select_appointment(data),
            'SERVICE': () => handle_select_service(data),
        }
        funcs[MODULE_TYPE] && funcs[MODULE_TYPE]()
    }

    useEffect(() => {
        let spnd_amnt = [
            ...spend_some_amount
        ]

        spnd_amnt.forEach(itm => {
            itm.spend_service?.forEach(service => {
                let this_service = servicesRedux?.find(srv => srv.id == service.service)
                if (this_service) {
                    service.service_name = this_service.name
                    service.client_can_book = this_service.client_can_book
                    service.slot_availible_for_online = this_service.slot_availible_for_online
                }
            })
        })

        setSpendData(spnd_amnt)
    }, [servicesRedux])


    return (
        <>
            {
                spend_data?.map((brnd_grp, index) => {
                    return (
                        <>
                            <FreeItemSpendAmountCard
                                data={brnd_grp}
                                key={index}
                                onSelect={handle_select}
                            />
                        </>
                    )
                })
            }
        </>
    )
}

FreeItemSpendAmount.defaultProps = {
    spend_some_amount: []
}


export default FreeItemSpendAmount