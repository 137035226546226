import { HOLIDAYS } from "Redux/TenantRedux/ActionTypes/holidayTypes"

export const getAllHolidays = (data) => {
    return {
        type: HOLIDAYS.GET_HOLIDAYS,
        payload: data
    }
}

export const addHolidays = (data) => {
    return {
        type: HOLIDAYS.ADD_HOLIDAYS,
        payload: data
    }
}
export const deleteHolidays = (data) => {
    return {
        type: HOLIDAYS.DELETE_HOLIDAYS,
        payload: data
    }
}
export const updateHolidays = (data) => {
    return {
        type: HOLIDAYS.UPDATE_HOLIDAYS,
        payload: data
    }
}
