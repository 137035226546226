import { VACATION } from "Redux/TenantRedux/ActionTypes/vacationTypes";

export const add_Vacation = (data) => {
  return {
    type: VACATION.ADD_VACATION,
    payload: data,
  };
};

// export const get_Vacations = (data) => {
//   return {
//     type: VACATION.GET_VACATIONS,
//     payload: data,
//   };
// };

export const delete_Vacation = (id) => {
  return {
    type: VACATION.DELETE_VACATION,
    payload: id,
  };
};

export const update_Vacation = (data) => {
  return {
    type: VACATION.UPDATE_VACATION,
    payload: data,
  };
};