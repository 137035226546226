import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Svgs from "../../../../../Assets/svgs";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import LoginInput from "../../../Elements/LoginInput";
import Popup from "../../../Elements/Popup";
import { useSelector } from "react-redux";

const GoodReceipt = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  const [AddGoodReceipt, setAddGoodReceipt] = useState(false);

  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
      <p className="text-[#7B7B7B] text-sm">
        <span
          className="cursor-pointer"
          onClick={() => {
            navigate("/dashboard/account/");
          }}
        >
          My Account
        </span>{" "}
        &gt;{" "}
        <span
          className="cursor-pointer"
          onClick={() => {
            navigate("/dashboard/account/inventory-operations");
          }}
        >
          Inventory Operations
        </span>{" "}
        &gt; <span className="text-primary font-semibold">Good Receipt</span>
      </p>
      <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
        <div className="flex items-center sm:gap-4 gap-2">
          
          <h2 className="text-primary font-bold lg:text-3xl text-xl">
            Good Receipt
          </h2>
        </div>
      </div>
      <div className="border rounded-lg">
        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">Good Receipt</h2>
              <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                <p className="text-[#101928]">10 Stock Items</p>
              </div>
            </div>
            <div className="mb-00">
              <LoginInput
                title=""
                placeholder="Search"
                padding="py-1"
                leftIcon={<Svgs.Search />}
              />
            </div>
          </div>
          <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
            <LoginBtn>
              <div className="flex items-center gap-2">
                <Svgs.Filters fill="#fff" />
              </div>
            </LoginBtn>
            {!employee ? (
              <LoginBtn>
                <div className="flex items-center gap-2">
                  <Svgs.Export fill="#fff" />
                </div>
              </LoginBtn>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="overflow-x-auto">
          <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(155px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
            <p>ID</p>
            <p>Product</p>
            <p>Quantity</p>
            <p>Location</p>
            {!employee ||
              (employee &&
                employeePermissions?.inventory_goods_receipt?.includes(
                  "create"
                )) ? (
              <p>Generate GRN</p>
            ) : (
              ""
            )}
          </div>
          <div className={`odd-bg text-[#3A3A3A] transition-all`}>
            <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(155px,1fr))]">
              <div className="flex items-center">
                <p className="text-sm">5 Category</p>
              </div>
              <div className="flex items-center">
                <p className="text-sm">123</p>
              </div>
              <div className="flex items-center">
                <p className="text-sm">5</p>
              </div>
              <div className="flex items-center">
                <p className="text-sm">5</p>
              </div>
              <div className="flex items-center">
                {!employee ||
                  (employee &&
                    employeePermissions?.inventory_goods_receipt?.includes(
                      "create"
                    )) ? (
                  <LoginBtn
                    text="Generate GRN"
                    onClick={() => {
                      setAddGoodReceipt(!AddGoodReceipt);
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {AddGoodReceipt && (
        <div
          className={`fixed inset-0 h-[100vh] w-[100vw] z-[4444] flex justify-center items-center`}
        >
          <div className="bg-[#101928]/30 absolute inset-0 z-[1]"></div>
          <div
            className={`relative overflow-hidden rounded-2xl lg:w-[26vw] w-[90vw] min-w-[30rem] z-[2] slide-in-elliptic-top-fwd`}
          >
            <div>
              <div className="relative ring-offset-black top-0 left-1/2 -translate-x-1/2 translate-y-1/2 border-2 border-white bg-white rounded-full w-fit">
                <Svgs.CircleCheck size="5rem" />
              </div>
            </div>
            <div className="bg-white rounded-t-2xl shadow-2xl">
              <div className="sticky top-0 z-[22]">
                <div
                  className="absolute top-0 right-0 bg-primary px-[1.6rem] py-[1rem] rounded-bl-2xl rounded-tr-2xl cursor-pointer"
                  onClick={() => {
                    setAddGoodReceipt(!AddGoodReceipt);
                  }}
                >
                  <Svgs.Close />
                </div>
                <h1 className={`p-[1rem] font-semibold text-xl text-left`}></h1>
              </div>
              <div className="p-[1.2rem] overflow-y-auto overflow-x-hidden scroll-hidden max-h-[75vh] text-center flex flex-col gap-5">
                <h1 className="font-bold text-xl">Invoice #12</h1>
                <div className="max-w-[80%] w-fit bg-[#F9F9F9] text-[#7B7B7B] border rounded-md mx-auto p-2 px-4 flex items-center gap-2 text-sm text-center">
                  <div>
                    <p>2022-11-16</p>
                  </div>
                  <div className="h-[1.5rem] w-[1px] border-l"></div>
                  <div>
                    <p>
                      Billed to <span className="font-bold">Walk In</span>
                    </p>
                  </div>
                </div>

                <div className="text-left flex flex-col gap-1">
                  <div className="flex items-center justify-between text-[#7B7B7B] text-sm">
                    <p>Item</p>
                    <p>Amount</p>
                  </div>
                  <div className="flex items-center justify-between text-primary text-sm font-semibold">
                    <p>Hair Cream</p>
                    <p>
                      <span className="text-[#7B7B7B]">1x</span> AED 200
                    </p>
                  </div>
                </div>

                <div className="text-left flex flex-col gap-1">
                  <div className="flex items-center justify-between text-primary text-sm font-bold">
                    <p>Subtotal</p>
                    <p>AED 405</p>
                  </div>
                  <div className="flex items-center justify-between text-[#7B7B7B] text-sm">
                    <p>Rija 5%</p>
                    <p>AED 50</p>
                  </div>
                  <div className="flex items-center justify-between text-[#7B7B7B] text-sm">
                    <p>Rija 5%</p>
                    <p>AED 50</p>
                  </div>
                  <div className="flex items-center justify-between text-primary text-sm font-bold">
                    <p>Total</p>
                    <p>AED 535</p>
                  </div>
                </div>

                <div className="text-left flex flex-col gap-1">
                  <div className="flex items-center justify-between text-primary text-sm font-bold">
                    <p>Payment Method</p>
                  </div>
                  <div className="flex items-center justify-between text-primary text-sm">
                    <p className="text-[#7B7B7B]">
                      Cash Payment on Tuesday,
                      <br className="hidden md:block" />
                      27 Sep 2022 at 3:28pm
                    </p>
                    <p className="font-semibold">AED 200</p>
                  </div>
                </div>

                <hr />

                <div className="flex items-center justify-between text-primary text-sm font-bold">
                  <p>Total</p>
                  <p>AED 535</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GoodReceipt;
