import { CRMTYPES } from "Redux/TenantRedux/ActionTypes/CRMTypes";

const initialState = {
    deals: [],
    deals_updated: true,
    categories: []
};

const CRMReducer = (state = initialState, action) => {

    const { type, payload } = action;
    switch (type) {

        case CRMTYPES.GET_DEALS:
            return {
                ...state,
                deals_updated: true,
                deals: payload,
            };

        case CRMTYPES.GET_DEAL_CATEGORIES:
            return {
                ...state,
                categories: payload,
            };


        default:
            return state;
    }
};
export default CRMReducer;
