import { LANGUAGE } from './../../ActionTypes/languageTypes/index';



export const addLanguages = (data) => {
    return {
        type: LANGUAGE.ADD_LANGUAGE,
        payload: data
    }
}

export const deleteLanguage = (data) => {
    return {
        type: LANGUAGE.DELETE_LANGUAGE,
        payload: data.id
    }
}


export const selected_language = (data) => {
    return {
        type: LANGUAGE.SELECTED_LANGUAGE,
        payload: data
    }
}


export const updateLanguagesArray = (data) => {
    return {
        type: LANGUAGE.UPDATE_LANGUAGE,
        payload: data
    }
}

export const getLanguagesTranslation = (data) => {
    return {
        type: LANGUAGE.GET_LANGUAGE_TRANSLATION,
        payload: data
    }
}