import React from 'react'

const CalendarRef = ({AllEmployee,calendarContainerRef}) => {
  return (
    <div className="h-[1px] opacity-0 overflow-y-hidden" style={{ width: `${AllEmployee?.length > 7 ? `${((AllEmployee.length - 7) * 36).toFixed(0)}%` : "100%"}` }}>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: `7rem 1fr`,
                }}
              >
                <div></div>
                <div className="flex-1 relative box-border">
                  <div
                    className="absolute h-full w-full "
                    ref={calendarContainerRef}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: `repeat(${AllEmployee?.length || 1}, minmax(15rem, 2fr))`,

                    }}
                  >
                    {AllEmployee?.map((employee, index) => (
                      <div key={index} style={{ border: '1px solid black', width: `${AllEmployee?.length > 7 ? `${((AllEmployee.length - 7) * 36).toFixed(0)}%` : "100%"}` }}>
                        {employee.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
  )
}

export default CalendarRef