import React from 'react'
import { useState } from 'react'
import { useSelector } from "react-redux";
let initialState = {
}

const useGroupPopup = () => {
    const [GroupForm, setGroupForm] = useState(initialState)
    const [filterPopup, setFilterPopup] = useState(false)
    const [toggleDateRange, setToggleDateRange] = useState(false)

        const state = useSelector(state => state)
        const translation = state?.languages?.language_translation
        const selectedLanguage = state?.languages?.selected_language

    return {
        filterPopup, setFilterPopup,
        toggleDateRange, setToggleDateRange,
        translation, selectedLanguage,
    }
}

export default useGroupPopup