import axios from "axios";
import { BASE_URL, add_expense_category_url, create_expense_payment_url, create_expense_url, delete_expense_category_url, delete_expense_payment_url, delete_expense_url, get_expense_analytics_url, get_expense_category_url, get_expense_payment_url, get_expense_url, update_expense_category_url, update_expense_payment_url, update_expense_url } from "../api_variables";
import { toast } from "react-toastify";
import { getExpenseAnalyticsAction, getExpenseCategories, getExpenseCategoriesNoPagination, getExpensePayments, getExpenses } from "Redux/TenantRedux/Actions/expense";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";

export const addExpenseCategory = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.post(`${BASE_URL}${add_expense_category_url}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success()
            }
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }
}

export const getExpenseCategory = (access_token,payload, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const result = await axios.get(`${BASE_URL}${get_expense_category_url}${query}`, headers);
        if (result?.status === 200) {
            const data = result?.data
            dispatch(getExpenseCategories(data));
            success && success()
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        fail && fail(error);
        return error;
    }
}

export const getExpenseCategoryNoPagination = (access_token,payload, success, fail) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const result = await axios.get(`${BASE_URL}${get_expense_category_url}${query}`, headers);
        if (result?.status === 200) {
            const data = result?.data
            dispatch(getExpenseCategoriesNoPagination(data));
            success && success()
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        fail && fail(error);
        return error;
    }
}

export const deleteExpenseCategory = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.delete(`${BASE_URL}${delete_expense_category_url}?id=${payload}`, {
            headers: {
                Authorization: `Token ${access_token}`,
            },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success()
            } else
                toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        toast.error(apiErrorMessage, { toastId: "toast" })
        return error;
    }
}

export const updateExpenseCategory = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.put(`${BASE_URL}${update_expense_category_url}`, payload, {
            headers: {
                Authorization: `Token ${access_token}`,
                'Content-Type': 'multipart/form-data' // Important for files
            },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success()
            } else
                toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        fail && fail()
        toast.error(apiErrorMessage, { toastId: "toast" })
        return error;
    }
}

export const addExpense = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.post(`${BASE_URL}${create_expense_url}`, payload, {
            headers: {
                Authorization: `Token ${access_token}`,
                'Content-Type': 'multipart/form-data' // Important for files

            },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success()
            } else
                toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        toast.error(apiErrorMessage, { toastId: "toast" })
        return error;
    }
}
export const getExpense = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };

        const query = buildQueryString(payload)
        const result = await axios.get(`${BASE_URL}${get_expense_url}${query}`, headers);
        if (result?.status === 200) {
            const data = result?.data
            dispatch(getExpenses(data));
            success && success()
        } else {
            fail && fail(result)
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        toast.error(apiErrorMessage, { toastId: "toast" })
        return error;
    }
}

export const updateExpense = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.put(`${BASE_URL}${update_expense_url}`, payload, {
            headers: {
                Authorization: `Token ${access_token}`,
                'Content-Type': 'multipart/form-data' // Important for files
            },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success()
            } else
                toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        fail && fail()
        toast.error(apiErrorMessage, { toastId: "toast" })
        return error;
    }
}

export const deleteExpense = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.delete(`${BASE_URL}${delete_expense_url}?id=${payload}`, {
            headers: {
                Authorization: `Token ${access_token}`,
            },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success()
            } else
                toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        toast.error(apiErrorMessage, { toastId: "toast" })
        return error;
    }
}

export const addExpensePayment = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.post(`${BASE_URL}${create_expense_payment_url}`, payload, {
            headers: {
                Authorization: `Token ${access_token}`,
                'Content-Type': 'multipart/form-data' // Important for files
            },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success()
            } else
                toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        toast.error(apiErrorMessage, { toastId: "toast" })
        return error;
    }
}

export const getExpensePayment = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };

        const query = buildQueryString(payload)
        const result = await axios.get(`${BASE_URL}${get_expense_payment_url}${query}`, headers);
        if (result?.status === 200) {
            const data = result?.data
            dispatch(getExpensePayments(data));
            success && success()
        } else {
            fail && fail(result)
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        toast.error(apiErrorMessage, { toastId: "toast" })
        return error;
    }
}

export const updateExpensePayment = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.put(`${BASE_URL}${update_expense_payment_url}`, payload, {
            headers: {
                Authorization: `Token ${access_token}`,
                'Content-Type': 'multipart/form-data' // Important for files
            },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success()
            } else
                toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        fail && fail()
        toast.error(apiErrorMessage, { toastId: "toast" })
        return error;
    }
}

export const deleteExpensePayment = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.delete(`${BASE_URL}${delete_expense_payment_url}?id=${payload}`, {
            headers: {
                Authorization: `Token ${access_token}`,
            },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 200) {
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success()
            } else
                toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
            return;
        } else {
            toast.error(result?.data?.response?.message, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        toast.error(apiErrorMessage, { toastId: "toast" })
        return error;
    }
}

export const getExpenseAnalytics = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };

        const query = buildQueryString(payload)
        const result = await axios.get(`${BASE_URL}${get_expense_analytics_url}${query}`, headers);
        if (result?.status === 200) {
            const data = result?.data
            dispatch(getExpenseAnalyticsAction(data));
            success && success()
        } else {
            fail && fail(result)
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        toast.error(apiErrorMessage, { toastId: "toast" })
        return error;
    }
}