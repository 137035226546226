import React from 'react'
import Shimmer from './Shimmer'
import { useState } from 'react';
import { useEffect } from 'react';

const SidebarShimmer = ({ size }) => {
    const [state, setState] = useState([])

    useEffect(() => {
        let arr = [];
        for (let index = 0; index < size; index++) {
            arr.push(index)
        };
        setState(arr)
    }, [size])

    return (
        state.map(ele => {
            return <Shimmer className={'px-3 py-6 cursor-pointer transition-all rounded-lg bg-[#ddd] text-primary'} />
        })
    )
}

export default SidebarShimmer