import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


const useMarketingAnalytics = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const reduxlocationsList = state?.locations?.locations;
  const [selected_location, setSelected_location,] = useState();

  const [FilterDetails, setFilterDetails] = useState({
    promotions: false,
    email_campaign: false,
    call_outcome: false,
    services: false,
    smart_cash_usage: false,
    customer_initiated_discounts: false,
  })
  const [toggleDateRange, setToggleDateRange] = useState(false)
  const [start_date, setStartDate] = useState("")
  const [end_date, setEndDate] = useState("")

  return {
    translation, selectedLanguage, navigate, FilterDetails, setFilterDetails,
    toggleDateRange, setToggleDateRange, start_date, setStartDate, end_date, setEndDate,
    reduxlocationsList, selected_location, setSelected_location
  }
}

export default useMarketingAnalytics