import React from "react";
import Products from "./Products";
import Services from "./Services";
import Vouchers from "./Vouchers/Vouchers";
import Membership from "./Membership/Membership";
import LocationSelection from "new-components/location";
import { useQuickSale } from "./helper";
import Svgs from "Assets/svgs";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import Popup from "Components/TenantApp/Elements/Popup";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
import NoSalesFound from "../Elements/NoSalesFound";
import SelectedInvoiceModal from "../sale-history/SelectedInvoiceModal";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import AddEmployeePopup from "../../Employee/AddEmployee/AddEmployeePopup";
import { useState } from "react";
import moment from "moment";
import { getEmployeeListSalesCart } from "Adapters/Api/employee";
import GiftCard from "./GiftCard/GiftCard";
import GroupInvoiceModal from "../sale-history/GroupInvoiceModal";

// import NoSaleHistoryFound from "../Elements/NoSaleHistoryFound";
// import useSaleModule from "../helper";
// import NotFound from "../Elements/NotFound";

const QuickSales = () => {
  const { navigate, setSearchParams, searchParams, tabList, active_tab, employeePopup, setEmployeePopup, setInvoicePopup,
    employee_updated, translation, selectedLanguage, loading, recentSaleorders, toggleInviceModal, location_currency, InvoicePopup,
    calculate_totals, selectedInvice, loader, invoice_titles, getInvoiceDetailData, setSelectedInvice, detailLoading,
    selected_location, setLoader, dispatch, getSaleHistory, storeDataInLocalStorage
  } = useQuickSale();



  const tabsList = {
    services: <Services isLoading={loader} />,
    products: <Products isLoading={loader} />,
    memberships: <Membership isLoading={loader} />,
    vouchers: <Vouchers isLoading={loader} />,
    gift_card: <GiftCard isLoading={loader} />,
  };

  const [showAddStaffPopup, setShowAddStaffPopup] = useState(false)

  return loader ? <PreloaderFull /> : (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right relative">
      <Breadcrumb url={"/dashboard/sales/"}
        first={selectedLanguage?.name == "English" ? "Sales" : translation[3]?.value ? translation[3]?.value : "Sales"}
        second={selectedLanguage?.name == "English" ? "Quick Sales" : translation[148]?.value ? translation[148]?.value : "Quick Sales"}
      />

      <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

        <div className="flex items-center gap-4">
          <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
            {selectedLanguage?.name == "English" ? "Quick Sales" : translation[148]?.value ? translation[148]?.value : "Quick Sales"}

          </h2>
          <LocationSelection conditionalOnClick={() => { storeDataInLocalStorage('locationChange', true) }} />
        </div>
      </div>
      <div className={"border-b mt-[2rem]"}>
        <div className="flex items-center gap-6">
          {tabList?.length > 0 &&
            tabList.map((tab, index) => {
              return (
                <div className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${active_tab == tab.value && "font-semibold border-primary"}`}
                  onClick={() => {
                    setSearchParams({ ...searchParams, active_tab: tab.value });
                  }}
                  key={index}
                >
                  <h2 className="text-primary capitalize">{tab.label}</h2>
                </div>
              );
            })}
        </div>
      </div>

      <div className="mt-[3rem] mb-[3rem]">{active_tab && tabsList[active_tab]}</div>

      <div className="border rounded-lg">
        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
            <div className="flex items-center lg:gap-4 gap-3">
              <div className="flex items-center sm:gap-4 gap-2 ">
                {loader || loading ?
                  <div className="animate-pulse rounded-md h-8 w-[10rem] mt-2 bg-gray-200"></div>
                  :
                  <h2 className="font-semibold text-xl">
                    {selectedLanguage?.name == "English" ? "Recent Sales" : translation[1264]?.value ? translation[1264]?.value : "Recent Sales"}
                  </h2>
                }
              </div>
            </div>
          </div>
        </div>
        {loading || loader ?
          <TableShimer cols={9} colsHeight={9} />
          :
          <div className="overflow-x-auto">

            <div className="inline-grid xl:grid grid-cols-[repeat(9,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
              <p>{selectedLanguage?.name == "English" ? "Invoice ID" : translation[411]?.value ? translation[411]?.value : "Invoice ID"}</p>

              <p>{selectedLanguage?.name == "English" ? "Payment Date" : translation[349]?.value ? translation[349]?.value : "Payment Date"}</p>

              <p>{selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"}</p>

              <p>{selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client"}</p>

              {/* <p>{selectedLanguage?.name == "English" ? "Method" : translation[351]?.value ? translation[351]?.value : "Method"}</p> */}

              <p>{selectedLanguage?.name == "English" ? "Total Tips" : translation[352]?.value ? translation[352]?.value : "Total Tips"}</p>

              <p>{selectedLanguage?.name == "English" ? "Sub Total" : translation[242]?.value ? translation[242]?.value : "Sub Total"}</p>

              <p>{selectedLanguage?.name == "English" ? "Tax" : translation[733]?.value ? translation[733]?.value : "Tax"}</p>

              <p>{selectedLanguage?.name == "English" ? "Total Sale" : translation[354]?.value ? translation[354]?.value : "Total Sale"}</p>

              <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
              <p></p>
            </div>
            <div
              className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
            >
              {recentSaleorders?.length > 0 ? (
                recentSaleorders?.map((item, index) => {
                  // let paymentDate;
                  // let total_amount = calculateTotalAmount(item);
                  // const totalSale = ((item?.quantity ? +item?.quantity : 1) * (total_amount ? +total_amount : 0)) +
                  // +item?.gst_price + +item?.gst_price1 +
                  //  (item?.tip?.length > 0 ? +item?.tip?.reduce(function (total, obj) {
                  //   return (((+total + +(obj.tip !== null ? obj?.tip : 0))));
                  //  }, 0) : 0) 
                  // if (item?.created_at || item?.end_date) {
                  //  const dataArr = item?.created_at
                  //    ? item?.created_at?.split("-")
                  //    : item?.end_date?.split("-");
                  //   const date_join = dataArr[2]
                  //    .split("")
                  //    .slice(0, 2)
                  //    .join("");
                  //  dataArr.splice(2, 1, date_join);
                  //  paymentDate = dataArr.join("-");
                  // }
                  //const date = new Date(paymentDate);
                  //const day = date.getDate().toString().padStart(2, '0');
                  //const month = (date.getMonth() + 1).toString().padStart(2, '0');
                  //const year = date.getFullYear();
                  //paymentDate = `${day}-${month}-${year}`;

                  return (
                    <div key={index} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(9,minmax(115px,1fr))]">
                      <div className={`before-left-arrow cursor-pointer`}>
                        <div className="h-full flex items-center">
                          <p
                            className="text-sm text-[#5679FF] font-black line-clamp-1"
                            onClick={() => {
                              getInvoiceDetailData(item?.id)
                              // toggleInviceModal(item?.id, "history")
                            }}
                          >
                            #{item?.invoice?.id
                              ?.split("-")[0]
                            }
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-1 pr-3">
                          {(item?.created_at || item?.end_date) ? moment(item?.created_at || item?.end_date).format('DD-MM-YYYY') : "----"}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm line-clamp-2">
                          {item?.checkout_type
                            ? item?.checkout_type
                            : "----"}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm">
                          {typeof item?.client == "string"
                            ? item?.client_data?.full_name
                            : "Walk In"}
                        </p>
                      </div>
                      {/* <div className="flex items-center">
                            <p className="text-sm ">
                              {item?.payment_type
                                ? item?.payment_type
                                : item?.payment_method
                                  ? item?.payment_method
                                  : "----"}
                            </p>
                          </div> */}
                      <div className="flex items-center">
                        <p className="text-sm ">
                          {(+item?.total_tip)?.toFixed(2)} {location_currency}
                          {/* {parseFloat(item?.tip?.reduce(function (total, obj) {
                                return total + (obj?.tip !== null && obj?.tip);
                              }, 0)?.toFixed(2))} {location_currency} */}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm ">
                          {(+item?.sub_total)?.toFixed(2)} {location_currency}
                          {/* {parseFloat(((item?.quantity ? item?.quantity : 1) * (total_amount ? total_amount : 0))?.toFixed(2))}
                              {" "}
                              {location_currency} */}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm ">
                          {(+item?.total_tax)?.toFixed(2)} {location_currency}
                          {/* {parseFloat((+item?.gst_price + +item?.gst_price1)?.toFixed(2))}
                              {" "}
                              {location_currency} */}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-sm ">
                          {(+item?.total_price)?.toFixed(2)} {location_currency}

                          {/* {(+item?.total_tip + +item?.subtotal + +item?.total_tax)?.toFixed(2)} {location_currency} */}
                          {/* {((item?.quantity ? item?.quantity : 1) * (total_amount ? total_amount : 0)) +
                              Math.round((total_tax * total_amount) / 100) +
                              Math.round(((item?.tip ? item?.tip : 0) * total_amount) / 100)
                            } */}
                          {/* {totalSale?.toFixed(2)} 
                               {" "}
                              {location_currency} */}
                        </p>
                      </div>

                      <div className="flex gap-2 items-center cursor-pointer">
                        <div onClick={() => {
                          getInvoiceDetailData(item?.id)
                          // toggleInviceModal(item?.id, "history")
                        }}>
                          <Svgs.Eye />
                        </div>
                        <div>
                          <Svgs.Import width="22" height="22" fill="#A0A0A0" href={item?.invoice?.file} target={true} />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoSalesFound />
              )}
            </div>
          </div>
        }

        {InvoicePopup && (
          <>
            {selectedInvice?.checkout_type == "Group Appointment" ?
              <GroupInvoiceModal
                open={InvoicePopup}
                close={() => {
                  setInvoicePopup(false)
                  setSelectedInvice({})
                }}
                invoice_titles={invoice_titles}
                selectedInvice={selectedInvice}
                selectedLanguage={selectedLanguage}
                translation={translation}
                calculate_totals={calculate_totals}
                loading={detailLoading}
              /> :
              <SelectedInvoiceModal
                open={InvoicePopup}
                close={() => {
                  setInvoicePopup(false)
                }}
                invoice_titles={invoice_titles}
                selectedInvice={selectedInvice}
                selectedLanguage={selectedLanguage}
                translation={translation}
                calculate_totals={calculate_totals}
                loading={detailLoading}
                getSaleHistory={getSaleHistory}
              />
            }
          </>
        )
        }
      </div>

      {
        (employeePopup && employee_updated) &&
        <Popup
          size={"md"}
          open={employeePopup}
          close={() => {
            setEmployeePopup()
            navigate("/dashboard/sales")
          }}
          heading={selectedLanguage?.name == "English" ? "Add Employee to Enable Quick Sales" : translation[1248]?.value ? translation[1248]?.value : "Add Employee to Enable Quick Sales"}
        >
          <h1 className="normal-case">
            {selectedLanguage?.name == "English" ? "You have no employees in your current location. Add an employee to gain access to the Quick Sale feature." : translation[1206]?.value ? translation[1206]?.value : "You have no employees in your current location. Add an employee to gain access to the Quick Sale feature."}
          </h1>
          <div className="flex justify-end items-center mt-5">
            <LoginBtn
              onClick={() => {
                setShowAddStaffPopup(!showAddStaffPopup);
              }}
            >
              {selectedLanguage?.name == "English" ? "Add Employee" : translation[465]?.value ? translation[465]?.value : "Add Employee"}
            </LoginBtn>
          </div>
        </Popup>
      }

      {showAddStaffPopup && (
        <AddEmployeePopup
          open={showAddStaffPopup}
          close={setShowAddStaffPopup}
          success={() => {
            dispatch(getEmployeeListSalesCart(selected_location, setLoader))
          }}
        />
      )}
    </div>
  );
};

export default QuickSales;
