import Svgs from 'Assets/svgs'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const NoTodayAppointmentDataFound = () => {
    const navigate = useNavigate()
    const state = useSelector((state) => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    return (
        <>
            <div className="flex items-center justify-center">
                <Svgs.NoTodayAppointment />
            </div>
            <p className="text-center text-sm">

                {selectedLanguage?.name == "English" ? "No appointments were created today." : translation[36]?.value ? translation[36]?.value : "No appointments were created today."}
                <br />
                <span onClick={() => { navigate('/dashboard/calendar/add-appointment') }}
                    className="text-[#5679FF] cursor-pointer underline px-1">
                    {selectedLanguage?.name == "English" ? "Click here." : translation[14]?.value ? translation[14]?.value : "Click here"}
                </span>

                {selectedLanguage?.name == "English" ? "to create." : translation[37]?.value ? translation[37]?.value : "to create."}

            </p>
        </>
    )
}

export default NoTodayAppointmentDataFound