import Select from "react-select";
import { Controller } from "react-hook-form";

import Svgs from "Assets/svgs";
import { useEffect } from "react";

const Selection = ({
  name,
  label,
  control,
  options,
  className,
  onBlur,
  placeholder,
  errorMessage,
  defaultValue,
  isDisabled,
  onChange,
  setVALUEEEE,
  VALUEEEE,
  children
}) => {

  // custom css style for field
  const colourStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      boxShadow: isFocused && "none",
      border: isFocused && "1px solid #e5e7eb",
      color: "#101928",
      fontSize: "0.875rem",
      padding: "0.2rem 0.2rem",
      outline: "none",
    }),
    option: (styles, { isDisabled, isFocused }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? "#f2f2f2"
          : isFocused
            ? "#2684ff14"
            : "#fff",
        color: "#101928",
        fontSize: "0.875rem",
        cursor: isDisabled ? "not-allowed" : "pointer",
      };
    },
    menu: (styles) => ({
      ...styles,
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    }),
  };

  // custom css style for field
  const errorStyle = {
    control: (styles, { isFocused }) => ({
      ...styles,
      boxShadow: isFocused ? "none" : "none",
      border: errorMessage && "1px solid #eb3b3b",
      color: "#101928",
      fontSize: "0.875rem",
      padding: "0.2rem 0.2rem",
      minHeight: "0",
    }),
    option: (styles, { isDisabled, isFocused }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? "#f2f2f2"
          : isFocused
            ? "#2684ff14"
            : "#fff",
        color: "#101928",
        fontSize: "0.875rem",
        cursor: isDisabled ? "not-allowed" : "pointer",
      };
    },
    menu: (styles) => ({
      ...styles,
      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    }),
    input: (styles) => ({
      ...styles,
    }),
  };

  return (
    <Controller
      name={name}
      control={control && control}
      defaultValue={defaultValue || null}
      render={({ field: { onBlur, onChange, value } }) => {
        return (
          <div
            className={`flex flex-col gap-2 flex-1 whitespace-nowrap relative ${className}`}
          >
            {label && <p className="text-sm">{label && label}</p>}
            <div className="relative">
              <Select
                className={`${errorMessage ? "error" : "border-0 placeholder:text-[#A1A1A1]"} `}
                menuPlacement="auto"
                placeholder={placeholder}
                styles={errorMessage ? errorStyle : colourStyles}
                options={options?.length > 0 && options}
                value={options?.find((val) => val?.value === value)}
                onChange={(e) => {
                  onChange(e.value);
                  if (setVALUEEEE) {
                    setVALUEEEE(e.value)
                  }
                }}
                menuPortalTarget={document.querySelector('body')}
                classNamePrefix="custom"
                onBlur={onBlur && onBlur}
                isDisabled={isDisabled}
                isOptionDisabled={(option) => option.disabled}
              >
              </Select>
              {children}
            </div>
            {
              errorMessage && (
                <span
                  className={
                    "text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end"
                  }
                >
                  <Svgs.I fill="#eb3b3b" />
                  {errorMessage}
                </span>
              )
            }
          </div >
        );
      }}
    />
  );
};

export default Selection;
