import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import Layout from 'Components/TenantApp/Dashboard/AdminBooking/Layout'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import RightBar from './RightBar';
import useProfileAppointment from './helper';
import { SALE_SERVICES } from 'Redux/TenantRedux/ActionTypes/saleServiceType';
import NotFound from 'Components/Utility/NotFound';

const SelectServices = ({ setStep, step }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        selectedProfileService, profileSelectedLocation,
        activeGroup, setActiveGroup,
        activeGroupServices, setActiveGroupServices,
        addedService, setAddedServices, location, isServiceExist
    } = useProfileAppointment()

    const ARR = [0, 0, 0, 0];

    return (
        <Layout
            className='!p-0'
            parent_className='!p-0'
            count={`${step}/4`}
            backClick={() => {
                navigate(-1)
            }}
            heading={'Select Services'}
            NextClick={() => {
                setStep(step + 1)
                dispatch({
                    type: SALE_SERVICES.PROFILE_SELECTED_SERIVCE,
                    payload: {
                        ...selectedProfileService,
                        selectedService: addedService,
                    }
                })
            }}
            disabled={addedService?.length === 0 || !isServiceExist}
        >
            <div className='md:grid flex flex-col grid-cols-7'>
                <div className='col-span-5 border-r'>
                    <div className='border-b flex items-center gap-[2rem] px-4 font-semibold overflow-auto'>
                        {profileSelectedLocation?.map((itm, ind) => {
                            return (
                                <div onClick={() => {
                                    setActiveGroup(itm?.id)
                                    setActiveGroupServices(itm)
                                }} className={`border-b-transparent border-b-[0.2rem] px-3 py-3 whitespace-nowrap cursor-pointer ${activeGroup === itm?.id ? 'text-[#000] border-b-black' : 'text-[#7B7B7B]'}`}>
                                    <h1>{itm?.name}</h1>
                                </div>
                            )
                        })}
                    </div>
                    <div className='px-8 pt-4 pb-8'>
                        <div className='flex flex-col gap-3 max-h-[25rem] overflow-auto'>
                            {isServiceExist ? <div className='rounded-lg shadow-md border'>
                                {
                                    activeGroupServices?.services.map((itm, i) => {
                                        return (
                                            itm?.priceservice?.filter((dt) => dt?.currency == location?.currency?.id).length > 0 &&
                                            itm?.priceservice?.filter((dt) => dt?.currency == location?.currency?.id).map((ele, i) => {
                                                return (
                                                    <div className={`${ARR.length - 1 != i && 'border-b'} px-6 py-3`}>
                                                        <div className='flex items-center gap-4 justify-between'>
                                                            <div className='flex items-center'>
                                                                <CheckBox
                                                                    checked={addedService?.find((dat) => (dat?.id === ele?.id) && (dat?.price === ele?.price) && (dat?.duration === ele?.duration)) ? true : false}
                                                                    onChange={(e) => {
                                                                        if (e?.target) {
                                                                            if (addedService?.find((it) => it?.id === ele?.id)) {
                                                                                setAddedServices((prev) => (
                                                                                    prev.filter((dt) => dt?.id != ele?.id)
                                                                                ))
                                                                            } else {
                                                                                setAddedServices([...addedService, { ...ele, service_name: itm?.name, employees: itm?.employees, group_id: itm?.id }])
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                                <div>
                                                                    <h1 className='font-semibold'>{itm?.name}</h1>
                                                                    <p className='text-sm text-[#101928]/60'>{ele?.duration?.replaceAll("_", " ")}</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h1 className='font-semibold'>
                                                                    {ele?.price}{" "} {location?.currency?.code}
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )
                                    })
                                }
                            </div>
                                :
                                <>
                                    <NotFound />
                                </>
                            }
                        </div>
                    </div>
                </div>
                <RightBar services={addedService} location={location} />
            </div>
        </Layout>
    )
}

export default SelectServices