import { getEmployeeDropDownList, getEmployeeDropDownListPaginationForWeekend } from 'Adapters/Api/employee'
import { createWeekend, updateWeekend } from 'Adapters/Api/weekend'
import useObjectKeys from 'Hooks/useObjectKeys'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import useValidations from 'Hooks/useValidations'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

let employeeTimeOutId = undefined
const useAddWeekend = ({ setCreateWeekend, data, setIsEdit, isEdit, selectedDate, successCallBack }) => {
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const dispatch = useDispatch()

    const bussiness = getDataFromLocalStorage('business_data')
    const access_token = getDataFromLocalStorage('access_token')
    const selected_location = getDataFromLocalStorage('selected_location')

    const { setErrors, validation, errors } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys();

    const state = useSelector(state => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const employeeRedux = state?.employee?.employeesDropdown
    const allEmployeeRedux = state?.employee?.employees
    const employeePaginationData = state?.employee?.employeeDropdownPaginationData

    const [weekendData, setWeekendData] = useState({
        date: selectedDate,
        employees: [],
        is_weekend: true
    })
    const [employeeSearch, setEmployeeSearch] = useState("")
    const [employeePage, setEmployeePage] = useState(1)
    const [employeeLoader, setEmployeeLoader] = useState(true)
    const [employeeSecondLoader, setEmployeeSecondLoader] = useState(false)
    const [isSelectAll, setIsSelectAll] = useState(false)
    const [submitLoader, setSubmitLoader] = useState(false)
    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [updatedEmployees, setUpdatedEmployees] = useState([])

    useEffect(() => {
        const employeeState = isSelectAll ? allEmployeeRedux : employeeRedux
        setUpdatedEmployees(employeeState)
        // Filter employees when either employeeRedux or weekendData changes
        // const filtered = employeeState?.filter((employee) =>
        //     weekendData?.employees?.includes(employee?.id)
        // );
        // setSelectedEmployees(filtered);
    }, [weekendData?.employees, employeeRedux, isSelectAll, allEmployeeRedux])

    useEffect(() => {
        if (selected_location) {
            let payload = {
                location_id: selected_location,
                search_text: employeeSearch
            }
            if (!isSelectAll && !isEdit) {
                payload = { ...payload, page: employeePage }
            } else {
                payload = { ...payload }
            }
            if (employeeSearch === null) {
                // Initial load with empty search_text
                dispatch(getEmployeeDropDownListPaginationForWeekend(payload, setEmployeeLoader))
            } else {
                if (employeeTimeOutId) {
                    clearTimeout(employeeTimeOutId)
                }
                employeeTimeOutId = setTimeout(() => {
                    dispatch(getEmployeeDropDownListPaginationForWeekend(payload, setEmployeeLoader))
                }, 500);
            }
        }
    }, [employeeSearch, selected_location, employeePage, isEdit])

    useEffect(() => {
        if (data && isEdit) {
            setWeekendData(data)
        }
        if (employeePaginationData?.employeeAllCount == data?.employees?.length) {
            setIsSelectAll(true)
        }

    }, [data, isEdit])

    useEffect(() => {
        setEmployeePage(1)
    }, [employeeSearch])

    useEffect(() => {
        if (selected_location) {
            dispatch(getEmployeeDropDownList(selected_location))
        }
    }, [selected_location])

    const handleChange = (e, all) => {
        if (e?.target) {
            const { name, value } = e.target;
            if (all) {
                if (employeePaginationData?.employeeAllCount == weekendData?.employees?.length) {
                    setWeekendData((prev) => ({
                        ...prev,
                        employees: [],
                    }));
                    setSelectedEmployees([])
                } else {
                    const employeeIds = allEmployeeRedux?.map((itm) => itm?.id)
                    setWeekendData((prev) => ({ ...prev, employees: [...employeeIds] }));
                    setErrors((prev) => ({ ...prev, employees: "" }))
                    setSelectedEmployees(allEmployeeRedux)
                }
            } else {
                let updatedEmployees = []
                const filteredEmployee = weekendData?.employees?.find(itm => itm === value)
                if (filteredEmployee) {
                    const filteredEmployee = weekendData?.employees?.filter(itm => itm !== value)
                    updatedEmployees = filteredEmployee
                } else {
                    updatedEmployees = [...weekendData?.employees, value]
                }
                setWeekendData((prev) => ({ ...prev, employees: updatedEmployees }));
                setErrors((prev) => ({ ...prev, employees: "" }))
                const filtered = allEmployeeRedux?.filter((employee) =>
                    updatedEmployees?.includes(employee?.id)
                );
                setSelectedEmployees(filtered);
                if (employeePaginationData?.employeeAllCount == updatedEmployees?.length) {
                    setIsSelectAll(true)
                } else {
                    setIsSelectAll(false)
                }
            }
        }
    }

    const removeSelectedEmployee = (id) => {
        let updatedEmployees = []
        const filteredEmployee = weekendData?.employees?.find(itm => itm === id)
        if (filteredEmployee) {
            const filteredEmployee = weekendData?.employees?.filter(itm => itm !== id)
            updatedEmployees = filteredEmployee
        }
        setWeekendData((prev) => ({ ...prev, employees: updatedEmployees }));
    }

    const onSubmitHandler = () => {
        const success = (res) => {
            setWeekendData({
                date: selectedDate,
                employees: [],
                is_weekend: true
            })
            toast.success(res?.data?.response?.message, { toastId: "toast" })
            setCreateWeekend(false)
            setIsEdit(false)
            setSubmitLoader(false)
            successCallBack && successCallBack()
        }
        const fail = () => {
            setSubmitLoader(false)
        }
        let payload = {
            date: weekendData?.date,
            location: selected_location,
            week_end_employee: weekendData?.employees,
            is_weekend: weekendData?.is_weekend,
            business: bussiness?.id,
            from_date: weekendData?.date
        }

        let validationData = { employees: weekendData?.employees?.length > 0 ? true : false }
        setErrors(validation(validationData));
        let errorResponse = validation(validationData);
        if (objectKeyConvertToArray(errorResponse)?.length === 0) {
            setSubmitLoader(true)
            if (isEdit) {
                dispatch(updateWeekend(payload, access_token, success, fail))
            } else {
                dispatch(createWeekend(payload, access_token, success, fail))
            }
        }
    }

    const callApiToResetEmployeeRedux = () => {
        // call this Api To Reset EmployeeRedux after close
        let payload = {
            location_id: selected_location,
            page: 1
        }
        dispatch(getEmployeeDropDownListPaginationForWeekend(payload, setEmployeeLoader))
    }

    const handleCloseFunction = () => {
        // close the popup
        callApiToResetEmployeeRedux()
        setIsEdit(false)
        setWeekendData({})
        setCreateWeekend(false)
    }

    return {
        translation, selectedLanguage, employeeRedux, employeePaginationData, setEmployeeSearch,
        employeeSearch, employeeLoader, handleChange, isSelectAll, setIsSelectAll, weekendData,
        employeeSecondLoader, setEmployeeSecondLoader, setEmployeePage, errors, submitLoader,
        onSubmitHandler, setWeekendData, callApiToResetEmployeeRedux, handleCloseFunction,
        selectedEmployees, removeSelectedEmployee, updatedEmployees
    }
}

export default useAddWeekend