import { get_refund_reports } from "Adapters/Api/refund";
import { getServiceGroupPaginationDropdown } from "Adapters/Api/saleServices";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

let timeOutId = undefined
let serviceGroupTimeOutId = undefined

const useServiceRefundInvoice = () => {
    const dispatch = useDispatch();

    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const { selected_location } = state?.locations;
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage('access_token')

    const [filterData, setFilterData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    const [isLoading, setIsLoading] = useState(true);
    const location_currency = state?.utility?.currency?.location_currency;

    let refunded_record = state?.product?.refund;

    let pages = state?.product?.refund_total_pages ? state?.product?.refund_total_pages : 1;
    let allCount = state?.product?.refund_allCount ? state?.product?.refund_allCount : 0;
    const [ShowDetails, setShowDetails] = useState(0);

    const [serviceGroupSearchText, setServiceGroupSearchText] = useState("");
    const [serviceGroupCurrentPage, setServiceGroupCurrentPage] = useState(1)
    const [serviceGroupLoader, setServiceGroupLoader] = useState(false)
    const serviceGroupPaginationData = state?.saleService?.servicesGroupDropdownPagination;
    const serviceGroup = state?.saleService.servicesGroupDropdown;

    useEffect(() => {
        if (serviceGroupSearchText === null) {
            dispatch(getServiceGroupPaginationDropdown(serviceGroupCurrentPage, "", setServiceGroupLoader))
        } else {
            if (serviceGroupTimeOutId) {
                clearTimeout(serviceGroupTimeOutId)
            }
            serviceGroupTimeOutId = setTimeout(() => {
                dispatch(getServiceGroupPaginationDropdown(serviceGroupCurrentPage, serviceGroupSearchText, setServiceGroupLoader))
            }, 500);
        }
    }, [serviceGroupSearchText, serviceGroupCurrentPage])


    const isPermissionUpdated = state?.user?.isPermissionUpdated;
    const [loadingPermission, setLoadingPermission] = useState(true)
    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])



    const fetchSeviceStockReport = (page) => {
        let location_id = localStorage.getItem('selected_location')
        let success = () => {
            setIsLoading(false);
        }
        let fail = () => {
            setIsLoading(false);
        }
        const payload = {
            ...filterData,
            search_text: filterData.text,
            location_id: location_id?.replaceAll('"', ''),
            page: page,
            type: 'Service'
        }

        setIsLoading(true);
        if (location_id) {
            dispatch(get_refund_reports(payload, access_token, success, fail))
        }
    };
    useEffect(() => {
        if (filterData?.text === null) {
            // Initial load with empty filterData?.text
            fetchSeviceStockReport(currentPage)
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                fetchSeviceStockReport(currentPage)
            }, 500);
        }
        // fetchSeviceStockReport();
    }, [filterData, selected_location, currentPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [filterData, selected_location])


    const serviceGroupDropdown = useMemo(() => {
        return [{ label: 'All Service Group', value: '' }, ...serviceGroup?.map((obj) => {
            return {
                label: obj.name,
                value: obj.id
            }
        })]
    }, [serviceGroup?.length])


    // sevice reports exports
    let seviceHeader = [
        { label: "Sevice Name", key: "sevice" },
        { label: "Type", key: "type" },
        { label: "Updated Stock", key: "available_stock" },
        { label: "Cost Price", key: "cost_price" },
        { label: "Retail Price", key: "retail_price" },
        { label: "Brand", key: "brand" },
        { label: "Date", key: "date" }
    ];

    const seviceReportsExport = {
        filename: "Inventory Report.csv",
        headers: seviceHeader,
        data: []
        // data: filter_sevice_stock_report()?.map((sevice_obj) => {
        //     return {
        //         ...sevice_obj,
        //         sevice: sevice_obj?.name ? sevice_obj?.name : "----",
        //         type: sevice_obj?.report_choice ? sevice_obj?.report_choice : "----",
        //         available_stock: sevice_obj?.after_quantity ? sevice_obj?.after_quantity : "----",
        //         cost_price: sevice_obj?.cost_price ? sevice_obj?.cost_price : "----",
        //         retail_price: (sevice_obj?.retail_price && sevice_obj?.retail_price?.retail_price) ? `${sevice_obj?.retail_price?.retail_price} ${sevice_obj?.retail_price?.currency_code}` : `0`,
        //         brand: sevice_obj?.brand ? sevice_obj?.brand : "----",
        //         date: sevice_obj?.created_at
        //     };
        // }),
    };

    let NestedSeviceReportsHeader = [
        { label: "Type", key: "type" },
        { label: "Stock before", key: "stock_before" },
        { label: "Sold Stock", key: "sold_stock" },
        { label: "Location", key: "location" },
        { label: "Updated Stock", key: "updated_stock" },
        { label: "Consumed Amount", key: "consumed_amount" },
        { label: "Vendor", key: "vendor" },
        { label: "PO ID", key: "po_id" },
        { label: "Reorder Amount", key: "reorder_amount" },
        { label: "Received/Transfer Amount", key: "received_amount" },
        { label: "Date", key: "date" },
    ];


    const SeviceStockNestedReportsExport = {
        filename: "nested_inventory_report.csv",
        headers: NestedSeviceReportsHeader,
        data: []
        // data: filter_sevice_stock_report()?.map((report_obj) => {
        //     return {
        //         ...report_obj,
        //         type: report_obj.report_choice,
        //         stock_before: report_obj.before_quantity ? report_obj.before_quantity : '------',
        //         sold_stock: (report_obj?.before_quantity && report_obj?.after_quantity) ? (report_obj?.before_quantity - report_obj?.after_quantity) : '------',
        //         location: `${report_obj.report_choice == 'Sold' ? report_obj.address_name : report_obj.report_choice == 'Consumed' ? report_obj?.consumed_location?.address_name : (report_obj.report_choice == 'Transfer_to' || report_obj.report_choice == 'Transfer_from') ? report_obj?.from_location?.address_name : '------'}`,
        //         updated_stock: report_obj?.after_quantity ? report_obj?.after_quantity : '------',
        //         consumed_amount: report_obj?.quantity ? report_obj?.quantity : '------',
        //         vendor: report_obj?.vendor_name ? report_obj?.vendor_name : '------',
        //         po_id: report_obj.report_choice == 'Purchase' ? report_obj?.id?.split("-", 1) : '------',
        //         reorder_amount: report_obj?.reorder_quantity ? report_obj?.reorder_quantity : '------',
        //         received_amount: report_obj?.quantity ? report_obj?.quantity : '------',
        //         date: report_obj.created_at,
        //     };
        // }),
    };


    return {
        selectedLanguage,
        translation,
        location_currency,
        refunded_record,
        serviceGroupDropdown,
        allCount,
        pages,
        isLoading,
        filterData, setFilterData,
        loadingPermission,
        currentPage, setCurrentPage,
        ShowDetails, setShowDetails,
        setServiceGroupSearchText,
        setServiceGroupCurrentPage,
        serviceGroupSearchText,
        serviceGroupCurrentPage,
        serviceGroupPaginationData,
        serviceGroupLoader,
    }
}

export default useServiceRefundInvoice