import { CAMPAIGNS } from "Redux/TenantRedux/ActionTypes/campaignTypes";

const initialState = {
    campaigns: [],
    campaignPagination: {}
};

const CampaignReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CAMPAIGNS.GET_CAMPAIGNS:
            return {
                ...state,
                campaigns: payload?.response?.data,
                campaignPagination: {
                    pages: Math.ceil(+payload?.total_pages),
                    allCount: payload?.count,
                    currentPage: +payload?.current_page,
                    isSearched: payload?.is_searched
                }
            };

        case CAMPAIGNS.ADD_CAMPAIGNS:
            return {
                ...state,
                campaigns: [payload, ...state.campaigns],
                campaignPagination: {
                    ...state?.campaignPagination,
                    allCount: state.campaignPagination?.allCount + 1
                }
            };

        case CAMPAIGNS.DELETE_CAMPAIGNS:
            let result = state?.campaigns?.filter((item) => item.id !== payload);
            return {
                ...state,
                campaigns: [...result],
                campaignPagination: {
                    ...state?.campaignPagination,
                    allCount: state.campaignPagination?.allCount - 1
                }
            };

        case CAMPAIGNS.UPDATE_CAMPAIGNS:
            let updateList = state.campaigns.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })
            return {
                ...state,
                campaigns: [...updateList],
            };

        default:
            return state;
    }

}
export default CampaignReducer