import React from "react";
import Header from "./Header";
import Footer from "./Footer.jsx";

const ConatinerWithHeader = ({ children, signin, next, showSignin }) => {
  return (
    <>
      <Header signin={signin} showSignin={showSignin} next={next} />
      <div className="py-[3rem]">
        <div className="nstyle-container mx-auto md:w-[85%] w-[95%]">{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default ConatinerWithHeader;
