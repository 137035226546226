import { getBrandDropdownlistPagination } from "Adapters/Api/Brand";
import { get_product_inventory_report } from "Adapters/Api/Products";
import { get_refund_reports } from "Adapters/Api/refund";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

let timeOutId = undefined
let brandTimeOutId = undefined

const useProductRefundInvoice = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const { selected_location } = state?.locations;
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage('access_token')

    const [searchText, setSearchText] = useState('')
    const [brandSearchText, setBrandSearchText] = useState("");
    const [brandCurrentPage, setBrandCurrentPage] = useState(1)
    const [filterData, setFilterData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    const [isLoading, setIsLoading] = useState(true);


    const [brandLoader, setBrandLoader] = useState(false)
    const brandListDropdown = state?.brand?.brandsDropdown;
    const brandPaginationData = state?.brand?.brandsPagination;
    const location_currency = state?.utility?.currency?.location_currency;

    let refunded_record = state?.product?.refund;
    let pages = state?.product?.refund_total_pages ? state?.product?.refund_total_pages : 1;
    let allCount = state?.product?.refund_allCount ? state?.product?.refund_allCount : 0;
    const [ShowDetails, setShowDetails] = useState(0);


    useEffect(() => {
        if (brandSearchText === null) {
            dispatch(getBrandDropdownlistPagination(brandCurrentPage, "", setBrandLoader))
        } else {
            if (brandTimeOutId) {
                clearTimeout(brandTimeOutId)
            }
            brandTimeOutId = setTimeout(() => {
                dispatch(getBrandDropdownlistPagination(brandCurrentPage, brandSearchText, setBrandLoader))
            }, 500);
        }
    }, [brandSearchText, brandCurrentPage])

    const isPermissionUpdated = state?.user?.isPermissionUpdated;
    const [loadingPermission, setLoadingPermission] = useState(true)
    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])



    const fetchProductStockReport = (page) => {
        let location_id = localStorage.getItem('selected_location')
        let success = () => {
            setIsLoading(false);
        }
        let fail = () => {
            setIsLoading(false);
        }
        const payload = {
            ...filterData,
            search_text: filterData.text,
            location_id: location_id?.replaceAll('"', ''),
            page: page,
            type: 'Product'
        }

        setIsLoading(true);
        if (location_id) {
            dispatch(get_refund_reports(payload, access_token, success, fail))
        }
    };
    useEffect(() => {
        if (filterData?.text === null) {
            // Initial load with empty filterData?.text
            fetchProductStockReport(currentPage)
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                fetchProductStockReport(currentPage)
            }, 500);
        }
        // fetchProductStockReport();
    }, [filterData, selected_location, currentPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [filterData, selected_location])


    const brands = useMemo(() => {
        return [{ label: 'All Brand', value: '' }, ...brandListDropdown?.map((obj) => {
            return {
                label: obj.name,
                value: obj.id
            }
        })]
    }, [brandListDropdown?.length])


    // product reports exports
    let productHeader = [
        { label: "Product Name", key: "product" },
        { label: "Type", key: "type" },
        { label: "Updated Stock", key: "available_stock" },
        { label: "Cost Price", key: "cost_price" },
        { label: "Retail Price", key: "retail_price" },
        { label: "Brand", key: "brand" },
        { label: "Date", key: "date" }
    ];

    const productReportsExport = {
        filename: "Inventory Report.csv",
        headers: productHeader,
        data: []
        // data: filter_product_stock_report()?.map((product_obj) => {
        //     return {
        //         ...product_obj,
        //         product: product_obj?.name ? product_obj?.name : "----",
        //         type: product_obj?.report_choice ? product_obj?.report_choice : "----",
        //         available_stock: product_obj?.after_quantity ? product_obj?.after_quantity : "----",
        //         cost_price: product_obj?.cost_price ? product_obj?.cost_price : "----",
        //         retail_price: (product_obj?.retail_price && product_obj?.retail_price?.retail_price) ? `${product_obj?.retail_price?.retail_price} ${product_obj?.retail_price?.currency_code}` : `0`,
        //         brand: product_obj?.brand ? product_obj?.brand : "----",
        //         date: product_obj?.created_at
        //     };
        // }),
    };

    let NestedProductReportsHeader = [
        { label: "Type", key: "type" },
        { label: "Stock before", key: "stock_before" },
        { label: "Sold Stock", key: "sold_stock" },
        { label: "Location", key: "location" },
        { label: "Updated Stock", key: "updated_stock" },
        { label: "Consumed Amount", key: "consumed_amount" },
        { label: "Vendor", key: "vendor" },
        { label: "PO ID", key: "po_id" },
        { label: "Reorder Amount", key: "reorder_amount" },
        { label: "Received/Transfer Amount", key: "received_amount" },
        { label: "Date", key: "date" },
    ];


    const ProductStockNestedReportsExport = {
        filename: "nested_inventory_report.csv",
        headers: NestedProductReportsHeader,
        data: []
        // data: filter_product_stock_report()?.map((report_obj) => {
        //     return {
        //         ...report_obj,
        //         type: report_obj.report_choice,
        //         stock_before: report_obj.before_quantity ? report_obj.before_quantity : '------',
        //         sold_stock: (report_obj?.before_quantity && report_obj?.after_quantity) ? (report_obj?.before_quantity - report_obj?.after_quantity) : '------',
        //         location: `${report_obj.report_choice == 'Sold' ? report_obj.address_name : report_obj.report_choice == 'Consumed' ? report_obj?.consumed_location?.address_name : (report_obj.report_choice == 'Transfer_to' || report_obj.report_choice == 'Transfer_from') ? report_obj?.from_location?.address_name : '------'}`,
        //         updated_stock: report_obj?.after_quantity ? report_obj?.after_quantity : '------',
        //         consumed_amount: report_obj?.quantity ? report_obj?.quantity : '------',
        //         vendor: report_obj?.vendor_name ? report_obj?.vendor_name : '------',
        //         po_id: report_obj.report_choice == 'Purchase' ? report_obj?.id?.split("-", 1) : '------',
        //         reorder_amount: report_obj?.reorder_quantity ? report_obj?.reorder_quantity : '------',
        //         received_amount: report_obj?.quantity ? report_obj?.quantity : '------',
        //         date: report_obj.created_at,
        //     };
        // }),
    };


    return {
        selectedLanguage,
        translation,
        location_currency,
        refunded_record,
        brands,
        allCount,
        pages,
        isLoading,
        filterData, setFilterData,
        loadingPermission,
        currentPage, setCurrentPage,
        ShowDetails, setShowDetails,
        NestedProductReportsHeader,
        setBrandCurrentPage,
        setBrandSearchText,
        brandCurrentPage,
        brandSearchText,
        brandPaginationData,
        brandLoader,
    }
}

export default useProductRefundInvoice