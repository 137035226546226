import { useState } from "react"
import { EMAIL } from '../Constants/Variables';
import useObjectKeys from './useObjectKeys';
import { useSelector } from "react-redux";

export default function useValidations() {

    const { objectKeyConvertToArray } = useObjectKeys();
    const [errors, setErrors] = useState({});
    const [editErrors, setEditErrors] = useState({});

    const state = useSelector((state) => state);

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    // removed error from a spacific input  when user click in it
    const onFocusHandler = (formField) => {
        setErrors(prev => ({ ...prev, ...formField }))
        setEditErrors(prev => ({ ...prev, ...formField }))
    }

    //show error when user click on specific input field and leave it without enter valid data
    const onBlurHandler = (formField) => {
        setErrors(prev => ({ ...prev, ...validation(formField) }))
        setEditErrors(prev => ({ ...prev, ...validation(formField) }))
    }

    //check url is valid or not 
    const validateWebsiteUrl = (webiste) => {
        let regex = /((https?:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gmi;
        // let regex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)([a-z]{2,})(\.[a-z]{2,})$/i;
        return regex.test(webiste);
        // return regex.test(String(webiste).toLowerCase());
    }


    //check email formate if email is proper email return true than false
    const validateEmail = (email) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    };


    //check Mobile Number formate  
    const validateMoblieNumber = (number) => {
        const regex = /^([+]?\d{1,4}[-\s]?|)\d{3}[-\s]?\d{2,3}[-\s]?\d{3,4}$/
        // const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[-\s\.]?[0-9]{0,9}$/im;
        // const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/im;
        return regex.test(number);
    };


    //removed white spaces and special charactors from a given string like "  !test@#  " to "test"
    const removeWhiteSpacesAndSpecialCharactors = (string) => {
        const result = string && string.replace(/[&\/\\#,@+!_^()$~%.'":*?<>{}]/g, "").trim();
        return result;
    }


    // removed only white spaces from both ends 
    const removeWhiteSpacesFromStartAndEnd = (value) => {
        let wsRegex = /^\s+|\s+$/g;
        let result = value?.replaceAll(wsRegex, "");
        return result;
    }


    // removed hypen ( _ ) from fieldName value and capitalize first latter of fieldName like ( first_name to First name)
    const capitalizeFirstLatters = (fieldName) => {

        let fieldNameArr = fieldName.split("_");
        let firstValue = fieldNameArr[0];
        let captilizeFirstLatter = firstValue[0].toUpperCase() + firstValue.slice(1);

        fieldNameArr.splice(0, 1, captilizeFirstLatter);
        let strValue = fieldNameArr.join(" ");

        return strValue;
    }


    //remove empty spaces from the input fields value like ("     ali     " to "ali" or "      " to "")
    const trimEmptySpace = (object) => {
        let trimmedObject = {};

        Object.keys(object).forEach((item) => {
            trimmedObject = {
                ...trimmedObject,
                [item]: object[item] ? object[item].trim() : "",
            };
        })

        return trimmedObject;
    }



    //validate all input fields and proper error about each field
    const validation = (fields, message = "", data) => {

        let errorArr = [];
        let errorMessages;

        //create array of fieldName like ["name", "email", ..] etc
        let fieldName = objectKeyConvertToArray(fields);

        // create array of object from field name and value like [{name:"ali"},{email:"ali@gmail.com"}] etc
        const fieldArr = Object.entries(fields).map(([key, value]) => {
            return { ["name"]: key }
        });


        //compare both arr fieldName and fieldArr check here fields is exist and null set error for that field
        // old validations
        // fieldName.forEach(item => {
        //     fieldArr.forEach(field => {
        //         if (field.name === item && field.name) {
        //             if (!fields[field.name]) {
        //                 let fieldName = capitalizeFirstLatters(field.name)

        //                 if (message) {

        //                     errorArr.push({ [field.name]: message })

        //                 } else {

        //                     errorArr.push({
        //                         [field.name]: selectedLanguage?.name == "English" ? "This field is required" : translation ? translation[287]?.value ? translation[287]?.value : "This field is required" : "This field is required"
        //                     })
        //                 }
        //                 // errorArr.push({ [field.name]: `${fieldName} is required` })

        //             } else if (field.name === EMAIL) {
        //                 if (!validateEmail(fields[field.name])) {
        //                     let fieldName = capitalizeFirstLatters(field.name)
        //                     errorArr.push({ [field.name]: `${fieldName}  ${selectedLanguage?.name == "English" ? "is invalid" : translation ? translation[288]?.value ? translation[288]?.value : "is invalid" : "is invalid"}` })
        //                 }
        //             } else if (field.name === "mobile_number") {
        //                 if (!validateMoblieNumber(fields[field.name])) {
        //                     let fieldName = capitalizeFirstLatters(field.name)
        //                     errorArr.push({ [field.name]: `${fieldName}  ${selectedLanguage?.name == "English" ? "is invalid" : translation ? translation[288]?.value ? translation[288]?.value : "is invalid" : "is invalid"} ` })
        //                 }
        //             } else if (field.name === "website") {

        //                 if (!validateWebsiteUrl(fields[field.name])) {
        //                     let fieldName = capitalizeFirstLatters(field.name)
        //                     errorArr.push({ [field.name]: `${fieldName}  ${selectedLanguage?.name == "English" ? "is invalid" : translation ? translation[288]?.value ? translation[288]?.value : "is invalid" : "is invalid"} ` })
        //                 }
        //             }
        //         }
        //     }

        //     )
        // });

        // new checks with trim empty space
        fieldName.forEach(item => {
            fieldArr.forEach(field => {
                if (field.name === item && field.name) {
                    let fieldValue = fields[field.name];
                    let trimmedValue = ''
                    if (typeof fieldValue === 'string') {
                        trimmedValue = fieldValue.trim();
                    } else if (typeof fieldValue === 'number' || typeof fieldValue === 'boolean') {
                        trimmedValue = fieldValue.toString().trim();
                    } else if (!isNaN(parseFloat(fieldValue)) && isFinite(fieldValue)) {
                        trimmedValue = fieldValue.toString().trim();
                    }

                    if (!fields[field.name] || !trimmedValue || trimmedValue == "") {
                        let fieldName = capitalizeFirstLatters(field.name)

                        if (message) {

                            errorArr.push({ [field.name]: message })

                        } else {

                            errorArr.push({
                                [field.name]: selectedLanguage?.name == "English" ? "This field is required" : translation ? translation[287]?.value ? translation[287]?.value : "This field is required" : "This field is required"
                            })
                        }
                        // errorArr.push({ [field.name]: `${fieldName} is required` })

                    } else if (field.name === EMAIL) {
                        if (!validateEmail(fields[field.name])) {
                            let fieldName = capitalizeFirstLatters(field.name)
                            errorArr.push({ [field.name]: `${fieldName}  ${selectedLanguage?.name == "English" ? "is invalid" : translation ? translation[288]?.value ? translation[288]?.value : "is invalid" : "is invalid"}` })
                        }
                    } else if (field.name === "mobile_number") {
                        if (!validateMoblieNumber(fields[field.name])) {
                            let fieldName = capitalizeFirstLatters(field.name)
                            errorArr.push({ [field.name]: `${fieldName}  ${selectedLanguage?.name == "English" ? "is invalid" : translation ? translation[288]?.value ? translation[288]?.value : "is invalid" : "is invalid"} ` })
                        }
                    } else if (field.name === "website") {

                        if (!validateWebsiteUrl(fields[field.name])) {
                            let fieldName = capitalizeFirstLatters(field.name)
                            errorArr.push({ [field.name]: `${fieldName}  ${selectedLanguage?.name == "English" ? "is invalid" : translation ? translation[288]?.value ? translation[288]?.value : "is invalid" : "is invalid"} ` })
                        }
                    }
                }
            });
        });

        // Errors for refund Product
        if (data?.product?.length > 0) {
            let errPrice = []
            let errQty = []

            data?.product?.forEach((itm) => {
                if (!itm?.refunded_amount) {
                    errPrice.push({
                        text: selectedLanguage?.name == "English" ? "This field is required" : translation ? translation[287]?.value ? translation[287]?.value : "This field is required" : "This field is required",
                        id: itm?.product,
                    })
                }
                if (!itm?.refunded_quantity) {
                    errQty.push({
                        text: selectedLanguage?.name == "English" ? "This field is required" : translation ? translation[287]?.value ? translation[287]?.value : "This field is required" : "This field is required",
                        id: itm?.product,
                    })
                }
            });
            if (errPrice?.length > 0)
                errorArr.push({
                    refunded_amount: errPrice,
                })

            if (errQty?.length > 0)
                errorArr.push({
                    refunded_quantity: errQty
                })
        }
        if (data?.service?.length > 0) {
            let errServicePrice = []

            data?.service?.forEach((itm) => {
                if (!itm?.refunded_amount) {
                    errServicePrice.push({
                        text: selectedLanguage?.name == "English" ? "This field is required" : translation ? translation[287]?.value ? translation[287]?.value : "This field is required" : "This field is required",
                        id: itm?.service,
                    })
                }
            });
            if (errServicePrice?.length > 0)
                errorArr.push({
                    refunded_service_amount: errServicePrice
                })
        }


        // convert array of error message to objects
        errorMessages = Object.assign({}, ...errorArr);
        return errorMessages;
    }

    return {
        trimEmptySpace,
        validation,
        removeWhiteSpacesAndSpecialCharactors,
        removeWhiteSpacesFromStartAndEnd,
        onFocusHandler,
        onBlurHandler,
        errors,
        setErrors,
        editErrors,
        setEditErrors,
        validateWebsiteUrl,
        validateMoblieNumber,
        validateEmail
    }
}
