import Svgs from 'Assets/svgs'
import React from 'react'

const Inprogress = () => {
    return (
        <div className='h-full w-full flex items-center justify-center'>
            <div className='text-center flex flex-col justify-center items-center gap-6'>
                <Svgs.Inprogress />
                <div>
                    <h1 className='font-semibold text-2xl text-[#5F698A]'>This Module is in Progress</h1>
                    <p className='text-[#5F698A]'>We're working on it!</p>
                </div>
            </div>
        </div>
    )
}

export default Inprogress