import Svgs from 'Assets/svgs';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import Popup from 'Components/TenantApp/Elements/Popup';
import React from 'react'

const AddFilter = ({ FilterPopup, setFilterPopup, onFilterChange, selectedLanguage, translation, filterData, clearFilter, onSubmit }) => {
  const { segment_type, is_active } = filterData

  return (
    <Popup heading="Filter" open={FilterPopup} close={setFilterPopup}>
      <div className="flex flex-col gap-5">
        <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
          <CircleIcon>
            <Svgs.FilterPopup />
          </CircleIcon>
          <div className="flex flex-col gap-1 lg:text-left text-center">
            <h2 className="text-primary font-semibold text-xl">
              Search by filter
            </h2>
            <p className="text-[#7B7B7B] text-sm">
              Choose filters by status and segmentation type to tailor your <br /> client segmentation view.
            </p>
          </div>
        </div>
        <div className="grid xs:grid-cols-2 gap-5">
          <div>
            <Dropwdown
              title={"Segment Type"}
              placeholder={"Select Segment Type"}
              options={
                [
                  {
                    label: "Dynamic",
                    value: "dynamic",
                  },
                  {
                    label: "Static",
                    value: "static",
                  }]
              }
              name={'segment_type'}
              value={segment_type}
              onChange={onFilterChange}
            />
          </div>
          <div>
            <Dropwdown
              title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
              placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[483]?.value : "Select Status"}
              options={[
                {
                  value: 'True',
                  label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                },
                {
                  value: 'False',
                  label: selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                },
              ]}
              value={is_active}
              name='is_active'
              onChange={onFilterChange}
            // onBlur={blur_change}
            // error={error.includes('is_active') && !form_data.status}
            // disabled={loading}
            />
          </div>
        </div>
        <div className='flex gap-3'>
          <LoginBtn text="Search" onClick={onSubmit} />
          <LoginBtn text="Clear Filter" onClick={clearFilter} />
        </div>
      </div>
    </Popup>
  )
};

export default AddFilter;