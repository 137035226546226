import React from 'react'
import { useState } from 'react'
import moment from 'moment'

let initialState = {

}
const useConversionPopup = () => {
    const [conversionForm, setConversionForm] = useState(initialState)
   // Date Range
   const [indexValue, setIndexValue] = useState()
   const [startDate, setStartDate] = useState()
   const [endDate, setEndDate] = useState()
   const [toggleDateRange, setToggleDateRange] = useState(false)
    return {
        moment,
        indexValue, setIndexValue,
        startDate, setStartDate,
        endDate, setEndDate,
        toggleDateRange, setToggleDateRange
    }
}

export default useConversionPopup