import React from 'react'
import AccountSetupCard from './AccountSetupCard'
import Svgs from 'Assets/svgs'
import { useSelector } from "react-redux";

const DashboardCRM = () => {
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <div>
            <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
                <div className='flex items-center gap-5 lg:w-fit w-full lg:px-0 lg:pb-[2rem] lg:py-0 p-3 pb-8'>
                    <h2 class="font-semibold text-2xl">{selectedLanguage?.name == "English" ? "CRM" : translation[1354]?.value ? translation[1354]?.value : "CRM"}</h2>
                </div>
                <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem] xl:pr-[4rem] pr-0">
                    <AccountSetupCard
                        link="/dashboard/crm/customers-segmentation" 
                        icon={<Svgs.Customer_Segmen />}
                        title={selectedLanguage?.name == "English" ? "Clients Segmentation" : translation[1355]?.value ? translation[1355]?.value : "Clients Segmentation"}
                        description={selectedLanguage?.name == "English" ? "Manage client through dynamic, static and multiple filters" : translation[1356]?.value ? translation[1356]?.value : "Manage client through dynamic, static and multiple filters"}
                    
                    />
                    <AccountSetupCard
                        link="/dashboard/crm/campaigns"
                        icon={<Svgs.Campaigns />}
                        title={selectedLanguage?.name == "English" ? "Campaigns" : translation[1357]?.value ? translation[1357]?.value : "Campaigns"}
                        description={selectedLanguage?.name == "English" ? "Manage payment methods, taxes, and apply tax on business locations" : translation[1358]?.value ? translation[1358]?.value : "Manage payment methods, taxes, and apply tax on business locations"}
                    />
                    {/* <AccountSetupCard
                        link="/dashboard/crm/conversion-tracker"
                        icon={<Svgs.Coversion_tracker />}
                        title={selectedLanguage?.name == "English" ? "Conversion Tracker" : translation[1359]?.value ? translation[1359]?.value : "Conversion Tracker"}
                        description={selectedLanguage?.name == "English" ? "Manage booking settings for the appointment booking" : translation[1360]?.value ? translation[1360]?.value : "Manage booking settings for the appointment booking"}
                    />
                    <AccountSetupCard
                        link="/dashboard/crm/lead-generation"
                        icon={<Svgs.Lead_Generation />}
                        title={selectedLanguage?.name == "English" ? "Lead Generation" : translation[1361]?.value ? translation[1361]?.value : "Lead Generation"}
                        description={selectedLanguage?.name == "English" ? "Manage theme color, menu, and calendar customizations" : translation[663]?.value ? translation[663]?.value : "Manage theme color, menu, and calendar customizations"}
                        
                        />
                    <AccountSetupCard
                        link="/dashboard/crm/unsubscribe"
                        icon={<Svgs.Unsubscribe />}
                        title={selectedLanguage?.name == "English" ? "Unsubscribe" : translation[1362]?.value ? translation[1362]?.value : "Unsubscribe"}
                        description={selectedLanguage?.name == "English" ? "Manage language preferences for your business with respect to locations" : translation[665]?.value ? translation[665]?.value : "Manage language preferences for your business with respect to locations"}
                    
                    />

                    <AccountSetupCard
                        link="/dashboard/crm/deals"
                        icon={<Svgs.Coversion_tracker />}
                        title={selectedLanguage?.name == "English" ? "Deals" : translation[1498]?.value ? translation[1498]?.value : "Deals"}
                        description={selectedLanguage?.name == "English" ? "Manage booking settings for the appointment booking" : translation[1360]?.value ? translation[1360]?.value : "Manage booking settings for the appointment booking"}
                    /> */}
                </div>
            </div>
        </div>
    )
}

export default DashboardCRM