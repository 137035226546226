import axios from "axios";
import {
  CREATE_APPOINTMENT,
  GET_ALL_APPOINTMENTS,
  GET_ALL_APPOINTMENTS_NO_PAGINATION,
  GET_ALL_REVERSAL,
  GET_BAR_CHART_DATA,
  GET_EMPLOYEE_DAILY_INSIGHT_DATA,
  GET_MY_APPOINTMENTS,
  GET_TODAY_APPOINTMENTS,
} from "../../../Redux/TenantRedux/ActionTypes/Appointment/Appointment";
import {
  BASE_URL,
  create_appointment,
  create_blockTime_url,
  create_checkout_url,
  get_all_appointments_url,
  get_appointment_url,
  get_single_appointments_url,
  get_client_sales,
  get_today_appointments_url,
  update_appointment_url,
  update_blockTime_url,
  create_client_appointment,
  get_appointments_service,
  update_appointment_detail,
  get_employees_for_selected_service,
  create_comment,
  get_comment,
  get_employee_check_time, get_employee_check_availability_list, get_appointment_log, get_client_loyalty_points, get_client_checkout_vouchers, get_client_checkout_memberships, get_all_appointments_url_no_pagination, get_employee_insights, get_daily_insights_of_employee, get_recent_ten_appointments_url, appointment_service_status_update, appointment_check_in, group_appointment_check_in, paid_unpaid_appointments, create_missed_opportunity, get_missed_opportunity, cancel_appointment_url, get_single_coupon_data, get_detail_from_code_url, create_reversal, get_all_reversal_url, update_reversal_status, get_client_checkout_giftCard_url, create_group_appointment, get_appointments_group_services, create_employee_comment, get_employee_comment,
} from "../api_variables";
import moment from "moment";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { toast } from "react-toastify";

export const createAppointment = (data, success, fail) => (dispatch) => {
  data = {
    ...data,
    appointments: data?.appointments?.map(obj => {
      return {
        ...obj,
        offer: obj?.offer?.id
      }
    })
  }
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }
  let form_data = new FormData();

  for (let dt in data) {
    if (data[dt]) {
      if (typeof data[dt] == "object") {
        form_data.append(dt, JSON.stringify(data[dt]));
      } else {
        form_data.append(dt, data[dt]);
      }
    }
  }
  fetch(BASE_URL + create_appointment, {
    method: "POST",
    body: form_data,
    headers: {
      Authorization: `Token ${access_token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 201) {
        success && success(result.response.appointments, result.response);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const createGroupAppointment = (data, success, fail) => async (dispatch) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }
  const header = {
    headers: { Authorization: `Token ${access_token}` },
  }
  try {
    const result = await axios.post(BASE_URL + create_group_appointment, data, header);
    if (result?.status === 201) {
      success && success(result?.data?.response)
    } else {
      fail && fail()
    }
  } catch (error) {
    fail && fail()
  }
}

export const getCalendarMainAppointments = (selected_date, selectedLocation, SelectedEmployee, setAppointmentLoader, success, fail) => (dispatch) => {
  setAppointmentLoader && setAppointmentLoader(true)
  if (!selected_date) {
    fail && fail()
    setAppointmentLoader && setAppointmentLoader(false)
    return
  }
  let s_code;

  let employeeData
  if (SelectedEmployee === "All") {
    employeeData = SelectedEmployee
  } else {
    employeeData = encodeURIComponent(JSON.stringify(SelectedEmployee))
  }

  if (selected_date) {
    selected_date = moment(selected_date).format('YYYY-MM-DD')
  }

  fetch(BASE_URL + get_appointment_url + `?selected_date=${selected_date}&employee=${employeeData}&location_id=${selectedLocation}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: GET_MY_APPOINTMENTS,
          payload: result.response.appointments,
        });
        success && success();
        if (setAppointmentLoader) {
          setAppointmentLoader(false)
        } else {
          toast.success('Appointment update successfully', { toastId: "toast" })
        }
      }
      setAppointmentLoader && setAppointmentLoader(false)
    })
    .catch((err) => {
      setAppointmentLoader && setAppointmentLoader(false)
    });
};

export const getAppointments = (selected_date, success, fail) => (dispatch) => {

  if (!selected_date) {
    fail && fail()
    return
  }
  let s_code;

  fetch(BASE_URL + get_appointment_url + `?selected_date=${selected_date}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: GET_MY_APPOINTMENTS,
          payload: result.response.appointments,
        });
        success && success();
      }
    })
    .catch((err) => { });
};

export const getEmployeeInsights = (formattedStartDate, formattedEndDate, SelectedEmployees, selectedLocation, setLoading, success, fail) => (dispatch) => {
  setLoading(true)
  let s_code;
  const employeeIds = encodeURIComponent(JSON.stringify(SelectedEmployees))
  let employees = employeeIds?.length > 0 ? `?employees=${employeeIds}` : ""
  let start_date = formattedStartDate ? `&start_date=${formattedStartDate}` : ""
  let end_date = formattedEndDate ? `&end_date=${formattedEndDate}` : ""
  let location_id = selectedLocation ? `&business_address_id=${selectedLocation}` : ""

  fetch(`${BASE_URL}${get_employee_insights}${employees}${start_date}${end_date}${location_id}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: GET_BAR_CHART_DATA,
          payload: result.response.data,
        });
        setLoading(false)
        success && success();
      }
    })
    .catch((err) => { setLoading(false) });
};

export const getEmployeeDailyInsights = (formattedStartDate, formattedEndDate, SelectedEmployees, selectedLocation, setEmployeeDailyInsightLoader, success, fail) => (dispatch) => {
  setEmployeeDailyInsightLoader(true)
  let s_code;
  const employeeIds = encodeURIComponent(JSON.stringify(SelectedEmployees))
  let employees = employeeIds?.length > 0 ? `?employees=${employeeIds}` : ""
  let start_date = formattedStartDate ? `&start_date=${formattedStartDate}` : ""
  let end_date = formattedEndDate ? `&end_date=${formattedEndDate}` : ""
  let location_id = selectedLocation ? `&business_address_id=${selectedLocation}` : ""

  fetch(`${BASE_URL}${get_daily_insights_of_employee}${employees}${start_date}${end_date}${location_id}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: GET_EMPLOYEE_DAILY_INSIGHT_DATA,
          payload: result.response.data,
        });
        success && success();
        setEmployeeDailyInsightLoader(false)
      }
    })
    .catch((err) => { setEmployeeDailyInsightLoader(false) });
};

export const getAllReversalRequest = (data, setLoader) => (dispatch) => {
  setLoader && setLoader(true)
  let s_code;

  const query = buildQueryString(data)
  let fetchAppointmentUrl = `${BASE_URL}${get_all_reversal_url}${query}`;

  fetch(fetchAppointmentUrl)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: GET_ALL_REVERSAL,
          payload: { data: result.response.data, count: result?.response?.total_pages, allCount: result?.response?.count },
        });
        setLoader && setLoader(false)
      }
      setLoader && setLoader(false)
    })
    .catch((err) => {
      setLoader && setLoader(false)
    });
};

export const getAllAppointments = (data, setLoader) => (dispatch) => {
  setLoader && setLoader(true)
  let s_code;

  const query = buildQueryString(data)
  let fetchAppointmentUrl = `${BASE_URL}${get_all_appointments_url}${query}`;

  fetch(fetchAppointmentUrl)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: GET_ALL_APPOINTMENTS,
          payload: { data: result.response.data, count: result?.response?.total_pages, allCount: result?.response?.count },
        });
        setLoader && setLoader(false)
      }
      setLoader && setLoader(false)
    })
    .catch((err) => {
      setLoader && setLoader(false)
    });
};

export const getAllAppointmentsWithNoPagination = (data, setNoPaginationLoader) => (dispatch) => {
  setNoPaginationLoader && setNoPaginationLoader(true)
  let s_code;
  let updatedData = { ...data, no_pagination: true }
  const query = buildQueryString(updatedData, true)

  let fetchAppointmentUrl = `${BASE_URL}${get_all_appointments_url}${query}`;

  fetch(fetchAppointmentUrl)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: GET_ALL_APPOINTMENTS_NO_PAGINATION,
          payload: { data: result.response.data },
        });
        setNoPaginationLoader && setNoPaginationLoader(false)
      }
      setNoPaginationLoader && setNoPaginationLoader(false)
    })
    .catch((err) => {
      setNoPaginationLoader && setNoPaginationLoader(false)
    });
};

export const getRecentTenAppointments = (location_id, setRecentAppointmentLoader) => (dispatch) => {
  setRecentAppointmentLoader && setRecentAppointmentLoader(true)
  let s_code;

  let fetchAppointmentUrl = `${BASE_URL}${get_recent_ten_appointments_url}?location_id=${location_id}`;

  fetch(fetchAppointmentUrl)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: GET_ALL_APPOINTMENTS,
          payload: { data: result.response.appointments, count: result?.pages },
        });
        setRecentAppointmentLoader && setRecentAppointmentLoader(false)
      }
      setRecentAppointmentLoader && setRecentAppointmentLoader(false)
    })
    .catch((err) => {
      setRecentAppointmentLoader && setRecentAppointmentLoader(false)
    });
};

export const getTodyasAppointments = (selected_location, setLoader) => (dispatch) => {
  setLoader && setLoader(true)
  let s_code;
  const location = selected_location ? `?location_id=${selected_location}` : ''
  fetch(BASE_URL + get_today_appointments_url + `${location}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        dispatch({
          type: GET_TODAY_APPOINTMENTS,
          payload: result.response.appointments,
        });
        setLoader && setLoader(false)
      }
    })
    .catch((err) => { setLoader && setLoader(false) });
};

export const getSingleAppointment = (data, success, fail) => {
  let s_code;

  fetch(BASE_URL + get_single_appointments_url + `?appointment_id=${data.id}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        success && success(result.response.appointment);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const getPaidUnpaidAppointments = (data, success, fail) => {
  let s_code;
  let query = '?'
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  for (let dt in data) {
    if (data[dt]) {
      query += `${dt}=${data[dt]}&`
    }
  }
  fetch(BASE_URL + paid_unpaid_appointments + `${query}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${access_token}`,
    }
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        success && success(result);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const UpdateAppointment = (data, success, fail) => {
  let s_code;

  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  let form_data = new FormData();
  for (let ky in data) {
    if (data[ky]) {
      form_data.append(ky, data[ky]);
    }
  }

  fetch(BASE_URL + update_appointment_url, {
    method: "PUT",
    headers: {
      Authorization: `Token ${access_token}`,
    },
    body: form_data,
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        success && success(result);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const CancelAppointment = (data, success, fail) => {
  let s_code;

  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  let form_data = new FormData();
  for (let ky in data) {
    if (data[ky]) {
      form_data.append(ky, data[ky]);
    }
  }

  fetch(BASE_URL + cancel_appointment_url, {
    method: "PUT",
    headers: {
      Authorization: `Token ${access_token}`,
    },
    body: form_data,
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        success && success(result);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};


export const UpdateAppointmentServiceStatus = (data, success, fail) => {
  let s_code;

  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  let form_data = new FormData();
  for (let ky in data) {
    if (data[ky]) {
      form_data.append(ky, data[ky]);
    }
  }

  fetch(BASE_URL + appointment_service_status_update, {
    method: "PUT",
    headers: {
      Authorization: `Token ${access_token}`,
    },
    body: form_data,
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        success && success(result);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const UpdateServiceReversalStatus = (data, success, fail) => {
  let s_code;

  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  let form_data = new FormData();
  for (let ky in data) {
    if (data[ky]) {
      form_data.append(ky, data[ky]);
    }
  }

  fetch(BASE_URL + update_reversal_status, {
    method: "PATCH",
    headers: {
      Authorization: `Token ${access_token}`,
    },
    body: form_data,
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result?.status_code == 200) {
        toast.success(result?.response?.message, { toastId: "toast" })
        success && success(result);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const AppointmentCheckout = (data, success, fail) => dispatch => {
  let s_code;

  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  let form_data = new FormData();
  for (let ky in data) {
    if (data[ky]) {
      if (typeof data[ky] == "object") {
        form_data.append(ky, JSON.stringify(data[ky]));
      } else {
        form_data.append(ky, data[ky]);

      }
    }
  }

  fetch(BASE_URL + create_checkout_url, {
    method: "POST",
    headers: {
      Authorization: `Token ${access_token}`,
    },
    body: form_data,
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 201) {
        success && success(result);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const CreateBlockTime = (data, success, fail) => (dispatch) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }
  let form_data = new FormData();

  for (let dt in data) {
    if (data[dt]) {
      form_data.append(dt, data[dt]);
    }
  }
  fetch(BASE_URL + create_blockTime_url, {
    method: "POST",
    body: form_data,
    headers: {
      Authorization: `Token ${access_token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (result.status_code == 201) {
        success && success();
        dispatch({
          type: CREATE_APPOINTMENT,
          payload: result.response.appointments,
        });
      } else {
        fail && fail(result.response.message);
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const UpdateBlockTime = (data, success, fail) => (dispatch) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }
  let form_data = new FormData();

  for (let dt in data) {
    if (data[dt]) {
      form_data.append(dt, data[dt]);
    }
  }
  fetch(BASE_URL + update_blockTime_url, {
    method: "PUT",
    body: form_data,
    headers: {
      Authorization: `Token ${access_token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 200) {
        success && success();
        dispatch({
          type: CREATE_APPOINTMENT,
          payload: result.response?.asset,
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const getClientSales = (payload, setLoader, success, fail) => {
  const query = buildQueryString(payload)
  let s_code;
  setLoader && setLoader(true)
  fetch(BASE_URL + get_client_sales + `${query}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      setLoader && setLoader(false)
      success && success(result.response);
    })
    .catch((err) => {
      setLoader && setLoader(false)
      fail && fail();
    });
};

//appointment form client in nstyle
export const createClientAppointment = (data, success, fail) => (dispatch) => {

  let form_data = new FormData();

  for (let dt in data) {
    if (data[dt]) {
      if (typeof data[dt] == "object") {
        form_data.append(dt, JSON.stringify(data[dt]));
      } else {
        form_data.append(dt, data[dt]);
      }
    }
  }
  fetch(BASE_URL + create_client_appointment, {
    method: "POST",
    body: form_data,
    headers: {
      Authorization: `Token ${data.client_token}`
    }
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 201) {
        success && success(result.response);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const getAppointmentServices = (id, success, fail, setLoader) => (dispatch) => {
  setLoader && setLoader(true)
  let s_code;
  fetch(BASE_URL + get_appointments_service + `?appointment_group_id=${id}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      setLoader && setLoader(false)
      success && success(result.response);
    })
    .catch((err) => {
      setLoader && setLoader(false)
      fail && fail();
    });
}

export const getAppointmentGroupServices = (id, success, fail, setLoader) => (dispatch) => {
  setLoader && setLoader(true)
  let s_code;
  fetch(BASE_URL + get_appointments_group_services + `?appointment_group_id=${id}`)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      setLoader && setLoader(false)
      success && success(result.response);
    })
    .catch((err) => {
      setLoader && setLoader(false)
      fail && fail();
    });
}


export const getAppointmentLog = (location_id, appointment_id, access_token, success, fail) => async dispatch => {
  let headers = {
    // "Content-type": "application/json",
    headers: { Authorization: `Token ${access_token}` }
  }
  try {
    const result = await axios.get(
      `${BASE_URL}${get_appointment_log}?location_id=${location_id}&appointment_id=${appointment_id}`, headers
    );
    if (result?.status === 200) {
      success && success(result?.data?.response)
    } else {
      fail && fail(result)
    }

    return result;
  } catch (error) {
    fail && fail(error)
    return error;
  }
};

export const requestAppointmentServiceReversal = (data, success, fail) => (dispatch) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }
  let form_data = new FormData();

  for (let dt in data) {
    if (data[dt]) {
      if (typeof data[dt] == "object") {
        form_data.append(dt, JSON.stringify(data[dt]));
      } else {
        form_data.append(dt, data[dt]);
      }
    }
  }
  fetch(BASE_URL + create_reversal, {
    method: "POST",
    body: form_data,
    headers: {
      Authorization: `Token ${access_token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      // console.log(result, "res")
      if (result?.status_code == 200) {
        success && success(result);
        // console.log(result, "result2")

      } else {
        console.log(result?.status_code, "Fail")
        fail && fail();
      }
    })
    .catch((err) => {
      console.log("Catch")
      fail && fail();
    });
};

export const updateAppointmentDetails = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const result = await axios.put(BASE_URL + update_appointment_detail, data, {
      headers: { 'Authorization': `Token ${access_token}` },
    });
    if (result?.status === 200) {
      success && success(result?.data?.response?.message)
    } else {
      fail && fail()
    }
  } catch (error) {
    fail && fail()
  }

}


export const getEmployeeOfSelectedService = (payload, success, fail) => (dispatch) => {
  const query = buildQueryString(payload)
  let s_code;
  fetch(BASE_URL + get_employees_for_selected_service + query)
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      success && success(result?.response?.data);
    })
    .catch((err) => {
      fail && fail();
    });
}




export const checkEmployeeWorkingTimes = (data, success, fail) => async (dispatch) => {
  if (!data.app_time) {
    return
  }
  try {
    const result = await axios.post(BASE_URL + get_employee_check_time, data);
    if (result?.status === 200) {
      success && success(result?.data?.response)
    } else {
      fail && fail()
    }
  } catch (error) {
    fail && fail()
  }

}


export const checkEmployeeAvailabilityForAppointment = (data, success, fail) => async (dispatch) => {
  try {
    const result = await axios.post(BASE_URL + get_employee_check_availability_list, data);
    if (result?.status === 200) {
      success && success(result?.data?.response)
    } else {
      fail && fail()
    }
  } catch (error) {
    fail && fail()
  }

}


export const getClientLoyaltyPoints = (data, success, fail) => async (dispatch) => {
  try {
    const result = await axios.get(`${BASE_URL}${get_client_loyalty_points}?location_id=${data?.location_id}&client_id=${data?.client_id}`);
    if (result?.status === 200) {
      success && success(result?.data?.response)
    } else {
      fail && fail(result)
    }
    return result;
  } catch (error) {
    fail && fail(error)
    return error;
  }
};


export const getClientCheckoutVouchers = (data, success, fail) => async (dispatch) => {
  try {
    const result = await axios.get(`${BASE_URL}${get_client_checkout_vouchers}?location_id=${data?.location_id}&client_id=${data?.client_id}`);
    if (result?.status === 200) {
      success && success(result?.data?.response)
    } else {
      fail && fail(result)
    }
    return result;
  } catch (error) {
    fail && fail(error)
    return error;
  }
};

export const getClientCheckoutMemberships = (data, success, fail) => async (dispatch) => {
  try {
    const result = await axios.get(`${BASE_URL}${get_client_checkout_memberships}?location_id=${data?.location_id}&client_id=${data?.client_id}`);
    if (result?.status === 200) {
      success && success(result?.data?.response)
    } else {
      fail && fail(result)
    }
    return result;
  } catch (error) {
    fail && fail(error)
    return error;
  }
};
export const getClientCheckoutGiftCard = (data, success, fail) => async (dispatch) => {
  try {
    const query = buildQueryString(data)
    const result = await axios.get(`${BASE_URL}${get_client_checkout_giftCard_url}${query}`);
    if (result?.status === 200) {
      success && success(result?.data?.response)
    } else {
      fail && fail(result)
    }
    return result;
  } catch (error) {
    fail && fail(error)
    return error;
  }
};

export const getSingleCouponOnCheckout = (data, success, fail) => async (dispatch) => {
  const query = buildQueryString(data)
  try {
    const result = await axios.get(`${BASE_URL}${get_single_coupon_data}${query}`);
    if (result?.status === 200) {
      success && success(result?.data?.response)
    } else {
      fail && fail(result)
    }
    return result;
  } catch (error) {
    fail && fail(error)
    return error;
  }
};
export const getSingleGiftCardOnCheckout = (data, success, fail) => async (dispatch) => {
  const query = buildQueryString(data)
  try {
    const result = await axios.get(`${BASE_URL}${get_detail_from_code_url}${query}`);
    if (result?.status === 200) {
      success && success(result?.data)
    } else {
      fail && fail(result)
    }
    return result;
  } catch (error) {
    fail && fail(error)
    return error;
  }
};


export const PostMissedOpportunity = (data, success, fail) => (dispatch) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }
  let form_data = new FormData();

  for (let dt in data) {
    if (data[dt]) {
      if (typeof data[dt] == "object") {
        form_data.append(dt, JSON.stringify(data[dt]));
      } else {
        form_data.append(dt, data[dt]);
      }
    }
  }
  fetch(BASE_URL + create_missed_opportunity, {
    method: "POST",
    body: form_data,
    headers: {
      Authorization: `Token ${access_token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (result.status_code == 200) {
        success && success(result?.response?.service_group, result?.response?.message)
      } else {
        fail && fail(result.response.message);
      }
    })
    .catch((err) => {
      fail && fail();
    });
};


export const getMissedOpportunity = (data, success, fail) => {
  let s_code;
  let query = '?'
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  for (let dt in data) {
    if (data[dt]) {
      query += `${dt}=${data[dt]}&`
    }
  }
  fetch(BASE_URL + get_missed_opportunity + `${query}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${access_token}`,
    }
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        success && success(result);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const deleteMissedOpportunity = (data, success, fail) => {
  let s_code;
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  fetch(BASE_URL + get_missed_opportunity + `${data}`, {
    method: "DELETE",
    headers: {
      Authorization: `Token ${access_token}`,
    }
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        success && success(result);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};


// Create Comment & Get Comment

export const createComment = (data, success, fail) => (dispatch) => {
  data = {
    ...data,
    employeeId: data?.employee,
    comment: data?.comment,
    appointmentId: data?.appointmentId
  }
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }
  let form_data = new FormData();

  for (let dt in data) {
    if (data[dt]) {
      if (typeof data[dt] == "object") {
        form_data.append(dt, JSON.stringify(data[dt]));
      } else {
        form_data.append(dt, data[dt]);
      }
    }
  }
  fetch(BASE_URL + create_comment, {
    method: "POST",
    body: form_data,
    headers: {
      Authorization: `Token ${access_token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 201) {
        success && success(result);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const getComment = (data, success, fail) => {
  let query = '?'
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  for (let dt in data) {
    if (data[dt]) {
      query += `${dt}=${data[dt]}&`
    }
  }
  fetch(BASE_URL + get_comment + `${query}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${access_token}`,
    }
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result?.status_code == 200) {
        success && success(result);
      } else {
        fail && fail(result);
      }
    })
    .catch((err) => {
      fail && fail(err);
    });
};





// Create Comment & Get Comment for employee

export const createEmployeeComment = (data, success, fail) => (dispatch) => {
  data = {
    ...data,
    employee_id: data?.employee_id,
    comment: data?.comment,
    location_id: data?.location_id
  }
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }
  let form_data = new FormData();

  for (let dt in data) {
    if (data[dt]) {
      if (typeof data[dt] == "object") {
        form_data.append(dt, JSON.stringify(data[dt]));
      } else {
        form_data.append(dt, data[dt]);
      }
    }
  }
  fetch(BASE_URL + create_employee_comment, {
    method: "POST",
    body: form_data,
    headers: {
      Authorization: `Token ${access_token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 200) {
        success && success(result);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};



export const getEmployeeComment = (data, success, fail) => {
  // const location = data?.location_id

  let query = buildQueryString(data)
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }


  fetch(BASE_URL + get_employee_comment + query, {
    method: "GET",
    headers: {
      Authorization: `Token ${access_token}`,
    }
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result?.status_code == 200) {
        success && success(result);
      } else {
        fail && fail(result);
      }
    })
    .catch((err) => {
      fail && fail(err);
    });
};






export const UserCheckIn = (data, success, fail) => {
  let s_code;

  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  let form_data = new FormData();
  for (let ky in data) {
    if (data[ky]) {
      form_data.append(ky, data[ky]);
    }
  }

  fetch(BASE_URL + appointment_check_in, {
    method: "PUT",
    headers: {
      Authorization: `Token ${access_token}`,
    },
    body: form_data,
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        success && success(result);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};


export const groupAppointmentCheckIn = (data, success, fail) => {
  let s_code;

  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  let form_data = new FormData();
  for (let ky in data) {
    if (data[ky]) {
      form_data.append(ky, data[ky]);
    }
  }

  fetch(BASE_URL + group_appointment_check_in, {
    method: "PUT",
    headers: {
      Authorization: `Token ${access_token}`,
    },
    body: form_data,
  })
    .then((response) => {
      s_code = response.status;
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (s_code == 200) {
        success && success(result);
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};
