import React, { useState, useEffect, useRef } from "react";
import Layout from "Components/TenantApp/Dashboard/AdminBooking/Layout";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import { DateCard } from "Components/Utility/DateSlider";
import { MONTHS, MONTHS_DURATIONS, WEEKDAYS } from "Constants/Data/Months";
import RightBar from "./RightBar";
import moment from "moment";
import useProfileAppointment from "./helper";
import { useDispatch } from "react-redux";
import { SALE_SERVICES } from "Redux/TenantRedux/ActionTypes/saleServiceType";
import {
  chechEmployeeAvailability,
  employeeAvailability,
  getRemdomFreeEmployee,
} from "Adapters/Api/businessLocationInNstyleApp";
import { toast } from "react-toastify";
import Svgs from "Assets/svgs";
import { apiErrorMessage } from "Constants/Data/Errors";

const SelectTime = ({ setStep, step }) => {
  const dt = new Date();
  const sliderRef = useRef();
  const DateCardRef = useRef();
  const [Selected, setSelected] = useState();
  const dispatch = useDispatch();
  const [employeeTimeSlot, setEmployeeTimeSlot] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [available, setavailable] = useState(true);

  const {
    selectedProfileService,
    addedService,
    totalPrice,
    location,
    token,
    onAppointmentBook,
    client_id,
    loading,
    business_location
  } = useProfileAppointment();


  const [appointmentData, setAppointmentData] = useState({
    current_month: 0,
    selected_month: "",
    appointment_date: "",
    current_year: "",
    selected_day: "",
    date: "",
  });

  // this method handle the auto scroll to current date in date bar menu
  const SliderRef_Hanlder = () => {
    let slider = sliderRef.current;
    let date_card = DateCardRef.current;
    let slider_left_ofset = slider.offsetLeft;
    let card_left_ofset = date_card.offsetLeft;

    let ofset_dif = card_left_ofset - slider_left_ofset;
    slider.scrollLeft = ofset_dif - 100;
  };



  useEffect(() => {
    if (sliderRef && sliderRef.current && DateCardRef && DateCardRef.current) {
      setTimeout(() => {
        SliderRef_Hanlder();
      }, 200);
    }
  }, [sliderRef.current, DateCardRef.current, appointmentData?.selected_month]);



  //set appointment date here by defualt
  useEffect(() => {
    let dt = new Date();
    let date = new Date(
      appointmentData?.selected_year &&
        appointmentData?.selected_year != dt.getFullYear()
        ? appointmentData?.selected_year
        : new Date()
    );
    let today_year = date.getFullYear();
    let today_month = date.getMonth();
    let today_date = date.getDate();
    let today_day = date.getDay() - 1;

    setAppointmentData({
      ...appointmentData,
      current_year: today_year,
      current_month: today_month,
      current_date: today_date,
      selected_month: MONTHS[today_month],
      selected_day: WEEKDAYS[today_day],
      appointment_date: `${today_year}-${today_month + 1}-${today_date}`,
    });
  }, [appointmentData?.selected_year]);

  //set time formate with 15 mints gap of the employee start and close time
  const timeSlot = (strt, clse) => {
    if (strt != null && clse != null) {
      var startTime = moment(strt, "HH:mm");
      var endTime = moment(clse, "HH:mm");
      var timeStops = [];
      while (startTime <= endTime) {
        timeStops.push(new moment(startTime).format("HH:mm:ss"));
        startTime.add(30, "minutes");
      }
      setEmployeeTimeSlot(
        timeStops.map((ele) => ({
          value: ele,
          label: moment(ele, ["HH:mm:ss"]).format("h:mm A"),
        }))
      );
    } else {
      setEmployeeTimeSlot([]);
    }
  };

  // show time detail of selected date set here
  useEffect(() => {
    const app_day = moment(appointmentData?.appointment_date)
      .format("dddd")
      .toLocaleLowerCase();
    setSelectedDate(
      moment(appointmentData?.appointment_date).format("D MMM,YYYY")
    );
    const res = location?.opening_hours?.find((dy) => dy?.day === app_day);
    timeSlot(res?.start_time, res?.close_time);
    setSelected(res?.start_time);
  }, [location, appointmentData?.appointment_date]);

  //check the employee availability on the selected date and time
  useEffect(() => {
    if (employeeTimeSlot?.length > 0 && Selected) {
      let start = moment(Selected, "HH:mm");
      let arr = [];

      selectedProfileService?.selectedService?.forEach((ele, i) => {
        const drtion_arr = ele?.duration?.split(" ");
        if (drtion_arr?.length > 1) {
          const hrs =
            drtion_arr[0]?.toString()?.includes("h") &&
            drtion_arr[0]?.split("h")[0] * 60;
          const mns = drtion_arr[1]?.toString()?.split("min")[0] * 1;
          const total = hrs + mns;
          arr.push(new moment(start).format("HH:mm:ss"));
          start.add(total, "minutes");
        } else {
          if (drtion_arr[0]?.toString()?.includes("min")) {
            const mns = drtion_arr[0]?.toString()?.split("min")[0] * 1;
            arr.push(new moment(start).format("HH:mm:ss"));
            start.add(mns, "minutes");
          } else {
            const hrs_to_mins = drtion_arr[0]?.toString()?.includes("h") && drtion_arr[0]?.split("h")[0] * 60;
            arr.push(new moment(start).format("HH:mm:ss"));
            start.add(hrs_to_mins, "minutes");
          }

        }
      });


      // formate data
      const result = selectedProfileService?.selectedService?.map(
        (itm, ind) => {
          return {
            ...itm,
            date_time: arr[ind],
          };
        }
      );


      // formate date for get available employee details
      const format_data = selectedProfileService?.selectedService?.map(
        (itm, ind) => {
          return {
            app_time: arr[ind],
            member_id: itm?.member,
            duration: itm?.duration,
            date: appointmentData?.appointment_date,
            service: itm?.id,
          };
        }
      );


      //set date_time in the selected service and set appointment detail
      if (result?.length > 0 && result[0]?.date_time != "Invalid date") {
        dispatch({
          type: SALE_SERVICES.PROFILE_SELECTED_SERIVCE,
          payload: {
            ...selectedProfileService,
            appointment: appointmentData,
            selectedService: result,
          },
        });
      }



      //if the required detail in the format_date then hit api and get detail about available employee
      if (
        format_data?.length > 0 &&
        format_data[0]?.member_id &&
        format_data[0]?.app_time != null &&
        format_data[0]?.app_time != undefined &&
        (new Date(appointmentData?.appointment_date)?.getDate() === dt.getDate() ?
          Selected <= moment(dt).format("HH:mm:ss") ? false : true : true)

      ) {

        dispatch(
          chechEmployeeAvailability(
            format_data,
            selectedProfileService?.hash,
            (message, response) => {
              setavailable(response);
              if (!response) {
                toast.success(message, { toastId: "toast" });
              }
            },
            (message) => {
              toast?.error(message);
            }
          )
        );
      } else {
        if (
          (appointmentData?.appointment_date)
          && (new Date(appointmentData?.appointment_date)?.getDate() === dt.getDate() ?
            Selected <= moment(dt).format("HH:mm:ss") ? false : true : true)
        ) {
          // { emp_list: format_data, hash: selectedProfileService?.hash, business: business_location },
          dispatch(
            getRemdomFreeEmployee(
              {
                hash: selectedProfileService?.hash,
                business: business_location,
                appointment_date: appointmentData?.appointment_date,
                start_time: Selected,
              },
              (data) => {
                dispatch({
                  type: SALE_SERVICES.PROFILE_SELECTED_SERIVCE,
                  payload: {
                    ...selectedProfileService,
                    selectedService: result,
                    free_employee: data[0],
                  },
                });
              },
              () => {
                toast.error(apiErrorMessage, { toastId: "toast" });
              }
            )
          );
        }
      }
    }
  }, [Selected, appointmentData?.appointment_date]);

  //  online booking appointments
  const onCreateAppointment = () => {
    const formatData = {
      selected_month: appointmentData?.selected_month,
      appointment_date: appointmentData?.appointment_date,
      appointments: selectedProfileService?.selectedService?.map((itm) => {
        return {
          date_time: itm?.date_time,
          duration: itm?.duration,
          service: itm?.service,
          price: itm?.price,
          member: selectedProfileService?.free_employee?.id
            ? selectedProfileService?.free_employee?.id
            : itm?.member,
        };
      }),
      appointment_notes: selectedProfileService?.appointment_notes,
      client_type: "Inhouse",
      current_year: appointmentData?.current_year,
      current_date: appointmentData?.current_date,
      selected_day: appointmentData?.selected_day,
      client: client_id,
      business: selectedProfileService?.business,
      business_address: selectedProfileService?.business_address,
      hash: selectedProfileService?.hash,
      token: selectedProfileService?.token,
    };

    onAppointmentBook(formatData);
  };



  return (
    <Layout
      loading={loading}
      className="!p-0"
      parent_className="!p-0"
      count={`${step}/4`}
      backClick={() => {
        if (selectedProfileService?.is_empoyee_select) {
          setStep(step - 1);
        } else {
          setStep(step - 2);
        }
      }}
      heading={"Select time"}
      NextClick={() => {
        onCreateAppointment();
      }}
      disabled={
        (new Date(appointmentData?.appointment_date)?.getDate() === dt.getDate() ?
          Selected <= moment(dt).format("HH:mm:ss") ? true : false : false ||
          employeeTimeSlot?.length === 0) || (!available)
      }
    >
      <div className="md:grid flex flex-col grid-cols-7">
        <div className="col-span-5 border-r">
          <div className="px-8 pt-4 pb-8">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between gap-3">
                <div>
                  <p className="text-sm flex items-center gap-2">
                    Select Date{" "}
                    {appointmentData?.selected_month
                      ? `- ${appointmentData?.selected_month}`
                      : ""}{" "}
                    <select
                      className="outline-none border-0 rounded-md text-sm"
                      placeholder="year"
                      onChange={(e) => {
                        setAppointmentData({
                          ...appointmentData,
                          selected_year: e.target.value,
                        });
                      }}
                    >
                      <option value={2023}>2023</option>
                      <option value={2024}>2024</option>
                    </select>
                  </p>
                </div>
                <div>
                  <Dropwdown
                    title=""
                    placeholder="Select Month"
                    showTitle={false}
                    value={appointmentData.selected_month}
                    name="selected_month"
                    onChange={(e) => {
                      setAppointmentData({
                        ...appointmentData,
                        selected_month: e.target.value,
                        // appointment_date: undefined
                      });
                    }}
                    options={MONTHS.map((mnth, index) => {
                      const dt = new Date();
                      return {
                        label: mnth,
                        value: mnth,
                        disabled:
                          appointmentData.current_year > dt.getFullYear()
                            ? false
                            : index >= dt.getMonth()
                              ? false
                              : true,
                      };
                    })}
                  />
                </div>
              </div>
              <div
                className="flex items-center gap-3 overflow-auto py-3 scroll-smooth"
                ref={sliderRef}
              >
                {/* <div className="flex gap-3 overflow-auto pb-4"> */}
                {appointmentData?.current_month >= 0 &&
                  [
                    ...Array(
                      MONTHS_DURATIONS[
                      MONTHS.indexOf(appointmentData?.selected_month)
                      ]
                    ).keys(),
                  ].map((day, index) => {
                    let is_selected =
                      appointmentData.appointment_date ==
                        `${appointmentData?.current_year}-${MONTHS.indexOf(appointmentData?.selected_month) + 1
                        }-${day + 1}`
                        ? true
                        : false;
                    let is_start =
                      MONTHS.indexOf(appointmentData?.selected_month) ==
                        appointmentData?.current_month
                        ? appointmentData?.current_date == day + 1
                        : index == 0;
                    return (
                      <DateCard
                        date_card_ref={is_selected ? DateCardRef : is_start ? DateCardRef : { current: undefined }}
                        // date_card_ref={{ current: undefined }}
                        year={appointmentData?.current_year}
                        month={appointmentData?.selected_month}
                        current_month={appointmentData?.current_month}
                        date={day + 1}
                        disabled={
                          MONTHS.indexOf(appointmentData?.selected_month) ==
                            appointmentData?.current_month
                            ? appointmentData?.current_date <= day + 1
                              ? false
                              : true
                            : false
                        }
                        selected={is_selected}
                        onSelected={(value) => {
                          setAppointmentData({
                            ...appointmentData,
                            appointment_date: value,
                          });
                        }}
                      />
                    );
                  })}
                {/* </div> */}
              </div>
              <h1 className="font-semibold text-xl">{selectedDate}</h1>
              <div className="flex items-center justify-center gap-3 flex-wrap">
                {employeeTimeSlot?.length > 0 ? (
                  employeeTimeSlot
                    ?.filter((itm) => {
                      if (appointmentData?.appointment_date === `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`) {
                        if (itm?.value >= moment(dt).format("HH:mm:ss")) {
                          return true
                        }
                      } else {
                        return true
                      }
                    }
                    )
                    .map((ele, i) => {
                      return (
                        <div
                          onClick={() => {
                            setSelected(ele?.value);
                          }}
                          className={`border rounded-md flex items-center align-middle justify-center w-[8rem] h-[3rem] text-sm text-[#101928] cursor-pointer hover:bg-gray-100 ${Selected == ele?.value &&
                            "!bg-blue-100 border-blue-500"
                            }`}
                        >
                          {ele?.label}
                        </div>
                      );
                    })
                ) : (
                  <div className="h-[15rem] w-full flex items-center justify-center text-center">
                    <div className="flex flex-col gap-5">
                      <Svgs.Off_Day />
                      <h1 className="font-semibold">Off Day</h1>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <RightBar
          services={addedService}
          totalPrice={totalPrice}
          location={location}
        />
      </div>
    </Layout>
  );
};

export default SelectTime;
