import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import PasswordInput from "../Elements/PasswordInput";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import { CreateEmployeePassword } from "Adapters/Api/auth/employeeSetPassword/index";
import { apiErrorMessage } from "Constants/Data/Errors";
import Header from "Components/NstyleApp/Wrappers/Header";

const EmployeePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let params = new URLSearchParams(document.location.search);
  const userId = params.get("user_id");

  // useStates
  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const [btn_loading, setBtnLoading] = useState(false);

  // onChange function
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  // validating input fields
  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  // function to create employee login password
  const handleCreateEmployeePassword = () => {
    setBtnLoading(true);
    if (userId === "") {
      navigate("/");
    } else if (userId) {
      if (input.password && input.confirmPassword) {
        if (input.password.length >= 8 && input.confirmPassword.length >= 8) {
          if (input.confirmPassword === input.password) {
            let employee_data = {
              password: input.password,
              user_id: userId,
            };
            // function to set employee password
            dispatch(
              CreateEmployeePassword(
                employee_data,
                (message) => {
                  toast.success(message, { toastId: "toast" });
                  setBtnLoading(false);
                  window.location.href = `http://${process.env.REACT_APP_SERVER_APPLICATION_DOMAIN}/`
                },
                () => {
                  setBtnLoading(false);
                  toast.error(apiErrorMessage, { toastId: "toast" });
                }
              )
            );
          } else {
            setBtnLoading(false);
            toast.error("Password and Confirm Password does not match", { toastId: "toast" });
          }
        } else {
          setBtnLoading(false);
          toast.error("Password length must be greater or equal to 8", { toastId: "toast" });
        }
      } else {
        setBtnLoading(false);
        toast.error("Please enter password and confirm password", { toastId: "toast" });
      }
    } else {
      setBtnLoading(false);
      toast.error("URL is invalid", { toastId: "toast" });
    }
  };

  return (
    <>
      <Header />
      <div className="h-full w-full flex items-center justify-center top-3.5 m-auto 2xs:w-[60%] mt-20">
        <div className="w-full shadow-md rounded-xl relative p-5">
          <div className="flex flex-col gap-4 ">
            <h2 className=" font-semibold text-xl mb-5">Set Password</h2>

            <div>
              <PasswordInput
                type="password"
                name="password"
                title={"Password"}
                placeholder="Enter Password"
                value={input.password}
                onChange={onInputChange}
                onBlur={validateInput}
                error={error.password}
              />
            </div>
            <div>
              <PasswordInput
                type="password"
                name="confirmPassword"
                title={"Confirm Password"}
                placeholder="Enter Confirm Password"
                value={input.confirmPassword}
                onChange={onInputChange}
                onBlur={validateInput}
                error={error.confirmPassword}
              />
            </div>

            <LoginBtn
              className={"mt-8"}
              text="Save Password"
              loading={btn_loading}
              onClick={handleCreateEmployeePassword}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeePassword;
