/* eslint-disable array-callback-return */
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import useObjectKeys from "Hooks/useObjectKeys";
import { add_service_group_discount, get_client_discount_promotion, update_service_group_discount } from "Adapters/Api/clientDiscountPromotion";
import { getSaleServices, getServiceGroup } from "Adapters/Api/saleServices";
import { discountErrors, navigateToDiscountPromotion } from "../../helper";
import { useRef } from "react";
import { getsProducts, getsProductsDropdownList } from "Adapters/Api/Products";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import { getBrandDropdownlist, get_business_brands } from "Adapters/Api/Brand";
import { getserviceDropdownList } from "Adapters/Api/services";

const initialState = {
  groupDiscount: [{
    service_group: '',
    discount: '',
    brand: '',
    brand_discount: '',
    is_deleted: 'False',
    // service: '',
    // servicePercentage: ''
  }],
  brandRestriction: '',
  brandRestrictions: [],

  serviceRestriction: '',
  serviceRestrictions: [],

  promotion_name: '',
  dateRestriction: { startDate: '', endDate: '' },
  blockDate: '',
  blockDates: [],
  storeRestriction: '',
  storeRestrictions: [],
  dayRestrictions: [
    { day: 'Monday', value: '', is_deleted: 'False' },
    { day: 'Tuesday', value: '', is_deleted: 'False' },
    { day: 'Wednesday', value: '', is_deleted: 'False' },
    { day: 'Thursday', value: '', is_deleted: 'False' },
    { day: 'Friday', value: '', is_deleted: 'False' },
    { day: 'Saturday', value: '', is_deleted: 'False' },
    { day: 'Sunday', value: '', is_deleted: 'False' },
  ]
}
export const useAddSpecificDiscount = () => {
  const formRef = useRef()
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { setErrors, validation, errors, onBlurHandler } = useValidations();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const dispatch = useDispatch();
  const { objectKeyConvertToArray } = useObjectKeys();
  const businessId = state?.business?.business?.id;
  const [isEdit, setIsEdit] = useState(false);
  const [toggleStore, setToggleStore] = useState(false);
  const [toggleblockdate, setToggleBlockDate] = useState(false);
  const [toggleDay, setToggleDay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(initialState);
  const [formErrors, setFormErrors] = useState()
  const categoryDiscountDropdown = [{ value: 'All', label: 'All' }, { value: 'Service', label: 'Service' }, { value: 'Retail', label: 'Retail' }, { value: 'Voucher', label: 'Voucher' }]
  const locations = state?.locations?.locations;
  const params = useParams();
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const serviceGroupRedux = state?.saleService?.servicesgroup;
  const servicesRedux = state?.service?.services;
  const brandRedux = state?.brand?.brands;
  const productRedux = state?.product?.products;

  const [serviceToggle, setServiceToggle] = useState(false)
  const [brandToggle, setBrandToggle] = useState(false)

  const [product, setProduct] = useState([])
  const [servic, setServic] = useState([])

  const {
    blockDates,
    blockDate,
    storeRestrictions,
  } = discount;

  let dateRangeCheck = moment(blockDate).valueOf() >= moment(discount.dateRestriction.startDate).valueOf() && moment(blockDate).valueOf() <= moment(discount.dateRestriction.endDate).valueOf()
  let validateBlockDate = discount?.blockDates?.find(obj => obj.date === blockDate)

  useEffect(() => {
    dispatch(getServiceGroup());
  }, [])

  useEffect(() => {
    if (params.servicegroup_discount_id === undefined) {
      setIsEdit(false);
    }
    if (state?.clientDiscountPromotion?.discountPromotions?.length === 0 && params.servicegroup_discount_id) {
      dispatch(get_client_discount_promotion())
      setIsEdit(true);
    } if (params.servicegroup_discount_id) {
      setIsEdit(true);
    }
  }, [params.servicegroup_discount_id, state?.clientDiscountPromotion?.discountPromotions?.length]);

  useEffect(() => {
    let editFlatDiscount = state?.clientDiscountPromotion?.discountPromotions?.find((obj) => obj.id === params.servicegroup_discount_id);

    if (editFlatDiscount) {
      let { servicegroup_discount, id, date_restrictions, block_date, day_restrictions, promotion_name, excluded_products, excluded_services, } = editFlatDiscount;
      let { start_date, end_date, business_address } = date_restrictions;

      let dayRestrictions = initialState?.dayRestrictions?.map((item) => {
        let obj = day_restrictions.find(obj => obj.day == item.day);
        if (obj?.day === item.day) {
          return {
            id: obj?.id,
            day: obj?.day,
            value: obj?.day,
            is_deleted: obj?.is_deleted
          }
        } else {
          return { ...item }
        }
      })
      day_restrictions?.length > 0 && setToggleDay(true);
      business_address?.length > 0 && setToggleStore(true);

      excluded_services?.length > 0 && setServiceToggle(true)
      excluded_products?.length > 0 && setBrandToggle(true)

      if (editFlatDiscount && date_restrictions) {
        setDiscount({
          ...discount,
          id: id,
          promotion_name: promotion_name,
          groupDiscount: servicegroup_discount?.map((obj) => { return { ...obj, service_group: obj?.servicegroup, discount: obj?.discount, brand: obj?.brand?.id, brand_discount: obj?.brand_discount } }),
          dateRestriction: { startDate: start_date, endDate: end_date },
          blockDates: block_date,
          storeRestrictions: business_address,
          dayRestrictions: dayRestrictions,

          serviceRestrictions: excluded_services.map(itm => itm.id),
          brandRestrictions: excluded_products.map(itm => itm.id),
        })
      }
    }
  }, [isEdit, state?.clientDiscountPromotion?.discountPromotions?.length])

  const handleChange = (e) => {
    const { value, name } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (name === 'startDate' || name === 'endDate') {
      if (name === 'startDate') {
        setDiscount((prev) => ({
          ...prev,
          dateRestriction: { ...prev.dateRestriction, endDate: "" }
        }));
      }
      setDiscount((prev) => ({
        ...prev,
        dateRestriction: { ...prev?.dateRestriction, [name]: value }
      }));
    }
    if (name === 'blockDate') {
      let validateBlock = discount?.blockDates?.find(obj => obj.date === value)

      let dateRangeCheck = moment(value).valueOf() >= moment(discount.dateRestriction.startDate).valueOf() && moment(value).valueOf() <= moment(discount.dateRestriction.endDate).valueOf()
      if (!dateRangeCheck) {
        setErrors((prev) => ({
          ...prev,
          [name]: discountErrors.dateRange,
        }));
      }

      if (dateRangeCheck) {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
      if (validateBlock) {
        setErrors((prev) => ({
          ...prev,
          [name]: discountErrors.dateAlreadySelected,
        }));
      }
      setDiscount((prev) => ({
        ...prev,
        [name]: value
      }));
    }
    else {
      setDiscount((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  }
  const addServiceGroupDiscount = (e, i) => {
    const { name, value } = e.target;
    if (value) {
      setErrors((prev) => ({ ...prev, [`${name}${i}`]: "" }))
    }
    if (name == "service_group") {
      setDiscount((prev) => ({
        ...prev,
        serviceRestrictions: []
      }));
    }

    // if (name == "brand") {
    //   setDiscount((prev) => ({
    //     ...prev,
    //     brandRestrictions: []
    //   }));
    // }
    setDiscount((prev) => ({
      ...prev,
      groupDiscount: [
        ...prev?.groupDiscount.map((obj, index) => {
          if (i === index) {
            return {
              ...obj,
              [name]: value,
              is_deleted: 'False'
            }
          }
          else {
            return obj
          }
        })
      ],
    }));
  }
  const filterServiceGroupDiscountField = (id, index) => {
    if (id) {  // edit 
      var filter = discount?.groupDiscount?.map((obj) => {
        if (obj === discount?.groupDiscount[index]) {
          return {
            ...obj,
            is_deleted: 'True'
          }
        }
        else {
          return { ...obj }
        }
      })
      setDiscount((prev) => ({
        ...prev,
        groupDiscount: filter,
      }))
      setServic(filter)
      setProduct(filter)
    }
    else { // create no need of isDelete here 
      let filter = discount.groupDiscount.filter((obj) => obj !== discount.groupDiscount[index])
      let serviceRestrictionsData = filter?.filter(itm => serviceExcludedDropdown?.find(item => item == itm?.value))
      let brandRestrictionsData = filter?.filter(itm => productExcludedDropdown?.find(item => item == itm?.value))

      setServic(filter)
      setProduct(filter)
      setDiscount((prev) => ({
        ...prev,
        groupDiscount: filter,
        brandRestrictions: brandRestrictionsData,
        serviceRestrictions: serviceRestrictionsData
      }))
    }
  }

  const addBlockDates = () => {
    setDiscount((prev) => ({
      ...prev,
      blockDates: [...prev.blockDates, { date: discount.blockDate, is_deleted: 'False' }],
      blockDate: ''
    }))
  }
  const filterBlockDate = (id, index) => {
    if (id) {
      var filter = blockDates?.map((obj) => {
        if (obj === blockDates[index]) {
          return {
            ...obj,
            is_deleted: 'True'
          }
        }
        else {
          return { ...obj }
        }
      })
      setDiscount((prev) => ({
        ...prev,
        blockDates: filter,
      }))
    }
    else {
      var filter = blockDates?.filter((obj) => obj != blockDates[index])
      setDiscount((prev) => ({
        ...prev,
        blockDates: filter,
      }))
    }
  }
  const addStoreRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      storeRestrictions: [...prev.storeRestrictions, discount.storeRestriction],
      storeRestriction: ''
    }))
    setErrors((prev) => ({
      ...prev,
      storeRestriction: "",
    }));
  }

  const addBrandRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      brandRestrictions: [...prev.brandRestrictions, discount.brandRestriction],
      brandRestriction: ''
    }))
  }
  const addServiceRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      serviceRestrictions: [...prev.serviceRestrictions, discount.serviceRestriction],
      serviceRestriction: ''
    }))
  }
  const filterStoreRestriction = (index) => {
    var filter = storeRestrictions?.filter(obj => obj !== storeRestrictions[index])
    setDiscount((prev) => ({
      ...prev,
      storeRestrictions: filter,
    }))
  }
  const handleDayChecklist = (e) => {
    setDiscount((prev) => ({
      ...prev,
      dayRestrictions: discount?.dayRestrictions?.map(obj => {
        // handle checklist withoutd ids 
        // check function
        if (obj.day === e.target.name && obj.value === '' && obj.is_deleted === 'False') {
          return {
            ...obj,
            value: e.target.name
          }
        }
        // UN Check block
        if (obj.value === e.target.value && !obj.id) {
          return {
            ...obj,
            value: ''
          }
        }

        // handling update scenarios for checklist
        // un check block for checked
        if (obj.id && obj.value === e.target.value && obj.is_deleted === 'False') {
          return {
            ...obj,
            is_deleted: 'True',
            value: ''
          }
        }

        // checked block and isDeleted 
        if (obj.day === e.target.name && obj.value === '' && obj.is_deleted && obj.id) {
          return {
            ...obj,
            value: e.target.name,
            is_deleted: 'False'
          }
        }
        else {
          return obj
        }
      })
    }))
  }

  const locationDropdown = useMemo(() => {
    return locations?.map((obj) => {
      if (obj) {
        return {
          label: obj?.address_name,
          value: obj?.id
        }
      }
    })
  }, [locations])

  const serviceGroupDropdown = useMemo(() => {
    return serviceGroupRedux?.map((obj) => {
      if (obj) {
        return {
          label: obj?.name,
          value: obj?.id
        }
      }
    })
  }, [serviceGroupRedux])

  const brandDropdown = useMemo(() => {
    return brandRedux?.map((obj) => {
      return {
        label: obj.name,
        value: obj.id
      }
    })
  }, [brandRedux])

  const serviceExcludedDropdown = useMemo(() => {
    // let newArr = [...servic]
    let newArr = []
    discount?.groupDiscount
      ?.map(itm => {
        newArr = [...newArr, ...servicesRedux?.filter(item => item?.service_group?.find(tm => tm.id === itm?.service_group))]
      })

    setServic(newArr)
    return newArr?.map((obj) => {
      if (obj)
        return {
          label: obj.name,
          value: obj.id
        }
    })
  }, [discount.groupDiscount])

  const productExcludedDropdown = useMemo(() => {
    // let newArr = [...product]
    let newArr = []
    // console.log(discount?.groupDiscount, "discount?.groupDiscount")
    // const filteredArray = productRedux?.filter(
    //   item => discount.groupDiscount.some(brandObj => item?.brand?.id === brandObj?.brand)
    // );
    // console.log(filteredArray, productRedux, "filteredArray")
    discount?.groupDiscount
      ?.map(itm =>
        newArr = [...newArr, ...productRedux?.filter(item => item?.brand?.id === itm?.brand)]
      )
    setProduct(newArr)
    return newArr?.map((obj) => {
      return {
        label: obj.name,
        value: obj.id
      }
    })
  }, [discount.groupDiscount])


  let success = () => {
    setLoading(false);
    setDiscount(initialState);
    navigateToDiscountPromotion(navigate)
    setToggleDay(false);
    setToggleStore(false);
    setToggleBlockDate(false)
  }
  let fail = () => {
    setLoading(false);
  }

  const onSubmit = () => {

    let payload = {
      business: businessId,
      start_date: discount.dateRestriction.startDate,
      end_date: discount.dateRestriction.endDate,
      servicegroup: discount.groupDiscount,
      blockdate: discount?.blockDates,
      promotion_name: discount.promotion_name,
      services: discount.serviceRestrictions,
      product: discount.brandRestrictions,
      location: discount.storeRestrictions
    }

    const requiredFields = {
      promotion_name: discount.promotion_name,
      startDate: discount.dateRestriction.startDate,
      endDate: discount.dateRestriction.endDate,
      storeRestriction: discount?.storeRestrictions?.length > 0 ? true : false
    }
    setErrors(validation(requiredFields));
    let result = validation(requiredFields);

    if (objectKeyConvertToArray(result)?.length === 0) {
      const errors = [];
      discount.groupDiscount?.forEach((data, index) => {
        const fieldErrors = {};
        if (!data.service_group) {
          fieldErrors.service_group = 'This field is required';
        }
        if (!data.discount) {
          fieldErrors.discount = 'This field is required';
        }
        if (!data.brand) {
          fieldErrors.brand = 'This field is required';
        }
        if (!data.brand_discount) {
          fieldErrors.brand_discount = 'This field is required';
        }
        errors[index] = fieldErrors;
      });
      // Update formErrors state with the new errors
      setFormErrors(errors);
      // If there are errors, return early and do not proceed with form submission
      if (errors?.some((error) => Object.keys(error)?.length > 0)) {
        formRef.current?.scrollIntoView({ behavior: "smooth" });
        return;
      } else {
        setLoading(true);
        if (isEdit) {
          if (payload) {
            payload.id = discount.id;
            payload.dayrestrictions = discount?.dayRestrictions?.filter(obj => obj.value || obj.is_deleted === "True");
            dispatch(update_service_group_discount(payload, access_token, success, fail))
          }
        } else {
          if (payload) {
            payload.dayrestrictions = discount?.dayRestrictions?.filter(obj => obj.value)?.map(obj => { return { day: obj.value } });
            dispatch(add_service_group_discount(payload, access_token, success, fail))
          }
        }
      }
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }

  const get_products = async () => {
    const response = await getsProductsDropdownList();
    if (response.status === 200) {
      let productList = response?.data?.response?.products;
      dispatch(getProducts(productList));
    }
  };

  useEffect(() => {
    dispatch(get_business_brands());
    dispatch(getBrandDropdownlist());
    dispatch(getserviceDropdownList());
    get_products();
  }, [])


  return {
    navigate, setErrors, validation, onBlurHandler,
    handleChange, setDiscount, filterServiceGroupDiscountField, addServiceGroupDiscount, handleDayChecklist,
    addBlockDates, filterBlockDate, addStoreRestriction, filterStoreRestriction, setToggleDay, setToggleStore, onSubmit, setLoading,
    errors, discount, categoryDiscountDropdown, toggleDay, toggleStore, loading, locationDropdown, dateRangeCheck, validateBlockDate,
    isEdit, serviceGroupDropdown, toggleblockdate, setToggleBlockDate, brandDropdown, translation, selectedLanguage,
    productExcludedDropdown, formErrors, setFormErrors, formRef,
    serviceExcludedDropdown,
    addBrandRestriction,
    addServiceRestriction,
    serviceToggle, setServiceToggle,
    brandToggle, setBrandToggle,
  };
};