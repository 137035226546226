import React from 'react'
import useLoyalityLogs from './helper';
// import { useNavigate } from "react-router-dom";
// import Svgs from 'Assets/svgs';
import LocationSelection from '../../../../../new-components/location'
import LoyaltyLogDetailed from './LoyaltyLogDetailed';
import ByCustomer from './ByCustomer';
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb';



const LoyaltyLogs = () => {

    const { clientRedux, customerLog, loading, detailedLog, translation, selectedLanguage, Section, setSection, startDate,
        setStartDate, endDate, setEndDate, startdt, setStartDt, closeDate, setcloseDate, customer_id, setCustomer_id, customer,
        setCustomer, invoiceLabels, InvoicePopup, setInvoicePopup, getInvoiceDetailData, selectedInvice, detailInvoiceLoading,
        location_currency, calculate_totals, reduxEmployees, total_tax, currentPage, setCurrentPage, customerPages,
        detailedCurrentPage, setDetailedCurrentPage, detailedPages, customerAllCount, detailedAllCount, invoice_titles,
        detailLoading, setSelectedInvice, clientSearchText, setClientSearchText, clientCurrentPage, setClientCurrentPage,
        clientLoader, clientPaginationData,
        noPaginationLoader, detailNoPaginationLoader
    } = useLoyalityLogs()

    const PageSize = 10
    return (
        <>
            <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
                <Breadcrumb
                    first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
                    second={selectedLanguage?.name == "English" ? "Loyalty Logs" : translation[455]?.value ? translation[455]?.value : "Loyalty Logs"}
                    url={'/dashboard/clients/'}
                />
                <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

                    <h2 className="text-primary font-bold lg:text-3xl text-xl">
                        {selectedLanguage?.name == "English" ? "Loyalty Logs" : translation[455]?.value ? translation[455]?.value : "Loyalty Logs"}
                    </h2>
                    <LocationSelection />
                </div>
                <div className='border-b mt-[2rem]'>
                    <div className='flex items-center gap-6'>
                        <div
                            className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${Section == 'ByCustomer' && 'font-semibold border-primary'}`}
                            onClick={() => { setSection('ByCustomer') }}
                        >
                            <h2 className='text-[#101928]'>
                                {selectedLanguage?.name == "English" ? "By Customer" : translation[1154]?.value ? translation[1154]?.value : "By Customer"}
                            </h2>
                        </div>
                        <div
                            className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${Section == 'LoyaltyLogDetailed' && 'font-semibold border-primary'}`}
                            onClick={() => { setSection('LoyaltyLogDetailed') }}
                        >
                            <h2 className='text-[#101928]'>
                                {selectedLanguage?.name == "English" ? "Loyalty Log Detailed" : translation[1155]?.value ? translation[1155]?.value : "Loyalty Log Detailed"}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className='mt-[2rem]'>
                    {
                        Section === 'ByCustomer' &&
                        <ByCustomer
                            // totals={totals}
                            // currency={locationDetail?.currency?.code}
                            // staffCommissionList={locationStaffCommissionReports}
                            setSection={setSection}

                            start={startDate}
                            setStartDate={setStartDate}
                            end={endDate}
                            setEndDate={setEndDate}

                            customer={customer_id}
                            setCustomer={setCustomer_id}

                            data={customerLog}

                            client={clientRedux}
                            // setMember={setMember}
                            // selectMember={selectMember}
                            // locationEmployee={locationEmployee}
                            // commissionReportData={commissionReportData}
                            loading={loading}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            PageSize={PageSize}
                            pages={customerPages}
                            allCount={customerAllCount}
                            translation={translation}
                            selectedLanguage={selectedLanguage}

                            clientSearchText={clientSearchText}
                            setClientSearchText={setClientSearchText}
                            clientCurrentPage={clientCurrentPage}
                            setClientCurrentPage={setClientCurrentPage}
                            clientLoader={clientLoader}
                            clientPaginationData={clientPaginationData}
                        />
                    }
                    {
                        Section === 'LoyaltyLogDetailed' &&
                        <LoyaltyLogDetailed
                            // currency={locationDetail?.currency?.code}
                            // commissionDetailList={locationDetailCommissionReports}
                            // selectEmployee={selectEmployee}
                            // setSelectEmployee={setSelectEmployee}
                            // locationEmployee={locationEmployee}
                            // itemType={itemType}
                            // setItemType={setItemType}
                            // commissionReportDetail={commissionReportDetail}
                            // isPopup={isPopup}
                            // setIsPopup={setIsPopup}
                            // selectedItem={selectedItem}
                            // setSeletedItem={setSeletedItem}
                            setSection={setSection}
                            start={startdt}
                            setStartDate={setStartDt}
                            end={closeDate}
                            setEndDate={setcloseDate}
                            setSelectedInvice={setSelectedInvice}
                            setInvoicePopup={setInvoicePopup}
                            client={clientRedux}
                            invoiceLabels={invoiceLabels}
                            customer={customer}
                            setCustomer={setCustomer}
                            data={detailedLog}
                            loading={detailLoading}
                            invoice_titles={invoice_titles}
                            InvoicePopup={InvoicePopup}
                            toggleInviceModal={getInvoiceDetailData}
                            selectedInvice={selectedInvice}
                            location_currency={location_currency}
                            calculate_totals={calculate_totals}
                            reduxEmployees={reduxEmployees}
                            total_tax={total_tax}
                            currentPage={detailedCurrentPage}
                            setCurrentPage={setDetailedCurrentPage}
                            PageSize={PageSize}
                            pages={detailedPages}
                            allCount={detailedAllCount}
                            translation={translation}
                            selectedLanguage={selectedLanguage}
                            detailInvoiceLoading={detailInvoiceLoading}
                            clientSearchText={clientSearchText}
                            setClientSearchText={setClientSearchText}
                            clientCurrentPage={clientCurrentPage}
                            setClientCurrentPage={setClientCurrentPage}
                            clientLoader={clientLoader}
                            clientPaginationData={clientPaginationData}


                        />
                    }

                </div>
            </div>

        </>
    )
}

export default LoyaltyLogs