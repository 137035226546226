const ALL_PERMISSION_OPTIONS = [
  "view",
  "create",
  "edit",
  "approve",
  "cancel",
  "accept",
  "modify"
];

export const EMPLOYEE_ALL_PERMISSIONS = (selectedLanguage, translation) => {
  return (
    [
      {
        title: selectedLanguage?.name == "English" ? "POS Application" : translation[566]?.value ? translation[566]?.value : "POS Application",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "root access",
            value: "pos_root_access",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
        ],
      },
      {
        title: selectedLanguage?.name == "English" ? "Mobile App" : translation[567]?.value ? translation[567]?.value : "Mobile App",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "root access",
            value: "mobile_root_access",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
        ],
      },
      {
        title: selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "root access",
            value: "account_root_access",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "bussiness settings/info",
            value: "account_business_setting",
            selected: ["edit"],
            disabled_options: ["create", "view", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "finance settings",
            value: "account_finance_setting",
            selected: ["edit"],
            disabled_options: ["create", "view", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "service management",
            value: "account_service_management",
            selected: ["edit"],
            disabled_options: ["create", "view", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "notification settings",
            value: "account_notification_setting",
            selected: ["edit"],
            disabled_options: ["create", "view", "approve", "cancel", "accept", "modify"],
          },
        ],
      },
      {
        title: selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "root access",
            value: "inventory_root_access",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "purchase order",
            value: "inventory_purchase_order",
            selected: ["view", "create", "edit"],
            disabled_options: ["cancel", "approve", "accept", "modify"],
          },
          {
            name: "goods reciept",
            value: "inventory_goods_receipt",
            selected: ["view", "create", "edit"],
            disabled_options: ["cancel", "approve", "accept", "modify"],
          },
          {
            name: "consumption",
            value: "inventory_consumption",
            selected: ["view", "create", "edit"],
            disabled_options: ["cancel", "approve", "accept", "modify"],
          },
          {
            name: "stock_transfer",
            value: "inventory_stock_transfer",
            selected: ["view", "create", "edit"],
            disabled_options: ["cancel", "approve", "accept", "modify"],
          },
          {
            name: "stock report",
            value: "inventory_stock_report",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "inventory report",
            value: "inventory_report",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
        ],
      },
      {
        title: selectedLanguage?.name == "English" ? "Target Control" : translation[569]?.value ? translation[569]?.value : "Target Control",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "staff target",
            value: "target_control_staff",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "store target",
            value: "target_control_store",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "service target",
            value: "target_control_service",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "retail target",
            value: "target_control_retail",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
        ],
      },
      {
        title: selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "root access",
            value: "reports_root_access",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "commission target report",
            value: "reports_commission",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "staff target reports",
            value: "reports_staff",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "store target reports",
            value: "reports_store",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "service target reports",
            value: "reports_service",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "retail target reports",
            value: "reports_retail",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
        ],
      },
      {
        title: selectedLanguage?.name == "English" ? "Online Profile" : translation[6]?.value ? translation[6]?.value : "Online Profile",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "root access",
            value: "profile_root_access",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "list profile",
            value: "profile_list",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
        ],
      },
      {
        title: selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "root access",
            value: "product_root_access",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
        ],
      },
      {
        title: selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "root access",
            value: "client_root_access",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "client profile",
            value: "client_profile",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "client groups",
            value: "client_groups",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "discount & promotions",
            value: "client_discount",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "reward/promotions",
            value: "client_reward",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "loyality points",
            value: "client_loyality",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "sharing settings",
            value: "client_sharing",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
        ],
      },
      {
        title: selectedLanguage?.name == "English" ? "Employee" : translation[4]?.value ? translation[4]?.value : "Employee",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "root access",
            value: "employee_root_access",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "new employee",
            value: "employee_new",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "payroll",
            value: "employee_payroll",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "attendance",
            value: "employee_attendance",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "staff groups",
            value: "employee_staff_group",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "employee reports",
            value: "employee_reports",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "commission scheme",
            value: "employee_commission",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "work schedule",
            value: "employee_work_schedule",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
          {
            name: "vacation",
            value: "employee_vacation",
            selected: ["view", "create", "edit"],
            disabled_options: ["approve", "cancel", "accept", "modify"],
          },
        ],
      },
      {
        title: selectedLanguage?.name == "English" ? "Sales" : translation[3]?.value ? translation[3]?.value : "Sales",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "root access",
            value: "sales_root_access",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "Add to Cart",
            value: "sales_apply_offer",
            selected: ["create", "edit"],
            disabled_options: ["view", "approve", "cancel", "accept", "modify"],
          },
        ],
      },
      {
        title: selectedLanguage?.name == "English" ? "Calendar/Appointment" : translation[570]?.value ? translation[570]?.value : "Calendar/Appointment",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "root access",
            value: "calender_root_access",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
          {
            name: "appointment",
            value: "calender_appointment",
            selected: ["view", "create", "edit", "cancel", "modify"],
            disabled_options: ["approve", "accept"],
          },
          {
            name: "add block time",
            value: "calender_block_time",
            selected: ["view", "create"],
            disabled_options: ["edit", "cancel", "approve", "accept", "modify"],
          },
        ],
      },
      {
        title: selectedLanguage?.name == "English" ? "System Access" : translation[1713]?.value ? translation[1713]?.value : "System Access",
        options: ALL_PERMISSION_OPTIONS,
        permissions: [
          {
            name: "root access",
            value: "system_root_access",
            selected: ["view"],
            disabled_options: ["create", "edit", "approve", "cancel", "accept", "modify"],
          },
        ],
      },
    ]
  )
}

// export const EMPLOYEE_ALL_PERMISSIONS = [
//     {
//         title : 'Appointment Booking',
//         options : ['create', 'modify', 'cancel', 'reschedule'],
//         permissions : [
//             {
//                 name : 'appointment',
//                 value : 'appointment',
//                 selected: ['create', 'cancel']
//             },
//             {
//                 name : 'email staff',
//                 value : 'email_staff',
//                 selected: ['modify', 'reschedule', 'cancel']
//             },
//         ]
//     },
//     {
//         title : 'Inventory Operations',
//         options : ['add', 'edit'],
//         permissions : [
//             {
//                 name : 'products',
//                 value : 'products',
//                 selected: []
//             },
//             {
//                 name : 'brand',
//                 value : 'brand',
//                 selected: []
//             },
//             {
//                 name : 'categories',
//                 value : 'categories',
//                 selected: []
//             },
//             {
//                 name : 'vendors',
//                 value : 'vendors',
//                 selected: []
//             },
//         ]
//     },
//     {
//         title : 'Stock Management',
//         options : ['order', 'turnover', 'email'],
//         permissions : [
//             {
//                 name : 'stock',
//                 value : 'stock',
//                 selected: []
//             }
//         ]
//     },
//     {
//         title : 'Inventory Reports',
//         options : ['view', 'filters'],
//         permissions : [
//             {
//                 name : 'stock report',
//                 value : 'stock_report',
//                 selected: []
//             }
//         ]
//     },
//     {
//         title : 'Staff Reports',
//         options : ['view', 'filters'],
//         permissions : [
//             {
//                 name : 'staff data',
//                 value : 'staff_data',
//                 selected: []
//             },
//             {
//                 name : 'Staff product sales',
//                 value : 'staff_product_sales',
//                 selected: []
//             },
//             {
//                 name : 'staff service sales',
//                 value : 'staff_service_sales',
//                 selected: []
//             },
//             {
//                 name : 'staff assets',
//                 value : 'staff_assets',
//                 selected: []
//             },
//         ]
//     },
//     {
//         title : 'Client Rewards',
//         options : ['view', 'add', 'edit', 'delete'],
//         permissions : [
//             {
//                 name : 'rewards',
//                 value : 'rewards',
//                 selected: []
//             },
//             {
//                 name : 'promotions',
//                 value : 'promotions',
//                 selected: []
//             },
//             {
//                 name : 'vouchers',
//                 value : 'vouchers',
//                 selected: []
//             },
//             {
//                 name : 'membership',
//                 value : 'membership',
//                 selected: []
//             },
//             {
//                 name : 'subscriptions',
//                 value : 'subscriptions',
//                 selected: []
//             },
//         ]
//     },
//     {
//         title : 'Sale Orders',
//         options : ['view', 'export', 'invoice', 'filter'],
//         permissions : [
//             {
//                 name : 'sale product',
//                 value : 'sale_product',
//                 selected: []
//             },
//             {
//                 name : 'sale services',
//                 value : 'sale_services',
//                 selected: []
//             },
//             {
//                 name : 'sale vouchers',
//                 value : 'sale_vouchers',
//                 selected: []
//             },
//             {
//                 name : 'sale membership',
//                 value : 'sale_membership',
//                 selected: []
//             },
//             {
//                 name : 'sale subscriptions',
//                 value : 'sale_subscriptions',
//                 selected: []
//             },
//         ]
//     },
//     {
//         title : 'Reports',
//         options : ['view', 'export', 'invoice', 'filter'],
//         permissions : [
//             {
//                 name : 'sale by products',
//                 value : 'sale_report_product',
//                 selected: []
//             },
//             {
//                 name : 'sale by services',
//                 value : 'sale_report_rewards',
//                 selected: []
//             },
//             {
//                 name : 'sales by membership',
//                 value : 'sale_report_membership',
//                 selected: []
//             },
//             {
//                 name : 'sales by subscriptions',
//                 value : 'sale_report_subscriptions',
//                 selected: []
//             },
//             {
//                 name : 'sales by vouchers',
//                 value : 'sale_report_vouchers',
//                 selected: []
//             },
//         ]
//     },
// ]
