import React from "react";
import Svgs from "Assets/svgs";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import { useNavigate } from "react-router-dom";
import useProfile from "../Profile/helper";
import moment from "moment";
import { WEEKDAYS } from "Constants/Data/Months";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { SALE_SERVICES } from "Redux/TenantRedux/ActionTypes/saleServiceType";
import GoogleDirection from "Components/Utility/GoogleDirection";

const ProfileInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    visitedLocation,
    serviceOfSelectedLocation,
    // selectedService,
    // setSelectedService,
    // selectedProfileService,
    token,
    // Selected,
    // setSelected,
    next,
    loc_services
  } = useProfile();

  const date = new Date();
  const currentDay = moment(date).format("dddd").toLocaleLowerCase();
  let remainingDays;

  if (visitedLocation?.opening_hours?.length > 0) {
    let ind = WEEKDAYS.indexOf(currentDay);
    remainingDays = WEEKDAYS?.map((item) =>
      visitedLocation?.opening_hours?.find(
        (itm) => itm?.day === item.toLocaleLowerCase()
      )
    );

  }


  return (
    <div className="grid md:grid-cols-6 gap-8">
      <div className="md:col-span-2">
        <img
          src={`${visitedLocation?.images
            ? visitedLocation?.images
            : `${process.env.PUBLIC_URL}/images/location_img.png`
            }`}
          className="h-[20rem] w-full rounded-xl object-contain border"
        />
      </div>
      <div className="md:col-span-4">
        <div className="flex flex-col gap-5">
          <div className="bg-[#ECF3FF] border border-[#BAD3FC] rounded-lg px-4 py-2 flex items-center justify-between gap-4">
            <div>
              <CircleIcon bg="#e5ecff" size="5rem">
                <Svgs.Todo size="1.5rem" />
              </CircleIcon>
            </div>
            <div className="flex flex-col gap-1 flex-1">
              <h1 className="font-semibold text-xl">
                {visitedLocation?.address}
              </h1>
              <p className="text-sm text-[#101928]/60">
                {visitedLocation?.description}
              </p>
            </div>
            <div>
              <LoginBtn
                disabled={visitedLocation?.banking != "Enable"}
                onClick={() => {
                  if (token) {
                    dispatch({
                      type: SALE_SERVICES.PROFILE_SELECTED_LOCATION,
                      payload: serviceOfSelectedLocation(),
                    });

                    dispatch({
                      type: SALE_SERVICES.PROFILE_SELECTED_SERIVCE,
                      payload: {
                        ...loc_services[0]?.priceservice[0],
                        employees: loc_services[0]?.employees,
                        // employees:
                        //   serviceOfSelectedLocation()[0]?.services[0]
                        //     ?.employees,
                        service_name: loc_services[0]?.name,
                        group_id: loc_services[0]?.group_id,
                        group: serviceOfSelectedLocation()?.find((itm) => itm?.id === loc_services[0]?.group_id),
                        location: visitedLocation,
                        business_address: visitedLocation?.business_address,
                        business: visitedLocation?.business,
                        hash: visitedLocation?.hash,
                        token: visitedLocation?.token,
                        next: visitedLocation?.next,
                      },
                    });
                    navigate("/profile-appointment");
                  } else {
                    navigate(`/auth/account-type/?next=${next}`);
                  }
                }}
                text={"Book Now"}
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="font-semibold text-xl">
              About {visitedLocation?.address_name}
            </h1>
            <div className="flex items-center gap-6">
              <div className="flex items-center gap-3">
                <Svgs.Thunder />
                <p className="text-[#101928]/60 text-sm">
                  Instant confirmation
                </p>
              </div>
              <div className="flex items-center gap-3">
                <Svgs.Gender />
                <p className="text-[#101928]/60 text-sm">For everyone</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="font-semibold text-xl">Location</h1>
            <p className="text-sm text-[#101928]/60">
              {visitedLocation?.address_name}
            </p>
            <GoogleDirection text={'See Directions'} address={visitedLocation?.address_name} />
          </div>
          <div className="bg-[#ECF3FF] border border-[#BAD3FC] rounded-lg px-3 py-3 flex items-center gap-2">
            <Svgs.Recent />
            <select
              className="text-[#101928]/60 text-sm outline-none bg-transparent"
            >
              {
                remainingDays?.map((itm, ind) => {
                  return (
                    <option
                      className="capitalize"
                      value={itm?.day}
                      selected={itm?.day === currentDay}
                    >
                      {itm?.day.charAt(0).toUpperCase() + itm?.day.slice(1)}{" "}
                      {itm?.start_time && itm?.close_time ? "open" : "Close"}{" "}
                      {ind === 0 ? "now" : ""}{" "}
                      {itm?.start_time
                        ? itm?.start_time.split(":").slice(0, 2).join(":")
                        : ""}{" "}
                      {itm?.start_time && itm?.close_time ? "-" : ""}{" "}
                      {itm?.close_time
                        ? itm?.close_time.split(":").slice(0, 2).join(":")
                        : ""}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ProfileInfo;
