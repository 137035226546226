import Svgs from 'Assets/svgs';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import React from 'react'

const WorkingScheduleShimmer = () => {
  return (
    <>
      <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3 animate-pulse">
        <div className="flex items-center justify-between flex-1 w-full gap-2 flex-wrap">
          <div className="flex items-center sm:justify-center justify-center gap-3 sm:w-2/5 w-full flex-wrap">
            <div className="flex items-center sm:gap-4 gap-2 w-full">
              <div className="bg-gray-200 h-[2.3rem] w-[2.3rem] rounded-lg flex items-center justify-center" >
                <Svgs.Chevron_next />
              </div>
              <div className="border border-[#A1A1A1] rounded-lg text-primary text-sm flex items-center w-3/5 " >
                <div className="border-r border-[#A1A1A1] p-1 w-1/5">
                  <div className="py-4 mx-auto w-full rounded-lg bg-gray-300"></div>
                </div>
                <div className="p-1 w-4/5">
                  <div className="py-4 mx-auto w-full rounded-lg bg-gray-300"></div>
                </div>
              </div>
              <div className="bg-gray-200 h-[2.3rem] w-[2.3rem] rounded-lg flex items-center justify-center">
                <Svgs.Chevron_prev />
              </div>
            </div>
          </div>
          <div className="mb-00 sm:w-2/5 w-4/5 justify-end flex items-center gap-3">
            <div className="py-4 mx-auto w-full rounded-lg bg-gray-300"></div>
            <div className="py-4 mx-auto w-full rounded-lg bg-gray-300"></div>

          </div>
        </div>
      </div>
      <div className="overflow-x-auto animate-pulse">
        <div className="inline-grid xl:grid grid-cols-[repeat(8,minmax(185px,1fr))] text-sm font-semibold ">
          <div className="schedule-grid-item">
            <div className="text-center w-full">
              <div className="py-3 mx-auto w-3/5 rounded-lg bg-gray-300"></div>
            </div>
          </div>
          {[0, 1, 2, 3, 4, 5, 6].map((day, index) => (
            <div className="schedule-grid-item" >
              <div className="py-3 mx-auto w-3/5 rounded-lg bg-gray-300"></div>
            </div>
          ))}
        </div>
        {[0, 1, 2, 3,4,5,6,7,8]?.map((obj, i) => {
          return (
            <div
              className="inline-grid xl:grid grid-cols-[repeat(8,minmax(185px,1fr))] text-sm font-semibold"
              key={i}
            >
              <div
                className="schedule-grid-item px-3"
                style={{ justifyContent: "start" }}
              >
                <div className="flex items-center gap-2 w-full">
                  <div className="h-[3rem] w-[3rem] rounded-full overflow-hidden mx-auto border bg-gray-200">

                  </div>
                  <div className="py-3 mx-auto w-3/5 rounded-lg bg-gray-300"></div>
                </div>
              </div>

              {[0, 1, 2, 3, 4, 5, 6].map((day, index) => {
                return (
                  <div
                    className={`time_block border-slate-100 block-${index} h-full bg-gray-200 w-full border-t border-r flex items-center justify-center`}
                    key={index}
                  >
                    <h1
                      className={`text-[#5C5C5C] hidden ${index % 3 == 0 &&
                        "translate-y-[1rem] !block text-[0.6rem] font-semibold"
                        }`}
                    >
                      <div className="py-4 w-full rounded-lg bg-gray-200"></div>
                    </h1>
                  </div>
                );
              })}
            </div>
          )
        })}
      </div >
    </>
  )
}

export default WorkingScheduleShimmer