import { CREATE_APPOINTMENT, GET_ALL_APPOINTMENTS, GET_ALL_APPOINTMENTS_NO_PAGINATION, GET_MY_APPOINTMENTS, GET_TODAY_APPOINTMENTS, RESET_APPOINTMENT_DATA, SET_APPOINTMENT_INPUT_DATA, GET_CLIENT_SALE, GET_BAR_CHART_DATA, GET_EMPLOYEE_DAILY_INSIGHT_DATA, GET_APPOINTMENT_DETAIL, GET_ALL_REVERSAL, GET_COMMENTS } from "../../ActionTypes/Appointment/Appointment"
import { ADD_BUSINESS_BRAND, DELETE_BUSINESS_BRAND, GET_BUSINESS_BRANDS, UPDATE_BUSINESS_BRAND } from "../../ActionTypes/BrandTypes"
const initialState = {
    appointment_inp_data: {},
    appointments: [],
    appointments_updated: false,
    all_appointments: [],
    all_appointments_no_pagination: [],
    get_todays_appointment: [],
    today_appointment: false,
    all_appointments_updated: false,
    all_appointments_no_pagination_updated: false,
    client_sale: [],
    selected_client: {},
    selected_client_update: false,
    appointment_future_errors: [],
    appointment_hours_errors: [],
    appointment_client: {},
    count: 0,
    allCount: 0,
    barChart_data: [],
    barChart_data_updated: false,
    employee_insight_data: [],
    employee_insight_data_updated: false,
    appointmentDetail: [],
    appointment_detail_updated: false,
    all_reversal_updated: false,
    reversalCount: 0,
    reversalAllCount: 0,
    all_reversal: [],
    comments: [],
}

     
const AppointmentReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_ALL_REVERSAL:
            return {
                ...state,
                all_reversal: action?.payload?.data,
                reversalCount: action?.payload?.count,
                reversalAllCount: action?.payload?.allCount,
                all_reversal_updated: true
            }

        case GET_ALL_APPOINTMENTS:
            return {
                ...state,
                all_appointments: action?.payload?.data,
                count: action?.payload?.count,
                allCount: action?.payload?.allCount,
                all_appointments_updated: true
            }

        case GET_ALL_APPOINTMENTS_NO_PAGINATION:
            return {
                ...state,
                all_appointments_no_pagination: action?.payload?.data,
                all_appointments_no_pagination_updated: true
            }

        case CREATE_APPOINTMENT:
            return {
                ...state,
                appointments: action.payload,
                appointments_updated: true
            }
        case GET_MY_APPOINTMENTS:
            return {
                ...state,
                appointments: action.payload,
                appointments_updated: true
            }

        case GET_APPOINTMENT_DETAIL:
            return {
                ...state,
                appointmentDetail: action.payload,
                appointment_detail_updated: true
            }

        case GET_BAR_CHART_DATA:
            return {
                ...state,
                barChart_data: action.payload,
                barChart_data_updated: true
            }

        case GET_EMPLOYEE_DAILY_INSIGHT_DATA:
            return {
                ...state,
                employee_insight_data: action.payload,
                employee_insight_data_updated: true
            }

        case RESET_APPOINTMENT_DATA:
            return {
                ...state,
                appointment_inp_data: {}
            }
        case SET_APPOINTMENT_INPUT_DATA:
            return {
                ...state,
                appointment_inp_data: action.payload
            }
        case GET_TODAY_APPOINTMENTS:
            return {
                ...state,
                get_todays_appointment: action.payload,
                today_appointment: true
            }

        case GET_CLIENT_SALE:
            return {
                ...state,
                client_sale: action.payload,
            }

        case "SELECTED_APPOINTMENT_CLIENT":
            return {
                ...state,
                selected_client: action.payload,
                selected_client_update: !state.selected_client_update
            }

            case "SELECTED_APPOINTMENT_CLIENT_GROUP":
                return {
                    ...state,
                    selected_client_group: action.payload
                }


        case "REST_SELECTED_APPOINTMENT_CLIENT":
            return {
                ...state,
                selected_client: [],
                selected_client_update: !state.selected_client_update
            }

        case "ADD_HOURS_ERRORS":
            let updateList;
            if (state?.appointment_hours_errors.length !== 0) {
                let exist = state?.appointment_hours_errors?.find((itm) => itm?.id === action?.payload?.id)
                if (exist) {
                    updateList = state?.appointment_hours_errors?.map((item) => {
                        if (item.id == action?.payload.id) {
                            return action?.payload
                        } else {
                            return item;
                        }
                    })
                } else {
                    updateList = [...state?.appointment_hours_errors, action?.payload]
                }
            } else {
                updateList = [action?.payload]
            }

            return {
                ...state,
                appointment_hours_errors: updateList,
            }


        case "REMOVE_HOURS_ERRORS":

            return {
                ...state,
                appointment_hours_errors: state?.appointment_hours_errors?.filter((err) => err?.id != action?.payload?.id),
            }
        case "ADD_FUTURE_ERRORS":
            let future_List;
            if (state?.appointment_future_errors.length !== 0) {
                let exist = state?.appointment_future_errors?.find((itm) => itm?.id === action?.payload?.id)
                if (exist) {
                    future_List = state?.appointment_future_errors?.map((item) => {
                        if (item.id == action?.payload.id) {
                            return action?.payload
                        } else {
                            return item;
                        }
                    })
                } else {
                    future_List = [...state?.appointment_future_errors, action?.payload]
                }
            } else {
                future_List = [action?.payload]
            }

            return {
                ...state,
                appointment_future_errors: future_List,
            }

        case "REMOVE_FUTURE_ERRORS":
            return {
                ...state,
                appointment_future_errors: state?.appointment_future_errors?.filter((err) => err?.id != action?.payload?.id),
            }

        case "CREATED_CLIENT_FROM_APPOINTMENT":
            return {
                ...state,
                appointment_client: action?.payload,
            }
        case GET_COMMENTS:
            return {
                ...state,
                comments: action?.payload,
            }

        default:
            return state
    }
}
export default AppointmentReducer