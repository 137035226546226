import React, { useState, useEffect } from "react";
import moment from 'moment'
import LoginBtn from "../../../Elements/LoginBtn";
import ProgressBar from "../../../Elements/ProgressBar";
import useMarketingAnalytics from "./helper";
// import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import Popup from "Components/TenantApp/Elements/Popup";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import DateRangePicker from "Components/TenantApp/Elements/DateRangePicker";
import Svgs from "Assets/svgs";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import SalesAnalyticsCards from "Components/TenantApp/Elements/SalesAnalyticsCards";
import AccordionDropdown from "Components/TenantApp/Elements/AccordionDropdown";
import RadioBtn from "Components/TenantApp/Elements/RadioBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import LineChatCurve from "Components/TenantApp/Elements/Charts/LineChatCurve";
import LineChart from "Components/TenantApp/Elements/Charts/LineChart";
import Donet from "Components/TenantApp/Elements/Charts/Donet";
import BarChart from "Components/TenantApp/Elements/Charts/BarChart";
import ColorLineChart from "Components/TenantApp/Elements/Charts/ColorLineChart";
import MultiDataDonet from "Components/TenantApp/Elements/Charts/MultiDataDonet";
import SingleBar from "Components/TenantApp/Elements/Charts/SingleBar";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";

const MarketingAnalytics = () => {

    const { translation, selectedLanguage, navigate, FilterDetails, setFilterDetails, 
        toggleDateRange, setToggleDateRange, start_date, setStartDate, end_date, setEndDate ,
        reduxlocationsList, selected_location, setSelected_location
    } = useMarketingAnalytics();
    const [Filter, setFilter] = useState(false);

    const products = [
        {
            name: "Silk & Shine",
            value: 1508,
        },
        {
            name: "Cosmic Care",
            value: 888,
        },
        {
            name: "Shinewave",
            value: 854,
        },
        {
            name: "LightDew",
            value: 790,
        },
        {
            name: "Nail Care",
            value: 641,
        },
    ]

    const SearchedResult = [
        {
            date: "16-5-2023",
            channel: "POS",
            product: "LightDew",
            quantity: 17,
            promotion: "Voucher",
            tax_type: "GST",
        },
        {
            date: "16-5-2023",
            channel: "POS",
            product: "LightDew",
            quantity: 17,
            promotion: "Voucher",
            tax_type: "GST",
        },
        {
            date: "16-5-2023",
            channel: "POS",
            product: "LightDew",
            quantity: 17,
            promotion: "Voucher",
            tax_type: "GST",
        },
        {
            date: "16-5-2023",
            channel: "POS",
            product: "LightDew",
            quantity: 17,
            promotion: "Voucher",
            tax_type: "GST",
        },
        {
            date: "16-5-2023",
            channel: "POS",
            product: "LightDew",
            quantity: 17,
            promotion: "Voucher",
            tax_type: "GST",
        },
        {
            date: "16-5-2023",
            channel: "POS",
            product: "LightDew",
            quantity: 17,
            promotion: "Voucher",
            tax_type: "GST",
        },
    ];

    return (
        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "POS Analytics" : translation[7]?.value ? translation[7]?.value : "POS Analytics"}
                second={selectedLanguage?.name == "English" ? "Marketing Analytics" : translation[1626]?.value ? translation[1626]?.value : "Marketing Analytics"}
                url={'/dashboard/pos-analytics'}
            />
            <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
                <div className="flex items-center sm:gap-4 gap-2">
                    <span onClick={() => navigate(-1)}>
                        <Svgs.Back />
                    </span>
                    <h2 className="text-primary font-bold lg:text-3xl text-xl">
                        {selectedLanguage?.name == "English" ? "Marketing Analytics" : translation[1626]?.value ? translation[1626]?.value : "Marketing Analytics"}
                    </h2>

                    <Dropwdown
                        className={'w-[10rem] lg:w-[14rem]'}
                        showTitle={false}
                        name={"selected_location"}
                        value={selected_location}
                        onChange={(e) => { setSelected_location(e.target.value) }}
                        options={[
                            { label: selectedLanguage?.name == "English" ? "All Locations" : translation[687]?.value ? translation[687]?.value : "All Locations", value: "" },
                            ...reduxlocationsList?.map((adrs) => {
                                return {
                                    label: adrs?.address_name,
                                    value: adrs?.id,
                                };
                            })]}
                        title={''}
                        placeholder={selectedLanguage?.name == "English" ? "All Locations" : translation[687]?.value ? translation[687]?.value : "All Locations"}
                    />
                </div>
                <div className="flex items-center sm:gap-4 gap-2">
                    <Dropwdown
                        className={'w-[7rem]'}
                        name="year"
                        title=""
                        showTitle={false}
                        placeholder={selectedLanguage?.name == "English" ? "Yearly" : translation[1587]?.value ? translation[1587]?.value : "Yearly"}
                        // value={filterStore}
                        options={[
                            { value: 'today', label: 'Today' },
                            { value: 'monthly', label: 'Monthly' },
                            { value: 'yearly', label: 'Yearly' },
                        ]}
                    // onChange={handleChange}
                    />
                    <Dropwdown
                        className={'w-[7rem]'}
                        name="year"
                        title=""
                        showTitle={false}
                        placeholder={"2024"}
                        // value={filterStore}
                        options={[
                            { value: '2022', label: '2022' },
                            { value: '2023', label: '2023' },
                            { value: '2024', label: '2024' },
                        ]}
                    // onChange={handleChange}
                    />

                    <LoginBtn
                        className="rounded-lg border-2 border-primary"
                        bg="bg-white"
                        animation={false}
                        onClick={() => setFilter(!Filter)}
                    >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                            <Svgs.DateFilter fill={"#fff"} />
                        </div>
                    </LoginBtn>
                </div>
            </div>

            <div className="border-t py-5 md:py-10 flex flex-col gap-5 md:gap-10">
                <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-5 md:gap-10">
                    <SalesAnalyticsCards
                        value={"851"}
                        subvalueType={"positive"}
                        subvalue={"+11.01"}
                        text={selectedLanguage?.name == "English" ? "New Leads" : translation[1385]?.value ? translation[1385]?.value : "New Leads"}
                        graph={<Svgs.NewGraphUp />}
                    />
                    <SalesAnalyticsCards
                        value={"851"}
                        subvalueType={"positive"}
                        subvalue={"+15.01"}
                        text={selectedLanguage?.name == "English" ? "Average Conversion Rate" : translation[1627]?.value ? translation[1627]?.value : "Average Conversion Rate"}
                        graph={<Svgs.NewGraphUp />}
                    />
                    <SalesAnalyticsCards
                        value={"851"}
                        subvalueType={"negative"}
                        subvalue={"-0.03"}
                        text={selectedLanguage?.name == "English" ? "Email Marketing" : translation[524]?.value ? translation[524]?.value : "Email Marketing"}
                        graph={<Svgs.NewGraphDown />}
                    />
                    <SalesAnalyticsCards
                        value={"851"}
                        subvalueType={"positive"}
                        subvalue={"+6.01"}
                        text={selectedLanguage?.name == "English" ? "Total Calls" : translation[1628]?.value ? translation[1628]?.value : "Total Calls"}
                        graph={<Svgs.NewGraphUp />}
                    />
                </div>
                <div className="grid sm:grid-cols-2 xl:grid-cols-8 gap-5 md:gap-10">

                    <div className="flex flex-col gap-5 xl:gap-10 justify-between h-auto xl:col-span-5 py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex justify-between items-center flex-wrap gap-2">
                            <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Total Conversions Rate" : translation[1629]?.value ? translation[1629]?.value : "Total Conversions Rate"}</h4>
                            <div className="flex items-center gap-2 2xl:gap-4">
                                <div className="flex items-center gap-1">
                                    <div className="h-2 w-2 rounded-full bg-[#0063F7]"></div>
                                    <p className="text-sm text-[#1C1C1C]">{selectedLanguage?.name == "English" ? "Emails Conversions" : translation[1630]?.value ? translation[1630]?.value : "Emails Conversions"}</p>
                                </div>
                                <div className="flex items-center gap-1">
                                    <div className="h-2 w-2 rounded-full bg-[#017A31]"></div>
                                    <p className="text-sm text-[#1C1C1C]">{selectedLanguage?.name == "English" ? "Calls Conversions" : translation[1631]?.value ? translation[1631]?.value : "Calls Conversions"}</p>
                                </div>
                            </div>
                        </div>
                        <div className="h-auto w-full overflow-hidden">
                            <ColorLineChart />
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-between gap-5 xl:gap-10 h-auto xl:col-span-3 py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">

                        <div className="flex items-center w-[150px] h-[150px] xl:h-[200px] xl:w-[200px] overflow-hidden">
                            <MultiDataDonet />
                        </div>

                        <div className="w-full md:w-auto grid grid-cols-2 gap-16 sm:gap-5 2xl:gap-10 text-sm 2xl:text-base">

                            <div className="flex flex-col gap-5">
                                <div className="flex items-center justify-between gap-5 whitespace-nowrap text-[#1C1C1C]">
                                    <div className="flex gap-2 items-center"><div className="h-2 w-2 rounded-full bg-[#2DA75D]"></div><p className="text-[#949494]">Membership</p></div>
                                    <div>38.6 %</div>
                                </div>
                                <div className="flex items-center justify-between gap-5 whitespace-nowrap text-[#1C1C1C]">
                                    <div className="flex gap-2 items-center"><div className="h-2 w-2 rounded-full bg-[#74CEDA]"></div><p className="text-[#949494]">Loyalty Points</p></div>
                                    <div>22.6 %</div>
                                </div>
                                <div className="flex items-center justify-between gap-5 whitespace-nowrap text-[#1C1C1C]">
                                    <div className="flex gap-2 items-center"><div className="h-2 w-2 rounded-full bg-[#AEBFFF]"></div><p className="text-[#949494]">Gift Card</p></div>
                                    <div>30.0 %</div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-5">
                                <div className="flex items-center justify-between gap-5 whitespace-nowrap text-[#1C1C1C]">
                                    <div className="flex gap-2 items-center"><div className="h-2 w-2 rounded-full bg-[#5679FF]"></div><p className="text-[#949494]">Coupon</p></div>
                                    <div>38.6 %</div>
                                </div>
                                <div className="flex items-center justify-between gap-5 whitespace-nowrap text-[#1C1C1C]">
                                    <div className="flex gap-2 items-center"><div className="h-2 w-2 rounded-full bg-[#1C1C1C]"></div><p className="text-[#949494]">Voucher</p></div>
                                    <div>22.6 %</div>
                                </div>
                                <div className="flex items-center justify-between gap-5 whitespace-nowrap text-[#1C1C1C]">
                                    <div className="flex gap-2 items-center"><div className="h-2 w-2 rounded-full bg-[#C5C5C5]"></div><p className="text-[#949494]">Other</p></div>
                                    <div>30.0 %</div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <div className="grid sm:grid-cols-2 gap-5 md:gap-10">
                    <div className="flex flex-col gap-10 h-auto 3xl:h-[25rem] py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-1">
                                <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Marketing Expense" : translation[1632]?.value ? translation[1632]?.value : "Marketing Expense"}</h4>
                                <h5 className="text-[#484848] font-bold text-2xl">8442.55</h5>
                            </div>
                            {/* <div className="flex items-center gap-1 text-[#00C287]">
                            <div className="border border-[#00C287] rounded-full p-1"><Svgs.UpArrow size={"10"} fill={"#00C287"} /></div>
                            <p className="text-sm font-semibold">6.2%</p>
                        </div> */}
                        </div>
                        <div className="h-auto w-full overflow-hidden">
                            <SingleBar />
                        </div>
                    </div>
                    <div className="min-h-[20rem] py-5 px-6 border border-[#5679FF1A] shadow-[0px_3px_5px_0px_#5679FF26] rounded-[10px] !pt-5 flex flex-col justify-between gap-2">
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col gap-1">
                                <h4 className="font-semibold text-xl text-[#101928]">{selectedLanguage?.name == "English" ? "Marketing Profit" : translation[1634]?.value ? translation[1634]?.value : "Marketing Profit"}</h4>
                                <h5 className="text-[#484848] font-bold text-2xl">8442.55</h5>
                            </div>
                            {/* <div className="flex items-center gap-1 text-[#BC2323]">
                            <div className="border border-[#BC2323] rounded-full p-1 rotate-180"><Svgs.UpArrow size={"10"} fill={"#BC2323"} /></div>
                            <p className="text-sm font-semibold">6.2%</p>
                        </div> */}
                        </div>
                        <div className="flex items-center justify-center w-full">
                            <div className="h-auto w-full overflow-hidden">
                                <SingleBar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="border rounded-lg">
                <div className="px-6 lg:px-6 py-2 flex items-center justify-between border-b gap-5">

                    <div className="flex items-center sm:gap-4 gap-2 ">
                        <h2 className="font-semibold text-xl">{selectedLanguage?.name == "English" ? "Search Result" : translation[1143]?.value ? translation[1143]?.value : "Search Result"}</h2>
                    </div>

                    <div className="flex items-center gap-5">
                        <div className='relative flex items-center gap-3 w-full z-[1000]'>
                            <div className={`border text-sm rounded-md font-semibold flex items-center w-full`}>
                                <div className='p-2 relative gap-4 flex items-center whitespace-nowrap w-full'>
                                    <p
                                        onClick={() => {
                                            setIndexValue(1)
                                            setErrors((prev) => ({ ...prev, 'date_range': "" }))
                                            setToggleDateRange(!toggleDateRange)
                                        }}
                                        className='cursor-pointer xl:text-xs w-full'>
                                        {start_date && end_date ?
                                            `${start_date ? moment(start_date).format("DD-MM-YYYY") : "--/--/----"} - ${end_date ? moment(end_date).format("DD-MM-YYYY") : "--/--/----"}` :
                                            '--/--/---- - --/--/----'}
                                    </p>
                                    <span className='cursor-pointer ' onClick={() => {
                                        setStartDate()
                                        setEndDate()
                                    }}>
                                        {selectedLanguage?.name == "English" ? "Clear" : translation[340]?.value ? translation[340]?.value : "Clear"}
                                    </span>

                                    {(toggleDateRange) && <>
                                        <div className='absolute inset-0 top-0 left-0 w-[95%] mb-4' onClick={() => { setToggleDateRange(false) }}></div>
                                        <div className={`absolute top-0 right-0 mb-4`}>
                                            <DateRangePicker
                                                start={start_date}
                                                end={end_date}
                                                setStartDate={setStartDate}
                                                setEndDate={setEndDate}
                                                setToggleDateRange={setToggleDateRange}
                                            addCustomDataOnSelection={() => {
                                                let date_name = `is_date_${ele.name}`
                                                setFilterQueries({
                                                    [date_name]: true,
                                                })
                                            }}
                                            />
                                        </div>
                                    </>
                                    }
                                </div>
                                {errors?.date_range &&
                            <p className={`!text-[#eb3b3b] flex items-center gap-1 !text-xs !font-normal absolute top-[100%] w-full justify-end normal-case pt-1`}>
                            <Svgs.I fill="#eb3b3b" />
                            {errors?.date_range}
                            </p>
                        }
                            </div>
                        </div>
                        <LoginBtn>
                            <Svgs.Import fill="#fff" width="1.5rem" height="1.5rem" />
                        </LoginBtn>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] px-12 py-3 bg-[#F3F6FF] text-sm font-semibold min-w-full w-fit">
                        <p>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</p>
                        <p>{selectedLanguage?.name == "English" ? "Email Campaign" : translation[201]?.value ? translation[201]?.value : "Email Campaign"} </p>
                        <p>{selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}</p>
                        <p>{selectedLanguage?.name == "English" ? "Feedback" : translation[1636]?.value ? translation[1636]?.value : "Feedback"}</p>
                        <p>{selectedLanguage?.name == "English" ? "Promotion" : translation[1583]?.value ? translation[1583]?.value : "Promotion"}</p>
                        <p>{selectedLanguage?.name == "English" ? "Cash Usage" : translation[1637]?.value ? translation[1637]?.value : "Cash Usage"}</p>
                    </div>
                    <div className={` text-[#3A3A3A] transition-all border-l-[0px]`} >
                    
                        {SearchedResult?.map((v, i) => {

                            return (
                                <div key={i} className="even:bg-[#FBFCFF] px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] min-w-full w-fit">
                                    <div>
                                        {v.date}
                                    </div>
                                    <div>
                                        {v.channel}
                                    </div>

                                    <div>
                                        {v.product}
                                    </div>
                                    <div>
                                        {v.quantity}
                                    </div>

                                    <div>
                                        {v.promotion}
                                    </div>
                                    <div>
                                        {v.tax_type}
                                    </div>

                                </div>
                            );
                        })}
                    </div>
                </div>
            </div> */}

            <Popup
                size={"lg"}
                customPadding={"!p-0"}

                heading={selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}
                open={Filter}
                close={setFilter}
            >
                <div>
                    <div className="grid lg:grid-cols-2 transition-all duration-300 max-h-[35rem] xl:max-h-[27rem] xl:min-h-[27rem] overflow-y-auto">
                        <div className="">
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Promotions" : translation[1583]?.value ? translation[1583]?.value : "Promotions"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, promotions: !FilterDetails?.promotions })}
                                    checked={FilterDetails?.promotions}
                                />
                                {FilterDetails?.promotions &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6 bg-white">
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"}
                                            name="new_customers"
                                            id={'new_customers'}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership"}
                                            name="returning_customers"
                                            id={'returning_customers'}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Coupon" : translation[1566]?.value ? translation[1566]?.value : "Coupon"}
                                            name="returning_customers"
                                            id={'returning_customers'}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Loyalty Points" : translation[447]?.value ? translation[447]?.value : "Loyalty Points"}
                                            name="returning_customers"
                                            id={'returning_customers'}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Email Campaign" : translation[201]?.value ? translation[201]?.value : "Email Campaign"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, email_campaign: !FilterDetails?.email_campaign })}
                                    checked={FilterDetails?.email_campaign}
                                />
                                {FilterDetails?.email_campaign &&
                                    <div className="transition-all py-6 px-6">
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Fifty Percent Off" : translation[1643]?.value ? translation[1643]?.value : "Fifty Percent Off"}
                                            name="returning_customers"
                                            id={'returning_customers'}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Call Outcome" : translation[1638]?.value ? translation[1638]?.value : "Call Outcome"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, call_outcome: !FilterDetails?.call_outcome })}
                                    checked={FilterDetails?.call_outcome}
                                />
                                {FilterDetails?.call_outcome &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6">

                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Conversion" : translation[1623]?.value ? translation[1623]?.value : "Conversion"}
                                            name={"conversion"}
                                            id={"conversion"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Loss" : translation[1624]?.value ? translation[1624]?.value : "Loss"}
                                            name={"loss"}
                                            id={"loss"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Services" : translation[31]?.value ? translation[31]?.value : "Services"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, services: !FilterDetails?.services })}
                                    checked={FilterDetails?.services}
                                />
                                {FilterDetails?.services &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6">
                                        <div className="flex flex-col gap-2">
                                            <p className="text-[#000] text-base font-medium">Search</p>
                                            <LoginInput
                                                title=''
                                                placeholder={"Search services here..."}
                                                padding='py-1'
                                                leftIcon={<Svgs.Search />}
                                            // value={search}
                                            // onChange={searchfunction}
                                            />
                                        </div>
                                        <hr className="border-[rgba(86,121,255,.2)]" />
                                        {
                                            products?.map((product) => {
                                                return (
                                                    <div className="flex items-center">
                                                        <RadioBtn
                                                            text={product?.name}
                                                            name={product?.name}
                                                            id={product?.name}
                                                        // checked={audience === 'public'}
                                                        // value={audience}
                                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Client Initiated Discounts" : translation[1583]?.value ? translation[1583]?.value : "Client Initiated Discounts"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, customer_initiated_discounts: !FilterDetails?.customer_initiated_discounts })}
                                    checked={FilterDetails?.customer_initiated_discounts}
                                />
                                {FilterDetails?.customer_initiated_discounts &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6">
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Service" : translation[361]?.value ? translation[361]?.value : "Service"}
                                            name={"customer_footfall_comparison"}
                                            id={"service"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Product" : translation[1316]?.value ? translation[1316]?.value : "Product"}
                                            name={"customer_footfall_comparison"}
                                            id={"product"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                    </div>
                                }
                            </div>
                            <div>
                                <AccordionDropdown
                                    mainClass={"px-6"}
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Smart Cash Usage" : translation[1583]?.value ? translation[1583]?.value : "Smart Cash Usage"}
                                    onClick={() => setFilterDetails({ ...FilterDetails, smart_cash_usage: !FilterDetails?.smart_cash_usage })}
                                    checked={FilterDetails?.smart_cash_usage}
                                />
                                {FilterDetails?.smart_cash_usage &&
                                    <div className="transition-all flex flex-col gap-5 py-6 px-6">
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Service" : translation[361]?.value ? translation[361]?.value : "Service"}
                                            name={"customer_footfall_comparison"}
                                            id={"service"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                        <RadioBtn
                                            text={selectedLanguage?.name == "English" ? "Product" : translation[1316]?.value ? translation[1316]?.value : "Product"}
                                            name={"customer_footfall_comparison"}
                                            id={"product"}
                                        // checked={audience === 'public'}
                                        // value={audience}
                                        // onClick={() => { handleChangeForRadio('audience', 'public') }}
                                        />
                                    </div>
                                }
                            </div>

                        </div>
                        <div className="lg:border-l lg:border-[#E5E7EB]">
                            <div className="px-6 flex flex-col gap-3 pt-5 lg:pt-3">
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Promotions" : translation[1583]?.value ? translation[1583]?.value : "Promotions"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">Coupon</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Email Campaign" : translation[201]?.value ? translation[201]?.value : "Email Campaign"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">100UED to 6000UED </h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Call Outcome" : translation[1638]?.value ? translation[1638]?.value : "Call Outcome"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">Loss</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Services" : translation[31]?.value ? translation[31]?.value : "Services"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">Hair Cutting</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Smart Cash Usage" : translation[1583]?.value ? translation[1583]?.value : "Smart Cash Usage"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">Service</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="font-semibold text-lg">{selectedLanguage?.name == "English" ? "Client Initiated Discounts" : translation[1583]?.value ? translation[1583]?.value : "Client Initiated Discounts"}</h3>
                                    <div className="py-2 border-b border-[rgba(86,121,255,.2)]">
                                        <div className="border border-[#5679FF] bg-[#F8FBFB] border-solid rounded-[10px] p-2 flex items-center gap-2 w-fit">
                                            <h4 className="text-[#5679FF] text-sm font-medium">Service</h4>
                                            <div className="flex items-center justify-center rounded-full p-1 ml-2 bg-[#101928] cursor-pointer">
                                                <Svgs.Cross className='w-2 h-2' fill='#fff' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end items-end gap-2 py-4 px-6">
                        <LoginBtn className="!px-6" borderBtn text={selectedLanguage?.name == "English" ? "RESET" : translation[1141]?.value ? translation[1141]?.value : "RESET"} />
                        <LoginBtn text={selectedLanguage?.name == "English" ? "APPLY FILTER" : translation[595]?.value ? translation[595]?.value : "APPLY FILTER"} />
                    </div>
                </div>

            </Popup>
        </div>
    )
}

export default MarketingAnalytics