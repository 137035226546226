import Svgs from 'Assets/svgs';
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Popup from 'Components/TenantApp/Elements/Popup';
import RadioBtn from 'Components/TenantApp/Elements/RadioBtn';
import Textarea from 'new-components/text-area';
import React from 'react'
import useConversionPopup from './helper';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import { useSelector } from "react-redux";

const AddConversion = ({ CreateConversion, setCreateConversion }) => {
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const {
    moment,
    indexValue, setIndexValue,
    startDate, setStartDate,
    endDate, setEndDate,
    toggleDateRange, setToggleDateRange
  } = useConversionPopup()
  return (

    <Popup
      size={"md"}
      heading={selectedLanguage?.name == "English" ? "Create Conversion" : translation[1381]?.value ? translation[1381]?.value : "Create Conversion"}
      open={CreateConversion}
      close={setCreateConversion}
    >
      <div className="flex flex-col gap-5 pr-4">
        <div className="flex items-center gap-3">
          <div>
            <CircleIcon children={<Svgs.NewService />} />
          </div>
          <div className="flex flex-col gap-1">
            <div>
              <h1 className="font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Create Conversion" : translation[1381]?.value ? translation[1381]?.value : "Create Conversion"}
              </h1>
            </div>
            <div>
              <p className="text-sm text-[#7B7B7B]">
                {selectedLanguage?.name == "English" ? "Fill in the following information to create campaign" : translation[1387]?.value ? translation[1387]?.value : "Fill in the following information to create campaign"}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col gap-3">
            <LoginInput
              title={"Title"}
              placeholder={"Enter Campaign Name"}
            />
            <Textarea title="Email/SMS Content *" rows={5} placeholder="Enter Description" />
            <Dropwdown
              title={selectedLanguage?.name == "English" ? "Segment Type" : translation[1409]?.value ? translation[1409]?.value : "Segment Type"}
              placeholder={selectedLanguage?.name == "English" ? "Select Segment Type" : translation[1410]?.value ? translation[1410]?.value : "Select Segment Type"}
              options={[{
                label: `${selectedLanguage?.name == "English" ? "Dynamic" : translation[1411]?.value ? translation[1411]?.value : "Dynamic"}`,
                value: "dynamic",
              },
              {
                label: `${selectedLanguage?.name == "English" ? "Static" : translation[1412]?.value ? translation[1412]?.value : "Static"}`,
                value: "static",
              }]}
            />
            <div className="space-y-2 w-full">
              <p className="text-sm">Compaign Type *</p>
              <div className='relative flex items-center gap-3 w-full pb-2'>
                <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center w-full'>
                  <div className='p-2 relative gap-4 flex items-center whitespace-nowrap w-full'>
                    <p onClick={() => {
                      // setStartDate()
                      // setEndDate()
                      setIndexValue(1)
                      setToggleDateRange(!toggleDateRange)
                    }} className='cursor-pointer xl:text-xs w-full'>
                      {indexValue == 1 ?
                        `${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/----"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/----"}` :
                        '--/--/---- - --/--/----'}
                    </p>
                    <span className='cursor-pointer ' onClick={() => {
                      setStartDate()
                      setEndDate()
                      // setFilterQueries({
                      //     is_date_top_sold: false,
                      //     top_sold: "TOP_SOLD_PRODUCTS"
                      // })
                    }}>Clear</span>

                    {
                      (toggleDateRange && indexValue == 1) && <>
                        <div className='absolute inset-0 left-0 w-[95%] mb-2' onClick={() => { setToggleDateRange(false) }}></div>
                        <div className={`absolute top-full right-0 mb-2`}>
                          <DateRangePicker
                            start={startDate}
                            end={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            setToggleDateRange={setToggleDateRange}
                          // addCustomDataOnSelection={() => {
                          //     let date_name = `is_date_${ele.name}`
                          //     setFilterQueries({
                          //         [date_name]: true,
                          //     })
                          // }}
                          />
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>

            </div>
            <div className="space-y-2">
              <p className="text-sm">Compaign Type *</p>
              <div className="flex gap-16">
                <RadioBtn text={"Email"} />
                <RadioBtn text={"SMS"} />
                <RadioBtn text={"Both"} />
              </div>
            </div>
            <div className='flex justify-end'>
              <LoginBtn
                className={"!px-6"}
                text={selectedLanguage?.name == "English" ? "ADD" : translation[97]?.value ? translation[97]?.value : "ADD"}
              />
            </div>
          </div>
        </div>



      </div>
    </Popup>
  )
}

export default AddConversion