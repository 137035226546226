import { combineReducers } from "redux";

import ThemeReducer from "../Utility/Theme/ThemeReducer";
import UtilityRootReducer from "../Utility/UtilityRootReducer";
import IncrementReducer from "./Reducers/countReducer/IncrementReducer";
import ForgetPasswordReducer from "./Reducers/ForgetPasswordReducer";
import SignupReducer from "./Reducers/SignupReducer";
import ClientAppointmentReducer from "./Reducers/ClientAppointmentReducer";
import HelpReducer from "./Reducers/HelpReducer";
import FinancialSettingsReducer from "Redux/TenantRedux/Reducers/financialSettings";

const NstyleRootReducer = combineReducers({
  counter: IncrementReducer,
  theme: ThemeReducer,
  userData: SignupReducer,
  passwordData: ForgetPasswordReducer,
  utility: UtilityRootReducer,
  client_appointments: ClientAppointmentReducer,
  financialSettings: FinancialSettingsReducer,
  helpData: HelpReducer
});

export default NstyleRootReducer;
