import React from 'react'
import { InventoryReport } from '../helper';

function TransferTo({ ShowDetails, setShowDetails, report_obj, i, selectedLanguage, translation }) {
  return (
    <div className="scale-in-center">
      <div className="bg-[#FAF9FF] px-6 py-3">
        <div className="inline-grid grid-cols-[repeat(8,minmax(140px,2fr))] gap-5 border-b">
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              {selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"}
            </p>
            <p className="text-black font-semibold">
              {report_obj?.report_choice === InventoryReport.transferTo && InventoryReport.transferToHeading}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              {selectedLanguage?.name == "English" ? "Stock before" : translation[1128]?.value ? translation[1128]?.value : "Stock before"}
            </p>
            <p className="text-black font-semibold">
              {report_obj?.before_quantity}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              {selectedLanguage?.name == "English" ? "From Location" : translation[1015]?.value ? translation[1015]?.value : "From Location"}
            </p>
            <p className="text-black font-semibold">
              {report_obj?.to_location?.address_name}
            </p>
          </div>
          {/* <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              Reorder Amount
            </p>
            <p className="text-black font-semibold">
              {report_obj?.reorder_quantity}
            </p>
          </div> */}
          {/* <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              Received Amount
            </p>
            <p className="text-black font-semibold">
              {report_obj?.quantity}
            </p>
          </div> */}
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              {selectedLanguage?.name == "English" ? "Updated Stock" : translation[1125]?.value ? translation[1125]?.value : "Updated Stock"}
            </p>
            <p className="text-black font-semibold">
              {report_obj?.after_quantity}
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              {selectedLanguage?.name == "English" ? "Date" : translation[450]?.value ? translation[450]?.value : "Date"}
            </p>
            <p className="text-black font-semibold">
              {report_obj?.created_at}
            </p>
          </div>



          {/* 
          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              Product
            </p>
            <p className="text-black font-semibold">
              {report_obj?.product?.name}
            </p>
          </div> */}



          {/*           

          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              To Location
            </p>
            <p className="text-black font-semibold">
              {report_obj?.to_location?.address_name}
            </p>
          </div> */}


          {/* 

          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              Retail Price
            </p>
            <p className="text-black font-semibold">
              {report_obj?.retail_price ? report_obj?.retail_price?.retail_price : '0'} {report_obj?.retail_currency}
            </p>
          </div> */}

          {/* <div className="flex flex-col gap-1">
                <p className="text-sm text-[#969BA0]">
                  Cost Price
                </p>
                <p className="text-black font-semibold">
                  {report_obj?.product?.cost_price}
                </p>
              </div> */}

          <div className="flex flex-col gap-1">
            <p className="text-sm text-[#969BA0]">
              {selectedLanguage?.name == "English" ? "Transfer Amount" : translation[1130]?.value ? translation[1130]?.value : "Transfer Amount"}
            </p>

            <p className="text-black font-semibold">
              {report_obj?.quantity}
            </p>
          </div>



          <div className="flex flex-col gap-1"></div>
          <div className="flex flex-col gap-1"></div>
          <div className="flex items-start justify-end">

          </div>
        </div>
      </div>
    </div>
    // <div
    //   className={`odd-bg text-[#3A3A3A] transition-all ${ShowDetails
    //     ? "border-l-[4px] border-[#5679FFB2]"
    //     : "border-l-[0px]"
    //     }`}
    // >
    //   <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(9,minmax(155px,1fr))]">
    //     <div
    //       className={`before-left-arrow cursor-pointer ${ShowDetails == i + 1
    //         ? "before-left-arrow-open"
    //         : "before-left-arrow-close"
    //         }`}
    //       onClick={() => {
    //         setShowDetails(ShowDetails != i + 1 ? i + 1 : 0);
    //       }}
    //     >
    //       <div className="h-full flex items-center">
    //         <p className="text-sm">{report_obj?.product?.name} </p>
    //       </div>
    //     </div>

    //     <div className="flex items-center">
    //       <p className="text-sm line-clamp-1 pr-3">
    //         {report_obj?.report_choice === InventoryReport.transferTo && InventoryReport.transferToHeading}
    //       </p>
    //     </div>

    //     <div className="flex items-center">
    //       <p className="text-sm">
    //         {report_obj?.after_quantity}
    //         {/* {report_obj?.available_stock?.current_stock}  available stock*/}
    //       </p>
    //     </div>

    //     {/* <div className="flex items-center">
    //     <p className="text-sm">
    //       {report_obj?.product?.cost_price}
    //     </p>
    //   </div> */}

    //     <div className="flex items-center">
    //       <p className="text-sm">
    //         {report_obj?.retail_price ? report_obj?.retail_price?.retail_price : '0'} {report_obj?.retail_currency}
    //       </p>
    //     </div>

    //     <div className="flex items-center">
    //       <p className="text-sm">
    //         {report_obj?.product?.brand}
    //       </p>
    //     </div>

    //     <div className="flex items-center">
    //       <p className="text-sm">
    //         {report_obj?.created_at.split('T', 1)}
    //       </p>
    //     </div>

    //   </div>
    //   {ShowDetails == i + 1 ? (
    //     <div className="scale-in-center">
    //       <div className="bg-[#FAF9FF] px-6 py-3">
    //         <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(140px,2fr))] gap-y-5">

    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">
    //               Product
    //             </p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.product.name}
    //             </p>
    //           </div>
    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">
    //               Type
    //             </p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.report_choice === InventoryReport.transferTo && InventoryReport.transferToHeading}
    //             </p>
    //           </div>
    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">
    //               Stock before Transfer
    //             </p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.before_quantity}
    //             </p>
    //           </div>

    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">
    //               From Location
    //             </p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.from_location?.address_name}
    //             </p>
    //           </div>

    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">
    //               To Location
    //             </p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.to_location?.address_name}
    //             </p>
    //           </div>

    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">
    //               Date
    //             </p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.created_at.split('T', 1)}
    //             </p>
    //           </div>


    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">
    //               Retail Price
    //             </p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.retail_price ? report_obj?.retail_price?.retail_price : '0'} {report_obj?.retail_currency}
    //             </p>
    //           </div>

    //           {/* <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">
    //               Cost Price
    //             </p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.product?.cost_price}
    //             </p>
    //           </div> */}

    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">
    //               Transfer Amount
    //             </p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.quantity}
    //             </p>
    //           </div>

    //           <div className="flex flex-col gap-1">
    //             <p className="text-sm text-[#969BA0]">
    //               Updated Stock
    //             </p>
    //             <p className="text-black font-semibold">
    //               {report_obj?.after_quantity}
    //             </p>
    //           </div>

    //           <div className="flex flex-col gap-1"></div>
    //           <div className="flex flex-col gap-1"></div>
    //           <div className="flex items-start justify-end">

    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   ) : (
    //     ""
    //   )}
    // </div>
  )
}

export default TransferTo