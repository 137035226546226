export const EMPLOYEES = {
  GET_EMPLOYEES: "GET_EMPLOYEES",
  GET_EMPLOYEES_SALES_CART: "GET_EMPLOYEES_SALES_CART",
  GET_EMPLOYEES_DROPDOWN: "GET_EMPLOYEES_DROPDOWN",
  GET_EMPLOYEES_PAGINATION_DATA: "GET_EMPLOYEES_PAGINATION_DATA",
  GET_EMPLOYEES_STAFF_DATA: 'GET_EMPLOYEES_STAFF_DATA',
  GET_EMPLOYEES_WITHOUT_PAGINATION: 'GET_EMPLOYEES_WITHOUT_PAGINATION',
  GET_EMPLOYEE_PAGES: "GET_EMPLOYEE_PAGES",
  ADD_EMPLOYEE: "ADD_EMPLOYEE",
  UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
  DELETE_EMPLOYEE: "DELETE_EMPLOYEE",
  GET_WORKING_SCHEDULE: "GET_WORKING_SCHEDULE",
  GET_WEEKEND: "GET_WEEKEND",

  GET_ASSETS: "GET_ASSETS",
  ADD_ASSETS: "ADD_ASSETS",
  DELETE_ASSETS: "DELETE_ASSETS",
  UPDATE_ASSETS: "UPDATE_ASSETS",

  GET_COMMISSION: "GET_COMMISSION",
  GET_COMMISSION_WITHOUT_PAGINATION: 'GET_COMMISSION_WITHOUT_PAGINATION',
  GET_COMMISSION_PAGES: "GET_COMMISSION_PAGES",
  ADD_COMMISSION: "ADD_COMMISSION",
  UPDATE_COMMISSION: "UPDATE_COMMISSION",
  DELETE_COMMISSION: "DELETE_COMMISSION",

  GET_COMMISSION_BY_STAFF: "GET_COMMISSION_BY_STAFF",
  GET_COMMISSION_BY_DETAIL: "GET_COMMISSION_BY_DETAIL",
  GET_COMMISSION_BY_DETAIL_WITHOUT_PAGINATION: 'GET_COMMISSION_BY_DETAIL_WITHOUT_PAGINATION',
  EMPLOYEE_COMMISSIONS: "EMPLOYEE_COMMISSIONS",

  GET_EMPLOYEE_SKILL: "GET_EMPLOYEE_SKILLS",
  GET_EMPLOYEE_TRAINING: "GET_EMPLOYEE_TRAINING"

};
