import { getMarketPlaceLocations } from 'Adapters/Api';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

function useMarketPlace() {
    const dispatch = useDispatch();
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();

    const [loader, setLoader] = useState(true)

    const state = useSelector((state) => state);
    const locationsList = state?.locations?.locations;
    const businessData = getDataFromLocalStorage("business_data");

    // get business locations
    const getBusinessLocation = (business_id) => {
        dispatch(getMarketPlaceLocations(business_id, setLoader));
    };

    useEffect(() => {
        if (businessData?.id) {
            getBusinessLocation(businessData?.id)
        }
    }, [businessData?.id])


    return {
        locationsList,
        loader,
        setLoader
    }
}

export default useMarketPlace
