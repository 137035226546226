import React from "react";
// Import Swiper styles
import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

export default function index() {
  const pagination = {
    clickable: true,
      renderBullet: function (index, className) {
      return `<span class='w-full h-[3px] rounded-none ${className}'></span>`;
    },
  };
  return (
    <>
      <div className="flex-1 pb-5">
        <Swiper
            style={{
              "--swiper-pagination-color": "#fff",
              "--swiper-pagination-bullet-inactive-color": "#C7CBDB",
            }}
          pagination={pagination}
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          className="h-full loginSwiper"
        >
          <SwiperSlide>
            <div className="h-full flex items-center justify-start flex-col gap-3">
              <div className="w-[20rem] h-[20rem] md:h-[25rem] md:w-[25rem] lg:h-[30rem] lg:w-[30rem] relative flex justify-center items-center">
                {/* <div className="absolute z-[1] inset-12 bg-primary rounded-full"></div> */}
                <img
                  src={`${process.env.PUBLIC_URL}/images/login-slider-1.png`}
                  className="relative z-10"
                />
              </div>
              <div className="text-center text-2xl text-white">
                Get to know about your business daily <br className="lg:block hidden" /> reports.
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-full flex items-center justify-start flex-col gap-3">
              <div className="w-[20rem] h-[20rem] md:h-[25rem] md:w-[25rem] lg:h-[30rem] lg:w-[30rem] relative flex justify-center items-center">
                {/* <div className="absolute z-[1] inset-12 bg-primary rounded-full"></div> */}
                <img
                  src={`${process.env.PUBLIC_URL}/images/login-slider-2.png`}
                  className="relative z-10"
                />
              </div>
              <div className="text-center text-2xl text-white">
                Book appointments for your clients and keep <br className="lg:block hidden" /> track through calander.
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="h-full flex items-center justify-start flex-col gap-3">
              <div className="w-[20rem] h-[20rem] md:h-[25rem] md:w-[25rem] lg:h-[30rem] lg:w-[30rem] relative flex justify-center items-center">
                {/* <div className="absolute z-[1] inset-12 bg-primary rounded-full"></div> */}
                <img
                  src={`${process.env.PUBLIC_URL}/images/login-slider-3.png`}
                  className="relative z-10"
                />
              </div>
              <div className="text-center text-2xl text-white">
                Manage your business employees to get the <br className="lg:block hidden" /> record.
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
}
