import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from "new-components/location/helper";
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { getRetailTarget, createRetailTarget, updateRetailTarget, deleteRetailTarget, getRetailTargetWithNoPagination } from 'Adapters/Api/retailsTarget';
import { getLocations } from 'Adapters/Api';
import { getBrandDropdownlist } from 'Adapters/Api/Brand';
import { toast } from 'react-toastify';
import { MONTHS } from 'Constants/Data/Months';
import { apiErrorMessage } from 'Constants/Data/Errors';

let timeOutId = undefined;
function useRetailTarget() {
    const {
        handleSubmit,
        control,
        register,
        reset,
        getValues,
        formState: { errors, isValid }
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onBlur"
    });

    const { locationDetail } = useLocation()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");
    const [add_serv_target_loading, setServ_targ_Loading] = useState(false)

    const dispatch = useDispatch()
    const state = useSelector((state) => state)

    const brandlist = state?.brand?.brands;
    const retailTargetList = state?.retailTarget?.retail_targets;
    const allRetailTargetList = state?.retailTarget?.all_retail_targets;
    const pages = state?.retailTarget?.pages;
    const allCount = state?.retailTarget?.allCount;
    const retail_updated = state?.retailTarget?.retail_target_updated;
    const locationList = state?.locations?.locations;
    const currency = state?.business?.business?.currency?.code;
    const selected_location = state.locations.selected_location;

    const [is_delete, setDelete] = useState(false);
    const [is_Update, setUpdate] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [searchText, setSearchText] = useState();
    const [monthFilter, setMonthFilter] = useState("");
    const [yearFilter, setYearFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [isToastVisible, setIsToastVisible] = useState(false);

    const [AddRetailTarget, setAddRetailTarget] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [data, setData] = useState({
        current_year: '',
        current_month: '',
        current_date: '',
        selected_month: '',
    })

    const locations = state?.locations?.locations;
    const location = locations?.find((obj) => obj?.id == selected_location);
    const currencyOfSelectedLocation = location?.currency?.code;


    //submit retails target here
    const onSubmitRetailsTarget = (data) => {
        setServ_targ_Loading(true)
        const body = {
            business: businessData?.id,
            location: data?.location,
            brand: data?.brand,
            brand_target: data?.brand_target,
            month: data?.month,
            year: data?.year
        }
        dispatch(
            createRetailTarget(
                body,
                access_token,
                (message) => {
                    setServ_targ_Loading(false)
                    toast.success(message, { toastId: "toast" });
                    setAddRetailTarget(false)
                    setUpdate(false)
                    reset({
                        business: '',
                        location: '',
                        brand: '',
                        month: '',
                        year: '',
                        brand_target: '',
                    })
                    setCurrentPage(1)
                    dispatch(getRetailTargetWithNoPagination(selected_location, searchText, monthFilter, yearFilter, setLoader))
                    dispatch(getRetailTarget(selected_location, 1, searchText, monthFilter, yearFilter, setLoader))
                },
                () => {
                    setServ_targ_Loading(false)
                    toast.error(apiErrorMessage, { toastId: "toast" })
                }
            )
        )
    }

    //set form state here of edited record
    const onEditHandler = (data) => {
        reset({
            id: data?.id,
            location: data?.location?.id,
            brand: data?.brand?.id,
            brand_target: data?.brand_target,
            month: data?.month,
            year: data.year?.slice(0, 4)
        })
        setAddRetailTarget(true)
        setUpdate(true)
    }


    // changes submit here 
    const onUpdateHandler = (data) => {
        setServ_targ_Loading(true)
        const body = {
            ...data,
            location: data?.location,
            brand: data?.brand,
            brand_target: data?.brand_target,
            month: data?.month,
            year: data?.year
        }
        dispatch(
            updateRetailTarget(
                body,
                access_token,
                (message) => {
                    setServ_targ_Loading(false)
                    toast.success(message, { toastId: "toast" });
                    setAddRetailTarget(false)
                    setUpdate(false)
                    reset({
                        business: '',
                        location: '',
                        brand: '',
                        month: '',
                        year: '',
                        brand_target: '',
                    })
                    setCurrentPage(1)
                    dispatch(getRetailTargetWithNoPagination(selected_location, searchText, monthFilter, yearFilter, setLoader))
                    dispatch(getRetailTarget(selected_location, 1, searchText, monthFilter, yearFilter, setLoader))
                },
                () => {
                    setServ_targ_Loading(false)
                    toast.error(apiErrorMessage, { toastId: "toast" })
                }
            )
        )
    }

    // retail delete here 
    const onDeleteHandler = () => {
        dispatch(
            deleteRetailTarget(
                deleteId,
                access_token,
                (message) => {
                    if (!isToastVisible) {
                        toast.success(message, {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }
                    setDelete(false)
                    setCurrentPage(1)
                    dispatch(getRetailTargetWithNoPagination(selected_location, searchText, monthFilter, yearFilter, setLoader))
                    dispatch(getRetailTarget(selected_location, 1, searchText, monthFilter, yearFilter, setLoader))
                },
                () => {
                    if (!isToastVisible) {
                        toast.success(apiErrorMessage, {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }
                }
            )
        )
    }

    const getBusinessLocation = (business_id) => {
        dispatch(getLocations(business_id));
    };

    // useEffect(() => {
    //     dispatch(getBrandDropdownlist())
    // }, [])

    useEffect(() => {
        if (locationList?.length === 0) {
            getBusinessLocation(businessData?.id);
        }

        // if (retailTargetList.length === 0) {
        // dispatch(getRetailTarget())
        // }
        if (retailTargetList) {
            // const val = retailTargetList?.map((element) => {
            //     if (element?.location?.id === selected_location) {
            //         return { ...element }
            //     }
            // });
            // const retailTargetArr = val?.filter((element) => element !== undefined);
            // setDataList(retailTargetArr);
            setDataList(retailTargetList)
        }
    }, [retailTargetList, selected_location])

    useEffect(() => {
        if (searchText === null) {
            // Initial load with empty searchText
            dispatch(getRetailTarget(selected_location, currentPage, "", monthFilter, yearFilter, setLoader))
            dispatch(getRetailTargetWithNoPagination(selected_location, "", monthFilter, yearFilter, setLoader))
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                dispatch(getRetailTarget(selected_location, currentPage, searchText, monthFilter, yearFilter, setLoader))
                dispatch(getRetailTargetWithNoPagination(selected_location, searchText, monthFilter, yearFilter, setLoader))
            }, 500);
        }
    }, [currentPage, searchText, monthFilter, yearFilter, selected_location])

    useEffect(() => {
        setLoader(true)
        setCurrentPage(1)
    }, [monthFilter, yearFilter, selected_location])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchText])

    useEffect(() => {
        let date = new Date();
        let today_year = date.getFullYear();
        let today_month = date.getMonth();
        let today_date = date.getDate();
        let today_day = date.getDay();

        setData({
            ...data,
            current_year: today_year,
            current_month: today_month,
            current_date: today_date,
            selected_month: MONTHS[today_month],
        });
    }, [])

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    const yearDropdown = useMemo(() => {
        if (data.current_year) {
            let years = [];
            for (let i = data.current_year; i <= 2040; i++) {
                years.push({ label: i.toString(), value: i.toString() })
            }
            return years;
        }
    }, [data.current_year])

    // search and month filter 
    const retail_target_fiter = () => {
        // const filter_data = dataList?.filter((itm) => {
        //     if (monthFilter || searchText || yearFilter) {
        //         let return_value = false;
        //         if (yearFilter) {
        //             if (itm?.year?.slice(0, 4)?.toLowerCase() === yearFilter?.toLocaleLowerCase()) {
        //                 return_value = true;
        //             } else {
        //                 return false;
        //             }
        //         }
        //         if (monthFilter) {
        //             if (itm?.month?.toLowerCase() === monthFilter?.toLocaleLowerCase()) {
        //                 return_value = true;
        //             } else {
        //                 return false;
        //             }
        //         }
        //         if (searchText) {
        //             if (itm?.brand?.name?.toLowerCase()?.includes(searchText?.toLocaleLowerCase())) {
        //                 return_value = true;
        //             } else {
        //                 return false;
        //             }
        //         }
        //         return return_value;
        //     }
        //     return true;
        // })
        const filter_data = dataList
        return filter_data;
    }

    let retailTarget = [
        {
            label: selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id",
            key: "id"
        },
        {
            label: selectedLanguage?.name == "English" ? "Brand" : translation[836]?.value ? translation[836]?.value : "Brand",
            key: "brand"
        },
        {
            label: selectedLanguage?.name == "English" ? "Brand Target" : translation[832]?.value ? translation[832]?.value : "Brand Target",
            key: "brand_target"
        },
        {
            label: selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month",
            key: "month"
        },
    ];

    const retailTargetData = {
        filename: "Retail Target Report.csv",
        headers: retailTarget,
        data:
            allRetailTargetList?.length > 0
                ? allRetailTargetList?.map((item) => {
                    return {
                        id: item?.id ? item?.id.toString().split("-")[0] : "---",
                        brand: item?.brand?.name ? item?.brand?.name : "----",
                        month: item?.month ? `${item?.month} ${item?.year && `, ${item?.year?.slice(0, 4)}`}` : "----",
                        brand_target: item?.brand_target ? `${item?.brand_target} ${currencyOfSelectedLocation}` : "----",
                    };
                })
                : [],
    };

    return {
        handleSubmit, control, getValues, register,
        reset, errors, isValid, is_delete, setDelete,
        is_Update, setUpdate, deleteId, setDeleteId,
        searchText, setSearchText, monthFilter, setMonthFilter,
        onSubmitRetailsTarget, onEditHandler, onUpdateHandler,
        onDeleteHandler, retailTargetList, retail_updated, dataList,
        locationList, brandlist, AddRetailTarget, setAddRetailTarget, locationDetail, loader,
        retail_target_fiter, retailTargetData, currency, yearDropdown, yearFilter, setYearFilter, currencyOfSelectedLocation,
        add_serv_target_loading, setServ_targ_Loading, pages, allCount, setCurrentPage, currentPage, selected_location
    }
}

export default useRetailTarget

export const validationSchema = yup.object().shape({
    location: yup.string().required("This field is required").nullable(),
    month: yup.string().required("This field is required").nullable(),
    year: yup.string().required("This field is required").nullable(),
    brand: yup.string().required("This field is required").nullable(),
    brand_target: yup.string().required("This field is required"),
})
