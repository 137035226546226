import React, { useEffect } from "react";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Svgs from "Assets/svgs";
import FloatInput from "Components/TenantApp/Elements/FloatInput";

function AddVoucherComission({
  currencyOnSelectedLocation,
  voucher_commission,
  onBlurHandler,
  errors,
  translation,
  selectedLanguage,
  setCommissions,
  commissions,
  setErrors,
  onUpdate,
  i,
  submitButtonClick
}) {
  let voucherCommissionLength = commissions?.voucher_comission.length;
  const handleChange = (e) => {
    const { name, value } = e.target;
    onUpdate({ name: name, value: value })
    setErrors((prev) => ({
      ...prev,
      name: "",
    }));
  };
  const removeSelectedField = () => {
    if (i == 0) {
      setCommissions((prev) => ({
        ...prev,
        voucher_comission: []
      }))
    }
    if (voucher_commission?.id) {  // edit 
      onUpdate({ name: 'isDeleted', value: 'True' }) //for handling isDelete
    }
    else { // create no need of isDelete here 
      let filter = commissions.voucher_comission.filter((obj) => obj !== commissions.voucher_comission[i])
      setCommissions((prev) => ({
        ...prev,
        voucher_comission: filter
      }))
    }
  }
  useEffect(() => {
    if (i == 0) {
      onUpdate({ name: 'from_value', value: '0' })
    }
    if (i == voucherCommissionLength - 1) {
      onUpdate({ name: 'to_value', value: '∞' })
    }
  }, [i, voucherCommissionLength])

  return (
    <div className="grid md:grid-cols-4 md:gap-4 gap-6 relative border px-3 py-6 whitespace-nowrap rounded-md">
      <div className="absolute top-0 right-0 ... bg-primary px-[3px] py-[3px]  rounded cursor-pointer -translate-y-1/2 translate-x-1/2" onClick={removeSelectedField}><Svgs.Close /></div>

      <FloatInput
        title={selectedLanguage?.name == "English" ? "From" : translation[601]?.value ? translation[601]?.value : "From"}
        rightIcon={currencyOnSelectedLocation?.currency?.code}
        placeholder="Enter from"
        name="from_value"
        value={voucher_commission?.from_value}
        onChange={handleChange}
        disabled={i == 0 ? true : false}
        error={(submitButtonClick && !voucher_commission?.from_value) ? true : false}
      // onBlur={() =>
      //   onBlurHandler({ from_value: voucher_commission?.from_value })
      // }
      // error={errors?.from_value}
      />
      <LoginInput
        type={i === voucherCommissionLength - 1 ? "text" : "number"}
        title={"To"}
        rightIcon={currencyOnSelectedLocation?.currency?.code}
        placeholder="Enter To"
        name="to_value"
        value={voucher_commission?.to_value}
        onChange={handleChange}
        disabled={i === voucherCommissionLength - 1 ? true : false}
        error={(submitButtonClick && !voucher_commission?.to_value) ? true
          : voucher_commission?.to_value && (voucher_commission?.to_value <= voucher_commission?.from_value)
            ? selectedLanguage?.name == "English" ? "Value should be greater than from value." : translation[1331]?.value
              ? translation[1331]?.value : "Value should be greater than from value." : false}
      // onBlur={() =>
      //   onBlurHandler({ to_value: voucher_commission?.to_value })
      // }
      // error={errors?.to_value}
      />
      <FloatInput
        title={selectedLanguage?.name == "English" ? "Commission" : translation[596]?.value ? translation[596]?.value : "Commission"}
        rightIcon={commissions.voucher_comission_symbol}
        placeholder="Enter"
        name="commission"
        value={voucher_commission?.commission}
        onChange={handleChange}
        error={(submitButtonClick && !voucher_commission?.commission) && true}
      // onBlur={() =>
      //   onBlurHandler({
      //     commission_percentage:
      //     voucher_commission?.commission_percentage,
      //   })
      // }
      // error={errors?.commission_percentage}
      />
      <div className="grid grid-cols-2 gap-1 self-end">
        <LoginBtn
          className="!w-full justify-center"
          borderBtn={commissions.voucher_comission_symbol === "%" && true}
          onClick={(e) => {
            setCommissions((prev) => ({
              ...prev,
              voucher_comission_symbol: currencyOnSelectedLocation?.currency?.code,
            }));
          }}
        >
          {currencyOnSelectedLocation?.currency?.code}
        </LoginBtn>

        <LoginBtn
          className="!w-full justify-center"
          borderBtn={commissions.voucher_comission_symbol !== "%" && true}
          onClick={(e) => {
            setCommissions((prev) => ({
              ...prev,
              voucher_comission_symbol: '%',
            }));
          }}
        >
          %
        </LoginBtn>
      </div>
    </div>
  );
}

export default AddVoucherComission;