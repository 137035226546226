import React, { useState, useEffect } from 'react';
import useValidations from './../../../../../Hooks/useValidations';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import useObjectKeys from './../../../../../Hooks/useObjectKeys';
import { createLoyaltyPoint, deleteLoyalty, getLoyaltyPoints } from 'Adapters/Api/loyaltyPoints';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { updateLoyaltyPoint } from './../../../../../Adapters/Api/loyaltyPoints/index';
import { apiErrorMessage } from 'Constants/Data/Errors';

function useLoyaltyPoint() {
    const dispatch = useDispatch()
    const state = useSelector((state) => state)
    let all_loyalty = state.client.loyalty_points
    const currency = state?.business?.business?.currency?.code
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const { setErrors, setEditErrors, editErrors, validation, errors, onBlurHandler } = useValidations();
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();

    let access_token = getDataFromLocalStorage("access_token")
    let businessData = getDataFromLocalStorage("business_data")
    const { objectKeyConvertToArray } = useObjectKeys();
    const [is_delete, setDelete] = useState(false)
    const [addLoyalityLoader, setAddLoyalityLoader] = useState(false)
    const [updateLoyalityLoader, setUpdateLoyalityLoader] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [loadLoyality, setLoadLoyality] = useState(true)

    const [editId, seteditId] = useState();
    const [loyaltyPoints, setLoyaltyPoints] = useState({
        name: "",
        // loyaltytype: "",
        status: true,
        amount_spend: "",
        number_points: "",
        location: "",
        earn_points: "",
        total_earn_from_points: ""
    })

    //loyalty handle change
    const loyaltyHandleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'amount_spend' || name == 'number_points' || name == 'earn_points' || name == 'total_earn_from_points') {
            setLoyaltyPoints((prev) => ({
                ...prev,
                [name]: value
            }))
            let error = ''
            if (value == 0 && value !== "") {
                error = selectedLanguage?.name == "English" ? "Value cannot be 0." : translation[1721]?.value ? translation[1721]?.value : "Value cannot be 0."
            } else {
                error = ''
            }
            setErrors((prev) => ({
                ...prev,
                [name]: error
            }))
        }
        if (name != 'amount_spend' && name != 'number_points' && name != 'earn_points' && name != 'total_earn_from_points') {
            setLoyaltyPoints((prev) => ({
                ...prev,
                [name]: value
            }))
            setErrors((prev) => ({
                ...prev,
                [name]: ""
            }))
        }
    }

    //submit loyalty forms here
    const onSubmitLoyalty = () => {
        if (!errors?.amount_spend && !errors?.number_points && !errors?.earn_points && !errors?.total_earn_from_points) {
            setErrors(validation(loyaltyPoints))
            let result = validation(loyaltyPoints)
            if (objectKeyConvertToArray(result)?.length === 0) {
                setAddLoyalityLoader(true)
                dispatch(
                    createLoyaltyPoint(
                        { ...loyaltyPoints, business: businessData?.id },
                        access_token,
                        (message) => {
                            if (!isToastVisible) {
                                toast.success(message, {
                                    onClose: () => setIsToastVisible(false),
                                });
                                setIsToastVisible(true);
                            }
                            setLoyaltyPoints({
                                name: "",
                                // loyaltytype: "",
                                amount_spend: "",
                                number_points: "",
                                location: "",
                                earn_points: "",
                                total_earn_from_points: ""
                            })
                            setAddLoyalityLoader(false)
                            setErrors()
                        },
                        () => {
                            if (!isToastVisible) {
                                toast.error(apiErrorMessage, {
                                    onClose: () => setIsToastVisible(false),
                                });
                                setIsToastVisible(true);
                            }
                            setAddLoyalityLoader(false)
                        }
                    )
                )
            }
        }
    }

    //update loyalty point here
    const onUpdateLoyalty = (data) => {
        if (!editErrors?.amount_spend && !editErrors?.number_points && !editErrors?.earn_points && !editErrors?.total_earn_from_points) {
            const { name, amount_spend, number_points, location, earn_points, total_earn_from_points } = data
            let requiredFields = { name, amount_spend, number_points, location, earn_points, total_earn_from_points }
            setEditErrors(validation(requiredFields))
            let result = validation(requiredFields)
            if (objectKeyConvertToArray(result)?.length === 0) {
                setUpdateLoyalityLoader(true)
                dispatch(
                    updateLoyaltyPoint(
                        data,
                        access_token,
                        (message) => {
                            if (!isToastVisible) {
                                toast.success(message, {
                                    onClose: () => setIsToastVisible(false),
                                });
                                setIsToastVisible(true);
                            }
                            seteditId();
                            setUpdateLoyalityLoader(false)
                        },
                        () => {
                            if (!isToastVisible) {
                                toast.error(apiErrorMessage, {
                                    onClose: () => setIsToastVisible(false),
                                });
                                setIsToastVisible(true);
                            }
                            setUpdateLoyalityLoader(false)
                        }
                    )
                )
            }
        }
    }

    const onDeleteLoyalty = () => {
        dispatch(
            deleteLoyalty(
                deleteId,
                access_token,
                (message) => {
                    setDelete(false);
                    if (!isToastVisible) {
                        toast.success(message, {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }
                },
                () => {
                    if (!isToastVisible) {
                        toast.error(apiErrorMessage, {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }

                }
            )
        )
    }

    useEffect(() => {
        // if (all_loyalty?.length === 0) {
        dispatch(getLoyaltyPoints(setLoadLoyality))
        // }
    }, []);


    return {
        all_loyalty, onUpdateLoyalty,
        loyaltyPoints, setLoyaltyPoints,
        errors, onBlurHandler,
        loyaltyHandleChange, onSubmitLoyalty,
        is_delete, setDelete, setDeleteId,
        onDeleteLoyalty, currency,
        addLoyalityLoader, updateLoyalityLoader,
        translation, selectedLanguage, setEditErrors, editErrors,
        editId, seteditId,
        loadLoyality
    }
}

export default useLoyaltyPoint