import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const DashboardChart = ({ GraphData, animate, expense }) => {
    let colorIndex = 0; // Initialize the color index
console.log(GraphData,'GraphData');
    const staticColors = [
        '#CED1FF', '#5679FE', '#054CD5', '#FFC0CB', '#FF5733', '#FFD700', '#32CD32', '#FF1493', '#800080', '#00FFFF',
        '#00FF00', '#FFFF00', '#FF4500', '#8B4513', '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2', '#00BFFF', '#1E90FF',
        '#87CEEB', '#40E0D0', '#7FFF00', '#228B22', '#FF6347', '#20B2AA', '#3CB371', '#FF7F50', '#DB7093', '#2E8B57',
        '#4682B4', '#48D1CC', '#FA8072', '#FFA07A', '#778899', '#191970', '#6A5ACD', '#9370DB', '#00CED1', '#FF4500',
        '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2', '#00BFFF', '#1E90FF', '#87CEEB', '#40E0D0', '#7FFF00', '#228B22',
        '#FF6347', '#20B2AA', '#3CB371', '#FF7F50', '#DB7093', '#2E8B57', '#4682B4', '#48D1CC', '#FA8072', '#FFA07A',
        '#778899', '#191970', '#6A5ACD', '#9370DB', '#00CED1', '#FF4500', '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2',
        '#00BFFF', '#1E90FF', '#87CEEB', '#40E0D0', '#7FFF00', '#228B22', '#FF6347', '#20B2AA', '#3CB371', '#FF7F50',
        '#DB7093', '#2E8B57', '#4682B4', '#48D1CC', '#FA8072', '#FFA07A', '#778899', '#191970', '#6A5ACD', '#9370DB',
        '#00CED1', '#FF4500', '#ADFF2F', '#7B68EE', '#BA55D3', '#8A2BE2', '#00BFFF', '#1E90FF', '#87CEEB', '#40E0D0',
        '#7FFF00', '#228B22', '#FF6347', '#20B2AA', '#3CB371', '#FF7F50', '#DB7093', '#2E8B57', '#4682B4', '#48D1CC',
        '#FA8072', '#FFA07A', '#778899', '#191970', '#6A5ACD', '#9370DB', '#00CED1'
    ];

    const [barThickness, setBarThickness] = useState(30);

    useEffect(() => {
        // Update barThickness based on screen width
        const updateBarThickness = () => {
            const screenWidth = window.innerWidth;
            let thickness;

            if (screenWidth < 600) {
                thickness = 15; // Mobile
            } else if (screenWidth < 992) {
                thickness = 20; // Tablet
            } else {
                thickness = 25; // Laptop and larger
            }

            setBarThickness(thickness);
        };

        // Initial call to set barThickness
        updateBarThickness();

        // Attach event listener to update barThickness on window resize
        window.addEventListener('resize', updateBarThickness);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateBarThickness);
        };
    }, []);

    const options = {
        // responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'x', // Set indexAxis to 'y' for vertical bar chart
        scales: {
            x: {
                ticks: {
                    // maxRotation: 30, // Disable rotation of labels
                    autoSkip: false, // Prevent labels from being skipped
                    maxTicksLimit: 10, // Adjust the maximum number of visible labels
                    callback: function (value) {
                        value = GraphData[value]?.key;
                        // Customize label display
                        if (value?.length > 10) {
                            return value?.substring(0, 10) + '...'; // Truncate long labels with ellipsis
                        }
                        return value;
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false, // Set display to false to hide the legend
            },
            tooltip: {
                backgroundColor: 'white', // Background color of the tooltip
                titleColor: '#101928',
                bodyColor: 'rgba(16, 25, 40, 0.50)', // Text color of the tooltip
                borderColor: '#cecece',
                borderWidth: '1',
                yAlign: 'bottom',
                padding: '10',
                textAlign: 'center'
            },
        },
    };
    let final = {
        labels: GraphData.map(item => item.key),
        datasets: [
            {
                label: expense ? ' Expense ': ' Sale ' ,
                data: GraphData.map(item => (item.data).toString()),
                backgroundColor: animate ? "#e5e7eb" : GraphData.map(item => {
                    const color = staticColors[colorIndex];
                    colorIndex = (colorIndex + 1) % staticColors.length; // Move to the next color index
                    return color;
                }),
                borderColor: animate ? "#e5e7eb" : GraphData.map(item => staticColors[colorIndex]),
                // backgroundColor: (context) => {
                //     const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300); // Adjust the gradient dimensions as needed
                //     gradient.addColorStop(0, 'rgba(225, 231, 255, 1)'); // End color
                //     gradient.addColorStop(1, 'rgba(86, 121, 255, 1)'); // Start color
                //     return gradient;
                // },
                // borderColor: 'rgba(86, 121, 255, 1)', // Border color
                borderWidth: 0,
                borderRadius: 1000,
                barThickness: barThickness, // Set the fixed thickness for the bars
            },
        ],
    }

    return <Bar height={120} style={{ width: '100%' }} options={options} data={final} />;
}

export default DashboardChart

