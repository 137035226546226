import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import DateCounter from "./CalendarElements/DateCounter";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import { getLocations } from "Adapters/Api";
import { getCalendarEmployeeDropDownList, getEmployeeDropDownList, getEmployeeList } from "Adapters/Api/employee";
import {
    APPOINTMENT_TIME,
    CALENDAR_OPENING_TIME,
} from "Constants/Data/OpeningHours";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getAppointments, getCalendarMainAppointments, getEmployeeDailyInsights, getEmployeeInsights } from "Adapters/Api/apointments";
import Svgs from "Assets/svgs";
import CalendarChart from "./Elements/CalendarChart";
import { DateRange } from "react-date-range";
import MultiDropdown from "Components/TenantApp/Elements/MultiDropdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import AnalyticsCard from "./Elements/AnalyticsCard";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import DashboardChart from "../DashboardChart";
import { graphScheleton } from "Constants/Data/Calendar";
import NoEmployeeDataOnCalendar from "Components/Utility/NoEmployeeDataOnCalendar";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";
import CalenderShimmer from "Components/Utility/Shimmers/CalenderShimmer";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";

const AnalyticsCalendar = () => {
    const dispatch = useDispatch();
    const { getDataFromLocalStorage, storeDataInLocalStorage } =
        useSetDataInLocalStorage();
    const businessData = getDataFromLocalStorage("business_data");
    const selected_location = getDataFromLocalStorage("selected_location");
    const state = useSelector((state) => state);
    const location_currency = state?.utility?.currency?.location_currency;
    const reduxlocationsList = state?.locations?.locations;
    const barChart_Data = state.appointment?.barChart_data
    const FinalData = state.appointment.appointments;
    const barChart_data_updated = state.appointment?.barChart_data_updated
    const employee_insight_data = state.appointment?.employee_insight_data
    const employee_insight_data_updated = state.appointment?.employee_insight_data_updated
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const employeeUpdate = state?.employee?.employee_updated
    const employeeReduxx = state?.employee?.employees

    const allEmployeesTranslation = selectedLanguage?.name == "English" ? "All Employee" : translation[122]?.value ? translation[122]?.value : "All Employee"

    const [selectedLocation, setLocationSelection] = useState("");
    const [pageLoading, setPageLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [employeeDailyInsightLoader, setEmployeeDailyInsightLoader] = useState(true)
    const [AllEmployee, setAllEmployee] = useState([]);
    const [SelectedEmployee, setSelectedEmployee] = useState([{ value: 'all', label: allEmployeesTranslation }]);
    const [appointmentLoader, setAppointmentLoader] = useState(true)
    const [selectedDateRange, setSelectedDateRange] = useState([
        {
            startDate: getStartOfMonth(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);
    const [StartTime, setStartTime] = useState(CALENDAR_OPENING_TIME);
    const [ToggleCalendarRange, setToggleCalendarRange] = useState(false)
    const [SelectedDate, setSelectedDate] = useState(
        new Date().toLocaleDateString("en-CA")
    );

    const formattedEndDate = formatDate(selectedDateRange[0]?.endDate);
    const formattedStartDate = formatDate(selectedDateRange[0]?.startDate);
    const EndDate = formatDateForFrontend(formattedEndDate);
    const StartDate = formatDateForFrontend(formattedStartDate);

    // get business locations
    const getBusinessLocation = (business_id) => {
        dispatch(getLocations(business_id));
    };

    function getStartOfMonth() {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), 1);
    }

    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function formatDateForFrontend(inputDate) {
        const dateParts = inputDate.split('-');
        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        return `${day}-${month}-${year}`;
    }

    const handleSelectChangeAll = (selected) => {
        if (selected?.length === 0 || selected[0]?.value != "all" && selected.some((option) => option.value === 'all')) {
            setSelectedEmployee([{ value: 'all', label: allEmployeesTranslation }]);
        } else {
            setSelectedEmployee(selected.filter((option) => option.value !== 'all'));
        }
    };

    const handleDateChange = (ranges) => {
        setSelectedDateRange([ranges.selection]);
        // setToggleCalendarRange(false)
    };


    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);


    useEffect(() => {
        setPageLoading(true)
        setLoading(true)
        setEmployeeDailyInsightLoader(true)
    }, [selected_location])

    useEffect(() => {
        if (selected_location) {
            dispatch(getCalendarEmployeeDropDownList(selected_location, setPageLoading))
        }
    }, [selected_location])


    useEffect(() => {
        if (reduxlocationsList?.length === 0) {
            getBusinessLocation(businessData?.id);
        }
    }, []);

    useEffect(() => {
        if (SelectedEmployee?.find(itm => itm?.value === "all")) {
            setAllEmployee(employeeReduxx);
        } else {
            const selectedEmployeeIds = SelectedEmployee.map(item => item.value);
            const filteredEmployees = employeeReduxx?.filter(item => selectedEmployeeIds?.includes(item.id));
            setAllEmployee(filteredEmployees);
        }
    }, [employeeReduxx, employeeReduxx?.length, SelectedEmployee]);

    // useEffect(() => {
    //     if (SelectedEmployee.some(option => option.value === 'all')) {
    //         setAllEmployee(employeeReduxx);
    //         setCurrentEmployees(employeeReduxx)
    //     } else {
    //         const selectedValues = SelectedEmployee.map(option => option.value);
    //         const filteredArray = employeeReduxx.filter(item => selectedValues.includes(item.id));
    //         setAllEmployee(filteredArray);
    //     }
    // }, [state, CalendarData, SelectedEmployee, employeeReduxx?.length, selected_location]);

    useEffect(() => {
        if (selected_location) {
            setLocationSelection(selected_location);
        } else {
            if (reduxlocationsList?.length > 0) {
                setLocationSelection(reduxlocationsList[0]?.id);
            }
        }
    }, [reduxlocationsList?.length]);

    useEffect(() => {
        // setAllEmployee([])
        // setCurrentEmployees([])
        setSelectedEmployee([{ value: 'all', label: allEmployeesTranslation }])
        let lctin = reduxlocationsList.find((itm) => itm?.id === selected_location);
        if (lctin != undefined || lctin != null) {
            if (Object.keys(lctin).includes("currency") && lctin?.currency?.code) {
                setSelectedCurrency(lctin?.currency?.code);
                dispatch({
                    type: "LOCATION_CURRENCY",
                    payload: lctin?.currency?.code,
                });
            }
        }
    }, [selected_location, reduxlocationsList?.length]);


    useEffect(() => {
        let range = selectedDateRange[0]
        setSelectedDate((range?.startDate)?.toLocaleDateString("en-CA"));
    }, [selectedDateRange])

    // update dom with latest 
    useEffect(() => {
        let drop = document.querySelectorAll('.drop');
        drop.forEach(ele => {
            ele.classList.add('hidden')
        })

        // var controller = new AbortController()
        // var signal = controller.signal;
        // controller.abort()
        var dateeee = new Date();
        if (SelectedDate && selectedLocation && SelectedEmployee?.length > 0) {
            let employeeData
            if (SelectedEmployee?.find(itm => itm?.value === "all")) {
                employeeData = "All";
            } else {
                employeeData = SelectedEmployee?.map(employee => ({ id: employee?.value }));
            }
            // if (!state.appointment.appointments_updated) {
            dispatch(getCalendarMainAppointments(SelectedDate ? SelectedDate : dateeee.toLocaleDateString(), selectedLocation, employeeData, setAppointmentLoader));
            // }
        }
    }, [SelectedDate, SelectedEmployee?.length, selectedLocation])

    useEffect(() => {
        if (!pageLoading) {
            if (employeeUpdate && selected_location && formattedStartDate && formattedEndDate) {
                onSubmit();
            }
        }
    }, [employeeUpdate, pageLoading]);


    const onSubmit = () => {
        if (!selected_location || !formattedStartDate || !formattedEndDate) {
            return;
        }

        let employeeList = AllEmployee?.length > 0 ? AllEmployee : employeeReduxx?.length > 0 ? employeeReduxx : [];
        employeeList = employeeList.map(employee => ({ id: employee.id }));

        dispatch(getEmployeeInsights(formattedStartDate, formattedEndDate, employeeList, selected_location, setLoading));
        dispatch(getEmployeeDailyInsights(formattedStartDate, formattedEndDate, employeeList, selected_location, setEmployeeDailyInsightLoader));
    }

    return (
        <>
            <div className="lg:px-[2rem] lg:pt-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-Fright">
                <div className="flex flex-col items-start gap-2 md:gap-4 mb-6">
                    <Breadcrumb
                        first={selectedLanguage?.name == "English" ? "POS Analytics" : translation[7]?.value ? translation[7]?.value : "POS Analytics"}
                        second={selectedLanguage?.name == "English" ? "Employee Analytics" : translation[1236]?.value ? translation[1236]?.value : "Employee Analytics"}
                        url={'/dashboard/pos-analytics'}
                    />
                    <div className="flex items-center justify-between md:gap-5 gap-7 flex-wrap">
                        <div className="flex justify-between gap-3 flex-1">
                            <div>
                                <div className="font-semibold lg:text-2xl text-xl">
                                    {selectedLanguage?.name == "English" ? "Employee Services Bar Chart" : translation[1235]?.value ? translation[1235]?.value : "Employee Services Bar Chart"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-[2rem]">
                    <div className="flex items-center gap-3 flex-wrap">
                        <div>
                            <MultiDropdown
                                showTitle={false}
                                isMulti={true}
                                placeholder={selectedLanguage?.name == "English" ? "All Employee" : translation[122]?.value ? translation[122]?.value : "All Employee"}
                                value={SelectedEmployee}
                                options={[
                                    {
                                        value: "all",
                                        label: selectedLanguage?.name == "English" ? "All Employee" : translation[122]?.value ? translation[122]?.value : "All Employee"
                                    },
                                    ...employeeReduxx?.map((info, i) => {
                                        return {
                                            label: info.full_name,
                                            value: info.id,
                                            otherData: {
                                                image: info?.image,
                                                DataOne: info.email,
                                                showImage: true,
                                                index: i
                                            },
                                            email: info.email,
                                            mobile_number: info.mobile_number
                                        };

                                    }),
                                ]}
                                onChange={handleSelectChangeAll}
                                minWidth='min-w-[15.625rem]'
                            />
                        </div>
                        <div className="relative z-[412]">
                            <Dropwdown
                                showTitle={false}
                                name={"selectedLocation"}
                                value={selectedLocation}
                                onChange={(e) => {
                                    setLocationSelection(e.target.value);
                                    storeDataInLocalStorage(
                                        "selected_location",
                                        e.target.value
                                    );
                                }}
                                className={'w-[10rem] lg:w-[14rem]'}
                                options={reduxlocationsList?.map((adrs) => {
                                    return {
                                        label: adrs?.address_name,
                                        value: adrs?.id,
                                    };
                                })}
                                title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                                placeholder={selectedLanguage?.name == "English" ? "Filter by Location" : translation[50]?.value ? translation[50]?.value : "Filter by Location"}
                                customHeight={'lg:h-[1.9rem] md:h-[2rem] h-[2.1rem]'}
                            />
                        </div>
                        <div className='flex items-center gap-3'>
                            <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                                <div className='p-2 relative'>
                                    <p onClick={() => { setToggleCalendarRange(!ToggleCalendarRange) }} className='cursor-pointer'>{`${StartDate} - ${EndDate}`}</p>
                                    {
                                        ToggleCalendarRange && <>
                                            <div className='fixed inset-0 z-1' onClick={() => { setToggleCalendarRange(false) }}></div>
                                            <div className='absolute top-full right-0 z-[2]'>
                                                <DateRange
                                                    ranges={selectedDateRange}
                                                    onChange={handleDateChange}
                                                    maxDate={new Date()}
                                                    className='border border-primary rounded-lg' />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <LoginBtn onClick={() => { onSubmit() }}>
                            {selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                        </LoginBtn>
                    </div>
                    <div className="h-fit xl:h-[30rem] p-3 border border-[#3F3F441A] rounded-lg !pt-5">
                        <CalendarChart data={barChart_Data} loading={(loading || pageLoading || !barChart_data_updated) ? true : false} />
                    </div>
                    {!employee_insight_data_updated || employeeDailyInsightLoader || pageLoading ?
                        <div className="grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
                            {[0, 1, 2]?.map((itm) => {
                                return (
                                    <AnalyticsCard animate />
                                )
                            })}
                        </div>
                        :
                        <div className="grid xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
                            {employee_insight_data?.map((itm, i) => {
                                return (
                                    <AnalyticsCard flexWrap={"flex-wrap"} data={itm} index={i} />
                                )
                            })}
                        </div>
                    }

                    {/* <div className="flex  gap-3">
                        <h2 className="font-semibold lg:text-2xl text-xl">
                            {selectedLanguage?.name == "English" ? "Calendar" : translation[2]?.value ? translation[2]?.value : "Calendar"}
                        </h2>
                    </div>
                    <div className="border border-[#3F3F441A] rounded-lg">
                        <div className="p-4 relative z-[12]">
                            <div className="flex items-center justify-between flex-wrap gap-3">
                                <div className="flex items-center gap-4 flex-wrap">

                                </div>
                                <div className="flex items-center gap-2 justify-between flex-1">
                                    <div></div>
                                    <div className="flex items-center gap-2">
                                        <DateCounter
                                            minDate={formattedStartDate}
                                            maxDate={formattedEndDate}
                                            SelectedDate={SelectedDate}
                                            setSelectedDate={setSelectedDate}
                                            selectedLanguage={selectedLanguage}
                                            translation={translation}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overflow-auto calendar_parent relative h-[67vh] w-full border-t">
                            <div className="flex">
                                {!pageLoading && !appointmentLoader ?
                                    AllEmployee?.length > 0 ? (
                                        <>
                                            <div className="sticky left-0 z-[2] bg-white">
                                                <div className="h-[6rem] w-[7rem] flex items-center justify-center border-r border-b top-0 bg-white sticky z-[2]">
                                                    <h1 className="font-semibold text-sm">
                                                        {selectedLanguage?.name == "English" ? "Duration" : translation[112]?.value ? translation[112]?.value : "Duration"}
                                                    </h1>
                                                </div>
                                                {StartTime.map((time, index) => {
                                                    return (
                                                        <div
                                                            className={`time_block block-${index} h-[1rem] w-[7rem] ${(index) % 3 == 0 && index != 0 && 'border-t'} border-r flex items-center justify-center`}
                                                            key={index}
                                                            time={time.value}
                                                        >
                                                            <h1
                                                                className={`text-[#5C5C5C] hidden ${index % 3 == 0 &&
                                                                    "translate-y-[1rem] !block text-[0.6rem] font-semibold"
                                                                    }`}
                                                            >
                                                                {time.label}
                                                            </h1>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="flex-1 relative box-border">
                                                <div
                                                    className="grid absolute h-full w-full"
                                                    style={{
                                                        gridTemplateColumns: `repeat(${AllEmployee?.length},minmax(20rem,2fr))`,
                                                    }}
                                                >
                                                    {
                                                        <>
                                                            {AllEmployee?.map((obj, i) => {
                                                                let data;
                                                                if (SelectedEmployee?.find(itm => itm?.value === "all")) {
                                                                    data = obj;
                                                                } else {
                                                                    data = obj.employee ? obj.employee : obj;
                                                                }
                                                                return (
                                                                    <div
                                                                        className={`border-r grid`}
                                                                        style={{
                                                                            gridTemplateRows: `repeat(${StartTime?.length},1rem)`,
                                                                        }}
                                                                    >
                                                                        <div className="row-span-6 flex items-center justify-center sticky top-0 w-full bg-white z-[1] border-b">
                                                                            <div className="flex flex-col gap-1">
                                                                                <div className="h-[3rem] w-[3rem] rounded-full overflow-hidden mx-auto border">
                                                                                    {data.image ?
                                                                                        <img
                                                                                            src={data.image}
                                                                                            className="w-full h-full object-cover"
                                                                                        />
                                                                                        :
                                                                                        <LabelAvatar iconLabel={data?.full_name} index={i} />

                                                                                    }
                                                                                </div>
                                                                                <h2 className="font-semibold text-sm text-center capitalize line-clamp-1">
                                                                                    {data.full_name}
                                                                                </h2>
                                                                            </div>
                                                                        </div>


                                                                        {StartTime.map((time, index) => {
                                                                            return (
                                                                                <div
                                                                                    className={`h-[1rem] flex items-center justify-center border-t ${index % 3 != 0 && "border-transparent"
                                                                                        } ${index == 0 && "border-transparent"
                                                                                        }`}
                                                                                    key={index}
                                                                                >
                                                                                </div>
                                                                            );
                                                                        })}

                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    }
                                                </div>


                                                <div
                                                    className="grid absolute h-full w-full asd"
                                                    style={{
                                                        gridTemplateColumns: `repeat(${AllEmployee?.length},minmax(20rem,2fr))`,
                                                    }}
                                                >
                                                    {FinalData?.map((data, index) => {
                                                        return (
                                                            <div
                                                                employee_id={data.employee.id}
                                                                className={`${SelectedEmployee?.find(itm => itm?.value != "all") ? "ONLY_EMPLOYEE" : ""
                                                                    } border-r grid`}
                                                                style={{
                                                                    gridTemplateRows: `repeat(${StartTime.length},1rem) `,
                                                                }}
                                                                key={index}
                                                            >
                                                                <div className="row-span-6 border-b flex items-center justify-center"></div>
                                                                {StartTime?.map((time, i) => {
                                                                    if (
                                                                        SelectedEmployee?.find(itm => itm?.value !== "all")
                                                                            ? SelectedEmployee?.filter(itm => itm?.value != data.employee.id)
                                                                            : true
                                                                    ) {
                                                                        if (data?.appointments?.length > 0) {
                                                                            return data?.appointments?.map(
                                                                                (appointment_arr) => {
                                                                                    return appointment_arr.map(
                                                                                        (appointment, Loop_Id, index) => {
                                                                                            if (
                                                                                                new Date(appointment.appointment_date).toLocaleDateString("en-CA") == SelectedDate && appointment.appointment_time == time.value
                                                                                            ) {
                                                                                                let id = i + 1;
                                                                                                let step = 9;

                                                                                                let width = (1 / appointment_arr.length) * 100 == 100 ? (1 / appointment_arr.length) * 100 == 100 : (1 / appointment_arr.length) * 100 - 0.8;
                                                                                                let margin = (1 / appointment_arr.length) * 100 * Loop_Id;
                                                                                                APPOINTMENT_TIME.filter(
                                                                                                    (ite, i) => {
                                                                                                        if (
                                                                                                            ite.label == appointment.duration.toLowerCase().replace(' 0min', '')
                                                                                                        ) {

                                                                                                            step = i + 9;
                                                                                                        }
                                                                                                    }
                                                                                                );
                                                                                                return (
                                                                                                    <div
                                                                                                        className={`${!(appointment.is_unavailable) && !(appointment.is_blocked && !(appointment.is_unavailable)) && appointment.appointment_status != 'Done' && 'AppointmentCard_Drag'} border-b p-2 ${appointment.is_blocked && 'is_blocked'} ${!employee || (employee && employeePermissions?.calender_appointment?.includes("edit")) ? 'clickable_div' : ''} 
                                                  ${appointment.appointment_status == 'Done' ? "appointment_done_Card no_edit" : appointment.client_type == "IN HOUSE" ? "blue-bg-card" : appointment.is_blocked ? (appointment.is_unavailable) ? 'unavailable-bg-card' : "gray-bg-card" : "yellow-bg-card"} cursor-pointer overflow-hidden`}
                                                                                                        style={{
                                                                                                            gridArea: `${id + 6
                                                                                                                } / 1 / ${id + step} / 2`,
                                                                                                            width: `${width}%`,
                                                                                                            marginLeft: `${margin}%`,
                                                                                                        }}
                                                                                                        key={appointment?.id}
                                                                                                    >
                                                                                                        <div id parent_id={appointment?.appointment_id} appointment_id={appointment.id} className="children_id flex flex-col gap-1 h-full justify-between">
                                                                                                            <div className="flex items-center justify-between gap-3">
                                                                                                                <div className="flex items-center gap-2">
                                                                                                                    <svg
                                                                                                                        width="14"
                                                                                                                        height="14"
                                                                                                                        viewBox="0 0 12 12"
                                                                                                                        fill="none"
                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                    >
                                                                                                                        <path
                                                                                                                            d="M5.72468 0.192383C2.70163 0.192383 0.242188 2.65183 0.242188 5.67487C0.242188 8.69792 2.70163 11.1574 5.72468 11.1574C8.74772 11.1574 11.2072 8.69792 11.2072 5.67487C11.2072 2.65183 8.74772 0.192383 5.72468 0.192383ZM8.87711 6.22312H5.17643V2.38538H6.27293V5.12663H8.87711V6.22312Z"
                                                                                                                            className="duration-fill"
                                                                                                                        />
                                                                                                                    </svg>
                                                                                                                    <p className="duration-time text-[0.65rem] font-semibold whitespace-nowrap">
                                                                                                                        <span className="duration_time">
                                                                                                                            {
                                                                                                                                CALENDAR_OPENING_TIME.find(
                                                                                                                                    (itm) =>
                                                                                                                                        itm.value ==
                                                                                                                                        appointment.appointment_time
                                                                                                                                )?.label
                                                                                                                            }
                                                                                                                        </span>{" "}
                                                                                                                        -{" "}
                                                                                                                        {
                                                                                                                            CALENDAR_OPENING_TIME.find(
                                                                                                                                (itm) =>
                                                                                                                                    itm.value ==
                                                                                                                                    appointment.end_time
                                                                                                                            )?.label
                                                                                                                        }
                                                                                                                        {appointment.duration}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <span className="capitalize text-[0.6rem] font-semibold truncate text-center">
                                                                                                                    {
                                                                                                                        appointment?.service?.name
                                                                                                                    }
                                                                                                                </span>
                                                                                                                {appointment.is_blocked &&
                                                                                                                    <>
                                                                                                                        {
                                                                                                                            (appointment.is_unavailable) ? <h3 className="font-semibold text-xs is_unavailable_typeee">
                                                                                                                            </h3> : <h3 className="font-semibold text-xs ">
                                                                                                                                Blocked Time
                                                                                                                            </h3>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                }
                                                                                                                {!appointment.is_blocked && (
                                                                                                                    <>
                                                                                                                        <button className="rounded-full whitespace-nowrap py-[0.15rem] px-3 duration-type font-semibold text-[0.65rem]">
                                                                                                                            {appointment.client_type ==
                                                                                                                                "IN HOUSE"
                                                                                                                                ? selectedLanguage?.name == "English" ? "In House" : translation[155]?.value ? translation[155]?.value : "In House"
                                                                                                                                : selectedLanguage?.name == "English" ? "Walk In" : translation[156]?.value ? translation[156]?.value : "Walk In"
                                                                                                                            }
                                                                                                                        </button>
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div className="flex gap-1 justify-between">
                                                                                                                {!appointment.is_blocked ? (
                                                                                                                    <>
                                                                                                                        <h3 className="font-semibold text-xs truncate">
                                                                                                                            {
                                                                                                                                appointment?.client
                                                                                                                                    ?.full_name
                                                                                                                            }
                                                                                                                        </h3>
                                                                                                                        <h3 className="font-semibold text-xs ">
                                                                                                                            {appointment.price}{" "}
                                                                                                                            {location_currency}
                                                                                                                        </h3>
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    ''
                                                                                                                )}

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                }
                                                                            );
                                                                        }
                                                                    }
                                                                })}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <NoEmployeeDataOnCalendar />
                                    )
                                    :
                                    <CalenderShimmer />
                                }
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default AnalyticsCalendar;
