import { PAYROLL } from "../../ActionTypes/payrollTypes"


export const getPayroll = (data) => {
    return {
        type: PAYROLL.GET_PAYROLL,
        payload: data
    }
}

export const getUpSellDownSell = (data) => {
    return {
        type: PAYROLL.GET_UPSEL_DOWNSELL,
        payload: data
    }
}

export const getUpSellDownSellStore = (data) => {
    return {
        type: PAYROLL.GET_UPSEL_DOWNSELL_STORE,
        payload: data
    }
}

export const getPayrollWithoutPagination = (data) => {
    return {
        type: PAYROLL.GET_PAYROLL_WITHOUT_PAGINATION,
        payload: data
    }
}


export const removePayroll = (data) => {
    return {
        type: PAYROLL.DELETE_PAYROLL,
        payload: data
    }
}
