import Svgs from 'Assets/svgs';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Popup from 'Components/TenantApp/Elements/Popup';
import Textarea from 'new-components/text-area';
import React from 'react'
import useCompaignPopup from './helper';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown';

const AddCompaign = ({ data, isEdit, setIsEdit, CreateCompanign, setCreateCompanign, setApiCall }) => {

  const {
    campaignForm,
    moment,
    setErrors,
    onChangeHandler,
    indexValue, setIndexValue,
    start_date, setStartDate,
    end_date, setEndDate, errors,
    translation, selectedLanguage,
    submitLoader, segmentDropdown,
    onBlurHandler, toggleDateRange, setToggleDateRange,
    onSubmitHandler,
    segmentSearch, setSegmentSearch,
    segmentLoader,
    segmentPage, setSegmentPage,
    segmentDropdownPagination
  } = useCompaignPopup({ setCreateCompanign, data, setIsEdit, setApiCall, isEdit })
  const { title, content, campaign_type, segment } = campaignForm

  return (
    <Popup
      size={"md"}
      heading={"Create Campaign"}
      open={CreateCompanign}
      close={() => {
        setCreateCompanign(false)
        setIsEdit(false)
      }}
    >
      <div className="flex flex-col gap-5 pr-4">
        <div className="flex items-center gap-3">
          <div>
            <CircleIcon children={<Svgs.NewService />} />
          </div>
          <div className="flex flex-col gap-1">
            <div>
              <h1 className="font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Create Campaign" : translation[1367]?.value ? translation[1367]?.value : "Create Campaign"}
              </h1>
            </div>
            <div>
              <p className="text-sm text-[#7B7B7B]">
                {selectedLanguage?.name == "English" ? "Fill in the following information to create" : translation[1368]?.value ? translation[1368]?.value : "Fill in the following information to create"}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col gap-3">
            <LoginInput
              title={selectedLanguage?.name == "English" ? "Title" : translation[1426]?.value ? translation[1426]?.value : "Title"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Title" : translation[1428]?.value ? translation[1428]?.value : "Enter Title"}
              required={true}
              name={'title'}
              specialCharacterNotAllowed={true}
              value={title}
              onChange={onChangeHandler}
              onEnterSubmit={onChangeHandler}
              error={errors?.title}
              onBlur={() => onBlurHandler({ name: title })}
            />
            <Textarea
              title={selectedLanguage?.name == "English" ? "Email/SMS Content" : translation[1370]?.value ? translation[1370]?.value : "Email/SMS Content"}
              rows={5}
              placeholder={selectedLanguage?.name == "English" ? "Enter Description" : translation[93]?.value ? translation[93]?.value : "Enter Description"}
              required={true}
              name={'content'}
              value={content}
              onChange={onChangeHandler}
              onEnterSubmit={onChangeHandler}
              errorMessage={errors?.content}
              onBlur={() => onBlurHandler({ name: content })}
            />
            <PaginationDropdown
              title={selectedLanguage?.name == "English" ? "Assign Segment" : translation[1371]?.value ? translation[1371]?.value : "Assign Segment"}
              placeholder={selectedLanguage?.name == "English" ? "Select Segment" : translation[1372]?.value ? translation[1372]?.value : "Select Segment"}
              required={true}
              name={'segment'}
              value={segment}
              onChange={onChangeHandler}
              onEnterSubmit={onChangeHandler}
              error={errors?.segment}
              onBlur={() => onBlurHandler({ name: segment })}
              setPage={setSegmentPage}
              page={segmentPage}
              setDropdownSearch={setSegmentSearch}
              dropdownSearch={segmentSearch}
              lastPage={segmentDropdownPagination?.pages}
              currentPage={segmentDropdownPagination?.currentPage}
              loading={segmentLoader}
              options={segmentDropdown?.map((empl, i) => {
                return {
                  label: empl.name,
                  value: empl.id,
                };
              })
              }
            />
            <div className="space-y-2 w-full">
              <p className="text-sm">
                {selectedLanguage?.name == "English" ? "Select Date" : translation[111]?.value ? translation[111]?.value : "Select Date"}
                <span className="text-red-600"> *</span>
              </p>
              <div className='relative flex items-center gap-3 w-full pb-2 z-[1000]'>
                <div className={`border ${errors?.date_range ? "border-[#eb3b3b]" : "border-[#A1A1A1]"} text-sm rounded-md font-semibold flex items-center w-full`}>
                  <div className='p-2 relative gap-4 flex items-center whitespace-nowrap w-full'>
                    <p
                      onClick={() => {
                        setIndexValue(1)
                        setErrors((prev) => ({ ...prev, 'date_range': "" }))
                        setToggleDateRange(!toggleDateRange)
                      }}
                      className='cursor-pointer xl:text-xs w-full'>
                      {indexValue == 1 ?
                        `${start_date ? moment(start_date).format("DD-MM-YYYY") : "--/--/----"} - ${end_date ? moment(end_date).format("DD-MM-YYYY") : "--/--/----"}` :
                        '--/--/---- - --/--/----'}
                    </p>
                    <span className='cursor-pointer ' onClick={() => {
                      setStartDate()
                      setEndDate()
                    }}>
                      {selectedLanguage?.name == "English" ? "Clear" : translation[340]?.value ? translation[340]?.value : "Clear"}
                    </span>

                    {(toggleDateRange && indexValue == 1) && <>
                      <div className='absolute inset-0 bottom-0 left-0 w-[95%] mb-4' onClick={() => { setToggleDateRange(false) }}></div>
                      <div className={`absolute bottom-0 right-0 mb-4`}>
                        <DateRangePicker
                          start={start_date}
                          end={end_date}
                          setStartDate={setStartDate}
                          setEndDate={setEndDate}
                          setToggleDateRange={setToggleDateRange}
                        // addCustomDataOnSelection={() => {
                        //     let date_name = `is_date_${ele.name}`
                        //     setFilterQueries({
                        //         [date_name]: true,
                        //     })
                        // }}
                        />
                      </div>
                    </>
                    }
                  </div>
                  {errors?.date_range &&
                    <p className={`!text-[#eb3b3b] flex items-center gap-1 !text-xs !font-normal absolute top-[100%] w-full justify-end normal-case pt-1`}>
                      <Svgs.I fill="#eb3b3b" />
                      {errors?.date_range}
                    </p>
                  }
                </div>
              </div>

            </div>
            <Dropwdown
              title={selectedLanguage?.name == "English" ? "Campaign Type" : translation[1374]?.value ? translation[1374]?.value : "Campaign Type"}
              placeholder={selectedLanguage?.name == "English" ? "Select Campaign Type" : translation[1375]?.value ? translation[1375]?.value : "Select Campaign Type"}
              required={true}
              name={'campaign_type'}
              value={campaign_type}
              onChange={onChangeHandler}
              onEnterSubmit={onChangeHandler}
              error={errors?.campaign_type}
              onBlur={() => onBlurHandler({ name: campaign_type })}
              options={[
                {
                  label: `${selectedLanguage?.name == "English" ? "Email" : translation[476]?.value ? translation[476]?.value : "Email"}`,
                  value: "email",
                },
                {
                  label: `${selectedLanguage?.name == "English" ? "App Notification" : translation[1376]?.value ? translation[1376]?.value : "App Notification"}`,
                  value: "App Notification",
                },
                {
                  label: `${selectedLanguage?.name == "English" ? "Both" : translation[1377]?.value ? translation[1377]?.value : "Both"}`,
                  value: "both",
                }
              ]}
            />
          </div>
        </div>


        <div className="flex justify-end py-4">
          <LoginBtn
            loading={submitLoader}
            onClick={onSubmitHandler}
            disabled={submitLoader}
            text={
              isEdit ?
                selectedLanguage?.name == "English" ? "Update Campaigns" : translation[1363]?.value ? translation[1363]?.value : "Update Campaigns"
                :
                selectedLanguage?.name == "English" ? "Create Campaigns" : translation[1363]?.value ? translation[1363]?.value : "Create Campaigns"
            }
          />
        </div>
      </div>
    </Popup>
  )
}

export default AddCompaign