import { useEffect, useState } from "react";

const useDealTypeFiveSetting = ({ setErrors, setSettingData, settingData, allMergedOptions }) => {
    const [screenLoader, setScreenLoader] = useState(true)
    const [serviceType, setServiceType] = useState()
    const [popup, setPopup] = useState(false)
    const [searchService, setSearchService] = useState("")
    const [selectedArray, setSelectedArray] = useState([])

    useEffect(() => {
        setTimeout(() => {
            setScreenLoader && setScreenLoader(false)
        }, 1000);
    }, [])

    useEffect(() => {
        const applicableServicesSet = new Set(settingData?.applicableItems?.map(service => service?.itemId));
        const filtered = allMergedOptions?.filter((itm) => applicableServicesSet.has(itm?.id));
        setSelectedArray(filtered)
    }, [allMergedOptions, settingData?.applicableItems])

    // handle change for deal type 2 dropdown
    const handleChangeForType5DealDropdown = (e, data) => {
        const { name, value } = e.target;
        setSettingData((prev) => {
            const mapingList = data || [];
            const updatedApplicableItems = mapingList?.map((itm) => {
                const filteredItm = prev?.applicableItems?.find(item => item?.itemId == itm)
                return {
                    itemId: itm,
                    fixedPrice: filteredItm?.fixedPrice ? filteredItm?.fixedPrice : ""
                };
            });
            return {
                ...prev,
                applicableItems: updatedApplicableItems,
            };
        });

        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
        setPopup(false)
        setServiceType()
    }

    // handle fixedPrice change 
    const handleDiscountChange = (e, id) => {
        const { name, value } = e.target;
        setSettingData((prev) => {
            const existingDiscounts = prev?.applicableItems || [];
            const updatedApplicableItems = existingDiscounts?.map((existingItem) => {
                if (existingItem?.itemId == id) {
                    // Update the fixedPrice for the matching item
                    return {
                        ...existingItem,
                        fixedPrice: value,
                    };
                }
                // No match, return the existing item as is
                return existingItem;
            });

            return {
                ...prev,
                applicableItems: updatedApplicableItems,
            };
        });
    };

    // removed selected Item from array
    const removeSelectedItem = (value, type) => {
        const updatedApplicableItems = settingData?.applicableItems?.filter((itm) => itm?.itemId != value)
        setSettingData((prev) => ({
            ...prev,
            applicableItems: updatedApplicableItems
        }))
    }

    console.log(settingData, "set")
    return {
        handleChangeForType5DealDropdown, screenLoader, handleDiscountChange, removeSelectedItem, serviceType, setServiceType,
        popup, setPopup, searchService, setSearchService, selectedArray, setSelectedArray
    }
};

export default useDealTypeFiveSetting