import React from 'react'
import useProductRefundInvoice from './helper';
import Shimmer from 'Components/Utility/Shimmers/Shimmer';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Svgs from 'Assets/svgs';
import Pagination from 'new-components/Pagination';
import NoPurchaseOrderDataFound from 'Components/Utility/NoPurchaseOrderDataFound';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import moment from 'moment';
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown';

const ProductRefundInvoice = () => {
    const {
        selectedLanguage,
        translation,
        location_currency,
        refunded_record,
        brands,
        allCount,
        pages,
        isLoading,
        filterData, setFilterData,
        loadingPermission,
        currentPage, setCurrentPage,
        ShowDetails, setShowDetails,
        NestedProductReportsHeader,
        setBrandCurrentPage,
        setBrandSearchText,
        brandCurrentPage,
        brandSearchText,
        brandPaginationData,
        brandLoader,
    } = useProductRefundInvoice()
    return (
        <>
            <div className="border rounded-lg mt-3">
                <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row  gap-3">
                    <div className="flex xs:items-center justify-between flex-col xs:flex-row flex-1 w-full md:gap-0 gap-4 ">
                        <div className="flex items-center sm:gap-4 gap-2 ">
                            <h2 className="font-semibold text-xl">
                                {selectedLanguage?.name == "English" ? "Refund Products Details" : translation[1668]?.value ? translation[1668]?.value : "Refund Products Details"}
                            </h2>
                            {isLoading ?
                                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                                </div>
                                :
                                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                    <p className="text-primary">
                                        {allCount ? allCount : 0}{" "}
                                        {allCount && allCount > 1
                                            ? selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                            : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                        }
                                    </p>
                                </div>
                            }
                        </div>
                        <div className="mb-00">
                            {loadingPermission ?
                                <Shimmer className={'w-44 lg:w-48'}>
                                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                                </Shimmer>
                                :
                                <div className='flex gap-2'>
                                    <LoginInput
                                        title=""
                                        placeholder={selectedLanguage?.name == "English" ? "Search product" : translation[1265]?.value ? translation[1265]?.value : "Search product"}
                                        padding="py-1"
                                        value={filterData?.text}
                                        onChange={(e) => {
                                            setFilterData({ ...filterData, text: e.target.value });
                                        }}
                                        leftIcon={<Svgs.Search />}
                                    />
                                    <PaginationDropdown
                                        title=''
                                        placeholder={selectedLanguage?.name == "English" ? "All Brand" : translation[398]?.value ? translation[398]?.value : "All Brand"}
                                        options={brands}
                                        value={filterData.brand_id}
                                        onChange={(e) => { setFilterData({ ...filterData, brand_id: e.target.value }) }}

                                        setPage={setBrandCurrentPage}
                                        setDropdownSearch={setBrandSearchText}
                                        page={brandCurrentPage}
                                        dropdownSearch={brandSearchText}
                                        lastPage={brandPaginationData?.total_pages}
                                        currentPage={brandPaginationData?.currentPage}
                                        loading={brandLoader}
                                    >
                                        {selectedLanguage?.name == "English" ? "All Brand" : translation[398]?.value ? translation[398]?.value : "All Brand"}
                                    </PaginationDropdown>
                                </div>
                            }
                        </div>
                    </div>
                    {/* <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
                        {loadingPermission ?
                            <Shimmer className={'w-12 lg:w-16'}>
                                <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                            </Shimmer>
                            : exportLoader ? (
                                <div className="h-[4rem] flex items-center justify-center">
                                    <PreloaderSm />
                                </div>
                            ) : !employee ? (
                                stockTransferList?.length > 0 ?
                                    <CSVLink {...csvReport} target="_blank" onClick={handleExportClick}>
                                        <IconButton filled>
                                            <Svgs.Export />
                                        </IconButton>
                                    </CSVLink> :
                                    <LoginBtn
                                        className="rounded-lg border-2 border-primary"
                                        bg="bg-white"
                                        animation={false}
                                        onClick={handleExportClick}
                                    >
                                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                                            <Svgs.Export bg="#FFFFFF" />
                                        </div>
                                    </LoginBtn>
                            ) : (
                                ""
                            )}
                    </div> */}
                </div>
                {isLoading
                    ? <TableShimer cols={5} rows={12} colsHeight={10} />
                    : <div className="overflow-x-auto ">
                        <div className="inline-grid xl:grid grid-cols-[repeat(7,minmax(155px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                            <p>{selectedLanguage?.name == "English" ? "Product" : translation[360]?.value ? translation[360]?.value : "Product"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Refunded Quantity" : translation[1454]?.value ? translation[1454]?.value : "Refunded Quantity"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Refunded Price" : translation[1453]?.value ? translation[1453]?.value : "Refunded Price"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Refund Type" : translation[1506]?.value ? translation[1506]?.value : "Refund Type"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Brand" : translation[836]?.value ? translation[836]?.value : "Brand"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</p>
                        </div>
                        {refunded_record?.length > 0
                            ? refunded_record?.map((product_obj, i) => {
                                return (
                                    <div key={product_obj.id} className={`odd-bg text-[#3A3A3A] transition-all`}>
                                        <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(7,minmax(155px,1fr))] min-w-full w-fit">
                                            <div className="h-full flex items-center">
                                                <p className="text-sm">{product_obj?.product_data?.product_name} </p>
                                            </div>

                                            <div className="flex items-center">
                                                <p className="text-sm line-clamp-1 pr-3">{product_obj?.refunded_quantity}</p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">{`${product_obj?.refunded_amount} ${location_currency}`}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">{product_obj?.refund_data?.refund_type}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">{`${product_obj?.refund_data?.client ? product_obj?.refund_data?.client : '--------'}`}
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <p className="text-sm">{product_obj?.product_data?.brand}</p>
                                            </div>

                                            <div className="flex items-center">
                                                <p className="text-sm">
                                                    {moment(product_obj?.created_at).format('DD-MM-YYYY')}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : <NoPurchaseOrderDataFound />
                        }

                        <Pagination
                            currentPage={currentPage}
                            totalCount={Math.ceil(pages)}
                            pageSize={10}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    </div>
                }
            </div>
        </>
    )
}

export default ProductRefundInvoice