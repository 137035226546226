import { CATEGORIES } from "../../ActionTypes/categoryTypes";


export const getCategories = (data) => {
    return {
        type: CATEGORIES.GET_CATEGORIES,
        payload: data
    }
}

export const getActionCategoriesDropdownList = (data) => {
    return {
        type: CATEGORIES.GET_CATEGORIES_DROPDOWNLIST,
        payload: data
    }
}
export const getActionCategoriesDropdownListPagination = (data) => {
    return {
        type: CATEGORIES.GET_CATEGORIES_DROPDOWNLIST_PAGINATION,
        payload: data
    }
}

export const getCategoriesWithNoPagination = (data) => {
    return {
        type: CATEGORIES.GET_CATEGORIES_WITH_NO_PAGINATION,
        payload: data
    }
}


export const addCategories = (data) => {
    return {
        type: CATEGORIES.ADD_CATEGORY,
        payload: data
    }
}

export const editCategroy = (data) => {
    return {
        type: CATEGORIES.UPDATE_CATEGORY,
        payload: data
    }
}


export const removeCategroy = (data) => {
    return {
        type: CATEGORIES.DELETE_CATEGORY,
        payload: data
    }
}