import Svgs from "Assets/svgs";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileAppointment from "../User/Profile/ProfileAppointment";
import { CLIENT_SIGNUP } from "Redux/NstyleRedux/ActionTypes/signupTypes";

const Header = ({ signin, next, noshadow, showSignin }) => {
  const dispatch = useDispatch()
  const state = useSelector(state => state);
  const clientDetail = state?.client_appointments?.client_detail;
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  next = getDataFromLocalStorage("next", next);
  signin = getDataFromLocalStorage("client_token");
  const navigate = useNavigate();
  const [ProfileToggle, setProfileToggle] = useState(false);

  return (
    <div className={!noshadow && 'shadow-md'}>
      <div className="nstyle-container mx-auto w-[95%]">
        <div className="flex items-center justify-between gap-4 py-3">
          <div
            className="flex items-center gap-3 w-fit cursor-pointer"
            onClick={() => {
              navigate(`${next}`);
            }}
          >
            <Svgs.LogoSvg />
            <h1 className="text-primary font-semibold text-2xl">NSTYLE</h1>
          </div>
          <div className="flex items-center gap-3">
            <div className="ml-2">
              {!signin ? (
                <div className="flex items-center gap-3">
                  {showSignin &&
                    <div onClick={() => {
                      navigate(`/auth/account-type`);
                    }}
                      className={"text-sm font-semibold !py-3 !px-5 cursor-pointer"}>
                      Signup
                    </div>
                  }
                  <LoginBtn
                    onClick={() => {
                      navigate(`/auth/account-type/?next=${next}`);
                    }}
                    text="Login"
                  />
                </div>
              ) : (
                <>
                  <div className="flex items-center gap-2">
                    <div>
                      <div className="h-[2.5rem] w-[2.5rem] rounded-full overflow-hidden ">
                        <img
                          src={(clientDetail?.length > 0 && clientDetail[0]?.image) ? clientDetail[0]?.image : `${process.env.PUBLIC_URL}/images/profile_img.svg`}
                          className="h-full w-full object-cover"
                        />
                      </div>
                    </div>
                    <div className="relative">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setProfileToggle(!ProfileToggle);
                        }}
                      >
                        <Svgs.Chevron_down size={"1.5rem"} />
                      </div>
                      {ProfileToggle && (
                        <>
                          <div className="fixed z-[10] inset-0" onClick={() => {
                            setProfileToggle(!ProfileToggle);
                          }}></div>
                          <div className="absolute top-full right-0 min-w-[12rem] z-[11]">
                            <div
                              className="mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                              role="menu"
                              aria-orientation="vertical"
                              aria-labelledby="menu-button"
                              tabindex="-1"
                            >
                              <div className="py-1" role="none">
                                <div
                                  className="hover:bg-gray-50 text-gray-700 block px-4 py-2 "
                                  role="menuitem"
                                  tabindex="-1"
                                >
                                  <p className="text-lg font-semibold cursor-pointer w-fit">{clientDetail?.length > 0 && clientDetail[0]?.full_name}</p>
                                </div>
                              </div>
                              <div className="py-1" role="none">
                                <div
                                  className="hover:bg-gray-50 cursor-pointer text-gray-700 px-4 py-2 text-sm flex items-center gap-2"
                                  role="menuitem"
                                  tabindex="-1"
                                  onClick={() => {
                                    navigate("/clients-appointments")
                                  }}
                                >
                                  <Svgs.Calendar />
                                  <p>My Appointments</p>
                                </div>
                                <p className="text-sm mt-2 px-4 text-[#5679FF] cursor-pointer w-fit"
                                  onClick={() => {
                                    localStorage.removeItem("client_token");
                                    localStorage.removeItem("client_id");
                                    localStorage.removeItem('loginData')
                                    localStorage.clear()
                                    // navigate(`/auth/account-type/?next=${next}`);
                                    next = next?.replaceAll('?', '&')
                                    window.location.href = `/auth/account-type/?next=${next}`
                                  }}>Logout</p>
                                <div
                                  className="block px-4 py-2 text-sm"
                                  role="menuitem"
                                  tabindex="-1"
                                >
                                  <div className="px-3 py-2 rounded-md bg-[#ECF3FF] border mt-[1.5rem] border-[#BAD3FC] text-xs text-center">For Business</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
