import { tenantDetail } from "Hooks/useTenant";

const { is_tenant, tenant } = tenantDetail();

const tenant_base_url = `${process.env.REACT_APP_SERVER_BASEURL_START}${tenant}.${process.env.REACT_APP_SERVER_BASEURL_END}`;
const nstyle_base_url = `${process.env.REACT_APP_SERVER_BASEURL_START}${process.env.REACT_APP_SERVER_BASEURL_END}`;

export const nstyle_BASE_URL = nstyle_base_url

export const BASE_URL = is_tenant ? tenant_base_url : nstyle_base_url;

export const api_base_url_tenant = `${tenant_base_url}`;

// BUSINESS URLs
export const update_business_additional_information =
  "/business/update_business_additional_information/";

// Brand URL
export const get_business_brands_url = "/product/get_brands/";
export const get_business_for_dropdown = "/product/get_brands_dropdown/";
export const create_business_brands_url = "/product/add_brand/";
export const update_business_brands_url = "/product/update_brand/";
export const delete_business_brands_url = "/product/delete_brand/";
export const get_Brand_import_url = "/product/import_brand/?file";

// products
export const get_product_stock_report_url =
  "/product/get_product_stock_report/";
export const get_Export_Prod_List = "/product/export_csv/";
export const get_products_url = "/product/get_products/";
export const get_products_main_page = "/product/get_products_main_page/";
export const get_products_detail = "/product/get_products/";
export const get_dashboard_products = "/product/get_top_products_dashboard/"
export const get_products_with_page = "/product/get_products";
export const get_products_for_dropdown = "/product/get_products_dropdown";
export const get_products_quick_sales = "/product/get_products_optimized";
export const get_order_stock_url = "/product/get_orderstock/";
export const add_product_url = "/product/add_product/";
export const delete_product_url = "/product/delete_product/";
export const update_product_url = "/product/update_product/";
export const search_product_url = "/product/search_product_url/";
export const get_import_url = "/product/import_product/";
export const create_order_stock_url = "/product/create_orderstock/";
export const update_order_stock_url = "/product/update_orderstock/";
export const delete_order_stock_url = "/product/delete_orderstock/";

// consumptions
export const get_consumptions_url = "/product/get_product_consumptions/";
export const add_consumptions_url = "/product/add_product_consumption/";
export const get_filtered_insight_products_url = "/product/get_filtered_insight_products/";
export const get_filtered_insight_chart_products_url = "/product/get_filtered_insight_chart_products/";
export const update_product_consumptions =
  "/product/update_product_consumptions/";
export const delete_product_consumptions =
  "/product/delete_product_consumption/";

//vedors
export const get_vendors = "/business/get_business_vendors/";
export const get_vendors_dropdown = "/business/get_business_vendors_dropdown/";
export const add_vendor = "/business/add_business_vendor/";
export const check_vendor_existance = "/business/check_vendor_existance/"
export const delete_vendor = "/business/delete_business_vendor/";
export const update_vendor = "/business/update_business_vendor/";
export const get_vendor_import_url = "/business/import_business_vendor/";
export const check_employee_availability = "/business/get_check_availability/";
export const assign_rendom_employee = "/business/get_employee_appointment/";
export const get_user_default_data = "/business/get_user_default_data/";
export const update_user_default_data = "/business/update_user_default_data/";
export const get_user_business_profile_completion_progress = "/business/get_user_business_profile_completion_progress/";

//category
export const get_categories = "/product/get_categories/";
export const get_categories_dropdown = "/product/get_categories_dropdown/";
export const add_categories = "/product/add_category/";
export const update_category = "/product/update_category/";
export const delete_category = "/product/delete_category/";
export const get_category_import_url = "/product/import_category/";

// STOCKS
export const get_stocks_url = "/product/get_stocks/";
export const delete_stocks_url = "/product/delete_stock/";
export const filter_stocks_url = "/product/filter_stock/";

// STOCKS TRANSFER
export const get_stock_transfer_list_url =
  "/product/get_product_stock_transfers";
export const delete_stock_transfer_url =
  "/product/delete_product_stock_transfer";
export const update_stock_transfer_url =
  "/product/update_product_stock_transfer/";
export const add_stock_transfer_stock = "/product/add_product_stock_transfer/";

// EMPLOYEE
export const get_employees_for_dropdown = "/employee/get_employees_dropdown/"
export const get_employee_url = "/employee/get_employees/";
export const get_employee_mainpage = "/employee/get_employees_mainpage/";
export const get_employee_With_page = "/employee/get_employees"
export const get_employee_dashboard = "/employee/get_employees_dashboard"
export const add_employee_url = "/employee/create_employee/";
export const delete_employee_url = "/employee/delete_employee/";
export const update_employee_url = "/employee/update_employee/";
export const get_single_employee = "/employee/get_single_employee/";
export const search_employee_url = "/employee/search_employee/";
export const get_scheme_data = "/employee/get_commission/";
export const send_scheme_data = "/employee/update_commision/";
export const get_employee_import = "/employee/import_employee/";
export const get_unique_employee_id = "/employee/generate_id/";
export const get_working_shedule = "/employee/get_workingschedule/";
export const delete_all__workingschedule = "/employee/delete_all__workingschedule/";
export const create_working_shedule = "/employee/create_workingschedule/";
export const update_working_shedule = "/employee/update_workingschedule/";
export const create_assets = "/employee/create_asset/";
export const get_assets = "/employee/get_asset/";
export const delete_asset = "/employee/delete_asset/";
export const update_asset = "/employee/update_asset/";
export const delete_working_schedule = "/employee/delete_workingschedule/";
export const create_break_time_url = "/employee/create-employee-brake-time/";
export const delete_break_time_url = "/employee/delete-employee-brake-time/";
export const update_break_time_url = "/employee/update-employee-brake-time/";

export const delete_lieu_day_url = "/employee/delete_lieu_day/";
export const get_employee_insights = "/appointment/get_employee_insights/"
export const get_daily_insights_of_employee = "/insights/employee_daily_insights/"

// VACATIONS
export const get_vacation_list_url = "/employee/get_vacation";
export const update_vacation_status_url = "/employee/update_vacation_status/"
export const add_vacation_url = "/employee/create_vacation/";
export const update_vacation_url = "/employee/update_vacation/";
export const delete_vacation_url = "/employee/delete_vacation/";

// ABSENCE
export const get_absence_list_url = "/employee/get_absence";
export const add_absence_url = "/employee/create_absence/";
export const update_absence_url = "/employee/update_absence/";
export const delete_absence_url = "/employee/delete_absence/";

// STAFF GROUP
export const get_staff_group_url = "/employee/get_staff_group/";
export const add_staff_group_url = "/employee/create_staff_group/";
export const delete_staff_group_url = "/employee/delete_staff_group/";
export const update_staff_group_url = "/employee/update_staff_group/";
export const import_staff_group = "/employee/import_staff_group/";
export const check_email_employees = "/employee/check_email_employees/";


// STAFF TARGET
export const get_staff_target_url = "/targetcontrol/get_stafftarget/";
export const add_staff_target_url = "/targetcontrol/create_stafftarget/";
export const copy_staff_target_url = "/targetcontrol/copy_stafftarget/";
export const delete_staff_target_url = "/targetcontrol/delete_stafftarget/";
export const update_staff_target_url = "/targetcontrol/update_stafftarget/";

// ATTENDACCE URL
export const get_attendance_url = "/employee/get_attendence/";
export const delete_attendance_url = "/employee/delete_attendence/";
export const add_attendance_url = "/";
export const update_attendance_url = "/";
export const import_attendance_url = "/employee/import_attendance/";

//PAYROOLS

export const get_payrool_url = "/employee/get_payrolls/";
export const delete_payrool_url = "/employee/delete_payroll/";
export const import_payrool_url = "/employee/import_payroll_csv/";
export const delete_language_url = "/business/delete_languages";
export const get_employee_working_payroll = "/employee/get_payrol_working";
export const get_employee_commission = "/employee/get_employee_commission";
export const get_employee_upsell_downsell_url = "/appointment/get_employee-up-and-downSale/";
export const get_up_and_down_sale_store_report = "/appointment/get_up_and_down_sale_report/";

//CLIENT
export const create_client_image = "/client/create_client_image/"
export const check_client_existance = "/client/check_client_existance/"
export const get_client_url = "/client/get_client/";
export const get_client_for_dropdown = "/client/get_client_dropdown/";
export const add_client_url = "/client/create_client/";
export const delete_client_url = "/client/delete_client/";
export const update_client_url = "/client/update_client/";
export const get_single_client_url = "/client/get_single_client/";
export const client_import_url = "/client/import_client/";
export const create_sub_program_url = "/client/create_subscription/";
export const get_sub_program_url = "/client/get_subscription/";
export const set_promo_url = "/client/create_promotion/";
export const get_promo_url = "/client/get_promotion/";
export const update_promo_url = "/client/update_promotion/";
export const delete_promotion_url = "/client/delete_promotion/";
export const get_service_url = "/service/get_services/";
export const get_service_optimizated = "/sale/get_service_optimized/";
export const get_service_for_dropdown = "/service/get_services_dropdown/";
export const add_reward_url = "/client/create_rewards/";
export const get_reward_url = "/client/get_rewards/";
export const get_voucher_url = "/client/get_vouchers/";
export const set_voucher_url = "/client/create_vouchers/";
export const delete_reward_url = "/client/delete_rewards/";
export const update_reward_url = "/client/update_rewards/";
export const update_Subcsription_url = "/client/update_subscription/";
export const delete_subscription_url = "/client/delete_subscription/";
export const update_voucher_url = "/client/update_vouchers/";
export const delete_voucher_url = "/client/delete_vouchers/";
export const get_member_url = "/client/get_memberships/";
export const create_member_url = "/client/create_memberships/";
export const delete_member_url = "/client/delete_memberships/";
export const update_member_url = "/client/update_memberships/";
export const get_unique_client_id = "/client/generate_id/";
export const get_client_complimentary = "/client/get_complimentary/";
export const get_client_package = "/client/get_client_package/";

//CLIENT GROUP
export const get_client_group_url = "/client/get_client_group/";
export const add_client_group_url = "/client/create_client_group/";
export const delete_client_group_url = "/client/delete_client_group/";
export const update_client_group_url = "/client/update_client_group/";

//CLIENT DISCOUNT PROMOTIONS
export const get_direct_or_flat_url = "/promotions/get_directorflat/";
export const get_discount_and_promotions_url = "/promotions/get_discount_and_promotions/";

export const add_direct_or_flat_url = "/promotions/create_directorflat/";
export const delete_direct_or_flat_url = "/promotions/delete_directorflat/";
export const update_direct_or_flat_url = "/promotions/update_directorflat/";
// service group
export const add_specific_group_discount_url =
  "/promotions/create_specificgroupdiscount/";
export const delete_specific_group_discount_url =
  "/promotions/delete_specificgroupdiscount/";
export const update_specific_group_discount_url =
  "/promotions/update_specificgroupdiscount/";
// purchase and get discount
export const add_purchase_discount_url = "/promotions/create_purchasediscount/";
export const delete_purchase_discount_url =
  "/promotions/delete_purchasediscount/";
export const update_purchase_discount_url =
  "/promotions/update_purchasediscount/";
// specific Brand and Service Group
export const add_specific_brandservice_discount_url =
  "/promotions/create_specificbrand_discount/";
export const delete_specific_brandservice_discount_url =
  "/promotions/delete_specificbrand_discount/";
export const update_specific_brandservice_discount_url =
  "/promotions/update_specificbrand_discount/";
// free item if you spend some amount
export const add_spend_someamount_discount_url =
  "/promotions/create_spend_some_amount/";
export const delete_spend_someamount_discount_url =
  "/promotions/delete_spend_some_amount/";
export const update_spend_someamount_discount_url =
  "/promotions/update_spend_some_amount/";
// get an item for a fixed price or in the group
export const add_fixed_price_service_discount_url =
  "/promotions/create_fixed_price_service/";
export const delete_fixed_price_service_discount_url =
  "/promotions/delete_fixed_price_service/";
export const update_fixed_price_service_discount_url =
  "/promotions/update_fixed_price_service/";
export const add_free_service_discount_url = "/promotions/create_free_service/";
export const delete_free_service_discount_url =
  "/promotions/delete_free_service/";
export const update_free_service_discount_url =
  "/promotions/update_free_service/";
// Discount on bundle discount
export const add_bundle_discount_url = "/promotions/create_bundle_fixed_price/";
export const delete_bundle_discount_url =
  "/promotions/delete_bundle_fixed_price/";
export const update_bundle_discount_url =
  "/promotions/update_bundle_fixed_price/";
// Promotion on buy retail and get free services
export const add_retail_getservice_url =
  "/promotions/create_retail_get_service/";
export const delete_retail_getservice_url =
  "/promotions/delete_retail_get_service/";
export const update_retail_getservice_url =
  "/promotions/update_retail_get_service/";
// Discount on Add User Restricted Discount
export const add_user_restricted_discount_url =
  "/promotions/create_user_restricted_discount/";
export const delete_user_restricted_discount_url =
  "/promotions/delete_user_restricted_discount/";
export const update_user_restricted_discount_url =
  "/promotions/update_user_restricted_discount/";
// Discount on Add User Restricted Discount
export const add_complimentary_discount_url =
  "/promotions/create_complimentrydiscount/";
export const delete_complimentary_discount_url =
  "/promotions/delete_complimentrydiscount/";
export const update_complimentary_discount_url =
  "/promotions/update_complimentrydiscount/";
// Discount on Package
export const add_package_discount_url = "/promotions/create_packagesdiscount/";
export const delete_package_discount_url =
  "/promotions/delete_packagesdiscount/";
export const update_package_discount_url =
  "/promotions/update_packagesdiscount/";
// Discount on Coupons
export const add_coupons_discount_url = "/promotions/create_couponsdiscount/";
export const delete_coupons_discount_url =
  "/promotions/delete_couponsdiscount/";
export const update_coupons_discount_url =
  "/promotions/update_couponsdiscount/";
// Country
export const country_code_url = "/country_code";

//APOINTMENTS

export const get_appointment_url = "/appointment/get_calendar_appointment/";
export const get_all_appointments_url = "/appointment/get_all_appointments/";
export const get_recent_ten_appointments_url = "/appointment/get_recent_ten_appointments/";
export const get_today_appointments_url =
  "/appointment/get_today_appointments/";
export const create_appointment = "/appointment/create_appointment/";
export const create_group_appointment = "/appointment/create_group_appointment/";
export const update_get_appointment_url = "/appointment/update_appointment/";
export const get_single_appointments_url =
  "/appointment/get_single_appointments/";
export const paid_unpaid_appointments = "/appointment/paid_unpaid_appointments/";
export const update_appointment_url = "/appointment/update_appointment/";
export const cancel_appointment_url = "/appointment/cancel_appointment/";
export const appointment_service_status_update = "/appointment/appointment_service_status_update/";
export const appointment_check_in = "/appointment/appointment_check_in/";
export const group_appointment_check_in = "/appointment/group_appointment_check_in/";
export const create_checkout_url = "/appointment/create_checkout/";
export const create_blockTime_url = "/appointment/create_blockTime/";
export const update_blockTime_url = "/appointment/update_block_time/";
export const get_appointments_service = "/appointment/get_appointments_service/";
export const get_appointments_group_services = "/appointment/get_appointments_group_services/";
export const update_appointment_detail =
  "/appointment/update_appointment_service/";
export const get_employees_for_selected_service =
  "/appointment/get_employees_for_selected_service/";
export const get_employee_check_time = "/appointment/get_employee_check_time/";
export const get_employee_check_availability_list =
  "/appointment/get_employee_check_availability_list/";
export const get_appointment_log = "/appointment/get_appointment_logs/"
export const create_missed_opportunity = "/appointment/create_missed_opportunity/";
export const get_missed_opportunity = "/appointment/missed_opportunities/";
export const create_reversal = "/appointment/create_reversal/";
export const get_all_reversal_url = "/appointment/get_reversal_logs/";
export const update_reversal_status = "/appointment/update_reversal/";
// Create & Get Comment 
export const create_comment = "/client/create_comment/";
export const get_comment = "/client/get_comment/";

// Create & Get Comment 
export const create_employee_comment = "/employee/create-employee-comment/";
export const get_employee_comment = "/employee/get-employee-comment-list/";

//Sales

export const create_service_url = "/sale/create_service/";
// export const get_sale_service_url = "/sale/get_service/";
export const get_sale_service_url = "/sale/get_service_main_page/";
export const get_service_detail = "/sale/get_service/";
export const delete_sale_service_url = "/sale/delete_service/";
export const update_sale_service_url = "/sale/update_service/";
export const create_order_url = "/sale/create_sale_order/";
export const create_new_checkout = "/sale-records/checkout/";

export const get_sale_order_product = "/sale/get_product_orders/";
export const get_sale_order_service = "/sale/get_service_orders/";
export const get_sale_order_membership = "/sale/get_membership_orders/";
export const get_sale_order_voucher = "/sale/get_voucher_orders/";
// export const get_all_sale_order = "/sale/get_all_sale_orders/";
export const get_all_sale_order = "/sale/get_all_sale_orders_optimized/";

export const get_single_invoice = "/sale/get_all_sale_orders/";
export const get_all_sale_order_updated_url = "/sale-records/sale-history/";
export const get_single_invoice_updated_url = "/sale-records/single-sale-record/";
// export const get_group_services = "/sale/get_servicegroup/";
export const get_group_services = "/sale/get_servicegroup_main_page/";
export const get_group_services_detail = "/sale/get_servicegroup/";
export const get_group_services_optimized = "/sale/get_servicegroup_optimized/";
export const delete_servicegroup = "/sale/delete_servicegroup/";
export const update_Group_service_url = "/sale/update_servicegroup/";

export const delete_block_time = "/appointment/delete_block_time/";
export const get_service_employee = "/appointment/get_service_employee/";
export const get_client_sales = "/appointment/get_client_sale/";
export const get_location_tax = "/sale/get_location_tax/";

// purchase order
export const get_purchase_order_list = "/product/get_orderstock/";
export const create_orderstock = "/product/create_orderstock/";
export const delete_purchase_order = "/product/delete_orderstock/";
export const update_orderstock_status = "/product/update_orderstockproduct/";
export const update_orderstock = "/product/update_orderstock/";

// Get curreny
export const get_currency_url = "/utility/get_all_currencies/";
export const create_servicegroup = "/sale/create_servicegroup/";

// Dashboard
export const get_dashboard_data_on_the_base_of_address =
  "/Dashboard/get_busines_client_appointment/";

// loyalty points
export const create_loyalty_point = "/client/create_loyalty/";
export const get_loyalty_points = "/client/get_loyalty/";
export const update_loyalty_point = "/client/update_loyalty/";
export const delete_loyalty_point = "/client/delete_loyalty/";
export const get_client_loyalty_points = "/client/get_client_available_loyalty_points/"
export const get_client_checkout_vouchers = "/client/get_client_all_vouchers/"
export const get_client_checkout_memberships = "/client/get_client_all_memberships/"
export const get_single_coupon_data = "/promotions/get_coupon/"
export const get_client_checkout_giftCard_url = "/client/get_client_all_gift_cards/"
export const pay_single_installment = "/sale-records/pay-installment/"

// Client CashBack
export const create_cashback = "/my-wallet/create-cashback/";
export const get_cashback = "/my-wallet/get-cashback/";
export const update_cashback = "/my-wallet/update-cashback/";
export const delete_cashback = "/my-wallet/delete-cashback/";

//  Loyality Points Logs
export const get_customers_loyalty_points_logs = "/client/get_customers_loyalty_points_logs/"
// export const get_customer_detailed_loyalty_points_logs = "/client/get_customer_detailed_loyalty_points/"
export const get_customer_detailed_loyalty_points_logs = "/client/get_customer_detailed_loyalty_points_list/"
export const get_customer_detailed_loyalty_points_logs_detail = "/client/get_customer_detailed_loyalty_points_detail/"
// export const get_client_available_loyalty_points = "/get_client_available_loyalty_points/"


//commission
export const create_commission = "/employee/create_commission/";
export const get_commission = "/employee/get_commission/";
export const get_commission_with_page = "/employee/get_commission";
export const update_commission = "/employee/update_commision/";
export const delete_commission = "/employee/delete_commission/";

//store target
export const create_store_target = "/targetcontrol/create_storetarget/";
export const get_store_target = "/targetcontrol/get_storetarget/";
export const update_store_target = "/targetcontrol/update_storetarget/";
export const delete_store_target = "/targetcontrol/delete_storetarget/";

//service target
export const copy_service_target = "/targetcontrol/copy_servicetarget/";
export const create_service_target = "/targetcontrol/create_servicetarget/";
export const get_service_target = "/targetcontrol/get_servicetarget/";
export const update_service_target = "/targetcontrol/update_servicetarget/";
export const delete_service_target = "/targetcontrol/delete_servicetarget/";

//retail target
export const create_retail_target = "/targetcontrol/create_retailtarget/";
export const get_retail_target = "/targetcontrol/get_retailtarget/";
export const update_retail_target = "/targetcontrol/update_retailtarget/";
export const delete_retail_target = "/targetcontrol/delete_retailtarget/";
export const copy_retail_target = "/targetcontrol/copy_retailtarget/";

// reports
export const get_commission_reports_by_staff =
  "/reports/get_commission_reports_by_staff/";
export const get_commission_reports_by_commission_details =
  "/reports/get_commission_reports_by_commission_details/";
// STAFF TARGET REPORT KPI
export const get_staff_target_report_url = "/reports/get_reports_staff_target/";
// STORE TARGET REPORT KPI
export const get_store_target_report_url = "/reports/get_store_target_report/";
// SERVICE TARGET REPORT KPI
export const get_service_target_report_url =
  "/reports/get_service_target_report/";
// RETAIL TARGET REPORT KPI
export const get_retail_target_report_url =
  "/reports/get_retail_target_report/";

  // BOOKING REPOTS
export const get_appointment_analytics =
  "/reports/get_appointment_analytics/";

// export const get_promotions_and_discounts_reports =
//   "/reports/get_promotions_and_discounts_sales";
export const get_promotions_and_discounts_reports =
  "/reports/get_promotions_and_discounts_sales_list";
export const get_promotions_and_discounts_reports_detail =
  "/reports/get_promotions_and_discounts_sales_detail";

//get business location in Nstyle app
//appointment book from client side
export const get_business_location_by_domain =
  "/business/get_domain_business_address/";
export const employee_availability = "/business/employee_availability/";
export const get_selected_locataion_tax = "/business/get_tenant_address_taxes/";
export const get_locataion_tax_for_clients =
  "/business/get_tenant_business_taxes/";
export const get_common_tenant_detail = "/business/get_common_tenant/";
export const check_employee_existance = "/employee/check_employee_existance"

export const create_client_appointment =
  "/appointment/create_appointment_client/";

export const update_appointment_client = "/customer/update_appointment_client/";
export const cancel_client_appointment = "/customer/cancel_appointment_client/";
export const create_business_client = "/customer/create_client_business/";
export const verification_business_client = "/customer/customer_verify_otp/";
export const customer_login = "/customer/customer_login/";
export const get_customer_appointment = "/customer/get_client_appointment/";
export const get_client_auto_generate_id = "/customer/generate_id_client/";
export const get_client_details = "/customer/get_client_detail/";

// employee set password
export const employee_set_password = "/employee/set_password/";

// Invoices Translation
export const add_invoice_tanslation_url = '/multilanguage/add_invoiceTranslation/'
export const get_invoice_tanslation_url = '/multilanguage/get_invoiceTranslation/'
export const update_invoice_tanslation_url = '/multilanguage/update_invoiceTranslation/'
export const delete_invoice_tanslation_url = '/multilanguage/delete_invoiceTranslation/'

// help center Module
export const help_center_url = 'https://us-telecoms.com/api/v1/help/get_comment/'
export const help_center_detail_url = 'https://us-telecoms.com/api/v1/help/get_comment_details/'

export const get_language_translation_url = "https://us-telecoms.com/api/v1/multilanguage/get_language_translation/"




// CRM -> Segmentation
export const create_segment_url = '/CRM/segment-create/'
export const get_segment_url = '/CRM/segment-list'
export const get_single_segment_url = '/CRM/segment'
export const delete_segment_url = '/CRM/segment-delete'
export const update_segment_url = '/CRM/segment-update'
export const get_client_for_CRM = "/CRM/client-filter/";
export const get_segment_dropdown_url = '/CRM/segment-dropdown'

// CRM -> Campaign
export const create_campaign_url = '/CRM/campaign-create/'
export const get_campaign_url = '/CRM/campaign-list'
export const get_single_campaign_url = '/CRM/campaign'
export const delete_campaign_url = '/CRM/campaign-delete'
export const update_campaign_url = '/CRM/campaign-update'

// HRM -> Holiday
export const create_holiday_url = '/HRM/holiday-create/'
export const get_holiday_url = '/HRM/holiday-list/'
export const get_single_holiday_url = '/HRM/holiday/'
export const delete_holiday_url = '/HRM/holiday-delete'
export const update_holiday_url = '/HRM/holiday-update'

// Refunds
export const create_refund_url = '/finance/refunds/'
export const get_refund_report_url = '/finance/get-all-refunds/'
export const check_refund_permission_url = '/finance/check-refund-permission/'

export const create_refund_time_url = '/finance/refund-permission/'
export const update_refund_time_url = '/finance/refund-permission/'
export const delete_refund_time_url = '/finance/refund-permission/'
export const get_refund_time_url = '/finance/refund-permission/'


// Gift Cards 

export const create_gift_card_url = "/employee/giftcards/"
export const get_gift_card_url = "/employee/get_gift_card/"
export const update_gift_card_url = "/employee/update_gift_card/"
export const get_detail_from_code_url = "/employee/get_detail_from_code/"
// export const create_refund_time_url = '/promotions/create_refund/'
// export const update_refund_time_url = '/promotions/update_refund/'
// export const delete_refund_time_url = '/promotions/delete_refund/'
// export const get_refund_time_url = '/promotions/get_refund/'


// CRM
// Deals
export const get_all_deals = '/deal/get_all_deals/'
export const delete_single_deal = '/deal/delete_single_deal/'
export const get_single_deal = '/deal/get_single_deal'
export const create_deal = '/deal/create_deal/'
export const update_deal = '/deal/update_deal/'
export const get_deal_restrictions = '/deal/get_deal_restrictions/'
export const get_deal_types = '/deal/get_deal_type_choices/'
export const get_deal_categories = '/deal/get_deal_category/'
export const get_deal_channels = '/deal/get_redeemable_channels/'
export const get_deal_validity = '/deal/get_deal_validity/'
export const upload_deal_restrictions = '/restrictions'
export const get_deal_brands = '/brand'
export const get_deal_locations = '/locations'
export const get_deal_settings = '/deals/settings/'
export const get_deal_items = '/items/'



// POS ANALYTICS 
// sales analytics 
export const get_sales_analytics = '/reports/get_analytics_optimizedsale-record/'
export const get_product_pos_analytics_url = '/reports/get_product_pos_analytics/'

// sales analytics 
export const get_customer_analytics = '/reports/get_customer_analytics/'

// finance analytics 
export const get_finance_analytics = '/reports/get_finance_analytics/'

// payment methods report
export const get_payment_method_reports = '/reports/get_payment_method_reports/'


// Employee Skill Training 
export const create_employee_skill_url = '/employee/create_employee_skills/'
export const get_employee_skills_url = '/employee/get_employee_skills'

// Employee Training 
export const create_employee_training_url = '/employee/create_employee_training/'
export const update_employee_training_url = '/employee/update_employee_training/'
export const get_employee_training_url = '/employee/get_employee_training/'
export const delet_employee_training_url = '/employee/create_employee_skills/'

// https://men-saloon.us-telecoms.com/api/v1/employee/create_employee_training/?crunt_location=62d55e45-be43-4e69-854b-7b57578a5e5e

// Expense
export const add_expense_category_url = '/expense/create_expense_category/'
export const get_expense_category_url = '/expense/get_expense_category/'
export const delete_expense_category_url = '/expense/delete_expense_category/'
export const update_expense_category_url = '/expense/update_expense_category/'

export const create_expense_url = '/expense/create_expense/'
export const get_expense_url = '/expense/get_expense/'
export const update_expense_url = '/expense/update_expense/'
export const delete_expense_url = '/expense/delete_expense/'

export const create_expense_payment_url = '/expense/create_expense_payment/'
export const get_expense_payment_url = '/expense/get_expense_payment/'
export const update_expense_payment_url = '/expense/update_expense_payment/'
export const delete_expense_payment_url = '/expense/delete_expense_payment/'
export const get_expense_analytics_url = '/expense/get_expense_analytics/'