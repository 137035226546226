import axios from "axios";

import {
  BASE_URL,
  create_working_shedule,
  create_break_time_url,
  delete_lieu_day_url,
  delete_working_schedule,
  update_working_shedule,
  delete_break_time_url,
  update_break_time_url,
} from "Adapters/Api/api_variables";

export const handleDeleteShift = async (data, setDeleteSlot, access_token) => {
  try {
    const result = await axios.delete(`${BASE_URL}${delete_working_schedule}`, {
      headers: {
        Authorization: `Token ${access_token}`,
      },
      data,
    });
    setDeleteSlot((prev) => ({
      ...prev,
      show: false,
      isSubmit: false,
    }));
    return result;
  } catch (error) {
    return error;
  }
};
export const handleDeleteBreakTime = async (data, setBreakTimeDeleteSlot, access_token) => {
  try {
    const result = await axios.delete(`${BASE_URL}${delete_break_time_url}?id=${data}`, {
      headers: {
        Authorization: `Token ${access_token}`,
      },
    });
    setBreakTimeDeleteSlot((prev) => ({
      ...prev,
      show: false,
      isSubmit: false,
    }));
    return result;
  } catch (error) {
    return error;
  }
};

export const handleDeleteLIEUDay = async (data, setDeleteSlot, access_token) => {
  try {
    const result = await axios.delete(`${BASE_URL}${delete_lieu_day_url}?id=${data}`, {
      headers: {
        Authorization: `Token ${access_token}`,
      },
    });
    setDeleteSlot((prev) => ({
      ...prev,
      show: false,
      isSubmit: false,
    }));
    return result;
  } catch (error) {
    return error;
  }
};

export const handleSubmitEmployeeSlot = async (
  data,
  access_token,
  handleClose,
  setIsLoading
) => {
  try {
    const result = await axios.post(
      `${BASE_URL}${create_working_shedule}`,
      data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    setIsLoading(false);

    handleClose();
    return result;
  } catch (error) {
    return error;
  }
};
export const create_breaktime = async (data, access_token, handleClose, setIsLoading) => {
  try {
    const result = await axios.post(`${BASE_URL}${create_break_time_url}`, data, {
      headers: { Authorization: `Token ${access_token}` },
    });
    setIsLoading(false);

    handleClose();
    return result;
  } catch (error) {
    return error;
  }
};
export const update_breaktime = async (data, access_token, handleClose, setIsLoading) => {
  try {
    const result = await axios.put(`${BASE_URL}${update_break_time_url}?id=${data?.schedule_id}`, data, {
      headers: { Authorization: `Token ${access_token}` },
    });
    setIsLoading(false);

    handleClose();
    return result;
  } catch (error) {
    return error;
  }
};

export const handleUpdateEmployeeSlot = async (
  data,
  access_token,
  handleClose,
  setIsLoading
) => {
  try {
    const result = await axios.put(
      `${BASE_URL}${update_working_shedule}`,
      data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    setIsLoading(false);

    handleClose();
    return result;
  } catch (error) {
    return error;
  }
};
