
const initialState = {
    loading: false
};

const LoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOADER':
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
};

export default LoaderReducer;
