import { OPENING_TIME } from 'Constants/Data/OpeningHours';
import moment from 'moment';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

function useAppointmentErrorHandling(appointmentData) {
    const state = useSelector(state => state)
    const dispatch = useDispatch();
    const [booking_error, setBookingError] = useState()
    const [service_future_error, setFutureError] = useState()

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    useEffect(() => {
        if (appointmentData?.client_can_book || appointmentData?.appointment_time) {
            let date = new Date();
            const { appointment_time, appointment_date } = appointmentData;
            if (appointmentData?.client_can_book === "5_Hours_Before") {
                date.setDate(date.getDate());
                date.setHours(date.getHours() + 5)
                let short_date = date.toLocaleDateString();
                let formate_current_date = moment(short_date, "DD/MM/YYYY").format('YYYY-MM-DD')
                let app_date = moment(appointment_date).format('YYYY-MM-DD')
                let time_formate = moment(date).format('HH:mm:ss')

                let test = OPENING_TIME?.find((tm) => tm?.value >= time_formate)
                let res = OPENING_TIME?.find((tm) => tm?.value === appointmentData?.appointment_time)
                let user_ind = OPENING_TIME.indexOf(res)
                let app_ind = OPENING_TIME.indexOf(test)
                if ((app_date == formate_current_date) && (user_ind < app_ind)) {
                    setBookingError(selectedLanguage?.name == "English" ? "You can book this service 5 hours before the start time" : translation[1229]?.value ? translation[1229]?.value : "You can book this service 5 hours before the start time")
                    dispatch({
                        type: "ADD_HOURS_ERRORS",
                        payload: { id: appointmentData?.index, error: "You can book this service 5 hours before the start time" }
                    })
                } else {
                    setBookingError()
                    dispatch({
                        type: "REMOVE_HOURS_ERRORS",
                        payload: { id: appointmentData?.index }
                    })
                }
            }


            if (appointmentData?.client_can_book === "24_Hours_Before") {
                date.setDate(date.getDate() + 1);
                date.setHours(date.getHours())
                let short_date = date.toLocaleDateString();
                let formate_current_date = moment(short_date, "DD/MM/YYYY").format('YYYY-MM-DD')
                let app_date = moment(appointment_date).format('YYYY-MM-DD')
                let time_formate = moment(date).format('HH:mm:ss')
                let test = OPENING_TIME?.find((tm) => tm?.value === appointmentData?.appointment_time)
                let user_ind = OPENING_TIME.indexOf(test)
                let currentTimeFormat = moment(date).format('h:mm A');
                let res = OPENING_TIME?.find((tm) => tm?.value >= time_formate)
                let app_ind = OPENING_TIME.indexOf(res)
                if (app_date <= formate_current_date) {

                    if (app_date < formate_current_date) {
                        setBookingError(selectedLanguage?.name == "English" ? "You can book this service 24 hours before the start time" : translation[1230]?.value ? translation[1230]?.value : "You can book this service 24 hours before the start time")
                        dispatch({
                            type: "ADD_HOURS_ERRORS",
                            payload: { id: appointmentData?.index, error: "You can book this service 24 hours before the start time" }
                        })
                    }
                    else {
                        if ((app_date == formate_current_date) && (user_ind < app_ind) && (res?.label < currentTimeFormat)) {
                            setBookingError(selectedLanguage?.name == "English" ? "You can book this service 24 hours before the start time" : translation[1230]?.value ? translation[1230]?.value : "You can book this service 24 hours before the start time")
                            dispatch({
                                type: "ADD_HOURS_ERRORS",
                                payload: { id: appointmentData?.index, error: "You can book this service 24 hours before the start time" }
                            })
                        } else {
                            setBookingError()
                            dispatch({
                                type: "REMOVE_HOURS_ERRORS",
                                payload: { id: appointmentData?.index }
                            })
                        }
                    }
                } else {
                    setBookingError()
                    dispatch({
                        type: "REMOVE_HOURS_ERRORS",
                        payload: { id: appointmentData?.index }
                    })
                }

            }


            if (appointmentData?.client_can_book === "36_Hours_Before") {

                date.setDate(date.getDate() + 3);
                date.setHours(date.getHours())
                let short_date = date.toLocaleDateString();
                let formate_current_date = moment(short_date, "DD/MM/YYYY").format('YYYY-MM-DD')
                let app_date = moment(appointment_date).format('YYYY-MM-DD')
                let time_formate = moment(date).format('HH:mm:ss')

                let select_time = OPENING_TIME?.find((tm) => tm?.value >= time_formate)
                let app_ind = OPENING_TIME.indexOf(select_time)

                let test = OPENING_TIME?.find((tm) => tm?.value === appointmentData?.appointment_time)
                let user_ind = OPENING_TIME.indexOf(test)

                let futureTimeFormat = moment().add(36, 'hours').format('h:mm A');
                let futureDateFormat = moment().add(36, 'hours').format('YYYY-MM-DD');

                let selectedTime = moment(test?.label, 'h:mm A');
                let TimeAllowedForBooking = moment(futureTimeFormat, 'h:mm A');
                if (app_date <= futureDateFormat) {
                    if (app_date < futureDateFormat) {
                        setBookingError(selectedLanguage?.name == "English" ? "You can book this service 36 hours before the start time" : translation[1231]?.value ? translation[1231]?.value : "You can book this service 36 hours before the start time")
                        dispatch({
                            type: "ADD_HOURS_ERRORS",
                            payload: { id: appointmentData?.index, error: "You can book this service 36 hours before the start time" }
                        })
                    } else {
                        console.log('inside else')
                        if ((app_date == futureDateFormat) && (user_ind < app_ind) && (selectedTime?.isBefore(TimeAllowedForBooking))) {
                            setBookingError(selectedLanguage?.name == "English" ? "You can book this service 36 hours before the start time" : translation[1231]?.value ? translation[1231]?.value : "You can book this service 36 hours before the start time")
                            dispatch({
                                type: "ADD_HOURS_ERRORS",
                                payload: { id: appointmentData?.index, error: "You can book this service 36 hours before the start time" }
                            })
                        } else {
                            setBookingError()
                            dispatch({
                                type: "REMOVE_HOURS_ERRORS",
                                payload: { id: appointmentData?.index }
                            })
                        }
                    }
                } else {
                    setBookingError()
                    dispatch({
                        type: "REMOVE_HOURS_ERRORS",
                        payload: { id: appointmentData?.index }
                    })
                }
            }

        }
    }, [appointmentData?.client_can_book, appointmentData?.appointment_time, appointmentData?.appointment_date])



    useEffect(() => {
        let date = new Date();
        const { appointment_time, appointment_date } = appointmentData;
        if (appointmentData?.slot_availible_for_online === "No_More_Than_1_Months_In_The_Future") {
            date.setDate(date.getDate() + 30);
            date.setHours(date.getHours())
            let short_date = date.toLocaleDateString();
            let formate_current_date = moment(short_date).format('YYYY-MM-DD')
            let app_date = moment(appointment_date).format('YYYY-MM-DD')


            if (formate_current_date < app_date) {
                setFutureError(selectedLanguage?.name == "English" ? "you can not book this appointment for more than 1 month in the future" : translation[1232]?.value ? translation[1232]?.value : "you can not book this appointment for more than 1 month in the future")
                dispatch({
                    type: "ADD_FUTURE_ERRORS",
                    payload: { id: appointmentData?.index, error: "you can not book this appointment for more than 1 month in the future" }
                })
            } else {
                setFutureError()
                dispatch({
                    type: "REMOVE_FUTURE_ERRORS",
                    payload: {
                        id: appointmentData?.index,
                    }
                })

            }

        }


        if (appointmentData?.slot_availible_for_online === "No_More_Than_2_Months_In_The_Future") {
            date.setDate(date.getDate() + 60);
            date.setHours(date.getHours())
            let short_date = date.toLocaleDateString();
            let formate_current_date = moment(short_date).format('YYYY-MM-DD')
            let app_date = moment(appointment_date).format('YYYY-MM-DD')

            if (formate_current_date < app_date) {
                setFutureError(selectedLanguage?.name == "English" ? "you can not book this appointment for more than 2 month in the future" : translation[1233]?.value ? translation[1233]?.value : "you can not book this appointment for more than 2 month in the future")
                dispatch({
                    type: "ADD_FUTURE_ERRORS",
                    payload: { id: appointmentData?.index, error: "you can not book this appointment for more than 2 month in the future" }
                })
            } else {
                setFutureError()
                dispatch({
                    type: "REMOVE_FUTURE_ERRORS",
                    payload: {
                        id: appointmentData?.index,
                    }
                })
            }
        }


        if (appointmentData?.slot_availible_for_online === "No_More_Than_3_Months_In_The_Future") {
            date.setDate(date.getDate() + 90);
            date.setHours(date.getHours())
            let short_date = date.toLocaleDateString();
            let formate_current_date = moment(short_date).format('YYYY-MM-DD')
            let app_date = moment(appointment_date).format('YYYY-MM-DD')
            if (formate_current_date < app_date) {
                setFutureError(selectedLanguage?.name == "English" ? "you can not book this appointment for more than 3 month in the future" : translation[1234]?.value ? translation[1234]?.value : "you can not book this appointment for more than 3 month in the future")
                dispatch({
                    type: "ADD_FUTURE_ERRORS",
                    payload: { id: appointmentData?.index, error: "you can not book this appointment for more than 3 month in the future" }
                })
            } else {
                setFutureError()
                dispatch({
                    type: "REMOVE_FUTURE_ERRORS",
                    payload: {
                        id: appointmentData?.index,
                    }
                })
            }
        }


    }, [appointmentData?.slot_availible_for_online, appointmentData?.appointment_date])


    return {
        booking_error,
        service_future_error,
        setBookingError
    }
}

export default useAppointmentErrorHandling