import React from 'react'

const PreloaderFull = () => {
    return (
        <div className='fixed inset-0 z-[100] flex justify-center items-center bg-[#000000B8]' >
            <div className="preloader-wrapper">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default PreloaderFull