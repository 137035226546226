import React from 'react'
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb'
import LocationSelection from "new-components/location";
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import ExpenseTable from './ExpenseTable';
import moment from 'moment';
import Svgs from 'Assets/svgs';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import ExpenseFilterPopup from './Popups/FilterPopup';
import AddExpenseCategoryPopup from './Popups/AddExpenseCategoryPopup';
import AddExpensePopup from './Popups/AddExpensePopup';
import useExpense from './helper';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import ExpenseTableCategory from './ExpenseCategoryTable';


const Expenses = () => {
    const {
        startDate, setStartDate, endDate, setEndDate, toggleDateRange, setToggleDateRange, FilterPopup, setFilterPopup, addCategoryPopup, setAddCategoryPopup, addExpensePopup,
        setAddExpensePopup, translation, selectedLanguage, expenses, loader, getAllExpenses, onExpenseDelete, deletePopup, setDeletePopup, deleteLoading, isEdit, setIsEdit,
        editData, setEditData, expenseFilter, setExpenseFilter, expenseCategory, expensePagination, currentPage, setCurrentPage, activeTab, setActiveTab, setExpenseCategoryCurrentPage,
        expenseCategoryLoader, getExpensesCategories, expenseCategoryDeleteLoading, onExpenseCategoryDelete, expenseCategoryPagination, expenseCategoryCurrentPage,
        setExpenseCategoryLoader,getAllExpensesCategories,allExpenseCategory
    } = useExpense()

    return (
        <>
            <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
                <Breadcrumb
                    first={selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
                    second={selectedLanguage?.name == "English" ? "Expenses" : translation[1603]?.value ? translation[1603]?.value : "Expenses"}
                    url={'/dashboard/account/'}
                />
                <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
                    <div className="flex items-center sm:gap-4 gap-2">

                        <h2 className="text-primary font-bold lg:text-3xl text-xl">
                            {selectedLanguage?.name == "English" ? "Expenses" : translation[1603]?.value ? translation[1603]?.value : "Expenses"}
                        </h2>
                        {activeTab == "Expense" &&
                        <LocationSelection />
                        }
                    </div>
                    <div className="flex justify-end gap-4 w-full sm:w-auto">
                        <LoginBtn
                            className={'!capitalize'}
                            text={selectedLanguage?.name == "English" ? "Add Expense" : translation[1750]?.value ? translation[1750]?.value : "Add Expense"}
                            onClick={() => { setAddExpensePopup(!addExpensePopup) }}
                        />
                        <LoginBtn
                            className={'!capitalize'}
                            text={selectedLanguage?.name == "English" ? "Add Category" : translation[887]?.value ? translation[887]?.value : "Add Category"}
                            onClick={() => {
                                setAddCategoryPopup(!addCategoryPopup)
                            }}
                        />
                    </div>
                </div>
                <div className="border-b mt-[3rem]">
                    <div className="flex items-center gap-6">
                        <div className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${activeTab == "Expense" && "font-semibold border-primary"
                            }`}
                            onClick={() => {
                                setActiveTab("Expense");
                            }}
                        >
                            <h2 className="text-[#595A5A]">
                                {selectedLanguage?.name == "English" ? "Expenses" : translation[1603]?.value ? translation[1603]?.value : "Expenses"}
                            </h2>
                        </div>
                        <div
                            className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${activeTab == "Expense Category" && "font-semibold border-primary"
                                }`}
                            onClick={() => {
                                setActiveTab("Expense Category");
                            }}
                        >
                            <h2 className="text-primary">
                                {selectedLanguage?.name == "English" ? "Expense Category" : translation[1790]?.value ? translation[1790]?.value : "Expense Category"}
                            </h2>
                        </div>
                    </div>
                </div>

                {activeTab == "Expense" &&
                    <div className="border rounded-lg mt-[3rem]">
                        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
                            <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                                <div className="flex items-center lg:gap-4 gap-3">
                                    <div className="flex items-center sm:gap-4 gap-2 ">
                                        <h2 className="font-semibold text-xl">
                                            {selectedLanguage?.name == "English" ? "Expenses" : translation[1603]?.value ? translation[1603]?.value : "Expenses"}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">

                                <div className='flex items-center gap-3'>
                                    <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                                        <div className='p-2 relative gap-4 flex items-center'>
                                            <p onClick={() => {
                                                setToggleDateRange(!toggleDateRange)
                                            }} className='cursor-pointer'>
                                                {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/----"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/----"}`}
                                            </p>
                                            <span className='cursor-pointer' onClick={() => {
                                                setStartDate()
                                                setEndDate()
                                            }}>Clear</span>

                                            {
                                                toggleDateRange && <>
                                                    <div className='fixed inset-0 z-1' onClick={() => { setToggleDateRange(false) }}></div>
                                                    <div className='absolute top-full sm:right-0 -right-44 z-[2]'>
                                                        <DateRangePicker
                                                            start={startDate}
                                                            end={endDate}
                                                            setStartDate={setStartDate}
                                                            setEndDate={setEndDate}
                                                            setToggleDateRange={setToggleDateRange}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <LoginBtn
                                    borderBtn
                                    onClick={() => setFilterPopup(!FilterPopup)}
                                >
                                    <div className="flex items-center gap-2 text-primary font-semibold">
                                        <Svgs.Filters />
                                        <p>
                                            {selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}
                                        </p>
                                    </div>
                                </LoginBtn>

                                <LoginBtn
                                    className="rounded-lg border-2 border-primary"
                                    bg="bg-white"
                                    animation={false}
                                >
                                    <div className="flex items-center gap-2 text-[#FFFFFF]">
                                        <Svgs.Export bg="#FFFFFF" />
                                    </div>
                                </LoginBtn>
                            </div>
                        </div>
                        {loader
                            ? <TableShimer cols={9} />
                            : <ExpenseTable
                                data={expenses}
                                loader={loader}
                                setIsEdit={setIsEdit}
                                setEditData={setEditData}
                                setAddExpensePopup={setAddExpensePopup}
                                selectedLanguage={selectedLanguage}
                                translation={translation}
                                deletePopup={deletePopup}
                                setDeletePopup={setDeletePopup}
                                deleteLoading={deleteLoading}
                                onDeleteClick={onExpenseDelete}
                                expensePagination={expensePagination}
                                currentPage={currentPage}
                                getAllExpenses={getAllExpenses}
                                setCurrentPage={setCurrentPage}
                            />
                        }
                    </div>
                }

                {activeTab == "Expense Category" &&
                    <div className="border rounded-lg mt-[3rem]">
                        <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
                            <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                                <div className="flex items-center lg:gap-4 gap-3">
                                    <div className="flex items-center sm:gap-4 gap-2 ">
                                        <h2 className="font-semibold text-xl">
                                            {selectedLanguage?.name == "English" ? "Expense Category" : translation[1790]?.value ? translation[1790]?.value : "Expense Category"}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {expenseCategoryLoader
                            ? <TableShimer cols={3} />
                            : <ExpenseTableCategory
                                data={expenseCategory}
                                loader={expenseCategoryLoader}
                                setIsEdit={setIsEdit}
                                setEditData={setEditData}
                                setAddCategoryPopup={setAddCategoryPopup}
                                selectedLanguage={selectedLanguage}
                                translation={translation}
                                deletePopup={deletePopup}
                                setDeletePopup={setDeletePopup}
                                deleteLoading={expenseCategoryDeleteLoading}
                                onDeleteClick={onExpenseCategoryDelete}
                                expensePagination={expenseCategoryPagination}
                                currentPage={expenseCategoryCurrentPage}
                                getAllExpenses={getAllExpenses}
                                setCurrentPage={setExpenseCategoryCurrentPage}
                            />
                        }
                    </div>
                }

                {FilterPopup &&
                    <ExpenseFilterPopup
                        open={FilterPopup}
                        close={setFilterPopup}
                        translation={translation}
                        selectedLanguage={selectedLanguage}
                        setExpenseFilter={setExpenseFilter}
                        data={expenseFilter}
                        getAllExpenses={getAllExpenses}
                        expenseCategory={allExpenseCategory}
                        setCurrentPage={setCurrentPage}
                    />
                }

                {addCategoryPopup &&
                    <AddExpenseCategoryPopup
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        editData={editData}
                        setEditData={setEditData}
                        open={addCategoryPopup}
                        close={setAddCategoryPopup}
                        selectedLanguage={selectedLanguage}
                        translation={translation}
                        onSuccessAPICall={() => {
                            setExpenseCategoryLoader(true)
                            setExpenseCategoryCurrentPage(1)
                            getExpensesCategories()
                            getAllExpensesCategories()
                        }}
                    />
                }
                {addExpensePopup &&
                    <AddExpensePopup
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        editData={editData}
                        setEditData={setEditData}
                        open={addExpensePopup}
                        onSuccessAPICall={getAllExpenses}
                        close={setAddExpensePopup}
                    />
                }
            </div>

        </>
    )
}

export default Expenses