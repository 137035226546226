import axios from "axios";
import { BASE_URL, create_segment_url, delete_segment_url, get_client_for_CRM, get_segment_dropdown_url, get_segment_url, update_segment_url } from "../api_variables";
import { addSegments, deleteSegments, getAllSegments, getSegmentsDropdownPagination, updateSegments } from "Redux/TenantRedux/Actions/segmentActions";
import { getClientsDropdownWithPagination, getClientsDropdownWithoutPagination } from "Redux/TenantRedux/Actions/clientActions";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";

export const get_all_segments = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        let query = '?'
        for (let dt in payload) {
            if (payload[dt]) {
                query += `${dt}=${payload[dt]}&`
            }
        }
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const result = await axios.get(`${BASE_URL}${get_segment_url}${query}`, headers);
        if (result?.status === 200) {
            const data = result?.data
            dispatch(getAllSegments(data));
            success && success()
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        fail && fail(error);
        return error;
    }
}

export const get_segment_dropdown_pagination = (access_token, payload, setLoader) => async (dispatch) => {
    try {
        let query = '?'
        for (let dt in payload) {
            if (payload[dt]) {
                query += `${dt}=${payload[dt]}&`
            }
        }
        setLoader && setLoader(true)
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const result = await axios.get(`${BASE_URL}${get_segment_dropdown_url}${query}`, headers);
        if (result?.status === 200) {
            const data = result?.data
            dispatch(getSegmentsDropdownPagination(data));
            setLoader && setLoader(false)
        }
    } catch (error) {
        return error;
    }
}

export const getCRMClient = (payload, setLoader) => async (dispatch) => {
    let query = '?'

    for (let dt in payload) {
        if (payload[dt]) {
            query += `${dt}=${payload[dt]}&`
        }
    }

    setLoader && payload?.page == 1 && setLoader(true)
    try {
        const response = await axios.get(`${BASE_URL}${get_client_for_CRM}${query}`);
        if (response.status === 200) {
            dispatch(getClientsDropdownWithPagination({ ...response?.data, isFiltered: payload?.page == 1 ? true : false }));
            setLoader && setLoader(false)
        } else {
            setLoader && setLoader(false)
            // toast.error("Error in get Client", { toastId: "toast" });
        }
    } catch (error) {
        setLoader && setLoader(false)
        return error;
    }
};
export const getCRMClientNoPagination = (payload, setLoader, isPaginated) => async (dispatch) => {
    let query = '?'

    for (let dt in payload) {
        if (payload[dt]) {
            query += `${dt}=${payload[dt]}&`
        }
    }

    try {
        const response = await axios.get(`${BASE_URL}${get_client_for_CRM}${query}`);
        if (response.status === 200) {
            dispatch(getClientsDropdownWithoutPagination({ ...response?.data, isPaginated }));
            setLoader && setLoader(false)
        } else {
            setLoader && setLoader(false)
            // toast.error("Error in get Client", { toastId: "toast" });
        }
    } catch (error) {
        setLoader && setLoader(false)
        return error;
    }
};

export const createSegment = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.post(`${BASE_URL}${create_segment_url}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.response?.data

            if (result?.data?.status_code == 200) {
                toast.error(result?.data?.response?.message, { toastId: "add_segment" })
                fail && fail()
            }

            if (result?.data?.status_code == 201) {
                dispatch(addSegments(data));
                toast.success(result?.data?.response?.message, { toastId: "add_segment" })
                success && success()
            }
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }
};

export const updateSegment = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.put(`${BASE_URL}${update_segment_url}/${payload?.id}/`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.response?.data
            if (result?.data?.status_code == 200) {
                toast.error(result?.data?.response?.message, { toastId: "add_segment" })
                fail && fail()
            }

            if (result?.data?.status_code == 201) {
                dispatch(updateSegments(data));
                toast.success(result?.data?.response?.message, { toastId: "add_segment" })
                success && success()
            }
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "update_segment" })
        fail && fail()
        return error;
    }
};

export const delete_Segment = (access_token, data, success, fail) => async (dispatch) => {
    try {
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const result = await axios.delete(`${BASE_URL}${delete_segment_url}/${data}/`, headers);
        if (result?.status === 200) {
            toast.success(result?.data?.response?.message, { toastId: "delete_segment" })
            dispatch(deleteSegments(data));
            success && success()
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "delete_segment" })

        fail && fail(error);
        return error;
    }
}