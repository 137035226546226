import React from 'react'
import {
  Chart as ChartJS,
  ArcElement,
  Legend,
  Tooltip,
} from 'chart.js';

import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

const MultiDataDonet = ({ data }) => {
  const defaultLabels = ['1', '2', '3', '4']; // Default labels if not provided
  const defaultDataValues = [3, 2, 4, 1]; // Default data values if not provided

  // Extracting labels and data values from props or using defaults
  const labels = data ? data?.map(item => item?.name) : defaultLabels;
  const dataValues = data ? data?.map(item => item?.value) : defaultDataValues;


  // Check if all data values are zero
  const allDataZero = dataValues.every(value => value === 0);

  const chartData = {
    labels: labels,
    datasets: [{
      label: 'poll',
      data: allDataZero ? ['1'] : dataValues,
      backgroundColor: ['#5679FF', '#AEBFFF', '#2DA75D', '#1C1C1C'],
      borderColor: ['#5679FF', '#AEBFFF', '#2DA75D', '#1C1C1C'],
    }]
  };

  const options = {
    cutout: '70%',
    // spacing: 8,

    plugins: {
      legend: false,
      tooltip: {
        enabled: allDataZero ? false : true,
        usePointStyle: true,
        bodyAlign: "center",
        yAlign: "bottom",
        xAlign: "center",
        footerAlign: "center",
        titleAlign: "center",
        displayColors: false,
        pointStyle: false,
        padding: 10,
        caretPadding: 10,
        bodyFont: {
          size: 15,
        },
        callbacks: {
          title: function () {
            return "";
          },
        },
      },
    },
  };

  return (
    <Doughnut
      data={chartData}
      options={options}
    />
  );
};

export default MultiDataDonet;


// const MultiDataDonet = ({ labels, dataValue }) => {

//   const data = {
//     labels: labels ? labels : ['1', '2', '3', '4'],
//     datasets: [{
//       label: 'poll',
//       data: dataValue ? dataValue : [3, 2, 4, 1],
//       backgroundColor: ['#5679FF', '#AEBFFF', '#2DA75D', '#1C1C1C'],
//       borderColor: ['#5679FF', '#AEBFFF', '#2DA75D', '#1C1C1C'],
//       // borderRadius: {
//       //     topLeft: 20,
//       //     topRight: 20,
//       //   },
//     }]
//   }

//   const options = {
//     cutout: '70%',
//     spacing: 8,

//     plugins: {
//       legend: false,
//       tooltip: {
//         // Disable the on-canvas tooltip
//         enabled: true,
//         usePointStyle: true,
//         bodyAlign: "center",
//         yAlign: "bottom",
//         xAlign: "center",
//         footerAlign: "center",
//         titleAlign: "center",
//         displayColors: false,
//         pointStyle: false,
//         padding: 10,
//         caretPadding: 10,
//         bodyFont: {
//           size: 15,
//         },
//         callbacks: {
//           title: function () {
//             return "";
//           },
//         },
//       },
//     },
//   }

//   return (
//     <Doughnut
//       data={data}
//       options={options}
//     />
//   )
// }

// export default MultiDataDonet