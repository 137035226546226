import React from 'react'

const LoyalityShimmer = () => {
    return (
        <>
            <div className="mt-[2rem] rounded-md w-full flex flex-col gap-4 animate-pulse">
            <div className="loyalty-gradient !bg-gray-50 flex">
                    <div className="px-3 md:px-8 py-4 bg-gray-100 gap-3 flex items-center justify-center flex-col text-white w-1/5">
                        <div className="h-10 w-4/5 bg-gray-200 rounded-full" />
                        <div className="h-7 w-3/5 bg-gray-200 rounded-lg" />
                    </div>
                    <div className="flex gap-2 flex-col px-6 py-8 flex-1">
                        <div className="flex items-center justify-between gap-4">
                            <div className="flex justify-between w-full">
                                <div className="h-7 w-1/5 bg-gray-200 rounded-lg" />
                                <div className="flex justify-end gap-3 w-1/5">
                                    <div className="h-[2.5rem] w-[2.5rem] bg-gray-200 border border-gray-300 rounded-full flex cursor-pointer"/>
                                    <div className="h-[2.5rem] w-[2.5rem] bg-gray-200 border border-gray-300 rounded-full flex cursor-pointer"/>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full text-[#22283D] text-lg gap-1">
                            <div className="h-7 w-3/5 bg-gray-200 rounded-lg" />
                            <div className="h-7 w-4/5 bg-gray-200 rounded-lg" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoyalityShimmer