
import { CLIENT } from '../../ActionTypes/clientActionTypes';
import { CLIENT_GROUP } from './../../ActionTypes/clientGroupTypes/index';

const initialState = {
    clientGroups: [],
    clientGroups_updated: false,
    allClientGroups: [],
    pages: 0,
    allCount: 0
};


const ClienGrouptReducer = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case CLIENT.DELETE_CLIENT:
            return {
                ...state,
                clientGroups: [
                    ...state.clientGroups.map(cl_grp => {
                        return {
                            ...cl_grp,
                            client: [
                                ...cl_grp.client.filter(itm => itm.id != payload)
                            ]
                        }
                    })
                ]
            }

        case CLIENT_GROUP.GET_CLIENT_GROUP:
            return {
                ...state,
                clientGroups: payload?.response?.clientsgroup,
                clientGroups_updated: true,
                pages: payload?.response?.pages,
                allCount: payload?.response?.count
            };

        case CLIENT_GROUP.GET_CLIENT_GROUP_WITHOUT_PAGINATION:
            return {
                ...state,
                allClientGroups: payload?.response?.clientsgroup,
                clientGroups_updated: true,
            };


        case CLIENT_GROUP.ADD_CLIENT_GROUP:
            return {
                ...state,
                clientGroups: [payload, ...state.clientGroups]
            };


        case CLIENT_GROUP.DELETE_CLIENT_GROUP:
            let result = state?.clientGroups?.filter((item) => item.id !== payload);
            return {
                ...state,
                clientGroups: [...result],
            };


        case CLIENT_GROUP.UPDATE_CLIENT_GROUP:
            let updateList = state.clientGroups.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })

            return {
                ...state,
                clientGroups: [...updateList],
            };



        default:
            return state;
    }
};
export default ClienGrouptReducer;
