export const SALE_SERVICES = {
    DEL_GROUP_SERVICES: "DEL_GROUP_SERVICES",
    GET_GROUP_SERVICES: "GET_GROUP_SERVICES",
    GET_GROUP_SERVICES_PAGINATION_DROPDOWN: "GET_GROUP_SERVICES_PAGINATION_DROPDOWN",
    GET_GROUP_SERVICES_WITHOUT_PAGINATION: 'GET_GROUP_SERVICES_WITHOUT_PAGINATION',
    ADD_GROUP_SERVICES: "ADD_GROUP_SERVICES",
    GET_SALE_SERVICES: "GET_SALE_SERVICES",
    GET_SALE_SERVICES_WITHOUT_PAGINATION: 'GET_SALE_SERVICES_WITHOUT_PAGINATION',
    ADD_SALE_SERVICES: "ADD_SALE_SERVICES",
    DELETE_SALE_SERVICES: "DELETE_SALE_SERVICES",
    UPDATE_SALE_SERVICES: "UPDATE_SALE_SERVICES",
    GET_IDS_SALE_SERVICES: "GET_SALE_SERVICES",
    UPDATE_GROUP_SERVICES: "UPDATE_GROUP_SERVICES",
    GET_LOCATION_TAX: "GET_LOCATION_TAX",
    DELETE_BLOCK: "DELETE_BLOCK",
    GET_PAGE_COUNT: "GET_PAGE_COUNT",

    ADD_TAXES: "ADD_TAXES",
    PROFILE_SELECTED_SERIVCE: "PROFILE_SELECTED_SERIVCE",
    PROFILE_SELECTED_LOCATION: "PROFILE_SELECTED_LOCATION"
};