import { deleteExpense, deleteExpenseCategory, getExpense, getExpenseCategory, getExpenseCategoryNoPagination } from "Adapters/Api/expense"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const useExpense = () => {
    const dispatch = useDispatch()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const selected_location = getDataFromLocalStorage("selected_location");

    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const allExpenseCategory = state?.expense?.allExpenseCategory

    const [activeTab, setActiveTab] = useState("Expense");
    const [deletePopup, setDeletePopup] = useState()
    const [editData, setEditData] = useState()
    const [isEdit, setIsEdit] = useState(false)

    // ==================================== Expense Table Data ==================================== //
    const expenses = state?.expense?.expenses
    const expensePagination = state?.expense?.expensePagination

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [expenseFilter, setExpenseFilter] = useState({
        category: '',
        status: ''
    })
    const [toggleDateRange, setToggleDateRange] = useState();
    const [FilterPopup, setFilterPopup] = useState(false);
    const [addExpensePopup, setAddExpensePopup] = useState(false)
    const [loader, setLoader] = useState(true)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    const getAllExpenses = (reset, page) => {
        setLoader(true)
        const payload = {
            page: page ? page : currentPage,
            start_date: startDate,
            end_date: endDate,
            location_id: selected_location,
            category: reset ? '' : expenseFilter?.category,
            status: reset ? '' : expenseFilter?.status
        }
        const success = () => { setLoader(false) }
        const fail = () => { setLoader(false) }
        dispatch(getExpense(access_token, payload, success, fail))
    }

    const onExpenseDelete = () => {
        setDeleteLoading(true)
        const success = () => {
            setDeleteLoading(false)
            setDeletePopup()
            getAllExpenses()
        }
        const fail = () => { setDeleteLoading(false) }
        dispatch(deleteExpense(access_token, deletePopup, success, fail))
    }

    useEffect(() => {
        if (activeTab == 'Expense') {
            getAllExpenses()
        }
    }, [startDate, currentPage, selected_location, endDate, activeTab])
    // ==================================== Expense Table Data ==================================== //



    // ==================================== Expense Category Table Data ==================================== //
    const [addCategoryPopup, setAddCategoryPopup] = useState(false)
    const [expenseCategoryLoader, setExpenseCategoryLoader] = useState(true)
    const [expenseCategoryCurrentPage, setExpenseCategoryCurrentPage] = useState(1)
    const [expenseCategoryDeleteLoading, setExpenseCategoryDeleteLoading] = useState(false)

    const expenseCategory = state?.expense?.expenseCategory
    const expenseCategoryPagination = state?.expense?.expenseCategoryPagination

    const getExpensesCategories = (page) => {
        setExpenseCategoryLoader(true)
        const payload = {
            page: page ? page : expenseCategoryCurrentPage,
        }
        const success = () => { setExpenseCategoryLoader(false) }
        const fail = () => { setExpenseCategoryLoader(false) }
        dispatch(getExpenseCategory(access_token, payload, success, fail))
    }

    const onExpenseCategoryDelete = () => {
        setExpenseCategoryDeleteLoading(true)
        const success = () => {
            setExpenseCategoryDeleteLoading(false)
            setDeletePopup()
            getExpensesCategories()
            getAllExpensesCategories()
        }
        const fail = () => { setExpenseCategoryDeleteLoading(false) }
        dispatch(deleteExpenseCategory(access_token, deletePopup, success, fail))
    }

    useEffect(() => {
        if (activeTab == 'Expense Category') {
            getExpensesCategories()
        }
    }, [activeTab, expenseCategoryCurrentPage])

    useEffect(() => {
        setExpenseCategoryLoader(true)
    }, [expenseCategoryCurrentPage])
    // ==================================== Expense Category Table Data ==================================== //

    const getAllExpensesCategories = () => {
        setExpenseCategoryLoader(true)
        const payload = {
            no_pagination: true
        }
        const success = () => { setExpenseCategoryLoader(false) }
        const fail = () => { setExpenseCategoryLoader(false) }
        dispatch(getExpenseCategoryNoPagination(access_token, payload, success, fail))
    }

    useEffect(() => {
        setLoader(true)
        setExpenseCategoryLoader(true)
        setCurrentPage(1)
        setExpenseCategoryCurrentPage(1)
    }, [activeTab])

    useEffect(() => {
        getAllExpensesCategories()
    }, [])

    return {
        startDate, setStartDate, endDate, setEndDate, toggleDateRange, setToggleDateRange, FilterPopup, setFilterPopup, addCategoryPopup, setAddCategoryPopup, addExpensePopup,
        setAddExpensePopup, translation, selectedLanguage, expenses, loader, getAllExpenses, onExpenseDelete, deletePopup, setDeletePopup, deleteLoading, isEdit, setIsEdit,
        editData, setEditData, expensePagination, currentPage, setCurrentPage, expenseFilter, expenseCategory, setExpenseFilter, activeTab, setActiveTab, expenseCategoryLoader,
        setExpenseCategoryCurrentPage, expenseCategoryCurrentPage, expenseCategoryPagination, getExpensesCategories, expenseCategoryDeleteLoading, onExpenseCategoryDelete,
        setExpenseCategoryLoader,getAllExpensesCategories,allExpenseCategory
    }

}

export default useExpense