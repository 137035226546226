import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AccountSetupCard = ({ link, icon, title, description, showProgress, progressData }) => {
  const navigate = useNavigate();
  const state = useSelector((state) => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const progressPercentage = (+(progressData?.completed_modules / progressData?.total_modules) * 100)?.toFixed(0)


  return (
    <div
      onClick={() => {
        navigate(`${link ? link : ""}`);
      }}
      className="hover:bg-slate-100 relative transition-all cursor-pointer shadow-login-card px-[1.25rem] py-[1rem] rounded-xl flex flex-col gap-2"
    >
      {
        showProgress && progressData && progressPercentage < 100 && <div className="absolute top-2 right-4 flex flex-col gap-2">
          <span className={`font-semibold`}>
            {progressPercentage} %
            {" "}{selectedLanguage?.name == "English" ? "setup completed" : translation[653]?.value ? translation[653]?.value : "setup completed"}
          </span>
          <div class="w-[18.75rem] bg-white rounded-full h-1.5 border relative border-[#68D087]">
            <div class="bg-[#68D087] rounded-full absolute inset-0" style={{ width: Math.round((+(progressData?.completed_modules / progressData?.total_modules) * 100)?.toFixed(0)) + '%' }}></div>
          </div>
        </div>
      }
      {icon}
      <h2 className="font-semibold text-xl">{title}</h2>
      <p className="text-sm text-[#7B7B7B]">{description}</p>
    </div>
  );
};

export default AccountSetupCard;
