import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  deleteStockTransferRecode,
  fetchStockTransferList,
  fetchStockTransferListWithNoPagination,
} from "Adapters/Api/stocks";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";

let timeOutId = undefined
export const useStockTransfer = () => {
  const navigate = useNavigate();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");

  const [search_text, setSearchText] = useState("");

  const [stockTransferList, setStockTransferList] = useState([]);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openStocks, setOpenStocks] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [isUpdate, setisUpdate] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loader, setLoader] = useState(true)
  const [noPaginationLoader, setNoPaginationLoader] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0)
  const [allCount, setAllCount] = useState(0)
  const [stockTransferListWithNoPagination, setStockTransferListWithNoPagination] = useState([])
  const PageSize = 10
  // useEffect(() => {
  //   handleGetStockTransferList();
  // }, []);
  useEffect(() => {
    if (search_text === null) {
      // Initial load with empty search_text
      handleGetStockTransferList(currentPage, "")
      handleGetStockTransferListWithNoPagination("")
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        handleGetStockTransferList(currentPage, search_text)
        handleGetStockTransferListWithNoPagination(search_text)
      }, 500);
    }
  }, [currentPage, search_text])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  const handleGetStockTransferList = async (currentPage, search_text) => {
    setLoader(true)
    const response = await fetchStockTransferList(currentPage, search_text, access_token);
    if (response.status === 200) {
      let productList = response?.data?.response?.product_stock_transfers;
      setStockTransferList(productList);
      setAllCount(response?.data?.count)
      setPages(response?.data?.pages)
      setLoader(false)
    } else {
      setLoader(false)
    }
  };

  const handleGetStockTransferListWithNoPagination = async (search_text) => {
    setNoPaginationLoader(true)
    const response = await fetchStockTransferListWithNoPagination(search_text, access_token);
    if (response.status === 200) {
      let productList = response?.data?.response?.product_stock_transfers;
      setStockTransferListWithNoPagination(productList);
      setNoPaginationLoader(false)
    } else {
      setNoPaginationLoader(false)
    }
  };

  const handleDeleteStockId = (id) => {
    setOpenDeletePopup(true);
    setDeleteId(id);
  };

  const handleUpdateStock = (selectedId) => {
    setUpdateData(stockTransferList.filter(({ id }) => id === selectedId)[0]);
    setisUpdate(true);
    setOpenStocks(true);
  };

  const handleDelete = async () => {
    setIsDeleteLoading(true);
    const response = await deleteStockTransferRecode({
      id: deleteId,
      access_token,
    });
    if (response.status === 200) {
      let productList = response;
      setIsDeleteLoading(false);
      setOpenDeletePopup(false);
      setCurrentPage(1)
      handleGetStockTransferList(1, search_text);
      handleGetStockTransferListWithNoPagination(search_text)
    }
  };

  return {
    handleGetStockTransferList, handleDeleteStockId, setOpenDeletePopup, handleUpdateStock, setSearchText, setOpenStocks,
    handleDelete, setDeleteId, setIsDeleteLoading, setisUpdate, navigate, setStockTransferList, stockTransferList,
    openDeletePopup, search_text, openStocks, isDeleteLoading, updateData, deleteId, isUpdate, currentPage, setCurrentPage,
    stockTransferListWithNoPagination, pages, allCount, PageSize, handleGetStockTransferListWithNoPagination, loader,
    setNoPaginationLoader, noPaginationLoader
  };
};
