import { GIFT_CARDS } from "Redux/TenantRedux/ActionTypes/giftCards/giftCards";
import { MEMBER } from "../../ActionTypes/Membership/member";
import { VOUCHER } from "../../ActionTypes/Vouchers/voucher";


export const get_vouchers = (data) => {
    return {
        type: VOUCHER.GET_VOUCHER,
        payload: data
    }
}

export const get_count_voucher = (data) => {
    return {
        type: VOUCHER.GET_VOUCHER_COUNT,
        payload: data
    }
}

export const get_members = (data) => {
    return {
        type: MEMBER.GET_MEMBER,
        payload: data
    }
}

export const get_count_members = (data) => {
    return {
        type: MEMBER.GET_MEMBER_COUNT,
        payload: data
    }
}

export const get_gift_cards = (data) => {
    return {
        type: GIFT_CARDS.GET_GIFT_CARDS,
        payload: data
    }
}