import React,{useState} from 'react'

const ConversionTrackerCard = ({children, text}) => {
  return (
    <div className='bg-white p-4 rounded-xl flex-1 border hover:shadow-md flex flex-col justify-between gap-4 transition-all h-full overflow-hidden w-full w-fit'>
      <div className='flex items-center justify-between'>
        <h4 className='text-[1.1rem] font-semibold'>{text}</h4>
        <span className='cursor-pointer'>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
          </svg>
        </span>
      </div>
      <>
        {children}
      </>
    </div>
  )
}

export default ConversionTrackerCard