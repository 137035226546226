/* eslint-disable react/jsx-pascal-case */
import Svgs from 'Assets/svgs'
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import InputSection from 'Components/TenantApp/Elements/InputSection';
import I_Info_Icon from 'Components/TenantApp/Elements/I_Info_Icon';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import SelectedOption from 'Components/TenantApp/Elements/SelectedOption';
import ToggleWithText from 'Components/TenantApp/Elements/ToggleWithText';
import { WEEKDAYS } from 'Constants/Data/Months';
import { useAddDiscount } from './helper';
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb';
import { useMemo } from 'react';
import moment from 'moment';
import FloatInput from 'Components/TenantApp/Elements/FloatInput';

const AddDiscount = () => {
    const { errors, toggleDay, toggleStore, discount, serviceDropdown, loading, locationDropdown, isEdit, dateRangeCheck, validateBlockDate,
        handleChange, filterServices, addServices, onBlurHandler, navigate, addStoreRestriction, filterStoreRestriction, onSubmit,
        addBlockDates, filterBlockDate, setToggleDay, setToggleStore, handleDayChecklist, setToggleBlockDate, toggleblockdate, translation,
        selectedLanguage, formRef
    } = useAddDiscount();

    const {
        spendAmount,
        promotion_name,
        service,
        services,
        dateRestriction,
        blockDate,
        blockDates,
        storeRestriction,
        storeRestrictions,
        dayRestrictions,
    } = discount;

    var minEndDateLimit = useMemo(() => {
        return moment(dateRestriction.startDate).add(1, 'day').format('YYYY-MM-DD')
    }, [dateRestriction.startDate]);

    return (
        <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right' >
            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
                url={'/dashboard/clients/'}
                second={selectedLanguage?.name == "English" ? "Discount & Promotions" : translation[638]?.value ? translation[638]?.value : "Discount & Promotions"}
                secondurl={'/dashboard/clients/discounts-promotions'}
                third={selectedLanguage?.name == "English" ? "Fixed Price Bundle/Package" : translation[1049]?.value ? translation[1049]?.value : "Fixed Price Bundle/Package"}
            />
            <div className='flex items-center sm:gap-4 gap-2 my-[1rem]'>

                <div className='flex items-center gap-4 justify-between w-full'>
                    <div className='flex items-center gap-3'>
                        <Svgs.Back link='/dashboard/clients/discounts-promotions' />
                        <h2 className='text-primary font-bold lg:text-3xl text-xl cursor-pointer'>{selectedLanguage?.name == "English" ? "Fixed Price Bundle/Package" : translation[1049]?.value ? translation[1049]?.value : "Fixed Price Bundle/Package"}</h2>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-4 md:w-[40vw]' ref={formRef}>
                <div className='border rounded-lg p-3 flex flex-col gap-5'>
                    <h1 className='text-sm normal-case'>
                        {selectedLanguage?.name == "English" ? "Get selected services/service group with a fixed amount." : translation[1050]?.value ? translation[1050]?.value : "Get selected services/service group with a fixed amount."}
                    </h1>
                    <div className='grid md:grid-cols-2 gap-4'>

                        <LoginInput
                            name="promotion_name"
                            className='!w-fit'
                            title={selectedLanguage?.name == "English" ? "Promotion Name" : translation[883]?.value ? translation[883]?.value : "Promotion Name"}
                            value={promotion_name}
                            placeholder={selectedLanguage?.name == "English" ? "Enter Promotion Name" : translation[1732]?.value ? translation[1732]?.value : "Enter Promotion Name"}
                            type='text'
                            // charactersMaxLength={25}
                            onChange={handleChange}
                            error={errors?.promotion_name}
                        />
                        <div>
                            <Dropwdown
                                required={true}
                                name="service"
                                error={services?.length == 0 ? errors?.service : ""}
                                title={<I_Info_Icon
                                    text={selectedLanguage?.name == "English" ? "Select Service" : translation[1063]?.value ? translation[1063]?.value : "Select Service"}
                                    info={selectedLanguage?.name == "English" ? "The client will get all these services for a fixed price." : translation[1051]?.value ? translation[1051]?.value : "The client will get all these services for a fixed price."} />}
                                placeholder={selectedLanguage?.name == "English" ? "Select Services" : translation[89]?.value ? translation[89]?.value : "Select Services"}
                                value={service}
                                options={serviceDropdown?.filter(
                                    (obj) =>
                                        obj.value !==
                                        discount?.services?.find(
                                            (service) => service === obj.value
                                        )
                                )}
                                onChange={addServices}
                            />
                            {(serviceDropdown?.filter(
                                (obj) =>
                                    obj.value !==
                                    discount?.services?.find(
                                        (service) => service === obj.value
                                    )
                            ))?.length == 0 && (
                                    <div
                                        className="flex items-center gap-2 cursor-pointer pt-2"
                                        onClick={() => {
                                            //humayunnnn
                                            navigate('/dashboard/account/service-management')
                                        }}
                                    >
                                        <p className="text-sm">
                                            {selectedLanguage?.name == "English" ? "No services found. Click" : translation[1045]?.value ? translation[1045]?.value : "No services found. Click"}
                                            <span className="underline mx-px">
                                                {selectedLanguage?.name == "English" ? "here" : translation[502]?.value ? translation[502]?.value : "here"}
                                            </span>
                                            {selectedLanguage?.name == "English" ? "to add." : translation[140]?.value ? translation[140]?.value : "to add."}
                                        </p>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className='grid md:grid-cols-2 gap-4'>
                        <div className='flex flex-col gap-2 flex-1 whitespace-nowrap capitalize relative'>
                            <p className='text-sm flex items-center gap-1'>
                                {selectedLanguage?.name == "English" ? "Spend Amount" : translation[1042]?.value ? translation[1042]?.value : "Spend Amount"} <span className='text-red-500'>*</span>
                            </p>
                            <FloatInput
                                name="spendAmount"
                                className='!w-fit'
                                leftText={'AED'}
                                // title={'Spend Amount '}
                                title={''}
                                value={spendAmount}
                                placeholder={selectedLanguage?.name == "English" ? "Enter Amount" : translation[794]?.value ? translation[794]?.value : "Enter Amount"}
                                required={true}
                                onChange={handleChange}
                                error={errors?.spendAmount}
                            />
                        </div>
                    </div>
                    <div className='flex items-center gap-2 flex-wrap'>

                        {services?.map((obj, index) => (
                            <SelectedOption
                                rounded={"full"}
                                name={
                                    serviceDropdown?.find(
                                        (service) => service?.value === obj
                                    )?.label
                                }
                                onClick={() => filterServices(index)}
                            />
                        ))}
                    </div>
                </div>

                <div className='border rounded-lg p-3 flex flex-col gap-7'>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>
                            {selectedLanguage?.name == "English" ? "Date Restrictions" : translation[899]?.value ? translation[899]?.value : "Date Restrictions"}
                        </h1>
                        <div className='grid grid-cols-2 gap-3'>
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                name="startDate"
                                value={dateRestriction.startDate}
                                type="date"
                                onChange={handleChange}
                                onBlur={() =>
                                    onBlurHandler({ startDate: dateRestriction.startDate })
                                }
                                error={errors.startDate}
                            />
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                                name="endDate"
                                value={dateRestriction.endDate}
                                type="date"
                                onChange={handleChange}
                                min={minEndDateLimit}
                                onBlur={() =>
                                    onBlurHandler({ endDate: dateRestriction.endDate })
                                }
                                error={errors.endDate}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>
                            {selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
                        </h1>
                        <div className='flex items-end gap-3'>
                            <Dropwdown
                                required={true}
                                name='storeRestriction'
                                placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                                title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                                parentClass='flex-1'
                                options={locationDropdown?.filter(obj => obj.value !== discount?.storeRestrictions?.find(location => location === obj.value))}
                                value={storeRestriction}
                                onChange={handleChange}
                                error={errors?.storeRestriction}
                            />
                            <LoginBtn
                                className={storeRestriction && "shake-bottom"}
                                text={<div className='flex items-center gap-2' > <Svgs.Plus /><p>
                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                </p></div>}
                                onClick={addStoreRestriction}
                                disabled={!storeRestriction}
                            />
                        </div>
                    </div>
                    <div className='flex items-center gap-2 flex-wrap'>
                        {storeRestrictions?.map((obj, index) => (
                            <SelectedOption
                                rounded={"full"}
                                name={
                                    locationDropdown?.find(
                                        (location) => location?.value === obj
                                    )?.label
                                }
                                onClick={() => filterStoreRestriction(index)}
                            />
                        ))}
                    </div>
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={'!text-lg'}
                            title={selectedLanguage?.name == "English" ? "Add Block Date" : translation[902]?.value ? translation[902]?.value : "Add Block Date"}
                            value={toggleblockdate}
                            onChange={() => setToggleBlockDate(!toggleblockdate)}
                            checked={toggleblockdate}
                        />
                        {toggleblockdate &&
                            <>
                                <div className='flex items-end gap-3'>
                                    <LoginInput
                                        name="blockDate"
                                        required={false}
                                        title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                        type="date"
                                        parentClass="flex-1"
                                        min={dateRestriction.startDate}
                                        max={dateRestriction.endDate}
                                        value={blockDate}
                                        onChange={handleChange}
                                        error={errors.blockDate}
                                        disabled={
                                            !discount.dateRestriction.startDate ||
                                            !discount.dateRestriction.endDate
                                        }
                                    />
                                    <LoginBtn
                                        className={(blockDate) && "shake-bottom"}
                                        text={
                                            <div className="flex items-center gap-2">
                                                <Svgs.Plus />
                                                <p>
                                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                                </p>
                                            </div>
                                        }
                                        onClick={addBlockDates}
                                        disabled={
                                            !dateRangeCheck ? true : false ||
                                                !discount.dateRestriction.startDate ||
                                                !discount.dateRestriction.endDate ||
                                                !blockDate ||
                                                validateBlockDate ? true : false
                                        }
                                    />
                                </div>
                                <div className='flex items-center gap-2 flex-wrap'>
                                    {blockDates?.map((obj, index) => (
                                        <>
                                            {obj?.is_deleted === "False" && (
                                                <SelectedOption
                                                    rounded={"full"}
                                                    name={obj?.date}
                                                    onClick={() => filterBlockDate(obj?.id, index)}
                                                />
                                            )}
                                        </>
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                    {/* <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={"!text-lg"}
                            title={selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
                            value={toggleStore}
                            onChange={() => setToggleStore(!toggleStore)}
                            checked={toggleStore}
                        />
                        {toggleStore && (
                            <>
                                <div className="flex items-end gap-3">
                                    <Dropwdown
                                        name="storeRestriction"
                                        placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                                        title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                                        parentClass="flex-1"
                                        options={locationDropdown?.filter(
                                            (obj) =>
                                                obj.value !==
                                                discount?.storeRestrictions?.find(
                                                    (location) => location === obj.value
                                                )
                                        )}
                                        value={storeRestriction}
                                        onChange={handleChange}
                                    />
                                    <LoginBtn
                                        className={(storeRestriction) && "shake-bottom"}
                                        text={
                                            <div className="flex items-center gap-2">
                                                {" "}
                                                <Svgs.Plus />
                                                <p>
                                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                                </p>
                                            </div>
                                        }
                                        onClick={addStoreRestriction}
                                        disabled={!storeRestriction}
                                    />
                                </div>
                                <div className="flex items-center gap-2 flex-wrap">
                                    {storeRestrictions?.map((obj, index) => (
                                        <SelectedOption
                                            rounded={"full"}
                                            name={
                                                locationDropdown?.find(
                                                    (location) => location?.value === obj
                                                )?.label
                                            }
                                            onClick={() => filterStoreRestriction(index)}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div> */}
                    <div className='flex flex-col gap-4'>
                        <ToggleWithText
                            textClass={"!text-lg"}
                            title={selectedLanguage?.name == "English" ? "Day Restrictions" : translation[906]?.value ? translation[906]?.value : "Day Restrictions"}
                            value={toggleDay}
                            onChange={() => setToggleDay(!toggleDay)}
                            checked={toggleDay}
                        />
                        {toggleDay && (
                            <div className="grid grid-cols-2 gap-4">
                                {dayRestrictions.map((item) => {
                                    return (
                                        <CheckBox
                                            name={item.day}
                                            label={item.day}
                                            value={item.value}
                                            onChange={handleDayChecklist}
                                            checked={item.value === item.day ? true : false}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <LoginBtn
                    text={isEdit ?
                        selectedLanguage?.name == "English" ? "Update" : translation[845]?.value ? translation[845]?.value : "Update"
                        :
                        selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
                    loading={loading}
                    disabled={loading}
                    // disabled={
                    //     loading ||
                    //     discount.services.length === 0 ||
                    //     !discount.spendAmount ||
                    //     !discount.promotion_name ||
                    //     !discount.dateRestriction.endDate ||
                    //     !discount.dateRestriction.startDate
                    //     || errors.endDate
                    //     // || discount.blockDates.length === 0
                    // }
                    onClick={onSubmit}
                />
            </div>
        </div>
    )
}

export default AddDiscount