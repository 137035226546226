
import axios from "axios";
import { BASE_URL, create_store_target, get_store_target, update_store_target, delete_store_target, get_store_target_report_url } from "../api_variables";
import { toast } from 'react-toastify';
import { STORE_TARGET } from './../../../Redux/TenantRedux/ActionTypes/storeTargetTypes/index';
import { apiErrorMessage } from "Constants/Data/Errors";

export const getStoreTarget = (selected_location, currentPage, searchText, monthFilter, searchYear, setLoader) => dispatch => {
    setLoader && setLoader(true)
    const location = selected_location ? `&location_id=${selected_location}` : ''
    const current_Page = currentPage ? `?page=${currentPage}` : '';
    const search = searchText ? `&search_text=${searchText}` : ''
    const month = monthFilter ? `&month=${monthFilter}` : ''
    const year = searchYear ? `&year=${searchYear}` : ''
    let s_code;
    fetch(`${BASE_URL}${get_store_target}${current_Page}${location}${search}${month}${year}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let storeTargets = result;
                dispatch({
                    type: STORE_TARGET?.GET_STORE_TARGET,
                    payload: storeTargets
                })
                setLoader && setLoader(false)
            }
            else {
                toast.error(apiErrorMessage, { toastId: "toast" })
                setLoader && setLoader(false)
            }
        }).catch((err) => {
            toast.error(apiErrorMessage, { toastId: "toast" })
            setLoader && setLoader(false)
        })
};
export const getStoreTargetWithNoPagination = () => async (dispatch) => {
    try {
        const result = await axios.get(`${BASE_URL}${get_store_target}?no_pagination=true`);
        if (dispatch) {
            let storeTargets = result?.data;
            dispatch({
                type: STORE_TARGET?.GET_STORE_TARGET_WITHOUT_PAGINATION,
                payload: storeTargets
            })
        }
        return result;
    } catch (error) {
        return error;
    }
};


export const createStoreTarget = (data, access_token, success, fail) => async dispatch => {
    let form_data = new FormData();
    for (let key in data) {
        if (data[key]) {
            if (typeof data[key] == "object") {
                form_data.append(key, JSON.stringify(data[key]))
            } else {
                form_data.append(key, data[key])
            }

        }
    }

    try {
        const result = await axios.post(`${BASE_URL}${create_store_target}`, form_data,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );

        if (result?.data?.status_code == 201) {
            dispatch({
                type: STORE_TARGET?.CREATE_STORE_TARGET,
                payload: result?.data?.response?.storetarget
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()

    }
};

export const copyStoreTarget = (data, access_token, success, fail) => async dispatch => {
    let form_data = new FormData();
    for (let key in data) {
        if (data[key]) {
            if (typeof data[key] == "object") {
                form_data.append(key, JSON.stringify(data[key]))
            } else {
                form_data.append(key, data[key])
            }

        }
    }

    try {
        const result = await axios.post(`${BASE_URL}${create_store_target}`, form_data,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );

        if (result?.data?.status_code == 201) {
            dispatch({
                type: STORE_TARGET?.CREATE_STORE_TARGET,
                payload: result?.data?.response?.storetarget
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()

    }
};



export const updateStoreTarget = (data, access_token, success, fail) => async dispatch => {
    let form_data = new FormData();
    for (let key in data) {
        if (data[key]) {
            if (typeof data[key] == "object") {
                form_data.append(key, JSON.stringify(data[key]))
            } else {
                form_data.append(key, data[key])
            }

        }
    }
    try {
        const result = await axios.put(`${BASE_URL}${update_store_target}`, form_data,
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });

        if (result?.data?.status_code == 200) {
            dispatch({
                type: STORE_TARGET?.UPDATE_STORE_TARGET,
                payload: result?.data?.response?.storetarget
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
};


export const deleteStoreTarget = (id, access_token, success, fail) => dispatch => {

    fetch(`${BASE_URL}${delete_store_target}`, {
        method: "DELETE",
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Token ${access_token}`
        },
        body: JSON.stringify(
            { id: id }
        )
    }).then((result) => {
        return result.json()
    }).then((response) => {
        if (response.status_code === 200) {
            dispatch({
                type: STORE_TARGET?.DELETE_STORE_TARGET,
                payload: id
            });
        }
        success && success(response?.response?.message);
    }).catch((err) => {
        fail && fail()
    })

}

// STORE TARGET KPI REPORT ADAPTER
export const get_store_target_kpi = (currentPage, month, year, filterStore, loader, success, fail) => async (dispatch) => {
    // const location = selected_location ? `?location_id=${selected_location}` : ''
    const page = currentPage ? `?page=${currentPage}` : ''
    const monthValue = month ? `&month=${month}` : ''
    const yearValue = year ? `&year=${year}` : ''
    const store = filterStore ? `&location_id=${filterStore}` : ''
    try {
        loader && loader()
        const response = await axios.get(`${BASE_URL}${get_store_target_report_url}${page}${monthValue}${yearValue}${store}`);
        if (response.status === 200) {
            success && success()
            dispatch({
                type: STORE_TARGET.GET_STORE_TARGET_REPORT,
                payload: response?.data
            });
        } else {
            toast.error(apiErrorMessage, { toastId: "toast" });
        }
    } catch (error) {
        fail && fail()
        toast.error(apiErrorMessage, { toastId: "toast" });
        return error;
    }
};

// STORE TARGET KPI REPORT ADAPTER
export const get_store_target_kpi_without_pagination = (month, year, filterStore, loader, success, fail) => async (dispatch) => {
    // const location = selected_location ? `?location_id=${selected_location}` : ''
    const monthValue = month ? `&month=${month}` : ''
    const yearValue = year ? `&year=${year}` : ''
    const store = filterStore ? `&location_id=${filterStore}` : ''
    try {
        loader && loader()
        const response = await axios.get(`${BASE_URL}${get_store_target_report_url}?no_pagination=true${monthValue}${yearValue}${store}`);
        if (response.status === 200) {
            success && success()
            dispatch({
                type: STORE_TARGET.GET_STORE_TARGET_REPORT_WITH_NO_PAGINATION,
                payload: response?.data
            });
        } else {
            toast.error(apiErrorMessage, { toastId: "toast" });
        }
    } catch (error) {
        fail && fail()
        toast.error(apiErrorMessage, { toastId: "toast" });
        return error;
    }
};