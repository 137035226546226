/* eslint-disable array-callback-return */
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { add_spend_some_amount_discount, get_client_discount_promotion, update_spend_some_amount_discount } from "Adapters/Api/clientDiscountPromotion";
import { getSaleServices, getServiceGroup } from "Adapters/Api/saleServices";
import { get_business_brands } from "Adapters/Api/Brand";
import { discountErrors, navigateToDiscountPromotion } from "../../helper";
import { useRef } from "react";
import useObjectKeys from "Hooks/useObjectKeys";

const initialState = {
  spendService: [{ service: '', spend_amount: '', is_deleted: 'False' }],
  dateRestriction: { startDate: '', endDate: '' },
  blockDate: '',
  promotion_name: '',
  blockDates: [],
  storeRestriction: '',
  storeRestrictions: [],
  dayRestrictions: [
    { day: 'Monday', value: '', is_deleted: 'False' },
    { day: 'Tuesday', value: '', is_deleted: 'False' },
    { day: 'Wednesday', value: '', is_deleted: 'False' },
    { day: 'Thursday', value: '', is_deleted: 'False' },
    { day: 'Friday', value: '', is_deleted: 'False' },
    { day: 'Saturday', value: '', is_deleted: 'False' },
    { day: 'Sunday', value: '', is_deleted: 'False' },
  ]
}
export const useAddDiscount = () => {
  const formRef = useRef()
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { setErrors, errors, onBlurHandler, validation } = useValidations();
  const { objectKeyConvertToArray } = useObjectKeys()
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const dispatch = useDispatch();
  const businessId = state?.business?.business?.id;
  const [isEdit, setIsEdit] = useState(false);
  const [toggleStore, setToggleStore] = useState(false);
  const [toggleblockdate, setToggleBlockDate] = useState(false);
  const [toggleDay, setToggleDay] = useState(false);
  const [formErrors, setFormErrors] = useState()
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(initialState);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const locations = state?.locations?.locations;
  const params = useParams();
  const servicesRedux = state?.saleService?.saleServices;
  const {
    spendService,
    blockDates,
    blockDate,
    storeRestrictions,
  } = discount;

  useEffect(() => {
    dispatch(getSaleServices());
  }, [])

  useEffect(() => {
    dispatch(getServiceGroup());
    if (!state.brand.brands_updated) {
      dispatch(get_business_brands());
    }
  }, [state?.brand?.brands?.length])

  useEffect(() => {
    if (params.id === undefined) {
      setIsEdit(false);
    }
    if (state?.clientDiscountPromotion?.discountPromotions?.length === 0 && params.id) {
      dispatch(get_client_discount_promotion())
      setIsEdit(true);
    }
    if (params.id) {
      setIsEdit(true);
    }
  }, [params.id, state?.clientDiscountPromotion?.discountPromotions?.length]);

  useEffect(() => {
    let editData = state?.clientDiscountPromotion?.discountPromotions?.find((obj) => obj.id === params.id);
    if (editData) {
      let { spend_service, id, date_restrictions, block_date, day_restrictions, promotion_name } = editData;
      let { start_date, end_date, business_address } = date_restrictions;

      let dayRestrictions = initialState?.dayRestrictions?.map((item) => {
        let obj = day_restrictions.find(obj => obj.day === item.day);
        if (obj?.day === item.day) {
          return {
            id: obj?.id,
            day: obj?.day,
            value: obj?.day,
            is_deleted: obj?.is_deleted
          }
        } else {
          return { ...item }
        }
      })
      day_restrictions?.length > 0 && setToggleDay(true);
      business_address?.length > 0 && setToggleStore(true);
      if (editData && dayRestrictions) {
        setDiscount({
          ...discount,
          id: id,
          spendService: spend_service,
          promotion_name: promotion_name,
          dateRestriction: { startDate: start_date, endDate: end_date },
          blockDates: block_date,
          storeRestrictions: business_address,
          dayRestrictions: dayRestrictions
        })
      }
    }
  }, [isEdit, state?.clientDiscountPromotion?.discountPromotions?.length])


  let dateRangeCheck = moment(blockDate).valueOf() >= moment(discount.dateRestriction.startDate).valueOf() && moment(blockDate).valueOf() <= moment(discount.dateRestriction.endDate).valueOf()
  let validateBlockDate = discount?.blockDates?.find(obj => obj.date === blockDate)
  const handleChange = (e) => {
    const { value, name } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (name === 'startDate' || name === 'endDate') {
      if (name === 'startDate') {
        setDiscount((prev) => ({
          ...prev,
          dateRestriction: { ...prev.dateRestriction, endDate: "" }
        }));
      }
      setDiscount((prev) => ({
        ...prev,
        dateRestriction: { ...prev?.dateRestriction, [name]: value }
      }));
    }
    if (name === 'blockDate') {
      let validateBlock = discount?.blockDates?.find(obj => obj.date === value)

      let dateRangeCheck = moment(value).valueOf() >= moment(discount.dateRestriction.startDate).valueOf() && moment(value).valueOf() <= moment(discount.dateRestriction.endDate).valueOf()
      if (!dateRangeCheck) {
        setErrors((prev) => ({
          ...prev,
          [name]: discountErrors.dateRange,
        }));
      }

      if (dateRangeCheck) {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
      if (validateBlock) {
        setErrors((prev) => ({
          ...prev,
          [name]: discountErrors.dateAlreadySelected,
        }));
      }


      setDiscount((prev) => ({
        ...prev,
        [name]: value
      }));
    }
    else {
      setDiscount((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  }

  const addSpendAmountOnSelectedService = (e, i) => {
    const { name, value } = e.target;
    if (value) {
      setErrors((prev) => ({ ...prev, [`${name}${i}`]: "" }))
    }
    if (formErrors?.length > 0) {
      const updatedFormErrors = [...formErrors];
      updatedFormErrors[i] = {
        ...updatedFormErrors[i],
        [name]: '',
      };
      setFormErrors(updatedFormErrors);
    }
    setDiscount((prev) => ({
      ...prev,
      spendService: [
        ...prev?.spendService.map((obj, index) => {
          if (i === index) {
            return {
              ...obj,
              [name]: value,
              is_deleted: 'False'
            }
          }
          else {
            return obj
          }
        })
      ],
    }));
  }
  const filterSpendAmountOnSelectedService = (id, index) => {
    if (id) {  // edit 
      var filter = discount?.spendService?.map((obj) => {
        if (obj === discount?.spendService[index]) {
          return {
            ...obj,
            is_deleted: 'True'
          }
        }
        else {
          return { ...obj }
        }
      })
      setDiscount((prev) => ({
        ...prev,
        spendService: filter,
      }))
    }
    else { // create no need of isDelete here 
      let filter = discount.spendService.filter((obj) => obj !== discount.spendService[index])
      setDiscount((prev) => ({
        ...prev,
        spendService: filter
      }))
    }
  }


  const addBlockDates = () => {
    setDiscount((prev) => ({
      ...prev,
      blockDates: [...prev.blockDates, { date: discount.blockDate, is_deleted: 'False' }],
      blockDate: ''
    }))
  }
  const addFreeServices = () => {
    setDiscount((prev) => ({
      ...prev,
      spendService: [
        ...prev?.spendService,
        initialState.spendService[0],
      ],
    }));
  }
  const filterBlockDate = (id, index) => {
    if (id) {
      var filter = blockDates?.map((obj) => {
        if (obj === blockDates[index]) {
          return {
            ...obj,
            is_deleted: 'True'
          }
        }
        else {
          return { ...obj }
        }
      })
      setDiscount((prev) => ({
        ...prev,
        blockDates: filter,
      }))
    }
    else {
      var filter = blockDates?.filter((obj) => obj != blockDates[index])
      setDiscount((prev) => ({
        ...prev,
        blockDates: filter,
      }))
    }
  }
  const addStoreRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      storeRestrictions: [...prev.storeRestrictions, discount.storeRestriction],
      storeRestriction: ''
    }))
  }
  const filterStoreRestriction = (index) => {
    var filter = storeRestrictions?.filter(obj => obj !== storeRestrictions[index])
    setDiscount((prev) => ({
      ...prev,
      storeRestrictions: filter,
    }))
  }
  const handleDayChecklist = (e) => {
    setDiscount((prev) => ({
      ...prev,
      dayRestrictions: discount?.dayRestrictions?.map(obj => {
        // handle checklist withoutd ids 
        // check function
        if (obj.day === e.target.name && obj.value === '' && obj.is_deleted === 'False') {
          return {
            ...obj,
            value: e.target.name
          }
        }
        // UN Check block
        if (obj.value === e.target.value && !obj.id) {
          return {
            ...obj,
            value: ''
          }
        }

        // handling update scenarios for checklist
        // un check block for checked
        if (obj.id && obj.value === e.target.value && obj.is_deleted === 'False') {
          return {
            ...obj,
            is_deleted: 'True',
            value: ''
          }
        }

        // checked block and isDeleted 
        if (obj.day === e.target.name && obj.value === '' && obj.is_deleted && obj.id) {
          return {
            ...obj,
            value: e.target.name,
            is_deleted: 'False'
          }
        }
        else {
          return obj
        }
      })
    }))
  }

  const locationDropdown = useMemo(() => {
    return locations?.map((obj) => {
      if (obj) {
        return {
          label: obj?.address_name,
          value: obj?.id
        }
      }
    })
  }, [locations])

  const serviceDropdown = useMemo(() => {
    return servicesRedux?.map((obj) => {
      if (obj) {
        return {
          label: obj?.name,
          value: obj?.id
        }
      }
    })
  }, [servicesRedux])


  let success = () => {
    setLoading(false);
    setDiscount(initialState);
    navigateToDiscountPromotion(navigate)
    setToggleDay(false);
    setToggleStore(false);
    setToggleBlockDate(false)
  }
  let fail = () => {
    setLoading(false);
  }

  const onSubmit = () => {
    const requiredFields = {
      promotion_name: discount.promotion_name,
      startDate: discount.dateRestriction.startDate,
      endDate: discount.dateRestriction.endDate
    }
    setErrors(validation(requiredFields));
    let result = validation(requiredFields);

    if (objectKeyConvertToArray(result)?.length === 0) {
      const errors = [];
      discount.spendService?.forEach((data, index) => {
        const fieldErrors = {};
        if (!data.spend_amount) {
          fieldErrors.spend_amount = 'This field is required';
        }
        if (!data.service) {
          fieldErrors.service = 'This field is required';
        }
        errors[index] = fieldErrors;
      });
      // Update formErrors state with the new errors
      setFormErrors(errors);
      // If there are errors, return early and do not proceed with form submission
      if (errors?.some((error) => Object.keys(error)?.length > 0)) {
        formRef.current?.scrollIntoView({ behavior: "smooth" });
        return;
      } else {
        setLoading(true);
        let payload = {
          business: businessId,
          spend_service: discount.spendService,
          start_date: discount.dateRestriction.startDate,
          end_date: discount.dateRestriction.endDate,
          promotion_name: discount.promotion_name,
          blockdate: discount?.blockDates,
          location: discount.storeRestrictions
        }
        if (isEdit) {
          if (payload) {
            payload.id = discount.id;
            payload.dayrestrictions = discount?.dayRestrictions?.filter(obj => obj.value || obj.is_deleted === "True");
            dispatch(update_spend_some_amount_discount(payload, access_token, success, fail))
          }
        } else {
          if (payload) {
            payload.dayrestrictions = discount?.dayRestrictions?.filter(obj => obj.value)?.map(obj => { return { day: obj.value } });
            dispatch(add_spend_some_amount_discount(payload, access_token, success, fail))
          }
        }
      }
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }


  return {
    errors, toggleDay, toggleStore, discount, loading, locationDropdown, isEdit, serviceDropdown, addSpendAmountOnSelectedService,
    filterSpendAmountOnSelectedService, handleChange, onBlurHandler, navigate, addFreeServices, addStoreRestriction, filterStoreRestriction,
    onSubmit, addBlockDates, filterBlockDate, setToggleDay, setToggleStore, handleDayChecklist, dateRangeCheck, validateBlockDate,
    toggleblockdate, setToggleBlockDate, translation, selectedLanguage, formRef, setFormErrors, formErrors
  };
};
