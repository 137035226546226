import { VENDORS } from "../../ActionTypes/vendorsTypes";

const inventoryVendor = {
  vendors: [],
  vendors_updated: false,
};

const VendorReducer = (state = inventoryVendor, action) => {

  const { type, payload } = action;

  switch (type) {


    case VENDORS.ADD_IMPORTED_VENDORS_TO_LIST:
      return {
        ...state,
        vendors: payload
      }

    case VENDORS.GET_VENDORS:
      return {
        ...state,
        vendors: payload?.response?.vendors,
        allCount: payload?.count,
        pages: payload?.pages,
        vendors_updated: true
      };
    case VENDORS.GET_VENDORS_DROPDOWNLIST:
      return {
        ...state,
        vendors: payload?.response?.vendors,
        vendors_updated: true
      };

    case VENDORS.GET_VENDORS_WITH_NO_PAGINATION:
      return {
        ...state,
        allVendors: payload,
      };


    case VENDORS.ADD_VENDOR:
      return {
        ...state,
        vendors: [payload, ...state.vendors],
        vendors_updated: true
      };

    case VENDORS.DELETE_VENDOR:
      let result = state?.vendors?.filter((item) => item.id !== payload);
      return {
        ...state,
        vendors: [...result],
      };



    case VENDORS.UPDATE_VENDOR:
      let updateList = state?.vendors?.map((item) => {
        if (item.id === payload.id) {
          return payload
        } else {
          return item;
        }
      })

      return {
        ...state,
        vendors: [...updateList],
      };


    default:
      return state;
  }
};

export default VendorReducer;
