import { getClient } from "Adapters/Api/clients"
import { getsProducts } from "Adapters/Api/Products"
import Svgs from "Assets/svgs"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { getProducts } from "Redux/TenantRedux/Actions/product"
import { CLIENT } from "Redux/TenantRedux/ActionTypes/clientActionTypes"





export const UserRestrictedDiscountCard = ({ selected, data, onSelect, onRemoveOffer }) => {
    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    // useEffect(()=>{
    //     console.log(data)
    // } , [])

    return (
        <>
            <div
                className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? "border-black" : " border-[#B8C6F5]"
                    }  border   rounded-md p-4 mt-1 cursor-pointer`}
                onClick={() => onSelect && onSelect(data)}
            >
                <div className="flex items-center justify-between mb-3">
                    <p className="text-[#A1A1A1] font-bold">
                        {selectedLanguage?.name == "English" ? "User Restricted Discount" : translation[1227]?.value ? translation[1227]?.value : "User Restricted Discount"}
                    </p>
                    {onRemoveOffer && selected &&
                        <button
                            className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                            onClick={onRemoveOffer}
                        >
                            <Svgs.Delete fill="#767A87" />
                        </button>
                    }
                </div>
                <div className="text-black font-semibold mb-1 w-full">{data?.promotion_name}</div>

                <div className="">
                    <div className="flex items-center justify-between">
                        <p className="text-[#2a2a2a]">
                            {selectedLanguage?.name == "English" ? "Get" : translation[299]?.value ? translation[299]?.value : "Get"}
                            <span className="font-bold">{data?.discount_percentage}%</span>
                            {selectedLanguage?.name == "English" ? "Discount for" : translation[1228]?.value ? translation[1228]?.value : "Discount for"}

                            {data.clients?.map((client, index) => <>
                                {index > 0 && ', '}
                                <span key={index} className="font-bold">"{client.full_name}"</span></>)} </p>
                    </div>
                </div>
            </div>
        </>
    )
}

UserRestrictedDiscountCard.defaultProps = {
    data: {},
    selected: false,
    onSelect: () => { }
}

const UserRestrictedDiscount = ({ setNotFound, setProductsData, userRestricted, MODULE_TYPE, servicesRedux, onSelect, setSelServGroups, setServiceData }) => {

    const state = useSelector(state => state)
    const [discount_data, setDiscountData] = useState([])

    const dispatch = useDispatch()

    const handle_select_appointment = (data) => {
        let selected_client = state?.appointment?.selected_client
        if (Object.keys(selected_client).length == 0) {
            toast.info('Please select Client first')
        }
        setSelServGroups(prev => {
            prev.forEach(srv_grp => {
                srv_grp.services?.forEach(service => {
                    service.priceservice?.forEach(itm_price => {
                        itm_price.discount = data?.discount_percentage
                        itm_price.discount_price = (itm_price.price * (100 - data?.discount_percentage) / 100)
                    })
                })
            })
            return prev
        })

    }

    const handle_select_services = (data) => {
        let selectedServic = data?.excluded_services?.map(itm => itm.id)
        setServiceData(prev => {
            let prev_new = [...prev]
            prev_new.forEach(prev_item => {
                prev_item.priceservice?.forEach(itm_price => {
                    itm_price.discount = data?.discount_percentage
                    itm_price.discount_price = (itm_price.price * (100 - data?.discount_percentage) / 100)
                })
            })
            if (selectedServic?.length > 0) {
                let newResults = prev_new?.length > 0 && prev_new?.filter(ser => {
                    if (!selectedServic?.find(s => s == ser?.id)) return true
                })
                prev_new = newResults;
            }
            return prev_new
        })
        dispatch({
            type: "ADD_BULK_ITEMS_TO_CART",
            payload: {
                'services': [],
                'client_type': "In_Saloon",
                is_promotion: true,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
            }
        });
    }
    const handle_select_products = (data) => {

        let selected_location = state.locations.selected_location
        selected_location = state.locations.locations?.find(itm => itm.id == selected_location)
        let selected_currency_id = undefined
        let product = data?.excluded_products?.map(ep => ep.id)

        if (selected_location && selected_location.currency) {
            selected_currency_id = selected_location.currency.id
        }

        setProductsData(prev => {
            let prev_new = [...prev]
            prev_new.forEach(prev_item => {
                let retail_price = prev_item?.currency_retail_price?.find(rt_price => rt_price.currency == selected_currency_id)

                prev_item.discount = data?.discount_percentage
                if (retail_price && retail_price?.retail_price) {
                    prev_item.discount_price = (retail_price?.retail_price * (100 - data?.discount_percentage) / 100)
                }
            })
            if (product?.length > 0) {
                let newResults = prev_new?.length > 0 && prev_new?.filter(ser => {
                    if (!product?.find(s => s == ser?.id)) return true
                })
                prev_new = newResults
            }

            return prev_new
        })
        dispatch({
            type: "ADD_BULK_ITEMS_TO_CART",
            payload: {
                'services': [],
                'client_type': "In_Saloon",
                is_promotion: true,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
            }
        });
    }

    const handle_select = (data) => {
        dispatch({
            type: CLIENT.SET_SPECIFIC_CLIENTS_FOR_CHECKOUT,
            payload: { 'clients': data.clients, 'client_discount': data?.discount_percentage }
        });

        let funcs = {
            'APPOINTMENT': () => handle_select_appointment(data),
            'SERVICE': () => handle_select_services(data),
            'PRODUCT': () => handle_select_products(data),
        }
        funcs[MODULE_TYPE] && funcs[MODULE_TYPE]()

        onSelect(
            [],
            false,
            {
                is_client_compulsary: true,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
                selected_promotion: data,
            }
        )


        // dispatch({
        //     type: "ADD_BULK_ITEMS_TO_CART",
        //     payload: {'services' : new_items}
        // });
        // onSelect(
        //     [], 
        //     false,
        // )
    }

    useEffect(() => {
        if (!state.client.clients_updated) {
            dispatch(
                getClient()
            )
        }
    }, [])

    useEffect(() => {
        let rest_discounts = [
            ...userRestricted
        ]

        if (!state.client?.clients) {
            return
        }

        rest_discounts.forEach(itm => {

            let clients = state.client?.clients?.filter(clnt => itm?.client?.includes(clnt.id))
            itm.clients = clients
        })

        let new_data = rest_discounts.filter(itm => {
            if ((MODULE_TYPE == 'APPOINTMENT' || MODULE_TYPE == 'SERVICE') && itm.corporate_type == 'All Service') {
                return true
            }
            else if (MODULE_TYPE == 'PRODUCT' && itm.corporate_type == 'Retail Product') {
                return true
            }
        })

        setDiscountData(new_data)

    }, [state.client.clients_updated])

    return (
        <>
            {
                discount_data?.map((retail, index) => {
                    return (
                        <>
                            <UserRestrictedDiscountCard
                                data={retail}
                                key={index}
                                onSelect={handle_select}
                            />
                        </>
                    )
                })
            }
        </>
    )
}

UserRestrictedDiscount.defaultProps = {
    spend_some_amount: []
}


export default UserRestrictedDiscount