import Popup from 'Components/TenantApp/Elements/Popup'
import React from 'react'
import useAddTraining from './helper'
import Textarea from 'Components/TenantApp/Elements/Textarea'
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import DateInput from 'Components/TenantApp/Elements/DateInput'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import moment from 'moment'

const AddTrainingRequestPopup = ({ open, close, onSuccessCall }) => {
    const {
        translation,
        selectedLanguage,
        employeeRedux,
        employeeLoader, onChangeHandler,
        LocationsList, errors,
        trainingData, onSubmitHandler,
        employeeSkills, loading
    } = useAddTraining(onSuccessCall, close)
    return (
        <>
            <Popup
                open={open}
                close={close}
                heading={selectedLanguage?.name == "English" ? "Add Training Requests" : translation[1707]?.value ? translation[1707]?.value : "Add Training Requests"}
            >
                <div className="space-y-6">
                    <div className="grid grid-cols-2 gap-3">
                        <Dropwdown
                            title={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                            showTitle={true}
                            name={"student"}
                            error={errors?.student}
                            value={trainingData?.student}
                            onChange={onChangeHandler}
                            loading={employeeLoader}
                            custom_min_width={'sm:min-w-[15rem] min-w-[20rem]'}
                            options={[
                                ...employeeRedux?.map((empl, i) => {
                                    return {
                                        label: empl.full_name,
                                        value: empl.id,
                                        otherData: {
                                            image: empl?.image,
                                            DataOne: empl.email,
                                            showImage: true,
                                            index: i
                                        },
                                        email: empl.email,
                                        mobile_number: empl.mobile_number
                                    };
                                })
                            ]}
                        />
                        <Dropwdown
                            title={selectedLanguage?.name == "English" ? "Requested By" : translation[1708]?.value ? translation[1708]?.value : "Requested By"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                            showTitle={true}
                            name={"teacher"}
                            value={trainingData?.teacher}
                            error={errors?.teacher}
                            onChange={onChangeHandler}
                            loading={employeeLoader}
                            custom_min_width={'sm:min-w-[15rem] min-w-[20rem]'}
                            options={[
                                ...employeeRedux?.filter(itm => itm?.id !== trainingData?.student)?.map((empl, i) => {
                                    return {
                                        label: empl.full_name,
                                        value: empl.id,
                                        otherData: {
                                            image: empl?.image,
                                            DataOne: empl.email,
                                            showImage: true,
                                            index: i
                                        },
                                        email: empl.email,
                                        mobile_number: empl.mobile_number
                                    };
                                })
                            ]}
                        />

                        <Dropwdown
                            title={selectedLanguage?.name == "English" ? "Select Skill" : translation[1709]?.value ? translation[1709]?.value : "Select Skill"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Skill" : translation[1709]?.value ? translation[1709]?.value : "Select Skill"}
                            // setPage={setEmployeePage}
                            // page={employeePage}
                            // setDropdownSearch={setEmployeeSearch}
                            // dropdownSearch={employeeSearch}
                            // lastPage={employeePaginationData?.employeePages}
                            // currentPage={employeePaginationData?.employeeCurrentPage}
                            showTitle={true}
                            name={"skill"}
                            value={trainingData?.skill}
                            error={errors?.skill}
                            onChange={onChangeHandler}
                            loading={employeeLoader}
                            custom_min_width={'sm:min-w-[15rem] min-w-[20rem]'}
                            options={[
                                ...employeeSkills?.map((empl, i) => {
                                    return {
                                        label: empl.name,
                                        value: empl.id,
                                    };
                                })
                            ]}
                        />

                        <Dropwdown
                            error={errors?.location}
                            name={"location"}
                            value={trainingData?.location}
                            onChange={onChangeHandler}
                            options={LocationsList?.map((adrs) => {
                                return {
                                    label: adrs?.address_name,
                                    value: adrs?.id,
                                };
                            })}
                            title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                            placeholder={selectedLanguage?.name == "English" ? "Filter by Location" : translation[50]?.value ? translation[50]?.value : "Filter by Location"}
                            custom_min_width={'sm:min-w-[15rem] min-w-[20rem]'}
                        />

                        <LoginInput
                            errorClass={"!static"}
                            parentClass={"w-full"}
                            title={selectedLanguage?.name == "English" ? "Select Date" : translation[111]?.value ? translation[111]?.value : "Select Date"}
                            type='date'
                            name='date_requested'
                            value={trainingData?.date_requested}
                            error={errors?.date_requested}
                            min={moment().format("YYYY-MM-DD")}
                            max={"2050-01-01"}
                            onChange={onChangeHandler}
                        />
                    </div>

                    <Textarea
                        title={selectedLanguage?.name == "English" ? "Add Comment" : translation[1570]?.value ? translation[1570]?.value : "Add Comment"}
                        rows={5}
                        limit={150}
                        placeholder={selectedLanguage?.name == "English" ? "Add text ..." : translation[1571]?.value ? translation[1571]?.value : "Add text ..."}
                        required={true}
                        name={'student_comment'}
                        value={trainingData?.student_comment}
                        onChange={onChangeHandler}
                        errorMessage={errors?.student_comment}
                    // onBlur={() => onBlurHandler({ name: "comment" })}
                    />

                    <LoginBtn
                        className={'capitalize'}
                        text={selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                        onClick={onSubmitHandler}
                        loading={loading}
                        disabled={loading}
                    />
                </div>

            </Popup>
        </>
    )
}

export default AddTrainingRequestPopup