import { apiErrorMessage } from 'Constants/Data/Errors';
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addCategroy, deleteCategory, getAllCategories, getAllCategoriesWithNoPagination, getCategoriesDropdownListPagination, updateCategory } from '../../../../../../Adapters/Api/categories';
import useObjectKeys from '../../../../../../Hooks/useObjectKeys';
import useSetDataInLocalStorage from '../../../../../../Hooks/useSetDataInLocalStorage';
import useValidations from '../../../../../../Hooks/useValidations';
import { addCategories, editCategroy, removeCategroy } from '../../../../../../Redux/TenantRedux/Actions/categoryActions';

function useCategory() {
    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys();
    const dispatch = useDispatch();
    const categoryRedux = useSelector((state) => state?.category?.categories);
    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");
    const category_id = getDataFromLocalStorage("category_id");

    const [currentPage, setCurrentPage] = useState(1);
    const [search_text, setSearchText] = useState('')
    const [loader, setLoader] = useState(true)
    const [noPaginationLoader, setNoPaginationLoader] = useState(true)
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [AddCategory, setAddCategory] = useState(false);
    const [categoryData, setCategory] = useState({
        name: "",
        is_active: true,
    })


    // *******************  Category modal handler *******************
    const categoryModalToggler = () => {
        setAddCategory(!AddCategory);
        setCategory({
            name: "",
            is_active: false,
        })
        setErrors({})
    }


    // ******************* on change handler *******************
    const categoryHandleChange = (e, fieldName = " ") => {

        if (e?.target) {
            const { name, value } = e.target;
            setCategory((prev) => ({ ...prev, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: "" }))
        }

    }


    // ******************* category submit *******************
    const onCategorySumbit = async (success) => {
        const { name } = categoryData
        setErrors(validation({ name }));
        let result = validation({ name });
        if (objectKeyConvertToArray(result)?.length === 0) {
            let formatData;
            if (isEdit) {
                setLoading(true)
                //setting data to backend format
                formatData = {
                    ...categoryData,
                    category: categoryData.id
                }
                //update api 
                const response = await updateCategory(formatData, access_token);
                if (response.status === 200) {
                    let updatedcategory = response?.data?.response?.categories;
                    let successMessage = response?.data?.response?.message;
                    // dispatch(editCategroy(updatedcategory));
                    setLoading(false)
                    setCurrentPage(1)
                    dispatch(getAllCategories(1, search_text, setLoader))
                    dispatch(getAllCategoriesWithNoPagination(search_text, setNoPaginationLoader))
                    dispatch(getCategoriesDropdownListPagination(1))
                    toast.success(successMessage, { toastId: "toast" });
                    categoryModalToggler();
                    setCategory({
                        name: "",
                        is_active: false,
                    })
                    success && success()
                } else {
                    setLoading(false);
                    toast.error(apiErrorMessage, { toastId: "toast" });
                }
            } else {
                setLoading(true);

                const response = await addCategroy(categoryData, access_token);
                if (response.status === 201) {
                    if (localStorage.getItem('category_create_from_sale')) {
                        dispatch(getAllCategories())
                        dispatch(getCategoriesDropdownListPagination(1))
                        localStorage.removeItem('category_create_from_sale')
                    }
                    let category = response?.data?.response?.categories;
                    let successMessage = response?.data?.response?.message;
                    // dispatch(addCategories(category));
                    setLoading(false);
                    categoryModalToggler()
                    toast.success(successMessage, { toastId: "toast" });
                    success && success()
                } else {
                    setLoading(false);
                    toast.error(apiErrorMessage, { toastId: "toast" });
                }
            }
        }

    }


    // delete  category 
    const categoryDeletehandler = async (id) => {

        let response = await deleteCategory(id, access_token);
        if (response.status_code === 200) {
            let successMessage = response?.response?.message;
            toast.success(successMessage, { toastId: "toast" });
            setCurrentPage(1)
            dispatch(getAllCategories(1, search_text, setLoader))
            dispatch(getAllCategoriesWithNoPagination(search_text, setNoPaginationLoader))
            // dispatch(removeCategroy(id));
        }
    }

    const categoryEdithandler = (id) => {
        storeDataInLocalStorage("category_id", id)
        setIsEdit(true);
        categoryModalToggler();

        const selectedCategory = categoryRedux.find((item) => item.id === id);

        if (selectedCategory) {
            setCategory((prev) => ({ name: selectedCategory.name, is_active: selectedCategory.is_active }))
        }


    }

    return {
        AddCategory, categoryData, categoryModalToggler, categoryHandleChange, onCategorySumbit, onBlurHandler,
        errors, categoryDeletehandler, categoryEdithandler, setIsEdit, loading, setCategory, currentPage, setCurrentPage,
        loader, setLoader, noPaginationLoader, setNoPaginationLoader, search_text, setSearchText
    }
}

export default useCategory
