import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AccountSetupCard from "./AccountSetupCard";

import { getProfileCompletion } from '../../../Adapters/Api/Business/index'
import Svgs from "Assets/svgs";

const DashboardSetup = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch()

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [profileCompletion, setProfileCompletion] = useState({})

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    if (state?.business?.business?.id) {
      dispatch(
        getProfileCompletion({ business_id: state?.business?.business?.id })
      )
    }
  }, [state?.business?.business])

  useEffect(() => {
    if (state.business.profile_completion) {
      setProfileCompletion(state.business.profile_completion)
    }
  }, [state?.business?.profile_completion])

  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

      <div className="flex items-center gap-5 lg:w-fit w-full lg:px-0 lg:pb-[2rem] lg:py-0 p-3 pb-8">
        <Svgs.Back link="/dashboard/account" />
        <h2 className="font-semibold text-2xl">
          {selectedLanguage?.name == "English" ? "Account Setup" : translation[645]?.value ? translation[645]?.value : "Account Setup"}
        </h2>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem] xl:pr-[4rem] pr-0">
        {(employee &&
          employeePermissions?.account_root_access?.includes("view") ||
          employeePermissions?.account_business_setting?.includes("edit") ||
          employeePermissions?.pos_root_access?.includes("view")) ||
          !employee ? (
          <AccountSetupCard
            link="/dashboard/account/setup/business-settings"
            icon={<Svgs.Business_Settings />}
            title={selectedLanguage?.name == "English" ? "Business Settings" : translation[654]?.value ? translation[654]?.value : "Business Settings"}
            description={selectedLanguage?.name == "English" ? "Manage business info, time and calendar settings, and business locations." : translation[655]?.value ? translation[655]?.value : "Manage business info, time and calendar settings, and business locations."}
            showProgress={true}
            progressData={profileCompletion?.business_settings}
          />
        ) : (
          ""
        )}

        {(employee &&
          employeePermissions?.account_root_access?.includes("view") ||
          employeePermissions?.account_business_setting?.includes("edit") ||
          employeePermissions?.pos_root_access?.includes("view")) ||
          !employee ? (
          <AccountSetupCard
            link="/dashboard/account/setup/business-settings/additional-information/"
            icon={<Svgs.Dashboard_Reports />}
            title={selectedLanguage?.name == "English" ? "Business Info" : translation[656]?.value ? translation[656]?.value : "Business Info"}
            description={selectedLanguage?.name == "English" ? "Manage your business category, team size, and other business info." : translation[657]?.value ? translation[657]?.value : "Manage your business category, team size, and other business info."}
            showProgress={true}
            progressData={profileCompletion?.business_info}
          />
        ) : (
          ""
        )}

        {(employee &&
          employeePermissions?.account_root_access?.includes("view") ||
          employeePermissions?.account_finance_setting?.includes("edit") ||
          employeePermissions?.pos_root_access?.includes("view")) ||
          !employee ? (
          <AccountSetupCard
            link="/dashboard/account/setup/financial-settings"
            icon={<Svgs.Financial_Settings />}
            title={selectedLanguage?.name == "English" ? "Financial Settings" : translation[658]?.value ? translation[658]?.value : "Financial Settings"}
            description={selectedLanguage?.name == "English" ? "Manage payment methods, taxes, and apply tax on business locations." : translation[659]?.value ? translation[659]?.value : "Manage payment methods, taxes, and apply tax on business locations."}
            showProgress={true}
            progressData={profileCompletion?.financial_settings}
          />
        ) : (
          ""
        )}

        {/* <AccountSetupCard
          link="/dashboard/account/setup/booking-settings"
          icon={<Svgs.Booking_Settings />}
          title="Booking Settings"
          description="Manage booking settings for the appointment booking"
        /> */}

        {/* {(employee &&
          employeePermissions?.account_root_access?.includes("view") ||
          employeePermissions?.account_service_management?.includes("edit") ||
          employeePermissions?.pos_root_access?.includes("view")) ||
          !employee ? (
          <AccountSetupCard
            link="/dashboard/account/setup/service-management"
            icon={<Svgs.ServiceManagement />}
            title={selectedLanguage?.name == "English" ? "Service Management" : translation[660]?.value ? translation[660]?.value : "Service Management"}
            description={selectedLanguage?.name == "English" ? "Manage services, and service groups." : translation[661]?.value ? translation[661]?.value : "Manage services, and service groups."}
            showProgress={true}
            progressData={profileCompletion?.service_management}
          />
        ) : (
          ""
        )} */}
        {(employee &&
          employeePermissions?.account_root_access?.includes("view") ||
          employeePermissions?.pos_root_access?.includes("view")) ||
          !employee ? (
          <AccountSetupCard
            link="/dashboard/account/setup/theme-customization"
            icon={<Svgs.Theme_customization />}
            title={selectedLanguage?.name == "English" ? "Theme Customization" : translation[662]?.value ? translation[662]?.value : "Theme Customization"}
            description={selectedLanguage?.name == "English" ? "Manage theme color, menu, and calendar customizations." : translation[663]?.value ? translation[663]?.value : "Manage theme color, menu, and calendar customizations."}
          />
        ) : (
          ""
        )}
        {(employee &&
          employeePermissions?.account_root_access?.includes("view") ||
          employeePermissions?.pos_root_access?.includes("view")) ||
          !employee ? (
          <AccountSetupCard
            link="/dashboard/account/setup/multi-language"
            icon={<Svgs.Multi_Language />}
            title={selectedLanguage?.name == "English" ? "Business  Language" : translation[664]?.value ? translation[664]?.value : "Business  Language"}
            description={selectedLanguage?.name == "English" ? "Manage language preferences for your business with respect to locations." : translation[665]?.value ? translation[665]?.value : "Manage language preferences for your business with respect to locations."}
          />

        ) : (
          ""
        )}

        {(employee &&
          employeePermissions?.account_root_access?.includes("view") ||
          (employeePermissions?.account_notification_setting?.includes("edit") ||
            employeePermissions?.pos_root_access?.includes("view"))) ||
          !employee ? (
          <AccountSetupCard
            link="/dashboard/account/setup/notification"
            icon={<Svgs.Notification_setting />}
            title={selectedLanguage?.name == "English" ? "Notification Settings" : translation[666]?.value ? translation[666]?.value : "Notification Settings"}
            description={selectedLanguage?.name == "English" ? "Manage staff, clients, admin, and stock notifications." : translation[667]?.value ? translation[667]?.value : "Manage staff, clients, admin, and stock notifications."}

          />
        ) : (
          ""
        )}

        <AccountSetupCard
          link="/dashboard/account/setup/invoice-translation"
          icon={<Svgs.Notification_setting />}
          title={selectedLanguage?.name == "English" ? "Invoice Translation" : translation[668]?.value ? translation[668]?.value : "Invoice Translation"}
          description={selectedLanguage?.name == "English" ? "Manage invoices in multi-language according to different business locations." : translation[669]?.value ? translation[669]?.value : "Manage invoices in multi-language according to different business locations."}
        />


      </div>
    </div>
  );
};

export default DashboardSetup;
