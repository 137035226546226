import { useSelector } from "react-redux"
import { useState } from "react";

const useHolidayLeaveManagment = () => {
    const state = useSelector(state => state)
    const [CreateHoliday, setCreateHoliday] = useState(false);
    const [CreateLeave, setCreateLeave] = useState(false);

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
  
  return {
    translation,
    selectedLanguage,
    CreateHoliday,
    setCreateHoliday,
    CreateLeave,
    setCreateLeave,
  }
}

export default useHolidayLeaveManagment