import { LogoSvg } from "../../../Assets/svgs/Logo/LogoSvg";
import SocialMediaPlatform from "../../../Components/NstyleApp/SocialMediaPlatforms";
import Sweepings from "../../../Components/NstyleApp/Sweepings";
import { useNavigate } from "react-router-dom";
import UserVerificationOTP from "../../../Components/NstyleApp/UserOTPVerification";
import { useState } from "react";
import ForgetScreen3 from "../../../Components/TenantApp/Elements/ForgetElements/ForgetScreen3";

const ForgetScreen = (props) => {
  const navigate = useNavigate();
  const [forgot_screen, setForgotScreen] = useState(false);

  const [verified_details, setVerifiedDetails] = useState({})

  return (
    <div className="min-h-[100vh] grid grid-cols-1 lg:grid-cols-2">
      <div className="flex flex-col lg:pt-[3rem] lg:px-[4rem] px-8 py-5">
        <div className="flex items-center justify-between">
          <LogoSvg />
          <p className="text-[#7B7B7B] text-sm lg:text-left text-right">
            Don't have an account?&nbsp;
            <span
              className="text-primary cursor-pointer font-semibold transition-all hover:underline"
              onClick={() => {
                // navigate("/auth/signup");
                navigate("/auth/account-type");
              }}
            >
              Signup Now
            </span>
          </p>
        </div>
        {!forgot_screen && <UserVerificationOTP ignore_activity={true} onVerify={(data) => { setForgotScreen(true); setVerifiedDetails(data) }} />}
        {
          (forgot_screen && verified_details.method_type && verified_details.sent_to) &&
          <ForgetScreen3 sent_to={verified_details.sent_to} method_type={verified_details.method_type} />
        }
      </div>
      <div className="hidden lg:block" style={{
        backgroundImage: 'linear-gradient(to bottom left, rgba(166,77,255,1), rgba(54,67,120,1), rgba(54,67,120,1), rgba(87,120,255,1))'
      }}>
        <div className="px-8 py-5 lg:px-[4rem] lg:pt-[3rem] flex flex-col h-full">
            <div className="flex justify-end text-white"><span className="cursor-pointer" onClick={() => {
                navigate('/help')
            }}>Help?</span></div>
          <Sweepings />
        </div>
      </div>
    </div>
  );
};

export default ForgetScreen;
