import React from 'react'

const LoginSocial = (props) => {
    return (
        <a target='_blank' href={props.link ? props.link : '#'}>
            <div className={`bg-primary cursor-pointer h-[35px] w-[35px] rounded-full flex items-center justify-center ${props?.className}`}>
                {props.children}
            </div>
        </a>
    )
}

export default LoginSocial