import { apiErrorMessage } from 'Constants/Data/Errors';
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ClientGroups from '.';
import { createClientGroup, deleteClientGroup, getClientGroup, getClientGroupWithNoPagination, updateClientGroup } from '../../../../../Adapters/Api/clientGroup';
import useObjectKeys from '../../../../../Hooks/useObjectKeys';
import useSetDataInLocalStorage from '../../../../../Hooks/useSetDataInLocalStorage';
import useValidations from '../../../../../Hooks/useValidations';
import { addClientGroup, editClientGroup, removeClientGroup } from '../../../../../Redux/TenantRedux/Actions/clientGroupActions';

function useClientGroup() {
    const formRef = useRef()
    const reduxState = useSelector(state => state)
    const translation = reduxState?.languages?.language_translation
    const selectedLanguage = reduxState?.languages?.selected_language
    const clientRedux = useSelector((state) => state?.client?.clients);
    const clientGroupRedux = useSelector((state) => state?.clientGroup?.clientGroups);
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const { setErrors, validation, errors, onBlurHandler, validateEmail } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys();
    const dispatch = useDispatch();

    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");

    const [AddClient, setAddClient] = useState(false)
    const [loading, setLoading] = useState(false)
    const [ShowDetails, setShowDetails] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [search_text, setSearchText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [loader, setLoader] = useState(true)
    const [noPaginationLoader, setNoPaginationLoader] = useState(true)
    const [clientGroup, setClientGroup] = useState({
        name: "",
        email: "",
        client: [],
    });


    //***************************** client group modal handler *********************************
    const clientModalHandler = () => {
        setAddClient(!AddClient);
        setClientGroup({ name: "", email: "", client: [] });
        setErrors({})
    }



    //**************************** set client dropdown list in client group *********************************
    let clientOptionList;
    if (clientRedux && clientRedux?.length >= 1) {
        clientOptionList = clientRedux && clientRedux?.map((item, i) => {
            return ({
                label: item.full_name,
                value: item.id,
                otherData: {
                    image: item?.image,
                    DataOne: item.email,
                    showImage: true,
                    index: i
                },
                email: item.email,
                mobile_number: item.mobile_number
                // label: item.full_name, value: item.id 
            });
        });
    }




    // ******************* client group on change handler *******************
    const clientGroupHandleChange = (e) => {

        if (e?.target) {
            const { name, value } = e.target;
            if (value != 'no_value') {
                if (name === "client" && value != 'no_value') {

                    if (!clientGroup?.client?.includes(value)) {
                        setClientGroup((prev) => ({ ...prev, [name]: [...prev.client, value] }));
                        setErrors((prev) => ({ ...prev, [name]: "" }))
                        return;
                    } else {
                        setClientGroup({
                            ...clientGroup,
                            client: [
                                ...clientGroup.client.filter(client_id => client_id != value)
                            ]
                        })
                        return;
                    }
                } else {
                    setClientGroup((prev) => ({ ...prev, [name]: value }));
                    setErrors((prev) => ({ ...prev, [name]: "" }))
                }

            }
        }
    }




    // ******************* create client group on submite handler *******************
    const onClientGroupSubmit = () => {
        const { name, client, email } = clientGroup;
        let requiredFields = {}
        if (client?.length == 0) {
            requiredFields = { name, client: client?.length > 0 ? client : "" }
        } else {
            requiredFields = { name }
        }
        // const requiredFields = { name, client: client.length > 0 ? client : "" }
        const isEmailValid = email && validateEmail(email)
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);

        if (objectKeyConvertToArray(result)?.length === 0 && ((email && isEmailValid) || !email)) {
            setLoading(true)
            if (isEdit) {

                updateClientGroup(clientGroup, access_token).
                    then((result) => {

                        if (result?.status_code === 200) {
                            // dispatch(editClientGroup(result?.response?.ClientGroupUpdate))
                            setCurrentPage(1)
                            dispatch(getClientGroup(1, search_text, setLoader))
                            dispatch(getClientGroupWithNoPagination(search_text, setNoPaginationLoader))
                            setLoading(false)
                            if (!isToastVisible) {
                                toast.success(result?.response?.message, {
                                    onClose: () => setIsToastVisible(false),
                                });
                                setIsToastVisible(true);
                            }
                            clientModalHandler();
                        }
                    }).catch((error) => {
                        setLoading(false)
                        if (!isToastVisible) {
                            toast.error(apiErrorMessage, {
                                onClose: () => setIsToastVisible(false),
                            });
                            setIsToastVisible(true);
                        }
                    })

            } else {
                createClientGroup({ ...clientGroup, business: businessData.id }, access_token).then((result) => {
                    if (result.status == 201) {
                        // dispatch(addClientGroup(result?.data?.response?.ClientGroup));
                        setCurrentPage(1)
                        dispatch(getClientGroup(1, search_text, setLoader))
                        dispatch(getClientGroupWithNoPagination(search_text, setNoPaginationLoader))
                        setLoading(false)
                        if (!isToastVisible) {
                            toast.success(result?.data?.response?.message, {
                                onClose: () => setIsToastVisible(false),
                            });
                            setIsToastVisible(true);
                        }
                        clientModalHandler();
                    }
                }).catch((error) => {
                    setLoading(false)
                    if (!isToastVisible) {
                        toast.error(apiErrorMessage, {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }
                })
            }

        } else {
            formRef.current?.scrollIntoView({ behavior: "smooth" });
            if (email && !isEmailValid) {
                setErrors((prev) => ({
                    ...prev,
                    email: "Invalid email"
                }))
            }
        }
    }




    // ********************* DELETE CLIENT GROUP ******************
    const clientGroupDeleteHandler = (id) => {
        deleteClientGroup(id, access_token).then((response) => {
            if (response.status_code === 200) {
                // dispatch(removeClientGroup(id));
                setCurrentPage(1)
                dispatch(getClientGroup(1, search_text, setLoader))
                dispatch(getClientGroupWithNoPagination(search_text, setNoPaginationLoader))
                if (!isToastVisible) {
                    toast.success(response?.response?.message, {
                        onClose: () => setIsToastVisible(false),
                    });
                    setIsToastVisible(true);
                }
            }
        }).catch((error) => {
            if (!isToastVisible) {
                toast.error("Somethings went wrong!", {
                    onClose: () => setIsToastVisible(false),
                });
                setIsToastVisible(true);
            }
        })

    }




    // **************************** Eidt Staff group *********************************
    const clientGroupEditHandler = async (id) => {
        clientModalHandler();
        setIsEdit(true);
        const selectedClient = clientGroupRedux && clientGroupRedux?.find((item) => item.id === id);
        const clientGroup = selectedClient?.client?.map((itm) => itm.id);
        if (selectedClient) {
            setClientGroup({
                name: selectedClient?.name,
                email: selectedClient?.email,
                client: clientGroup ? clientGroup : [],
                client_group_id: selectedClient?.id,
                business: businessData.id
            });
        }


    }



    return {
        clientGroup, setClientGroup, isEdit, setIsEdit, loading, clientOptionList, clientGroupHandleChange, onClientGroupSubmit,
        clientGroupDeleteHandler, clientGroupEditHandler, errors, onBlurHandler, clientModalHandler, ShowDetails, setShowDetails,
        AddClient, setAddClient, translation, selectedLanguage, search_text, setSearchText, currentPage, setCurrentPage, loader,
        setLoader, isToastVisible, setIsToastVisible, formRef, noPaginationLoader, setNoPaginationLoader
    }
}

export default useClientGroup
