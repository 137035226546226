import React from 'react'
import {
    Chart as ChartJS,
    ArcElement,
    Legend,
    Tooltip,
  } from 'chart.js';

  import { Doughnut } from 'react-chartjs-2';

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
  );

const Donet = ({appointment_progress,chartData, chartDataColors, circleCutout}) => {

    const data = {
        labels:['Completed','Canceled'],
        datasets:[{
            label:'poll',
            data: chartData,
            backgroundColor: chartDataColors,
            borderWidth:0,
        }]
    }

    const options ={
        cutout: circleCutout,
        plugins: {
          legend:false,
          tooltip: {
            // Disable the on-canvas tooltip
            enabled: (appointment_progress?.finished_appointment > 0 || appointment_progress?.cancel_appointment > 0) ? true : false,
            usePointStyle: true,
            bodyAlign: "center",
            yAlign: "bottom",
            xAlign: "center",
            footerAlign: "center",
            titleAlign: "center",
            displayColors: false,
            pointStyle: false,
            padding: 10,
            caretPadding: 10,
            bodyFont: {
              size: 15,
            },
            callbacks: {
              title: function () {
                return "";
              },
            },
          },
        }
    }

  return (
    <div>
        <Doughnut 
        data={data}
        options={options}
        width={150}
        height={150}
        >

        </Doughnut>
    </div>
  )
}

export default Donet