import { EMPLOYEES } from "../../ActionTypes/employeeTypes";


export const getEmployees = (data) => {

    return {
        type: EMPLOYEES.GET_EMPLOYEES,
        payload: data
    }
}

export const getEmployeesSalesCart = (data) => {

    return {
        type: EMPLOYEES.GET_EMPLOYEES_SALES_CART,
        payload: data
    }
}

export const getEmployeesDropdownPaginationData = (data) => {

    return {
        type: EMPLOYEES.GET_EMPLOYEES_PAGINATION_DATA,
        payload: data
    }
}

export const getEmployeesDropdown = (data) => {
    return {
        type: EMPLOYEES.GET_EMPLOYEES_DROPDOWN,
        payload: data
    }
}

export const getEmployeesStaffData = (data) => {

    return {
        type: EMPLOYEES.GET_EMPLOYEES_STAFF_DATA,
        payload: data
    }
}

export const getEmployeesWithoutPagination = (data) => {

    return {
        type: EMPLOYEES.GET_EMPLOYEES_WITHOUT_PAGINATION,
        payload: data
    }
}


export const addEmployees = (data) => {
    return {
        type: EMPLOYEES.ADD_EMPLOYEE,
        payload: data
    }
}

export const editEmployee = (data) => {
    return {
        type: EMPLOYEES.UPDATE_EMPLOYEE,
        payload: data
    }
}

export const removeEmployee = (data) => {
    return {
        type: EMPLOYEES.DELETE_EMPLOYEE,
        payload: data
    }
}
export const getEmployeeSkills = (data) => {
    return {
        type: EMPLOYEES.GET_EMPLOYEE_SKILL,
        payload: data
    }
}
export const getEmployeeTrainingData = (data) => {
    return {
        type: EMPLOYEES.GET_EMPLOYEE_TRAINING,
        payload: data
    }
}
