import Svgs from 'Assets/svgs';
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Popup from 'Components/TenantApp/Elements/Popup';
import React from 'react'
import useAddWeekend from './helper';
import InfiniteScroll from 'react-infinite-scroll-component';
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm';
import AddAppointmentRightClient from 'Components/TenantApp/Dashboard/Calendar/Elements/AddAppointmentRightClient';
import NoClientFound from 'Components/Utility/NoClientFound';
import ClientCardShimmer from 'Components/Utility/Shimmers/ClientCardShimmer';

const AddWeekend = ({ data, setIsEdit, isEdit, createWeekend, setCreateWeekend, selectedDate, successCallBack }) => {
    const {
        translation, selectedLanguage, updatedEmployees, employeePaginationData, setEmployeeSearch,
        employeeSearch, employeeLoader, handleChange, isSelectAll, setIsSelectAll, weekendData,
        employeeSecondLoader, setEmployeeSecondLoader, setEmployeePage, errors, submitLoader,
        onSubmitHandler, handleCloseFunction, selectedEmployees, removeSelectedEmployee
    } = useAddWeekend({ setCreateWeekend, data, setIsEdit, isEdit, selectedDate, successCallBack })
    const { employees } = weekendData

    return (
        <>
            {createWeekend && (
                <Popup
                    size={"lg"}
                    heading={!isEdit ? selectedLanguage?.name == "English" ? "Create Weekend" : translation[1403]?.value ? translation[1403]?.value : "Create Weekend"
                        : selectedLanguage?.name == "English" ? "Edit Weekend" : translation[1403]?.value ? translation[1403]?.value : "Edit Weekend"}
                    open={createWeekend}
                    close={handleCloseFunction}
                >
                    <div className="flex flex-col relative gap-4">
                        <div className="flex items-center justify-between gap-2">
                            <h1 className="text-xl font-semibold">
                                {selectedLanguage?.name == "English" ? "Add Employee" : translation[1413]?.value ? translation[1413]?.value : "Add Employee"}
                            </h1>
                        </div>
                        <>
                            <LoginInput
                                title={''}
                                value={employeeSearch}
                                onChange={(e) => {
                                    setEmployeeSearch(e.target.value)
                                }}

                                placeholder={selectedLanguage?.name == "English" ? "Search Employee Name" : translation[1414]?.value ? translation[1414]?.value : "Search Employee Name"}
                            />
                            {selectedEmployees?.length > 0 && (
                                <div className="flex items-center flex-wrap gap-2">
                                    {selectedEmployees &&
                                        selectedEmployees?.map((item, i) => {
                                            return (
                                                <div className="px-1 py-1 rounded-lg items-center gap-3 flex justify-between border-[2px] border-primary w-max text-primary cursor-pointer">
                                                    <div
                                                        className="w-8 h-8 flex items-center bg-center bg-cover bg-no-repeat justify-center rounded-sm "
                                                        style={{
                                                            backgroundImage: `url('${item?.image
                                                                ? item?.image
                                                                : ""
                                                                }')`,
                                                        }}
                                                    >
                                                        {!item?.image && <Svgs.Employee />}
                                                    </div>
                                                    <div>{item?.full_name}</div>

                                                    <Svgs.Remove
                                                        onClick={() => {
                                                            removeSelectedEmployee(item?.id)
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                </div>
                            )}
                            <div className="flex items-center gap-3 justify-between">
                                {employeeLoader
                                    ? <>
                                        <div className='w-1/3 bg-gray-200 h-6 rounded-md animate-pulse' />
                                        <div className="bg-[#E4EAF0]  rounded-2xl px-4 py-2 animate-pulse">
                                            <div className="h-4 w-16"></div>
                                        </div>
                                    </>
                                    : <>
                                        <CheckBox
                                            label={selectedLanguage?.name == "English" ? "Select All" : translation[1415]?.value ? translation[1415]?.value : "Select All"}
                                            onChange={(e) => {
                                                setIsSelectAll(!isSelectAll)
                                                handleChange(e, 'all')
                                            }}
                                            disabled={updatedEmployees?.length == 0}
                                            checked={isSelectAll && updatedEmployees?.length == selectedEmployees?.length}
                                        />
                                        <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                            <p className="text-primary">
                                                {employees?.length ? employees?.length : 0} {' '}
                                                {selectedLanguage?.name == "English" ? "Selected" : translation[1434]?.value ? translation[1434]?.value : "Selected"}
                                            </p>
                                        </div>
                                    </>
                                }
                            </div>
                            <hr className="border" />
                            <div className="flex flex-col items-start gap-4 justify-start min-h-[17rem] max-h-[17rem] overflow-y-auto">
                                <div className='max-h-[30rem] overflow-y-auto w-full' id="scrollableDiv">
                                    {employeeLoader && !employeeSecondLoader
                                        ? [0, 1, 2, 3, 4]?.map(itm =>
                                            <ClientCardShimmer />
                                        )
                                        : <InfiniteScroll
                                            dataLength={updatedEmployees?.length ? updatedEmployees?.length : 10} //This is important field to render the next data
                                            next={() => {
                                                employeePaginationData?.employeeCurrentPage < employeePaginationData?.employeePages ? setEmployeeSecondLoader(true) : setEmployeeSecondLoader(false)
                                                setEmployeePage(employeePaginationData?.employeeCurrentPage + 1)
                                            }}
                                            hasMore={employeePaginationData && updatedEmployees?.length < employeePaginationData?.employeeAllCount && employeePaginationData?.employeeCurrentPage > 0 && employeePaginationData?.employeeCurrentPage < employeePaginationData?.employeePages}
                                            scrollableTarget="scrollableDiv"
                                            loader={employeeSecondLoader
                                                ? <div className="h-[4rem] flex items-center justify-center">
                                                    <PreloaderSm />
                                                </div>
                                                : ""
                                            }
                                        >
                                            {updatedEmployees?.length > 0
                                                ? updatedEmployees?.map((emp, index) => {
                                                    return (
                                                        <CheckBox
                                                            mainLabelClass={"!w-full"}
                                                            label={
                                                                <AddAppointmentRightClient
                                                                    data={emp}
                                                                    index={index}
                                                                    noId
                                                                />
                                                            }
                                                            name={'employee'}
                                                            value={emp?.id}
                                                            id={emp?.id}
                                                            onChange={handleChange}
                                                            checked={employees?.find(itm => emp?.id == itm) ? true : false}
                                                        />
                                                    )
                                                })
                                                : <NoClientFound />
                                            }
                                        </InfiniteScroll>
                                    }
                                </div>
                            </div>
                            {errors?.employees && (
                                <span className={"text-[#eb3b3b] flex items-center gap-1 text-xs w-full justify-end"}>
                                    <Svgs.I fill="#eb3b3b" />
                                    {selectedLanguage?.name == "English" ? "Add at least one employee" : translation[1416]?.value ? translation[1416]?.value : "Add at least one employee"}
                                </span>
                            )}
                            <hr className="border" />
                            <div className='flex justify-end items-end'>
                                <LoginBtn
                                    loading={submitLoader}
                                    onClick={onSubmitHandler}
                                    disabled={submitLoader}
                                    text={
                                        isEdit ?
                                            `${selectedLanguage?.name == "English" ? "Update Weekend" : translation[1416]?.value ? translation[1416]?.value : "Update Weekend"}`
                                            :
                                            `${selectedLanguage?.name == "English" ? "Add Weekend" : translation[1417]?.value ? translation[1417]?.value : "Add Weekend"}`
                                    }
                                />
                            </div>
                        </>
                    </div>
                </Popup>
            )}
        </>
    )
}

export default AddWeekend