import PreloaderSm from "../TenantApp/Elements/PreloaderSm"




export const PageLoader = () => {
    return (
        <>
            <div className="flex items-center justify-center fixed z-50 top-0 right-0 bottom-0 left-0 bg-gray-900/50">
                <PreloaderSm className='scale-150' />
            </div>
        </>
    )
}