import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Appointment from "../Appointment";
import Popup from "Components/TenantApp/Elements/Popup";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import { useDispatch, useSelector } from "react-redux";

import EmployeeCommentSystem from "../EmployeeCommentSystem";
import GroupAppointment from "../GroupAppointmentDetail/GroupAppointment";

const AppointmentDetailPopup = ({ appGroupId, serviceId, onClose, open, onUpdateParam, appointment_group_id }) => {
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  return (
    <>
      <Popup
        heading={`Appointment Detail `}
        size={"xl"}
        close={() => {
          onClose(false);
        }}
        open={open}
      >
        {appointment_group_id ?
          <GroupAppointment appGroupId={appointment_group_id} onlyView={true} serviceId={appGroupId} onUpdateParam={onUpdateParam} />
          :
          <Appointment appGroupId={appGroupId} onlyView={true} serviceId={serviceId} onUpdateParam={onUpdateParam} />
        }
        <div className="flex gap-2 items-center justify-start w-full py-2">
          <LoginBtn
            className='!bg-[#5679FF] !text-white'
            customThemeBtn={"custom-theme-btn-reschedule"}
            text={selectedLanguage?.name == "English" ? "View More Detail" : translation[1349]?.value ? translation[1349]?.value : "View More Detail"}
            onClick={() => {
              if (appointment_group_id) {
                navigate(`/dashboard/calendar/appointment-group-detail/${appGroupId}/?parent_id=${appointment_group_id}`)
              } else {
                navigate(`/dashboard/calendar/appointment-detail/${serviceId}/?parent_id=${appGroupId}`)
              }
            }}
          />
          
          <EmployeeCommentSystem appointmentDataId={appGroupId} />
        </div>
      </Popup>
      
    </>
  );
};

export default AppointmentDetailPopup;
