import axios from "axios";
import { BASE_URL, create_campaign_url, delete_campaign_url, get_campaign_url, update_campaign_url, } from "../api_variables";
import { addCampaigns, deleteCampaigns, getAllCampaigns, updateCampaigns } from "Redux/TenantRedux/Actions/campaignActions";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";

export const get_all_campaigns = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        let query = '?'
        for (let dt in payload) {
            if (payload[dt]) {
                query += `${dt}=${payload[dt]}&`
            }
        }
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const result = await axios.get(`${BASE_URL}${get_campaign_url}${query}`, headers);
        if (result?.status === 200) {
            const data = result?.data
            dispatch(getAllCampaigns(data));
            success && success()
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        fail && fail(error);
        return error;
    }
}

export const createCampaign = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.post(`${BASE_URL}${create_campaign_url}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.response?.data
            if (result?.data?.status_code == 200) {
                toast.error(result?.data?.response?.message, { toastId: "add_campaign" })
                fail && fail()
            }

            if (result?.data?.status_code == 201) {
                dispatch(addCampaigns(data));
                toast.success(result?.data?.response?.message, { toastId: "add_campaign" })
                success && success()
            }
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'create_campaign' })
        fail && fail()
        return error;
    }
};

export const updateCampaign = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.put(`${BASE_URL}${update_campaign_url}/${payload?.id}/`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.response?.data
            if (result?.data?.status_code == 200) {
                toast.error(result?.data?.response?.message, { toastId: "update_campaign" })
                fail && fail()
            }

            if (result?.data?.status_code == 201) {
                dispatch(updateCampaigns(data));
                toast.success(result?.data?.response?.message, { toastId: "update_campaign" })
                success && success()
            }
            // toast.success(result?.data?.response?.message)
            // success && success()
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: 'update_campaign' })
        fail && fail()
        return error;
    }
};

export const delete_Campaign = (access_token, data, success, fail) => async (dispatch) => {
    try {
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const result = await axios.delete(`${BASE_URL}${delete_campaign_url}/${data}/`, headers);
        if (result?.status === 200) {
            toast.success(result?.data?.response?.message, { toastId: "delete_campaign" })
            dispatch(deleteCampaigns(data));
            success && success()
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "delete_campaign" })
        fail && fail(error);
        return error;
    }
}