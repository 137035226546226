import React from 'react'

const TotalReadingCards = (props) => {
    return (
        props?.animate ?
            <div className="bg-white p-4 rounded-xl flex-1 border animate-pulse hover:shadow-md flex flex-col justify-between gap-4 transition-all">
                <div className="flex justify-between items-center">
                    <div className="h-10 w-1/4 rounded-lg bg-gray-200"></div>
                    <div className="h-10 w-2/4 rounded-lg bg-gray-200"></div>

                </div>
                <div className="flex justify-between items-center">
                    <div className="h-6 w-2/5 rounded-lg bg-gray-200"></div>
                    <div className="h-6 w-1/4 rounded-lg bg-gray-200"></div>
                </div>
            </div>
            :
            <div className="bg-white p-4 rounded-xl flex-1 border hover:shadow-md flex flex-col justify-between gap-4 transition-all cursor-pointer">
                <div className="flex justify-between items-center gap-2">
                    {props.icon}

                    {props.head == "Total Revenue" ?

                        <p className="text-[#007930] font-semibold text-xl">{props.value ? props.value : 0}</p>

                        : ""
                    }
                    {props.head == "Total Footfalls" ?

                        <p className="text-[#007930] font-semibold text-xl">{props.value ? props.value : 0}</p>

                        : ""
                    }
                    {props.head == "Average sales per footfall" ?

                        <p className="text-[#007930] font-semibold text-xl">{props.value ? props.value : 0}</p>

                        : ""
                    }

                </div>
                <div className="flex justify-between items-center">
                    <p className="text-[#3F3F44] text-sm font-semibold">{props.text}</p>
                    {props.graph}
                </div>
            </div>
    )
}

export default TotalReadingCards