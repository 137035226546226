import { DIAL_CODES } from "../Constants/Data/DialCodes";

export const useCommonHooks = () =>  {
    
    //hide some part of mobile numbile or email address
    const hiddenMobileNumberDigits = (mobile_number) => { 
       let hideMobileNumberDigits;
        const numberToArray = mobile_number.toString().split("");
        numberToArray.splice(5, 5, "*", "*", "*", "*", "*");
        hideMobileNumberDigits = numberToArray.join("");

        return hideMobileNumberDigits;
    }



    //check phone number hs only phone_number code or number
    const checkMobileNumber = (mobile_number) => {

        if (mobile_number) {
            const list = DIAL_CODES.find((item) => item.MobileCode == mobile_number.split("-")[0]);
            if (list?.MobileCode.length === mobile_number.toString().length - 1) {
                return true
            } else {
                return false
            }

        }

    }





    return {
        hiddenMobileNumberDigits,
        checkMobileNumber,
    }
}

export default useCommonHooks
