import { addExpense, getExpenseCategoryNoPagination, updateExpense } from "Adapters/Api/expense";
import useObjectKeys from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useLocation } from "new-components/location/helper";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { json } from "react-router-dom";


const initialData = {
    expense_category: '',
    payment_method: '',
    total_amount: '',
    date: '',
    expense_note: '',
    payment_note: '',
    card_number: '',
    card_holder_name: '',
    card_type: '',
    month: '',
    year: '',
    // cvv: '',
    transaction_number: '',
    cheque_number: ''
}
const useAddExpense = (close, onSuccessAPICall, isEdit, editData) => {
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const expenseCategory = state?.expense?.allExpenseCategory

    const PaymentMethodOptions = [
        {
            value: "Cash",
            label: selectedLanguage?.name == "English" ? "Cash" : translation[1754]?.value ? translation[1754]?.value : "Cash"
        },
        {
            value: "Card",
            label: selectedLanguage?.name == "English" ? "Card" : translation[1755]?.value ? translation[1755]?.value : "Card"
        },
        {
            value: "Cheque",
            label: selectedLanguage?.name == "English" ? "Cheque" : translation[1756]?.value ? translation[1756]?.value : "Cheque"
        },
        {
            value: "Bank",
            label: selectedLanguage?.name == "English" ? "Bank Transfer" : translation[1757]?.value ? translation[1757]?.value : "Bank Transfer"
        },
        {
            value: "Other",
            label: selectedLanguage?.name == "English" ? "Other" : translation[709]?.value ? translation[709]?.value : "Other",
        },
        {
            value: "Online",
            label: selectedLanguage?.name == "English" ? "Online" : translation[43]?.value ? translation[43]?.value : "Online",
        },
        {
            value: "Visa",
            label: selectedLanguage?.name == "English" ? "Visa" : translation[43]?.value ? translation[43]?.value : "Visa",
        }
    ]

    const CardTypeOptions = [
        {
            value: "Mastercard",
            label: "Mastercard"
        },
        {
            value: "PayPal",
            label: "PayPal"
        },
        {
            value: "Stripe",
            label: "Stripe"
        },
        {
            value: "Apple Pay",
            label: "Apple Pay"
        },
        {
            value: "Google Pay",
            label: "Google Pay"
        },
        {
            value: "Amazon Pay",
            label: "Amazon Pay",
        }
    ]

    const dispatch = useDispatch()
    const { objectKeyConvertToArray } = useObjectKeys()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const { errors, validation, setErrors, } = useValidations()

    const { selected_location } = useLocation()
    const [loading, setLoading] = useState(false)
    const [expenseData, setExpenseData] = useState(initialData)
    const [expenseCategoryLoader, setExpenseCategoryLoader] = useState(true)

    const onChangeHandler = (e) => {
        if (e?.target) {
            const { name, value } = e.target;
            if (name == 'file') {
                setExpenseData((prev) => ({ ...prev, 'file': e.target.files[0] }));
                setErrors((prev) => ({ ...prev, 'file': "" }))
            } else {
                setExpenseData((prev) => ({ ...prev, [name]: value }));
                setErrors((prev) => ({ ...prev, [name]: "" }))
            }
        }
    }

    const getExpensesCategories = () => {
        setExpenseCategoryLoader(true)
        const payload = {
            no_pagination: true
        }
        const success = () => { setExpenseCategoryLoader(false) }
        const fail = () => { setExpenseCategoryLoader(false) }
        dispatch(getExpenseCategoryNoPagination(access_token, payload, success, fail))
    }

    const SubmitExpense = () => {
        const { expense_category, payment_method, transaction_number, file, cheque_number, card_number, total_amount, date, expense_note,
            payment_note, month, card_type, year, card_holder_name } = expenseData
        let required_field = { expense_category, payment_method, total_amount, date, expense_note, payment_note }

        if ((expenseData?.payment_method == 'Bank Transfer') || (expenseData?.payment_method == 'Online') || (expenseData?.payment_method == 'Visa')) {
            required_field = { ...required_field, transaction_number }
        }
        if (expenseData?.payment_method == 'Card') {
            required_field = { ...required_field, card_number, month, card_type, year, card_holder_name }
        }
        if (expenseData?.payment_method == 'Cheque') {
            required_field = { ...required_field, cheque_number }
        }

        setErrors(validation(required_field));
        let result = validation(required_field);
        if (objectKeyConvertToArray(result)?.length === 0) {

            setLoading(true)
            const payload = {
                ...expenseData,
                location: selected_location,
            }
            const success = () => {
                onSuccessAPICall && onSuccessAPICall()
                setExpenseData(initialData)
                close && close(false)
                setLoading(false)
            }
            const fail = () => {
                setLoading(false)
            }
            isEdit
                ? dispatch(updateExpense(access_token, payload, success, fail))
                : dispatch(addExpense(access_token, payload, success, fail))
        }
    }

    useEffect(() => {
        if (isEdit && editData?.id) {
            setExpenseData({
                id: editData?.id,
                expense_category: editData?.expense_category,
                payment_method: editData?.payment_method,
                total_amount: editData?.total_amount,
                date: editData?.date,
                file: editData?.file,
                file_type: editData?.file_type,

                expense_note: editData?.expense_note,
                payment_note: editData?.cheque_details?.payment_note || editData?.card_details?.payment_note || editData?.online_payment_details?.payment_note || editData?.payment_note,
                transaction_number: editData?.online_payment_details?.transaction_number,
                cheque_number: editData?.cheque_details?.cheque_number,
                card_number: editData?.card_details?.card_number,
                card_holder_name: editData?.card_details?.card_holder_name,
                card_type: editData?.card_details?.card_type,
                month: editData?.card_details?.month,
                year: editData?.card_details?.year,
                // cvv: editData?.card_details?.cvv,
            })
        }
    }, [editData, isEdit])

    useEffect(() => {
        getExpensesCategories()
    }, [])

    return {
        translation,
        selectedLanguage,
        PaymentMethodOptions,
        onChangeHandler,
        expenseData, expenseCategory,
        errors, SubmitExpense,
        loading, CardTypeOptions
    }

}
export default useAddExpense