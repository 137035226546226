


const TermsAndConditions = () => {
    return (
        <>
            <div className="container mx-auto px-[1rem] md:px-[6rem] py-12 flex flex-col gap-6">
                <div className="flex flex-col gap-3">
                    <h2 className="text-3xl font-bold ">Terms and conditions</h2>

                    <p className="text-[#5d5d5d] text-sm">
                        Hi! We are X. We've worked hard to make our Terms and Conditions <span className="font-semibold">(Terms of Service)</span> easy to read. We've summarised key points of our Terms of Service below, but you must also read the full version that follows.
                    </p>
                </div>
                <div className="flex flex-col gap-3">
                    <ul className="text-[#5d5d5d] text-sm list-disc ml-4 flex flex-col gap-2">
                        <li>
                            X provides <a className="underline" target='_blank' href="X.com">X.com</a> and the X app (together, our X Platform), which allows you to discover and book online with (or purchase Products from) spas, salons, beauticians, trainers and other businesses. You can buy or book Partner Services (including any Products that Partners have for sale) by making an Appointment or an Order through the X Platform.
                        </li>
                        <li>
                            The Partner Services you can book or buy via X are sold by our Partners and not by us. We are only responsible for arranging and concluding your Appointment or Order, and we have been appointed by our Partners to act as their commercial agent to do so.
                        </li>
                        <li>
                            If you pay for Partner Services using the X Payment Services, we may collect and receive your payment on behalf of the relevant Partner in our capacity as their commercial agent. When we receive your payment, this will discharge your debt to the Partner for the Partner Services.
                        </li>
                        <li>
                            The contract for the Partner Services is directly between you and the relevant Partner. We are not responsible for the Partner Services you receive from our Partners. However, we would like to know if you are less than happy with any Partner. You can contact us at <a href="mailto:support@X.com" className="underline">support@X.com</a>, and we'll see if there's anything we can do to help.
                        </li>
                        <li>
                            If you make an Appointment or Order, you agree to a Partner Contract (including any Partner Terms of Sale) with the relevant Partner. X is not part of this agreement. Please thoroughly check all details and any restrictions relating to an Appointment or Order before confirming it.
                        </li>
                        <li>
                            X may deactivate your X Account if you breach any part of these Terms of Service or our Terms of Use, or if you act in a way that is inappropriate, abusive or otherwise unacceptable towards a Partner or our staff.
                        </li>
                        <li>If you would like to reschedule or cancel an Appointment or Order, or return or exchange a Product, first check these Terms of Service and any Partner Contract to see if you are able to do so. If you are, you can do this through:
                            <ul>
                                <li>Submitting a request through the X Platform,</li>
                                <li>emailing or calling the Partner directly, or</li>
                                <li>emailing our support team at <a href="mailto:support@X.com" className="underline">support@X.com</a>.</li>
                            </ul>
                        </li>
                    </ul>
                    <p>If you want to talk to us, please get in touch with our team and we'd be happy to help at <a href="mailto:support@X.com" className="underline">support@X.com</a>.</p>
                </div>
            </div>
        </>
    )
}


export default TermsAndConditions