import { isEditable } from "@testing-library/user-event/dist/utils";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkEmployeeEmail,
  createEmployees,
  deleteEmployee,
  getEmployeeDropDownListPagination,
  getEmployeeList,
  getEmployeeListMainPage,
  getEmployeeListWithNoPagination,
  updateEmployee,
} from "../../../../../Adapters/Api/employee";
import { getSaleServices } from "../../../../../Adapters/Api/saleServices";
import { DIAL_CODES } from "../../../../../Constants/Data/DialCodes";
import { WEEKDAYS } from "../../../../../Constants/Data/Months";
import useAddress from "../../../../../Hooks/useAddress";
import useCommonHooks from "../../../../../Hooks/useCommonHooks";
import useObjectKeys from "../../../../../Hooks/useObjectKeys";
import useSetDataInLocalStorage from "../../../../../Hooks/useSetDataInLocalStorage";
import useValidations from "../../../../../Hooks/useValidations";
import { useRef } from "react";
import { apiErrorMessage } from "Constants/Data/Errors";
import { useEffect } from "react";
// import {
//   addEmployees,
//   removeEmployee,
//   editEmployee,
// } from "../../../../../Redux/TenantRedux/Actions/employeeActions";

function useEmployeeForms() {
  const formRef = useRef()
  const { getDataFromLocalStorage, storeDataInLocalStorage } =
    useSetDataInLocalStorage();
  const { setErrors, validation, errors, onBlurHandler } = useValidations();
  const { checkMobileNumber } = useCommonHooks();
  const { objectKeyConvertToArray } = useObjectKeys();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const employeeRedux = useSelector((state) => state?.employee?.employees);
  const state = useSelector(state => state)
  const { selected_location } = state.locations;
  const access_token = getDataFromLocalStorage("access_token");
  const businessData = getDataFromLocalStorage("business_data");
  const emp_id = getDataFromLocalStorage("employee_id");
  const hash = getDataFromLocalStorage("hash");
  const domain = getDataFromLocalStorage("domain-name");

  const [isEdit, setIsEdit] = useState(false);
  const [Finished, setFinished] = useState(false);
  const [step, setStep] = useState(1);
  const [image, setImage] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [selectSevices, setSelectSeverice] = useState([]);
  const [countryId, setCountryId] = useState();
  const [stateId, setStateId] = useState();
  const [isToastVisible, setIsToastVisible] = useState(false);
  const { countriesList, stateList } = useAddress(countryId);
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownStatus, setDropdownStatus] = useState('')
  const [loader, setLoader] = useState(true)
  const [noPaginationLoader, setNoPaginationLoader] = useState(true)
  const [deleteemp, setDeleteemp] = useState(undefined);
  const [deleting, setDeleting] = useState(false);
  const [search_text, setSearchText] = useState("");
  const [employeeData, setEmployeeData] = useState({
    full_name: "",
    employee_id: "",
    email: "",
    mobile_number: "",
    image: "",
    dob: "",
    gender: "Male",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    address: "",
    joining_date: "",
    to_present: true,
    ending_date: "",
    is_active: true,
    location: "",
    //step 2
    designation: "",
    salary: "",
    income_type: "",
    start_time: "",
    services: [],
    end_time: "",
    working_days: [],
    level: "",
    staff_group: "",
    maximum_discount: "",
    leave_data: {
      operational_casual_leave: "",
      operational_annual_leave: "",
      operational_medical_leave: "",
      number_of_months: ""
    },
    //step 3
    allow_calendar_booking: true,
    access_calendar: true,
    change_calendar_color: true,
    access_reports: true,
    access_sales: true,
    access_inventory: true,
    access_expenses: true,
    access_products: true,
    access_voucher: true,
    access_member_discount: true,
    access_invite_friend: true,
    access_loyalty_points: true,
    access_gift_cards: true,
  });

  // **************************** employee on change handler *********************************
  const employeeHandleChange = (e, fieldName = "") => {
    let more_values = {}
    if (e?.target) {
      const { name, value } = e.target;

      // if (name === "location") {
      //     if (!employeeData?.location?.includes(value)) {
      //         setEmployeeData((prev) => ({ ...prev, [name]: [...prev.location, value] }));
      //         setErrors((prev) => ({ ...prev, [name]: "" }))
      //     }
      //     return;
      // }

      if (name === "working_days") {
        if (!employeeData?.working_days?.includes(value)) {
          setEmployeeData((prev) => ({
            ...prev,
            [name]: [...prev.working_days, value],
            ...more_values
          }));
          setErrors((prev) => ({ ...prev, [name]: "" }));
        }
        return;
      }

      if (name === "services") {
        if (!employeeData?.services?.includes(value)) {
          setEmployeeData((prev) => ({
            ...prev,
            [name]: [...prev.services, value],
            ...more_values
          }));
          setErrors((prev) => ({ ...prev, [name]: "" }));
        }
        return;
      }

      setEmployeeData((prev) => ({ ...prev, [name]: value, ...more_values }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
      name === "country" && setCountryId(e?.target?.value);
      name === "state" && setStateId(e?.target?.value);
    }

    if (fieldName && fieldName === "image") {
      setImage(URL.createObjectURL(e.target.files[0]));
      setEmployeeData((prev) => ({ ...prev, image: e.target.files[0] }));
      setErrors((prev) => ({ ...prev, image: "" }));
    }
  };

  const employeeHandleLeaveChange = (e) => {
    if (e?.target) {
      const { name, value } = e.target;

      setEmployeeData((prev) => ({
        ...prev,
        leave_data: {
          ...prev.leave_data,
          [name]: value,
        },
      }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };
  // **************************** employee on change handler *********************************
  const employeePermissionHandleChange = (e) => {
    const { name, value } = e.target;
    let new_returned = employeeData[name] ? employeeData[name] : [];
    if (!new_returned.includes(value)) {
      new_returned.push(value);
    } else {
      let itm_index = new_returned.indexOf(value);
      new_returned.splice(itm_index, 1);
    }
    setEmployeeData({ ...employeeData, [name]: new_returned });
  };

  // **************************** remove selected items *********************************
  const removedItem = (value, type) => {
    if (type === "service") {
      if (employeeData?.services?.includes(value)) {
        setEmployeeData((prev) => ({
          ...prev,
          services: [...employeeData.services.filter((srvc) => srvc != value)],
        }));
      }
    }

    if (type === "location") {
      if (employeeData?.location?.includes(value)) {
        setEmployeeData((prev) => ({
          ...prev,
          location: [...employeeData.location.filter((srvc) => srvc != value)],
        }));
      }
    }
  };


  // months options
  const leaveMonthsOptions = [
    { label: '3 Months', value: '3' },
    { label: '4 Months', value: '4' },
    { label: '5 Months', value: '5' },
    { label: '6 Months', value: '6' },
    { label: '7 Months', value: '7' },
    { label: '8 Months', value: '8' },
    { label: '9 Months', value: '9' },
    { label: '10 Months', value: '10' },
    { label: '11 Months', value: '11' },
    { label: '12 Months', value: '12' }
  ]

  // **************************** vendor on submit handler *********************************
  const onEmployeeSubmit = async (stepValue) => {
    const selected_days = WEEKDAYS?.filter(
      (item) => employeeData[item.toLowerCase()]
    );

    const {
      full_name,
      employee_id,
      email,
      mobile_number,
      image,
      dob,
      gender,
      country,
      state,
      city,
      staff_group,
      location,
      postal_code,
      address,
      joining_date,
      ending_date,
      is_active,
      to_present,
      designation,
      income_type,
      salary,
      services,
      level,
      start_time,
      end_time,
      leave_data
    } = employeeData;
    let requiredFields;
    if (step === 1) {
      requiredFields = {
        full_name,
        employee_id,
        gender,
        country,
        address,
        email,
        business: businessData.id,
        location: location?.length > 0 ? location[0] : "",
      };
      if (mobile_number) {
        const isPhoneNumber = checkMobileNumber(mobile_number);
        if (isPhoneNumber) {
          delete employeeData.mobile_number;
        } else {
          requiredFields = { ...requiredFields, mobile_number };
        }
      }
    } else {
      if (selected_days?.length > 0) {
        // start_time, end_time
        requiredFields = {
          designation,
          income_type,
          salary,
          operational_casual_leave: leave_data?.operational_casual_leave,
          operational_annual_leave: leave_data?.operational_annual_leave,
          operational_medical_leave: leave_data?.operational_medical_leave,
          number_of_months: leave_data?.number_of_months,
        };
      } else {
        setErrors((prev) => ({
          ...prev,
          working_days: "This field is required",
        }));
        formRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
    // console.log(stepValue, "stepValue")
    setErrors(validation(requiredFields));
    let result = validation(requiredFields);
    // if (to_present || ending_date) {
    if (
      objectKeyConvertToArray(result)?.length === 0
      //  && ((salary && salary.toString().length < 7) || !salary)
    ) {
      if (step === 1) {
        if (!isEdit) {
          setStep(2);
        } else {
          if (stepValue && stepValue === 1) {
            setStep(2);
          } else {
            editFormSubmit()
          }
        }
      } else if (step === 2) {
        setStep(3);
      } else if (step === 3) {
        if (isEdit) {
          editFormSubmit()
        } else {
          setLoading(true);
          let form_data = new FormData();
          for (let key in employeeData) {
            if (employeeData[key] || typeof employeeData[key] == "boolean") {
              if (typeof employeeData[key] == "object" && key != "image") {
                form_data.append(key, JSON.stringify(employeeData[key]));
              } else {
                form_data.append(key, employeeData[key]);
              }
            }
          }
          form_data.append("business", businessData.id);
          form_data.append("tenant_id", hash);
          form_data.append("domain", domain);
          console.log('Siubmo');

            createEmployees(form_data, access_token)
              .then((response) => {
                setLoading(false);
                if (response?.status == 201) {
                  setCurrentPage(1)
                  dispatch(getEmployeeListMainPage(1, selected_location, search_text, dropdownStatus, setLoader))
                  dispatch(getEmployeeListWithNoPagination(selected_location, search_text, dropdownStatus, setNoPaginationLoader))
                  dispatch(getEmployeeDropDownListPagination(1, selected_location))
                  // dispatch(addEmployees(response?.data?.response?.employees));
                  toast.success(response?.data?.response?.message, { toastId: "toast" });
                  setFinished(true);
                  dispatch(getSaleServices());
                } else if (response.response.status == 500) {
                  toast.error("Internal server error!", { toastId: "toast" });
                }
              })
              .catch((error) => {
                setLoading(false);
                toast.error("invalid data!", { toastId: "toast" });
              });
        }
      }
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    // }
    // else {
    //   if (salary && salary.toString().length > 6) {
    //     toast.error("Salary limit exceeded!");
    //     setErrors((prev) => ({ ...prev, salary: "Salary limit exceeded!" }));
    //   }
    // }
  };


  const firstStepSubmitValidation = (success_callback, fail_callback) => {
    const {
      full_name,
      employee_id,
      email,
      gender,
      country,
      location,
      address,
    } = employeeData;
    let requiredFields = {
      full_name,
      employee_id,
      gender,
      country,
      address,
      email,
      business: businessData.id,
      location: location?.length > 0 ? location[0] : "",
    };
    setErrors(validation(requiredFields));
    let result = validation(requiredFields);
    if (objectKeyConvertToArray(result)?.length === 0) {
      success_callback && success_callback()
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
      fail_callback && fail_callback()
    }
  }

  const editFormSubmit = () => {
    const { to_present } = employeeData
    if (isEdit) {
      setLoading(true);
      employeeData.location = typeof (employeeData.location) == 'object' ? employeeData.location[0] : employeeData.location;
      let formatDataToBackend = {
        ...employeeData,
        id: emp_id,
        business: businessData.id,
        tenant_id: hash,
      };
      let form_data = new FormData();
      for (let key in formatDataToBackend) {
        if (
          key === "image" &&
          typeof formatDataToBackend[key] == "string"
        ) {
        } else {
          if (to_present) {
            if (key !== "ending_date" && formatDataToBackend[key]) {
              if (
                typeof formatDataToBackend[key] == "object" &&
                key != "image"
              ) {
                form_data.append(
                  key,
                  JSON.stringify(formatDataToBackend[key])
                );
              } else {
                form_data.append(key, formatDataToBackend[key]);
              }
            }
          } else {
            if (
              formatDataToBackend[key] ||
              typeof formatDataToBackend[key] == "boolean" ||
              typeof formatDataToBackend[key] == "object"
            ) {
              if (
                formatDataToBackend[key] &&
                typeof formatDataToBackend[key] == "object" &&
                key != "image"
              ) {
                form_data.append(
                  key,
                  JSON.stringify(formatDataToBackend[key])
                );
              } else {
                if (formatDataToBackend[key]) {
                  form_data.append(key, formatDataToBackend[key]);
                }
              }
            }
          }
        }
      }

      form_data.append("domain", domain);
      updateEmployee(form_data, access_token)
        .then((response) => {
          setLoading(false);
          if (response?.response?.status == 402) {
            return toast.error(response?.response?.data?.response?.message, { toastId: "toast" });
          } else {
            let employee = response?.data?.response?.Employee;
            let successMessage = response?.data?.response?.message;
            setCurrentPage(1)
            dispatch(getEmployeeListMainPage(1, selected_location, search_text, dropdownStatus, setLoader))
            dispatch(getEmployeeListWithNoPagination(selected_location, search_text, dropdownStatus, setNoPaginationLoader))
            // dispatch(editEmployee(employee));
            navigate(`/dashboard/employee/employees`);
            dispatch(getSaleServices());
            return toast.success(successMessage, { toastId: "toast" });
          }
        })
        .catch((error) => {
          // console.log(error, "error")
          setLoading(false);
          toast.error("invalid data!", { toastId: "toast" });
          return;
        });
    }
  }

  // **************************** Eidt employee *********************************
  const employeeEditHandler = async (id) => {
    setIsEdit(true);
    storeDataInLocalStorage("employee_id", id);
    navigate(`/dashboard/employee/employees/add-employee/?employee_id=${id}`);
  };

  // **************************** delete employee *********************************
  const employeeDeleteHandler = (id) => {
    deleteEmployee(id, access_token).then((response) => {
      if (response.status_code === 200) {
        setLoader(true)
        setCurrentPage(1)
        let successMessage = response?.response?.message;
        dispatch(getEmployeeListMainPage(currentPage, selected_location, "", dropdownStatus, setLoader))
        if (!isToastVisible) {
          toast.success(successMessage, {
            onClose: () => setIsToastVisible(false),
          });
          setIsToastVisible(true);
        }
        setDeleting(false);
        setDeleteemp(undefined);
        setCurrentPage(1)
        dispatch(getEmployeeListMainPage(1, selected_location, search_text, dropdownStatus, setLoader))
        dispatch(getEmployeeListWithNoPagination(selected_location, search_text, dropdownStatus, setNoPaginationLoader))
        // dispatch(removeEmployee(id));
      }
    }).catch((error) => {
      if (!isToastVisible) {
        toast.error("Somethings went wrong!", {
          onClose: () => setIsToastVisible(false),
        });
        setIsToastVisible(true);
      }
    })

  }

  return {
    Finished, setFinished, step, setStep, employeeData, setEmployeeData, employeeHandleChange, employeePermissionHandleChange,
    onEmployeeSubmit, errors, onBlurHandler, isEdit, setIsEdit, setErrors, countriesList, stateList,
    employeeDeleteHandler, employeeEditHandler, image, setImage, selectSevices, setCountryId, setStateId, loading,
    removedItem, access_token, currentPage, setCurrentPage, loader, setLoader, search_text, setSearchText, formRef,
    deleteemp, setDeleteemp, deleting, setDeleting, setLoading, noPaginationLoader, setNoPaginationLoader, firstStepSubmitValidation,
    validation, objectKeyConvertToArray, checkEmployeeEmail, hash, getEmployeeList, domain, createEmployees, apiErrorMessage,
    dropdownStatus, setDropdownStatus, employeeHandleLeaveChange, leaveMonthsOptions, selected_location
  };
}

export default useEmployeeForms;
