export const CLIENT = {
    GET_CLIENT: "GET_CLIENT",
    GET_CLIENT_DROPDOWN: "GET_CLIENT_DROPDOWN",
    GET_CLIENT_DROPDOWN_PAGINATION: 'GET_CLIENT_DROPDOWN_PAGINATION',
    GET_CLIENT_WITHOUT_PAGINATION: '',
    GET_CLIENT_DROPDOWN_WITHOUT_PAGINATION: "GET_CLIENT_DROPDOWN_WITHOUT_PAGINATION",
    ADD_CLIENT: "ADD_CLIENT",
    UPDATE_CLIENT: "UPDATE_CLIENT",
    DELETE_CLIENT: "DELETE_CLIENT",
    CLIENT_ID: "CLIENT_ID",
    CLIENT_SUB: "CLIENT_SUB",
    CLIENT_PROMO: "CLIENT_PROMO",
    GET_CLIENT_REWARDS: "GET_CLIENT_REWARDS",
    ADD_CLIENT_REWARDS: "ADD_CLIENT_REWARDS",
    DELETE_CLIENT_REWARDS: "DELETE_CLIENT_REWARDS",
    UPDATE_CLIENT_REWARDS: "UPDATE_CLIENT_REWARDS",
    CREATE_PROMOTION: 'CREATE_PROMOTION',
    DELETE_PROMOTION: 'DELETE_PROMOTION',
    UPDATE_PROMOTION: 'UPDATE_PROMOTION',
    UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
    DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',
    CREATE_LOAYALTY_POINT: "CREATE_LOAYALTY_POINT",
    GET_LOAYALTY_POINTS: "GET_LOAYALTY_POINTS",
    UPDATE_LOAYALTY_POINT: "UPDATE_LOAYALTY_POINT",
    DELETE_LOAYALTY_POINT: "DELETE_LOAYALTY_POINT",
    SET_SPECIFIC_CLIENTS_FOR_CHECKOUT: "SET_SPECIFIC_CLIENTS_FOR_CHECKOUT",
    GET_LOAYALTY_POINTS_CUSTOMER_LOG: "GET_LOAYALTY_POINTS_CUSTOMER_LOG",
    GET_LOAYALTY_POINTS_DETAILED_LOG: "GET_LOAYALTY_POINTS_DETAILED_LOG",
    CREATE_CASHBACK: "CREATE_CASHBACK",
    UPDATE_CASHBACK: "UPDATE_CASHBACK",
    DELETE_CASHBACK: "DELETE_CASHBACK",
    GET_CASHBACK: "GET_CASHBACK"
};
