
import axios from "axios";
import { BASE_URL, create_commission, get_commission, update_commission, delete_commission, get_commission_with_page } from "../api_variables";
import { toast } from 'react-toastify';
import { EMPLOYEES } from "Redux/TenantRedux/ActionTypes/employeeTypes";
import { apiErrorMessage } from "Constants/Data/Errors";

export const getCommissions = (selected_location, currentPage, searchText, setLoader) => dispatch => {
    setLoader && setLoader(true)
    const location = selected_location ? `?location_id=${selected_location}` : ''
    const page = currentPage ? `&page=${currentPage}` : ''
    const search = searchText ? `&search_text=${searchText}` : ''

    let url = `${BASE_URL}${get_commission_with_page}${location}${page}${search}`
    let s_code;
    fetch(url)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let commission_list = result?.response?.commission;
                let pages = result?.response
                dispatch({
                    type: EMPLOYEES.GET_COMMISSION,
                    payload: commission_list,
                })
                dispatch({
                    type: EMPLOYEES.GET_COMMISSION_PAGES,
                    payload: pages,
                })
                setLoader && setLoader(false)
            }
            else {
                setLoader && setLoader(false)
                toast.error(apiErrorMessage, { toastId: "toast" })
            }
        })
};

export const getCommissionsWithNoPagination = (selected_location, searchText, setLoader) => dispatch => {
    setLoader && setLoader(true)
    const location = selected_location ? `&location_id=${selected_location}` : ''
    const search = searchText ? `&search_text=${searchText}` : ''

    let url = `${BASE_URL}${get_commission_with_page}?no_pagination=true${location}${search}`
    let s_code;
    fetch(url)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let commission_list = result?.response?.commission;
                dispatch({
                    type: EMPLOYEES.GET_COMMISSION_WITHOUT_PAGINATION,
                    payload: commission_list,
                })
                setLoader && setLoader(false)
            }
            else {
                setLoader && setLoader(false)
                toast.error(apiErrorMessage, { toastId: "toast" })
            }
        })
};



export const createCommission = (data, access_token, success, fail) => async dispatch => {
    try {
        const result = await axios.post(`${BASE_URL}${create_commission}`, data,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );
        if (result?.data?.status_code === 201) {
            dispatch({
                type: EMPLOYEES.ADD_COMMISSION,
                payload: result?.data?.response?.commission
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()

    }
};





export const updateCommission = (data, access_token, success, fail) => async dispatch => {
    try {
        const result = await axios.put(`${BASE_URL}${update_commission}`, { ...data },
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });

        if (result?.data?.status_code === 200) {
            dispatch({
                type: EMPLOYEES.UPDATE_COMMISSION,
                payload: result?.data?.response?.commission
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
};


export const deleteCommission = (id, access_token, success, fail) => dispatch => {
    fetch(`${BASE_URL}${delete_commission}`, {
        method: "DELETE",
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Token ${access_token}`
        },
        body: JSON.stringify(
            { id: id }
        )
    }).then((result) => {
        return result.json()
    }).then((response) => {
        if (response.status_code === 200) {
            dispatch({
                type: EMPLOYEES?.DELETE_COMMISSION,
                payload: id
            });
        }
        success && success(response?.response?.message);
    }).catch((err) => {
        fail && fail()
    })

}

