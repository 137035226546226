import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getClient, getClientWithNoPagination, getsClientsImport } from "../../../../Adapters/Api/clients";
import Svgs from "../../../../Assets/svgs";
import LoginBtn from "../../Elements/LoginBtn";
import LoginInput from "../../Elements/LoginInput";
import useAddClient from "./AddClient/useAddClient";
import ClientCard from "./Elements/ClientCard";
import useSetDataInLocalStorage from "./../../../../Hooks/useSetDataInLocalStorage";
import { CSVLink } from "react-csv";
import { useState } from "react";
import NotFound from "../../../Utility/NotFound";
import Popup from "../../Elements/Popup";
import { toast } from "react-toastify";
import PreloaderFull from "./../../Elements/PreloaderFull";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import Pagination from "new-components/Pagination";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoClientFound from "Components/Utility/NoClientFound";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";

let timeOutId = undefined;
const ClientProfiles = () => {
  const dispatch = useDispatch();
  const [ImportClients, setImportClients] = useState(false);
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const state = useSelector((state) => state);
  const clientRedux = state?.client?.clients;
  const pages = state?.client?.pages;
  const allCount = state?.client?.allCount
  const allClientRedux = state?.client?.allClients;
  const clients_updated = state?.client?.clients_updated;
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const PageSize = 10
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [exportLoader, setExportLoader] = useState(false);

  const navigate = useNavigate();
  const { clientDeleteHandler, clientEditHandler, currentPage, setCurrentPage, loader, setLoader, noPaginationLoader, setNoPagiantionLoader,
    isToastVisible, setIsToastVisible, search_text, setSearchText, dropdownStatus, setDropdownStatus } = useAddClient();

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    if (search_text === null) {
      // Initial load with empty search_text
      dispatch(getClient(currentPage, "", setLoader, dropdownStatus))
      dispatch(getClientWithNoPagination("", setNoPagiantionLoader, dropdownStatus))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(getClient(currentPage, search_text, setLoader, dropdownStatus))
        dispatch(getClientWithNoPagination(search_text, setNoPagiantionLoader, dropdownStatus))
      }, 500);
    }
  }, [currentPage, search_text, dropdownStatus])

  useEffect(() => {
    setLoader(true)
    setNoPagiantionLoader(true)
    setCurrentPage(1)
  }, [dropdownStatus])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  //----------------------Exoprt code--------------------

  let headers = [
    { label: selectedLanguage?.name == "English" ? 'Client Name' : translation[474]?.value ? translation[474]?.value : "Client Name", key: "full_name" },
    { label: selectedLanguage?.name == "English" ? 'Email' : translation[476]?.value ? translation[476]?.value : "Email", key: "email" },
    { label: selectedLanguage?.name == "English" ? 'Cient Id' : translation[478]?.value ? translation[478]?.value : "Cient Id", key: "client_id" },
    { label: selectedLanguage?.name == "English" ? 'Mobile Number' : translation[480]?.value ? translation[480]?.value : "Mobile Number", key: "mobile_number" },
    { label: selectedLanguage?.name == "English" ? 'Gender' : translation[479]?.value ? translation[479]?.value : "Gender", key: "gender" },
    { label: selectedLanguage?.name == "English" ? 'Address' : translation[49]?.value ? translation[49]?.value : "Address", key: "address" },
    { label: selectedLanguage?.name == "English" ? 'Status' : translation[454]?.value ? translation[454]?.value : "Status", key: "is_active" },
  ];

  const csvReport = {
    filename: "Client Directory.csv",
    headers: headers,
    data: allClientRedux?.map((itm) => {
      return {
        ...itm,
        full_name: itm?.full_name ? itm?.full_name : "--------",
        email: itm?.email ? itm?.email : "--------",
        client_id: itm?.client_id ? itm?.client_id : "--------",
        mobile_number: itm?.mobile_number ? itm?.mobile_number.split("+").join("") : "--------",
        gender: itm?.gender ? itm?.gender : "--------",
        address: itm?.address ? itm?.address : "--------",
        is_active: itm?.is_active ? "Active" : "InActive",
      };
    }),
  };

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allClientRedux?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  const submit = (file) => {
    // const file=csvFile;
    // const reader=new FileReader();
    const businessData = getDataFromLocalStorage("business_data");
    let business = businessData.id;

    let form_data = new FormData();
    form_data.append("file", file);
    form_data.append("business", business);

    getsClientsImport(form_data, () => {
      dispatch(getClient(currentPage, search_text, setLoader, dropdownStatus));
    });
  };

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <p className="text-[#7B7B7B] text-sm">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/clients/");
            }}
          >
            {selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
          </span>{" "}
          &gt;{" "}
          <span className="text-primary font-semibold"> {selectedLanguage?.name == "English" ? "Client Profile" : translation[461]?.value ? translation[461]?.value : "Client Profile"}</span>
        </p>

        <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
          <div className="flex sm:flex-nowrap flex-wrap items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
            <div className="flex items-center sm:gap-4 gap-2">

              <h2 className="text-primary font-bold lg:text-3xl text-xl whitespace-nowrap">
                {selectedLanguage?.name == "English" ? "Client Profile" : translation[461]?.value ? translation[461]?.value : "Client Profile"}
              </h2>
            </div>
            <div className="flex items-center gap-3 w-full sm:w-auto">

            
            {loadingPermission ?
              <EmployeesHeadShimmer classOn2={'!w-full lg:!w-44 sm:!w-40'} />
              :
              <div className="mb-00 grid grid-cols-2 sm:flex items-center gap-2 w-full sm:w-auto">
                <div className="mb-00">
                  <Dropwdown
                    title=""
                    value={dropdownStatus}
                    onChange={(e) => setDropdownStatus(e.target.value)}
                    showTitle={false}
                    placeholder={selectedLanguage?.name == "English" ? "Select Staff" : translation[334]?.value ? translation[334]?.value : "Select Staff"}
                    options={[
                      { label: selectedLanguage?.name == "English" ? "All Clients" : translation[1301]?.value ? translation[1301]?.value : "All Clients", value: "" },
                      { label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active", value: "true" },
                      { label: selectedLanguage?.name == "English" ? "In Active" : translation[1302]?.value ? translation[1302]?.value : "In Active", value: "false" }
                    ]}
                  />
                </div>
                <LoginInput
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Search Client" : translation[143]?.value ? translation[143]?.value : "Search Client"}
                  padding="py-1"
                  value={search_text}
                  leftIcon={<Svgs.Search />}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            }
         

          {loadingPermission ?
            <EmployeesHeadShimmer classOn1={'!w-12 lg:!16'} classOn2={'!w-32 lg:!w-44 sm:!w-40'} />
            :
            <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">
              {exportLoader ? (
                <div className="h-[4rem] flex items-center justify-center">
                  <PreloaderSm />
                </div>
              ) :
                allClientRedux?.length > 0 ?
                  <>
                    <CSVLink {...csvReport} target="_blank" onClick={handleExportClick}>
                      <IconButton filled>
                        <Svgs.Export />
                      </IconButton>
                    </CSVLink>
                    <input
                      type="file"
                      accept=".csv"
                      id="csvFileforproduct"
                      onChange={(e) => {
                        submit(e.target.files[0]);
                        setImportClients(false);
                      }}
                      hidden
                      value={""}
                    />
                  </> :
                  <LoginBtn
                    className="rounded-lg border-2 border-primary"
                    bg="bg-white"
                    animation={false}
                    onClick={handleExportClick}
                  >
                    <div className="flex items-center gap-2 text-[#FFFFFF]">
                      <Svgs.Export bg="#FFFFFF" />
                    </div>
                  </LoginBtn>
              }
              {/* {!employee ? (
              <LoginBtn
                onClick={() => {
                  setImportClients(true);
                }}
                className="rounded-lg border-2 border-primary"
                bg="bg-white"
                
              >
                <div className="flex items-center gap-2 text-[#101928]">
                  <Svgs.Import />
                </div>
              </LoginBtn>
            ) : (
              ""
            )} */}

              {!employee ||
                (employee &&
                  employeePermissions?.client_profile?.includes("create")) ? (
                <LoginBtn
                  onClick={() => {
                    navigate("/dashboard/clients/client-profiles/add-client");
                  }}

                >
                  <div className="flex items-center gap-2">
                    <Svgs.Plus />
                    <p className="font-semibold">{selectedLanguage?.name == "English" ? "New Client" : translation[462]?.value ? translation[462]?.value : "New Client"}</p>
                  </div>
                </LoginBtn>
              ) : (
                ""
              )}
            </div>
          }
          </div>
           </div>
        </div>


        {
          //-------------------------------search and map module---------------------------------------
          !clients_updated || loader ?
            <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-[2rem]">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8]?.map(itm => {
                return <ClientCard animate />
              })}
            </div>
            :
            clientRedux &&
              clientRedux?.length > 0 ? (
              <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mt-[2rem]">
                {clientRedux &&
                  clientRedux?.map((client, index) => {
                    return (
                      <ClientCard
                        key={client?.id}
                        countryName={client?.country_obj?.name}
                        data={client}
                        index={index}
                        onEdit={clientEditHandler}
                        onDelete={clientDeleteHandler}
                        translation={translation}
                        selectedLanguage={selectedLanguage}
                        hideDeleteIcon={allCount == 1 ? true : false}
                      />
                    );
                  })}
              </div>
            ) : (
              <>
                <NoClientFound className={"my-[7rem]"} onClick={() => {
                  navigate('/dashboard/clients/client-profiles/add-client')
                }} />
              </>
            )
        }


        {(clientRedux?.length > 0 && !loader) &&
          <Pagination
            currentPage={currentPage}
            totalCount={Math.ceil(pages)}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          />
        }
      </div>
      <Popup
        heading={`Import Clients`}
        close={setImportClients}
        open={ImportClients}
      >
        <div className="flex flex-col gap-4 border-[#A1A1A1] border-dashed border-2 p-4 rounded-lg text-center">
          <div className="h-[15rem]">
            <Svgs.UploadList />
          </div>
          <p className="text-[#595959]">Drop your .CSV file to add Clients</p>
          <p className="text-[#595959]">Or</p>
          <LoginBtn size="sm" className="mx-auto">
            <label htmlFor="csvFileforproduct" className="cursor-pointer">
              <div className="flex items-center gap-2 text-[#ffffff]">
                Upload New List
              </div>
            </label>
          </LoginBtn>
        </div>
      </Popup>
    </>)

};

export default ClientProfiles;
