import { upadateEmployeeTraining } from "Adapters/Api/employee/employeeTraining";
import useObjectKeys from "Hooks/useObjectKeys";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useTrainerComments = (data, onSuccessCall, close) => {
    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const dispatch = useDispatch();
    const { objectKeyConvertToArray } = useObjectKeys()
    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");
    const [loading, setLoading] = useState(false)
    const [acceptPopup, setAcceptPopup] = useState(false)
    const [trainingData, setTrainingData] = useState({})

    const onChangeHandler = (e) => {
        if (e?.target) {
            const { name, value } = e.target;
            setTrainingData((prev) => ({ ...prev, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: "" }))
        }
    }

    const onSubmitHandler = (type) => {
        const { date_offered, teacher_comment, teacher_recommendation, manager_feedback } = trainingData;
        let requiredFields = {}
        let payload = trainingData

        if (type == 'accept') {
            requiredFields = { date_offered }
        }
        if (type == 'complete') {
            requiredFields = { teacher_comment, teacher_recommendation }
        }
        if (type == 'feedback') {
            requiredFields = { manager_feedback }
        }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            if (type == 'Reject') {
                payload.status = 'Reject'
            }
            if (type == 'accept') {
                payload.status = 'Accept'
            }
            if (type == 'complete') {
                payload.is_completed = true
            }
            setLoading(true)
            const success = () => {
                onSuccessCall && onSuccessCall()
                close && close(false)
                setLoading(false)
            }
            const fail = () => {
                setLoading(false)
            }
            dispatch(upadateEmployeeTraining(access_token, payload, success, fail))
        }
    }


    useEffect(() => {
        if (data) {
            setTrainingData(data)
        }
    }, [data, close])

    return {
        acceptPopup, setAcceptPopup,
        trainingData, setTrainingData,
        translation, selectedLanguage,
        errors, loading, onChangeHandler,
        onSubmitHandler


    }
}

export default useTrainerComments