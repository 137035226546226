export const STAFF_TARGET = {
  GET_STAFF_TARGET_REPORT_WITHOUT_PAGINATION: 'GET_STAFF_TARGET_REPORT_WITHOUT_PAGINATION',
  GET_STAFFTARGET_WITHOUT_PAGINATION: 'GET_STAFFTARGET_WITHOUT_PAGINATION',
  ADD_STAFFTARGET: "ADD_STAFFTARGET",
  GET_STAFFTARGET: "GET_STAFFTARGET",
  UPDATE_STAFFTARGET: "UPDATE_STAFFTARGET",
  DELETE_STAFFTARGET: "DELETE_STAFFTARGET",
  COPY_STAFFTARGET: "COPY_STAFFTARGET",
  GET_STAFF_TARGET_REPORT: "GET_STAFF_TARGET_REPORT",
};
