import { CLIENT_GROUP } from "../../ActionTypes/clientGroupTypes";


export const getClientGroups = (data) => {
    return {
        type: CLIENT_GROUP.GET_CLIENT_GROUP,
        payload: data
    }
}

export const getClientGroupsWithoutPagination = (data) => {
    return {
        type: CLIENT_GROUP.GET_CLIENT_GROUP_WITHOUT_PAGINATION,
        payload: data
    }
}

export const addClientGroup = (data) => {
    return {
        type: CLIENT_GROUP.ADD_CLIENT_GROUP,
        payload: data
    }
}

export const editClientGroup = (data) => {
    return {
        type: CLIENT_GROUP.UPDATE_CLIENT_GROUP,
        payload: data
    }
}


export const removeClientGroup = (data) => {
    return {
        type: CLIENT_GROUP.DELETE_CLIENT_GROUP,
        payload: data
    }
}