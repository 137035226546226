
import { get_help_center, get_help_center_details } from 'Adapters/Api'
import { HELP_MODULE } from 'Redux/NstyleRedux/ActionTypes/HelpTypes'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

const useHelpCenter = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const helpData = state?.helpData?.help_data
    const [searchParams, setSearchParams] = useSearchParams();
    const [topics, setTopics] = useState([])
    const [topicDetail, setTopicDetail] = useState([])

    const [loader, setLoader] = useState(false)
    const [loader_detailed, setLoader_detailed] = useState(false)

    const [Active, setActive] = useState(0);

    const [Type, setType] = useState('');
    const [helpId, setHelpId] = useState('')
    const [searchText, setSearchText] = useState('')

    const getHelpCenterData = async () => {
        setLoader(true)
        const success = (data) => {
            if (data) {
                dispatch({
                    type: HELP_MODULE.GET_HELP_DATA,
                    payload: data
                })
                setLoader(false)
                setTopics(data)
            }
        }
        const fail = () => {
            setLoader(false)
        }
        const payload = {
            search: searchText,
        }
        get_help_center(payload, success, fail)
    }

    const getHelpCenterDetails = async (id) => {
        setLoader_detailed(true)
        const success = (data) => {
            if (data) {
                setLoader_detailed(false)
                setTopicDetail(data)
            }
        }
        const fail = () => {
            setLoader_detailed(false)
        }
        const payload = { id: id }
        get_help_center_details(payload, success, fail)
    }

    const onSearchChangeHandler = (e) => {
        setSearchText(e.target.value)
    }

    const redirectToHelp = () => {
        navigate('/help')
    }

    function sliceString(text) {
        if (text.length <= 120) {
            return text;
        }
        const lastSpaceIndex = text.slice(0, 120).lastIndexOf(' ');

        if (lastSpaceIndex === -1) {
            return text.slice(0, 120);
        } else {
            return text.slice(0, lastSpaceIndex);
        }
    }

    useEffect(() => {
        if (searchParams.get('type')) {
            setType(searchParams.get('type'));
        } else {
            setType('dashboard');
        }
        if (searchParams.get('id')) {
            getHelpCenterDetails(searchParams.get('id'))
        }
    }, [helpId])


    return {
        topics,
        loader,
        Type,
        topicDetail,
        loader_detailed,
        searchParams,
        Active, setActive,
        searchText, setSearchText,
        setHelpId,
        onSearchChangeHandler,
        redirectToHelp,
        getHelpCenterData,
        sliceString,
        helpData
    }
}

export default useHelpCenter