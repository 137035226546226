export const FileTypes = {
    pdf: "application/pdf",
    doc: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    mp4: "video/mp4",
    mp3: "audio/mpeg",
    zip: "application/x-zip-compressed",
    rar: "",
    webp: 'image/webp',
    excel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    xls: "application/vnd.ms-excel",
    csv: "text/csv",
    png: "image/png",
    jpg: 'image/jpg',
    jpeg: 'image/jpeg'
}

export const backendFileType = {
    pdf: "pdf",
    doc: "docx",
    mp3: "mp3",
    mp4: "mp4",
    zip: "zip",
    rar: "rar",
    jpg: "jpeg",
    jpeg: "jpeg",
    png: "png"
}