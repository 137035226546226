import { PRODUCT } from "../../ActionTypes/productTypes"


export const getProducts = (data) => {
    return {
        type: PRODUCT.GET_PRODUCT,
        payload: data
    }
}
export const getProductsDropdown = (data) => {
    return {
        type: PRODUCT.GET_PRODUCT,
        payload: data
    }
}
export const getProductsDropdownPagination = (data) => {
    return {
        type: PRODUCT.GET_PRODUCT_DROPDOWN_PAGINATION,
        payload: data
    }
}
export const getProductsOrderStock = (data) => {
    return {
        type: PRODUCT.GET_PRODUCT_ORDER_STOCK,
        payload: data
    }
}
export const getProductsWithoutPagination = (data) => {
    return {
        type: PRODUCT.GET_PRODUCT_WITH_NO_PAGINATION,
        payload: data
    }
}
export const get_product_stock_report = (data) => {
    return {
        type: PRODUCT.GET_PRODUCT_STOCK_REPORT,
        payload: data
    }
}
export const get_refund_reports_data = (data) => {
    return {
        type: PRODUCT.GET_REFUND_REPORT,
        payload: data
    }
}
export const updateProductStatus = (data) => {
    return {
        type: PRODUCT.GET_PRODUCT_UPDATED,
        payload: data
    }
}
export const getIds = (data) => {
    return {
        type: PRODUCT.GET_IDS_SALE_SERVICES,
        payload: data
    }
}
export const get_orderStocks = (data) => {
    return {
        type: PRODUCT.GET_ORDER_STOCK,
        payload: data
    }
}

export const addProduct = (data) => {
    return {
        type: PRODUCT.ADD_PRODUCT,
        payload: data
    }
}

export const updateProduct = (data) => {
    return {
        type: PRODUCT.UPDATE_PRODUCT,
        payload: data
    }
}


export const removeProduct = (data) => {
    return {
        type: PRODUCT.DELETE_PRODUCT,
        payload: data
    }
}