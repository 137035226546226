import React from "react";
import moment from "moment";

import Popup from "new-components/popup";

import Selection from "new-components/dropdown";

import useEmployeeSlot from "./helper";

import Svgs from "Assets/svgs";
import { useSelector } from "react-redux";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";

const AddEditEmployeeSlot = ({
  setEmployeeSlotPopup,
  employeeData,
  updateSlotData,
  isUpdate,
  setIsUpdate,
  isLeoDay,
  successCallBack
}) => {
  const {
    fields,
    errors,
    control,
    isLoading,
    timeSlotArr,
    watch,
    handleClose,
    onSubmit,
    handleSubmit,
    handleAddTimeSlot,
  } = useEmployeeSlot({
    employeeData,
    setEmployeeSlotPopup,
    updateSlotData,
    isUpdate,
    setIsUpdate,
    isLeoDay,
    successCallBack
  });

  const endTime = (index) => {
    let endTime = timeSlotArr
      .map((obj) => {
        if (
          moment(obj.value, "hh:mm").valueOf() <=
          moment(watch(`schedule[${index}].start_time`), "hh:mm").valueOf()
        ) {
        } else {
          return obj;
        }
      })
      .filter((obj) => obj !== undefined);
    return endTime;
  };

  const state = useSelector((state) => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  const startTime = (index) => {
    let startTime = timeSlotArr
      .map((obj) => {
        if (
          moment(obj.value, "hh:mm").valueOf() <=
          moment(watch(`schedule[${index}].end_time`), "hh:mm").valueOf()
        ) {
        } else {
          return obj;
        }
      })
      .filter((obj) => obj !== undefined);
    return startTime;
  };

  return (
    <Popup
      size="md"
      heading={
        <p className="text-sm">
          {employeeData?.full_name || updateSlotData?.employee?.full_name}
          {" "}
          {selectedLanguage?.name == "English" ? " shift" : translation[619]?.value ? translation[619]?.value : " shift"}
          {" "}
          {employeeData?.week}</p>}
      handleClose={handleClose}
    >
      {timeSlotArr?.length === 0 ? "The business location is not working on this day" :
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4">
            {fields &&
              fields?.map((ele, index) => {
                return (
                  <div key={index} className="flex items-center gap-3">
                    <Selection
                      name={`schedule[${index}].start_time`}
                      placeholder={selectedLanguage?.name == "English" ? " Select In Time" : translation[621]?.value ? translation[621]?.value : " Select In Time"}
                      options={
                        watch(`schedule[${index - 1}].end_time`)
                          ? startTime(index - 1)
                          : timeSlotArr
                      }
                      control={control}
                      errorMessage={
                        errors?.schedule &&
                          errors?.schedule[index]?.start_time?.message ?
                          selectedLanguage?.name == "English"
                            ? "This field is required"
                            : translation[287]?.value
                              ? translation[287]?.value
                              : "This field is required"
                          : ""
                      }
                    />

                    <Selection
                      name={`schedule[${index}].end_time`}
                      placeholder={selectedLanguage?.name == "English" ? " Select Out Time" : translation[622]?.value ? translation[622]?.value : " Select Out Time"}
                      options={endTime(index)}
                      control={control}
                      errorMessage={
                        errors?.schedule &&
                          errors?.schedule[index]?.end_time?.message ?
                          selectedLanguage?.name == "English"
                            ? "This field is required"
                            : translation[287]?.value
                              ? translation[287]?.value
                              : "This field is required"
                          : ""
                      }
                    />
                  </div>
                );
              })}
            {
              fields.length > 1 ? (
                ""
              ) :

                <p
                  className="flex items-center gap-1 text-primary cursor-pointer w-fit"
                  onClick={handleAddTimeSlot}
                >
                  <Svgs.Plus color="#5679FF" />
                  {selectedLanguage?.name == "English" ? " Add a shift" : translation[620]?.value ? translation[620]?.value : " Add a shift"}

                </p>
            }

            <LoginBtn
              text={selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        </form>}
    </Popup>
  );
};

export default AddEditEmployeeSlot;
