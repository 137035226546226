
import { LANGUAGE } from "../../ActionTypes/languageTypes";

const businessLanguages = {
  languages: [],
  selected_language: {},
  language_translation: [],
}

const LanguageReducer = (state = businessLanguages, action) => {

  const { type, payload } = action;

  switch (type) {
    case LANGUAGE.ADD_LANGUAGE:
      return {
        ...state,
        languages: [...payload],
      };
    case LANGUAGE.DELETE_LANGUAGE:
      return {
        ...state,
        languages: [
          ...state.languages.filter(lang => lang.id != action.payload)
        ],
      };

    case LANGUAGE.UPDATE_LANGUAGE:
      return {
        ...state,
        languages: [payload],
      };

    case LANGUAGE.GET_LANGUAGE_TRANSLATION:
      return {
        ...state,
        language_translation: payload,
      };

    case LANGUAGE.SELECTED_LANGUAGE:
      return {
        ...state,
        selected_language: action.payload,
      };

    default:
      return state;
  }

};

export default LanguageReducer;


