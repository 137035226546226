import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SupportCard from "../Elements/SupportCard";
import Vouchers from "./Voucher/Vouchers";
import Membership from "./Membership/Membership";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import { useParams } from 'react-router-dom';
import GiftCard from "./GiftCard/GiftCard";

const SharingSetings = () => {
  const navigate = useNavigate();
  const { activeTab } = useParams();
  const [Sharing, setSharing] = useState();
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    if (activeTab) {
      setSharing(activeTab)
    }
  }, [activeTab])
  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
      <Breadcrumb
        first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
        url={'/dashboard/clients/'}
        second={selectedLanguage?.name == "English" ? "Sharing Settings" : translation[451]?.value ? translation[451]?.value : "Sharing Settings"}
      />

      <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

        <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
          {selectedLanguage?.name == "English" ? "Sharing Settings" : translation[451]?.value ? translation[451]?.value : "Sharing Settings"}
        </h2>
      </div>

      <div className="border-b mt-[3rem]">
        <div className="flex items-center gap-6">
          <div
            className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${Sharing == "membership" && "font-semibold border-primary"
              }`}
            onClick={() => {
              setSharing("membership");
              navigate("/dashboard/clients/sharing-settings/membership")
            }}
          >
            <h2 className="text-[#595A5A]">
              {selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership"}
            </h2>
          </div>
          <div
            className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${Sharing == "voucher" && "font-semibold border-primary"
              }`}
            onClick={() => {
              setSharing("voucher");
              navigate("/dashboard/clients/sharing-settings/voucher")
            }}
          >
            <h2 className="text-[#101928]">
              {selectedLanguage?.name == "English" ? "Vouchers" : translation[196]?.value ? translation[196]?.value : "Vouchers"}
            </h2>
          </div>
          <div
            className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${Sharing == "gift-card" && "font-semibold border-primary"
              }`}
            onClick={() => {
              setSharing("gift-card");
              navigate("/dashboard/clients/sharing-settings/gift-card")
            }}
          >
            <h2 className="text-[#101928]">
              {selectedLanguage?.name == "English" ? "Gift Card" : translation[1540]?.value ? translation[1540]?.value : "Gift Card"}
            </h2>
          </div>
        </div>
      </div>
      <div className="mt-[3rem]">
        <div className="">
          {Sharing == "voucher" && <Vouchers />}

          {Sharing == "membership" && <Membership />}

          {Sharing == "gift-card" && <GiftCard />}

          {!employee ? (
            <div className="col-span-2">
              <div>
                <SupportCard />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default SharingSetings;
