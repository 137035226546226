import { MULTI_LANGUAGE_INVOICES } from "../../ActionTypes/multiLanguageInvoicesTypes";


export const getInvoiceTranslation = (data) => {
    return {
        type: MULTI_LANGUAGE_INVOICES.GET_INVOICE_TRANSLATION,
        payload: data
    }
}

export const addInvoiceTranslation = (data) => {
    return {
        type: MULTI_LANGUAGE_INVOICES.ADD_INVOICE_TRANSLATION,
        payload: data
    }
}

export const deleteInvoiceTranslation = (data) => {
    return {
        type: MULTI_LANGUAGE_INVOICES.DELETE_INVOICE_TRANSLATION,
        payload: data
    }
}
export const updateInvoiceTranslation = (data) => {
    console.log("🚀 ~ file: index.js:25 ~ updateInvoiceTranslation ~ data:", data)
    return {
        type: MULTI_LANGUAGE_INVOICES.UPDATE_INVOICE_TRANSLATION,
        payload: data
    }
}
export const getLanguages = (data) => {
    return {
        type: MULTI_LANGUAGE_INVOICES.GET_LANUAGES,
        payload: data
    }
}
