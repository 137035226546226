import CircleIcon from 'Components/TenantApp/Elements/CircleIcon'
import FormSteps from 'Components/TenantApp/Elements/FormSteps'
import React from 'react'

const ProductFormShimmer = ({ hideStep }) => {
    return (
        <>
            <div className="flex flex-col gap-6 animate-pulse">
                {!hideStep &&
                    <div className='flex items-center xl:gap-3 gap-1 justify-center topscroll'>
                        {[0, 1, 2].map((step, i) => {
                            return <>
                                <div key={i} className='flex items-center lg:flex-row text-center flex-col gap-1.5 xl:gap-3'>
                                    <div className={`h-[2.1875rem] w-[2.1875rem] rounded-full border-2 flex items-center justify-center font-semibold border-gray-200`}> </div>
                                    <div className="w-32 h-6 bg-gray-200 rounded-lg"></div>
                                </div>
                                {i < 2 &&
                                    <div className='h-[1px] w-[2.813rem] border-t-2 border-dashed border-gray-300'></div>
                                }
                            </>
                        })
                        }
                    </div>
                }

                <div className="flex flex-col gap-5 animate-pulse">
                    <div className="flex flex-1 items-center gap-4 lg:flex-row flex-col">
                        <CircleIcon />
                        <div className="flex flex-1 w-full flex-col gap-1 lg:text-left text-center">
                            <div className="w-2/3 h-6 bg-gray-200 rounded-lg" />
                            <div className="w-1/3 h-6 bg-gray-200 rounded-lg" />
                        </div>
                    </div>
                    <div className="flex-1 h-12 bg-gray-200 rounded-lg" />
                    <div className="grid lg:grid-cols-2 gap-5">
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                        <div className="h-12 bg-gray-200 rounded-lg" />
                    </div>
                    <div className="flex justify-end w-full">
                        <div className="w-1/3 h-14 bg-gray-200 rounded-lg" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductFormShimmer