import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import Popup from 'Components/TenantApp/Elements/Popup'
import React from 'react'
import useCustomGiftCard from './helper'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import Svgs from 'Assets/svgs'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import ToggleWithText from 'Components/TenantApp/Elements/ToggleWithText'
import Textarea from 'Components/TenantApp/Elements/Textarea'

const CustomGiftCardPopup = ({ open, close, setOpenCart, handleSelection }) => {
    const {
        gift_card_input,
        selectedLanguage,
        translation,
        LocationCurrency,
        errors,
        onBlurHandler,
        allUinqueCurrencyList,
        giftCardsubmithandler,
        codeToggle, setCodeToggle,
        btn_loading,
        giftCardCode, setGiftCardCode,
        handlegift_card_input,
    } = useCustomGiftCard({ handleSelection, setOpenCart, close })

    // const { price, retail_price, selectedCurrency } = currenciesDetails
    const { price, retail_price, title, valid_till, description, term_condition } = gift_card_input;

    return (
        <>

            <Popup open={open} close={close} heading={selectedLanguage?.name == "English" ? "Custom Gift Card" : translation[1670]?.value ? translation[1670]?.value : "Custom Gift Card"}>
                <div className='rounded-md w-full flex flex-col gap-4'>
                    <LoginInput
                        name={"title"}
                        required={true}
                        title={selectedLanguage?.name == "English" ? "Title" : translation[205]?.value ? translation[205]?.value : "Title"}
                        placeholder={selectedLanguage?.name == "English" ? "Title" : translation[205]?.value ? translation[205]?.value : "Title"}
                        value={title}
                        // charactersMaxLength={25}
                        onChange={handlegift_card_input}
                        // onEnterSubmit={membersubmithandler}
                        onBlur={() => onBlurHandler({ name: title })}
                        error={errors?.title}
                    />

                    <Textarea
                        required={true}
                        name={"description"}
                        rows={5}
                        value={description}
                        onChange={handlegift_card_input}
                        onBlur={() => onBlurHandler({ description: description })}
                        errorMessage={errors?.description}
                        title={selectedLanguage?.name == "English" ? "Description" : translation[1167]?.value ? translation[1167]?.value : "Description"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Description" : translation[1168]?.value ? translation[1168]?.value : "Enter Description"}
                    />

                    <div className='md:grid md:grid-cols-2 flex flex-col gap-4'>
                        <div className=''>
                            <FloatInput
                                rightIcon={LocationCurrency?.code}
                                required={true}
                                name={"price"}
                                value={price}
                                parentClass={errors?.price && 'mb-5'}
                                onChange={handlegift_card_input}
                                disabled={allUinqueCurrencyList?.length == gift_card_input?.currency_gift_card_price?.length}
                                onBlur={() => onBlurHandler({ price: price })}
                                error={errors?.price}
                                title={selectedLanguage?.name == "English" ? "Pricing" : translation[269]?.value ? translation[269]?.value : "Pricing"}
                                placeholder={selectedLanguage?.name == "English" ? "Price" : translation[200]?.value ? translation[200]?.value : "Price"}
                            />
                        </div>

                        <div className=''>
                            <FloatInput
                                required={true}
                                rightIcon={LocationCurrency?.code}
                                name={"retail_price"}
                                value={retail_price}
                                parentClass={errors?.retail_price && 'mb-5'}
                                onChange={handlegift_card_input}
                                disabled={allUinqueCurrencyList?.length == gift_card_input?.currency_gift_card_price?.length}
                                onBlur={() => onBlurHandler({ price: retail_price })}
                                error={errors?.retail_price}
                                title={selectedLanguage?.name == "English" ? "Retail Pricing" : translation[1545]?.value ? translation[1545]?.value : "Retail Pricing"}
                                placeholder={selectedLanguage?.name == "English" ? "Gift Card Retail Pricing" : translation[1546]?.value ? translation[1546]?.value : "Gift Card Retail Pricing"}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col gap-3">
                        <ToggleWithText
                            className={'w-full'}
                            value={codeToggle}
                            checked={codeToggle}
                            onChange={() => setCodeToggle(!codeToggle)}
                            title={selectedLanguage?.name == "English" ? "Use a custom gift card code" : translation[1661]?.value ? translation[1661]?.value : "Use a custom gift card code"}
                        />

                        {codeToggle &&
                            <LoginInput
                                name={"gift_card_code"}
                                required={true}
                                title={selectedLanguage?.name == "English" ? "Gift Card Code" : translation[1660]?.value ? translation[1660]?.value : "Gift Card Code"}
                                placeholder={selectedLanguage?.name == "English" ? "Enter Gift Card Code" : translation[1548]?.value ? translation[1548]?.value : "Enter Gift Card Code"}
                                value={giftCardCode}
                                charactersMaxLength={6}
                                onChange={(e) => {
                                    setGiftCardCode(e?.target?.value)
                                }}
                                onBlur={() => onBlurHandler({ name: giftCardCode })}
                                error={errors?.giftCardCode}
                            />
                        }
                    </div>
                    <div className='md:grid grid-cols-1 gap-4'>
                        <Dropwdown
                            required={true}
                            name={"valid_till"}
                            value={valid_till}
                            onChange={handlegift_card_input}
                            onBlur={() => onBlurHandler({ valid_till: valid_till })}
                            error={errors?.valid_till}
                            options={[
                                { label: '7 Days', value: '7 Days' },
                                { label: '14 Days', value: '14 Days' },
                                { label: '1 Month', value: '1 Months' },
                                { label: '2 Months', value: '2 Months' },
                                { label: '3 Months', value: '3 Months' },
                                { label: '4 Months', value: '4 Months' },
                                { label: '6 Months', value: '6 Months' },
                                { label: '8 Months', value: '8 Months' },
                                { label: '1 Years', value: '1 Years' },
                                { label: '18 Months', value: '18 Months' },
                                { label: '2 Years', value: '2 Years' },
                                { label: '5 Years', value: '5 Years' },
                            ]}
                            title={selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}
                            placeholder={selectedLanguage?.name == "English" ? "Select validity" : translation[1173]?.value ? translation[1173]?.value : "Select validity"}
                        />
                    </div>

                    <Textarea
                        required={true}
                        name={"term_condition"}
                        value={term_condition}
                        rows={5}
                        onChange={handlegift_card_input}
                        onBlur={() => onBlurHandler({ term_condition: term_condition })}
                        errorMessage={errors?.term_condition}
                        title={selectedLanguage?.name == "English" ? "Terms & Conditions" : translation[1174]?.value ? translation[1174]?.value : "Terms & Conditions"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Terms & Conditions" : translation[1175]?.value ? translation[1175]?.value : "Enter Terms & Conditions"}
                    />

                    <LoginBtn
                        text={selectedLanguage?.name == "English" ? "Add Gift Card" : translation[1543]?.value ? translation[1543]?.value : "Add Gift Card"}
                        disabled={btn_loading}
                        loading={btn_loading}
                        onClick={giftCardsubmithandler}
                    />
                </div>
            </Popup>
        </>
    )
}

export default CustomGiftCardPopup