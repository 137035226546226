import React from 'react'
import Shimmer from './Shimmer'

const EmployeesHeadShimmer = ({ classOn1, classOn2, classOn3, classOn4,classOn5, has3Itm, has4Itm,has5Itm,parentClass }) => {
    return (
        <>
            <div className={`flex items-center lg:justify-center justify-end gap-3 w-fit ${parentClass ? parentClass : ''}`}>
                <Shimmer className={`${classOn1} w-40 lg:w-44`}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                <Shimmer className={`${classOn2} w-40 lg:w-44`}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                {has3Itm &&
                    <Shimmer className={`${classOn3} w-40 lg:w-44`}>
                        <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                    </Shimmer>
                }
                {has4Itm &&
                    <>
                        <Shimmer className={`${classOn3} w-40 lg:w-44`}>
                            <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                        </Shimmer>
                        <Shimmer className={`${classOn4} w-40 lg:w-44`}>
                            <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                        </Shimmer>
                    </>
                }
                {has5Itm &&
                    <>
                        <Shimmer className={`${classOn3} w-40 lg:w-44`}>
                            <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                        </Shimmer>
                        <Shimmer className={`${classOn4} w-40 lg:w-44`}>
                            <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                        </Shimmer>
                        <Shimmer className={`${classOn5} w-40 lg:w-44`}>
                            <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                        </Shimmer>
                    </>
                }
            </div>
        </>
    )
}

export default EmployeesHeadShimmer