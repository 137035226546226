import React from 'react'

const TableShimer = ({ cols, className, colsHeight, rows }) => {
    let array = []
    for (let j = 0; j < cols; j++) {
        array.push(j);
    }


    let tableRows = []
    let totalRows = rows ? rows : 10
    for (let i = 0; i < totalRows; i++) {
        tableRows.push(i);
    }

    return (
        <>
            <div className="overflow-x-auto animate-pulse">
                <div className={`${className} inline-grid gap-2 xl:grid grid-cols-[repeat(${cols},minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit animate-pulse`}>
                    {array.map(itm => {
                        return (
                            <p className={`bg-gray-200 rounded-md h-${[colsHeight ? colsHeight : "8"]}`}> </p>
                        )
                    })}
                </div>
                <div className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}>
                    {tableRows.map((item, index) => {
                        return (
                            <div key={index} className={`${className} inline-grid px-12 py-1 xl:grid grid-cols-[repeat(${cols},minmax(115px,1fr))] min-w-full w-fit gap-2 animate-pulse`}>
                                {array.map(itm => {
                                    return (
                                        <p className={`bg-gray-200 rounded-md h-${colsHeight ? colsHeight : "12"}`}> </p>
                                    )
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    )
}

export default TableShimer