import React from 'react'

const CheckBoxCardShimmer = () => {
    return (
        <>
            <div className="cursor-pointer border rounded-lg animate-pulse flex items-center justify-between gap-5" >
                <div className="py-4 w-full rounded-lg m-2 bg-gray-200"></div>
            </div>
        </>
    )
}

export default CheckBoxCardShimmer