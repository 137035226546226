/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { MONTHS } from "Constants/Data/Months";
import { get_store_target_kpi, get_store_target_kpi_without_pagination } from "Adapters/Api/storeTarget";
import { toast } from "react-toastify";

let timeOutId = undefined
export const useReports = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const { selected_location } = state?.locations;
  const reportStatus = state?.storeTarget?.store_target_reports_updated;
  const reports = state?.storeTarget?.store_targets_report;
  const pages = state?.storeTarget?.store_targets_report_pages;
  const allCount = state?.storeTarget?.store_targets_report_allCount;
  const allReports = state?.storeTarget?.all_store_targets_report;
  const locations = state?.locations?.locations;
  const currencyOfSelectedLocation = (location) => locations?.find((obj) => obj?.id === location)?.currency?.code
  let date = new Date();
  let today_year = date.getFullYear();
  let month = date.getMonth();
  const PageSize = 10

  const [filterStore, setFilterStore] = useState("");
  const [filterYear, setFilterYear] = useState(today_year);
  const [filterMonth, setFilterMonth] = useState(month);
  const [loading, setLoading] = useState(true);
  const [noPaginationLoader, setNoPaginationLoader] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  //fetch
  useEffect(() => {
    let loader = () => {
      setLoading(true)
    }
    let success = () => {
      setLoading(false);
    }
    let fail = () => {
      setLoading(false);
    }
    if (timeOutId) {
      clearTimeout(timeOutId)
    }
    timeOutId = setTimeout(() => {
      dispatch(get_store_target_kpi(currentPage, filterMonth + 1, filterYear, filterStore, loader, success, fail));
    }, 500);

  }, [filterMonth, filterYear, currentPage, filterStore])

  useEffect(() => {
    setLoading(true)
    setNoPaginationLoader(true)
    setCurrentPage(1)
  }, [filterMonth, filterYear, filterStore])

  useEffect(() => {
    let loader = () => {
      setNoPaginationLoader(true)
    }
    let success = () => {
      setNoPaginationLoader(false);
    }
    let fail = () => {
      setNoPaginationLoader(false);
    }

    dispatch(get_store_target_kpi_without_pagination(filterMonth + 1, filterYear, filterStore, loader, success, fail));
  }, [filterMonth + 1, filterYear, filterStore])

  useMemo(() => {
    setDataList(reports);
  }, [reports, reports?.length, selected_location, filterMonth, filterYear])

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'filterStore':
        setFilterStore(value);
        break;
      case 'filterYear':
        setFilterYear(value);
        break;
      case 'filterMonth':
        setFilterMonth(value);
        break;
      default:
        break;
    }
  }

  const yearDropdown = useMemo(() => {
    if (today_year) {
      let years = [];
      for (let i = today_year; i <= 2040; i++) {
        years.push({ label: i.toString(), value: i.toString() })
      }
      return years;
    }
  }, [today_year])

  const monthDropdown = useMemo(() => {
    return MONTHS?.map((month, i) => {
      return {
        label: month,
        value: i,
      };
    })
  }, [])

  const locationDropdown = useMemo(() => {
    return [{ label: selectedLanguage?.name == "English" ? 'All Store' : translation[389]?.value ? translation[389]?.value : 'All Store', value: '' }, ...locations?.map((obj) => {
      return {
        label: obj?.address_name,
        value: obj?.id
      }
    })]
  }, [locations])

  const filterTarget = (obj) => {
    let location_tier = obj?.tier_target?.find(tierTarget => tierTarget?.location?.id === obj?.id && tierTarget?.tier?.find((tie) => tie.month.toLowerCase() === MONTHS[filterMonth].toLowerCase()))
    if (location_tier) {
      let tier = location_tier?.tier?.find((tie) => tie.month.toLowerCase() === MONTHS[filterMonth].toLowerCase())
      if (tier) {
        return tier
      }
    }
    return undefined
  }

  const reportsFilter = () => {
    const staffTargetfilterData = dataList
    // const staffTargetfilterData = dataList?.filter((item) => {
    //   if (filterStore) {
    //     let return_value = false;
    //     if (filterStore) {
    //       if (
    //         item?.address_name
    //           ?.toLowerCase()
    //           .includes(filterStore?.toLowerCase())
    //       ) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }
    //     return return_value;
    //   }
    //   return true;
    // });
    return staffTargetfilterData;
  };
  //export csv
  let headers = [
    { label: selectedLanguage?.name == "English" ? 'Store Name' : translation[381]?.value ? translation[381]?.value : "Store Name", key: "address_name" },
    { label: selectedLanguage?.name == "English" ? 'Total Service Target' : translation[371]?.value ? translation[371]?.value : "Total Service Target", key: "service_target" },
    { label: selectedLanguage?.name == "English" ? 'Total Service Sales' : translation[372]?.value ? translation[372]?.value : "Total Service Sales", key: "service_sale_price" },
    { label: selectedLanguage?.name == "English" ? 'Total Retail Target' : translation[373]?.value ? translation[373]?.value : "Total Retail Target", key: "retail_target" },
    { label: selectedLanguage?.name == "English" ? 'Total Retail Sales' : translation[374]?.value ? translation[374]?.value : "Total Retail Sales", key: "product_sale_price" },
    { label: selectedLanguage?.name == "English" ? 'Total Voucher Target' : translation[385]?.value ? translation[385]?.value : "Total Voucher Target", key: "voucher_target" },
    { label: selectedLanguage?.name == "English" ? 'Total Voucher Sales' : translation[386]?.value ? translation[386]?.value : "Total Voucher Sales", key: "voucher_sale_price" },
    { label: selectedLanguage?.name == "English" ? 'Total Membership Target' : translation[387]?.value ? translation[387]?.value : "Total Membership Target", key: "membership_target" },
    { label: selectedLanguage?.name == "English" ? 'Total Membership Sales' : translation[388]?.value ? translation[388]?.value : "Total Membership Sales", key: "membership_sale_price" },
    { label: selectedLanguage?.name == "English" ? 'Year' : translation[376]?.value ? translation[376]?.value : "Year", key: "year" },
    { label: selectedLanguage?.name == "English" ? 'Month' : translation[377]?.value ? translation[377]?.value : "Month", key: "month" },
  ];

  const csvReport = {
    filename: "Store Target Report.csv",
    headers: headers,
    data: allReports.map((item) => {

      return {
        ...item,
        address_name: item?.address_name ? item?.address_name : "--------",
        service_target: filterTarget(item)?.service_target ? filterTarget(item)?.service_target + " " + currencyOfSelectedLocation(item?.id) : "--------",
        service_sale_price: item?.service_sale_price ? item?.service_sale_price?.toFixed(2) + " " + currencyOfSelectedLocation(item?.id) : "--------",
        retail_target: filterTarget(item)?.retail_target ? filterTarget(item)?.retail_target + " " + currencyOfSelectedLocation(item?.id) : "--------",
        product_sale_price: item?.product_sale_price ? item?.product_sale_price?.toFixed(2) + " " + currencyOfSelectedLocation(item?.id) : "--------",
        voucher_target: filterTarget(item)?.voucher_target ? filterTarget(item)?.voucher_target + " " + currencyOfSelectedLocation(item?.id) : "--------",
        voucher_sale_price: item?.product_sale_price ? item?.product_sale_price?.toFixed(2) + " " + currencyOfSelectedLocation(item?.id) : "--------",
        membership_target: filterTarget(item)?.membership_target ? filterTarget(item)?.membership_target + " " + currencyOfSelectedLocation(item?.id) : "--------",
        membership_sale_price: item?.membership_sale_price ? item?.membership_sale_price + " " + currencyOfSelectedLocation(item?.id) : "--------",
        year: filterYear ? filterYear : "--------",
        month: MONTHS[filterMonth] ? MONTHS[filterMonth] : "--------",
      };
    }),
  };

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allReports?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  return {
    reportStatus, filterMonth, filterYear, filterStore, locationDropdown, yearDropdown, monthDropdown, csvReport,
    loading, currencyOfSelectedLocation, filterTarget, reportsFilter, navigate, handleChange, translation, selectedLanguage,
    currentPage, setCurrentPage, PageSize, pages, allCount, allReports, handleExportClick, exportLoader
  }
};