import { useEffect } from 'react';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import useCalculateTaxes from 'Hooks/useCalculateTaxes';
import { getLocationTax } from 'Adapters/Api/saleServices';
import { get_client_discount_promotion } from 'Adapters/Api/clientDiscountPromotion';

function useSaleModule() {
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const { saleService, utility } = useSelector((state) => state);
    const location_currency = utility?.currency?.location_currency;
    const locationTax = saleService?.location_tax
    const { calculateTaxes } = useCalculateTaxes(locationTax);
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    const selected_location = getDataFromLocalStorage("selected_location");
    const discountPromotionData = state?.clientDiscountPromotion?.discountPromotions_updated;
    const discountPromotions = state?.clientDiscountPromotion?.discountPromotions;

    useEffect(() => {
        !discountPromotionData && dispatch(get_client_discount_promotion())
    }, [discountPromotions?.length, !discountPromotionData])

    useEffect(() => {
        if (selected_location) {
            dispatch(getLocationTax(selected_location))
        }
    }, [selected_location])

    useEffect(() => {
        if (locationTax?.id) {
            calculateTaxes(locationTax)
        }
    }, [locationTax?.id])

    return {
        location_currency,
        translation,
        selectedLanguage
    };
}

export default useSaleModule