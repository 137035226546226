import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Popup from 'Components/TenantApp/Elements/Popup';
import React from 'react'
import { useAddServiceGroup } from './helper';
import Svgs from 'Assets/svgs';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import { useEffect } from 'react';
import UploadFile from 'Components/TenantApp/Elements/UploadFile';

const AddServiceGroup = (props) => {
    const { update, onClose, open, isLoading, editValues, addSuccess, updateSuccess } = props
    const { handleInputChange, setValues, errors, reduxServices, values, translation, selectedLanguage,
        not_selected_service, removedSelectedServices, loading, CreateGroup, EditGroup, servicesRedux }
        = useAddServiceGroup()

    useEffect(() => {
        if (editValues && update) {
            setValues(editValues)
        }
    }, [editValues, update])


    return (
        <Popup
            heading={
                !update ?
                    selectedLanguage?.name == "English" ? "Create Service Group" : translation[806]?.value ? translation[806]?.value : "Create Service Group"
                    : selectedLanguage?.name == "English" ? "Update Service Group" : translation[810]?.value ? translation[810]?.value : "Update Service Group"
            }
            size="md"
            close={() => {
                onClose()
                setValues({ name: "", service: [], status: true });
            }}
            open={open}
        >
            {update && isLoading ?
                <div className="flex flex-col gap-4 animate-pulse">
                    <div className="h-12 bg-gray-200 rounded-lg" />
                    <div className="h-12 bg-gray-200 rounded-lg" />
                    <div className="h-12 bg-gray-200 rounded-lg" />
                    <div className="flex justify-start w-full">
                        <div className="w-1/3 h-14 bg-gray-200 rounded-lg" />
                    </div>
                </div>
                :
                <div className="flex flex-col gap-4">
                    <LoginInput
                        title={selectedLanguage?.name == "English" ? "Group Name" : translation[546]?.value ? translation[546]?.value : "Group Name"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter group name" : translation[1726]?.value ? translation[1726]?.value : "Enter group name"}
                        value={values.name}
                        // charactersMaxLength={25}
                        onChange={handleInputChange}
                        name="name"
                        required={true}
                        error={errors?.name}
                    />
                    <Dropwdown
                        title={selectedLanguage?.name == "English" ? "Select Service" : translation[89]?.value ? translation[89]?.value : "Select Service"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Service" : translation[89]?.value ? translation[89]?.value : "Select Service"}
                        // value={values.service}
                        onChange={handleInputChange}
                        name="service"
                        options={not_selected_service
                            ?.filter((emp) => !values.service.includes(emp?.id))
                            .map((item) => {
                                return { label: item?.name, value: item.id };
                            })}
                    />
                    {(not_selected_service?.filter((emp) => !values.service.includes(emp?.id))?.length == 0) &&
                        <p className="text-xs">
                            {selectedLanguage?.name == "English"
                                ? "There is no service available to assign to this service group because one service can have one service group only"
                                : translation[807]?.value
                                    ? translation[807]?.value
                                    : "There is no service available to assign to this service group because one service can have one service group only"
                            }
                        </p>
                    }
                    {/* </div> */}
                    <p className="d-flex">
                        {values.service?.length > 0 ? (
                            <div className="flex items-center flex-wrap gap-2">
                                {values.service &&
                                    values.service?.map((item) => {
                                        let exist_service = servicesRedux?.find(
                                            (empl) => empl?.id == item
                                        );
                                        return (
                                            <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                                <p className="text-primary text-sm font-bold">
                                                    {exist_service?.name}
                                                </p>
                                                <span
                                                    onClick={() =>
                                                        removedSelectedServices(item, "serivicegroup")
                                                    }
                                                >
                                                    <Svgs.CirlceClose fill="#5679FF" />
                                                </span>
                                            </div>
                                        );
                                    })}
                            </div>
                        ) : (
                            not_selected_service
                                ?.filter((emp) => !values.service.includes(emp?.id))?.length > 0 &&
                            <div className="flex items-center gap-1">
                                <Svgs.I />
                                <p className="text-xs text-[#555555] normal-case">
                                    {selectedLanguage?.name == "English" ? "You can select multiple services" : translation[249]?.value ? translation[249]?.value : "You can select multiple services"}
                                </p>
                            </div>
                        )}
                    </p>

                    <Dropwdown
                        name="status"
                        value={values?.status}
                        onChange={(e) => {
                            setValues((prev) => ({ ...prev, status: e.target.value }));
                        }}
                        title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[483]?.value : "Select Status"}
                        options={[
                            {
                                value: true,
                                label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                            },
                            {
                                value: false,
                                label: selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                            },
                        ]}
                        required={true}
                    />

                    <UploadFile
                        accept={".png,.PNG,.jpg,.JPG,.jpeg,.JPEG"}
                        onChange={handleInputChange}
                        name="image"
                        mb_file_size={10}
                        id={'group_image'}
                        required
                        // edit={isEdit ? true : false}
                        value={values?.image}
                        title={selectedLanguage?.name == "English" ? "Service Image" : translation[1399]?.value ? translation[1399]?.value : "Service Image"}
                        error={errors?.image}
                    />
                    <div className="flex items-center justify-start">
                        {!update || update == "false" ? (
                            <LoginBtn
                                loading={loading}
                                disabled={loading}
                                onClick={(e) => {
                                    CreateGroup(() => {
                                        addSuccess && addSuccess()
                                        onClose()
                                    }, () => {

                                    })
                                }}
                            >
                                <p className="font-semibold text-white">
                                    {selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                                </p>
                            </LoginBtn>
                        ) : (
                            <LoginBtn
                                loading={loading}
                                disabled={loading}
                                onClick={(e) => {
                                    EditGroup(() => {
                                        updateSuccess && updateSuccess()
                                        onClose()
                                    }, () => {
                                    })
                                }}
                            >
                                <p className="font-semibold text-white">
                                    {" "}
                                    {selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}
                                </p>
                            </LoginBtn>
                        )}
                    </div>
                </div>
            }
        </Popup>
    )
}

export default AddServiceGroup