import React, { useEffect, useState } from "react";
import Svgs from "../../../../Assets/svgs";
import CheckboxCard from "../../Elements/CheckboxCard";
import Dropwdown from "../../Elements/Dropwdown";
import LoginBtn from "../../Elements/LoginBtn";
import { useNavigate } from "react-router-dom";
import SupportCard from "../Clients/Elements/SupportCard";
import { useDispatch, useSelector } from "react-redux";
import { getClient } from "../../../../Adapters/Api/clients";
import { addLocations } from "../../../../Redux/TenantRedux/Actions/locationActions";
import { getLocations } from "../../../../Adapters/Api";
import useSetDataInLocalStorage from "../../../../Hooks/useSetDataInLocalStorage";
import PromotionCheckoutCard from "./Checkout/PromotionChackoutCard";
import RewardsCheckoutCard from "./Checkout/RewardsCheckoutCard";
import MemberShipCheckoutCard from "./Checkout/MemberShipCheckoutCard";
import SubscriptionCheckoutCard from "./Checkout/SubscriptionCheckoutCard";
import BookingDetailCard from "./Elements/BookingDetailsCard";
import {
  CREATE_APPOINTMENT,
  RESET_APPOINTMENT_DATA,
  SET_APPOINTMENT_INPUT_DATA,
} from "../../../../Redux/TenantRedux/ActionTypes/Appointment/Appointment";
import {
  createAppointment,
  getClientSales,
} from "../../../../Adapters/Api/apointments";
import { toast } from "react-toastify";
import ClientSection from "./AddAppointment/ClientSection";
import { GET_CLIENT_SALE } from "./../../../../Redux/TenantRedux/ActionTypes/Appointment/Appointment";
import { apiErrorMessage } from "Constants/Data/Errors";

const Checkout = () => {
  const state = useSelector((state) => state);
  const selected_client = state?.appointment?.selected_client;
  const reduxlocationsList = state?.locations?.locations;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [btn_loading, setBtnLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  const selected_location = getDataFromLocalStorage("selected_location");

  const [selectedLocation, setLocationSelection] = useState("");
  const [selectedid, setSelectedId] = useState();
  const [appointmentData, setAppointmentData] = useState({});
  const [discount, setdiscount] = useState(false);

  const AppointmentDatahandler = (e) => {
    const { name, value } = e.target;

    if (name === "client") {
      setSelectedId(value);
      setAppointmentData({
        ...appointmentData,
        [name]: value,
      });
    }

    if (name === "client_type" && value === "Walkin") {
      //reset client details when user select client type walk in
      setSelectedId("");
      setReset(true);
      setAppointmentData({
        ...appointmentData,
        client: "",
        [name]: value,
      });
    } else {
      setAppointmentData({
        ...appointmentData,
        [name]: value,
      });
    }
  };

  const getBusinessLocation = async (business_id) => {
    dispatch(getLocations(business_id));
  };

  const handle_submit = () => {
    const business = getDataFromLocalStorage("business_data");
    setBtnLoading(true);
    dispatch(
      createAppointment(
        {
          ...appointmentData,
          business: business.id,
          business_address: selected_location,
        },
        async (appointments) => {
          toast.success("Appointment created successfully", { toastId: "toast" });
          await dispatch({
            type: RESET_APPOINTMENT_DATA,
          });
          await dispatch({
            type: CREATE_APPOINTMENT,
            payload: appointments,
          });
          await dispatch({
            type: GET_CLIENT_SALE,
            payload: {},
          });
          setBtnLoading(false);
          setAppointmentData({});
          setSelectedId();
          navigate("/dashboard/calendar/");
        },
        () => {
          setBtnLoading(false);
          toast.error(apiErrorMessage, { toastId: "toast" });
        }
      )
    );
  };

  useEffect(() => {
    if (Object.keys(state?.appointment?.appointment_inp_data).length == 0) {
      navigate("/dashboard/calendar/add-appointment");
    } else {
      if (state.client.clients.length == 0) {
        dispatch(getClient());
      }
      if (
        state.locations.locations.length == 0 &&
        businessData &&
        businessData.id
      ) {
        getBusinessLocation(businessData.id);
      }
      setAppointmentData({
        ...state?.appointment?.appointment_inp_data,
        client: selected_client?.id ? selected_client?.id : "",
      });
      setSelectedId(selected_client?.id);
    }
  }, []);

  useEffect(() => {
    if (selectedid) {
      setAppointmentData({ ...appointmentData, client: selectedid });
      getClientSales(
        { client: selectedid, location_id: selected_location },
        (data) => {
          let ClientSaleDetail = {
            appointment: data?.appointment,
            product: data?.product,
            voucher: data?.voucher,
            service: data?.service
          };

          dispatch({
            type: GET_CLIENT_SALE,
            payload: ClientSaleDetail,
          });
        },
        () => {
          toast.error("invalid client id!", { toastId: "toast" });
        }
      );
    }
  }, [selectedid]);

  useEffect(() => {
    if (selected_location) {
      setLocationSelection(selected_location);
    } else {
      if (reduxlocationsList?.length > 0) {
        setLocationSelection(reduxlocationsList[0]?.id);
      }
    }
  }, [reduxlocationsList?.length]);

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <div className="flex flex-col gap-4">
          {/* <h2 className="text-primary font-bold lg:text-3xl text-xl">Add Appointment</h2> */}
          <div className="flex items-center gap-3">
            <span
              onClick={() => {
                navigate(-1);
              }}
            >
              <Svgs.Back />
            </span>
            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              Add Appointment
            </h2>
          </div>
          <div className="grid lg:grid-cols-5 mt-[3rem] gap-[3rem]">
            <div className="flex flex-col gap-5 lg:col-span-3">
              <h2 className="text-primary font-bold text-xl">Client Details</h2>
              <div className="border rounded-lg p-4 flex flex-col gap-4 text-sm">
                <div className="grid lg:grid-cols-2 gap-4">
                  <Dropwdown
                    title="Client Type"
                    placeholder="Select Client Type"
                    name={"client_type"}
                    value={appointmentData?.client_type}
                    options={[
                      { label: "In House", value: "InHouse" },
                      { label: "Walk In", value: "Walkin" },
                    ]}
                    onChange={AppointmentDatahandler}
                  />

                  {appointmentData?.client_type != "Walkin" && (
                    <Dropwdown
                      title="Client"
                      required={true}
                      placeholder="Select Client"
                      name={"client"}
                      value={appointmentData?.client}
                      options={[
                        ...state?.client?.clients
                          ?.filter((itm) => itm?.is_active === true)
                          ?.map((client, index) => {
                            return {
                              label: client.full_name,
                              value: client.id,
                            };
                          }),
                      ]}
                      onChange={AppointmentDatahandler}
                    />
                  )}
                </div>
                <div
                  className="flex items-center gap-2 text-primary text-sm font-semibold cursor-pointer w-fit"
                  onClick={() => {
                    navigate("/dashboard/clients/client-profiles/add-client");
                  }}
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect x="6" width="2" height="14" fill="#5679FF" />
                    <rect
                      x="14"
                      y="6"
                      width="2"
                      height="14"
                      transform="rotate(90 14 6)"
                      fill="#5679FF"
                    />
                  </svg>
                  <p>Add Client</p>
                </div>
                <Dropwdown
                  disabled={selectedLocation}
                  required={true}
                  title="Business Location"
                  placeholder="Select Business Location"
                  name={"business_address"}
                  value={selectedLocation}
                  onChange={AppointmentDatahandler}
                  options={state?.locations?.locations
                    ?.filter((itm) => itm.banking == "Enable")
                    ?.map((location, index) => {
                      return {
                        label: location.address_name,
                        value: location.id,
                      };
                    })}
                />
              </div>
              {/* <div className='flex items-center gap-2 text-primary text-sm font-semibold cursor-pointer w-fit' onClick={() => {
                                setdiscount(!discount)
                                setAppointmentData({})
                            }}>
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="6" width="2" height="14" fill="#5679FF" />
                                    <rect x="14" y="6" width="2" height="14" transform="rotate(90 14 6)" fill="#5679FF" />
                                </svg>
                                <p>Avail Discounts</p>
                            </div> */}
              {discount && (
                <div className="border rounded-lg p-4 flex flex-col gap-4 text-sm">
                  <h2 className="font-semibold">
                    Click to view your discounts
                  </h2>
                  <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-2">
                    <CheckboxCard
                      checked={appointmentData?.discount_type == "PROMOTIONS"}
                      onClick={() => {
                        AppointmentDatahandler({
                          target: {
                            name: "discount_type",
                            value: "PROMOTIONS",
                          },
                        });
                      }}
                      id={"PROMOTIONS"}
                      title={
                        <div className="flex items-center gap-2">
                          <Svgs.PromotionsBadge />
                          <p className="font-semibold text-sm">Promotions</p>
                        </div>
                      }
                    />
                    <CheckboxCard
                      checked={appointmentData?.discount_type == "REWARDS"}
                      onClick={() => {
                        AppointmentDatahandler({
                          target: { name: "discount_type", value: "REWARDS" },
                        });
                      }}
                      id={"REWARDS"}
                      title={
                        <div className="flex items-center gap-2">
                          <Svgs.RewardBadge />
                          <p className="font-semibold text-sm">Rewards</p>
                        </div>
                      }
                    />
                    <CheckboxCard
                      checked={appointmentData?.discount_type == "VOUCHERS"}
                      onClick={() => {
                        AppointmentDatahandler({
                          target: { name: "discount_type", value: "VOUCHERS" },
                        });
                      }}
                      id={"VOUCHERS"}
                      title={
                        <div className="flex items-center gap-2">
                          <Svgs.VoucherBadge />
                          <p className="font-semibold text-sm">Vouchers</p>
                        </div>
                      }
                    />
                    <CheckboxCard
                      checked={appointmentData?.discount_type == "MEMBERSHIPS"}
                      onClick={() => {
                        AppointmentDatahandler({
                          target: {
                            name: "discount_type",
                            value: "MEMBERSHIPS",
                          },
                        });
                      }}
                      id={"MEMBERSHIPS"}
                      title={
                        <div className="flex items-center gap-2">
                          <Svgs.MembershipsBadge />
                          <p className="font-semibold text-sm">Memberships</p>
                        </div>
                      }
                    />
                    <CheckboxCard
                      checked={
                        appointmentData?.discount_type == "SUBSCRIPTIONS"
                      }
                      onClick={() => {
                        AppointmentDatahandler({
                          target: {
                            name: "discount_type",
                            value: "SUBSCRIPTIONS",
                          },
                        });
                      }}
                      id={"SUBSCRIPTIONS"}
                      title={
                        <div className="flex items-center gap-2">
                          <Svgs.SubscriptionsBadge />
                          <p className="font-semibold text-sm">Subscriptions</p>
                        </div>
                      }
                    />
                  </div>
                </div>
              )}

              {appointmentData?.discount_type == "PROMOTIONS" && (
                <>
                  <PromotionCheckoutCard />
                </>
              )}
              {appointmentData?.discount_type == "REWARDS" && (
                <>
                  <RewardsCheckoutCard />
                </>
              )}
              {appointmentData?.discount_type == "MEMBERSHIPS" && (
                <>
                  <MemberShipCheckoutCard />
                </>
              )}
              {appointmentData?.discount_type == "SUBSCRIPTIONS" && (
                <>
                  <SubscriptionCheckoutCard />
                </>
              )}
              <LoginBtn
                text="Create Appointment"
                loading={btn_loading}
                onClick={() => {
                  // appointment_submit_handler()
                  handle_submit();
                }}
                disabled={
                  btn_loading ||
                    !appointmentData ||
                    !selectedLocation ||
                    Object.keys(appointmentData).length == 0 ||
                    (!appointmentData?.client &&
                      appointmentData?.client_type != "Walkin")
                    ? true
                    : false
                }
                className="w-fit"
              />
            </div>

            {appointmentData?.client_type != "Walkin" && (
              <ClientSection
                id={selectedid}
                setSelectedId={setSelectedId}
                reset={reset}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
