import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const NoEmployeeDataOnCalendar = () => {
    const navigate = useNavigate()
    const state = useSelector((state) => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    return (
        <>
            <div className="absolute inset-0 z-[222] flex items-center justify-center">
                <div className="text-center">
                    <img
                        src={`${process.env.PUBLIC_URL}/images/no-employee.png`}
                        className="h-[15rem]"
                    />
                    <h1 className="font-semibold normal-case">
                        {selectedLanguage?.name == "English" ? "This location has not any scheduled staff." : translation[124]?.value ? translation[124]?.value : "This location has not any scheduled staff."}
                    </h1>
                    <p className="text-sm text-[#555555]">
                        {selectedLanguage?.name == "English" ? "Go to" : translation[125]?.value ? translation[125]?.value : "Go to"}
                        {" "}
                        <span
                            onClick={() => {
                                navigate("/dashboard/employee/employees");
                            }}
                            className="underline text-primary cursor-pointer"
                        >
                            {selectedLanguage?.name == "English" ? "Employees Management" : translation[126]?.value ? translation[126]?.value : "Employees Management"}
                        </span>{" "}
                        {selectedLanguage?.name == "English" ? "to assign/add staff at this location" : translation[127]?.value ? translation[127]?.value : "to assign/add staff at this location"}
                    </p>
                </div>
            </div>
        </>
    )
}

export default NoEmployeeDataOnCalendar