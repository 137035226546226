// import { get_all_segments } from "Adapters/Api/segments"
import { delete_Campaign, get_all_campaigns } from "Adapters/Api/campaigns"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"


const initialFilter = {
  segment_type: "",
  is_active: "",
}

let campaignTimeout = undefined
const useCampaign = () => {
  const dispatch = useDispatch()
  const state = useSelector(state => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const { getDataFromLocalStorage } = useSetDataInLocalStorage()
  const access_token = getDataFromLocalStorage('access_token')

  const [CreateCompanign, setCreateCompanign] = useState(false);
  const [ViewDetails, setViewDetails] = useState(false);
  const [isEdit, setIsEdit] = useState(false)

  const [loading, setLoading] = useState(true)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [FilterPopup, setFilterPopup] = useState(false)
  const [deletePopup, setDeletePopup] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState({})

  const [currentPage, setCurrentPage] = useState(1);
  const [searchCampaign, setSearchCampaign] = useState('')
  const [filterData, setFilterData] = useState(initialFilter)

  const campaigns = state?.campaigns?.campaigns;
  const campaignPagination = state?.campaigns?.campaignPagination;
  const [apiCall, setApiCall] = useState('')


  const getCampaignDetail = (data, type) => {
    setCampaignDetails(data)
    if (type == "detail") {
      setViewDetails(true);
    }
    if (type == "edit") {
      setIsEdit(true)
      setCreateCompanign(true)
    }
    if (type == "delete") {
      setDeletePopup(true);
    }
  }

  const getCampaigns = (apply) => {
    setLoading(true)
    const payload = {
      page: currentPage,
    };
    if (searchCampaign) {
      payload.search_text = searchCampaign
    }
    if (apply) {
      payload.segment_type = filterData?.segment_type;
      payload.is_active = filterData?.is_active;
    }
    const success = () => { setLoading(false) }
    const fail = () => { setLoading(false) }
    dispatch(get_all_campaigns(access_token, payload, success, fail))
  }


  const deleteCampaign = (id) => {
    setDeleteLoading(true)
    const success = () => {
      setApiCall(id)
      setDeletePopup(false)
      setDeleteLoading(false)
    }
    const fail = () => {
      setDeletePopup(false)
      setDeleteLoading(false)
    }
    dispatch(delete_Campaign(access_token, id, success, fail))
  }

  useEffect(() => {
    if (searchCampaign === null) {
      getCampaigns()
    } else {
      if (campaignTimeout) {
        clearTimeout(campaignTimeout)
      }
      campaignTimeout = setTimeout(() => {
        getCampaigns()
      }, 500);
    }
  }, [searchCampaign, currentPage, apiCall])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchCampaign])

  return {
    loading, translation, selectedLanguage, campaignDetails, campaignPagination,
    campaigns, deleteCampaign,
    CreateCompanign, setCreateCompanign,
    ViewDetails, setViewDetails,
    FilterPopup, setFilterPopup,
    searchCampaign, setSearchCampaign,
    deleteLoading, getCampaignDetail,
    deletePopup, setDeletePopup,
    currentPage, setCurrentPage,
    isEdit, setIsEdit, setApiCall

  }
}

export default useCampaign