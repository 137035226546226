import { getEmployeeDropDownListPagination } from "Adapters/Api/employee"
import { fetchVacationList, update_vacation_status } from "Adapters/Api/vacations"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { VACATION } from "Redux/TenantRedux/ActionTypes/vacationTypes"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

let timeOutId = undefined
let employeeTimeOutId = undefined
const useLeave = () => {
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const vacationList = state?.vacation?.vacations;
  const pages = state?.vacation?.pages;
  const allCount = state?.vacation?.allCount;
  const employeeListRedux = state?.employee?.employeesDropdown;
  const employeePaginationData = state?.employee?.employeeDropdownPaginationData

  const { getDataFromLocalStorage } = useSetDataInLocalStorage()
  const access_token = getDataFromLocalStorage('access_token')
  const selected_location = getDataFromLocalStorage("selected_location");

  const [loader, setLoader] = useState(true)
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ShowDetails, setShowDetails] = useState(0)
  const [filterStaff, setFilterStaff] = useState("");
  const [loadingPermission, setLoadingPermission] = useState(true)
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [employeePage, setEmployeePage] = useState(1)
  const [employeeLoader, setEmployeeLoader] = useState(false)
  const [statusLoader, setStatusLoader] = useState({
    accepted: false,
    declined: false,
  });

  const PageSize = 10

  useEffect(() => {
    if (searchText === null) {
      // Initial load with empty searchText
      handleGetVacationList(currentPage, selected_location, filterStaff, '', access_token)
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        handleGetVacationList(currentPage, selected_location, filterStaff, searchText, access_token)
      }, 500);
    }
  }, [currentPage, selected_location, searchText, filterStaff])

  useEffect(() => {
    setLoader(true)
  }, [currentPage, filterStaff, selected_location])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchText, selected_location, filterStaff])

  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    if (selected_location) {
      if (employeeSearch === null) {
        // Initial load with empty search_text
        dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
      } else {
        if (employeeTimeOutId) {
          clearTimeout(employeeTimeOutId)
        }
        employeeTimeOutId = setTimeout(() => {
          dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
        }, 500);
      }
    }
  }, [employeeSearch, selected_location, employeePage])

  const handleGetVacationList = async (currentPage, selected_location, filterStaff, searchText, access_token) => {
    const response = await fetchVacationList(currentPage, selected_location, filterStaff, searchText, access_token, setLoader);
    if (response.status === 200) {
      let vacationList = response?.data?.response?.vacations;
      let pages = response?.data?.response;
      dispatch({
        type: VACATION.GET_VACATIONS,
        payload: vacationList,
      })
      dispatch({
        type: VACATION.GET_VACATION_PAGES,
        payload: pages,
      })
      setLoader && setLoader(false)
    }
  };

  const handleUpdateVacationStatus = (id, employeeId, status, vacationType, noOfDays) => {
    let payload = {
      created_from_dashboard: "true",
      vacation_id: id,
      vacation_type: vacationType,
      vacation_status: status,
      employee: employeeId,
      total_days_to_detect: noOfDays
    }
    setStatusLoader(() => ({
      id: id,
      [status]: true,
    }));
    // success function
    let success = () => {
      setStatusLoader(() => ({
        id: id,
        [status]: false,
      }));
      setCurrentPage(1)
      handleGetVacationList(1, selected_location, filterStaff, searchText, access_token)
      setLoader(true)
    }
    // fail function
    let fail = () => {
      setStatusLoader(() => ({
        id: id,
        [status]: false,
      }));
    }
    // console.log(payload)
    dispatch(update_vacation_status(payload, access_token, success, fail))
  }

  return {
    ShowDetails, setShowDetails, searchText, setSearchText, translation, selectedLanguage, loader,
    filterStaff, setFilterStaff, loadingPermission, vacationList, pages, allCount, employeeListRedux,
    employeePaginationData, currentPage, setCurrentPage, PageSize, employeePage, setEmployeePage,
    employeeSearch, setEmployeeSearch, employeeLoader, setEmployeeLoader, statusLoader,
    handleUpdateVacationStatus
  }
}

export default useLeave