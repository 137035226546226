import React from 'react'

const AppointmentCommentShimmer = ({itemLength,headShimmer,parentClass}) => {
    let arr = itemLength ? itemLength : [0, 1, 2, 3, 4]
    return (
        <>
            <div className={`p-4 ${parentClass}`}>
                <div>
                {headShimmer && <div className='animate-pulse bg-gray-200 h-[1.5rem] w-32 overflow-hidden'></div>}
                    {arr?.map((app, ind) => {
                        return <>
                            <div className=" p-2 last:border-0 border-b-2 border-[#DFE6FF]">
                                <div className=" flex gap-3 items-center py-2">
                                <div className='animate-pulse bg-gray-200 h-[2.5rem] w-[2.5rem] bg rounded-full overflow-hidden'>
                                    
                                </div>
                                <div className='w-40'>
                                    <p className="my-1 animate-pulse text-[#7B7B7B] text-sm bg-gray-200 h-3 w-full"></p>
                                    <p className="animate-pulse text-[#7B7B7B] text-sm bg-gray-200 h-3 w-full"></p>
                                </div>
                                </div>
                                <div className="animate-pulse bg-gray-200 py-3 w-full h-8">
                                    <p className="text-[#444] text-justify h-full w-full"><span className="text-[#5679FF]"></span><span className="text-[#5679FF]"></span></p>
                                </div>
                            </div>
                        </>
                    })}
                </div>
            </div>
        </>
    )
}

export default AppointmentCommentShimmer