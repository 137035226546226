import { STAFF_TARGET } from "Redux/TenantRedux/ActionTypes/staffTargetTypes";

export const add_StaffTarget = (data) => {
  return {
    type: STAFF_TARGET.ADD_STAFFTARGET,
    payload: data,
  };
};

export const get_StaffTarget = (data) => {
  return {
    type: STAFF_TARGET.GET_STAFFTARGET,
    payload: data,
  };
};

export const get_StaffTarget_No_Pagination = (data) => {
  return {
    type: STAFF_TARGET.GET_STAFFTARGET_WITHOUT_PAGINATION,
    payload: data,
  };
};

export const delete_StaffTarget = (id) => {
  return {
    type: STAFF_TARGET.DELETE_STAFFTARGET,
    payload: id,
  };
};

export const update_StaffTarget = (data) => {
  return {
    type: STAFF_TARGET.UPDATE_STAFFTARGET,
    payload: data,
  };
};

export const copy_StaffTarget = (data) => {
  return {
    type: STAFF_TARGET.COPY_STAFFTARGET,
    payload: data,
  };
};

// staff target report 
export const get_StaffTarget_report = (data) => {
  return {
    type: STAFF_TARGET.GET_STAFF_TARGET_REPORT,
    payload: data,
  };
};

// staff target report without pagination
export const get_StaffTarget_report_without_pagination = (data) => {
  return {
    type: STAFF_TARGET.GET_STAFF_TARGET_REPORT_WITHOUT_PAGINATION,
    payload: data,
  };
};