import React from "react";


import Popup from "new-components/popup";

import Svgs from "Assets/svgs";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";

const UploadWorkingSchedule = ({ handleClose }) => {
  return (
    <Popup heading={`Import Categories`} handleClose={handleClose}>
      <div className="flex flex-col gap-4 border-[#A1A1A1] border-dashed border-2 p-4 rounded-lg text-center">
        <div className="h-[15rem]">
          <Svgs.UploadList />
        </div>
        <p className="text-[#595959]">
          Drop your .CSV file to add working schedule
        </p>
        <p className="text-[#595959]">Or</p>
        {/* <LoginBtn size="sm" className="mx-auto">
          <label htmlFor="csvFileforproduct" className="cursor-pointer">
            <div className="flex items-center gap-2 text-[#ffffff]">
              Upload New List
            </div>
          </label>
        </LoginBtn> */}
        <LoginBtn className={"mx-auto"} text={"Upload New List"} />
      </div>
    </Popup>
  );
};

export default UploadWorkingSchedule;
