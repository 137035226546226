import { SIGNUP_USER } from './../../ActionTypes/signupTypes/index';

export const signupUserPersonalInfo = (data) => {
    return {
        type: SIGNUP_USER.PERSONAL_INFO,
        payload: data,
    }
}


export const signupUserBusinessDetails = (data) => {
    return {
        type: SIGNUP_USER.BUSINESS_DETAILS,
        payload: data,
    }
}


export const signupUserBusinessType = (data) => {
    return {
        type: SIGNUP_USER.BUSINESS_TYPE,
        payload: data,
    }
}


export const signupUserTeamSize = (data) => {
    return {
        type: SIGNUP_USER.TEAM_SIZE,
        payload: data,
    }
}


export const signupUserSoftwareUsing = (data) => {
    return {
        type: SIGNUP_USER.SOFTWARE_USING,
        payload: data,
    }
}


export const signupUserFindUs = (data) => {
    return {
        type: SIGNUP_USER.FIND_US,
        payload: data,
    }
}

export const signupUserOpiningHours = (data) => {
    return {
        type: SIGNUP_USER.OPENINIG_HOURS,
        payload: data,
    }
}