import { getPaymentMethods } from 'Adapters/Api/financialSettings'
import NotFound from 'Components/Utility/NotFound'

import { apiErrorMessage } from 'Constants/Data/Errors'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getPaymentMethodList } from 'Redux/TenantRedux/Actions/financialSettingsActions'
import { AppointmentCheckout, createAppointment, getAppointments, getAppointmentServices, getClientCheckoutVouchers, getClientCheckoutMemberships, getClientLoyaltyPoints, getSingleAppointment, UpdateAppointment, getSingleCouponOnCheckout, getClientCheckoutGiftCard } from '../../../../Adapters/Api/apointments'
import Svgs from '../../../../Assets/svgs'
import { APPOINTMENT_TIPS } from '../../../../Constants/Data/AppointmentsData'
import useSetDataInLocalStorage from '../../../../Hooks/useSetDataInLocalStorage'
import CheckboxCard from '../../Elements/CheckboxCard'
import LoginBtn from '../../Elements/LoginBtn'
import LoginInput from '../../Elements/LoginInput'
import Popup from '../../Elements/Popup'
import SupportCard from '../Clients/Elements/SupportCard'
import BookingDetailCard from './Elements/BookingDetailsCard'
import useAppointmentServiceDetails from './useAppointmentServiceDetails'
import moment from "moment";
import { getEmployeeList } from 'Adapters/Api/employee'
import { CLIENT } from 'Redux/TenantRedux/ActionTypes/clientActionTypes'
import CheckBoxCardShimmer from 'Components/Utility/Shimmers/CheckBoxCardShimmer'
import PaymentCheckboxCard from 'Components/TenantApp/Elements/PaymentCheckboxCard'
import { OTHER_PAYMENT_METHODS } from 'Constants/Data/Payment'
import { CreateNewCheckout } from 'Adapters/Api/saleServices'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'

// import { PAYMENT_METHODS } from '../../../../Constants/Data/Payment'
// import { RESET_APPOINTMENT_DATA, SET_APPOINTMENT_INPUT_DATA } from '../../../../Redux/TenantRedux/ActionTypes/Appointment/Appointment'
// import Dropwdown from '../../Elements/Dropwdown'
// import InputSection from 'Components/TenantApp/Elements/InputSection'
const CheckoutFinish = () => {
    const params = useParams()
    const state = useSelector(state => state);
    const [searchParams, setSearchParams] = useSearchParams();
    const app_group_id = searchParams?.get("app_group_id");
    const parent_id = searchParams?.get("parent_id");
    const { appointmentServices, setAppointmentService, subTotal, setSubTotal } = useAppointmentServiceDetails(app_group_id);
    const total_tax = state?.saleService?.location_tax?.tax ? state?.saleService?.total_tax : 0;
    const reduxPaymentMethodList = useSelector((state) => state?.financialSettings?.paymentMethod);

    const { getDataFromLocalStorage, storeDataInLocalStorage } = useSetDataInLocalStorage();
    let selected_location = getDataFromLocalStorage("selected_location");
    let businessData = getDataFromLocalStorage("business_data");

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [appointmentData, setAppointmentData] = useState({ selected_tip: 0, other_method: "" })
    const [all_tips, setAllTips] = useState([])
    const [loyalityPoints, setLoyalityPoints] = useState({})
    const [existingLoyalityPoints, setExistingLoyalityPoints] = useState({})
    const [checkoutVouchers, setCheckoutVouchers] = useState([])
    const [checkoutMemberships, setCheckoutMembership] = useState([])
    const [notFound, setNotFound] = useState(false)
    const [voucherNotFound, setVoucherNotFound] = useState(false)
    const [membershipNotFound, setMembershipNotFound] = useState(false)
    const [checkoutLoader, setCheckoutLoader] = useState(false)
    const [tip_for_emply, setTip_for_emply] = useState([])
    const [employee_tips, setEmployee_tips] = useState([])
    const [employeeTips, setEmployeeTips] = useState([])
    const [currentEmployeeTip, setCurrentEmployeeTip] = useState([])
    const [employeeTipSum, setEmployeeTipSum] = useState(0)
    const [Voucher, setVoucher] = useState(false)
    const [SplitTip, setSplitTip] = useState(false)
    const [TipToStaff, setTipToStaff] = useState(false)
    const [ConfirmRedeem, setConfirmRedeem] = useState(false)
    const [tip_inp, setTipInp] = useState(0)
    const [t_price, setT_price] = useState(0)
    const [is_redeemed, setIs_redeemed] = useState(false)
    const [redeemed_id, setRedeemed_id] = useState('')
    const [redeemed_points, setRedeemed_points] = useState('')
    const [loyaltyDiscountAmount, setLoyaltyDiscountAmount] = useState('')
    const [tip_sum, setTip_sum] = useState(0)
    const [membershipAvailable, setMembershipAvailable] = useState(false)
    const [voucherAvailable, setVoucherAvailable] = useState(false)
    const [loyalityAvailable, setLoyalityAvailable] = useState(false)
    const [Employees, setEmployees] = useState([])
    const [discountValue, setDiscountValue] = useState(null)
    const [couponDiscountValue, setCouponDiscountValue] = useState(null)
    const [appointmentStatus, setAppointmentStatus] = useState("")
    const [completeAppointmentData, setCompleteAppointmentData] = useState({})
    const [loader, setLoader] = useState(true)
    const [couponCode, setCouponCode] = useState("")
    const [couponDetail, setCouponDetail] = useState(undefined)
    const [totalPriceWithoutTax, setTotalPriceWithoutTax] = useState(0)
    const [getCouponLoader, setGetCouponLoader] = useState(false)
    const [newAddedItmFromCoupon, setNewAddedItmFromCoupon] = useState({})
    const [couponPopup, setCouponPopup] = useState(false);
    const [loyalityPointsPopup, setLoyalityPointsPopup] = useState(false);
    const [voucherPopup, setVoucherPopup] = useState(false);
    const [giftCardPopup, setGiftCardPopup] = useState(false);
    const [membershipPopup, setMembershipPopup] = useState(false);
    const [totalAmountPaid, setTotalAmountPaid] = useState(0)
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])
    const [paymentError, setPaymentError] = useState({ id: '', message: '' })
    const [remainingPrice, setRemainingPrice] = useState(0)
    const [payment_price, setPaymentPrice] = useState(0)
    const [taxApplied, setLocationTax] = useState(0)
    const [amountToPay, setAmountToPay] = useState(0)
    const [giftCardDetail, setGiftCardDetail] = useState([])
    const [checkoutGiftCard, setCheckoutGiftCard] = useState([])
    const [giftCardCode, setGiftCardCode] = useState("")
    const [giftCardLoader, setGiftCardLoader] = useState(false)
    const [giftCard_amount, setGiftCard_amount] = useState(0)
    const [giftCardError, setGiftCardError] = useState()
    const [payBtnDisabled, setPayBtnDisabled] = useState(false)
    const [loyaltyLoader, setLoyaltyLoader] = useState(false)
    const [membershipLoader, setMembershipLoader] = useState(false)
    const [voucherLoader, setVoucherLoader] = useState(false)
    const [getGiftCardLoader, setGetGiftCardLoader] = useState(false)
    const [totalTips, setTotalTips] = useState(0)

    const points_for_every_amount = (loyalityPoints?.for_every_points / loyalityPoints?.customer_will_get_amount)

    const points_to_redeem = points_for_every_amount * t_price

    const amount_for_points =
        t_price < loyalityPoints?.total_amount
            ? (points_for_every_amount * t_price) / points_for_every_amount
            : loyalityPoints?.total_available_points / points_for_every_amount < loyalityPoints?.total_amount
                ? loyalityPoints?.total_available_points / points_for_every_amount
                : loyalityPoints?.total_available_points / points_for_every_amount


    const cur_currency = state?.locations?.locations?.filter(itm => itm?.id === selected_location)?.map(itm => itm?.currency?.code)

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    const otherOptions = useMemo(() =>
        [
            {
                method_type: "Coupon",
                label: selectedLanguage?.name == "English" ? "Coupon" : translation[1566]?.value ? translation[1566]?.value : "Coupon"
            },
            {
                method_type: "LoyalityPoints",
                label: selectedLanguage?.name == "English" ? "LoyalityPoints" : translation[197]?.value ? translation[197]?.value : "LoyalityPoints"
            },
            {
                method_type: "Voucher",
                label: selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"
            },
            {
                method_type: "Membership",
                label: selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership"
            },
            {
                method_type: "GiftCard",
                label: selectedLanguage?.name == "English" ? "Gift Card" : translation[1540]?.value ? translation[1540]?.value : "Gift Card"
            },
        ], [selectedLanguage])

    useEffect(() => {
        if (businessData.id) {
            fetchPaymentMethods(businessData.id);
        }
    }, [businessData.id])

    useEffect(() => {
        if (redeemed_points && redeemed_id && appointmentData?.other_method == "LoyalityPoints") {
            let remaining_available_points;
            let single_amount_for_every_point = loyalityPoints?.customer_will_get_amount / loyalityPoints?.for_every_points
            let remaining_value;
            remaining_available_points = loyalityPoints?.total_available_points - redeemed_points
            remaining_value = single_amount_for_every_point * remaining_available_points
            setLoyalityPoints({
                ...loyalityPoints,
                total_available_points: remaining_available_points,
                total_amount: remaining_value
            })
        }
        else {
            setLoyalityPoints(existingLoyalityPoints)
        }

    }, [redeemed_points, redeemed_id])

    useEffect(() => {
        if (completeAppointmentData?.client) {
            setAppointmentData((prev) => ({
                ...prev,
                client: completeAppointmentData?.client,
                client_id: completeAppointmentData?.client
            }));
        }
    }, [completeAppointmentData?.client]);


    useEffect(() => {
        // setting coupon data in OrderData
        if (couponDetail != {} && couponDetail != undefined) {
            let dataToUpdate = {}
            let finaldata = []
            if (couponDetail?.coupon_type == 'refund') {
                dataToUpdate = {
                    refund_coupon: { coupon_discounted_price: +couponDetail?.amount, refund_coupon: couponDetail?.id, client: couponDetail?.client }
                }
            } else if (couponDetail?.coupon_type == 'Cashback') {
                dataToUpdate = {
                    cashback_coupon: { coupon_discounted_price: +couponDetail?.dicounted_amount, cashback_coupon: couponDetail?.id, client: couponDetail?.client }
                }
            } else {
                // apply coupon according to type 1
                if (couponDetail?.coupon_type_value == 1) {
                    // queries to apply coupon on services
                    const filteredServices = appointmentServices?.filter(itm => itm?.status != "Void")
                    const servicesWithUpdatedPrices = filteredServices?.map((srv) => {
                        let data = { ...srv }
                        if (srv?.avaliable_service_group && srv?.avaliable_service_group?.length > 0) {
                            const isServiceExcluded = couponDetail?.excluded_services?.includes(srv?.service);
                            const filteredServiceGroupDiscount = couponDetail?.servicegroup_discount?.find((itm) =>
                                srv?.avaliable_service_group[0]?.id == itm?.service_group[0]?.service_group && !isServiceExcluded)?.service_group[0]?.service_group_discount
                            let updatedDiscountPrice = srv?.discount_price ? srv?.discount_price : srv?.price
                            if (filteredServiceGroupDiscount) {
                                updatedDiscountPrice = (+updatedDiscountPrice * +filteredServiceGroupDiscount / 100)
                                data = { ...data, discount_price: updatedDiscountPrice, discount_percentage: filteredServiceGroupDiscount }
                            }
                        }
                        return data
                    })
                    finaldata = [...servicesWithUpdatedPrices]
                    finaldata = finaldata?.filter(itm => itm?.discount_price !== undefined && itm?.discount_price)
                    dataToUpdate = { couponsIds: finaldata }
                } else if (couponDetail?.coupon_type_value == 2) {
                    let addToCartAllow = false
                    let filteredItm = {}
                    // apply coupon according to type 2
                    if (couponDetail?.buy_one_type == 'Service') {
                        // queries to apply coupon on services
                        const filteredServices = appointmentServices?.filter(itm => itm?.status != "Void")
                        const servicesWithUpdatedPrices = filteredServices?.map((srv) => {
                            let data = { ...srv }
                            const isServiceIncluded = couponDetail?.buy_one_get_one_service?.includes(srv?.service);
                            if (isServiceIncluded) {
                                addToCartAllow = true
                                filteredItm = { ...srv, is_coupon_redeemed: true, selection_type: 'SERVICE' }
                                let updatedDiscountPrice = srv?.discount_price ? srv?.discount_price : srv?.price
                                data = { ...data, discount_price: updatedDiscountPrice, discount_percentage: 100 }
                            }
                            return data
                        })
                        finaldata = [...servicesWithUpdatedPrices]
                    }
                    finaldata = finaldata?.filter(itm => itm?.discount_price !== undefined && itm?.discount_price)
                    dataToUpdate = { couponsIds: finaldata }
                    if (addToCartAllow) {
                        setNewAddedItmFromCoupon(filteredItm)
                    }
                } else {
                    dataToUpdate = {
                        couponSpendAmount: couponDetail?.amount_spent, couponDiscountPercentage: couponDetail?.discounted_percentage
                    }
                }
            }
            // setting final data of coupon integration in OrderData Object
            let finalDataAfterCouponApply = {}
            if (dataToUpdate != {}) {
                finalDataAfterCouponApply = { ...dataToUpdate }
            } else {
                finalDataAfterCouponApply = {}
            }
            // deleteing redeemed voucher,membership and loyality points
            const updatedOrderData = { ...appointmentData };
            delete updatedOrderData.is_redeemed;
            delete updatedOrderData.redeemed_id;
            delete updatedOrderData.redeemed_points;
            delete updatedOrderData.is_membership_redeemed;
            delete updatedOrderData.redeemed_membership_id;
            delete updatedOrderData.membership_product;
            delete updatedOrderData.membership_service;
            delete updatedOrderData.membership_type;
            delete updatedOrderData.is_voucher_redeemed;
            delete updatedOrderData.redeemed_voucher_id;
            delete updatedOrderData.voucher_redeem;
            delete updatedOrderData.voucher_type
            setAppointmentData({
                ...updatedOrderData,
                ...finalDataAfterCouponApply,
                other_method: 'Coupon',
                redeemed_coupon_id: couponDetail?.id,
                coupon_name: couponDetail?.name,
                coupon_type: couponDetail?.coupon_type,
                coupon_type_value: couponDetail?.coupon_type_value,
                is_coupon_redeemed: true
            })
        }
    }, [couponDetail])

    useEffect(() => {
        // check that checkout membership is available or not 
        if (checkoutMemberships && checkoutMemberships?.length > 0 && appointmentData?.client_id != null) {
            setMembershipAvailable(true);
        }
    }, [checkoutMemberships, appointmentData, checkoutMemberships?.length])

    useEffect(() => {
        // check that checkout voucher is available or not
        if (checkoutVouchers && checkoutVouchers?.length > 0 && appointmentData?.client_id != null) {
            // If the condition is met, set 'voucherAvailable' to true.
            setVoucherAvailable(true);
        }
    }, [checkoutVouchers, voucherNotFound, checkoutVouchers?.length])

    useEffect(() => {
        // check that loyality points are available or not 
        if (!notFound && loyalityPoints && appointmentData?.client_id != null) {
            setLoyalityAvailable(true)
        }
    }, [loyalityPoints])

    useEffect(() => {
        dispatch(getEmployeeList())
    }, [])

    useEffect(() => {
        dispatch(getAppointmentServices(
            app_group_id,
            (response) => {
                setCompleteAppointmentData(response?.appointment)
                setAppointmentStatus(response?.appointment?.status)
                setLoader(false)
            },
            () => {
                toast.error(apiErrorMessage, { toastId: "toast" })
            },

        ));
    }, [])

    useEffect(() => {
        if (completeAppointmentData != {} && completeAppointmentData?.client) {
            getLoyaltyPoints(selected_location, completeAppointmentData?.client)
            getCheckoutVouchers(selected_location, completeAppointmentData?.client)
            getCheckoutMembership(selected_location, completeAppointmentData?.client)
            getCheckoutGiftCard()
        }
    }, [completeAppointmentData?.client])


    useEffect(() => {
        setAllTips(APPOINTMENT_TIPS)
    }, [APPOINTMENT_TIPS])

    useEffect(() => {
        let new_empls = state.employee.employees?.filter((emp) => emp?.is_active === true)
        new_empls = new_empls.filter(empl => {
            let active_locs = empl.location.filter(loc => loc.id == state.locations.selected_location)
            if (active_locs.length > 0) {
                return true
            }
        })
        // let filteredEmployeesForTip = new_empls?.filter(itm => data?.ids?.find(emp => emp?.employee_id == itm?.id))
        setEmployees(new_empls)
    }, [state.locations.selected_location, state.employee.employees])

    useEffect(() => {
        if (appointmentData?.payment_method == '' && reduxPaymentMethodList?.length) {
            setAppointmentData({
                ...appointmentData,
                payment_method: reduxPaymentMethodList?.length > 0 ? reduxPaymentMethodList[0]?.method_type : ''
            })
        }
    }, [reduxPaymentMethodList?.length, appointmentData, reduxPaymentMethodList, appointmentData?.payment_method])

    useEffect(() => {

        const totalAmount = selectedPaymentMethods?.reduce((sum, item) => sum + item.amount, 0)
        let gc_price = appointmentData?.is_gift_card_redeemed ? (appointmentData?.appliedGiftCard?.partial_price)?.toFixed(2) : 0?.toFixed(2)
        setPaymentPrice(+((+t_price + (taxApplied ? +taxApplied : 0))?.toFixed(2) - (+totalAmount + +gc_price)?.toFixed(2)))
        setAmountToPay(+((+t_price + (taxApplied ? +taxApplied : 0))?.toFixed(2) - totalAmount?.toFixed(2)))
        setTotalAmountPaid((+totalAmount + + gc_price)?.toFixed(2))
    }, [selectedPaymentMethods, appointmentData?.appliedGiftCard, taxApplied, t_price, appointmentData?.is_gift_card_redeemed])

    useEffect(() => {
        if (Object.keys(newAddedItmFromCoupon).length !== 0) {
            const filteredServicesLength = appointmentServices?.filter(itm => itm?.service == newAddedItmFromCoupon?.service)?.length
            // Create a new object without "discount_percentage" and "redeemed_price"
            const updatedObject = { ...newAddedItmFromCoupon };
            delete updatedObject.discount_percentage;
            delete updatedObject.redeemed_price;
            let updatedData = {
                ...updatedObject,
                is_coupon_redeemed: true,
                selection_type: newAddedItmFromCoupon?.selection_type
            }
            // Repeat the addition of updatedData based on filteredServicesLength
            const newDataArray = Array.from({ length: filteredServicesLength }, () => updatedData);
            setAppointmentService([...appointmentServices, ...newDataArray])
        }
    }, [newAddedItmFromCoupon])

    //get payment methods
    const fetchPaymentMethods = async (business_id) => {
        const response = await getPaymentMethods(business_id);
        if (response.status === 200) {
            let paymentMethods = response?.data?.response?.payment_methods;
            dispatch(getPaymentMethodList(paymentMethods));
        }
    };

    // get client loyalty points
    const getLoyaltyPoints = (location_id, client_id) => {
        setLoyaltyLoader(true)
        if (location_id && client_id) {
            dispatch(getClientLoyaltyPoints(
                { location_id: location_id, client_id: client_id },
                (result) => {
                    setLoyaltyLoader(false)
                    setLoyalityPoints(result?.client_loyalty_points)
                    setExistingLoyalityPoints(result?.client_loyalty_points)
                },
                (err) => {
                    setLoyaltyLoader(false)
                    setNotFound(true)
                }
            ))
        }
    }

    // get client voucher
    const getCheckoutVouchers = (location_id, client_id) => {
        setVoucherLoader(true)
        if (location_id && client_id) {
            dispatch(getClientCheckoutVouchers(
                { location_id: location_id, client_id: client_id },
                (result) => {
                    setVoucherLoader(false)
                    const filteredVouchers = result?.client_vouchers?.filter(vchr => vchr?.voucher?.voucher_type == "Service")
                    setCheckoutVouchers(filteredVouchers)
                },
                (err) => {
                    setVoucherLoader(false)
                    setVoucherNotFound(true)
                }
            ))
        }
    }

    // get client membership
    const getCheckoutMembership = (location_id, client_id) => {
        setMembershipLoader(true)
        if (location_id && client_id) {
            dispatch(getClientCheckoutMemberships(
                { location_id: location_id, client_id: client_id },
                (result) => {
                    setMembershipLoader(false)
                    setCheckoutMembership(result?.client_memberships)
                },
                (err) => {
                    setMembershipLoader(false)
                    setMembershipNotFound(true)
                }
            ))
        }
    }

    // get coupon data
    const checkCouponCode = () => {
        setGetCouponLoader(true)
        setCouponPopup(!couponPopup)
        const clientType = completeAppointmentData?.client_type == 'IN HOUSE' ? 'In_Saloon' : 'Walk_in'
        dispatch(getSingleCouponOnCheckout(
            { coupon_code: couponCode, client_type: clientType, client_id: completeAppointmentData?.client ? completeAppointmentData?.client : null, totalPriceWithoutTax: +totalPriceWithoutTax, location_id: selected_location },
            (result) => {
                setSelectedPaymentMethods([])
                setAppointmentData({
                    ...appointmentData,
                    is_gift_card_redeemed: false,
                    appliedGiftCard: {
                        purchased_gift_card_id: '',
                        partial_price: 0
                    }
                })

                const updatedOrderData = { ...appointmentData };
                const filteredItemsforOrderIds = appointmentServices?.filter((item) => !item.is_coupon_redeemed)
                setAppointmentService(filteredItemsforOrderIds)
                delete updatedOrderData.other_method;
                delete updatedOrderData.coupon_name;
                delete updatedOrderData.coupon_type;
                delete updatedOrderData.coupon_type_value;
                delete updatedOrderData.couponsIds;
                delete updatedOrderData.couponDiscountPercentage;
                delete updatedOrderData.couponSpendAmount;
                delete updatedOrderData.is_coupon_redeemed;
                delete updatedOrderData?.redeemed_coupon_id
                delete updatedOrderData?.refund_coupon
                setAppointmentData(updatedOrderData);
                setSelectedPaymentMethods([])

                toast.success(result.message)
                setCouponDetail(result?.coupon)
                setCouponCode('')
                setGetCouponLoader(false)
            },
            (err) => {
                toast.error(err.response.data.response.message)
                setGetCouponLoader(false)
            }
        ))
    }

    // get Gift Cards WRT client
    const getCheckoutGiftCard = (code) => {
        setGetGiftCardLoader(true)
        let payload = {
            location_id: selected_location,
            client_id: code ? "" : completeAppointmentData?.client && completeAppointmentData?.client,
            code: code ? code : "",
        }

        dispatch(getClientCheckoutGiftCard(
            payload,
            (result) => {
                setGetGiftCardLoader(false)
                setGiftCardLoader(false)
                if (code) {
                    if (result?.status == 404) {
                        toast.error(result?.message)
                    } else {
                        setGiftCardPopup(true)
                        toast.success(result?.message)
                        setGiftCardDetail(result?.client_gift_cards)
                    }
                } else {
                    setCheckoutGiftCard(result?.client_gift_cards)
                }
            },
            (err) => {
                setGiftCardLoader(false)
                setGetGiftCardLoader(false)
            }
        ))
    }

    // check gift card
    const checkGiftCardCode = () => {
        setGiftCardLoader(true)
        setAppointmentData({
            ...appointmentData,
            is_gift_card_redeemed: false,
            appliedGiftCard: {
                purchased_gift_card_id: '',
                partial_price: 0
            }
        })
        setSelectedPaymentMethods([])
        setGiftCardDetail([])
        setGiftCard_amount(amountToPay.toFixed(2))
        getCheckoutGiftCard(giftCardCode)
        setGiftCardPopup(!giftCardPopup)
    }

    // handle tip delete
    const handle_tip_delete = (data) => {
        let is_tip = tip_for_emply.filter(itm => itm.employee !== data.emp_id)
        let is_employee = employee_tips.filter(itm => itm.emp_id !== data.emp_id)
        const sum = is_tip.reduce((accumulator, object) => {
            return accumulator + object.tip;
        }, 0);

        setTip_sum(sum)
        setEmployeeTipSum(sum)
        setTip_for_emply([...is_tip])
        setEmployee_tips([...is_employee])
        setEmployeeTips([...is_employee])

        // Remove the employee's tip from currentEmployeeTip
        const employeeId = data.emp_id;
        const updatedEmployeeTip = { ...currentEmployeeTip };
        delete updatedEmployeeTip[employeeId];
        setCurrentEmployeeTip(updatedEmployeeTip);
    }

    // handle tip change
    const handleTipChange = (emp_id, emp_name, newTip) => {
        let prev_tips = [...tip_for_emply]
        let prev_employees = [...employee_tips]
        let is_tip = prev_tips?.find(itm => itm?.employee === emp_id)
        let is_employee = prev_employees?.find(itm => itm?.emp_id === emp_id)

        // if (newTip != 0) {
        if (is_employee) {
            // is_employee.tip = newTip
            prev_employees = prev_employees?.map(itm => {
                if (itm?.emp_id === emp_id) {
                    return { ...itm, tip: newTip };
                } else {
                    return itm
                }
            });
        } else {
            prev_employees?.push({ emp_id: emp_id, employee: emp_name, tip: newTip })
        }

        if (is_tip) {
            is_tip.tip = newTip
        } else {
            prev_tips?.push({ employee: emp_id, tip: newTip })
        }
        // }

        const updatedPreviousTips = prev_tips?.filter(itm => itm?.tip != 0)
        const updatedPreviousEmployees = prev_employees?.filter(itm => itm?.tip != 0)

        setTip_for_emply(updatedPreviousTips)
        setEmployee_tips(updatedPreviousEmployees)

        const sum = updatedPreviousTips.reduce((accumulator, object) => {
            return accumulator + object?.tip;
        }, 0);

        setTip_sum(sum)
    }

    // Split Payment, handlle payment change
    const handlePaymentMethodChange = (e, type) => {
        const { name, value } = e?.target
        let totalCartPrice = (+t_price + (taxApplied ? +taxApplied : 0))?.toFixed(2)
        let gc_price = appointmentData?.is_gift_card_redeemed ? (appointmentData?.appliedGiftCard?.partial_price)?.toFixed(2) : 0?.toFixed(2)

        if (appointmentData?.is_gift_card_redeemed > 0) {
            totalCartPrice = totalCartPrice - +gc_price
        }

        if (name === 'payment_method') {
            const updatedPaymentMethods = () => {
                if (value) {
                    if (selectedPaymentMethods?.some(itm => itm?.payment_method === value)) {
                        setPayBtnDisabled(false)
                        setPaymentError({ id: '', message: '' })
                        return selectedPaymentMethods?.filter(itm => itm?.payment_method !== value)
                    } else {
                        if (payment_price > 0) {
                            setPaymentError({ id: '', message: '' })
                            setPayBtnDisabled(false)
                            return ([...selectedPaymentMethods || [], {
                                payment_method: value, amount: +payment_price?.toFixed(2)
                            }])
                        } else {
                            setPaymentError((prev) => ({
                                ...prev,
                                id: value,
                                message: `payment already paid`
                            }));
                            return selectedPaymentMethods
                        }
                    }
                } else {
                    setPaymentError({ id: '', message: '' })
                    return selectedPaymentMethods
                }
            }
            let updatedValue = updatedPaymentMethods()
            const totalAddAmount = updatedValue?.reduce((sum, item) => sum + item.amount, 0);
            if (totalCartPrice >= totalAddAmount) {
                setRemainingPrice((totalCartPrice - totalAddAmount)?.toFixed(2))
            } else {
                setRemainingPrice(0)
            }
            setSelectedPaymentMethods(updatedValue);
        }
        if (name === 'amount') {
            if (selectedPaymentMethods?.find(itm => itm?.payment_method == type)) {
                const updatedPaymentMethods = selectedPaymentMethods.map(itm => {
                    if (itm?.payment_method === type) {
                        const updatedArray = selectedPaymentMethods?.map((itm) => {
                            if (type === itm?.payment_method) {
                                return ({
                                    ...itm,
                                    amount: +value
                                })
                            } else {
                                return itm
                            }
                        });
                        const totalRemaining = updatedArray?.reduce((sum, item) => sum + item.amount, 0);
                        let remaing = +(totalCartPrice - totalRemaining)?.toFixed(2)

                        if (itm?.payment_method === 'Cash' && selectedPaymentMethods?.length <= 1) {
                            setPayBtnDisabled(false)
                            return { ...itm, amount: +value };
                        } else {
                            if (+value <= remaing) {
                                setPayBtnDisabled(false)
                                setPaymentError((prev) => ({ ...prev, id: itm?.payment_method, message: `` }))
                                return { ...itm, amount: +value };
                            } else {
                                if (totalRemaining <= totalCartPrice) {
                                    setPayBtnDisabled(false)

                                    setPaymentError((prev) => ({ ...prev, id: itm?.payment_method, message: `` }))
                                    return { ...itm, amount: +value };
                                } else {
                                    setPayBtnDisabled(true)
                                    setPaymentError((prev) => ({
                                        ...prev,
                                        id: itm?.payment_method,
                                        message: `Payment amount can't be more than the its actual price`
                                    }));
                                    return { ...itm, amount: +value };
                                }
                            }
                        }
                    } else {
                        return itm;
                    }
                });
                const totalAddAmount = updatedPaymentMethods?.reduce((sum, item) => sum + item.amount, 0);
                if (totalCartPrice >= totalAddAmount) {
                    setRemainingPrice((totalCartPrice - totalAddAmount)?.toFixed(2))
                } else {
                    setRemainingPrice(0)
                }
                setSelectedPaymentMethods(updatedPaymentMethods);
            }
        }
    };

    // checkout submit function
    const handle_submit = (data) => {
        let total_price = 0;
        const filteredAppointmentServicesArray = appointmentServices?.filter(itm => itm?.status != "Void")

        // total price of all services
        total_price = filteredAppointmentServicesArray?.reduce((accumulator, curValue) => {
            return accumulator + curValue.price
        }, 0)
        if (appointmentData?.voucher_redeem) {
            total_price -= (appointmentData?.voucher_redeem * total_price) / 100
        }
        if (appointmentData?.membership_service?.length > 0 && appointmentData?.membership_type === "Free") {
            const filteredServices = filteredAppointmentServicesArray?.filter(itm =>
                appointmentData?.membership_service?.some(mmbr_serv => mmbr_serv?.service == itm?.service)
            );
            const sumOfFreeServicesPrices = filteredServices?.map(srv => srv?.price)?.reduce((total, percentage) => total + percentage, 0);
            if (sumOfFreeServicesPrices) {
                total_price -= sumOfFreeServicesPrices;
            }
        }
        if (appointmentData?.membership_service?.length > 0 && appointmentData?.membership_type == "Limited") {
            const filteredLimitedServicesPrices = appointmentData?.membership_service?.map(srv => ((filteredAppointmentServicesArray?.find(itm => itm?.service == srv?.service)?.price * srv?.percentage) / 100))
            const sumOfLimitedServicesPrices = filteredLimitedServicesPrices?.reduce((total, percentage) => total + percentage, 0);
            if (sumOfLimitedServicesPrices) {
                total_price -= sumOfLimitedServicesPrices
            }
        }

        let appliedVoucher = [];
        let appliedMembership = [];
        let appliedCoupon = []
        let couponPrice = 0;

        // updating previous data of appointment services
        const previousAppointmentServices = filteredAppointmentServicesArray?.map((item) => {
            const newItem = { ...item };
            if (!newItem.discount_price) {
                delete newItem.discount_price
            }
            if (appointmentData?.voucher_redeem) {
                const redeemedPrice = (+newItem?.price - ((appointmentData?.voucher_redeem * newItem?.price) / 100))
                appliedVoucher?.push({
                    client: appointmentData?.client,
                    voucher: appointmentData?.redeemed_voucher_id,
                    price: redeemedPrice,
                    is_voucher_redeemed: true,
                    discount_percentage: appointmentData?.voucher_redeem,
                })
                newItem.price = +redeemedPrice?.toFixed(2)
                newItem.discount_percentage = appointmentData?.voucher_redeem
            }

            if (appointmentData?.membership_service?.length > 0 && appointmentData?.membership_type == "Free") {
                const matchedService = appointmentData?.membership_service?.find(itm => itm?.service == newItem?.service)
                if (matchedService) {
                    appliedMembership?.push({
                        client: appointmentData?.client,
                        membership: appointmentData?.redeemed_membership_id,
                        price: +newItem?.price,
                        is_membership_redeemed: true,
                        discount_percentage: 100,
                    })
                    newItem.price = newItem?.price - newItem?.price
                    newItem.discount_percentage = 100
                }
            }

            if (appointmentData?.membership_service?.length > 0 && appointmentData?.membership_type == "Limited") {
                const serviceLimited = appointmentData?.membership_service?.find(itm => itm?.service == newItem?.service)
                if (serviceLimited) {
                    const redeemedPrice = (+newItem?.price - (+serviceLimited?.percentage * +newItem?.price) / 100)
                    newItem.price = +redeemedPrice?.toFixed(2)
                    newItem.discount_percentage = serviceLimited?.percentage
                    appliedMembership?.push({
                        client: appointmentData?.client,
                        membership: appointmentData?.redeemed_membership_id,
                        price: +redeemedPrice?.toFixed(2),
                        is_membership_redeemed: true,
                        discount_percentage: +serviceLimited?.percentage,
                    })
                }
            }

            if (appointmentData?.is_coupon_redeemed === true) {
                if (appointmentData?.coupon_type == 'refund') {
                    couponPrice = appointmentData?.refund_coupon?.coupon_discounted_price
                } else if (appointmentData?.coupon_type == 'Cashback') {
                    couponPrice = appointmentData?.cashback_coupon?.coupon_discounted_price
                } else {
                    if (appointmentData?.coupon_type_value == 1 || appointmentData?.coupon_type_value == 2) {
                        let matchingId = appointmentData?.couponsIds?.length > 0 ? appointmentData?.couponsIds?.find(itm => itm?.id == newItem?.id && !newItem.is_coupon_redeemed) : ''
                        // let matchingId = appointmentData?.couponsIds?.length > 0 ? appointmentData?.couponsIds?.find(itm => itm?.id == newItem?.id && !newItem?.is_coupon_redeemed) : ''
                        if (matchingId) {
                            const priceOfItem = newItem?.discount_price || newItem?.discount_price == 0 ? newItem?.discount_price : newItem?.price
                            couponPrice = matchingId?.discount_price
                            newItem.price = priceOfItem - matchingId?.discount_price
                            newItem.discount_percentage = matchingId?.discount_percentage
                        }
                    } else {
                        if (total_price >= appointmentData?.couponSpendAmount) {
                            couponPrice = +total_price * +appointmentData?.couponDiscountPercentage / 100
                        }
                    }
                }
            }

            // remove values after deleting redeemed voucher
            if (!appointmentData?.redeemed_voucher_id && newItem?.is_voucher_redeemed) {
                appliedVoucher = []
            }

            // remove values after deleting redeemed membership
            if (!appointmentData?.redeemed_membership_id && newItem?.is_membership_redeemed) {
                appliedMembership = []
            }

            // remove values after deleting redeemed loyality points
            if (!appointmentData?.redeemed_id && newItem?.is_redeemed) {
                delete newItem?.is_redeemed;
                delete newItem?.redeemed_id;
                delete newItem?.redeemed_points
            }

            // remove values after deleting redeemed Coupon
            if (!appointmentData?.is_coupon_redeemed && newItem?.is_coupon_redeemed) {
                appliedCoupon = []
            }
            if (newItem.hasOwnProperty('member')) {
                newItem.employee = newItem.member;
                delete newItem.member;
            }
            if (newItem.hasOwnProperty('status')) {
                if (newItem?.status == 'Finished') {
                    newItem.service_status = "completed"
                } else {
                    newItem.service_status = newItem?.status
                }
                delete newItem.status;
            }
            return newItem;
        })

        // finilizing applied coupon data
        appliedCoupon = appointmentData?.is_coupon_redeemed
            ? [{
                client: appointmentData?.client,
                coupon: (appointmentData?.refund_coupon?.refund_coupon || appointmentData?.cashback_coupon?.cashback_coupon) ? null :
                    appointmentData?.redeemed_coupon_id,
                refund_coupon: appointmentData?.refund_coupon?.refund_coupon ? appointmentData?.refund_coupon?.refund_coupon : null,
                cash_back_coupons: appointmentData?.cashback_coupon?.cashback_coupon ? appointmentData?.cashback_coupon?.cashback_coupon : null,
                coupon_name: appointmentData?.refund_coupon?.refund_coupon ? "Refund Coupon" :
                    appointmentData?.cashback_coupon?.cashback_coupon ? "Cashback Coupon" : couponDetail?.name,
                coupon_type: appointmentData?.coupon_type,
                coupon_discounted_price: (+couponPrice)?.toFixed(2),
                is_coupon_redeemed: appointmentData?.is_coupon_redeemed,
            }] : []
        // Loop through each object and delete 'coupon' key if its value is null
        appliedCoupon?.forEach(obj => {
            if (obj?.coupon === null) {
                delete obj.coupon;
            }
            if (obj?.refund_coupon === null) {
                delete obj.refund_coupon;
            }
            if (obj?.cash_back_coupons === null) {
                delete obj.cash_back_coupons;
            }
        });

        // finilizing applied loyalty data
        const loyality_redemption = is_redeemed
            ? [{
                client: appointmentData?.client,
                is_redeemed: is_redeemed,
                client_loyalty_point: redeemed_id,
                redeemed_points: redeemed_points,
                redeemed_amount: loyaltyDiscountAmount
            }]
            : []
        // updating total_price whenever loyalty points redeemed
        if (is_redeemed) {
            total_price = total_price - (loyaltyDiscountAmount ? +loyaltyDiscountAmount : 0)?.toFixed(2)
        }

        // finilizing applied promotions data
        const applied_promotions_records = appointmentData?.selected_promotion_id
            ? [{
                promotion: appointmentData?.selected_promotion_id,
                promotion_type: appointmentData?.selected_promotion_type
            }]
            : []

        // finilizing membership data
        const applied_membership_records = appliedMembership?.length > 0 ? appliedMembership?.reduce((acc, obj) => {
            const existingObj = acc?.find(item => item.client === obj.client);
            if (existingObj) {
                existingObj.price += obj.price;
            } else {
                acc.push(obj);
            }
            return acc;
        }, []) : []

        // finilizing voucher data
        const applied_voucher_records = appliedVoucher?.length > 0 ? appliedVoucher?.reduce((acc, obj) => {
            const existingObj = acc?.find(item => item.client === obj.client);
            if (existingObj) {
                existingObj.price += obj.price;
            } else {
                acc.push(obj);
            }
            return acc;
        }, []) : []


        // client type
        const client_type = completeAppointmentData?.client_type == 'IN HOUSE' ? 'In_Saloon' : 'Walk_in'

        // updated appointment services data
        const updatedAppointmentServices = [];
        previousAppointmentServices?.map(srv => {
            const mergedService = {
                client: appointmentData?.client,
                appointment: app_group_id,
                service: srv?.service,
                group: null,
                employee: srv?.employee,
                discount_percentage: srv?.discount_percentage ? srv?.discount_percentage : null,
                price: srv?.discount_price != 0 ? srv?.discount_price ? srv?.discount_price : srv?.price : 0,
                service_start_time: srv?.service_start_time,
                service_end_time: srv?.service_end_time
            }
            updatedAppointmentServices?.push(mergedService);
        });

        // total tip calculation
        const tips_sum = employeeTips?.reduce((accumulator, object) => {
            return accumulator + object.tip;
        }, 0);

        // remaining price calculation
        let remaining_price = +(+total_price + (taxApplied ? +taxApplied?.toFixed(2) : 0) - (totalAmountPaid ? +totalAmountPaid : 0)?.toFixed(2)).toFixed(2)

        // applied tax 
        const applied_tax = data?.tax_type ? data?.tax_type == "Group" ? data?.taxes_applied_group?.length > 0 ? data?.taxes_applied_group : [] : data?.single_tax_applied : []

        // total cart price calculation
        const total_cart_price = (+total_price?.toFixed(2) + (data?.taxes_applied_single_value ? +data?.taxes_applied_single_value?.toFixed(2) : 0)) - (appointmentData?.is_gift_card_redeemed ? appointmentData?.appliedGiftCard?.partial_price : 0)

        // formatting payload data
        let format_data = {
            // business: state?.business?.business?.id,
            checkout_type: "Appointment",
            location: selected_location,
            tax_records: applied_tax,
            payment_methods_records: selectedPaymentMethods,
            products_records: [],
            services_records: [],
            membership_records: [],
            vouchers_records: [],
            gift_cards_records: [],
            installment_memberships: [],
            applied_promotions_records: applied_promotions_records,
            applied_loyalty_points_records: loyality_redemption,
            applied_gift_cards_records: appointmentData?.is_gift_card_redeemed ? [{
                ...appointmentData?.appliedGiftCard, client: appointmentData?.client,
            }] : [],
            applied_vouchers_records: applied_voucher_records,
            applied_memberships_records: applied_membership_records,
            applied_coupons_records: appliedCoupon,
            tip_records: employeeTips?.map(tip_item => ({ tip: tip_item?.tip, employee: tip_item.emp_id })),
            change: remaining_price < 0 ? Math.abs(remaining_price)?.toFixed(2) : 0.00,
            total_tip: tips_sum,
            total_price: +total_cart_price?.toFixed(2),
            total_tax: data?.taxes_applied_single_value ? +data?.taxes_applied_single_value?.toFixed(2) : 0,
            sub_total: subTotal,
            appointment_services: updatedAppointmentServices,
            client_type: client_type,
            client: appointmentData?.client,
            appointment: app_group_id,
            discount_value: +discountValue,
            appointment_status: "Done"
        }

        const success = (data) => {
            storeDataInLocalStorage('checkoutId', data?.id)
            dispatch(getAppointments())
            toast.success('Payment successfully completed', { toastId: "toast" })
            navigate('/dashboard/sales/sale-history')
            dispatch({
                type: CLIENT.SET_SPECIFIC_CLIENTS_FOR_CHECKOUT,
                payload: {}
            });
            setCheckoutLoader(false)
        }
        const fail = () => {
            toast.error(apiErrorMessage, { toastId: "toast" })
            setCheckoutLoader(false)
        }
        // console.log(format_data, appointmentServices, appointmentData, "format_data")
        setCheckoutLoader(true)
        dispatch(CreateNewCheckout(format_data, success, fail))
    }

    useEffect(() => {
        const tips_sum = employeeTips?.reduce((accumulator, object) => {
            return accumulator + object.tip;
        }, 0);
        setTotalTips(tips_sum)

    }, [employeeTips])


    return (
        <>
            <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
                <div className='flex flex-col gap-4'>
                    <div className="flex items-center gap-3">
                        <Svgs.Back link={`/dashboard/calendar/appointment-detail/${params?.appointment_service_id}/?parent_id=${app_group_id}`} />
                        <h2 className="text-primary font-bold lg:text-3xl text-xl">
                            {selectedLanguage?.name == "English" ? "Checkout" : translation[182]?.value ? translation[182]?.value : "Checkout"}
                        </h2>
                    </div>

                    <div className='grid lg:grid-cols-5 mt-[3rem] gap-[3rem]'>
                        <div className='flex flex-col gap-5 lg:col-span-3'>
                            <h2 className="text-primary font-bold text-xl">
                                {selectedLanguage?.name == "English" ? "Add Tip" : translation[187]?.value ? translation[187]?.value : "Add Tip"}
                            </h2>
                            <div className='grid grid-cols-1 md:grid-cols-2 items-center gap-4 cursor-pointer text-sm'>
                                <PaymentCheckboxCard
                                    checked={employeeTips?.length > 0}
                                    onClick={() => {
                                        setTipToStaff(true)
                                        setTip_for_emply([])
                                        setTip_sum(0)
                                    }}
                                    padding={'gap-4'}
                                    inputClass={'!opacity-100 !font-medium'}
                                    currency={cur_currency}
                                    inputValue={totalTips && totalTips?.toFixed(2)}
                                    inputField
                                    inputDisabled
                                    title={selectedLanguage?.name == "English" ? "Add Tip" : translation[187]?.value ? translation[187]?.value : "Add Tip"}
                                />
                            </div>
                            <h2 className="text-primary font-bold text-xl">
                                {selectedLanguage?.name == "English" ? "Payment Methods" : translation[190]?.value ? translation[190]?.value : "Payment Methods"} <span className="text-red-600">*</span></h2>
                            <div className='grid lg:grid-cols-1 gap-3'>
                                {reduxPaymentMethodList && reduxPaymentMethodList?.length > 0 ? (
                                    <div className='grid md:grid-cols-2 gap-4'>
                                        {reduxPaymentMethodList?.filter(method => method.is_active)?.map((item, index) => {
                                            return (
                                                <>
                                                    <PaymentCheckboxCard
                                                        key={index}
                                                        checked={selectedPaymentMethods?.find(itm => itm?.payment_method === item.method_type)}
                                                        // leftIcon={lft_icon?.icon ? lft_icon?.icon : <img className="h-[2rem] w-[2rem] object-contain" src={`${process.env.PUBLIC_URL}/images/payment_icon.jpeg`} />}
                                                        onChange={handlePaymentMethodChange}
                                                        inputField
                                                        onInputChange={(e) => {
                                                            handlePaymentMethodChange(e, item?.method_type)
                                                        }}
                                                        currency={cur_currency}
                                                        restrictedAmount={item?.method_type != 'Cash' ? payment_price : false}
                                                        inputValue={selectedPaymentMethods?.find(itm => itm?.payment_method === item.method_type)?.amount}
                                                        error={(paymentError?.id === item.method_type) && paymentError?.message}
                                                        totalPaymentMethods={payment_price}
                                                        title={
                                                            <div className='flex items-center gap-2'>
                                                                <p className='font-semibold text-sm'>{item.method_type}</p> {" "}
                                                            </div>
                                                        }
                                                        errorClass={"!static !pt-1"}
                                                        id={item.method_type}
                                                    />
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                )
                                    :
                                    <div className='grid md:grid-cols-2 gap-4'>
                                        {[0, 1, 2]?.map(itm => {
                                            return (
                                                <CheckBoxCardShimmer />
                                            )
                                        })}
                                    </div>
                                }
                            </div>


                            {/* {appointmentData?.client_id != null && 
                                <>
                                */}
                            <hr className='my-2' />
                            <div className='grid lg:grid-cols-1 gap-3'>
                                <h2 className="text-primary font-bold text-xl">
                                    {selectedLanguage?.name == "English" ? "Other Methods " : translation[192]?.value ? translation[192]?.value : "Other Methods "}
                                </h2>

                                <div className='grid md:grid-cols-2 gap-4'>
                                    {otherOptions?.length > 0 ? (
                                        otherOptions?.map((item, index) => {
                                            let lft_icon = OTHER_PAYMENT_METHODS?.find(
                                                (itm) => itm.name == item.method_type
                                            );
                                            return (
                                                ((item.method_type == "Membership" && membershipAvailable) ||
                                                    (item.method_type == "Voucher" && voucherAvailable) ||
                                                    (item.method_type == "LoyalityPoints" && loyalityAvailable)
                                                    || (item.method_type == "GiftCard")
                                                    || (item.method_type == "Coupon")) &&
                                                <>
                                                    <PaymentCheckboxCard
                                                        key={index}
                                                        leftIcon={lft_icon?.icon ? lft_icon?.icon : <img className="h-[1.25rem] w-[1.25rem] object-contain" src={`${process.env.PUBLIC_URL}/images/payment_icon.jpeg`} />}
                                                        checked={appointmentData.other_method === item.method_type}
                                                        onClick={() => {
                                                            setAppointmentData({ ...appointmentData, other_method: item.method_type });
                                                            if (item.method_type == 'Coupon') {
                                                                setCouponPopup(true);
                                                            }
                                                            if (item.method_type == 'GiftCard') {
                                                                setGiftCardPopup(true);
                                                            }
                                                            if (item.method_type == 'Membership') {
                                                                setMembershipPopup(true);
                                                            }
                                                            if (item.method_type == 'LoyalityPoints') {
                                                                setLoyalityPointsPopup(true)
                                                            }
                                                            if (item.method_type == 'Voucher') {
                                                                setVoucherPopup(true)
                                                            }
                                                        }}
                                                        id={item.method_type}
                                                        title={
                                                            <div className='flex items-center gap-2'>
                                                                <p className='font-semibold text-sm'>{item.label}</p>
                                                            </div>
                                                        }
                                                    />
                                                </>
                                            )
                                        }))
                                        :
                                        ""
                                    }
                                    {((membershipLoader || voucherLoader || loyaltyLoader || getGiftCardLoader) && completeAppointmentData?.client) && [0, 1, 2]?.map(itm => {
                                        return (
                                            <CheckBoxCardShimmer />
                                        )
                                    })}
                                </div>
                            </div>
                            {/* </> } */}

                            {/* Gift Cards For Client */}
                            {checkoutGiftCard?.length > 0 &&
                                <>
                                    <h2 className="text-primary font-bold text-xl mt-5">
                                        {selectedLanguage?.name == "English" ? "Gift Cards" : translation[1541]?.value ? translation[1541]?.value : "Gift Cards"}
                                    </h2>
                                    <div className='grid xs:grid-cols-2 grid-cols-1 gap-4'>
                                        {checkoutGiftCard?.map((itm, index) => {
                                            return <div
                                                key={index}
                                                onClick={() => {
                                                    setGiftCardPopup(!giftCardPopup)
                                                    setGiftCardDetail([itm])
                                                    setGiftCard_amount(amountToPay.toFixed(2))
                                                    setAppointmentData({ ...appointmentData, other_method: '' })
                                                }}
                                                className='flex cursor-pointer items-center rounded-md gap-2 bg-[#E6E9FF] p-2'>
                                                <div className="flex bg-white p-1 rounded-md">
                                                    <Svgs.Gift width={'2rem'} height={'2rem'} />
                                                </div>
                                                <div className="">
                                                    <p className="font-semibold text-sm">{cur_currency + " " + (+itm?.spend_amount?.toFixed(2))} Gift Card</p>
                                                    <p className=" text-xs text-[#000000]/40 ">{itm?.sale_code}</p>
                                                </div>
                                            </div>
                                        }
                                        )}
                                    </div>
                                </>
                            }
                        </div>
                        <div className='lg:col-span-2 flex flex-col gap-6'>
                            <BookingDetailCard
                                subTotal={subTotal}
                                setSubTotal={setSubTotal}
                                payNowBtnDisabled={false}
                                status={appointmentStatus}
                                appointmentData={appointmentData}
                                setData={setAppointmentData}
                                setTotalAmountPaid={setTotalAmountPaid}
                                is_gift_card_redeemed={appointmentData?.is_gift_card_redeemed}
                                appliedGiftCard={appointmentData?.appliedGiftCard}
                                totalAmountPaid={totalAmountPaid}
                                selectedPaymentMethods={selectedPaymentMethods}
                                setSelectedPaymentMethods={setSelectedPaymentMethods}
                                payBtnDisabled={payBtnDisabled}
                                isFromCheckout
                                loading={loader}
                                setAppliedTax={setLocationTax}
                                taxApplied={taxApplied}
                                setAppointmentService={setAppointmentService}
                                services={appointmentServices?.length > 0 ? appointmentServices?.filter(itm => itm?.status != "Void")?.map((itm) => {
                                    return {
                                        id: itm?.id,
                                        price: itm?.price,
                                        name: itm?.srv_name,
                                        serviceId: itm?.service,
                                        is_coupon_redeemed: itm?.is_coupon_redeemed
                                    }
                                }) : []}
                                appointmentServices={appointmentServices?.filter(itm => itm?.status != "Void")}
                                price={appointmentServices?.filter(itm => itm?.status != "Void")?.reduce(function (accumulator, curValue) {
                                    return accumulator + curValue.price
                                }, 0)}
                                location={appointmentData?.location}
                                payBtnText={'Checkout'}
                                tip={appointmentData?.selected_tip}
                                voucher_redeem={appointmentData?.voucher_redeem}
                                onPayClick={(data) => {
                                    handle_submit(data)
                                }}
                                onTipDelete={(data) => {
                                    handle_tip_delete(data)
                                }}
                                tips={employeeTips}
                                tip_sum={employeeTipSum}
                                tax={total_tax}
                                setT_price={setT_price}
                                membership_service={appointmentData?.membership_service}
                                membership_type={appointmentData?.membership_type}
                                is_redeemed={is_redeemed}
                                setIs_redeemed={setIs_redeemed}
                                redeemed_id={redeemed_id}
                                setRedeemed_id={setRedeemed_id}
                                redeemed_points={redeemed_points}
                                setRedeemed_points={setRedeemed_points}
                                loyaltyDiscountAmount={loyaltyDiscountAmount}
                                setLoyaltyDiscountAmount={setLoyaltyDiscountAmount}
                                setDiscountValue={setDiscountValue}
                                discountValue={discountValue}
                                checkoutLoader={checkoutLoader}
                                allowCheckout={true}
                                setTotalPriceWithoutTax={setTotalPriceWithoutTax}
                                couponsIds={appointmentData?.couponsIds}
                                setCouponDiscountValue={setCouponDiscountValue}
                                couponDiscountValue={couponDiscountValue}
                            />
                            <SupportCard />
                        </div>
                    </div>
                </div>
                <Popup heading={`Voucher Payment`} close={setVoucher} open={Voucher}>
                    <div className='flex flex-col gap-4'>
                        <div className='text-sm flex items-center gap-2 px-4 py-2 bg-[#F4F7FA] rounded-md'>
                            <Svgs.I />
                            <p className='text-[#555555]'>Total amount to pay is <span className='tetx-black'>RS. 50</span> </p>
                        </div>
                        <LoginInput title='Voucher Code' placeholder='Enter Voucher Code ' required={true} leftIcon={<Svgs.Search />} />
                    </div>
                    <div className='flex items-center justify-start mt-[2rem]'>
                        <LoginBtn>Pay Now</LoginBtn>
                    </div>
                </Popup>

                <Popup size='md' heading={`Split Tip `} close={setSplitTip} open={SplitTip}>
                    {/* <p className='text-primary text-sm'>Team Members</p> */}
                    <div className='flex flex-col gap-2'>
                        <div className='flex items-center gap-4'>
                            {/* <Dropwdown placeholder='member' showTitle={false} /> */}
                            <div className='flex items-center gap-3 border rounded-md p-3 flex-1'>
                                {/* <p className='text-xs text-[#A1A1A1]'>{state.business.business?.currency?.code ? state.business.business?.currency?.code : 'AED'}</p> */}
                                <p className='text-xs text-[#A1A1A1]'>{cur_currency}</p>
                                <input
                                    type="number"
                                    className='flex-1 outline-none border-none w-[3rem] text-sm'
                                    placeholder='12'
                                    max={100}
                                    maxLength={3}
                                    value={tip_inp}
                                    onChange={(e) => {
                                        if (e.target.value <= 100) {
                                            setTipInp(e.target.value)
                                        }
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                    <div className='flex items-center justify-start mt-[2rem]'>
                        <LoginBtn
                            onClick={() => {
                                setAllTips([
                                    ...all_tips,
                                    {
                                        tip: tip_inp
                                    }
                                ])
                                setSelectedPaymentMethods([])
                                setAppointmentData({
                                    ...appointmentData,
                                    selected_tip: tip_inp,
                                    appliedGiftCard: {
                                        purchased_gift_card_id: '',
                                        partial_price: 0
                                    },
                                })
                                setSplitTip(false)
                            }}
                        >
                            {selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}
                        </LoginBtn>
                    </div>
                </Popup>

                {TipToStaff &&
                    <Popup size='md'
                        heading={selectedLanguage?.name == "English" ? "Add a tip to the staff" : translation[186]?.value ? translation[186]?.value : "Add a tip to the staff"}
                        close={setTipToStaff}
                        open={TipToStaff}
                    >
                        <div className='flex gap-4 max-h-[25rem] overflow-y-auto'>
                            <div className="flex flex-col w-full ">
                                {Employees?.sort(itm => itm.level == "Above_Averge" ? -1 : 1)?.map(empl => {
                                    return (
                                        <div className="flex items-center gap-3 ">
                                            <div className='w-1/2 p-2'>
                                                {empl?.full_name}
                                            </div>
                                            <div className='flex w-1/2 items-center gap-2 border rounded-md flex-1 '>
                                                <div className='p-2 rounded-l-md text-xs text-[#A1A1A1] bg-slate-50 h-full flex items-center justify-center w-10 border-r-[1px]'>{cur_currency}</div>
                                                <input
                                                    type="number"
                                                    className='placeholder:text-[0.6rem] flex-1 outline-none border-none w-[3rem] text-sm'
                                                    placeholder={selectedLanguage?.name == "English" ? "Add tip" : translation[187]?.value ? translation[187]?.value : "Add tip"}
                                                    max={100}
                                                    maxLength={4}
                                                    onKeyDown={(e) => {
                                                        if (e.key === '-') {
                                                            e.preventDefault(); // Prevent typing of '-' symbol
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        const value = Math.abs(+e.target.value?.replace(/[^0-9.]/g, '')?.slice(0, 4))
                                                        const updatedTipValues = {
                                                            ...currentEmployeeTip,
                                                            [empl?.id]: value
                                                        };
                                                        setCurrentEmployeeTip(updatedTipValues);
                                                        handleTipChange(empl?.id, empl?.full_name, value)
                                                    }}
                                                    value={Math.abs(currentEmployeeTip[empl?.id]) || ''}
                                                />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className='flex items-center gap-5 justify-end mt-[2rem]'>
                            <LoginBtn
                                // disabled={amount_for_points < t_price}
                                className={"!px-6"}
                                // disabled={employee_tips?.length == 0}
                                onClick={() => {
                                    setTipToStaff(false)
                                    setEmployeeTips(employee_tips)
                                    setEmployeeTipSum(tip_sum)
                                }}
                            >
                                {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                            </LoginBtn>
                        </div>
                    </Popup>
                }

                {(appointmentData?.client_id && appointmentData?.client && appointmentData?.other_method === "LoyalityPoints" && !notFound && loyalityPoints && loyalityPointsPopup) &&
                    <Popup size='md' heading={selectedLanguage?.name == "English" ? "Loyalty Points" : translation[197]?.value ? translation[197]?.value : "Loyalty Points"} close={() => { setLoyalityPointsPopup(!loyalityPointsPopup) }} open={loyalityPointsPopup}>
                        <div className='space-y-10 pt-5'>
                            {
                                notFound ? <NotFound /> :
                                    <>
                                        <div className='w-full'>
                                            <div className="flex items-center justify-center gap-3 divide-x whitespace-nowrap">
                                                <div className="text-center flex-1 space-y-2">
                                                    <p className="text-[#A1A1A1] text-sm">{selectedLanguage?.name == "English" ? "Total Points" : translation[210]?.value ? translation[210]?.value : "Total Points"}</p>
                                                    <h3 className="font-bold text-3xl text-[#101928]">{loyalityPoints?.total_available_points?.toFixed(2)}</h3>
                                                </div>
                                                <div className="text-center flex-1 space-y-2">
                                                    <p className="text-[#A1A1A1] text-sm">{selectedLanguage?.name == "English" ? "Redeemable Value" : translation[211]?.value ? translation[211]?.value : "Redeemable Value"}</p>
                                                    <h3 className="font-bold text-3xl text-[#101928]">{cur_currency + " " + loyalityPoints?.total_amount?.toFixed(2)}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-end w-full gap-3">
                                            <LoginBtn className={'!bg-[#E5E6EC] !text-[#101928] !border-none !px-6'}
                                                onClick={() => { setLoyalityPointsPopup(!loyalityPointsPopup) }}
                                                text={selectedLanguage?.name == "English" ? "Cancel" : translation[165]?.value ? translation[165]?.value : "Cancel"}
                                            />
                                            {loyalityPoints && <LoginBtn
                                                disabled={notFound || redeemed_id}
                                                onClick={() => {
                                                    if (+t_price <= loyalityPoints?.total_amount) {
                                                        setConfirmRedeem(true)
                                                        setLoyalityPointsPopup(false)
                                                    } else {
                                                        toast.error(selectedLanguage?.name == "English" ? "You don't have enough loyalty points to complete this sale" : translation[212]?.value ? translation[212]?.value : "You don't have enough loyalty points to complete this sale", { toastId: "toast" })
                                                    }
                                                }}>
                                                {selectedLanguage?.name == "English" ? "Apply" : translation[1421]?.value ? translation[1421]?.value : "Apply"}
                                            </LoginBtn>
                                            }
                                        </div>
                                    </>
                            }
                        </div>
                    </Popup>
                }

                <Popup size='md'
                    heading={selectedLanguage?.name == "English" ? "Confirm Redemption" : translation[213]?.value ? translation[213]?.value : "Confirm Redemption"}
                    close={setConfirmRedeem} open={ConfirmRedeem}>
                    <div className='flex flex-col gap-5 p-5'>
                        <div className='flex flex-col gap-5'>
                            <h3 className="text-center font-semibold text-xl">
                                {selectedLanguage?.name == "English" ? "Confirm Redemption" : translation[213]?.value ? translation[213]?.value : "Confirm Redemption"}
                            </h3>
                            {notFound ?
                                <div className='text-[#A1A1A1] flex items-center gap-1.5'>
                                    {selectedLanguage?.name == "English" ? "Loyalty Points Not Found" : translation[214]?.value ? translation[214]?.value : "Loyalty Points Not Found"}
                                </div>
                                :
                                <div className='grid grid-cols-2 items-center text-center py-5'>
                                    <div className='border-r border-[#C7C7C7]'>
                                        <h5 className='text-sm text-[#A1A1A1]'>{selectedLanguage?.name == "English" ? "Total redeem points" : translation[1650]?.value ? translation[1650]?.value : "Total redeem points"}</h5>
                                        <p className='font-bold text-3xl'>{points_to_redeem?.toFixed(2)}</p>
                                    </div>
                                    <div>
                                        <h5 className='text-sm text-[#A1A1A1]'>{selectedLanguage?.name == "English" ? "Total redeemable value" : translation[1651]?.value ? translation[1651]?.value : "Total redeemable value"}</h5>
                                        <p className='font-bold text-3xl'>{amount_for_points?.toFixed(2)} {cur_currency}</p>
                                    </div>
                                </div>}
                            <div className="flex items-center justify-end gap-3">
                                <LoginBtn
                                    borderBtn
                                    text={
                                        <div className='flex items-center gap-2'>
                                            <p>{selectedLanguage?.name == "English" ? "Cancel" : translation[1421]?.value ? translation[1421]?.value : "Cancel"}</p>
                                        </div>
                                    }
                                    onClick={() => setConfirmRedeem(false)}
                                />
                                {loyalityPoints &&
                                    <LoginBtn
                                        disabled={notFound}
                                        onClick={() => {
                                            toast.success(selectedLanguage?.name == "English" ? "Loyality points redeem successfully" : translation[1652]?.value ? translation[1652]?.value : "Loyality points redeem successfully", { toastId: "toast" })
                                            setIs_redeemed(true)
                                            setRedeemed_points(points_to_redeem?.toFixed(2))
                                            setLoyaltyDiscountAmount(amount_for_points?.toFixed(2))
                                            setRedeemed_id(loyalityPoints?.id)
                                            setSelectedPaymentMethods([])
                                            setConfirmRedeem(false)
                                            setAppointmentData({
                                                ...appointmentData,
                                                voucher_redeem: undefined,
                                                voucher_type: "",
                                                is_voucher_redeemed: false,
                                                is_gift_card_redeemed: false,
                                                appliedGiftCard: {
                                                    purchased_gift_card_id: '',
                                                    partial_price: 0
                                                },
                                                redeemed_voucher_id: "",
                                                membership_product: [],
                                                membership_service: [],
                                                membership_type: "",
                                                is_membership_redeemed: false,
                                                redeemed_membership_id: "",
                                                is_coupon_redeemed: false,
                                                redeemed_coupon_id: "",
                                                coupon_type_value: "",
                                                couponsIds: [],
                                                coupon_name: "",
                                                coupon_type: "",
                                                couponDiscountPercentage: "",
                                                couponSpendAmount: ""
                                            })
                                        }}>
                                        {selectedLanguage?.name == "English" ? "Redeem" : translation[204]?.value ? translation[204]?.value : "Redeem"}
                                    </LoginBtn>
                                }
                            </div>
                        </div>
                    </div>
                </Popup>

                {(appointmentData?.client_id && appointmentData?.other_method === "Voucher" && voucherAvailable && voucherPopup) &&
                    <Popup size='md' heading={selectedLanguage?.name == "English" ? "Vouchers" : translation[196]?.value ? translation[196]?.value : "Vouchers"} close={() => { setVoucherPopup(!voucherPopup) }} open={voucherPopup}>
                        <div className=''>
                            {
                                voucherNotFound ? <NotFound /> :
                                    checkoutVouchers?.length > 0 && checkoutVouchers?.map((vchr, index) =>
                                        <>
                                            <div className='border-2 border-[#e5e7eb] border-solid px-5 py-3 rounded-xl my-4' key={index}>
                                                <div className=''>
                                                    <div className="pb-3 flex items-center gap-5 justify-between">
                                                        <div className="space-y-1">
                                                            <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name"}</p>
                                                            <h3 className="font-bold">{vchr?.voucher?.name}</h3>
                                                        </div>
                                                        <div className="space-y-1">
                                                            <h3 className=" font-bold text-right">{cur_currency + " " + vchr?.voucher_price}</h3>
                                                            <div className="px-[0.625rem] py-2 rounded-lg bg-[#ECF3FF]"><p className="text-[#5679FF] text-xs">{vchr?.discount_percentage} Percentge</p></div>
                                                        </div>
                                                    </div>
                                                    <div className="space-y-4 py-3">
                                                        <div className="flex items-center justify-between sm:flex-row flex-col border-y border-solid border-[#DEDFE5] sm:divide-x divide-y sm:divide-y-0">
                                                            <div className="px-5  py-3 w-full sm:w-fit">
                                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"}</p>
                                                                <h4 className="font-bold whitespace-nowrap">{vchr?.voucher?.voucher_type}</h4>
                                                            </div>
                                                            <div className="px-5  py-3 w-full sm:w-fit">
                                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Purchase at" : translation[1647]?.value ? translation[1647]?.value : "Purchase at"}</p>
                                                                <h4 className="font-bold whitespace-nowrap">{moment(vchr?.created_at).format('DD MMMM YYYY')}</h4>
                                                            </div>
                                                            <div className="px-5  py-3 w-full sm:w-fit">
                                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Expiry" : translation[203]?.value ? translation[203]?.value : "Expiry"}</p>
                                                                <h4 className="font-bold whitespace-nowrap">{moment(vchr?.voucher?.end_date).format('DD MMMM YYYY')}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-full flex items-center justify-end gap-3">
                                                    {/* <LoginBtn className={'!bg-[#E5E6EC] !text-[#101928] !border-none !px-6'}
                                            text={selectedLanguage?.name == "English" ? "Cancel" : translation[165]?.value ? translation[165]?.value : "Cancel"}
                                        /> */}
                                                    <LoginBtn onClick={() => {
                                                        setAppointmentData({
                                                            ...appointmentData,
                                                            voucher_redeem: vchr?.discount_percentage,
                                                            voucher_type: vchr?.voucher?.voucher_type,
                                                            is_gift_card_redeemed: false,
                                                            appliedGiftCard: {
                                                                purchased_gift_card_id: '',
                                                                partial_price: 0
                                                            },
                                                            is_voucher_redeemed: true,
                                                            redeemed_voucher_id: vchr?.id,
                                                            membership_product: [],
                                                            membership_service: [],
                                                            membership_type: "",
                                                            is_membership_redeemed: false,
                                                            redeemed_membership_id: "",
                                                            is_coupon_redeemed: false,
                                                            redeemed_coupon_id: "",
                                                            coupon_type_value: "",
                                                            couponsIds: [],
                                                            coupon_name: "",
                                                            coupon_type: "",
                                                            couponDiscountPercentage: "",
                                                            couponSpendAmount: ""
                                                        })
                                                        setRedeemed_id('')
                                                        setSelectedPaymentMethods([])
                                                        setRedeemed_points('')
                                                        setLoyaltyDiscountAmount('')
                                                        setIs_redeemed(false)
                                                        setVoucherPopup(!voucherPopup)
                                                        toast.success(selectedLanguage?.name == "English" ? "Voucher redeem successfully" : translation[1654]?.value ? translation[1654]?.value : "Voucher redeem successfully", { toastId: "toast" })
                                                    }}
                                                        text={selectedLanguage?.name == "English" ? "Redeem" : translation[204]?.value ? translation[204]?.value : "Redeem"}
                                                    />
                                                </div>
                                            </div>
                                        </>)
                            }
                        </div>
                    </Popup>
                }


                {couponPopup &&
                    <Popup size='md' heading={selectedLanguage?.name == "English" ? "Redeem Coupon" : translation[1646]?.value ? translation[1646]?.value : "Redeem Coupon"} close={() => { setCouponPopup(!couponPopup) }} open={couponPopup}>
                        <div className='space-y-6'>
                            <div className='w-full min-w-[18.75rem]'>
                                <LoginInput
                                    title={selectedLanguage?.name == "English" ? "Enter Coupon Code" : translation[901]?.value ? translation[901]?.value : "Enter Coupon Code"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Coupon Code" : translation[901]?.value ? translation[901]?.value : "Enter Coupon Code"}
                                    name="couponCode"
                                    value={couponCode}
                                    type="text"
                                    charactersMaxLength={6}
                                    onChange={(e) => {
                                        setCouponCode(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="flex items-center justify-end w-full gap-3">
                                <LoginBtn className={'!bg-[#E5E6EC] !text-[#101928] !border-none !px-6'}
                                    onClick={() => { setCouponPopup(!couponPopup) }}
                                    text={selectedLanguage?.name == "English" ? "Cancel" : translation[165]?.value ? translation[165]?.value : "Cancel"}
                                />
                                <LoginBtn
                                    disabled={!couponCode || getCouponLoader}
                                    loading={getCouponLoader}
                                    text={
                                        <div className='flex items-center gap-2'>
                                            <p>{selectedLanguage?.name == "English" ? "Redeem Coupon" : translation[97]?.value ? translation[97]?.value : "Redeem Coupon"}</p>
                                        </div>
                                    }
                                    onClick={checkCouponCode}
                                />
                            </div>
                        </div>
                    </Popup>
                }
                <Popup size='md' heading={selectedLanguage?.name == "English" ? "Gift Card" : translation[1540]?.value ? translation[1540]?.value : "Gift Card"} close={() => { setGiftCardPopup(!giftCardPopup) }} open={giftCardPopup}>
                    {giftCardDetail?.length > 0
                        ? giftCardDetail?.map((itm, index) =>
                            <div className='space-y-5' key={index}>
                                <div className='flex flex-col justify-between gap-10 p-5 px-7 rounded-[20px] text-white md:h-[255px]' style={{ backgroundImage: 'linear-gradient(269.5deg, #5679FF -5.22%, #325AF1 87.33%)' }}>
                                    <div>
                                        <div className='flex items-end gap-5'>
                                            <p className='text-[2.5rem] font-bold'>{cur_currency} {' '}
                                                {+(itm?.spend_amount)?.toFixed(2)}</p>
                                            <p className='text-[1.3rem]'>/ {cur_currency} {' '} {+(itm?.price)?.toFixed(2)}</p>
                                        </div>
                                        <p className='text-[1.25rem]'>{itm.gift_card_detail?.title}</p>
                                    </div>
                                    <div className='flex justify-between items-center'>
                                        <div className='flex flex-col'>
                                            <p className='font-light'>{selectedLanguage?.name == "English" ? "Code" : translation[1576]?.value ? translation[1576]?.value : "Code"}</p>
                                            <p className='text-[1.25rem] font-semibold'>{itm?.sale_code}</p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='font-light'> {selectedLanguage?.name == "English" ? "Expiry" : translation[203]?.value ? translation[203]?.value : "Expiry"}</p>
                                            <p className='text-[1.25rem] font-semibold'>{moment(itm?.expiry).format('DD MMMM YYYY')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-between items-end'>
                                    <p className='font-semibold text-xl'>{selectedLanguage?.name == "English" ? "Amount to Pay" : translation[1540]?.value ? translation[1540]?.value : "Amount to Pay"}</p>
                                    <p className='font-semibold text-base'> {cur_currency} {' '} {(+amountToPay).toFixed(2)}</p>
                                </div>
                                <div className='flex flex-col gap-5'>
                                    <div>
                                        <FloatInput
                                            title={selectedLanguage?.name == "English" ? "Redeem Amount" : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"}
                                            placeholder={selectedLanguage?.name == "English" ? "Redeem Amount" : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"}
                                            name="giftCardCode"
                                            value={giftCard_amount}
                                            required={false}
                                            type="text"
                                            charactersMaxLength={6}
                                            onChange={(e) => {
                                                setGiftCardError('')
                                                if (+e.target.value !== 0) {
                                                    if (+amountToPay < +e.target.value || +(itm?.spend_amount) < +e.target.value) {
                                                        // setGiftCard_amount(null)
                                                        setGiftCardError(`${selectedLanguage?.name == "English" ? "Amount to redeem must be lower or equal to " : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"} ${+(itm?.spend_amount) < +e.target.value ? +(itm?.spend_amount) : +amountToPay.toFixed(2)} `)

                                                    } else {
                                                        setGiftCardError('')
                                                        setGiftCard_amount(e.target.value)
                                                    }
                                                } else {
                                                    setGiftCard_amount(null)
                                                    setGiftCardError(`${selectedLanguage?.name == "English" ? "Amount cannot be zero" : translation[1664]?.value ? translation[1664]?.value : "Amount cannot be zero"} `)
                                                }
                                            }}
                                            error={giftCardError}
                                            parentClass={"mb-2"}
                                        />
                                    </div>
                                    <div className="flex items-center justify-end gap-3">
                                        <LoginBtn
                                            borderBtn
                                            text={
                                                <div className='flex items-center gap-2'>
                                                    <p>{selectedLanguage?.name == "English" ? "Cancel" : translation[1421]?.value ? translation[1421]?.value : "Cancel"}</p>
                                                </div>
                                            }
                                            onClick={() => {
                                                setGiftCardPopup(false)
                                                setGiftCardDetail([])
                                            }}
                                        />
                                        {/* <div className="flex gap-4  items-end"> */}
                                        <LoginBtn
                                            onClick={() => {
                                                if (+itm?.spend_amount?.toFixed(2) < (+giftCard_amount)) {
                                                    setGiftCardError(`${selectedLanguage?.name == "English"
                                                        ? "Amount to redeem must be lower or equal to "
                                                        : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"} ${+(itm?.spend_amount).toFixed(2)} `)
                                                } else {
                                                    setAppointmentData({
                                                        ...appointmentData,
                                                        voucher_redeem: undefined,
                                                        voucher_type: "",
                                                        is_voucher_redeemed: false,
                                                        redeemed_voucher_id: "",
                                                        membership_product: [],
                                                        membership_service: [],
                                                        membership_type: "",
                                                        is_membership_redeemed: false,
                                                        is_gift_card_redeemed: true,
                                                        appliedGiftCard: {
                                                            purchased_gift_card_id: itm?.id,
                                                            partial_price: +giftCard_amount,
                                                        },
                                                        redeemed_membership_id: "",
                                                        is_coupon_redeemed: false,
                                                        redeemed_coupon_id: "",
                                                        coupon_type_value: "",
                                                        couponsIds: [],
                                                        coupon_name: "",
                                                        coupon_type: "",
                                                        couponDiscountPercentage: "",
                                                        couponSpendAmount: ""
                                                    })
                                                    setSelectedPaymentMethods([])
                                                    setRedeemed_id('')
                                                    setRedeemed_points('')
                                                    setLoyaltyDiscountAmount('')
                                                    setIs_redeemed(false)
                                                    setGiftCardPopup(!giftCardPopup)
                                                    setSelectedPaymentMethods([])
                                                }
                                            }}
                                            disabled={!giftCard_amount}
                                            className={'h-fit'}
                                            text={selectedLanguage?.name == "English" ? "Redeem" : translation[204]?.value ? translation[204]?.value : "Redeem"} />

                                    </div>
                                </div>
                            </div>
                        )
                        : <div className='space-y-6'>
                            <div className='w-full min-w-[18.75rem]'>
                                <LoginInput
                                    title={selectedLanguage?.name == "English" ? "Gift card number" : translation[1649]?.value ? translation[1649]?.value : "Gift card number"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter gift card number" : translation[1648]?.value ? translation[1648]?.value : "Enter gift card number"}
                                    name="giftCardCode"
                                    value={giftCardCode}
                                    type="text"
                                    charactersMaxLength={6}
                                    onChange={(e) => {
                                        setGiftCardCode(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="flex items-center justify-end w-full gap-3">
                                <LoginBtn className={'!bg-[#E5E6EC] !text-[#101928] !border-none !px-6'}
                                    onClick={() => { setGiftCardPopup(false) }}
                                    text={selectedLanguage?.name == "English" ? "Cancel" : translation[165]?.value ? translation[165]?.value : "Cancel"}
                                />
                                <LoginBtn
                                    disabled={!giftCardCode || giftCardLoader}
                                    loading={giftCardLoader}
                                    text={
                                        <div className='flex items-center gap-2'>
                                            <p>{selectedLanguage?.name == "English" ? "Apply" : translation[1421]?.value ? translation[1421]?.value : "Apply"}</p>
                                        </div>
                                    }
                                    onClick={checkGiftCardCode}
                                />
                            </div>
                        </div>
                    }
                </Popup>

                {(membershipPopup && checkoutMemberships?.length > 0) &&
                    <Popup size='md' heading={selectedLanguage?.name == "English" ? "Memberships" : translation[195]?.value ? translation[195]?.value : "Memberships"} close={() => { setMembershipPopup(!membershipPopup) }} open={membershipPopup}>
                        {membershipNotFound ? <NotFound /> :
                            checkoutMemberships?.length > 0 && checkoutMemberships?.map((membership, index) =>
                                <div className='border-2 border-[#e5e7eb] border-solid px-5 py-3 rounded-xl my-4' key={index}>
                                    <div className='divide-y'>
                                        <div className="pb-3 flex items-center gap-5">
                                            <div className="space-y-1">
                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Membership Name" : translation[205]?.value ? translation[205]?.value : "Membership Name"}</p>
                                                <h3 className="font-bold">{membership?.name}</h3>
                                            </div>
                                            <div className="space-y-1">
                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Price" : translation[200]?.value ? translation[200]?.value : "Price"}</p>
                                                <h3 className=" font-bold">{cur_currency + " " + membership?.membership_price}</h3>
                                            </div>
                                        </div>
                                        <div className="space-y-1 py-3">
                                            <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Membership Services" : translation[206]?.value ? translation[206]?.value : "Membership Services"}</p>
                                            <div className="flex items-center gap-3 flex-wrap">
                                                {membership?.services?.map(service => (
                                                    <div className="px-[0.625rem] py-2 rounded-lg bg-[#ECF3FF]"><p className="text-[#5679FF]">{service?.service_name}</p></div>
                                                ))}

                                            </div>
                                        </div>
                                        <div className="space-y-4 py-3">
                                            <div className="space-y-1">
                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Membership Products" : translation[1236]?.value ? translation[1236]?.value : "Membership Products"}</p>
                                                <div className="flex items-center gap-3 flex-wrap">
                                                    {membership?.products?.map(product => (
                                                        <div className="px-[0.625rem] py-2 rounded-lg bg-[#ECF3FF]"><p className="text-[#5679FF] text-sm">{product?.product_name}</p></div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between sm:flex-row flex-col border-y border-solid border-[#DEDFE5] sm:divide-x divide-y sm:divide-y-0">
                                                <div className="px-5  py-3 w-full sm:w-fit">
                                                    <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"}</p>
                                                    <h4 className="font-bold whitespace-nowrap">{membership?.discount_type}</h4>
                                                </div>
                                                <div className="px-5  py-3 w-full sm:w-fit">
                                                    <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Purchase at" : translation[1647]?.value ? translation[1647]?.value : "Purchase at"}</p>
                                                    <h4 className="font-bold whitespace-nowrap">{moment(membership?.created_at).format('DD MMMM YYYY')}</h4>
                                                </div>
                                                <div className="px-5  py-3 w-full sm:w-fit">
                                                    <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Expiry" : translation[203]?.value ? translation[203]?.value : "Expiry"}</p>
                                                    <h4 className="font-bold whitespace-nowrap">{moment(membership?.end_date).format('DD MMMM YYYY')}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full flex items-center justify-end gap-3">
                                        {/* <LoginBtn className={'!bg-[#E5E6EC] !text-[#101928] !border-none !px-6'}
                                            text={selectedLanguage?.name == "English" ? "Cancel" : translation[165]?.value ? translation[165]?.value : "Cancel"}
                                        /> */}
                                        <LoginBtn
                                            onClick={() => {
                                                let redeemValidation = membership?.services?.filter(srvce => appointmentServices?.find(itm => srvce?.service == itm?.service))
                                                setAppointmentData({
                                                    ...appointmentData,
                                                    membership_service: membership?.services?.filter(srvce => appointmentServices?.find(itm => srvce?.service == itm?.service)),
                                                    membership_type: membership?.discount_type,
                                                    is_membership_redeemed: redeemValidation ? true : false,
                                                    redeemed_membership_id: redeemValidation ? membership?.id : undefined,
                                                    is_gift_card_redeemed: false,
                                                    appliedGiftCard: {
                                                        purchased_gift_card_id: '',
                                                        partial_price: 0
                                                    },
                                                    voucher_redeem: undefined,
                                                    voucher_type: "",
                                                    is_voucher_redeemed: false,
                                                    redeemed_voucher_id: "",
                                                    is_coupon_redeemed: false,
                                                    redeemed_coupon_id: "",
                                                    coupon_type_value: "",
                                                    couponsIds: [],
                                                    coupon_name: "",
                                                    coupon_type: "",
                                                    couponDiscountPercentage: "",
                                                    couponSpendAmount: ""
                                                })
                                                setSelectedPaymentMethods([])
                                                setRedeemed_id('')
                                                setRedeemed_points('')
                                                setLoyaltyDiscountAmount('')
                                                setIs_redeemed(false)
                                                setMembershipPopup(!membershipPopup)
                                                if (redeemValidation) {
                                                    toast.success(selectedLanguage?.name == "English" ? "Membership redeem successfully" : translation[1653]?.value ? translation[1653]?.value : "Membership redeem successfully", { toastId: "toast" })
                                                } else {
                                                    toast.error(selectedLanguage?.name == "English" ? "Membership cannot be redeemed" : translation[1653]?.value ? translation[1653]?.value : "Membership cannot be redeemed", { toastId: "toast" })
                                                }
                                            }}
                                            text={selectedLanguage?.name == "English" ? "Redeem" : translation[204]?.value ? translation[204]?.value : "Redeem"}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </Popup>
                }
            </div>
        </>
    )
}

export default CheckoutFinish