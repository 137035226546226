import React from 'react'
import useDealTypeTwoSetting from './helper'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import Svgs from 'Assets/svgs'
import PreloaderFull from 'Components/TenantApp/Elements/PreloaderFull'
import RadioBtn from 'Components/TenantApp/Elements/RadioBtn'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import AddPopup from '../../../Elements/AddPopup'
import NotFound from 'Components/Utility/NotFound'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'

const DealTypeTwoSetting = (props) => {
    const { serviceDiscount, errors, allServices, productDiscount, allServicesCategory,
        allServicesGroup, allProductsBrand, allProducts, setErrors, setSettingData, settingData,
        voucherDiscount, submitSettingClick, firstStepData } = props

    const {
        screenLoader, handleChangeForServiceDiscountRadio, handleChangeForDealType2, handleChangeForType2DealDropdown,
        handleDiscountChange, removeSelectedItem, tabList, activeTab, setActiveTab, allMergedArrays,
        serviceType, setServiceType, popup, setPopup, searchService, setSearchService, searchProduct, setSearchProduct,
        selectedServicesArray, selectedProductsArray
    } = useDealTypeTwoSetting({
        setErrors, setSettingData, settingData, allProducts, allServices, allProductsBrand, allServicesCategory, allServicesGroup,
        serviceDiscount, productDiscount
    })

    return screenLoader ? <PreloaderFull /> : (
        <>
            <div className="space-y-3">
                <h1 className='font-semibold'>Service</h1>
                <div className="flex items-center gap-10">
                    <div className="flex items-center gap-1">
                        <RadioBtn
                            text={"Not Applicable"}
                            name="applicableOnService"
                            id={'applicableOnService'}
                            checked={serviceDiscount?.applicableOn ? serviceDiscount?.applicableOn === 'None' : true}
                            value={serviceDiscount?.applicableOn}
                            onClick={() => { handleChangeForServiceDiscountRadio('service', 'None') }}
                        />
                    </div>
                    <div className="flex items-center gap-1">
                        <RadioBtn
                            text={"On All Service"}
                            name="applicableOnService"
                            id={'onAllService'}
                            checked={serviceDiscount?.applicableOn === 'All'}
                            value={serviceDiscount?.applicableOn}
                            onClick={() => { handleChangeForServiceDiscountRadio('service', 'All') }}
                        />
                    </div>
                    <div className="flex items-center gap-1">
                        <RadioBtn
                            text={"Custom Discount"}
                            name="applicableOnService"
                            id={'customDiscountService'}
                            checked={serviceDiscount?.applicableOn === 'Custom'}
                            value={serviceDiscount?.applicableOn}
                            onClick={() => { handleChangeForServiceDiscountRadio('service', 'Custom') }}
                        />
                    </div>
                </div>
                {serviceDiscount?.applicableOn === 'All' &&
                    <LoginInput
                        required={true}
                        title={'Discount Percentage'}
                        placeholder={'Enter Discount Percentage'}
                        name="applicableOnAllDiscountService"
                        value={serviceDiscount?.applicableOnAllDiscount}
                        type={'number'}
                        onlyNumericAllowed={true}
                        onChange={(e) => { handleChangeForDealType2(e, 'service') }}
                        error={errors?.applicableOnAllDiscountService}
                    />
                }
            </div>
            {serviceDiscount?.applicableOn === 'Custom' &&
                <>
                    <div className="grid md:grid-cols-3 gap-8 mx-auto">
                        <LoginBtn
                            text={'Select Service'}
                            className={'!w-full justify-center'}
                            onClick={() => {
                                setPopup(true)
                                setServiceType('Service')
                            }}
                        />
                        <LoginBtn
                            text={'Select Service Group'}
                            className={'!w-full justify-center'}
                            onClick={() => {
                                setPopup(true)
                                setServiceType('ServiceGroup')
                            }}
                        />
                        <LoginBtn
                            text={'Select Service Category'}
                            className={'!w-full justify-center'}
                            onClick={() => {
                                setPopup(true)
                                setServiceType('ServiceCategory')
                            }}
                        />
                    </div>
                    {(serviceType == 'Service' && popup) &&
                        <AddPopup
                            completeDataLength={allServices?.length}
                            firstStepData={firstStepData}
                            open={popup}
                            close={() => {
                                setPopup(false)
                                setServiceType()
                            }}
                            heading={"Select Services"}
                            placeholder={"Search Service"}
                            apiType={"single-service"}
                            data={serviceDiscount?.applicableServices?.map(itm => itm?.itemId)}
                            onSubmitHandler={(e, data) => { handleChangeForType2DealDropdown(e, data, 'customService') }}
                        />
                    }
                    {(serviceType == 'ServiceGroup' && popup) &&
                        <AddPopup
                            firstStepData={firstStepData}
                            open={popup}
                            close={() => {
                                setPopup(false)
                                setServiceType()
                            }}
                            heading={"Select Service Groups"}
                            placeholder={"Search Service Groups"}
                            apiType={"service-group"}
                            completeDataLength={allServicesGroup?.length}
                            data={serviceDiscount?.applicableServices?.map(itm => itm?.itemId)}
                            onSubmitHandler={(e, data) => { handleChangeForType2DealDropdown(e, data, 'customService') }}
                        />
                    }
                    {(serviceType == 'ServiceCategory' && popup) &&
                        <AddPopup
                            firstStepData={firstStepData}
                            open={popup}
                            close={() => {
                                setPopup(false)
                                setServiceType()
                            }}
                            heading={"Select Service Categories"}
                            placeholder={"Search Service Categories"}
                            apiType={"service-category"}
                            completeDataLength={allServicesCategory?.length}
                            data={serviceDiscount?.applicableServices?.map(itm => itm?.itemId)}
                            onSubmitHandler={(e, data) => { handleChangeForType2DealDropdown(e, data, 'customService') }}
                        />
                    }
                    {serviceDiscount?.applicableServices?.length > 0 &&

                        <div className="">
                            <div className="border rounded-lg">
                                <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
                                    <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                                        <div className="flex items-center lg:gap-4 gap-3">
                                            <div className="flex items-center sm:gap-4 gap-2 ">
                                                <h2 className="font-semibold text-xl">Selected Items</h2>
                                                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                                    <p className="text-primary">{serviceDiscount?.applicableServices?.length} {serviceDiscount?.applicableServices?.length > 1 ? 'Records' : 'Record'} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-00">
                                            <LoginInput
                                                title=""
                                                value={searchService}
                                                onChange={(e) => setSearchService(e.target.value)}
                                                name={"search_text"}
                                                placeholder={"Search"}
                                                padding="py-1"
                                                leftIcon={<Svgs.Search />}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="overflow-x-auto">
                                    <div className="inline-grid xl:grid grid-cols-[repeat(3,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                                        <p>Name</p>
                                        <p>Discount Percentage</p>
                                        <p>Action</p>
                                    </div>
                                    <div className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}>
                                        {selectedServicesArray?.filter((itm) =>
                                            searchService ? itm?.name?.toLowerCase()?.includes(searchService?.toLowerCase()) : true
                                        )?.length > 0 ? selectedServicesArray?.filter((itm) =>
                                            searchService ? itm?.name?.toLowerCase()?.includes(searchService?.toLowerCase()) : true
                                        )?.map((item, i) => {
                                            const filtered = serviceDiscount?.applicableServices?.find((itm) => itm?.itemId == item?.id);
                                            return (
                                                <div key={i} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(3,minmax(115px,1fr))] min-w-full w-fit">

                                                    <div className="flex items-center gap-4">
                                                        <div>
                                                            #{i + 1}
                                                        </div>
                                                        {item?.name}
                                                    </div>

                                                    <div className="flex items-center capitalize">
                                                        <FloatInput
                                                            required={true}
                                                            title={''}
                                                            placeholder={'Enter Discount Percentage'}
                                                            name="discountPercentage"
                                                            value={filtered?.discount}
                                                            onChange={(e) => { handleDiscountChange(e, filtered?.itemId, 'customService') }}
                                                            error={(submitSettingClick && !filtered?.discount) ? true : false}
                                                        />
                                                    </div>
                                                    <div className="flex items-center gap-3">
                                                        <div
                                                            onClick={() =>
                                                                removeSelectedItem(filtered?.itemId, "customService")
                                                            }
                                                            className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center">
                                                            <Svgs.Delete fill="#7D7D7D" />
                                                        </div>
                                                    </div>
                                                </div>

                                            );
                                        })
                                            : <NotFound />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
            <h1 className='font-semibold'>Product</h1>
            <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-3">
                    <div className="flex items-center gap-10">
                        <RadioBtn
                            text={"Not Applicable"}
                            name="applicableOnProduct"
                            id={'applicableOnProduct'}
                            value={productDiscount?.applicableOn}
                            checked={productDiscount?.applicableOn ? productDiscount?.applicableOn === 'None' : true}
                            onClick={() => { handleChangeForServiceDiscountRadio('product', 'None') }}
                        />
                        <RadioBtn
                            text={"On All Products"}
                            name="applicableOnProduct"
                            id={'onAllProduct'}
                            value={productDiscount?.applicableOn}
                            checked={productDiscount?.applicableOn == 'All'}
                            onClick={() => { handleChangeForServiceDiscountRadio('product', 'All') }}
                        />
                        <RadioBtn
                            text={"Custom Discount"}
                            name="applicableOnProduct"
                            id={'customDiscountProduct'}
                            value={productDiscount?.applicableOn}
                            checked={productDiscount?.applicableOn == 'Custom'}
                            onClick={() => { handleChangeForServiceDiscountRadio('product', 'Custom') }}
                        />
                    </div>
                    {productDiscount?.applicableOn === 'All' &&
                        <LoginInput
                            required={true}
                            title={'Discount Percentage'}
                            placeholder={'Enter Discount Percentage'}
                            name="applicableOnAllDiscountProduct"
                            value={productDiscount?.applicableOnAllDiscount}
                            type={'number'}
                            onlyNumericAllowed={true}
                            onChange={(e) => { handleChangeForDealType2(e, 'product') }}
                            error={errors?.applicableOnAllDiscountProduct}
                        />
                    }
                </div>
            </div>
            {productDiscount?.applicableOn === 'Custom' &&
                <>
                    <div className="grid md:grid-cols-3 gap-8 mx-auto">
                        <LoginBtn
                            text={'Select Product'}
                            className={'!w-full justify-center'}
                            onClick={() => {
                                setPopup(true)
                                setServiceType('Product')
                            }}
                        />
                        <LoginBtn
                            text={'Select Product Brand'}
                            className={'!w-full justify-center'}
                            onClick={() => {
                                setPopup(true)
                                setServiceType('ProductBrand')
                            }}
                        />
                    </div>
                    {(serviceType == 'Product' && popup) &&
                        <AddPopup
                            firstStepData={firstStepData}
                            open={popup}
                            close={() => {
                                setPopup(false)
                                setServiceType()
                            }}
                            heading={"Select Products"}
                            placeholder={"Search Product"}
                            apiType={"single-product"}
                            completeDataLength={allProducts?.length}
                            data={productDiscount?.applicableProducts?.map(itm => itm?.itemId)}
                            onSubmitHandler={(e, data) => { handleChangeForType2DealDropdown(e, data, 'customProduct') }}
                        />
                    }
                    {(serviceType == 'ProductBrand' && popup) &&
                        <AddPopup
                            firstStepData={firstStepData}
                            open={popup}
                            close={() => {
                                setPopup(false)
                                setServiceType()
                            }}
                            heading={"Select Products Brands"}
                            placeholder={"Search Product Brand"}
                            apiType={"product-brand"}
                            completeDataLength={allProductsBrand?.length}
                            data={productDiscount?.applicableProducts?.map(itm => itm?.itemId)}
                            onSubmitHandler={(e, data) => { handleChangeForType2DealDropdown(e, data, 'customProduct') }}
                        />
                    }
                    {productDiscount?.applicableProducts?.length > 0 &&
                        <div className="">
                            <div className="border rounded-lg">
                                <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
                                    <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                                        <div className="flex items-center lg:gap-4 gap-3">
                                            <div className="flex items-center sm:gap-4 gap-2 ">
                                                <h2 className="font-semibold text-xl">Selected Items</h2>
                                                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                                    <p className="text-primary">{productDiscount?.applicableProducts?.length} {serviceDiscount?.applicableServices?.length > 1 ? 'Records' : 'Record'} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-00">
                                            <LoginInput
                                                title=""
                                                value={searchProduct}
                                                onChange={(e) => setSearchProduct(e.target.value)}
                                                name={"search_text"}
                                                placeholder={"Search"}
                                                padding="py-1"
                                                leftIcon={<Svgs.Search />}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="overflow-x-auto">
                                    <div className="inline-grid xl:grid grid-cols-[repeat(3,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                                        <p>Name</p>
                                        <p>Discount Percentage</p>
                                        <p>Action</p>
                                    </div>
                                    <div className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}>
                                        {selectedProductsArray?.filter((itm) =>
                                            searchProduct ? itm?.name?.toLowerCase()?.includes(searchProduct?.toLowerCase()) : true
                                        )?.length > 0 ? selectedProductsArray?.filter((itm) =>
                                            searchProduct ? itm?.name?.toLowerCase()?.includes(searchProduct?.toLowerCase()) : true
                                        )?.map((item, i) => {
                                            const filtered = productDiscount?.applicableProducts?.find((itm) => itm?.itemId == item?.id);
                                            return (
                                                <div key={i} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(3,minmax(115px,1fr))] min-w-full w-fit">

                                                    <div className="flex items-center gap-4">
                                                        <div>
                                                            #{i + 1}
                                                        </div>
                                                        {item?.name}
                                                    </div>

                                                    <div className="flex items-center capitalize">
                                                        <LoginInput
                                                            required={true}
                                                            title={''}
                                                            placeholder={'Enter Discount Percentage'}
                                                            name="discountPercentage"
                                                            value={filtered?.discount}
                                                            onChange={(e) => { handleDiscountChange(e, filtered?.itemId, 'customProduct') }}
                                                            error={(submitSettingClick && !filtered?.discount) ? true : false}
                                                        />
                                                    </div>
                                                    <div className="flex items-center gap-3">
                                                        <div
                                                            onClick={() =>
                                                                removeSelectedItem(filtered?.itemId, "customProduct")
                                                            }
                                                            className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center">
                                                            <Svgs.Delete fill="#7D7D7D" />
                                                        </div>
                                                    </div>
                                                </div>

                                            );
                                        })
                                            : <NotFound />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
            <h1 className='font-semibold'>Voucher</h1>
            <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-3">
                    <div className="flex items-center gap-10">
                        <div className="flex items-center gap-1">
                            <RadioBtn
                                text={"Not Applicable"}
                                name="applicableOnVoucher"
                                id={'applicableOnVoucher'}
                                value={voucherDiscount?.applicableOn}
                                checked={voucherDiscount?.applicableOn ? voucherDiscount?.applicableOn == 'None' : true}
                                onClick={() => { handleChangeForServiceDiscountRadio('voucher', 'None') }}
                            />
                        </div>
                        <div className="flex items-center gap-1">
                            <RadioBtn
                                text={"On All Voucher"}
                                name="applicableOnVoucher"
                                id={'onAllVoucher'}
                                value={voucherDiscount?.applicableOn}
                                checked={voucherDiscount?.applicableOn == 'All'}
                                onClick={() => { handleChangeForServiceDiscountRadio('voucher', 'All') }}
                            />
                        </div>
                    </div>
                    {voucherDiscount?.applicableOn === 'All' &&
                        <LoginInput
                            required={true}
                            title={'Discount Percentage'}
                            placeholder={'Enter Discount Percentage'}
                            name="applicableOnAllDiscountVoucher"
                            value={voucherDiscount?.applicableOnAllDiscount}
                            type={'number'}
                            onlyNumericAllowed={true}
                            onChange={(e) => { handleChangeForDealType2(e, 'voucher') }}
                            error={errors?.applicableOnAllDiscountVoucher}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default DealTypeTwoSetting