
import { UTITLY_SERVICE } from "Redux/Utility/ActionTypes/ServiceTypes";

const initialState = {
    services: [],
    service_groups: [],
    service_updated: false,
};


const ServiceReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {

        case UTITLY_SERVICE.GET_SERVICE:
            return {
                ...state,
                services: payload,
                service_updated: true
            }
        case UTITLY_SERVICE.GET_SERVICE_GROUP:
            return {
                ...state,
                service_groups: payload,
                service_updated: true
            }
        default:
            return state;
    }
};


export default ServiceReducer;
