import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: false,
  },
  tooltip: {
    backgroundColor: 'white', // Background color of the tooltip
    titleColor: '#101928',
    bodyColor: 'rgba(16, 25, 40, 0.50)', // Text color of the tooltip
    borderColor: '#cecece',
    borderWidth: '1',
    yAlign: 'bottom',
    padding: '10',
    textAlign: 'center'
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      data: [5, 121, 121, 123, 123, 213, 12, 311, 312],
      borderColor: 'rgba(86, 121, 254, 1)',
      backgroundColor: 'rgba(86, 121, 254, 0.5)',
      cubicInterpolationMode: 'monotone', // Set the interpolation mode for smooth curves


    },
  ],
};

export default function LineChart() {
  return <Line height={100} options={options} data={data} />;
}
