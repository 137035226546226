import Svgs from "../../../../../../Assets/svgs";
import React, { useEffect, useState, useMemo } from "react";
import useSetDataInLocalStorage from "../../../../../../Hooks/useSetDataInLocalStorage";
import LoginInput from "../../../../Elements/LoginInput";
import Dropwdown from "../../../../Elements/Dropwdown";
import LoginBtn from "../../../../Elements/LoginBtn";
import { useSelector } from "react-redux";
import Textarea from "../../../../Elements/Textarea";
import FloatInput from "Components/TenantApp/Elements/FloatInput";
import CheckBox from "Components/TenantApp/Elements/Checkbox/CheckBox";
// import NumberInput from "Components/TenantApp/Elements/NumberInput";

const AddMembershipCard = ({
  data,
  onDelete,
  is_edit,
  onUpdate,
  index,
  onEditClick,
  currency,
  editMemberError,
  onBlurHandler,
  errors,
  setErrors,
  setEditMemberError,
  updateLoading,
  translation, selectedLanguage, formRef, submitButtonClick,
  ...props
}) => {
  const state = useSelector((state) => state);
  const locationList = state.locations.locations;
  const productRedux = state?.product?.products;
  const serviceRedux = state?.service?.services;
  const currencyList = state?.utility?.currency?.currency_list;
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");

  let business = businessData?.id;

  // let pricingType = "test";
  const [memberData, setmemberData] = useState({
    services: [{ percentage: "", service: "" }],
    products: [{ percentage: "", duration: "7 Days", product: "" }],
    is_installment: false,
  });
  const { name, services, products, valid_for, currency_membership, description, term_condition, is_installment } = memberData;

  const [duration, setDuration] = useState('')
  const [price, setPrice] = useState('')
  const [product_percentage, setProduct_percentage] = useState('')
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [editId, seteditId] = useState("");
  const [selectedService, setSelectedService] = useState();
  const [servicePercentage, setServicePercentage] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [productPercentage, setProductPercentage] = useState();
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  let allUinqueCurrencyList = [];

  locationList?.filter((item) => item?.currency?.id).filter((itm) => {
    if (!allUinqueCurrencyList.find((dt) => dt?.currency?.id === itm?.currency?.id)) {
      allUinqueCurrencyList.push(itm)
      return true
    }
  })

  let serviceList = [];

  serviceRedux?.filter((item) => item?.id).filter((itm) => {
    if (!serviceList.find((dt) => dt?.id === itm?.id)) {
      serviceList?.push(itm)
      return true
    }
  })

  let productList = [];
  productRedux?.filter((item) => item?.id).filter((itm) => {
    if (!productList.find((dt) => dt?.id === itm?.id)) {
      productList?.push(itm)
      return true
    }
  })

  const servicesDropdown = useMemo(() => {
    return serviceRedux?.map(obj => {
      return {
        label: obj?.name,
        value: obj?.id
      }
    })
  }, [serviceRedux])

  const productDropdown = useMemo(() => {
    return productRedux?.map(obj => {
      return {
        label: obj?.name,
        value: obj?.id
      }
    })
  }, [[productRedux]])


  const addNewService = (data) => {
    setmemberData({
      ...memberData,
      services: [
        ...memberData?.services,
        { ...data }
      ]
    })
  }

  const addNewProduct = (data) => {
    setmemberData({
      ...memberData,
      products: [
        ...memberData?.products,
        { ...data }
      ]
    })
  }

  const removedSelectedItems = (id, type) => {

    if (type === "service") {
      setmemberData((prev) => ({
        ...prev,
        services: memberData?.services?.filter((itm) => itm.service !== id)
      }))
    }

    if (type === "product") {
      setmemberData((prev) => ({
        ...prev,
        products: memberData?.products?.filter((itm) => itm?.product !== id)
      }))
    }

  }

  useEffect(() => {
    const serviceResult = memberData?.services?.map((itm, i) => {
      return {
        duration: duration,
        service: itm?.service,
      }
    });

    setmemberData({ ...memberData, services: serviceResult })

  }, [duration])


  useEffect(() => {
    const prodcutResult = memberData?.products?.map((itm, i) => {
      return {
        percentage: product_percentage,
        product: itm?.product,
      }
    });

    setmemberData({
      ...memberData, products: prodcutResult
    })

  }, [product_percentage])

  useEffect(() => {
    if (allUinqueCurrencyList?.length == memberData?.currency_membership?.length) {
      setEditMemberError({
        ...editMemberError,
        price: '',
      });
    }
  }, [memberData?.currency_membership?.length, allUinqueCurrencyList?.length])

  //set currency and membership price object here
  const addNewItem = () => {
    setmemberData((prev) => ({
      ...prev,
      currency_membership: [
        ...prev?.currency_membership,
        { currency: selectedCurrency, price: price }
      ]
    }))
    setSelectedCurrency();
    setPrice()
  }

  //set selectedService and ServicePercentage object here
  const addNewServiceItem = () => {
    setmemberData((prev) => ({
      ...prev,
      services: [
        ...prev?.services,
        { service: selectedService, percentage: servicePercentage }
      ]
    }))
    setSelectedService();
    setServicePercentage()
  }

  // set selectedProduct and ProductPercentage object here
  const addNewProductItem = () => {
    setmemberData((prev) => ({
      ...prev,
      products: [
        ...prev?.products,
        { product: selectedProduct, percentage: productPercentage }
      ]
    }))
    setSelectedProduct();
    setProductPercentage()
  }

  // **************************** remove selected items *********************************
  const removedItem = (value, type) => {

    if ((type === "price")) {
      if (memberData?.currency_membership?.find((itm) => itm?.currency == value)) {
        setmemberData((prev) => ({
          ...prev,
          currency_membership: [...prev?.currency_membership?.filter((itm) => itm?.currency != value)],
        }));
      }
    } else {
      if (memberData?.currency_membership?.find((itm) => itm?.currency == value)) {
        setmemberData((prev) => ({
          ...prev,
          currency_membership: [...prev?.currency_membership?.map((itm) => {
            if (itm?.currency === value) {
              return {
                ...itm,
                is_deleted: true
              }
            }
            return itm
          })],
        }));
      }
    }

  };

  // **************************** remove selected Services *********************************

  const removedSelectedService = (value, type) => {
    if ((type === "servicePercentage")) {
      if (memberData?.services?.find((itm) => itm?.service === value)) {
        setmemberData((prev) => ({
          ...prev,
          services: [...prev?.services?.filter((itm) => itm?.service !== value)],
        }));
      }
    } else {
      if (memberData?.services?.find((itm) => itm?.service === value)) {
        setmemberData((prev) => ({
          ...prev,
          services: [...prev?.services?.map((itm) => {
            if (itm?.service === value) {
              return {
                ...itm,
                is_deleted: true
              }
            }
            return itm
          })],
        }));
      }
    }
  };

  // **************************** remove selected Products *********************************

  const removedSelectedProduct = (value, type) => {

    if ((type === "productPercentage")) {
      if (memberData?.products?.find((itm) => itm?.product == value)) {
        setmemberData((prev) => ({
          ...prev,
          products: [...prev?.products?.filter((itm) => itm?.product != value)],
        }));
      }
    } else {
      if (memberData?.products?.find((itm) => itm?.product == value)) {
        setmemberData((prev) => ({
          ...prev,
          products: [...prev?.products?.map((itm) => {
            if (itm?.product === value) {
              return {
                ...itm,
                is_deleted: true
              }
            }
            return itm
          })],
        }));
      }
    }

  };

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);


  const isInstallmentChangeHandler = (e) => {
    const { name, checked } = e.target;
    setmemberData({
      ...memberData,
      [name]: checked,
    });
  }


  const handlememberData = (e) => {
    const { name, value } = e.target;
    let trimmedValue = value;

    if (name === "description") {
      trimmedValue = value.slice(0, 150); // Trim the value to a maximum of 150 characters

      if (value.length > 150) {
        setErrors((prev) => ({
          ...prev,
          // description: "You can not add more than 150 characters",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          description: "",
        }));
      }
    }

    if (name === "term_condition") {
      trimmedValue = value.slice(0, 150); // Trim the value to a maximum of 150 characters

      if (value.length > 150) {
        setErrors((prev) => ({
          ...prev,
          // term_condition: "You can not add more than 150 characters",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          term_condition: "",
        }));
      }
    }
    setmemberData({
      ...memberData,
      [name]: trimmedValue,
    });
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    setEditMemberError({
      ...editMemberError,
      [name]: false,
    });
  };

  useEffect(() => {
    setmemberData({
      ...data,
      currency_membership: data?.currency_membership?.map(currency => currency),
    });
    setDuration(memberData?.services?.find(itm => itm?.service !== null)?.duration)
    setProduct_percentage(memberData?.products?.find(itm => itm?.product !== null)?.percentage)
  }, [editId]);

  useEffect(() => {
    if (allUinqueCurrencyList?.length == memberData?.currency_membership?.length) {
      setErrors((prev) => ({
        ...prev,
        price: ""
      }))
    }
  }, [memberData?.currency_membership])

  return (
    <>
      <div className="flex gap-6">
        <div className="relative">
          {index != 0 && (
            <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
          )}
          <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
            <div className="h-[1.8rem] w-[1.8rem] rounded-full  border border-primary flex items-center justify-center bg-white">
              <Svgs.Appointment_Bullet />
            </div>
          </div>
          <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-1/2 left-1/2 -translate-x-1/2"></div>
        </div>
        {editId === memberData?.id ? (
          <>
            <div className="mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4" ref={formRef}>
              <LoginInput
                name={"name"}
                required={true}
                title={selectedLanguage?.name == "English" ? "Membership Name" : translation[205]?.value ? translation[205]?.value : "Membership Name"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Membership Name" : translation[1730]?.value ? translation[1730]?.value : "Enter Membership Name"}
                value={name}
                onEnterSubmit={() => {
                  onUpdate(memberData);
                }}
                // charactersMaxLength={25}
                error={editMemberError?.name}
                onChange={(e) => {
                  handlememberData(e);
                }}
              />

              <Textarea
                required={true}
                name={"description"}
                value={description}
                // onChange={handlememberInput}
                onChange={handlememberData}
                onBlur={() =>
                  onBlurHandler({ description: memberData?.description })
                }
                error={errors?.description}
                title={selectedLanguage?.name == "English" ? "Membership Description" : translation[1167]?.value ? translation[1167]?.value : "Membership Description"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Membership Description" : translation[1168]?.value ? translation[1168]?.value : "Enter Membership Description"}
              />
              {memberData?.discount === "Free" ? (
                <>
                  <div className=" gap-4">
                    {/* <div className="col-span-2">
                      <Dropwdown
                        required={true}
                        name={"duration"}
                        value={duration}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setDuration(value)
                        }}
                        // onBlur={() => onBlurHandler({ duration: memberData?.duration })}
                        error={errors?.duration}
                        options={[
                          { label: "7 Days", value: "7" },
                          { label: "14 Days", value: "14" },
                          { label: "30 Days", value: "30" },
                        ]}
                        title={selectedLanguage?.name == "English" ? "Duration" : translation[112]?.value ? translation[112]?.value : "Duration"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Duration" : translation[115]?.value ? translation[115]?.value : "Select Duration"}
                      />
                    </div> */}
                    <div className="">
                      <Dropwdown
                        required={true}
                        name={"service"}
                        value={memberData?.services?.service}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          addNewService({ service: value })
                        }}
                        error={(submitButtonClick && memberData?.discount === "Free" && memberData?.services?.length == 0) && "At least select one service."}
                        options={servicesDropdown?.filter(({ value }) => (memberData?.services?.find(obj => obj?.service == value)?.service) != value)}
                        // title={selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}
                        title={selectedLanguage?.name == "English" ? "Free Services" : translation[1036]?.value ? translation[1036]?.value : "Free Services"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Service" : translation[89]?.value ? translation[89]?.value : "Select Service"}
                      />

                    </div>
                  </div>

                  {<div className='flex items-center flex-wrap gap-2 py-3'>
                    {
                      memberData?.services && memberData?.services?.map((item) => {

                        let selected_service = serviceRedux?.find(srvc => srvc?.id == item?.service);
                        return (
                          selected_service?.name && <div className='flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]'>
                            <p className='text-primary text-sm font-bold'>{selected_service?.name}</p>
                            <span onClick={() => removedSelectedItems(item?.service, "service")}><Svgs.CirlceClose fill='#5679FF' /></span>
                          </div>
                        )
                      })
                    }
                  </div>
                  }
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-2">
                      <FloatInput
                        required={true}
                        name={"percentage"}
                        // value={memberData?.discount_membership?.filter(itm => itm?.product !== null)?.map(obj => obj?.percentage)}
                        value={product_percentage}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setProduct_percentage(value)
                        }}
                        isPercentage={true}
                        // onBlur={() => onBlurHandler({ percentage: percentage })}
                        error={(submitButtonClick && !product_percentage) ? true : false}
                        title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[1172]?.value ? translation[1172]?.value : "Discount Percentage"}
                        placeholder={"%"}
                      />
                    </div>
                    <div className="col-span-3">
                      <Dropwdown
                        required={true}
                        name={"product"}
                        value={memberData?.products?.product}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          addNewProduct({ percentage: product_percentage, product: value })
                        }}
                        // onBlur={() => onBlurHandler({ product: .product })}
                        // error={errors?.product}
                        error={(submitButtonClick && memberData?.discount === "Free" && memberData?.products?.length == 0) && "At least select one product."}
                        options={productDropdown?.filter(({ value }) => (memberData?.products?.find(obj => obj?.product == value)?.product) != value)}
                        title={selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Products" : translation[1025]?.value ? translation[1025]?.value : "Select Products"}
                      />
                    </div>
                  </div>
                  {<div className='flex items-center flex-wrap gap-2 py-3'>
                    {
                      memberData?.products && memberData?.products?.map((item) => {
                        let selected_product = productRedux?.find(prdct => prdct?.id == item?.product);
                        return (
                          selected_product?.name && <div className='flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]'>
                            <p className='text-primary text-sm font-bold'>{selected_product?.name}</p>
                            <span onClick={() => removedSelectedItems(item?.product, "product")}><Svgs.CirlceClose fill='#5679FF' /></span>
                          </div>
                        )
                      })
                    }
                  </div>
                  }
                </>
              ) : (
                <>
                  {/* service list start */}

                  {/* {services && services?.map((srvc, i) => {
        return ( */}
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-2">
                      <FloatInput
                        required={true}
                        name={"percentage"}
                        value={servicePercentage}
                        disabled={serviceRedux?.filter((itm) => !memberData?.services?.find(({ service }) => service === itm?.id))?.length == 0}
                        onChange={(e) => setServicePercentage(e.target.value)}
                        isPercentage={true}
                        setErrors={setErrors}
                        // onBlur={() => onBlurHandler({ percentage: srvc?.percentage })}
                        error={(errors?.percentage && !servicePercentage && memberData?.services?.length == 0) || errors?.percentage}
                        title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[1172]?.value ? translation[1172]?.value : "Discount Percentage"}
                        placeholder={"%"}
                      />
                    </div>
                    <div className="col-span-3">
                      <Dropwdown
                        required={true}
                        name={"service"}
                        value={selectedService}
                        onChange={(e) => setSelectedService(e.target.value)}
                        // onBlur={() => onBlurHandler({ service: srvc?.service })}
                        // error={errors?.service}
                        options={serviceRedux
                          ?.filter(
                            (itm) =>
                              !memberData?.services?.find(
                                ({ service }) => service === itm?.id
                              )
                          )
                          ?.map((srvc) => {
                            return {
                              label: srvc?.name,
                              value: srvc?.id
                            }
                          })}
                        error={(submitButtonClick && memberData?.discount === "Limited" && memberData?.services?.length == 0) && "At least select one service."}
                        title={selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Service" : translation[89]?.value ? translation[89]?.value : "Select Service"}
                      />
                    </div>

                    <div className="col-span-1 flex items-end">
                      <LoginBtn
                        className={(selectedService && servicePercentage) && "shake-bottom"}
                        disabled={!selectedService || !servicePercentage}
                        onClick={() => {
                          addNewServiceItem();
                        }}
                      >
                        <div className="flex items-center gap-2 text-sm">
                          <Svgs.Plus />
                          <p>
                            {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                          </p>
                        </div>
                      </LoginBtn>
                    </div>
                  </div>
                  {memberData?.services?.length > 0 && (
                    <div className="flex items-center flex-wrap gap-2">
                      {memberData?.services &&
                        memberData?.services?.filter(itm => itm?.service !== null)?.map((item, i) => {
                          const srv = serviceList?.find(
                            (itm) => itm?.id === item?.service
                          );
                          return (
                            !item?.is_deleted && (
                              <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                <p className="text-primary text-sm font-bold">{`${item?.percentage} % on ${srv?.name}`}</p>
                                <span
                                  onClick={() =>
                                    removedSelectedService(item?.service, "servicePercentage")
                                  }
                                >
                                  <Svgs.CirlceClose fill="#5679FF" />
                                </span>
                              </div>
                            )
                          );
                        })}
                    </div>
                  )}
                  {/* )
    })} */}

                  {/* service list end */}

                  {/* product list start */}
                  <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-2">
                      <FloatInput
                        required={true}
                        name={"product_percentage"}
                        value={productPercentage}
                        onChange={(e) => setProductPercentage(e.target.value)}
                        disabled={productRedux
                          ?.filter(
                            (itm) =>
                              !products?.find(
                                ({ product }) => product === itm?.id
                              )
                          )?.length == 0}
                        isPercentage={true}
                        // onBlur={() => onBlurHandler({ percentage: prdct?.percentage })}
                        error={(errors?.product_percentage && !productPercentage && memberData?.products?.length == 0) || errors?.product_percentage}
                        title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[1172]?.value ? translation[1172]?.value : "Discount Percentage"}
                        placeholder={"%"}
                      />
                    </div>
                    <div className="col-span-3">
                      <Dropwdown
                        required={true}
                        name={"product"}
                        value={selectedProduct}
                        onChange={(e) => setSelectedProduct(e.target.value)}
                        // onBlur={() => onBlurHandler({ product: prdct?.product })}
                        error={(submitButtonClick && memberData?.discount === "Limited" && memberData?.products?.length == 0) && "At least select one product."}
                        options={productRedux
                          ?.filter(
                            (itm) =>
                              !products?.find(
                                ({ product }) => product === itm?.id
                              )
                          )
                          ?.map((prdct) => {
                            return {
                              label: prdct?.name,
                              value: prdct?.id
                            }
                          })}
                        title={selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Products" : translation[1025]?.value ? translation[1025]?.value : "Select Products"}
                      />
                    </div>

                    <div className="col-span-1 flex items-end">
                      <LoginBtn
                        className={(selectedProduct && productPercentage) && "shake-bottom"}
                        disabled={!selectedProduct || !productPercentage}
                        onClick={() => {
                          addNewProductItem();
                        }}
                      >
                        <div className="flex items-center gap-2 text-sm">
                          <Svgs.Plus />
                          <p>
                            {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                          </p>
                        </div>
                      </LoginBtn>
                    </div>
                  </div>
                  {memberData?.products?.length > 0 && (
                    <div className="flex items-center flex-wrap gap-2">
                      {memberData?.products &&
                        memberData?.products?.filter(itm => itm?.product !== null)?.map((item, i) => {
                          const prd = productList?.find(
                            (itm) => itm?.id === item?.product
                          );
                          return (
                            !item?.is_deleted && (
                              <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                                <p className="text-primary text-sm font-bold">{`${item?.percentage} % on ${prd?.name}`}</p>
                                <span
                                  onClick={() =>
                                    removedSelectedProduct(item?.product, "productPercentage")
                                  }
                                >
                                  <Svgs.CirlceClose fill="#5679FF" />
                                </span>
                              </div>
                            )
                          );
                        })}
                    </div>
                  )}
                  {/* )
        })
    } */}

                  {/* product list end */}
                </>
              )}
              <div className='grid grid-cols-6 gap-4 pb-6'>
                <div className='col-span-2'>
                  <FloatInput
                    required={true}
                    name={"price"}
                    value={price}
                    disabled={allUinqueCurrencyList?.length == memberData?.currency_membership?.length}
                    onChange={(e) => setPrice(e.target.value)}
                    // onBlur={() => {
                    //   if (!memberData?.price) {
                    //     setEditMemberError({
                    //       ...editMemberError,
                    //       price: true,
                    //     });
                    //   } else {
                    //     setEditMemberError({
                    //       ...editMemberError,
                    //       price: false,
                    //     });
                    //   }
                    // }}
                    error={errors?.price ? errors?.price
                      : editMemberError?.price ? editMemberError?.price : false}
                    title={selectedLanguage?.name == "English" ? "Selling Price" : translation[269]?.value ? translation[269]?.value : "Selling Price"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter Membership Price" : translation[1731]?.value ? translation[1731]?.value : "Enter Membership Price"}
                  />
                </div>

                <div className='col-span-3'>
                  <Dropwdown
                    required={true}
                    title="Currency"
                    name={"selectedCurrency"}
                    value={selectedCurrency}
                    onChange={(e) => setSelectedCurrency(e.target.value)}
                    placeholder="Select Currency"
                    options={allUinqueCurrencyList
                      ?.filter(
                        (itm) =>
                          !currency_membership?.find(
                            ({ currency }) => currency === itm?.currency?.id
                          )
                      )
                      .map((itm) => {
                        return {
                          label: itm?.currency?.code,
                          value: itm?.currency?.id,
                        };
                      })}
                  />
                </div>

                <div className='col-span-1 flex items-end'>
                  <LoginBtn
                    className={(selectedCurrency && price) && "shake-bottom"}
                    disabled={!selectedCurrency || !price}
                    onClick={() => {
                      addNewItem();
                    }}
                  >
                    <div className='flex items-center gap-2 text-sm'>
                      <Svgs.Plus />
                      <p>
                        {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                      </p>
                    </div>
                  </LoginBtn>
                </div>
              </div>
              {currency_membership?.length > 0 && (
                <div className="flex items-center flex-wrap gap-2">
                  {currency_membership &&
                    currency_membership?.map((item, i) => {
                      const crrncy = currencyList?.find(
                        (itm) => itm?.id === item?.currency
                      );
                      return (
                        !item?.is_deleted && (
                          <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                            <p className="text-primary text-sm font-bold">{`${item?.price} ${crrncy?.code}`}</p>
                            <span
                              onClick={() =>
                                removedItem(item?.currency, "price")
                              }
                            >
                              <Svgs.CirlceClose fill="#5679FF" />
                            </span>
                          </div>
                        )
                      );
                    })}
                </div>
              )}
              <div className='grid grid-cols-2 gap-4'>
                <Dropwdown
                  required={true}
                  name={"valid_for"}
                  value={valid_for}
                  onChange={handlememberData}
                  onBlur={() => onBlurHandler({ valid_for: valid_for })}
                  error={errors?.valid_for}
                  options={[
                    { label: '7 Days', value: '7 Days' },
                    { label: '14 Days', value: '14 Days' },
                    { label: '1 Month', value: '1 Months' },
                    { label: '2 Months', value: '2 Months' },
                    { label: '3 Months', value: '3 Months' },
                    { label: '4 Months', value: '4 Months' },
                    { label: '6 Months', value: '6 Months' },
                    { label: '8 Months', value: '8 Months' },
                    { label: '1 Years', value: '1 Years' },
                    { label: '18 Months', value: '18 Months' },
                    { label: '2 Years', value: '2 Years' },
                    { label: '5 Years', value: '5 Years' },
                  ]}
                  title={selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}
                  placeholder={selectedLanguage?.name == "English" ? "Select validity" : translation[1173]?.value ? translation[1173]?.value : "Select validity"}


                />
              </div>

              <CheckBox
                //  className={"w-full"}
                name="is_installment"
                onChange={isInstallmentChangeHandler}
                value={is_installment}
                checked={is_installment}
                label={"Membership available for installment"}
              />
              <Textarea
                required={true}
                name={"term_condition"}
                value={term_condition}
                onChange={handlememberData}
                onBlur={() => onBlurHandler({ term_condition: term_condition })}
                error={errors?.term_condition}
                title={selectedLanguage?.name == "English" ? "Terms & Conditions" : translation[1174]?.value ? translation[1174]?.value : "Terms & Conditions"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Terms & Conditions" : translation[1175]?.value ? translation[1175]?.value : "Enter Terms & Conditions"}


              />
              <LoginBtn
                text={selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                // disabled={
                //   !memberData?.name ||
                //   !memberData?.description ||
                //   !memberData?.term_condition ||
                //   // (memberData?.currency_membership?.length < allUinqueCurrencyList?.length) ||
                //   (memberData?.products?.length === 0) ||
                //   (memberData?.services?.length === 0) ||
                //   !memberData?.valid_for ||
                //   updateLoading
                // }
                disabled={updateLoading}
                loading={updateLoading}
                onClick={() => {
                  onUpdate(memberData, seteditId);
                }}
              />
              {/* </div> */}
            </div>
          </>
        ) : (
          <>
            <div className="mt-[2rem] rounded-xl w-full flex flex-col gap-4 border relative overflow-hidden">
              <div className="absolute bg-transparent -right-28 -top-24 border-[#ffffff50] border w-60 h-60 rounded-full"></div>
              <div className="absolute bg-transparent right-12 -top-48 border-[#ffffff50] border w-56 h-56 rounded-full"></div>
              <div className="absolute bg-transparent -right-8 -top-32 border-[#ffffff50] border w-56 h-56 rounded-full"></div>
              <div className={`rounded-lg px-6 py-4  ${(index % 4 == 0) ? 'vorcher-gradient-1' : (index % 3 == 0) ? 'vorcher-gradient-2' : (index % 2 == 0) ? 'vorcher-gradient-2' : 'vorcher-gradient-4'}`}>
                <div className="flex gap-2 flex-col">
                  <div className="flex items-center justify-between gap-4">
                    <h1 className="text-white font-semibold text-3xl">
                      {data?.name}
                    </h1>
                  </div>
                  <div className="text-white text-lg">
                    <div className="flex flex-col gap-2">
                      {data?.discount === "Free" ?
                        <p className="flex flex-col gap-1">
                          {selectedLanguage?.name == "English" ? "Get free services:" : translation[1178]?.value ? translation[1178]?.value : "Get free services:"} {''}{data?.services?.map((itm, i) => {
                            return ((i != 0 ? ',' : '') + itm?.service_name)
                          })}
                        </p> :
                        data?.services?.map((itm, i) => <p>
                          {selectedLanguage?.name == "English" ? "Get" : translation[299]?.value ? translation[299]?.value : "Get"} {itm?.percentage ? itm?.percentage : ""}  {selectedLanguage?.name == "English" ? "% off on service:" : translation[1179]?.value ? translation[1179]?.value : "% off on service:"} {itm?.service_name}
                        </p>)
                      }
                      {/* <p className="flex flex-col gap-1">
                        Get free services: {''}{data?.discount_membership?.filter(obj => obj?.service !== null)?.map((itm, i) => {
                          return ((i != 0 ? ',' : '') + itm?.service_name)
                        })}
                      </p> */}
                      <p>
                        {data?.products?.map((itm, i) => <p>
                          {selectedLanguage?.name == "English" ? "Get" : translation[299]?.value ? translation[299]?.value : "Get"} {itm?.percentage ? itm?.percentage : ""}  {selectedLanguage?.name == "English" ? "% off on" : translation[1180]?.value ? translation[1180]?.value : "% off on"} {itm?.product_name}
                        </p>)}
                      </p>
                      {/* <p className="flex flex-col gap-1">
                        Avail on {data?.total_number} purchases of{" "}
                        {data?.membership == "Service"
                          ? data?.service?.name
                          : data?.product?.name}
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-5 justify-end px-7 pb-3">
                {/* <p className="flex items-center gap-4">
                          <span className="font-bold">
                            {currency} {data?.price}{" "}
                          </span>
                          <span className="">including tax*</span>
                        </p> */}
                <p className="text-sm text-right">
                  {selectedLanguage?.name == "English" ? "Valid for:" : translation[1181]?.value ? translation[1181]?.value : "Valid for:"} {data?.valid_for}{" "}
                  {/* {data?.valid_for == "Days" ? "Days" : "Months"} */}
                </p>
                <div className="flex items-center gap-3">
                  {!employee ||
                    (employee &&
                      employeePermissions?.client_sharing?.includes(
                        "edit"
                      )) ? (
                    <div
                      className="h-[2.5rem] w-[2.5rem] border border-[#585972] rounded-full flex items-center cursor-pointer justify-center"
                      onClick={() => {
                        onEditClick(data?.id);
                        seteditId(data?.id);
                      }}
                    >
                      <Svgs.Edit fill="#585972" />
                    </div>
                  ) : (
                    ""
                  )}
                  {!employee ? (
                    <div
                      className="h-[2.5rem] w-[2.5rem] border border-[#585972] rounded-full flex items-center cursor-pointer justify-center"
                      onClick={() => {
                        onDelete(data?.id);
                        // setDelete(true)
                      }}
                    >
                      <Svgs.Delete fill="#585972" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

AddMembershipCard.defaultProps = {
  data: {},
  index: undefined,
  onEditClick: () => { },
  onDelete: () => { },
  onUpdate: () => { },
};

export default AddMembershipCard;
