import axios from "axios";
import { BASE_URL, create_working_shedule, delete_working_schedule, get_working_shedule, update_working_shedule } from "../api_variables";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { EMPLOYEES } from "Redux/TenantRedux/ActionTypes/employeeTypes";

export const createWeekend = (payload, access_token, success, fail) => async (dispatch) => {
    const data = convertDataToFormData(payload)
    try {
        const result = await axios.post(`${BASE_URL}${create_working_shedule}`, data, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.status_code == 201) {
            success && success(result)
            return;
        } else {
            fail && fail(result)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
};


export const updateWeekend = (payload, access_token, success, fail) => async (dispatch) => {
    const data = convertDataToFormData(payload)
    try {
        const result = await axios.put(`${BASE_URL}${update_working_shedule}`, data, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.data?.status_code == 200) {
            success && success(result)
            return;

        } else {
            fail && fail(result)
        }
    } catch (error) {
        fail && fail(error)
        return error;
    }
};

export const deleteWeekendApi = async (payload, setDeleteLoader, access_token) => {
    const data = convertDataToFormData(payload)
    try {
        const result = await axios.delete(`${BASE_URL}${delete_working_schedule}`, {
            headers: {
                Authorization: `Token ${access_token}`,
            },
            data,
        });
        setDeleteLoader && setDeleteLoader(false)
        return result;
    } catch (error) {
        setDeleteLoader && setDeleteLoader(false)
        return error;
    }
};


export const getWeekend = (payload, setLoader) => (dispatch) => {
    const query = buildQueryString(payload)
    setLoader && setLoader(true)
    let s_code;
    fetch(`${BASE_URL}${get_working_shedule}${query}`)
        .then((response) => {
            s_code = response.status;
            if (response.ok) {
                return response.json();
            }
        })
        .then((result) => {
            if (s_code == 200) {
                let data = result?.response?.employees;
                dispatch({
                    type: EMPLOYEES.GET_WEEKEND,
                    payload: data,
                });
                setLoader && setLoader(false)
            }
            setLoader && setLoader(false)
        })
        .catch((err) => {
            setLoader && setLoader(false)
            // console.log("err in get working schedule ::", err);
        });
};
