import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { MONTHS } from 'Constants/Data/Months';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { STORE_TARGET } from 'Redux/TenantRedux/ActionTypes/storeTargetTypes';
import { copyStoreTarget } from 'Adapters/Api/storeTarget';
function useCopyStore() {
    const [toMonth, setToMonth] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");
    const dispatch = useDispatch();
    const {
        handleSubmit,
        reset,
        watch,
        control,
        formState: { isValid, errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onBlur"
    })

    const onApplyFilter = async (data) => {
        setIsLoading(true);
        const body = {
            from_month: data?.from_month,
            to_month: data?.to_month,
        }
        // dispatch(
        //     copyStoreTarget(
        //         { ...body},
        //         access_token,
        //         (message) => {
        //             toast.success(message ? message : "Copy Store Target Created successfully!");
        //         },
        //         () => {
        //             toast.error("Something went wrong!")
        //         }

        //     )
        // )

    }
    
    const monthDropdown = useMemo(() =>{
        let months = MONTHS?.map((month, i) => {
          return {
            label: month,
            value:month,
          };
        })
        return months;
      },[])

    useEffect(()=>{
        let fromMonth = watch('from_month');
        if(fromMonth !== undefined || fromMonth !== null){
          setToMonth(monthDropdown.filter((obj)=>obj.value !== watch('from_month')))
        }
    },[watch('from_month')])
    return {
        control, reset,
        isValid, errors,toMonth,
        onApplyFilter, handleSubmit,monthDropdown,
    }
}

export default useCopyStore

export const validationSchema =yup.object().shape({
    from_month: yup.string().required("This field is required!").nullable(),
    to_month: yup.string().required("This field is required!").nullable(),

})
