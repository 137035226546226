import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { SALE_SERVICES } from 'Redux/TenantRedux/ActionTypes/saleServiceType';

function useCalculateTaxes(locationTax) {
    const dispatch = useDispatch()

    const calculateTaxes = useCallback((locationTax) => {
        let totalTax = 0;
        if (locationTax?.tax?.parent_tax[0]?.tax_type === "Group") {
            totalTax = locationTax?.tax?.parent_tax[0]?.parent_tax[0]?.tax_rate + locationTax?.tax?.parent_tax[0]?.parent_tax[1]?.tax_rate
            dispatch({
                type: SALE_SERVICES.ADD_TAXES,
                payload: totalTax
            })
        } else {
            if (locationTax?.tax?.parent_tax[0]?.tax_type === "Individual") {
                totalTax = locationTax?.tax?.parent_tax[0]?.tax_rate
                dispatch({
                    type: SALE_SERVICES.ADD_TAXES,
                    payload: totalTax
                })
            }
        }
    }, [locationTax?.id])


    return {
        calculateTaxes,
    }

}

export default useCalculateTaxes