import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { resetPassword } from '../../../../../Adapters/Api';
import useObjectKeys from '../../../../../Hooks/useObjectKeys';
import useValidations from '../../../../../Hooks/useValidations';

function useNewPasswordForm() {

    let active = false;
    const navigate = useNavigate()
    const { errors, setErrors, validation, onBlurHandler, removeWhiteSpacesFromStartAndEnd } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys();
    const [loading, setLoading] = useState(false);
    const [passwordlengthValidation, setPasswordlengthValidation] = useState(false)
    const userEmailOrMobileNumber = useSelector((state) => state.passwordData);

    const [newPassword, setNewPassword] = useState({
        password: "",
        confirmPassword: "",
    });
    const { password, confirmPassword } = newPassword;

    //enabled or disabled Next here
    if (!Object.values(newPassword).includes("")) {
        active = true;
    }

    //handle new password state here
    const handleChange = (e) => {
        const { name, value } = e.target;
        const result = removeWhiteSpacesFromStartAndEnd(value);
        setNewPassword((prev) => ({
            ...prev,
            [name]: result
        }));
        setErrors((prev) => ({
            ...prev,
            [name]: ""
        }));
    }

    // submit new password here 
    const onSubmitHandler = async ({ sent_to, method_type }) => {
        // check validation 
        setErrors(validation(newPassword));
        const result = validation(newPassword);

        if (objectKeyConvertToArray(result)?.length === 0) {
            setPasswordlengthValidation(true)
            if (password?.length >= 6 && confirmPassword?.length >= 6) {
                setLoading(true);
                if (password === confirmPassword) {
                    setLoading(true);

                    // get selected verification method from redux store
                    const { email, mobile_number, verifications } = userEmailOrMobileNumber;
                    let methodName = email ? { email: email } : { mobile_number: mobile_number };


                    // this commented code for real flow
                    //    let methodName = verifications === 1 ? {email: email} : {mobile_number : mobile_number};

                    //set object for backend
                    let forgetPasswordObj = {
                        password,
                        // ...methodName
                    }
                    if (method_type) {
                        forgetPasswordObj[method_type.toLowerCase()] = sent_to
                    }
                    const response = await resetPassword(forgetPasswordObj);

                    if (response?.status === 200) {
                        setLoading(false);
                        const successMessage = response?.data?.response?.message;
                        toast.success(successMessage, { toastId: "toast" });
                        // navigate('/auth/login');
                        navigate('/auth/login/?account_type=business')
                    } else {
                        setLoading(false);
                        toast.error(response?.response?.data?.response.message, { toastId: "toast" })
                    }


                } else {
                    toast.error('Password does not match', { toastId: "toast" })
                    setErrors((prev) => ({
                        ...prev,
                        confirmPassword: "password is not match"
                    }));
                    setLoading(false);
                }
            }
        }
    }

    return {
        newPassword,
        onBlurHandler,
        handleChange,
        onSubmitHandler,
        errors,
        loading,
        active,
        passwordlengthValidation
    }
}

export default useNewPasswordForm
