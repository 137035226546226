/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Svgs from "Assets/svgs";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";

function CommissionItem({
  itm,
  toggleModal,
  onEditStateManageHandler,
  setDelete,
  setDeleteId,
  index
}) {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <div>
      <div className={`odd-bg text-[#3A3A3A] transition-all `}>
        <div className="px-12 py-3 inline-grid xl:grid gap-3 grid-cols-[repeat(5,minmax(145px,1fr))] min-w-full w-fit">
          {/* <div
            className="flex items-center cursor-pointer"
            onClick={() => toggleModal(itm)}
          >
            <p className="text-sm">{itm?.employee?.employee_id}</p>
          </div> */}
          <div
            className={`before-left-arrow cursor-pointer`}
            onClick={() => toggleModal(itm)}
          >
            <div className="flex items-center gap-3">
              {itm?.employee?.image ?
                <img
                  src={itm?.employee?.image}
                  className="h-[2.8125rem] w-[2.8125rem] object-cover rounded-full"
                />
                :
                <LabelAvatar iconLabel={itm?.employee?.full_name} index={index} className="!h-[2.8125rem] !w-[2.8125rem] !rounded-full" />
              }
              <p className="text-sm">
                {itm?.employee?.full_name ? itm?.employee?.full_name : "----"}
              </p>
            </div>
          </div>
          {/* <div className="flex items-center">
            <p className="text-sm">
              {itm?.commission_cycle ? itm?.commission_cycle : "----"}
            </p>
          </div> */}
          <div className="flex items-center">
            <p className="text-sm">
              {
                itm?.category_comission?.find(
                  (obj) => obj.category_comission === "Service"
                )?.commission_percentage
              }{" "}
              {
                itm?.category_comission?.find(
                  (obj) => obj.category_comission === "Service"
                )?.symbol
              }
            </p>
          </div>
          <div className="flex items-center">
            <p className="text-sm">
              {
                itm?.category_comission?.find(
                  (obj) => obj.category_comission === "Retail"
                )?.commission_percentage
              }{" "}
              {
                itm?.category_comission?.find(
                  (obj) => obj.category_comission === "Retail"
                )?.symbol
              }
            </p>
          </div>
          <div className="flex items-center">
            <p className="text-sm">
              {
                itm?.category_comission?.find(
                  (obj) => obj.category_comission === "Voucher"
                )?.commission_percentage
              }{" "}
              {
                itm?.category_comission?.find(
                  (obj) => obj.category_comission === "Voucher"
                )?.symbol
              }
            </p>
          </div>
          <div className="flex items-center gap-2">
            {!employee ||
              (employee &&
                employeePermissions?.employee_commission?.includes("edit")) ? (
              <div
                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center cursor-pointer"
                onClick={() => {
                  onEditStateManageHandler(itm?.id);
                }}
              >
                <Svgs.Pen />
              </div>
            ) : (
              ""
            )}
            {!employee ? (
              <div
                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center cursor-pointer"
                onClick={() => {
                  setDelete(true);
                  setDeleteId(itm?.id);
                }}
              >
                <Svgs.Delete />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommissionItem;
