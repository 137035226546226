import { FINANCIAL_SETTINGS } from "../../ActionTypes/financialSettings";

const financialSettings = {
    paymentMethod: [],
    businessTaxs: [],
    businessTaxSetting: '',
    refundTimePeriod: []
}

const FinancialSettingsReducer = (state = financialSettings, action) => {

    const { type, payload } = action;

    switch (type) {
        case FINANCIAL_SETTINGS.GET_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: payload,
            };

        case FINANCIAL_SETTINGS.ADD_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: [payload, ...state.paymentMethod],
            };


        case FINANCIAL_SETTINGS.DELETE_PAYMENT_METHOD:
            let result = state?.paymentMethod?.filter((item) => item.id !== payload);
            return {
                ...state,
                paymentMethod: [...result],
            };


        case FINANCIAL_SETTINGS.UPDATE_PAYMENT_METHOD:

            let updateList = state.paymentMethod.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })

            return {
                ...state,
                paymentMethod: [...updateList],
            };


        // ********************************************* BUSINESS_TAX  ***************************************************



        case FINANCIAL_SETTINGS.GET_BUSINESS_TAX:
            return {
                ...state,
                businessTaxs: payload,
            };

        case FINANCIAL_SETTINGS.GET_BUSINESS_TAX_SETTING:
            return {
                ...state,
                businessTaxSetting: payload,
            };


        case FINANCIAL_SETTINGS.ADD_BUSINESS_TAX:
            return {
                ...state,
                businessTaxs: [payload, ...state.businessTaxs],
            };


        case FINANCIAL_SETTINGS.UPDATE_BUSINESS_TAX:

            let updatedList = state.businessTaxs.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })

            return {
                ...state,
                businessTaxs: [...updatedList],
            };


        case FINANCIAL_SETTINGS.DELETE_BUSINESS_TAX:
            let res = state?.businessTaxs?.filter((item) => item.id !== payload);
            return {
                ...state,
                businessTaxs: [...res],
            };

        case FINANCIAL_SETTINGS.ADD_REFUND_PERIOD:
            return {
                ...state,
                refundTimePeriod: [
                    payload,
                    ...state.refundTimePeriod
                ],
            };

        case FINANCIAL_SETTINGS.GET_REFUND_PERIOD:
            return {
                ...state,
                refundTimePeriod: payload
            };

        case FINANCIAL_SETTINGS.DELETE_REFUND_PERIOD:
            const deleted = state?.refundTimePeriod?.filter((item) => item.id !== payload);
            return {
                ...state,
                refundTimePeriod: [...deleted],
            };

        case FINANCIAL_SETTINGS.UPDATE_REFUND_PERIOD:
            let updatedRefund = state.refundTimePeriod.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })
            return {
                ...state,
                refundTimePeriod: [...updatedRefund],
            };

        default:
            return state;
    }
};

export default FinancialSettingsReducer;


