import { ATTENDANCE } from "../../ActionTypes/attendanceTypes"


export const getAttendance = (data) => {
    return {
        type: ATTENDANCE.GET_ATTENDANCE,
        payload: data
    }
}

export const getAttendanceWithoutPagination = (data) => {
    return {
        type: ATTENDANCE.GET_ATTENDANCE_WITHOUT_PAGINATION,
        payload: data
    }
}


export const removeAttendance = (data) => {
    return {
        type: ATTENDANCE.DELETE_ATTENDANCE,
        payload: data
    }
}




// export const addAttendance = (data) => {
//     return {
//         type: ATTENDANCE.ADD_ATTENDANCE,
//         payload: data
//     }
// }

// export const editAttendance = (data) => {
//     return {
//         type: ATTENDANCE.UPDATE_ATTENDANCE,
//         payload: data
//     }
// }