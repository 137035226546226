import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";


import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import { useLocation } from "new-components/location/helper";

import { PRODUCT } from "Redux/TenantRedux/ActionTypes/productTypes/index";

import Svgs from "Assets/svgs";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Counter from "Components/TenantApp/Elements/Counter";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import { getBusinessTax } from "Adapters/Api/financialSettings";
import { getLocationTax } from "Adapters/Api/saleServices";
// import { getEmployeeDropDownListPagination } from "Adapters/Api/employee";
// import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";


// let employeeTimeOutId = undefined
const AddToCart = ({ setOpenCart, OpenCart }) => {
  const navigate = useNavigate();
  const taxTypeForGroup = localStorage.getItem('taxTypeForGroup')
  const dispatch = useDispatch();
  const { selected_location } = useLocation();
  const { cartItem, saleService, utility } = useSelector((state) => state);
  const location_currency = utility?.currency?.location_currency;
  const { locationDetail } = useLocation();
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const active_currency_id = locationDetail?.currency?.id
  const cartItems = cartItem.cart_items;
  const cart_total = cartItem.cart_total;
  const required_amount = cartItem.required_amount;
  const total_tax = saleService?.location_tax?.tax ? saleService?.total_tax : 0;
  const singleTaxName = state?.saleService?.location_tax?.tax ? state?.saleService?.location_tax?.tax?.parent_tax[0]?.name : ""
  const employeeRedux = state?.employee?.employeesSalesCart;

  const [productEmployees, setProductEmployees] = useState({});
  const [taxType, setTaxType] = useState()
  const [taxForGroup, setTaxForGroup] = useState(null)
  const [taxApplied, setLocationTax] = useState(0)
  const [tax, setTax] = useState()


  useEffect(() => {
    if (state.business.business?.id) {
      dispatch(getBusinessTax(state.business.business?.id))
    }

  }, [state.business.business, state.business.business?.id])

  const setProductEmployee = (productId, employee) => {
    const employee_id = employeeRedux?.find(empl => empl?.id === employee)?.id
    setProductEmployees({
      ...productEmployees,
      [productId]: employee,
    });

    dispatch({
      type: "ADD_EMPLOYEE_IN_CART_ITEMS",
      payload: {
        id: productId,
        employee: employee,
        employee_id: employee_id
      },
    });
  };

  useEffect(() => {
    if (OpenCart && employeeRedux?.length == "1") {
      cartItems?.map(itm => { setProductEmployee(itm?.id, employeeRedux[0]?.id) })
    }
  }, [employeeRedux, employeeRedux?.length, OpenCart])

  const proceedToCheckout = () => {
    const result = cartItems?.map(({ id, quantity, selection_type, price, disount_price, employee, employee_id }) => ({
      id,
      quantity,
      price,
      disount_price,
      selection_type,
      employee: employee, // Get selected employee for the current product
      employee_id: employee_id
    }));

    dispatch({
      type: PRODUCT.STORE_SELECTED_IDS_INTO_REDUX,
      payload: result,
    });
    navigate("/dashboard/sales/quick-sales/checkout");
  };

  //remove item form cart
  // const removedFromCart = (item) => {
  //   dispatch({
  //     type: "REMOVE_FROM_CART",
  //     payload: item,
  //   });
  // };

  const removedFromCart = (item, index) => {
    if (item.employee !== "") {
      item.employee = "";
    }

    // Dispatch the REMOVE_FROM_CART action with the updated cart items array
    dispatch({
      type: "REMOVE_FROM_CART",
      payload: item,
    });

    // Reset the productEmployees state for the item that was just removed
    if (item.employee === "") {
      setProductEmployees({
        ...productEmployees,
        [item.id]: "",
      });
    }
  };

  // calculate total price
  const total_price = () => {
    let total_price = cart_total ? cart_total : 0;
    if (cartItem?.selected_promotion_type == "Bundle_Fixed_Service") {
      total_price -= cart_total
    }

    if (cartItem?.selected_promotion_type == "Fixed_Price_Service") {
      total_price -= cart_total
    }

    cartItems?.filter(({ price, full_price, currency_membership, quantity, gift_card_details, discount_price, installment_price }) => {
      if (discount_price || discount_price == 0) {
        total_price += discount_price * quantity
      } else if (installment_price) {
        total_price += installment_price * quantity
      }
      else if (price || full_price || currency_membership || gift_card_details) {
        total_price += price
          ? price * quantity
          : full_price
            ? full_price * quantity
            : currency_membership
              ? currency_membership?.find(itm => itm?.currency === active_currency_id)?.price * quantity
              : gift_card_details
                ? gift_card_details?.find(itm => itm?.currencies === active_currency_id)?.price * quantity
                : 0
      }
    });

    return total_price;
  };

  // calculate total quantity
  const total_quantity = () => {
    let total_quantity = 0;
    cartItems?.filter(({ quantity, full_price, currency_membership }) => {
      if (quantity || full_price || currency_membership) {
        total_quantity += quantity ? quantity : 0;
      }
    });

    return total_quantity;
  };

  useEffect(() => {
    let tax_amount = 0

    let location_taxes = []
    let allTaxes = []
    if (state.financialSettings.businessTaxs.length > 0) {
      location_taxes = state.financialSettings.businessTaxs.filter(itm => itm.tax_type == 'Location' && itm?.location?.id == selected_location)
      setTaxType(location_taxes[0]?.parent_tax[0]?.tax_type)
    }
    if (location_taxes?.length > 0) {
      allTaxes = location_taxes[0]?.parent_tax[0]?.parent_tax
      setTaxForGroup(location_taxes[0]?.parent_tax[0]?.parent_tax)
    }

    if (taxType == 'Group') {
      let tax1Price = 0
      let tax2Price = 0
      let totalPriceWithTax1 = 0
      if (taxTypeForGroup == 'Seperately') {
        tax1Price = (allTaxes[0]?.tax_rate * (total_price())) / 100
        totalPriceWithTax1 = +tax1Price + (+total_price())
        tax2Price = allTaxes[1]?.tax_rate * totalPriceWithTax1 / 100
        tax_amount = +tax1Price + +tax2Price
        allTaxes?.forEach((tx, i) => {
          if (i == 0) {
            tx.value = tax1Price
          } else {
            tx.value = tax2Price
          }
        })
      } else {
        const totalCombinedTax = allTaxes?.reduce(function (total, obj) {
          return total + +(obj?.tax_rate);
        }, 0)
        tax1Price = (allTaxes[0]?.tax_rate * (total_price())) / 100
        tax2Price = allTaxes[1]?.tax_rate * (total_price()) / 100
        allTaxes?.forEach((tx, i) => {
          if (i == 0) {
            tx.value = tax1Price
          } else {
            tx.value = tax2Price
          }
        })
        tax_amount = (+totalCombinedTax * (total_price())) / 100
      }
    }
    else {
      let tax_perc = location_taxes[0]?.parent_tax[0]?.tax_rate
      setTax(tax_perc)
      tax_amount = (tax_perc * (total_price())) / 100
    }

    setLocationTax(+tax_amount)
    // setCalculatedTax(+tax_amount)
  }, [tax, total_price(), state.financialSettings.businessTaxs, state.financialSettings.businessTaxs.length, taxTypeForGroup, selected_location, taxType])

  useEffect(() => {
    if (selected_location) {
      dispatch(getLocationTax(selected_location))
    }
  }, [selected_location])

  let discountPrice = (itm) => {
    let price = itm?.installment_price ? itm?.installment_price
      : (itm?.discount_price || itm?.discount_price == 0) ? itm?.discount_price
        : itm?.price
          ? itm?.price
          : itm?.full_price
            ? itm?.full_price
            : itm?.currency_membership
              ? itm?.currency_membership?.find(itm => itm?.currency === active_currency_id)?.price
              : itm?.gift_card_details
                ? itm?.gift_card_details?.find(itm => itm?.currencies === active_currency_id)?.price
                : 0

    price = price?.toString(); // Convert price to string
    price = (+price)?.toFixed(2)
    return price
  }

  let simplePrice = (itm) => {
    let price = itm?.installment_price ? itm?.installment_price : itm?.price
      ? itm?.price?.toFixed(2)
      : itm?.full_price
        ? itm?.full_price?.toFixed(2)
        : itm?.currency_membership
          ? itm?.currency_membership?.find(itm => itm?.currency === active_currency_id)?.price?.toFixed(2)
          : itm?.gift_card_details
            ? itm?.gift_card_details?.find(itm => itm?.currencies === active_currency_id)?.price?.toFixed(2)
            : 0
    price = price?.toString(); // Convert price to string
    price = (+price)?.toFixed(2)
    return price
  }

  return (
    <>
      <div className="">
        <div
          className="relative"
          onClick={() => {
            setOpenCart(!OpenCart);
          }}
        >
          <LoginBtn text={selectedLanguage?.name == "English" ? "Cart" : translation[236]?.value ? translation[236]?.value : "Cart"} />

          <div className="select-none absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 h-[1.3rem] w-[1.3rem] rounded-full text-xs font-semibold bg-primary text-white flex items-center justify-center">
            {total_quantity()}
          </div>
        </div>
      </div>
      {OpenCart && (
        <div className="fixed inset-0 z-[12312321] bg-black/30">
          <style>
            {`
                    body{
                        overflow:hidden
                    }
                `}
          </style>
          <div className="bg-white w-[25rem] h-screen overflow-auto ml-auto p-[1.5rem] flex flex-col gap-5 relative slide-in-right">
            <div className="flex items-center justify-between">
              <h1 className="text-xl font-bold text-primary">
                {selectedLanguage?.name == "English" ? "Order List" : translation[237]?.value ? translation[237]?.value : "Order List"}
              </h1>
              <div className="flex justify-end">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setOpenCart(!OpenCart);
                  }}
                >
                  <Svgs.Close fill="#333" />
                </div>
              </div>

            </div>

            {cartItems?.length > 0 ? (
              <>
                <div className="overflow-auto">
                  {cartItems?.map((itm, index) => {
                    return (
                      <div className="border-b py-6 ">
                        <div className="grid grid-cols-5 gap-3">
                          <div className="col-span-3">
                            <div className="flex items-center gap-2">
                              <div>
                                <div className="h-[4rem] w-[4rem] rounded-full border overflow-hidden flex ">
                                  {itm?.selection_type === "SERVICE" ? (
                                    itm?.image ?
                                      <img
                                        src={itm?.image}
                                      />
                                      :
                                      <CircleIcon size={'4rem'}>
                                        <Svgs.NewService />
                                      </CircleIcon>
                                  ) : itm?.selection_type === "VOUCHER" ? (
                                    <CircleIcon size={'4rem'}>
                                      <Svgs.Gift width={"3.5625rem"} height={"2.75rem"} />
                                    </CircleIcon>
                                  ) : itm?.selection_type === "MEMBERSHIP" ? (
                                    <CircleIcon size={'4rem'}>
                                      <Svgs.MembershipIcon width={"3.5625rem"} height={"2.75rem"} />
                                    </CircleIcon>
                                  ) : itm?.selection_type === "INSTALLMENT_MEMBERSHIP" ? (
                                    <CircleIcon size={'4rem'}>
                                      <Svgs.MembershipIcon width={"3.5625rem"} height={"2.75rem"} />
                                    </CircleIcon>
                                  ) : itm?.selection_type === "GIFT_CARD" ? (
                                    <CircleIcon size={'4rem'}>
                                      <Svgs.MembershipIcon width={"3.5625rem"} height={"2.75rem"} />
                                    </CircleIcon>
                                  ) : itm?.selection_type === "PRODUCT" ? (
                                    <img
                                      src={`${itm?.selection_type === "PRODUCT"
                                        ? itm?.cover_image
                                        : itm?.image ? itm?.image
                                          // ? allProducts?.find(prod => prod?.name == itm?.name)?.cover_image
                                          : "https://images.unsplash.com/photo-1474511320723-9a56873867b5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YW5pbWFsfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
                                        }`}
                                      className="h-full w-full"
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-col text-sm">
                                <h2 className="font-semibold text-primary">
                                  {itm?.name ? itm?.name : itm?.title}
                                </h2>
                                <p className="font-semibold text-[#5679FF]">
                                  {simplePrice(itm)}  {location_currency ? location_currency : ""}
                                </p>
                                {
                                  (itm.discount_price || itm?.discount_price == 0) && (
                                    <>
                                      {selectedLanguage?.name == "English" ? "Discount Price" : translation[245]?.value ? translation[245]?.value : "Discount Price"}
                                      <p className="font-semibold text-primary">
                                        {itm?.discount_price?.toFixed(2)}  {location_currency ? location_currency : ""}
                                      </p>
                                    </>
                                  )
                                }

                              </div>
                            </div>
                          </div>
                          <div className="col-span-1 flex items-center justify-center">
                            {itm?.quantity} X{" "}
                            {discountPrice(itm)}
                          </div>
                          <div className="col-span-1 flex items-center justify-center">
                            <div
                              className="cursor-pointer"
                              onClick={() => removedFromCart(itm, index)}
                            >
                              <Svgs.Delete_2 />
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 flex justify-end items-center gap-2">
                          <p className="text-[#A1A1A1]">
                            {selectedLanguage?.name == "English" ? "by" : translation[240]?.value ? translation[240]?.value : "by"}
                          </p>
                          <Dropwdown
                            title=""
                            placeholder={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                            value={productEmployees[itm.id] || cartItems.find(item => item.id === itm.id)?.employee || ""}
                            showTitle={false}
                            onChange={(e) => setProductEmployee(itm.id, e.target.value)}
                            options={employeeRedux?.map((empl, ind) => {
                              return {
                                label: empl.full_name,
                                value: empl.id,
                                otherData: {
                                  image: empl?.image,
                                  DataOne: empl.email,
                                  showImage: true,
                                  index: ind
                                },
                                email: empl.email,
                                mobile_number: empl.mobile_number
                              };
                            })}
                            filterOption={(option, inputValue) => {
                              const { mobile_number, label, email } = option?.data
                              const normalizedInput = inputValue ? inputValue?.toLowerCase() : '';
                              return label?.toLowerCase()?.includes(normalizedInput)
                                || (mobile_number && mobile_number?.includes(normalizedInput))
                                || (email && email?.includes(normalizedInput));
                            }}
                          />
                          {!itm?.is_installment &&
                            <Counter
                              data={itm}
                              value={itm?.quantity}
                              key={itm?.id}
                            />
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flex flex-col gap-2 text-sm  font-bold">
                  <div className="flex items-center justify-between w-full">
                    <h1 className="text-[#A1A1A1]">
                      {selectedLanguage?.name == "English" ? "Sub total" : translation[242]?.value ? translation[242]?.value : "Sub total"}
                    </h1>
                    <h1 className="text-primary text-base">
                      {total_price().toFixed(2)}  {location_currency ? location_currency : ""}
                    </h1>
                  </div>
                  {taxType == 'Group' ?
                    taxForGroup?.map((itm, index) => {
                      return (
                        <div key={index} className='flex items-center justify-between gap-4 text-sm '>
                          <p className='font-semibold'>{itm?.name} ( {itm?.tax_rate}% )</p>
                          <p className='text-[#A1A1A1] flex items-center gap-2'>
                            <span>{itm?.value?.toFixed(2)} {location_currency}</span>
                          </p>
                        </div>
                      )
                    })
                    : tax > 0 &&
                    <div className='flex items-center justify-between gap-4 text-sm '>
                      {/* <p className='font-semibold'>{tax_name} ( {tax_percentage}% )</p> */}
                      <p className='font-semibold'>{singleTaxName} ({tax}%)</p>
                      <p className='text-[#A1A1A1] flex items-center gap-2'>

                        <span>
                          {/* {(tax * taxFreeAmount) / 100} {location_currency} */}
                          {taxApplied?.toFixed(2)} {location_currency}
                        </span>
                        {/* <span>{location_currency}. {loc_tax}</span> */}
                      </p>
                    </div>
                  }
                  {/* </h1>
                  </div> */}
                  <div className="flex items-center justify-between w-full">
                    <h1 className="text-[#A1A1A1]">
                      {selectedLanguage?.name == "English" ? "Total Amount" : translation[183]?.value ? translation[183]?.value : "Total Amount"}
                    </h1>
                    <h1 className="text-primary text-base">
                      {" "}
                      {((+total_price()) + (taxApplied ? +taxApplied : 0))?.toFixed(2)} {location_currency ? location_currency : ""}

                      {/* {(((total_tax * total_price()) / 100) + total_price()).toFixed(2)} {location_currency ? location_currency : ""} */}
                    </h1>
                  </div>
                </div>
              </>
            ) : (
              <div className="h-[20rem] flex items-center justify-center">
                <div className="text-center">
                  <div className="w-fit mx-auto">
                    <Svgs.NotFoundIcon />
                  </div>
                  <h1 className="font-semibold text-lg normal-case">
                    {selectedLanguage?.name == "English" ? "Cart is empty" : translation[238]?.value ? translation[238]?.value : "Cart is empty"} !
                  </h1>
                </div>
              </div>
            )}
            <div className="flex-1 pt-[0.5rem] gap-2 flex items-end justify-between xl:mb-0 mb-[6rem]">
              <LoginBtn
                onClick={() => {
                  setOpenCart(!OpenCart)
                  // navigate("/dashboard/sales/quick-sales?active_tab=products")
                }}
                text={selectedLanguage?.name == "English" ? "Add more items" : translation[1287]?.value ? translation[1287]?.value : "Add more items"}
              />
              <LoginBtn
                disabled={(!cartItems?.every((item) => item.employee)
                  || cartItems?.some((item) => item.employee === "")
                  || cartItems?.length === 0
                  || total_price() < required_amount)
                  || (cartItems?.some((item) => item.buy_retail_redemption && item.buy_retail_redemption == true) &&
                    !cartItems?.some((item) => item.selection_type === "PRODUCT"))
                  ? true : false}
                onClick={proceedToCheckout}
                text={selectedLanguage?.name == "English" ? "Proceed to Checkout" : translation[239]?.value ? translation[239]?.value : "Proceed to Checkout"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddToCart;
