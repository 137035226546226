/* eslint-disable react/jsx-pascal-case */
import Svgs from "Assets/svgs";
import CheckBox from "Components/TenantApp/Elements/Checkbox/CheckBox";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import I_Info_Icon from "Components/TenantApp/Elements/I_Info_Icon";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import SelectedOption from "Components/TenantApp/Elements/SelectedOption";
import ToggleWithText from "Components/TenantApp/Elements/ToggleWithText";
import { useAddPurchaseDiscount } from "./helper";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import { useMemo } from "react";
import moment from "moment";
import FloatInput from "Components/TenantApp/Elements/FloatInput";

const AddDiscount = () => {
  const { errors, toggleDay, toggleStore, discount, loading, locationDropdown, isEdit, serviceDropdown, productDropdown, choices,
    dateRangeCheck, validateBlockDate, handleChange, onBlurHandler, navigate, handlePurchaseDiscount, addStoreRestriction,
    filterStoreRestriction, onSubmit, addBlockDates, filterBlockDate, setToggleDay, setToggleStore, handleDayChecklist, toggleblockdate,
    setToggleBlockDate, translation, selectedLanguage, formRef
  } = useAddPurchaseDiscount();
  const {
    purchaseDiscount,
    dateRestriction,
    blockDate,
    blockDates,
    promotion_name,
    storeRestriction,
    storeRestrictions,
    dayRestrictions,
  } = discount;

  var minEndDateLimit = useMemo(() => {
    return moment(dateRestriction.startDate).add(1, 'day').format('YYYY-MM-DD')
  }, [dateRestriction.startDate]);

  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
      <Breadcrumb
        first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
        url={'/dashboard/clients/'}
        second={selectedLanguage?.name == "English" ? "Discount & Promotions" : translation[638]?.value ? translation[638]?.value : "Discount & Promotions"}
        secondurl={'/dashboard/clients/discounts-promotions'}
        third={selectedLanguage?.name == "English" ? " Purchase specific product/service and Get Discount for other" : translation[991]?.value ? translation[991]?.value : " Purchase specific product/service and Get Discount for other"}
      />
      <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

        <div className="flex items-center gap-4 justify-between w-full">
          <div className="flex items-center gap-3">
            <Svgs.Back link="/dashboard/clients/discounts-promotions" />
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Purchase specific product/service and Get Discount for other" : translation[991]?.value ? translation[991]?.value : "Purchase specific product/service and Get Discount for other"}
            </h2>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:w-[40vw]" ref={formRef}>
        <div className="border rounded-lg p-3 flex flex-col gap-3">
          <LoginInput
            name="promotion_name"
            className='!w-fit'
            title={selectedLanguage?.name == "English" ? "Promotion Name" : translation[883]?.value ? translation[883]?.value : "Promotion Name"}
            value={promotion_name}
            placeholder={selectedLanguage?.name == "English" ? "Enter Promotion Name" : translation[1732]?.value ? translation[1732]?.value : "Enter Promotion Name"}
            type='text'
            // charactersMaxLength={25}
            onChange={handleChange}
            error={(!promotion_name && errors.promotion_name)}
          />
          <p className="text-sm">
            Purchase selected product/service and get discount on selected
            service.
          </p>
          <div className="flex items-center gap-3">
            <Dropwdown
              required={true}
              title={selectedLanguage?.name == "English" ? "Select Type" : translation[992]?.value ? translation[992]?.value : "Select Type"}
              name="type"
              placeholder={selectedLanguage?.name == "English" ? "Select Type" : translation[992]?.value ? translation[992]?.value : "Select Type"}
              options={choices}
              value={purchaseDiscount.type}
              onChange={handlePurchaseDiscount}
              onBlur={() => onBlurHandler({ type: purchaseDiscount.type })}
              error={errors.type}
            />
            <Dropwdown
              required={true}
              title={selectedLanguage?.name == "English" ? "Select product/service" : translation[993]?.value ? translation[993]?.value : "Select product/service"}
              name="selectType"
              placeholder={selectedLanguage?.name == "English" ? "Select product/service" : translation[993]?.value ? translation[993]?.value : "Select product/service"}
              value={purchaseDiscount.selectType}
              options={
                purchaseDiscount?.type === "Service"
                  ? serviceDropdown
                  : purchaseDiscount?.type === "Product"
                    ? productDropdown
                    : []
              }
              onChange={handlePurchaseDiscount}
              onBlur={() =>
                onBlurHandler({ selectType: purchaseDiscount.selectType })
              }
              error={errors.selectType}
            />
          </div>
          <div className="grid lg:grid-cols-2 gap-3">

            <Dropwdown
              required={true}
              name="discountOnSelectedType"
              title={selectedLanguage?.name == "English" ? `Discounted ${purchaseDiscount.type}` : translation[994]?.value ? translation[994]?.value : `Discounted ${purchaseDiscount.type}`}
              placeholder={`${selectedLanguage?.name == "English" ? "Select" : translation[913]?.value ? translation[913]?.value : "Select"}` + ' ' +
                `${selectedLanguage?.name == "English" ? "Discounted" + ' ' + purchaseDiscount.type : translation[994]?.value ? translation[994]?.value : "Discounted" + ' ' + purchaseDiscount.type}`}

              options={
                purchaseDiscount?.type === "Service"
                  ? serviceDropdown
                  : purchaseDiscount?.type === "Product"
                    ? productDropdown
                    : []
              }
              value={purchaseDiscount?.discountOnSelectedType}
              onChange={handlePurchaseDiscount}
              onBlur={() =>
                onBlurHandler({
                  discountOnSelectedType:
                    purchaseDiscount.discountOnSelectedType,
                })
              }
              error={errors.discountOnSelectedType}
            />
            <LoginInput
              required={true}
              name={`purchaseQty`}
              title={
                <I_Info_Icon
                  text={selectedLanguage?.name == "English" ? "Quantity of applicable items" : translation[995]?.value ? translation[995]?.value : "Quantity of applicable items"}
                  info={selectedLanguage?.name == "English" ? "Type number of products/services that client need to purchase to avail discount" : translation[996]?.value ? translation[996]?.value : "Type number of products/services that client need to purchase to avail discount"}
                />
              }
              onlyNumericAllowed={true}
              type={'number'}
              placeholder={selectedLanguage?.name == "English" ? "Enter Number of Purchase" : translation[1735]?.value ? translation[1735]?.value : "Enter Number of Purchase"}
              value={purchaseDiscount.purchaseQty}
              onChange={handlePurchaseDiscount}
              onBlur={() =>
                onBlurHandler({ purchaseQty: purchaseDiscount.purchaseQty })
              }
              error={errors.purchaseQty}
            />

          </div>
          <div className="grid lg:grid-cols-3 gap-3">
            <FloatInput
              required={true}
              title={selectedLanguage?.name == "English" ? "Discount Value" : translation[997]?.value ? translation[997]?.value : "Discount Value"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Discount Percentage" : translation[1733]?.value ? translation[1733]?.value : "Enter Discount Percentage"}
              name="discount"
              value={purchaseDiscount.discount}
              onChange={(e) =>
                e.target.value <= 100 && handlePurchaseDiscount(e)
              }
              isPercentage={true}
              onBlur={() =>
                onBlurHandler({ discount: purchaseDiscount.discount })
              }
              error={errors.discount}
            />
          </div>
        </div>

        <div className="border rounded-lg p-3 flex flex-col gap-7">
          <div className="flex flex-col gap-4">
            <h1 className='font-semibold text-lg'>
              {selectedLanguage?.name == "English" ? "Date Restrictions" : translation[899]?.value ? translation[899]?.value : "Date Restrictions"}
            </h1>
            <div className="grid grid-cols-2 gap-3">
              <LoginInput
                title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                name="startDate"
                value={dateRestriction.startDate}
                type="date"
                onChange={handleChange}
                onBlur={() =>
                  onBlurHandler({ startDate: dateRestriction.startDate })
                }
                error={errors.startDate}
              />
              <LoginInput
                title={selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                name="endDate"
                value={dateRestriction.endDate}
                type="date"
                onChange={handleChange}
                min={minEndDateLimit}
                onBlur={() =>
                  onBlurHandler({ endDate: dateRestriction.endDate })
                }
                error={errors.endDate}
              />
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <h1 className='font-semibold text-lg'>
              {selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
            </h1>
            <div className='flex items-end gap-3'>
              <Dropwdown
                required={true}
                name="storeRestriction"
                placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                parentClass="flex-1"
                options={locationDropdown?.filter(
                  (obj) =>
                    obj.value !==
                    discount?.storeRestrictions?.find(
                      (location) => location === obj.value
                    )
                )}
                value={storeRestriction}
                onChange={handleChange}
                error={errors?.storeRestriction}
              />
              <LoginBtn
                className={storeRestriction && "shake-bottom"}
                text={
                  <div className="flex items-center gap-2">
                    {" "}
                    <Svgs.Plus />
                    <p>
                      {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                    </p>
                  </div>
                }
                onClick={addStoreRestriction}
                disabled={!storeRestriction}
              />
            </div>
          </div>
          <div className='flex items-center gap-2 flex-wrap'>
            {storeRestrictions?.map((obj, index) => (
              <SelectedOption
                rounded={"full"}
                name={
                  locationDropdown?.find(
                    (location) => location?.value === obj
                  )?.label
                }
                onClick={() => filterStoreRestriction(index)}
              />
            ))}
          </div>
          <div className="flex flex-col gap-4">
            <ToggleWithText
              textClass={'!text-lg'}
              title={selectedLanguage?.name == "English" ? "Add Block Date" : translation[902]?.value ? translation[902]?.value : "Add Block Date"}
              value={toggleblockdate}
              onChange={() => setToggleBlockDate(!toggleblockdate)}
              checked={toggleblockdate}
            />
            {toggleblockdate &&
              <>
                <div className="flex items-end gap-3">
                  <LoginInput
                    name="blockDate"
                    title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                    type="date"
                    required={false}
                    parentClass="flex-1"
                    min={dateRestriction.startDate}
                    max={dateRestriction.endDate}
                    value={blockDate}
                    onChange={handleChange}
                    // onBlur={() => onBlurHandler({ blockDate: blockDate })}
                    error={errors.blockDate}
                    disabled={
                      !discount.dateRestriction.startDate ||
                      !discount.dateRestriction.endDate
                    }
                  />
                  <LoginBtn
                    className={blockDate && "shake-bottom"}
                    text={
                      <div className="flex items-center gap-2">
                        <Svgs.Plus />
                        <p>
                          {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                        </p>
                      </div>
                    }
                    onClick={addBlockDates}
                    disabled={
                      !dateRangeCheck ? true : false ||
                        !discount.dateRestriction.startDate ||
                        !discount.dateRestriction.endDate ||
                        !blockDate ||
                        validateBlockDate ? true : false
                    }
                  />
                </div>
                <div className="flex items-center gap-2 flex-wrap">
                  {blockDates?.map((obj, index) => (
                    <>
                      {obj?.is_deleted === "False" && (
                        <SelectedOption
                          rounded={"full"}
                          name={obj?.date}
                          onClick={() => filterBlockDate(obj?.id, index)}
                        />
                      )}
                    </>
                  ))}
                </div>
              </>
            }
          </div>
          {/* <div className="flex flex-col gap-4">
            <ToggleWithText
              textClass={"!text-lg"}
              title={selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
              value={toggleStore}
              onChange={() => setToggleStore(!toggleStore)}
              checked={toggleStore}
            />
            {toggleStore && (
              <>
                <div className="flex items-end gap-3">
                  <Dropwdown
                    name="storeRestriction"
                    placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                    title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                    parentClass="flex-1"
                    options={locationDropdown?.filter(
                      (obj) =>
                        obj.value !==
                        discount?.storeRestrictions?.find(
                          (location) => location === obj.value
                        )
                    )}
                    value={storeRestriction}
                    onChange={handleChange}
                  />
                  <LoginBtn
                    className={storeRestriction && "shake-bottom"}
                    text={
                      <div className="flex items-center gap-2">
                        {" "}
                        <Svgs.Plus />
                        <p>
                          {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                        </p>
                      </div>
                    }
                    onClick={addStoreRestriction}
                    disabled={!storeRestriction}
                  />
                </div>
                <div className="flex items-center gap-2 flex-wrap">
                  {storeRestrictions?.map((obj, index) => (
                    <SelectedOption
                      rounded={"full"}
                      name={
                        locationDropdown?.find(
                          (location) => location?.value === obj
                        )?.label
                      }
                      onClick={() => filterStoreRestriction(index)}
                    />
                  ))}
                </div>
              </>
            )}
          </div> */}
          <div className="flex flex-col gap-4">
            <ToggleWithText
              textClass={"!text-lg"}
              title={selectedLanguage?.name == "English" ? "Day Restrictions" : translation[906]?.value ? translation[906]?.value : "Day Restrictions"}
              value={toggleDay}
              onChange={() => setToggleDay(!toggleDay)}
              checked={toggleDay}
            />
            {toggleDay && (
              <div className="grid grid-cols-2 gap-4">
                {dayRestrictions.map((item) => {
                  return (
                    <CheckBox
                      name={item.day}
                      label={item.day}
                      value={item.value}
                      onChange={handleDayChecklist}
                      checked={item.value === item.day ? true : false}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <LoginBtn
          text={isEdit ?
            selectedLanguage?.name == "English" ? "Update" : translation[845]?.value ? translation[845]?.value : "Update"
            :
            selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
          loading={loading}
          disabled={loading}
          // disabled={
          //   loading ||
          //   // discount.serviceGroupDiscount.length === 0 ||
          //   // discount?.serviceGroupDiscount?.find(
          //   //   (obj) => !obj?.service_group || !obj?.discount
          //   // ) ||
          //   !discount.promotion_name ||
          //   !discount.dateRestriction.endDate ||
          //   !discount.dateRestriction.startDate
          //   || errors.endDate
          //   // || discount.blockDates.length === 0
          // }
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default AddDiscount;
