import { useState } from 'react'
import { toast } from 'react-toastify';
import { updateSettingNotifications } from '../../../../../../Adapters/Api/notificationsSettings';
import useSetDataInLocalStorage from '../../../../../../Hooks/useSetDataInLocalStorage';
import { useNavigate } from 'react-router-dom';

function useNotication() {
  const navigate = useNavigate()
  const [selectedNotificationsSetttings, setSelectedNotificationsSetttings] = useState([])

  const [notificationSettings, setNotificationSettings] = useState({
    sms_daily_sale: true,
    email_daily_sale: true,
    sms_purchase_plan: true,
    sms_for_rewards_on_quick_sale: true,
    sms_pending_services_quicksale: true,
    sms_for_ewallet_balance_on_quick_sale: true,
    sms_pending_payment: true,
    email_notify_on_purchase_plan: true,
    sms_quick_sale: false,
    sms_appoinment: true,
    sms_appoinment_reschedule: false,
    sms_birthday: false,
    sms_notify_on_appoinment: true,
    sms_notify_on_quick_sale: true,
    sms_notify_for_daily_book: true,
    email_notify_on_appoinment: true,
    email_notify_on_quick_sale: true,
    email_notify_on_daily_book: false,
    notify_for_lowest_stock: true,
    notify_stock_turnover: true,

  });
  const { getDataFromLocalStorage } = useSetDataInLocalStorage()
  const businessData = getDataFromLocalStorage("business_data")
  const access_token = getDataFromLocalStorage("access_token");

  const onSaveChanges = async () => {
    const response = await updateSettingNotifications(notificationSettings, businessData.id, access_token)

    if (response.status === 200) {
      const updatedSettings = response?.data?.response?.settings;
      const successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      navigate("/dashboard/account/setup");
    }
  }

  return {
    notificationSettings,
    setNotificationSettings,
    selectedNotificationsSetttings,
    setSelectedNotificationsSetttings,
    onSaveChanges
  }

}

export default useNotication
