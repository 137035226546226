import { BASE_URL, employee_set_password } from "../../api_variables";

export const CreateEmployeePassword = (data, success, fail) => (dispatch) => {
  let form_data = new FormData();

  for (let dt in data) {
    if (data[dt]) {
      form_data.append(dt, data[dt]);
    }
  }
  fetch(BASE_URL + employee_set_password, {
    method: "POST",
    body: form_data,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status === 200) {
        success && success(result.response.message);
        // dispatch({
        //   type: SET_EMPLOYEE_PASSWORD,
        //   payload: result.response.message,
        // });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};
