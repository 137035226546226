import React from "react";

import Popup from "new-components/popup";

import DetailPopupDetails from "../../../Elements/DetailPopupDetails";

const AddProduct = ({ setAddProduct, product_details, location_currency, translation, selectedLanguage, }) => {
  return (
    <Popup
      heading={selectedLanguage?.name == "English" ? "Product Details" : translation[291]?.value ? translation[291]?.value : "Product Details"}
      handleClose={() => setAddProduct(false)}>
      <div className="grid md:grid-cols-5 gap-4">
        <div className="flex justify-center">
          <img
            src={`${product_details?.cover_image
              ? product_details?.cover_image
              : `${process.env.PUBLIC_URL}/images/profile_img.svg`
              }`}
            className="h-[4.5rem] w-[4.5rem] object-cover rounded-full"
          />
        </div>
        <div className="flex flex-col gap-6 md:col-span-4">
          <div className="grid md:grid-cols-3 gap-2">
            <DetailPopupDetails
              title={selectedLanguage?.name == "English" ? "Product Name" : translation[296]?.value ? translation[296]?.value : "Product Name"}
              desc={product_details?.name}
            />
            <DetailPopupDetails
              title={selectedLanguage?.name == "English" ? "Brand" : translation[292]?.value ? translation[292]?.value : "Brand"}
              desc={product_details?.brand?.name}
            />
            <DetailPopupDetails
              title={selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}
              desc={product_details?.category?.name}
            />
          </div>
          {/* <div>
            <p className={`text-[#555555] text-sm`}>Location</p>
            <p className={` text-[#101928]`}>
              {product_details?.stocks.length > 0 &&
                product_details?.stocks?.map(
                  (itm) => ` ${itm?.location?.address_name} ,`
                )}
            </p>
          </div> */}
          {/* <DetailPopupDetails
            title="Employee"
            desc={
              <div className="flex items-center gap-4 flex-wrap mt-2">
                <SelectedOption showDelete={false} img='https://source.unsplash.com/random' name='Waqar Zaka' />
              </div>
            }
          /> */}
          <DetailPopupDetails
            // location_currency={location_currency}
            title={selectedLanguage?.name == "English" ? "Retail Price" : translation[294]?.value ? translation[294]?.value : "Retail Price"}
            desc={`${location_currency} ${product_details?.retail_price?.toFixed(2)}`}
          // descClass="text-xl font-semibold text-primary"
          />
          <DetailPopupDetails
            title={selectedLanguage?.name == "English" ? "Consumption Notes" : translation[295]?.value ? translation[295]?.value : "Consumption Notes"}
            desc={((product_details?.description != null) && (product_details?.description != "null")) ? product_details?.description : "----"}
          />
          <DetailPopupDetails
            title={selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description"}
            desc={((product_details?.short_description != null) && (product_details?.short_description != "null")) ? product_details?.short_description : " ----"}
          />
        </div>
      </div>
    </Popup>
  );
};

export default AddProduct;
