import { useState, useEffect } from 'react';
import useValidations from './../../../../../Hooks/useValidations';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import useObjectKeys from './../../../../../Hooks/useObjectKeys';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { apiErrorMessage } from 'Constants/Data/Errors';
import { createCashBack, deleteCashBack, getCashBack, updateCashBack } from 'Adapters/Api/cashBack';
import { getLocations } from 'Adapters/Api';

let applicableData = [
    { label: "All", value: "All" },
    { label: "Product", value: "Product" },
    { label: "Service", value: "Service" }
]

let initialState = {
    name: "",
    allowedLocations: [],
    redeemableLocations: [],
    amount_spend: "",
    percentage: "",
    validTill: "",
    applicableFor: "",
    redeemptionAmount: "",
    termsAndCondition: "",
    allowMembership: false,
    status: true
}

function useCashBack() {
    const dispatch = useDispatch()

    const state = useSelector((state) => state)
    const allCashBackData = state.client.cashback
    const currency = state?.business?.business?.currency?.code
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const isPermissionUpdated = state?.user?.isPermissionUpdated;
    const reduxlocationsList = state?.locations?.locations;

    const { setErrors, setEditErrors, editErrors, validation, errors, onBlurHandler } = useValidations();

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    let access_token = getDataFromLocalStorage("access_token")
    let businessData = getDataFromLocalStorage("business_data")

    const { objectKeyConvertToArray } = useObjectKeys();

    const [loadingPermission, setLoadingPermission] = useState(true)
    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);
    const [is_delete, setDelete] = useState(false)
    const [addLoader, setAddLoader] = useState(false)
    const [updateLoader, setUpdateLoader] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [getLoader, setGetLoader] = useState(true)
    const [editId, seteditId] = useState();
    const [cashBackData, setCashBackData] = useState(initialState)

    useEffect(() => {
        dispatch(getCashBack(access_token, setGetLoader))
        dispatch(getLocations(businessData?.id));
    }, []);

    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])

    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);

    // handle change of allow membership
    const allowMembershipChangeHandler = (e) => {
        const { name, checked } = e.target;
        setCashBackData((prev) => ({
            ...prev,
            [name]: checked
        }))
        setErrors((prev) => ({
            ...prev,
            [name]: ""
        }))
    }

    // handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'redeemableLocations' || name == 'allowedLocations') {
            const prevData = cashBackData?.[name] || []
            setCashBackData((prev) => ({
                ...prev,
                [name]: [...prevData, value]
            }))
        }

        if (name != 'redeemableLocations' && name != 'allowedLocations') {
            setCashBackData((prev) => ({
                ...prev,
                [name]: value
            }))
        }
        setErrors((prev) => ({
            ...prev,
            [name]: ""
        }))
    }

    // remove cashback spending item
    const removeItem = (name, data) => {
        if (name == 'allowedLocations') {
            const filterData = cashBackData?.allowedLocations?.filter(itm => itm != data)
            setCashBackData((prev) => ({
                ...prev,
                allowedLocations: filterData
            }))
        }

        if (name == 'redeemableLocations') {
            const filterData = cashBackData?.redeemableLocations?.filter(itm => itm != data)
            setCashBackData((prev) => ({
                ...prev,
                redeemableLocations: filterData
            }))
        }
    }

    //create cash back function
    const onSubmitCashBack = () => {
        let requiredFields = {
            ...cashBackData,
            allowedLocations: cashBackData?.allowedLocations?.length > 0 ? true : false,
            redeemableLocations: cashBackData?.redeemableLocations?.length > 0 ? true : false
        }
        delete requiredFields.allowMembership
        setErrors(validation(requiredFields))
        let result = validation(requiredFields)
        if (objectKeyConvertToArray(result)?.length === 0) {
            setAddLoader(true)
            let payload = {
                name: cashBackData?.name,
                amount_spend: cashBackData?.amount_spend,
                percentage: cashBackData?.percentage,
                redumption_amount: cashBackData?.redeemptionAmount,
                expiry: cashBackData?.validTill,
                is_active: cashBackData?.status,
                applicable_for: cashBackData?.applicableFor,
                terms_and_conditions: cashBackData?.termsAndCondition,
                allowMembership: cashBackData?.allowMembership,
                cashback_allowed_locations_records: cashBackData?.allowedLocations?.map(itm => ({ location: itm })),
                cashback_redeemed_locations_records: cashBackData?.redeemableLocations?.map(itm => ({ location: itm })),
                business: businessData?.id
            }
            dispatch(
                createCashBack(
                    payload,
                    access_token,
                    (message) => {
                        dispatch(getCashBack(access_token, setGetLoader))
                        if (!isToastVisible) {
                            toast.success(message, {
                                onClose: () => setIsToastVisible(false),
                            });
                            setIsToastVisible(true);
                        }
                        setCashBackData(initialState)
                        setAddLoader(false)
                    },
                    () => {
                        if (!isToastVisible) {
                            toast.error(apiErrorMessage, {
                                onClose: () => setIsToastVisible(false),
                            });
                            setIsToastVisible(true);
                        }
                        setAddLoader(false)
                    }
                )
            )
        }
    }

    //update cash back function
    const onUpdateCashback = (data) => {
        let requiredFields = {
            ...data,
            allowedLocations: data?.allowedLocations?.length > 0 ? true : false,
            redeemableLocations: data?.redeemableLocations?.length > 0 ? true : false
        }
        delete requiredFields.allowMembership
        delete requiredFields.status
        setEditErrors(validation(requiredFields))
        let result = validation(requiredFields)
        if (objectKeyConvertToArray(result)?.length === 0) {
            let payload = {
                id: data?.id,
                name: data?.name,
                amount_spend: data?.amount_spend,
                percentage: data?.percentage,
                redumption_amount: data?.redeemptionAmount,
                expiry: data?.validTill,
                is_active: data?.status,
                applicable_for: data?.applicableFor,
                terms_and_conditions: data?.termsAndCondition,
                allowMembership: data?.allowMembership,
                cashback_allowed_locations_records: data?.allowedLocations?.map(itm => ({ location: itm })),
                cashback_redeemed_locations_records: data?.redeemableLocations?.map(itm => ({ location: itm })),
                business: businessData?.id
            }
            setUpdateLoader(true)
            dispatch(
                updateCashBack(
                    payload,
                    access_token,
                    (message) => {
                        dispatch(getCashBack(access_token, setGetLoader))
                        if (!isToastVisible) {
                            toast.success(message, {
                                onClose: () => setIsToastVisible(false),
                            });
                            setIsToastVisible(true);
                        }
                        seteditId();
                        setUpdateLoader(false)
                    },
                    () => {
                        if (!isToastVisible) {
                            toast.error(apiErrorMessage, {
                                onClose: () => setIsToastVisible(false),
                            });
                            setIsToastVisible(true);
                        }
                        setUpdateLoader(false)
                    }
                )
            )
        }
    }

    //delete cash back function
    const onDeleteCashBack = () => {
        dispatch(
            deleteCashBack(
                deleteId,
                access_token,
                (message) => {
                    dispatch(getCashBack(access_token, setGetLoader))
                    setDelete(false);
                    if (!isToastVisible) {
                        toast.success(message, {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }
                },
                () => {
                    if (!isToastVisible) {
                        toast.error(apiErrorMessage, {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }

                }
            )
        )
    }

    return {
        allCashBackData, onUpdateCashback, cashBackData, setCashBackData, errors, onBlurHandler, handleChange, onSubmitCashBack,
        is_delete, setDelete, setDeleteId, onDeleteCashBack, currency, addLoader, updateLoader, translation, selectedLanguage,
        setEditErrors, editErrors, editId, seteditId, getLoader, applicableData, loadingPermission, employee, employeePermissions,
        removeItem, allowMembershipChangeHandler, reduxlocationsList
    }
}

export default useCashBack