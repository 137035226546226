import React,{useState, useEffect} from 'react'
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
  } from 'chart.js';
  
  import { Bar } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  
  const BarChart = ({chartData1, chartData2, chartData1Color, chartData2Color, chartBorderRadius}) => {
    const [ChartBarThickness, setChartBarThickness] = useState(10);
    const [LabelFontSize, setLabelFontSize] = useState(14);
      useEffect(() => {
            const handleResize = () => {
            if (window.innerWidth >= 770) {
                setChartBarThickness(10);
                setLabelFontSize(14)
            } else {
                setChartBarThickness(5);
                setLabelFontSize(10)
            }
            };
            // Initial setup
            handleResize();
            // Event listener for window resize
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);
   
    const options = {
      plugins: {
        legend:false,
        tooltip: {
          // Disable the on-canvas tooltip
          enabled: true,
          usePointStyle: true,
          bodyAlign: "center",
          yAlign: "bottom",
          xAlign: "center",
          footerAlign: "center",
          titleAlign: "center",
          displayColors: false,
          pointStyle: false,
          padding: 10,
          caretPadding: 10,
          bodyFont: {
            size: 15,
          },
          callbacks: {
            title: function () {
              return "";
            },
          },
        },
      },
      scales: {
        y: {
          grid:{
            display: true,
          },
          ticks: {
            font: {
              size: LabelFontSize, // Set the font size for the y-axis labels
              family: 'Arial', // Set the font family for the y-axis labels
            },
          },
        },
        x: {
          grid:{
            display: false,
          },
          ticks: {
            font: {
              size: LabelFontSize, // Set the font size for the y-axis labels
              family: 'Arial', // Set the font family for the y-axis labels
            },
          },
        },
      },
    };
    
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    const data = {
      labels,
      datasets: [
        {
          label: '',
          data: chartData1,
          backgroundColor: chartData1Color,
          barThickness: ChartBarThickness,
          borderRadius:chartBorderRadius?chartBorderRadius:0,
          
        },
        {
          label: '',
          data: chartData2,
          backgroundColor: chartData2Color,
          barThickness: ChartBarThickness,
          borderRadius:chartBorderRadius?chartBorderRadius:0,
        },
      ],
    };
  return (
    <Bar options={options} data={data}  />
  )
}

export default BarChart