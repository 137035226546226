/* eslint-disable react/jsx-pascal-case */
import Svgs from "Assets/svgs";
import CheckBox from "Components/TenantApp/Elements/Checkbox/CheckBox";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import I_Info_Icon from "Components/TenantApp/Elements/I_Info_Icon";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import SelectedOption from "Components/TenantApp/Elements/SelectedOption";
import ToggleWithText from "Components/TenantApp/Elements/ToggleWithText";
import moment from "moment";
import ToggleRestrictions from "../../CreateDirectFlatDiscount/AddDiscount/ToggleRestrictions";
import { useAddSpecificDiscount } from "./helper";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import { useMemo } from "react";
import FloatInput from "Components/TenantApp/Elements/FloatInput";
import Textarea from "Components/TenantApp/Elements/Textarea";
import RadioBtn from "Components/TenantApp/Elements/RadioBtn";
import AddClientPopup from "../../../AddClient/AddClientPopup";

const AddDiscount = () => {
    const { errors, toggleDay, toggleStore, discount, loading, locationDropdown, isEdit, serviceGroupDropdown, brandDropdown,
        handleChange, onBlurHandler, setDiscount, filterServiceGroupDiscountField, addServiceGroupDiscount, clientTypeOptions,
        addStoreRestriction, filterStoreRestriction, onSubmit, setToggleDay, setToggleStore, addBrandRestriction,
        addServiceRestriction, handleDayChecklist, translation, selectedLanguage, formErrors, setFormErrors, serviceToggle, setServiceToggle,
        brandToggle, setBrandToggle, productExcludedDropdown, serviceExcludedDropdown, formRef, clientDropdown,
        showAddClientPopup, setShowAddClientPopup, addClients, filterClient, getClientName, handleBuyOneGetOne,
        choices, serviceDropdown, productDropdown, handleFixedAmountDiscount, generateCouponCode, handleChangeCouponType, getClients
    } = useAddSpecificDiscount();
    const {
        client_type,
        buyOneGetOne,
        specificServiceGroupBrandDiscount,
        dateRestriction,
        coupon_name,
        coupon_description,
        usageLimit,
        userLimit,
        client,
        clients,
        couponTypeValue,
        brandRestriction,
        brandRestrictions,
        serviceRestriction,
        serviceRestrictions,
        storeRestriction,
        storeRestrictions,
        dayRestrictions,
        fixedAmount,
        status,
        coupon_code,
        id
    } = discount;

    var minEndDateLimit = useMemo(() => {
        return moment(dateRestriction.startDate).add(1, 'day').format('YYYY-MM-DD')
    }, [dateRestriction.startDate]);

    return (
        <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">


            <Breadcrumb
                first={selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}
                url={'/dashboard/clients/'}
                second={selectedLanguage?.name == "English" ? "Discount & Promotions" : translation[638]?.value ? translation[638]?.value : "Discount & Promotions"}
                secondurl={'/dashboard/clients/discounts-promotions'}
                third={selectedLanguage?.name == "English" ? " Coupons" : translation[1437]?.value ? translation[1437]?.value : " Coupons"}
            />
            <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

                <div className="flex items-center gap-4 justify-between w-full">
                    <div className="flex items-center gap-3">
                        <Svgs.Back link="/dashboard/clients/discounts-promotions" />
                        <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
                            {selectedLanguage?.name == "English" ? "Coupons" : translation[1437]?.value ? translation[1437]?.value : "Coupons"}
                        </h2>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-4 md:w-[40vw]" ref={formRef}>
                <div className="border rounded-lg p-3 flex flex-col gap-3">
                    <LoginInput
                        name="coupon_name"
                        className='!w-fit'
                        title={selectedLanguage?.name == "English" ? "Coupon Name" : translation[1736]?.value ? translation[1736]?.value : "Coupon Name"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Coupon Name" : translation[1737]?.value ? translation[1737]?.value : "Enter Coupon Name"}
                        value={coupon_name}
                        type='text'
                        // charactersMaxLength={25}
                        onChange={handleChange}
                        error={errors.coupon_name}
                    />
                    <Textarea
                        required
                        error={errors.coupon_description}
                        title={selectedLanguage?.name == "English" ? "Coupon Description" : translation[92]?.value ? translation[92]?.value : "Coupon Description"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Coupon Description" : translation[93]?.value ? translation[93]?.value : "Enter Coupon Description"}
                        rows='5'
                        limit='150'
                        name='coupon_description'
                        onChange={handleChange}
                        value={coupon_description}
                    />
                    <LoginInput
                        required={true}
                        type="number"
                        title={selectedLanguage?.name == "English" ? "Usage Limit" : translation[1445]?.value ? translation[1445]?.value : "Usage Limit"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter how many times to use" : translation[1446]?.value ? translation[1446]?.value : "Enter how many times to use"}
                        value={usageLimit}
                        error={errors?.usageLimit}
                        name="usageLimit"
                        onChange={handleChange}
                    />
                    <LoginInput
                        required={true}
                        type="number"
                        title={selectedLanguage?.name == "English" ? "Client Limit" : translation[1738]?.value ? translation[1738]?.value : "Client Limit"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter how many clients can use this" : translation[1739]?.value ? translation[1739]?.value : "Enter how many clients can use this"}
                        value={userLimit}
                        error={errors?.userLimit}
                        name="userLimit"
                        onChange={handleChange}
                    />
                    <Dropwdown
                        name="status"
                        required={true}
                        title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[483]?.value : "Select Status"}
                        value={status}
                        onChange={handleChange}
                        options={[
                            { label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active", value: true },
                            { label: selectedLanguage?.name == "English" ? "In Active" : translation[1302]?.value ? translation[1302]?.value : "In Active", value: false }
                        ]}
                    />
                    <div className="flex flex-col gap-2">
                        <Dropwdown
                            required={'true'}
                            name={'client_type'}
                            value={client_type}
                            title={selectedLanguage?.name == "English" ? "Client Type" : translation[330]?.value ? translation[330]?.value : "Client Type"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Client Type" : translation[379]?.value ? translation[379]?.value : "Select Client Type"}
                            onChange={handleChange}
                            options={clientTypeOptions}
                        />
                        {client_type === 'In_Saloon' &&
                            <>
                                <Dropwdown
                                    required={true}
                                    name="client"
                                    error={errors.client}
                                    value={client}
                                    title={<I_Info_Icon
                                        text={selectedLanguage?.name == "English" ? "Select Clients" : translation[1450]?.value ? translation[1450]?.value : "Select Clients"}
                                        info={selectedLanguage?.name == "English" ? "The customers selected here can only get this discount." : translation[1068]?.value ? translation[1068]?.value : "The customers selected here can only get this discount."} />}
                                    placeholder={selectedLanguage?.name == "English" ? "Select clients" : translation[1450]?.value ? translation[1450]?.value : "Select clients"}
                                    options={[
                                        { label: selectedLanguage?.name == "English" ? "All Clients" : translation[1301]?.value ? translation[1301]?.value : "All Clients", value: "All" },
                                        ...clientDropdown?.filter(
                                            (obj) =>
                                                obj.value !==
                                                discount?.clients?.find(
                                                    (client) => client === obj.value
                                                )
                                        )]}
                                    onChange={addClients}
                                />
                                {(clientDropdown?.filter(
                                    (obj) =>
                                        obj.value !==
                                        discount?.clients?.find(
                                            (client) => client === obj.value
                                        )
                                ))?.length == 0 && (
                                        <div
                                            className="flex items-center gap-2 cursor-pointer pt-2"
                                            onClick={() => {
                                                setShowAddClientPopup(!showAddClientPopup)
                                            }}
                                        >
                                            <p className="text-sm">
                                                {selectedLanguage?.name == "English" ? "No clients found. Click" : translation[1069]?.value ? translation[1069]?.value : "No services found. Click"}
                                                <span className="underline mx-px text-[#5679FF]">
                                                   {' '} {selectedLanguage?.name == "English" ? "here" : translation[502]?.value ? translation[502]?.value : "here"} {' '}
                                                </span>
                                                {selectedLanguage?.name == "English" ? "to add." : translation[140]?.value ? translation[140]?.value : "to add."}
                                            </p>
                                        </div>
                                    )}
                                <div className='flex items-center gap-2 flex-wrap'>
                                    {typeof clients !== 'string' && clients?.length > 0 && clients.map(obj => (
                                        <SelectedOption
                                            rounded={'full'}
                                            name={getClientName(obj, clientDropdown)}
                                            onClick={() => filterClient(obj)}
                                        />
                                    ))}
                                </div>
                            </>
                        }
                    </div>
                    <div className='flex items-end gap-6 flex-wrap'>
                        <div className='flex-1'>
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "Coupon Code" : translation[901]?.value ? translation[901]?.value : "Coupon Code"}
                                placeholder={selectedLanguage?.name == "English" ? "Enter Coupon Code" : translation[901]?.value ? translation[901]?.value : "Enter Coupon Code"}
                                name="coupon_code"
                                value={coupon_code}
                                type="text"
                                disabled={isEdit}
                                charactersMaxLength={20}
                                onChange={handleChange}
                                error={errors.coupon_code}
                            />
                        </div>
                        <LoginBtn
                            disabled={isEdit}
                            text={
                                <div className='flex items-center gap-2'>
                                    <p>{selectedLanguage?.name == "English" ? "Generate Code" : translation[97]?.value ? translation[97]?.value : "Generate Code"}</p>
                                </div>
                            }
                            onClick={generateCouponCode}
                        />
                    </div>

                    {/* Coupon types */}
                    {!id &&
                        <div className='flex items-center gap-[2rem]'>
                            <RadioBtn
                                onClick={() => {
                                    handleChangeCouponType("Specific Product And Services", 1)
                                }}
                                checked={couponTypeValue == 1}
                                id='1'
                                text={selectedLanguage?.name == "English" ? "Specific Product And Services" : translation[1438]?.value ? translation[1438]?.value : "Specific Product And Services"}

                            />
                            <RadioBtn
                                onClick={() => {
                                    handleChangeCouponType("Buy One Get One Free", 2)
                                }}
                                checked={couponTypeValue == 2}
                                id='2'
                                text={selectedLanguage?.name == "English" ? "Buy One Get One Free" : translation[1439]?.value ? translation[1439]?.value : "Buy One Get One Free"}
                            />
                            <RadioBtn
                                onClick={() => {
                                    handleChangeCouponType("Spend Some Amount And Get Discount", 3)
                                }}
                                checked={couponTypeValue == 3}
                                id='3'
                                text={selectedLanguage?.name == "English" ? "Spend Some Amount And Get Discount" : translation[1440]?.value ? translation[1440]?.value : "Spend Some Amount And Get Discount"}
                            />
                        </div>
                    }

                    {/* render data according to coupon types */}
                    {couponTypeValue === 1 &&
                        <>
                            {specificServiceGroupBrandDiscount?.map(
                                (obj, index) =>
                                    obj.is_deleted === "False" && (
                                        <AddServiceGroupDiscountPercentage
                                            obj={obj}
                                            index={index}
                                            removeServiceGroupDiscountField={filterServiceGroupDiscountField}
                                            specificServiceGroupBrandDiscount={specificServiceGroupBrandDiscount}
                                            serviceGroupDropdown={serviceGroupDropdown}
                                            brandDropdown={brandDropdown}
                                            handleServiceGroupDiscount={addServiceGroupDiscount}
                                            onBlurHandler={onBlurHandler}
                                            errors={errors}
                                            translation={translation}
                                            selectedLanguage={selectedLanguage}
                                            formErrors={formErrors}
                                            setFormErrors={setFormErrors}
                                        />
                                    )
                            )}
                            <LoginBtn
                                borderBtn
                                onClick={() => {
                                    const errors = [];
                                    discount.specificServiceGroupBrandDiscount?.forEach((data, index) => {
                                        const fieldErrors = {};
                                        if (!data.service_group) {
                                            fieldErrors.service_group = 'This field is required';
                                        }
                                        if (!data.discount) {
                                            fieldErrors.discount = 'This field is required';
                                        }
                                        if (!data.brand) {
                                            fieldErrors.brand = 'This field is required';
                                        }
                                        if (!data.brand_discount) {
                                            fieldErrors.brand_discount = 'This field is required';
                                        }
                                        errors[index] = fieldErrors;
                                    });
                                    // Update formErrors state with the new errors
                                    setFormErrors(errors);
                                    // If there are errors, return early and do not proceed with form submission
                                    if (errors?.some((error) => Object.keys(error)?.length > 0)) {
                                        formRef.current?.scrollIntoView({ behavior: "smooth" });
                                        return;
                                    } else {
                                        setDiscount((prev) => ({
                                            ...prev,
                                            specificServiceGroupBrandDiscount: [
                                                ...prev?.specificServiceGroupBrandDiscount,
                                                {
                                                    service_group: "", discount: "", brand: '', brand_discount: '', is_deleted: "False"
                                                },
                                            ],
                                        }));
                                    }
                                }}
                            // disabled={specificServiceGroupBrandDiscount?.find(
                            //   (obj) => !obj?.service_group || !obj?.discount || !obj?.brand || !obj?.brand_discount
                            // )}
                            >
                                <Svgs.Plus color="#1C8AFE" /> {selectedLanguage?.name == "English" ? "Add New" : translation[898]?.value ? translation[898]?.value : "Add New"}
                            </LoginBtn>
                        </>
                    }
                    {couponTypeValue === 2 &&
                        <>
                            <div className="flex items-center gap-3">
                                <Dropwdown
                                    required={true}
                                    title={selectedLanguage?.name == "English" ? "Select Type" : translation[992]?.value ? translation[992]?.value : "Select Type"}
                                    name="type"
                                    placeholder={selectedLanguage?.name == "English" ? "Select Type" : translation[992]?.value ? translation[992]?.value : "Select Type"}
                                    options={choices}
                                    value={buyOneGetOne.type}
                                    onChange={handleBuyOneGetOne}
                                    onBlur={() => onBlurHandler({ type: buyOneGetOne.type })}
                                    error={errors.type}
                                />
                                <Dropwdown
                                    required={true}
                                    title={selectedLanguage?.name == "English" ? "Select product/service" : translation[993]?.value ? translation[993]?.value : "Select product/service"}
                                    name="selectedType"
                                    placeholder={selectedLanguage?.name == "English" ? "Select product/service" : translation[993]?.value ? translation[993]?.value : "Select product/service"}
                                    value={buyOneGetOne.selectedType}
                                    options={
                                        buyOneGetOne?.type === "Service"
                                            ? serviceDropdown
                                            : buyOneGetOne?.type === "Product"
                                                ? productDropdown
                                                : []
                                    }
                                    onChange={handleBuyOneGetOne}
                                    error={errors.selectedType}
                                />
                            </div>
                        </>
                    }
                    {couponTypeValue === 3 &&
                        <>
                            <div className="grid grid-cols-2 gap-3">
                                <FloatInput
                                    name="spendAmount"
                                    title={selectedLanguage?.name == "English" ? "Spend Amount" : translation[1042]?.value ? translation[1042]?.value : "Spend Amount"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Amount" : translation[794]?.value ? translation[794]?.value : "Enter Amount"}
                                    required={true}
                                    value={fixedAmount?.spendAmount}
                                    error={errors?.spendAmount}
                                    onChange={handleFixedAmountDiscount}
                                />
                                <FloatInput
                                    required={true}
                                    name="discount"
                                    isPercentage={true}
                                    title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[895]?.value ? translation[895]?.value : "Discount Percentage"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Discount Percentage" : translation[1733]?.value ? translation[1733]?.value : "Enter Discount Percentage"}
                                    value={fixedAmount?.discount}
                                    onChange={handleFixedAmountDiscount}
                                    error={errors?.discount}
                                />
                            </div>
                        </>
                    }
                </div>

                <div className="border rounded-lg p-3 flex flex-col gap-7">
                    <div className="flex flex-col gap-4">
                        <h1 className="font-semibold text-lg">Date Restrictions</h1>
                        <div className="grid grid-cols-2 gap-3">
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                                name="startDate"
                                value={dateRestriction.startDate}
                                type="date"
                                onChange={handleChange}
                                onBlur={() =>
                                    onBlurHandler({ startDate: dateRestriction.startDate })
                                }
                                error={errors.startDate}
                            />
                            <LoginInput
                                title={selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                                name="endDate"
                                value={dateRestriction.endDate}
                                type="date"
                                onChange={handleChange}
                                min={minEndDateLimit}
                                onBlur={() =>
                                    onBlurHandler({ endDate: dateRestriction.endDate })
                                }
                                error={errors.endDate}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <h1 className='font-semibold text-lg'>
                            {selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
                        </h1>
                        <div className='flex items-end gap-3'>
                            <Dropwdown
                                required={true}
                                name='storeRestriction'
                                placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                                title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                                parentClass='flex-1'
                                options={locationDropdown?.filter(obj => obj.value !== discount?.storeRestrictions?.find(location => location === obj.value))}
                                value={storeRestriction}
                                onChange={handleChange}
                                error={errors?.storeRestriction}
                            />
                            <LoginBtn
                                className={storeRestriction && "shake-bottom"}
                                text={<div className='flex items-center gap-2' > <Svgs.Plus /><p>
                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                </p></div>}
                                onClick={addStoreRestriction}
                                disabled={!storeRestriction}
                            />
                        </div>
                    </div>
                    <div className='flex items-center gap-2 flex-wrap'>
                        {
                            storeRestrictions?.map((obj, index) => (
                                <SelectedOption
                                    rounded={'full'}
                                    name={locationDropdown?.find(location => location?.value === obj)?.label}
                                    onClick={() => {
                                        var filter = storeRestrictions?.filter(obj => obj !== storeRestrictions[index])
                                        setDiscount((prev) => ({
                                            ...prev,
                                            storeRestrictions: filter,
                                        }))
                                    }}
                                />
                            ))
                        }
                    </div>
                    {/* <div className="flex flex-col gap-4">
                        <ToggleWithText
                            textClass={"!text-lg"}
                            title={selectedLanguage?.name == "English" ? "Store Restrictions" : translation[903]?.value ? translation[903]?.value : "Store Restrictions"}
                            value={toggleStore}
                            onChange={() => setToggleStore(!toggleStore)}
                            checked={toggleStore}
                        />
                        {toggleStore && (
                            <>
                                <div className="flex items-end gap-3">
                                    <Dropwdown
                                        name="storeRestriction"
                                        placeholder={"Store"}
                                        title={selectedLanguage?.name == "English" ? "Stores where this promotions will be available" : translation[905]?.value ? translation[905]?.value : "Stores where this promotions will be available"}
                                        parentClass="flex-1"
                                        options={locationDropdown?.filter(
                                            (obj) =>
                                                obj.value !==
                                                discount?.storeRestrictions?.find(
                                                    (location) => location === obj.value
                                                )
                                        )}
                                        value={storeRestriction}
                                        onChange={handleChange}
                                    />
                                    <LoginBtn
                                        className={storeRestriction && "shake-bottom"}
                                        text={
                                            <div className="flex items-center gap-2">
                                                {" "}
                                                <Svgs.Plus />
                                                <p>
                                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                                </p>
                                            </div>
                                        }
                                        onClick={addStoreRestriction}
                                        disabled={!storeRestriction}
                                    />
                                </div>
                                <div className="flex items-center gap-2 flex-wrap">
                                    {storeRestrictions?.map((obj, index) => (
                                        <SelectedOption
                                            rounded={"full"}
                                            name={
                                                locationDropdown?.find(
                                                    (location) => location?.value === obj
                                                )?.label
                                            }
                                            onClick={() => filterStoreRestriction(index)}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </div> */}
                    <div className="flex flex-col gap-4">
                        <ToggleWithText
                            textClass={"!text-lg"}
                            title={selectedLanguage?.name == "English" ? "Day Restrictions" : translation[906]?.value ? translation[906]?.value : "Day Restrictions"}
                            value={toggleDay}
                            onChange={() => setToggleDay(!toggleDay)}
                            checked={toggleDay}
                        />
                        {toggleDay && (
                            <div className="grid grid-cols-2 gap-4">
                                {dayRestrictions?.map((item) => {
                                    console.log(item)
                                    return (
                                        <CheckBox
                                            name={item.day}
                                            label={item.day}
                                            value={item.value}
                                            onChange={handleDayChecklist}
                                            checked={item.value === item.day ? true : false}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>

                    {couponTypeValue === 1 &&
                        <>
                            <div className="flex flex-col gap-4">
                                <ToggleWithText
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Excluded Services" : translation[909]?.value ? translation[909]?.value : "Excluded Services"}
                                    value={serviceToggle}
                                    onChange={() => setServiceToggle(!serviceToggle)}
                                    checked={serviceToggle}
                                />

                                <ToggleRestrictions
                                    setDiscount={setDiscount}
                                    handleChange={handleChange}
                                    addRestrictions={addServiceRestriction}
                                    toggleRestrictions={serviceToggle}
                                    restrictions={serviceRestrictions}
                                    restriction={serviceRestriction}
                                    translation={translation}
                                    selectedLanguage={selectedLanguage}
                                    type={"serviceRestriction"}
                                    discount={discount}
                                    title={selectedLanguage?.name == "English" ? "services that you want to exclude from this offer" : translation[911]?.value ? translation[911]?.value : "services that you want to exclude from this offer"}
                                    // dropwdown={serviceExcludedDropdown}
                                    dropwdown={serviceExcludedDropdown.filter(itm => itm.value !== serviceRestrictions?.find(ele => ele?.id === itm.value))}

                                />
                            </div>
                            <div className="flex flex-col gap-4">
                                <ToggleWithText
                                    textClass={'!text-lg'}
                                    title={selectedLanguage?.name == "English" ? "Excluded Products" : translation[989]?.value ? translation[989]?.value : "Excluded Products"}
                                    value={brandToggle}
                                    onChange={() => setBrandToggle(!brandToggle)}
                                    checked={brandToggle}
                                />
                                <ToggleRestrictions
                                    setDiscount={setDiscount}
                                    handleChange={handleChange}
                                    addRestrictions={addBrandRestriction}
                                    toggleRestrictions={brandToggle}
                                    restrictions={brandRestrictions}
                                    restriction={brandRestriction}
                                    translation={translation}
                                    selectedLanguage={selectedLanguage}
                                    type={"brandRestriction"}
                                    title={selectedLanguage?.name == "English" ? "products that you want to exclude from this offer" : translation[990]?.value ? translation[990]?.value : "products that you want to exclude from this offer"}
                                    // dropwdown={productExcludedDropdown}
                                    dropwdown={productExcludedDropdown.filter(itm => itm.value !== brandRestrictions?.find(ele => ele?.id === itm.value))}

                                />
                            </div>
                        </>}

                </div>
                <LoginBtn
                    text={isEdit ?
                        selectedLanguage?.name == "English" ? "Update" : translation[845]?.value ? translation[845]?.value : "Update"
                        :
                        selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
                    loading={loading}
                    disabled={loading}
                    // disabled={
                    //   loading ||
                    //   discount.specificServiceGroupBrandDiscount.length === 0 ||
                    //   discount?.specificServiceGroupBrandDiscount?.find(
                    //     (obj) => !obj?.service_group || !obj?.discount || !obj?.brand || !obj?.brand_discount
                    //   ) ||
                    //   !discount.dateRestriction.endDate ||
                    //   !discount.promotion_name ||
                    //   !discount.dateRestriction.startDate
                    //   || errors.endDate
                    //   // || discount.blockDates.length === 0
                    // }
                    onClick={onSubmit}
                />
            </div>
            {
                showAddClientPopup &&
                <AddClientPopup open={showAddClientPopup} close={setShowAddClientPopup} getClients={getClients} />
            }
        </div>
    );
};

export default AddDiscount;

const AddServiceGroupDiscountPercentage = ({
    translation, selectedLanguage,
    obj,
    index,
    removeServiceGroupDiscountField,
    serviceGroupDropdown,
    brandDropdown,
    handleServiceGroupDiscount,
    specificServiceGroupBrandDiscount,
    onBlurHandler,
    errors,
    formErrors
}) => {


    const getServiceGroupName = (id) => {
        return serviceGroupDropdown.find((obj) => obj.value === id)?.label;
    }
    const getBrandName = (id) => {
        return brandDropdown.find((obj) => obj.value === id)?.label;
    }

    return (
        <>
            <div className="items-start gap-3 border p-2 rounded-md relative flex-col">

                {index > 0 &&
                    <div
                        className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 cursor-pointer"
                        onClick={() => removeServiceGroupDiscountField(obj?.id, index)}
                    >
                        <Svgs.CirlceClose fill={"red"} size={"20"} />
                    </div>
                }

                <div className='grid lg:grid-cols-2 gap-3'>
                    <div>
                        <Dropwdown
                            required={true}
                            name='brand'
                            title={selectedLanguage?.name == "English" ? "Brand" : translation[836]?.value ? translation[836]?.value : "Brand"}
                            placeholder={selectedLanguage?.name == "English" ? "Select Brand" : translation[984]?.value ? translation[984]?.value : "Select Brand"}
                            options={
                                // brandDropDown
                                !obj.brand ? brandDropdown.filter(obj => {
                                    return obj && !specificServiceGroupBrandDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.brand === obj.value)
                                })
                                    :
                                    [{ label: getBrandName(obj?.brand), value: getBrandName(obj?.brand) },
                                    ...brandDropdown.filter(obj => {
                                        return obj && !specificServiceGroupBrandDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.brand === obj.value)
                                    })
                                    ]
                            }
                            value={getBrandName(obj?.brand)}
                            onChange={(e) => handleServiceGroupDiscount(e, index)}
                            error={formErrors?.length > 0 ? formErrors[index]?.brand : ""}
                        />
                    </div>
                    <FloatInput
                        name="brand_discount"
                        title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[895]?.value ? translation[895]?.value : "Discount Percentage"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Discount Percentage" : translation[1733]?.value ? translation[1733]?.value : "Enter Discount Percentage"}
                        required={true}
                        isPercentage={true}
                        value={obj?.brand_discount}
                        maxValue={100}
                        onChange={(e) => {
                            +e.target.value <= 100 &&
                                handleServiceGroupDiscount(e, index)
                        }}
                        error={formErrors?.length > 0 ? formErrors[index]?.brand_discount : ""}
                    />
                    <Dropwdown
                        required={true}
                        name="service_group"
                        title={selectedLanguage?.name == "English" ? "Service group" : translation[262]?.value ? translation[262]?.value : "Service group"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Service group" : translation[916]?.value ? translation[916]?.value : "Select Service group"}
                        options={
                            !obj.service_group ? serviceGroupDropdown.filter(obj => {
                                return obj && !specificServiceGroupBrandDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.service_group === obj.value)
                            })
                                :
                                [{ label: getServiceGroupName(obj?.service_group), value: getServiceGroupName(obj?.service_group) },
                                ...serviceGroupDropdown.filter(obj => {
                                    return obj && !specificServiceGroupBrandDiscount.filter(element => element.is_deleted === 'False')?.find((element) => element.service_group === obj.value)
                                })
                                ]
                        }
                        value={getServiceGroupName(obj?.service_group)}
                        onChange={(e) => handleServiceGroupDiscount(e, index)}
                        // onBlur={() => onBlurHandler({ [`service_group${index}`]: obj?.service_group })}
                        // error={errors[`service_group${index}`]}
                        error={formErrors?.length > 0 ? formErrors[index]?.service_group : ""}
                    />
                    <FloatInput
                        required={true}
                        name="discount"
                        title={selectedLanguage?.name == "English" ? "Discount Percentage" : translation[895]?.value ? translation[895]?.value : "Discount Percentage"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Discount Percentage" : translation[1733]?.value ? translation[1733]?.value : "Enter Discount Percentage"}
                        value={obj?.discount}
                        isPercentage={true}
                        onChange={(e) => {
                            e.target.value <= 100 &&
                                handleServiceGroupDiscount(e, index)
                        }}
                        // onBlur={() => onBlurHandler({ [`discount${index}`]: obj?.discount })}
                        // error={errors[`discount${index}`]}
                        error={formErrors?.length > 0 ? formErrors[index]?.discount : ""}
                    />
                </div>
            </div>
        </>
    );
};
