const initial = {
    dashboard_data: {}
}

const DashboardReducer = (state = initial, action) => {
    const { type, payload } = action;

    switch (type) {
        case "GET_BASHBOARD_DATA_ON_SELECTED_LOCATION":
            return {
                ...state,
                dashboard_data: payload,
            }

        default:
            return state
    }
}
export default DashboardReducer;