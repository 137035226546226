import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Svgs from "../../../../../Assets/svgs";
import LoginBtn from "../../../Elements/LoginBtn";
import Popup from "../../../Elements/Popup";
import { useSelector } from "react-redux";
import moment from "moment";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";

const ClientCard = ({ data, onEdit, index, onDelete, animate, countryName, translation, selectedLanguage, hideDeleteIcon }) => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  // *******************handle delete modal *********************
  const deleteModelHandler = () => {
    setDeleteModal(!deleteModal);
  };

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <>

      {animate ?
        // Shimmer
        <div className="rounded-xl shadow-appoint p-3 flex flex-col gap-3 animate-pulse">
          <div className="flex items-start justify-between">
            <div>
              <div className="h-[4.375rem] w-[4.375rem] rounded-full overflow-hidden bg-gray-200 "></div>
            </div>
          </div>

          <div className=" bg-[#F8FBFB] border-2 border-[#E1E7FF] p-2 rounded-lg">
            <div>
              <p className="bg-gray-200 rounded-[4px] h-6 my-1 w-2/3 mx-1"></p>
              <p className="bg-gray-300 rounded-[4px] h-5 my-1 w-4/5 mx-1"></p>
            </div>
            <div className="text-sm flex flex-col gap-2 text-[#101928]">
              <div className="flex items-center">
                <p className="bg-gray-200 rounded-[4px] h-5 w-4/5 mx-1"></p>
              </div>
              <div className="flex items-center">
                <p className="bg-gray-300 rounded-[4px] h-5 w-3/5 mx-1"></p>
              </div>
              <div className="flex items-center">
                <p className="bg-gray-300 rounded-[4px] h-5 w-4/5 mx-1"></p>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="rounded-xl shadow-appoint p-3 flex flex-col gap-3">
          <div className="flex items-start justify-between">
            <div>
              <div className="h-[4.375rem] w-[4.375rem] rounded-full relative">
                {data?.image ? (
                  <img src={data?.image} className="h-full w-full object-cover rounded-full" />
                ) : (
                  // <Svgs.AvatarClient />
                  <LabelAvatar iconLabel={data?.full_name} index={index} className="rounded-full" />
                )}
                <span className={`absolute z-10 bottom-1 right-1.5 h-2.5 w-2.5 rounded-full ${data?.is_active ? 'bg-green-500' : 'bg-red-500'}  content-['']`} />
              </div>
            </div>
            <div className="flex items-center gap-3">
              {!employee ||
                (employee &&
                  employeePermissions?.client_profile?.includes("edit")) ? (
                <div
                  onClick={() => onEdit(data?.id)}
                  className="h-[2.5rem] w-[2.5rem] border border-[#E1E7FF] rounded-lg bg-[#F8FBFB] flex items-center justify-center cursor-pointer"
                >
                  <span>
                    <Svgs.Pen />
                  </span>
                </div>
              ) : (
                ""
              )}
              {(!employee && !hideDeleteIcon) ? (
                <div
                  onClick={() => deleteModelHandler()}
                  className="h-[2.5rem] w-[2.5rem] border border-[#E1E7FF] rounded-lg bg-[#F8FBFB] flex items-center justify-center cursor-pointer"
                >
                  <span>
                    <Svgs.Delete fill="#CE0000" />
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className=" bg-[#F8FBFB] border-2 border-[#E1E7FF] p-2 rounded-lg">
            <h1 className="font-semibold font-2xl line-clamp-1">
              {data?.full_name}
            </h1>
            <div className="flex items-center gap-2">
              <div className="w-[20px]">
                <Svgs.Email className={'!w-5'} />
              </div>
              <p className="line-clamp-1">{data?.email ? data?.email : "------"}</p>
            </div>
            <div className="text-sm flex flex-col gap-2 text-[#101928]">
              <div className="flex items-center gap-2">
                <div className="w-[20px]">
                  <Svgs.Phone />
                </div>
                <p>{data?.mobile_number ? data?.mobile_number : "------"}</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-[20px]">
                  <Svgs.Location_baisc />
                </div>
                <p>
                  {data?.country_obj?.name ? data?.country_obj?.name : "------"}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <div className=" font-semibold">
                  <p>Last Transaction: </p>
                </div>
                <p>
                  {data?.last_transaction_date ? moment(data?.last_transaction_date).format('DD-MM-YYYY') : "------"}
                </p>
              </div>
            </div>
          </div>
        </div>
      }

      {deleteModal && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this Client?" : translation[464]?.value ? translation[464]?.value : "Are you sure want to delete this Client?"}
          onclose={deleteModelHandler}
        >
          <div>
            <p>
              {selectedLanguage?.name == "English" ? "Are you sure you want to delete this Client? You won't be able to see this again." : translation[463]?.value ? translation[463]?.value : "Are you sure you want to delete this Client? You won't be able to see this again."}
            </p>
            <div className="flex justify-end mt-4">
              <LoginBtn
                deleteBtn
                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                onClick={async () => {
                  await onDelete(data?.id);
                  deleteModelHandler();
                }}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default ClientCard;
