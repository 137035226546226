import { selectedLocation } from "Redux/TenantRedux/Actions/locationActions";
import { useDispatch, useSelector } from "react-redux";

export default function useSetDataInLocalStorage() {
    const state = useSelector(state => state)
    const dispatch = useDispatch()

    const storeDataInLocalStorage = (key, data) => {
        localStorage.setItem(key, JSON.stringify(data));

        switch (key){
            case 'selected_location':
                let id = data
                id = id?.replaceAll('"', '')
                dispatch(selectedLocation(id))
        }

    }

    const getDataFromLocalStorage = (key) => {
        const localStorageData = localStorage.getItem(key);
        if(localStorageData){
            const result = JSON.parse(localStorageData);
            return result;
        }
        return null
    }

    return {
        storeDataInLocalStorage,
        getDataFromLocalStorage
    }
}
