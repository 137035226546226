/* eslint-disable array-callback-return */
import { useState, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useValidations from 'Hooks/useValidations';
import { getStoreTarget, createStoreTarget, updateStoreTarget, deleteStoreTarget, getStoreTargetWithNoPagination } from './../../../../../../Adapters/Api/storeTarget/index';
import useSetDataInLocalStorage from './../../../../../../Hooks/useSetDataInLocalStorage';
import { toast } from 'react-toastify';
import { getLocations } from './../../../../../../Adapters/Api/index';
import { MONTHS } from 'Constants/Data/Months';
import { apiErrorMessage } from 'Constants/Data/Errors';
import useObjectKeys from 'Hooks/useObjectKeys';

let timeOutId = undefined
function useStoreTarget() {
    const { objectKeyConvertToArray } = useObjectKeys()
    const { errors, setErrors, validation, onBlurHandler } = useValidations()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const access_token = getDataFromLocalStorage("access_token");
    const businessData = getDataFromLocalStorage("business_data");

    const formRef = useRef()
    const dispatch = useDispatch()
    const state = useSelector((state) => state)
    const locationList = state?.locations?.locations;
    const storeTargetList = state?.storeTarget?.store_targets
    const allStoreTargetList = state?.storeTarget?.all_store_targets
    const pages = state?.storeTarget?.pages
    const allCount = state?.storeTarget?.allCount
    const currency = state?.utility?.currency?.location_currency;
    const selected_location = state.locations.selected_location;
    const store_target_updated = state?.storeTarget?.store_target_updated
    const [AddStore_Target, setAddStore_Target] = useState(false);
    const [CopyStore_Target, setCopyStore_Target] = useState(false);
    const [AddNewTarget, setAddNewTarget] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [allLocations, setAllLocations] = useState([]);
    const [searchText, setSearchText] = useState();
    const [searchYear, setSearchYear] = useState();
    const [searchLocation, setSearchLocation] = useState();
    const [is_delete, setDelete] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [editId, setEditId] = useState();
    const [monthFilter, setMonthFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [exportLoader, setExportLoader] = useState(false);
    const [tierError, setTierError] = useState()
    const [copyStoreTarget, setCopyStoreTarget] = useState({
        from_month: "",
        to_month: "",
    });
    const [storeData, setStoreData] = useState({
        location: "",
        store_tier: [{
            month: "",
            service_target: "",
            retail_target: "",
            voucher_target: "",
            membership_target: "",
            is_primary: false,
        }]
    });

    const [data, setData] = useState({
        current_year: '',
        current_month: '',
        current_date: '',
        selected_month: '',
    })
    const [selectedLocationCurrencyCode, setSelectedLocationCurrencyCode] = useState()
    const PageSize = 10

    //get business location and stored it in redux
    const getBusinessLocation = (business_id) => {
        dispatch(getLocations(business_id));
    };

    useEffect(() => {
        let date = new Date();
        let today_year = date.getFullYear();
        let today_month = date.getMonth();
        let today_date = date.getDate();
        let today_day = date.getDay();

        setData({
            ...data,
            current_year: today_year,
            current_month: today_month,
            current_date: today_date,
            selected_month: MONTHS[today_month],
        });
    }, [])

    const yearDropdown = useMemo(() => {
        if (data.current_year) {
            let years = [];
            for (let i = data.current_year; i <= 2040; i++) {
                years.push({ label: i.toString(), value: i.toString() })
            }
            return years;
        }
    }, [data.current_year])

    useEffect(() => {
        if (state?.locations?.locations) {
            setAllLocations(state?.locations?.locations?.map(itm => itm))
        }
    }, [state?.locations?.locations])

    const locationDropdown = useMemo(() => {
        if (allLocations) {
            return allLocations?.map(({ address_name, id }) => ({
                label: address_name,
                value: id.toString()
            }));
        }
    }, [allLocations]);

    useEffect(() => {
        if (locationList?.length === 0) {
            getBusinessLocation(businessData?.id);
        }
        // dispatch(getStoreTarget())
    }, [selected_location, storeTargetList.length])

    useEffect(() => {
        if (searchText === null) {
            // Initial load with empty searchText
            dispatch(getStoreTarget(searchLocation, currentPage, "", monthFilter, searchYear, setLoader))
        } else {
            if (timeOutId) {
                clearTimeout(timeOutId)
            }
            timeOutId = setTimeout(() => {
                dispatch(getStoreTarget(searchLocation, currentPage, searchText, monthFilter, searchYear, setLoader))
            }, 500);
        }
    }, [currentPage, searchText, monthFilter, searchYear, selected_location, searchLocation])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchText, monthFilter, searchYear, selected_location, searchLocation])

    useEffect(() => {
        dispatch(getStoreTargetWithNoPagination())
    }, [])

    useEffect(() => {
        if (storeData?.location) {
            setSelectedLocationCurrencyCode(locationList?.find(loc => loc?.id == storeData?.location)?.currency?.code)
        }
    }, [storeData?.location])

    useMemo(() => {
        // const val = storeTargetList?.map((element) => {
        //     if (element?.location?.id === selected_location) {
        //         return { ...element }
        //     }
        // });
        // const storeTargetArr = val.filter((element) => element !== undefined);
        // setDataList(storeTargetArr);
        setDataList(storeTargetList);
    }, [storeTargetList, selected_location]);

    //handle change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setStoreData((prev) => ({
            ...prev,
            [name]: value
        }))
        setErrors((prev) => ({
            ...prev,
            [name]: ""
        }))
    }

    // onSubmit store target
    const onSubmitStoreTarget = () => {
        const result = validation({ location: storeData?.location });
        setErrors(validation({ location: storeData?.location }));
        let tier = storeData.store_tier.map((obj) => {
            if (obj.is_primary === true) {
                return { ...obj, is_primary: obj.is_primary && 'True' }
            }
            else {
                return obj
            }
        })
        let submit = false
        let all_errors = [];

        (storeData.store_tier)?.forEach((item, index) => {
            let err_obj = {}
            if (item.year && item.month && item?.retail_target && item.membership_target && item?.voucher_target && item?.service_target && storeData.store_tier?.every(
                (itm) =>
                    itm?.year !== "" &&
                    itm?.month !== "" &&
                    itm?.retail_target !== 0 &&
                    itm?.membership_target !== 0 &&
                    itm?.voucher_target !== 0 &&
                    itm?.service_target !== 0
            )) {
                submit = true
            } else {
                err_obj.retail_target = item.retail_target !== '0' ? !(item.retail_target) : '0'
                err_obj.membership_target = item.membership_target !== '0' ? !(item.membership_target) : '0'
                err_obj.voucher_target = item.voucher_target !== '0' ? !(item.voucher_target) : '0'
                err_obj.service_target = item.service_target !== '0' ? !(item.service_target) : '0'
                err_obj.year = item.year !== '' ? !(item.year) : ''
                err_obj.month = item.month == '' ? !(item.month) : ''
                err_obj.index = index
                all_errors.push(err_obj)
                formRef.current?.scrollIntoView({ behavior: "smooth" });
            }
        });
        setTierError(all_errors)
        if (submit) {
            if (tier && objectKeyConvertToArray(result)?.length === 0) {
                let payload = {
                    location: storeData.location,
                    store_tier: tier
                }
                setIsLoading(true)
                dispatch(
                    createStoreTarget(
                        { ...payload, business: businessData?.id },
                        access_token,
                        (message) => {
                            toast.success(message ? message : "Store target created successfully!", { toastId: "toast" })
                            setAddStore_Target(false)
                            setStoreData({
                                location: "",
                                store_tier: [{
                                    month: "",
                                    service_target: "",
                                    retail_target: "",
                                    voucher_target: "",
                                    membership_target: "",
                                    is_primary: false,
                                }]
                            })
                            setIsLoading(false)
                            setCurrentPage(1)
                            dispatch(getStoreTargetWithNoPagination())
                            dispatch(getStoreTarget(searchLocation, 1, searchText, monthFilter, searchYear, setLoader))
                        },
                        () => {
                            setAddStore_Target(false)
                            toast.error(apiErrorMessage, { toastId: "toast" })
                            setIsLoading(false)
                        }

                    )
                )
            }
        }
    }
    //set state of edit record
    const onEidtStateData = (itm) => {
        const tier = itm?.tier?.map((obj) => {
            return {
                ...obj,
                year: obj?.year?.slice(0, 4)
            }
        })
        setEditId(itm?.id);
        setAddStore_Target(true)
        setStoreData({
            id: itm?.id,
            location: itm?.location?.id,
            store_tier: tier && tier,
        })
    }

    //update store target handle here
    const onUpdateStoreTarget = () => {
        const result = validation({ location: storeData?.location });
        setErrors(validation({ location: storeData?.location }));
        let submit = false
        let tier = storeData.store_tier.map((obj) => {
            if (obj.is_primary === true) {
                return { ...obj, is_primary: obj.is_primary && 'True' }
            }
            else {
                return obj
            }
        })
        let all_errors = [];
        (storeData.store_tier)?.forEach((item, index) => {
            let err_obj = {}
            if (item.year && item.month && item?.retail_target && item.membership_target && item?.voucher_target && item?.service_target && storeData.store_tier?.every(
                (itm) =>
                    itm?.year !== "" &&
                    itm?.month !== "" &&
                    itm?.retail_target != '0' &&
                    itm?.membership_target != '0' &&
                    itm?.voucher_target != '0' &&
                    itm?.service_target != '0'
            )) {
                if (tier && objectKeyConvertToArray(result)?.length === 0) {
                    submit = true
                } else {
                    err_obj.retail_target = item.retail_target !== '0' ? !(item.retail_target) : '0'
                    err_obj.membership_target = item.membership_target !== '0' ? !(item.membership_target) : '0'
                    err_obj.voucher_target = item.voucher_target !== '0' ? !(item.voucher_target) : '0'
                    err_obj.service_target = item.service_target !== '0' ? !(item.service_target) : '0'
                    err_obj.year = item.year !== '' ? !(item.year) : ''
                    err_obj.month = item.month == '' ? !(item.month) : ''
                    err_obj.index = index
                    all_errors.push(err_obj)
                    formRef.current?.scrollIntoView({ behavior: "smooth" });
                }
            }
        });
        setTierError(all_errors)

        if (submit) {
            let payload = {
                id: storeData?.id,
                location: storeData.location,
                store_tier: tier
            }
            setIsLoading(true)
            dispatch(
                updateStoreTarget(
                    payload,
                    access_token,
                    (message) => {
                        toast.success(message ? message : "Store target updated successfully!", { toastId: "toast" })
                        setAddStore_Target(false)
                        setStoreData({
                            location: "",
                            store_tier: [{
                                month: "",
                                service_target: "",
                                retail_target: "",
                                voucher_target: "",
                                membership_target: "",
                                is_primary: false,
                            }]
                        })
                        setIsLoading(false)
                        setCurrentPage(1)
                        dispatch(getStoreTargetWithNoPagination())
                        dispatch(getStoreTarget(searchLocation, 1, searchText, monthFilter, searchYear, setLoader))
                        setEditId()
                    },
                    () => {
                        setIsLoading(false)
                        toast.error(apiErrorMessage, { toastId: "toast" })

                    }
                )
            )
        }
    }

    // delete store target here
    const onDeleteHandler = () => {
        dispatch(
            deleteStoreTarget(
                deleteId,
                access_token,
                (message) => {
                    if (!isToastVisible) {
                        toast.success(message, {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }
                    setDelete(false)
                    setCurrentPage(1)
                    dispatch(getStoreTargetWithNoPagination())
                    dispatch(getStoreTarget(searchLocation, 1, searchText, monthFilter, searchYear, setLoader))
                },
                () => {
                    if (!isToastVisible) {
                        toast.success(apiErrorMessage, {
                            onClose: () => setIsToastVisible(false),
                        });
                        setIsToastVisible(true);
                    }

                }
            )
        )
    }


    // search and month filter 
    const store_target_fiter = () => {
        // const filter_data = dataList?.filter((itm) => {
        //     if (monthFilter || searchText || searchYear || searchLocation) {
        //         let return_value = false;
        //         if (monthFilter) {
        //             let available_months = itm?.tier?.filter(obj => obj.month.toLowerCase() === monthFilter?.toLocaleLowerCase())
        //             if (available_months.length > 0) {
        //                 // }
        //                 // if (itm?.tier[0]?.month.toLowerCase() === monthFilter?.toLocaleLowerCase()) {
        //                 return_value = true;
        //             } else {
        //                 return false;
        //             }
        //         }
        //         if (searchText) {
        //             if (itm?.location?.address_name?.toLowerCase()?.includes(searchText?.toLocaleLowerCase())) {
        //                 return_value = true;
        //             } else {
        //                 return false;
        //             }
        //         }
        //         if (searchYear) {
        //             let available_years = itm?.tier?.filter(obj => obj.year?.slice(0, 4).toLowerCase() === searchYear?.toLocaleLowerCase())

        //             if (available_years.length > 0) {
        //                 return_value = true;
        //             } else {
        //                 return false;
        //             }
        //         }
        //         console.log(searchLocation, "searchLocation")
        //         if (searchLocation) {
        //             if (itm?.location?.id?.toLowerCase()?.includes(searchLocation?.toLocaleLowerCase())) {
        //                 return_value = true;
        //             } else {
        //                 return false;
        //             }
        //         }
        //         return return_value;
        //     }
        //     return true;
        // })
        const filter_data = dataList
        return filter_data;
    }

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    let storeTarget = [
        { label: selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id", key: "id" },
        { label: selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location", key: "location" },
        { label: selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service", key: "service" },
        { label: selectedLanguage?.name == "English" ? "Retail" : translation[604]?.value ? translation[604]?.value : "Retail", key: "retail" },
        { label: selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher", key: "voucher" },
        { label: selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership", key: "membership" },
        { label: selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month", key: "month" },
        { label: selectedLanguage?.name == "English" ? "is Primary" : translation[864]?.value ? translation[864]?.value : "is Primary", key: "is_primary" },
    ];

    const [CSV_data, setCSVData] = useState([])

    useEffect(() => {
        let new_data = []
        allStoreTargetList?.forEach((item) => {
            item?.tier?.forEach(inner_itm => {
                new_data.push({
                    id: item?.id.toString().split("-")[0],
                    location: item?.location?.address_name ? item?.location?.address_name : "----",
                    service: inner_itm?.service_target ? `${inner_itm?.service_target} ${currency}` : "----",
                    retail: inner_itm?.retail_target ? `${inner_itm?.retail_target} ${currency}` : "----",
                    voucher: inner_itm?.voucher_target ? `${inner_itm?.voucher_target} ${currency}` : "----",
                    membership: inner_itm?.membership_target ? `${inner_itm?.membership_target} ${currency}` : "----",
                    month: inner_itm?.month ? inner_itm?.month : "----",
                    is_primary: inner_itm?.is_primary ? inner_itm?.is_primary : "----",
                })
            })
        })
        setCSVData(new_data)
    }, [allStoreTargetList])

    const storeTargetData = {
        filename: "Store Target Report.csv",
        headers: storeTarget,
        data:
            CSV_data?.length > 0
                ?
                CSV_data
                : [],
    };

    const handleExportClick = () => {
        if (!isToastVisible) {
            if (allStoreTargetList?.length > 0) {
                // setExportLoader(true)
                setTimeout(() => {
                    toast.success("File downloaded successfully", {
                        toastId: "toast",
                        onClose: () => setIsToastVisible(false)
                    });
                    setExportLoader(false)
                }, 300);
            } else {
                toast.error("No data to export", {
                    onClose: () => setIsToastVisible(false),
                });
            }
            setIsToastVisible(true);
        }
    };

    return {
        AddStore_Target, dataList, CopyStore_Target, AddNewTarget, storeTargetData, errors, monthFilter, searchYear,
        is_delete, deleteId, storeData, searchText, storeTargetList, yearDropdown, store_target_updated, locationList, editId, currency,
        setAddStore_Target, setCopyStore_Target, setAddNewTarget, setErrors, onBlurHandler, setEditId, setTierError,
        setMonthFilter, store_target_fiter, setDelete, setDeleteId, setStoreData, handleChange,
        onSubmitStoreTarget, onEidtStateData, onUpdateStoreTarget, onDeleteHandler, CSV_data,
        setSearchText, setSearchYear, searchLocation, setSearchLocation, locationDropdown, selectedLocationCurrencyCode,
        currentPage, setCurrentPage, pages, allCount, PageSize, loader, setLoader, handleExportClick, exportLoader, tierError, formRef,
        isLoading
    }
}

export default useStoreTarget
