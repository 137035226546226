import Svgs from 'Assets/svgs'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import React from 'react'

const Footer = () => {
    return (
        <div className='bg-[#F5F6F8]'>
            <div className='nstyle-container mx-auto md:w-[85%] w-[95%]'>
                <div className='grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-[1.5rem] py-[2rem]'>
                    <div className='flex flex-col gap-3 items-center w-full md:items-start justify-center'>
                        <div className='flex items-center gap-3 w-fit cursor-pointer'>
                            <Svgs.LogoSvg />
                            <h1 className='text-primary font-semibold text-2xl'>NSTYLE</h1>
                        </div>
                        <div className='flex items-center gap-3'>
                            <Svgs.Facebook_filled />
                            <Svgs.Instagram_filled />
                            <Svgs.Twitter_filled />
                            <Svgs.LinkedIn_filled />
                        </div>
                    </div>
                    <div className='flex flex-col gap-4 md:text-start text-center'>
                        <h1 className='font-semibold text-xl'>About</h1>
                        <div className='flex flex-col gap-3'>
                            <p className='text-[#101928]/60 text-sm cursor-pointer'> Careers at NStyle</p>
                            <p className='text-[#101928]/60 text-sm cursor-pointer'> Client support</p>
                            <p className='text-[#101928]/60 text-sm cursor-pointer'> Blog</p>
                            <p className='text-[#101928]/60 text-sm cursor-pointer'> Sitemap</p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4 md:text-start text-center'>
                        <h1 className='font-semibold text-xl'>For Business</h1>
                        <div className='flex flex-col gap-3'>
                            <p className='text-[#101928]/60 text-sm cursor-pointer'> For Partners</p>
                            <p className='text-[#101928]/60 text-sm cursor-pointer'> Pricing</p>
                            <p className='text-[#101928]/60 text-sm cursor-pointer'> Support for partners</p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4 md:text-start text-center'>
                        <h1 className='font-semibold text-xl'>Legal</h1>
                        <div className='flex flex-col gap-3'>
                            <p className='text-[#101928]/60 text-sm cursor-pointer'> Privacy Policy</p>
                            <p className='text-[#101928]/60 text-sm cursor-pointer'> Terms of Service</p>
                            <p className='text-[#101928]/60 text-sm cursor-pointer'> Terms of Use</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer