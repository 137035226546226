import Svgs from "../../../../../Assets/svgs"
import { STAFF_LEVELS } from "../../../../../Constants/Data/StaffLevel"
import Dropwdown from "../../../Elements/Dropwdown"
import I_Info_Icon from "../../../Elements/I_Info_Icon"


const AddEmployeeServiceCard = ({ edit_able, data, selectedLanguage, translation, data_list, is_last, index, onEditClick, onDeleteClick, onUpdate, ...props }) => {
    let selected_data = data_list.find(itm => itm.id == data.service)
    return (
        <>

            <div className='flex gap-6'>
                <div className='relative'>
                    {
                        index != 0 &&
                        < div className='absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] -z-[1] top-0 left-1/2 -translate-x-1/2'></div>
                    }
                    <div className='flex flex-col absolute top-1/2 -translate-x-1/2 -translate-y-1/2 '>
                        <div className='h-[1.8rem] w-[1.8rem] rounded-full  border border-primary flex items-center justify-center bg-white'>
                            <Svgs.Appointment_Bullet />
                        </div>
                    </div>
                    {
                        !is_last &&
                        <div className='absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] -z-[1] top-1/2 left-1/2 -translate-x-1/2'></div>
                    }
                </div>
                {/* <div></div> */}
                <div className='mb-5 border border-[#EDEDED] rounded-md w-full flex flex-col gap-4 relative'>
                    <div
                        className='absolute cursor-pointer top-0 right-0 translate-x-1/2 -translate-y-1/2'
                        onClick={() => {
                            onDeleteClick()
                        }}
                    >
                        <Svgs.CirlceClose size='1.75rem' fill='#ED0F1C' />
                    </div>
                    <div className='bg-[#FCFDFE] rounded-lg px-6 py-6'>

                        <div className='grid lg:grid-cols-2 gap-4'>
                            <>
                                <Dropwdown
                                    required={false}
                                    name={"service"}
                                    value={data?.service}
                                    onChange={onUpdate}
                                    // onBlur={() => onBlurHandler({ services: services })}
                                    // error={errors.services}
                                    title={selectedLanguage?.name == "English" ? "Services" : translation[31]?.value ? translation[31]?.value : "Services"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Services" : translation[89]?.value ? translation[89]?.value : "Select Services"}
                                    options={
                                        data_list && data_list?.map(srvc => {
                                            return {
                                                label: srvc?.name,
                                                value: srvc?.id
                                            }
                                        })
                                    } />
                                <Dropwdown
                                    title={
                                        <I_Info_Icon text={<>
                                            {selectedLanguage?.name == "English" ? "Employee Level" : translation[505]?.value ? translation[505]?.value : "Employee Level"}
                                            <span className="text-red-600">*</span>
                                        </>}
                                            info='The employees will appear based on their level during appointment booking.' />
                                    }
                                    name="level"
                                    value={data?.level}
                                    onChange={onUpdate}
                                    // onBlur={() => onBlurHandler({ level: level })}
                                    // error={errors.level}
                                    placeholder={selectedLanguage?.name == " English" ? "Select Level " : translation[541]?.value ? translation[541]?.value : "Select Level "}
                                    options={STAFF_LEVELS}
                                />
                            </>
                        </div>
                        {/* 
                                <div className='flex items-center justify-between gap-4'>
                                    <div className='flex flex-col gap-2'>
                                        <h1 className='text-[#000000] font-bold text-2xl'>{selected_data?.name}</h1>
                                        <h1 className='text-[#555555] font-semibold'>{data?.level?.replace('_', ' ')}</h1>
                                    </div>
                                    <div className='flex items-center gap-3'>
                                        <div
                                            className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center'
                                            onClick={onEditClick}
                                        >
                                            <Svgs.Edit fill='#7D7D7D' />
                                        </div>
                                        <div
                                            className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center'
                                            onClick={onDeleteClick}
                                        >
                                            <Svgs.Delete />
                                        </div>
                                    </div>
                                </div> */}

                    </div>
                </div>
            </div>
        </>
    )
}

AddEmployeeServiceCard.defaultProps = {
    data_list: [],
    data: {},
    is_last: false,
    onEditClick: () => { },
    onDeleteClick: () => { },
    onUpdate: () => { },
}

export default AddEmployeeServiceCard