import Svgs from "Assets/svgs"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLocations } from "../../../../../Adapters/Api"
import { getBusinessTax } from "../../../../../Adapters/Api/financialSettings"
import LoginBtn from "../../../Elements/LoginBtn"
import { getLocationTax } from "Adapters/Api/saleServices"
import BookingDetailCardShimmer from "Components/Utility/Shimmers/BookingDetailCardShimmer"

const BookingServiceCard = ({ appointmentData, client, is_coupon_redeemed, couponsIds, couponType, service, currency, membership_service, price, membership_type, voucher_redeem }) => {
    const [discount, setDiscount] = useState(null)
    const [updatedPrice, setUpdatedPrice] = useState(null)

    useEffect(() => {
        setDiscount(null)
        setUpdatedPrice(price?.toFixed(2))
        if (voucher_redeem) {
            setUpdatedPrice(price - (voucher_redeem * price) / 100)
            setDiscount({ id: service?.service, percentage: voucher_redeem, client: client })
        }

        if (membership_service?.length > 0 && membership_type == "Free") {
            const matchedService = membership_service?.find(itm => itm?.service == service?.service)
            if (matchedService?.service == service?.service) {
                setUpdatedPrice(price - price)
                setDiscount({ id: service?.service, percentage: 100, client: client })
            }
        }

        if (membership_service?.length > 0 && membership_type == "Limited") {
            const serviceLimitedPercantage = membership_service?.find(itm => itm?.service == service?.service)?.percentage
            if (serviceLimitedPercantage) {
                setUpdatedPrice((+price - (+serviceLimitedPercantage * +price) / 100))
                setDiscount({ id: service?.service, percentage: serviceLimitedPercantage, client: client })
            }
        }

        if (is_coupon_redeemed === true) {
            if (couponType == 1 || couponType == 2) {
                let matchingId
                if (couponType == 1) {
                    matchingId = couponsIds?.length > 0 ? couponsIds?.find(itm => itm?.service == service?.service) : ''
                    if (matchingId && !matchingId?.is_coupon_redeemed) {
                        setDiscount({ id: matchingId?.service, percentage: matchingId?.discount_percentage, client: client })
                        setUpdatedPrice(matchingId?.discount_price)
                    }
                } else if (couponType == 2) {
                    matchingId = couponsIds?.length > 0 ? couponsIds?.find(itm => itm?.service == service?.service && service?.is_coupon_redeemed) : ''
                    if (matchingId) {
                        setDiscount({ id: matchingId?.service, percentage: matchingId?.discount_percentage, client: client })
                        setUpdatedPrice(0)
                    }
                }
            }
        }
    }, [service, price, voucher_redeem, membership_service, membership_type, couponType, couponsIds, is_coupon_redeemed, appointmentData])


    return (
        <>
            <div className='flex items-center justify-between gap-4 text-sm py-1'>
                <p className='text-[#7B7B7B]'>{service?.name} {" "}
                    {((discount?.client == client) && (discount?.id == service?.service)) ? `(Discount ${discount.percentage}%)` : ''}
                </p>
                <p className='text-[#7B7B7B] flex items-center gap-2'>
                    <span>{updatedPrice ? (+updatedPrice)?.toFixed(2) : 0} {currency}</span>
                </p>
            </div>
        </>
    )
}

const ClientBookingServiceCard = ({ setSelectedPaymentMethods, existingLoyalityPoints, setLoyalityPoints, appointmentData, setAppointmentData, setAppointmentService, services, location_currency, clientName, client, selectedLanguage, translation }) => {
    const filteredClientMembership = appointmentData?.membership_data?.length > 0 ? appointmentData?.membership_data?.find(itm => itm?.client == client) : undefined
    const filteredClientVoucher = appointmentData?.voucher_data?.length > 0 ? appointmentData?.voucher_data?.find(itm => itm?.client == client) : undefined
    const filteredClientCoupon = appointmentData?.coupon_data?.length > 0 ? appointmentData?.coupon_data?.find(itm => itm?.client == client) : undefined
    const filteredClientLoyalty = appointmentData?.loyalty_data?.length > 0 ? appointmentData?.loyalty_data?.find(itm => itm?.client == client) : undefined

    // on deleting voucher, membership, loyalty
    const onDeleteFunction = () => {
        setSelectedPaymentMethods([])
        if (filteredClientVoucher?.is_voucher_redeemed) {
            const updatedVoucherData = appointmentData?.voucher_data?.filter(itm => itm?.client != client)
            // remove discount with existing data in services
            const filteredAppointmentServices = appointmentData?.filteredClientsAppointments?.map(appointment => {
                if (client == appointment?.client) {
                    const filteredServicesPrices = appointment?.appointmnet_service?.map(srv => {
                        const data = { ...srv }
                        delete data?.is_voucher_redeemed
                        delete data?.discount_price
                        delete data?.discount_percentage
                        return data
                    })
                    return { ...appointment, appointmnet_service: filteredServicesPrices }
                } else {
                    return appointment
                }
            })
            setAppointmentData({ ...appointmentData, voucher_data: updatedVoucherData, filteredClientsAppointments: filteredAppointmentServices });
        } else if (filteredClientMembership?.is_membership_redeemed) {
            const updatedMembershipData = appointmentData?.membership_data?.filter(itm => itm?.client != client)
            // remove discount with existing data in services
            const filteredAppointmentServices = appointmentData?.filteredClientsAppointments?.map(appointment => {
                if (client == appointment?.client) {
                    const filteredServicesPrices = appointment?.appointmnet_service?.map(srv => {
                        const data = { ...srv }
                        delete data?.is_membersip_redeemed
                        delete data?.discount_price
                        delete data?.discount_percentage
                        return data
                    })
                    return { ...appointment, appointmnet_service: filteredServicesPrices }
                } else {
                    return appointment
                }
            })
            setAppointmentData({ ...appointmentData, membership_data: updatedMembershipData, filteredClientsAppointments: filteredAppointmentServices });
        } else if (filteredClientCoupon?.is_coupon_redeemed) {
            const filteredAppointmentServices = appointmentData?.filteredClientsAppointments?.map(appointment => {
                if (client == appointment?.client) {
                    let filteredServices = appointment?.appointmnet_service?.filter(service => !service?.is_coupon_redeemed);
                    if (filteredServices?.length > 0) {
                        filteredServices = filteredServices?.map(srv => {
                            const filteredService = filteredClientCoupon?.couponIds?.find(itm => itm?.service == srv?.service)
                            const data = { ...srv }
                            if (filteredService) {
                                delete data?.discount_percentage
                                delete data?.is_coupon_redeemed
                                delete data?.discount_price
                            }
                            return data
                        })
                        return { ...appointment, appointmnet_service: filteredServices }
                    }
                } else {
                    return appointment
                }
            });
            setAppointmentService(filteredAppointmentServices);
            const updatedCouponData = appointmentData?.coupon_data?.filter(itm => itm?.client != client)
            setAppointmentData({ ...appointmentData, coupon_data: updatedCouponData, filteredClientsAppointments: filteredAppointmentServices });
        } else if (filteredClientLoyalty?.is_redeemed) {
            const updatedLoyaltyData = appointmentData?.loyalty_data?.filter(itm => itm?.client != client)
            setAppointmentData({ ...appointmentData, loyalty_data: updatedLoyaltyData });
            setLoyalityPoints(existingLoyalityPoints)
        }
    }

    return (
        <div className="border !border-b border-solid p-2 rounded-xl">
            <p className='text-[#000000]  text-xl font-medium'>
                {clientName}
            </p>
            <div className="space-y-1 divide-y divide-[#E5E7EB]">
                {
                    services && services?.length > 0 ?
                        <>
                            {
                                services?.map((service, index) => {
                                    return (
                                        <BookingServiceCard
                                            couponsIds={filteredClientCoupon?.couponIds}
                                            couponType={filteredClientCoupon?.coupon_type_value}
                                            is_coupon_redeemed={filteredClientCoupon?.is_coupon_redeemed}
                                            service={service}
                                            key={index}
                                            currency={location_currency}
                                            clientName={clientName}
                                            voucher_redeem={filteredClientVoucher?.discount_percentage}
                                            membership_service={filteredClientMembership?.membership_service}
                                            membership_type={filteredClientMembership?.membership_type}
                                            price={service?.price}
                                            appointmentData={appointmentData}
                                            client={client}
                                        />
                                    )
                                })
                            }
                        </>
                        :
                        <>
                            {selectedLanguage?.name == "English" ? "No Service Selected" : translation[181]?.value ? translation[181]?.value : "No Service Selected"}
                        </>
                }
                {filteredClientMembership?.is_membership_redeemed &&
                    <div className='flex items-center justify-between gap-2 text-sm pt-2'>
                        <div className="flex items-center gap-2">
                            <LoginBtn
                                onClick={onDeleteFunction}
                            >
                                <Svgs.Delete fill="#fff" />
                            </LoginBtn>
                            <p className='font-semibold'>
                                {selectedLanguage?.name == "English" ? "Membership Redeemed" : translation[219]?.value ? translation[219]?.value : "Membership Redeemed"}
                            </p>
                        </div>
                        <p className='text-[#A1A1A1] flex items-center gap-2'>
                            <span>{(+filteredClientMembership?.discount)?.toFixed(2)} {location_currency}</span>
                        </p>
                    </div>
                }
                {filteredClientVoucher?.is_voucher_redeemed &&
                    <div className='flex items-center justify-between gap-2 text-sm pt-2'>
                        <div className="flex items-center gap-2">
                            <LoginBtn
                                onClick={onDeleteFunction}
                            >
                                <Svgs.Delete fill="#fff" />
                            </LoginBtn>
                            <p className='font-semibold'>
                                {selectedLanguage?.name == "English" ? "Voucher Redeemed" : translation[1695]?.value ? translation[1695]?.value : "Voucher Redeemed"}
                            </p>
                        </div>
                        <p className='text-[#A1A1A1] flex items-center gap-2'>
                            <span>{(+filteredClientVoucher?.discount)?.toFixed(2)} {location_currency}</span>
                        </p>
                    </div>
                }

                {filteredClientCoupon?.is_coupon_redeemed &&
                    <div className='flex items-center justify-between gap-2 text-sm pt-2'>
                        <div className="flex items-center gap-2">
                            <LoginBtn
                                onClick={onDeleteFunction}
                            >
                                <Svgs.Delete fill="#fff" />
                            </LoginBtn>
                            <p className='font-semibold'>
                                {selectedLanguage?.name == "English" ? "Coupon Redeem " : translation[1480]?.value ? translation[1480]?.value : "Coupon Redeem "}
                            </p>
                        </div>
                        {(filteredClientCoupon?.discount && filteredClientCoupon?.discount !== '0') &&
                            <p className='text-[#A1A1A1] flex items-center gap-2'>
                                <span>{(+filteredClientCoupon?.discount)?.toFixed(2)} {location_currency}</span>
                            </p>
                        }
                    </div>
                }
                {filteredClientLoyalty?.is_redeemed &&
                    <div className='flex items-center justify-between gap-2 text-sm pt-2'>
                        <LoginBtn
                            onClick={onDeleteFunction}
                        >
                            <Svgs.Delete fill="#fff" />
                        </LoginBtn>
                        <div className="flex justify-between w-11/12 flex-1 ">
                            <p className='font-semibold'>
                                {selectedLanguage?.name == "English" ? "Redeemed Loyality Points " : translation[220]?.value ? translation[220]?.value : "Redeemed Loyality Points "}</p>
                            <p className='text-[#A1A1A1] flex items-center gap-2'>
                                <span>{(+filteredClientLoyalty?.redeemed_amount)?.toFixed(2)} {location_currency}</span>
                            </p>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}


const GroupBookingDetailCard = ({ setSelectedPaymentMethods, cartChange, setCartChange, cartRemainingPrice, setCartRemainingPrice, existingLoyalityPoints, setLoyalityPoints, isFromCheckout, setAppliedTax, setCartTotal, cartTotal, totalAmountPaid, loading, allowCheckout, checkoutLoader, setAppointmentService, setAppointmentData, appointmentData, payNowBtnDisabled, onTipDelete, services, location, onPayClick, payNowBtn, price, tips, setT_price, payBtnDisabled }) => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const singleTaxName = state?.saleService?.location_tax?.tax ? state?.saleService?.location_tax?.tax?.parent_tax[0]?.name : ""
    const reduxlocationsList = state?.locations?.locations;
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");
    const location_currency = getDataFromLocalStorage("selectedLocationCurrency");
    const taxTypeForGroup = localStorage.getItem('taxTypeForGroup')

    const [buttondisable, setButtonDisable] = useState(true)
    const [taxApplied, setLocationTax] = useState(0)
    const [taxType, setTaxType] = useState()
    const [total_price, setTotalPrice] = useState(0)
    const [taxForGroup, setTaxForGroup] = useState(null)
    const [singleTax, setSingleTax] = useState([])
    const [tax, setTax] = useState()
    const [taxLoader, setTaxLoader] = useState(true)
    const [showReset, setShowReset] = useState(false)
    const [subTotal, setSubTotal] = useState(0)

    // delete gift card
    const onDeleteGiftCard = () => {
        setAppointmentData({
            ...appointmentData,
            gift_card_data: []
        })
    }

    // on reset button click 
    const onResetClick = () => {
        // remove discount with existing data in services
        const filteredAppointmentServices = appointmentData?.filteredClientsAppointments?.map(appointment => {
            let filteredServices = appointment?.appointmnet_service?.filter(service => !service?.is_coupon_redeemed);
            const filteredServicesPrices = filteredServices?.map(srv => {
                const data = { ...srv }
                delete data?.is_voucher_redeemed
                delete data?.discount_price
                delete data?.discount_percentage
                delete data?.is_membersip_redeemed
                delete data?.is_coupon_redeemed
                return data
            })
            return { ...appointment, appointmnet_service: filteredServicesPrices }
        })
        setAppointmentData({ ...appointmentData, loyalty_data: [], coupon_data: [], membership_data: [], voucher_data: [], gift_card_data: [], filteredClientsAppointments: filteredAppointmentServices });

    }

    useEffect(() => {
        if (appointmentData?.membership_data?.length > 0 || appointmentData?.voucher_data?.length || appointmentData?.coupon_data?.length || appointmentData?.loyalty_data?.length || appointmentData?.gift_card_data?.length) {
            setShowReset(true)
        } else {
            setShowReset(false)
        }
    }, [appointmentData?.membership_data?.length, appointmentData?.voucher_data?.length, appointmentData?.coupon_data?.length, appointmentData?.loyalty_data?.length, appointmentData?.gift_card_data?.length])

    useEffect(() => {
        let remaining_price = 0
        remaining_price = +(+total_price + (taxApplied ? +taxApplied : 0) - (totalAmountPaid ? +totalAmountPaid : 0))
        if (remaining_price < 0) {
            setCartRemainingPrice(0.00)
            setCartChange(Math.abs(remaining_price)?.toFixed(2))
        } else {
            setCartChange(0.00)
            setCartRemainingPrice(Math.abs(remaining_price)?.toFixed(2))
        }
    }, [total_price, taxApplied, totalAmountPaid])

    useEffect(() => {
        let price = +total_price + (taxApplied ? +taxApplied : 0)
        if (appointmentData?.gift_card_data?.length > 0) {
            price = price - appointmentData?.gift_card_data[0]?.partial_price
        }
        setCartTotal && setCartTotal((+price)?.toFixed(2))
    }, [total_price, taxApplied, appointmentData?.gift_card_data])

    useEffect(() => {
        let disable = false
        if (payNowBtnDisabled || checkoutLoader || !allowCheckout || payBtnDisabled
            || (window.location.href?.includes("/group-checkout/finish") && (+cartRemainingPrice > 0))) {
            disable = true
        } else {
            disable = false
        }
        setButtonDisable(disable)
    }, [payNowBtnDisabled, checkoutLoader, cartRemainingPrice, allowCheckout, payBtnDisabled])

    useEffect(() => {
        let total_price = price ? price : 0
        let total_tips = 0

        if (appointmentData?.membership_data?.length > 0) {
            const membershipTotalPrice = appointmentData?.membership_data?.reduce((total, service) => total + +service?.discount, 0);
            total_price -= membershipTotalPrice
        }

        if (appointmentData?.voucher_data?.length > 0) {
            const voucherTotalPrice = appointmentData?.voucher_data?.reduce((total, service) => total + +service?.discount, 0);
            total_price -= voucherTotalPrice
        }

        if (appointmentData?.coupon_data?.length > 0) {
            const couponTotalPrice = appointmentData?.coupon_data?.reduce((total, service) => total + +service?.discount, 0);
            total_price -= couponTotalPrice
        }

        if (appointmentData?.loyalty_data?.length > 0) {
            const loyaltyTotalDiscount = appointmentData?.loyalty_data?.reduce((total, service) => total + +service?.redeemed_amount, 0);
            total_price -= loyaltyTotalDiscount
        }

        if (tips?.length > 0) {
            const total_tip_amount = tips?.reduce(function (total, obj) {
                return total + (+obj?.tip);
            }, 0)
            total_tips = +total_tip_amount
            total_price = total_price + total_tips
        }
        setSubTotal(total_price - total_tips)
        setTotalPrice(total_price)
        if (setT_price) setT_price(total_price)
    }, [services, tips, price, appointmentData])

    useEffect(() => {
        if (state.business.business?.id) {
            dispatch(getLocations(state.business.business?.id))
            dispatch(getBusinessTax(state.business.business?.id))
        }

    }, [state.business.business])

    useEffect(() => {
        if (selected_location) {
            dispatch(getLocationTax(selected_location))
        }
    }, [selected_location])

    useEffect(() => {
        let lctin = reduxlocationsList.find((itm) => itm?.id === selected_location);
        if (lctin != undefined || lctin != null) {
            if ((Object.keys(lctin).includes("currency")) && (lctin?.currency?.code)) {
                dispatch({
                    type: "LOCATION_CURRENCY",
                    payload: lctin?.currency?.code,
                })
            }
        }
    }, [selected_location, reduxlocationsList?.length])

    useEffect(() => {
        let total_tips = 0
        let tax_amount = 0
        if (tips?.length > 0) {
            const total_tip_amount = tips?.reduce(function (total, obj) {
                return total + (+obj?.tip);
            }, 0)
            total_tips = +total_tip_amount
        }

        let location_taxes = []
        let allTaxes = []
        if (state.financialSettings.businessTaxs.length > 0) {
            location_taxes = state.financialSettings.businessTaxs.filter(itm => itm.tax_type == 'Location' && itm?.location?.id == selected_location)
            setTaxType(location_taxes[0]?.parent_tax[0]?.tax_type)
        }
        if (location_taxes?.length > 0) {
            allTaxes = location_taxes[0]?.parent_tax[0]?.parent_tax
            setTaxForGroup(location_taxes[0]?.parent_tax[0]?.parent_tax)
        }
        if (taxType == 'Group') {
            let tax1Price = 0
            let tax2Price = 0
            let totalPriceWithTax1 = 0
            if (taxTypeForGroup == 'Seperately') {
                tax1Price = (allTaxes[0]?.tax_rate * (total_price - total_tips)) / 100
                totalPriceWithTax1 = +tax1Price + (+total_price - +total_tips)
                tax2Price = allTaxes[1]?.tax_rate * totalPriceWithTax1 / 100
                tax_amount = +tax1Price + +tax2Price
                allTaxes?.forEach((tx, i) => {
                    if (i == 0) {
                        tx.value = tax1Price
                    } else {
                        tx.value = tax2Price
                    }
                })
            } else {
                const totalCombinedTax = allTaxes?.reduce(function (total, obj) {
                    return total + +(obj?.tax_rate);
                }, 0)
                tax1Price = (allTaxes[0]?.tax_rate * (total_price - total_tips)) / 100
                tax2Price = allTaxes[1]?.tax_rate * (total_price - total_tips) / 100
                allTaxes?.forEach((tx, i) => {
                    if (i == 0) {
                        tx.value = tax1Price
                    } else {
                        tx.value = tax2Price
                    }
                })
                tax_amount = (+totalCombinedTax * (total_price - total_tips)) / 100
            }
        }
        else {
            const singleData = location_taxes[0]?.parent_tax[0]
            let tax_perc = singleData?.tax_rate
            setTax(tax_perc)
            tax_amount = (tax_perc * (total_price - total_tips)) / 100
            setSingleTax([{ name: singleData?.name, tax_rate: singleData?.tax_rate, business_tax_id: singleData?.id, value: tax_amount?.toFixed(2) }])
        }

        setAppliedTax && setAppliedTax(+tax_amount)
        setLocationTax(+tax_amount)
        // setCalculatedTax(+tax_amount)
    }, [tax, total_price, tips, state.financialSettings.businessTaxs, state.financialSettings.businessTaxs.length, taxTypeForGroup, selected_location, taxType])

    useEffect(() => {
        if (taxType) {
            if (taxType == 'Group' && taxForGroup?.length > 0) {
                setTaxLoader(false)
            } else if (tax?.length > 0) {
                setTaxLoader(false)
            } else {
                setTaxLoader(false)
            }
        } else {
            setTaxLoader(false)
        }
    }, [taxType, taxForGroup?.length, tax?.length])

    return (
        <>
            <div className='rounded-[10px] appointment-shadow border-[1px] border-solid border-[#E5E7EB]'>
                {!loading && !taxLoader ?
                    <>
                        <div className='px-6 p-3'>
                            <h2 className='font-semibold text-[#101928] text-2xl'>
                                {selectedLanguage?.name == "English" ? "Cart Details" : translation[1551]?.value ? translation[1551]?.value : "Cart Details"}
                            </h2>

                        </div>
                        <div className='flex flex-col'>
                            <div className="p-2 flex flex-col gap-3 border-y max-h-[50vh] overflow-y-auto">
                                <div className="px-4  flex flex-col gap-3">
                                    {
                                        services && services?.length > 0 ?
                                            <div className='flex flex-col gap-2 divide-y divide-[#E5E7EB]'>
                                                {
                                                    services?.map((appointment, index) => {
                                                        return (
                                                            <ClientBookingServiceCard
                                                                services={appointment?.appointmnet_service}
                                                                key={index}
                                                                location_currency={location_currency}
                                                                clientName={appointment?.client_name}
                                                                client={appointment?.client}
                                                                selectedLanguage={selectedLanguage}
                                                                translation={translation}
                                                                appointmentData={appointmentData}
                                                                setAppointmentData={setAppointmentData}
                                                                setAppointmentService={setAppointmentService}
                                                                existingLoyalityPoints={existingLoyalityPoints}
                                                                setLoyalityPoints={setLoyalityPoints}
                                                                setSelectedPaymentMethods={setSelectedPaymentMethods}
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            <>
                                                {selectedLanguage?.name == "English" ? "No Service Selected" : translation[181]?.value ? translation[181]?.value : "No Service Selected"}
                                            </>
                                    }
                                </div>

                                <hr />
                                <div className='flex items-center justify-between gap-2 text-sm px-4'>
                                    <p className='font-semibold'>{selectedLanguage?.name == "English" ? "Subtotal" : translation[1712]?.value ? translation[1712]?.value : "Subtotal"}</p>
                                    <p className='text-[#A1A1A1] flex items-center gap-2'>
                                        {(+subTotal)?.toFixed(2)} {location_currency}
                                    </p>
                                </div>
                                <div className="px-4 py-1 flex flex-col gap-3">

                                    {taxType == 'Group' ?
                                        taxForGroup?.map((itm, index) => {
                                            return (
                                                <div key={index} className='flex items-center justify-between gap-4 text-sm '>
                                                    <p className='font-semibold'>{itm?.name} ( {itm?.tax_rate}% )</p>
                                                    <p className='text-[#101928] font-medium flex items-center gap-2'>
                                                        <span>{itm?.value?.toFixed(2)} {location_currency}</span>
                                                    </p>
                                                </div>
                                            )
                                        })
                                        : tax > 0 &&
                                        <div className='flex items-center justify-between gap-4 text-sm '>
                                            <p className='font-semibold'>{singleTaxName} ({tax}%)</p>
                                            <p className='text-[#101928] font-medium flex items-center gap-2'>
                                                <span>
                                                    {taxApplied?.toFixed(2)} {location_currency}
                                                </span>
                                            </p>
                                        </div>
                                    }
                                    {
                                        tips?.length > 0 ?
                                            tips?.map(tp => <>
                                                <div className='flex items-center justify-between gap-2 text-sm '>
                                                    {
                                                        !tp?.hide_delete_icon &&
                                                        <LoginBtn
                                                            onClick={() => {
                                                                onTipDelete(tp)
                                                            }}
                                                            className={'bg-none'}
                                                        >
                                                            <Svgs.Delete fill="#fff" />
                                                        </LoginBtn>
                                                    }
                                                    <div className="flex justify-between w-11/12 flex-1">

                                                        <p className='font-semibold'>
                                                            {selectedLanguage?.name == "English" ? "Tip for" : translation[188]?.value ? translation[188]?.value : "Tip for"} {" "}

                                                            {tp.employee}</p>

                                                        <p className='text-[#101928] font-medium flex items-center gap-2'>
                                                            <span>{(+tp.tip)?.toFixed(2)} {location_currency}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </>)
                                            :
                                            <></>
                                    }

                                    {appointmentData?.gift_card_data?.length > 0 &&
                                        <div className='flex items-center justify-between gap-2 text-sm '>
                                            <LoginBtn
                                                onClick={onDeleteGiftCard}
                                            >
                                                <Svgs.Delete fill="#fff" />
                                            </LoginBtn>
                                            <div className="flex justify-between w-11/12 flex-1 ">
                                                <p className='font-semibold'>
                                                    {selectedLanguage?.name == "English" ? "Gift Card Redeem" : translation[1574]?.value ? translation[1574]?.value : "Gift Card Redeem"}
                                                </p>
                                                <div className="flex items-center justify-between gap-2 text-sm ">
                                                    <p className='text-[#A1A1A1] flex items-center gap-2'>
                                                        <span>{(+appointmentData?.gift_card_data[0]?.partial_price)?.toFixed(2)} {location_currency}</span>
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                </div>
                                <hr />
                                <div className="px-4 py-1 flex flex-col gap-3">
                                    <div className='flex items-center justify-between gap-4 text-sm '>
                                        <p className='font-semibold'>{selectedLanguage?.name == "English" ? "Paid" : translation[1340]?.value ? translation[1340]?.value : "Paid"}</p>
                                        <p className='text-[#101928] font-medium flex items-center gap-2'>
                                            {isFromCheckout ? totalAmountPaid?.toFixed(2) : 0?.toFixed(2)} {location_currency}
                                        </p>
                                    </div>
                                    <div className='flex items-center justify-between gap-4 text-sm '>
                                        <p className='font-semibold'>{selectedLanguage?.name == "English" ? "Remaining" : translation[1562]?.value ? translation[1562]?.value : "Remaining"}</p>
                                        <p className='text-[#101928] font-medium flex items-center gap-2'>
                                            {cartRemainingPrice ?
                                                cartRemainingPrice :
                                                0.00}
                                            {' '}
                                            {location_currency}
                                        </p>
                                    </div>
                                    <div className='flex items-center justify-between gap-4 text-sm '>
                                        <p className='font-semibold'>{selectedLanguage?.name == "English" ? "Change" : translation[1581]?.value ? translation[1581]?.value : "Change"}</p>
                                        <p className='text-[#101928] font-medium flex items-center gap-2'>
                                            {cartChange
                                                ? cartChange
                                                : 0.00
                                            }{" "}
                                            {location_currency}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center justify-between gap-4 text-sm px-6 py-5'>
                                {payNowBtn &&
                                    <div className="flex flex-end gap-3">
                                        <LoginBtn
                                            className={`${!buttondisable ? "bg-[#E0EBFD] text-[#5679FF] " : 'bg-[#E8E8E8] !text-[#B4B4B4] '}  !px-7`}
                                            customThemeBtn={'custom-theme-btn-checkout'}
                                            disabled={buttondisable}
                                            loading={checkoutLoader}
                                            text={selectedLanguage?.name == "English" ? "Checkout" : translation[182]?.value ? translation[182]?.value : "Checkout"}
                                            onClick={() => {
                                                onPayClick({
                                                    taxes_applied_group: taxForGroup?.length > 0 ? taxForGroup : tax > 0 ? singleTax : [],
                                                    taxes_applied_single_value: taxApplied,
                                                    taxes_applied_single: tax,
                                                    location: location.id
                                                })
                                            }}
                                        />
                                        <LoginBtn
                                            customThemeBtn={'custom-theme-btn-checkout'}
                                            disabled={!showReset}
                                            className={`${showReset ? "bg-[#E0EBFD] text-[#5679FF] " : 'bg-[#E8E8E8] !text-[#B4B4B4] '}  !px-7`}
                                            borderBtn
                                            text={selectedLanguage?.name == "English" ? "Reset" : translation[1141]?.value ? translation[1141]?.value : "Reset"}
                                            onClick={onResetClick}
                                        />
                                    </div>
                                }
                                <div className={`flex gap-1 text-center ${!payNowBtn ? 'flex-1 justify-between' : 'flex-col'}`}>
                                    <p className='text-[#A1A1A1] flex items-center gap-2'>
                                        {selectedLanguage?.name == "English" ? "Total Amount" : translation[183]?.value ? translation[183]?.value : "Total Amount"}
                                    </p>
                                    <p className='font-semibold'>{(+cartTotal)?.toFixed(2)} {location_currency}</p>
                                </div>
                            </div>

                        </div>

                    </>
                    : <BookingDetailCardShimmer />
                }
            </div>
        </>
    )
}


GroupBookingDetailCard.defaultProps = {
    payNowBtn: true,
    onPayClick: () => { },
    payBtnText: 'Checkout',
    payNowBtnDisabled: true,
    services: [],
    location: {},
    tips: [],
}

export default GroupBookingDetailCard