import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { MONTHS, MONTHS_DURATIONS, WEEKDAYS, WEEKDAYS_SHORT } from "../../Constants/Data/Months"
import Dropwdown from "../TenantApp/Elements/Dropwdown"


export const DateCard = ({ year, month, animate, date, selected, current_month, onSelected, disabled, date_card_ref }) => {
    const [c_day, setCDay] = useState('')
    useEffect(() => {
        let new_date = new Date(`${month} ${date}, ${year}`)

        setCDay(WEEKDAYS_SHORT[new_date.getDay() - 1] ? WEEKDAYS_SHORT[new_date.getDay() - 1] : 'Sun')
    }, [month]);

    return (
        <>
            {animate ?
                <div
                    className={`border rounded-md text-xs h-12 w-14 bg-gray-200`}
                >

                </div>
                :
                <div
                    ref={date_card_ref}
                    className={`${date} border rounded-md text-xs px-5 py-2 text-center cursor-pointer ${selected ? 'border-primary border-[2px] text-primary bg-[#F8FBFB]' : `${disabled ? 'bg-[#f2f2f2] cursor-not-allowed' : ''} text-[#A1A1A1] border-[#A1A1A1]`}`}
                    onClick={() => {
                        if (!disabled) {
                            onSelected && onSelected(`${year}-${(MONTHS.indexOf(month) + 1) < 10 ? '0' : ''}${MONTHS.indexOf(month) + 1}-${date < 10 ? '0' : ''}${date}`)
                        }
                    }}
                >
                    { }
                    <p>{c_day ? c_day : ''}</p>
                    <p>{date}</p>
                </div>
            }
        </>
    )
}



const DateSlider = ({ onUpdate, selectedDate, translation, selectedLanguage, appointmentData, setAppointmentData, disabled }) => {
    let dat = new Date();
    // let yearArr = [2022, 2023, 2024];
    const currentYear = new Date().getFullYear();
    const upcomingYears = Array(2).fill().map((_, i) => ({ label: currentYear + i, value: currentYear + i }));

    const navigate = useNavigate();
    const sliderRef = useRef()
    const DateCardRef = useRef()
    const params = useParams()
    const dispatch = useDispatch()

    const [data, setData] = useState({})
    const [dateValue, setDateValue] = useState()

    const SliderRef_Hanlder = () => {
        let slider = sliderRef.current
        let date_card = DateCardRef.current

        let slider_left_ofset = slider?.offsetLeft
        let card_left_ofset = date_card?.offsetLeft

        let ofset_dif = card_left_ofset - slider_left_ofset
        slider.scrollLeft = ofset_dif - 100

    }


    useEffect(() => {
        if (sliderRef && sliderRef.current && DateCardRef && DateCardRef.current) {
            setTimeout(() => {
                SliderRef_Hanlder()
            }, 200);
        }
    }, [sliderRef.current, DateCardRef.current, data?.selected_month, selectedDate])


    useEffect(() => {
        // let dt = new Date();
        // let date = new Date(
        //     appointmentData?.selected_year && appointmentData?.selected_year != dt.getFullYear() ? appointmentData?.selected_year : appointmentData?.appointment_date ? appointmentData?.appointment_date : new Date()
        // );
        // let today_year = date.getFullYear()
        // let today_month = date.getMonth()
        // let today_date = date.getDate()
        // let today_day = date.getDay() - 1;



        let dt = moment();
        let date = moment(
            appointmentData?.selected_year && appointmentData?.selected_year !== dt.year()
                ? appointmentData?.selected_year
                : appointmentData?.appointment_date
                    ? appointmentData?.appointment_date
                    : moment()
        );
        let today_year = date.year();
        let today_month = date.month();
        let today_date = date.date();
        let today_day = date.day() - 1;



        setData({
            ...data,
            current_year: today_year,
            current_month: today_month,
            current_date: today_date,
            selected_month: selectedDate ? MONTHS[(Number(selectedDate.split('-')[1])) - 1] : MONTHS[today_month],
            selected_day: WEEKDAYS[today_day],
            date: selectedDate ? selectedDate : `${today_year}-${today_month + 1}-${today_date}`
        });
    }, [appointmentData?.selected_year, appointmentData?.appointment_date])



    useEffect(() => {
        if (dateValue) {
            let updatedData = { ...data, date: dateValue }
            onUpdate(updatedData)
        } else {
            if (data?.current_year || data?.current_month || data?.selected_month || data?.date) {
                onUpdate(data)
            }
        }

    }, [data?.current_year, data?.current_month, data?.selected_month, dateValue])

    return (
        <>
            <div className='flex flex-col gap-3'>
                <div className='flex items-center justify-between'>
                    {/* <p className="text-primary flex items-center gap-2">
                        {selectedLanguage?.name == "English" ? "Select Date" : translation[111]?.value ? translation[111]?.value : "Select Date"}

                        {" "}
                        {appointmentData?.selected_month
                            ? `- ${appointmentData?.selected_month}`
                            : ""}{" "}
                         <select
                            className="outline-none border-0 rounded-md text-sm"
                            placeholder="year"
                            onChange={(e) => {
                                setAppointmentData({
                                    ...appointmentData,
                                    selected_year: e.target.value,
                                });
                            }}
                        >
                            {upcomingYears?.map((itm, ind) => {
                                return (
                                    <option
                                        value={itm.label}
                                        selected={currentYear === itm.label}
                                        disabled={itm.label < currentYear ? true : false}
                                        className={
                                            itm < currentYear
                                                ? "bg-[#c8c6c6] cursor-not-allowed"
                                                : "cursor-pointer"
                                        }
                                    >
                                        {itm.label}
                                    </option>
                                );
                            })}
                        </select> 
                        </p>
                    */}

                    <div className='text-sm flex items-center gap-2 justify-end w-full'>
                        <div>
                            <Dropwdown
                                title=""
                                placeholder="Select Year"
                                showTitle={false}
                                value={appointmentData?.selected_year ? appointmentData.selected_year : currentYear}
                                name="selected_month"
                                onChange={(e) => {
                                    setAppointmentData({
                                        ...appointmentData,
                                        selected_year: e.target.value,
                                    });
                                }}
                                options={upcomingYears}
                                disabled={disabled}
                            />
                        </div>
                        <div>
                            <Dropwdown
                                title=''
                                placeholder='Select Month'
                                showTitle={false}
                                value={data.selected_month}
                                disabled={disabled}
                                name='selected_month'
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        selected_month: e.target.value,
                                    })
                                }}
                                options={MONTHS.map((mnth, index) => {
                                    return {
                                        label: mnth,
                                        value: mnth,
                                        disabled: moment().month() > index ? true : false,
                                        // disabled: data?.current_month > index ? true : false,
                                    }
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className='flex items-center gap-3 overflow-auto py-3 scroll-smooth'
                    ref={sliderRef}
                >
                    {
                        data?.current_month >= 0 &&
                        [...Array(MONTHS_DURATIONS[MONTHS.indexOf(data?.selected_month)]).keys()].map((day, index) => {
                            const currentDate = moment();
                            const selectedMonthIndex = moment(data?.selected_month, 'MMMM').month();
                            const currentMonthIndex = currentDate.month();
                            const isDisabled =
                                selectedMonthIndex === currentMonthIndex
                                    ? currentDate.date() <= day + 1
                                        ? false
                                        : true
                                    : false;

                            let date_pattern = moment(`${data?.current_year} ${data?.selected_month} ${day + 1}`, 'YYYY MMMM D').format('YYYY-MM-DD');
                            let is_selected = moment(data.date).format("YYYY-MM-DD") == moment(date_pattern).format('YYYY-MM-DD');

                            let is_start = (MONTHS.indexOf(data?.selected_month) == data?.current_month) ? data?.current_date == day + 1 : index == 0
                            return (
                                <DateCard
                                    date_card_ref={is_selected ? DateCardRef : is_start ? DateCardRef : { current: undefined }}
                                    year={data?.current_year}
                                    month={data?.selected_month}
                                    current_month={data?.current_month}
                                    date={day + 1}
                                    disabled={isDisabled || disabled}
                                    // disabled={moment([data?.selected_year, MONTHS.indexOf(data?.selected_month), day + 1]) < moment() ? true : false}
                                    // disabled={MONTHS.indexOf(data?.selected_month) == data?.current_month ? dat?.getDate() <= day + 1 ? false : true : false}
                                    selected={is_selected}
                                    onSelected={(value) => {
                                        setDateValue(value)
                                        setData({
                                            ...data,
                                            date: value
                                        })
                                    }}
                                />
                            )
                        })
                    }

                </div>
            </div>
        </>
    )
}

export default DateSlider