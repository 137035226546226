import React from 'react'
import useAddLieuDay from './helper';
import Popup from 'new-components/popup';
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown';
import DateInput from 'Components/TenantApp/Elements/DateInput';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'; 
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';

const AddLieuDay = ({ open, close, successCallback }) => {
    const {
        translation, selectedLanguage,
        isLoading,
        employeeRedux,
        employeePaginationData,
        employeeSearch, setEmployeeSearch,
        employeePage, setEmployeePage,
        employeeLoader,
        minStartDateLimit,
        handleClose,
        handleSubmit,
        limit_start_time,
        onChangeHandler,
        lieuDayData, errors, limit_end_time
    } = useAddLieuDay({ open, close, successCallback })

    return (
        <>
            <Popup size="md" heading={selectedLanguage?.name == "English" ? "Add Lieu day" : translation[1558]?.value ? translation[1558]?.value : "Add Lieu day"} handleClose={handleClose} >
                <div className="flex flex-col gap-2">
                    <PaginationDropdown
                        title={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                        setPage={setEmployeePage}
                        page={employeePage}
                        setDropdownSearch={setEmployeeSearch}
                        dropdownSearch={employeeSearch}
                        lastPage={employeePaginationData?.employeePages}
                        currentPage={employeePaginationData?.employeeCurrentPage}
                        placeholder={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                        value={lieuDayData?.employee}
                        onChange={onChangeHandler}
                        name={"employee"}
                        error={errors?.employee}
                        custom_min_width={'sm:min-w-[15rem] min-w-[20rem]'}
                        loading={employeeLoader}
                        options={employeeRedux?.map((empl, i) => {
                            return {
                                label: empl.full_name,
                                value: empl.id,
                                otherData: {
                                    image: empl?.image,
                                    DataOne: empl.email,
                                    showImage: true,
                                    index: i
                                },
                                email: empl.email,
                                mobile_number: empl.mobile_number
                            };
                        })}
                        errorClass={"!static"}
                    />
                    {/* <DateInput
                        title={'Select Date'}
                        name={'date'}
                        min={minStartDateLimit}
                        value={lieuDayData?.date}
                        onChange={onChangeHandler}
                        error={errors?.date}
                    /> */}
                    <LoginInput
                        type="date"
                        title={selectedLanguage?.name == "English" ? "Select Date" : translation[111]?.value ? translation[111]?.value : "Select Date"}
                        placeholder={selectedLanguage?.name == "English" ? "Choose Date" : translation[113]?.value ? translation[113]?.value : "Choose Date"}
                        name={'date'}
                        min={minStartDateLimit}
                        value={lieuDayData?.date}
                        onChange={onChangeHandler}
                        error={errors?.date}
                        errorClass={"!static"}
                        />

                    <div className="flex items-start gap-3">
                        <Dropwdown
                            title={selectedLanguage?.name == "English" ? " Select In Time" : translation[621]?.value ? translation[621]?.value : " Select In Time"}
                            placeholder={selectedLanguage?.name == "English" ? " Select In Time" : translation[621]?.value ? translation[621]?.value : " Select In Time"}
                            name={'start_time'}
                            value={lieuDayData?.start_time}
                            onChange={onChangeHandler}
                            options={limit_start_time}
                            error={errors?.start_time}
                            errorClass={"!static"}
                        />
                        <Dropwdown
                            title={selectedLanguage?.name == "English" ? " Select Out Time" : translation[622]?.value ? translation[622]?.value : " Select Out Time"}
                            placeholder={selectedLanguage?.name == "English" ? " Select Out Time" : translation[622]?.value ? translation[622]?.value : " Select Out Time"}
                            name={'end_time'}
                            value={lieuDayData?.end_time}
                            onChange={onChangeHandler}
                            options={limit_end_time}
                            error={errors?.end_time}
                            errorClass={"!static"}
                        />
                    </div>

                    {/* <div className="flex flex-col gap-4">
                    {fields &&
                        fields?.map((ele, index) => {
                            return (
                                <div key={index} className="flex items-center gap-3">
                                    <Selection
                                        name={`start_time`}
                                        placeholder={selectedLanguage?.name == "English" ? " Select In Time" : translation[621]?.value ? translation[621]?.value : " Select In Time"}
                                        options={startTime}
                                        // control={control}
                                        errorMessage={
                                            errors?.schedule &&
                                                errors?.schedule[index]?.start_time?.message ?
                                                selectedLanguage?.name == "English"
                                                    ? "This field is required"
                                                    : translation[287]?.value
                                                        ? translation[287]?.value
                                                        : "This field is required"
                                                : ""
                                        }
                                    />

                                    <Selection
                                        name={`end_time`}
                                        placeholder={selectedLanguage?.name == "English" ? " Select Out Time" : translation[622]?.value ? translation[622]?.value : " Select Out Time"}
                                        options={endTime}
                                        // control={control}
                                        errorMessage={
                                            errors?.schedule &&
                                                errors?.schedule[index]?.end_time?.message ?
                                                selectedLanguage?.name == "English"
                                                    ? "This field is required"
                                                    : translation[287]?.value
                                                        ? translation[287]?.value
                                                        : "This field is required"
                                                : ""
                                        }
                                    />
                                </div>
                            );
                        })}
                </div> */}

                    <div className='pt-2 flex justify-end'>
                        <LoginBtn
                            className={"!px-6"}
                            text={selectedLanguage?.name == "English" ? "Save" : translation[623]?.value ? translation[623]?.value : "Save"}
                            loading={isLoading}
                            disabled={isLoading}
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default AddLieuDay