import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import NotFound from "Components/Utility/NotFound";
import { MONTHS } from "Constants/Data/Months";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import LocationSelection from "new-components/location";
import Svgs from "../../../../../Assets/svgs";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import { useReports } from "./helper";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import Pagination from "new-components/Pagination";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoReportDataFound from "Components/Utility/NoReportDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";

const RetailTargetReports = () => {
  const { reportStatus, filterMonth, filterYear, filterBrand, yearDropdown, monthDropdown, csvReport, loading,
    brandDropdown, getTotalTarget, reportsFilter, handleChange, translation, selectedLanguage,
    pages, allCount, currentPage, setCurrentPage, PageSize, handleExportClick, allReports, exportLoader,
    brandSearchText, setBrandSearchText, brandCurrentPage, setBrandCurrentPage, brandLoader, brandPaginationData,
    currencyOfSelectedLocation
  } = useReports();
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
          url={'/dashboard/reports/'}
          second={selectedLanguage?.name == "English" ? 'Retail Target Reports' : translation[394]?.value ? translation[394]?.value : 'Retail Target Reports'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
            </h2>
            <LocationSelection />
          </div>
        </div>

        <div className="border rounded-lg">
          <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-1 gap-4 ">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl whitespace-nowrap">{selectedLanguage?.name == "English" ? 'Retail Target Reports' : translation[394]?.value ? translation[394]?.value : 'Retail Target Reports'}</h2>
                {(!reportStatus || loading) ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">
                      {allCount ? allCount : 0}{" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                      }
                    </p>
                  </div>
                }
              </div>
              {loadingPermission ?
                <Shimmer className={'w-44 lg:w-48'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer> :
                <div className="mb-00">
                  <PaginationDropdown
                    name="filterBrand"
                    showTitle={false}
                    placeholder={selectedLanguage?.name == "English" ? "Select Brands" : translation[395]?.value ? translation[395]?.value : "Select Brands"}
                    options={brandDropdown}
                    value={filterBrand}
                    onChange={handleChange}

                    setPage={setBrandCurrentPage}
                    setDropdownSearch={setBrandSearchText}
                    page={brandCurrentPage}
                    dropdownSearch={brandSearchText}
                    lastPage={brandPaginationData?.total_pages}
                    currentPage={brandPaginationData?.currentPage}
                    loading={brandLoader}
                  />
                </div>
              }
            </div>

            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-32 lg:!w-44 sm:!w-40'} classOn2={'!w-32 lg:!w-44 sm:!w-40'} classOn3={'!w-12 lg:!16'} has3Itm />
              :
              <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">
                <Dropwdown
                  name="filterYear"
                  title=""
                  showTitle={false}
                  placeholder="2023"
                  options={yearDropdown}
                  value={filterYear}
                  onChange={handleChange}
                />
                <Dropwdown
                  name="filterMonth"
                  title=""
                  showTitle={false}
                  placeholder={selectedLanguage?.name == "English" ? "January" : translation[370]?.value ? translation[370]?.value : "January"}
                  options={monthDropdown}
                  value={filterMonth}
                  onChange={handleChange}
                />
                {exportLoader ? (
                  <div className="h-[4rem] flex items-center justify-center">
                    <PreloaderSm />
                  </div>
                ) :
                  !employee ? (
                    allReports?.length > 0 ?
                      <CSVLink
                        {...csvReport}
                        target="_blank"
                        onClick={handleExportClick}
                      >
                        <IconButton filled>
                          <Svgs.Export />
                        </IconButton>
                      </CSVLink> :
                      <LoginBtn
                        className="rounded-lg border-2 border-primary"
                        bg="bg-white"
                        animation={false}
                        onClick={handleExportClick}
                      >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                          <Svgs.Export bg="#FFFFFF" />
                        </div>
                      </LoginBtn>
                  ) : (
                    ""
                  )}
              </div>
            }
          </div>
          {(!reportStatus || loading) ?
            <TableShimer cols={5} colsHeight={7} rows={12} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(10.625rem,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                <p>{selectedLanguage?.name == "English" ? "Brand" : translation[292]?.value ? translation[292]?.value : "Brand"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Brand Target" : translation[396]?.value ? translation[396]?.value : "Total Brand Target"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Brand Sales" : translation[397]?.value ? translation[397]?.value : "Total Brand Sales"}</p>
                <p>{selectedLanguage?.name == "English" ? "Year" : translation[376]?.value ? translation[376]?.value : "Year"}</p>
                <p>{selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}</p>
              </div>
              <div className={`odd-bg text-[#3A3A3A] transition-all`}>
                {reportsFilter()?.length > 0 ? (
                  reportsFilter()?.map(
                    ({ name, product_sale_price, brand_target }, index) => (
                      <>
                        <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(10.625rem,1fr))] min-w-full w-fit">
                          <div className="flex items-center">
                            <p className="text-sm">{name}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">
                              {getTotalTarget(brand_target) || "0"} {currencyOfSelectedLocation}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">{product_sale_price} {currencyOfSelectedLocation}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">{filterYear}</p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">{MONTHS[filterMonth]}</p>
                          </div>
                        </div>
                      </>
                    )
                  )
                ) : (
                  <>
                    <NoReportDataFound />
                  </>
                )}

                {(reportsFilter()?.length > 0 && !loading) &&
                  <Pagination
                    currentPage={currentPage}
                    totalCount={Math.ceil(pages)}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                  />
                }
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default RetailTargetReports;
