
export const useTenant = () => {
  let hostName = process.env.REACT_APP_SERVER_APPLICATION_DOMAIN
  if (typeof window !== 'undefined'){
    hostName = window.location.host;
  }
  const hostArr = hostName.split(".");
  const hostLength = hostArr.length;
  //currently use 2 for local testing and then 3 use for live

  const tenant = (process.env.REACT_APP_IS_LOCAL == 1 ? hostLength >= 2 : hostLength > 2) && hostName.split(".")[0];
  return {
    is_tenant: tenant ? true : false,
    tenant,
  };
};

export const tenantDetail = () => {
  let hostName = process.env.REACT_APP_SERVER_APPLICATION_DOMAIN
  if (typeof window !== 'undefined'){
    hostName = window.location.host;
  }
  const hostArr = hostName.split(".");
  const hostLength = hostArr.length;
  //currently use 2 for local testing and then 3 use for live
  const tenant = (process.env.REACT_APP_IS_LOCAL == 1 ? hostLength >= 2 : hostLength > 2) && hostName.split(".")[0];
  return {
    is_tenant: tenant ? true : false,
    tenant: tenant,
  };
};