import React from "react";
import FacebookLogin from "react-facebook-login";

import LoginCardBlock from "Components/TenantApp/Elements/LoginCardBlock";
import LoginSocial from "Components/TenantApp/Elements/LoginSocial";
import Svgs from "Assets/svgs";

const LoginWithFacebook = ({ onVerify, login }) => {
  const facebookHandler = (response) => {
    onVerify(response);
  };

  return (
    <>
      {/*  FacebookLogin implemented just commented  due to absence of appId */}
      <div className="relative">
        <LoginSocial login={login} width={login}  className={"bg-[#F9F9F9!important] p-1 border border-[#E8E8E8] h-[auto!important] w-[auto!important]"} desc={false}>
          <span className="absolute opacity-0 inset-0">
            <span>hi</span>
            <FacebookLogin
              appId="858830515002290"
              autoLoad={false}
              fields="name,email,picture,first_name,last_name"
              // onClick={onClickHandler}
              callback={facebookHandler}
              // textButton={<p className="whitespace-nowrap">Signup With Facebook</p>}
              size={"medium"}
              cssClass="absolute opacity-0 inset-0"
            />
          </span>
          <div>
            <Svgs.FaceBook />
          </div>
        </LoginSocial>
      </div>
    </>
  );
};

LoginWithFacebook.defaultProps = {
  onVerify: () => { },
};

export default LoginWithFacebook;
