import Svgs from "../../../../../Assets/svgs";
import React, { useEffect, useState } from "react";
import useSetDataInLocalStorage from "../../../../../Hooks/useSetDataInLocalStorage";
import LoginInput from "../../../Elements/LoginInput";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddPromotionCard = ({
  index,
  data,
  onDelete,
  is_edit,
  onUpdate,
  onEditClick,
  ...props
}) => {
  const state = useSelector((state) => state);
  const [promoData, setpromoData] = useState({});
  const [promoError, setpromoError] = useState({});
  const [editId, seteditId] = useState();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  let business = businessData?.id;
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    setpromoData({
      ...data,
      service: data?.service?.id,
      product: data?.product?.id,
    });
  }, [editId]);

  const handlePromoData = (e) => {
    const { name, value } = e.target;
    setpromoData({
      ...promoData,
      [name]: value,
    });
  };

  return (
    <>
      <div className="flex gap-6">
        <div className="relative">
          {index != 0 && (
            <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
          )}
          <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
            <div className="h-[1.8rem] w-[1.8rem] rounded-full  border border-primary flex items-center justify-center bg-white">
              <Svgs.Appointment_Bullet />
            </div>
          </div>
          <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-1/2 left-1/2 -translate-x-1/2"></div>
        </div>
        {editId === promoData?.id ? (
          <>
            <div className="mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4">
              <LoginInput
                name={"name"}
                required={true}
                title="Promotion Name"
                placeholder="Promotion Name"
                value={promoData?.name}
                onEnterSubmit={() => {
                  onUpdate(promoData);
                }}
                error={promoError.name}
                onChange={(e) => {
                  handlePromoData(e);
                }}
                onBlur={() => {
                  if (!promoData?.name) {
                    setpromoError({
                      ...promoError,
                      name: true,
                    });
                  } else {
                    setpromoError({
                      ...promoError,
                      name: false,
                    });
                  }
                }}
              />

              <Dropwdown
                required
                title="Choose Promotion Type"
                placeholder="Promotion Type"
                name="promotion_type"
                value={promoData?.promotion_type}
                error={promoError.promotion_type}
                options={[
                  { label: "Service", value: "Service" },
                  { label: "Product", value: "Product" },
                ]}
                onChange={(e) => {
                  handlePromoData(e);
                }}
                onBlur={() => {
                  if (!promoData?.promotion_type) {
                    setpromoError({
                      ...promoError,
                      promotion_type: true,
                    });
                  } else {
                    setpromoError({
                      ...promoError,
                      promotion_type: false,
                    });
                  }
                }}
              />
              <div className="grid lg:grid-cols-2 gap-4">
                {promoData?.promotion_type == "Service" ? (
                  <>
                    <Dropwdown
                      title="Services"
                      required={true}
                      placeholder="Select Service"
                      name="service"
                      value={promoData?.service}
                      error={promoError?.service}
                      options={state?.service?.services?.map((itm) => {
                        return {
                          label: itm.name,
                          value: itm.id,
                        };
                      })}
                      onChange={(e) => {
                        handlePromoData(e);
                      }}
                      onBlur={() => {
                        if (!promoData?.service) {
                          setpromoError({
                            ...promoError,
                            service: true,
                          });
                        } else {
                          setpromoError({
                            ...promoError,
                            service: false,
                          });
                        }
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Dropwdown
                      title="Select Product"
                      required={true}
                      placeholder="Select Product"
                      name="product"
                      value={promoData?.product}
                      error={promoError.product}
                      options={state?.product?.products?.map((itm) => {
                        return {
                          label: itm.name,
                          value: itm.id,
                        };
                      })}
                      onChange={(e) => {
                        handlePromoData(e);
                      }}
                      onBlur={() => {
                        if (!promoData?.product) {
                          setpromoError({
                            ...promoError,
                            product: true,
                          });
                        } else {
                          setpromoError({
                            ...promoError,
                            product: false,
                          });
                        }
                      }}
                    />
                  </>
                )}

                <LoginInput
                  required={true}
                  name="purchases"
                  type="number"
                  title="No. of Purchases"
                  onEnterSubmit={() => {
                    onUpdate(promoData);
                  }}
                  value={promoData?.purchases}
                  error={promoError.purchases}
                  placeholder="Enter no. of purchases"
                  onChange={(e) => {
                    handlePromoData(e);
                  }}
                  onBlur={() => {
                    if (!promoData?.purchases) {
                      setpromoError({
                        ...promoError,
                        purchases: true,
                      });
                    } else {
                      setpromoError({
                        ...promoError,
                        purchases: false,
                      });
                    }
                  }}
                />
              </div>
              <div className="grid lg:grid-cols-2 gap-4">
                <div className="flex flex-col gap-2">
                  <LoginInput
                    placeholder="Ex 50%"
                    title="Discount Percentage "
                    name="discount"
                    value={promoData?.discount}
                    onEnterSubmit={() => {
                      onUpdate(promoData);
                    }}
                    error={promoError.discount}
                    onChange={(e) => {
                      handlePromoData(e);
                    }}
                    onBlur={() => {
                      if (!promoData?.discount) {
                        setpromoError({
                          ...promoError,
                          discount: true,
                        });
                      } else {
                        setpromoError({
                          ...promoError,
                          discount: false,
                        });
                      }
                    }}
                  />
                </div>
                <LoginInput
                  required
                  title="Valid Till"
                  type="date"
                  placeholder="Select Discount Duration"
                  name="valid_til"
                  value={promoData?.valid_til}
                  onEnterSubmit={() => {
                    onUpdate(promoData);
                  }}
                  error={promoError.valid_til}
                  onChange={(e) => {
                    handlePromoData(e);
                  }}
                  onBlur={() => {
                    if (!promoData?.valid_til) {
                      setpromoError({
                        ...promoError,
                        valid_til: true,
                      });
                    } else {
                      setpromoError({
                        ...promoError,
                        valid_til: false,
                      });
                    }
                  }}
                />
              </div>
              <LoginBtn
                text="Submit"
                onClick={() => {
                  onUpdate(promoData);
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="mt-[2rem] rounded-md w-full flex flex-col gap-4">
              <div className="reward-gradient-1 rounded-lg px-6 py-4">
                <div className="flex gap-2 flex-col">
                  <div className="flex items-center justify-between gap-4">
                    <h1 className="text-white font-bold text-2xl">
                      {data?.name}
                    </h1>
                    <div className="flex items-center gap-3">
                      {!employee ||
                        (employee &&
                          employeePermissions?.client_reward?.includes(
                            "edit"
                          )) ? (
                        <div
                          className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                          onClick={() => {
                            onEditClick(data?.id);
                            seteditId(data?.id);
                          }}
                        >
                          <Svgs.Edit fill="#fff" />
                        </div>
                      ) : (
                        ""
                      )}
                      {!employee ? (
                        <div
                          className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                          onClick={() => {
                            onDelete(data?.id);
                          }}
                        >
                          <Svgs.Delete fill="#FFF" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="text-white text-lg">
                    <p className="">
                      <span className="text-2xl font-semibold">
                        {data?.discount}%
                      </span>
                      <span> off on </span>
                      {data?.product
                        ? data?.product?.name
                        : data?.service?.name}
                    </p>

                    <p className="text-sm text-right">
                      Redeem on {data?.purchases} purchases of
                      <span>
                        {" "}
                        {data?.product
                          ? data?.product?.name
                          : data?.service?.name}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

AddPromotionCard.defaultProps = {
  data: {},
  index: undefined,
  onEditClick: () => { },
  onDelete: () => { },
  onUpdate: () => { },
};

export default AddPromotionCard;
