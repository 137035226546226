import Svgs from 'Assets/svgs';
import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Popup from 'Components/TenantApp/Elements/Popup';
import React from 'react'
import useGroupPopup from './helper';


const AddGroup = ({ CreateGroup, setCreateGroup, UnsubscribeData }) => {
    const {
      translation, selectedLanguage
    } = useGroupPopup()

        

    return (

      <Popup
      size={"md"}
      heading={selectedLanguage?.name == "English" ? "Create Group" : translation[579]?.value ? translation[579]?.value : "Create Group"}
      open={CreateGroup}
      close={setCreateGroup}
  >
     <div className="flex flex-col gap-5 pr-4">
     
      <div>
          <LoginInput
            title={selectedLanguage?.name == "English" ? "Group Name" : translation[546]?.value ? translation[546]?.value : "Group Name"}
            placeholder={selectedLanguage?.name == "English" ? "Enter Group Name" : translation[1726]?.value ? translation[1726]?.value : "Enter Group Name"}
          />
        <div className="flex flex-col gap-4 w-full py-5">
              {UnsubscribeData.map((v,i) => {
                  return( 
                      <div className="flex items-center justify-between w-full bg-[#FBFCFF] p-2 rounded-md">
                        <p>{v.name}</p>
                        <Svgs.Close fill="#000"/>
                      </div>
                  )
              })}
        </div>
        <LoginBtn
              onClick={() => {
                  setCreateGroup(!CreateGroup);
              }}
              text={
              <div className="flex items-center gap-3">
                  <div>
                  <Svgs.Plus />
                  </div>
                  <div>
                    <p>{selectedLanguage?.name == "English" ? "Create Group" : translation[579]?.value ? translation[579]?.value : "Create Group"}</p>
                  </div>
              </div>
              }
          />
      </div>
      
      
     
    </div>
  </Popup>
    )
}

export default AddGroup