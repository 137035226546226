import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TooltipLite from 'react-tooltip-lite';

const SidebarOptions = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const next_path = `/dashboard/${props.navigater}`;

  const is_active_route = () => {
    if (
      location.pathname == "/" ||
      (props.navigater == "/" && location.pathname == "/dashboard") ||
      location.pathname.includes(`/dashboard/${props.navigater}`)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div
      onClick={() => {
        if (props.onClick) {
          props.onClick();
          return;
        }
        if (props.navigater == "/") {
          navigate(`/dashboard`);
        } else if (props.navigater != "#") {
          navigate(next_path);
        }

        props.activeId(props.id);

        let responsive_sidebar = document.querySelector(".responsive_sidebar");
        if (responsive_sidebar) {
          responsive_sidebar.classList.toggle("hidden");
        }
 

        document.querySelector('.right_container_scroll').scrollTo({ top: 0, behavior: 'smooth' });
      }}
      className={`${props.padding ? props.padding : "px-3 py-4"} ${props.CollapsedMenu && 'justify-center w-[2.75rem]'} ${is_active_route() && "active_sidebar"
        } cursor-pointer transition-all rounded-lg ${is_active_route()
          ? "bg-primary text-white"
          : "bg-[#fff] text-primary"
        } flex items-center gap-3 whitespace-nowrap`}
    >
      <div className="">
        {
          props.CollapsedMenu ? <TooltipLite direction="right" zIndex={'123213213'} content={<p className="whitespace-nowrap">{props.text}</p>} tagName="div">
            {props.children}
          </TooltipLite> : <>{props.children}</>
        }
      </div>

      {
        !props.CollapsedMenu && <p className="text-sm"> {props.text}</p>
      }

    </div>
  );
};
SidebarOptions.defaultProps = {
  children: "Add SVG as Children",
  text: "Add Title as text prop",
};
export default SidebarOptions;
