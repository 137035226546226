import React, { useEffect, useState } from "react";
import LoginCardBlock from "./LoginCardBlock";
import useSearchParamsHandle from "../../../Hooks/useSearchParamsHandle";
// import { useNavigate, useSearchParams, Navigate } from "react-router-dom";
import SVGs from "../../../Assets/svgs";
import {
  // EMAIL,
  ACCOUNT_TYPE,
  // PERSONAL_INFORMATION,
} from "../../../Constants/Variables";
// import LoginWithGoogle from "../../NstyleApp/SocialsLogin/LoginWithGoogle";
// import LoginWithFacebook from "../../NstyleApp/SocialsLogin/LoginWithFacebook";
import { useLocation, useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { signupUserPersonalInfo } from "../../../Redux/NstyleRedux/Actions/sigupAction";
// import { useSaleHistory } from "../Dashboard/Sales/sale-history/helper";
import SignupSteps from "Components/NstyleApp/SignupSteps";


const ChooseSectionSignup = (props) => {

  const navigate = useNavigate()
  // const dispatch = useDispatch()
  const { pathname, search } = useLocation()
  // const info_data = useSelector(state => state.userData.personalInfo)


  const {
    getSearchParams,
    searchParamsValue,
  } = useSearchParamsHandle();

  useEffect(() => {
    getSearchParams(ACCOUNT_TYPE);
  }, [searchParamsValue]);

 

  return (
    <div
      // className={`h-[100vh] lg:h-auto`}
    >
      <h1 className="text-3xl font-semibold text-[#374168] lg:text-left" style={{textTransform: 'none'}}>
        Register your account 
        {/* <br /> Personal & business details */}
      </h1>
      
      <SignupSteps />

      

      {/* <LoginCardBlock
        onClick={() => {
          navigate(`${pathname}${search}&signup_type=email&actives=personal_information`)
        }}
        heading={<span>Signup with Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
        desc={false}
      >
        <SVGs.Email />
      </LoginCardBlock> */}
      {/* <LoginWithGoogle
        onVerify={(user) => {
          dispatch(
            signupUserPersonalInfo({
              ...info_data,
              // ...user,
              email: user.email,
              first_name: user.first_name,
              last_name: user.last_name,
              social_account: true,
              social_id: user.social_id,
              social_platform: user.social_platform,
              business_name: `${user.first_name} ${user.last_name}`
            })
          )
          navigate(`${pathname}${search}&signup_type=Google&actives=personal_information`)
        }}
      />
      <LoginWithFacebook
        onVerify={(user) => {
          if (user.status == "unknown" || !user.email) {
            return
          }
          else {
            dispatch(
              signupUserPersonalInfo({
                ...info_data,
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                business_name: `${user.first_name} ${user.last_name}`,
                social_account: true,
                social_id: user.id,
                social_platform: 'Facebook'
              })
            )
            navigate(`${pathname}${search}&signup_type=Facebook&actives=personal_information`)
          }
        }}
      /> */}
    </div>
  );
};

export default ChooseSectionSignup;
