import { useSearchParams } from 'react-router-dom';

function useClientUrlFromCreatedLink() {
    const [searchParams, setSearchParams] = useSearchParams();
    const nexts = searchParams.get("next");
    const hash = searchParams.get("hash");
    const token = searchParams.get("token");
    const domain = searchParams.get("domain");

  return {
    nexts,
     hash, 
     token, 
     domain
  }
}

export default useClientUrlFromCreatedLink