/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getBrandDropdownlist, getBrandDropdownlistPagination } from "Adapters/Api/Brand";
import { get_product_inventory_report } from "Adapters/Api/Products";

let timeOutId = undefined
let brandTimeOutId = undefined
export const useReports = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { selected_location } = state?.locations;
  let product_stock = state?.product?.product_stock;
  let pages = state?.product?.product_stock_pages;
  let allCount = state?.product?.product_stock_allCount;

  const [ShowDetails, setShowDetails] = useState(0);
  const [Filters, setFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterBrand, setFilterBrand] = useState('');
  const [filterReportType, setFilterReportType] = useState('');
  const [toggle, setToggle] = useState("Stock");
  const [filterData, setFilterData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const { text } = filterData;
  const PageSize = 10
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [brandSearchText, setBrandSearchText] = useState("");
  const [brandCurrentPage, setBrandCurrentPage] = useState(1)
  const [brandLoader, setBrandLoader] = useState(false)
  const brandListDropdown = state?.brand?.brandsDropdown;
  const brandPaginationData = state?.brand?.brandsPagination;
  useEffect(() => {
    if (brandSearchText === null) {
      dispatch(getBrandDropdownlistPagination(brandCurrentPage, "", setBrandLoader))
    } else {
      if (brandTimeOutId) {
        clearTimeout(brandTimeOutId)
      }
      brandTimeOutId = setTimeout(() => {
        dispatch(getBrandDropdownlistPagination(brandCurrentPage, brandSearchText, setBrandLoader))
      }, 500);
    }
  }, [brandSearchText, brandCurrentPage])

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  let loading = () => {
    setIsLoading(true);
  }
  let success = () => {
    setIsLoading(false);
  }
  let fail = () => {
    setIsLoading(false);
  }
  // // get product list
  // const fetchProductStockReport = (page) => {
  //   let location_id = localStorage.getItem('location_id')
  //   if (!location_id) {
  //     location_id = localStorage.getItem('selected_location')
  //   }
  //   if (location_id) {
  //     dispatch(get_product_inventory_report(loading, success, fail, { ...filterData, query: filterData.text, location_id: location_id?.replaceAll('"', '') }))
  //   }
  // };
  // get product list
  const fetchProductStockReport = (page) => {
    let location_id = localStorage.getItem('location_id')
    if (!location_id) {
      location_id = localStorage.getItem('selected_location')
    }
    if (location_id) {
      dispatch(get_product_inventory_report(loading, success, fail, { ...filterData, search_text: filterData.text, location_id: location_id?.replaceAll('"', ''), page }))
    }
  };
  useEffect(() => {
    if (filterData?.text === null) {
      // Initial load with empty filterData?.text
      fetchProductStockReport(currentPage)
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        fetchProductStockReport(currentPage)
      }, 500);
    }
    // fetchProductStockReport();
  }, [filterData, selected_location, currentPage])

  useEffect(() => {
    setCurrentPage(1)
  }, [filterData, selected_location])

  // useEffect(() => {
  //   if (!state.brand.brands_updated) {
  //     dispatch(get_business_brands());
  //   }
  // }, [!state.product.products_stock_updated]);

  // useEffect(() => {
  //   dispatch(getBrandDropdownlist());
  // }, [])

  // useEffect(() => {
  //   fetchProductStockReport();
  // }, [filterData, selected_location])

  const dataList = useMemo(() => {
    let filteredData = product_stock
    // if (product_stock?.length) {
    //   filteredData = product_stock?.map((product_obj) => {
    //     // const location = state?.locations?.locations?.find((objj) => objj?.id === selected_location);
    //     // if (location) {
    //       let retail_price = product_obj?.retail_price[0]
    //       // let retail_price = product_obj?.retail_price.find((objjj) => objjj?.currency === location?.currency?.id)
    //       let retail_currency = state?.utility?.currency?.location_currency;
    //       let reports = product_obj?.reports?.filter(itm => {
    //         switch (selected_location) {
    //           case itm?.consumed_location?.id: //consumption
    //             return { ...itm, itm, retail_price, retail_currency };
    //           case product_obj?.to_location?.id: // transfer to
    //             return { ...itm, itm, retail_price, retail_currency }
    //           case product_obj?.from_location?.id: // transfer from
    //             return { ...itm, itm, retail_price, retail_currency }
    //           case product_obj?.location?.id: // purchase order // sold
    //             return { ...itm, itm, retail_price, retail_currency }
    //           default:
    //         }
    //       })
    //       if (reports?.length > 0) {
    //         return product_obj
    //       }
    //     // }
    //   })?.filter(product_obj => product_obj !== undefined)
    // }
    return filteredData;
  }, [selected_location, product_stock, state?.locations?.locations?.length])

  const brands = useMemo(() => {
    return [{ label: 'All Brand', value: '' }, ...brandListDropdown?.map((obj) => {
      return {
        label: obj.name,
        value: obj.id
      }
    })]
  }, [brandListDropdown?.length])

  const filter_product_stock_report = () => {
    // const new_stocks = dataList?.filter((item) => {
    //   if (filterData?.text || filterBrand || filterReportType) {
    //     let return_value = false;
    //     if (filterData?.text) { //check search text
    //       if (
    //         item?.product?.name?.toLowerCase().includes(filterData?.text.toLowerCase())
    //       ) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }
    //     if (filterBrand === '' && filterReportType) { // report type
    //       if (item?.reports?.map(itm => itm?.report_choice?.toLowerCase().includes(filterReportType?.toLowerCase()))) {
    //         return true;
    //       }
    //     }

    //     if (filterBrand && filterReportType === '') { // brand
    //       if (
    //         item?.product?.brand?.toLowerCase().includes(filterBrand?.toLowerCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //     if (filterBrand !== '' && filterReportType !== '') { // both filter
    //       if (
    //         item?.reports?.map(itm => itm?.report_choice?.toLowerCase().includes(filterReportType?.toLowerCase())) &&
    //         item?.product?.brand?.toLowerCase().includes(filterBrand?.toLowerCase())
    //       ) {
    //         return true;
    //       }
    //     }
    //     return return_value;
    //   }
    //   return true;
    // });
    const new_stocks = dataList
    return new_stocks;
  };

  // product reports exports
  let productHeader = [
    { label: "Product Name", key: "product" },
    { label: "Type", key: "type" },
    { label: "Updated Stock", key: "available_stock" },
    { label: "Cost Price", key: "cost_price" },
    { label: "Retail Price", key: "retail_price" },
    { label: "Brand", key: "brand" },
    { label: "Date", key: "date" }
  ];

  const productReportsExport = {
    filename: "Inventory Report.csv",
    headers: productHeader,
    data: filter_product_stock_report()?.map((product_obj) => {
      return {
        ...product_obj,
        product: product_obj?.name ? product_obj?.name : "----",
        type: product_obj?.report_choice ? product_obj?.report_choice : "----",
        available_stock: product_obj?.after_quantity ? product_obj?.after_quantity : "----",
        cost_price: product_obj?.cost_price ? product_obj?.cost_price : "----",
        retail_price: (product_obj?.retail_price && product_obj?.retail_price?.retail_price) ? `${product_obj?.retail_price?.retail_price} ${product_obj?.retail_price?.currency_code}` : `0`,
        brand: product_obj?.brand ? product_obj?.brand : "----",
        date: product_obj?.created_at
      };
    }),
  };

  let NestedProductReportsHeader = [
    { label: "Type", key: "type" },
    { label: "Stock before", key: "stock_before" },
    { label: "Sold Stock", key: "sold_stock" },
    { label: "Location", key: "location" },
    { label: "Updated Stock", key: "updated_stock" },
    { label: "Consumed Amount", key: "consumed_amount" },
    { label: "Vendor", key: "vendor" },
    { label: "PO ID", key: "po_id" },
    { label: "Reorder Amount", key: "reorder_amount" },
    { label: "Received/Transfer Amount", key: "received_amount" },
    { label: "Date", key: "date" },
  ];



  // Common Fields 
  // Type = report_obj.report_choice
  // Stock before = report_obj.before_quantity
  // Sold Stock : 
  // Sold : (report_obj?.before_quantity - report_obj?.after_quantity)
  // Location = 
  //      Sold : report_obj.address_name | 
  // Consumed : report_obj?.consumed_location?.address_name | 
  // To Location : report_obj?.from_location?.address_name
  // From Location : report_obj?.from_location?.address_name
  // Updated Stock : report_obj?.after_quantity

  // Consumed Amount : 
  // Consumed : report_obj?.quantity
  // Vendor : report_obj?.vendor_name
  // PO ID : report_obj?.id?.split("-", 1)
  // Reorder Amount : report_obj?.reorder_quantity
  // Received/Transfer Amount : report_obj?.quantity
  // Date = report_obj.created_at




  const ProductStockNestedReportsExport = {
    filename: "nested_inventory_report.csv",
    headers: NestedProductReportsHeader,
    data: filter_product_stock_report()?.map((report_obj) => {
      return {
        ...report_obj,
        type: report_obj.report_choice,
        stock_before: report_obj.before_quantity ? report_obj.before_quantity : '------',
        sold_stock: (report_obj?.before_quantity && report_obj?.after_quantity) ? (report_obj?.before_quantity - report_obj?.after_quantity) : '------',
        location: `${report_obj.report_choice == 'Sold' ? report_obj.address_name : report_obj.report_choice == 'Consumed' ? report_obj?.consumed_location?.address_name : (report_obj.report_choice == 'Transfer_to' || report_obj.report_choice == 'Transfer_from') ? report_obj?.from_location?.address_name : '------'}`,
        updated_stock: report_obj?.after_quantity ? report_obj?.after_quantity : '------',
        consumed_amount: report_obj?.quantity ? report_obj?.quantity : '------',
        vendor: report_obj?.vendor_name ? report_obj?.vendor_name : '------',
        po_id: report_obj.report_choice == 'Purchase' ? report_obj?.id?.split("-", 1) : '------',
        reorder_amount: report_obj?.reorder_quantity ? report_obj?.reorder_quantity : '------',
        received_amount: report_obj?.quantity ? report_obj?.quantity : '------',
        date: report_obj.created_at,
      };
    }),
  };

  return {
    text,
    toggle,
    Filters,
    state,
    ShowDetails,
    filterData,
    filterBrand,
    filterReportType,
    brands,
    productReportsExport,
    NestedProductReportsHeader,
    ProductStockNestedReportsExport,
    isLoading,
    translation,
    selectedLanguage,
    filter_product_stock_report,
    navigate,
    setToggle,
    setFilters,
    setFilterData,
    setShowDetails,
    setFilterReportType,
    setFilterBrand,
    product_stock,
    currentPage, setCurrentPage, pages, allCount,
    loadingPermission,
    brandSearchText, setBrandSearchText, brandCurrentPage, setBrandCurrentPage, brandLoader, brandPaginationData,
  }
};
// getting these types from backend
export const InventoryReport = {
  purchase: 'Purchase',
  consumed: 'Consumed',
  transferFrom: 'Transfer_from',
  transferTo: 'Transfer_to',
  sold: 'Sold',
  // headings for frontend
  transferToHeading: 'Transfer To',
  transferFromHeading: 'Transfer From'
}