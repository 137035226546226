import React from "react";

import NstyleApp from "./NstyleApp";
import TenantApp from "./TenantApp";

import { useTenant } from "Hooks/useTenant";

export default function Routes() {
  const { is_tenant } = useTenant();
  
  return is_tenant ? <TenantApp /> : <NstyleApp />
}
