
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { createvouchers } from "../../../../../Adapters/Api/vouchers";
import I_Info_Icon from "Components/TenantApp/Elements/I_Info_Icon";
import FloatInput from "Components/TenantApp/Elements/FloatInput";
import Svgs from "Assets/svgs";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";

const AddVoucherCard = ({
  data,
  onDelete,
  is_edit,
  onUpdate,
  index,
  onEditClick,
  currency,
  errors,
  setErrors,
  setEditVoucherError,
  editVoucherError,
  updateLoading,
  translation, selectedLanguage, submitButtonClick,
  ...props
}) => {
  const state = useSelector((state) => state);
  const locationList = state.locations.locations;
  const currencyList = state?.utility?.currency?.currency_list;
  const navigate = useNavigate();
  const [voucherData, setvoucherData] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [price, setPrice] = useState('')
  const [voucherError, setvoucherError] = useState({});
  const [editId, seteditId] = useState("");
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  let business = businessData?.id;
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const { currency_voucher } = voucherData;

  const currencyID = data?.currency_voucher?.map(itm => itm?.currency)

  let allUinqueCurrencyList = [];

  locationList?.filter((item) => item?.currency?.id).filter((itm) => {
    if (!allUinqueCurrencyList.find((dt) => dt?.currency?.id === itm?.currency?.id)) {
      allUinqueCurrencyList.push(itm)
      return true
    }
  })

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const handlevoucherData = (e) => {
    const { name, value } = e.target;
    setvoucherData({
      ...voucherData,
      [name]: value,
    });
    if (name !== 'discount_percentage') {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    setEditVoucherError({
      ...editVoucherError,
      [name]: false,
    });
  };

  useEffect(() => {
    setvoucherData({
      ...data,
      currency_voucher: data?.currency_voucher?.map(currency => currency),
    });
  }, [editId]);

  //set currency and voucher price object here
  const addNewItem = () => {
    setvoucherData((prev) => ({
      ...prev,
      currency_voucher: [
        ...prev?.currency_voucher,
        { currency: selectedCurrency, price: price }
      ]
    }))
    setSelectedCurrency();
    setPrice()
  }

  // **************************** remove selected items *********************************
  const removedItem = (value, type) => {

    if ((type === "price")) {
      if (voucherData?.currency_voucher?.find((itm) => itm?.currency == value)) {
        setvoucherData((prev) => ({
          ...prev,
          currency_voucher: [...prev?.currency_voucher?.filter((itm) => itm?.currency != value)],
        }));
      }
    } else {
      if (voucherData?.currency_voucher?.find((itm) => itm?.currency == value)) {
        setvoucherData((prev) => ({
          ...prev,
          currency_voucher: [...prev?.currency_voucher?.map((itm) => {
            if (itm?.currency === value) {
              return {
                ...itm,
                is_deleted: true
              }
            }
            return itm
          })],
        }));
      }
    }

  };

  useEffect(() => {
    if (allUinqueCurrencyList?.length == voucherData?.currency_voucher?.length) {
      setErrors((prev) => ({
        ...prev,
        price: ""
      }))
    }
  }, [voucherData?.currency_voucher, voucherData])

  return (
    <>
      <div className="flex gap-6">
        <div className="relative">
          {index != 0 && (
            <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-0 left-1/2 -translate-x-1/2"></div>
          )}
          <div className="flex flex-col z-[2] absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
            <div className="h-[1.8rem] w-[1.8rem] rounded-full  border border-primary flex items-center justify-center bg-white">
              <Svgs.Appointment_Bullet />
            </div>
          </div>
          <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[1] top-1/2 left-1/2 -translate-x-1/2"></div>
        </div>
        {editId === voucherData?.id ? (
          <>
            <div className="mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4">
              <LoginInput
                name={"name"}
                required={true}
                title={selectedLanguage?.name == "English" ? "Vouchers Name" : translation[1184]?.value ? translation[1184]?.value : "Vouchers Name"}
                placeholder={selectedLanguage?.name == "English" ? "Enter Vouchers Name" : translation[1185]?.value ? translation[1185]?.value : "Enter Vouchers Name"}
                onEnterSubmit={() => {
                  onUpdate(voucherData);
                }}
                // charactersMaxLength={25}
                value={voucherData?.name}
                error={voucherError.name ? voucherError.name : errors?.name}
                onChange={(e) => {
                  handlevoucherData(e);
                }}
                onBlur={() => {
                  if (!voucherData?.name) {
                    setvoucherError({
                      ...voucherError,
                      name: true,
                    });
                  } else {
                    setvoucherError({
                      ...voucherError,
                      name: false,
                    });
                  }
                }}
              />
              {/* <LoginInput
                required
                title="Value"
                type="number"
                placeholder="Select Discount Duration"
                name="value"
                onEnterSubmit={() => {
                  onUpdate(voucherData);
                }}
                value={voucherData?.value}
                error={voucherError.value}
                onChange={(e) => {
                  handlevoucherData(e);
                }}
                onBlur={() => {
                  if (!voucherData?.value) {
                    setvoucherError({
                      ...voucherError,
                      value: true,
                    });
                  } else {
                    setvoucherError({
                      ...voucherError,
                      value: false,
                    });
                  }
                }}
              /> */}

              <Dropwdown
                required
                title={selectedLanguage?.name == "English" ? "Select Service / Product" : translation[1186]?.value ? translation[1186]?.value : "Select Service / Product"}
                placeholder={selectedLanguage?.name == "English" ? "Select on which service / product you want to implement this voucher" : translation[1187]?.value ? translation[1187]?.value : "Select on which service / product you want to implement this voucher"}
                name="voucher_type"
                value={voucherData?.voucher_type}
                error={voucherError.voucher_type ? voucherError.voucher_type : errors?.voucher_type}
                options={[
                  { label: "Service", value: "Service" },
                  { label: "Product", value: "Product" },
                ]}
                onChange={(e) => {
                  handlevoucherData(e);
                }}
                onBlur={() => {
                  if (!voucherData?.voucher_type) {
                    setvoucherError({
                      ...voucherError,
                      voucher_type: true,
                    });
                  } else {
                    setvoucherError({
                      ...voucherError,
                      voucher_type: false,
                    });
                  }
                }}
              />

              <div className="grid lg:grid-cols-2 gap-4">
                {/* <div className="flex flex-col gap-2">
                  <Dropwdown
                    title="Valid For Days / Months"
                    required={true}
                    placeholder="Select Days / Months"
                    options={[
                      { label: "Days", value: "Days" },
                      { label: "Months", value: "Months" },
                    ]}
                    value={voucherData?.valid_for}
                    error={voucherError.valid_for}
                    name="valid_for"
                    onChange={(e) => {
                      handlevoucherData(e);
                      setvoucherError({
                        ...voucherError,
                        valid_for: false,
                      });
                    }}
                    onBlur={() => {
                      if (!voucherData?.valid_for) {
                        setvoucherError({
                          ...voucherError,
                          valid_for: true,
                        });
                      } else {
                        setvoucherError({
                          ...voucherError,
                          valid_for: false,
                        });
                      }
                    }}
                  />
                </div> */}

              </div>
              <div className="grid lg:grid-cols-2 gap-4">
                <Dropwdown
                  required
                  title={
                    <I_Info_Icon
                      text={selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}
                      info={selectedLanguage?.name == "English" ? "For how many days the voucher will be valid." : translation[1188]?.value ? translation[1188]?.value : "For how many days the voucher will be valid."}
                    />
                  }
                  type="number"
                  placeholder={selectedLanguage?.name == "English" ? "Select validity" : translation[1173]?.value ? translation[1173]?.value : "Select validity"}
                  name="validity"
                  options={[
                    { label: "14 Days", value: "14 Days" },
                    { label: "1 Months", value: "1 Months" },
                    { label: "2 Months", value: "2 Months" },
                    { label: "3 Months", value: "3 Months" },
                    { label: "4 Months", value: "4 Months" },
                    { label: "6 Months", value: "6 Months" },
                    { label: "8 Months", value: "8 Months" },
                    { label: "1 Years", value: "1 Years" },
                    { label: "18 Months", value: "18 Months" },
                    { label: "2 Years", value: "2 Years" },
                    { label: "5 Years", value: "5 Years" },
                  ]}
                  value={voucherData?.validity}
                  onEnterSubmit={() => {
                    onUpdate(voucherData);
                  }}
                  error={voucherError.validity ? voucherError.validity : errors?.validity}
                  onChange={(e) => {
                    handlevoucherData(e);
                  }}
                  onBlur={() => {
                    if (!voucherData?.validity) {
                      setvoucherError({
                        ...voucherError,
                        validity: true,
                      });
                    } else {
                      setvoucherError({
                        ...voucherError,
                        validity: false,
                      });
                    }
                  }}
                />

                <LoginInput
                  title={selectedLanguage?.name == "English" ? "No. of Sales" : translation[1189]?.value ? translation[1189]?.value : "No. of Sales"}
                  placeholder={selectedLanguage?.name == "English" ? "Enter no. of vouchers to sale" : translation[1190]?.value ? translation[1190]?.value : "Enter no. of vouchers to sale"}
                  required={true}
                  type="number"
                  value={voucherData?.sales}
                  onEnterSubmit={() => {
                    onUpdate(voucherData);
                  }}
                  error={voucherError.sales ? voucherError.sales : errors?.sales}
                  name="sales"
                  onChange={(e) => {
                    handlevoucherData(e);
                    setvoucherError({
                      ...voucherError,
                      sales: false,
                    });
                  }}
                  onBlur={() => {
                    if (!voucherData?.sales) {
                      setvoucherError({
                        ...voucherError,
                        sales: true,
                      });
                    } else {
                      setvoucherError({
                        ...voucherError,
                        valid_for: false,
                      });
                    }
                  }}
                />
                { }
                <FloatInput
                  title={selectedLanguage?.name == "English" ? "Percentage" : translation[199]?.value ? translation[199]?.value : "Percentage"}
                  required={true}
                  parentClass={'col-span-2'}
                  placeholder="%"
                  value={voucherData?.discount_percentage}
                  error={voucherError.discount_percentage ? voucherError.discount_percentage : errors?.discount_percentage}
                  onEnterSubmit={() => {
                    onUpdate(voucherData);
                  }}
                  name="discount_percentage"
                  onChange={(e) => {
                    handlevoucherData(e);
                    setvoucherError({
                      ...voucherError,
                      discount_percentage: false,
                    });
                  }}
                  isPercentage={true}
                  onBlur={() => {
                    if (!voucherData?.discount_percentage) {
                      setvoucherError({
                        ...voucherError,
                        discount_percentage: true,
                      });
                    } else {
                      setvoucherError({
                        ...voucherError,
                        discount_percentage: false,
                      });
                    }
                  }}
                />
                {/* <LoginInput
                  required
                  title="Set a Price"
                  type="number"
                  placeholder="Enter price"
                  name="price"
                  value={voucherData?.price}
                  onEnterSubmit={() => {
                    onUpdate(voucherData);
                  }}
                  error={voucherError.price}
                  onChange={(e) => {
                    handlevoucherData(e);
                  }}
                  onBlur={() => {
                    if (!voucherData?.price) {
                      setvoucherError({
                        ...voucherError,
                        price: true,
                      });
                    } else {
                      setvoucherError({
                        ...voucherError,
                        price: false,
                      });
                    }
                  }}
                /> */}
              </div>
              <div className='md:grid md:grid-cols-6 flex flex-col gap-4'>
                <div className='col-span-3'>
                  <FloatInput
                    required={true}
                    name={"price"}
                    value={price}
                    //   onEnterSubmit={() => {
                    //   onUpdate(memberData);
                    // }}
                    disabled={allUinqueCurrencyList?.length == currency_voucher?.length}
                    onChange={(e) => setPrice(e.target.value)}
                    onBlur={() => {
                      if (!voucherData?.price) {
                        setEditVoucherError({
                          ...editVoucherError,
                          price: true,
                        });
                      } else {
                        setEditVoucherError({
                          ...editVoucherError,
                          price: false,
                        });
                      }
                    }}
                    error={errors?.price ? errors?.price : (submitButtonClick && currency_voucher?.length == 0) && "Please add all currencies and their price"}
                    title={
                      <I_Info_Icon
                        text={selectedLanguage?.name == "English" ? "Selling Price" : translation[294]?.value ? translation[294]?.value : "Selling Price"}
                        info={selectedLanguage?.name == "English" ? "A seliing price is the cost paid for a good at seliing stores. It is a term applied to the price that final consumers pay at seliing outlets" : translation[1191]?.value ? translation[1191]?.value : "A seliing price is the cost paid for a good at seliing stores. It is a term applied to the price that final consumers pay at seliing outlets"}
                      />
                    }
                    placeholder={selectedLanguage?.name == "English" ? "Enter selling price" : translation[944]?.value ? translation[944]?.value : "Enter selling price"}

                  />
                </div>

                <div className='col-span-2'>
                  <Dropwdown
                    required={true}
                    name={"selectedCurrency"}
                    title={selectedLanguage?.name == "English" ? "Currency" : translation[67]?.value ? translation[67]?.value : "Currency"}
                    placeholder={selectedLanguage?.name == "English" ? "Select Currency" : translation[68]?.value ? translation[68]?.value : "Select Currency"}
                    value={selectedCurrency}
                    onChange={(e) => setSelectedCurrency(e.target.value)}
                    options={allUinqueCurrencyList
                      ?.filter(
                        (itm) =>
                          !currency_voucher?.find(
                            ({ currency }) => currency === itm?.currency?.id
                          )
                      )
                      .map((itm) => {
                        return {
                          label: itm?.currency?.code,
                          value: itm?.currency?.id,
                        };
                      })}
                  />
                </div>

                <div className='col-span-1 flex items-end'>
                  <LoginBtn
                    className={(selectedCurrency && price) && "shake-bottom"}
                    disabled={!selectedCurrency || !price}
                    onClick={() => {
                      addNewItem();
                    }}
                  >
                    <div className='flex items-center gap-2 text-sm'>
                      <Svgs.Plus />
                      <p>
                        {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                      </p>
                    </div>
                  </LoginBtn>
                </div>
              </div>
              {currency_voucher?.length > 0 && (
                <div className="flex items-center flex-wrap gap-2 1232312">
                  {currency_voucher &&
                    currency_voucher?.map((item, i) => {
                      const crrncy = currencyList?.find(
                        (itm) => itm?.id === item?.currency
                      );
                      return (
                        !item?.is_deleted && (
                          <div className="flex items-center gap-2 p-1 pr-2 border-2 rounded-md border-primary bg-[#F8FBFB]">
                            <p className="text-primary text-sm font-bold">{`${item?.price} ${crrncy?.code}`}</p>
                            <span
                              onClick={() =>
                                removedItem(item?.currency, "price")
                              }
                            >
                              <Svgs.CirlceClose fill="#5679FF" />
                            </span>
                          </div>
                        )
                      );
                    })}
                </div>
              )}
              <LoginBtn
                text={selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                // disabled={
                //   !voucherData?.name ||
                //   // !voucherData?.value ||
                //   !voucherData?.voucher_type ||
                //   // (voucherData?.currency_voucher?.length < allUinqueCurrencyList?.length) ||
                //   // !voucherData?.valid_for ||
                //   !voucherData?.validity ||
                //   !voucherData?.sales ||
                //   updateLoading
                //   // !voucherData?.price
                // }
                onClick={() => {
                  onUpdate(voucherData, seteditId);
                }}
                disabled={updateLoading}
                loading={updateLoading}
              />
            </div>
          </>
        ) : (
          <>
            <div className="mt-[2rem] rounded-md w-full flex flex-col gap-4">
              <div className={`rounded-lg py-4 ${(index % 4 == 0) ? 'vorcher-gradient-1' : (index % 3 == 0) ? 'vorcher-gradient-2' : (index % 2 == 0) ? 'vorcher-gradient-2' :'vorcher-gradient-4' }`}>
                <div className="flex gap-2 flex-col">
                  <div className="flex items-center justify-between gap-4 px-6">
                    <h1 className="text-white font-semibold text-3xl">
                      {data?.name}
                    </h1>
                    <div className="flex items-center gap-3">
                      {!employee ||
                        (employee &&
                          employeePermissions?.client_sharing?.includes(
                            "edit"
                          )) ? (
                        <div
                          className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                          onClick={() => {
                            onEditClick(data?.id);
                            seteditId(data?.id);
                          }}
                        >
                          <Svgs.Edit fill="#fff" />
                        </div>
                      ) : (
                        ""
                      )}
                      {!employee ? (
                        <div
                          className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                          onClick={() => {
                            onDelete(data?.id);
                            // setDelete(true)
                          }}
                        >
                          <Svgs.Delete fill="#FFF" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="text-white text-lg">
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center gap-3 justify-between px-6">
                        <p className="text-xl">
                          {selectedLanguage?.name == "English" ? "Avail" : translation[1194]?.value ? translation[1194]?.value : "Avail"} {data?.discount_percentage} {selectedLanguage?.name == "English" ? "% discount on all" : translation[1195]?.value ? translation[1195]?.value : "% discount on all"} {data?.voucher_type}s
                        </p>
                        <p className="text-sm text-right whitespace-nowrap">
                          {selectedLanguage?.name == "English" ? "Valid for:" : translation[1181]?.value ? translation[1181]?.value : "Valid for:"} {data?.validity}{" "}
                          {/* {data?.valid_for == "Days" ? "Days" : "Months"} */}
                        </p>
                      </div>
                      <div className="flex items-center relative w-full my-6">
                        <span className="w-7 h-7 rounded-full bg-white absolute -left-4"></span><span className="flex-1 h-1 w-full border-b-2 border-[#ffffff] border-dashed border-spacing-5"></span><span className="w-7 h-7 rounded-full bg-white absolute -right-4"></span>
                      </div>
                      <div className="flex items-center gap-5 justify-between px-6">
                        <p className="flex items-center gap-4 flex-wrap">
                          {/* <span className="font-bold">
                            {currency} {data?.price}{" "}
                            { }
                          </span> */}
                          {
                            data?.currency_voucher?.map((item, i) => {
                              const crrncy = currencyList?.find(
                                (itm) => itm?.id === item?.currency
                              );
                              return (
                                <div className="flex items-center gap-2 px-4 py-2 rounded-[0.625rem]  bg-[#F8FBFB]">
                                  <p className="text-primary text-sm">{`${item?.price} ${crrncy?.code}`}</p>
                                </div>
                              )
                            })}
                          {/* <span className="line-through">
                            {currency} {data?.value}
                          </span> */}
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

AddVoucherCard.defaultProps = {
  data: {},
  index: undefined,
  onEditClick: () => { },
  onDelete: () => { },
  onUpdate: () => { },
};

export default AddVoucherCard;
