import React from 'react'
import { useSelector } from 'react-redux';

import TeamSizeS from '../../../../Assets/svgs/Login/TeamSizeS'
import CheckboxCard from '../../../TenantApp/Elements/CheckboxCard';

const TeamSize = ({ onSelect, selected_size }) => {
    const state = useSelector((state) => state);


    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    // team size list 
    const team = [

        {
            value: "2-5 People",
            label: selectedLanguage?.name == "English" ? "2-5 People" : translation[710]?.value ? translation[710]?.value : "2-5 People",
        },
        {
            value: "6-10 people",
            label: selectedLanguage?.name == "English" ? "6-10 people" : translation[711]?.value ? translation[711]?.value : "6-10 people",
        },
        {
            value: "11+ People",
            label: selectedLanguage?.name == "English" ? "11+ People" : translation[712]?.value ? translation[712]?.value : "11+ People",
        },
        // "Its just Me",



    ];


    return (
        <div className='my-10 w-full slide-in-right'>
            <div className='flex flex-col gap-5 w-full lg:items-start items-center text-center lg:text-left'>
                <TeamSizeS />
                <h3 className='font-semibold text-2xl'>
                    {selectedLanguage?.name == "English" ? "Team Size" : translation[702]?.value ? translation[702]?.value : "Team Size"}
                </h3>
                <p className='text-[#7B7B7B]'>
                    {selectedLanguage?.name == "English" ? "Please provide the information about your team size." : translation[703]?.value ? translation[703]?.value : "Please provide the information about your team size."}
                </p>
                <div className='flex flex-col gap-4 w-full'>
                    {
                        team.map((title, id) => {
                            return <div className='w-full lg:w-[70%]' key={title}>
                                <CheckboxCard
                                    title={title?.label}
                                    value={title?.label}
                                    onClick={() => {
                                        onSelect(title?.value)
                                    }}
                                    checked={selected_size === title?.value}
                                />
                            </div>
                        })
                    }
                </div>
                {/* <LoginBtn
                    loading={loading}
                    onClick={(e) => {
                        onSubmitHandler(e);
                    }}
                    disabled={active != false ? false : true} className={active != false ? 'shake-bottom' : 'opacity-40'} text='Next'>
                    <Svgs.NextArrow />
                </LoginBtn> */}
            </div>
        </div>
    )
}

export default TeamSize