export const convertDataToFormData = (data) => {
    let form_data = new FormData();

    for (let key in data) {
        if (data[key]) {
            if (typeof data[key] === "object") {
                form_data.append(key, JSON.stringify(data[key]));
            } else {
                form_data.append(key, data[key]);
            }
        }
    }

    return form_data;
}