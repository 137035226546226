import { MULTI_LANGUAGE_INVOICES } from "../../ActionTypes/multiLanguageInvoicesTypes";

const initialState = {
    invoices: [],
    languages: [],
};

const MultiLanguageInvoices = (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case MULTI_LANGUAGE_INVOICES.ADD_INVOICE_TRANSLATION:
            return {
                ...state,
                invoices: [...state.invoices, payload],
            };

        case MULTI_LANGUAGE_INVOICES.UPDATE_INVOICE_TRANSLATION:
            const updatedInvoices = state?.invoices?.map(itm => {
                if (itm?.id == payload?.id) {
                    return payload
                } else {
                    return itm
                }
            })
            return {
                ...state,
                invoices: updatedInvoices,
            };

        case MULTI_LANGUAGE_INVOICES.DELETE_INVOICE_TRANSLATION:
            return {
                ...state,
                invoices: state?.invoices?.filter(invoice => invoice.id != payload),
            };

        case MULTI_LANGUAGE_INVOICES.GET_INVOICE_TRANSLATION:
            return {
                ...state,
                invoices: payload,
            };

        case MULTI_LANGUAGE_INVOICES.GET_LANUAGES:
            return {
                ...state,
                languages: payload,
            };
        default:
            return state;
    }
};
export default MultiLanguageInvoices;
