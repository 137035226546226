import Svgs from 'Assets/svgs'
import LabelAvatar from 'Components/TenantApp/Elements/LabelAvatar'
import React from 'react'

const AddAppointmentRightClient = ({ data, index, noId, selectedClient, onCloseClick, isSelected, isAppointment, ...props }) => {
    return (
        selectedClient ? (
            <div className={`flex items-center justify-between pr-2 transition-all hover:bg-[#ecf4ff] ${isAppointment ? 'bg-[#ecf4ff]' : ''} ${isSelected ? 'border border-solid border-[#5679FF]' : 'border-b'} `} onClick={props.onClick}>
                <div className='p-4 flex items-center gap-4 '>
                    <div>
                        <div className='h-[3.5rem] w-[3.5rem] rounded-full overflow-hidden group/item group relative'>
                            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 group/edit invisible group-hover/item:visible cursor-pointer" onClick={props.onEyeClick}>
                                <Svgs.Eye className='w-8 h-8 ' stroke='#333' />
                            </div>
                            {data?.image ?
                                <div className="h-full w-full relative cursor-pointer">
                                    <div className="h-full w-full absolute transition-all group-hover:bg-[#ffffff98] group-hover:z-[1]"></div>
                                    <img
                                        src={data?.image}
                                        className="h-full w-full object-cover absolute " />
                                </div>
                                :
                                <div className="h-full w-full relative cursor-pointer">
                                    <div className=" h-full w-full absolute transition-all group-hover:bg-[#ffffff98] group-hover:z-[1]"></div>
                                    <LabelAvatar iconLabel={data?.full_name} index={index} />
                                </div>
                            }
                        </div>
                    </div>
                    <div className=''>
                        <h1 className='text-lg text-primary font-semibold'>{data?.full_name}</h1>
                        <>
                            <p className='text-xs text-[#A1A1A1]'>{data?.mobile_number}</p>
                            <p className='text-xs text-[#A1A1A1]'>{data?.email}</p>
                        </>
                    </div>
                </div>
                <div className='cursor-pointer' onClick={onCloseClick}>
                    <Svgs.Cross className='w-6 h-6 ' fill='#5679FF' />
                </div>
            </div>
        ) : (
            <div className='flex items-center justify-between pr-2 transition-all cursor-pointer hover:bg-[#ecf4ff] border-b' onClick={props.onClick}>
                <div className='p-4 flex items-center gap-4 '>
                    <div>
                        <div className='h-[3.5rem] w-[3.5rem] rounded-full overflow-hidden'>
                            {data?.image ?
                                <img
                                    src={data?.image}
                                    className="h-full w-full object-cover" />
                                :
                                <LabelAvatar iconLabel={data?.full_name} index={index} />
                            }
                        </div>
                    </div>
                    <div className=''>
                        <h1 className='text-lg text-primary font-semibold'>{data?.full_name}</h1>
                        <>
                            <p className='text-xs text-[#A1A1A1]'>{data?.mobile_number}</p>
                            <p className='text-xs text-[#A1A1A1]'>{data?.email}</p>
                        </>
                    </div>
                </div>
                {!noId &&
                    <div className=''>
                        <p className='text-xs text-[#A1A1A1]'>{data?.client_id}</p>
                    </div>}
            </div>
        )
    )
}

export default AddAppointmentRightClient