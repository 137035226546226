import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Svgs from '../../../../Assets/svgs'
import CircleIcon from '../../Elements/CircleIcon'
import DateInput from '../../Elements/DateInput'
import LoginBtn from '../../Elements/LoginBtn'
import LoginInput from '../../Elements/LoginInput'
import Popup from '../../Elements/Popup'
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb'
import IconButton from 'Components/TenantApp/Elements/IconButton'

const Client_Reports = () => {
    const navigate = useNavigate();
    const [ShowDetails, setShowDetails] = useState(0);
    const [DateRangePopup, setDateRangePopup] = useState('');

    return (
        <>
            <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
                <Breadcrumb url={"/dashboard/clients/"} first={'Clients'} second={'Reports'} />
                <div className='my-[1.5rem] flex items-center justify-between'>
                    <div className='flex items-center sm:gap-4 gap-2'>

                        <h2 className='text-primary font-bold lg:text-3xl text-xl'>Rewards/Promotions Reports</h2>
                    </div>
                </div>
                <div className='border rounded-lg'>
                    <div className='px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3'>
                        <div className='flex items-center justify-between flex-1 w-full 
 gap-2 flex-wrap'>
                            <div className='flex items-center sm:gap-4 gap-2 '>
                                <h2 className='font-semibold text-xl'>Reports</h2>
                                <div className='bg-[#E4EAF0] rounded-full text-primary px-4 py-2 text-xs font-semibold'>
                                    <p>20 Groups</p>
                                </div>
                            </div>
                            <div className='flex items-center gap-3 flex-wrap'>
                                <IconButton filled>
                                    <Svgs.Export />
                                </IconButton>
                                <LoginBtn>
                                    <div className='flex items-center gap-2'>
                                        <Svgs.Filters fill='#fff' />
                                        <p>Filters</p>
                                    </div>
                                </LoginBtn>
                                <div className='mb-00'>
                                    <LoginInput title='' placeholder='Search' padding='py-1' leftIcon={<Svgs.Search />} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='overflow-x-auto'>
                        <div className='inline-grid xl:grid grid-cols-[repeat(5,minmax(15.313rem,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold'>
                            <p>Client Name</p>
                            <p>Total Reward Points</p>
                            <p>Remaining Reward points</p>
                            <p>Gifts Claimed</p>
                            <p>Actions</p>
                        </div>
                        <div className={`odd-bg text-[#3A3A3A] transition-all ${ShowDetails ? 'border-l-[4px] border-[#5679FFB2]' : 'border-l-[0px]'}`}>
                            {
                                DateRangePopup != 'Month' ? <>
                                    <div className='px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(155px,1fr))]'>
                                        <div className={`before-left-arrow cursor-pointer ${ShowDetails == 1 ? 'before-left-arrow-open' : 'before-left-arrow-close'}`} onClick={() => { setShowDetails(ShowDetails != 1 ? 1 : 0) }}>
                                            <div className='h-full flex items-center'>
                                                <p className='text-sm'>Reports 1</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center'>
                                            <p className='text-sm'>120</p>
                                        </div>
                                        <div className='flex items-center'>
                                            <p className='text-sm'>120</p>
                                        </div>
                                        <div className='flex items-center'>
                                            <p className='text-sm line-clamp-1 pr-3'>12 Claimed</p>
                                        </div>
                                        {/* <div className='flex items-center gap-2'>
                                            <div className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center'>
                                                <Svgs.Delete fill='#7D7D7D' />
                                            </div>
                                        </div> */}
                                    </div>
                                    {
                                        ShowDetails ? <div className='scale-in-center'>
                                            <div className='bg-[#FAF9FF] px-6 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(180px,1fr))]'>
                                                <div className='flex items-center gap-12 col-span-2 mr-4'>
                                                    <div className='w-full flex flex-col gap-3'>
                                                        <div className='flex items-center gap-3 w-full'>
                                                            <img src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80' className="h-[4.375rem] w-[4.375rem] object-cover rounded-lg" />
                                                            <div className='flex flex-col gap-2 flex-1'>
                                                                <p className='text-black font-semibold'>Alizeh Shah</p>
                                                                <h2 className='text-[#949494] text-sm'>1200 Points Earned</h2>
                                                                <div className="w-full bg-[#DDDDDD] rounded-full h-1.5">
                                                                    <div className="bg-[#FFBA08] h-1.5 rounded-full w-[45%]"></div>
                                                                </div>
                                                                <div className='flex items-center justify-between'>
                                                                    <p className='text-xs text-[#555555]'>12100 / 20000</p>
                                                                    <p className='text-xs text-[#555555]'>7900 points remaining </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Svgs.I />
                                                        <Svgs.I />
                                                        <Svgs.I />
                                                    </div>
                                                    <div className='h-full w-[1px] bg-[#3F3F441A] mr-3'></div>
                                                </div>
                                                <div className='grid grid-cols-2 gap-[1.6rem] col-span-4 gap-y-5 gap-x-3'>
                                                    <div className='col-span-2'>
                                                        <div className='flex items-center justify-between'>
                                                            <p className='font-semibold text-lg'>Rewards</p>
                                                            <div className='h-[2.5rem] w-[2.5rem] border border-[#FF3030] cursor-pointer rounded-full flex items-center justify-center'>
                                                                <Svgs.Delete fill='#FF3030' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='flex flex-col gap-2 flex-1'>
                                                            <div className='flex items-center gap-2'>
                                                                <Svgs.Crown />
                                                                <h2 className='text-[#000] text-sm font-semibold'>1200 Points Earned</h2>
                                                            </div>
                                                            <div className="w-full bg-[#DDDDDD] rounded-full h-1.5">
                                                                <div className="bg-[#FFBA08] h-1.5 rounded-full w-[45%]"></div>
                                                            </div>
                                                            <div className='flex items-center justify-between'>
                                                                <p className='text-xs text-[#555555]'>12100 / 20000</p>
                                                                <p className='text-xs text-[#555555]'>7900 points remaining </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='flex flex-col gap-2 flex-1'>
                                                            <div className='flex items-center gap-2'>
                                                                <Svgs.Crown />
                                                                <h2 className='text-[#000] text-sm font-semibold'>1200 Points Earned</h2>
                                                            </div>
                                                            <div className="w-full bg-[#DDDDDD] rounded-full h-1.5">
                                                                <div className="bg-[#FFBA08] h-1.5 rounded-full w-[45%]"></div>
                                                            </div>
                                                            <div className='flex items-center justify-between'>
                                                                <p className='text-xs text-[#555555]'>12100 / 20000</p>
                                                                <p className='text-xs text-[#555555]'>7900 points remaining </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='flex flex-col gap-2 flex-1'>
                                                            <div className='flex items-center gap-2'>
                                                                <Svgs.Crown />
                                                                <h2 className='text-[#000] text-sm font-semibold'>1200 Points Earned</h2>
                                                            </div>
                                                            <div className="w-full bg-[#DDDDDD] rounded-full h-1.5">
                                                                <div className="bg-[#FFBA08] h-1.5 rounded-full w-[45%]"></div>
                                                            </div>
                                                            <div className='flex items-center justify-between'>
                                                                <p className='text-xs text-[#555555]'>12100 / 20000</p>
                                                                <p className='text-xs text-[#555555]'>7900 points remaining </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='flex flex-col gap-2 flex-1'>
                                                            <div className='flex items-center gap-2'>
                                                                <Svgs.Crown />
                                                                <h2 className='text-[#000] text-sm font-semibold'>1200 Points Earned</h2>
                                                            </div>
                                                            <div className="w-full bg-[#DDDDDD] rounded-full h-1.5">
                                                                <div className="bg-[#FFBA08] h-1.5 rounded-full w-[45%]"></div>
                                                            </div>
                                                            <div className='flex items-center justify-between'>
                                                                <p className='text-xs text-[#555555]'>12100 / 20000</p>
                                                                <p className='text-xs text-[#555555]'>7900 points remaining </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''
                                    }
                                </> : <>
                                    <div className='px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))]'>
                                        <div className={`cursor-pointer`}>
                                            <div className='h-full flex items-center'>
                                                <p className='text-sm'>Reports 1</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center'>
                                            <p className='text-sm'>120</p>
                                        </div>
                                        <div className='flex items-center'>
                                            <p className='text-sm'>120</p>
                                        </div>
                                        <div className='flex items-center'>
                                            <p className='text-sm line-clamp-1 pr-3'>12 Claimed</p>
                                        </div>
                                        <div className='flex items-center'>
                                            <p className='text-sm line-clamp-1 pr-3'>50%</p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <div className='h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center'>
                                                <Svgs.Delete fill='#7D7D7D' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))]'>
                                        <p>Total</p>
                                        <p></p>
                                        <p></p>
                                        <p>216 Hrs</p>
                                        <p>AED 5,400</p>
                                        <LoginBtn text='Pay Now' />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                DateRangePopup == 'Date' && <Popup heading='Date Range' open={DateRangePopup} close={setDateRangePopup} >
                    <div className='flex flex-col gap-5'>
                        <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
                            <CircleIcon>
                                <Svgs.Inventory_Category />
                            </CircleIcon>
                            <div className="flex flex-col gap-1 lg:text-left text-center">
                                <h2 className="text-primary font-semibold text-xl">
                                    Date Range
                                </h2>
                                <p className="text-[#7B7B7B] text-sm">
                                    Get the results based on applied date range
                                </p>
                            </div>
                        </div>
                        <div className='flex items-center gap-5'>
                            <div className='flex-1'>
                                <DateInput title='From' />
                            </div>
                            <div className='flex-1'>
                                <DateInput title='To' />
                            </div>
                        </div>
                        <div className='flex items-center justify-center'>
                            <LoginBtn text='Select' />
                        </div>
                    </div>
                </Popup>
            }
        </>
    )
}

export default Client_Reports