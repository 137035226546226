import React from "react";
import PreloaderSm from "./PreloaderSm";

const IconButton = ({
    title,
    type,
    onClick,
    disabled,
    className,
    children,
    filled,
    loading,
}) => {

    const handleClick = (e) => {
        if (!loading && !disabled) {
            onClick(e)
        }
    }
    return (
        <button
            title={title}
            type={type}
            onClick={handleClick}
            disabled={disabled}
            className={`!px-3 !py-2.5 !h-[4.2ch] text-sm font-medium leading-tight uppercase rounded-md shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap w-fit disabled:cursor-not-allowed border border-primary ${filled ? "bg-primary" : "bg-white text-primary"}`}>
            {children}
        </button>
    );
};




IconButton.defaultProps = {
    animation: true,
    onClick: () => { },
    handleSubmit: () => { },
    borderBtn: false,
};

export default IconButton;
