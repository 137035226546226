import { apiErrorMessage } from "Constants/Data/Errors";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  CancelAppointment,
  // getAllAppointments,
  // getAppointmentServices,
  getSingleAppointment,
  UpdateAppointment,
} from "../../../../Adapters/Api/apointments";
import moment from "moment";
import Svgs from "../../../../Assets/svgs";
import { statusES } from "../../../../Constants/Data/AppointmentsData";
import { CALENDAR_OPENING_TIME, OPENING_TIME } from "../../../../Constants/Data/OpeningHours";
import Dropwdown from "../../Elements/Dropwdown";
import LoginBtn from "../../Elements/LoginBtn";
import LoginInput from "../../Elements/LoginInput";
import Popup from "../../Elements/Popup";
import SupportCard from "../Clients/Elements/SupportCard";
import BookingDetailCard from "./Elements/BookingDetailsCard";
import useAppointmentServiceDetails from "./useAppointmentServiceDetails";
import AppointmentDetailShimmer from "Components/Utility/Shimmers/AppointmentDetailShimmer";
import AppointmentLogShimmers from "Components/Utility/Shimmers/AppointmentLogShimmers";
import Textarea from "Components/TenantApp/Elements/Textarea";
// import useValidations from "Hooks/useValidations";
// import useObjectKeys from "Hooks/useObjectKeys";
import { createComment, getComment, UserCheckIn } from 'Adapters/Api/apointments'
import ClientSection from "./AddAppointment/ClientSection";
import InfiniteScroll from 'react-infinite-scroll-component';
import LabelAvatar from 'Components/TenantApp/Elements/LabelAvatar'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { GET_COMMENTS } from '../../../../Redux/TenantRedux/ActionTypes/Appointment/Appointment';
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import AppointmentCommentShimmer from "Components/Utility/Shimmers/AppointmentCommentShimmer";
import EmployeeCommentSystem from "./EmployeeCommentSystem";
// import AddBlockTime from "./Elements/AddBlockTime";

const Appointment = ({ appGroupId, onlyView, serviceId, onUpdateParam }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [appointmentData, setAppointmentData] = useState(undefined);
  const [Voucher, setVoucher] = useState(false);
  const [SplitTip, setSplitTip] = useState(false);
  const [cancelDeletePopup, setCancelDeletePopup] = useState(false)
  const [appointmentLogPopup, setAppointmentLogPopup] = useState(false);
  const [clickedLogIndex, setClickedLogIndex] = useState(-1);
  // const [data_updated, setDataUpdated] = useState(false);
  // const [appointment_service, setAppointmentService] = useState({})
  const [showButtons, setShowButtons] = useState(true)
  const [showServiceButtons, setShowServiceButtons] = useState(true)
  const [allowCheckout, setAllowCheckout] = useState(false)
  const [dataObj, setDataObj] = useState({})
  const [disableButtons, setDisableButtons] = useState('')
  const [updateAppointment, setUpdateAppointment] = useState(false)
  const [cancelReason, setCancelReason] = useState({})
  const [showActionMenu, setShowActionMenu] = useState(false)
  const [voidReason, setVoidReason] = useState({
    void_note: ''
  })
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [checkInTime, setCheckInTime] = useState('')

  const params = useParams();
  const navigate = useNavigate();
  const app_group_id = searchParams?.get("parent_id");
  const { loader, appointmentServices, tipsApplied, appliedTipTotal, fetchLogs, handleAppointmentLog, appointmentLog, statusLoader,
    voidPopup, setVoidPopup, clientDetails, setClientDetails, updateAppointmentServiceStatus, setAppointmentService, callCalendarApi, setCallCalendarApi, setTaxValuesForApi,
    requestReversalPopup, setRequestReversalPopup, requestReversalNote, setRequestReversalNote, handleReversalRequest, reversalLoader,
    setErrors, validation, errors, selectedLocationCurrency, objectKeyConvertToArray, subTotal, setSubTotal
  } = useAppointmentServiceDetails(app_group_id ? app_group_id : appGroupId);
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const appointmentServicesDetail = state?.appointment?.appointmentDetail


  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const urlParams = new URLSearchParams(window.location.search);
  const parentId = urlParams.get('parent_id')
  const buttonLabel = appointmentData?.status
  // const buttonLabel = statusES.find((itm) => itm.value == appointmentData?.status || itm.label == appointmentData?.status)?.label


  // useEffect(() => {
  //   if (data_updated) {
  //     update_appointment(app_group_id ? app_group_id : appGroupId);
  //   }
  // }, [appointmentData?.status]);

  // useEffect(() => {
  //   if (params.appointment_service_id) {
  //     setDataUpdated(false);
  //     getSingleAppointment(
  //       { id: params.appointment_service_id },
  //       (result) => {
  //         // setAppointmentData(result);
  //       },
  //       () => {
  //         navigate("/dashboard/calendar/");
  //       }
  //     );
  //   }
  // }, [params?.appointment_service_id]);
  useEffect(() => {
    if (appointmentServicesDetail && appointmentServicesDetail?.appointmnet_service?.length > 0) {
      setAppointmentData(appointmentServicesDetail)
      setAppointmentService(appointmentServicesDetail?.appointmnet_service)
    }
  }, [appointmentServicesDetail, appointmentServicesDetail?.appointmnet_service])

  useEffect(() => {
    if (appointmentData && appointmentData?.status) {
      if (appointmentData?.status !== 'Done' && appointmentData?.status !== "Cancelled") {
        setShowServiceButtons(true)
      } else {
        setShowServiceButtons(false)
      }
    }
  }, [appointmentData?.status])

  useEffect(() => {
    if (updateAppointment) {
      toast.success("Appointment cancelled successfully", { toastId: "toast" })
      localStorage.setItem("cancelled_appointment", true)
      setCancelDeletePopup(false)
    }
  }, [updateAppointment])

  useEffect(() => {
    if (appointmentServices?.length > 0) {
      const anyServicesHaveStatusStartFinishVoid = appointmentServices?.find(appointment =>
        appointment?.status === "Started" || appointment?.status === "Finished" || appointment?.status === "Void"
      );
      if (anyServicesHaveStatusStartFinishVoid) {
        setShowButtons(false)
      } else {
        setShowButtons(true)
      }

      const filteredServices = appointmentServices?.filter(itm => (itm?.status !== "Void"))
      const allServicesHaveEndTime = filteredServices?.every(appointment =>
        appointment?.service_end_time && appointment?.service_end_time !== null && appointment?.service_end_time !== undefined
      );
      const anyServicesHaveStartTime = appointmentServices?.find(appointment =>
        appointment?.service_start_time && appointment?.service_start_time !== null && appointment?.service_start_time !== undefined
      );
      // console.log(allServicesHaveEndTime, "allServicesHaveEndTime")
      // if (allServicesHaveEndTime) {
      //   if (appointmentData?.status !== 'Done') {
      //     setAllowCheckout(true)
      //     setAppointmentData((prev) => ({
      //       ...prev,
      //       status: 'Finished',
      //     }));
      //   }
      // } if (!allServicesHaveEndTime && anyServicesHaveStartTime) {
      //   setAppointmentData((prev) => ({
      //     ...prev,
      //     status: 'Started',
      //   }));
      // }
      // else if (appointmentData?.status !== "Cancelled" && !allServicesHaveEndTime) {
      //   setAppointmentData((prev) => ({
      //     ...prev,
      //     status: 'Booked',
      //   }));
      // }

      if (anyServicesHaveStartTime && anyServicesHaveStartTime?.status === "Started") {
        setDisableButtons(anyServicesHaveStartTime?.id)
      } else {
        setDisableButtons('')
      }
    }
  }, [appointmentServices?.length, appointmentServices, appointmentData?.status])

  const update_appointment = () => {
    let data = {
      id: appointmentData?.id,
      status: appointmentData?.status,
    }
    UpdateAppointment(data, () => {
      setCallCalendarApi(true)
      setUpdateAppointment(true)
      setDeleteLoader(false)
      setAppointmentData({
        ...appointmentData,
        status: "Cancelled",
      });
    }, () => {
      setDeleteLoader(false)
    });
  }

  const cancel_appointment = () => {
    const data = {
      appointment_id: appointmentData?.id,
      cancel_note: cancelReason?.cancel_note,
      reason: cancelReason?.reason,
      status: appointmentData?.status
    }
    let validationData = {
      appointment_id: appointmentData?.id,
      reason: cancelReason?.reason,
      cancel_note: cancelReason?.cancel_note,
      status: appointmentData?.status,
    }
    setErrors(validation(validationData));
    let result = validation(validationData);
    if (objectKeyConvertToArray(result)?.length === 0) {
      setDeleteLoader(true)
      CancelAppointment(data, () => {
        setCallCalendarApi(true)
        setUpdateAppointment(true)
        setDeleteLoader(false)
        setAppointmentData({
          ...appointmentData,
          status: "Cancelled",
        });
      }, () => {
        setDeleteLoader(false)
      });
    }
  }

  const statusOrder = ['Void'];

  appointmentServices?.sort((a, b) => {
    const statusA = statusOrder.indexOf(a.status);
    const statusB = statusOrder.indexOf(b.status);

    // Compare the indices to determine the order
    return statusA - statusB;
  });

  const handleCancelInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "notes") {
      setCancelReason((prev) => ({ ...prev, notes: value }));
      setErrors((prev) => ({ ...prev, notes: "" }));
      // if (value && value.length <= 150) {
      //   setCancelReason((prev) => ({ ...prev, notes: value }));
      //   setErrors((prev) => ({ ...prev, notes: "" }));
      // } else {
      //   if (value && value.length > 150) {
      //     setErrors((prev) => ({
      //       ...prev,
      //       notes: selectedLanguage?.name == "English" ? "You can not add more than 150 characters" : translation[1200]?.value ? translation[1200]?.value : "You can not add more than 150 characters"
      //     }));
      //   }
      // }
    }
    if (name != "notes") {
      setCancelReason((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  useEffect(() => {
    if (callCalendarApi) {
      onUpdateParam && onUpdateParam(callCalendarApi)
    }
  }, [callCalendarApi])

  const cancelReasonOptions = [
    { label: selectedLanguage?.name == "English" ? "Staffing Issues" : translation[1393]?.value ? translation[1393]?.value : "Staffing Issues", value: "Staffing Issues" },
    { label: selectedLanguage?.name == "English" ? "Technical Malfunctions" : translation[1394]?.value ? translation[1394]?.value : "Technical Malfunctions", value: 'Technical Malfunctions' },
    { label: selectedLanguage?.name == "English" ? "Double-Booking or Scheduling Errors" : translation[1395]?.value ? translation[1395]?.value : "Double-Booking or Scheduling Errors", value: 'Double-Booking or Scheduling Errors' },
    { label: selectedLanguage?.name == "English" ? "Facility or Office Closure" : translation[1396]?.value ? translation[1396]?.value : "Facility or Office Closure", value: "Facility or Office Closure" },
    { label: selectedLanguage?.name == "English" ? "Supplier Delays" : translation[1397]?.value ? translation[1397]?.value : "Supplier Delays", value: 'Supplier Delays' },
    { label: selectedLanguage?.name == "English" ? "Policy Changes or Legal Compliance" : translation[1398]?.value ? translation[1398]?.value : "Policy Changes or Legal Compliance", value: 'Policy Changes or Legal Compliance' },
    { label: selectedLanguage?.name == "English" ? "Communication Breakdown" : translation[1399]?.value ? translation[1399]?.value : "Communication Breakdown", value: "Communication Breakdown" },
    { label: selectedLanguage?.name == "English" ? "Weather Conditions" : translation[1400]?.value ? translation[1400]?.value : "Weather Conditions", value: 'Weather Conditions' },
    { label: selectedLanguage?.name == "English" ? "Unexpected Health Issues" : translation[1401]?.value ? translation[1401]?.value : "Unexpected Health Issues", value: 'Unexpected Health Issues' }
  ]


  const handleVoidInputChange = (e) => {
    const { name, value } = e.target;
    setVoidReason((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({
      prev,
      vode_note: ""
    }))
  }


  const appointmentCheckInSuccess = (res) => {
    setCheckInTime(res?.response?.Appointment?.check_in_time)
  }


  return (
    <>
      <div className={`${onlyView ? 'p-0' : 'lg:pr-[2rem] pr-[1rem] lg:py-[2.5rem] py-[1.3rem] '}  min-h-full bounce-in-right`}>
        <div className="flex flex-col gap-4">
          {
            onlyView ? '' : (<div className={`flex items-center justify-between gap-5 ${onlyView ? '' : ' lg:pl-[2rem] pl-[1rem]'}`}>
              <div className="flex items-center gap-3">
                <div className="w-fit" onClick={() => {
                  // window.location.pathname = '/dashboard/calendar'
                  // window.location.href = '/dashboard/calendar'
                  navigate('/dashboard')
                }}>
                  <Svgs.Back />
                </div>
                <h2 className="text-primary font-bold lg:text-3xl text-xl">
                  {selectedLanguage?.name == "English" ? "Appointment Details" : translation[1550]?.value ? translation[1550]?.value : "Appointment Details"}

                </h2>
              </div>
              <div className="flex items-center gap-4">
                {/* {!employee ||
                    (employee &&
                      employeePermissions?.calender_block_time?.includes(
                        "create"
                      )) ? (
                    <AddBlockTime />
                  ) : (
                    ""
                  )} */}
                {!employee ||
                  (employee &&
                    employeePermissions?.calender_appointment?.includes(
                      "create"
                    )) ? (
                  <>
                    {(appointmentData?.status != "Cancelled" && appointmentData?.status != "Done") && (
                      <LoginBtn
                        className={`${appointmentData?.status == 'Started' || appointmentData?.status == 'Finished' ? "bg-[#E8E8E8] !text-[#B4B4B4]" : 'bg-[#E0EBFD] text-[#5679FF]'}  !px-7`}
                        customThemeBtn={'custom-theme-btn-reschedule'}
                        text={selectedLanguage?.name == "English" ? "Reschedule" : translation[166]?.value ? translation[166]?.value : "Reschedule"}
                        disabled={appointmentData?.status == 'Started' || appointmentData?.status == 'Finished'}
                        onClick={() => {
                          navigate(
                            //   `/dashboard/calendar/appointment-detail/${appointmentData?.id}/reschedule/?action=reschedul&app_group_id=${app_group_id ? app_group_id : appGroupId}`
                            `/dashboard/calendar/appointment-detail/${params.appointment_service_id ? params.appointment_service_id : serviceId}/reschedule/?action=reschedul&app_group_id=${app_group_id ? app_group_id : appGroupId}`
                          );
                        }}
                      />
                    )}

                    <LoginBtn

                      onClick={() => {
                        navigate("/dashboard/calendar/add-appointment");
                      }}
                    >
                      <div className="flex items-center gap-2">
                        <Svgs.Plus />
                        <p className="">
                          {selectedLanguage?.name == "English" ? "New Appointment" : translation[18]?.value ? translation[18]?.value : "New Appointment"}
                        </p>
                      </div>
                    </LoginBtn>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>)
          }
          {onlyView ? '' : <hr />}
          <div className={`${onlyView ? '' : 'lg:pl-[2rem] pl-[1rem]'}  grid lg:grid-cols-5 gap-[1rem]`}>
            <div className="flex flex-col gap-5 lg:col-span-3">

              {!loader ?
                <>
                  <div className="appointment-shadow border-[#E6E6E6] border-solid border-[1px] rounded-xl">
                    <div className="flex items-end justify-between md:gap-0 gap-3 p-4 relative border-b-[1px] border-solid">
                      <div className="flex flex-col gap-2 pr-5">
                        <div className="">
                          <span className={`font-semibold text-2xl leading-6 ${appointmentData?.client_type !== "Walkin" ? 'cursor-pointer animate-pulse text-[#5679FF] border-b border-[#5679FF]' : ''}`} onClick={() => {
                            if (appointmentData?.client_type !== "Walkin") {
                              setClientDetails({
                                client_id: appointmentData?.client,
                                isOpen: !clientDetails?.isOpen
                              })
                            }
                          }}>
                            {appointmentData?.client_type !== "Walkin" && appointmentData?.client_name ? appointmentData?.client_name : "Walkin"}
                          </span>
                          {/* {selectedLanguage?.name == "English" ? "Appointment History" : translation[157]?.value ? translation[157]?.value : "Appointment History"} */}
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className="flex gap-5 items-center">
                            {/* {(appointmentData?.client_type !== "Walkin" && appointmentData?.client_email) &&
                              <p className="text-[#90959D] text-sm">
                                {appointmentData?.client_type !== "Walkin" && appointmentData?.client_email ? appointmentData?.client_email : ""}
                              </p>
                            } */}
                            {(appointmentData?.client_type !== "Walkin" && appointmentData?.client_phone) &&
                              <div className="flex gap-2 items-center">
                                <Svgs.Phone className={'!w-3 !h-3'} />
                                <p className="text-[#90959D] text-sm">
                                  {appointmentData?.client_type !== "Walkin" && appointmentData?.client_phone ? appointmentData?.client_phone : ""}
                                </p>
                              </div>
                            }
                          </div>

                          {
                            (appointmentData?.client_type !== "Walkin") &&
                            <div className="flex gap-2 items-center">
                              <Svgs.AppointmentIcon className={'!w-3 !h-3'} />
                              <p className="text-[#90959D] text-sm">
                                Total Appointments: {appointmentData?.client_all_appointment ? appointmentData?.client_all_appointment : 0}
                              </p>
                            </div>
                          }
                          {
                            (appointmentData?.client_type !== "Walkin") &&
                            <div className="flex gap-2 items-center">
                              <Svgs.SalesIcon className={'w-3 h-3'} />
                              <p className="text-[#90959D] text-sm">
                                Total Sales: {appointmentData?.total_sales ? appointmentData?.total_sales : 0}
                              </p>
                            </div>
                          }

                        </div>
                        {/* <p className="text-[#90959D] text-sm">
                          {selectedLanguage?.name == "English" ? "You will see appointment related information" : translation[159]?.value ? translation[159]?.value : "You will see appointment related information"}
                        </p> */}
                        <div className="rounded-md border-[1px] border-solid border-[#7C98FF] w-fit text-[#5679FF] bg-white px-2 py-1 text-xs font-semibold whitespace-nowrap">
                          {/* {appointmentData?.client_type} {" "}
                          {selectedLanguage?.name == "English" ? "Appointment" : translation[158]?.value ? translation[158]?.value : "Appointment"} */}
                          <span className="">Appointment ID : </span><span className="">{appointmentData?.id?.substring(0, 8)?.toUpperCase()}</span>
                        </div>
                      </div>
                      {/* {appointmentData?.status != "Cancelled" && ( */}
                      <div className="flex justify-center">
                        <div className="dropdown relative flex items-center justify-end gap-5 flex-wrap">
                          <button
                            className=""
                            type="button"
                            id="dropdownMenuButton1"
                            onClick={() => {
                              if (buttonLabel == 'Done') {
                                navigate('/dashboard/calendar')
                              }
                            }}
                          >
                            <div className="flex items-center gap-2 text-[#5679ff] font-bold">
                              <Svgs.CircleTick />
                              {buttonLabel}
                            </div>
                          </button>

                        </div>
                      </div>
                      {/* )} */}
                      {(appointmentData?.status != "Done" && appointmentData?.status != "Cancelled") &&
                        <div className="absolute right-3 top-2">
                          <LoginBtn onClick={() => setShowActionMenu(!showActionMenu)} >
                            <div className="flex items-center gap-2">
                              <p className="leading-3 text-xs font-normal">Action</p>
                              <Svgs.Chevron_down stroke={'#fff'} className={'!w-3 !h-3'} />
                            </div>
                          </LoginBtn>
                        </div>}
                      {showActionMenu &&
                        <>
                          <div
                            className="fixed inset-0 bg-black/20 z-[100] top-0 bottom-0 left-0 right-0"
                            onClick={() => {
                              setShowActionMenu(false)
                            }}
                          ></div>
                          <div className={`absolute right-2 z-[101] top-14 rounded-md bg-white  shadow-lg `}>
                            {((!employee || (employee && employeePermissions?.calender_appointment?.includes("edit")))) ?
                              (appointmentData?.status != "Done" && appointmentData?.status != "Cancelled") ? (
                                <button
                                  className="flex items-center justify-center gap-5 p-5 py-3 hover:bg-[rgba(86,120,255,0.09)]"
                                  type="button"
                                  onClick={() => {
                                    navigate(
                                      `/dashboard/calendar/appointment-detail/${params.appointment_service_id ? params.appointment_service_id : serviceId}/reschedule/?action=edit&app_group_id=${app_group_id ? app_group_id : appGroupId}`
                                    );
                                  }}>
                                  <Svgs.EditPen className={'w-5 h-5'} />
                                  <p className="">Edit Appointment</p>
                                </button>
                              ) : ""
                              : ""}
                            {appointmentData?.status != "Cancelled" && (
                              <div className="">
                                {(appointmentData?.status != "Done" && showButtons) && (
                                  <div className="">
                                    {!employee ||
                                      (employee &&
                                        employeePermissions?.calender_appointment?.includes(
                                          "Cancelled"
                                        )) ? (
                                      <button
                                        className="flex items-center gap-5 w-full hover:bg-[rgba(86,120,255,0.09)] rounded-md py-3 p-5"
                                        onClick={() => {
                                          setCancelDeletePopup(!cancelDeletePopup)
                                        }}
                                      >
                                        <Svgs.Delete size={'1.1rem'} fill={'rgb(86,121,255)'} />
                                        <p className="">Cancel</p>
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )}
                              </div>
                            )}


                          </div>
                        </>
                      }
                    </div>
                    <div className="p-4 space-y-6 max-h-[42vh] overflow-auto">
                      {appointmentServices?.map((srvData, index) => {


                        return (
                          <>
                            <div className="relative pl-[1.2rem]">
                              <div className={`absolute left-0 top-1/2 ${index < appointmentServices.length - 1 ? "h-full" : "h-fit"} `}>
                                <div className={`${srvData?.status == 'Started' || srvData?.status == 'Finished' ? 'bg-[#5679FF]' : 'bg-[#E8E8E8]'} z-10 absolute w-5 h-5 -left-2 rounded-full `}></div>
                                {index < appointmentServices.length - 1 && (
                                  <div className={`${srvData?.status == 'Finished' ? (appointmentServices[index + 1]?.status === 'Booked' || appointmentServices[index + 1]?.status === 'Started') ? '!h-[120%] bg-[#5679FF]' : 'bg-[#5679FF]' : 'bg-[#E8E8E8]'} absolute h-[114%] w-1`}></div>
                                )}
                              </div>

                              {/* <div className="border-t-2 border-[#CFCFDC] border-dashed px-4 py-2.5 flex items-center justify-between"></div> */}
                              <div className="p-4 relative border-solid border-[1px] border-[#E6E6E6] bg-white drop-shadow rounded-xl pt-0 pb-[1.2rem]">
                                <div className={` relative  border-dashed border-b border-[#CFCFDC] space-y-2 pt-0 pb-1`}>
                                  <div className="flex justify-between items-center my-2">
                                    <h2 className='bg-primary text-white rounded-md px-3 py-1'>{index + 1}</h2>
                                    {srvData?.status != 'Booked' && appointmentData?.status != "Done" && appointmentData?.status != 'Cancelled' &&
                                      <LoginBtn
                                        className={`z-[123123123] !bg-[#fff] cursor-default !text-[#5679FF] border-[#5679FF] !px-[14px] !py-2 !font-semibold  border-[1px] border-solid ${srvData?.status == 'Finished' && 'hover:!shadow-none !shadow-none'} `}
                                        customThemeBtn={'custom-theme-btn-reschedule'}
                                        text={`Request Reversal`}
                                        onClick={() => {
                                          setRequestReversalNote({
                                            appointment_id: appointmentData?.id,
                                            appointment_service: srvData?.id,
                                            description: "",
                                            existingStatus: srvData?.reversal_status ? srvData?.reversal_status : ""
                                          })
                                          setRequestReversalPopup(true)
                                        }}
                                      />
                                    }
                                  </div>
                                </div>
                                <div className={` ${srvData?.status == 'Finished' || srvData?.status == 'Void' ? 'bg-[#0000000e] block ' : 'hidden'} absolute w-full h-full left-0 top-0 rounded-xl z-20`}>
                                </div>
                                <div className="flex items-center justify-between gap-2 w-full py-2 relative">
                                  <div className={` ${srvData?.status == 'Finished' || srvData?.status == 'Void' ? 'block ' : 'hidden'}  absolute w-full h-full left-0 top-0 rounded-xl z-20`}>
                                    <LoginBtn
                                      className={`absolute right-0 top-[0.4rem] !bg-[#fff] cursor-default !text-[#5679FF] border-[#5679FF] !px-[14px] !py-2 !font-semibold  border-[1px] border-solid ${srvData?.status == 'Finished' && 'hover:!shadow-none !shadow-none'} `}
                                      customThemeBtn={'custom-theme-btn-reschedule'}
                                      text={`${srvData?.status == 'Void' ? 'Void' : 'Complete'}`}
                                    />
                                  </div>
                                  <h3 className="lg:text-2xl sm:text-xl text-lg font-medium text-black self-start">
                                    {srvData?.srv_name}
                                  </h3>
                                  {(showServiceButtons && appointmentData?.status != "Done") &&
                                    <>
                                      {/* {((!srvData?.service_start_time || srvData?.service_start_time == undefined) && srvData?.status !== "Void") && */}
                                      <div className="flex items-center justify-end mb-2">

                                        {/* {!employee && ( */}

                                        <LoginBtn
                                          className={` ${srvData?.status == 'Started' || srvData?.status == 'Finished' || srvData?.status == 'Void' ? 'bg-[#E8E8E8] !text-[#B4B4B4] border-none' : '!bg-[#fff] !text-[#5679FF] border-[#5679FF]'} !px-[14px] !py-2 !font-semibold  border-[1px] border-solid ${srvData?.status === 'Finished' && 'hover:!shadow-none !shadow-none'}`}
                                          customThemeBtn={'custom-theme-btn-reschedule'}
                                          text={selectedLanguage?.name == "English" ? "Void" : translation[1339]?.value ? translation[1339]?.value : "Void"}
                                          onClick={() => {
                                            setDataObj({
                                              srvId: srvData?.id,
                                              appointmentId: appointmentData?.id,
                                              type: "Void"
                                            })
                                            setVoidPopup(!voidPopup)
                                            // updateAppointmentServiceStatus(index, srvData?.id, appointmentData?.id, "Void")
                                          }}
                                          disabled={srvData?.status == 'Started' || srvData?.status == 'Finished' || srvData?.status == 'Void'}
                                        // disabled={statusLoader && statusLoader?.Void && statusLoader?.index === index}
                                        // loading={statusLoader && statusLoader?.Void && statusLoader?.index === index}
                                        />
                                        {/* )} */}
                                      </div>
                                      {/* } */}
                                    </>
                                  }
                                </div>
                                <div className="grid md:grid-cols-3 grid-cols-2 md:gap-2 gap-5 justify-between w-full !mt-0">

                                  <div className="flex flex-col gap-1">
                                    <p className="text-[#A1A1A1] text-sm">{selectedLanguage?.name == "English" ? "Booked By" : translation[161]?.value ? translation[161]?.value : "Booked By"}:</p>
                                    <p className="text-primary font-semibold text-sm">
                                      {srvData?.booked_by}
                                    </p>
                                  </div>
                                  <div className="flex flex-col gap-1">
                                    <p className="text-[#A1A1A1] text-sm">{selectedLanguage?.name == "English" ? "Assigned to" : translation[161]?.value ? translation[161]?.value : "Assigned to"}:</p>
                                    <p className="text-primary font-semibold text-sm">
                                      {srvData?.appointment_service_member?.full_name}
                                    </p>
                                  </div>


                                  {
                                    srvData?.status == 'Started' || srvData?.status == 'Finished' ? (srvData?.service_start_time &&
                                      <div className="flex flex-col gap-1">
                                        <p className="text-[#A1A1A1] text-sm">{selectedLanguage?.name == "English" ? "Start Time / End Time " : translation[73]?.value ? translation[73]?.value : "Start Time / End Time"}:</p>
                                        <p className="text-primary font-semibold text-sm">
                                          {moment(srvData?.service_start_time)?.format('hh:mm A')} / {srvData?.service_end_time && moment(srvData?.service_end_time)?.format('hh:mm A')}
                                        </p>
                                      </div>)
                                      : (<div className="flex flex-col gap-1">
                                        <p className="text-[#A1A1A1] text-sm">{selectedLanguage?.name == "English" ? "Service Duration" : translation[164]?.value ? translation[164]?.value : "Service Duration"}:</p>
                                        <p className="text-primary font-semibold text-sm">
                                          {srvData?.duration}
                                        </p>
                                      </div>)
                                  }
                                  <div className="flex flex-col gap-1">
                                    <p className="text-[#A1A1A1] text-sm">{selectedLanguage?.name == "English" ? "Time and Date" : translation[164]?.value ? translation[164]?.value : "Time and Date"}:</p>
                                    <p className="text-primary font-semibold text-sm">
                                      {
                                        CALENDAR_OPENING_TIME.find(
                                          (itm) =>
                                            itm.value == srvData?.appointment_time
                                        )?.label
                                      }{" "}
                                      -{" "}
                                      {
                                        CALENDAR_OPENING_TIME.find(
                                          (itm) => itm.value == srvData?.end_time
                                        )?.label
                                      }{" "}
                                      <Moment format="ddd, DD MMM YY">
                                        {srvData?.appointment_date}
                                      </Moment>
                                    </p>
                                  </div>
                                  <div className="flex flex-col gap-1">
                                    <p className="text-[#A1A1A1] text-sm">{selectedLanguage?.name == "English" ? "Price" : translation[200]?.value ? translation[200]?.value : "Price"}:</p>
                                    <p className="text-primary font-semibold text-sm">
                                      {+(srvData?.price)?.toFixed(2)} {selectedLocationCurrency}
                                    </p>
                                  </div>

                                  {(showServiceButtons && appointmentData?.status != "Done" &&
                                    (srvData?.status != 'Finished' && srvData?.status != 'Void')) &&
                                    <div className="col-span-3">
                                      {/* {srvData?.status !== "Void" && */}
                                      <div className="flex items-end justify-end flex-wrap gap-3 mb-00 h-full">

                                        <div className="flex items-center gap-3">
                                          {/* {!employee && ( */}
                                          <LoginBtn
                                            className={`${srvData?.status == 'Started' || srvData?.status == 'Finished' || srvData?.status == 'Void' ? 'bg-[#AFB7DD] !text-[#fff] border-none' : 'bg-[#5679FF] !text-[#fff]'}`}
                                            customThemeBtn={'custom-theme-btn-reschedule'}
                                            text={selectedLanguage?.name == "English" ? "Start" : translation[640]?.value ? translation[640]?.value : "Start"}
                                            onClick={() => {
                                              updateAppointmentServiceStatus(srvData?.id, appointmentData?.id, "Started")
                                            }}
                                            disabled={(statusLoader && statusLoader?.Started && statusLoader?.id == srvData?.id) ||
                                              srvData?.service_start_time}
                                            loading={statusLoader && statusLoader?.Started && statusLoader?.id == srvData?.id}
                                          />
                                          {/* )} */}

                                          {/* {!employee && ( */}
                                          <LoginBtn
                                            className={`${srvData?.status == 'Started' ? '!bg-[#5679FF] !text-[#fff]' : (srvData?.status == 'Finished' || srvData?.status == 'Void') ? 'bg-[#AFB7DD] !text-[#fff] border-none' : 'bg-[#E8E8E8] !text-[#B4B4B4] border-none'}`}
                                            customThemeBtn={'custom-theme-btn-reschedule'}
                                            text={selectedLanguage?.name == "English" ? "Finish" : translation[547]?.value ? translation[547]?.value : "Finish"}
                                            onClick={() => {
                                              updateAppointmentServiceStatus(srvData?.id, appointmentData?.id, "Finished")
                                            }}
                                            disabled={(statusLoader && statusLoader?.Finished && statusLoader?.id == srvData?.id) ||
                                              srvData?.service_end_time || !srvData?.service_start_time
                                              || srvData?.service_start_time == undefined}
                                            loading={statusLoader && statusLoader?.Finished && statusLoader?.id == srvData?.id}
                                          />
                                          {/* )} */}
                                        </div>
                                      </div>
                                      {/* } */}
                                    </div>
                                  }
                                </div>

                              </div>
                            </div>
                          </>
                        )
                      })}
                      <div className="flex justify-end w-full">
                        {/* text={appointmentData?.check_in_time ? moment(appointmentData?.check_in_time).format('ddd MMM DD hh:mm A') : (selectedLanguage?.name == "English" ? "Check In" : translation[1632]?.value ? translation[1632]?.value : "Check In")} */}
                        {appointmentData?.check_in_time != null || checkInTime ?
                          (<div className="rounded-md border-[1px] border-solid border-[#7C98FF] w-fit text-[#5679FF] bg-white p-2 text-xs font-semibold whitespace-nowrap">
                            <span className="">Check In : </span><span>{moment(checkInTime ? checkInTime : appointmentData?.check_in_time).format(' DD MMM yyyy hh:mm A')}</span>
                          </div>)
                          :
                          (<LoginBtn
                            className={'bg-[#5679FF] !text-[#fff]'}
                            customThemeBtn={'custom-theme-btn-reschedule'}
                            text={selectedLanguage?.name == "English" ? "Check In" : translation[1632]?.value ? translation[1632]?.value : "Check In"}
                            onClick={() => {
                              UserCheckIn({ appointment_id: appointmentData?.id }, appointmentCheckInSuccess)
                            }}
                            disabled={appointmentData?.status !== 'Booked'}
                          />)
                        }
                      </div>
                    </div>
                  </div>

                </>
                : (
                  <>
                    <AppointmentDetailShimmer onlyView />
                  </>
                )}
              {
                onlyView ? '' : (
                  <div className="rounded-md appointment-shadow p-4 bg-[#ECF3FF]">
                    <div className="flex items-start gap-4">
                      <div>
                        <Svgs.ListCheck />
                      </div>
                      <div className="flex flex-col gap-1">
                        <h2 className="font-bold text-primary text-lg">

                          {selectedLanguage?.name == "English" ? "Appointment Notes" : translation[1557]?.value ? translation[1557]?.value : "Appointment Notes"}
                        </h2>
                        {appointmentData?.notes ? (

                          <div className="flex items-center gap-2">
                            <Svgs.CircleCheck />
                            <p className="text-[#A1A1A1] text-sm">
                              {appointmentData?.notes?.text}
                            </p>
                          </div>
                        ) : (
                          <>
                            <p className="font-medium">
                              {selectedLanguage?.name == "English" ? "No Instructions" : translation[169]?.value ? translation[169]?.value : "No Instructions"}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )
              }
              <div className="flex gap-3 items-center">
                {
                  onlyView ? '' : (
                    <LoginBtn
                      text={selectedLanguage?.name == "English" ? "Appointment Log" : translation[170]?.value ? translation[170]?.value : "Appointment Log"}
                      className={'bg-[#5679ff] text-white'}
                      customThemeBtn={'custom-theme-btn'}
                      onClick={() => {
                        setAppointmentLogPopup(true);
                        handleAppointmentLog(parentId)
                      }}
                    />
                  )
                }
                {
                  onlyView ? '' : (
                    <EmployeeCommentSystem appointmentDataId={appointmentData?.id} />
                  )}
              </div>


            </div>
            {appointmentServices?.filter(itm => itm?.status != "Void")?.length > 0 &&
              <div className="lg:col-span-2 flex flex-col gap-6">
                {(appointmentData?.status != "Cancelled" ||
                  appointmentData?.status != "Paid") && (
                    <BookingDetailCard
                      subTotal={subTotal}
                      setSubTotal={setSubTotal}
                      Taxes={(dt) => null}
                      payBtnDisabled={false}
                      tips={tipsApplied}
                      tip_sum={appliedTipTotal}
                      // appointmentData={appointmentData}
                      loading={loader}
                      payNowBtnDisabled={appointmentData?.status === 'Cancelled' ? true : false}
                      status={appointmentData?.status}
                      allowCheckout={true}
                      setTaxValuesForApi={setTaxValuesForApi}
                      services={
                        appointmentServices?.length > 0
                          ? appointmentServices?.filter(itm => itm?.status != "Void")?.map((itm) => {
                            return {
                              id: itm?.id,
                              price: itm?.price,
                              name: itm?.srv_name,
                            };
                          })
                          : []
                      }
                      price={appointmentServices?.filter(itm => itm?.status != "Void")?.reduce(function (
                        accumulator,
                        curValue
                      ) {
                        return accumulator + curValue.price;
                      },
                        0)}
                      location={appointmentData?.location}
                      onPayClick={() => {
                        navigate(
                          `/dashboard/calendar/appointment/${params.appointment_service_id ? params.appointment_service_id : serviceId}/checkout/finish/?app_group_id=${app_group_id ? app_group_id : appGroupId}`
                        );
                      }}
                    />
                  )}

                <SupportCard />
              </div>
            }
          </div>
        </div>
      </div>

      {cancelDeletePopup && (
        <Popup
          heading={selectedLanguage?.name == "English"
            ? "Cancel Appointment"
            : translation[1300]?.value
              ? translation[1300]?.value
              : "Cancel Appointment"
          }
          onclose={() => setCancelDeletePopup(false)}
        >
          <div className="">
            <p>
              {selectedLanguage?.name == "English"
                ? "Select a reason for cancellation and provide a brief explanation to help us improve our services. We appreciate your feedback!"
                : translation[1468]?.value
                  ? translation[1468]?.value
                  : "Select a reason for cancellation and provide a brief explanation to help us improve our services. We appreciate your feedback!"
              }

            </p>
            <div className="flex flex-col mt-[2rem] gap-4">
              <Dropwdown
                title={selectedLanguage?.name == "English" ? "Select Reason" : translation[1392]?.value ? translation[1392]?.value : "Select Reason"}
                required={true}
                name={"reason"}
                value={cancelReason?.reason}
                error={errors.reason}
                onChange={handleCancelInputChange}
                placeholder={selectedLanguage?.name == "English" ? "Select Reason" : translation[1392]?.value ? translation[1392]?.value : "Select Reason"}
                options={cancelReasonOptions}
              />

              <Textarea
                title={selectedLanguage?.name == "English" ? "Note" : translation[536]?.value ? translation[536]?.value : "Note"}
                required={true}
                name={"cancel_note"}
                value={cancelReason?.cancel_note}
                onChange={handleCancelInputChange}
                error={errors.cancel_note}
                rows='5'
                limit={'150'}
              />
            </div>
            <div className="flex items-center justify-end mt-[2rem]">
              <LoginBtn
                deleteBtn
                text={selectedLanguage?.name == "English" ? "Yes! Cancel" : translation[1298]?.value ? translation[1298]?.value : "Yes! Cancel"}
                onClick={() => {
                  // setDataUpdated(true);
                  cancel_appointment()
                  // update_appointment()
                }}

                loading={deleteLoader}
                disabled={deleteLoader}
              />
            </div>
          </div>
        </Popup>
      )}

      <Popup heading={`Voucher Payment`} close={setVoucher} open={Voucher}>
        <div className="flex flex-col gap-4">
          <div className="text-sm flex items-center gap-2 px-4 py-2 bg-[#F4F7FA] rounded-md">
            <Svgs.I />
            <p className="text-[#555555]">
              Total amount to pay is <span className="tetx-black">RS. 50</span>{" "}
            </p>
          </div>
          <LoginInput
            title="Voucher Code"
            placeholder="Enter Voucher Code "
            required={true}
            padding="py-1"
            leftIcon={<Svgs.Search />}
          />
        </div>
        <div className="flex items-center justify-start mt-[2rem]">
          <LoginBtn>Pay Now</LoginBtn>
        </div>
      </Popup>

      <Popup
        size="md"
        heading={`Split Tip `}
        close={setSplitTip}
        open={SplitTip}
      >
        <p className="text-primary text-sm">Team Members</p>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <Dropwdown placeholder="member" showTitle={false} />
            <div className="flex items-center gap-3 border rounded-md p-3">
              <p className="text-xs text-[#A1A1A1]">PKR</p>
              <input
                type="text"
                className="outline-none border-none w-[3rem] text-sm"
                placeholder="12"
              />
            </div>
            <div className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center">
              <Svgs.Delete fill="#7D7D7D" />
            </div>
          </div>
          <div className="flex items-center gap-4">
            <Dropwdown placeholder="member" showTitle={false} />
            <div className="flex items-center gap-3 border rounded-md p-3">
              <p className="text-xs text-[#A1A1A1]">PKR</p>
              <input
                type="text"
                className="outline-none border-none w-[3rem] text-sm"
                placeholder="12"
              />
            </div>
            <div className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center">
              <Svgs.Delete fill="#7D7D7D" />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-start mt-[2rem]">
          <LoginBtn>Save Changes</LoginBtn>
        </div>
      </Popup>

      <Popup
        heading={
          fetchLogs
            ?
            <div className="h-7 w-2/5 bg-gray-200 rounded-lg" />
            :
            <p>
              {selectedLanguage?.name == "English" ? "Booking Id" : translation[173]?.value ? translation[173]?.value : "Booking Id"}: #{appointmentLog ? appointmentLog[0]?.id?.split("-")[0].split("").slice(0, 6).join("") : "-------"}
            </p>
        }
        close={setAppointmentLogPopup}
        open={appointmentLogPopup}
      >
        {fetchLogs ?
          [0, 1, 2]?.map((log, i) => (
            <AppointmentLogShimmers />
          ))
          :
          appointmentLog?.map((log, i) => (
            <div className="flex flex-col gap-3 mb-1">
              <div className="border rounded-lg">
                <div className="px-5 py-3 bg-[#ECF3FF]">
                  <div className="grid grid-cols-4 gap-3">
                    <div className="flex flex-col gap-1 justify-center">
                      <p className="text-[#101928]/50 text-xs">
                        {selectedLanguage?.name == "English" ? "Log Type" : translation[174]?.value ? translation[174]?.value : "Log Type"}
                      </p>
                      <p className="text-[#101928] text-sm">{log?.log_type}</p>
                    </div>
                    <div className="flex flex-col gap-1 justify-center">
                      <p className="text-[#101928]/50 text-xs">
                        {selectedLanguage?.name == "English" ? "Logged By" : translation[175]?.value ? translation[175]?.value : "Logged By"}
                      </p>
                      <p className="text-[#101928] text-sm">{log?.logged_by ? log?.logged_by : "------"}</p>
                    </div>
                    <div className="flex flex-col gap-1 justify-center">
                      <p className="text-[#101928]/50 text-xs">
                        {selectedLanguage?.name == "English" ? "Date & Time" : translation[164]?.value ? translation[164]?.value : "Date & Time"}
                      </p>
                      <p className="text-[#101928] text-sm">{moment(log?.created_at).format('DD MMMM YYYY')} at {moment(log?.created_at).format('hh:mm:ss')}</p>
                    </div>
                    <div className="flex flex-col gap-1 justify-center">
                      {clickedLogIndex === i ?
                        <LoginBtn key={i} onClick={() => setClickedLogIndex(-1)}>
                          {selectedLanguage?.name == "English" ? "Click to close" : translation[177]?.value ? translation[177]?.value : "Click to close"}
                        </LoginBtn> :
                        <LoginBtn key={i} onClick={() => setClickedLogIndex(i)}>
                          {selectedLanguage?.name == "English" ? "Log Details" : translation[176]?.value ? translation[176]?.value : "Log Details"}
                        </LoginBtn>
                      }
                    </div>
                  </div>
                </div>
                {clickedLogIndex === i ? log?.log_details?.map((detail, ind) => (
                  <div key={ind}>
                    <div className="py-2 px-5">
                      <h1 className="font-semibold text-lg">
                        {selectedLanguage?.name == "English" ? "Log Details" : translation[176]?.value ? translation[176]?.value : "Log Details"}:
                      </h1>
                    </div>
                    <div className="bg-[#FBFCFF] px-5 py-2 grid grid-cols-4 gap-3 text-sm text-[#101928]/50">
                      <h1>{selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}</h1>
                      <h1>{selectedLanguage?.name == "English" ? "Duration" : translation[112]?.value ? translation[112]?.value : "Duration"}</h1>
                      <h1>{selectedLanguage?.name == "English" ? "Start Time" : translation[73]?.value ? translation[73]?.value : "Start Time"}</h1>
                      <h1>{selectedLanguage?.name == "English" ? "Assigned Staff" : translation[178]?.value ? translation[178]?.value : "Assigned Staff"}</h1>
                    </div>
                    <div className="border-b px-5 py-3 grid grid-cols-4 gap-3 text-sm text-[#101928]">
                      <h1>{detail?.service}</h1>
                      <h1>{detail?.duration}</h1>
                      <h1>{moment(detail?.appointment_time, "HH:mm:ss").format("hh:mm:ss A")}</h1>
                      <h1>{detail?.assigned_staff}</h1>
                    </div>
                  </div>
                )) : null}

              </div>
            </div>
          ))}
        {/* <div key={i}>
            <div className="flex flex-col gap-4 mb-[2rem]">
              <h1>Logged Type</h1>
              <p>{log?.log_type}</p>
              <h1>Logged By</h1>
              <p>{log?.logged_by ? log?.logged_by : "------"}</p>
              <h1>Date & Time</h1>
              <p>{moment(log?.created_at).format('yyy mm ddd HH:mm')}</p>
              <h1>Log Details</h1>
              <LoginBtn key={i} onClick={() => setClickedLogIndex(i)}>
                Click to view
              </LoginBtn>
            </div>
            {clickedLogIndex === i ? log?.log_details?.map((detail, ind) => (
              <div className="flex flex-col gap-4 mt-[2rem] mb-[2rem]" key={ind}>
                <h1>Log Details:</h1>
                <div className="flex flex-col gap-4">
                  <p>Service:<span>{detail?.service}</span></p>
                  <p>Duration:<span>{detail?.duration}</span></p>
                  <p>Start Time:<span>{detail?.appointment_time}</span></p>
                  <p>Assigned Staff:<span>{detail?.assigned_staff}</span></p>
                </div>
              </div>
            )) : null}
          </div> */}
      </Popup>

      {voidPopup && (
        <Popup
          heading={selectedLanguage?.name == "English"
            ? "Void Appointment"
            : translation[1353]?.value
              ? translation[1353]?.value
              : "Void Appointment"}
          onclose={() => { setVoidPopup(false); setDataObj({}); }}
          open={voidPopup}
        >
          <div className="flexd items-center justify-center space-y-4">
            {/* <Svgs.Cross className='w-20 h-20 m-auto rounded-full border p-5 border-[#5679FF]' fill='#5679FF' /> */}
            <div className="flex items-center flex-col gap-3 text-center ">
              {/* <h4 className="text-[#B4B4B4] text-2xl font-medium">{selectedLanguage?.name == "English" ? "Are you sure" : translation[1350]?.value ? translation[1350]?.value : "Are you sure"}</h4>
              <p className="text-[#4B4B4B] ">{selectedLanguage?.name == "English" ? "Do you really want to void  this service?" : translation[1351]?.value ? translation[1351]?.value : "Do you really want to void  this service?"} <br />{selectedLanguage?.name == "English" ? "This process cannot be undone." : translation[1352]?.value ? translation[1352]?.value : "This process cannot be undone."}</p> */}
              <div className="w-full text-left">
                <Textarea
                  title={selectedLanguage?.name == "English" ? "Void Reason" : translation[1607]?.value ? translation[1607]?.value : "Void Reason"}
                  required={true}
                  name={"void_note"}
                  value={voidReason?.void_note}
                  onChange={handleVoidInputChange}
                  error={errors.void_note}
                  rows='5'
                  limit={'150'}
                />
              </div>
              <div className="w-full flex justify-end">
                <LoginBtn
                  className={'bg-[#5679FF] !px-[14px] !py-2 !font-semibold border-[#5679FF] border-[1px] border-solid !text-[#FFF]'}
                  customThemeBtn={'custom-theme-btn-reschedule'}
                  text={selectedLanguage?.name == "English" ? "Void" : translation[1339]?.value ? translation[1339]?.value : "Void"}
                  onClick={() => {
                    const { void_note } = voidReason;
                    let requiredFields = {}
                    requiredFields = { void_note }

                    setErrors(validation(requiredFields));
                    let result = validation(requiredFields);
                    if (objectKeyConvertToArray(result)?.length === 0) {
                      updateAppointmentServiceStatus(dataObj?.srvId, dataObj?.appointmentId, dataObj?.type, voidReason)
                    }
                  }}
                  // disabled={statusLoader && statusLoader?.Void && statusLoader?.index === index}
                  loading={statusLoader && statusLoader?.Void}
                />
              </div>
            </div>
          </div>
        </Popup>
      )}

      {clientDetails && (
        <Popup
          size="xl"
          heading={selectedLanguage?.name == "English"
            ? "Client Details"
            : translation[382]?.value
              ? translation[382]?.value
              : "Client Details"}
          onclose={() => {
            setClientDetails({
              client_id: '',
              isOpen: !clientDetails?.isOpen
            })
          }}
          open={clientDetails.isOpen}
        >
          <div className="flexd items-center justify-center space-y-4">
            <ClientSection client_id={clientDetails?.client_id} removeBtn={true} />
          </div>
        </Popup>
      )}

      {requestReversalPopup && (
        <Popup
          heading={selectedLanguage?.name == "English"
            ? "Request Reversal"
            : translation[1559]?.value
              ? translation[1559]?.value
              : "Request Reversal"}
          onclose={() => {
            setRequestReversalNote({
              appointment_id: "",
              appointment_service: "",
              description: ""
            })
            setRequestReversalPopup(false)
          }}
          open={requestReversalPopup}
        >
          <div className="">
            <div className="flex items-center justify-between w-full">
              <p>
                {selectedLanguage?.name == "English"
                  ? "Request for service reversal"
                  : translation[1563]?.value
                    ? translation[1563]?.value
                    : "Request for service reversal"
                }
              </p>
              {requestReversalNote?.existingStatus !== "" &&
                <LoginBtn
                  borderBtn
                  customThemeBtn={`theme-btn hover:!shadow-none !shadow-none !border !capitalize  !py-[7px] !px-[15px] !rounded-[12px] ${requestReversalNote?.existingStatus == "accepted" ? "!border-[#05D672] !bg-[#05D67226] !text-[#05D672]" : requestReversalNote?.existingStatus == "rejected" ? "!border-[#F95C5C] !bg-[#F95C5C]/10 !text-[#F95C5C]" : "!border-[#FF9843] !bg-[#FF984326] !text-[#FF9843]"}`}
                  text={requestReversalNote?.existingStatus == 'accepted' ? "Accepted" : requestReversalNote?.existingStatus == 'rejected' ? "Rejected" : "Pending"}
                />
              }
            </div>
            <div className="flex flex-col gap-4 mt-4">
              <Textarea
                title={selectedLanguage?.name == "English" ? "Reason" : translation[536]?.value ? translation[536]?.value : "Reason"}
                required={true}
                name={"request_note"}
                placeholder={"Enter reason of reversal request"}
                value={requestReversalNote?.description}
                onChange={(e) => {
                  setRequestReversalNote((prev) => ({
                    ...prev,
                    description: e.target.value
                  }))
                  setErrors((prev) => ({
                    prev,
                    reversalNote: ""
                  }))
                }}
                error={errors.reversalNote}
                rows='5'
                limit={'150'}
              />
            </div>
            <div className="flex items-center justify-end mt-[2rem]">
              <LoginBtn
                text={selectedLanguage?.name == "English" ? "Apply" : translation[1421]?.value ? translation[1421]?.value : "Apply"}
                onClick={() => {
                  handleReversalRequest()
                }}
                className={`!px-7 bg-primary !text-white`}
                customThemeBtn={'custom-theme-btn-reschedule'}
                loading={reversalLoader}
                disabled={reversalLoader || requestReversalNote?.existingStatus == "accpeted"}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default Appointment;