import axios from "axios"
import { add_invoice_tanslation_url, BASE_URL, delete_invoice_tanslation_url, get_invoice_tanslation_url, update_invoice_tanslation_url } from "../api_variables"
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";
import { addInvoiceTranslation, deleteInvoiceTranslation, getInvoiceTranslation, updateInvoiceTranslation } from "Redux/TenantRedux/Actions/multiLanguageInvoicesActions";

export const createMultiLanguageInvoice = (data, access_token, success, fail) => async (dispatch) => {
    try {
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const response = await axios.post(`${BASE_URL}${add_invoice_tanslation_url}`, data, headers)
        if (response?.data?.status_code == 200) {
            dispatch(addInvoiceTranslation(response?.data?.response?.data))
            toast.success(response?.data?.response?.message, { toastId: "toast" })
            success && success()
        } else {
            toast.error(apiErrorMessage, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
    }
}

export const updateMultiLanguageInvoice = (data, access_token, success, fail) => async (dispatch) => {
    try {
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const response = await axios.post(`${BASE_URL}${update_invoice_tanslation_url}`, data, headers)

        if (response?.data?.status_code == 200) {
            dispatch(updateInvoiceTranslation(response?.data?.response?.data))
            toast.success(response?.data?.response?.message, { toastId: "toast" })
            success && success()
        } else {
            toast.error(apiErrorMessage, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "toast" })
        fail && fail()
    }
}

export const getMultiLanguageInvoice = (access_token, success, fail) => async (dispatch) => {
    let accessToken = localStorage.getItem("access_token");
    if (!access_token) {
        access_token = accessToken.replaceAll('"', "");
    }
    try {
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const response = await axios.get(`${BASE_URL}${get_invoice_tanslation_url}`, headers)
        if (response?.data?.status_code == 200) {
            const data = response?.data?.response?.data
            dispatch(getInvoiceTranslation(data))
            success && success()
        } else {
            toast.error(apiErrorMessage, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}

export const deleteMultiLanguageInvoice = (data, access_token, success, fail) => async (dispatch) => {
    try {
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const response = await axios.get(`${BASE_URL}${delete_invoice_tanslation_url}?id=${data?.id}`, headers)

        if (response?.data?.status_code == 200) {
            toast.success(response?.data?.response?.message, { toastId: "toast" })
            dispatch(deleteInvoiceTranslation(data?.id))
            success && success()
        } else {
            toast.error(apiErrorMessage, { toastId: "toast" })
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
}
