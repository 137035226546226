export const PRODUCT = {
    GET_PRODUCT_ORDER_STOCK: "GET_PRODUCT_ORDER_STOCK",
    GET_PRODUCT_STOCK_REPORT: "GET_PRODUCT_STOCK_REPORT",
    GET_REFUND_REPORT: "GET_REFUND_REPORT",
    GET_IDS_SALE_SERVICES: "GET_IDS_SALE_SERVICES",
    STORE_SELECTED_IDS_INTO_REDUX: "STORE_SELECTED_IDS_INTO_REDUX",
    GET_PRODUCT: "GET_PRODUCT",
    GET_PRODUCT_DROPDOWN: "GET_PRODUCT_GET_PRODUCT_DROPDOWN",
    GET_PRODUCT_DROPDOWN_PAGINATION: "GET_PRODUCT_DROPDOWN_PAGINATION",
    GET_PRODUCT_PAGES: "GET_PRODUCT_PAGES",
    GET_ALL_COUNT: 'GET_ALL_COUNT',
    GET_PRODUCT_WITH_NO_PAGINATION: 'GET_PRODUCT_WITH_NO_PAGINATION',
    ADD_PRODUCT: "ADD_PRODUCT",
    UPDATE_PRODUCT: "UPDATE_PRODUCT",
    DELETE_PRODUCT: "DELETE_PRODUCT",
    GET_SEARCH_PRODUCTS: "GET_SEARCH_PRODUCTS",
    GET_PRODUCT_UPDATED: 'GET_PRODUCT_UPDATED',
    GET_PRODUCT_STOCKS: "GET_PRODUCT_STOCKS",
    GET_PRODUCT_STOCKS_NO_PAGINATION: "GET_PRODUCT_STOCKS_NO_PAGINATION",
    GET_PRODUCT_STOCKS_PAGINATION_DATA: "GET_PRODUCT_STOCKS_PAGINATION_DATA",
    GET_FILTER_PRODUCT_STOCKS: "GET_FILTER_PRODUCT_STOCKS",
    REMOVE_STOCK_FILTERS: "REMOVE_STOCK_FILTERS",
    DELETE_PRODUCT_STOCKS: "DELETE_PRODUCT_STOCKS",
    CREATE_ORDER_STOCK: "CREATE_ORDER_STOCK",
    GET_ORDER_STOCK: "GET_ORDER_STOCK",
    UPDATE_ORDER_STOCK: "UPDATE_ORDER_STOCK",
    DELETE_ORDER_STOCK: "DELETE_ORDER_STOCK",
    GET_INSIGHT_PRODUCTS: "GET_INSIGHT_PRODUCTS",
    GET_INSIGHT_PRODUCTS_CHART: "GET_INSIGHT_PRODUCTS_CHART",
}