export const FINANCIAL_SETTINGS = {
    GET_PAYMENT_METHOD: "GET_PAYMENT_METHOD",
    ADD_PAYMENT_METHOD: "ADD_PAYMENT_METHOD",
    DELETE_PAYMENT_METHOD: "DELETE_PAYMENT_METHOD",
    UPDATE_PAYMENT_METHOD: "UPDATE_PAYMENT_METHOD",

    GET_BUSINESS_TAX: "GET_BUSINESS_TAX",
    GET_BUSINESS_TAX_SETTING: "GET_BUSINESS_TAX_SETTING",
    ADD_BUSINESS_TAX: "ADD_BUSINESS_TAX",
    UPDATE_BUSINESS_TAX: "UPDATE_BUSINESS_TAX",
    DELETE_BUSINESS_TAX: "DELETE_BUSINESS_TAX",

    // Refund Time Period 
    GET_REFUND_PERIOD: "GET_REFUND_PERIOD",
    ADD_REFUND_PERIOD: "ADD_REFUND_PERIOD",
    UPDATE_REFUND_PERIOD: "UPDATE_REFUND_PERIOD",
    DELETE_REFUND_PERIOD: "DELETE_REFUND_PERIOD",
}