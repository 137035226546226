import { getClientDropDownList, getClientDropDownListPagination } from 'Adapters/Api/clients';
import { getCustomerDetailedLoyaltyPointsLogs, getCustomerDetailedLoyaltyPointsLogsInvoiceDetail, getCustomerDetailedLoyaltyPointsLogsNoPagination, getCustomerLoyaltyPointsLogs, getCustomerLoyaltyPointsLogsNoPagination } from 'Adapters/Api/loyalityPointsLogs';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { useLocation } from 'new-components/location/helper';
import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// import { getMultiLanguageInvoice } from 'Adapters/Api/multiLanguageInvoice';
// import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';

let clientTimeOutId = undefined;

const useLoyalityLogs = () => {
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();

    const { locationDetail } = useLocation();
    const dispatch = useDispatch();
    const state = useSelector((state) => state)
    // const clientRedux = state?.client?.clients;
    const location_currency = state?.utility?.currency?.location_currency;
    const reduxEmployees = state.employee?.employees;
    const total_tax = state?.saleService?.total_tax;
    // const access_token = getDataFromLocalStorage('access_token')

    // Date for all
    const [Section, setSection] = useState('ByCustomer');
    const [loading, setLoading] = useState(true)
    const [InvoicePopup, setInvoicePopup] = useState(false);
    const [selectedInvice, setSelectedInvice] = useState();
    const [currentPage, setCurrentPage] = useState(1)
    const [detailedCurrentPage, setDetailedCurrentPage] = useState(1)
    const [startdt, setStartDt] = useState();
    const [closeDate, setcloseDate] = useState();
    const [customer, setCustomer] = useState("")
    const [detailedLog, setDetailedLog] = useState([])
    const [detailedLogNoPagination, setDetailedLogNoPagination] = useState([])
    const [detailedPages, setDetailedPages] = useState(0)
    const [detailedAllCount, setDetailedAllCount] = useState(0)
    const [detailLoading, setDetailedLoading] = useState(true)
    const [noPaginationLoader, setNoPaginationLoader] = useState(true)
    const [detailNoPaginationLoader, setDetailNoPaginationLoader] = useState(true)
    const [detailInvoiceLoading, setDetailInvoiceLoading] = useState(true)

    // Date For customer
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [customer_id, setCustomer_id] = useState("")
    const [customerLog, setCustomerLog] = useState([])
    const [customerPages, setCustomerPages] = useState(0)
    const [customerAllCount, setCustomerAllCount] = useState(0)
    const [customerLogNoPagination, setCustomerLogNoPagination] = useState([])

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const reduxInvoices = state?.multilanguageInvoices?.invoices
    const invoice_titles = state?.saleOrder?.invoice_titles;


    const [clientSearchText, setClientSearchText] = useState("");
    const [clientCurrentPage, setClientCurrentPage] = useState(1)
    const [clientLoader, setClientLoader] = useState(false)
    const clientPaginationData = state?.client?.clientPaginationData;
    const clientRedux = state?.client?.clientsRedux;


    const getCustomersLoyaltyPointsLogs = () => {
        if (locationDetail?.id) {
            setLoading(true)
            dispatch(getCustomerLoyaltyPointsLogs(
                { location_id: locationDetail?.id, client_Id: customer_id, startDate: startDate, endDate: endDate, currentPage: currentPage },
                (result) => {
                    setCustomerLog(result?.response?.loyaltycustomer)
                    setCustomerPages(result?.pages)
                    setCustomerAllCount(result?.count)
                    setLoading(false)
                    // console.log("response", result?.data)
                },
                (err) => {
                    setLoading(false)
                    // console.log("error", err?.response?.data?.response?.message)
                }
            ))
        }
    }




    //  no pagination by customer
    const getCustomersLoyaltyPointsLogsNoPagination = () => {
        if (locationDetail?.id) {
            setNoPaginationLoader(true)
            dispatch(getCustomerLoyaltyPointsLogsNoPagination(
                { location_id: locationDetail?.id, client_Id: customer_id, startDate: startDate, endDate: endDate },
                (result) => {
                    setCustomerLogNoPagination(result?.data)
                    setNoPaginationLoader(false)
                    // console.log("response", result?.data)
                },
                (err) => {
                    setNoPaginationLoader(false)
                    // console.log("error", err?.response?.data?.response?.message)
                }
            ))
        }
    }


    const getCustomersDetailedLoyaltyPointsLogs = () => {
        if (locationDetail?.id) {
            setDetailedLoading(true)
            dispatch(getCustomerDetailedLoyaltyPointsLogs(
                { location_id: locationDetail?.id, client_Id: customer, startDate: startdt, endDate: closeDate, currentPage: detailedCurrentPage },
                (result) => {
                    setDetailedLog(result?.data)
                    setDetailedPages(result?.pages)
                    setDetailedAllCount(result?.count)
                    // console.log("response", result)
                    setDetailedLoading(false)
                },
                (err) => {
                    setDetailedLoading(false)
                    // console.log("error", err?.response?.data?.response?.message)
                }
            ))
        }
    }
    // no pagination detailed
    const getCustomersDetailedLoyaltyPointsLogsNoPagination = () => {
        if (locationDetail?.id) {
            setDetailNoPaginationLoader(true)
            dispatch(getCustomerDetailedLoyaltyPointsLogsNoPagination(
                { location_id: locationDetail?.id, client_Id: customer, startDate: startdt, endDate: closeDate, currentPage: detailedCurrentPage },
                (result) => {
                    setDetailedLogNoPagination(result?.data)
                    // console.log("response", result.message)
                    setDetailNoPaginationLoader(false)
                },
                (err) => {
                    setDetailNoPaginationLoader(false)
                    // console.log("error", err?.response?.data?.response?.message)
                }
            ))
        }
    }

    const getInvoiceDetailData = (id) => {
        setInvoicePopup(!InvoicePopup);
        dispatch(getCustomerDetailedLoyaltyPointsLogsInvoiceDetail(
            id, locationDetail?.id, setDetailInvoiceLoading,
            (result) => {
                setSelectedInvice(result);
            },
            () => { }
        ));
    }

    // const toggleInviceModal = (id = "", type = "") => {
    //     if (id && type == "history") {
    //         const result = detailedLog?.find(
    //             (item) => item?.checkout_data && item?.checkout_data[0]?.id == id
    //         );
    //         calculate_totals(result?.checkout_data[0]);
    //         setSelectedInvice(result?.checkout_data[0]);
    //     }
    //     setInvoicePopup(!InvoicePopup);
    // };

    // const getInovices = () => {
    //     const success = () => { }
    //     const fail = () => { }
    //     dispatch(getMultiLanguageInvoice(access_token, success, fail))
    // }

    // useEffect(() => {
    //     if (reduxInvoices?.length === 0) {
    //         getInovices()
    //     }
    // }, [reduxInvoices?.length])

    const invoiceLabels = useMemo(() => {
        return reduxInvoices?.find(itm => itm?.location == locationDetail?.id)
    }, [reduxInvoices || locationDetail?.id])

    const calculate_totals = (item) => {
        let total_bill = 0;
        if (typeof item?.membership == "object") {
            if (item?.membership?.length > 0) {
                item?.membership?.forEach((itm) => {
                    total_bill += (Math.round(itm?.membership_price * 10) / 10) * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        if (typeof item?.voucher == "object") {
            if (item?.voucher?.length > 0) {
                item?.voucher?.forEach((itm) => {
                    total_bill += (itm?.voucher_price) * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        if (typeof item?.service == "object") {
            if (item?.service?.length > 0) {
                item?.service?.forEach((single_service) => {
                    // let this_price = single_service?.service?.priceservice?.find(price => price.currency_name == location_currency)
                    // if (this_price) {
                    // total_bill += (this_price?.price) * (single_service?.quantity ? single_service?.quantity : 1);
                    // }
                    total_bill += (single_service?.price) * (single_service?.quantity ? single_service?.quantity : 1);
                });
            }
        }


        if (typeof item?.product == "object") {
            if (item?.product?.length > 0) {
                item?.product?.forEach((itm) => {
                    total_bill += (itm?.price) * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        // if (item?.tip?.length > 0) {
        //   const selected_invoice_tip = (item?.tip?.length > 0 ? item?.tip?.reduce(function (total, obj) {
        //     return total + (obj.tip !== null && obj?.tip);
        //   }, 0) : 0)

        //   if (selected_invoice_tip) {
        //     total_bill += selected_invoice_tip
        //   }
        // }


        if (item?.order_type === "Appointment") {
            total_bill += item?.total_price;
        }
        return total_bill;
    };

    useEffect(() => {
        if (locationDetail?.id) {
            getCustomersLoyaltyPointsLogs()
            getCustomersLoyaltyPointsLogsNoPagination()
        }
    }, [locationDetail, customer_id, startDate, endDate, currentPage])

    useEffect(() => {
        setCurrentPage(1)
        setLoading(true)
    }, [locationDetail, customer_id, startDate, endDate])

    useEffect(() => {
        setDetailedCurrentPage(1)
        setDetailedLoading(true)
    }, [locationDetail, customer, startdt, closeDate])

    useEffect(() => {
        if (locationDetail?.id) {
            getCustomersDetailedLoyaltyPointsLogs()
            getCustomersDetailedLoyaltyPointsLogsNoPagination()
        }
    }, [locationDetail, customer, startdt, closeDate, detailedCurrentPage])

    // useEffect(() => {
    //     dispatch(getClientDropDownList());
    // }, [])

    const getClients = (clientCurrentPage, clientSearchText) => {
        dispatch(getClientDropDownListPagination(clientCurrentPage, clientSearchText, setClientLoader));
    }

    useEffect(() => {
        if (clientSearchText === null) {
            getClients(clientCurrentPage, "")
        } else {
            if (clientTimeOutId) {
                clearTimeout(clientTimeOutId)
            }
            clientTimeOutId = setTimeout(() => {
                getClients(clientCurrentPage, clientSearchText)
            }, 500);
        }
    }, [clientSearchText, clientCurrentPage])


    return {
        Section, setSection, startDate, setStartDate, endDate, setEndDate, startdt, setStartDt, closeDate,
        setcloseDate, customer_id, setCustomer_id, customer, setCustomer, invoiceLabels, clientRedux, translation,
        selectedLanguage, customerLog, detailedLog, loading, InvoicePopup, setInvoicePopup, selectedInvice,
        location_currency, calculate_totals, reduxEmployees, total_tax, detailedLogNoPagination,
        customerLogNoPagination, currentPage, setCurrentPage, customerPages, detailedCurrentPage, setDetailedCurrentPage,
        detailedPages, customerAllCount, detailedAllCount, invoice_titles, detailLoading, noPaginationLoader, detailNoPaginationLoader,
        clientSearchText, setClientSearchText, clientCurrentPage, setClientCurrentPage, clientLoader, clientPaginationData,
        detailInvoiceLoading, getInvoiceDetailData, setSelectedInvice
    }
}

export default useLoyalityLogs