import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { deleteStaffTarget, getStaffTarget, getStaffTargetWithNoPagination } from "Adapters/Api/staffTarget";
import { add_StaffTarget, delete_StaffTarget, get_StaffTarget, get_StaffTarget_No_Pagination } from "Redux/TenantRedux/Actions/staffTarget";
import { getEmployeeList } from "Adapters/Api/employee";

let timeOutId = undefined
export const useStaffTarget = () => {
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openStaffTarget, setOpenStaffTarget] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [searchMonth, setSearchMonth] = useState('');
  const [dataList, setDataList] = useState([]);
  const navigate = useNavigate();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const [searchText, setSearchText] = useState("");
  const [searchYear, setSearchYear] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true)
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);

  const dispatch = useDispatch();
  const PageSize = 10
  const state = useSelector((state) => state);
  const selected_location = state?.locations?.selected_location;
  const staffTargetList = state?.staffTarget?.staffTargets;
  const pages = state?.staffTarget?.pages;
  const allCount = state?.staffTarget?.allCount;
  const allStaffTargetList = state?.staffTarget?.allStaffTargets
  const staffTarget_updated = state?.staffTarget?.staffTargets_updated;
  const currency = state?.business?.business?.currency?.code;
  const locations = state?.locations?.locations;
  const location = locations?.find((obj) => obj?.id == selected_location);
  const currencyOfSelectedLocation = location?.currency?.code;
  const fetchStaffTargetList = async (selected_location, currentPage, searchText, searchMonth, searchYear) => {
    setLoader(true)
    const response = await getStaffTarget(selected_location, currentPage, searchText, searchMonth, searchYear, access_token);
    if (response.status === 200) {
      let staffTargetList = response?.data;
      dispatch(get_StaffTarget(staffTargetList))
      setLoader(false)
    } else {
      setLoader(false)
    }
  }
  const fetchStaffTargetListNoPagination = async (selected_location, access_token) => {
    const response = await getStaffTargetWithNoPagination(selected_location, access_token);
    if (response.status === 200) {
      let staffTargetList = response?.data;
      dispatch(get_StaffTarget_No_Pagination(staffTargetList))
    }
  }

  useEffect(() => {
    // fetchStaffTargetList()
    fetchStaffTargetListNoPagination(selected_location, access_token)
  }, [])

  useEffect(() => {
    if (searchText === null) {
      // Initial load with empty searchText
      fetchStaffTargetList(selected_location, currentPage, "", searchMonth, searchYear)
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        fetchStaffTargetList(selected_location, currentPage, searchText, searchMonth, searchYear)
      }, 500);
    }
  }, [currentPage, searchText, selected_location, searchMonth, searchYear])

  useEffect(() => {
    setCurrentPage(1)
  }, [searchText, selected_location, searchMonth, searchYear])

  useEffect(() => {
    // if (staffTargetList?.length === 0) {
    //   fetchStaffTargetList();
    // } else {
    // const val = staffTargetList?.map((element) => {
    //   const data = element?.employee?.location?.filter(
    //     ({ id }) => id === selected_location
    //   );
    //   if (data?.length > 0 && data !== undefined) return { ...element };
    // });
    // const productArr = val.filter((element) => element !== undefined);
    // setDataList(productArr);
    // }
    setDataList(staffTargetList);
  }, [selected_location, staffTargetList, staffTargetList?.length]);

  const handleDelete = async (id) => {
    setDeleteLoading(true);
    const response = await deleteStaffTarget(id, access_token);
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      dispatch(delete_StaffTarget(id));
      setDeleteLoading(false);
      if (!isToastVisible) {
        toast.success(successMessage, {
          onClose: () => setIsToastVisible(false),
        });
        setIsToastVisible(true);
      }
      setOpenDeletePopup(false);
      setCurrentPage(1)
      fetchStaffTargetListNoPagination(selected_location, access_token)
      fetchStaffTargetList(selected_location, 1, searchText, searchMonth, searchYear, access_token)
    } else {
      setDeleteLoading(false);
      if (!isToastVisible) {
        toast.success('Error in delete staff target', {
          onClose: () => setIsToastVisible(false),
        });
        setIsToastVisible(true);
      }
    }
  }

  const handleDeleteStaffId = (id) => {
    setOpenDeletePopup(true);
    setDeleteId(id)
  }

  const handleUpdateStaffId = (id) => {
    const editStaffTarget = staffTargetList.find((obj) => obj.id === id);
    setUpdateData(editStaffTarget);
    setIsUpdate(true);
    setOpenStaffTarget(true);
  }

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  const yearDropdown = useMemo(() => {
    let date = new Date();
    let today_year = date.getFullYear();
    if (today_year) {
      let years = [{ label: selectedLanguage?.name == "English" ? "All Year" : translation[823]?.value ? translation[823]?.value : "All Year", value: "" },];
      for (let i = today_year; i <= 2040; i++) {
        years.push({ ...years, label: i.toString(), value: i.toString() })
      }

      return years;
    }
  }, [])

  const staffTargetfilter = () => {
    // const staffTargetfilterData = dataList?.filter((item) => {
    //   if (searchText || searchMonth || searchYear) {
    //     let return_value = false;
    //     //check search text
    //     if (searchText) {
    //       if (
    //         item?.employee?.full_name
    //           ?.toLowerCase()
    //           .includes(searchText?.toLowerCase()) ||
    //         item?.service_target
    //           ?.toString()
    //           ?.toLowerCase()
    //           .includes(searchText?.toLowerCase()) ||
    //         item?.retail_target?.toString()?.includes(searchText?.toString())
    //       ) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }
    //     if (searchMonth) {
    //       if (
    //         item?.month
    //           ?.toString()
    //           ?.toLowerCase()
    //           .includes(searchMonth?.toLowerCase())
    //       ) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }

    //     if (searchYear) {
    //       if (
    //         item?.year?.slice(0, 4)
    //           ?.toString()
    //           ?.toLowerCase()
    //           .includes(searchYear?.toLowerCase())
    //       ) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }

    //     return return_value;
    //   }
    //   return true;
    // });
    const staffTargetfilterData = dataList
    return staffTargetfilterData;
  };

  //export csv
  let headers = [
    { label: selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id", key: "id" },
    { label: selectedLanguage?.name == "English" ? "Full Name" : translation[472]?.value ? translation[472]?.value : "Full Name", key: "fullName" },
    { label: selectedLanguage?.name == "English" ? "Service Target" : translation[817]?.value ? translation[817]?.value : "Service Target", key: "serviceTarget" },
    { label: selectedLanguage?.name == "English" ? "Retail Target" : translation[819]?.value ? translation[819]?.value : "Retail Target", key: "retailTarget" },
    { label: selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month", key: "month" },
  ];

  const csvReport = {
    filename: "Staff Target Report.csv",
    headers: headers,
    data: allStaffTargetList?.map((item) => {
      const { id, employee, service_target, retail_target, month, year } = item;

      return {
        ...item,
        id: item?.id ? id : "--------",
        fullName: item?.employee ? item.employee.full_name : "--------",
        serviceTarget: item?.employee ? `${service_target} ${currencyOfSelectedLocation}` : "--------",
        retailTarget: item?.retail_target ? `${retail_target} ${currencyOfSelectedLocation}` : "--------",
        month: item?.month || item?.year ? month + year?.slice(0, 4) : "--------",
      };
    }),
  };

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allStaffTargetList?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  return {
    staffTargetList, openStaffTarget, staffTarget_updated, openDeletePopup, deleteLoading, searchText, updateData, isUpdate,
    searchMonth, csvReport, deleteId, currency, yearDropdown, dataList, searchYear, currencyOfSelectedLocation, setSearchYear,
    handleDelete, setOpenDeletePopup, setDeleteLoading, handleDeleteStaffId, handleUpdateStaffId, setSearchText,
    setOpenStaffTarget, setIsUpdate, setSearchMonth, staffTargetfilter, navigate, currentPage, setCurrentPage, PageSize,
    allStaffTargetList, pages, allCount, fetchStaffTargetListNoPagination, fetchStaffTargetList, selected_location, loader,
    handleExportClick, exportLoader
  };
};
