import { HELP_MODULE } from "Redux/NstyleRedux/ActionTypes/HelpTypes";

const initialState = {
    help_data: [],
};

const HelpReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case HELP_MODULE.GET_HELP_DATA:
            return {
                ...state,
                help_data: payload,
            };

        default:
            return state;
    }

};

export default HelpReducer;
