/* eslint-disable array-callback-return */
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import useObjectKeys from "Hooks/useObjectKeys";
import { add_specific_brandservice_group_discount, get_client_discount_promotion, update_specific_brandservice_group_discount } from "Adapters/Api/clientDiscountPromotion";
import { getSaleServices, getServiceGroup } from "Adapters/Api/saleServices";
import { getsProducts, getsProductsDropdownList } from "Adapters/Api/Products";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import { getBrandDropdownlist, get_business_brands } from "Adapters/Api/Brand";
import { discountErrors, navigateToDiscountPromotion } from "../../helper";
import { useRef } from "react";
import { getserviceDropdownList } from "Adapters/Api/services";

const initialState = {
  specificDiscount: {
    brand: '',
    brandPercentage: '',
    service: '',
    servicePercentage: ''
  },

  brandRestriction: '',
  brandRestrictions: [],

  serviceRestriction: '',
  serviceRestrictions: [],

  promotion_name: '',
  dateRestriction: { startDate: '', endDate: '' },
  blockDate: '',
  blockDates: [],
  storeRestriction: '',
  storeRestrictions: [],
  dayRestrictions: [
    { day: 'Monday', value: '', is_deleted: 'False' },
    { day: 'Tuesday', value: '', is_deleted: 'False' },
    { day: 'Wednesday', value: '', is_deleted: 'False' },
    { day: 'Thursday', value: '', is_deleted: 'False' },
    { day: 'Friday', value: '', is_deleted: 'False' },
    { day: 'Saturday', value: '', is_deleted: 'False' },
    { day: 'Sunday', value: '', is_deleted: 'False' },
  ]
}
export const useAddSpecificBrandDiscount = () => {
  const formRef = useRef()
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { setErrors, validation, errors, onBlurHandler } = useValidations();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const dispatch = useDispatch();
  const { objectKeyConvertToArray } = useObjectKeys();
  const businessId = state?.business?.business?.id;
  const [isEdit, setIsEdit] = useState(false);
  const [toggleStore, setToggleStore] = useState(false);
  const [toggleblockdate, setToggleBlockDate] = useState(false);
  const [toggleDay, setToggleDay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(initialState);
  const locations = state?.locations?.locations;
  const params = useParams();
  const reduxProducts = state.product?.products;
  const serviceGroupRedux = state?.saleService?.servicesgroup;
  const brandRedux = state?.brand?.brands;

  const servicesRedux = state?.service?.services;
  const productRedux = state?.product?.products;

  const [serviceToggle, setServiceToggle] = useState(false)
  const [brandToggle, setBrandToggle] = useState(false)


  const choices = [{ label: 'Service', value: 'Service' }, { label: 'Product', value: 'Product' }]
  const {
    specificDiscount,
    blockDates,
    blockDate,
    storeRestrictions,
  } = discount;

  let dateRangeCheck = moment(blockDate).valueOf() >= moment(discount.dateRestriction.startDate).valueOf() && moment(blockDate).valueOf() <= moment(discount.dateRestriction.endDate).valueOf()
  let validateBlockDate = discount?.blockDates?.find(obj => obj.date === blockDate)

  const get_products = async () => {
    if (!state.product.products_updated) {
      const response = await getsProductsDropdownList();

      if (response.status === 200) {
        let productList = response?.data?.response?.products;
        dispatch(getProducts(productList));
      }
    }
  };
  useEffect(() => {
    dispatch(getserviceDropdownList());
    get_products();
    dispatch(getServiceGroup());
  }, [])

  useEffect(() => {
    if (!state.brand.brands_updated) {
      // dispatch(get_business_brands());
      dispatch(getBrandDropdownlist());

    }
  }, [state?.brand?.brands?.length])

  useEffect(() => {
    if (params.id === undefined) {
      setIsEdit(false);
    }
    if (state?.clientDiscountPromotion?.discountPromotions?.length === 0 && params.id) {
      dispatch(get_client_discount_promotion())
      setIsEdit(true);
    }
    if (params.id) {
      setIsEdit(true);
    }
  }, [params.id, state?.clientDiscountPromotion?.discountPromotions?.length]);

  useEffect(() => {
    let editData = state?.clientDiscountPromotion?.discountPromotions?.find((obj) => obj.id === params.id);
    if (editData) {
      let { brand, discount_brand, discount_service_group, servicegroup, id, date_restrictions, block_date, day_restrictions, promotion_name, excluded_products, excluded_services } = editData;
      let { start_date, end_date, business_address } = date_restrictions;

      let dayRestrictions = initialState?.dayRestrictions?.map((item) => {
        let obj = day_restrictions.find(obj => obj.day === item.day);
        if (obj?.day === item.day) {
          return {
            id: obj?.id,
            day: obj?.day,
            value: obj?.day,
            is_deleted: obj?.is_deleted
          }
        } else {
          return { ...item }
        }
      })
      day_restrictions?.length > 0 && setToggleDay(true);
      business_address?.length > 0 && setToggleStore(true);

      excluded_services?.length > 0 && setServiceToggle(true)
      excluded_products?.length > 0 && setBrandToggle(true)
      if (editData && dayRestrictions) {
        setDiscount({
          ...discount,
          promotion_name: promotion_name,
          id: id,
          specificDiscount: {
            brand: brand,
            brandPercentage: discount_brand,
            service: servicegroup,
            servicePercentage: discount_service_group
          },

          dateRestriction: { startDate: start_date, endDate: end_date },
          blockDates: block_date,
          storeRestrictions: business_address,
          dayRestrictions: dayRestrictions,

          serviceRestrictions: excluded_services.map(itm => itm.id),
          brandRestrictions: excluded_products.map(itm => itm.id),
        })
      }
    }
  }, [isEdit, state?.clientDiscountPromotion?.discountPromotions?.length])



  const handleChange = (e) => {
    const { value, name } = e.target;
    // setErrors((prev) => ({
    //     ...prev,
    //     [name]: "",
    //   }));
    if (name === 'startDate' || name === 'endDate') {
      if (name === 'startDate') {
        setDiscount((prev) => ({
          ...prev,
          dateRestriction: { ...prev.dateRestriction, endDate: "" }
        }));
      }
      setDiscount((prev) => ({
        ...prev,
        dateRestriction: { ...prev?.dateRestriction, [name]: value }
      }));
    }
    if (name === 'blockDate') {
      let validateBlock = discount?.blockDates?.find(obj => obj.date === value)

      let dateRangeCheck = moment(value).valueOf() >= moment(discount.dateRestriction.startDate).valueOf() && moment(value).valueOf() <= moment(discount.dateRestriction.endDate).valueOf()
      if (!dateRangeCheck) {
        setErrors((prev) => ({
          ...prev,
          [name]: discountErrors.dateRange,
        }));
      }

      if (dateRangeCheck) {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
      if (validateBlock) {
        setErrors((prev) => ({
          ...prev,
          [name]: discountErrors.dateAlreadySelected,
        }));
      }
      setDiscount((prev) => ({
        ...prev,
        [name]: value
      }));
    }
    else {
      setDiscount((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  }

  const filterServiceGroupDiscountField = (id, index) => {
    if (id) {  // edit 
      var filter = discount?.serviceGroupDiscount?.map((obj) => {
        if (obj === discount?.serviceGroupDiscount[index]) {
          return {
            ...obj,
            is_deleted: 'True'
          }
        }
        else {
          return { ...obj }
        }
      })
      setDiscount((prev) => ({
        ...prev,
        serviceGroupDiscount: filter,
      }))
    }
    else { // create no need of isDelete here 
      let filter = discount.serviceGroupDiscount.filter((obj) => obj !== discount.serviceGroupDiscount[index])
      setDiscount((prev) => ({
        ...prev,
        serviceGroupDiscount: filter
      }))
    }
  }
  const handleSpecificDiscount = (e) => {
    const { name, value } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    setDiscount((prev) => ({
      ...prev,
      specificDiscount: { ...specificDiscount, [name]: value },
    }))
  }
  const addBlockDates = () => {
    setDiscount((prev) => ({
      ...prev,
      blockDates: [...prev.blockDates, { date: discount.blockDate, is_deleted: 'False' }],
      blockDate: ''
    }))
  }
  const filterBlockDate = (id, index) => {
    if (id) {
      var filter = blockDates?.map((obj) => {
        if (obj === blockDates[index]) {
          return {
            ...obj,
            is_deleted: 'True'
          }
        }
        else {
          return { ...obj }
        }
      })
      setDiscount((prev) => ({
        ...prev,
        blockDates: filter,
      }))
    }
    else {
      var filter = blockDates?.filter((obj) => obj != blockDates[index])
      setDiscount((prev) => ({
        ...prev,
        blockDates: filter,
      }))
    }
  }
  const addStoreRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      storeRestrictions: [...prev.storeRestrictions, discount.storeRestriction],
      storeRestriction: ''
    }))
    setErrors((prev) => ({
      ...prev,
      storeRestriction: "",
    }));
  }
  const filterStoreRestriction = (index) => {
    var filter = storeRestrictions?.filter(obj => obj !== storeRestrictions[index])
    setDiscount((prev) => ({
      ...prev,
      storeRestrictions: filter,
    }))
  }

  const addBrandRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      brandRestrictions: [...prev.brandRestrictions, discount.brandRestriction],
      brandRestriction: ''
    }))
  }
  const addServiceRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      serviceRestrictions: [...prev.serviceRestrictions, discount.serviceRestriction],
      serviceRestriction: ''
    }))
  }

  const handleDayChecklist = (e) => {
    setDiscount((prev) => ({
      ...prev,
      dayRestrictions: discount?.dayRestrictions?.map(obj => {
        // handle checklist withoutd ids 
        // check function
        if (obj.day === e.target.name && obj.value === '' && obj.is_deleted === 'False') {
          return {
            ...obj,
            value: e.target.name
          }
        }
        // UN Check block
        if (obj.value === e.target.value && !obj.id) {
          return {
            ...obj,
            value: ''
          }
        }

        // handling update scenarios for checklist
        // un check block for checked
        if (obj.id && obj.value === e.target.value && obj.is_deleted === 'False') {
          return {
            ...obj,
            is_deleted: 'True',
            value: ''
          }
        }

        // checked block and isDeleted 
        if (obj.day === e.target.name && obj.value === '' && obj.is_deleted && obj.id) {
          return {
            ...obj,
            value: e.target.name,
            is_deleted: 'False'
          }
        }
        else {
          return obj
        }
      })
    }))
  }


  const locationDropdown = useMemo(() => {
    return locations?.map((obj) => {
      if (obj) {
        return {
          label: obj?.address_name,
          value: obj?.id
        }
      }
    })
  }, [locations])

  const productDropdown = useMemo(() => {
    return reduxProducts?.map((obj) => {
      if (obj) {
        return {
          label: obj?.name,
          value: obj?.id
        }
      }
    })
  }, [reduxProducts])

  const serviceGroupDropdown = useMemo(() => {
    return serviceGroupRedux?.map((obj) => {
      if (obj) {
        return {
          label: obj?.name,
          value: obj?.id
        }
      }
    })
  }, [serviceGroupRedux])

  const brandDropdown = useMemo(() => {
    return brandRedux?.map((obj) => {
      return {
        label: obj.name,
        value: obj.id
      }
    })
  }, [brandRedux])

  const serviceExcludedDropdown = useMemo(() => {
    let newArr = []
    console.log(discount, "dis")

    newArr = servicesRedux?.filter(item => item?.service_group?.find(tm => tm.id === discount?.specificDiscount?.service))

    return newArr?.map((obj) => {
      if (obj)
        return {
          label: obj.name,
          value: obj.id
        }
    })
  }, [discount.specificDiscount])

  const productExcludedDropdown = useMemo(() => {
    let newArr = []
    newArr = productRedux?.filter(item => item?.brand?.id === discount?.specificDiscount?.brand)

    return newArr?.map((obj) => {
      return {
        label: obj.name,
        value: obj.id
      }
    })
  }, [discount.specificDiscount])



  let success = () => {
    setLoading(false);
    setDiscount(initialState);
    navigateToDiscountPromotion(navigate)
    setToggleDay(false);
    setToggleStore(false);
    setToggleBlockDate(false)
  }
  let fail = () => {
    setLoading(false);
  }

  const onSubmit = () => {

    let payload = {
      business: businessId,
      promotion_name: discount.promotion_name,
      service_group: discount.specificDiscount.service,
      brand: discount.specificDiscount.brand,
      discount_service_group: discount.specificDiscount.servicePercentage,
      discount_brand: discount.specificDiscount.brandPercentage,
      services: discount.serviceRestrictions,
      // product: discount.brandRestrictions,
      product: discount.brandRestrictions,
      start_date: discount.dateRestriction.startDate,
      end_date: discount.dateRestriction.endDate,
      blockdate: discount?.blockDates,
      location: discount.storeRestrictions
    }

    const requiredFields = {
      promotion_name: discount.promotion_name,
      startDate: discount.dateRestriction.startDate,
      endDate: discount.dateRestriction.endDate,
      brand: discount.specificDiscount.brand,
      brandPercentage: discount.specificDiscount.brandPercentage,
      type: discount.specificDiscount.service,
      servicePercentage: discount.specificDiscount.servicePercentage,
      storeRestriction: discount?.storeRestrictions?.length > 0 ? true : false
    }
    setErrors(validation(requiredFields));
    let result = validation(requiredFields);

    if (objectKeyConvertToArray(result)?.length === 0) {
      setLoading(true);
      if (isEdit) {
        if (payload) {
          payload.id = discount.id;
          payload.dayrestrictions = discount?.dayRestrictions?.filter(obj => obj.value || obj.is_deleted === "True");
          dispatch(update_specific_brandservice_group_discount(payload, access_token, success, fail))
        }
      } else {
        if (payload) {
          payload.dayrestrictions = discount?.dayRestrictions?.filter(obj => obj.value)?.map(obj => { return { day: obj.value } });
          dispatch(add_specific_brandservice_group_discount(payload, access_token, success, fail))
        }
      }
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    setDiscount({
      ...discount,
      serviceRestrictions: []
    })
  }, [discount?.specificDiscount?.service])

  useEffect(() => {
    setDiscount({
      ...discount,
      brandRestrictions: []
    })
  }, [discount?.specificDiscount?.brand])


  return {
    navigate, setErrors, validation, onBlurHandler,

    serviceToggle, setServiceToggle,
    brandToggle, setBrandToggle,

    productExcludedDropdown,
    serviceExcludedDropdown,
    addBrandRestriction,
    addServiceRestriction,

    handleChange, setDiscount, filterServiceGroupDiscountField, handleDayChecklist, handleSpecificDiscount,
    addBlockDates, filterBlockDate, addStoreRestriction, filterStoreRestriction, setToggleDay, setToggleStore, onSubmit, setLoading,
    errors, discount, toggleDay, toggleStore, loading, locationDropdown, choices, dateRangeCheck, validateBlockDate, formRef,
    isEdit, serviceGroupDropdown, productDropdown, brandDropdown, toggleblockdate, setToggleBlockDate, translation, selectedLanguage,
  };
};
