
import { SERVICE_TARGET } from './../../ActionTypes/serviceTargetTypes/index';
const initialState = {
    service_targets: [],
    all_service_targets: [],
    pages: 0,
    allCOunt: 0,
    service_target_updated: false,
    // staff target report kpi
    serviceTargetReports: [],
    serviceTargetReportsPages: 0,
    serviceTargetReportsAllCount: 0,
    allServiceTargetReports: [],
    serviceTargetReports_updated: false,
};


const ServiceTargetReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SERVICE_TARGET.GET_SERVICE_TARGET_REPORT:
            return {
                ...state,
                serviceTargetReports: payload?.response?.servicegroups,
                serviceTargetReports_updated: true,
                serviceTargetReportsPages: payload?.pages,
                serviceTargetReportsAllCount: payload?.count
            };

        case SERVICE_TARGET.GET_SERVICE_TARGET_REPORT_WITHOUT_PAGINATION:
            return {
                ...state,
                allServiceTargetReports: payload?.response?.servicegroups,
                serviceTargetReports_updated: true,
            };

        case SERVICE_TARGET.GET_SERVICE_TARGET:
            return {
                ...state,
                service_targets: payload?.response?.servicetarget,
                pages: payload?.pages,
                allCount: payload?.count,
                service_target_updated: true
            };

        case SERVICE_TARGET.GET_SERVICE_TARGET_WITHOUT_PAGINATION:
            return {
                ...state,
                all_service_targets: payload?.response?.servicetarget,
                service_target_updated: true
            };


        case SERVICE_TARGET.CREATE_SERVICE_TARGET:
            return {
                ...state,
                service_targets: [payload, ...state.service_targets]
            };


        case SERVICE_TARGET.DELETE_SERVICE_TARGET:
            let result = state?.service_targets?.filter((item) => item.id !== payload);
            return {
                ...state,
                service_targets: [...result],
            };


        case SERVICE_TARGET.UPDATE_SERVICE_TARGET:
            let updateList = state?.service_targets?.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })

            return {
                ...state,
                service_targets: [...updateList],
            };

        default:
            return state;
    }
};
export default ServiceTargetReducer;
