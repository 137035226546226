import React from 'react'

const DiscountPromotionShimmer = () => {
    return (
        <>
            <div className={`rounded-lg px-4 bg-gray-100 border animate-pulse py-6 flex flex-col gap-4`}>
                <div className="flex items-center justify-between gap-3 flex-wrap">
                    <div className="h-10 w-1/5 my-3 bg-gray-300 rounded-full" />
                    <div className="w-1/2 my-3 gap-2 flex justify-end" >
                        <div className="h-7 w-1/3 bg-gray-200 rounded-lg" />
                        <div className="h-7 w-1/3 bg-gray-200 rounded-lg" />
                    </div>
                    <div className="h-7 w-2/5 bg-gray-200 rounded-lg" />

                </div>
                <div className="flex items-center gap-4 justify-between flex-wrap w-full">
                    <div className="h-7 w-4/5 bg-gray-200 rounded-lg" />
                </div>
            </div>
        </>
    )
}

export default DiscountPromotionShimmer