import { PRODUCT } from "../../ActionTypes/productTypes";
import { SALE_SERVICES } from "../../ActionTypes/saleServiceType";

const initialState = {
    servicesgroup: [],
    allServicesgroup: [],
    servicesgroup_updated: false,
    allCountServiceGroup: 0,
    pagesServiceGroup: 0,
    allServiceGroup: [],
    saleServices: [],
    allSaleServices: [],
    saleServices_updated: false,
    selection: {
        ids: '',
        selection_type: undefined
    },
    location_tax: {},
    page_count: 1,
    total_tax: 0,
    profileSelectedLocation: null,
    profileSelectedService: null,
    servicesGroupDropdown: [],
    servicesGroupDropdownWithoutSearch: [],
    servicesGroupDropdownPagination: {},
};

const SaleServicesReducer = (state = initialState, action) => {

    const { type, payload } = action;
    switch (type) {

        case SALE_SERVICES.GET_SALE_SERVICES:
            return {
                ...state,
                saleServices: payload,
                saleServices_updated: true
            };

        case SALE_SERVICES.GET_SALE_SERVICES_WITHOUT_PAGINATION:
            return {
                ...state,
                allSaleServices: payload,
                saleServices_updated: true
            };

        case SALE_SERVICES.GET_PAGE_COUNT:
            return {
                ...state,
                page_count: payload?.pages,
                allCount: payload?.count
            };

        case SALE_SERVICES.DEL_GROUP_SERVICES:
            let results = state?.servicesgroup?.filter((item) => item.id !== payload?.id);
            return {
                ...state,
                servicesgroup: [...results],
            };

        case SALE_SERVICES.GET_GROUP_SERVICES:
            return {
                ...state,
                servicesgroup: payload?.response?.sales,
                servicesgroup_updated: true,
                pagesServiceGroup: payload?.pages,
                allCountServiceGroup: payload?.count
            };

        case SALE_SERVICES.GET_GROUP_SERVICES_PAGINATION_DROPDOWN:
            const uniqueServicesGroupWithSearch = Array.from(
                new Set([...state?.servicesGroupDropdown, ...payload?.response?.sales].map(serviceGrp => serviceGrp.id))).map(id => {
                    return [...state?.servicesGroupDropdown, ...payload?.response?.sales].find(serviceGrp => serviceGrp.id === id);
                });

            const uniqueServicesGroupWithoutSearch = Array.from(
                new Set([...state?.servicesGroupDropdownWithoutSearch, ...payload?.response?.sales]?.map(serviceGrp => serviceGrp.id))).map(id => {
                    return [...state?.servicesGroupDropdownWithoutSearch, ...payload?.response?.sales]?.find(serviceGrp => serviceGrp.id === id);
                });

            return {
                ...state,
                servicesGroupDropdown: payload?.is_searched
                    ? (Math.ceil(+payload?.pages) > 1 && +payload?.current_page > 1)
                        ? uniqueServicesGroupWithSearch
                        : payload?.response?.sales
                    : payload?.current_page == 1
                        ? payload?.response?.sales
                        : uniqueServicesGroupWithoutSearch,

                servicesGroupDropdownWithoutSearch: payload?.current_page == 1
                    ? payload?.response?.sales
                    : uniqueServicesGroupWithoutSearch,

                servicesGroupDropdownPagination: {
                    total: action.payload?.count,
                    total_pages: Math.ceil(+action.payload?.pages),
                    currentPage: +action.payload?.current_page,
                    isSearched: action.payload?.is_searched
                }
            };

        case SALE_SERVICES.GET_GROUP_SERVICES_WITHOUT_PAGINATION:
            return {
                ...state,
                allServicesgroup: payload?.response?.sales,
                servicesgroup_updated: true,
            };


        case SALE_SERVICES.ADD_GROUP_SERVICES:
            return {
                ...state,
                servicesgroup: [payload, ...state?.servicesgroup],
            };

        case SALE_SERVICES.ADD_SALE_SERVICES:
            return {
                ...state,
                saleServices: [payload, ...state.saleServices],
            };

        case SALE_SERVICES.DELETE_SALE_SERVICES:
            let result = state?.saleServices?.filter((item) => item.id !== payload?.id);
            return {
                ...state,
                saleServices: [...result],
            };




        case SALE_SERVICES.UPDATE_GROUP_SERVICES:
            let updatedServicesgroup = state?.servicesgroup?.map((item) => {
                if (item?.id === payload?.id) {
                    return payload
                } else {
                    return item;
                }
            })
            return {
                ...state,
                servicesgroup: updatedServicesgroup,
            };



        case SALE_SERVICES.UPDATE_SALE_SERVICES:
            let updatedServices = state.saleServices.map((item) => {
                if (item.id === payload.id) {
                    return payload
                } else {
                    return item;
                }
            })
            return {
                ...state,
                saleServices: [...updatedServices],
            };


        case PRODUCT.STORE_SELECTED_IDS_INTO_REDUX:

            return {
                ...state,
                selection: payload
            };

        case SALE_SERVICES.GET_LOCATION_TAX:
            return {
                ...state,
                location_tax: payload,
            };

        case SALE_SERVICES.ADD_TAXES:
            return {
                ...state,
                total_tax: payload,
            };

        case SALE_SERVICES.PROFILE_SELECTED_SERIVCE:
            return {
                ...state,
                profileSelectedService: payload,
            };


        case SALE_SERVICES.PROFILE_SELECTED_LOCATION:
            return {
                ...state,
                profileSelectedLocation: payload,
            };


        default:
            return state;
    }
};

export default SaleServicesReducer;