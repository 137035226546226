import { useState } from 'react'
import { updateBookingsSettings } from '../../../../../../Adapters/Api/bookingSettings'
import useSetDataInLocalStorage from '../../../../../../Hooks/useSetDataInLocalStorage'
import { toast } from 'react-toastify';

function useBoookingSettings() {
    const [isDisabled, setIsDisabled] = useState(true)
    const [BookingSettings, setBookingSettings] = useState({
        cancel_or_reschedule: "",
        client_can_book: "",
        controls_time_slot: "",
        time_slots_interval: "",
        allow_client_to_select_team_member: false,
        send_to_client: false,
        send_to_specific_email_address: false,
        auto_confirmation: false,
        admin_confirmation: false,
        start_time: false,
        services: false,
        duration: false,
        choose_team_member: false,
        select_payment_type: false,
        initial_deposit: false,
    });
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const businessData = getDataFromLocalStorage("business_data");
    const access_token = getDataFromLocalStorage("access_token");

    //this is handler function for checkbox 
    const onHandleChange = (e, name = "") => {
        setBookingSettings((prev) => ({
            ...prev,
            [name]: e.target.checked
        }))
        setIsDisabled(false)
    }
    //this is handler function for checkbox 
    const onDropDownHandleChange = (e) => {
        const { name, value } = e.target
        setBookingSettings((prev) => ({
            ...prev,
            [name]: value,
        }))
        setIsDisabled(false)
    }

    // update bookings settings 
    const onSaveBoookingSettingsChanges = async () => {

        const formatDataForBack = {
            ...BookingSettings,
            business: businessData.id
        }

        const response = await updateBookingsSettings(formatDataForBack, access_token);

        if (response.status === 200) {
            let successMessage = response?.data?.response?.message
            toast.success(successMessage, { toastId: "toast" })
            setIsDisabled(true)
        } else {
            toast.error("Invalid Data !", { toastId: "toast" })
        }
    }

    return {
        BookingSettings,
        setBookingSettings,
        onSaveBoookingSettingsChanges,
        onHandleChange,
        onDropDownHandleChange,
        isDisabled
    }
}

export default useBoookingSettings
