import React from "react";

const TotalCount = ({ count, bg, textColor }) => {
  return (
    <div
      style={{
        backgroundColor: bg ? bg : "#E4EAF0",
        color: textColor ? textColor : "#101928",
      }}
      className="text-sm h-[1.5rem] w-[1.5rem] rounded-full flex items-center justify-center"
    >
      {count ? count : 0}
    </div>
  );
};

export default TotalCount;
