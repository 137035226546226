import Svgs from 'Assets/svgs';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import LocationSelection from 'new-components/location';
import React, { useEffect } from 'react'
import { useDiscountPromotionsReports } from './helper';
import moment from "moment";
import { CSVLink } from 'react-csv';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import { useState } from 'react';
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm';
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb';
import Pagination from 'new-components/Pagination';
import IconButton from 'Components/TenantApp/Elements/IconButton';
import NoSalesFound from '../../Sales/Elements/NoSalesFound';
import SelectedInvoiceModal from '../../Sales/sale-history/SelectedInvoiceModal';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import TableShimer from 'Components/Utility/Shimmers/TableShimer';
import Shimmer from 'Components/Utility/Shimmers/Shimmer';
import EmployeesHeadShimmer from 'Components/Utility/Shimmers/EmployeesHeadShimmer';

// import { toast } from 'react-toastify';
// import { pdfFromReactCustom } from '../../Sales/sale-history/PDFDownloader';
// import { useReactToPrint } from 'react-to-print';
// import { useRef } from 'react';


const DiscountsPromotionsReports = () => {

    const {
        search_text, onChange, InvoicePopup, commissionAndReports, selectedInvice, loading, exportPromotionDiscountReport, startDate,
        setStartDate, getInvoiceDetailData, endDate, setEndDate, location_currency, calculate_totals, currentPage, pages, setCurrentPage,
        invoice_titles, translation, selectedLanguage, commissionAndReportsWithNoPagination, handleExportClick, exportLoader,
        setInvoicePopup, setSelectedInvice, detailLoading, loadingPermission
        // selectedInvoiceTip,reduxEmployees,invoiceLabels,total_tax,navigate
    } = useDiscountPromotionsReports()

    // const ref = useRef()
    // const handlePrint = useReactToPrint({
    //     content: () => ref.current,
    //     documentTitle: "Invoice",
    //     onAfterPrint: () => { },
    // });
    const [employee, setEmployee] = useState();
    const [toggleDateRange, setToggleDateRange] = useState(false)
    const PageSize = 10

    return (
        <>
            <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right relative">

                <Breadcrumb
                    first={selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
                    url={'/dashboard/reports/'}
                    second={selectedLanguage?.name == "English" ? "Discounts & Promotions Reports" : translation[401]?.value ? translation[401]?.value : "Discounts & Promotions Reports"}
                />

                <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

                    <div className="flex items-center gap-4">
                        <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
                            {selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
                        </h2>
                        <LocationSelection />
                    </div>
                </div>

                <div className="">
                    <div className="border rounded-lg">
                        <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
                            <div className="flex items-center lg:gap-4 gap-3 self-start">
                                <div className="flex items-center sm:gap-4 gap-2 ">
                                    <h2 className="font-semibold text-xl sm:whitespace-nowrap">
                                        {selectedLanguage?.name == "English" ? "Discounts & Promotions Reports" : translation[401]?.value ? translation[401]?.value : "Discounts & Promotions Reports"}
                                    </h2>

                                    {loading ?
                                        <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                                            <p className="rounded-md h-5 w-12 mx-1"> </p>
                                        </div>
                                        : <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                                            <p className="text-primary">
                                                {commissionAndReports?.length ? commissionAndReports?.length : 0}{" "}
                                                {commissionAndReports?.length && commissionAndReports?.length > 1 ?
                                                    selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                                                    : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                                                }
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                            {loadingPermission ?
                                <EmployeesHeadShimmer classOn2={'!w-32 lg:!w-44 sm:!w-40'} classOn3={'!w-12 lg:!16'} has3Itm classOn1={'w-44 lg:w-48'} />
                                :
                                <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
                                    <div className="mb-00 flex-1">
                                        <LoginInput
                                            title=""
                                            name={"search_text"}
                                            placeholder={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                                            value={search_text}
                                            onChange={onChange}
                                            padding="py-1"
                                            leftIcon={<Svgs.Search />}
                                        />
                                    </div>

                                    {/* <DateRangePicker
                                    value={[startDate, endDate]}
                                    clearIcon={
                                        <p
                                            onClick={() => {
                                                if (startDate && endDate) setStartDate();
                                                setEndDate();
                                            }}
                                        >
                                            {" "}
                                            {selectedLanguage?.name == "English" ? "clear" : translation[340]?.value ? translation[340]?.value : "clear"}{" "}
                                        </p>
                                    }
                                    onChange={(e) => {
                                        if (e && e.length > 0) {
                                            const from = moment(e[0]).format("YYYY-MM-DD");
                                            const to = moment(e[1]).format("YYYY-MM-DD");
                                            setStartDate(from);
                                            setEndDate(to);
                                        }
                                    }}
                                /> */}
                                    <div className='flex items-center gap-3'>
                                        <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                                            <div className='sm:p-[0.45rem] p-2 relative gap-4 flex items-center'>
                                                <p onClick={() => {
                                                    setToggleDateRange(!toggleDateRange)
                                                }} className='cursor-pointer'>
                                                    {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/----"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/----"}`}
                                                </p>
                                                <span className='cursor-pointer' onClick={() => {
                                                    setStartDate()
                                                    setEndDate()
                                                }}>Clear</span>

                                                {
                                                    toggleDateRange && <>
                                                        <div className='fixed inset-0 z-1' onClick={() => { setToggleDateRange(false) }}></div>
                                                        <div className='absolute top-full right-0 z-[2]'>
                                                            <DateRangePicker
                                                                start={startDate}
                                                                end={endDate}
                                                                setStartDate={setStartDate}
                                                                setEndDate={setEndDate}
                                                                setToggleDateRange={setToggleDateRange}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {exportLoader ? (
                                        <div className="h-[4rem] flex items-center justify-center">
                                            <PreloaderSm />
                                        </div>
                                    ) :
                                        !employee ? (
                                            commissionAndReportsWithNoPagination?.length > 0 ?
                                                <CSVLink
                                                    {...exportPromotionDiscountReport}
                                                    target="_blank"
                                                    onClick={handleExportClick}
                                                >
                                                    <IconButton filled>
                                                        <Svgs.Export />
                                                    </IconButton>
                                                </CSVLink> :
                                                <LoginBtn
                                                    className="rounded-lg border-2 border-primary"
                                                    bg="bg-white"
                                                    animation={false}
                                                    onClick={handleExportClick}
                                                >
                                                    <div className="flex items-center gap-2 text-[#FFFFFF]">
                                                        <Svgs.Export bg="#FFFFFF" />
                                                    </div>
                                                </LoginBtn>
                                        ) : (
                                            ""
                                        )}
                                </div>

                            }
                        </div>
                        {loading ?
                            <TableShimer cols={6} colsHeight={7} />
                            :
                            <div className="overflow-x-auto">
                                <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                                    <p>{selectedLanguage?.name == "English" ? "Invoice ID" : translation[411]?.value ? translation[411]?.value : "Invoice ID"} #</p>
                                    <p>{selectedLanguage?.name == "English" ? "Discount Name" : translation[416]?.value ? translation[416]?.value : "Discount Name"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "Original Price" : translation[418]?.value ? translation[418]?.value : "Original Price"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "Discounted Price" : translation[419]?.value ? translation[419]?.value : "Discounted Price"}</p>
                                    <p>{selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}</p>
                                    {/* <p>Team member</p> */}
                                    {/* <p>Tips</p>
                                <p>Total</p> */}
                                </div>
                                <div
                                    className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
                                >
                                    {commissionAndReports.length > 0 ? (
                                        commissionAndReports?.map((item, index) => {
                                            let paymentDate;
                                            paymentDate = moment(item?.created_at || item?.end_date).format('DD-MM-YYYY')
                                            // let total_amount = calculateTotalAmount(item);
                                            // if (index == 0) {
                                            //   console.log('item?.tip : ', item?.tip)
                                            //   console.log('item?.gst_price : ', item?.gst_price)
                                            //   console.log('total_amount : ', total_amount)
                                            // }

                                            return (
                                                <div key={index} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(115px,1fr))] min-w-full w-fit">
                                                    <div className={`before-left-arrow cursor-pointer`}>
                                                        <div className="h-full flex items-center">
                                                            <p
                                                                className="text-sm text-primary line-clamp-1"
                                                                onClick={() => {
                                                                    getInvoiceDetailData(item?.id)
                                                                    // toggleInviceModal(item?.id, "history")
                                                                }}
                                                            >
                                                                #{item?.invoice ? item?.invoice?.split('-')[0] : ''}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm line-clamp-1 pr-3">
                                                            {item?.promotion_name ? item?.promotion_name : "----"}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm line-clamp-2">
                                                            {paymentDate ? paymentDate : ""}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm">
                                                            {item?.original_price ? (+item?.original_price)?.toFixed(2) : "----"}
                                                            {" "}
                                                            {location_currency}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm ">
                                                            {item?.discounted_price ? (+item?.discounted_price)?.toFixed(2) : 0} {location_currency}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <p className="text-sm ">
                                                            {item?.location ? item?.location?.address_name : 0}
                                                        </p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        {/* <p className="text-sm ">
                                                            {((item?.quantity ? item?.quantity : 1) * (total_amount ? total_amount : 0)) +
                                                                // ((total_tax * total_amount) / 100) +
                                                                (item?.gst_price ? item?.gst_price : ((total_tax * total_amount) / 100)) +
                                                                (item?.tip ? item?.tip : 0)
                                                            }
                                                            {/* {((item?.quantity ? item?.quantity : 1) * (total_amount ? total_amount : 0)) +
                              Math.round((total_tax * total_amount) / 100) +
                              Math.round(((item?.tip ? item?.tip : 0) * total_amount) / 100)
                            } ** }
                                                            {" "}
                                                            {location_currency}
                                                        </p> */}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <NoSalesFound />
                                    )}
                                </div>
                            </div>
                        }
                        {/* Pagination */}
                        {(commissionAndReports.length > 0 && !loading) &&
                            <Pagination
                                currentPage={currentPage}
                                totalCount={Math.ceil(pages)}
                                pageSize={PageSize}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        }

                    </div>
                </div>



                <SelectedInvoiceModal
                    open={InvoicePopup}
                    close={() => {
                        setInvoicePopup(false)
                    }}
                    invoice_titles={invoice_titles}
                    selectedInvice={selectedInvice}
                    selectedLanguage={selectedLanguage}
                    translation={translation}
                    calculate_totals={calculate_totals}
                    loading={detailLoading}
                />
            </div>

        </>
    )
}

export default DiscountsPromotionsReports