import React from "react";

import { Close } from "Assets/svgs";

const Popup = ({ handleClose, heading, children, sizeClass, size, normalCase }) => {
  return (
    <div
      className={`fixed inset-0 h-[100vh] w-[100vw] z-[4444] flex justify-center items-center`}
    >
      <div className="bg-[#101928]/30 absolute inset-0 z-[1]"></div>
      <div
        className={`relative overflow-hidden bg-white ${size == "md"
          ? "lg:w-[45vw] xl:w-[40vw]"
          : size == "lg"
            ? "lg:w-[65vw]"
            : size == "xl"
              ? "lg:w-[90vw]"
              : "lg:w-[50vw]"
          } ${sizeClass} w-[90vw] shadow-2xl rounded-2xl z-[2] slide-in-elliptic-top-fwd`}
      >
        <div className="sticky top-0 bg-white z-[22]">
          <div
            className="absolute top-0 right-0 bg-primary px-[1.6rem] py-[1rem] rounded-bl-2xl cursor-pointer"
            onClick={handleClose}
          >
            <Close />
          </div>
          <h1 className={`p-[1rem] font-semibold text-xl text-left pr-20 ${normalCase ? normalCase : ''}`}>
            {heading && heading}
          </h1>
          <hr />
        </div>
        <div className="p-[1.2rem] overflow-y-auto overflow-x-hidden scroll-hidden lg:max-h-[75vh] max-h-[65vh]">
          {children && children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
