import React, { useState, useEffect } from 'react'
import PlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import Layout from '../Layout'
import useLocationInfo from './helper'
import { useDispatch } from 'react-redux';
import useAdminBooking from '../helper';

const LocationInfo = ({ count, setStepCount, StepCount }) => {
    const dispatch = useDispatch()
    const { locationInfo, handleChange, show, setShow, handlePostalCodeInput, setLocationInfo } = useLocationInfo();
    const [address, setAddress] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [buttonClick, setButtonClick] = useState(false)

    const handleSelect = async (value) => {
        const result = await geocodeByAddress(value)
        setAddress(value)
        setLocationInfo((prev) => ({
            ...prev,
            address: value
        }))
        setBtnDisabled(false)
    }
    const { translation, selectedLanguage, formRef } = useAdminBooking()


    useEffect(() => {
        setAddress(locationInfo?.address)

    }, [locationInfo?.address])

    useEffect(() => {
        if (address == '' || locationInfo?.address == "") {
            setBtnDisabled(true)
        } else {
            setBtnDisabled(false)
        }
    }, [address, locationInfo?.address])

    return (
        <Layout
            title={selectedLanguage?.name == "English" ? "Setup your business profile" : translation[81]?.value ? translation[81]?.value : "Setup your business profile"}
            heading={selectedLanguage?.name == "English" ? "Where is Lahore located?" : translation[95]?.value ? translation[95]?.value : "Where is Lahore located?"}
            subHeading={selectedLanguage?.name == "English" ? "Add your business location so your clients can easily find you." : translation[94]?.value ? translation[94]?.value : "Add your business location so your clients can easily find you."}
            count={count}
            disabled={btnDisabled}
            NextClick={() => {
                setButtonClick(true)

                if (!btnDisabled) {
                    dispatch({
                        type: "STORE_CURRENT_LOCATION_DATA",
                        payload: locationInfo
                    })
                    setStepCount(StepCount + 1)
                } else {
                    formRef.current?.scrollIntoView({ behavior: "smooth" });
                }
            }}
            backClick={() => {
                dispatch({
                    type: "STORE_CURRENT_LOCATION_DATA",
                    payload: locationInfo
                })
                if (StepCount > 0) {
                    setStepCount(StepCount - 1)
                }
            }}
            btnInner={selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
        >
            <div className='lg:grid flex flex-col lg:grid-cols-2 gap-4' ref={formRef}>
                <div className='flex flex-col gap-4'>
                    <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <LoginInput
                                    {...getInputProps()}
                                    title={selectedLanguage?.name == "English" ? "Address Name" : translation[48]?.value ? translation[48]?.value : "Address Name"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Address Name" : translation[50]?.value ? translation[50]?.value : "Enter Address Name"}
                                    error={(buttonClick && btnDisabled) ? true : false}
                                />

                                {
                                    suggestions.length > 0 && <div className="autocomplete-dropdown-container border rounded-md mt-2">
                                        {loading && <div className='rounded-md p-2 text-xs text-[#555555]'>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = 'rounded-md p-2 text-xs hover:!bg-gray-100 text-[#555555]';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                }
                            </div>
                        )}
                    </PlacesAutocomplete>
                    <div className='flex flex-col gap-3'>
                        <p className='text-sm text-[#101928]'>
                            {selectedLanguage?.name == "English" ? "Business Details" : translation[1245]?.value ? translation[1245]?.value : "Business Details"}
                        </p>
                        <div className='p-3 grid lg:grid-cols-2 gap-3 border rounded-md'>
                            <InfoEle
                                title={selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
                                desc={locationInfo?.address}
                            />
                            {/* <InfoEle title={'Apt./Suite etc'} desc={
                                <div className='text-primary'>
                                    <p className='text-sm'>+Add</p>
                                </div>
                            } /> */}
                            {/* <InfoEle title={'District'} desc='Model Town Link Road' /> */}
                            {locationInfo?.postal_code ?
                                <InfoEle
                                    title={selectedLanguage?.name == "English" ? "Postcode" : translation[96]?.value ? translation[96]?.value : "Postcode"}
                                    desc={
                                        <div className='text-primary'>
                                            <p className='text-sm'>{locationInfo?.postal_code}</p>
                                        </div>
                                    } /> :
                                <div onClick={handlePostalCodeInput}>
                                    <InfoEle
                                        title={selectedLanguage?.name == "English" ? "Postcode" : translation[96]?.value ? translation[96]?.value : "Postcode"}
                                        desc={
                                            <div className='text-primary cursor-pointer'>
                                                <p className='text-sm'>+
                                                    {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                                                </p>
                                            </div>
                                        } />
                                </div>

                            }
                            <InfoEle title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"} desc={locationInfo?.city?.name} />
                            {show && <LoginInput
                                title={''}
                                name={"postal_code"}
                                onChange={handleChange}
                                value={locationInfo?.postal_code}
                                placeholder={selectedLanguage?.name == "English" ? "Enter postal code" : translation[59]?.value ? translation[59]?.value : "Enter postal code"} />}
                            {/* {/* <InfoEle title={'Region'} desc='----' />} */}

                            <InfoEle title={selectedLanguage?.name == "English" ? "Country" : translation[52]?.value ? translation[52]?.value : "Country"} desc={locationInfo?.country?.name} />
                            {/* <InfoEle title={'Directions'} desc={
                                <div className='text-primary'>
                                    <p className='text-sm'>+Add</p>
                                </div>
                            } /> */}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='h-full w-full overflow-hidden rounded-md border'>
                        <iframe className="iframe-map" src={`https://maps.google.com/maps?q=${address}&t=&z=13&ie=UTF8&iwloc=&output=embed`} width="100%" height="260px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const InfoEle = ({ title, desc }) => {
    return <>
        <div className='text-sm'>
            <h1 className='font-semibold'>{title}</h1>
            <p className='text-[#101928]/60'>{desc}</p>
        </div>
    </>
}

export default LocationInfo