

import axios from "axios";
import { ADD_BUSINESS_BRAND, DELETE_BUSINESS_BRAND, GET_BUSINESS_BRANDS, GET_BUSINESS_BRANDS_DROPDOWNLIST, GET_BUSINESS_BRANDS_DROPDOWNLIST_PAGINATION, GET_BUSINESS_BRANDS_WITHOUT_PAGINATION, UPDATE_BUSINESS_BRAND } from "../../../Redux/TenantRedux/ActionTypes/BrandTypes";
import { BASE_URL, api_base_url_tenant, create_business_brands_url, delete_business_brands_url, get_business_brands_url, get_business_for_dropdown, update_business_brands_url } from "../api_variables"



export const get_business_brands = (currentPage, search_text, setLoader, success = () => { }, fail = () => { }) => dispatch => {
    setLoader && setLoader(true)
    const current_Page = currentPage ? `?page=${currentPage}` : '';
    const search = search_text ? `&search_text=${search_text}` : ''
    let s_code;
    fetch(
        `${api_base_url_tenant}${get_business_brands_url}${current_Page}${search}`
    )
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let brand_data = result
                dispatch({
                    type: GET_BUSINESS_BRANDS,
                    payload: brand_data
                })
                setLoader && setLoader(false)
                success && success(brand_data)
            }
            else {
                setLoader && setLoader(false)
                fail && fail()
            }
        })
        .catch((err) => {
            setLoader && setLoader(false)
            fail && fail(err)
        })
}

export const getBrandDropdownlist = (setLoader) => async (dispatch) => {
    setLoader && setLoader(true)
    try {
        const result = await axios.get(`${BASE_URL}${get_business_for_dropdown}`);
        if (dispatch) {
            dispatch({
                type: GET_BUSINESS_BRANDS_DROPDOWNLIST,
                payload: result?.data?.response?.brands
            })
            setLoader && setLoader(false)
        }
        setLoader && setLoader(false)
        return result;
    } catch (error) {
        setLoader && setLoader(false)
        return error;
    }
}
export const getBrandDropdownlistPagination = (page, searchText, setLoader, setBrndLoader) => async (dispatch) => {
    setLoader && setLoader(true)
    const pageNumb = page ? `?page=${page}` : ''
    const search = searchText ? `&search_text=${searchText}` : ''
    try {
        const result = await axios.get(`${BASE_URL}${get_business_for_dropdown}${pageNumb}${search}`);
        if (dispatch) {
            dispatch({
                type: GET_BUSINESS_BRANDS_DROPDOWNLIST_PAGINATION,
                payload: result?.data
            })
            setLoader && setLoader(false)
            setBrndLoader && setBrndLoader(false)
        }
        setLoader && setLoader(false)
        setBrndLoader && setBrndLoader(false)
        return result;
    } catch (error) {
        setLoader && setLoader(false)
        setBrndLoader && setBrndLoader(false)
        return error;
    }
}

export const get_business_brands_with_no_pagination = (search_text, setNoPaginationLoader) => async (dispatch) => {
    setNoPaginationLoader && setNoPaginationLoader(true)
    const search = search_text ? `&search_text=${search_text}` : ''
    try {
        const result = await axios.get(`${api_base_url_tenant}${get_business_brands_url}?no_pagination=true${search}`);
        if (dispatch) {
            dispatch({
                type: GET_BUSINESS_BRANDS_WITHOUT_PAGINATION,
                payload: result?.data?.response?.brands
            })
            setNoPaginationLoader && setNoPaginationLoader(false)
        }
        setNoPaginationLoader && setNoPaginationLoader(false)
        return result;
    } catch (error) {
        setNoPaginationLoader && setNoPaginationLoader(false)
        return error;
    }
}

export const create_brand = (data = {}, success = () => { }, fail = () => { }) => dispatch => {
    let s_code;
    let access_token = localStorage.getItem('access_token')
    access_token = JSON.parse(access_token)

    if (!access_token) {
        alert('something went wrong')
        return
    }

    let form_data = new FormData()
    for (let dt in data) {
        if (data[dt]) {
            form_data.append(dt, data[dt])
        }
    }
    fetch(
        api_base_url_tenant + create_business_brands_url,
        {
            headers: {
                Authorization: `Token ${access_token}`
            },
            method: 'POST',
            body: form_data
        }
    )
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 201) {
                let brand_data = result.response.brand
                dispatch({
                    type: ADD_BUSINESS_BRAND,
                    payload: brand_data
                })
                success && success(brand_data)
            }
            else {
                fail && fail(result?.response?.message)
            }
        })
        .catch((err) => {
            fail && fail('Somthing went wrong')
        })
}


export const update_brand = (data = {}, success = () => { }, fail = () => { }) => dispatch => {
    let s_code;
    let access_token = localStorage.getItem('access_token')
    access_token = JSON.parse(access_token)
    if (!access_token) {
        alert('something went wrong')
        return
    }

    let new_data = { ...data }
    new_data['brand'] = data.id
    delete new_data['id']
    let form_data = new FormData()
    for (let dt in new_data) {
        if (new_data[dt]) {
            if (dt == 'image' && typeof new_data[dt] == 'string') {
                continue
            }
            form_data.append(dt, new_data[dt])
        }
    }
    fetch(
        api_base_url_tenant + update_business_brands_url,
        {
            headers: {
                Authorization: `Token ${access_token}`
            },
            method: 'PUT',
            body: form_data
        }
    )
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let brand_data = result.response.brand
                dispatch({
                    type: UPDATE_BUSINESS_BRAND,
                    payload: brand_data
                })
                success && success(brand_data)
            }
            else {
                fail && fail(result?.response?.message)
            }
        })
        .catch((err) => {
            fail && fail('Somthing went wrong')
        })
}


export const delete_brand = (data, success, fail) => dispatch => {
    let s_code;
    let access_token = localStorage.getItem('access_token')
    access_token = JSON.parse(access_token)
    if (!access_token) {
        alert('something went wrong')
        return
    }

    let form_data = new FormData()
    form_data.append('brand', data.brand_id)
    fetch(
        api_base_url_tenant + delete_business_brands_url,
        {
            headers: {
                Authorization: `Token ${access_token}`
            },
            method: 'DELETE',
            body: form_data
        }
    )
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let brand_data = result.response.brand
                dispatch({
                    type: DELETE_BUSINESS_BRAND,
                    payload: {
                        id: data.brand_id
                    }
                })
                success && success(brand_data)
            }
            else {
                fail && fail(result?.response?.message)
            }
        })
        .catch((err) => {
            fail && fail('Somthing went wrong')
        })
}