import Svgs from "Assets/svgs";
import React, { useState, useEffect, useRef } from "react";

const Input = ({
  type,
  name,
  value,
  title,
  onBlur,
  onChange,
  leftIcon,
  required,
  disabled,
  register,
  rightIcon,
  placeholder,
  errorMessage,
  specialCharacterNotAllowed,
  onlyNumericAllowed,
  onEnterSubmit,
  setValue
}) => {

  const [sanitizedValue, setSanitizedValue] = useState();
  const inputRef = useRef(null);

  useEffect(() => {
    // Sanitize the input value based on the specified type
    if (value) {
      if (type === "float") {
        const sanitized = typeof value == 'string' ? value?.replace(/[^\d.]/g, "")?.replace(/(\..*)\./g, '$1')?.replace(/^(\d+\.\d*)\..*/, '$1') : value;
        setSanitizedValue(Math.abs(sanitized));
      } else if (type === "number") {
        setSanitizedValue(typeof value == 'string' ? Math.abs(value.replace(/[^0-9]/g, "")) : value);
      } else {
        setSanitizedValue(value);
      }
    } else {
      setSanitizedValue("");
    }
  }, [value, type]);

  useEffect(() => {
    try {
      // Preserve cursor position in the input field
      if (sanitizedValue != '') {
        if (inputRef.current && inputRef.current.selectionStart !== null && inputRef.current.selectionEnd !== null) {
          const startPosition = inputRef.current.selectionStart;
          const endPosition = inputRef.current.selectionEnd;

          inputRef.current.value = sanitizedValue;

          if (startPosition !== null && endPosition !== null) {
            inputRef.current.setSelectionRange(startPosition, endPosition);
          }
        }
      }
    } catch (error) {
      // Handle the error
      // console.warn("An error occurred:", error);
    }
  }, [sanitizedValue, value]);

  useEffect(() => {
    // Use setTimeout to ensure that the blur occurs after the initial render
    const timeoutId = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }, 0);

    window.scrollTo({ top: 0 });
    return () => clearTimeout(timeoutId);

  }, []);

  const handleInputChange = (e) => {
    let currentPosition = 0;

    try {
      // Check if inputRef, inputRef.current, and selectionStart are supported
      if (inputRef?.current && 'selectionStart' in inputRef.current) {
        currentPosition = inputRef.current.selectionStart || 0;
      }
    } catch (error) {
      // Handle any potential error if accessing selectionStart fails
      console.error("Error accessing selectionStart:", error);
      // Set currentPosition to a default value, for example 0
      currentPosition = 0;
    }

    const inputValue = e.target.value || '';

    // Update the sanitizedValue state based on the input value
    if (type === "float") {
      const sanitized = inputValue?.replace(/[^\d.]/g, "")?.replace(/(\..)\./g, '$1')?.replace(/^(\d+\.\d)\..*/, '$1');
      setSanitizedValue(Math.abs(sanitized));
    } else if (type === "number") {
      setSanitizedValue(typeof value == 'string' ? Math.abs(value.replace(/[^0-9]/g, "")) : value);
    } else {
      setSanitizedValue(inputValue);
    }

    // Call the external onChange handler (if provided)
    if (typeof onChange === "function") {
      onChange(e);
    }
    if (setValue) {
      setValue(e.target.value)
    }


    // Restore the cursor position
    try {
      if (
        inputRef?.current &&
        'selectionStart' in inputRef.current &&
        'setSelectionRange' in inputRef.current
      ) {
        currentPosition = inputRef.current.selectionStart || 0;
        const maxLength = inputRef.current.value.length;

        // Ensure the currentPosition is within the bounds of the input text length
        currentPosition = Math.min(currentPosition, maxLength);

        // Set the selection range if the input has focus
        if (inputRef.current === document.activeElement) {
          inputRef.current.setSelectionRange(currentPosition, currentPosition);
        }
      }
    } catch (error) {
      // Handle any potential error if accessing or setting selection properties fails
    }
  };

  const HandleKeyPress = (e) => {
    // regular expression pattern for special characters
    const specialCharsPattern = /[0123456789=!@#$%^&*()_+{}+×÷%≤≥≈≠∞€£¥""¢''/`"'\[\]:|;<>,.?~\\-]/g;
    // Check your condition here to disallow special characters
    if (specialCharacterNotAllowed && specialCharsPattern.test(e.key)) {
      e.preventDefault(); // Prevent typing of special characters
    }

    // regular expression pattern for special characters
    const onlyNumericCharsAllowed = /[=!@#$%^&*()_+{}+×÷%≤≥≈≠∞€£¥¢/`"'\[\]:|;<>,.?~\\-]/g;
    // Check your condition here to disallow special characters
    if (onlyNumericAllowed && onlyNumericCharsAllowed.test(e.key)) {
      e.preventDefault(); // Prevent typing of special characters
    }

    if ((type === 'float' || type === 'number') && e.key === '-') {
      e.preventDefault(); // Prevent typing of '-' symbol
    }

    if (e.key.toLowerCase() == "enter") {
      onEnterSubmit();
    }
  };


  return (
    <div className="flex flex-col gap-2 relative">
      {title != "" && (
        <div className="text-sm flex items-center gap-1">
          <div>{title}</div> {required && title && <span className="leading-[1] text-red-600"> *</span>}
        </div>
      )}
      <div className="w-full relative">
        {leftIcon && (
          <div className="absolute top-1/2 -translate-y-1/2 left-3">
            {leftIcon}
          </div>
        )}
        <input
          ref={inputRef}
          disabled={disabled || false}
          style={leftIcon && { paddingLeft: "35px" }}
          step={type == 'float' && '0.1'}
          type={type == 'float' ? 'number' : type}
          placeholder={placeholder || ""}
          onKeyDown={HandleKeyPress}
          name={name || ""}
          value={sanitizedValue}
          onInput={handleInputChange}
          onChange={handleInputChange}
          onBlur={onBlur}
          // onKeyDown={(e) => {
          //   if (type === "number" && e.key === "-") {
          //     e.preventDefault(); // Prevent adding the minus sign to the input
          //   }
          // }}
          className={`border ${errorMessage && " border-l-[7px] !border-[#eb3b3b]"
            } input w-full lg:pr-12 pr-7`}
          {...(register && register(name))}
        />
        {rightIcon && (
          <div className="absolute top-1/2 -translate-y-1/2 right-3 input-right-icon">
            {rightIcon}
          </div>
        )}
      </div>
      {errorMessage && (
        <span
          className={
            "text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end"
          }
        >
          <Svgs.I fill="#eb3b3b" />
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default Input;
