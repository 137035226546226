
const initialState = {
    currency_list: [],
    location_currency: "",
};


const CurrencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CURRENCY':
            return {
                ...state,
                currency_list: action?.payload,
            }

        case 'LOCATION_CURRENCY':
            return {
                ...state,
                location_currency: action?.payload,
            }


        default:
            return state;
    }
};

export default CurrencyReducer;
