import axios from "axios";
import { toast } from "react-toastify";

import {
  client_promo,
  getClients,
  getclientsub,
  get_rewards,
  getClientsWithoutPagination,
  getClientsDropdown,
  getClientsDropdownWithPagination,
} from "Redux/TenantRedux/Actions/clientActions";
import {
  get_client_url,
  add_client_url,
  delete_client_url,
  update_client_url,
  get_single_client_url,
  BASE_URL,
  get_sub_program_url,
  client_import_url,
  set_promo_url,
  add_reward_url,
  get_reward_url,
  get_promo_url,
  delete_promotion_url,
  update_promo_url,
  delete_reward_url,
  update_reward_url,
  create_sub_program_url,
  update_Subcsription_url,
  delete_subscription_url,
  get_unique_client_id,
  get_client_complimentary,
  get_client_package,
  check_client_existance,
  get_client_for_dropdown,
  get_client_for_CRM,
  create_client_image,
} from "../api_variables";
import { CLIENT } from "Redux/TenantRedux/ActionTypes/clientActionTypes";
import { apiErrorMessage } from "Constants/Data/Errors";

export const getClient = (currentPage, search_text, setLoader, dropdownStatus) => async (dispatch) => {
  setLoader && setLoader(true)
  const page = currentPage ? `?page=${currentPage}` : ''
  const search = search_text ? `&search_text=${search_text}` : ''
  const status = dropdownStatus ? `&active=${dropdownStatus}` : ''
  try {
    const response = await axios.get(`${BASE_URL}${get_client_url}${page}${search}${status}`);
    if (response.status === 200) {
      dispatch(getClients(response?.data));
      setLoader && setLoader(false)
    } else {
      setLoader && setLoader(false)
      toast.error("Error in get Client", { toastId: "toast" });
    }
  } catch (error) {
    setLoader && setLoader(false)
    toast.error(apiErrorMessage, { toastId: "toast" });
    return error;
  }
};

// upload single image
export const uploadSingleImage = (payload, access_token, success, fail) => async (dispatch) => {
  const formData = new FormData();
  formData.append('image', payload?.image);
  formData.append('file_name', payload?.file_name);
  formData.append('file_type', payload?.file_type);
  try {
    const result = await axios.post(`${BASE_URL}${create_client_image}`, formData, {
      headers: {
        Authorization: `Token ${access_token}`,
        'content-type': 'multipart/form-data'
      },
    });
    // console.log(result, "res")
    if (result?.data?.status_code == 200) {
      const data = result?.data?.response?.data
      success && success(data)
      return;
    } else {
      console.log("fail")
      fail && fail()
    }
  } catch (error) {
    console.log("error")
    fail && fail(error?.response?.data)
    return error;
  }
}

export const getClientDropDownListPagination = (page, searchText, setLoader) => async (dispatch) => {
  const pageNumb = page ? `?page=${page}` : ''
  const search = searchText ? `&search_text=${searchText}` : ''
  setLoader && setLoader(true)
  try {
    const response = await axios.get(`${BASE_URL}${get_client_for_dropdown}${pageNumb}${search}`);
    if (response.status === 200) {
      dispatch(getClientsDropdownWithPagination(response?.data));
      setLoader && setLoader(false)
    } else {
      setLoader && setLoader(false)
      // toast.error("Error in get Client", { toastId: "toast" });
    }
  } catch (error) {
    setLoader && setLoader(false)
    toast.error(apiErrorMessage, { toastId: "toast" });
    return error;
  }
};

export const getClientDropDownList = (setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  try {
    const response = await axios.get(`${BASE_URL}${get_client_for_dropdown}`);
    if (response.status === 200) {
      dispatch(getClientsDropdown(response?.data));
      setLoader && setLoader(false)
    } else {
      setLoader && setLoader(false)
      // toast.error("Error in get Client", { toastId: "toast" });
    }
  } catch (error) {
    setLoader && setLoader(false)
    toast.error(apiErrorMessage, { toastId: "toast" });
    return error;
  }
};

export const getClientWithNoPagination = (search_text, setLoader, dropdownStatus) => async (dispatch) => {
  setLoader && setLoader(true)
  const search = search_text ? `&search_text=${search_text}` : ''
  const status = dropdownStatus ? `&active=${dropdownStatus}` : ''
  try {
    const response = await axios.get(`${BASE_URL}${get_client_url}?no_pagination=true${search}${status}`);
    if (response.status === 200) {
      dispatch(getClientsWithoutPagination(response?.data));
      setLoader && setLoader(false)
    } else {
      toast.error("Error in get Client", { toastId: "toast" });
      setLoader && setLoader(false)
    }
  } catch (error) {
    toast.error(apiErrorMessage, { toastId: "toast" });
    setLoader && setLoader(false)
    return error;
  }
};

export const getsClientsImport = async (form, success) => {
  //-------------------real token------------------------
  let token = localStorage.getItem("access_token");
  if (token) {
    token = token.replaceAll('"', "");
  }

  // let token="8e8a59a52a7c47a3ecc44e1a741f0b76cf982f10"
  fetch(
    `${BASE_URL}${client_import_url}`,
    // fetch("http://192.168.18.56:8000/api/v1/product/import_csv/",
    {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: form,
    }
  )
    .then((response) => {
      if (response.status == 200) {
        success();
        toast.success("Clients Imported Successfully", { toastId: "toast" });
        return response.json();
      } else {
        toast.error("Clients did not Imported Successfully", { toastId: "toast" });
      }
    })
    .then((result) => { });
};
export const createSubscriptionprogram =
  (data, success, fail) => (dispatch) => {
    let token = localStorage.getItem("access_token");

    if (token) {
      token = token.replaceAll('"', "");
    }
    let form_data = new FormData();

    for (let ky in data) {
      if (data[ky]) {
        form_data.append(ky, data[ky]);
      }
    }
    fetch(`${BASE_URL}${create_sub_program_url}`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: form_data,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((result) => {
        if (result?.status_code === 201) {
          success && success();
          dispatch({
            type: CLIENT.CLIENT_SUB,
            payload: result.response.subscription,
          });
        } else {
          fail && fail();
        }
      })
      .catch((err) => {
        fail && fail();
      });
  };

export const createPromotion = (data, success, fail) => (dispatch) => {
  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }
  let form_data = new FormData();

  for (let ky in data) {
    if (data[ky]) {
      form_data.append(ky, data[ky]);
    }
  }
  fetch(`${BASE_URL}${set_promo_url}`, {
    method: "POST",
    headers: {
      Authorization: `Token ${token}`,
    },
    body: form_data,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result?.status_code === 201) {
        success && success();
        dispatch({
          type: CLIENT.CREATE_PROMOTION,
          payload: result.response.promotion,
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};
export const createReward = (data, success, fail) => (dispatch) => {
  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }
  let form_data = new FormData();

  for (let ky in data) {
    if (data[ky]) {
      form_data.append(ky, data[ky]);
    }
  }
  fetch(`${BASE_URL}${add_reward_url}`, {
    method: "POST",
    headers: {
      Authorization: `Token ${token}`,
    },
    body: form_data,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result?.status_code === 201) {
        success && success();
        dispatch({
          type: CLIENT.ADD_CLIENT_REWARDS,
          payload: result.response.rewards,
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const getsubscriptionprogram = () => async (dispatch) => {
  try {
    const result = await axios.get(`${BASE_URL}${get_sub_program_url}`);
    let subscriptionList = result?.data?.response?.subscription;
    dispatch(getclientsub(subscriptionList));
    return result;
  } catch (error) {
    return error;
  }
};

export const getrewards = () => async (dispatch) => {
  try {
    const result = await axios.get(`${BASE_URL}${get_reward_url}`);
    if (result.status === 200) {
      dispatch(get_rewards(result?.data?.response?.rewards));
    } else {
      toast.error("Error in get rewards", { toastId: "toast" });
    }

    return result;
  } catch (error) {
    return error;
  }
};

export const getPromotions = () => async (dispatch) => {
  try {
    const result = await axios.get(`${BASE_URL}${get_promo_url}`);

    let promotionList = result?.data?.response?.promotion;
    dispatch(client_promo(promotionList));
    return result;
  } catch (error) {
    return error;
  }
};

export const getSingleClient = async (id) => {
  let result = await fetch(
    `${BASE_URL}${get_single_client_url}?client_id=${id}`
  );
  result = result.json();
  return result;
};

export const createClient = async (client_data, access_token) => {
  let result = await fetch(`${BASE_URL}${add_client_url}`, {
    method: "POST",
    body: client_data,
    headers: {
      Authorization: `Token ${access_token}`,
    },
  });
  result = result.json();
  return result;
};

export const checkClientExistance = (clientData, success, fail) => async dispatch => {
  let email = clientData?.email ? clientData?.email : ""
  let phone_number = clientData?.phone_number ? clientData?.phone_number : ""
  let data = {}
  if (email) {
    data = { email, phone_number }
  } else {
    data = { phone_number }
  }

  try {
    const result = await axios.post(
      `${BASE_URL}${check_client_existance}`, data
    );
    if (result?.data?.status_code == 200) {
      success && success(result)
    }
  } catch (error) {
    fail && fail(error?.response)
  }
};

export const updateClient = async (client_data, access_token) => {
  let result = await fetch(`${BASE_URL}${update_client_url}`, {
    method: "PUT",
    body: client_data,
    headers: {
      Authorization: `Token ${access_token}`,
    },
  });
  result = result.json();
  return result;
};

export const deleteClient = async (client_id, access_token) => {
  try {
    let result = await fetch(`${BASE_URL}${delete_client_url}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${access_token}`,
      },
      body: JSON.stringify({ client_id: client_id }),
    });
    result = result.json();
    return result;
  } catch (error) {
    return error;
  }
};

export const deletePromotion = (data, success, fail) => (dispatch) => {
  let s_code;

  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }

  let form_data = new FormData();
  form_data.append("promotion_id", data.id);

  fetch(BASE_URL + delete_promotion_url, {
    method: "DELETE",
    body: form_data,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 200) {
        success && success();
        dispatch({
          type: CLIENT.DELETE_PROMOTION,
          payload: {
            id: data.id,
          },
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};
export const deleteReward = (data, success, fail) => (dispatch) => {
  let s_code;

  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }

  let form_data = new FormData();
  form_data.append("rewards_id", data.id);

  fetch(BASE_URL + delete_reward_url, {
    method: "DELETE",
    body: form_data,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 200) {
        success && success();
        dispatch({
          type: CLIENT.DELETE_CLIENT_REWARDS,
          payload: {
            id: data.id,
          },
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};
export const deleteSubscription = (data, success, fail) => (dispatch) => {
  let s_code;

  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }

  let form_data = new FormData();
  form_data.append("subsciption_id", data.id);

  fetch(BASE_URL + delete_subscription_url, {
    method: "DELETE",
    body: form_data,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 200) {
        success && success();
        dispatch({
          type: CLIENT.DELETE_SUBSCRIPTION,
          payload: {
            id: data.id,
          },
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};
export const updatePromotion = (data, success, fail) => (dispatch) => {
  let s_code;

  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }
  let form_data = new FormData();

  for (let ky in data) {
    if (data[ky]) {
      form_data.append(ky, data[ky]);
    }
  }

  fetch(BASE_URL + update_promo_url, {
    method: "PUT",
    body: form_data,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 200) {
        success && success();
        dispatch({
          type: CLIENT.UPDATE_PROMOTION,
          payload: {
            id: data.id,
          },
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};
export const updateRewards = (data, success, fail) => (dispatch) => {
  let s_code;

  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }
  let form_data = new FormData();

  for (let ky in data) {
    if (data[ky]) {
      form_data.append(ky, data[ky]);
    }
  }

  fetch(BASE_URL + update_reward_url, {
    method: "PUT",
    body: form_data,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 200) {
        success && success();
        dispatch({
          type: CLIENT.UPDATE_CLIENT_REWARDS,
          payload: {
            id: data.id,
          },
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};
export const updateSubscription = (data, success, fail) => (dispatch) => {
  let s_code;

  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }
  let form_data = new FormData();

  for (let ky in data) {
    if (data[ky]) {
      form_data.append(ky, data[ky]);
    }
  }

  fetch(BASE_URL + update_Subcsription_url, {
    method: "PUT",
    body: form_data,
    headers: {
      Authorization: `Token ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result.status_code == 200) {
        success && success();
        dispatch({
          type: CLIENT.UPDATE_SUBSCRIPTION,
          payload: {
            id: data.id,
          },
        });
      } else {
        fail && fail();
      }
    })
    .catch((err) => {
      fail && fail();
    });
};

export const getUniqueClientId = async (token) => {
  let result = await fetch(`${BASE_URL}${get_unique_client_id}`, {
    headers: { Authorization: `Token ${token}` },
  });
  result = result.json();
  return result;
};


export const getClienComplimentaryVoucher = (data, success, fail) => {
  let s_code;
  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }
  fetch(
    `${BASE_URL}${get_client_complimentary}?client=${data?.client}&complimentary=${data?.complimentary}`,
    {
      headers: { Authorization: `Token ${token}` },
    }
  )
    .then(response => {
      return response.json()
    })
    .then(result => {
      success && success(result.response)
    })
    .catch(err => {
      console.log(err)
    })
}

export const getClientPackage = (data, success, fail) => {
  let s_code;
  let token = localStorage.getItem("access_token");

  if (token) {
    token = token.replaceAll('"', "");
  }
  fetch(
    `${BASE_URL}${get_client_package}?client=${data?.client}&package=${data?.package}&package_service=${data?.package_service}`,
    {
      headers: { Authorization: `Token ${token}` },
    }
  )
    .then(response => {
      return response.json()
    })
    .then(result => {
      // console.log(result)
      success && success(result)
    })
    .catch(err => {
      console.log(err)
      fail && fail()
    })
}
