import React from 'react'
import Popup from "Components/TenantApp/Elements/Popup";
// import useDetailLeadPopup from './helper';
import StatusBtn from 'Components/TenantApp/Elements/StatusBtn';

const DetailLead = ({v, ViewDetails, setViewDetails}) => {

  return (
    <Popup
        size={"md"}
        heading={"View Details"}
        open={ViewDetails}
        close={setViewDetails}
    >
        <div className="space-y-5">
        <div className="flex items-start gap-16">
            <div className="flex flex-col gap-3">
              <h5 className="text-[16px] font-semibold">Type</h5>
              <p className="text-[14px] font-normal">Dynamic</p>
            </div>
            <div className="flex flex-col gap-3">
              <h5 className="text-[16px] font-semibold">Status</h5>
              <StatusBtn
                  status={v.is_active ? "Active" : "InActive"}
              />
            </div>
        </div>
       
        </div>
    </Popup>
  )
}

export default DetailLead