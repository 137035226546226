import Svgs from 'Assets/svgs';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import FormSteps from 'Components/TenantApp/Elements/FormSteps';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import LoginInput from 'Components/TenantApp/Elements/LoginInput';
import Popup from 'Components/TenantApp/Elements/Popup';
import React, { useEffect } from 'react'
import PermissionsComponent from '../Elements/PermissionsComponent';
import useStaffGroups from './useStaffGroups';
import AddEmployeePopup from '../AddEmployee/AddEmployeePopup';
import { getEmployeeDropDownList } from 'Adapters/Api/employee';

const AddStaffGroupPopup = ({ selectedLanguage, translation, open, close, editValue, editFormOpen }) => {
    const {
        staffGroup, setStaffGroup, staffGroupHandleChange, employeePermissionHandleChange, onStaffGroupSubmit, errors, onBlurHandler, step,
        setStep, loading, filter_employess, employeeRedux, setIsEdit, setIsEditValue, showAddStaffPopup, setShowAddStaffPopup, dispatch
    } = useStaffGroups();

    useEffect(() => {
        if (editValue && editFormOpen) {
            setIsEdit(true)
            setStaffGroup(editValue)
            setIsEditValue(true)
        }
    }, [editValue])

    const {
        name,
        employees,
        is_active,
    } = staffGroup;
    return (
        <>
            <Popup
                size="sm"
                heading={(editValue && editFormOpen) ?
                    selectedLanguage?.name == "English" ? "Update Staff Group" : translation[573]?.value ? translation[573]?.value : "Update Staff Group"
                    : selectedLanguage?.name == "English" ? "Add Staff Group" : translation[573]?.value ? translation[573]?.value : "Add Staff Group"}
                close={close}
                open={open}
            >
                <div className="flex flex-col gap-6">
                    <FormSteps
                        disabledStep={3}
                        currentStep={step}
                        setStep={setStep}
                        active={step}
                        steps={[
                            selectedLanguage?.name == "English" ? "Group Details" : translation[574]?.value ? translation[574]?.value : "Group Details",
                            selectedLanguage?.name == "English" ? "Permissions" : translation[467]?.value ? translation[467]?.value : "Permissions",
                            selectedLanguage?.name == "English" ? "Finish" : translation[547]?.value ? translation[547]?.value : "Finish",
                        ]}
                        stepsLength="3"
                    />
                    {step == 1 && (
                        <>
                            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
                                <CircleIcon size='45px'>
                                    <Svgs.Staff_Group />
                                </CircleIcon>
                                <div className="flex flex-col gap-1 lg:text-left text-center">
                                    <h2 className="text-primary font-semibold text-xl">
                                        {selectedLanguage?.name == "English" ? "Staff Group" : translation[572]?.value ? translation[572]?.value : "Staff Group"}
                                    </h2>
                                    <p className="text-[#7B7B7B] text-sm">
                                        {selectedLanguage?.name == "English" ? "A staff group can contain employees with same designation/expertise" : translation[575]?.value ? translation[575]?.value : "A staff group can contain employees with same designation/expertise"}
                                    </p>
                                </div>
                            </div>
                            <LoginInput
                                required={true}
                                title={selectedLanguage?.name == "English" ? "Staff Group Name" : translation[576]?.value ? translation[576]?.value : "Staff Group Name"}
                                placeholder={selectedLanguage?.name == "English" ? "Enter Staff Group Name" : translation[577]?.value ? translation[577]?.value : "Enter Staff Group Name"}
                                name={"name"}
                                value={name}
                                // charactersMaxLength={25}
                                specialCharacterNotAllowed={true}
                                onEnterSubmit={onStaffGroupSubmit}
                                onChange={(e) => staffGroupHandleChange(e)}
                                onBlur={() => onBlurHandler({ name: name })}
                                error={errors?.name}
                            />
                            {/* <Dropwdown

                                options={filter_employess()
                                    .filter((emp) => !employees.includes(emp?.id))
                                    .map((empl) => {
                                        return { label: empl.full_name, value: empl.id };
                                    })}
                            /> */}
                            <div className="space-y-2">
                                <Dropwdown
                                    title={selectedLanguage?.name == "English" ? "Add Staff" : translation[578]?.value ? translation[578]?.value : "Add Staff"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Staff" : translation[334]?.value ? translation[334]?.value : "Select Staff"}
                                    name={"employees"}
                                    value={employees}
                                    onChange={(e) => staffGroupHandleChange(e)}
                                    options={
                                        filter_employess()
                                            .filter((emp) => !employees.includes(emp?.id))
                                            .map((empl, i) => {
                                                return {
                                                    label: empl.full_name,
                                                    value: empl.id,
                                                    otherData: {
                                                        image: empl?.image,
                                                        DataOne: empl.email,
                                                        showImage: true,
                                                        index: i
                                                    },
                                                    email: empl.email,
                                                    mobile_number: empl.mobile_number
                                                };
                                            })
                                    }
                                    filterOption={(option, inputValue) => {
                                        const { mobile_number, label, email } = option?.data
                                        const normalizedInput = inputValue ? inputValue?.toLowerCase() : '';
                                        return label?.toLowerCase()?.includes(normalizedInput)
                                            || (mobile_number && mobile_number?.includes(normalizedInput))
                                            || (email && email?.includes(normalizedInput));
                                    }}
                                    error={
                                        errors.employees
                                            ?
                                            selectedLanguage?.name == "English"
                                                ? "This field is required"
                                                : translation[287]?.value
                                                    ? translation[287]?.value
                                                    : "This field is required"
                                            : ""
                                    }
                                />

                                {(filter_employess()?.length == 0) &&
                                    <p className='text-sm'>
                                        {selectedLanguage?.name == "English" ? "No staff found." : translation[1719]?.value ? translation[1719]?.value : "No staff found."}
                                        <span className="underline mx-px text-[#5679FF] cursor-pointer" onClick={() => {
                                            setShowAddStaffPopup(!showAddStaffPopup);
                                        }}>
                                            {selectedLanguage?.name == "English" ? "Click here" : translation[14]?.value ? translation[14]?.value : "Click here"}
                                        </span>
                                        {selectedLanguage?.name == "English" ? " to add." : translation[140]?.value ? translation[140]?.value : " to add."}


                                    </p>
                                }
                            </div>
                            {staffGroup?.employees?.length > 0 && (
                                <div className="flex items-center gap-3 flex-wrap">
                                    {staffGroup?.employees &&
                                        staffGroup?.employees?.map((item) => {
                                            let selected_employee = employeeRedux?.find(
                                                (empl) => empl.id == item
                                            );
                                            return (
                                                <div className="px-3 py-2 rounded-full flex items-center gap-3 border-[2px] border-primary w-max text-primary cursor-pointer text-sm">
                                                    {selected_employee?.full_name}
                                                    <span
                                                        onClick={() => {
                                                            setStaffGroup({
                                                                ...staffGroup,
                                                                employees: [
                                                                    ...staffGroup.employees.filter(
                                                                        (emp_id) =>
                                                                            emp_id != selected_employee?.id
                                                                    ),
                                                                ],
                                                            });
                                                        }}
                                                    >
                                                        <Svgs.CirlceClose fill="#5679FF" />
                                                    </span>
                                                </div>
                                            );
                                        })}
                                </div>
                            )}

                            <Dropwdown
                                required={true}
                                name={"is_active"}
                                value={is_active}
                                onChange={(e) => staffGroupHandleChange(e)}
                                // onBlur={() => onBlurHandler({ is_active: is_active })}
                                error={errors.is_active}
                                title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[483]?.value : "Select Status"}
                                options={[
                                    {
                                        value: true,
                                        label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                                    },
                                    {
                                        value: false,
                                        label: selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                                    },
                                ]}
                            />
                            <div className="flex items-center justify-start mt-[2rem]">
                                <LoginBtn
                                    onClick={() => {
                                        document.querySelector('.topscroll').scrollIntoView();
                                        onStaffGroupSubmit();
                                        // setStep(2);
                                    }}
                                    disabled={employeeRedux?.length == 0}
                                >
                                    <div className="flex items-center gap-2">
                                        <div className="">
                                            {selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
                                        </div>
                                        <Svgs.NextArrow fill="#fff" />
                                    </div>
                                </LoginBtn>
                            </div>
                        </>
                    )}

                    {step == 2 && (
                        <>
                            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
                                <CircleIcon>
                                    <Svgs.Staff_Group_Permission />
                                </CircleIcon>
                                <div className="flex flex-col gap-1 lg:text-left text-center">
                                    <h2 className="text-primary font-semibold text-xl">
                                        {selectedLanguage?.name == "English" ? "Permissions" : translation[467]?.value ? translation[467]?.value : "Permissions"}
                                    </h2>
                                    <p className="text-[#7B7B7B] text-sm">
                                        {selectedLanguage?.name == "English" ? "The permission assigned to the group will be applied to each employee" : translation[584]?.value ? translation[584]?.value : "The permission assigned to the group will be applied to each employee"}
                                        <br className="hidden md:block" />
                                        {selectedLanguage?.name == "English" ? "of that group" : translation[585]?.value ? translation[585]?.value : "of that group"}
                                    </p>
                                </div>
                            </div>
                            <PermissionsComponent
                                selectedLanguage={selectedLanguage}
                                translation={translation}
                                onUpdate={employeePermissionHandleChange}
                                data={staffGroup}
                            />
                            <div className="flex items-center justify-start mt-[2rem]">
                                <LoginBtn
                                    text={(editValue && editFormOpen) ?
                                        selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                                        :
                                        selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                                    loading={loading}
                                    disabled={loading}
                                    onClick={() => {
                                        onStaffGroupSubmit();
                                    }}
                                />
                            </div>
                        </>
                    )}

                    {step == 3 && (
                        <div className="my-[2rem] flex flex-col gap-5 items-center text-center">
                            <div className="h-[150px] w-[150px]">
                                <Svgs.Form_Complete_check />
                            </div>
                            <h2 className="font-semibold text-xl normal-case">
                                {editValue ?
                                    selectedLanguage?.name == "English" ? "Staff group updated successfully" : translation[1293]?.value ? translation[1293]?.value : "Staff group updated successfully" :
                                    selectedLanguage?.name == "English" ? "Staff group created successfully" : translation[586]?.value ? translation[586]?.value : "Staff group created successfully"}
                            </h2>
                            <p className="text-[#7B7B7B] text-sm">
                                {selectedLanguage?.name == "English" ? "Staff group can perform business operations now." : translation[587]?.value ? translation[587]?.value : "Staff group can perform business operations now."}
                                <br className="hidden lg:block" />
                                {selectedLanguage?.name == "English" ? "industry." : translation[588]?.value ? translation[588]?.value : "industry."}

                            </p>
                            <LoginBtn
                                onClick={() => {
                                    close();
                                }}
                                borderBtn

                            >
                                <p className="font-semibold">
                                    {selectedLanguage?.name == "English" ? "View All Groups" : translation[589]?.value ? translation[589]?.value : "View All Groups"}
                                </p>
                            </LoginBtn>
                        </div>
                    )}
                </div>
            </Popup>

            {showAddStaffPopup && (
                <AddEmployeePopup
                    open={showAddStaffPopup}
                    close={setShowAddStaffPopup}
                    success={() => {
                        dispatch(getEmployeeDropDownList())
                    }}
                />
            )}
        </>
    )
}

export default AddStaffGroupPopup