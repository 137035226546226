import { BUSINESS, LOCATION } from "../../ActionTypes/locationTypes";
export const addLocations = (data) => {
  return {
    type: LOCATION.ADD_LOCATION,
    payload: data,
  };
};
export const createLocations = (data) => {
  return {
    type: LOCATION.CREATE_LOCATION,
    payload: data,
  };
};

export const deleteLocations = (location_id) => {
  return {
    type: LOCATION.DELETE_LOCATION,
    payload: location_id,
  };
};

export const updateLocation = (location) => {
  return {
    type: LOCATION.UPDATE_LOCATION,
    payload: location,
  };
};

export const fetchBusiness = (business) => {
  return {
    type: BUSINESS.ADD_BUSINESS,
    payload: business,
  };
};

export const editBusiness = (business) => {
  return {
    type: BUSINESS.UPDATE_BUSINESS,
    payload: business,
  };
};

export const selectedLocation = (location) => {
    return {
      type: LOCATION.SELECTED_LOCATION,
      payload: location,
    };
  };
  