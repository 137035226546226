import Svgs from 'Assets/svgs';
import Layout from 'Components/TenantApp/Dashboard/AdminBooking/Layout'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import RadioBtn from 'Components/TenantApp/Elements/RadioBtn';
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { SALE_SERVICES } from 'Redux/TenantRedux/ActionTypes/saleServiceType';
import useProfileAppointment from './helper';
import RightBar from './RightBar';

const SelectStaff = ({ setStep, step }) => {
    const navigate = useNavigate();
    const [assignEmp, setAssignEmp] = useState([])
    const [Checked, setChecked] = useState(-1)

    const dispatch = useDispatch();
    const {
        selectedProfileService, addedService,
        totalPrice, location
    } = useProfileAppointment();

    useEffect(() => {
        setAssignEmp(selectedProfileService?.selectedService)
    }, [])


    useEffect(() => {
        dispatch({
            type: SALE_SERVICES.PROFILE_SELECTED_SERIVCE,
            payload: {
                ...selectedProfileService,
                selectedService: assignEmp
            }
        })
    }, [assignEmp])


    return (
        <Layout
            className='!p-0'
            parent_className='!p-0'
            count={`${step}/4`}
            backClick={() => {
                setStep(step - 1)
            }}
            heading={'Select staff'}
            NextClick={() => {
                setStep(step + 1)
                // navigate(-1)
            }}
            // disabled={assignEmp?.find((itm) => !itm?.member) ? true : false}
        >
            <div className='md:grid flex flex-col grid-cols-7'>
                <div className='col-span-5 border-r'>
                    <div className='p-8'>
                        <div className='shadow-appoint rounded-md'>
                            <div className='p-5 border-b'>
                                <div className=''>
                                    <div className='flex items-center gap-2'>
                                        <div className='h-[4rem] w-[4rem] rounded-full overflow-hidden flex items-center justify-center border bg-[#f4f4f4]'>
                                            <div>
                                                <Svgs.Professional_employee />
                                            </div>
                                        </div>
                                        <div>
                                            <h1 className='font-semibold'>No preference</h1>
                                            <p className='text-xs text-[#101928]/50'>Maximum availability </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='h-[25rem] overflow-auto'>
                                {
                                    selectedProfileService?.selectedService?.map((itm, i) => {
                                        return <>
                                            <div onClick={() => {
                                                if (Checked != i) {
                                                    setChecked(i)
                                                } else {
                                                    setChecked(-1)
                                                }
                                            }} className='p-5 hover:bg-gray-100 rounded-md cursor-pointer'>
                                                <div className='flex items-center gap-4 justify-between'>
                                                    <div className='flex items-center gap-2'>
                                                        <div className={`transition-all ${Checked != i && '-rotate-90'}`}>
                                                            <Svgs.Chevron_down size={'1rem'} />
                                                        </div>
                                                        <div>
                                                            <h1>{itm?.service_name}</h1>
                                                            <p className='text-sm text-[#101928]/50'>{itm?.duration?.toLowerCase()?.replace("_", "")}</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex items-center gap-4'>
                                                        <p className='text-sm font-semibold'>PKR {itm?.price}</p>
                                                        <LoginBtn text={'View Staff'} borderBtn />
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                Checked == i && <>
                                                    {
                                                        itm?.employees?.filter((itm) => (itm?.location?.id === location?.id) && (itm?.status === true))?.length > 0 ?
                                                            itm?.employees?.filter((itm) => (itm?.location?.id === location?.id) && (itm?.status === true))?.map((emp, i) => {
                                                                return <div className='bg-[#FAF9FF] flex items-center gap-2 p-5 border-l-[3px] border-[#5679FFB2]'>
                                                                    <RadioBtn
                                                                        name={"member"}
                                                                        value={itm?.emp?.full_name}
                                                                        checked={assignEmp?.find((dt) => ((itm?.member === emp?.employee) && (dt?.group_id === itm?.group_id)) ? true : false) ? true : false}
                                                                        onClick={() => {
                                                                            setAssignEmp([
                                                                                ...assignEmp?.map((dt) => {
                                                                                    if (dt?.id === itm?.id) {
                                                                                        return {
                                                                                            ...dt,
                                                                                            member: emp?.employee,
                                                                                            emp_detail: emp,
                                                                                            isChecked: true
                                                                                        }
                                                                                    }
                                                                                    return dt
                                                                                })
                                                                            ])
                                                                        }}
                                                                    />
                                                                    <div className='flex items-center gap-2'>
                                                                        <div>
                                                                            <img
                                                                                src={`${emp?.image ? emp?.image : `${process.env.PUBLIC_URL}/images/profile_img.svg`}`}
                                                                                alt="" className='h-[2rem] w-[2rem] object-cover rounded-full' />
                                                                        </div>
                                                                        <div>
                                                                            <h1 className='font-semibold'>{emp?.full_name}</h1>
                                                                            <p className='text-xs text-[#101928]/50'>{emp?.designation}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }) :
                                                            <div className='flex items-center text-sm justify-center text-[#eb3b3b]'>

                                                                <h3>No staff is assigned for this service</h3>
                                                            </div>

                                                    }
                                                </>
                                            }
                                        </>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <RightBar services={addedService} totalPrice={totalPrice} location={location} />
            </div>
        </Layout>
    )
}

export default SelectStaff