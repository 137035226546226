export const PAYROLL = {
  GET_PAYROLL: "GET_PAYROLL",
  GET_PAYROLL_WITHOUT_PAGINATION: 'GET_PAYROLL_WITHOUT_PAGINATION',
  ADD_PAYROLL: "ADD_PAYROLL",
  UPDATE_PAYROLL: "UPDATE_PAYROLL",
  DELETE_PAYROLL: "DELETE_PAYROLL",
  GET_PAYROLL_PAGES: "GET_PAYROLL_PAGES",


  // Upsell and Downsell Report
  GET_UPSEL_DOWNSELL: 'GET_UPSEL_DOWNSELL',
  GET_UPSEL_DOWNSELL_STORE: 'GET_UPSEL_DOWNSELL_STORE',
};

