import React from "react";
import AddProductBtn from "Components/TenantApp/Dashboard/MyAccount/Inventory/AddHeaderBtns/AddProductBtn";
import ProductCard from "../../Elements/ProductCard";
import AddToCart from "../../Elements/AddToCart";
import AddProduct from "./add-product";
import { useSaleProduct } from "./helper";
import Svgs from "Assets/svgs";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import DiscountPopup from "Components/TenantApp/Dashboard/Calendar/AddAppointment/DiscountPromotion/DiscountPopup";
import Pagination from "new-components/Pagination";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import NoProductFound from "Components/Utility/NoProductFound";
import QuickSalesTableHead from "Components/Utility/Shimmers/QuickSalesTableHead";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import { useState } from "react";
import { useEffect } from "react";
import Shimmer_array from "Constants/Data/Shimmer_array";

// import { useSelector } from "react-redux";
// import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
// import NotFound from "../../Elements/NotFound";
// import Input from "new-components/input";
// import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";

const Products = ({ isLoading }) => {
  const {
    setSearchText, removedFromCart, handleSelection, getProductList, setAddProductPopup, handleProductDetails, page_loading, pages,
    search_text, product_details, isPermissionUpdated, addProductPopup, products_updated, seletectedProduct, selected_location, location_currency, locationDetail,
    cart_items, currentPage, setCurrentPage, updatedProducts, setUpdatedProducts, state, productsCount, OpenCart, setOpenCart, availOfferPopup,
    setAvailOfferPopup, employee, employeePermissions, lastPage, setLastPage, translation, selectedLanguage, PageSize
  } = useSaleProduct();

  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  // useEffect(() => {
  //   FilterProduct()
  // }, [search_text, listArr])

  return (
      <>
        {!products_updated ?
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 py-5">
          {Shimmer_array()?.map(itm => <ProductCard animate />)}
          </div>
          : (
            <div className="border rounded-md">
              <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
                <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold capitalize text-xl">
                      {selectedLanguage?.name == "English" ? "products" : translation[233]?.value ? translation[233]?.value : "products"}
                    </h2>

                    {page_loading ?
                      <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                        <p className="rounded-md h-5 w-12 mx-1"> </p>
                      </div>
                      :
                      <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                        <p className="text-primary">
                          {productsCount ? productsCount : 0} {" "}
                          {productsCount && productsCount > 1 ?
                            selectedLanguage?.name == "English" ? "Products" : translation[233]?.value ? translation[233]?.value : "Products"
                            : selectedLanguage?.name == "English" ? "Product" : translation[1316]?.value ? translation[1316]?.value : "Product"
                          }
                        </p>
                      </div>
                    }
                  </div>
                  <div className="mb-00">
                    {loadingPermission ?
                      <Shimmer className={'w-40 lg:w-44'}>
                        <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                      </Shimmer>
                      :
                      <LoginInput
                        title=""
                        placeholder={selectedLanguage?.name == "English" ? "Search Product" : translation[1265]?.value ? translation[1265]?.value : "Search Product"}
                        leftIcon={<Svgs.Search />}
                        padding="py-1"
                        value={search_text}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                    }
                  </div>
                </div>

                {loadingPermission ?
                  <QuickSalesTableHead />
                  :
                  <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">

                    {!employee ||
                      (employee &&
                        employeePermissions?.sales_apply_offer?.includes("create")) ||
                      employeePermissions?.sales_apply_offer?.includes("edit") ? (
                      <LoginBtn
                        onClick={() => {
                          setAvailOfferPopup(!availOfferPopup)
                        }}
                      >
                        {selectedLanguage?.name == "English" ? "Available Offer" : translation[1723]?.value ? translation[1723]?.value : "Available Offer"}
                      </LoginBtn>
                    ) : (
                      ""
                    )}

                    {!employee ? <AddProductBtn quickSales={true} title={selectedLanguage?.name == "English" ? "Add Product" : translation[235]?.value ? translation[235]?.value : "Add Product"} /> : ""}

                    {!employee ||
                      (employee &&
                        employeePermissions?.sales_apply_offer?.includes("create")) ||
                      employeePermissions?.sales_apply_offer?.includes("edit") ? (
                      <AddToCart OpenCart={OpenCart} setOpenCart={setOpenCart} />
                    ) : (
                      ""
                    )}
                  </div>
                }
              </div>
              <div className="px-6 py-3">
                {page_loading ?
                  <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 py-5">
                  {Shimmer_array()?.map(itm => <ProductCard animate />)}
                  </div>
                  : <>
                    {updatedProducts && updatedProducts?.length > 0 ? (
                      <>
                        <div className="flex items-center justify-between">
                          <h1 className="text-[#7B7B7B] text-sm font-semibold normal-case">
                            {selectedLanguage?.name == "English" ? "You can select multiple products" : translation[234]?.value ? translation[234]?.value : "You can select multiple products"} <span className='text-red-500'>*</span>
                          </h1>
                          {productsCount > 10 &&
                            <div className="flex items-center justify-between gap-4">
                              <LoginBtn
                                onClick={() => {
                                  setCurrentPage(currentPage - 1);
                                }}
                                disabled={currentPage === 1}
                                text="Back" />
                              <LoginBtn
                                onClick={() => {
                                  setCurrentPage(currentPage + 1);
                                }}
                                disabled={currentPage === lastPage}
                                text="Next" />
                            </div>
                          }
                        </div>

                        <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 py-5">
                          {updatedProducts?.map((product) => {
                            return (
                              <ProductCard
                                setOpenCart={setOpenCart}
                                OpenCart={OpenCart}
                                item={product}
                                productsData={updatedProducts}
                                locationDetail={locationDetail}
                                currency={location_currency}
                                name={product?.name}
                                value={product?.name}
                                selected_location={selected_location}
                                onClick={(stock, price) => {
                                  handleSelection(product?.id, stock, price)
                                }}
                                translation={translation}
                                selectedLanguage={selectedLanguage}
                                checked={seletectedProduct?.includes(product?.id)}
                                product={product}
                                key={product?.id}
                                removedFromCart={removedFromCart}
                                view_details={(retail) =>
                                  handleProductDetails(product?.id, retail)
                                }
                                getProductList={() => getProductList()}
                                cartItems={cart_items}
                              />
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <div className="flex items-center justify-center">
                        <NoProductFound />
                      </div>
                    )
                    }
                  </>
                }
              </div>

              {/* Pagination */}
              {!page_loading &&
                <Pagination
                  currentPage={currentPage}
                  totalCount={Math.ceil(pages)}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                  setLastPage={setLastPage}
                />
              }
            </div>
          )}

        {addProductPopup && (
          <AddProduct
            location_currency={location_currency}
            product_details={product_details}
            setAddProduct={setAddProductPopup}
            translation={translation}
            selectedLanguage={selectedLanguage}
          />
        )}
        {
          availOfferPopup && (
            <DiscountPopup
              MODULE_TYPE={'PRODUCT'}
              availOfferPopup={availOfferPopup}
              setAvailOfferPopup={setAvailOfferPopup}
              setProductsData={setUpdatedProducts}
              servicesRedux={state?.saleService?.saleServices}


            // discountPromotionData={discountPromotionData}
            // discountPromotions={discountPromotions}
            // addOffer={addOffer}
            // filterOffer={filterOffer}
            // selected={serviceData.avail_service}
            // quickSale={true}
            />
          )
        }
      </>
    );
};
export default Products;
