import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";

import LoginInput from "Components/TenantApp/Elements/LoginInput";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Popup from "Components/TenantApp/Elements/Popup";

import Svgs from "Assets/svgs";
import LocationSelection from "new-components/location";
import { useSaleHistory } from "./helper";
import Pagination from "new-components/Pagination/index";
import moment from "moment";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoSaleHistoryFound from "../Elements/NoSaleHistoryFound";
import SelectedInvoiceModal from "./SelectedInvoiceModal";
import DateRangePicker from "Components/TenantApp/Elements/DateRangePicker";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import RefundPopup from "./Refund";
import GroupInvoiceModal from "./GroupInvoiceModal";
// import { useReactToPrint } from "react-to-print";
// import NotFound from "../Elements/NotFound";
// import { pdfFromReactCustom } from "./PDFDownloader";

const SaleHistory = () => {
  const {
    search_text, onChange, pages, toggleInviceModal, FilterPopup, setFilterPopup, setstaff, setservices, client, setclient, clientDropdownRedux,
    setlocation, reduxServices, services, loading, applyFilters, InvoicePopup, selectedInvice, calculate_totals, exportSaleHistory,
    getInvoiceDetailData, location_currency, clearFilter, currentPage, setCurrentPage, startDate, setStartDate, endDate, setEndDate,
    allSaleordersWithNoPagination, invoice_titles, allSaleorders, detailLoading, setInvoicePopup, setSelectedInvice,
    clientSearchText, setClientSearchText, clientCurrentPage, setClientCurrentPage, clientLoader, clientPaginationData,
    serviceSearchText, setServiceSearchText, serviceCurrentPage, setServiceCurrentPage, serviceLoader, servicePaginationData,
    openRefundPopup, setOpenRefundPopup, invoiceId, checkRefundAvailablity, checkRefundLoading, getSaleHistory
    // navigate, staff_data_filters, reduxlocationsList, reduxEmployees, staff, location, businessCurrency, invoiceLabels, calculateTotalAmount,
    // total_tax
  } = useSaleHistory();

  // const ref = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => ref.current,
  //   documentTitle: "Invoice",
  //   onAfterPrint: () => { },
  // });
  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [toggleDateRange, setToggleDateRange] = useState(false)

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);


  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allSaleordersWithNoPagination?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  const PageSize = 10
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right relative">
        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Sales" : translation[3]?.value ? translation[3]?.value : "Sales"}
          second={selectedLanguage?.name == "English" ? "Sales History" : translation[338]?.value ? translation[338]?.value : "Sales History"}
          url={'/dashboard/sales/'}
        />

        <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">

          <div className="flex items-center gap-4">
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Sales History" : translation[338]?.value ? translation[338]?.value : "Sales History"}
            </h2>
            <LocationSelection />
          </div>
        </div>

        <div className="">
          <div className="border rounded-lg">
            <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
              <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                <div className="flex items-center lg:gap-4 gap-3">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold text-xl">
                      {selectedLanguage?.name == "English" ? "Business Sales History" : translation[339]?.value ? translation[339]?.value : "Business Sales History"}
                    </h2>
                  </div>
                </div>
                <div className="mb-00">
                  <LoginInput
                    title=""
                    name={"search_text"}
                    placeholder={selectedLanguage?.name == "English" ? "Search client or invoice" : translation[143]?.value ? translation[143]?.value : "Search client or invoice"}
                    value={search_text}
                    onChange={onChange}
                    padding="!py-3"
                    leftIcon={<Svgs.Search />}
                  />
                </div>
              </div>
              <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">

                {/* <DateRangePicker
                  value={[startDate, endDate]}
                  clearIcon={
                    <p
                      onClick={() => {
                        if (startDate && endDate) setStartDate();
                        setEndDate();

                      }}
                    >
                      {" "}
                      {selectedLanguage?.name == "English" ? "clear" : translation[340]?.value ? translation[340]?.value : "clear"}
                      {" "}
                    </p>
                  }
                  onChange={(e) => {
                    if (e && e.length > 0) {
                      const from = moment(e[0]).format("YYYY-MM-DD");
                      const to = moment(e[1]).format("YYYY-MM-DD");
                      setStartDate(from);
                      setEndDate(to);
                    }
                  }}
                /> */}
                <div className='flex items-center gap-3'>
                  <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                    <div className='p-2 relative gap-4 flex items-center'>
                      <p onClick={() => {
                        setToggleDateRange(!toggleDateRange)
                      }} className='cursor-pointer'>
                        {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/----"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/----"}`}
                      </p>
                      <span className='cursor-pointer' onClick={() => {
                        setStartDate()
                        setEndDate()
                      }}>Clear</span>

                      {
                        toggleDateRange && <>
                          <div className='fixed inset-0 z-1' onClick={() => { setToggleDateRange(false) }}></div>
                          <div className='absolute top-full sm:right-0 -right-44 z-[2]'>
                            <DateRangePicker
                              start={startDate}
                              end={endDate}
                              setStartDate={setStartDate}
                              setEndDate={setEndDate}
                              setToggleDateRange={setToggleDateRange}
                            />
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>

                <LoginBtn
                  borderBtn
                  onClick={() => {
                    setFilterPopup(!FilterPopup);
                  }}
                >
                  <div className="flex items-center gap-2 text-primary font-semibold">
                    <Svgs.Filters />
                    <p>
                      {selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}
                    </p>
                  </div>
                </LoginBtn>
                {exportLoader ? (
                  <div className="h-[4rem] flex items-center justify-center">
                    <PreloaderSm />
                  </div>
                ) :
                  !employee ? (
                    allSaleordersWithNoPagination?.length > 0 ?
                      <CSVLink
                        {...exportSaleHistory}
                        target="_blank"
                        onClick={handleExportClick}
                      >
                        <IconButton filled>
                          <Svgs.Export />
                        </IconButton>
                      </CSVLink> :
                      <LoginBtn
                        className="rounded-lg border-2 border-primary"
                        bg="bg-white"
                        animation={false}
                        onClick={handleExportClick}
                      >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                          <Svgs.Export bg="#FFFFFF" />
                        </div>
                      </LoginBtn>
                  ) : (
                    ""
                  )}
              </div>
            </div>

            {loading ?
              <TableShimer cols={9} rows={10} />
              :
              <div className="overflow-x-auto">
                <div className="inline-grid xl:grid grid-cols-[repeat(9,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                  <p>{selectedLanguage?.name == "English" ? "Invoice ID" : translation[411]?.value ? translation[411]?.value : "Invoice ID"}</p>

                  <p>{selectedLanguage?.name == "English" ? "Payment Date" : translation[349]?.value ? translation[349]?.value : "Payment Date"}</p>

                  <p>{selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"}</p>

                  <p>{selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client"}</p>

                  {/* <p>{selectedLanguage?.name == "English" ? "Method" : translation[351]?.value ? translation[351]?.value : "Method"}</p> */}

                  <p>{selectedLanguage?.name == "English" ? "Total Tips" : translation[352]?.value ? translation[352]?.value : "Total Tips"}</p>

                  <p>{selectedLanguage?.name == "English" ? "Sub Total" : translation[242]?.value ? translation[242]?.value : "Sub Total"}</p>

                  <p>{selectedLanguage?.name == "English" ? "Tax" : translation[733]?.value ? translation[733]?.value : "Tax"}</p>

                  <p>{selectedLanguage?.name == "English" ? "Total Sale" : translation[354]?.value ? translation[354]?.value : "Total Sale"}</p>

                  <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
                  <p></p>
                </div>
                <div
                  className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
                >
                  {allSaleorders?.length > 0 ? (
                    allSaleorders?.map((item, index) => {
                      // let paymentDate;
                      // let total_amount = calculateTotalAmount(item);
                      // const totalSale = ((item?.quantity ? +item?.quantity : 1) * (total_amount ? +total_amount : 0)) +
                      // +item?.gst_price + +item?.gst_price1 +
                      //  (item?.tip?.length > 0 ? +item?.tip?.reduce(function (total, obj) {
                      //   return (((+total + +(obj.tip !== null ? obj?.tip : 0))));
                      //  }, 0) : 0) 
                      // if (item?.created_at || item?.end_date) {
                      //  const dataArr = item?.created_at
                      //    ? item?.created_at?.split("-")
                      //    : item?.end_date?.split("-");
                      //   const date_join = dataArr[2]
                      //    .split("")
                      //    .slice(0, 2)
                      //    .join("");
                      //  dataArr.splice(2, 1, date_join);
                      //  paymentDate = dataArr.join("-");
                      // }
                      //const date = new Date(paymentDate);
                      //const day = date.getDate().toString().padStart(2, '0');
                      //const month = (date.getMonth() + 1).toString().padStart(2, '0');
                      //const year = date.getFullYear();
                      //paymentDate = `${day}-${month}-${year}`;

                      return (
                        <div key={index} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(9,minmax(115px,1fr))]">
                          <div className={`before-left-arrow cursor-pointer`}>
                            <div className="h-full flex items-center">
                              <p
                                className="text-sm text-[#5679FF] font-black line-clamp-1"
                                onClick={() => {
                                  getInvoiceDetailData(item?.id)
                                  // toggleInviceModal(item?.id, "history")
                                }}
                              >
                                #{item?.invoice?.id
                                  ?.split("-")[0]
                                }
                              </p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm line-clamp-1 pr-3">
                              {(item?.created_at || item?.end_date) ? moment(item?.created_at || item?.end_date).format('DD-MM-YYYY') : "----"}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm line-clamp-2">
                              {item?.checkout_type
                                ? item?.checkout_type
                                : "----"}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">
                              {typeof item?.client == "string"
                                ? item?.client_data?.full_name
                                : "Walk In"}
                            </p>
                          </div>
                          {/* <div className="flex items-center">
                            <p className="text-sm ">
                              {item?.payment_type
                                ? item?.payment_type
                                : item?.payment_method
                                  ? item?.payment_method
                                  : "----"}
                            </p>
                          </div> */}
                          <div className="flex items-center">
                            <p className="text-sm ">
                              {(+item?.total_tip)?.toFixed(2)} {location_currency}
                              {/* {parseFloat(item?.tip?.reduce(function (total, obj) {
                                return total + (obj?.tip !== null && obj?.tip);
                              }, 0)?.toFixed(2))} {location_currency} */}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm ">
                              {(+item?.sub_total)?.toFixed(2)} {location_currency}
                              {/* {parseFloat(((item?.quantity ? item?.quantity : 1) * (total_amount ? total_amount : 0))?.toFixed(2))}
                              {" "}
                              {location_currency} */}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm ">
                              {(+item?.total_tax)?.toFixed(2)} {location_currency}
                              {/* {parseFloat((+item?.gst_price + +item?.gst_price1)?.toFixed(2))}
                              {" "}
                              {location_currency} */}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm ">
                              {(+item?.total_price)?.toFixed(2)} {location_currency}

                              {/* {(+item?.total_tip + +item?.subtotal + +item?.total_tax)?.toFixed(2)} {location_currency} */}
                              {/* {((item?.quantity ? item?.quantity : 1) * (total_amount ? total_amount : 0)) +
                              Math.round((total_tax * total_amount) / 100) +
                              Math.round(((item?.tip ? item?.tip : 0) * total_amount) / 100)
                            } */}
                              {/* {totalSale?.toFixed(2)} 
                               {" "}
                              {location_currency} */}
                            </p>
                          </div>

                          <div className="flex gap-2 items-center cursor-pointer">
                            <div onClick={() => {
                              getInvoiceDetailData(item?.id)
                              // toggleInviceModal(item?.id, "history")
                            }}>
                              <Svgs.Eye />
                            </div>
                            <div>
                              <Svgs.Import width="22" height="22" fill="#A0A0A0" href={item?.invoice?.file} target={true} />
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <NoSaleHistoryFound />
                  )}
                </div>
              </div>
            }

            {/* Pagination */}
            {(allSaleorders?.length > 0 && !loading) &&
              <Pagination
                currentPage={currentPage}
                totalCount={Math.ceil(pages)}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
              />
            }

          </div>
        </div>
      </div>

      {FilterPopup && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}
          open={FilterPopup}
          close={() => {
            setFilterPopup(!FilterPopup);
            setclient();
            setlocation();
            setstaff();
            setservices();
          }}
        >
          <div className="flex flex-col gap-5">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
              <CircleIcon>
                <Svgs.FilterPopup />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Search By Filters" : translation[343]?.value ? translation[343]?.value : "Search By Filters"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Explore tailored results by applying various filters to enhance your sales search." : translation[344]?.value ? translation[344]?.value : "Explore tailored results by applying various filters to enhance your sales search."}
                </p>
              </div>
            </div>
            <div className="grid xs:grid-cols-2 gap-5">
              <div>
                <PaginationDropdown
                  title={selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client"}
                  placeholder={selectedLanguage?.name == "English" ? "Select Client" : translation[225]?.value ? translation[225]?.value : "Select Client"}
                  name={client}
                  onChange={(e) => setclient(e.target.value)}
                  value={client}

                  setPage={setClientCurrentPage}
                  page={clientCurrentPage}
                  setDropdownSearch={setClientSearchText}
                  dropdownSearch={clientSearchText}
                  lastPage={clientPaginationData?.pages}
                  currentPage={clientPaginationData?.currentPage}
                  loading={clientLoader}

                  options={clientDropdownRedux?.map((clnt, i) => {
                    return {
                      label: clnt.full_name,
                      value: clnt.id,
                      otherData: {
                        image: clnt?.image,
                        DataOne: clnt.email,
                        showImage: true,
                        index: i
                      },
                      email: clnt.email,
                      mobile_number: clnt.mobile_number
                    };
                  })}
                />
              </div>
              {/* <div>
                <Dropwdown
                  title="Location"
                  placeholder="Select Location"
                  onChange={(e) => setlocation(e.target.value)}
                  value={location}
                  name={location}
                  options={reduxlocationsList.map((lction) => {
                    return {
                      value: lction?.address_name,
                      label: lction?.address_name,
                    };
                  })}
                />
              </div> */}
              {/* <div>
                <Dropwdown
                  title="Staff"
                  placeholder="Select Staff"
                  name={staff}
                  value={staff}
                  onChange={(e) => {
                    setstaff(e.target.value);
                  }}
                  options={reduxEmployees?.map((emp) => {
                    return {
                      value: emp?.full_name,
                      label: emp?.full_name,
                    };
                  })}
                />
              </div> */}
              <div>
                <PaginationDropdown
                  title={selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}
                  placeholder={selectedLanguage?.name == "English" ? "Select Service" : translation[89]?.value ? translation[89]?.value : "Select Service"}
                  name={services}
                  value={services}
                  onChange={(e) => { setservices(e.target.value); }}

                  setPage={setServiceCurrentPage}
                  setDropdownSearch={setServiceSearchText}
                  page={serviceCurrentPage}
                  dropdownSearch={serviceSearchText}
                  lastPage={servicePaginationData?.total_pages}
                  currentPage={servicePaginationData?.currentPage}
                  loading={serviceLoader}

                  options={reduxServices?.map((srv) => {
                    return {
                      value: srv?.id,
                      label: srv?.name,
                    };
                  })}
                />
              </div>
            </div>
            <div className="flex gap-2">
              <LoginBtn
                text={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                onClick={applyFilters} />
              <LoginBtn
                text={selectedLanguage?.name == "English" ? "Clear filter" : translation[346]?.value ? translation[346]?.value : "Clear filter"}
                onClick={() => {
                  setCurrentPage(1)
                  setclient("");
                  setlocation("");
                  setstaff("");
                  setservices("");
                  clearFilter()
                }}
              />
            </div>
          </div>
        </Popup>
      )}

      {selectedInvice?.checkout_type == "Group Appointment" ?
        <GroupInvoiceModal
          open={InvoicePopup}
          close={() => {
            setInvoicePopup(false)
          }}
          invoice_titles={invoice_titles}
          selectedInvice={selectedInvice}
          selectedLanguage={selectedLanguage}
          translation={translation}
          calculate_totals={calculate_totals}
          loading={detailLoading}
          getSaleHistory={getSaleHistory}
        /> :
        <>
          <SelectedInvoiceModal
            open={InvoicePopup}
            close={() => {
              setInvoicePopup(false)
            }}
            invoice_titles={invoice_titles}
            selectedInvice={selectedInvice}
            selectedLanguage={selectedLanguage}
            translation={translation}
            calculate_totals={calculate_totals}
            loading={detailLoading}
            getSaleHistory={getSaleHistory}
          />
        </>
      }

      {/* {openRefundPopup &&
        <RefundPopup
          open={openRefundPopup}
          close={setOpenRefundPopup}
          invoiceId={invoiceId}
          getSaleHistory={getSaleHistory}
        />
      } */}
    </>
  );
};

export default SaleHistory;
