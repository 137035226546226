/* eslint-disable no-const-assign */
import axios from "axios";
import { apiErrorMessage } from "Constants/Data/Errors";
import { toast } from "react-toastify";
import { addDiscountPromotion, deleteDiscountPromotion, getAvailOffers, getDiscountPromotion, updateDiscountPromotion } from "Redux/TenantRedux/Actions/clientDiscountPromotionActions";
import { CLIENT_DISCOUNT_PROMOTION } from "Redux/TenantRedux/ActionTypes/clientDiscountActionTypes";
import { add_bundle_discount_url, add_complimentary_discount_url, add_coupons_discount_url, add_direct_or_flat_url, add_fixed_price_service_discount_url, add_free_service_discount_url, add_package_discount_url, add_purchase_discount_url, add_retail_getservice_url, add_specific_brandservice_discount_url, add_specific_group_discount_url, add_spend_someamount_discount_url, add_user_restricted_discount_url, BASE_URL, delete_bundle_discount_url, delete_complimentary_discount_url, delete_coupons_discount_url, delete_direct_or_flat_url, delete_fixed_price_service_discount_url, delete_free_service_discount_url, delete_package_discount_url, delete_purchase_discount_url, delete_retail_getservice_url, delete_specific_brandservice_discount_url, delete_specific_group_discount_url, delete_spend_someamount_discount_url, delete_user_restricted_discount_url, get_direct_or_flat_url, get_discount_and_promotions_url, update_bundle_discount_url, update_complimentary_discount_url, update_coupons_discount_url, update_direct_or_flat_url, update_fixed_price_service_discount_url, update_free_service_discount_url, update_package_discount_url, update_purchase_discount_url, update_retail_getservice_url, update_specific_brandservice_discount_url, update_specific_group_discount_url, update_spend_someamount_discount_url, update_user_restricted_discount_url } from "../api_variables";
import { convertDataToFormData } from "Constants/Functions/convertDataToFormData";


export const get_client_discount_promotion = (setGetLoader) => async (dispatch) => {
  setGetLoader && setGetLoader(true)
  try {
    const response = await axios.get(`${BASE_URL}${get_direct_or_flat_url}`);
    if (response.status === 200) {
      dispatch(getDiscountPromotion(response?.data?.response?.flatordirect));
      setGetLoader && setGetLoader(false)
    } else {
      toast.error(apiErrorMessage, { toastId: "toast" });
      setGetLoader && setGetLoader(false)
    }
  } catch (error) {
    toast.error(apiErrorMessage, { toastId: "toast" });
    setGetLoader && setGetLoader(false)
    return error;
  }
};

export const add_client_discount_promotion = (data, access_token, success) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}${add_direct_or_flat_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(<div className="normal-case">{successMessage}</div>, { toastId: "toast" });
      // dispatch(addDiscountPromotion(response?.data?.response?.flatordirect, CLIENT_DISCOUNT_PROMOTION.ADD_DISCOUNT));
      return response;
    }

  } catch (error) {
    return error;
  }
};

export const update_client_discount_promotion = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}${update_direct_or_flat_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      // dispatch(updateDiscountPromotion(response?.data?.response?.flatordirect, CLIENT_DISCOUNT_PROMOTION.UPDATE_DISCOUNT));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};
export const delete_client_discount_promotion = (id, access_token, success, fail) => async (dispatch) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_direct_or_flat_url}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      // dispatch(deleteDiscountPromotion(id, CLIENT_DISCOUNT_PROMOTION.DELETE_DISCOUNT));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};

// Discount on Specific group of category
export const add_service_group_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}${add_specific_group_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      // dispatch(addDiscountPromotion(response?.data?.response?.specificgroup, CLIENT_DISCOUNT_PROMOTION.ADD_SPECIFIC_CATEGORYGROUP_DISCOUNT));
      return response;
    }

  } catch (error) {
    fail && fail();
    return error;
  }
};

export const update_service_group_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}${update_specific_group_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      // dispatch(updateDiscountPromotion(response?.data?.response?.specificgroup, CLIENT_DISCOUNT_PROMOTION.UPDATE_SPECIFIC_CATEGORYGROUP_DISCOUNT));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const delete_service_group_discount = (id, access_token, success, fail) => async (dispatch) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_specific_group_discount_url}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      // dispatch(deleteDiscountPromotion(id, CLIENT_DISCOUNT_PROMOTION.DELETE_SPECIFIC_CATEGORYGROUP_DISCOUNT));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};

// Discount on Purchase and Get Discount
export const add_purchase_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}${add_purchase_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      // dispatch(addDiscountPromotion(response?.data?.response?.purchasediscount, CLIENT_DISCOUNT_PROMOTION.ADD_PURCHASE_DISCOUNT));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const update_purchase_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}${update_purchase_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      dispatch(updateDiscountPromotion(response?.data?.response?.purchasediscount, CLIENT_DISCOUNT_PROMOTION.UPDATE_PURCHASE_DISCOUNT));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const delete_purchase_discount = (id, access_token, success, fail) => async (dispatch) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_purchase_discount_url}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      // dispatch(deleteDiscountPromotion(id, CLIENT_DISCOUNT_PROMOTION.DELETE_PURCHASE_DISCOUNT));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};

// Discount on Specific Brand and Service Group
export const add_specific_brandservice_group_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}${add_specific_brandservice_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      // dispatch(addDiscountPromotion(response?.data?.response?.specificbrand, CLIENT_DISCOUNT_PROMOTION.ADD_SPECIFIC_BRAND_SERVICE_GROUP_DISCOUNT));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const update_specific_brandservice_group_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}${update_specific_brandservice_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      let type = CLIENT_DISCOUNT_PROMOTION.UPDATE_SPECIFIC_BRAND_SERVICE_GROUP_DISCOUNT;
      // dispatch(updateDiscountPromotion(response?.data?.response?.specificbrand, type));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const delete_specific_brandservice_group_discount = (id, access_token, success, fail) => async (dispatch) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_specific_brandservice_discount_url}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      // dispatch(deleteDiscountPromotion(id, CLIENT_DISCOUNT_PROMOTION.DELETE_SPECIFIC_BRAND_SERVICE_GROUP_DISCOUNT));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};

// Discount on get a free item if you spend some amount
export const add_spend_some_amount_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}${add_spend_someamount_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });

      let actionType = CLIENT_DISCOUNT_PROMOTION.ADD_SPEND_SOME_AMOUNT_DISCOUNT;
      // dispatch(addDiscountPromotion(response?.data?.response?.spendamount, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const update_spend_some_amount_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}${update_spend_someamount_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      let actionType = CLIENT_DISCOUNT_PROMOTION.UPDATE_SPEND_SOME_AMOUNT_DISCOUNT;
      // dispatch(updateDiscountPromotion(response?.data?.response?.spendsome, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const delete_spend_some_amount_discount = (id, access_token, success, fail) => async (dispatch) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_spend_someamount_discount_url}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      let actionType = CLIENT_DISCOUNT_PROMOTION.DELETE_SPEND_SOME_AMOUNT_DISCOUNT
      // dispatch(deleteDiscountPromotion(id, actionType));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};

// Get an Item for a fixed price or in the group
export const add_fixed_price_service_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}${add_fixed_price_service_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });

      let actionType = CLIENT_DISCOUNT_PROMOTION.ADD_FIXED_PRICE_SERVICE_DISCOUNT;
      // dispatch(addDiscountPromotion(response?.data?.response?.fixedprice, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const update_fixed_price_service_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}${update_fixed_price_service_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      let actionType = CLIENT_DISCOUNT_PROMOTION.UPDATE_FIXED_PRICE_SERVICE_DISCOUNT;
      // dispatch(updateDiscountPromotion(response?.data?.response?.fixedprice, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const delete_fixed_price_service_discount = (id, access_token, success, fail) => async (dispatch) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_fixed_price_service_discount_url}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      let actionType = CLIENT_DISCOUNT_PROMOTION.DELETE_FIXED_PRICE_SERVICE_DISCOUNT
      // dispatch(deleteDiscountPromotion(id, actionType));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};

export const add_free_service_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}${add_free_service_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });

      let actionType = CLIENT_DISCOUNT_PROMOTION.ADD_FREE_SERVICE_DISCOUNT;
      // dispatch(addDiscountPromotion(response?.data?.response?.freservice, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const update_free_service_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}${update_free_service_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      let actionType = CLIENT_DISCOUNT_PROMOTION.UPDATE_FREE_SERVICE_DISCOUNT;
      // dispatch(updateDiscountPromotion(response?.data?.response?.freservice, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const delete_free_service_discount = (id, access_token, success, fail) => async (dispatch) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_free_service_discount_url}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      let actionType = CLIENT_DISCOUNT_PROMOTION.DELETE_FREE_SERVICE_DISCOUNT
      // dispatch(deleteDiscountPromotion(id, actionType));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};

// Discount on Bundle fixed price
export const add_bundle_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}${add_bundle_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });

      let actionType = CLIENT_DISCOUNT_PROMOTION.ADD_BUNDLE_DISCOUNT;
      // dispatch(addDiscountPromotion(response?.data?.response?.bundlefixed, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const update_bundle_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}${update_bundle_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      let actionType = CLIENT_DISCOUNT_PROMOTION.UPDATE_BUNDLE_DISCOUNT;
      // dispatch(updateDiscountPromotion(response?.data?.response?.bundlefixed, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const delete_bundle_discount = (id, access_token, success, fail) => async (dispatch) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_bundle_discount_url}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      let actionType = CLIENT_DISCOUNT_PROMOTION.DELETE_BUNDLE_DISCOUNT
      // dispatch(deleteDiscountPromotion(id, actionType));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};

// Discount on Buy retail and get specific services
export const add_retail_getservice_promotion = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}${add_retail_getservice_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });

      let actionType = CLIENT_DISCOUNT_PROMOTION.ADD_RETAIL_PROMOTION;
      // dispatch(addDiscountPromotion(response?.data?.response?.retail, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const update_retail_getservice_promotion = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}${update_retail_getservice_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      let actionType = CLIENT_DISCOUNT_PROMOTION.UPDATE_RETAIL_PROMOTION;
      // dispatch(updateDiscountPromotion(response?.data?.response?.retail, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const delete_retail_getservice_promotion = (id, access_token, success, fail) => async (dispatch) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_retail_getservice_url}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      let actionType = CLIENT_DISCOUNT_PROMOTION.DELETE_RETAIL_PROMOTION
      dispatch(deleteDiscountPromotion(id, actionType));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};


// Discount on Add User Restricted Discount
export const add_user_restricted_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}${add_user_restricted_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });

      let actionType = CLIENT_DISCOUNT_PROMOTION.ADD_USER_RESTRICTED_DISCOUNT;
      // dispatch(addDiscountPromotion(response?.data?.response?.userRestricted, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const update_user_restricted_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}${update_user_restricted_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      let actionType = CLIENT_DISCOUNT_PROMOTION.UPDATE_USER_RESTRICTED_DISCOUNT;
      // dispatch(updateDiscountPromotion(response?.data?.response?.userRestricted, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const delete_user_restricted_discount = (id, access_token, success, fail) => async (dispatch) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_user_restricted_discount_url}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      let actionType = CLIENT_DISCOUNT_PROMOTION.DELETE_USER_RESTRICTED_DISCOUNT
      // dispatch(deleteDiscountPromotion(id, actionType));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};

// Discount on Complimentary Vouchers 
export const add_complimentry_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}${add_complimentary_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });

      let actionType = CLIENT_DISCOUNT_PROMOTION.ADD_COMPLIMENTARY_DISCOUNT;
      // dispatch(addDiscountPromotion(response?.data?.response?.complimentry, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const update_complimentry_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}${update_complimentary_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      let actionType = CLIENT_DISCOUNT_PROMOTION.UPDATE_COMPLIMENTARY_DISCOUNT;
      // dispatch(updateDiscountPromotion(response?.data?.response?.complimentry, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const delete_complimentry_discount = (id, access_token, success, fail) => async (dispatch) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_complimentary_discount_url}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      let actionType = CLIENT_DISCOUNT_PROMOTION.DELETE_COMPLIMENTARY_DISCOUNT
      // dispatch(deleteDiscountPromotion(id, actionType));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};

// Discount on Packages
export const add_package_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.post(`${BASE_URL}${add_package_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });

      let actionType = CLIENT_DISCOUNT_PROMOTION.ADD_PACKAGE_DISCOUNT;
      // dispatch(addDiscountPromotion(response?.data?.response?.packages, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const update_package_discount = (data, access_token, success, fail) => async (dispatch) => {
  try {
    const response = await axios.put(`${BASE_URL}${update_package_discount_url}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      let actionType = CLIENT_DISCOUNT_PROMOTION.UPDATE_PACKAGE_DISCOUNT;
      // dispatch(updateDiscountPromotion(response?.data?.response?.packages, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const delete_package_discount = (id, access_token, success, fail) => async (dispatch) => {
  try {
    let data = {
      id: id,
    };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` },
      data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_package_discount_url}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      let actionType = CLIENT_DISCOUNT_PROMOTION.DELETE_PACKAGE_DISCOUNT
      // dispatch(deleteDiscountPromotion(id, actionType));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};

// Discount on Coupons
export const add_coupons_discount = (data, access_token, success, fail) => async (dispatch) => {
  const form_data = convertDataToFormData(data)
  try {
    const response = await axios.post(`${BASE_URL}${add_coupons_discount_url}`, form_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response?.data?.status_code === 201) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });

      let actionType = CLIENT_DISCOUNT_PROMOTION.ADD_COUPONS_DISCOUNT;
      // dispatch(addDiscountPromotion(response?.data?.response?.coupon, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const update_coupons_discount = (data, access_token, success, fail) => async (dispatch) => {
  const form_data = convertDataToFormData(data)
  try {
    const response = await axios.patch(`${BASE_URL}${update_coupons_discount_url}`, form_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      success && success();
      toast.success(successMessage, { toastId: "toast" });
      let actionType = CLIENT_DISCOUNT_PROMOTION.UPDATE_COUPONS_DISCOUNT;
      // dispatch(updateDiscountPromotion(response?.data?.response?.coupon, actionType));
      return response;
    }

  } catch (error) {
    fail && fail()
    return error;
  }
};

export const delete_coupons_discount = (id, access_token, success, fail) => async (dispatch) => {
  try {
    // let data = {
    //   id: id,
    // };
    let headers = {
      "Content-type": "application/json",
      headers: { Authorization: `Token ${access_token}` }
      // data,
    };
    const response = await axios.delete(
      `${BASE_URL}${delete_coupons_discount_url}${id}`,
      headers
    );
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      success && success()
      let actionType = CLIENT_DISCOUNT_PROMOTION.DELETE_COUPONS_DISCOUNT
      // dispatch(deleteDiscountPromotion(id, actionType));
      return response;
    }
  } catch (error) {
    fail && fail()
    return error;
  }
};

// Get Discount and Promotions
export const get_discount_promotions = (data, success, fail) => (dispatch) => {
  let queries = '?'

  let selected_location = localStorage.getItem('selected_location')


  if (selected_location) {
    selected_location = selected_location.replaceAll('"', '')
  }

  data['selected_location'] = selected_location

  for (let dt in data) {
    queries += `${dt}=${data[dt]}&`
  }
  fetch(`${BASE_URL}${get_discount_and_promotions_url}${queries}`,)
    .then(response => {
      return response.json()
    })
    .then(result => {
      dispatch(getAvailOffers(result.response?.avail_offers));
      success && success(result.response?.avail_offers)
    })
    .catch(err => {

    })

};