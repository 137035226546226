import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import { useLocation } from "new-components/location/helper";

import { getvouchers } from "Adapters/Api/vouchers";

import Svgs from "Assets/svgs";


const VoucherCard = ({
  setOpenCart,
  data,
  onClick,
  checked,
  veiw_details,
  onUpdate,
  removedFromCart,
}) => {
  const [voucherData, setvoucherData] = useState({});
  const [voucherError, setvoucherError] = useState({});
  const [open, setopen] = useState("true");

  const { locationDetail } = useLocation();
  const LocationCurrency = locationDetail?.currency?.id;

  const [editId, seteditId] = useState("");
  const state = useSelector((state) => state);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const handlevoucherData = (e) => {
    const { name, value } = e.target;
    setvoucherData({
      ...voucherData,
      [name]: value,
    });
  };

  useEffect(() => {
    setvoucherData({
      ...data,
    });
  }, [editId]);

  return (
    <>
      {editId === voucherData?.id && open == "false" ? (
        <div className="mt-[2rem] border px-4 py-3 rounded-md w-full flex flex-col gap-4">
          <LoginInput
            name={"name"}
            required={true}
            title="Voucher Name"
            placeholder="Voucher Name"
            onEnterSubmit={() => {
              onUpdate(voucherData);
            }}
            value={voucherData?.name}
            error={voucherError.name}
            onChange={(e) => {
              handlevoucherData(e);
            }}
            onBlur={() => {
              if (!voucherData?.name) {
                setvoucherError({
                  ...voucherError,
                  name: true,
                });
              } else {
                setvoucherError({
                  ...voucherError,
                  name: false,
                });
              }
            }}
          />
          <LoginInput
            required
            title="Value"
            type="number"
            placeholder="Select Discount Duration"
            name="value"
            onEnterSubmit={() => {
              onUpdate(voucherData);
            }}
            value={voucherData?.value}
            error={voucherError.value}
            onChange={(e) => {
              handlevoucherData(e);
            }}
            onBlur={() => {
              if (!voucherData?.value) {
                setvoucherError({
                  ...voucherError,
                  value: true,
                });
              } else {
                setvoucherError({
                  ...voucherError,
                  value: false,
                });
              }
            }}
          />

          <Dropwdown
            required
            title="Choose Promotion Type"
            placeholder="Promotion Type"
            name="voucher_type"
            value={voucherData?.voucher_type}
            error={voucherError.voucher_type}
            options={[
              { label: "Service", value: "Service" },
              { label: "Product", value: "Product" },
            ]}
            onChange={(e) => {
              handlevoucherData(e);
            }}
            onBlur={() => {
              if (!voucherData?.voucher_type) {
                setvoucherError({
                  ...voucherError,
                  voucher_type: true,
                });
              } else {
                setvoucherError({
                  ...voucherError,
                  voucher_type: false,
                });
              }
            }}
          />

          <div className="grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col gap-2">
              <Dropwdown
                title="Valid For Days / Months"
                required={true}
                placeholder="Select Days / Months"
                options={[
                  { label: "Days", value: "Days" },
                  { label: "Months", value: "Months" },
                ]}
                value={voucherData?.valid_for}
                error={voucherError.valid_for}
                name="valid_for"
                onChange={(e) => {
                  handlevoucherData(e);
                  setvoucherError({
                    ...voucherError,
                    valid_for: false,
                  });
                }}
                onBlur={() => {
                  if (!voucherData?.valid_for) {
                    setvoucherError({
                      ...voucherError,
                      valid_for: true,
                    });
                  } else {
                    setvoucherError({
                      ...voucherError,
                      valid_for: false,
                    });
                  }
                }}
              />
            </div>
            <Dropwdown
              required
              title="Valid Till"
              //  type='number'
              options={[
                { label: "14 Days", value: "14 Days" },
                { label: "1 Months", value: "1 Months" },
                { label: "2 Months", value: "2 months" },
                { label: "3 Months", value: "3 Months" },
                { label: "4 Months", value: "4 Months" },
                { label: "6 Months", value: "6 Months" },
                { label: "8 Months", value: "8 Months" },
                { label: "1 Years", value: "1 Years" },
                { label: "18 Months", value: "18 Months" },
                { label: "2 Years", value: "2 Years" },
                { label: "5 Years", value: "5 Years" },
              ]}
              placeholder="Select Discount Duration"
              name="validity"
              value={voucherData?.validity}
              onEnterSubmit={() => {
                onUpdate(voucherData);
              }}
              error={voucherError.validity}
              onChange={(e) => {
                handlevoucherData(e);
              }}
              onBlur={() => {
                if (!voucherData?.validity) {
                  setvoucherError({
                    ...voucherError,
                    validity: true,
                  });
                } else {
                  setvoucherError({
                    ...voucherError,
                    validity: false,
                  });
                }
              }}
            />
          </div>
          <div className="grid lg:grid-cols-2 gap-4">
            <div className="flex flex-col gap-2">
              <LoginInput
                title="No. of Sales"
                required={true}
                placeholder="No. of Sales"
                value={voucherData?.sales}
                onEnterSubmit={() => {
                  onUpdate(voucherData);
                }}
                error={voucherError.sales}
                name="sales"
                onChange={(e) => {
                  handlevoucherData(e);
                  setvoucherError({
                    ...voucherError,
                    sales: false,
                  });
                }}
                onBlur={() => {
                  if (!voucherData?.sales) {
                    setvoucherError({
                      ...voucherError,
                      sales: true,
                    });
                  } else {
                    setvoucherError({
                      ...voucherError,
                      valid_for: false,
                    });
                  }
                }}
              />
            </div>
            <LoginInput
              required
              title="Set a Price"
              type="number"
              placeholder="Enter price"
              name="price"
              value={voucherData?.price}
              onEnterSubmit={() => {
                onUpdate(voucherData);
              }}
              error={voucherError.price}
              onChange={(e) => {
                handlevoucherData(e);
              }}
              onBlur={() => {
                if (!voucherData?.price) {
                  setvoucherError({
                    ...voucherError,
                    price: true,
                  });
                } else {
                  setvoucherError({
                    ...voucherError,
                    price: false,
                  });
                }
              }}
            />
          </div>

          <LoginBtn
            text="Submit"
            disabled={
              !voucherData?.name ||
              !voucherData?.value ||
              !voucherData?.voucher_type ||
              !voucherData?.valid_for ||
              !voucherData?.validity ||
              !voucherData?.sales
            }
            onClick={() => {
              onUpdate(voucherData);
              setopen("true");
            }}
          />
        </div>
      ) : (<>

        <div className={`border justify-between relative rounded-[0.625rem]`}>
          <div className="flex items-center gap-4 p-4">

            <div className="absolute top-2 right-2">
              <div className="cursor-pointer" onClick={() => veiw_details()}>
                <Svgs.INFO />
              </div>
            </div>

            <div>
              <div className="h-[6rem] w-[6rem] overflow-hidden flex items-center justify-center rounded-[0.625rem]">
                <Svgs.Gift />
              </div>
            </div>

            <div className="">
              <h1 className="font-semibold ">{data?.name}</h1>
              <div className="">
                <p className="text-[#5679ff] text-xs font-semibold">
                  {data?.currency_voucher?.find((itm) => itm?.currency === LocationCurrency)?.price ?
                    <>
                      {data?.currency_voucher?.find((itm) =>
                        itm?.currency === LocationCurrency)?.price} {data?.currency_voucher?.find((itm) => itm?.currency === LocationCurrency) ? locationDetail?.currency?.code : "AED"}
                    </>
                    : ""
                  }
                </p>
                <p className="text-[#555555] text-xs">
                  {selectedLanguage?.name == "English" ? "Get" : translation[299]?.value ? translation[299]?.value : "Get"}
                  {" "}
                  {data?.voucher_type}s
                  {" "}
                  {selectedLanguage?.name == "English" ? "with" : translation[300]?.value ? translation[300]?.value : "with"}
                  {" "}
                  {data?.discount_percentage} %
                  {" "}
                  {selectedLanguage?.name == "English" ? "discount" : translation[221]?.value ? translation[221]?.value : "discount"}
                  {" "}
                </p>
              </div>
            </div>
          </div>

          <div className="border-t-2 border-[#CFCFDC] border-dashed px-4 py-2.5 flex items-center justify-between">
            <div>
              <p className="text-[#555555] text-xs">
                {selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}: {" "}
                <span className="text-[#5679ff]">
                  {data?.validity} {" "} {data?.valid_for}
                </span>
              </p>
            </div>
            {(!employee || (employee && employeePermissions?.sales_apply_offer?.includes("create")) || employeePermissions?.sales_apply_offer?.includes("edit")) ? (
              <LoginBtn
                className={'ml-auto !py-2'}
                onClick={() => {
                  setOpenCart(true)
                  onClick()
                }}
                text={'Add to Cart'}
              />
            ) : (
              ""
            )}
          </div>
        </div>



        {/* OLD UI */}
        {/* <div className="relative z-0">
            <div
              className={
                "odd:bg-white even:bg-[#F7F8FF] rounded-xl shadow border lg:w-[17rem] relative w-[14rem] z-0"
              }
            >
              <div>
                {(!employee || (employee && employeePermissions?.sales_apply_offer?.includes("create")) || employeePermissions?.sales_apply_offer?.includes("edit")) ? (
                  <div className="absolute top-2 right-2">
                    <LoginBtn
                      onClick={() => {
                        setOpenCart(true)
                        onClick()
                      }}
                      text={<Svgs.Plus color="#fff" />}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="flex flex-col gap-1 p-3 justify-center">
                  <div className="flex justify-center">
                    <div className="flex flex-col gap-2">
                      <div className="flex items-start justify-center">
                        <CircleIcon>
                          <Svgs.VoucherCard />
                        </CircleIcon>
                      </div>
                      <h1 className="text-primary font-bold text-lg text-center line-clamp-2">
                        {data?.name}
                      </h1>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-2">
                    <p className="text-primary font-semibold text-base flex items-center justify-start">
                      {data?.currency_voucher?.find((itm) => itm?.currency === LocationCurrency)?.price ?
                        <>
                          {data?.currency_voucher?.find((itm) =>
                            itm?.currency === LocationCurrency)?.price} {data?.currency_voucher?.find((itm) => itm?.currency === LocationCurrency) ? locationDetail?.currency?.code : "AED"}
                        </>
                        : ""
                      }
                    </p>
                  </div>
                </div>
                <div className="bg-[#F7F9FF] px-6 py-4 flex flex-col gap-2 text-sm">

                  <p className="text-[#555555]">
                    {selectedLanguage?.name == "English" ? "Get" : translation[299]?.value ? translation[299]?.value : "Get"}
                    {" "}
                    {data?.voucher_type}s
                    {" "}
                    {selectedLanguage?.name == "English" ? "with" : translation[300]?.value ? translation[300]?.value : "with"}
                    {" "}
                    {data?.discount_percentage} %
                    {" "}
                    {selectedLanguage?.name == "English" ? "discount" : translation[221]?.value ? translation[221]?.value : "discount"}
                    {" "}
                  </p>

                  <p className="text-primary">
                    {selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"} : {" "}
                    {data?.validity} {" "} {data?.valid_for}
                  </p>
                </div>
              </div>
              <div className="py-2 text-center">

                <p
                  className="text-sm underline cursor-pointer text-[#949494]"
                  onClick={() => veiw_details()}
                >
                  {selectedLanguage?.name == "English" ? "View Details" : translation[244]?.value ? translation[244]?.value : "View Details"}
                </p>
              </div>
            </div>
          </div> */}
        {/* OLD UI */}
      </>
      )}
























    </>
  );
};

VoucherCard.defaultProps = {
  data: {},
  index: undefined,
  onEditClick: () => { },
  onUpdate: () => { },
};

export default VoucherCard;
