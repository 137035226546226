/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "new-components/location/helper";

import { getEmployeeDropDownList, getEmployeeDropDownListPagination } from "Adapters/Api/employee";
import { MONTHS } from "Constants/Data/Months";
import { get_staff_target_kpi, get_staff_target_kpi_without_pagination } from "Adapters/Api/staffTarget";
import { toast } from "react-toastify";

let timeOutId = undefined
let employeeTimeOutId = undefined

export const useReports = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locationDetail } = useLocation();

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const { selected_location } = state?.locations;
  const reportStatus = state?.staffTarget?.staffTargetReports_updated;
  const reports = state?.staffTarget?.staffTargetReports;
  const allReports = state?.staffTarget?.allStaffTargetReports;
  const pages = state?.staffTarget?.staffTargetReportsPages;
  const allCount = state?.staffTarget?.staffTargetReportsAllCount;
  const locations = state?.locations?.locations;
  const location = locations?.find((obj) => obj?.id === selected_location);
  const currencyOfSelectedLocation = location?.currency?.code;
  const PageSize = 10

  let date = new Date();
  let today_year = date.getFullYear();
  let month = date.getMonth();

  const [filterStaff, setFilterStaff] = useState("");
  const [filterYear, setFilterYear] = useState(today_year);
  const [filterMonth, setFilterMonth] = useState(month);
  const [loading, setLoading] = useState(true);
  const [noPaginationLoader, setNoPaginationLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const filter = { staff: 'filterStaff', year: 'filterYear', month: 'filterMonth' }
  const [dataList, setDataList] = useState([]);

  let selectedd_location = locationDetail?.id
  const employeeRedux = state?.employee?.employeesDropdown;
  const employeePaginationData = state?.employee?.employeeDropdownPaginationData


  const [employeeSearch, setEmployeeSearch] = useState("");
  const [employeePage, setEmployeePage] = useState(1)
  const [employeeLoader, setEmployeeLoader] = useState(false)

  useEffect(() => {
    if (selected_location) {
      if (employeeSearch === null) {
        // Initial load with empty search_text
        dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
      } else {
        if (employeeTimeOutId) {
          clearTimeout(employeeTimeOutId)
        }
        employeeTimeOutId = setTimeout(() => {
          dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
        }, 500);
      }
    }
  }, [employeeSearch, selected_location, employeePage])

  //fetch

  useEffect(() => {
    let loader = () => {
      setLoading(true)
    }
    let success = () => {
      setLoading(false);
    }
    let fail = () => {
      setLoading(false);
    }

    if (selectedd_location) {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(get_staff_target_kpi(selectedd_location, currentPage, filterMonth + 1, filterYear, filterStaff, loader, success, fail));
      }, 500);
    }
  }, [filterMonth, filterYear, currentPage, selectedd_location, filterStaff])

  useEffect(() => {
    setCurrentPage(1)
    setLoading(true)
    setNoPaginationLoader(true)
  }, [filterMonth, filterYear, selectedd_location, filterStaff])

  useEffect(() => {
    let loader = () => {
      setNoPaginationLoader(true)
    }
    let success = () => {
      setNoPaginationLoader(false);
    }
    let fail = () => {
      setNoPaginationLoader(false);
    }
    if (selectedd_location) {
      dispatch(get_staff_target_kpi_without_pagination(selectedd_location, filterMonth + 1, filterYear, filterStaff, loader, success, fail));
    }
  }, [selectedd_location, filterMonth + 1, filterYear, filterStaff])

  useMemo(() => {
    // const val = reports?.map((element) => {
    //   const data = element?.location?.filter(
    //     ({ id }) => id === selected_location
    //   );
    //   if (data?.length > 0 && data !== undefined) return { ...element };
    // });
    // const reportsArr = val.filter((element) => element !== undefined);
    // setDataList(reportsArr);
    setDataList(reports)
  }, [reports, selected_location, filterMonth, filterYear])


  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case filter.staff:
        setFilterStaff(value);
        break;
      case filter.year:
        setFilterYear(value);
        break;
      case filter.month:
        setFilterMonth(value);
        break;
      default:
    }
  }

  const employeeDropdown = useMemo(() => {
    return [{ label: selectedLanguage?.name == "English" ? 'All Employee' : translation[122]?.value ? translation[122]?.value : "All Employee", value: "" }, ...employeeRedux.map((empl, i) => {
      return {
        label: empl.full_name,
        value: empl.id,
        otherData: {
          image: empl?.image,
          DataOne: empl.email,
          showImage: true,
          index: i
        },
        email: empl.email,
        mobile_number: empl.mobile_number
      }
    })]
  })

  const yearDropdown = useMemo(() => {
    if (today_year) {
      let years = [];
      for (let i = today_year; i <= 2040; i++) {
        years.push({ label: i.toString(), value: i.toString() })
      }
      return years;
    }
  }, [today_year])

  const monthDropdown = useMemo(() => {
    return MONTHS?.map((month, i) => {
      return {
        label: month,
        value: i,
      };
    })
  }, [])

  const reportsFilter = () => {
    // const staffTargetfilterData = dataList?.filter((item) => {
    //   if (filterStaff) {
    //     let return_value = false;
    //     if (filterStaff) {
    //       if (
    //         item?.id
    //           ?.toLowerCase()
    //           .includes(filterStaff?.toLowerCase())
    //       ) {
    //         return true;
    //       } else {
    //         return false;
    //       }
    //     }
    //     return return_value;
    //   }
    //   return true;
    // });
    const staffTargetfilterData = dataList
    return staffTargetfilterData;
  };
  //export csv
  let headers = [
    { label: selectedLanguage?.name == "English" ? 'Staff' : translation[335]?.value ? translation[335]?.value : "Staff", key: "full_name" },
    { label: selectedLanguage?.name == "English" ? 'Total Service Target' : translation[371]?.value ? translation[371]?.value : "Total Service Target", key: "staff_target" },
    { label: selectedLanguage?.name == "English" ? 'Total Service Sales' : translation[372]?.value ? translation[372]?.value : "Total Service Sales", key: "service_sale_price" },
    { label: selectedLanguage?.name == "English" ? 'Total Retail Target' : translation[373]?.value ? translation[373]?.value : "Total Retail Target", key: "staff_target" },
    { label: selectedLanguage?.name == "English" ? 'Total Retail Sales' : translation[374]?.value ? translation[374]?.value : "Total Retail Sales", key: "product_sale_price" },
    { label: selectedLanguage?.name == "English" ? 'Year' : translation[376]?.value ? translation[376]?.value : "Year", key: "year" },
    { label: selectedLanguage?.name == "English" ? 'Month' : translation[377]?.value ? translation[377]?.value : "Month", key: "month" },
  ];

  const csvReport = {
    filename: "Staff Target Report.csv",
    headers: headers,
    data: allReports?.map((item) => {
      const { full_name, staff_target, service_sale_price, product_sale_price } = item;

      return {
        ...item,
        full_name: item?.full_name ? full_name : "--------",
        staff_target: item?.staff_target ? `${item?.staff_target?.service_target?.toFixed(2)} ${currencyOfSelectedLocation}` : "--------",
        service_sale_price: item?.service_sale_price ? `${parseFloat(item?.service_sale_price)?.toFixed(2)} ${currencyOfSelectedLocation}` : "--------",
        retailTarget: item?.retailTarget ? `${item?.staff_target?.retail_target?.toFixed(2)} ${currencyOfSelectedLocation}` : "--------",
        product_sale_price: item?.product_sale_price ? `${parseFloat(item?.product_sale_price)?.toFixed(2)} ${currencyOfSelectedLocation}` : "--------",
        year: filterYear ? filterYear : "--------",
        month: filterMonth ? MONTHS[filterMonth] : "--------",
      };
    }),
  };


  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allReports?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  return {
    reportStatus, filterStaff, filterMonth, filterYear, filter, employeeDropdown, yearDropdown, monthDropdown,
    currencyOfSelectedLocation, csvReport, loading, reportsFilter, navigate, handleChange, translation, selectedLanguage,
    currentPage, setCurrentPage, pages, allCount, PageSize, allReports, handleExportClick, exportLoader,
    employeeSearch, setEmployeeSearch, employeePage, setEmployeePage, employeeLoader, setEmployeeLoader, employeePaginationData

  }
};
