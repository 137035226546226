import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import React from 'react'
import LineChart from './LineChart'
import { useSelector } from 'react-redux'


const SaleOvertime = () => {
    const state = useSelector((state) => state)

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language



    return (
        <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
            <div className='flex flex-col gap-6'>
                <h2 class="font-semibold text-2xl">
                    {selectedLanguage?.name == "English" ? "Sales Overtime" : translation[1153]?.value ? translation[1153]?.value : "Sales Overtime"}
                </h2>
                <div className='flex flex-col gap-4'>
                    <div className='flex items-center gap-3 justify-between'>
                        <div className='flex items-center gap-3'>
                            <Dropwdown
                                title={''}
                                placeholder={selectedLanguage?.name == "English" ? "Last 30 days" : translation[1134]?.value ? translation[1134]?.value : "Last 30 days"}
                            />
                            <Dropwdown
                                title={''}
                                placeholder={selectedLanguage?.name == "English" ? "Compare" : translation[1145]?.value ? translation[1145]?.value : "Compare"}
                            />
                            <Dropwdown
                                title={''}
                                placeholder={selectedLanguage?.name == "English" ? "Monthly" : translation[1146]?.value ? translation[1146]?.value : "Monthly"}
                            />
                        </div>
                        <div className='flex items-center gap-3'>
                            <div className='flex items-center gap-2'>
                                <div>
                                    <p className='text-black/60 text-sm'>
                                        {selectedLanguage?.name == "English" ? "Filter by" : translation[1147]?.value ? translation[1147]?.value : "Filter by"} :
                                    </p>
                                </div>
                                <Dropwdown
                                    title={''}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                                />
                            </div>
                            <span>-</span>
                            <Dropwdown
                                title={''}
                                placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[1704]?.value ? translation[1704]?.value : "Select Stores"}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className='border rounded-xl p-5'>
                        <h1 className='font-semibold text-xl mb-4'>
                            {selectedLanguage?.name == "English" ? "Total Sales" : translation[146]?.value ? translation[146]?.value : "Total Sales"}
                        </h1>
                        <LineChart />
                    </div>
                </div>
                <h2 class="font-semibold text-2xl">
                    {selectedLanguage?.name == "English" ? "Results" : translation[1144]?.value ? translation[1144]?.value : "Results"}
                </h2>
                <div>
                    <div className='border rounded-xl overflow-auto'>
                        <div className='rounded-xl grid grid-cols-[repeat(8,minmax(115px,1fr))] px-12 py-3 bg-[#fff] text-sm font-semibold min-w-full w-fit'>
                            <p>{selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Duties" : translation[1148]?.value ? translation[1148]?.value : "Duties"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Orders" : translation[1149]?.value ? translation[1149]?.value : "Orders"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Gross sales" : translation[1150]?.value ? translation[1150]?.value : "Gross sales"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Net sales" : translation[1151]?.value ? translation[1151]?.value : "Net sales"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Tax" : translation[733]?.value ? translation[733]?.value : "Tax"}</p>
                            <p>{selectedLanguage?.name == "English" ? "Total Sales" : translation[146]?.value ? translation[146]?.value : "Total Sales"}</p>
                        </div>
                        <div className='grid grid-cols-[repeat(8,minmax(115px,1fr))] px-12 py-3 bg-[#E0EBFD] text-sm  min-w-full w-fit'>
                            <div>
                                <p>Jan 2023</p>
                                <p>Jan 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <p>1</p>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-[repeat(8,minmax(115px,1fr))] px-12 py-3 odd:bg-white bg-[#F8F8FA] text-sm  min-w-full w-fit'>
                            <div>
                                <p>Jan 2023</p>
                                <p>Jan 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <p>1</p>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-[repeat(8,minmax(115px,1fr))] px-12 py-3 odd:bg-white bg-[#F8F8FA] text-sm  min-w-full w-fit'>
                            <div>
                                <p>Jan 2023</p>
                                <p>Jan 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <p>1</p>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-[repeat(8,minmax(115px,1fr))] px-12 py-3 odd:bg-white bg-[#F8F8FA] text-sm  min-w-full w-fit'>
                            <div>
                                <p>Jan 2023</p>
                                <p>Jan 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <p>1</p>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                            <div>
                                <p>AED 2023</p>
                                <p>AED 2023</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SaleOvertime