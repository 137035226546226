import { useEffect } from "react"
import { BundleFixedPriceCard } from "./BundleFixedPrice"
import { ComplimentaryVoucherCard } from "./ComplimentaryVoucher"


import { DirectFlatCard } from "./DirectFlat"
import { DiscountOnBrandNServiceGroupCard } from "./DiscountOnBrandNServiceGroup"
import { FixedPriceServiceCard } from "./FixedPriceService"
import { FreeItemForOtherPurchaseCard } from "./FreeItemForOtherPurchase"
import { FreeItemSpendAmountCard } from "./FreeItemSpendAmount"
import { PackagesPromotionsCard } from "./Packages"
import { PurchaseAndDiscountCard } from "./PurchaseNDiscount"
import { SpecificBrandServiceGroup_Card } from "./SpecificGroup"
import { UserRestrictedDiscountCard } from "./UserRestrictedDiscount"


const PromotionDisplayAppointmentCard = ({ data, servicesRedux, onRemoveOffer }) => {


    // useEffect(() =>{
    // console.log("Data =>", data)
    // } , [])

    switch (data.type) {
        case "Direct Or Flat":
            return <DirectFlatCard data={data} selected={true} servicesRedux={servicesRedux} onRemoveOffer={onRemoveOffer} />

        case "Specific Group Discount":
            return <SpecificBrandServiceGroup_Card data={data} selected={true} servicesRedux={servicesRedux} onRemoveOffer={onRemoveOffer} />

        case "Mentioned_Number_Service":
            return <FreeItemForOtherPurchaseCard data={data} selected={true} servicesRedux={servicesRedux} onRemoveOffer={onRemoveOffer} />

        case "Purchase Discount":
            return <PurchaseAndDiscountCard data={data} selected={true} servicesRedux={servicesRedux} onRemoveOffer={onRemoveOffer} />

        case "Specific Brand Discount":
            return <DiscountOnBrandNServiceGroupCard data={data} selected={true} servicesRedux={servicesRedux} onRemoveOffer={onRemoveOffer} />

        case "Fixed_Price_Service":
            return <FixedPriceServiceCard data={data} selected={true} servicesRedux={servicesRedux} onRemoveOffer={onRemoveOffer} />

        case "Bundle_Fixed_Service":
            return <BundleFixedPriceCard data={data} selected={true} servicesRedux={servicesRedux} onRemoveOffer={onRemoveOffer} />

        case "Spend_Some_Amount":
            return <FreeItemSpendAmountCard data={data} selected={true} servicesRedux={servicesRedux} onRemoveOffer={onRemoveOffer} />

        case "User_Restricted_discount":
            return <UserRestrictedDiscountCard data={data} selected={true} servicesRedux={servicesRedux} onRemoveOffer={onRemoveOffer} />

        case "Complimentary_Discount":
            return <ComplimentaryVoucherCard data={data} selected={true} servicesRedux={servicesRedux} onRemoveOffer={onRemoveOffer} />

        case "Packages_Discount":
            return <PackagesPromotionsCard data={data} selected={true} servicesRedux={servicesRedux} onRemoveOffer={onRemoveOffer} />


        default:
            return <></>;
    }
}

export default PromotionDisplayAppointmentCard