import { CALENDAR_OPENING_TIME } from 'Constants/Data/OpeningHours';
import React from 'react'
import { useState } from 'react';

const CalenderShimmer = () => {
    const [StartTime, setStartTime] = useState(CALENDAR_OPENING_TIME);

    return (
        <>
            <div className="overflow-auto calendar_parent relative h-full w-full border-t animate-pulse">
                <div className="flex ">
                    <div className="sticky left-0 z-[2] bg-white">
                        <div className="h-[6rem] w-[7rem] flex items-center justify-center border-r border-b top-0 bg-white sticky z-[2]">
                            <div className="py-4 w-4/5 rounded-lg bg-gray-200"></div>
                        </div>
                        {StartTime.map((time, index) => {
                            if (index < 40)
                                return (
                                    <div
                                        className={`time_block block-${index} h-[1rem] w-[7rem] ${(index) % 3 == 0 && index != 0 && 'border-t'} border-r flex items-center justify-center`}
                                        key={index}
                                        time={time.value}
                                    >
                                        <h1
                                            className={`text-[#5C5C5C] hidden ${index % 3 == 0 &&
                                                "translate-y-[1rem] !block text-[0.6rem] font-semibold"
                                                }`}
                                        >
                                            <div className="py-4 w-24 rounded-lg bg-gray-200"></div>
                                        </h1>
                                    </div>
                                );
                        })}
                    </div>
                    <div className="flex-1 relative box-border">
                        {/* Calendar Structure Starts here */}
                        <div
                            className="grid absolute h-full w-full"
                            style={{
                                gridTemplateColumns: `repeat(4,minmax(12rem,2fr))`,
                            }}
                        >
                            {[0, 1, 2, 3]?.map((obj, i) => {
                                return (
                                    <div
                                        className={`border-r grid`}
                                        style={{
                                            gridTemplateRows: `repeat(30,1rem)`,
                                        }}
                                    >
                                        <div className="row-span-6 flex items-center justify-center sticky top-0 w-full bg-white z-[1] border-b">
                                            <div className="flex flex-col gap-1.5 w-full">
                                                <div className="h-[3rem] w-[3rem] rounded-full overflow-hidden mx-auto border bg-gray-200">

                                                </div>
                                                <div className="py-3 mx-auto w-3/5 rounded-lg bg-gray-300"></div>

                                            </div>
                                        </div>

                                        {StartTime.map((time, index) => {
                                            if (index < 42)
                                                return (
                                                    <div
                                                        className={`time_block border-slate-100 block-${index} h-[1rem] bg-gray-200 w-full ${(index) % 3 == 0 && index != 0 && 'border-t'} border-r flex items-center justify-center`}
                                                        key={index}
                                                        time={time.value}
                                                    >
                                                        <h1
                                                            className={`text-[#5C5C5C] hidden ${index % 3 == 0 &&
                                                                "translate-y-[1rem] !block text-[0.6rem] font-semibold"
                                                                }`}
                                                        >
                                                            <div className="py-4 w-full rounded-lg bg-gray-200"></div>
                                                        </h1>
                                                    </div>
                                                );
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CalenderShimmer