import CheckBox from 'Components/TenantApp/Elements/Checkbox/CheckBox'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import React from 'react'
import { useAddStoreTarget } from "./helper";
import useStoreTarget from "../helper";
import { MONTHS } from 'Constants/Data/Months';
import FloatInput from 'Components/TenantApp/Elements/FloatInput';

const AddTarget = ({ store_tier, formRef, setTierError, index, ties, id, onUpdate, errors, onBlurHandler, yearDropdown, data, location, currency, selectedLanguage, translation }) => {
    //handle change
    const handleChange = (e, index, is_primary = "") => {

        const { name, value } = e.target;
        if (is_primary === "is_primary") {
            onUpdate({ name: "is_primary", value: e?.target?.checked })
        }
        else {
            onUpdate({ name: name, value: value })
        }

        if (errors?.length > 0) {
            const updatedFormErrors = [...errors];
            // Check if the value is zero and update the error message
            if (name && parseInt(value, 10) === 0) {
                updatedFormErrors[index] = {
                    ...updatedFormErrors[index],
                    [name]: 'Value must be greater than 0.',
                };
            } else {
                // Clear the error for the changed field
                updatedFormErrors[index] = {
                    ...updatedFormErrors[index],
                    [name]: '',
                };
            }
            setTierError(updatedFormErrors);
        }

        // let all_errors = [];
        // (store_tier)?.forEach((item, index) => {
        //     let err_obj = {}
        //     if (item.year && item.month && item?.retail_target && item.membership_target && item?.voucher_target && item?.service_target && store_tier?.every(
        //         (itm) =>
        //             itm?.year !== "" &&
        //             itm?.month !== "" &&
        //             itm?.retail_target !== 0 &&
        //             itm?.membership_target !== 0 &&
        //             itm?.voucher_target !== 0 &&
        //             itm?.service_target !== 0
        //     )) {
        //     } else {
        //         if (name == "retail_target") {
        //             err_obj.onChange = "retail_target"
        //         } else if (name == "service_target") {
        //             err_obj.onChange = "service_target"
        //         }
        //         else if (name == "membership_target") {
        //             err_obj.onChange = "membership_target"
        //         }
        //         else if (name == "voucher_target") {
        //             err_obj.onChange = "voucher_target"
        //         }
        //         else if (name == "year") {
        //             err_obj.onChange = "year"
        //         }
        //         else if (name == "month") {
        //             err_obj.onChange = "month"
        //         }
        //         err_obj.retail_target = item.retail_target !== '0' ? !(item.retail_target) : '0'
        //         err_obj.membership_target = item.membership_target !== '0' ? !(item.membership_target) : '0'
        //         err_obj.voucher_target = item.voucher_target !== '0' ? !(item.voucher_target) : '0'
        //         err_obj.service_target = item.service_target !== '0' ? !(item.service_target) : '0'
        //         err_obj.year = item.year !== '' ? !(item.year) : ''
        //         err_obj.month = item.month !== '' ? !(item.month) : ''
        //         err_obj.index = index
        //         all_errors.push(err_obj)
        //         formRef.current?.scrollIntoView({ behavior: "smooth" });
        //     }
        // });
        // setTierError(all_errors)
    }

    const {
        store_target_fiter,
    } = useStoreTarget();

    const {
        locationDetail
    } = useAddStoreTarget();


    let Month_value = []
    let Year_value = []

    store_target_fiter()?.forEach((str) => {
        // let teirs = str.tier.filter((tier) => tier?.is_primary)
        if (str?.location?.id == location) {
            let teirs_months = str?.tier?.map(tier => tier?.month)
            Month_value = [
                ...Month_value,
                ...teirs_months
            ]
            let teirs_years = str?.tier?.map(tier => tier?.year?.slice(0, 4))
            Year_value = [
                ...Year_value,
                ...teirs_years
            ]
        }
    })

    let filteredErrorObject = {}
    filteredErrorObject = errors?.length > 0 ? errors?.find(itm => itm?.index == index) : {}

    return (
        <>
            <h1 className='font-semibold text-lg'>
                {selectedLanguage?.name == "English" ? "Tier" : translation[861]?.value ? translation[861]?.value : "Tier"}{" "} {id}
            </h1>
            <div className='grid md:grid-cols-2 gap-4'>
                <Dropwdown
                    title={selectedLanguage?.name == "English" ? "Year" : translation[376]?.value ? translation[376]?.value : "Year"}
                    placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                    name="year"
                    value={ties?.year}
                    onChange={(e) => { handleChange(e, index) }}
                    // onBlur={() => onBlurHandler({ month: ties?.month })}
                    error={(filteredErrorObject?.year && !ties?.year) && filteredErrorObject?.year}
                    options={yearDropdown}
                />
                <Dropwdown
                    title={selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}
                    placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                    name="month"
                    value={ties?.month}
                    onChange={(e) => { handleChange(e, index) }}
                    options={
                        !ties?.month ? MONTHS.filter(dt => ((Year_value.includes(ties?.year) ? !Month_value.includes(dt) : true) && !data.find((ele) => ((ele.month === dt) && (ele?.year ? ele?.year == ties?.year : true))))).map((mnth) => {
                            return {
                                label: mnth,
                                value: mnth,
                            };
                        }) :
                            [{ label: ties?.month, value: ties?.month },
                            ...MONTHS.filter(dt => !data.find((ele) => ((ele.month === dt) && (ele?.year ? ele?.year == ties?.year : true))))?.map((mnth) => {
                                return {
                                    label: mnth,
                                    value: mnth,
                                }
                            })
                            ]
                    }
                    error={(ties?.year && filteredErrorObject?.month && ties?.month == '') ? true : false}
                    disabled={ties?.year ? false : true}
                />
                <FloatInput
                    title={selectedLanguage?.name == "English" ? "Retail Target" : translation[819]?.value ? translation[819]?.value : "Retail Target"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter Retail Target" : translation[843]?.value ? translation[843]?.value : "Enter Retail Target"}
                    rightIcon={currency}
                    name="retail_target"
                    currencyCode={'CAD'}
                    value={ties?.retail_target}
                    onChange={(e) => { handleChange(e, index) }}
                    // onBlur={() => onBlurHandler({ retail_target: ties?.retail_target })}
                    error={filteredErrorObject?.retail_target}
                />
                <FloatInput
                    title={selectedLanguage?.name == "English" ? "Service Target" : translation[819]?.value ? translation[819]?.value : "Service Target"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter Service Target" : translation[843]?.value ? translation[843]?.value : "Enter Service Target"}
                    rightIcon={currency}
                    name="service_target"
                    value={ties?.service_target}
                    currencyCode={'CAD'}
                    onChange={(e) => { handleChange(e, index) }}
                    // onBlur={() => onBlurHandler({ service_target: ties?.service_target })}
                    error={filteredErrorObject?.service_target}
                />
                <FloatInput
                    title={selectedLanguage?.name == "English" ? "Voucher Target" : translation[856]?.value ? translation[856]?.value : "Voucher Target"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter Voucher Target" : translation[857]?.value ? translation[857]?.value : "Enter Voucher Target"}
                    rightIcon={currency}
                    name="voucher_target"
                    currencyCode={'CAD'}
                    value={ties?.voucher_target}
                    onChange={(e) => { handleChange(e, index) }}
                    // onBlur={() => onBlurHandler({ voucher_target: ties?.voucher_target })}
                    error={filteredErrorObject?.voucher_target}
                />
                <LoginInput
                    title={selectedLanguage?.name == "English" ? "Membership Target" : translation[858]?.value ? translation[858]?.value : "Membership Target"}
                    placeholder={selectedLanguage?.name == "English" ? "Enter Membership Target" : translation[859]?.value ? translation[859]?.value : "Enter Membership Target"}
                    rightIcon={currency}
                    name="membership_target"
                    value={ties?.membership_target}
                    onChange={(e) => { handleChange(e, index) }}
                    // onBlur={() => onBlurHandler({ membership_target: ties?.membership_target })}
                    error={filteredErrorObject?.membership_target}
                />
            </div>

            {ties?.is_primary === true &&
                <CheckBox
                    label={selectedLanguage?.name == "English" ? "Make this target primary" : translation[860]?.value ? translation[860]?.value : "Make this target primary"}
                    checked={ties.is_primary}
                    onChange={(e) => { handleChange(e, '', "is_primary"); }}
                />
            }

            {   // it will show to all elements, when i check at any field this condition becomes false
                data?.filter((obj) => obj?.is_primary === true)?.length == 0 &&
                <CheckBox
                    label={selectedLanguage?.name == "English" ? "Make this target primary" : translation[860]?.value ? translation[860]?.value : "Make this target primary"}
                    checked={ties.is_primary}
                    onChange={(e) => { handleChange(e, '', "is_primary"); }}
                />
            }
        </>
    )
}

export default AddTarget