import React from "react";

import Popup from "new-components/popup";
import { useSelector } from "react-redux";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";

const DeleteConfirmation = ({
  heading,
  description,
  handleClose,
  handleDelete,
  isLoading,
  title,
}) => {

  const state = useSelector((state) => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  return (
    <Popup
      normalCase={'normal-case'}
      heading={
        heading ?
          heading : selectedLanguage?.name == "English" ? "Are you sure you want to delete this Service?" :
            translation[652]?.value ? translation[652]?.value : "Are you sure you want to delete this Service?"
      }
      handleClose={handleClose}
    >
      <div>
        <p>
          {
            description ?
              description :
              `${selectedLanguage?.name == "English"
                ? "Are you sure you want to delete this"
                : translation[744]?.value
                  ? translation[744]?.value
                  : "Are you sure you want to delete this"} 
                  ${title ? title : ""}

                ${selectedLanguage?.name == "English" ? "service?" : translation[31]?.value ? translation[31]?.value : "service?"}

                ${selectedLanguage?.name == "English"
                ? "You won't be able to see this again."
                : translation[745]?.value
                  ? translation[745]?.value
                  : "You won't be able to see this again."}`
          }
        </p>
        <div className="flex items-center justify-end mt-[2rem]">
          <LoginBtn
            deleteBtn
            text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
            loading={isLoading}
            onClick={handleDelete}
            disabled={isLoading}
          />
        </div>
      </div>
    </Popup>
  );
};

export default DeleteConfirmation;
