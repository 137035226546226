
import axios from "axios";
import { BASE_URL, create_service_target, get_service_target, update_service_target, delete_service_target, copy_service_target, get_service_target_report_url } from "../api_variables";
import { toast } from 'react-toastify';
import { SERVICE_TARGET } from "Redux/TenantRedux/ActionTypes/serviceTargetTypes";
import { apiErrorMessage } from "Constants/Data/Errors";

export const getServiceTarget = (selected_location, currentPage, searchText, monthFilter, searchYear, serviceGroup, setLoader) => dispatch => {
    setLoader && setLoader(true)
    const location = selected_location ? `?location_id=${selected_location}` : ''
    const current_Page = currentPage ? `&page=${currentPage}` : '';
    const search = searchText ? `&search_text=${searchText}` : ''
    const month = monthFilter ? `&month=${monthFilter}` : ''
    const year = searchYear ? `&year=${searchYear}` : ''
    const service = serviceGroup ? `&search_text=${serviceGroup}` : ''
    let s_code;
    fetch(`${BASE_URL}${get_service_target}${location}${current_Page}${search}${month}${year}${service}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {

                let serviceTarget = result;
                dispatch({
                    type: SERVICE_TARGET?.GET_SERVICE_TARGET,
                    payload: serviceTarget
                })
                setLoader && setLoader(false)
            }
            else {
                toast.error(apiErrorMessage, { toastId: "toast" })
                setLoader && setLoader(false)
            }
        }).catch((err) => {
            toast.error(apiErrorMessage, { toastId: "toast" })
            setLoader && setLoader(false)
        })
};

export const getServiceTargetWithNoPagination = (selected_location) => async (dispatch) => {
    const location = selected_location ? `&location_id=${selected_location}` : ''
    try {
        const result = await axios.get(`${BASE_URL}${get_service_target}?no_pagination=true${location}`);
        if (dispatch) {
            let serviceTarget = result?.data;
            dispatch({
                type: SERVICE_TARGET.GET_SERVICE_TARGET_WITHOUT_PAGINATION,
                payload: serviceTarget
            })
        }
        return result;
    } catch (error) {
        return error;
    }
};



export const createServiceTarget = (data, access_token, success, fail) => async dispatch => {
    let form_data = new FormData();

    for (let key in data) {
        if (data[key]) {
            form_data.append(key, data[key])
        }
    }

    try {
        const result = await axios.post(`${BASE_URL}${create_service_target}`, form_data,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );

        if (result?.data?.status_code == 201) {
            dispatch({
                type: SERVICE_TARGET?.CREATE_SERVICE_TARGET,
                payload: result?.data?.response?.servicetarget
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()

    }
};


export const createCopyServiceTarget = (data, access_token, success, fail) => async dispatch => {
    let form_data = new FormData();

    for (let key in data) {
        if (data[key]) {
            form_data.append(key, data[key])
        }
    }

    try {
        const result = await axios.post(`${BASE_URL}${copy_service_target}`, form_data,
            {
                headers: { Authorization: `Token ${access_token}` },
            }
        );
        if (result?.data?.status_code == 200) {
            const response = result?.data;
            dispatch({
                type: SERVICE_TARGET?.GET_SERVICE_TARGET,
                payload: response
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()

    }
};

export const updateServiceTarget = (data, access_token, success, fail) => async dispatch => {

    let form_data = new FormData();
    for (let key in data) {
        if (data[key]) {
            form_data.append(key, data[key])
        }
    }

    try {
        const result = await axios.put(`${BASE_URL}${update_service_target}`, form_data,
            {
                headers: { 'Authorization': `Token ${access_token}` },
            });
        if (result?.data?.status_code == 200) {
            dispatch({
                type: SERVICE_TARGET.UPDATE_SERVICE_TARGET,
                payload: result?.data?.response?.servicetarget
            })
            success && success(result?.data?.response?.message)
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
    }
};


export const deleteServiceTarget = (id, access_token, success, fail) => dispatch => {

    fetch(`${BASE_URL}${delete_service_target}`, {
        method: "DELETE",
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Token ${access_token}`
        },
        body: JSON.stringify(
            { id: id }
        )
    }).then((result) => {
        return result.json()
    }).then((response) => {
        if (response.status_code === 200) {
            dispatch({
                type: SERVICE_TARGET?.DELETE_SERVICE_TARGET,
                payload: id
            });
        }
        success && success(response?.response?.message);
    }).catch((err) => {
        fail && fail()
    })

}

// Service Target Report KPI
export const get_service_target_kpi = (selectedd_location, currentPage, month, year, filterServiceGroup, loader, success, fail) => async (dispatch) => {
    const location = selectedd_location ? `?location_id=${selectedd_location}` : ''
    const page = currentPage ? `&page=${currentPage}` : ''
    const monthFilter = month ? `&month=${month}` : ''
    const yearFilter = year ? `&year=${year}` : ''
    const serviceGroup = filterServiceGroup ? `&service_group_id=${filterServiceGroup}` : ''
    try {
        loader && loader()
        const response = await axios.get(`${BASE_URL}${get_service_target_report_url}${location}${page}${monthFilter}${yearFilter}${serviceGroup}`);
        if (response.status === 200) {
            success && success()
            dispatch({
                type: SERVICE_TARGET?.GET_SERVICE_TARGET_REPORT,
                payload: response?.data
            });
        } else {
            toast.error(apiErrorMessage, { toastId: "toast" });
        }
    } catch (error) {
        fail && fail()
        toast.error(apiErrorMessage, { toastId: "toast" });
        return error;
    }
};

// no pagination
export const get_service_target_kpi_without_pagination = (selectedd_location, month, year, filterServiceGroup, loader, success, fail) => async (dispatch) => {
    const location = selectedd_location ? `?location_id=${selectedd_location}` : ''
    const monthFilter = month ? `&month=${month}` : ''
    const yearFilter = year ? `&year=${year}` : ''
    const serviceGroup = filterServiceGroup ? `&service_group_id=${filterServiceGroup}` : ''
    try {
        loader && loader()
        const response = await axios.get(`${BASE_URL}${get_service_target_report_url}${location}&no_pagination=true${monthFilter}${yearFilter}${serviceGroup}`);
        if (response.status === 200) {
            success && success()
            dispatch({
                type: SERVICE_TARGET?.GET_SERVICE_TARGET_REPORT_WITHOUT_PAGINATION,
                payload: response?.data
            });
        } else {
            toast.error(apiErrorMessage, { toastId: "toast" });
        }
    } catch (error) {
        fail && fail()
        toast.error(apiErrorMessage, { toastId: "toast" });
        return error;
    }
};

