import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { LogoSvg } from "../../../Assets/svgs/Logo/LogoSvg";
import { toast } from "react-toastify";

import MobileVerify from "../Signup/MobileVerify";
import PersonalInfo from "../Signup/PersonalInfo";
import useSearchParamsHandle from "../../../Hooks/useSearchParamsHandle";
import {
  ACTIVES,
  PERSONAL_INFORMATION,
  VERIFICATION,
} from "../../../Constants/Variables";
import useSetDataInLocalStorage from "../../../Hooks/useSetDataInLocalStorage";
import Svgs from "../../../Assets/svgs";
import { CLIENT_SIGNUP } from "Redux/NstyleRedux/ActionTypes/signupTypes";
import useClientLandingScreenUrl from "Hooks/useClientLandingScreenUrl";
import useClientUrlFromCreatedLink from "Hooks/useClientUrlFromCreatedLink";
import usePersonlInfoForm from "../Signup/PersonalInfo/usePersonlInfoForm";
import { signupUserPersonalInfo } from "Redux/NstyleRedux/Actions/sigupAction";
import { useEffect } from "react";

export default function SignupSteps() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { urls } = useClientLandingScreenUrl();
  const {
    account_type
  } = usePersonlInfoForm();

  const info_data = useSelector((state) => state.userData.personalInfo);
  const [checkAccountType, setCheckAccountType] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { allParams, setDynamicSearchParams } = useSearchParamsHandle();
  const [ActiveStep, setActiveStep] = useState(1);
  const personal_info_state = useSelector((state) => state.userData?.personalInfo);
  const client_info_state = useSelector((state) => state.userData?.clientInfo);
  const { storeDataInLocalStorage } = useSetDataInLocalStorage();
  const { nexts, hash, token, domain } = useClientUrlFromCreatedLink();

  const signup_type = searchParams.get("signup_type");
  // const nexts = searchParams.get("next");
  // const hash = searchParams.get("hash");
  // const token = searchParams.get("token");
  // const domain = searchParams.get("domain");

  let SideBar_Data = [
    {
      title: "Personal & Business Information",
      active: ActiveStep == 1 && true,
      paramsValue: PERSONAL_INFORMATION,
      currentStep: 1,
    },
    {
      title: "Verification",
      active: ActiveStep == 2 && true,
      paramsValue: VERIFICATION,
      currentStep: 2,
    },
  ];

  let urlParams;
  if (allParams.length > 2) {
    urlParams =
      allParams && allParams?.find((param) => param.value === ACTIVES);
  }

  const getServerData = async (domain) => {
    const result = await axios.get(
      `http://${process.env.REACT_APP_SERVER_APPLICATION_DOMAIN}/ssl-certificate-nstyle-sub-domain/${domain}`
    );
  };

  useEffect(() => {
    if (ActiveStep === 1) {
      window.addEventListener('popstate', () => {
        // Run the onClick action when ActiveStep is 1
        dispatch(signupUserPersonalInfo({}));
      });
    }

  
  }, [ActiveStep, dispatch]);

  return (
    <div className="overflow-x-hidden w-full">
     
      {/* <div
        className={`col-span-1 lg:px-[2rem] lg:pt-[3rem] px-8 py-5 bg-primary lg:h-[100vh] h-fit overflow-auto scroll-hidden lg:sticky top-0 flex flex-col`}
      >
      
        <div className="">
          <LogoSvg fill="fill-[white]" />
        </div>
        <div className="flex flex-col gap-6 text-white flex-1 justify-center lg:mt-[4rem] mt-[2rem] overflow-auto">
          <div>
            <div className="flex items-center gap-3">
              {ActiveStep == 1 ?
                <Svgs.Back fill="#fff" link={`/auth/signup/?account_type=${account_type.toLowerCase()}`} onClick={() => { dispatch(signupUserPersonalInfo({})) }} />
                :
                <div onClick={() => setActiveStep(1)}>
                  <Svgs.Back fill="#fff" link={`/auth/signup/?account_type=${account_type.toLowerCase()}&signup_type=email&actives=personal_information`} />
                </div>
              }

              <h2 className="text-[#fff] font-bold text-3xl">
                Step {ActiveStep}
              </h2>
            </div>
          </div>
          <p className="text-[#C8D1DE]">
            Enter your personal information to get start your business.
          </p>
          <div className="flex flex-row lg:flex-col gap-4 overflow-auto scroll-hidden-lg">
            {SideBar_Data.map((data, i) => {
              const paramsObject =
                data.paramsValue &&
                setDynamicSearchParams(allParams, data.paramsValue);
              return (
                <>
                  <div
                    className={`flex items-center gap-3 ${!data.active ? "opacity-30" : ""
                      }`}
                 
                  >
                    <div
                      className={`h-[2.25rem] w-[2.25rem] border rounded-full flex justify-center items-center font-semibold `}
                    >
                      {i + 1}
                    </div>
                    <p className="lg:whitespace-normal whitespace-pre">
                      {data.title}
                    </p>
                    
                  </div>
                  {i != SideBar_Data.length - 1 ? (
                    <div
                      className={`w-[36px] flex justify-center items-center font-semibold ${!data.active ? "opacity-30" : ""
                        }`}
                    >
                      <div className="lg:h-[50px] lg:w-[1px] h-[1px] w-[50px] border-t border-dashed lg:border-l"></div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div> */}
     
      <div className="lg:pt-[3rem] w-full py-5 pb-0 overflow-x-hidden">
        {ActiveStep == 1 && (
          <PersonalInfo
            setStep={setActiveStep}
            setCheckAccountType={setCheckAccountType}
          />
        )}
         {ActiveStep == 2 && (
          <div className="">
                <MobileVerify
                    checkAccountType={checkAccountType}
                    setStep={setActiveStep}
                    sent_to={
                      checkAccountType === "business"
                        ? personal_info_state?.account_email ? personal_info_state?.account_email : personal_info_state?.email
                        : client_info_state.email
                    }
                    code_for="Email"
                    onVerifyOTP={(response) => {
                      toast.success("Authenticated successfully", { toastId: "toast" });
                      if (info_data?.account_type?.toLowerCase() === "business") {
                        let userData = response?.data?.response?.data;
                        let hostName = process.env.REACT_APP_SERVER_APPLICATION_DOMAIN
                        if (typeof window !== 'undefined') {
                          hostName = window.location.host;
                        }
                        const hostArr = hostName.split(".");
                        hostArr.unshift(`${userData.domain}`);
                        const result = hostArr.join(".");
                        try {
                          getServerData(userData.domain);
                        } catch { }
                        let is_asked_for_data_update = userData.is_asked_for_data_update
                        // navigate("/auth/login/?account_type=business")

                        let redirect_params = ''
                        if (!is_asked_for_data_update) {
                          redirect_params += `is_asked_for_data_update=${is_asked_for_data_update}&`
                        }
                        window.location = `http://${result}/authentication/login/auto_login/?user_login_id=${userData.id}&access_token=${userData.access_token}&next_path=/dashboard/account/setup/business-settings/additional-information&redirection_source=nstyle_app&redirection_purpose=account_and_mobile_otp_verification&${redirect_params}`;
                        storeDataInLocalStorage("otp", "verified");
                        storeDataInLocalStorage("currentStep", 3);
                      } else {
                        if (info_data?.account_type?.toLowerCase() == "everyone") {
                          dispatch({
                            type: CLIENT_SIGNUP.CLIENT_INFO,
                            payload: response?.data?.response?.data,
                          });
                          storeDataInLocalStorage("client_token", response?.data?.response?.data?.access_token);
                          storeDataInLocalStorage("client_id", response?.data?.response?.data?.client_id);
                          storeDataInLocalStorage("client_email", response?.data?.response?.data?.email);
                          if (nexts) {
                            navigate(
                              `${nexts}&hash=${hash}&token=${token}&domain=${domain}`
                            );
                          } else {
                            navigate(`${urls}`);
                          }
                          return;
                        }
                      }
                    }}
                  />
          </div>
        )}
      </div>
    </div>
  );
}
