import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCommissionReportsByCommissionDetails, getCommissionReportsByCommissionDetailsWithoutPagination, getCommissionReportsByStaff } from 'Adapters/Api/commissionReports';
import { getEmployeeDropDownList, getEmployeeDropDownListPagination, getEmployeeList } from 'Adapters/Api/employee';
import { useLocation } from 'new-components/location/helper';
import { toast } from 'react-toastify';
import { apiErrorMessage } from 'Constants/Data/Errors';
import moment from 'moment';
import { getLocationTax } from 'Adapters/Api/saleServices';
import useCalculateTaxes from 'Hooks/useCalculateTaxes';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { getMultiLanguageInvoice } from 'Adapters/Api/multiLanguageInvoice';
import { getSingleInvoiceDetail } from 'Adapters/Api/saleOrders';

let employeeTimeOutId = undefined;
function useCommissionReports() {
    const { locationDetail } = useLocation();
    const dispatch = useDispatch();
    const state = useSelector((state) => state)
    const total_tax = state?.saleService?.total_tax;
    const locationTax = state?.saleService?.location_tax;
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const { calculateTaxes } = useCalculateTaxes(locationTax);
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");
    const access_token = getDataFromLocalStorage('access_token')

    const commissionReportByStaff = state?.employee?.commission_by_staff;
    const commissionReportByDetail = state?.employee?.commission_by_detail;
    const pagesCommissionDetailed = state?.employee?.pagesCommissionDetailed;
    const invoice_titles = state?.employee?.invoice_titles;
    const allCountCommissionDetailed = state?.employee?.allCountCommissionDetailed;
    const allCommissionReportByDetail = state?.employee?.all_commission_by_detail;
    // const employeeList = state?.employee?.employees;
    // const locationEmployee = useMemo(() => {
    //     return employeeList?.filter((empl) => empl?.location?.find((loc) => loc?.id === locationDetail?.id) ? true : false)
    // }, [employeeList, locationDetail?.id]);

    const [InvoicePopup, setInvoicePopup] = useState(false);
    const [detailLoading, setDetailLoading] = useState(false)
    const [selectedInvice, setSelectedInvice] = useState();
    const [locationStaffCommissionReports, setLocationStaffCommissionReports] = useState();
    const [locationDetailCommissionReports, setLocationDetailCommissionReports] = useState();
    const [ImportPayroll, setImportPayroll] = useState(false);
    const [Section, setSection] = useState('ByStaff');
    const [selectEmployee, setSelectEmployee] = useState("");
    const [selectMember, setMember] = useState("");
    const [loading, setLoading] = useState(true);
    const [detailedLoading, setDetailedLoading] = useState(true)
    const [detailedNoPaginationLoading, setDetailedNoPaginationLoading] = useState(true)
    const [isPopup, setIsPopup] = useState(false);
    const [selectedItem, setSeletedItem] = useState();
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [startdt, setStartDt] = useState();
    const [closeDate, setcloseDate] = useState();
    const [itemType, setItemType] = useState();
    const [commissionDetailCurrentPage, setCommissionDetailCurrentPage] = useState(1);
    const [totals, setTotals] = useState({
        total_products_sales: 0,
        total_services_sales: 0,
        total_vouchers_sales: 0,

        total_products_commissions: 0,
        total_services_commissions: 0,
        total_vouchers_commissions: 0,
        total_commission: 0,
    })

    const [employeeSearch, setEmployeeSearch] = useState("");
    const [employeePage, setEmployeePage] = useState(1)
    const [employeeLoader, setEmployeeLoader] = useState(false)

    const employeeList = state?.employee?.employeesDropdown;
    const employeePaginationData = state?.employee?.employeeDropdownPaginationData

    const reduxInvoices = state?.multilanguageInvoices?.invoices

    // const getInovices = () => {
    //     const success = () => { }
    //     const fail = () => { }
    //     dispatch(getMultiLanguageInvoice(access_token, success, fail))
    // }

    // useEffect(() => {
    //     getInovices()
    // }, [])
    //get employee api hit here
    // useEffect(() => {
    //     if (selected_location) {
    //         dispatch(getEmployeeDropDownList(selected_location))
    //     }
    // }, [selected_location])

    useEffect(() => {
        if (selected_location) {
            if (employeeSearch === null) {
                // Initial load with empty search_text
                dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
            } else {
                if (employeeTimeOutId) {
                    clearTimeout(employeeTimeOutId)
                }
                employeeTimeOutId = setTimeout(() => {
                    dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
                }, 500);
            }
        }
    }, [employeeSearch, selected_location, employeePage])

    //handling get commission by staff and date range filter
    useEffect(() => {
        if ((Section === "ByStaff") && (endDate && startDate)) {
            setLoading(true)
            dispatch(getCommissionReportsByStaff(startDate, endDate, selected_location, setLoading))
        } else {
            if ((commissionReportByStaff?.length === 0 && Section === "ByStaff") || (Section === "ByStaff" && (!endDate && !startDate))) {
                setLoading(true)
                dispatch(getCommissionReportsByStaff("", "", selected_location, setLoading))
            }
        }
    }, [endDate, startDate, Section, selected_location])

    const invoiceLabels = useMemo(() => {
        return (reduxInvoices?.find(itm => itm?.location == selected_location))
    }, [reduxInvoices?.length || selected_location])


    // calculate total sale and total commission of product, service, voucher 
    useEffect(() => {

        let total_products_sales = 0;
        let total_services_sales = 0;
        let total_vouchers_sales = 0;

        let total_products_commissions = 0;
        let total_services_commissions = 0;
        let total_vouchers_commissions = 0;
        let total_commission = 0;

        locationStaffCommissionReports?.forEach((itm) => {
            total_services_sales += itm?.service_sale_price;
            total_vouchers_sales += itm?.voucher_sale_price;
            total_products_sales += itm?.product_sale_price?.product_sale_price;

            total_products_commissions += itm?.product_sale_price?.product_commission;
            total_services_commissions += itm?.product_sale_price?.service_commission;
            total_vouchers_commissions += itm?.product_sale_price?.voucher_commission;

            total_commission += itm?.product_sale_price?.commission_total;
        });

        setTotals((prev) => ({
            ...prev,
            total_products_sales,
            total_services_sales,
            total_vouchers_sales,
            total_products_commissions,
            total_services_commissions,
            total_vouchers_commissions,
            total_commission,
        }))


    }, [locationStaffCommissionReports, endDate, startDate]);


    //filter commission by staff on selected member
    const filter_commission_by_staff = (data) => {
        const filter_data = data?.filter((itm) => {

            // if ((selectEmployee || itemType) && Section === "CommissionDetailed") {
            //     let return_value = false;

            //     if (((selectEmployee) && (selectEmployee != "all"))) {
            //         if (((selectEmployee) && (selectEmployee != "all")) && (itemType)) {
            //             if ((itm?.sale?.order_type?.toLowerCase() === itemType?.toLowerCase()) && (itm?.employee?.full_name?.toLowerCase()?.includes(selectEmployee?.toLowerCase()))) {
            //                 return_value = true;
            //                 // return true
            //             }
            //         } else {
            //             if (itm?.employee?.full_name?.toLowerCase()?.includes(selectEmployee?.toLowerCase())) {
            //                 return_value = true;
            //                 // return true
            //             }
            //         }

            //     }

            //     if ((itemType)) {
            //         if ((itemType) && ((selectEmployee) && (selectEmployee != "all"))) {
            //             if ((itm?.sale?.order_type?.toLowerCase() === itemType?.toLowerCase()) && (itm?.employee?.full_name?.toLowerCase()?.includes(selectEmployee?.toLowerCase()))) {
            //                 return_value = true;
            //                 // return true
            //             }
            //         } else {
            //             if (itm?.sale?.order_type?.toLowerCase() === itemType?.toLowerCase()) {
            //                 return_value = true;
            //                 // return true
            //             }
            //         }

            //     }

            //     return return_value;

            // } else
            if (selectMember && Section === "ByStaff") {

                if ((selectMember) && (selectMember != "all")) {
                    // if (itm?.full_name?.toLowerCase()?.includes(selectMember?.toLowerCase())) {
                    if (itm?.id == selectMember) {
                        return true
                    }
                }

            } else {
                return true;
            }

        })
        return filter_data;
    }


    //filter staff commission with selected location 
    useEffect(() => {
        const result = commissionReportByStaff?.filter((cmsn) => cmsn?.location?.find((loc) => loc?.id === locationDetail?.id) ? true : false)
        let filter_commsission_employee = filter_commission_by_staff(result);
        setLocationStaffCommissionReports(filter_commsission_employee)
    }, [selectMember, employeeList, locationDetail?.id, commissionReportByStaff])



    // export start 
    let commissionReportByStaffExport = [
        { label: selectedLanguage?.name == "English" ? 'Employee Name' : translation[420]?.value ? translation[420]?.value : 'Employee Name', key: 'employee_name' },
        { label: selectedLanguage?.name == "English" ? 'Product Sales Total' : translation[342]?.value ? translation[342]?.value : 'Product Sales Total', key: 'product_sales_total' },
        { label: selectedLanguage?.name == "English" ? 'Product Commission Total' : translation[345]?.value ? translation[345]?.value : 'Product Commission Total', key: 'product_commission_total' },
        { label: selectedLanguage?.name == "English" ? 'Service Sales Total' : translation[336]?.value ? translation[336]?.value : 'Service Sales Total', key: 'service_sales_total' },
        { label: selectedLanguage?.name == "English" ? 'Service Commission Total' : translation[337]?.value ? translation[337]?.value : 'Service Commission Total', key: 'service_commission_total' },
        { label: selectedLanguage?.name == "English" ? 'Voucher Sales Total' : translation[347]?.value ? translation[347]?.value : 'Voucher Sales Total', key: 'voucher_sale_total' },
        { label: selectedLanguage?.name == "English" ? 'Voucher Commission Total' : translation[350]?.value ? translation[350]?.value : 'Voucher Commission Total', key: 'voucher_commission_total' },
        { label: selectedLanguage?.name == "English" ? 'Total Commission' : translation[421]?.value ? translation[421]?.value : 'Total Commission', key: 'total_commission' },
    ]


    const commissionReportData = {
        filename: "Staff Commissions Report.csv",
        headers: commissionReportByStaffExport,
        data: locationStaffCommissionReports?.length > 0 ?
            locationStaffCommissionReports?.map((itm) => {
                return {
                    employee_name: itm?.full_name ? itm?.full_name : "----",
                    product_sales_total: itm?.product_sale_price?.product_sale_price ? `${itm?.product_sale_price?.product_sale_price} ${locationDetail?.currency?.code}` : "----",
                    product_commission_total: itm?.product_sale_price?.product_commission ? `${itm?.product_sale_price?.product_commission} ${locationDetail?.currency?.code}` : "----",
                    service_sales_total: itm?.service_sale_price ? `${itm?.service_sale_price} ${locationDetail?.currency?.code}` : "----",
                    service_commission_total: itm?.product_sale_price?.service_commission ? `${itm?.product_sale_price?.service_commission} ${locationDetail?.currency?.code}` : "----",
                    voucher_sale_total: itm?.voucher_sale_price ? `${itm?.voucher_sale_price} ${locationDetail?.currency?.code}` : "----",
                    voucher_commission_total: itm?.product_sale_price?.voucher_commission ? `${itm?.product_sale_price?.voucher_commission} ${locationDetail?.currency?.code}` : "----",
                    total_commission: itm?.product_sale_price?.commission_total ? `${itm?.product_sale_price?.commission_total} ${locationDetail?.currency?.code}` : "----"
                }
            }) : []
    }
    // export end 

    const handleCommissionReportByStaffExportClick = () => {
        if (!isToastVisible) {
            if (locationStaffCommissionReports?.length > 0) {
                setTimeout(() => {
                    toast.success("File downloaded successfully", {
                        toastId: "toast",
                        onClose: () => setIsToastVisible(false)
                    });
                }, 300);
            } else {
                toast.error("No data to export", {
                    onClose: () => setIsToastVisible(false),
                });
            }
            setIsToastVisible(true);
        }
    };



    // *********************************** commission detail reports integration start ************************
    //hit commission detail api here
    useEffect(() => {
        if ((Section === "CommissionDetailed") && (startdt && closeDate)) {
            getCommissionDetailedFunction()
            getCommissionDetailedFunctionWithNoPagination()
        } else {
            if ((commissionReportByDetail?.length === 0 && Section === "CommissionDetailed") || (Section === "CommissionDetailed" && (!startdt && !closeDate))) {
                getCommissionDetailedFunction()
                getCommissionDetailedFunctionWithNoPagination()
            }
        }
    }, [startdt, closeDate, Section, itemType, selectEmployee, selected_location, commissionDetailCurrentPage])

    useEffect(() => {
        setCommissionDetailCurrentPage(1)
        setDetailedNoPaginationLoading(true)
        setDetailedLoading(true)
    }, [startdt, closeDate, Section, itemType, selectEmployee, selected_location])

    useEffect(() => {
        setCommissionDetailCurrentPage(1)
    }, [startdt, closeDate])

    const getCommissionDetailedFunctionWithNoPagination = () => {
        setDetailedNoPaginationLoading(true)
        dispatch(getCommissionReportsByCommissionDetailsWithoutPagination(
            selected_location,
            itemType,
            selectEmployee,
            startdt,
            closeDate,
            setDetailedNoPaginationLoading,
            () => {
                setDetailedNoPaginationLoading(false)
            },
            () => {
                setDetailedNoPaginationLoading(false)
                toast.error(apiErrorMessage, { toastId: "toast" })
            }
        ))
    }

    const getCommissionDetailedFunction = () => {
        setDetailedLoading(true)
        dispatch(getCommissionReportsByCommissionDetails(
            selected_location,
            commissionDetailCurrentPage,
            itemType,
            selectEmployee,
            startdt,
            closeDate,
            setDetailedLoading,
            () => {
                setDetailedLoading(false)
            },
            () => {
                setDetailedLoading(false)
                toast.error(apiErrorMessage, { toastId: "toast" })
            }
        ))
    }

    //filter detail commission with selected location
    useEffect(() => {
        // const result = commissionReportByDetail?.filter((cmsn) => cmsn?.location?.id === locationDetail?.id)
        // let filter_commsission_employee = filter_commission_by_staff(result);
        setLocationDetailCommissionReports(commissionReportByDetail);
    }, [selectEmployee, employeeList, locationDetail?.id, commissionReportByDetail, itemType])


    // export commission by detail 

    let commissionReportDetailExport = [
        { label: selectedLanguage?.name == "English" ? 'Employee Name' : translation[420]?.value ? translation[420]?.value : 'Employee Name', key: 'employee_name' },
        { label: selectedLanguage?.name == "English" ? 'Sale Date' : translation[362]?.value ? translation[362]?.value : 'Sale Date', key: 'sale_date' },
        { label: selectedLanguage?.name == "English" ? 'Invoice ID' : translation[422]?.value ? translation[422]?.value : 'Invoice ID', key: 'invoice_id' },
        { label: selectedLanguage?.name == "English" ? 'Item Sold' : translation[364]?.value ? translation[364]?.value : 'Item Sold', key: 'item_sale' },
        { label: selectedLanguage?.name == "English" ? 'Item Type' : translation[357]?.value ? translation[357]?.value : 'Item Type', key: 'item_type' },
        { label: selectedLanguage?.name == "English" ? 'Quantity' : translation[365]?.value ? translation[365]?.value : 'Quantity', key: 'quantity' },
        { label: selectedLanguage?.name == "English" ? 'Sale Value' : translation[366]?.value ? translation[366]?.value : 'Sale Value', key: 'sale_value' },
        { label: selectedLanguage?.name == "English" ? 'Commission Rate' : translation[367]?.value ? translation[367]?.value : 'Commission Rate', key: 'commission_rate' },
        { label: selectedLanguage?.name == "English" ? 'Commission Amount' : translation[368]?.value ? translation[368]?.value : 'Commission Amount', key: 'commission_amount' },
    ]


    const commissionReportDetail = {
        filename: "Staff Commissions Detailed Report.csv",
        headers: commissionReportDetailExport,
        data: allCommissionReportByDetail?.length > 0 &&
            allCommissionReportByDetail?.filter((cmsn) => (Object.keys(cmsn?.sale)?.length > 0) ? true : false).length > 0 ?
            allCommissionReportByDetail?.filter((cmsn) => (Object.keys(cmsn?.sale)?.length > 0) ? true : false)?.map((itm) => {

                let symble = itm?.sale?.Commission_Rate?.toString()?.split("")?.includes("%") ? "%" : "";

                return {
                    employee_name: itm?.employee?.full_name ? itm?.employee?.full_name : "----",
                    sale_date: itm?.sale?.created_at ? moment(itm?.sale?.created_at).format("YYYY-MM-DD") : "----",
                    invoice_id: itm?.sale?.id ? itm?.sale?.id.split("-")[0] : "----",
                    item_sale: itm?.sale?.name ? itm?.sale?.name : "----",
                    item_type: itm?.sale?.order_type ? itm?.sale?.order_type : "----",
                    quantity: itm?.sale?.quantity ? itm?.sale.quantity : 0,
                    sale_value: itm?.sale ? itm?.sale.quantity * itm?.sale.price : 0,
                    commission_rate: itm?.commission_rate ? `${itm?.commission_rate}` : 0,
                    commission_amount: itm?.commission ? `${itm?.commission} ${locationDetail?.currency?.code}` : 0,

                }
            }) : []

    }
    // export end 

    const handleCommissionReportByDetailExportClick = () => {
        if (!isToastVisible) {
            if (allCommissionReportByDetail?.length > 0) {
                setTimeout(() => {
                    toast.success("File downloaded successfully", {
                        toastId: "toast",
                        onClose: () => setIsToastVisible(false)
                    });
                }, 300);
            } else {
                toast.error("No data to export", {
                    onClose: () => setIsToastVisible(false),
                });
            }
            setIsToastVisible(true);
        }
    };

    // *********************************** commission detail reports integration end ************************

    useEffect(() => {
        if (selected_location) {
            dispatch(getLocationTax(selected_location))
        }
    }, [selected_location]);


    useEffect(() => {
        if (locationTax?.id) {
            calculateTaxes(locationTax)
        }
    }, [locationTax?.id])


    const getInvoiceDetailData = (id) => {
        setDetailLoading(true)
        setInvoicePopup(!InvoicePopup);
        dispatch(getSingleInvoiceDetail(
            access_token, id, selected_location, setDetailLoading,
            (result) => {
                setSelectedInvice(result);
            },
            () => { }
        ));
    }


    const calculate_totals = (item) => {
        let total_bill = 0;
        if (typeof item?.membership == "object") {
            if (item?.membership?.length > 0) {
                item?.membership?.forEach((itm) => {
                    total_bill += itm?.membership_price * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        if (typeof item?.voucher == "object") {
            if (item?.voucher?.length > 0) {
                item?.voucher?.forEach((itm) => {
                    total_bill += (itm?.voucher_price) * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        if (typeof item?.service == "object") {
            if (item?.service?.length > 0) {
                item?.service?.forEach((single_service) => {
                    // let this_price = single_service?.service?.priceservice?.find(price => price.currency_name == location_currency)
                    // if (this_price) {
                    // total_bill += (this_price?.price) * (single_service?.quantity ? single_service?.quantity : 1);
                    // }
                    total_bill += (single_service?.price) * (single_service?.quantity ? single_service?.quantity : 1);
                });
            }
        }


        if (typeof item?.product == "object") {
            if (item?.product?.length > 0) {
                item?.product?.forEach((itm) => {
                    total_bill += (itm?.price) * (itm?.quantity ? itm?.quantity : 1);
                });
            }
        }

        // if (item?.tip?.length > 0) {
        //   const selected_invoice_tip = (item?.tip?.length > 0 ? item?.tip?.reduce(function (total, obj) {
        //     return total + (obj.tip !== null && obj?.tip);
        //   }, 0) : 0)

        //   if (selected_invoice_tip) {
        //     total_bill += selected_invoice_tip
        //   }
        // }


        if (item?.order_type === "Appointment") {
            total_bill += item?.total_price;
        }
        let total = total_bill?.toFixed(2)
        return total;
    };


    return {
        locationStaffCommissionReports, locationDetailCommissionReports, ImportPayroll, setImportPayroll, Section,
        setSection, startDate, setStartDate, endDate, setEndDate, selectMember, setMember, selectEmployee, setSelectEmployee,
        locationDetail, employeeList, totals, commissionReportData, loading, startdt, setStartDt, closeDate, setcloseDate,
        itemType, setItemType, commissionReportDetail, isPopup, setIsPopup, selectedItem, invoiceLabels, setSeletedItem,
        total_tax, translation, selectedLanguage, pagesCommissionDetailed, allCountCommissionDetailed, commissionDetailCurrentPage,
        setCommissionDetailCurrentPage, detailedLoading, handleCommissionReportByDetailExportClick, handleCommissionReportByStaffExportClick,
        allCommissionReportByDetail, invoice_titles, getInvoiceDetailData, detailLoading, selectedInvice, setInvoicePopup,
        employeeSearch, setEmployeeSearch, employeePage, setEmployeePage, employeeLoader, setEmployeeLoader, employeePaginationData,
        calculate_totals, InvoicePopup

    }
}

export default useCommissionReports;