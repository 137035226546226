import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getEmployeeDropDownListPagination } from "../../../../../Adapters/Api/employee";
import { getEmployeeWorkingPayroll, getEmployeeWorkingPayrollWithNoPagination } from "../../../../../Adapters/Api/payroll";
import Svgs from "../../../../../Assets/svgs";
import { MONTHS, MONTHS_DURATIONS } from "../../../../../Constants/Data/Months";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import Popup from "../../../Elements/Popup";
import usePayroll from "./usePayroll";
import LocationSelection from "new-components/location";
import { useLocation } from "new-components/location/helper";
import moment from "moment";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import Pagination from "new-components/Pagination";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import NoPayrollFound from "../Elements/NoPayrollFound";
import IconButton from "Components/TenantApp/Elements/IconButton";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
// import LoginInput from "../../../Elements/LoginInput";
// import PreloaderFull from "../../../Elements/PreloaderFull";
// import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
// import NotFound from "Components/Utility/NotFound";
// import SalarySlip from "./SalarySlip";

let employeeTimeOutId = undefined
const Payroll = () => {
  const navigate = useNavigate();
  const { selected_location, locationDetail } = useLocation();
  const dt = new Date();
  const state = useSelector((state) => state);
  const payrollRedux = state?.payroll?.payrolls;
  const allPayrollList = state?.payroll?.allPayrolls
  const payroll_updated = state?.payroll?.payroll_updated;
  const pages = state?.payroll?.pages;
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const employeePaginationData = state?.employee?.employeeDropdownPaginationData
  const Currency = locationDetail?.currency?.code
  //get only that employee whos belong to selected location
  const employeeRedux = state?.employee?.employeesDropdown
  const businessCurrency = state.business.business;

  // const payrollRedux = useMemo(() => {
  //   const result = payrollList?.filter((pyrl) =>
  //     pyrl?.location?.find((loc) => loc?.id === selected_location)
  //       ? true
  //       : false
  //   );
  //   return result;
  // }, [payrollList, selected_location]);


  const [exportLoader, setExportLoader] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [ImportPayroll, setImportPayroll] = useState(false);
  const [ShowDetails, setShowDetails] = useState(0);
  const [DateRange, setDateRange] = useState("");
  const [selectYear, setSelectYear] = useState("");
  const dispatch = useDispatch();
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [days, setDays] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [firstday, setFirstDay] = useState();
  const [lastDay, setlastDay] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [filterPayroll, setFilterPay] = useState();
  const [employee, setEmployee] = useState();
  const [loader, setLoader] = useState(true)
  const [employeeLoader, setEmployeeLoader] = useState(true)
  const [noPaginationLoader, setNoPaginationLoader] = useState(true)
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [employeePage, setEmployeePage] = useState(1)

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const { payrollDeleteHandler, isDelete, setDelete, currentPage, setCurrentPage } = usePayroll();

  //hit employee and payroll api here
  useEffect(() => {
    const date = new Date(`${DateRange} 1, ${selectYear}`);
    const monthNumber = date.getMonth() + 1;
    const formattedMonth = String(monthNumber).padStart(2, '0');
    const nextMonthDate = new Date(date);
    nextMonthDate.setMonth(monthNumber);
    // Subtract one day to get the last day of the current month
    nextMonthDate.setDate(0);
    const lastDay = nextMonthDate.getDate();

    const startDate = `${selectYear}-${formattedMonth}-01`
    const endDate = `${selectYear}-${formattedMonth}-${lastDay}`

    if (payrollRedux?.length === 0 && startDate && endDate && selectYear) {
      dispatch(getEmployeeWorkingPayroll(currentPage, selected_location, startDate, endDate, selectedEmployee, setLoader));
      dispatch(getEmployeeWorkingPayrollWithNoPagination(selected_location, startDate, endDate, selectedEmployee, setNoPaginationLoader));
    } else {
      if (startDate && endDate && selectYear) {
        dispatch(getEmployeeWorkingPayroll(currentPage, selected_location, startDate, endDate, selectedEmployee, setLoader));
        dispatch(getEmployeeWorkingPayrollWithNoPagination(selected_location, startDate, endDate, selectedEmployee, setNoPaginationLoader));
      }
    }
  }, [currentPage, selected_location, DateRange, selectYear, selectedEmployee]);


  useEffect(() => {
    setLoader(true)
    setNoPaginationLoader(true)
    setCurrentPage(1)
  }, [selected_location, DateRange, selectYear, selectedEmployee])

  // filter handler
  const payroll_filter = () => {
    // let payroll_flter_data = payrollRedux?.filter((item) => {
    //   if (search_text || selectedEmployee) {
    //     let return_value = false;
    //     //check search text
    //     if (search_text) {
    //       if (
    //         item.full_name.toLowerCase().includes(search_text.toLowerCase())
    //       ) {
    //         return_value = true;
    //       } else {
    //         return false;
    //       }
    //     }

    //     //check dropdown text
    //     if (selectedEmployee) {
    //       if (
    //         item.full_name
    //           .toLowerCase()
    //           .includes(selectedEmployee.toLowerCase())
    //       ) {
    //         return_value = true;
    //       } else {
    //         return false;
    //       }
    //     }
    //     return return_value;
    //   }
    //   return true;
    // });
    let payroll_flter_data = payrollRedux
    return payroll_flter_data;
  };

  //set default state of year, day, month, dataRange, selectedMonth, firstDay, lastDay etc
  useEffect(() => {
    // const selectedDate =
    //   DateRange && selectYear
    //     ? moment(`${dt.getDate()}-${DateRange}-${selectYear}`).format(
    //       "YYYY-M-D"
    //     )
    //     : "";
    // const date = new Date(selectedDate ? selectedDate : new Date());
    const selectedDate =
      DateRange && selectYear
        ? moment(`${dt.getDate()}-${DateRange}-${selectYear}`, "DD-MM-YYYY").format(
          "YYYY-M-D"
        )
        : "";
    const date = (selectedDate && selectedDate != "Invalid date") ? moment(selectedDate, "YYYY-M-D").toDate() : moment();
    const current_month = date.month();
    const month = moment(date).format("MMM");
    const startOfMonth = moment(date).startOf("month").format("ddd");
    const endOfMonth = moment(date).endOf("month").format("ddd");
    setFirstDay(startOfMonth);
    setlastDay(endOfMonth);
    setSelectedMonth(month);
    setDays(MONTHS_DURATIONS[current_month]);
    setDateRange(DateRange ? DateRange : MONTHS[moment().month()]);
    setSelectYear(selectYear ? selectYear : date?.year());
    const result = payroll_filter()?.map((itm) => {
      const current_month_schedule = itm?.schedule?.filter((dt) => {
        if (
          moment(dt?.date).format("MMMM") == DateRange &&
          moment(dt?.date).format("YYYY") == selectYear
        ) {
          return true;
        }
      });
      return {
        ...itm,
        schedule: current_month_schedule,
      };
    });
    setFilterPay(result);
  }, [payrollRedux, selectedEmployee, DateRange, selectYear]);

  //calculate minst to hours
  function timeConvert(total_minutes) {
    let mins = total_minutes;
    let float_hours = mins / 60;
    let int_hours = Math.floor(float_hours);
    let float_minutes = (float_hours - int_hours) * 60;
    let int_minutes = Math.round(float_minutes);

    return int_hours + "." + int_minutes;
  }

  // const calculateTotalHours = () => {
  //   let total_worked_hours_ = 0;
  //   if (filterPayroll?.length > 0) {
  //     filterPayroll?.forEach((itm) => {
  //       itm?.schedule?.forEach((dt) => {
  //         const test = dt?.total_hours?.split(":").slice(0, 2)?.join(".");
  //         total_worked_hours_ += test * 1;
  //       });
  //     });
  //   }
  //   const times = total_worked_hours_?.toFixed(2)?.split(".");
  //   let hr_to_min = parseInt(times[0]) * 60;
  //   let mins = parseInt(times[1]);
  //   let total_mins = hr_to_min + mins;
  //   const times_in_hours_mins = timeConvert(total_mins);

  //   return times_in_hours_mins?.split(".").join(":");
  // };

  // payroll export start
  let payrollHeader = [
    { label: selectedLanguage?.name == "English" ? "Employee Name" : translation[420]?.value ? translation[420]?.value : "Employee Name", key: "employee_name" },
    { label: selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date", key: "date" },
    { label: selectedLanguage?.name == "English" ? "Income Type" : translation[432]?.value ? translation[432]?.value : "Income Type", key: "income_type" },
    { label: selectedLanguage?.name == "English" ? "Total Worked Hours" : translation[433]?.value ? translation[433]?.value : "Total Worked Hours", key: "total_worked_hours" },
    { label: selectedLanguage?.name == "English" ? "Total Salary" : translation[1309]?.value ? translation[1309]?.value : "Total Salary", key: "total_salary" },
    { label: selectedLanguage?.name == "English" ? "Total Comission" : translation[1308]?.value ? translation[1308]?.value : "Total Comission", key: "total_comission" },
    { label: selectedLanguage?.name == "English" ? "Total Tips" : translation[352]?.value ? translation[352]?.value : "Total Tips", key: "total_tips" },
    { label: selectedLanguage?.name == "English" ? "Total Earning" : translation[1336]?.value ? translation[1336]?.value : "Total Earning", key: "total_earning" },
  ];

  const payrollData = {
    filename: "Employee Payroll Report.csv",
    headers: payrollHeader,
    data:
      allPayrollList?.length > 0
        ? allPayrollList?.map((item) => {

          return {
            employee_name: item?.full_name ? item?.full_name : "----",
            // date: item?.created_at ? moment(item?.created_at).format("YYYY-MM-DD") : "----",
            date: `${firstday}, 01 ${selectedMonth} - ${lastDay} ${days}, ${selectYear}`,
            income_type:
              item?.employee_info?.income_type === "Daily_Income"
                ? "Daily"
                : item?.employee_info?.income_type === "Hourly_Rate"
                  ? "Hourly"
                  : item?.employee_info?.income_type === "Monthly_Salary"
                    ? "Monthly"
                    : "----",
            total_worked_hours: item?.total_hours?.toString()?.replace(".", ":")
              ? item?.total_hours
              : "----",
            // total_worked_hours: calculateTotalHours()
            //   ? calculateTotalHours()
            //   : "----",
            // total_earning: totalEarns ? `${totalEarns} ${Currency}` : "----",
            total_salary: item?.total_earning ? `${item?.total_earning?.toFixed(2)} ${Currency}` : "----",
            total_comission: item?.total_commission ? `${item?.total_commission?.toFixed(2)} ${Currency}` : "----",
            total_tips: item?.total_tips ? `${item?.total_tips?.toFixed(2)} ${Currency}` : "----",
            total_earning: `${(+item?.total_commission + +item?.total_tips)?.toFixed(2)} ${Currency}`,
          };
        })
        : [],
  };
  // payroll export  end

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allPayrollList?.length > 0) {
        // // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  useEffect(() => {
    if (selected_location) {
      if (employeeSearch === null) {
        // Initial load with empty search_text
        dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
      } else {
        if (employeeTimeOutId) {
          clearTimeout(employeeTimeOutId)
        }
        employeeTimeOutId = setTimeout(() => {
          dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
        }, 500);
      }
    }
  }, [employeeSearch, selected_location, employeePage])


  const PageSize = 10

  return (
    <>
      {/* <SalarySlip open={true} filterPayroll={filterPayroll} selectedLanguage={selectedLanguage} translation={translation} /> */}
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Employee Management" : translation[375]?.value ? translation[375]?.value : "Employee Management"}
          second={selectedLanguage?.name == "English" ? "Payroll" : translation[402]?.value ? translation[402]?.value : "Payroll"}
          url={'/dashboard/employee/'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Payroll" : translation[402]?.value ? translation[402]?.value : "Payroll"}
            </h2>
            <LocationSelection />
          </div>

        </div>

        <div className="border rounded-lg">
          <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b sm:flex-row flex-col gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Payroll Details" : translation[429]?.value ? translation[429]?.value : "Payroll Details"}
                </h2>
                {(!payroll_updated || loader) ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">
                      {allPayrollList?.length ? allPayrollList?.length : 0}{" "}
                      {allPayrollList?.length && allPayrollList?.length > 1 ?
                        selectedLanguage?.name == "English" ? "Employees" : translation[399]?.value ? translation[399]?.value : "Employees"
                        : selectedLanguage?.name == "English" ? "Employee" : translation[1321]?.value ? translation[1321]?.value : "Employee"
                      }
                    </p>
                  </div>
                }
              </div>
              {/* <div className="mb-00">
                <LoginInput
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Search Employee" : translation[423]?.value ? translation[423]?.value : "Search Employee"}
                  padding="py-1"
                  leftIcon={<Svgs.Search />}
                  value={search_text}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div> */}
            </div>
            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-full lg:!w-44 sm:!w-40'} classOn2={'!w-full lg:!w-44 sm:!w-40'} classOn3={'!w-full lg:!w-44 sm:!w-40'} classOn4={'!w-12 lg:!16'} has4Itm />
              :
              <div className="flex items-center sm:justify-center justify-end gap-3 sm:w-fit w-full flex-wrap sm:flex-nowrap">
                <PaginationDropdown
                  title=""
                  setPage={setEmployeePage}
                  page={employeePage}
                  setDropdownSearch={setEmployeeSearch}
                  dropdownSearch={employeeSearch}
                  lastPage={employeePaginationData?.employeePages}
                  currentPage={employeePaginationData?.employeeCurrentPage}
                  showTitle={false}
                  placeholder={selectedLanguage?.name == "English" ? "Select Employee" : translation[241]?.value ? translation[241]?.value : "Select Employee"}
                  name={"selectedEmployee"}
                  value={selectedEmployee}
                  onChange={(e) => setSelectedEmployee(e.target.value)}
                  loading={employeeLoader}
                  // customHeight={'h-[1.25rem]'}
                  custom_min_width={'sm:min-w-[15rem] min-w-[20rem]'}
                  options={[
                    {
                      label: selectedLanguage?.name == "English" ? "All Employee" : translation[241]?.value ? translation[241]?.value : "All Employee", value: ""
                    },
                    ...employeeRedux?.map((empl, i) => {
                      return {
                        label: empl.full_name,
                        value: empl.id,
                        otherData: {
                          image: empl?.image,
                          DataOne: empl.email,
                          showImage: true,
                          index: i
                        },
                        email: empl.email,
                        mobile_number: empl.mobile_number
                      };
                    })
                  ]}
                />
                <div className="flex gap-3 w-full">
                  <div className="grid grid-cols-2 sm:flex gap-3 w-full">
                    <Dropwdown
                      title=""
                      placeholder={selectedLanguage?.name == "English" ? "Select Month" : translation[430]?.value ? translation[430]?.value : "Select Month"}
                      showTitle={false}
                      value={DateRange}
                      onChange={(e) => {
                        setDateRange(e.target.value);
                      }}
                      options={MONTHS.map((mnth, i) => {
                        return {
                          label: mnth,
                          value: mnth,
                        };
                      })}
                      className={"!w-full sm:!w-auto"}
                    />

                    <Dropwdown
                      title=""
                      placeholder={selectedLanguage?.name == "English" ? "Select Year" : translation[431]?.value ? translation[431]?.value : "Select Year"}
                      showTitle={false}
                      value={selectYear}
                      onChange={(e) => {
                        setSelectYear(e.target.value);
                      }}
                      options={[
                        { label: 2022, value: 2022 },
                        { label: 2023, value: 2023 },
                        { label: 2024, value: 2024 },
                        { label: 2025, value: 2025 },
                      ]}
                      className={"!w-full sm:!w-auto"}
                    />
                  </div>

                  {exportLoader ? (
                    <div className="h-[4rem] flex items-center justify-center">
                      <PreloaderSm />
                    </div>
                  ) :
                    !employee ? (
                      filterPayroll?.length > 0 ?
                        <div className="flex items-center sm:gap-4 gap-2">
                          <CSVLink
                            {...payrollData}
                            target="_blank"
                            onClick={handleExportClick}
                          >
                            <IconButton filled>
                              <Svgs.Export />
                            </IconButton>
                          </CSVLink>
                        </div> :
                        <LoginBtn
                          className="rounded-lg border-2 border-primary"
                          bg="bg-white"
                          animation={false}
                          onClick={handleExportClick}
                        >
                          <div className="flex items-center gap-2 text-[#FFFFFF]">
                            <Svgs.Export bg="#FFFFFF" />
                          </div>
                        </LoginBtn>
                    ) : (
                      ""
                    )}
                </div>
              </div>
            }
          </div>
          {(!payroll_updated || loader) ?
            <TableShimer cols={5} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(8,minmax(155px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                <p>
                  {selectedLanguage?.name == "English" ? "Employee Name" : translation[420]?.value ? translation[420]?.value : "Employee Name"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Income Type" : translation[432]?.value ? translation[432]?.value : "Income Type"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Total Hrs Worked" : translation[433]?.value ? translation[433]?.value : "Total Hrs Worked"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Total Salary" : translation[1309]?.value ? translation[1309]?.value : "Total Salary"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Total Comission" : translation[1308]?.value ? translation[1308]?.value : "Total Comission"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Total Tips" : translation[352]?.value ? translation[352]?.value : "Total Tips"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Total Earning" : translation[1336]?.value ? translation[1336]?.value : "Total Earning"}
                </p>
                {/* <p>Status</p> */}
              </div>
              <div className={`odd-bg text-[#3A3A3A] transition-all min-w-full w-fit`}>
                {filterPayroll && filterPayroll?.length > 0 ? (
                  filterPayroll?.map((item, i) => {
                    const start_t = item?.employee_info?.start_time
                      ?.split(":")
                      .slice(0, 2)
                      .join(".");
                    const end_t = item?.employee_info?.end_time
                      ?.split(":")
                      .slice(0, 2)
                      .join(".");
                    let working_hours = end_t * 1 - start_t * 1;

                    return (
                      <>
                        <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(8,minmax(155px,1fr))] min-w-full w-fit">
                          <div
                            className={`before-left-arrow cursor-pointer ${ShowDetails == i + 1
                              ? "before-left-arrow-open"
                              : "before-left-arrow-close"
                              }`}
                            onClick={() => {
                              setShowDetails(ShowDetails != i + 1 ? i + 1 : 0);
                            }}
                          >
                            <div className="h-full flex items-center">
                              <p className="text-sm">{item?.full_name}</p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">
                              {firstday}, 01 {selectedMonth} - {lastDay} {days} {selectedMonth},{" "}
                              {selectYear}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm">
                              {item?.employee_info?.income_type === "Daily_Income"
                                ? "Daily"
                                : item?.employee_info?.income_type === "Hourly_Rate"
                                  ? "Hourly"
                                  : item?.employee_info?.income_type === "Monthly_Salary"
                                    ? "Monthly"
                                    : ""}
                            </p>
                          </div>

                          <div className="flex items-center">
                            <p className="text-sm line-clamp-1 pr-3">
                              {item?.total_hours
                                ? item?.total_hours?.toString()?.replace(".", ":")
                                : "----"}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm line-clamp-1 pr-3">
                              {item?.total_earning?.toFixed(2)} {Currency}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm line-clamp-1 pr-3">
                              {item?.total_commission?.toFixed(2)} {Currency}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm line-clamp-1 pr-3">
                              {item?.total_tips?.toFixed(2)} {Currency}
                            </p>
                          </div>
                          <div className="flex items-center">
                            <p className="text-sm line-clamp-1 pr-3">
                              {(+item?.total_earning + +item?.total_tips)?.toFixed(2)} {Currency}
                            </p>
                          </div>
                          {/* <div className="">
                          <StatusBtn status={"Pending"} />
                        </div> */}
                        </div>
                        {ShowDetails == i + 1
                          ? item?.schedule
                            ?.filter((dt) => {
                              if (
                                moment(dt?.date).format("MMMM") === DateRange
                              ) {
                                return true;
                              }
                            })
                            .map((schl, ind) => {
                              return (
                                <div className="bg-[#FAF9FF] scale-in-center">
                                  <div className="bg-[#FAF9FF] px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(155px,1fr))]">
                                    <div className="flex items-center gap-12">
                                      <div className="flex items-center gap-3 col-span-1">
                                        {/* <img
                                        src={`${item?.image
                                          ? item?.image
                                          : `${process.env.PUBLIC_URL}/images/profile_img.svg`
                                          }`}
                                        className="h-[4.375rem] w-[4.375rem] object-cover rounded-lg"
                                      />
                                      <div className="flex flex-col gap-2 flex-1">
                                        <p className="text-black font-semibold">
                                          {item?.full_name}
                                        </p>
                                      </div> */}
                                      </div>
                                      {/* <div className="h-full w-[1px] bg-[#3F3F441A] mr-3"></div> */}
                                    </div>
                                    <div className="grid grid-cols-7 col-span-3 gap-5">
                                      <div className="col-span-2">
                                        <div className="flex flex-col gap-4">
                                          <div>
                                            <h2 className="text-[#949494] text-sm">
                                              {selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}
                                            </h2>
                                            <p className="text-sm">
                                              {moment(schl?.date).format(
                                                "DD-MM-YYYY"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-span-2">
                                        <div className="flex flex-col gap-4">
                                          <div>
                                            <h2 className="text-[#949494] text-sm">
                                              {selectedLanguage?.name == "English" ? "Total Hours Worked Per Day" : translation[435]?.value ? translation[435]?.value : "Total Hours Worked Per Day"}

                                            </h2>
                                            <p className="text-sm">
                                              {/* {schl?.is_vacation
                                              ? 0
                                              : schl?.total_hours
                                                ?.split(":")
                                                .slice(0, 2)
                                                ?.join(":")}{" "}
                                            hours */}
                                              {schl?.is_holiday === true ? 'Holiday' :
                                                schl?.is_weekend === true ? 'Weekend' :
                                                  schl?.is_vacation === true
                                                    ? selectedLanguage?.name == "English" ? "Vacation" : translation[442]?.value ? translation[442]?.value : "Vacation"
                                                    : schl?.is_leave === true
                                                      ? selectedLanguage?.name == "English" ? "Absent" : translation[441]?.value ? translation[441]?.value : "Absent"
                                                      : schl?.total_hours ? `${schl?.total_hours && schl?.total_hours?.toString()?.replace(".", ":")} 
                                            ${selectedLanguage?.name == "English" ? "Working Time" : translation[438]?.value ? translation[438]?.value : "Working Time"}
                                                
                                                ` : null}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-span-3 flex flex-col gap-3">
                                        <div className="flex flex-col gap-4">
                                          <div>
                                            <h2 className="text-[#949494] text-sm">
                                              {selectedLanguage?.name == "English" ? "Working Time" : translation[437]?.value ? translation[437]?.value : "Working Time"}
                                            </h2>
                                            <p className="text-sm">
                                              {schl?.is_holiday === true ? 'Holiday' :
                                                schl?.is_weekend === true ? 'Weekend' :
                                                  schl?.is_vacation === true ? selectedLanguage?.name == "English" ? "Vacation" : translation[442]?.value ? translation[442]?.value : "Vacation"
                                                    : schl?.is_leave === true ? selectedLanguage?.name == "English" ? "Absent" : translation[441]?.value ? translation[441]?.value : "Absent" :
                                                      (`${moment(schl?.start_time, ["HH:mm:ss",]).format("hh:mm A")} - ${moment(schl?.end_time, ["HH:mm:ss",]).format("hh:mm A")}`)
                                              }
                                            </p>
                                          </div>
                                        </div>
                                        {/* {!employee ||
                                        (employee &&
                                          employeePermissions?.employee_payroll?.includes(
                                            "create"
                                          )) ||
                                        employeePermissions?.employee_payroll?.includes(
                                          "edit"
                                        )
                                        ? ind ===
                                        item?.schedule?.length - 1 && (
                                          <div className="flex flex-col items-end gap-4">
                                            <LoginBtn
                                              disabled={true}
                                              text="Pay Now"
                                            />
                                          </div>
                                        )
                                        : ""} */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </>
                    );
                  })
                ) : (
                  <>
                    <NoPayrollFound />
                  </>
                )}
              </div>
            </div>
          }
          {/* Pagination */}
          {(!loader && filterPayroll?.length > 0) &&
            <Pagination
              currentPage={currentPage}
              totalCount={Math.ceil(pages)}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          }
        </div>
      </div>
      <Popup
        heading={`Import Payrolls`}
        close={setImportPayroll}
        open={ImportPayroll}
      >
        <div className="flex flex-col gap-4 border-[#A1A1A1] border-dashed border-2 p-4 rounded-lg text-center">
          <div className="h-[15rem]">
            <Svgs.UploadList />
          </div>
          <p className="text-[#595959]">Drop your .CSV file to add Payrolls</p>
          <p className="text-[#595959]">Or</p>
          <LoginBtn size="sm" className="mx-auto">
            <label htmlFor="csvFileforproduct" className="cursor-pointer">
              <div className="flex items-center gap-2 text-[#ffffff]">
                Upload New List
              </div>
            </label>
          </LoginBtn>
        </div>
      </Popup>
      {isDelete && (
        <Popup
          heading="Are you sure you want to delete this Payroll?"
          onclose={() => {
            setDelete(false);
          }}
        >
          <div className="">
            <p>
              Are you sure you want to delete this Payroll? You won't be able to see
              this again.
            </p>
            <div className="flex items-center justify-start mt-[2rem]">
              <LoginBtn
                deleteBtn
                text="Yes! Delete"
                onClick={() => payrollDeleteHandler()}
              />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default Payroll;
