import { ADD_BUSINESS_BRAND, DELETE_BUSINESS_BRAND, GET_BUSINESS_BRANDS, GET_BUSINESS_BRANDS_DROPDOWNLIST, GET_BUSINESS_BRANDS_DROPDOWNLIST_PAGINATION, GET_BUSINESS_BRANDS_WITHOUT_PAGINATION, UPDATE_BUSINESS_BRAND } from "../../ActionTypes/BrandTypes"

const initialState = {
    brands: [],
    brands_updated: false,
    allCount: 0,
    pages: 0,
    brandsDropdown: [],
    brandsPagination: {},
    brandsDropdownWithoutSearch: [],
    all_brands: []
}

const BrandReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_BUSINESS_BRAND:
            return {
                ...state,
                brands: [
                    ...state.brands.map(itm => {
                        if (itm.id == action.payload.id) {
                            return action.payload
                        }
                        return itm
                    })
                ],
                brands_updated: true
            }
        case DELETE_BUSINESS_BRAND:
            return {
                ...state,
                brands: [
                    ...state.brands.filter(itm => {
                        if (itm.id != action.payload.id) {
                            return action.payload
                        }
                    })
                ],
                brands_updated: true
            }
        case ADD_BUSINESS_BRAND:
            return {
                ...state,
                brands: [
                    action.payload,
                    ...state.brands,
                ],
                brands_updated: true
            }
        case GET_BUSINESS_BRANDS:
            return {
                ...state,
                brands: action.payload.response.brands,
                brands_updated: true,
                pages: action.payload.pages,
                allCount: action.payload.count
            }
        case GET_BUSINESS_BRANDS_DROPDOWNLIST:
            return {
                ...state,
                brands: action.payload,
                brands_updated: true,
            }
        case GET_BUSINESS_BRANDS_DROPDOWNLIST_PAGINATION:
            const uniqueBrandWithSearch = Array.from(
                new Set([...state?.brandsDropdown, ...action.payload?.response?.brands].map(brand => brand.id))).map(id => {
                    return [...state?.brandsDropdown, ...action.payload?.response?.brands].find(brand => brand.id === id);
                });

            const uniqueBrandWithoutSearch = Array.from(
                new Set([...state?.brandsDropdownWithoutSearch, ...action.payload?.response?.brands]?.map(brand => brand.id))).map(id => {
                    return [...state?.brandsDropdownWithoutSearch, ...action.payload?.response?.brands]?.find(brand => brand.id === id);
                });
            return {
                ...state,
                // brandsDropdown: action.payload?.is_searched ? action.payload?.response?.brands
                //     : [...state?.brandsDropdownWithoutSearch, ...action.payload?.response?.brands],

                brandsDropdown: action.payload?.is_searched
                    ? (Math.ceil(+action.payload?.pages) > 1 && +action.payload?.current_page > 1)
                        ? uniqueBrandWithSearch
                        : action.payload?.response?.brands
                    : action.payload?.current_page == 1
                        ? action.payload?.response?.brands
                        : uniqueBrandWithoutSearch,

                // : [...state?.brandsDropdown, ...action.payload?.response?.brands],
                // : action.payload?.response?.brands

                brandsDropdownWithoutSearch: action.payload?.current_page == 1 ? action.payload?.response?.brands
                    : uniqueBrandWithoutSearch,

                brandsPagination: {
                    total: action.payload?.count,
                    total_pages: Math.ceil(+action.payload?.pages),
                    currentPage: +action.payload?.current_page,
                    isSearched: action.payload?.is_searched
                }
            }

        case GET_BUSINESS_BRANDS_WITHOUT_PAGINATION:
            return {
                ...state,
                all_brands: action.payload,
            }
        default:
            return state
    }
}
export default BrandReducer