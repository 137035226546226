import { useLocation } from "new-components/location/helper";
import { getEmployeeDropDownListPagination } from "../../../../../Adapters/Api/employee";
import { getEmployeeUpsellDownsellReport, getUpAndDownSaleStoreReport } from "../../../../../Adapters/Api/payroll";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

let employeeTimeOutId = undefined
const PageSize = 10

function useUpsellDownsell() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { locationDetail } = useLocation();

  const state = useSelector((state) => state);
  const up_down_sell = state?.payroll?.upsell_downsell;
  const up_down_sell_store = state?.payroll?.upsell_downsell_store;
  const pages = state?.payroll?.upsell_downsell_pages;
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const employeePaginationData = state?.employee?.employeeDropdownPaginationData
  const Currency = locationDetail?.currency?.code
  const selected_location = state?.locations.selected_location
  const employeeRedux = state?.employee?.employeesDropdown
  const reduxlocationsList = state?.locations?.locations;
  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  // const store_pages = state?.payroll?.upsell_downsell_store_pages;
  // console.log("🚀 ~ useUpsellDownsell ~ selected_location:", selected_location)

  const [searchParams, setSearchParams] = useSearchParams();
  const active_tab = searchParams.get("active_tab");

  //get only that employee whos belong to selected location
  const [selectedLocation, setSelectedLocation] = useState("")
  const [currentPage, setCurrentPage] = useState(1);
  const [exportLoader, setExportLoader] = useState(false);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [ImportPayroll, setImportPayroll] = useState(false);
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [employee, setEmployee] = useState();
  const [loader, setLoader] = useState(true)
  const [employeeLoader, setEmployeeLoader] = useState(true)
  const [employeePage, setEmployeePage] = useState(1)
  const [backButtonClick, setBackButtonClick] = useState(false)
  const [loadingPermission, setLoadingPermission] = useState(true)
  const [toggleDateRange, setToggleDateRange] = useState(false)
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [month, setMonth] = useState();
  // const [employeePermissions, setEmployeePermissions] = useState([]);

  const tabList = [
    {
      value: "employee",
      label: selectedLanguage?.name == "English" ? "employee" : translation[233]?.value ? translation[233]?.value : "employee"
    },
    {
      value: "store",
      label: selectedLanguage?.name == "English" ? "store" : translation[180]?.value ? translation[180]?.value : "store"
    },
  ];

  let payrollHeader = [
    { label: selectedLanguage?.name == "English" ? "Employee Name" : translation[420]?.value ? translation[420]?.value : "Employee Name", key: "employee_name" },
    { label: selectedLanguage?.name == "English" ? "Before Service" : translation[1692]?.value ? translation[1692]?.value : "Before Service", key: "old_price" },
    { label: selectedLanguage?.name == "English" ? "After Service" : translation[1693]?.value ? translation[1693]?.value : "After Service", key: "new_price" },
    { label: selectedLanguage?.name == "English" ? "Price Difference" : translation[1694]?.value ? translation[1694]?.valu : "Price Difference", key: "price_difference" },
    { label: selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Staus", key: "status" },
    { label: selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date", key: "created_at" },
  ];

  useEffect(() => {
    // custom browser back button functionality implemented
    const handlePopstate = () => {
      setBackButtonClick(true)
      navigate('/dashboard/pos-analytics');
    };
    window.addEventListener('popstate', handlePopstate);
    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  useEffect(() => {
    if (!backButtonClick && !(active_tab && !tabList.includes(active_tab))) {
      setSearchParams({ ...searchParams, active_tab: tabList[0]?.value });
    }
  }, [active_tab, backButtonClick]);

  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    // setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  //hit employee and payroll api here
  useEffect(() => {
    if (active_tab == 'employee') {
      getUpAndDownSell()
    }
  }, [currentPage, startDate, endDate]);


  useEffect(() => {
    if (currentPage == 1) {
      // console.log("🚀 ~ useEffect ~ currentPage:", currentPage)
      getUpAndDownSell()
      if (active_tab == 'employee') {
        getUpAndDownSell()
      }
      // console.log("🚀 ~ useEffect:", currentPage)
    } else {
      setCurrentPage(1)
    }
  }, [selected_location, selectedEmployee])

  //hit Store  api here
  useEffect(() => {
    if (active_tab == 'store') {
      getUpAndDownSellStore()
    }
  }, [currentPage, startDate, endDate, selectedLocation, month]);


  useEffect(() => {
    if (currentPage == 1) {
      if (active_tab == 'store') {
        getUpAndDownSellStore()
      }
    } else {
      setCurrentPage(1)
    }
  }, [selected_location, selectedEmployee, selectedLocation])

  useEffect(() => {
    // get employee dropdown api
    if (selected_location) {
      if (employeeSearch === null) {
        // Initial load with empty search_text
        dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, "", setEmployeeLoader))
      } else {
        if (employeeTimeOutId) {
          clearTimeout(employeeTimeOutId)
        }
        employeeTimeOutId = setTimeout(() => {
          dispatch(getEmployeeDropDownListPagination(employeePage, selected_location, employeeSearch, setEmployeeLoader))
        }, 500);
      }
    }
  }, [employeeSearch, selected_location, employeePage])

  // data export function
  const payrollData = {
    filename: "Employee Payroll Report.csv",
    headers: payrollHeader,
    data:
      up_down_sell?.length > 0
        ? up_down_sell?.map((item) => {
          return {
            employee_name: item?.employee_name,
            old_price: item?.old_price,
            new_price: item?.new_price,
            price_difference: item?.price_difference,
            status: item?.status,
            created_at: item?.created_at,
          };
        })
        : [],
  };

  // export click
  const handleExportClick = () => {
    if (!isToastVisible) {
      if (up_down_sell?.length > 0) {
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  // get api for up and down sell for employee
  const getUpAndDownSell = (page) => {
    const payload = {
      page: page ? page : currentPage,
      location: selected_location,
      employee: selectedEmployee,
      start_date: startDate,
      end_date: endDate,
    }
    setLoader(true)
    const success = () => { setLoader(false) }
    const fail = () => { setLoader(false) }
    dispatch(getEmployeeUpsellDownsellReport(payload, success, fail));
  }

  // get api for up and down sell for store
  const getUpAndDownSellStore = () => {
    const payload = {
      // page: page ? page : currentPage,
      location: selectedLocation,
      month: month,
      start_date: startDate,
      end_date: endDate,
    }
    setLoader(true)
    const success = () => { setLoader(false) }
    const fail = () => { setLoader(false) }
    dispatch(getUpAndDownSaleStoreReport(payload, success, fail));
  }

  return {
    loader,
    up_down_sell,
    up_down_sell_store,
    // store_pages,
    pages,
    currentPage,
    translation,
    selectedLanguage,
    employeePaginationData,
    Currency,
    employeeLoader, employeeRedux,
    setToggleDateRange, toggleDateRange,
    tabList,
    active_tab,
    setSearchParams,
    searchParams,
    startDate, endDate,
    setStartDate, setEndDate,
    month, setMonth,
    setEmployeeSearch, employeeSearch,
    selectedEmployee, setSelectedEmployee, employee, payrollData,
    exportLoader, handleExportClick, setCurrentPage, loadingPermission,
    setEmployeePage, employeePage, setImportPayroll, ImportPayroll, PageSize,
    selectedLocation, setSelectedLocation, reduxlocationsList

  };
}

export default useUpsellDownsell;
