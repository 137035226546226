import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";

import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
import NotFound from "../../Elements/NotFound";

import { getSaleOrderProduct } from "Adapters/Api/saleOrders";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import moment from 'moment';
import useSaleOrderProduct from "./helper";

const Products = ({ filter_data, toggleInviceModal, setLengths }) => {
  const { productList, calculate_totals, location_currency } = useSaleOrderProduct()

  const dispatch = useDispatch();

  const { saleOrder, business, locations } = useSelector((state) => state);
  // const businessCurrency = business.business?.currency?.code;
  const { saleOrderProducts } = saleOrder;
  const { selected_location } = locations;

  const [dataList, setDataList] = useState([]);

  const filterProducts = () => {
    return productList?.filter((itm) => {
      if (Object.keys(filter_data).length > 0) {
        let returned_value = false;
        if (filter_data?.text) {
          let value = filter_data?.text.toLowerCase();
          if (
            itm?.product[0]?.client?.full_name?.toLowerCase().includes(value) ||
            itm?.product[0]?.member?.full_name?.toLowerCase().includes(value)
          ) {
            returned_value = true;
          } else {
            return false;
          }
        }
        if (filter_data?.client) {
          if (filter_data?.client == itm?.product[0]?.client?.id) {
            returned_value = true;
          } else {
            return false;
          }
        }
        if (filter_data?.location) {
          if (filter_data?.location == itm?.product[0]?.location?.id) {
            returned_value = true;
          } else {
            return false;
          }
        }
        if (filter_data?.member) {
          if (filter_data?.member == itm?.product[0]?.member?.id) {
            returned_value = true;
          } else {
            return false;
          }
        }
        return returned_value;
      }
      return true;
    });
  };

  useEffect(() => {
    saleOrderProducts.length === 0 && dispatch(getSaleOrderProduct());
    setLengths(filterProducts().length)
  }, [filterProducts().length]);

  useEffect(() => {
    saleOrderProducts.length === 0 && dispatch(getSaleOrderProduct());
    const val = saleOrderProducts?.filter(
      (ele) => ele.location.id === selected_location
    );
    setDataList(val);
  }, [selected_location]);

  return (
    <>
      <div className="overflow-x-auto">
        <div className="inline-grid xl:grid grid-cols-[repeat(7,minmax(140px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
          <p>Invoice#</p>
          <p>Client </p>
          <p>Status</p>
          <p>Invoice Date</p>
          <p>Staff</p>
          <p>Location</p>
          <p>Gross Total</p>
        </div>
        {filterProducts()?.length > 0 ? (
          filterProducts()?.map((prdct, index) => {
            let totalPrice = calculate_totals(prdct)
            return (
              <div
                className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}
                key={index}
              >
                <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(7,minmax(140px,1fr))] gap-3">
                  <div className={`before-left-arrow cursor-pointer`}>
                    <div className="h-full flex items-center">
                      <p
                        className="text-sm text-primary line-clamp-1"
                        onClick={() => {
                          toggleInviceModal(prdct?.id, "Products", prdct)
                          calculate_totals(prdct, true)
                        }}
                      >
                        # {prdct?.id}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <p className="text-sm line-clamp-1 pr-3">
                      {prdct?.product[0]?.client?.full_name ? prdct?.product[0]?.client?.full_name : "----"}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <StatusBtn
                      rounded="rounded-md"
                      status="active"
                      size="sm"
                      text={prdct?.product[0]?.status}
                    />
                  </div>
                  <div className="flex items-center">
                    <p className="text-sm">
                      {
                        moment(prdct?.product[0]?.created_at).format('YYYY-MM-DD')
                      }
                      {/* <Moment fromNow>{prdct?.created_at}</Moment>
                       */}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-sm ">{prdct?.product[0]?.member?.full_name ? prdct?.product[0]?.member?.full_name : "----"}</p>
                  </div>
                  <div className="flex items-center">
                    <p className="text-sm ">{prdct?.product[0]?.location?.address_name ? prdct?.product[0]?.location?.address_name : "----"}</p>
                  </div>
                  {/* <div className='flex items-center'>
                                        <p className='text-sm '>{prdct?.tip}</p>
                                    </div> */}
                  <div className="flex items-center">
                    {/* <p className='text-sm '>{prdct?.tip + prdct?.total_price}</p> */}
                    <p className="text-sm ">{totalPrice} {location_currency}</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <NotFound />
        )}
      </div>
    </>
  );
};

export default Products;
