import FindUsS from "Assets/svgs/Login/FindUsS.jsx";
import CheckboxCard from "../../../TenantApp/Elements/CheckboxCard";
import { useSelector } from "react-redux";

const FindUs = ({ onSelect, selected }) => {
  const state = useSelector((state) => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const team = [
    {
      value: "Recommended by a friend",
      label: selectedLanguage?.name == "English" ? "Recommended by a friend" : translation[704]?.value ? translation[704]?.value : "Recommended by a friend",
    },
    {
      value: "Search engine (eg. Google, Yahoo)",
      label: selectedLanguage?.name == "English" ? "Search engine (eg. Google, Yahoo)" : translation[705]?.value ? translation[705]?.value : "Search engine (eg. Google, Yahoo)",
    },
    {
      value: "Social Media",
      label: selectedLanguage?.name == "English" ? "Social media" : translation[706]?.value ? translation[706]?.value : "Social media",
    },
    {
      value: "Magazine Ad",
      label: selectedLanguage?.name == "English" ? "Magazine Ad" : translation[707]?.value ? translation[707]?.value : "Magazine Ad",
    },
    {
      value: "Ratings Website (eg. Capterra, Trustpilot)",
      label: selectedLanguage?.name == "English" ? "Ratings website (like Capterra, Trustpilot)" : translation[708]?.value ? translation[708]?.value : "Ratings website (like Capterra, Trustpilot)",
    },
    {
      value: "Other",
      label: selectedLanguage?.name == "English" ? "Other" : translation[709]?.value ? translation[709]?.value : "Other",
    },
  ];



  return (
    <div className="my-10 slide-in-right">
      <div className="flex flex-col gap-5 w-full lg:items-start items-center text-center lg:text-left">
        <FindUsS />
        <h3 className="font-semibold text-2xl">
          {selectedLanguage?.name == "English" ? "How Did You Find Us?" : translation[527]?.value ? translation[527]?.value : "How Did You Find Us?"}
        </h3>
        <p className="text-[#7B7B7B]">
          {selectedLanguage?.name == "English" ? "Please tell us how did you find NStyle." : translation[701]?.value ? translation[701]?.value : "Please tell us how did you find NStyle."}
          {" "}
        </p>
        <div className="flex flex-col gap-4 w-full">
          {team.map((title, id) => {
            return (
              <div className="w-full lg:w-[70%]" key={id}>
                <CheckboxCard
                  value={selected}
                  onClick={() => onSelect(title?.value)}
                  title={title?.label}
                  checked={selected === title?.value}
                  id={id}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FindUs;
