import axios from "axios";
import { getStaffGroups, getStaffGroupsWithoutPagination } from "../../../Redux/TenantRedux/Actions/staffGroupActions";
import { get_staff_group_url, add_staff_group_url, delete_staff_group_url, update_staff_group_url, import_staff_group, BASE_URL } from "../api_variables";


export const getStaffGroup = (currentPage, search_text, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const page = currentPage ? `?page=${currentPage}` : ''
  const search = search_text ? `&search_text=${search_text}` : ''
  try {
    const response = await axios.get(`${BASE_URL}${get_staff_group_url}${page}${search}`);
    if (response.status === 200) {
      let staffGroups = response?.data;
      dispatch(getStaffGroups(staffGroups));
      setLoader && setLoader(false)
    }
    setLoader && setLoader(false)
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const getStaffGroupWithNoPagination = (search_text, setLoader) => async (dispatch) => {
  setLoader && setLoader(true)
  const search = search_text ? `&search_text=${search_text}` : ''
  try {
    const response = await axios.get(`${BASE_URL}${get_staff_group_url}?no_pagination=true${search}`);
    if (response.status === 200) {
      let staffGroups = response?.data?.response?.staff_group;
      dispatch(getStaffGroupsWithoutPagination(staffGroups));
      setLoader && setLoader(false)
    }
    setLoader && setLoader(false)
  } catch (error) {
    setLoader && setLoader(false)
    return error;
  }
};

export const importStaffGroup = (data, access_token, success) => async (dispatch) => {
  try {
    const result = await axios.post(
      `${BASE_URL}${import_staff_group}`, data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );

    if (result?.status == 200) {
      success && success()
    }

  } catch (error) {
    return error;
  }
};





export const createStaffGroup = async (staff_group_data, access_token) => {

  try {
    const result = await axios.post(
      `${BASE_URL}${add_staff_group_url}`, staff_group_data,
      {
        headers: { Authorization: `Token ${access_token}` },
      }
    );
    return result;
  } catch (error) {
    return error;
  }
};


export const updateStaffGroup = async (staff_group_data, access_token) => {
  try {
    const result = await axios.put(`${BASE_URL}${update_staff_group_url}`, { ...staff_group_data },
      {
        headers: { 'Authorization': `Token ${access_token}` },
      });
    return result;
  } catch (error) {
    return error;
  }
}


export const deleteStaffGroup = async (staff_group_id, access_token) => {
  try {
    let result = await fetch(`${BASE_URL}${delete_staff_group_url}`, {
      method: "DELETE",
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Token ${access_token}`
      },
      body: JSON.stringify(
        { staff_id: staff_group_id }
      )
    })
    result = result.json();
    return result;

  } catch (error) {
    return error;
  }

}