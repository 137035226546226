import Svgs from 'Assets/svgs';
import CircleIcon from 'Components/TenantApp/Elements/CircleIcon';
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown';
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn';
import Popup from 'Components/TenantApp/Elements/Popup';

import React from 'react'


const AddFilter = ({ FilterPopup, setFilterPopup }) => {
  
    return (
        <Popup heading="Filter" open={FilterPopup} close={setFilterPopup}>
          <div className="flex flex-col gap-5">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
              <CircleIcon>
                <Svgs.FilterPopup />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  Search By Filters
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Dolorem, nobis.s <br className="hidden md:block" /> Lorem.
                </p>
              </div>
            </div>
            <div className="grid xs:grid-cols-2 gap-5">
              <div>
                <Dropwdown
                  title="Unsubscribe Type"
                  placeholder="Select Unsubscribe Type"
                  options={
                  [
                  {  
                      label: "Dynamic",
                      value: "Dynamic",
                  },
                  {
                      label: "Static",
                      value: "static",
                  }]
                  }
                  required={true}
                />
              </div>
              <div>
                <Dropwdown title="Status" placeholder="Select Status"  options={
                  [
                  {  
                      label: "Dynamic",
                      value: "Dynamic",
                  },
                  {
                      label: "Static",
                      value: "static",
                  }]
                  }
                  required={true}/>
              </div>
            </div>
            <div className='flex gap-3'>
                <LoginBtn text="Search" />
                <LoginBtn text="Clear Filter" />
            </div>
          </div>
        </Popup>
)};

export default AddFilter;