import { buildQueryString } from "Constants/Functions/buildQueryString";
import {
  BASE_URL,
  get_sales_analytics,
  get_customer_analytics,
  get_product_pos_analytics_url,
  get_finance_analytics,
  get_payment_method_reports,
} from "../api_variables";
import axios from "axios";

export const getPOSSales = (data, success, fail) => {
  let query = '?'
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  for (let dt in data) {
    if (data[dt]) {
      query += `${dt}=${data[dt]}&`
    }
  }
  fetch(BASE_URL + get_sales_analytics + `${query}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${access_token}`,
    }
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result?.status_code == 200) {
        success && success(result);
      } else {
        fail && fail(result);
      }
    })
    .catch((err) => {
      fail && fail(err);
    });
};

export const getPOSCustomer = (data, success, fail) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  let query = buildQueryString(data)
  fetch(BASE_URL + get_customer_analytics + `${query}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${access_token}`,
    }
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result?.status_code == 200) {
        success && success(result);
      } else {
        fail && fail(result);
      }
    })
    .catch((err) => {
      fail && fail(err);
    });
};

export const getPOSProductAnalytics = async (data, success, fail) => {
  try {
    const query = buildQueryString(data)
    let headers = {
      "Content-type": "application/json",
      // headers: { Authorization: `Token ${access_token}` },
    };
    const result = await axios.get(`${BASE_URL}${get_product_pos_analytics_url}${query}`, headers);
    if (result?.status === 200) {
      const data = result?.data
      success && success(data)
    } else {
      fail && fail(result)
    }
    return result;
  } catch (error) {
    fail && fail(error);
    return error;
  }
}

export const getFinanceAnalyticsData = (data, success, fail) => {
  let query = '?'
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  for (let dt in data) {
    if (data[dt]) {
      query += `${dt}=${data[dt]}&`
    }
  }
  fetch(BASE_URL + get_finance_analytics + `${query}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${access_token}`,
    }
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result?.status_code == 200) {
        success && success(result);
      } else {
        fail && fail(result);
      }
    })
    .catch((err) => {
      fail && fail(err);
    });
};

export const getPaymentMethodsData = (data, success, fail) => {
  let access_token = localStorage.getItem("access_token");
  if (access_token) {
    access_token = access_token?.replaceAll('"', "");
  }

  const query = buildQueryString(data)
  fetch(BASE_URL + get_payment_method_reports + `${query}`, {
    method: "GET",
    headers: {
      Authorization: `Token ${access_token}`,
    }
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((result) => {
      if (result?.status_code == 200) {
        success && success(result);
      } else {
        fail && fail(result);
      }
    })
    .catch((err) => {
      fail && fail(err);
    });
};