import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CheckBox from "../../../Elements/Checkbox/CheckBox";
import CircleIcon from "../../../Elements/CircleIcon";
import DateInput from "../../../Elements/DateInput";
import Dropwdown from "../../../Elements/Dropwdown";
import LoginBtn from "../../../Elements/LoginBtn";
import Popup from "../../../Elements/Popup";
import StatusBtn from "../../../Elements/StatusBtn";
import LoginInput from "../../../Elements/LoginInput";

import Svgs from "Assets/svgs";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import IconButton from "Components/TenantApp/Elements/IconButton";

const HistoryLog = () => {
  const navigate = useNavigate();
  const [ShowDetails, setShowDetails] = useState(0);
  const [DateRange, setDateRange] = useState("");
  const [DateRangePopup, setDateRangePopup] = useState("");
  const [Filters, setFilters] = useState(false);

  const [Tab, setTab] = useState(1);

  useEffect(() => {
    if (DateRange == "Date") {
      setDateRangePopup("Date");
    } else if (DateRange != "") {
      setDateRangePopup("Month");
    }
  }, [DateRange]);

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

        <Breadcrumb
          first={'Employee Management'}
          url={'/dashboard/employee/'}
          second={'History Log'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              History Log
            </h2>
          </div>
          <div className="flex items-center sm:gap-4 gap-2">
            <LoginBtn
              borderBtn
              
            >
              <div className="flex items-center gap-2 text-primary">
                <Svgs.Import />
                {/* <p className='font-semibold'>Import List</p> */}
              </div>
            </LoginBtn>
            <LoginBtn
              
            >
              <div className="flex items-center gap-2">
                <Svgs.Plus />
                <p className="font-semibold">Save Settings</p>
              </div>
            </LoginBtn>
          </div>
        </div>
        <div className="mb-[1.5rem]">
          <div className="border-b flex items-end gap-5">
            <p
              onClick={() => {
                setTab(1);
              }}
              className={`text-sm cursor-pointer pb-3 border-b-[3px] font-semibold ${Tab == 1
                  ? "text-primary border-primary"
                  : "text-[#595A5A] border-transparent"
                }`}
            >
              Sales History
            </p>
            <p
              onClick={() => {
                setTab(2);
              }}
              className={`text-sm cursor-pointer pb-3 border-b-[3px] font-semibold ${Tab == 2
                  ? "text-primary border-primary"
                  : "text-[#595A5A] border-transparent"
                }`}
            >
              Leaves
            </p>
          </div>
        </div>
        <div className="border rounded-lg">
          {Tab == 1 ? (
            <>
              <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
                <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold text-xl">Sales History</h2>
                  </div>
                  <div className="mb-00">
                    <LoginInput
                      title=""
                      placeholder="Search"
                      padding="py-1"
                      leftIcon={<Svgs.Search />}
                    />
                  </div>
                </div>
                <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
                  <LoginBtn
                    borderBtn
                    
                    onClick={() => {
                      setFilters(!Filters);
                    }}
                  >
                    <div className="flex items-center gap-2 text-primary">
                      <Svgs.Filters />
                      <p>Filters</p>
                    </div>
                  </LoginBtn>
                </div>
              </div>
              <div className="overflow-x-auto">
                <div className="inline-grid xl:grid grid-cols-[repeat(9,minmax(130px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                  <p>Invoice Number</p>
                  <p>Client</p>
                  <p>Invoice Date</p>
                  <p>Billing Name</p>
                  <p>Location</p>
                  <p>Tips</p>
                  <p>Gross Total</p>
                  <p>Status</p>
                  <p>Actions</p>
                </div>
                <div
                  className={`odd-bg text-[#3A3A3A] transition-all ${ShowDetails
                      ? "border-l-[4px] border-[#5679FFB2]"
                      : "border-l-[0px]"
                    }`}
                >
                  {DateRangePopup != "Month" ? (
                    <>
                      <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(9,minmax(130px,1fr))]">
                        <div
                          className={`before-left-arrow cursor-pointer ${ShowDetails == 1
                              ? "before-left-arrow-open"
                              : "before-left-arrow-close"
                            }`}
                          onClick={() => {
                            setShowDetails(ShowDetails != 1 ? 1 : 0);
                          }}
                        >
                          <div className="h-full flex items-center">
                            <p className="text-sm">#1226087</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">Walk-In</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">
                            Fri, 22 July,2022
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">Salon</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">
                            Bahria Town...
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">AED 100</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">AED 100</p>
                        </div>
                        <div className="flex items-center">
                          <StatusBtn status="active" />
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center">
                            <Svgs.Delete fill="#7D7D7D" />
                          </div>
                        </div>
                      </div>
                      {ShowDetails ? (
                        <div className="scale-in-center">
                          <div className="bg-[#FAF9FF] px-6 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(145px,1fr))]">
                            <div className="flex flex-col gap-4">
                              <div>
                                <h2 className="text-[#949494] text-sm">
                                  Invoice #
                                </h2>
                                <p className="text-sm">#373737</p>
                              </div>
                              <div>
                                <h2 className="text-[#949494] text-sm">Tips</h2>
                                <p className="text-sm">January 20,2022</p>
                              </div>
                            </div>

                            <div className="flex flex-col gap-4">
                              <div>
                                <h2 className="text-[#949494] text-sm">
                                  Client
                                </h2>
                                <p className="text-sm">Walk in</p>
                              </div>
                              <div>
                                <h2 className="text-[#949494] text-sm">
                                  Gross Total
                                </h2>
                                <p className="text-sm">January 20,2022</p>
                              </div>
                            </div>

                            <div className="flex flex-col gap-4">
                              <div>
                                <h2 className="text-[#949494] text-sm">
                                  Invoice Date
                                </h2>
                                <p className="text-sm">
                                  Lorem ipsum dolor sit.
                                </p>
                              </div>
                            </div>

                            <div className="flex flex-col gap-4">
                              <div>
                                <h2 className="text-[#949494] text-sm">
                                  Billing Name
                                </h2>
                                <p className="text-sm">
                                  Lorem ipsum dolor sit.
                                </p>
                              </div>
                            </div>

                            <div className="flex items-start justify-between gap-4">
                              <div>
                                <h2 className="text-[#949494] text-sm">
                                  Location
                                </h2>
                                <p className="text-sm">January 20,2022</p>
                              </div>
                              <div className="flex-col flex gap-4">
                                <div className="h-[2.5rem] cursor-pointer w-[2.5rem] border border-[#00AC6E] rounded-full flex items-center justify-center">
                                  <Svgs.Pen fill="#00AC6E" />
                                </div>
                                <div className="h-[2.5rem] w-[2.5rem] border border-[#FF3030] rounded-full flex items-center justify-center">
                                  <Svgs.Delete fill="#FF3030" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))]">
                        <div className={`cursor-pointer`}>
                          <div className="h-full flex items-center">
                            <p className="text-sm">HistoryLog 1</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">120</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">120</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">AED 1200</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">50%</p>
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center">
                            <Svgs.Delete fill="#7D7D7D" />
                          </div>
                        </div>
                      </div>
                      <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))]">
                        <p>Total</p>
                        <p></p>
                        <p></p>
                        <p>216 Hrs</p>
                        <p>AED 5,400</p>
                        <LoginBtn text="Pay Now" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
                <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 flex-wrap">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold text-xl">Leaves</h2>
                  </div>
                  <div className="mb-00">
                    <LoginInput
                      title=""
                      placeholder="Search"
                      padding="py-1"
                      leftIcon={<Svgs.Search />}
                    />
                  </div>
                </div>
                <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
                  <LoginBtn
                    borderBtn
                    onClick={() => {
                      setFilters(!Filters);
                    }}
                  >
                    <div className="flex items-center gap-2 text-primary">
                      <Svgs.Filters />
                      <p>Filters</p>
                    </div>
                  </LoginBtn>
                  <IconButton filled>
                    <Svgs.Export />
                  </IconButton>
                </div>
              </div>
              <div className="overflow-x-auto">
                <div className="inline-grid xl:grid grid-cols-[repeat(8,minmax(130px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                  <p>Employee Name</p>
                  <p>Designation</p>
                  <p>Leave Type</p>
                  <p>From</p>
                  <p>To</p>
                  <p>Total Days</p>
                  <p>Status</p>
                  <p>Actions</p>
                </div>
                <div
                  className={`odd-bg text-[#3A3A3A] transition-all ${ShowDetails
                      ? "border-l-[4px] border-[#5679FFB2]"
                      : "border-l-[0px]"
                    }`}
                >
                  {DateRangePopup != "Month" ? (
                    <>
                      <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(8,minmax(130px,1fr))]">
                        <div
                          className={`before-left-arrow cursor-pointer ${ShowDetails == 1
                              ? "before-left-arrow-open"
                              : "before-left-arrow-close"
                            }`}
                          onClick={() => {
                            setShowDetails(ShowDetails != 1 ? 1 : 0);
                          }}
                        >
                          <div className="h-full flex items-center">
                            <p className="text-sm">#1226087</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">Walk-In</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">
                            Fri, 22 July,2022
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">Salon</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">
                            Bahria Town...
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">AED 100</p>
                        </div>
                        <div className="flex items-center">
                          <StatusBtn status="active" />
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center">
                            <Svgs.Delete fill="#7D7D7D" />
                          </div>
                        </div>
                      </div>
                      {ShowDetails ? (
                        <div className="scale-in-center">
                          <div className="bg-[#FAF9FF] px-6 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(145px,1fr))]">
                            <div className="flex flex-col gap-4">
                              <div>
                                <h2 className="text-[#949494] text-sm">
                                  Employee Name
                                </h2>
                                <p className="text-sm">#373737</p>
                              </div>
                            </div>

                            <div className="flex flex-col gap-4">
                              <div>
                                <h2 className="text-[#949494] text-sm">
                                  Designation
                                </h2>
                                <p className="text-sm">Walk in</p>
                              </div>
                            </div>

                            <div className="flex flex-col gap-4">
                              <div>
                                <h2 className="text-[#949494] text-sm">
                                  Leave Type
                                </h2>
                                <p className="text-sm">
                                  Lorem ipsum dolor sit.
                                </p>
                              </div>
                            </div>

                            <div className="flex flex-col gap-4">
                              <div>
                                <h2 className="text-[#949494] text-sm">From</h2>
                                <p className="text-sm">
                                  Lorem ipsum dolor sit.
                                </p>
                              </div>
                            </div>

                            <div className="flex items-start justify-between gap-4">
                              <div>
                                <h2 className="text-[#949494] text-sm">To</h2>
                                <p className="text-sm">January 20,2022</p>
                              </div>
                              <div className="flex-col flex gap-4">
                                <div className="h-[2.5rem] w-[2.5rem] border border-[#FF3030] rounded-full flex items-center justify-center">
                                  <Svgs.Delete fill="#FF3030" />
                                </div>
                              </div>
                            </div>

                            <div className="flex flex-col gap-4 mt-[1.5rem] col-span-5">
                              <div>
                                <h2 className="text-[#949494] text-sm">
                                  Reason
                                </h2>
                                <p className="text-sm">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text
                                  ever since the 1500s, when an unknown printer
                                  took a galley of.Lorem Ipsum is simply dummy
                                  text of the printing and typesetting industry.
                                  Lorem Ipsum has been the industry's standard
                                  dummy text ever since the 1500s, when an
                                  unknown printer took a galley of.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))]">
                        <div className={`cursor-pointer`}>
                          <div className="h-full flex items-center">
                            <p className="text-sm">HistoryLog 1</p>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">120</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">120</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">AED 1200</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm line-clamp-1 pr-3">50%</p>
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center">
                            <Svgs.Delete fill="#7D7D7D" />
                          </div>
                        </div>
                      </div>
                      <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(155px,1fr))]">
                        <p>Total</p>
                        <p></p>
                        <p></p>
                        <p>216 Hrs</p>
                        <p>AED 5,400</p>
                        <LoginBtn text="Pay Now" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {DateRangePopup == "Date" && (
        <Popup
          heading="Date Range"
          open={DateRangePopup}
          close={setDateRangePopup}
        >
          <div className="flex flex-col gap-5">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
              <CircleIcon>
                <Svgs.Inventory_Category />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  Date Range
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  Get the results based on applied date range
                </p>
              </div>
            </div>
            <div className="flex items-center gap-5">
              <div className="flex-1">
                <DateInput title="From" />
              </div>
              <div className="flex-1">
                <DateInput title="To" />
              </div>
            </div>
            <div className="flex items-center justify-center">
              <LoginBtn text="Select" />
            </div>
          </div>
        </Popup>
      )}
      {Filters && (
        <Popup heading="Filters" close={setFilters} open={Filters}>
          <div className="flex flex-col gap-6">
            <Dropwdown title="Vendors" placeholder="Select Vendors" />
            <Dropwdown title="Brand" placeholder="Select Brand" />
            <Dropwdown title="Category" placeholder="Select Category" />
            <LoginInput title="Vendors Name" placeholder="Enter Vendors Name" />

            <div className="flex flex-col gap-3">
              <h2 className="font-semibold">Stock</h2>
              <CheckBox label="Low Stock" />
              <CheckBox label="High Stock" />
            </div>

            <div className="flex flex-col gap-3">
              <h2 className="font-semibold">Top Selling Items</h2>
              <CheckBox label="Top Sellable Items" />
            </div>

            <div className="flex flex-col gap-2">
              <h2 className="font-semibold">Top Selling Items</h2>
              <div className="flex items-center gap-5">
                <div className="flex-1">
                  <div className="border rounded-lg p-3">
                    <input
                      type="date"
                      placeholder="asudhas"
                      className="outline-none w-full text-[#7B7B7B]"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <div className="border rounded-lg p-3">
                    <input
                      type="date"
                      placeholder="asudhas"
                      className="outline-none w-full text-[#7B7B7B]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-start mt-[2rem]">
              <LoginBtn text="Apply Filters" />
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default HistoryLog;
