import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { getPaidUnpaidAppointments } from "Adapters/Api/apointments";

let timeOutId = undefined;
function usePaidUnpaidAppointment() {
    const dispatch = useDispatch()

    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const isPermissionUpdated = state?.user?.isPermissionUpdated;

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");
    const selectedLocationCurrency = getDataFromLocalStorage("selectedLocationCurrency");

    const [searchText, setSearchText] = useState("")
    const [data, setData] = useState([])
    const [toggleDateRange, setToggleDateRange] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [loader, setLoader] = useState(true)
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [pages, setPages] = useState(0)
    const [count, setCount] = useState(0)
    const [dropdownStatus, setDropdownStatus] = useState("")
    const [loadingPermission, setLoadingPermission] = useState(true)

    const PageSize = 10

    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])

    useEffect(() => {
        let data = {}
        if (startDate && endDate) {
            data = { page: currentPage, location_id: selected_location, start_date: startDate, end_date: endDate, is_paid: dropdownStatus }
        } else {
            data = { page: currentPage, location_id: selected_location, is_paid: dropdownStatus }
        }
        let success = (data) => {
            setLoader(false)
            setData(data?.response?.appointments)
            setCount(data?.count)
            setPages(data?.pages)
        }
        let fail = () => {
            setLoader(false)
        }
        if (selected_location) {
            if (searchText === null) {
                setLoader(true)
                // Initial load with empty search_text
                getPaidUnpaidAppointments(data, success, fail)
            } else {
                data = { ...data, search_text: searchText?.replace(/#/g, '') }
                if (timeOutId) {
                    clearTimeout(timeOutId)
                }
                timeOutId = setTimeout(() => {
                    setLoader(true)
                    getPaidUnpaidAppointments(data, success, fail)
                }, 500);
            }
        }
    }, [searchText, selected_location, currentPage, startDate, endDate, dropdownStatus])

    useEffect(() => {
        setLoader(true)
    }, [selected_location, currentPage])

    useEffect(() => {
        if (startDate && endDate) {
            setCurrentPage(1)
        }
    }, [startDate, endDate])


    return {
        searchText, setSearchText, toggleDateRange, setToggleDateRange, currentPage, setCurrentPage, loader, PageSize, data, setData,
        translation, selectedLanguage, startDate, setStartDate, endDate, setEndDate, pages, setDropdownStatus, dropdownStatus,
        selectedLocationCurrency, count, loadingPermission
    };
}

export default usePaidUnpaidAppointment;
