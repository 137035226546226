import { useSelector } from "react-redux"
import Svgs from "../../Assets/svgs"
import { useNavigate } from "react-router-dom"

const NoDiscountAndPromotionFound = ({ className }) => {
    const navigate = useNavigate()
    const state = useSelector((state) => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>
            <div className={`flex items-center justify-center my-5 ${className} bg-white`}>
                <div className="flex flex-col gap-1 justify-center text-center">
                    <div className="w-fit mx-auto">
                        <Svgs.NoDiscountAndPromotionFound />
                    </div>
                    <h1 className="text-primary font-semibold text-lg normal-case">
                        {selectedLanguage?.name == "English" ? "No discounts or promotions currently available" : translation[1260]?.value ? translation[1260]?.value : "No discounts or promotions currently available"}
                    </h1>
                    <p className="text-sm text-[#555555]">
                        {selectedLanguage?.name == "English" ? "Please click the" : translation[1270]?.value ? translation[1270]?.value : "Please click the"}
                        {" "}
                        <span
                            className="underline text-primary cursor-pointer"
                        >
                            {selectedLanguage?.name == "English" ? "Create New" : translation[639]?.value ? translation[639]?.value : "Create New"}
                        </span>{" "}
                        {selectedLanguage?.name == "English" ? "button to add one." : translation[1271]?.value ? translation[1271]?.value : "button to add one."}
                    </p>
                </div>
            </div>
        </>
    )
}

NoDiscountAndPromotionFound.defaultProps = {
    className: ''
}

export default NoDiscountAndPromotionFound