import axios from "axios";
import { BASE_URL, create_gift_card_url, create_member_url, delete_member_url, delete_voucher_url, get_client_checkout_memberships, get_gift_card_url, get_member_url, get_voucher_url, pay_single_installment, set_voucher_url, update_gift_card_url, update_member_url, update_voucher_url } from "../api_variables";
import { toast } from 'react-toastify';
import { VOUCHER } from "../../../Redux/TenantRedux/ActionTypes/Vouchers/voucher";
import { MEMBER } from "../../../Redux/TenantRedux/ActionTypes/Membership/member";
import { get_count_members, get_count_voucher, get_gift_cards, get_members, get_vouchers } from "../../../Redux/TenantRedux/Actions/voucherActions";
import { apiErrorMessage } from "Constants/Data/Errors";
import { buildQueryString } from "Constants/Functions/buildQueryString";


export const getvouchers = (page, search_text, setPageLoading) => async (dispatch) => {
    setPageLoading && setPageLoading(true)
    const currentPage = page ? `?page=${page}` : ''
    const search = search_text ? `&search_text=${search_text}` : ''
    try {
        const response = await axios.get(`${BASE_URL}${get_voucher_url}${currentPage}${search}`);
        if (response.status === 200) {

            let count = response?.data?.response

            let vouchertList = response?.data?.response?.vouchers;
            dispatch(get_vouchers(vouchertList))
            dispatch(get_count_voucher(count))
            setPageLoading && setPageLoading(false)
            return (
                response
            )
        } else {
            setPageLoading && setPageLoading(false)
            toast.error(apiErrorMessage, { toastId: "toast" })
        }
    } catch (error) {
        setPageLoading && setPageLoading(false)
        return error;
    }
};

export const getvouchersFromQuickSales = (selected_location, page, search_text, setPageLoading, quickSales) => async (dispatch) => {
    setPageLoading && setPageLoading(true)
    const currentPage = page ? `?page=${page}` : ''
    const location = selected_location ? `&location_id=${selected_location}` : ''
    const search = search_text ? `&search_text=${search_text}` : ''
    const getVoucherFromQuickSales = quickSales ? `&quick_sales=true` : ''
    try {
        const response = await axios.get(`${BASE_URL}${get_voucher_url}${currentPage}${location}${search}${getVoucherFromQuickSales}`);
        if (response.status === 200) {

            let count = response?.data?.response

            let vouchertList = response?.data?.response?.vouchers;
            dispatch(get_vouchers(vouchertList))
            dispatch(get_count_voucher(count))
            setPageLoading && setPageLoading(false)
            return (
                response
            )
        } else {
            setPageLoading && setPageLoading(false)
            toast.error(apiErrorMessage, { toastId: "toast" })
        }
    } catch (error) {
        setPageLoading && setPageLoading(false)
        return error;
    }
};
export const createvouchers = async (form, success, fail) => {
    let token = localStorage.getItem('access_token')

    if (token) {
        token = token.replaceAll('"', '')
    }
    // let form_data = new FormData()


    // form_data.append('name', form.name)
    // form_data.append('currency_voucher_price', form.currency_voucher_price)
    // // form_data.append('value', form.value)
    // // form_data.append('valid_for', form.valid_for)
    // form_data.append('voucher_type', form.voucher_type)
    // form_data.append('sales', form.sales)
    // form_data.append('validity', form.validity)
    // form_data.append('business', form.business)

    fetch(`${BASE_URL}${set_voucher_url}`,
        {
            method: "POST",
            headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json'

            },
            body: JSON.stringify(form)
        }).then((response => {
            if (response.status == 201) {
                return (
                    response.json()
                )
            }
        })).then((result) => {
            if (result?.status_code === 201) {
                success && success()
            }
            else {
                fail && fail()
            }
        })

};


export const updateVoucher = (data, success, fail) => dispatch => {
    let s_code;

    delete data.is_active
    delete data.created_at
    delete data.user
    let token = localStorage.getItem('access_token')
    if (token) {
        token = token.replaceAll('"', '')
    }

    // let form_data = new FormData()

    // for (let ky in data) {
    //     if (data[ky]) {
    //         form_data.append(ky, data[ky])
    //     }
    // }


    fetch(
        BASE_URL + update_voucher_url,
        {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json'
            }
        }
    )
        .then(response => {
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (result.status_code == 200) {
                success && success()
                dispatch({
                    type: VOUCHER.UPDATE_VOUCHER,
                    payload: {
                        id: data.id
                    }
                })
            }
            else {
                fail && fail()
            }
        })
        .catch(err => {
            fail && fail()
        })
}

export const deleteVoucher = (data, success, fail) => dispatch => {
    let s_code;

    let token = localStorage.getItem('access_token')

    if (token) {
        token = token.replaceAll('"', '')
    }

    let form_data = new FormData()
    form_data.append('id', data.id)

    fetch(
        BASE_URL + delete_voucher_url,
        {
            method: 'DELETE',
            body: form_data,
            headers: {
                Authorization: `Token ${token}`
            }
        }
    )
        .then(response => {
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (result.status_code == 200) {
                success && success()
                dispatch({
                    type: VOUCHER.DELETE_VOUCHER,
                    payload: {
                        id: data.id
                    }
                })
            }
            else {
                fail && fail()
            }
        })
        .catch(err => {
            fail && fail()
        })
}




//--------------------------------------------------------MEMBER--------------------------------------------------



export const getMembers = (pages, search_text, setPageLoading) => async (dispatch) => {
    setPageLoading && setPageLoading(true)
    const search = search_text ? `&search_text=${search_text}` : ''
    const page = pages ? `?page=${pages}` : ''
    try {
        const response = await axios.get(`${BASE_URL}${get_member_url}${page}${search}`);
        if (response.status === 200) {
            let memberList = response?.data?.response?.membership;
            let count = response?.data?.response
            dispatch(get_members(memberList))
            dispatch(get_count_members(count))
            setPageLoading && setPageLoading(false)
            return response
        } else {
            setPageLoading && setPageLoading(false)
            toast.error(apiErrorMessage, { toastId: "toast" })
        }
    } catch (error) {
        setPageLoading && setPageLoading(false)
        return error;
    }
};

export const getQuickSalesMembership = (selected_location, pages, search_text, setPageLoading, quickSales) => async (dispatch) => {
    setPageLoading && setPageLoading(true)
    const page = pages ? `?page=${pages}` : ''
    const location = selected_location ? `&location_id=${selected_location}` : ''
    const search = search_text ? `&search_text=${search_text}` : ''
    const getMembershipFromQuickSales = quickSales ? `&quick_sales=true` : ''
    try {
        const response = await axios.get(`${BASE_URL}${get_member_url}${page}${location}${search}${getMembershipFromQuickSales}`);
        if (response.status === 200) {
            let memberList = response?.data?.response?.membership;
            let count = response?.data?.response
            dispatch(get_members(memberList))
            dispatch(get_count_members(count))
            setPageLoading && setPageLoading(false)
            return response
        } else {
            setPageLoading && setPageLoading(false)
            toast.error(apiErrorMessage, { toastId: "toast" })
        }
    } catch (error) {
        setPageLoading && setPageLoading(false)
        return error;
    }
};

export const getClientInstallmentMemberships = (data, setLoading, success, fail) => async (dispatch) => {
    setLoading && setLoading(true)
    const query = buildQueryString(data)
    try {
        const result = await axios.get(`${BASE_URL}${get_client_checkout_memberships}${query}`);
        if (result?.status === 200) {
            success && success(result?.data?.response?.client_memberships)
            if (result?.data?.response?.client_memberships?.length == 0 && data?.client_id) {
                toast.error('No installment membership found for this client', { toastId: "toast" })
            }
            setLoading && setLoading(false)
        } else {
            fail && fail(result)
            setLoading && setLoading(false)
        }
        return result;
    } catch (error) {
        setLoading && setLoading(false)
        fail && fail(error)
        return error;
    }
};

export const paySingleInstallmentPrice = (payload, setLoading, success, fail) => async (dispatch) => {
    let payloadData = {
        membership: payload?.membership,
        paid_installment: ((+payload?.paid_installment)?.toFixed(2))
    }

    let token = localStorage.getItem('access_token')

    if (token) {
        token = token.replaceAll('"', '')
    }
    setLoading && setLoading(true)
    try {
        const result = await axios.post(`${BASE_URL}${pay_single_installment}`, payloadData, {
            headers: { Authorization: `Token ${token}` },
        });

        if (result?.data?.status_code === 200) {
            toast.success(result?.data?.response?.message, { toastId: 'toast' })
            success && success(result?.data?.response?.client_memberships)
            setLoading && setLoading(false)
        } else {
            fail && fail(result)
            setLoading && setLoading(false)
        }
        return result;
    } catch (error) {
        setLoading && setLoading(false)
        fail && fail(error)
        return error;
    }
};

export const createMembers = async (form, success, fail) => {
    let token = localStorage.getItem('access_token')

    if (token) {
        token = token.replaceAll('"', '')
    }
    // let form_data = new FormData()
    // form_data.append('name', form.name)
    // if (form.product) {
    //     form_data.append('product', form.product)
    // }
    // if (form.service) {
    //     form_data.append('service', form.service)
    // }
    // form_data.append('price', form.price)
    // form_data.append('valid_for', form.valid_for)
    // form_data.append('membership_type', form.membership_type)
    // form_data.append('tax_rate', form.tax_rate)
    // form_data.append('sales', form.tax_rate)
    // form_data.append('validity', form.validity)
    // form_data.append('total_number', form.total_number)
    // form_data.append('business', form.business)



    fetch(`${BASE_URL}${create_member_url}`,
        {
            method: "POST",
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(form),
        }).then((response => {
            if (response.status == 201) {
                return (
                    response.json()
                )
            }
        })).then((result) => {
            if (result?.status_code === 201) {
                success && success()
            }
            else {
                fail && fail()
            }
        })

};


export const updateMember = (data, success, fail) => async (dispatch) => {
    delete data.created_at
    delete data.user

    let token = localStorage.getItem('access_token')
    if (token) {
        token = token.replaceAll('"', '')
    }
    try {
        await axios.put(
            `${BASE_URL}${update_member_url}`,
            data,
            {
                headers: { Authorization: `Token ${token}` },
            }
        )
            // .then(response => {
            //     if (response.statusText === 'OK') {
            //         return response.json()
            //     }
            // })
            .then(response => {
                if (response.status === 200) {
                    success && success()
                    dispatch({
                        type: MEMBER.UPDATE_MEMBER,
                        payload: {
                            id: data.id
                        }
                    })
                }
                else {
                    fail && fail()
                }
            })

    } catch (error) {
        fail && fail()
        return error;
    }



    // let form_data = new FormData()

    // for (let ky in data) {
    //     if (data[ky]) {
    //         form_data.append(ky, data[ky])
    //     }
    // }


    // fetch(
    //     BASE_URL + update_member_url,
    //     {
    //         method: 'PUT',
    //         body: form_data,
    //         headers: {
    //             Authorization: `Token ${token}`
    //         }
    //     }
    // )
    //     .then(response => {
    //         if (response.ok) {
    //             return response.json()
    //         }
    //     })
    //     .then(result => {
    //         if (result.status_code == 200) {
    //             success && success()
    //             dispatch({
    //                 type: MEMBER.UPDATE_MEMBER,
    //                 payload: {
    //                     id: data.id
    //                 }
    //             })
    //         }
    //         else {
    //             fail && fail()
    //         }
    //     })
    //     .catch(err => {
    //         fail && fail()
    //     })
}

export const deleteMember = (data, success, fail) => dispatch => {
    let s_code;

    let token = localStorage.getItem('access_token')

    if (token) {
        token = token.replaceAll('"', '')
    }

    let form_data = new FormData()
    form_data.append('memberships_id', data.id)

    fetch(
        BASE_URL + delete_member_url,
        {
            method: 'DELETE',
            body: form_data,
            headers: {
                Authorization: `Token ${token}`
            }
        }
    )
        .then(response => {
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (result.status_code == 200) {
                success && success()
                dispatch({
                    type: MEMBER.DELETE_MEMBER,
                    payload: {
                        id: data.id
                    }
                })
            }
            else {
                fail && fail()
            }
        })
        .catch(err => {
            fail && fail()
        })
}

//--------------------------------------------------------GIFT CARDS--------------------------------------------------
export const AddGiftCards = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.post(`${BASE_URL}${create_gift_card_url}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 400) {
                toast.error(result?.data?.response?.message, { toastId: 'cusGC' })
                fail && fail()
                return
            } else {
                const data = result?.data?.response?.data
                // dispatch(addSegments(data));
                toast.success(result?.data?.response?.message, { toastId: "add_segment" })
                success && success(data)
                return;
            }
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }
}
export const UpdateGiftCards = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.patch(`${BASE_URL}${update_gift_card_url}?id=${payload?.id}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 400) {
                toast.error(result?.data?.response?.message, { toastId: 'cusGC' })
                fail && fail()
                return
            } else {
                const data = result?.data?.response?.data
                // dispatch(addSegments(data));
                toast.success(result?.data?.response?.message, { toastId: "add_segment" })
                success && success()
                return;
            }
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }
}

export const GetGiftCards = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const data = buildQueryString(payload)
        const response = await axios.get(`${BASE_URL}${get_gift_card_url}${data}`, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (response.status === 200) {
            let giftCardList = response?.data
            dispatch(get_gift_cards(giftCardList))
            success && success()
            return response
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail()
        return error;
    }
};

export const deleteGiftCard = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const response = await axios.delete(`${BASE_URL}${create_gift_card_url}?id=${payload}`, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (response.status === 200) {
            toast.success(response?.data?.response?.message, {
                toastId: "GiftCardDel",
            });
            success && success()
            return response
        } else {
            toast.error(apiErrorMessage, { toastId: "GiftCardDel", });
            fail && fail()
        }
    } catch (error) {
        toast.error(apiErrorMessage, { toastId: "GiftCardDel", });
        fail && fail()
        return error;
    }
}
