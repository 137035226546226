export const CLIENT_DISCOUNT_PROMOTION = {
    // Discount on direct or flat
    UPDATE_BUNDLE_FIXED_AMOUNT: "UPDATE_BUNDLE_FIXED_AMOUNT",
    UPDATE_FIXED_PRICE_SERVICES: "UPDATE_FIXED_PRICE_SERVICES",
    GET_DISCOUNT_PROMOTION: "GET_DISCOUNT_PROMOTION",
    GET_AVAIL_OFFERS: "GET_AVAIL_OFFERS",
    ADD_DISCOUNT: "ADD_DISCOUNT",
    UPDATE_DISCOUNT: "UPDATE_DISCOUNT",
    DELETE_DISCOUNT: "DELETE_DISCOUNT",
    // Discount on specific group of category
    ADD_SPECIFIC_CATEGORYGROUP_DISCOUNT: "ADD_SPECIFIC_CATEGORYGROUP_DISCOUNT",
    UPDATE_SPECIFIC_CATEGORYGROUP_DISCOUNT: "UPDATE_SPECIFIC_CATEGORYGROUP_DISCOUNT",
    DELETE_SPECIFIC_CATEGORYGROUP_DISCOUNT: "DELETE_SPECIFIC_CATEGORYGROUP_DISCOUNT",
    // Discount on Purchase and Get Discount
    ADD_PURCHASE_DISCOUNT: "ADD_PURCHASE_DISCOUNT",
    UPDATE_PURCHASE_DISCOUNT: "UPDATE_PURCHASE_DISCOUNT",
    DELETE_PURCHASE_DISCOUNT: "DELETE_PURCHASE_DISCOUNT",
    // Discount on Specific Brand and Service Group
    ADD_SPECIFIC_BRAND_SERVICE_GROUP_DISCOUNT: "ADD_SPECIFIC_BRAND_SERVICE_GROUP_DISCOUNT",
    UPDATE_SPECIFIC_BRAND_SERVICE_GROUP_DISCOUNT: "UPDATE_SPECIFIC_BRAND_SERVICE_GROUP_DISCOUNT",
    DELETE_SPECIFIC_BRAND_SERVICE_GROUP_DISCOUNT: "DELETE_SPECIFIC_BRAND_SERVICE_GROUP_DISCOUNT",
    // Discount on get a free item if you spend some amount
    ADD_SPEND_SOME_AMOUNT_DISCOUNT: "ADD_SPEND_SOME_AMOUNT_DISCOUNT",
    UPDATE_SPEND_SOME_AMOUNT_DISCOUNT: "UPDATE_SPEND_SOME_AMOUNT_DISCOUNT",
    DELETE_SPEND_SOME_AMOUNT_DISCOUNT: "DELETE_SPEND_SOME_AMOUNT_DISCOUNT",
    // Discount on get an Item for a fixed price or in the group
    ADD_FIXED_PRICE_SERVICE_DISCOUNT: "ADD_FIXED_PRICE_SERVICE_DISCOUNT",
    UPDATE_FIXED_PRICE_SERVICE_DISCOUNT: "UPDATE_FIXED_PRICE_SERVICE_DISCOUNT",
    DELETE_FIXED_PRICE_SERVICE_DISCOUNT: "DELETE_FIXED_PRICE_SERVICE_DISCOUNT",
    // Discount on Get free Item for other purchase
    ADD_FREE_SERVICE_DISCOUNT: "ADD_FREE_SERVICE_DISCOUNT",
    UPDATE_FREE_SERVICE_DISCOUNT: "UPDATE_FREE_SERVICE_DISCOUNT",
    DELETE_FREE_SERVICE_DISCOUNT: "DELETE_FREE_SERVICE_DISCOUNT",
    // Discount on Bundle fixed price
    ADD_BUNDLE_DISCOUNT: "ADD_BUNDLE_DISCOUNT",
    UPDATE_BUNDLE_DISCOUNT: "UPDATE_BUNDLE_DISCOUNT",
    DELETE_BUNDLE_DISCOUNT: "DELETE_BUNDLE_DISCOUNT",
    // Discount on buy retail and get specific service
    ADD_RETAIL_PROMOTION: "ADD_RETAIL_PROMOTION",
    UPDATE_RETAIL_PROMOTION: "UPDATE_RETAIL_PROMOTION",
    DELETE_RETAIL_PROMOTION: "DELETE_RETAIL_PROMOTION",
    // Discount on add user restricted discount url
    ADD_USER_RESTRICTED_DISCOUNT: "ADD_USER_RESTRICTED_DISCOUNT",
    UPDATE_USER_RESTRICTED_DISCOUNT: "UPDATE_USER_RESTRICTED_DISCOUNT",
    DELETE_USER_RESTRICTED_DISCOUNT: "DELETE_USER_RESTRICTED_DISCOUNT",
    // Discount on complimentary voucher
    ADD_COMPLIMENTARY_DISCOUNT: "ADD_COMPLIMENTARY_DISCOUNT",
    UPDATE_COMPLIMENTARY_DISCOUNT: "UPDATE_COMPLIMENTARY_DISCOUNT",
    DELETE_COMPLIMENTARY_DISCOUNT: "DELETE_COMPLIMENTARY_DISCOUNT",
    // Discount on Package
    ADD_PACKAGE_DISCOUNT: "ADD_PACKAGE_DISCOUNT",
    UPDATE_PACKAGE_DISCOUNT: "UPDATE_PACKAGE_DISCOUNT",
    DELETE_PACKAGE_DISCOUNT: "DELETE_PACKAGE_DISCOUNT",
    // Discount on Coupons
    ADD_COUPONS_DISCOUNT: "ADD_COUPONS_DISCOUNT",
    UPDATE_COUPONS_DISCOUNT: "UPDATE_COUPONS_DISCOUNT",
    DELETE_COUPONS_DISCOUNT: "DELETE_COUPONS_DISCOUNT",
};