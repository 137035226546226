import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getSaleOrderProduct, getSaleOrderServices } from 'Adapters/Api/saleOrders'
import { getClientDropDownListPagination } from '../../../../Adapters/Api/clients'
import { getEmployeeDropDownList } from '../../../../Adapters/Api/employee'
import { CreateNewCheckout } from '../../../../Adapters/Api/saleServices'
import Svgs from '../../../../Assets/svgs'
import { APPOINTMENT_TIPS } from '../../../../Constants/Data/AppointmentsData'
import { CHECKOUT_PAYMENT_METHODS, PAYMENT_METHODS } from '../../../../Constants/Data/Payment'
import Dropwdown from '../../Elements/Dropwdown'
import LoginBtn from '../../Elements/LoginBtn'
import Popup from '../../Elements/Popup'
import SupportCard from '../Clients/Elements/SupportCard'
import SaleBookingDetailCard from './SaleBookingDetailsCard'
import { getSaleEmployeeCommission } from 'Adapters/Api/commissionReports'
import { EMPLOYEES } from 'Redux/TenantRedux/ActionTypes/employeeTypes'
import { useLocation } from "new-components/location/helper";
import { getPaymentMethodList } from 'Redux/TenantRedux/Actions/financialSettingsActions'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { getPaymentMethods } from 'Adapters/Api/financialSettings'
import NotFound from './Elements/NotFound'
import { getClientCheckoutGiftCard, getClientCheckoutMemberships, getClientCheckoutVouchers, getClientLoyaltyPoints, getSingleCouponOnCheckout, getSingleGiftCardOnCheckout } from 'Adapters/Api/apointments'
import moment from "moment";
import { useMemo } from 'react'
import { CLIENT } from 'Redux/TenantRedux/ActionTypes/clientActionTypes'
import AddClientPopup from '../Clients/AddClient/AddClientPopup'
import CheckBoxCardShimmer from 'Components/Utility/Shimmers/CheckBoxCardShimmer'
import PaginationDropdown from 'Components/TenantApp/Elements/PaginationDropdown'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import PaymentCheckboxCard from 'Components/TenantApp/Elements/PaymentCheckboxCard'
import { generateUniqueCode } from 'Constants/Functions/generateRandomCodes'
import { OTHER_PAYMENT_METHODS } from 'Constants/Data/Payment'
import FloatInput from 'Components/TenantApp/Elements/FloatInput'
let clientTimeOutId = undefined;

const CheckoutClientDetails = ({ selectedLanguage, setGiftCardDetail, setPaymentError, setSelectedPaymentMethods, translation, data, Employees, setEmployees, onUpdate, getMemberCommission, setOrderData }) => {


    const dispatch = useDispatch()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");

    const [showAddClientPopup, setShowAddClientPopup] = useState(false)
    const [clients, setClients] = useState([])
    const [clientSearchText, setClientSearchText] = useState("");
    const [clientCurrentPage, setClientCurrentPage] = useState(1)
    const [clientLoader, setClientLoader] = useState(false)
    const [clientsDropdown, setClientsDropdown] = useState([])

    const state = useSelector(state => state)
    const clientPaginationData = state?.client?.clientPaginationData;
    const clientDropdownRedux = state?.client?.clientsRedux;
    const clientInCart = state?.cartItem?.installment_client;

    const getClients = (clientCurrentPage, clientSearchText) => {
        dispatch(getClientDropDownListPagination(clientCurrentPage, clientSearchText, setClientLoader));
    }

    useEffect(() => {
        if (clientSearchText === null) {
            getClients(clientCurrentPage, "")
        } else {
            if (clientTimeOutId) {
                clearTimeout(clientTimeOutId)
            }
            clientTimeOutId = setTimeout(() => {
                getClients(clientCurrentPage, clientSearchText)
            }, 500);
        }
    }, [clientSearchText, clientCurrentPage])

    useEffect(() => {
        setPaymentError({ id: '', message: '' })
        setGiftCardDetail([])
        setSelectedPaymentMethods([])
    }, [])

    useEffect(() => {
        if (data?.client_type != "Walk_in") {
            // let filtered_clients = state.client?.checkout_client?.clients
            // if (!filtered_clients || filtered_clients?.length == 0) {
            //     filtered_clients = state.client.clients?.filter((itm) => itm?.is_active === true)
            // }
            let filtered_clients = state.client?.clientDropdownRedux
            setClients(filtered_clients)
        }
    }, [state.client.clients_updated, data?.client_type, state.client?.clientDropdownRedux, state?.client?.clientDropdownRedux?.length])

    // useEffect(() => {
    //     dispatch(getClientDropDownList())
    // }, [])

    useEffect(() => {
        if (selected_location) {
            dispatch(getEmployeeDropDownList(selected_location))
        }
    }, [selected_location])


    useEffect(() => {
        let new_empls = state.employee.employees
        setEmployees(new_empls)

    }, [state.locations.selected_location, state.employee.employees])

    const options = useMemo(() => [
        { value: 'Walk_in', label: selectedLanguage?.name == "English" ? "Walk-in" : translation[383]?.value ? translation[383]?.value : "Walk-in" },
        { value: 'In_Saloon', label: selectedLanguage?.name == "English" ? "In-Saloon" : translation[384]?.value ? translation[384]?.value : "In-Saloon" },
    ], [])

    const filteredOptions = options.filter((option) => {
        // Check if the selection_type in ids array is "VOUCHER"
        if (data?.ids?.find(itm => itm?.selection_type === 'VOUCHER' || itm?.selection_type === 'MEMBERSHIP')) {
            // If so, return only options with value "In_Saloon"
            return option.value === 'In_Saloon';
        }
        // Otherwise, return all options
        return true;
    });

    useEffect(() => {
        if (data?.client_type === "In_Saloon" && clientDropdownRedux?.length && data?.client == "") {
            setOrderData({
                ...data,
                client: clientDropdownRedux[0]?.id
            })
        }
    }, [clientDropdownRedux, clientDropdownRedux?.length, data?.client])

    useEffect(() => {
        let data = []
        if (clientInCart?.id) {
            data = [{ label: clientInCart?.name, value: clientInCart?.id }]
            let event = {
                target: {
                    name: 'client',
                    value: clientInCart?.id
                }
            }
            onUpdate(event)
            setOrderData({
                ...data,
                client: clientInCart?.id
            })
        } else {
            data = clientDropdownRedux?.map((clnt, i) => {
                return {
                    label: clnt.full_name,
                    value: clnt.id,
                    otherData: {
                        image: clnt?.image,
                        DataOne: clnt.email,
                        showImage: true,
                        index: i
                    },
                    email: clnt.email,
                    mobile_number: clnt.mobile_number
                };
            })
        }
        setClientsDropdown(data)
    }, [clientInCart, clientDropdownRedux])

    return (
        <>
            <div className='flex flex-col gap-3'>
                <h2 className="text-primary font-bold text-xl">
                    {selectedLanguage?.name == "English" ? "Client Details" : translation[382]?.value ? translation[382]?.value : "Client Details"}
                </h2>
                <div className='border rounded-lg p-4 grid md:grid-cols-2 gap-4 text-sm'>
                    <Dropwdown
                        name={'client_type'}
                        value={data?.client_type}
                        title={selectedLanguage?.name == "English" ? "Client Type" : translation[330]?.value ? translation[330]?.value : "Client Type"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Client Type" : translation[379]?.value ? translation[379]?.value : "Select Client Type"}
                        onChange={onUpdate}
                        disabled={!data?.client_type_changeable}
                        options={filteredOptions}
                    />
                    {data?.client_type === "In_Saloon" &&
                        <div>
                            <PaginationDropdown
                                name={'client'}
                                value={data?.client}
                                required={true}
                                title={selectedLanguage?.name == "English" ? "Client" : translation[34]?.value ? translation[34]?.value : "Client"}
                                placeholder={selectedLanguage?.name == "English" ? "Select Client" : translation[225]?.value ? translation[225]?.value : "Select Client"}
                                onChange={onUpdate}
                                setPage={setClientCurrentPage}
                                page={clientCurrentPage}
                                setDropdownSearch={setClientSearchText}
                                dropdownSearch={clientSearchText}
                                lastPage={clientPaginationData?.pages}
                                currentPage={clientPaginationData?.currentPage}
                                loading={clientLoader}
                                disabled={clientInCart?.id}
                                options={clientsDropdown}
                            />
                            {/* <Dropwdown
                                options={}
                                filterOption={(option, inputValue) => {
                                    const { mobile_number, label } = option?.data
                                    const normalizedInput = inputValue ? inputValue?.toLowerCase() : '';
                                    return label?.toLowerCase()?.includes(normalizedInput) || (mobile_number && mobile_number?.includes(normalizedInput));
                                }}
                            /> */}
                            {!clientInCart?.id &&
                                <div
                                    className="flex items-center gap-2 cursor-pointer pt-2"
                                    onClick={() => {
                                        localStorage.setItem('add_client_from_sales', true)
                                        // navigate('/dashboard/clients/client-profiles/add-client/?from=quick_sales_checkout')
                                        setShowAddClientPopup(!showAddClientPopup)
                                    }}
                                >
                                    <p className="text-sm mt-6">
                                        <span className="underline mx-px text-[#5679FF]">
                                            {selectedLanguage?.name == "English" ? "Click here" : translation[502]?.value ? translation[502]?.value : "Click here"}
                                        </span>
                                        {selectedLanguage?.name == "English" ? " to add client." : translation[1284]?.value ? translation[1284]?.value : " to add client."}
                                    </p>
                                </div>
                            }
                        </div>
                    }
                    {/* <div>
                        <Dropwdown
                            name={'member'}
                            value={data?.member}
                            required={true}
                            title='Team Member'
                            placeholder='Select Team Member'
                            onChange={onUpdate}
                            options={Employees.map(empl => {
                                return {
                                    label: empl.full_name,
                                    value: empl.id,
                                }
                            })}
                        />
                        {(Employees.map(empl => {
                            return {
                                label: empl.full_name,
                                value: empl.id,
                            }
                        }))?.length == 0 && (
                                <div
                                    className="flex items-center gap-2 cursor-pointer pt-2"
                                    onClick={() => {
                                        //humayunnnn
                                        navigate('/dashboard/employee/employees')
                                    }}
                                >
                                    <p className="text-sm">No team members found for the store. Click <span className="underline">here</span> to add.</p>
                                </div>
                            )}
                    </div> */}
                </div>
                <div className='flex items-center justify-end'>
                    <LoginBtn
                        disabled={((!data?.client && data?.client_type != "Walk_in") || !data?.client_type) ? true : false}
                        text={selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
                        onClick={() => {
                            getMemberCommission()
                            onUpdate({
                                target: {
                                    name: 'checkout',
                                    value: true,
                                }
                            })
                        }}
                    />
                </div>
            </div>

            {showAddClientPopup &&
                <AddClientPopup open={showAddClientPopup} close={setShowAddClientPopup} getClients={getClients} />}
        </>
    )
}

const CheckoutTipDetails = ({ employeeTips, setLoyaltyDiscountAmount, onDeleteCoupon, appliedGiftCard, setPayBtnDisabled, totalTips, totalPriceWithoutTax, paymentError, setPaymentError, giftCards, taxApplied, selectedPaymentMethods, setSelectedPaymentMethods, totalAmountPaid, setTotalAmountPaid, setCouponDetail, giftCardDetail, setGiftCardDetail, calculatedTax, setData, onUpdate, tip_for_emply, setTip_for_emply, employee_tips, tip_sum, setCurrentEmployeeTip, currentEmployeeTip, setEmployeeTips, setEmployeeTipSum, setEmployee_tips, setTip_sum, data, setOrderData, handleTipChange, Employees, currency, t_price, setIs_redeemed, setRedeemed_id, setRedeemed_points, redeemed_id, redeemed_points }) => {
    const dispatch = useDispatch()

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");
    const businessData = getDataFromLocalStorage("business_data");
    const location = getDataFromLocalStorage("selected_location");

    const state = useSelector(state => state)
    const reduxPaymentMethodList = useSelector((state) => state?.financialSettings?.paymentMethod);
    const cur_currency = state?.locations?.locations?.filter(itm => itm?.id === selected_location)?.map(itm => itm?.currency?.code)

    // const [tip_for_emply, setTip_for_emply] = useState([])
    const [tipPopup, setTipPopup] = useState(false)
    const [notFound, setNotFound] = useState(true)
    const [loyalityPoints, setLoyalityPoints] = useState({})
    const [existingLoyalityPoints, setExistingLoyalityPoints] = useState({})
    const [redeemPop, setRedeemPop] = useState(false)
    const [sortedEmployees, setSortedEmployees] = useState([])
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const [couponPopup, setCouponPopup] = useState(false)
    const [voucherPopup, setVoucherPopup] = useState(false)
    const [giftCardPopup, setGiftCardPopup] = useState(false)
    const [loyalityPopup, setLoyalityPopup] = useState(false)
    const [membershipPopup, setMembershipPopup] = useState(false);
    const [giftCardError, setGiftCardError] = useState()
    const [checkoutVouchers, setCheckoutVouchers] = useState([])
    const [checkoutMemberships, setCheckoutMembership] = useState([])
    const [voucherNotFound, setVoucherNotFound] = useState(false)
    const [membershipNotFound, setMembershipNotFound] = useState(false)
    const [membershipAvailable, setMembershipAvailable] = useState(false)
    const [voucherAvailable, setVoucherAvailable] = useState(false)
    const [loyalityAvailable, setLoyalityAvailable] = useState(false)
    const [giftCardAvailable, setGiftCardAvailable] = useState(false)
    const [checkoutGiftCard, setCheckoutGiftCard] = useState([])
    const [pointsToRedeem, setPointsToRedeem] = useState(0)
    const [amountForPoints, setAmountForPoints] = useState(0)
    const [couponCode, setCouponCode] = useState("")
    const [getCouponLoader, setGetCouponLoader] = useState(false)
    const [giftCardCode, setGiftCardCode] = useState("")
    const [giftCardLoader, setGiftCardLoader] = useState(false)
    const [payment_price, setPaymentPrice] = useState(0)
    const [giftCard_amount, setGiftCard_amount] = useState(null)
    const [SplitTip, setSplitTip] = useState(false);
    const [tip_inp, setTipInp] = useState(0)
    const [remainingPrice, setRemainingPrice] = useState(0)
    const [amountToPay, setAmountToPay] = useState(0)
    const [loyaltyLoader, setLoyaltyLoader] = useState(true)
    const [membershipLoader, setMembershipLoader] = useState(true)
    const [voucherLoader, setVoucherLoader] = useState(true)
    const [getGiftCardLoader, setGetGiftCardLoader] = useState(true)

    const otherOptions = useMemo(() =>
        [
            {
                method_type: "Coupon",
                label: selectedLanguage?.name == "English" ? "Coupon" : translation[1566]?.value ? translation[1566]?.value : "Coupon",
            },
            {
                method_type: "GiftCard",
                label: selectedLanguage?.name == "English" ? "Gift Card" : translation[1540]?.value ? translation[1540]?.value : "Gift Card",
            },

            {
                method_type: "LoyalityPoints",
                label: selectedLanguage?.name == "English" ? "Loyality Points" : translation[197]?.value ? translation[197]?.value : "Loyality Points"
            },
            {
                method_type: "Voucher",
                label: selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"
            },
            {
                method_type: "Membership",
                label: selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership"
            },
        ], [selectedLanguage])

    //get payment methods
    const fetchPaymentMethods = async (business_id) => {
        const response = await getPaymentMethods(business_id);
        if (response.status === 200) {
            let paymentMethods = response?.data?.response?.payment_methods;
            dispatch(getPaymentMethodList(paymentMethods));
        }
    };

    // Fetch Loyality Point against specific client
    const getLoyaltyPoints = () => {
        setLoyaltyLoader(true)
        const location_id = location
        const client_id = data?.client && data?.client

        if (location_id && client_id) {
            dispatch(getClientLoyaltyPoints(
                { location_id: location_id, client_id: client_id },
                (result) => {
                    setLoyaltyLoader(false)
                    setLoyalityPoints(result?.client_loyalty_points)
                    setExistingLoyalityPoints(result?.client_loyalty_points)
                    setNotFound(false)
                    setLoyalityAvailable(true)
                },
                (err) => {
                    setLoyalityAvailable(false)
                    setLoyaltyLoader(false)
                    setNotFound(true)
                }
            ))
        }
    }

    // Loyality Points
    useEffect(() => {
        if (redeemed_points && redeemed_id && data?.other_method == "LoyalityPoints") {
            let remaining_available_points;
            let single_amount_for_every_point = loyalityPoints?.customer_will_get_amount / loyalityPoints?.for_every_points
            let remaining_value;
            remaining_available_points = loyalityPoints?.total_available_points - redeemed_points
            remaining_value = single_amount_for_every_point * remaining_available_points
            setLoyalityPoints({
                ...loyalityPoints,
                total_available_points: remaining_available_points,
                total_amount: remaining_value
            })
        }
        else {
            setLoyalityPoints(existingLoyalityPoints)
        }

    }, [redeemed_points, redeemed_id])

    useEffect(() => {
        if (Employees?.length > 0) {
            setSortedEmployees(Employees?.sort(itm => itm.level == "Above_Averge" ? -1 : 1))
        }
    }, [Employees, Employees?.length])

    useEffect(() => {
        if (loyalityPoints) {
            const points_for_every_amount = (+loyalityPoints?.for_every_points / +loyalityPoints?.customer_will_get_amount)

            const points_to_redeem = points_for_every_amount * (+t_price + +calculatedTax)

            const amount_for_points =
                t_price < loyalityPoints?.total_amount
                    ? (+points_for_every_amount * (+t_price + +calculatedTax)) / +points_for_every_amount
                    : (+loyalityPoints?.total_earn / +points_for_every_amount) < +loyalityPoints?.total_amount
                        ? +loyalityPoints?.total_earn / +points_for_every_amount
                        : +loyalityPoints?.total_earn / +points_for_every_amount

            setPointsToRedeem(points_to_redeem)
            setAmountForPoints(amount_for_points)
        }
    }, [calculatedTax, loyalityPoints, t_price])

    useEffect(() => {
        if (businessData.id) {
            // if (reduxPaymentMethodList?.length === 0) {
            fetchPaymentMethods(businessData.id);
            // }
        }
    }, [businessData.id]);

    useEffect(() => {
        if (businessData.id) {
            if (data.client_type !== "Walk_in") {
                getLoyaltyPoints()
                getCheckoutVouchers()
                getCheckoutMembership()
                getCheckoutGiftCard()
            }
        }
    }, [data?.client, location])

    useEffect(() => {
        if (data?.ids?.find(itm => itm?.selection_type !== "GIFT_CARD")) {
            setGiftCardAvailable(true);
        }
    }, [data?.ids]);

    // useEffect(() => {
    //     // check that loyality points are available or not 
    //     if (!notFound && loyalityPoints) {
    //         setLoyalityAvailable(!loyalityAvailable)
    //     }
    // }, [loyalityPoints])

    useEffect(() => {
        const totalAmount = selectedPaymentMethods?.reduce((sum, item) => sum + item.amount, 0)
        let gc_price = data?.is_gift_card_redeemed ? (appliedGiftCard?.partial_price)?.toFixed(2) : 0?.toFixed(2)
        setPaymentPrice(+((+t_price + (taxApplied ? +taxApplied : 0))?.toFixed(2) - (+totalAmount + +gc_price)?.toFixed(2)))
        setAmountToPay(+((+t_price + (taxApplied ? +taxApplied : 0))?.toFixed(2) - totalAmount?.toFixed(2)))
        setTotalAmountPaid((+totalAmount + + gc_price)?.toFixed(2))
    }, [selectedPaymentMethods, appliedGiftCard, taxApplied, t_price, data?.is_gift_card_redeemed])

    // get client vouchers
    const getCheckoutVouchers = () => {
        setVoucherAvailable(false)
        setVoucherLoader(true)
        const location_id = location
        const client_id = data?.client && data?.client
        if (location_id && client_id) {
            dispatch(getClientCheckoutVouchers(
                { location_id: location_id, client_id: client_id },
                (result) => {
                    setCheckoutVouchers(result?.client_vouchers)
                    if (data?.ids?.find(itm => itm?.selection_type === "PRODUCT" || itm?.selection_type === "SERVICE") && result?.client_vouchers?.length > 0) {
                        setVoucherAvailable(true);
                    } else {
                        setVoucherAvailable(false);
                    }
                    setVoucherLoader(false)
                },
                (err) => {
                    setVoucherLoader(false)
                    setVoucherNotFound(true)
                }
            ))
        }
    }

    // get client memberships
    const getCheckoutMembership = () => {
        setMembershipLoader(true)
        setMembershipAvailable(false)
        const location_id = location
        const client_id = data?.client && data?.client
        if (location_id && client_id) {
            dispatch(getClientCheckoutMemberships(
                { location_id: location_id, client_id: client_id },
                (result) => {
                    setMembershipLoader(false)
                    setCheckoutMembership(result?.client_memberships)
                    if (data?.ids?.find(itm => itm?.selection_type === "PRODUCT" || itm?.selection_type === "SERVICE") && result?.client_memberships?.length > 0) {
                        setMembershipAvailable(true);
                    } else {
                        setMembershipAvailable(false);
                    }
                },
                (err) => {
                    setMembershipLoader(false)
                    setMembershipNotFound(true)
                }
            ))
        }
    }

    // get Gift Cards WRT client
    const getCheckoutGiftCard = (code) => {
        setGetGiftCardLoader(true)
        let payload = {
            location_id: location,
            client_id: code ? "" : data?.client && data?.client,
            code: code ? code : "",
        }

        dispatch(getClientCheckoutGiftCard(
            payload,
            (result) => {
                if (code) {
                    setGiftCardLoader(false)
                    if (result?.status == 404) {
                        toast.error(result?.message)
                    } else {
                        toast.success(result?.message)
                        setGiftCardDetail(result?.client_gift_cards)
                    }
                } else {
                    setCheckoutGiftCard(result?.client_gift_cards)
                }
                setGetGiftCardLoader(false)
            },
            (err) => {
                setGetGiftCardLoader(false)
            }
        ))
    }

    // check coupon
    const checkCouponCode = () => {
        setGetCouponLoader(true)
        setCouponPopup(!couponPopup)
        dispatch(getSingleCouponOnCheckout(
            { coupon_code: couponCode, client_type: data?.client_type, client_id: data?.client, totalPriceWithoutTax: +totalPriceWithoutTax, location_id: selected_location },
            (result) => {
                onDeleteCoupon()
                toast.success(result.message)
                setCouponDetail(result?.coupon)
                setCouponCode('')
                setGetCouponLoader(false)
            },
            (err) => {
                toast.error(err.response.data.response.message)
                setGetCouponLoader(false)
            }
        ))
    }

    // check gift card
    const checkGiftCardCode = () => {
        setGiftCardLoader(true)
        setData({
            ...data,
            is_gift_card_redeemed: false,
            appliedGiftCard: {
                purchased_gift_card_id: '',
                partial_price: 0
            }
        })
        setSelectedPaymentMethods([])
        setGiftCardDetail([])
        setGiftCard_amount(amountToPay.toFixed(2))
        getCheckoutGiftCard(giftCardCode)
    }

    // handle payment change
    const handlePaymentMethodChange = (e, type) => {
        const { name, value } = e?.target
        let totalCartPrice = (+t_price + (taxApplied ? +taxApplied : 0))?.toFixed(2)
        let gc_price = data?.is_gift_card_redeemed ? (appliedGiftCard?.partial_price)?.toFixed(2) : 0?.toFixed(2)

        if (data?.is_gift_card_redeemed > 0) {
            totalCartPrice = totalCartPrice - +gc_price
        }
        if (name === 'payment_method') {
            const updatedPaymentMethods = () => {
                if (value) {
                    if (selectedPaymentMethods?.some(itm => itm?.payment_method === value)) {
                        setPaymentError({ id: '', message: '' })
                        setPayBtnDisabled(false)
                        return selectedPaymentMethods?.filter(itm => itm?.payment_method !== value)
                    } else {
                        if (payment_price > 0) {
                            // setRemainingPrice(+((+t_price + +taxApplied) - totalAmount - +payment_price?.toFixed(2))?.toFixed(2))
                            // setRemainingPrice(+((+t_price + +taxApplied) - totalAmount)?.toFixed(2))

                            setPaymentError({ id: '', message: '' })
                            setPayBtnDisabled(false)
                            return ([...selectedPaymentMethods || [], {
                                payment_method: value, amount: +payment_price?.toFixed(2)
                            }])
                        } else {
                            setPaymentError((prev) => ({
                                ...prev,
                                id: value,
                                message: `payment already paid`
                            }));
                            return selectedPaymentMethods
                        }
                    }
                } else {
                    setPaymentError({ id: '', message: '' })
                    return selectedPaymentMethods
                }
            }
            let updatedValue = updatedPaymentMethods()
            const totalAddAmount = updatedValue?.reduce((sum, item) => sum + item.amount, 0);
            if (totalCartPrice >= totalAddAmount) {
                setRemainingPrice((totalCartPrice - totalAddAmount)?.toFixed(2))
            } else {
                setRemainingPrice(0)
            }
            setSelectedPaymentMethods(updatedValue);
        }
        if (name === 'amount') {
            if (selectedPaymentMethods?.find(itm => itm?.payment_method == type)) {
                const updatedPaymentMethods = selectedPaymentMethods.map(itm => {
                    if (itm?.payment_method === type) {
                        const updatedArray = selectedPaymentMethods?.map((itm) => {
                            if (type === itm?.payment_method) {
                                return ({
                                    ...itm,
                                    amount: +value
                                })
                            } else {
                                return itm
                            }
                        });
                        const totalRemaining = updatedArray?.reduce((sum, item) => sum + item.amount, 0);
                        let remaing = +(totalCartPrice - totalRemaining)?.toFixed(2)

                        if (itm?.payment_method === 'Cash' && selectedPaymentMethods?.length <= 1) {
                            return { ...itm, amount: +value };
                        } else {
                            if (+value <= remaing) {
                                setPayBtnDisabled(false)
                                setPaymentError((prev) => ({ ...prev, id: itm?.payment_method, message: `` }))
                                return { ...itm, amount: +value };
                            } else {
                                if (totalRemaining <= totalCartPrice) {
                                    setPayBtnDisabled(false)
                                    setPaymentError((prev) => ({ ...prev, id: itm?.payment_method, message: `` }))
                                    return { ...itm, amount: +value };
                                } else {
                                    setPayBtnDisabled(true)
                                    setPaymentError((prev) => ({
                                        ...prev,
                                        id: itm?.payment_method,
                                        message: `Payment amount can't be more than the its actual price`
                                    }));
                                    return { ...itm, amount: +value };
                                }
                            }
                        }
                    } else {
                        return itm;
                    }
                });
                const totalAddAmount = updatedPaymentMethods?.reduce((sum, item) => sum + item.amount, 0);
                if (totalCartPrice >= totalAddAmount) {
                    setRemainingPrice((totalCartPrice - totalAddAmount)?.toFixed(2))
                } else {
                    setRemainingPrice(0)
                }
                setSelectedPaymentMethods(updatedPaymentMethods);
            }
        }
    };

    return (
        <>
            <div className='flex flex-col gap-3'>
                <h2 className="text-primary font-bold text-xl">
                    {selectedLanguage?.name == "English" ? "Add Tip" : translation[187]?.value ? translation[187]?.value : "Add Tip"}
                </h2>
                <div className='grid grid-cols-1 md:grid-cols-2 items-center gap-4 cursor-pointer text-sm'>
                    <PaymentCheckboxCard
                        checked={employeeTips?.length > 0}
                        onClick={() => {
                            setTipPopup(true)
                            setTip_for_emply([])
                            setTip_sum(0)
                        }}
                        padding={'gap-4'}
                        inputClass={'!opacity-100 !font-medium'}
                        currency={cur_currency}
                        inputValue={totalTips && totalTips?.toFixed(2)}
                        inputField
                        inputDisabled
                        title={selectedLanguage?.name == "English" ? "Add Tip" : translation[187]?.value ? translation[187]?.value : "Add Tip"}
                    />
                </div>
            </div>

            {/* Split Payment */}
            <div className='flex flex-col gap-3'>
                <h2 className="text-primary font-bold text-xl">
                    {selectedLanguage?.name == "English" ? "Payment Methods" : translation[190]?.value ? translation[190]?.value : "Payment Methods"} <span className="text-red-600">*</span>
                </h2>
                {reduxPaymentMethodList && reduxPaymentMethodList?.length > 0
                    ? (<div className='py-4 grid md:grid-cols-2 gap-4'>
                        {reduxPaymentMethodList?.filter(method => method.is_active)?.map((item, index) => {
                            let lft_icon = CHECKOUT_PAYMENT_METHODS?.find(
                                (itm) => itm.name == item.method_type
                            );
                            return (
                                <PaymentCheckboxCard
                                    errorClass={"!static !pt-1"}
                                    key={index}
                                    checked={selectedPaymentMethods?.find(itm => itm?.payment_method === item.method_type)}
                                    onChange={handlePaymentMethodChange}
                                    inputField
                                    onInputChange={(e) => {
                                        handlePaymentMethodChange(e, item?.method_type)
                                    }}
                                    currency={cur_currency}
                                    restrictedAmount={item?.method_type != 'Cash' ? payment_price : false}
                                    inputValue={selectedPaymentMethods?.find(itm => itm?.payment_method === item.method_type)?.amount}
                                    error={(paymentError?.id === item.method_type) && paymentError?.message}
                                    totalPaymentMethods={payment_price}
                                    title={
                                        <div className='flex items-center gap-2'>
                                            <p className='font-semibold text-sm'>{item.method_type}</p> {" "}
                                        </div>
                                    }

                                    id={item.method_type}
                                    maxCharactersInput={5}
                                />
                            )
                        })
                        }
                    </div>)
                    : <div className='grid md:grid-cols-2 gap-4'>
                        {[0, 1, 2]?.map(itm => {
                            return (
                                <CheckBoxCardShimmer />
                            )
                        })}
                    </div>
                }
            </div>


            {/* Other Methods */}
            <div className='grid lg:grid-cols-1 gap-3'>
                <h2 className="text-primary font-bold text-xl">
                    {selectedLanguage?.name == "English" ? "Other Methods " : translation[192]?.value ? translation[192]?.value : "Other Methods "}
                </h2>
                <div className='grid md:grid-cols-2 gap-4'>
                    {otherOptions?.length > 0
                        ? otherOptions?.map((item, index) => {
                            let lft_icon = OTHER_PAYMENT_METHODS?.find((itm) => itm.name == item.method_type);
                            return (
                                (item.method_type == "Membership" && membershipAvailable)
                                || (item.method_type == "Voucher" && voucherAvailable)
                                || (item.method_type == "LoyalityPoints" && loyalityAvailable)
                                || (item.method_type == "GiftCard" && giftCardAvailable)
                                || (item.method_type == "Coupon")) &&
                                <>
                                    <PaymentCheckboxCard
                                        key={index}
                                        leftIcon={lft_icon?.icon ? lft_icon?.icon : <img className="h-[1.25rem] w-[1.25rem] object-contain" src={`${process.env.PUBLIC_URL}/images/payment_icon.jpeg`} />}
                                        checked={data?.other_method === item.method_type}
                                        onClick={() => {
                                            setData({ ...data, other_method: item.method_type })
                                            if (item.method_type == 'Coupon') {
                                                setCouponPopup(true)
                                            }
                                            if (item.method_type == 'GiftCard') {
                                                setGiftCardPopup(true);
                                            }
                                            if (item.method_type == 'Voucher') {
                                                setVoucherPopup(true)
                                            }
                                            if (item.method_type == 'LoyalityPoints') {
                                                setLoyalityPopup(true);
                                            }
                                            if (item.method_type == 'Membership') {
                                                setMembershipPopup(true);
                                            }
                                        }}
                                        id={item.method_type}
                                        title={
                                            <div className='flex items-center gap-2'>
                                                <p className='font-semibold text-sm'>{item.label}</p>
                                            </div>
                                        }
                                    />
                                </>
                        })
                        : ""
                    }
                    {((membershipLoader || voucherLoader || loyaltyLoader || getGiftCardLoader) && data.client_type !== "Walk_in") && [0, 1, 2]?.map(itm => {
                        return (
                            <CheckBoxCardShimmer />
                        )
                    })}
                </div>
            </div>

            {/* Gift Cards For Client */}
            {giftCardAvailable && checkoutGiftCard?.length > 0 &&
                <div>
                    <h2 className="text-primary font-bold text-xl my-3">
                        {selectedLanguage?.name == "English" ? "Gift Cards" : translation[1540]?.value ? translation[1540]?.value : "Gift Cards"}
                    </h2>
                    <div className='grid xs:grid-cols-2 grid-cols-1 gap-4'>
                        {checkoutGiftCard?.map((itm, index) => {
                            return <div
                                key={index}
                                onClick={() => {
                                    setGiftCardDetail([itm])
                                    setGiftCardPopup(true);
                                    setGiftCard_amount(amountToPay.toFixed(2))
                                    setData({ ...data, other_method: '' })
                                }}
                                className='flex cursor-pointer items-center rounded-md gap-2 bg-[#E6E9FF] p-2'>
                                <div className="flex bg-white p-1 rounded-md">
                                    <Svgs.Gift width={'2rem'} height={'2rem'} />
                                </div>
                                <div className="">
                                    <p className="font-semibold text-sm">{cur_currency + " " + (+itm?.spend_amount?.toFixed(2))} Gift Card</p>
                                    <p className=" text-xs text-[#000000]/40 ">{itm?.sale_code}</p>
                                </div>
                            </div>
                        }
                        )}
                    </div>
                </div>
            }


            <Popup size='md' heading={`Split Tip `} close={setSplitTip} open={SplitTip}>
                <div className='flex flex-col gap-2'>
                    <div className='flex items-center gap-4'>
                        <div className='flex items-center gap-3 border rounded-md p-3 flex-1'>
                            <p className='text-xs text-[#A1A1A1]'>{currency}</p>
                            <input
                                type="number"
                                className='flex-1 outline-none border-none w-[3rem] text-sm'
                                placeholder='12'
                                max={100}
                                maxLength={3}
                                value={tip_inp}
                                onChange={(e) => {
                                    if (e.target.value <= 100) {
                                        setTipInp(e.target.value)
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex items-center justify-start mt-[2rem]'>
                    <LoginBtn
                        onClick={() => {
                            APPOINTMENT_TIPS.push({ tip: tip_inp })
                            setSplitTip(false)
                        }}
                    >
                        {selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}

                    </LoginBtn>
                </div>
            </Popup>
            {/* Redemption Popup */}
            <Popup
                size='md'
                heading={selectedLanguage?.name == "English" ? "Confirm Redemption" : translation[213]?.value ? translation[213]?.value : "Confirm Redemption"}
                close={setRedeemPop} open={redeemPop}
            >
                <div className='flex flex-col gap-5 p-5'>
                    <div className='flex flex-col gap-5'>
                        <h3 className="text-center font-semibold text-xl">
                            {selectedLanguage?.name == "English" ? "Confirm Redemption" : translation[213]?.value ? translation[213]?.value : "Confirm Redemption"}
                        </h3>
                        {notFound ?
                            <div className='text-[#A1A1A1] flex items-center gap-1.5'>
                                {selectedLanguage?.name == "English" ? "Loyalty Points Not Found" : translation[214]?.value ? translation[214]?.value : "Loyalty Points Not Found"}
                            </div>
                            :
                            <div className='grid grid-cols-2 items-center text-center py-5'>
                                <div className='border-r border-[#C7C7C7]'>
                                    <h5 className='text-sm text-[#A1A1A1]'>{selectedLanguage?.name == "English" ? "Total redeem points" : translation[1650]?.value ? translation[1650]?.value : "Total redeem points"}</h5>
                                    <p className='font-bold text-3xl'>{pointsToRedeem?.toFixed(2)}</p>
                                </div>
                                <div>
                                    <h5 className='text-sm text-[#A1A1A1]'>{selectedLanguage?.name == "English" ? "Total redeemable value" : translation[1651]?.value ? translation[1651]?.value : "Total redeemable value"}</h5>
                                    <p className='font-bold text-3xl'>{amountForPoints?.toFixed(2)} {currency}</p>
                                </div>
                            </div>}
                        <div className="flex items-center justify-end gap-3">
                            <LoginBtn
                                borderBtn
                                text={
                                    <div className='flex items-center gap-2'>
                                        <p>{selectedLanguage?.name == "English" ? "Cancel" : translation[1421]?.value ? translation[1421]?.value : "Cancel"}</p>
                                    </div>
                                }
                                onClick={() => setRedeemPop(false)}
                            />
                            {loyalityPoints &&
                                <LoginBtn
                                    disabled={amountForPoints < t_price}
                                    onClick={() => {
                                        toast.success(selectedLanguage?.name == "English" ? "Loyality points redeem successfully" : translation[1652]?.value ? translation[1652]?.value : "Loyality points redeem successfully", { toastId: "toast" })
                                        setSelectedPaymentMethods([])
                                        setIs_redeemed(true)
                                        setRedeemed_points(pointsToRedeem?.toFixed(2))
                                        setLoyaltyDiscountAmount(amountForPoints?.toFixed(2))
                                        setRedeemed_id(loyalityPoints?.id)
                                        setRedeemPop(false)
                                        setData({
                                            ...data,
                                            voucher_redeem: undefined,
                                            voucher_type: "",
                                            is_voucher_redeemed: false,
                                            redeemed_voucher_id: "",
                                            membership_product: [],
                                            membership_service: [],
                                            membership_type: "",
                                            is_membership_redeemed: false,
                                            redeemed_membership_id: "",
                                            is_coupon_redeemed: false,
                                            is_gift_card_redeemed: false,
                                            appliedGiftCard: {
                                                purchased_gift_card_id: '',
                                                partial_price: 0
                                            },
                                            redeemed_coupon_id: "",
                                            coupon_type_value: "",
                                            couponsIds: [],
                                            coupon_name: "",
                                            coupon_type: "",
                                            couponDiscountPercentage: "",
                                            couponSpendAmount: ""
                                        })
                                    }}>
                                    {selectedLanguage?.name == "English" ? "Redeem" : translation[204]?.value ? translation[204]?.value : "Redeem"}
                                </LoginBtn>
                            }
                        </div>
                    </div>
                </div>
            </Popup>

            {/* Tip Popup */}
            {tipPopup &&
                <Popup size='md'
                    heading={selectedLanguage?.name == "English" ? "Add a tip to the staff" : translation[186]?.value ? translation[186]?.value : "Add a tip to the staff"}
                    close={setTipPopup}
                    open={tipPopup}
                >
                    <div className='flex gap-4 max-h-[25rem] overflow-y-auto'>
                        <div className="flex flex-col w-full ">
                            {Employees?.sort(itm => itm.level == "Above_Averge" ? -1 : 1)?.map(empl => {
                                return (
                                    <div className="flex items-center gap-3 ">
                                        <div className='w-1/2 p-2'>
                                            {empl?.full_name}
                                        </div>
                                        <div className='flex w-1/2 items-center gap-2 border rounded-md flex-1 '>
                                            <div className='p-2 rounded-l-md text-xs text-[#A1A1A1] bg-slate-50 h-full flex items-center justify-center w-10 border-r-[1px]'>{cur_currency}</div>
                                            <input
                                                type="number"
                                                className='placeholder:text-[0.6rem] flex-1 outline-none border-none w-[3rem] text-sm'
                                                placeholder={selectedLanguage?.name == "English" ? "Add tip" : translation[187]?.value ? translation[187]?.value : "Add tip"}
                                                max={100}
                                                maxLength={4}
                                                onKeyDown={(e) => {
                                                    if (e.key === '-') {
                                                        e.preventDefault(); // Prevent typing of '-' symbol
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    const value = Math.abs(+e.target.value?.replace(/[^0-9.]/g, '')?.slice(0, 4))
                                                    const updatedTipValues = {
                                                        ...currentEmployeeTip,
                                                        [empl?.id]: value
                                                    };
                                                    setCurrentEmployeeTip(updatedTipValues);
                                                    handleTipChange(empl?.id, empl?.full_name, value)
                                                }}
                                                value={Math.abs(currentEmployeeTip[empl?.id]) || ''}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='flex items-center gap-5 justify-end mt-[2rem]'>
                        <LoginBtn
                            // disabled={amount_for_points < t_price}
                            className={"!px-6"}
                            // disabled={employee_tips?.length == 0}
                            onClick={() => {
                                setTipPopup(false)
                                setEmployeeTips(employee_tips)
                                setEmployeeTipSum(tip_sum)
                            }}
                        >
                            {selectedLanguage?.name == "English" ? "Add" : translation[97]?.value ? translation[97]?.value : "Add"}
                        </LoginBtn>
                    </div>
                </Popup>
            }

            {couponPopup &&
                <Popup size='md' heading={`Redeem Coupon`} close={() => { setCouponPopup(!couponPopup) }} open={couponPopup}>
                    <div className='flex flex-col gap-5'>
                        <div className='py-3'>
                            <LoginInput
                                title={"Enter Coupon Code"}
                                placeholder={selectedLanguage?.name == "English" ? "Enter Coupon Code" : translation[901]?.value ? translation[901]?.value : "Enter Coupon Code"}
                                name="couponCode"
                                value={couponCode}
                                type="text"
                                charactersMaxLength={20}
                                onChange={(e) => {
                                    setCouponCode(e.target.value)
                                }}
                            />
                        </div>
                        <div className='flex gap-3 items-center justify-end'>
                            <LoginBtn
                                borderBtn
                                text={
                                    <div className='flex items-center gap-2'>
                                        <p>{selectedLanguage?.name == "English" ? "Cancel" : translation[97]?.value ? translation[97]?.value : "Cancel"}</p>
                                    </div>
                                }
                                onClick={() => { setCouponPopup(!couponPopup) }}
                            />
                            <LoginBtn
                                disabled={!couponCode || getCouponLoader}
                                loading={getCouponLoader}
                                text={
                                    <div className='flex items-center gap-2'>
                                        <p>{selectedLanguage?.name == "English" ? "Redeem Coupon" : translation[97]?.value ? translation[97]?.value : "Redeem Coupon"}</p>
                                    </div>
                                }
                                onClick={checkCouponCode}
                            />
                        </div>
                    </div>
                </Popup>
            }

            <Popup size='md'
                heading={selectedLanguage?.name == "English" ? "Redeem gift card" : translation[1549]?.value ? translation[1549]?.value : "Redeem gift card"}
                close={() => {
                    setGiftCardPopup(false)
                    setGiftCardDetail([])
                }}
                open={giftCardPopup}>
                <div className=' p-3'>

                    {(giftCards?.length > 0) ?
                        <>
                            {giftCards?.map((itm, index) =>
                                <div className='space-y-5' key={index}>
                                    <div className='flex flex-col justify-between gap-10 p-5 px-7 rounded-[20px] text-white md:h-[255px]' style={{ backgroundImage: 'linear-gradient(269.5deg, #5679FF -5.22%, #325AF1 87.33%)' }}>
                                        <div>
                                            <div className='flex items-end gap-5'>
                                                <p className='text-[2.5rem] font-bold'>{cur_currency} {' '}
                                                    {+(itm?.spend_amount)?.toFixed(2)}</p>
                                                <p className='text-[1.3rem]'>/ {cur_currency} {' '} {+(itm?.price)?.toFixed(2)}</p>
                                            </div>
                                            <p className='text-[1.25rem]'>{itm.gift_card_detail?.title}</p>
                                        </div>
                                        <div className='flex justify-between items-center'>
                                            <div className='flex flex-col'>
                                                <p className='font-light text-base'>{selectedLanguage?.name == "English" ? "Code" : translation[1576]?.value ? translation[1576]?.value : "Code"}</p>
                                                <p className='text-[1.25rem] font-semibold'>{itm?.sale_code}</p>
                                            </div>
                                            <div className='flex flex-col'>
                                                <p className='font-light text-base'> {selectedLanguage?.name == "English" ? "Expiry" : translation[203]?.value ? translation[203]?.value : "Expiry"}</p>
                                                <p className='text-[1.25rem] font-semibold'>{moment(itm?.expiry).format('DD MMMM YYYY')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex justify-between items-end'>
                                        <p className='font-semibold text-lg'>{selectedLanguage?.name == "English" ? "Amount to Pay" : translation[1540]?.value ? translation[1540]?.value : "Amount to Pay"}</p>
                                        <p className='font-semibold text-lg'>{cur_currency} {' '} {(+amountToPay).toFixed(2)}</p>
                                    </div>
                                    <div className='flex flex-col gap-5'>
                                        <div>
                                            <FloatInput
                                                title={selectedLanguage?.name == "English" ? "Redeem Amount" : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"}
                                                placeholder={selectedLanguage?.name == "English" ? "Redeem Amount" : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"}
                                                name="giftCardCode"
                                                value={giftCard_amount}
                                                required={false}
                                                type="text"
                                                charactersMaxLength={+amountToPay?.toString().length || 6}
                                                onChange={(e) => {
                                                    setGiftCardError('')
                                                    if (+e.target.value !== 0) {
                                                        if (+amountToPay < +e.target.value || +(itm?.spend_amount) < +e.target.value) {
                                                            // setGiftCard_amount(null)
                                                            setGiftCardError(`${selectedLanguage?.name == "English" ? "Amount to redeem must be lower or equal to " : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"} ${+(itm?.spend_amount) < +e.target.value ? +(itm?.spend_amount) : +amountToPay.toFixed(2)} `)

                                                        } else {
                                                            setGiftCardError('')
                                                            setGiftCard_amount(e.target.value)
                                                        }
                                                    } else {
                                                        setGiftCard_amount(null)
                                                        setGiftCardError(`${selectedLanguage?.name == "English" ? "Amount cannot be zero" : translation[1664]?.value ? translation[1664]?.value : "Amount cannot be zero"} `)
                                                    }
                                                }}
                                                error={giftCardError}
                                                parentClass={"mb-2"}
                                            />
                                        </div>
                                        <div className="flex items-center justify-end gap-3">
                                            <LoginBtn
                                                borderBtn
                                                text={
                                                    <div className='flex items-center gap-2'>
                                                        <p>{selectedLanguage?.name == "English" ? "Cancel" : translation[1421]?.value ? translation[1421]?.value : "Cancel"}</p>
                                                    </div>
                                                }
                                                onClick={() => {
                                                    setGiftCardPopup(false)
                                                    setGiftCardDetail([])
                                                }}
                                            />
                                            <LoginBtn
                                                onClick={() => {
                                                    if (+itm?.spend_amount?.toFixed(2) < (+giftCard_amount)) {
                                                        setGiftCardError(`${selectedLanguage?.name == "English" ? "Amount to redeem must be lower or equal to " : translation[1577]?.value ? translation[1577]?.value : "Redeem Amount"} ${+(itm?.spend_amount).toFixed(2)} `)
                                                    }
                                                    else {
                                                        setData({
                                                            ...data,
                                                            is_gift_card_redeemed: true,
                                                            membership_product: [],
                                                            membership_service: [],
                                                            membership_type: '',
                                                            is_membership_redeemed: false,
                                                            redeemed_membership_id: '',
                                                            voucher_redeem: undefined,
                                                            voucher_type: "",
                                                            is_voucher_redeemed: false,
                                                            redeemed_voucher_id: "",
                                                            is_coupon_redeemed: false,
                                                            redeemed_coupon_id: "",
                                                            coupon_type_value: "",
                                                            couponsIds: [],
                                                            coupon_name: "",
                                                            coupon_type: "",
                                                            couponDiscountPercentage: "",
                                                            couponSpendAmount: "",
                                                            appliedGiftCard: {
                                                                purchased_gift_card_id: itm?.id,
                                                                partial_price: +giftCard_amount,
                                                            }
                                                        })
                                                        setIs_redeemed(false)
                                                        setRedeemed_points('')
                                                        setLoyaltyDiscountAmount('')
                                                        setRedeemed_id("")
                                                        setSelectedPaymentMethods([])
                                                        setGiftCardPopup(!giftCardPopup)
                                                    }
                                                }}
                                                disabled={!giftCard_amount || giftCardError}
                                                className={'h-fit'}
                                                text={selectedLanguage?.name == "English" ? "Redeem" : translation[204]?.value ? translation[204]?.value : "Redeem"}
                                            />

                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                        :
                        <div className="flex flex-col gap-3">
                            <div className='py-1'>
                                <LoginInput
                                    title={selectedLanguage?.name == "English" ? "Gift card number" : translation[1649]?.value ? translation[1649]?.value : "Gift card number"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter gift card number" : translation[1648]?.value ? translation[1648]?.value : "Enter gift card number"}
                                    name="giftCardCode"
                                    value={giftCardCode}
                                    type="text"
                                    charactersMaxLength={6}
                                    onChange={(e) => {
                                        setGiftCardCode(e.target.value)
                                    }}
                                />
                            </div>

                            <div className='flex justify-end items-center gap-3'>
                                <LoginBtn
                                    borderBtn
                                    text={<p>{selectedLanguage?.name == "English" ? "Cancel" : translation[1421]?.value ? translation[1421]?.value : "Cancel"}</p>}
                                    onClick={() => {
                                        setGiftCardPopup(false)
                                        setGiftCardDetail([])
                                    }}
                                />
                                <LoginBtn
                                    disabled={!giftCardCode || giftCardLoader}
                                    loading={giftCardLoader}
                                    text={
                                        <div className='flex items-center gap-2'>
                                            <p>{selectedLanguage?.name == "English" ? "Apply" : translation[1421]?.value ? translation[1421]?.value : "Apply"}</p>
                                        </div>
                                    }
                                    onClick={checkGiftCardCode}
                                />
                            </div>

                        </div>
                    }
                </div>
            </Popup>

            {(data?.client && data?.other_method === "Voucher" && voucherAvailable && voucherPopup) &&
                <Popup size='md' heading={selectedLanguage?.name == "English" ? "Vouchers" : translation[196]?.value ? translation[196]?.value : "Vouchers"} close={() => { setVoucherPopup(!voucherPopup) }} open={voucherPopup}>
                    <div className=''>
                        {
                            voucherNotFound ? <NotFound /> :
                                checkoutVouchers?.length > 0 && checkoutVouchers?.map((vchr, index) =>
                                    <>
                                        <div className='border-2 border-[#e5e7eb] border-solid px-5 py-3 rounded-xl my-4' key={index}>
                                            <div className=''>
                                                <div className="pb-3 flex items-center gap-5 justify-between">
                                                    <div className="space-y-1">
                                                        <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name"}</p>
                                                        <h3 className="font-bold">{vchr?.voucher?.name}</h3>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <h3 className=" font-bold text-right">{cur_currency + " " + vchr?.voucher_price}</h3>
                                                        <div className="px-[0.625rem] py-2 rounded-lg bg-[#ECF3FF]"><p className="text-[#5679FF] text-xs">{vchr?.discount_percentage} Percentge</p></div>
                                                    </div>
                                                </div>
                                                <div className="space-y-4 py-3">
                                                    <div className="flex items-center justify-between sm:flex-row flex-col border-y border-solid border-[#DEDFE5] sm:divide-x divide-y sm:divide-y-0">
                                                        <div className="px-5  py-3 w-full sm:w-fit">
                                                            <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"}</p>
                                                            <h4 className="font-bold whitespace-nowrap">{vchr?.voucher?.voucher_type}</h4>
                                                        </div>
                                                        <div className="px-5  py-3 w-full sm:w-fit">
                                                            <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Purchase at" : translation[1647]?.value ? translation[1647]?.value : "Purchase at"}</p>
                                                            <h4 className="font-bold whitespace-nowrap">{moment(vchr?.created_at).format('DD MMMM YYYY')}</h4>
                                                        </div>
                                                        <div className="px-5  py-3 w-full sm:w-fit">
                                                            <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Expiry" : translation[203]?.value ? translation[203]?.value : "Expiry"}</p>
                                                            <h4 className="font-bold whitespace-nowrap">{moment(vchr?.voucher?.end_date).format('DD MMMM YYYY')}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full flex items-center justify-end gap-3">
                                                <LoginBtn onClick={() => {
                                                    const filteredItemsforOrderIds = data?.ids?.map((itm) => {
                                                        if (itm?.is_voucher_redeemed) {
                                                            delete itm.is_voucher_redeemed
                                                            delete itm.redeemed_price
                                                            delete itm.discount_percentage
                                                        }
                                                        return itm
                                                    })
                                                    setData({
                                                        ...data,
                                                        ids: filteredItemsforOrderIds,
                                                        voucher_redeem: vchr?.discount_percentage,
                                                        voucher_type: vchr?.voucher?.voucher_type,
                                                        is_voucher_redeemed: true,
                                                        redeemed_voucher_id: vchr?.id,
                                                        new_voucher_redeemed_id: vchr?.voucher?.id,
                                                        membership_product: [],
                                                        membership_service: [],
                                                        membership_type: "",
                                                        is_membership_redeemed: false,
                                                        redeemed_membership_id: "",
                                                        is_coupon_redeemed: false,
                                                        redeemed_coupon_id: "",
                                                        coupon_type_value: "",
                                                        couponsIds: [],
                                                        coupon_name: "",
                                                        is_gift_card_redeemed: false,
                                                        coupon_type: "",
                                                        couponDiscountPercentage: "",
                                                        couponSpendAmount: "",
                                                        appliedGiftCard: {
                                                            purchased_gift_card_id: '',
                                                            partial_price: 0
                                                        },
                                                    })
                                                    setSelectedPaymentMethods([])
                                                    setIs_redeemed(false)
                                                    setRedeemed_points('')
                                                    setLoyaltyDiscountAmount('')
                                                    setRedeemed_id("")
                                                    setVoucherPopup(!voucherPopup)
                                                    toast.success(selectedLanguage?.name == "English" ? "Voucher redeem successfully" : translation[1654]?.value ? translation[1654]?.value : "Voucher redeem successfully", { toastId: "toast" })
                                                }}
                                                    text={selectedLanguage?.name == "English" ? "Redeem" : translation[204]?.value ? translation[204]?.value : "Redeem"}
                                                />
                                            </div>
                                        </div>
                                    </>)
                        }
                    </div>
                </Popup>
            }
            {(data.client_type !== "Walk_in" && data?.client && data?.other_method === "LoyalityPoints" && !notFound && loyalityPoints && loyalityPopup) &&
                <Popup size='md'
                    heading={selectedLanguage?.name == "English" ? "Loyalty Points" : translation[197]?.value ? translation[197]?.value : "Loyalty Points"}
                    close={() => { setLoyalityPopup(!loyalityPopup) }}
                    open={loyalityPopup}>
                    <div className='flex flex-col gap-5 p-5'>
                        {notFound ?
                            <NotFound /> :
                            <div className='flex flex-col gap-5'>
                                <div className='grid grid-cols-2 items-center text-center py-5'>
                                    <div className='border-r border-[#C7C7C7]'>
                                        <h5 className='text-sm text-[#A1A1A1]'>{selectedLanguage?.name == "English" ? "Total Points" : translation[210]?.value ? translation[210]?.value : "Total Points"}</h5>
                                        <p className='font-bold text-3xl'>{loyalityPoints?.total_available_points?.toFixed(2)}</p>
                                    </div>
                                    <div>
                                        <h5 className='text-sm text-[#A1A1A1]'>{selectedLanguage?.name == "English" ? "Redeemable Value" : translation[211]?.value ? translation[211]?.value : "Redeemable Value"}</h5>
                                        <p className='font-bold text-3xl'>{loyalityPoints?.total_amount?.toFixed(2)}</p>
                                    </div>
                                </div>
                                <div className="flex items-center justify-end gap-3">
                                    <LoginBtn
                                        borderBtn
                                        text={
                                            <div className='flex items-center gap-2'>
                                                <p>{selectedLanguage?.name == "English" ? "Cancel" : translation[1421]?.value ? translation[1421]?.value : "Cancel"}</p>
                                            </div>
                                        }
                                        onClick={() => { setLoyalityPopup(false) }}
                                    />
                                    {loyalityPoints &&
                                        <LoginBtn
                                            disabled={notFound || redeemed_id}
                                            onClick={() => {
                                                if ((+t_price + +calculatedTax) <= +loyalityPoints?.total_amount) {
                                                    setLoyalityPopup(false)
                                                    setRedeemPop(true)
                                                } else
                                                    toast.error(selectedLanguage?.name == "English" ? "You don't have enough loyalty points to complete this sale" : translation[212]?.value ? translation[212]?.value : "You don't have enough loyalty points to complete this sale", { toastId: "toast" })
                                            }}>
                                            {selectedLanguage?.name == "English" ? "Apply" : translation[1421]?.value ? translation[1421]?.value : "Apply"}
                                        </LoginBtn>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </Popup>
            }

            {(data?.client && data?.other_method === "Membership" && membershipAvailable && membershipPopup) &&
                <Popup size='md' heading={selectedLanguage?.name == "English" ? "Memberships" : translation[195]?.value ? translation[195]?.value : "Memberships"} close={() => { setMembershipPopup(!membershipPopup) }} open={membershipPopup}>
                    {membershipNotFound ? <NotFound /> :
                        checkoutMemberships?.length > 0 && checkoutMemberships?.map((membership, index) =>
                            <div className='border-2 border-[#e5e7eb] border-solid px-5 py-3 rounded-xl my-4' key={index}>
                                <div className='divide-y'>
                                    <div className="pb-3 flex items-center gap-5 justify-between">
                                        <div className="space-y-1">
                                            <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Membership Name" : translation[205]?.value ? translation[205]?.value : "Membership Name"}</p>
                                            <h3 className="font-bold">{membership?.name}</h3>
                                        </div>
                                        <div className="space-y-1">
                                            <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Price" : translation[200]?.value ? translation[200]?.value : "Price"}</p>
                                            <h3 className=" font-bold">{cur_currency + " " + membership?.membership_price}</h3>
                                        </div>
                                    </div>
                                    <div className="space-y-1 py-3">
                                        <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Membership Services" : translation[206]?.value ? translation[206]?.value : "Membership Services"}</p>
                                        <div className="flex items-center gap-3 flex-wrap">
                                            {membership?.services?.map(service => (
                                                <div className="px-[0.625rem] py-2 rounded-lg bg-[#ECF3FF]"><p className="text-[#5679FF]">{service?.service_name}</p></div>
                                            ))}

                                        </div>
                                    </div>
                                    <div className="space-y-4 py-3">
                                        <div className="space-y-1">
                                            <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Membership Products" : translation[1236]?.value ? translation[1236]?.value : "Membership Products"}</p>
                                            <div className="flex items-center gap-3 flex-wrap">
                                                {membership?.products?.map(product => (
                                                    <div className="px-[0.625rem] py-2 rounded-lg bg-[#ECF3FF]"><p className="text-[#5679FF] text-sm">{product?.product_name}</p></div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between sm:flex-row flex-col border-y border-solid border-[#DEDFE5] sm:divide-x divide-y sm:divide-y-0">
                                            <div className="px-5  py-3 w-full sm:w-fit">
                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"}</p>
                                                <h4 className="font-bold whitespace-nowrap">{membership?.discount_type}</h4>
                                            </div>
                                            <div className="px-5  py-3 w-full sm:w-fit">
                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Purchase at" : translation[1647]?.value ? translation[1647]?.value : "Purchase at"}</p>
                                                <h4 className="font-bold whitespace-nowrap">{moment(membership?.created_at).format('DD MMMM YYYY')}</h4>
                                            </div>
                                            <div className="px-5  py-3 w-full sm:w-fit">
                                                <p className="text-[#7A7A7A]">{selectedLanguage?.name == "English" ? "Expiry" : translation[203]?.value ? translation[203]?.value : "Expiry"}</p>
                                                <h4 className="font-bold whitespace-nowrap">{moment(membership?.end_date).format('DD MMMM YYYY')}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex items-center justify-end gap-3">
                                    <LoginBtn onClick={() => {
                                        const filteredItemsforOrderIds = data?.ids?.map((itm) => {
                                            if (itm?.is_membership_redeemed) {
                                                delete itm.is_membership_redeemed
                                                delete itm.redeemed_price
                                                delete itm.discount_percentage
                                            }
                                            return itm
                                        })
                                        let redeemValidation = data?.ids?.map(srvce => membership?.services?.find(itm => itm?.service == srvce?.id))?.filter(obj => obj !== undefined)?.length > 0 || data?.ids?.map(prod => membership?.products?.find(itm => itm?.product == prod?.id))?.filter(obj => obj !== undefined)?.length > 0 ? true : false
                                        setData({
                                            ...data,
                                            ids: filteredItemsforOrderIds,
                                            membership_product: data?.ids?.map(prod => membership?.products?.find(itm => itm?.product == prod?.id))?.filter(obj => obj !== undefined),
                                            membership_service: data?.ids?.map(srvce => membership?.services?.find(itm => itm?.service == srvce?.id))?.filter(obj => obj !== undefined),
                                            membership_type: membership?.discount_type,
                                            is_membership_redeemed: redeemValidation ? true : false,
                                            redeemed_membership_id: redeemValidation ? membership?.id : undefined,
                                            voucher_redeem: undefined,
                                            voucher_type: "",
                                            is_voucher_redeemed: false,
                                            redeemed_voucher_id: "",
                                            is_coupon_redeemed: false,
                                            is_gift_card_redeemed: false,
                                            appliedGiftCard: {
                                                purchased_gift_card_id: '',
                                                partial_price: 0
                                            },
                                            redeemed_coupon_id: "",
                                            coupon_type_value: "",
                                            couponsIds: [],
                                            coupon_name: "",
                                            coupon_type: "",
                                            couponDiscountPercentage: "",
                                            couponSpendAmount: ""
                                        })
                                        setIs_redeemed(false)
                                        setRedeemed_points('')
                                        setLoyaltyDiscountAmount('')
                                        setRedeemed_id("")
                                        setSelectedPaymentMethods([])
                                        setMembershipPopup(!membershipPopup)
                                        if (redeemValidation) {
                                            toast.success(selectedLanguage?.name == "English" ? "Membership redeem successfully" : translation[1653]?.value ? translation[1653]?.value : "Membership redeem successfully", { toastId: "toast" })
                                        } else {
                                            toast.error(selectedLanguage?.name == "English" ? "Membership cannot be redeemed" : translation[1653]?.value ? translation[1653]?.value : "Membership cannot be redeemed", { toastId: "toast" })
                                        }

                                    }}
                                        text={selectedLanguage?.name == "English" ? "Redeem" : translation[204]?.value ? translation[204]?.value : "Redeem"} />
                                </div>
                            </div>
                        )
                    }
                </Popup>
            }
        </>
    )
}

const Checkout = () => {
    const { storeDataInLocalStorage } = useSetDataInLocalStorage();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { locationDetail } = useLocation();
    const state = useSelector(state => state)
    const reduxPaymentMethodList = useSelector((state) => state?.financialSettings?.paymentMethod);
    const selected_location = state?.locations?.selected_location;
    const active_currency_id = locationDetail?.currency?.id
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const location_currency = state?.utility?.currency?.location_currency;
    const cmsn = state?.employee?.employee_commsions;
    const singleTaxName = state?.saleService?.location_tax?.tax ? state?.saleService?.location_tax?.tax : ""
    const cartItems = state.cartItem.cart_items;
    const clientInCart = state?.cartItem?.installment_client

    const [total_price, setTotalPrice] = useState(0)
    const [calculatedTax, setCalculatedTax] = useState(0)
    const [is_redeemed, setIs_redeemed] = useState(false)
    const [redeemed_id, setRedeemed_id] = useState('')
    const [redeemed_points, setRedeemed_points] = useState('')
    const [loyaltyDiscountAmount, setLoyaltyDiscountAmount] = useState('')
    const [Employees, setEmployees] = useState([])
    const [t_price, setT_price] = useState(0)
    const [btn_loading, setLoading] = useState(false)
    const [tax, setTax] = useState()
    const [commission, setCommission] = useState({
        service_commission: 0,
        product_commission: 0,
        voucher_commission: 0,
        service_total_price: 0,
        product_total_price: 0,
        voucher_total_price: 0,
        service_commission_type: null,
        product_commission_type: null,
        voucher_commission_type: null,
    })
    const [OrderData, setOrderData] = useState((cartItems?.find((itm) => itm?.selection_type === "VOUCHER" || itm?.selection_type === "MEMBERSHIP" || itm?.selection_type === "INSTALLMENT_MEMBERSHIP"))
        ? {
            client_type: 'In_Saloon',
            payment_type: reduxPaymentMethodList?.length > 0
                ? reduxPaymentMethodList[0]?.method_type
                : '',
            tips: 0,
            client_type_changeable: true,
            client: clientInCart?.id ? clientInCart?.id : ""
        }
        : {
            client_type: localStorage.getItem('client_type') && localStorage.getItem('client_type') === 'In_Saloon'
                ? 'In_Saloon'
                : 'Walk_in',
            payment_type: reduxPaymentMethodList?.length > 0
                ? reduxPaymentMethodList[0]?.method_type
                : '',
            tips: 0,
            client_type_changeable: true
        }
    )
    const [tip_for_emply, setTip_for_emply] = useState([])
    const [employee_tips, setEmployee_tips] = useState([])
    const [employeeTips, setEmployeeTips] = useState([])
    const [currentEmployeeTip, setCurrentEmployeeTip] = useState([])
    const [employeeTipSum, setEmployeeTipSum] = useState(0)
    const [orderCheckoutData, setOrderCheckoutData] = useState()
    const [discountValue, setDiscountValue] = useState(null)
    const [couponDiscountValue, setCouponDiscountValue] = useState('0')
    const [couponDetail, setCouponDetail] = useState({})
    const [giftCardDetail, setGiftCardDetail] = useState({})
    const [totalPriceWithoutTax, setTotalPriceWithoutTax] = useState(0)
    const [newAddedItmFromCoupon, setNewAddedItmFromCoupon] = useState({})
    const [taxApplied, setLocationTax] = useState(0)
    const [payBtnDisabled, setPayBtnDisabled] = useState(false)
    const [totalAmountPaid, setTotalAmountPaid] = useState(0)
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])
    const [paymentError, setPaymentError] = useState({ id: '', message: '' })
    const [tip_sum, setTip_sum] = useState(0)
    const [totalTips, setTotalTips] = useState(0)
    const [subTotal, setSubTotal] = useState('')

    // on change function
    const onUpdateHandler = (e) => {
        let { name, value } = e.target
        if (name == 'client_type') {
            setOrderData({
                ...OrderData,
                [name]: value,
                client: ""
            })
        }
        if (name !== 'client_type') {
            setOrderData({
                ...OrderData,
                [name]: value
            })
        }
    }

    //get selecte member commssions
    const getMemberCommission = () => {
        if (OrderData?.member) {
            dispatch(getSaleEmployeeCommission(
                OrderData?.member,
                () => {
                    // toast.error(apiErrorMessage,{ toastId: "toast" })
                },
            ))
        }
    }

    // Booking detail card data
    const BookingData = () => {
        if (state.saleService?.selection.length > 0) {
            let data = cartItems?.map(itm => {
                return {
                    name: itm?.name ? itm?.name : itm?.title,
                    price: itm?.price ? itm?.price : itm?.full_price ? itm?.full_price : 0,
                    spend_amount: itm?.spend_amount,
                    quantity: itm?.quantity,
                    client_can_book: itm.client_can_book,
                    description: itm.description,
                    discount_price: (itm.discount_price || itm?.discount_price == 0) && itm?.discount_price,
                    slot_availible_for_online: itm.slot_availible_for_online,
                    currency_membership: itm.currency_membership,
                    gift_card_details: itm?.gift_card_details,
                    id: itm.id,
                    is_coupon_redeemed: itm?.is_coupon_redeemed,
                    coupon_discounted_price: itm?.coupon_discounted_price
                }
            })
            return data
        }
        return []
    }

    useEffect(() => {
        if (localStorage.getItem('client_type') && localStorage.getItem('client_type') === 'In_Saloon') {
            setTimeout(() => {
                localStorage.removeItem('client_type')
            }, 500);
        }
    }, [])

    // deleting client if change client type
    useEffect(() => {
        const order_data = { ...OrderData }
        if (order_data?.client_type == "Walk_in") {
            delete order_data.client;
        }
        setOrderData(order_data)
    }, [OrderData?.client_type])

    useEffect(() => {
        if (OrderData?.other_method === 'Coupon') {
            const updatedOrderData = { ...OrderData };
            delete updatedOrderData.other_method;
            delete updatedOrderData.coupon_name;
            delete updatedOrderData.coupon_type;
            delete updatedOrderData.coupon_type_value;
            delete updatedOrderData.couponsIds;
            delete updatedOrderData.couponDiscountPercentage;
            delete updatedOrderData.couponSpendAmount;
            delete updatedOrderData.is_coupon_redeemed;
            setOrderData(updatedOrderData);
        }
    }, [OrderData?.client, OrderData?.client_type])

    // making initial checkout data here
    useEffect(() => {
        const order_data = { ...OrderData }
        order_data.tips = employeeTips?.map(tip_item => ({ ...tip_item, employee: tip_item.emp_id }))

        let new_order_data = {
            ...order_data,
            ...commission,
            location: selected_location,
            total_price: +total_price,
            status: 'Completed',
            loyality_redemption: {
                is_redeemed: is_redeemed,
                client_loyalty_point: redeemed_id,
                redeemed_points: redeemed_points,
                redeemed_amount: loyaltyDiscountAmount
            },
            is_redeemed: is_redeemed,
            redeemed_id: redeemed_id,
            redeemed_points: redeemed_points,
            redeem_option: OrderData?.is_membership_redeemed ? "Membership" : OrderData?.is_voucher_redeemed ? "Voucher" : '',
            voucher_redeem_percentage: OrderData?.is_voucher_redeemed ? OrderData?.voucher_redeem : null,
            redeemed_coupon_id: OrderData?.redeemed_coupon_id ? OrderData?.redeemed_coupon_id : null
        }
        new_order_data?.ids?.forEach(item => {
            if (!item.discount_price && item?.discount_price != 0) {
                delete item.discount_price
            }
            let priceOfItem = item?.installment_price ? item?.installment_price : (item.discount_price || item?.discount_price == 0) ? item?.discount_price * item?.quantity : item?.price * item?.quantity
            // ================================================  VOUCHER SCENARIOS  =========================================== //
            if (new_order_data?.voucher_redeem && new_order_data?.ids?.find(itm => itm?.selection_type.toLowerCase() == new_order_data?.voucher_type.toLowerCase())) {
                if (item?.selection_type?.toLowerCase() == new_order_data?.voucher_type.toLowerCase()) {
                    item.redeemed_price = (priceOfItem - (new_order_data?.voucher_redeem * priceOfItem) / 100);
                    item.is_voucher_redeemed = true;
                    item.discount_percentage = new_order_data?.voucher_redeem
                }
            }
            // total calulcations for voucher
            if (new_order_data?.is_voucher_redeemed && new_order_data?.redeemed_voucher_id) {
                let total = 0
                if (new_order_data?.voucher_type == 'Service') {
                    const filteredService = new_order_data?.ids?.filter(itm => itm?.selection_type == "SERVICE")
                    if (filteredService?.length > 0) {
                        const ServicePriceSum = filteredService?.reduce((accumulator, object) => {
                            // const priceOfItem = object?.discount_price || object?.discount_price == 0 ? object?.discount_price : object?.price
                            const finalPrice = priceOfItem - (new_order_data?.voucher_redeem_percentage * priceOfItem) / 100
                            return accumulator + finalPrice;
                        }, 0);
                        total = ServicePriceSum
                    }
                }
                if (new_order_data?.voucher_type == 'Product') {
                    const filteredProducts = new_order_data?.ids?.filter(itm => itm?.selection_type == "PRODUCT")
                    if (filteredProducts?.length > 0) {
                        const ProductPriceSum = filteredProducts?.reduce((accumulator, object) => {
                            // const priceOfItem = object?.discount_price || object?.discount_price == 0 ? object?.discount_price : object?.price
                            const finalPrice = priceOfItem - (new_order_data?.voucher_redeem_percentage * priceOfItem) / 100
                            return accumulator + finalPrice;
                        }, 0);
                        total = ProductPriceSum
                    }
                }
                new_order_data.voucher_redeemed_price = total
            }
            // ================================================  VOUCHER SCENARIOS  =========================================== //

            // ================================================  MEMBERSHIP FREE SCENARIOS  =========================================== //
            if (new_order_data?.membership_service?.length > 0 && new_order_data?.membership_type == "Free" && OrderData?.redeemed_membership_id) {
                const filteredService = new_order_data?.ids?.filter(itm => itm?.selection_type == "SERVICE")?.filter(itm => new_order_data?.membership_service?.find(srv => srv?.service == itm?.id))?.find(itm => itm?.id == item?.id)
                const servicePrice = (filteredService?.discount_price || filteredService?.discount_price == 0) ? filteredService?.discount_price : filteredService?.price
                if ((servicePrice || servicePrice == 0) && servicePrice != undefined) {
                    // const priceOfItem = item?.discount_price || item?.discount_price == 0 ? item?.discount_price : item?.price
                    item.redeemed_price = priceOfItem - servicePrice
                    item.is_membership_redeemed = true
                    item.discount_percentage = 100
                }
            }
            if (new_order_data?.membership_product?.length > 0 && new_order_data?.membership_type == "Free" && OrderData?.redeemed_membership_id) {
                const productPercantage = new_order_data?.membership_product?.find(itm => itm?.product == item?.id)?.percentage
                if (productPercantage) {
                    // const priceOfItem = item?.discount_price || item?.discount_price == 0 ? item?.discount_price : item?.price
                    item.redeemed_price = (priceOfItem - (productPercantage * priceOfItem) / 100)
                    item.is_membership_redeemed = true
                    item.discount_percentage = productPercantage
                }
            }
            // total calulcations for membership free
            if (new_order_data?.membership_type == "Free" && OrderData?.redeemed_membership_id) {
                let serviceTotal = 0
                let productTotal = 0
                if (new_order_data?.membership_service?.length > 0) {
                    const filteredService = new_order_data?.ids?.filter(itm => itm?.selection_type == "SERVICE")?.filter(itm => new_order_data?.membership_service?.find(srv => srv?.service == itm?.id))
                    if (filteredService?.length > 0) {
                        const ServicePriceSum = filteredService?.reduce((accumulator, object) => {
                            // const priceOfItem = object?.discount_price || object?.discount_price == 0 ? object?.discount_price : object?.price
                            return accumulator + priceOfItem;
                        }, 0);
                        serviceTotal = serviceTotal + ServicePriceSum
                    }
                }
                if (new_order_data?.membership_product?.length > 0) {
                    const filteredProducts = new_order_data?.ids?.filter(itm => itm?.selection_type == "PRODUCT")?.filter(itm => new_order_data?.membership_product?.find(prod => prod?.product == itm?.id))
                    const ProductPriceSum = filteredProducts?.reduce((accumulator, object) => {
                        // const priceOfItem = object?.discount_price || object?.discount_price == 0 ? object?.discount_price : object?.price
                        const productPercantage = new_order_data?.membership_product?.find(itm => itm?.product == object?.id)?.percentage
                        const finalPrice = priceOfItem - (productPercantage * priceOfItem) / 100
                        return accumulator + finalPrice;
                    }, 0);
                    productTotal = productTotal + ProductPriceSum
                }
                new_order_data.membership_redeemed_price = serviceTotal + productTotal
            }
            // ================================================  MEMBERSHIP FREE SCENARIOS  =========================================== //


            // ================================================  MEMBERSHIP LIMITED SCENARIOS  =========================================== //
            if (new_order_data?.membership_service?.length > 0 && new_order_data?.membership_type == "Limited" && OrderData?.redeemed_membership_id) {
                const serviceLimitedPercantage = new_order_data?.membership_service?.find(itm => itm?.service == item?.id)?.percentage
                if (serviceLimitedPercantage) {
                    // const priceOfItem = item?.discount_price || item?.discount_price == 0 ? item?.discount_price : item?.price
                    item.redeemed_price = (priceOfItem - (serviceLimitedPercantage * priceOfItem) / 100)
                    item.is_membership_redeemed = true
                    item.discount_percentage = serviceLimitedPercantage
                }
            }

            if (new_order_data?.membership_product?.length > 0 && new_order_data?.membership_type == "Limited" && OrderData?.redeemed_membership_id) {
                const productLimitedPercantage = new_order_data?.membership_product?.find(itm => itm?.product == item?.id)?.percentage
                if (productLimitedPercantage) {
                    // const priceOfItem = item?.discount_price || item?.discount_price == 0 ? item?.discount_price : item?.price
                    item.redeemed_price = (priceOfItem - (productLimitedPercantage * priceOfItem) / 100)
                    item.is_membership_redeemed = true
                    item.discount_percentage = productLimitedPercantage
                }
            }
            // total calulcations for membership limited
            if (new_order_data?.membership_type == "Limited" && OrderData?.redeemed_membership_id) {
                let serviceTotal = 0
                let productTotal = 0
                if (new_order_data?.membership_service?.length > 0) {
                    const filteredService = new_order_data?.ids?.filter(itm => itm?.selection_type == "SERVICE")?.filter(itm => new_order_data?.membership_service?.find(srv => srv?.service == itm?.id))
                    if (filteredService?.length > 0) {
                        const ServicePriceSum = filteredService?.reduce((accumulator, object) => {
                            // const priceOfItem = object?.discount_price || object?.discount_price == 0 ? object?.discount_price : object?.price
                            const SericePercantage = new_order_data?.membership_service?.find(itm => itm?.service == object?.id)?.percentage
                            const finalPrice = priceOfItem - (SericePercantage * priceOfItem) / 100
                            return accumulator + finalPrice;
                        }, 0);
                        serviceTotal = serviceTotal + ServicePriceSum
                    }
                }
                if (new_order_data?.membership_product?.length > 0) {
                    const filteredProducts = new_order_data?.ids?.filter(itm => itm?.selection_type == "PRODUCT")?.filter(itm => new_order_data?.membership_product?.find(prod => prod?.product == itm?.id))
                    const ProductPriceSum = filteredProducts?.reduce((accumulator, object) => {
                        // const priceOfItem = object?.discount_price || object?.discount_price == 0 ? object?.discount_price : object?.price
                        const productPercantage = new_order_data?.membership_product?.find(itm => itm?.product == object?.id)?.percentage

                        const finalPrice = priceOfItem - (productPercantage * priceOfItem) / 100
                        return accumulator + finalPrice;
                    }, 0);
                    productTotal = productTotal + ProductPriceSum
                }
                new_order_data.membership_redeemed_price = serviceTotal + productTotal
            }
            // ================================================  MEMBERSHIP LIMITED SCENARIOS  =========================================== //

            // ================================================  COUPON SCENARIOS  =========================================== //
            if (new_order_data?.is_coupon_redeemed === true) {
                if (new_order_data?.coupon_type_value == 1 || new_order_data?.coupon_type_value == 2) {
                    let matchingId = new_order_data?.couponsIds?.length > 0 ? new_order_data?.couponsIds?.find(itm => itm?.id == item?.id) : ''
                    if (matchingId) {
                        // const priceOfItem = item?.discount_price || item?.discount_price == 0 ? item?.discount_price : item?.price
                        item.redeemed_price = +priceOfItem - +matchingId?.discount_price
                        item.is_coupon_redeemed = true
                        item.discount_percentage = matchingId?.discount_percentage
                        if (new_order_data?.coupon_type_value == 1) {
                            item.couponRedeemed = true
                        }
                    }

                }
            }
            // ================================================  COUPON SCENARIOS  =========================================== //

            // remove values after deleting redeemed voucher
            if (!new_order_data?.redeemed_voucher_id && item?.is_voucher_redeemed) {
                delete item.is_voucher_redeemed;
                delete item.redeemed_price;
                delete item.discount_percentage
            }

            // remove values after deleting redeemed membership
            if (!new_order_data?.redeemed_membership_id && item?.is_membership_redeemed) {
                delete item.is_membership_redeemed;
                delete item.redeemed_price;
                delete item.discount_percentage
            }

            // remove values after deleting redeemed loyality points
            if (!new_order_data?.redeemed_id && item?.is_redeemed) {
                delete item.is_redeemed;
                delete item.redeemed_id;
                delete item.redeemed_points
            }

            // remove values after deleting redeemed Coupon
            if (!new_order_data?.is_coupon_redeemed && item?.is_coupon_redeemed) {
                delete item.redeemed_price;
                delete item.discount_percentage
                delete item.is_coupon_redeemed;
            }
        })

        if (new_order_data) {
            setOrderCheckoutData(new_order_data)
        }
    }, [OrderData, total_price, employeeTips, couponDiscountValue])

    useEffect(() => {
        const tips_sum = employeeTips?.reduce((accumulator, object) => {
            return accumulator + object.tip;
        }, 0);
        setTotalTips(tips_sum)

    }, [employeeTips])

    // calculate subtotal here
    useEffect(() => {
        const tips_sum = orderCheckoutData?.tips?.reduce((accumulator, object) => {
            return accumulator + object.tip;
        }, 0);
        // setTotalTips(tips_sum)
        setSubTotal((+orderCheckoutData?.total_price - +tips_sum)?.toFixed(2))
    }, [orderCheckoutData])

    // making initial cart data here
    useEffect(() => {
        if (state.saleService?.selection.length > 0) {
            let more_objs = {}
            if (state?.cartItem?.free_services_quantity) {
                more_objs['free_services_quantity'] = state?.cartItem?.free_services_quantity
            }
            if (state?.cartItem?.is_promotion_availed) {
                more_objs['is_promotion_availed'] = state?.cartItem?.is_promotion_availed
            }
            if (state?.cartItem?.selected_promotion_type) {
                more_objs['selected_promotion_type'] = state?.cartItem?.selected_promotion_type
            }
            if (state?.cartItem?.selected_promotion_id) {
                more_objs['selected_promotion_id'] = state?.cartItem?.selected_promotion_id
            }
            if (state?.cartItem?.is_promotion) {
                more_objs['is_promotion'] = state?.cartItem?.is_promotion
            }

            if (state?.cartItem?.cart_items?.find(itm => itm?.selection_type == 'INSTALLMENT_MEMBERSHIP')) {
                more_objs['checkout'] = true
            }

            setOrderData({
                ...OrderData,
                ids: state?.cartItem?.cart_items.map(({
                    custom_code, is_custom_card, coupon_discounted_price, valid_for, validity, is_coupon_redeemed, brand, name, id, valid_till,
                    discount_price, currency_membership, gift_card_details, price, quantity, selection_type, employee, employee_id,
                    avaliableservicegroup, installment_months, installment_price, newAddedCouponItm, membership
                }) => {
                    let this_price = currency_membership?.find(itm => itm?.currency === active_currency_id)?.price
                    let gc_price = gift_card_details?.find(itm => itm?.currencies === active_currency_id)?.price
                    let gc_spend_amount = gift_card_details?.find(itm => itm?.currencies === active_currency_id)?.spend_amount

                    let data = {
                        selection_type, coupon_discounted_price, avaliableservicegroup, is_coupon_redeemed, name, id, discount_price, quantity, employee, employee_id,
                        price: price !== undefined ? price : this_price ? this_price : gc_price, brand
                    }
                    if (gc_spend_amount) {
                        data = { ...data, spend_amount: gc_spend_amount }
                    }
                    if (validity) {
                        data = { ...data, valid_till: validity }
                    }
                    if (valid_till) {
                        data = { ...data, valid_till: valid_till }
                    }
                    if (valid_for) {
                        data = { ...data, valid_till: valid_for }
                    }
                    if (brand !== undefined) {
                        data = { ...data, brand }
                    }
                    if (is_coupon_redeemed) {
                        data = { ...data, couponRedeemed: true, newAddedCouponItm: newAddedCouponItm ? newAddedCouponItm : false, coupon_discounted_price: coupon_discounted_price, is_coupon_redeemed: is_coupon_redeemed }
                    }
                    if (is_custom_card && custom_code) {
                        data = { ...data, custom_code: custom_code }
                    }
                    if (selection_type == 'MEMBERSHIP') {
                        data = { ...data, installment_months, installment_price }
                    }
                    if (selection_type == 'INSTALLMENT_MEMBERSHIP') {
                        data = { ...data, membership, installment_price }
                    }
                    return data
                }),
                client_type: state?.cartItem?.client_type ? state.cartItem.client_type : OrderData?.client_type,
                client_type_changeable: state?.cartItem?.client_type ? false : true,
                ...more_objs
            })
        }
        else {
            navigate('/dashboard/sales/quick-sales')
        }
    }, [state])

    // redeem coupon code
    useEffect(() => {
        // console.log(couponDetail, OrderData, "couponDetail")
        // setting coupon data in OrderData
        if (couponDetail != {} && couponDetail != undefined) {
            let dataToUpdate = {}
            let finaldata = []
            if (couponDetail?.coupon_type == 'refund') {
                dataToUpdate = {
                    refund_coupon: { coupon_discounted_price: +couponDetail?.amount, refund_coupon: couponDetail?.id, client: couponDetail?.client }
                }
            } else if (couponDetail?.coupon_type == 'Cashback') {
                dataToUpdate = {
                    cashback_coupon: { coupon_discounted_price: +couponDetail?.dicounted_amount, cashback_coupon: couponDetail?.id, client: couponDetail?.client }
                }
            } else {
                // apply coupon according to type 1
                if (couponDetail?.coupon_type_value == 1) {
                    // queries to apply coupon on product
                    const filteredProducts = OrderData?.ids?.filter((prd) => prd?.selection_type === "PRODUCT")
                    const productsWithUpdatedPrices = filteredProducts?.map((prod) => {
                        let data = { ...prod }
                        const isProductExcluded = couponDetail?.excluded_products?.includes(prod?.id);
                        const filteredBrandDiscount = couponDetail?.servicegroup_discount?.find((itm) =>
                            itm?.brand[0]?.brand == prod?.brand?.id && !isProductExcluded)?.brand[0]?.brand_discount
                        let updatedDiscountPrice = prod?.discount_price ? prod?.discount_price : prod?.price
                        if (filteredBrandDiscount) {
                            updatedDiscountPrice = (+updatedDiscountPrice * +filteredBrandDiscount / 100)
                            data = { ...data, discount_price: updatedDiscountPrice, discount_percentage: filteredBrandDiscount }
                        } else {
                            data = { ...data, discount_price: updatedDiscountPrice, discount_percentage: filteredBrandDiscount }
                        }
                        return data
                    })?.filter(itm => itm?.discount_percentage)
                    // queries to apply coupon on services
                    const filteredServices = OrderData?.ids?.filter((srv) => srv?.selection_type === "SERVICE")
                    const servicesWithUpdatedPrices = filteredServices?.map((srv) => {
                        let data = { ...srv }
                        if (srv?.avaliableservicegroup && srv?.avaliableservicegroup?.length > 0) {
                            const isServiceExcluded = couponDetail?.excluded_services?.includes(srv?.id);
                            const filteredServiceGroupDiscount = couponDetail?.servicegroup_discount?.find((itm) =>
                                srv?.avaliableservicegroup[0]?.id == itm?.service_group[0]?.service_group && !isServiceExcluded)?.service_group[0]?.service_group_discount
                            let updatedDiscountPrice = srv?.discount_price ? srv?.discount_price : srv?.price
                            if (filteredServiceGroupDiscount) {
                                updatedDiscountPrice = (+updatedDiscountPrice * +filteredServiceGroupDiscount / 100)
                                data = { ...data, discount_price: updatedDiscountPrice, discount_percentage: filteredServiceGroupDiscount }
                            }
                        }
                        return data
                    })?.filter(itm => itm?.discount_percentage)
                    finaldata = [...servicesWithUpdatedPrices, ...productsWithUpdatedPrices]
                    finaldata = finaldata?.filter(itm => itm?.discount_price !== undefined && itm?.discount_price)
                    dataToUpdate = { couponsIds: finaldata }
                } else if (couponDetail?.coupon_type_value == 2) {
                    let addToCartAllow = false
                    let filteredItm = {}
                    // apply coupon according to type 2
                    if (couponDetail?.buy_one_type == 'Service') {
                        // queries to apply coupon on services
                        const filteredServices = OrderData?.ids?.filter((srv) => srv?.selection_type === "SERVICE")
                        const servicesWithUpdatedPrices = filteredServices?.map((srv) => {
                            let data = { ...srv }
                            const isServiceIncluded = couponDetail?.buy_one_get_one_service?.includes(srv?.id);
                            if (isServiceIncluded) {
                                addToCartAllow = true
                                filteredItm = { ...srv, is_coupon_redeemed: true, selection_type: 'SERVICE' }
                                let updatedDiscountPrice = srv?.discount_price ? srv?.discount_price : srv?.price
                                data = { ...data, discount_price: updatedDiscountPrice, discount_percentage: 100 }
                            }
                            return data
                        })?.filter(itm => itm?.discount_percentage)
                        finaldata = [...servicesWithUpdatedPrices]
                    } else if (couponDetail?.buy_one_type == 'Product') {
                        // queries to apply coupon on products
                        const filteredProducts = OrderData?.ids?.filter((srv) => srv?.selection_type === "PRODUCT")
                        const productsWithUpdatedPrices = filteredProducts?.map((prod) => {
                            let data = { ...prod }
                            const isProductIncluded = couponDetail?.buy_one_get_one_product?.includes(prod?.id);
                            if (isProductIncluded) {
                                addToCartAllow = true
                                let updatedDiscountPrice = prod?.discount_price ? prod?.discount_price : prod?.price
                                filteredItm = { ...prod, is_coupon_redeemed: true, selection_type: 'PRODUCT' }
                                data = { ...data, discount_price: updatedDiscountPrice, discount_percentage: 100 }
                            }
                            return data
                        })?.filter(itm => itm?.discount_percentage)
                        finaldata = [...productsWithUpdatedPrices]
                    }
                    finaldata = finaldata?.filter(itm => itm?.discount_price !== undefined && itm?.discount_price)
                    dataToUpdate = { couponsIds: finaldata }
                    if (addToCartAllow) {
                        setNewAddedItmFromCoupon(filteredItm)
                    }
                } else {
                    dataToUpdate = {
                        couponSpendAmount: couponDetail?.amount_spent, couponDiscountPercentage: couponDetail?.discounted_percentage
                    }
                }
            }
            // setting final data of coupon integration in OrderData Object
            let finalDataAfterCouponApply = {}
            if (dataToUpdate != {}) {
                finalDataAfterCouponApply = { ...dataToUpdate }
            } else {
                finalDataAfterCouponApply = {}
            }
            // deleteing redeemed voucher,membership and loyality points
            const updatedOrderData = { ...OrderData };
            delete updatedOrderData.is_redeemed;
            delete updatedOrderData.redeemed_id;
            delete updatedOrderData.redeemed_points;
            delete updatedOrderData.is_membership_redeemed;
            delete updatedOrderData.redeemed_membership_id;
            delete updatedOrderData.membership_product;
            delete updatedOrderData.membership_service;
            delete updatedOrderData.membership_type;
            delete updatedOrderData.is_voucher_redeemed;
            delete updatedOrderData.redeemed_voucher_id;
            delete updatedOrderData.voucher_redeem;
            delete updatedOrderData.voucher_type;
            delete updatedOrderData.is_gift_card_redeemed
            setOrderData({
                ...updatedOrderData,
                ...finalDataAfterCouponApply,
                other_method: 'Coupon',
                redeemed_coupon_id: couponDetail?.id,
                coupon_name: couponDetail?.name,
                coupon_type: couponDetail?.coupon_type,
                coupon_type_value: couponDetail?.coupon_type_value,
                is_coupon_redeemed: true
            })
        }
    }, [couponDetail])

    // calculate total price and commission of service, product, voucher
    useEffect(() => {
        // calculate total price of service, product, voucher
        let service_total = 0;
        let product_total = 0;
        let voucher_total = 0;
        let membership_total = 0;


        //calculate PRODUCT totals
        OrderData?.ids?.filter((itm) => itm?.selection_type === "PRODUCT")?.forEach((dt) => {
            product_total += ((dt?.price) * (dt?.quantity))
            return product_total;
        })


        //calculate SERVICE totals
        OrderData?.ids?.filter((itm) => itm?.selection_type === "SERVICE")?.forEach((dt) => {
            service_total += ((dt?.discount_price ? dt?.discount_price : dt?.price) * (dt?.quantity))
            return service_total;
        })


        //calculate VOUCHER totals
        OrderData?.ids?.filter((itm) => itm?.selection_type === "VOUCHER")?.forEach((dt) => {
            service_total += ((dt?.price) * (dt?.quantity))
            return service_total;
        })

        //calculate Membership totals
        OrderData?.ids?.filter((itm) => itm?.selection_type === "MEMBERSHIP")?.forEach((dt) => {
            membership_total += ((dt?.currency_membership?.find(itm => itm?.currency === active_currency_id)?.price) * (dt?.quantity))
            return membership_total;
        })


        // calculate commission for service, product, voucher
        let service_cmsn = 0;
        let product_cmsn = 0;
        let voucher_cmsn = 0;

        //set commission type like % or currency symble
        let service_commission_type = null;
        let product_commission_type = null;
        let voucher_commission_type = null;



        // calculate commission for product
        cmsn.category_comission?.filter((dt => dt?.category_comission === "Retail"))?.forEach((itm) => {
            if (itm?.category_comission === "Retail") {
                let to = (itm?.to_value * 1);

                if (((itm?.from_value <= product_total) && ((!isNaN(to) && to >= product_total))) || ((itm?.from_value <= product_total) && ((isNaN(to))))) {
                    product_cmsn = itm?.symbol == "%" ? Math.round((product_total * itm?.commission_percentage) / 100) : itm?.commission_percentage
                    product_commission_type = `${itm?.commission_percentage} ${itm?.symbol} `;
                }
            }
        })


        // calculate commission for Service
        cmsn.category_comission?.filter((dt => dt?.category_comission === "Service"))?.forEach((itm) => {
            if (itm?.category_comission === "Service") {
                let to = (itm?.to_value * 1);
                if (((itm?.from_value <= service_total) && ((!isNaN(to) && to >= service_total))) || ((itm?.from_value <= service_total) && ((isNaN(to))))) {
                    service_cmsn = itm?.symbol == "%" ? Math.round((service_total * itm?.commission_percentage) / 100) : itm?.commission_percentage
                    service_commission_type = `${itm?.commission_percentage} ${itm?.symbol} `;
                }
            }
        })


        // calculate commission for Voucher
        cmsn.category_comission?.filter((dt => dt?.category_comission === "Voucher"))?.forEach((itm) => {
            if (itm?.category_comission === "Voucher") {
                let to = (itm?.to_value * 1);
                if (((itm?.from_value <= voucher_total) && ((!isNaN(to) && to >= voucher_total))) || ((itm?.from_value <= voucher_total) && ((isNaN(to))))) {
                    voucher_cmsn = itm?.symbol == "%" ? Math.round((voucher_total * itm?.commission_percentage) / 100) : itm?.commission_percentage
                    voucher_commission_type = `${itm?.commission_percentage} ${itm?.symbol} `
                }
            }
        })


        setCommission((prev) => ({
            ...prev,
            service_commission: service_cmsn,
            product_commission: product_cmsn,
            voucher_commission: voucher_cmsn,
            service_total_price: service_total,
            product_total_price: product_total,
            voucher_total_price: voucher_total,
            service_commission_type,
            product_commission_type,
            voucher_commission_type

        }))


    }, [OrderData?.member, cmsn])

    // add new item in cart when coupon is redeemed
    useEffect(() => {
        if (Object.keys(newAddedItmFromCoupon).length !== 0) {
            // Create a new object without "discount_percentage" and "redeemed_price"
            const updatedObject = { ...newAddedItmFromCoupon };
            delete updatedObject.discount_percentage;
            delete updatedObject.redeemed_price;
            dispatch({
                type: "ADD_TO_CART_FROM_COUPON",
                payload: {
                    ...updatedObject,
                    is_coupon_redeemed: true,
                    newAddedCouponItm: true,
                    selection_type: newAddedItmFromCoupon?.selection_type
                },
            });
        }
    }, [newAddedItmFromCoupon])

    // handle tip delete function
    const handle_tip_delete = (data) => {

        let is_tip = tip_for_emply.filter(itm => itm.employee !== data.emp_id)
        let is_employee = employee_tips.filter(itm => itm.emp_id !== data.emp_id)


        const sum = is_tip.reduce((accumulator, object) => {
            return accumulator + object.tip;
        }, 0);

        setTip_sum(sum)
        setEmployeeTipSum(sum)

        setTip_for_emply([...is_tip])
        setEmployee_tips([...is_employee])
        setEmployeeTips([...is_employee])

        // Remove the employee's tip from currentEmployeeTip
        const employeeId = data.emp_id;
        const updatedEmployeeTip = { ...currentEmployeeTip };
        delete updatedEmployeeTip[employeeId];
        setCurrentEmployeeTip(updatedEmployeeTip);
    }

    // handle tip change
    const handleTipChange = (emp_id, emp_name, newTip) => {
        let prev_tips = [...tip_for_emply]
        let prev_employees = [...employee_tips]
        let is_tip = prev_tips?.find(itm => itm?.employee === emp_id)
        let is_employee = prev_employees?.find(itm => itm?.emp_id === emp_id)

        // if (newTip != 0) {
        if (is_employee) {
            // is_employee.tip = newTip
            prev_employees = prev_employees?.map(itm => {
                if (itm?.emp_id === emp_id) {
                    return { ...itm, tip: newTip };
                } else {
                    return itm
                }
            });
        } else {
            prev_employees?.push({ emp_id: emp_id, employee: emp_name, tip: newTip })
        }

        if (is_tip) {
            is_tip.tip = newTip
        } else {
            prev_tips?.push({ employee: emp_id, tip: newTip })
        }
        // }

        const updatedPreviousTips = prev_tips?.filter(itm => itm?.tip != 0)
        const updatedPreviousEmployees = prev_employees?.filter(itm => itm?.tip != 0)

        setTip_for_emply(updatedPreviousTips)
        setEmployee_tips(updatedPreviousEmployees)

        const sum = updatedPreviousTips.reduce((accumulator, object) => {
            return accumulator + object?.tip;
        }, 0);

        setTip_sum(sum)
    }

    // delete coupon 
    const onDeleteCoupon = () => {
        const updatedOrderData = { ...OrderData };

        const filteredItemsforOrderIds = updatedOrderData?.ids?.filter((item) => !item.newAddedCouponItm)?.map(({ discount_percentage, name, id, discount_price, gift_card_details, currency_membership, price, quantity, selection_type, employee, employee_id, brand, avaliableservicegroup }) => {
            setSelectedPaymentMethods([])
            let this_price = currency_membership?.find(itm => itm?.currency === locationDetail?.currency?.id)?.price
            let gc_price = gift_card_details?.find(itm => itm?.currencies === locationDetail?.currency?.id)?.price

            let data = {
                selection_type, name, id, discount_price, quantity, employee, employee_id, discount_percentage,
                price: price !== undefined ? price : this_price ? this_price : gc_price
            }

            if (brand !== undefined) {
                data = { ...data, brand }
            }
            if (avaliableservicegroup?.length > 0) {
                data = { ...data, avaliableservicegroup: avaliableservicegroup }
            }
            delete data.is_coupon_redeemed
            delete data.redeemed_price
            delete data.discount_percentage
            delete data.coupon_discounted_price
            delete data?.refund_coupon
            delete data?.cashback_coupon
            return data
        })

        dispatch({
            type: 'REMOVE_FROM_CART_FROM_COUPON',
            payload: filteredItemsforOrderIds
        })

        delete updatedOrderData.other_method;
        delete updatedOrderData.coupon_name;
        delete updatedOrderData.coupon_type;
        delete updatedOrderData.coupon_type_value;
        delete updatedOrderData.couponsIds;
        delete updatedOrderData.couponDiscountPercentage;
        delete updatedOrderData.couponSpendAmount;
        delete updatedOrderData.is_coupon_redeemed;
        delete updatedOrderData?.redeemed_coupon_id
        delete updatedOrderData?.refund_coupon
        delete updatedOrderData?.cashback_coupon
        setSelectedPaymentMethods([])
        setOrderData({ ...updatedOrderData, ids: filteredItemsforOrderIds });
    }
    // delete voucher membership
    const onDeleteVoucherMembership = () => {
        setSelectedPaymentMethods([])
        if (OrderData?.is_voucher_redeemed && OrderData?.is_voucher_redeemed === true) {

            const updatedOrderData = { ...OrderData };
            delete updatedOrderData.is_voucher_redeemed;
            delete updatedOrderData.redeemed_voucher_id;
            delete updatedOrderData.voucher_redeem;
            delete updatedOrderData.voucher_type

            const filteredItemsforOrderIds = updatedOrderData?.ids?.map((itm) => {
                if (itm?.is_voucher_redeemed) {
                    delete itm.is_voucher_redeemed
                    delete itm.redeemed_price
                    delete itm.discount_percentage
                }
                return itm
            })

            setOrderData({ ...updatedOrderData, ids: filteredItemsforOrderIds, checkout: false });
        }
        else if (OrderData?.is_membership_redeemed && OrderData?.is_membership_redeemed === true) {
            const updatedOrderData = { ...OrderData };
            delete updatedOrderData.is_membership_redeemed;
            delete updatedOrderData.redeemed_membership_id;
            delete updatedOrderData.membership_product;
            delete updatedOrderData.membership_service;
            delete updatedOrderData.membership_type
            const filteredItemsforOrderIds = updatedOrderData?.ids?.map((itm) => {
                if (itm?.is_membership_redeemed) {
                    delete itm.is_membership_redeemed
                    delete itm.redeemed_price
                    delete itm.discount_percentage
                }
                return itm
            })

            setOrderData({ ...updatedOrderData, ids: filteredItemsforOrderIds, checkout: false });
        }
    }
    // delete loyality points
    const onDeleteLoyalityPoints = () => {
        setSelectedPaymentMethods([])
        const updatedOrderData = { ...OrderData };
        delete updatedOrderData.is_redeemed;
        delete updatedOrderData.redeemed_id;
        delete updatedOrderData.redeemed_points;
        setOrderData({ ...updatedOrderData, checkout: false });
        setIs_redeemed(false)
        setRedeemed_points('')
        setLoyaltyDiscountAmount('')
        setRedeemed_id("")
    }
    // delete gift card
    const onDeleteGiftCard = () => {
        if (OrderData?.is_gift_card_redeemed) {
            const updatedOrderData = { ...OrderData }
            delete updatedOrderData?.is_gift_card_redeemed
            setSelectedPaymentMethods([])
            setOrderData({
                ...updatedOrderData,
                appliedGiftCard: {
                    purchased_gift_card_id: '',
                    partial_price: 0
                },
                checkout: false
            });
        }
    }

    // on back click
    const onBackClick = () => {
        setSelectedPaymentMethods([])
        if (OrderData?.is_voucher_redeemed && OrderData?.is_voucher_redeemed === true) {
            onDeleteVoucherMembership()
        }
        if (OrderData?.is_membership_redeemed && OrderData?.is_membership_redeemed === true) {
            onDeleteVoucherMembership()
        }
        if (OrderData.is_redeemed) {
            onDeleteLoyalityPoints()
        }
        if (OrderData?.is_gift_card_redeemed) {
            onDeleteGiftCard()
        }
        if (OrderData?.is_coupon_redeemed) {
            onDeleteCoupon()
        }
        if (!OrderData?.is_voucher_redeemed && !OrderData?.is_membership_redeemed && !OrderData.is_redeemed && !OrderData?.is_gift_card_redeemed && !OrderData?.is_coupon_redeemed) {
            setOrderData({
                ...OrderData,
                checkout: false
            })
        }
    }

    // checkout submit function
    const SubmitHandler = (data) => {
        const orderCheckoutDataIds = orderCheckoutData?.ids || [];
        // console.log(orderCheckoutDataIds, OrderData, "orderCheckoutDataIds")
        const processItems = (type) => {
            let code = generateUniqueCode(3, 3)
            if (type == 'PRODUCT' || type == 'SERVICE') {
                return orderCheckoutDataIds
                    .filter(itm => itm?.selection_type === type)
                    .map(itm => {
                        let couponPrice = (orderCheckoutData?.is_coupon_redeemed && itm?.couponRedeemed) ?
                            (itm?.redeemed_price === 0 ? 0 :
                                itm?.redeemed_price ? itm?.redeemed_price / +itm?.quantity :
                                    (itm?.discount_price && itm?.discount_price === 0) ? 0 :
                                        itm?.discount_price ? itm?.discount_price :
                                            itm?.price ? itm?.price : 0) :
                            (itm?.discount_price && itm?.discount_price === 0) ? 0 :
                                itm?.discount_price ? itm?.discount_price :
                                    itm?.price ? itm?.price : 0;

                        let simplePrice = itm?.redeemed_price === 0 ? 0 :
                            itm?.redeemed_price ? itm?.redeemed_price / +itm?.quantity :
                                (itm?.discount_price && itm?.discount_price === 0) ? 0 :
                                    itm?.discount_price ? itm?.discount_price :
                                        itm?.price ? itm?.price : 0;

                        return {
                            [type.toLowerCase()]: itm?.id,
                            employee: itm?.employee,
                            quantity: itm?.quantity,
                            discount_percentage: (orderCheckoutData?.is_membership_redeemed || orderCheckoutData?.is_voucher_redeemed || (orderCheckoutData?.is_coupon_redeemed && itm?.couponRedeemed)) ? itm?.discount_percentage : null,
                            price: orderCheckoutData?.is_coupon_redeemed ? couponPrice : simplePrice
                        };
                    });
            } else if (type == 'MEMBERSHIP' || type == 'VOUCHER') {
                return orderCheckoutDataIds?.filter(itm => itm?.selection_type === type)?.map(itm => {
                    let data = {
                        [type.toLowerCase()]: itm?.id,
                        discounted_price: itm?.discount_price,
                        employee: itm?.employee,
                        quantity: itm?.quantity,
                        price: itm?.price,
                        valid_till: itm?.valid_till
                    }
                    if (type == 'MEMBERSHIP') {
                        data = {
                            ...data,
                            installment_months: itm?.installment_months ? itm?.installment_months : null,
                            installment_price: itm?.installment_price ? itm?.installment_price : null
                        }
                    }
                    return data
                });
            } else if (type == 'GIFT_CARD') {
                return orderCheckoutDataIds?.filter(itm => itm?.selection_type === type)?.map(itm => (
                    {
                        [type.toLowerCase()]: itm?.id,
                        discounted_price: itm?.discount_price,
                        coupon_discounted_price: itm?.coupon_discounted_price,
                        employee: itm?.employee,
                        quantity: itm?.quantity,
                        price: itm?.price,
                        valid_till: itm?.valid_till,
                        spend_amount: itm?.spend_amount,
                        sale_code: itm?.custom_code ? itm?.custom_code : code,
                    }));
            } else if (type == 'INSTALLMENT_MEMBERSHIP') {
                return orderCheckoutDataIds?.filter(itm => itm?.selection_type === type)?.map(itm => (
                    {
                        membership: itm?.membership ? itm?.membership : itm?.id,
                        employee: itm?.employee,
                        quantity: itm?.quantity,
                        paid_installment: itm?.installment_price ? itm?.installment_price : itm?.price,
                    }));
            }
        };

        const product = processItems('PRODUCT');
        const service = processItems('SERVICE');
        const membership = processItems('MEMBERSHIP');
        const voucher = processItems('VOUCHER');
        const gift_cards_records = processItems('GIFT_CARD');
        const installment_memberships = processItems('INSTALLMENT_MEMBERSHIP');


        const tips_sum = orderCheckoutData?.tips?.reduce((accumulator, object) => {
            return accumulator + object.tip;
        }, 0);

        let tax = data.taxes_applied_group?.length > 0
            ? data.taxes_applied_group[0]?.value
                ? +(data.taxes_applied_group[0]?.value)?.toFixed(2)
                : 0
            : data?.taxes_applied_single_value
                ? (+data?.taxes_applied_single_value)?.toFixed(2)
                : 0;
        let tax1 = data.taxes_applied_group?.length > 0 ? +(data.taxes_applied_group[1]?.value)?.toFixed(2) : 0;
        // Calculate Total price
        let sub_total = (+tax + +tax1 + orderCheckoutData?.total_price) - (orderCheckoutData?.is_gift_card_redeemed ? orderCheckoutData?.appliedGiftCard?.partial_price : 0)

        let location_taxes = []
        if (state?.financialSettings?.businessTaxs.length > 0) {
            location_taxes = state?.financialSettings?.businessTaxs.filter(itm => itm.tax_type == 'Location' && itm?.location?.id == selected_location)
        }
        const applied_tax =
            location_taxes?.length > 0
                ? data?.tax_type == "Group"
                    ? data?.taxes_applied_group
                    : data?.single_tax_applied
                : []


        let couponObj = orderCheckoutData?.is_coupon_redeemed
            ? [{
                coupon: (orderCheckoutData?.refund_coupon?.refund_coupon || orderCheckoutData?.cashback_coupon?.cashback_coupon) ? null :
                    couponDetail?.id,
                coupon_name: orderCheckoutData?.refund_coupon?.refund_coupon ? "Refund Coupon" :
                    orderCheckoutData?.cashback_coupon?.cashback_coupon ? "Cashback Coupon" :
                        couponDetail?.name,
                refund_coupon: orderCheckoutData?.refund_coupon?.refund_coupon ? orderCheckoutData?.refund_coupon?.refund_coupon : null,
                cash_back_coupons: orderCheckoutData?.cashback_coupon?.cashback_coupon ? orderCheckoutData?.cashback_coupon?.cashback_coupon : null,
                coupon_type: couponDetail?.coupon_type,
                coupon_discounted_price: (+couponDiscountValue)?.toFixed(2),
                is_redeemed: orderCheckoutData?.is_coupon_redeemed,
                client: orderCheckoutData?.client ? orderCheckoutData?.client : null,
            }] : []
        // Loop through each object and delete 'coupon' key if its value is null
        couponObj?.forEach(obj => {
            if (obj?.coupon === null) {
                delete obj.coupon;
            }
            if (obj?.refund_coupon === null) {
                delete obj.refund_coupon;
            }
            if (obj?.cash_back_coupons === null) {
                delete obj.cash_back_coupons;
            }
        });

        const applied_promotions_records = orderCheckoutData?.selected_promotion_id
            ? [{
                promotion: orderCheckoutData?.selected_promotion_id,
                promotion_type: orderCheckoutData?.selected_promotion_type
            }]
            : []

        const applied_membership = orderCheckoutData?.is_membership_redeemed
            ? [{
                membership: orderCheckoutData?.redeemed_membership_id,
                price: orderCheckoutData?.membership_redeemed_price,
                is_redeemed: orderCheckoutData?.is_membership_redeemed,
                discount_percentage: null,
                client: orderCheckoutData?.client ? orderCheckoutData?.client : null,
            }]
            : []
        const applied_voucher = orderCheckoutData?.is_voucher_redeemed
            ? [{
                voucher: orderCheckoutData?.redeemed_voucher_id,
                price: orderCheckoutData?.voucher_redeemed_price,
                is_redeemed: orderCheckoutData?.is_voucher_redeemed,
                discount_percentage: orderCheckoutData?.voucher_redeem_percentage,
                client: orderCheckoutData?.client ? orderCheckoutData?.client : null,
            }]
            : []

        const loyality_redemption = orderCheckoutData?.loyality_redemption?.is_redeemed
            ? [{ ...orderCheckoutData?.loyality_redemption, client: orderCheckoutData?.client ? orderCheckoutData?.client : null }]
            : []

        let payload = {
            // ...orderCheckoutData,
            sub_total: (+orderCheckoutData?.total_price - +tips_sum)?.toFixed(2),
            checkout_type: "Sale",
            client_type: orderCheckoutData?.client_type,
            total_tip: +tips_sum?.toFixed(2),
            total_price: +sub_total?.toFixed(2),
            total_tax: +(+tax + +tax1)?.toFixed(2),
            client: orderCheckoutData?.client ? orderCheckoutData?.client : '',
            location: orderCheckoutData?.location,
            status: 'paid',

            installment_memberships: installment_memberships,
            products_records: product,
            services_records: service,
            membership_records: membership,
            vouchers_records: voucher,
            gift_cards_records: gift_cards_records,
            payment_methods_records: selectedPaymentMethods,
            change: Math.abs(+(+total_price + +taxApplied?.toFixed(2) - (+totalAmountPaid).toFixed(2)).toFixed(2)),

            tip_records: orderCheckoutData?.tips,
            appointment_services: [],
            tax_records: applied_tax,

            discount_value: discountValue,
            applied_coupons_records: couponObj,
            applied_memberships_records: applied_membership,
            applied_vouchers_records: applied_voucher,
            applied_gift_cards_records: orderCheckoutData?.is_gift_card_redeemed ? [orderCheckoutData?.appliedGiftCard] : [],
            applied_promotions_records: applied_promotions_records,
            applied_loyalty_points_records: loyality_redemption
        }

        const success = (data) => {
            storeDataInLocalStorage("checkoutId", data?.id)
            setLoading(false)
            dispatch(getSaleOrderProduct())
            dispatch(getSaleOrderServices())
            dispatch({
                type: EMPLOYEES.EMPLOYEE_COMMISSIONS,
                payload: []
            })
            dispatch({
                type: CLIENT.SET_SPECIFIC_CLIENTS_FOR_CHECKOUT,
                payload: {}
            });
            dispatch({
                type: "RESET_CART",
                payload: []
            })
            dispatch({
                type: "REMOVE_INSTALLMENT_CLIENT",
                payload: {}
            })
            localStorage.removeItem('cart_items')
            navigate('/dashboard/sales/sale-history')
        }
        const fail = (error) => {
            setLoading(false)
            toast.error(error)
        }
        // console.log(payload, cartItems, "payload")

        setLoading(true)
        dispatch(CreateNewCheckout(payload, success, fail))
        // Old Checkout
        // dispatch(CreateSaleOrder(payload, success, fail))
    }

    return (
        <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
            <div className='flex flex-col gap-4'>
                <div className='flex items-center gap-3'>
                    <div onClick={() => {
                        if (cartItems?.find(itm => itm?.selection_type == 'INSTALLMENT_MEMBERSHIP')) {
                            storeDataInLocalStorage('resetCart', true)
                            navigate(-2)
                        } else if (!OrderData?.checkout) {
                            navigate(-1)
                        } else {
                            onBackClick()
                        }
                    }}>
                        <Svgs.Back />
                    </div>
                    <h2 className='text-primary font-bold lg:text-3xl text-xl'>
                        {selectedLanguage?.name == "English" ? "Checkout" : translation[34]?.value ? translation[34]?.value : "Checkout"}
                    </h2>
                </div>
                <div className='grid lg:grid-cols-5 mt-[3rem] gap-[3rem]'>
                    <div className='flex flex-col gap-8 lg:col-span-3'>

                        {
                            !OrderData?.checkout
                                ? <CheckoutClientDetails
                                    onUpdate={onUpdateHandler}
                                    data={OrderData}
                                    setOrderData={setOrderData}
                                    selectedLanguage={selectedLanguage}
                                    setSelectedPaymentMethods={setSelectedPaymentMethods}
                                    translation={translation}
                                    getMemberCommission={getMemberCommission}
                                    Employees={Employees}
                                    setPaymentError={setPaymentError}
                                    setGiftCardDetail={setGiftCardDetail}
                                    setEmployees={setEmployees}
                                />
                                : <>
                                    <CheckoutTipDetails
                                        totalTips={totalTips}
                                        onDeleteCoupon={onDeleteCoupon}
                                        setCouponDetail={setCouponDetail}
                                        setGiftCardDetail={setGiftCardDetail}
                                        giftCardDetail={giftCardDetail}
                                        appliedGiftCard={OrderData?.appliedGiftCard}
                                        data={OrderData}
                                        setOrderData={setOrderData}
                                        currency={location_currency}
                                        onUpdate={onUpdateHandler}
                                        t_price={t_price}
                                        Employees={Employees}
                                        paymentError={paymentError}
                                        setPaymentError={setPaymentError}
                                        setPayBtnDisabled={setPayBtnDisabled}
                                        totalAmountPaid={totalAmountPaid}
                                        setTotalAmountPaid={setTotalAmountPaid}
                                        setData={setOrderData}
                                        setTip_sum={setTip_sum}
                                        tip_sum={tip_sum}
                                        setEmployeeTipSum={setEmployeeTipSum}
                                        // tip_invalid={tip_invalid}
                                        // setTip_invalid={setTip_invalid}
                                        tip_for_emply={tip_for_emply}
                                        setTip_for_emply={setTip_for_emply}
                                        setEmployee_tips={setEmployee_tips}
                                        employee_tips={employee_tips}
                                        setEmployeeTips={setEmployeeTips}
                                        employeeTips={employeeTips}
                                        currentEmployeeTip={currentEmployeeTip}
                                        setCurrentEmployeeTip={setCurrentEmployeeTip}
                                        setIs_redeemed={setIs_redeemed}
                                        redeemed_id={redeemed_id}
                                        setRedeemed_id={setRedeemed_id}
                                        redeemed_points={redeemed_points}
                                        setRedeemed_points={setRedeemed_points}
                                        setLoyaltyDiscountAmount={setLoyaltyDiscountAmount}
                                        handleTipChange={handleTipChange}
                                        totalPriceWithoutTax={totalPriceWithoutTax}
                                        // handleTipChange={(emp_id, emp_name, newTip) => handleTipChange(emp_id, emp_name, newTip)}
                                        calculatedTax={calculatedTax}
                                        taxApplied={taxApplied}
                                        setLocationTax={setLocationTax}
                                        selectedPaymentMethods={selectedPaymentMethods}
                                        setSelectedPaymentMethods={setSelectedPaymentMethods}
                                        giftCards={giftCardDetail}


                                        selectedLanguage={selectedLanguage}
                                        translation={translation}
                                    />
                                    {/* <LoginBtn
                                        text='Complete'
                                        onClick={() => {
                                            SubmitHandler()
                                        }}
                                    /> */}
                                </>
                        }
                    </div>
                    <div className='lg:col-span-2 flex flex-col gap-6'>
                        <SaleBookingDetailCard
                            subTotal={subTotal}
                            total_amount={setTotalPrice}
                            checkout_price={setT_price}
                            type={state.saleService?.selection?.selection_type}
                            data={BookingData()}
                            orderData={OrderData}
                            setTotalAmountPaid={setTotalAmountPaid}
                            is_gift_card_redeemed={OrderData?.is_gift_card_redeemed}
                            appliedGiftCard={OrderData?.appliedGiftCard}
                            totalAmountPaid={totalAmountPaid}
                            setOrderData={setOrderData}
                            previous_price={state.cartItem.cart_total}
                            cart_Item={state.cartItem}
                            voucher_redeem={OrderData?.voucher_redeem}
                            is_voucher_redeemed={OrderData?.is_voucher_redeemed}
                            voucher_type={OrderData?.voucher_type}
                            membership_product={OrderData?.membership_product}
                            is_membership_redeemed={OrderData?.is_membership_redeemed}
                            redeemed_membership_id={OrderData?.redeemed_membership_id}
                            redeemed_voucher_id={OrderData?.redeemed_voucher_id}
                            membership_service={OrderData?.membership_service}
                            membership_type={OrderData?.membership_type}
                            employee_tips={employeeTips}
                            is_redeemed={is_redeemed}
                            loyaltyDiscountAmount={loyaltyDiscountAmount}
                            setIs_redeemed={setIs_redeemed}
                            setRedeemed_points={setRedeemed_points}
                            setLoyaltyDiscountAmount={setLoyaltyDiscountAmount}
                            redeemed_id={redeemed_id}
                            setRedeemed_id={setRedeemed_id}
                            redeemed_points={redeemed_points}
                            // tip_invalid={tip_invalid}
                            tip_sum={employeeTipSum}
                            location={OrderData?.location}
                            payNowBtnDisabled={OrderData?.checkout ? false : true}
                            payBtnDisabled={payBtnDisabled}
                            payNowBtnLoading={btn_loading}
                            // tax={total_tax}
                            tax={tax}
                            taxApplied={taxApplied}
                            setLocationTax={setLocationTax}
                            setTax={setTax}
                            onPayClick={(data) => {
                                SubmitHandler(data)
                            }}
                            onTipDelete={(data) => {
                                handle_tip_delete(data)
                            }}
                            paymentError={paymentError}
                            setCalculatedTax={setCalculatedTax}
                            selected_location={selected_location}
                            singleTaxName={singleTaxName}
                            setDiscountValue={setDiscountValue}
                            discountValue={discountValue}
                            couponDiscountValue={couponDiscountValue}
                            setCouponDiscountValue={setCouponDiscountValue}
                            selectedPaymentMethods={selectedPaymentMethods}
                            setSelectedPaymentMethods={setSelectedPaymentMethods}
                            couponsIds={OrderData?.couponsIds}
                            is_coupon_redeemed={OrderData?.is_coupon_redeemed}
                            setTotalPriceWithoutTax={setTotalPriceWithoutTax}
                        />
                        <SupportCard />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Checkout