import { useEffect, useState } from "react";

const useDealTypeFourSetting = ({ setErrors, setSettingData, settingData, allProducts, allServices, allProductsBrand, allServicesCategory, allServicesGroup, allMergedOptions }) => {
    const [screenLoader, setScreenLoader] = useState(true)
    const [popup, setPopup] = useState(false)
    const [serviceType, setServiceType] = useState('')
    const [searchApplicable, setSearchApplicable] = useState('')
    const [searchRequired, setSearchRequired] = useState('')
    const [selectedApplicableItemsArray, setSelectedApplicableItemsArray] = useState([])
    const [selectedRequiredItemsArray, setSelectedRequiredItemsArray] = useState([])


    useEffect(() => {
        setTimeout(() => {
            setScreenLoader && setScreenLoader(false)
        }, 1000);
    }, [])

    useEffect(() => {
        const applicableServicesSet = new Set(settingData?.applicableItemsSettings?.itemIds?.map(service => service));
        const filtered = allMergedOptions?.filter((itm) => applicableServicesSet.has(itm?.id));
        setSelectedApplicableItemsArray(filtered)
    }, [allMergedOptions, settingData?.applicableItemsSettings?.itemIds])

    useEffect(() => {
        const applicableProductsSet = new Set(settingData?.requiredItemsSettings?.itemIds?.map(product => product));
        const filtered = allMergedOptions?.filter((itm) => applicableProductsSet.has(itm?.id));
        setSelectedRequiredItemsArray(filtered)
    }, [allMergedOptions, settingData?.requiredItemsSettings?.itemIds])

    // handle change for deal type 3 
    const handleChangeForDealType3 = (e, type, name) => {
        const { value } = e.target
        if (type == 'requiredItemsSettings') {
            setSettingData({
                ...settingData,
                requiredItemsSettings: {
                    ...settingData?.requiredItemsSettings,
                    [name]: value,
                }
            })
        }
        if (type == 'applicableItemsSettings') {
            setSettingData({
                ...settingData,
                applicableItemsSettings: {
                    ...settingData?.applicableItemsSettings,
                    [name]: value,
                }
            })
        }
    }

    // filtered for deal type 3options
    const filteredRSForDealType3 = allServices?.filter((itm) =>
        !settingData?.requiredItemsSettings?.itemIds?.includes(itm.id)
    );
    const filteredRSGForDealType3 = allServicesGroup?.filter((itm) =>
        !settingData?.requiredItemsSettings?.itemIds?.includes(itm.id)
    );
    const filteredRSCForDealType3 = allServicesCategory?.filter((itm) =>
        !settingData?.requiredItemsSettings?.itemIds?.includes(itm.id)
    );
    const filteredRPForDealType3 = allProducts?.filter((itm) =>
        !settingData?.requiredItemsSettings?.itemIds?.includes(itm.id)
    );
    const filteredRPBForDealType3 = allProductsBrand?.filter((itm) =>
        !settingData?.requiredItemsSettings?.itemIds?.includes(itm.id)
    );
    const filteredASForDealType3 = allServices?.filter((itm) =>
        !settingData?.applicableItemsSettings?.itemIds?.includes(itm.id)
    );
    const filteredASGForDealType3 = allServicesGroup?.filter((itm) =>
        !settingData?.applicableItemsSettings?.itemIds?.includes(itm.id)
    );
    const filteredASCForDealType3 = allServicesCategory?.filter((itm) =>
        !settingData?.applicableItemsSettings?.itemIds?.includes(itm.id)
    );
    const filteredAPForDealType3 = allProducts?.filter((itm) =>
        !settingData?.applicableItemsSettings?.itemIds?.includes(itm.id)
    );
    const filteredAPBForDealType3 = allProductsBrand?.filter((itm) =>
        !settingData?.applicableItemsSettings?.itemIds?.includes(itm.id)
    );
    // filtered for deal type 3options

    // handle change for deal type 3 add data
    const handleChangeForDealType3AddData = (e, data, type, name) => {
        // for deal type 3 required discount dropdown
        if (type == 'requiredItemsSettings') {
            setSettingData({
                ...settingData,
                requiredItemsSettings: {
                    ...settingData?.requiredItemsSettings,
                    [name]: [...data]
                }
            })
        }

        // for deal type 3 applicable discount dropdown
        if (type == 'applicableItemsSettings') {
            setSettingData({
                ...settingData,
                applicableItemsSettings: {
                    ...settingData?.applicableItemsSettings,
                    [name]: [...data]
                }
            })
        }
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
        setPopup(false)
        setServiceType('')
    }

    // handle change for deal type 3 dropdown
    const handleChangeForDealType3Dropdown = (e, type, name) => {
        const { value } = e.target
        // for deal type 3 required discount dropdown
        if (type == 'requiredItemsSettings') {
            if (name == 'itemIds') {
                const existingItms = settingData?.requiredItemsSettings?.itemIds || []
                setSettingData({
                    ...settingData,
                    requiredItemsSettings: {
                        ...settingData?.requiredItemsSettings,
                        [name]: [...existingItms, value]
                    }
                })

            } else {
                setSettingData({
                    ...settingData,
                    requiredItemsSettings: {
                        ...settingData?.requiredItemsSettings,
                        [name]: value
                    }
                })
            }
        }

        // for deal type 3 applicable discount dropdown
        if (type == 'applicableItemsSettings') {
            if (name == 'itemIds') {
                const existingItms = settingData?.applicableItemsSettings?.itemIds || []
                setSettingData({
                    ...settingData,
                    applicableItemsSettings: {
                        ...settingData?.applicableItemsSettings,
                        [name]: [...existingItms, value]
                    }
                })
            } else {
                setSettingData({
                    ...settingData,
                    applicableItemsSettings: {
                        ...settingData?.applicableItemsSettings,
                        [name]: value
                    }
                })
            }
        }
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
    }

    // removed selected Item from array
    const removeSelectedItem = (value, type) => {
        // for deal type 3 
        if (type == 'requiredItemsSettings') {
            const updatedApplicableItems = settingData?.requiredItemsSettings?.itemIds?.filter((itm) => itm != value)
            setSettingData((prev) => ({
                ...prev,
                requiredItemsSettings: {
                    ...prev?.requiredItemsSettings,
                    itemIds: updatedApplicableItems
                }
            }))
        }
        // for deal type 3 
        if (type == 'applicableItemsSettings') {
            const updatedApplicableItems = settingData?.applicableItemsSettings?.itemIds?.filter((itm) => itm != value)
            setSettingData((prev) => ({
                ...prev,
                applicableItemsSettings: {
                    ...prev?.applicableItemsSettings,
                    itemIds: updatedApplicableItems
                }
            }))
        }
    }

    return {
        handleChangeForDealType3Dropdown, handleChangeForDealType3, filteredRPBForDealType3,
        filteredRSForDealType3, filteredRSGForDealType3, filteredRSCForDealType3,
        filteredRPForDealType3, removeSelectedItem, filteredASForDealType3,
        filteredASGForDealType3, filteredASCForDealType3, filteredAPBForDealType3,
        filteredAPForDealType3, screenLoader, popup, setPopup, serviceType,
        setServiceType, handleChangeForDealType3AddData, searchApplicable, setSearchApplicable, selectedApplicableItemsArray,
        setSelectedApplicableItemsArray, selectedRequiredItemsArray, setSelectedRequiredItemsArray, searchRequired, setSearchRequired
    }
};

export default useDealTypeFourSetting