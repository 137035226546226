import { usePagination, DOTS } from 'Hooks/usePagination';
import React from 'react'
import { useSelector } from 'react-redux';

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize
    } = props;

    const state = useSelector((state) => state)

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange?.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange?.length - 1];

    props.setLastPage && props.setLastPage(paginationRange[paginationRange?.length - 1])

    return (
        <div>
            {/* Pagination  */}
            <nav aria-label="Page navigation example" >
                <ul className="list-style-none my-6 flex w-full justify-center ">

                    <li>
                        <button
                            disabled={currentPage === 1}
                            className={`relative block rounded bg-transparent px-3 py-1.5 text-sm transition-all duration-300 ${currentPage === 1 ? 'text-neutral-400' : 'text-black hover:bg-primary-100'}`}
                            onClick={onPrevious}>
                            {selectedLanguage?.name == "English" ? "Previous" : translation[328]?.value ? translation[328]?.value : "Previous"}
                        </button>
                    </li>

                    {paginationRange.map(pageNumber => {
                        if (pageNumber === DOTS) {
                            return <li className="pagination-item dots">&#8230;</li>;
                        }
                        return (
                            <li>
                                <button
                                    onClick={() => onPageChange(pageNumber)}
                                    className={`relative block rounded cursor-pointer mx-px px-3 py-1.5 text-sm transition-all duration-300 hover:bg-primary-200 ${pageNumber === currentPage ? "bg-primary-300 font-bold text-primary-700" : 'bg-transparent'} `} >
                                    {pageNumber}
                                </button>
                            </li>
                        )
                    })
                    }

                    <li>
                        <button
                            disabled={currentPage === lastPage}
                            className={`relative block rounded bg-transparent px-3 py-1.5 text-sm transition-all duration-300 ${currentPage === lastPage ? 'text-neutral-400' : 'text-black hover:bg-primary-100'}`}
                            onClick={onNext} >
                            {selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
                        </button>
                    </li>

                </ul>
            </nav>
        </div>
    )
}

export default Pagination



