import React, { useEffect, useState } from 'react'
import { getChartProductsInsights } from 'Adapters/Api/Products'
import Dropwdown from 'Components/TenantApp/Elements/Dropwdown'
import { useDispatch, useSelector } from 'react-redux'
import LocationSelection from "new-components/location";
import InventoryInsightNotFound from 'Components/Utility/InventoryInsightNotFound'
import Shimmer from 'Components/Utility/Shimmers/Shimmer'
import { graphScheleton } from 'Constants/Data/Calendar'
import Breadcrumb from 'new-components/breadcrumbs/Breadcrumb'
import DashboardChart from '../../DashboardChart';
import DateRangePicker from 'Components/TenantApp/Elements/DateRangePicker';
import moment from 'moment';
import { getExpenseAnalytics, getExpenseCategory } from 'Adapters/Api/expense';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';

const ExpenseAnalytics = () => {
    const dispatch = useDispatch()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()

    const selected_location = getDataFromLocalStorage('selected_location')
    const access_token = getDataFromLocalStorage('access_token')

    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const expenseCategory = state?.expense?.expenseCategory
    const expenseAnalytics = state?.expense?.expenseAnalytics
    const [loading, setLoading] = useState(true)

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [toggleDateRange, setToggleDateRange] = useState()
    const [category, setCategory] = useState()

    useEffect(() => {
        dispatch(getExpenseCategory(access_token))
    }, [])

    const getExpenseAnalyticsRecord = () => {
        setLoading(true)
        const payload = {
            start_date: startDate,
            end_date: endDate,
            category: category,
            location_id: selected_location,
        }
        const success = () => { setLoading(false) }
        const fail = () => { setLoading(false) }
        dispatch(getExpenseAnalytics(access_token, payload, success, fail))
    }

    useEffect(() => {
        getExpenseAnalyticsRecord()
    }, [startDate, endDate, selected_location, category])


    return (
        <div className='lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right'>
            <div className="flex flex-col items-start gap-2 md:gap-4 mb-6">
                <Breadcrumb
                    first={selectedLanguage?.name == "English" ? "POS Analytics" : translation[7]?.value ? translation[7]?.value : "POS Analytics"}
                    second={selectedLanguage?.name == "English" ? "Expenses" : translation[1603]?.value ? translation[1603]?.value : "Expenses"}
                    url={'/dashboard/pos-analytics'}
                />
                <div className="flex items-center sm:gap-4 gap-2">
                    <h2 className="font-semibold text-2xl">
                        {selectedLanguage?.name == "English" ? "Expenses" : translation[1603]?.value ? translation[1603]?.value : "Expenses"}
                    </h2>
                    <LocationSelection />
                </div>
            </div>
            <div className='flex flex-col gap-[2rem]'>
                <div className='border rounded-2xl p-4 flex flex-col gap-3.5'>
                    <div className='flex items-center justify-between gap-4'>
                        <h1 className='font-semibold text-xl'>
                            {selectedLanguage?.name == "English" ? "Expenses" : translation[1603]?.value ? translation[1603]?.value : "Expenses"}
                        </h1>
                        <div className='flex items-center gap-4'>
                            <div className='border border-[#A1A1A1] text-sm rounded-md font-semibold flex items-center select-none'>
                                <div className='p-2 relative gap-4 flex items-center'>
                                    <p onClick={() => {
                                        setToggleDateRange(!toggleDateRange)
                                    }} className='cursor-pointer'>
                                        {`${startDate ? moment(startDate).format("DD-MM-YYYY") : "--/--/----"} - ${endDate ? moment(endDate).format("DD-MM-YYYY") : "--/--/----"}`}
                                    </p>
                                    <span className='cursor-pointer' onClick={() => {
                                        setStartDate()
                                        setEndDate()
                                    }}>Clear</span>

                                    {toggleDateRange && <>
                                        <div className='fixed inset-0 z-1' onClick={() => { setToggleDateRange(false) }}></div>
                                        <div className='absolute top-full sm:right-0 -right-44 z-[2]'>
                                            <DateRangePicker
                                                start={startDate}
                                                end={endDate}
                                                setStartDate={setStartDate}
                                                setEndDate={setEndDate}
                                                setToggleDateRange={setToggleDateRange}
                                            />
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                            <div>
                                <Dropwdown
                                    title={''}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Category" : translation[889]?.value ? translation[889]?.value : "Select Category"}
                                    name={'category'}
                                    onChange={(e) => setCategory(e.target.value)}
                                    value={category}
                                    options={[
                                        {
                                            label: selectedLanguage?.name == "English" ? "All Categories" : translation[1781]?.value ? translation[1781]?.value : "All Categories",
                                            value: "",
                                        },
                                        ...expenseCategory?.map(itm => {
                                            return {
                                                label: itm?.name,
                                                value: itm?.id
                                            }
                                        })
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='py-2 overflow-x-auto'>
                        {/* <div className={`${graph_data?.length > 0 ? 'min-w-[1000px]' : ''}`}> */}

                        {loading ?
                            <Shimmer>
                                <DashboardChart GraphData={graphScheleton} animate />
                            </Shimmer>
                            : expenseAnalytics?.length > 0
                                ? <DashboardChart GraphData={expenseAnalytics} expense />
                                : <InventoryInsightNotFound />
                        }
                        {/* <BarChart
                            colorScheme={'#418AD7'}
                            data={state.product.insights.chart_products.data ? state.product.insights.chart_products.data : []}
                        ></BarChart> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpenseAnalytics