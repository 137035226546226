import React from "react";

import Svgs from "Assets/svgs";
import ProfileStack from "../Elements/ProfileStack";

const CardTile = (props) => {
  return (
    <div className="dark-shadow rounded-xl p-3 flex justify-between items-center cursor-pointer capitalize">
      <div className="flex items-center sm:gap-4 gap-2">
        <div
          className={`${props.left_icon_color ? "bg-primary" : ""
            }  ${props.lefticon_class} p-5 h-[3.5rem] w-[3.5rem] rounded-lg flex items-center justify-center overflow-hidden`}
        >
          {
            props.leftImage ? props.leftImage : <span className={`${props.left_icon_color ? "" : "flex-1"}`}>
              {props.leftIcon}
            </span>
          }

        </div>
        <div className="h-fit flex flex-col gap-1">
          <h2 className="font-semibold text-black">{props.title}</h2>
          {props?.groupTax && props?.groupTax.length > 0 ? (
            props?.groupTax.map((tax, index) => (
              <p className="text-[#7B7B7B] text-xs">
                {tax?.name} {tax.tax_rate}%
              </p>
            ))
          ) : (
            <p className="text-[#7B7B7B] text-xs">{props.desc}</p>
          )}
          {props?.icon && props?.icon ? (
            <ProfileStack data={props?.icon} type='refund' />
          ) : ''}
        </div>
      </div>
      <div className="pr-3">
        {props.onDelete || props.onEdit ? (
          <div className="flex items-center sm:gap-4 gap-2">
            {props.onDelete ? (
              <span onClick={props.onDelete}>
                <Svgs.Delete />
              </span>
            ) : (
              ""
            )}
            {props.onEdit ? (
              <span onClick={props.onEdit}>
                <Svgs.Edit />
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {props.rightIcon && props.rightIcon}
      </div>
    </div>
  );
};

CardTile.defaultProps = {
  left_icon_color: true,
};

export default CardTile;
