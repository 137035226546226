import React from 'react'

const RadioBtn = ({ onClick, checked, id,gap, text, value, name, error, className }) => {
    return (
        <div className={`flex items-center  ${gap}`}>
            <input
                name={name ? name : "default-radio"}
                id={`radio-${id}`}
                onClick={onClick}
                checked={checked}
                type="radio"
                value={value}
                className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 outline-none accent-[#5679FF] ${className ? className : ''}`}
            />
            <label
                for={`radio-${id}`}
                className={`ml-2 text-sm font-medium text-primary ${error && '!text-red-500'}`}>
                {text}
            </label>
        </div>
    )
}

export default RadioBtn