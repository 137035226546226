import { CAMPAIGNS } from "Redux/TenantRedux/ActionTypes/campaignTypes"

export const getAllCampaigns = (data) => {
    return {
        type: CAMPAIGNS.GET_CAMPAIGNS,
        payload: data
    }
}

export const addCampaigns = (data) => {
    return {
        type: CAMPAIGNS.ADD_CAMPAIGNS,
        payload: data
    }
}
export const deleteCampaigns = (data) => {
    return {
        type: CAMPAIGNS.DELETE_CAMPAIGNS,
        payload: data
    }
}
export const updateCampaigns = (data) => {
    return {
        type: CAMPAIGNS.UPDATE_CAMPAIGNS,
        payload: data
    }
}
