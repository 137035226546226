import React, { useEffect, useState } from "react";
import Select from "react-select";
import Svgs from "../../../Assets/svgs";
import { error_message } from "../../../Constants/Variables";
import LabelAvatar from "./LabelAvatar";

const MultiDropdown = ({
    className,
    title,
    name,
    value,
    onBlur,
    placeholder,
    options,
    onChange,
    error,
    disabled,
    required,
    noBorder,
    showTitle,
    edit,
    handleFocus,
    isMulti,
    minWidth
}) => {

    const [field_err, setFieldErr] = useState(undefined)
    const colourStyles = {
        control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            boxShadow: isFocused ? "none" : "none",
            border: noBorder
                ? 0
                : isFocused
                    ? "1px solid #e5e7eb"
                    : "1px solid #e5e7eb",
            color: "#101928",
            fontSize: "0.875rem",
            padding: "0.2rem 0.2rem",
            outline: "none",
            minWidth: options.find((itm) => itm.value == value) ? `${(options.find((itm) => itm.value == value).label.length * 0.625 + 3) > 12 ? 12 : (options.find((itm) => itm.value == value).label.length * 0.625 + 3)}rem` : '12rem' // Adjust the width calculation as needed
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? "#f2f2f2"
                    : isFocused
                        ? "#2684ff14"
                        : "#fff",
                color: "#101928",
                fontSize: "0.875rem",
                cursor: isDisabled ? "not-allowed" : "pointer",
            };
        },
        menu: (styles) => ({
            ...styles,
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            zIndex: 111111111111,
        }),
    };

    const errorStyle = {
        control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            boxShadow: isFocused ? "none" : "none",
            border: field_err && "1px solid #eb3b3b",
            color: "#101928",
            fontSize: "0.875rem",
            padding: "0.2rem 0.2rem",
            minHeight: "0",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? "#f2f2f2"
                    : isFocused
                        ? "#2684ff14"
                        : "#fff",
                color: "#101928",
                fontSize: "0.875rem",
                cursor: isDisabled ? "not-allowed" : "pointer",
            };
        },
        menu: (styles) => ({
            ...styles,
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            zIndex: '11111111222222221111',
        }),
        input: (styles) => ({
            ...styles,
        }),

        // sudo certbot --nginx -d result
    };


    useEffect(() => {
        setFieldErr(error)
    }, [error])


    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleMenuOpen = () => {
        setMenuIsOpen(true);
    };

    const handleMenuClose = () => {
        setTimeout(() => {
            setMenuIsOpen(false);
        }, 100);
    };

    const handleOptionSelect = (selectedOption) => {
        // Handle option selection logic here
        console.log(selectedOption);
    };

    const customFormatOptionLabel = ({ label, otherData }) => (
        <div className='flex items-center gap-3 h-[1.125rem] z-[10]'>
            {otherData?.showImage == true ?
                <div className="h-[1.650rem] w-[1.650rem] rounded-full overflow-hidden">
                    {
                        otherData?.image ? (
                            <img src={otherData?.image} className="h-full w-full object-cover" />
                        ) : (
                            <LabelAvatar fontSize={'text-[12px]'} index={otherData?.index} iconLabel={label} />
                        )}
                </div>
                : ""
            }
            <div className="text-sm gap-2">
                <div className="text-sm font-medium">{label}</div>
                <div className="overflow-hidden whitespace-nowrap text-[8px] leading-normal">{otherData?.DataOne && otherData.DataOne}</div>
            </div>
        </div>
    );

    return (
        <>
            <div
                className={`flex flex-col gap-2 flex-1 whitespace-nowrap capitalize relative ${minWidth ? minWidth : ''} ${className} ${disabled ? "cursor-not-allowed" : ""
                    }`}
            >
                {title != "" && showTitle && (
                    <p className="text-sm flex gap-1 normal-case">
                        {title && title}
                        <span className="text-red-600">
                            {required && required ? " * " : ""}
                        </span>
                    </p>
                )}
                <Select
                    styles={field_err ? errorStyle : colourStyles}
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                    name={name}
                    className="border-0"
                    formatOptionLabel={customFormatOptionLabel}
                    // menuPlacement={'auto'}
                    menuPosition="fixed"
                    isMulti={isMulti}
                    menuShouldBlockScroll
                    menuPortalTarget={document.body}
                    classNamePrefix="custom"
                    // defaultValue={options.find(itm => itm.value == value).label}
                    // defaultInputValue={options.find(itm => itm.value == value).label}
                    // value={value}
                    // onChange = {onChange}
                    isSearchable={window.innerWidth >= 1024 ? true : false} // Enable search for larger screens
                    onBlur={(e) => {
                        onBlur({ target: { name: name } });
                    }}
                    // value={value}
                    value={
                        value?.length > 0
                            ? value?.map((val) => ({
                                value: val.value,
                                label: options.find((itm) => itm.value === val.value)?.label || "",
                            }))
                            : placeholder
                    }
                    options={options && options.length > 0 ? options : []}
                    onChange={onChange}
                    isDisabled={edit ? false : options.length == 0 ? true : disabled}
                    placeholder={placeholder}
                    isOptionDisabled={(option) => option.disabled}
                    onFocus={() => {
                        if (handleFocus) {
                            handleFocus();
                        }
                    }}
                />

                {/* <p className='text-sm'>{title && title}{required && required ? ' * ' : <span className='text-[#A1A1A1] text-sm'> (Optional)</span>}</p> */}
                {/* {field_err && <span className='text-[#eb3b3b] flex items-center gap-2'>{field_err}<Svgs.I /></span>} */}

                {field_err && typeof field_err == "string" ? (
                    <p className="text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                        <Svgs.I fill="#eb3b3b" />
                        {field_err}
                    </p>
                ) : typeof field_err == "boolean" && field_err == true ? (
                    <p className="text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[106%] w-full justify-end">
                        <Svgs.I fill="#eb3b3b" />
                        {error_message}
                    </p>
                ) : (
                    ""
                )}
                {/* {field_err && <span className='text-[#eb3b3b] flex items-center gap-2 text-xs'>{field_err}<Svgs.I /></span>} */}
            </div>
        </>
    );
};

MultiDropdown.defaultProps = {
    title: 'Enter "title" prop',
    placeholder: 'Enter "placeholder" prop',
    options: [
        // { value: 'no_value', label: 'Enter "options" prop' },
    ],
    name: "no_name",
    value: "no_value",
    onBlur: () => { },
    onChange: () => { },
    error: false,
    disabled: false,
    required: false,
    noBorder: false,
    showTitle: true,
};

export default MultiDropdown;