

const initialState = {
    cart_items: [],
}

const CartItemReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {

        case "ADD_BULK_ITEMS_TO_CART":
            let more_objs = {}
            if (payload?.free_services_quantity) {
                more_objs['free_services_quantity'] = payload.free_services_quantity
            }
            if (payload?.is_promotion_availed) {
                more_objs['is_promotion_availed'] = payload.is_promotion_availed
            }
            if (payload?.is_promotion) {
                more_objs['is_promotion'] = payload.is_promotion
            }
            if (payload?.selected_promotion_type) {
                more_objs['selected_promotion_type'] = payload.selected_promotion_type
            }
            if (payload?.selected_promotion_id) {
                more_objs['selected_promotion_id'] = payload.selected_promotion_id
            }
            if (payload?.installment_client) {
                more_objs['installment_client'] = payload?.installment_client
            }

            return {
                ...state,
                cart_items: [...state.cart_items, ...payload.services],
                cart_total: payload?.cart_total ? payload.cart_total : 0,
                required_amount: payload?.required_amount ? payload.required_amount : 0,
                client_type: payload?.client_type ? payload.client_type : undefined,
                ...more_objs
            }

        case 'REMOVE_INSTALLMENT_CLIENT':
            return {
                ...state,
                installment_client: payload
            }

        case "ADD_TO_CART":
            let new_cart_items;
            const itemIndex = state?.cart_items?.findIndex(item => ((item.id === payload.id) && (item?.price == payload?.price)));
            const this_item = state?.cart_items?.find(item => ((item.id === payload.id) && (item?.price == payload?.price)));
            if (itemIndex !== -1) {
                if (this_item && (!this_item?.discount_price)) {
                    new_cart_items = [...state?.cart_items]
                    new_cart_items[itemIndex].quantity += 1
                    new_cart_items[itemIndex].displayQuantity += 1
                }
                else {
                    new_cart_items = [...state?.cart_items, payload];
                }
            } else {
                new_cart_items = [...state?.cart_items, payload];
            }

            return {
                ...state,
                cart_items: [...new_cart_items]
            }


        case "CART_FROM_LOCAL":
            return {
                ...state,
                cart_items: payload
            }

        case "ADD_TO_CART_FROM_COUPON":
            let newCartItems;
            newCartItems = [...state?.cart_items, payload];
            return {
                ...state,
                cart_items: [...newCartItems]
            }

        case "REMOVE_FROM_CART_FROM_COUPON":
            return {
                ...state,
                cart_items: [...payload]
            }


        case "REMOVE_FROM_CART":
            let prevtCartDetail = { ...state }
            if (payload?.selection_type == "INSTALLMENT_MEMBERSHIP") {
                delete prevtCartDetail.installment_client
            }
            if (state?.cart_items?.filter((item) => item.id !== payload?.id)?.length == 0) {
                localStorage.removeItem('cart_items')
            }
            return {
                ...prevtCartDetail,
                cart_items: state?.cart_items?.filter((item) => item.id !== payload?.id)
            }


        case "RESET_CART":
            let prev_state = {
                ...state
            }
            prev_state.cart_items = []
            delete prev_state.free_services_quantity
            delete prev_state.is_promotion_availed
            delete prev_state.is_promotion
            delete prev_state.cart_total
            delete prev_state.required_amount
            delete prev_state.client_type

            return prev_state

        case "ITEM_QUANTITY_INCREMENT":
            let prev_items = [...state.cart_items]
            let itm = prev_items?.find(item => item?.id === payload?.id)
            if (itm) {
                itm.quantity += 1
            }
            return {
                ...state,
                cart_items: prev_items,
            };

        case "ITEM_QUANTITY_DECREMENT":
            let prevs_items = [...state.cart_items]
            let itmss = prevs_items?.find(item => item?.id === payload?.id)
            if (itmss && itmss.quantity > 0) {
                itmss.quantity -= 1
            }
            else if (itmss && itmss.quantity === 0) {
                prevs_items = prevs_items.filter(item => item.id !== payload.id);
            }
            return {
                ...state,
                cart_items: prevs_items,
            };

        case "ADD_EMPLOYEE_IN_CART_ITEMS":
            const { id, employee, employee_id } = payload;
            const updatedItems = state.cart_items.map((item) => {
                if (item.id === id) {
                    return { ...item, employee, employee_id };
                } else {
                    return item;
                }
            });

            return {
                ...state,
                cart_items: updatedItems,
            };


        default:
            return state
    }
}
export default CartItemReducer