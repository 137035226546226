import React from 'react'

const PromotionCardShimmer = () => {
    return (
        <div className={`bg-gray-100 relative border rounded-md p-3 mt-1 animate-pulse`}>
            <div className="h-8 w-2/5 my-3 bg-gray-200 rounded-lg" />
            <div className="flex item-center justify-between w-full  mt-5">
                <div className="flex flex-col w-1/2">
                    <div className="h-5 w-1/3  bg-gray-200 rounded-lg" />
                    <div className="h-6 w-2/3 my-1 bg-gray-200 rounded-lg" />
                    <div className="h-5 w-1/3  bg-gray-200 rounded-lg" />
                </div>
                <div className="flex flex-col w-1/2 items-end">
                    <div className="h-5 w-1/3 my-3 bg-gray-200 rounded-lg" />
                    <div className="h-6 w-1/3  bg-gray-200 rounded-lg" />
                </div>
            </div>
        </div>
    )
}

export default PromotionCardShimmer