import { ABSENCE } from "Redux/TenantRedux/ActionTypes/absenceTypes";

export const add_Absence = (data) => {
    return {
        type: ABSENCE.ADD_ABSENCE,
        payload: data,
    };
};

// export const get_Absences = (data) => {
//     return {
//         type: ABSENCE.GET_ABSENCES,
//         payload: data,
//     };
// };

export const delete_Absence = (id) => {
    return {
        type: ABSENCE.DELETE_ABSENCE,
        payload: id,
    };
};

export const update_Absence = (data) => {
    return {
        type: ABSENCE.UPDATE_ABSENCE,
        payload: data,
    };
};