import { get_business_brands } from "Adapters/Api/Brand"
import { getServiceGroup } from "Adapters/Api/saleServices"
import Svgs from "Assets/svgs"
import moment from "moment"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"



export const DiscountOnBrandNServiceGroupCard = ({ selected, onRemoveOffer, data, onSelect }) => {
    const state = useSelector(state => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>
            <div
                className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? "border-black" : " border-[#B8C6F5]"
                    }  border   rounded-md p-4 mt-1 cursor-pointer`}
                onClick={() => {
                    onSelect && onSelect(data)
                }}
            >
                <div className="flex justify-between items-center mb-2">
                    <p className="text-[#A1A1A1] font-bold">
                        {selectedLanguage?.name == "English" ? "Get Discount on Specific Brand and Service Group" : translation[1213]?.value ? translation[1213]?.value : "Get Discount on Specific Brand and Service Group"}
                    </p>
                    {onRemoveOffer && selected &&
                        <button
                            className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
                            onClick={onRemoveOffer}
                        >
                            <Svgs.Delete fill="#767A87" />
                        </button>
                    }
                </div>
                <div className="text-black font-semibold mb-2 w-full">{data?.promotion_name}</div>

                <div className="mb-2">
                    <div className="flex items-center justify-between">
                        <p className="text-[#2a2a2a] font-bold">
                            {selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group"}
                        </p>
                        <span className="text-[#2a2a2a] font-bold">
                            {selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}
                        </span>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="text-[#A1A1A1]">{data?.service_group?.name}</p>
                        <span className="whitespace-nowrap">{data?.discount_service_group} %</span>
                    </div>
                </div>
                <div className="mb-2">
                    <div className="flex items-center justify-between">
                        <p className="text-[#2a2a2a] font-bold">
                            {selectedLanguage?.name == "English" ? "Specific Brand" : translation[1212]?.value ? translation[1212]?.value : "Specific Brand"}
                        </p>
                        <span className="text-[#2a2a2a] font-bold">
                            {selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}
                        </span>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="text-[#A1A1A1]">{data?.brand?.name}</p>
                        <span className="whitespace-nowrap">{data?.discount_brand} %</span>
                    </div>
                </div>
                {/* Date duration */}
                <div className="flex wf items-center justify-between gap-2">
                    <div className="flex items-center gap-2">
                        <p className="text-[#A1A1A1] text-sm">
                            {selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                        </p>
                        <p className="text-[#101928] font-semibold">
                            {moment(data?.date_restrictions?.start_date).format('DD-MM-YYYY')}
                        </p>
                    </div>
                    <div className="flex items-center gap-2">
                        <p className="text-[#A1A1A1] text-sm">
                            {selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                        </p>
                        <p className="text-[#101928] font-semibold">
                            {moment(data?.date_restrictions?.end_date).format('DD-MM-YYYY')}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

DiscountOnBrandNServiceGroupCard.defaultProps = {
    data: {},
    selected: false,
    onSelect: () => { }
}

const DiscountOnBrandNServiceGroup = ({ setNotFound, specificBrandServiceGroup, setProductsData, MODULE_TYPE, setServiceData_state, servicesRedux, onSelect, setSelServGroups }) => {
    const state = useSelector(state => state)
    const [brand_and_service_data, setServiceData] = useState([])

    const dispatch = useDispatch()


    const handle_on_select_appointment = (data) => {
        let this_group = { ...data?.service_group }
        this_group?.services?.forEach(serv => {
            serv.priceservice.forEach(serv_price => {
                serv_price.discount_price = (serv_price?.price * (100 - data.discount_service_group)) / 100
            })
        })
        setSelServGroups([this_group])
        onSelect(
            [],
            false,
            {
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
                selected_promotion: data,
            }
        )
    }


    const handle_select_service = (data) => {
        let services_ids = data?.service_group?.services?.map(itm => itm.id)
        let selectedServic = data?.excluded_services?.map(itm => itm.id)

        setServiceData_state(prev => {
            let services = prev?.filter(itm => services_ids.includes(itm.id))
            let new_services = services.map(itm => {
                return {
                    ...itm,
                    priceservice: itm?.priceservice.map(itm_price => {
                        return {
                            ...itm_price,
                            discount_price: (itm.price * (100 - data?.discount_service_group)) / 100,
                            discount: data?.discount_service_group,
                        }
                    })
                }
            })

            if (selectedServic?.length > 0) {
                let newResults = new_services?.length > 0 && new_services?.filter(ser => {
                    if (!selectedServic?.find(s => s == ser?.id)) return true
                })
                new_services = newResults;
            }
            return new_services
        })
        dispatch({
            type: "ADD_BULK_ITEMS_TO_CART",
            payload: {
                'services': [],
                is_promotion: true,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
            }
        });
        onSelect(
            [],
            false,
        )
    }

    const handle_product_select = (data) => {
        let selected_location = state.locations.selected_location
        selected_location = state.locations.locations?.find(itm => itm.id == selected_location)
        let selected_currency_id = undefined
        let product = data?.excluded_products?.map(ep => ep.id)

        if (selected_location && selected_location.currency) {
            selected_currency_id = selected_location.currency.id
        }
        setProductsData((prev) => {
            let this_brand_products = prev.filter(obj => obj.brand.id == data.brand.id)
            this_brand_products.forEach(prod => {
                let retail_price = prod.currency_retail_price.find(rt_price => rt_price.currency == selected_currency_id)
                prod.discount = data.discount_brand
                if (retail_price && retail_price?.retail_price) {
                    prod.discount_price = (retail_price.retail_price * (100 - data.discount_brand)) / 100
                }
            })

            if (product?.length > 0) {
                let newResults = this_brand_products?.length > 0 && this_brand_products?.filter(ser => {
                    if (!product?.find(s => s == ser?.id)) return true
                })
                this_brand_products = newResults
            }
            return this_brand_products
        })
        dispatch({
            type: "ADD_BULK_ITEMS_TO_CART",
            payload: {
                'services': [],
                is_promotion: true,
                selected_promotion_type: data?.type,
                selected_promotion_id: data?.id,
                is_promotion_availed: true,
            }
        });
        onSelect(
            [],
            false,
        )
    }

    const handle_on_select = (data) => {
        let funcs = {
            'APPOINTMENT': () => handle_on_select_appointment(data),
            'SERVICE': () => handle_select_service(data),
            'PRODUCT': () => handle_product_select(data),
        }
        funcs[MODULE_TYPE] && funcs[MODULE_TYPE]()
    }

    useEffect(() => {
        if (!state.brand.brands_updated) {
            return
        }
        if (state?.saleService?.servicesgroup?.length > 0) {
            let new_grps = [
                ...specificBrandServiceGroup
            ]



            new_grps.forEach(itm => {
                let this_group = state.saleService.servicesgroup?.find(grp_itm => grp_itm.id == itm.servicegroup)
                if (this_group) {
                    itm.service_group = this_group
                }

                let this_brand = state.brand.brands?.find(brnd => brnd.id == itm.brand)
                if (this_brand) {
                    itm.brand = this_brand
                }
            })
            setServiceData(new_grps)
        }
        else {
            dispatch(getServiceGroup());
        }
    }, [state.saleService.servicesgroup.length, state.brand.brands_updated])

    useEffect(() => {
        if (!state.brand.brands_updated) {
            dispatch(get_business_brands());
        }
    }, [])

    return (
        <>
            {
                brand_and_service_data?.map((brnd_grp, index) => {
                    return (
                        <>
                            <DiscountOnBrandNServiceGroupCard
                                data={brnd_grp}
                                key={index}
                                onSelect={handle_on_select}
                            />
                        </>
                    )
                })
            }
        </>
    )
}

DiscountOnBrandNServiceGroup.defaultProps = {
    specificBrandServiceGroup: [],
}


export default DiscountOnBrandNServiceGroup