import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    getEmployeeDropDownListPagination,
    getUniqueEmployeeId,
} from "../../../../../Adapters/Api/employee";
import Svgs from "../../../../../Assets/svgs";
import { DESIGNATIONS } from "../../../../../Constants/Data/Designation";
import { WEEKDAYS } from "../../../../../Constants/Data/Months";
import useSetDataInLocalStorage from "../../../../../Hooks/useSetDataInLocalStorage";
import Dropwdown from "../../../Elements/Dropwdown";
import I_Info_Icon from "../../../Elements/I_Info_Icon";
import LoginBtn from "../../../Elements/LoginBtn";
import LoginInput from "../../../Elements/LoginInput";
import Popup from "../../../Elements/Popup";
import useEmployeeForms from "./useEmployeeForms";
import { getLocations } from "./../../../../../Adapters/Api/index";
import FloatInput from "Components/TenantApp/Elements/FloatInput";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";

const AddEmployeePopup = ({ open, close, success }) => {
    const { employeeData, setEmployeeData, employeeHandleChange, errors, setLoading, employeeHandleLeaveChange,
        onBlurHandler, countriesList, access_token, setErrors, isEdit, loading, formRef, leaveMonthsOptions,
        validation, objectKeyConvertToArray, checkEmployeeEmail, hash, domain, createEmployees, apiErrorMessage, selected_location
    } = useEmployeeForms();

    const dispatch = useDispatch();
    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const businessData = getDataFromLocalStorage("business_data");
    const mState = useSelector((state) => state);
    const reduxlocationsList = mState?.locations?.locations;

    const translation = mState?.languages?.language_translation
    const selectedLanguage = mState?.languages?.selected_language

    const {
        full_name,
        employee_id,
        email,
        gender,
        country,
        address,
        location,
        designation,
        income_type,
        salary,
        working_days,
        leave_data
    } = employeeData;

    const fetchUnqiueEmployeeId = () => {
        //get unique employee id
        getUniqueEmployeeId(access_token)
            .then((result) => {
                const res = result?.response?.id;
                let idToString = res.toString();
                let idSrigToArray = idToString.split("-");

                let shortDomian = idSrigToArray[0]
                    .split("")
                    .filter((i) => {
                        if (i != "," && i != "'" && i != "[" && i != "]" && i != " ") {
                            return i;
                        }
                    })
                    .join("")
                    .toUpperCase();

                idSrigToArray.splice(0, 1, shortDomian);

                setEmployeeData((prev) => ({
                    ...prev,
                    employee_id: idSrigToArray.join("-"),
                }));
            })
            .catch((err) => {
                toast.error("Error in get employee id", { toastId: "toast" });
            });
    };


    //get business location and stored it in redux
    const getBusinessLocation = (business_id) => {
        dispatch(getLocations(business_id));
    };


    useEffect(() => {
        // dispatch(getservice());

        if (!isEdit) {
            fetchUnqiueEmployeeId();
        }
        // if (staffGroupRedux?.length === 0) {
        //     dispatch(getStaffGroup());
        // }
        if (reduxlocationsList?.length === 0) {
            getBusinessLocation(businessData?.id);
        }
    }, []);

    const onEmployeeSubmitPopup = () => {
        const selected_days = WEEKDAYS?.filter(
            (item) => employeeData[item.toLowerCase()]
        );

        const {
            full_name,
            employee_id,
            email,
            gender,
            country,
            location,
            address,
            designation,
            income_type,
            number_of_months,
            salary,
            leave_data
        } = employeeData;

        let requiredFields = {
            full_name,
            // employee_id,
            gender,
            country,
            address,
            email,
            business: businessData.id,
            location: location?.length > 0 ? location[0] : "",
            designation,
            income_type,
            salary,
            working_days: selected_days?.length == 0 && '',
            operational_casual_leave: leave_data?.operational_casual_leave,
            operational_annual_leave: leave_data?.operational_annual_leave,
            operational_medical_leave: leave_data?.operational_medical_leave,
            number_of_months: leave_data?.number_of_months,
        };

        if (selected_days?.length > 0) {
            // start_time, end_time
            delete requiredFields.working_days;
            requiredFields = { ...requiredFields, designation, income_type, salary };
        } else {
            setErrors((prev) => ({
                ...prev,
                working_days: "This field is required",
            }));
        }
        console.log("requiredFields", requiredFields);
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);
        if (objectKeyConvertToArray(result)?.length === 0) {
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                dispatch(checkEmployeeEmail(
                    { email },
                    (response) => {
                        if (response?.employee) {
                            toast.error(response.message, { toastId: "toast" })
                            setErrors((prev) => ({ ...prev, email: response?.message }))
                            formRef.current?.scrollIntoView({ behavior: "smooth" });
                        }
                        else {
                            setLoading(true);
                            let form_data = new FormData();
                            for (let key in employeeData) {
                                if (employeeData[key] || typeof employeeData[key] == "boolean") {
                                    if (typeof employeeData[key] == "object" && key != "image") {
                                        form_data.append(key, JSON.stringify(employeeData[key]));
                                    } else {
                                        form_data.append(key, employeeData[key]);
                                    }
                                }
                            }
                            form_data.append("business", businessData.id);
                            form_data.append("tenant_id", hash);
                            form_data.append("domain", domain);

                            createEmployees(form_data, access_token)
                                .then((response) => {
                                    setLoading(false);
                                    if (response?.status == 201) {
                                        dispatch(getEmployeeDropDownListPagination(1, selected_location))
                                        toast.success(response?.data?.response?.message, { toastId: "toast" });
                                        close && close()
                                        success && success()
                                        // dispatch(getEmployeeList());

                                    } else if (response.response.status == 500) {
                                        toast.error("Internal server error!", { toastId: "toast" });
                                        formRef.current?.scrollIntoView({ behavior: "smooth" });
                                    }
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    toast.error("invalid data!", { toastId: "toast" });
                                    formRef.current?.scrollIntoView({ behavior: "smooth" });
                                });
                        }
                    },
                    () => {
                        setErrors((prev) => ({ ...prev, email: "" }))
                        toast.error(apiErrorMessage, { toastId: "toast" })
                        formRef.current?.scrollIntoView({ behavior: "smooth" });
                    }
                ));
            } else {
                setErrors((prev) => ({
                    ...prev,
                    email: "Email is invalid.",
                }));
                formRef.current?.scrollIntoView({ behavior: "smooth" });
            }
        }
        else {
            formRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }


    return (
        <Popup close={close} open={open} heading={selectedLanguage?.name == "English" ? "Add Employee" : translation[465]?.value ? translation[465]?.value : "Add Employee"}>
            <>
                <div className="h-full bounce-in-right overflow-auto scroll-hidden-lg">
                    <div className="flex gap-4">

                        <div className="w-full" ref={formRef}>
                            <div className="flex flex-col gap-5">
                                <div className="flex items-center gap-3">
                                    <CircleIcon children={<Svgs.NewService />} />
                                    <div className="flex flex-col gap-1 text-left">
                                        <div>
                                            <h1 className="font-semibold text-xl">
                                                {selectedLanguage?.name == "English" ? "Add new employee" : translation[1672]?.value ? translation[1672]?.value : "Add new employee"}
                                            </h1>
                                        </div>
                                        <div>
                                            <p className="text-sm text-[#7B7B7B]">
                                                {selectedLanguage?.name == "English" ? "Fill in the following information to add employee" : translation[1673]?.value ? translation[1673]?.value : "Fill in the following information to add employee"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex gap-5">
                                    <LoginInput
                                        errorClass={"!static"}
                                        parentClass={"w-full"}
                                        required={true}
                                        title={selectedLanguage?.name == "English" ? "Full Name" : translation[472]?.value ? translation[472]?.value : "Full Name"}
                                        placeholder={selectedLanguage?.name == "English" ? "Enter Full Name" : translation[473]?.value ? translation[473]?.value : "Enter Full Name"}
                                        name={"full_name"}
                                        value={full_name}
                                        // charactersMaxLength={25}
                                        specialCharacterNotAllowed={true}
                                        onChange={(e) => employeeHandleChange(e)}
                                        onEnterSubmit={onEmployeeSubmitPopup}
                                        onBlur={() => onBlurHandler({ full_name: full_name })}
                                        error={errors.full_name}
                                    />

                                    <LoginInput
                                        errorClass={"!static"}
                                        parentClass={"w-full"}
                                        required={true}
                                        title={
                                            <I_Info_Icon
                                                text={selectedLanguage?.name == "English" ? "Employee Id" : translation[448]?.value ? translation[448]?.value : "Employee Id"}
                                                info={
                                                    selectedLanguage?.name == "English" ?
                                                        "Employee ID can be the employee’s attendance ID or contact HR department for employee ID"
                                                        : translation[475]?.value
                                                            ? translation[475]?.value
                                                            : "Employee ID can be the employee’s attendance ID or contact HR department for employee ID"
                                                }
                                            />
                                        }
                                        name={"employee_id"}
                                        placeholder={selectedLanguage?.name == "English" ? "Enter Your Name" : translation[509]?.value ? translation[509]?.value : "Enter Your Name"}
                                        value={employee_id}
                                        onEnterSubmit={onEmployeeSubmitPopup}
                                        onChange={(e) => employeeHandleChange(e)}
                                        onBlur={() => onBlurHandler({ employee_id: employee_id })}
                                        error={errors.employee_id}
                                        disabled={employee_id ? true : false}
                                    />


                                </div>
                                <div className="w-full grid md:grid-cols-2 gap-5">
                                    <LoginInput
                                        errorClass={"!static text-left"}
                                        parentClass={"w-full"}
                                        required={true}
                                        title={selectedLanguage?.name == "English" ? "Email Address" : translation[60]?.value ? translation[60]?.value : "Email Address"}
                                        placeholder={selectedLanguage?.name == "English" ? "Enter Your Email" : translation[510]?.value ? translation[510]?.value : "Enter Your Email"}
                                        name={"email"}
                                        // disabled={isEdit ? true : false}
                                        value={email}
                                        error={errors.email}
                                        onChange={(e) => employeeHandleChange(e)}
                                        onEnterSubmit={onEmployeeSubmitPopup}
                                    // onBlur={() => { onBlurHandler({ email: email }) }}
                                    />
                                    <Dropwdown
                                        className={"h-fit"}
                                        required={true}
                                        title={selectedLanguage?.name == "English" ? "Gender" : translation[479]?.value ? translation[479]?.value : "Gender"}
                                        placeholder={selectedLanguage?.name == "English" ? "Select Gender" : translation[520]?.value ? translation[520]?.value : "Select Gender"}
                                        errorClass={"!static"}
                                        name={"gender"}
                                        value={gender}
                                        onChange={(e) => employeeHandleChange(e)}
                                        onBlur={() => onBlurHandler({ gender: gender })}
                                        error={errors.gender}
                                        options={[
                                            {
                                                value: "Male",
                                                label: selectedLanguage?.name == "English" ? "Male" : translation[86]?.value ? translation[86]?.value : "Male"
                                            },
                                            {
                                                value: "Female",
                                                label: selectedLanguage?.name == "English" ? "Female" : translation[87]?.value ? translation[87]?.value : "Female"
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className="pt-5 grid sm:grid-cols-2 gap-5">

                                <LoginInput
                                    errorClass={"!static"}
                                    required={true}
                                    title={selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Address" : translation[482]?.value ? translation[482]?.value : "Enter Address"}
                                    name={"address"}
                                    onEnterSubmit={onEmployeeSubmitPopup}
                                    value={address}
                                    onChange={(e) => employeeHandleChange(e)}
                                    // onBlur={() => onBlurHandler({ address: address })}
                                    error={errors.address}
                                />
                                <Dropwdown
                                    errorClass={"!static"}
                                    className={"h-fit"}
                                    required={true}
                                    title={selectedLanguage?.name == "English" ? "Country" : translation[52]?.value ? translation[52]?.value : "Country"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Country" : translation[53]?.value ? translation[53]?.value : "Select Country"}
                                    name={"country"}
                                    value={country}
                                    onChange={(e) => employeeHandleChange(e)}
                                    onBlur={() => onBlurHandler({ country: country })}
                                    error={errors.country}
                                    options={countriesList}
                                    edit={isEdit ? true : false}
                                />




                                <Dropwdown
                                    errorClass={"!static"}
                                    className={"h-fit"}
                                    required={true}
                                    name={"location"}
                                    value={location}
                                    onChange={employeeHandleChange}
                                    options={reduxlocationsList?.map((adrs) => {
                                        // reduxlocationsList?.filter((lctin) => (!location.includes(lctin?.id)))?.map((adrs) => {
                                        return {
                                            label: adrs?.address_name,
                                            value: adrs?.id,
                                        };
                                    })}
                                    onBlur={() => onBlurHandler({ location: location })}
                                    error={errors?.location}
                                    // onBlur={() => onBlurHandler({ location: location?.length > 0 ? location[0] : "" })}
                                    title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Location" : translation[20]?.value ? translation[20]?.value : "Select Location"}
                                />

                                <Dropwdown
                                    errorClass={"!static"}
                                    required={true}
                                    title={selectedLanguage?.name == "English" ? "Designation" : translation[488]?.value ? translation[488]?.value : "Designation"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Designation" : translation[540]?.value ? translation[540]?.value : "Select Designation"}
                                    name={"designation"}
                                    value={designation}
                                    onChange={(e) => employeeHandleChange(e, "designation")}
                                    onBlur={() => onBlurHandler({ designation: designation })}
                                    error={errors.designation}
                                    options={DESIGNATIONS}
                                />



                                <Dropwdown
                                    errorClass={"!static"}
                                    required={true}
                                    title={selectedLanguage?.name == "English" ? "Income Type" : translation[432]?.value ? translation[432]?.value : "Income Type"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Income Type" : translation[491]?.value ? translation[491]?.value : "Select Income Type"}
                                    name={"income_type"}
                                    value={income_type}
                                    onChange={(e) => employeeHandleChange(e, "income_type")}
                                    onBlur={() => onBlurHandler({ income_type: income_type })}
                                    error={errors.income_type}
                                    options={[
                                        {
                                            value: "Hourly_Rate",
                                            label: selectedLanguage?.name == "English" ? "Hourly Rate" : translation[498]?.value ? translation[498]?.value : "Hourly Rate"
                                        },
                                        {
                                            value: "Daily_Income",
                                            label: selectedLanguage?.name == "English" ? "Daily Income" : translation[499]?.value ? translation[499]?.value : "Daily Income"
                                        },
                                        {
                                            value: "Monthly_Salary",
                                            label: selectedLanguage?.name == "English" ? "Monthly Salary" : translation[500]?.value ? translation[500]?.value : "Monthly Salary"
                                        },
                                    ]}
                                />
                                <div>
                                    <FloatInput
                                        required={true}
                                        title={selectedLanguage?.name == "English" ? "Salary" : translation[492]?.value ? translation[492]?.value : "Salary"}
                                        placeholder={selectedLanguage?.name == "English" ? "Enter Salary" : translation[494]?.value ? translation[494]?.value : "Enter Salary"}
                                        onEnterSubmit={onEmployeeSubmitPopup}
                                        name={"salary"}
                                        value={salary}
                                        onChange={(e) => employeeHandleChange(e, "salary")}
                                        onBlur={() => onBlurHandler({ salary: salary })}
                                        error={errors.salary}
                                    />
                                </div>



                            </div>
                            <div className="w-full grid grid-cols-2 2xl:grid-cols-4 gap-5 pt-5">
                                <LoginInput
                                    errorClass={"!static"}
                                    type={"number"}
                                    required={true}
                                    title={selectedLanguage?.name == "English" ? "Casual" : translation[507]?.value ? translation[507]?.value : "Casual"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Casual Leaves" : translation[508]?.value ? translation[508]?.value : "Enter Casual Leaves"}
                                    name={"operational_casual_leave"}
                                    value={leave_data?.operational_casual_leave}
                                    onChange={(e) => employeeHandleLeaveChange(e)}
                                    error={errors.operational_casual_leave}
                                />
                                <LoginInput
                                    errorClass={"!static"}
                                    type={"number"}
                                    required={true}
                                    title={selectedLanguage?.name == "English" ? "Annual" : translation[507]?.value ? translation[507]?.value : "Annual"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Annual Leaves" : translation[508]?.value ? translation[508]?.value : "Enter Annual Leaves"}
                                    name={"operational_annual_leave"}
                                    value={leave_data?.operational_annual_leave}
                                    onChange={(e) => employeeHandleLeaveChange(e)}
                                    error={errors.operational_annual_leave}
                                />
                                <LoginInput
                                    errorClass={"!static"}
                                    type={"number"}
                                    required={true}
                                    title={selectedLanguage?.name == "English" ? "Medical" : translation[507]?.value ? translation[507]?.value : "Medical"}
                                    placeholder={selectedLanguage?.name == "English" ? "Enter Medical Leaves" : translation[508]?.value ? translation[508]?.value : "Enter Medical Leaves"}
                                    name={"operational_medical_leave"}
                                    value={leave_data?.operational_medical_leave}
                                    onChange={(e) => employeeHandleLeaveChange(e)}
                                    error={errors.operational_medical_leave}
                                />
                                <Dropwdown
                                    errorClass={"!static"}
                                    className={"h-fit"}
                                    required={true}
                                    name={"number_of_months"}
                                    value={leave_data?.number_of_months}
                                    onChange={(e) => employeeHandleLeaveChange(e)}
                                    error={errors?.number_of_months}
                                    options={leaveMonthsOptions}
                                    title={selectedLanguage?.name == "English" ? "Valid For" : translation[298]?.value ? translation[298]?.value : "Valid For"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select validity" : translation[1173]?.value ? translation[1173]?.value : "Select validity"}
                                />
                            </div>
                            <div className="flex flex-col gap-5 flex-wrap h-fit pt-5">
                                <Dropwdown
                                    errorClass={"!static"}
                                    required={true}
                                    title={selectedLanguage?.name == "English" ? "Select Working Days" : translation[503]?.value ? translation[503]?.value : "Select Working Days"}
                                    placeholder={selectedLanguage?.name == "English" ? "Select Days" : translation[504]?.value ? translation[504]?.value : "Select Days"}
                                    name={"working_days"}
                                    value={working_days}
                                    onChange={(e) => {
                                        let { name, value } = e.target;
                                        if (value === "all_days") {
                                            setEmployeeData((prev) => ({
                                                ...prev,
                                                monday: true,
                                                tuesday: true,
                                                wednesday: true,
                                                thursday: true,
                                                friday: true,
                                                saturday: true,
                                                sunday: true,
                                            }));
                                            setErrors((prev) => ({ ...prev, working_days: "" }));
                                        } else {
                                            employeeHandleChange({
                                                target: {
                                                    name: value.toLowerCase(),
                                                    value: true,
                                                },
                                            });
                                            setErrors((prev) => ({ ...prev, working_days: "" }));
                                        }
                                    }}
                                    onBlur={() => onBlurHandler({ working_days: working_days })}
                                    error={errors.working_days}
                                    options={[
                                        {
                                            label: "All Days",
                                            value: "all_days",
                                        },
                                        ...WEEKDAYS.filter((itm) => !employeeData[itm?.toLowerCase()]).map((srvc) => ({
                                            label: srvc,
                                            value: srvc,
                                        })),
                                    ]}
                                />
                                <div className="flex items-center gap-3 flex-wrap">
                                    {WEEKDAYS?.map((item) => {
                                        return (
                                            employeeData[item.toLowerCase()] && (
                                                <div className="px-2 md:px-3 py-1 md:py-2 rounded-full flex items-center gap-2 md:gap-3 border-[2px] border-primary w-max text-primary cursor-pointer text-sm md:text-base">
                                                    {item}
                                                    <span
                                                        onClick={() => {
                                                            let name = item.toLowerCase();
                                                            setEmployeeData({
                                                                ...employeeData,
                                                                [name]: false,
                                                            });
                                                        }}
                                                    >
                                                        <Svgs.CirlceClose fill="#5679FF" />
                                                    </span>
                                                </div>
                                            )
                                        );
                                    })}
                                </div>
                            </div>

                            <div className="flex items-center justify-end pt-5 gap-2">
                                <LoginBtn
                                    className={"!px-8"}
                                    loading={loading}
                                    disabled={loading}
                                    onClick={() => {
                                        onEmployeeSubmitPopup();
                                    }}
                                    text={selectedLanguage?.name == "English" ? "ADD" : translation[97]?.value ? translation[97]?.value : "ADD"}
                                >
                                </LoginBtn>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        </Popup>
    );
};

export default AddEmployeePopup;
