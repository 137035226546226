import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import NotFound from "Components/Utility/NotFound";

import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import { MONTHS } from "Constants/Data/Months";
import Svgs from "Assets/svgs";
import { useReports } from "./helper";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import Pagination from "new-components/Pagination";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoReportDataFound from "Components/Utility/NoReportDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";

const StoreTargetReports = () => {
  const { reportStatus, filterMonth, filterYear, filterStore, locationDropdown, yearDropdown, monthDropdown, csvReport,
    loading, currencyOfSelectedLocation, filterTarget, reportsFilter, handleChange, translation, selectedLanguage,
    currentPage, setCurrentPage, PageSize, pages, allCount, allReports, handleExportClick, exportLoader
  } = useReports();
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])
  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
          url={'/dashboard/reports/'}
          second={selectedLanguage?.name == "English" ? 'Store Target Reports' : translation[378]?.value ? translation[378]?.value : 'Store Target Reports'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
            </h2>
          </div>
        </div>

        <div className="border rounded-lg">
          <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-1 gap-4 ">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl whitespace-nowrap">{selectedLanguage?.name == "English" ? "Store Target KPI" : translation[318]?.value ? translation[318]?.value : "Store Target KPI"}</h2>

                {(!reportStatus || loading) ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">
                      {allCount ? allCount : 0}{" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                      }
                    </p>
                  </div>
                }
              </div>
              {loadingPermission ?
                <Shimmer className={'w-44 lg:w-48'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer> :
                <div className="mb-00">
                  <Dropwdown
                    className={'w-[10rem] lg:w-[14rem]'}
                    name="filterStore"
                    title=""
                    showTitle={false}
                    placeholder={selectedLanguage?.name == "English" ? "Select Stores" : translation[380]?.value ? translation[380]?.value : "Select Stores"}
                    value={filterStore}
                    options={locationDropdown}
                    onChange={handleChange}
                  />
                </div>
              }
            </div>
            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-32 lg:!w-44 sm:!w-40'} classOn2={'!w-32 lg:!w-44 sm:!w-40'} classOn3={'!w-12 lg:!16'} has3Itm />
              :
              <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">
                <Dropwdown
                  name="filterYear"
                  title=""
                  showTitle={false}
                  value={filterYear}
                  placeholder="2023"
                  options={yearDropdown}
                  onChange={handleChange}
                />
                <Dropwdown
                  name="filterMonth"
                  title=""
                  showTitle={false}
                  value={filterMonth}
                  placeholder={selectedLanguage?.name == "English" ? "January" : translation[370]?.value ? translation[370]?.value : "January"}
                  options={monthDropdown}
                  onChange={handleChange}
                />
                {exportLoader ? (
                  <div className="h-[4rem] flex items-center justify-center">
                    <PreloaderSm />
                  </div>
                ) :
                  !employee ? (
                    allReports?.length > 0 ?
                      <CSVLink
                        {...csvReport}
                        target="_blank"
                        onClick={handleExportClick}
                      >
                        <IconButton filled>
                          <Svgs.Export />
                        </IconButton>
                      </CSVLink> :
                      <LoginBtn
                        className="rounded-lg border-2 border-primary"
                        bg="bg-white"
                        animation={false}
                        onClick={handleExportClick}
                      >
                        <div className="flex items-center gap-2 text-[#FFFFFF]">
                          <Svgs.Export bg="#FFFFFF" />
                        </div>
                      </LoginBtn>
                  ) : (
                    ""
                  )}
              </div>
            }
          </div>
          {(!reportStatus || loading) ?
            <TableShimer cols={11} colsHeight={7} rows={12} className={"inline-grid xl:grid grid-cols-[repeat(11,minmax(185px,1fr))] text-sm font-semibold"} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(11,minmax(195px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                <p>{selectedLanguage?.name == "English" ? "Store Name" : translation[381]?.value ? translation[381]?.value : "Store Name"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Service Target" : translation[371]?.value ? translation[371]?.value : "Total Service Target"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Service Sales" : translation[372]?.value ? translation[372]?.value : "Total Service Sales"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Retail Target" : translation[373]?.value ? translation[373]?.value : "Total Retail Target"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Retail Sales" : translation[374]?.value ? translation[374]?.value : "Total Retail Sales"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Voucher Target" : translation[385]?.value ? translation[385]?.value : "Total Voucher Target"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Voucher Sales" : translation[386]?.value ? translation[386]?.value : "Total Voucher Sales"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Membership Target" : translation[387]?.value ? translation[387]?.value : "Total Membership Target"}</p>
                <p>{selectedLanguage?.name == "English" ? "Total Membership Sales" : translation[388]?.value ? translation[388]?.value : "Total Membership Sales"}</p>
                <p className="text-center">{selectedLanguage?.name == "English" ? "Year" : translation[376]?.value ? translation[376]?.value : "Year"}</p>
                <p className="text-center">{selectedLanguage?.name == "English" ? "Month" : translation[377]?.value ? translation[377]?.value : "Month"}</p>
              </div>
              <div className={`odd-bg text-[#3A3A3A] transition-all`}>
                {reportsFilter()?.length > 0 ? (
                  reportsFilter()?.map((obj, index) => (
                    <>
                      <div
                        key={index}
                        className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(11,minmax(195px,1fr))] min-w-full w-fit"
                      >
                        <div className="flex items-center">
                          <p className="text-sm">{obj?.address_name}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {filterTarget(obj)?.service_target || 0}{" "}
                            {currencyOfSelectedLocation(obj?.id) || "AED"}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {parseFloat(obj?.service_sale_price?.toFixed(2))}{" "}
                            {currencyOfSelectedLocation(obj?.id) || "AED"}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {filterTarget(obj)?.retail_target || 0}{" "}
                            {currencyOfSelectedLocation(obj?.id) || "AED"}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {obj?.product_sale_price?.toFixed(2)}{" "}
                            {currencyOfSelectedLocation(obj?.id) || "AED"}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {filterTarget(obj)?.voucher_target || 0}{" "}
                            {currencyOfSelectedLocation(obj?.id) || "AED"}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {obj?.voucher_sale_price?.toFixed(2)}{" "}
                            {currencyOfSelectedLocation(obj?.id) || "AED"}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {filterTarget(obj)?.membership_target || 0}{" "}
                            {currencyOfSelectedLocation(obj?.id) || "AED"}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm">
                            {obj?.membership_sale_price?.toFixed(2)}{" "}
                            {currencyOfSelectedLocation(obj?.id) || "AED"}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm w-full text-center">{filterYear}</p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-sm w-full text-center">{MONTHS[filterMonth]}</p>
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <>
                    <NoReportDataFound />
                  </>
                )}

                {(reportsFilter()?.length > 0 && !loading) &&
                  <Pagination
                    currentPage={currentPage}
                    totalCount={Math.ceil(pages)}
                    pageSize={PageSize}
                    onPageChange={page => setCurrentPage(page)}
                  />
                }
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default StoreTargetReports;
