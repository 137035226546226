import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceEmployee, getServiceGroupCalendar, updateGroupService } from "Adapters/Api/saleServices";
import useValidations from "Hooks/useValidations";
import useObjectKeys from "Hooks/useObjectKeys";
import { toast } from "react-toastify";
import { apiErrorMessage } from "Constants/Data/Errors";
import { getserviceDropdownList } from "Adapters/Api/services";
import { getClientDropDownListPagination } from "Adapters/Api/clients";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { PostMissedOpportunity, checkEmployeeWorkingTimes } from "Adapters/Api/apointments";
import moment from "moment";

let clientTimeOutId = undefined
export const useAddMissedOpportunity = () => {
    const dispatch = useDispatch();
    const { setErrors, validation, errors } = useValidations();
    const { objectKeyConvertToArray } = useObjectKeys()

    const state = useSelector(state => state)
    const reduxServices = state?.service?.services;
    const employeeRedux = state?.employee?.employees
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const servicesRedux = state?.saleService?.saleServices;
    const clientPaginationData = state?.client?.clientPaginationData;
    const clientDropdownRedux = state?.client?.clientsRedux;

    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");
    const selectedLocationCurrency = getDataFromLocalStorage("selectedLocationCurrency");

    const [values, setValues] = useState({
        client_type: "Walk_in",
        services: [],
        notes: "",
        opportunity_date: ""
    });


    const [possible_error, setPossibleError] = useState({
        booking_error: "",
        future_slot_error: "",
    });
    const [loader, setLoader] = useState(false);
    const [showAddStaffPopup, setShowAddStaffPopup] = useState(false)
    const [submitClick, setSubmitClick] = useState(false)
    const [inp_data, setInpData] = useState({});
    const [select, setselect] = useState("");
    const [SelectServices, setSelectServices] = useState(false);
    const [dropdown, setdropdown] = useState("False");
    const [search, setSearch] = useState("")
    const [serviceEmployee, setServiceEmployee] = useState([]);
    const [select_service_serviceGroups, setSelServGroups] = useState([])
    const [ServiceGroup, setServiceGroup] = useState(false);
    const [errorss, setErrorss] = useState(errors)
    const [groupLoader, setGroupLoader] = useState(false)
    const [clientSearchText, setClientSearchText] = useState("");
    const [clientCurrentPage, setClientCurrentPage] = useState(1)
    const [clientLoader, setClientLoader] = useState(false)
    const [showAddClientPopup, setShowAddClientPopup] = useState(false)


    const getClients = (clientCurrentPage, clientSearchText) => {
        dispatch(getClientDropDownListPagination(clientCurrentPage, clientSearchText, setClientLoader));
    }

    useEffect(() => {
        if (clientSearchText === null) {
            getClients(clientCurrentPage, "")
        } else {
            if (clientTimeOutId) {
                clearTimeout(clientTimeOutId)
            }
            clientTimeOutId = setTimeout(() => {
                getClients(clientCurrentPage, clientSearchText)
            }, 500);
        }
    }, [clientSearchText, clientCurrentPage])

    const minDateLimit = useMemo(() => {
        return moment().format('YYYY-MM-DD');
    }, []);

    const clientTypeOptions = useMemo(() => [
        { value: 'Walk_in', label: selectedLanguage?.name == "English" ? "Walk-in" : translation[383]?.value ? translation[383]?.value : "Walk-in" },
        { value: 'In_Saloon', label: selectedLanguage?.name == "English" ? "In-Saloon" : translation[384]?.value ? translation[384]?.value : "In-Saloon" },
    ], [])

    const dependencyOptions = useMemo(() => [
        { value: 'Techincian', label: selectedLanguage?.name == "English" ? "Techincian" : translation[383]?.value ? translation[383]?.value : "Techincian" },
        { value: 'Out of stock', label: selectedLanguage?.name == "English" ? "Out of stock" : translation[384]?.value ? translation[384]?.value : "Out of stock" },
    ], [])

    useEffect(() => {
        dispatch(getserviceDropdownList())
    }, [])

    useEffect(() => {
        let formatData
        formatData = { ...values }
        if (formatData.client_type === "Walk_in") {
            delete formatData.client;
            setValues(formatData)
        }
    }, [values?.client_type])

    useEffect(() => {
        FilterDataGroup()
    }, [search, state?.saleService?.servicesgroup])

    useEffect(() => {
        setErrorss(errors)
    }, [errors])

    useEffect(() => {
        if (selected_location) {
            dispatch(getServiceGroupCalendar(selected_location, setGroupLoader));
        }
    }, [selected_location])

    //return all service against the selected location on dashboard or calender
    const appointmentServices = () => {
        let result = state?.saleService?.servicesgroup?.filter((grp) => grp?.status === true)?.map((itm) => {
            let grp_services = itm?.services?.filter((srv) => {
                let loc_service = srv?.location?.find((loc) => loc == selected_location);
                if (loc_service) {
                    return true;
                } else {
                    return false;
                }
            });

            if (grp_services.length > 0) {
                return { ...itm, services: grp_services };
            } else {
                return undefined;
            }
        });
        result = result.filter((itm) => itm);

        return result;
    }


    // filter group in the dropdown
    const FilterDataGroup = () => {
        let filter_data = []
        appointmentServices()?.forEach((itm) => {
            if (search) {
                if (itm.name.toLowerCase().includes(search.toLowerCase())) {
                    const results = itm?.services?.filter((dt) => {
                        if (dt.name.toLowerCase().includes(search.toLowerCase())) {
                            return true;
                        }
                    })
                    results?.length > 0 ?
                        filter_data.push({ name: itm?.name, services: results }) :
                        filter_data.push({ name: itm?.name })
                } else {
                    const result = itm?.services?.filter((dt) => {
                        if (dt.name.toLowerCase().includes(search.toLowerCase())) {
                            return true;
                        }
                    })
                    result?.length > 0 && filter_data.push({ name: itm?.name, services: result })
                }
            }
        });

        setSelServGroups(search ? filter_data : appointmentServices())

        return search ? filter_data : appointmentServices()
    };

    const addNewData = (data) => {
        setValues({
            ...values,
            services: [
                ...values.services,
                {
                    ...data,
                },
            ],
        });
        setInpData({});
        setselect("");
    };

    const searchfunction = (e) => {
        setSearch(e.target.value);
    };

    //selected service name
    const handleServices = (id, name) => {
        setdropdown("False");
        let formatdata = `${name.srv_name} (${name.srv_duration}, ${selectedLocationCurrency} ${name.price})`;

        setselect(formatdata);
        getServiceEmployee(id)
            .then((result) => {
                const employees = result?.response?.data;
                setServiceEmployee(employees);
            })
            .catch((error) => {
                toast.error(apiErrorMessage, { toastId: "toast" });
            });
    };

    //get duration of selected service
    const selectedServiceDurations = () => {
        let result = appointmentServices()?.map((itm) => {
            let selected_serv = itm?.services?.find((srv) => srv?.name === select);
            if (selected_serv) {
                return selected_serv?.priceservice;
            } else {
                return false;
            }
        });

        result = result.filter((itm) => itm);
        return result;
    };
    selectedServiceDurations();


    //set duration option list
    let [...duration_options] = selectedServiceDurations()?.map((itm) => {
        return itm?.map((i) => {
            return {
                label: i?.duration.replaceAll("_", " "),
                value: i?.duration.replaceAll("_", " "),
            };
        });
    });

    //check employee availability of working
    const checkEmployeetiming = (dt) => {

        // if ((dt?.member) && (dt?.duration)) {
        if (dt?.duration) {
            const formate_data = {
                service: dt?.service,
                // member_id: dt?.member,
                duration: dt?.duration,
                app_time: dt?.date_time,
                date: dt?.appointment_date
            }

            dispatch(checkEmployeeWorkingTimes(
                formate_data,
                (response) => {
                    setValues({
                        ...values,
                        services: [
                            ...values.services.map(
                                (appmnt, ind) => {
                                    if (ind == dt?.index) {

                                        return {
                                            ...dt,
                                            message: response?.message
                                        };

                                    }
                                    return appmnt;
                                }
                            ),
                        ],
                    });
                },
                () => {
                    toast.error(apiErrorMessage, { toastId: "toast" })
                }
            ))
        }
    }

    const handleRemoveAppointments = (id) => {
        setValues({
            ...values,
            services: [
                ...values.services?.filter(
                    (app, app_i) => {
                        return app_i != id;
                    }
                ),
            ],
        })
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == "notes") {
            setValues((prev) => ({ ...prev, notes: value }));
            setErrors((prev) => ({ ...prev, notes: "" }));
            // if (value && value.length <= 150) {
            //     setValues((prev) => ({ ...prev, notes: value }));
            //     setErrors((prev) => ({ ...prev, notes: "" }));
            // } else {
            //     if (value && value.length > 150) {
            //         setErrors((prev) => ({
            //             ...prev,
            //             notes: selectedLanguage?.name == "English" ? "You can not add more than 150 characters" : translation[1200]?.value ? translation[1200]?.value : "You can not add more than 150 characters"

            //         }));
            //     }
            // }
        }
        if (name != "notes") {
            setValues((prev) => ({ ...prev, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: "" }));
        }
    };

    const CreateMissedOpportunity = (success, fail) => {
        setSubmitClick(true)
        let validationData = {}
        if (values?.client_type === 'In_Saloon') {
            validationData = { client: values?.client, opportunity_date: values?.opportunity_date }
        } else {
            validationData = { opportunity_date: values?.opportunity_date }
        }
        setErrors(validation(validationData));
        let result = validation(validationData);
        let formatData = {}
        const updatedServices = values?.services?.map(({ member, duration, service, date_time }) => ({
            employee_id: member ? member : null,
            duration: duration,
            service_id: service,
            time: date_time
        }));
        const allServicesHaveEmployee = updatedServices?.every(appointment =>
            appointment?.employee_id && appointment?.employee_id !== null && appointment?.employee_id !== undefined
        );

        formatData = { ...values, services: updatedServices, location_id: selected_location }
        if (objectKeyConvertToArray(result)?.length === 0 && allServicesHaveEmployee) {
            if (formatData?.services?.length > 0) {
                setLoader(true);
                dispatch(
                    PostMissedOpportunity(
                        formatData,
                        (result, message) => {
                            setLoader(false);
                            setValues({ client_type: "", services: [], notes: "", opportunity_date: "" });
                            toast.success(message, { toastId: "toast" });
                            setSubmitClick(false)
                            success && success()
                        },

                        () => {
                            setLoader(false);
                            toast.error(apiErrorMessage, { toastId: "toast" });
                        }
                    )
                );
            }
        }
    }

    const EditMissedOpportunity = (success, fail) => {
        setSubmitClick(true)
        setErrors(validation({ opportunity_date: values?.opportunity_date }));
        let result = validation({ opportunity_date: values?.opportunity_date });
        if (objectKeyConvertToArray(result)?.length === 0) {
            if (values?.employees?.length > 0 && values?.services?.length > 0) {
                setLoader(true);
                dispatch(
                    updateGroupService(
                        values,
                        (message) => {
                            setValues({ name: "", service: [], status: true });
                            toast.success(message, { toastId: "toast" });
                            setLoader(false);
                            setSubmitClick(false)
                            success && success()
                        },
                        () => {
                            setLoader(false);
                            toast.error(apiErrorMessage, { toastId: "toast" });
                        }
                    )
                );
            }
        }
    };

    return {
        handleInputChange, setValues, errorss, reduxServices, values, translation, selectedLanguage, showAddStaffPopup, setShowAddStaffPopup,
        loader, CreateMissedOpportunity, EditMissedOpportunity, employeeRedux, handleServices, minDateLimit, submitClick, inp_data, setInpData,
        select, setselect, addNewData, SelectServices, setSelectServices, dropdown, setdropdown, search, setSearch, searchfunction, dispatch,
        setServiceEmployee, serviceEmployee, select_service_serviceGroups, selectedLocationCurrency, selected_location, ServiceGroup, setServiceGroup,
        groupLoader, setGroupLoader, servicesRedux, possible_error, setPossibleError, appointmentServices, duration_options, FilterDataGroup,
        checkEmployeetiming, handleRemoveAppointments, setSelServGroups, clientTypeOptions, clientDropdownRedux, clientSearchText, setClientSearchText,
        clientCurrentPage, setClientCurrentPage, clientLoader, setClientLoader, clientPaginationData, showAddClientPopup, setShowAddClientPopup,
        dependencyOptions, getClients
    };
};