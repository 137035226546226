import React from 'react'

const CardTileShimmer = ({ tax }) => {
    return (
        <>

            <div className="dark-shadow rounded-xl p-3 flex justify-between items-center capitalize animate-pulse">
                <div className="flex items-center sm:gap-4 gap-2 flex-1">
                    <div className={`bg-gray-200 p-5 h-[3.5rem] w-[3.5rem] rounded-lg flex items-center justify-center overflow-hidden`}></div>
                    <div className="h-fit flex flex-col gap-1 flex-1">
                        <div className={`w-1/4 ${tax ? "h-6" : "h-8"} rounded-lg bg-gray-200`}></div>
                        {tax && <div className="w-1/4 h-4 rounded-xl bg-gray-200"></div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardTileShimmer