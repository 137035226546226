
export const cancelOrRescheduleOptionList = [

    { value: 'Anytime', label: 'Anytime' },
    { value: '12_Hours_Prior_To_The_Appoinment', label: '12 hours prior to the appointment' },
    { value: '24_Hours_Prior_To_The_Appoinment', label: '24 hours prior to the appointment' },
    { value: '36_Hours_Prior_To_The_Appoinment', label: '36 hours prior to the appointment' }
]


export const controllTimeSlotOptionList = (selectedLanguage, translation) => {
    return ([
        {
            label: selectedLanguage?.name == "English" ? "Anytime in the future" : translation[282]?.value ? translation[282]?.value : "Anytime in the future",
            value: 'Anytime_In_The_Future'
        },
        {
            label: selectedLanguage?.name == "English" ? "No more than 1 months in the future" : translation[283]?.value ? translation[283]?.value : "No more than 1 months in the future",
            value: 'No_More_Than_1_Months_In_The_Future'
        },
        {
            label: selectedLanguage?.name == "English" ? "No more than 2 months in the future" : translation[284]?.value ? translation[284]?.value : "No more than 2 months in the future",
            value: 'No_More_Than_2_Months_In_The_Future'
        },
        {
            label: selectedLanguage?.name == "English" ? "No more than 3 months in the future" : translation[285]?.value ? translation[285]?.value : "No more than 3 months in the future",
            value: 'No_More_Than_3_Months_In_The_Future'
        },
    ])
}

export const timeSlotIntervalOptionList = [
    { label: '15 Mins', value: '15_Mins' },
    { label: '30 Mins', value: '30_Mins' },
    { label: '45 Mins', value: '45_Mins' },
    { label: '60 Mins', value: '60_Mins' },
    { label: '120 Mins', value: '120_Mins' },
]

export const clientCanBookOptionList = (selectedLanguage, translation) => {
    return ([
        {
            label: selectedLanguage?.name == "English" ? "Anytime" : translation[278]?.value ? translation[278]?.value : "Anytime",
            value: 'Anytime'
        },
        {
            label: selectedLanguage?.name == "English" ? "5 hours before" : translation[279]?.value ? translation[279]?.value : "5 hours before",
            value: '5_Hours_Before'
        },
        {
            label: selectedLanguage?.name == "English" ? "24 hours before" : translation[280]?.value ? translation[280]?.value : "24 hours before",
            value: '24_Hours_Before'
        },
        {
            label: selectedLanguage?.name == "English" ? "36 hours before" : translation[281]?.value ? translation[281]?.value : "36 hours before",
            value: '36_Hours_Before'
        },
    ])
}


export const timeSlotIntervalForSaleServiceOptionList = [
    { label: '30 Mins', value: '30_Mins' },
    { label: '60 Mins', value: '60_Mins' },
    { label: '90 Mins', value: '90_Mins' },
    { label: '120 Mins', value: '120_Mins' },
]