import { FINANCIAL_SETTINGS } from "../../ActionTypes/financialSettings"

export const getPaymentMethodList = (data) => {

    return {
        type: FINANCIAL_SETTINGS.GET_PAYMENT_METHOD,
        payload: data,
    }
}


export const addPaymentMethod = (data) => {

    return {
        type: FINANCIAL_SETTINGS.ADD_PAYMENT_METHOD,
        payload: data,
    }
}



export const removePaymentMethod = (data) => {

    return {
        type: FINANCIAL_SETTINGS.DELETE_PAYMENT_METHOD,
        payload: data,
    }
}



export const updatePaymentMethod = (data) => {

    return {
        type: FINANCIAL_SETTINGS.UPDATE_PAYMENT_METHOD,
        payload: data,
    }
}




// ************************************** business tax ***********************************




export const getBusinessTaxs = (data) => {

    return {
        type: FINANCIAL_SETTINGS.GET_BUSINESS_TAX,
        payload: data,
    }
}

export const getBusinessTaxSetting = (data) => {

    return {
        type: FINANCIAL_SETTINGS.GET_BUSINESS_TAX_SETTING,
        payload: data,
    }
}


export const addingBusinessTaxs = (data) => {

    return {
        type: FINANCIAL_SETTINGS.ADD_BUSINESS_TAX,
        payload: data,
    }
}


export const updatingBusinessTax = (data) => {

    return {
        type: FINANCIAL_SETTINGS.UPDATE_BUSINESS_TAX,
        payload: data,
    }
}




export const removeBusinessTax = (data) => {

    return {
        type: FINANCIAL_SETTINGS.DELETE_BUSINESS_TAX,
        payload: data,
    }
}

export const createRefundTimePeriod = (data) => {
    return {
        type: FINANCIAL_SETTINGS.ADD_REFUND_PERIOD,
        payload: data,
    }
}
export const updateRefundTimePeriod = (data) => {
    return {
        type: FINANCIAL_SETTINGS.UPDATE_REFUND_PERIOD,
        payload: data,
    }
}
export const getRefundTimePeriod = (data) => {
    return {
        type: FINANCIAL_SETTINGS.GET_REFUND_PERIOD,
        payload: data,
    }
}
export const deleteRefundTimePeriod = (data) => {
    return {
        type: FINANCIAL_SETTINGS.DELETE_REFUND_PERIOD,
        payload: data,
    }
}