import Svgs from "Assets/svgs"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLocations } from "../../../../../Adapters/Api"
import { getBusinessTax } from "../../../../../Adapters/Api/financialSettings"
import LoginBtn from "../../../Elements/LoginBtn"
import { getLocationTax } from "Adapters/Api/saleServices"
import BookingDetailCardShimmer from "Components/Utility/Shimmers/BookingDetailCardShimmer"
import Shimmer from "Components/Utility/Shimmers/Shimmer"

// import { addLocations } from "../../../../../Redux/TenantRedux/Actions/locationActions"

const BookingServiceCard = ({ is_coupon_redeemed, couponsIds, couponType, setShowDiscount, showDiscount, service, currency, membership_service, price, membership_type, voucher_redeem }) => {
    const [discount, setDiscount] = useState(null)
    const [updatedPrice, setUpdatedPrice] = useState(null)

    useEffect(() => {
        setDiscount(null)
        setUpdatedPrice(price?.toFixed(2))
        if (voucher_redeem) {
            setUpdatedPrice(price - (voucher_redeem * price) / 100)
            setDiscount({ id: service?.id, percentage: voucher_redeem })
            setShowDiscount(true)
        }

        if (membership_service?.length > 0 && membership_type == "Free") {
            const matchedService = membership_service?.find(itm => itm?.service == service?.serviceId)
            if (matchedService?.service == service?.serviceId) {
                setUpdatedPrice(price - price)
                setDiscount({ id: service?.id, percentage: 100 })
                setShowDiscount(true)
            }
        }

        if (membership_service?.length > 0 && membership_type == "Limited") {
            const serviceLimitedPercantage = membership_service?.find(itm => itm?.service == service?.serviceId)?.percentage
            if (serviceLimitedPercantage) {
                setUpdatedPrice((+price - (+serviceLimitedPercantage * +price) / 100))
                setDiscount({ id: service?.id, percentage: serviceLimitedPercantage })
                setShowDiscount(true)
            }
        }

        if (is_coupon_redeemed === true) {
            if (couponType == 1 || couponType == 2) {
                let matchingId = couponsIds?.length > 0 ? couponsIds?.find(itm => itm?.id == service?.id && !service.is_coupon_redeemed) : ''
                if (matchingId && !matchingId?.is_coupon_redeemed) {
                    setDiscount({ id: service?.id, percentage: matchingId?.discount_percentage })
                    setShowDiscount(true)
                    setUpdatedPrice(+price - +matchingId?.discount_price)
                }
            }
        }
    }, [service, price, voucher_redeem, membership_service, membership_type, membership_service?.length, , couponType, couponsIds?.length, couponsIds, is_coupon_redeemed])

    return (
        <>
            <div className='flex items-center justify-between gap-4 text-sm py-1'>
                <p className='text-[#7B7B7B]'>{service?.name} {" "}
                    {(showDiscount && (discount?.id == service?.id))
                        ? `(Discount ${discount.percentage}%)`
                        : ''
                    }
                </p>
                <p className='text-[#7B7B7B] flex items-center gap-2'>
                    <span>{updatedPrice ? (+updatedPrice)?.toFixed(2) : 0} {currency}</span>
                </p>
            </div>
        </>
    )
}


const BookingDetailCard = ({ payBtnDisabled, subTotal, setSubTotal, couponDiscountValue, isFromCheckout, discountValue, setTotalAmountPaid, setAppliedTax, is_gift_card_redeemed, appliedGiftCard, totalAmountPaid, selectedPaymentMethods, setSelectedPaymentMethods, setCouponDiscountValue, couponsIds, setTotalPriceWithoutTax, setTaxValuesForApi, loading, allowCheckout, checkoutLoader, setDiscountValue, is_redeemed, setIs_redeemed, setRedeemed_points, loyaltyDiscountAmount, setLoyaltyDiscountAmount, appointmentServices, setAppointmentService, redeemed_id, setRedeemed_id, redeemed_points, setData, appointmentData, payNowBtnDisabled, discount, onTipDelete, discount_price, tip, voucher_redeem, membership_service, membership_type, services, location, onPayClick, payNowBtn, status, price, tips, Taxes, setT_price }) => {
    const [showDiscount, setShowDiscount] = useState(true)
    const state = useSelector(state => state)
    const singleTaxName = state?.saleService?.location_tax?.tax ? state?.saleService?.location_tax?.tax?.parent_tax[0]?.name : ""
    const location_currency = state?.utility?.currency?.location_currency;
    const reduxlocationsList = state?.locations?.locations;
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    const { getDataFromLocalStorage } = useSetDataInLocalStorage();
    const selected_location = getDataFromLocalStorage("selected_location");
    const taxTypeForGroup = localStorage.getItem('taxTypeForGroup')

    const dispatch = useDispatch()

    // const [loc_tax, setLocationTaxx] = useState(undefined)
    // const [tax_percentage, setTaxPercentage] = useState(undefined)
    // const [tax_name, setTaxName] = useState('')
    // const [taxFreeAmount, setTaxFreeAmount] = useState(0)
    // const [loc_tax_group, setLocationTaxGroup] = useState(null)

    const [taxApplied, setLocationTax] = useState(0)
    const [taxType, setTaxType] = useState()
    const [total_price, setTotalPrice] = useState(0)
    const [taxForGroup, setTaxForGroup] = useState(null)
    const [singleTax, setSingleTax] = useState(null)
    const [tax, setTax] = useState()
    const [taxLoader, setTaxLoader] = useState(true)
    const [currencyLoader, setCurrencyLoader] = useState(true)
    const [disableResetBtn, setDisableResetBtn] = useState(true)
    const [disableButton, setButtonDisable] = useState(true)

    let appliedGiftCard_amount = appointmentData?.is_gift_card_redeemed ? +appointmentData?.appliedGiftCard?.partial_price : 0
    let remaining_price = +(+total_price + (taxApplied ? +taxApplied?.toFixed(2) : 0) - (totalAmountPaid ? +totalAmountPaid : 0)?.toFixed(2)).toFixed(2)

    const onDeleteVoucherMembership = () => {
        if (appointmentData?.is_voucher_redeemed && appointmentData?.is_voucher_redeemed === true) {
            const updatedappointmentData = { ...appointmentData };
            delete updatedappointmentData.is_voucher_redeemed;
            delete updatedappointmentData.redeemed_voucher_id;
            delete updatedappointmentData.voucher_redeem;
            delete updatedappointmentData.voucher_type
            setData(updatedappointmentData);
            setSelectedPaymentMethods([])
            setDiscountValue && setDiscountValue(0)
            setShowDiscount(false)
        }
        else if (appointmentData?.is_membership_redeemed && appointmentData?.is_membership_redeemed === true) {
            const updatedappointmentData = { ...appointmentData };
            delete updatedappointmentData.is_membership_redeemed;

            delete updatedappointmentData.redeemed_membership_id;
            delete updatedappointmentData.membership_product;
            delete updatedappointmentData.membership_service;
            delete updatedappointmentData.membership_type
            setData(updatedappointmentData);
            setSelectedPaymentMethods([])
            setDiscountValue && setDiscountValue(0)
            setShowDiscount(false)
        }
    }

    const onDeleteCoupon = () => {
        const updatedOrderData = { ...appointmentData };
        const filteredItemsforOrderIds = appointmentServices?.filter((item) => !item.is_coupon_redeemed)
        setAppointmentService(filteredItemsforOrderIds)
        delete updatedOrderData.other_method;
        delete updatedOrderData.coupon_name;
        delete updatedOrderData.coupon_type;
        delete updatedOrderData.coupon_type_value;
        delete updatedOrderData.couponsIds;
        delete updatedOrderData.couponDiscountPercentage;
        delete updatedOrderData.couponSpendAmount;
        delete updatedOrderData.is_coupon_redeemed;
        delete updatedOrderData?.redeemed_coupon_id
        delete updatedOrderData?.refund_coupon
        setData(updatedOrderData);
        setSelectedPaymentMethods([])
    }

    const onDeleteGiftCard = () => {
        const updatedOrderData = { ...appointmentData };
        delete updatedOrderData?.is_gift_card_redeemed
        const filteredItemsforOrderIds = appointmentServices?.filter((item) => !item.is_gift_card_redeemed)
        setSelectedPaymentMethods([])
        setAppointmentService(filteredItemsforOrderIds)
        setData({
            ...updatedOrderData, is_gift_card_redeemed: false,
            appliedGiftCard: {
                purchased_gift_card_id: '',
                partial_price: 0
            },
        });
    }

    const onDeleteLoyalityPoints = () => {
        const updatedOrderData = { ...appointmentData };
        delete updatedOrderData.is_redeemed;
        delete updatedOrderData.redeemed_id;
        delete updatedOrderData.redeemed_points;
        setData(updatedOrderData);
        setSelectedPaymentMethods([])
        setIs_redeemed(false)
        setRedeemed_points('')
        setLoyaltyDiscountAmount('')
        setRedeemed_id("")
    }

    useEffect(() => {
        if (appointmentData?.is_voucher_redeemed || appointmentData?.is_membership_redeemed || appointmentData?.is_redeemed || appointmentData?.is_gift_card_redeemed || appointmentData?.is_coupon_redeemed || selectedPaymentMethods?.length > 0)
            setDisableResetBtn(false)
        else
            setDisableResetBtn(true)
    }, [appointmentData, selectedPaymentMethods])

    useEffect(() => {
        let paymentValidation = false
        if (selectedPaymentMethods?.length > 0) {
            // Check if each object has an amount property and it's not zero
            const allNonZeroAmounts = selectedPaymentMethods?.every(item => item?.amount && item?.amount !== 0);
            if (allNonZeroAmounts) {
                paymentValidation = true
            }
        }
        let disable = false
        if (payNowBtnDisabled || checkoutLoader || !allowCheckout || status != 'Finished' || payBtnDisabled
            || (window.location.href?.includes("/checkout/finish") && +remaining_price > 0 && !paymentValidation)) {
            disable = true
        } else {
            disable = false
        }
        setButtonDisable(disable)
    }, [payNowBtnDisabled, checkoutLoader, remaining_price, allowCheckout, selectedPaymentMethods, status, payBtnDisabled])

    const onResetClick = () => {
        setSelectedPaymentMethods([])
        if (appointmentData?.is_voucher_redeemed && appointmentData?.is_voucher_redeemed === true) {
            onDeleteVoucherMembership()
        }
        if (appointmentData?.is_membership_redeemed && appointmentData?.is_membership_redeemed === true) {
            onDeleteVoucherMembership()
        }
        if (appointmentData.is_redeemed) {
            onDeleteLoyalityPoints()
        }
        if (appointmentData?.is_gift_card_redeemed) {
            onDeleteGiftCard()
        }
        if (appointmentData?.is_coupon_redeemed) {
            onDeleteCoupon()
        }
    }

    useEffect(() => {
        let discountedPrice = 0
        let total_price = price ? price : 0
        let total_tips = 0

        if (membership_service?.length > 0 && membership_type == "Limited") {
            const filteredLimitedServicesPrices = membership_service?.map(srv => ((services?.find(itm => itm?.name?.toLowerCase() == srv?.service_name?.toLowerCase())?.price * srv?.percentage) / 100))
            const sumOfLimitedServicesPrices = filteredLimitedServicesPrices?.reduce((total, percentage) => total + percentage, 0);
            if (sumOfLimitedServicesPrices) {
                total_price -= sumOfLimitedServicesPrices
                discountedPrice = discountedPrice + sumOfLimitedServicesPrices
                // setDiscountValue(sumOfLimitedServicesPrices)
            }
        }

        if (membership_service?.length > 0 && membership_type === "Free") {
            const filteredServices = appointmentServices?.filter(itm =>
                membership_service?.some(mmbr_serv => mmbr_serv?.service == itm?.service)
            );
            const sumOfFreeServicesPrices = filteredServices?.map(srv => srv?.price)?.reduce((total, percentage) => total + percentage, 0);
            if (sumOfFreeServicesPrices) {
                total_price -= sumOfFreeServicesPrices;
                discountedPrice = discountedPrice + sumOfFreeServicesPrices
            }
        }

        if (voucher_redeem) {
            total_price -= (voucher_redeem * price) / 100
            discountedPrice = discountedPrice + ((voucher_redeem * price) / 100)
        }
        // console.log(appointmentData, "appointmentData")
        if (appointmentData?.is_coupon_redeemed) {
            if (appointmentData?.coupon_type == 'refund') {
                const discount = appointmentData?.refund_coupon?.coupon_discounted_price
                setCouponDiscountValue(discount)
                total_price -= discount
            } else if (appointmentData?.coupon_type == 'Cashback') {
                const discount = appointmentData?.cashback_coupon?.coupon_discounted_price
                if (total_price >= discount) {
                    total_price -= discount
                    setCouponDiscountValue(discount)
                } else {
                    setCouponDiscountValue(total_price)
                    total_price = 0
                }
            } else {
                if (appointmentData?.coupon_type_value == 1 || appointmentData?.coupon_type_value == 2) {
                    if (couponsIds?.length > 0) {
                        const totalDiscountPrice = couponsIds?.reduce((total, item) => total + (item.discount_price || 0), 0);
                        setCouponDiscountValue(totalDiscountPrice)
                        total_price -= +totalDiscountPrice
                    }
                } else {
                    if (total_price >= appointmentData?.couponSpendAmount) {
                        setCouponDiscountValue((+total_price * +appointmentData?.couponDiscountPercentage / 100))
                        total_price = (+total_price - +(+total_price * +appointmentData?.couponDiscountPercentage / 100))
                    }
                }
            }
        }

        // redeem loyalty discount amount here
        if (is_redeemed) {
            total_price = total_price - +loyaltyDiscountAmount
        }


        if (tips?.length > 0) {
            const total_tip_amount = tips?.reduce(function (total, obj) {
                return total + (+obj?.tip);
            }, 0)
            total_tips = +total_tip_amount
            total_price = total_price + total_tips
        }

        setTotalPriceWithoutTax && setTotalPriceWithoutTax(total_price)
        setTotalPrice(total_price)
        setSubTotal(total_price - total_tips)
        setDiscountValue && setDiscountValue((+discountedPrice)?.toFixed(2))
        if (setT_price) setT_price(total_price)
    }, [state.financialSettings.businessTaxs, location, services, tip, price, loyaltyDiscountAmount])

    useEffect(() => {
        if (state.business.business?.id) {
            dispatch(getLocations(state.business.business?.id))
            dispatch(getBusinessTax(state.business.business?.id))
        }

    }, [state.business.business])

    useEffect(() => {
        if (selected_location) {
            dispatch(getLocationTax(selected_location))
        }
    }, [selected_location])

    useEffect(() => {
        let lctin = reduxlocationsList.find((itm) => itm?.id === selected_location);
        if (lctin != undefined || lctin != null) {
            if ((Object.keys(lctin).includes("currency")) && (lctin?.currency?.code)) {
                dispatch({
                    type: "LOCATION_CURRENCY",
                    payload: lctin?.currency?.code,
                })
            }
        }
    }, [selected_location, reduxlocationsList?.length])

    useEffect(() => {
        let total_tips = 0
        let tax_amount = 0
        if (tips?.length > 0) {
            const total_tip_amount = tips?.reduce(function (total, obj) {
                return total + (+obj?.tip);
            }, 0)
            total_tips = +total_tip_amount
        }

        let location_taxes = []
        let allTaxes = []
        if (state.financialSettings.businessTaxs.length > 0) {
            location_taxes = state.financialSettings.businessTaxs.filter(itm => itm.tax_type == 'Location' && itm?.location?.id == selected_location)
            setTaxType(location_taxes[0]?.parent_tax[0]?.tax_type)
        }
        if (location_taxes?.length > 0) {
            allTaxes = location_taxes[0]?.parent_tax[0]?.parent_tax
            setTaxForGroup(location_taxes[0]?.parent_tax[0]?.parent_tax)
        }

        if (taxType == 'Group') {
            let tax1Price = 0
            let tax2Price = 0
            let totalPriceWithTax1 = 0
            if (taxTypeForGroup == 'Seperately') {
                tax1Price = (allTaxes[0]?.tax_rate * (total_price - total_tips)) / 100
                totalPriceWithTax1 = +tax1Price + (+total_price - +total_tips)
                tax2Price = allTaxes[1]?.tax_rate * totalPriceWithTax1 / 100
                tax_amount = +tax1Price + +tax2Price
                allTaxes?.forEach((tx, i) => {
                    if (i == 0) {
                        tx.value = tax1Price
                    } else {
                        tx.value = tax2Price
                    }
                })
            } else {
                const totalCombinedTax = allTaxes?.reduce(function (total, obj) {
                    return total + +(obj?.tax_rate);
                }, 0)
                tax1Price = (allTaxes[0]?.tax_rate * (total_price - total_tips)) / 100
                tax2Price = allTaxes[1]?.tax_rate * (total_price - total_tips) / 100
                allTaxes?.forEach((tx, i) => {
                    if (i == 0) {
                        tx.value = tax1Price
                    } else {
                        tx.value = tax2Price
                    }
                })
                tax_amount = (+totalCombinedTax * (total_price - total_tips)) / 100
            }
        }
        else {
            let tax_perc = location_taxes[0]?.parent_tax[0]?.tax_rate
            tax_amount = (tax_perc * (total_price - total_tips)) / 100
            setSingleTax([{ ...location_taxes[0]?.parent_tax[0], value: tax_amount }])
            setTax(tax_perc)
        }

        setAppliedTax && setAppliedTax(+tax_amount)
        setLocationTax(+tax_amount)
        // setCalculatedTax(+tax_amount)
    }, [tax, total_price, tips, state.financialSettings.businessTaxs, state.financialSettings.businessTaxs.length, taxTypeForGroup, selected_location, taxType])

    useEffect(() => {
        if (location_currency) {
            setCurrencyLoader(false)
        }
    }, [location_currency])

    useEffect(() => {
        if (taxType) {
            if (taxType == 'Group' && taxForGroup?.length > 0) {
                setTaxLoader(false)
            } else if (tax?.length > 0) {
                setTaxLoader(false)
            } else {
                setTaxLoader(false)
            }
        } else {
            setTaxLoader(false)
        }
    }, [taxType, taxForGroup?.length, tax?.length])

    useEffect(() => {
        let data = {
            gst: taxForGroup?.length > 0 ? taxForGroup[0]?.tax_rate : tax,
            gst_price: taxForGroup?.length > 0 ? taxForGroup[0]?.value ? taxForGroup[0]?.value?.toFixed(2) : 0 : taxApplied ? taxApplied?.toFixed(2) : 0,
            gst1: taxForGroup?.length > 0 ? taxForGroup[1]?.tax_rate : 0,
            gst_price1: taxForGroup?.length > 0 ? taxForGroup[1]?.value?.toFixed(2) : 0,
            tax_name: taxForGroup?.length > 0 ? taxForGroup[0]?.name : singleTaxName,
            tax_name1: taxForGroup?.length > 0 ? taxForGroup[1]?.name : ""
        }
        setTaxValuesForApi && setTaxValuesForApi(data)
    }, [taxForGroup, singleTaxName, taxApplied, tax, setTaxValuesForApi])


    return (
        <>
            <div className='rounded-[10px] appointment-shadow border-[1px] border-solid border-[#E5E7EB]'>
                {!loading && !currencyLoader && !taxLoader ?
                    <>
                        <div className='px-6 p-3'>
                            <h2 className='font-semibold text-[#101928] text-2xl'>
                                {selectedLanguage?.name == "English" ? "Cart Details" : translation[1551]?.value ? translation[1551]?.value : "Cart Details"}
                            </h2>

                        </div>
                        <div className='flex flex-col'>
                            <div className="p-2 flex flex-col gap-3 border-y max-h-[40vh] overflow-y-auto">
                                <div className="px-4  flex flex-col gap-3">
                                    <p className='text-[#000000]  text-xl font-medium'>
                                        {selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"}
                                    </p>
                                    {
                                        services && services?.length > 0 ?
                                            <div className='flex flex-col'>
                                                {
                                                    services?.map((service, index) => {
                                                        return (
                                                            <BookingServiceCard
                                                                couponsIds={appointmentData?.couponsIds}
                                                                couponType={appointmentData?.coupon_type_value}
                                                                is_coupon_redeemed={appointmentData?.is_coupon_redeemed}
                                                                service={service}
                                                                key={index}
                                                                currency={location_currency}
                                                                voucher_redeem={voucher_redeem}
                                                                membership_service={membership_service}
                                                                membership_type={membership_type}
                                                                showDiscount={showDiscount}
                                                                setShowDiscount={setShowDiscount}
                                                                price={service?.price} />
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            <>
                                                {selectedLanguage?.name == "English" ? "No Service Selected" : translation[181]?.value ? translation[181]?.value : "No Service Selected"}
                                            </>
                                    }
                                </div>

                                <hr />
                                {appointmentData?.is_voucher_redeemed &&
                                    <div className='flex items-center justify-between gap-2 text-sm px-4 '>
                                        <div className="flex items-center gap-2">
                                            <LoginBtn
                                                onClick={onDeleteVoucherMembership}
                                            >
                                                <Svgs.Delete fill="#fff" />
                                            </LoginBtn>
                                            {selectedLanguage?.name == "English" ? "Voucher Redeemed" : translation[1695]?.value ? translation[1695]?.value : "Voucher Redeemed"}
                                        </div>
                                        <div className="">
                                            <p className='font-semibold'>{discountValue}{" "}{location_currency}</p>
                                        </div>
                                    </div>
                                }
                                {appointmentData?.is_membership_redeemed &&
                                    <div className='flex items-center justify-between gap-2 text-sm px-4 '>
                                        <div className="flex items-center gap-2">
                                            <LoginBtn
                                                onClick={onDeleteVoucherMembership}
                                            >
                                                <Svgs.Delete fill="#fff" />
                                            </LoginBtn>
                                            <p className='font-semibold'>{selectedLanguage?.name == "English" ? "Membership Redeemed" : translation[219]?.value ? translation[219]?.value : "Membership Redeemed"}</p>
                                        </div>
                                        <div className="">
                                            <p className='font-semibold'>{discountValue}{" "}{location_currency}</p>
                                        </div>
                                    </div>
                                }
                                {is_redeemed &&
                                    <div className='flex items-center justify-between gap-2 text-sm px-4 '>
                                        <div className="flex items-center gap-2">
                                            <LoginBtn
                                                onClick={onDeleteLoyalityPoints}
                                            >
                                                <Svgs.Delete fill="#fff" />
                                            </LoginBtn>
                                            <p className='font-semibold'>
                                                {selectedLanguage?.name == "English" ? "Redeemed Loyality Points " : translation[220]?.value ? translation[220]?.value : "Redeemed Loyality Points "}</p>
                                        </div>
                                        <div className="">
                                            <p className='text-[#A1A1A1] flex items-center gap-2'>
                                                <span>{loyaltyDiscountAmount} {location_currency}</span>
                                            </p>
                                        </div>
                                    </div>
                                }
                                {appointmentData?.is_coupon_redeemed &&
                                    <div className='flex items-center justify-between gap-2 text-sm px-4 '>
                                        <div className="flex items-center gap-2">
                                            <LoginBtn
                                                onClick={onDeleteCoupon}
                                            >
                                                <Svgs.Delete fill="#fff" />
                                            </LoginBtn>
                                            <p className='font-semibold'>
                                                {selectedLanguage?.name == "English" ? "Coupon Redeem " : translation[1480]?.value ? translation[1480]?.value : "Coupon Redeem "}
                                            </p>
                                        </div>
                                        <div className="">
                                            {(couponDiscountValue && couponDiscountValue !== '0') &&
                                                <div className="flex items-center justify-between gap-2 text-sm ">
                                                    <p className='text-[#A1A1A1] flex items-center gap-2'>
                                                        <span>{(+couponDiscountValue)?.toFixed(2)} {location_currency}</span>
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                <div className='flex items-center justify-between gap-2 text-sm px-4'>
                                    <p className='font-semibold'>{selectedLanguage?.name == "English" ? "Subtotal" : translation[1712]?.value ? translation[1712]?.value : "Subtotal"}</p>
                                    <p className='text-[#A1A1A1] flex items-center gap-2'>
                                        {(+subTotal)?.toFixed(2)} {location_currency}
                                    </p>
                                </div>
                                <div className="px-4 py-1 flex flex-col gap-3">

                                    {taxType == 'Group' ?
                                        taxForGroup?.map((itm, index) => {
                                            return (
                                                <div key={index} className='flex items-center justify-between gap-4 text-sm '>
                                                    <p className='font-semibold'>{itm?.name} ( {itm?.tax_rate}% )</p>
                                                    <p className='text-[#101928] font-medium flex items-center gap-2'>
                                                        <span>{itm?.value?.toFixed(2)}{" "}{location_currency}</span>
                                                    </p>
                                                </div>
                                            )
                                        })
                                        : tax > 0 &&
                                        <div className='flex items-center justify-between gap-4 text-sm '>
                                            {/* <p className='font-semibold'>{tax_name} ( {tax_percentage}% )</p> */}
                                            <p className='font-semibold'>{singleTaxName} ({tax}%)</p>
                                            <p className='text-[#101928] font-medium flex items-center gap-2'>

                                                <span>
                                                    {/* {(tax * taxFreeAmount) / 100} {location_currency} */}
                                                    {taxApplied?.toFixed(2)}{" "}{location_currency}
                                                </span>
                                                {/* <span>{location_currency}. {loc_tax}</span> */}
                                            </p>
                                        </div>
                                    }

                                    {tips?.length > 0
                                        ? tips?.map(tp => <>
                                            <div className='flex items-center justify-between gap-2 text-sm px-4 '>
                                                {
                                                    !tp?.hide_delete_icon &&
                                                    <LoginBtn
                                                        onClick={() => {
                                                            onTipDelete(tp)
                                                        }}
                                                        className={'bg-none'}
                                                    >
                                                        <Svgs.Delete fill="#fff" />
                                                    </LoginBtn>
                                                }
                                                <div className="flex justify-between w-11/12 flex-1">

                                                    <p className='font-semibold'>
                                                        {selectedLanguage?.name == "English" ? "Tip for" : translation[188]?.value ? translation[188]?.value : "Tip for"} {" "}

                                                        {tp.employee}</p>

                                                    <p className='text-[#101928] font-medium flex items-center gap-2'>
                                                        <span>{(+tp.tip)?.toFixed(2)} {location_currency}</span>
                                                        {/* <span>{location_currency}  {tip}</span> */}
                                                        {/* <span>{location_currency}  {Math.round((tip * services[0].price) / 100)}</span> */}
                                                    </p>
                                                </div>
                                            </div>
                                        </>)
                                        :
                                        <></>
                                    }
                                    {
                                        discount && discount_price
                                            ? <div className='flex items-center justify-between gap-4 text-sm '>
                                                <p className='font-semibold'>
                                                    {selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}{" "} ({discount}%)</p>
                                                <p className='text-[#101928] font-medium flex items-center gap-2'>
                                                    <span>{(+discount_price)?.toFixed(2)} {location_currency}</span>
                                                </p>
                                            </div>
                                            : <></>
                                    }


                                    {appointmentData?.is_gift_card_redeemed &&
                                        <div className='flex items-center justify-between gap-2 text-sm'>
                                            <LoginBtn
                                                onClick={onDeleteGiftCard}
                                            >
                                                <Svgs.Delete fill="#fff" />
                                            </LoginBtn>
                                            <div className="flex justify-between w-11/12 flex-1 ">
                                                <p className='font-semibold'>
                                                    {selectedLanguage?.name == "English" ? "Gift Card Redeem" : translation[1574]?.value ? translation[1574]?.value : "Gift Card Redeem"}
                                                </p>
                                                <div className="flex items-center justify-between gap-2 text-sm">
                                                    <p className='text-[#A1A1A1] flex items-center gap-2'>
                                                        <span>{(+appliedGiftCard_amount)?.toFixed(2)} {location_currency}</span>
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                </div>
                                <hr />
                                <div className="px-4 py-1 flex flex-col gap-3">
                                    <div className='flex items-center justify-between gap-4 text-sm '>
                                        <p className='font-semibold'>{selectedLanguage?.name == "English" ? "Payable" : translation[1642]?.value ? translation[1642]?.value : "Payable"}</p>
                                        <p className='text-[#101928] font-medium flex items-center gap-2'>
                                            {isFromCheckout ? (+totalAmountPaid - +appliedGiftCard_amount)?.toFixed(2) : 0?.toFixed(2)} {location_currency}
                                        </p>
                                    </div>
                                    <div className='flex items-center justify-between gap-4 text-sm '>
                                        <p className='font-semibold'>{selectedLanguage?.name == "English" ? "Remaining" : translation[1562]?.value ? translation[1562]?.value : "Remaining"}</p>
                                        <p className='text-[#101928] font-medium flex items-center gap-2'>
                                            {isFromCheckout && (remaining_price < 0)
                                                ? 0?.toFixed(2)
                                                : remaining_price?.toFixed(2)}
                                            {' '}
                                            {location_currency}
                                        </p>
                                    </div>
                                    <div className='flex items-center justify-between gap-4 text-sm '>
                                        <p className='font-semibold'>{selectedLanguage?.name == "English" ? "Change" : translation[1581]?.value ? translation[1581]?.value : "Change"}</p>
                                        <p className='text-[#101928] font-medium flex items-center gap-2'>
                                            {remaining_price < 0
                                                ? Math.abs(remaining_price)?.toFixed(2)
                                                : 0?.toFixed(2)
                                            }{" "}
                                            {location_currency}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='flex items-center justify-between gap-4 text-sm px-6 py-5'>
                                {(payNowBtn && status !== "Done" && !payNowBtnDisabled) &&
                                    <div className="flex justify-start gap-3">
                                        <LoginBtn
                                            // className={'bg-[#E8E8E8] !text-[#B4B4B4] !font-semibold'}
                                            className={`${status == 'Finished' && !disableButton ? "bg-[#E0EBFD] text-[#5679FF] " : 'bg-[#E8E8E8] !text-[#B4B4B4] '}  !px-7`}
                                            customThemeBtn={'custom-theme-btn-checkout'}
                                            disabled={disableButton}
                                            loading={checkoutLoader}
                                            text={selectedLanguage?.name == "English" ? "Checkout" : translation[182]?.value ? translation[182]?.value : "Checkout"}
                                            onClick={() => {
                                                onPayClick({
                                                    // taxes_applied: loc_tax_group,
                                                    taxes_applied_group: taxForGroup,
                                                    single_tax_applied: singleTax,
                                                    taxes_applied_single_value: taxApplied,
                                                    taxes_applied_single: tax,
                                                    tax_type: taxType,
                                                    location: location.id
                                                })
                                            }}
                                        />
                                        <LoginBtn
                                            customThemeBtn={'custom-theme-btn-checkout'}
                                            disabled={disableResetBtn}
                                            className={`${!disableResetBtn ? "bg-[#E0EBFD] text-[#5679FF] " : 'bg-[#E8E8E8] !text-[#B4B4B4] '}  !px-7`}
                                            borderBtn
                                            text={selectedLanguage?.name == "English" ? "Reset" : translation[1141]?.value ? translation[1141]?.value : "Reset"}
                                            onClick={onResetClick}
                                        />
                                    </div>
                                }
                                <div className={`flex gap-1 text-center ${!payNowBtn ? 'flex-1 justify-between' : 'flex-col'}`}>
                                    <p className='text-[#A1A1A1] flex items-center gap-2'>
                                        {selectedLanguage?.name == "English" ? "Total Amount" : translation[183]?.value ? translation[183]?.value : "Total Amount"}
                                    </p>
                                    {/* <p className='font-semibold'> {location_currency} {Math.round(tip_sum ? (total_price + tip_sum) : total_price)}</p> */}
                                    {/* <p className='font-semibold'> {location_currency} {tip_sum ? ((total_price + tip_sum))?.toFixed(2) : total_price?.toFixed(2)}</p> */}
                                    <p className='font-semibold'>{((total_price ? +total_price : 0) + (taxApplied ? +taxApplied : 0) - +appliedGiftCard_amount).toFixed(2)} {location_currency}</p>
                                </div>
                            </div>

                        </div>
                    </>
                    : <BookingDetailCardShimmer />
                }
            </div>
        </>
    )
}


BookingDetailCard.defaultProps = {
    payNowBtn: true,
    onPayClick: () => { },
    payBtnText: 'Checkout',
    status: 'Pending',
    payNowBtnDisabled: true,
    services: [],
    location: {},
    tip: undefined,
}

export default BookingDetailCard