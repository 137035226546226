import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPOSProductAnalytics, getPOSSales } from 'Adapters/Api/posAnalytics/index'
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import { rangesEqual } from "@fullcalendar/core/internal";
import moment from "moment";
import { MonthData } from "Constants/Data/Months";

const useSalesAnalytics = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const reduxlocationsList = state?.locations?.locations;
  const selected_location = state?.locations?.selected_location;

  const [Filter, setFilter] = useState(false);

  const [FilterDetails, setFilterDetails] = useState({
    channel: false,
    product: false,
    service: false,
    promotion: false,
    appointment_status: false,
    tax_type: false,
  })
  const [toggleDateRange, setToggleDateRange] = useState(false);
  const [start_date, setStartDate] = useState();
  const [end_date, setEndDate] = useState();
  const [dateRange, setDateRange] = useState({
    start_date: '',
    end_date: ''
  })
  const [tableLoader, setTableLoader] = useState(false)
  const [productAnalytics, setProductAnalytics] = useState([])
  const [pagination, setPagination] = useState()
  const [current_page, setCurrent_page] = useState(1)

  const [salesData, setSalesData] = useState({})
  const [loader, setLoader] = useState(true);
  const currentYear = moment().year();


  const [filterData, setFilterData] = useState({
    type: 'year',
    year: currentYear,
    month: 1,
  })

  const getPOSSalesData = (clear) => {
    let isRangeFilter = dateRange?.start_date && dateRange?.end_date

    const payload = {
      location_id: selected_location,
      year: (isRangeFilter && !clear) ? '' : filterData?.type != 'today' ? filterData?.year : '',
      month:( isRangeFilter && !clear) ? '' : (filterData?.type != 'today' && filterData?.type == 'month') ? filterData?.month : '',
      today:( isRangeFilter && !clear) ? '' : filterData?.type == 'today' ? moment().format('YYYY-MM-DD') : '',
      start_date: clear ? '' : dateRange?.start_date,
      end_date: clear ? '' : dateRange?.end_date
    };

    const success = (result) => {
      setSalesData(result?.data)
      setLoader(false)
    };
    getPOSSales(payload, success);
  };

  useEffect(() => {
    getPOSSalesData();
  }, [selected_location, filterData]);

  const getPosProductAnalytics = (page) => {
    setTableLoader(true)
    const payload = {
      location_id: selected_location,
      start_date: start_date,
      end_date: end_date,
      page: page ? page : current_page
    };
    const success = (result) => {
      setProductAnalytics(result?.data?.product_records)
      setPagination({
        count: result?.data?.count,
        total_pages: result?.data?.total_pages,
      })
      setTableLoader(false)
    };
    const fail = () => { setTableLoader(false) };
    getPOSProductAnalytics(payload, success, fail)
  }

  // useEffect(() => {
  //   if (start_date || end_date || selected_location) {
  //     setCurrent_page(1)
  //     getPosProductAnalytics(1)
  //   } else {
  //     getPosProductAnalytics()
  //   }
  // }, [start_date, end_date, current_page, selected_location,])

  const onChangeHandler = (e) => {
    setDateRange({
      start_date: '',
      end_date: ''
    })
    const { name, value } = e.target
    setFilterData({
      ...filterData,
      [name]: [value]
    })
  }

  const MonthsDropdown = useMemo(() => {
    if (filterData?.year < currentYear) {
      return MonthData
    } else {
      const currentMonth = new Date().getMonth() + 1;
      return MonthData.filter(month => parseInt(month.value) <= currentMonth);
    }
  }, [filterData?.year])

  const location_currency = useMemo(() => {
    const foundLocation = reduxlocationsList.find(location => location?.id === selected_location);
    return foundLocation?.currency?.code ? foundLocation?.currency?.code : ''

  }, [reduxlocationsList, selected_location]);
  // const currentDate = moment().format('YYYY-MM-DD');

  const onDateRangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' || name === 'end_date') {
      if (name === 'start_date') {
        setDateRange((prev) => ({
          ...prev,
          start_date: value,
          end_date: ''
        }));
      }
      if (name === 'end_date') {
        setDateRange((prev) => ({
          ...prev,
          end_date: value
        }));
      }
    }
  }
  var minEndDateLimit = useMemo(() => {
    return moment(dateRange.start_date).add(1, 'day').format('YYYY-MM-DD')
  }, [dateRange.start_date]);

  const resetFilters = () => {
    getPOSSalesData(1)
    setDateRange({
      start_date: '',
      end_date: ''
    })
    setFilter(false)
  }

  const ApplyFilter = () => {
    if (dateRange?.start_date && dateRange?.end_date) {
      setFilter(false)
      getPOSSalesData()
    }
  }
  return {
    translation, selectedLanguage, navigate, FilterDetails, setFilterDetails, toggleDateRange, setToggleDateRange, start_date, setStartDate, end_date, setEndDate,
    salesData, loader, onChangeHandler, tableLoader, productAnalytics, pagination, Filter, setFilter, setPagination, setCurrent_page, current_page,
    selected_location, reduxlocationsList, MonthsDropdown, location_currency,
    filterData, onDateRangeHandler, minEndDateLimit, dateRange, resetFilters, ApplyFilter,
  }
}

export default useSalesAnalytics