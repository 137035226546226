import Svgs from "Assets/svgs";
import React from "react";
import { useSelector } from "react-redux";

const AddBreakTime = ({ plusBtnClick, setBreaktimeSlotPopup, start, end, handleDeleteSlot, handleEditSlot, week, bottom, employee, employeePermissions }) => {
  let start_time = start ? new Date(`12/12/2222 ${start}`) : "------";
  let end_time = end ? new Date(`12/12/2222 ${end}`) : "------";

  if (start_time !== "------") {
    start_time = start_time.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // This will display AM/PM indicator
    }).replace(/\b([ap])m\b/i, function (match, p1) {
      return p1.toUpperCase() + 'M'; // Capitalize 'A' or 'P' and add 'M'
    });
  }

  if (end_time !== "------") {
    end_time = end_time.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true, // This will display AM/PM indicator
    }).replace(/\b([ap])m\b/i, function (match, p1) {
      return p1.toUpperCase() + 'M'; // Capitalize 'A' or 'P' and add 'M'
    });
  }

  const state = useSelector((state) => state);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language



  return (
    (start && end) ?
      (<button className="transition-all relative border border-[#BAD3FC] text-[#3A3A3A] hover:bg-[#BAD3FC] bg-[#ECF3FF] px-3 py-1 flex items-center gap-2 rounded-lg hover-parent">
        <p className="whitespace-nowrap">
          {start_time} - {end_time}
        </p>
        {!employee ||
          (employee &&
            employeePermissions?.employee_work_schedule?.includes(
              "edit"
            )) ?
          <div className={`hidden hover-child absolute ${bottom ? 'bottom-full' : 'top-full'} left-0 right-0 bg-white border p-[0.35rem]  text-left shadow-md rounded-md`}>
            <div className="hover:bg-[#ddd] rounded p-1"
              onClick={handleEditSlot}>
              {selectedLanguage?.name == "English" ? "Edit break time" : translation[624]?.value ? translation[624]?.value : "Edit break time"} {' '}
              {week}
            </div>
            <div
              className="hover:bg-[#ddd] rounded p-1 text-[#E04663]"
              onClick={handleDeleteSlot}
            >
              {selectedLanguage?.name == "English" ? "Delete break" : translation[625]?.value ? translation[625]?.value : "Delete break"}
            </div>
          </div>
          : ""}
      </button>) : (
        <div className="flex justify-center items-center">
          <div onClick={() => {
            setBreaktimeSlotPopup(true);
            plusBtnClick()
          }
          } className="h-[1.5rem] w-[1.5rem] border-2 border-[#5679FF] rounded-full items-center justify-center cursor-pointer flex">
            <Svgs.Plus size={"0.7rem"} color="#5679FF" />
          </div>
        </div>
      )
  );
};
export default AddBreakTime;
