import { UTITLY_THEME_SERVICE } from "../ActionTypes/ThemeTypes";

const initialState = {
    nstyle: {
        primary: {
            text: 'white',
            bg: "#00383C"
        },
        secondary: '#FEE19D'
    },
    tenant: {

    },
    font_family: ''
};

const ThemeReducer = (state = initialState, action) => {

    switch (action.type) {
        case UTITLY_THEME_SERVICE.CHANGE_FONT:
            return {
                ...state,
                font_family: action.payload
            }
        case UTITLY_THEME_SERVICE.CHANGE_COLOR:
            return {
                ...state,
                nstyle: {
                    ...state.nstyle,
                    primary: {
                        ...state.nstyle.primary,
                        bg: action.payload,
                    }
                }
            }
        default:
            return state;
    }
};

export default ThemeReducer;
