import React, { useState, useEffect, useRef } from "react";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { logoutfunction } from "Constants/Functions/Logout";
import Side from "Assets/svgs/SideNave";
import SidebarOptions from "Components/TenantApp/Dashboard/SidebarOptions";
import LocationSelection from "new-components/location"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import Svgs from "Assets/svgs";
import { LogoSvg } from "Assets/svgs/Logo/LogoSvg";
import TooltipLite from 'react-tooltip-lite';
import SidebarShimmer from "Components/Utility/Shimmers/SidebarShimmer";


// import { sidebarData } from "./helper";
// import { getUser } from "Adapters/Api";
// import {
//   get_user_details,
//   get_user_permissions,
// } from "Redux/TenantRedux/Actions/UserActions/UserActions";
// import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
// import Moment from "react-moment";

const Dashboard = () => {
  const navigater = useNavigate();
  const [isUserAllowed, setUserAllowed] = useState(false)
  const [sidebar_options, setSideBarOptions] = useState([]);
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [SidebarOptionShowState, setSidebarOptionShowState] = useState(false);
  const [SidebarState, setSidebarState] = useState(1);
  const [CollapsedMenu, setCollapsedMenu] = useState(false)
  const [showMenuPopup, setShowMenuPopup] = useState(false)

  const state = useSelector((state) => state);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const { getDataFromLocalStorage, storeDataInLocalStorage } =
    useSetDataInLocalStorage();
  let user_id = getDataFromLocalStorage("user_id");

  useEffect(() => {
    if (user_id == null || user_id == undefined || user_id == "") {
      window.location = `http://${process.env.REACT_APP_SERVER_APPLICATION_DOMAIN}/`;
    }
    else {
      setUserAllowed(true)
    }
  }, [])



  const sidebarData = [
    {
      text: `${selectedLanguage?.name == "English" ? "Dashboard" : translation[1]?.value ? translation[1]?.value : "Dashboard"}`,
      id: 1,
      icon: <Side.SideNav_Dashboard />,
      navigate: "/",
    },
    !employee ||
      (employee &&
        (employeePermissions?.calender_root_access?.includes("view")
          || employeePermissions?.pos_root_access?.includes("view")
          || employeePermissions?.calender_appointment?.includes("view")
          || employeePermissions?.calender_appointment?.includes("create")
          || employeePermissions?.calender_appointment?.includes("edit")
          || employeePermissions?.calender_block_time?.includes("view")
          || employeePermissions?.calender_block_time?.includes("create"))) ? (
      {
        id: 2,
        text: `${selectedLanguage?.name == "English" ? "Calendar" : translation[2]?.value ? translation[2]?.value : "Calendar"}`,

        icon: <Svgs.Calender />,
        navigate: "calendar",
      }
    ) : undefined,
    !employee ||
      (employee &&
        (employeePermissions?.sales_root_access?.includes("view")
          || employeePermissions?.pos_root_access?.includes("view")
          || employeePermissions?.sales_apply_offer?.includes("create")
          || employeePermissions?.sales_apply_offer?.includes("edit"))) ? (
      {
        id: 3,
        text: `${selectedLanguage?.name == "English" ? "Sales" : translation[3]?.value ? translation[3]?.value : "Sales"}`,
        icon: <Side.SideNav_Sales />,
        navigate: "sales",
      }
    ) : undefined,
    // !employee ||
    //   (employee &&
    //     (employeePermissions?.pos_root_access?.includes("view"))) ? (
    //   {
    //     id: 4,
    //     text: "Expenses",
    //     icon: <Svgs.Expenses />,
    //     navigate: "expenses",
    //   }
    // ) : undefined,
    !employee ||
      (employee &&
        (employeePermissions?.employee_root_access?.includes("view")
          || employeePermissions?.pos_root_access?.includes("view")
          || employeePermissions?.employee_new?.includes("view")
          || employeePermissions?.employee_new?.includes("create")
          || employeePermissions?.employee_new?.includes("edit")
          || employeePermissions?.employee_payroll?.includes("view")
          || employeePermissions?.employee_payroll?.includes("create")
          || employeePermissions?.employee_payroll?.includes("edit")
          || employeePermissions?.employee_commission?.includes("view")
          || employeePermissions?.employee_commission?.includes("create")
          || employeePermissions?.employee_commission?.includes("edit")
          || employeePermissions?.employee_work_schedule?.includes("view")
          || employeePermissions?.employee_work_schedule?.includes("create")
          || employeePermissions?.employee_work_schedule?.includes("edit")
          || employeePermissions?.employee_vacation?.includes("view")
          || employeePermissions?.employee_vacation?.includes("create")
          || employeePermissions?.employee_vacation?.includes("edit")
          || employeePermissions?.employee_attendance?.includes("view")
          || employeePermissions?.employee_reports?.includes("view"))) ? (
      {
        id: 5,
        text: `${selectedLanguage?.name == "English" ? "Employee" : translation[4]?.value ? translation[4]?.value : "Employee"}`,
        icon: <Svgs.Employee />,
        navigate: "employee",
      }
    ) : undefined,
    !employee ||
      (employee &&
        (employeePermissions?.client_root_access?.includes("view")
          || employeePermissions?.pos_root_access?.includes("view")
          || employeePermissions?.client_list?.includes("view")
          || employeePermissions?.client_profile?.includes("view")
          || employeePermissions?.client_profile?.includes("create")
          || employeePermissions?.client_profile?.includes("edit")
          || employeePermissions?.client_groups?.includes("view")
          || employeePermissions?.client_groups?.includes("create")
          || employeePermissions?.client_groups?.includes("edit")
          || employeePermissions?.client_discount?.includes("view")
          || employeePermissions?.client_discount?.includes("create")
          || employeePermissions?.client_discount?.includes("edit")
          || employeePermissions?.client_reward?.includes("view")
          || employeePermissions?.client_reward?.includes("create")
          || employeePermissions?.client_reward?.includes("edit")
          || employeePermissions?.client_loyality?.includes("view")
          || employeePermissions?.client_loyality?.includes("create")
          || employeePermissions?.client_loyality?.includes("edit")
          || employeePermissions?.client_sharing?.includes("view")
          || employeePermissions?.client_sharing?.includes("create")
          || employeePermissions?.client_sharing?.includes("edit"))) ? (
      {
        id: 6,
        text: `${selectedLanguage?.name == "English" ? "Clients" : translation[5]?.value ? translation[5]?.value : "Clients"}`,
        icon: <Svgs.Clients />,
        navigate: "clients",
      }
    ) : undefined,
    // !employee ||
    //   (employee &&
    //     (employeePermissions?.pos_root_access?.includes("view"))) ? (
    //   {
    //     id: 7,
    //     text: "Products",
    //     icon: <Side.SideNav_Products />,
    //     navigate: "products",
    //   }
    // ) : undefined,
    !employee ||
      (employee &&
        (employeePermissions?.pos_root_access?.includes("view"))) ? (
      {
        id: 8,
        text: `${selectedLanguage?.name == "English" ? "HRM" : translation[1469]?.value ? translation[1469]?.value : "HRM"}`,
        icon: <Svgs.HRM />,
        navigate: "hrm",
      }
    ) : undefined,
    !employee ||
      (employee &&
        (employeePermissions?.pos_root_access?.includes("view"))) ? (
      {
        id: 9,
        text: `${selectedLanguage?.name == "English" ? "CRM" : translation[1354]?.value ? translation[1354]?.value : "CRM"}`,
        icon: <Svgs.CRM />,
        navigate: "crm",
      }
    ) : undefined,
    // !employee ||
    //   (employee &&
    //     (employeePermissions?.pos_root_access?.includes("view"))) ? (
    //   {
    //     id: 11,
    //     text: "Automation",
    //     icon: <Svgs.Automation />,
    //     navigate: "automation",
    //   }
    // ) : undefined,
    !employee ||
      (employee &&
        (employeePermissions?.profile_root_access?.includes("view")
          || employeePermissions?.profile_list?.includes("view")
          || employeePermissions?.profile_list?.includes("create")
          || employeePermissions?.profile_list?.includes("edit")
          || employeePermissions?.pos_root_access?.includes("view"))) ? (
      {
        id: 12,
        // text: "Online Profile",
        text: `${selectedLanguage?.name == "English" ? "Online Profile" : translation[6]?.value ? translation[6]?.value : "Online Profile"}`,

        icon: <Side.SideNav_Marketing />,
        navigate: "marketplace-profile",
      }
    ) : undefined,
    !employee ||
      (employee &&
        (employeePermissions?.reports_root_access?.includes("view")
          || employeePermissions?.reports_commission?.includes("view")
          || employeePermissions?.reports_staff?.includes("view")
          || employeePermissions?.reports_store?.includes("view")
          || employeePermissions?.reports_service?.includes("view")
          || employeePermissions?.reports_retail?.includes("view")
          || employeePermissions?.pos_root_access?.includes("view"))) ? (
      {
        id: 13,
        // text: "Reports",
        text: `${selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}`,
        icon: <Svgs.Reports />,
        navigate: "reports",
      }
    ) : undefined,
    !employee ||
      (employee &&
        (employeePermissions?.reports_root_access?.includes("view")
          || employeePermissions?.reports_commission?.includes("view")
          || employeePermissions?.reports_staff?.includes("view")
          || employeePermissions?.reports_store?.includes("view")
          || employeePermissions?.reports_service?.includes("view")
          || employeePermissions?.reports_retail?.includes("view")
          || employeePermissions?.pos_root_access?.includes("view"))) ? (
      {
        id: 13,
        // text: "Reports",
        text: `${selectedLanguage?.name == "English" ? "POS Analytics" : translation[7]?.value ? translation[7]?.value : "POS Analytics"}`,
        icon: <Svgs.POSAnalytics />,
        navigate: "pos-analytics",
      }
    ) : undefined,
    !employee ||
      (employee &&
        (employeePermissions?.account_root_access?.includes("view")
          || employeePermissions?.account_business_setting?.includes("edit")
          || employeePermissions?.account_finance_setting?.includes("edit")
          || employeePermissions?.account_service_management?.includes("edit")
          || (employeePermissions?.account_notification_setting?.includes("edit")
            || employeePermissions?.pos_root_access?.includes("view")))) ? (
      {
        id: 14,
        // text: "My Account",
        text: `${selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}`,

        icon: <Side.SideNav_Myaccount />,
        navigate: "account",
      }
    ) : undefined,
    // {
    //   text: `${selectedLanguage?.name == "English" ? "Inventory Insights" : translation[9]?.value ? translation[9]?.value : "Inventory Insights"}`,
    //   id: 15,
    //   icon: <Svgs.InventoryInsights />,
    //   navigate: "inventory-insights",
    // },
    // {
    //   text: selectedLanguage?.name == "English" ? "Employee Analytics" : translation[1236]?.value ? translation[1236]?.value : "Employee Analytics",
    //   id: 16,
    //   icon: <Svgs.Employee_Analytics />,
    //   navigate: "analytics",
    // },
    // {
    //   text: "Overtime",
    //   text: `${selectedLanguage?.name == "English" ? "Overtime" : translation[10]?.value ? translation[10]?.value : "Overtime"}`,

    //   id: 17,
    //   icon: <Svgs.SaleOvertime />,
    //   navigate: "overtime",
    // },
    {
      id: 18,
      text: "Logout",
      text: `${selectedLanguage?.name == "English" ? "Logout" : translation[11]?.value ? translation[11]?.value : "Logout"}`,
      icon: <Svgs.Logout />,
      onClick: () => {
        logoutfunction();
      },
    },
  ];

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  useEffect(() => {
    if (state?.user && state?.user?.user?.id) {
      setSideBarOptions(sidebarData.filter(itm => itm));
    }
  }, [state?.user]);

  useEffect(() => {
    if (sidebar_options?.length > 0) {
      setTimeout(() => {
        setSidebarOptionShowState(true)
      }, 1000);
    }
  }, [sidebar_options, sidebar_options?.length])

  // !isUserAllowed ? <PreloaderFull /> :
  return (<>
    <div className="h-[100vh] flex flex-col overflow-hidden">
      <div className="flex sm:items-center items-start justify-between border-b py-[0.4rem] xl:px-[2rem] px-[1rem] sticky top-0 z-[100] bg-white gap-1">
        <div className="flex items-center gap-4">
          <div className="cursor-pointer lg:block hidden" onClick={() => {
            setCollapsedMenu(!CollapsedMenu)
          }}>
            <TooltipLite direction="right" zIndex={'123213213'} content={<p className="whitespace-nowrap">{CollapsedMenu ? <>&nbsp;&nbsp;&nbsp;Show</> : 'Collapse'} Menu</p>} tagName="div">
              {
                CollapsedMenu ? <Svgs.ShowSideBar /> : <Svgs.CollapseSideBar />
              }
            </TooltipLite>
          </div>

          <div className="cursor-pointer lg:hidden block">
            <Svgs.Menu />
          </div>
          <div
            className="flex items-center gap-3 cursor-pointer flex-wrap"
            onClick={() => {
              navigater("/");
            }}
          >
            <LogoSvg />
            <h1 className="text-primary font-semibold text-2xl">NSTYLE</h1>
          </div>
        </div>
        <div className="flex items-center justify-between lg:col-span-2 col-span-6 bg-white lg:py-0 py-2">
          <div className="flex items-center gap-3 sm:justify-between justify-end flex-wrap-reverse w-full relative">
            <div className="truncate">
              <LocationSelection onlyShow={true} />
            </div>
            <div className="flex items-center gap-3 cursor-pointer" onClick={() => setShowMenuPopup(!showMenuPopup)}>
              {/* <div>
                <div
                  className="border-2 border-[#000]  rounded-full h-[3rem] w-[3rem] flex justify-center items-center bg-[#F6F6F6] bg-center bg-cover bg-no-repeat"
                  style={{
                    backgroundImage: `url(${state.business.business?.logo
                      })`,
                  }}
                >
                  <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.57999 19.01L2.55999 19.03C2.28999 18.44 2.11999 17.77 2.04999 17.03C2.11999 17.76 2.30999 18.42 2.57999 19.01Z"
                        fill="black"
                      />
                      <path
                        d="M9 10.3801C10.3144 10.3801 11.38 9.31456 11.38 8.00012C11.38 6.68568 10.3144 5.62012 9 5.62012C7.68556 5.62012 6.62 6.68568 6.62 8.00012C6.62 9.31456 7.68556 10.3801 9 10.3801Z"
                        fill="black"
                      />
                      <path
                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V13.9V7.81C22 4.17 19.83 2 16.19 2ZM20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V12.61L20.37 12.5Z"
                        fill="black"
                      />
                    </svg>
                  {!state.business.business?.logo && (
                    <Svgs.Home fill={"#000"} size={'1.5rem'} />
                  )}
                </div>
              </div> */}
                <div className="flex items-center gap-1 border-2 border-solid p-2 py-[0.38rem]  rounded-md">
                  <div>
                    <p className="font-semibold text-[#3F3F44] capitalize">
                      {state.business.business &&
                        state.business.business.business_name ? (
                        <>{state.business.business.business_name}</>
                      ) : (
                        ""
                      )}
                    </p>
                    {/* "Bella Famina" */}
                    {/* <p className="text-[#3F3F44] text-xs">
                      Joined <Moment fromNow>{state?.user?.user?.joined_at}</Moment>
                    </p> */}
                  </div>
                  <Svgs.Chevron_down className={`w-5 h-5 transition-all ${showMenuPopup ? '-rotate-180' : 'rotate-0'}`}/>
                </div>
            </div>
            {showMenuPopup &&
              <>
                <div className="absolute w-full z-10 rounded-md bg-white shadow-popup-card top-16 divide-y">
                  <Link to='/dashboard/account/setup/financial-settings'>
                    <div className="flex group items-center gap-3 px-5 py-3 cursor-pointer rounded-md hover:!bg-[#5679ff] hover:text-white" onClick={() => setShowMenuPopup(!showMenuPopup)}>
                      <Svgs.FinancialIcon className={'w-[1.5rem] h-[1rem] group-hover:fill-white group-hover:stroke-white'} />
                      <p className="text-sm">Financial Settings</p>
                    </div>

                  </Link>
                  <Link to={'/dashboard/account/setup/notification'}>
                    <div className="flex items-center group gap-3 px-5 py-3 cursor-pointer rounded-md hover:!bg-[#5679ff]  hover:text-white" onClick={() => setShowMenuPopup(!showMenuPopup)}>
                      {<Svgs.Notification_setting className={'w-[1.25rem] h-[1.25rem] group-hover:fill-white group-hover:stroke-white'} />}
                      <p className="text-sm">Notifications</p>
                    </div>
                  </Link>
                  <Link to={'/dashboard/account/setup/business-settings'}>
                    <div className="flex group items-center gap-3 px-5 py-3 cursor-pointer rounded-md hover:bg-[#5679ff]  hover:text-white" onClick={() => setShowMenuPopup(!showMenuPopup)}>
                      <Svgs.Settings_icon className={'w-[1.25rem] h-[1.25rem] group-hover:fill-white group-hover:stroke-white'} />
                      <p className="text-sm">Business Settings</p>
                    </div>
                  </Link>

                  <div onClick={() => logoutfunction()} className="flex  group items-center gap-3 px-5 py-3 cursor-pointer rounded-md hover:bg-[#5679ff]  hover:text-white">
                    <Svgs.LogoutIcon className={'w-[1rem] h-[1rem] group-hover:fill-white group-hover:stroke-white'} />
                    <p className="text-sm">Logout</p>
                  </div>
                </div>
                <div
                  className="fixed inset-0 bg-black/20 z-[1] top-0 bottom-0 left-0 right-0"
                  onClick={() => {
                    setShowMenuPopup(!showMenuPopup)
                  }}
                ></div>
              </>}
            {/* <div>
              <Svgs.Chevron_down />
            </div> */}
          </div>
        </div>
      </div>

      <div className="flex items-center flex-1 overflow-hidden">
        <div className={`${CollapsedMenu ? 'col-span-1 w-[5.6rem]' : 'col-span-2 w-[16rem]'} transition-all border-r lg:block lg:static sticky top-0 bg-white responsive_sidebar h-full overflow-auto scroll-hidden-lg block z-40`}>

          <div
            className={`xl:py-[2rem] py-[1rem] flex flex-col gap-[2rem] overflow-auto sticky top-0`}
          >

            <div className="items-center gap-4 lg:hidden flex">
              <div
                className="flex items-center gap-3 cursor-pointer flex-wrap"
                onClick={() => {
                  navigater("/");
                }}
              >
                <LogoSvg />
                <h1 className="text-primary font-semibold text-2xl">NSTYLE</h1>
              </div>
              <div
                className="cursor-pointer lg:hidden block"
                onClick={() => {
                  let responsive_sidebar = document.querySelector(
                    ".responsive_sidebar"
                  );
                  if (responsive_sidebar) {
                    responsive_sidebar.classList.toggle("hidden");
                  }
                }}
              >
                <svg
                  viewBox="0 0 24 24"
                  width="22"
                  height="22"
                  stroke="#878C93"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="css-i6dzq1"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>

            </div>



            <div className={`${CollapsedMenu ? '' : ''} pr-[1.4rem] ml-[1.4rem] flex flex-col gap-2 overflow-y-auto scroll-hidden-lg flex-1 lg:mb-0 mb-[4rem] lg:pb-0 pb-[1rem]`}>
              {(SidebarOptionShowState && sidebar_options?.length > 0) ? sidebar_options
                ?.map(({ navigate, id, text, onClick, icon }) => {
                  return (
                    <SidebarOptions
                      CollapsedMenu={CollapsedMenu}
                      navigater={navigate ? navigate : "#"}
                      active={SidebarState === id}
                      id={id}
                      key={id}
                      activeId={setSidebarState}
                      text={text}
                      onClick={onClick}
                    >
                      {icon != "" ? icon : <Svgs.Reports />}
                    </SidebarOptions>
                  );
                }) :
                <SidebarShimmer size={10} />
              }


              {/* <p>Loading ...</p> */}
            </div>
          </div>
        </div>
        <div
          className="fixed inset-0 bg-black/20 z-[1] overlay_responsive backdrop-blur-[2px]"
          onClick={() => {
            let responsive_sidebar = document.querySelector(
              ".responsive_sidebar"
            );
            if (responsive_sidebar) {
              responsive_sidebar.classList.toggle("hidden");
            }
          }}
        ></div>
        <div className={`${CollapsedMenu ? "" : ""}  flex-1 overflow-y-auto px-0 h-[100%] right_container_scroll`}>
          <Outlet />
        </div>
      </div>
    </div>
  </>
  );
};

const mapState = (state) => {
  return state;
};

export default connect(mapState, null)(Dashboard);
