import Svgs from 'Assets/svgs'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const ImageUploader = ({ name, title, imgSize, accept, id, multiple, mb_file_size, onChange, value, imgWidth, imgHeight, showImageSize }) => {
  const [image, setImage] = useState(undefined)
  useEffect(() => {
    if (value) {
      if (typeof value == 'string') {
        setImage(value)
      }
      else {
        let url = URL.createObjectURL(value)
        setImage(url)
      }
    }
  }, [value])

  return (
    <div className="flex items-center justify-start w-full sm:w-auto" key={id}>
      <label htmlFor={id ? id : 'upload_img'} class="flex items-center justify-start gap-5 px-5 sm:justify-center w-fit border-2 border-primary border-dashed rounded-lg cursor-pointer bg-[#F2F5FF] ">
        <div className="flex items-center justify-center gap-5  py-2">
          <p className='text-[#5679FF]'><Svgs.FileUpload width={'w-[2rem]'} height={'h-[2rem]'} /></p>
          <div className='flex flex-col gap-0'>
            <p className='text-base text-[#000] font-medium'>{title}</p>
            {showImageSize &&
              <p className='font-light text-sm text-[#3A3A3A]'>Image size {imgSize ? imgSize : '0 x 0'}</p>
            }
          </div>

        </div>
        <input
          type="file"
          id={id ? id : 'upload_img'}
          className='hidden'
          name={name}
          accept={accept}
          multiple={multiple}
          onChange={(e) => {
            let withInWidth = false
            if (multiple) {
              for (let fl in e.target.files) {
                let file = e.target.files[fl]
                let kb_file_size = file.size
                let file_size_mb = (kb_file_size / 1000) / 1000
                if (file_size_mb > mb_file_size) {
                  toast.info(`Maximum file size limit is ${mb_file_size} MB`, { toastId: "toast" })
                  return
                }
              }
            }
            else {
              let kb_file_size = e.target.files[0].size
              let file_size_mb = (kb_file_size / 1000) / 1000
              if (file_size_mb > mb_file_size) {
                toast.info(`Maximum file size limit is ${mb_file_size} MB`, { toastId: "toast" })
                return
              }
            }

            const file = e.target.files[0];
            if (imgWidth && imgHeight) {
              const reader = new FileReader();
              reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                  const width = img.width;
                  const height = img.height;
                  console.log(width, height, "apply")
                  if (width <= imgWidth && height <= imgHeight) {
                    onChange && onChange(e)
                    if (!multiple) {
                      let url = URL.createObjectURL(e.target.files[0])
                      setImage(url)
                    }
                  } else {
                    toast.info(`Reduce image resolution to ${imgSize} or lower`, { toastId: "toast" });
                    return;
                  }
                };
              };

              reader.readAsDataURL(file);
            } else {
              onChange && onChange(e)
              if (!multiple) {
                // let url = URL.createObjectURL(e.target.files[0])
                setImage(e.target.files[0])
              }
            }
            // Reset the input field value
            e.target.value = null;
          }}
        />
      </label>
    </div>
  )
}

ImageUploader.defaultProps = {
  onClick: () => { },
  multiple: false,
  showImageSize: true
}

export default ImageUploader