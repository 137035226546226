import axios from "axios";
import { BASE_URL, check_refund_permission_url, create_refund_url, get_refund_report_url } from "../api_variables";
import { toast } from "react-toastify";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { apiErrorMessage } from "Constants/Data/Errors";
import { get_refund_reports_data } from "Redux/TenantRedux/Actions/product";

export const checkRefundPermission = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const header = {
            headers: { Authorization: `Token ${access_token}` },
        }
        const query = buildQueryString(payload)
        const result = await axios.get(`${BASE_URL}${check_refund_permission_url}${query}`, header)
        if (result?.status == 200) {
            if (result?.data?.status_code == 404) {
                toast.error(result?.data?.response?.message, { toastId: "add_segment" })
                fail && fail()
            }
            if (result?.data?.status_code == 201) {
                success && success()
            }
        } else {
            fail && fail()
        }
    } catch (error) {
        // toast.error(apiErrorMessage, 'api-error')
        fail && fail()
    }
}

export const creatRefund = (payload, access_token, success, fail) => async (dispatch) => {
    try {
        const result = await axios.post(`${BASE_URL}${create_refund_url}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            const data = result?.data?.response?.data
            if (result?.data?.status_code == 200) {
                toast.error(result?.data?.response?.message, { toastId: "toast" })
                fail && fail()
            }

            if (result?.data?.status_code == 201) {
                // dispatch(addSegments(data));
                toast.success(result?.data?.response?.message, { toastId: "toast" })
                success && success(data)
            }
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }
}


export const get_refund_reports = (payload, access_token, success, fail) => async (dispatch) => {

    let query = '?'

    for (let dt in payload) {
        if (payload[dt]) {
            query += `${dt}=${payload[dt]}&`
        }
    }
    try {
        const response = await axios.get(`${BASE_URL}${get_refund_report_url}${query}`, {
            headers: { Authorization: `Token ${access_token}` },
        })
        if (response.status === 200) {
            success && success()
            let reportList = response?.data?.response?.data?.refund_data
            dispatch(get_refund_reports_data({
                data: reportList,
                current_page: response?.data?.current_page,
                total_pages: response?.data?.total_pages,
                count: response?.data?.count
            }));
        }
    } catch (error) {
        fail && fail()
        toast.error(apiErrorMessage, { toastId: "toast" });
        return error;
    }
};