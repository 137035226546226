

export const APPOINTMENT_TIPS = [
    { tip: 0 },
    { tip: 10 },
    { tip: 20 },
    { tip: 25 },
    { tip: 35 },
    { tip: 45 },
]

export const APPOINTMENT_STATUSES = [
    { label: 'Appointment Booked', value: 'Appointment_Booked' },
    { label: 'Arrived', value: 'Arrived' },
    { label: 'Start', value: 'Started' },
    { label: 'Finish', value: 'Finished' },
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Done', value: 'Done' },
    { label: 'Cancel', value: 'Cancel' },
]