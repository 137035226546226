import React, { useState } from "react";
import Svgs from "Assets/svgs";
import FormSteps from "Components/TenantApp/Elements/FormSteps";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import useOrderStock from "./useOrderStock";
import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";

const OrderStock = () => {
  const {
    step,
    orderStock,
    selectedProductsList, selected_location,
    isEdit, vendorsDropdownList, locationsDropdownList,
    loading, errors, deleting, searchText, openDeletePopup,
    deleteId, productListRedux, productList, productListRedux_updated,
    page_loading, productLengthError, setProductLengthError,
    currentPage, setCurrentPage, pages, lastStepError,
    translation, selectedLanguage,
    setStep, setGetProduct,
    orderStockHandleChange,
    removeSelectedProduct,
    handleOrder,
    setLoading,
    onBlurHandler,
    handleStep,
    setDeleting,
    setSearchText,
    setOpenDeletePopup,
    setDeleteId,
  } = useOrderStock();
  const { selected_product_list } = orderStock;
  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-3">
          <Svgs.Back link="/dashboard/account/inventory-operations/purchases-orders" />
          <h2 className="text-primary font-bold lg:text-3xl text-xl">
            {selectedLanguage?.name == "English" ? "Order Stock" : translation[1019]?.value ? translation[1019]?.value : "Order Stock"}
          </h2>
        </div>
        <FormSteps
          currentStep={step}
          setStep={setStep}
          active={step}
          steps={[
            selectedLanguage?.name == "English" ? "Select Vendor & Location" : translation[1024]?.value ? translation[1024]?.value : "Select Vendor & Location",
            selectedLanguage?.name == "English" ? "Select Products" : translation[1025]?.value ? translation[1025]?.value : "Select Products",
            selectedLanguage?.name == "English" ? "Add Quantity" : translation[1026]?.value ? translation[1026]?.value : "Add Quantity",
            ,
          ]}
          stepsLength="3"
        />
        {step === 1 && (
          <Step1
            translation={translation}
            selectedLanguage={selectedLanguage}
            step={step}
            setStep={setStep}
            orderStock={orderStock}
            selected_location={selected_location}
            orderStockHandleChange={orderStockHandleChange}
            vendorsDropdownList={vendorsDropdownList}
            locationsDropdownList={locationsDropdownList}
            onBlurHandler={onBlurHandler}
            handleStep={handleStep}
            errors={errors}
            setGetProduct={setGetProduct}
          />
        )}

        {step === 2 && (
          <Step2
            translation={translation}
            selectedLanguage={selectedLanguage}
            step={step}
            setStep={setStep}
            selected_product_list={selected_product_list}
            handleStep={handleStep}
            orderStock={orderStock}
            orderStockHandleChange={orderStockHandleChange}
            errors={errors}
            page_loading={page_loading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pages={pages}
            productLengthError={productLengthError}
            setProductLengthError={setProductLengthError}
            productListRedux={productListRedux}
            productList={productList}
            searchText={searchText}
            setSearchText={setSearchText}
          />
        )}

        {step === 3 && (
          <Step3
            translation={translation}
            selectedLanguage={selectedLanguage}
            step={step}
            setStep={setStep}
            orderStock={orderStock}
            orderStockHandleChange={orderStockHandleChange}
            selectedProductsList={selectedProductsList}
            removeSelectedProduct={removeSelectedProduct}
            handleOrder={handleOrder}
            loading={loading}
            setLoading={setLoading}
            deleting={deleting} setDeleting={setDeleting}
            isEdit={isEdit}
            searchText={searchText}
            setSearchText={setSearchText}
            onBlurHandler={onBlurHandler}
            errors={errors}
            openDeletePopup={openDeletePopup}
            setOpenDeletePopup={setOpenDeletePopup}
            deleteId={deleteId}
            setDeleteId={setDeleteId}
            lastStepError={lastStepError}
          />
        )}
      </div>
    </div>
  );
};

export default OrderStock;
