import React, { useEffect } from "react";
import SignupSteps from "../../../Components/NstyleApp/SignupSteps";
import AccountTypesAndSignupTypes from "../../../Components/NstyleApp/AccountTypesAndSignupTypes";
import useSearchParamsHandle from "../../../Hooks/useSearchParamsHandle";
import { SIGNUP_TYPE } from "../../../Constants/Variables";
import { useDispatch, useSelector } from "react-redux";
import { signupUserPersonalInfo } from "../../../Redux/NstyleRedux/Actions/sigupAction";
import { useNavigate, useSearchParams } from "react-router-dom";

const SignupScreen = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { allParams, searchParamsValue, getSearchParams } = useSearchParamsHandle();
  const dispatch = useDispatch()

  const info_data = useSelector(state => state.userData.personalInfo)

  let result;
  if (allParams.length > 1) {
    result =
      allParams && allParams?.find((param) => param.value === SIGNUP_TYPE);
  }

  useEffect(() => {
    const is_account_type = searchParams?.get("account_type");
    if (is_account_type) {
      // set account type state here 
      if (Object.keys(allParams).length > 0) {
        let new_prms = {}
        allParams.map(prm => {
          new_prms[prm.value] = prm.key
        })
        dispatch(
          signupUserPersonalInfo({
            ...info_data,
            ...new_prms
          })
        )
      }
    } else {
      navigate("/auth/account-type")
    }
  }, [allParams.account_type])

  return (
    <>
      {result?.value !== SIGNUP_TYPE ? (
        <AccountTypesAndSignupTypes />
      ) : (
        <SignupSteps />
      )}
    </>
  );
};

export default SignupScreen;
