import React, { useEffect, useState } from 'react'

import Svgs from 'Assets/svgs';
import { useHelper } from './helper';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';


let modules = {
  'APPOINTMENT': 'service',
  'SERVICE': 'service',
  'PRODUCT': 'retail',
}


export const DirectFlatCard = ({ selected, data, isDelete, onRemoveOffer, onSelect }) => {
  const state = useSelector(state => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  return (
    <>
      <div
        onClick={() => {
          onSelect && onSelect(data)
        }}
        className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? "border-black" : " border-[#B8C6F5]"} border rounded-md p-4 mt-1 cursor-pointer`}>
        {/* <div
            className="absolute cursor-pointer top-0 right-0 translate-x-1/2 -translate-y-1/2"
            onClick={() => { }}
          >
            {isDelete && <Svgs.CirlceClose size="20" fill="red" />}
          </div> */}
        <div className="flex justify-between items-center mb-2">
          <div className="text-[#A1A1A1] font-bold">
            {selectedLanguage?.name == "English" ? "Direct or Flat" : translation[878]?.value ? translation[878]?.value : "Direct or Flat"}
          </div>
          {onRemoveOffer && selected &&
            <button
              className="h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
              onClick={() => onRemoveOffer()}
            >
              <Svgs.Delete fill="#767A87" />
            </button>
          }
        </div>
        <div className="text-black font-semibold mb-1 w-full">{data?.promotion_name}</div>
        <div className="flex items-center gap-6">

          <div className="flex flex-col gap-2 flex-1 ">
            {data?.category_discount?.map(({ category_type, discount }, index) => {
              return (
                <div key={index} className="flex items-center justify-between gap-2">
                  <div>
                    <p className="text-[#A1A1A1] text-sm">
                      {selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group"}
                    </p>
                    <p className="text-[#101928] font-semibold">
                      {category_type}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#A1A1A1] text-sm">
                      {selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}

                    </p>
                    <p className="text-[#101928] font-semibold">{discount} %</p>
                  </div>
                </div>
              );
            })}

            {/* Date duration */}
            <div className="flex items-center justify-between gap-2">
              <div className="flex items-center gap-2">
                <p className="text-[#A1A1A1] text-sm">
                  {selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
                </p>
                <p className="text-[#101928] font-semibold">
                  {moment(data?.date_restrictions?.start_date).format('DD-MM-YYYY')}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-[#A1A1A1] text-sm">
                  {selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
                </p>
                <p className="text-[#101928] font-semibold">
                  {moment(data?.date_restrictions?.end_date).format('DD-MM-YYYY')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

const DirectFlat = ({ MODULE_TYPE, setHavDirectOrFlat, setNotFound, setProductsData, setServiceData, onSelect, discount_data, isDelete, selected, setSelServGroups, select_service_serviceGroups }) => {

  const state = useSelector(state => state)
  const dispatch = useDispatch()
  // const [requiredData, setrequiredData] = useState(undefined)
  const [discountData, setDiscountData] = useState([])

  const handle_select_appointment = (data) => {
    let services_disc = data.category_discount?.find(itm => (itm.category_type == "Service" || itm.category_type == "All"))

    if (!services_disc) { return }

    let service_grps = [
      ...select_service_serviceGroups
    ]
    service_grps.forEach(srv_grp => {
      srv_grp.services.forEach(service => {
        service?.priceservice?.forEach(loc_price => {
          loc_price.discount = services_disc.discount
          loc_price.discount_price = (loc_price.price * (100 - services_disc.discount)) / 100
        })
      })
    })
    setSelServGroups(service_grps)

    onSelect(
      [],
      false,
      {
        selected_promotion_type: data?.type,
        selected_promotion_id: data?.id,
        is_promotion_availed: true,
        selected_promotion: data,
      }
    )
  }

  const handle_select_service = (data) => {
    let services_disc = data.category_discount?.find(itm => (itm.category_type == "Service" || itm.category_type == "All"))
    let selectedServic = data?.excluded_services?.map(itm => itm.id)

    if (!services_disc) { return }

    setServiceData(prev => {
      let new_srvs = [...prev]
      if (services_disc) {
        new_srvs.forEach(itm => {
          itm.priceservice?.forEach(itm_price => {
            itm_price.discount = services_disc.discount
            itm_price.discount_price = (itm_price.price * (100 - services_disc.discount)) / 100
          })
        })
      }
      if (selectedServic?.length > 0) {
        let newResults = new_srvs?.length > 0 && new_srvs?.filter(ser => {
          if (!selectedServic?.find(s => s == ser?.id)) return true
        })
        new_srvs = newResults;
      }
      return new_srvs
    })

    dispatch({
      type: "ADD_BULK_ITEMS_TO_CART",
      payload: {
        'services': [],
        is_promotion: true,
        selected_promotion_type: data?.type,
        selected_promotion_id: data?.id,
        is_promotion_availed: true,
      }
    });
    onSelect([], false)
  }

  const handle_select_product = (data) => {
    let product_disc = data.category_discount?.find(itm => (itm.category_type == "Retail" || itm.category_type == "All"))
    let product = data?.excluded_products?.map(ep => ep.id)

    if (!product_disc) { return }

    let selected_currency_id = state.locations.locations.find(loc => loc.id == state.locations.selected_location)?.currency?.id

    setProductsData(prev => {
      let prev_prods = [...prev]
      if (product_disc) {
        prev_prods.forEach(this_prod => {
          let result = this_prod?.currency_retail_price?.find((itm) => itm?.currency == selected_currency_id)
          this_prod.discount = product_disc.discount
          this_prod.discount_price = (result?.retail_price * (100 - product_disc.discount)) / 100
        })
      }

      if (product?.length > 0) {
        let newResults = prev_prods?.length > 0 && prev_prods?.filter(prod => {
          if (!product?.find(s => s == prod?.id)) return true
        })
        prev_prods = newResults
      }
      return prev_prods
    })

    dispatch({
      type: "ADD_BULK_ITEMS_TO_CART",
      payload: {
        'services': [],
        is_promotion: true,
        selected_promotion_type: data?.type,
        selected_promotion_id: data?.id,
        is_promotion_availed: true,
      }
    });
    onSelect([], false)

  }

  const handle_select = (data) => {
    let funcs = {
      'APPOINTMENT': () => handle_select_appointment(data),
      'SERVICE': () => handle_select_service(data),
      'PRODUCT': () => handle_select_product(data),
    }
    funcs[MODULE_TYPE] && funcs[MODULE_TYPE]()
  }

  useEffect(() => {
    let category = modules[MODULE_TYPE]
    category = category.toLowerCase()

    let new_data = [...discount_data]
    new_data = new_data.filter(itm_disc => {
      let filtered_data = itm_disc?.category_discount?.filter(itm => ((itm?.category_type?.toLowerCase() == category) || itm.category_type == 'All'))
      if (filtered_data.length > 0) {
        return true
      }
      return false
    })
    if (new_data.length > 0) {
      setDiscountData(new_data)
    }
    else {
      setHavDirectOrFlat(false)
    }
  }, [])

  return (
    <>
      {
        discountData?.map((dicount, index) => {
          return (
            <DirectFlatCard
              key={index}
              selected={selected}
              data={dicount}
              isDelete={isDelete}
              onSelect={handle_select}
            />
          )
        })
      }
    </>
  );
};


DirectFlat.defaultProps = {
  discount_data: []
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = {

}


export default connect(mapStateToProps, mapDispatchToProps)(DirectFlat);