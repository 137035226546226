import axios from "axios";
import { BASE_URL, update_Group_service_url, get_sale_service_url, delete_servicegroup, create_service_url, delete_sale_service_url, get_service_employee, update_sale_service_url, create_order_url, get_group_services, delete_block_time, get_location_tax, get_group_services_optimized, get_service_optimizated, get_service_detail, get_group_services_detail, create_new_checkout } from "../api_variables";
import { toast } from 'react-toastify';
import { SALE_SERVICES } from './../../../Redux/TenantRedux/ActionTypes/saleServiceType/index';
import { apiErrorMessage } from "Constants/Data/Errors";
import { useState } from "react";
import { buildQueryString } from "Constants/Functions/buildQueryString";

export const createService = (data, success, fail) => dispatch => {
    let token = localStorage.getItem('access_token')

    let form_data = new FormData()
    for (let key in data) {
        if (data[key]) {
            if ((typeof data[key] == "object" && key != "image") && data[key].length > 0) {
                form_data.append(key, JSON.stringify(data[key]))
            } else if (key == 'image') {
                form_data.append("image", data[key]);
            } else {
                if ((typeof data[key] != "object" && key != "image")) {
                    form_data.append(key, data[key])
                }
            }
        }
    }



    if (token) {
        token = token.replaceAll('"', '')
    }


    fetch(`${BASE_URL}${create_service_url}`,
        {
            method: "POST",
            headers: {
                // 'Content-type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: form_data,
        }).then((response => {
            if (response.ok) {
                return (
                    response.json()
                )
            }
        })).then((result) => {
            if (result?.status_code === 201) {
                success && success()
                dispatch({
                    type: SALE_SERVICES.ADD_SALE_SERVICES,
                    payload: result?.response?.service
                })
            }
            else {
                fail && fail()
            }
        })
        .catch(err => {
            fail && fail()
        })

};

export const getServiceGroupCalendar = (location, setGroupLoader) => dispatch => {
    setGroupLoader && setGroupLoader(true)
    const service_location = location ? `?location_id=${location}` : "";
    // const page = pages ? location ? `&page=${pages}` : `?page=${pages}` : '';
    // const searchText = search ? `&search_text=${search}` : ''
    let s_code;
    fetch(`${BASE_URL}${get_group_services_optimized}${service_location}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let servicetList = result;
                dispatch({
                    type: SALE_SERVICES.GET_GROUP_SERVICES,
                    payload: servicetList
                })
                setGroupLoader && setGroupLoader(false)
            }
            else {
                setGroupLoader && setGroupLoader(false)
                toast.error("Error in get services", { toastId: "toast" })
            }
        })

}

export const getServiceGroup = (location, pages, search, setGroupLoader) => dispatch => {
    setGroupLoader && setGroupLoader(true)
    const service_location = location ? `?location_id=${location}` : "";
    const page = pages ? location ? `&page=${pages}` : `?page=${pages}` : '';
    const searchText = search ? `&search_text=${search}` : ''
    let s_code;
    fetch(`${BASE_URL}${get_group_services}${service_location}${page}${searchText}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let servicetList = result;
                dispatch({
                    type: SALE_SERVICES.GET_GROUP_SERVICES,
                    payload: servicetList
                })
                setGroupLoader && setGroupLoader(false)
            }
            else {
                setGroupLoader && setGroupLoader(false)
                toast.error("Error in get services", { toastId: "toast" })
            }
        })

}

export const getServiceGroupPaginationDropdown = (pages, search, setGroupLoader, location) => dispatch => {
    setGroupLoader && setGroupLoader(true)
    const page = pages
    const location_id = location
    const search_text = search
    const is_active = true
    const query = buildQueryString({ page, location_id, search_text, is_active })
    let s_code;
    fetch(`${BASE_URL}${get_group_services}${query}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                dispatch({
                    type: SALE_SERVICES.GET_GROUP_SERVICES_PAGINATION_DROPDOWN,
                    payload: result
                })
                setGroupLoader && setGroupLoader(false)
            }
            // else {
            //     setGroupLoader && setGroupLoader(false)
            //     toast.error("Error in get services", { toastId: "toast" })
            // }
        })

}

export const getSingleServiceGroupDetail = (id, setGroupLoader, success, fail) => dispatch => {
    setGroupLoader && setGroupLoader(true)
    const groupId = id ? `?service_group_id=${id}` : "";
    let s_code;
    fetch(`${BASE_URL}${get_group_services_detail}${groupId}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let servicetList = result?.response?.sales[0];
                setGroupLoader && setGroupLoader(false)
                success && success(servicetList)
            }
            else {
                setGroupLoader && setGroupLoader(false)
                toast.error("Error in get services", { toastId: "toast" })
                fail && fail()
            }
        })

}

export const getServiceGroupWithNoPagination = (search, setGroupNoPaginationLoader) => dispatch => {
    setGroupNoPaginationLoader && setGroupNoPaginationLoader(true)
    const searchText = search ? `&search_text=${search}` : ''
    let s_code;
    fetch(`${BASE_URL}${get_group_services}?no_pagination=true${searchText}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let servicetList = result;
                dispatch({
                    type: SALE_SERVICES.GET_GROUP_SERVICES_WITHOUT_PAGINATION,
                    payload: servicetList
                })
                setGroupNoPaginationLoader && setGroupNoPaginationLoader(false)
            }
            else {
                setGroupNoPaginationLoader && setGroupNoPaginationLoader(false)
                toast.error("Error in get services", { toastId: "toast" })
            }
        })

}

export const getSaleServices = (location, currentPage, search, setPageLoading) => dispatch => {
    const service_location = location ? `?location_id=${location}` : "";
    const page = currentPage ? location ? `&page=${currentPage}` : `?page=${currentPage}` : '';
    const searchText = search ? `&search_text=${search}` : ''

    setPageLoading && setPageLoading(true)
    let s_code;
    fetch(`${BASE_URL}${get_sale_service_url}${service_location}${page}${searchText}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let servicetList = result?.response?.service;
                let page_count = result?.response
                dispatch({
                    type: SALE_SERVICES.GET_SALE_SERVICES,
                    payload: servicetList
                })
                dispatch({
                    type: SALE_SERVICES.GET_PAGE_COUNT,
                    payload: page_count
                })
                setPageLoading && setPageLoading(false)
            }
            else {
                toast.error("Error in get services", { toastId: "toast" })
                setPageLoading && setPageLoading(false)
            }
        })
};

export const getSingleServiceData = (serviceID, setPageLoading, success, fail) => dispatch => {
    const id = serviceID ? `?service_id=${serviceID}` : "";

    setPageLoading && setPageLoading(true)
    let s_code;
    fetch(`${BASE_URL}${get_service_detail}${id}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let servicetList = result?.response?.service;
                setPageLoading && setPageLoading(false)
                success && success(servicetList[0])
            }
            else {
                toast.error("Error in get services", { toastId: "toast" })
                setPageLoading && setPageLoading(false)
                fail && fail()
            }
        })
};

export const getServiceOptimized = (location, currentPage, search, setLoader) => dispatch => {
    setLoader && setLoader(true)
    let s_code;
    const location_id = location ? `?location_id=${location}` : ""
    const page = currentPage ? `&page=${currentPage}` : '';
    const searchText = search ? `&search_text=${search}` : ''
    fetch(`${BASE_URL}${get_service_optimizated}${location_id}${page}${searchText}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let servicetList = result?.response?.service;
                let page_count = result?.response
                dispatch({
                    type: SALE_SERVICES.GET_SALE_SERVICES,
                    payload: servicetList
                })
                dispatch({
                    type: SALE_SERVICES.GET_PAGE_COUNT,
                    payload: page_count
                })
                setLoader && setLoader(false)
            }
            else {
                toast.error("Error in get services", { toastId: "toast" })
                setLoader && setLoader(false)
            }
        })
};

export const getSaleServicesWithNoPagination = (search, setPageLoading) => dispatch => {
    const searchText = search ? `&search_text=${search}` : ''

    setPageLoading && setPageLoading(true)
    let s_code;
    fetch(`${BASE_URL}${get_sale_service_url}?no_pagination=true${searchText}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let servicetList = result?.response?.service;
                dispatch({
                    type: SALE_SERVICES.GET_SALE_SERVICES_WITHOUT_PAGINATION,
                    payload: servicetList
                })
                setPageLoading && setPageLoading(false)
            }
            else {
                toast.error("Error in get services", { toastId: "toast" })
                setPageLoading && setPageLoading(false)
            }
        })
};


export const deleteSaleService = (data, success, fail) => dispatch => {

    let token = localStorage.getItem('access_token')
    if (token) {
        token = token.replaceAll('"', '')
    }

    let form_data = new FormData()
    form_data.append('id', data.id)

    fetch(
        BASE_URL + delete_sale_service_url,
        {
            method: 'DELETE',
            body: form_data,
            headers: {
                Authorization: `Token ${token}`
            }
        }
    )
        .then(response => {
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (result.status_code == 200) {
                success && success()
                dispatch({
                    type: SALE_SERVICES.DELETE_SALE_SERVICES,
                    payload: {
                        id: data.id
                    }
                })
            }
            else {
                fail && fail()
            }
        })
        .catch(err => {
            fail && fail()
        })
}


export const deletegroupService = (data, success, fail) => dispatch => {

    let token = localStorage.getItem('access_token')
    if (token) {
        token = token.replaceAll('"', '')
    }

    let form_data = new FormData()
    form_data.append('id', data.id)

    fetch(
        BASE_URL + delete_servicegroup,
        {
            method: 'DELETE',
            body: form_data,
            headers: {
                Authorization: `Token ${token}`
            }
        }
    )
        .then(response => {
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (result.status_code == 200) {
                success && success()
                dispatch({
                    type: SALE_SERVICES.DEL_GROUP_SERVICES,
                    payload: {
                        id: data.id
                    }
                })
            }
            else {
                fail && fail()
            }
        })
        .catch(err => {
            fail && fail()
        })
}

export const deletecalender = (data, success, fail) => dispatch => {

    let token = localStorage.getItem('access_token')
    if (token) {
        token = token.replaceAll('"', '')
    }

    let form_data = new FormData()
    form_data.append('id', data.id)

    fetch(
        BASE_URL + delete_block_time,
        {
            method: 'DELETE',
            body: form_data,
            headers: {
                Authorization: `Token ${token}`
            }
        }
    )
        .then(response => {
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (result.status_code == 200) {
                success && success()
            }
            else {
                fail && fail()
            }
        })
        .catch(err => {
            fail && fail()
        })
}

export const updateSaleService = (data, success, fail) => dispatch => {
    let s_code;
    let token = localStorage.getItem('access_token')

    if (token) {
        token = token.replaceAll('"', '')
    }

    let form_data = new FormData()

    // form_data.append(key, JSON.stringify(data[key]))
    // form_data.append("image", data[key]);
    // form_data.append(key, data[key])

    for (let key in data) {
        if (data[key]) {
            if ((typeof data[key] == "object" && key != "image") && data[key].length > 0) {
                form_data.append(key, JSON.stringify(data[key]))
            } else if (key == 'image') {
                form_data.append("image", data[key]);
            } else {
                if ((typeof data[key] != "object" && key != "image")) {
                    form_data.append(key, data[key])
                }
            }
        }
    }

    // for (let key in data) {
    //     if (data.is_package) {
    //         if (key != "service_type" && data[key]) {

    //             form_data = { ...form_data, [key]: data[key] }
    //         }
    //     } else {
    //         if (key != "service" && data[key]) {
    //             if (typeof data[key] == "object" && key != 'image') {
    //                 form_data = { ...form_data, [key]: JSON.stringify(data[key]) }
    //             }
    //             form_data = { ...form_data, [key]: data[key] }
    //         } else {
    //             if (key === "service") {
    //                 form_data = { ...form_data, service: [] }
    //             }
    //         }
    //     }
    // }
    fetch(BASE_URL + update_sale_service_url, {
        method: 'PUT',
        headers: {
            // 'Content-type': 'application/json',
            'Authorization': `Token ${token}`
        },
        body: form_data,
    }).then(response => {
        if (response.ok) {
            return response.json()
        }
    }).then(result => {
        if (result.status_code == 200) {
            success && success()
            dispatch({
                type: SALE_SERVICES.UPDATE_SALE_SERVICES,
                payload: result?.response?.service,
            })
        }
        else {
            fail && fail()
        }
    }).catch(err => {
        fail && fail()
    })
}

export const updateGroupService = (data, success, fail) => dispatch => {
    let s_code;
    let token = localStorage.getItem('access_token')

    if (token) {
        token = token.replaceAll('"', '')
    }

    let form_data = new FormData();

    for (let dt in data) {
        if (data[dt]) {
            if (typeof data[dt] == "object" && dt != 'image') {
                form_data.append(dt, JSON.stringify(data[dt]))
            } else {
                form_data.append(dt, data[dt])
            }
        }
    }
    fetch(BASE_URL + update_Group_service_url, {
        method: 'PUT',
        headers: {
            'Authorization': `Token ${token}`
        },
        body: form_data,
    }
    ).then(response => {
        if (response.ok) {
            return response.json()
        }
    }).then(result => {
        if (result.status_code == 200) {
            success && success(result?.response?.message)
            dispatch({
                type: SALE_SERVICES.UPDATE_GROUP_SERVICES,
                payload: result?.response?.service,
            })
        }
        else {
            fail && fail()
        }
    }).catch(err => {
        fail && fail()
    })
}

export const CreateSaleOrder = (data, success, fail) => dispatch => {
    let s_code;

    let token = localStorage.getItem('access_token')

    if (token) {
        token = token.replaceAll('"', '')
    }

    let form_data = new FormData();
    for (let key in data) {

        if (data[key]) {
            if (key == 'ids') {
                form_data.append(key, JSON.stringify(data[key]))
            }
            else if (key == "membership_product") {
                form_data.append(key, JSON.stringify(data[key]))
            }
            else if (key == "membership_service") {
                form_data.append(key, JSON.stringify(data[key]))
            }
            else if (key == 'tip') {
                form_data.append(key, JSON.stringify(data[key]))
            } else {
                form_data.append(key, data[key])
            }
        }
    }

    fetch(
        BASE_URL + create_order_url,
        {
            method: 'POST',
            headers: {
                Authorization: `Token ${token}`
            },
            body: form_data
        }
    ).then(response => {
        s_code = response.status
        if (response.ok) {
            return response.json()
        }
    })
        .then(result => {
            if (s_code == 201) {
                toast.success('Order payment successful', { toastId: "toast" })
                let sale = result?.response?.sale;
                success && success()
            }
            else {
                fail && fail(result.response.message)
            }
        })
        .catch(err => {
            fail && fail(apiErrorMessage)
        })
}

export const CreateNewCheckout = (data, success, fail) => (dispatch) => {
    let s_code;

    let token = localStorage.getItem('access_token')
    if (token) { token = token.replaceAll('"', '') }

    // let form_data = new FormData();

    // for (let key in data) {
    //     if (data[key]) {
    //         if (typeof data[key] == 'object') {
    //             form_data.append(key, JSON.stringify(data[key]))
    //         } else {
    //             form_data.append(key, data[key])
    //         }
    //     }
    // }

    fetch(BASE_URL + create_new_checkout, {
        method: 'POST',
        headers: { Authorization: `Token ${token}`, 'Content-Type': "application/json" },
        body: JSON.stringify(data)
    }).then(response => {
        s_code = response.status
        if (response.ok) {
            return response.json()
        }
    }).then(result => {
        if (s_code == 201) {
            toast.success(result?.response?.message, { toastId: "toast" })
            // console.log(result, "res")
            let sale = result?.response?.sale;
            success && success(result?.response?.data?.checkout)
        } else {
            fail && fail(result.response.message)
        }
    }).catch(err => {
        fail && fail(apiErrorMessage)
    })
}

export const getServiceEmployee = async (id) => {
    let result = await fetch(`${BASE_URL}${get_service_employee}?service=${id}`)
    result = result.json();
    return result;
};

export const getLocationTax = (id) => dispatch => {
    let s_code;
    fetch(`${BASE_URL}${get_location_tax}?location_id=${id}`)
        .then(response => {
            if (response.ok) {
                s_code = 200;
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let locationTax = result?.response?.tax;
                dispatch({
                    type: SALE_SERVICES.GET_LOCATION_TAX,
                    payload: locationTax
                })
            }
            else {
                toast.error(apiErrorMessage, { toastId: "toast" })
            }
        })

}
