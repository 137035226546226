/* eslint-disable array-callback-return */
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import useValidations from "Hooks/useValidations";
import { add_user_restricted_discount, get_client_discount_promotion, update_user_restricted_discount } from "Adapters/Api/clientDiscountPromotion";
import { getSaleServices, getServiceGroup } from "Adapters/Api/saleServices";
import { get_business_brands } from "Adapters/Api/Brand";
import { discountErrors, navigateToDiscountPromotion } from "../../helper";
import { getsProducts, getsProductsDropdownList } from "Adapters/Api/Products";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import { getClient, getClientDropDownList } from "Adapters/Api/clients";
import { useRef } from "react";
import useObjectKeys from "Hooks/useObjectKeys";

const initialState = {
  corporateType: '',
  promotion_name: '',
  discountPercentage: '',

  serviceRestriction: '',
  serviceRestrictions: [],

  brandRestriction: '',
  brandRestrictions: [],

  user: '',
  users: [],
  dateRestriction: { startDate: '', endDate: '' },
  blockDate: '',
  blockDates: [],
  storeRestriction: '',
  storeRestrictions: [],
  dayRestrictions: [
    { day: 'Monday', value: '', is_deleted: 'False' },
    { day: 'Tuesday', value: '', is_deleted: 'False' },
    { day: 'Wednesday', value: '', is_deleted: 'False' },
    { day: 'Thursday', value: '', is_deleted: 'False' },
    { day: 'Friday', value: '', is_deleted: 'False' },
    { day: 'Saturday', value: '', is_deleted: 'False' },
    { day: 'Sunday', value: '', is_deleted: 'False' },
  ]
}
export const CORPORATE_CHOICE = {
  All_Service: 'All Service',
  Retail_Product: 'Retail Product'
}

export const useAddDiscount = () => {
  const formRef = useRef()
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { setErrors, errors, onBlurHandler, validation } = useValidations();
  const { objectKeyConvertToArray } = useObjectKeys()
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const dispatch = useDispatch();
  const businessId = state?.business?.business?.id;
  const [isEdit, setIsEdit] = useState(false);
  const [toggleStore, setToggleStore] = useState(false);
  const [toggleblockdate, setToggleBlockDate] = useState(false);
  const [toggleDay, setToggleDay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [discount, setDiscount] = useState(initialState);
  const locations = state?.locations?.locations;
  const params = useParams();
  const clientRedux = useSelector((state) => state?.client?.clients);
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const servicesRedux = state?.saleService?.saleServices;
  const productRedux = state?.product?.products;

  const [serviceToggle, setServiceToggle] = useState(false)
  const [brandToggle, setBrandToggle] = useState(false)

  const {
    blockDates,
    blockDate,
    storeRestrictions,
  } = discount;

  const get_products = async () => {
    const response = await getsProductsDropdownList();

    if (response.status === 200) {
      let productList = response?.data?.response?.products;
      dispatch(getProducts(productList));
    }
  };
  useEffect(() => {
    get_products();
    dispatch(getSaleServices());
    dispatch(getClientDropDownList())
    dispatch(getServiceGroup());
    dispatch(get_business_brands());
  }, [])

  useEffect(() => {
    if (params.id === undefined) {
      setIsEdit(false);
    }
    if (state?.clientDiscountPromotion?.discountPromotions?.length === 0 && params.id) {
      dispatch(get_client_discount_promotion())
      setIsEdit(true);
    }
    if (params.id) {
      setIsEdit(true);
    }
  }, [params.id, state?.clientDiscountPromotion?.discountPromotions?.length]);

  useEffect(() => {
    let editData = state?.clientDiscountPromotion?.discountPromotions?.find((obj) => obj.id === params.id);
    if (editData) {
      let { id, date_restrictions, block_date, day_restrictions, corporate_type, client, discount_percentage, promotion_name, excluded_products, excluded_services, } = editData;
      let { start_date, end_date, business_address } = date_restrictions;
      let dayRestrictions = initialState?.dayRestrictions?.map((item) => {
        let obj = day_restrictions.find(obj => obj.day === item.day);
        if (obj?.day === item.day) {
          return {
            id: obj?.id,
            day: obj?.day,
            value: obj?.day,
            is_deleted: obj?.is_deleted
          }
        } else {
          return { ...item }
        }
      })

      day_restrictions?.length > 0 && setToggleDay(true);
      business_address?.length > 0 && setToggleStore(true);

      excluded_services?.length > 0 && setServiceToggle(true)
      excluded_products?.length > 0 && setBrandToggle(true)

      if (editData && dayRestrictions) {
        setDiscount({
          ...discount,
          id: id,
          corporateType: corporate_type,
          discountPercentage: discount_percentage,
          users: client,
          promotion_name: promotion_name,
          dateRestriction: { startDate: start_date, endDate: end_date },
          blockDates: block_date,
          storeRestrictions: business_address,
          dayRestrictions: dayRestrictions,

          serviceRestrictions: excluded_services.map(itm => itm.id),
          brandRestrictions: excluded_products.map(itm => itm.id),
        })
      }

    }
  }, [isEdit, state?.clientDiscountPromotion?.discountPromotions?.length])


  let dateRangeCheck = moment(blockDate).valueOf() >= moment(discount.dateRestriction.startDate).valueOf() && moment(blockDate).valueOf() <= moment(discount.dateRestriction.endDate).valueOf()
  let validateBlockDate = discount?.blockDates?.find(obj => obj.date === blockDate)
  const handleChange = (e) => {
    const { value, name } = e.target;
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    if (name === 'startDate' || name === 'endDate') {
      if (name === 'startDate') {
        setDiscount((prev) => ({
          ...prev,
          dateRestriction: { ...prev.dateRestriction, endDate: "" }
        }));
      }
      setDiscount((prev) => ({
        ...prev,
        dateRestriction: { ...prev?.dateRestriction, [name]: value }
      }));
    }
    if (name === 'blockDate') {
      let validateBlock = discount?.blockDates?.find(obj => obj.date === value)

      let dateRangeCheck = moment(value).valueOf() >= moment(discount.dateRestriction.startDate).valueOf() && moment(value).valueOf() <= moment(discount.dateRestriction.endDate).valueOf()
      if (!dateRangeCheck) {
        setErrors((prev) => ({
          ...prev,
          [name]: discountErrors.dateRange,
        }));
      }

      if (dateRangeCheck) {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
      if (validateBlock) {
        setErrors((prev) => ({
          ...prev,
          [name]: discountErrors.dateAlreadySelected,
        }));
      }
      setDiscount((prev) => ({
        ...prev,
        [name]: value
      }));
    }
    else {
      setDiscount((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  }
  const addBlockDates = () => {
    setDiscount((prev) => ({
      ...prev,
      blockDates: [...prev.blockDates, { date: discount.blockDate, is_deleted: 'False' }],
      blockDate: ''
    }))
  }

  const filterBlockDate = (id, index) => {
    if (id) {
      var filter = blockDates?.map((obj) => {
        if (obj === blockDates[index]) {
          return {
            ...obj,
            is_deleted: 'True'
          }
        }
        else {
          return { ...obj }
        }
      })
      setDiscount((prev) => ({
        ...prev,
        blockDates: filter,
      }))
    }
    else {
      var filter = blockDates?.filter((obj) => obj != blockDates[index])
      setDiscount((prev) => ({
        ...prev,
        blockDates: filter,
      }))
    }
  }
  const addClients = (e) => {
    const { value } = e.target;

    setDiscount((prev) => ({
      ...prev,
      users: [...prev.users, value],
      user: ''
    }))
  }
  const filterClient = (id) => {
    let filter = discount.users.filter((obj) => obj !== id)
    setDiscount((prev) => ({
      ...prev,
      users: filter
    }))
  }
  const addStoreRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      storeRestrictions: [...prev.storeRestrictions, discount.storeRestriction],
      storeRestriction: ''
    }))
  }
  const filterStoreRestriction = (index) => {
    var filter = storeRestrictions?.filter(obj => obj !== storeRestrictions[index])
    setDiscount((prev) => ({
      ...prev,
      storeRestrictions: filter,
    }))
  }
  const handleDayChecklist = (e) => {
    setDiscount((prev) => ({
      ...prev,
      dayRestrictions: discount?.dayRestrictions?.map(obj => {
        // handle checklist withoutd ids 
        // check function
        if (obj.day === e.target.name && obj.value === '' && obj.is_deleted === 'False') {
          return {
            ...obj,
            value: e.target.name
          }
        }
        // UN Check block
        if (obj.value === e.target.value && !obj.id) {
          return {
            ...obj,
            value: ''
          }
        }

        // handling update scenarios for checklist
        // un check block for checked
        if (obj.id && obj.value === e.target.value && obj.is_deleted === 'False') {
          return {
            ...obj,
            is_deleted: 'True',
            value: ''
          }
        }

        // checked block and isDeleted 
        if (obj.day === e.target.name && obj.value === '' && obj.is_deleted && obj.id) {
          return {
            ...obj,
            value: e.target.name,
            is_deleted: 'False'
          }
        }
        else {
          return obj
        }
      })
    }))
  }

  const addBrandRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      brandRestrictions: [...prev.brandRestrictions, discount.brandRestriction],
      brandRestriction: ''
    }))
  }
  const addServiceRestriction = () => {
    setDiscount((prev) => ({
      ...prev,
      serviceRestrictions: [...prev.serviceRestrictions, discount.serviceRestriction],
      serviceRestriction: ''
    }))
  }


  const locationDropdown = useMemo(() => {
    return locations?.map((obj) => {
      if (obj) {
        return {
          label: obj?.address_name,
          value: obj?.id
        }
      }
    })
  }, [locations.length])

  const clientDropdown = useMemo(() => {
    return clientRedux?.map((clnt, i) => {
      if (clnt) {
        return {
          label: clnt.full_name,
          value: clnt.id,
          otherData: {
            image: clnt?.image,
            DataOne: clnt.email,
            showImage: true,
            index: i
          },
          email: clnt.email,
          mobile_number: clnt.mobile_number
        }
      }
    })
  }, [clientRedux])

  const serviceExcludedDropdown = useMemo(() => {
    // let newArr = []
    // newArr = servicesRedux?.filter(item => item?.service_group?.find(tm => tm.id === discount?.specificDiscount?.service))

    return servicesRedux?.map((obj) => {
      if (obj)
        return {
          label: obj.name,
          value: obj.id
        }
    })
  }, [discount.specificDiscount, servicesRedux, servicesRedux?.length])

  const productExcludedDropdown = useMemo(() => {
    // let newArr = []
    //   newArr = productRedux?.filter(item => item?.brand?.id === discount?.specificDiscount?.brand)

    return productRedux?.map((obj) => {
      return {
        label: obj.name,
        value: obj.id
      }
    })
  }, [discount.specificDiscount, productRedux, productRedux?.length])



  const getServiceName = (id, data) => {
    return data.find((obj) => obj.value === id)?.label;
  }

  let success = () => {
    setLoading(false);
    setDiscount(initialState);
    navigateToDiscountPromotion(navigate)
    setToggleDay(false);
    setToggleStore(false);
    setToggleBlockDate(false)
  }
  let fail = () => {
    setLoading(false);
  }

  const onSubmit = () => {
    let requiredFields = {}
    if (discount.users?.length == 0) {
      requiredFields = {
        promotion_name: discount.promotion_name,
        startDate: discount.dateRestriction.startDate,
        endDate: discount.dateRestriction.endDate,
        corporateType: discount.corporateType,
        discountPercentage: discount.discountPercentage,
        user: discount.user
      }
    } else {
      requiredFields = {
        promotion_name: discount.promotion_name,
        startDate: discount.dateRestriction.startDate,
        endDate: discount.dateRestriction.endDate,
        corporateType: discount.corporateType,
        discountPercentage: discount.discountPercentage,
      }
    }
    setErrors(validation(requiredFields));
    let result = validation(requiredFields);

    if (objectKeyConvertToArray(result)?.length === 0) {
      setLoading(true);
      let products = [];
      let services = [];

      if (discount.corporateType === 'All Service')
        services = discount.serviceRestrictions

      if (discount.corporateType === 'Retail Product')
        products = discount.brandRestrictions

      let payload = {
        business: businessId,
        client: discount.users,
        corporate_type: discount.corporateType,
        discount_percentage: discount.discountPercentage,
        start_date: discount.dateRestriction.startDate,
        end_date: discount.dateRestriction.endDate,
        blockdate: discount?.blockDates,
        services: services,
        product: products,
        location: discount.storeRestrictions,
        promotion_name: discount.promotion_name,
      }

      if (isEdit) {
        if (payload) {
          payload.id = discount.id;
          payload.dayrestrictions = discount?.dayRestrictions?.filter(obj => obj.value || obj.is_deleted === "True");
          dispatch(update_user_restricted_discount(payload, access_token, success, fail))
        }
      } else {
        if (payload) {
          payload.dayrestrictions = discount?.dayRestrictions?.filter(obj => obj.value)?.map(obj => { return { day: obj.value } });
          dispatch(add_user_restricted_discount(payload, access_token, success, fail))
        }
      }
    } else {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }

  return {
    serviceExcludedDropdown, productExcludedDropdown, errors, toggleDay, toggleStore, discount, clientDropdown, loading, locationDropdown,
    isEdit, dateRangeCheck, validateBlockDate, setDiscount, addClients, getServiceName, filterClient, handleChange, onBlurHandler, navigate,
    addStoreRestriction, filterStoreRestriction, onSubmit, addBlockDates, filterBlockDate, setToggleDay, setToggleStore, handleDayChecklist,
    toggleblockdate, setToggleBlockDate, serviceToggle, setServiceToggle, brandToggle, setBrandToggle, addBrandRestriction,
    addServiceRestriction, translation, selectedLanguage, formRef

  }
};