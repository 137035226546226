import Svgs from 'Assets/svgs'
import React from 'react'

const ThumnailCard = ({ url, text, title, onDelete, onThumbnailClick, isThumbnail, showThumbnailSection }) => {
  return (
    <div className='w-fit h-[4.6875rem] flex justify-between px-2 items-center border border-[#E2E2E2] rounded-lg relative'>
      {url ? <img className='h-[3.75rem] w-[3.75rem] rounded-lg' src={url} alt={title ? title : "img"} /> : <img className='h-[3.75rem] w-[3.75rem] rounded-lg' src="https://media.istockphoto.com/id/184266033/photo/forest.jpg?s=612x612&w=0&k=20&c=NxnOuxGeg2IPP9qz9bpTkcQaHufGnGBmUOXPllKJ4Lk=" alt="img" />}
      {/* {showThumbnailSection &&
        <div className={`rounded-full text-sm px-5 py-2 sm:p-1 sm:px-2 border ${isThumbnail ? 'border-[#5679FF] bg-[#F2F5FF] text-black' : 'border-[#D1D1D1] bg-[#F8F8F8] text-[#3A3A3A]'} cursor-pointer`} onClick={onThumbnailClick}>{text ? text : "Set as Thumnail"}</div>
      } */}
      <div className='cursor-pointer p-[0.4rem] flex justify-center items-center w-[1.275rem] h-[1.275rem] bg-[#FFE8E1] rounded-full absolute -top-[0.375rem] -right-[0.375rem]' onClick={onDelete}>
        <Svgs.Close fill={"#FF5722"} />
      </div>
    </div>
  )
}

ThumnailCard.defaultProps = {
  showThumbnailSection: true
}

export default ThumnailCard