import React from 'react'
import moment from "moment";
import { useSelector } from 'react-redux';
import StatusBtn from 'Components/TenantApp/Elements/StatusBtn';


export const ClientCard = ({ name, price, created_at, sale_by, description, dt, dy, sub_price, qnty, type, status, expiry }) => {
    const state = useSelector((state) => {
        return state;
    });

    const location_currency = state?.utility?.currency?.location_currency;
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    return (
        <div className='border-b'>

            <div className='flex items-center gap-3 pt-4 pb-3'>
                <div>
                    <div className='bg-[#E0EBFD] rounded-lg text-center p-4'>
                        <h1 className='font-semibold text-lg'>{dy ? dy : 0}</h1>
                        <p className='text-[#A1A1A1] text-sm'>
                            {moment(dt).format("MMM")}
                        </p>
                    </div>
                </div>
                <div className='flex flex-col flex-1'>
                    <p className='text-[#A1A1A1] text-xs'>
                        <p className='text-[#A1A1A1] text-xs'>
                            {moment(created_at).format('ddd HH:mm')}
                        </p>
                    </p>
                    <div className='flex items-center justify-between gap-4'>
                        <h1 className='text-primary font-semibold'>{name}</h1>
                        <h1 className='text-primary font-semibold'> {parseFloat(parseFloat(price)?.toFixed(2))} {location_currency}</h1>
                    </div>

                    {(sub_price && qnty) && <p className='text-[#A1A1A1] text-sm'> {sub_price && sub_price} X {qnty && qnty}</p>}
                    {type && <p className='text-[#A1A1A1] text-sm'> {type && type}</p>}
                    <div className='flex items-center justify-between gap-4'>
                        <p className='text-[#A1A1A1] flex text-sm gap-1'>
                            {selectedLanguage?.name == "English" ? "Sale by:" : translation[153]?.value ? translation[153]?.value : "Sale by:"}
                            <span className='text-primary'>{sale_by}</span>
                        </p>
                        {status && <StatusBtn status={status} size={'xs'} />}
                    </div>
                    <p className='text-[#A1A1A1] text-sm'>{description ? description : ""} </p>
                    {expiry && <p className='text-[#A1A1A1] flex text-sm gap-1'>
                        {selectedLanguage?.name == "English" ? "Expiry" : translation[203]?.value ? translation[203]?.value : "Expiry"}:{' '}
                        <span className='text-primary'>{moment(expiry)?.format('YYYY-MM-DD')}</span>
                    </p>}
                </div>
            </div>

        </div>
    )
}
