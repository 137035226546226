import Svgs from "Assets/svgs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


export const SpecificBrandServiceGroup_Card = ({ onSelect, data, isDelete, selected, onRemoveOffer }) => {
  const state = useSelector(state => state)

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  return (
    <div
      className={`bg-[#F2F5FF] relative ${selected?.id === data?.id ? "border-black" : " border-[#B8C6F5]"
        }  border   rounded-md p-4 mt-1 cursor-pointer`}
      onClick={() => {
        onSelect && onSelect(data)
      }}
    >
      <div
        className="absolute cursor-pointer top-0 right-0 translate-x-1/2 -translate-y-1/2"
      >
        {isDelete && <Svgs.CirlceClose size="20" fill="red" />}
      </div>
      <div className="flex justify-between items-center mb-2">

        <div className="text-[#A1A1A1] font-bold">
          {selectedLanguage?.name == "English" ? "Specific Group of Category" : translation[1207]?.value ? translation[1207]?.value : "Specific Group of Category"}
        </div>
        {onRemoveOffer && selected &&
          <button
            className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
            onClick={onRemoveOffer}
          >
            <Svgs.Delete fill="#767A87" />
          </button>
        }
      </div>
      <div className="flex flex-col items-center gap-2">
        <div className="text-black font-semibold mb-1 w-full">{data?.promotion_name}</div>

        <div className="flex flex-col gap-2 flex-1 w-full">
          <div className="flex items-center justify-between gap-2">
            <div>
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group"}
              </p>
            </div>
            <div>
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}
              </p>
            </div>
          </div>
          {
            data?.servicegroup_discount?.map((srv_grp, index) => {
              return (
                <div key={index} className="flex items-center justify-between gap-2">
                  <div>
                    <p className="text-[#101928] font-semibold">{srv_grp?.servicegroup?.name}</p>
                  </div>
                  <div>
                    <p className="text-[#101928] font-semibold">{srv_grp?.discount} %</p>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="flex flex-col gap-2 flex-1 w-full">
          <div className="flex items-center justify-between gap-2">
            <div>
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Brand" : translation[292]?.value ? translation[292]?.value : "Brand"}
              </p>
            </div>
            <div>
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Discount" : translation[221]?.value ? translation[221]?.value : "Discount"}
              </p>
            </div>
          </div>
          {
            data?.servicegroup_discount?.map((srv_grp, index) => {
              return (
                <div key={index} className="flex items-center justify-between gap-2">
                  <div>
                    <p className="text-[#101928] font-semibold">{srv_grp?.brand?.name}</p>
                  </div>
                  <div>
                    <p className="text-[#101928] font-semibold">{srv_grp?.brand_discount} %</p>
                  </div>
                </div>
              )
            })
          }
          {/* Date duration */}
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
              </p>
              <p className="text-[#101928] font-semibold">
                {moment(data?.date_restrictions?.start_date).format('DD-MM-YYYY')}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
              </p>
              <p className="text-[#101928] font-semibold">
                {moment(data?.date_restrictions?.end_date).format('DD-MM-YYYY')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



export const SpecificGroup = ({ setNotFound, MODULE_TYPE, setServiceData, onSelect, specific_group, setSelServGroups, setProductsData, select_service_serviceGroups, servicesRedux, appointmentData }) => {
  const service_groups = useSelector(state => state.saleService.servicesgroup)
  const dispatch = useDispatch()
  const state = useSelector(state => state)

  const [Specific_group_data, setSpecificGroupData] = useState([])

  const handle_select_appointmet = (data, index) => {
    let this_groups = data?.servicegroup_discount?.map(grp => ({ ...grp?.servicegroup, discount: grp.discount }))
    this_groups.forEach(service_grp => {
      service_grp?.services?.forEach(service => {
        service?.priceservice?.forEach(this_price => {
          this_price.discount_price = (this_price?.price * (100 - service_grp?.discount)) / 100
        })
      })
    })
    setSelServGroups(this_groups)
    onSelect(
      [],
      false,
      {
        selected_promotion_type: data?.type,
        selected_promotion_id: data?.id,
        is_promotion_availed: true,
        selected_promotion: data,
      }
    )
  }

  const handle_select_service = (data) => {
    let groups = data?.servicegroup_discount?.map(group => ({ ...group.servicegroup, discount: group.discount }))
    let selectedServic = data?.excluded_services?.map(itm => itm.id)

    setServiceData(prev => {
      let new_services = []
      groups.forEach(grp => {
        grp.services?.forEach((service) => {
          let this_service = prev?.find(itm => itm.id == service.id)
          if (this_service) {
            new_services.push({
              ...this_service,
              priceservice: this_service?.priceservice.map(itm_price => {
                return {
                  ...itm_price,
                  discount_price: (itm_price.price * (100 - grp?.discount)) / 100,
                  discount: grp?.discount,
                }
              })
            })
          }
        })
      })
      if (selectedServic?.length > 0) {
        let newResults = new_services?.length > 0 && new_services?.filter(ser => {
          if (!selectedServic?.find(s => s == ser?.id)) return true
        })
        new_services = newResults;
      }
      return new_services
    })
    dispatch({
      type: "ADD_BULK_ITEMS_TO_CART",
      payload: {
        'services': [],
        is_promotion: true,
        selected_promotion_type: data?.type,
        selected_promotion_id: data?.id,
        is_promotion_availed: true,
      }
    });
    onSelect(
      [],
      false,
    )
  }

  const handle_select_product = (data) => {
    let services_disc = data.servicegroup_discount?.find(itm => itm)
    let selected_currency_id = state.locations.locations.find(loc => loc.id == state.locations.selected_location)?.currency?.id

    let product = data.excluded_products?.map(ep => ep.id)

    setProductsData(prev => {
      let this_brand_products = prev.filter(obj => obj.brand.id == services_disc?.brand?.id)

      this_brand_products.forEach(this_prod => {
        let result = this_prod?.currency_retail_price?.find((itm) => itm?.currency === selected_currency_id)
        this_prod.discount = services_disc?.brand_discount
        this_prod.discount_price = (result?.retail_price * (100 - services_disc.brand_discount)) / 100
      })

      if (product?.length > 0) {
        let newResults = this_brand_products?.length > 0 && this_brand_products?.filter(ser => {
          if (!product?.find(s => s == ser?.id)) return true
        })
        this_brand_products = newResults
      }
      return this_brand_products
    })

    dispatch({
      type: "ADD_BULK_ITEMS_TO_CART",
      payload: {
        'services': [],
        is_promotion: true,
        selected_promotion_type: data?.type,
        selected_promotion_id: data?.id,
        is_promotion_availed: true,
      }
    });
    onSelect([], false)

  }

  const handle_select = (data, index) => {
    let funcs = {
      'APPOINTMENT': () => handle_select_appointmet(data),
      'SERVICE': () => handle_select_service(data),
      'PRODUCT': () => handle_select_product(data),
    }
    funcs[MODULE_TYPE] && funcs[MODULE_TYPE]()
  }

  useEffect(() => {
    let new_data = [
      ...specific_group
    ]

    new_data.forEach((spc_grp) => {
      spc_grp.servicegroup_discount?.forEach(grp => {
        let this_group = service_groups.find(itm => itm.id == grp?.servicegroup)
        if (this_group) {
          grp.servicegroup = this_group
        }
      })
    })

    setSpecificGroupData(new_data)
  }, [])
  return (
    <>
      {
        Specific_group_data && Specific_group_data?.map((itm, index) => {
          return (
            <SpecificBrandServiceGroup_Card
              key={index}
              data={itm}
              isDelete={false}
              selected={false}
              onSelect={(data) => handle_select(data, index)}
            />
          )
        })
      }
    </>
  );
};