import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Svgs from '../../../../../../Assets/svgs'
import LoginBtn from '../../../../Elements/LoginBtn'
import LoginInput from '../../../../Elements/LoginInput'
import Popup from '../../../../Elements/Popup'
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { getAllCategories, getAllCategoriesWithNoPagination, getCategoryImport } from '../../../../../../Adapters/Api/categories'
import AddCategoryBtn from '../AddHeaderBtns/AddCategoryBtn'
import CategoryCard from './CategoryCard'
import { toast } from 'react-toastify'
import moment from 'moment'
import IconButton from 'Components/TenantApp/Elements/IconButton'
import Pagination from 'new-components/Pagination'
import PreloaderSm from 'Components/TenantApp/Elements/PreloaderSm'
import NoCategoriesFound from 'Components/Utility/NoCategoriesFound'
import TableShimmer_v2 from 'Components/Utility/Shimmers/TableShimmer_v2'
import useCategory from './useCategory'
import Shimmer from 'Components/Utility/Shimmers/Shimmer'
import EmployeesHeadShimmer from 'Components/Utility/Shimmers/EmployeesHeadShimmer'

// import PreloaderFull from './../../../../Elements/PreloaderFull';
// import NotFound from 'Components/TenantApp/Dashboard/Sales/Elements/NotFound'
// import CircleIcon from '../../../../Elements/CircleIcon'
// import Dropwdown from '../../../../Elements/Dropwdown'
// import { getCategories } from "../../../../../../Redux/TenantRedux/Actions/categoryActions";
// import StatusBtn from '../../../../Elements/StatusBtn'
// import Moment from 'react-moment'
// import AddProductBtn from '../AddHeaderBtns/AddProductBtn'

let timeOutId = undefined;

const Category = () => {
  const { currentPage, setCurrentPage, loader, setLoader, noPaginationLoader, setNoPaginationLoader, search_text,
    setSearchText } = useCategory()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const categoryRedux = state?.category
  const pages = categoryRedux?.pages
  const allCount = categoryRedux?.allCount
  const categories_updated = state?.category?.categories_updated
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [Importcategory, setImportCategory] = useState(false);
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const [isToastVisible, setIsToastVisible] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  // const [ShowDetails, setShowDetails] = useState(0)


  // const { AddCategory,
  //   categoryData, categoryModalToggler,
  //   categoryHandleChange, onCategorySumbit,
  //   onBlurHandler, errors,
  //   categoryDeletehandler, categoryEdithandler,
  //   setIsEdit, loading
  // } = useCategory();



  useEffect(() => {
    if (search_text === null) {
      // Initial load with empty search_text
      dispatch(getAllCategories(currentPage, "", setLoader))
      dispatch(getAllCategoriesWithNoPagination("", setNoPaginationLoader))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(getAllCategories(currentPage, search_text, setLoader))
        dispatch(getAllCategoriesWithNoPagination(search_text, setNoPaginationLoader))
      }, 500);
    }
  }, [currentPage, search_text])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  const submit = (file) => {
    let form_data = new FormData()
    form_data.append('file', file)
    getCategoryImport(form_data, () => {
      setCurrentPage(1)
      dispatch(getAllCategories(1, search_text, setLoader))
      dispatch(getAllCategoriesWithNoPagination(search_text, setNoPaginationLoader))
    });
  }

  let headers = [
    { label: selectedLanguage?.name == "English" ? "Category Name" : translation[892]?.value ? translation[892]?.value : "Category Name", key: 'name' },
    { label: selectedLanguage?.name == "English" ? 'Create at' : translation[914]?.value ? translation[914]?.value : "Create at", key: 'created_at' },
    { label: selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status", key: 'is_active' },
  ]

  const csvReport = {
    filename: "Product Categories List.csv",
    headers: headers,
    data: categoryRedux.all_categories?.map(itm => {
      return {
        ...itm,
        name: itm?.name ? itm?.name : '--------',
        created_at: itm?.created_at ? moment(itm?.created_at).format("dddd,  DD MMM YYYY ") : '--------',
        is_active: itm?.is_active ?
          selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
          : selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
      }
    })
  }

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (categoryRedux.all_categories?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  const PageSize = 10
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <p className="text-[#7B7B7B] text-sm">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/");
            }}
          >
            {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          </span>{" "}
          {' '} &gt; {' '}{" "}
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/inventory-operations");
            }}
          >
            {selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations"}
          </span>
          {" "}{' '} &gt; {' '}
          <span className="text-primary font-semibold">
            {selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}
          </span>
        </p>
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}
            </h2>
          </div>
          {loadingPermission ?
            <Shimmer className={'w-44 lg:w-48'}>
              <div className="h-8 w-full  bg-gray-200 rounded-lg" />
            </Shimmer>
            :
            !employee ? (
              <div className="flex items-center sm:gap-4 gap-2">
                {/* <AddProductBtn /> */}
                <AddCategoryBtn
                  fill={true}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  search_text={search_text} />
              </div>
            ) : (
              ""
            )}

        </div>

        <div className="border rounded-lg">
          <div className="px-6 py-2 flex items-center justify-between border-b flex-nowrap gap-3">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"}
              </h2>
              {(!categories_updated || loader) ?
                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                  <p className="rounded-md h-5 w-12 mx-1"> </p>
                </div>
                :
                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                  <p className="text-primary">{allCount ? allCount : '0'} {" "}
                    {allCount && allCount > 1 ?
                      selectedLanguage?.name == "English" ? "Categories" : translation[897]?.value ? translation[897]?.value : "Categories"
                      : selectedLanguage?.name == "English" ? "Category" : translation[293]?.value ? translation[293]?.value : "Category"
                    }
                  </p>
                </div>
              }
            </div>
            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-32 !lg:w-44 !sm:w-40'} classOn2={'!w-12 !lg:16'} classOn3={'!w-12 !lg:16'} has3Itm />
              :
              exportLoader ? (
                <div className="h-[4rem] flex items-center justify-center">
                  <PreloaderSm />
                </div>
              ) : !employee ? (
                <div className="flex items-center gap-3 ">
                  <div className="">
                    <LoginInput
                      title=""
                      placeholder={selectedLanguage?.name == "English" ? "Search Category" : translation[1274]?.value ? translation[1274]?.value : "Search Category"}
                      value={search_text}
                      leftIcon={<Svgs.Search />}
                      onChange={(e) => {
                        setSearchText(e.target.value)
                      }}
                    />
                  </div>
                  <input type="file"
                    accept=".csv"
                    id='csvFileforproduct'
                    onChange={(e) => {
                      submit(e.target.files[0])
                      setImportCategory(false)
                    }}
                    hidden
                    value={''}
                  />
                  <IconButton onClick={() => {
                    setImportCategory(true)
                  }}>
                    <Svgs.Import />
                  </IconButton>
                  {categoryRedux.all_categories?.length > 0 ?
                    <CSVLink {...csvReport} target="_blank" onClick={handleExportClick}>
                      <IconButton filled>
                        <Svgs.Export />
                      </IconButton>
                    </CSVLink>
                    :
                    <LoginBtn
                      className="rounded-lg border-2 border-primary"
                      bg="bg-white"
                      animation={false}
                      onClick={handleExportClick}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.Export bg="#FFFFFF" />
                      </div>
                    </LoginBtn>}
                </div>
              ) : (
                ""
              )}

          </div>
          {(!categories_updated || loader) ?
            <TableShimmer_v2 cols={3} rows={11} rowHeight={'h-12'} />
            :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid grid-cols-[repeat(3,minmax(155px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                <p>{selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name"}</p>
                {/* <p>Created At</p> */}
                <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
                {!employee ? (
                  <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
                ) : (
                  ""
                )}

              </div>
              {categoryRedux.categories && categoryRedux?.categories?.length > 0 ?
                categoryRedux.categories && categoryRedux?.categories?.map((item, index) => {
                  return (
                    <CategoryCard
                      item={item}
                      key={index}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      search_text={search_text}
                      setLoader={setLoader}
                      setNoPaginationLoader={setNoPaginationLoader}
                      hideDeleteIcon={categoryRedux?.categories?.length == 1 ? true : false}
                    />
                  )
                })
                :
                <>
                  <div className="flex items-center justify-center">
                    <NoCategoriesFound />
                  </div>
                  {/* <div className='flex items-center justify-center w-full my-[2rem] '>
                  <svg width="50" height="50" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1690_3556)">
                      <path d="M469.058 0H69.0578C52.5158 0 39.0588 13.458 39.0588 29.9999V335.888C39.0588 352.43 52.5158 365.888 69.0578 365.888H113.471L23.8529 449.956C16.9759 456.408 13.1029 465.262 12.9499 474.888C12.7929 484.747 16.6829 494.382 23.6249 501.324C30.5609 508.264 40.2128 512.157 50.0578 511.997C59.6858 511.844 68.5408 507.972 74.9928 501.095L187.17 381.512C241.683 419.186 324.122 411.708 370.939 365.889H469.06C485.602 365.889 499.06 352.431 499.06 335.889V29.9999C499.058 13.458 485.6 0 469.058 0V0ZM60.4048 487.41C57.6908 490.302 53.9028 491.931 49.7378 491.998C45.2758 492.069 40.8988 490.314 37.7658 487.181C34.6308 484.047 32.8738 479.682 32.9458 475.207C33.0128 471.044 34.6428 467.257 37.5358 464.543L126.183 381.387L143.561 398.765L60.4048 487.41ZM157.252 384.169L140.778 367.695L155.547 353.841C160.348 359.398 165.55 364.599 171.106 369.4L157.252 384.169ZM139.059 255.889C139.059 184.207 197.376 125.89 269.058 125.89C441.284 132.425 441.237 379.38 269.056 385.89C197.376 385.889 139.059 327.571 139.059 255.889V255.889ZM469.058 345.888H388.987C463.159 249.647 391.587 104.756 269.056 105.891C151.126 104.741 78.2188 240.507 143.437 337.779L134.792 345.888H69.0578C63.5438 345.888 59.0588 341.402 59.0588 335.888V79.9998H224.054C237.308 79.5078 237.298 60.4869 224.054 59.9999H59.0588V29.9999C59.0588 24.486 63.5438 20 69.0578 20H469.058C474.572 20 479.058 24.486 479.058 29.9999V59.9999H314.054C300.8 60.4919 300.81 79.5128 314.054 79.9998H479.058V335.888C479.058 341.402 474.571 345.888 469.058 345.888Z" fill="black" />
                      <path d="M269.059 60H269.051C263.529 60 259.055 64.477 259.055 70C259.547 83.248 278.57 83.249 279.059 70C279.059 64.477 274.581 60 269.059 60Z" fill="black" />
                      <path d="M342.862 213.904L311.043 182.085C307.318 178.211 300.625 178.212 296.9 182.085L269.057 209.928L241.215 182.086C237.49 178.212 230.797 178.212 227.072 182.086L195.253 213.905C191.348 217.81 191.348 224.142 195.253 228.048L223.095 255.89L195.253 283.732C191.348 287.637 191.348 293.969 195.253 297.875L227.072 329.694C230.797 333.568 237.49 333.568 241.215 329.694L269.057 301.852L296.9 329.695C300.625 333.568 307.318 333.568 311.043 329.695L342.862 297.876C346.767 293.971 346.767 287.639 342.862 283.733L315.02 255.891L342.862 228.049C346.768 224.142 346.768 217.81 342.862 213.904ZM293.807 248.818C289.902 252.723 289.902 259.055 293.807 262.961L321.649 290.803L303.972 308.481L276.129 280.638C272.405 276.765 265.71 276.765 261.986 280.638L234.144 308.48L216.467 290.802L244.309 262.96C248.214 259.055 248.214 252.723 244.309 248.817L216.467 220.975L234.144 203.297L261.986 231.139C265.71 235.013 272.404 235.013 276.129 231.139L303.972 203.296L321.649 220.974L293.807 248.818Z" fill="black" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1690_3556">
                        <rect width="512" height="512" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div> */}
                </>
              }

              {(categoryRedux?.categories?.length > 0 && !loader) &&
                <Pagination
                  currentPage={currentPage}
                  totalCount={Math.ceil(pages)}
                  pageSize={PageSize}
                  onPageChange={page => setCurrentPage(page)}
                />
              }
            </div>
          }
        </div>
      </div>


      <Popup
        heading={selectedLanguage?.name == "English" ? "Import Categories" : translation[882]?.value ? translation[882]?.value : "Import Categories"}
        close={setImportCategory}
        open={Importcategory}
      >

        <div className='flex flex-col gap-4 border-[#A1A1A1] border-dashed border-2 p-4 rounded-lg text-center'>
          <div className='h-[15rem]'>
            <Svgs.UploadList />
          </div>
          <p className='text-[#595959]'>
            {selectedLanguage?.name == "English" ? "Add your .CSV file to add categories" : translation[1315]?.value ? translation[1315]?.value : "Add your .CSV file to add categories"}
          </p>
          {/* <p className='text-[#595959]'>
            {selectedLanguage?.name == "English" ? "Or" : translation[885]?.value ? translation[885]?.value : "Or"}
          </p> */}
          <LoginBtn size='sm' className='mx-auto'>
            <label htmlFor='csvFileforproduct' className='cursor-pointer'>
              <div className='flex items-center gap-2 text-[#ffffff]'>
                {selectedLanguage?.name == "English" ? "Upload New List" : translation[886]?.value ? translation[886]?.value : "Upload New List"}
              </div>
            </label>
          </LoginBtn>
        </div>
      </Popup>
    </>
  );
};

export default Category;
