import React from "react";
import Svgs from "Assets/svgs";
import { useSelector } from "react-redux";

const NoVoucherFound = (props) => {
    const state = useSelector((state) => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    return (
        <div className="flex items-center justify-center h-[22rem]">
            <div className="flex flex-col gap-3 justify-center text-center">
                <div className="w-fit mx-auto">
                    <Svgs.NoVoucherFound />
                </div>
                <h1 className="text-primary font-semibold text-lg normal-case">
                    {selectedLanguage?.name == "English" ? "Sorry, no voucher found" : translation[1325]?.value ? translation[1325]?.value : "Sorry, no voucher found"}
                </h1>
                <p className="text-sm text-[#555555]">
                    {selectedLanguage?.name == "English" ? "We couldn't find any voucher. Please add a voucher to continue." : translation[1333]?.value ? translation[1333]?.value : "We couldn't find any voucher. Please add a voucher to continue."}
                </p>
            </div>
        </div>
    );
};

export default NoVoucherFound;
