import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
// import { toast } from "react-toastify";

import Svgs from "Assets/svgs";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Popup from "../../../Elements/Popup";
import useCommission from "./helper";
// import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
// import NotFound from "Components/Utility/NotFound";
import DeleteConfirmation from "new-components/delete-popup";
import LocationSelection from "new-components/location";
import CommissionItem from "./CommissionItem";
import AddComission from "./AddComission";
import Pagination from "new-components/Pagination";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import IconButton from "Components/TenantApp/Elements/IconButton";
import NoComissionDataFound from "../Elements/NoComissionDataFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";

const Commission_Scheme = () => {
  const navigate = useNavigate();
  const [detailModal, setDetailModal] = useState(false);
  const [selectedCommission, setSelectedCommission] = useState({});
  const state = useSelector((state) => state);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  const [Employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)

  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const { errors, currencyOnSelectedLocation, commissions_updated, is_delete, commissions, filterEmployee, searchText,
    AddCommission, exportCommission, isEdit, isLoading, setIsLoading, setErrors, setCommissions, handleChange,
    onSubmitCommission, setDeleteId, onBlurHandler, setDelete, onUpdateCommission, commission_filter,
    onEditStateManageHandler, onDeleteCommission, setAddCommission, setSearchText, pages, currentPage, setSubmitButtonClick,
    setCurrentPage, dataList, allCount, loader, handleExportClick, exportLoader, allCommissionList, submitButtonClick, formRef,
    employeeSearch, setEmployeeSearch, employeePage, setEmployeePage, employeeLoader, setEmployeeLoader, employeePaginationData
  } = useCommission();

  const {
    employee,
    commission_cycle,
    service_comission,
    product_comission,
    voucher_comission,
  } = commissions;

  const toggleModal = (itm) => {
    setSelectedCommission(itm);
    setDetailModal(true);
  };

  const pageSize = 10
  // console.log(commissions,'commission')
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Employee Management" : translation[375]?.value ? translation[375]?.value : "Employee Management"}
          second={selectedLanguage?.name == "English" ? "Commission Scheme" : translation[409]?.value ? translation[409]?.value : "Commission Scheme"}
          url={'/dashboard/employee/'}
        />
        <div className="my-[1rem] flex items-center justify-between">
          <div className="flex items-center sm:gap-4 gap-2">

            <div className="flex items-center gap-4">
              <h2 className="text-primary font-bold lg:text-3xl text-xl">
                {selectedLanguage?.name == "English" ? "Commission Scheme" : translation[409]?.value ? translation[409]?.value : "Commission Scheme"}
              </h2>
              <LocationSelection />
            </div>
          </div>
          {loadingPermission ?
            <Shimmer className={'w-40 lg:w-44'}>
              <div className="h-8 w-full  bg-gray-200 rounded-lg" />
            </Shimmer>
            :
            !Employee ||
              (Employee &&
                employeePermissions?.employee_commission?.includes("create")) ? (
              <div className="flex items-center sm:gap-4 gap-2">
                <LoginBtn
                  onClick={() => {
                    setAddCommission(!AddCommission);
                  }}

                >
                  <div className="flex items-center gap-2">
                    <Svgs.Plus />
                    <p className="font-semibold">
                      {selectedLanguage?.name == "English" ? "Add Commission" : translation[597]?.value ? translation[597]?.value : "Add Commission"}
                    </p>
                  </div>
                </LoginBtn>
              </div>
            ) : (
              ""
            )}
        </div>

        <div className="border rounded-lg">
          <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-2">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Commission Scheme" : translation[409]?.value ? translation[409]?.value : "Commission Scheme"}
                </h2>
                {!commissions_updated || loader ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">
                      {allCount ? allCount : 0}{" "}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"
                        : selectedLanguage?.name == "English" ? "Record" : translation[1320]?.value ? translation[1320]?.value : "Record"
                      }
                    </p>
                  </div>
                }
              </div>
              <div className="mb-00">
                {loadingPermission ?
                  <Shimmer className={'w-40 lg:w-44'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                  </Shimmer>
                  :
                  <LoginInput
                    name="searchText"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    title=""
                    placeholder={selectedLanguage?.name == "English" ? "Search Employee" : translation[423]?.value ? translation[423]?.value : "Search Employee"}
                    padding="py-1"
                    leftIcon={<Svgs.Search />}
                  />
                }
              </div>
            </div>
            {loadingPermission ?
              <Shimmer className={'w-12 lg:16'}>
                <div className="h-8 w-full bg-gray-200 rounded-lg" />
              </Shimmer>
              :
              exportLoader ? (
                <div className="h-[4rem] flex items-center justify-center">
                  <PreloaderSm />
                </div>
              ) :
                !Employee ? (
                  allCommissionList?.length > 0 ?
                    <div className="flex items-center  justify-end gap-3 w-fit h-[2.3rem] ">
                      {/* <input
                    type="file"
                    accept=".csv"
                    id="csvFileforproduct"
                    hidden
                  /> */}
                      <CSVLink
                        {...exportCommission}
                        target="_blank"
                        onClick={handleExportClick}
                      >
                        <IconButton filled>
                          <Svgs.Export />
                        </IconButton>
                      </CSVLink>
                    </div> :
                    <LoginBtn
                      className="rounded-lg border-2 border-primary"
                      bg="bg-white"
                      animation={false}
                      onClick={handleExportClick}
                    >
                      <div className="flex items-center gap-2 text-[#FFFFFF]">
                        <Svgs.Export bg="#FFFFFF" />
                      </div>
                    </LoginBtn>
                ) : (
                  ""
                )
            }

          </div>
          {!commissions_updated || loader ? (
            <TableShimer cols={5} />
          ) :
            <div className="overflow-x-auto">
              <div className="inline-grid xl:grid gap-3 grid-cols-[repeat(5,minmax(145px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                {/* <p>Commission Cycle</p> */}
                {/* <p>
                {selectedLanguage?.name == "English" ? "Id" : translation[603]?.value ? translation[603]?.value : "Id"}
              </p> */}
                <p>
                  {selectedLanguage?.name == "English" ? "Employee" : translation[4]?.value ? translation[4]?.value : "Employee"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Retail" : translation[604]?.value ? translation[604]?.value : "Retail"}
                </p>
                <p>
                  {selectedLanguage?.name == "English" ? "Voucher" : translation[193]?.value ? translation[193]?.value : "Voucher"}
                </p>
                <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
              </div>
              {commission_filter()?.length > 0 ? (
                commission_filter()?.map((itm, index) => {
                  return (
                    <CommissionItem
                      itm={itm}
                      index={index}
                      toggleModal={toggleModal}
                      onEditStateManageHandler={onEditStateManageHandler}
                      setDelete={setDelete}
                      setDeleteId={setDeleteId}
                    />
                  );
                })
              ) : (
                <NoComissionDataFound />
              )}
            </div>
          }

          {(commission_filter()?.length > 0 && !loader) &&
            <Pagination
              currentPage={currentPage}
              totalCount={Math.ceil(pages)}
              pageSize={pageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          }
        </div>
      </div>

      {AddCommission && (
        <AddComission
          commissions={commissions}
          setAddCommission={setAddCommission}
          setCommissions={setCommissions}
          AddCommission={AddCommission}
          employee={employee}
          handleChange={handleChange}
          onBlurHandler={onBlurHandler}
          errors={errors}
          formRef={formRef}
          submitButtonClick={submitButtonClick}
          translation={translation}
          selectedLanguage={selectedLanguage}
          filterEmployee={filterEmployee}
          commission_cycle={commission_cycle}
          service_comission={service_comission}
          currencyOnSelectedLocation={currencyOnSelectedLocation}
          setErrors={setErrors}
          product_comission={product_comission}
          voucher_comission={voucher_comission}
          onUpdateCommission={onUpdateCommission}
          onSubmitCommission={onSubmitCommission}
          isEdit={isEdit}
          isLoading={isLoading}
          setSubmitButtonClick={setSubmitButtonClick}
          employeeSearch={employeeSearch}
          setEmployeeSearch={setEmployeeSearch}
          employeePage={employeePage}
          setEmployeePage={setEmployeePage}
          employeeLoader={employeeLoader}
          employeePaginationData={employeePaginationData}

        />
      )}

      {is_delete && (
        <DeleteConfirmation
          title={" "}
          heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this commission?" : translation[605]?.value ? translation[605]?.value : "Are you sure you want to delete this commission?"}
          description={selectedLanguage?.name == "English" ? "Are you sure you want to delete this service? You won't be able to see this again." : translation[606]?.value ? translation[606]?.value : "Are you sure you want to delete this service? You won't be able to see this again."}
          handleClose={() => {
            setDelete(false);
          }}
          handleDelete={() => {
            onDeleteCommission();
            setTimeout(() => {
              setDelete(false);
            }, 500);
          }}
        />
      )}

      {detailModal && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Commission Details" : translation[607]?.value ? translation[607]?.value : "Commission Details"}
          size="md"
          open={detailModal}
          close={setDetailModal}
        >
          <div className="flex flex-col gap-12">
            <div className="flex items-start sm:gap-4 gap-2 lg:flex-row flex-col">
              <div className="flex flex-col space-x-5 md:text-left text-left">
                <h2 className="text-primary font-semibold">
                  {selectedLanguage?.name == "English" ? "Employee Name" : translation[420]?.value ? translation[420]?.value : "Employee Name"}
                </h2>
                <p className="text-primary pt-2 ">
                  {selectedCommission?.employee?.full_name}
                </p>
              </div>

              {/* <div className="flex flex-col space-x-5 md:text-left text-left">
                <h2 className="text-primary font-semibold">
                  Commissions Cycle
                </h2>
                <p className="text-primary pt-2">
                  {selectedCommission?.commission_cycle}
                </p>
              </div> */}
            </div>

            <div className="flex items-start sm:gap-4 gap-2 lg:flex-row flex-col">
              <div className="flex flex-col space-x-5 md:text-left text-left">
                <h2 className="text-primary font-semibold">
                  {selectedLanguage?.name == "English" ? "Service Commission" : translation[598]?.value ? translation[598]?.value : "Service Commission"}
                </h2>
                {selectedCommission?.category_comission?.map(
                  (obj) =>
                    obj.category_comission === "Service" && (
                      <CommissionStat
                        translation={translation}
                        selectedLanguage={selectedLanguage}
                        commission_percentage={obj?.commission_percentage}
                        symbol={obj?.symbol}
                        from_value={obj?.from_value}
                        to_value={obj?.to_value}
                      />
                    )
                )}
              </div>
            </div>

            <div className="flex items-start sm:gap-4 gap-2 lg:flex-row flex-col">
              <div className="flex flex-col space-x-5 md:text-left text-left">
                <h2 className="text-primary font-semibold">
                  {selectedLanguage?.name == "English" ? "Product Commission" : translation[599]?.value ? translation[599]?.value : "Product Commission"}
                </h2>
                {selectedCommission?.category_comission?.map(
                  (obj) =>
                    obj.category_comission === "Retail" && (
                      <CommissionStat
                        translation={translation}
                        selectedLanguage={selectedLanguage}
                        commission_percentage={obj?.commission_percentage}
                        symbol={obj?.symbol}
                        from_value={obj?.from_value}
                        to_value={obj?.to_value}
                      />
                    )
                )}
              </div>
            </div>

            <div className="flex items-start sm:gap-4 gap-2 lg:flex-row flex-col">
              <div className="flex flex-col space-x-5 md:text-left text-left">
                <h2 className="text-primary font-semibold">
                  {selectedLanguage?.name == "English" ? "Voucher Commission" : translation[600]?.value ? translation[600]?.value : "Voucher Commission"}
                </h2>
                {selectedCommission?.category_comission?.map(
                  (obj) =>
                    obj.category_comission === "Voucher" && (
                      <CommissionStat
                        translation={translation}
                        selectedLanguage={selectedLanguage}
                        commission_percentage={obj?.commission_percentage}
                        symbol={obj?.symbol}
                        from_value={obj?.from_value}
                        to_value={obj?.to_value}
                      />
                    )
                )}
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default Commission_Scheme;

const CommissionStat = ({
  commission_percentage,
  symbol,
  from_value,
  to_value,
  translation,
  selectedLanguage,
}) => {
  return (
    <p className="text-primary pt-2">
      {commission_percentage} {symbol} On {from_value} - {to_value} {" "}
      {selectedLanguage?.name == "English" ? "Sales" : translation[3]?.value ? translation[3]?.value : "Sales"}
    </p>
  );
};
