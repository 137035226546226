/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AccountSetupCard from "../AccountSetupCard";

import Svgs from "Assets/svgs";

const DashboardReports = () => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

      <div className="flex items-center gap-5 lg:w-fit w-full lg:px-0 lg:pb-[2rem] lg:py-0 p-3 pb-8">
        {/* <Svgs.Back link="/dashboard/account" /> */}
        <h2 className="font-semibold text-2xl">
          {selectedLanguage?.name == "English" ? "Reports" : translation[7]?.value ? translation[7]?.value : "Reports"}
        </h2>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem] xl:pr-[4rem] pr-0">
        {!employee ||
          (employee && (employeePermissions?.reports_commission?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.reports_root_access?.includes("view"))) ? (
          <AccountSetupCard
            title={selectedLanguage?.name == "English" ? "Commission Reports" : translation[314]?.value ? translation[314]?.value : "Commission Reports"}
            icon={<Svgs.Dashboard_Scheme />}
            description={selectedLanguage?.name == "English" ? "Check reports on staff commission with respect to service/products/vouchers." : translation[315]?.value ? translation[315]?.value : "Check reports on staff commission with respect to service/products/vouchers."}
            link="/dashboard/reports/commission-reports"
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && (employeePermissions?.reports_staff?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.reports_root_access?.includes("view"))) ? (
          <AccountSetupCard
            title={selectedLanguage?.name == "English" ? "Staff Target KPI" : translation[316]?.value ? translation[316]?.value : "Staff Target KPI"}
            icon={<Svgs.Staff_target />}
            description={selectedLanguage?.name == "English" ? "Check reports on staff target with respect to services and retail." : translation[317]?.value ? translation[317]?.value : "Check reports on staff target with respect to services and retail."}
            link="/dashboard/reports/staff-target"
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && (employeePermissions?.reports_store?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.reports_root_access?.includes("view"))) ? (
          <AccountSetupCard
            title={selectedLanguage?.name == "English" ? "Store Target KPI" : translation[318]?.value ? translation[318]?.value : "Store Target KPI"}
            description={selectedLanguage?.name == "English" ? "Check reports on stores targets with respect to services, retail, voucher, and membership." : translation[319]?.value ? translation[319]?.value : "Check reports on stores targets with respect to services, retail, voucher, and membership."}
            icon={<Svgs.Store_Target />}
            link="/dashboard/reports/store-target"
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && (employeePermissions?.reports_service?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.reports_root_access?.includes("view"))) ? (
          <AccountSetupCard
            title={selectedLanguage?.name == "English" ? "Service Target KPI" : translation[321]?.value ? translation[321]?.value : "Service Target KPI"}
            icon={<Svgs.Service_Target />}
            description={selectedLanguage?.name == "English" ? "Check reports on services targets with respect to service groups." : translation[323]?.value ? translation[323]?.value : "Check reports on services targets with respect to service groups."}
            link="/dashboard/reports/service-target"
          />
        ) : (
          ""
        )}

        {!employee ||
          (employee && (employeePermissions?.reports_retail?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.reports_root_access?.includes("view"))) ? (
          <AccountSetupCard
            title={selectedLanguage?.name == "English" ? "Retail Target KPI" : translation[324]?.value ? translation[324]?.value : "Retail Target KPI"}
            icon={<Svgs.Retail_Target />}
            description={selectedLanguage?.name == "English" ? "Check reports on retail target with respect to brands." : translation[325]?.value ? translation[325]?.value : "Check reports on retail target with respect to brands."}
            link="/dashboard/reports/retail-target"
          />
        ) : (
          ""
        )}
        {!employee ||
          (employee && (employeePermissions?.reports_retail?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.reports_root_access?.includes("view"))) ? (
          <AccountSetupCard
            title={selectedLanguage?.name == "English" ? "Promotions & Discount Sales" : translation[326]?.value ? translation[326]?.value : "Promotions & Discount Sales"}
            icon={<Svgs.Client_Groups />}
            description={selectedLanguage?.name == "English" ? "Check reports on promotions & discount sales." : translation[327]?.value ? translation[327]?.value : "Check reports on promotions & discount sales."}
            link="/dashboard/reports/discounts-promotions"
          />
        ) : (
          ""
        )}
        {!employee ||
          (employee && (employeePermissions?.reports_retail?.includes("view")
            || employeePermissions?.pos_root_access?.includes("view")
            || employeePermissions?.reports_root_access?.includes("view"))) ? (
          <AccountSetupCard
            title={selectedLanguage?.name == "English" ? "Booking Reports" : translation[1784]?.value ? translation[1784]?.value : "Booking Reports"}
            icon={<Svgs.Client_Groups />}
            description={selectedLanguage?.name == "English" ? "Check reports on Bookings" : translation[1785]?.value ? translation[1785]?.value : "Check reports on Bookings"}
            link="/dashboard/reports/appointment-reports"
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default DashboardReports;
