import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import StoreWrapper from 'Wrapper/StoreWrapper';
import FontFamilyWrapper from 'Wrapper/FontFamilyWrapper';

import reportWebVitals from "./reportWebVitals";


import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <Router>
        <StoreWrapper>
            <FontFamilyWrapper>
                <App />
            </FontFamilyWrapper>
        </StoreWrapper>
    </Router>
    // </React.StrictMode>
);

reportWebVitals();