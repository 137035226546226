import axios from "axios";
import { BASE_URL, get_service_url, create_service_url, get_service_for_dropdown, get_service_optimizated } from "../api_variables";
import { toast } from 'react-toastify';
import { get_Services } from "../../../Redux/TenantRedux/Actions/ServiceActions";
import { SERVICES } from "../../../Redux/TenantRedux/ActionTypes/ServicesTypes";


export const getservice = () => dispatch => {

    let s_code;
    fetch(`${BASE_URL}${get_service_url}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let servicetList = result?.response?.service;

                dispatch({
                    type: SERVICES.GET_SERVICES,
                    payload: servicetList
                })
            }
            else {
                toast.error("Error in get services", { toastId: "toast" })
            }
        })
};

export const getserviceDropdownList = (setLoader) => dispatch => {
    let selected_location = localStorage.getItem("selected_location") ? `?location_id=${localStorage.getItem("selected_location")}` : ""

    setLoader && setLoader(true)
    let s_code;
    fetch(`${BASE_URL}${get_service_for_dropdown}${selected_location}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {

                let servicetList = result?.response?.services;
                dispatch({
                    type: SERVICES.GET_SERVICES,
                    payload: servicetList
                })
                setLoader && setLoader(false)
            }
            else {
                toast.error("Error in get services", { toastId: "toast" })
                setLoader && setLoader(false)
            }
        })
};
export const getserviceDropdownListPagination = (page, searchText, setLoader) => dispatch => {
    let selected_location = localStorage.getItem("selected_location") ? `?location_id=${localStorage.getItem("selected_location")}` : ""
    const pageNumb = page ? `&page=${page}` : ''
    const search = searchText ? `&search_text=${searchText}` : ''
    setLoader && setLoader(true)
    let s_code;
    fetch(`${BASE_URL}${get_service_for_dropdown}${selected_location}${pageNumb}${search}`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                dispatch({
                    type: SERVICES.GET_SERVICES_DROPDOWN_PAGINATION,
                    payload: result
                })
                setLoader && setLoader(false)
            }
            else {
                // toast.error("Error in get services", { toastId: "toast" })
                setLoader && setLoader(false)
            }
        })
};

export const getServiceForCalendar = (location, setLoader) => dispatch => {
    const location_id = location ? `?location_id=${location}` : ""
    setLoader(true)
    let s_code;
    fetch(`${BASE_URL}${get_service_optimizated}${location_id}&no_pagination=true`)
        .then(response => {
            s_code = response.status
            if (response.ok) {
                return response.json()
            }
        })
        .then(result => {
            if (s_code == 200) {
                let servicetList = result?.response?.service;

                dispatch({
                    type: SERVICES.GET_SERVICES,
                    payload: servicetList
                })
                setLoader(false)
            }
            else {
                toast.error("Error in get services", { toastId: "toast" })
                setLoader(false)
            }
        })
};



