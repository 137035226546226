import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";

import Svgs from "../../../../../Assets/svgs";
import LoginBtn from "../../../Elements/LoginBtn";
import EmployeeCard from "../Elements/EmployeeCard";
import {
  // getEmployeeList,
  getEmployeeListMainPage,
  getEmployeeListWithNoPagination,
  // getsEmployeesImport,
} from "../../../../../Adapters/Api/employee";
import useEmployeeForms from "./useEmployeeForms";
import LoginInput from "../../../Elements/LoginInput";
import Popup from "../../../Elements/Popup";
import { toast } from "react-toastify";
import useSetDataInLocalStorage from "../../../../../Hooks/useSetDataInLocalStorage";
import LocationSelection from "new-components/location";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import IconButton from "Components/TenantApp/Elements/IconButton";
import Pagination from "new-components/Pagination";
import NoEmployeeFound from "Components/Utility/NoEmployeeFound";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import QuickSalesTableHead from "Components/Utility/Shimmers/QuickSalesTableHead";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
// import NotFound from "../../../../Utility/NotFound";
// import PreloaderFull from "./../../../Elements/PreloaderFull";
// import SelectLocation from "Components/TenantApp/Elements/SelectLocation";
// import AddEmployeePopup from "./AddEmployeePopup";


let timeOutId = undefined;
const Employees = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");

  const state = useSelector((state) => state);
  const employeeRedux = state?.employee?.employees;
  const pages = state?.employee?.employeePages;
  const employeeAllCount = state?.employee?.employeeAllCount
  const allEmployeeRedux = state?.employee?.allEmployees
  const employee_updated = state.employee.employee_updated;
  const location_currency = state?.utility?.currency?.location_currency;
  const { selected_location } = state.locations;
  const PageSize = 10

  // const [time_id, setTimeID] = useState("");
  const [ImportEmployees, setImportEmployees] = useState(false);
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [dataArr, setDataArr] = useState([]);
  const [AddVendor, setAddVendor] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)

  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  const { employeeDeleteHandler, employeeEditHandler, setIsEdit, setLoader, loader, currentPage, setCurrentPage,
    noPaginationLoader, setNoPaginationLoader, search_text, setSearchText, deleteemp, setDeleteemp, deleting, setDeleting,
    dropdownStatus, setDropdownStatus
  } = useEmployeeForms();

  // useEffect(() => {
  //   if (search_text) {
  //     clearTimeout(time_id);
  //     let tim_id = setTimeout(() => {
  //       // handle_text_search(search_text)
  //     }, 1000);
  //     setTimeID(tim_id);
  //   }
  // }, [search_text]);

  // useEffect(() => {
  //   if (employeeRedux?.length === 0) dispatch(getEmployeeList());
  // }, []);

  useEffect(() => {
    if (selected_location) {
      if (search_text === null) {
        // Initial load with empty search_text
        dispatch(getEmployeeListMainPage(currentPage, selected_location, "", dropdownStatus, setLoader))
        dispatch(getEmployeeListWithNoPagination(selected_location, "", dropdownStatus, setNoPaginationLoader))
      } else {
        if (timeOutId) {
          clearTimeout(timeOutId)
        }
        timeOutId = setTimeout(() => {
          dispatch(getEmployeeListMainPage(currentPage, selected_location, search_text, dropdownStatus, setLoader))
          dispatch(getEmployeeListWithNoPagination(selected_location, search_text, dropdownStatus, setNoPaginationLoader))
        }, 500);
      }
    }
  }, [currentPage, search_text, selected_location, dropdownStatus])

  useEffect(() => {
    setCurrentPage(1)
    setNoPaginationLoader(true)
    setLoader(true)
  }, [selected_location, dropdownStatus])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  // useEffect(() => {
  //   if (selected_location) {
  //     dispatch(getEmployeeListWithNoPagination(selected_location))
  //   }
  // }, [selected_location])

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  //----------------------Exoprt code--------------------

  let headers = [
    { label: "Employee Name", key: "full_name" },
    { label: "Phone Number", key: "mobile_number" },
    { label: "Designation", key: "designation" },
    { label: "Email", key: "email" },
    { label: "Income Type", key: "income_type" },
    { label: "Salary", key: "salary" },
    { label: "Address", key: "address" },
    { label: "Gender", key: "gender" },
    { label: "Country", key: "country_name" },
    { label: "City", key: "city_name" },
    { label: "State", key: "state_name" },
  ];

  const csvReport = {
    filename: "Employee Directory.csv",
    headers: headers,
    data: allEmployeeRedux?.length > 0 ? allEmployeeRedux?.map((itm) => {
      let phone_number = itm?.mobile_number?.replaceAll("-", " ");
      return {
        ...itm,
        full_name: itm?.full_name ? itm?.full_name : "--------",
        mobile_number: phone_number
          ? phone_number.replaceAll("+", " ")
          : "--------",
        designation: itm?.designation ? itm?.designation : "--------",
        email: itm?.email ? itm?.email : "--------",
        income_type: itm?.income_type ? itm?.income_type : "--------",
        salary: itm?.salary ? itm?.salary : "--------",
        address: itm?.address ? itm?.address : "--------",
        gender: itm?.gender ? itm?.gender : "--------",
        country_name: itm?.country_name ? itm?.country_name : "--------",
        state_name: itm?.state_name ? itm?.state_name : "--------",
        city_name: itm?.city_name ? itm?.city_name : "--------",
      };
    }) : [],
  };
  let business = businessData.id;
  business = business.replaceAll('"', "");

  // const submit = (file) => {
  //   const businessData = getDataFromLocalStorage("business_data");
  //   let business = businessData.id;
  //   business = business.replaceAll('"', "");

  //   getsEmployeesImport({ file: file, business: business }, () => {
  //     dispatch(getEmployeeList());
  //   });
  // };



  useEffect(() => {
    // if (employeeRedux?.length === 0) {
    //   dispatch(getEmployeeList());
    // } else {
    //   const val = employeeRedux?.map((element) => {
    //     const data = element?.location?.filter(
    //       ({ id }) => id === selected_location
    //     );

    //     if (data.length > 0 && data !== undefined) return { ...element };
    //   });
    //   const productArr = val.filter((element) => element !== undefined);

    //   setDataArr(productArr);
    // }
    setDataArr(employeeRedux)
  }, [selected_location, employeeRedux]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (allEmployeeRedux?.length > 0) {
        setAddVendor(!AddVendor);
        // // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", { toastId: "toast", onClose: () => setIsToastVisible(false) });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  return <>
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

      <Breadcrumb
        first={selectedLanguage?.name == "English" ? "Employee Management" : translation[375]?.value ? translation[375]?.value : "Employee Management"}
        second={selectedLanguage?.name == "English" ? "Employees" : translation[399]?.value ? translation[399]?.value : "Employees"}
        url={'/dashboard/employee/'}
      />
      <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
        <div className="flex flex-wrap items-center justify-between sm:flex-1 w-full md:gap-0 lg:gap-0 gap-3 sm:gap-2">
          <div className="flex items-center gap-4 whitespace-nowrap">
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Employees" : translation[399]?.value ? translation[399]?.value : "Employees"}
            </h2>
            <LocationSelection />
          </div>
          {loadingPermission ?
            <EmployeesHeadShimmer classOn1={'!w-32 lg:!w-44 sm:!w-40'} />
            :
            <div className="flex items-center gap-2 w-full sm:w-auto justify-end sm:justify-between">
              <div className="mb-00 w-full">
                <LoginInput
                  name={"text"}
                  value={search_text}
                  leftIcon={<Svgs.Search />}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Search Employee" : translation[423]?.value ? translation[423]?.value : "Search Employee"}
                />
              </div>
              <div className="mb-00 w-full">
                <Dropwdown
                  title=""
                  value={dropdownStatus}
                  onChange={(e) => setDropdownStatus(e.target.value)}
                  showTitle={false}
                  placeholder={selectedLanguage?.name == "English" ? "Select Staff" : translation[334]?.value ? translation[334]?.value : "Select Staff"}
                  options={[
                    { label: selectedLanguage?.name == "English" ? "All Employees" : translation[1337]?.value ? translation[1337]?.value : "All Employees", value: "" },
                    { label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active", value: "true" },
                    { label: selectedLanguage?.name == "English" ? "In Active" : translation[1302]?.value ? translation[1302]?.value : "In Active", value: "false" }
                  ]}
                />
              </div>
            </div>
          }
        </div>

        {loadingPermission ?
          <EmployeesHeadShimmer classOn1={'!w-12 lg:!16'} classOn2={''} />
          :
          <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">

            {exportLoader ? (
              <div className="h-[4rem] flex items-center justify-center">
                <PreloaderSm />
              </div>
            ) :
              !employee ? (
                dataArr?.length > 0 ?
                  <>
                    <div className="flex items-center gap-2 text-primary">
                      <CSVLink
                        {...csvReport}
                        target="_blank"
                        onClick={handleExportClick}
                      >
                        <IconButton filled>
                          <Svgs.Export />
                        </IconButton>
                      </CSVLink>
                    </div>
                    {/* <input
                  type="file"
                  accept=".csv"
                  id="csvFileforproduct"
                  onChange={(e) => {
                    submit(e.target.files[0]);
                    setImportEmployees(false);
                  }}
                  hidden
                  value={""}
                />
                <LoginBtn
                  onClick={() => {
                    setAddVendor(!AddVendor);
                    setImportEmployees(true);
                  }}
                  className="rounded-lg border-2 border-primary"
                  bg="bg-white"
                  
                >
                  <div className="flex items-center gap-2 text-primary">
                    <Svgs.Import />
                  </div>
                </LoginBtn> */}
                  </> :
                  <LoginBtn
                    className="rounded-lg border-2 border-primary"
                    bg="bg-white"
                    animation={false}
                    onClick={handleExportClick}
                  >
                    <div className="flex items-center gap-2 text-[#FFFFFF]">
                      <Svgs.Export bg="#FFFFFF" />
                    </div>
                  </LoginBtn>
              ) : (
                ""
              )}

            {!employee ||
              (employee &&
                employeePermissions?.employee_new?.includes("create")) ? (<>
                  <LoginBtn
                    onClick={() => {
                      setIsEdit(false);
                      navigate("/dashboard/employee/employees/add-employee");
                    }}

                  >
                    <div className="flex items-center gap-2">
                      <Svgs.Plus />
                      <div className="">
                        {selectedLanguage?.name == "English" ? "New Employee" : translation[423]?.value ? translation[423]?.value : "New Employee"}
                      </div>
                    </div>
                  </LoginBtn>
                </>
            ) : (
              ""
            )}
          </div>
        }
      </div>

      {
        !employee_updated || loader ?
          <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-[2rem]">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8]?.map(itm => {
              return <EmployeeCard animate />
            })}
          </div>
          : dataArr &&
          dataArr?.length > 0 ? (
            <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-[2rem]">
              {dataArr &&
                dataArr?.map((item, index) => {
                  return (
                    <EmployeeCard
                      currency={location_currency}
                      key={item?.id}
                      id={item?.id}
                      index={index}
                      data={item}
                      onDelete={() => {
                        setDeleteemp(item.id);
                      }}
                      onEdit={employeeEditHandler}
                      hideDeleteIcon={employeeAllCount == 1 ? true : false}
                    />
                  );
                })}
            </div>
          ) : (
            <>
              <NoEmployeeFound className={"my-[5rem]"} />
            </>
          )}

      {(dataArr?.length > 0 && !loader) &&
        <Pagination
          currentPage={currentPage}
          totalCount={Math.ceil(pages)}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
        />
      }
    </div>
    {deleteemp && (
      <Popup
        heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this Employee?" : translation[427]?.value ? translation[427]?.value : "Are you sure you want to delete this Employee?"}
        onclose={() => {
          setDeleteemp(false);
        }}
      >
        <div className="">
          <p>
            {selectedLanguage?.name == "English" ?
              "Are you sure you want to delete this Employee? You won't be able to see this again." :
              translation[427]?.value ? translation[427]?.value : "Are you sure you want to delete this Employee? You won't be able to see this again."}
          </p>
          <div className="flex items-center justify-end mt-[2rem]">
            <LoginBtn
              deleteBtn
              loading={deleting}
              text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
              onClick={async () => {
                setDeleting(true);
                await employeeDeleteHandler(deleteemp);
                // setTimeout(() => {
                //   setDeleting(false);
                //   setDeleteemp(undefined);
                // }, 500);
              }}
            />
          </div>
        </div>
      </Popup>
    )}
    <Popup
      heading={`Import Employees`}
      close={setImportEmployees}
      open={ImportEmployees}
    >
      <div className="flex flex-col gap-4 border-[#A1A1A1] border-dashed border-2 p-4 rounded-lg text-center">
        <div className="h-[15rem]">
          <Svgs.UploadList />
        </div>
        <p className="text-[#595959]">Drop your .CSV file to add Employees</p>
        <p className="text-[#595959]">Or</p>
        <LoginBtn size="sm" className="mx-auto">
          <label htmlFor="csvFileforproduct" className="cursor-pointer">
            <div className="flex items-center gap-2 text-[#ffffff]">
              Upload New List
            </div>
          </label>
        </LoginBtn>
      </div>
    </Popup>
  </>
};

export default Employees;
