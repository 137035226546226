import {
  addConsumptions,
  deleteConsumption,
  editConsumption,
  getConsumptions,
  getConsumptionsWithNoPagination,
} from "Adapters/Api/consumptions";
import { getsProducts, getsProductsDropdownListPagination, getsProductsForConsumption } from "Adapters/Api/Products";
import { apiErrorMessage } from "Constants/Data/Errors";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getConsumptionWithNoPagination,
  getProductConsumption,
  // addProductConsumption,
  // deleteProductConsumption,
  // updateProductConsumption,
} from "Redux/TenantRedux/Actions/consumption";
import { getProducts } from "Redux/TenantRedux/Actions/product";
import useObjectKeys from "../../../../../../Hooks/useObjectKeys";
import useSetDataInLocalStorage from "../../../../../../Hooks/useSetDataInLocalStorage";
import useValidations from "../../../../../../Hooks/useValidations";
let productTimeOutId = undefined;

function useConsumptions() {
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const { setErrors, validation, errors, onBlurHandler } = useValidations();
  const { objectKeyConvertToArray } = useObjectKeys();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const access_token = getDataFromLocalStorage("access_token");
  const [loading, setLoading] = useState(false);
  const [noPaginationLoader, setNoPaginationLoader] = useState(true)
  const [getLoader, setGetLoader] = useState(true)
  const [isEdit, setIsEdit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const { selected_location } = state?.locations;

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const [consumption, setConsumption] = useState({
    product: "",
    location: selected_location,
    quantity: "",
  });
  const [locations, setLocations] = useState([]);
  const [products, setProducts] = useState([]);



  const [productSearchText, setProductSearchText] = useState("");
  const [productCurrentPage, setProductCurrentPage] = useState(1)
  const [productLoader, setProductLoader] = useState(false)
  const productsRedux = state?.product?.productDropdown;
  const productPaginationData = state?.product?.productPagination;

  useEffect(() => {
    if (selected_location) {
      if (productSearchText === null) {
        // Initial load with empty search_text
        dispatch(getsProductsDropdownListPagination(productCurrentPage, selected_location, "", setProductLoader))
      } else {
        if (productTimeOutId) {
          clearTimeout(productTimeOutId)
        }
        productTimeOutId = setTimeout(() => {
          dispatch(getsProductsDropdownListPagination(productCurrentPage, selected_location, productSearchText, setProductLoader))
        }, 500);
      }
    }
  }, [productSearchText, selected_location, productCurrentPage])

  // // fetch Product Lists 
  // const fetchProductslist = async () => {
  //   const response = await getsProductsForConsumption(selected_location);
  //   if (response.status === 200) {
  //     let productList = response?.data?.response?.products;
  //     dispatch(getProducts(productList));
  //   }
  // };

  // useEffect(() => {
  //   if (selected_location) {
  //     fetchProductslist();
  //   }
  // }, [selected_location])

  // Create Product list and Passing Product List data in product dropdown

  const productList = useMemo(() => {
    var products = [];
    productsRedux?.map((item, index) => {
      products?.push({ label: item.name, value: item.id, ...item });
    });
    return products;
  }, [productsRedux, productsRedux?.length == 0, consumption.product]);

  // get Consumption list
  const fetchConsumptionList = async (currentPage, searchText) => {
    const response = await getConsumptions(selected_location, currentPage, searchText, access_token, setGetLoader);
    if (response.status === 200) {
      let consumptionList = response?.data;
      dispatch(getProductConsumption(consumptionList));
    }
  };

  // get Consumption list NoPagination
  const fetchConsumptionListNoPagination = async (searchText) => {
    const response = await getConsumptionsWithNoPagination(selected_location, searchText, access_token, setNoPaginationLoader)
    if (response.status === 200) {
      let consumptionList = response?.data?.response?.product_consumptions;
      dispatch(getConsumptionWithNoPagination(consumptionList))
    }
  };

  // ******** CRUD HANDLERS ********
  //    Edit Handler
  const consumptionEditHandler = async (item, handleClose) => {
    const { product, location, quantity } = consumption;
    let consumptionData;
    consumptionData = {
      consumption_id: item.id,
      product,
      location,
      quantity,
    };
    setErrors(validation(consumptionData));
    let errorResponse = validation(consumptionData);
    if (objectKeyConvertToArray(errorResponse)?.length === 0) {
      if (consumption?.quantity != 0) {
        if (filterObjectBasedOnProductLocation?.current_stock >= +consumption?.quantity) {
          setLoading(true);
          const response = await editConsumption(consumptionData, access_token);
          if (response.status === 200) {
            let product_consumption = response?.data?.response?.product_consumption;
            let successMessage = response?.data?.response?.message;
            setLoading(false);
            // dispatch(updateProductConsumption(product_consumption));
            setLoading(false);
            toast.success(successMessage, { toastId: "toast" });
            setConsumption({
              product: "",
              quantity: "",
              location: "",
            });
            handleClose();
            setCurrentPage(1)
            fetchConsumptionList(1, searchText)
            fetchConsumptionListNoPagination(searchText)
          } else {
            setLoading(false);
            toast.error(apiErrorMessage, { toastId: "toast" });
          }
        }
        else {
          setErrors((prev) => ({ ...prev, quantity: `The available stock of this product is ${filterObjectBasedOnProductLocation?.current_stock}. You can not add more than the available stock` }));
          // toast.error(`The available stock of this product is ${filterObjectBasedOnProductLocation?.current_stock}. You can not add more than the available stock`)
        }
      } else {
        setErrors((prev) => ({ ...prev, quantity: "Quantity should be greater than 0." }));
      }
    }
  };

  const filterObjectBasedOnProductLocation = useMemo(() => {
    let data = {}
    data = (consumption.product && productList?.length > 0) ? productList?.find(prod => prod?.id == consumption.product)?.location_quantities[0] : {}
    // productList?.map((item, index) => {
    //   if (item?.location_quantities?.length > 0) {
    //     data = item?.location_quantities[0]
    //     // item?.location_quantities?.map((obj) => {
    //     //   if (obj?.location?.id === selected_location && obj.product === consumption.product) {
    //     //     data = obj;
    //     //   }
    //     // })
    //   }
    // });
    return data;
  }, [consumption.product, consumption.quantity, selected_location])

  //    onChange Handler
  const consumptionHandleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'quantity') {
      setConsumption((prev) => ({ ...prev, [name]: value }));
      // if (value > filterObjectBasedOnProductLocation.available_quantity) {
      // setErrors((prev) => ({ ...prev, [name]: `The available quantity of the product is  ${filterObjectBasedOnProductLocation.available_quantity}` }));
      // }
      if (value <= filterObjectBasedOnProductLocation.current_stock) {
        setErrors((prev) => ({ ...prev, [name]: `` }));
      }
      if (Object.keys(filterObjectBasedOnProductLocation).length === 0) {
        setErrors((prev) => ({ ...prev, [name]: `0 stock exist` }));
      }
    } else {
      setConsumption((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  useEffect(() => {
    if (consumption?.quantity == 0 && consumption?.quantity != '') {
      setErrors((prev) => ({ ...prev, quantity: "Quantity should be greater than 0." }));
    } else {
      setErrors((prev) => ({ ...prev, quantity: "" }));
    }
  }, [consumption?.quantity, consumption])

  //    onSubmit Handler
  const onConsumptionSubmit = async (handleClose) => {
    const { product, location, quantity } = consumption;
    let consumptionData;
    consumptionData = { product, location, quantity };
    setErrors(validation(consumptionData));
    let errorResponse = validation(consumptionData);
    if (objectKeyConvertToArray(errorResponse)?.length === 0) {
      if (consumption?.quantity != 0) {
        if (filterObjectBasedOnProductLocation?.current_stock >= +consumption?.quantity) {
          setLoading(true);
          const response = await addConsumptions(consumptionData, access_token);
          if (response.status === 201) {
            let product_consumption = response?.data?.response?.product_consumption;
            let successMessage = response?.data?.response?.message;
            // dispatch(addProductConsumption(product_consumption));
            setLoading(false);
            toast.success(successMessage, { toastId: "toast" });
            setConsumption({
              product: "",
              quantity: "",
              location: "",
            });
            handleClose();
            setCurrentPage(1)
            fetchConsumptionList(1, searchText)
            fetchConsumptionListNoPagination(searchText)
          } else {
            setLoading(false);
            toast.error(apiErrorMessage, { toastId: "toast" });
          }
        }
        else {
          setErrors((prev) => ({ ...prev, quantity: `The available stock of this product is ${filterObjectBasedOnProductLocation?.current_stock}. You can not add more than the available stock` }));
          // toast.error(`The available stock of this product is ${filterObjectBasedOnProductLocation?.current_stock}. You can not add more than the available stock`)
        }
      } else {
        setErrors((prev) => ({ ...prev, quantity: "Quantity should be greater than 0." }));
      }
    }
  };

  //    onDelete Handler
  const consumptionDeleteHandler = async (deleteItem, handleClose) => {
    setLoading(true);
    let response = await deleteConsumption(deleteItem.id, access_token);
    if (response.status === 200) {
      let successMessage = response?.data?.response?.message;
      toast.success(successMessage, { toastId: "toast" });
      // dispatch(deleteProductConsumption(deleteItem.id));
      setLoading(false);
      handleClose();
      setCurrentPage(1)
      fetchConsumptionList(1, searchText)
      fetchConsumptionListNoPagination(searchText)
    }
  };

  return {
    setConsumption, setModalOpen, consumptionHandleChange, consumptionEditHandler, onConsumptionSubmit, consumptionDeleteHandler,
    setLoading, setIsEdit, onBlurHandler, setLocations, setProducts, errors, consumption, isEdit, loading, locations,
    products, access_token, modalOpen, productList, filterObjectBasedOnProductLocation, getLoader, setGetLoader,
    searchText, setSearchText, currentPage, setCurrentPage, noPaginationLoader, setNoPaginationLoader, fetchConsumptionListNoPagination,
    productSearchText, setProductSearchText, productCurrentPage, setProductCurrentPage, productLoader, productPaginationData,
    fetchConsumptionList, dispatch, selected_location, state
  };
}

export default useConsumptions;
