import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import useSound from "use-sound";
import { useLocation } from 'react-router-dom';
import Routes from "Routes";
import { PageLoader } from "Components/Utility/Loader";
import "App.css";
import "react-toastify/dist/ReactToastify.css";
import { selected_language } from 'Redux/TenantRedux/Actions/languageActions';
import { useState } from 'react';
import { BASE_URL } from 'Adapters/Api/api_variables';
import axios from 'axios';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';

function App() {
  const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
  const cartFromLocalStorage = getDataFromLocalStorage('cart_items')
  const locationChange = getDataFromLocalStorage("locationChange");

  const dispatch = useDispatch();

  const loading = useSelector((state) => state?.utility?.loader?.loading);
  const state = useSelector((state) => state);
  const stateCart = state?.cartItem?.cart_items

  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (locationChange) {
      dispatch({
        type: "RESET_CART",
        payload: []
      })
      localStorage.removeItem('cart_items')
      localStorage.removeItem('locationChange')
    }
  }, [locationChange])

  useEffect(() => {
    if (cartFromLocalStorage?.length > 0 && stateCart?.length == 0) {
      dispatch({
        type: 'CART_FROM_LOCAL',
        payload: cartFromLocalStorage
      })
    }
  }, [stateCart, cartFromLocalStorage, state])

  useEffect(() => {
    dispatch(
      selected_language(JSON.parse(localStorage.getItem("selected_language") ?
        localStorage.getItem("selected_language") :
        JSON.stringify({ "id": "e0f6d750-d107-4fb2-b9bd-ef22f63d2ba6", "name": "English", "code": "eng" }))
      ))
  }, [])

  const [play] = useSound(
    `${process.env.PUBLIC_URL}/assets/sounds/notification-3.mp3`,
    { volume: 0.1 }
  );
  toast.onChange((payload) => {
    if (payload.status === "added") {
      play();
    }
  });


  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const loginData = localStorage.getItem('loginData');
    if (localStorage.getItem('loginData') && loginData != null) {
      // const domain = JSON.parse(loginData).domain; // Assuming loginData is a JSON string
      // const access_token = JSON.parse(loginData).access_token;
      // const id = JSON.parse(loginData).id;
      // const employee = JSON.parse(loginData).employee;
      const email = JSON.parse(loginData).email;
      // userAutoLogin(
      //   {
      //     email: email,
      //   },
      //   (result) => {
      //     // console.log(result, "result")
      //     if (result?.data?.status_code == 200) {
      //       let data = result?.data?.response?.data;
      //       storeDataInLocalStorage("loginData", data);
      //       let hostName = process.env.REACT_APP_SERVER_APPLICATION_DOMAIN
      //       if (typeof window !== 'undefined') {
      //         hostName = window.location.host;
      //       }
      //       const hostArr = hostName.split(".");
      //       hostArr.unshift(`${data.domain ? data.domain : null}`);
      //       const domain = hostArr.join(".");
      //       window.location = `http://${domain}/authentication/login/auto_login/?user_login_id=${data.id}&access_token=${data.access_token}&next_path=/dashboard&redirection_source=nstyle_app&redirection_purpose=account_and_mobile_otp_verification`;
      //       setLoader(false)
      //     }
      //   },
      //   (err) => {
      //     setLoader(false)
      //   }
      // );
    } else {
      setLoader(false)
    }
  }, []);

  // autoLogin Api calling here
  const userAutoLogin = async (login_credentials, success, fail) => {
    try {
      const result = await axios.post(
        `${BASE_URL}/auth/nstyle_login_flag/`,
        login_credentials
      );
      success && success(result);
      return result;
    } catch (error) {
      fail && fail(error);
      return error.response;
    }
  };

  return (
    <>
      {(loading || loader) && <PageLoader />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      />
      <div className="nstyle-container">
        <Routes />
      </div>
    </>
  );
}

export default App;