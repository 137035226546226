import { useState } from 'react';
import useAdminBooking from '../helper';

function useLocationInfo() {
    const [show, setShow] = useState(false)
    const { locationInfo, locationsList, setLocationInfo } = useAdminBooking();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLocationInfo((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const handlePostalCodeInput = () => {
        setShow(!show)
    }

    return {
        locationInfo,
        setLocationInfo,
        locationsList,
        handleChange,
        show, setShow,
        handlePostalCodeInput
    }
}

export default useLocationInfo
