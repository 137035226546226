import { useEffect, useState } from "react";

const optionsForDealTypeOne = [
    { label: 'Product', value: 'product' },
    { label: 'Service', value: 'service' },
    { label: 'Voucher', value: 'voucher' }
]

const useDealTypeOneSetting = ({ dealTypeSlug, setErrors, setSettingData, settingData }) => {
    const [screenLoader, setScreenLoader] = useState(true)
    const [dealOptionForTypeOne, setDealOptionForTypeOne] = useState(optionsForDealTypeOne)

    useEffect(() => {
        setTimeout(() => {
            setScreenLoader && setScreenLoader(false)
        }, 1000);
    }, [])

    useEffect(() => {
        // filtering deal type one options
        if (dealTypeSlug == "fixed-amount-discount-deal") {
            let filtered = optionsForDealTypeOne;
            filtered = optionsForDealTypeOne?.filter((type) =>
                !settingData?.applicalbleCategoryIds?.includes(type?.value) // Assuming that brand has an id property
            );
            setDealOptionForTypeOne([...filtered]); // Creating a new array before setting state
        }
    }, [dealTypeSlug, settingData?.applicalbleCategoryIds?.length, dealOptionForTypeOne?.length]);

    // data handle change function
    const handleSettingChange = (e) => {
        const { name, value } = e.target;
        setSettingData((prev) => ({
            ...prev,
            [name]: value
        }))
        setErrors((prev) => ({
            ...prev,
            [name]: ''
        }))
    }

    // handle change for dropdown add
    const handleChangeForDropdown = (e, type) => {
        const { name, value } = e.target;
        // for applicalbleCategoryIds
        if (type == 'applicalbleCategoryIds') {
            if (value == "all") {
                setSettingData((prev) => ({
                    ...prev,
                    [name]: optionsForDealTypeOne?.map((itm) => itm?.value)
                }))
            } else {
                const existing = settingData?.applicalbleCategoryIds || []
                const exist = settingData?.applicalbleCategoryIds?.find(itm => itm == value)
                if (!exist) {
                    setSettingData((prev) => ({
                        ...prev,
                        [name]: [...existing, value]
                    }))
                }
            }
        }
    }

    // removed selected Item from array
    const removeSelectedItem = (value, type) => {

        // for applicalbleCategoryIds
        if (type == 'applicalbleCategoryIds') {
            const filteredIds = settingData?.applicalbleCategoryIds?.filter((itm) => itm != value)
            setSettingData((prev) => ({
                ...prev,
                applicalbleCategoryIds: [...filteredIds]
            }))
        }
    }

    return {
        screenLoader, handleSettingChange, handleChangeForDropdown, dealOptionForTypeOne,
        removeSelectedItem, optionsForDealTypeOne
    }
};

export default useDealTypeOneSetting