import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectedOption from "Components/TenantApp/Elements/SelectedOption";
import DetailPopupDetails from "../../Elements/DetailPopupDetails";
import CircleIcon from "Components/TenantApp/Elements/CircleIcon";
import DeleteConfirmation from "new-components/delete-popup";
import Popup from "Components/TenantApp/Elements/Popup";
import ServiceCard from "../../Elements/ServiceCard";
import AddToCart from "../../Elements/AddToCart";
import AddService from "./addService";
import { getSaleServices, getServiceGroup, getServiceGroupPaginationDropdown, getServiceOptimized, pages_count } from "Adapters/Api/saleServices";
import { useService } from "./helper";
import Svgs from "Assets/svgs";
import DiscountPopup from "Components/TenantApp/Dashboard/Calendar/AddAppointment/DiscountPromotion/DiscountPopup";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import Pagination from "new-components/Pagination";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import NoServiceFound from "Components/Utility/NoServiceFound";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import QuickSalesTableHead from "Components/Utility/Shimmers/QuickSalesTableHead";
import AddServiceGroup from "./AddServiceGroup";
import Shimmer_array from "Constants/Data/Shimmer_array";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";

// import { getservice } from "Adapters/Api/services";
// import { toast } from "react-toastify";
// import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
// import { PRODUCT } from "Redux/TenantRedux/ActionTypes/productTypes";
// import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
// import ProductCard from "../../Elements/ProductCard";
// import NotFound from "../../Elements/NotFound";
// import Input from "new-components/input";

let timeOutId = undefined
const Services = ({ isLoading }) => {
  const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
  const cartFromLocalStorage = getDataFromLocalStorage('cart_items')

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saleService, cartItem, service, business, locations } = useSelector(
    (state) => state
  );
  const state = useSelector((state) => state);
  const servicesRedux = saleService?.saleServices;
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const services = service?.services;
  const cartItems = cartItem.cart_items;
  const reduxServiceGroup = saleService?.servicesgroup;
  const saleServices_updated = saleService?.saleServices_updated;
  const selected_location = state.locations?.selected_location
  const pages = state?.saleService?.page_count
  const allCount = state?.saleService?.allCount
  const isPermissionUpdated = state?.user?.isPermissionUpdated;

  const { setErrors, setService, setIsDelete, setAddService, onSubmitService, serviceHandleChange, removedSelectedEmployee,
    onDeleteHandler, setServiceType, onEditHandler, onBlurHandler, setIsEdit, durationOptions, serviceData, isEdit, active,
    errors, loading, isDelete, ServiceType, serviceModal, removedFromCart, location_currency, locationDetail, availOfferPopup,
    setAvailOfferPopup, discountPromotions, discountPromotionData, addOffer, filterOffer, formRef
  } = useService();

  // const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selected_ids, setSelectedIds] = useState([]);
  const [service_details, setServiceDetails] = useState(undefined);
  const [ServiceDetailPopup, setServiceDetailPopup] = useState(false);
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [OpenCart, setOpenCart] = useState(false);
  const [lastPage, setLastPage] = useState()
  const [pageLoading, setPageLoading] = useState(true)
  const [servicesData, setServiceData] = useState([])
  const [serviceUpdated, setServiceUpdated] = useState(false)
  const [search_text, setSearchText] = useState("");
  const [ServiceGroup, setServiceGroup] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(true)

  const PageSize = 10
  const serviceDetailPrice = service_details ? service_details?.priceservice?.find((itm) => itm?.currency === locationDetail?.currency?.id) : 0

  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);


  useEffect(() => {
    if (search_text === null) {
      // Initial load with empty search_text 
      dispatch(getServiceOptimized(selected_location, currentPage, "", setPageLoading))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(getServiceOptimized(selected_location, currentPage, search_text, setPageLoading))
      }, 500);
    }
  }, [currentPage, search_text, selected_location])

  useEffect(() => {
    setPageLoading(true)
    setCurrentPage(1)
  }, [selected_location])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  useEffect(() => {
    if (servicesRedux?.length > 0) {
      setServiceData(servicesRedux)
      setTimeout(() => {
        setServiceUpdated(true)
      }, 500);
    } else {
      setServiceData([])
      setTimeout(() => {
        setServiceUpdated(true)
      }, 500);
    }
  }, [servicesRedux, servicesRedux?.length])

  useEffect(() => {
    if (reduxServiceGroup?.length === 0) {
      dispatch(getServiceGroup());
    } else {
      const result = cartItems?.map((itm) => itm.id);
      setSelectedIds(result);
    }
  }, [reduxServiceGroup, reduxServiceGroup.length]);

  const handleSelection = (id, price, discount_price) => {
    if (selected_ids.includes(id)) {
      selected_ids.splice(selected_ids.indexOf(id), 1);
    } else {
      selected_ids.push(id);
    }
    setSelectedIds([...selected_ids, id]);
    const result = servicesRedux?.find((itm) => itm.id === id);
    let payloadData = {
      ...result,
      quantity: 1,
      price,
      selection_type: "SERVICE",
    }
    if ((discount_price || discount_price == 0) && discount_price !== undefined) {
      payloadData = { ...payloadData, discount_price }
    }
    dispatch({
      type: "ADD_TO_CART",
      payload: payloadData,
    });
    let updatedCartForLocal = []
    if (cartFromLocalStorage?.length > 0 && cartFromLocalStorage != undefined) {
      updatedCartForLocal = [...cartFromLocalStorage]
    }
    updatedCartForLocal = [...updatedCartForLocal, payloadData]
    storeDataInLocalStorage('cart_items', updatedCartForLocal)
  };

  const service_details_settings = (selected_service) => {
    setServiceDetailPopup(true);
    setServiceDetails(selected_service);
  };


  return !saleServices_updated ? (
    <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 py-5">
      {Shimmer_array()?.map(itm => <ServiceCard animate />)}
    </div>

    // <PreloaderFull />
  ) : (
    <>
      <div className="border rounded-md">
        <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "All Services" : translation[248]?.value ? translation[248]?.value : "All Services"}
              </h2>
              {pageLoading ?
                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                  <p className="rounded-md h-5 w-12 mx-1"> </p>
                </div>
                :
                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                  <p className="text-primary">
                    {allCount ? allCount : 0} {" "}
                    {allCount && allCount > 1 ?
                      selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"
                      : selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"
                    }
                  </p>
                </div>
              }
            </div>
            <div className="mb-00">
              {loadingPermission ?
                <Shimmer className={'w-40 lg:w-44'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                :
                <LoginInput
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Search Service" : translation[1266]?.value ? translation[1266]?.value : "Search Service"}
                  leftIcon={<Svgs.Search />}
                  padding="py-1"
                  value={search_text}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              }
            </div>
          </div>
          {loadingPermission ?
            <QuickSalesTableHead />
            :
            <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">
              {!employee ||
                (employee &&
                  employeePermissions?.sales_apply_offer?.includes("create")) ||
                employeePermissions?.sales_apply_offer?.includes("edit") ? (
                <LoginBtn onClick={() => setAvailOfferPopup(true)}>
                  {selectedLanguage?.name == "English" ? "Available Offer" : translation[1723]?.value ? translation[1723]?.value : "Available Offer"}
                </LoginBtn>
              ) : (
                ""
              )}
              {!employee ? (
                <AddService
                  formRef={formRef}
                  ServiceType={ServiceType}
                  setServiceGroup={setServiceGroup}
                  serviceGroup={reduxServiceGroup}
                  setServiceType={setServiceType}
                  serviceList={services}
                  serviceData={serviceData}
                  isEdit={isEdit}
                  setService={setService}
                  serviceHandleChange={serviceHandleChange}
                  onSubmitService={onSubmitService}
                  active={active}
                  onBlurHandler={onBlurHandler}
                  errors={errors}
                  removedSelectedEmployee={removedSelectedEmployee}
                  serviceModal={serviceModal}
                  setAddService={setAddService}
                  reduxServices={servicesRedux}
                  durationOptions={durationOptions}
                  setIsEdit={setIsEdit}
                  setErrors={setErrors}
                  setIsDelete={setIsDelete}
                  loading={loading}
                  showPlusIcon={true}
                />
              ) : (
                ""
              )}

              {!employee ||
                (employee &&
                  employeePermissions?.sales_apply_offer?.includes("create")) ||
                employeePermissions?.sales_apply_offer?.includes("edit") ? (
                <AddToCart OpenCart={OpenCart} setOpenCart={setOpenCart} />
              ) : (
                ""
              )}
            </div>
          }
        </div>

        <div className="px-6 py-3">
          {pageLoading || !serviceUpdated ?
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 py-5">
              {Shimmer_array()?.map(itm => <ServiceCard animate />)}
            </div>
            :
            <>
              {servicesData && servicesData?.length > 0 ? (
                <>
                  <div className="flex items-center justify-between">
                    <h1 className="text-[#7B7B7B] font-semibold text-sm normal-case">
                      {selectedLanguage?.name == "English" ? "You can select multiple services" : translation[249]?.value ? translation[249]?.value : "You can select multiple services"} <span className='text-red-500'>*</span>
                    </h1>
                    {allCount > 10 &&
                      <div className="flex items-center justify-between gap-4">
                        <LoginBtn
                          onClick={() => {
                            setCurrentPage(currentPage - 1);
                          }}
                          disabled={currentPage === 1}
                          text="Back" />
                        <LoginBtn
                          onClick={() => {
                            setCurrentPage(currentPage + 1);
                          }}
                          disabled={currentPage === lastPage}
                          text="Next" />
                      </div>
                    }
                  </div>
                  <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 py-5">
                    {servicesData?.map((service) => {
                      return (
                        <ServiceCard
                          setOpenCart={setOpenCart}
                          service={service}
                          locationDetail={locationDetail}
                          key={service?.id}
                          onEditHandler={onEditHandler}
                          onDeleteHandler={onDeleteHandler}
                          removedFromCart={removedFromCart}
                          translation={translation}
                          selectedLanguage={selectedLanguage}

                          currency={location_currency}
                          checked={selected_ids.includes(service.id)}
                          onClick={(price, discount_price) =>
                            handleSelection(service?.id, price, discount_price)
                          }
                          view_details={() => service_details_settings(service)}
                        />
                      );
                    })}
                  </div>
                </>
              ) : (
                <NoServiceFound />
              )}
            </>
          }
        </div>
        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          totalCount={Math.ceil(pages)}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
          setLastPage={setLastPage}
        />
      </div>

      {isDelete && (
        <DeleteConfirmation
          handleClose={() => setIsDelete(false)}
          handleDelete={onDeleteHandler}
        />
      )}

      {ServiceDetailPopup && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Service Details" : translation[297]?.value ? translation[297]?.value : "Service Details"}
          close={() => setServiceDetailPopup(false)}
        >
          <div className="grid md:grid-cols-5 gap-4">
            <div className="flex justify-center">
              {service_details?.image
                ?
                <div className="h-[6rem] w-[6rem] overflow-hidden flex items-center justify-center bg-gray-50 border rounded-[0.625rem]">
                  <img src={service_details?.image} />
                </div>
                :
                <div className="h-[6rem] w-[6rem] overflow-hidden flex items-center justify-center bg-gray-50 border rounded-[0.625rem]">
                  <Svgs.NewService size='2.5rem' />
                </div>
              }
              {/* <CircleIcon className="!h-[6rem] !w-[6rem]">
                <Svgs.NewService />
              </CircleIcon> */}
            </div>
            <div className="flex flex-col gap-6 md:col-span-4">
              <div className="grid md:grid-cols-2 gap-2">
                <DetailPopupDetails
                  title={selectedLanguage?.name == "English" ? "Service Name" : translation[260]?.value ? translation[260]?.value : "Service Name"}
                  desc={service_details?.name}
                />
                <DetailPopupDetails
                  title={selectedLanguage?.name == "English" ? "Price" : translation[200]?.value ? translation[200]?.value : "Price"}
                  desc={
                    <>
                      {location_currency}{" "}{serviceDetailPrice?.price ? serviceDetailPrice?.price?.toFixed(2) : 0}
                    </>
                  }
                />
              </div>
              <DetailPopupDetails
                title={selectedLanguage?.name == "English" ? "Service Group" : translation[162]?.value ? translation[162]?.value : "Service Group"}
                desc={
                  <>
                    {service_details?.avaliableservicegroup?.length > 0 ? service_details?.avaliableservicegroup[0]?.name : "----"}
                  </>
                }
              />
              <DetailPopupDetails
                title={selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description"}
                desc={service_details?.description ? service_details?.description : "-----"}
              />
            </div>
          </div>
        </Popup>
      )}
      {availOfferPopup && (
        <DiscountPopup
          setServiceData={setServiceData}
          MODULE_TYPE={'SERVICE'}
          availOfferPopup={availOfferPopup}
          setAvailOfferPopup={setAvailOfferPopup}
          discountPromotionData={discountPromotionData}
          discountPromotions={discountPromotions}
          addOffer={addOffer}
          filterOffer={filterOffer}
          selected={serviceData.avail_service}
          quickSale={true}
          servicesRedux={servicesRedux}
        />
      )}

      {
        ServiceGroup && (
          <AddServiceGroup
            onClose={() => {
              setServiceGroup(false);
            }}
            update={false}
            open={ServiceGroup}
            addSuccess={() => {
              dispatch(getServiceGroupPaginationDropdown(1))
            }}
          />
        )
      }
    </>
  );
};

export default Services;
