import { useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import useValidations from 'Hooks/useValidations';
import { createCampaign, updateCampaign } from 'Adapters/Api/campaigns';
import useObjectKeys from 'Hooks/useObjectKeys';
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { get_all_segments, get_segment_dropdown_pagination } from 'Adapters/Api/segments';
import { useEffect } from 'react';

let initialState = {
    title: "",
    content: "",
    campaign_type: "",
    segment: '',
}
let segmentTimeOutId = undefined;

const useCompaigntPopup = ({ setCreateCompanign, data, setIsEdit, isEdit, setApiCall }) => {
    const { objectKeyConvertToArray } = useObjectKeys();
    const dispatch = useDispatch()
    const state = useSelector((state) => state);
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const segmentDropdown = state?.segments?.segmentDropdown;
    const segmentDropdownPagination = state?.segments?.segmentDropdownPagination;

    const [campaignForm, setCampaignForm] = useState(initialState)
    // Date Range
    const [indexValue, setIndexValue] = useState()
    const [start_date, setStartDate] = useState()
    const [end_date, setEndDate] = useState()
    const [toggleDateRange, setToggleDateRange] = useState(false)
    const { setErrors, validation, errors, onBlurHandler } = useValidations();
    const [submitLoader, setSubmitLoader] = useState(false)
    const [segmentSearch, setSegmentSearch] = useState('')
    const [segmentLoader, setSegmentLoader] = useState(false)
    const [segmentPage, setSegmentPage] = useState(1)

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language


    useEffect(() => {
        if (data && isEdit) {
            setCampaignForm(data)
            setIndexValue(1)
            setStartDate(data?.start_date)
            setEndDate(data?.end_date)
        }
    }, [data, isEdit])

    const onChangeHandler = (e) => {
        if (e?.target) {
            const { name, value } = e.target;
            setCampaignForm((prev) => ({ ...prev, [name]: value }));
            setErrors((prev) => ({ ...prev, [name]: "" }))
        }
    }

    const getSegmentDropdown = () => {
        const payload = { page: segmentPage, search_text: segmentSearch }
        dispatch(get_segment_dropdown_pagination(access_token, payload, setSegmentLoader))
    }

    useEffect(() => {
        if (segmentSearch === null) {
            // Initial load with empty search_text
            getSegmentDropdown()
        } else {
            if (segmentTimeOutId) {
                clearTimeout(segmentTimeOutId)
            }
            segmentTimeOutId = setTimeout(() => {
                getSegmentDropdown()
            }, 500);
        }
    }, [segmentSearch, segmentPage])

    const onSubmitHandler = () => {
        const { title, content, campaign_type, segment } = campaignForm

        let requiredFields = {}
        let date_range = { end_date, start_date }

        if (!end_date || !start_date) {
            let date_range = { end_date, start_date }
            requiredFields = { title, content, campaign_type, segment, date_range }
        } else {
            requiredFields = { title, content, campaign_type, segment }
        }
        setErrors(validation(requiredFields));
        let result = validation(requiredFields);

        if (objectKeyConvertToArray(result)?.length === 0) {
            setSubmitLoader(true)
            const success = () => {
                setApiCall(title)
                setCampaignForm(initialState)
                setCreateCompanign(false)
                setIsEdit(false)
                setSubmitLoader(false)
            }
            const fail = () => {
                setSubmitLoader(false)
            }
            if (isEdit) {
                dispatch(updateCampaign({ ...campaignForm, ...date_range }, access_token, success, fail))
            } else {
                dispatch(createCampaign({ ...campaignForm, ...date_range }, access_token, success, fail))
            }
        }
    }

    return {
        moment, submitLoader,
        indexValue, setIndexValue,
        setErrors, start_date, setStartDate,
        end_date, setEndDate,
        campaignForm, onChangeHandler,
        toggleDateRange, setToggleDateRange, onBlurHandler,
        translation, selectedLanguage, errors,
        onSubmitHandler, segmentDropdown,
        segmentSearch, setSegmentSearch,
        segmentLoader, segmentPage, setSegmentPage, segmentDropdownPagination
    }
}

export default useCompaigntPopup