import { addExpensePayment, updateExpensePayment } from "Adapters/Api/expense"
import useObjectKeys from "Hooks/useObjectKeys"
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage"
import useValidations from "Hooks/useValidations"
import { useLocation } from "new-components/location/helper"
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const initialData = {
    amount: '',
    file: '',
    date: '',
    payment_note: ''
}

const useExpensePayment = (close, data, onSuccessAPICall, editData, isEdit, setEditData, setIsEdit) => {
    const dispatch = useDispatch()
    const { objectKeyConvertToArray } = useObjectKeys()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')
    const { errors, validation, setErrors, } = useValidations()
    const state = useSelector(state => state)
    const { selected_location } = useLocation()

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const location_currency = state?.utility?.currency?.location_currency;

    const [expensePaymentData, setExpensePaymentData] = useState(initialData)
    const [loading, setLoading] = useState(false)

    const onChangeHandler = (e) => {
        if (e?.target) {
            const { name, value } = e.target;
            if (name == 'file') {
                setExpensePaymentData((prev) => ({ ...prev, 'file': e.target.files[0] }));
                setErrors((prev) => ({ ...prev, 'file': "" }))
            } else {
                setExpensePaymentData((prev) => ({ ...prev, [name]: value }));
                setErrors((prev) => ({ ...prev, [name]: "" }))
            }
        }
    }
    const SubmitExpense = () => {
        const { amount, file, date, payment_note } = expensePaymentData
        let required_field = { amount, date, payment_note }

        setErrors(validation(required_field));
        let result = validation(required_field);
        if (objectKeyConvertToArray(result)?.length === 0) {
            setLoading(true)
            const payload = {
                ...expensePaymentData,
                location: selected_location,
                payment_method: data?.payment_method,
                expense: data?.id
            }
            const success = () => {
                onSuccessAPICall && onSuccessAPICall()
                setExpensePaymentData(initialData)
                close && close(false)
                setLoading(false)
            }
            const fail = () => {
                setLoading(false)
            }
            isEdit
                ? dispatch(updateExpensePayment(access_token, payload, success, fail))
                : dispatch(addExpensePayment(access_token, payload, success, fail))
        }
    }

    const onClose = () => {
        setIsEdit && setIsEdit(false)
        setEditData && setEditData({})
        close && close(false)
        setErrors()
        setExpensePaymentData()
    }

    useEffect(() => {
        if (editData?.id) {
            setExpensePaymentData({
                id: editData?.id,
                amount: editData?.amount,
                file: editData?.file,
                date: editData?.date,
                payment_note: editData?.payment_note,
                file_type: editData?.file_type,
            });
        } else {
            if (data?.id) {
                console.log("🚀 ~ useEffect ~ data:")
                setExpensePaymentData((prev) => ({ ...prev, 'amount': data?.due_amount == 0 ? data?.total_amount : data?.due_amount }));
            }
        }
    }, [editData, data])

    return {
        SubmitExpense, onChangeHandler, errors, expensePaymentData,
        onClose,
        translation, selectedLanguage, location_currency, loading, setErrors
    }
}

export default useExpensePayment