import React from 'react'

const InputSection = ({ className, leftText, title, required, type, name, placeholder, value, onChange, onBlur, error, disabled ,customClass }) => {

    return (
        <div className='flex flex-col gap-2 w-full'>
        {title ? (
            <div className="text-sm flex items-center gap-1">
                <div>{title}</div> {required && title && <span className="leading-[1] text-red-600"> *</span>}
            </div>
        ) : ""}
        <div className={`${error && "border-[#eb3b3b]"} ${className} w-full border rounded-md text-sm outline-none focus:border-primary ease-in transition-all focus:border-l-[7px] ${customClass ? '' : "lg:pr-7 pr-7 " } flex items-center gap-3`}>
            {leftText && <div className='text-[#A1A1A1] pl-3 text-sm'>{leftText}</div>}
            <input name={name} value={value} onChange={onChange} type={type} onBlur={onBlur} placeholder={placeholder} className={`${customClass ? customClass : ''} lg:px-4 px-2 py-[0.8rem]  border-l pl-2 outline-none disabled:cursor-not-allowed placeholder:text-sm placeholder:text-[#A1A1A1] text-primary w-full`} />
        </div>
        </div>
    )
}

export default InputSection