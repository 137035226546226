import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage';
import { toast } from 'react-toastify';
import { createCopyServiceTarget, getServiceTarget, getServiceTargetWithNoPagination } from 'Adapters/Api/serviceTarget';
import { MONTHS } from 'Constants/Data/Months';
import { apiErrorMessage } from 'Constants/Data/Errors';
import useServiceTarget from '../helper';

function useCopyService({ onClose }) {
  const { service_target_fiter, setLoader, selected_location, searchText, monthFilter, searchYear, serviceGroup,
    setCurrentPage } = useServiceTarget();
  const [toMonth, setToMonth] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isValid, errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onBlur"
  })

  const onApplyFilter = (data) => {
    setIsLoading(true);
    const body = {
      from_year: data?.from_year,
      from_month: data?.from_month,
      to_year: data?.to_year,
      to_month: data?.to_month,
    }

    // Check if from_year and from_month exist in any object of the array
    const existsInArray = service_target_fiter()?.some(item => {
      const date = new Date(item.year);
      const year = date.getFullYear();
      return year == data?.from_year && item.month?.toLowerCase() == data?.from_month?.toLowerCase();
    });

    if (!existsInArray) {
      setIsLoading(false);
      toast.error('No record found', { toastId: "toast" })
    }
    else {
      dispatch(
        createCopyServiceTarget(
          { ...body },
          access_token,
          (message) => {
            setIsLoading(false);
            toast.success(message ? message : "Copy Service Target Created successfully!", { toastId: "toast" });
            onClose();
            setCurrentPage(1)
            dispatch(getServiceTargetWithNoPagination(selected_location))
            dispatch(getServiceTarget(selected_location, 1, searchText, monthFilter, searchYear, serviceGroup, setLoader))
          },
          () => {
            setIsLoading(false);
            toast.error(apiErrorMessage, { toastId: "toast" })
          }

        )
      )
    }
  }
  const monthDropdown = useMemo(() => {
    let months = MONTHS?.map((month, i) => {
      return {
        label: month,
        value: month,
      };
    })
    return months;
  }, [])

  useEffect(() => {
    let fromMonth = watch('from_month');
    if (fromMonth !== undefined || fromMonth !== null) {
      setToMonth(monthDropdown.filter((obj) => obj.value !== watch('from_month')))
    }
  }, [watch('from_month')])

  const yearDropdown = useMemo(() => {
    let date = new Date();
    let this_year = date?.getFullYear();
    if (this_year) {
      let years = [];
      for (let i = this_year; i <= 2040; i++) {
        years.push({ label: i.toString(), value: i.toString() })
      }
      return years;
    }
  }, [])

  return {
    control,
    isValid, errors, toMonth, monthDropdown, isLoading, yearDropdown,
    reset, setIsLoading, watch,
    onApplyFilter, handleSubmit
  }
}

export default useCopyService

export const validationSchema = yup.object().shape({
  from_month: yup.string().required("This field is required!").nullable(),
  to_month: yup.string().required("This field is required!").nullable(),
  from_year: yup.string().required("This field is required").nullable(),
  to_year: yup.string().required("This field is required").nullable(),
})
