import { getPaymentMethodsData } from "Adapters/Api/posAnalytics";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const usePaymentMethodsReport = () => {
    const navigate = useNavigate();

    const state = useSelector((state) => state);
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language
    const selected_location = state?.locations?.selected_location;
    const isPermissionUpdated = state?.user?.isPermissionUpdated;

    const [loader, setLoader] = useState(true)
    const [paymentMethodsData, setPaymentMethodsData] = useState({})
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [toggleDateRange, setToggleDateRange] = useState(false)
    const [loadingPermission, setLoadingPermission] = useState(true)

    const getFinanceData = () => {
        setLoader(true)
        const payload = {
            location_id: selected_location,
            start_date: startDate,
            end_date: endDate
        };

        const success = (result) => {
            const data = result?.response?.data
            setPaymentMethodsData(data)
            setLoader(false)
        };
        const fail = () => {
            setLoader(false)
        }
        getPaymentMethodsData(payload, success, fail);
    };

    useEffect(() => {
        setLoadingPermission(!isPermissionUpdated)
    }, [isPermissionUpdated])

    useEffect(() => {
        if (selected_location) {
            setLoader(true)
            getFinanceData();
        }
    }, [selected_location, startDate, endDate]);


    return {
        translation, selectedLanguage, navigate, startDate, setStartDate, endDate, setEndDate, loader, paymentMethodsData,
        toggleDateRange, setToggleDateRange, loadingPermission
    }
}

export default usePaymentMethodsReport