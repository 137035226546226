// import { get_all_segments } from "Adapters/Api/segments"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

const useConversion= () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const [loading, setLoading] = useState(true)
    const [CreateConversion, setCreateConversion] = useState(false);
    const [ViewDetails, setViewDetails] = useState(false);
     
    const segment = state?.segments?.segments;


    const getConversion = () => {
        const success = () => { }
        const fail = () => { }

        // dispatch(get_all_segments(success, fail))
    }

    useEffect(() => {
        getConversion()
    }, [])


    // Chart Dummy Data
const data = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'My Line Chart',
        data: [10, 20, 15, 25, 30],
        backgroundColor: ['#000','#5679FF'], // Set the background color
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 2,
        fill: origin, // Enable background fill
      },
    ],
  };
  const options = {
    scales: {
      x: {
        display: false, // Hide x-axis labels and grid
      },
      y: {
        display: false, // Hide y-axis labels and grid
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the title label and box
      },
    },
  };


    return {
        loading,
        data,options,
        CreateConversion, setCreateConversion,
        ViewDetails, setViewDetails,
    }
}

export default useConversion