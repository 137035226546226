import React, { useState, useEffect } from "react";

import Svgs from "Assets/svgs";

const Textarea = ({
  name,
  value,
  title,
  onBlur,
  onChange,
  disabled,
  register,
  rows,
  placeholder,
  errorMessage,
}) => {
  return (
    <div className="flex flex-col gap-2 ">
      {title && <div className="text-sm flex items-center gap-1">{title} {title && <span className="leading-[1] text-red-600"> *</span>}</div>}
      <div className="relative">
        <textarea
          disabled={disabled}
          onBlur={onBlur}
          value={value}
          name={name}
          onChange={onChange && onChange}
          rows={rows || 10}
          placeholder={placeholder}
          className={`border ${
            errorMessage && " border-l-[7px] border-[#eb3b3b]"
          } disabled:cursor-not-allowed rounded-md text-sm outline-none lg:px-4 px-2 py-[0.8rem] bordercol placeholder:text-[#A1A1A1] text-primary focus:border-primary ease-in transition-all focus:border-l-[7px] w-full lg:pr-8 pr-7`}
          {...(register && register(name))}
        ></textarea>
        {errorMessage && (
          <span
            className={
              "text-[#eb3b3b] flex items-center gap-1 text-xs absolute top-[103%] w-full justify-end"
            }
          >
            <Svgs.I fill="#eb3b3b" />
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  );
};

Textarea.defaultProps = {
  counter: true,
};

export default Textarea;
