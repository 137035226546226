export const BUSINESS_SETTING = { 
    GET_BUSINESS : "GET_BUSINESS",
    UPDATE_BUSINESS : "UPDATE_BUSINESS",

    GET_LOCATION_IN_BUSINESS : "GET_LOCATION_IN_BUSINESS",
    ADD_LOCATION_IN_BUSINESS : "ADD_LOCATION_IN_BUSINESS",
    UPDATE_LOCATION_IN_BUSINESS : "UPDATE_LOCATION_IN_BUSINESS",
    DELETE_LOCATION_IN_BUSINESS : "GET_LOCATION_IN_BUSINESS",


    GET_BUSINESS_TYPES : 'GET_BUSINESS_TYPES',
    ADD_BUSINESS_INFORMATIONS : 'ADD_BUSINESS_INFORMATIONS',
    UPDATE_BUSINESS_PROFILE_COMPLETION_PROGRESS : 'UPDATE_BUSINESS_PROFILE_COMPLETION_PROGRESS',

}