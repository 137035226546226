import React from 'react'
import Svgs from 'Assets/svgs'

const AccordionDropdown = ({ title, title2, checked, name, value, onClick, disabled, textClass, label , mainClass}) => {
  return (
    <div className={`flex items-center justify-between transition-all py-3 border-b border-[#E5E7EB] bg-[#FCFCFF] ${mainClass}`}>
        {title && <p onClick={()=> onClick()} className={`text-sm font-semibold cursor-pointer ${textClass}`}>{title}</p>}
        <button onClick={()=> onClick()} className={`${checked?"":"-rotate-90"} transition-all duration-300`}> 
          <Svgs.Chevron_down />
        </button>
        
        {title2 && <p className={`text-sm font-semibold cursor-pointer pl-2 ${textClass}`}>{ title2}</p>}
    </div>
  )
}

export default AccordionDropdown