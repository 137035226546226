import React, { useMemo } from "react";
import Dropwdown from "Components/TenantApp/Elements/Dropwdown";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import Popup from "Components/TenantApp/Elements/Popup";
import Textarea from "Components/TenantApp/Elements/Textarea";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import moment from "moment/moment";
import { useAbsence } from "./helper";
import { useSelector } from "react-redux";
import PaginationDropdown from "Components/TenantApp/Elements/PaginationDropdown";

function AddAbsence({ handleClose, editData, editMode }) {
    const {
        absenceHandleChange,
        absence,
        setAbsence,
        employeeAbsenceDropdownList,
        onBlurHandler,
        errors,
        disbledValidation,
        onSubmitHandler,
        loading,
        onSubmitEditHandler,
        isEdit,
        setIsEdit,
        date,
        employeeSearch, setEmployeeSearch, employeePage,
        setEmployeePage, employeeLoader, setEmployeeLoader, employeePaginationData, employeeRedux
    } = useAbsence();
    const { staff, from, to, description } = absence;

    const state = useSelector((state) => state)

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    useMemo(() => {
        if (editData && editMode) {
            setAbsence({
                ...absence,
                staff: editData?.employee?.id,
                from: editData?.from_date,
                to: editData?.to_date,
                description: editData?.note,
                schedule_id: editData?.id,
            });
            setIsEdit(true);
        }
    }, [editMode]);

    var minToDateLimit = useMemo(() => {
        return moment(from).add(1, 'day').format('YYYY-MM-DD')
    }, [from]);

    return (
        <>
            <Popup
                heading={`${isEdit && editData ? "Update Absence" :
                    selectedLanguage?.name == "English" ? "Add Absence" : translation[628]?.value ? translation[628]?.value : "Add Absence"
                    }`}
                onclose={handleClose}
                size="md"
            >
                <div className="flex flex-col gap-4">
                    <PaginationDropdown
                        error={errors?.staff}
                        setPage={setEmployeePage}
                        page={employeePage}
                        setDropdownSearch={setEmployeeSearch}
                        dropdownSearch={employeeSearch}
                        lastPage={employeePaginationData?.employeePages}
                        currentPage={employeePaginationData?.employeeCurrentPage}
                        title={selectedLanguage?.name == "English" ? "Staff" : translation[335]?.value ? translation[335]?.value : "Staff"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Staff" : translation[334]?.value ? translation[334]?.value : "Select Staff"}
                        name={"staff"}
                        value={staff}
                        onChange={(e) => {
                            absenceHandleChange(e);
                        }}
                        loading={employeeLoader}
                        // customHeight={'h-[1.25rem]'}
                        custom_min_width={'sm:min-w-[15rem] min-w-[20rem]'}
                        options={[
                            ...employeeRedux?.map((empl, i) => {
                                return {
                                    label: empl.full_name,
                                    value: empl.id,
                                    otherData: {
                                        image: empl?.image,
                                        DataOne: empl.email,
                                        showImage: true,
                                        index: i
                                    },
                                    email: empl.email,
                                    mobile_number: empl.mobile_number
                                };
                            })
                        ]}
                    />
                    {/* <Dropwdown
                        required={true}
                        name="staff"
                        value={staff}
                        onBlur={() => onBlurHandler({ staff: staff })}
                        options={employeeAbsenceDropdownList}
                        title={selectedLanguage?.name == "English" ? "Staff" : translation[335]?.value ? translation[335]?.value : "Staff"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Staff" : translation[334]?.value ? translation[334]?.value : "Select Staff"}
                        onChange={(e) => {
                            absenceHandleChange(e);
                        }}
                        error={errors?.staff}
                         />  */}

                    <div className="grid md:grid-cols-2 gap-4">
                        <LoginInput
                            min={moment(date).format("YYYY-MM-DD")}
                            max={"2050-01-01"}
                            name="from"
                            value={from}
                            title={selectedLanguage?.name == "English" ? "From Date" : translation[635]?.value ? translation[635]?.value : "From Date"}
                            type="date"
                            required={true}
                            onBlur={() => onBlurHandler({ from: from })}
                            placeholder="Enter Value"
                            onChange={(e) => {
                                absenceHandleChange(e);
                            }}
                            error={errors?.from}
                        />
                        <LoginInput
                            min={minToDateLimit}
                            max={"2050-01-01"}
                            name="to"
                            value={to}
                            title={selectedLanguage?.name == "English" ? "To Date" : translation[636]?.value ? translation[636]?.value : "To Date"}
                            type="date"
                            onBlur={() => onBlurHandler({ to: to })}
                            required={false}
                            placeholder="Enter Value"
                            onChange={(e) => {
                                absenceHandleChange(e);
                            }}
                            error={errors?.to}
                        />
                    </div>
                    {/* <Textarea
                        name="description"
                        value={description}
                        title={selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description"}
                        // onBlur={() => onBlurHandler({ description: description })}
                        onChange={(e) => {
                            absenceHandleChange(e);
                        }}
                    // error={errors?.description}
                    /> */}
                    <LoginBtn
                        loading={loading}
                        disabled={loading}
                        // disabled={disbledValidation}
                        text={`${editData && isEdit ?
                            selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                            : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"
                            }`}
                        onClick={() => {
                            if (editData && isEdit) {
                                onSubmitEditHandler(handleClose, editData);
                            } else {
                                onSubmitHandler(handleClose);
                            }
                        }}
                    />
                </div>
            </Popup>
        </>
    );
}

export default AddAbsence;
