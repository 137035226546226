import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteAttendance, getAttendanceData } from "../../../../../Adapters/Api/attendance";
import useObjectKeys from "../../../../../Hooks/useObjectKeys";
import useSetDataInLocalStorage from "../../../../../Hooks/useSetDataInLocalStorage";
import useValidations from "../../../../../Hooks/useValidations";
import { removeAttendance } from "../../../../../Redux/TenantRedux/Actions/attendanceActions";

function useAttendance() {
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const dispatch = useDispatch();
  const { setErrors, validation, errors, onBlurHandler } = useValidations();
  const { objectKeyConvertToArray } = useObjectKeys();
  const access_token = getDataFromLocalStorage("access_token");
  const businessData = getDataFromLocalStorage("business_data");

  const [isEdit, setIsEdit] = useState(false);
  const [attendace, setAttendanceModal] = useState(false);
  const [DateRangePopup, setDateRangePopup] = useState("");
  const [isDelete, setDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedDateRange, setSelectedDataRange] = useState({
    from: "",
    to: "",
  });

  const [lieuDayAdded, setLieuDayAdded] = useState(false)

  // **************************** date range on change handler *************************
  const dateRangeHandleChange = (e) => {
    if (e?.target) {
      const { name, value } = e.target;
      setSelectedDataRange((prev) => ({ ...prev, [name]: value }));
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  // *******************  selected Range submission handler ************************************
  const onDateRangeSubmit = () => {
    setErrors(validation(selectedDateRange));
    let result = validation(selectedDateRange);
    if (objectKeyConvertToArray(result)?.length === 0) {
      setDateRangePopup("");
    }
  };

  // **************************** delete Attendance *********************************
  // const attendanceDeleteHandler = async (id) => {
  //   if (deleteId && isDelete) {
  //     let response = await deleteAttendance(deleteId, access_token);
  //     if (response.status_code === 200) {
  //       toast.success(response?.response?.message);
  //       dispatch(removeAttendance(deleteId));
  //       setDelete(false);
  //       setDeleteId();
  //     }
  //   } else {
  //     setDelete(true);
  //     setDeleteId(id);
  //   }
  // };

  const attendanceDeleteHandler = async (id) => {
    if (deleteId && isDelete) {
      setDeleteLoading(true);
      let response = await deleteAttendance(deleteId, access_token);

      if (response.status === 200) {
        toast.success("Attendance delete Successfully!", { toastId: "toast" });
        dispatch(getAttendanceData(currentPage));
        setDelete(false);
        setDeleteId();
        setDeleteLoading(false);
      } else {
        setDeleteLoading(false);
        toast.error("Something went wrong.", { toastId: "toast" })
      }
    }
    else {
      setDelete(true);
      setDeleteId(id);
    }
  };

  // *******************  Attendance modal handler ************************************
  // const attendanceModalToggler = () => {
  //     setAttendanceModal(!attendace)
  //     setIsEdit(false);
  // }

  // **************************** Eidt Attendance *********************************
  // const attendanceEditHandler = async (id) => {
  // storeDataInLocalStorage("attendance_id", id)
  // attendanceModalToggler();
  // setIsEdit(true);
  // const attendanceRedux = attendanceRedux.find((item) => item.id === id);
  // if (attendanceRedux) {
  // }
  // }

  return {
    attendanceDeleteHandler,
    dateRangeHandleChange,
    selectedDateRange,
    DateRangePopup,
    setDateRangePopup,
    errors,
    onBlurHandler,
    onDateRangeSubmit,
    isDelete,
    setDelete, deleteId, setDeleteId,
    currentPage, setCurrentPage,
    deleteLoading,
    lieuDayAdded, setLieuDayAdded,
    // attendanceEditHandler,
    // setIsEdit, attendanceModalToggler
  };
}

export default useAttendance;
