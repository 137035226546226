


const StrengthBar = ({ index, value, active }) => {
    return (
        <>
            <div className={`flex-1 h-[5px] rounded-sm  ${active ? 'bg-[#5679FF]' : 'bg-[#E1E4ED]'}`}>
            </div>
        </>
    )
}


const PasswordValidationBar = ({ value }) => {
    return (
        <>
            <div>
                <div className="flex items-center justify-between gap-2 mt-3">
                    {
                        Array(4).fill(1).map((bar, index) => {
                            return (
                                <>
                                    <StrengthBar
                                        value={value}
                                        index={index}
                                        key={index}
                                        active={(value > (25 * index)) ? true : false}
                                    />
                                </>
                            )
                        })
                    }
                </div>
                <div>
                    <p className="text-xs mt-2 text-right">{
                        value > 0 ?
                            value == 25 ?
                                'weakest'
                                :
                                value == 50 ?
                                    'week'
                                    :
                                    value == 75 ?
                                        'strong'
                                        :
                                        'Perfect'
                            :
                            'weakest'
                    }</p>
                </div>
            </div>
        </>
    )
}

PasswordValidationBar.defaultProps = {
    value: 0
}

export default PasswordValidationBar