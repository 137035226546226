import Svgs from 'Assets/svgs'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'
import Popup from 'Components/TenantApp/Elements/Popup'
import NotFound from 'Components/Utility/NotFound'
import React from 'react'
import { useState } from 'react'
import CreatePaymentPopup from '../CreatePayment'
import useSetDataInLocalStorage from 'Hooks/useSetDataInLocalStorage'
import { useDispatch, useSelector } from 'react-redux'
import { deleteExpensePayment, getExpensePayment } from 'Adapters/Api/expense'
import { useEffect } from 'react'
import TableShimer from 'Components/Utility/Shimmers/TableShimer'
import DeleteConfirmation from 'new-components/delete-popup'

const ViewPaymentPopup = ({ selectedLanguage, translation, onSuccessAPICall, open, data, close }) => {
    const [createPaymentPopup, setCreatePaymentPopup] = useState(false)
    const [loader, setLoader] = useState(true)
    const [editPayment, setEditPayment] = useState()
    const [isEdit, setIsEdit] = useState()
    const [deleteLoading, setDeleteLoading] = useState(false)

    const state = useSelector(state => state)
    const expenseDetail = state?.expense?.expensePayment

    const dispatch = useDispatch()
    const { getDataFromLocalStorage } = useSetDataInLocalStorage()
    const access_token = getDataFromLocalStorage('access_token')

    const getExpensePaymentDetails = () => {
        setLoader(true)
        const success = () => { setLoader(false) }
        const fail = () => { setLoader(false) }
        dispatch(getExpensePayment(access_token, { expense_id: data?.id }, success, fail))
    }
    const [deletePopup, setDeletePopup] = useState()

    const onDeleteClick = () => {
        setDeleteLoading(true)
        const success = () => {
            setDeleteLoading(false)
            setDeletePopup()
            onSuccessAPICall && onSuccessAPICall()
        }
        const fail = () => { setDeleteLoading(false) }
        dispatch(deleteExpensePayment(access_token, deletePopup, success, fail))
    }

    useEffect(() => {
        if (open)
            getExpensePaymentDetails()
    }, [open, data])


    return (
        <>
            <Popup
                heading={selectedLanguage?.name == "English" ? "View Payment" : translation[1771]?.value ? translation[1771]?.value : "View Payment"}
                open={open}
                size={'lg'}
                close={close}
            >
                <div className="flex flex-col gap-4">
                    <div className="grid grid-cols-2">
                        <div className="flex flex-col">
                            <h1>
                                <b>
                                    {selectedLanguage?.name == "English" ? "Reference No" : translation[1773]?.value ? translation[1773]?.value : "Reference No"}
                                </b>
                            </h1>
                            <p>{data?.reference_number}</p>
                            <p>
                                <b>{selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}</b>: { }
                                {data?.location_name}
                            </p>
                        </div>
                        <div className="flex flex-col">
                            <p>
                                <b>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</b>: { }
                                {data?.date}
                            </p>
                            <p>
                                <b>{selectedLanguage?.name == "English" ? "Payment Status" : translation[1748]?.value ? translation[1748]?.value : "Payment Status"}</b>: { }
                                {data?.status}
                            </p>
                        </div>
                    </div>

                    {data?.status == 'Due' &&
                        <div className='flex justify-end'>
                            <LoginBtn
                                className={'gap-3'}
                                onClick={() => setCreatePaymentPopup(true)}
                            >
                                <Svgs.Plus />
                                {selectedLanguage?.name == "English" ? "Add Payment" : translation[1772]?.value ? translation[1772]?.value : "Add Payment"}
                            </LoginBtn>
                        </div>
                    }


                    {loader
                        ? <TableShimer cols={6} rows={4} />
                        : <div className="overflow-x-auto rounded-lg">
                            <div className="inline-grid gap-2 border-b xl:grid grid-cols-[repeat(7,minmax(120px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                                <p>{selectedLanguage?.name == "English" ? "Date" : translation[417]?.value ? translation[417]?.value : "Date"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Reference No" : translation[1773]?.value ? translation[1773]?.value : "Reference No"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Amount" : translation[793]?.value ? translation[793]?.value : "Amount"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Payment Method" : translation[742]?.value ? translation[742]?.value : "Payment Method"}</p>
                                <p className='col-span-2'>{selectedLanguage?.name == "English" ? "Payment Note" : translation[1752]?.value ? translation[1752]?.value : "Payment Note"}</p>
                                <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
                            </div>
                            {expenseDetail?.length == 0
                                ? <NotFound />
                                : expenseDetail?.map(itm => {
                                    return (
                                        <div className="inline-grid gap-2 items-center xl:grid grid-cols-[repeat(7,minmax(120px,1fr))] px-12 py-3 text-sm font-semibold min-w-full w-fit">
                                            <p>{itm?.date} </p>
                                            <p>{itm?.reference_number} </p>
                                            <p>{itm?.amount} </p>
                                            <p>{itm?.payment_method} </p>
                                            <p className='col-span-2'>{itm?.payment_note} </p>
                                            <div className='flex gap-1'>
                                                <div
                                                    onClick={() => {
                                                        setIsEdit(true)
                                                        setEditPayment(itm)
                                                        setCreatePaymentPopup(true)
                                                    }}
                                                    className="h-[2rem] w-[2rem] border border-[#E1E7FF] rounded-lg bg-[#F8FBFB] flex items-center justify-center cursor-pointer"
                                                >
                                                    <span>
                                                        <Svgs.Pen />
                                                    </span>
                                                </div>
                                                <div
                                                    onClick={() => setDeletePopup(itm?.id)}
                                                    className="h-[2rem] w-[2rem] border border-[#E1E7FF] rounded-lg bg-[#F8FBFB] flex items-center justify-center cursor-pointer"
                                                >
                                                    <span>
                                                        <Svgs.Delete fill="#CE0000" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </Popup>
            <CreatePaymentPopup
                open={createPaymentPopup}
                close={setCreatePaymentPopup}
                data={data}
                editData={editPayment}
                isEdit={isEdit}
                setEditData={setEditPayment}
                onSuccessAPICall={() => {
                    getExpensePaymentDetails()
                    onSuccessAPICall && onSuccessAPICall()
                }}
            />

            {deletePopup &&
                <DeleteConfirmation
                    handleClose={() => setDeletePopup()}
                    heading={selectedLanguage?.name == "English" ? "Are you sure you want to delete this expense?" : translation[1778]?.value ? translation[1778]?.value : "Are you sure you want to delete this expense?"}
                    description={`
                        ${selectedLanguage?.name == "English" ? "Are you sure you want to delete this expense?" : translation[1778]?.value ? translation[1778]?.value : "Are you sure you want to delete this expense?"}
                        ${selectedLanguage?.name == "English" ? "You won't be able to see this again." : translation[745]?.value ? translation[745]?.value : "You won't be able to see this again."}`}
                    isLoading={deleteLoading}
                    handleDelete={onDeleteClick}
                />
            }
        </>
    )
}

export default ViewPaymentPopup