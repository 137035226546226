import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetGiftCards,
  createMembers,
  getMembers,
  getQuickSalesMembership,
  updateMember,
} from "../../../../../../Adapters/Api/vouchers";
import { useLocation } from "new-components/location/helper";
import Svgs from "../../../../../../Assets/svgs";
import { PRODUCT } from "../../../../../../Redux/TenantRedux/ActionTypes/productTypes";
import LoginBtn from "../../../../Elements/LoginBtn";
import { toast } from "react-toastify";
import LoginInput from "../../../../Elements/LoginInput";
import MembershipCard from "../../Elements/MembershipCard.jsx";
import AddToCart from "../../Elements/AddToCart";
import Popup from "../../../../../../new-components/popup/index";
import CircleIcon from "../../../../Elements/CircleIcon";
import DetailPopupDetails from "../../Elements/DetailPopupDetails";
import { useService } from "../Services/helper";
import { apiErrorMessage } from "Constants/Data/Errors";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import Pagination from "new-components/Pagination";
import NoMembershipFound from "../../Elements/NoMembershipFound";
import ServiceCard from "../../Elements/ServiceCard";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import QuickSalesTableHead from "Components/Utility/Shimmers/QuickSalesTableHead";
import Shimmer_array from "Constants/Data/Shimmer_array";
import GiftCardItem from "../../Elements/GiftCardItem";
import CustomGiftCardPopup from "./CustomeGiftCardPopup";
import NoGiftCardFound from "../../Elements/NoGiftCardFound";

// import NotFound from "../../Elements/NotFound";
// import { createMembers, deleteMember, getMembers, updateMember } from '../../../../../'
// import {getMembers} from
// import PreloaderFull from "./../../../../Elements/PreloaderFull";
// import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";

let timeOutId = undefined
const GiftCard = ({ isLoading }) => {
  const { storeDataInLocalStorage, getDataFromLocalStorage } = useSetDataInLocalStorage();
  const cartFromLocalStorage = getDataFromLocalStorage('cart_items')

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { locations } = useSelector(
    (state) => state
  );
  const { selected_location } = locations;
  const location_currency = state?.utility?.currency?.location_currency;
  let pages = state?.membership?.page_count

  const { locationDetail } = useLocation();
  const LocationCurrency = locationDetail?.currency?.id;

  const { removedFromCart } = useService();
  const cartItems = state.cartItem.cart_items;
  const [selected_ids, setSelectIds] = useState([]);
  const [search_text, setSearchText] = useState("");
  const [edit_id, setEditId] = useState(undefined);
  const [editMemberError, setEditMemberError] = useState({});
  const [popclose, setpopclose] = useState(false);
  const [ProductDetailPopup, setProductDetailPopup] = useState(false);
  const [giftCard_details, setGiftCardDetails] = useState({});
  const [pageLoading, setPageLoading] = useState(true)
  const [lastPage, setLastPage] = useState()
  const [memberInput, setmemberInput] = useState({});
  const [btn_loading, setBtnLoading] = useState(false);
  // const [edit_id, setEditId] = useState(undefined)
  const [memberError, setmemberError] = useState({});
  // const [editMemberError, setEditMemberError] = useState({})
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [OpenCart, setOpenCart] = useState(false);
  const PageSize = 10
  const [currentPage, setCurrentPage] = useState(1);
  const [openCustomGiftCard, setOpenCustomGiftCard] = useState(false)

  const isPermissionUpdated = state?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  const token = getDataFromLocalStorage('access_token')

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const fetchMemberslist = async () => {
    dispatch(GetGiftCards());
  };

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  // useEffect(() => {
  //   dispatch(getMembers(currentPage));
  // }, [currentPage]);

  useEffect(() => {
    const success = () => { setPageLoading(false) }
    const fail = () => { setPageLoading(false) }
    if (search_text === null) {
      const payload = {
        selected_location: selected_location,
        currentPage: "",
      }
      // Initial load with empty search_text 
      dispatch(GetGiftCards(token, payload, success, fail, true))
    } else {
      const payload = {
        selected_location: selected_location,
        currentPage: currentPage,
        search_text: search_text,
      }
      if (timeOutId) { clearTimeout(timeOutId) }
      timeOutId = setTimeout(() => {
        dispatch(GetGiftCards(token, payload, success, fail, true))
      }, 500);
    }
  }, [currentPage, search_text, selected_location])

  useEffect(() => {
    // setPageLoading(true)
    setCurrentPage(1)
  }, [search_text])

  useEffect(() => {
    setPageLoading(true)
    setCurrentPage(1)
  }, [selected_location])

  const ff = (e) => {
    setSearchText(e.target.value);
  };

  const membersubmithandler = () => {
    if (memberInput.membership_type === "Service") {
      delete memberInput.product;
    } else if (memberInput.membership_type === "Product") {
      delete memberInput.service;
    }
    memberInput["business"] = state?.business?.business?.id;
    memberInput["discount_service"] = memberInput.service;

    if (
      (Object.keys(memberInput).includes("product") &&
        memberInput["product"]) ||
      (Object.keys(memberInput).includes("service") && memberInput["service"])
    ) {
      setBtnLoading(true);
      dispatch(
        createMembers(
          memberInput,
          () => {
            setmemberInput({});
            setmemberError({});
            setBtnLoading(false);
            fetchMemberslist();

            toast.success("GiftCard created successfully", { toastId: "toast" });
          },
          () => {
            setBtnLoading(false);
            toast.error("GiftCard did not created", { toastId: "toast" });
          }
        )
      );
    } else {
      if (memberInput.membership_type === "Service") {
        setmemberError({
          ...memberError,
          service: true,
        });
      } else {
        setmemberError({
          ...memberError,
          product: true,
        });
      }
    }
  };

  // const onGiftCardUpdateHnadler = (edited_data) => {
  //   if (edited_data.membership === "Service") {
  //     delete edited_data.product;
  //   } else if (edited_data.membership === "Product") {
  //     delete edited_data.service;
  //   }

  //   if (
  //     (Object.keys(edited_data).includes("product") &&
  //       edited_data["product"]) ||
  //     (Object.keys(edited_data).includes("service") && edited_data["service"])
  //   ) {
  //     dispatch(
  //       updateGiftCard(
  //         edited_data,
  //         () => {
  //           toast.success("GiftCardship updated successfully", { toastId: "toast" });
  //           setEditId(false);
  //         },
  //         () => {
  //           toast.error(apiErrorMessage, { toastId: "toast" });
  //         }
  //       )
  //     );
  //   } else {
  //     if (edited_data.membership === "Service") {
  //       setEditGiftCardError({
  //         ...editGiftCardError,
  //         service: true,
  //       });
  //     } else {
  //       setEditGiftCardError({
  //         ...editGiftCardError,
  //         product: true,
  //       });
  //     }
  //   }
  // };

  const onGiftCardEditClickHnadler = (id) => {
    setEditId(id);
  };

  const onBuyGiftCardship = () => {
    let ids = selected_ids;
    dispatch({
      type: PRODUCT.STORE_SELECTED_IDS_INTO_REDUX,
      payload: {
        ids: ids,
        selection_type: "MEMBERSHIP",
      },
    });
    navigate("/dashboard/sales/quick-sales/checkout");
  };

  const handleSelection = (id, price, isCustomCode, code) => {
    const selected_price = price?.gift_card_details?.find(itm => itm?.currencies == LocationCurrency)?.price
    const selected_spent_price = price?.gift_card_details?.find(itm => itm?.currencies == LocationCurrency)?.spend_amount
    if (selected_ids.includes(id)) {
      selected_ids.splice(selected_ids.indexOf(id), 1);
    } else {
      selected_ids.push(id);
    }

    const result = (code || isCustomCode) ? price : state?.giftCards?.giftCards?.find((itm) => itm.id === id);
    dispatch({
      type: "ADD_TO_CART",
      payload: { ...result, quantity: 1, price: +selected_price, spend_amount: selected_spent_price, custom_code: code, selection_type: "GIFT_CARD", },
    });
    let updatedCartForLocal = []
    if (cartFromLocalStorage?.length > 0 && cartFromLocalStorage != undefined) {
      updatedCartForLocal = [...cartFromLocalStorage]
    }
    updatedCartForLocal = [...updatedCartForLocal, { ...result, quantity: 1, price: +selected_price, spend_amount: selected_spent_price, custom_code: code, selection_type: "GIFT_CARD" }]
    storeDataInLocalStorage('cart_items', updatedCartForLocal)
    // toast.success("item added to cart",{ toastId: "toast" });
  };

  const filterGiftCard = () => {
    return state.giftCards?.giftCards
    // return state.membership?.memberships.filter((itm) =>
    //   itm.name.toLowerCase().includes(search_text.toLowerCase())
    // );
  };

  useEffect(() => {
    const result = cartItems?.map((itm) => itm.id);
    setSelectIds(result);
  }, [cartItems.length != selected_ids.length]);

  const member_details_settings = (id) => {
    setProductDetailPopup(true);
    const selected_membership = filterGiftCard()?.find(
      (itm) => itm?.id === id
    );
    setGiftCardDetails(selected_membership);
  };

  const giftCardCount = state?.giftCards?.allCount

  return (
    <>
      <div className="border rounded-md">
        <div className="px-4 lg:px-6 py-2 flex sm:items-center items-end justify-between border-b sm:flex-row flex-col gap-3">
          <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
            <div className="flex items-center sm:gap-4 gap-2 ">
              <h2 className="font-semibold text-xl">
                {selectedLanguage?.name == "English" ? "Gift Card" : translation[1540]?.value ? translation[1540]?.value : "Gift Card"}
              </h2>

              {pageLoading ?
                <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                  <p className="rounded-md h-5 w-12 mx-1"> </p>
                </div>
                :
                <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                  <p className="text-primary">
                    {filterGiftCard()?.length ? filterGiftCard()?.length : 0} {" "}
                    {filterGiftCard()?.length && filterGiftCard()?.length > 1
                      ? selectedLanguage?.name == "English" ? "Gift Cards" : translation[1541]?.value ? translation[1541]?.value : "Gift Cards"
                      : selectedLanguage?.name == "English" ? "Gift Card" : translation[1540]?.value ? translation[1540]?.value : "Gift Card"

                    }
                  </p>
                </div>
              }
            </div>
            <div className="mb-00">
              {loadingPermission ?
                <Shimmer className={'w-40 lg:w-44'}>
                  <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                </Shimmer>
                :
                <LoginInput
                  title=""
                  placeholder={selectedLanguage?.name == "English" ? "Search Gift Card" : translation[1542]?.value ? translation[1542]?.value : "Search Gift Card"}
                  padding="py-1"
                  leftIcon={<Svgs.Search />}
                  value={search_text}
                  onChange={ff}
                />
              }
            </div>
          </div>
          {loadingPermission ?
            <QuickSalesTableHead />
            :
            <div className="flex items-center lg:justify-center justify-end gap-3 w-fit ">

              {!employee ? (
                <>
                  <LoginBtn
                    text={selectedLanguage?.name == "English" ? "Add custom gift card" : translation[301]?.value ? translation[301]?.value : "Add custom gift card"}
                    onClick={() => {
                      setOpenCustomGiftCard(!openCustomGiftCard)
                    }}
                  />
                  <LoginBtn
                    text={selectedLanguage?.name == "English" ? "Add gift card" : translation[301]?.value ? translation[301]?.value : "Add gift card"}
                    onClick={() => {
                      navigate("/dashboard/clients/sharing-settings/gift-card");
                    }}
                  />
                </>
              ) : (
                ""
              )}
              {!employee ||
                (employee &&
                  employeePermissions?.sales_apply_offer?.includes("create")) ||
                employeePermissions?.sales_apply_offer?.includes("edit") ? (
                <AddToCart OpenCart={OpenCart} setOpenCart={setOpenCart} />
              ) : (
                ""
              )}
            </div>
          }
        </div>
        <div className="px-6 py-3">

          {pageLoading ?
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 py-5">
              {Shimmer_array()?.map(itm => <ServiceCard animate />)}
            </div>
            :
            filterGiftCard()?.length > 0 ? (
              <>
                <div className="flex items-center justify-between">
                  <h1 className="text-[#7B7B7B] text-sm font-semibold normal-case">
                    {selectedLanguage?.name == "English" ? "You can select multiple gift cards" : translation[1665]?.value ? translation[1665]?.value : "You can select multiple gift cards"} <span className='text-red-500'>*</span>
                  </h1>
                  {giftCardCount > 10 &&
                    <div className="flex items-center justify-between gap-4">
                      <LoginBtn
                        onClick={() => {
                          setCurrentPage(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                        text="Back" />
                      <LoginBtn
                        onClick={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                        disabled={currentPage === lastPage}
                        text="Next" />
                    </div>
                  }
                </div>
                <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 py-5">
                  {/* ?.filter(membership => membership?.currency_membership?.some(item => item.currency == LocationCurrency)) */}
                  {filterGiftCard()?.map((gc, index) => {
                    return (
                      <GiftCardItem
                        setOpenCart={setOpenCart}
                        data={{
                          ...gc,
                          currency: location_currency,
                        }}
                        checked={
                          selected_ids.includes(gc.id) ? true : false
                        }
                        key={index}
                        onEnterSubmit={membersubmithandler}
                        value={memberInput?.validity}
                        error={memberError?.validity}
                        // onUpdate={(edited_data) => {
                        //   onGiftCardUpdateHnadler(edited_data);
                        // }}
                        onEditClick={(id) => {
                          onGiftCardEditClickHnadler(id);
                        }}
                        removedFromCart={removedFromCart}
                        view_details={() =>
                          member_details_settings(gc?.id)
                        }
                        is_edit={edit_id == gc.id ? true : false}
                        onClick={() =>
                          handleSelection(gc?.id, gc)
                        }
                      />
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center">
                <NoGiftCardFound />
              </div>
            )}
        </div>

        {openCustomGiftCard &&
          <CustomGiftCardPopup
            setOpenCart={setOpenCart}
            open={openCustomGiftCard}
            close={setOpenCustomGiftCard}
            handleSelection={handleSelection}

          />
        }
        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          totalCount={Math.ceil(pages)}
          pageSize={PageSize}
          onPageChange={page => setCurrentPage(page)}
          setLastPage={setLastPage}
        />
      </div>
      {ProductDetailPopup && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Gift Card Details" : translation[1659]?.value ? translation[1659]?.value : "Gift Card Details"}
          handleClose={() => setProductDetailPopup(false)}
        >
          <div className="grid md:grid-cols-5 gap-4">
            <div className="flex justify-center">
              <CircleIcon className="!h-[6rem] !w-[6rem] p-2">
                <Svgs.Gift />
              </CircleIcon>
            </div>
            <div className="flex flex-col gap-6 md:col-span-4">
              <div className="grid md:grid-cols-2 gap-2">
                <DetailPopupDetails
                  title={selectedLanguage?.name == "English" ? "Gift Card Name" : translation[1656]?.value ? translation[1656]?.value : "Gift Card Name"}
                  desc={giftCard_details?.title}
                />
                {giftCard_details?.gift_card_details?.filter((itm) =>
                  itm?.currency === LocationCurrency).map((obj) => obj?.price) &&
                  <DetailPopupDetails
                    title={selectedLanguage?.name == "English" ? "Gift Card Price" : translation[1657]?.value ? translation[1657]?.value : "Gift Card Price"}
                    desc={
                      <div>
                        {giftCard_details?.gift_card_details?.filter((itm) =>
                          itm?.currencies === LocationCurrency).map((obj) => obj?.price)} {giftCard_details?.gift_card_details?.find((itm) => itm?.currencies === LocationCurrency) ? locationDetail?.currency?.code : "AED"}
                        {/* {giftCard_details?.price} */}
                      </div>
                    }
                  />
                }
                <DetailPopupDetails
                  title={selectedLanguage?.name == "English" ? "Validity" : translation[307]?.value ? translation[307]?.value : "Validity"}
                  desc={giftCard_details?.valid_till}
                />
                {giftCard_details?.gift_card_details?.filter((itm) =>
                  itm?.currency === LocationCurrency).map((obj) => obj?.price) &&
                  <DetailPopupDetails
                    title={selectedLanguage?.name == "English" ? "Gift Card Retail Price" : translation[1658]?.value ? translation[1658]?.value : "Gift Card Retail Price"}
                    desc={
                      <div>
                        {giftCard_details?.gift_card_details?.filter((itm) =>
                          itm?.currencies === LocationCurrency).map((obj) => obj?.spend_amount)} {giftCard_details?.gift_card_details?.find((itm) => itm?.currencies === LocationCurrency) ? locationDetail?.currency?.code : "AED"}
                      </div>
                    }
                  />
                }
                {/* <div className="col-span-2">
                  <DetailPopupDetails
                    title={selectedLanguage?.name == "English" ? "Gift Card Details" : translation[1659]?.value ? translation[1659]?.value : "Gift Card Details"}
                    desc={giftCard_details?.description}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}

export default GiftCard;
