/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AccountSetupCard from "../AccountSetupCard";

import Svgs from "Assets/svgs";

const DashboardPOSAnalytics = () => {
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);
  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

      <div className="flex items-center gap-5 lg:w-fit w-full lg:px-0 lg:pb-[2rem] lg:py-0 p-3 pb-8">
        {/* <Svgs.Back link="/dashboard/account" /> */}
        <h2 className="font-semibold text-2xl">
          {selectedLanguage?.name == "English" ? "POS Analytics" : translation[7]?.value ? translation[7]?.value : "POS Analytics"}
        </h2>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem] xl:pr-[4rem] pr-0">
        <AccountSetupCard
          title={selectedLanguage?.name == "English" ? "Sales Analytics" : translation[314]?.value ? translation[314]?.value : "Sales Analytics"}
          icon={<Svgs.GraphFill />}
          description={selectedLanguage?.name == "English" ? "Lorem Ipsum is simply dummy text of the printing and typesetting industry." : translation[315]?.value ? translation[315]?.value : "Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
          link="/dashboard/pos-analytics/sales-analytics"
        />
        {/* <AccountSetupCard
          title={selectedLanguage?.name == "English" ? "Marketing Analytics" : translation[314]?.value ? translation[314]?.value : "Marketing Analytics"}
          icon={<Svgs.GraphNotFill />}
          description={selectedLanguage?.name == "English" ? "Lorem Ipsum is simply dummy text of the printing and typesetting industry." : translation[315]?.value ? translation[315]?.value : "Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
          link="/dashboard/pos-analytics/marketing-analytics"
        /> */}
        <AccountSetupCard
          title={selectedLanguage?.name == "English" ? "Client Analytics" : translation[314]?.value ? translation[314]?.value : "Client Analytics"}
          icon={<Svgs.BarGraph />}
          description={selectedLanguage?.name == "English" ? "Lorem Ipsum is simply dummy text of the printing and typesetting industry." : translation[315]?.value ? translation[315]?.value : "Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
          link="/dashboard/pos-analytics/client-analytics"
        />
        <AccountSetupCard
          title={selectedLanguage?.name == "English" ? "Finance Analytics" : translation[314]?.value ? translation[314]?.value : "Finance Analytics"}
          icon={<Svgs.PieGraph />}
          description={selectedLanguage?.name == "English" ? "Lorem Ipsum is simply dummy text of the printing and typesetting industry." : translation[315]?.value ? translation[315]?.value : "Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
          link="/dashboard/pos-analytics/finance-analytics"
        />
        <AccountSetupCard
          title={selectedLanguage?.name == "English" ? "Inventory Insights" : translation[9]?.value ? translation[9]?.value : "Inventory Insights"}
          icon={<Svgs.PieGraph />}
          description={selectedLanguage?.name == "English" ? "Lorem Ipsum is simply dummy text of the printing and typesetting industry." : translation[315]?.value ? translation[315]?.value : "Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
          link="/dashboard/pos-analytics/inventory-insights"
        />
        <AccountSetupCard
          title={selectedLanguage?.name == "English" ? "Employee Analytics" : translation[1236]?.value ? translation[1236]?.value : "Employee Analytics"}
          icon={<Svgs.PieGraph />}
          description={selectedLanguage?.name == "English" ? "Lorem Ipsum is simply dummy text of the printing and typesetting industry." : translation[315]?.value ? translation[315]?.value : "Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
          link="/dashboard/pos-analytics/analytics"
        />
        <AccountSetupCard
          title={selectedLanguage?.name == "English" ? "Expense Analytics" : translation[1780]?.value ? translation[1780]?.value : "Expense Analytics"}
          icon={<Svgs.PieGraph />}
          description={selectedLanguage?.name == "English" ? "Lorem Ipsum is simply dummy text of the printing and typesetting industry." : translation[315]?.value ? translation[315]?.value : "Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
          link="/dashboard/pos-analytics/expense"
        />
        <AccountSetupCard
          title={selectedLanguage?.name == "English" ? "Upsell and Downsell Report" : translation[1690]?.value ? translation[1690]?.value : "Upsell and Downsell Report"}
          icon={<Svgs.GraphNotFill />}
          description={selectedLanguage?.name == "English" ? "Manage vacations for your staff from their working schedule" : translation[415]?.value ? translation[415]?.value : "Manage vacations for your staff from their working schedule"}
          link="/dashboard/pos-analytics/upsell&downsell-report"
        />
        <AccountSetupCard
          title={selectedLanguage?.name == "English" ? "Payment Methods Report" : translation[1690]?.value ? translation[1690]?.value : "Payment Methods Report"}
          icon={<Svgs.GraphNotFill />}
          description={selectedLanguage?.name == "English" ? "Lorem Ipsum is simply dummy text of the printing and typesetting industry." : translation[315]?.value ? translation[315]?.value : "Lorem Ipsum is simply dummy text of the printing and typesetting industry."}
          link="/dashboard/pos-analytics/payment-methods-report"
        />
      </div>
    </div>
  );
};
export default DashboardPOSAnalytics;
