import { BASE_URL, create_employee_skill_url, create_employee_training_url, get_employee_skills_url, get_employee_training_url, update_employee_training_url } from "Adapters/Api/api_variables";
import { buildQueryString } from "Constants/Functions/buildQueryString";
import { getEmployeeSkills, getEmployeeTrainingData } from "Redux/TenantRedux/Actions/employeeActions";
import axios from "axios";
import { toast } from "react-toastify";

export const addNewSkill = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.post(`${BASE_URL}${create_employee_skill_url}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 200) {
                toast.error(result?.data?.response?.message, { toastId: "add_skill" })
                fail && fail()
            }

            if (result?.data?.status_code == 201) {
                toast.success(result?.data?.response?.message, { toastId: "add_skill" })
                success && success()
            }
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }
};

export const getEmployeesSkill = (access_token, success, fail, payload) => async (dispatch) => {
    try {
        // const query = buildQueryString(payload)
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const result = await axios.get(`${BASE_URL}${get_employee_skills_url}`, headers);
        if (result?.status === 200) {
            const data = result?.data
            dispatch(getEmployeeSkills(data));
            success && success()
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        fail && fail(error);
        return error;
    }
}

export const createEmployeeTraining = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.post(`${BASE_URL}${create_employee_training_url}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 200) {
                toast.error(result?.data?.response?.message, { toastId: "add_skill" })
                fail && fail()
            }

            if (result?.data?.status_code == 201) {
                toast.success(result?.data?.response?.message, { toastId: "add_skill" })
                success && success()
            }
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }
};
export const upadateEmployeeTraining = (access_token, payload, success, fail) => async (dispatch) => {
    try {
        const result = await axios.put(`${BASE_URL}${update_employee_training_url}?id=${payload?.id}`, payload, {
            headers: { Authorization: `Token ${access_token}` },
        });
        if (result?.status == 200) {
            if (result?.data?.status_code == 200) {
                toast.success(result?.data?.response?.message, { toastId: "update_trainnig" })
                success && success()
            }
            if (result?.data?.status_code == 400) {
                toast.error(result?.data?.response?.error_message, { toastId: "update_trainnig" })
                fail && fail()
            }
            return;
        } else {
            fail && fail()
        }
    } catch (error) {
        fail && fail(error?.response?.data)
        return error;
    }
};


export const getEmployeesTraining = (access_token, success, fail, payload) => async (dispatch) => {
    try {
        const query = buildQueryString(payload)
        let headers = {
            "Content-type": "application/json",
            headers: { Authorization: `Token ${access_token}` },
        };
        const result = await axios.get(`${BASE_URL}${get_employee_training_url}${query}`, headers);
        if (result?.status === 200) {
            const data = result?.data
            dispatch(getEmployeeTrainingData(data));
            success && success()
        } else {
            fail && fail(result)
        }
        return result;
    } catch (error) {
        console.log("🚀 ~ getEmployeesTraining ~ error:", error, payload)
        fail && fail(error);
        return error;
    }
}