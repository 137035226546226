import React,{useState, useEffect} from 'react'
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
  } from 'chart.js';
  
  import { Bar } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  
const SingleBar = () => {
  
  const [LabelFontSize, setLabelFontSize] = useState(14);
  const [BarThickness, setBarThickness] = useState(20);
    useEffect(() => {
          const handleResize = () => {
          if (window.innerWidth >= 770) {
              setBarThickness(20)
              setLabelFontSize(14)
            } else {
              
              setBarThickness(12)
              setLabelFontSize(10)
          }
          };
          // Initial setup
          handleResize();
          // Event listener for window resize
          window.addEventListener('resize', handleResize);
          return () => {
              window.removeEventListener('resize', handleResize);
          };
      }, []);


      const options = {
        plugins: {
          legend: false,
          tooltip: {
            // Disable the on-canvas tooltip
            enabled: true,
            usePointStyle: true,
            bodyAlign: "center",
            yAlign: "bottom",
            xAlign: "center",
            footerAlign: "center",
            titleAlign: "center",
            displayColors: false,
            pointStyle: false,
            padding: 10,
            caretPadding: 10,
            bodyFont: {
              size: 15,
            },
            callbacks: {
              title: function () {
                return "";
              },
            },
          },
          title: {
            display: false,
            text: '',
          },
          
        },
        scales: {
          y: {
            grid:{
              display: true,
            },
            ticks: {
              font: {
                size: LabelFontSize, // Set the font size for the y-axis labels
                family: 'Arial', // Set the font family for the y-axis labels
              },
            },
          },
          x: {
            grid:{
              display: false,
            },
            ticks: {
              font: {
                size: LabelFontSize, // Set the font size for the y-axis labels
                family: 'Arial', // Set the font family for the y-axis labels
              },
            },
          },
        }
      };
      
      const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul','Aug','Sep','Oct','Nov','Dec'];
      
      const data = {
        labels,
        datasets: [
          {
            label: '',
            data: [680, 808, 700, 900, 967, 800, 430,600,950,300,540,270],
            backgroundColor: '#2652F9',
            barThickness: BarThickness,
            borderRadius: {
              topLeft: 5,
              topRight: 5,
            },
          }
        ],
      };

  return (
    <Bar options={options} data={data} />
  )
}

export default SingleBar