import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AddAbsence from "./AddAbsence";
import { DeleteAbsence } from ".";

import Svgs from "Assets/svgs";
import moment from "moment";
import LabelAvatar from "Components/TenantApp/Elements/LabelAvatar";

function AbsenceItem({ item, i, setDeleteAbsenceID, setDeleteAbsencePopup }) {
    const [editPopup, setEditPopup] = useState(false);
    const [ShowDetails, setShowDetails] = useState(0);
    // const [deletePopup, setDeletePopup] = useState(false);
    // const [deleteID, setDeleteID] = useState(null);


    const state = useSelector((state) => state);

    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    const [employee, setEmployee] = useState();
    const [employeePermissions, setEmployeePermissions] = useState([]);

    useEffect(() => {
        setEmployee(state?.user?.user?.employee);
        setEmployeePermissions(state?.user?.permissions);
    }, [state?.user]);

    return (
        <div
            className={`odd-bg text-[#3A3A3A] transition-all ${ShowDetails
                ? "border-l-[4px] border-[#5679FFB2]"
                : "border-l-[0px]"
                }`}
        >
            <>
                <div>
                    <div key={item?.id} className={`odd-bg text-[#3A3A3A] transition-all `}>
                        {editPopup && (
                            <AddAbsence
                                handleClose={() => {
                                    setEditPopup(false);
                                }}
                                editData={item}
                                editMode={item?.id}
                            />
                        )}
                        {/* {deletePopup && (
                            <DeleteAbsence
                                heading={
                                    selectedLanguage?.name == "English"
                                        ? "Are you sure you want to delete this Absence?" :
                                        translation[633]?.value ? translation[633]?.value
                                            : "Are you sure you want to delete this Absence?"
                                }
                                description={
                                    selectedLanguage?.name == "English"
                                        ? "Are you sure you want to delete this Absence? You won't be able to see this again." :
                                        translation[634]?.value ? translation[634]?.value
                                            : "Are you sure you want to delete this Absence? You won't be able to see this again."
                                }
                                btnText={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                                deleteId={deleteID}
                                setDeletePopup={setDeletePopup}
                            />
                        )} */}
                        <div className="px-12 py-3 inline-grid xl:grid gap-3 grid-cols-[repeat(3,minmax(145px,1fr))] min-w-full w-fit">
                            {/* <div className="flex items-center">
                                <p className={`text-sm font-semibold text-[#3A3A3A]" before-left-arrow cursor-pointer ${ShowDetails == i + 1
                                    ? "before-left-arrow-open"
                                    : "before-left-arrow-close"
                                    }`}
                                    onClick={() => {
                                        setShowDetails(ShowDetails != i + 1 ? i + 1 : 0);
                                    }}>{item?.id}</p>
                            </div> */}
                            <div className={`before-left-arrow cursor-pointer`}>
                                <div className={`text-sm font-semibold text-[#3A3A3A]" before-left-arrow cursor-pointer ${ShowDetails == i + 1
                                    ? "before-left-arrow-open"
                                    : "before-left-arrow-close"
                                    }`}
                                    onClick={() => {
                                        setShowDetails(ShowDetails != i + 1 ? i + 1 : 0);
                                    }}>
                                    <div className="flex items-center gap-3">
                                        {item?.employee?.image ? (
                                            <img
                                                alt=""
                                                src={item?.employee?.image}
                                                className="h-[2.8125rem] w-[2.8125rem] object-cover rounded-full"
                                            />
                                        ) : (
                                            <LabelAvatar iconLabel={item?.employee?.full_name} index={i} className={'!h-[2.8125rem] !w-[2.8125rem] !rounded-full'} />
                                        )}
                                        <p className="text-sm">{item?.employee?.full_name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center">
                                {moment(item?.from_date).format("D MMM, YYYY") || "--"} - {moment(item?.to_date).format("D MMM, YYYY") || "--"}
                            </div>
                            <div className="flex items-center">
                                <p className="text-sm">{item?.employee?.designation}</p>
                            </div>
                            {/* <div className="flex items-center gap-2">
                  {!employee ||
                    (employee &&
                      employeePermissions?.employee_absence?.includes("edit")) ? (
                    <div
                      className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                      onClick={() => {
                        setEditPopup(true);
                      }}
                    >
                      <Svgs.Pen />
                    </div>
                  ) : (
                    ""
                  )}
                  {!employee ? (
                    <div
                      className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center cursor-pointer"
                      onClick={() => {
                        setDeletePopup(true);
                      }}
                    >
                      <Svgs.Delete />
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}
                        </div>
                    </div>
                </div>
                {ShowDetails == i + 1 ? (
                    <div>
                        <div className="scale-in-center">
                            <div className="bg-[#FFFFFF] border border-[#E5E7EB] px-6 py-3 flex flex-col gap-3">

                                <div className="grid grid-cols-5 gap-5">
                                    {item?.absence_details?.map((detail, index) => (
                                        <div className="flex justify-between items-center rounded-md border border-[#BAD3FC] bg-[#ECF3FF] py-2 px-3 text-sm">
                                            <p className="text-sm">{moment(detail?.from_date).format("D MMM, YYYY") || "--"}</p>
                                            <p className="text-sm">
                                                {!employee ? (
                                                    <div
                                                        className="flex items-center justify-center cursor-pointer"
                                                        onClick={() => {
                                                            setDeleteAbsencePopup(true);
                                                            setDeleteAbsenceID(detail?.id)
                                                        }}
                                                    >
                                                        <Svgs.Delete size={"1rem"} />
                                                    </div>
                                                ) : (
                                                    "")}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </>
        </div>
    );
}

export default AbsenceItem;