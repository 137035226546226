import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import LocationSelection from "new-components/location";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";

import useTrainingRequest from "./helper";
import AddTrainingRequestPopup from "./AddTrainingRequest";
import Trainer from "./Trainer";
import Student from "./Student";
import AddSkillPopup from './Popups/AddSkillPopup';
const EmployeeTrainingRequests = () => {
  const {
    translation,
    selectedLanguage,
    addTraingPoup, setAddTraingPoup,
    addSkillPopup, setAddSkillPopup, trainingLoading, getTrainingData, trainingPage, setTrainingPage,
    employeeTraining, employeeTrainingPaginationData, ReportToggle, setReportToggle
  } = useTrainingRequest()


  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">

        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "Employee Management" : translation[375]?.value ? translation[375]?.value : "Employee Management"}
          second={selectedLanguage?.name == "English" ? "Employee Training Requests" : translation[1706]?.value ? translation[1706]?.value : "Employee Training Requests"}
          url={'/dashboard/employee/'}
        />
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Employee Training Requests" : translation[1706]?.value ? translation[1706]?.value : "Employee Training Requests"}
            </h2>
            <LocationSelection />
          </div>
          {ReportToggle == "student" &&
            (<div className="flex justify-end gap-4 w-full sm:w-auto">
              <LoginBtn
                className={'!capitalize'}
                text={selectedLanguage?.name == "English" ? "Add Training Requests" : translation[1707]?.value ? translation[1707]?.value : "Add Training Requests"}
                onClick={() => { setAddTraingPoup(!addTraingPoup) }}
              />
              <LoginBtn
                className={'!capitalize'}
                text={selectedLanguage?.name == "English" ? "Add Skill" : translation[1714]?.value ? translation[1714]?.value : "Add Skill"}
                onClick={() => {
                  setAddSkillPopup(!addSkillPopup)
                }}
              />
            </div>)
          }

        </div>

        <div className="border-b mt-[1.5rem]">
          <div className="flex items-end gap-6">
            <p onClick={() => { setReportToggle("student"); }} className={`border-b-[3px] ${ReportToggle == "student" ? "border-primary font-semibold" : "border-transparent"} pb-3 w-fit cursor-pointer`}>
              {selectedLanguage?.name == "English" ? "Student" : translation[592]?.value ? translation[592]?.value : "Student"}
            </p>
            <p onClick={() => { setReportToggle("teacher"); }} className={`border-b-[3px] ${ReportToggle == "teacher" ? "border-primary font-semibold" : "border-transparent"} pb-3 w-fit cursor-pointer`}>
              {selectedLanguage?.name == "English" ? "Trainer" : translation[592]?.value ? translation[592]?.value : "Trainer"}
            </p>
          </div>

        </div>

        <div className="my-[3rem]">
          {ReportToggle == "student"
            ? <Student
              loading={trainingLoading}
              translation={translation}
              selectedLanguage={selectedLanguage}
              data={employeeTraining}
              pagination={employeeTrainingPaginationData}
              currentPage={trainingPage}
              setCurrentPage={setTrainingPage}
              onSuccessCall={getTrainingData}
            />
            : ReportToggle == "teacher"
            && <Trainer
              loading={trainingLoading}
              translation={translation}
              selectedLanguage={selectedLanguage}
              data={employeeTraining}
              pagination={employeeTrainingPaginationData}
              currentPage={trainingPage}
              setCurrentPage={setTrainingPage}
              onSuccessCall={getTrainingData}
            />
          }
        </div>



      </div>

      {addTraingPoup &&
        <AddTrainingRequestPopup
          open={addTraingPoup}
          close={setAddTraingPoup}
          onSuccessCall={getTrainingData}
        />}


      {/* Add skilld */}
      {addSkillPopup &&
        <AddSkillPopup
          open={addSkillPopup}
          close={setAddSkillPopup}
          translation={translation}
          selectedLanguage={selectedLanguage}
        />
      }
    </>
  );
};

export default EmployeeTrainingRequests;
