import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ACTIVES, SIGNUP_TYPE, ACCOUNT_TYPE } from "../Constants/Variables";

export default function useSearchParamsHandle() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsValue, setSearchParamsValue] = useState();
  const [param, setParams] = useState();

  const allParams = [];

  //get all search params convert into objects of arrays
  searchParams?.forEach((key, value) => {
    allParams.push({ key, value });
  });


  //set a dynamic search params values
  const setDynamicSearchParams = (paramList, value) => {
    const [account_type, signup_type, actives] = allParams;
    let obj = {}
    paramList.forEach((param) => {
      if (param.value === ACCOUNT_TYPE) {
        obj[param.value] = account_type.key;
      } else if (param.value === SIGNUP_TYPE) {
        obj[param.value] = signup_type.key;
      } else if (param.value === ACTIVES) {
        obj[param.value] = value;
      }
    })

    return obj;
  }


  // set value for adding that as search params in ul
  const handleParams = (data) => {
    setParams(data);
  };

  // set searchParams data in url
  const handleSearchParams = () => {
    setSearchParams(param);
  };

  //get search params value from urls using it key
  const getSearchParams = (key) => {
    const value = searchParams.get(key);
    setSearchParamsValue(value);

  };

  return {
    handleSearchParams,
    handleParams,
    getSearchParams,
    searchParamsValue,
    allParams,
    setDynamicSearchParams
  };
}
