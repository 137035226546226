


export const DURATIONS = [
    { label: '5min', value: '5min' },
    { label: '10min', value: '10min' },
    { label: '15min', value: '15min' },
    { label: '20min', value: '20min' },
    { label: '25min', value: '25min' },
    { label: '30min', value: '30min' },
    { label: '35min', value: '35min' },
    { label: '40min', value: '40min' },
    { label: '45min', value: '45min' },
    { label: '50min', value: '50min' },
    { label: '55min', value: '55min' },
    { label: '1h', value: '1h' },
    { label: '1h 5min', value: '1h 5min' },
    { label: '1h 10min', value: '1h 10min' },
    { label: '1h 15min', value: '1h 15min' },
    { label: '1h 20min', value: '1h 20min' },
    { label: '1h 25min', value: '1h 25min' },
    { label: '1h 30min', value: '1h 30min' },
    { label: '1h 35min', value: '1h 35min' },
    { label: '1h 40min', value: '1h 40min' },
    { label: '1h 45min', value: '1h 45min' },
    { label: '1h 50min', value: '1h 50min' },
    { label: '1h 55min', value: '1h 55min' },
    { label: '2h', value: '2h' },
    { label: '2h 5min', value: '2: 5min' },
    { label: '2h 10min', value: '2h 10min' },
    { label: '2h 15min', value: '2h 15min' },
    { label: '2h 20min', value: '2h 20min' },
    { label: '2h 25min', value: '2h 25min' },
    { label: '2h 30min', value: '2h 30min' },
    { label: '2h 35min', value: '2h 35min' },
    { label: '2h 40min', value: '2h 40min' },
    { label: '2h 45min', value: '2h 45min' },
    { label: '2h 50min', value: '2h 50min' },
    { label: '2h 55min', value: '2h 55min' },
    { label: '3h', value: '3h' },
    { label: '3h 5min', value: '3: 5min' },
    { label: '3h 10min', value: '3h 10min' },
    { label: '3h 15min', value: '3h 15min' },
    { label: '3h 20min', value: '3h 20min' },
    { label: '3h 25min', value: '3h 25min' },
    { label: '3h 30min', value: '3h 30min' },
    { label: '3h 35min', value: '3h 35min' },
    { label: '3h 40min', value: '3h 40min' },
    { label: '3h 45min', value: '3h 45min' },
    { label: '3h 50min', value: '3h 50min' },
    { label: '3h 55min', value: '3h 55min' },
    { label: '4h', value: '4h ' },
    { label: '4h 5min', value: '4h 5min' },
    { label: '4h 10min', value: '4h 10min' },
    { label: '4h 15min', value: '4h 15min' },
    { label: '4h 20min', value: '4h 20min' },
    { label: '4h 25min', value: '4h 25min' },
    { label: '4h 30min', value: '4h 30min' },
    { label: '4h 35min', value: '4h 35min' },
    { label: '4h 40min', value: '4h 40min' },
    { label: '4h 45min', value: '4h 45min' },
    { label: '4h 50min', value: '4h 50min' },
    { label: '4h 55min', value: '4h 55min' },
    { label: '5h', value: '5h' },
    { label: '5h 5min', value: '5h 5min' },
    { label: '5h 10min', value: '5h 10min' },
    { label: '5h 15min', value: '5h 15min' },
    { label: '5h 20min', value: '5h 20min' },
    { label: '5h 25min', value: '5h 25min' },
    { label: '5h 30min', value: '5h 30min' },
    { label: '5h 35min', value: '5h 35min' },
    { label: '5h 40min', value: '5h 40min' },
    { label: '5h 45min', value: '5h 45min' },
    { label: '5h 50min', value: '5h 50min' },
    { label: '5h 55min', value: '5h 55min' },
    { label: '6h', value: '6h' },
    { label: '6h 5min', value: '6h 5min' },
    { label: '6h 10min', value: '6h 10min' },
    { label: '6h 15min', value: '6h 15min' },
    { label: '6h 20min', value: '6h 20min' },
    { label: '6h 25min', value: '6h 25min' },
    { label: '6h 30min', value: '6h 30min' },
    { label: '6h 35min', value: '6h 35min' },
    { label: '6h 40min', value: '6h 40min' },
    { label: '6h 45min', value: '6h 45min' },
    { label: '6h 50min', value: '6h 50min' },
    { label: '6h 55min', value: '6h 55min' },
    { label: '7h', value: '7h' },
    { label: '7h 5min', value: '7h 5min' },
    { label: '7h 10min', value: '7h 10min' },
    { label: '7h 15min', value: '7h 15min' },
    { label: '7h 20min', value: '7h 20min' },
    { label: '7h 25min', value: '7h 25min' },
    { label: '7h 30min', value: '7h 30min' },
    { label: '7h 35min', value: '7h 35min' },
    { label: '7h 40min', value: '7h 40min' },
    { label: '7h 45min', value: '7h 45min' },
    { label: '7h 50min', value: '7h 50min' },
    { label: '7h 55min', value: '7h 55min' },
    { label: '8h', value: '8h' },
    { label: '8h 5min', value: '8h 5min' },
    { label: '8h 10min', value: '8h 10min' },
    { label: '8h 15min', value: '8h 15min' },
    { label: '8h 20min', value: '8h 20min' },
    { label: '8h 25min', value: '8h 25min' },
    { label: '8h 30min', value: '8h 30min' },
    { label: '8h 35min', value: '8h 35min' },
    { label: '8h 40min', value: '8h 40min' },
    { label: '8h 45min', value: '8h 45min' },
    { label: '8h 50min', value: '8h 50min' },
    { label: '8h 55min: 8h 55min' },
    { label: '9h', value: '9h' },
    { label: '9h 5min', value: '9h 5min' },
    { label: '9h 10Mmin', value: '9h 10min' },
    { label: '9h 15Mmin', value: '9h 15min' },
    { label: '9h 20min', value: '9h 20min' },
    { label: '9h 25min', value: '9h 25min' },
    { label: '9h 30min', value: '9h 30min' },
    { label: '9h 35min', value: '9h 35min' },
    { label: '9h 40min', value: '9h 40min' },
    { label: '9h 45min', value: '9h 45min' },
    { label: '9h 50min', value: '9h 50min' },
    { label: '9h 55min', value: '9h 55min' },
    { label: '10h', value: '10h' },
    { label: '10h 5min', value: '10h 5min' },
    { label: '10h 10Amin', value: '10h 10min' },
    { label: '10h 15min', value: '10h 15min' },
    { label: '10h 20min', value: '10h 20min' },
    { label: '10h 25min', value: '10h 25min' },
    { label: '10h 30min', value: '10h 30min' },
    { label: '10h 35min', value: '10h 35min' },
    { label: '10h 40min', value: '10h 40min' },
    { label: '10h 45min', value: '10h 45min' },
    { label: '10h 50min', value: '10h 50min' },
    { label: '10h 55min', value: '10h 55min' },
    { label: '11h', value: '11h' },
    { label: '11h 5min', value: '11h 5min' },
    { label: '11h 10min', value: '11h 10min' },
    { label: '11h 20min', value: '11h 20min' },
    { label: '11h 15min', value: '11h 15min' },
    { label: '11h 25min', value: '11h 25min' },
    { label: '11h 30min', value: '11h 30min' },
    { label: '11h 35min', value: '11h 35min' },
    { label: '11h 40min', value: '11h 40min' },
    { label: '11h 45min', value: '11h 45min' },
    { label: '11h 50min', value: '11h 50min' },
    { label: '11h 55min', value: '11h 55min' },
    { label: '12h', value: '12h' },
]


export const OPENING_TIME = [
    { label: '12:00 AM', value: '00:00:00' },
    { label: '12:30 AM', value: '00:30:00' },
    { label: '01:00 AM', value: '01:00:00' },
    { label: '01:30 AM', value: '01:30:00' },
    { label: '02:00 AM', value: '02:00:00' },
    { label: '02:30 AM', value: '02:30:00' },
    { label: '03:00 AM', value: '03:00:00' },
    { label: '03:30 AM', value: '03:30:00' },
    { label: '04:00 AM', value: '04:00:00' },
    { label: '04:30 AM', value: '04:30:00' },
    { label: '05:00 AM', value: '05:00:00' },
    { label: '05:30 AM', value: '05:30:00' },
    { label: '06:00 AM', value: '06:00:00' },
    { label: '06:30 AM', value: '06:30:00' },
    { label: '07:00 AM', value: '07:00:00' },
    { label: '07:30 AM', value: '07:30:00' },
    { label: '08:00 AM', value: '08:00:00' },
    { label: '08:30 AM', value: '08:30:00' },
    { label: '09:00 AM', value: '09:00:00' },
    { label: '09:30 AM', value: '09:30:00' },
    { label: '10:00 AM', value: '10:00:00' },
    { label: '10:30 AM', value: '10:30:00' },
    { label: '11:00 AM', value: '11:00:00' },
    { label: '11:30 AM', value: '11:30:00' },
    { label: '12:00 PM', value: '12:00:00' },
    { label: '12:30 PM', value: '12:30:00' },
    { label: '01:00 PM', value: '13:00:00' },
    { label: '01:30 PM', value: '13:30:00' },
    { label: '02:00 PM', value: '14:00:00' },
    { label: '02:30 PM', value: '14:30:00' },
    { label: '03:00 PM', value: '15:00:00' },
    { label: '03:30 PM', value: '15:30:00' },
    { label: '04:00 PM', value: '16:00:00' },
    { label: '04:30 PM', value: '16:30:00' },
    { label: '05:00 PM', value: '17:00:00' },
    { label: '05:30 PM', value: '17:30:00' },
    { label: '06:00 PM', value: '18:00:00' },
    { label: '06:30 PM', value: '18:30:00' },
    { label: '07:00 PM', value: '19:00:00' },
    { label: '07:30 PM', value: '19:30:00' },
    { label: '08:00 PM', value: '20:00:00' },
    { label: '08:30 PM', value: '20:30:00' },
    { label: '09:00 PM', value: '21:00:00' },
    { label: '09:30 PM', value: '21:30:00' },
    { label: '10:00 PM', value: '22:00:00' },
    { label: '10:30 PM', value: '22:30:00' },
    { label: '11:00 PM', value: '23:00:00' },
    { label: '11:30 PM', value: '23:30:00' },
]

export const CALENDAR_OPENING_TIME = [
    {
        value: '00:00:00',
        label: '12:00 AM'
    },
    {
        value: '00:05:00',
        label: '12:05 AM'
    },
    {
        value: '00:10:00',
        label: '12:10 AM'
    },
    {
        value: '00:15:00',
        label: '12:15 AM'
    },
    {
        value: '00:20:00',
        label: '12:20 AM'
    },
    {
        value: '00:25:00',
        label: '12:25 AM'
    },
    {
        value: '00:30:00',
        label: '12:30 AM'
    },
    {
        value: '00:35:00',
        label: '12:35 AM'
    },
    {
        value: '00:40:00',
        label: '12:40 AM'
    },
    {
        value: '00:45:00',
        label: '12:45 AM'
    },
    {
        value: '00:50:00',
        label: '12:50 AM'
    },
    {
        value: '00:55:00',
        label: '12:55 AM'
    },
    {
        value: '01:00:00',
        label: '01:00 AM'
    },
    {
        value: '01:05:00',
        label: '01:05 AM'
    },
    {
        value: '01:10:00',
        label: '01:10 AM'
    },
    {
        value: '01:15:00',
        label: '01:15 AM'
    },
    {
        value: '01:20:00',
        label: '01:20 AM'
    },
    {
        value: '01:25:00',
        label: '01:25 AM'
    },
    {
        value: '01:30:00',
        label: '01:30 AM'
    },
    {
        value: '01:35:00',
        label: '01:35 AM'
    },
    {
        value: '01:40:00',
        label: '01:40 AM'
    },
    {
        value: '01:45:00',
        label: '01:45 AM'
    },
    {
        value: '01:50:00',
        label: '01:50 AM'
    },
    {
        value: '01:55:00',
        label: '01:55 AM'
    },
    {
        value: '02:00:00',
        label: '02:00 AM'
    },
    {
        value: '02:05:00',
        label: '02:05 AM'
    },
    {
        value: '02:10:00',
        label: '02:10 AM'
    },
    {
        value: '02:15:00',
        label: '02:15 AM'
    },
    {
        value: '02:20:00',
        label: '02:20 AM'
    },
    {
        value: '02:25:00',
        label: '02:25 AM'
    },
    {
        value: '02:30:00',
        label: '02:30 AM'
    },
    {
        value: '02:35:00',
        label: '02:35 AM'
    },
    {
        value: '02:40:00',
        label: '02:40 AM'
    },
    {
        value: '02:45:00',
        label: '02:45 AM'
    },
    {
        value: '02:50:00',
        label: '02:50 AM'
    },
    {
        value: '02:55:00',
        label: '02:55 AM'
    },
    {
        value: '03:00:00',
        label: '03:00 AM'
    },
    {
        value: '03:05:00',
        label: '03:05 AM'
    },
    {
        value: '03:10:00',
        label: '03:10 AM'
    },
    {
        value: '03:15:00',
        label: '03:15 AM'
    },
    {
        value: '03:20:00',
        label: '03:20 AM'
    },
    {
        value: '03:25:00',
        label: '03:25 AM'
    },
    {
        value: '03:30:00',
        label: '03:30 AM'
    },
    {
        value: '03:35:00',
        label: '03:35 AM'
    },
    {
        value: '03:40:00',
        label: '03:40 AM'
    },
    {
        value: '03:45:00',
        label: '03:45 AM'
    },
    {
        value: '03:50:00',
        label: '03:50 AM'
    },
    {
        value: '03:55:00',
        label: '03:55 AM'
    },
    {
        value: '04:00:00',
        label: '04:00 AM'
    },
    {
        value: '04:05:00',
        label: '04:05 AM'
    },
    {
        value: '04:10:00',
        label: '04:10 AM'
    },
    {
        value: '04:15:00',
        label: '04:15 AM'
    },
    {
        value: '04:20:00',
        label: '04:20 AM'
    },
    {
        value: '04:25:00',
        label: '04:25 AM'
    },
    {
        value: '04:30:00',
        label: '04:30 AM'
    },
    {
        value: '04:35:00',
        label: '04:35 AM'
    },
    {
        value: '04:40:00',
        label: '04:40 AM'
    },
    {
        value: '04:45:00',
        label: '04:45 AM'
    },
    {
        value: '04:50:00',
        label: '04:50 AM'
    },
    {
        value: '04:55:00',
        label: '04:55 AM'
    },
    {
        value: '05:00:00',
        label: '05:00 AM'
    },
    {
        value: '05:05:00',
        label: '05:05 AM'
    },
    {
        value: '05:10:00',
        label: '05:10 AM'
    },
    {
        value: '05:15:00',
        label: '05:15 AM'
    },
    {
        value: '05:20:00',
        label: '05:20 AM'
    },
    {
        value: '05:25:00',
        label: '05:25 AM'
    },
    {
        value: '05:30:00',
        label: '05:30 AM'
    },
    {
        value: '05:35:00',
        label: '05:35 AM'
    },
    {
        value: '05:40:00',
        label: '05:40 AM'
    },
    {
        value: '05:45:00',
        label: '05:45 AM'
    },
    {
        value: '05:50:00',
        label: '05:50 AM'
    },
    {
        value: '05:55:00',
        label: '05:55 AM'
    },
    {
        value: '06:00:00',
        label: '06:00 AM'
    },
    {
        value: '06:05:00',
        label: '06:05 AM'
    },
    {
        value: '06:10:00',
        label: '06:10 AM'
    },
    {
        value: '06:15:00',
        label: '06:15 AM'
    },
    {
        value: '06:20:00',
        label: '06:20 AM'
    },
    {
        value: '06:25:00',
        label: '06:25 AM'
    },
    {
        value: '06:30:00',
        label: '06:30 AM'
    },
    {
        value: '06:35:00',
        label: '06:35 AM'
    },
    {
        value: '06:40:00',
        label: '06:40 AM'
    },
    {
        value: '06:45:00',
        label: '06:45 AM'
    },
    {
        value: '06:50:00',
        label: '06:50 AM'
    },
    {
        value: '06:55:00',
        label: '06:55 AM'
    },
    {
        value: '07:00:00',
        label: '07:00 AM'
    },
    {
        value: '07:05:00',
        label: '07:05 AM'
    },
    {
        value: '07:10:00',
        label: '07:10 AM'
    },
    {
        value: '07:15:00',
        label: '07:15 AM'
    },
    {
        value: '07:20:00',
        label: '07:20 AM'
    },
    {
        value: '07:25:00',
        label: '07:25 AM'
    },
    {
        value: '07:30:00',
        label: '07:30 AM'
    },
    {
        value: '07:35:00',
        label: '07:35 AM'
    },
    {
        value: '07:40:00',
        label: '07:40 AM'
    },
    {
        value: '07:45:00',
        label: '07:45 AM'
    },
    {
        value: '07:50:00',
        label: '07:50 AM'
    },
    {
        value: '07:55:00',
        label: '07:55 AM'
    },
    {
        value: '08:00:00',
        label: '08:00 AM'
    },
    {
        value: '08:05:00',
        label: '08:05 AM'
    },
    {
        value: '08:10:00',
        label: '08:10 AM'
    },
    {
        value: '08:15:00',
        label: '08:15 AM'
    },
    {
        value: '08:20:00',
        label: '08:20 AM'
    },
    {
        value: '08:25:00',
        label: '08:25 AM'
    },
    {
        value: '08:30:00',
        label: '08:30 AM'
    },
    {
        value: '08:35:00',
        label: '08:35 AM'
    },
    {
        value: '08:40:00',
        label: '08:40 AM'
    },
    {
        value: '08:45:00',
        label: '08:45 AM'
    },
    {
        value: '08:50:00',
        label: '08:50 AM'
    },
    {
        value: '08:55:00',
        label: '08:55 AM'
    },
    {
        value: '09:00:00',
        label: '09:00 AM'
    },
    {
        value: '09:05:00',
        label: '09:05 AM'
    },
    {
        value: '09:10:00',
        label: '09:10 AM'
    },
    {
        value: '09:15:00',
        label: '09:15 AM'
    },
    {
        value: '09:20:00',
        label: '09:20 AM'
    },
    {
        value: '09:25:00',
        label: '09:25 AM'
    },
    {
        value: '09:30:00',
        label: '09:30 AM'
    },
    {
        value: '09:35:00',
        label: '09:35 AM'
    },
    {
        value: '09:40:00',
        label: '09:40 AM'
    },
    {
        value: '09:45:00',
        label: '09:45 AM'
    },
    {
        value: '09:50:00',
        label: '09:50 AM'
    },
    {
        value: '09:55:00',
        label: '09:55 AM'
    },
    {
        value: '10:00:00',
        label: '10:00 AM'
    },
    {
        value: '10:05:00',
        label: '10:05 AM'
    },
    {
        value: '10:10:00',
        label: '10:10 AM'
    },
    {
        value: '10:15:00',
        label: '10:15 AM'
    },
    {
        value: '10:20:00',
        label: '10:20 AM'
    },
    {
        value: '10:25:00',
        label: '10:25 AM'
    },
    {
        value: '10:30:00',
        label: '10:30 AM'
    },
    {
        value: '10:35:00',
        label: '10:35 AM'
    },
    {
        value: '10:40:00',
        label: '10:40 AM'
    },
    {
        value: '10:45:00',
        label: '10:45 AM'
    },
    {
        value: '10:50:00',
        label: '10:50 AM'
    },
    {
        value: '10:55:00',
        label: '10:55 AM'
    },
    {
        value: '11:00:00',
        label: '11:00 AM'
    },
    {
        value: '11:05:00',
        label: '11:05 AM'
    },
    {
        value: '11:10:00',
        label: '11:10 AM'
    },
    {
        value: '11:15:00',
        label: '11:15 AM'
    },
    {
        value: '11:20:00',
        label: '11:20 AM'
    },
    {
        value: '11:25:00',
        label: '11:25 AM'
    },
    {
        value: '11:30:00',
        label: '11:30 AM'
    },
    {
        value: '11:35:00',
        label: '11:35 AM'
    },
    {
        value: '11:40:00',
        label: '11:40 AM'
    },
    {
        value: '11:45:00',
        label: '11:45 AM'
    },
    {
        value: '11:50:00',
        label: '11:50 AM'
    },
    {
        value: '11:55:00',
        label: '11:55 AM'
    },
    {
        value: '12:00:00',
        label: '12:00 PM'
    },
    {
        value: '12:05:00',
        label: '12:05 PM'
    },
    {
        value: '12:10:00',
        label: '12:10 PM'
    },
    {
        value: '12:15:00',
        label: '12:15 PM'
    },
    {
        value: '12:20:00',
        label: '12:20 PM'
    },
    {
        value: '12:25:00',
        label: '12:25 PM'
    },
    {
        value: '12:30:00',
        label: '12:30 PM'
    },
    {
        value: '12:35:00',
        label: '12:35 PM'
    },
    {
        value: '12:40:00',
        label: '12:40 PM'
    },
    {
        value: '12:45:00',
        label: '12:45 PM'
    },
    {
        value: '12:50:00',
        label: '12:50 PM'
    },
    {
        value: '12:55:00',
        label: '12:55 PM'
    },
    {
        value: '13:00:00',
        label: '01:00 PM'
    },
    {
        value: '13:05:00',
        label: '01:05 PM'
    },
    {
        value: '13:10:00',
        label: '01:10 PM'
    },
    {
        value: '13:15:00',
        label: '01:15 PM'
    },
    {
        value: '13:20:00',
        label: '01:20 PM'
    },
    {
        value: '13:25:00',
        label: '01:25 PM'
    },
    {
        value: '13:30:00',
        label: '01:30 PM'
    },
    {
        value: '13:35:00',
        label: '01:35 PM'
    },
    {
        value: '13:40:00',
        label: '01:40 PM'
    },
    {
        value: '13:45:00',
        label: '01:45 PM'
    },
    {
        value: '13:50:00',
        label: '01:50 PM'
    },
    {
        value: '13:55:00',
        label: '01:55 PM'
    },
    {
        value: '14:00:00',
        label: '02:00 PM'
    },
    {
        value: '14:05:00',
        label: '02:05 PM'
    },
    {
        value: '14:10:00',
        label: '02:10 PM'
    },
    {
        value: '14:15:00',
        label: '02:15 PM'
    },
    {
        value: '14:20:00',
        label: '02:20 PM'
    },
    {
        value: '14:25:00',
        label: '02:25 PM'
    },
    {
        value: '14:30:00',
        label: '02:30 PM'
    },
    {
        value: '14:35:00',
        label: '02:35 PM'
    },
    {
        value: '14:40:00',
        label: '02:40 PM'
    },
    {
        value: '14:45:00',
        label: '02:45 PM'
    },
    {
        value: '14:50:00',
        label: '02:50 PM'
    },
    {
        value: '14:55:00',
        label: '02:55 PM'
    },
    {
        value: '15:00:00',
        label: '03:00 PM'
    },
    {
        value: '15:05:00',
        label: '03:05 PM'
    },
    {
        value: '15:10:00',
        label: '03:10 PM'
    },
    {
        value: '15:15:00',
        label: '03:15 PM'
    },
    {
        value: '15:20:00',
        label: '03:20 PM'
    },
    {
        value: '15:25:00',
        label: '03:25 PM'
    },
    {
        value: '15:30:00',
        label: '03:30 PM'
    },
    {
        value: '15:35:00',
        label: '03:35 PM'
    },
    {
        value: '15:40:00',
        label: '03:40 PM'
    },
    {
        value: '15:45:00',
        label: '03:45 PM'
    },
    {
        value: '15:50:00',
        label: '03:50 PM'
    },
    {
        value: '15:55:00',
        label: '03:55 PM'
    },
    {
        value: '16:00:00',
        label: '04:00 PM'
    },
    {
        value: '16:05:00',
        label: '04:05 PM'
    },
    {
        value: '16:10:00',
        label: '04:10 PM'
    },
    {
        value: '16:15:00',
        label: '04:15 PM'
    },
    {
        value: '16:20:00',
        label: '04:20 PM'
    },
    {
        value: '16:25:00',
        label: '04:25 PM'
    },
    {
        value: '16:30:00',
        label: '04:30 PM'
    },
    {
        value: '16:35:00',
        label: '04:35 PM'
    },
    {
        value: '16:40:00',
        label: '04:40 PM'
    },
    {
        value: '16:45:00',
        label: '04:45 PM'
    },
    {
        value: '16:50:00',
        label: '04:50 PM'
    },
    {
        value: '16:55:00',
        label: '04:55 PM'
    },
    {
        value: '17:00:00',
        label: '05:00 PM'
    },
    {
        value: '17:05:00',
        label: '05:05 PM'
    },
    {
        value: '17:10:00',
        label: '05:10 PM'
    },
    {
        value: '17:15:00',
        label: '05:15 PM'
    },
    {
        value: '17:20:00',
        label: '05:20 PM'
    },
    {
        value: '17:25:00',
        label: '05:25 PM'
    },
    {
        value: '17:30:00',
        label: '05:30 PM'
    },
    {
        value: '17:35:00',
        label: '05:35 PM'
    },
    {
        value: '17:40:00',
        label: '05:40 PM'
    },
    {
        value: '17:45:00',
        label: '05:45 PM'
    },
    {
        value: '17:50:00',
        label: '05:50 PM'
    },
    {
        value: '17:55:00',
        label: '05:55 PM'
    },
    {
        value: '18:00:00',
        label: '06:00 PM'
    },
    {
        value: '18:05:00',
        label: '06:05 PM'
    },
    {
        value: '18:10:00',
        label: '06:10 PM'
    },
    {
        value: '18:15:00',
        label: '06:15 PM'
    },
    {
        value: '18:20:00',
        label: '06:20 PM'
    },
    {
        value: '18:25:00',
        label: '06:25 PM'
    },
    {
        value: '18:30:00',
        label: '06:30 PM'
    },
    {
        value: '18:35:00',
        label: '06:35 PM'
    },
    {
        value: '18:40:00',
        label: '06:40 PM'
    },
    {
        value: '18:45:00',
        label: '06:45 PM'
    },
    {
        value: '18:50:00',
        label: '06:50 PM'
    },
    {
        value: '18:55:00',
        label: '06:55 PM'
    },
    {
        value: '19:00:00',
        label: '07:00 PM'
    },
    {
        value: '19:05:00',
        label: '07:05 PM'
    },
    {
        value: '19:10:00',
        label: '07:10 PM'
    },
    {
        value: '19:15:00',
        label: '07:15 PM'
    },
    {
        value: '19:20:00',
        label: '07:20 PM'
    },
    {
        value: '19:25:00',
        label: '07:25 PM'
    },
    {
        value: '19:30:00',
        label: '07:30 PM'
    },
    {
        value: '19:35:00',
        label: '07:35 PM'
    },
    {
        value: '19:40:00',
        label: '07:40 PM'
    },
    {
        value: '19:45:00',
        label: '07:45 PM'
    },
    {
        value: '19:50:00',
        label: '07:50 PM'
    },
    {
        value: '19:55:00',
        label: '07:55 PM'
    },
    {
        value: '20:00:00',
        label: '08:00 PM'
    },
    {
        value: '20:05:00',
        label: '08:05 PM'
    },
    {
        value: '20:10:00',
        label: '08:10 PM'
    },
    {
        value: '20:15:00',
        label: '08:15 PM'
    },
    {
        value: '20:20:00',
        label: '08:20 PM'
    },
    {
        value: '20:25:00',
        label: '08:25 PM'
    },
    {
        value: '20:30:00',
        label: '08:30 PM'
    },
    {
        value: '20:35:00',
        label: '08:35 PM'
    },
    {
        value: '20:40:00',
        label: '08:40 PM'
    },
    {
        value: '20:45:00',
        label: '08:45 PM'
    },
    {
        value: '20:50:00',
        label: '08:50 PM'
    },
    {
        value: '20:55:00',
        label: '08:55 PM'
    },
    {
        value: '21:00:00',
        label: '09:00 PM'
    },
    {
        value: '21:05:00',
        label: '09:05 PM'
    },
    {
        value: '21:10:00',
        label: '09:10 PM'
    },
    {
        value: '21:15:00',
        label: '09:15 PM'
    },
    {
        value: '21:20:00',
        label: '09:20 PM'
    },
    {
        value: '21:25:00',
        label: '09:25 PM'
    },
    {
        value: '21:30:00',
        label: '09:30 PM'
    },
    {
        value: '21:35:00',
        label: '09:35 PM'
    },
    {
        value: '21:40:00',
        label: '09:40 PM'
    },
    {
        value: '21:45:00',
        label: '09:45 PM'
    },
    {
        value: '21:50:00',
        label: '09:50 PM'
    },
    {
        value: '21:55:00',
        label: '09:55 PM'
    },
    {
        value: '22:00:00',
        label: '10:00 PM'
    },
    {
        value: '22:05:00',
        label: '10:05 PM'
    },
    {
        value: '22:10:00',
        label: '10:10 PM'
    },
    {
        value: '22:15:00',
        label: '10:15 PM'
    },
    {
        value: '22:20:00',
        label: '10:20 PM'
    },
    {
        value: '22:25:00',
        label: '10:25 PM'
    },
    {
        value: '22:30:00',
        label: '10:30 PM'
    },
    {
        value: '22:35:00',
        label: '10:35 PM'
    },
    {
        value: '22:40:00',
        label: '10:40 PM'
    },
    {
        value: '22:45:00',
        label: '10:45 PM'
    },
    {
        value: '22:50:00',
        label: '10:50 PM'
    },
    {
        value: '22:55:00',
        label: '10:55 PM'
    },
    {
        value: '23:00:00',
        label: '11:00 PM'
    },
    {
        value: '23:05:00',
        label: '11:05 PM'
    },
    {
        value: '23:10:00',
        label: '11:10 PM'
    },
    {
        value: '23:15:00',
        label: '11:15 PM'
    },
    {
        value: '23:20:00',
        label: '11:20 PM'
    },
    {
        value: '23:25:00',
        label: '11:25 PM'
    },
    {
        value: '23:30:00',
        label: '11:30 PM'
    },
    {
        value: '23:35:00',
        label: '11:35 PM'
    },
    {
        value: '23:40:00',
        label: '11:40 PM'
    },
    {
        value: '23:45:00',
        label: '11:45 PM'
    },
    {
        value: '23:50:00',
        label: '11:50 PM'
    },
    {
        value: '23:55:00',
        label: '11:55 PM'
    },
]

export const APPOINTMENT_TIME = [
    // { label: '5min', value: '5min' },
    // { label: '10min', value: '10min' },
    { label: '15min', value: '15min' },
    { label: '20min', value: '20min' },
    { label: '25min', value: '25min' },
    { label: '30min', value: '30min' },
    { label: '35min', value: '35min' },
    { label: '40min', value: '40min' },
    { label: '45min', value: '45min' },
    { label: '50min', value: '50min' },
    { label: '55min', value: '55min' },
    { label: '1h', value: '1h' },
    { label: '1h 5min', value: '1h 5min' },
    { label: '1h 10min', value: '1h 10min' },
    { label: '1h 15min', value: '1h 15min' },
    { label: '1h 20min', value: '1h 20min' },
    { label: '1h 25min', value: '1h 25min' },
    { label: '1h 30min', value: '1h 30min' },
    { label: '1h 35min', value: '1h 35min' },
    { label: '1h 40min', value: '1h 40min' },
    { label: '1h 45min', value: '1h 45min' },
    { label: '1h 50min', value: '1h 50min' },
    { label: '1h 55min', value: '1h 55min' },
    { label: '2h', value: '2h' },
    { label: '2h 5min', value: '2: 5min' },
    { label: '2h 10min', value: '2h 10min' },
    { label: '2h 15min', value: '2h 15min' },
    { label: '2h 20min', value: '2h 20min' },
    { label: '2h 25min', value: '2h 25min' },
    { label: '2h 30min', value: '2h 30min' },
    { label: '2h 35min', value: '2h 35min' },
    { label: '2h 40min', value: '2h 40min' },
    { label: '2h 45min', value: '2h 45min' },
    { label: '2h 50min', value: '2h 50min' },
    { label: '2h 55min', value: '2h 55min' },
    { label: '3h', value: '3h' },
    { label: '3h 5min', value: '3: 5min' },
    { label: '3h 10min', value: '3h 10min' },
    { label: '3h 15min', value: '3h 15min' },
    { label: '3h 20min', value: '3h 20min' },
    { label: '3h 25min', value: '3h 25min' },
    { label: '3h 30min', value: '3h 30min' },
    { label: '3h 35min', value: '3h 35min' },
    { label: '3h 40min', value: '3h 40min' },
    { label: '3h 45min', value: '3h 45min' },
    { label: '3h 50min', value: '3h 50min' },
    { label: '3h 55min', value: '3h 55min' },
    { label: '4h', value: '4h ' },
    { label: '4h 5min', value: '4h 5min' },
    { label: '4h 10min', value: '4h 10min' },
    { label: '4h 15min', value: '4h 15min' },
    { label: '4h 20min', value: '4h 20min' },
    { label: '4h 25min', value: '4h 25min' },
    { label: '4h 30min', value: '4h 30min' },
    { label: '4h 35min', value: '4h 35min' },
    { label: '4h 40min', value: '4h 40min' },
    { label: '4h 45min', value: '4h 45min' },
    { label: '4h 50min', value: '4h 50min' },
    { label: '4h 55min', value: '4h 55min' },
    { label: '5h', value: '5h' },
    { label: '5h 5min', value: '5h 5min' },
    { label: '5h 10min', value: '5h 10min' },
    { label: '5h 15min', value: '5h 15min' },
    { label: '5h 20min', value: '5h 20min' },
    { label: '5h 25min', value: '5h 25min' },
    { label: '5h 30min', value: '5h 30min' },
    { label: '5h 35min', value: '5h 35min' },
    { label: '5h 40min', value: '5h 40min' },
    { label: '5h 45min', value: '5h 45min' },
    { label: '5h 50min', value: '5h 50min' },
    { label: '5h 55min', value: '5h 55min' },
    { label: '6h', value: '6h' },
    { label: '6h 5min', value: '6h 5min' },
    { label: '6h 10min', value: '6h 10min' },
    { label: '6h 15min', value: '6h 15min' },
    { label: '6h 20min', value: '6h 20min' },
    { label: '6h 25min', value: '6h 25min' },
    { label: '6h 30min', value: '6h 30min' },
    { label: '6h 35min', value: '6h 35min' },
    { label: '6h 40min', value: '6h 40min' },
    { label: '6h 45min', value: '6h 45min' },
    { label: '6h 50min', value: '6h 50min' },
    { label: '6h 55min', value: '6h 55min' },
    { label: '7h', value: '7h' },
    { label: '7h 5min', value: '7h 5min' },
    { label: '7h 10min', value: '7h 10min' },
    { label: '7h 15min', value: '7h 15min' },
    { label: '7h 20min', value: '7h 20min' },
    { label: '7h 25min', value: '7h 25min' },
    { label: '7h 30min', value: '7h 30min' },
    { label: '7h 35min', value: '7h 35min' },
    { label: '7h 40min', value: '7h 40min' },
    { label: '7h 45min', value: '7h 45min' },
    { label: '7h 50min', value: '7h 50min' },
    { label: '7h 55min', value: '7h 55min' },
    { label: '8h', value: '8h' },
    { label: '8h 5min', value: '8h 5min' },
    { label: '8h 10min', value: '8h 10min' },
    { label: '8h 15min', value: '8h 15min' },
    { label: '8h 20min', value: '8h 20min' },
    { label: '8h 25min', value: '8h 25min' },
    { label: '8h 30min', value: '8h 30min' },
    { label: '8h 35min', value: '8h 35min' },
    { label: '8h 40min', value: '8h 40min' },
    { label: '8h 45min', value: '8h 45min' },
    { label: '8h 50min', value: '8h 50min' },
    { label: '8h 55min: 8h 55min' },
    { label: '9h', value: '9h' },
    { label: '9h 5min', value: '9h 5min' },
    { label: '9h 10Mmin', value: '9h 10min' },
    { label: '9h 15Mmin', value: '9h 15min' },
    { label: '9h 20min', value: '9h 20min' },
    { label: '9h 25min', value: '9h 25min' },
    { label: '9h 30min', value: '9h 30min' },
    { label: '9h 35min', value: '9h 35min' },
    { label: '9h 40min', value: '9h 40min' },
    { label: '9h 45min', value: '9h 45min' },
    { label: '9h 50min', value: '9h 50min' },
    { label: '9h 55min', value: '9h 55min' },
    { label: '10h', value: '10h' },
    { label: '10h 5min', value: '10h 5min' },
    { label: '10h 10Amin', value: '10h 10min' },
    { label: '10h 15min', value: '10h 15min' },
    { label: '10h 20min', value: '10h 20min' },
    { label: '10h 25min', value: '10h 25min' },
    { label: '10h 30min', value: '10h 30min' },
    { label: '10h 35min', value: '10h 35min' },
    { label: '10h 40min', value: '10h 40min' },
    { label: '10h 45min', value: '10h 45min' },
    { label: '10h 50min', value: '10h 50min' },
    { label: '10h 55min', value: '10h 55min' },
    { label: '11h', value: '11h' },
    { label: '11h 5min', value: '11h 5min' },
    { label: '11h 10min', value: '11h 10min' },
    { label: '11h 15min', value: '11h 15min' },
    { label: '11h 20min', value: '11h 20min' },
    { label: '11h 25min', value: '11h 25min' },
    { label: '11h 30min', value: '11h 30min' },
    { label: '11h 35min', value: '11h 35min' },
    { label: '11h 40min', value: '11h 40min' },
    { label: '11h 45min', value: '11h 45min' },
    { label: '11h 50min', value: '11h 50min' },
    { label: '11h 55min', value: '11h 55min' },
    { label: '12h', value: '12h' },
    { label: '12h 5min', value: '12h 5min' },
    { label: '12h 10min', value: '12h 10min' },
    { label: '12h 15min', value: '12h 15min' },
    { label: '12h 20min', value: '12h 20min' },
    { label: '12h 25min', value: '12h 25min' },
    { label: '12h 30min', value: '12h 30min' },
    { label: '12h 35min', value: '12h 35min' },
    { label: '12h 40min', value: '12h 40min' },
    { label: '12h 45min', value: '12h 45min' },
    { label: '12h 50min', value: '12h 50min' },
    { label: '12h 55min', value: '12h 55min' },
    { label: '13h', value: '13h' },
    { label: '13h 5min', value: '13h 5min' },
    { label: '13h 10min', value: '13h 10min' },
    { label: '13h 15min', value: '13h 15min' },
    { label: '13h 20min', value: '13h 20min' },
    { label: '13h 25min', value: '13h 25min' },
    { label: '13h 30min', value: '13h 30min' },
    { label: '13h 35min', value: '13h 35min' },
    { label: '13h 40min', value: '13h 40min' },
    { label: '13h 45min', value: '13h 45min' },
    { label: '13h 50min', value: '13h 50min' },
    { label: '13h 55min', value: '13h 55min' },
    { label: '14h', value: '14h' },
    { label: '14h 5min', value: '14h 5min' },
    { label: '14h 10min', value: '14h 10min' },
    { label: '14h 15min', value: '14h 15min' },
    { label: '14h 20min', value: '14h 20min' },
    { label: '14h 25min', value: '14h 25min' },
    { label: '14h 30min', value: '14h 30min' },
    { label: '14h 35min', value: '14h 35min' },
    { label: '14h 40min', value: '14h 40min' },
    { label: '14h 45min', value: '14h 45min' },
    { label: '14h 50min', value: '14h 50min' },
    { label: '14h 55min', value: '14h 55min' },
    { label: '15h', value: '15h' },
    { label: '15h 5min', value: '15h 5min' },
    { label: '15h 10min', value: '15h 10min' },
    { label: '15h 15min', value: '15h 15min' },
    { label: '15h 20min', value: '15h 20min' },
    { label: '15h 25min', value: '15h 25min' },
    { label: '15h 30min', value: '15h 30min' },
    { label: '15h 35min', value: '15h 35min' },
    { label: '15h 40min', value: '15h 40min' },
    { label: '15h 45min', value: '15h 45min' },
    { label: '15h 50min', value: '15h 50min' },
    { label: '15h 55min', value: '15h 55min' },
    { label: '16h', value: '16h' },
    { label: '16h 5min', value: '16h 5min' },
    { label: '16h 10min', value: '16h 10min' },
    { label: '16h 20min', value: '16h 20min' },
    { label: '16h 15min', value: '16h 15min' },
    { label: '16h 25min', value: '16h 25min' },
    { label: '16h 30min', value: '16h 30min' },
    { label: '16h 35min', value: '16h 35min' },
    { label: '16h 40min', value: '16h 40min' },
    { label: '16h 45min', value: '16h 45min' },
    { label: '16h 50min', value: '16h 50min' },
    { label: '16h 55min', value: '16h 55min' },
    { label: '17h', value: '17h' },
    { label: '17h 5min', value: '17h 5min' },
    { label: '17h 10min', value: '17h 10min' },
    { label: '17h 15min', value: '17h 15min' },
    { label: '17h 20min', value: '17h 20min' },
    { label: '17h 25min', value: '17h 25min' },
    { label: '17h 30min', value: '17h 30min' },
    { label: '17h 35min', value: '17h 35min' },
    { label: '17h 40min', value: '17h 40min' },
    { label: '17h 45min', value: '17h 45min' },
    { label: '17h 50min', value: '17h 50min' },
    { label: '17h 55min', value: '17h 55min' },
    { label: '18h', value: '18h' },
    { label: '18h 5min', value: '18h 5min' },
    { label: '18h 10min', value: '18h 10min' },
    { label: '18h 20min', value: '18h 20min' },
    { label: '18h 15min', value: '18h 15min' },
    { label: '18h 25min', value: '18h 25min' },
    { label: '18h 30min', value: '18h 30min' },
    { label: '18h 35min', value: '18h 35min' },
    { label: '18h 40min', value: '18h 40min' },
    { label: '18h 45min', value: '18h 45min' },
    { label: '18h 50min', value: '18h 50min' },
    { label: '18h 55min', value: '18h 55min' },
    { label: '19h', value: '19h' },
    { label: '19h 5min', value: '19h 5min' },
    { label: '19h 10min', value: '19h 10min' },
    { label: '19h 15min', value: '19h 15min' },
    { label: '19h 20min', value: '19h 20min' },
    { label: '19h 25min', value: '19h 25min' },
    { label: '19h 30min', value: '19h 30min' },
    { label: '19h 35min', value: '19h 35min' },
    { label: '19h 40min', value: '19h 40min' },
    { label: '19h 45min', value: '19h 45min' },
    { label: '19h 50min', value: '19h 50min' },
    { label: '19h 55min', value: '19h 55min' },
    { label: '20h', value: '20h' },
    { label: '20h 5min', value: '20h 5min' },
    { label: '20h 10min', value: '20h 10min' },
    { label: '20h 15min', value: '20h 15min' },
    { label: '20h 20min', value: '20h 20min' },
    { label: '20h 25min', value: '20h 25min' },
    { label: '20h 30min', value: '20h 30min' },
    { label: '20h 35min', value: '20h 35min' },
    { label: '20h 40min', value: '20h 40min' },
    { label: '20h 45min', value: '20h 45min' },
    { label: '20h 50min', value: '20h 50min' },
    { label: '20h 55min', value: '20h 55min' },
    { label: '21h', value: '21h' },
    { label: '21h 5min', value: '21h 5min' },
    { label: '21h 10min', value: '21h 10min' },
    { label: '21h 15min', value: '21h 15min' },
    { label: '21h 20min', value: '21h 20min' },
    { label: '21h 25min', value: '21h 25min' },
    { label: '21h 30min', value: '21h 30min' },
    { label: '21h 35min', value: '21h 35min' },
    { label: '21h 40min', value: '21h 40min' },
    { label: '21h 45min', value: '21h 45min' },
    { label: '21h 50min', value: '21h 50min' },
    { label: '21h 55min', value: '21h 55min' },
    { label: '22h', value: '22h' },
    { label: '22h 5min', value: '22h 5min' },
    { label: '22h 10min', value: '22h 10min' },
    { label: '22h 15min', value: '22h 15min' },
    { label: '22h 20min', value: '22h 20min' },
    { label: '22h 25min', value: '22h 25min' },
    { label: '22h 30min', value: '22h 30min' },
    { label: '22h 35min', value: '22h 35min' },
    { label: '22h 40min', value: '22h 40min' },
    { label: '22h 45min', value: '22h 45min' },
    { label: '22h 50min', value: '22h 50min' },
    { label: '22h 55min', value: '22h 55min' },
    { label: '23h', value: '23h' },
    { label: '23h 5min', value: '23h 5min' },
    { label: '23h 10min', value: '23h 10min' },
    { label: '23h 15min', value: '23h 15min' },
    { label: '23h 20min', value: '23h 20min' },
    { label: '23h 25min', value: '23h 25min' },
    { label: '23h 30min', value: '23h 30min' },
    { label: '23h 35min', value: '23h 35min' },
    { label: '23h 40min', value: '23h 40min' },
    { label: '23h 45min', value: '23h 45min' },
    { label: '23h 50min', value: '23h 50min' },
    { label: '23h 55min', value: '23h 55min' },
    { label: '24h', value: '24h' },
]

export const CLOSING_TIME = [...OPENING_TIME]