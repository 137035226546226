import { ClientAppointmenrCard } from 'Components/TenantApp/Dashboard/Calendar/AddAppointment/ClientAppointmenrCard'
import React from 'react'

const ClientSectionDetailShimmer = () => {
    return (
        <>
            <div className='p-3 animate-pulse'>
                <div className='bg-slate-100 border-2 border-slate-100 rounded-md p-4 relative'>
                    <div className='pt-4'>
                        <div className='flex items-center gap-4'>
                            <div className='h-[4rem] w-[4rem] rounded-full overflow-hidden bg-gray-200'></div>
                            <div className='flex flex-col gap-1 w-3/5'>
                                <p className="bg-gray-300 rounded-[4px] h-4 w-3/5"></p>
                                <p className="bg-gray-200 rounded-[4px] h-3 w-2/5"></p>
                                <p className="bg-gray-200 rounded-[4px] h-3 w-2/5"></p>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-6 pt-4'>
                            <div className='flex flex-col justify-end gap-1 text-center pr-3 border-r border-[#E0E0F3]'>
                                <p className="bg-gray-200 rounded-[4px] h-5 mx-auto w-2/5"></p>
                                <p className="bg-gray-300 rounded-[4px] h-6 mx-auto w-3/5"></p>
                            </div>

                            {/* <div className='h-[3.5rem] bg-[#E0E0F3] w-[1px]'></div> */}

                            <div className='flex flex-col justify-end gap-1 text-center pr-3 border-r border-[#E0E0F3]'>
                                <p className="bg-gray-200 rounded-[4px] h-5 mx-auto w-2/5"></p>
                                <p className="bg-gray-300 rounded-[4px] h-6 mx-auto w-3/5"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='border-b flex items-center justify-between gap-5 px-2 font-semibold animate-pulse'>
                <div className="inline-grid xl:grid grid-cols-[repeat(4,minmax(15px,1fr))] py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                    <p className="bg-gray-300 rounded-[4px] h-8 w-2/3 mx-1"></p>
                    <p className="bg-gray-300 rounded-[4px] h-8 w-2/3 mx-1"></p>
                    <p className="bg-gray-300 rounded-[4px] h-8 w-2/3 mx-1"></p>
                    <p className="bg-gray-300 rounded-[4px] h-8 w-2/3 mx-1"></p>
                </div>
            </div>

            <div className='p-4'>
                <div>
                    <p className="bg-gray-200 rounded-[4px] h-8 w-1/3 mx-1"></p>
                    {[0, 1, 2, 3, 4]?.map((app, ind) => {
                        return <>
                            <ClientAppointmenrCard
                                animate
                            />
                        </>
                    })}
                </div>
            </div>
        </>
    )
}

export default ClientSectionDetailShimmer