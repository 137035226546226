import { EXPENSE_ACTIONS } from "Redux/TenantRedux/ActionTypes/expense"

const initialState = {
    expenses: [],
    expenseCategory: [],
    expensePayment: [],
    expenseAnalytics: [],
    expensePagination: {},
    expenseCategoryPagination:{},
    allExpenseCategory: []
}

const ExpenseReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case EXPENSE_ACTIONS.GET_EXPENSE_CATEGORIES_NO_PAGINATION:
            return {
                ...state,
                allExpenseCategory: payload?.response?.data
            };
        case EXPENSE_ACTIONS.GET_EXPENSE_CATEGORIES:
            return {
                ...state,
                expenseCategory: payload?.response?.data,
                expenseCategoryPagination: {
                    total_pages: payload?.total_pages,
                    total_count: payload?.count,
                    current_page: payload?.current_page
                }
            };
        case EXPENSE_ACTIONS.GET_EXPENSE_PAYMENT:
            return {
                ...state,
                expensePayment: payload?.response?.data,
            };
        case EXPENSE_ACTIONS.GET_EXPENSE_ANALYTICS:
            return {
                ...state,
                expenseAnalytics: payload?.response?.data,
            };
        case EXPENSE_ACTIONS.GET_EXPENSE:
            return {
                ...state,
                expenses: payload?.response?.data,
                expensePagination: {
                    total_pages: payload?.total_pages,
                    total_count: payload?.count,
                    current_page: payload?.current_page
                }
            };
        default:
            return state;
    }
}

export default ExpenseReducer