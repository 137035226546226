


export const PasswordValidation = (password) => {

    var strength = 0;
    var strength_value = 0

    if (password.match(/[a-z]+/)) {
        strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
        strength += 1;
    }
    if (password.match(/[0-9]+/)) {
        strength += 1;
    }
    if (password.match(/[$@#&!]+/)) {
        strength += 1;
    }
    if (password.length < 6){
        strength -= 1
    }

    switch (strength) {
        case 0:
            strength_value = 0;
            break;

        case 1:
            strength_value = 25;
            break;

        case 2:
            strength_value = 50;
            break;

        case 3:
            strength_value = 75;
            break;

        case 4:
            strength_value = 100;
            break;
        default:
            strength_value = 0
    }
    return strength_value
}