import { GET_USER_DETAILS, MAKE_LOGGEDIN_USER } from "../../ActionTypes/UserActionTypes";

import { GET_USER_PERMISSIONS } from "../../ActionTypes/UserActionTypes";

const initialState = {
  user: {},
  permissions: {},
  loggedIn: false,
  isPermissionUpdated: false
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAKE_LOGGEDIN_USER:
      return {
        ...state,
        loggedIn: action.payload,
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        user: action.payload.data,
      };
    case GET_USER_PERMISSIONS:
      return {
        ...state,
        isPermissionUpdated: true,
        permissions: action.payload.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default UserReducer;
