import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Svgs from "Assets/svgs";
import AccountSetupCard from "../AccountSetupCard";
import useSaleModule from "./helper";

const DashboardSales = () => {
  const state = useSelector((state) => state);

  const { translation, selectedLanguage } = useSaleModule();

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  const [sales_cards, setSalesCards] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const saleMenuArr = [
    {
      title: selectedLanguage?.name == "English" ? "Quick Sales" : translation[148]?.value ? translation[148]?.value : "Quick Sales",
      icon: <Svgs.QuickSales />,
      link: "/dashboard/sales/quick-sales",
      description: selectedLanguage?.name == "English" ? "Add and manage client profiles." : translation[227]?.value ? translation[227]?.value : "Add and manage client profiles.",
      permission_value: "sales_root_access",
      permission_apply_offer_discount: "sales_apply_offer",
      pos_value: "pos_root_access"
    },
    // {
    //   title: "Sale Orders",
    //   icon: <Svgs.SaleOrder />,
    //   link: "/dashboard/sales/sale-orders",
    //   description: "Manage client groups. Add edit and delete client groups.",
    //   permission_value: "sales_root_access",
    //   pos_value: "pos_root_access"
    // },
    {
      title: selectedLanguage?.name == "English" ? "Appointments" : translation[147]?.value ? translation[147]?.value : "Appointments",
      icon: <Svgs.APPOINTMENT_REWARD />,
      link: "/dashboard/sales/appointments?active_tab=appointments",
      description: selectedLanguage?.name == "English" ? "Schedule, track, and generate reports for appointments." : translation[1243]?.value ? translation[1243]?.value : "Schedule, track, and generate reports for appointments.",
      permission_value: "sales_root_access",
      pos_value: "pos_root_access"
    },
    {
      title: selectedLanguage?.name == "English" ? "Sale History" : translation[229]?.value ? translation[229]?.value : "Sale History",
      icon: <Svgs.Client_Programs />,
      link: "/dashboard/sales/sale-history",
      description: selectedLanguage?.name == "English" ? "Access and download sales history reports." : translation[1244]?.value ? translation[1244]?.value : "Access and download sales history reports.",
      permission_value: "sales_root_access",
      pos_value: "pos_root_access"
    },
    // {
    //   title: selectedLanguage?.name == "English" ? "Vouchers" : translation[196]?.value ? translation[196]?.value : "Vouchers",
    //   icon: <Svgs.Dashboard_Reports />,
    //   link: "/dashboard/sales/quick-sales?active_tab=vouchers",
    //   description: selectedLanguage?.name == "English" ? "Access client reports based on services, sales, subscription, and vouchers." : translation[231]?.value ? translation[231]?.value : "Access client reports based on services, sales, subscription, and vouchers.",
    //   permission_value: "sales_root_access",
    //   pos_value: "pos_root_access"
    // },
    // {
    //   title: selectedLanguage?.name == "English" ? "Membership" : translation[194]?.value ? translation[194]?.value : "Membership",
    //   icon: <Svgs.Client_Sharing />,
    //   link: "/dashboard/sales/quick-sales?active_tab=memberships",
    //   description: "",
    //   description: selectedLanguage?.name == "English" ? "Manage vouchers and add create membership." : translation[232]?.value ? translation[232]?.value : "Manage vouchers and add create membership.",
    //   permission_value: "sales_root_access",
    //   pos_value: "pos_root_access"
    // },
  ];

  useEffect(() => {
    let filtered_data = saleMenuArr.filter((itm) => {
      if (employee) {
        if (employeePermissions[itm.permission_value]?.includes("view")
          || employeePermissions[itm.permission_apply_offer_discount]?.includes("create")
          || employeePermissions[itm.permission_apply_offer_discount]?.includes("edit")
          || employeePermissions[itm.pos_value]?.includes("view")) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    setSalesCards(filtered_data);
  }, [employeePermissions]);


  return (
    <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
      <div className="flex items-center gap-5 lg:w-fit w-full lg:px-0 lg:pb-[2rem] lg:py-0 p-3 pb-8">

        <h2 className="font-semibold text-2xl">
          {selectedLanguage?.name == "English" ? "Sales" : translation[3]?.value ? translation[3]?.value : "Sales"}
        </h2>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem] xl:pr-[4rem] pr-0">
        {sales_cards.map(({ link, icon, title, description }) => (
          <AccountSetupCard
            link={link}
            icon={icon}
            title={title}
            description={description}
          />
        ))}
      </div>
    </div>
  );
};


export default DashboardSales;
