import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getVendorsImport,
  getVendorsList,
  getVendorsListWithNoPagination,
} from "../../../../../../Adapters/Api/vendor";
import Svgs from "../../../../../../Assets/svgs";
import CircleIcon from "../../../../Elements/CircleIcon";
import Dropwdown from "./../../../../Elements/Dropwdown";
import LoginBtn from "./../../../../Elements/LoginBtn";
import LoginInput from "./../../../../Elements/LoginInput";
import PhoneInput from "./../../../../Elements/PhoneInput";
import Popup from "./../../../../Elements/Popup";
import StatusBtn from "./../../../../Elements/StatusBtn";
import useVendors from "./useVendors";
import { useDispatch, useSelector } from "react-redux";
import { getVendorData } from "./../../../../../../Redux/TenantRedux/Actions/vendorActions/index";
import useSetDataInLocalStorage from "../../../../../../Hooks/useSetDataInLocalStorage";
import AddCategoryBtn from "../AddHeaderBtns/AddCategoryBtn";
import AddProductBtn from "../AddHeaderBtns/AddProductBtn";
import NotFound from "../../../../../Utility/NotFound";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import I_Info_Icon from "../../../../Elements/I_Info_Icon";
import PreloaderFull from "./../../../../Elements/PreloaderFull";
import IconButton from "Components/TenantApp/Elements/IconButton";
import Pagination from "new-components/Pagination";
import PreloaderSm from "Components/TenantApp/Elements/PreloaderSm";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import NoVendorFound from "Components/Utility/NoVendorFound";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";

let timeOutId = undefined
const Vendors = () => {
  const [ImportVendors, setImportVendors] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [del_popup, setDelPopup] = useState(false);
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const [ShowDetails, setShowDetails] = useState(0);
  const vendors_updated = useSelector(
    (state) => state?.vendors?.vendors_updated
  );

  const stateData = useSelector((state) => state);

  const translation = stateData?.languages?.language_translation
  const selectedLanguage = stateData?.languages?.selected_language


  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [exportLoader, setExportLoader] = useState(false);

  const isPermissionUpdated = stateData?.user?.isPermissionUpdated;
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])

  useEffect(() => {
    setEmployee(stateData?.user?.user?.employee);
    setEmployeePermissions(stateData?.user?.permissions);
  }, [stateData?.user]);

  const { AddVendor, vendorsData, vendorModalToggler, vendorHandleChange, checkVendorExistenceBeforeSubmit, errors, setIsEdit, isEdit,
    vendorDeleteHandler, vendorEditHandler, countriesList, stateList, onBlurHandler, loading, currentPage,
    setCurrentPage, pages, allCount, vendorsRedux, vendorsDataRedux, search_text, setSearchText, setLoader, loader, isToastVisible,
    setIsToastVisible, formRefVendor, setNoPaginationLoader, noPaginationLoader
  } = useVendors();

  const {
    vendor_name,
    address,
    mobile_number,
    email,
    country,
    state,
    city,
    gstin,
    website,
    is_active,
  } = vendorsData;

  // useEffect(() => {
  //   if (vendorsRedux?.length == 0) {
  //     dispatch(getVendorsList());
  //   }
  // }, []);
  useEffect(() => {
    if (search_text === null) {
      // Initial load with empty search_text
      dispatch(getVendorsList(currentPage, "", setLoader))
      dispatch(getVendorsListWithNoPagination("", setNoPaginationLoader))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(getVendorsList(currentPage, search_text, setLoader))
        dispatch(getVendorsListWithNoPagination(search_text, setNoPaginationLoader))
      }, 500);
    }
  }, [currentPage, search_text])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  const submit = (file) => {
    const businessData = getDataFromLocalStorage("business_data");
    let business = businessData.id;
    business = business.replaceAll('"', "");

    let form_data = new FormData();
    form_data.append("file", file);
    form_data.append("business", business);
    dispatch(
      getVendorsImport(form_data, () => {
        setCurrentPage(1)
        dispatch(getVendorsList(1, search_text, setLoader))
        dispatch(getVendorsListWithNoPagination(search_text, setNoPaginationLoader))
      })
    )
  };

  let headers = [
    { label: `${selectedLanguage?.name == "English" ? "Vendor Name" : translation[971]?.value ? translation[971]?.value : "Vendor Name"}`, key: "vendor_name" },
    { label: selectedLanguage?.name == "English" ? "Contact" : translation[583]?.value ? translation[583]?.value : "Contact", key: "mobile_number" },
    { label: `${selectedLanguage?.name == "English" ? "Email" : translation[476]?.value ? translation[476]?.value : "Email"}`, key: "email" },
    { label: `${selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}`, key: "address" },
    { label: `${selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}`, key: "is_active" },
    // { label: 'Country', key: 'country' },
    // { label: 'City', key: 'city' },
    // { label: 'State', key: 'state' },
    // { label: 'Postal Code', key: 'postal_code' },
    { label: selectedLanguage?.name == "English" ? "GST IN" : translation[977]?.value ? translation[977]?.value : "GST IN", key: "gstin" },
  ];

  const csvReport = {
    filename: "Vendor Directory.csv",
    headers: headers,
    data: vendorsDataRedux?.allVendors?.map((itm) => {
      let phone_number = itm?.mobile_number?.replaceAll("-", " ");

      return {
        ...itm,
        vendor_name: itm?.vendor_name ? itm?.vendor_name : "--------",
        mobile_number: phone_number
          ? phone_number.replaceAll("+", " ")
          : "--------",
        email: itm?.email ? itm?.email : "--------",
        address: itm?.address ? itm?.address : "--------",
        is_active: itm?.is_active
          ? selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
          : selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive",
        // country: itm?.country ? itm?.country : '--------',
        // city: itm?.city ? itm?.city : '--------',
        // state: itm?.state ? itm?.state : '--------',
        // postal_code: itm?.postal_code ? itm?.postal_code : '--------',
        gstin: itm?.gstin ? itm?.gstin : "--------",
      };
    }),
  };

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (vendorsDataRedux?.allVendors?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  const PageSize = 10
  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <p className="text-[#7B7B7B] text-sm">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/");
            }}
          >
            {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          </span>{" "}
          &gt;{" "}
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/inventory-operations");
            }}
          >
            {selectedLanguage?.name == "English" ? "Inventory Operations" : translation[568]?.value ? translation[568]?.value : "Inventory Operations"}
          </span>{" "}
          &gt; <span className="text-primary font-semibold">
            {selectedLanguage?.name == "English" ? "Vendors" : translation[867]?.value ? translation[867]?.value : "Vendors"}
          </span>
        </p>
        <div className="my-[1rem] flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2">

            <h2 className="text-primary font-bold lg:text-3xl text-xl">
              {selectedLanguage?.name == "English" ? "Vendors" : translation[867]?.value ? translation[867]?.value : "Vendors"}
            </h2>
          </div>
          {loadingPermission ?
            <Shimmer className={'w-32 lg:w-36'}>
              <div className="h-8 w-full  bg-gray-200 rounded-lg" />
            </Shimmer> :
            !employee ? (
              <div className="flex items-center sm:gap-4 gap-2">
                <LoginBtn
                  onClick={() => {
                    vendorModalToggler();
                    setIsEdit(false);
                  }}

                >
                  <div className="flex items-center gap-2 text-[#fff]">
                    <Svgs.Plus color="#fff" />
                    <p className="">
                      {selectedLanguage?.name == "English" ? "Add Vendor" : translation[970]?.value ? translation[970]?.value : "Add Vendor"}
                    </p>
                  </div>
                </LoginBtn>
                {/* <AddProductBtn />
              <AddCategoryBtn fill={true} /> */}
              </div>
            ) : ""
          }

        </div>

        <div className="border rounded-lg">
          <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row gap-3">
            <div className="flex items-center justify-between flex-1 w-full md:gap-0 gap-4 ">
              <div className="flex items-center sm:gap-4 gap-2 ">
                <h2 className="font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Vendors" : translation[867]?.value ? translation[867]?.value : "Vendors"}
                </h2>
                {(!vendors_updated || loader) ?
                  <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                    <p className="rounded-md h-5 w-12 mx-1"> </p>
                  </div>
                  :
                  <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                    <p className="text-primary">
                      {allCount ? allCount : "0"}{' '}
                      {allCount && allCount > 1 ?
                        selectedLanguage?.name == "English" ? "Vendors" : translation[867]?.value ? translation[867]?.value : "Vendors"
                        : selectedLanguage?.name == "English" ? "Vendor" : translation[962]?.value ? translation[962]?.value : "Vendor"
                      }
                    </p>
                  </div>
                }
              </div>
              <div className="mb-00">
                {loadingPermission ?
                  <Shimmer className={'w-44 lg:w-48'}>
                    <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                  </Shimmer> :
                  <LoginInput
                    title=""
                    placeholder={selectedLanguage?.name == "English" ? "Search Vendor" : translation[1277]?.value ? translation[1277]?.value : "Search Vendor"}
                    padding="py-1"
                    value={search_text}
                    leftIcon={<Svgs.Search />}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                }
              </div>
            </div>
            {loadingPermission ?
              <EmployeesHeadShimmer classOn1={'!w-12 !lg:16'} classOn2={'!w-12 !lg:16'} />
              : exportLoader ? (
                <div className="h-[4rem] flex items-center justify-center">
                  <PreloaderSm />
                </div>
              ) : !employee ? (
                vendorsDataRedux?.allVendors?.length > 0 ?
                  <div className="flex items-center lg:justify-center justify-end gap-3 w-fit">
                    <input
                      type="file"
                      accept=".csv"
                      id="csvFileforproduct"
                      onChange={(e) => {
                        submit(e.target.files[0]);
                        setImportVendors(false);
                      }}
                      hidden
                      value={""}
                    />
                    <LoginBtn
                      onClick={() => {
                        setImportVendors(true);
                      }}
                      borderBtn
                      animation={false}
                    >
                      <div className="flex items-center gap-2 text-[#101928]">
                        <Svgs.Import />
                        {/* <p>Import Vendors</p> */}
                      </div>
                    </LoginBtn>

                    <CSVLink
                      {...csvReport}
                      target="_blank"
                      onClick={handleExportClick}
                    >
                      <IconButton filled>
                        <Svgs.Export />
                      </IconButton>
                    </CSVLink>

                  </div> :
                  <LoginBtn
                    className="rounded-lg border-2 border-primary"
                    bg="bg-white"
                    animation={false}
                    onClick={handleExportClick}
                  >
                    <div className="flex items-center gap-2 text-[#FFFFFF]">
                      <Svgs.Export bg="#FFFFFF" />
                    </div>
                  </LoginBtn>
              ) : (
                ""
              )}

          </div>
          <>
            {(!vendors_updated || loader) ?
              <TableShimer cols={6} rows={11} />
              :
              <div className="overflow-x-auto">
                <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(170px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                  <p>{selectedLanguage?.name == "English" ? "Vendor" : translation[962]?.value ? translation[962]?.value : "Vendor"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Contact" : translation[583]?.value ? translation[583]?.value : "Contact"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Email" : translation[476]?.value ? translation[476]?.value : "Email"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
                  {!employee ? (
                    <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
                  ) : (
                    ""
                  )}

                </div>

                {/* previous filter method
                ?.filter((itm) => {
                      if (
                        itm?.vendor_name
                          ?.toLowerCase()
                          ?.includes(search_text.toLowerCase()) ||
                        itm?.address
                          ?.toLowerCase()
                          ?.includes(search_text.toLowerCase()) ||
                        itm?.email
                          ?.toLowerCase()
                          ?.startsWith(search_text.toLowerCase()) ||
                        itm?.mobile_number
                          ?.toLowerCase()
                          ?.startsWith(search_text.toLowerCase())
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }) */}

                {vendorsRedux &&
                  vendorsRedux?.length > 0 ?
                  vendorsRedux?.map((item) => {
                    return (
                      <>
                        <div
                          className={`odd-bg min-w-full w-fit text-[#3A3A3A] transition-all  ${ShowDetails == item.id
                            ? "border-l-[4px] border-[#5679FFB2]"
                            : "border-l-[0px]"
                            }`}
                        >
                          <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(170px,1fr))]">
                            <div
                              className={`before-left-arrow cursor-pointer ${ShowDetails == item.id
                                ? "before-left-arrow-open"
                                : "before-left-arrow-close"
                                }`}
                              onClick={() => {
                                setShowDetails(
                                  item.id == ShowDetails ? undefined : item.id
                                );
                              }}
                            >
                              <div className="h-full flex items-center">
                                <p className="text-sm">{item.vendor_name}</p>
                              </div>
                            </div>
                            <div className="flex items-center">
                              <p className="text-sm">
                                {item.mobile_number
                                  ? item.mobile_number
                                  : "-------"}
                              </p>
                            </div>
                            <div className="flex items-center">
                              <div className="word-break line-clamp-1">
                                <p className="text-sm">
                                  {item.email ? item.email : "-------"}
                                </p>
                              </div>
                            </div>
                            <div className="flex items-center">
                              <p className="text-sm line-clamp-1 pr-3">
                                {item?.address
                                  ? item?.address.slice(0, 20)
                                  : "-------"}
                                {item?.address &&
                                  item?.address.length > 20 &&
                                  "..."}
                              </p>
                            </div>
                            <div className="flex items-center">
                              <StatusBtn
                                profile_status={
                                  item?.is_active ?
                                    selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                                    :
                                    selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                                }
                                status={item.is_active ? "Active" : "InActive"}
                              />
                            </div>
                            {!employee ? (
                              <div className="flex items-center gap-2">
                                <div
                                  onClick={() => {
                                    vendorEditHandler(item.id);
                                  }}
                                  className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center cursor-pointer"
                                >
                                  <span>
                                    <Svgs.Pen />
                                  </span>
                                </div>
                                {vendorsRedux?.length != 1 &&
                                  <div
                                    onClick={() => {
                                      setDelPopup(item.id);
                                    }}
                                    className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center justify-center cursor-pointer"
                                  >
                                    <span>
                                      <Svgs.Delete />{" "}
                                    </span>
                                  </div>
                                }
                              </div>
                            ) : (
                              ""
                            )}

                          </div>
                          {ShowDetails == item.id ? (
                            <div className="scale-in-center">
                              <div className="bg-[#FAF9FF] px-6 py-3 min-w-full w-fit">
                                <div className="inline-grid xl:grid grid-cols-[repeat(4,minmax(150px,1fr))] gap-y-5 gap-x-3">
                                  <div className="flex flex-col gap-1">
                                    <p className="text-sm text-[#969BA0]">
                                      {selectedLanguage?.name == "English" ? "Vendor Name" : translation[971]?.value ? translation[971]?.value : "Vendor Name"}
                                    </p>
                                    <p className="text-black font-semibold">
                                      {item.vendor_name}
                                    </p>
                                  </div>
                                  <div className="flex flex-col gap-1">
                                    <p className="text-sm text-[#969BA0]">
                                      {selectedLanguage?.name == "English" ? "Contact" : translation[583]?.value ? translation[583]?.value : "Contact"}
                                    </p>
                                    <p className="text-black font-semibold">
                                      {item.mobile_number
                                        ? item.mobile_number
                                        : "-------"}
                                    </p>
                                  </div>
                                  <div className="flex flex-col gap-1">
                                    <p className="text-sm text-[#969BA0]">
                                      {selectedLanguage?.name == "English" ? "Email Address" : translation[60]?.value ? translation[60]?.value : "Email Address"}
                                    </p>
                                    <p className="text-black font-semibold word-break line-clamp-1">
                                      {item.email}
                                    </p>
                                  </div>
                                  <div className="flex items-end justify-between">
                                    <div className="flex flex-col gap-1">
                                      <p className="text-sm text-[#969BA0]">
                                        {selectedLanguage?.name == "English" ? "Tax Registration" : translation[972]?.value ? translation[872]?.value : "Tax Registration"}
                                      </p>
                                      <p className="text-black font-semibold">
                                        {item?.gstin ? item?.gstin : "-------"}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex flex-col gap-1">
                                    <p className="text-sm text-[#969BA0]">
                                      {selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
                                    </p>
                                    <p className="text-black font-semibold word-break line-clamp-1">
                                      {item?.address}
                                    </p>
                                  </div>
                                  <div className="flex flex-col gap-1">
                                    <p className="text-sm text-[#969BA0]">
                                      {selectedLanguage?.name == "English" ? "Website" : translation[917]?.value ? translation[917]?.value : "Website"}
                                    </p>
                                    <p className="text-black font-semibold">
                                      {item?.website
                                        ? item?.website
                                        : "-------"}
                                    </p>
                                  </div>
                                  {/* <div className="flex items-start justify-between">
                                    <div className="flex flex-col gap-1">
                                      <p className="text-sm text-[#969BA0]">
                                        {selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
                                      </p>
                                      <StatusBtn
                                        status={`${item?.is_active
                                          ? "Active"
                                          : "InActive"
                                          }`}
                                        profile_status={
                                          item?.is_active ?
                                            selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                                            :
                                            selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                                        }
                                        size="sm"
                                      />
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    );
                  }) : (
                    <>
                      <NoVendorFound />
                    </>
                  )}
              </div>
            }
            {(vendorsRedux?.length > 0 && !loader) &&
              <Pagination
                currentPage={currentPage}
                totalCount={Math.ceil(pages)}
                pageSize={PageSize}
                onPageChange={page => setCurrentPage(page)}
              />
            }
          </>
        </div>
      </div>

      {AddVendor && (
        <Popup
          heading={isEdit
            ? selectedLanguage?.name == "English" ? "Update Vendor" : translation[970]?.value ? translation[970]?.value : "Update Vendor"
            : selectedLanguage?.name == "English" ? "Add Vendor" : translation[970]?.value ? translation[970]?.value : "Add Vendor"
          }
          close={() => vendorModalToggler()}
          open={AddVendor}
        >
          <div className="flex flex-col gap-6" ref={formRefVendor}>
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
              <CircleIcon>
                <Svgs.Vendor_details />
              </CircleIcon>
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {selectedLanguage?.name == "English" ? "Vendor Details" : translation[978]?.value ? translation[978]?.value : "Vendor Details"}
                </h2>
                <p className="text-[#7B7B7B] text-sm">
                  {selectedLanguage?.name == "English" ? "Adding vendor information with ordered product stock" : translation[1324]?.value ? translation[1324]?.value : "Adding vendor information with ordered product stock"}
                </p>
              </div>
            </div>
            <LoginInput
              required={true}
              title={selectedLanguage?.name == "English" ? "Vendor Name" : translation[971]?.value ? translation[971]?.value : "Vendor Name"}
              placeholder={selectedLanguage?.name == "English" ? "Enter vendor name" : translation[980]?.value ? translation[980]?.value : "Enter vendor name"}
              name={"vendor_name"}
              // charactersMaxLength={25}
              value={vendor_name}
              onChange={(e) => vendorHandleChange(e)}
              onBlur={() => onBlurHandler({ vendor_name: vendor_name })}
              error={errors?.vendor_name}
            />

            <PhoneInput
              required={false}
              name="mobile_number"
              title={selectedLanguage?.name == "English" ? "Contact Number" : translation[981]?.value ? translation[981]?.value : "Contact Number"}
              placeholder={selectedLanguage?.name == "English" ? "Enter your contact number" : translation[982]?.value ? translation[982]?.value : "Enter your contact number"}
              value={mobile_number}
              onChange={(e) => vendorHandleChange(e)}
              // onBlur={() => onBlurHandler({ mobile_number: mobile_number })}
              error={errors?.mobile_number}
              edit={isEdit ? true : false}
            />

            <LoginInput
              required={false}
              title={selectedLanguage?.name == "English" ? "Email Address" : translation[60]?.value ? translation[60]?.value : "Email Address"}
              placeholder={selectedLanguage?.name == "English" ? "Enter Your email" : translation[510]?.value ? translation[510]?.value : "Enter Your email"}
              name={"email"}
              value={email}
              onChange={(e) => vendorHandleChange(e)}
              onBlur={() => onBlurHandler({ email: email })}
              error={errors?.email}
            />

            <Dropwdown
              required={false}
              title={selectedLanguage?.name == "English" ? "Country" : translation[52]?.value ? translation[52]?.value : "Country"}
              placeholder={selectedLanguage?.name == "English" ? "Select country" : translation[53]?.value ? translation[53]?.value : "Select country"}
              name={"country"}
              value={country}
              onChange={(e) => vendorHandleChange(e, "country")}
              // onBlur={() => onBlurHandler({ country: country })}
              // error={errors?.country}
              options={countriesList}
              edit={isEdit ? true : false}
            />
            <div className="grid md:grid-cols-2 gap-5">
              <Dropwdown
                title={selectedLanguage?.name == "English" ? "State" : translation[481]?.value ? translation[481]?.value : "State"}
                placeholder={selectedLanguage?.name == "English" ? "Select State" : translation[55]?.value ? translation[55]?.value : "Select State"}
                required={false}
                name={"state"}
                value={state}
                onChange={(e) => vendorHandleChange(e, "state")}
                // onBlur={() => onBlurHandler({ state: state })}
                // error={errors?.state}
                options={stateList}
                edit={isEdit ? true : false}
              />

              {/* <Dropwdown
                title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                placeholder={selectedLanguage?.name == "English" ? "Select City" : translation[57]?.value ? translation[57]?.value : "Select City"}
                required={false}
                name={"city"}
                value={city}
                onChange={(e) => vendorHandleChange(e, "city")}
                // onBlur={() => onBlurHandler({ city: city })}
                // error={errors?.city}
                options={cityList}
                edit={isEdit ? true : false}
              /> */}
              <LoginInput
                required={false}
                name={"city"}
                value={city}
                disabled={!state || !country}
                onChange={(e) => vendorHandleChange(e, "city")}
                title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                placeholder={selectedLanguage?.name == "English" ? "Enter city name" : translation[57]?.value ? translation[57]?.value : "Enter city name"}
              />
            </div>
            <LoginInput
              required={true}
              title={selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
              placeholder={selectedLanguage?.name == "English" ? "Enter address" : translation[482]?.value ? translation[482]?.value : "Enter address"}
              name={"address"}
              value={address}
              onChange={(e) => vendorHandleChange(e)}
              onBlur={() => onBlurHandler({ address: address })}
              error={errors?.address}
            />

            <div className="grid md:grid-cols-2 gap-5">
              <LoginInput
                required={false}
                title={
                  <I_Info_Icon
                    text={selectedLanguage?.name == "English" ? "GSTIN" : translation[977]?.value ? translation[977]?.value : "GSTIN"}
                    info={selectedLanguage?.name == "English" ? "GSTIN is a unique identification number assigned to a business registered."
                      : translation[986]?.value ? translation[986]?.value : "GSTIN is a unique identification number assigned to a business registered."
                    }
                  />
                }
                placeholder={selectedLanguage?.name == "English" ? "Enter tax registration no." : translation[987]?.value ? translation[987]?.value : "Enter tax registration no."}
                name={"gstin"}
                value={gstin}
                onChange={(e) => vendorHandleChange(e)}
              // onBlur={() => onBlurHandler({ gstin: gstin })}
              // error={errors?.gstin}

              />
              <LoginInput
                required={false}
                title={selectedLanguage?.name == "English" ? "Website" : translation[917]?.value ? translation[917]?.value : "Website"}
                placeholder={selectedLanguage?.name == "English" ? "Enter website link" : translation[681]?.value ? translation[681]?.value : "Enter website link"}
                name={"website"}
                value={website}
                onChange={(e) => vendorHandleChange(e)}
                // onBlur={() => onBlurHandler({ website: website })}
                error={errors?.website}
              />
            </div>
            <Dropwdown
              required={true}
              name={"is_active"}
              value={is_active}
              onChange={(e) => vendorHandleChange(e, "is_active")}
              // onBlur={() => onBlurHandler({ is_active: is_active })}
              error={errors?.is_active}
              title={selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
              placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[521]?.value : "Select Status"}
              options={[
                {
                  value: true,
                  label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                },
                {
                  value: false,
                  label: selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                },
              ]}
            />

            <div className="flex items-center justify-start mt-[2rem]">
              <LoginBtn
                loading={loading}
                disabled={loading}
                // disabled={loading
                //   || !vendor_name
                //   || !email}
                text={isEdit ?
                  selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"
                  : selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                onClick={() => checkVendorExistenceBeforeSubmit()}
              />
            </div>
          </div>
        </Popup>
      )
      }

      {
        del_popup && (
          <Popup
            heading={selectedLanguage?.name == "English" ? "Delete Vendor?" : translation[973]?.value ? translation[973]?.value : "Delete Vendor?"}
            onclose={() => {
              setDelPopup(false);
            }}
          >
            <p>
              {selectedLanguage?.name == "English"
                ? "Are you sure you want to delete this Vendor? You won't be able to see this again."
                : translation[974]?.value
                  ? translation[974]?.value
                  : "Are you sure you want to delete this Vendor? You won't be able to see this again."
              }
            </p>
            <div className="flex justify-end mt-4">
              <LoginBtn
                deleteBtn
                text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                onClick={async () => {
                  await vendorDeleteHandler(del_popup);
                  setDelPopup(false);
                }}
              />
            </div>
          </Popup>
        )
      }

      <Popup
        heading={selectedLanguage?.name == "English" ? "Import Vendors" : translation[975]?.value ? translation[975]?.value : "Import Vendors"}
        close={setImportVendors}
        open={ImportVendors}
      >
        <div className="flex flex-col gap-4 border-[#A1A1A1] border-dashed border-2 p-4 rounded-lg text-center">
          <div className="h-[15rem]">
            <Svgs.UploadList />
          </div>
          <p className="text-[#595959]">
            {selectedLanguage?.name == "English" ? "Drop your .CSV file to add Vendors" : translation[976]?.value ? translation[976]?.value : "Drop your .CSV file to add Vendors"}
          </p>
          <p className="text-[#595959]">
            {selectedLanguage?.name == "English" ? "Or" : translation[885]?.value ? translation[885]?.value : "Or"}
          </p>
          <LoginBtn size="sm" className="mx-auto">
            <label htmlFor="csvFileforproduct" className="cursor-pointer">
              <div className="flex items-center gap-2 text-[#ffffff]">
                {selectedLanguage?.name == "English" ? "Upload New List" : translation[886]?.value ? translation[886]?.value : "Upload New List"}
              </div>
            </label>
          </LoginBtn>
        </div>
      </Popup>
    </>
  );
};

export default Vendors;
