

export const SET_APPOINTMENT_INPUT_DATA = 'SET_APPOINTMENT_INPUT_DATA'
export const RESET_APPOINTMENT_DATA = 'RESET_APPOINTMENT_DATA'
export const GET_MY_APPOINTMENTS = 'GET_MY_APPOINTMENTS'
export const GET_BAR_CHART_DATA = 'GET_BAR_CHART_DATA'
export const GET_EMPLOYEE_DAILY_INSIGHT_DATA = 'GET_EMPLOYEE_DAILY_INSIGHT_DATA'
export const GET_ALL_APPOINTMENTS = 'GET_ALL_APPOINTMENTS'
export const GET_ALL_REVERSAL = 'GET_ALL_REVERSAL'
export const GET_APPOINTMENT_DETAIL = 'GET_APPOINTMENT_DETAIL'
export const GET_ALL_APPOINTMENTS_NO_PAGINATION = 'GET_ALL_APPOINTMENTS_NO_PAGINATION'
export const GET_CLIENT_SALE = 'GET_CLIENT_SALE'
export const GET_TODAY_APPOINTMENTS = 'GET_TODAY_APPOINTMENTS'

export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT'
// export const UPDA = 'CREATE_APPOINTMENT'
 // GET_COMMENTS //
export const GET_COMMENTS = 'GET_COMMENTS'