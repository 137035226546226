// import { get_all_segments } from "Adapters/Api/segments"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

const useLeads = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const [loading, setLoading] = useState(true)
    const [ViewDetails, setViewDetails] = useState(false);
    const [FilterPopup, setFilterPopup] = useState(false);
    const segment = state?.segments?.segments;


    const getSegments = () => {
        const success = () => { }
        const fail = () => { }

        // dispatch(get_all_segments(success, fail))
    }

    useEffect(() => {
        getSegments()
    }, [])


    // Dummy Data For Lead_Generation
const Lead_GenerationData = [
    {
      customer_name: "Alfreda",
      img: "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png",
      email: "alfreda20@dicki.com",
      phone: "52538134",
      is_active: true,
    },
    {
      customer_name: "Alfreda",
      img: "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png",
      email: "alfreda20@dicki.com",
      phone: "52538134",
      is_active: false,
    },
    {
      customer_name: "Alfreda",
      img: "https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png",
      email: "alfreda20@dicki.com",
      phone: "52538134",
      is_active: true,
    },
   
  ];


    return {
        loading,
        Lead_GenerationData,
        ViewDetails, setViewDetails,
        FilterPopup, setFilterPopup,
    }
}

export default useLeads