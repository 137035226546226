import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkEmployeeEmail,
  getSingleEmployee,
  getUniqueEmployeeId,
} from "../../../../../Adapters/Api/employee";
import { getserviceDropdownList } from "../../../../../Adapters/Api/services";
import { getStaffGroup } from "../../../../../Adapters/Api/staffGroup";
import Svgs from "../../../../../Assets/svgs";
import { DESIGNATIONS } from "../../../../../Constants/Data/Designation";
import { WEEKDAYS } from "../../../../../Constants/Data/Months";
import { STAFF_LEVELS } from "../../../../../Constants/Data/StaffLevel";
import useSetDataInLocalStorage from "../../../../../Hooks/useSetDataInLocalStorage";
import CheckBox from "../../../Elements/Checkbox/CheckBox";
import CircleIcon from "../../../Elements/CircleIcon";
import DateInput from "../../../Elements/DateInput";
import Dropwdown from "../../../Elements/Dropwdown";
import FormSteps from "../../../Elements/FormSteps";
import I_Info_Icon from "../../../Elements/I_Info_Icon";
import LoginBtn from "../../../Elements/LoginBtn";
import LoginInput from "../../../Elements/LoginInput";
import PhoneInput from "../../../Elements/PhoneInput";
import Popup from "../../../Elements/Popup";
import PermissionsComponent from "../Elements/PermissionsComponent";
import AddEmployeeServiceCard from "./EmployeeServiceCard";
import useEmployeeForms from "./useEmployeeForms";
import { getLocations } from "./../../../../../Adapters/Api/index";
import { apiErrorMessage } from "Constants/Data/Errors";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import moment from "moment";
import AddStaffGroupPopup from "../StaffGroups/AddStaffGroupPopup";
import useStaffGroups from "../StaffGroups/useStaffGroups";
import FormShimmerFullScreen from "Components/Utility/Shimmers/FormShimmerFullScreen";
import FloatInput from "Components/TenantApp/Elements/FloatInput";
// import PreloaderFull from "Components/TenantApp/Elements/PreloaderFull";
// import { OPENING_TIME } from "../../../../../Constants/Data/OpeningHours";
// import { EMPLOYEE_ALL_PERMISSIONS } from "../../../../../Constants/Data/PermissionValues";
// import SeeMorePopup from "../../../Elements/SeeMorePopup";
// import ToggleWithText from "../../../Elements/ToggleWithText";
// import PermissionParent from "../Elements/PermissionParent";
// import PermissionRow from "../Elements/PermissionRow";

const AddEmployee = () => {
  const {
    staffModal,
    staffGroupModalToggler,
    setStaffModal
  } = useStaffGroups();

  const { employeeData, setEmployeeData, employeeHandleChange, employeePermissionHandleChange, onEmployeeSubmit, errors,
    onBlurHandler, step, setStep, Finished, countriesList, access_token, setErrors, stateList, image, setIsEdit, isEdit,
    setCountryId, loading, formRef, firstStepSubmitValidation, employeeHandleLeaveChange, leaveMonthsOptions
    // selectSevices,
    // setFinished,
    // removedItem,
    // cityList,
    // setStateId,
  } = useEmployeeForms();

  const [multi_service_data, setServiceData] = useState({});

  const dispatch = useDispatch();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const businessData = getDataFromLocalStorage("business_data");
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageLoader, setPageLoader] = useState(true)
  const [editEmpPrevState, setEditEmpPrevState] = useState({})
  const emp_id = searchParams?.get("employee_id");
  const mState = useSelector((state) => state);
  const servicesList = mState.service.services;
  const staffGroupRedux = mState?.staffGroup?.staffGroups;
  const reduxlocationsList = mState?.locations?.locations;
  const translation = mState?.languages?.language_translation
  const selectedLanguage = mState?.languages?.selected_language

  const navigate = useNavigate();

  const {
    full_name,
    employee_id,
    email,
    mobile_number,
    image: u_img,
    dob,
    gender,
    country,
    state,
    city,
    postal_code,
    address,
    joining_date,
    ending_date,
    location,
    is_active,
    to_present,
    designation,
    income_type,
    salary,
    services,
    working_days,
    staff_group,
    leave_data,
    // maximum_discount,
    // level,
    // start_time,
    // end_time,
    // allow_calendar_booking,
    // access_calendar,
    // change_calendar_color,
    // access_reports,
    // access_sales,
    // access_inventory,
    // access_products,
    // access_expenses,
    // access_voucher,
    // access_member_discount,
    // access_invite_friend,
    // access_loyalty_points,
    // access_gift_cards,
  } = employeeData;
  //this methods use in edit employee case show data in forms fields
  const setsFormsData = (emp_id) => {
    setIsEdit(true);
    getSingleEmployee(emp_id)
      .then((result) => {
        const employee = result?.data.response?.Employee;
        const {
          full_name,
          employee_id,
          email,
          mobile_number,
          image,
          dob,
          gender,
          country,
          state,
          city,
          postal_code,
          address,
          joining_date,
          staff_group,
          ending_date,
          is_active,
          to_present,
          location,
          employee_info,
          designation,
          income_type,
          salary,
          working_days,
          services,
          start_time,
          level,
          end_time,
          access_voucher,
          access_member_discount,
          access_invite_friend,
          access_loyalty_points,
          access_gift_cards,
          access_expenses,
          access_inventory,
          access_products,
          access_reports,
          access_sales,
          allow_calendar_booking,
          access_calendar,
          change_calendar_color,
          leave_data
        } = employee;
        setPageLoader(false)
        setCountryId(country?.unique_id);
        // setStateId(state?.unique_id);
        let service_list = services?.map((item) => item);
        const locationList = location?.map((item) => item?.id);

        setEditEmpPrevState(employee)
        setEmployeeData({
          ...employee,
          full_name: full_name,
          employee_id: employee_id,
          email: email,
          mobile_number: mobile_number ? mobile_number : "",
          image: image,
          dob: dob,
          gender: gender,
          country: country?.unique_id,
          state: state?.unique_id,
          city: city?.name,
          postal_code: postal_code,
          address: address,
          joining_date: joining_date,
          to_present: to_present,
          ending_date: ending_date,
          is_active: is_active,
          location: locationList?.length > 0 ? locationList : [],
          //step 2
          designation: employee_info?.designation,
          income_type: employee_info?.income_type,
          salary: employee_info?.salary,
          start_time: employee_info?.start_time,
          end_time: employee_info?.end_time,
          working_days: working_days ? working_days : [],
          level: level,
          services: service_list,
          staff_group: staff_group,
          // maximum_discount: employee_info?.maximum_discount,
          leave_data: {
            operational_casual_leave: leave_data?.operational_casual_leave,
            operational_annual_leave: leave_data?.operational_annual_leave,
            operational_medical_leave: leave_data?.operational_medical_leave,

            casual_leave: leave_data?.casual_leave ? leave_data?.casual_leave : '0',
            annual_leave: leave_data?.annual_leave ? leave_data?.annual_leave : '0',
            medical_leave: leave_data?.medical_leave ? leave_data?.medical_leave : '0',

            number_of_months: leave_data?.number_of_months
          },
          //step 3
          allow_calendar_booking: allow_calendar_booking,
          access_calendar: access_calendar,
          change_calendar_color: change_calendar_color,
          access_reports: access_reports,
          access_sales: access_sales,
          access_inventory: access_inventory,
          access_products: access_products,
          access_expenses: access_expenses,
          access_voucher: access_voucher,
          access_member_discount: access_member_discount,
          access_invite_friend: access_invite_friend,
          access_loyalty_points: access_loyalty_points,
          access_gift_cards: access_gift_cards,
        });
      })
      .catch((err) => {
        setPageLoader(false)
        toast.error("invalid employee", { toastId: "toast" });
      });
  };

  const fetchUnqiueEmployeeId = () => {
    //get unique employee id
    getUniqueEmployeeId(access_token)
      .then((result) => {
        const res = result?.response?.id;
        let idToString = res.toString();
        let idSrigToArray = idToString.split("-");

        let shortDomian = idSrigToArray[0]
          .split("")
          .filter((i) => {
            if (i != "," && i != "'" && i != "[" && i != "]" && i != " ") {
              return i;
            }
          })
          .join("")
          .toUpperCase();

        idSrigToArray.splice(0, 1, shortDomian);

        setEmployeeData((prev) => ({
          ...prev,
          employee_id: idSrigToArray.join("-"),
        }));
      })
      .catch((err) => {
        toast.error("Error in get employee id", { toastId: "toast" });
      });
  };

  //get business location and stored it in redux
  const getBusinessLocation = (business_id) => {
    dispatch(getLocations(business_id));
  };


  useEffect(() => {
    // if (mState.service.services.length == 0) {
    dispatch(getserviceDropdownList());
    // }

    if (!isEdit) {
      fetchUnqiueEmployeeId();
    }
    if (staffGroupRedux?.length === 0) {
      dispatch(getStaffGroup());
    }
    if (reduxlocationsList?.length === 0) {
      getBusinessLocation(businessData?.id);
    }
  }, []);


  useEffect(() => {
    if (emp_id) {
      setsFormsData(emp_id);
    } else {
      setPageLoader(false)
    }
  }, [emp_id]);

  //check employee email it is exist or not 
  const checkEmployeeEmailAddressAndMobileNumber = (data, success_callback, fail_callback) => {
    let payloadData = {}
    const phoneNumberWithoutCode = data?.mobile_number?.split('-')[1]
    if (data?.mobile_number && phoneNumberWithoutCode && phoneNumberWithoutCode?.trim() !== '') {
      payloadData = data
    } else {
      payloadData = { email: data?.email }
    }
    if (emp_id && isEdit && emp_id != "") {
      payloadData = { ...payloadData, id: emp_id, previous_email: editEmpPrevState?.email, previous_mobile_number: editEmpPrevState?.mobile_number }
    }
    dispatch(checkEmployeeEmail(
      payloadData,
      (response) => {
        if (response?.employee) {
          const errorMessage = response.message ? response.message : response?.message_mobile_number
          toast.error(errorMessage, { toastId: "toast" })
          if (response.message) {
            setErrors((prev) => ({ ...prev, email: response?.message }))
          } else {
            setErrors((prev) => ({ ...prev, mobile_number: response?.message_mobile_number }))
          }
          formRef.current?.scrollIntoView({ behavior: "smooth" });
        }
        else {
          success_callback && success_callback()
        }
      },
      () => {
        setErrors((prev) => ({ ...prev, email: "" }))
        toast.error(apiErrorMessage, { toastId: "toast" })
        formRef.current?.scrollIntoView({ behavior: "smooth" });
        fail_callback && fail_callback()
      }
    ));

  }

  const minDateLimit = useMemo(() => {
    return moment().format('YYYY-MM-DD');
  }, []);

  return (
    <>
      <div className="lg:px-[3rem] lg:py-[2.5rem] py-[1.3rem] px-[2.5rem] h-full bounce-in-right overflow-auto scroll-hidden-lg">
        <div className="flex flex-col gap-4">

          <Breadcrumb
            first={selectedLanguage?.name == "English" ? "Employee Management" : translation[375]?.value ? translation[375]?.value : "Employee Management"}
            second={selectedLanguage?.name == "English" ? "Employees" : translation[399]?.value ? translation[399]?.value : "Employees"}
            third={selectedLanguage?.name == "English" ? "Add Employee" : translation[465]?.value ? translation[465]?.value : "Add Employee"}
            url={'/dashboard/employee/'}
            secondurl={'/dashboard/employee/employees/'}
          />
          {pageLoader ?
            <FormShimmerFullScreen />
            :
            <>
              <div className="my-[2rem]">
                <FormSteps
                  currentStep={step}
                  setStep={setStep}
                  active={step}
                  scrollNone={true}
                  steps={[
                    selectedLanguage?.name == "English" ? "Personal Information" : translation[466]?.value ? translation[466]?.value : "Personal Information",
                    selectedLanguage?.name == "English" ? "Professional Information" : translation[515]?.value ? translation[515]?.value : "Professional Information",
                    selectedLanguage?.name == "English" ? "Permissions" : translation[467]?.value ? translation[467]?.value : "Permissions"
                  ]}
                  className={'flex-1'}
                  stepsLength="3"
                />
              </div>
              {step == 1 && (
                <>
                  <div ref={formRef}>
                    <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
                      <CircleIcon>
                        <Svgs.Profile />
                      </CircleIcon>
                      <div className="flex flex-col gap-1 lg:text-left text-center">
                        <h2 className="text-primary font-semibold text-xl">
                          {selectedLanguage?.name == "English" ? "Personal Information" : translation[466]?.value ? translation[466]?.value : "Personal Information"}
                        </h2>
                        <p className="text-[#7B7B7B] text-sm">
                          {selectedLanguage?.name == "English" ? "Add employee personal information to get" : translation[468]?.value ? translation[468]?.value : "Add employee personal information to get"}
                          {" "}
                          <br className="hidden md:block" />
                          {selectedLanguage?.name == "English" ? "started." : translation[469]?.value ? translation[469]?.value : "started."}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-start lg:flex-row flex-col gap-5">
                      <div className="relative w-fit">
                        <div className="h-[11rem] w-[10rem] bg-[#E4EAF0] rounded-lg flex items-center justify-center overflow-hidden">
                          {isEdit && typeof u_img == "string" ? (
                            <img src={u_img} />
                          ) : image ? (
                            <img src={image} />
                          ) : (
                            <>
                              <p className="text-center text-primary text-sm font-semibold">
                                {selectedLanguage?.name == "English" ? "Upload Profile" : translation[470]?.value ? translation[470]?.value : "Upload Profile"}
                                <br className="hidden md:block" />{" "}
                                {selectedLanguage?.name == "English" ? "Picture" : translation[471]?.value ? translation[471]?.value : "Picture"}
                              </p>
                            </>
                          )}
                        </div>
                        <label htmlFor="upload_img">
                          <div
                            className={`cursor-pointer absolute h-[3.125rem] w-[3.125rem] rounded-full border top-full left-1/2 -translate-x-1/2 -translate-y-1/2 bg-primary flex items-center justify-center`}
                          >
                            {/* <div className={`cursor-pointer absolute h-[3.125rem] w-[3.125rem] rounded-full border top-full left-1/2 -translate-x-1/2 -translate-y-1/2 ${errors?.image ? "bg-[#eb3b3b]" : "bg-primary "} flex items-center justify-center`}> */}
                            <Svgs.Camera fill={`#fff`} />
                          </div>
                        </label>
                        <input
                          type="file"
                          name="image"
                          onChange={(e) => employeeHandleChange(e, "image")}
                          id="upload_img"
                          hidden
                          accept=".png,.PNG,.JPG,.jpg,jpeg,.JPEG"
                        />
                      </div>
                      <div className="w-full flex flex-col gap-5">
                        <LoginInput
                          required={true}
                          title={selectedLanguage?.name == "English" ? "Full Name" : translation[472]?.value ? translation[472]?.value : "Full Name"}
                          placeholder={selectedLanguage?.name == "English" ? "Enter Full Name" : translation[473]?.value ? translation[473]?.value : "Enter Full Name"}
                          name={"full_name"}
                          value={full_name}
                          specialCharacterNotAllowed={true}
                          onChange={(e) => employeeHandleChange(e)}
                          onEnterSubmit={onEmployeeSubmit}
                          onBlur={() => onBlurHandler({ full_name: full_name })}
                          error={errors.full_name}
                        />

                        <LoginInput
                          required={true}
                          title={selectedLanguage?.name == "English" ? "Email Address" : translation[60]?.value ? translation[60]?.value : "Email Address"}
                          placeholder={selectedLanguage?.name == "English" ? "Enter Your Email" : translation[510]?.value ? translation[510]?.value : "Enter Your Email"}
                          name={"email"}
                          // disabled={isEdit ? true : false}
                          value={email}
                          error={errors.email}
                          onChange={(e) => employeeHandleChange(e)}
                          onEnterSubmit={onEmployeeSubmit}
                        // onBlur={() => { onBlurHandler({ email: email }) }}
                        />
                      </div>
                      <div className="w-full flex flex-col gap-5">
                        <LoginInput
                          required={true}
                          title={
                            <I_Info_Icon
                              text={selectedLanguage?.name == "English" ? "Employee Id" : translation[448]?.value ? translation[448]?.value : "Employee Id"}
                              info={
                                selectedLanguage?.name == "English" ?
                                  "Employee ID can be the employee’s attendance ID or contact HR department for employee ID"
                                  : translation[475]?.value
                                    ? translation[475]?.value
                                    : "Employee ID can be the employee’s attendance ID or contact HR department for employee ID"
                              }
                            />
                          }
                          name={"employee_id"}
                          placeholder={selectedLanguage?.name == "English" ? "Enter Your Name" : translation[509]?.value ? translation[509]?.value : "Enter Your Name"}
                          value={employee_id}
                          onEnterSubmit={onEmployeeSubmit}
                          onChange={(e) => employeeHandleChange(e)}
                          onBlur={() => onBlurHandler({ employee_id: employee_id })}
                          error={errors.employee_id}
                          disabled={employee_id ? true : false}
                        />

                        <PhoneInput
                          required={false}
                          name="mobile_number"
                          title={selectedLanguage?.name == "English" ? "Phone Number" : translation[62]?.value ? translation[62]?.value : "Phone Number"}
                          placeholder={selectedLanguage?.name == "English" ? "Enter your phone number" : translation[514]?.value ? translation[514]?.value : "Enter your phone number"}
                          value={mobile_number}
                          onChange={(e) => employeeHandleChange(e)}
                          onEnterSubmit={onEmployeeSubmit}
                          edit={emp_id ? true : false}
                          // onBlur={() => onBlurHandler({ mobile_number: mobile_number })}
                          error={errors?.mobile_number}
                        />
                      </div>
                    </div>
                    <div className="mt-[2rem] grid lg:grid-cols-2 gap-5">
                      <DateInput
                        required={false}
                        title={selectedLanguage?.name == "English" ? "Date of Birth" : translation[477]?.value ? translation[477]?.value : "Date of Birth"}
                        name={"dob"}
                        value={dob}
                        max={minDateLimit}
                        custom_padding={" "}
                        onChange={(e) => employeeHandleChange(e)}
                        // onBlur={() => onBlurHandler({ dob: dob })}
                        error={errors.dob}
                      />
                      {/* <LoginInput
                  required={false}
                  title="Date of Birth"
                  name={"dob"}
                  value={dob}
                  onChange={(e) => employeeHandleChange(e)}
                  min={minDateLimit}
                  // onBlur={() => onBlurHandler({ dob: dob })}
                  error={errors.dob}
                /> */}
                      <Dropwdown
                        required={true}
                        title={selectedLanguage?.name == "English" ? "Gender" : translation[479]?.value ? translation[479]?.value : "Gender"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Gender" : translation[520]?.value ? translation[520]?.value : "Select Gender"}

                        name={"gender"}
                        value={gender}
                        onChange={(e) => employeeHandleChange(e)}
                        onBlur={() => onBlurHandler({ gender: gender })}
                        error={errors.gender}
                        options={[
                          {
                            value: "Male",
                            label: selectedLanguage?.name == "English" ? "Male" : translation[86]?.value ? translation[86]?.value : "Male"
                          },
                          {
                            value: "Female",
                            label: selectedLanguage?.name == "English" ? "Female" : translation[87]?.value ? translation[87]?.value : "Female"
                          },
                        ]}
                      />

                      <Dropwdown
                        required={true}
                        title={selectedLanguage?.name == "English" ? "Country" : translation[52]?.value ? translation[52]?.value : "Country"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Country" : translation[53]?.value ? translation[53]?.value : "Select Country"}
                        name={"country"}
                        value={country}
                        onChange={(e) => employeeHandleChange(e)}
                        onBlur={() => onBlurHandler({ country: country })}
                        error={errors.country}
                        options={countriesList}
                        edit={isEdit ? true : false}
                      />

                      <Dropwdown
                        required={false}
                        title={selectedLanguage?.name == "English" ? "State" : translation[481]?.value ? translation[481]?.value : "State"}
                        placeholder={selectedLanguage?.name == "English" ? "Select State" : translation[55]?.value ? translation[55]?.value : "Select State"}
                        name={"state"}
                        value={state}
                        onChange={(e) => employeeHandleChange(e)}
                        // onBlur={() => onBlurHandler({ state: state })}
                        // error={errors.state}
                        options={stateList}
                        edit={isEdit ? true : false}
                      />

                      {/* <Dropwdown
                  required={false}
                  title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                  placeholder={selectedLanguage?.name == "English" ? "Select City" : translation[57]?.value ? translation[57]?.value : "Select City"}
                  name={"city"}
                  value={city}
                  onChange={(e) => employeeHandleChange(e)}
                  // onBlur={() => onBlurHandler({ city: city })}
                  // error={errors.city}
                  options={cityList}
                  edit={isEdit ? true : false}
                /> */}
                      <LoginInput
                        required={false}
                        onEnterSubmit={onEmployeeSubmit}
                        name={"city"}
                        value={city}
                        disabled={!state || !country}
                        onChange={(e) => employeeHandleChange(e)}
                        // onBlur={() => onBlurHandler({ postal_code: postal_code })}
                        // error={errors.postal_code}
                        title={selectedLanguage?.name == "English" ? "City" : translation[56]?.value ? translation[56]?.value : "City"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter City Name" : translation[57]?.value ? translation[57]?.value : "Enter City Name"}
                      />

                      <LoginInput
                        required={false}
                        type={"number"}
                        onEnterSubmit={onEmployeeSubmit}
                        name={"postal_code"}
                        value={postal_code}
                        onChange={(e) => employeeHandleChange(e)}
                        // onBlur={() => onBlurHandler({ postal_code: postal_code })}
                        // error={errors.postal_code}
                        title={selectedLanguage?.name == "English" ? "Postal Code" : translation[58]?.value ? translation[58]?.value : "Postal Code"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Postal Code" : translation[59]?.value ? translation[59]?.value : "Enter Postal Code"}
                      />

                      <LoginInput
                        required={true}
                        title={selectedLanguage?.name == "English" ? "Address" : translation[49]?.value ? translation[49]?.value : "Address"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Address" : translation[482]?.value ? translation[482]?.value : "Enter Address"}
                        name={"address"}
                        onEnterSubmit={onEmployeeSubmit}
                        value={address}
                        onChange={(e) => employeeHandleChange(e)}
                        // onBlur={() => onBlurHandler({ address: address })}
                        error={errors.address}
                      />
                      <Dropwdown
                        required={true}
                        title={selectedLanguage?.name == "English" ? "Profile Status" : translation[521]?.value ? translation[521]?.value : "Profile Status"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Status" : translation[483]?.value ? translation[483]?.value : "Select Status"}
                        name={"is_active"}
                        value={is_active}
                        onChange={(e) => employeeHandleChange(e)}
                        // onBlur={() => onBlurHandler({ is_active: is_active })}
                        error={errors.is_active}
                        options={[
                          {
                            value: true,
                            label: selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                          },
                          {
                            value: false,
                            label: selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                          },
                        ]}
                      />
                      <div className="flex flex-col gap-3">
                        <DateInput
                          required={false}
                          title={selectedLanguage?.name == "English" ? "Joining Date" : translation[484]?.value ? translation[484]?.value : "Joining Date"}
                          name={"joining_date"}
                          value={joining_date}
                          custom_padding={" "}
                          onChange={(e) => employeeHandleChange(e)}
                          // onBlur={() => onBlurHandler({ joining_date: joining_date })}
                          // error={errors.joining_date}
                          placeholder={selectedLanguage?.name == "English" ? "Enter Joining Date" : translation[484]?.value ? translation[484]?.value : "Enter Joining Date"}
                        />

                        <CheckBox
                          label={selectedLanguage?.name == "English" ? "To Present" : translation[485]?.value ? translation[485]?.value : "To Present"}
                          name={"to_present"}
                          value={to_present}
                          checked={to_present}
                          onChange={() =>
                            setEmployeeData((prev) => ({
                              ...prev,
                              to_present: !to_present,
                            }))
                          }
                        // error={errors.to_present}
                        />
                      </div>
                      {!to_present && (
                        <DateInput
                          required={false}
                          title={selectedLanguage?.name == "English" ? "Ending Date" : translation[530]?.value ? translation[530]?.value : "Ending Date"}
                          name={"ending_date"}
                          value={ending_date}
                          custom_padding={" "}
                          onChange={(e) => employeeHandleChange(e)}
                          // onBlur={() => onBlurHandler({ ending_date: ending_date })}
                          // error={errors.ending_date}
                          placeholder={selectedLanguage?.name == "English" ? "Enter Ending Date" : translation[530]?.value ? translation[530]?.value : "Enter Ending Date"}
                        />
                      )}
                      <div className="flex flex-col gap-3 h-fit">
                        <Dropwdown
                          required={true}
                          name={"location"}
                          value={location}
                          onChange={employeeHandleChange}
                          disabled={isEdit}
                          options={reduxlocationsList?.map((adrs) => {
                            // reduxlocationsList?.filter((lctin) => (!location.includes(lctin?.id)))?.map((adrs) => {
                            return {
                              label: adrs?.address_name,
                              value: adrs?.id,
                            };
                          })}
                          onBlur={() => onBlurHandler({ location: location })}
                          error={errors?.location}
                          // onBlur={() => onBlurHandler({ location: location?.length > 0 ? location[0] : "" })}
                          title={selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}
                          placeholder={selectedLanguage?.name == "English" ? "Select Location" : translation[20]?.value ? translation[20]?.value : "Select Location"}
                        />

                      </div>
                    </div>
                    <div className="flex items-center justify-between mt-[2rem] gap-2">
                      {isEdit &&
                        <LoginBtn
                          loading={loading}
                          disabled={loading}
                          onClick={() => {
                            firstStepSubmitValidation(
                              () => {

                                // Success Func 
                                if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                                  checkEmployeeEmailAddressAndMobileNumber(
                                    {
                                      email,
                                      mobile_number
                                    },
                                    () => {
                                      // Success Func 
                                      onEmployeeSubmit();
                                    },
                                    () => {
                                      // Fail Func 
                                    }
                                  )
                                }
                              },
                              () => {
                                // Fail Func 
                              }
                            )

                            // setstep(2)
                          }}
                          text={selectedLanguage?.name == "English" ? "Save Changes" : translation[678]?.value ? translation[678]?.value : "Save Changes"}
                        >
                        </LoginBtn>
                      }
                      <LoginBtn
                        // disabled={Object.values(errors)?.find((itm) => itm ? true : false) ? true : false}
                        // disabled={!full_name
                        //   || !gender
                        //   || !country
                        //   || !address
                        //   || !is_active
                        //   || !location}
                        // onBlur={() => checkEmployeeEmailAddressAndMobileNumber(email)}
                        // error={errors.checkEmployeeEmailAddressAndMobileNumber(email)}
                        // loading={true}
                        // disabled={loading}
                        onClick={() => {
                          firstStepSubmitValidation(
                            () => {
                              // Success Func 
                              if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                                checkEmployeeEmailAddressAndMobileNumber(
                                  {
                                    email,
                                    mobile_number
                                  },
                                  () => {
                                    // Success Func 
                                    onEmployeeSubmit(1);
                                  },
                                  () => {
                                    // Fail Func 
                                  }
                                )
                              }
                            },
                            () => {
                              // Fail Func 
                            }
                          )
                        }}
                      >
                        <div className="flex items-center gap-2">
                          <div className="">
                            {selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
                          </div>
                          <Svgs.NextArrow />
                        </div>
                      </LoginBtn>
                    </div>
                  </div>
                </>
              )}

              {step == 2 && (
                <>
                  <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col pb-[1.5rem]">
                    <CircleIcon>
                      <Svgs.Professional_employee />
                    </CircleIcon>
                    <div className="flex flex-col gap-1 lg:text-left text-center">
                      <h2 className="text-primary font-semibold text-xl">
                        {selectedLanguage?.name == "English" ? "Professional Information" : translation[515]?.value ? translation[515]?.value : "Professional Information"}
                      </h2>
                      <p className="text-[#7B7B7B] text-sm">
                        {selectedLanguage?.name == "English"
                          ? "Add employee professional information to manage business operations."
                          : translation[487]?.value
                            ? translation[487]?.value : "Add employee professional information to manage business operations."
                        }
                      </p>
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 gap-5">
                    <Dropwdown
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Designation" : translation[488]?.value ? translation[488]?.value : "Designation"}
                      placeholder={selectedLanguage?.name == "English" ? "Select Designation" : translation[540]?.value ? translation[540]?.value : "Select Designation"}
                      name={"designation"}
                      value={designation}
                      onChange={(e) => employeeHandleChange(e, "designation")}
                      onBlur={() => onBlurHandler({ designation: designation })}
                      error={errors.designation}
                      options={DESIGNATIONS}
                    />

                    <Dropwdown
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Income Type" : translation[432]?.value ? translation[432]?.value : "Income Type"}
                      placeholder={selectedLanguage?.name == "English" ? "Select Income Type" : translation[491]?.value ? translation[491]?.value : "Select Income Type"}
                      name={"income_type"}
                      value={income_type}
                      onChange={(e) => employeeHandleChange(e, "income_type")}
                      onBlur={() => onBlurHandler({ income_type: income_type })}
                      error={errors.income_type}
                      options={[
                        {
                          value: "Hourly_Rate",
                          label: selectedLanguage?.name == "English" ? "Hourly Rate" : translation[498]?.value ? translation[498]?.value : "Hourly Rate"
                        },
                        {
                          value: "Daily_Income",
                          label: selectedLanguage?.name == "English" ? "Daily Income" : translation[499]?.value ? translation[499]?.value : "Daily Income"
                        },
                        {
                          value: "Monthly_Salary",
                          label: selectedLanguage?.name == "English" ? "Monthly Salary" : translation[500]?.value ? translation[500]?.value : "Monthly Salary"
                        },
                      ]}
                    />

                    <div>
                      <FloatInput
                        required={true}
                        title={selectedLanguage?.name == "English" ? "Salary" : translation[492]?.value ? translation[492]?.value : "Salary"}
                        placeholder={selectedLanguage?.name == "English" ? "Enter Salary" : translation[494]?.value ? translation[494]?.value : "Enter Salary"}
                        onEnterSubmit={onEmployeeSubmit}
                        name={"salary"}
                        value={salary}
                        onlyFloatAllowed={true}
                        onChange={(e) => employeeHandleChange(e, "salary")}
                        onBlur={() => onBlurHandler({ salary: salary })}
                        error={errors.salary}
                      />
                    </div>

                    <div>
                      <Dropwdown
                        required={false}
                        title={selectedLanguage?.name == "English" ? "Assign Staff Group" : translation[495]?.value ? translation[495]?.value : "Assign Staff Group"}
                        placeholder={selectedLanguage?.name == "English" ? "Select Staff Group" : translation[496]?.value ? translation[496]?.value : "Select Staff Group"}
                        name={"staff_group"}
                        value={staff_group}
                        onChange={(e) => employeeHandleChange(e)}
                        // onBlur={() => onBlurHandler({ staff_group: staff_group })}
                        // error={errors.staff_group}
                        options={staffGroupRedux?.map((staff) => {
                          return {
                            label: staff?.name,
                            value: staff?.id,
                          };
                        })}
                      />
                      {staffGroupRedux?.length == 0 &&
                        <p>
                          {selectedLanguage?.name == "English" ? "You dont have any group yet." : translation[501]?.value ? translation[501]?.value : "You dont have any group yet."}

                          <span className='cursor-pointer text-blue-500 underline mx-px' onClick={() => {
                            // staffGroupModalToggler();
                            setStaffModal(!staffModal)
                          }}>
                            {selectedLanguage?.name == "English" ? "Click here" : translation[14]?.value ? translation[14]?.value : "Click here"}
                          </span>
                          {selectedLanguage?.name == "English" ? " to add." : translation[140]?.value ? translation[140]?.value : " to add."}
                        </p>
                      }
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 gap-5">
                    <LoginInput
                      type={"number"}
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Casual" : translation[507]?.value ? translation[507]?.value : "Casual"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Casual Leaves" : translation[508]?.value ? translation[508]?.value : "Enter Casual Leaves"}
                      name={"operational_casual_leave"}
                      value={leave_data?.operational_casual_leave}
                      onChange={(e) => employeeHandleLeaveChange(e)}
                      error={errors.operational_casual_leave}
                      rightIconClassName={'h-[2.125rem] mr-px w-14 justify-center flex items-center !right-0 rounded-tr-[3px] rounded-br-[3px]  bg-[#E4EAF0]'}
                      rightIcon={leave_data?.casual_leave}
                    />
                    <LoginInput
                      type={"number"}
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Annual" : translation[507]?.value ? translation[507]?.value : "Annual"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Annual Leaves" : translation[508]?.value ? translation[508]?.value : "Enter Annual Leaves"}
                      name={"operational_annual_leave"}
                      value={leave_data?.operational_annual_leave}
                      onChange={(e) => employeeHandleLeaveChange(e)}
                      error={errors.operational_annual_leave}
                      rightIconClassName={'h-[2.125rem] mr-px w-14 justify-center flex items-center !right-0 rounded-tr-[3px] rounded-br-[3px]  bg-[#E4EAF0]'}
                      rightIcon={leave_data?.annual_leave}
                    />
                    <LoginInput
                      type={"number"}
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Medical" : translation[507]?.value ? translation[507]?.value : "Medical"}
                      placeholder={selectedLanguage?.name == "English" ? "Enter Medical Leaves" : translation[508]?.value ? translation[508]?.value : "Enter Medical Leaves"}
                      name={"operational_medical_leave"}
                      value={leave_data?.operational_medical_leave}
                      onChange={(e) => employeeHandleLeaveChange(e)}
                      error={errors.operational_medical_leave}
                      rightIconClassName={'h-[2.125rem] mr-px w-14 justify-center flex items-center !right-0 rounded-tr-[3px] rounded-br-[3px]  bg-[#E4EAF0]'}
                      rightIcon={leave_data?.medical_leave}
                    />
                    <Dropwdown
                      required={true}
                      name={"number_of_months"}
                      value={leave_data?.number_of_months}
                      onChange={(e) => employeeHandleLeaveChange(e)}
                      error={errors?.number_of_months}
                      options={leaveMonthsOptions}
                      textTransform={'normal-case'}
                      title={selectedLanguage?.name == "English" ? "Annual leave eligibility period" : translation[1537]?.value ? translation[1537]?.value : "Annual leave eligibility period"}
                      placeholder={selectedLanguage?.name == "English" ? "Select time period" : translation[1538]?.value ? translation[1538]?.value : "Select time period"}
                    />
                  </div>
                  <div className="flex flex-col gap-3 flex-wrap">
                    <Dropwdown
                      required={true}
                      title={selectedLanguage?.name == "English" ? "Select Working Days" : translation[503]?.value ? translation[503]?.value : "Select Working Days"}
                      placeholder={selectedLanguage?.name == "English" ? "Select Days" : translation[504]?.value ? translation[504]?.value : "Select Days"}
                      name={"working_days"}
                      value={working_days}
                      onChange={(e) => {
                        let { name, value } = e.target;
                        if (value === "all_days") {
                          setEmployeeData((prev) => ({
                            ...prev,
                            monday: true,
                            tuesday: true,
                            wednesday: true,
                            thursday: true,
                            friday: true,
                            saturday: true,
                            sunday: true,
                          }));
                          setErrors((prev) => ({ ...prev, working_days: "" }));
                        } else {
                          employeeHandleChange({
                            target: {
                              name: value.toLowerCase(),
                              value: true,
                            },
                          });
                          setErrors((prev) => ({ ...prev, working_days: "" }));
                        }
                      }}
                      onBlur={() => onBlurHandler({ working_days: working_days })}
                      error={errors.working_days}
                      options={[
                        {
                          label: "All Days",
                          value: "all_days",
                        },
                        ...WEEKDAYS.filter((itm) => !employeeData[itm?.toLowerCase()]).map((srvc) => ({
                          label: srvc,
                          value: srvc,
                        })),
                      ]}
                    />
                    <div className="flex items-center gap-3 flex-wrap">
                      {WEEKDAYS?.map((item) => {
                        return (
                          employeeData[item.toLowerCase()] && (
                            <div className="px-3 py-2 rounded-full flex items-center gap-3 border-[2px] border-primary w-max text-primary cursor-pointer">
                              {item}
                              <span
                                onClick={() => {
                                  let name = item.toLowerCase();
                                  setEmployeeData({
                                    ...employeeData,
                                    [name]: false,
                                  });
                                }}
                              >
                                <Svgs.CirlceClose fill="#5679FF" />
                              </span>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>

                  <div className="w-full col-span-4 relative ml-[-1.4rem]">
                    <div className="">
                      <>
                        {employeeData?.services
                          ?.filter((itm) => !itm?.is_deleted)
                          .map((selected_service, ss_index) => {
                            return (
                              <>
                                <AddEmployeeServiceCard
                                  data={selected_service}
                                  selectedLanguage={selectedLanguage}
                                  translation={translation}
                                  key={ss_index}
                                  data_list={servicesList}
                                  index={ss_index}
                                  is_last={
                                    // employeeData?.services?.length - 1 ==
                                    //   ss_index &&
                                    //   servicesList?.length ==
                                    //   employeeData?.services?.length
                                    //   ? true
                                    //   : false
                                    false
                                  }
                                  onEditClick={() => {
                                    let prev_data = [...employeeData?.services];
                                    prev_data[ss_index] = {
                                      ...prev_data[ss_index],
                                      edit_able: true,
                                    };
                                    setEmployeeData({
                                      ...employeeData,
                                      services: [...prev_data],
                                    });
                                  }}
                                  onDeleteClick={() => {
                                    let prev_data = [...employeeData?.services];
                                    if (emp_id && prev_data[ss_index]["id"]) {
                                      prev_data[ss_index]["is_deleted"] = true;
                                    } else {
                                      prev_data.splice(ss_index, 1);
                                    }
                                    prev_data = prev_data?.filter(itm => !itm?.is_deleted)
                                    setEmployeeData({
                                      ...employeeData,
                                      services: [...prev_data],
                                    });
                                  }}
                                  onUpdate={(e) => {
                                    let { name, value } = e.target;
                                    let prev_data = [...employeeData?.services];
                                    prev_data[ss_index] = {
                                      ...prev_data[ss_index],
                                      [name]: value,
                                    };
                                    setEmployeeData({
                                      ...employeeData,
                                      services: [...prev_data],
                                    });
                                  }}
                                />
                              </>
                            );
                          })}
                      </>
                      {
                        // servicesList && servicesList?.filter((itm) => !services?.find(s_itm => s_itm.service == itm.id))?.length > 100 &&
                        <div className="flex gap-6">
                          <div className="relative">
                            <div className="flex flex-col absolute top-1/2 -translate-x-1/2 -translate-y-1/2 ">
                              <div className="h-[1.8rem] w-[1.8rem] rounded-full border border-[#C9C7C7] bg-white flex items-center justify-center"></div>
                            </div>
                            {employeeData?.services?.length > 0 && (
                              <div className="absolute h-[50%] w-[2px] border-l border-dashed border-l-[#C9C7C7] z-[-1] top-0 left-1/2 -translate-x-1/2"></div>
                            )}
                          </div>
                          <div className="border px-5 py-5 rounded-md w-full flex flex-col gap-4">
                            <div className="grid lg:grid-cols-2 gap-4">
                              <>
                                <Dropwdown
                                  required={false}
                                  title={selectedLanguage?.name == "English" ? "Services" : translation[31]?.value ? translation[31]?.value : "Services"}
                                  placeholder={selectedLanguage?.name == "English" ? "Select Services" : translation[89]?.value ? translation[89]?.value : "Select Services"}
                                  name={"services"}
                                  value={multi_service_data?.service}
                                  onChange={(e) => {
                                    let dd_data = {
                                      level: 'Above_Averge',
                                      service: e.target.value,
                                      ...multi_service_data,
                                    };
                                    setServiceData(dd_data);
                                    // if (multi_service_data?.level) {
                                    let new_services = employeeData.services
                                      ? employeeData.services
                                      : [];
                                    setEmployeeData({
                                      ...employeeData,
                                      services: [...new_services, dd_data],
                                    });
                                    setServiceData({});
                                    // }
                                  }}
                                  // onBlur={() => onBlurHandler({ services: services })}
                                  // error={errors.services}
                                  options={
                                    servicesList &&
                                    servicesList
                                      ?.filter(
                                        (itm) =>
                                          !services?.find(
                                            (s_itm) => s_itm.service == itm.id
                                          )
                                      )
                                      ?.map((srvc) => {
                                        return {
                                          label: srvc?.name,
                                          value: srvc?.id,
                                        };
                                      })
                                  }
                                />
                                <Dropwdown
                                  title={
                                    <I_Info_Icon
                                      text={
                                        <>
                                          {selectedLanguage?.name == "English" ? "Employee Level" : translation[505]?.value ? translation[505]?.value : "Employee Level"}
                                          <span className="text-red-600">*</span>
                                        </>
                                      }
                                      info={
                                        selectedLanguage?.name == "English" ?
                                          "The employees will appear based on their level during appointment booking."
                                          : translation[506]?.value
                                            ? translation[506]?.value
                                            : "The employees will appear based on their level during appointment booking."
                                      }
                                    />
                                  }
                                  name="level"
                                  value={multi_service_data?.level}
                                  onChange={(e) => {
                                    let dd_data = {
                                      ...multi_service_data,
                                      level: e.target.value,
                                    };
                                    setServiceData(dd_data);
                                    if (multi_service_data.service) {
                                      let new_services = employeeData.services
                                        ? employeeData.services
                                        : [];
                                      setEmployeeData({
                                        ...employeeData,
                                        services: [...new_services, dd_data],
                                      });
                                      setServiceData({});
                                    }
                                  }}
                                  // onBlur={() => onBlurHandler({ level: level })}
                                  // error={errors.level}
                                  placeholder={selectedLanguage?.name == " English" ? "Select Level " : translation[541]?.value ? translation[541]?.value : "Select Level "}
                                  options={STAFF_LEVELS}
                                  disabled={
                                    !multi_service_data?.service ||
                                    multi_service_data?.service == "null" ||
                                    multi_service_data?.service == "undefined"
                                  }
                                />
                              </>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  {/* <div className="grid lg:grid-cols-2 gap-5">
                <LoginInput
                  type={"number"}
                  rightIcon={"%"}
                  required={false}
                  title={selectedLanguage?.name == "English" ? "Maximum bill discount permission" : translation[507]?.value ? translation[507]?.value : "Maximum bill discount permission"}
                  placeholder={selectedLanguage?.name == "English" ? "Enter Maximum discount" : translation[508]?.value ? translation[508]?.value : "Enter Maximum discount"}
                  name={"maximum_discount"}
                  value={maximum_discount}
                  onChange={(e) => employeeHandleChange(e)}
                // onBlur={() => onBlurHandler({ maximum_discount: maximum_discount })}
                // error={errors.maximum_discount}
                />
              </div> */}
                  <div className="flex items-center justify-end mt-[2rem]">
                    <LoginBtn
                      onClick={() => {
                        onEmployeeSubmit();
                      }}
                    >
                      <div className="flex items-center gap-2">
                        <div className="">
                          {selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
                        </div>
                        <Svgs.NextArrow />
                      </div>
                    </LoginBtn>
                  </div>
                </>
              )}

              {step == 3 && (
                <>
                  <div className="flex flex-col gap-5">
                    <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
                      <CircleIcon>
                        <Svgs.Avatar_guard />
                      </CircleIcon>
                      <div className="flex flex-col gap-1 lg:text-left text-center">
                        <h2 className="text-primary font-semibold text-xl">
                          {selectedLanguage?.name == "English" ? "Permissions" : translation[467]?.value ? translation[467]?.value : "Permissions"}
                        </h2>
                        <p className="text-[#7B7B7B] text-sm">
                          {selectedLanguage?.name == "English" ? "Assign permissions to employee regarding appointments,inventory" : translation[544]?.value ? translation[544]?.value : "Assign permissions to employee regarding appointments,inventory"}
                          <br className="hidden md:block" />
                          {selectedLanguage?.name == "English" ? "stock,reports, etc." : translation[545]?.value ? translation[545]?.value : "stock,reports, etc."}
                        </p>
                      </div>
                    </div>
                    <PermissionsComponent
                      selectedLanguage={selectedLanguage}
                      translation={translation}
                      onUpdate={employeePermissionHandleChange}
                      data={employeeData}
                    />
                    <div className="mt-[2rem] flex items-center justify-end">
                      <LoginBtn
                        loading={loading}
                        disabled={loading}
                        onClick={() => {
                          onEmployeeSubmit();
                          // setFinished(true);
                        }}
                        text={selectedLanguage?.name == "English" ? "Submit" : translation[713]?.value ? translation[713]?.value : "Submit"}
                      >
                      </LoginBtn>
                    </div>
                  </div>
                </>
              )}
            </>
          }
        </div>
      </div>
      {Finished && (
        <Popup
          heading={selectedLanguage?.name == "English" ? "Successful" : translation[562]?.value ? translation[562]?.value : "Successful"}
          open={Finished}
          // close={setFinished}
          onclose={() => {
            navigate("/dashboard/employee/employees/");
          }}
        >
          <div className="my-[2rem] flex flex-col gap-5 items-center text-center">
            <div className="h-[150px] w-[150px]">
              <Svgs.Form_Complete_check />
            </div>
            <h2 className="font-semibold text-xl">
              {selectedLanguage?.name == "English" ? "Employee added successfully" : translation[563]?.value ? translation[563]?.value : "Employee added successfully"}
            </h2>
            <p className="text-[#7B7B7B] text-sm">
              {selectedLanguage?.name == "English" ? "Employee can manage business operations now." : translation[564]?.value ? translation[564]?.value : "Employee can manage business operations now."}
            </p>
            <LoginBtn
              onClick={() => {
                navigate("/dashboard/employee/employees/");
              }}
              borderBtn

            >
              <p className="font-semibold">
                {selectedLanguage?.name == "English" ? "View All Employees" : translation[565]?.value ? translation[565]?.value : "View All Employees"}
              </p>
            </LoginBtn>
          </div>
        </Popup>
      )}

      {staffModal && (
        <>
          <AddStaffGroupPopup
            selectedLanguage={selectedLanguage}
            translation={translation}
            open={staffModal}
            close={staffGroupModalToggler}
          />
        </>
      )}
    </>
  );
};

export default AddEmployee;