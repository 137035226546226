const {
  CONSUMPTION,
} = require("Redux/TenantRedux/ActionTypes/consumptionTypes");

export const getProductConsumption = (data) => {
  return {
    type: CONSUMPTION.GET_PRODUCT_CONSUMPTIONS,
    payload: data,
  };
};

export const getConsumptionWithNoPagination = (data) => {
  return {
    type: CONSUMPTION.GET_PRODUCT_CONSUMPTIONS_WITH_NO_PAGINATION,
    payload: data,
  };
};

export const addProductConsumption = (data) => {
  return {
    type: CONSUMPTION.ADD_PRODUCT_CONSUMPTION,
    payload: data,
  };
};

export const updateProductConsumption = (data) => {
  return {
    type: CONSUMPTION.UPDATE_PRODUCT_CONSUMPTION,
    payload: data,
  };
};

export const deleteProductConsumption = (id) => {
  return {
    type: CONSUMPTION.DELETE_PRODUCT_CONSUMPTION,
    payload: id,
  };
};
