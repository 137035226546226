import { useSelector } from "react-redux"
import Svgs from "../../Assets/svgs"
import { useNavigate } from "react-router-dom"

const InventoryInsightNotFound = ({ className }) => {
    const navigate = useNavigate()
    const state = useSelector((state) => state)
    const translation = state?.languages?.language_translation
    const selectedLanguage = state?.languages?.selected_language

    return (
        <>
            <div className={`flex items-center justify-center my-5 ${className}`}>
                <div className="flex flex-col gap-1 justify-center text-center">
                    <div className="w-fit mx-auto">
                        <Svgs.InventoryInsightNotFound />
                    </div>
                    <h1 className="text-primary font-semibold text-lg normal-case">
                        {selectedLanguage?.name == "English" ? "No inventory insight available!" : translation[1252]?.value ? translation[1252]?.value : "No inventory insight available!"}
                    </h1>
                    <p className="text-sm text-[#555555]">
                        {selectedLanguage?.name == "English" ? "After you" : translation[1250]?.value ? translation[1250]?.value : "After you"}
                        {" "}
                        <span
                            onClick={() => {
                                navigate("/dashboard/account/inventory-operations/products");
                            }}
                            className="underline text-primary cursor-pointer !text-[#5679FF]"
                        >
                            {selectedLanguage?.name == "English" ? "add product" : translation[235]?.value ? translation[235]?.value : "add product"}
                        </span>{" "}
                        {selectedLanguage?.name == "English" ? "to this location, top products become available." : translation[1251]?.value ? translation[1251]?.value : "to this location, top products become available."}
                    </p>
                </div>
            </div>
        </>
    )
}

InventoryInsightNotFound.defaultProps = {
    className: ''
}

export default InventoryInsightNotFound