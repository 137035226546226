import React from 'react'
import useTrainerComments from './helper'
import moment from 'moment'
import Popup from 'Components/TenantApp/Elements/Popup'
import LoginInput from 'Components/TenantApp/Elements/LoginInput'
import LoginBtn from 'Components/TenantApp/Elements/LoginBtn'

const RejectRequestPopup = ({ open, close, data, onSuccessCall }) => {
    const {
        translation, selectedLanguage,
        trainingData, Data, errors, loading, onChangeHandler, onSubmitHandler
    } = useTrainerComments(data, onSuccessCall, close)
    return (
        <>
            <Popup
                open={open}
                onclose={close}
                heading={"Reject Request"}
            >
                <div className='flex flex-col gap-5'>
                    <div className='flex justify-end items-center'>
                        <LoginBtn
                            onClick={() => {
                                onSubmitHandler('Reject')
                            }}
                            loading={loading}
                            disabled={loading}
                            className={"!px-6"}
                            text={selectedLanguage?.name == "English" ? "Reject" : translation[1493]?.value ? translation[1493]?.value : "Reject"}
                        />
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default RejectRequestPopup