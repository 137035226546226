import Svgs from "Assets/svgs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHelper } from "./helper";
import moment from "moment";


export const FreeItemForOtherPurchaseCard = ({ onSelect, data, isDelete, selected, onRemoveOffer, servicesRedux }) => {

  const [purchase_data, setPurchaseData] = useState({})

  const state = useSelector(state => state)
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language


  useEffect(() => {
    let free_services = [...data.free_service]

    free_services.forEach((serv) => {
      let inn_serv = servicesRedux?.find((service) => service?.id === serv.service)
      if (inn_serv) {
        serv.name = inn_serv.name
        serv.client_can_book = inn_serv.client_can_book
        serv.slot_availible_for_online = inn_serv.slot_availible_for_online
      }
    })
    let this_service = servicesRedux?.find((service) => service?.id === data.service)

    setPurchaseData({
      ...data,
      free_service: free_services,
      service_name: this_service ? this_service?.name : ''
    })
  }, [])


  return (
    <div
      className={`bg-[#F2F5FF] relative ${selected?.id === purchase_data?.id ? "border-black" : " border-[#B8C6F5]"
        }  border   rounded-md p-4 mt-1 cursor-pointer`}
      onClick={() => {
        onSelect && onSelect({ ...purchase_data })
      }}
    >
      <div
        className="absolute cursor-pointer top-0 right-0 translate-x-1/2 -translate-y-1/2"
      >
        {isDelete && <Svgs.CirlceClose size="20" fill="red" />}
      </div>
      <div className="flex justify-between items-center mb-2">

        <div className="text-[#A1A1A1] font-bold">
          {selectedLanguage?.name == "English" ? "Get a Free Item For other purchase" : translation[1215]?.value ? translation[1215]?.value : "Get a Free Item For other purchase"}
        </div>

        {onRemoveOffer && selected &&
          <button
            className=" h-[2.5rem] w-[2.5rem] border border-[#767A87] rounded-full flex items-center cursor-pointer justify-center"
            onClick={onRemoveOffer}
          >
            <Svgs.Delete fill="#767A87" />
          </button>
        }
      </div>
      <div className="text-black font-semibold mb-2 w-full">{data?.promotion_name}</div>
      <div className="flex items-center  gap-6">

        <div className="flex flex-col gap-2 flex-1">
          <p className=" text-bold">
            {selectedLanguage?.name == "English" ? "Get Free" : translation[1216]?.value ? translation[1216]?.value : "Get Free"}
            {purchase_data?.service_name ? <span className="font-bold">"{purchase_data?.service_name}"</span> : ''}
            {selectedLanguage?.name == "English" ? "on buying" : translation[1217]?.value ? translation[1217]?.value : "on buying"}
          </p>
          <div className="flex items-center justify-between gap-2">
            <div>
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Service" : translation[31]?.value ? translation[31]?.value : "Service"}
              </p>
            </div>
            <div>
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Quantity" : translation[365]?.value ? translation[365]?.value : "Quantity"}
              </p>
            </div>
          </div>
          {purchase_data?.free_service?.map((obj, index) => {
            return (
              <div className="flex items-center justify-between gap-2" key={index} >
                <div>
                  <p className="text-[#101928] font-semibold">{obj?.name}</p>
                </div>
                <div>
                  <p className="text-[#101928] font-semibold text-left">
                    {obj.quantity}
                  </p>
                </div>
              </div>
            );
          })}
          {/* Date duration */}
          <div className="flex items-center justify-between w-full gap-2">
            <div className="flex items-center gap-2">
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "Start Date" : translation[900]?.value ? translation[900]?.value : "Start Date"}
              </p>
              <p className="text-[#101928] font-semibold">
                {moment(data?.date_restrictions?.start_date).format('DD-MM-YYYY')}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <p className="text-[#A1A1A1] text-sm">
                {selectedLanguage?.name == "English" ? "End Date" : translation[901]?.value ? translation[901]?.value : "End Date"}
              </p>
              <p className="text-[#101928] font-semibold">
                {moment(data?.date_restrictions?.end_date).format('DD-MM-YYYY')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export const FreeItemForOtherPurchase = ({ MODULE_TYPE, onSelect, get_free_item_for_other_purchase, servicesRedux }) => {
  const state = useSelector(state => state)
  const dispatch = useDispatch()


  const handle_selected_data_appointment = (data) => {
    let prev_data = JSON.parse(JSON.stringify(data))
    if (data?.free_service?.length > 0) {
      let first_staff = state?.employee?.employees[0]
      let new_appointments = []
      let today_date = new Date()
      today_date = `${today_date.getFullYear()}-${today_date.getMonth() + 1}-${today_date.getDate()}`

      let frv_service = state.service.services?.find(itm => itm.id == data?.service)
      if (frv_service) {
        data?.free_service?.push({ ...frv_service, service: frv_service.id, quantity: 1, is_free_service: true })
      }

      let selected_currency_id = state.locations.locations.find(loc => loc.id == state.locations?.selected_location)?.currency?.id


      data?.free_service?.forEach(service => {
        let quantity = service?.quantity
        if (!quantity) {
          return
        }

        // let this_price = service.priceservice?.find(itm => itm.currency == selected_currency_id)
        // Show only first price as per Project Manager

        let this_price = service.priceservice[0]
        let srvic_duration = undefined
        if (this_price) {
          srvic_duration = this_price.duration
        }

        if (srvic_duration) {
          srvic_duration = srvic_duration.toLowerCase()
        }

        for (let q = 1; q <= quantity; q++) {
          let appointment = {
            appointment_date: today_date,
            client_can_book: service.client_can_book,
            duration: srvic_duration,
            member: first_staff ? first_staff?.id : undefined,
            price: service?.is_free_service ? 0 : this_price ? this_price.price : 0,
            service: service.service,
            slot_availible_for_online: service.slot_availible_for_online,
            srv_duration: srvic_duration,
            srv_name: service?.name
          }
          new_appointments.push(appointment)
        }
      })

      onSelect(
        new_appointments,
        false,
        {
          selected_promotion_type: data?.type,
          selected_promotion_id: data?.id,
          is_promotion_availed: true,
          // selected_promotion: data,
          selected_promotion: prev_data,
        }
      )
    }
  }


  const handle_select_service = (data) => {
    let selected_currency_id = state.locations.locations.find(loc => loc.id == state.locations?.selected_location)?.currency?.id
    let services = data?.free_service?.map(service => {
      // let this_price = service?.priceservice?.find(itm => itm.currency == selected_currency_id)
      // Show only first price as per Project Manager
      let this_price = service?.priceservice[0]
      return {
        ...service,
        id: service.service,
        selection_type: "SERVICE",
        quantity: service?.quantity,
        price: this_price ? this_price?.price : 0,
        discount_price: undefined,
      }
    })

    let free_service = state.service.services?.find(itm => itm.id == data?.service)
    if (free_service) {
      services.push({
        ...free_service,
        selection_type: "SERVICE",
        quantity: 1,
        price: 0,
        discount_price: undefined,
      })
    }

    dispatch({
      type: "ADD_BULK_ITEMS_TO_CART",
      payload: {
        'services': services,
        is_promotion: true,
        selected_promotion_type: data?.type,
        selected_promotion_id: data?.id,
        is_promotion_availed: true,
      }
    });
    toast.success("Items added to cart", { toastId: "toast" });
    onSelect(
      [],
      false,
    )
  }

  const handle_selected_data = (data) => {
    let funcs = {
      'APPOINTMENT': () => handle_selected_data_appointment(data),
      'SERVICE': () => handle_select_service(data),
    }
    funcs[MODULE_TYPE] && funcs[MODULE_TYPE]()
  }



  // appointment_date
  // client_can_book
  // date_time
  // discount_price
  // duration
  // index
  // member
  // message
  // offer
  // price
  // service
  // slot_availible_for_online
  // srv_duration
  // srv_name


  return (
    <>
      {
        get_free_item_for_other_purchase?.map((itm, index) => {
          return (
            <FreeItemForOtherPurchaseCard
              key={index}
              data={itm}
              isDelete={false}
              selected={false}
              servicesRedux={servicesRedux}
              onSelect={handle_selected_data}
            />
          )
        })
      }
    </>
  );
};