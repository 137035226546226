import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { delete_stock, get_product_stocks, get_product_stocks_nopagination, get_product_stocks_optmized } from "Adapters/Api/Products";

let timeOutId = undefined
export const useStocks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const stockData = state?.product?.stocks
  const pages = state?.product?.pagesStock
  const allCount = state?.product?.allCountStock
  const allStockData = state?.product?.allStocks
  const businessCurrency = state.business.business;
  const { selected_location } = state?.locations;
  const PageSize = 10

  const [stock_del_popup, setStockDelPopup] = useState(false);
  const [loader, setLoader] = useState(true)
  const [deleting, setDeleting] = useState(false);
  const [filter_data, setFilterData] = useState({});
  const [dataList, setDataList] = useState([]);
  const [search_text, setSearchText] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [noPaginationLoader, setNoPaginationLoader] = useState(true)

  const handle_stock_delete = (id) => {
    setDeleting(true);
    dispatch(
      delete_stock(
        { id: id },
        () => {
          setDeleting(false);
          setStockDelPopup(undefined);
          toast.success("Deleted Successful");
        },
        (err) => {
          setDeleting(false);
          toast.error(err);
        }
      )
    );
  };

  // const filter_stock = () => {
  //   let new_stocks = dataList.filter((itm) => {
  //     if (Object.keys(filter_data).length > 0) {
  //       let return_value = false;
  //       if (filter_data.text) {
  //         if (itm.name.toLowerCase().includes(filter_data.text?.toLowerCase())) {
  //           return_value = true;
  //         } else {
  //           return false;
  //         }
  //       }

  //       if (filter_data.vendor) {
  //         if (itm?.vendor?.id == filter_data.vendor) {
  //           return_value = true;
  //         } else {
  //           return false;
  //         }
  //       }

  //       if (filter_data.vendor_name) {
  //         if (itm?.vendor?.name == filter_data.vendor_name) {
  //           return_value = true;
  //         } else {
  //           return false;
  //         }
  //       }

  //       if (filter_data.brand) {
  //         if (itm?.brand?.id == filter_data.brand) {
  //           return_value = true;
  //         } else {
  //           return false;
  //         }
  //       }

  //       if (filter_data.category) {
  //         if (itm?.category?.id == filter_data.category) {
  //           return_value = true;
  //         } else {
  //           return false;
  //         }
  //       }

  //       if (filter_data.low_stock) {
  //         if (itm?.stock?.quantity < 10) {
  //           return_value = true;
  //         } else {
  //           return false;
  //         }
  //       }

  //       if (filter_data.high_stock) {
  //         if (itm?.stock?.quantity > 10) {
  //           return_value = true;
  //         } else {
  //           return false;
  //         }
  //       }

  //       if (filter_data.top_sellable_items) {
  //         if (itm?.stock?.sold_stock > itm?.stock?.quantity / 2) {
  //           return_value = true;
  //         } else {
  //           return false;
  //         }
  //       }

  //       return return_value;
  //     }
  //     return true;
  //   });
  //   new_stocks = new_stocks.sort((product_item_a, product_item_b) => product_item_b?.stock.filter(({ location }) => location?.id === selected_location)[0]?.sold_quantity - product_item_a?.stock.filter(({ location }) => location?.id === selected_location)[0]?.sold_quantity)
  //   return new_stocks;
  // };

  // useEffect(() => {
  //   // if (!state.product.stocks_updated) {
  //   dispatch(get_product_stocks(setLoader));
  //   // }
  // }, []);

  useEffect(() => {
    if (search_text === null) {
      // Initial load with empty search_text
      dispatch(get_product_stocks_optmized(currentPage, selected_location, "", setLoader))
      dispatch(get_product_stocks_nopagination(selected_location, "", setNoPaginationLoader))
    } else {
      if (timeOutId) {
        clearTimeout(timeOutId)
      }
      timeOutId = setTimeout(() => {
        dispatch(get_product_stocks_optmized(currentPage, selected_location, search_text, setLoader))
        dispatch(get_product_stocks_nopagination(selected_location, search_text, setNoPaginationLoader))
      }, 500);
    }
  }, [currentPage, search_text, selected_location])

  useEffect(() => {
    setCurrentPage(1)
  }, [search_text])

  useEffect(() => {
    setLoader(true)
  }, [currentPage])

  useEffect(() => {
    setLoader(true)
    setCurrentPage(1)
  }, [selected_location])

  // useEffect(() => {
  //   const val = state?.product?.stocks?.map((ele) => {
  //     const data = ele?.stock?.filter(
  //       ({ location }) => location?.id === selected_location
  //     );

  //     if (data.length > 0 && data !== undefined) return { ...ele };
  //   });

  //   const productArr = val?.filter((element) => element !== undefined);
  //   if (productArr) {
  //     setDataList(productArr);
  //   }
  // }, [selected_location, state.product?.stocks]);

  return {
    state, deleting, dataList, filter_data, businessCurrency, stock_del_popup, selected_location, navigate, setFilterData, setStockDelPopup,
    handle_stock_delete, loader, stockData, setSearchText, pages, allCount, PageSize, allStockData, currentPage, setCurrentPage
    // filter_stock,
  };
};
