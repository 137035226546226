import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CheckBox from '../../../Elements/Checkbox/CheckBox'
import Dropwdown from '../../../Elements/Dropwdown'

import Layout from '../Layout'
import useOpeningHours from './helper'
import { OPENING_TIME } from 'Constants/Data/OpeningHours'
import useAdminBooking from '../helper'

const OpeningHours = ({ count, setStepCount, StepCount }) => {
    const dispatch = useDispatch()
    const [buttonClick, setButtonClick] = useState(false)
    const [showError, setShowError] = useState(false)
    const { locationInfo, setLocationInfo } = useOpeningHours();
    const realFormat = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    let formatOpeningHours;


    if (locationInfo?.opening_hours?.length > 0) {
        formatOpeningHours = realFormat?.map((item) => locationInfo?.opening_hours?.find((itm) => itm?.day === item));
    }
    const { translation, selectedLanguage, formRef } = useAdminBooking()

    useEffect(() => {
        if (buttonClick) {
            locationInfo?.opening_hours?.find((itm) => {
                if ((!itm?.is_closed) && (!itm?.start_time || !itm?.close_time)) {
                    setShowError(true)
                    return true
                } else {
                    setShowError(false)
                }
            })
        }
    }, [locationInfo?.opening_hours, buttonClick])

    return (
        <Layout
            title={selectedLanguage?.name == "English" ? "Setup your business profile" : translation[81]?.value ? translation[81]?.value : "Setup your business profile"}
            heading={selectedLanguage?.name == "English" ? "Add your opening hours" : translation[98]?.value ? translation[98]?.value : "Add your opening hours"}
            subHeading={selectedLanguage?.name == "English" ? "Set standard opening hours to show on your profile page, these hours do not impact your calendar availability." : translation[99]?.value ? translation[99]?.value : "Set standard opening hours to show on your profile page, these hours do not impact your calendar availability."}

            count={count}
            NextClick={() => {
                setButtonClick(true)
                if (!showError) {
                    setStepCount(StepCount + 1)
                    dispatch({
                        type: "STORE_CURRENT_LOCATION_DATA",
                        payload: locationInfo
                    })
                }
            }}
            backClick={() => {
                dispatch({
                    type: "STORE_CURRENT_LOCATION_DATA",
                    payload: locationInfo
                })
                if (StepCount > 0) {
                    setStepCount(StepCount - 1)
                }
            }}

            // disabled={locationInfo?.opening_hours?.find((itm) => {
            //     if ((!itm?.is_closed) && (!itm?.start_time || !itm?.close_time)) {
            //         return true
            //     }
            // })}

            btnInner={selectedLanguage?.name == "English" ? "Next" : translation[69]?.value ? translation[69]?.value : "Next"}
        >
            <div className='flex flex-col gap-3' ref={formRef}>
                {
                    formatOpeningHours?.map((itm, index) => {
                        return (
                            <div className='flex items-start justify-between gap-4 flex-wrap sm:flex-row flex-col'>
                                <CheckBox
                                    label={itm?.day}
                                    name={itm?.day}
                                    value={itm?.is_closed}
                                    onChange={(e) => {
                                        setLocationInfo({
                                            ...locationInfo,
                                            opening_hours: [
                                                ...formatOpeningHours?.map((dt, ind) => {
                                                    if (ind === index) {
                                                        if ((formatOpeningHours[0]?.is_closed) && itm?.day != "monday") {
                                                            return {
                                                                ...itm,
                                                                is_closed: !dt?.is_closed,
                                                            };
                                                        } else {
                                                            return {
                                                                ...itm,
                                                                start_time: dt?.is_closed ? formatOpeningHours[0]?.start_time : null,
                                                                close_time: dt?.is_closed ? formatOpeningHours[0]?.close_time : null,
                                                                is_closed: !dt?.is_closed,
                                                            };
                                                        }
                                                    }
                                                    return dt;
                                                })]
                                        });
                                    }}
                                    checked={!itm?.is_closed}
                                />
                                <div className='flex flex-col gap-2'>
                                    <DateTime formatOpeningHours={formatOpeningHours} translation={translation} selectedLanguage={selectedLanguage} data={itm} index={index} locationInfo={locationInfo} setLocationInfo={setLocationInfo} />

                                    {/* <div className='bg-[#5679FF]/20 rounded-md px-5 py-3 text-xs flex items-center gap-3'>
                            <Svgs.Bulb />
                            <div>
                                <p className='text-[#101928]'>Copy these working hours to all <br className='md:block hidden' /> days? <span className='cursor-pointer text-[#5679FF]'>Yes, Copy</span></p>
                            </div>
                        </div> */}

                                </div>
                            </div>
                        )

                    })}
                {/* <div className='flex items-start justify-between gap-4'>
                    <CheckBox label={'Monday'} />
                    <DateTime Add={true} />
                </div> */}
            </div>
            {(buttonClick && showError) &&
                <div className='justify-end'>
                    <p className='text-red-500'>{selectedLanguage?.name == "English" ? "Add business hours for atleast one day." : translation[1292]?.value ? translation[1292]?.value : "Add business hours for atleast one day."}</p>
                </div>
            }
        </Layout>
    )
}

const DateTime = ({ data, index, locationInfo, translation, selectedLanguage, setLocationInfo, formatOpeningHours }) => {

    return <div className='flex items-center gap-4 justify-between'>
        <Dropwdown
            disabled={data?.is_closed}
            title={''}
            placeholder={selectedLanguage?.name == "English" ? "Enter Time" : translation[100]?.value ? translation[100]?.value : "Enter Time"}
            value={data?.start_time}
            name="start_time"
            custom_opening_hours_width="min-w-[10rem]"
            onChange={(e) => {
                setLocationInfo({
                    ...locationInfo,
                    opening_hours: [
                        ...formatOpeningHours.map((itm, ind) => {
                            if (data?.id == itm?.id) {
                                return {
                                    ...itm,
                                    start_time: e.target.value,
                                };
                            }
                            return itm
                        })]
                });
            }}
            options={OPENING_TIME}
        />
        {'-'}
        <Dropwdown
            disabled={data?.is_closed}
            title={''}
            placeholder={selectedLanguage?.name == "English" ? "Enter Time" : translation[100]?.value ? translation[100]?.value : "Enter Time"}
            value={data?.close_time}
            name="close_time"
            custom_opening_hours_width="min-w-[10rem]"
            onChange={(e) => {
                setLocationInfo({
                    ...locationInfo,
                    opening_hours: [
                        ...formatOpeningHours?.map((itm, ind) => {
                            if (data?.id == itm?.id) {
                                return {
                                    ...itm,
                                    close_time: e.target.value,
                                };
                            }
                            return itm
                        })]
                });
            }}
            options={OPENING_TIME}

        />

        {/* <div className='cursor-pointer'>
            {
                Add ? <Svgs.Plus size='1.2rem' color='#5679FF' /> : <Svgs.Close fill='red' />
            }
        </div> */}
    </div>
}

export default OpeningHours