import { SEGMENTS } from "Redux/TenantRedux/ActionTypes/segmentTypes"

export const getAllSegments = (data) => {
    return {
        type: SEGMENTS.GET_SEGMENTS,
        payload: data
    }
}

export const getSegmentsDropdownPagination = (data) => {
    return {
        type: SEGMENTS.GET_SEGMENTS_DROPDOWN_PAGINATION,
        payload: data
    }
}

export const addSegments = (data) => {
    return {
        type: SEGMENTS.ADD_SEGMENTS,
        payload: data
    }
}
export const deleteSegments = (data) => {
    return {
        type: SEGMENTS.DELETE_SEGMENTS,
        payload: data
    }
}
export const updateSegments = (data) => {
    return {
        type: SEGMENTS.UPDATE_SEGMENTS,
        payload: data
    }
}
