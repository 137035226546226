const {
  PURCHASE_ORDER,
} = require("Redux/TenantRedux/ActionTypes/purchaseOrderTypes");

const inventoryPurchases = {
  orderStock: [],
  orderStock_updated: false,
  allCount: 0,
  pages: 0,
};

const PurchasesReducer = (state = inventoryPurchases, action) => {
  const { type, payload } = action;

  switch (type) {
    case PURCHASE_ORDER.ADD_ORDER_STOCK:
      return {
        ...state,
        orderStock: [payload, ...state.orderStock],
      };

    case PURCHASE_ORDER.GET_ORDER_STOCK:
      return {
        ...state,
        orderStock: payload,
        orderStock_updated: true
      };

    case PURCHASE_ORDER.ALL_PURCHASE_ORDER:
      return {
        ...state,
        allPurchases: payload,
      };

    case PURCHASE_ORDER.GET_PURCHASES_PAGES:
      return {
        ...state,
        pages: payload.pages,
        allCount: payload.count
      };

    case PURCHASE_ORDER.UPDATE_ORDER_STOCK:
      let updateList = state?.orderStock?.map((item) => {
        if (item.id == payload.id) {
          return payload;
        } else {
          return item;
        }
      });
      return {
        ...state,
        orderStock: updateList,
      };

    case PURCHASE_ORDER.UPDATE_ORDER_STOCK_STATUS:
      let list = state?.orderStock?.map((item) => {
        if (item?.id === payload?.orderId) {
          const data = item?.products.map((obj) => {
            if (obj.id === payload.data.id) {
              return payload.data;
            }
            else {
              return obj
            }
          })
          if (data) {
            return { ...item, products: data };
          }
        }
        else {
          return item
        }
      });
      return {
        ...state,
        orderStock: list,
      };

    case PURCHASE_ORDER.DELETE_ORDER_STOCK:
      let filterArrayAfterDeletion = state?.orderStock?.filter(
        (order) => {
          if (order?.id !== payload) {
            return order;
          }
        }
      );
      return {
        ...state,
        orderStock: filterArrayAfterDeletion,
      };

    default:
      return state;
  }
};

export default PurchasesReducer;