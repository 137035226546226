import React from 'react'
import Popup from "Components/TenantApp/Elements/Popup";
// import useDetailSegmentPopup from './helper';
import StatusBtn from 'Components/TenantApp/Elements/StatusBtn';
import { object } from 'yup';
import useSegments from './helper';

const DetailSegment = ({ data, ViewDetails, setViewDetails }) => {
    const {
        selectedLanguage, translation,
     } = useSegments()

    return (
        <Popup
            size={"md"}
            heading={selectedLanguage?.name == "English" ? "View Details" : translation[244]?.value ? translation[244]?.value : "View Details"}
            open={ViewDetails}
            close={setViewDetails}
        >
            <div className="space-y-5">
                <div className="flex items-start gap-16">
                    <div className="flex flex-col gap-3">
                        <h5 className="text-[16px] font-semibold">{selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"}</h5>
                        <p className="text-[14px] font-normal capitalize">
                            {data?.segment_type}
                        </p>
                    </div>
                    <div className="flex flex-col gap-3">
                        <h5 className="text-[16px] font-semibold capitalize">{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</h5>
                        <StatusBtn
                            status={data?.is_active ? `${selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"}`: `${selectedLanguage?.name == "English" ? "InActive":translation[460]?.value ? translation[460]?.value : "InActive"}`}
                        />
                    </div>
                </div>
                {/* Make Style */}
                {/* <div>
                <h4 className="text-[30px] font-semibold pb-5">Make Style</h4>
                <div className="flex items-center gap-3 flex-wrap">
                <div className="border-2 border-[#5679FF] rounded-md flex items-center gap-3 p-1">
                    <img className="w-[34px] h-[34px] rounded-md" src="https://source.unsplash.com/random?people" alt="img" />
                    <span className="text-[#5679FF] text-base">Ayesha Zaka</span>
                </div>
                <div className="border-2 border-[#5679FF] rounded-md flex items-center gap-3 p-1">
                    <img className="w-[34px] h-[34px] rounded-md" src="https://source.unsplash.com/random?people" alt="img" />
                    <span className="text-[#5679FF] text-base">Ayesha Zaka</span>
                </div>
                <div className="border-2 border-[#5679FF] rounded-md flex items-center gap-3 p-1">
                    <img className="w-[34px] h-[34px] rounded-md" src="https://source.unsplash.com/random?people" alt="img" />
                    <span className="text-[#5679FF] text-base">Ayesha Zaka</span>
                </div>
                <div className="border-2 border-[#5679FF] rounded-md flex items-center gap-3 p-1">
                    <img className="w-[34px] h-[34px] rounded-md" src="https://source.unsplash.com/random?people" alt="img" />
                    <span className="text-[#5679FF] text-base">Ayesha Zaka</span>
                </div>
                </div>
            </div> */}
            </div>
        </Popup>
    )
}

export default DetailSegment