import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createOrderStock, getPurchaseOrder, updateOrderStockStatus } from "Adapters/Api/purchasesOrder";
import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
import { toast } from "react-toastify";
import { update_orderStock_status } from "Redux/TenantRedux/Actions/purchaseOrderActions";
import { getProducts, updateProductStatus } from "Redux/TenantRedux/Actions/product";
import { getProductConsumption } from "Redux/TenantRedux/Actions/consumption";
import { apiErrorMessage } from "Constants/Data/Errors";
import { usePurchaseOrder } from "../helper";
import useObjectKeys from "Hooks/useObjectKeys";
import useValidations from "Hooks/useValidations";

export const useUpdatePurchaseStatus = ({ dataList, updateData, handleClose }) => {
  const formRef = useRef()
  const { objectKeyConvertToArray } = useObjectKeys()
  const { validation } = useValidations()
  const { setLoader } = usePurchaseOrder()
  const state = useSelector((state) => state);
  const { selected_location } = state?.locations;
  const dispatch = useDispatch();
  const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  const access_token = getDataFromLocalStorage("access_token");
  const { locations } = state?.locations;
  const [updateStatus, setUpdateStatus] = useState(false);
  const [fromAddress, setFromAddress] = useState([]);
  const [receivedValue, setReceivedValue] = useState("");
  const [recievedError, setRecievedError] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const {
    handleSubmit,
    getValues,
    register,
    setValue,
    trigger,
    watch,
    reset,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(vaildationSchema),
    mode: "onBlur"
  });

  const { productId, orderStockId, locationId } = updateData;
  const onSubmit = async (formData) => {
    const { total_order, received, change_location, status, note } = formData;
    if (received && received > 0 && recievedError == "") {
      setIsLoading(true)
      const body = {
        stockproduct_id: data.orderStock.id,
        order_id: data.orderStock.order,
        product_id: data.orderStock.product.id,
        quantity: total_order,
        rec_quantity: received,
        vendor_id: data.product.vendor,
        to_location: change_location,
        status: status,
        note: note,
      }
      const response = await updateOrderStockStatus(body, access_token);
      if (response.status === 200) {
        toast.success(response.data.response.message, { toastId: "toast" });
        dispatch(update_orderStock_status(productId, response.data.response.stock))
        handleClose();
        setIsLoading(false)
        dispatch(getProducts([]));
        dispatch(getProductConsumption([]));
        dispatch(updateProductStatus());
        dispatch(getPurchaseOrder(selected_location, 1, "", setLoader, access_token))
      } else {
        toast.error(apiErrorMessage, { toastId: "toast" });
      }
    } else {
      setRecievedError("This field is required")
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const productOrderStock = dataList?.find((obj) => obj.id === productId);
    const orderStock = productOrderStock.products.find((obj) => obj.id === orderStockId);
    setData({ product: productOrderStock, orderStock: orderStock })
    reset({
      total_order: orderStock?.quantity,
      received: orderStock?.rec_quantity !== 0 ? orderStock?.rec_quantity : "",
      change_location: locationId,
      status: orderStock?.status,
      note: orderStock?.note,
    })
  }, [orderStockId])

  useEffect(() => {
    const num = parseInt(receivedValue, 10);
    if (num == '0') {
      setRecievedError("Received quantity should be greater than 0");
    } else {
      setRecievedError("");
    }
  }, [receivedValue]);

  useEffect(() => {
    setFromAddress(
      locations?.map(({ address_name, id }) => ({
        label: address_name,
        value: id,
      }))
    );
  }, []);

  return {
    handleSubmit,
    getValues,
    onSubmit,
    setValue,
    register,
    trigger,
    watch,
    setUpdateStatus,
    data,
    control,
    errors,
    fromAddress,
    updateStatus,
    isLoading,
    setReceivedValue,
    receivedValue,
    recievedError
  };
};

export const vaildationSchema = yup.object().shape({
  total_order: yup.string().required("field is required"),
  received: yup.string(),
  change_location: yup.string().required("field is required").nullable(),
  status: yup.string().required("field is required").nullable(),
  note: yup.string(),
});

export const status = (translation, selectedLanguage) => [
  {
    label: selectedLanguage?.name == "English" ? "Placed" : translation[1055]?.value ? translation[1055]?.value : "Placed",
    value: "Placed"
  },
  {
    label: selectedLanguage?.name == "English" ? "Received" : translation[1023]?.value ? translation[1023]?.value : "Received",
    value: "Received",
  },
];