import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import LoginInput from "../Elements/LoginInput";
import LoginBtn from "../Elements/LoginBtn";
import Popup from "../Elements/Popup";
import StatusBtn from "../Elements/StatusBtn";
import CircleIcon from "../Elements/CircleIcon";
import SelectedOption from "../Elements/SelectedOption";
import AddService from "./Sales/quick-sales/Services/addService";
import { getSaleServices, getSaleServicesWithNoPagination, getServiceGroup, getServiceGroupPaginationDropdown, getServiceGroupWithNoPagination } from "Adapters/Api/saleServices";
import Svgs from "Assets/svgs";
import { useService } from "./Sales/quick-sales/Services/helper";
import { useLocation } from "new-components/location/helper";
import PreloaderSm from "../Elements/PreloaderSm";
import Pagination from "new-components/Pagination";
import IconButton from "../Elements/IconButton";
import NoServiceFound from "Components/Utility/NoServiceFound";
import NoStaffGroupFound from "Components/Utility/NoStaffGroupFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import TableShimmer_v2 from "Components/Utility/Shimmers/TableShimmer_v2";
import EmployeesHeadShimmer from "Components/Utility/Shimmers/EmployeesHeadShimmer";
import Shimmer from "Components/Utility/Shimmers/Shimmer";
import AddServiceGroup from "./Sales/quick-sales/Services/AddServiceGroup";
import ProfileStack from "../Elements/ProfileStack";
import { getEmployeeList } from "Adapters/Api/employee";
// import useSetDataInLocalStorage from "Hooks/useSetDataInLocalStorage";
// import useObjectKeys from "Hooks/useObjectKeys";
// import useValidations from "Hooks/useValidations";
// import { apiErrorMessage } from "Constants/Data/Errors";
// import { SALE_SERVICES } from "Redux/TenantRedux/ActionTypes/saleServiceType";
// import Dropwdown from "../Elements/Dropwdown";
// import ToggleWithText from "../Elements/ToggleWithText";
// import { PostServiceGroup } from "Adapters/Api/CreateServicesGroup";
// import NotFound from "./Sales/Elements/NotFound";

let timeOutId = undefined;
let groupTimeOutId = undefined
const ServiceManagement = ({ service }) => {
  const { serviceData, setService, serviceHandleChange, serviceModal, isEdit, onDeleteGroup, setAddService, onSubmitService,
    active, onBlurHandler, errors, setIsEdit, removedSelectedEmployee, onDeleteHandler, onEditHandler, durationOptions,
    setErrors, ServiceType, setServiceType, isDelete, deleteGroupLoading, setIsDelete, loading, onEditGroup, setValues,
    reduxInvoices, values, ServiceGroup, setServiceGroup, allUinqueCurrencyList, formRef, fetchServiceLoading, groupEditLoading
  } = useService();

  // const { id, name, toggle } = values;
  // const { getDataFromLocalStorage } = useSetDataInLocalStorage();
  // const businessData = getDataFromLocalStorage("business_data");
  // const { objectKeyConvertToArray } = useObjectKeys()
  // const { validation } = useValidations();

  let navigate = useNavigate();
  const { locationDetail } = useLocation();
  const [Sharing, setSharing] = useState("Services");
  const [update, setupdate] = useState(false);
  const [ServiceDetail, setServiceDetail] = useState(false);
  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [search, setSearch] = useState("");
  const [groupSearch, setGroupSearch] = useState('')
  const [AddVendor, setAddVendor] = useState(false);
  const [ServicesGroup, setServicesGroup] = useState(false);
  const [serviceCurrentPage, setServiceCurrentPage] = useState(1);
  const [groupCurrentPage, setGroupCurrentPage] = useState(1)
  const [loader, setLoader] = useState(true)
  const [serviceNoPaginationLoader, setServiceNoPaginationLoader] = useState(true)
  const [groupLoader, setGroupLoader] = useState(true)
  const [groupNoPaginationLoader, setGroupNoPaginationLoader] = useState(true)
  const [isToastVisible, setIsToastVisible] = useState(false);
  const [details, setdetails] = useState({
    name: "",
    location: "",
    staff: "",
    price: "",
    duration: "",
    description: "",
    preduration: "",
    available: "",
    price_duration: "",
    initial_deposit: "",
  });
  const [service_group_details, setGroupDetail] = useState({});
  const searchfunction = (e) => {
    if (Sharing === "Services Group") {
      setGroupSearch(e.target.value)
    } else {
      setSearch(e.target.value);
    }
  };

  const state = useSelector((state) => state);
  const reduxServices = state?.saleService?.saleServices;
  const allReduxServices = state?.saleService?.allSaleServices;
  const pagesSaleService = state?.saleService?.page_count;
  const allCountSaleService = state?.saleService?.allCount;
  const services = state?.service?.services;
  const reduxgroup = state?.saleService?.servicesgroup;
  const allReduxgroup = state?.saleService?.allServicesgroup
  const pagesServiceGroup = state?.saleService?.pagesServiceGroup
  const allCountServiceGroup = state?.saleService?.allCountServiceGroup
  const currency = locationDetail?.currency?.code;
  const translation = state?.languages?.language_translation
  const selectedLanguage = state?.languages?.selected_language
  const isPermissionUpdated = state?.user?.isPermissionUpdated;

  const PageSize = 10
  const dispatch = useDispatch();
  const [loadingPermission, setLoadingPermission] = useState(true)
  useEffect(() => {
    setLoadingPermission(!isPermissionUpdated)
  }, [isPermissionUpdated])


  // const FilterData = () => {
  //   return state?.saleService?.saleServices.filter((itm) => {
  //     if (itm.name.toLowerCase().includes(search.toLowerCase())) {
  //       if (Sharing == "Services") {
  //         return true;
  //       }
  //     }
  //   });
  // };

  // const ServiceDetailse = (id) => {
  //   setServiceDetail(!ServiceDetail);
  //   const selectedService =
  //     reduxServices && reduxServices?.find((item) => item.id === id);
  //   setdetails({
  //     ...selectedService,
  //     name: selectedService?.name,
  //     location: selectedService?.location,
  //     staff: selectedService.employees,
  //     price: selectedService.priceservice[0].price,
  //     duration: selectedService.priceservice[0].duration,
  //     description: selectedService.description,
  //     preduration: selectedService.client_can_book,
  //     available: selectedService.slot_availible_for_online,
  //     price_duration: selectedService?.priceservice,
  //     initial_deposit: selectedService?.initial_deposit,
  //   });
  // };

  // const FilterDataGroup = () => {
  //   return state?.saleService?.servicesgroup.filter((itm) => {
  //     if (itm.name.toLowerCase().includes(search.toLowerCase())) {
  //       return true;
  //     }
  //   });
  // };

  const service_group_details_settings = (id) => {
    const selected_group = reduxgroup?.find((itm) => itm?.id === id);
    if (selected_group?.services?.length > 0) {
      setGroupDetail({
        service_name: selected_group?.name,
        service_list: selected_group?.services,
        status: selected_group?.is_active,
        image: selected_group?.image,
      });
      setServicesGroup(!ServicesGroup);
    }
  };

  let headers = [
    { label: selectedLanguage?.name == "English" ? "Name" : translation[198]?.value ? translation[198]?.value : "Name", key: "name" },
    { label: selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services", key: "services" },
  ];

  const csvReport = {
    filename: "Service Group Directory.csv",
    headers: headers,
    data: allReduxgroup.map((itm) => {
      return {
        ...itm,
        Name: itm?.name ? itm?.name : "--------",
        services: itm.services?.map((emp) => {
          return emp?.name;
        }),
      };
    }),
  };
  ///////////////////////////////////////////////////////////
  let header = [
    {
      label: selectedLanguage?.name == "English" ? "Service Name" : translation[260]?.value ? translation[260]?.value : "Service Name",
      key: "name"
    },
    {
      label: selectedLanguage?.name == "English" ? "Staff" : translation[335]?.value ? translation[335]?.value : "Staff",
      key: "staff"
    },
    {
      label: selectedLanguage?.name == "English" ? "Price" : translation[200]?.value ? translation[200]?.value : "Price",
      key: "price"
    },
    {
      label: selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location",
      key: "location"
    },
    {
      label: selectedLanguage?.name == "English" ? "Service Availible" : translation[835]?.value ? translation[835]?.value : "Service Availible",
      key: "service_availible"
    },
    {
      label: selectedLanguage?.name == "English" ? "Description" : translation[92]?.value ? translation[92]?.value : "Description",
      key: "description"
    },
    {
      label: selectedLanguage?.name == "English" ? "Duration" : translation[112]?.value ? translation[112]?.value : "Duration",
      key: "duration"
    },
    {
      label: selectedLanguage?.name == "English" ? "Create Service Group" : translation[806]?.value ? translation[806]?.value : "Create Service Group",
      key: "servicegroup"
    },
  ];
  const csvReports = {
    filename: "Service Records Catalog.csv",
    headers: header,
    data: allReduxServices?.map((itm) => {
      return {
        ...itm,
        // Id: itm?.id ? itm?.id : '--------',
        Name: itm?.name ? itm?.name : "--------",
        staff: itm.employees?.map((emp) => {
          return emp?.full_name;
        }),
        location: itm.location?.map((emp) => {
          return emp?.address_name;
        }),
        // price: itm?.price ? `${itm?.price} ${currency}` : "--------",
        price: (itm?.priceservice && itm?.priceservice?.length > 0) ? itm?.priceservice.map((t_price, index) => `${t_price?.price} ${t_price.currency_name}`) : itm?.price ? `${itm?.price} ${currency}` : "--------",
        service_availible: itm?.service_availible
          ? itm?.service_availible
          : "--------",
        description: itm?.description ? itm?.description : "--------",
        duration: itm.priceservice?.map((emp) => {
          return emp?.duration;
        }),
        servicegroup: itm?.service_group.map((itm) => {
          return itm.name;
        }),
      };
    }),
  };

  useEffect(() => {
    if (Sharing == 'Services') {
      if (search === null) {
        // Initial load with empty search
        dispatch(getSaleServices("", serviceCurrentPage, "", setLoader))
        dispatch(getSaleServicesWithNoPagination('', setServiceNoPaginationLoader))
      } else {
        if (timeOutId) {
          clearTimeout(timeOutId)
        }
        timeOutId = setTimeout(() => {
          dispatch(getSaleServices("", serviceCurrentPage, search, setLoader))
          dispatch(getSaleServicesWithNoPagination(search, setServiceNoPaginationLoader))
        }, 500);
      }
    }
  }, [serviceCurrentPage, search, Sharing])

  useEffect(() => {
    setServiceCurrentPage(1)
  }, [search])

  useEffect(() => {
    if (localStorage.getItem('get_sale_service')) {
      setServiceCurrentPage(1)
      dispatch(getSaleServicesWithNoPagination(search, setServiceNoPaginationLoader))
      dispatch(getSaleServices("", serviceCurrentPage, search, setLoader))
      setTimeout(() => {
        localStorage.removeItem('get_sale_service')
      }, 500);
    }
  }, [localStorage.getItem('get_sale_service')])

  useEffect(() => {
    if (Sharing == 'Services Group') {
      if (groupSearch === null) {
        // Initial load with empty groupSearch
        dispatch(getServiceGroup("", groupCurrentPage, "", setGroupLoader))
        dispatch(getServiceGroupWithNoPagination('', setGroupNoPaginationLoader))
      } else {
        if (groupTimeOutId) {
          clearTimeout(groupTimeOutId)
        }
        groupTimeOutId = setTimeout(() => {
          dispatch(getServiceGroup("", groupCurrentPage, groupSearch, setGroupLoader))
          dispatch(getServiceGroupWithNoPagination(groupSearch, setGroupNoPaginationLoader))
        }, 500);
      }
    }
  }, [groupCurrentPage, groupSearch, Sharing])

  useEffect(() => {
    if (Sharing == 'Services') {
      setLoader(true)
      setServiceNoPaginationLoader(true)
      setServiceCurrentPage(1)
    } else {
      setGroupLoader(true)
      setGroupNoPaginationLoader(true)
      setGroupCurrentPage(1)
    }
  }, [Sharing])

  useEffect(() => {
    if (localStorage.getItem('get_service_group')) {
      setGroupCurrentPage(1)
      dispatch(getServiceGroup("", 1, groupSearch, setGroupLoader))
      dispatch(getServiceGroupWithNoPagination(groupSearch, setGroupNoPaginationLoader))
      setTimeout(() => {
        localStorage.removeItem('get_service_group')
      }, 500);
    }
  }, [localStorage.getItem('get_service_group')])

  useEffect(() => {
    setGroupCurrentPage(1)
  }, [groupSearch])

  // useEffect(() => {
  //   dispatch(getServiceGroup());
  //   // FilterDataGroup();
  // }, []);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  const [exportLoader, setExportLoader] = useState(false);

  const handleGroupExportClick = () => {
    if (!isToastVisible) {
      if (reduxgroup?.length > 0) {
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };

  const handleExportClick = () => {
    if (!isToastVisible) {
      if (reduxServices?.length > 0) {
        // setExportLoader(true)
        setTimeout(() => {
          toast.success("File downloaded successfully", {
            toastId: "toast",
            onClose: () => setIsToastVisible(false)
          });
          setExportLoader(false)
        }, 300);
      } else {
        toast.error("No data to export", {
          toastId: "toast",
          onClose: () => setIsToastVisible(false),
        });
      }
      setIsToastVisible(true);
    }
  };


  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <p className="text-[#7B7B7B] text-sm">
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/");
            }}
          >
            {selectedLanguage?.name == "English" ? "My Account" : translation[8]?.value ? translation[8]?.value : "My Account"}
          </span>{" "}
          &gt;{" "}
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/dashboard/account/setup/");
            }}
          >
            {selectedLanguage?.name == "English" ? "Account Setup" : translation[645]?.value ? translation[645]?.value : "Account Setup"}
          </span>{" "}
          &gt;{" "}
          <span className="text-primary font-semibold">
            {selectedLanguage?.name == "English" ? "Service Management" : translation[660]?.value ? translation[660]?.value : "Service Management"}
          </span>
        </p>
        <div className="flex items-center justify-between gap-4 flex-wrap">
          <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Service Management" : translation[660]?.value ? translation[660]?.value : "Service Management"}
            </h2>
          </div>
          {loadingPermission ?
            <EmployeesHeadShimmer classOn1={'!w-32 !lg:w-44 !sm:w-40'} classOn2={'!w-32 !lg:w-44 !sm:w-40'} />
            :
            <div className="flex items-center gap-3">
              {!employee ||
                (isEdit &&
                  employee &&
                  employeePermissions?.account_service_management?.includes(
                    "edit"
                  )) ? (
                <>
                  <AddService
                    formRef={formRef}
                    setServiceGroup={setServiceGroup}
                    ServiceType={ServiceType}
                    setServiceType={setServiceType}
                    serviceList={services}
                    serviceData={serviceData}
                    isEdit={isEdit}
                    setService={setService}
                    serviceHandleChange={serviceHandleChange}
                    onSubmitService={onSubmitService}
                    active={active}
                    onBlurHandler={onBlurHandler}
                    errors={errors}
                    removedSelectedEmployee={removedSelectedEmployee}
                    serviceModal={serviceModal}
                    setAddService={setAddService}
                    reduxServices={reduxServices}
                    durationOptions={durationOptions}
                    setIsEdit={setIsEdit}
                    setErrors={setErrors}
                    setIsDelete={setIsDelete}
                    loading={loading}
                    isLoading={fetchServiceLoading}
                    allUinqueCurrencyList={allUinqueCurrencyList}
                    invoiceLanguage={reduxInvoices}
                    successAddServiceFromServiceManagmnet={() => { dispatch(getEmployeeList()); }}
                  />
                  {!employee && (
                    <LoginBtn
                      onClick={() => {
                        setServiceGroup(!ServiceGroup);
                        setupdate(false);
                      }}
                    >
                      <div className="flex items-center gap-2">
                        <Svgs.Plus />
                        <p>
                          {selectedLanguage?.name == "English" ? "Create Service Group" : translation[806]?.value ? translation[806]?.value : "Create Service Group"}
                        </p>
                      </div>
                    </LoginBtn>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          }
        </div>

        <div className="border-b mt-[3rem]">
          <div className="flex items-center gap-6">
            <div className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${Sharing == "Services" && "font-semibold border-primary"
              }`}
              onClick={() => {
                setSharing("Services");
                setSearch('');
                setGroupSearch('')
              }}
            >
              <h2 className="text-[#595A5A]">
                {selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"}
              </h2>
            </div>
            <div
              className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${Sharing == "Services Group" && "font-semibold border-primary"
                }`}
              onClick={() => {
                setSharing("Services Group");
                setSearch('');
                setGroupSearch('')
              }}
            >
              <h2 className="text-primary">
                {selectedLanguage?.name == "English" ? "Services Group" : translation[809]?.value ? translation[809]?.value : "Services Group"}
              </h2>
            </div>
          </div>
        </div>
        <div className="mt-[3rem]">
          <div className="border rounded-lg">
            <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b flex-row gap-3">
              <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                <div className="flex items-center lg:gap-4 gap-3">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold text-xl">
                      {Sharing === "Services" ?
                        selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"
                        :
                        selectedLanguage?.name == "English" ? "Services Group" : translation[809]?.value ? translation[809]?.value : "Services Group"
                      }

                    </h2>
                    {(Sharing === 'Services' ? loader : groupLoader) ?
                      <div className="bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap animate-pulse">
                        <p className="rounded-md h-5 w-12 mx-1"></p>
                      </div>
                      :
                      <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                        <p className="text-primary">
                          {Sharing === 'Services'
                            ? allCountSaleService ?
                              allCountSaleService : 0
                            : allCountServiceGroup ? allCountServiceGroup : 0}
                          {" "}
                          {selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"}</p>
                      </div>
                    }
                  </div>
                </div>
                <div className="mb-00">
                  {loadingPermission ?
                    <Shimmer className={'w-44 lg:w-48'}>
                      <div className="h-8 w-full  bg-gray-200 rounded-lg" />
                    </Shimmer> :
                    <LoginInput
                      title=""
                      placeholder={Sharing === "Services Group" ?
                        selectedLanguage?.name == "English" ? "Search service group" : translation[1277]?.value ? translation[1277]?.value : "Search service group"
                        : selectedLanguage?.name == "English" ? "Search Service" : translation[1266]?.value ? translation[1266]?.value : "Search Service"}
                      padding="py-1"
                      leftIcon={<Svgs.Search />}
                      value={Sharing === "Services Group" ? groupSearch : search}
                      onChange={searchfunction}
                    />
                  }
                </div>
              </div>
              {loadingPermission ?
                <Shimmer className={'w-12 lg:w-16'}>
                  <div className="h-8 w-full bg-gray-200 rounded-lg" />
                </Shimmer> :
                exportLoader ? (
                  <div className="h-[4rem] flex items-center justify-center" >
                    <PreloaderSm />
                  </div>
                ) :
                  !employee ? (
                    <div className="flex items-center  justify-end gap-3 w-fit h-[2.3rem] ">
                      {/* { Sharing=="Services Group"?
                               "":
                               <LoginBtn>
                               <Svgs.Import fill='white' />
                           </LoginBtn>
                                } */}
                      {Sharing === "Services Group" ? (
                        reduxgroup?.length > 0 ?
                          <CSVLink
                            {...csvReport}
                            target="_blank"
                            onClick={() => {
                              setAddVendor(!AddVendor);
                              handleGroupExportClick()
                            }}
                          >
                            <IconButton filled>
                              <Svgs.Export />
                            </IconButton>
                          </CSVLink> :
                          <LoginBtn
                            className="rounded-lg border-2 border-primary"
                            bg="bg-white"
                            animation={false}
                            onClick={handleGroupExportClick}
                          >
                            <div className="flex items-center gap-2 text-[#FFFFFF]">
                              <Svgs.Export bg="#FFFFFF" />
                            </div>
                          </LoginBtn>) : (
                        reduxServices?.length > 0 ?
                          <CSVLink
                            {...csvReports}
                            target="_blank"
                            onClick={() => {
                              setAddVendor(!AddVendor);
                              handleExportClick()
                            }}
                          >
                            <IconButton filled>
                              <Svgs.Export />
                            </IconButton>
                          </CSVLink> :
                          <LoginBtn
                            className="rounded-lg border-2 border-primary"
                            bg="bg-white"
                            animation={false}
                            onClick={handleExportClick}
                          >
                            <div className="flex items-center gap-2 text-[#FFFFFF]">
                              <Svgs.Export bg="#FFFFFF" />
                            </div>
                          </LoginBtn>)}
                    </div>
                  ) : (
                    ""
                  )}
            </div>
            {Sharing === "Services" && (
              loader ?
                <TableShimer cols={6} />
                :
                <div className="overflow-x-auto">
                  {reduxServices?.length > 0 &&
                    <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(140px,1fr))] space-x-2 px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                      <p>{selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"}</p>
                      <p>{selectedLanguage?.name == "English" ? "Service Group" : translation[262]?.value ? translation[262]?.value : "Service Group"}</p>
                      <p>{selectedLanguage?.name == "English" ? "Location" : translation[19]?.value ? translation[19]?.value : "Location"}</p>
                      <p>{selectedLanguage?.name == "English" ? "Staff" : translation[335]?.value ? translation[335]?.value : "Staff"}</p>
                      <p>{selectedLanguage?.name == "English" ? "Pricing" : translation[269]?.value ? translation[269]?.value : "Pricing"}</p>
                      <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>
                    </div>
                  }
                  {reduxServices?.length > 0 ?
                    reduxServices?.map((item, index) => {
                      return (

                        <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(140px,1fr))] space-x-2 min-w-full w-fit odd-bg text-[#3A3A3A] transition-all border-l-[0px]">
                          <div
                            className="flex items-center cursor-pointer"
                          // onClick={() => {
                          //   onEditHandler(item.id);
                          //   // ServiceDetailse(item.id);
                          // }}
                          >
                            <div className="flex items-center gap-4">
                              {item.image ?
                                <div className="flex items-center justify-center h-[3rem] w-[3rem] overflow-hidden rounded-full bg-gray-200 ">
                                  <img src={item?.image} className="w-full h-full" />
                                </div>
                                :
                                <div className="h-[3rem] w-[3rem] bg-primary rounded-full flex items-center justify-center">
                                  <Svgs.NewService fill="white" />
                                </div>
                              }
                              <p className="text-sm flex-1 capitalize">{item.name}</p>
                            </div>
                          </div>
                          <div className="flex items-center cursor-pointer">
                            {item?.service_group[0]?.name && (
                              <p className="text-sm capitalize">
                                {item?.service_group[0]?.name}
                              </p>
                            )}
                          </div>
                          <div className="flex items-center cursor-pointer">
                            {item?.location?.length > 0 && (
                              <p className="text-sm capitalize">
                                {item?.location[0]?.address_name}{" "}
                                {item?.location?.length > 1
                                  ? ` +${item?.location?.length - 1}`
                                  : ""}
                              </p>
                            )}
                          </div>
                          <div className="flex items-center cursor-pointer">
                            <ProfileStack data={item?.employees} textFontSize={'text-sm'} />
                            {/* {item?.employees?.length > 0 &&
                                item?.employees?.map((itm, ind) => {
                                  return (
                                    ind < 1 && (
                                      <p className="text-sm ">{itm?.full_name}</p>
                                    )
                                  );
                                })}
                              {item?.employees?.length > 1
                                ? ` +${item?.employees?.length - 1}`
                                : ""} */}
                          </div>
                          <div className="flex items-center cursor-pointer">
                            <p className="text-sm ">{item.price}</p>
                          </div>
                          <div className="flex items-center gap-3">
                            {!employee ||
                              (employee &&
                                employeePermissions?.account_service_management?.includes(
                                  "edit"
                                )) ? (
                              <div
                                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center"
                                onClick={() => {
                                  onEditHandler(item?.id);
                                }}
                              >
                                <Svgs.Edit fill="#7D7D7D" />
                              </div>
                            ) : (
                              ""
                            )}

                            {(!employee && reduxServices?.length != 1) ? (
                              <div
                                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                                onClick={() => {
                                  onDeleteGroup(item?.id);
                                }}
                              >
                                <Svgs.Delete />
                              </div>
                            ) : employee &&
                              employeePermissions?.account_service_management?.includes(
                                "edit"
                              ) ? (
                              ""
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })
                    : <NoServiceFound />
                  }

                  {(reduxServices?.length > 0 && !loader) &&
                    <Pagination
                      currentPage={serviceCurrentPage}
                      totalCount={Math.ceil(pagesSaleService)}
                      pageSize={PageSize}
                      onPageChange={page => setServiceCurrentPage(page)}
                    />
                  }
                </div>
            )}

            {Sharing == "Services Group" && (
              groupLoader ?
                <TableShimmer_v2 cols={4} />
                :
                <div className="overflow-x-auto">
                  <div className="inline-grid xl:grid grid-cols-[repeat(6,minmax(140px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit gap-1">
                    <p>{selectedLanguage?.name == "English" ? "Group Name" : translation[546]?.value ? translation[546]?.value : "Group Name"}</p>
                    <p className="col-span-3">{selectedLanguage?.name == "English" ? "Services" : translation[180]?.value ? translation[180]?.value : "Services"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
                    <p>{selectedLanguage?.name == "English" ? "Actions" : translation[551]?.value ? translation[551]?.value : "Actions"}</p>

                  </div>
                  {reduxgroup?.length > 0 ?
                    reduxgroup?.map((val, index) => {
                      return (
                        <div className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(6,minmax(140px,1fr))] min-w-full w-fit gap-1 odd-bg text-[#3A3A3A] transition-a border-l-[0px]">
                          <div
                            className="flex items-center cursor-pointer"
                          // onClick={() => {
                          //   setServicesGroup(!ServicesGroup);
                          // }}
                          >
                            <div className="flex items-center gap-4">
                              {val.image ?
                                <div className="h-[2.3rem] w-[2.3rem] bg-primary rounded-full flex items-center justify-center">
                                  <img src={val?.image} className="rounded-full h-full object-cover" />
                                </div>
                                :
                                <div className="h-[2.3rem] w-[2.3rem] bg-primary rounded-full flex items-center justify-center">
                                  <Svgs.NewService size={"25"} fill="white" />
                                </div>
                              }
                              <p
                                className="text-sm capitalize"
                                onClick={() => {
                                  service_group_details_settings(val?.id)
                                }
                                }
                              >
                                {val.name}
                              </p>
                              <div></div>
                            </div>
                          </div>
                          <div
                            className="flex items-center cursor-pointer flex-wrap col-span-3"

                          >
                            {val?.services?.length > 0 ? (
                              <div className="text-[rgba(85, 85, 85, 1)] text-xs font-medium flex items-center gap-[0.625rem] flex-wrap" onClick={() => {
                                service_group_details_settings(val?.id)
                              }
                              }>
                                <div className="px-3 py-[0.625rem] border-[rgba(216, 209, 209, 1)] border-[0.33px] rounded-[0.2469rem] w-fit max-w-[7.5rem] truncate">
                                  {val.services[0]?.name}
                                </div>
                                {val?.services?.length > 1 ?
                                  <div className="px-3 py-[0.625rem] border-[rgba(216, 209, 209, 1)] border-[0.33px] rounded-[0.2469rem] w-fit max-w-[7.5rem] truncate">
                                    {val.services[1]?.name}
                                  </div>
                                  : ""}
                                {val?.services?.length > 2 ?
                                  <div div className="px-3 py-[0.625rem] border-[rgba(216, 209, 209, 1)] border-[0.33px] rounded-[0.2469rem] w-fit max-w-[7.5rem] truncate">
                                    {val.services[2]?.name}
                                  </div>
                                  : ""}
                                {
                                  val?.services?.length > 3 &&
                                  <div className="flex justify-center items-center rounded-full bg-[#DDE4FF] text-[#5679FF] w-9 h-9">
                                    +{val?.services?.length - 3}
                                  </div>
                                }
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="flex items-center cursor-pointer">
                            <StatusBtn
                              status={val?.is_active ? "active" : "inactive"}
                              text={
                                val?.is_active
                                  ? selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                                  : selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
                              }
                              size="sm"
                            />
                          </div>
                          <div className="flex items-center gap-3">
                            {!employee ||
                              (employee &&
                                employeePermissions?.account_service_management?.includes(
                                  "edit"
                                )) ? (
                              <div
                                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                                onClick={() => {
                                  // setServiceGroup(!ServiceGroup);
                                  setupdate(true);
                                  onEditGroup(val?.id);
                                }}
                              >
                                <Svgs.Edit fill="#7D7D7D" />
                              </div>
                            ) : (
                              ""
                            )}
                            {!employee ? (
                              <div
                                className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center"
                                onClick={() => {
                                  if (val?.services?.length > 0) {
                                    toast.error("Service group cannot be deleted until services are assigned to this group", { toastId: 'toast' })
                                  } else {
                                    onDeleteGroup(val?.id);
                                  }
                                }}
                              >
                                <Svgs.Delete />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    })
                    : <NoStaffGroupFound />
                  }

                  {(reduxgroup?.length > 0 && !groupLoader) &&
                    <Pagination
                      currentPage={groupCurrentPage}
                      totalCount={Math.ceil(pagesServiceGroup)}
                      pageSize={PageSize}
                      onPageChange={page => setGroupCurrentPage(page)}
                    />
                  }
                </div>
            )}
          </div>
        </div>
      </div>

      {ServiceDetail && (
        <Popup
          heading={`Services Details`}
          size="lg"
          close={setServiceDetail}
          open={ServiceDetail}
        >
          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between gap-4 flex-wrap">
              <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
                <CircleIcon>
                  <Svgs.NewService />
                </CircleIcon>
                <div className="flex flex-col gap-2 lg:text-left text-center">
                  <h2 className="text-primary font-bold text-xl">
                    {details.name}
                  </h2>
                  <p className="text-[#7B7B7B] text-xs">
                    Service Type{" "}
                    <span className="text-primary font-semibold">
                      {details.name}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-xs text-[#7B7B7B]">Price</p>
                <h1 className="text-xl font-bold text-primary">
                  {currency}
                  {details?.price_duration?.length > 1
                    ? `  ${details?.price_duration[0]?.price}  -  ${details?.price_duration[
                      details?.price_duration?.length - 1
                    ]?.price
                    }`
                    : details?.price_duration?.length != 0 &&
                    details?.price_duration[0]?.price}
                </h1>
              </div>
            </div>
            <hr />
            <div className="flex flex-col gap-2">
              <h1 className="text-primary font-bold">Location</h1>
              <div className="flex items-center gap-2 flex-wrap">
                {details.location?.map((itm) => {
                  return (
                    <SelectedOption
                      name={itm.address_name}
                      rounded="full"
                      showDelete={false}
                    />
                  );
                })}
              </div>
            </div>
            <hr />
            <div className="flex flex-col gap-2">
              <h1 className="text-primary font-bold">Staff</h1>
              <div className="flex items-center gap-2 flex-wrap">
                {details?.staff?.map((itm) => {
                  return (
                    <SelectedOption
                      img={`${itm?.image
                        ? itm?.image
                        : `${process.env.PUBLIC_URL}/images/profile_img.svg`
                        }`}
                      name={itm?.full_name}
                      rounded="md"
                      showDelete={false}
                    />
                  );
                })}
              </div>
            </div>
            <hr />
            <div className="grid grid-cols-4 gap-4">
              <div className="flex flex-col gap-1">
                <p className="text-[#A1A1A1] text-xs">Duration</p>
                <p className="text-primary font-semibold text-sm">
                  {details?.price_duration?.length > 1
                    ? `  ${details?.price_duration[0]?.duration.replaceAll(
                      "_",
                      " "
                    )}  -  ${details?.price_duration[
                      details?.price_duration?.length - 1
                    ]?.duration.replaceAll("_", " ")}`
                    : details?.price_duration?.length != 0 &&
                    details?.price_duration[0]?.duration.replaceAll("_", " ")}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-[#A1A1A1] text-xs">Timeline</p>
                <p className="text-primary font-semibold text-sm">
                  {details?.available?.replaceAll("_", " ")
                    ? details?.available?.replaceAll("_", " ")
                    : "----"}
                </p>
              </div>
              <div className="flex flex-col gap-1 col-span-2">
                <p className="text-[#A1A1A1] text-xs">Appointment</p>
                <p className="text-primary font-semibold text-sm">
                  {details?.preduration?.replaceAll("_", " ")
                    ? details?.preduration?.replaceAll("_", " ")
                    : "----"}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-[#A1A1A1] text-xs">Initial Deposit</p>
                <p className="text-primary font-semibold text-sm">
                  {currency} {details?.initial_deposit}
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-[#A1A1A1] text-xs">Service Available</p>
                <p className="text-primary font-semibold text-sm">
                  {details?.service_availible
                    ? details?.service_availible
                    : "----"}
                </p>
              </div>
            </div>
            <hr />
            <div className="flex flex-col gap-1">
              <p className="text-[#A1A1A1] text-xs">Description</p>
              <p className="text-primary font-semibold text-sm">
                {details.description ? details.description : "----"}
              </p>
            </div>
            {/* <div className="flex items-center gap-3">
                <div className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center">
                  <Svgs.Edit fill="#7D7D7D" />
                </div>
                <div className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center cursor-pointer justify-center">
                  <Svgs.Delete />
                </div>
              </div> */}
          </div>
        </Popup>
      )
      }

      {
        ServiceGroup && (
          <AddServiceGroup
            onClose={() => {
              setServiceGroup(false);
              setValues({ name: "", service: [], status: true });
            }}
            editValues={values}
            open={ServiceGroup}
            update={update}
            addSuccess={() => {
              setGroupCurrentPage(1)
              dispatch(getServiceGroup("", 1, groupSearch, setGroupLoader))
              dispatch(getServiceGroupWithNoPagination(groupSearch, setGroupNoPaginationLoader))
              dispatch(getServiceGroupPaginationDropdown(1, "", setGroupNoPaginationLoader))
            }}
            isLoading={groupEditLoading}
            updateSuccess={() => {
              dispatch(getSaleServices());
              localStorage.setItem('get_service_group', true)
              setAddService(false);
              setIsEdit(false);
              setAddService(false);
              setServiceGroup(false);
              setGroupCurrentPage(1)
              dispatch(getServiceGroup("", 1, groupSearch, setGroupLoader))
              dispatch(getServiceGroupWithNoPagination(groupSearch, setGroupNoPaginationLoader))
              dispatch(getServiceGroupPaginationDropdown(1, "", setGroupNoPaginationLoader))
            }}
          />
        )
      }

      {
        Sharing == "Services"
          ? (
            <>
              {isDelete && (
                <Popup
                  heading={selectedLanguage?.name == "English"
                    ? "Are you sure you want to delete this Service?"
                    : translation[652]?.value
                      ? translation[652]?.value
                      : "Are you sure you want to delete this Service?"
                  }
                  onclose={() => setIsDelete(false)}
                >
                  <div className="">
                    <p>
                      {selectedLanguage?.name == "English"
                        ? "Are you sure you want to delete this sale service? You won't be able to see this again."
                        : translation[812]?.value
                          ? translation[812]?.value
                          : "Are you sure you want to delete this sale service? You won't be able to see this again."
                      }

                    </p>
                    <div className="flex items-center justify-end mt-[2rem]">
                      <LoginBtn
                        // loading={deleteGroupLoading}
                        // disabled={deleteGroupLoading}
                        deleteBtn
                        text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                        onClick={() => {
                          onDeleteHandler();
                          // onDeleteGroup();
                        }}
                      />
                    </div>
                  </div>
                </Popup>
              )}
            </>
          )
          : (<>
            {isDelete && (

              <Popup
                heading={selectedLanguage?.name == "English"
                  ? "Are you sure you want to delete this Service?"
                  : translation[652]?.value
                    ? translation[652]?.value
                    : "Are you sure you want to delete this Service?"
                }
                onclose={() => setIsDelete(false)}
              >
                <div className="">
                  <p>
                    {selectedLanguage?.name == "English"
                      ? "Are you sure you want to delete this sale service? You won't be able to see this again."
                      : translation[812]?.value
                        ? translation[812]?.value
                        : "Are you sure you want to delete this sale service? You won't be able to see this again."
                    }

                  </p>
                  <div className="flex items-center justify-end mt-[2rem]">
                    <LoginBtn
                      loading={deleteGroupLoading}
                      disabled={deleteGroupLoading}
                      deleteBtn
                      text={selectedLanguage?.name == "English" ? "Yes! Delete" : translation[426]?.value ? translation[426]?.value : "Yes! Delete"}
                      onClick={() => {
                        // onDeleteHandler();
                        onDeleteGroup();
                      }}
                    />
                  </div>
                </div>
              </Popup>
            )}
          </>
          )
      }

      {
        <Popup
          heading={"Service Group Details"}
          size="md"
          open={ServicesGroup}
          close={setServicesGroup}
        >
          <div className="flex flex-col gap-6">
            <div className="flex items-center sm:gap-4 gap-2 lg:flex-row flex-col">
              {/* <CircleIcon>
                <Svgs.NewService />
              </CircleIcon> */}
              {service_group_details.image ?
                <div className="h-[2.3rem] w-[2.3rem] bg-primary rounded-full flex items-center justify-center">
                  <img src={service_group_details?.image} className="rounded-full h-full object-cover" />
                </div>
                :
                <div className="h-[3rem] w-[3rem] bg-primary rounded-full flex items-center justify-center">
                  <Svgs.NewService fill="white" />
                </div>
              }
              <div className="flex flex-col gap-1 lg:text-left text-center">
                <h2 className="text-primary font-semibold text-xl">
                  {service_group_details?.service_name}
                </h2>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <h1>Services</h1>
              <div className="flex items-center flex-wrap gap-4">
                {service_group_details?.service_list?.map((itm) => {
                  return (
                    <LoginBtn
                      text={itm?.name}
                    />
                  );
                })}
              </div>
            </div>
            <div>
              {selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}
              : {service_group_details?.status ?
                selectedLanguage?.name == "English" ? "Active" : translation[459]?.value ? translation[459]?.value : "Active"
                : selectedLanguage?.name == "English" ? "InActive" : translation[460]?.value ? translation[460]?.value : "InActive"
              }
            </div>
          </div>
        </Popup>
      }
    </>
  );
};

export default ServiceManagement;
