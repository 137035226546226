
export default function useObjectKeys() {
    const objectKeyConvertToArray = (object) => {
        return Object.keys(object);
    }

    return {
        objectKeyConvertToArray,
    }
}
