import axios from "axios";
import { getPayroll, getPayrollWithoutPagination, getUpSellDownSell, getUpSellDownSellStore } from "../../../Redux/TenantRedux/Actions/payrollActions";
import { BASE_URL, get_payrool_url, delete_payrool_url, import_payrool_url, get_employee_working_payroll, get_employee_upsell_downsell_url, get_up_and_down_sale_store_report } from "../api_variables";
import { PAYROLL } from "Redux/TenantRedux/ActionTypes/payrollTypes";
import { buildQueryString } from "Constants/Functions/buildQueryString";

export const getPayrolls = () => async (dispatch) => {
    try {
        const response = await axios.get(`${BASE_URL}${get_payrool_url}`);
        if (response.status === 200) {
            let payrolls = response?.data?.response?.payroll;
            dispatch(getPayroll(payrolls));
        }
    } catch (error) {
        return error;
    }
};


export const getsPayrollImport = async (form) => {
    let token = localStorage.getItem('access_token')
    if (token) {
        token = token.replaceAll('"', '')
    }
    fetch(`${BASE_URL}${import_payrool_url}`,
        {
            method: "POST",
            headers: {
                Authorization: `Token ${token}`
            },
            body: form
        }).then((response => {
            return (
                response.json()
            )
        })).then((result) => {
        })

};


export const deletePayroll = async (payroll_id, access_token) => {
    try {
        let result = await fetch(`${BASE_URL}${delete_payrool_url}`, {
            method: "DELETE",
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Token ${access_token}`
            },
            body: JSON.stringify(
                { payroll_id: payroll_id }
            )
        })
        result = result.json();
        return result;
    } catch (error) {
        return error;
    }

}



export const getEmployeeWorkingPayroll = (currentPage, selected_location, startDate, endDate, employeeId, setLoader) => async (dispatch) => {
    setLoader && setLoader(true)
    let start_date = startDate ? `&start_date=${startDate}` : ""
    let end_date = endDate ? `&end_date=${endDate}` : ""
    const employee = employeeId ? `&employee_id=${employeeId}` : ''
    try {
        const response = await axios.get(`${BASE_URL}${get_employee_working_payroll}?page=${currentPage}&location=${selected_location}${start_date}${end_date}${employee}`);
        if (response.status === 200) {
            let payrolls = response?.data?.response?.employees;
            let pages = response?.data?.response?.pages
            dispatch(getPayroll(payrolls));
            dispatch({
                type: PAYROLL.GET_PAYROLL_PAGES,
                payload: pages,
            })
            setLoader && setLoader(false)
        }
        setLoader && setLoader(false)
    } catch (error) {
        setLoader && setLoader(false)
        return error;
    }
};


export const getEmployeeUpsellDownsellReport = (data, success, fail) => async (dispatch) => {
    const query = buildQueryString(data)
    try {
        const response = await axios.get(`${BASE_URL}${get_employee_upsell_downsell_url}${query}`);
        if (response.status === 200) {
            let upsellDownSell = response?.data?.response;
            dispatch(getUpSellDownSell(upsellDownSell));
            success && success()
        }
        fail && fail()
    } catch (error) {
        fail && fail()
        return error;
    }
};

export const getUpAndDownSaleStoreReport = (data, success, fail) => async (dispatch) => {
    const query = buildQueryString(data)
    try {
        const response = await axios.get(`${BASE_URL}${get_up_and_down_sale_store_report}${query}`);
        if (response.status === 200) {
            let getUpSellDownSellStoreData = response?.data?.response?.data;
            dispatch(getUpSellDownSellStore(getUpSellDownSellStoreData));
            success && success()
        }
        fail && fail()
    } catch (error) {
        fail && fail()
        return error;
    }
};

export const getEmployeeWorkingPayrollWithNoPagination = (selected_location, startDate, endDate, employeeId, setNoPaginationLoader) => async (dispatch) => {
    setNoPaginationLoader && setNoPaginationLoader(true)
    let start_date = startDate ? `&start_date=${startDate}` : ""
    let end_date = endDate ? `&end_date=${endDate}` : ""
    const employee = employeeId ? `&employee_id=${employeeId}` : ''
    try {
        const response = await axios.get(`${BASE_URL}${get_employee_working_payroll}?no_pagination=true&location=${selected_location}${start_date}${end_date}${employee}`);
        if (response.status === 200) {
            let payrolls = response?.data?.response?.employees;
            dispatch(getPayrollWithoutPagination(payrolls));
            setNoPaginationLoader && setNoPaginationLoader(false)
        }
        setNoPaginationLoader && setNoPaginationLoader(false)
    } catch (error) {
        setNoPaginationLoader && setNoPaginationLoader(false)
        return error;
    }
};



