import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getsProducts } from "../../../../Adapters/Api/Products";
import Svgs from "../../../../Assets/svgs";
import { getProducts } from "../../../../Redux/TenantRedux/Actions/product";
import SupportCard from "./Elements/SupportCard";
import PromotionSection from "./PromotionSection";
import RewardSection from "./RewardSection";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";

const Promotions = () => {
  const navigate = useNavigate();
  const [Sharing, setSharing] = useState("reward");
  const state = useSelector((state) => state);

  const [employee, setEmployee] = useState();
  const [employeePermissions, setEmployeePermissions] = useState([]);

  useEffect(() => {
    setEmployee(state?.user?.user?.employee);
    setEmployeePermissions(state?.user?.permissions);
  }, [state?.user]);

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <Breadcrumb url={"/dashboard/clients/"} first={'Clients'} second={'Rewards / Promotions'} />

        <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">
          
          <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
            Rewards / Promotions
          </h2>
        </div>

        <div className="border-b mt-[3rem]">
          <div className="flex items-center gap-6">
            <div
              className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${Sharing == "reward" && "font-semibold border-primary"
                }`}
              onClick={() => {
                setSharing("reward");
              }}
            >
              <h2 className="text-[#101928]">Rewards</h2>
            </div>
            <div
              className={`cursor-pointer pb-3 border-b-[3px] border-transparent ${Sharing == "promotions" && "font-semibold border-primary"
                }`}
              onClick={() => {
                setSharing("promotions");
              }}
            >
              <h2 className="text-[#595A5A]">Promotions</h2>
            </div>
          </div>
        </div>
        <div className="mt-[3rem]">
          <div className="md:grid grid-cols-6">
            {Sharing == "reward" && <RewardSection />}

            {Sharing == "promotions" && <PromotionSection />}

            {!employee ? (
              <div className="col-span-2">
                <div>
                  <SupportCard />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Promotions;
