import Svgs from "Assets/svgs";
import LoginBtn from "Components/TenantApp/Elements/LoginBtn";
import LoginInput from "Components/TenantApp/Elements/LoginInput";
import ProfileStack from "Components/TenantApp/Elements/ProfileStack";
import StatusBtn from "Components/TenantApp/Elements/StatusBtn";
import Breadcrumb from "new-components/breadcrumbs/Breadcrumb";
import React from "react";
import useSegments from "./helper";
import AddSegmentaion from "./addSegmentation";
import AddFilter from "./addFilter";
import DetailSegment from "./DetailSegment";
import NotFound from "Components/Utility/NotFound";
import TableShimer from "Components/Utility/Shimmers/TableShimer";
import DeleteConfirmation from "new-components/delete-popup";
import Pagination from "new-components/Pagination";

const CustomersSegmentation = () => {
  const {
    loading, getSegmentDetail,
    FilterPopup, setFilterPopup,
    CreateS, setCreateS,
    segment, segmentDetail,
    deleteSegment, setIsEdit,
    deletePopup, isEdit, deleteLoading,
    ViewDetails, setViewDetails,
    currentPage, setCurrentPage, segmentPagination,
    setDeletePopup, clearFilter,
    setApiCall, searchSegment, setSearchSegment,
    onFilterChange, selectedLanguage, translation, filterData, onSubmit,
  } = useSegments()

  return (
    <>
      <div className="lg:px-[2rem] lg:py-[2.5rem] py-[1.3rem] px-[1rem] min-h-full bounce-in-right">
        <Breadcrumb
          first={selectedLanguage?.name == "English" ? "CRM" : translation[1354]?.value ? translation[1354]?.value : "CRM"}
          url={"/dashboard/crm/"}
          second={selectedLanguage?.name == "English" ? "Customers Segmentation" : translation[1355]?.value ? translation[1355]?.value : "Customers Segmentation"}
        />

        <div className="flex items-center gap-3 justify-between">
          <div className="flex items-center sm:gap-4 gap-2 my-[1rem]">
            <h2 className="text-primary font-bold lg:text-3xl text-xl cursor-pointer">
              {selectedLanguage?.name == "English" ? "Customers Segmentation" : translation[1355]?.value ? translation[1355]?.value : "Customers Segmentation"}
            </h2>
          </div>
          <LoginBtn
            onClick={() => { setCreateS(!CreateS); }}
            text={
              <div className="flex items-center gap-3">
                <div>
                  <Svgs.Plus />
                </div>
                <div>
                  <p>{selectedLanguage?.name == "English" ? "Create segment" : translation[1403]?.value ? translation[1403]?.value : "Create segment"}</p>
                </div>
              </div>
            }
          />
        </div>

        <div className="">
          <div className="border rounded-lg">
            <div className="px-4 lg:px-6 py-2 flex items-center justify-between border-b lg:flex-row flex-col gap-3">
              <div className="flex items-center justify-between flex-1 w-full md:gap-0 lg:gap-0 gap-2">
                <div className="flex items-center lg:gap-4 gap-3">
                  <div className="flex items-center sm:gap-4 gap-2 ">
                    <h2 className="font-semibold text-xl">{selectedLanguage?.name == "English" ? "Segments" : translation[1402]?.value ? translation[1402]?.value : "Segments"}</h2>
                    <div className="p-2 bg-[#E4EAF0] px-4 py-2 rounded-2xl text-xs font-semibold whitespace-nowrap">
                      {loading
                        ? <div className="h-4 w-12" />
                        : <p className="text-primary">{segmentPagination?.allCount ? segmentPagination?.allCount : 0} {selectedLanguage?.name == "English" ? "Records" : translation[497]?.value ? translation[497]?.value : "Records"} </p>
                      }
                    </div>
                  </div>
                </div>
                <div className="mb-00">
                  <LoginInput
                    title=""
                    value={searchSegment}
                    onChange={(e) => setSearchSegment(e.target.value)}
                    name={"search_text"}
                    placeholder={selectedLanguage?.name == "English" ? "Search" : translation[135]?.value ? translation[135]?.value : "Search"}
                    padding="py-1"
                    leftIcon={<Svgs.Search />}
                  />
                </div>
              </div>
              <div className="flex items-center lg:justify-center justify-end gap-3 lg:w-fit w-full">
                <LoginBtn
                  borderBtn
                  onClick={() => {
                    setFilterPopup(!FilterPopup);
                  }}
                >
                  <div className="flex items-center gap-2 text-primary">
                    <Svgs.Filters />
                    <p>{selectedLanguage?.name == "English" ? "Filters" : translation[341]?.value ? translation[341]?.value : "Filters"}</p>
                  </div>
                </LoginBtn>
                {/* <IconButton>
                  <Svgs.Import />
                </IconButton>
                <IconButton filled>
                  <Svgs.Export />
                </IconButton> */}
              </div>
            </div>

            {loading ?
              <TableShimer cols={5} rows={11} />
              :
              <div className="overflow-x-auto">
                <div className="inline-grid xl:grid grid-cols-[repeat(5,minmax(115px,1fr))] px-12 py-3 bg-[#FBFCFF] text-sm font-semibold min-w-full w-fit">
                  <p>{selectedLanguage?.name == "English" ? "Segment Name" : translation[1404]?.value ? translation[1404]?.value : "Segment Name"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Customers" : translation[1405]?.value ? translation[1405]?.value : "Customers"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Type" : translation[201]?.value ? translation[201]?.value : "Type"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Status" : translation[454]?.value ? translation[454]?.value : "Status"}</p>
                  <p>{selectedLanguage?.name == "English" ? "Action" : translation[1366]?.value ? translation[1366]?.value : "Action"}</p>
                </div>
                <div className={`odd-bg text-[#3A3A3A] transition-all border-l-[0px]`}>
                  {/* --------- Customer Segmentations ---------- */}
                  {segment?.length > 0
                    ? segment?.map((segmnt, i) => {
                      return (
                        <div key={i} className="px-12 py-3 inline-grid xl:grid grid-cols-[repeat(5,minmax(115px,1fr))] min-w-full w-fit">
                          <div
                            onClick={() => getSegmentDetail(segmnt, 'detail')}
                            className="cursor-pointer h-full flex items-center gap-3">
                            <p className="text-sm text-primary">{segmnt?.name}</p>
                          </div>
                          <div className="flex items-center">
                            <ProfileStack data={segmnt?.client_data} />
                          </div>

                          <div className="flex items-center capitalize">
                            <p className="text-sm">{segmnt?.segment_type}</p>
                          </div>
                          <div className="flex items-center">
                            <StatusBtn status={segmnt?.is_active ? "Active" : "InActive"} />
                          </div>
                          <div className="flex items-center gap-3">
                            <div
                              onClick={() =>
                                getSegmentDetail(segmnt, 'edit')
                              }
                              className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center">
                              <Svgs.Edit fill="#7D7D7D" />
                            </div>
                            <div
                              onClick={() => getSegmentDetail(segmnt, 'delete')}
                              className="h-[2.5rem] w-[2.5rem] border rounded-full flex items-center  cursor-pointer justify-center">
                              <Svgs.Delete fill="#7D7D7D" />
                            </div>
                          </div>
                        </div>

                      );
                    })
                    :
                    <NotFound />
                  }
                </div>
              </div>
            }
            {(segment?.length > 0 && !loading) &&
              <Pagination
                currentPage={currentPage}
                totalCount={segmentPagination?.pages}
                pageSize={10}
                onPageChange={page => setCurrentPage(page)}
              />
            }
          </div>
        </div>
      </div>
      {CreateS &&
        <AddSegmentaion
          data={segmentDetail}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setApiCall={setApiCall}
          CreateS={CreateS}
          setCreateS={setCreateS}
        />
      }

      {FilterPopup &&
        <AddFilter
          onFilterChange={onFilterChange}
          selectedLanguage={selectedLanguage}
          translation={translation}
          filterData={filterData}
          onSubmit={onSubmit}
          clearFilter={clearFilter}
          setFilterPopup={setFilterPopup}
          FilterPopup={FilterPopup}
        />
      }

      {ViewDetails &&
        <DetailSegment
          data={segmentDetail}
          ViewDetails={ViewDetails}
          setViewDetails={setViewDetails}
        />
      }

      {deletePopup &&
        <DeleteConfirmation
          title='Customers Segmentation'
          isLoading={deleteLoading}
          handleClose={() => {
            setDeletePopup(false);
          }}
          handleDelete={() => deleteSegment(segmentDetail?.id)}
          heading={"Are you sure you want to delete?"}
        />}
    </>
  );
};

export default CustomersSegmentation;
